import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment'
import { Alert, Row, message, Tabs, Col, DatePicker, Input, Select, Upload, Progress, Avatar, Tag, Table, Menu, Typography, Modal, Tooltip, Card } from 'antd'
import { UploadOutlined, ClockCircleOutlined, MinusOutlined, PlusOutlined, createFromIconfontCN, DownOutlined, FieldTimeOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../config/apiUrl'
import NbAxios from '../config/utils/nbAxios'
import Base64 from 'base-64'
import locale from 'antd/es/date-picker/locale/zh_CN';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import '../static/css/workbench.css'
const { Text, Title } = Typography;
function WorkBench(props) {
    const MyFileTypeIcon = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/c/font_3779089_3kt93auqngf.js', // 在 iconfont.cn 上生成
    });

    // 共享服务器
    const JiaxingSharedServer = [
        {'name':'BMC002', 'desc':'非标（除欣贺项目外）','link':'https://183.134.3.222:60000'},
        {'name':'BMC003', 'desc':'欣贺项目','link':'https://183.134.3.222:61000'},
        {'name':'BMC006', 'desc':'标品，常春藤，财务，行政，人资','link':'https://183.134.3.222:61006'},
    ]
    const HangzhouSharedServer = [
        {'name':'BMC005','desc':'杭州服务器','link':'http://bmc005.quickconnect.cn/#/signin'}
    ]

    // 文件列表
    const financeFileList = [
        'BMC费用制度2021年.pdf',
        "BMC费用制度及报销流程培训--11.25日改.pptx",
        "云之家单据申请操作流程—手机版.zip",
        "云之家培训视频.zip",
        "云之家单据申请操作流程—网页版.zip",
        "云之家流程审批—网页端.zip",
        "云之家流程审批—手机端.zip"
    ]

    // 财务文件路径
    const financeFilePath = {
        "BMC费用制度2021年.pdf":"BMC费用制度2021年.pdf",
        "BMC费用制度及报销流程培训--11.25日改.pptx":"BMC费用制度及报销流程培训--11.25日改.pptx",
        "云之家单据申请操作流程—手机版.zip":"云之家操作视频/云之家单据申请操作流程—手机版.zip",
        "云之家培训视频.zipp":"云之家操作视频/云之家培训视频.zip",
        "云之家单据申请操作流程—网页版.zip":"云之家操作视频/云之家单据申请操作流程—网页版.zip",
        "云之家流程审批—网页端.zip":"云之家操作视频/云之家流程审批—网页端.zip",
        "云之家流程审批—手机端.zip":"云之家流程审批—手机端.zip",
    }

    // 人资文件
    const humanFileList = [
        '新员工入职文件包-嘉兴.zip',
        '新员工入职文件包-西可.zip',
        '新员工入职文件包-下沙.zip',
        '离职相关资料.zip',
    ]

    const fileDict = {
        'pdf':<MyFileTypeIcon type="icon-pdf" style={{fontSize:'20px', marginRight:'5px'}} />,
        'pptx':<MyFileTypeIcon type="icon-PPT" style={{fontSize:'20px', marginRight:'5px'}} />,
        'zip':<MyFileTypeIcon type="icon-ZIP" style={{fontSize:'20px', marginRight:'5px'}} />,
    }
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])

    var colors = ['#5793f3', '#d14a61', '#675bba', '#00a8e1', '#e30039 '];

    // 任务排序规则
    const menu = (
        <Menu>
            <Menu.Item>
                按任务紧急排序
            </Menu.Item>
            <Menu.Item>
                按任务时间排序
            </Menu.Item>
        </Menu>
    );

    const [project, setProject] = useState([
        {
            'name': '项目1',
            'jd': '80'
        },
        {
            'name': '项目2',
            'jd': '40'
        },
        {
            'name': '项目3',
            'jd': '90'
        },
        {
            'name': '项目4',
            'jd': '50'
        },
        {
            'name': '项目5',
            'jd': '20'
        }
    ])

    // 测试
    const columns = [
        {
            title: '项目名',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: '新增任务',
            dataIndex: 'jd',
            key: 'jd',
        },
        {
            title: '新完成任务',
            dataIndex: 'jd',
            key: 'jd',
        },
        {
            title: '待完成任务',
            dataIndex: 'jd',
            key: 'jd',
        }
    ]

    const [attention, setAttention] = useState([
        {
            'name': 'SUK:EJWACQ01030008采购已发货'
        },
        {
            'name': 'SUK:EJWACQ01030008 商品缺货'
        },
        {
            'name': 'SUK:EJWACQ01030008采购已发货'
        },
        {
            'name': 'SUK:EJWACQ01030008采购已发货'
        }
    ])

    const [task, setTask] = useState([
        {
            'name': '任务1',
            'jd': '80'
        },
        {
            'name': '任务2',
            'jd': '40'
        },
        {
            'name': '任务2',
            'jd': '90'
        },
        {
            'name': '任务2',
            'jd': '50'
        },
        {
            'name': '任务2',
            'jd': '20'
        }
    ])

    // 工单
    const [work, setWork] = useState([])

    // 天气数据
    const [ weatherData, setWeatherData] = useState([])

    const [team, setTeam] = useState([
        {
            'name': '团队1'
        },
        {
            'name': '团队2'
        },
        {
            'name': '团队3'
        },
        {
            'name': '团队4'
        },
        {
            'name': '团队5'
        },
    ])

    // 会议数据
    const [meeting, setMeeting] = useState([])

    // 日报数据
    const [ dailyReport , setDailyReport ] = useState('')

    // 已求课数据
    const [ seekCourse , setSeekCourse ] = useState([])

    // 已预约好的课
    const [ scheduledCourse , setScheduledCourse] = useState([])

    const teamColumns = [
        {
            title: '所属团队',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
    ]

    const meetingColumns = [
        {
            title: '会议室',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
        {
            title: '会议日期',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
        {
            title: '会议时间',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        }
    ]

    const workColunms = [
        {
            title: '工单号',
            key: 'id',
            dataIndex: 'id',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => (
                text == '1' ? <Tag>待指派</Tag> :
                    text == '2' ? <Tag color="blue">已指派，待处理</Tag> :
                        text == '3' ? <Tag color="purple">处理中</Tag> :
                            text == '4' ? <Tag color="#87d068">已处理,待反馈</Tag> :
                                <Tag color="#108ee9">已完成</Tag>
            ),
        },
        {
            title: '优先级',
            key: 'priority',
            dataIndex: 'priority',
            align: 'center',
            render: (text, record, index) => (
                text == '紧急' ? <Tag color="red">紧急</Tag> :
                    text == '一般' ? <Tag color="blue">一般</Tag> :
                        <Tag color="#87d068">低</Tag>
            ),
        },
        {
            title: '工单类型',
            key: 'type',
            dataIndex: 'type',
            align: 'center',
            render: (text, record, index) => (
                text == 'IT工单' ? <Tag color="red">数据需求工单</Tag> :
                    text == '邮箱工单' ? <Tag color="blue">邮箱服务工单</Tag> :
                        text == '共享工单' ? <Tag color="geekblue">共享服务工单</Tag> :
                            text == '旺店通工单' ? <Tag color="purple">旺店通工单</Tag> :
                                text == '法务工单' ? <Tag color="cyan">法务工单</Tag> :
                                    text == '入职共享工单' ? <Tag color="green">入职共享工单</Tag> :
                                        text == '入职门禁工单' ? <Tag color="green">入职门禁工单</Tag> :
                                            text == '入职ERP工单' ? <Tag color="green">入职ERP工单</Tag> :
                                                text == '入职绿码工单' ? <Tag color="green">入职绿码工单</Tag> :
                                                    text == '入职电脑工单' ? <Tag color="green">入职电脑工单</Tag> :
                                                        <Tag color="red">数据需求工单</Tag>
            ),
        },
    ]

    // 已求课的标题
    const seekCourseColunms = [
        {
            title: '课程名称',
            key: 'course_name',
            dataIndex: 'course_name',
            align: 'center',
        },
        {
            title: '创建者',
            key: 'creator_name',
            dataIndex: 'creator_name',
            align: 'center',
        },
    ]

    // 预约好课的标题
    const scheduledCourseColunms = [
        {
            title: '课程名称',
            key: 'course_name',
            dataIndex: 'course_name',
            align: 'center',
        },
        {
            title: '开课时间',
            key: 'course_clock',
            dataIndex: 'course_clock',
            align: 'center',
            render: (text, record, index) => (
                moment(text).format('YYYY-MM-DD HH:mm')
            ),
        },
        {
            title: '开课类型',
            key: 'course_kind,',
            dataIndex: 'course_kind,',
            align: 'center',
            render: (text, record, index) => (
                text == '1' ?  <Text type="secondary">分享</Text>:
                    <Text type="secondary">讨论</Text>
            ),
        }
    ]
    
    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    useEffect(()=>{
        // // 获取个人会议
        // getPersonalMeeting()
        // // 获取个人工单
        // getAllPersonalWorkOrder()
        // // getPersonalDailyReport()
        // // 获取所有已求课
        // getAlreadySeekList()
        // // 获取已预约好的课
        // getAlreadyScheduledList()
        // // 获取权限代码
        // getJurisdiction()
        // // 获取天气数据
        // getWeatherData()
        // Promise.all([getPersonalMeeting(), getAllPersonalWorkOrder(), getAlreadySeekList(), getAlreadyScheduledList(), getJurisdiction(), getWeatherData()])
        const getData = async () => {
            await Promise.all([getWeatherData(),
                getPersonalMeeting(),
                getAllPersonalWorkOrder(),
                getAlreadySeekList(),
                getJurisdiction(),
                getAlreadyScheduledList()])
        }
        getData()
    },[])

    // 获取天气数据
    const getWeatherData = ()=>{
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList'),
            "email": Base64.decode(cookie.load('email'))
        }
        NbAxios(dataProps, 'POST', servicePath.getWeatherData).then(res => {
            if (res.success) {
                setWeatherData(res.data['weather_all'])
            }else{
                console.log(res.errorMsg);
                message.error('天气数据,系统升级，请重新登陆')
            }
            
        })  
    }

    // 获取权限代码
    const getJurisdiction = ()=>{
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    }

    // 获取个人会议
    const getPersonalMeeting = ()=>{
        let dataProps = {
            "order_man_id": cookie.load('id'),
            "role_id": cookie.load('role'),
            "shop_list": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getAllPersonalMeeting,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res=>{
                if(res.data.success){
                    setMeeting(res.data.data);
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("id", { path: '/' });
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error('系统升级，请重新登陆')
                        cookie.remove("id", { path: '/' });
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    // 获取个人工单
    const getAllPersonalWorkOrder = ()=>{
        let dataProps = {
            "create_man": cookie.load('name'),
            "role_id": cookie.load('role'),
            "shop_list": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getAllPersonalWorkOrder,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res=>{
                if(res.data.success){
                    setWork(res.data.data)
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("id", { path: '/' });
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error('系统升级，请重新登陆')
                        cookie.remove("id", { path: '/' });
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    // 获取所有已求课的数据
    const getAlreadySeekList=()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "principal_uuid":  cookie.load('id')
        }
        NbAxios(dataProps, 'POST', servicePath.queryAlreadySeekList).then(res => {
            if (res.success) {
                setSeekCourse(res.data)
            }else{
                message.error('系统升级，请重新登陆')
            }
            
        })  
    }

    // 获取所有已预约好的课
    const getAlreadyScheduledList = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "principal_uuid":  cookie.load('id')
        }
        NbAxios(dataProps, 'POST', servicePath.queryAlreadyScheduledList).then(res => {
            if (res.success) {
                setScheduledCourse(res.data)
            }else{
                message.error('系统升级，请重新登陆')
            }
            
        })
    }

    // 获取个人日报
    const getPersonalDailyReport = ()=>{
        let date = moment().format("YYYY-MM-DD")
        let dataProps = {
            "user_id": cookie.load('id'),
            "date": date,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPersonalDailyReport,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res=>{
                if(res.data.success){
                    setDailyReport(res.data.data[0]['content']);
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("id", { path: '/' });
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error('系统升级，请重新登陆')
                    }
                }
            }
        )
    }

    // 任务完成率
    let finishingRate = {
        color: colors,
        title: {
            text: `任务完成率` + parseInt(20 / 22 * 100) + `%`,
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                labelLine: {
                    show: false
                },
                label: {
                    show: false,
                },

                data: [
                    { value: 20, name: '已完成' },
                    { value: 2, name: '待处理' },
                ]
            }
        ]
    }

    // 任务完成率
    let delayRate = {
        color: colors,
        title: {
            text: `任务延误率` + parseInt(2 / 22 * 100) + `%`,
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                labelLine: {
                    show: false
                },
                label: {
                    show: false,
                },

                data: [
                    { value: 20, name: '按时完成' },
                    { value: 2, name: '延误' },
                ]
            }
        ]
    }

    // useEffect(() => {
    //     // 基于准备好的dom，初始化echarts实例
    //     let reportChart = echarts.init(document.getElementById('finishingreport'));
    //     // 绘制图表
    //     reportChart.setOption(finishingRate);
    //     window.addEventListener('resize', () => {
    //         reportChart.resize()
    //     });
    // }, [finishingRate])

    // useEffect(() => {
    //     // 基于准备好的dom，初始化echarts实例
    //     let reportChart = echarts.init(document.getElementById('delayreport'));
    //     // 绘制图表
    //     reportChart.setOption(delayRate);
    //     window.addEventListener('resize', () => {
    //         reportChart.resize()
    //     });
    // }, [delayRate])

    return (
        <div style={{ paddingBottom: size.height * 0.1, marginTop: '5px' }}>
            <div style={{ marginLeft: '100px' }}>
                <div style={{ fontSize: '30px', fontWeight: 'bold' }}>我的工作台</div>
            </div>
            <div className="workbench-content" style={{ marginLeft: '100px', marginTop: '10px', width:'90vw', overflowY:'auto'  }}>
                <Row>
                    {/* <Col span={7} style={{ marginRight: '20px' }}>
                        <Card title="我参与的项目" extra={<a href="#">查看详情</a>} style={{ height: 300 }}>
                            <div style={{ height: 200, overflow: 'auto' }}>
                                <Table dataSource={project} columns={columns} />
                            </div>
                        </Card>
                    </Col>
                    <Col span={7} style={{ marginRight: '20px' }}>
                        <Card title="我参与的团队" extra={<a href="#">查看详情</a>} style={{ height: 300 }}>
                            {
                                team.map(item => {
                                    return <Tag color="blue">{item.name}</Tag>
                                })
                            }
                        </Card>
                    </Col> */}
                    <Col style={{width:'49.5%', marginRight:"1%"}}>
                        <Card title="常用链接" style={{ height: 300 }}>
                            <div>
                                <Title level={4}>嘉兴服务器:</Title>
                            </div>
                            <div style={{ display:"flex", flexDirection:"column"}}>
                                {
                                    JiaxingSharedServer.map(item=>{
                                        return <div style={{display:"flex",}}>
                                            <Text style={{fontSize:"20px", marginRight:"10px"}}>{item.name}</Text>
                                            <Text style={{fontSize:"20px", marginRight:"10px"}}>{item.desc}</Text>
                                            <a href={item.link} target="_blank" style={{fontSize:"20px",textDecoration:"underline"}}>{'点击跳转'}</a>
                                        </div>
                                    })
                                }
                            </div>
                            <div>
                                <Title level={4}>杭州服务器:</Title>
                            </div>
                            <div style={{ display:"flex", flexDirection:"column"}}>
                                {
                                    HangzhouSharedServer.map(item=>{
                                        return <div style={{display:"flex",}}>
                                            <Text style={{fontSize:"20px", marginRight:"10px"}}>{item.name}</Text>
                                            <Text style={{fontSize:"20px", marginRight:"10px"}}>{item.desc}</Text>
                                            <a href={item.link} target="_blank" style={{fontSize:"20px",textDecoration:"underline"}}>{'点击跳转'}</a>
                                        </div>
                                    })
                                }
                            </div>
                        </Card>
                    </Col>
                    <Col style={{ width:'49.5%' }}>
                        <Card title="常用文件" style={{ height: 300, }} bodyStyle={{padding:0, paddingLeft:"10px"}}>
                            <Tabs>
                                <Tabs.TabPane tab="财务" key="finance">
                                    <div style={{ height: 180, display:'flex',flexDirection:'column', flexWrap:'wrap', alignItems:"start" }}>
                                        {
                                                financeFileList.map(item=>{
                                                    return <a href={`https://pic.bmcsoft.cn/finance/`+financeFilePath[item]} target="_blank">
                                                        <div style={{marginRight:'10px'}}>
                                                            {fileDict[item.split('.')[item.split('.').length-1]]}
                                                            <Text style={{fontSize:"20px"}}>{item}</Text> 
                                                        </div>
                                                    </a>
                                                })
                                        }
                                    </div>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="人资" key="human">
                                    <div style={{ height: 180, display:'flex',flexDirection:'column', flexWrap:'wrap', alignItems:"start" }}>
                                        {
                                            humanFileList.map((item, index)=>{
                                                return <a href={`https://pic.bmcsoft.cn/imge_dir/RegulationsTran/`+humanFileList[index]} target="_blank">
                                                    <div style={{marginRight:'10px'}}>
                                                        {fileDict[item.split('.')[item.split('.').length-1]]}
                                                        <Text style={{fontSize:"20px"}}>{item}</Text> 
                                                    </div>
                                                </a>
                                            })
                                        }
                                    </div>
                                </Tabs.TabPane>
                            </Tabs>
                           
                        </Card>
                    </Col>
                     <Col style={{ width:'49.5%', marginRight:"1%", marginTop:'1%' }}>
                        <Card title="天气预报"  style={{ height: 300 }}>
                            <Row style={{width:'100%'}}>
                                {
                                    weatherData.map(item=>{
                                            return (
                                                <Card   
                                                    hoverable
                                                    style={{ width: '25%' }}
                                                >
                                                    <div style={{textAlign:'center', backgroundColor:"#87CEFA"}}>
                                                        <div style={{color:'white'}}>{item['realTime'].split('/')[2]}日</div>
                                                        <div>
                                                            <img src={`https://pic.bmcsoft.cn/it/weather/`+item['day_text'] +`.png`}></img> 
                                                        </div>
                                                        <div style={{color:'white'}}>
                                                            {item['day_text']}
                                                        </div>
                                                        <div style={{color:'white'}}>
                                                            {item['minTemp']} - {item['maxTemp']}
                                                        </div>
                                                    </div>
                                                </Card>
                                            )
                                    })
                                }
                            </Row>
                        </Card>
                    </Col>
                    <Col style={{ width:'49.5%', marginTop:'1%'  }}>
                        <Card title="我的工单" extra={<a onClick={() => { 
                            jurisdiction.indexOf("oa::workingSystem") != -1
                            ? props.history.push({ 'pathname': '/app/work/workSystem' })
                            : props.history.push({ 'pathname': '/app/work/insideWorkSystem' })
                         }}>查看详情</a>} style={{ height: 300 }}>
                            <div style={{ height: 300, color: '#ccc' }}>
                                 <Table dataSource={work} columns={workColunms} pagination={false} scroll={{y:150}} />
                            </div>
                        </Card>
                    </Col>
                    <Col style={{ width:'49.5%',marginRight:"1%", marginTop:'1%'}}>
                        <Card title="我的会议" extra={<a href="#" onClick={() => { props.history.push({ 'pathname': '/app/oa/ordermeeting' }) }}>查看详情</a>} style={{ height: 300 }}>
                            <div style={{ height: 200, overflow: 'auto', textAlign: 'center' }}>
                                {
                                    meeting.map(item => {
                                        return <Alert message={item.meeting_date + ` ` + item.start_time + `-` + item.end_time + ' ' + item.meeting_place + '-'+item.meeting_room} type="info" />
                                    })
                                }
                            </div>
                        </Card>
                    </Col>
                    {/* <Col span={7} style={{ marginRight: '20px', marginTop: '10px' }}>
                        <Card title="我的任务进度" extra={
                            <Row>
                                <Dropdown overlay={menu}>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{marginRight:'10px'}}>
                                        排序规则 <DownOutlined />
                                    </a>
                                </Dropdown>
                                <Col>
                                    <a href="#">查看详情</a>
                                </Col>
                            </Row>} style={{ height: 300 }}>
                            <div style={{ height: 200, overflow: 'auto' }}>
                                {
                                    task.map(item => {
                                        return <Row>
                                            <Col style={{ fontSize: '15px', fontWeight: 'bold', marginRight: '10px' }}>{item.name}:</Col>
                                            <Col style={{ width: size.width * 0.2 }}><Progress percent={item.jd} /></Col>
                                        </Row>
                                    })
                                }
                            </div>
                        </Card>
                    </Col> */}
                    {/* <Col span={7} style={{ marginRight: '20px', marginTop: '10px' }}>
                        <Card title="我的任务完成情况" style={{ height: 300 }}>
                            <Row>
                                <Col style={{ width: '30%' }}>
                                    <div id="finishingreport" style={{ width: size.width * 0.10, height: 200 }}></div>
                                </Col>
                                <Col style={{ width: '50%' }}>
                                    <div id="delayreport" style={{ width: size.width * 0.20, height: 200 }}></div>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                    {/* <Col span={7} style={{ marginRight: '20px', marginTop: '10px' }}>
                        <Card title="我的日报" extra={<a href="#">查看详情</a>} style={{ height: 300 }}>
                            <div style={{ height: 200, overflow: 'auto', color: '#ccc' }}>
                                <div dangerouslySetInnerHTML={{ __html: dailyReport }}></div>
                            </div>
                        </Card>
                    </Col> */}
                    {/* <Col span={7} style={{ marginRight: '20px', marginTop: '10px' }}>
                        <Card title="我的周报" extra={<a href="#">查看详情</a>} style={{ height: 300 }}>
                            <div style={{ height: 200, overflow: 'auto', color: '#ccc' }}>
                                本周工作总结<br />
                                1..宠粮经销供应商档案管理上线<br />
                                2.完成周报,日报,任务的UI<br />
                                3.react-beautiful-dnd, 初步完成工作状态看板UI<br />
                                4.完善工单提供功能，NB欣贺需求<br />

                                存在问题<br />
                                1.暂无<br />

                                下周工作计划<br />
                                1.研究odoo
                            </div>
                        </Card>
                    </Col> */}
                   
                    {/* <Col style={{ width:'49.5%', marginTop:'1%' }}>
                        <Card title="我的求课" extra={<a onClick={() => { props.history.push({ 'pathname': '/app/cursor/teachCourse' }) }}>查看详情</a>} style={{ height: 300 }}>
                            <div style={{ height: 300, color: '#ccc' }}>
                                <Table dataSource={seekCourse} columns={seekCourseColunms} pagination={false} scroll={{y:150}} />
                            </div>
                        </Card>
                    </Col> */}
                    {/* <Col style={{ width:'49.5%', marginTop:'1%' }}>
                        <Card title="我的学习" extra={<a onClick={() => { props.history.push({ 'pathname': '/app/cursor/myStudy' }) }}>查看详情</a>} style={{ height: 300 }}>
                            <div style={{ height: 300, color: '#ccc' }}>
                                <Table dataSource={scheduledCourse} columns={scheduledCourseColunms} pagination={false} scroll={{y:150}}/>
                            </div>
                        </Card>
                    </Col> */}
                    {/* <Col span={7} style={{ marginRight: '20px', marginTop: '10px' }}>
                        <Card title="我的关注" style={{ height: 300 }}>
                            <div style={{ height: 200, overflow: 'auto', color: '#ccc' }}>
                                {
                                    attention.map(item => {
                                        return <Alert message={item.name} type="info" />
                                    })
                                }
                            </div>
                        </Card>
                    </Col> */}
                    {/* <Col span={8}>1111</Col>
                    <Col span={8}>1111</Col>
                    <Col span={8}>1111</Col> */}
                </Row>
            </div>
        </div>
    )

}

export default WorkBench