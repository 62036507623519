import React, { useState, useCallback, useEffect } from 'react';
import { Breadcrumb, Row, Col, Button, Table, Modal, message, Input, Tag, Form, Select, Space, Spin } from 'antd'
import { EditOutlined, DeleteOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor'
import '../../static/css/Table.css'
import axios from 'axios'
import servicePath from '../../config/apiUrl'
import cookie from 'react-cookies'

function Introduce(props) {
    // 富文本编辑器控件
    const extendControls = ['bold', 'italic', 'underline', 'text-color', 'separator', 'link',]

    // 设置编辑器初始内容
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''))

    // 通知id
    const [id, setId] = useState('')

    // 通知数据
    const [date, setDate] = useState([])

    //页面
    const [page, setPage] = useState(1)

    // 总数页数
    const [total, setTotal] = useState(0)

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 是否添加通知
    const [addIntroduce, setAddIntroduce] = useState(false)

    // 是否修改通知
    const [updateIntroduce, setUpdateIntroduce] = useState(false)

    // 是否是加载状态
    const [isLoading, setIsLoading] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    const columns = [
        {
            title: '通知内容',
            key: 'content',
            dataIndex: 'content',
            width: '300px',
            align: 'center',
            render: (text, record, index) => (
                <div style={{textAlign:'left'}} dangerouslySetInnerHTML={{ __html: text }}></div>
            ),
        },
        {
            title: '更新时间',
            key: 'time',
            dataIndex: 'time',
            width: '100px',
            align: 'center'
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={()=>{
                    setEditorState(BraftEditor.createEditorState(date[index]['content']))
                    setId(date[index]['id'])
                    setUpdateIntroduce(true)
                }}/>
            ),
        }
    ]

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 页面改变的时候，去请求对应页面的数据
    useEffect(()=>{
        getAllNotification()
    },[page])
    

    // 获取所有通知
    const getAllNotification = ()=>{
        setIsLoading(true)
        let dataProps = {
            'page': page,
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role'),
        }
        axios({
            method: 'POST',
            url: servicePath.getNotification,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    console.log(res.data['data']);
                    setDate(res.data['data'])
                    setTotal(res.data['total'])
                    setIsLoading(false)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 编辑内容触发
    const editorChange = (editorState) => {
        setEditorState(editorState)
    }

    //  确认添加通知
    const addOk = ()=>{
        let dataProps = {
            'content': editorState.toHTML(),
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role'),
        }
        axios({
            method: 'POST',
            url: servicePath.addNotification,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    message.success("成功添加通知")
                    setAddIntroduce(false)
                    getAllNotification()
                    setEditorState(BraftEditor.createEditorState(''))
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
        
    }

    // 取消添加通知
    const addCancel = ()=>{
        message.success("取消添加通知")
        setEditorState(BraftEditor.createEditorState(''))
        setAddIntroduce(false)
    }

    //  确认修改通知
    const updateOk = ()=>{
        let dataProps = {
            'id':id,
            'content': editorState.toHTML(),
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role'),
        }
        axios({
            method: 'POST',
            url: servicePath.updateNotification,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    message.success("成功修改通知")
                    setUpdateIntroduce(false)
                    getAllNotification()
                    setEditorState(BraftEditor.createEditorState(''))
                    setId('')
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )        
    }

    // 取消修改通知
    const updateCancel = ()=>{
        message.success("取消修改通知")
        setEditorState(BraftEditor.createEditorState(''))
        setId('')
        setUpdateIntroduce(false)
    }




    return (
        <>
            <Spin spinning={isLoading} size="large" tip="数据请求中....." style={{ marginTop: size.height * 0.3 }}>
                 {/* 导航区域 */}
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.02 }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>首页</Breadcrumb.Item>
                        <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                        <Breadcrumb.Item>通知管理</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/* 按钮区域 */}
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06 }}>
                    <Row>
                        <Col span={1}>
                            <Button type="primary" onClick={()=>setAddIntroduce(true)}>新增通知</Button>
                        </Col>
                    </Row>
                </div>
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06 }}>
                    {/* 表单区域 */}
                    <Table
                        //表格列的配置描述
                        columns={columns}
                        //数据数组
                        dataSource={date}
                        pagination={paginationProps}
                        scroll={{ y: 600 }}
                        //表格行 key 的取值
                        rowKey={'id'}
                    />
                </div>
            </Spin>
            {/* 添加通知 */}
            <Modal
                centered={true}
                visible={addIntroduce}
                okText="发布"
                cancelText="取消"
                onOk={addOk}
                onCancel={addCancel}
                width="1000px"
                maskClosable={false}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <BraftEditor
                    contentStyle={{ height: 200 }}
                    value={editorState}
                    extendControls={extendControls}
                    onChange={editorChange}
                />
            </Modal>

             {/* 修改通知 */}
             <Modal
                centered={true}
                visible={updateIntroduce}
                okText="发布"
                cancelText="取消"
                onOk={updateOk}
                onCancel={updateCancel}
                width="1000px"
                maskClosable={false}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <BraftEditor
                    contentStyle={{ height: 200 }}
                    value={editorState}
                    extendControls={extendControls}
                    onChange={editorChange}
                />
            </Modal>

        </>
    )

}


export default Introduce