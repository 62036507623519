import React, { useState, useEffect } from 'react';
import { Badge, Typography, Skeleton, Image, Checkbox, Button, Pagination, Divider, Input, Modal, Upload, message, Form, DatePicker, Table, Space } from 'antd';
import { EditOutlined, DeleteOutlined, InboxOutlined, SearchOutlined } from '@ant-design/icons';
import servicePath from '../../../config/apiUrl';
import Base64 from 'base-64'
import NbAxios from '../../../config/utils/nbAxios'
import cookie from 'react-cookies'
const { Title, Text, Paragraph } = Typography
function Index() {

    // 点心
    const [dessertList, setDesserList] = useState([])
    // 取餐码
    const [takeCode, setTakeCode] = useState('')
    // 是否取餐
    const [isTake, setIsTake] = useState(0)
    // 报名Id
    const [applicationId, setApplicationId] = useState('')
    // 剩余次数
    const [ remainingCount , setRemainingCount] = useState(0)

    useEffect(() => {
        getHalfMealTodayDessert()
        getHalfMealTakeCode()
        getHalfMealRemainingCount()
    }, [])

    const getHalfMealRemainingCount = () => {
        let values = {}
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        values.creator = cookie.load('name')
        NbAxios(values, "POST", servicePath.queryHalfMealRemainingCount).then(res => {
            if (res.success) {
                setRemainingCount(res.data['remaining_count']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    const getHalfMealTodayDessert = () => {
        let values = {}
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.queryHalfMealTodayDessert).then(res => {
            if (res.success) {
                setDesserList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    const getHalfMealTakeCode = () => {
        let values = {}
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        values.creator = cookie.load('name')
        NbAxios(values, "POST", servicePath.queryHalfMealTakeCode).then(res => {
            if (res.success) {
                setTakeCode(res.data['take_code'])
                setApplicationId(res.data['application_id'])
                setIsTake(res.data['is_take'])

            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: 10, borderBottom: "1px solid #ccc" }}>
                <Title level={4}>0.5餐申请</Title>
                <div></div>
            </div>
            <div style={{ height: 'calc(100vh - 200px)', display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '90vw', margin: "0 auto", border: '1px solid #ccc', marginTop: 10, paddingBottom: 20 }}>
                    <div>
                        <Badge.Ribbon text="今日点心" placement="start" color="red"></Badge.Ribbon>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop:20 }}>
                        {
                            dessertList.map(item => {
                                return <div style={{
                                    padding: 40,
                                    marginRight: 10,
                                    boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)',
                                    display: 'flex', alignItems: 'center', flexDirection: 'column',
                                    justifyContent: 'center', marginTop: 20, cursor: 'pointer',
                                    height: 200, marginBottom: 20
                                }} >
                                    {
                                        item.image == '' ? <Skeleton.Image style={{ width: '10vw', height: 135, margin: "0 auto" }} /> : <Image src={`https://pic.bmcsoft.cn/` + item.image} style={{ width: '10vw', height: 135 }} />
                                    }
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Text strong style={{ fontSize: 15, marginRight: 20, marginTop: 20 }}>{item.name}</Text>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', marginTop: 50, marginLeft: 30 }}>
                            {
                                takeCode == '' ? <div style={{display: 'flex'}}>
                                    <Button disabled={remainingCount ? false : true} type="primary" onClick={() => {
                                        let values = {}
                                        values.planId = dessertList[0]['plan_id']
                                        values.roleId = cookie.load('role')
                                        values.shopList = cookie.load('shopList')
                                        values.creator = cookie.load('name')
                                        values.email = Base64.decode(cookie.load('email'))
                                        NbAxios(values, "POST", servicePath.signUpHalfMeal).then(res => {
                                            if (res.success) {
                                                message.success('报名成功')
                                                getHalfMealTakeCode()
                                            } else {
                                                message.warn(res.errorMsg)
                                            }
                                        })
                                    }}>报名</Button>
                                    <Text type="danger" strong style={{marginLeft:15, fontSize:15}}>{`今日取消次数剩余${remainingCount}次`}</Text>
                                </div>
                                    : <div style={{ display: 'flex' }}>
                                        <Button disabled={isTake}  type="primary" danger onClick={() => {
                                            let values = {}
                                            values.applictionId = applicationId
                                            values.roleId = cookie.load('role')
                                            values.shopList = cookie.load('shopList')
                                            NbAxios(values, "POST", servicePath.cancelSignUpHalfMeal).then(res => {
                                                if (res.success) {
                                                    message.success('取消报名成功')
                                                    getHalfMealTakeCode()
                                                    getHalfMealRemainingCount()
                                                } else {
                                                    message.warn(res.errorMsg)
                                                }
                                            })
                                        }}>取消报名</Button>
                                        
                                    </div>
                            }
                        </div>
                    </div>
                    <div style={{display:takeCode == '' ? 'none' : 'flex',justifyContent:'center', marginTop: 5, fontSize:20 }}>
                        <div style={{ display: 'flex'}}>
                            <b style={{ marginLeft: 20, marginRight: 5 }}>取餐码:</b>
                            <Paragraph style={{ marginRight: 5 }} copyable={{ text: takeCode }} >
                                <Text strong>{takeCode}</Text>
                            </Paragraph>
                            {
                                isTake ? <Text>已取餐</Text> : <Text>取餐码已发送到对应手机</Text>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Index