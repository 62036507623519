import React, { useEffect, useState } from 'react';
import { Input, Typography, Checkbox, message, Modal, Button, Spin, Table, Select, Progress, Popover, Tag } from 'antd'
import { PlusSquareOutlined, ArrowLeftOutlined, MinusSquareOutlined, DownloadOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl';
import NbAxios from '../../config/utils/nbAxios';
import axios from 'axios'
import webUrl from '../../config/webUrl';
import AcceptBuried from '../../config/utils/buried';
import style from './index.module.css'
import Barcode from './components/barcode';
import downExcel from "../../config/utils/downloadFile"
const { Title, Text, Paragraph } = Typography;
const { Option } = Select
function ChargeBack(props) {
    // 递归拷贝(深拷贝)
    var deepCopy = function (source) {
        var result = {};
        for (var key in source) {
            result[key] = typeof source[key] === 'object' ? deepCopy(source[key]) : source[key];
        }
        return result;
    }
    // 表头列表
    const [columnList] = useState(["序号", "款图", "货品编码", "品牌", "分类", "码数", "数量", , "吊牌价", "金额", "结算价", "结算金额", "结算折扣", "应付金额", "退货"])
    // 打印表头列表
    const [printColumnList] = useState(["序号", "货品编码", "品牌", "分类", "码数", "数量", "吊牌价", "金额", "结算价", "结算金额", "结算折扣", "应付金额"])
    // 导出权限校验
    const [jurisdiction] = useState(["于雪","沈费","唐铎","叶康辉","周浩琪","姚凯鹏","王修文","陈黎黎","曹守梅"])
    // 设置打印表头
    const [printColumn, setPrintColumn] = useState([])
    // 设置表头
    const [column, setColumn] = useState([])
    // 表格明细内容
    const [tableData, setTableData] = useState([])
    // 原订单
    const [originalPrice, setOriginalPrice] = useState([])
    // 订单条形码
    const [orderBarCode, setOrderBarCode] = useState('')
    // 退货单编号
    const [orderCode, setOrderCode] = useState('')
    // 原订单编号
    const [originalCode, setOriginalCode] = useState('')
    // 控制气泡框开启关闭
    const [visible, setVisible] = useState([false]);
    // 订单金额
    const [orderMoumnt, setOrderMoumnt] = useState('')
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 退货商品列表
    const [chargeBackData, setChargeBackData] = useState([])
    // 退货数
    const [chargeNum, setChargeNum] = useState('')
    // 是否打开退货窗口
    const [isOrder, setIsOrder] = useState(false)
    // 是否打开打印窗口
    const [isOrderPrint, setIsOrderPrint] = useState(false)
    // 是否导出所有明细数据
    const [isExportAllModel, setIsExportAllModel] = useState(false)
    // 是否在导出中
    const [exportFlag, setExportFlag] = useState(0)
    useEffect(() => {
        setColumn(columnList.map((item, index) => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                width: "5%",
                render: (text, record, index) => {
                    return (
                        item == '款图' && record["序号"] !== '合计' ?
                            <img src={record.url} style={{ width: '50px' }} /> : item == '退货' && record["序号"] !== '合计' ?
                                // <Popconfirm  title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                //     // <MinusSquareOutlined />{record["数量"]}<PlusSquareOutlined />
                                // }}>
                                //     <a onClick={()=>{
                                //         // delGoods(record)
                                //         message.warning("开发中，暂不支持")
                                //     }}>退货</a>
                                //     </Popconfirm>:
                                <Popover content={exportMoldelMenu(record, index)} placement="right" visible={record.visible} trigger="click" onClick={(e) => {
                                    if (e.target.length == 0) {
                                        let obj = [...tableData]
                                        obj.map(item => {
                                            item["visible"] = false
                                        })
                                        setTableData(obj)
                                    }
                                }}
                                >
                                    <a onClick={() => {
                                        let obj = [...tableData]
                                        obj.map(item => {
                                            item["visible"] = false
                                        })
                                        obj[index]["visible"] = true
                                        setTableData(obj)
                                        setChargeNum(record["数量"])
                                    }}>退货</a>
                                </Popover> : text !== '' ?
                                    (item == '吊牌价' && record["序号"] !== '合计') || item == '金额' || item == '结算金额' || item == '应付金额' || (item == '结算价' && record["序号"] !== '合计') ?
                                        <div>{text.toFixed(2)}</div> :
                                        <div>{text}</div> : ""
                        // <div>{text}</div>
                    )
                }
            }
        }))
    }, [visible, tableData, chargeNum])
    useEffect(() => {
        setPrintColumn(printColumnList.map((item, index) => {
            return {
                title: item == "应付金额" ? "应退金额" : item,
                dataIndex: item,
                key: item,
                align: "center",
                width: "5%",
                render: (text, record, index) => {
                    return (
                        // item == '结算折扣' && discountBrand.indexOf(record["品牌"]) !== -1 && jewelryDiscount >= 3 && jewelryDiscount < 5 ?
                        //     <div>{0.9}</div> :
                        //     item == '结算折扣' && discountBrand.indexOf(record["品牌"]) !== -1 && jewelryDiscount >= 5 ?
                        //         <div>{0.8}</div> : item == '结算折扣' && record["序号"] !== '合计' ?
                        //             <div>{discount}</div> : 
                        text !== '' ?
                            (item == '吊牌价' && record["序号"] !== '合计') || item == '金额' || item == '结算金额' || item == '应付金额' || (item == '结算价' && record["序号"] !== '合计') ?
                                <div>{text.toFixed(2)}</div> :
                                <div>{text}</div> : ""
                    )
                },
                onCell: function (column) {
                    return (
                        {
                            style: {
                                // width: 80,
                                // overflow: 'hidden',
                                // whiteSpace: 'nowrap',
                                // textOverflow:'ellipsis',
                                // cursor:'pointer',
                                fontSize: 5
                            },
                        }
                    )
                },
                onHeaderCell: function (column) {
                    return (
                        {
                            style: {
                                // width: 80,
                                // overflow: 'hidden',
                                // whiteSpace: 'nowrap',
                                // textOverflow:'ellipsis',
                                // cursor:'pointer',
                                fontSize: 5
                            },
                        }
                    )
                },
            }
        }))
    }, [])
    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
        setChargeNum('')
    };
    // 退货菜单
    const exportMoldelMenu = (record, index) => (
        <div>
            <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                <MinusSquareOutlined style={{ marginRight: '10px', cursor: "pointer", fontSize: '15px' }} onClick={() => {
                    let obj = chargeNum
                    // // 默认全部退货
                    // if(obj === ''){
                    //     obj = record["数量"]
                    // }
                    // 如果数量不大于原本的数量则可以减一
                    if (obj > 0) {
                        obj = obj - 1
                    }
                    setChargeNum(obj)
                    console.log(obj);
                }} />{chargeNum}<PlusSquareOutlined style={{ marginLeft: '10px', cursor: "pointer", fontSize: '15px' }} onClick={() => {
                    let obj = chargeNum
                    if (obj === '') {
                        obj = record["数量"]
                    }
                    if (obj < record["数量"]) {
                        obj = obj + 1
                    }
                    setChargeNum(obj)
                }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button size='small' onClick={() => {
                    let obj = [...tableData]
                    obj[index]["visible"] = false
                    setTableData(obj)
                }} style={{ marginRight: "10px" }}>取消</Button>
                <Button type='primary' size='small' onClick={() => {
                    let num = chargeNum
                    let chargeObj = [...chargeBackData]
                    // 如果没有选择退货数则默认全退
                    if (num === '') {
                        num = record["数量"]
                    }
                    let obj = [...tableData]
                    // 如果选择退0件则退出函数
                    if (num == 0) {
                        return false
                    }
                    // 如果全退则直接移除这条数据
                    if (obj[index]["数量"] === num) {
                        obj.remove(record)
                        // 如果最后一列是总计，则删除
                        if (chargeObj[chargeObj.length - 1]) {
                            if (chargeObj[chargeObj.length - 1]["序号"] == "合计") {
                                chargeObj.pop()
                            }
                        }
                        let repeat = true
                        // 查询退货表中是否已存在退货数据
                        chargeObj.map(item => {
                            // 若存在,则数量+上退货数量
                            if (item["商家编码"] == record["商家编码"]) {
                                item["数量"] = item["数量"] + num
                                repeat = false
                            }
                        })
                        if (repeat) {
                            // 将退的这条数据加入退货表
                            chargeObj.push(record)
                        }
                    } else {
                        // 否则按退货数量移除
                        obj[index]["数量"] -= num
                        recalculatePrice(obj, setTableData)
                        let repeat = true
                        // 查询退货表中是否已存在退货数据
                        chargeObj.map(item => {
                            // 若存在,则数量+上退货数量
                            if (item["商家编码"] == record["商家编码"]) {
                                item["数量"] = item["数量"] + num
                                repeat = false
                            }
                        })
                        // 若不存在,则整条插入
                        if (repeat) {
                            // 如果最后一列是总计，则删除
                            if (chargeObj[chargeObj.length - 1]) {
                                if (chargeObj[chargeObj.length - 1]["序号"] == "合计") {
                                    chargeObj.pop()
                                }
                            }
                            // 深拷贝
                            let objRecord = deepCopy(record)
                            objRecord["数量"] = num
                            chargeObj.push(objRecord)
                        }
                    }
                    obj[index]["visible"] = false
                    recalculatePrice(obj, setTableData)
                    // 重新计算价格
                    recalculatePrice(chargeObj, setChargeBackData)
                }}>确认</Button>
            </div>
        </div>
    )
    // 查询订单
    const saleReturnGetGoodsInfo = (order_code) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            order_code: order_code,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.saleReturnGetGoodsInfo).then(res => {
            setLoading(false)
            setOriginalCode(order_code)
            setOrderBarCode('')
            if (res.success) {
                message.success("查询订单成功")
                res.data.map(item => {
                    item["visible"] = false
                })
                totalAdd(res.data, setTableData)
                // 原订单存储
                totalAdd(res.data, setOriginalPrice)
                console.log(res.data);
                // setTableData(res.data)
            } else {
                // setOrderBarCode('')
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setOrderBarCode('')
            setLoading(false)
            // 获取500错误
            message.warn('查询条形码接口报错，请联系IT帮忙解决')
        })
    }
    // 退货提交
    const uploadRefundInfo = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            name: cookie.load('name'),
            // order_code:orderBarCode,
            // pay_money:orderMoumnt,
            data: chargeBackData,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.uploadRefundInfo).then(res => {
            setLoading(false)
            // setOrderBarCode('')
            if (res.success) {
                message.success("上传成功")
                setOrderCode(res.data)
                setIsOrder(true)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            // setOrderBarCode('')
            setLoading(false)
            // 获取500错误
            message.warn('退货提交报错，请联系IT帮忙解决')
        })
    }
    //该方法参数 content：需要打印的元素
    const printFun = (content) => {
        var Window = window.open("", "页面打印", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no");
        var style = "<style type='text/css'></style>";
        Window.document.write(content + style);
        Window.focus();
        Window.document.close();     //关闭document的输出流, 显示选定的数据
        Window.print();               //打印当前窗口
        return Window;
    }
    // 总计计算和增加
    const totalAdd = (obj, setTableData) => {
        // 如果最后一列是总计，则删除
        if (obj[obj.length - 1]) {
            if (obj[obj.length - 1]["序号"] == "合计") {
                obj.pop()
            }
        }
        // 计算总计
        let sumdP = 0
        let sumdj = 0
        let sumds = 0
        let num = 0
        let sumyf = 0
        let sumjsj = 0
        obj.map(item => {
            // if (item["吊牌价"]) {
            //     sumdP = sumdP + item["吊牌价"]
            // }
            if (item["金额"]) {
                sumdj = sumdj + item["金额"]
            } if (item["结算金额"]) {
                sumds = sumds + item["结算金额"]
            }
            if (item["应付金额"]) {
                sumyf = sumyf + item["应付金额"]
            }
            if (item["数量"]) {
                num = num + item["数量"]
            }
            // if (item["结算价"]) {
            //     sumjsj = sumjsj + item["结算价"]
            // }
        })
        obj.push({
            "序号": "合计",
            // "吊牌价": sumdP,
            "金额": sumdj,
            "结算金额": sumds,
            "应付金额": sumyf,
            // "结算价": sumjsj,
            "数量": num,
        })
        setTableData(obj)
    }
    // 重新计算价格
    const recalculatePrice = (obj, setTableData) => {
        // 如果最后一列是总计，则删除
        if (obj[obj.length - 1]) {
            if (obj[obj.length - 1]["序号"] == "合计") {
                obj.pop()
            }
        }
        if (obj.length !== 0) {
            // 计算总计
            let sumdP = 0
            let sumdj = 0
            let sumds = 0
            let num = 0
            let sumyf = 0
            let sumjsj = 0
            obj.map(item => {
                // if (item["吊牌价"]) {
                //     sumdP = sumdP + item["吊牌价"]
                // }
                if (item["金额"]) {
                    item["金额"] = item["吊牌价"] * item["数量"]
                    sumdj = sumdj + item["金额"]
                } if (item["结算金额"]) {
                    item["结算金额"] = item["结算价"] * item["数量"]
                    sumds = sumds + item["结算金额"]
                }
                if (item["应付金额"]) {
                    item["应付金额"] = item["结算价"] * item["结算折扣"]
                    sumyf = sumyf + item["应付金额"]
                }
                if (item["数量"]) {
                    num = num + item["数量"]
                }
                // if (item["结算价"]) {
                //     sumjsj = sumjsj + item["结算价"]
                // }
            })
            obj.push({
                "序号": "合计",
                // "吊牌价": sumdP,
                "金额": sumdj,
                "结算金额": sumds,
                "应付金额": sumyf,
                // "结算价": sumjsj,
                "数量": num,
            })
        }
        setTableData(obj)
    }
    
    // 更改退货单单状态
    const updateRefundStatus = (status) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            name:cookie.load('name'),
            // data: chargeBackData,
            refund_state: status,
            refund_code: orderCode,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.updateRefundStatus).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("退货单单处理成功")
                var windows = printFun(document.getElementById('print').innerHTML);
                windows.close();
                props.history.push({ 'pathname': `/app/pos/initial` })
                setTableData([])
                setChargeBackData([])
                setOrderBarCode('')
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('更改订单状态报错，请联系IT帮忙解决')
        })
    }
    // 导出退货单明细
    const downloadStandardizedModel = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            name: cookie.load('name'),
        }
        setLoading(true)
        axios({
            data: dataProps,
            method: 'POST',
            url: servicePath.downloadRefundDetail,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setLoading(false)
                downExcel(res)
                setExportFlag(2)
            }
        ).catch(e => {
            setLoading(false)
            setExportFlag(3)
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }
    return (
        <div className={style.initial}>
            <Spin spinning={loading} tip="数据加载中....">
                <div style={{ marginTop: '20px', alignItems: 'center', fontSize: '30px', cursor: 'pointer', marginLeft: '50px' }} onClick={() => props.history.push({ 'pathname': `/app/pos/initial` })}>
                    <ArrowLeftOutlined />
                    返回
                </div>
                <div className={style.externalContent}>
                    <div className={style.mak} style={{ fontSize: '40px' }}>创建退货单</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <span style={{ marginRight: '10px' }}>订单条形码:</span>
                            <Input value={orderBarCode} onChange={(e) => { setOrderBarCode(e.target.value) }}
                                // 自动获取焦点
                                ref={function (input) {
                                    if (input != null) {
                                        input.focus();
                                    }
                                }}
                                onPressEnter={(e) => {
                                    if (e.target.value.length !== 20) {
                                        message.warning("订单编码输入有误,请在再次尝试并且切换英文输入法")
                                        setOrderBarCode('')
                                    } else {
                                        saleReturnGetGoodsInfo(e.target.value)
                                        setChargeBackData([])
                                    }
                                }}
                                style={{ width: '200px' }}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Table columns={column} dataSource={tableData} size="small" pagination={false} />
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div style={{marginTop:'20px'}}>
                            <Button onClick={() => {
                                if(jurisdiction.indexOf(cookie.load("name")) == -1){
                                    message.error("没有权限导出")
                                }else{
                                    setIsExportAllModel(true)
                                    setExportFlag(0)
                                }
                                }}
                                type='primary'
                                >
                                <DownloadOutlined /> 导出退货单明细
                            </Button>
                        </div>
                        <div className={style.endButton}>
                            <Button  onClick={() => {
                                if (originalPrice.length !== 0) {
                                    setIsOrderPrint(true)
                                }else{
                                    message.warning("还未查询订单")
                                }
                            }} style={{marginRight:'20px'}}>打印原订单</Button>
                            <Button type='primary' onClick={() => {
                                if (chargeBackData.length !== 0) {
                                    uploadRefundInfo()
                                } else {
                                    message.warning("还未录入退货品")
                                }
                                // message.warning("开发中，暂不支持")
                            }}>生成退货单</Button>
                        </div>
                    </div>
                    
                </div>
            </Spin>
            <Modal
                title="退货窗口"
                visible={isOrder}
                onCancel={() => {
                    setIsOrder(false)
                }}
                // onOk={()=>{
                //     setIsOrder(false)
                // }}
                // okText="已支付"
                // cancelText="取消支付"
                centered={true}
                footer={<div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Button style={{ width: "88px" }} onClick={() => {
                        message.success("取消支付成功")
                        updateRefundStatus("取消退款")
                    }}>取消退款</Button>
                    <Button style={{ width: "74px" }} type="primary" onClick={() => {
                        updateRefundStatus("已退款")
                    }}>已退款</Button>
                    <Button onClick={() => {
                        // setIsOrder(false)
                        // let newstr = document.getElementById("print").innerHTML;
                        // let oldstr = document.body.innerHTML;
                        // document.body.innerHTML = newstr;
                        // window.print();
                        // document.body.innerHTML = oldstr;
                        // window.location.reload()
                        var windows = printFun(document.getElementById('print').innerHTML);
                        windows.close();
                    }}>打印</Button>
                </div>}
                width={1000}
            >
                {/* style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "120px" */}
                <div id="print">
                    <h1 style={{ textAlign: 'center' }}>
                        退货单明细
                    </h1>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: "500px" }}>

                            <div style={{ display: 'grid', gridTemplateColumns: '70px 1fr' }}>
                                <div>
                                    交易时间:
                                </div>
                                <div>
                                    {moment().format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                                <div>
                                    订单编号:
                                </div>
                                <div>
                                    {orderCode}
                                </div>
                                <div>
                                    单位:
                                </div>
                                <div>
                                    元
                                </div>
                            </div>


                        </div>
                        <div>
                            {/* <BarCodeSVG /> */}
                            <Barcode value={orderCode} height={50} width={2} />
                        </div>

                    </div>

                    {/* <div style={{ fontSize: '30px' }}>总金额:{total}</div> */}
                    <Table columns={printColumn} dataSource={chargeBackData} size="small" pagination={false} />
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <div style={{ width: "180px" }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '70px 1fr' }}>
                                <div>
                                    原价金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {chargeBackData.length !== 0 ? chargeBackData[chargeBackData.length - 1]["金额"].toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    应退金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {chargeBackData.length !== 0 ? chargeBackData[chargeBackData.length - 1]["应付金额"].toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    已退金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {chargeBackData.length !== 0 ? chargeBackData[chargeBackData.length - 1]["应付金额"].toFixed(2) + "元" : ""}
                                </div>
                                {/* <div>
                                    折扣金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {chargeBackData.length !== 0 ? (chargeBackData[chargeBackData.length - 1]["金额"] - chargeBackData[chargeBackData.length - 1]["应付金额"]).toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    综合折扣:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {chargeBackData.length !== 0 ? (chargeBackData[chargeBackData.length - 1]["应付金额"] / chargeBackData[chargeBackData.length - 1]["金额"] * 100).toFixed(2) + "%" : ""}
                                </div> */}
                            </div>
                        </div>
                        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '70px 1fr', height: '88px', marginLeft: '120px' }}>
                                <div>
                                    数量合计:
                                </div>
                                <div>
                                    {chargeBackData.length !== 0 ? chargeBackData[chargeBackData.length - 1]["数量"] + "件" : ""}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                            <div style={{ height: '130px' }}>
                            </div>
                            <div style={{ marginRight: "150px" }}>
                                顾客签字:
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
            <Modal
                title="原订单打印"
                visible={isOrderPrint}
                onCancel={() => {
                    setIsOrderPrint(false)
                }}
                // onOk={()=>{
                //     setIsOrder(false)
                // }}
                // okText="已支付"
                // cancelText="取消支付"
                centered={true}
                footer={<div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Button onClick={() => {
                        setIsOrderPrint(false)
                    }}>取消</Button>
                    {/* <Button style={{ width: "74px" }} type="primary" onClick={() => {
                        modifyOrderStatus("已支付")
                    }}>已支付</Button> */}
                    <Button type='primary' onClick={() => {
                        // setIsOrder(false)
                        // let newstr = document.getElementById("print").innerHTML;
                        // let oldstr = document.body.innerHTML;
                        // document.body.innerHTML = newstr;
                        // window.print();
                        // document.body.innerHTML = oldstr;
                        // window.location.reload()
                        var windows = printFun(document.getElementById('originalPrint').innerHTML);
                        windows.close();
                    }}>打印</Button>
                </div>}
                width={1000}
            >
                {/* style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "120px" */}
                <div id="originalPrint">
                    <h1 style={{ textAlign: 'center' }}>
                        销售订单明细
                    </h1>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: "500px" }}>

                            <div style={{ display: 'grid', gridTemplateColumns: '70px 1fr' }}>
                                <div>
                                    交易时间:
                                </div>
                                <div>
                                    {originalPrice.length !== 0 ?originalPrice[0]["订单日期"]:""}
                                </div>
                                <div>
                                    订单编号:
                                </div>
                                <div>
                                    {originalCode}
                                </div>
                                <div>
                                    单位:
                                </div>
                                <div>
                                    元
                                </div>
                            </div>


                        </div>
                        <div>
                            {/* <BarCodeSVG /> */}
                            <Barcode value={originalCode} height={50} width={2} />
                        </div>

                    </div>

                    {/* <div style={{ fontSize: '30px' }}>总金额:{total}</div> */}
                    <Table columns={printColumn} dataSource={originalPrice} size="small" pagination={false} />
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: "180px" }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '70px 1fr' }}>
                                <div>
                                    原价金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {originalPrice.length !== 0 ? originalPrice[originalPrice.length - 1]["金额"].toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    应付金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {originalPrice.length !== 0 ? originalPrice[originalPrice.length - 1]["应付金额"].toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    已付金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {originalPrice.length !== 0 ? originalPrice[originalPrice.length - 1]["应付金额"].toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    折扣金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {originalPrice.length !== 0 ? (originalPrice[originalPrice.length - 1]["金额"] - originalPrice[originalPrice.length - 1]["应付金额"]).toFixed(2) + "元" : ""}
                                </div>
                                 <div>
                                    综合折扣:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {originalPrice.length !== 0 ? (originalPrice[originalPrice.length - 1]["应付金额"] / originalPrice[originalPrice.length - 1]["金额"] * 100).toFixed(2) + "%" : ""}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '70px 1fr', height: '88px', marginLeft: '120px' }}>
                                <div>
                                    数量合计:
                                </div>
                                <div>
                                    {originalPrice.length !== 0 ? originalPrice[originalPrice.length - 1]["数量"] + "件" : ""}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        如需开票请持有此单在收银柜台登记，我司将以邮件形式将电子发票信息发送至您的邮箱！
                    </div>
                    <div>
                        =============================================================================================================
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <h3>礼品兑换券</h3>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: "300px", height: '130px' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '90px 1fr' }}>
                                <div>
                                    订单编号:
                                </div>
                                <div>
                                    {originalCode}
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    订单条形码:
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <Barcode value={originalCode} height={50} width={2} />
                                </div>
                                <div>
                                    已付金额:
                                </div>
                                <div>
                                    {originalPrice.length !== 0 ? originalPrice[originalPrice.length - 1]["应付金额"].toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    交易时间:
                                </div>
                                <div>
                                    {originalPrice.length !== 0 ?originalPrice[0]["订单日期"]:""}
                                </div>
                            </div>
                        </div>
                        {/* <div style={{maxHeight: "calc(100% - 10px)",display:'flex',justifyContent:'right'}}>
                            <div >
                                顾客签字:
                            </div>
                        </div> */}
                        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                            <div style={{ height: '130px' }}>
                            </div>
                            <div style={{ marginRight: "150px" }}>
                                顾客签字:
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
            <Modal
                    title="导出退货单明细"
                    visible={isExportAllModel}
                    centered={true}
                    onCancel={() => {
                        if(exportFlag == 1){
                            message.warning("导出中,请勿操作")
                        }else{
                            setIsExportAllModel(false)
                        }
                    }}
                    onOk={() => {
                        if(cookie.load("name"))
                        if(exportFlag == 1){
                            message.warning("导出中,请勿操作")
                        }else if (exportFlag == 2) {
                            setIsExportAllModel(false)
                        }else {
                            const getAllData = async () => {
                                await Promise.all([
                                    downloadStandardizedModel(),
                                    setExportFlag(1)
                                ])
                            }
                            getAllData()
                        }
                    }}
                    okText="确认"
                    cancelText="取消"
                >
                    {/* <Spin style={{ display: tabKey == 'productList' ? '' : 'none' }} spinning={loading} tip="数据加载中...."> */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <ExclamationCircleOutlined style={{color:"#a3986d",width:'30px'}}/> */}
                            {exportFlag == 0 ? <Progress type="circle" percent={0} /> : exportFlag == 1 ? <Spin size="large" /> : exportFlag == 2 ? <Progress type="circle" percent={100} /> : <Progress type="circle" percent={70} status="exception" />}
                        </div>
                        <div style={{ marginTop: "30px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {exportFlag == 0 ? <b>确认导出所有导出赠品明细吗？</b> : exportFlag == 1 ? <b>导出中</b> : exportFlag == 2 ? <b>导出完成</b> : <b>导出失败</b>}
                        </div>
                    {/* </Spin> */}
            </Modal>
        </div>
    )
}

export default ChargeBack