import React, { useState, useEffect } from 'react';
import { Table, Typography, DatePicker, message, Modal, Collapse, Button, Form, Input, Tooltip, Row, Col, Select, Divider, Spin } from 'antd'
import CommodityImage from './components/commodityImage'
import moment from 'moment'
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl';
import webUrl from '../../config/webUrl';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
const { Option } = Select;
const { Panel } = Collapse;
const { Title, Text, Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
function Index(props) {
    let toChinesNum = (num) => {
        let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        let unit = ["", "十", "百", "千", "万"];
        num = parseInt(num);
        let getWan = (temp) => {
        　　let strArr = temp.toString().split("").reverse();
        　　let newNum = "";
        　　for (var i = 0; i < strArr.length; i++) {
          　　newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
        　　}
         　 return newNum;
       }
       let overWan = Math.floor(num / 10000);
       let noWan = num % 10000;
       if (noWan.toString().length < 4) {　　　　　　noWan = "0" + noWan;　　　 }
       return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
    }
    let colorDict = {
        3:['#E06666','#E99899', '#FFC8B8'],
        4:['#de1c31','#c04851','#f07c82','#eea2a4'],
        5:['#de1c31','#c04851','#f07c82','#eea2a4','#e3b4b8', ]
    }
    let widthDict = {
        3:[450, 550, 750],
        4:[250, 350, 550, 750],
        5:[250, 250,350, 550, 750],
    }
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 12 },
    };
    const [form] = Form.useForm()
    const [baseForm] = Form.useForm()
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an':"7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr':"f115bbea-bb18-439e-bda8-8d1e26719fee",
        "gs":"703247c1-a200-42bc-b70f-742dbab27aa4",
        'qda':"2715f3fa-0dae-4dc7-bcfc-0dd912314e40"
    }
    const dateFormat = 'YYYY-MM-DD';
    // 表头
    const [ headerList , setHearList ] = useState([])
    const [ columns , setColumns] = useState([])
    // 表格是否在加载中
    const [isLoading, setIsLoading] = useState(false)
    // 结束时间
    const [ endTime , setEndTime ] = useState(moment().format(dateFormat))
    // const [ endTime , setEndTime ] = useState(moment().subtract(6, "M").format(dateFormat))
    // 开始时间
    const [ startTime , setStartTime ] = useState(moment().subtract(30, "day").format(dateFormat))
    // const [ startTime , setStartTime ] = useState(moment().subtract(30, "day").subtract(6, "M").format(dateFormat))
    // 店铺名字
    const [shopName, setShopName] = useState('')
    // 查看折线图
    const [isDetail, setIsDetail] = useState(false)
    // 数据
    const [data, setData] = useState([])
    // 折叠框key
    const [collapsekey, setCollapseKey] = useState(['1'])
    // 商品id
    const [goodId, setGoodId] = useState('')
    // sku
    const [sku, setSku] = useState('')
    // 档位
    const [ gear , setGear ] = useState(3)
    // 档位的值
    const [ gearValue , setGearValue ] = useState('')
    // 是否推荐
    const [isRecommend, setIsRecommend] = useState(false)
    // 推荐款
    const [recommendationList, setRecommendationList] = useState([])
    // 是否是推荐款
    const [isRecommendation, setIsRecommendation] = useState(false)
    const [isRecommendationModel, setIsRecommendationModel] = useState(false)
    // 查看爆款梯队
    const [ isLookEchelon, setIsLookEchelon ] = useState(false)
    // 梯队变量
    const [ echelonData , setEchelonData ] = useState([])
    const [ rank , setRank ] = useState(0)
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 折线图数据
    const [legendData, setLegendData] = useState([])
    const [viewsList, setViewsList] = useState([])
    const [visitorList, setVisitorList] = useState([])
    const [goodsNumList, setGoodsNumList] = useState([])
    const [purchaseNumList, setPurchaseNumList] = useState([])
    const [ isRecommendSpinning , setIsRecommendSpinning] = useState(false)
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    // 表格表头
    useEffect(()=>{
        setColumns([
            {
                title: '日期',
                dataIndex: 'data',
                key: 'data',
                align: 'center',
                fixed: 'left',
                width: 70,
                onHeaderCell:(record, index)=>{
                    return (
                        {
                            style:{
                                border:'1px solid black'
                            }
                        }
                    )
                },
                onCell:(record, index)=>{
                    return (
                        {
                            style:{
                                borderLeft:'1px solid black',
                                borderRight:'1px solid black',
                                borderBottom:'1px solid black'
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <span>{text.substring(5, 10)}</span>
                ),
            },
            {
                title: '星期',
                dataIndex: 'week',
                key: 'week',
                align: 'center',
                fixed: 'left',
                width: 40,
                onHeaderCell:(record, index)=>{
                    return (
                        {
                            style:{
                                border:'1px solid black'
                            }
                        }
                    )
                },
                onCell:(record, index)=>{
                    return (
                        {
                            style:{
                                borderLeft:'1px solid black',
                                borderRight:'1px solid black',
                                borderBottom:'1px solid black'
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <span>{text.substring(2, 3)}</span>
                ),
            },
            {
                title: 'TOP款加购明细',
                onHeaderCell:(record, index)=>{
                    return (
                        {
                            style:{
                                background:"#FF1100",
                                color:"#ffffff",
                                border:'1px solid black'
                            }
                        }
                    )
                },
                children: [
                    ...headerList.map((item,index)=>{
                        return {
                            title: item.title,
                            dataIndex: item.key,
                            key: item.key,
                            align: 'center',
                            width: widthDict[gear][index],
                            onHeaderCell:()=>{
                                return (
                                    {
                                        style:{
                                            background: colorDict[gear][index],
                                            border:'1px solid black'
                                        }
                                    }
                                )
                            },
                            onCell:()=>{
                                return (
                                    {
                                        style:{
                                            borderLeft:'1px solid black',
                                            borderRight:'1px solid black',
                                            borderBottom:'1px solid black'
                                        }
                                    }
                                )
                            },
                            render: (text, record, index) => (
                                <div style={{ display: 'flex', justifyContent: "start", flexWrap:"wrap"}}>
                                    {
                                        text.map(item => {
                                            return <div style={{ height: "80px", border: `3px solid ${item.rank == 1 ? 'red' : item.rank == 3 ? 'green' : 'white'}`}}>
                                                <CommodityImage onClick={() => {
                                                    setIsRecommendation(false)
                                                    getProductLineData(item.goods_id, item.sku1)
                                                }} style={{ width: '100%', height: "100%", padding: 7 }}
                                                    shopName={props.location.pathname.split('/')[2]} sku={item.sku1} id={item.goods_id} index={index} />
                                            </div>
                                        })
                                    }
                                </div>
                            ),
                        }
                    })
                ]
            },
        ])
    },[headerList])
   
    // 流量图表
    let flowReport = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: ['访客数']
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: legendData
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '访客数',
                position: 'left',
                offset: 0,
                axisLine: {
                    show: true,
                    onZero: true
                },
            }
        ],
        series: [
            {
                name: '访客数',
                type: 'line',
                stack: '总量',
                data: visitorList
            }
        ]
    }
    // 支付图表
    let payReport = {
        // title: {
        //     text: `${plate}板块实销数据汇总`,
        //     centered:'true'
        // },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: ['支付商品件数', '加购商品件数']
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: legendData
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '支付/加购件数',
                position: 'left',
                offset: 0,
                axisLine: {
                    show: true,
                    onZero: true
                },
            }
        ],
        series: [
            {
                name: '支付商品件数',
                type: 'line',
                stack: '总量',
                data: goodsNumList
            },
            {
                name: '加购商品件数',
                type: 'line',
                stack: '总量',
                data: purchaseNumList
            }
        ]
    }
    // 时间修改的时候
    useEffect(() => {
        // 获取商品排行表格数据接口
        getProductRankingTableData()
    }, [startTime, endTime])
    useEffect(() => {
        setShopName(props.location.pathname.split('/')[2])
        if(props.location.pathname.split('/')[2] == 'iv'){
            setGearValue('5-10-30')
        }else{
            setGearValue('50-80-100')
        }
        // 获取爆款商品推荐数据接口
        // queryPopularProductRecommendation()
        // 获取权限
        getJurisdiction()
    }, [])
    useEffect(() => {
        if (isDetail == true) {
            let reportChart = echarts.init(document.getElementById('flowReport'));
            reportChart.clear();    //清除残留渲染
            // 绘制图表
            reportChart.setOption(flowReport);
            window.addEventListener('resize', () => {
                reportChart.resize()
            });
            let payReportChart = echarts.init(document.getElementById('payReport'));
            payReportChart.clear();    //清除残留渲染
            // 绘制图表
            payReportChart.setOption(payReport);
            window.addEventListener('resize', () => {
                payReportChart.resize()
            });
        }
    }, [isDetail])

    //获取权限代码
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        NbAxios(dataProps, "POST", servicePath.getRoleShopPorts).then(res => {
            if (res.success) {
                setJurisdiction(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取爆款商品推荐数据接口
    const queryPopularProductRecommendation = () => {
        setIsRecommendSpinning(true)
        let dataProps = {
            "shopId": shopIdDict[props.location.pathname.split('/')[2]],
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            
        }
        NbAxios(dataProps, "POST", servicePath.queryPopularProductRecommendation).then(res => {
            setIsRecommendSpinning(false)
            if (res.success) {
                setEchelonData(res.data);
                setIsLookEchelon(true)
                // setRecommendationList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取折线图数据接口
    const getProductLineData = (id, sku) => {
        setIsLoading(true)
        setIsRecommendSpinning(true)
        let dataProps = {
            "shopId": shopIdDict[props.location.pathname.split('/')[2]],
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            'start_time': startTime,
            'end_time': endTime,
            'goods_id': id
        }
        NbAxios(dataProps, "POST", servicePath.getProductLineData).then(res => {
            setIsLoading(false)
            setIsRecommendSpinning(false)
            if (res.success) {
                setGoodId(id)
                setSku(sku)
                baseForm.setFieldsValue(res.data.item_info)
                setLegendData(res.data.date_list);
                // 浏览量
                setViewsList(res.data.views_list);
                // 访客数
                setVisitorList(res.data.visitor_num_list);
                // 支付商品件数
                setGoodsNumList(res.data.pay_goods_num_list);
                // 加购商品件数
                setPurchaseNumList(res.data.purchase_num_list)
                setIsDetail(true)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }


    // 获取商品排行表格数据接口
    const getProductRankingTableData = () => {
        setIsLoading(true)
        if(gearValue == ''){
            let gearList = []
            if(props.location.pathname.split('/')[2] == 'iv'){
                gearList = '5-10-30'.split('-')
            }else{
                gearList = '50-80-100'.split('-')
            }
            let numberList = gearList.map(Number);
            let dataProps = {
                "shopId": shopIdDict[props.location.pathname.split('/')[2]],
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                'start_time': startTime,
                'end_time': endTime,
                "gear": gear,
                "num_list": numberList
            }
            NbAxios(dataProps, "POST", servicePath.getProductRankingTableData).then(res => {
                setIsLoading(false)
                if (res.success) {
                    setHearList(res.data['head_list'])
                    setData(res.data['dict_list']);
                } else {
                    message.warn(res.errorMsg)
                }
            })
            
        }else{
            let gearList = gearValue.split('-')
            if (gearList.length == gear){
                let numberList = gearList.map(Number);
                let flag = 1
                numberList.map(item=>{
                    if(isNaN(item)){
                        flag = 0
                    }
                })
                if(flag){
                    let dataProps = {
                        "shopId": shopIdDict[props.location.pathname.split('/')[2]],
                        "roleId": cookie.load('role'),
                        "shopList": cookie.load('shopList'),
                        'start_time': startTime,
                        'end_time': endTime,
                        "gear": gear,
                        "num_list": numberList
                    }
                    NbAxios(dataProps, "POST", servicePath.getProductRankingTableData).then(res => {
                        setIsLoading(false)
                        if (res.success) {
                            setHearList(res.data['head_list'])
                            setData(res.data['dict_list']);
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }else{
                    message.warn('请输入正确的档位数值')
                }
                
            
            }else{
                let mess = ''
                if(gear == 3){
                    mess = '请输入xx-xx-xx格式的档位数值'
                } else if(gear == 4){
                    mess = '请输入xx-xx-xx-xx格式的档位数值'
                } else if(gear == 5){
                    mess = '请输入xx-xx-xx-xx-xx格式的档位数值'
                }
                setIsLoading(false)
                message.warn(mess)
            }
        }
        
       
    }

    // 时间切换
    const onTimeChange = (date, dateString) => {
        if (date === null || date === '' || date === undefined) {
            message.warn('请选择正确的时间')
            return false
        }
        setStartTime(dateString[0])
        setEndTime(dateString[1])
    }

    // 添加潜力推荐
    const recommendation = () => {
        // let dataProps = {
        //     "shopId": shopIdDict[props.location.pathname.split('/')[2]],
        //     "roleId": cookie.load('role'),
        //     "shopList": cookie.load('shopList'),
        //     'item_id': goodId,
        //     'sku': sku
        // }
        // NbAxios(dataProps, "POST", servicePath.createPopularProductRecommendation).then(res => {
        //     if (res.success) {
        //         message.success('添加为潜力推荐成功')
        //         queryPopularProductRecommendation()
        //         setGoodId('')
        //         setSku('')
        //         setIsDetail(false)
        //     } else {
        //         message.warn(res.errorMsg)
        //     }
        // })
        form.resetFields()
        let obj = {
            'item_id': goodId,
            'sku': sku
        }
        form.setFieldsValue(obj)
        setIsRecommend(true)
    }

    // 取消潜力推荐
    const deleteRecommendation = () => {
        let dataProps = {
            "shopId": shopIdDict[props.location.pathname.split('/')[2]],
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            'item_id': goodId,
            'sku': sku
        }
        NbAxios(dataProps, "POST", servicePath.deletePopularProductRecommendation).then(res => {
            if (res.success) {
                message.success(`成功将其从第${toChinesNum(rank)}梯队移除`)
                queryPopularProductRecommendation()
                setGoodId('')
                setSku('')
                setRank(0)
                setIsDetail(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 潜力推荐
    const onRecommend = (value) => {
        setEffective(1)
        value.shopId = shopIdDict[props.location.pathname.split('/')[2]]
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        NbAxios(value, "POST", servicePath.createPopularProductRecommendation).then(res => {
            setEffective(0)
            if (res.success) {
                message.success(`成功将其设置为第${toChinesNum(value.rank)}梯队`)
                queryPopularProductRecommendation()
                form.resetFields()
                setIsDetail(false)
                setIsRecommend(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 拖拽开始函数
    const onDragStart = (item, index)=>{
        setGoodId(item.item_id)
        setSku(item.sku)
        setRank(index)
    }

    // 拖拽进入函数
    const onDragEnter = (rank)=>{
        if(jurisdiction.indexOf('commodity::operationRecommendation') != -1  && goodId != '' && sku != '' && isRecommendationModel == false){
            setIsRecommendationModel(true)
            confirm({
                centered:true,
                title: `是否将其设置为第${toChinesNum(rank)}梯队`,
                icon: <ExclamationCircleOutlined />,
                content: '',
                okText:"确定",
                cancelText:"取消",
                onOk() {
                    setIsRecommendSpinning(true)
                    let dataProps = {
                        "shopId": shopIdDict[props.location.pathname.split('/')[2]],
                        "roleId": cookie.load('role'),
                        "shopList": cookie.load('shopList'),
                        'item_id': goodId,
                        'sku': sku,
                        'rank':rank
                    }
                    NbAxios(dataProps, "POST", servicePath.createPopularProductRecommendation).then(res => {
                        if (res.success) {
                            message.success(`成功将其设置为第${toChinesNum(rank)}梯队`)
                            queryPopularProductRecommendation()
                            setGoodId('')
                            setSku('')
                            setIsDetail(false)
                            setIsRecommendationModel(false)
                        } else {
                            setIsRecommendSpinning(false)
                            message.warn(res.errorMsg)
                        }
                    })
                },
                onCancel() {
                    setIsRecommendationModel(false)
                    message.success(`取消将其设置为第${toChinesNum(rank)}梯队`)
                },
            });
        }

    }


    return (
        <div>
            <div style={{
                display: 'flex',
                backgroundColor: "#fff",
                borderBottom: "solid 2px #ccc",
                marginBottom: 10,
                position: 'fixed',
                top: 40,
                zIndex: 99,
                justifyContent: 'space-between',
                alignItems: 'center',
                width:'100vw'
            }}>
                <div style={{ display: 'flex' }}>
                    <Title level={2} style={{ marginLeft: 50, marginTop: 10 }}>{shopName}爆款加购跟踪</Title>
                    <div style={{ marginTop: 20, marginLeft: 10 }}>
                        <Tooltip placement="right" title="红色边框: 相比于前一天，加购款数上升；绿色边框: 相比于前一天，加购款数下降">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <Button type="primary" style={{marginRight:10}} onClick={()=>{
                        queryPopularProductRecommendation()
                    }} disabled={isRecommendSpinning ? true : false}>查看爆款梯队</Button>
                    <b style={{lineHeight:'32px', marginRight:10}}>档位:</b>
                    <Select value={gear} onChange={(value)=>{
                        setGear(value)
                        setGearValue('')
                    }}>
                        <Option value={3}>3</Option>
                        <Option value={4}>4</Option>
                        <Option value={5}>5</Option>
                    </Select>
                    <Tooltip title={
                            gear == 3 ? '请输入xx-xx-xx格式的档位数值' :
                            gear == 4 ?   '请输入xx-xx-xx-xx格式的档位数值'
                            :'请输入xx-xx-xx-xx-xx格式的档位数值'
                        }>
                        <Input value={gearValue} style={{height:'32px', width:'200px'}} onChange={(e)=>setGearValue(e.target.value)} />
                    </Tooltip>
                    <Button style={{marginRight:20}} disabled={gearValue.split('-').length == gear ? false:true} onClick={()=>getProductRankingTableData()}>确定</Button>
                    {/* <Button type="primary" onClick={() => setIsRecommend(true)}
                    disabled={jurisdiction.indexOf('commodity::operationRecommendation') != -1 ? false : true}>添加潜力推荐</Button> */}
                    <RangePicker
                        locale={locale}
                        style={{ height: 32, marginRight: 20, marginLeft: 20 }}
                        defaultValue={[moment(startTime, dateFormat), moment(endTime, dateFormat)]}
                        format={dateFormat}
                        onChange={onTimeChange}
                    />
                </div>
            </div>
            {/* <div onDragEnter={()=>onDragEnter()}  style={{ marginLeft: 10, marginRight: 10 }}>
                <Collapse activeKey={collapsekey} onChange={(key) => setCollapseKey(key)}>
                    <Panel header="潜力款推荐" key="1">
                        <div style={{ width: '90vw', overflow: 'auto', display: 'flex' }}>
                            {
                                recommendationList.map(item => {
                                    return <CommodityImage style={{ width: '100px', height: '100px', marginRight: 10 }}
                                        onClick={() => {
                                            setIsRecommendation(true)
                                            getProductLineData(item.item_id, item.sku)
                                        }}
                                        shopName={props.location.pathname.split('/')[2]} sku={item.sku} id={item.item_id} index={'recommendList'} />
                                })
                            }
                        </div>
                    </Panel>
                </Collapse>
            </div> */}
            <div style={{marginTop:80}}>
                <Table
                    loading={isLoading}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    scroll={{ x: 100, y: '65vh' }}
                />
            </div>

            {/* 查看爆款梯队 */}
            <Modal
                visible={isLookEchelon}
                onCancel={()=>{ 
                    setIsLookEchelon(false)
                }}
                closable={false}
                footer={null}
                width={1500}
                zIndex={800}
            >
                <Spin spinning={isRecommendSpinning} tip="数据加载中.....">
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button type="primary" onClick={()=>{
                            setIsRecommend(true)
                        }}>添加爆款梯队</Button>
                    </div>
                    {
                        echelonData.map((i, index)=>{
                            return <div>
                                 <Divider orientation="left">{`第${toChinesNum(index+1)}梯队`}</Divider>
                                 <div onDragEnter={()=>{
                                    if(rank != index){
                                        onDragEnter(index + 1)
                                    }
                                 }} style={{display:'flex', width:'100%', overflow:'auto', height:120, border:'1px solid #ccc'}}>
                                    {
                                        i.map(item=>{
                                            return <CommodityImage onDragStart={()=>onDragStart(item, index)} style={{ width: '100px', height: '100px', marginRight: 10, marginTop:5, marginLeft:5 }}
                                            onClick={() => {
                                                setIsRecommendation(true)
                                                setRank(index+1)
                                                getProductLineData(item.item_id, item.sku)
                                            }}
                                            shopName={props.location.pathname.split('/')[2]} sku={item.sku} id={item.item_id} index={'firstEchelon'} />
                                        })
                                    }
                                 </div>
                            </div>
                        })
                    }
                </Spin>
               
            </Modal>
            <Modal
                title="单款数据走势"
                visible={isDetail}
                onCancel={() => {
                    setSku('')
                    setGoodId('')
                    setIsDetail(false)
                }}
                footer={[]}
                width={'1700px'}
                centered={true}
            >
                <div style={{display:'flex'}}>
                    <Button type="primary" onClick={() => recommendation()} style={{ display: isRecommendation ? 'none' : '' }}
                    disabled={jurisdiction.indexOf('commodity::operationRecommendation') != -1 ? false : true}>添加梯队</Button>
                    <Button type="primary" onClick={() => deleteRecommendation()} style={{ display: isRecommendation ? '' : 'none' }}
                    disabled={jurisdiction.indexOf('commodity::operationRecommendation') != -1 ? false : true}>{`将其从第${toChinesNum(rank)}梯队移除`}</Button>
                    {/* <Button type="link" style={{marginLeft:10}} onClick={()=>props.history.push({ 'pathname': '/app/base/skuAnaysis', 'state': { 'goodId': goodId, 'sku': sku } }) }>查看单款数据分析</Button> */}
                    <Button type="link" style={{marginLeft:10}} onClick={()=>{
                        window.open(`${webUrl}${`/app/${props.location.pathname.split('/')[2]}/base/skuAnaysis`}?goodId=${goodId}`)
                    } }>查看单款数据分析</Button>
                </div>
                <div style={{ marginTop: 10, display: 'flex' }}>
                    <div style={{width:'100px', height:"100px", marginLeft:10, marginRight:20}}>
                        <CommodityImage style={{ width: '100%', height: "100%" }}
                                            shopName={props.location.pathname.split('/')[2]} sku={sku} id={goodId} index={'detail'} />
                    </div>
                    <div style={{height:'100px', alignItems:'center'}}>
                        <Form form={baseForm}>
                            <Row>
                                <Col span={6}>
                                    <Form.Item label="款号">
                                        <Paragraph copyable={{ text: baseForm.getFieldValue('sku') }}>
                                            <Text type="secondary">{baseForm.getFieldValue('sku')}</Text>
                                        </Paragraph>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="商品id">
                                        <Paragraph copyable={{ text: baseForm.getFieldValue('item_id') }}>
                                            <Text type="secondary">{baseForm.getFieldValue('item_id')}</Text>
                                        </Paragraph>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="子类目">
                                        <Text type="secondary">{baseForm.getFieldValue('category2')}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="季节">
                                        <Text type="secondary">{baseForm.getFieldValue('season')}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="产品线">
                                        <Text type="secondary">{baseForm.getFieldValue('product_line')}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="品牌">
                                        <Text type="secondary">{baseForm.getFieldValue('brand_code')}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="上线时间">
                                        <Text type="secondary">{baseForm.getFieldValue('new_time')}</Text>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="吊牌价">
                                        <Text type="secondary">{baseForm.getFieldValue('price')}</Text>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent:'space-around' }}>
                    <div id="flowReport" style={{ width: '50%', height: '38vh', marginLeft: 20 }}></div>
                    <div id="payReport" style={{ width: '50%', height: '38vh', marginLeft: 20 }}></div>
                </div>
            </Modal>

            <Modal
                title="设置爆款梯队"
                visible={isRecommend}
                onCancel={() => {
                    message.success('取消设置爆款梯队')
                    setIsRecommend(false)
                }}
                footer={[]}
                centered={true}
            >
                <Form form={form} {...layout} labelAlign='left' onFinish={onRecommend}>
                    <Form.Item label="商品图片">
                        <CommodityImage style={{ width: '100px', height: '100px' }}
                            shopName={props.location.pathname.split('/')[2]} sku={sku} id={goodId} index={'recommend'} />
                    </Form.Item>
                    <Form.Item label="商品Id" name="item_id"
                        rules={[{ required: true, message: '请输入商品Id！' }]}
                    >
                        <Input onChange={(e) => setGoodId(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="商品SKU" name="sku"
                        rules={[{ required: true, message: '请输入商品SKU！' }]}
                    >
                        <Input onChange={(e) => setSku(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="梯队" name="rank"
                        rules={[{ required: true, message: '请选择梯队！' }]}
                    >
                        <Select>
                            <Option value={1}>第一梯队</Option>
                            <Option value={2}>第二梯队</Option>
                            <Option value={3}>第三梯队</Option>
                            <Option value={4}>第四梯队</Option>
                        </Select>
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="primary" htmlType="submit" disabled={effective == 1 ? true : false} >确认</Button>
                    </div>
                </Form>
            </Modal>

        </div>
    )

}

export default Index