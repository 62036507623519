import React, { useState, useEffect } from "react";
import { Table, message, Button, Modal, Form, Input, Select, Spin, Tooltip, } from "antd";
import FormItem from "antd/lib/form/FormItem";
import servicePath from '../../config/apiUrl'
import Highlighter from "react-highlight-words";
import NbAxios from "../../config/utils/nbAxios";
import cookie from 'react-cookies'
function Index() {
  // 设置选择器
  const { Option } = Select;
  // 设置表单布局
  const Layout = {
    labelCol: { span: 4 },
    wrapperCol: { offset: 1, span: 16 },
  };
  // 设置修改供应商表的数据源
  const [form] = Form.useForm();
  // 搜索框设置
  const { Search } = Input;
  // 设置页码变量
  const [page, setPage] = useState(1);
  // 设置总数变量
  const [total, setTotal] = useState();
  // 设置加载中变量
  const [loading, setloading] = useState(false);
  // 设置搜索内容
  const [supplier_select, setSupplier_select] = useState("");
  // 设置关键字列表
  const [select_list, setSelect_list] = useState([]);
  // 供应商列表数据源
  const [data, setdata] = useState([]);
  // 设置选中的分类
  const [selectedClassify, setSelectedClassify] = useState("");
  // 设置编辑对话框可不可见
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  // 设置新增对话框可不可见
  const [isInsertModalVisible, setIsInsertModalVisible] = useState(false);
  // 页面设置
  const paginationProps = {
    pageSize: 10, // 每页条数
    showSizeChanger: false,
    current: page,
    total: total,
    onChange: (page) => {
      //改变页码
      setPage(page);
    },
  };
  // 监听并调用页面初始化所需要的函数
  useEffect(() => {
    initialization();
  }, [page]);
  // 监听关键字
  useEffect(() => {
    setloading(true);
    // 把搜索关键字以空格分隔并存到关键字列表里
    setSelect_list(supplier_select.split(/[\s\n]/));
    if (page !== 1) {
      setPage(1);
    } else {
      initialization();
    }
  }, [supplier_select]);
  // 供应商表格设置
  const columns = [
    {
      title: "分类",
      dataIndex: "classify",
      key: "classify",
      width: 70,
      align: "center",
      // 关键字高亮显示
      render: (text, record, index) => {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={select_list}
            autoEscape
            textToHighlight={text ? text.toString() : "-"}
          />
        );
      },
    },
    {
      title: "供应商",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 70,
      align: "center",
      // 关键字高亮显示
      render: (text, record, index) => {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={select_list}
            autoEscape
            textToHighlight={text ? text.toString() : "-"}
          />
        );
      },
    },
    {
      title: "联系人",
      dataIndex: "contact",
      key: "contact",
      width: 70,
      align: "center",
      // 关键字高亮显示
      render: (text, record, index) => {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={select_list}
            autoEscape
            textToHighlight={text ? text.toString() : "-"}
          />
        );
      },
    },
    {
      title: "联系方式",
      dataIndex: "contact_information",
      key: "contact_information",
      width: 100,
      align: "center",
      // 关键字高亮显示
      render: (text, record, index) => {
        return (
          
              <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={select_list}
            autoEscape
            textToHighlight={text ? text.toString() : "-"}
          />

        );
      },
    },
    {
      title: "地址",
      dataIndex: "address",
      key: "address",
      width: 70,
      // fixed: "left",
      align: "center",
      ellipsis: true,    // 超长长度的字体以省略号显示
      // 关键字高亮显示
      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft"  title={text}>
             <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={select_list}
                autoEscape
                textToHighlight={text ? text.toString() : "-"}
              />
          </Tooltip>
        );
      },
    },
    {
      title: "编辑",
      dataIndex: "edit",
      key: "edit",
      width: 70,
      fixed: "left",
      align: "center",
      // 关键字高亮显示
      render: (text, record, index) => {
        return <a onClick={() => showEditModel(record)}>编辑</a>;
      },
    },
  ];
  // 编辑对话框显示函数
  const showEditModel = (record) => {
    form.resetFields();
    form.setFieldsValue(record);
    setIsEditModalVisible(true);
  };
  // 编辑对话框取消函数
  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };
  // 搜索框函数
  const onSearch = (value) => {
    setSupplier_select(value);
  };
  // 新增供应商对话框函数
  const showInsertModel = () => {
    setIsInsertModalVisible(true);
  };
  // 新增对话框取消函数
  const handleInsertCancel = () => {
    setIsInsertModalVisible(false);
  };
  // 编辑提交函数
  const supplierEdit = (value) => {
    let dataProps = {};
    dataProps = value;
    dataProps.id = form.getFieldValue("id");
    dataProps.shopList = cookie.load('shopList')
    dataProps.roleId = cookie.load('role')
    dataProps.name = cookie.load('name')
    NbAxios(dataProps, "POST", servicePath.supplier_update).then((res) => {
      if (res.success) {
        message.success("修改成功")
        initialization();
        setIsEditModalVisible(false);
      } else {
        message.warn(res.errorMsg);
      }
    });
  };
  // 新增提交函数
  const supplierInsert = (value) => {
    let dataProps = {};
    dataProps = value;
    dataProps.shopList = cookie.load('shopList')
    dataProps.roleId = cookie.load('role')
    dataProps.name = cookie.load('name')
    NbAxios(dataProps, "POST", servicePath.supplier_insert).then((res) => {
      if (res.success) {
        message.success("添加成功")
        initialization();
        setIsInsertModalVisible(false);
      } else {
        message.warn(res.errorMsg);
      }
    });
  };
  // 初始化函数
  function initialization() {
    setloading(true);
    let dataProps = {};
    dataProps.supplier_select = supplier_select;
    dataProps.page = page;
    dataProps.Total = total;
    dataProps.shopList = cookie.load('shopList')
    dataProps.roleId = cookie.load('role')
    dataProps.name = cookie.load('name')
    // 调用初始化界面接口
    NbAxios(dataProps, "POST", servicePath.supplier_all).then((res) => {
      setloading(false);
      if (res.success) {
        setdata(res.data);
        setTotal(res.total);
      } else {
        message.warn(res.errorMsg);
      }
    });
  }
  return (
    <div>
      <Spin tip="Loading..." spinning={loading}>
        {/* 搜索框 */}
        <div style={{ display: "flex" }}>
          <Search
            placeholder="按关键字搜索 以空格分开可搜索多个关键字"
            allowClear
            onSearch={onSearch}
            style={{ width: 400 }}
          />
          <Button
            type="primary"
            onClick={showInsertModel}
            style={{ marginLeft: 20 }}
          >
            新增
          </Button>
        </div>
        <div>
          {/* 供应商列表 */}
          <Table
            dataSource={data}
            columns={columns}
            // scroll={{ x: 1600 }}
            rowKey={"id"}
            pagination={paginationProps}
          />
        </div>
      </Spin>
      {/* 编辑供应商对话框 */}
      <Modal
        title="编辑供应商窗口"
        visible={isEditModalVisible}
        onCancel={handleEditCancel}
        centered={true}
        footer={[null, null]}
      >
        <Form form={form} onFinish={supplierEdit}>
          <FormItem name="supplierName" label="供应商" {...Layout} rules={[{ required: true, message: "请输入供应商" }]}>
            <Input />
          </FormItem>
          <FormItem name="classify" label="分类" {...Layout} rules={[{ required: true, message: "请输入分类" }]}>
            <Select
              placeholder="请选择分类"
              onChange={(value) => setSelectedClassify(value)}
              value={selectedClassify}
            >
              <Option value="面料">面料</Option>
              <Option value="辅料">辅料</Option>
              <Option value="里料">里料</Option>
              <Option value="加工">加工</Option>
            </Select>
          </FormItem>
          <FormItem name="contact" label="联系人" {...Layout}>
            <Input />
          </FormItem>
          <FormItem name="contact_information" label="联系方式" {...Layout}>
            <Input />
          </FormItem>
          <FormItem name="address" label="地址" {...Layout}>
            <Input />
          </FormItem>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button type="primary" onClick={handleEditCancel}>
              关闭
            </Button>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </div>
        </Form>
      </Modal>
      {/* 新增供应商对话框 */}
      <Modal
        title="新增供应商窗口"
        visible={isInsertModalVisible}
        onCancel={handleInsertCancel}
        centered={true}
        footer={[null, null]}
      >
        <Form onFinish={supplierInsert}>
          <FormItem name="supplierName" label="供应商" {...Layout} rules={[{ required: true, message: "请输入供应商" }]}>
            <Input />
          </FormItem>
          <FormItem name="classify" label="分类" {...Layout} rules={[{ required: true, message: "请输入分类" }]}>
            <Select
              placeholder="请选择分类"
              onChange={(value) => setSelectedClassify(value)}
              value={selectedClassify}
            >
              <Option value="面料">面料</Option>
              <Option value="辅料">辅料</Option>
              <Option value="里料">里料</Option>
              <Option value="加工">加工</Option>
            </Select>
          </FormItem>
          <FormItem name="contact" label="联系人" {...Layout}>
            <Input />
          </FormItem>
          <FormItem name="contact_information" label="联系方式" {...Layout}>
            <Input />
          </FormItem>
          <FormItem name="address" label="地址" {...Layout}>
            <Input />
          </FormItem>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button type="primary" onClick={handleEditCancel}>
              关闭
            </Button>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
export default Index;
