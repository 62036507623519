import React, { useEffect } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

function Index({
    chooseCategory,
    categoryLegend,
    categoryXAxis,
    categoryData
}){

    // 核心品类图表
    let categoryChart = {
        title: {
          text: `${chooseCategory}对比`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: categoryLegend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: categoryXAxis,
          axisLabel:{
            rotate:60,
        }
        },
        yAxis: {
          type: 'value'
        },
        series: categoryData
      }
      useEffect(()=>{
        let reportChart = echarts.init(document.getElementById('categoryChart'));
        reportChart.clear()
        // 绘制图表
        reportChart.setOption(categoryChart);
        // 图表点击事件
        reportChart.on('click', function(param){
            console.log(param.seriesName);
        })
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[chooseCategory,categoryData])

    return (
        <div id="categoryChart" style={{width:"100%", height:'300px'}}></div>
    )

}

export default Index