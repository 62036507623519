import React, {useState, useEffect, useCallback} from 'react';
import {Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Tag, Form, Select, Space} from 'antd'
import {EditOutlined, DeleteOutlined, RedoOutlined, SearchOutlined} from '@ant-design/icons';
import servicePath from '../../config/apiUrl'
import Highlighter from 'react-highlight-words';
import axios from 'axios'
import cookie from 'react-cookies'
import '../../static/css/Table.css'
import '../../static/css/index.css'
const {confirm} = Modal
const {Option} = Select

function Data(props){

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    //页面
    const [page, setPage] = useState(1)

    //数据
    const [ data , setData ] = useState([])

    //备份
    const [backups, setBackups] = useState([])

    //接口数据
    const [portData , setPortData ] = useState([])

    //数据权限id
    const [ id , setId ] = useState(-1)

    //数据权限名字
    const [ name , setName ] = useState('')

    //接口id
    const [ portId , setPortId ] = useState(-1)

    //权限代码
    const [ permission , setPermission ] = useState('')

    //是否添加数据权限
    const [ addData , setAddData ] = useState(false)

    //是否修改数据权限
    const [ updateData , setUpdateData ] = useState(false)

    // 筛选字段
    const [searchText, setSearchText] = useState('')

    // 筛选关键词
    const [searchedColumn, setSearchedColumn] = useState('')

    // 筛选信息
    const [searchMessage, setSearchMessage] = useState('暂无筛选信息')

    //第一次筛选的关键字
    const [column1, setColumn1] = useState('')

    //第一次筛选的值
    const [text1, setText1] = useState('')

    //筛选次数
    const [count, setCount] = useState(0)

    // 筛选功能
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        // searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
             </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
             </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                // setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    // 根据筛选信息更新数据
    useEffect(() => {
        if (searchedColumn == "name") {
            if (count == 1) {
                let newData = backups.filter(item => item.name.toString().toLowerCase().includes(searchText.toLowerCase()))
                setData(newData)
            }
        } else {
            setData(backups)
            let newMessage = '暂无筛选信息'
            setSearchMessage(newMessage)
        }
    }, [searchText, searchedColumn])

    // 筛选选择
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        // 将次数保存到新的值
        let newCount = count
        if (newCount == 0) {
            //第一次筛选，之前没有关键字查询，直接次数加一
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
            //保存第一次筛选的关键字和值
            setColumn1(dataIndex)
            setText1(selectedKeys[0])
            newCount = newCount + 1;
            let newMessage = "筛选条件:在"+"\xa0\xa0"+"权限名称"+"\xa0\xa0"+"筛选" + "\xa0\xa0" + selectedKeys[0]
            setSearchMessage(newMessage)
        } else {
            //有一个关键字查询，判断是否是同一个字段查询
            if (column1 == dataIndex) {
                //如果是都一个字段，次数不进行改变
                setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                setColumn1(dataIndex)
                setText1(selectedKeys[0])
                let newMessage = "筛选条件:在"+"\xa0\xa0"+"权限名称"+"\xa0\xa0"+"筛选" + "\xa0\xa0" + selectedKeys[0]
                setSearchMessage(newMessage)
            }
        }
        //保存次数
        setCount(newCount)
    };

    // 筛选清空
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
        setSearchedColumn('')
        // 次数减一
        let newCount = count - 1;
        setCount(newCount)
    };


    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger:false,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    const columns = [
        {
            title: '权限名字',
            key: 'name',
            dataIndex: 'name',
            width: '200px',
            align: 'center',
            ...getColumnSearchProps('name'),
        },
        {
            title: '所在接口',
            key: 'port',
            dataIndex: 'port',
            width: '200px',
            align: 'center'
        },
        {
            title: '权限代码',
            key: 'permission',
            dataIndex: 'permission',
            width: '200px',
            align: 'center'
        },
        {
            title: '状态',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == 1 ? <Tag color="red">禁用</Tag>
                    : <Tag color="blue">正常</Tag>
            ),
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={()=>openUpdate(index)}/>
            ),
        },
        {
            title: '删除/恢复',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == 1 ? <RedoOutlined onClick={()=>recoverData(index)}/>
                    : <DeleteOutlined onClick={()=>deleteData(index)}/>
            ),
        }
    ]

    //获取所有数据权限
    useEffect(()=>{
        queryAllData()
        queryPort()
    },[])

    //查询全部的数据权限
    const queryAllData=()=>{
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryAllDataPermission,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    if (count == 0) {
                        // 没有筛选的时候，数据就不进行处理
                        setData(res.data['data']);
                        setBackups(res.data['data'])
                    } else {
                        // 有筛选的时候，数据进行过滤
                        setBackups(res.data['data'])
                        let newData = res.data['data'].filter(item => item.name.toString().toLowerCase().includes(searchText.toLowerCase()))
                        setData(newData)
                    }
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    //查询所有的接口
    const queryPort = ()=>{
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryNorPort,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                
                if (res.data['success']) {
                    setPortData(res.data['data']);
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    //确认添加
    const addOk = ()=>{
       
        if(name == ''){
            message.error('数据权限名字不能为空')
        }else if(permission == ''){
            message.error('数据权限代码不能为空')
        }else if(portId == ''){
            message.error('接口必选')
        }else{
            let dataProps = {
                'name':name,
                'permission_id':portId,
                'permission':permission,
                "role_Id": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method:'POST',
                url: servicePath.addDataPermission,
                data:dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res=>{
                    if (res.data['success']) {
                        // 提示用户成功添加菜单
                        message.success('成功添加数据权限')
                        setName('')
                        setPermission('')
                        setPortId(-1)
                        form.resetFields();
                        setAddData(false)
                        queryAllData()
                    }else{
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )   
        }
       
    }

    //取消添加
    const addCancel = ()=>{
        message.success('取消添加数据权限')
        setAddData(false)
    }

    //打开修改窗口
    const openUpdate = (index)=>{
        index = (page - 1) * 10 + index
        form.resetFields();
        setId(data[index]['id'])
        setName(data[index]['name'])
        setPortId(data[index]['permission_id'])
        setPermission(data[index]['permission'])
        setUpdateData(true)
    }

    //确认修改
    const updateOk = ()=>{
        if(name == ''){
            message.error('数据权限名字不能为空')
        }else if(permission == ''){
            message.error('数据权限代码不能为空')
        }else if(portId == ''){
            message.error('接口必选')
        }else{
            let dataProps = {
                'id':id,
                'name':name,
                'permission_id':portId,
                'permission':permission,
                "role_Id": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method:'POST',
                url: servicePath.updateDataPermission,
                data:dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res=>{
                    if (res.data['success']) {
                        // 提示用户成功添加菜单
                        message.success('成功修改数据权限')
                        setName('')
                        setPermission('')
                        setPortId(-1)
                        form.resetFields();
                        setUpdateData(false)
                        queryAllData()
                    }else{
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )   
        }
    }

    //取消修改
    const updateCancel = ()=>{
        message.success('取消修改数据权限')
        setUpdateData(false)
    }

    //禁用数据权限
    const deleteData = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        let id = data[index]['id']

        confirm({
            title: '确定禁用这个数据权限',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                //向后端传递的参数
                let dataProps = {
                    'id': id.toString(),
                    "role_Id": cookie.load('role'),
                    "shopId": cookie.load('shopList')
                }
                axios({
                    method: 'POST',
                    url: servicePath.deleteDataPermission,
                    data: dataProps,
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization':cookie.load('token')
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功禁用这个数据权限")
                            queryAllData()
                            queryPort()
                        }else {
                            if(res.data['errorCode'] == '10001'){
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            }
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消删除这个数据权限')
            }
        })
    }

    // 恢复数据权限
    const recoverData = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        let id = data[index]['id']

        confirm({
            title: '确定恢复这个数据权限',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                //向后端传递的参数
                let dataProps = {
                    'id': id.toString(),
                    "role_Id": cookie.load('role'),
                    "shopId": cookie.load('shopList')
                }
                axios({
                    method: 'POST',
                    url: servicePath.recoverDataPermission,
                    data: dataProps,
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization':cookie.load('token')
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功恢复这个数据权限")
                            queryAllData()
                            queryPort()
                        }else {
                            if(res.data['errorCode'] == '10001'){
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            }
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消恢复这个数据权限')
            }
        })
    }


    

    return (
        <>
            {/* 导航区域 */}
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.02}}>
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                    <Breadcrumb.Item>数据权限管理</Breadcrumb.Item>
                </Breadcrumb>
            </div> 
             {/* 按钮区域 */}
             <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.06}}>
                <Row>
                    <Col span={3}>
                        <Button type="primary" onClick={()=>setAddData(true)}>新增数据权限</Button>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06}}>
                <div style={{ marginTop: '20px', marginBottom: "10px" }}><Tag color="pink" style={{ height: "30px", lineHeight: "30px", fontSize: "20px" }}>{searchMessage}</Tag></div>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={data}
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                />
            </div>
              {/* 添加菜单 */}
              <Modal
                title="添加数据权限"
                visible={addData}
                onOk={addOk}
                onCancel={addCancel}
                okText="确认添加"
                cancelText="取消添加"
                >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="权限名字" name="name">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写权限名字"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="请填写权限名字"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="权限代码" name="path">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写权限代码"
                            placement="topLeft"
                        >
                            <Input
                                id="path"
                                size="default"
                                placeholder="请填写权限代码"
                                onChange={(e) => {
                                    setPermission(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="所在接口" name="shop">
                        <Tooltip
                            trigger={['focus']}
                            title="请所在接口"
                            placement="topLeft"
                        >
                            <Select placeholder="请所在接口" style={{width: 315}} onChange={(value)=>{setPortId(value)}}>
                                {
                                    portData.map(function (item) {
                                        return (
                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                  
                </Form>
            </Modal>

             {/* 添加菜单 */}
             <Modal
                title="修改数据权限"
                visible={updateData}
                onOk={updateOk}
                onCancel={updateCancel}
                okText="确认修改"
                cancelText="取消修改"
                >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="权限名字"  name="name">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写权限名字"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="请填写权限名字"
                                value = {name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="权限代码"  name="path">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写权限代码"
                            placement="topLeft"
                        >
                            <Input
                                id="path"
                                size="default"
                                placeholder="请填写权限代码"
                                value={permission}
                                onChange={(e) => {
                                    setPermission(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="所在接口" name="shop">
                        <Tooltip
                            trigger={['focus']}
                            title="请所在接口"
                            placement="topLeft"
                        >
                            <Select value={portId} placeholder="请所在接口" style={{width: 315}} onChange={(value)=>{setPortId(value)}}>
                                {
                                    portData.map(function (item) {
                                        return (
                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                  
                </Form>
            </Modal>
        </>
    )

}

export default Data