import React, { useState, useCallback, useEffect } from 'react';
import { DatePicker, Row, Col, Button, Table, Drawer, Spin, Input, Select, Form, message, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons';
import axios from 'axios'
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl'
import '../../../static/css/index.css'
import locale from 'antd/es/date-picker/locale/zh_CN';
import NbAxios from '../../../config/utils/nbAxios'
const { RangePicker } = DatePicker
const { Option } = Select
function CommodityCrowd(props) {

    const [form] = Form.useForm();
    // 地域form
    const [form1] = Form.useForm();
    // 省份form
    const [form2] = Form.useForm();
    // 城市form
    const [form3] = Form.useForm();

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    }

    //数据
    const [date, setDate] = useState([])

    //页面
    const [page, setPage] = useState(1)

    // 总数
    const [total, setTotal] = useState(0)

    //  用户名
    const [customerName, setCustomerName] = useState('')

    // 订单编号
    const [ orderId , setOrderId  ] = useState('')

    // 店铺
    const [shop, setShop] = useState(['京东'])

    // 订单创建时间开始时间
    const [orderCreateTimeStart, setOrderCreateTimeStart] = useState('')

    // 订单创建时间结束时间
    const [orderCreateTimeEnd, setOrderCreateTimeEnd] = useState('')

    // 订单支付时间开始时间
    const [orderPayTimeStart, setOrderPayTimeStart] = useState('')

    // 订单支付时间结束时间
    const [orderPayTimeEnd, setOrderPayTimeEnd] = useState('')

    // 收货地址
    const [shdz, setShdz] = useState('')

    // sku
    const [skuOutside, setSkuOutside] = useState('')

    // 省份
    const [pro, setPro] = useState([])

    // 省份集合
    const [proList, setProList] = useState([])

    // 城市
    const [city, setCity] = useState([])

    // 城市集合
    const [cityList, setCityList] = useState([])

    // 地域
    const [dy, setDy] = useState([])

    // 地域集合
    const [dyList, setDyList] = useState([])

    // 城市等级
    const [citylevel, setCitylevel] = useState([])

    // 城市等级集合
    const [citylevelList, setCitylevelList] = useState([])

    // 产品线
    const [productLine, setProductLine] = useState([])

    // 产品线集合
    const [productLineList, setProductLineList] = useState([])

    //  品牌
    const [brand, setBrand] = useState([])

    // 品牌集合
    const [brandList, setBrandList] = useState([])

    // 年份
    const [year, setYear] = useState([])

    // 年份集合
    const [yearList, setYearList] = useState([])

    // 季节
    const [season, setSeason] = useState([])

    // 季节集合
    const [seasonList, setSeasonList] = useState([])

    // 颜色
    const [color, setColor] = useState([])

    // 颜色集合
    const [colorList, setColorList] = useState([])

    // 尺寸
    const [clothesSize, setClothesSize] = useState([])

    // 尺寸
    const [clothesSizeList, setClothesSizeList] = useState([])

    // 时间间隔/天开始时间
    const [dayStart, setDayStart] = useState('')

    // 时间间隔/天结束时间
    const [dayEnd, setDayEnd] = useState('')

    // 用户分层
    const [yhfc, setYhfc] = useState([])

    // 用户分层集合
    const [yhfcList, setYhfcList] = useState([])

    // 品类集合
    const [ categoryList, setCategoryList] = useState([])

    // 品类
    const [ category, setCategory] = useState([])

    // 是否加载中
    const [loading, setLoading] = useState(false)

    // 打开查询条件
    const [openSearch, setOpenSearch] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        },
        showTotal: ((total) => {
            return `共 ${total} 条`;
        }),
    }

    useEffect(() => {
        getDate()
    }, [page])

    // 店铺修改的时候 地域修改
    useEffect(() => {
        // 地域
        getDyList()
        // 商品线
        getProductLineList()
        // 品牌
        getBrandList()
        // 年份
        getYearList()
        // 季节
        getSeasonList()
        // 颜色
        getColorList()
        // 尺寸
        getSizeList()
        // 用户分层
        getYhfcList()
        // 品类
        getCategoryList()
        // 城市等级
        getCitylevelList()
        // 请求数据
        // if (page == 1) {
        //     getDate()
        // } else {
        //     setPage(1);
        // }
    }, [shop])

    // 地域改变的时候 省份修改
    useEffect(() => {
        form2.resetFields()
        setPro([])
        getProList()
    }, [dy])

    // 省份修改的时候 城市修改
    useEffect(() => {
        form3.resetFields()
        setCity([])
        getCityList()
    }, [pro])

    // 获取数据
    const getDate = () => {
        setLoading(true)
        let dataProps = {
            "shop": shop,
            // "customer_name": customerName,
            "order_id": orderId,
            "order_create_time_start": orderCreateTimeStart,
            "order_create_time_end": orderCreateTimeEnd,
            "order_pay_time_start": orderPayTimeStart,
            "order_pay_time_end": orderPayTimeEnd,
            "shdz": shdz,
            "sku_outside": skuOutside,
            "pro_list": pro,
            "city_list": city,
            "dy_list": dy,
            "citylevel_list": citylevel,
            "product_line_list": productLine,
            "brand_list": brand,
            "year_list": year,
            "season_list": season,
            "color_list": color,
            "size_list": clothesSize,
            "day_start": dayStart,
            "day_end": dayEnd,
            "yhfc_list": yhfc,
            "category_list": category,
            "page": page,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryBasic,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setLoading(false)
                if (res.data['success']) {
                    setDate(res.data.data);
                    setTotal(res.data.total);
                    setOpenSearch(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取品类
    const getCategoryList = ()=>{
        let dataProps = {
            "shop": shop,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryCategoryList).then(res=>{
            if(res.success){
                setCategoryList(res.data);
            }else {
                message.error(res.errorMsg)
            }
        })
    }

    // 获取省
    const getProList = () => {
        let dataProps = {
            "shop": shop,
            "dy_list": dy,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryProList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setProList(res.data.data)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取地域
    const getDyList = () => {
        let dataProps = {
            "shop": shop,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryDyList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setDyList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取城市
    const getCityList = () => {
        let dataProps = {
            "shop": shop,
            "dy_list": dy,
            "pro_list": pro,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryCityList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setCityList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取产品线
    const getProductLineList = () => {
        let dataProps = {
            "shop": shop,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryProductLineList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setProductLineList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取品牌
    const getBrandList = () => {
        let dataProps = {
            "shop": shop,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryBrandList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setBrandList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取年份
    const getYearList = () => {
        let dataProps = {
            "shop": shop,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryYearList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setYearList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取季节
    const getSeasonList = () => {
        let dataProps = {
            "shop": shop,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.querySeasonList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setSeasonList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取颜色
    const getColorList = () => {
        let dataProps = {
            "shop": shop,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryColorList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setColorList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取尺寸集合
    const getSizeList = () => {
        let dataProps = {
            "shop": shop,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.querySizeList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setClothesSizeList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取用户分层集合
    const getYhfcList = () => {
        let dataProps = {
            "shop": shop,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryYhfcList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setYhfcList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取城市等级
    const getCitylevelList = () => {
        let dataProps = {
            "shop": shop,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryCityLevelList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data['success']) {
                    setCitylevelList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 表头
    const columns = [
        {
            title: '订单编号',
            key: 'order_id',
            dataIndex: 'order_id',
            width: '202px',
            align: 'center'
        },
        {
            title: '用户名',
            key: 'customer_name',
            dataIndex: 'customer_name',
            width: '122px',
            align: 'center'
        },
        {
            title: '订单创建时间',
            key: 'order_create_time',
            dataIndex: 'order_create_time',
            width: '182px',
            align: 'center'
        },
        {
            title: '订单支付时间',
            key: 'order_pay_time',
            dataIndex: 'order_pay_time',
            width: '182px',
            align: 'center'
        },
        {
            title: '品牌',
            key: 'brand',
            dataIndex: 'brand',
            width: '70px',
            align: 'center'
        }, {
            title: 'SKU',
            key: 'sku_outside',
            dataIndex: 'sku_outside',
            width: '100px',
            align: 'center'
        }, {
            title: '省份',
            key: 'pro',
            dataIndex: 'pro',
            width: '100px',
            align: 'center'
        }, {
            title: '城市',
            key: 'city',
            dataIndex: 'city',
            width: '100px',
            align: 'center'
        }, {
            title: '地域',
            key: 'dy',
            dataIndex: 'dy',
            width: '100px',
            align: 'center'
        }, {
            title: '城市等级',
            key: 'citylevel',
            dataIndex: 'citylevel',
            width: '110px',
            align: 'center'
        }, {
            title: '产品线',
            key: 'product_line',
            dataIndex: 'product_line',
            width: '100px',
            align: 'center'
        }, {
            title: '年份',
            key: 'year',
            dataIndex: 'year',
            width: '80px',
            align: 'center'
        }, {
            title: '季节',
            key: 'season',
            dataIndex: 'season',
            width: '80px',
            align: 'center'
        }, {
            title: '颜色',
            key: 'color',
            dataIndex: 'color',
            width: '80px',
            align: 'center'
        }, {
            title: '尺寸',
            key: 'size',
            dataIndex: 'size',
            width: '100px',
            align: 'center'
        },
        {
            title: '品类',
            key: 'category',
            dataIndex: 'category',
            width: '100px',
            align: 'center'
        },
        {
            title: '商品数量',
            key: 'order_num',
            dataIndex: 'order_num',
            width: '100px',
            align: 'center'
        },
        {
            title: '来源',
            key: 'source',
            dataIndex: 'source',
            width: '100px',
            align: 'center'
        },
        {
            title: '时间间隔/秒',
            key: 'timeStamp_diff',
            dataIndex: 'timeStamp_diff',
            width: '100px',
            align: 'center'
        },
        {
            title: '时间间隔/天',
            key: 'day',
            dataIndex: 'day',
            width: '100px',
            align: 'center'
        },
        {
            title: '用户分层',
            key: 'yhfc',
            dataIndex: 'yhfc',
            width: '100px',
            align: 'center'
        },
        {
            title: '订单状态',
            key: 'order_status',
            dataIndex: 'order_status',
            width: '100px',
            align: 'center'
        },
        {
            title: '价格',
            key: 'price',
            dataIndex: 'price',
            width: '100px',
            align: 'center'
        }
    ]

    // 重置
    const restart = () => {
        setLoading(true)
        setOrderId('')
        setOrderCreateTimeEnd('')
        setOrderCreateTimeStart('')
        setOrderPayTimeStart('')
        setOrderPayTimeEnd('')
        setShdz('')
        setSkuOutside('')
        setPro([])
        setCity([])
        setDy([])
        setCitylevel([])
        setProductLine([])
        setBrandList([])
        setYear([])
        setSeason([])
        setColor([])
        setClothesSize([])
        setColor([])
        setDayStart('')
        setDayEnd('')
        setYhfc([])
        setCategory([])
        setPage(1)
        let dataProps = {
            "shop": shop,
            // "customer_name": customerName,
            "order_id": '',
            "order_create_time_start": '',
            "order_create_time_end": '',
            "order_pay_time_start": '',
            "order_pay_time_end": '',
            "shdz": '',
            "sku_outside": '',
            "pro_list": [],
            "city_list": [],
            "dy_list": [],
            "citylevel_list": [],
            "product_line_list": [],
            "brand_list": [],
            "year_list": [],
            "season_list": [],
            "color_list": [],
            "size_list": [],
            "day_start": '',
            "day_end": '',
            "yhfc_list": [],
            "category_list": [],
            "page": 1,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryBasic,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setLoading(false)
                if (res.data['success']) {
                    form.resetFields()
                    form1.resetFields()
                    form2.resetFields()
                    form3.resetFields()
                    setDate(res.data.data);
                    setTotal(res.data.total);
                    setOpenSearch(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }



    // 关闭查询弹框
    const onSearchClose = () => {
        setOpenSearch(false)
    }

    // 创建任务
    const createTask = ()=>{
        if(shop.length == 0){
            message.warn('请选择店铺')
        }else{
            let dataProps = {
                "shop_list": shop,
                // "customer_name": customerName,
                "order_id": orderId,
                "order_create_time_start": orderCreateTimeStart,
                "order_create_time_end": orderCreateTimeEnd,
                "order_pay_time_start": orderPayTimeStart,
                "order_pay_time_end": orderPayTimeEnd,
                "shdz": shdz,
                "sku_outside": skuOutside,
                "pro_list": pro,
                "city_list": city,
                "dy_list": dy,
                "citylevel_list": citylevel,
                "product_line_list": productLine,
                "brand_list": brand,
                "year_list": year,
                "season_list": season,
                "color_list": color,
                "size_list": clothesSize,
                "day_start": dayStart,
                "day_end": dayEnd,
                "yhfc_list": yhfc,
                "category_list": category,
                "page": page,
                "page_size": 10,
                "roleId": cookie.load('role'),
                "shopId": cookie.load('shopList'),
                "principal_uuid": cookie.load('id')
            }
            NbAxios(dataProps, "POST", servicePath.createBasicFileTask).then(res => {
                if (res.success) {
                    message.success('任务创建成功')
                } else {
                    message.error(res.errorMsg)
                }
            })
        }
    }

    return (
        <div>
            <Spin spinning={loading} size="large" style={{ alignItems: "center", top: '40%' }}>
                <div style={{ height: '80vh' }}>
                    {/* 按钮区域 */}
                    <div style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.03 }}>
                        <Row>
                            <Form>
                                <Form.Item
                                    name="shop"
                                    label="店铺"
                                >
                                    <Select mode="multiple" defaultValue={shop} value={shop} onChange={(value) => {
                                       setShop(value)
                                    } } style={{width:'300px'}}>
                                        <Option  value="京东" disabled={shop.length == 1 && shop.indexOf('京东') != -1 ? true : false}>京东</Option>
                                        <Option  value="奥莱" disabled={shop.length == 1 && shop.indexOf('奥莱') != -1 ? true : false}>奥莱</Option>
                                        <Option  value="恩曼琳" disabled={shop.length == 1 && shop.indexOf('恩曼琳') != -1 ? true : false}>恩曼琳</Option>
                                        <Option value="卡洛琳"
                                                disabled={shop.length == 1 && shop.indexOf('卡洛琳') != -1 ? true : false}>卡洛琳</Option>
                                        <Option value="JORYA"
                                                disabled={shop.length == 1 && shop.indexOf('JORYA') != -1 ? true : false}>JORYA</Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                            <Button style={{ marginLeft: '1vw'}} type="primary" onClick={()=>{
                                // 请求数据
                                if (page == 1) {
                                    getDate()
                                } else {
                                    setPage(1);
                                }
                            }}>店铺查询</Button>
                            <Button type="primary" onClick={() => setOpenSearch(true)} style={{ marginLeft: '1vw', marginRight: '1vw' }}>条件查询</Button>
                            {/* <a href={servicePath.downloadBasic + "?shop=" + shop + "&pro_list=" + pro + "&city_list=" + city + "&dy_list=" + dy + "&citylevel_list=" + citylevel + "&product_line_list=" + productLine + "&brand_list=" + brand + "&year_list=" + year + "&season_list="
                                + season + "&color_list=" + color + "&size_list=" + clothesSize + "&yhfc_list=" + yhfc + "&customer_name=" + customerName + "&order_create_time_start=" + orderCreateTimeStart +
                                "&order_create_time_end=" + orderCreateTimeEnd + "&order_pay_time_start=" + orderPayTimeStart + "&order_pay_time_end=" + orderPayTimeEnd + "&shdz=" + shdz + "&sku_outside=" + skuOutside + "&category_list=" + category
                                + "&shopId=" + cookie.load('shopList') + "&roleId=" + cookie.load('role')} target="_blank"><Button>下载数据</Button></a> */}
                            <Button onClick={()=>createTask()}>下载数据</Button>
                        </Row>
                    </div>
                    <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.03, marginRight: size.width * 0.03 }}>
                        {/* 表单区域 */}
                        {/* <Alert message={"总数为"+total} type="info" showIcon /> */}
                        <Table
                            //表格列的配置描述s
                            columns={columns}
                            //数据数组
                            dataSource={date}
                            pagination={paginationProps}
                            scroll={{ x: '80vw', y: `${size.width > 1500 ? '65vh' : '55vh'}` }}
                            //表格行 key 的取值
                            rowKey={'id'}
                        />
                    </div>

                    {/* 查询条件 */}
                    <Drawer
                        title="查询条件"
                        placement="right"
                        closable={false}
                        onClose={onSearchClose}
                        visible={openSearch}
                        width={1200}
                    >
                        <Form
                            form={form}
                            {...layout}
                            labelAlign='left'
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    {/* <Form.Item
                                        name="customerName"
                                        label="用户名"
                                    >
                                        <Input
                                            id='customerName'
                                            placeholder="请输入用户名"
                                            value={customerName}
                                            onChange={(e) => {
                                                setCustomerName(e.target.value);
                                            }}
                                        />
                                    </Form.Item> */}
                                    <Form.Item
                                        name="orderId"
                                        label="订单编号"
                                    >
                                        <Input
                                            id='orderId'
                                            placeholder="请输入订单编号"
                                            value={orderId}
                                            onChange={(e) => {
                                                setOrderId(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form
                                        form={form1}
                                        {...layout}
                                        labelAlign='left'
                                    >
                                        <Form.Item
                                            name="dy"
                                            label="地域"
                                        >
                                            <Select value={dy} mode="multiple" placeholder="请选择地域" style={{ width: 370 }} onChange={(value) => setDy(value)}>
                                                {
                                                    dyList.map(item => {
                                                        return <Option value={item}>{item}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col span={12}>
                                    <Form
                                        form={form2}
                                        {...layout}
                                        labelAlign='left'
                                    >
                                        <Form.Item
                                            name="pro"
                                            label="省份"
                                        >
                                            <Select value={pro} mode="multiple" placeholder="请选择省份" style={{ width: 370 }} onChange={(value) => setPro(value)}>
                                                {
                                                    proList.map(item => {
                                                        return <Option value={item}>{item}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col span={12}>
                                    <Form
                                        form={form3}
                                        {...layout}
                                        labelAlign='left'
                                    >
                                        <Form.Item
                                            name="city"
                                            label="城市"
                                        >
                                            <Select value={city} mode="multiple" placeholder="请选择城市" style={{ width: 370 }} onChange={(value) => setCity(value)}>
                                                {
                                                    cityList.map(item => {
                                                        return <Option value={item}>{item}</Option>
                                                    })
                                                }
                                            </Select>

                                        </Form.Item>
                                    </Form>

                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="productLine"
                                        label="产品线"
                                    >
                                        <Select value={productLine} mode="multiple" placeholder="请选择产品线" style={{ width: 370 }} onChange={(value) => setProductLine(value)}>
                                            {
                                                productLineList.map(item => {
                                                    return <Option value={item}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="brand"
                                        label="品牌"
                                    >
                                        <Select value={brand} mode="multiple" placeholder="请选择品牌" style={{ width: 370 }} onChange={(value) => setBrand(value)}>
                                            {
                                                brandList.map(item => {
                                                    return <Option value={item}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="year"
                                        label="年份"
                                    >
                                        <Select value={year} mode="multiple" placeholder="请选择年份" style={{ width: 370 }} onChange={(value) => setYear(value)}>
                                            {
                                                yearList.map(item => {
                                                    return <Option value={item}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="season"
                                        label="季节"
                                    >
                                        <Select value={season} mode="multiple" placeholder="请选择季节" style={{ width: 370 }} onChange={(value) => setSeason(value)}>
                                            {
                                                seasonList.map(item => {
                                                    return <Option value={item}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="color"
                                        label="颜色"
                                    >
                                        <Select value={color} mode="multiple" placeholder="请选择颜色" style={{ width: 370 }} onChange={(value) => setColor(value)}>
                                            {
                                                colorList.map(item => {
                                                    return <Option value={item}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="clothesSize"
                                        label="尺寸"
                                    >
                                        <Select value={clothesSize} mode="multiple" placeholder="请选择尺寸" style={{ width: 370 }} onChange={(value) => setClothesSize(value)}>
                                            {
                                                clothesSizeList.map(item => {
                                                    return <Option value={item}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="yhfc"
                                        label="用户分层"
                                    >
                                        <Select value={yhfc} mode="multiple" placeholder="请选择尺寸" style={{ width: 370 }} onChange={(value) => setYhfc(value)}>
                                            {
                                                yhfcList.map(item => {
                                                    return <Option value={item}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="category"
                                        label="品类"
                                    >
                                        <Select value={category} mode="multiple" placeholder="请选择品类" style={{ width: 370 }} onChange={(value) => setCategory(value)}>
                                            {
                                                categoryList.map(item => {
                                                    return <Option value={item}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="orderCreateTime"
                                        label="订单创建时间"
                                    >
                                        <RangePicker locale={locale} showTime onChange={(date, dateString) => {
                                            setOrderCreateTimeStart(dateString[0]);
                                            setOrderCreateTimeEnd(dateString[1]);
                                        }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="orderPayTime"
                                        label="订单支付时间"
                                    >
                                        <RangePicker locale={locale} showTime onChange={(date, dateString) => {
                                            setOrderPayTimeStart(dateString[0]);
                                            setOrderPayTimeEnd(dateString[1]);
                                        }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="citylevel"
                                        label="城市等级"
                                    >
                                        <Select value={citylevel} mode="multiple" placeholder="请选择城市等级" style={{ width: 370 }} onChange={(value) => setCitylevel(value)}>
                                            {
                                                citylevelList.map(item => {
                                                    return <Option value={item}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="day"
                                        label="时间间隔"
                                    >
                                        <Input.Group>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        value={dayStart}
                                                        placeholder="请输入开始时间"
                                                        onChange={(e) => {
                                                            setDayStart(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col> —— </Col>
                                                <Col>
                                                    <Input
                                                        value={dayEnd}
                                                        placeholder="请输入开始时间"
                                                        onChange={(e) => {
                                                            setDayEnd(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Input.Group>
                                    </Form.Item>
                                </Col>
                                <Col style={{ marginLeft: 110 }}>
                                    <Button type="primary" onClick={() => {
                                        if (page == 1) {
                                            getDate()
                                        } else {
                                            setPage(1);
                                        }
                                    }}>查询</Button>
                                    <Button type="primary" danger style={{ marginLeft: 10 }} onClick={() => { setPage(1); restart() }}>重置</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                </div>
            </Spin>

        </div>
    )

}

export default CommodityCrowd