import React, {useState, useEffect, useCallback} from 'react';
import {Breadcrumb, Row, Col, Button, Table, message, Tag,} from 'antd'
import servicePath from '../../config/apiUrl'
import axios from 'axios'
import cookie from 'react-cookies'
import '../../static/css/Table.css'

function Accredit(props) {


    //所有正常的角色
    const [role, setRole] = useState([])

    //所有正常的店铺
    const [shop, setShop] = useState([])

    //对应菜单
    const [menu, setMenu] = useState([])

    //对应的接口
    const [port, setPort] = useState([])

    //对应的数据权限
    const [ data , setData ] = useState([])

    //角色页面
    const [rolePage, setRolePage] = useState(1)

    //店铺页面
    const [shopPage, setShopPage] = useState(1)

    //菜单页面
    const [menuPage, setMenuPage] = useState(1)

    //接口页面
    const [portPage, setPortPage] = useState(1)

    //数据权限页面
    const [dataPage , setDataPage ] = useState(1)

    //菜单Id
    const [menuId, setMenuId] = useState(-1)

    //角色Id
    const [roleId, setRoleId] = useState(-1)

    //店铺Id
    const [shopId, setShopId] = useState(-1)

    //接口Id
    const [ portId , setPortId ] = useState(-1)

    //可能新增的接口
    const [ newPort , setNewPort ] = useState([])

    //可能新增的数据权限
    const [ newData , setNewData ] = useState([])

    //角色下面的店铺
    const [ choosedShop , setChoosedShop ] = useState([])    

    //已经选好的菜单
    const [choosedMenu, setChooseMenu] = useState([])

    //已经选好的接口
    const [choosedPort, setChoosePort] = useState([])

    //已经选好的数据权限
    const [ choosedData , setChooseData ] = useState([])

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    useEffect(() => {
        getAllRole()
        getAllShop()
    }, [])

    //角色改变的时候 店铺，菜单都置-1
    useEffect(() => {
        setShopId(-1)
        setMenuId(-1)
        setPortId(-1)
        getAllShop()
        getChoosedShop() 
    }, [roleId])

    //店铺改变的时候
    useEffect(() => {
        getMenu()
        getRoleMenu()
        setMenuId(-1)
        getRolePort()
        getRoleData()
        setNewPort([])
        setNewData([])
    }, [shopId])

    // 菜单改变的时候 修改接口列表,获取角色对应的接口
    useEffect(() => {
        getPort()
        setPortId(-1)
    }, [menuId])

    // 接口改变的时候，修改数据权限列表
    useEffect(()=>{
        getAllData()
    },[portId])

    //获取角色下面的店铺
    const getChoosedShop = ()=>{
        let dataProps = {
            'role_id': roleId,

        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShop,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    //清空数组
                    choosedShop.splice(0,choosedShop.length)
                    res.data['data'].map(item=>{
                        choosedShop.push(item.name)
                    })
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }


    //获取角色对应店铺的菜单权限
    const getRoleMenu = () => {
        let dataProps = {
            'role_id': roleId,
            'shop_id': shopId
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopMenu,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setChooseMenu(res.data['data'])
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //获取角色对应店铺的数据权限
    const getRoleData = ()=>{
        if (roleId== ''){
            
        }else if(shopId == -1){
            
        }else{
            let dataProps = {
                'roleId': roleId,
                'shopId': shopId
            }
            axios({
                method: 'POST',
                url: servicePath.getRoleShopData,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        setChooseData(res.data['data'])
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
       
    }

    //获取角色对应店铺的接口权限
    const getRolePort = () => {
        if (roleId== ''){
            
        }else if(shopId == -1){
            
        }else{
            let dataProps = {
                'role_id': roleId,
                'shop_id': shopId
            }
            axios({
                method: 'POST',
                url: servicePath.getRoleShopPort,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        setChoosePort(res.data['data'])
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
       

    }


    //根据菜单获取接口
    const getPort = () => {
        let dataProps = {
            'menuId': menuId,
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPortByMenu,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setPort(res.data['data'])
                    res.data['data'].map(item=>{
                        newPort.push(item.id)
                    })
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
       
    }

    //根据店铺获取菜单
    const getMenu = () => {
        if (shopId == -1){
           
        }else{
            let dataProps = {
                'shopId': shopId,
            }
            axios({
                method: 'POST',
                url: servicePath.getChildMenuByShop,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        setMenu(res.data['data'])
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
       
    }

    // 获取数据权限接口
    const getAllData = ()=>{
        if (portId == ''){
           
        }else{
            let dataProps = {
                'port_id': portId,
                "role_Id": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.getDataByPort,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        setData(res.data['data'])
                        res.data['data'].map(item=>{
                            newData.push(item.id)
                        })
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
    }

    //获取所有角色
    const getAllRole = () => {
         let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryRoles,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {    
                if(res.data['success']){
                    setRole(res.data['data']);
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //获取所有店铺
    const getAllShop = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryNorShop,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setShop(res.data['data']);
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    //设置每个角色页面显示个数
    const paginationRoleProps = {
        pageSize: 10, // 每页条数
        onChange: page => {
            //改变页码
            setRolePage(page)
        }
    }

    //设置每个店铺页面显示个数
    const paginationShopProps = {
        pageSize: 10, // 每页条数
        showSizeChanger:false,
        onChange: page => {
            //改变页码
            setShopPage(page)
        }
    }


    //设置每个菜单页面显示个数
    const paginationMenuProps = {
        pageSize: 10, // 每页条数
        showSizeChanger:false,
        onChange: page => {
            //改变页码
            setMenuPage(page)
        }
    }

    //设置每个接口页面显示个数
    const paginationPortProps = {
        pageSize: 10, // 每页条数
        showSizeChanger:false,
        onChange: page => {
            //改变页码
            setPortPage(page)
        }
    }

    //设置每个数据权限页面显示个数
    const paginationDataProps = {
        pageSize: 10, // 每页条数
        showSizeChanger:false,
        onChange: page => {
            //改变页码
            setDataPage(page)
        }
    }


    //选择角色
    const chooseRole = (index) => {
        //根据页改变下标
        index = (rolePage - 1) * 10 + index
        setRoleId(role[index]['id'])
    }

    //选择菜单
    const chooseMenu = (index) => {
        //根据页改变下标
        index = (menuPage - 1) * 10 + index
        setMenuId(menu[index]['id'])
    }

    //选择店铺
    const chooseShop = (index) => {
        //根据页改变下标
        index = (shopPage - 1) * 10 + index
        setShopId(shop[index]['id'])
    }

    //选择接口
    const choosePort = (index) =>{
         //根据页改变下标
         index = (portPage - 1) * 10 + index
         setPortId(port[index]['id'])
    }

    //角色表格标题
    const roleColumns = [
        {
            title: '角色名称',
            key: 'name',
            dataIndex: 'name',
            width: '150px',
            align: 'center',
            render: (text, record, index) => (
                <Tag color={roleId == role[(rolePage - 1) * 10 + index]['id'] ? "red" : "default"}
                     onClick={() => chooseRole(index)}>{text}</Tag>
            ),
        }
    ]

    //店铺表格标题
    const shopColumns = [
        {
            title: '板块名称',
            key: 'shopname',
            dataIndex: 'shopname',
            width: '150px',
            align: 'center',
            render: (text, record, index) => (
                <Tag color={choosedShop.indexOf(text)!=-1?"#2db7f5":"default"} style={{color:shopId == shop[(shopPage - 1) * 10 + index]['id'] ? "red" : "black"}}
                        onClick={() => chooseShop(index)}>{text}</Tag>
            ),
        }
    ]

    //菜单表格标题
    const menuColumns = [
        {
            title: '菜单名称',
            key: 'name',
            dataIndex: 'name',
            width: '150px',
            align: 'center',
            render: (text, record, index) => (  
                <Tag color={menuId == menu[(menuPage - 1) * 10 + index]['id'] ? "red" : "default"}
                     onClick={() => chooseMenu(index)}>{menu[(menuPage - 1) * 10 + index]['pName'] + "/" + text}</Tag>
            ),
        }
    ]

    //接口表格标题
    const portColumns = [
        {
            title: '接口名称',
            key: 'name',
            dataIndex: 'name',
            width: '150px',
            align: 'center',
            render: (text, record, index) => (
                <Tag color={portId == port[(portPage - 1) * 10 + index]['id'] ? "red" : "default"}
                     onClick={() => choosePort(index)}>{text}</Tag>
            ),
        }
    ]

    //数据权限表格标题
    const dataColumns = [
        {
            title: '数据权限名称',
            key: 'name',
            dataIndex: 'name',
            width: '150px',
            align: 'center',
            render: (text, record, index) => (
                <Tag color={"default"}>{text}</Tag>
            ),
        }
    ]

    //改变菜单复选框
    const onSelectMenuChange = selectRowKeys => {
        setChooseMenu(selectRowKeys)
    }

    //菜单复选框
    const rowMenuSelection = {
        columnWidth: '10px',
        selectedRowKeys: choosedMenu,
        hideDefaultSelections: true,
        onChange: onSelectMenuChange,
    }


    //改变接口复选框
    const onSelectPortChange = selectRowKeys => {
        let ids = []
        port.map(item=>{
            ids.push(item.id)
        })
        //差集
        let c = ids.filter(function(v){ return selectRowKeys.indexOf(v) == -1 })
        //差集
        let newChoosedPort = choosedPort.filter(function(v){return c.indexOf(v) == -1}) 
        //交集
        let list = newPort.filter(function(v){ return selectRowKeys.indexOf(v) > -1 })
        if(list.length>0){
            list.map(item=>{
                // 新的编写
                if(newChoosedPort.indexOf(item)==-1){
                    newChoosedPort.push(item)
                }
            })
        }      
        setChoosePort(newChoosedPort)
        
      
    }

    //接口复选框
    const rowPortSelection = {
        columnWidth: '10px',
        selectedRowKeys: choosedPort,
        hideDefaultSelections: true,
        onChange: onSelectPortChange,
    }

     //数据权限复选框
    const onSelectDataChange = selectRowKeys =>{
        let ids = []
        data.map(item=>{
            ids.push(item.id)
        })
         //差集
         let c = ids.filter(function(v){ return selectRowKeys.indexOf(v) == -1 })
         //差集
         let newChoosedData = choosedData.filter(function(v){return c.indexOf(v) == -1}) 
         //交集
         let list = newData.filter(function(v){ return selectRowKeys.indexOf(v) > -1 })
         if(list.length>0){
            list.map(item=>{
                // 新的编写
                if(newChoosedData.indexOf(item)==-1){
                    newChoosedData.push(item)
                }
            })
        }     
         setChooseData(newChoosedData)
    }

    const rowDataSelection = {
        columnWidth: '10px',
        selectedRowKeys: choosedData,
        hideDefaultSelections: true,
        onChange: onSelectDataChange,
    }

   

    // 保存权限
    const saveData = () => {
        setChoosedShop([])
        if (shopId == -1){
            message.error('店铺必选')
        }else if(roleId == -1){
            message.error('角色必选')
        }else{
            let menuDataProps = {
                'role_id': roleId,
                'shop_id': shopId,
                'menuIds': choosedMenu
            }
            let portDataProps = {
                'role_id': roleId,
                'shop_id': shopId,
                'portIds': choosedPort
            }
            let dataDataProps = {
                'role_id': roleId,
                'shop_id': shopId,
                'dataIds': choosedData
            }
            axios({
                method: 'POST',
                url: servicePath.updateRoleShopMenu,
                data: menuDataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success('角色菜单权限配置成功')
                        setRoleId(0)
                        setShopId(-1)
                        setChooseMenu([])
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
            axios({
                method: 'POST',
                url: servicePath.updateRoleShopPort,
                data: portDataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success('角色接口权限配置成功')
                        setRoleId(0)
                        setShopId(-1)
                        setChoosePort([])
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
            axios({
                method: 'POST',
                url: servicePath.updateRoleShopData,
                data: dataDataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success('角色数据权限配置成功')
                        setRoleId(0)
                        setShopId(-1)
                        setChooseData([])
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
        setMenu([])
    }


    return (
        <>
            {/* 导航区域 */}
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.02}}>
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                    <Breadcrumb.Item>权限管理</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* 按钮区域 */}
            <div style={{marginTop: size.height * 0.05, marginLeft: size.width * 0.06}}>
                <Button type="primary" onClick={() => saveData()}>保存</Button>
            </div>
            {/* 授权区域 */}
            <div style={{marginTop: size.height * 0.10, marginLeft: size.width * 0.06}}>
                {/* 角色区域 */}
                <div style={{}}>
                    {/* 表单区域 */}
                    <Row>
                        <Col style={{marginRight: size.width * 0.05}}>
                            <Table
                                //表格列的配置描述
                                columns={roleColumns}
                                //数据数组
                                dataSource={role}
                                pagination={paginationRoleProps}
                                width="100px"
                                //表格行 key 的取值
                                rowKey={'id'}
                            />
                        </Col>
                        <Col style={{marginRight: size.width * 0.05}}>
                            <Table
                                //表格列的配置描述
                                columns={shopColumns}
                                //数据数组
                                dataSource={shop}
                                pagination={paginationShopProps}
                                width="100px"
                                //表格行 key 的取值
                                rowKey={'id'}
                            />
                        </Col>
                        <Col style={{marginRight: size.width * 0.05}}>
                            <Table
                                //选择功能的配置
                                rowSelection={rowMenuSelection}
                                //表格列的配置描述
                                columns={menuColumns}
                                //数据数组
                                dataSource={menu}
                                pagination={paginationMenuProps}
                                width="100px"
                                //表格行 key 的取值
                                rowKey={'id'}
                            />
                        </Col>

                        <Col style={{marginRight: size.width * 0.05}}>
                            <Table
                                //选择功能的配置
                                rowSelection={rowPortSelection}
                                //表格列的配置描述
                                columns={portColumns}
                                //数据数组
                                dataSource={port}
                                pagination={paginationPortProps}
                                width="100px"
                                //表格行 key 的取值
                                rowKey={'id'}
                            />
                        </Col>
                        <Col style={{marginRight: size.width * 0.05}}>
                            <Table
                                //选择功能的配置
                                rowSelection={rowDataSelection}
                                //表格列的配置描述
                                columns={dataColumns}
                                //数据数组
                                dataSource={data}
                                pagination={paginationDataProps}
                                width="100px"
                                //表格行 key 的取值
                                rowKey={'id'}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Accredit