import React, { useState, useEffect } from 'react';
import { Table, Button, Select, Typography, message, Upload, Spin, Collapse, Row, Col, Input, Modal, Checkbox, Image, Form, Popconfirm, Divider, Tooltip, DatePicker } from 'antd'
import { PlusCircleOutlined, MinusCircleOutlined, InboxOutlined, MenuOutlined, DeleteOutlined} from '@ant-design/icons';
import Axios from "axios";
import Base64 from 'base-64'
import cookie from 'react-cookies'
import NbAxios from "../../../config/utils/nbAxios";
import locale from "antd/es/date-picker/locale/zh_CN";
import style from './index.module.css';
import servicePath from "../../../config/apiUrl";
import moment from "moment";
// 设置选择器
const { Option } = Select;
// 设置文本
const { Text } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input
// 文件上传组建
const { Dragger } = Upload;
function Index() {
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const [baseForm] = Form.useForm()
    // 设置页码变量
    const [page, setPage] = useState(1);
    // 设置总数变量
    const [total, setTotal] = useState();
    // 设置加载中变量
    const [loading, setloading] = useState(false);
    // 规定时间格式
    const dateFormat = "YYYY-MM-DD";
    // 设置关键字列表
    const [select_list, setSelect_list] = useState([]);
    // 数据
    const [data, setData] = useState([])
    // 新增数据
    const [ addData , setAddData ] = useState([])
    // 更新数据
    const [ updateData , setUpdateData ] = useState([])
    // 上新时间
    const [ putShelvesTime , setPutShelvesTime ] = useState('')
    // 商品id
    const [ id , setId ] = useState('')
    // 可选菜单
    const [ choseMenuList, setChoseMenuList ] = useState([])
    // 已选择菜单
    const [ chosedMenus , setChosedMenus ] = useState([])
    // 确认已选择菜单
    const [ confirmChosedMenus , setConfirmChosedMenus ] = useState([])
    // 可选项
    const [ choseList, setChoseList] = useState([])
    // 搜索数据
    const [searchData, setSearchData] = useState([])
    // 已存在选
    const [exitChoseList, setExitChostList] = useState([])
    // 是否上传文件
    const [isUpload, setIsUpload] = useState(false)
    // 是否编辑商品基础信息
    const [ isUpdate , setIsUpdate ] = useState(false)
    // 数据反馈
    const [ isResult , setIsResult] = useState(false)
    // 预算目标文件
    const [baseFile, setBaseFile] = useState([])
    // 选择的id
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 添加页面
    const [ addPage , setAddPage ] = useState(1)
    // 更新页面
    const [ updatePage , setUpdatePage ] = useState(1)
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 表头集合
    const [ columnsList , setColumnsList ] = useState([])
    const [ columns , setColumns] = useState([])
    // 显示的字段
    const [  fieldStr , setFieldStr] = useState([])
    // 页面设置
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,  
        total: total,
        onChange: (page) => {
            //改变页码
            setPage(page);
        },
    };
    // 表格设置
    useEffect(()=>{
        setColumns([
            {
                title: "款图",
                dataIndex: "goods_platform_id",
                key: "goods_platform_id",
                width: 100,
                fixed: "left",
                align: "center",
                render: (text, record, index) => {
                      return <Image src={`https://pic.bmcsoft.cn/imge_dir/item_info/joomilim/${record.goods_platform_id}.png`} width="50px" alt="" />;
                },
            },
            {
                title: "款号",
                width: 120,
                align: "center",
                dataIndex: "spu",
                key: "spu",
                fixed: "left",
                ellipsis: {
                    showTitle: false,
                },
                render: (text, record, index) => (
                    <Tooltip placement="topLeft" title={text}>
                      {text}
                    </Tooltip>
                )
            },
            {
                title: "商品ID",
                width: 150,
                align: "center",
                dataIndex: "goods_platform_id",
                key: "goods_platform_id",
                fixed: "left",
            },
            {
                title: "商家编码",
                width: 160,
                align: "center",
                dataIndex: "merchant_code",
                key: "merchant_code",
            },
            ...columnsList.map(item=>{
                return {
                    title: item.key,
                    dataIndex: item.value,
                    key: item.value,
                    width: 100,
                    align: "center",
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (text, record, index) => (
                        item.key == '材质' ? 
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip> :
                        item.key == '商品简称' ? 
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip> :
                        item.key == '上新波段' ? 
                        <Tooltip placement="topLeft" title={text}>
                            {text}
                        </Tooltip> :
                        <span>{text}</span>
                    )
                }
            })
            // {
            //     title: "产品年份",
            //     dataIndex: "year",
            //     key: "year",
                // width: 100,
                // align: "center",
            // },
            // {
            //     title: "上新波段",
            //     width: 100,
            //     align: "center",
            //     dataIndex: "new_band",
            //     key: "new_band",
            // },
            // {
            //     title: "一级分类",
            //     width: 100,
            //     align: "center",
            //     dataIndex: "primary_classification",
            //     key: "primary_classification",
            // },
            // {
            //     title: "二级分类",
            //     width: 100,
            //     align: "center",
            //     dataIndex: "secondary_classification",
            //     key: "secondary_classification",
            // },
            // {
            //     title: "颜色编号",
            //     width: 100,
            //     align: "center",
            //     dataIndex: "color_number",
            //     key: "color_number",
            // },
            // {
            //     title: "颜色名称",
            //     width: 100,
            //     align: "center",
            //     dataIndex: "color",
            //     key: "color",
            // },
            // {
            //     title: "零售价",
            //     width: 120,
            //     align: "center",
            //     dataIndex: "price",
            //     key: "price",
            // },
            // {
            //     title: "日销价",
            //     width: 100,
            //     align: "center",
            //     dataIndex: "daily_price",
            //     key: "daily_price",
            // },
            // {
            //     title: "标签",
            //     dataIndex: "label",
            //     key: "label",
            //     width: 100,
            //     align: "center",
            // },
            // {
            //     title: "商品简称",
            //     dataIndex: "introduction",
            //     key: "introduction",
            //     width: 180,
            //     align: "center",
            // },
            // {
            //     title: "系列",
            //     dataIndex: "series",
            //     key: "series",
            //     width: 150,
            //     align: "center",
            // },
            // {
            //     title: "材质",
            //     dataIndex: "material",
            //     key: "material",
            //     width: 120,
            //     align: "center",
            // },
            // {
            //     title: "镶嵌",
            //     dataIndex: "inlay",
            //     key: "inlay",
            //     width: 100,
            //     align: "center",
            // },
            // {
            //     title: "品牌",
            //     dataIndex: "brand",
            //     key: "brand",
            //     width: 100,
            //     align: "center",
            // },
            // {
            //     title: "适用性别",
            //     dataIndex: "sex",
            //     key: "sex",
            //     width: 100,
            //     align: "center",
            // },
            // {
            //     title: "采购成本",
            //     dataIndex: "cost",
            //     key: "cost",
            //     width: 100,
            //     align: "center",
            // }
        ])
    },[columnsList])
    // 添加数据表格
    const addColumns = [
        {
            title: "移除",
            dataIndex: "cost",
            key: "cost",
            width: 100,
            fixed: "left",
            align: "center",
            render: (text, record, index) => {
                return  <Popconfirm title="确认要移除？" okText="确认" cancelText="取消" onConfirm={()=>{
                            let obj = [...addData]
                            obj.splice((addPage - 1) * 5 + index , 1)
                            setAddData([])
                            setAddData(obj)
                        }}>
                            <DeleteOutlined />
                        </Popconfirm>
            },
        },
        {
            title: "款图",
            dataIndex: "picture",
            key: "picture",
            width: 100,
            fixed: "left",
            align: "center",
            render: (text, record, index) => {
                  return <Image src={`https://pic.bmcsoft.cn/imge_dir/item_info/joomilim/${record.商品平台编号}.png`} width="50px" alt="" />;
            },
        },
        {
            title: "款号",
            width: 120,
            align: "center",
            dataIndex: "款号(SPU)",
            key: "款号(SPU)",
            fixed: "left",
            ellipsis: {
                showTitle: false,
            },
            render: (text, record, index) => (
                <Tooltip placement="topLeft" title={text}>
                  {text}
                </Tooltip>
            )
        },
        {
            title: "商品ID",
            width: 150,
            align: "center",
            dataIndex: "商品平台编号",
            key: "商品平台编号",
            fixed: "left",
        },
        {
            title: "商家编码",
            width: 160,
            align: "center",
            dataIndex: "商家编码",
            key: "商家编码",
        },
        {
            title: "产品年份",
            dataIndex: "产品年份",
            key: "产品年份",
            width: 100,
            align: "center",
        },
        {
            title: "上新波段",
            width: 100,
            align: "center",
            dataIndex: "上新波段",
            key: "上新波段",
        },
        {
            title: "一级分类",
            width: 100,
            align: "center",
            dataIndex: "一级分类",
            key: "一级分类",
        },
        {
            title: "二级分类",
            width: 100,
            align: "center",
            dataIndex: "二级分类",
            key: "二级分类",
        },
        {
            title: "颜色编号",
            width: 100,
            align: "center",
            dataIndex: "颜色编号",
            key: "颜色编号",
        },
        {
            title: "颜色名称",
            width: 100,
            align: "center",
            dataIndex: "颜色名称",
            key: "颜色名称",
        },
        {
            title: "零售价",
            width: 120,
            align: "center",
            dataIndex: "零售价",
            key: "零售价",
        },
        {
            title: "日销价",
            width: 100,
            align: "center",
            dataIndex: "日销价",
            key: "日销价",
        },
        {
            title: "标签",
            dataIndex: "标签",
            key: "标签",
            width: 100,
            align: "center",
        },
        {
            title: "商品简称",
            dataIndex: "商品简称",
            key: "商品简称",
            width: 180,
            align: "center",
        },
        {
            title: "系列",
            dataIndex: "系列",
            key: "系列",
            width: 150,
            align: "center",
        },
        {
            title: "材质",
            dataIndex: "材质",
            key: "材质",
            width: 120,
            align: "center",
            ellipsis: {
                showTitle: false,
            },
            render: (text, record, index) => (
                <Tooltip placement="topLeft" title={text}>
                  {text}
                </Tooltip>
            )
        },
        {
            title: "镶嵌",
            dataIndex: "镶嵌",
            key: "镶嵌",
            width: 100,
            align: "center",
        },
        {
            title: "品牌",
            dataIndex: "品牌",
            key: "品牌",
            width: 100,
            align: "center",
        },
        {
            title: "适用性别",
            dataIndex: "适用性别",
            key: "适用性别",
            width: 100,
            align: "center",
        },
        {
            title: "采购成本",
            dataIndex: "采购成本",
            key: "采购成本",
            width: 100,
            align: "center",
        },
      
    ];

    // 更新数据表格
    const updateColumns = [
        {
            title: "移除",
            dataIndex: "cost",
            key: "cost",
            width: 100,
            fixed: "left",
            align: "center",
            render: (text, record, index) => {
                return  <Popconfirm title="确认要移除？" okText="确认" cancelText="取消" onConfirm={()=>{
                            let obj = [...updateData]
                            obj.splice((addPage - 1) * 5 + index , 1)
                            setUpdateData([])
                            setUpdateData(obj)
                        }}>
                            <DeleteOutlined />
                        </Popconfirm>
            },
        },
        {
            title: "款图",
            dataIndex: "picture",
            key: "picture",
            width: 100,
            fixed: "left",
            align: "center",
            render: (text, record, index) => {
                  return <Image src={`https://pic.bmcsoft.cn/imge_dir/item_info/joomilim/${record.商品平台编号}.png`} width="50px" alt="" />;
            },
        },
        {
            title: "款号",
            width: 120,
            align: "center",
            dataIndex: "款号(SPU)",
            key: "款号(SPU)",
            fixed: "left",
            ellipsis: {
                showTitle: false,
            },
            render: (text, record, index) => (
                <Tooltip placement="topLeft" title={text}>
                  {text}
                </Tooltip>
            )
        },
        {
            title: "商品ID",
            width: 150,
            align: "center",
            dataIndex: "商品平台编号",
            key: "商品平台编号",
            fixed: "left",
        },
        {
            title: "商家编码",
            width: 160,
            align: "center",
            dataIndex: "商家编码",
            key: "商家编码",
        },
        {
            title: "产品年份",
            dataIndex: "产品年份",
            key: "产品年份",
            width: 100,
            align: "center",
        },
        {
            title: "上新波段",
            width: 100,
            align: "center",
            dataIndex: "上新波段",
            key: "上新波段",
        },
        {
            title: "一级分类",
            width: 100,
            align: "center",
            dataIndex: "一级分类",
            key: "一级分类",
        },
        {
            title: "二级分类",
            width: 100,
            align: "center",
            dataIndex: "二级分类",
            key: "二级分类",
        },
        {
            title: "颜色编号",
            width: 100,
            align: "center",
            dataIndex: "颜色编号",
            key: "颜色编号",
        },
        {
            title: "颜色名称",
            width: 100,
            align: "center",
            dataIndex: "颜色名称",
            key: "颜色名称",
        },
        {
            title: "零售价",
            width: 120,
            align: "center",
            dataIndex: "零售价",
            key: "零售价",
        },
        {
            title: "日销价",
            width: 100,
            align: "center",
            dataIndex: "日销价",
            key: "日销价",
        },
        {
            title: "标签",
            dataIndex: "标签",
            key: "标签",
            width: 100,
            align: "center",
        },
        {
            title: "商品简称",
            dataIndex: "商品简称",
            key: "商品简称",
            width: 180,
            align: "center",
        },
        {
            title: "系列",
            dataIndex: "系列",
            key: "系列",
            width: 150,
            align: "center",
        },
        {
            title: "材质",
            dataIndex: "材质",
            key: "材质",
            width: 120,
            align: "center",
            ellipsis: {
                showTitle: false,
            },
            render: (text, record, index) => (
                <Tooltip placement="topLeft" title={text}>
                  {text}
                </Tooltip>
            )
        },
        {
            title: "镶嵌",
            dataIndex: "镶嵌",
            key: "镶嵌",
            width: 100,
            align: "center",
        },
        {
            title: "品牌",
            dataIndex: "品牌",
            key: "品牌",
            width: 100,
            align: "center",
        },
        {
            title: "适用性别",
            dataIndex: "适用性别",
            key: "适用性别",
            width: 100,
            align: "center",
        },
        {
            title: "采购成本",
            dataIndex: "采购成本",
            key: "采购成本",
            width: 100,
            align: "center",
        },
      
    ];

    // 添加数据分页数据
    const addPaginationProps = {
        pageSize: 5, // 每页条数
        current: addPage,
        onChange: page => {
            //改变页码
            setAddPage(page)
        }
    }

    // 更新数据分页数据
    const updatePaginationProps = {
        pageSize: 5, // 每页条数
        current: updatePage,
        onChange: page => {
            //改变页码
            setUpdatePage(page)
        }
    }

    // 多选设置
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setId(selectedRowKeys[0]);
        setPutShelvesTime(selectedRows[0]['put_shelves_date'])
        baseForm.resetFields()
        baseForm.setFieldsValue(selectedRows[0])
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        type:"radio",
        selectedRowKeys,
        onChange: onSelectChange,
    }

    // 初始化参数
    useEffect(()=>{
        getJewelryItemInfoField()
    },[])

    // 已选择菜单修改的数据
    useEffect(()=>{
        if(chosedMenus.length != 0){
            // 处理表格表头显示的字段
            let columnsList = []
            let fieldStrList = []
            confirmChosedMenus.map(item=>{
                let obj = {
                    'key': item.split(',')[0],
                    'value': item.split(',')[1]
                }
                columnsList.push(obj)
                fieldStrList.push(item.split(',')[1])
            })
            setColumnsList(columnsList)
            // 处理显示字段
            setFieldStr(fieldStrList);
        }
    },[confirmChosedMenus])

    // 显示字段修改的时候
    useEffect(()=>{
       if(fieldStr.length != 0){
            getJewelryItemInfo()
       }
    },[fieldStr])

    useEffect(()=>{
        getJewelryItemInfo()
    },[page])

    useEffect(()=>{
        if(searchData.length == 0){
            if(page == 1){
                getJewelryItemInfo()
            }else{
                setPage(1)
            }
        }
    },[searchData])

    // 获取商品基础信息
    const getJewelryItemInfo = ()=>{
        setloading(true) 
        fieldStr.push('goods_platform_id')
        fieldStr.push('merchant_code')
        fieldStr.push('spu')
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            'shopId':'0188223a-5046-4afe-a43e-8762d3bd71ed',
            'field_list': fieldStr,
            'condition_list': searchData,
            'page': page,
            'page_size': 10
        }
        NbAxios(dataProps, "POST", servicePath.queryJewelryItemInfo).then(res => {
            setloading(false) 
            if (res.success) {
                setData(res.data);
                setTotal(res.total);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    
    // 查询首饰商品基础信息字段接口
    const getJewelryItemInfoField = ()=>{
        setloading(true)   
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            'shopId':'0188223a-5046-4afe-a43e-8762d3bd71ed'
        }
        NbAxios(dataProps, "POST", servicePath.queryJewelryItemInfoField).then(res => {
            if (res.success) {
                // 可选项
                let choseList = JSON.parse(JSON.stringify(res.data))
                choseList.map((item,index)=>{
                    if(item.value == 'spu'){
                        item.key = '款号'
                    }
                    if(item.value == 'goods_platform_id'){
                        item.key = '商品ID'
                    }
                    if(item.value == "put_shelves_date"){
                        choseList.splice(index, 1)
                    }
                })
                setChoseList(choseList);
                // 深拷贝
                let columnsList = JSON.parse(JSON.stringify(res.data))
                // 初始化表头
                columnsList.map((item,index)=>{
                    if(item.value == 'spu' || item.value == 'goods_platform_id' || item.value == 'merchant_code'){
                        columnsList.splice(index, 1)
                    }
                })
                // 表头数据
                // setColumnsList(columnsList)
                // 可选菜单数据
                setChoseMenuList(columnsList)
                let chosedMenus = []
                columnsList.map(item=>{
                    chosedMenus.push(item.key + ',' + item.value)
                })
                // 已选择菜单数据
                setChosedMenus(chosedMenus);
                // 确认已选择菜单数据
                setConfirmChosedMenus(chosedMenus)
            } else {
                setloading(false) 
                message.warn(res.errorMsg)
            }
        })
    }

    // 预算目标文件上传
    const baseFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setBaseFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setBaseFile(fileList)
        }
    }

    // 上传商品基础信息
    const uploadBaseFile = ()=>{
        if (baseFile.length != 0) {
            setEffective(1)
            const formData = new FormData()
            // 多个图片
            baseFile.forEach((item) => {
                formData.append('file', item['originFileObj']);
            })
            formData.append('shopList', cookie.load('shopList'));
            formData.append('shopId', '0188223a-5046-4afe-a43e-8762d3bd71ed')
            formData.append('roleId', cookie.load('role'));
            NbAxios(formData, "POST", servicePath.checkJewelryItemInfo).then(res => {
                setEffective(0)
                if (res.success) {
                    setBaseFile([])
                    message.success('文件数据上传成功')
                    setAddData(res.data['add']);
                    setUpdateData(res.data['update']);
                    setIsResult(true)
                    setIsUpload(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }else{
            message.warn('请选择文件')
        }
    }

    // 保存数据
    const saveItemInfo = ()=>{
        let goods_data = {
            'add': addData,
            'update': updateData
        }
        // let goods_data_json = JSON.stringify(goods_data)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            'shopId':'0188223a-5046-4afe-a43e-8762d3bd71ed',
            'goods_data':goods_data,
            'only_check':0
        }
        NbAxios(dataProps, "POST", servicePath.saveJewelryItemInfo).then(res => {
            if (res.success) {
                message.success('数据保存成功')
                setIsResult(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 更新商品基础信息
    const updateBase=(values)=>{
        let update = []
        let dataProps = {}
        dataProps['款号(SPU)'] = values.spu
        dataProps['商品平台编号'] = values.goods_platform_id
        dataProps['商家编码'] = values.merchant_code
        dataProps['产品年份'] = values.product_year
        dataProps['上新波段'] = putShelvesTime
        dataProps['一级分类'] = values.primary_category
        dataProps['二级分类'] = values.secondary_category
        dataProps['颜色编号'] = values.color_number
        dataProps['颜色名称'] = values.color_name
        dataProps['零售价'] = values.retail_price
        dataProps['日销价'] = values.daily_sales_price
        dataProps['标签'] = values.tag
        dataProps['商品简称'] = values.goods_abbr
        dataProps['系列'] = values.series
        dataProps['材质'] = values.material
        dataProps['镶嵌'] = values.inlay
        dataProps['品牌'] = values.brand
        dataProps['适用性别'] = values.apply_gender
        dataProps['采购成本'] = values.purchase_costs
        update.push(dataProps);
        let goods_data = {
            'add': [],
            'update': update
        }
        let obj = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            'shopId':'0188223a-5046-4afe-a43e-8762d3bd71ed',
            'goods_data':goods_data,
            'only_check':0
        }
        NbAxios(obj, "POST", servicePath.saveJewelryItemInfo).then(res => {
            if (res.success) {
                setId('');
                setPutShelvesTime('');
                setSelectedRowKeys([])
                message.success('修改商品基础信息成功')
                getJewelryItemInfo()
                setIsUpdate(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

   


    return (
        <div>
            <Spin tip="数据请求中....." spinning={loading}>
                <div style={{ marginTop: 10, marginLeft: 20, display:'flex', justifyContent:'space-between' }}>
                    <div style={{display:'flex'}}>
                        <Button type="primary" onClick={() => setIsUpload(true)}>上传商品基础信息</Button>
                        <Button disabled={id == '' ? true : false} style={{marginLeft:10}} onClick={()=>{
                            setIsUpdate(true)
                        }}>修改商品基础信息</Button>
                        <a href="https://pic.bmcsoft.cn/imge_dir/首饰商品基础数据上传模板.xlsx" style={{marginLeft:10}} ><Button type="dashed">下载首饰商品基础数据上传模版</Button></a>
                    </div>
                    <div className={style.menuContent} onMouseOver={()=>{
                              // js 操作对象css的值
                              let obj = document.getElementById('choseMenuContent')
                              obj.style.display = 'block';
                        }} onMouseOut={()=>{
                            // js 操作对象css的值
                            let obj = document.getElementById('choseMenuContent')
                            obj.style.display = 'none';
                      }}>
                        <MenuOutlined className={style.menuIcon} />
                        <div className={style.choseMenuContent} id="choseMenuContent">
                            <Checkbox.Group value={chosedMenus} style={{marginTop:10, marginLeft:20}} onChange={(value)=>{
                                setChosedMenus(value)
                            }}>
                                <Row>
                                   {
                                       choseMenuList.map(item=>{
                                         return <Col span={8} style={{marginTop:5}}>
                                             <Checkbox value={item.key + ',' + item.value}>{item.key}</Checkbox>
                                         </Col>
                                       })
                                   }
                                </Row>
                            </Checkbox.Group>
                            <Button type="primary" style={{marginLeft:'210px', marginTop:10}} onClick={()=>{
                                // js 操作对象css的值
                                let obj = document.getElementById('choseMenuContent')
                                obj.style.display = 'none';
                                setConfirmChosedMenus(chosedMenus)
                            }}>确定</Button>
                        </div>
                    </div>

                </div>
                <div style={{marginLeft:20, marginRight:20}}>
                    <Collapse style={{marginTop:10, marginBottom:10}}>
                        <Panel header="查询条件">
                            <Row style={{ width: '88vw', }}>
                                {
                                    searchData.map((item, index) => {
                                        return <Col span={6} style={{ display: 'flex', marginTop: 5 }}>
                                            <Select style={{ width: 150 }} value={searchData[index]['sort']} onChange={(value) => {
                                                let flag = 1
                                                exitChoseList.map(item=>{
                                                    if(item.key ==  value.split(',')[0]){
                                                            flag = 0
                                                    }
                                                })
                                                if (flag) {
                                                    if (searchData[index]['sort'] != '') {
                                                        let obj = [...exitChoseList]
                                                        obj.splice(index, 1)
                                                        let objValue = {
                                                            'key': value.split(',')[0],
                                                            'value': value.split(',')[1]
                                                        }
                                                        obj.push(objValue)
                                                        setExitChostList(obj)
                                                        let searchDataObj = [...searchData]
                                                        setSearchData([])
                                                        searchDataObj[index]['value'] = []
                                                        searchDataObj[index]['sort'] = value.split(',')[0]
                                                        searchDataObj[index]['sortValue'] = value.split(',')[1]
                                                        if ( value.split(',')[0] == '商品ID' || value.split(',')[0] == '款号' || value.split(',')[0] == '商家编码') {
                                                            setSearchData(searchDataObj)
                                                        }else{
                                                            let dataProps = {
                                                                "roleId": cookie.load('role'),
                                                                "shopList": cookie.load('shopList'),
                                                                'shopId':'0188223a-5046-4afe-a43e-8762d3bd71ed',
                                                                'field_str': value.split(',')[1]
                                                            }
                                                            NbAxios(dataProps, "POST", servicePath.queryJewelryItemInfoCondition).then(res => {
                                                                if (res.success) {
                                                                    searchDataObj[index]['value_chose_list'] = res.data
                                                                    setSearchData(searchDataObj)
                                                                } else {
                                                                    message.warn(res.errorMsg)
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        let obj = [...exitChoseList]
                                                        setExitChostList([])
                                                        let objValue = {
                                                            'key': value.split(',')[0],
                                                            'value': value.split(',')[1]
                                                        }
                                                        obj.push(objValue)
                                                        setExitChostList(obj)
                                                        let searchDataObj = [...searchData]
                                                        setSearchData([])
                                                        searchDataObj[index]['sort'] = value.split(',')[0]
                                                        searchDataObj[index]['sortValue'] = value.split(',')[1]
                                                        if ( value.split(',')[0] == '商品ID' || value.split(',')[0] == '款号' || value.split(',')[0] == '商家编码') {
                                                            setSearchData(searchDataObj)
                                                        }else{
                                                            let dataProps = {
                                                                "roleId": cookie.load('role'),
                                                                "shopList": cookie.load('shopList'),
                                                                'shopId':'0188223a-5046-4afe-a43e-8762d3bd71ed',
                                                                'field_str': value.split(',')[1]
                                                            }
                                                            NbAxios(dataProps, "POST", servicePath.queryJewelryItemInfoCondition).then(res => {
                                                                if (res.success) {
                                                                    searchDataObj[index]['value_chose_list'] = res.data
                                                                    setSearchData(searchDataObj)
                                                                } else {
                                                                    message.warn(res.errorMsg)
                                                                }
                                                            })
                                                        }
                                                    }
                                                } else {
                                                    message.warn('已存在这个选择项了')
                                                }

                                            }}>
                                                {
                                                    item.chose_list.map(i => {
                                                        return <Option value={i.key + ',' + i.value}>{i.key}</Option>
                                                    })
                                                }
                                            </Select>
                                            {
                                                searchData[index]['sort'] == '商品ID' ? <Tooltip placement="top" title="支持模糊查询">
                                                        <Input style={{ width: 200 }} value={searchData[index]['value']} onChange={(e) => {
                                                            let obj = [...searchData]
                                                            obj[index]['value'] = e.target.value
                                                            setSearchData(obj)
                                                        }} />
                                                    </Tooltip>
                                                    : searchData[index]['sort'] == '款号' ? <Tooltip placement="top" title="支持模糊查询">
                                                        <Input style={{ width: 200 }} value={searchData[index]['value']} onChange={(e) => {
                                                            let obj = [...searchData]
                                                            obj[index]['value'] = e.target.value
                                                            setSearchData(obj)
                                                        }} />
                                                    </Tooltip>
                                                    : searchData[index]['sort'] == '商家编码' ? <Tooltip placement="top" title="支持模糊查询">
                                                        <Input style={{ width: 200 }} value={searchData[index]['value']} onChange={(e) => {
                                                            let obj = [...searchData]
                                                            obj[index]['value'] = e.target.value
                                                            setSearchData(obj)
                                                        }} />
                                                    </Tooltip>
                                                    :<Select showSearch value={searchData[index]['value']} className={style.sitemore} style={{ width: 400 }} mode="multiple" onChange={(value) => {
                                                        let obj = [...searchData]
                                                        obj[index]['value'] = value
                                                        setSearchData(obj)
                                                    }}>
                                                        {
                                                            item.value_chose_list.map(i => {
                                                                return <Option value={i}>{i}</Option>
                                                            })
                                                        }
                                                    </Select>
                                            }
                                            <MinusCircleOutlined style={{ marginTop: 5, marginLeft: 10, marginRight: 10 }} onClick={() => {
                                                if (searchData[index]['sort'] != '') {
                                                    let exitChoseObj = [...exitChoseList]
                                                    setExitChostList([])
                                                    exitChoseObj.splice(index, 1)
                                                    setExitChostList(exitChoseObj)
                                                    let obj = [...searchData]
                                                    obj.splice(index, 1);
                                                    setSearchData(obj)
                                                } else {
                                                    let obj = [...searchData]
                                                    obj.splice(index, 1);
                                                    setSearchData(obj)
                                                }
                                            }} />
                                        </Col>
                                    })
                                }
                                <PlusCircleOutlined style={{ marginLeft: 20, marginTop: 10 }} onClick={() => {
                                    let obj = [...searchData]
                                    setSearchData([])
                                    let arr3 = [...choseList]
                                    // 已存在key集合
                                    exitChoseList.map(item=>{
                                        arr3.map((i,index)=>{
                                            if(i.key == item.key){
                                                arr3.splice(index, 1)
                                            }
                                        })
                                    })
                                    obj.push({
                                        'sort': '',
                                        'sortValue': '',
                                        'value': [],
                                        'chose_list': arr3,
                                        'value_chose_list': []
                                    })
                                    setSearchData(obj);
                                }} />

                            </Row>
                            <div style={{ marginTop: 20, marginLeft: 50, display: 'flex', justifyContent:'flex-end' }}>
                                <Button type="primary" onClick={() => {
                                    let flag = 0
                                    searchData.map(item=>{
                                        if(item['sortValue'] == ''){
                                            flag = 1
                                        }
                                    })
                                    if(flag){
                                        message.warn('请选择要查询的选项')
                                    }else{
                                        if(page == 1){
                                            getJewelryItemInfo()
                                        }else{
                                            setPage(1)
                                        }
                                    }
                                }}>搜索</Button>
                                <Button type="primary" danger onClick={() => {
                                    setSearchData([])
                                    setExitChostList([])
                                }}>重置</Button>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
                <div style={{marginLeft:20, marginRight:20}}>
                    <Table
                        dataSource={data}
                        columns={columns}
                        rowSelection={rowSelection}
                        scroll={{ x: 1600 }}
                        rowKey={"goods_platform_id"}
                        pagination={paginationProps}
                    />
                </div>
                
                <Modal
                    title="上传商品基础信息"
                    visible={isUpload}
                    centered={true}
                    okText="确定"
                    cancelText="取消"
                    maskClosable={true}
                    onOk={() => uploadBaseFile()}
                    onCancel={() => {
                        message.success('取消上传商品基础信息')
                        setBaseFile([])
                        setIsUpload(false)
                    }}
                    okButtonProps={{disabled: effective == 1 ? true : false}}
                    cancelButtonProps={{disabled: effective == 1 ? true : false}}
                >
                    <Dragger
                        // accept=".pdf .doc .docx"
                        action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                        fileList={baseFile}
                        onChange={baseFileHandleChange}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                        <p className="ant-upload-hint">
                            仅支持上传一份后缀为.xlsx文件
                        </p>
                    </Dragger>
                </Modal>

                <Modal
                    title="编辑商品基础信息"
                    visible={isUpdate}
                    okText="确定"
                    cancelText="取消"
                    centered="true"
                    onCancel={()=>{
                        setId('');
                        setPutShelvesTime('');
                        setSelectedRowKeys([])
                        message.success('取消编辑商品基础信息')
                        setIsUpdate(false)
                    }}
                    onOk={()=>{
                        setIsUpdate(false)
                    }}
                    maskClosable={false}
                    footer={null}
                    width={900}
                >
                    <Form form={baseForm} {...layout} onFinish={updateBase}>
                        {/* 图片区域 */}
                        <div style={{display:'flex'}}>  
                            <div style={{width:300, height:200}}>
                                <Image src={`https://pic.bmcsoft.cn/imge_dir/item_info/joomilim/${id}.png`} style={{width:"250px", height:'200px'}} alt="" />
                            </div>
                            <div style={{width:600}}>
                                <Row>
                                
                                    <Col span={12}>
                                        <Form.Item label="品牌" name="brand">
                                            <Text type="secondary">{baseForm.getFieldValue('brand')}</Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="商品ID" name="goods_platform_id">
                                            <Text type="secondary">{baseForm.getFieldValue('goods_platform_id')}</Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="款号" name="spu">
                                            <Text type="secondary">{baseForm.getFieldValue('spu')}</Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="商家编码" name="merchant_code">
                                            <Text type="secondary">{baseForm.getFieldValue('merchant_code')}</Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="产品年份" name="product_year">
                                            <Input style={{width:180}} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="上新波段">    
                                           <DatePicker 
                                                locale={locale}
                                                value={moment(putShelvesTime, dateFormat)}
                                                onChange={(date, dateString) => {
                                                    if (dateString == '') {
                                                        message.warn('请选择正确的时间')
                                                        return false
                                                    } else{
                                                        setPutShelvesTime(dateString)
                                                    }
                                                }}
                                           />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="一级分类" name="primary_category">
                                            <Input style={{width:180}} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="二级分类" name="secondary_category">
                                            <Input style={{width:180}} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Row>
                            <Col span={8}>
                                <Form.Item label="颜色编号" name="color_number">
                                    <Input style={{width:180}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="颜色名称" name="color_name">
                                    <Input style={{width:180}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="零售价" name="retail_price">
                                    <Input style={{width:180}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="日销价" name="daily_sales_price">
                                    <Input style={{width:180}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="标签" name="tag">
                                    <Input style={{width:180}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="商品简称" name="goods_abbr">
                                    <Input style={{width:180}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="系列" name="series">
                                    <Input style={{width:180}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="镶嵌" name="inlay">
                                    <Input style={{width:180}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="适用性别" name="apply_gender">
                                    <Select value={baseForm.getFieldValue('apply_gender')}>
                                        <Option value="男性">{"男性"}</Option>
                                        <Option value="女性">{"女性"}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="采购成本" name="purchase_costs">
                                    <Input style={{width:180}} />
                                </Form.Item>
                            </Col>
                            <div style={{ marginLeft:'-100px'}}>
                                <Form.Item label="材质" name="material">
                                    <TextArea rows={4} style={{width:620}} />
                                </Form.Item>
                            </div>
                        </Row>
                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                            <Button type="primary" htmlType="submit">
                                确认
                            </Button>
                            <Button type="primary" htmlType="submit" danger onClick={()=>{
                                setId('');
                                setPutShelvesTime('');
                                setSelectedRowKeys([])
                                message.success('取消编辑商品基础信息')
                                setIsUpdate(false)
                            }}>
                                取消
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    maskClosable={true}
                    title="数据反馈确认表"
                    visible={isResult}
                    cancelText="取消全部数据导入"
                    okText="确定"
                    onCancel={()=>{
                        message.success('取消全部数据导入')
                        setAddData([])
                        setUpdateData([])
                        setIsResult(false)
                    }}
                    onOk ={()=>saveItemInfo()}
                    centered={true}
                    width={1200}
                >
                    <Divider>新增的数据</Divider>
                    <Table
                        dataSource={addData}
                        columns={addColumns}
                        pagination={addPaginationProps}
                        scroll={{ x: 1600 }}
                    />
                    <Divider>更新的数据</Divider>
                    <Table
                        dataSource={updateData}
                        columns={updateColumns}
                        pagination={updatePaginationProps}
                        scroll={{ x: 1600 }}
                    />
                </Modal>
            </Spin>
        </div>
    )

}

export default Index