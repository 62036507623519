import React, { useEffect } from 'react';
import Debounce from '../../../config/utils/debounce';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

function GoodsChart({
    id,
    title,
    xZhou,
    barData,
    lineData,
    ...rest
}) {
    const total = barData.reduce((pre,now)=>pre+now,0)
    let labelOption = {
        show: true,
        align: 'center',
        position: 'top',
        distance: 5,
        formatter: ({data}) => (data * 100 / total).toFixed(0).toString() + "%",
        fontSize: "1rem",
        color: "rgba(0,0,0,.8)"
    };
    let report = {
        title: {
            text: title,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '100px',
            right: '10%',
            left: '10%',
        },
        dataZoom: [{
            type: "inside"         //详细配置可见echarts官网
        }],
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: ['销售额', '同比'],
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: xZhou,
                axisLine: {
                    lineStyle: {
                        // color: 'transparent'
                    },
                    onZero: true,
                    show: false
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '销售额/万元',
                position: 'left',
                // interval: 50,
                axisLabel: {
                    formatter: function (data) {
                        return (data/10000);
                    }
                },
                axisTick: {
                    show: true
                },
                axisLine: {
                    show: false
                },
            },
            {
                show: false,
                type: 'value',
                name: '同比%',
                position: 'right',
                // interval: 50,
                axisTick: {
                    show: true
                },
                axisLine: {
                    show: false
                },
            },
        ],
        series: [{
            name: "销售额",
            data: barData,
            type: 'bar',
            label: labelOption,
            itemStyle: {
                color: 'rgba(58, 161, 255,.9)'
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(58, 161, 255)',
                }
            }
        }, {
            yAxisIndex: 1,
            name: "同比",
            data: lineData,
            type: 'line',
            itemStyle: {
                color: 'rgba(78, 203, 117)'
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(78, 203, 117)',
                }
            }
        }]
    };
    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById(id));
        // 绘制图表
        reportChart.setOption(report)
        window.addEventListener('resize', () => {
            Debounce(reportChart.resize(), 200)
        });
    },[xZhou,barData,lineData])
    return (
        <div id={id} style={{ width: '100%', height: "400px" }} ></div>
    )
}

export default GoodsChart