import React, { useCallback, useState, useEffect, memo, useMemo } from 'react';
import { Table, DatePicker, Checkbox, Button, Typography, message, Select, Popover, Tooltip, Form, Modal, Col, Row, Input, Spin } from 'antd'
import moment from 'moment'
import TargetCard from '../saleBoard/components/targetCard';
import IndicatorCard from '../saleBoard/components/indicatorCard';
import { CloudDownloadOutlined, SearchOutlined, RightOutlined, SettingOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios'
import locale from 'antd/es/date-picker/locale/zh_CN';
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl'
import cookie from 'react-cookies'
import style from './style.module.css';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
const { Option } = Select;
const { Column } = Table
const MemoTargetCard = memo(TargetCard)
const MemoIndicatorCard = memo(IndicatorCard)
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD';
const monthDateFormat = 'YYYY-MM';
const { Title, Paragraph, Text, Link } = Typography;

function Daily(props) {
    let shopIdDict = {
        'zhuoya': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'gs':'703247c1-a200-42bc-b70f-742dbab27aa4',
        'threePets':'82328e58-068b-4a27-9bd0-9d1649cb8e6e',
        'qda':"2715f3fa-0dae-4dc7-bcfc-0dd912314e40"
    }
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    };
    const targetMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const targetFooter = (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" onClick={submitTarget} >提交</Button>
            <Button onClick={controlSetting} >取消</Button>
        </div>
    )
    const formList = targetMonths.map((item, index) => {
        return {
            name: `${item}`,
            label: `${item}月`
        }
    })
    // 目标年份改变
    function yearChange(date, dateString) {
        if (!date) {
            message.warn('时间不可以为空！')
            setTargetYear(moment())
            return false
        } else {
            setTargetYear(date)
            getTargetData(date, targetType)
        }
    }

    // 绑定表单数据
    const [targetForm] = Form.useForm()
    // 封装memo，阻止重复渲染
    function useMyMemo(data, deps) {
        return useMemo(() => {
            return data
        }, [deps ? deps : data])
    }
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    // 经营数据看版数据
    const [indicatorCardList, setIndicatorCardList] = useState(['转化率', '支付人数', '支付件数', '人均加购件数', '加购人数', '加购件数', '访客数', '实销金额', '退款金额', '销售额'])
    const [allIndicatorCardList] = useState(['转化率', '支付人数', '支付件数', '人均加购件数', '加购人数', '加购件数', '访客数', '实销金额', '退款金额', '销售额'])
    const settingContent = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Checkbox style={{ marginLeft: 8 }} checked={indicatorCardList.sort().toString() == allIndicatorCardList.sort().toString() ? true : false}
                onClick={(e) => {
                    if (e.target.checked) {
                        setIndicatorCardList(allIndicatorCardList)
                    } else {
                        setIndicatorCardList([])
                    }
                }}>全选</Checkbox>
            {
                allIndicatorCardList.map(item => {
                    return <Checkbox checked={indicatorCardList.indexOf(item) != -1 ? true : false} onClick={(e) => {
                        if (e.target.checked) {
                            let obj = [...indicatorCardList]
                            obj.push(item)
                            setIndicatorCardList(obj)
                        } else {
                            let obj = [...indicatorCardList]
                            obj.remove(item)
                            setIndicatorCardList(obj)
                        }
                    }}>{item}</Checkbox>
                })
            }
        </div>
    )
    function disabledDate(current) {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }
    // 计算两个日期之间的日期
    function getBetweenDateStr(start, end) {
        let result = [];
        let startTime = start.format('YYYY-MM-DD')
        let endTime = end.format('YYYY-MM-DD')
        let beginDay = startTime.split("-");
        let endDay = endTime.split("-");
        let diffDay = new Date();
        let dateList = new Array;
        let i = 0;
        diffDay.setDate(beginDay[2]);
        diffDay.setMonth(beginDay[1] - 1);
        diffDay.setFullYear(beginDay[0]);
        let startDay = startTime.substring(5, start.size)
        result.push(startDay);
        while (i == 0) {
            var countDay = diffDay.getTime() + 24 * 60 * 60 * 1000;
            diffDay.setTime(countDay);
            dateList[2] = diffDay.getDate();
            dateList[1] = diffDay.getMonth() + 1;
            dateList[0] = diffDay.getFullYear();
            if (String(dateList[1]).length == 1) { dateList[1] = "0" + dateList[1] };
            if (String(dateList[2]).length == 1) { dateList[2] = "0" + dateList[2] };
            result.push(dateList[1] + "-" + dateList[2]);
            if (dateList[0] == endDay[0] && dateList[1] == endDay[1] && dateList[2] == endDay[2]) {
                i = 1;
            }
        };
        result.reverse()
        return result;
    }

    // 品牌字典
    const trademarkDict = {
        'joomilim': 'joomilim',
        'billymargot': 'billymargot',
        'ivorycoat': 'ivorycoat',
        'airinum': 'airinum',
        'zhuoya': 'JORYA',
        'qda': 'QDA',
        'an': '恩曼琳',
        'xh': '经销',
        'z11': 'Z11',
        'cr': '卡洛琳',
        'iv': '常春藤',
        'vgrass': 'vgrass',
        'EIN': 'EIN',
        'mesux': 'mesux',
        'threePets':'threePets',
        'gs':'gs',
    }

    // 店铺字典
    const storeDict = {
        '': [
            {
                'name': '请选择店铺',
                'value': ''
            }
        ],
        'ivorycoat': [
            {
                'name': 'ivorycoat海外旗舰店',
                'value': 'ivorycoat'
            }
        ],
        'jr': [
            {
                'name': 'JORYA天猫店',
                'value': 'jr_tmall'
            },
            // {
            //     'name':'京东',
            //     'value':'jr_JD'
            // }
        ],
        'an': [
            {
                'name': 'AN天猫店',
                'value': 'an_tmall'
            }
        ],
        'qda': [
            {
                'name': 'QDA天猫店',
                'value': 'qda_tmall'
            }
        ],
        'joomilim': [
            {
                'name': 'Joomilim天猫店',
                'value': 'joomilim_tmall'
            }
        ],
        'iv': [
            {
                'name': 'IV天猫店',
                'value': 'iv_tmall'
            }
        ],
        'freiol': [
            {
                'name': 'freiol旗舰店',
                'value': 'freiol_tm'
            },
            {
                'name': 'freiol福来官方品牌店',
                'value': 'freiol'
            },
            {
                'name': 'FREIOL海外旗舰店',
                'value': 'freiol_tmgj'
            },
        ],
        'xh': [
            {
                'name': '欣贺女装outlets天猫店',
                'value': 'xh_outlets'
            },
            {
                'name': '欣贺女装outlets淘宝店',
                'value': 'xh_outlets_c'
            },
            {
                'name': 'LY高端精选女装店',
                'value': 'likeforlike'
            }
        ],
        'z11': [
            {
                'name': 'Z11天猫店',
                'value': 'Z11_tmall'
            },
            {
                'name': 'Z11官方企业店',
                'value': 'Z11_c'
            }
        ],
        'cr': [
            {
                'name': '卡洛琳天猫店',
                'value': 'cr_tmall'
            }
        ],
        'airinum': [
            {
                'name': 'airinum旗舰店',
                'value': 'airinum'
            }
        ],
        'billymargot': [
            {
                'name': 'billymargot海外旗舰店',
                'value': 'billymargot'
            }
        ],
        'vgrass': [
            {
                'name': 'vgrass女装旗舰店',
                'value': 'vgrass'
            }
        ],
        'ein': [
            {
                'name': 'EIN天猫店',
                'value': 'EIN_tmall'
            },
        ],
        'mesux': [
            {
                'name': 'mesux天猫店',
                'value': 'mesux_tmall'
            }
        ],
        'threePets': [
            {
                'name': '三只小宠天猫旗舰店',
                'value': 'threePets_tmall'
            }
        ],
        'gs':[
            {
                'name': '巨式天猫旗舰店',
                'value': 'gs_tmall'
            }
        ],
    }
    // 获取一月的天数
    const monthDays = moment().daysInMonth()
    // 获取一月的第几天
    const nowInMonth = parseInt(moment().format('DD'))
    // 获取一年的第几天
    const nowInyear = moment().dayOfYear()
    // 是否查看整体数据
    const [overallData, setOverallData] = useState(true)
    // 获取一年的天数
    const yearDays = parseInt(moment().format('YYYY')) / 4 === 0 ? 366 : 365
    // 本周销售
    const [weekPay, setWeekPay] = useState(1)
    // 上周销售
    const [lastWeekPay, setLastWeekPay] = useState(1)
    // 同比周
    const [weekCompare, setWeekCompare] = useState(1)
    // 本月销售
    const [monthPay, setMonthPay] = useState(1)
    // 上月销售
    const [lastMonthPay, setLastMonthPay] = useState(1)
    // 同比本月
    const [monthCompare, setMonthCompare] = useState(1)
    // 本年销售
    const [yearPay, setYearPay] = useState(1)
    // 去年销售
    const [lastYearPay, setLastYearPay] = useState(1)
    // 同比去年
    const [yearCompare, setYearCompare] = useState(1)
    // 退款率
    const [monthRefundRate, setMonthRefundRate] = useState(1)
    // 同比退款率
    const [monthRefundRateCompare, setMonthRefundRateCompare] = useState(1)
    // 年目标
    const [yearTarget, setYearTarget] = useState(1)
    // 月目标
    const [monthTarget, setMonthTarget] = useState(1)
    // 年实销目标
    const [yearGetTarget, setYearGetTarget] = useState(1)
    // 月实销目标
    const [monthGetTarget, setMonthGetTarget] = useState(1)
    // 月实销
    const [monthGetMoney, setMonthGetMoney] = useState(1)
    // 上月实销
    const [lastmonthGetMoney, setLastMonthGetMoney] = useState(1)
    // 年实销
    const [yearGetMoney, setyearGetMoney] = useState(1)
    // 去年实销
    const [lastYearGetMoney, setLastYearGetMoney] = useState(1)
    // 月实销同比
    const [monthGetCompare, setMonthGetCompare] = useState(1)
    // 年实销同比
    const [yearGetCompare, setYearGetCompare] = useState(1)
    // 退款率目标
    const [monthRefundRateTarget, setMonthRefundRateTarget] = useState(1)
    // 上月退款
    const [lastMonthRefund, setLastMonthRefund] = useState(1)
    // 表格数据
    const [tableData, setTableData] = useState([])
    // 品牌名称
    const [trademark, setTrademark] = useState('')
    // 店铺集合
    const [shopList, setShopList] = useState([])
    // 店铺
    const [shop, setShop] = useState('')
    // 店铺名字
    const [shopName, setShopName] = useState('')
    // 表头集合
    const [columnList, setColumnList] = useState([])
    // 选择的周期类型
    const [dateType, setDateType] = useState('day')
    // 开始日期
    const [startDate, setStartDate] = useState(moment().subtract(1, 'day').startOf(dateType))
    // 结束日期  
    const [endDate, setEndDate] = useState(moment().subtract(1, 'day').endOf(dateType))
    // 隐藏列表
    const [hideList] = useState(['支付完成率(%)', '回款完成率(%)', '支付目标', '回款目标'])
    // 销售额
    const [saleMoney, setSaleMoney] = useState(0)
    // 销售额同比
    const [saleMoneyCompare, setSaleMoneyCompare] = useState(0)
    // 退款金额
    const [refundMoney, setRefundMoney] = useState(0)
    // 退款金额同比
    const [refundMoneyCompare, setRefundMoneyCompare] = useState(0)
    // 退款率
    const [refundRate, setRefundRate] = useState(0)
    // 转化率
    const [changeRate, setChangeRate] = useState(0)
    // 转化率同比
    const [changeRateCompare, setChangeRateCompare] = useState(0)
    // 访客数
    const [visitor, setVisitor] = useState(0)
    // 访客数同比
    const [visitorCompare, setVisitorCompare] = useState(0)
    // 支付件数
    const [payPiece, setPayPiece] = useState(0)
    // 支付件数同比
    const [payPieceCompare, setPayPieceCompare] = useState(0)
    // 支付人数
    const [payPeople, setPayPeople] = useState(0)
    // 支付人数同比
    const [payPeopleCompare, setPayPeopleCompare] = useState(0)
    // 实销金额
    const [actualPay, setActualPay] = useState(0)
    // 实销金额同比
    const [actualPayCompare, setActualPayCompare] = useState(0)
    // 加购件数
    const [addPiece, setAddPiece] = useState(0)
    // 加购件数加购率
    const [addPieceRate, setAddPieceRate] = useState(0)
    // 加购件数同比
    const [addPieceCompare, setAddPieceCompare] = useState(0)
    // 商品加购人数
    const [goodsAddPeople, setGoodsAddPeople] = useState(0)
    // 商品加购人数同比
    const [goodsAddPeopleCompare, setGoodsAddPeopleCompare] = useState(0)
    // 商品加购人数加购率
    const [goodsAddPeopleRate, setGoodsAddPeopleRate] = useState(0)
    // 人均加购件数
    const [perAdd, setPerAdd] = useState(0)
    const [setting, setSetting] = useState(false)
    // 设置目标的类型
    const [targetType, setTargetType] = useState('支付')
    // 人均加购件数同比
    const [perAddCompare, setPerAddCompare] = useState(0)
    // 目标弹窗默认的年份
    const [targetYear, setTargetYear] = useState(moment())
    // 销售趋势月份
    const [ salesTrandMonth , setSalesTrandMonth] = useState(moment())
    // 当前月份
    const [ currentMonth ] = useState(moment().format('YYYY-MM'))
    // 日加购
    const [ dailyPurchaseLegend, setDailyPurchaseLegend] = useState(['','','',''])
    const [ dailyPurchaseData, setDailyPurchaseData] = useState([])
    const [ dailyPurchaseDate, setDailyPurchaseDate] = useState([{
        '加购1':[],
        '加购2':[],
        '销售1':[],
        '销售2':[],
    }])
    const [ grandTotalLegend, setGrandTotalLegend] = useState(['','','',''])
    const [ grandTotalData, setGrandTotalData] = useState([])
    const [ grandTotalDate, setGrandTotalDate] = useState([{
        '累计加购1':[],
        '累计加购2':[],
        '累计销售1':[],
        '累计销售2':[],
    }])
    // 加载
    const [ loading , setLoading ] = useState(false)
    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 获取品牌名
    useEffect(() => {
        let urlPath = props.location.pathname
        let urlList = urlPath.split('/')
        let trademark = urlList[3] == 'zhuoya' ? 'jr' : urlList[3]
        setTrademark(trademark)
        getTimeRangesaleStatistical_month(salesTrandMonth)
        getBusinessStaffDailyAdditionalPurchase(moment().startOf('month').format('YYYY-MM'))
        let dateList = getBetweenDateStr(moment().subtract(32, 'day'), moment().subtract(1, 'day'))
        setColumnList(dateList);
        let data = {}
        data.startTime = moment().subtract(32, 'day').format('YYYY-MM-DD')
        data.endTime = moment().subtract(1, 'day').format('YYYY-MM-DD')
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shop = storeDict[trademark][0]['value']
        return NbAxios(data, 'POST', servicePath.getBusinessStaffDailyResport).then(res => {
            if (res.success) {
                setTableData(res.data['data_dict_table']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }, [])

    // 获取店铺
    useEffect(() => {
        if(trademark != ''){
            getTableHeaderData()
            setShopList(storeDict[trademark])
            getTaskDaily()
            setShop(storeDict[trademark][0]['value'])
            setShopName(storeDict[trademark][0]['name'])
        }
    }, [trademark])


    function getBusinessStaffDailyAdditionalPurchase(dateString){
        let data = {}
        data.date = dateString
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shopId = shopIdDict[props.location.pathname.split('/')[3]]
        // data.shop_name = storeDict[props.location.pathname.split('/')[3] == 'zhuoya' ? 'jr' : props.location.pathname.split('/')[3]][0]['value']
        return NbAxios(data, 'POST', servicePath.getSalesTrendData).then(res => {
            if (res.success) {
                let data = res.data
                setDailyPurchaseDate(data['日趋势']['data']);
                setDailyPurchaseData(data['日趋势']['date']);
                setDailyPurchaseLegend(data['日趋势']['legend']);
                setGrandTotalLegend(data['累计趋势']['legend']);
                setGrandTotalData(data['累计趋势']['date']);
                setGrandTotalDate(data['累计趋势']['data']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    let dailyPurchaseChart = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: dailyPurchaseLegend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: dailyPurchaseData
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                position: 'right',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
          {
            name: dailyPurchaseLegend[0],
            type: 'line',
            data: dailyPurchaseDate['加购1']
          },
          {
            name: dailyPurchaseLegend[1],
            type: 'line',
            data: dailyPurchaseDate['加购2']
          },
          {
            name: dailyPurchaseLegend[2],
            type: 'bar',
            yAxisIndex: 1,
            data: dailyPurchaseDate['销售1']
          },
          {
            name: dailyPurchaseLegend[3],
            type: 'bar',
            yAxisIndex: 1,
            data: dailyPurchaseDate['销售2']
          }
        ]
    };

    let grandTotalChart = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: grandTotalLegend
        },
        grid: {
          left: '8%',
          right: '8%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: grandTotalData
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                position: 'right',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
          {
            name: grandTotalLegend[0],
            type: 'line',
            data: grandTotalDate['累计加购1']
          },
          {
            name: grandTotalLegend[1],
            type: 'line',
            data: grandTotalDate['累计加购2']
          },
          {
            name: grandTotalLegend[2],
            type: 'bar',
            yAxisIndex: 1,
            data: grandTotalDate['累计销售1']
          },
          {
            name: grandTotalLegend[3],
            type: 'bar',
            yAxisIndex: 1,
            data:  grandTotalDate['累计销售2']
          }
        ]
    };
    useEffect(()=>{
        let dailyPurchaseReportChart = echarts.init(document.getElementById('dailyPurchase'));
        dailyPurchaseReportChart.clear();    //清除残留渲染
        // 绘制图表
        dailyPurchaseReportChart.setOption(dailyPurchaseChart);
        window.addEventListener('resize', () => {
            dailyPurchaseReportChart.resize()
        });
        let grandTotalReportChart = echarts.init(document.getElementById('grandTotal'));
        grandTotalReportChart.clear();    //清除残留渲染
        // 绘制图表
        grandTotalReportChart.setOption(grandTotalChart);
        window.addEventListener('resize', () => {
            grandTotalReportChart.resize()
        });
    },[grandTotalDate])
    // 获取数据
    const getDailyData = () => {
        let data = {}
        data.startTime = startDate.format('YYYY-MM-DD')
        data.endTime = endDate.format('YYYY-MM-DD')
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shop = storeDict[trademark][0]['value']
        return NbAxios(data, 'POST', servicePath.getBusinessStaffDailyResport).then(res => {
            if (res.success) {
                setTableData(res.data['data_dict_table']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }

    // 日期切换函数
    function onChange(value, dateString) {
        setStartDate(dateString[0]);
        setEndDate(dateString[1])
    }

    // 近三十天
    const thirty = () => {
        setStartDate(moment().subtract(30, 'days').locale('zh-cn').format('YYYY-MM-DD'))
        setEndDate(moment().subtract(1, 'days').locale('zh-cn').format('YYYY-MM-DD'))
    }

    // 近六十天
    const sixty = () => {
        setStartDate(moment().subtract(60, 'days').locale('zh-cn').format('YYYY-MM-DD'))
        setEndDate(moment().subtract(1, 'days').locale('zh-cn').format('YYYY-MM-DD'))
    }

    // 近九十天
    const ninety = () => {
        setStartDate(moment().subtract(90, 'days').locale('zh-cn').format('YYYY-MM-DD'))
        setEndDate(moment().subtract(1, 'days').locale('zh-cn').format('YYYY-MM-DD'))
    }

    // 查看图标的函数
    const lookCharts = () => {
        props.history.push({ 'pathname': '/app/bussiness/dailyCharts', 'state': { 'trademark': trademark } })
    }

    // 切换店铺
    const changeShop = (value) => {
        // 切换店铺的值
        setShop(value)
        // 修改店铺名称
        setShopName(shopList[shopList.map(item => item.value).indexOf(value)]['name']);
    }
    // 日期变化 
    function dateChange(dates, dateStrings) {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }
    // 周期加减函数
    function dateCycleChange(type) {
        if (type === 'add') {
            setStartDate(moment(startDate).add(1, dateType).startOf(dateType))
            setEndDate(moment(startDate).add(1, dateType).endOf(dateType))
        } else if (type === 'sub') {
            setStartDate(moment(startDate).subtract(1, dateType).startOf(dateType))
            setEndDate(moment(startDate).subtract(1, dateType).endOf(dateType))
        }
    }
    // 设置日期框选择周期
    function dateTypeChange(type) {
        setDateType(type)
        switch (type) {
            case 'day':
                setStartDate(moment())
                setEndDate(moment())
                break
            case 'week':
                setStartDate(moment().startOf('week'))
                setEndDate(moment().endOf('week'))
                break
            case 'month':
                setStartDate(moment().startOf('month'))
                setEndDate(moment().endOf('month'))
                break
            default:
                break;
        }
    }

    // 提交目标
    function submitTarget() {
        let value = {}
        value.data = targetForm.getFieldsValue()
        value.target_year = targetYear.format('YYYY')
        value.founder = cookie.load('name')
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        value.shopId = shopIdDict[props.location.pathname.split('/')[3]]
        // value.shop = props.location.pathname.split('/')[3] == 'zhuoya' ? 'jr' : props.location.pathname.split('/')[3]
        value.target_type = targetType
        for (let key in value) {
            if (targetMonths.indexOf(parseInt(key)) != -1) {
                let patrn = /^[0-9]*[1-9][0-9]*$/
                if (!patrn.exec(value[key])) {
                    message.warn('请填写正整数')
                    return false
                }
                if (['', undefined].indexOf(value[key]) != -1) {
                    value[key] = 0
                }
            }
        }
        NbAxios(value, 'POST', servicePath.updateSaleTargetData).then(res => {
            if (res.success) {
                message.success('目标设置成功!')
                setSetting(false)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.warn("设置目标出现错误！")
        })
    }

    // 获得表头数据
    function getTableHeaderData() {
        let data = {}
        data.start_date = startDate.format('YYYY-MM-DD')
        data.end_date = endDate.format('YYYY-MM-DD')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.shop_name = trademark
        data.last_start_date = moment(startDate).subtract(1, dateType).format('YYYY-MM-DD')
        data.last_end_date = moment(endDate).subtract(1, dateType).format('YYYY-MM-DD')
        return NbAxios(data, 'POST', servicePath.getGoodsTotalData).then(res => {
            if (res.success) {
                let data = res.data
                new Promise((re, rj) => {
                    setSaleMoney(data.销售额)
                    setSaleMoneyCompare(data.销售额同比)
                    re(true)
                }).then(() => {
                    setRefundMoney(data.退款金额)
                    setRefundMoneyCompare(data.退款金额同比)
                    setRefundRate(data.退款率)
                }).then(() => {
                    setChangeRate(data.转化率)
                    setChangeRateCompare(data.转化率同比)
                }).then(() => {
                    setVisitor(data.访客数)
                    setVisitorCompare(data.访客数同比)
                }).then(() => {
                    setPayPiece(data.支付件数)
                    setPayPieceCompare(data.支付件数同比)
                    setPayPeople(data.支付人数)
                    setPayPeopleCompare(data.支付人数同比)
                }).then(() => {
                    setActualPay(data.实销金额)
                    setActualPayCompare(data.实销金额同比)
                    setAddPiece(data.加购件数)
                    setAddPieceRate(data.加购件数加购率)
                    setAddPieceCompare(data.加购件数同比)
                }).then(() => {
                    setGoodsAddPeople(data.商品加购人数)
                    setGoodsAddPeopleCompare(data.商品加购人数同比)
                    setGoodsAddPeopleRate(data.商品加购人数加购率)
                    setPerAdd(data.人均加购件数)
                    setPerAddCompare(data.人均加购件数同比)
                })
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取看板头部周，月，年统计值
    function getTimeRangesaleStatistical_month(salesTrandMonth) {
        setLoading(true)
        let data = {}
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList') 
        // 年月
        data.date = salesTrandMonth.format('YYYY-MM')
        // 今年年份
        data.year = salesTrandMonth.format('YYYY')
        // 今年月份
        data.month = salesTrandMonth.format('M')
        // 计算年的日期，去年
        data.year_start = salesTrandMonth.format('YYYY-01-01')
        data.last_year_start = salesTrandMonth.subtract(1, 'year').format('YYYY-01-01')
        // data.shop_name = props.location.pathname.split('/')[3] == 'zhuoya' ? 'jr' : props.location.pathname.split('/')[3]
        data.shopId = shopIdDict[props.location.pathname.split('/')[3]]
        return NbAxios(data, 'POST', servicePath.getSalesStatisticsData).then(res => {
            setLoading(false)
            if (res.success) {
                let data = res.data
                setWeekPay(data['本周销售']['本期数据'])
                setLastWeekPay(data['本周销售']['上期数据'])
                setWeekCompare(data['本周销售']['比'])
                setMonthPay(data['本月销售']['本期数据'])
                setLastMonthPay(data['本月销售']['上期数据'])
                setMonthCompare(data['本月销售']['比'])
                setMonthTarget(data['本月销售']['目标'])
                setYearPay(data['本年销售']['本期数据'])
                setLastYearPay(data['本年销售']['上期数据'])
                setYearCompare(data['本年销售']['比'])
                setYearTarget(data['本年销售']['目标'])
                setyearGetMoney(data['本年实销']['本期数据'])
                setLastYearGetMoney(data['本年实销']['上期数据'])
                setYearGetCompare(data['本年实销']['比'])
                setYearGetTarget(data['本年实销']['目标'])
                setMonthGetMoney(data['本月实销']['本期数据'])
                setLastMonthGetMoney(data['本月实销']['上期数据'])
                setMonthGetCompare(data['本月实销']['比'])
                setMonthGetTarget(data['本月实销']['目标'])
                setMonthRefundRate(data['本月退款率']['本期数据'])
                setLastMonthRefund(data['本月退款率']['上期数据'])
                setMonthRefundRateCompare(data['本月退款率']['比'])
                setMonthRefundRateTarget(data['本月退款率']['目标'])
            }
        })
    }

    // 打开关闭设置对话框
    function controlSetting(type) {
        setSetting(setting ? false : true)
        if (!setting) {
            setTargetType(type)
            getTargetData(targetYear, type)
        }
    }

    // 获取目标
    function getTargetData(year, type) {
        let data = {}
        data.target_year = year.format('YYYY')
        // data.shop = props.location.pathname.split('/')[3] == 'zhuoya' ? 'jr' : props.location.pathname.split('/')[3]
        data.target_type = type
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shopId = shopIdDict[props.location.pathname.split('/')[3]]
        NbAxios(data, 'POST', servicePath.getSaleTargetData).then(
            res => {
                if (res.success) {
                    let data = res.data
                    targetForm.setFieldsValue(data)
                } else {
                    message.warn(res.errorMsg)
                    let a = {}
                    for (let i = 0; i < targetMonths.length; i++) {
                        a[i + 1] = 0
                    }
                    targetForm.setFieldsValue(a)
                }
            }
        )
    }

    // 查看生意参谋日报下载并导入任务是否成功
    const getTaskDaily = ()=>{
        let data = {}
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shop_name = storeDict[trademark][0]['value']
        return NbAxios(data, 'POST', servicePath.getTaskDaily).then(res => {
            if (res.success) {
                getDataIntegrity()
                // setTableData(res.data['data_dict_table']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('查看生意参谋日报下载并导入任务接口报错，请联系IT帮忙解决')
        })
    }

    // 查询日报数据是否完整
    const getDataIntegrity = ()=>{
        let data = {}
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shop_name = storeDict[trademark][0]['value']
        return NbAxios(data, 'POST', servicePath.getDataIntegrity).then(res => {
            if (res.success) {
                // setTableData(res.data['data_dict_table']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('查看生意参谋日报下载并导入任务接口报错，请联系IT帮忙解决')
        })
    }

    return (
        <>
            <div style={{ marginLeft: size.width * 0.02, marginRight: size.width * 0.02, marginTop: size.height * 0.02 }}>
                <div style={{ width: "100%", borderBottom: "1px solid #ccc", display: "flex", justifyContent: 'space-between' }}>
                    <div>
                        {
                            overallData ? <Link onClick={() => {
                                setOverallData(false)
                            }}>隐藏店铺整体数据</Link> : <Link onClick={() => {
                                setOverallData(true)
                            }}>查看店铺整体数据</Link>
                        }
                    </div>
                </div>
                <div style={{ display: overallData ? '' : 'none' }}>
                    <div style={{ marginTop: 20, display:'flex', justifyContent:'space-between' }}>
                        <Title level={4}>销售统计</Title>
                        <DatePicker format="YYYY-MM" picker="month" style={{height:30}}  disabledDate={disabledDate} locale={locale} defaultValue={salesTrandMonth == '' ? '' : moment(salesTrandMonth, monthDateFormat)} onChange={(date, dateString)=>{
                            if(dateString == ''){
                                message.warn('请选择合理的时间')
                                return false
                            }else{
                                getBusinessStaffDailyAdditionalPurchase(dateString)
                                setSalesTrandMonth(dateString)
                                getTimeRangesaleStatistical_month(moment(dateString, monthDateFormat))
                                // 当选择的月份是当前月份的时候
                                if(dateString == currentMonth){
                                    getTaskDaily(storeDict[trademark])
                                }
                            }
                        }}/>
                    </div>
                    <Spin spinning={loading} tip="数据加载中........">
                        <div className={style.targetRow}>
                            <MemoTargetCard title={useMyMemo('本周销售')} compared={useMyMemo(weekCompare)} type={useMyMemo("周")} finsh={useMyMemo(weekPay)} target={1} timePercent={100} lastNumber={lastWeekPay} lastTitle={useMyMemo('上周销售')}/>
                            <MemoTargetCard showSetting compared={useMyMemo(monthCompare)} settingClick={useMyMemo(() => controlSetting('支付'), '支付')} title={useMyMemo('本月销售')} lastTitle={useMyMemo('去年同月销售')} type={useMyMemo("月")} timePercent={useMyMemo(nowInMonth / monthDays)} finsh={useMyMemo(monthPay)} target={useMyMemo(monthTarget)} lastNumber={lastMonthPay} />
                            <MemoTargetCard showSetting compared={useMyMemo(yearCompare)} settingClick={useMyMemo(() => controlSetting('支付'), '支付')} title={useMyMemo('本年销售')} lastTitle={useMyMemo('去年销售')} type={useMyMemo("年")} timePercent={useMyMemo(nowInyear / yearDays)} finsh={useMyMemo(yearPay)} target={useMyMemo(yearTarget)}  lastNumber={lastYearPay} />
                            <MemoTargetCard showSetting compared={useMyMemo(monthGetCompare)} settingClick={useMyMemo(() => controlSetting('实销'), '实销')} title={useMyMemo('本月实销')} lastTitle={useMyMemo('去年同月实销')} type={useMyMemo("月")} timePercent={useMyMemo(nowInMonth / monthDays)} finsh={useMyMemo(monthGetMoney)} target={useMyMemo(monthGetTarget)}  lastNumber={lastmonthGetMoney} />
                            <MemoTargetCard showSetting compared={useMyMemo(yearGetCompare)} settingClick={useMyMemo(() => controlSetting('实销'), '实销')} title={useMyMemo('本年实销')} lastTitle={useMyMemo('去年实销')} type={useMyMemo("年")} timePercent={useMyMemo(nowInyear / yearDays)} finsh={useMyMemo(yearGetMoney)} target={useMyMemo(yearGetTarget)}  lastNumber={lastYearGetMoney} />
                            <MemoTargetCard compared={useMyMemo(monthRefundRateCompare)} settingClick={useMyMemo(() => controlSetting('退款率'), '退款率')} title={useMyMemo('本月退款率')} lastTitle={useMyMemo('上月退款')} type={useMyMemo("月")} timePercent={useMyMemo(nowInMonth / monthDays)} finsh={useMyMemo(monthRefundRate)} target={useMyMemo(monthRefundRateTarget)} lastNumber={lastMonthRefund}  />
                        </div> 
                        <div style={{ marginTop: 20, display:'flex', justifyContent:'space-between' }}>
                            <Title level={4}>销售趋势</Title>
                        </div>
                        <div style={{display:'flex', justifyContent:'space-around', marginTop:20}}>
                            <div><Text strong>日加购趋势/销售趋势图</Text></div>
                            <div><Text strong>日累计加购趋势/销售趋势图</Text></div>
                        </div>
                        <div style={{display:'flex', justifyContent:'space-around', marginTop:5}}>
                            <div  style={{ width: '45vw', height: 300 }} id="dailyPurchase"></div>
                            <div  style={{ width: '45vw', height: 300 }} id="grandTotal"></div>
                        </div>
                    </Spin>
                    <div className={style.controlBar}>
                        <div className={style.controlBarLeft}>
                            <Title level={3} style={{ margin: 0 }} >日报表</Title>
                            <Select style={{ width: 190, marginLeft: '15px', marginRight: '10px', fontWeight:"bolder", fontSize:18 }} value={shop}
                                onChange={changeShop}>
                                {
                                    shopList.map(function (item) {
                                        return (
                                            <Option value={item.value}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                            {/* <Text style={{ lineHeight: '33px' }}>
                            {`统计日期 ${startDate.format('YYYY-MM-DD')} ~ ${endDate.format('YYYY-MM-DD')}`}
                        </Text> */}
                        </div>
                        <div className={style.controlBarRight}>
                            <Popover placement="bottom" title={"配置经营数据看板"} content={settingContent} trigger="click">
                                <Button style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}><SettingOutlined /></Button>
                            </Popover>
                            <RangePicker
                                disabledDate={disabledDate}
                                ranges={{
                                    '今天': [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }}
                                locale={locale}
                                style={{ height: 28, width: 240 }}
                                value={[startDate, endDate]}
                                format="YYYY-MM-DD"
                                onChange={dateChange}
                            />
                            <Tooltip placement="bottomLeft" title="选择今日" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateTypeChange('day')}
                                >
                                    <Text>日</Text>
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="选择本周" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateTypeChange('week')}
                                >
                                    <Text>周</Text>
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="选择本月" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateTypeChange('month')}
                                >
                                    <Text>月</Text>
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="周期减1" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateCycleChange('sub')}
                                >
                                    <LeftOutlined style={{ lineHeight: "10px", fontSize: 12 }} />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="周期加1" >
                                <Button
                                    onClick={() => dateCycleChange('add')}
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}>
                                    <RightOutlined style={{ lineHeight: "10px", fontSize: 12 }} />
                                </Button>
                            </Tooltip>
                            <Button style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }} type="primary" onClick={() => {
                                if (startDate.format('YYYY-MM-DD') == endDate.format('YYYY-MM-DD')) {
                                    message.warn('请选择不同的日期')
                                } else {
                                    const getData = async () => {
                                        await Promise.all([
                                            getTableHeaderData(),
                                            getDailyData()
                                        ])
                                    }
                                    setColumnList(getBetweenDateStr(startDate, endDate))
                                    getData()
                                }

                            }}>查询</Button>
                            <Button style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }} onClick={lookCharts}>查看图表</Button>
                        </div>
                    </div>
                    <div className={style.indicatorCardRow}>
                        <MemoIndicatorCard title={useMyMemo('销售额')} value={useMyMemo(saleMoney)} compare={useMyMemo(saleMoneyCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} titleKey={'销售额'} />
                        <MemoIndicatorCard title={useMyMemo(`退款金额(${refundRate})`)} titleKey={'退款金额'} value={useMyMemo(refundMoney)} compare={useMyMemo(refundMoneyCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('实销金额')} titleKey={'实销金额'} value={useMyMemo(actualPay)} compare={useMyMemo(actualPayCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('访客数')} titleKey={'访客数'} value={useMyMemo(visitor)} compare={useMyMemo(visitorCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo(`加购件数(${addPieceRate})`)} titleKey={'加购件数'} value={useMyMemo(addPiece)} compare={useMyMemo(addPieceCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo(`加购人数(${goodsAddPeopleRate})`)} titleKey={'加购人数'} value={useMyMemo(`${goodsAddPeople}`)} compare={useMyMemo(goodsAddPeopleCompare)} iconStatus={useMyMemo('down')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('人均加购件数')} titleKey={'人均加购件数'} value={useMyMemo(perAdd)} compare={useMyMemo(perAddCompare)} iconStatus={useMyMemo('down')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('支付件数')} titleKey={'支付件数'} value={useMyMemo(payPiece)} compare={useMyMemo(payPieceCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('支付人数')} titleKey={'支付人数'} value={useMyMemo(payPeople)} compare={useMyMemo(payPeopleCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('转化率')} titleKey={'转化率'} value={useMyMemo(changeRate)} compare={useMyMemo(changeRateCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                    </div>
                </div>
                <div style={{ display: overallData ? 'none' : '' }}>
                    <div className={style.controlBar}>
                        <div className={style.controlBarLeft}>
                            <Title level={3} style={{ margin: 0 }} >日报表</Title>
                            <Select style={{ width: 190, marginLeft: '15px', marginRight: '10px', fontWeight:"bolder", fontSize:18 }} value={shop}
                                onChange={changeShop}>
                                {
                                    shopList.map(function (item) {
                                        return (
                                            <Option value={item.value}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                            {/* <Text style={{ lineHeight: '33px' }}>
                            {`统计日期 ${startDate.format('YYYY-MM-DD')} ~ ${endDate.format('YYYY-MM-DD')}`}
                        </Text> */}
                        </div>
                        <div className={style.controlBarRight}>
                            <Popover placement="bottom" title={"配置经营数据看板"} content={settingContent} trigger="click">
                                <Button style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}><SettingOutlined /></Button>
                            </Popover>
                            <RangePicker
                                disabledDate={disabledDate}
                                ranges={{
                                    '今天': [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }}
                                locale={locale}
                                style={{ height: 28, width: 240 }}
                                value={[startDate, endDate]}
                                format="YYYY-MM-DD"
                                onChange={dateChange}
                            />
                            <Tooltip placement="bottomLeft" title="选择今日" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateTypeChange('day')}
                                >
                                    <Text>日</Text>
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="选择本周" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateTypeChange('week')}
                                >
                                    <Text>周</Text>
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="选择本月" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateTypeChange('month')}
                                >
                                    <Text>月</Text>
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="周期减1" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateCycleChange('sub')}
                                >
                                    <LeftOutlined style={{ lineHeight: "10px", fontSize: 12 }} />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="周期加1" >
                                <Button
                                    onClick={() => dateCycleChange('add')}
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}>
                                    <RightOutlined style={{ lineHeight: "10px", fontSize: 12 }} />
                                </Button>
                            </Tooltip>
                            <Button style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }} type="primary" onClick={() => {
                                if (startDate.format('YYYY-MM-DD') == endDate.format('YYYY-MM-DD')) {
                                    message.warn('请选择不同的日期')
                                } else {
                                    const getData = async () => {
                                        await Promise.all([
                                            getTableHeaderData(),
                                            getDailyData()
                                        ])
                                    }
                                    setColumnList(getBetweenDateStr(startDate, endDate))
                                    getData()
                                }

                            }}>查询</Button>
                            <Button style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }} onClick={lookCharts}>查看图表</Button>
                        </div>
                    </div>
                </div>
                {/* <Row style={{marginTop: size.height * 0.02}}>
                   
                    <RangePicker
                        style={{ marginRight: '10px'}}
                        value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                        defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                        onChange={onChange} />
                    <Button type="primary" style={{ marginRight: '10px' }} onClick={() => thirty()}>近三十天</Button>
                    <Button type="primary" style={{ marginRight: '10px' }} onClick={() => sixty()}>近六十天</Button>
                    <Button type="primary" style={{ marginRight: '10px' }} onClick={() => ninety()}>近九十天</Button>
                    <Button style={{ marginRight: '10px' }} onClick={lookCharts}>查看图表</Button>
                    <Tag color="error" style={{fontSize:'15px', fontWeight:'bold', height:'35px', lineHeight:'35px'}}>红色数字为汇总值</Tag>
                </Row> */}
                <Table
                    style={{ marginTop: size.height * 0.02 }}
                    // 数据源
                    dataSource={tableData}
                    // 不进行分页处理
                    pagination={false}
                    scroll={{ y: `${size.width > 1500 ? '78vh' : '70vh'}` }}
                >

                    <Column key='item' dataIndex="item" align="center"
                        title='数据项' width={150} fixed={'left'}
                        onCell={function (record, rowIndex) {
                            return (
                                {
                                    style: {
                                        padding: '0',
                                        height: '35px',
                                        // display: record[columnList[1]]==null ? 'none':''
                                    },
                                }
                            )
                        }} />
                    {
                        columnList.map(item => {
                            return (<Column align="center" key={item} width={200}
                                title={item} style={{ height: '10px', }}
                                dataIndex={item}
                                render={(text, record, index) => (
                                    <div style={{ fontSize: 13, textAlign: 'right' }}>{text}</div>
                                )}
                                onCell={function (record, rowIndex) {
                                    return (
                                        {
                                            style: {
                                                padding: '0',
                                                height: '35px',
                                                textAlign: 'right',
                                                fontSize: 13
                                                // color: rowIndex > 9 ? 'red' : 'black',
                                                // display: record[columnList[1]]==null ? 'none':''
                                            },
                                        }
                                    )
                                }}
                            />)
                        })
                    }
                </Table>
                {/* <div style={{ marginTop: 20, display:'flex', justifyContent:'space-between' }}>
                    <Title level={4}>销售趋势</Title>
                    <DatePicker format="YYYY-MM" picker="month" style={{height:30}}  disabledDate={disabledDate} locale={locale} defaultValue={salesTrandMonth == '' ? '' : moment(salesTrandMonth, monthDateFormat)} onChange={(date, dateString)=>{
                        if(dateString == ''){
                            message.warn('请选择合理的时间')
                            return false
                        }else{
                            getBusinessStaffDailyAdditionalPurchase(dateString)
                            setSalesTrandMonth(dateString)
                        }
                    }}/>
                </div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:20}}>
                    <div><Text strong>日加购趋势/销售趋势趋势图</Text></div>
                    <div><Text strong>日累计加购趋势/销售趋势趋势图</Text></div>
                </div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:5, paddingBottom:200}}>
                    <div  style={{ width: '45vw', height: 300 }} id="dailyPurchase"></div>
                    <div  style={{ width: '45vw', height: 300 }} id="grandTotal"></div>
                </div> */}
                <Modal
                    title={`设置${targetType}目标`}
                    visible={setting}
                    // onClose={controlSetting}
                    onCancel={controlSetting}
                    footer={targetFooter}
                    height={'50vh'}
                    placement="top"
                    width={800}
                >

                    <Form
                        {...layout}
                        form={targetForm}
                        style={{ height: "fit-content", overflowY: "auto" }}
                    >
                        <Row>
                            <Col style={{ height: 45 }} span={24} >
                                <Form.Item
                                    labelCol={{ span: 2 }}
                                    wrapperCol={{ span: 10 }}
                                    label="年份"
                                    name={'year'}
                                >
                                    <DatePicker defaultValue={targetYear} value={targetYear} onChange={yearChange} picker="year" />
                                </Form.Item>
                            </Col>
                            {formList.map((item, index) => {
                                return (
                                    <Col style={{ height: 60 }} span={12}>
                                        <Form.Item
                                            label={item.label}
                                            name={item.name}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Form>
                </Modal>
            </div>
        </>
    )

}

export default Daily