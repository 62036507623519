import React, { useState, useEffect, useCallback } from 'react';
import { DatePicker, Row, Col, Button, Table, Drawer, Tooltip, Radio, Input, Select, Form, message, Modal, Popconfirm, Upload, Image } from 'antd'
import { SearchOutlined, UploadOutlined, DeleteOutlined, CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor'
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl'
import axios from 'axios'
import moment from 'moment'
import Base64 from 'base-64'
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'braft-editor/dist/index.css'
import '../../../static/css/index.css'
const { Option } = Select
const { TextArea } = Input;
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'


// 宠粮商品基础信息
function Commodity() {

    // 上传图片按钮
    const uploadButton = (
        <Button>
            <UploadOutlined /> 上传
        </Button>
    );

    const [form] = Form.useForm()

    const [form1] = Form.useForm()

    const layout1 = {
        labelCol: { span: 10 },
        wrapperCol: { span: 18 },
    };

    // 数据
    const [date, setDate] = useState([])

    // 总数
    const [total, setTotal] = useState(0)

    //页面
    const [page, setPage] = useState(1)

    // id
    const [id, setId] = useState('')

    // 商品编码
    const [spbm, setSpbm] = useState('')

    // 商品名称
    const [spmz, setSpmz] = useState('')

    // 规格
    const [gg, setGg] = useState('')

    // 品牌
    const [pp, setPp] = useState('')


    // 查询商品编码
    const [searchSpbm, setSearchSpbm] = useState('')

    // 查询商品名称
    const [searchSpmz, setSearchSpmz] = useState('')

    // 查询规格
    const [searchGg, setSearchGg] = useState('')

    // 查询品牌
    const [searchPp, setSearchPp] = useState('')

    // 更新时间
    const [time, setTime] = useState('')

    // 商品基础文件
    const [fileList, setFileList] = useState([])

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 打开详情
    const [detail, setDetail] = useState(false)

    // 查询
    const [search, setSearch] = useState(false)

    // 打开上传
    const [openUpload, setOpenUpload] = useState(false)

    // //权限列表
    // const [jurisdiction, setJurisdiction] = useState([])

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // //获取权限代码
    // useEffect(() => {
    //     let dataProps = {
    //         "role_id": cookie.load('role'),
    //         "shop_id": cookie.load('shopList')
    //     }
    //     axios({
    //         method: 'POST',
    //         url: servicePath.getRoleShopPorts,
    //         data: dataProps,
    //         headers: {
    //             'Content-Type': 'application/json'  //如果写成contentType会报错
    //         }
    //     }).then(
    //         res => {
    //             if (res.data['success']) {
    //                 console.log(res.data.data);
    //                 setJurisdiction(res.data.data)
    //             }
    //         }
    //     )
    // }, [])


    // 表格标题
    const columns = [
        {
            title: '商品编码',
            key: 'spbm',
            dataIndex: 'spbm',
            width: '100px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '商品名称',
            key: 'spmz',
            dataIndex: 'spmz',
            width: '100px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '规格',
            key: 'gg',
            dataIndex: 'gg',
            width: '100px',
            align: 'center'
        },
        {
            title: '品牌',
            key: 'pp',
            dataIndex: 'pp',
            width: '100px',
            align: 'center'
        },
        {
            title: '更新时间',
            key: 'gxsj',
            dataIndex: 'gxsj',
            width: '100px',
            align: 'center'
        },
        {
            title: '操作',
            key: 'operate',
            dataIndex: 'operate',
            width: '50px',
            align: 'center',
            // onHeaderCell:function(record, rowIndex){
            //     return (
            //         {
            //             style: {
            //                 display: jurisdiction.indexOf("petFood::updateSupplier") != -1 || jurisdiction.indexOf("petFood::deleteSupplier") != -1 ? "" : "none"
            //             },
            //         }
            //     )
            // },
            // onCell:function(record, rowIndex){
            //     return (
            //         {
            //             style: {
            //                 display: jurisdiction.indexOf("petFood::updateSupplier") != -1 || jurisdiction.indexOf("petFood::deleteSupplier") != -1 ? "" : "none"
            //             },
            //         }
            //     )
            // },
            render: (text, record, index) => {
                return <Row>
                    <Col span={12}>
                        <Tooltip placement="top" title="查看详情" >
                            <SearchOutlined onClick={() => {
                                form1.resetFields()
                                setId(date[index]['uuid']);
                                setSpbm(date[index]['spbm']);
                                setSpmz(date[index]['spmz'])
                                setGg(date[index]['gg'])
                                setPp(date[index]['pp'])
                                setTime(date[index]['gxsj'])
                                setDetail(true)
                            }} />
                        </Tooltip>
                    </Col>
                    <Col span={12} >
                        <Popconfirm
                            title="确认删除?删除后不可恢复!"
                            onConfirm={orderDelete}
                            onCancel={cancelDelete}
                            okText="确定"
                            cancelText="取消"
                        >
                            <DeleteOutlined onClick={()=>setId(date[index]['uuid'])} />
                        </Popconfirm>
                    </Col>
                </Row>
            }
        }
    ]

    // 商品基础信息页面修改的时候触发
    useEffect(() => {
        getData()
    }, [page])


    // 获取数据
    const getData = () => {
        let dataProps = {
            "spbm": searchSpbm,
            "spmz": searchSpmz,
            "gg": searchGg,
            "pp": searchPp,
            "page": page,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryPetFoodCommodity,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total);
                    setDate(res.data.data);
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 确定删除
    const orderDelete = () => {
        let dataProps = {
            "uuid": id,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.deletePetFoodCommodity,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                   message.success("成功删除该商品基础信息")
                   getData()
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 取消删除
    const cancelDelete = () => {
        setId('')
    }

    // 关闭查询
    const closeSearch = () => {
        setSearch(false)
    }


    // 确定修改
    const okDetail = () => {
        setEffective(1)
        let dataProps = {
            "uuid": id,
            "spbm": spbm,
            "spmz": spmz,
            "gg": gg,
            "pp": pp,
            "sqsj": time,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        console.log(dataProps);
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.updatePetFoodCommodity,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    message.success("成功修改该商品基础信息")
                    getData()
                    setId('')
                    setSpbm('');
                    setSpmz('')
                    setGg('')
                    setPp('')
                    setDetail(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 取消修改
    const cancelDetail = () => {
        message.success("取消修改该商品基础信息")
        setId('')
        setSpbm('');
        setSpmz('')
        setGg('')
        setPp('')
        setDetail(false)
    }

    // 查询
    const searchDate = ()=>{
        getData()
        setSearch(false)
    }

     // 重置
     const reset = ()=>{
        form.resetFields()
        setId('')
        setSpbm('');
        setSpmz('')
        setGg('')
        setPp('')
        setPage(1)
        let dataProps = {
            "spbm": '',
            "spmz": '',
            "gg": '',
            "pp": '',
            "page": 1,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryPetFoodCommodity,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total);
                    setDate(res.data.data);
                    setSearch(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
       
    }

     // 报价文件改变的时候触发
     const filesHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                // let flag = fileList[fileList.length-1].originFileObj['size'] / 1024 / 1024 < 50
                // // 在判断文件的大小
                // if (flag){
                //     setDocList(fileList)
                // }else{
                //     message.error('文件大小必须小于50M')
                // }
                setFileList(fileList)
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setFileList(fileList)
        }
    }

    // 确认上传商品基础信息文件
    const affirmUpload = ()=>{
        setEffective(1)
        const formData = new FormData()
        fileList.forEach((item) => {
            formData.append('file', item['originFileObj']);
        })
        formData.append('roleId', cookie.load('role'));
        formData.append('shopId', cookie.load('shopList'));
        axios({
            method: 'POST',
            url: servicePath.uploadPetFoodCommodity,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    message.success("成功上传商品基础信息文件")
                    setFileList([])
                    getData()
                    setOpenUpload(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 取消上传商品基础信息文件
    const closeUpload = () => {
        message.success("取消上传商品基础文件")
        setFileList([])
        setOpenUpload(false)
    }
    


    return (
        <div>
            {/* 按钮区域 */}
            <div style={{ marginTop: size.height * 0.01 }}>
                <Row>
                    <Col style={{ marginLeft: '80px' }}>
                        <Button type="primary" onClick={()=>{setOpenUpload(true)}} icon={<UploadOutlined />}>上传商品基础信息</Button>
                    </Col>
                    <Col style={{ marginLeft: '40px' }}>
                        <a href="https://pic.bmcsoft.cn/imge_dir/宠粮经销商品基础信息模板.xlsx"><Button type="primary" icon={<DownloadOutlined />}  >下载商品基础信息模版</Button></a>
                    </Col>
                    <Col style={{ marginLeft: '40px' }}>
                        <Button type="primary" icon={<SearchOutlined />} onClick={()=>setSearch(true)} >查询数据</Button>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: size.height * 0.025 }}>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={paginationProps}
                    scroll={{ x: 1000 }}
                    //表格行 key 的取值
                    rowKey={'id'}
                    scroll={{ x:'70vw', y: `${size.width>1500?'70vh':'63vh'}` }}
                />
            </div>


            {/* 查询详情 */}
            <Modal
                title="查看/修改详情"
                centered={true}
                visible={detail}
                onOk={okDetail}
                onCancel={cancelDetail}
                okText="确认修改"
                cancelText="取消"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                width={800}
            >
                <Form form={form1} {...layout1}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="商品编码"
                                name="spbm"
                            >
                                <Input
                                    defaultValue={spbm}
                                    value={spbm}
                                    placeholder="请输入商品编码"
                                    onChange={(e) => {
                                        setSpbm(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="商品名称"
                                name="spmz"
                            >
                                <Input
                                    value={spmz}
                                    defaultValue={spmz}
                                    placeholder="请输入商品名称"
                                    onChange={(e) => {
                                        setSpmz(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="规格"
                                name="gg"
                            >
                                <Input
                                    value={gg}
                                    defaultValue={gg}
                                    placeholder="请输入规格"
                                    onChange={(e) => {
                                        setGg(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="品牌"
                                name="pp"
                            >
                                <Input
                                    value={pp}
                                    defaultValue={pp}
                                    placeholder="请输入品牌"
                                    onChange={(e) => {
                                        setPp(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="数据更新时间"
                                name="time"
                            >
                                {/* <DatePicker defaultValue={moment(time, dateFormat)} locale={locale} disabled /> */}
                                <span>{time}</span>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {/*  条件查询 */}
            <Drawer
                title="条件查询"
                closable={false}
                onClose={closeSearch}
                visible={search}
                width={600}
            >
                <Form form={form} {...layout1} labelAlign='left'>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="商品编码"
                                name="searchSpbm"
                            >
                                <Input
                                    value={searchSpbm}
                                    placeholder="请输入商品编码"
                                    onChange={(e) => {
                                        setSearchSpbm(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="商品名称"
                                name="searchSpmz"
                            >
                                <Input
                                    value={searchSpmz}
                                    placeholder="请输入商品名称"
                                    onChange={(e) => {
                                        setSearchSpmz(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="规格"
                                name="searchGg"
                            >
                                <Input
                                    value={searchGg}
                                    placeholder="请输入规格"
                                    onChange={(e) => {
                                        setSearchGg(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="品牌"
                                name="searchPp"
                            >
                                <Input
                                    value={searchPp}
                                    placeholder="请输入品牌"
                                    onChange={(e) => {
                                        setSearchPp(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type="primary" style={{ marginLeft: '30px' }} onClick={() => searchDate()}>查询</Button>
                    <Button type="primary" style={{ marginLeft: '20px' }} danger onClick={() => reset()}>重置</Button>
                </Form>
            </Drawer>

             {/* 上传商品基础信息文件 */}
             <Modal
                title="上传商品基础信息文件"
                centered={true}
                visible={openUpload}
                maskClosable={false}
                onOk={affirmUpload}
                onCancel={closeUpload}
                okText="确认上传"
                cancelText="取消上传"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Upload
                    action={servicePath.image}                          //上传地址，填错/不填上传图片的时候都会报错
                    listType="picture"                             // 上传列表的内建样式
                    fileList={fileList}                                 //已经上传的文件列表（受控）
                    onChange={filesHandleChange}                             //上传文件改变时的状态
                    accept=".doc,.docx,.xlsx,.xls,.pdf,image/*"                                    //设置文件上传类型
                >
                    {fileList.length >= 1 ? null : uploadButton}
                </Upload>
            </Modal>
        </div>
    )

}

export default Commodity