import React, { useState, useEffect, useCallback } from 'react';
import { Col, message, Statistic,Row } from 'antd'
import IntroduceImage from '../../static/image/introduction/bmc1.jpg'
import servicePath from '../../config/apiUrl'
import cookie from 'react-cookies'
import axios from 'axios'
import {Chrono} from 'react-chrono'
const { Countdown } = Statistic
function Introduce(props) {

    // 数据
    const [date , setDate ] = useState([])

    // chrono数据
    const [ itemList, setItemList ] = useState([
        
      ])

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    useEffect(() => {
        axios({
            method: 'POST',
            url: servicePath.getAllNotification,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                   setDate(res.data.data);
                   let date = res.data.data
                   let itemList = []
                   date.map(item=>{
                    itemList.push(
                            {
                                title: item.time,
                                contentText : <div dangerouslySetInnerHTML={{ __html: item.content }}></div> 
                            }
                        )
                    })
                    setItemList(itemList);
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }, [])

    return (
        <div style={{display:'block'}}>
            <div style={{margin:'0 auto', width:'500px'}}>
                <Countdown style={{margin: '0 auto',}} title="距离双十二开始" value='2020-12-12 00:00:00' format="D 天 H 时 m 分 s 秒" valueStyle={{color:'red'}} />
                {/* <Countdown style={{margin: '0 auto',}} title="距离2020年双十一结束" value='2020-11-12 00:00:00' format="D 天 H 时 m 分 s 秒" />
                <Countdown style={{margin: '0 auto',}} title="距离2021年双十一开始" value='2021-11-11 00:00:00' format="D 天 H 时 m 分 s 秒" /> */}
            </div>
            <div style={{marginTop:'100px', marginLeft:'200px', margin:'0 auto', width:"50%", height: size.height*0.7 }}>
                {/* slideShow 自动播放 */}
                {
                    itemList.length != 0 ? <Chrono items={itemList} cardHeight={500} slideShow="true" mode="TREE"  /> : ''
                }
                
            </div>
        </div>
    )

}

export default Introduce