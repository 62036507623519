import { Form, Input, Upload, Radio, Modal, Button, message, Table, Typography, Timeline, Row, Col, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import { AudioOutlined, DeliveredProcedureOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import servicePath from '../../../config/apiUrl';
import cookie from "react-cookies";
import NbAxios from '../../../config/utils/nbAxios';
const { Text, Paragraph } = Typography
function Main() {
    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
    // 文件上传
    const props = {
        name: 'file',
        action: servicePath.uploadKolExcel,
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === "done") {
                if (info.file.response.success) {
                    message.success("文件上传成功")
                }
                else {
                    message.error(info.file.response.errorMsg)
                }
                info.fileList.splice(0, info.fileList.length);
                dataAll()
            }
        }, progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    };
   
    // 数据
    const [koldata, setkoldata] = useState([])
    const [koldetailsdata, setkoldetailsdata] = useState([])
    const [ search , setSearch ] = useState('')
    const [kol_selects, setkol_selects] = useState([])
    const [kol_selectsList, setkol_selectsList] = useState([])
    // 时间戳
    const [mode, setMode] = useState('left');
    // 分页页数选择器和页数
    const [total, settotal] = useState()
    const [page, setpage] = useState(1)
    // 编辑按钮弹窗
    const [form] = Form.useForm()
    const [isdetailsVisible, setIsdetailsVisible] = useState(false);
    const handledetailsOk = () => {
        setIsdetailsVisible(false);
    };
    const handledetailsCancel = () => {
        setIsdetailsVisible(false);
    };
  
    const querykolDetails = (brand, medium_connent, record) => {
        let dataProps = { "brand": brand, "medium_connent": medium_connent }
        dataProps.roleId = cookie.load("role");
        dataProps.name = cookie.load("name");
        dataProps.shopId = cookie.load('shopList')
        NbAxios(dataProps, "POST", servicePath.queryKolDetails).then(
            res => {
                if (res.success) {
                    setkoldetailsdata(res.data);
                     // 重置
                    form.resetFields();
                    form.setFieldsValue(record);
                    setIsdetailsVisible(true);
                } else {
                    message.error(res.errorMsg)

                }
            }
        )
    }
  
    const setpagination = {
        simple :true,
        pageSize: 10,
        total: total,
        onChange: (page) => setpage(page),
    }
    // []里的参数发生改变时调用{}里的函数
    useEffect(() => {
        if(page == 1){
            dataAll() 
        }else{
            setpage(1) 
        }
    }, [kol_selects])
    useEffect(() => { 
        dataAll() 
    }, [page])
   
    const onChange = e => { setMode(e.target.value); }
    // 表头
    const columns = [
        {
            title: '品牌名',
            dataIndex: 'brand',
            key: 'brand',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                        searchWords={kol_selectsList}
                        autoEscape
                        textToHighlight={text ? text.toString() : ""}
                    />
                );
            },
        },
        {
            title: 'pr名字',
            dataIndex: 'medium_name',
            key: 'medium_name',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                        searchWords={kol_selectsList}
                        autoEscape
                        textToHighlight={text ? text.toString() : ""}
                    />
                );
            },
        },
        {
            title: 'pr联系方式',
            dataIndex: 'medium_connent',
            key: 'medium_connent',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                        searchWords={kol_selectsList}
                        autoEscape
                        textToHighlight={text ? text.toString() : ""}
                    />
                );
            },
        },
        {
            title: '合作详情',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            render: (a, record) => {
                return <a onClick={() =>  querykolDetails(record.brand, record.medium_connent, record)}>详情</a>
            }
        },
    ]

    // 搜索
    const handlekolSelects = (date) => { 
        setkol_selectsList(date.split(/[\s\n]/)); 
        setkol_selects(date) 
    }

    // 展示界面
    const dataAll = () =>{
        let dataProps = { "kol_selects": kol_selects, "page": page }
        dataProps.shopList = cookie.load("shopList");
        dataProps.roleId = cookie.load("role");
        dataProps.name = cookie.load("name");
        dataProps.shopId = cookie.load('shopList')
        NbAxios(dataProps, "POST", servicePath.kolAll).then(
            res => {
                setkoldata(res.data);
                settotal(res.total);
            }
        )
    }
    return (
        <div>
            <div>
                <div style={{ display: "flex", justifyContent: "left", marginTop: 20, marginLeft: "20%" }}>
                    <Space>
                        <Input style={{width:'350px'}} placeholder="请输入搜索词，以空格间隔可同时查询多个搜索词" value={search} onChange={(e)=>{
                            setSearch(e.target.value)
                        }} />
                        <Button type="primary" onClick={()=>{
                            handlekolSelects(search)
                        }}>搜索</Button>
                        <Upload {...props}>
                            <Button icon={<DeliveredProcedureOutlined />}>上传文件</Button>
                        </Upload>
                        <a href="https://pic.bmcsoft.cn/imge_dir/KOL媒介管理上传模版.xlsx" style={{marginLeft:10}} ><Button type="dashed">下载KOL媒介管理上传模版</Button></a>
                    </Space>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }} >
                    <Table columns={columns} dataSource={koldata} pagination={setpagination} bordered style={{ width: "60%" }} />
                </div>

                <Modal title="合作详情"
                    visible={isdetailsVisible}
                    onOk={handledetailsOk}
                    onCancel={handledetailsCancel}
                    footer>
                    <Form form={form}>

                        <Row>
                            <Col span={10} offset={2}>

                                <Form.Item
                                    label="品牌名"
                                    name="brand"
                                    key="brand">

                                    <Paragraph copyable={{ tooltips: ["复制", "复制成功"], text: form.getFieldValue('brand') }} style={{ marginTop: 5 }}>
                                        <Text type="secondary">{form.getFieldValue('brand')}</Text>
                                    </Paragraph>
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="pr联系方式"
                                    name="medium_connent"
                                    key="medium_connent">

                                    <Paragraph copyable={{ tooltips: ["复制", "复制成功"], text: form.getFieldValue('medium_connent') }}
                                        style={{ marginTop: 5 }}>
                                        <Text type="secondary">{form.getFieldValue('medium_connent')}</Text>
                                    </Paragraph>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Text style={{ justifyContent: "center", display: "flex", }} strong>
                        合作详情
                    </Text>
                    <hr />
                    <Radio.Group
                        onChange={onChange}
                        value={mode}
                        style={{ marginBottom: 20, }}
                    >
                    </Radio.Group>
                    <Timeline mode={mode}>
                        {
                            koldetailsdata.map(item => {
                                return <Timeline.Item label={item.cooperation_time} color="green">
                                    {item.cooperation_feedback}</Timeline.Item>
                            })

                        }

                    </Timeline>

                </Modal>
            </div></div>
    )
}


export default Main