import React, {useState, useEffect} from 'react';
import {Form, DatePicker, Table, message, Typography, Upload, Modal, Input, Button, Spin} from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import {InboxOutlined} from '@ant-design/icons';
import moment from "moment"
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios'
import servicePath from '../../../config/apiUrl'
import Base64 from 'base-64'
import style from './index.module.css';
const {Text} = Typography
const {Dragger} = Upload;
function Index(props){
    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 20 },
    }
    function disabledDate(current) {
        // Can not select days before today and today
        return current > moment().endOf('year');
    }

    const [form1] = Form.useForm()

    // 日期格式
    const dateFormat = 'YYYY'
    // 事业部
    const [ division , setDivision ] = useState('')
    //年份
    const [ year , setYear ] = useState(moment().format('YYYY'))
    // 月份list
    const [ monthList , setMonthList ] = useState(['01','02','03','04','05','06','07','08','09','10','11','12'])
    // 月份表头
    const [ monthCustomList , setMonthCustomList ] = useState([])
    // 数据
    const [ data , setData ] = useState([])
    // 页数
    const [ page , setPage ] = useState(1)
    // 总数
    const [ total, setTotal] = useState(0)
    // 店铺名称
    const [ shopName , setShopName ] = useState('')
    // 修改信息
    const [ updateData , setUpdateData] = useState(false)
    // 添加数据
    const [ addData , setAddData ] = useState(false)
    // 禁用数据
    const [ deleteData , setDeleteData] = useState(false)
    // 请求数据
    const [ isSpinning , setIsSpinning ] = useState(false)
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    // 是否上传预算目标
    const [uploadBudgetTarget, setUploadBudgetTarget] = useState(false)
    // 预算目标文件
    const [budgetTargetFile, setBudgetTargetFile] = useState([])
    // 按钮是否有效
    const [effective, setEffective] = useState(false)
    // 店铺基础表头
    const baseCustom = [
        {
            title: '事业部',
            key: 'division',
            align: 'center',
            width: 80,
            fixed: 'left',
        },
        {
            title: '项目',
            key: 'project',
            align: 'center',
            width: 80,
            fixed: 'left',
        },
        {
            title: '店铺',
            key: 'shop',
            align: 'center',
            width: 100,
            fixed: 'left',
        }
    ]

    // 表格表头
    const columns = [
        ...baseCustom.map(item=>{
            return {
                title: item.title,
                dataIndex: item.key,
                width: item.width,
                key: item.key,
                align: 'center',
                fixed: 'left',
            }
        }),
        ...monthCustomList.length != 0 ? monthCustomList.map(item=>{
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: 'center',
                render: (text, record, index) => {
                    return text != '-' ? <a onClick={()=>{
                        let obj = {
                            'division': record['division'],
                            'plate': record['plate'],
                            'project': record['project'],
                            'shop': record['shop'],
                            'month':item,
                            'sale': record[item]
                        }
                        form1.setFieldsValue(obj)
                        setUpdateData(true)
                    }}>{text}</a> : <a
                       onClick={()=>{
                            let obj = {
                                'division': record['division'],
                                'plate': record['plate'],
                                'project': record['project'],
                                'shop': record['shop'],
                                'month':item
                            }
                            form1.setFieldsValue(obj)
                            setAddData(true)
                        }}
                    >{text}</a>
                }
            }
        }) : ''
    ]

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current:page,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    useEffect(()=>{
        if (props.history.location.pathname.split('/')[3] == 'standard') {
            setDivision('标品事业部')
        } else if (props.history.location.pathname.split('/')[3] == 'unstandard') {
            setDivision('非标事业部')
        }
        // 获取接口权限
        queryJurisdiction()
    },[])
    // 年份、事业部修改的时候
    useEffect(()=>{
        let customList = []
        monthList.map(item=>{
            customList.push(year + '-' + item);
        })
        setMonthCustomList(customList)
        if(division != ''){
            if(page!=1){
                setPage(1)
            }else{
                getShopSalesTargetData()
            }
        }
    },[year,division])

    useEffect(()=>{
        if(division != ''){
            getShopSalesTargetData()
        }
    },[page])

    // 获取接口权限
    const queryJurisdiction = ()=>{
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        NbAxios(dataProps,"POST", servicePath.getRoleShopPorts).then(res=>{
            if(res.success){
                setJurisdiction(res.data);
            }else{
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取店铺销售目标数据
    const getShopSalesTargetData = ()=>{
        setIsSpinning(true)
        let dataProps = {
            "year": year,
            "division": division,
            "shop_id": cookie.load('shopList'),
            "role_id": cookie.load('role'),
            "page": page-1,
            "shop_name": shopName
        }
        NbAxios(dataProps, "POST", servicePath.queryShopSalesTargetData).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setTotal(res.total)
                setData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 重置
    const restart = ()=>{
        let dataProps = {
            "year": year,
            "division": division,
            "shop_id": cookie.load('shopList'),
            "role_id": cookie.load('role'),
            "page": 0,
            "shop_name": ''
        }
        NbAxios(dataProps, "POST", servicePath.queryShopSalesTargetData).then(res => {
            if (res.success) {
                setTotal(res.total)
                setData(res.data);
                setPage(1)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 更新数据
    const updateDataClick = (values) => {
        values.shop_id = cookie.load('shopList')
        values.role_id= cookie.load('role')
        values.principal_name = cookie.load('name')
        values.principal_uuid = cookie.load('id')
        values.principal_email = Base64.decode(cookie.load('email'))
        NbAxios(values, "POST", servicePath.updateShopSalesTargetData).then(res => {
            if (res.success) {
                message.success('业绩目标更新成功')
                form1.resetFields()
                if(page != 1){
                    setPage(1)
                }else{
                    getShopSalesTargetData()
                }
                setUpdateData(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
       
    };    
    // 添加数据
    const addDataClick = (values) => {
        values.shop_id = cookie.load('shopList')
        values.role_id= cookie.load('role')
        values.principal_name = cookie.load('name')
        values.principal_uuid = cookie.load('id')
        values.principal_email = Base64.decode(cookie.load('email'))
        NbAxios(values, "POST", servicePath.addShopSalesTargetData).then(res => {
            if (res.success) {
                message.success('业绩目标添加成功')
                form1.resetFields()
                if(page != 1){
                    setPage(1)
                }else{
                    getShopSalesTargetData()
                }
                setAddData(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
       
    };    

    // 禁用数据
    const deleteDataClick = (values) => {
        values.shop_id = cookie.load('shopList')
        values.role_id= cookie.load('role')
        values.principal_name = cookie.load('name')
        values.principal_uuid = cookie.load('id')
        values.principal_email = Base64.decode(cookie.load('email'))
        NbAxios(values, "POST", servicePath.deleteShopSalesTargetData).then(res => {
            if (res.success) {
                message.success('业绩目标禁用成功')
                form1.resetFields()
                if(page != 1){
                    setPage(1)
                }else{
                    getShopSalesTargetData()
                }
                setDeleteData(false)
                setUpdateData(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
        
    }; 
  // 预算目标文件上传
  const budgetTargetFileHandleChange = ({ fileList }) => {
    if (fileList.length != 0) {
        // 获取最新文件的文件名
        let fileName = fileList[fileList.length - 1].originFileObj['name']
        // 提取文件名的后缀名
        let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
        // 定义允许的类型
        let fileTypes = ['xlsx']
        // 首先判断文件上传的类型
        if (fileTypes.indexOf(fileExtension) != -1) {
            let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
            // 在判断文件的大小
            if (flag) {
                // 更新文件(限制只能上传一个文件)
                let newFileList = []
                newFileList.push(fileList[fileList.length - 1])
                setBudgetTargetFile(newFileList)
            } else {
                message.error('文件大小必须小于50M')
                // 移除文件
                fileList.pop()
            }
        } else {
            message.error('文件类型不符合')
            // 移除文件
            fileList.pop()
        }
    } else {
        setBudgetTargetFile(fileList)
    }
}
    //上传预算目标
    const uploadBudgetTargetFile = ()=>{
        if(budgetTargetFile.length == 0){
            message.warn('请选择您要上传的文件')
        }else{
            setEffective(true)
            // 上传原始文件例子
            const formData = new FormData()
            // 多个图片
            budgetTargetFile.forEach((item) => {
                formData.append('file ', item['originFileObj']);
            })
            formData.append('shopId', cookie.load('shopList'))
            formData.append('roleId', cookie.load('role'))
            formData.append('principal_name', cookie.load('name'))
            formData.append('principal_uuid', cookie.load('id'))
            formData.append('principal_email', Base64.decode(cookie.load('email')))
            NbAxios(formData, "POST", servicePath.uploadBudgetTarget).then(res => {
                setEffective(false)
                if(res.success){
                    message.success('数据上传成功')
                    setUploadBudgetTarget(false)
                    setBudgetTargetFile([])
                    getShopSalesTargetData()
                }else{
                    message.warn(res.errorMsg)
                }
            })
        }
    }
    

    return (
        <div style={{marginLeft: 30, marginTop: 20, marginRight:30}}>
            <div>
                <Form style={{display:'flex'}}>
                    <Form.Item label="年份" style={{marginRight:5}}>
                        <DatePicker
                            disabledDate={disabledDate}
                            locale={locale}
                            picker="year"
                            onChange={yearChange}
                            value={moment(year, dateFormat)}
                        />
                    </Form.Item>
                    <Form.Item label="店铺名称" style={{marginRight:5}}>
                        <Input value={shopName} onChange={(e)=>setShopName(e.target.value)} />
                    </Form.Item>
                    <Button type="primary" onClick={()=>{
                        if(page != 1){
                            setPage(1)
                        }else{
                            getShopSalesTargetData()
                        }
                    }}>搜索</Button>
                    <Button type="primary" onClick={()=>{
                        setShopName('')
                        restart()
                    }} danger>重置</Button>
                     <Button type="primary" style={{
                        marginLeft: 10,
                        display: jurisdiction.indexOf("sale::uploadExpected") != -1 ? "" : "none"
                    }} onClick={() => setUploadBudgetTarget(true)}>上传预算目标</Button>
                    <Button type="link" style={{display: jurisdiction.indexOf("sale::uploadExpected") != -1 ? "" : "none"}}>
                        <a href={"https://pic.bmcsoft.cn/imge_dir/预算目标上传模版.xlsx"}>下载预算目标上传模版</a>
                    </Button>
                </Form>
            </div>
            <div className={style.reportWapper}>
                <Spin spinning={isSpinning} tip="数据请求中.....">
                    <Table  
                        dataSource={data} 
                        columns={columns}
                        pagination={paginationProps}
                        scroll={{ x: '70vw', y: `100vh` }}
                        />
                </Spin>
            </div>
            {/* 修改 */}
            <Modal
                title={`${division}业绩目标`}
                onCancel={()=>{
                    form1.resetFields()
                    setUpdateData(false)
                }}
                footer={[]}
                centered={true}
                visible={updateData}
            >
                <Form form={form1} labelAlign='left'  {...layout1} onFinish={updateDataClick}>
                    <Form.Item label="事业部" name="division">
                        <Text type="secondary">{form1.getFieldValue('division')}</Text>
                    </Form.Item>
                    <Form.Item label="板块" name="plate">
                        <Text type="secondary">{form1.getFieldValue('plate')}</Text>
                    </Form.Item>
                    <Form.Item label="项目" name="project">
                        <Text type="secondary">{form1.getFieldValue('project')}</Text>
                    </Form.Item>
                    <Form.Item label="店铺" name="shop">
                        <Text type="secondary">{form1.getFieldValue('shop')}</Text>
                    </Form.Item>
                    <Form.Item label="月份" name="month">
                        <Text type="secondary">{form1.getFieldValue('month')}</Text>
                    </Form.Item>
                    <Form.Item label="业绩目标" name="sale">
                        <Input />
                    </Form.Item>
                    <div style={{display:'flex', justifyContent:'space-around'}}>
                        <Button type="primary" htmlType="submit">更新数据</Button>
                        <Button type="primary" danger onClick={()=>setDeleteData(true)}>禁用目标</Button>
                    </div>
                </Form>
            </Modal>
            {/* 禁用 */}
            <Modal
                title={`${division}业绩目标`}
                onCancel={()=>{
                    setDeleteData(false)
                }}
                footer={[]}
                centered={true}
                visible={deleteData}
            >
                 <Form form={form1} labelAlign='left'  {...layout1} onFinish={deleteDataClick}>
                    <Form.Item label="事业部" name="division">
                        <Text type="secondary">{form1.getFieldValue('division')}</Text>
                    </Form.Item>
                    <Form.Item label="板块" name="plate">
                        <Text type="secondary">{form1.getFieldValue('plate')}</Text>
                    </Form.Item>
                    <Form.Item label="项目" name="project">
                        <Text type="secondary">{form1.getFieldValue('project')}</Text>
                    </Form.Item>
                    <Form.Item label="店铺" name="shop">
                        <Text type="secondary">{form1.getFieldValue('shop')}</Text>
                    </Form.Item>
                    <Form.Item label="月份" name="month">
                        <Text type="secondary">{form1.getFieldValue('month')}</Text>
                    </Form.Item>
                    <Form.Item label="业绩目标" name="sale">
                        <Text type="secondary">{form1.getFieldValue('sale')}</Text>
                    </Form.Item>
                    <span>
                        <Text type="danger">是否要禁用该业绩目标?</Text>
                    </span>
                    <div style={{display:'flex', justifyContent:'space-around'}}>
                        <Button type="primary" htmlType="submit">禁用数据</Button>
                    </div>
                </Form>
            </Modal>
            {/* 添加业绩目标 */}
            <Modal
                title={`${division}业绩目标`}
                onCancel={()=>{
                    setAddData(false)
                }}
                footer={[]}
                centered={true}
                visible={addData}
            >
                 <Form form={form1} labelAlign='left'  {...layout1} onFinish={addDataClick}>
                    <Form.Item label="事业部" name="division">
                        <Text type="secondary">{form1.getFieldValue('division')}</Text>
                    </Form.Item>
                    <Form.Item label="板块" name="plate">
                        <Text type="secondary">{form1.getFieldValue('plate')}</Text>
                    </Form.Item>
                    <Form.Item label="项目" name="project">
                        <Text type="secondary">{form1.getFieldValue('project')}</Text>
                    </Form.Item>
                    <Form.Item label="店铺" name="shop">
                        <Text type="secondary">{form1.getFieldValue('shop')}</Text>
                    </Form.Item>
                    <Form.Item label="月份" name="month">
                        <Text type="secondary">{form1.getFieldValue('month')}</Text>
                    </Form.Item>
                    <Form.Item label="业绩目标" name="sale">
                        <Input />
                    </Form.Item>
                    <div style={{display:'flex', justifyContent:'space-around'}}>
                        <Button type="primary" htmlType="submit">添加数据</Button>
                    </div>
                </Form>
            </Modal>

            <Modal
                title="上传预算目标"
                visible={uploadBudgetTarget}
                centered={true}
                okText="确定"
                cancelText="取消"
                onOk={() => {
                    uploadBudgetTargetFile()
                }}
                onCancel={() => {
                    setBudgetTargetFile([])
                    setUploadBudgetTarget(false)
                }}
                okButtonProps={{disabled: effective == 1 ? true : false}}
                cancelButtonProps={{disabled: effective == 1 ? true : false}}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={budgetTargetFile}
                    onChange={budgetTargetFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
            </Modal>
        </div>
        
    )

    // 年份改变
    function yearChange(date, dateString) {
        if (date === null || date === '' || date === undefined) {
            message.warn('请选择正确的年份')
            return false
        }else{
            setYear(dateString)
        }
    }

   


}

export default Index