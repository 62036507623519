import { Spin, Table, Typography, InputNumber, Modal, Upload, message, Tooltip, DatePicker, Button } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import React, { useContext, useState, useEffect } from 'react';
import { StateContext } from '../raceShop/store/stateShare'
import TitleCard from './components/titleCard'
import {
    CalendarOutlined,
    RightOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined,
    SyncOutlined,
    LeftOutlined,
    CloudUploadOutlined,
    LoadingOutlined,
    CloudDownloadOutlined
} from '@ant-design/icons';
import moment from "moment"
import cookie from 'react-cookies'
import './style.css'
import NbAxios from '../../config/utils/nbAxios'
import axios from 'axios'
import servicePath from '../../config/apiUrl';
import Debounce from '../../config/utils/debounce';
const { Title } = Typography;
const { Dragger } = Upload;
function MonthlyReport() {
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    const [titleData, setTitleData] = useState()
    // 标题卡片排序
    const cardList = ['总计回顾', '天猫', '淘宝', '京东', '抖音-姿芮', '供应链']
    // 字段名字典
    const filed_dict = {
        'xh_pay_money': '支付金额',
        'xh_get_money': '回款金额',
        'xh_go_away_money': '退款金额'
    }
    // 表格列
    const [tableColumns, setTableColumns] = useState([])
    // 表格数据
    const [tableData, setTableData] = useState([])
    // 表格加载状态
    const [tableLoading, setTableLoading] = useState(false)
    // 本月开始日期
    const [monthStart, setMonthStart] = useState(moment().startOf('month'))
    // 本月结束日期
    const [monthEnd, setMonthEnd] = useState(moment().endOf('month'))
    // 日期框中日期
    const [month, setMonth] = useState(moment())
    // 上传文件列表
    const [list, setList] = useState(new FormData())
    //上传文件名
    const [excelName, setExcelName] = useState()
    // 上传文件状态
    const [uploadLoading, setUploadLoading] = useState(0)
    // 上传文件状态对应图表
    const stausIcon = {
        // 无状态
        0:'',
        // 加载中
        1:<LoadingOutlined/>,
        // 成功
        3:<CheckCircleOutlined/>,
        // 错误
        4:<CloseCircleOutlined />
    }
    // 上传框是否弹出
    const [visible, setVisible] = useState(false)
    // 百分比数值
    const [percent, setPercent] = useState(50)
    // 表格中的数据
    useEffect(() => {
        let res = getBetweenDateStr(monthStart.format('YYYY-MM-DD'), monthEnd.format('YYYY-MM-DD'))
        setTableColumns(res)
        getTableData()
    }, [monthStart, monthEnd, percent])
    //EXCEL上传组件的参数
    const propsExcel = {
        //如果上传成功
        onSuccess: (ret, file) => {
        },
        onProgress: ({ percent }, file) => {
        },
        //上传前判断文件类型
        beforeUpload: (file, fileList) => {
            let aa = file.name.split('.').slice(-1)[0]
            if (aa.indexOf('xls') === -1 && aa.indexOf('XLS') === -1) {
                message.warn('请上传excel!')
                return false
            } else {
                setExcelName(file.name)
                list.append('EXCEL', fileList[0])
                return true
            }
        },
        //自定义EXCEL的上传方法
        customRequest: upLoadExcel,
    }

    return (
        <div className="month-index-wapper" style={{ margin: "20px 40px", display: "flex" }}>
            <Spin spinning={tableLoading}>
                <div className="month-title-wapper">
                    <div className="month-title">
                        <Title style={{ margin: 0, lineHeight: "50px" }} level={4}><CalendarOutlined style={{ marginRight: 5, fontWeight: 400 }} />经销全渠道月度汇总看板</Title>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
                        <Modal onOk={showUpload} onCancel={showUpload} title="上传经销数据" visible={visible}>
                            <Dragger showUploadList={false} {...propsExcel}>
                                {/* 文件名及上传进度条 */}
                                <Title level={5}>点击或拖拽店铺日报EXCEL文件于此上传</Title>
                                {excelName &&
                                    <div>
                                        <span style={{ fontSize: 20, marginRight: 10 }}>
                                            {excelName}
                                        </span>
                                        <span>
                                            {stausIcon[uploadLoading]}
                                        </span>
                                    </div>
                                }
                            </Dragger>
                        </Modal>
                        <span className="get_money_percent_input" style={{display: "flex",alignItems: "center"}}>
                            经销JD回款比例：
                            <InputNumber
                                defaultValue={50}
                                min={0}
                                max={100}
                                prefix="JD回款比例"
                                style={{ height: "26px",width:70 }}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                                onChange={Debounce(percentChange, 200)}
                            />
                        </span>
                        <DatePicker
                            value={month}
                            disabledDate={disabledMonth}
                            picker="month"
                            style={{ height: "30px", lineHeight: "50px", marginLeft: 5 }}
                            locale={locale}
                            style={{ height: 26 }}
                            onChange={monthChange}
                        />
                        <Tooltip placement="bottomLeft" title="月份减1" >
                            <Button
                                disabled={month.format('YYYY-MM') == moment('2021-01').format('YYYY-MM')}
                                onClick={() => changeMonth('sub')}
                                style={{
                                    padding: "0 5px",
                                    width: 28,
                                    height: 26,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "rgba(0,0,0,0.5)"
                                }}
                            >
                                <LeftOutlined style={{lineHeight: "10px", fontSize: 12}}/>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="月份加1">
                            <Button disabled={month.format('YYYY-MM') == moment().format('YYYY-MM')}
                                    onClick={() => changeMonth('add')} style={{
                                padding: "0 5px",
                                width: 28,
                                height: 26,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "rgba(0,0,0,0.5)"
                            }}><RightOutlined style={{lineHeight: "10px", fontSize: 12}}/></Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="创建下载任务">
                            <Button onClick={createDownloadTest} style={{
                                padding: "0 5px",
                                height: 26,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}><CloudDownloadOutlined/></Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="上传经销日报excel">
                            <Button onClick={showUpload} style={{
                                padding: "0 5px",
                                height: 26,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}><CloudUploadOutlined/></Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="刷新表格数据">
                            <Button onClick={getTableData} style={{
                                padding: "0 5px",
                                height: 26,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}><SyncOutlined/></Button>
                        </Tooltip>
                    </div>
                </div>
                <div className="month-title-card-wapper">
                    {titleData && cardList.map((item, index) => {
                        return (
                            <TitleCard
                                channel={item == '总计回顾' ? '总计' : item}
                                target={titleData[item].target}
                                finshRate={titleData[item].finshRate}
                                hasFinshNum={titleData[item].reGet}
                                inTotal={titleData[item].inTotal}
                            />
                        )
                    })}
                </div>
                <div className="month-table-wapper">
                    <Table
                        dataSource={tableData}
                        bordered
                        scroll={{ x: 3000, y: `${state.size.width > 1500 ? '60vh' : '56vh'}` }}
                        pagination={false}
                        columns={
                            [
                                {
                                    title: '字段',
                                    dataIndex: 'filed',
                                    key: 'filed',
                                    align: 'center',
                                    width: 80,
                                    fixed: "left",
                                    render: (item, record, index) => {
                                        if (index==0) {
                                            return {
                                                children: <span>{filed_dict[item]}</span>,
                                                props: { rowSpan: 7 }
                                            }
                                        } else if(index==7 || index==14) {
                                            return {
                                                children: <span>{filed_dict[item]}</span>,
                                                props: { rowSpan: 7 }
                                            }
                                        }else{
                                            return {
                                                children: <span>{filed_dict[item]}</span>,
                                                props: { rowSpan: 0 }
                                            }
                                        }
                                    }
                                },
                                {
                                    title: '店铺',
                                    dataIndex: 'shop',
                                    key: 'shop',
                                    align: 'center',
                                    width: 100,
                                    fixed: "left"
                                },
                                {
                                    title: '店铺合计',
                                    dataIndex: 'shop_total',
                                    key: 'shop_total',
                                    align: 'center',
                                    width: 100,
                                    fixed: "left",
                                    onCell: (value) => {
                                        let s = value['shop_total'];
                                        let isRed
                                        if (s > 0) {
                                            // 如果该数字包含-，则为负数
                                            isRed = false
                                        } else if (s == 0) {
                                            isRed = false
                                        } else {
                                            isRed = true
                                        }
                                        return (
                                            {
                                                style: {
                                                    padding: 0,
                                                    height: "20px",
                                                    color: isRed ? "red" : null
                                                }
                                            }
                                        )
                                    },
                                },
                                ...tableColumns.map((item) => {
                                    return {
                                        title: item,
                                        dataIndex: `${monthEnd.format('YYYY')}-${item}`,
                                        key: item,
                                        align: 'center',
                                        width: 100,
                                        onCell: (value) => {
                                            let s = value[`${monthEnd.format('YYYY')}-${item}`];
                                            return (
                                                {
                                                    style: {
                                                        padding: 0,
                                                        height: "20px",
                                                        color: parseFloat(s) < 0 ? "red" : "null"
                                                    }
                                                }
                                            )
                                        },
                                        render: (item, record, index) => {
                                            if (item) {
                                                return item
                                            } else {
                                                if(item === 0){
                                                    return 0
                                                }else{
                                                    return "-"
                                                }
                                            }
                                        }
                                    }
                                })
                            ]
                        }
                    />
                </div>
            </Spin>
        </div>
    )

    // 百分比变化
    function percentChange(value) {
        setPercent(value)
    }

    // 不可选的周
    function disabledMonth(current) {
        // 不可选2020年得数据以及后一月的数据
        return current <= moment('2020-12-31') || current > moment().endOf("month");
    }

    // 月份改变
    function monthChange(value) {
        if (value) {
            setMonth(value)
            setMonthStart(moment(value).startOf('month'))
            setMonthEnd(moment(value).endOf('month'))
        }
    }

    // 按钮加减月份
    function changeMonth(type) {
        if (type === 'add') {
            setMonth(moment(month).add(1, "months"))
            monthChange(moment(month).add(1, "months"))
        } else {
            setMonth(moment(month).subtract(1, "months"))
            monthChange(moment(month).subtract(1, "months"))
        }
    }


    // 获取表格数据
    function getTableData() {
        let data = {}
        data.start_date = monthStart.format('YYYY-MM-DD')
        data.end_date = monthEnd.format('YYYY-MM-DD')
        data.shopList = cookie.load('shopList')
        data.roleId = cookie.load('role')
        data.get_percent = percent
        setTableLoading(true)
        setTableData([])
        setTitleData()
        NbAxios(data, 'POST', servicePath.getMonthTableData).then(res => {
            if (res.success) {
                setTableData(res.data.table)
                setTitleData(res.data.total)
            }
            setTableLoading(false)
        }).catch(e => {
            setTableLoading(false)
        })
    }

    // 上传弹框的控制
    function showUpload() {
        setVisible(visible ? false : true)
    }
    // 上传文件
    function upLoadExcel(options) {
        const { onSuccess, onProgress, file } = options;
        list.append('shopList', cookie.load('shopList'))
        list.append('name', cookie.load('name'))
        list.append('roleId', cookie.load('role'))
        list.append('uploadTime', moment().format("YYYY-MM-DD-HH-mm-ss"))
        setUploadLoading(1)
        axios({
            method: 'POST',
            url: servicePath.uploadXhExcel,
            data: list,
            headers: {
                // authorization: 'authorization-text',
                authorization: cookie.load('token')
            }
        }).then(
            res => {
                onSuccess(res, file);
                if (res.data.success) {
                    setList(new FormData())
                    message.success("上传成功!正在刷新数据")
                    showUpload()
                    getTableData()
                    setUploadLoading(3)
                } else {
                    setList(new FormData())
                    message.warn(res.data.errorMsg)
                    setUploadLoading(4)
                }
            }
        ).catch(
            error => {
                message.error('上传失败')
                setList(new FormData())
                setUploadLoading(4)
            }
        )
    }

    // 计算两个日期之间的日期
    function getBetweenDateStr(start, end) {
        let result = [];
        let beginDay = start.split("-");
        let endDay = end.split("-");
        let diffDay = new Date();
        let dateList = new Array;
        let i = 0;
        diffDay.setDate(beginDay[2]);
        diffDay.setMonth(beginDay[1] - 1);
        diffDay.setFullYear(beginDay[0]);
        let startDay = start.substring(5, start.size)
        result.push(startDay);
        while (i == 0) {
            var countDay = diffDay.getTime() + 24 * 60 * 60 * 1000;
            diffDay.setTime(countDay);
            dateList[2] = diffDay.getDate();
            dateList[1] = diffDay.getMonth() + 1;
            dateList[0] = diffDay.getFullYear();
            if (String(dateList[1]).length == 1) {
                dateList[1] = "0" + dateList[1]
            }
            ;
            if (String(dateList[2]).length == 1) {
                dateList[2] = "0" + dateList[2]
            }
            ;
            result.push(dateList[1] + "-" + dateList[2]);
            if (dateList[0] == endDay[0] && dateList[1] == endDay[1] && dateList[2] == endDay[2]) {
                i = 1;
            }
        }
        ;
        // result.reverse()
        return result;
    }

    // 创建下载任务
    function createDownloadTest() {
        let data = {}
        data.start_date = monthStart.format('YYYY-MM-DD')
        data.end_date = monthEnd.format('YYYY-MM-DD')
        data.shopId = cookie.load('shopList')
        data.roleId = cookie.load('role')
        data.principal_uuid = cookie.load('id')
        data.get_percent = percent
        NbAxios(data, 'POST', servicePath.creatXhMonthTableFileTask).then(res => {
            if (res.success) {
                message.success('下载任务创建成功')
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
}
export default MonthlyReport