import React, { useState, useEffect, useCallback, } from 'react';
import moment from 'moment'
import { UploadOutlined, RightOutlined, EditOutlined } from '@ant-design/icons';
import { message, Row, Col, Button, DatePicker, Upload, Tag, Alert, Drawer, Form, Input, Select, Progress } from 'antd'
import BraftEditor from 'braft-editor'
import cookie from 'react-cookies'
import axios from 'axios'
import locale from 'antd/es/date-picker/locale/zh_CN';
import servicePath from '../../../../config/apiUrl'
import '../../../../static/css/index.css'
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;
const { RangePicker } = DatePicker;
function DailyReport(props) {


    const [form1] = Form.useForm()

    const [form2] = Form.useForm()

    const [form3] = Form.useForm()

    const [form4] = Form.useForm()


    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 20 },
    }

    // 设置编辑器初始内容
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''))

    // 富文本编辑器控件
    const extendControls = ['bold', 'italic', 'underline', 'text-color', 'separator', 'link',]

    // 日期
    const [date, setDate] = useState(moment().locale('zh-cn').format('YYYY-MM-DD'))

    // 文档文件集合
    const [docList, setDocList] = useState([])

    // 任务列表
    const [taskList, setTaskList] = useState([])

    // 日报id
    const [id, setId] = useState('')

    // 文件名列表
    const [fileNameList, setFileNameList] = useState([])

    // 文件id列表
    const [fileIdList, setFileIdList] = useState([])

    // 任务标记 false 就是没有数据 true就是有数据
    const [flag, setFlag] = useState('')

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 任务名字
    const [name, setName] = useState('')

    // 任务项目
    const [project, setProject] = useState('')

    // 任务团队
    const [team, setTeam] = useState('')

    // 任务负责人
    const [director, setDirector] = useState('')

    // 任务协助人
    const [collaborators, setCollaborators] = useState([])

    // 任务开始时间
    const [startTime, setStartTime] = useState('')

    // 任务结束时间
    const [endTime, setEndTime] = useState('')

    // 任务天
    const [day, setDay] = useState('')

    // 任务小时
    const [hour, setHour] = useState('')

    // 父任务名
    const [pName, setPName] = useState('')

    // 任务内容
    const [content, setContent] = useState('')

    // 任务进度
    const [progress, setProgress] = useState(0)

    // 任务优先级
    const [priority, setPriority] = useState('')

    // 任务文件名
    const [taskFileNameList, setTaskFileNameList] = useState([])

    // 任务文件id
    const [taskFileIdList, setTaskFileIdList] = useState([])

    // 查看任务
    const [updateTask, setUpdateTask] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    useEffect(() => {
        let now = moment().locale('zh-cn').format('YYYY-MM-DD');
        setDate(now);
    }, [])

    // 编辑内容触发
    const editorChange = (editorState) => {
        setEditorState(editorState)
    }

    // 文档列表改变的时候出发
    const fileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'zip', 'rar', 'gif', 'jpg', 'jpeg', 'png', 'jpg', 'pptx', 'txt']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    setDocList(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                }
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setDocList(fileList)
        }
    }

    // 初始化数据，获取日报数据
    useEffect(() => {
        setEditorState(BraftEditor.createEditorState(''))
        setDocList([])
        setFileNameList([])
        setFileIdList([])
        getOwnDailyReport()
        getOwnDailyTaskProgress()
    }, [date])

    // 查询日报
    const getOwnDailyReport = () => {
        let dataProps = {
            "date": date,
            "principal": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryOwnDailyReport,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data.success) {
                    setFlag(res.data.success)
                    setId(res.data.data[0].id)
                    setFileNameList(res.data.data[0].file_name)
                    setFileIdList(res.data.data[0].file_uuid)
                    setEditorState(BraftEditor.createEditorState(res.data.data[0].content))
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        setFlag(res.data.success)
                    }
                }
            }
        )
    }

    // 提交日报
    const submit = () => {
        setEffective(1)
        const formData = new FormData()
        // 多个文件
        docList.forEach((item) => {
            formData.append('file_list', item['originFileObj'])
        })
        // 店铺ID
        formData.append('shopId', cookie.load('shopList'))
        // 角色ID
        formData.append('roleId', cookie.load('role'))
        // 日报内容
        formData.append('content', editorState.toHTML())
        // 操作人
        formData.append('principal', cookie.load('name'))
        // 日报日期
        formData.append('date', date)
        // 店铺ID
        formData.append('shopId', cookie.load('shopList'))
        // 角色ID
        formData.append('roleId', cookie.load('role'))
        axios({
            method: 'POST',
            url: servicePath.createDailyReport,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data['success']) {
                    message.success("日报提交成功")
                    setEditorState(BraftEditor.createEditorState(''))
                    setDocList([])
                    getOwnDailyReport()
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 更新日报
    const update = () => {
        setEffective(1)
        const formData = new FormData()
        // 多个文件
        docList.forEach((item) => {
            formData.append('file_list', item['originFileObj'])
        })
        // 店铺ID
        formData.append('shopId', cookie.load('shopList'))
        // 角色ID
        formData.append('roleId', cookie.load('role'))
        // 日报内容
        formData.append('content', editorState.toHTML())
        // 操作人
        formData.append('principal', cookie.load('name'))
        // 日报日期
        formData.append('date', date)
        // 日报id
        formData.append('id', id)
        // 店铺ID
        formData.append('shopId', cookie.load('shopList'))
        // 角色ID
        formData.append('roleId', cookie.load('role'))
        axios({
            method: 'POST',
            url: servicePath.updateDailyReport,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data['success']) {
                    message.success("日报更新成功")
                    setEditorState(BraftEditor.createEditorState(''))
                    setDocList([])
                    getOwnDailyReport()
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 更新每日个人的工作进度变化
    const getOwnDailyTaskProgress = () => {
        let dataProps = {
            "principal": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "date": date
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryOwnDailyTaskProgress,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTaskList(res.data.data)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取任务
    const getStateTask = (id) => {
        let dataProps = {
            "id": id,
            "principal": cookie.load('name'),
            "page": 1,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryProjectManagementTask,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setName(res.data.data[0].name)
                    setProject(res.data.data[0].project)
                    setTeam(res.data.data[0].team)
                    setDirector(res.data.data[0].director)
                    setCollaborators(res.data.data[0].collaborators)
                    setStartTime(res.data.data[0].start_time)
                    setEndTime(res.data.data[0].end_time)
                    setDay(res.data.data[0].day)
                    setHour(res.data.data[0].hour)
                    setPriority(res.data.data[0].priority)
                    setPName(res.data.data[0].pid_name)
                    setProgress(res.data.data[0].progress)
                    setContent(res.data.data[0].introduction)
                    setTaskFileNameList(res.data.data[0].file_name)
                    setTaskFileIdList(res.data.data[0].file_uuid)
                    setUpdateTask(true)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 关闭查看任务
    const onUpdateTask = () => {
        setName('')
        setProject('')
        setTeam('')
        setDirector('')
        setCollaborators([])
        setStartTime('')
        setEndTime('')
        setDay('')
        setHour('')
        setPriority('')
        setPName('')
        setContent('')
        setProgress(0)
        setTaskFileNameList([])
        setTaskFileIdList([])
        setUpdateTask(false)
    }



    return (
        <div style={{ paddingBottom: size.height * 0.1, margin: '0 auto', width: size.width * 0.6 }}>
            <div>
                <Row>
                    <Col>
                        <DatePicker
                            locale={locale}
                            defaultValue={moment(moment().locale('zh-cn').format('YYYY-MM-DD'), dateFormat)}
                            format={dateFormat}
                            disabledDate={(current) => {
                                return current && current > moment().endOf('day');
                            }}
                            onChange={(date, dateString) => {
                                setDate(dateString)
                            }}
                        />
                    </Col>
                    <Col>
                        {/* 
                            判断逻辑
                            1.无内容则显示提交按钮
                            2.有内容则显示更新按钮
                            3.只有当前的更新按钮可以点击
                         */}
                        {
                            flag == true
                                ? <Button style={{ marginLeft: '20px' }} type="primary" disabled={moment().locale('zh-cn').format('YYYY-MM-DD') != date || effective == 1 ? true : false} onClick={() => update()}>更新</Button>
                                : <Button style={{ marginLeft: '20px' }} type="primary" disabled={effective == 1 ? true : false} onClick={() => submit()}>提交</Button>
                        }
                    </Col>
                    <Col>
                        {/* 
                            判断逻辑:
                            1. 要是不是今日而且没有内容,则显示文字
                            2. 要是不是今日而且有内容,则不显示文字
                            3. 要是是今日而且有内容,则不显示文字
                        */}
                        <div style={{ display: moment().locale('zh-cn').format('YYYY-MM-DD') == date || flag ? 'none' : '', color: 'red', lineHeight: '50px', marginLeft: '20px' }}>非今日的日报提交后无法更改</div>
                    </Col>
                </Row>
            </div>
            <div style={{ height: size.height * 0.4, width: size.width * 0.6, border: '1px solid #ccc', marginTop: '10px' }}>
                <BraftEditor
                    contentStyle={{ height: 200 }}
                    value={editorState}
                    extendControls={extendControls}
                    onChange={editorChange}
                />
            </div>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                {
                    fileNameList.map((item, index) => {
                        return <a target="_blank" href={servicePath.downloadDailyReportFile + '?principal=' + cookie.load('name') + '&file_uuid=' + fileIdList[index] + '&roleId=' + cookie.load('role') + '&shopId=' + cookie.load('shopList')}><Tag color="blue">{item}</Tag></a>
                    })
                }
            </div>
            <Row>
                <Col span={12}>
                    <Row>
                        一次最多上传5个文件:
                        <div>
                            <Upload
                                accept=".doc,.docx,.xlsx,.xls,.pdf,.zip,.rar,.txt,image/*"
                                action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                                listType="picture"                                 // 上传列表的内建样式
                                fileList={docList}                                //已经上传的文件列表（受控）
                                onChange={fileHandleChange}
                            >
                                {docList.length >= 5 ? null :
                                    <Button>
                                        <UploadOutlined /> 文件
                                    </Button>}

                            </Upload>
                        </div>
                    </Row>
                </Col>
                <Col span={12}>
                    今天完成任务:
                    {
                        taskList.map(item => {
                            return (
                                <div>
                                    <Alert
                                        message={item.task_name + `的进度从` + item.progress_first + `%进行到` + item.progress_second + `%`}
                                        type="info"
                                        onClick={() => getStateTask(item.task_id)} />
                                </div>
                            )
                        })
                    }
                </Col>
            </Row>
            {/* 查看任务 */}
            <Drawer
                title="查看任务"
                placement="right"
                closable={false}
                onClose={onUpdateTask}
                visible={updateTask}
                width={1000}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Form
                    form={form1}
                    labelAlign="left"
                    {...layout1}

                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="任务名字"
                            >
                                {name}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="project"
                                label="项目"
                            >
                                {project}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form
                                labelAlign="left"
                                form={form4}
                                {...layout1}>
                                <Form.Item
                                    name="team"
                                    label="团队"
                                >
                                    {team}
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Form
                                labelAlign="left"
                                form={form3}
                                {...layout1}>
                                <Form.Item
                                    name="principal"
                                    label="负责人"
                                >
                                    {director}
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Form
                                labelAlign="left"
                                form={form2}
                                {...layout1}>
                                <Form.Item
                                    name="coadjutant"
                                    label="协作者"
                                >
                                    {
                                        collaborators.map(item => {
                                            return <span >{item} </span>
                                        })
                                    }
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="time"
                                label="任务时间"
                            >
                                {startTime} - {endTime}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="duration"
                                label="预计工期"
                            >
                                {day}天{hour}小时
                                </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="yxj"
                                label="优先级"
                            >
                                {
                                    priority == "1" ? '严重'
                                        : priority == "2" ? '主要'
                                            : priority == "3" ? '次要'
                                                : priority == "4" ? '不重要'
                                                    : ''
                                }

                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="frw"
                                label="关联父任务"
                            >
                                {
                                    pName
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="type"
                                label="任务类型"
                            >

                            </Form.Item>
                        </Col>
                        <div style={{ height: 500, width: 1000, border: '1px solid #ccc', overflow: 'auto' }}>
                            <div dangerouslySetInnerHTML={{ __html: content }}></div>
                        </div>
                    </Row>
                    <div>
                        <Row>
                            任务进度:
                                <Col span={20} style={{ marginLeft: '5px' }}>
                                <Progress percent={progress} />
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {
                            taskFileNameList.map((item, index) => {
                                return <a target="_blank" href={servicePath.downloadTaskFile + '?principal=' + cookie.load('name') + '&file_uuid=' + taskFileIdList[index] + '&roleId=' + cookie.load('role') + '&shopId=' + cookie.load('shopList')}><Tag color="blue">{item}</Tag></a>
                            })
                        }
                    </div>
                </Form>
            </Drawer>
        </div>
    )


}

export default DailyReport