import React, { useEffect, useState } from 'react';
import { Input, Typography, Checkbox, message, Modal, Button, Spin, Table, Select, Progress, Tooltip, Tag, Radio, Image } from 'antd'
import { SearchOutlined, DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl';
import NbAxios from '../../config/utils/nbAxios';
import axios from 'axios'
import webUrl from '../../config/webUrl';
import AcceptBuried from '../../config/utils/buried';
import downExcel from "../../config/utils/downloadFile"
import style from './index.module.css'
import { inRange, iteratee, set } from 'lodash';
import JsBarcode from 'jsbarcode';
import Barcode from './components/barcode';
import { tuple } from 'antd/lib/_util/type';
const { Title, Text, Paragraph } = Typography;
const { Option } = Select
function Create(props) {
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    // 表头列表
    const [columnList] = useState(["序号", "款图", "货品编码", "品牌", "分类", "码数", "数量", "吊牌价", "金额", "结算价", "结算金额", "结算折扣", "应付金额", "编辑"])
    // 打印表头列表
    const [printColumnList] = useState(["序号", "货品编码", "品牌", "分类", "码数", "数量", "吊牌价", "金额", "结算价", "结算金额", "结算折扣", "应付金额"])
    // 详情列表
    const [detailList] = useState(["商家编码", "货品编码", "品牌", "库存", "码数", "结算价"])
    // 录入商品详情列表
    const [detailInsertList] = useState(["商家编码", "货品编码", "品牌", "分类", "码数", "数量", "吊牌价", "结算价"])
    // 打折列表
    const [discountBrand] = useState(["莓拉", "JOOMiLiM"])
    // 导出权限校验
    const [jurisdiction] = useState(["于雪","沈费","唐铎","叶康辉","周浩琪","姚凯鹏","王修文","陈黎黎","曹守梅"])
    // 详情数据
    const [detailData, setDetailData] = useState({})
    // 正品价格
    const [tPrice, setTPrice] = useState('')
    // 次品价格
    const [fPrice, setFPrice] = useState('')
    // 设置表头
    const [column, setColumn] = useState([])
    // 设置打印表头
    const [printColumn, setPrintColumn] = useState([])
    // 折扣选择
    const [discount, setDiscount] = useState([])
    // 折扣列表
    const [discountList, setDiscountList] = useState([0.95])
    // 条形码
    const [barCode, setBarCode] = useState('')
    // 是否打开支付窗口
    const [isOrder, setIsOrder] = useState(false)
    // 总价格
    const [total, setTotal] = useState(10000)
    // 是否需要更改价格
    const [updateFlag,setUpdateFlag] = useState(true)
    // 订单编号
    const [orderCode, setOrderCode] = useState('')
    // 表格明细内容
    const [tableData, setTableData] = useState([])
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 是否在导出中
    const [exportFlag, setExportFlag] = useState(0)
    // 是否导出所有明细数据
    const [isExportAllModel, setIsExportAllModel] = useState(false)
    // 是否打开商品信息窗口
    const [isGoodsDetial, setIsGoodsDetial] = useState(false)
    // 是否打开录入商品信息窗口
    const [isGoodsDetialInsert, setIsGoodsDetialInsert] = useState(false)
    // 商品信息
    const [goodsInfo, setGoodsInfo] = useState({
            "商家编码":"",
            "货品编码":"",
            "品牌":"",
            "分类":"",
            "码数":"", 
            "数量":"", 
            "吊牌价":"", 
            "结算价":""
        }
    )
    // 生成订单条形码
    const [barcodeSVG, setBarcodeSVG] = useState('')
    // 饰品折扣
    const [jewelryDiscount, setJewelryDiscount] = useState(1)
    // 正次品选择
    const [goodsType, setGoodsType] = useState(1)

    // 总计计算和增加
    const totalAdd = (obj, setTableData) => {
        // 如果最后一列是总计，则删除
        if (obj[obj.length - 1]) {
            if (obj[obj.length - 1]["序号"] == "合计") {
                obj.pop()
            }
        }
        // 计算总计
        let sumdP = 0
        let sumdj = 0
        let sumds = 0
        let num = 0
        let sumyf = 0
        let sumjsj = 0
        obj.map(item => {
            // if (item["吊牌价"]) {
            //     sumdP = sumdP + item["吊牌价"]
            // }
            if (item["金额"]) {
                sumdj = sumdj + item["金额"]
            } if (item["结算金额"]) {
                sumds = sumds + item["结算金额"]
            }
            if (item["应付金额"]) {
                sumyf = sumyf + item["应付金额"]
            }
            if (item["数量"]) {
                num = num + item["数量"]
            }
            // if (item["结算价"]) {
            //     sumjsj = sumjsj + item["结算价"]
            // }
        })
        obj.push({
            "序号": "合计",
            // "吊牌价": sumdP,
            "金额": sumdj,
            "结算金额": sumds,
            "应付金额": sumyf,
            // "结算价": sumjsj,
            "数量": num,
        })
        setTableData(obj)
    }
    // 查询条形码接口
    const goodsInfoByCode = (merchant_code) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            merchant_code: merchant_code,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.goodsInfoByCode).then(res => {
            setLoading(false)
            
            if (res.success) {
                setBarCode('')
                setTPrice(res.data["正次品价格"]["1"])
                setFPrice(res.data["正次品价格"]["2"])
                let obj = [...tableData]
                // 如果最后一列是总计，则删除
                if (obj[obj.length - 1]) {
                    if (obj[obj.length - 1]["序号"] == "合计") {
                        obj.pop()
                    }
                }
                // 折扣flag
                let discountFlag = 0
                // 是否是重复商品
                let repeat = true
                let outOff = false
                // 打开弹窗
                setDetailData(res.data)
                setIsGoodsDetial(true)
                obj.map((item, index) => {
                    // 如果是重复商品数量加一
                    if (res.data["商家编码"] == item["商家编码"] && res.data["结算价"] == item["结算价"]) {
                        repeat = false
                        // if (item["数量"] >= item["库存"]) {
                        //     message.error("库存不足")
                        //     outOff = true
                        //     return false
                        // }
                        item["数量"] = item["数量"] + 1
                    }
                    // 如果是饰品，则加入饰品折扣
                    if (discountBrand.indexOf(item["品牌"]) !== -1) {
                        discountFlag = discountFlag + item["数量"]
                    }
                    // item["金额"] = item["吊牌价"] * item["数量"]
                    // item["结算金额"] = item["结算价"] * item["数量"]
                    // item["应付金额"] = item["结算金额"]
                })
                
                // // 如果库存不足就不执行后面的操作
                // if(outOff){
                //     return false
                // }
                // 如果不是重复商品则添加进入列表
                if (repeat) {
                    res.data["数量"] = 1
                    res.data["结算金额"] = res.data["结算价"] * res.data["数量"]
                    res.data["应付金额"] = res.data["结算金额"]
                    res.data["金额"] = res.data["吊牌价"] * res.data["数量"]
                    // // 如果库存不足就不执行后面的操作
                    // if(res.data["数量"]>=res.data["库存"]){
                    //     message.error("库存不足")
                    //     return false
                    // }else{
                    //     obj.push(res.data)
                    // }
                    // 如果是饰品，则加入饰品折扣
                    if (discountBrand.indexOf(res.data["品牌"]) !== -1) {
                        discountFlag = discountFlag + res.data["数量"]
                    }
                    obj.push(res.data)
                }
                // // 如果饰品总量大于等于3件，则饰品打折
                // if (discountFlag >= 3) {
                //     obj.map(item => {
                //         let _discount = discount.length !== 0 ? discount : 1
                //         // 如果是饰品，则加入饰品折扣
                //         if (discountBrand.indexOf(item["品牌"]) !== -1) {
                //             let jewelryDiscount = 1
                //             if (discountFlag >= 3 && discountFlag < 5) {
                //                 jewelryDiscount = 0.9
                //             } else if (discountFlag >= 5) {
                //                 jewelryDiscount = 0.8
                //             }
                //             item["金额"] = item["吊牌价"] * item["数量"] * jewelryDiscount
                //             item["结算金额"] = item["结算价"] * item["数量"] * jewelryDiscount
                //             item["应付金额"] = item["结算金额"] * jewelryDiscount
                //             item["结算折扣"] = jewelryDiscount
                //         } else {
                //             item["金额"] = item["吊牌价"] * item["数量"] * _discount
                //             item["结算金额"] = item["结算价"] * item["数量"] * _discount
                //             item["应付金额"] = item["结算金额"] * _discount
                //             item["结算折扣"] = _discount
                //         }

                //     })
                // }
                // 打折
                obj.map(item => {
                    let _discount = discount.length !== 0 ? discount[0] : 1
                    // 如果是饰品，则加入饰品折扣
                    if (discountBrand.indexOf(item["品牌"]) !== -1 && discountFlag>=3) {
                        let jewelryDiscount = 1
                        if (discountFlag >= 3 && discountFlag < 5) {
                            jewelryDiscount = 0.9
                        } else if (discountFlag >= 5) {
                            jewelryDiscount = 0.8
                        }
                        item["金额"] = item["吊牌价"] * item["数量"]
                        item["结算金额"] = item["结算价"] * item["数量"]
                        item["应付金额"] = item["结算金额"] * jewelryDiscount
                        item["结算折扣"] = jewelryDiscount
                    } else {
                        item["金额"] = item["吊牌价"] * item["数量"]
                        item["结算金额"] = item["结算价"] * item["数量"]
                        item["应付金额"] = item["结算金额"] * _discount
                        item["结算折扣"] = _discount
                    }

                })

                // 添加序号
                obj.map((item, index) => {
                    item["序号"] = index + 1
                })
                // 重新计算总计列
                totalAdd(obj, setTableData)
                // setDetailData(res.data)
                // setTableData(obj)
                setJewelryDiscount(discountFlag)
                // setIsGoodsDetial(true)
            } else {
                message.warn({ content: res.errorMsg })
                if(res.errorMsg == '未查询到商品'){
                    setUpdateFlag(false)
                    setIsGoodsDetialInsert(true)
                    let obj = {
                        "商家编码":barCode,
                        "货品编码":"",
                        "品牌":"",
                        "分类":"",
                        "码数":"", 
                        "数量":"", 
                        "吊牌价":"", 
                        "结算价":""
                    }
                    setGoodsInfo(obj)
                }
                setBarCode('')
            } 
        }).catch((err) => {
            setBarCode('')
            setLoading(false)
            // 获取500错误
            message.warn('查询条形码接口报错，请联系IT帮忙解决')
        })

    }
    // 删除商品
    const delGoods = (record) => {
        let obj = [...tableData]
        obj.remove(record)
        // 如果最后一列是总计，则删除
        if (obj[obj.length - 1]) {
            if (obj[obj.length - 1]["序号"] == "合计") {
                obj.pop()
            }
        }
        // 如果删除的是饰品，则重新计算饰品折扣
        if (discountBrand.indexOf(record["品牌"]) !== -1) {
            let jwCount = jewelryDiscount
            jwCount = jwCount - record["数量"]
            setJewelryDiscount(jwCount)
        }
        // 重新生成序号
        obj.map((item, index) => {
            item["序号"] = index + 1
        })
        if (obj.length !== 0) {
            totalAdd(obj, setTableData)
        } else {
            setTableData(obj)
        }

    }
    // 生成订单
    const createOrder = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            name:cookie.load('name'),
            data: tableData,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.createOrder).then(res => {
            setLoading(false)
            if (res.success) {
                setOrderCode(res.data)
                setIsOrder(true)
                // props.history.push({ 'pathname': `/app/pos/initial` })
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('生成订单报错，请联系IT帮忙解决')
        })
    }
    //该方法参数 content：需要打印的元素
    const printFun = (content) => {
        var Window = window.open("", "页面打印", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no");
        var style = "<style type='text/css'></style>";
        Window.document.write(content + style);
        Window.focus();
        Window.document.close();     //关闭document的输出流, 显示选定的数据
        Window.print();               //打印当前窗口
        return Window;
    }
    // 更改订单状态
    const modifyOrderStatus = (status) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            name:cookie.load('name'),
            data: tableData,
            order_state: status,
            order_code: orderCode,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.modifyOrderStatus).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("订单处理成功")
                var windows = printFun(document.getElementById('print').innerHTML);
                windows.close();
                props.history.push({ 'pathname': `/app/pos/initial` })
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('更改订单状态报错，请联系IT帮忙解决')
        })
    }
    useEffect(() => {
        setColumn(columnList.map((item, index) => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                width: "5%",
                render: (text, record, index) => {
                    return (
                        item == '款图' && record["序号"] !== '合计' ?
                            <img src={record.url} style={{ width: '50px' }} /> :
                            // item == '结算折扣' && discountBrand.indexOf(record["品牌"]) !== -1 && jewelryDiscount >= 3 && jewelryDiscount < 5 ?
                            //     <div>{0.9}</div> :
                            //     item == '结算折扣' && discountBrand.indexOf(record["品牌"]) !== -1 && jewelryDiscount >= 5 ?
                            //         <div>{0.8}</div> : item == '结算折扣' && record["序号"] !== '合计' ?
                            //             <div>{discount}</div> : 
                                        // text !== '' ?
                                            (item == '吊牌价' && record["序号"] !== '合计') || item == '金额' || item == '结算金额' || item == '应付金额' ?
                                                <div>{text!==''?text.toFixed(2):text}</div> : item == '编辑' && record["序号"] !== '合计' ? <a onClick={() => {
                                                    delGoods(record)
                                                }}>删除</a> : (item == '结算价' || item == '结算折扣') && record["序号"] !== '合计' ? <Paragraph style={{ margin: 0 }} onClick={()=>setUpdateFlag(false)} editable={{
                                                    onChange: (value) => {
                                                        let reg = new RegExp("^[0-9.]*$");
                                                        if (reg.test(value) && value) {
                                                            let obj = [...tableData]
                                                            obj[index][item] = parseFloat(value)
                                                            obj[index]["结算金额"] = obj[index]["结算价"] * obj[index]["数量"]
                                                            obj[index]["应付金额"] = obj[index]["结算金额"] * obj[index]["结算折扣"]
                                                            // 重新计算总计列
                                                            totalAdd(obj, setTableData)
                                                            // 恢复输入框自动获取焦点
                                                            setUpdateFlag(true)
                                                        } else {
                                                            message.error("只能输入数字")
                                                        }
                                                    }
                                                }}>{text?text.toFixed(2):text}</Paragraph> : item == '结算价' && record["序号"] == '合计'?<div>{text}</div>:
                                                    <div>{text}</div>
                    )
                }
            }
        }))
        setPrintColumn(printColumnList.map((item, index) => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                width: "5%",
                render: (text, record, index) => {
                    return (
                        // item == '结算折扣' && discountBrand.indexOf(record["品牌"]) !== -1 && jewelryDiscount >= 3 && jewelryDiscount < 5 ?
                        //     <div>{0.9}</div> :
                        //     item == '结算折扣' && discountBrand.indexOf(record["品牌"]) !== -1 && jewelryDiscount >= 5 ?
                        //         <div>{0.8}</div> : item == '结算折扣' && record["序号"] !== '合计' ?
                        //             <div>{discount}</div> : 
                                    text !== '' ?
                                        (item == '吊牌价' && record["序号"] !== '合计') || item == '金额' || item == '结算金额' || item == '应付金额' || (item == '结算价' && record["序号"] !== '合计') ?
                                            <div>{text.toFixed(2)}</div> :
                                            <div>{text}</div> : ""
                    )
                },
                onCell: function (column) {
                    return (
                        {
                            style: {
                                // width: 80,
                                // overflow: 'hidden',
                                // whiteSpace: 'nowrap',
                                // textOverflow:'ellipsis',
                                // cursor:'pointer',
                                fontSize: 5
                            },
                        }
                    )
                },
                onHeaderCell: function (column) {
                    return (
                        {
                            style: {
                                // width: 80,
                                // overflow: 'hidden',
                                // whiteSpace: 'nowrap',
                                // textOverflow:'ellipsis',
                                // cursor:'pointer',
                                fontSize: 5
                            },
                        }
                    )
                },
            }
        }))

    }, [discount, jewelryDiscount, tableData])
    useEffect(() => {
        let obj = [...tableData]
        let _discount = 0
        // 如果加入了折扣，则使用折扣
        if (discount.length !== 0) {
            _discount = discount[0]
        } else {
            _discount = 1
        }
        // 如果饰品类超过3件以上，折扣改变
        if (jewelryDiscount >= 5) {
            _discount = 0.8
        } else if (jewelryDiscount >= 3) {
            _discount = 0.9
        }
        let sumyf = 0
        // 打折，饰品折扣与固定折扣不叠加
        obj.map(item => {
            if (discountBrand.indexOf(item["品牌"]) !== -1) {
                item["应付金额"] = item["结算金额"] * _discount
                sumyf = sumyf + item["应付金额"]
                item["结算折扣"] = _discount
            } else if (item["序号"] != "合计") {
                // item["应付金额"] = item["结算金额"] * discount
                sumyf = sumyf + item["应付金额"]
            } else if (item["序号"] == "合计") {
                item["应付金额"] = sumyf
            }
        })
        setTableData(obj)
    }, [jewelryDiscount, discount])
    // 导出销售订单明细
    const downloadStandardizedModel = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            name: cookie.load('name'),
        }
        setLoading(true)
        axios({
            data: dataProps,
            method: 'POST',
            url: servicePath.downloadOrderDetailByFile,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setLoading(false)
                downExcel(res)
                setExportFlag(2)
            }
        ).catch(e => {
            setLoading(false)
            setExportFlag(3)
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }
    return (
        <div className={style.initial}>
            <Spin spinning={loading} tip="数据加载中....">
                <div style={{ marginTop: '20px', alignItems: 'center', fontSize: '30px', cursor: 'pointer', marginLeft: '50px' }} onClick={() => props.history.push({ 'pathname': `/app/pos/initial` })}>
                    <ArrowLeftOutlined />
                    返回
                </div>
                <div>
                    <div className={style.externalContent}>
                        <div className={style.mak} style={{ fontSize: '40px' }}>销售订单明细</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <span style={{ marginRight: '10px' }}>商品条形码:</span>
                                <Input value={barCode} onChange={(e) => { setBarCode(e.target.value) }}
                                    // 自动获取焦点
                                    ref={function (input) {
                                        if (input != null && updateFlag) {
                                            input.focus();
                                        }
                                    }}
                                    onPressEnter={(e) => {
                                        setGoodsType(1)
                                        goodsInfoByCode(e.target.value)

                                    }}
                                    style={{ width: '200px' }}
                                />
                            </div>

                            {discountList.map(discountItem => {
                                return <Checkbox onChange={(e) => {
                                    if (e.target.checked) {
                                        let obj = [...discount]
                                        obj.push(discountItem)
                                        setDiscount(obj)
                                        let data = [...tableData]
                                        data.map(item => {
                                            if (item["结算折扣"] == 1) {
                                                item["应付金额"] = item["结算金额"] * discountItem
                                                item["结算折扣"] = discountItem
                                            }
                                        })
                                        setTableData(data)
                                    } else {
                                        let obj = [...discount]
                                        obj.remove(discountItem)
                                        setDiscount(obj)
                                        let data = [...tableData]
                                        data.map(item => {
                                            if (item["结算折扣"] == discountItem) {
                                                item["应付金额"] = item["结算金额"]
                                                item["结算折扣"] = 1
                                            }
                                        })
                                        setTableData(data)
                                    }
                                }} style={{ fontSize: "15px" }}>{discountItem}</Checkbox>
                            })}
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Table columns={column} dataSource={tableData} size="small" pagination={false} />
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{marginTop:'20px'}}>
                                <Button onClick={() => {
                                    if(jurisdiction.indexOf(cookie.load("name")) == -1){
                                        message.error("没有权限导出")
                                    }else{
                                        setIsExportAllModel(true)
                                        setExportFlag(0)
                                    }
                                    }}
                                    type='primary'
                                    >
                                    <DownloadOutlined /> 导出销售订单明细
                                </Button>
                                {/* <Button type='primary' >导出销售订单明细</Button> */}
                            </div>
                            <div className={style.endButton}>
                                <Button type='primary' onClick={() => {
                                    if (tableData.length !== 0) {
                                        createOrder()
                                    } else {
                                        message.warning("还未录入商品")
                                    }
                                }}>生成订单</Button>
                            </div>
                        </div>
                        

                        <div>
                            {/* <svg  ref={(ref) => setBarcodeSVG(ref)}></svg> */}
                            {/* <BarCodeSVG /> */}
                        </div>
                    </div>
                </div>

            </Spin>

            <Modal
                title="支付窗口"
                visible={isOrder}
                onCancel={() => {
                    setIsOrder(false)
                }}
                // onOk={()=>{
                //     setIsOrder(false)
                // }}
                // okText="已支付"
                // cancelText="取消支付"
                centered={true}
                footer={<div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Button style={{ width: "88px" }} onClick={() => {
                        message.success("取消支付成功")
                        modifyOrderStatus("取消支付")
                    }}>取消支付</Button>
                    <Button style={{ width: "74px" }} type="primary" onClick={() => {
                        modifyOrderStatus("已支付")
                    }}>已支付</Button>
                    <Button onClick={() => {
                        // setIsOrder(false)
                        // let newstr = document.getElementById("print").innerHTML;
                        // let oldstr = document.body.innerHTML;
                        // document.body.innerHTML = newstr;
                        // window.print();
                        // document.body.innerHTML = oldstr;
                        // window.location.reload()
                        var windows = printFun(document.getElementById('print').innerHTML);
                        windows.close();
                    }}>打印</Button>
                </div>}
                width={1000}
            >
                {/* style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "120px" */}
                <div id="print">
                    <h1 style={{ textAlign: 'center' }}>
                        销售订单明细
                    </h1>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: "500px" }}>

                            <div style={{ display: 'grid', gridTemplateColumns: '70px 1fr' }}>
                                <div>
                                    交易时间:
                                </div>
                                <div>
                                    {moment().format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                                <div>
                                    订单编号:
                                </div>
                                <div>
                                    {orderCode}
                                </div>
                                <div>
                                    单位:
                                </div>
                                <div>
                                    元
                                </div>
                            </div>


                        </div>
                        <div>
                            {/* <BarCodeSVG /> */}
                            <Barcode value={orderCode} height={50} width={2} />
                        </div>

                    </div>

                    {/* <div style={{ fontSize: '30px' }}>总金额:{total}</div> */}
                    <Table columns={printColumn} dataSource={tableData} size="small" pagination={false} />
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: "180px" }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '70px 1fr' }}>
                                <div>
                                    原价金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {tableData.length !== 0 ? tableData[tableData.length - 1]["金额"].toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    应付金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {tableData.length !== 0 ? tableData[tableData.length - 1]["应付金额"].toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    已付金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {tableData.length !== 0 ? tableData[tableData.length - 1]["应付金额"].toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    折扣金额:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {tableData.length !== 0 ? (tableData[tableData.length - 1]["金额"] - tableData[tableData.length - 1]["应付金额"]).toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    综合折扣:
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {tableData.length !== 0 ? (tableData[tableData.length - 1]["应付金额"] / tableData[tableData.length - 1]["金额"] * 100).toFixed(2) + "%" : ""}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '70px 1fr', height: '88px', marginLeft: '120px' }}>
                                <div>
                                    数量合计:
                                </div>
                                <div>
                                    {tableData.length !== 0 ? tableData[tableData.length - 1]["数量"] + "件" : ""}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        如需开票请持有此单在收银柜台登记，我司将以邮件形式将电子发票信息发送至您的邮箱！
                    </div>
                    <div>
                        =============================================================================================================
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <h3>礼品兑换券</h3>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: "300px", height: '130px' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '90px 1fr' }}>
                                <div>
                                    订单编号:
                                </div>
                                <div>
                                    {orderCode}
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    订单条形码:
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <Barcode value={orderCode} height={50} width={2} />
                                </div>
                                <div>
                                    已付金额:
                                </div>
                                <div>
                                    {tableData.length !== 0 ? tableData[tableData.length - 1]["应付金额"].toFixed(2) + "元" : ""}
                                </div>
                                <div>
                                    交易时间:
                                </div>
                                <div>
                                    {moment().format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                            </div>
                        </div>
                        {/* <div style={{maxHeight: "calc(100% - 10px)",display:'flex',justifyContent:'right'}}>
                            <div >
                                顾客签字:
                            </div>
                        </div> */}
                        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                            <div style={{ height: '130px' }}>
                            </div>
                            <div style={{ marginRight: "150px" }}>
                                顾客签字:
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
            <Modal
                title="商品详情"
                visible={isGoodsDetial}
                onCancel={() => {
                    setIsGoodsDetial(false)
                }}
                onOk={() => {
                    setIsGoodsDetial(false)
                }}
                okText="确认"
                cancelText="取消"
                centered={true} 
                width={800}
            >
                <div style={{ display: 'grid', gridTemplateColumns: "30% 1fr" }}>
                    {/* <Image src='https://pic.bmcsoft.cn/it/JORYA/656728153951.jpg' style={{ width: "105px", height: "105px" }}/> */}
                    <img src={detailData.url} style={{ width: '150px', height: '150px' }} />
                    <div>
                        {tPrice && <div style={{ display: 'flex', justifyContent: 'right', marginBottom: "10px" }}>
                            <Radio.Group onChange={(e) => {
                                setGoodsType(e.target.value)
                                let obj = [...tableData]
                                let flag = true
                                let discount = 1
                                // 如果是正品则进入正品判断
                                if (e.target.value == 1) {
                                    detailData["商品等级标签"] = 1
                                    obj.map(item => {
                                        // 如果是相同产品，并且结算价一致（数量+1）
                                        if (item["商家编码"] == detailData["商家编码"] && item["结算价"] == tPrice) {
                                            item["数量"] = item["数量"] + 1
                                            // 如果是饰品，则判断是否使用饰品折扣
                                            if(discountBrand.indexOf(item["品牌"]) !== -1 && item["数量"]>=3){
                                                if(item["数量"]>=5){
                                                    discount = 0.8
                                                }else{
                                                    discount = 0.9
                                                }
                                            }else{
                                                discount = item["结算折扣"]
                                            }
                                            flag = false
                                            item["结算金额"] = item["结算价"] * item["数量"]
                                            item["金额"] = item["吊牌价"] * item["数量"]
                                            if (item["结算折扣"]) {
                                                item["应付金额"] = item["结算金额"] * item["结算折扣"]
                                            } else {
                                                item["应付金额"] = item["结算金额"]
                                            }
                                        }else if(item["商家编码"] == detailData["商家编码"] && item["结算价"] == fPrice){         // 如果有相同产品，且结算价等于次品的，数量-1
                                            discount = item["结算折扣"]
                                            // 如果数量只有1，则整条删除
                                            if(item["数量"] == 1){
                                                obj.remove(item)
                                            }else{
                                                item["数量"] = item["数量"] -1
                                                item["金额"] = item["吊牌价"] * item["数量"]
                                                item["结算金额"] = item["结算价"] * item["数量"]
                                                if (item["结算折扣"]) {
                                                    item["应付金额"] = item["结算金额"] * item["结算折扣"]
                                                } else {
                                                    item["应付金额"] = item["结算金额"]
                                                }
                                            }
                                        }
                                    })
                                    // 如果找不到相同的产品，则重新生成一列
                                    if(flag){
                                        detailData["数量"] = 1
                                        detailData["结算价"] = tPrice
                                        detailData["结算折扣"] = discount
                                        detailData["结算金额"] = detailData["结算价"]
                                        detailData["应付金额"] = detailData["结算金额"] * detailData["结算折扣"]
                                        detailData["金额"] = detailData["吊牌价"]
                                        // 如果最后一列是总计，则删除
                                        if (obj[obj.length - 1]) {
                                            if (obj[obj.length - 1]["序号"] == "合计") {
                                                obj.pop()
                                            }
                                        }
                                        obj.push(detailData)
                                        // 重新生成序号
                                        obj.map((item, index) => {
                                            item["序号"] = index + 1
                                        })
                                    }
                                } else {
                                    detailData["商品等级标签"] = 2
                                    // 如果是次品则使用次品价
                                    obj.map(item => {
                                        if (item["商家编码"] == detailData["商家编码"] && item["结算价"] == fPrice) {
                                            // discount = item["结算折扣"]
                                            item["数量"] = item["数量"] + 1
                                            if(discountBrand.indexOf(item["品牌"]) !== -1 && item["数量"]>=3){
                                                if(item["数量"]>=5){
                                                    discount = 0.8
                                                }else{
                                                    discount = 0.9
                                                }
                                            }else{
                                                discount = item["结算折扣"]
                                            }
                                            flag = false
                                            item["结算金额"] = item["结算价"] * item["数量"]
                                            item["金额"] = item["吊牌价"] * item["数量"]
                                            if (item["结算折扣"]) {
                                                item["应付金额"] = item["结算金额"] * item["结算折扣"]
                                            } else {
                                                item["应付金额"] = item["结算金额"]
                                            }
                                        }else if(item["商家编码"] == detailData["商家编码"] && item["结算价"] == tPrice){
                                            discount = item["结算折扣"]
                                            if(item["数量"] == 1){
                                                obj.remove(item)
                                            }else{
                                                item["数量"] = item["数量"] -1
                                                item["金额"] = item["吊牌价"] * item["数量"]
                                                item["结算金额"] = item["结算价"] * item["数量"]
                                                if (item["结算折扣"]) {
                                                    item["应付金额"] = item["结算金额"] * item["结算折扣"]
                                                } else {
                                                    item["应付金额"] = item["结算金额"]
                                                }
                                            }
                                            
                                            
                                        }
                                    })
                                    if(flag){
                                        detailData["数量"] = 1
                                        detailData["结算价"] = fPrice
                                        detailData["结算折扣"] = discount
                                        detailData["结算金额"] = detailData["结算价"]
                                        detailData["应付金额"] = detailData["结算金额"] * detailData["结算折扣"]
                                        detailData["金额"] = detailData["吊牌价"]
                                        // 如果最后一列是总计，则删除
                                        if (obj[obj.length - 1]) {
                                            if (obj[obj.length - 1]["序号"] == "合计") {
                                                obj.pop()
                                            }
                                        }
                                        obj.push(detailData)
                                        // 重新生成序号
                                        obj.map((item, index) => {
                                            item["序号"] = index + 1
                                        })
                                    }
                                }
                                // 重新计算总计列
                                totalAdd(obj, setTableData)
                            }} value={goodsType}>
                                <Radio value={1}>正品价:{tPrice}</Radio>
                                <Radio value={2}>次品价:{fPrice}</Radio>
                            </Radio.Group>
                        </div>}
                        <div style={{ display: 'grid', gridTemplateColumns: "50% 1fr", fontSize: '15px' }}>
                            {detailList.map(item => {
                                return <div style={{ display: 'grid', gridTemplateColumns: "30% 1fr" }}>
                                    <div style={{ textAlign: 'right' }}>
                                        {item}:
                                    </div>
                                    <div style={{ textAlign: 'left', marginLeft: "10px" }}>
                                        {detailData[item]}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>

                </div>
            </Modal>
            <Modal
                title="录入商品"
                visible={isGoodsDetialInsert}
                onCancel={() => {
                    setIsGoodsDetialInsert(false)
                    setUpdateFlag(true)
                }}
                onOk={() => {
                    let flag = false
                    detailInsertList.map(item=>{
                        if(!goodsInfo[item]){
                            flag = true
                        }
                    })
                    if(flag){
                        message.warning("请将商品信息填写完整")
                    }else{
                        let obj = Object.assign({}, goodsInfo);
                        if(discountBrand.indexOf(obj["品牌"]) !== -1){
                            let jwcount = jewelryDiscount
                            jwcount = jwcount + obj["数量"]
                            setJewelryDiscount(jwcount)
                            obj["结算折扣"] = jwcount >=5? 0.8 : jwcount>=3 ? 0.9 : discount.length !== 0? discount[0]: 1
                        }else{
                            obj["结算折扣"] = discount.length !== 0 ? discount[0] : 1
                        }
                        obj["结算金额"] = obj["结算价"] * obj["数量"]
                        obj["金额"] = obj["吊牌价"] * obj["数量"]
                        obj["应付金额"] = obj["结算金额"] * obj["结算折扣"]
                        let data = [...tableData]
                        // 如果最后一列是总计，则删除
                        if (data[data.length - 1]) {
                            if (data[data.length - 1]["序号"] == "合计") {
                                data.pop()
                            }
                        }
                        data.push(obj)
                        // 添加序号
                        data.map((item, index) => {
                            item["序号"] = index + 1
                        })
                        totalAdd(data,setTableData)
                        setIsGoodsDetialInsert(false)
                        setUpdateFlag(true)
                    }
                    
                }}
                okText="确认"
                cancelText="取消"
                centered={true} 
                width={800}
            >
                {/* <Image src='https://pic.bmcsoft.cn/it/JORYA/656728153951.jpg' style={{ width: "105px", height: "105px" }}/> */}
                {/* <img src={detailData.url} style={{ width: '150px', height: '150px' }} /> */}
                <div>
                    <h3 style={{textAlign:'center'}}>请再次确认查无商品,需要自己录入</h3>
                    <div style={{ display: 'grid', gridTemplateColumns: "50% 1fr", fontSize: '15px' }}>
                        {detailInsertList.map(item => {
                            return <div style={{ display: 'grid', gridTemplateColumns: "30% 1fr" }}>
                                <div style={{ textAlign: 'right',marginTop:'23px' }}>
                                    {item}:
                                </div>
                                <div style={{ textAlign: 'left', marginLeft: "10px",marginTop:'20px' }}>
                                    <Input value={goodsInfo[item]} onChange={(e)=>{
                                        let obj = Object.assign({}, goodsInfo);
                                        if(item == "数量" || item == "吊牌价" || item == "结算价"){
                                            let reg = new RegExp("^[0-9.]*$");
                                            if (reg.test(e.target.value)) {
                                                obj[item] = parseFloat(e.target.value)
                                            } else {
                                                message.error("只能输入数字")
                                            }
                                        }else{
                                            obj[item] = e.target.value
                                        }
                                        setGoodsInfo(obj)
                                        
                                    }} disabled={item == "商家编码"?true:false}/>
                                </div>
                            </div>
                        })}
                    </div>
                </div>

            </Modal>
            <Modal
                    title="导出订单销售明细"
                    visible={isExportAllModel}
                    centered={true}
                    onCancel={() => {
                        if(exportFlag == 1){
                            message.warning("导出中,请勿操作")
                        }else{
                            setIsExportAllModel(false)
                        }
                    }}
                    onOk={() => {
                        if(cookie.load("name"))
                        if(exportFlag == 1){
                            message.warning("导出中,请勿操作")
                        }else if (exportFlag == 2) {
                            setIsExportAllModel(false)
                        }else {
                            const getAllData = async () => {
                                await Promise.all([
                                    downloadStandardizedModel(),
                                    setExportFlag(1)
                                ])
                            }
                            getAllData()
                        }
                    }}
                    okText="确认"
                    cancelText="取消"
                >
                    {/* <Spin style={{ display: tabKey == 'productList' ? '' : 'none' }} spinning={loading} tip="数据加载中...."> */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <ExclamationCircleOutlined style={{color:"#a3986d",width:'30px'}}/> */}
                            {exportFlag == 0 ? <Progress type="circle" percent={0} /> : exportFlag == 1 ? <Spin size="large" /> : exportFlag == 2 ? <Progress type="circle" percent={100} /> : <Progress type="circle" percent={70} status="exception" />}
                        </div>
                        <div style={{ marginTop: "30px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {exportFlag == 0 ? <b>确认导出所有销售订单明细吗？</b> : exportFlag == 1 ? <b>导出中</b> : exportFlag == 2 ? <b>导出完成</b> : <b>导出失败</b>}
                        </div>
                    {/* </Spin> */}
            </Modal>
        </div>
    )
}

export default Create