import React, { useState, useEffect } from 'react';
import style from './index.module.css';
import {Button, Input, Typography, Tag, Popover, Pagination, message } from 'antd'
import {  EyeOutlined, TagOutlined } from '@ant-design/icons'
import servicePath from '../../../config/apiUrl';
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios';
const {Text, Title} = Typography

function Index(props){
    // 标签展示框
    const labelContent =(data)=> {
       return <div style={{width:"240px", display:'flex', padding:"5px", flexWrap:'wrap'}}>
         {
             data.map(item=>{
                return <Tag color="blue" style={{marginTop:"2px"}}>{item}</Tag>
            })
         }
       </div>
    }
    // 关键字
    const [ keywords, setKeywords] = useState(props.location.state.keywords)

    // 数据
    const [ date , setDate] = useState([])

    // 页数
    const [current, setCurrent] = useState(1);
    // 总数
    const [ total , setTotal ] = useState(50) 
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 最新报告
    const [ hotReport , setHotReport ] = useState([])

    useEffect(()=>{
        searchTabKnoledge()
    },[])

    useEffect(()=>{
        searchByKeywordsKnoledge()
    },[current])

    // 根据关键词查询
    const searchByKeywordsKnoledge = ()=>{
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        values['keywords'] = keywords
        values['page'] = current
        setLoading(true)
        NbAxios(values, "POST", servicePath.searchByKeywordsKnoledge).then(res => {
            setLoading(false)
            if (res.success) {
                setDate(res.data)
                setTotal(res.total);
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('根据关键词查询报告报错，请联系IT帮忙解决')
        })
    }

    // 获取最新、最热报告
    const searchTabKnoledge = ()=>{
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        setLoading(true)
        NbAxios(values, "POST", servicePath.searchTabKnoledge).then(res => {
            setLoading(false)
            if (res.success) {
                setHotReport(res.data.hot)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('获取最新、最热报告报错，请联系IT帮忙解决')
        })
    }

    const recordAccessesKnoledge = (report_id)=>{
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        values["user_name"] = cookie.load('name');
        values["user_id"] = cookie.load('id');
        values["report_id"] = report_id
        NbAxios(values, "POST", servicePath.recordAccessesKnoledge)
    }


    return (
        <div style={{ backgroundColor: '#F5F5F5', height: "calc(100vh - 40px)", overflow: 'auto' }}>
            <div className={style.externalContent}>
                <div style={{display:'grid', gridTemplateColumns:"60% 40%"}}>
                    <div>
                        <div style={{marginTop:"30px", display:'flex'}}>
                            <div style={{width:"900px", border:"1px solid #ccc", display:"flex", padding:"10px"}}>
                                <Input style={{width:"800px"}} bordered={false} placeholder={"请输入内容关键字，以空格拼接多个关键字"} value={keywords} onChange={(e)=>{
                                    setKeywords(e.target.value)
                                }}  onKeyDown={(e)=>{
                                    if(e.keyCode == 13){
                                        searchByKeywordsKnoledge()
                                    }
                                }}/>
                                <Button type="primary" style={{width:"80px"}} onClick={()=>searchByKeywordsKnoledge()} disabled={loading}>搜索</Button>
                            </div>
                        </div>
                        <div style={{width:"900px", border:"1px dashed #ccc",height:"850px", marginTop:'10px'}}>
                            <div style={{height:"800px", overflow:'auto'}}>
                                {
                                    date.map(item=>{
                                        return <div style={{fontSize:"18px", backgroundColor:'#F7F7F7', marginBottom:"10px", display:'flex', padding:"10px", alignItems:"center"}}>
                                            <a onClick={()=>recordAccessesKnoledge(item.report_id)} style={{ fontWeight:'bolder', width:"400px"}} href={`https://pic.bmcsoft.cn/BMC知识库/`+item.filepath} target="_blank">{item.filename}</a>
                                            <Text style={{marginLeft:"20px", width:'90px' }} type="secondary">{item.creator}</Text>
                                            <Text style={{marginLeft:"20px", width:'110px'}} type="secondary">{item.create_time}</Text>
                                            <div style={{display:'flex',marginLeft:"60px", alignItems:"center", width:'70px'}}>
                                                <EyeOutlined />
                                                <Text type="secondary" style={{marginLeft:"5px"}}>{item.count_view}</Text>
                                            </div>
                                            <div style={{marginLeft:"60px"}}>
                                                <Popover placement="bottom" content={labelContent(item.content_label)}><a ><TagOutlined /></a> </Popover>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <Pagination current={current} onChange={(page) => {
                                    setCurrent(page);
                                }} total={total} />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft:"100px"}}>
                        <div style={{marginTop:'95px',  height:'850px', width:'300px', border:'1px dashed #ccc',padding:"10px" }}>
                            <div>
                                <Title level={3}>最热报告</Title>
                            </div>
                            {
                                hotReport.map(item=>{
                                    return <div>
                                        <a onClick={()=>recordAccessesKnoledge(item.report_id)} style={{marginTop:"10px", fontSize:"13px"}} href={`https://pic.bmcsoft.cn/BMC知识库/`+item.filepath} target="_blank">{item.filename}</a>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )

}


export default Index