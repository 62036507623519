import React, { useContext, useState, useEffect } from 'react';
import { StateContext } from '../../raceShop/store/stateShare'
import { Button, Card, DatePicker, message } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import servicePath from '../../../config/apiUrl'

function JrReport(props) {

    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)

    // 获取屏幕尺寸
    const size = state.size

    
    // 竞店数据日期
    const [raceShopDate, setRaceShopDate] = useState()

    // 查看状态管理中是否有用户选择的时间数据
    useEffect(() => {
        if (state.raceShopDate) {
            setRaceShopDate(state.raceShopDate)
            message.success('已为您选择跳转前的数据时间')
        }
    }, [])

    // 店铺名
    let shop = props.location.pathname.split('/')[3]

    // 下载竞店数据的方法
    function reportRaceShop() {
        if (raceShopDate === null || raceShopDate === undefined || raceShopDate === '') {
            message.warn('请选择时间')
            return false
        } else {
            let date = raceShopDate.format('YYYY-MM-DD')
            window.open(servicePath.getRaceShopReport + "?shop=" + shop + "&date=" + date, '_blank')
        }
    }

    // 日期变化调用的方法
    function changeDate(date, dateString) {
        if (date !== null || date !== '') {
            setRaceShopDate(date)
        }
    }

    return (
        <div style={{ marginTop: size.height * 0.03, marginLeft: size.width * 0.04, paddingBottom: size.height * 0.1 }}>
            <Card style={{ width: 400 }} title='jorya竞店数据下载' >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <DatePicker
                        value={raceShopDate}
                        locale={locale}
                        disabledDate={disabledDate}
                        onChange={changeDate}
                    />
                    <Button type={'primary'} onClick={reportRaceShop}>
                        下载
                    </Button>
                </div>
            </Card>
        </div>
    )
    // 不可选的周
    function disabledDate(current) {
        // 不可选前一周
        return current && current > moment().endOf('day').subtract(1, 'day');
    }

}

export default JrReport