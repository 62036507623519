import React, { useEffect, useState } from 'react';
import { Table, Typography, Tooltip, Popover, Button, Input, Checkbox, Drawer, Tabs, Select, InputNumber, message, Modal, Image } from 'antd'
import { Prompt } from 'react-router-dom'
import NbAxios from '../../../config/utils/nbAxios';
import Axios from 'axios'
import WSUrl from '../../../config/wsUrl'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl';
import calculatePrice from '../method/priceCalculation'
import calculateRelationPartitionPrice from '../method/calculateRelationPartitionPrice'
import { PlusCircleOutlined, MinusCircleOutlined, SearchOutlined, CheckSquareOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CommodityImage from '../../dataMart/component/commodityImage';
import FormBenefit from '../compontent/formalBenefit/index'
import { Model } from 'echarts';
const { Text, Link, Paragraph } = Typography
const { TabPane } = Tabs
const { Option } = Select
function Index(props) {
    // 获取当前时间
    function getNowTime() {
        let dateTime
        let yy = new Date().getFullYear()
        let mm = new Date().getMonth() + 1
        let dd = new Date().getDate()
        let hh = new Date().getHours()
        let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes()
          :
          new Date().getMinutes()
        let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds()
          :
          new Date().getSeconds()
        dateTime = hh + ':' + mf + ':' + ss
        console.log(dateTime)
        return dateTime
    }
    function toPercent(point){
        var str=Number(point*100).toFixed(0);
        str+="%";
        return str;
    }
    let ws = new WebSocket(WSUrl)
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    // 离开页面，刷新页面的时候，强制提醒
    function beforeunload(e) {
        let confirmationMessage = '你确定离开此页面吗?';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }
    useEffect(() => {
        return () => {
            window.removeEventListener("beforeunload", beforeunload)
            // 关闭websocket
            ws.close()
        }
    }, [])
    useEffect(() => {
        // 拦截判断是否离开当前页面
        window.addEventListener('beforeunload', beforeunload);
    }, [])





    // 参加价格内容
    /**
     * 
     * @param {*} priceList 每个分区的参加价格列表
     * @param {*} columnsIndex 分区名字(比如: '4件7折')
     * @param {*} dataIndex 数据的行数
     * @returns 
     */
    const chosePriceListContent = (priceList, columnsIndex, dataIndex) => {
        return <div style={{ display: 'flex', width: 500, justifyContent: 'space-around' }}>
            {
                priceList.map((item, index) => {
                    // border:"1px solid #ccc",
                    return <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        // border:isLookIndex == index ? '1px solid #ccc' : '',
                        // border:"1px solid red",
                        border: data.length != 0 ? data.length > dataIndex ? data[dataIndex]['is_delete'] == 0 ? item.sign == 2 ? '3px solid #ff3131' : item.sign == 1 ? '3px solid #188fff' : '' : '' : '' : '',
                        paddingLeft: 5,
                        paddingTop: 5,
                        paddingBottom: 5,
                        width: `calc(500px/${priceList.length})`, fontSize: 12
                    }}>
                        <div style={{ textAlign: 'center', display: columnsIndex == '不参与多件' ? 'flex' : 'none' }}>
                            {
                                index != priceList.length - 1 ? <Text style={{ fontSize: 14, fontWeight: 700 }}>{"单件折扣"}:</Text>
                                    : <Link onClick={() => {
                                        console.log(dataIndex);
                                        console.log(data[dataIndex]);
                                        setColumnsIndex(columnsIndex)
                                        setDataIndex(dataIndex)
                                        setIndex(index)
                                        setIsChangeSinglePieceDiscount(true)
                                    }} style={{ fontSize: 14, fontWeight: 700, textDecoration: "underline" }}>{"单件折扣"}:</Link>
                            }
                            <Text style={{ fontSize: 14, marginLeft: 10, fontWeight: 500 }}>{data.length != 0 ? data.length > dataIndex ? data[dataIndex]['is_use_platform_coupon'] ?  toPercent(item.value['使用平台购物券单件折扣']): toPercent(item.value['单件折扣']) : '' : ''}</Text>
                        </div>
                        <div style={{ textAlign: 'center', display: 'flex' }}>
                            {
                                index != priceList.length - 1 ? <Text style={{ fontSize: 14, fontWeight: 700 }}>{item.title[item.title.length - 1]}:</Text>
                                    : <Link onClick={() => {
                                        setColumnsIndex(columnsIndex)
                                        setDataIndex(dataIndex)
                                        setIndex(index)
                                        setIsChangeCommodityVoucher(true)
                                    }} style={{ fontSize: 14, fontWeight: 700, textDecoration: "underline" }}>{item.title[item.title.length - 1]}:</Link>
                            }
                            <Text style={{ fontSize: 14, marginLeft: 10, fontWeight: 500 }}>{data.length != 0 ? data.length > dataIndex ? data[dataIndex]['is_use_platform_coupon'] ? item.value['使用平台购物券商品券凑10'] : item.value['商品券凑10'] : '' : ''}</Text>
                        </div>
                        {
                            item.title.map((titleItem, titleIndex) => {
                                return titleIndex != item.title.length - 1 ?
                                    <div>
                                        {
                                            titleItem == '活动价' ?
                                                // <Popover placement={'bottom'} content={activityPriceContent(index, columnsIndex, dataIndex)} title="根据活动价计算到手价">
                                                //     <Link>{titleItem}:</Link>
                                                // </Popover>
                                                <Link style={{ textDecoration: "underline" }} onClick={() => {
                                                    // let obj = [...data]
                                                    setTagPrice(data[dataIndex]['basicInformation']['确认吊牌价']);
                                                    setColumnsIndex(columnsIndex)
                                                    setDataIndex(dataIndex)
                                                    setIndex(index)
                                                    setIsChangeActivityValue(true)
                                                }}>{titleItem}:</Link>
                                                : titleItem == '到手价' ?
                                                    // <Popover placement={'bottom'} content={atHandContent(index, columnsIndex, dataIndex)} title="根据到手价计算活动价">
                                                    //     <Link>{titleItem}:</Link>
                                                    // </Popover>
                                                    <Link style={{ textDecoration: "underline" }} onClick={() => {
                                                        // let obj = [...data]
                                                        cookie.save('datacache', data[dataIndex][columnsIndex]['priceList'][index], { path: '/' })
                                                        // setDataCache(data[dataIndex])
                                                        setTagPrice(data[dataIndex]['basicInformation']['确认吊牌价']);
                                                        setColumnsIndex(columnsIndex)
                                                        setDataIndex(dataIndex)
                                                        setIndex(index)
                                                        setIsChangeHandValue(true)
                                                    }}>{titleItem}:</Link>
                                                :  <Text type="secondary" style={{display: titleItem == '单件折扣' ? 'none' : ''}}>{titleItem}:</Text>
                                        }
                                        {/* <Text style={{ marginLeft: 5 }}>{item.value[titleItem]}</Text> */}
                                        {
                                            <Text style={{ marginLeft: 5, display: titleItem == '单件折扣' ? 'none' : ''  }}>{data.length != 0 ? data.length > dataIndex ? data[dataIndex]['is_use_platform_coupon'] ? item.value['使用平台购物券' + titleItem] : item.value[titleItem] : '' : ''}</Text>
                                        }
                                    </div>
                                    : <span></span>
                            })
                        }
                        <div style={{ display: 'flex', justifyContent: "center", marginTop: 5 }}>
                            <Button disabled={effective ? true : false} style={{ borderRadius: 25, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} onClick={() => {
                                // 校验websocket是否要连接
                                if (ws.readyState !== 0) {
                                    let result = {}
                                    // console.log(columnsIndex);
                                    // console.log(data[dataIndex][columnsIndex]);
                                    // console.log(index);
                                    // console.log(data[dataIndex][columnsIndex]['priceList'][index]);
                                    /**
                                     * 
                                     * 根据选择的区间、价格下标中的商品券
                                     * 
                                     * 使用了店铺券的时候
                                     * 商品券不为0的时候，店铺券的标志位修改为0，商品券的标志位修改为1
                                     * 商品券为0的时候，店铺券的标志位修改为1，商品券的标志位修改为0
                                     * 
                                     * 不使用店铺的时候
                                     * 商品券不为0的时候，商品券的标志位修改为1
                                     * 商品券为0的时候，商品券的标志位修改为0
                                     */
                                    // 判断是否有使用店铺券
                                    if(hasShopCoupon == 1){
                                        // 使用了店铺券
                                        // 判断是否使用平台购物券
                                        if(data[dataIndex]['is_use_platform_coupon']){
                                            // 要是使用平台购物券的时候
                                            if(data[dataIndex][columnsIndex]['priceList'][index]['value']['使用平台购物券商品券凑10'] != 0){
                                                // 商品券不为0的时候
                                                data[dataIndex]['is_use_shop_coupon'] = 0
                                                data[dataIndex]['is_use_goods_coupon'] = 1
                                            }else{
                                                // 商品券为0的时候
                                                data[dataIndex]['is_use_shop_coupon'] = 1
                                                data[dataIndex]['is_use_goods_coupon'] = 0
                                            }
                                        }else{
                                            // 要是不使用平台购物券的时候
                                            if(data[dataIndex][columnsIndex]['priceList'][index]['value']['商品券凑10'] != 0){
                                                // 商品券不为0的时候
                                                data[dataIndex]['is_use_shop_coupon'] = 0
                                                data[dataIndex]['is_use_goods_coupon'] = 1
                                            }else{
                                                // 商品券为0的时候
                                                data[dataIndex]['is_use_shop_coupon'] = 1
                                                data[dataIndex]['is_use_goods_coupon'] = 0
                                            }
                                        }
                                    }else{
                                        // 不使用店铺券
                                        // 判断是否使用平台购物券
                                        if(data[dataIndex]['is_use_platform_coupon']){
                                            // 要是使用平台购物券的时候
                                            if(data[dataIndex][columnsIndex]['priceList'][index]['value']['使用平台购物券商品券凑10'] != 0){
                                                // 商品券不为0的时候
                                                data[dataIndex]['is_use_goods_coupon'] = 1
                                            }else{
                                                // 商品券为0的时候
                                                data[dataIndex]['is_use_goods_coupon'] = 0
                                            }
                                        }else{
                                            // 要是不使用平台购物券的时候
                                            if(data[dataIndex][columnsIndex]['priceList'][index]['value']['商品券凑10'] != 0){
                                                // 商品券不为0的时候
                                                data[dataIndex]['is_use_goods_coupon'] = 1
                                            }else{
                                                // 商品券为0的时候
                                                data[dataIndex]['is_use_goods_coupon'] = 0
                                            }
                                        }
                                    }
                                   
                                    if (columnsIndex != '不参与多件') {
                                        console.log(data[dataIndex], columnsIndex, index);
                                        result = calculateRelationPartitionPrice(data[dataIndex], columnsIndex, index)
                                    } else {
                                        result = data[dataIndex]
                                    }
                                    let key = "update_price"
                                    let changeLog =  ""
                                    if(cookie.load('changeLog') == ''){
                                        let spu = data[dataIndex]['spu']
                                        let goods_id = data[dataIndex]['goods_id']
                                        changeLog =   `操作spu:${spu} - id:${goods_id}这款商品，选择了${columnsIndex}区间的第${index+1}个参加价格`
                                        changeLog =  cookie.load('changeLog') + `选择了${columnsIndex}区间的第${index+1}个参加价格`
                                    }
                                    let dataProps = {
                                        'url': 'update_price',
                                        'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                        'name': selectionPlanName,
                                        'data_dict': result,
                                        'special_area': columnsIndex,
                                        'special_area_index': index,
                                        'change_log': changeLog,
                                        'table_head': specialArea,
                                        'creator':cookie.load('name')
                                    }
                                    console.log(dataProps);
                                    setEffective(1)
                                    message.loading({ content: '正在修改定价方法...', key, duration: 100 })
                                    // 调用后台接口
                                    ws.send(JSON.stringify(dataProps))
                                    ws.onmessage = e => {
                                        setEffective(0)
                                        let mes = JSON.parse(e.data)
                                        console.log(mes);
                                        if (mes['success']) {
                                            // console.log(mes['data']);
                                            let dataObj = [...data]
                                            dataObj[dataIndex] = mes['data']
                                            setData(dataObj)
                                            message.success({ content: '修改定价方法成功...', key })
                                            setDoubleClickContent('')
                                        } else {
                                            message.warn({ content: mes['errorMsg'], key })
                                        }
                                    }
                                }

                            }}>确定</Button>
                        </div>
                    </div>
                })
            }
        </div>
    }
    // 活动名字
    const [selectionPlanName, setSelectionPlanName] = useState('')
    // 产品线集合
    const [productLine, setProductLine] = useState([])
    // 季节集合
    const [seasonList, setSeasonList] = useState([])
    // 年份集合
    const [yearList, setYearList] = useState([])
    // 品类集合
    const [ categoryList , setCategoryList ] = useState([])
    const [dataLable, setDataLable] = useState([
        {
            'name': '爆款',
            'id': 1
        }, {
            'name': '销售TOP30',
            'id': 2
        }, {
            'name': '销售TOP50',
            'id': 3
        }, {
            'name': '销售TOP100',
            'id': 4
        }
    ])
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 默认专区
    const [defaultZone, setDefaultZone] = useState([])
    const [choseDefaultZone, setChoseDefaultZone] = useState('')
    // 是否选择88会员
    const [isChoseMember, setIsChoseMember] = useState(0)
    // 是否选择未确认
    const [isConfirmed, setIsConfirmed] = useState(0)
    // 是否选择参与活动
    const [isParticipate, setIsParticipate] = useState(0)
    // 是否选择已移除商品
    const [isChooseDelete, setIsChooseDelete] = useState(0)
    // 是否要移除该商品
    const [isDelete, setIsDelete] = useState(0)

    // 路由跳转的时候，强制提醒
    const [isShlef, setIsShlef] = useState(true)
    // 限时活动绑定专区
    const [limitedTimeDiscount, setLimitedTimeDiscount] = useState('')
    // 限时专区名字
    const [limitedTimeName, setLimitedTimeName] = useState('')
    // 全部数据
    const [allData, setAllData] = useState([])
    const [data, setData] = useState([])
    // Tab的键
    const [tabKey, setTabKey] = useState("设置选款范围")
    // 鼠标移入数据
    const [mouseEnterValue, setMouseEnterValue] = useState('')
    // 选款JSON
    const [selectionObj, setSelectionObj] = useState([{
        'productLine': '',
        'year': '',
        'season': '',
        'secondary_category':'',
        'label': '',
        'maxStock': '',
        'minStock': '',
    }])
    // 专区分类
    const [specialArea, setSpecialArea] = useState([])

    // 到手价JSON
    const [atHandJson, setAtHandJson] = useState([
        {
            'data': {
                "value": {
                    "活动价": '',
                    "使用平台购物券活动价": '',
                    "到手价": '',
                    '使用平台购物券到手价': ''
                },
            },
            'check': 0
        },
        {
            'data': {
                "value": {
                    "活动价": '',
                    "使用平台购物券活动价": '',
                    "到手价": '',
                    '使用平台购物券到手价': ''
                },
            },
        },
        {
            'data': {
                "value": {
                    "活动价": '',
                    "使用平台购物券活动价": '',
                    "到手价": '',
                    '使用平台购物券到手价': ''
                },
            },
            'check': 0
        }
    ])
    // 活动价JSON
    const [activityJson, setActivityJson] = useState({
        "value": {
            "活动价": '',
            "使用平台购物券活动价": '',
            "到手价": '',
            "使用平台购物券到手价": '',
        },
    })
    // 商品券
    const [commodityVoucherJson, setCommodityVoucherJson] = useState({
        "value": {
            "活动价": '',
            "使用平台购物券活动价": '',
            "到手价": '',
            "使用平台购物券到手价": '',
            "到手折扣": '',
            "使用平台购物券到手折扣": '',
            "到手价-底线价": '',
            "使用平台购物券到手价-底线价": '',
            "极限到手价": '',
            "使用平台购物券极限到手价": '',
            "极限到手折扣": '',
            "使用平台购物券极限到手折扣": '',
            "使用平台购物券极限到手价-底线价": '',
            "极限到手价-底线价": '',
        },
    })

    // 单件折扣
    const [singlePieceDiscount, setSinglePieceDiscountJson] = useState({
        "value": {
            "活动价": '',
            "使用平台购物券活动价": '',
            "到手价": '',
            "使用平台购物券到手价": '',
            "到手折扣": '',
            "使用平台购物券到手折扣": '',
            "到手价-底线价": '',
            "使用平台购物券到手价-底线价": '',
            "极限到手价": '',
            "使用平台购物券极限到手价": '',
            "极限到手折扣": '',
            "使用平台购物券极限到手折扣": '',
            "使用平台购物券极限到手价-底线价": '',
            "极限到手价-底线价": '',
        },
    })
    // 修改到手价
    const [changeHandValue, setChangeHandValue] = useState('')
    // 修改活动价
    const [changeActivityValue, setChangeActivityValue] = useState('')
    // 修改商品券
    const [changeCommodityVoucher, setChangeCommodityVoucher] = useState('')
    // 修改单件折扣
    const [changeSinglePieceDiscount, setChangeSinglePieceDiscount] = useState('')

    // 是否修改到手价
    const [isChangeHandValue, setIsChangeHandValue] = useState(false)
    // 是否修改活动价
    const [isChangeActivityValue, setIsChangeActivityValue] = useState(false)
    // 是否修改商品券
    const [isChangeCommodityVoucher, setIsChangeCommodityVoucher] = useState(false)
    // 是否修改单款折扣
    const [isChangeSinglePieceDiscount, setIsChangeSinglePieceDiscount] = useState(false)
    // 是否批量修改单款折扣
    const [isBatchChangeSinglePieceDiscount, setIsBatchChangeSinglePieceDiscount] = useState(false)
    // 修改活动价、到手价的基础数据
    const [columnsIndex, setColumnsIndex] = useState('')
    const [dataIndex, setDataIndex] = useState('')
    const [index, setIndex] = useState('')
    const [tagPrice, setTagPrice] = useState(0)

    // 确认信息
    const [confirmInformation, setConfirmInformation] = useState({
        "is_confirmed_num": '',
        "not_confirmed_num": '',
        "is_participate_num": '',
        "not_participate_num": '',
        'is_use_platform_coupon_num': '',
        'not_is_use_platform_coupon_num': '',
        "total": '',
    })
    // 打来确认信息
    const [isConfirmInformation, setIsConfirmInformation] = useState(false)
    // 选择的id
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRow, setSelectedRow] = useState([])
    // 页数
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [ total , setTotal ] = useState(100)
    // 是否有平台优惠券
    const [hasPlatformCoupon, setHasPlatformCoupon] = useState(0)
    // 是否有店铺券
    const [ hasShopCoupon , setHasShopCoupon] = useState(0)
    // 是否有津贴 has_platform_allowance
    // 多件多折专区
    const [listPartition, setListPartition] = useState([])
    // 限时专区
    const [limListPartition, setLimListPartition] = useState([])
    // 平台利益点
    const [platformCoupons, setPlatformCoupons] = useState([])
    // 活动上限
    const [discountOfActPriceCap, setDiscountOfActPriceCap] = useState(0)
    // 平台利益点json
    const [ optionJson, setOptionJson ] = useState({})
    // 是否打开选款定价利益点
    const [isSelectionBenefits, setIsSelectionBenefits] = useState(false)
    // 多选设置
    const onSelectChange = (selectedRowKeys, selectedRow) => {
        // 关闭价格列表
        setDoubleClickContent('')
        setSelectedRowKeys(selectedRowKeys)
        setSelectedRow(selectedRow)
    };
    const rowSelection = {
        columnWidth: 17,
        selectedRowKeys,
        onChange: onSelectChange,
    }

    //设置每个页面显示个数
    const paginationProps = {
        pageSizeOptions: [20, 50, 100, 200],
        showSizeChanger: true,
        current: page,
        pageSize: pageSize,
        total: total,
        showTotal: (total)=>`总共 ${total} 商品`,
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize)
        },
        onChange: (page) => {
            setPage(page)
        }
    }

    // id
    const [ id , setId ] = useState('')
    // spu
    const [ spu , setSpu ] = useState('')

    // 查询下标
    const [isLookIndex, setIsLookIndex] = useState(-1)
    // 双击内容
    const [doubleClickContent, setDoubleClickContent] = useState('')
    // 条件查询
    const [isSearch, setIsSearch] = useState(false)
    // 表头
    const [columns, setColumns] = useState([])
    // 最大极限到手价-底线价
    const [maxHandLine, setMaxHandLine] = useState('')
    // 最小极限到手价-底线价
    const [minHandLine, setMinHandLine] = useState('')
    // 最小底线折扣
    const [ minLineDiscount , setMinLineDiscount] = useState('')
    // 最大底线折扣
    const [ maxLineDiscount , setMaxLineDiscount] = useState('')
    // 最大价格带
    const [ maxPriceBand , setMaxPriceBand ] = useState('')
    // 最小价格带
    const [ minPriceBand , setMinPriceBand ] = useState('')
    // 数据缓存
    const [dataCache, setDataCache] = useState({})
    // 查看操作日志
    const [ isLookLog , setIsLookLog ] = useState(false)
    // 日志数据
    const [ logData , setLogData ] = useState([])
    // 日志表头
    const [ logColumns ] = useState([
        {
            title: "操作人",
            dataIndex: 'creator',
            width: 80,
            align: 'center',
        },
        {
            title: "操作日志",
            dataIndex: 'log',
            align: 'center',
        }
    ])
    // 是否初始化查询
    const [isInitSearch, setIsInitSearch] = useState(1)
    // 页面修改时候调用
    useEffect(()=>{
        if(!isInitSearch){
            getAllData()
        }
    },[page])

    // 一页个数修改的时候
    useEffect(() => {
        if(page != 1){
            setPage(1)
        }else{
            if(!isInitSearch){
                getAllData()
            }
        }
    }, [pageSize])

    useEffect(() => {
        setColumns([
            {
                title: "确认",
                dataIndex: 'confirmed',
                width: 20,
                align: 'center',
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: 2,
                                fontWeight: 100
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <div>
                        {
                            text == 1 ? <CheckSquareOutlined style={{ color: 'green' }} /> : <CheckSquareOutlined style={{ color: "#ccc" }} />
                        }
                    </div>
                )
            },
            {
                title: "基础信息",
                dataIndex: 'id',
                width: 331,
                align: 'center',
                fixed: 'left',
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: 5,
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: '0',
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <div onClick={() => {
                        // 关闭价格列表
                        setDoubleClickContent('')
                    }} style={{ width: 420, height: 130, fontSize: 12 }}>
                        <div style={{ display: 'grid', gridTemplateColumns: "130px 1fr" }}>
                            <div>
                                <a href={`https://detail.tmall.com/item.htm?id=${record.goods_id}`} target="_blank">
                                    {/* <CommodityImage
                                        style={{ width: 65, height: 65 }}
                                        id={record.goods_id} sku={record.spu}
                                        shopName={props.history.location.pathname.split('/')[2]} index={index} /> */}
                                    <img src={record.basicInformation['属性图链接']}  style={{ width: 65, height: 65 }}/>
                                </a>
                                <div style={{ display: 'flex', marginLeft: 5 }}>
                                    <span>ID:</span>
                                    <CopyToClipboard text={record.goods_id} onCopy={() => message.success('复制成功')}>
                                        <Tooltip title="点击可复制" placement="topRight">
                                            <a style={{ textDecoration: "underline" }}>{record.goods_id}</a>
                                        </Tooltip>
                                    </CopyToClipboard>
                                </div>
                                <div style={{ display: 'flex', marginLeft: 5 }}>
                                    <span>SPU:</span>
                                    <CopyToClipboard text={record.spu} onCopy={() => message.success('复制成功')}>
                                        <Tooltip title="点击可复制" placement="topRight">
                                            <a style={{ textDecoration: "underline" }}>{record.spu}</a>
                                        </Tooltip>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: 130 }}>
                                        <div style={{ display: 'flex' }}>
                                            <span>产品线:</span><span>{record.basicInformation['产品线']}</span>
                                        </div>
                                        <div style={{ display: 'flex' , marginTop: -5}}>
                                            <span>年份:</span><span>{record.basicInformation['年份']}</span>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>季节:</span><span>{record.basicInformation['季节']}</span>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>品类:</span><span>{record.basicInformation['品类']}</span>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>波段:</span><span>{record.basicInformation['波段']}</span>
                                        </div>
                                      
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>吊牌价:</span><span>{record.basicInformation['确认吊牌价']}</span>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>上架时间:</span><span>{record.basicInformation['上架时间'] != null ? record.basicInformation['上架时间'] == '' ? '' : record.basicInformation['上架时间'].substring(0, 10) : ''}</span>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            {/* .substring(0,10) */}
                                            <span>上线时间:</span><span>{record.basicInformation['上线时间']}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: 130 }}>
                                        <div style={{ display: 'flex' }}>
                                            <span>库存:</span><span>{record.basicInformation['库存']}</span>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>底线价:</span><span>{record.basicInformation['底线价']}</span>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>底线折扣:</span><span>{record.basicInformation['底线折扣']}</span>
                                        </div>
                                        {/* <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>活动折扣:</span><span>{record.basicInformation['活动价折扣']}</span>
                                        </div> */}
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>加购件数:</span><span>{record.basicInformation['近14天加购件数']}</span>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>支付件数:</span><span>{record.basicInformation['近14天支付件数']}</span>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: -5 }}>
                                            <span>上次活动销量:</span><span>{record.basicInformation['上次活动销量']}</span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection:'column' }}>
                                        <div style={{
                                            border: '1px dashed',
                                            borderColor: '#e45200',
                                            color: '#e45200',
                                            marginTop: 5,
                                            marginRight: 1,
                                            padding: 1,
                                            fontSize: 3,
                                            width: 60,
                                            display: record.basicInformation['重点款'] != '0' ? '' : 'none'
                                        }}>{'重点款'}</div>
                                        <div style={{
                                            border: '1px dashed',
                                            borderColor: '#e2557d',
                                            color: '#e2557d',
                                            marginTop: 1,
                                            marginRight: 1,
                                            padding: 1,
                                            fontSize: 3,
                                            width: 80,
                                            display: record.is_participate ? '' : 'none'
                                        }}>{'参加活动'}</div>
                                        <div style={{
                                            border: '1px dashed',
                                            borderColor: '#a7c531',
                                            color: '#a7c531',
                                            marginTop: 1,
                                            marginRight: 1,
                                            padding: 1,
                                            fontSize: 3,
                                            width: 60,
                                            display: record.is_use_platform_coupon ? '' : 'none'
                                        }}>{'平台券'}</div>
                                         <div style={{
                                            border: '1px dashed',
                                            borderColor: '#7c9ce5',
                                            color: '#7c9ce5',
                                            marginTop: 1,
                                            marginRight: 1,
                                            padding: 1,
                                            fontSize: 3,
                                            width: 60,
                                            display: record.is_use_shop_coupon ? '' : 'none'
                                        }}>{'店铺券'}</div>
                                         <div style={{
                                            border: '1px dashed',
                                            borderColor: '#fbbc59 ',
                                            color: '#fbbc59 ',
                                            marginTop: 1,
                                            marginRight: 1,
                                            padding: 1,
                                            fontSize: 3,
                                            width: 60,
                                            display: record.is_use_goods_coupon ? '' : 'none'
                                        }}>{'商品券'}</div>

                                        <div style={{
                                            border: '1px dashed',
                                            borderColor: '#ffc107',
                                            color: '#ffc107',
                                            marginTop: 1,
                                            marginRight: 1,
                                            padding: 1,
                                            fontSize: 3,
                                            width: 20,
                                            display: 'none'
                                        }}>{'津'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {
                                record.tag.map(item => {
                                    return <Tooltip placement="topLeft" title={item.remarks} color={`#${item.tag_color}`}>
                                        <div style={{
                                            border: '1px dashed',
                                            borderColor: `#${item.tag_color}`,
                                            color: `#${item.tag_color}`,
                                            marginTop: 1,
                                            marginRight: 1,
                                            padding: 1,
                                            fontSize: 3
                                        }}>{item.short_tag_name}</div>
                                    </Tooltip>
                                })
                            }
                        </div>
                    </div>
                )
            },
            {
                title: "总览",
                dataIndex: 'general',
                width: 100,
                align: 'center',
                fixed: 'left',
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: 5,
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <div onClick={() => {
                        // 关闭价格列表
                        setDoubleClickContent('')
                    }} style={{ fontSize: 12 }}>
                        {
                            record['is_delete'] == 0 ? <div>
                                <div style={{ marginTop: -5 }}>
                                    <Text strong>活动价:</Text>
                                    <Text>{record.is_use_platform_coupon ? text['使用平台购物券活动价'] : text['活动价']}</Text>
                                </div>
                                <div style={{ marginTop: -5 }}>
                                    <Text strong>到手价:</Text>
                                    <Text>{record.is_use_platform_coupon ? text['使用平台购物券到手价'] : text['到手价']}</Text>
                                </div>
                                <div style={{ marginTop: -5 }}>
                                    <Text strong>极限到手价:</Text>
                                    <Text>{record.is_use_platform_coupon ? text['使用平台购物券极限到手价'] : text['极限到手价']}</Text>
                                </div>
                                <div style={{ marginTop: -5 }}>
                                    <Text strong>极限到手折扣:</Text>
                                    <Text>{record.is_use_platform_coupon ? text['使用平台购物券极限到手折扣'] : text['极限到手折扣']}</Text>
                                </div>
                                <div style={{ marginTop: -5 }}>
                                    <Text strong>极限差价:</Text>
                                    <Text>{record.is_use_platform_coupon ? text['使用平台购物券极限到手价-底线价'] : text['极限到手价-底线价']}</Text>
                                </div>
                            </div> : <Text>该商品不参与本次活动</Text>
                        }

                    </div>
                )
            },
            ...specialArea.map(item => {
                return {
                    title: item,
                    dataIndex: item,
                    width: 110,
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: 5,
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    padding: '0',
                                    border: record['is_delete'] == 0 ? record[item]['sign'] == 2 ? '3px solid #ff3131' : record[item]['sign'] == 1 ? "3px solid #188fff" : '' : '',
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        // visible={isLookListContent}
                        // (page - 1) * pageSize + index
                        item != '88会员' ? <Popover visible={`${index},${item}` == doubleClickContent ? true : false} placement={'bottom'} content={chosePriceListContent(text.priceList, item, index)} title="参考价格">
                            <div onDoubleClick={() => {
                                // 判断是否选择限时绑定的专区
                                if (item == limitedTimeName && record[limitedTimeDiscount]['sign'] == 0) {

                                } else if (item == limitedTimeName && record['is_delete']) {

                                } else {
                                    // 打开价格列表
                                    let doubleClickContent = index + "," + item
                                    setDoubleClickContent(doubleClickContent)
                                }

                            }} onClick={() => {
                                // 关闭价格列表
                                if (`${index},${item}` != doubleClickContent) {
                                    setDoubleClickContent('')
                                    cookie.save('changeLog', '', { path: '/' })
                                }
                            }} style={{ display: 'flex', flexDirection: 'column', fontSize: 12, paddingBottom: 2, paddingTop: 2, paddingLeft: 20 }}>
                                {
                                    text.bestTitle.map(item => {
                                        return <div style={{ marginTop: -2, display: 'flex' }}>
                                            <Text type="secondary">{item}:</Text>
                                            {
                                                item == '单件折扣' ? 
                                                // * 100 + '%' 
                                                <Text style={{ marginLeft: 5 }}>{record.is_use_platform_coupon ? toPercent(text.bestValue['使用平台购物券' + item]) : toPercent(text.bestValue[item]) }</Text>
                                                : <Text style={{ marginLeft: 5 }}>{record.is_use_platform_coupon ? text.bestValue['使用平台购物券' + item] : text.bestValue[item]}</Text>
                                            }

                                        </div>
                                    })
                                }
                            </div>
                        </Popover> :
                            <div onMouseEnter={() => {
                                setMouseEnterValue(item + ',' + (page - 1) * pageSize + index)
                            }} onMouseLeave={() => {
                                setMouseEnterValue('')
                            }} style={{ display: 'flex', flexDirection: 'column', fontSize: 12, paddingBottom: 2, paddingTop: 2, paddingLeft: 20 }}>
                                {
                                    text.bestTitle.map(item => {
                                        return <div style={{ marginTop: -2, display: 'flex' }}>
                                            <Text type="secondary">{item}:</Text>
                                            <Text style={{ marginLeft: 5 }}>{text.bestValue[item]}</Text>
                                        </div>
                                    })
                                }
                                <div onClick={() => {
                                    // 关闭价格列表
                                    setDoubleClickContent('')
                                }} style={{ display: mouseEnterValue == item + ',' + (page - 1) * pageSize + index ? 'flex' : 'none', justifyContent: 'center' }}>
                                    {
                                        text.sign != 0 ?
                                            <Tooltip placement="topLeft" title={"功能暂时不提供"} color={'cyan'}>
                                                <Button disabled style={{ borderRadius: 25, marginTop: 10, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }}>点击取消</Button>
                                            </Tooltip> :
                                            <Tooltip placement="topLeft" title={"功能暂时不提供"} color={'cyan'}>
                                                <Button disabled style={{ borderRadius: 25, marginTop: 10, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }}>点击参加</Button>
                                            </Tooltip>

                                    }

                                </div>
                            </div>

                    )
                }
            }),
            {
                title: '备注',
                dataIndex: '备注',
                width: 70,
                align: 'center',
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: 5,
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <div onClick={() => {
                        // 关闭价格列表
                        setDoubleClickContent('')
                    }} style={{ display: 'flex', fontSize: 12 }}>
                        <a style={{ marginRight: 10 }} disabled>编辑</a>
                        <a style={{ display: record['is_delete'] == 0 ? '' : 'none' }} onClick={() => {
                            // setDataIndex((page - 1) * pageSize + index)
                            setDataIndex(index)
                            setIsDelete(true)
                        }}>移除</a>
                    </div>
                )
            }
        ])
    }, [doubleClickContent, mouseEnterValue, specialArea])
    // 初始化数据
    useEffect(() => {
        // 建立websocket连接
        ws.onopen = e => {
            console.log("跟websocket建立连接");
        }
        ws.onclose = e => {
            console.log('WebSocket连接断开', e);
        }
        ws.onerror = e => {
            console.log('WebSocket连接错误', e);
        }
        setSelectionPlanName(props.location.state.name);
    }, [])
    useEffect(() => {
        if (selectionPlanName != '') {
            querySpecialAreaList()
            getFitTargetValue()
            getCommodityTagList()
            // 判断是否有汇总的条件
            if(props.location.state.summaryDimension != undefined){
                let summaryDimension = props.location.state.summaryDimension
                // console.log(summaryDimension.yearSelectedTags);
                // console.log(summaryDimension.seasonSelectedTags);
                // console.log(summaryDimension.productLineSelectedTags);
                // console.log(summaryDimension.priceBand);
                let obj = []
                summaryDimension.productLineSelectedTags.map(productLineItem=>{
                    let objDict = {}
                    objDict['productLine'] = productLineItem
                    summaryDimension.yearSelectedTags.map(yearItem=>{
                        objDict['year'] = yearItem
                        summaryDimension.seasonSelectedTags.map((seasonItem, seasonIndex)=>{
                            objDict['season'] = seasonItem
                            objDict['secondary_category'] = summaryDimension.categorySelectedTags
                            obj.push(objDict)
                            objDict = {'productLine': productLineItem, 'year': yearItem}
                        })
                        objDict = {'productLine': productLineItem}
                    })
                })
                setIsConfirmed(1)
                setSelectionObj(obj)
                setIsSearch(true)
                // 获取价格带
                let priceBand = summaryDimension.priceBand
                console.log(priceBand);
                // 处理600以下
                if(priceBand.indexOf("以下") != -1){
                    setMinPriceBand(0)
                    setMaxPriceBand(600)
                    setMaxHandLine('')
                    setMinHandLine('')
                    setMinLineDiscount('')
                    setMaxLineDiscount('')
                }
                // 处理中间的价格带
                if(priceBand.indexOf('-') != -1 ){
                    setMinPriceBand(priceBand.split('-')[0])
                    setMaxPriceBand(priceBand.split('-')[1])
                    setMaxHandLine('')
                    setMinHandLine('')
                    setMinLineDiscount('')
                    setMaxLineDiscount('')
                }
                // 处理4000以上
                if(priceBand.indexOf('以上') != -1){
                    setMinPriceBand(4000)
                    setMaxPriceBand(1000000)
                    setMaxHandLine('')
                    setMinHandLine('')
                    setMinLineDiscount('')
                    setMaxLineDiscount('')
                }
            }else{
                setIsSearch(true)
            }
        }
    }, [selectionPlanName])

    useEffect(() => {
        setData(allData)
    }, [allData])



    // 查询默认专区列表方法
    const querySpecialAreaList = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'name': selectionPlanName
        }
        NbAxios(dataProps, "POST", servicePath.querySpecialAreaList).then(res => {
            if (res.success) {
                setDefaultZone(res.data['discounts_list'])
                let zoneList = res.data['discounts_list']
                zoneList.map(item => {
                    let name = item.interest_points_name
                    if (name.indexOf('前') != -1) {
                        setLimitedTimeDiscount(name.split("_")[0]);
                        setLimitedTimeName(name)
                    }

                })
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 查询下拉菜单列表接口
    const getFitTargetValue = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "select_menu_list": ['产品线', '年份', '季节', '品类']
        }
        NbAxios(dataProps, "POST", servicePath.queryFitTargetValue).then(res => {
            if (res.success) {
                let categoryList = res.data['品类'];
                categoryList.unshift({ 'name': '全部' })
                categoryList.unshift({ 'name': '' })
                setCategoryList(categoryList)
                let productLine = res.data['产品线']
                productLine.unshift({ 'name': '全部' })
                productLine.unshift({ 'name': '' })
                setProductLine(productLine)
                let seasonLine = res.data['季节']
                seasonLine.unshift({ 'name': '全部' })
                seasonLine.unshift({ 'name': '' })
                setSeasonList(seasonLine);
                let yearList = res.data['年份']
                yearList.unshift({ 'name': '全部' })
                yearList.unshift({ 'name': '' })
                setYearList(yearList);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 查询商品标签列表
    const getCommodityTagList = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dataProps, "POST", servicePath.queryCommodityTagList).then(res => {
            if (res.success) {
                let commodity_tag_list = res.data['commodity_tag_list']
                commodity_tag_list.unshift({ 'tag_name': '', 'remarks': '' })
                setDataLable(commodity_tag_list)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    const openNotification = () => {
        Modal.info({
            title: '名词解释',
            width: "900px",
            centered: true,
            content: (
                <div style={{ width: '800px' }}>
                    <div style={{ marginTop: 5 }}>最终活动价：选定的价格选区中最终确定的活动价；</div>
                    <div style={{ marginTop: 5 }}>最终到手价：选定的价格选区中最终确定的到手价即打标价；</div>
                    <div style={{ marginTop: 5 }}>极限到手价：选定的价格选区中确定的最极限的到手价；</div>
                    <div style={{ marginTop: 5 }}>最终折扣：选定的价格选区中确定的最终极限折扣；</div>
                    <div style={{ marginTop: 5 }}>到手折扣：到手价格对于吊牌价所对应的折扣；</div>
                    <div style={{ marginTop: 5 }}>最终折扣：选定的价格选区中确定的最终极限折扣；</div>
                    <div style={{ marginTop: 5 }}>到手价-底线价：到手价与底线价的差距；</div>
                    <div style={{ marginTop: 5 }}>二后到手-前二到手价：在限时优惠专区中的到手价与活动到手价的差距；</div>
                    <div style={{ marginTop: 5 }}>极限到手价：利用专区与限时优惠进行计算的，最极致的到手价；</div>
                    <div style={{ marginTop: 5 }}>极限到手折扣：最极致的到手价对于吊牌价所对应的折扣；</div>
                    <div style={{ marginTop: 5 }}>极限到手价-底线价：最极致的到手价与底线价的差距；</div>
                </div>
            ),
            okText: "知道了",
            onOk() { },
        });
    };


    const getAllData = () => {
        let key = "query_option_pricing_commodity_list"
        let dataProps = {
            'url': 'query_option_pricing_commodity_list',
            'shopId': shopIdDict[props.history.location.pathname.split('/')[2]],
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            'name': selectionPlanName,
            'selection_range': selectionObj,
            'special_area': choseDefaultZone,
            'is_members': isChoseMember,
            'is_confirmed': isConfirmed,
            'is_participate': isParticipate,
            'is_delete': isChooseDelete,
            'creator':cookie.load('name'),
            "goods_id": id,
            "spu":spu,
            "minHandLine":parseFloat(minHandLine),
            "maxHandLine":parseFloat(maxHandLine),
            "minLineDiscount":parseFloat(minLineDiscount),
            "maxLineDiscount":parseFloat(maxLineDiscount),
            "minPriceBand":parseFloat(minPriceBand),
            "maxPriceBand":parseFloat(maxPriceBand),
            "page":page,
            "page_size":pageSize
        }
        console.log(dataProps);
        setEffective(1)
        console.log("发起请求");
        console.log(getNowTime());
        message.loading({ content: '正在查询相关商品...', key, duration: 100 })
        NbAxios(dataProps,"POST",servicePath.queryOptionPricingCommodityList).then(res=>{
            setEffective(0)
            console.log("接收请求");
            console.log(getNowTime());
            if(res.success){
                console.log(res['data']['commodity_list']);
                console.log(res['data']['table_head']);
                setSelectedRowKeys([])
                setSpecialArea(res['data']['table_head']['table_head']);
                setHasPlatformCoupon(res['data']['table_head']['has_platform_coupon']);
                setHasShopCoupon(res['data']['table_head']['has_shop_coupon'])
                setAllData(res['data']['commodity_list']);
                console.log(res.total);
                setTotal(res.total)
                setIsSearch(false)
                console.log("处理数据");
                console.log(getNowTime());
                message.success({ content: '查询商品成功...', key })
            }else{
                message.warn({ content: res['errorMsg'], key })
            }
        })
        // 校验websocket是否要连接
        // if (ws.readyState !== 0) {
        //     let key = "query_option_pricing_commodity_list"
        //     let dataProps = {
        //         'url': 'query_option_pricing_commodity_list',
        //         'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
        //         'name': selectionPlanName,
        //         'selection_range': selectionObj,
        //         'special_area': choseDefaultZone,
        //         'is_members': isChoseMember,
        //         'is_confirmed': isConfirmed,
        //         'is_participate': isParticipate,
        //         'is_delete': isChooseDelete,
        //         'creator':cookie.load('name'),
        //         "goods_id": id,
        //         "spu":spu,
        //         "page":page,
        //         "pageSize":pageSize
        //     }
        //     console.log(dataProps);
        //     setEffective(1)
        //     console.log("发起请求");
        //     console.log(getNowTime());
        //     NbAxios(dataProps,"POST",servicePath.queryActivityLabel).then(res=>{
        //         console.log(res);
        //     })
        //     // ws.send(JSON.stringify(dataProps))
        //     // message.loading({ content: '正在查询相关商品...', key, duration: 100 })
        //     // ws.onmessage = e => {
        //     //     setEffective(0)
        //     //     console.log("接收请求");
        //     //     console.log(getNowTime());
        //     //     let mes = JSON.parse(e.data)
        //     //     if (mes['success']) {
        //     //         console.log(mes['data']['commodity_list']);
        //     //         console.log(mes['data']['table_head']);
        //     //         if (mes['data']['commodity_list'].length != 0) {
        //     //             setLimListPartition(mes['data']['commodity_list'][0]['basicInformation']['list_lim_time_part_discounts_name']);
        //     //             setListPartition(mes['data']['commodity_list'][0]['basicInformation']['list_partition_discounts_name']);
        //     //             setPlatformCoupons(mes['data']['commodity_list'][0]['basicInformation']['platform_coupons']);
        //     //             setDiscountOfActPriceCap(mes['data']['commodity_list'][0]['basicInformation']['discount_of_act_price_cap']);
        //     //         }
        //     //         setSelectedRowKeys([])
        //     //         setSpecialArea(mes['data']['table_head']['table_head']);
        //     //         setHasPlatformCoupon(mes['data']['table_head']['has_platform_coupon']);
        //     //         setHasShopCoupon(mes['data']['table_head']['has_shop_coupon'])
        //     //         setAllData(mes['data']['commodity_list']);
        //     //         setIsSearch(false)
        //     //         console.log("处理数据");
        //     //         console.log(getNowTime());
        //     //         message.success({ content: '查询商品成功...', key })
        //     //     } else {
        //     //         message.warn({ content: mes['errorMsg'], key })
        //     //     }
        //     // }

        // }
    }




    return (
        <div>
            {/* 路由切换的时候提醒 */}
            <div style={{ marginTop: 5, marginLeft: 10, display: 'flex', justifyContent: 'space-between' }} >
                <div style={{ display: 'flex' }}>
                    <div style={{ display: selectedRowKeys.length == 0 ? 'none' : '' }}>
                        <Button disabled={effective ? true : false} onClick={() => {
                            // 校验websocket是否要连接
                            if (ws.readyState !== 0) {
                                let key = "update_batch_confirmed"
                                let dataProps = {
                                    'url': 'update_batch_confirmed',
                                    'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                    'name': selectionPlanName,
                                    'id_list': selectedRowKeys,
                                    'creator':cookie.load('name')
                                }
                                setEffective(1)
                                ws.send(JSON.stringify(dataProps))
                                message.loading({ content: '正在批量确认相关商品...', key, duration: 100 })
                                ws.onmessage = e => {
                                    let mes = JSON.parse(e.data)
                                    if (mes['success']) {
                                        message.success({ content: '批量确认相关商品成功...', key })
                                        getAllData()
                                    } else {
                                        setEffective(0)
                                        message.warn({ content: mes['errorMsg'], key })
                                    }
                                }
                            }

                        }}>批量确认价格</Button>
                        <Button disabled={effective ? true : false} type="primary" style={{ marginLeft: 10, display:hasPlatformCoupon ? 'none':'' }} onClick={() => {
                            // 校验websocket是否要连接
                            if (ws.readyState !== 0) {
                                let allObj = [...allData]
                                allObj.map(item=>{
                                    if(selectedRowKeys.indexOf(item._id) != -1){
                                        item['is_participate'] = 1
                                        item['confirmed'] = 0
                                    }
                                })
                                setData(allObj)
                                setSelectedRowKeys([])
                                let key = "update_commodity_is_participate"
                                let dataProps = {
                                    'url': 'update_commodity_is_participate',
                                    'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                    'name': selectionPlanName,
                                    'id_list': selectedRowKeys,
                                    'is_participate': 1,
                                    'hasPlatformCoupon':hasPlatformCoupon,
                                    'creator':cookie.load('name')
                                }
                                ws.send(JSON.stringify(dataProps))
                                message.loading({ content: '正在批量修改相关商品...', key, duration: 100 })
                                ws.onmessage = e => {
                                    let mes = JSON.parse(e.data)
                                    if (mes['success']) {
                                        message.success({ content: '批量修改相关商品成功...', key })
                                    } else {
                                        message.warn({ content: mes['errorMsg'], key })
                                    }
                                }
                            }

                        }}>参加活动</Button>
                        <Button disabled={effective ? true : false} type="primary" danger style={{ marginLeft: 10, display:hasPlatformCoupon ? 'none':'' }} onClick={() => {
                            // 校验websocket是否要连接
                            if (ws.readyState !== 0) {
                                let allObj = [...allData]
                                allObj.map(item=>{
                                    if(selectedRowKeys.indexOf(item._id) != -1){
                                        item['is_participate'] = 0
                                        item['confirmed'] = 0
                                    }
                                })
                                setData(allObj)
                                setSelectedRowKeys([])
                                let key = "update_commodity_is_participate"
                                let dataProps = {
                                    'url': 'update_commodity_is_participate',
                                    'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                    'name': selectionPlanName,
                                    'id_list': selectedRowKeys,
                                    'is_participate': 0,
                                    'hasPlatformCoupon':hasPlatformCoupon,
                                    'creator':cookie.load('name')
                                }
                                ws.send(JSON.stringify(dataProps))
                                message.loading({ content: '正在批量修改相关商品...', key, duration: 100 })
                                ws.onmessage = e => {
                                    let mes = JSON.parse(e.data)
                                    if (mes['success']) {
                                        message.success({ content: '批量修改相关商品成功...', key })
                                    } else {
                                        message.warn({ content: mes['errorMsg'], key })
                                    }
                                }
                            }
                        }}>退出活动</Button>
                        <Button disabled={effective ? true : false} type="primary" style={{ marginLeft: 10, display: hasPlatformCoupon ? '' : 'none' }} onClick={() => {
                            // 校验websocket是否要连接
                            if (ws.readyState !== 0) {
                                let allObj = [...allData]
                                allObj.map(item=>{
                                    if(selectedRowKeys.indexOf(item._id) != -1){
                                        item['is_participate'] = 1
                                        item['confirmed'] = 0
                                        item['is_use_platform_coupon'] = 1
                                    }
                                })
                                setData(allObj)
                                setSelectedRowKeys([])
                                let key = "update_commodity_is_participate"
                                let dataProps = {
                                    'url': 'update_commodity_is_participate',
                                    'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                    'name': selectionPlanName,
                                    'id_list': selectedRowKeys,
                                    'is_participate': 1,
                                    'hasPlatformCoupon':hasPlatformCoupon,
                                    'creator':cookie.load('name')
                                }
                                console.log(dataProps);
                                ws.send(JSON.stringify(dataProps))
                                message.loading({ content: '正在批量修改相关商品...', key, duration: 100 })
                                ws.onmessage = e => {
                                    let mes = JSON.parse(e.data)
                                    if (mes['success']) {
                                        message.success({ content: '批量修改相关商品成功...', key })
                                    } else {
                                        message.warn({ content: mes['errorMsg'], key })
                                    }
                                }
                            }

                        }}>参与活动且使用平台购物券</Button>
                        <Button disabled={effective ? true : false} type="primary" danger style={{ marginLeft: 10, display: hasPlatformCoupon ? '' : 'none' }} onClick={() => {
                            // 校验websocket是否要连接
                            if (ws.readyState !== 0) {
                                let allObj = [...allData]
                                allObj.map(item=>{
                                    if(selectedRowKeys.indexOf(item._id) != -1){
                                        item['is_participate'] = 0
                                        item['confirmed'] = 0
                                        item['is_use_platform_coupon'] = 0
                                    }
                                })
                                setData(allObj)
                                setSelectedRowKeys([])
                                let key = "update_commodity_is_participate"
                                let dataProps = {
                                    'url': 'update_commodity_is_participate',
                                    'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                    'name': selectionPlanName,
                                    'id_list': selectedRowKeys,
                                    'is_participate': 0,
                                    'hasPlatformCoupon':hasPlatformCoupon,
                                    'creator':cookie.load('name')
                                }
                                ws.send(JSON.stringify(dataProps))
                                message.loading({ content: '正在批量修改相关商品...', key, duration: 100 })
                                ws.onmessage = e => {
                                    let mes = JSON.parse(e.data)
                                    if (mes['success']) {
                                        message.success({ content: '批量修改相关商品成功...', key })
                                    } else {
                                        message.warn({ content: mes['errorMsg'], key })
                                    }
                                }
                            }
                        }}>不参与活动且不使用平台购物券</Button>
                        <Button type="dashed" style={{marginLeft: 10}} onClick={()=>{
                            // 关闭价格列表
                            setDoubleClickContent('')
                            setIsBatchChangeSinglePieceDiscount(true)
                        }}>批量修改单件折扣</Button>
                    </div>
                    <div style={{ display: selectedRowKeys.length != 0 ? 'none' : '' }}>
                        <Button disabled={effective ? true : false} type="dashed" onClick={() => {
                            // 关闭价格列表
                            setDoubleClickContent('')
                            openNotification()
                        }}>名词解释</Button>
                        <Button type="dashed" danger style={{ marginRight: 10 }} onClick={() => {
                            // 关闭价格列表
                            setDoubleClickContent('')
                            let dataProps = {
                                "roleId": cookie.load('role'),
                                "shopList": cookie.load('shopList'),
                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                'name': selectionPlanName,
                                'creator':cookie.load('name')
                            }
                            NbAxios(dataProps, "POST", servicePath.queryOptionJson).then(res => {
                                setEffective(0)
                                if (res.success) {
                                    setOptionJson(res.data['option_json']);
                                    setIsSelectionBenefits(true)
                                } else {
                                    setEffective(0)
                                    message.warn({ content: res.errorMsg })
                                }
                            })
                      
                            
                        }}>选款定价利益点</Button>
                        <Button type="dashed" onClick={()=>{
                            let dataProps = {
                                "roleId": cookie.load('role'),
                                "shopList": cookie.load('shopList'),
                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                'name': selectionPlanName,
                                'inquirer': cookie.load('name'),
                            }
                            NbAxios(dataProps, "POST", servicePath.querySelectionPricingLog).then(res => {
                                if (res.success) {
                                    setLogData(res.data);
                                    setIsLookLog(true)
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                            
                        }}>查看操作日志</Button>
                    </div>

                </div>
                <div style={{ display: 'flex' }}>
                    <Button disabled={effective ? true : false} style={{ marginLeft: 10 }} type="primary" onClick={() => {
                        // 校验websocket是否要连接
                        if (ws.readyState !== 0) {
                            // 关闭价格列表
                            setDoubleClickContent('')
                            let key = "check_option_pricing"
                            let dataProps = {
                                'url': 'check_option_pricing',
                                'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                'name': selectionPlanName,
                                'creator':cookie.load('name')
                            }
                            console.log(dataProps);
                            setEffective(1)
                            ws.send(JSON.stringify(dataProps))
                            message.loading({ content: '正在统计未确认商品数量...', key, duration: 100 })
                            ws.onmessage = e => {
                                let mes = JSON.parse(e.data)
                                setEffective(0)
                                if (mes['success']) {
                                    message.success({ content: '统计未确认商品数量成功...', key })
                                    setConfirmInformation(mes['data']);
                                    setIsConfirmInformation(true)
                                } else {
                                    message.warn({ content: mes['errorMsg'], key })
                                }
                            }
                        }

                    }} danger>结束选款</Button>
                    <Button disabled={effective ? true : false} style={{ marginLeft: 10 }} type="dashed" onClick={() => {
                        // 关闭价格列表
                        setDoubleClickContent('')
                        setIsSearch(true)
                    }}>选款范围</Button>
                </div>
            </div>
            <div style={{ marginLeft: 15, marginRight: 15, marginTop: 2 }}>
                <Table
                    loading={effective ? true : false}
                    bordered={true}
                    dataSource={data}
                    columns={columns}
                    scroll={{ x: 800, y: '78vh' }}
                    pagination={paginationProps}
                    rowSelection={rowSelection}
                    rowKey={'goods_only_flag'}
                />
            </div>
            {/* 选款定价利益点 */}
            <Modal
                title="选款定价利益点"
                footer={[]}
                onCancel={() => {
                    setIsSelectionBenefits(false)
                }}
                visible={isSelectionBenefits}
                centered={true}
                width={'90vw'}
            >
                <div>
                    <FormBenefit name={selectionPlanName} props={props} optionJson={optionJson} />
                </div>
            </Modal>
            {/* 选款范围 */}
            <Drawer
                title="选款范围"
                visible={isSearch}
                onClose={() => {
                    setIsSearch(false)
                }}
                width="80vw"
            >
                {/* <Tabs activeKey={tabKey} type="card" style={{ backgroundColor: "#fff", height: 54 }} onChange={(key) => {
                    setTabKey(key)
                }}>
                    <TabPane tab="设置选款范围" key="设置选款范围"></TabPane>
                    <TabPane tab="筛选已选商品" key="筛选已选商品" disabled={allData.length == 0 ? true : false}></TabPane>
                </Tabs> */}
                <div style={{ display: tabKey == '设置选款范围' ? '' : 'none' }}>
                    <div style={{display:"flex", marginTop:10}}>
                        <b style={{marginTop: 5, width:45}}>ID:</b>
                        <Input style={{marginLeft: 10, width:"10vw"}} value={id} onChange={(e)=>{
                            setId(e.target.value)
                        }}/>
                        <b style={{marginTop: 5, marginLeft: 10}}>SPU:</b>
                        <Input style={{marginLeft: 10, width:"10vw"}} value={spu} onChange={(e)=>{
                            setSpu(e.target.value)
                        }}/>
                    </div>
                    <div>
                        <Text type="danger" style={{marginLeft:5}}>ID、SPU提供通配符查询: "%" 代表一位或多位任意字符,"_" 代表一位任意字符；比如要查SPU中第二第四位分别是QS,则应当输入 "_Q_S%"</Text> 
                    </div>
                    {
                        selectionObj.map((selectionItem, selectionIndex) => {
                            return <div style={{ display: 'flex', marginTop: 5 }}>
                                <b style={{ marginTop: 5, fontSize: 15 }}>产品线</b>
                                {/* <Tooltip title="产品线的解释" placement="topLeft">
                                    <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                </Tooltip> */}
                                <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                                <Select style={{ width: "5vw" }} value={selectionItem.productLine} onChange={(value) => {
                                    let obj = [...selectionObj]
                                    obj[selectionIndex]['productLine'] = value
                                    setSelectionObj(obj)
                                }}>
                                    {
                                        productLine.map(item => {
                                            return <Option value={item.name}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                                <b style={{ marginTop: 5, fontSize: 15, marginLeft: 10 }}>年份</b>
                                {/* <Tooltip title="年份的解释" placement="topLeft">
                                    <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                </Tooltip> */}
                                <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                                <Select style={{ width: "5vw" }} value={selectionItem.year} onChange={(value) => {
                                    let obj = [...selectionObj]
                                    obj[selectionIndex]['year'] = value
                                    setSelectionObj(obj)
                                }}>
                                    {
                                        yearList.map(item => {
                                            return <Option value={item.name}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                                <b style={{ marginTop: 5, fontSize: 15, marginLeft: 10 }}>季节</b>
                                {/* <Tooltip title="季节的解释" placement="topLeft">
                                    <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                </Tooltip> */}
                                <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                                <Select style={{ width: "5vw" }} value={selectionItem.season} onChange={(value) => {
                                    let obj = [...selectionObj]
                                    obj[selectionIndex]['season'] = value
                                    setSelectionObj(obj)
                                }}>
                                    {
                                        seasonList.map(item => {
                                            return <Option value={item.name}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                                <b style={{ marginTop: 5, fontSize: 15, marginLeft: 10 }}>品类</b>
                                <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                                <Select onSearch style={{ width: "9vw" }} value={selectionItem.secondary_category} onChange={(value) => {
                                    let obj = [...selectionObj]
                                    obj[selectionIndex]['secondary_category'] = value
                                    setSelectionObj(obj)
                                }}>
                                    {
                                        categoryList.map(item => {
                                            return <Option value={item.name}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                                <b style={{ marginTop: 5, fontSize: 15, marginLeft: 10 }}>标签</b>
                                {/* <Tooltip title="标签的解释" placement="topLeft">
                                    <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                </Tooltip> */}
                                <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                                <Select allowClear style={{ width: "12vw" }} value={selectionItem.label} onChange={(value) => {
                                    let obj = [...selectionObj]
                                    obj[selectionIndex]['label'] = value
                                    setSelectionObj(obj)
                                }}>
                                    {
                                        dataLable.map(item => {
                                            return <Option value={item.tag_name}>{item.remarks}</Option>
                                        })
                                    }
                                </Select>
                                <b style={{ marginTop: 5, fontSize: 15, marginLeft: 10 }}>{'库存'}</b>
                                <Tooltip title="库存范围请从小到大进行填写" placement="topLeft">
                                    <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                </Tooltip>
                                <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                                <InputNumber style={{ marginLeft: 5, width:"5vw" }} value={selectionItem.minStock} onChange={(value) => {
                                    let obj = [...selectionObj]
                                    obj[selectionIndex]['minStock'] = value
                                    setSelectionObj(obj)
                                }} />
                                {/* <b style={{ marginTop: 5, fontSize: 15, marginLeft: 5 }}>{'>= 库存 >='}</b> */}
                                <Input
                                    style={{
                                        width: 30,
                                        borderLeft: 0,
                                        borderRight: 0,
                                        pointerEvents: 'none',
                                    }}
                                    placeholder="-"
                                    disabled
                                />
                                <InputNumber style={{width:"5vw"}} value={selectionItem.maxStock} onChange={(value) => {
                                    let obj = [...selectionObj]
                                    obj[selectionIndex]['maxStock'] = value
                                    setSelectionObj(obj)
                                    // if(selectionItem.minStock != ''){
                                    //     console.log(selectionItem.maxStock < selectionItem.minStock);
                                    //     if(selectionItem.maxStock > selectionItem.minStock){
                                    //         let obj = [...selectionObj]
                                    //         obj[selectionIndex]['maxStock'] = value
                                    //         setSelectionObj(obj)
                                    //     }else{
                                    //         message.warn('请先填写正确的库存范围')
                                    //     }
                                    // }else{
                                    //     message.warn('请先填写库存的最小值')
                                    // }
                                }} />
                                <MinusCircleOutlined style={{ marginLeft: 10, marginTop: 10, display: selectionObj.length == 1 ? 'none' : '' }} onClick={() => {
                                    let obj = [...selectionObj]
                                    obj.splice(selectionIndex, 1)
                                    setSelectionObj(obj)
                                }} />
                                <PlusCircleOutlined style={{ marginLeft: 10, marginTop: 10, display: selectionObj.length -1  == selectionIndex ? '' : 'none'  }} onClick={() => {
                                    let obj = [...selectionObj]
                                    obj.push({
                                        'productLine': '',
                                        'year': '',
                                        'season': '',
                                        'label': '',
                                        'maxStock': '',
                                        'minStock': '',
                                    })
                                    setSelectionObj(obj)
                                }} />
                            </div>
                        })
                    }
                    <div style={{display:'flex', marginTop:10}}>
                        <div>
                            <b style={{ marginTop: 5, fontSize: 15 }}>极限到手价-底线价</b>
                            <Tooltip title="最极致的到手价与底线价的差距" placement="topLeft">
                                <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                            </Tooltip>
                            <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                        </div>
                        <Input style={{ marginLeft: 5, width: "5vw" }} value={minHandLine} onChange={(e)=>{
                            setMinHandLine(e.target.value.replace(/[^\d^\.\-]+/g,''))
                        }} />
                        <Input
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="-"
                            disabled
                        />
                        <Input style={{ marginRight: 5, width: "5vw" }} value={maxHandLine} onChange={(e)=>{
                            setMaxHandLine(e.target.value.replace(/[^\d^\.\-]+/g,''))
                        }} />
                        <div>
                            <b style={{ marginTop: 5, fontSize: 15, marginLeft:5 }}>底线折扣</b>
                            <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                        </div>
                        <Input style={{ marginLeft: 5, width: "5vw" }} value={minLineDiscount} onChange={(e)=>{
                            setMinLineDiscount(e.target.value.replace(/[^\d^\.\-]+/g,''))
                        }} />
                        <Input
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="-"
                            disabled
                        />
                         <Input style={{ marginRight: 5, width: "5vw" }} value={maxLineDiscount} onChange={(e)=>{
                            setMaxLineDiscount(e.target.value.replace(/[^\d^\.\-]+/g,''))
                        }} />
                        <div>
                            <b style={{ marginTop: 5, fontSize: 15, marginLeft:5 }}>价格带</b>
                            <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                        </div>
                        <Input style={{ marginLeft: 5, width: "5vw" }} value={minPriceBand} onChange={(e)=>{
                            setMinPriceBand(e.target.value.replace(/[^\d^\.\-]+/g,''))
                        }} />
                        <Input
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="-"
                            disabled
                        />
                        <Input style={{ marginRight: 5, width: "5vw" }} value={maxPriceBand} onChange={(e)=>{
                            setMaxPriceBand(e.target.value.replace(/[^\d^\.\-]+/g,''))
                        }} />
                    </div>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <b>默认专区:</b>
                        <Select value={choseDefaultZone} style={{ width: 200, marginLeft: 5 }} onChange={(value) => {
                            setChoseDefaultZone(value)
                        }}>
                            {
                                defaultZone.map(item => {
                                    return <Option value={item.interest_points_name}>{item.interest_points_name}</Option>
                                })
                            }
                        </Select>
                        <a style={{ display: choseDefaultZone != '' ? '' : 'none', marginLeft: 10, marginTop: 5 }} onClick={(e) => {
                            setChoseDefaultZone('')
                        }}>清空默认专区</a>
                        <div>
                            <Checkbox style={{ marginLeft: 20, marginTop: 3 }} checked={isConfirmed} onChange={(e) => {
                                if (e.target.checked) {
                                    setIsConfirmed(1)
                                } else {
                                    setIsConfirmed(0)
                                }
                            }}>已确认商品</Checkbox>
                        </div>
                        <div>
                            <Checkbox style={{ marginLeft: 20, marginTop: 3 }} checked={isParticipate} onChange={(e) => {
                                if (e.target.checked) {
                                    setIsParticipate(1)
                                } else {
                                    setIsParticipate(0)
                                }
                            }}>已参加活动商品</Checkbox>
                        </div>
                        <div>
                            <Checkbox style={{ marginLeft: 20, marginTop: 3 }} checked={isChooseDelete} onChange={(e) => {
                                if (e.target.checked) {
                                    setIsChooseDelete(1)
                                } else {
                                    setIsChooseDelete(0)
                                }
                            }}>已移除商品</Checkbox>
                        </div>
                        <div style={{ marginLeft: 20, marginTop: 3 }}>
                            <Tooltip placement="topLeft" title={"功能暂时不提供"} color={'cyan'}>
                                <Checkbox disabled value={isChoseMember} onChange={(e) => {
                                    if (e.target.checked) {
                                        setIsChoseMember(1)
                                    } else {
                                        setIsChoseMember(0)
                                    }
                                }}>88会员</Checkbox>
                            </Tooltip>
                        </div>

                    </div>
                   
                    <div style={{ marginTop: 30 }}>
                        <Button disabled={effective ? true : false} type="primary" style={{ marginRight: 15 }} onClick={() => {
                            let flag = 1
                            let errorMsg = ''
                            selectionObj.map((selectionItem, selectionIndex)=>{
                                if(selectionItem.maxStock < selectionItem.minStock){
                                    flag = 0
                                    errorMsg = `请先填写第${selectionIndex + 1}的选择方案正确的库存范围`
                                }
                            })
                            if(minHandLine > maxHandLine || (minHandLine != '' && maxHandLine == '')){
                                flag = 0
                                errorMsg = `请先填写正确的极限到手价-底线价`
                            }
                            if(minLineDiscount > maxLineDiscount || (minLineDiscount != '' && maxLineDiscount == '')){
                                flag = 0
                                errorMsg = `请先填写正确的底线折扣`
                            }
                            if(minPriceBand > maxPriceBand || (minPriceBand != '' && maxPriceBand == '')){
                                flag = 0
                                errorMsg = `请先填写正确的价格带`
                            }
                            if(flag){
                                setIsInitSearch(0)
                                getAllData()
                            }else{
                                message.warn(errorMsg)
                            }
                        }}>查询</Button>
                        <Button style={{ marginRight: 15 }} disabled={effective ? true : false} type="primary" danger onClick={() => {
                            if (choseDefaultZone != '') {
                                setIsInitSearch(0)
                                let key = "update_batch_price"
                                let dataProps = {
                                    'url': 'update_batch_price',
                                    'shopId': shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    'name': selectionPlanName,
                                    "selection_range": selectionObj,
                                    "special_area": choseDefaultZone,
                                    "is_members": isChoseMember,
                                    'is_confirmed': isConfirmed,
                                    'creator':cookie.load('name'),
                                    "goods_id": id,
                                    "spu":spu,
                                    "page":page,
                                    "page_size":pageSize
                                }
                                setEffective(1)
                                console.log("发起请求");
                                console.log(getNowTime());
                                message.loading({ content: '正在查询相关商品并设定专区...', key, duration: 100 })
                                NbAxios(dataProps, "POST", servicePath.updateBatchPriceInterface).then(res=>{
                                    setEffective(0)
                                    console.log("接收请求");
                                    console.log(getNowTime());
                                    if(res.success){
                                        console.log(res['data']['commodity_list']);
                                        console.log(res['data']['table_head']);
                                        setSelectedRowKeys([])
                                        setSpecialArea(res['data']['table_head']['table_head']);
                                        setHasPlatformCoupon(res['data']['table_head']['has_platform_coupon']);
                                        setHasShopCoupon(res['data']['table_head']['has_shop_coupon'])
                                        setAllData(res['data']['commodity_list']);
                                        console.log(res.total);
                                        setTotal(res.total)
                                        setIsSearch(false)
                                        console.log("处理数据");
                                        console.log(getNowTime());
                                        message.success({ content: '查询相关商品并设定专区成功...', key })
                                    }else{
                                        message.warn({ content: res['errorMsg'], key })
                                    }
                                })
                                // 校验websocket是否要连接
                                // if (ws.readyState !== 0) {
                                //     let key = "update_batch_price"
                                //     let dataProps = {
                                //         'url': 'update_batch_price',
                                //         'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                //         'name': selectionPlanName,
                                //         "selection_range": selectionObj,
                                //         "special_area": choseDefaultZone,
                                //         "is_members": isChoseMember,
                                //         'is_confirmed': isConfirmed,
                                //         'creator':cookie.load('name'),
                                //         "goods_id": id,
                                //         "spu":spu
                                //     }
                                //     setEffective(1)
                                //     ws.send(JSON.stringify(dataProps))
                                //     message.loading({ content: '正在查询相关商品并设定专区...', key, duration: 100 })
                                //     ws.onmessage = e => {
                                //         setEffective(0)
                                //         let mes = JSON.parse(e.data)
                                //         if (mes['success']) {
                                //             console.log(mes['data']);
                                //             if (mes['data']['commodity_list'].length != 0) {
                                //                 setLimListPartition(mes['data']['commodity_list'][0]['basicInformation']['list_lim_time_part_discounts_name']);
                                //                 setListPartition(mes['data']['commodity_list'][0]['basicInformation']['list_partition_discounts_name']);
                                //                 setPlatformCoupons(mes['data']['commodity_list'][0]['basicInformation']['platform_coupons']);
                                //             }
                                //             setSelectedRowKeys([])
                                //             setSpecialArea(mes['data']['table_head']['table_head']);
                                //             setAllData(mes['data']['commodity_list']);
                                //             setHasPlatformCoupon(mes['data']['table_head']['has_platform_coupon']);
                                //             setIsSearch(false)
                                //             message.success({ content: '查询相关商品并设定专区成功...', key })
                                //         } else {
                                //             message.warn({ content: mes['errorMsg'], key })
                                //         }
                                //     }
                                // }

                            } else {
                                message.warn('请选择专区')
                            }
                        }}>查询并设定专区</Button>
                        <Button type="dashed" onClick={()=>{
                            setId('')
                            setSpu('')
                            setChoseDefaultZone('')
                            setIsConfirmed(0)
                            setIsParticipate(0)
                            setIsChooseDelete(0)
                            setSelectionObj([{
                                'productLine': '',
                                'year': '',
                                'season': '',
                                'secondary_category':'',
                                'label': '',
                                'maxStock': '',
                                'minStock': '',
                            }])
                            setMaxHandLine('')
                            setMinHandLine('')
                            setMaxPriceBand('')
                            setMinPriceBand('')
                            setMinLineDiscount('')
                            setMaxLineDiscount('')
                        }}>重置</Button>
                    </div>
                </div>
                <div style={{ display: tabKey == '筛选已选商品' ? '' : 'none' }}>
                    <div>
                        <Text type="danger">以下条件互斥，不填写默认为0</Text>
                    </div>
                    <div style={{display:'flex'}}>
                        <div style={{width:170}}>
                            <b style={{ marginTop: 5, fontSize: 15 }}>极限到手价-底线价</b>
                            <Tooltip title="最极致的到手价与底线价的差距" placement="topLeft">
                                <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                            </Tooltip>
                            <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                        </div>
                        <InputNumber style={{ marginLeft: 5 }} value={minHandLine} onChange={(value) => {
                            if (value == null) {
                                setMinHandLine('')
                            } else {
                                setMinHandLine(value)
                            }
                            setMinLineDiscount('')
                            setMaxLineDiscount('')
                            setMaxPriceBand('')
                            setMinPriceBand('')
                        }} />
                        <Input
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="-"
                            disabled
                        />
                        <InputNumber value={maxHandLine} onChange={(value) => {
                            if (value == null) {
                                setMaxHandLine('')
                            } else {
                                setMaxHandLine(value)
                            }
                            setMinLineDiscount('')
                            setMaxLineDiscount('')
                            setMaxPriceBand('')
                            setMinPriceBand('')
                        }} />
                    </div>
                    <div style={{display:'flex', marginTop:5}}>
                        <div style={{width:170}}>
                            <b style={{ marginTop: 5, fontSize: 15 }}>底线折扣</b>
                            <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                        </div>
                        <InputNumber style={{ marginLeft: 5 }} value={minLineDiscount} onChange={(value) => {
                            if (value == null) {
                                setMinLineDiscount('')
                            } else {
                                setMinLineDiscount(value)
                            }
                            setMaxHandLine('')
                            setMinHandLine('')
                            setMaxPriceBand('')
                            setMinPriceBand('')
                        }} />
                        <Input
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="-"
                            disabled
                        />
                        <InputNumber value={maxLineDiscount} onChange={(value) => {
                             if (value == null) {
                                setMaxLineDiscount('')
                            } else {
                                setMaxLineDiscount(value)
                            }
                            setMaxHandLine('')
                            setMinHandLine('')
                            setMaxPriceBand('')
                            setMinPriceBand('')
                        }} />
                         <Text type="danger" style={{marginTop:3, marginLeft:5}}>{"(如: 8折 -> 填80、75折 -> 填75)"}</Text>
                    </div>
                    <div style={{display:'flex', marginTop:5}}>
                        <div style={{width:170}}>
                            <b style={{ marginTop: 5, fontSize: 15 }}>价格带</b>
                            <b style={{ marginTop: 5, fontSize: 15, marginRight: 5 }}>:</b>
                        </div>
                        <InputNumber style={{ marginLeft: 5 }} value={minPriceBand} onChange={(value) => {
                            if (value == null) {
                                setMinPriceBand('')
                            } else {
                                setMinPriceBand(value)
                            }
                            setMaxHandLine('')
                            setMinHandLine('')
                            setMinLineDiscount('')
                            setMaxLineDiscount('')
                        }} />
                        <Input
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="-"
                            disabled
                        />
                        <InputNumber value={maxPriceBand} onChange={(value) => {
                             if (value == null) {
                                setMaxPriceBand('')
                            } else {
                                setMaxPriceBand(value)
                            }
                            setMaxHandLine('')
                            setMinHandLine('')
                            setMinLineDiscount('')
                            setMaxLineDiscount('')
                        }} />
                    </div>
                    <div>
                        <Button type="primary" onClick={() => {
                            let allObj = [...allData]
                            let obj = []
                            if(maxHandLine != '' || minHandLine != ''){
                                if(parseInt(minHandLine) != NaN && parseInt(maxHandLine) != NaN){
                                    allObj.map(item => {
                                        if (minHandLine <= item.general['极限到手价-底线价'] && item.general['极限到手价-底线价'] <= maxHandLine) {
                                            obj.push(item)
                                        }
                                    })
                                    setData(obj) 
                                    setIsSearch(false)
                                }else{
                                    message.warn('请填写合理的极限到手价-底线价区间')
                                }
                            }
                            if(maxLineDiscount != '' || minLineDiscount != ''){
                                if(parseInt(minLineDiscount) != NaN && parseInt(maxLineDiscount) != NaN){
                                    let minLineDiscountNum = minLineDiscount / 100
                                    let maxLineDiscountNum = maxLineDiscount / 100
                                    allObj.map(item => {
                                        if (minLineDiscountNum <= item.basicInformation['底线折扣'] && item.basicInformation['底线折扣'] <= maxLineDiscountNum) {
                                            obj.push(item)
                                        }
                                    })
                                    setData(obj)
                                    setIsSearch(false)
                                }else{
                                    message.warn('请填写合理的底线折扣区间')
                                }
                            }
                            if(maxPriceBand != '' || minPriceBand != ''){
                                if(parseInt(minPriceBand) != NaN && parseInt(maxPriceBand) != NaN){
                                    allObj.map(item => {
                                        if (minPriceBand <= item.general['到手价'] && item.general['到手价'] <= maxPriceBand) {
                                            obj.push(item)
                                        }
                                    })
                                    setData(obj)
                                    setIsSearch(false)
                                }else{
                                    message.warn('请填写合理的价格带区间')
                                }
                            }
                        }}>查询</Button>
                        <Button style={{ marginLeft: 10 }} type="primary" danger onClick={() => {
                            let allObj = [...allData]
                            setData(allObj)
                            setMaxHandLine('')
                            setMinHandLine('')
                            setMinLineDiscount('')
                            setMaxLineDiscount('')
                            setMaxPriceBand('')
                            setMinPriceBand('')
                        }}>重置</Button>
                    </div>
                </div>
            </Drawer>

            <Modal
                title="修改到手价"
                visible={isChangeHandValue}
                footer={[]}
                closable={false}
                onCancel={() => {
                    let dataObj = [...data]
                    dataObj[dataIndex][columnsIndex]['priceList'][index] = cookie.load('datacache')
                    setData(dataObj)
                    setIsChangeHandValue(false)
                    setAtHandJson([
                        {
                            'data': {
                                "value": {
                                    "活动价": '',
                                    "使用平台购物券活动价": '',
                                    "到手价": '',
                                    '使用平台购物券到手价': ''
                                },
                            },
                            'check': 0
                        },
                        {
                            'data': {
                                "value": {
                                    "活动价": '',
                                    "使用平台购物券活动价": '',
                                    "到手价": '',
                                    '使用平台购物券到手价': ''
                                },
                            },
                        },
                        {
                            'data': {
                                "value": {
                                    "活动价": '',
                                    "使用平台购物券活动价": '',
                                    "到手价": '',
                                    '使用平台购物券到手价': ''
                                },
                            },
                            'check': 0
                        }
                    ])
                    setChangeHandValue('')
                }}
                centered={true}
                zIndex={9999}
                width={400}
                mask={false}
            >
                <div>
                    <div>
                        <Checkbox disabled={atHandJson[0]['data']['value']['活动价'] == '' ? true : false} style={{ fontSize: 12, display: 'flex', width: '300px' }} checked={atHandJson[0]['check']} onChange={(e) => {
                            if (e.target.checked) {
                                // 选中
                                let obj = [...atHandJson]
                                obj[2]['check'] = 0
                                obj[0]['check'] = 1
                                setAtHandJson(obj)
                            } else {
                                // 未选中
                                let obj = [...atHandJson]
                                obj[0]['check'] = 0
                                setAtHandJson(obj)
                            }
                        }}>
                            <Text strong>活动价:</Text>
                            <Text type="secondary" style={{ width: 50, display: 'inline-block' }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? atHandJson[0]['data']['value']['使用平台购物券活动价'] : atHandJson[0]['data']['value']['活动价'] : ''}</Text>
                            <Text strong>到手价:</Text>
                            <Text type="secondary" style={{ width: 50, display: 'inline-block' }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? atHandJson[0]['data']['value']['使用平台购物券到手价'] : atHandJson[0]['data']['value']['到手价'] : ''}</Text>
                        </Checkbox>
                    </div>
                    <div style={{ display: 'flex', marginLeft: 25, fontSize: 12, height: 20 }}>
                        <Text strong>活动价:</Text>
                        <Text type="secondary" style={{ width: 50, display: 'inline-block' }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? atHandJson[1]['data']['value']['使用平台购物券活动价'] : atHandJson[1]['data']['value']['活动价'] : ''}</Text>
                        <Text strong >极限到手价:</Text>
                        <Input 
                        // 键盘事件
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                if(changeHandValue != ''){
                                    // console.log(columnsIndex);
                                    // console.log({ "到手价": parseInt(changeHandValue) });
                                    // console.log(dataIndex);
                                    // console.log(data[dataIndex]);
                                    // console.log(columnsIndex);
                                    // console.log(changeHandValue);
                                    let spu = data[dataIndex]['spu']
                                    let goods_id = data[dataIndex]['goods_id']
                                    let log = `操作spu:${spu} - id:${goods_id}这款商品，修改${columnsIndex}区间的第${index+1}个参加价格的到手价，将到手价修改为${changeHandValue}.`
                                    cookie.save('changeLog', log, { path: '/' })
                                    let is_use_shop_coupon = 0
                                    // 判断是否有使用店铺券
                                    if(hasShopCoupon == 1){
                                        // 使用了店铺券
                                        // 判断是否使用平台购物券
                                        if(data[dataIndex]['is_use_platform_coupon']){
                                            // 要是使用平台购物券的时候
                                            if(data[dataIndex][columnsIndex]['priceList'][index]['value']['使用平台购物券商品券凑10'] != 0){
                                                // 商品券不为0的时候
                                                is_use_shop_coupon = 0
                                            }else{
                                                // 商品券为0的时候
                                                is_use_shop_coupon = 1
                                            }
                                        }else{
                                            // 要是不使用平台购物券的时候
                                            if(data[dataIndex][columnsIndex]['priceList'][index]['value']['商品券凑10'] != 0){
                                                // 商品券不为0的时候
                                                is_use_shop_coupon = 0
                                            }else{
                                                // 商品券为0的时候
                                                is_use_shop_coupon = 1
                                            }
                                        }
                                    }
                                    let result = calculatePrice(data[dataIndex]['is_use_platform_coupon'], is_use_shop_coupon ,data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { '到手价': parseInt(changeHandValue) }, columnsIndex)
                                    // console.log(result);
                                    let AtHandObj = [...atHandJson]
                                    AtHandObj = result
                                    setAtHandJson(AtHandObj)
                                }else{
                                    message.warn('请先填写您想要的到手价')
                                }
                            }
                        }}
                        value={changeHandValue} style={{ width: 80, height: 20, marginRight: 10, marginLeft: 10, }} onChange={(e) => {
                            setChangeHandValue(e.target.value.replace(/[^\d^\.]+/g,''))
                        }} suffix={<SearchOutlined onClick={() => {
                            if(changeHandValue != ''){
                                // console.log(columnsIndex);
                                // console.log({ "到手价": parseInt(changeHandValue) });
                                // console.log(dataIndex);
                                // console.log(data[dataIndex]);
                                // console.log(columnsIndex);
                                // console.log(changeHandValue);
                                let spu = data[dataIndex]['spu']
                                let goods_id = data[dataIndex]['goods_id']
                                let log = `操作spu:${spu} - id:${goods_id}这款商品，修改${columnsIndex}区间的第${index+1}个参加价格的到手价，将到手价修改为${changeHandValue}；`
                                cookie.save('changeLog', log, { path: '/' })
                                let is_use_shop_coupon = 0
                                // 判断是否有使用店铺券
                                if(hasShopCoupon == 1){
                                    // 使用了店铺券
                                    // 判断是否使用平台购物券
                                    if(data[dataIndex]['is_use_platform_coupon']){
                                        // 要是使用平台购物券的时候
                                        if(data[dataIndex][columnsIndex]['priceList'][index]['value']['使用平台购物券商品券凑10'] != 0){
                                            // 商品券不为0的时候
                                            is_use_shop_coupon = 0
                                        }else{
                                            // 商品券为0的时候
                                            is_use_shop_coupon = 1
                                        }
                                    }else{
                                        // 要是不使用平台购物券的时候
                                        if(data[dataIndex][columnsIndex]['priceList'][index]['value']['商品券凑10'] != 0){
                                            // 商品券不为0的时候
                                            is_use_shop_coupon = 0
                                        }else{
                                            // 商品券为0的时候
                                            is_use_shop_coupon = 1
                                        }
                                    }
                                }
                                let result = calculatePrice(data[dataIndex]['is_use_platform_coupon'], is_use_shop_coupon ,data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { '到手价': parseInt(changeHandValue) }, columnsIndex)
                                // console.log(result);
                                let AtHandObj = [...atHandJson]
                                AtHandObj = result
                                setAtHandJson(AtHandObj)
                            }else{
                                message.warn('请先填写您想要的到手价')
                            }
                            
                        }} />} />

                    </div>
                    <div>
                        <Checkbox disabled={atHandJson[2]['data']['value']['活动价'] == '' ? true : false} style={{ fontSize: 12 }} checked={atHandJson[2]['check']} onChange={(e) => {
                            if (e.target.checked) {
                                // 选中
                                let obj = [...atHandJson]
                                obj[0]['check'] = 0
                                obj[2]['check'] = 1
                                setAtHandJson(obj)
                            } else {
                                // 未选中
                                let obj = [...atHandJson]
                                obj[2]['check'] = 0
                                setAtHandJson(obj)
                            }
                        }}>
                            <Text strong>活动价:</Text>
                            <Text type="secondary" style={{ width: 50, display: 'inline-block' }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? atHandJson[2]['data']['value']['使用平台购物券活动价'] : atHandJson[2]['data']['value']['活动价'] : ''}</Text>
                            <Text strong>到手价:</Text>
                            <Text type="secondary" style={{ width: 50, display: 'inline-block' }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? atHandJson[2]['data']['value']['使用平台购物券到手价'] : atHandJson[2]['data']['value']['到手价'] : ''}</Text>
                        </Checkbox>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 10 }}>
                        <Button type="primary" style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} onClick={() => {
                            let obj = {}
                            atHandJson.map(item => {
                                if (item.check) {
                                    obj = item
                                }
                            })
                            if (Object.keys(obj).length != 0) {
                                // 当前选择的活动价
                                let nowActivityValue = data[dataIndex]['is_use_platform_coupon'] ? obj['data']['value']['使用平台购物券活动价'] : obj['data']['value']['活动价']
                                // 判断活动价是否高于吊牌价
                                // 如果活动价高于吊牌价则强提醒
                                if (nowActivityValue <= tagPrice) {
                                    let dataObj = [...data]
                                    dataObj[dataIndex][columnsIndex]['priceList'][index] = obj['data']
                                    setData(dataObj)
                                    let atHandJsonData = [...atHandJson]
                                    setAtHandJson([])
                                    atHandJsonData = [
                                        {
                                            'data': {
                                                "value": {
                                                    "活动价": '',
                                                    "使用平台购物券活动价": '',
                                                    "到手价": '',
                                                    '使用平台购物券到手价': ''
                                                },
                                            },
                                            'check': 0
                                        },
                                        {
                                            'data': {
                                                "value": {
                                                    "活动价": '',
                                                    "使用平台购物券活动价": '',
                                                    "到手价": '',
                                                    '使用平台购物券到手价': ''
                                                },
                                            },
                                        },
                                        {
                                            'data': {
                                                "value": {
                                                    "活动价": '',
                                                    "使用平台购物券活动价": '',
                                                    "到手价": '',
                                                    '使用平台购物券到手价': ''
                                                },
                                            },
                                            'check': 0
                                        }
                                    ]
                                    setAtHandJson(atHandJsonData)
                                    setChangeHandValue('')
                                    setIsChangeHandValue(false)
                                }else{
                                    message.error('活动价不能高于吊牌价')
                                }

                            } else {
                                message.warn({
                                    content: '请选择一个方案'
                                })
                            }

                        }}>确定</Button>
                        <Button style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" danger onClick={() => {
                            // cookie.
                            let dataObj = [...data]
                            dataObj[dataIndex][columnsIndex]['priceList'][index] = cookie.load('datacache')
                            setData(dataObj)
                            setIsChangeHandValue(false)
                            setAtHandJson([
                                {
                                    'data': {
                                        "value": {
                                            "活动价": '',
                                            "使用平台购物券活动价": '',
                                            "到手价": '',
                                            '使用平台购物券到手价': ''
                                        },
                                    },
                                    'check': 0
                                },
                                {
                                    'data': {
                                        "value": {
                                            "活动价": '',
                                            "使用平台购物券活动价": '',
                                            "到手价": '',
                                            '使用平台购物券到手价': ''
                                        },
                                    },
                                },
                                {
                                    'data': {
                                        "value": {
                                            "活动价": '',
                                            "使用平台购物券活动价": '',
                                            "到手价": '',
                                            '使用平台购物券到手价': ''
                                        },
                                    },
                                    'check': 0
                                }
                            ])
                            setChangeHandValue('')
                        }}>取消</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                title="修改活动价"
                visible={isChangeActivityValue}
                footer={[]}
                closable={false}
                onCancel={() => {
                    setIsChangeActivityValue(false)
                    setChangeActivityValue('')
                    setActivityJson({
                        "value": {
                            "活动价": '',
                            "使用平台购物券活动价": '',
                            "到手价": '',
                            '使用平台购物券到手价': ''
                        },
                    })
                }}
                centered={true}
                zIndex={99999}
                width={400}
                mask={false}
            >
                <div style={{ fontSize: 12 }}>
                    <div style={{ display: 'flex' }}>
                        <Text strong>活动价:</Text>
                        <Input 
                            onKeyDown={(e)=>{
                                if(e.keyCode == 13){
                                    if(changeActivityValue != ''){
                                        if(changeActivityValue <= tagPrice){
                                            let spu = data[dataIndex]['spu']
                                            let goods_id = data[dataIndex]['goods_id']
                                            let log = `操作spu:${spu} - id:${goods_id}这款商品，修改${columnsIndex}区间的第${index+1}个参加价格的活动价，将活动价修改为${changeActivityValue}.`
                                            cookie.save('changeLog', log, { path: '/' })
                                            console.log(data[dataIndex]['is_use_platform_coupon'], data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { '活动价': parseInt(changeActivityValue) }, columnsIndex);
                                            let is_use_shop_coupon = 0
                                            // 判断是否有使用店铺券
                                            if(hasShopCoupon == 1){
                                                // 使用了店铺券
                                                // 判断是否使用平台购物券
                                                if(data[dataIndex]['is_use_platform_coupon']){
                                                    // 要是使用平台购物券的时候
                                                    if(data[dataIndex][columnsIndex]['priceList'][index]['value']['使用平台购物券商品券凑10'] != 0){
                                                        // 商品券不为0的时候
                                                        is_use_shop_coupon = 0
                                                    }else{
                                                        // 商品券为0的时候
                                                        is_use_shop_coupon = 1
                                                    }
                                                }else{
                                                    // 要是不使用平台购物券的时候
                                                    if(data[dataIndex][columnsIndex]['priceList'][index]['value']['商品券凑10'] != 0){
                                                        // 商品券不为0的时候
                                                        is_use_shop_coupon = 0
                                                    }else{
                                                        // 商品券为0的时候
                                                        is_use_shop_coupon = 1
                                                    }
                                                }
                                            }
                                            let result = calculatePrice(data[dataIndex]['is_use_platform_coupon'], is_use_shop_coupon ,data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { '活动价': parseInt(changeActivityValue) }, columnsIndex)
                                            setActivityJson(result)
                                        }else{
                                            message.error('活动价不能高于吊牌价')
                                        }
                                      
                                    }else{
                                        message.warn('请先填写您想要的活动价')
                                    } 
                                }
                            }}
                            value={changeActivityValue} style={{ width: 80, height: 20, marginRight: 10, marginLeft: 10 }}
                            onChange={(e) => {
                                setChangeActivityValue(e.target.value.replace(/[^\d^\.]+/g,''))
                            }}
                            suffix={<SearchOutlined onClick={() => {
                                if(changeActivityValue != ''){
                                    if(changeActivityValue <= tagPrice){
                                        let spu = data[dataIndex]['spu']
                                        let goods_id = data[dataIndex]['goods_id']
                                        let log = `操作spu:${spu} - id:${goods_id}这款商品，修改${columnsIndex}区间的第${index+1}个参加价格的活动价，将活动价修改为${changeActivityValue}.`
                                        cookie.save('changeLog', log, { path: '/' })
                                        console.log(data[dataIndex]['is_use_platform_coupon'], data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { '活动价': parseInt(changeActivityValue) }, columnsIndex);
                                        let is_use_shop_coupon = 0
                                        // 判断是否有使用店铺券
                                        if(hasShopCoupon == 1){
                                            // 使用了店铺券
                                            // 判断是否使用平台购物券
                                            if(data[dataIndex]['is_use_platform_coupon']){
                                                // 要是使用平台购物券的时候
                                                if(data[dataIndex][columnsIndex]['priceList'][index]['value']['使用平台购物券商品券凑10'] != 0){
                                                    // 商品券不为0的时候
                                                    is_use_shop_coupon = 0
                                                }else{
                                                    // 商品券为0的时候
                                                    is_use_shop_coupon = 1
                                                }
                                            }else{
                                                // 要是不使用平台购物券的时候
                                                if(data[dataIndex][columnsIndex]['priceList'][index]['value']['商品券凑10'] != 0){
                                                    // 商品券不为0的时候
                                                    is_use_shop_coupon = 0
                                                }else{
                                                    // 商品券为0的时候
                                                    is_use_shop_coupon = 1
                                                }
                                            }
                                        }
                                        let result = calculatePrice(data[dataIndex]['is_use_platform_coupon'], is_use_shop_coupon ,data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { '活动价': parseInt(changeActivityValue) }, columnsIndex)
                                        setActivityJson(result)
                                    }else{
                                        message.error('活动价不能高于吊牌价')
                                    }
                                  
                                }else{
                                    message.warn('请先填写您想要的活动价')
                                }
                            }} />} />
                        <Text strong>到手价:</Text>
                        <Text type="secondary" style={{ marginRight: 10, marginLeft: 10 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? activityJson['value']['使用平台购物券到手价'] : activityJson['value']['到手价'] : ''}</Text>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 10 }}>
                        <Button disabled={activityJson['value']['到手价'] == '' ? true : false} style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" onClick={() => {
                            let dataObj = [...data]
                            dataObj[dataIndex][columnsIndex]['priceList'][index] = activityJson
                            setData(dataObj)
                            setChangeActivityValue('')
                            setActivityJson({
                                "value": {
                                    "活动价": '',
                                    "使用平台购物券活动价": '',
                                    "到手价": '',
                                    '使用平台购物券到手价': ''
                                },
                            })
                            setIsChangeActivityValue(false)
                        }}>确定</Button>
                        <Button style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" danger onClick={() => {
                            setChangeActivityValue('')
                            setActivityJson({
                                "value": {
                                    "活动价": '',
                                    "使用平台购物券活动价": '',
                                    "到手价": '',
                                    '使用平台购物券到手价': ''
                                },
                            })
                            setIsChangeActivityValue(false)
                        }}>取消</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                title="批量修改单件折扣"
                visible={isBatchChangeSinglePieceDiscount}
                footer={[]}
                closable={false}
                mask={false}
                onCancel={()=>{
                    setChangeSinglePieceDiscount('')
                    setIsBatchChangeSinglePieceDiscount(false)
                }}
                centered={true}
                zIndex={99999}
                width={400}
            >
                <div style={{ fontSize: 12 }}>
                    <div style={{ display: 'flex', marginTop:5 }}>
                        <Text strong>单件折扣:</Text>
                        <Input value={changeSinglePieceDiscount} style={{ width: 80, height: 20, marginRight: 10, marginLeft: 10 }}
                        onChange={(e) => {
                            setChangeSinglePieceDiscount(e.target.value.replace(/[^\d^\.]+/g,''))
                        }}/>
                        <Text type="danger">{"(如: 8折 -> 填80、75折 -> 填75)"}</Text>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 10 }}>
                        <Button disabled={changeSinglePieceDiscount == "" || effective ? true : false} style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" onClick={() => {
                            let key = "update_price_list"
                            setEffective(1)
                            message.loading({ content: '正在批量修改单件折扣...', key, duration: 200000 })
                            console.log(changeSinglePieceDiscount);
                            let changedSinglePieceDiscount = (100 - changeSinglePieceDiscount) / 100
                            console.log(changedSinglePieceDiscount);
                            console.log(selectedRow);
                            selectedRow.map(item=>{
                                let is_use_shop_coupon = 0
                                // 判断是否有使用店铺券
                                if(hasShopCoupon == 1){
                                    // 使用了店铺券
                                    // 判断是否使用平台购物券
                                    if(item['is_use_platform_coupon']){
                                        // 要是使用平台购物券的时候
                                        if(item['不参与多件']['priceList'][1]['value']['使用平台购物券商品券凑10'] != 0){
                                            // 商品券不为0的时候
                                            is_use_shop_coupon = 0
                                        }else{
                                            // 商品券为0的时候
                                            is_use_shop_coupon = 1
                                        }
                                    }else{
                                        // 要是不使用平台购物券的时候
                                        if(item['不参与多件']['priceList'][1]['value']['商品券凑10'] != 0){
                                            // 商品券不为0的时候
                                            is_use_shop_coupon = 0
                                        }else{
                                            // 商品券为0的时候
                                            is_use_shop_coupon = 1
                                        }
                                    }
                                }
                                // let result = calculatePrice(data[dataIndex]['is_use_platform_coupon'],  is_use_shop_coupon ,data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { "单件折扣": changedSinglePieceDiscount }, columnsIndex)
                                let result = calculatePrice(item['is_use_platform_coupon'],  is_use_shop_coupon ,item['basicInformation'], item['不参与多件']['priceList'][1], { "单件折扣": changedSinglePieceDiscount }, '不参与多件')
                                item['不参与多件']['priceList'][1]=result
                            })
                            console.log(selectedRow);
                            let dataProps = {
                                'url': 'update_price_list',
                                'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                'name': selectionPlanName,
                                'data_dict_list': selectedRow,
                                'special_area': '不参与多件',
                                'special_area_index': 1,
                                'change_log': "批量修改单件折扣",
                                'table_head': specialArea,
                                'creator':cookie.load('name')
                            }
                            console.log(dataProps);
                            ws.send(JSON.stringify(dataProps))
                            ws.onmessage = e => {
                                setEffective(0)
                                let mes = JSON.parse(e.data)
                                if (mes['success']) {
                                    message.success({ content: '批量修改单件折扣成功...', key })
                                    setChangeSinglePieceDiscount('')
                                    setIsBatchChangeSinglePieceDiscount(false)
                                    // 重新渲染页面
                                    getAllData()
                                } else {
                                    message.warn({ content: mes['errorMsg'], key })
                                }
                            }
                        }}>确定</Button>
                        <Button style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" danger onClick={() => {
                            setChangeSinglePieceDiscount('')
                            setIsBatchChangeSinglePieceDiscount(false)
                        }}>取消</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                title="修改单件折扣"
                visible={isChangeSinglePieceDiscount}
                footer={[]}
                closable={false}
                mask={false}
                onCancel={() => {
                    setChangeSinglePieceDiscount('')
                    setSinglePieceDiscountJson({
                        "value": {
                            "活动价": '',
                            "使用平台购物券活动价": '',
                            "到手价": '',
                            "使用平台购物券到手价": '',
                            "到手折扣": '',
                            "使用平台购物券到手折扣": '',
                            "到手价-底线价": '',
                            "使用平台购物券到手价-底线价": '',
                            "极限到手价": '',
                            "使用平台购物券极限到手价": '',
                            "极限到手折扣": '',
                            "使用平台购物券极限到手折扣": '',
                            "使用平台购物券极限到手价-底线价": '',
                            "极限到手价-底线价": '',
                        },
                    })
                    setIsChangeSinglePieceDiscount(false)
                }}
                centered={true}
                zIndex={99999}
                width={400}
            >
                <div style={{ fontSize: 12 }}>
                    <div>
                        <Text type="danger">注意: 变更单件折扣时，活动价保持不变</Text>
                    </div>
                    <div style={{ display: 'flex', marginTop:5 }}>
                        <Text strong>单件折扣:</Text>
                        {/* changeCommodityVoucher */}
                        <Input 
                        // 键盘事件
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                if (changeSinglePieceDiscount != '' && changeSinglePieceDiscount.toString().indexOf('.') == -1 && changeSinglePieceDiscount <= 100) {
                                    console.log(changeSinglePieceDiscount);
                                    let changedSinglePieceDiscount = (100 - changeSinglePieceDiscount) / 100
                                    console.log(changedSinglePieceDiscount);
                                    let spu = data[dataIndex]['spu']
                                    let goods_id = data[dataIndex]['goods_id']
                                    let log = `操作spu:${spu} - id:${goods_id}这款商品，修改${columnsIndex}区间的第${index+1}个参加价格的单件折扣，将单件折扣修改为${changedSinglePieceDiscount}.`
                                    cookie.save('changeLog', log, { path: '/' })
                                    let is_use_shop_coupon = 0
                                    // 判断是否有使用店铺券
                                    if(hasShopCoupon == 1){
                                        // 使用了店铺券
                                        // 判断是否使用平台购物券
                                        if(data[dataIndex]['is_use_platform_coupon']){
                                            // 要是使用平台购物券的时候
                                            if(data[dataIndex][columnsIndex]['priceList'][index]['value']['使用平台购物券商品券凑10'] != 0){
                                                // 商品券不为0的时候
                                                is_use_shop_coupon = 0
                                            }else{
                                                // 商品券为0的时候
                                                is_use_shop_coupon = 1
                                            }
                                        }else{
                                            // 要是不使用平台购物券的时候
                                            if(data[dataIndex][columnsIndex]['priceList'][index]['value']['商品券凑10'] != 0){
                                                // 商品券不为0的时候
                                                is_use_shop_coupon = 0
                                            }else{
                                                // 商品券为0的时候
                                                is_use_shop_coupon = 1
                                            }
                                        }
                                    }
                                    let result = calculatePrice(data[dataIndex]['is_use_platform_coupon'],  is_use_shop_coupon ,data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { "单件折扣": changedSinglePieceDiscount }, columnsIndex)
                                    console.log(result);
                                    setSinglePieceDiscountJson(result)
                                } else {
                                    message.warn('请先输入正确的单件折扣')
                                }
                            }
                        }} 
                        value={changeSinglePieceDiscount} style={{ width: 80, height: 20, marginRight: 10, marginLeft: 10 }}
                        onChange={(e) => {
                            setChangeSinglePieceDiscount(e.target.value.replace(/[^\d^\.]+/g,''))
                        }}
                        suffix={<SearchOutlined onClick={() => {
                            if (changeSinglePieceDiscount != '' && changeSinglePieceDiscount.toString().indexOf('.') == -1 && changeSinglePieceDiscount <= 100) {
                                console.log(changeSinglePieceDiscount);
                                let changedSinglePieceDiscount = (100 - changeSinglePieceDiscount) / 100
                                console.log(changedSinglePieceDiscount);
                                let spu = data[dataIndex]['spu']
                                let goods_id = data[dataIndex]['goods_id']
                                let log = `操作spu:${spu} - id:${goods_id}这款商品，修改${columnsIndex}区间的第${index+1}个参加价格的单件折扣，将单件折扣修改为${changedSinglePieceDiscount}.`
                                cookie.save('changeLog', log, { path: '/' })
                                let is_use_shop_coupon = 0
                                // 判断是否有使用店铺券
                                if(hasShopCoupon == 1){
                                    // 使用了店铺券
                                    // 判断是否使用平台购物券
                                    if(data[dataIndex]['is_use_platform_coupon']){
                                        // 要是使用平台购物券的时候
                                        if(data[dataIndex][columnsIndex]['priceList'][index]['value']['使用平台购物券商品券凑10'] != 0){
                                            // 商品券不为0的时候
                                            is_use_shop_coupon = 0
                                        }else{
                                            // 商品券为0的时候
                                            is_use_shop_coupon = 1
                                        }
                                    }else{
                                        // 要是不使用平台购物券的时候
                                        if(data[dataIndex][columnsIndex]['priceList'][index]['value']['商品券凑10'] != 0){
                                            // 商品券不为0的时候
                                            is_use_shop_coupon = 0
                                        }else{
                                            // 商品券为0的时候
                                            is_use_shop_coupon = 1
                                        }
                                    }
                                }
                                let result = calculatePrice(data[dataIndex]['is_use_platform_coupon'],  is_use_shop_coupon ,data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { "单件折扣": changedSinglePieceDiscount }, columnsIndex)
                                console.log(result);
                                setSinglePieceDiscountJson(result)
                            } else {
                                message.warn('请先输入正确的单件折扣')
                            }

                        }} />}
                        />
                        <Text type="danger">{"(如: 8折 -> 填80、75折 -> 填75)"}</Text>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ width: "50%" }}>
                            <Text strong>活动价:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? singlePieceDiscount['value']['使用平台购物券活动价'] : singlePieceDiscount['value']['活动价'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>到手价:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? singlePieceDiscount['value']['使用平台购物券到手价'] : singlePieceDiscount['value']['到手价'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>到手折扣:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? singlePieceDiscount['value']['使用平台购物券到手折扣'] : singlePieceDiscount['value']['到手折扣'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>到手价-底线价:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? singlePieceDiscount['value']['使用平台购物券到手价-底线价'] : singlePieceDiscount['value']['到手价-底线价'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>极限到手价:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? singlePieceDiscount['value']['使用平台购物券极限到手价'] : singlePieceDiscount['value']['极限到手价'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>极限到手折扣:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? singlePieceDiscount['value']['使用平台购物券极限到手折扣'] : singlePieceDiscount['value']['极限到手折扣'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>极限到手价-底线价:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? singlePieceDiscount['value']['使用平台购物券极限到手价-底线价'] : singlePieceDiscount['value']['极限到手价-底线价'] : ''}</Text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 10 }}>
                        <Button disabled={singlePieceDiscount['value']['到手价'] == '' ? true : false} style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" onClick={() => {
                            let dataObj = [...data]
                            dataObj[dataIndex][columnsIndex]['priceList'][index] = singlePieceDiscount
                            setData(dataObj)
                            setChangeSinglePieceDiscount('')
                            setSinglePieceDiscountJson({
                                "value": {
                                    "活动价": '',
                                    "使用平台购物券活动价": '',
                                    "到手价": '',
                                    "使用平台购物券到手价": '',
                                    "到手折扣": '',
                                    "使用平台购物券到手折扣": '',
                                    "到手价-底线价": '',
                                    "使用平台购物券到手价-底线价": '',
                                    "极限到手价": '',
                                    "使用平台购物券极限到手价": '',
                                    "极限到手折扣": '',
                                    "使用平台购物券极限到手折扣": '',
                                    "使用平台购物券极限到手价-底线价": '',
                                    "极限到手价-底线价": '',
                                },
                            })
                            setIsChangeSinglePieceDiscount(false)
                        }}>确定</Button>
                        <Button style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" danger onClick={() => {
                            setChangeSinglePieceDiscount('')
                            setSinglePieceDiscountJson({
                                "value": {
                                    "活动价": '',
                                    "使用平台购物券活动价": '',
                                    "到手价": '',
                                    "使用平台购物券到手价": '',
                                    "到手折扣": '',
                                    "使用平台购物券到手折扣": '',
                                    "到手价-底线价": '',
                                    "使用平台购物券到手价-底线价": '',
                                    "极限到手价": '',
                                    "使用平台购物券极限到手价": '',
                                    "极限到手折扣": '',
                                    "使用平台购物券极限到手折扣": '',
                                    "使用平台购物券极限到手价-底线价": '',
                                    "极限到手价-底线价": '',
                                },
                            })
                            setIsChangeSinglePieceDiscount(false)
                        }}>取消</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                title="修改商品券"
                visible={isChangeCommodityVoucher}
                footer={[]}
                closable={false}
                mask={false}
                onCancel={() => {
                    setChangeCommodityVoucher('')
                    setCommodityVoucherJson({
                        "value": {
                            "活动价": '',
                            "使用平台购物券活动价": '',
                            "到手价": '',
                            "使用平台购物券到手价": '',
                            "到手折扣": '',
                            "使用平台购物券到手折扣": '',
                            "到手价-底线价": '',
                            "使用平台购物券到手价-底线价": '',
                            "极限到手价": '',
                            "使用平台购物券极限到手价": '',
                            "极限到手折扣": '',
                            "使用平台购物券极限到手折扣": '',
                            "使用平台购物券极限到手价-底线价": '',
                            "极限到手价-底线价": '',
                        },
                    })
                    setIsChangeCommodityVoucher(false)
                }}
                centered={true}
                zIndex={99999}
                width={400}
            >
                <div style={{ fontSize: 12 }}>
                    <div>
                        <Text type="danger">注意: 变更商品券时，活动价保持不变.</Text>
                    </div>
                    <div>
                        <Text type="danger">由于商品券与店铺券使用互斥，商品券设置为0时，价格计算将包含店铺券；商品券设置不为0时，价格计算将不包含店铺券；</Text>
                    </div>
                    <div style={{ display: 'flex', marginTop:5 }}>
                        <Text strong>商品券:</Text>
                        {/* changeCommodityVoucher */}
                        <Input 
                            // 键盘事件
                            onKeyDown={(e)=>{
                                if(e.keyCode == 13){
                                    if(changeCommodityVoucher != ''){
                                        let spu = data[dataIndex]['spu']
                                        let goods_id = data[dataIndex]['goods_id']
                                        let log = `操作spu:${spu} - id:${goods_id}这款商品，修改${columnsIndex}区间的第${index+1}个参加价格的商品券，将商品券修改为${changeHandValue}.`
                                        cookie.save('changeLog', log, { path: '/' })
                                        console.log(data[dataIndex]['is_use_platform_coupon'], data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { '商品券': changeCommodityVoucher }, columnsIndex);
                                        let is_use_shop_coupon = 0
                                        // 判断是否有使用店铺券
                                        if(hasShopCoupon == 1){
                                            // 使用了店铺券
                                            // 判断是否使用平台购物券
                                            if(data[dataIndex]['is_use_platform_coupon']){
                                                // 要是使用平台购物券的时候
                                                if(data[dataIndex][columnsIndex]['priceList'][index]['value']['使用平台购物券商品券凑10'] != 0){
                                                    // 商品券不为0的时候
                                                    is_use_shop_coupon = 0
                                                }else{
                                                    // 商品券为0的时候
                                                    is_use_shop_coupon = 1
                                                }
                                            }else{
                                                // 要是不使用平台购物券的时候
                                                if(data[dataIndex][columnsIndex]['priceList'][index]['value']['商品券凑10'] != 0){
                                                    // 商品券不为0的时候
                                                    is_use_shop_coupon = 0
                                                }else{
                                                    // 商品券为0的时候
                                                    is_use_shop_coupon = 1
                                                }
                                            }
                                        }
                                        let result = calculatePrice(data[dataIndex]['is_use_platform_coupon'],  is_use_shop_coupon ,data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { '商品券': changeCommodityVoucher }, columnsIndex)
                                        setCommodityVoucherJson(result)
                                    }else{
                                        message.warn('请先填写您想要的商品券')
                                    }
                                }
                            }}
                            value={changeCommodityVoucher} style={{ width: 80, height: 20, marginRight: 10, marginLeft: 10 }}
                            onChange={(e) => {
                                setChangeCommodityVoucher(e.target.value.replace(/[^\d^\.]+/g,''))
                            }}
                            suffix={<SearchOutlined onClick={() => {
                                if(changeCommodityVoucher != ''){
                                    let spu = data[dataIndex]['spu']
                                    let goods_id = data[dataIndex]['goods_id']
                                    let log = `操作spu:${spu} - id:${goods_id}这款商品，修改${columnsIndex}区间的第${index+1}个参加价格的商品券，将商品券修改为${changeHandValue}.`
                                    cookie.save('changeLog', log, { path: '/' })
                                    console.log(data[dataIndex]['is_use_platform_coupon'], data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { '商品券': changeCommodityVoucher }, columnsIndex);
                                    let is_use_shop_coupon = 0
                                    // 判断是否有使用店铺券
                                    if(hasShopCoupon == 1){
                                        // 使用了店铺券
                                        // 判断是否使用平台购物券
                                        if(data[dataIndex]['is_use_platform_coupon']){
                                            // 要是使用平台购物券的时候
                                            if(data[dataIndex][columnsIndex]['priceList'][index]['value']['使用平台购物券商品券凑10'] != 0){
                                                // 商品券不为0的时候
                                                is_use_shop_coupon = 0
                                            }else{
                                                // 商品券为0的时候
                                                is_use_shop_coupon = 1
                                            }
                                        }else{
                                            // 要是不使用平台购物券的时候
                                            if(data[dataIndex][columnsIndex]['priceList'][index]['value']['商品券凑10'] != 0){
                                                // 商品券不为0的时候
                                                is_use_shop_coupon = 0
                                            }else{
                                                // 商品券为0的时候
                                                is_use_shop_coupon = 1
                                            }
                                        }
                                    }
                                    let result = calculatePrice(data[dataIndex]['is_use_platform_coupon'],  is_use_shop_coupon ,data[dataIndex]['basicInformation'], data[dataIndex][columnsIndex]['priceList'][index], { '商品券': changeCommodityVoucher }, columnsIndex)
                                    setCommodityVoucherJson(result)
                                }else{
                                    message.warn('请先填写您想要的商品券')
                                }
                               
                            }} />}
                        />
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ width: "50%" }}>
                            <Text strong>活动价:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? commodityVoucherJson['value']['使用平台购物券活动价'] : commodityVoucherJson['value']['活动价'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>到手价:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? commodityVoucherJson['value']['使用平台购物券到手价'] : commodityVoucherJson['value']['到手价'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>到手折扣:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? commodityVoucherJson['value']['使用平台购物券到手折扣'] : commodityVoucherJson['value']['到手折扣'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>到手价-底线价:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? commodityVoucherJson['value']['使用平台购物券到手价-底线价'] : commodityVoucherJson['value']['到手价-底线价'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>极限到手价:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? commodityVoucherJson['value']['使用平台购物券极限到手价'] : commodityVoucherJson['value']['极限到手价'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>极限到手折扣:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? commodityVoucherJson['value']['使用平台购物券极限到手折扣'] : commodityVoucherJson['value']['极限到手折扣'] : ''}</Text>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Text strong>极限到手价-底线价:</Text>
                            <Text type="secondary" style={{ width: 100 }}>{data[dataIndex] != undefined ? data[dataIndex]['is_use_platform_coupon'] ? commodityVoucherJson['value']['使用平台购物券极限到手价-底线价'] : commodityVoucherJson['value']['极限到手价-底线价'] : ''}</Text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 10 }}>
                        <Button disabled={commodityVoucherJson['value']['到手价'] == '' ? true : false} style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" onClick={() => {
                            let dataObj = [...data]
                            dataObj[dataIndex][columnsIndex]['priceList'][index] = commodityVoucherJson
                            setData(dataObj)
                            setChangeCommodityVoucher('')
                            setCommodityVoucherJson({
                                "value": {
                                    "活动价": '',
                                    "使用平台购物券活动价": '',
                                    "到手价": '',
                                    "使用平台购物券到手价": '',
                                    "到手折扣": '',
                                    "使用平台购物券到手折扣": '',
                                    "到手价-底线价": '',
                                    "使用平台购物券到手价-底线价": '',
                                    "极限到手价": '',
                                    "使用平台购物券极限到手价": '',
                                    "极限到手折扣": '',
                                    "使用平台购物券极限到手折扣": '',
                                    "使用平台购物券极限到手价-底线价": '',
                                    "极限到手价-底线价": '',
                                },
                            })
                            setIsChangeCommodityVoucher(false)
                        }}>确定</Button>
                        <Button style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" danger onClick={() => {
                            setChangeCommodityVoucher('')
                            setCommodityVoucherJson({
                                "value": {
                                    "活动价": '',
                                    "使用平台购物券活动价": '',
                                    "到手价": '',
                                    "使用平台购物券到手价": '',
                                    "到手折扣": '',
                                    "使用平台购物券到手折扣": '',
                                    "到手价-底线价": '',
                                    "使用平台购物券到手价-底线价": '',
                                    "极限到手价": '',
                                    "使用平台购物券极限到手价": '',
                                    "极限到手折扣": '',
                                    "使用平台购物券极限到手折扣": '',
                                    "使用平台购物券极限到手价-底线价": '',
                                    "极限到手价-底线价": '',
                                },
                            })
                            setIsChangeCommodityVoucher(false)
                        }}>取消</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                title="是否移除该商品"
                visible={isDelete}
                onCancel={() => {
                    setIsDelete(false)
                }}
                footer={false}
                centered={true}
                width={300}
                mask={false}
            >
                <div>
                    {
                        isDelete ?
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                {/* <CommodityImage
                                    style={{ width: 65, height: 65 }}
                                    id={data[dataIndex]['goods_id']} sku={data[dataIndex]['spu']}
                                    shopName={props.history.location.pathname.split('/')[2]} index={dataIndex + 'delete'} /> */}
                                <Image src={data[dataIndex]['basicInformation']['属性图链接']} style={{ width: 65, height: 65 }} />
                                <div style={{ display: 'flex', marginLeft: 5 }}>
                                    <span>ID:</span><span>{data[dataIndex]['goods_id']}</span>
                                </div>
                                <div style={{ display: 'flex', marginLeft: 5 }}>
                                    <span>SPU:</span><span>{data[dataIndex]['spu']}</span>
                                </div>
                            </div>
                            : <span></span>
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" onClick={() => {
                        // 校验websocket是否要连接
                        if (ws.readyState !== 0) {
                            let key = "delete_commodity"
                            let dataProps = {
                                'url': 'delete_commodity',
                                'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                'name': selectionPlanName,
                                "data_dict": data[dataIndex],
                                'creator':cookie.load('name')
                            }
                            setEffective(1)
                            ws.send(JSON.stringify(dataProps))
                            message.loading({ content: '正在移除该商品...', key, duration: 100 })
                            ws.onmessage = e => {
                                setEffective(0)
                                let mes = JSON.parse(e.data)
                                if (mes['success']) {
                                    let dataObj = [...data]
                                    dataObj[dataIndex] = mes['data']
                                    setData(dataObj)
                                    setIsDelete(false)
                                    message.success({ content: '移除商品成功...', key })
                                } else {
                                    message.warn({ content: mes['errorMsg'], key })
                                }
                            }
                        }

                    }}>确定</Button>
                    <Button style={{ borderRadius: 25, marginTop: 5, fontSize: 10, height: 20, display: 'flex', alignItems: 'center' }} type="primary" danger onClick={() => {
                        setIsDelete(false)
                    }}>取消</Button>
                </div>
            </Modal>

            <Modal
                title="操作日志"
                visible={isLookLog}
                centered={true}
                footer={[]}
                onCancel={()=>{
                    setIsLookLog(false)
                }}
            >
                <Table dataSource={logData} columns={logColumns} pagination={{pageSize:10}} />
            </Modal>

            <Modal
                title="确认信息"
                visible={isConfirmInformation}
                footer={[]}
                onCancel={() => {
                    setIsConfirmInformation(false)
                }}
                centered={true}
                mask={false}
            >
                <div>
                    <div>{"本次选款共" + confirmInformation['total'] + '款商品'}</div>
                    <div>{'已确认' + confirmInformation['is_confirmed_num'] + '款，未确认' + confirmInformation['not_confirmed_num'] + '款。'}</div>
                    <div>{'参加活动' + confirmInformation['is_participate_num'] + '款，未参加活动' + confirmInformation['not_participate_num'] + '款。'}</div>
                    <div>{'使用平台优惠券' + confirmInformation['is_use_platform_coupon_num'] + '款，未使用平台优惠券' + confirmInformation['not_is_use_platform_coupon_num'] + '款。'}</div>
                </div>
                <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-around' }}>
                    <Button disabled={effective ? true : false} type="primary" onClick={() => {
                        // 校验websocket是否要连接
                        if (ws.readyState !== 0) {
                            let key = "finish_option_pricing"
                            let dataProps = {
                                'url': 'finish_option_pricing',
                                'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                'name': selectionPlanName,
                                'creator':cookie.load('name')
                            }
                            setEffective(1)
                            ws.send(JSON.stringify(dataProps))
                            message.loading({ content: '正在保存选款结果...', key, duration: 6000 })
                            ws.onmessage = e => {
                                let mes = JSON.parse(e.data)
                                if (mes['success']) {
                                    message.success({ content: '选款结果保存成功...', key })
                                    message.loading({ content: '正在计算保存汇总数据...', key, duration: 6000 })
                                    let dataProps = {
                                        "roleId": cookie.load('role'),
                                        "shopList": cookie.load('shopList'),
                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                        'name': selectionPlanName
                                    }
                                    console.log(dataProps);
                                    Axios({
                                        method: 'POST',
                                        url: servicePath.summaryCommodityData,
                                        data: dataProps,
                                        headers: {
                                            'authorization': cookie.load('token')
                                        },
                                        // 设置超时时间为15分钟
                                        timeout:900000
                                    }).then(res=>{
                                        setEffective(0)
                                        if (res.data.success) {
                                            message.success({ content: '计算保存汇总数据成功...', key })
                                            props.history.push({
                                                'pathname': `/app/${props.history.location.pathname.split('/')[2]}/selectionPricing/formalSummary`,
                                                'state': { 'name': selectionPlanName }
                                            })
                                        } else {
                                            if (res.data.errorCode === '10001') {
                                                cookie.remove("id", { path: '/' });
                                                cookie.remove("role", { path: '/' });
                                                cookie.remove("shop", { path: '/' })
                                                cookie.remove("shopName", { path: '/' })
                                                cookie.remove("shopList", { path: '/' })
                                                cookie.remove("name", { path: '/' })
                                                cookie.remove("roleName", { path: '/' })
                                                cookie.remove("email", { path: '/' })
                                                cookie.remove("phone", { path: '/' })
                                                cookie.remove("watermark", { path: '/' })
                                                cookie.remove("flag", { path: '/' })
                                                cookie.remove("token", { path: '/' })
                                                window.location.replace('https://nb20.bmcsoft.cn/')
                                            }else{
                                                message.warn({ content: res.data['errorMsg'], key })
                                            }
                                        }
                                    })
                                    // NbAxios(dataProps, "POST", servicePath.summaryCommodityData).then(res => {
                                    //     setEffective(0)
                                    //     if (res.success) {
                                    //         message.success({ content: '计算保存汇总数据成功...', key })
                                    //         props.history.push({
                                    //             'pathname': `/app/${props.history.location.pathname.split('/')[2]}/selectionPricing/formalSummary`,
                                    //             'state': { 'name': selectionPlanName }
                                    //         })
                                    //     } else {
                                    //         setEffective(0)
                                    //         message.warn({ content: res.errorMsg, key })
                                    //     }
                                    // })
                                } else {
                                    message.warn({ content: mes['errorMsg'], key })
                                }
                            }
                        }

                    }}>确认结束选款</Button>
                    <Button disabled={effective ? true : false} type="primary" danger onClick={() => {
                        // 校验websocket是否要连接
                        if (ws.readyState !== 0) {
                            let key = "query_option_pricing_commodity_list"
                            let dataProps = {
                                'url': 'query_option_pricing_commodity_list',
                                'shop_id': shopIdDict[props.history.location.pathname.split('/')[2]],
                                'name': selectionPlanName,
                                'selection_range': [{
                                    'productLine': '',
                                    'year': '',
                                    'season': '',
                                    'label': '',
                                    'maxStock': '',
                                    'minStock': '',
                                }],
                                'special_area': '',
                                'is_members': 0,
                                'is_confirmed': 0,
                                'is_use_platform_coupon': 0,
                                'creator':cookie.load('name')
                            }
                            setEffective(1)
                            ws.send(JSON.stringify(dataProps))
                            message.loading({ content: '正在查询相关商品...', key, duration: 100 })
                            ws.onmessage = e => {
                                setEffective(0)
                                let mes = JSON.parse(e.data)
                                if (mes['success']) {
                                    setSelectedRowKeys([])
                                    setSpecialArea(mes['data']['table_head']);
                                    setAllData(mes['data']['commodity_list']);
                                    setIsConfirmInformation(false)
                                    message.success({ content: '查询商品成功...', key })
                                } else {
                                    message.warn({ content: mes['errorMsg'], key })
                                }
                            }
                        }

                    }}>查询未确认商品</Button>
                </div>
            </Modal>
        </div>
    )


}


export default Index