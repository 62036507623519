import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, List, Badge, Button, Table, Modal, Tooltip, Radio, Input, Select, Form, Tabs, Tag, message, Upload, Row } from 'antd'
import { EditOutlined, PlusOutlined, CloseCircleOutlined, SearchOutlined, CommentOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl'
import axios from 'axios'
import Base64 from 'base-64'
const { Option } = Select


function Message(props){

    //数据
    const [date, setDate] = useState([])

    //页面
    const [page, setPage] = useState(1)

    // 总数
    const [total , setTotal ] = useState(0)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 20, // 每页条数
        showSizeChanger: false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 初始化数据
    useEffect(()=>{
        let dataProps = {
            'number':Base64.decode(cookie.load('number')),
            'page': page,
            'roleId': cookie.load('role'),
            'shopList': cookie.load('shopList')
        }
        axios({
            method:'POST',
            url:servicePath.getMessage,
            data:dataProps,
            headers: {
                'Content-Type': 'application/json', //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res=>{
                if(res.data['success']){
                    setDate(res.data['data'])
                    setTotal(res.data['total'])
                } else {                 
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    },[page])

    // 查看的消息
    const lookMessage = (id, workOrderId)=>{
        let dataProps = {
            'id': id,
            'roleId': cookie.load('role'),
            'shopList': cookie.load('shopList')
        }
        axios({
            method:'POST',
            url:servicePath.lookMessage,
            data:dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res=>{
                if(res.data['success']){
                    cookie.save('tabKey', 3, { path: '/' });
                    props.history.push({'pathname':'/app/work/comment','state':{'workOrderId':workOrderId}})
                } else {                 
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    return(
        <div >
            <div style={{ marginLeft: size.width * 0.15, marginRight: size.width * 0.15, marginTop:size.height*0.01 }}>
            <List
                header={<div>我的消息</div>}
                bordered 
                dataSource={date}
                pagination={paginationProps}
                renderItem={item => (
                   <Row>
                       <Badge dot={item.status == 0 ? true : false}>
                            <List.Item>
                                您的 {<a onClick={()=>{lookMessage(item.id,item.workOrderId);}} style={{fontWeight:"bold"}}>#{item.workOrderId} {item.title}</a>} 工单有新的消息({item.time})
                            </List.Item>
                       </Badge>
                   </Row>
                )}
            />
            </div>
        </div>
    )
}

export default Message