import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Menu, Row, message, Select, Avatar, Drawer, Button, Descriptions, ConfigProvider, Badge, Tooltip, Input, Col, Tabs, Card, Typography } from 'antd';
import { AppstoreAddOutlined, SmileOutlined, MailOutlined, CloseOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Route } from 'react-router-dom'
// import 'antd/dist/antd.css'
import cookie from 'react-cookies'
import colorCss from '../config/colorCss'
import servicePath from '../config/apiUrl'
import axios from 'axios';
import Base64 from 'base-64'
import Role from './system/role'
import User from './system/user'
import Shop from './system/shop'
import Menus from './system/menu'
import Port from './system/port'
import SystemIntroduce from './system/introduce'
import DataPerssiom from './system/data'
import Accredit from './system/accredit'
import Provider from './kol/provider'
import Party from './kol/party'
import KOL from './kol/kol'
import Marketing from './kol/marketing'
import Put from './kol/put'
import Retrieval from './kol/retrieval'
import Jillion from './jillion/jillion'
import JillionTask from './jillion/task'
import MarketRank from './industry/marketRank'
import ItemInfo from './base/iteminfo'
import GoodsManager from './base/goodsManager'
import JewelryItemInfo from './base/jewelry/index'
import SpiderPluginDownload from './spiderPlugin/download'
import Ciyun from './spiderPlugin/ciyun'
import WorkSystem from './work/workSystem'
import InsideWorkSystem from './work/insideSystem'
import WorkComment from './work/inside/comment'
import Introduce from './introduce/introduce'
import Z11Report from './peek/Z11/flagship'
import JrReport from './peek/jr/jrReport'
import MarkReport from './peek/markRank/markRank'
import Modal from 'antd/lib/modal/Modal'
import OrderMeeting from './oa/orderMeeting'
import PresidentDaily from './president/daily'
import DaliyCharts from './president/dailyCharts'
import OverView from './president/overview'
import BackOrders from './presell/wantSlips/backorders'
import PurchaseOrders from './presell/wantSlips/purchaseorder'
import Preview from './presell/purchase/preview'
import PresellDetails from './presell/purchase/details'
import SKUDetails from './presell/purchase/skuDetails'
import BussinessStaffDaily from './bussinessStaff/dailyResport'
import BussinessStaffDailyCharts from './bussinessStaff/dailyCharts'
import RaceShopLine from './raceShop/raceShopLine'
import RaceShopTrend from './raceShop/trend'
import RaceShop from './raceShop'
import MarkBorder from './markBorder/markBorder'
import petSupplier from './distributeSell/petSupplier'
import petCommodity from './base/pet/commodity'
import PMProject from './projectManageme/project'
import PMTeam from './projectManageme/team'
import PMTask from './projectManageme/task'
import PMReport from './projectManageme/report'
import PMMember from './projectManageme/member'
import PMTaskOverview from './projectManageme/teskOverview'
import WorkBench from './workbench';
import Promote from './kol/promote'
import LetterTip from './siteLetter/LetterTip'
// import LookLetter from './siteLetter/lookLetter'
import CommodityCrowd from './peek/woman/commodityCrowd'
import Task from './peek/task'
import DownloadCommodityCrowd from './peek/woman/downloadCommodityCrowd'
import OrderCursor from './nightCursor/orderCursor'
import MyCursor from './nightCursor/myCursor'
import CursorBase from './nightCursor/cursorBase'
import MyStudy from './nightCursor/myStudy'
import TeachCourse from './nightCursor/teachCourse'
import MonthlReport from './monthlyReport/index'
import TalentWarehouse from './recruit/talentWarehouse'
import InterView from './recruit/interview'
import RecruitmentPlan from './recruit/recruitmentPlan'
import Position from './recruit/position'
import SaleBorder from './saleBoard'
import DistributionShop from './distribution/shop'
import DistributionSummary from './distribution/summary'
import ContactUser from './milkyway'
import KolSummary from './kol/summary'
import KolPerson from './kol/kolPerson'
import GoodsReport from './goodsReport/index'
import SalesPerformance from './finance/salesPerformance'
import SalesPerformanceSummary from './finance/salesPerformanceSummary'
import SalesPerformanceShopSummary from './finance/salesPerformanceShopSummary'
import SalesTargetData from './finance/salesTargetData'
import OtmOperationModel from './otm/operationModel'
import OtmEventModel from './otm/eventModel'
import OtmSubEventModel from './otm/subEventModel'
import OtmEvent from './otm/event'
import OtmGannt from './otm/gannt'
import OtmConfirmGannt from './otm/gannt/confirm'
import OtmOperation from './otm/operate'
import SkuAnaysis from './skuAnaysis/index'
import TrafficStatistics from './trafficStatistics'
import OperationReport from './operationalReport/index'
import StandardProduct from './industry/standardProduct'
import RFMCrowdClassification from './crowdClassification/rfm'
import SampleClothes from './sampleClothes/index'
import FinanceCheckSummaryTable from './finance/management/check/summaryTable'
import FinanceCheckDisputeTable from './finance/management/check/disputeTable'
import FinanceSuject from './finance/management/suject'
import FinanceProject from './finance/management/project'
// import Apriori from './commodityDataAnalysis/apriori'
import CommodityRanking from './commodityRanking'
import CommodityDaily from './bussinessStaff/commodityDaily'
import SupplyChainDevelopment from './supplyChain/development'
import SupplyChainFabric from './supplyChain/fabric'
import SupplyChainSupplier from './supplyChain/supplier'
import KolMediaManagement from './kol/media'
import PhoneMessage from './phoneMessage'
// import QuarterlyTarget from './commodityPlanning/quarterlyTarget'
import MemberConsumption from './memberConsumption'
// import ProductCommon from './ProductCommon'
import CommodityTable from './commodityTable/index';
import GroupStatistics from './commodityTable/groupStatistics'
import DataManagement from './dataMart/dataManagement'
import DataComparison from './dataMart/dataComparison'
import DataComparisonManagement from './dataMart/dataComparisonManagement'
import DataNote from './dataMart/dataNote'
import SelectionPricingInitial from './selectionPricing/initial'
import SelectionPricingFormalBenefit from './selectionPricing/formalBenefit'
import SelectionPricingFormal from './selectionPricing/formal'
import SelectionPricingFormalSummary from './selectionPricing/formalSummary'
import Device from './device'
import ThreePetsSupplier from './threePets/supplier'
import ThreePetsFactory from './threePets/factory'
import ThreePetsOrder from './threePets/order'
import ThreePetsCommodity from './threePets/commodity'
import HalfMealDessert from './halfMeal/dessert'
import HalfMealSignUp from './halfMeal/signUp'
import CommodityOperationTable from './commodityOperationTable'
import CommodityPlanning from './commodityPlanning'
import IndustrySector from './industrySector'
import DistributionBrandProductAnalysis from './distributionBrandProductAnalysis/distributionBrandProductAnalysis'
// import GoalManagement from './goalManagement'
import CrowdAssets from './crowdAssets'
import Member from './member'
import CreationWomenExplosive from './creationWomenExplosive'
import CompetitiveTracking from './competitiveTracking'
import DailyOrderAnalysis from './dailyOrderAnalysis'
import WomenWeekly from './weekly/women'
import WomenWeeklyPreview from './weekly/women/preview.js'
import LiveDailyReport from './liveDaily/report'
import LiveDailyTarget from './liveDaily/target'
import LiveDailyCommodityOperation from './liveDaily/commodityOperation'
import LiveDailySingleItemAnalysis from './liveDaily/singleItemAnalysis'
import CommodityTop from './commodityTop'
import CrowdSelect from './haoTianMirror/crowd/select'
import CrowdAnalyze from './haoTianMirror/crowd/analyze'
// import CrowdCalculate from './haoTianMirror/crowd/calculate'
import ChoicePricingOperate from './choicePricing/operate'
import ChoicePricingInitial from './choicePricing/initial'
import ChoicePricingBenefit from './choicePricing/benefit'
import ChoicePricingSummary from './choicePricing/summary'
import PosInitial from './pos/initial'
import PosCreate from './pos/create';
import PosGift from './pos/gift';
import PosChargeBack from './pos/chargeBack';
import HumanResourcesManagement from './humanResources/management';
import HumanResourcesSummary from './humanResources/summary';
import HumanResourcesDetail from './humanResources/detail';
import HumanResourDifference from './humanResources/difference';
import HumanResourJurisdiction from './humanResources/jurisdiction';
import HumanResourChange from './humanResources/change';
import HumanResourNumber from './humanResources/number';
import HumanResourItem from './humanResources/item';
import { StateShare } from './raceShop/store/stateShare'
import { LetterShare } from './siteLetter/store/stateShare'
import { ItemInfoShare } from './goodsReport/store/itemInfoShare'
import NbAxios from '../config/utils/nbAxios'
import buriedServicePath from '../config/buriedUrl'
import uuid from '../config/utils/uuid';
import myBrowser from '../config/utils/myBrowser';
import webUrl from '../config/webUrl';
import AcceptBuried from '../config/utils/buried';
import { ReactComponent as Reminder } from '../static/image/workLogo/reminder.svg'
import ShortVideoMaterial from './shortVideo/material'
import ShortVideoPlan from './shortVideo/plan'
import KnowledgeSearch from './knowledge/search'
import KnowledaeExamine from './knowledge/examine'
import KnowledaeResult from './knowledge/result'
import StoreGroupDaily from './storeGroup/daily'
import Pos2023Create from "./pos2023/create";
import Pos2023Initial from "./pos2023/initial";
import Pos2023Search from "./pos2023/search";
import '../static/css/index.css'
import '../static/css/browser.css'
const { Footer, Sider, Header } = Layout;
const { SubMenu } = Menu
const { Option } = Select
const { TabPane } = Tabs
const { Text } = Typography
const { TextArea } = Input
function Index(props) {
    function removeByVal(arrylist , val) {
        for(var i = 0; i < arrylist .length; i++) {
            if(arrylist [i] == val) {
                arrylist .splice(i, 1);
                break;
            }
        }
    }
    // 提示是否显示
    const [ isShowReminder , setIsShowReminder] = useState(false)
    // 报告
    const [ isReport, setIsReport ] = useState(false)
    // 报告内容
    const [ reportContent , setReportContent ] = useState('')
    // 报告类型
    const [ reportType ] = useState(['数据显示错误','数据未更新','图表数据错误','数据上传失败','我有一个idea'])
    // 选择报告类型
    const [ chooseReportType , setChooseReportType ] = useState([])
    // 获取当前时间
    function getNowTime() {
        let dateTime
        let yy = new Date().getFullYear()
        let mm = new Date().getMonth() + 1
        let dd = new Date().getDate()
        let hh = new Date().getHours()
        let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes()
            :
            new Date().getMinutes()
        let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds()
            :
            new Date().getSeconds()
        dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
        console.log(dateTime)
        return dateTime
    }
    // 控制计时器
    let countTimeout
    //数组中删除指定一项数据
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };

    // 发送埋点数据
    function sendBuriedDate() {
        let keyList = []
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i); //获取本地存储的Key
            //获取所有的key
            keyList.push(key)
        }
        if (keyList.length != 0) {
            while (keyList.length > 0) {
                let key = keyList[0]
                axios({
                    method: 'PUT',
                    url: buriedServicePath.nb20DataPointWeb,
                    data: JSON.parse(localStorage.getItem(key)),
                    // data: localStorage.getItem(key),
                }).then(res => {
                    if (res.status == 201) {
                        // 接口成功接受参数，从localStorage删除对应的key
                        localStorage.removeItem(key)
                    }
                }).catch(error => {
                    // if(error.response){
                    //     if(error.response.status == 422){
                    //         console.log(error.response.data.detail);
                    //         console.log("字段缺失");
                    //     }
                    // }
                })
                // 无论成功、失败都从list移除
                keyList.remove(key)
            }
        }
    }

    // 发送在线数据
    function sendOnlineDate() {
        let timestamp = new Date().getTime();
        // 用户邮箱
        let userEmail = Base64.decode(cookie.load('email'))
        // 用户信息
        let userId = cookie.load('id')
        // 浏览器类型
        let browserType = myBrowser()
        // console.log(browserType);
        let userAgent = navigator.userAgent.toLowerCase(), s, o = {};
        // 浏览器版本
        let browserVersion = (userAgent.match(/(?:firefox|opera|safari|chrome|msie)[\/: ]([\d.]+)/))[1]
        let jsonUuid = uuid()
        let jsonData = {
            id: jsonUuid,
            trigger_time: timestamp,
            event_name: "NB在线",
            event_variable: {},
            event_type: "online",
            default_variable: {
                browser_type: browserType,
                browser_version: "97.0.4758.109",
                user_id: userId,
                user_email: userEmail,
                function_path: "/app/",
                function_module: "NB在线"
            }
        }
        axios({
            method: 'PUT',
            url: buriedServicePath.nb20DataPointWeb,
            data: jsonData,
            // data: localStorage.getItem(key),
        }).then(res => {

        }).catch(error => {
            // if(error.response){
            //     if(error.response.status == 422){
            //         console.log(error.response.data.detail);
            //         console.log("字段缺失");
            //     }
            // }
        })
    }

    // // 发送埋点数据
    // function sendBuriedDate() {
    //     console.log("===进入函数==");
    //     console.log(getNowTime());
    //     let timestamp = new Date().getTime();
    //     console.log(timestamp);
    //     let keyList = []
    //     // 定时去拿localStorage的数据
    //     console.log("===循环开始时间==");
    //     console.log(getNowTime());
    //     for (let i = 0; i < localStorage.length; i++) {
    //         let key = localStorage.key(i); //获取本地存储的Key
    //         //获取所有的key
    //         keyList.push(key)
    //     }
    //     console.log("===循环结束时间==");
    //     console.log(getNowTime());
    //     // 若这段时间没有生产埋点数据
    //     if (keyList.length == 0) {
    //         console.log("===发送空闲时间==");
    //         console.log(getNowTime());
    //         // 用户邮箱
    //         let userEmail = Base64.decode(cookie.load('email'))
    //         // 用户信息
    //         let userId = cookie.load('id')
    //         // 浏览器类型
    //         let browserType = myBrowser()
    //         // console.log(browserType);
    //         let userAgent = navigator.userAgent.toLowerCase(), s, o = {};
    //         // 浏览器版本
    //         let browserVersion = (userAgent.match(/(?:firefox|opera|safari|chrome|msie)[\/: ]([\d.]+)/))[1]
    //         let jsonUuid = uuid()
    //         let jsonData = {
    //             id: jsonUuid,
    //             trigger_time: timestamp,
    //             event_name: "NB空闲",
    //             event_variable: {},
    //             event_type: "no action",
    //             default_variable: {
    //                 browser_type: browserType,
    //                 browser_version: "97.0.4758.109",
    //                 user_id: userId,
    //                 user_email: userEmail,
    //                 function_path: "/app/",
    //                 function_module: "NB空闲"
    //             }
    //         }
    //         axios({
    //             method: 'PUT',
    //             url: buriedServicePath.nb20DataPointWeb,
    //             data: jsonData,
    //             // data: localStorage.getItem(key),
    //         }).then(res => {

    //         }).catch(error => {
    //             // if(error.response){
    //             //     if(error.response.status == 422){
    //             //         console.log(error.response.data.detail);
    //             //         console.log("字段缺失");
    //             //     }
    //             // }
    //         })
    //     } else {
    //         while (keyList.length > 0) {
    //             let key = keyList[0]
    //             axios({
    //                 method: 'PUT',
    //                 url: buriedServicePath.nb20DataPointWeb,
    //                 data: JSON.parse(localStorage.getItem(key)),
    //                 // data: localStorage.getItem(key),
    //             }).then(res => {
    //                 if (res.status == 201) {
    //                     // 接口成功接受参数，从localStorage删除对应的key
    //                     localStorage.removeItem(key)
    //                 }
    //             }).catch(error => {
    //                 // if(error.response){
    //                 //     if(error.response.status == 422){
    //                 //         console.log(error.response.data.detail);
    //                 //         console.log("字段缺失");
    //                 //     }
    //                 // }
    //             })
    //             // 无论成功、失败都从list移除
    //             keyList.remove(key)
    //         }
    //     }

    // }

    // 计时器
    const [count, setCount] = useState(11)
    // 定时发送埋点数据
    useEffect(() => {
        if (count != 0) {
            countTimeout = window.setTimeout(() => {
                if (count != 1) {
                    let nowcount = count - 1
                    setCount(nowcount)
                } else {
                    const getData = async () => {
                        await Promise.all([
                            // 发送埋点数据函数
                            sendBuriedDate(),
                            // 发送在线数据
                            sendOnlineDate()
                        ])
                    }
                    getData()
                    setCount(11)
                }

            }, 1000)
        }
    }, [count])

    // useEffect(()=>{
    //     return ()=>{
    //         // 页面离开
    //         window.clearTimeout(countTimeout)
    //         sendBuriedDate()
    //         // 清除所有localStorage
    //         localStorage.clear();
    //         // 时间戳
    //         let timestamp = new Date().getTime();
    //         // 用户邮箱
    //         let userEmail = Base64.decode(cookie.load('email'))
    //         // 用户信息
    //         let userId = cookie.load('id')
    //         // 浏览器类型
    //         let browserType = myBrowser()
    //         // console.log(browserType);
    //         let userAgent=navigator.userAgent.toLowerCase(), s, o = {};
    //         // 浏览器版本
    //         let browserVersion = (userAgent.match(/(?:firefox|opera|safari|chrome|msie)[\/: ]([\d.]+)/))[1]
    //         let jsonUuid = uuid()
    //         let jsonData = {
    //             id:jsonUuid,
    //             trigger_time : timestamp,
    //             event_name: "离开NB",
    //             event_variable: {},
    //             event_type :"leave",
    //             default_variable:{
    //                 browser_type: browserType,
    //                 browser_version: "97.0.4758.109",
    //                 user_id : userId,
    //                 user_email : userEmail,
    //                 function_path:"/app/",
    //                 function_module:"离开NB"
    //             }
    //         }
    //         axios({
    //             method: 'PUT',
    //             url: buriedServicePath.nb20DataPointWeb,
    //             data: jsonData,
    //             // data: localStorage.getItem(key),
    //         }).then(res=>{

    //         }).catch(error=>{
    //             // if(error.response){
    //             //     if(error.response.status == 422){
    //             //         console.log(error.response.data.detail);
    //             //         console.log("字段缺失");
    //             //     }
    //             // }
    //         })
    //     }
    // },[])

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.clientWidth,
        height: document.body.clientHeight
    })
    // 设置水印
    useEffect(() => {
        if (cookie.load('watermark') != 'a0a596e6-b2f7-4ee5-bb80-eb61f1824eb0') {
            let phone = ""
            let email = ""
            if (cookie.load('phone') != "" && cookie.load('email') != "") {
                phone = Base64.decode(cookie.load('phone'))
                email = Base64.decode(cookie.load('email'))
            }
            function watermark(t1, t2, t3) {
                var maxWidth = size.width;
                var maxHeight = size.height;
                var intervalWidth = 340;    //间隔宽度
                var intervalheight = 200;   //间隔高度
                var rowNumber = (maxWidth - 150) / intervalWidth;    //横向个数
                var coumnNumber = (maxHeight - 80) / intervalheight;   //纵向个数

                //默认设置
                var defaultSettings = {
                    watermark_color: '#aaa', //水印字体颜色
                    watermark_alpha: 0.4, //水印透明度
                    watermark_fontsize: '15px', //水印字体大小
                    watermark_font: '微软雅黑', //水印字体
                    watermark_width: 200, //水印宽度
                    watermark_height: 80, //水印长度
                    watermark_angle: 15 //水印倾斜度数
                };

                var _temp = document.createDocumentFragment();
                for (var i = 0; i < rowNumber; i++) {
                    for (var j = 0; j < coumnNumber; j++) {
                        var x = intervalWidth * i + 20;
                        var y = intervalheight * j + 30;
                        var mark_div = document.createElement('div');
                        mark_div.id = 'mark_div' + i + j;
                        mark_div.className = 'mark_div';
                        ///三个节点
                        var span0 = document.createElement('div');
                        span0.appendChild(document.createTextNode(t1));
                        var span1 = document.createElement('div');
                        span1.appendChild(document.createTextNode(t2));
                        var span2 = document.createElement('div');
                        span2.appendChild(document.createTextNode(t3));
                        mark_div.appendChild(span0);
                        mark_div.appendChild(span1);
                        mark_div.appendChild(span2);
                        //设置水印div倾斜显示
                        mark_div.style.webkitTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
                        mark_div.style.MozTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
                        mark_div.style.msTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
                        mark_div.style.OTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
                        mark_div.style.transform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
                        mark_div.style.visibility = "";
                        mark_div.style.position = "absolute";
                        mark_div.style.left = x + 'px';
                        mark_div.style.top = y + 'px';
                        mark_div.style.overflow = "hidden";
                        mark_div.style.zIndex = "9999";
                        mark_div.style.pointerEvents = 'none'; //pointer-events:none  让水印不阻止交互事件
                        mark_div.style.opacity = defaultSettings.watermark_alpha;
                        mark_div.style.fontSize = defaultSettings.watermark_fontsize;
                        mark_div.style.fontFamily = defaultSettings.watermark_font;
                        mark_div.style.color = defaultSettings.watermark_color;
                        mark_div.style.textAlign = "center";
                        mark_div.style.width = defaultSettings.watermark_width + 'px';
                        mark_div.style.height = defaultSettings.watermark_height + 'px';
                        mark_div.style.display = "block";

                        _temp.appendChild(mark_div);
                    }
                }
                document.body.appendChild(_temp);
            }

            watermark(cookie.load('name'), phone, email);
        }

    }, [])

    //统一无数据处理
    const customizeRenderEmpty = () => (
        <div style={{ textAlign: 'center' }}>
            <SmileOutlined style={{ fontSize: 20 }} />
            <p>暂无数据</p>
        </div>
    );

    //菜单数据
    const [menu, setMenu] = useState([])

    //板块
    const [shop, setShop] = useState([])

    // 密码
    const [password, setPassword] = useState('')

    // 旧密码
    const [oldPassword, setOldPassword] = useState('')

    // 确认密码
    const [againPassword, setAgainPassword] = useState('')

    //选择板块
    const [chooseShop, setChooseShop] = useState(-1)

    //是否打开个人信息
    const [isOpen, setIsOpen] = useState(false)

    // 是否打开修改密码
    const [updatePassword, setUpdatePassword] = useState(false)

    // 是否更新密码
    const [updatePass, setUpdatePass] = useState(false)

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 菜单是否打开
    const [collapsed, setCollapsed] = useState(false)

    // 是否显示侧边栏
    const [showDrawer, setShowDrawer] = useState(false)

    // 模块index
    const [moduleIndex, setModuleIndex] = useState(-1)

    // 数据
    const [data, setData] = useState([])

    // 菜单Id
    const [menuId, setMenuId] = useState('')

    // 模块id
    const [moduleId, setModuleId] = useState('')

    // 邮件未读数据
    const [eamilCount, setEmailCount] = useState(0)

    // tag标签
    const [tags, setTags] = useState([
        { title: '工作台', key: '/app/myWorkbench', closable: false, },

    ])

    // 选择那个标签
    const [activeKey, setActiveKey] = useState('')

    // 模块列表
    const [shopList] = useState(['1fe8c8a8-24d7-4378-96f8-00823a11ea28'])
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    // 获取权限
    useEffect(() => {
        getJurisdiction()
    }, [])

    //获取权限代码
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": shopList
        }
        NbAxios(dataProps, "POST", servicePath.getRoleShopPorts).then(res => {
            if (res.success) {
                console.log(res.data);
                setJurisdiction(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 判断是否是初始化密码
    useEffect(() => {
        let flag = Base64.decode(cookie.load('flag'))
        if (flag == 1) {
            setUpdatePassword(true)
            props.history.push('/app/myWorkbench')
        } else {
            // 用户的密码不是初始化密码的时候操作的措施
            //判断是否已经选择好板块
            // if (cookie.load('shop') == null) {
            //     //提示框
            //     notification.info({
            //         message: '请先选择板块',
            //         duration: 2,
            //         placement: 'topLeft'
            //     });
            // }
            // 禁止页面的复制粘贴
            // 禁止右键
            document.oncontextmenu = new Function("event.returnValue=false");
            // // 禁止选中
            // document.onselectstart=new Function("event.returnValue=false");
            // 禁止ctrl+c
            // document.onkeydown = function () {
            //     if (window.event.keyCode == 67) {
            //         return false;
            //     }
            // }
            cookie.load('pattern') == 1 ? dynamicLoadCss(colorCss.darkCss) : dynamicLoadCss(colorCss.minCss)
        }
    }, [])


    //屏幕适配操作
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])

    const onResize = useCallback(() => {
        setSize({
            width: document.body.clientWidth,
            height: document.body.clientHeight
        })
        if (cookie.load('watermark') != 'a0a596e6-b2f7-4ee5-bb80-eb61f1824eb0') {
            // 强制刷新
            window.location.reload(true)
        }
    }, [])

    //加载白天/深夜模式的css样式文件
    function dynamicLoadCss(url) {
        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = url;
        head.appendChild(link);
    }

    // 检测用户使用的浏览器
    useEffect(() => {
        let userAgent = navigator.userAgent.toLowerCase(), s, o = {};
        let browser = {
            version: (userAgent.match(/(?:firefox|opera|safari|chrome|msie)[\/: ]([\d.]+)/))[1],
            safari: /version.+safari/.test(userAgent),
            chrome: /chrome/.test(userAgent),
            firefox: /firefox/.test(userAgent),
            ie: /msie/.test(userAgent),
            opera: /opera/.test(userAgent)
        }
        if (!browser.chrome) {
            let tip = document.querySelector('.tip-wapper-browser')
            setTimeout(() => {
                tip.style.display = 'none'
            }, 9000)
        } else {
            let tip = document.querySelector('.tip-wapper-browser')
            tip.style.display = 'none'
        }
    }, [])

    // useEffect(() => {
    //     if(cookie.load('name') != undefined){
    //         if (cookie.load('role')) {
    //             let dataProps = {
    //                 'role_id': cookie.load('role'),
    //             }
    //             axios({
    //                 method: 'POST',
    //                 url: servicePath.getRoleShop,
    //                 data: dataProps,
    //                 headers: {
    //                     'Content-Type': 'application/json',  //如果写成contentType会报错
    //                     'authorization': cookie.load('token')
    //                 }
    //             }).then(
    //                 res => {
    //                     if (res.data['success']) {
    //                         setShop(res.data.data)
    //                     } else {
    //                         if (res.data['errorCode'] == '10001') {
    //                             message.error(res.data['errorMsg'])
    //                             cookie.remove("id", { path: '/' });
    //                             cookie.remove("role", { path: '/' });
    //                             cookie.remove("shop", { path: '/' })
    //                             cookie.remove("shopName", { path: '/' })
    //                             cookie.remove("name", { path: '/' })
    //                             cookie.remove("roleName", { path: '/' })
    //                             cookie.remove("email", { path: '/' })
    //                             cookie.remove("phone", { path: '/' })
    //                             cookie.remove("watermark", { path: '/' })
    //                             cookie.remove("flag", { path: '/' })
    //                             cookie.remove("token", { path: '/' })
    //                             props.history.push("/")
    //                         }
    //                     }
    //                 }
    //             )
    //         } else {
    //             //判断用户是否登陆
    //             cookie.remove("id", { path: '/' });
    //             cookie.remove("role", { path: '/' });
    //             cookie.remove("shop", { path: '/' })
    //             cookie.remove("shopName", { path: '/' })
    //             cookie.remove("name", { path: '/' })
    //             cookie.remove("roleName", { path: '/' })
    //             cookie.remove("email", { path: '/' })
    //             cookie.remove("phone", { path: '/' })
    //             cookie.remove("watermark", { path: '/' })
    //             cookie.remove("flag", { path: '/' })
    //             cookie.remove("token", { path: '/' })
    //             props.history.push('/')
    //             message.error('请先登陆系统')
    //         }
    //     }else{
    //          //判断用户cookie是否有效
    //          cookie.remove("id", { path: '/' });
    //          cookie.remove("role", { path: '/' });
    //          cookie.remove("shop", { path: '/' })
    //          cookie.remove("shopName", { path: '/' })
    //          cookie.remove("name", { path: '/' })
    //          cookie.remove("roleName", { path: '/' })
    //          cookie.remove("email", { path: '/' })
    //          cookie.remove("phone", { path: '/' })
    //          cookie.remove("watermark", { path: '/' })
    //          cookie.remove("flag", { path: '/' })
    //          cookie.remove("token", { path: '/' })
    //          props.history.push('/')
    //          message.error('请先登陆系统')
    //     }

    // }, [])

    // // 这里获取的菜单
    // useEffect(() => {
    //     if (cookie.load('shop')) {
    //         let dataProps = {
    //             'role_id': cookie.load('role'),
    //             "shop_id": cookie.load('shop')
    //         }
    //         axios({
    //             method: 'POST',
    //             url: servicePath.getRoleShopTreeMenu,
    //             data: dataProps,
    //             headers: {
    //                 'Content-Type': 'application/json',  //如果写成contentType会报错
    //                 'authorization': cookie.load('token')
    //             }
    //         }).then(
    //             res => {
    //                 if (res.data['success']) {
    //                     setMenu(res.data.data)
    //                     // 初始化模块集合
    //                     shopList.push(cookie.load('shop'))
    //                     cookie.save('shopList', shopList, { path: '/' })
    //                 } else {
    //                     // message.error(res.data['errorMsg'])
    //                     if (res.data['errorCode'] == '10001') {
    //                         message.error(res.data['errorMsg'])
    //                         cookie.remove("id", { path: '/' });
    //                         cookie.remove("role", { path: '/' });
    //                         cookie.remove("shop", { path: '/' })
    //                         cookie.remove("shopName", { path: '/' })
    //                         cookie.remove("name", { path: '/' })
    //                         cookie.remove("roleName", { path: '/' })
    //                         cookie.remove("email", { path: '/' })
    //                         cookie.remove("phone", { path: '/' })
    //                         cookie.remove("watermark", { path: '/' })
    //                         cookie.remove("flag", { path: '/' })
    //                         cookie.remove("token", { path: '/' })
    //                         props.history.push("/")
    //                     }
    //                 }
    //             }
    //         )
    //     }

    // }, [chooseShop])

    useEffect(() => {
        if (cookie.load('name') != undefined) {
            if (cookie.load('role')) {
                let dataProps = {
                    'roleId': cookie.load('role'),
                }
                NbAxios(dataProps, 'POST', servicePath.getRoleModuleMenu).then(res => {
                    if (res.success) {
                        setData(res.data);
                    }
                })
            } else {
                //判断用户是否登陆
                cookie.remove("id", { path: '/' });
                cookie.remove("role", { path: '/' });
                cookie.remove("shop", { path: '/' })
                cookie.remove("shopName", { path: '/' })
                cookie.remove("name", { path: '/' })
                cookie.remove("roleName", { path: '/' })
                cookie.remove("email", { path: '/' })
                cookie.remove("phone", { path: '/' })
                cookie.remove("watermark", { path: '/' })
                cookie.remove("flag", { path: '/' })
                cookie.remove("token", { path: '/' })
                props.history.push('/')
                message.error('请先登陆系统')
            }
        } else {
            //判断用户cookie是否有效
            cookie.remove("id", { path: '/' });
            cookie.remove("role", { path: '/' });
            cookie.remove("shop", { path: '/' })
            cookie.remove("shopName", { path: '/' })
            cookie.remove("name", { path: '/' })
            cookie.remove("roleName", { path: '/' })
            cookie.remove("email", { path: '/' })
            cookie.remove("phone", { path: '/' })
            cookie.remove("watermark", { path: '/' })
            cookie.remove("flag", { path: '/' })
            cookie.remove("token", { path: '/' })
            props.history.push('/')
            message.error('请先登陆系统')
        }
    }, [])
    // 定时器
    // useEffect(() => {
    //     let timer
    //     getEmailCount()
    //     // 设定定时器每15分钟请求一次未读消息
    //     timer = setInterval(() => {
    //         getEmailCount()
    //     }, 900000)
    //     return () => {
    //         clearInterval(timer)
    //     }
    // }, [])

    // 请求邮件未读数量
    // const getEmailCount = () => {
    //     NbAxios(data, "POST", servicePath.getWinmailMsglist).then(res => {
    //         if (res.success) {
    //             setEmailCount(res.data.newmsg);
    //         } else {
    //             message.warn(res.errorMsg)
    //         }
    //     })
    // }



    //菜单点击事件
    const handleClick = (e) => {
        let menuList = e.split(",")
        let tagList = tags
        let activeKey = menuList[0];
        setActiveKey(activeKey)
        let result = tagList.some(item => {
            if (item.key == menuList[0]) {
                return true
            }
        })
        // if (!result) {
        //     tagList.push({ title: menuList[1] + "/" + menuList[2], key: menuList[0] },);
        //     setTags(tagList)
        // }
        // setCollapsed(true)
        cookie.save('shop', moduleId, { path: '/' });
        if (shopList.indexOf(moduleId) == -1) {
            shopList.push(moduleId)
        }
        cookie.save('shopList', shopList, { path: '/' })
        setShowDrawer(false)
        if(props.history.location.pathname != '/app/myWorkbench'){
            window.open(`${webUrl}${menuList[0]}`)
        }else{
            props.history.push(menuList[0])
        }
    };

    //关闭信息框
    const onClose = () => {
        setIsOpen(false)
    }

    //退出登陆
    const logout = () => {
        cookie.remove("id", { path: '/' });
        cookie.remove("number", { path: '/' });
        cookie.remove("role", { path: '/' });
        cookie.remove("shop", { path: '/' })
        cookie.remove("shopList", { path: '/' })
        cookie.remove("shopName", { path: '/' })
        cookie.remove("name", { path: '/' })
        cookie.remove("roleName", { path: '/' })
        cookie.remove("email", { path: '/' })
        cookie.remove("phone", { path: '/' })
        cookie.remove("watermark", { path: '/' })
        cookie.remove("flag", { path: '/' })
        cookie.remove("token", { path: '/' })
        props.history.push("/")
        window.location.reload(true)
    }

    // 第一次修改密码
    const updateOk = () => {
        // if(password == ''){
        //     message.warn('密码不能为空')
        // }else{
        //     let dataProps = {
        //         'username': Base64.decode(cookie.load('email')),
        //         "password": password
        //     }
        //     axios({
        //         method: 'POST',
        //         url: servicePath.updatePassword,
        //         data: dataProps,
        //         header: {
        //             'Content-Type': 'application/json'  //如果写成contentType会报错
        //         }
        //     }).then(
        //         res => {
        //             if (res.data['success']) {
        //                 setUpdatePassword(false)
        //                 setPassword('')
        //                 cookie.save('flag', Base64.encode(0), { path: '/' })
        //             } else {
        //                 message.error(res.data['errorMsg'])
        //             }
        //         }
        //     )
        // }
        if (oldPassword == '') {
            message.warn("请填写短信上的密码")
        } else if (password == '') {
            message.warn("请填写新密码")
        } else if (againPassword != password) {
            message.warn("确认密码和新密码不一致")
        } else {
            let data = {}
            data.password_old = Base64.encode(oldPassword)
            data.password_new = Base64.encode(password)
            NbAxios(data, "POST", servicePath.LogForResetPassword).then(res => {
                if (res.success) {
                    message.success("初始密码修改成功")
                    setOldPassword('')
                    setPassword('')
                    setAgainPassword('')
                    cookie.save('flag', Base64.encode(0), { path: '/' })
                    setUpdatePassword(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 确认修改密码
    const updatePassOk = () => {
        // let dataProps = {
        //     'username': Base64.decode(cookie.load('email')),
        //     "password": password,
        //     "oldPassword": oldPassword
        // }
        // axios({
        //     method: 'POST',
        //     url: servicePath.updateNewPassword,
        //     data: dataProps,
        //     header: {
        //         'Content-Type': 'application/json'  //如果写成contentType会报错
        //     }
        // }).then(
        //     res => {
        //         if (res.data['success']) {
        //             setUpdatePass(false)
        //             setPassword('')
        //             setOldPassword('')
        //             message.success('密码修改成功')
        //         } else {
        //             message.error(res.data['errorMsg'])
        //         }
        //     }
        // )
        if (oldPassword == '') {
            message.warn("请填写旧密码")
        } else if (password == '') {
            message.warn("请填写新密码")
        } else if (againPassword != password) {
            message.warn("确认密码和新密码不一致")
        } else {
            let data = {}
            data.password_old = Base64.encode(oldPassword)
            data.password_new = Base64.encode(password)
            NbAxios(data, "POST", servicePath.LogForResetPassword).then(res => {
                if (res.success) {
                    message.success("密码修改成功")
                    setOldPassword('')
                    setPassword('')
                    setAgainPassword('')
                    setUpdatePass(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }


    // 取消修改密码
    const updatePassCancel = () => {
        message.success('取消修改密码')
        setOldPassword('')
        setPassword('')
        setAgainPassword('')
        setUpdatePass(false)
    }

    const onChange = (activeKey) => {
        setActiveKey(activeKey)
        props.history.push(activeKey)
    }

    const remove = targetKey => {
        let activeKeys = activeKey
        let lastIndex;
        tags.forEach((pane, i) => {
            if (pane.key === activeKeys) {
                lastIndex = i - 1;
            }
        });
        const tag = tags.filter(pane => pane.key !== targetKey);
        if (tag.length && activeKeys === targetKey) {
            if (lastIndex >= 0) {
                activeKeys = tag[lastIndex].key;
            } else {
                activeKeys = tag[0].key;
            }
        }
        setTags(tag)
        setActiveKey(activeKeys)
    };

    // 隐藏消息
    function missTip() {
        let tip = document.querySelector('.tip-wapper-browser')
        tip.style.display = 'none'
    }

    // 鼠标移入菜单的事件 打开菜单
    const openDrawer = () => {
        setShowDrawer(true)
    }

    // 关闭菜单
    const onCloseDrawer = () => {
        setModuleIndex(-1)
        setShowDrawer(false)
    }

    // 选择模块
    const chooseModule = (item, index) => {
        // cookie.save('shop',item.id, { path: '/' });
        // if (shopList.indexOf(item.id) == -1) {
        //     shopList.push(item.id)
        // }
        // cookie.save('shopList', shopList, { path: '/' })
        setModuleIndex(index);
        setModuleId(item.id)
        setMenu(data[index].children)
    }

    // 菜单选中
    const chooseMenuIndex = (id) => {
        setMenuId(id)
    }

    // 菜单离开
    const leaveMenuIndex = () => {
        setMenuId('')
    }

    function allowCopy(e) {
        if (jurisdiction.indexOf('nb::pageCopy') == -1) {
            e.preventDefault()
        }
    }
    return (
        <div onCopy={allowCopy}>
            <div className={'tip-wapper-browser'} onClick={missTip}>
                Tip:为了提升亲的使用体验感,请使用谷歌浏览器
            </div>
            <div >
                {/* <Sider
                    collapsible
                    collapsed={collapsed}
                    breakpoint="xxl"
                    collapsedWidth="0"
                    onBreakpoint={broken => {
                    }}
                    onCollapse={(collapsed, type) => {
                        setCollapsed(collapsed);
                    }}
                >
                    <div className="logo" />
                    <Select placeholder="请选择板块" defaultValue={cookie.load('shopName')} showSearch theme="dark" size="large" style={{ width: 200 }}
                            onChange={(value) => {
                                cookie.save('shopName', value, { path: '/' });
                                cookie.save('shop', shop[shop.map(item => item.name).indexOf(value)]['id'], { path: '/' });
                                if (shopList.indexOf(shop[shop.map(item => item.name).indexOf(value)]['id']) == -1) {
                                    shopList.push(shop[shop.map(item => item.name).indexOf(value)]['id'])
                                }
                                cookie.save('shopList', shopList, { path: '/' })
                                setChooseShop(shop[shop.map(item => item.name).indexOf(value)]['id']);
                                props.history.push('/app/myWorkbench')
                            }} >
                        {
                            shop.map(function (item) {
                                return (
                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                )
                            })
                        }
                    </Select>
                    <div style={{ height: size.height * 0.93, overflow: 'auto' }}>
                        <Menu onClick={handleClick} theme="dark" mode="inline" defaultSelectedKeys={['4']}>
                            {
                                menu.map(function (item, index) {
                                    return (
                                        <SubMenu
                                            key={item.id}
                                            title={<span><AppstoreAddOutlined /><span>{item.name}</span></span>}>
                                            {
                                                item.child.map((menu) => (
                                                    <Menu.Item key={menu.path + ',' + item.name + ',' + menu.name}>{menu.name}</Menu.Item>
                                                ))
                                            }
                                        </SubMenu>
                                    )
                                })
                            }
                        </Menu>
                    </div>
                </Sider> */}
                <div>
                    <LetterShare>
                        <div style={{ margin: 0, padding: 0, height: 41, backgroundColor: "#001529", position: 'fixed', top: 0, zIndex: 1000, width: '100vw' }} >
                            {/* <Row>
                            <Col>
                                <Tabs
                                    style={{ width: size.width * 0.6, color: '#ccc' }}
                                    hideAdd
                                    type="card"
                                    onChange={onChange}
                                    activeKey={activeKey}
                                    size='large'
                                >
                                    {tags.map((pane, index) => (
                                        <TabPane tab={<span>{pane.title}<CloseOutlined style={{ marginLeft: '10px', display: index == 0 ? 'none' : '' }} onClick={() => remove(pane.key)} /></span>} key={pane.key} closable={pane.closable}></TabPane>
                                    ))}
                                </Tabs>
                            </Col>
                            <Col >
                                <LetterTip style={{position:"absolute" }} fontSize="1.5rem" />
                                <Avatar onClick={() => setIsOpen(true)} size="small" style={{ marginLeft: size.width * 0.15, marginTop: -20,position:"relative" }}>{cookie.load('name').substring(0, 1)}</Avatar>
                            </Col>
                        </Row> */}
                            <div style={{ display: "grid", height: 40, gridTemplateColumns: "30px 1fr 135px" }}>
                                <Button icon={<MenuUnfoldOutlined />} style={{ height: 40 }} onMouseEnter={openDrawer.bind(this)}></Button>
                                {/* <Tabs
                                    style={{ width: size.width * 0.6, color: '#ccc' }}
                                    hideAdd
                                    type="card"
                                    onChange={onChange}
                                    activeKey={activeKey}
                                    size='large'
                                >
                                    {tags.map((pane, index) => (
                                        <TabPane tab={<span>{pane.title}<CloseOutlined
                                            style={{ marginLeft: '10px', display: index == 0 ? 'none' : '' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                remove(pane.key)
                                            }} /></span>} key={pane.key} closable={pane.closable}></TabPane>
                                    ))}
                                </Tabs> */}
                                <div style={{width: size.width * 0.6,}}>
                                    <Button size="large" onClick={()=>{
                                        props.history.push('/app/myWorkbench')
                                    }}>工作台</Button>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "40px 35px 1fr", height: 40, justifyContent: "center", alignItems: "center" }}>
                                    <Badge count={eamilCount} size="small" style={{ cursor: "pointer", marginRight: 20 }}>
                                        <MailOutlined style={{ color: 'white', fontSize: '1.5rem' }} onClick={() => {
                                            let data = {}
                                            AcceptBuried('email_click',"click",{},"打开邮箱")
                                            NbAxios(data, "POST", servicePath.loginWebWinmail).then(res => {
                                                if (res.success) {
                                                    window.open(res.data.url);
                                                } else {
                                                    message.warn(res.errorMsg)
                                                }
                                            })
                                        }} />
                                    </Badge>
                                    <LetterTip fontSize="1.5rem" />
                                    <Avatar onClick={() => setIsOpen(true)} size="small" >{cookie.load('name') == undefined ? <span></span> : cookie.load('name').substring(0, 1)}</Avatar>
                                </div>
                            </div>
                        </div>
                        {/* 设置网站内容的高度 */}
                        <ConfigProvider renderEmpty={customizeRenderEmpty}>
                            {/* style={{height:size.height<557?580:size.height,overflowY:"scroll"}} */}
                            <div style={{ minHeight:props.history.location.pathname == '/app/oa/ordermeeting' ? '0px' : '100vh', width: 'calc(100vw - 7px)', paddingTop:props.history.location.pathname == '/app/oa/ordermeeting' ?  0 : 40 }}>
                                {/* 二级路由配置以及页面渲染 */}
                                <Route path="/app/system/role" component={Role} />
                                <Route path="/app/system/user" component={User} />
                                <Route path="/app/system/shop" component={Shop} />
                                <Route path="/app/system/menu" component={Menus} />
                                <Route path="/app/system/port" component={Port} />
                                <Route path="/app/system/data" component={DataPerssiom} />
                                <Route path="/app/system/accredit" component={Accredit} />
                                <Route path="/app/system/introduce" component={SystemIntroduce} />
                                <Route path="/app/kol/provider" component={Provider} />
                                <Route path="/app/kol/party" component={Party} />
                                <Route path="/app/kol/kol" component={KOL} />
                                <Route path="/app/kol/marketing" component={Marketing} />
                                <Route path="/app/kol/put" component={Put} />
                                <Route path="/app/kol/retrieval" component={Retrieval} />
                                <Route path="/app/kol/promote" component={Promote} />
                                <Route path="/app/jillion/jillion" component={Jillion} />
                                <Route path="/app/jillion/task" component={JillionTask} />
                                <Route path="/app/spiderPlugin/download" component={SpiderPluginDownload} />
                                <Route path="/app/spiderPlugin/ciyun" component={Ciyun} />
                                <Route path="/app/work/insideWorkSystem" component={InsideWorkSystem} />
                                <Route path="/app/work/workSystem" component={WorkSystem} />
                                <Route path="/app/work/comment" component={WorkComment} />
                                <Route path="/app/introduce/introduce" component={Introduce} />
                                {/* <Route path="/app/oa/ordermeeting" component={OrderMeeting} /> */}
                                <Route path="/app/peek/Z11/flagship" component={Z11Report} />
                                <Route path="/app/president/zhuoya/daily" component={PresidentDaily} />
                                <Route path="/app/president/freiol/daily" component={PresidentDaily} />
                                <Route path="/app/president/iv/daily" component={PresidentDaily} />
                                <Route path="/app/president/vgrass/daily" component={PresidentDaily} />
                                <Route path="/app/president/qda/daily" component={PresidentDaily} />
                                <Route path="/app/president/an/daily" component={PresidentDaily} />
                                <Route path="/app/president/ivorycoat/daily" component={PresidentDaily} />
                                <Route path="/app/president/xh/daily" component={PresidentDaily} />
                                <Route path="/app/president/z11/daily" component={PresidentDaily} />
                                <Route path="/app/president/cr/daily" component={PresidentDaily} />
                                <Route path="/app/president/joomilim/daily" component={PresidentDaily} />
                                <Route path="/app/president/airinum/daily" component={PresidentDaily} />
                                <Route path="/app/president/billymargot/daily" component={PresidentDaily} />
                                <Route path="/app/president/overview" component={OverView} />
                                <Route path="/app/president/DaliyCharts" component={DaliyCharts} />
                                <Route path="/app/presell/backorders" component={BackOrders} />
                                <Route path="/app/presell/purchaseorders" component={PurchaseOrders} />
                                <Route path="/app/presell/purchase" component={Preview} />
                                <Route path="/app/presell/details" component={PresellDetails} />
                                <Route path="/app/presell/skudetails" component={SKUDetails} />
                                <Route path="/app/distribute/perSupplier" component={petSupplier} />
                                <Route path="/app/distribute/commodity" component={petCommodity} />
                                <Route path="/app/pm/project_managment" component={PMProject} />
                                <Route path="/app/pm/team_managment" component={PMTeam} />
                                <Route path="/app/pm/task_managment" component={PMTask} />
                                <Route path="/app/pm/daily_weekly_report" component={PMReport} />
                                <Route path="/app/pm/member_managment" component={PMMember} />
                                <Route path="/app/pm/overview_managment" component={PMTaskOverview} />
                                <Route path="/app/myWorkbench" component={WorkBench} />
                                <Route path="/app/peek/women/commodityCrowd" component={CommodityCrowd} />
                                <Route path="/app/peek/task" component={Task} />
                                <Route path="/app/peek/women/downloadCommodityCrowd" component={DownloadCommodityCrowd} />
                                <StateShare>
                                    <Route path="/app/business/market/女装" component={MarketRank} />
                                    <Route path="/app/raceshop/jr" component={RaceShop} />
                                    <Route path="/app/raceshop/trend" component={RaceShopTrend} />
                                    <Route path="/app/raceshopline/:shop/:id" component={RaceShopLine} />
                                    <Route path="/app/mark/markborder/女装" component={MarkBorder} />
                                    <Route path="/app/peek/jr/flagship" component={JrReport} />
                                    <Route path="/app/peek/markrRank/女装" component={MarkReport} />
                                    <Route path="/app/business/market/童装" component={MarketRank} />
                                    <Route path="/app/mark/markborder/童装" component={MarkBorder} />
                                    <Route path="/app/peek/markrRank/童装" component={MarkReport} />
                                    <Route path="/app/business/market/美容护肤" component={MarketRank} />
                                    <Route path="/app/mark/markborder/美容护肤" component={MarkBorder} />
                                    <Route path="/app/peek/markrRank/美容护肤" component={MarkReport} />
                                    <Route path="/app/JORYA/base/itemInfo" component={ItemInfo} />
                                    <Route path="/app/iv/base/itemInfo" component={ItemInfo} />
                                    <Route path="/app/ein/base/itemInfo" component={ItemInfo} />
                                    <Route path="/app/an/base/itemInfo" component={ItemInfo} />
                                    <Route path="/app/cr/base/itemInfo" component={ItemInfo} />
                                    <Route path="/app/vgrass/base/itemInfo" component={ItemInfo} />
                                    <Route path="/app/qda/base/itemInfo" component={ItemInfo} />
                                    <Route path="/app/gs/base/itemInfo" component={ItemInfo} />
                                    <Route path="/app/JORYA/base/goodsManager" component={GoodsManager} />
                                    <Route path="/app/iv/base/goodsManager" component={GoodsManager} />
                                    <Route path="/app/ein/base/goodsManager" component={GoodsManager} />
                                    <Route path="/app/an/base/goodsManager" component={GoodsManager} />
                                    <Route path="/app/cr/base/goodsManager" component={GoodsManager} />
                                    <Route path="/app/vgrass/base/goodsManager" component={GoodsManager} />
                                    <Route path="/app/qda/base/goodsManager" component={GoodsManager} />
                                    <Route path="/app/gs/base/goodsManager" component={GoodsManager} />
                                    <Route path="/app/base/jewelryItemInfo" component={JewelryItemInfo} />
                                    {/* <Route path="/app/letter/lookLetter" component={LookLetter} /> */}
                                    <Route path="/app/cursor/order" component={OrderCursor} />
                                    <Route path="/app/cursor/myCursor" component={MyCursor} />
                                    <Route path="/app/cursor/myStudy" component={MyStudy} />
                                    <Route path="/app/cursor/cursorBase" component={CursorBase} />
                                    <Route path="/app/cursor/teachCourse" component={TeachCourse} />
                                    <Route path="/app/xhchanel/monthreport" component={MonthlReport} />
                                    <ItemInfoShare>
                                        <Route path="/app/bussiness/cr/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/ein/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/zhuoya/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/qda/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/xh/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/z11/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/an/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/vgrass/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/mesux/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/iv/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/freiol/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/billymargot/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/airinum/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/ivorycoat/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/joomilim/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/threePets/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/gs/daily" component={BussinessStaffDaily} />
                                        <Route path="/app/bussiness/dailyCharts" component={BussinessStaffDailyCharts} />
                                        <Route path="/app/SaleBorder/jr" component={SaleBorder} />
                                        <Route path="/app/SaleBorder/vgrass" component={SaleBorder} />
                                        <Route path="/app/SaleBorder/cr" component={SaleBorder} />
                                        <Route path="/app/SaleBorder/qda" component={SaleBorder} />
                                        <Route path="/app/SaleBorder/z11" component={SaleBorder} />
                                        <Route path="/app/SaleBorder/an" component={SaleBorder} />
                                        <Route path="/app/SaleBorder/xhoutlets" component={SaleBorder} />
                                        <Route path="/app/SaleBorder/ein" component={SaleBorder} />
                                        <Route path="/app/SaleBorder/gs" component={SaleBorder} />
                                        <Route path="/app/SaleBorder/mesux" component={SaleBorder} />
                                        <Route path="/app/base/vgrass/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/jr/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/z11/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/an/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/cr/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/qda/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/xhoutlets/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/ein/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/gs/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/qda/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/mesux/goodsreport" component={GoodsReport} />
                                        <Route path="/app/base/JORYA/operationreport" component={OperationReport} />
                                        <Route path="/app/base/vgrass/operationreport" component={OperationReport} />
                                        <Route path="/app/base/z11/operationreport" component={OperationReport} />
                                        <Route path="/app/base/an/operationreport" component={OperationReport} />
                                        <Route path="/app/base/cr/operationreport" component={OperationReport} />
                                        <Route path="/app/base/xhoutlets/operationreport" component={OperationReport} />
                                        <Route path="/app/base/ein/operationreport" component={OperationReport} />
                                        <Route path="/app/base/gs/operationreport" component={OperationReport} />
                                        <Route path="/app/base/mesux/operationreport" component={OperationReport} />
                                        <Route path="/app/base/qda/operationreport" component={OperationReport} />
                                        <Route path="/app/JORYA/base/skuAnaysis" component={SkuAnaysis} />
                                        <Route path="/app/iv/base/skuAnaysis" component={SkuAnaysis} />
                                        <Route path="/app/ein/base/skuAnaysis" component={SkuAnaysis} />
                                        <Route path="/app/an/base/skuAnaysis" component={SkuAnaysis} />
                                        <Route path="/app/cr/base/skuAnaysis" component={SkuAnaysis} />
                                        <Route path="/app/vgrass/base/skuAnaysis" component={SkuAnaysis} />
                                        <Route path="/app/qda/base/skuAnaysis" component={SkuAnaysis} />
                                        <Route path="/app/gs/base/skuAnaysis" component={SkuAnaysis} />
                                    </ItemInfoShare>
                                    <Route path="/app/recruit/talentWarehouse" component={TalentWarehouse} />
                                    <Route path="/app/recruit/recruitmentPlan" component={RecruitmentPlan} />
                                    <Route path="/app/recruit/interview" component={InterView} />
                                    <Route path="/app/recruit/position" component={Position} />
                                    <Route path="/app/distribution/shop" component={DistributionShop} />
                                    <Route path="/app/distribution/summary" component={DistributionSummary} />
                                    <Route path="/app/milkyway/contact" component={ContactUser} />
                                    <Route path="/app/kol/summary" component={KolSummary} />
                                    <Route path="/app/kol/person" component={KolPerson} />
                                    <Route path="/app/sales/performance" component={SalesPerformance} />
                                    <Route path="/app/sales/standard/performanceSummary"
                                        component={SalesPerformanceSummary} />
                                    <Route path="/app/sales/unstandard/performanceSummary"
                                        component={SalesPerformanceSummary} />
                                    <Route path="/app/sales/standard/performanceShopSummary"
                                        component={SalesPerformanceShopSummary} />
                                    <Route path="/app/sales/unstandard/performanceShopSummary"
                                        component={SalesPerformanceShopSummary} />
                                    <Route path="/app/sales/standard/salesTargetData"
                                        component={SalesTargetData} />
                                    <Route path="/app/sales/unstandard/salesTargetData"
                                        component={SalesTargetData} />
                                    <Route path="/app/finance/check/summary" component={FinanceCheckSummaryTable} />
                                    <Route path="/app/finance/dispute/summary" component={FinanceCheckDisputeTable} />
                                    <Route path="/app/finance/subject" component={FinanceSuject} />
                                    <Route path="/app/finance/project" component={FinanceProject} />
                                    <Route path="/app/otm/JORYA/operationModel" component={OtmOperationModel} />
                                    <Route path="/app/otm/JORYA/eventModel" component={OtmEventModel} />
                                    <Route path="/app/otm/JORYA/subEventModel" component={OtmSubEventModel} />
                                    <Route path="/app/otm/JORYA/confirmGannt" component={OtmConfirmGannt} />
                                    <Route path="/app/otm/JORYA/gannt/tmall" component={OtmGannt} />
                                    <Route path="/app/otm/JORYA/event" component={OtmEvent} />
                                    <Route path="/app/otm/JORYA/operation" component={OtmOperation} />
                                    <Route path="/app/otm/iv/operationModel" component={OtmOperationModel} />
                                    <Route path="/app/otm/iv/eventModel" component={OtmEventModel} />
                                    <Route path="/app/otm/iv/subEventModel" component={OtmSubEventModel} />
                                    <Route path="/app/otm/iv/confirmGannt" component={OtmConfirmGannt} />
                                    <Route path="/app/otm/iv/gannt/tmall" component={OtmGannt} />
                                    <Route path="/app/otm/iv/event" component={OtmEvent} />
                                    <Route path="/app/otm/iv/operation" component={OtmOperation} />
                                    <Route path="/app/otm/ein/operationModel" component={OtmOperationModel} />
                                    <Route path="/app/otm/ein/eventModel" component={OtmEventModel} />
                                    <Route path="/app/otm/ein/subEventModel" component={OtmSubEventModel} />
                                    <Route path="/app/otm/ein/confirmGannt" component={OtmConfirmGannt} />
                                    <Route path="/app/otm/ein/gannt/tmall" component={OtmGannt} />
                                    <Route path="/app/otm/ein/event" component={OtmEvent} />
                                    <Route path="/app/otm/ein/operation" component={OtmOperation} />
                                    <Route path="/app/otm/cr/operationModel" component={OtmOperationModel} />
                                    <Route path="/app/otm/cr/eventModel" component={OtmEventModel} />
                                    <Route path="/app/otm/cr/subEventModel" component={OtmSubEventModel} />
                                    <Route path="/app/otm/cr/confirmGannt" component={OtmConfirmGannt} />
                                    <Route path="/app/otm/cr/gannt/tmall" component={OtmGannt} />
                                    <Route path="/app/otm/cr/event" component={OtmEvent} />
                                    <Route path="/app/otm/cr/operation" component={OtmOperation} />
                                    <Route path="/app/otm/an/operationModel" component={OtmOperationModel} />
                                    <Route path="/app/otm/an/eventModel" component={OtmEventModel} />
                                    <Route path="/app/otm/an/subEventModel" component={OtmSubEventModel} />
                                    <Route path="/app/otm/an/confirmGannt" component={OtmConfirmGannt} />
                                    <Route path="/app/otm/an/gannt/tmall" component={OtmGannt} />
                                    <Route path="/app/otm/an/event" component={OtmEvent} />
                                    <Route path="/app/otm/an/operation" component={OtmOperation} />
                                    <Route path="/app/otm/z11/operationModel" component={OtmOperationModel} />
                                    <Route path="/app/otm/z11/eventModel" component={OtmEventModel} />
                                    <Route path="/app/otm/z11/subEventModel" component={OtmSubEventModel} />
                                    <Route path="/app/otm/z11/confirmGannt" component={OtmConfirmGannt} />
                                    <Route path="/app/otm/z11/gannt/tmall" component={OtmGannt} />
                                    <Route path="/app/otm/z11/event" component={OtmEvent} />
                                    <Route path="/app/otm/z11/operation" component={OtmOperation} />
                                    <Route path="/app/otm/mesux/operationModel" component={OtmOperationModel} />
                                    <Route path="/app/otm/mesux/eventModel" component={OtmEventModel} />
                                    <Route path="/app/otm/mesux/subEventModel" component={OtmSubEventModel} />
                                    <Route path="/app/otm/mesux/confirmGannt" component={OtmConfirmGannt} />
                                    <Route path="/app/otm/mesux/gannt/tmall" component={OtmGannt} />
                                    <Route path="/app/otm/mesux/event" component={OtmEvent} />
                                    <Route path="/app/otm/mesux/operation" component={OtmOperation} />
                                    <Route path="/app/otm/vgrass/operationModel" component={OtmOperationModel} />
                                    <Route path="/app/otm/vgrass/eventModel" component={OtmEventModel} />
                                    <Route path="/app/otm/vgrass/subEventModel" component={OtmSubEventModel} />
                                    <Route path="/app/otm/vgrass/confirmGannt" component={OtmConfirmGannt} />
                                    <Route path="/app/otm/vgrass/gannt/tmall" component={OtmGannt} />
                                    <Route path="/app/otm/vgrass/event" component={OtmEvent} />
                                    <Route path="/app/otm/vgrass/operation" component={OtmOperation} />
                                    <Route path="/app/vgrass/traffic/statistics" component={TrafficStatistics} />
                                    <Route path="/app/jr/traffic/statistics" component={TrafficStatistics} />
                                    <Route path="/app/z11/traffic/statistics" component={TrafficStatistics} />
                                    <Route path="/app/an/traffic/statistics" component={TrafficStatistics} />
                                    <Route path="/app/cr/traffic/statistics" component={TrafficStatistics} />
                                    <Route path="/app/xhoutlets/traffic/statistics" component={TrafficStatistics} />
                                    <Route path="/app/ein/traffic/statistics" component={TrafficStatistics} />
                                    <Route path="/app/gs/traffic/statistics" component={TrafficStatistics} />
                                    <Route path="/app/qda/traffic/statistics" component={TrafficStatistics} />
                                    <Route path="/app/mesux/traffic/statistics" component={TrafficStatistics} />
                                    <Route path="/app/airinum/standardProduct/industry" component={StandardProduct} />
                                    <Route path="/app/JORYA/crowdClassification/rfm" component={RFMCrowdClassification} />
                                    <Route path="/app/EIN/crowdClassification/rfm" component={RFMCrowdClassification} />
                                    <Route path="/app/vgrass/crowdClassification/rfm" component={RFMCrowdClassification} />
                                    <Route path="/app/supplyChainManagement/sample/clothes" component={SampleClothes} />
                                    <Route path="/app/supplyChainManagement/development" component={SupplyChainDevelopment} />
                                    <Route path="/app/supplyChainManagement/fabric" component={SupplyChainFabric} />
                                    <Route path="/app/supplyChainManagement/supplier" component={SupplyChainSupplier} />
                                    {/*<Route path="/app/EIN/commodity/apriori" component={Apriori} />*/}
                                    <Route path="/app/JORYA/commodity/ranking" component={CommodityRanking} />
                                    <Route path="/app/ein/commodity/ranking" component={CommodityRanking} />
                                    <Route path="/app/gs/commodity/ranking" component={CommodityRanking} />
                                    <Route path="/app/iv/commodity/ranking" component={CommodityRanking} />
                                    <Route path="/app/cr/commodity/ranking" component={CommodityRanking} />
                                    <Route path="/app/an/commodity/ranking" component={CommodityRanking} />
                                    <Route path="/app/qda/commodity/ranking" component={CommodityRanking} />
                                    <Route path="/app/kol/mediaManagement" component={KolMediaManagement} />
                                    {/*<Route path="/app/JORYA/commodityPlanning/quarteryTarget" component={QuarterlyTarget} />*/}
                                    <Route path="/app/JORYA/member/consumption" component={MemberConsumption} />
                                    <Route path="/app/iv/member/consumption" component={MemberConsumption} />
                                    <Route path="/app/ein/member/consumption" component={MemberConsumption} />
                                    <Route path="/app/an/member/consumption" component={MemberConsumption} />
                                    <Route path="/app/cr/member/consumption" component={MemberConsumption} />
                                    <Route path="/app/z11/member/consumption" component={MemberConsumption} />
                                    <Route path="/app/mesux/member/consumption" component={MemberConsumption} />
                                    <Route path="/app/vgrass/member/consumption" component={MemberConsumption} />
                                    {/*<Route path="/app/JORYA/product/common" component={ProductCommon} />*/}
                                    <Route path="/app/phone/message" component={PhoneMessage} />
                                    <Route path="/app/my/device" component={Device} />
                                    <Route path="/app/JORYA/commodityTable" component={CommodityTable} />
                                    <Route path="/app/ein/commodityTable" component={CommodityTable} />
                                    <Route path="/app/iv/commodityTable" component={CommodityTable} />
                                    <Route path="/app/cr/commodityTable" component={CommodityTable} />
                                    <Route path="/app/an/commodityTable" component={CommodityTable} />
                                    <Route path="/app/z11/commodityTable" component={CommodityTable} />
                                    <Route path="/app/mesux/commodityTable" component={CommodityTable} />
                                    <Route path="/app/vgrass/commodityTable" component={CommodityTable} />
                                    <Route path="/app/JORYA/groupStatistics" component={GroupStatistics} />
                                    <Route path="/app/JORYA/dataMart/dataManagement" component={DataManagement} />
                                    <Route path="/app/JORYA/dataMart/dataComparison" component={DataComparison} />
                                    <Route path="/app/JORYA/dataMart/dataComparisonManagement" component={DataComparisonManagement} />
                                    <Route path="/app/JORYA/dataMart/dataNote" component={DataNote} />
                                    <Route path="/app/ein/groupStatistics" component={GroupStatistics} />
                                    <Route path="/app/ein/dataMart/dataManagement" component={DataManagement} />
                                    <Route path="/app/ein/dataMart/dataComparison" component={DataComparison} />
                                    <Route path="/app/ein/dataMart/dataComparisonManagement" component={DataComparisonManagement} />

                                    <Route path="/app/iv/groupStatistics" component={GroupStatistics} />
                                    <Route path="/app/iv/dataMart/dataManagement" component={DataManagement} />
                                    <Route path="/app/iv/dataMart/dataComparison" component={DataComparison} />
                                    <Route path="/app/iv/dataMart/dataComparisonManagement" component={DataComparisonManagement} />

                                    <Route path="/app/cr/groupStatistics" component={GroupStatistics} />
                                    <Route path="/app/cr/dataMart/dataManagement" component={DataManagement} />
                                    <Route path="/app/cr/dataMart/dataComparison" component={DataComparison} />
                                    <Route path="/app/cr/dataMart/dataComparisonManagement" component={DataComparisonManagement} />

                                    <Route path="/app/an/groupStatistics" component={GroupStatistics} />
                                    <Route path="/app/an/dataMart/dataManagement" component={DataManagement} />
                                    <Route path="/app/an/dataMart/dataComparison" component={DataComparison} />
                                    <Route path="/app/an/dataMart/dataComparisonManagement" component={DataComparisonManagement} />

                                    <Route path="/app/z11/groupStatistics" component={GroupStatistics} />
                                    <Route path="/app/z11/dataMart/dataManagement" component={DataManagement} />
                                    <Route path="/app/z11/dataMart/dataComparison" component={DataComparison} />
                                    <Route path="/app/z11/dataMart/dataComparisonManagement" component={DataComparisonManagement} />

                                    <Route path="/app/mesux/groupStatistics" component={GroupStatistics} />
                                    <Route path="/app/mesux/dataMart/dataManagement" component={DataManagement} />
                                    <Route path="/app/mesux/dataMart/dataComparison" component={DataComparison} />
                                    <Route path="/app/mesux/dataMart/dataComparisonManagement" component={DataComparisonManagement} />

                                    <Route path="/app/vgrass/groupStatistics" component={GroupStatistics} />
                                    <Route path="/app/vgrass/dataMart/dataManagement" component={DataManagement} />
                                    <Route path="/app/vgrass/dataMart/dataComparison" component={DataComparison} />
                                    <Route path="/app/vgrass/dataMart/dataComparisonManagement" component={DataComparisonManagement} />

                                    <Route path="/app/vgrass/selectionPricing/initial" component={SelectionPricingInitial} />
                                    <Route path="/app/vgrass/selectionPricing/formalBenefit" component={SelectionPricingFormalBenefit} />
                                    <Route path="/app/vgrass/selectionPricing/formal" component={SelectionPricingFormal} />
                                    <Route path="/app/vgrass/selectionPricing/formalSummary" component={SelectionPricingFormalSummary} />
                                    {/* <Route path="/app/JORYA/smart/charts" component={SmartChart} /> */}
                                    <Route path="/app/three/pets/sms/supplier" component={ThreePetsSupplier} />
                                    <Route path="/app/three/pets/sms/factory" component={ThreePetsFactory} />
                                    <Route path="/app/three/pets/sms/order" component={ThreePetsOrder} />
                                    <Route path="/app/three/pets/sms/commodity" component={ThreePetsCommodity} />
                                    <Route path="/app/halfMeal/dessert" component={HalfMealDessert} />
                                    <Route path="/app/halfMeal/signUp" component={HalfMealSignUp} />
                                    <Route path="/app/JORYA/commodity/operationTable" component={CommodityOperationTable} />
                                    <Route path="/app/ein/commodity/operationTable" component={CommodityOperationTable} />
                                    <Route path="/app/iv/commodity/operationTable" component={CommodityOperationTable} />
                                    <Route path="/app/cr/commodity/operationTable" component={CommodityOperationTable} />
                                    <Route path="/app/an/commodity/operationTable" component={CommodityOperationTable} />
                                    <Route path="/app/z11/commodity/operationTable" component={CommodityOperationTable} />
                                    <Route path="/app/mesux/commodity/operationTable" component={CommodityOperationTable} />
                                    <Route path="/app/vgrass/commodity/operationTable" component={CommodityOperationTable} />
                                    <Route path="/app/qda/commodity/operationTable" component={CommodityOperationTable} />
                                    <Route path="/app/ein/daily/orderAnalysis" component={DailyOrderAnalysis} />
                                    <Route path="/app/JORYA/daily/orderAnalysis" component={DailyOrderAnalysis} />
                                    <Route path="/app/iv/daily/orderAnalysis" component={DailyOrderAnalysis} />
                                    <Route path="/app/cr/daily/orderAnalysis" component={DailyOrderAnalysis} />
                                    <Route path="/app/an/daily/orderAnalysis" component={DailyOrderAnalysis} />
                                    <Route path="/app/z11/daily/orderAnalysis" component={DailyOrderAnalysis} />
                                    <Route path="/app/mesux/daily/orderAnalysis" component={DailyOrderAnalysis} />
                                    <Route path="/app/vgrass/daily/orderAnalysis" component={DailyOrderAnalysis} />
                                    <Route path="/app/qda/daily/orderAnalysis" component={DailyOrderAnalysis} />
                                    {/* <Route path="/app/JORYA/goalManagement" component={GoalManagement}/> */}
                                    <Route path="/app/JORYA/crowdAssets" component={CrowdAssets} />
                                    <Route path="/app/iv/crowdAssets" component={CrowdAssets} />
                                    <Route path="/app/cr/crowdAssets" component={CrowdAssets} />
                                    <Route path="/app/an/crowdAssets" component={CrowdAssets} />
                                    <Route path="/app/ein/crowdAssets" component={CrowdAssets} />
                                    <Route path="/app/z11/crowdAssets" component={CrowdAssets} />
                                    <Route path="/app/mesux/crowdAssets" component={CrowdAssets} />
                                    <Route path="/app/vgrass/crowdAssets" component={CrowdAssets} />
                                    <Route path="/app/gs/crowdAssets" component={CrowdAssets} />
                                    <Route path="/app/JORYA/member" component={Member} />
                                    <Route path="/app/iv/member" component={Member} />
                                    <Route path="/app/cr/member" component={Member} />
                                    <Route path="/app/an/member" component={Member} />
                                    <Route path="/app/z11/member" component={Member} />
                                    <Route path="/app/ein/member" component={Member} />
                                    <Route path="/app/mesux/member" component={Member} />
                                    <Route path="/app/gs/member" component={Member} />
                                    <Route path="/app/vgrass/member" component={Member} />
                                    <Route path="/app/qda/member" component={Member} />
                                    <Route path="/app/women/creationExplosive" component={CreationWomenExplosive} />
                                    <Route path="/app/women/competitiveTracking" component={CompetitiveTracking} />
                                    <Route path="/app/JORYA/women/weekly" component={WomenWeekly} />
                                    <Route path="/app/iv/women/weekly" component={WomenWeekly} />
                                    <Route path="/app/ein/women/weekly" component={WomenWeekly} />
                                    <Route path="/app/an/women/weekly" component={WomenWeekly} />
                                    <Route path="/app/cr/women/weekly" component={WomenWeekly} />
                                    <Route path="/app/qda/women/weekly" component={WomenWeekly} />
                                    <Route path="/app/vgrass/women/weekly" component={WomenWeekly} />
                                    <Route path="/app/gs/women/weekly" component={WomenWeekly} />
                                    <Route path="/app/women/weekly/preview" component={WomenWeeklyPreview} />
                                    <Route path="/app/JORYA/commodity/daily" component={CommodityDaily} />
                                    <Route path="/app/iv/commodity/daily" component={CommodityDaily} />
                                    <Route path="/app/cr/commodity/daily" component={CommodityDaily} />
                                    <Route path="/app/an/commodity/daily" component={CommodityDaily} />
                                    <Route path="/app/z11/commodity/daily" component={CommodityDaily} />
                                    <Route path="/app/ein/commodity/daily" component={CommodityDaily} />
                                    <Route path="/app/gs/commodity/daily" component={CommodityDaily} />
                                    <Route path="/app/vgrass/commodity/daily" component={CommodityDaily} />
                                    <Route path="/app/qda/commodity/daily" component={CommodityDaily} />
                                    {/* <Route path="/app/JORYA/competitive/storeLoss" component={CompetitiveStoreLoss} />
                                    <Route path="/app/iv/competitive/storeLoss" component={CompetitiveStoreLoss} />
                                    <Route path="/app/cr/competitive/storeLoss" component={CompetitiveStoreLoss} />
                                    <Route path="/app/an/competitive/storeLoss" component={CompetitiveStoreLoss} />
                                    <Route path="/app/z11/competitive/storeLoss" component={CompetitiveStoreLoss} />
                                    <Route path="/app/ein/competitive/storeLoss" component={CompetitiveStoreLoss} />
                                    <Route path="/app/gs/competitive/storeLoss" component={CompetitiveStoreLoss} />
                                    <Route path="/app/vgrass/competitive/storeLoss" component={CompetitiveStoreLoss} />
                                    <Route path="/app/qda/competitive/storeLoss" component={CompetitiveStoreLoss} /> */}
                                    <Route path="/app/JORYA/commodity/top" component={CommodityTop} />
                                    <Route path="/app/iv/commodity/top" component={CommodityTop} />
                                    <Route path="/app/cr/commodity/top" component={CommodityTop} />
                                    <Route path="/app/an/commodity/top" component={CommodityTop} />
                                    <Route path="/app/z11/commodity/top" component={CommodityTop} />
                                    <Route path="/app/ein/commodity/top" component={CommodityTop} />
                                    <Route path="/app/gs/commodity/top" component={CommodityTop} />
                                    <Route path="/app/vgrass/commodity/top" component={CommodityTop} />
                                    <Route path="/app/qda/commodity/top" component={CommodityTop} />
                                    <Route path="/app/commodity/planning" component={CommodityPlanning} />
                                    <Route path="/app/industry/sector" component={IndustrySector} />
                                    <Route path="/app/Pos/initial" component={PosInitial} />
                                    <Route path="/app/Pos/create" component={PosCreate} />
                                    <Route path="/app/Pos/gift" component={PosGift} />
                                    <Route path="/app/Pos/chargeBack" component={PosChargeBack} />
                                    <Route path="/app/JORYA/crowd/select" component={CrowdSelect} />
                                    <Route path="/app/JORYA/crowd/analyze" component={CrowdAnalyze} />
                                    <Route path="/app/an/crowd/select" component={CrowdSelect} />
                                    <Route path="/app/an/crowd/analyze" component={CrowdAnalyze} />
                                    <Route path="/app/cr/crowd/select" component={CrowdSelect} />
                                    <Route path="/app/cr/crowd/analyze" component={CrowdAnalyze} />
                                    <Route path="/app/qda/crowd/select" component={CrowdSelect} />
                                    <Route path="/app/qda/crowd/analyze" component={CrowdAnalyze} />
                                    <Route path="/app/gs/crowd/select" component={CrowdSelect} />
                                    <Route path="/app/gs/crowd/analyze" component={CrowdAnalyze} />
                                    {/*<Route path="/app/JORYA/crowd/calculate" component={CrowdCalculate} />*/}
                                    <Route path="/app/DY/liveDaily/report" component={LiveDailyReport} />
                                    <Route path="/app/DY/liveDaily/target" component={LiveDailyTarget} />
                                    <Route path="/app/DY/liveDaily/commodityOperation" component={LiveDailyCommodityOperation} />
                                    <Route path="/app/DY/liveDaily/singleItemAnalysis" component={LiveDailySingleItemAnalysis} />
                                    <Route path="/app/xin/liveDaily/report" component={LiveDailyReport} />
                                    <Route path="/app/xin/liveDaily/target" component={LiveDailyTarget} />
                                    <Route path="/app/xin/liveDaily/commodityOperation" component={LiveDailyCommodityOperation} />
                                    <Route path="/app/xin/liveDaily/singleItemAnalysis" component={LiveDailySingleItemAnalysis} />
                                    <Route path="/app/jw/liveDaily/report" component={LiveDailyReport} />
                                    <Route path="/app/jw/liveDaily/target" component={LiveDailyTarget} />
                                    <Route path="/app/jw/liveDaily/commodityOperation" component={LiveDailyCommodityOperation} />
                                    <Route path="/app/jw/liveDaily/singleItemAnalysis" component={LiveDailySingleItemAnalysis} />
                                    <Route path="/app/threePet/liveDaily/report" component={LiveDailyReport} />
                                    <Route path="/app/threePet/liveDaily/target" component={LiveDailyTarget} />
                                    <Route path="/app/threePet/liveDaily/commodityOperation" component={LiveDailyCommodityOperation} />
                                    <Route path="/app/threePet/liveDaily/singleItemAnalysis" component={LiveDailySingleItemAnalysis} />
                                    <Route path="/app/ai/liveDaily/commodityOperation" component={LiveDailyCommodityOperation} />
                                    <Route path="/app/ai/liveDaily/report" component={LiveDailyReport} />
                                    <Route path="/app/ai/liveDaily/target" component={LiveDailyTarget} />
                                    <Route path="/app/ai/liveDaily/singleItemAnalysis" component={LiveDailySingleItemAnalysis} />
                                    {/*<Route path="/app/choicePricing/initial" component={ChoicePricingInitial} />*/}
                                    {/*<Route path="/app/choicePricing/operate" component={ChoicePricingOperate} />*/}
                                    <Route path="/app/distributionManagement/distributionBrandProductAnalysis" component={DistributionBrandProductAnalysis} />
                                    <Route path="/app/JORYA/choicePricing/initial" component={ChoicePricingInitial} />
                                    <Route path="/app/JORYA/choicePricing/operate" component={ChoicePricingOperate} />
                                    <Route path="/app/JORYA/choicePricing/benefit" component={ChoicePricingBenefit} />
                                    <Route path="/app/JORYA/choicePricing/summary" component={ChoicePricingSummary} />
                                    <Route path="/app/ANMANI/choicePricing/initial" component={ChoicePricingInitial} />
                                    <Route path="/app/ANMANI/choicePricing/operate" component={ChoicePricingOperate} />
                                    <Route path="/app/ANMANI/choicePricing/benefit" component={ChoicePricingBenefit} />
                                    <Route path="/app/ANMANI/choicePricing/summary" component={ChoicePricingSummary} />
                                    <Route path="/app/CAROLINE/choicePricing/initial" component={ChoicePricingInitial} />
                                    <Route path="/app/CAROLINE/choicePricing/operate" component={ChoicePricingOperate} />
                                    <Route path="/app/CAROLINE/choicePricing/benefit" component={ChoicePricingBenefit} />
                                    <Route path="/app/CAROLINE/choicePricing/summary" component={ChoicePricingSummary} />
                                    <Route path="/app/EIN/choicePricing/initial" component={ChoicePricingInitial} />
                                    <Route path="/app/EIN/choicePricing/operate" component={ChoicePricingOperate} />
                                    <Route path="/app/EIN/choicePricing/benefit" component={ChoicePricingBenefit} />
                                    <Route path="/app/EIN/choicePricing/summary" component={ChoicePricingSummary} />
                                    <Route path="/app/humanResources/estimate/management" component={HumanResourcesManagement} />
                                    <Route path="/app/humanResources/estimate/summary" component={HumanResourcesSummary} />
                                    <Route path="/app/humanResources/estimate/detail" component={HumanResourcesDetail} />
                                    <Route path="/app/humanResources/estimate/difference" component={HumanResourDifference} />
                                    <Route path="/app/humanResources/estimate/jurisdiction" component={HumanResourJurisdiction} />
                                    <Route path="/app/humanResources/estimate/change" component={HumanResourChange} />
                                    <Route path="/app/humanResources/estimate/number" component={HumanResourNumber} />
                                    <Route path="/app/humanResources/budget/management" component={HumanResourcesManagement} />
                                    <Route path="/app/humanResources/budget/summary" component={HumanResourcesSummary} />
                                    <Route path="/app/humanResources/budget/detail" component={HumanResourcesDetail} />
                                    <Route path="/app/humanResources/budget/difference" component={HumanResourDifference} />
                                    {/* <Route path="/app/humanResources/budget/jurisdiction" component={HumanResourJurisdiction} /> */}
                                    <Route path="/app/humanResources/budget/change" component={HumanResourChange} />
                                    <Route path="/app/humanResources/budget/number" component={HumanResourNumber} />
                                    <Route path="/app/humanResources/estimate/item" component={HumanResourItem} />
                                    <Route path="/app/shortVideo/material" component={ShortVideoMaterial} />
                                    <Route path="/app/shortVideo/plan" component={ShortVideoPlan} />
                                    <Route path="/app/knowledge/search" component={KnowledgeSearch} />
                                    <Route path="/app/knowledge/examine" component={KnowledaeExamine} />
                                    <Route path="/app/knowledge/result" component={KnowledaeResult} />
                  <Route
                      path="/app/pos2023/initial"
                      component={Pos2023Initial}
                  />
                  <Route path="/app/pos2023/search" component={Pos2023Search} />
                  <Route path="/app/pos2023/create" component={Pos2023Create} />
                                    <Route path="/app/storeGroup/daily" component={StoreGroupDaily} />
                                    </StateShare>
                            </div>
                            {
                                isShowReminder ?  <div style={{position:'fixed', height:60, width:60, right:0, bottom:100, backgroundColor:'#1890FF', display:'flex', justifyContent:'center', alignItems:'center', zIndex:100000}}>
                                    <Reminder onClick={()=>{
                                        setIsReport(true)
                                    }} width={50} height={50} style={{color:'#fff'}} onMouseEnter={()=>{
                                        setIsShowReminder(true)
                                    }} onMouseLeave={()=>{
                                        setIsShowReminder(false)
                                    }}/>
                                </div> 
                                :<div style={{position:'fixed', height:60, width:20, right:0, bottom:100, backgroundColor:'#1890FF', zIndex:100000}} onMouseEnter={()=>{
                                    setIsShowReminder(true)
                                }}></div>
                            }
                        </ConfigProvider>
                    </LetterShare>
                </div>
            </div>

            <Modal
                title={<span style={{fontSize:20}}>功能使用反馈</span>}
                visible={isReport}
                centered={true}
                closable={false}
                onCancel={()=>{
                    setIsReport(false)
                    setChooseReportType([])
                    setReportContent('')
                }}
                footer={null}
                width={665}
            >
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                     <div style={{display:'flex', flexWrap:'wrap'}}>
                        {
                            reportType.map((item,index)=>{
                            return <Button type={chooseReportType.indexOf(item) != -1 ? 'primary' : ''} style={{ marginTop:5, marginRight:5}} onClick={()=>{
                                if(chooseReportType.indexOf(item) != -1){
                                        let obj = [...chooseReportType]
                                        // obj.splice(index,1)
                                        removeByVal(obj, item)
                                        setChooseReportType(obj)
                                }else{
                                    let obj = [...chooseReportType]
                                    obj.push(item)
                                    setChooseReportType(obj)
                                }
                                
                            }}>{item}</Button>
                            })
                        }
                    </div>
                    <div style={{width:'95%', marginTop:10}}>
                        {/* <b>其他(补充):</b> */}
                        <TextArea placeholder="其他补充" value={reportContent} rows={4} onChange={(e)=>{
                            setReportContent(e.target.value)
                        }}/>
                    </div>
                </div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:10}}>
                    <Button disabled={effective} type="primary" onClick={()=>{
                        let path = props.location.pathname
                        let messageContent = ""
                        if(chooseReportType.length!=0){
                            messageContent = "在页面路径为:"+path + ',存在'+chooseReportType.toString()+"等问题；"
                            if(reportContent != ''){
                                messageContent = messageContent + '补充' + reportContent
                            }
                        }else{
                            messageContent = "在页面路径为:"+path + ";"
                            if(reportContent != ''){
                                messageContent = messageContent + '补充' + reportContent
                            }
                        }
                        console.log(messageContent);
                        const formData = new FormData()
                        // 任务状态
                        formData.append('status', 1)
                        // 标题
                        formData.append('title', '功能使用反馈')
                        // 优先级
                        formData.append('priority', '紧急')
                        // 类型
                        formData.append('type', "IT工单")
                        // 内容
                        formData.append('content', messageContent)
                        // 创建人
                        formData.append('creator', cookie.load('name'))
                        // 创建人工号
                        formData.append('number', Base64.decode(cookie.load('number')))
                        // 处理人名称
                        formData.append('acceptor', '唐铎')
                        // 处理人工号
                        formData.append('acceptorNumber', '1804100055')
                        setEffective(true)
                        NbAxios(formData, "POST", servicePath.createWorkOrder).then(res=>{
                            setEffective(false)
                            if (res.success) {
                                message.success('成功反馈数据问题')
                                setIsReport(false)
                                setChooseReportType([])
                                setReportContent('')
                            } else {
                                message.warn(res.errorMsg)
                            }
                        }).catch((err) => {
                            setEffective(false)
                            message.warn('数据反馈报错，请联系IT帮忙解决')
                        })
                    }}>提交</Button>
                    <Button disabled={effective} onClick={()=>{
                        setIsReport(false)
                        setChooseReportType([])
                        setReportContent('')
                    }}>取消</Button>
                </div>
            </Modal>

            <Drawer
                title="个人信息"
                closable={false}
                onClose={onClose}
                visible={isOpen}
                width="300"
            >
                <Descriptions>
                    <Descriptions.Item label="姓名" span={5}>{cookie.load('name')}</Descriptions.Item>
                    <Descriptions.Item label="职位" span={5}>{cookie.load('roleName')}</Descriptions.Item>
                    <Descriptions.Item label="工号" span={5}>{Base64.decode(cookie.load('number'))}</Descriptions.Item>
                    <Descriptions.Item label="邮箱" span={5}>{Base64.decode(cookie.load('email'))}</Descriptions.Item>
                    <Descriptions.Item label="手机号" span={5}>{Base64.decode(cookie.load('phone'))}</Descriptions.Item>
                </Descriptions>
                <div>
                    <Button onClick={() => setUpdatePass(true)} type="primary" danger>修改密码</Button>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <Button onClick={logout} type="primary">退出登陆</Button>
                </div>
            </Drawer>

            <Modal
                title="为了保障账号安全，请先修改初始化密码"
                closable={false}
                visible={updatePassword}
                footer={[]}
            >
                {/* <Row>
                    <Col span={12} style={{ marginRight: "20px" }}>
                        <Tooltip
                            trigger={['focus']}
                            title="请输入新密码"
                            placement="topLeft"
                        >
                            <Input.Password
                                id="name"
                                size="default"
                                placeholder="请输入新密码"
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Button onClick={() => updateOk()} type="primary">确认</Button>
                    </Col>
                </Row> */}
                <Tooltip
                    trigger={['focus']}
                    title="请输入短信上的密码"
                    placement="topLeft"
                >
                    <Input.Password
                        id="name"
                        size="default"
                        placeholder="请输入短信上的密码"
                        value={oldPassword}
                        onChange={(e) => {
                            setOldPassword(e.target.value)
                        }}
                    />
                </Tooltip>
                <Tooltip
                    trigger={['focus']}
                    title="请输入新密码"
                    placement="topLeft"
                >
                    <Input.Password
                        style={{ marginTop: '20px' }}
                        id="password"
                        size="default"
                        value={password}
                        placeholder="请输入新密码"
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />
                </Tooltip>
                <Tooltip
                    trigger={['focus']}
                    title="请再次输入新密码"
                    placement="topLeft"
                >
                    <Input.Password
                        style={{ marginTop: '20px' }}
                        id="againPassword"
                        size="default"
                        value={againPassword}
                        placeholder="请再次输入新密码"
                        onChange={(e) => {
                            setAgainPassword(e.target.value)
                        }}
                    />
                </Tooltip>
                <Button onClick={() => updateOk()} type="primary" style={{ marginLeft: 350, marginTop: 20 }}>确认</Button>
            </Modal>

            <Modal
                title="修改密码"
                closable={false}
                visible={updatePass}
                onOk={updatePassOk}
                onCancel={updatePassCancel}
                okText="确认"
                cancelText="取消"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Tooltip
                    trigger={['focus']}
                    title="请输入旧密码"
                    placement="topLeft"
                >
                    <Input.Password
                        id="name"
                        size="default"
                        placeholder="请输入旧密码"
                        value={oldPassword}
                        onChange={(e) => {
                            setOldPassword(e.target.value)
                        }}
                    />
                </Tooltip>
                <Tooltip
                    trigger={['focus']}
                    title="请输入新密码"
                    placement="topLeft"
                >
                    <Input.Password
                        style={{ marginTop: '20px' }}
                        id="password"
                        size="default"
                        value={password}
                        placeholder="请输入新密码"
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />
                </Tooltip>
                <Tooltip
                    trigger={['focus']}
                    title="请再次输入新密码"
                    placement="topLeft"
                >
                    <Input.Password
                        style={{ marginTop: '20px' }}
                        id="againPassword"
                        size="default"
                        value={againPassword}
                        placeholder="请再次输入新密码"
                        onChange={(e) => {
                            setAgainPassword(e.target.value)
                        }}
                    />
                </Tooltip>
            </Modal>


            {/* 新菜单 */}
            <Drawer
                placement='left'
                onClose={onCloseDrawer}
                visible={showDrawer}
                closable={false}
                width={1000}
                bodyStyle={{ padding: '0px' }}
            >
                <Row>
                    <Col span={6} style={{ backgroundColor: '#708090', height: '100vh', overflow: 'auto' }}>
                        <div>
                            <Button icon={<MenuUnfoldOutlined />} onClick={() => setShowDrawer(false)}></Button>
                            {
                                data.map((item, index) => {
                                    return <div
                                        style={{ height: '40px', color: moduleIndex == index ? '#708090' : '#fff', backgroundColor: moduleIndex == index ? '#fff' : '#708090', paddingLeft: "20px", fontSize: "1rem", borderBottom: '1px solid #fff' }}
                                        onMouseEnter={() => chooseModule(item, index)}>{item.name}</div>
                                })
                            }
                        </div>
                    </Col>
                    <Col span={18} style={{ height: '90vh', overflow: 'auto' }}>
                        <Row>
                            {
                                menu.map(item => {
                                    return (
                                        <Col span={8}>
                                            <Card title={item.name} bordered={false} headStyle={{ color: '#708090' }}>
                                                {
                                                    item.children.map((menu, index) => {
                                                        return <Row>
                                                            <Text
                                                                style={{
                                                                    fontSize: "15px", marginBottom: "5px",
                                                                    color: menuId == menu.id ? '#708090' : '',
                                                                    background: menuId == menu.id ? "rgba(211,211,211,0.3)" : '',
                                                                    width: '100%',
                                                                    padding: '10px',
                                                                    cursor: "pointer"
                                                                }}
                                                                onMouseEnter={chooseMenuIndex.bind(this, menu.id)}
                                                                onMouseLeave={leaveMenuIndex.bind(this, menu.id)}
                                                                // menu.path + ',' + item.name + ',' + menu.name
                                                                onClick={() => handleClick(menu.path + ',' + item.name + ',' + menu.name)}>{menu.name}</Text>
                                                        </Row>
                                                        // return <div>{item.name}</div>
                                                    })
                                                }
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            </Drawer>
        </div>
    )
}


export default Index