import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Card, Input, Button, Spin, Layout, Row, Col, message, Form} from 'antd'
import {UserOutlined, KeyOutlined} from '@ant-design/icons';
import {randomNum, originalCharacter} from '../../config/utils/code'
import QueueAnim from 'rc-queue-anim'
import Texty from 'rc-texty';
import style from './index.module.css'
import cookie from 'react-cookies'
import 'rc-texty/assets/index.css';
// import 'antd/dist/antd.css'
import servicePath from '../../config/apiUrl'
import colorCss from '../../config/colorCss'
import axios from 'axios';
import Base64 from 'base-64'
import Modal from 'antd/lib/modal/Modal';
import Captcha from "react-captcha-code";
import '../../static/css/browser.css'
import NbAxios from '../../config/utils/nbAxios';

const {Header} = Layout;


//登录页面
function Login(props) {

    const layout = {
        labelCol: {span: 5},
        wrapperCol: {span: 16},
    };

    const [form] = Form.useForm();

    //用户名输入框
    const userRef = useRef(null)

    // 用户名
    const [userName, setUserName] = useState('')

    // 密码
    const [passWord, setPassWord] = useState('')

    // 是否加载
    const [isLoading, setIsLoading] = useState(false)

    // 邮箱
    const [email, setEmail] = useState('')

    // 验证码
    const [code, setCode] = useState('')

    // 手机号
    const [phone, setPhone] = useState('')

    // 用户输入的验证码
    const [checkCode, setCheckCode] = useState('')

    // 短信验证码
    const [smsCode, setSmsCode] = useState('')

    // 发送验证码
    const [isShowCode, setIsShowCode] = useState(false)

    // 倒计时时间
    const [time, setTime] = useState(600)

    // 是否修改密码
    const [updatePassword, setUpdatePassword] = useState('')

    // 忘记密码
    const [isForget, setIsForget] = useState(false)

    // 发送邮件
    const [isEmail, setIsEmail] = useState(false)

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 获取验证码
    const [btnEffective, setBtnEffective] = useState(0)

    // 文字
    const [patternText, setPatternText] = useState('深夜模式')

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // useEffect(()=>{
    //     if(props.location.pathname == '/'){
    //         message.info('请用邮件账号密码进行登录');
    //     }
    // },[])

    function dynamicLoadCss(url) {
        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = url;
        head.appendChild(link);
    }

    useEffect(() => {
        if (cookie.load('role')) {
            //判断用户是否登陆
            props.history.push('/app/myWorkbench')
        } else {
            message.info('请用邮件账号密码进行登录');
            // 用户输入框获取焦点
            setTimeout(function () {
                userRef.current.focus();
            }, 0);
            cookie.save('pattern', 0, {path: '/'})
            cookie.load('pattern') == 1 ? dynamicLoadCss(colorCss.darkCss) : dynamicLoadCss(colorCss.minCss)
        }

    }, [])

    // 检测用户使用的浏览器
    useEffect(() => {
        let userAgent = navigator.userAgent.toLowerCase(), s, o = {};
        let browser = {
            version: (userAgent.match(/(?:firefox|opera|safari|chrome|msie)[\/: ]([\d.]+)/))[1],
            safari: /version.+safari/.test(userAgent),
            chrome: /chrome/.test(userAgent),
            firefox: /firefox/.test(userAgent),
            ie: /msie/.test(userAgent),
            opera: /opera/.test(userAgent)
        }
        if (!browser.chrome) {
            let tip = document.querySelector('.tip-wapper-browser')
            setTimeout(() => {
                tip.style.display = 'none'
            }, 9000)
        } else {
            let tip = document.querySelector('.tip-wapper-browser')
            tip.style.display = 'none'
        }
    }, [])

    const changePattern = () => {
        if (cookie.load('pattern') == 0) {
            cookie.save('pattern', 1, {path: '/'})
            setPatternText('日间模式')
        } else {
            cookie.save('pattern', 0, {path: '/'})
        }
        cookie.load('pattern') == 1 ? dynamicLoadCss(colorCss.darkCss) : window.location.reload(true)
    }

    const checkLogin = () => {
        setIsLoading(true)
        if (userName == '') {
            message.error('用户名不能为空')
        } else if (passWord == '') {
            message.error('密码不能为空')
        } else {
            let password = Base64.encode(passWord)
            let dataProps = {
                'username': userName + '@bmcsoft.cn',
                "password": password
            }
            axios({
                method: 'POST',
                url: servicePath.login,
                data: dataProps,
                header: {
                    'Content-Type': 'application/json'  //如果写成contentType会报错
                },
                maxRedirects: 0
            }).then(
                res => {
                    if (res.data['success']) {
                        cookie.save('id', res.data.data['id'], {path: '/'})
                        cookie.save('watermark', res.data.data['watermark'], {path: '/'})
                        cookie.save('role', res.data.data['roleId'], {path: '/'})
                        cookie.save('name', res.data.data['user'], {path: '/'})
                        cookie.save('roleName', res.data.data['role'], {path: '/'})
                        cookie.save('phone', Base64.encode(res.data.data['phone']), {path: '/'})
                        cookie.save('email', Base64.encode(res.data.data['email']), {path: '/'})
                        cookie.save('number', Base64.encode(res.data.data['number']), {path: '/'})
                        cookie.save('flag', Base64.encode(res.data.data['flag']), {path: '/'})
                        cookie.save('token', res.data.data['token'], {path: '/'})
                        cookie.save('shopList', ['1fe8c8a8-24d7-4378-96f8-00823a11ea28', 'c99fa4ef-e679-4a76-832f-944b332cf98d'], {path: '/'})
                        // cookie.save('shopList', res.data.data['shop_list'], {path: '/'})
                        props.history.push('/app/myWorkbench')
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            )
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }

    // 确认获取验证码
    const getCode = () => {
        if (email == '') {
            message.error('请输入邮箱')
        } else {
            setBtnEffective(1)
            let dataProps = {
                'email': email,
            }
            axios({
                method: 'POST',
                url: servicePath.getVerification,
                data: dataProps,
                header: {
                    'Content-Type': 'application/json'  //如果写成contentType会报错
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success("验证码发送成功,15分有效")
                    } else {
                        setBtnEffective(0)
                        message.error(res.data['errorMsg'])
                    }
                }
            )
        }
    }

    // 确认验证码
    const emailOk = () => {
        if (btnEffective == 0) {
            message.warn('请获取验证码')
        } else {
            if (email == '') {
                message.error('请输入邮箱')
            } else {
                setEffective(1)
                let dataProps = {
                    'email': email,
                    'code': code,
                    "password": passWord
                }
                axios({
                    method: 'POST',
                    url: servicePath.checkVerification,
                    data: dataProps,
                    header: {
                        'Content-Type': 'application/json'  //如果写成contentType会报错
                    }
                }).then(
                    res => {
                        setEffective(0)
                        if (res.data['success']) {
                            message.success("密码修改成功")
                            setCode('')
                            setPassWord('')
                            setEmail('')
                            setIsEmail(false)
                        } else {
                            if (res.data['errorCode'] == 405) {
                                message.error(res.data['errorMsg'])
                                setBtnEffective(0)
                            } else {
                                message.error(res.data['errorMsg'])
                            }
                        }
                    }
                )
            }
        }


    }

    // 取消发送验证码
    const emailCancel = () => {
        if (btnEffective == 1) {
            let dataProps = {
                'email': email,
            }
            axios({
                method: 'POST',
                url: servicePath.cancelGetPassword,
                data: dataProps,
                header: {
                    'Content-Type': 'application/json'  //如果写成contentType会报错
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        setCode('')
                        setEmail('')
                        setPassWord('')
                        setBtnEffective(0)
                        setIsEmail(false)
                    }
                }
            )
        } else {
            setIsEmail(false)
        }
        message.success('取消找回密码')
    }

    // 隐藏消息
    function missTip() {
        let tip = document.querySelector('.tip-wapper-browser')
        tip.style.display = 'none'
    }

    const handleClick = useCallback(() => {
        let str = "";
        for (let i = 0; i < 4; i++) {
            const temp =
                originalCharacter[randomNum(0, originalCharacter.length - 1)];
            str = `${str}${temp}`;
        }
        setCode(str);
    }, []);


    return (
        <div className={style.loginBackGround}>
            <div className={'tip-wapper-browser'} onClick={missTip}>
                Tip:为了提升亲的使用体验感,请使用谷歌浏览器
            </div>
            <div className={style.loginContent}>
                <div></div>
                <div className={style.inputContent}>
                    <div className={style.logo}>BMC-NB</div>
                    <div className={style.inputStyle}>
                        <Input
                            id="userName"
                            size="large"
                            placeholder="请输入邮箱账号"
                            ref={userRef}
                            prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                            addonAfter="@bmcsoft.cn"
                            onChange={(e) => {
                                setUserName(e.target.value)
                            }}
                        />
                    </div>
                    <div className={style.inputStyle}>
                        <Input.Password
                            id="passWord"
                            size="large"
                            placeholder="请输入邮箱密码"
                            prefix={<KeyOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                            onChange={(e) => {
                                setPassWord(e.target.value)
                            }}
                        />
                    </div>
                    <div className={style.forget}>
                        <a onClick={() => {
                            let str = "";
                            for (let i = 0; i < 4; i++) {
                                const temp = originalCharacter[randomNum(0, originalCharacter.length - 1)];
                                str = `${str}${temp}`;
                            }
                            setCode(str);
                            setIsForget(true)
                        }}>忘记密码？</a>
                    </div>
                    <div className={style.buttonContent}>
                        <Button className={style.loginButton} size="large" block onClick={checkLogin}>
                            {<Texty type="left" mode='smooth'>登录</Texty>}
                        </Button>
                    </div>
                    {/* <div className={style.pattern}>
                        <Button type="primary" onClick={() => changePattern()}>{patternText}</Button>
                    </div> */}
                </div>
                <div className={style.propagandaBackground}>
                    <div className={style.propagandaContent}>
                        {/* <div className={style.video_box}>
                            <video
                            className={style.video_background} 
                            src='https://pic.bmcsoft.cn/imge_dir/1_1.mp4'
                            autoplay="autoplay" 
                            loop="loop" 
                            muted="muted" 
                            controls="controls"
                            controlsList="nodownload"
                            height="100%" width="100%"
                            >
                            </video>
                            <img src={image1}/>
                        </div> */}
                        {/* <a href="https://campus.bmcsoft.cn/" target="_blank"><img src="https://pic.bmcsoft.cn/imge_dir/index_1.jpg"/></a> */}
                        {/* <img src="https://pic.bmcsoft.cn/imge_dir/小脑斧.jpg"/> */}
                    </div>
                </div>
            </div>
            {/* <QueueAnim type={['right', 'left']} duration="3000" className="demo-content">
                <Header key="topNav" style={{ color: 'white', fontWeight: 'bold', fontSize: '250%' }}>
                    <Texty type="top" mode='smooth'>BMC-NB</Texty>
                </Header>
            </QueueAnim> */}
            {/* <QueueAnim type="bottom" duration="3000" className="demo-page">
                <div style={{ width: size.width * 0.2, margin: '150px auto' }} key="login-div">
                    <Spin tip="Loading...." spinning={isLoading}>
                        <Card title={<Texty type="left" mode='smooth'>SIGN IN</Texty>} bordered={true}
                            style={{ width: 400, textAlign: "center" }}>
                            <Input
                                id="userName"
                                size="large"
                                placeholder="请输入邮箱账号"
                                ref={userRef}
                                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                addonAfter="@bmcsoft.cn"
                                onChange={(e) => {
                                    setUserName(e.target.value)
                                }}
                            />
                            <br /><br />
                            <Input.Password
                                id="passWord"
                                size="large"
                                placeholder="请输入邮箱密码"
                                prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                onChange={(e) => {
                                    setPassWord(e.target.value)
                                }}
                            />
                            <br />
                            <Row>
                                <Col span={18}></Col>
                                <Col>
                                    <div style={{ margin: "10px 0 " }}>
                                        <a onClick={() => {
                                            let str = "";
                                            for (let i = 0; i < 4; i++) {
                                              const temp = originalCharacter[randomNum(0, originalCharacter.length - 1)];
                                              str = `${str}${temp}`;
                                            }
                                            setCode(str);
                                            setIsForget(true)
                                        }}>忘记密码？</a>
                                    </div>
                                </Col>
                            </Row>
                            <Button type="primary" size="large" block onClick={checkLogin}>
                                {<Texty type="left" mode='smooth'>登录</Texty>}
                            </Button>
                        </Card>
                    </Spin>
                    <div>
                        <Row style={{ marginLeft: '300px', marginTop: '30px' }}>
                            <Col span={3}>
                                <Button type="primary" onClick={() => changePattern()}>{patternText}</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </QueueAnim> */}

            {/* <Modal
                title="邮箱验证"
                centered={true}
                visible={isEmail}
                onOk={emailOk}
                onCancel={emailCancel}
                okText="确认"
                cancelText="取消"
                maskClosable={false}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Row>
                    <Col style={{ width: "300px" }}>
                        <Tooltip
                            trigger={['focus']}
                            title="请输入邮箱"
                            placement="topLeft"
                        >
                            <Input
                                id="email"
                                size="default"
                                placeholder="请输入邮箱"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Button 
                            style={{ marginLeft: "20px" }} type="primary" 
                            disabled = {btnEffective == 0 ? false : true}
                            onClick={()=>getCode()}>获取验证码</Button>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ width: "300px", marginTop: "20px" }}>
                        <Tooltip
                            trigger={['focus']}
                            title="请输入验证码"
                            placement="topLeft"
                        >
                            <Input
                                id="code"
                                size="default"
                                placeholder="请输入验证码"
                                value={code}
                                onChange={(e) => {
                                    setCode(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ width: "300px", marginTop: "20px" }}>
                        <Tooltip
                            trigger={['focus']}
                            title="请输入新密码"
                            placement="topLeft"
                        >
                            <Input.Password
                                style={{marginTop: "20px"}}
                                id="password"
                                size="default"
                                value={passWord}
                                placeholder="请输入新密码"
                                onChange={(e) => {
                                    setPassWord(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            </Modal> */}

            <Modal
                title="忘记密码"
                centered={true}
                visible={isForget}
                footer={[]}
                onCancel={() => {
                    form.resetFields()
                    setEmail('')
                    setCheckCode('')
                    setSmsCode('')
                    setIsForget(false)
                }}
                maskClosable={false}
            >
                <Form {...layout} form={form} labelAlign='left'>
                    <Form.Item label="邮箱" name="email">
                        <Input
                            placeholder="请输入邮箱账号"
                            addonAfter="@bmcsoft.cn"
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="验证码" name="code">
                        <Input.Group>
                            <Input
                                placeholder="请输入验证码"
                                onChange={(e) => {
                                    setCheckCode(e.target.value)
                                }}
                                style={{width: '40%', marginRight: "20px"}}
                            />
                            <Captcha style={{width: '40%',}} onClick={handleClick} code={code} bgColor="#cccccc"
                                     height={30}/>
                        </Input.Group>
                    </Form.Item>
                    <Form.Item label="短信验证码" help={phone == '' ? '' : `短信验证码已发送到` + phone} name="smsCode">
                        <Input.Group>
                            <Input
                                placeholder="请输入短信验证码"
                                onChange={(e) => {
                                    setSmsCode(e.target.value)
                                }}
                                style={{width: '50%', marginRight: "20px"}}
                            />
                            <a onClick={() => {
                                if (checkCode == '') {
                                    message.warn('请输入验证码')
                                } else if (checkCode != code) {
                                    message.warn('请输入正确的验证码')
                                } else if (email == '') {
                                    message.warn('请输入邮箱号')
                                } else {
                                    if (isShowCode == false) {
                                        let data = {}
                                        data.email = email + '@bmcsoft.cn'
                                        NbAxios(data, 'POST', servicePath.sendSmsForResetPassword).then(res => {
                                            if (res.success) {
                                                setPhone(res.data.phone)
                                                setIsShowCode(true)
                                                const active = setInterval(() => {
                                                    setTime((preSecond) => {
                                                        if (preSecond <= 1) {
                                                            setIsShowCode(false)
                                                            clearInterval(active)
                                                            // 重置秒数
                                                            return 600
                                                        }
                                                        return preSecond - 1
                                                    })
                                                }, 1000)
                                            } else {
                                                message.warn(res.errorMsg)
                                            }
                                        }).catch(e => {
                                            // message.success("短信验证码已发送，有效时间为10分钟！")
                                        })
                                    }

                                }
                            }}>{isShowCode ? `${time}秒后重新发送` : '发送验证码'}</a>
                        </Input.Group>
                    </Form.Item>
                    <Button type="primary" style={{marginLeft: 300}} onClick={() => {
                        if (checkCode == '') {
                            message.warn('请输入验证码')
                        } else if (checkCode != code) {
                            message.warn('请输入正确的验证码')
                        } else if (email == '') {
                            message.warn('请输入邮箱账号')
                        } else if (smsCode == '') {
                            message.warn('请输入短信验证码')
                        } else {
                            let data = {}
                            data.email = email + '@bmcsoft.cn'
                            data.activation_code = smsCode
                            NbAxios(data, "POST", servicePath.validateActivationCodeForResetPassword).then(res => {
                                if (res.success) {
                                    message.success("重置后的密码已发送到手机上面，请注意查收")
                                    setEmail('')
                                    setCheckCode('')
                                    setSmsCode('')
                                    setIsForget(false)
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }
                    }}>确定</Button>
                </Form>
            </Modal>


        </div>
    )
}

export default Login