import React, { useEffect, useState } from 'react';
import style from './index.module.css';
import PlatformEventsCalendar from '../../../component/platformEventsCalendar'
import {Typography, Button, Radio, message, Spin} from 'antd'
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl';
import NbAxios from '../../../config/utils/nbAxios';
import AcceptBuried from '../../../config/utils/buried';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
const {Title, Text} = Typography

function Index(props){
    // 开始时间
    const [ startDate , setStartDate ] = useState('')
    // 结束时间
    const [ endDate , setEndDate ] = useState('')
    // 竞店名
    const [ shopName , setShopName ] = useState('')
    // 指标
    const [ indexList ] = useState(["全店访客数", "全店搜索人数", "全店收藏人数", "全店加购人数", "全店转化率", "全店交易金额", "全店买家数","全店预售定金金额", "全店预售定金商品件数", "全店上新商品数", "搜索占比", "收藏率", "加购率", "UV价值", "客单价"]) 
    // 选择指标
    const [ chooseIndex, setChooseIndex] = useState('')
    // 指标累计
    const [ indexSum , setIndexSum ] = useState('')
    const [ oldIndexSum , setOldIndexSum ] = useState('')
    const [ growth , setGrowth ] = useState('')
    // 表格数据
    const [ tableDate, setTableDate ] = useState([])
    const [ dateLegend, setDateLegend ] = useState([])
    const [ dateSeries, setDateSeries ] = useState([])
    // 加载中
    const [ loading , setLoading ] = useState(false) 

    let report = {
        title: {
            text:  `${shopName}同比报表`
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (params, ticket, callback) {
                var showHtm="";
                for(var i=0;i<params.length;i++){
                    //x轴名称
                    var name = params[i].seriesName;
                    // 名称
                    var marker = params[i].marker;
                    //值
                    var value = params[i].value;
                    if(name.indexOf('同比') != -1){
                        showHtm+= marker +  name + '\xa0\xa0\xa0' +  value + '%<br>'
                    }else{
                        showHtm+= marker + name + '\xa0\xa0\xa0' +  value + '<br>'
                    }
                    
                }
                return showHtm;
            }
        },
        legend: {
            data: dateLegend
        },
        xAxis: {
            type: 'category',
            data: tableDate,
            axisLabel:{
              rotate:60,
            }
        },
        yAxis: [
            {
                type: 'value',
                name: `${chooseIndex}`,
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: '同比',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: dateSeries
      }

    useEffect(()=>{
        setStartDate(window.opener["filter"]['startDate']);
        setEndDate(window.opener["filter"]['endDate']);
        setShopName(window.opener["filter"]['shop']);
    },[])

    useEffect(()=>{
        if(shopName != ''){
            
            setChooseIndex(indexList[0])
            const getData = async () => {
                await Promise.all([
                    getRaceShopDetailData(indexList[0]),
                    getRaceShopTotalData(indexList[0])
                ])
            }
            getData()
        }
    },[shopName])

    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('trendChart'));
        reportChart.clear()
        // 绘制图表
        reportChart.setOption(report)
        // window捕获标签点击，表格重绘放到浏览器渲染前
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [dateSeries, tableDate, dateLegend])

    // 获取累计数据
    const getRaceShopTotalData = (field)=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            start_date:startDate,
            end_date:endDate,
            field:field,
            race_shop:shopName
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.getRaceShopTotalData).then(res => {
            setLoading(false)
            if (res.success) {
                setIndexSum(res.data['本期']);
                setOldIndexSum(res.data['上期']);
                setGrowth(res.data['同比']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('获取竞店数据详情数据接口出错,请联系IT')
        })
    }
    // 获取竞店数据详情数据接口
    const getRaceShopDetailData = (field)=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            start_date:startDate,
            end_date:endDate,
            field:field,
            race_shop:shopName
        }
        AcceptBuried('query_echarts_trends',"click",dataProps,props.location.pathname,"竞店数据")
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.getRaceShopDetailData).then(res => {
            setLoading(false)
            if (res.success) {
                setTableDate(res.data['date_list']);
                setDateLegend(res.data['legend']);
                setDateSeries(res.data['data']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('获取竞店数据详情数据接口出错,请联系IT')
        })
    }

    return (
        <div className={style.externalContent}>
            <div style={{display:'flex', justifyContent:'space-around', marginTop: 10, borderBottom: "1px solid #ccc", alignItems:'center', borderLeft:"1px solid #ccc",borderRight:"1px solid #ccc",}}>
                <Title level={4}>{`${shopName}数据趋势`}</Title>
                <div style={{display:'flex', alignItems:"center"}}>
                    <PlatformEventsCalendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
                </div>
            </div> 
            <div style={{display:'grid', gridTemplateColumns:"100px 1fr 100px", marginTop:"5px"}}>
                <b>可选指标:</b>
                <div>
                   <Radio.Group value={chooseIndex} onChange={(e)=>{
                        setIndexSum('')
                        setOldIndexSum('')
                        setGrowth('')
                        setChooseIndex(e.target.value)
                        AcceptBuried('select_indicator',"click",{'field':e.target.value},props.location.pathname,"竞店数据")
                    }}>
                        {
                            indexList.map(item=>{
                                return <Radio style={{width:"180px"}} value={item} >{item}</Radio>
                            })
                        }
                   </Radio.Group>
                </div>
                <div>
                    <Button type="primary" size="small" style={{marginLeft:"3px"}} onClick={()=>{
                        if(chooseIndex == ''){
                            message.warn('请选择指标')
                        }else{
                            const getData = async () => {
                                await Promise.all([
                                    getRaceShopDetailData(chooseIndex),
                                    getRaceShopTotalData(chooseIndex)
                                ])
                            }
                            getData()
                        }
                    }}>查询</Button>
                </div>
            </div>
            <Spin spinning={loading} tip="数据加载中....">
                <div>
                    <div style={{display:'flex', marginTop:"5px"}}>
                        <Text strong style={{fontSize:"20px"}}>{`${chooseIndex}累计:` + indexSum + `; ${chooseIndex}去年累计:` + oldIndexSum + `; ${chooseIndex}同比增长` + growth}</Text>
                    </div>
                    <div style={{ width:"100%", height:'500px', marginTop:"30px"}} id="trendChart"></div>
                </div>
            </Spin>

        </div>
    )

}


export default Index