import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, DatePicker, Input, Tag, Form, Select, Descriptions, Collapse, Spin } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import axios from 'axios'
import moment from 'moment';
import '../../static/css/Table.css'
import '../../static/css/index.css'
const { Panel } = Collapse
const { Option } = Select
const monthFormat = 'YYYY-MM-DD';

//投放管理
function Put(props) {

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const [form1] = Form.useForm();

    // 排序方式
    const [sortOrder, setSortOrder] = useState('ascend')

    //页面
    const [page, setPage] = useState(1)

    // 总数页数
    const [total, setTotal] = useState(0)

    //数据
    const [data, setData] = useState([])

    // 老数据
    const [oldData, setOldData] = useState([])

    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])

    // 是否在加载
    const [isLoading, setIsLoading] = useState(true)

    //营销集合
    const [marketingList, setMarketingList] = useState([])

    //KOL集合
    const [kolList, setKolList] = useState([])

    //投放渠道集合
    const [platformList, setPlatformList] = useState([])

    //评级集合
    const [rateList] = useState([
        '超出预期',
        '达到预期',
        '未达到预期'
    ])

    //id
    const [id, setId] = useState(0)

    //含税费用
    const [cost, setCost] = useState('')

    //投放名字
    const [name, setName] = useState('')

    //甲方名称
    const [partyName, setPartyName] = useState('')

    //甲方ID
    const [partyId, setPartyId] = useState(-1)

    //营销名称
    const [marketingName, setMarketingName] = useState('')

    //营销id
    const [marketingId, setMarketId] = useState(-1)

    //KOL名称
    const [kolName, setKolName] = useState('')

    //KOLId
    const [kolId, setKolId] = useState(-1)

    //总藏赞
    const [praise, setPraise] = useState('')

    //平均阅读数
    const [avgRead, setAvgRead] = useState('')

    //场均观看量
    const [avgView, setAvgView] = useState('')

    //平均互动量
    const [avgInteraction, setAvgInteraction] = useState('')

    //推广产品
    const [product, setProduct] = useState('')

    // 合作形式
    const [status, setStatus] = useState('')

    // 合作开始时间
    const [startTime, setStartTime] = useState('')

    //合作结束时间
    const [endTime, setEndTime] = useState('')

    //阅读量
    const [read, setRead] = useState('')

    //互动量
    const [interaction, setInteraction] = useState('')

    //阅读成本
    const [readCost, setReadCost] = useState(0)

    //互动量成本
    const [interactionCost, setInteractionCost] = useState(0)

    //销量
    const [sales, setSales] = useState('')

    //销售额
    const [sale, setSale] = useState('')

    //ROI
    const [roi, setRoi] = useState(0)

    //内容链接
    const [contentUrl, setContentUrl] = useState('')

    //投放渠道
    const [platform, setPlatform] = useState('')

    //账号链接
    const [platfromUrl, setPlatformUrl] = useState('')

    //粉丝数
    const [fans, setFans] = useState('')

    //评级
    const [rate, setRate] = useState('')

    //是否打开增加
    const [addPut, setAddPut] = useState(false)

    //是否打开更新
    const [updatePut, setUpdatePut] = useState(false)

    //是否查看明细
    const [lookDetail, setLookDetail] = useState(false)

    // 选择投放
    const [searchPut, setSearchPut] = useState('')

    // 选择营销
    const [searchName, setSearchName] = useState('')

    // 筛选甲方
    const [searchParty, setSearchParty] = useState('')

    // 选择等级
    const [searchRate, setSearchRate] = useState('')

    // 是否是检索状态
    const [isSearch, setIsSearch] = useState(0)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 表格信息
    const columns = [
        {
            title: "投放名称",
            key: 'name',
            dataIndex: 'name',
            width: '200px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: "甲方名称",
            key: 'party',
            dataIndex: 'party',
            width: '200px',
            align: 'center',
            fixed: 'left',

        },
        {
            title: "营销名称",
            key: "marketing",
            dataIndex: 'marketing',
            width: '200px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: "KOL名称",
            key: "kol",
            dataIndex: 'kol',
            width: '200px',
            align: 'center',
            fixed: 'left',
        }, {
            title: "评级",
            key: "rate",
            dataIndex: 'rate',
            width: '200px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => (
                text == "超出预期" ? <Tag color="blue">{text}</Tag> : text == "达到预期" ? <Tag color="green">{text}</Tag> : <Tag color="red">{text}</Tag>
            ),
        }, {
            title: '查看明细',
            key: 'search',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <SearchOutlined onClick={() => openDetail(index)} />
            ),
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            colSpan: jurisdiction.indexOf("kol::updatePut") != -1 ? 1 : 0,
            render: (text, record, index) => (
                <EditOutlined style={{ display: jurisdiction.indexOf("kol::updatePut") != -1 ? "" : "none" }} onClick={() => openUpdate(index)} />
            ),
        },
    ]

    //获取甲方信息/营销信息数据
    useEffect(() => {
        getAllKol()
        getAllMarketing()
        getJurisdiction()
    }, [])

    // 根据KOL_ID查询渠道
    useEffect(() => {
        getAllPlatformList()
    }, [kolId])
    // 根据KOL_ID查询渠道
    const getAllPlatformList = () => {
        let dataProps = {
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role'),
            "kol_id": kolId
        }
        axios({
            method: 'POST',
            url: servicePath.getPlatform,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setPlatformList(res.data['data']);
                } else {
                    // 这里是获取初始化数据，不用提示
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }


    //获取所有KOL
    const getAllKol = () => {
        let dataProps = {
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role')
        }
        axios({
            method: 'POST',
            url: servicePath.getNorKol,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setKolList(res.data['data']);
                } else {
                    // 这里是获取初始化数据，不用提示
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }
    // 获取所有营销信息
    const getAllMarketing = () => {
        let dataProps = {
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role')
        }
        axios({
            method: 'POST',
            url: servicePath.getAllMarketing,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setMarketingList(res.data['data']);
                } else {
                    // 这里是获取初始化数据，不用提示
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }
    // 获取所有角色/店铺对应的权限
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    }

    //获取所有的投放信息
    useEffect(() => {
        getData()
    }, [page])

    // 获取数据
    const getData = () => {
        if (isSearch == 0) {
            getAllPutData()
        } else {
            search()
        }
    }


    //获取所有投放数据
    const getAllPutData = () => {
        setIsLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "page": page
        }
        axios({
            method: 'POST',
            url: servicePath.getAllPut,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setData(res.data['data'])
                    setTotal(res.data['total'])
                    setIsLoading(false)
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //  确认添加
    const addOk = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "name": name,
            "marketing_id": marketingId,
            "kol_id": kolId,
            "platform": platform,
            "platform_url": platfromUrl,
            "fans": fans,
            "rate": rate,
            "praise": praise,
            "avg_read": avgRead,
            "avg_interaction": avgInteraction,
            "avg_view": avgView,
            "product": product,
            "status": status,
            "cost": cost,
            "startTime": startTime,
            "endTime": endTime,
            "read": read,
            "interaction": interaction,
            "sales": sales,
            "sale": sale,
            "url": contentUrl,
            "operator": cookie.load('name'),
        }
        axios({
            method: 'POST',
            url: servicePath.addPutMessage,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    message.success("成功添加投放")
                    form.resetFields();
                    setName('')
                    setMarketId(-1)
                    setKolId(-1)
                    setPlatform('')
                    setPraise('')
                    setAvgView('')
                    setPlatformUrl('')
                    setFans('')
                    setRate('')
                    setAvgRead('')
                    setAvgInteraction('')
                    setProduct('')
                    setStatus('')
                    setCost('')
                    setStartTime('')
                    setEndTime('')
                    setRead('')
                    setInteraction('')
                    setSales('')
                    setSale('')
                    setContentUrl('')
                    getData()
                    setAddPut(false)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )

    }

    // 取消添加
    const addCancel = () => {
        message.success("取消添加投放")
        form.resetFields();
        setName('')
        setMarketId(-1)
        setKolId(-1)
        setPlatform('')
        setPraise('')
        setAvgView('')
        setAvgRead('')
        setAvgInteraction('')
        setProduct('')
        setStatus('')
        setPlatformUrl('')
        setFans('')
        setRate('')
        setCost('')
        setStartTime('')
        setEndTime('')
        setRead('')
        setInteraction('')
        setSales('')
        setSale('')
        setContentUrl('')
        setAddPut(false)
    }

    // 打开更新弹框
    const openUpdate = (index) => {
        form.resetFields();
        console.log(data[index])
        setOldData(data[index])
        setId(data[index]['id'])
        setName(data[index]['name'])
        setPartyName(data[index]['party'])
        setMarketId(data[index]['marketing_id'])
        setMarketingName(data[index]['marketing'])
        setKolId(data[index]['kol_id'])
        setKolName(data[index]['kol'])
        setProduct(data[index]['product'])
        setStatus(data[index]['status'])
        setStartTime(data[index]['startTime'])
        setEndTime(data[index]['endTime'])
        setPraise(data[index]['praise'])
        setAvgRead(data[index]['avgRead'])
        setAvgView(data[index]['avgView'])
        setAvgInteraction(data[index]['avgInteraction'])
        setContentUrl(data[index]['contentUrl'])
        setRead(data[index]['read'])
        setInteraction(data[index]['interaction'])
        setReadCost(data[index]['readCost'])
        setInteractionCost(data[index]['interactionCost'])
        setCost(data[index]['cost'])
        setSale(data[index]['sale'])
        setSales(data[index]['sales'])
        setRoi(data[index]['roi'])
        setPlatform(data[index]['platform'])
        setPlatformUrl(data[index]['platfromUrl'])
        setFans(data[index]['fans'])
        setRate(data[index]['rate'])
        setUpdatePut(true)
    }

    //确认更新
    const updateOk = () => {
        let dataProps = {
            "id": id,
            "oldData": oldData,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "name": name,
            "marketing_id": marketingId,
            "kol_id": kolId,
            "platform": platform,
            "platform_url": platfromUrl,
            "fans": fans,
            "rate": rate,
            "praise": praise,
            "avg_read": avgRead,
            "avg_interaction": avgInteraction,
            "avg_view": avgView,
            "product": product,
            "status": status,
            "cost": cost,
            "startTime": startTime,
            "endTime": endTime,
            "read": read,
            "interaction": interaction,
            "sales": sales,
            "sale": sale,
            "url": contentUrl,
            "operator": cookie.load('name'),
        }
        axios({
            method: 'POST',
            url: servicePath.updatePut,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    message.success("成功修改投放")
                    form.resetFields();
                    setId(0)
                    setOldData([])
                    setName('')
                    setMarketId(-1)
                    setKolId(-1)
                    setPlatform('')
                    setPraise('')
                    setAvgView('')
                    setAvgRead('')
                    setAvgInteraction('')
                    setProduct('')
                    setStatus('')
                    setCost('')
                    setPlatformUrl('')
                    setFans('')
                    setRate('')
                    setStartTime('')
                    setEndTime('')
                    setRead('')
                    setInteraction('')
                    setSales('')
                    setSale('')
                    setContentUrl('')
                    getData()
                    setUpdatePut(false)
                } else {
                    if (res.data['errorCode'] == '401') {
                        message.error(res.data['errorMsg'])
                        form.resetFields();
                        setId(0)
                        setOldData([])
                        setName('')
                        setMarketId(-1)
                        setKolId(-1)
                        setPlatform('')
                        setPraise('')
                        setAvgView('')
                        setAvgRead('')
                        setAvgInteraction('')
                        setProduct('')
                        setStatus('')
                        setCost('')
                        setPlatformUrl('')
                        setFans('')
                        setRate('')
                        setStartTime('')
                        setEndTime('')
                        setRead('')
                        setInteraction('')
                        setSales('')
                        setSale('')
                        setContentUrl('')
                        getData()
                        setUpdatePut(false)
                    } else if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //取消更新
    const updateCancel = () => {
        message.success("取消修改投放")
        form.resetFields();
        setId(0)
        setOldData([])
        setName('')
        setMarketId(-1)
        setKolId(-1)
        setPlatform('')
        setPraise('')
        setAvgView('')
        setAvgRead('')
        setPlatformUrl('')
        setFans('')
        setRate('')
        setAvgInteraction('')
        setProduct('')
        setStatus('')
        setCost('')
        setStartTime('')
        setEndTime('')
        setRead('')
        setInteraction('')
        setSales('')
        setSale('')
        setContentUrl('')
        setUpdatePut(false)
    }

    //打开查看明细
    const openDetail = (index) => {
        setProduct(data[index]['product'])
        setStatus(data[index]['status'])
        setStartTime(data[index]['startTime'])
        setEndTime(data[index]['endTime'])
        setPraise(data[index]['praise'])
        setKolName(data[index]['kol'])
        setAvgRead(data[index]['avgRead'])
        setAvgView(data[index]['avgView'])
        setAvgInteraction(data[index]['avgInteraction'])
        setContentUrl(data[index]['contentUrl'])
        setRead(data[index]['read'])
        setInteraction(data[index]['interaction'])
        setReadCost(data[index]['readCost'])
        setInteractionCost(data[index]['interactionCost'])
        setCost(data[index]['cost'])
        setSale(data[index]['sale'])
        setSales(data[index]['sales'])
        setRoi(data[index]['roi'])
        setPlatform(data[index]['platform'])
        setPlatformUrl(data[index]['platfromUrl'])
        setFans(data[index]['fans'])
        setLookDetail(true)
    }

    // 关闭查看明细
    const closeDetail = () => {
        setProduct('')
        setStatus('')
        setStartTime('')
        setEndTime('')
        setPraise('')
        setAvgRead('')
        setAvgView('')
        setAvgInteraction('')
        setContentUrl('')
        setRead('')
        setInteraction('')
        setReadCost(0)
        setInteractionCost(0)
        setCost('')
        setSale('')
        setSales('')
        setRoi('')
        setPlatform('')
        setPlatformUrl('')
        setFans('')
        setLookDetail(false)
    }

    // 打开新增框架
    const openAdd = () => {
        setAddPut(true);
        setStartTime(moment().format("YYYY-MM-DD"));
        setEndTime(moment().add('30', 'day').format("YYYY-MM-DD"))
    }

    // 条件查询
    const search = () => {
        // 修改isSearch的值,说明是搜索状态
        setIsSearch(1)
        // 出现加载
        setIsLoading(true)

        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            'name': searchPut,
            "marketing": searchName,
            "party": searchParty,
            "page": page,
            "rate": searchRate
        }
        axios({
            method: 'POST',
            url: servicePath.searchPut,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setData(res.data['data'])
                    setTotal(res.data['total'])
                    setIsLoading(false)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 重置
    const reset = () => {
        // 数据清空
        setSearchName('')
        setSearchParty('')
        // 表单清空
        form1.resetFields();
        // 关闭搜索状态
        setIsSearch(0)
        // 将当前页设置为第一页
        setPage(1)
        // 如果当前页面是1的话。手动请求数据
        if (page == 1) {
            getAllPutData()
        }
    }


    return (
        <div>
            <Spin spinning={isLoading} size="large" tip="Loading......" style={{ marginTop: size.height * 0.3 }}>
                {/* 按钮区域 */}
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06 }}>
                    <Row>
                        <Col span={3}>
                            <Button style={{ display: jurisdiction.indexOf("kol::addPut") != -1 ? "" : "none" }} type="primary" onClick={() => openAdd()}>新增投放信息</Button>
                        </Col>
                    </Row>
                </div>
                <Collapse style={{ marginRight: size.width * 0.06, marginLeft: size.width * 0.06, marginTop: size.height * 0.02 }}>
                    <Panel header="检索条件">
                        <Form
                            form={form1}
                            style={{
                                padding: 24,
                                background: '#fbfbfb',
                            }}
                        >
                            <Row gutter={16}>
                                <Col span={5}>
                                    <Form.Item
                                        name="put"
                                        label="投放名称"
                                    >
                                        <Input
                                            placeholder="请输入投放名称"
                                            value={searchPut}
                                            onChange={(e) => {
                                                setSearchPut(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        name="name"
                                        label="营销名称"
                                    >
                                        <Input
                                            placeholder="请输入营销名称"
                                            value={searchName}
                                            onChange={(e) => {
                                                setSearchName(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        name="type"
                                        label="甲方名称"
                                    >
                                        <Input
                                            placeholder="请输入甲方名称"
                                            value={searchParty}
                                            onChange={(e) => {
                                                setSearchParty(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        name="rate"
                                        label="评级"
                                    >
                                        <Input
                                            placeholder="请输入评级"
                                            value={searchRate}
                                            onChange={(e) => {
                                                setSearchRate(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Button type="primary"
                                        onClick={() => {
                                            setPage(1)
                                            setTimeout(search(), 2000)
                                        }}
                                        style={{ marginRight: "10px" }}>检索</Button>
                                    <Button type="primary" onClick={() => reset()} danger>重置</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06 }}>
                    {/* 表单区域 */}
                    <Table
                        //表格列的配置描述
                        columns={columns}
                        //数据数组
                        dataSource={data}
                        pagination={paginationProps}
                        scroll={{ x: 1300 }}
                        //表格行 key 的取值
                        rowKey={'id'}
                        scroll={{ x:'80vw', y: `${size.width>1500?'65vh':'55vh'}` }}
                    />
                </div>
            </Spin>

            {/* 增加投放计划 */}
            <Modal
                title="添加投放计划"
                visible={addPut}
                onOk={addOk}
                onCancel={addCancel}
                centered={true}
                width={1000}
                okText="确认添加"
                cancelText="取消添加"
            >
                <Form form={form} {...layout}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="投放名称" name="name">
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写投放名称"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="name"
                                        size="default"
                                        placeholder="请填写投放名称"
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="营销名称" name="marketing">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请选择营销名称"
                                    placement="topLeft"
                                >
                                    <Select placeholder="请选择营销名称" showSearch style={{ width: 310 }} onChange={(value) => setMarketId(marketingList[marketingList.map(item => item.name).indexOf(value)]['id'])}>
                                        {
                                            marketingList.map(function (item) {
                                                return (
                                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="KOL名称" name="kol">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请选择KOL名称"
                                    placement="topLeft"
                                >
                                    <Select placeholder="请选择KOL名称" showSearch style={{ width: 310 }} onChange={(value) => setKolId(kolList[kolList.map(item => item.name).indexOf(value)]['id'])}>
                                        {
                                            kolList.map(function (item) {
                                                return (
                                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="投放渠道" name="praise">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="选择投放渠道"
                                    placement="topLeft"
                                >
                                    <Select placeholder="请选择投放渠道" showSearch style={{ width: 310 }} onChange={(value) => setPlatform(value)}>
                                        {
                                            platformList.map(function (item) {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="渠道链接" name="platfromUrl">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写渠道链接"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="platfromUrl"
                                        size="default"
                                        placeholder="填写渠道链接"
                                        onChange={(e) => {
                                            setPlatformUrl(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="粉丝数" name="fans">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写粉丝数"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="fans"
                                        size="default"
                                        placeholder="填写粉丝数"
                                        onChange={(e) => {
                                            setFans(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="总藏赞" name="praise">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写总藏赞"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="praise"
                                        size="default"
                                        placeholder="填写总藏赞"
                                        onChange={(e) => {
                                            setPraise(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="平均阅读量" name="read">
                                {/* 填写客户名称 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写平均阅读量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="read"
                                        size="default"
                                        placeholder="填写平均阅读量"
                                        onChange={(e) => {
                                            setAvgRead(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="场均观看量" name="avgView">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写场均观看量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="avgView"
                                        size="default"
                                        placeholder="请填写场均观看量"
                                        onChange={(e) => {
                                            setAvgView(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="平均互动量" name="interaction">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写平均互动量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="interaction"
                                        size="default"
                                        placeholder="请填写平均互动量"
                                        onChange={(e) => {
                                            setAvgInteraction(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="推广产品" name="product">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写推广产品"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="product"
                                        size="default"
                                        placeholder="请填写推广产品"
                                        onChange={(e) => {
                                            setProduct(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合作形式" name="form">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写合作形式"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="status"
                                        size="default"
                                        placeholder="请填写合作形式"
                                        onChange={(e) => {
                                            setStatus(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="含税费用" name="cost">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写含税费用"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="cost"
                                        size="default"
                                        placeholder="请填写含税费用"
                                        onChange={(e) => {
                                            setCost(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="开始时间" name="startTime">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写合作开始时间"
                                    placement="topLeft"
                                >
                                    <DatePicker defaultValue={moment()} placeholder="请填写合作开始时间" style={{ width: 310 }} onChange={(date, dateString) => { setStartTime(dateString) }} />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="结束时间" name="endTime">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写合作结束时间"
                                    placement="topLeft"
                                >
                                    <DatePicker defaultValue={moment().add('30', 'day')} placeholder="请填写合作结束时间" style={{ width: 310 }} onChange={(date, dateString) => { setEndTime(dateString) }} />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="内容链接" name="joint">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写内容连接"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="joint"
                                        size="default"
                                        placeholder="请填写内容连接"
                                        onChange={(e) => {
                                            setContentUrl(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="阅读量" name="read">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写阅读量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="read"
                                        size="default"
                                        placeholder="请填写阅读量"
                                        onChange={(e) => {
                                            setRead(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="互动量" name="interaction">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写互动量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="interaction"
                                        size="default"
                                        placeholder="请填写互动量"
                                        onChange={(e) => {
                                            setInteraction(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="销量" name="market">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写销量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="market"
                                        size="default"
                                        placeholder="请填写销量"
                                        onChange={(e) => {
                                            setSales(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="销售额" name="sale">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写销售额"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="sale"
                                        size="default"
                                        placeholder="请填写销售额"
                                        onChange={(e) => {
                                            setSale(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="评级" name="rate">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="选择评级"
                                    placement="topLeft"
                                >
                                    <Select placeholder="请选择评级" style={{ width: 310 }} onChange={(value) => setRate(value)}>
                                        {
                                            rateList.map(function (item) {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {/* 更新投放计划 */}
            <Modal
                title="更新投放计划"
                visible={updatePut}
                onOk={updateOk}
                onCancel={updateCancel}
                centered={true}
                width={1000}
                okText="确认更新"
                cancelText="取消更新"
            >
                <Form form={form} {...layout}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="投放名称" name="name">
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写投放名称"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="name"
                                        size="default"
                                        placeholder="请填写投放名称"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="营销名称" name="marketing">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请选择营销名称"
                                    placement="topLeft"
                                >
                                    <Select defaultValue={marketingName} showSearch placeholder="请选择营销名称" style={{ width: 310 }} onChange={(value) => setMarketId(marketingList[marketingList.map(item => item.name).indexOf(value)]['id'])}>
                                        {
                                            marketingList.map(function (item) {
                                                return (
                                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="KOL名称" name="kol">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请选择KOL名称"
                                    placement="topLeft"
                                >
                                    <Select defaultValue={kolName} showSearch placeholder="请选择KOL名称" style={{ width: 310 }} onChange={(value) => setKolId(kolList[kolList.map(item => item.name).indexOf(value)]['id'])}>
                                        {
                                            kolList.map(function (item) {
                                                return (
                                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="投放渠道" name="praise">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="选择投放渠道"
                                    placement="topLeft"
                                >
                                    <Select defaultValue={platform} showSearch placeholder="请选择投放渠道" style={{ width: 310 }} onChange={(value) => setPlatform(value)}>
                                        {
                                            platformList.map(function (item) {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="渠道链接" name="platfromUrl">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写渠道链接"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="platfromUrl"
                                        size="default"
                                        placeholder="填写渠道链接"
                                        value={platfromUrl}
                                        onChange={(e) => {
                                            setPlatformUrl(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="粉丝数" name="fans">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写粉丝数"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="fans"
                                        size="default"
                                        placeholder="填写粉丝数"
                                        value={fans}
                                        onChange={(e) => {
                                            setFans(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="总藏赞" name="praise">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写总藏赞"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="praise"
                                        size="default"
                                        placeholder="填写总藏赞"
                                        value={praise}
                                        onChange={(e) => {
                                            setPraise(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="平均阅读量" name="read">
                                {/* 填写客户名称 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写平均阅读量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="read"
                                        size="default"
                                        placeholder="填写平均阅读量"
                                        value={avgRead}
                                        onChange={(e) => {
                                            setAvgRead(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="场均观看量" name="avgView">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写场均观看量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="avgView"
                                        size="default"
                                        placeholder="请填写场均观看量"
                                        value={avgView}
                                        onChange={(e) => {
                                            setAvgView(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="平均互动量" name="interaction">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写平均互动量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="interaction"
                                        size="default"
                                        placeholder="请填写平均互动量"
                                        value={avgInteraction}
                                        onChange={(e) => {
                                            setAvgInteraction(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="推广产品" name="product">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写推广产品"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="product"
                                        size="default"
                                        placeholder="请填写推广产品"
                                        value={product}
                                        onChange={(e) => {
                                            setProduct(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合作形式" name="form">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写合作形式"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="status"
                                        size="default"
                                        placeholder="请填写合作形式"
                                        value={status}
                                        onChange={(e) => {
                                            setStatus(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="含税费用" name="cost">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写含税费用"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="cost"
                                        size="default"
                                        placeholder="请填写含税费用"
                                        value={cost}
                                        onChange={(e) => {
                                            setCost(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="开始时间" name="startTime">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写合作开始时间"
                                    placement="topLeft"
                                >
                                    <DatePicker defaultValue={moment(startTime, monthFormat)} placeholder="请填写合作开始时间" style={{ width: 310 }} onChange={(date, dateString) => { setStartTime(dateString) }} />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="结束时间" name="endTime">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写合作结束时间"
                                    placement="topLeft"
                                >
                                    <DatePicker defaultValue={moment(endTime, monthFormat)} placeholder="请填写合作结束时间" style={{ width: 310 }} onChange={(date, dateString) => { setEndTime(dateString) }} />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="内容链接" name="joint">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写内容连接"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="joint"
                                        size="default"
                                        placeholder="请填写内容连接"
                                        value={contentUrl}
                                        onChange={(e) => {
                                            setContentUrl(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="阅读量" name="read">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写阅读量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="read"
                                        size="default"
                                        placeholder="请填写阅读量"
                                        value={read}
                                        onChange={(e) => {
                                            setRead(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="互动量" name="interaction">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写互动量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="interaction"
                                        size="default"
                                        placeholder="请填写互动量"
                                        value={interaction}
                                        onChange={(e) => {
                                            setInteraction(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="销量" name="market">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写销量"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="market"
                                        size="default"
                                        placeholder="请填写销量"
                                        value={sales}
                                        disabled={sales == "****" ? true : false}
                                        onChange={(e) => {
                                            setSales(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="销售额" name="sale">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写销售额"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="sale"
                                        size="default"
                                        placeholder="请填写销售额"
                                        value={sale}
                                        disabled={sale == "****" ? true : false}
                                        onChange={(e) => {
                                            setSale(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item label="评级" name="rate">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="选择评级"
                                    placement="topLeft"
                                >
                                    <Select value={rate} placeholder="请选择评级" style={{ width: 310 }} onChange={(value) => setRate(value)}>
                                        {
                                            rateList.map(function (item) {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>

            {/* 查看明细 */}
            <Modal
                visible={lookDetail}
                onCancel={closeDetail}
                width={1000}
                centered={true}
                footer={[]}
            >
                <Descriptions
                    title="明细表"
                    bordered
                    column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                    <Descriptions.Item label="总藏赞">{praise}</Descriptions.Item>
                    <Descriptions.Item label="平均阅读数">{avgRead}</Descriptions.Item>
                    <Descriptions.Item label="场均观看量">{avgView}</Descriptions.Item>
                    <Descriptions.Item label="平均互动量">{avgInteraction}</Descriptions.Item>
                    <Descriptions.Item label="推广产品">{product}</Descriptions.Item>
                    <Descriptions.Item label="合作形式">{status}</Descriptions.Item>
                    <Descriptions.Item label="含税费用">{cost}</Descriptions.Item>
                    <Descriptions.Item label="合作开始时间">{startTime} </Descriptions.Item>
                    <Descriptions.Item label="合作结束时间">{endTime} </Descriptions.Item>
                    <Descriptions.Item label="阅读量">{read}</Descriptions.Item>
                    {/* <Descriptions.Item label="内容连接" span={3}> {contentUrl}</Descriptions.Item> */}
                    <Descriptions.Item label="内容连接"> <a href={contentUrl} target="_blank">点击查看</a></Descriptions.Item>
                    {/* <a href={network} target="_blank">点击查看</a> */}
                    <Descriptions.Item label="互动量">{interaction}</Descriptions.Item>
                    <Descriptions.Item label="阅读成本">{readCost}</Descriptions.Item>
                    <Descriptions.Item label="互动成本">{interactionCost}</Descriptions.Item>
                    <Descriptions.Item label="投放渠道">{platform} </Descriptions.Item>
                    {/* <Descriptions.Item label="账号链接">{platfromUrl} </Descriptions.Item> */}
                    <Descriptions.Item label="KOL名称">{kolName} </Descriptions.Item>
                    <Descriptions.Item label="账号链接"><a href={platfromUrl} target="_blank">点击查看</a> </Descriptions.Item>
                    <Descriptions.Item label="粉丝数">{fans} </Descriptions.Item>
                    <Descriptions.Item label="销量"> {sales}</Descriptions.Item>
                    <Descriptions.Item label="销售额">{sale} </Descriptions.Item>
                    <Descriptions.Item label="ROI">{roi} </Descriptions.Item>

                </Descriptions>
            </Modal>
        </div>
    )
}

export default Put