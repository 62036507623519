import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Table, message, Tag, Spin, Input, Space, Button, Tooltip, Col, Drawer } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment'
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import axios from 'axios'

function Store(props) {

    const { month, shopOrProduct,category,download } = props

    //定义每页显示数量,默认为10
    const [pageSize, setPageSize] = useState(10)

    //定义当前页码,默认为1
    const [page, setPage] = useState(1)

    //控制查看排行抽屉的参数
    const [drawer, setDrawer] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //定义筛选功能
    const [searchText, setSearchText] = useState('')

    const [searchedColumn, setSearchedColumn] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };

    // 店铺排名表头
    const storeColumns = [
        {
            title: '',
            dataIndex: '店铺',
            key: '店铺',
            width: 50,
            ellipsis: true,
            fixed: 'left',
            align: 'center',
            render: (text, record, index) =>
                <img style={{ width: 30, height: 30 }} src={`http:${record.店铺图片}`} alt={''} />
        },
        {
            title: '店铺',
            dataIndex: '店铺',
            key: '店铺',
            width: 200,
            align: 'center',
            fixed: 'left',
            ellipsis: true,
            ...getColumnSearchProps('店铺'),
            render: (text, record, index) =>
                <Tooltip title={text} >
                    <div>{text}</div>
                </Tooltip>
        },
        {
            title: '平台',
            dataIndex: '平台',
            key: '平台',
            width: 100,
            align: 'center',
        },
        {
            title: '类目',
            dataIndex: '类目',
            key: '类目',
            width: 140,
            align: 'center',
            ellipsis: true,
            render:(text, record, index)=>{
                return (
                    <Tooltip title={text}>
                        {text}
                    </Tooltip>
                )
            }
        },
        {
            title: '交易金额(元)',
            dataIndex: '交易金额',
            key: '交易金额',
            align: 'center',
            width: 200,
            sorter: (a, b) => {
                setPage(1);
                return (b.交易金额 - a.交易金额)
            },
        },
        {
            title: '交易增长幅度%',
            dataIndex: '交易增长幅度',
            key: '交易增长幅度',
            align: 'center',
            width: 150,
            sorter: (a, b) => {
                setPage(1);
                return (b.交易增长幅度 - a.交易增长幅度)
            },
            render: (text, record, index) => {
                let color = 'red';
                if (text == null) {
                    return text
                }
                else if (text.indexOf('-') !== -1) {
                    color = 'green'

                }
                return (
                    <Tag color={color} key={index}>
                        {text.toUpperCase()}
                    </Tag>
                )
            }
        },
        {
            title: '支付转化率%',
            dataIndex: '支付转化率',
            key: '支付转化率',
            width: 140,
            align: 'center',
            sorter: (a, b) => {
                setPage(1);
                return (b.支付转化率 - a.支付转化率)
            },
            render: (text, record, index) => {
                let color = 'red';
                if (text == null) {
                    return text
                }
                else if (text.indexOf('-') !== -1) {
                    color = 'green'
                }
                return (
                    <Tag color={color} key={index}>
                        {text.toUpperCase()}
                    </Tag>
                )
            }
        },
        {
            title: 'uv价值',
            dataIndex: 'uv价值',
            key: 'uv价值',
            width: 100,
            align: 'center',
            sorter: (a, b) => {
                setPage(1);
                return (b.uv价值 - a.uv价值)
            },
        },
        {
            title: '搜索人数',
            dataIndex: '搜索人数',
            key: '搜索人数',
            width: 120,
            align: 'center',
            sorter: (a, b) => {
                setPage(1);
                return (b.搜索人数 - a.搜索人数)
            },
        },
        {
            title: '搜索占比',
            dataIndex: '搜索占比',
            key: '搜索占比',
            width: 120,
            align: 'center',
            sorter: (a, b) => {
                setPage(1);
                return (b.搜索占比 - a.搜索占比)
            },
        },
        {
            title: '访客数',
            dataIndex: '访客数',
            key: '访客数',
            width: 100,
            align: 'center',
            sorter: (a, b) => {
                setPage(1);
                return (b.访客数 - a.访客数)
            },
        },
        {
            title: '买家数',
            dataIndex: '买家数',
            key: '买家数',
            width: 100,
            align: 'center',
            sorter: (a, b) => {
                setPage(1);
                return (b.买家数 - a.买家数)
            },
        },
        {
            title: '客单价',
            dataIndex: '客单价',
            key: '客单价',
            width: 100,
            align: 'center',
            sorter: (a, b) => {
                setPage(1);
                return (b.客单价 - a.客单价)
            },
        },
    ];

    //设置表格加载状态
    const [isLoading, setIsLoading] = useState(false)

    // 定义店铺数据
    const [store, setStore] = useState([])

    // 获取店铺数据
    useEffect(() => {
        getAllStore()
    },
        [month, shopOrProduct]
    )

    const getAllStore = () => {
        if (month === '' && month === null) {
            message.warn('请选择日期！')
        }
        else if (shopOrProduct == '店铺') {
            setIsLoading(true)
            let dataProps = {
                "shopList": cookie.load("shopList"),
                "roleId": cookie.load('role'),
                "lm": category,
                "month": month.format('YYYY-MM'),
            };
            axios({
                method: 'POST',
                url: servicePath.getStoreRank,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data.success) {
                        //获取数据成功
                        let store = res.data.data
                        setStore(store)
                        if (store == null || store == [] || store == '') {
                            message.warn('当前类目或日期暂无数据！', 1)
                            setIsLoading(false)
                        } else { message.success('店铺排名已加载！', 1) }
                        setPage(1);
                        setIsLoading(false);
                    }
                    else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            message.warn(res.data.errorMsg)
                            setIsLoading(false)
                        }

                    }
                }
            ).catch(error => {
                setIsLoading(false)
            })
        }
    }

    //分页器参数
    const paginationProps = {
        showSizeChanger: false,
        showQuickJumper: true,
        //页码改变时，设置当前页码和每页显示条数
        current: page,
        onChange: (page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
        },
    }

    //定义表头的函数
    function header() {
        return (
            <div style={{fontSize:'1rem'}}>
                {`${props.month.format('YYYY-MM')}统计 共${store.length}条数据 `}
            </div>
        )
    }

    return (
        <div>
            <Spin
                tip='数据请求中，请稍等...'
                spinning={isLoading}
            >
                <Table
                    title={(store) => header()}
                    columns={storeColumns}
                    dataSource={store}
                    pagination={paginationProps}
                    size='large'
                    // scroll={{ y: size.height * 0.6 }}
                    scroll={{ x: size.width * 0.8, y: `${size.width>1500?'90vh':'50vh'}`}}
                />
            </Spin>
        </div>
    )
}
export default Store