import React, { useState, useEffect } from 'react';
import style from './index.module.css';
import {Button,Popover,Tag, Checkbox, Input,Tooltip, Typography, Form, Modal, DatePicker, Upload, message, Spin, Table} from 'antd'
import {  InboxOutlined,  EyeOutlined,SearchOutlined, QuestionCircleOutlined} from '@ant-design/icons'
import servicePath from '../../../config/apiUrl';
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios';
import locale from 'antd/es/date-picker/locale/zh_CN';
import webUrl from '../../../config/webUrl';
import moment from 'moment'
const {Text} = Typography
const { Dragger } = Upload;
const yearFormat = 'YYYY';
function Index(props){
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    };
     // 标签展示框
     const labelContent =(data)=> {
        return <div style={{width:"240px", display:'flex', padding:"5px", flexWrap:'wrap'}}>
          {
              data.map(item=>{
                 return <Tag color="blue" style={{marginTop:"2px"}}>{item}</Tag>
             })
          }
        </div>
     }
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    // 表头
    const [newColumnsList] = useState([
        {title:'行业类目', name:'industry_category'},
        {title:'作者', name:'author'},
        {title:'报告年份', name:'year'},
        {title:'内容标签', name:'content_label'},
        {title:'网站站点', name:'web'},
    ])
    const [ newColumns, setNewColumns] = useState([])
    // 上传报告
    const [uploadForm] = Form.useForm()
    // 关键字
    const [ keywords, setKeywords] = useState('')
    // 最新行业报告
    const [ newReport , setNewReport ] = useState([])
    // 最热行业报告
    const [ hotReport , setHotReport] = useState([])
    // 临时报告
    const [ temporaryReport , setTemporaryReport] = useState([])
    // 是否上传报告
    const [ isUpload , setIsUpload ] = useState(false)
    // 是否批量上传报告
    const [ isBatchUpload, setIsBatchUpload] = useState(false)
    // 编辑报告
    const [ isEditReport , setIsEditReport ] = useState(false)
    // 报告年份
    const[ reportYear, setReportYear ] = useState('')
    // 概述
    const [ summary , setSummary] = useState('')
    // 报告文件
    const [reportFile, setReportFile] = useState([])
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 模块列表
    const [shopList] = useState(['b9e67dd3-650c-4ed4-a84a-42362370fcb9'])
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    useEffect(()=>{
        searchTabKnoledge()
        getJurisdiction()
    },[])

    useEffect(()=>{
        setNewColumns([
            ...newColumnsList.map(item=>{
                return {
                    title: item.title,
                    align: 'center',
                    dataIndex: item.name,
                    key: item.name,
                    width:90,
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 5,
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div>
                            {
                                item.title == '内容标签' ? <Popover placement="bottom" content={labelContent(text)}><a><EyeOutlined /></a> </Popover>
                                :item.title == '文件名字' ? <Tooltip placement="top" title={record.filename}>
                                     <a onClick={()=>recordAccessesKnoledge(record.report_id)} style={{display:'block', fontWeight:'bolder', width:'90px', textOverflow:'ellipsis', whiteSpace:"nowrap", overflow:'hidden'}} 
                                     href={`https://pic.bmcsoft.cn/BMC知识库/`+record.filename} target="_blank">{record.filename}</a>
                                </Tooltip>
                                :item.title == '网站站点' ? <a href={text} target="_blank"><SearchOutlined /> </a>
                                :item.title == '报告年份' ? <span>{text}</span>
                                :<Tooltip placement="top" title={text}>
                                   <div style={{width:'90px', textOverflow:'ellipsis', whiteSpace:"nowrap", overflow:'hidden'}}> {text}</div>
                                </Tooltip>
                            }
                        </div>
                    )
                }
            }),
        ])
    },[])

    //获取权限代码
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": shopList
        }
        NbAxios(dataProps, "POST", servicePath.getRoleShopPorts).then(res => {
            if (res.success) {
                setJurisdiction(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取最新、最热报告
    const searchTabKnoledge = ()=>{
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        setLoading(true)
        NbAxios(values, "POST", servicePath.searchTabKnoledge).then(res => {
            setLoading(false)
            if (res.success) {
                setNewReport(res.data.new)
                setHotReport(res.data.hot)
               
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('获取最新、最热报告报错，请联系IT帮忙解决')
        })
    }

    // 参考图文件上传
    const reportDrawingHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['pdf']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setReportFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setReportFile(fileList)
        }
    }

     // 参考图文件上传
     const reporBatchDrawingHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['pdf']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件
                    setReportFile(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setReportFile(fileList)
        }
    }

    // 上传文件
    const uploadFile = (value)=>{
        if(reportFile.length == 0){
             message.warn('请选择报告文件')
             setReportYear('')
        }else{
           let nowReportYear = reportYear == '' ? moment().format('YYYY') : reportYear
            if(value.industry_category == undefined){
                value.industry_category = '商业'
            }
            if(value.author == undefined){
                value.author = '未知'
            }
            if(value.web == undefined){
                value.web = 'http://www.baidu.com'
            }
            const formData = new FormData()
            // 报告文件
            reportFile.forEach((item) => {
                formData.append('flie', item['originFileObj']);
            })
            formData.append('roleId', cookie.load('role'))
            formData.append('shopList', cookie.load('shopList'))
            formData.append('industry_category', value.industry_category)       
            formData.append('author', value.author)       
            formData.append('web', value.web)       
            formData.append('summary', summary)       
            formData.append('creator', cookie.load('name'))       
            formData.append('year', nowReportYear)  
            setLoading(true)
            NbAxios(formData, "POST", servicePath.uploadReportKnoledge).then(res => {
                setLoading(false)
                if (res.success) {
                    message.success('上传报告成功')
                    closeUploadReport()
                } else {
                    setReportYear('')
                    message.warn({ content: res.errorMsg })
                }
            }).catch((err) => {
                setLoading(false)
                setReportYear('')
                // 获取500错误
                message.warn('上传报告报错，请联系IT帮忙解决')
            })
        }
    }

     // 批量上传文件
    const uploadBatchFile = (value)=>{
        if(reportFile.length == 0){
             message.warn('请选择报告文件')
             setReportYear('')
        }else{
            let nowReportYear = reportYear == '' ? moment().format('YYYY') : reportYear
            if(value.industry_category == undefined){
                value.industry_category = '商业'
            }
            if(value.author == undefined){
                value.author = '未知'
            }
            if(value.web == undefined){
                value.web = 'http://www.baidu.com'
            }
            const formData = new FormData()
            // 报告文件
            reportFile.forEach((item) => {
                formData.append('flie', item['originFileObj']);
            })
            formData.append('roleId', cookie.load('role'))
            formData.append('shopList', cookie.load('shopList'))
            formData.append('industry_category', value.industry_category)       
            formData.append('author', value.author)       
            formData.append('web', value.web)       
            formData.append('summary', summary)       
            formData.append('creator', cookie.load('name'))       
            formData.append('year', nowReportYear)  
            setLoading(true)
            NbAxios(formData, "POST", servicePath.batchUploadINterimReportKnoledge).then(res => {
                setLoading(false)
                if (res.success) {
                    message.success('上传报告成功')
                    setTemporaryReport(res.data);
                    closeUploadReport()
                    setIsEditReport(true)
                } else {
                    setReportYear('')
                    message.warn({ content: res.errorMsg })
                }
            }).catch((err) => {
                setLoading(false)
                setReportYear('')
                // 获取500错误
                message.warn('上传报告报错，请联系IT帮忙解决')
            })
        }
    }
    // 关闭上传报告
    const closeUploadReport = ()=>{
        setReportFile([])
        setReportYear('')
        setSummary('')
        uploadForm.resetFields()
        setIsUpload(false) 
        setIsBatchUpload(false)    
    }

    // 关闭编辑报告
    const closeEditReport = ()=>{
        setIsEditReport(false)
    }

    const recordAccessesKnoledge = (report_id)=>{
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        values["user_name"] = cookie.load('name');
        values["user_id"] = cookie.load('id');
        values["report_id"] = report_id
        setLoading(true)
        NbAxios(values, "POST", servicePath.recordAccessesKnoledge).then(res => {
            setLoading(false)
            if (res.success) {
                searchTabKnoledge()
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('记录访问量报错，请联系IT帮忙解决')
        })
    }

    // 编辑报告
    const editReport = ()=>{
        console.log(temporaryReport);
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        values["creator"] = cookie.load('name');
        values["temporary_report_data"] = temporaryReport
        setLoading(true)
        NbAxios(values, "POST", servicePath.editInterimReportKnoledge).then(res => {
            setLoading(false)
            if (res.success) {
                message.success('上传报告成功')
                setTemporaryReport([])
                setIsEditReport(false)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('获取上传报告报错，请联系IT帮忙解决')
        })
    }

    return (
        <div style={{ backgroundColor: '#F5F5F5', height: "calc(100vh - 40px)", overflow: 'auto' }}>
            <div className={style.externalContent}>
                <div style={{marginTop:'5px', display:"flex", justifyContent:"flex-end"}}>
                    <Button type="primary" danger onClick={()=>setIsUpload(true)}>上传报告</Button>
                    <Button type="primary" danger onClick={()=>setIsBatchUpload(true)} style={{marginLeft:'5px'}}>批量上传报告</Button>
                    {/* <Button type="primary" danger onClick={()=>setIsEditReport(true)} style={{marginLeft:'5px'}}>批量上传报告</Button> */}
                    <Button type="link" style={{marginLeft:'5px', display:jurisdiction.indexOf('examine::knowledge') == -1 ? 'none' : ''}} onClick={()=>{
                       window.open(`${webUrl}/app/knowledge/examine`)
                    }}>审核报告</Button>
                </div>
                <div style={{marginTop:"80px", display:'flex', justifyContent:'center'}}>
                    <div style={{width:"1100px", border:"1px solid #ccc", display:"flex", padding:"10px"}}>
                        <Input style={{width:"1000px"}} bordered={false} placeholder={"请输入内容关键字，以空格拼接多个关键字"} value={keywords} onChange={(e)=>{
                            setKeywords(e.target.value)
                        }} onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                props.history.push({ 'pathname': '/app/knowledge/result', 'state': { 'keywords': keywords} }) 
                            }
                        }} />
                        <Button type="primary" style={{width:"80px"}} onClick={()=>{
                            if(keywords == ''){
                                message.warn('请输入关键字')
                            }else{
                                props.history.push({ 'pathname': '/app/knowledge/result', 'state': { 'keywords': keywords} }) 
                            }
                        }}>搜索</Button>
                    </div>
                </div>
                <Spin spinning={loading}>
                    <div style={{marginTop:'30px', display:'flex', justifyContent:"center"}}>
                        <div style={{width:"670px", border:'1px dashed #ccc', height:"800px", padding:"10px"}}>
                            <div style={{display:'flex'}}>
                                <Text strong style={{fontSize:'20px'}}>最新行业报告</Text>
                                <Tooltip title="用户最新上传的报告">
                                    <QuestionCircleOutlined style={{fontSize:'15px', marginTop:'6px', marginLeft:"5px"}}/>
                                </Tooltip>
                            </div>
                            {/* <Table columns={newColumns} dataSource={newReport} pagination={false} size="small" style={{marginTop:"10px"}}/> */}
                            <div style={{height:'740px', overflow:'auto'}}>
                                {
                                    newReport.map(item=>{
                                        return <div style={{boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)',height:"80px",  marginTop:"10px", padding:"10px"}}>
                                            <div style={{display:'flex', justifyContent:"center"}}>
                                                <a onClick={()=>recordAccessesKnoledge(item.report_id)} style={{ fontWeight:'bolder', marginTop:"10px"}} href={`https://pic.bmcsoft.cn/BMC知识库/`+item.filepath} target="_blank">{item.filename}</a>
                                            </div>
                                            <div style={{ display:'flex' , justifyContent:"space-around", marginTop:"10px"}}>
                                                {
                                                    newColumnsList.map(columnsItem=>{
                                                        return  <div style={{ display:"flex", width:"130px"  }}>
                                                            <b>{columnsItem.title}:</b>
                                                            {
                                                                columnsItem.title == '网站站点' ? <a href={item[columnsItem.name]} target="_blank" style={{marginLeft:'3px'}}><SearchOutlined /> </a>
                                                                :columnsItem.title == '行业类目' ? <Tooltip title={item[columnsItem.name]}><span style={{width:"60px",textOverflow:"ellipsis", whiteSpace:'nowrap', overflow:"hidden"}}>{item[columnsItem.name]}</span></Tooltip>
                                                                :columnsItem.title ==  '内容标签' ? <Popover placement="bottom" content={labelContent(item[columnsItem.name])}><a style={{marginLeft:'3px'}}><EyeOutlined /></a> </Popover>
                                                                :<span>{item[columnsItem.name]}</span>
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            {/* <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                {
                                    newReport.map(item=>{
                                        return <div>
                                            <a onClick={()=>recordAccessesKnoledge(item.report_id)} style={{marginTop:"10px", fontSize:"18px"}} href={`https://pic.bmcsoft.cn/BMC知识库/`+item.filepath} target="_blank">{item.filename}</a>
                                        </div>
                                    })
                                }
                            </div> */}
                        </div>
                        <div style={{width:"670px", border:'1px dashed #ccc', height:"800px", marginLeft:"10px", padding:"10px", overflowY:'auto'}}>
                            <div style={{display:'flex'}}>
                                <Text strong style={{fontSize:'20px'}}>最热行业报告</Text>
                                <Tooltip title="浏览量最多的报告">
                                    <QuestionCircleOutlined style={{fontSize:'15px', marginTop:'6px', marginLeft:"5px"}}/>
                                </Tooltip>
                            </div>
                            {/* <Table columns={newColumns} dataSource={hotReport} pagination={false} size="small" style={{marginTop:"10px"}}/> */}
                            <div style={{height:'740px', overflow:'auto'}}>
                                {
                                    hotReport.map(item=>{
                                        return <div style={{boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)',height:"80px",  marginTop:"10px", padding:"10px"}}>
                                            <div style={{display:'flex', justifyContent:"center"}}>
                                                <a onClick={()=>recordAccessesKnoledge(item.report_id)} style={{ fontWeight:'bolder', marginTop:"10px"}} href={`https://pic.bmcsoft.cn/BMC知识库/`+item.filepath} target="_blank">{item.filename}</a>
                                            </div>
                                            <div style={{ display:'flex' , justifyContent:"space-around", marginTop:"10px"}}>
                                                {
                                                    newColumnsList.map(columnsItem=>{
                                                        return  <div style={{ display:"flex", width:"130px"  }}>
                                                            <b>{columnsItem.title}:</b>
                                                            {
                                                                columnsItem.title == '网站站点' ? <a href={item[columnsItem.name]} target="_blank" style={{marginLeft:'3px'}}><SearchOutlined /> </a>
                                                                :columnsItem.title == '行业类目' ? <Tooltip title={item[columnsItem.name]}><span style={{width:"60px",textOverflow:"ellipsis", whiteSpace:'nowrap', overflow:"hidden"}}>{item[columnsItem.name]}</span></Tooltip>
                                                                :columnsItem.title ==  '内容标签' ? <Popover placement="bottom" content={labelContent(item[columnsItem.name])}><a style={{marginLeft:'3px'}}><EyeOutlined /></a> </Popover>
                                                                :<span>{item[columnsItem.name]}</span>
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            
                            {/* <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                {
                                    hotReport.map(item=>{
                                        return <div>
                                            <a onClick={()=>recordAccessesKnoledge(item.report_id)} style={{marginTop:"10px", fontSize:"18px"}} href={`https://pic.bmcsoft.cn/BMC知识库/`+item.filepath} target="_blank">{item.filename}</a>
                                        </div>
                                    })
                                }
                            </div> */}
                        </div>
                    </div>
                </Spin>
            </div>

            {/* 上传报告 */}
            <Modal
                title="上传报告"
                visible={isUpload}
                onCancel={()=>closeUploadReport()}
                footer={null}
                maskClosable={false}
                closable={false}
                centered={true}
                width={800}
            >
                <Form form={uploadForm} {...layout} onFinish={uploadFile}>
                    <div style={{display:'flex'}}>
                        <p style={{color:'red', lineHeight:'32px', fontSize:"8px", marginRight:"-5px"}}>*</p>
                        <Form.Item label="行业类目" name="industry_category">
                            <Input placeholder='商业' />
                        </Form.Item>
                        <div style={{display:"flex"}}>
                            <p style={{color:'red', lineHeight:'32px', fontSize:"4px", marginRight:"2px", marginLeft:'10px'}}>*</p>
                            <Form.Item label="报告年份" >
                                <DatePicker placeholder={moment().format('YYYY')} disabledDate={disabledDate} locale={locale} picker="year" value={reportYear == '' ? '' : moment(reportYear, yearFormat)} onChange={(value, dateString) => {
                                    if (dateString == '') {
                                        message.warn('请选择合理的时间')
                                        return false
                                    } else {
                                        setReportYear(dateString)
                                    }
                                }} />
                            </Form.Item>
                        </div>
                        <p style={{color:'red', lineHeight:'32px', fontSize:"8px", marginRight:"-25px", marginLeft:"10px"}}>*</p>
                        <Form.Item label="作者" name="author">
                            <Input placeholder='未知' />
                        </Form.Item>
                    </div>
                    <div style={{display:"flex"}}>
                        <p style={{color:'red', lineHeight:'32px', fontSize:"8px", marginRight:"-5px"}}>*</p>
                        <Form.Item label="网页站点" name="web">
                            <Input placeholder='http://www.baidu.com' />
                        </Form.Item>
                        <div style={{display:'flex'}}>
                            <b style={{marginLeft:"35px", marginRight:"8px", lineHeight:'32px', fontSize:"14px", color:"rgba(0,0,0,.85)"}}>概述:</b>
                            <Input style={{width:"370px", height:"32px"}} value={summary} onChange={(e)=>{
                                setSummary(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div style={{display:"flex"}}>
                        <p style={{color:'red', lineHeight:'32px',marginLeft:"35px", fontSize:"4px", marginRight:"2px"}}>*</p>
                        <b style={{ marginRight:"8px", lineHeight:'32px', fontSize:"14px", color:"rgba(0,0,0,.85)"}}>文件:</b>
                        <div style={{width:'600px'}}>
                            <Dragger
                                // accept=".pdf .doc .docx"
                                action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                                fileList={reportFile}
                                onChange={reportDrawingHandleChange}
                                // listType="picture"
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                                <p className="ant-upload-hint">
                                    支持扩展名：.pdf
                                </p>
                            </Dragger>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-around', marginTop:"40px"}}>
                        <Button type="primary" danger onClick={()=>closeUploadReport()} disabled={loading}>取消</Button>
                        <Button type="primary" htmlType="submit" disabled={loading}>上传</Button>
                    </div>
                </Form>
            </Modal>

             {/* 批量上传报告 */}
             <Modal
                title="批量上传报告"
                visible={isBatchUpload}
                onCancel={()=>closeUploadReport()}
                footer={null}
                maskClosable={false}
                closable={false}
                centered={true}
                width={800}
            >
                <Form form={uploadForm} {...layout} onFinish={uploadBatchFile}>
                    <div style={{display:'flex'}}>
                        <p style={{color:'red', lineHeight:'32px', fontSize:"8px", marginRight:"-5px"}}>*</p>
                        <Form.Item label="行业类目" name="industry_category">
                            <Input placeholder='商业' />
                        </Form.Item>
                        <div style={{display:"flex"}}>
                            <p style={{color:'red', lineHeight:'32px', fontSize:"4px", marginRight:"2px", marginLeft:'10px'}}>*</p>
                            <Form.Item label="报告年份" >
                                <DatePicker placeholder={moment().format('YYYY')} disabledDate={disabledDate} locale={locale} picker="year" value={reportYear == '' ? '' : moment(reportYear, yearFormat)} onChange={(value, dateString) => {
                                    if (dateString == '') {
                                        message.warn('请选择合理的时间')
                                        return false
                                    } else {
                                        setReportYear(dateString)
                                    }
                                }} />
                            </Form.Item>
                        </div>
                        <p style={{color:'red', lineHeight:'32px', fontSize:"8px", marginRight:"-25px", marginLeft:"10px"}}>*</p>
                        <Form.Item label="作者" name="author">
                            <Input placeholder='未知' />
                        </Form.Item>
                    </div>
                    <div style={{display:"flex"}}>
                        <p style={{color:'red', lineHeight:'32px', fontSize:"8px", marginRight:"-5px"}}>*</p>
                        <Form.Item label="网页站点" name="web">
                            <Input placeholder='http://www.baidu.com' />
                        </Form.Item>
                        {/* <div style={{display:'flex'}}>
                            <b style={{marginLeft:"35px", marginRight:"8px", lineHeight:'32px', fontSize:"14px", color:"rgba(0,0,0,.85)"}}>概述:</b>
                            <Input style={{width:"370px", height:"32px"}} value={summary} onChange={(e)=>{
                                setSummary(e.target.value)
                            }}/>
                        </div> */}
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{color:'red', lineHeight:'32px',marginLeft:"35px", fontSize:"4px", marginRight:"2px"}}>*</p>
                        <b style={{ marginRight:"8px", lineHeight:'32px', fontSize:"14px", color:"rgba(0,0,0,.85)"}}>文件:</b>
                        <div style={{width:'600px'}}>
                            <Dragger
                                // accept=".pdf .doc .docx"
                                action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                                fileList={reportFile}
                                onChange={reporBatchDrawingHandleChange}
                                multiple={true}
                                // listType="picture"
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">单击或拖动多个文件到该区域以上传</p>
                                <p className="ant-upload-hint">
                                    支持扩展名：.pdf
                                </p>
                            </Dragger>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-around', marginTop:"40px"}}>
                        <Button type="primary" danger onClick={()=>closeUploadReport()} disabled={loading}>取消</Button>
                        <Button type="primary" htmlType="submit" disabled={loading}>上传</Button>
                    </div>
                </Form>
            </Modal>

            {/* 编辑报告 */}
            <Modal
                title="批量上传报告"
                visible={isEditReport}
                onCancel={()=>closeEditReport()}
                footer={null}
                maskClosable={false}
                closable={false}
                centered={true}
                width={1000}
            >
                <div>
                    {
                        temporaryReport.map((item, index)=>{
                           return <div style={{boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', padding:"10px", marginTop:"5px"}}>
                                <div style={{display:'flex', justifyContent:"space-between"}}>
                                    <a style={{ fontWeight:'bolder', fontSize:"20px", marginTop:"10px"}} href={`https://pic.bmcsoft.cn/BMC知识库/`+item.filepath} target="_blank">{item.filename}</a>
                                    <Checkbox checked={item.is_valid == 1 ? true : false} onChange={(e) => {
                                        if (e.target.checked) {
                                            temporaryReport[index]['is_valid'] = 1
                                        } else {
                                            temporaryReport[index]['is_valid'] = 0
                                        }
                                    }} />
                                </div>
                                <div style={{display:'flex',  marginTop:"10px"}}>
                                    <div style={{display:'flex', width:300}}>
                                        <p style={{color:'red', lineHeight:'36px', fontSize:"4px", marginRight:"2px", marginLeft:'10px'}}>*</p>
                                        <b style={{ lineHeight:"36px", marginRight:"5px"}}>行业类目:</b>
                                        <Input style={{width:200}} size="small" defaultValue={temporaryReport[index]["industry_category"]} onChange={(e)=>{
                                            temporaryReport[index]["industry_category"] = e.target.value
                                        }} />
                                    </div>
                                    <div style={{display:'flex', width:300}}>
                                        <p style={{color:'red', lineHeight:'36px', fontSize:"4px", marginRight:"2px", marginLeft:'10px'}}>*</p>
                                        <b style={{ lineHeight:"36px", marginRight:"5px"}}>报告年份:</b>
                                        <DatePicker size="small" defaultValue={item.year == '' ? '' : moment(item.year, yearFormat)} disabledDate={disabledDate} locale={locale} picker="year" onChange={(value, dateString) => {
                                            if (dateString == '') {
                                                message.warn('请选择合理的时间')
                                                return false
                                            } else {
                                                temporaryReport[index]["year"] = dateString
                                            }
                                        }}/>
                                    </div> 
                                    <div style={{display:'flex', width:300}}>
                                        <p style={{color:'red', lineHeight:'36px', fontSize:"4px", marginRight:"2px", marginLeft:'10px'}}>*</p>
                                        <b style={{ lineHeight:"36px", marginRight:"5px"}}>作者:</b>
                                        <Input style={{width:150}} size="small" defaultValue={temporaryReport[index]["author"]} onChange={(e)=>{
                                            temporaryReport[index]["author"] = e.target.value
                                        }} />
                                    </div> 
                                </div>
                                <div style={{display:'flex', marginTop:"10px"}}>
                                    <div style={{display:'flex', width:300}}>
                                        <p style={{color:'red', lineHeight:'36px', fontSize:"4px", marginRight:"2px", marginLeft:'10px'}}>*</p>
                                        <b style={{ lineHeight:"36px", marginRight:"5px"}}>网页站点:</b>
                                        <Input style={{width:200}} size="small" defaultValue={temporaryReport[index]["web"]} onChange={(e)=>{
                                            temporaryReport[index]["web"] = e.target.value
                                        }} />
                                    </div>
                                    <div style={{display:'flex'}}>
                                        <b style={{ lineHeight:"36px", marginRight:"5px", marginLeft:"45px"}}>概述:</b>
                                        <Input style={{width:"425px"}} size="small" defaultValue={temporaryReport[index]["summary"]} onChange={(e)=>{
                                            temporaryReport[index]["summary"] = e.target.value
                                        }} />
                                    </div> 
                                </div>
                           </div>
                        })
                    }
                </div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:"40px"}}>
                    <Button type="primary" danger onClick={()=>closeEditReport()} disabled={loading}>取消</Button>
                    <Button type="primary" htmlType="submit" disabled={loading} onClick={()=>editReport()}>上传</Button>
                </div>
            </Modal>

        </div>
    )

}


export default Index