import React, { useState, useEffect, useRef } from 'react';
import { Typography, Select, Button, Checkbox, DatePicker, Input, message, Spin, Modal } from 'antd'
import { DownOutlined, UpOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import moment from 'moment'
import style from './index.module.css';
import NbAxios from '../../../../config/utils/nbAxios';
import servicePath from '../../../../config/apiUrl';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
// 埋点函数
import AcceptBuried from '../../../../config/utils/buried';
import CommodityImage from '../../../commodityRanking/components/commodityImage';
const { Title, Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM';
function Index({
    // 路径
    pathName
}) {
    function disabledDate(current) {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }
    //数组中删除指定一项数据
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    // 店铺集合
    const [shopList, setShopList] = useState([])
    // 店铺
    const [shop, setShop] = useState('')
    // 年份集合
    const [yearList, setYearList] = useState([])
    // 年份
    const [year, setYear] = useState('')
    // 季节集合
    const [seasonList, setSeasonList] = useState(['春', '夏', '秋', '冬','春夏','秋冬'])
    // 季节
    const [season, setSeason] = useState('')
    const [newSeason, setNewSeason] = useState('')
    // 品类集合
    const [categoryList, setCategoryList] = useState([])
    const [newCategoryList, setNewCategoryList] = useState([])
    // 品类
    const [category, setCategory] = useState([])
    const [newCategory, setNewCategory] = useState('')
    // JORYA规模集合
    const [ scaleJORYAList ] = useState([{'label': '50W以上', "value": '50W以上' }, { 'label': '50W - 100W', "value": '50w' }, { 'label': '100W - 200W', "value": '100w' }, { 'label': '200W - 300W', "value": '200w' }, { 'label': '300W - 500W', "value": '300w' }, { 'label': '500W以上', "value": '500w' },])
    const [ scaleCRList ] = useState([{'label': '30W以上', "value": '30W以上' }, { 'label': '30W - 50W', "value": '30w' },  { 'label': '50W - 100W', "value": '50w' }, { 'label': '100W - 200W', "value": '100w' }, { 'label': '200W - 300W', "value": '200w' }, { 'label': '300W - 500W', "value": '300w' }, { 'label': '500W以上', "value": '500w' },])
    // 规模集合
    const [scaleList, setScaleList] = useState([])
    // 规模
    const [scale, setScale] = useState('')
    const [scaleValue, setScaleValue] = useState('')
    // 上线时间开始时间
    const [timeOnlineStartMonth, setTimeOnlineStartMonth] = useState(moment().subtract(1, 'year').startOf('month').format('YYYY-MM'))
    const [timeOnlineEndMonth, setTimeOnlineEndMonth] = useState(moment().subtract(1, 'year').startOf('month').format('YYYY-MM'))
    const [newTimeOnlineStartMonth, setNewTimeOnlineStartMonth] = useState('')
    // 可选维度
    const [optionalDimensionList] = useState(['商品访客数', '加购件数', '加购人数', '加购率', '收藏人数', '支付件数', '支付转化率', 'UV价值', '折扣情况', '温度', '件单价', '累计新买家数占比'])
    // 选择维度
    const [chooseDimensionList, setChooseDimensionList] = useState([])
    // 模型加载
    const [modelLoading, setModelLoading] = useState(false)
    const [newModelLoading, setNewModelLoading] = useState(false)
    const [echartLoading, setEchartLoading] = useState(false)
    // 可选模型
    const [optionalModel, setOptionalModel] = useState([])
    // 新品模型
    const [newOptionalModel, setNewOptionalModel] = useState([{},{},{},{}])
    // 新品下标
    const [ newOptionIndex, setNewOptionIndex ] = useState('')
    // 添加新品
    const [ addNewOptional, setAddNewOptional] = useState(false)
    // 款号
    const [spu, setSpu] = useState('')
    // id
    const [goodsId, setGoodsId] = useState('')
    // 选择模型
    const [chooseModel, setChooseModel] = useState([])
    // 选中模型Id
    const [chooseModelId, setChooseModelId] = useState([])
    // 是否选择平均线
    const [chooseAverageLine, setChooseAverageLine] = useState(false)
    // 是否打开新品
    const [isOpenNew, setIsOpenNew] = useState(true)
    // 选择新品模型
    const [chooseNewModel, setChooseNewModel] = useState('')
    const [chooseNewModelId, setChooseNewModelId] = useState('')
    // 图表时间
    const [echartDate, setEchartDate] = useState([])
    const [echartLegend, setEchartLegend] = useState([])
    const [echartLegendSelected, setEchartLegendSelected] = useState({})
    const [echartSeries, setEchartSeries] = useState([])
    const [echartStartValue, setEchartStartValue] = useState('')
    const [echartEndValue, setEchartEndValue] = useState('')
    const [ selectedLegend , setSelectedLegend ] = useState([])
    // 已选数据
    const [ chooseDateList , setChooseDateList ] = useState([])

    let explosiveTrendChart = {
        // title: {
        //     text: '爆款趋势变化图',
        //     left: 'center'
        // },
        tooltip: {
            trigger: 'axis',
            formatter: function (params, ticket, callback) {
                var showHtm="";
                for(var i=0;i<params.length;i++){
                    //x轴名称
                    var name = params[i].seriesName;
                    // 名称
                    var marker = params[i].marker;
                    //值
                    var value = params[i].value;
                    if(name.indexOf('累计新买家数占比') != -1 || name.indexOf('折扣情况') != -1){
                        showHtm+= marker +  name + '\xa0\xa0\xa0' +  value + '%<br>'
                    }else{
                        showHtm+= marker + name + '\xa0\xa0\xa0' +  value + '<br>'
                    }
                    
                }
                return showHtm;
            }
        },
        legend: {
            data: echartLegend,
            selected: echartLegendSelected,
            left: 10
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        dataZoom: [
            {
                show: true,
                realtime: true,
                startValue: echartStartValue,
                endValue: echartEndValue
            },
        ],
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: echartDate
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                position: 'right',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: echartSeries
        // series:[]
    }
    useEffect(() => {
        getShopMenuList()
    }, [])

    useEffect(()=>{
        if(echartLegend.length != 0){
            if(Object.keys(echartLegendSelected).length == echartLegend.length){
                let obj = []
                echartLegend.map(item=>{
                    if(echartLegendSelected[item] == true){
                        let spu = item.split('-')[1]
                        if(obj.indexOf(spu) == -1){
                            obj.push(spu)
                        }
                    }
                })
                setSelectedLegend(obj)
            }
        }
      
    },[echartLegendSelected])


    useEffect(() => {
        if (shop != '') {
            setSeason('')
            setCategory([])
            setScale('')
            setTimeOnlineStartMonth(moment().subtract(1, 'year').startOf('month').format('YYYY-MM'))
            setTimeOnlineEndMonth(moment().subtract(1, 'year').startOf('month').format('YYYY-MM'))
            setChooseModel([])
            setOptionalModel([])
            setScaleValue('')
            setNewOptionalModel([{},{},{},{}])
            setChooseNewModel('')
            setChooseNewModelId('')
            setNewTimeOnlineStartMonth('')
            setEchartDate([])
            setEchartLegend([])
            setEchartLegendSelected({})
            setEchartSeries([])
            setEchartStartValue('')
            setEchartEndValue('')
            setSelectedLegend([])
            setChooseDimensionList([])
            setChooseDateList([])
            setGoodsId('')
            setSpu('')
            setChooseAverageLine(false)
            const getData = async () => {
                await Promise.all([
                    // getSeasonMenuList(),
                    getCategoryMenuList(),
                    // getNewCommodityMenuList(),
                    // getScaleMenuList(),
                    // getYearMenuList()
                ])
            }
            getData()
            if(shop == 'JORYA'){
                setScaleList(scaleJORYAList)
            }else if(shop == 'CR'){
                setScaleList(scaleCRList)
            }
        }
    }, [shop])

    // 获取季节下拉框
    const getNewCommodityMenuList = () => {
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shop_name": shop,
            "field": "品类"
        }
        NbAxios(values, "POST", servicePath.getCommodityMenuList).then(res => {
            if (res.success) {
                setNewCategoryList(res.data['menu_list']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获取店铺下拉框错误，请联系IT帮忙解决')
        })
    }

    // 获取品类下拉框
    const getYearMenuList = () => {
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shop_name": shop,
            "field": "年份"
        }
        NbAxios(values, "POST", servicePath.getExplosiveModelsMenuList).then(res => {
            if (res.success) {
                setYearList(res.data['menu_list']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获取店铺下拉框错误，请联系IT帮忙解决')
        })
    }

    // 获取品类下拉框
    const getCategoryMenuList = () => {
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shop_name": shop,
            "field": "品类"
        }
        NbAxios(values, "POST", servicePath.getExplosiveModelsMenuList).then(res => {
            if (res.success) {
                setCategoryList(res.data['menu_list']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获取店铺下拉框错误，请联系IT帮忙解决')
        })
    }

    // 获取规模下拉框
    const getScaleMenuList = () => {
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shop_name": shop,
            "field": "规模"
        }
        NbAxios(values, "POST", servicePath.getExplosiveModelsMenuList).then(res => {
            if (res.success) {
                setScaleList(res.data['menu_list']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获取店铺下拉框错误，请联系IT帮忙解决')
        })
    }

    // 获取店铺下拉框
    const getShopMenuList = () => {
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shop_name": "",
            "field": "店铺"
        }
        NbAxios(values, "POST", servicePath.getExplosiveModelsMenuList).then(res => {
            if (res.success) {
                setShopList(res.data['menu_list']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获取店铺下拉框错误，请联系IT帮忙解决')
        })
    }

    // 获取折线图
    const getModelComparisonLineChartData = () => {
        let new_commodity_list = []
        newOptionalModel.map(item=>{
            if(JSON.stringify(item) !== '{}'){
                new_commodity_list.push(item)
            }
        })
        let year = timeOnlineStartMonth.split('-')[0]
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shop_name": shop,
            "explosive_commodity_spu_list": chooseModel,
            "explosive_commodity_id_list":chooseModelId,
            "index_list": chooseDimensionList,
            "new_commodity_list":new_commodity_list,
            "is_average_line": chooseAverageLine,
            "explosive_season": season,
            "explosive_category": category,
            "explosive_pay_range": scale,
            "explosive_time": timeOnlineStartMonth,
        }
        setEchartLoading(true)
        NbAxios(values, "POST", servicePath.getModelComparisonLineChartData).then(res => {
            setEchartLoading(false)
            if (res.success) {
                setEchartDate(res.data['date_list']);
                setEchartLegend(res.data['legend']);
                let obj = {}
                res.data['legend'].map(item => {
                    obj[item] = true
                })
                if(chooseDimensionList.length == 2){
                    res.data['line'].map((item,index)=>{
                        if(item.name.indexOf(chooseDimensionList[1]) != -1){
                            res.data['line'][index]['yAxisIndex'] = 1
                        }
                    })
                }
                setEchartLegendSelected(obj);
                setEchartStartValue(res.data['startValue']);
                setEchartEndValue(res.data['endValue'])
                res.data['line'].map((item,index) =>{
                    let list = []
                    if(item.name){
                        if(item.name.split('-')[2] == '折扣情况'){
                            item.data.map(data=>{
                                if(data!=='-'){
                                    list.push(Math.floor(data*100))
                                }
                                else{
                                    list.push(data)
                                }
                            })
                            item.data = list
                        }
                    }
                })
                
                setEchartSeries(res.data['line']);
                setChooseDateList(res.data['commodity_id_list'])
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            setEchartLoading(false)
            message.warn('获取折线图错误，请联系IT帮忙解决')
        })
    }
    const chartRef = useRef(null)
    useEffect(()=>{
        chartRef.current = echarts.init(document.getElementById('explosiveTrend'));
        chartRef.current.off('click')
        chartRef.current.on('legendselectchanged',function(params){
            // 他选择的select
            let selected = params.selected;
            let legend = params.name;
            if(echartLegend.length != 0){
                let obj = []
                echartLegend.map(item=>{
                    if(echartLegendSelected[item] == true || echartLegendSelected[item] == undefined){
                        let spu = item.split('-')[1]
                        if(obj.indexOf(spu) == -1){
                            obj.push(spu)
                        }
                    }
                })
                setSelectedLegend(obj)
            }
            echartLegendSelected[legend] = selected[legend]
            setEchartLegendSelected(echartLegendSelected)
        })
    },[echartLegend])
    useEffect(() => {
        // let reportChart = null
        // const renderedInstance = echarts.getInstanceByDom(document.getElementById('explosiveTrend'))
        // if(renderedInstance){
        //     reportChart = renderedInstance
        // }else{
        
        // }
        // let reportChart = echarts.init(chartRef.current);
        chartRef.current.clear();    //清除残留渲染
        // 绘制图表
        chartRef.current.setOption(explosiveTrendChart);
        window.addEventListener('resize', () => {
            chartRef.current.resize()
        });
    }, [echartSeries,echartLegendSelected])


    return (
        <div>
            <div style={{ width: '100%', height: '500px', boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', paddingLeft: 10, paddingTop: 5 }}>
                <div style={{ marginLeft: 5, marginTop: 5 }}>
                    <Title level={5} >爆款模型</Title>
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: "space-around" }}>
                    <div>
                        <Text style={{ marginRight: 5, marginTop: 3 }}>店铺:</Text>
                        <Select value={shop} style={{ width: '140px' }} showSearch onChange={(value) => {
                            AcceptBuried('popular_model_store_selection', "change", { "page_name": '爆款单品诊断', "shop_drop-down_box": value }, pathName, "女装/女装爆款打造")
                            setShop(value);
                        }}>
                            {
                                shopList.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <div>
                        <Text style={{ marginRight: 5, marginTop: 3 }}>季节:</Text>
                        <Select value={season} disabled={shop == '' ? true : false} style={{ width: '140px' }} showSearch onChange={(value) => {
                            setSeason(value);
                        }}>
                            {
                                seasonList.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <div>
                        <Text style={{ marginRight: 5, marginTop: 3 }}>品类:</Text>
                        <Select maxTagCount="responsive" mode="multiple" value={category} disabled={shop == '' ? true : false} style={{ width: '150px' }} showSearch onChange={(value) => {
                            setCategory(value);
                        }}>
                            {
                                categoryList.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <div>
                        <Text style={{ marginRight: 5, marginTop: 3 }} >规模:</Text>
                        <Select value={scaleValue} disabled={shop == '' ? true : false} style={{ width: '140px' }} showSearch onChange={(value) => {
                            setScale(value.split(',')[1]);
                            setScaleValue(value)
                        }}>
                            {
                                scaleList.map(item => {
                                    return <Option value={item.label + ',' + item.value}>{item.label}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <div>
                        <Text style={{ marginRight: 5, marginTop: 3 }}>上线月份:</Text>
                        {/* <DatePicker locale={locale} picker="month" format="YYYY-MM"
                            disabledDate={disabledDate}
                            defaultValue={timeOnlineStartMonth == '' ? '' : moment(timeOnlineStartMonth, dateFormat)}
                            onChange={(value, dateString) => {
                                if (dateString[0] == '') {
                                    message.warn('请选择正确的时间')
                                } else {
                                    setTimeOnlineStartMonth(dateString)
                                }
                            }}
                        /> */}
                        <RangePicker locale={locale} picker="month" format= "YYYY-MM"
                            disabledDate={disabledDate}
                            value ={timeOnlineStartMonth == '' ? ['',''] : [moment(timeOnlineStartMonth, dateFormat),moment(timeOnlineEndMonth, dateFormat)]}
                            onChange={(value, dateString)=>{
                                if (dateString[0] == '') {
                                    message.warn('请选择合理的时间')
                                    return false
                                } else {
                                    setTimeOnlineStartMonth(dateString[0])
                                    setTimeOnlineEndMonth(dateString[1])
                                    setChooseAverageLine(false)
                                } 
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button style={{ padding: "0 5px", width: 45, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "white" }} type="primary" onClick={() => {
                            let buriedValues = {
                                "page_name": "爆款单品诊断",
                                "shop": shop,
                                "season": season,
                                "category": category.toString(),
                                "scale": scale,
                                "new_time": timeOnlineStartMonth,
                                "start_time": timeOnlineStartMonth,
                                "end_time": timeOnlineEndMonth
                            }
                            AcceptBuried('query_the_blockbuster_model', "click", buriedValues, pathName, "女装/女装爆款打造")
                            if (shop != '' && season != '' && category != '' && scale != '' && timeOnlineStartMonth != '') {
                                let values = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shop_name": shop,
                                    "season": season,
                                    "category": category,
                                    "pay_range": scale,
                                    "new_time": timeOnlineStartMonth,
                                    "start_time": timeOnlineStartMonth,
                                    "end_time": timeOnlineEndMonth
                                }
                                setModelLoading(true)
                                NbAxios(values, "POST", servicePath.getSingleProductModelList).then(res => {
                                    setModelLoading(false)
                                    if (res.success) {
                                        let modelList = res.data['model_list']
                                        if(modelList.legend == 0){
                                            message.warn('暂无爆款模型')
                                        }
                                        setOptionalModel(res.data['model_list']);
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                }).catch((err) => {
                                    setModelLoading(false)
                                    message.warn('获取模型列表错误，请联系IT帮忙解决')
                                })
                            } else {
                                message.warn('店铺、季节、品类、规模、上线月份必填')
                            }

                        }}>搜索</Button>
                        <Button style={{ padding: "0 5px", width: 45, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "white", marginLeft: 5 }} type="primary" danger onClick={() => {
                            AcceptBuried('reset_the_blockbuster_model', "click", { "page_name": "爆款单品诊断", }, pathName, "女装/女装爆款打造")
                            setShop('')
                            setSeason('')
                            setCategory([])
                            setScale('')
                            setTimeOnlineStartMonth('')
                            setTimeOnlineEndMonth('')
                            setChooseModel([])
                            setOptionalModel([])
                            setScaleValue('')
                        }}>重置</Button>
                    </div>
                </div>
                {/* <div style={{ display: 'flex', marginTop: 10, marginRight: 20 }}>
                    <Text className={style.meanTitle}>选择维度:</Text>
                    {
                        optionalDimensionList.map(item => {
                            return <Checkbox checked={chooseDimensionList.indexOf(item) != -1 ? true : false} value={item} onChange={(e) => {
                                if (e.target.checked) {
                                    if (chooseDimensionList.length < 2) {
                                        let obj = [...chooseDimensionList]
                                        obj.push(item)
                                        setChooseDimensionList(obj)
                                    } else {
                                        message.warn('选择维度最多只能选择两个')
                                    }
                                } else {
                                    let obj = [...chooseDimensionList]
                                    obj.remove(item)
                                    setChooseDimensionList(obj)
                                }
                            }}>{item}</Checkbox>
                        })
                    }
                </div> */}
                <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                    <Text className={style.meanTitle}>选择模型:</Text>
                    {/* <Button className={style.chooseModelButton} disabled={(chooseModel.length != 0 || chooseAverageLine) && chooseDimensionList.length != 0 ? false : true} type="primary" onClick={()=>{
                        getModelComparisonLineChartData()
                    }}>确定</Button> */}
                </div>
                <Spin spinning={modelLoading}>
                    <div style={{ height: '330px', marginTop: 10, display: 'flex', marginBottom:10 }}>
                        <div style={{ border: chooseAverageLine ? '3px solid red' : '1px dotted #000', marginRight: 10, display: timeOnlineStartMonth == timeOnlineEndMonth && category.length == 1   ? 'flex' : 'none', justifyContent: 'center', writingMode: 'vertical-lr' }} onClick={() => {
                            if (chooseAverageLine) {
                                let buriedValues = {
                                    "page_name": "爆款单品诊断",
                                    "instance_ID": '',
                                    "paragraph_number": '',
                                    'time_to_go_live': '',
                                    'checked': false,
                                    'the_instance_type': '平均线'
                                }
                                AcceptBuried('select_the_blockbuster_instance', "click", buriedValues, pathName, "女装/女装爆款打造")
                                setChooseAverageLine(false)
                            } else {
                                let buriedValues = {
                                    "page_name": "爆款单品诊断",
                                    "instance_ID": '',
                                    "paragraph_number": '',
                                    'time_to_go_live': '',
                                    'checked': true,
                                    'the_instance_type': '平均线'
                                }
                                AcceptBuried('select_the_blockbuster_instance', "click", buriedValues, pathName, "女装/女装爆款打造")
                                setChooseAverageLine(true)
                            }
                        }}>
                            平均线
                        </div>
                        <div style={{ width: "85vw", overflow: 'auto', display: 'flex' }}>
                            {
                                optionalModel.map(item => {
                                    return <div style={{ border: chooseModel.indexOf(item['款号']) == -1 ? '1px dotted #000' : '3px solid red', marginRight: 10, display: 'flex', flexDirection: 'column', alignContent: 'center', padding: 5, width:180 }} onClick={() => {
                                        if (chooseModel.indexOf(item['款号']) != -1) {
                                            let buriedValues = {
                                                "page_name": "爆款单品诊断",
                                                "instance_ID": item['ID'],
                                                "paragraph_number": item['款号'],
                                                'time_to_go_live': '',
                                                'checked': true,
                                                'the_instance_type': '实例'
                                            }
                                            AcceptBuried('select_the_blockbuster_instance', "click", buriedValues, pathName, "女装/女装爆款打造")
                                            let obj = [...chooseModel]
                                            obj.remove(item['款号'])
                                            setChooseModel(obj)
                                            let idObj = [...chooseModelId]
                                            idObj.remove(item['ID'])
                                            setChooseModelId(idObj)
                                        } else {
                                            let buriedValues = {
                                                "page_name": "爆款单品诊断",
                                                "instance_ID": item['ID'],
                                                "paragraph_number": item['款号'],
                                                'time_to_go_live': '',
                                                'checked': false,
                                                'the_instance_type': '实例'
                                            }
                                            AcceptBuried('select_the_blockbuster_instance', "click", buriedValues, pathName, "女装/女装爆款打造")
                                            let obj = [...chooseModel]
                                            obj.push(item['款号'])
                                            setChooseModel(obj)
                                            let idObj = [...chooseModelId]
                                            idObj.push(item['ID'])
                                            setChooseModelId(idObj)
                                        }

                                    }}>
                                        <CommodityImage shopName={shop == 'JORYA' ? shop : shop.toLowerCase()} sku={item['款号']} id={item['ID']} index={"model"} style={{ width: 160, height: 140 }} />
                                        {/* <img src={`https://pic.bmcsoft.cn/it/${shop}/${item['ID']}.jpg`} style={{ width: 110, height: 110 }} /> */}
                                        <div><Text type="secondary">ID:</Text><Text>{item['ID']}</Text></div>
                                        <div><Text type="secondary">款号:</Text><Text>{item['款号']}</Text></div>
                                        <div><Text type="secondary">品类:</Text><Text>{item['品类']}</Text></div>
                                        <div><Text type="secondary">年份:</Text><Text>{item['年份']}</Text></div>
                                        <div><Text type="secondary">季节:</Text><Text>{item['季节']}</Text></div>
                                        <div><Text type="secondary">上线月份:</Text><Text>{item['上线月份']}</Text></div>
                                        <div><Text type="secondary">累计销售额:</Text><Text>{item['累计销售额']}</Text></div>
                                        <div><Text type="secondary">累计销售件数:</Text><Text>{item['累计销售件数']}</Text></div>
                                    </div>
                                })
                            }
                        </div>

                    </div>
                </Spin>
                <div style={{ marginTop: 10, display: 'grid', gridTemplateColumns: '200px 1fr' }}>
                    <Text className={style.meanTitle}>已选模型:</Text>
                    <div style={{ display: 'flex' }}>
                        {
                            chooseModel.map((item, index) => {
                                return <div style={{ display: 'flex', padding: 5, marginRight: 5, border: '2px dashed #188fff' }}>
                                    <div>
                                        {item}
                                    </div>
                                    <CloseOutlined style={{ marginTop: 3, marginLeft: 3 }} onClick={() => {
                                        let obj = [...chooseModel]
                                        obj.remove(item)
                                        setChooseModel(obj)
                                        let idObj = [...chooseModelId]
                                        idObj.splice(index,1)
                                        setChooseModelId(idObj)
                                    }} />
                                </div>
                            })
                        }
                    </div>
                    {/* <Text type="secondary">{chooseModel.toString()}</Text> */}
                </div>

            </div>
            <div style={{ width: '100%', boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', paddingLeft: 10, paddingRight: 10, marginTop: 5, paddingBottom: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 5 }} onClick={() => {
                    if (isOpenNew) {
                        setIsOpenNew(false)
                    } else {
                        setIsOpenNew(true)
                    }
                }}>
                    <div style={{ marginLeft: 5, marginTop: 5 }}>
                        <Title level={5} >新品成长</Title>
                    </div>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        {
                            isOpenNew ? <DownOutlined style={{ fontSize: 25, fontWeight: 'bolder' }} />
                                : <UpOutlined style={{ fontSize: 25, fontWeight: 'bolder' }} />
                        }
                    </div>
                </div>
                <div style={{ display: isOpenNew ? '' : 'none' }}>
                    {/* <div style={{ display: 'flex', width: '100%', justifyContent: "space-around" }}>
                        <div>
                            <Text style={{ marginRight: 5, marginTop: 3 }}>款号:</Text>
                            <Input value={spu} style={{ width: '140px' }} onChange={(e) => {
                                setSpu(e.target.value)
                            }} />
                        </div>
                        <div>
                            <Text style={{ marginRight: 5, marginTop: 3 }}>ID:</Text>
                            <Input value={goodsId} style={{ width: '140px' }} onChange={(e) => {
                                setGoodsId(e.target.value)
                            }} />
                        </div>
                        <div style={{ width: '140px' }} >
                            <Text style={{ marginRight: 5, marginTop: 3 }}>年份:</Text>
                            <Select value={year} disabled={shop == '' ? true : false} style={{ width: '140px' }} showSearch onChange={(value) => {
                                setYear(value);
                            }}>
                                {
                                    yearList.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div style={{ width: '140px' }} >
                            <Text style={{ marginRight: 5, marginTop: 3 }}>季节:</Text>
                            <Select value={newSeason} disabled={shop == '' ? true : false} style={{ width: '140px' }} showSearch onChange={(value) => {
                                setNewSeason(value);
                            }}>
                                {
                                    seasonList.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div style={{ width: '140px' }} >
                            <Text style={{ marginRight: 5, marginTop: 3 }}>品类:</Text>
                            <Select value={newCategory} disabled={shop == '' ? true : false} style={{ width: '140px' }} showSearch onChange={(value) => {
                                setNewCategory(value);
                            }}>
                                {
                                    categoryList.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div style={{ display: 'flex', marginTop: 10 }}>
                            <Button style={{ padding: "0 5px", width: 45, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "white" }} type="primary" onClick={() => {
                                if(spu != '' || goodsId != ''){
                                    let buriedValues = {
                                        "page_name": "爆款单品诊断",
                                        "new_model_number": spu,
                                        "new_product_ID": goodsId,
                                    }
                                    AcceptBuried('query_new_product_growth_examples', "click", buriedValues, pathName, "女装/女装爆款打造")
                                    setChooseNewModel('')
                                    setChooseNewModelId('')
                                    let values = {
                                        "roleId": cookie.load('role'),
                                        "shopList": cookie.load('shopList'),
                                        "shop_name": shop,
                                        "goods_id": goodsId,
                                        "spu": spu,
                                        "year": year,
                                        "season": newSeason,
                                        "category": newCategory,
                                    }
                                    setNewModelLoading(true)
                                    NbAxios(values, "POST", servicePath.getExplosiveModelsCommodityList).then(res => {
                                        setNewModelLoading(false)
                                        if (res.success) {
                                            let commodity_list = res.data['commodity_list']
                                            if(commodity_list.legend != 0){
                                                if(res.data['commodity_list'][0]['上线日期'] != null){
                                                    setNewOptionalModel(res.data['commodity_list']);
                                                    setChooseNewModel(res.data['commodity_list'][0]['款号'])
                                                    setChooseNewModelId(res.data['commodity_list'][0]['ID'])
                                                    setNewTimeOnlineStartMonth(res.data['commodity_list'][0]['上线日期'])
                                                }else{
                                                    setNewOptionalModel(res.data['commodity_list']);
                                                    message.warn('请补全商品基础信息的上架时间')
                                                }
                                            }else{
                                                message.warn('商品基础信息没有该商品')
                                                setNewOptionalModel([]);
                                                setChooseNewModel('')
                                                setChooseNewModelId('')
                                                setNewTimeOnlineStartMonth('')
                                            }
                                           
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    }).catch((err) => {
                                        setNewModelLoading(false)
                                        message.warn('获取模型列表错误，请联系IT帮忙解决')
                                    })
                                }else{
                                    message.warn('请填写商品ID或者SPU')
                                }
                               
                            }}>搜索</Button>
                            <Button style={{ padding: "0 5px", width: 45, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "white", marginLeft: 5 }} type="primary" danger onClick={() => {
                                let buriedValues = {
                                    "page_name": "爆款单品诊断",
                                }
                                AcceptBuried('reset_the_new_product_growth_instance', "click", buriedValues, pathName, "女装/女装爆款打造")
                                setGoodsId('')
                                setSpu('')
                                setNewOptionalModel([])
                                setChooseNewModel('')
                                setChooseNewModelId('')
                                setNewTimeOnlineStartMonth('')
                            }}>重置</Button>
                        </div>
                    </div> */}
                    {/* <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                        <Text className={style.meanTitle}>选择模型:</Text>
                        <Button className={style.chooseModelButton} disabled={chooseNewModel != '' && chooseDimensionList.length != 0 ? false : true} type="primary"  onClick={()=>{
                            getModelComparisonLineChartData()
                        }}>确定</Button>
                    </div> */}
                    <Spin spinning={newModelLoading}>
                        <div style={{ width: "85vw", height: '125px', overflow: 'auto', marginTop: 10, display: 'grid', gridTemplateColumns:"1fr 100px" }}>
                           <div style={{display:'flex'}}>
                            {/* {
                                    newOptionalModel.map(item => {
                                        return  <div style={{ border: chooseNewModel != item['款号'] ? '1px dotted #000' : '3px solid red', marginRight: 10, display: 'flex', flexDirection: 'column', alignContent: 'center', padding: 5 }}
                                        // onClick={() => {
                                        //     if (item.id == chooseNewModel) {
                                        //         setChooseNewModel('')
                                        //         setNewTimeOnlineStartMonth('')
                                        //     } else {
                                        //         setChooseNewModel(item.ID)
                                        //         setNewTimeOnlineStartMonth(item['上线日期'])
                                        //     }
                                        // }}
                                        >
                                            <img src={`https://pic.bmcsoft.cn/it/${shop == 'JORYA' ? shop : shop.toLowerCase()}/${item['ID']}.jpg`} style={{ width: 110, height: 110 }} />
                                            <div><Text type="secondary">ID:</Text><Text>{item['ID']}</Text></div>
                                            <div><Text type="secondary">款号:</Text><Text>{item['款号']}</Text></div>
                                            <div><Text type="secondary">品类:</Text><Text>{item['品类']}</Text></div>
                                            <div><Text type="secondary">年份:</Text><Text>{item['年份']}</Text></div>
                                            <div><Text type="secondary">季节:</Text><Text>{item['季节']}</Text></div>
                                            <div style={{display:'flex', justifyContent:'center', paddingTop:5}}>
                                                <Button style={{ padding: "0 5px", width: 45, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "white", marginLeft: 5 }} type="primary" danger>取消</Button>
                                            </div>
                                        </div>
                                    })
                                } */}
                                <div style={{display:'flex'}}>
                                    {
                                        newOptionalModel.map((item, index) =>{
                                            return JSON.stringify(item)=="{}" ? <div style={{border:'1px dotted #000', marginRight:10, display:"flex", justifyContent:'center',  width:'125px', height: '125px',  padding: 5, alignItems:"center"}} onClick={()=>{setAddNewOptional(true); setNewOptionIndex(index)}}>
                                                    <PlusOutlined style={{fontSize:'20px'}} />
                                                </div> :<div style={{border:'1px dotted #000' , display:'flex', marginRight:'5px', padding:"5px"}}>
                                                <div style={{height:'110px', display:'flex', alignItems:'center'}}>
                                                    <img src={`https://pic.bmcsoft.cn/it/${shop == 'JORYA' ? shop : shop.toLowerCase()}/${item['ID']}.jpg`} style={{ width: 110, height: 110 }} />
                                                </div>
                                                <div style={{paddingLeft:"5px"}}>
                                                    <div><Text type="secondary">ID:</Text><Text>{item['ID']}</Text></div>
                                                    <div><Text type="secondary">款号:</Text><Text>{item['款号']}</Text></div>
                                                    <div><Text type="secondary">年份:</Text><Text>{item['年份']}</Text></div>
                                                    <div><Text type="secondary">季节:</Text><Text>{item['季节']}</Text></div>
                                                    <div style={{display:'flex', justifyContent:'center'}} onClick={()=>{
                                                        let newObj = [...newOptionalModel]
                                                        newObj[index] = {}
                                                        setNewOptionalModel(newObj)
                                                    }}>
                                                        <a>取消</a>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                           </div>
                           <div>
                            <Button style={{ padding: "0 5px", width: 45, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "white", marginLeft: 5 }} type="primary" danger>重置</Button>
                           </div>
                        </div>
                    </Spin>
                </div>
            </div>
            <Spin spinning={echartLoading}>
                <div style={{ width: '100%', boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', paddingLeft: 10, paddingRight: 10, marginTop: 5, paddingBottom: 200 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5, paddingTop: 15 }}>
                        <Title level={5}>爆款趋势变化图</Title>
                    </div>
                    <div style={{ display: 'flex', marginTop: 10, marginRight: 20, marginBottom: 5, justifyContent: 'space-between' }}>
                        <div>
                            <Text style={{ marginRight: 20 }}>选择维度:</Text>
                            {
                                optionalDimensionList.map(item => {
                                    return <Checkbox checked={chooseDimensionList.indexOf(item) != -1 ? true : false} value={item} onChange={(e) => {
                                        if (e.target.checked) {
                                            if (chooseDimensionList.length < 2) {
                                                let obj = [...chooseDimensionList]
                                                obj.push(item)
                                                setChooseDimensionList(obj)
                                            } else {
                                                message.warn('选择维度最多只能选择两个')
                                            }
                                        } else {
                                            let obj = [...chooseDimensionList]
                                            obj.remove(item)
                                            setChooseDimensionList(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                        <Button className={style.chooseModelButton} disabled={chooseDimensionList.length != 0 ? false : true} type="primary" onClick={() => {
                            let buriedValues = {
                                "page_name": "爆款单品诊断",
                                'select_a_dimension': chooseDimensionList.toString()
                            }
                            AcceptBuried('query_the_trend_change_chart_of_the_blockbuster', "click", buriedValues, pathName, "女装/女装爆款打造")
                            getModelComparisonLineChartData()
                        }}>确定</Button>
                    </div>
                    <div  style={{ width: '100%', height: 300 }} id="explosiveTrend"></div>
                    <div style={{display:'flex', marginLeft:20, flexWrap:'wrap'}}>
                        {
                            chooseDateList.map(item=>{
                                //border: '1px dotted #000', 
                              return  <div style={{ marginRight: 10, display: 'flex', flexDirection: 'column', alignContent: 'center', padding: 10, border: selectedLegend.indexOf(item.SPU) != -1 ? "3px solid red " : '1px solid #ccc', marginTop:20 }} onClick={()=>{
                                            let obj = [...selectedLegend]
                                            if(selectedLegend.indexOf(item.SPU) != -1){
                                                // 选中点击取消
                                                obj.remove(item.SPU)
                                                setSelectedLegend(obj)
                                            }else{
                                                obj.push(item.SPU)
                                                setSelectedLegend(obj)
                                            } 
                                            let echartLegendSelectedObj = {}
                                            echartLegend.map(echartLegendItem=>{
                                                let spu = echartLegendItem.split('-')[1]
                                                if(obj.indexOf(spu) != -1){
                                                    echartLegendSelectedObj[echartLegendItem] = true
                                                }else{
                                                    echartLegendSelectedObj[echartLegendItem] = false
                                                }
                                            })
                                            setEchartLegendSelected(echartLegendSelectedObj)
                                    }}>
                                    <CommodityImage shopName={shop == 'JORYA' ? shop : shop.toLowerCase()} sku={item.SPU} id={item.id} index={"echart"} style={{ width: 110, height: 110 }}/>
                                    <div><Text type="secondary">ID:</Text><Text>{item['id']}</Text></div>
                                    <div><Text type="secondary">款号:</Text><Text>{item['SPU']}</Text></div>
                              </div>
                            })
                        }
                    </div>
                </div>
            </Spin>
            <Modal
                title="添加新品"
                visible={addNewOptional}
                centered={true}
                cancelText="取消"
                okText="确定"
                onCancel={()=>{
                    setAddNewOptional(false)
                    setSpu('')
                    setGoodsId('')
                }}
                onOk={()=>{
                    if(spu != '' || goodsId != ''){
                        let values = {
                            "roleId": cookie.load('role'),
                            "shopList": cookie.load('shopList'),
                            "shop_name": shop,
                            "goods_id": goodsId,
                            "spu": spu,
                        }
                        AcceptBuried('query_new_product_growth_examples', "click", values, pathName, "女装/女装爆款打造")
                        setNewModelLoading(true)
                        NbAxios(values, "POST", servicePath.getExplosiveModelsCommodityList).then(res => {
                            setNewModelLoading(false)
                            if(res.success){
                                let newObj = [...newOptionalModel]
                                newObj[newOptionIndex] = res.data['commodity_dict']
                                setNewOptionalModel(newObj)
                                setAddNewOptional(false)
                                setSpu('')
                                setGoodsId('')
                                setNewOptionIndex('')
                            }else{
                                message.warn(res.errorMsg)
                            }
                        }).catch((err) => {
                            setNewModelLoading(false)
                            message.warn('获取新品错误，请联系IT帮忙解决')
                        })
                    }else{
                        message.warn('请填写商品ID或者SPU')
                    }
                }}
                okButtonProps={{disabled: newModelLoading == true ? true : false}}
                cancelButtonProps={{disabled: newModelLoading == true ? true : false}}
            >
                <div style={{display:'flex'}}>
                    <div style={{marginRight: 15}}>
                        <Text style={{ marginRight: 5, marginTop: 3 }}>款号:</Text>
                        <Input value={spu} style={{ width: '140px' }} onChange={(e) => {
                            setSpu(e.target.value)
                        }} />
                    </div>
                    <div>
                        <Text style={{ marginRight: 5, marginTop: 3 }}>ID:</Text>
                        <Input value={goodsId} style={{ width: '140px' }} onChange={(e) => {
                            setGoodsId(e.target.value)
                        }} />
                    </div>
                </div>
            </Modal>
        </div>
    )

}

export default Index