import React, { useState, useEffect } from "react";
import { DatePicker, Button, Modal, Form, message, Upload, Typography, Radio, Spin, Table, Input, Select } from 'antd'
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import servicePath from "../../../config/apiUrl";
import NbAxios from "../../../config/utils/nbAxios";
import AcceptBuried from "../../../config/utils/buried";
import downExcel from "../../../config/utils/downloadFile"
import liveDict from '../data/liveDict';
import liveNameDict from '../data/liveNameDict';
import axios from "axios";
// 设置文本
const { Text, Paragraph, Title } = Typography;
const { Option } = Select
const dateFormat = 'YYYY-MM';
function Index(props){
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };
    const [form] = Form.useForm();
    // 设置选择文件按钮样式
    const uploadFileButton = (
        <div>
        <Button icon={<UploadOutlined />}>选择文件</Button>
        </div>
    );
    // 控制上传excel对话框的可不可见
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    // 设置加载中变量
    const [loading, setloading] = useState(false);
    // 上传excel列表
    const [fileList, setFileList] = useState([]);
    // 表格数据源
    const [data,setData] = useState([])
    // 当前月份
    const [ month , setMonth ] = useState(moment().format('YYYY-MM'))
    // 设置表头
    const [columns,setColumns] = useState([]) 
    // 是否添加目标
    const [ addTarget, setAddTarget ] = useState(false)
    // 上传开发Excel函数
    const uploadFile = () => {
        if (fileList.length == 0) {
            message.error("请选择文件");
        }else{
            AcceptBuried('upload_target_data',"click",{},props.location.pathname,"抖音月度排期计划表")
            setloading(true);
            const formData = new FormData();
            fileList.forEach((item) => {
                formData.append("target_file", item["originFileObj"]);
            });
            formData.append("shopList", cookie.load("shopList"));
            formData.append("roleId", cookie.load("role"));
            formData.append("live_id", liveDict[window.location.href.split('/')[4]]);
            NbAxios(formData, "POST", servicePath.uploadTarget).then(
                (res) => {
                    setloading(false);
                    if (res.success) {
                    message.success("排期表上传成功");
                    setFileList([]);
                    setIsUploadModalVisible(false);
                    initialization()
                    } else {
                    message.error(res.errorMsg);
                    setFileList([]);
                    }
                }
            );
        }
        
    };
    // 合并列函数
    const createCategoryNewArr = (data) => {
        return data.reduce((result, item) => {
            //首先将data_item字段作为新数组result取出
            if (result.indexOf(item.data_item) < 0) {
                result.push(item.data_item)
            }
            return result
        }, []).reduce((result, data_item) => {
            //将data_item相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
            const children = data.filter(item => item.data_item === data_item);
            result = result.concat(
                children.map((item, index) => ({
                    ...item,
                    rowCategorySpan: index === 0 ? children.length : 0,//将第一行数据添加rowSpan字段
                })),
            )
            return result;
        }, [])
    }
    // 初始化
    const initialization = () =>{
        let dataProps = {
            "target_month" : month,
            "live_id" : liveDict[window.location.href.split('/')[4]],
            'roleId': cookie.load('role'),
            'shopList': cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.tiktokTargetSearch).then(
            (res) => {
                setloading(false);
                if (res.success) {
                message.success("查询成功");
                setData(res.data);
                } else {
                message.error(res.errorMsg);
                }
            }
        );
    }
    useEffect(()=>{
        initialization()
    },[month])
    useEffect(()=>{
    
        let daysInMonth = moment(month,"YYYY-MM").daysInMonth()
        let dayList = []
        // 将天数存入数组，不然循环拿不到下标
        for(var i = 1;i <= daysInMonth;i++){
            let day = ""
            if(i<10){
                day = month + '-0' + i.toString()
            }else{
                day = month + '-' + i.toString()
            }
            dayList.push(day)
        }
        setColumns([
            {
                title:"数据项",
                dataIndex: "data_item",
                key: "data_item",
                width: 100,
                fixed: "left",
                align: "center",
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                render(_, row) {
                    return {
                        children: row.data_item,
                        props: {
                            rowSpan: row.rowCategorySpan,
                        }
                    }
                }
            },
            {
                title:"日期",
                dataIndex: "date",
                key: "date",
                width: 100,
                align: "center",
                fixed: "left",
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
            },
            ...dayList.map(item=>{
                return ({
                    title:String(parseInt(item.split('-')[1])) + "月" + String(parseInt(item.split('-')[2])) + "日",
                    dataIndex: item,
                    key: item,
                    width: 100,
                    align: "center",
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        text != "" && text != null ?
                        <Paragraph style={{ margin: 0 }} editable={{onChange:(value)=>
                        {
                            let obj = [...data]
                            obj[index][item] = value
                            setData(obj)
                            // 修改接口
                            let dataProps = {
                                "data_item" : obj[index]["data_item"],
                                "date_detail" : obj[index]["date"],
                                "update": obj[index][item],
                                "date" : item,
                                'roleId': cookie.load('role'),
                                'shopList': cookie.load('shopList'),
                                "live_id" : liveDict[window.location.href.split('/')[4]],
                            }
                            setloading(true);
                            NbAxios(dataProps, "POST", servicePath.modifyTarget).then(
                                (res) => {
                                    setloading(false);
                                    if (res.success) {
                                    message.success("修改成功");
                                    } else {
                                    message.error(res.errorMsg);
                                    }
                                }
                            );
                            
                        }
                    }}>{text}</Paragraph> : <span></span>
                    )
                })
            })
        ])
    },[month,data])
    useEffect(()=>{
        AcceptBuried('page_views',"view",{"page_type":"初始化页面"},props.location.pathname,"抖音月度排期计划表")
    },[])

    // 添加目标
    const addTargetForm = (value)=>{
        value.date = value.date.format('YYYY-MM-DD')
        value.live_id = liveDict[window.location.href.split('/')[4]]
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        NbAxios(value, "POST", servicePath.addTarget).then((res) => {
                if (res.success) {
                    setAddTarget(false)
                    initialization()
                } else {
                    message.error(res.errorMsg);
                }
            }
        );
    }
    return(
        <div style={{paddingBottom:100 ,paddingLeft:20,paddingRight:20}}>
            <div style={{
                display: 'flex',
                justifyContent: "space-around",
                backgroundColor: "#fff",
                borderBottom: "solid 2px #ccc",
                marginBottom: 10,
                position: 'fixed',
                top: 40,
                zIndex: 99,
                width: '100vw',
                paddingTop:10,
                paddingBottom:10
            }}>
                <Title level={3} style={{ margin: 0 }} >{liveNameDict[props.location.pathname.split('/')[2]]}抖音月度排期计划表</Title>
                <div style={{display:'flex'}}>
                    <DatePicker picker="month" locale={locale} value={month == '' ? '' : moment(month, dateFormat)} onChange={(date, dateString)=>{
                         if (dateString == '') {
                            message.warn('请选择合理的时间')
                            return false
                        } else {
                            setMonth(dateString)
                        }
                    }} />
                    <Button
                        type="primary"
                        onClick={()=>setIsUploadModalVisible(true)}
                        style={{ marginLeft: 20 }}
                    >上传</Button>
                    <div style={{marginLeft:20}}>
                        <Button type="primary" onClick={()=>{
                                if(month == ""){
                                    message.error("未选择时间")
                                }else{
                                    setloading(true)
                                    // 数据传入调接口
                                    let dataProps = {
                                        target_month: month,
                                    }
                                    axios({
                                        data: dataProps,
                                        method: 'POST',
                                        url: servicePath.tiktokExcel,
                                        responseType: "blob",
                                        headers: {
                                            'Content-Type': 'application/json',  //如果写成contentType会报错
                                            'authorization': cookie.load('token')
                                        }
                                    }).then(
                                        res => {
                                            setloading(false)
                                            downExcel(res)
                                        }
                                    ).catch(e => {
                                        setloading(false)
                                        message.error({ content: '文件制作出错，请联系it同事！' })
                                    })
                                }
                                    
                            }}>下载模版</Button>
                    </div>
                    <Button style={{marginLeft:20}} type="primary" onClick={()=>{
                        form.resetFields()
                        setAddTarget(true)
                    }}>添加目标</Button>
                    <a href="https://pic.bmcsoft.cn/imge_dir/052610272315/052610272315.html" target="_Blank" style={{marginRight:10}}><Button type="link">操作手册</Button></a>
                </div>
            </div>
            <div style={{marginTop:80}}>
                <Table
                    dataSource={createCategoryNewArr(data)}
                    columns={columns}
                    scroll={{ x: 1500 }}
                    // rowKey={"skc"}
                    pagination={false}
                />
            </div>
            {/* 上传抖音直播目标窗口 */}
            <Modal
            title="上传抖音直播目标窗口"
            visible={isUploadModalVisible}
            onOk={()=>setIsUploadModalVisible(false)}
            onCancel={()=>{
                setIsUploadModalVisible(false)
            }}
            footer={[null, null]}
            centered={true}
            >
            <Spin tip="Loading..." spinning={loading}>
                <Upload
                action={servicePath.development_uploadSuccess} //上传地址，填错/不填上传图片的时候都会报错
                // listType="picture-card"                             // 上传列表的内建样式
                fileList={fileList} //已经上传的文件列表（受控）
                // onPreview={handleFilePreview}                           //点击文件链接或预览图标时的回调
                onChange={({ fileList }) => {
                    setFileList(fileList);
                  }} //上传文件改变时的状态
                >
                {fileList.length >= 1 ? null : uploadFileButton}
                </Upload>
                <div style={{ marginLeft: 400 }}>
                <Button type="primary" onClick={() => uploadFile()} disabled={fileList.length >= 1 ?false:true}>
                    上传
                </Button>
                </div>
            </Spin>
            </Modal>

            <Modal
                title="添加抖音目标"
                visible={addTarget}
                centered={true}
                onCancel={()=>{
                    setAddTarget(false)
                    form.resetFields()
                }}
                footer={[]}
            >
                <Form form={form} {...layout} labelAlign='left' onFinish={addTargetForm}>
                  <Form.Item label="直播时间" name="date_detail" rules={[{ required: true }]}>
                    <Select>
                        <Option value={'上午'}>上午</Option>
                        <Option value={'下午'}>下午</Option>
                        <Option value={'晚上'}>晚上</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="直播时间" name="date" rules={[{ required: true }]}>
                    <DatePicker locale={locale} />
                  </Form.Item>
                  <Form.Item label="主播" name="anchor" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="助播" name="assist_anchor" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="中控" name="central_control" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="场控" name="field_control" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="销售成交" name="GMV" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="推广费用" name="promotion_cost" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                    <div style={{display:'flex', justifyContent:'space-around'}}>
                        <Button type="primary" danger onClick={()=>{
                            setAddTarget(false)
                            form.resetFields()
                        }}>取消</Button>
                        <Button type="primary" htmlType="submit">提交</Button>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}
export default Index;