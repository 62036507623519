import React, {useEffect, useState} from 'react';
import {Button, message, Spin, Form, Modal, Typography} from 'antd'
import Base64 from 'base-64'
import Gannt from './components/index'
import NbAxios from '../../../config/utils/nbAxios'
import servicePath from '../../../config/apiUrl'
import cookie from 'react-cookies'
import moment from 'moment'

const {Text} = Typography
const dateFormat = 'YYYY-MM-DD';

function Index(props) {
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv':"3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein':"444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an':"7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr':"f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11':'d4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass':'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    // 操作表单
    const [operatingForm] = Form.useForm()
    // 添加操作表单
    const [addOperatingForm] = Form.useForm()
    // 数据
    const [data, setData] = useState([])
    // 目录
    const [catalogueDict, setCatalogueDict] = useState([])
    // 事件id
    const [eventId, setEventId] = useState('')
    // 悬停时间
    const [hoverTime, setHoverTime] = useState('')
    // 操作详情是否打开
    const [operatingOpen, setOperatingOpen] = useState(false)
    // 操作id
    const [operatingId, setOperatingId] = useState('')
    // 主线事件集合
    const [mainEvent, setMainEvent] = useState([])
    // 是否添加操作
    const [isAddOperation, setIsAddOperation] = useState(false)
    // 添加操作数据
    const [addOperationData, setAddOperationData] = useState({})
    // 目录下标
    const [catalogueIndex, setCatalogueIndex] = useState('')
    // 目录父下标
    const [catalogueParentIndex, setCatalogueParentIndex] = useState('')
    // 全体员工
    const [employeeList, setEmployeeList] = useState([])
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    // 删除操作
    const [isDelete, setIsDelete] = useState(false)
    // 操作详情
    const [detail, setDetail] = useState('')
    // 记录上次操作的父Id
    const [ operationParentId , setOperationParentId  ] = useState('')
    // 是否文件上传
    const [ isUpload , setIsUpload ] = useState(0)
    // 操作名字
    const [ operationName , setOperationName ] = useState('')
    // 操作父名字
    const [ operationParentName , setOperationParentName ] = useState('')
    // 模型集合
    const [ modalList , setModalList ] = useState([])
    // 模型id
    const [ modalId , setModalId ] = useState('')
    useEffect(() => {
        getOtmPreviewData(props.location.state.eventId);
        getAllEployees()
        getOtmEventStage(props.location.state.eventId)
        getJurisdiction()
    }, [])

    //获取权限代码
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        NbAxios(dataProps, "POST", servicePath.getRoleShopPorts).then(res => {
            if (res.success) {
                setJurisdiction(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取公司所有员工
    const getAllEployees = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryPMSysUser).then(res => {
            if (res.success) {
                setEmployeeList(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询事件下阶段接口
    const getOtmEventStage = (eventId) => {
        let dataProps = {
            "uuid": eventId,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.queryOtmEventStage).then(res => {
            if (res.success) {
                setMainEvent(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 监控操作id
    useEffect(() => {
        if (operatingId != '') {
            let dataProps = {
                "otm_operation_id": operatingId,
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
            }
            NbAxios(dataProps, "POST", servicePath.queryOtmOperation).then(res => {
                if (res.success) {
                    setOperatingOpen(true)
                    operatingForm.resetFields()
                    setDetail(res.data['operation_detail'])
                    // 设置表单的值
                    operatingForm.setFieldsValue({
                        'otm_stage_name': res.data['otm_stage_name'],
                        'leader': res.data['leader'],
                        'leader_id': res.data['leader_id'],
                        'leader_username': res.data['leader_username'],
                        'operation_detail': res.data['operation_detail'],
                        'time': [moment(res.data['operation_start'], dateFormat), moment(res.data['operation_end'], dateFormat)]
                    })
                } else {
                    message.warn(res.errorMsg)
                }
            })

        }
    }, [operatingId])

    // // 监控事件id
    // useEffect(() => {
    //     if (eventId != '') {
    //         setCatalogueDict([])
    //         getSingleData(eventId)
    //     }
    // }, [eventId])

    useEffect(()=>{
        if(modalId != ''){
             let dataProps = {
                 "operation_model_id": modalId,
                 "roleId": cookie.load('role'),
                 "shopList": cookie.load('shopList'),
                 "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
             }
             NbAxios(dataProps, "POST", servicePath.queryOtmOperationModelDetailName).then(res => {
                 if (res.success) {
                     //操作名称
                     addOperationData['operation_name'] = res.data['operation_name']
                     //业务分类
                     addOperationData['type_business'] = res.data['type_business']
                     // 操作描述
                     addOperationData['operation_detail'] = res.data['operation_detail']
                     // 操作负责人
                     addOperationData['operation_principal'] = res.data['leader_list'][0]
                     // 阶段
                     addOperationData['event'] = mainEvent[0]['otm_stage_name']
                     addOperatingForm.resetFields()
                     // 设置表单的值
                     addOperatingForm.setFieldsValue(addOperationData)
                     setIsAddOperation(true)
                 }else {
                     message.warn(res.errorMsg)
                 }
             })
        }
     },[modalId])

    // 监听目录下标
    useEffect(() => {
        if (catalogueIndex != '') {
            let dataProps = {
                "name": operationName,
                "stage": operationParentName,
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
            }
            NbAxios(dataProps, "POST", servicePath.queryOtmOperationModelListName).then(res => {
                    if (res.success) {
                        if(catalogueIndex == 1){
                            //操作名称
                            addOperationData['operation_name'] = '主利益点'
                            // 阶段
                            addOperationData['event'] = mainEvent[0]['otm_stage_name']
                            addOperatingForm.resetFields()
                            // 设置表单的值
                            addOperatingForm.setFieldsValue(addOperationData)
                            setModalList([])
                            setIsAddOperation(true)
                        }else if(catalogueIndex == 2){
                            //操作名称
                            addOperationData['operation_name'] = '聚划算'
                            // 阶段
                            addOperatingForm.resetFields()
                            // 设置表单的值
                            addOperatingForm.setFieldsValue(addOperationData)
                            setModalList([])
                            setIsAddOperation(true)
                        }else{
                            setModalList(res.data);
                            setModalId(res.data[0]['id'])
                        }
                        
                    }else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }, [catalogueIndex])

    // 获取所有时间数据
    const getAllData = () => {
        let dataProps = {
            "user_uuid": cookie.load('id'),
            "start_time": "2021-01-01",
            "end_time": "2021-12-01",
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.getOtmData).then(res => {
            if (res.success) {
                setData(res.data['data'])
                setCatalogueDict(res.data['catalogue_dict'])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取单个事件数据
    const getOtmPreviewData = (eventId) => {
        let dataProps = {
            "user_uuid": cookie.load('id'),
            "uuid": eventId,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.getOtmPreviewData).then(res => {
            if (res.success) {
                setData(res.data['data'])
                setCatalogueDict(res.data['catalogue_dict'])
            } else {
                message.warn(res.errorMsg)
            }
        })
        setHoverTime(props.location.state.start_time)
    }

    // 重置
    const reset = () => {
        let dataProps = {
            "user_uuid": cookie.load('id'),
            "start_time": "2021-01-01",
            "end_time": "2021-12-01",
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.getOtmData).then(res => {
            if (res.success) {
                setData(res.data['data'])
                setCatalogueDict(res.data['catalogue_dict'])
            } else {
                message.warn(res.errorMsg)
            }
        })
        setHoverTime('')
    }

    // 更新操作
    const updateOperating = values => {
        values['otm_operation_id'] = operatingId
        values['start_time'] = values['time'][0].format('YYYY-MM-DD')
        values['end_time'] = values['time'][1].format('YYYY-MM-DD')
        values['roleId'] = cookie.load('role')
        values['shopList'] = cookie.load('shopList')
        values['shopId'] = shopIdDict[props.history.location.pathname.split('/')[3]]
        if (values['leader'].indexOf(",") != -1) {
            let leader = values['leader'].split(',')
            values['leader'] = leader[1]
            values['leader_id'] = leader[0]
            values['leade_username'] = leader[2]
        }
        NbAxios(values, "POST", servicePath.updateOtmOperation).then(res => {
            if (res.success) {
                setOperatingId('')
                setOperatingOpen(false)
                setCatalogueDict([])
                getOtmPreviewData(props.location.state.eventId)
                operatingForm.resetFields()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 添加事件
    const addOperating = values => {
        if(values['event'].indexOf(",") != -1){
            values['otm_event_uuid'] = values['event'].split(',')[1]
            values['otm_stage_id'] = values['event'].split(',')[0]
        }else{
            values['otm_event_uuid'] = mainEvent[0]['otm_event_uuid']
            values['otm_stage_id'] = mainEvent[0]['otm_stage_id']
        }
        values['start_time'] = values['time'][0].format('YYYY-MM-DD')
        values['end_time'] = values['time'][1].format('YYYY-MM-DD')
        values['roleId'] = cookie.load('role')
        values['shopList'] = cookie.load('shopList')
        values['shopId'] = shopIdDict[props.history.location.pathname.split('/')[3]]
        if (typeof(values['operation_principal'])!='string') {
            let leader = values['operation_principal'].split(',')
            values['leader'] = leader[1]
            values['leader_id'] = leader[0]
            values['leader_username'] = leader[2]
        }else{
            values['leader'] =  values['operation_principal']
        }
        values['creator'] = cookie.load('name')
        values['creator_id'] = cookie.load('id')
        values['creator_username'] = Base64.decode(cookie.load('email'))
        NbAxios(values, "POST", servicePath.addOtmOperation).then(res => {
            if (res.success) {
                setCatalogueIndex('')
                setIsAddOperation(false)
                setCatalogueDict([])
                getOtmPreviewData(props.location.state.eventId)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 删除操作
    const deleteOperation = () => {
        let values = {}
        values['operation_id'] = operatingId
        values['roleId'] = cookie.load('role')
        values['shopList'] = cookie.load('shopList')
        values['shopId'] = shopIdDict[props.history.location.pathname.split('/')[3]]
        NbAxios(values, "POST", servicePath.deleteOtmOperation).then(res => {
            if (res.success) {
                setOperatingId('')
                setOperatingOpen(false)
                setCatalogueDict([])
                getOtmPreviewData(props.location.state.eventId)
                setIsDelete(false)
                setOperatingOpen(false)
                setOperatingId('')
            } else {
                message.warn(res.errorMsg)
            }
        })
    }


    return (
        <div id="app">
            {
                Object.keys(catalogueDict).length == 0 ?
                    <div style={{display: "flex", justifyContent: 'center', height: '40vh', alignItems: 'center'}}><Spin size="large" tip="数据加载中..."/></div>
                    : <Gannt data={data} catalogueDict={catalogueDict}
                             setEventId={setEventId} hoverTime={hoverTime}
                             setHoverTime={setHoverTime} addOperatingForm={addOperatingForm}
                             operatingOpen={operatingOpen} setOperatingOpen={setOperatingOpen}
                             setOperatingId={setOperatingId} operatingForm={operatingForm}
                             updateOperating={updateOperating} isAddOperation={isAddOperation}
                             setIsAddOperation={setIsAddOperation} setCatalogueIndex={setCatalogueIndex}
                             setAddOperationData={setAddOperationData} addOperating={addOperating}
                             mainEvent={mainEvent} setCatalogueParentIndex={setCatalogueParentIndex}
                             employeeList={employeeList} props={props} jurisdiction={jurisdiction}
                             setIsDelete={setIsDelete} operationParentId={operationParentId} setOperationParentId={setOperationParentId}
                             isUpload = {isUpload} setOperationName={setOperationName} setOperationParentName={setOperationParentName}
                             setModalId = {setModalId} modalList = {modalList} modalId = {modalId}
                    />
            }
            {
                Object.keys(catalogueDict).length == 0 ? <div></div>
                    :
                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: "30px", paddingBottom: '30px'}}>
                        <Button onClick={() => {
                            props.history.push({'pathname': `/app/otm/${props.history.location.pathname.split('/')[3]}/gannt/tmall`,})
                        }} type="primary" style={{width: "15vw"}}>确认</Button>
                    </div>
            }

            <Modal
                title="是否要删除该操作"
                visible={isDelete}
                onCancel={() => {
                    setIsDelete(false)
                }}
                onOk={deleteOperation}
                okText="确认"
                cancelText="取消"
                centered={true}
                zIndex={1005}
            >
                <span>是否要删除<Text strong>{detail}</Text></span>
            </Modal>

        </div>

    )


}

export default Index