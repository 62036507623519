import React, { useState, useEffect } from 'react';
import { Form, Upload, Table, Input, Drawer, Tooltip, Button, Modal, message, DatePicker, Space, Divider, Row, Col, Typography } from 'antd';
import { LeftOutlined, InboxOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios';
import servicePath from '../../../config/apiUrl';
import downExcel from '../../../config/utils/downloadFile';
import axios from 'axios'
import locale from 'antd/es/date-picker/locale/zh_CN';
//时间框
const { RangePicker } = DatePicker;
// 文件上传组建
const { Dragger } = Upload;
const { Title, Text } = Typography
// 主页面函数
function Index(props) {
    /*其他*/
    const [form] = Form.useForm();
    
    /*useState*/
    const [factoryname, setFactoryName] = useState("")
    const [corporatename, setCorporateName] = useState("")
    //  上传订单弹框
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const showUploadModal = () => {
        setIsUploadModalVisible(true);
    };
    const handleUploadOk = () => {
        setIsUploadModalVisible(false);
    };
    const handleUploadCancel = () => {
        setIsUploadModalVisible(false);
    };
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    // 预算目标文件
    const [baseFile, setBaseFile] = useState([])
    // 搜索json
    const [searchJson, setSearchJson] = useState(undefined)
    // 表格数据
    const [data, setData] = useState([])
    // 分页展示
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(100)
    //搜索框
    const [visible, setVisible] = useState(false);
    // 是否上传文件
    const [isUpload, setIsUpload] = useState(false)
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    /*table列*/
    //表格的列名
    const columns = [
        {
            title: '入库时间',
            dataIndex: 'time',
            key: 'time',
            width: 120,
            align: 'center',
            fixed:true,
        },
        {
            title: '供应商名称',
            dataIndex: 'corporate_name',
            key: 'corporate_name',
            align: 'center',
            align: 'center',
            width: 120,
            fixed:true,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '工厂名称',
            dataIndex: 'factory_name',
            key: 'factory_name',
            align: 'center',
            width: 120,
            fixed:true,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '采购单号',
            dataIndex: 'purchase_order',
            key: 'purchase_order',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '入库单号',
            dataIndex: 'warehousing_order',
            key: 'warehousing_order',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '批次号',
            dataIndex: 'batch_number',
            key: 'batch_number',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '商品编码',
            dataIndex: 'commodity_code',
            key: 'commodity_code',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '商品条形码',
            dataIndex: 'bar_code',
            key: 'bar_code',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '商品名称',
            dataIndex: 'project_name',
            key: 'project_name',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '产品类型',
            dataIndex: 'product_type',
            key: 'product_type',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '规格',
            dataIndex: 'specifications',
            key: 'specifications',
            align: 'center',
            width: 80,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
            width: 80,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '单价',
            dataIndex: 'unit_price',
            key: 'unit_price',
            align: 'center',
            width: 80,
            render: text => (
                <span>
                    {parseFloat(text).toFixed(4)}
                </span>
            )
        },
        {
            title: '合计金额',
            dataIndex: 'total_amount',
            key: 'total_amount',
            align: 'center',
            width: 120,
            render: text => (
                <span>
                    {parseFloat(text).toFixed(2)}
                </span>
            )
        },
        {
            title: '生产日期',
            dataIndex: 'date_of_manufacture',
            key: 'date_of_manufacture',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '下单时间',
            dataIndex: 'order_time',
            key: 'order_time',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: '到货时间',
            dataIndex: 'arrival_time',
            key: 'arrival_time',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: '到货时长',
            key: 'corresponding_time',
            dataIndex: 'corresponding_time',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: '预付款',
            key: 'advance_payment',
            dataIndex: 'advance_payment',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: '预付款时间',
            key: 'advance_payment_time',
            dataIndex: 'advance_payment_time',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: '是否支付预付款',
            key: 'advance_pay',
            dataIndex: 'advance_pay',
            align: 'center',
            width: 140,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: '尾款',
            key: 'balance_payment',
            dataIndex: 'balance_payment',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: '尾款时间',
            key: 'balance_payment_time',
            dataIndex: 'balance_payment_time',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: '是否支付尾款',
            key: 'balance_pay',
            dataIndex: 'balance_pay',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )

        },
    ];
    //初始界面
    useEffect(() => {
        searchOrder(searchJson)
        getJurisdiction()
    }, [page])

    // 获取所有角色/店铺对应的权限
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": ['82328e58-068b-4a27-9bd0-9d1649cb8e6e']
        }
        NbAxios(dataProps, "POST", servicePath.getRoleShopPorts).then(res => {
            if (res.success) {
                setJurisdiction(res.data)
            }
        })
    }

    useEffect(() => {
        //判断上个界面有无传值，是否从路由直接进入
        if (props.location.state != undefined) {
            form.setFieldsValue(
                {
                    "corporate_name": props.location.state.corporate_name,
                    "factory_name": props.location.state.factory_name
                }
            )
        }
    }, [])

    /*接口*/
    //搜索框，在进入Drawer抽屉查询时，内容已经存在供应商名和工厂名
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    //搜索表格数据
    // corporate_name,factory_name
    const searchOrder = (value) => {
        // 初始化查询的时候
        if (value == undefined) {
            value = {}
            //判断上个界面有无传值，是否从路由直接进入
            if (props.location.state != undefined) {
                value["corporate_name"] = props.location.state.corporate_name
                value["factory_name"] = props.location.state.factory_name
            }
        }
        value["page"] = page
        value['roleId'] = cookie.load('role')
        value['shopList'] = cookie.load('shopList')
        setSearchJson(value)
        NbAxios(value, "POST", servicePath.searchOrder).then(res => {
            if (res.success) {
                onClose()
                setTotal(res.total);
                setData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    //  上传附件信息
    const uploadOrder = () => {
        if (baseFile.length != 0) {
            setEffective(1)
            const formData = new FormData()
            // 多个图片
            baseFile.forEach((item) => {
                formData.append('order_file', item['originFileObj']);

            })
            formData.append('corporate_name', corporatename)
            formData.append('factory_name', factoryname)
            formData.append('roleId', cookie.load('role'));
            formData.append('shopList', cookie.load('shopList'));
            NbAxios(formData, "POST", servicePath.uploadOrder).then(res => {
                setEffective(0)
                if (res.success) {
                    setIsUploadModalVisible(false)
                    setBaseFile([]);
                    message.success('文件数据上传成功');
                    setIsUpload(false)
                    searchOrder(searchJson)
                    // props.history.push({
                    //     'pathname': '/pageorderlinshi',
                    //     'state': {
                    //     'corporate_name': corporatename,
                    //     'factory_name': factoryname,
                    //     'order_data': res.data
                    // }
                    // })
                } else {
                    message.warn(res.errorMsg)
                }
            })
        } else {
            message.warn('请选择文件')
        }
    }
    // 预算目标文件上传
    const baseFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx', 'jpeg']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setBaseFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setBaseFile(fileList)
        }
    }
    // 附件信息下载
    const downloadOrder = () => {
        let data = searchJson
        axios({
            data: data,
            method: 'POST',
            url: servicePath.downloadOrder,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                downExcel(res)
            }
        ).catch(e => {
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }
    //限制页面输出
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-around", marginBottom: 20, marginTop: 10 , borderBottom:"1px solid #ccc"}}>
                <Title level={4}>订单管理</Title>
                <div style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "flex" : "none"}}>
                    <Button type="link" style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none"}}>
                        <a href={"https://pic.bmcsoft.cn/imge_dir/订单信息上传模版.xlsx"}>下载订单信息上传模版</a>
                    </Button>
                    <a href="https://pic.bmcsoft.cn/imge_dir/122717595311/122717595311.html" target="_Blank"><Button type="link">操作手册</Button></a>
                    <Button type="primary" onClick={showDrawer} style={{ marginRight: 10 }}>条件查询</Button>
                    <div style={{display: "flex"}}>
                        <Button type="primary" onClick={showUploadModal} style={{ marginRight: 10, display:jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none" }}>上传</Button>
                        <Button style={{ marginRight: 10, display:jurisdiction.indexOf("threepets::exportSMSData") != -1 ? "" : "none" }} type="primary" onClick={() => {downloadOrder()}} danger>下载</Button>
                    </div>
                    <Button type="primary" onClick={() => {
                        props.history.push({
                            'pathname': '/app/three/pets/sms/commodity'
                        })
                    }}>商品页面</Button>
                </div>
            </div>
            <Table 
                style={{marginLeft:20, marginRight:20}}
                scroll={{ x: 900, y: 'calc(100vh - 250px)' }}
                columns={columns}
                dataSource={data}
                align="center"
                pagination={paginationProps}
                bordered hideOnSinglePage={true} />
            <Modal
                title="上传订单信息"
                visible={isUploadModalVisible}
                centered={true}
                okText="确定"
                cancelText="取消"
                maskClosable={true}
                onOk={() => {
                    uploadOrder()
                }}
                onCancel={() => {
                    message.success('取消上传订单信息')
                    setBaseFile([])
                    handleUploadCancel()
                }}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.findTrue}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={baseFile}
                    onChange={baseFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
            </Modal>

            <Drawer title="订单条件查询"
                placement="right"
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                width={720}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={searchOrder}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="corporate_name"
                                label="供应商名称"
                            >
                                <Input placeholder="请输入供应商名称" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="factory_name"
                                label="工厂名称"
                            >
                                <Input placeholder="请输入工厂名称" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="purchase_order"
                                label="采购单号"
                            >
                                <Input placeholder="采购单号（对应ERP系统）" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="commodity_code"
                                label="商品编码"
                            >
                                <Input placeholder="请输入商品编码" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="warehousing_order"
                                label="入库单号"
                            >
                                <Input placeholder="请输入入库单号" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="batch_number"
                                label="批次号"
                            >
                                <Input placeholder="请输入批次号" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="product_type"
                                label="产品类型"
                            >
                                <Input placeholder="请输入产品类型" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="project_name"
                                label="商品名称"
                            >
                                <Input placeholder="请输入商品名称" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="specifications"
                                label="单价"
                            >
                                <Input placeholder="请输入单价" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="corresponding_time"
                                label="到货时长"
                            >
                                <Input placeholder="请输入到货时长" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="advance_pay"
                                label="是否支付预付款"
                            >
                                <Input placeholder="是否支付预付款" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="balance_pay"
                                label="是否支付尾款"
                            >
                                <Input placeholder="是否支付尾款" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="time"
                                label="入库时间"
                            >
                                <RangePicker locale={locale} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="order_time"
                                label="下单时间"
                            >
                                <RangePicker locale={locale} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="arrival_time"
                                label="到货时间"
                            >
                                <RangePicker locale={locale} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="date_of_manufacture"
                                label="生产日期"
                            >
                                <RangePicker locale={locale} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: 'space-around' }}>
                        <Button type="primary" htmlType="submit"
                            onClick={() => {
                                onClose()
                            }}>
                            查询
                        </Button>
                        <Button type="primary" onClick={() => {
                            //重置form
                            setFactoryName('')
                            setCorporateName('')
                            searchOrder({})
                            form.resetFields();
                        }}>
                            重置
                        </Button>
                    </div>
                </Form>

            </Drawer>
        </div>
    )
};


export default Index; 