import React, {useState, useEffect} from 'react'
import {
    Popover,
    Input,
    InputNumber,
    Table,
    Button,
    Tag,
    Form,
    Row,
    Upload,
    message,
    Typography,
    Col,
    Tooltip,
    Modal,
    Collapse,
    Radio,
    Spin,
    Popconfirm
} from 'antd'
import {InboxOutlined} from '@ant-design/icons';
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios'
import axios from 'axios'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import Base64 from 'base-64'
import servicePath from '../../../config/apiUrl'
const {TextArea} = Input;
const {CheckableTag} = Tag
const {Dragger} = Upload;
const {Text, Paragraph} = Typography;
const {Panel} = Collapse;
function Index(props) {
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    // 搜索粉丝数
    const [searchTag, setSearchTag] = useState(0)
    const [searchMinFans, setSearchMinFans] = useState(undefined)
    const [searchMaxFans, setSearchMaxFans] = useState(undefined)
    // 搜索人设标签
    const [humanLabelTag, setHumanLabelTag] = useState(0)
    const [searchHumanLabel, setSearchHumanLabel] = useState('')
    // 搜索阅读成本
    const [searchReadTag, setSearchReadTag] = useState(0)
    const [searchMinRead, setSearchMinRead] = useState(undefined)
    const [searchMaxRead, setSearchMaxRead] = useState(undefined)
    // 搜索互动成本
    const [searchInteractiveTag, setSearchInteractiveTag] = useState(0)
    const [searchMinInteractive, setSearchMinInteractive] = useState(undefined)
    const [searchMaxInteractive, setSearchMaxInteractive] = useState(undefined)
    // 搜索昵称
    const [searchName, setSearchName] = useState('')
    const [ searchNameTag , setSearchNameTag ] = useState(0)
    // 平台数据
    const [ platformSeries , setPlatformSeries ] = useState([])
    // 平台时间
    const [ platformTime , setPlatformTime ] = useState([])
    const content = (
        <div >
            <div style={{ display: 'flex', }}>
                <div>
                   <Form>
                        <Input.Group compact>
                            <InputNumber
                                min={0}
                                value={searchMinFans}
                                style={{width:'35%'}}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} 
                                onChange={value=>setSearchMinFans(value)}
                                />
                            <Input
                                className="site-input-split"
                                style={{
                                    width: 40,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    pointerEvents: 'none',
                                    textAlign: 'center'
                                }}
                                placeholder="至"
                                disabled
                            />
                            <InputNumber
                                min={0}
                                value={searchMaxFans}
                                style={{width:'35%'}}
                                onChange={value=>setSearchMaxFans(value)}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                        </Input.Group>
                   </Form>
                </div>
                <div style={{ lineHeight: '30px', marginLeft: 10 }}><a htmlType="submit" onClick={() => {
                    if(searchMinFans>searchMaxFans || searchMaxFans == undefined || searchMinFans == undefined){
                        message.warn('请输入正确的粉丝数区间')
                    }else{
                        setMinFans((searchMinFans/10000).toString());
                        setMaxFans((searchMaxFans/10000).toString());
                        setFansLabelSelectedTags('')
                        setSearchTag(1)
                        setCloseBubble(false)
                    }
                }}>确定</a></div>
            </div>
        </div>
    );


    // 昵称输入框
    const nameContent = (
        <div>
             <div style={{ display: 'flex', }}>
                <div><Input style={{width:140}} value={searchName} onChange={(e)=>{setSearchName(e.target.value)}}/></div>
                <div style={{ lineHeight: '30px', marginLeft: 10, fontSize:'15px' }}><a onClick={()=>{
                    setName(searchName)
                    setSearchNameTag(1)
                    setCloseNameBubble(false)
                }}>确定</a></div>
                 <div style={{lineHeight: '30px', marginLeft: 5, fontSize: '15px'}}><a onClick={() => {
                     setName('')
                     setSearchName('')
                     setSearchNameTag(0)
                     setCloseNameBubble(false)
                 }}>重置</a></div>
             </div>
        </div>
    )

    //人设标签输入框
    const humanLabelContent = (
        <div>
            <div style={{display: 'flex',}}>
                <div><Input style={{width: 140}} value={searchHumanLabel} onChange={(e) => {
                    setSearchHumanLabel(e.target.value)
                }}/></div>
                <div style={{lineHeight: '30px', marginLeft: 10, fontSize: '15px'}}><a onClick={() => {
                    setHumanLabelSelectedTags(searchHumanLabel)
                    setHumanLabelTag(1)
                    setHumanLabelCloseBubble(false)
                }}>确定</a></div>
                <div style={{lineHeight: '30px', marginLeft: 5, fontSize: '15px'}}><a onClick={() => {
                    setHumanLabelSelectedTags('全部')
                    setSearchHumanLabel('')
                    setHumanLabelTag(0)
                    setHumanLabelCloseBubble(false)
                }}>重置</a></div>
            </div>
        </div>
    )

    // 阅读成本
    const readContent = (
        <div>
            <div style={{display: 'flex',}}>
                <div>
                    <Form>
                        <Input.Group compact>
                            <InputNumber
                                min={0}
                                value={searchMinRead}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} 
                                onChange={value=>setSearchMinRead(value)}
                                />
                            <Input
                                className="site-input-split"
                                style={{
                                    width: 40,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    pointerEvents: 'none',
                                    textAlign: 'center'
                                }}
                                placeholder="至"
                                disabled
                            />
                            <InputNumber
                                min={0}
                                value={searchMaxRead}
                                onChange={value=>setSearchMaxRead(value)}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                        </Input.Group>
                   </Form>
                </div>
                <div style={{ lineHeight: '30px', marginLeft: 10 }}><a htmlType="submit" onClick={() => {
                    if(searchMinRead>searchMaxRead || searchMaxRead == undefined || searchMinRead == undefined){
                        message.warn('请输入正确的阅读成本区间')
                    }else{
                        setMinRead(searchMinRead.toString());
                        setMaxRead(searchMaxRead.toString());
                        setSearchReadTag(1)
                        setCloseReadBubble(false)
                    }
                }}>确定</a>
                <a style={{marginLeft:10}} onClick={()=>{
                    setMinRead('');
                    setMaxRead('');
                    setSearchMinRead(undefined)
                    setSearchMaxRead(undefined)
                    setSearchReadTag(0)
                    setCloseReadBubble(false)
                }}>重置</a>
                </div>
            </div>
        </div>
    );

     // 互动成本
     const interactiveContent = (
        <div >
            <div style={{ display: 'flex', }}>
                <div>
                   <Form>
                        <Input.Group compact>
                            <InputNumber
                                min={0}
                                value={searchMinInteractive}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} 
                                onChange={value=>setSearchMinInteractive(value)}
                                />
                            <Input
                                className="site-input-split"
                                style={{
                                    width: 40,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    pointerEvents: 'none',
                                    textAlign: 'center'
                                }}
                                placeholder="至"
                                disabled
                            />
                            <InputNumber
                                min={0}
                                value={searchMaxInteractive}
                                onChange={value=>setSearchMaxInteractive(value)}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                        </Input.Group>
                   </Form>
                </div>
                <div style={{ lineHeight: '30px', marginLeft: 10 }}><a htmlType="submit" onClick={() => {
                    if(searchMinInteractive>searchMaxInteractive || searchMaxInteractive == undefined || searchMinInteractive == undefined){
                        message.warn('请输入正确的互动成本区间')
                    }else{
                        setMinInteractive(searchMinInteractive.toString());
                        setMaxInteractive(searchMaxInteractive.toString());
                        setSearchInteractiveTag(1)
                        setCloseInteractiveBubble(false)
                    }
                }}>确定</a>
                <a style={{marginLeft:10}} onClick={()=>{
                    setMinInteractive('');
                    setMaxInteractive('');
                    setSearchMaxInteractive(undefined)
                    setSearchMinInteractive(undefined)
                    setSearchInteractiveTag(0)
                    setCloseInteractiveBubble(false)
                }}>重置</a>
                </div>
            </div>
        </div>
    );

    const layout = {
        labelCol: { span: 2 },
    };
    const layout1 = {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
    };
    // 表格
    let report = {
        title: {
            text: '平台数据'
        },
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: platformTime
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
                onZero: true
            },
        },
        series: platformSeries
    }
    // 是否在加载中
    const [isloading, setIsloading] = useState(false)
    // 页数
    const [page, setPage] = useState(1)
    // 总数
    const [total, setTotal] = useState(0)
    // 平台标签集合
    const platformTagsData = ['全部', '小红书', '微博', '抖音', 'B站']
    // 选择平台标签
    const [platformSelectedTags, setPlatformSelectedTags] = useState('全部')
    // 笔记标签
    const noteLabelTagsData = ['全部', '种草安利', '测评', 'vlog', '探店', '教程干货']
    // 选择笔记标签
    const [noteLabelSelectedTags, setNoteLabelSelectedTags] = useState('全部')
    // 人设标签
    const humanLabelTagsData = ['全部', '时尚穿搭', '美妆', '护肤', '美食', '萌宠', '家居生活']
    // 选择人设标签
    const [humanLabelSelectedTags, setHumanLabelSelectedTags] = useState('全部')
    // 粉丝数标签
    const fansLabelTagsData = ['全部', '1000w以上', '500w-1000w', '300w-500w', '100w-300w', '50w-100w', '10w-50w','10w以下']
    // 选择粉丝标签
    const [fansLabelSelectedTags, setFansLabelSelectedTags] = useState('全部')
    // 最小粉丝数
    const [ minFans , setMinFans ] = useState('')
    // 最大粉丝数
    const [ maxFans , setMaxFans ] = useState('')
    // 最小阅读量
    const [ minRead , setMinRead ] = useState('')
    // 最大阅读量
    const [ maxRead , setMaxRead ] = useState('')
    // 最小互动量
    const [ minInteractive , setMinInteractive ] = useState('')
    // 最大互动量
    const [ maxInteractive , setMaxInteractive] = useState('')
    // 昵称
    const [ name , setName ] = useState('')
    // 数据
    const [data, setData] = useState([])
    // KOLid
    const [kolId, setKolId] = useState('')
    // 联系方式
    const [phone, setPhone] = useState('')
    // 查看联系方式
    const [openPhone, setOpenPhone] = useState(false)
    // 打开店铺数据表格
    const [openChart, setOpenChart] = useState(false)
    // 上传KOL数据
    const [uploadData, setUploadData] = useState(false)
    // 上传KOL成本数据
    const [uploadCostData, setUploadCostData] = useState(false)
    // 上传KOL投放数据
    const [uploadLaunchData, setUploadLaunchData] = useState(false)
    // 上传KOL文件
    const [uploadFile, setUploadFile] = useState([])
    // 上传KOL成本文件
    const [uploadCostFile, setUploadCostFile] = useState([])
    // 上传KOL投放文件
    const [uploadLaunchFile, setUploadLaunchFile] = useState([])
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    // 关闭气泡
    const [closeBubble, setCloseBubble] = useState(false)
    const [closeHumanLabelBubble, setHumanLabelCloseBubble] = useState(false)
    const [closeNameBubble, setCloseNameBubble] = useState(false)
    const [closeReadBubble, setCloseReadBubble] = useState(false)
    const [closeInteractiveBubble, setCloseInteractiveBubble] = useState(false)
    // 修改KOL数据
    const [updateData, setUpdateData] = useState(false)
    // 按钮是否有效
    const [effective, setEffective] = useState(false)
    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current:page,
        total: total,
        showTotal:(total)=>{
            return `共${total}位`
        },
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    // 表格标题
    const columns = [
        {
            title: '平台',
            dataIndex: 'platform',
            align: 'center',
            fixed:true,
            width: 80
        },
        {
            title: '昵称',
            dataIndex: 'nickname',
            align: 'center',
            fixed:true,
            width: 200,
            render: (text, record, index) => (
                <a href={record.url} target="_blank" style={{ textDecoration: 'underline' }}>{text}</a>
            ),
        },
        {
            title: '联系方式',
            dataIndex: 'contact',
            align: 'center',
            width: 120,
            render: (text, record, index) => (
                <a onClick={()=>{
                    let dataProps = {
                        "roleId": cookie.load('role'),
                        "shopId": cookie.load('shopList'),
                        "platform":record.platform,
                        "kol_id": record.id,
                        "nickname":record.nickname,
                        "principal":cookie.load('name'),
                        "principal_uuid":cookie.load('id'),
                        "principal_email":Base64.decode(cookie.load('email'))
                    }
                    NbAxios(dataProps, "POST", servicePath.queryPlatformKolContent).then(res => {
                        if (res.success) {
                            setPhone(res.data);
                            setOpenPhone(true)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}>查看联系方式</a>
            ),
        },
        {
            title: '资源对接人',
            dataIndex: 'docking',
            align: 'center',
            width: 100,
        },
        {
            title: '所属',
            dataIndex: 'belonging',
            align: 'center',
            width: 100,
            render: (text, record, index) => (
                <Tooltip placement="bottom" title={text}>
                    <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: '形式',
            dataIndex: 'form',
            align: 'center',
            width: 100,
        },
        {
            title: '人设标签',
            dataIndex: 'human_label',
            align: 'center',
            width: 120,
        },
        {
            title: '笔记标签',
            dataIndex: 'note_label',
            align: 'center',
            width: 120,
        },
        {
            title: '阅读成本',
            dataIndex: 'read_cost',
            align: 'center',
            width: 100,
        },
        {
            title: '互动成本',
            dataIndex: 'interactive_cost',
            align: 'center',
            width: 100,
        },
        {
            title: '粉丝数(w)',
            dataIndex: 'fans',
            align: 'center',
            width: 100
        },
        {
            title: '总藏赞(w)',
            dataIndex: 'favorites',
            align: 'center',
            width: 100
        },
        {
            title: '总点赞(w)',
            dataIndex: 'like',
            align: 'center',
            width: 100
        },
        {
            title: '总播放量(w)',
            dataIndex: 'play',
            align: 'center',
            width: 120
        },
        {
            title: '可直播',
            dataIndex: 'is_live',
            align: 'center',
            width: 120,
            render: (text, record, index) => (
                text == undefined ? <span></span> :
                text == 1 ? <Tag color="green">可直播</Tag>
                : <Tag color="red">不可直播</Tag>
            )
        },
        {
            title: '操作',
            dataIndex: 'fans',
            align: 'center',
            width: 190,
            render: (text, record, index) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <div><a onClick={() => {
                        let dataProps = {
                            "roleId": cookie.load('role'),
                            "shopId": cookie.load('shopList'),
                            "platform":record.platform,
                            "kol_id": record.id,
                            "nickname":record.nickname,
                            "principal":cookie.load('name'),
                            "principal_uuid":cookie.load('id'),
                            "principal_email":Base64.decode(cookie.load('email'))
                        }
                        NbAxios(dataProps, "POST", servicePath.queryPlatformKolEchart).then(res => {
                            if (res.success) {
                                setPlatformTime(res.data.month)
                                setPlatformSeries(res.data.series)
                                setOpenChart(true)
                            } else {
                                message.warn(res.errorMsg)
                            }
                        })
                    }}>查看平台数据</a></div> */}
                    <div>
                        <a onClick={()=>{
                             let dataProps = {
                                "roleId": cookie.load('role'),
                                "shopId": cookie.load('shopList'),
                                "platform":record.platform,
                                "kol_id": record.id,
                                "nickname":record.nickname,
                                "principal":cookie.load('name'),
                                "principal_uuid":cookie.load('id'),
                                "principal_email":Base64.decode(cookie.load('email'))
                            }
                            NbAxios(dataProps, "POST", servicePath.queryPlatformKolContent).then(res => {
                                if (res.success) {
                                    props.history.push(
                                        {'pathname':'/app/kol/person',
                                         'state': { 'platform':record.platform, 'kol_id': record.id, 'nickname': record.nickname, 'contact': res.data, 'belonging': record.belonging, 'form':record.form, 'human_label': record.human_label, 'note_label':record.note_label }
                                        })
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }}>查看KOL个人详情</a>
                    </div>
                    <div>/</div>
                    <div><a onClick={()=>{
                         let dataProps = {
                            "roleId": cookie.load('role'),
                            "shopId": cookie.load('shopList'),
                            "platform":record.platform,
                            "kol_id": record.id,
                            "nickname":record.nickname,
                            "principal":cookie.load('name'),
                            "principal_uuid":cookie.load('id'),
                            "principal_email":Base64.decode(cookie.load('email'))
                        }
                        NbAxios(dataProps, "POST", servicePath.queryPlatformKolContent).then(res => {
                            if (res.success) {
                                record.contact = res.data
                                setKolId(record.id)
                                form.resetFields()
                                // 设置表单的值
                                form.setFieldsValue(record)
                                // 打开修改弹框
                                setUpdateData(true)
                            } else {
                                message.warn(res.errorMsg)
                            }
                        })

                    }}>修改</a></div>
                    <div style={{display: jurisdiction.indexOf("kol::deleteKol") != -1 ? "" : "none"}}>/</div>
                    <Popconfirm title="你是否要禁用这个KOL？" okText="确定" cancelText="取消" onConfirm={() => {
                        let dataProps = {
                            "roleId": cookie.load('role'),
                            "shopId": cookie.load('shopList'),
                            "platform": record.platform,
                            "kol_id": record.id,
                            "nickname": record.nickname,
                            "principal": cookie.load('name'),
                            "principal_uuid": cookie.load('id'),
                            "principal_email": Base64.decode(cookie.load('email'))
                        }
                        NbAxios(dataProps, "POST", servicePath.disablePlatformKolData).then(res => {
                            if (res.success) {
                                message.success("成功禁用该KOL")
                                getAllPlatformKol()
                            } else {
                                message.warn(res.errorMsg)
                            }
                        })

                    }}>
                        <a style={{display: jurisdiction.indexOf("kol::deleteKol") != -1 ? "" : "none"}}>禁用</a>
                    </Popconfirm>

                </div>
            ),
        },
    ]
    // KOL数据上传
    const fileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setUploadFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setUploadFile(fileList)
        }
    }

    // KOL数据上传
    const fileCostHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setUploadCostFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setUploadCostFile(fileList)
        }
    }

     // KOL数据上传
     const fileLaunchHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setUploadLaunchFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setUploadLaunchFile(fileList)
        }
    }


    useEffect(() => {
        if (document.getElementById('report') != null) {
            // 基于准备好的dom，初始化echarts实例
            let reportChart = echarts.init(document.getElementById('report'));
            // 绘制图表
            reportChart.setOption(report);
            window.addEventListener('resize', () => {
                reportChart.resize()
            });
        } else {
            if (openChart == true) {
                setTimeout(() => {
                    // 基于准备好的dom，初始化echarts实例
                    let reportChart = echarts.init(document.getElementById('report'));
                    // 绘制图表
                    reportChart.setOption(report);
                    window.addEventListener('resize', () => {
                        reportChart.resize()
                    });
                }, 100)
            }
        }
    }, [openChart,platformSeries])

    // 页面，平台，笔记标签，人际标签，粉丝数修改的时候触发
    useEffect(()=>{
        if(page != 1){
            setPage(1)
        }else{
            getAllPlatformKol()
        }
    },[platformSelectedTags, noteLabelSelectedTags, humanLabelSelectedTags, minFans, name, minRead, minInteractive])

    useEffect(()=>{
        getAllPlatformKol()
    },[page])

    // 粉丝标签修改的时候触发
    useEffect(()=>{
       if(fansLabelSelectedTags != ''){
            setSearchTag(0)
            if(searchMinFans != undefined){
                setSearchMinFans(undefined)
            }
           if (searchMaxFans != undefined) {
               setSearchMaxFans(undefined)
           }
           if (fansLabelSelectedTags == '全部') {
               setMaxFans('')
               setMinFans('')
           } else if (fansLabelSelectedTags == '1000w以上') {
               setMaxFans('')
               setMinFans('1000')
           } else if(fansLabelSelectedTags == '10w以下'){
                setMaxFans('10')
                setMinFans('0')
           } else {
               setMaxFans(fansLabelSelectedTags.split('-')[1].replace(/[^0-9]/ig, ""));
               setMinFans(fansLabelSelectedTags.split('-')[0].replace(/[^0-9]/ig, ""));
           }
       }
    }, [fansLabelSelectedTags])

    //获取权限代码
    useEffect(() => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    }, [])

    // 获取各个平台KOL数据
    const getAllPlatformKol = () => {
        setIsloading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "name": name,
            "human_label": humanLabelSelectedTags,
            "note_label": noteLabelSelectedTags,
            "min_fans": minFans,
            "max_fans": maxFans,
            "min_read": minRead,
            "max_read": maxRead,
            "min_interactive": minInteractive,
            "max_interactive": maxInteractive,
            "platform": platformSelectedTags,
            "page": page,
            "page_size": 10
        }
        NbAxios(dataProps, "POST", servicePath.queryAllPlatformKol).then(res => {
            setIsloading(false)
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 平台选择
    const platformHandleChange = (tag, checked) => {
        setPlatformSelectedTags(tag)
    }
    // 笔记选择
    const noteLabelHandleChange = (tag, checked) => {
        setNoteLabelSelectedTags(tag)
    }
    // 人设标签选择
    const humanLabelHandleChange = (tag, checked) => {
        setHumanLabelTag(0)
        setSearchHumanLabel('')
        setHumanLabelSelectedTags(tag)
    }
    // 粉丝标签选择
    const fansLabelHandleChange = (tag, checked) => {
        setFansLabelSelectedTags(tag)
    }

    // 更新数据
    const updateDataOk = values=>{
        values.kol_id = kolId
        values.principal = cookie.load('name')
        values.principal_uuid = cookie.load('id')
        values.principal_email = Base64.decode(cookie.load('email'))
        values.shopId = cookie.load('shopList')
        values.roleId = cookie.load('role')
        values.phone = values.contact
        NbAxios(values, "POST", servicePath.updatePlatformKolData).then(res => {
            if (res.success) {
                message.success('成功更新KOL基本信息')
                getAllPlatformKol()
                setUpdateData(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 上传KOL数据
    const uploadKolData = ()=>{
        if(uploadFile.length == 0){
            message.warn('请选择您要上传的文件')
        }else{
            setEffective(true)
            // 上传原始文件例子
            const formData = new FormData()
            // 多个图片
            uploadFile.forEach((item) => {
                formData.append('file ', item['originFileObj']);
            })
            formData.append('shopId', cookie.load('shopList'))
            formData.append('roleId', cookie.load('role'))
            formData.append('principal_name', cookie.load('name'))
            formData.append('principal_uuid', cookie.load('id'))
            formData.append('principal_email', Base64.decode(cookie.load('email')))
            NbAxios(formData, "POST", servicePath.uploadKolData).then(res => {
                setEffective(false)
                if(res.success){
                    setUploadFile([])
                    form.resetFields()
                    message.success('数据上传成功')
                    getAllPlatformKol()
                    setUploadData(false)
                }else{
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 上传KOL成本数据
    const uploadKolCostData = ()=>{
        if(uploadCostFile.length == 0){
            message.warn('请选择您要上传的文件')
        }else{
            setEffective(true)
            // 上传原始文件例子
            const formData = new FormData()
            // 多个图片
            uploadCostFile.forEach((item) => {
                formData.append('file ', item['originFileObj']);
            })
            formData.append('shopId', cookie.load('shopList'))
            formData.append('roleId', cookie.load('role'))
            formData.append('principal_name', cookie.load('name'))
            formData.append('principal_uuid', cookie.load('id'))
            formData.append('principal_email', Base64.decode(cookie.load('email')))
            NbAxios(formData, "POST", servicePath.uploadKolCost).then(res => {
                setEffective(false)
                if(res.success){
                    setUploadCostFile([])
                    form.resetFields()
                    message.success('成本数据上传成功')
                    setUploadCostData(false)
                }else{
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 上传KOL投放数据
    const uploadKolLaunchData = ()=>{
        if(uploadLaunchFile.length == 0){
            message.warn('请选择您要上传的文件')
        }else{
            setEffective(true)
            // 上传原始文件例子
            const formData = new FormData()
            // 多个图片
            uploadLaunchFile.forEach((item) => {
                formData.append('file ', item['originFileObj']);
            })
            formData.append('shopId', cookie.load('shopList'))
            formData.append('roleId', cookie.load('role'))
            formData.append('principal_name', cookie.load('name'))
            formData.append('principal_uuid', cookie.load('id'))
            formData.append('principal_email', Base64.decode(cookie.load('email')))
            NbAxios(formData, "POST", servicePath.uploadKolLaunch).then(res => {
                setEffective(false)
                if(res.success){
                    setUploadLaunchFile([])
                    form.resetFields()
                    message.success('成本数据上传成功')
                    setUploadLaunchData(false)
                }else{
                    message.warn(res.errorMsg)
                }
            })
        }
    }
    return (
        <div style={{ marginLeft: 100, paddingBottom: 100 }}>
            <Row style={{marginTop: 20}}>
                <div>
                    <Button type="primary" onClick={() => setUploadData(true)}>导入KOL</Button>
                </div>
                <div>
                    <Button type="primary" style={{marginLeft: 20}}
                            onClick={() => setUploadLaunchData(true)}>导入投放数据</Button>
                </div>
                <div>
                    <Button type="primary" onClick={() => setUploadCostData(true)}
                            style={{marginLeft: 20}}>导入报价</Button>
                </div>
                <div>
                    <a target="_blank" href="https://pic.bmcsoft.cn/imge_dir/一枕星河kol后台内部资源上传模版.xlsx"><Button type="link">下载导入KOL模版</Button></a>
                </div>
                <div>
                    <a target="_blank" href="https://pic.bmcsoft.cn/imge_dir/KOL投放数据上传模版.xlsx"><Button type="link">下载KOL投放数据上传模版</Button></a>
                </div>
                <div>
                    <a target="_blank" href="https://pic.bmcsoft.cn/imge_dir/KOL报价表上传模版.xlsx"><Button type="link">下载KOL报价表上传模版</Button></a>
                </div>
            </Row>
            <Spin spinning={isloading} size="large" tip="数据加载中....."
                  style={{display: 'flex', alignItems: 'center', marginTop: '30vh'}}>
                <div style={{marginTop: 20}}>
                    <Collapse accordion>
                        <Panel header="查询条件" key="1">
                            <Form
                                {...layout}
                                labelAlign="left"
                                style={{width: 1000}}
                            >
                                <Form.Item label="昵称">
                                    <Popover
                                        placement="right"
                                        content={nameContent}
                                        trigger="click"
                                        visible={closeNameBubble}
                                        onVisibleChange={visible => setCloseNameBubble(visible)}
                                    >
                                        <Button size="small">自定义{searchNameTag == 1 ? <span>: {searchName}</span> :
                                            <span></span>}</Button>
                                    </Popover>
                                </Form.Item>
                                <Form.Item label="阅读成本">
                                    <Popover
                                        placement="right"
                                        content={readContent}
                                        trigger="click"
                                        visible={closeReadBubble}
                                        onVisibleChange={visible => setCloseReadBubble(visible)}
                                    >
                                        <Button size="small">自定义{searchReadTag == 1 ?
                                            <span>: {searchMinRead} - {searchMaxRead}</span> : <span></span>}</Button>
                                    </Popover>
                                </Form.Item>
                                <Form.Item label="互动成本">
                                    <Popover
                                        placement="right"
                                        content={interactiveContent}
                                        trigger="click"
                                        visible={closeInteractiveBubble}
                                        onVisibleChange={visible => setCloseInteractiveBubble(visible)}
                                    >
                                        <Button size="small">自定义{searchInteractiveTag == 1 ?
                                            <span>: {searchMinInteractive} - {searchMaxInteractive}</span> :
                                            <span></span>}</Button>
                                    </Popover>
                                </Form.Item>
                                <Form.Item label="平台">
                                    {platformTagsData.map(tag => (
                                        <CheckableTag
                                            key={tag}
                                            // style={{fontSize:'15px'}}
                                            checked={platformSelectedTags.indexOf(tag) > -1}
                                            onChange={checked => platformHandleChange(tag, checked)}
                                        >
                                            {tag}
                                        </CheckableTag>
                                    ))}
                                </Form.Item>
                                <Form.Item label="笔记标签">
                                    {noteLabelTagsData.map(tag => (
                                        <CheckableTag
                                            key={tag}
                                            // style={{fontSize:'15px'}}
                                            checked={noteLabelSelectedTags.indexOf(tag) > -1}
                                            onChange={checked => noteLabelHandleChange(tag, checked)}
                                        >
                                            {tag}
                                        </CheckableTag>
                                    ))}
                                </Form.Item>
                                <Form.Item label="人设标签">
                                    {humanLabelTagsData.map(tag => (
                                        <CheckableTag
                                            key={tag}
                                            // style={{fontSize:'15px'}}
                                            checked={humanLabelSelectedTags.indexOf(tag) > -1}
                                            onChange={checked => humanLabelHandleChange(tag, checked)}
                                        >
                                            {tag}
                                        </CheckableTag>
                                    ))}
                                    <Popover
                                        placement="right"
                                        content={humanLabelContent}
                                        trigger="click"
                                        visible={closeHumanLabelBubble}
                                        onVisibleChange={visible => setHumanLabelCloseBubble(visible)}
                                    >
                                        <Button size="small">自定义{humanLabelTag == 1 ?
                                            <span>: {searchHumanLabel}</span> :
                                            <span></span>}</Button>
                                    </Popover>
                                </Form.Item>
                                <Form.Item label="粉丝数">
                                    <Row>
                                        {fansLabelTagsData.map(tag => (
                                            <CheckableTag
                                                key={tag}
                                                // style={{fontSize:'15px'}}
                                                checked={fansLabelSelectedTags.indexOf(tag) > -1}
                                                onChange={checked => fansLabelHandleChange(tag, checked)}
                                            >
                                                {tag}
                                            </CheckableTag>
                                        ))}
                                        <Popover
                                            placement="right"
                                            content={content}
                                            trigger="click"
                                            visible={closeBubble}
                                            onVisibleChange={visible => setCloseBubble(visible)}
                                        >
                                            <Button size="small">自定义{searchTag == 1 ?
                                                <span>: {searchMinFans} - {searchMaxFans}</span> :
                                                <span></span>}</Button>
                                        </Popover>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </Panel>
                    </Collapse>
                </div>
                <div style={{marginTop: 20, marginRight: 20}}>
                    <Table
                        dataSource={data}
                        columns={columns}
                        scroll={{x: 1400}}
                        pagination={paginationProps}
                    />
                </div>
            </Spin>
            <Modal
                visible={openChart}
                footer={[]}
                centered={true}
                onCancel={() => {
                    setPlatformTime([])
                    setPlatformSeries([])
                    setOpenChart(false)
                    form1.resetFields()
                }}
                width='55vw'
            >
                <Form
                    form={form1}
                >
                    <Form.Item name="echart">
                        <div id="report" style={{ width: '45vw', height: '70vh', marginTop: 100 }}></div>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="上传KOL数据"
                visible={uploadData}
                centered={true}
                okText="确定"
                cancelText="取消"
                onOk={() =>uploadKolData()}
                onCancel={() => {
                    setUploadFile([])
                    form.resetFields()
                    message.success('取消上传数据')
                    setUploadData(false)
                }}
                okButtonProps = {{disabled : effective == 1 ? true : false}}
                cancelButtonProps = {{disabled : effective == 1 ? true : false}}
            >
                <Dragger
                    accept=".xlsx"
                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={uploadFile}
                    onChange={fileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
            </Modal>
            <Modal
                title="上传KOL成本数据"
                visible={uploadCostData}
                centered={true}
                okText="确定"
                cancelText="取消"
                onOk={() =>uploadKolCostData()}
                onCancel={() => {
                    setUploadCostFile([])
                    form.resetFields()
                    message.success('取消上传成本数据')
                    setUploadCostData(false)
                }}
                okButtonProps = {{disabled : effective == 1 ? true : false}}
                cancelButtonProps = {{disabled : effective == 1 ? true : false}}
            >
                <Dragger
                    accept=".xlsx"
                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={uploadCostFile}
                    onChange={fileCostHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
            </Modal>
            <Modal
                title="上传KOL投放数据"
                visible={uploadLaunchData}
                centered={true}
                okText="确定"
                cancelText="取消"
                onOk={() =>uploadKolLaunchData()}
                onCancel={() => {
                    setUploadLaunchFile([])
                    form.resetFields()
                    message.success('取消上传投放数据')
                    setUploadLaunchData(false)
                }}
                okButtonProps = {{disabled : effective == 1 ? true : false}}
                cancelButtonProps = {{disabled : effective == 1 ? true : false}}
            >
                <Dragger
                    accept=".xlsx"
                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={uploadLaunchFile}
                    onChange={fileLaunchHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
            </Modal>
            <Modal
                title="修改KOL数据"
                visible={updateData}
                centered={true}
                onCancel={() => {
                    setUpdateData(false)
                }}
                footer={null}
                width={1000}
            >
                <Form
                    {...layout1}
                    form={form}
                    labelAlign="left"
                    onFinish={updateDataOk}
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="平台"
                                name="platform"
                            >
                                <Text>{form.getFieldValue('platform')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="昵称"
                                name="nickname"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="所属"
                                name="belonging"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="联系方式"
                                name="contact"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item
                                label="粉丝数"
                                name="fans"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="总赞藏"
                                name="favorites"
                            >
                                <Input disabled={form.getFieldValue('platform') == '小红书' ? false:true} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="总点赞数"
                                name="like"
                            >
                                <Input disabled={form.getFieldValue('platform') == '抖音' ? false:true} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="总播放量"
                                name="play"
                            >
                                <Input disabled={form.getFieldValue('platform') == 'B站' ? false:true} />
                            </Form.Item>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item
                                label="形式"
                                name="form"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="人设标签"
                                name="human_label"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="笔记标签"
                                name="note_label"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="可否直播"
                                name="is_live"
                            >
                                <Radio.Group value={form.getFieldValue('is_live')}>
                                    <Radio value={1}>可直播</Radio>
                                    <Radio value={0}>不可直播</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="备注"
                                name="remarks"
                            >
                                <TextArea rows={4}/>
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item
                                label="链接"
                                name="url"
                            >
                                <Text>{form.getFieldValue('url')}</Text>
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Form.Item>
                        <div style={{display:'flex'}}>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 700 }}>
                                确定
                            </Button>
                            <Button style={{marginLeft:20}} type="primary" danger onClick={()=>{
                                setUpdateData(false)
                            }}>取消</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="KOL联系方式"
                visible={openPhone}
                centered={true}
                onCancel={() => {
                    setPhone('')
                    setOpenPhone(false)
                }}
                footer={null}
            >
                <span>
                    <Paragraph copyable>{phone}</Paragraph>
                </span>
            </Modal>

        </div>
    )

}


export default Index