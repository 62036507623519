import React, { useEffect, useState } from 'react';
import { Input, Typography, Checkbox, message, Modal, Button, Spin, Table, Select, Progress, Tooltip,Tag } from 'antd'
import { DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl';
import NbAxios from '../../config/utils/nbAxios';
import axios from 'axios'
import webUrl from '../../config/webUrl';
import AcceptBuried from '../../config/utils/buried';
import style from './index.module.css'
import downExcel from "../../config/utils/downloadFile"
const { Title, Text,Paragraph } = Typography;
const { Option } = Select
function Gift(props) {
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    // 表头列表
    const [columnList] = useState(["赠品条形码","赠品数量","编辑"])
    // 表头列表
    const [columnOrderList] = useState(["销售订单编号","销售实付金额","编辑"])
    // 导出权限校验
    const [jurisdiction] = useState(["于雪","沈费","唐铎","叶康辉","周浩琪","姚凯鹏","王修文","陈黎黎","曹守梅"])
    // 设置表头
    const [column, setColumn] = useState([])
    // 设置表头
    const [columnOrder, setColumnOrder] = useState([])
    // 表格明细内容
    const [tableData,setTableData] = useState([])
    // 表格明细内容
    const [giftTableData,setGiftTableData] = useState([])
    // 订单条形码
    const [orderBarCode, setOrderBarCode] = useState('')
    // 订单金额
    const [orderMoumnt, setOrderMoumnt] = useState('')
    // 是否导出所有明细数据
    const [isExportAllModel, setIsExportAllModel] = useState(false)
    // 是否在导出中
    const [exportFlag, setExportFlag] = useState(0)
    // 赠品条形码
    const [giftBarCode, setGiftBarCode] = useState('')
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 是否需要更改数量
    const [updateFlag,setUpdateFlag] = useState(true)
    // 扫描的是订单条形码还是赠品条形码的判断
    const [flag,setFlag] = useState(0)
    useEffect(()=>{
        setColumn(columnList.map((item,index)=>{
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                // width:item == '款图'? "8%":"5%",
                render: (text, record, index) => {
                    return (
                        item == '赠品数量' && text?
                        <Paragraph style={{ margin: 0 }} onClick={()=>{
                            setUpdateFlag(false)
                        }} editable={{
                            onChange: (value) => {
                                let reg = new RegExp("^[0-9.]*$");
                                if (reg.test(value)&&value) {
                                    let obj = [...giftTableData]
                                    obj[index][item] = parseInt(value)
                                    setGiftTableData(obj)
                                    // 恢复输入框自动获取焦点
                                    setUpdateFlag(true)
                                } else {
                                    message.error("只能输入数字")
                                }
                            }
                        }}>{text}</Paragraph>:item == '编辑' && record["赠品条形码"]? <a onClick={() => {
                            let obj = [...giftTableData]
                            obj.remove(record)
                            setGiftTableData(obj)
                            // 如果订单没有赠品，则需要重新扫描订单号
                            if(obj.length == 0){
                                setOrderBarCode('')
                                setFlag(0)
                            }
                        }}>删除</a>:<div>{text}</div>
                    )
                }
            }
        }))
        
    },[giftTableData])
    useEffect(()=>{
        setColumnOrder(columnOrderList.map((item,index)=>{
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                // width:item == '款图'? "8%":"5%",
                render: (text, record, index) => {
                    return (
                        item == '编辑' && record["销售订单编号"]!=="合计"? <a onClick={() => {
                            let obj = [...tableData]
                            obj.remove(record)
                            // 如果最后一列是总计，则删除
                            if (obj[obj.length - 1]) {
                                if (obj[obj.length - 1]["销售订单编号"] == "合计") {
                                    obj.pop()
                                }
                            }
                            // 如果订单没有赠品，则需要重新扫描订单号
                            if(obj.length !== 0){
                                totalAdd(obj,setTableData)
                                setOrderBarCode('')
                                setFlag(0)
                            }else{
                                setTableData(obj)
                            }
                            
                        }}>删除</a>:item == '销售实付金额' ?<div>{text?text.toFixed(2):text}</div>:<div>{text}</div>
                    )
                }
            }
        }))
    },[tableData])
    // 总计计算和增加
    const totalAdd = (obj, setTableData) => {
        // 如果最后一列是总计，则删除
        if (obj[obj.length - 1]) {
            if (obj[obj.length - 1]["销售订单编号"] == "合计") {
                obj.pop()
            }
        }
        // 计算总计
        let sum = 0
        obj.map(item => {
            // if (item["吊牌价"]) {
            //     sumdP = sumdP + item["吊牌价"]
            // }
            if (item["销售实付金额"]) {
                sum = sum + item["销售实付金额"]
            } 
            
        })
        obj.push({
            "销售订单编号": "合计",
            // "吊牌价": sumdP,
            "销售实付金额": sum,
        })
        setTableData(obj)
    }
    // 查询订单接口
    const queryOrderInfo=(order_code)=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            order_code:order_code,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.queryOrderInfo).then(res => {
            setLoading(false)
            // setOrderBarCode('')
            if (res.success) {
                message.success("查询订单成功")
                let obj = [...tableData]
                
                // 如果最后一列是总计，则删除
                if (obj[obj.length - 1]) {
                    if (obj[obj.length - 1]["销售订单编号"] == "合计") {
                        obj.pop()
                    }
                }
                obj.push({
                    "销售订单编号":res.data["销售订单编号"],
                    "销售实付金额":res.data["销售实付金额"]
                })
                // setTableData(obj)
                totalAdd(obj,setTableData)
                setOrderMoumnt(res.data["销售实付金额"])
                setOrderBarCode('')
                setFlag(0)
            } else {
                setOrderBarCode('')
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setOrderBarCode('')
            setLoading(false)
            // 获取500错误
            message.warn('查询条形码接口报错，请联系IT帮忙解决')
        })
    }
    // 赠品提交
    const confirmGiftInfo=()=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            name:cookie.load('name'),
            // order_code:orderBarCode,
            // pay_money:orderMoumnt,
            order_data:tableData,
            gift_data:giftTableData,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.confirmGiftInfo).then(res => {
            setLoading(false)
            // setOrderBarCode('')
            if (res.success) {
                message.success("上传成功")
                setTableData([])
                setGiftTableData([])
                setOrderBarCode('')
                setGiftBarCode('')
                setFlag(0)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            // setOrderBarCode('')
            setLoading(false)
            // 获取500错误
            message.warn('赠品提交报错，请联系IT帮忙解决')
        })
    }
    // 导出赠品明细
    const downloadStandardizedModel = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            name: cookie.load('name'),
        }
        setLoading(true)
        axios({
            data: dataProps,
            method: 'POST',
            url: servicePath.downloadGiftDetail,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setLoading(false)
                downExcel(res)
                setExportFlag(2)
            }
        ).catch(e => {
            setLoading(false)
            setExportFlag(3)
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }
    return (
        <div className={style.initial}>
            <Spin spinning={loading} tip="数据加载中....">
            <div style={{marginTop:'20px',alignItems:'center',fontSize:'30px',cursor: 'pointer', marginLeft: '50px'}} onClick={()=>props.history.push({'pathname':`/app/pos/initial`})}>
                <ArrowLeftOutlined/>
                返回
            </div>
                <div className={style.externalContent}>
                
                    <div className={style.mak} style={{fontSize:'40px'}}>赠品单</div>
                    <div style={{display:'flex',alignItems:'center' ,justifyContent:'space-between'}}>
                        <div>
                            <span style={{marginRight:'10px'}}>订单条形码:</span>
                            <Input value={orderBarCode} onChange={(e)=>{setOrderBarCode(e.target.value)}} 
                                // 自动获取焦点
                                ref={function (input) {
                                    if (input != null && updateFlag) {
                                        input.focus();
                                    }
                                }}
                            onPressEnter={(e)=>{
                                if(e.target.value.length !==20){
                                    message.warning("订单编码输入有误,请再次尝试并且切换英文输入法")
                                    setOrderBarCode('')
                                }else{
                                    let obj = [...tableData]
                                    let repeat = false
                                    obj.map(item=>{
                                        if(item["销售订单编号"] == e.target.value){
                                            repeat = true
                                        }
                                    })
                                    if(repeat){
                                        message.warning("订单已存在")
                                        setOrderBarCode('')
                                    }else{
                                        queryOrderInfo(e.target.value)
                                    }
                                }
                            }}
                            style={{width:'200px'}}
                            onClick={()=>{
                                setFlag(0)
                            }}
                            />
                        </div>
                        <div>
                        <span style={{marginRight:'10px'}}>赠品条形码:</span>
                            <Input value={giftBarCode} onChange={(e)=>{setGiftBarCode(e.target.value)}} 
                                // 自动获取焦点
                                ref={function (input) {
                                    if (input != null && flag !==0 && updateFlag) {
                                        input.focus();
                                    }
                                }}
                            onPressEnter={(e)=>{
                                // goodsInfoByCode(e.target.value)
                                // setFlag(0)
                                let flag = 1
                                let obj = [...tableData]
                                let err = false
                                obj.map(item=>{
                                    if(e.target.value == item["销售订单编号"]){
                                        err = true
                                    }
                                })
                                if(err){
                                    message.error("请输入正确的赠品条形码")
                                    return false
                                }
                                // obj.map(item=>{
                                //     // 若存在订单条形码且赠品条形码存在，则判断是否有重复的赠品
                                //     if(item["赠品条形码"]){
                                //         // 赠品已存在，则数量加一
                                //         if(item["赠品条形码"] == e.target.value){
                                //             item["赠品数量"] = item["赠品数量"] + 1
                                //             flag =  0                                    // 如果存在赠品条形码，则不许新增行
                                //             return false
                                //         }else{
                                //             // 赠品不存在，则没事
                                //             return false
                                //         }
                                //     }else{
                                //         // 若存在订单条形码且赠品条形码不存在，则添加
                                //         item["赠品条形码"] = e.target.value
                                //         item["赠品数量"] = 1
                                //         flag =  0
                                //     }
                                // })
                                // // 判断赠品是否需要新增
                                // if(flag == 1){
                                //     obj.push({
                                //         "销售订单编号": orderBarCode,
                                //         "销售实付金额":orderMoumnt,
                                //         "赠品条形码":e.target.value,
                                //         "赠品数量" : 1
                                //     })
                                // }
                                // setTableData(obj)
                                let data = [...giftTableData]
                                data.map(item=>{
                                    if(item["赠品条形码"]){
                                        // 赠品已存在，则数量加一
                                        if(item["赠品条形码"] == e.target.value){
                                            item["赠品数量"] = item["赠品数量"] + 1
                                            flag =  0                                    // 如果存在赠品条形码，则不许新增行
                                            return false
                                        }else{
                                            // 赠品不存在，则没事
                                            return false
                                        }
                                    }
                                })
                                if(flag == 1){
                                    data.push({
                                        "赠品条形码":e.target.value,
                                        "赠品数量" : 1
                                    })
                                }
                                setGiftTableData(data)
                                setGiftBarCode('')
                            }}
                            style={{width:'200px'}}
                            onClick={()=>{
                                setFlag(1)
                            }}
                            />
                        </div>
                    </div>
                    <div style={{marginTop:'20px'}}>
                        <h2>订单表</h2>
                        <Table columns={columnOrder} dataSource={tableData}  size="small" pagination={false}/>
                    </div>
                    <div style={{marginTop:'20px'}}>
                        <h2>赠品表</h2>
                        <Table columns={column} dataSource={giftTableData}  size="small" pagination={false}/>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div style={{marginTop:'20px'}}>
                            <Button onClick={() => {
                                if(jurisdiction.indexOf(cookie.load("name")) == -1){
                                    message.error("没有权限导出")
                                }else{
                                    setIsExportAllModel(true)
                                    setExportFlag(0)
                                }
                                }}
                                type='primary'
                                >
                                <DownloadOutlined /> 导出赠品明细
                            </Button>
                            {/* <Button type='primary' >导出销售订单明细</Button> */}
                        </div>
                        <div className={style.endButton}>
                            <Button type='primary' onClick={() => {
                                if (giftTableData.length !== 0 && tableData.length !== 0) {
                                    confirmGiftInfo()
                                } else {
                                    message.warning("还未录入赠品或订单")
                                }
                            }}>上传赠品订单</Button>
                        </div>
                    </div>
                </div>
            </Spin>
            <Modal
                    title="导出赠品明细"
                    visible={isExportAllModel}
                    centered={true}
                    onCancel={() => {
                        if(exportFlag == 1){
                            message.warning("导出中,请勿操作")
                        }else{
                            setIsExportAllModel(false)
                        }
                    }}
                    onOk={() => {
                        if(cookie.load("name"))
                        if(exportFlag == 1){
                            message.warning("导出中,请勿操作")
                        }else if (exportFlag == 2) {
                            setIsExportAllModel(false)
                        }else {
                            const getAllData = async () => {
                                await Promise.all([
                                    downloadStandardizedModel(),
                                    setExportFlag(1)
                                ])
                            }
                            getAllData()
                        }
                    }}
                    okText="确认"
                    cancelText="取消"
                >
                    {/* <Spin style={{ display: tabKey == 'productList' ? '' : 'none' }} spinning={loading} tip="数据加载中...."> */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <ExclamationCircleOutlined style={{color:"#a3986d",width:'30px'}}/> */}
                            {exportFlag == 0 ? <Progress type="circle" percent={0} /> : exportFlag == 1 ? <Spin size="large" /> : exportFlag == 2 ? <Progress type="circle" percent={100} /> : <Progress type="circle" percent={70} status="exception" />}
                        </div>
                        <div style={{ marginTop: "30px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {exportFlag == 0 ? <b>确认导出所有导出赠品明细吗？</b> : exportFlag == 1 ? <b>导出中</b> : exportFlag == 2 ? <b>导出完成</b> : <b>导出失败</b>}
                        </div>
                    {/* </Spin> */}
            </Modal>
            
        </div>
    )
}

export default Gift