import React, {useEffect, useState} from 'react';
import {
    Table,
    Cascader,
    InputNumber,
    Select,
    BackTop,
    Form,
    Radio,
    Checkbox,
    Tabs,
    DatePicker,
    Typography,
    Tooltip,
    Button,
    message,
    Spin
} from 'antd';
import {CloudDownloadOutlined, SearchOutlined, RightOutlined, SyncOutlined, LeftOutlined} from '@ant-design/icons';
import style from './style.module.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import NbAxios from '../../config/utils/nbAxios';
import cookie from 'react-cookies';
import GoodsChart from './reportModel';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import Axios from 'axios'
import servicePath from '../../config/apiUrl';
import downExcel from '../../config/utils/downloadFile'

const {Title, Paragraph, Text, Link} = Typography;
const {RangePicker} = DatePicker;
const {Option} = Select;
const {TabPane} = Tabs;

function Index(props) {
    const [form1] = Form.useForm();
    const layout = {
        labelCol: {span: 5},
        wrapperCol: {span: 19},
    };
    // 显示当前自定义条件的jsx
    const fileds = condition => <span
        className={style.condition}>{options.filter(item => item.value == condition)[0].label}</span>
    // 自定义条件 
    const options = [
        {label: '产品线', value: "product_line"},
        {label: '季节', value: 'season'},
        {label: '品类', value: 'category2'},
        {label: '年份', value: 'year'},
        {label: '价格带', value: 'price_zone'},
    ];
    // 条件及条件值
    const [conditionDict, setConditionDict] = useState({})
    // 条件列表
    const [conditions, setConditions] = useState([])
    // 自定义类目数据
    const [kind, setKind] = useState([])
    // 自定义产品线数据
    const [product, setProduct] = useState([])
    // 自定义季节数据
    const [season, setSeason] = useState([])
    // 自定义年份数据
    const [year, setYear] = useState([])
    // 价格带
    const [priceInterval, setPriceInterval] = useState(500)
    // 获取店铺名
    const shopName = props.location.pathname.split('/')[3]
    // 选择的周期类型
    const [dateType, setDateType] = useState('month')
    // 开始日期
    const [startDate, setStartDate] = useState(moment().startOf(dateType))
    // 结束日期  
    const [endDate, setEndDate] = useState(moment().endOf(dateType))
    // 同比类型
    const [compareType, setCompareType] = useState("year")
    // 表格状态
    const [tableLoading, setTableLoading] = useState(false)
    // 标签key
    const [tabKey, setTabKey] = useState('product_line')
    // 已经请求过的key
    const [tabKeyList, setTabKeyList] = useState([])
    // 产品线表格数据
    const [proData, setProData] = useState([])
    const [proX, setProX] = useState([])
    const [proBar, setProBar] = useState([])
    const [proLine, setProLine] = useState([])
    // 季节线表格数据
    const [seasonData, setSeasonData] = useState([])
    const [seasonX, setSeasonX] = useState([])
    const [seasonBar, setSeasonBar] = useState([])
    const [seasonLine, setSeasonLine] = useState([])
    // 品类线表格数据
    const [kindData, setKindData] = useState([])
    const [kindX, setKindX] = useState([])
    const [kindBar, setKindBar] = useState([])
    const [kindLine, setKindLine] = useState([])
    // 价格线表格数据
    const [priceData, setPriceData] = useState([])
    const [priceX, setPriceX] = useState([])
    const [priceBar, setPriceBar] = useState([])
    const [priceLine, setPriceLine] = useState([])
    // 自定义数据
    const [customData, setCustomData] = useState([])
    // 自定义表格数据加载状态
    const [customLoading, setCustomLoading] = useState(false)
    const colNameList = [
        {
            title: '加购款数',
            key: "sku_num",
            width: 120,
            sorter: true
        },
        {
            title: '款数占比',
            key: "sku_num_proportion",
            width: 120,
            sorter: true,
            showPercent: true
        },
        {
            title: '访客数',
            key: "views",
            width: 150,
            sorter: true,

        },
        {
            title: '访客占比',
            key: "views_proportion",
            width: 100,
            sorter: true,
            showPercent: true
        },
        {
            title: '加购件数',
            key: "purchase_num",
            width: 100,
            sorter: true
        },
        {
            title: '加购款数占比',
            key: "purchase_num_proportion",
            width: 110,
            sorter: true,
            showPercent: true
        },
        {
            title: '加购率',
            key: "additional_purchase_rate",
            width: 100,
            sorter: true,
            showPercent: true
        },
        {
            title: '加购转化率',
            key: "purchase_conversion_rate",
            width: 100,
            sorter: true,
            showPercent: true
        },
        {
            title: '加购吊牌金额',
            key: "additional_hangtag_amount",
            width: 100,
            sorter: true
        }
    ]
    const proColumns = [
        {
            title: '产品线',
            dataIndex: 'product_line',
            key: 'product_line',
            align: 'center',
            ellipsis: true,
            fixed: "left",
            onHeaderCell: onHeaderCell,
            width: 200
        },
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                align: 'center',
                sorter: (a, b) => {
                    let sort_a = parseFloat(a[item.key] || 0)
                    let sort_b = parseFloat(b[item.key] || 0)
                    return sort_a - sort_b
                },
                onHeaderCell: onHeaderCell,
                width: item.width,
                render: (cell, index, record) => {
                    if (cell !== null) {
                        if (item.showPercent) {
                            return `${cell}%`
                        }
                        return cell
                    } else {
                        return '-'
                    }
                }
            }
        }),
        {
            title: '查看',
            dataIndex: 'action',
            fixed: "right",
            key: 'action',
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100,
            render: (item, record, index) => {
                let {product_line} = record
                // return <Button type="link" onClick={() => getGoodsIdList()}>
                //     {product_line === "基础信息缺失" ? '导出' : '详情'}
                // </Button>
                return product_line === '基础信息缺失' ? <Button type="link" onClick={() => getGoodsIdList()}>
                    导出
                </Button>
                :<Button type="link"  onClick={()=>lookDetails({ "product_line": product_line })}>
                    详情
                </Button>
            }
        }
    ]
    const seasonColumns = [
        {
            title: '季节线',
            dataIndex: 'season',
            key: 'season',
            fixed: "left",
            align: 'center',
            ellipsis: true,
            width: 100,
            onHeaderCell: onHeaderCell
        },
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                align: 'center',
                width: item.width,
                sorter: (a, b) => {
                    let sort_a = parseFloat(a[item.key] || 0)
                    let sort_b = parseFloat(b[item.key] || 0)
                    return sort_a - sort_b
                },
                onHeaderCell: onHeaderCell,
                render: (cell, index, record) => {
                    if (cell !== null) {
                        if (item.showPercent) {
                            return `${cell}%`
                        }
                        return cell
                    } else {
                        return '-'
                    }
                }
            }
        }),
        {
            title: '查看',
            dataIndex: 'action',
            fixed: "right",
            key: 'action',
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100,
            render: (item, record, index) => {
                let {season} = record
                // return <Button type="link" onClick={() => getGoodsIdList()}>
                //     {season === "基础信息缺失" ? '导出' : '详情'}
                // </Button>
                return season === '基础信息缺失' ? <Button type="link" onClick={() => getGoodsIdList()}>
                    导出
                </Button>
                :<Button type="link"  onClick={()=>lookDetails({ "season": season })}>
                    详情
                </Button>
            }
        }
    ]
    const kindColumns = [
        {
            title: '品类线',
            dataIndex: 'category',
            key: 'category',
            fixed: "left",
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100
        },
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                align: 'center',
                onHeaderCell: onHeaderCell,
                sorter: (a, b) => {
                    let sort_a = parseFloat(a[item.key] || 0)
                    let sort_b = parseFloat(b[item.key] || 0)
                    return sort_a - sort_b
                },
                width: item.width,
                render: (cell, index, record) => {
                    if (cell !== null) {
                        if (item.showPercent) {
                            return `${cell}%`
                        }
                        return cell
                    } else {
                        return '-'
                    }
                }
            }
        }),
        {
            title: '查看',
            dataIndex: 'action',
            fixed: "right",
            key: 'action',
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100,
            render: (item, record, index) => {
                let {category2} = record
                // return <Button type="link" onClick={() => getGoodsIdList()}>
                //     {category2 === "基础信息缺失" ? '导出' : '详情'}
                // </Button>
                return category2 === '基础信息缺失' ? <Button type="link" onClick={() => getGoodsIdList()}>
                    导出
                </Button>
                :<Button type="link"  onClick={()=>lookDetails({ "category2": category2 })}>
                    详情
                </Button>
            }
        }
    ]
    const priceColumns = [
        {
            title: 'TOP款',
            dataIndex: 'top',
            fixed: "left",
            key: 'top',
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100
        },
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                align: 'center',
                sorter: (a, b) => {
                    let sort_a = parseFloat(a[item.key] || 0)
                    let sort_b = parseFloat(b[item.key] || 0)
                    return sort_a - sort_b
                },
                onHeaderCell: onHeaderCell,
                width: item.width,
                render: (cell, index, record) => {
                    if (cell !== null) {
                        if (item.showPercent) {
                            return `${cell}%`
                        }
                        return cell
                    } else {
                        return '-'
                    }
                }
            }
        }),
        {
            title: '查看',
            dataIndex: 'action',
            fixed: "right",
            key: 'action',
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100,
            render: (item, record, index) => {
                let {top} = record
                return top === '基础信息缺失' ? <Button type="link" onClick={() => getGoodsIdList()}>
                    导出
                </Button>
                :<Button type="link"  onClick={()=>{
                    let rank = 0
                    if(top == 10){
                        rank=1
                    }else if(top == 30){
                        rank=2
                    }else if(top == 50){
                        rank=3
                    }else if(top == 100){
                        rank=4
                    }else {
                        rank=5
                    }
                    let data = {}
                    data.roleId = cookie.load('role')
                    data.shop_name = shopName
                    data.start_time = startDate.format('YYYY-MM-DD')
                    data.end_time  = endDate.format('YYYY-MM-DD')
                    data.shopList = cookie.load('shopList')
                    data.rank = rank
                    let key = "getCommodityTopGoodsIdList"
                    message.loading({ content: '正在获取数据...', key, duration: 100 })
                    NbAxios(data, "POST", servicePath.getCommodityTopGoodsIdList).then((res, rej) => {
                        if(res.success){
                            if(shopName == 'JORYA'){
                                props.history.push({'pathname':`/app/SaleBorder/jr`,'state':{'idList':res.data, "start_date":startDate.format('YYYY-MM-DD'),"end_date":endDate.format('YYYY-MM-DD')}})
                            }else{
                                props.history.push({'pathname':`/app/SaleBorder/${shopName}`,'state':{'idList':res.data, "start_date":startDate.format('YYYY-MM-DD'),"end_date":endDate.format('YYYY-MM-DD')}})
                            }
                            message.success({ content: "成功获取数据!", key })
                        }
                        else{
                            message.error({content:res.errorMsg, key})
                        }
                    })
                }}>
                    详情
                </Button>
            }
        }
    ]
    const customColumns = [
        ...conditions.map((item, index, arr) => {
            return {
                title: options.filter(option => option.value == item)[0].label,
                dataIndex: item,
                key: item,
                align: 'center',
                ellipsis: true,
                onHeaderCell: onHeaderCell,
                width: 100
            }
        })
        ,
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                align: 'center',
                sorter: (a, b) => {
                    if (item.key == "sku_contribution") {
                        return (a.pay_money / a.pay_skus).toFixed(0) - (b.pay_money / b.pay_skus).toFixed(0)
                    } else {
                        return a[item.key] - b[item.key]
                    }
                },
                // sortOrder: item.key === sortField && changeSortField(item.key),
                onHeaderCell: onHeaderCell,
                width: item.width,
                render: (content, record, index) => {
                    if (item.key == "sku_contribution") {
                        return (record.pay_money / record.pay_skus).toFixed(0)
                    } else {
                        if (content !== null) {
                            if (item.showPercent) {
                                return `${content}%`
                            }
                            return content
                        } else {
                            return '-'
                        }
                    }
                }
            }
        })
    ]

    useEffect(() => {
        setSeasonData([])
        setSeasonX([])
        setSeasonLine([])
        setSeasonBar([])
        setPriceX([])
        setPriceBar([])
        setPriceData([])
        setPriceLine([])
        setProData([])
        setProX([])
        setProBar([])
        setProLine([])
        setKindX([])
        setKindBar([])
        setKindLine([])
        setKindData([])
        setTabKeyList([])
        tabKeyList.push('product_line')
    }, [startDate, endDate, compareType, shopName])

    useEffect(() => {
        if (tabKeyList.indexOf(tabKey) == -1) {
            tabKeyList.push(tabKey)
            setTableLoading(true)
            if (tabKey == 'product_line') {
                //获取运营汇总数据产品线接口
                getOperationDataProductLine()
            } else if (tabKey == 'season') {
                //获取运营汇总数据季节接口
                getOperationDataSeason()
            } else if (tabKey == 'category') {
                //获取运营汇总数据品类接口
                getOperationDataCategory()
            } else {
                //获取运营汇总数据Top接口
                getOperationDataTop()
            }
        }
    }, [tabKey, tabKeyList, compareType])

    //获取运营汇总数据Top接口
    const getOperationDataTop = () => {
        let data = {}
        data.start_time = startDate.format('YYYY-MM-DD')
        data.end_time = endDate.format('YYYY-MM-DD')
        data.last_start_time = moment(startDate).subtract(1, compareType).format('YYYY-MM-DD')
        data.last_end_time = moment(endDate).subtract(1, compareType).format('YYYY-MM-DD')
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shop_name = shopName
        NbAxios(data, "POST", servicePath.queryOperationDataTop).then(res => {
            setTableLoading(false)
            if (res.success) {
                console.log(res.data['table']);
                setPriceBar(res.data['chart']['bar'])
                setPriceX(res.data['chart']['x_zhou'])
                setPriceLine(res.data['chart']['line'])
                setPriceData(res.data['table']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取运营汇总数据品类接口
    const getOperationDataCategory = () => {
        let data = {}
        data.start_time = startDate.format('YYYY-MM-DD')
        data.end_time = endDate.format('YYYY-MM-DD')
        data.last_start_time = moment(startDate).subtract(1, compareType).format('YYYY-MM-DD')
        data.last_end_time = moment(endDate).subtract(1, compareType).format('YYYY-MM-DD')
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shop_name = shopName
        NbAxios(data, "POST", servicePath.queryOperationDataCategory).then(res => {
            setTableLoading(false)
            if (res.success) {
                setKindBar(res.data['chart']['bar'])
                setKindX(res.data['chart']['x_zhou'])
                setKindLine(res.data['chart']['line'])
                setKindData(res.data['table']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取运营汇总数据季节接口
    const getOperationDataSeason = () => {
        let data = {}
        data.start_time = startDate.format('YYYY-MM-DD')
        data.end_time = endDate.format('YYYY-MM-DD')
        data.last_start_time = moment(startDate).subtract(1, compareType).format('YYYY-MM-DD')
        data.last_end_time = moment(endDate).subtract(1, compareType).format('YYYY-MM-DD')
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shop_name = shopName
        NbAxios(data, "POST", servicePath.queryOperationDataSeason).then(res => {
            setTableLoading(false)
            if (res.success) {
                setSeasonBar(res.data['chart']['bar'])
                setSeasonX(res.data['chart']['x_zhou'])
                setSeasonLine(res.data['chart']['line'])
                setSeasonData(res.data['table']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取运营汇总数据产品线接口
    const getOperationDataProductLine = () => {
        let data = {}
        data.start_time = startDate.format('YYYY-MM-DD')
        data.end_time = endDate.format('YYYY-MM-DD')
        data.last_start_time = moment(startDate).subtract(1, compareType).format('YYYY-MM-DD')
        data.last_end_time = moment(endDate).subtract(1, compareType).format('YYYY-MM-DD')
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shop_name = shopName
        NbAxios(data, "POST", servicePath.queryOperationDataProductLine).then(res => {
            setTableLoading(false)
            if (res.success) {
                setProBar(res.data['chart']['bar'])
                setProX(res.data['chart']['x_zhou'])
                setProLine(res.data['chart']['line'])
                setProData(res.data['table']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 日期变化 
    function dateChange(dates, dateStrings) {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        } else {
            message.warn('请选择正确的时间')
        }
    }

    // 选择同比时间端
    function compareChange(e) {
        let choose = e.target.value
        switch (choose) {
            // 同比去年
            case 1:
                setCompareType("year")
                break
            // 同比上期
            case 2:
                setCompareType(dateType)
                break
        }
    }

    useEffect(() => {
        // 第一次加载获取可选条件
        getCustomConditions()
    }, [])
    return (
        <div className={style.goodsIndexWapper}>
            <div className={style.DataArea}>
                <div className={style.controlBar}>
                    <div className={style.controlBarLeft}>
                        <Title level={2} style={{margin: 0}}>{shopName}运营数据统计看板</Title>
                    </div>
                    <div className={style.controlBarRight}>
                        <RangePicker
                            ranges={{
                                '今天': [moment(), moment()],
                                '本周': [moment().startOf('week'), moment().endOf('week')],
                                '本月': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            locale={locale}
                            style={{height: 28, width: 240}}
                            value={[startDate, endDate]}
                            format="YYYY-MM-DD"
                            onChange={dateChange}
                        />
                        <Tooltip placement="bottomLeft" title="选择今日">
                            <Button
                                style={{
                                    padding: "0 5px",
                                    width: 28,
                                    height: 26,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "rgba(0,0,0,0.5)"
                                }}
                                onClick={() => dateTypeChange('day')}
                            >
                                <Text>日</Text>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="选择本周">
                            <Button
                                style={{
                                    padding: "0 5px",
                                    width: 28,
                                    height: 26,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "rgba(0,0,0,0.5)"
                                }}
                                onClick={() => dateTypeChange('week')}
                            >
                                <Text>周</Text>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="选择本月">
                            <Button
                                style={{
                                    padding: "0 5px",
                                    width: 28,
                                    height: 26,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "rgba(0,0,0,0.5)"
                                }}
                                onClick={() => dateTypeChange('month')}
                            >
                                <Text>月</Text>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="周期减1">
                            <Button
                                style={{
                                    padding: "0 5px",
                                    width: 28,
                                    height: 26,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "rgba(0,0,0,0.5)"
                                }}
                                onClick={() => dateCycleChange('sub')}
                            >
                                <LeftOutlined style={{lineHeight: "10px", fontSize: 12}}/>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="周期加1">
                            <Button
                                onClick={() => dateCycleChange('add')}
                                style={{
                                    padding: "0 5px",
                                    width: 28,
                                    height: 26,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "rgba(0,0,0,0.5)"
                                }}>
                                <RightOutlined style={{lineHeight: "10px", fontSize: 12}}/>
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <div className={style.myTable}>
                    <div className={style.myTableHeader}>
                        <Title level={4} style={{fontsize: 15}}>
                            常用分析报表
                        </Title>
                        <p>
                            历史数据同比：
                            <span>
                                <Radio.Group onChange={compareChange} value={compareType === "year" ? 1 : 2}>
                                    <Radio value={1}>同去年</Radio>
                                    <Radio value={2}>同上期</Radio>
                                </Radio.Group>
                            </span>
                        </p>
                    </div>
                    <Spin spinning={tableLoading} tip="数据计算中...">
                        <Tabs activeKey={tabKey} onChange={(key) => setTabKey(key)}>
                            <TabPane tab="产品线" key={'product_line'}></TabPane>
                            <TabPane tab="季节线" key={'season'}></TabPane>
                            <TabPane tab="品类线" key={'category'}></TabPane>
                            <TabPane tab="TOP款" key={'price'}></TabPane>
                        </Tabs>
                        <div className={style.indicatorCardRow}>
                            {
                                tabKey == 'product_line' ?
                                    <div className={style.reportWapper}>
                                        <GoodsChart id={"proLine"} title={"产品线商品数据统计"} xZhou={proX} barData={proBar}
                                                    lineData={proLine}/>
                                        <Table
                                            // title={() => header}
                                            scroll={{x: '100%', y: 300}}
                                            columns={proColumns}
                                            dataSource={proData}
                                            rowClassName={style.tableRow}
                                            pagination={false}
                                        />
                                    </div>
                                    : tabKey == 'season' ?
                                    <div className={style.reportWapper}>
                                        <GoodsChart id={"season"} title={"季节线商品数据统计"} xZhou={seasonX}
                                                    barData={seasonBar} lineData={seasonLine}/>
                                        <Table
                                            // title={() => header}
                                            scroll={{x: '100%', y: 300}}
                                            columns={seasonColumns}
                                            dataSource={seasonData}
                                            rowClassName={style.tableRow}
                                            pagination={false}
                                        />
                                    </div>
                                    : tabKey == 'category' ?
                                        <div className={style.reportWapper}>
                                            <GoodsChart id={"kind"} title={"品类线商品数据统计"} xZhou={kindX} barData={kindBar}
                                                        lineData={kindLine}/>
                                            <Table
                                                // title={() => header}
                                                scroll={{x: '100%', y: 300}}
                                                columns={kindColumns}
                                                dataSource={kindData}
                                                rowClassName={style.tableRow}
                                                pagination={false}
                                                onRow={function (record, rowIndex) {
                                                    return (
                                                        {
                                                            style: {
                                                                display: record['pay_skus'] == 0 ? 'none' : ''
                                                            },
                                                        }
                                                    )
                                                }}
                                            />
                                        </div>
                                        : <div className={style.reportWapper}>
                                            <GoodsChart id={"price"} title={"TOP款商品数据统计"} xZhou={priceX}
                                                        barData={priceBar} lineData={priceLine}/>
                                            <Table
                                                // title={() => header}
                                                scroll={{x: '100%', y: 300}}
                                                columns={priceColumns}
                                                dataSource={priceData}
                                                rowClassName={style.tableRow}
                                                pagination={false}
                                            />
                                        </div>
                            }
                        </div>
                    </Spin>
                </div>
                <div className={style.myTable}>
                    <div className={style.customTableHeader}>
                        <Title level={4} style={{fontsize: 15, margin: 0}}>自定义属性分析</Title>
                        <Form {...layout} form={form1} labelAlign='left'>
                            <div className={style.customTableHeaderRow}>
                                <Form.Item label="类目" name="category">
                                    <Cascader
                                        style={{width: 250, height: 28}}
                                        size="small"
                                        placeholder="选择类目"
                                        onChange={(value) => conditionChange(value, 'category2')}
                                        options={kind}
                                        changeOnSelect
                                        showSearch
                                    />
                                </Form.Item>
                            </div>
                            <div className={style.customTableHeaderRow}>
                                <Form.Item label="季节" name="season">
                                    <Checkbox.Group options={season}
                                                    onChange={(value) => conditionChange(value, 'season')}/>
                                </Form.Item>
                            </div>
                            <div className={style.customTableHeaderRow}>
                                <Form.Item label="产品线" name="product_line">
                                    <Checkbox.Group options={product}
                                                    onChange={(value) => conditionChange(value, 'product_line')}/>
                                </Form.Item>
                            </div>
                            <div className={style.customTableHeaderRow}>
                                <Form.Item label="年份" name="year">
                                    <Checkbox.Group options={year}
                                                    onChange={(value) => conditionChange(value, 'year')}/>
                                </Form.Item>
                            </div>
                            <div className={style.customTableHeaderRow}>
                                <Form.Item label="价格带" name="priceInterval">
                                    <div>
                                        <InputNumber className={style.priceZone} style={{height: 28, width: 70}}
                                                     max={2000} min={10} value={priceInterval} step={100}
                                                     onChange={priceChange}/>
                                    </div>
                                </Form.Item>
                            </div>
                        </Form>
                        <div className={style.customButonRow}>
                            <Button type="primary" onClick={() => getCustomTableData(conditions)}>查询</Button>
                            <Button type="primary" danger onClick={clearConditions}>清空</Button>
                            <Text type="secondary" style={{fontSize: "14px", lineHeight: "32px"}}>
                                当前统计条件:
                                <span className={style.conditions}>
                                    {conditions.length !== 0 ?
                                        conditions.map(condition => {
                                            return fileds(condition)
                                        }) : " 总计数据"
                                    }
                                </span>
                            </Text>
                        </div>
                    </div>
                    <Spin spinning={customLoading} tip="自定义数据查询中...">
                        <Table
                            columns={customColumns}
                            dataSource={customData}
                            rowClassName={style.tableRow}
                            pagination={false}
                            // onChange={tableChange}
                        />
                    </Spin>
                </div>
            </div>

        </div>
    )

    // 设置日期框选择周期
    function dateTypeChange(type) {
        setCompareType(type)
        setDateType(type)
        switch (type) {
            case 'day':
                setStartDate(moment())
                setEndDate(moment())
                break
            case 'week':
                setStartDate(moment().startOf('week'))
                setEndDate(moment().endOf('week'))
                break
            case 'month':
                setStartDate(moment().startOf('month'))
                setEndDate(moment().endOf('month'))
                break
            default:
                break;
        }
    }

    // 条件字段变化
    function conditionChange(value, type) {
        if (value.length === 0) {
            let newDict = {...conditionDict}
            delete newDict[type]
            setConditionDict(newDict)
            let con = [...conditions]
            let index = con.indexOf(type)
            con.splice(index, 1)
            setConditions(con)
        } else {
            if (conditions.indexOf(type) === -1) {
                let nowConditions = [...conditions]
                nowConditions.push(type)
                setConditions(nowConditions)
            }
            let newDict = {...conditionDict}
            newDict[type] = value
            setConditionDict(newDict)
        }
    }


    // 周期加减函数
    function dateCycleChange(type) {
        if (type === 'add') {
            setStartDate(moment(startDate).add(1, dateType).startOf(dateType))
            setEndDate(moment(startDate).add(1, dateType).endOf(dateType))
        } else if (type === 'sub') {
            setStartDate(moment(startDate).subtract(1, dateType).startOf(dateType))
            setEndDate(moment(startDate).subtract(1, dateType).endOf(dateType))
        }
    }

    // 控制单元格表头样式，去掉padding文字居左
    function onHeaderCell(record, rowIndex) {
        return (
            {
                style: {
                    padding: '0',
                    background: '#ffffff',
                    borderTop: "1px solid #f6f6f6"
                }
            }
        )
    }
    // 获取商品id集合
    function lookDetails(conditions){
        let data = {}
        data.roleId = cookie.load('role')
        data.shop_name = shopName
        data.conditions_list = conditions
        data.start_time = startDate.format('YYYY-MM-DD')
        data.end_time  = endDate.format('YYYY-MM-DD')
        data.shopList = cookie.load('shopList')
        let key = "getCommodityGoodsIdList"
        message.loading({ content: '正在获取数据...', key, duration: 100 })
        NbAxios(data, "POST", servicePath.getOperationCommodityGoodsIdList).then((res, rej) => {
            if(res.success){
                if(shopName == 'JORYA'){
                    props.history.push({'pathname':`/app/SaleBorder/jr`,'state':{'idList':res.data, "start_date":startDate.format('YYYY-MM-DD'),"end_date":endDate.format('YYYY-MM-DD')}})
                }else{
                    props.history.push({'pathname':`/app/SaleBorder/${shopName}`,'state':{'idList':res.data, "start_date":startDate.format('YYYY-MM-DD'),"end_date":endDate.format('YYYY-MM-DD')}})
                }
                message.success({ content: "成功获取数据!", key })
            }
            else{
                message.error({content:res.errorMsg, key})
            }
        })
    }

    // 根据条件获取商品id列表
    function getGoodsIdList() {
        let data = {}
        data.start_time = startDate.format('YYYY-MM-DD')
        data.end_time = endDate.format('YYYY-MM-DD')
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.shop_name = shopName
        let key = "getIdList"
        message.loading({content: '正在为你导出...', key, duration: 100})
        return Axios({
            method: 'POST',
            url: servicePath.downloadUnmatchedGoodsId,
            data: data,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
        }).then(res => {
            downExcel(res)
            message.success({content: "导出成功！", key})
        }).catch(e => {
            message.error({content: '文件制作出错，请联系it同事！', key})
        })
    }

    // 价格带变化
    function priceChange(value) {
        setPriceInterval(value)
        // if (conditions.includes("price_zone")) {
        //     getCustomTableData(conditions, e.target.value)
        // }
    }

    // 清空所有字段
    function clearConditions() {
        form1.resetFields()
        setConditions([])
        getCustomTableData([])
    }

    // 获取自定义表格数据
    function getCustomTableData(conditions, price = priceInterval) {
        let data = {}
        data.start_time = startDate.format('YYYY-MM-DD')
        data.end_time = endDate.format('YYYY-MM-DD')
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.conditions_list = conditions
        data.conditions_dict = conditionDict
        data.shop_name = shopName
        setCustomLoading(true)
        return NbAxios(data, "POST", servicePath.queryOperationDataCustom).then((res, rej) => {
            if (res.success) {
                let data = res.data.table
                setCustomData(data)
            } else {
                message.warn(res.errorMsg)
            }
            setCustomLoading(false)
            // data = null
            // return true
        }).catch(e => {
            message.error("出现错误！")
            data = null
            setCustomLoading(false)
        })
    }

    // 获取根系可选条件
    function getCustomConditions() {
        let data = {}
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.shop_name = shopName == 'JORYA' ? 'jr' : shopName
        return NbAxios(data, "POST", servicePath.getCustomConditions).then((res, rej) => {
            if (res.success) {
                let data = res.data
                setKind(data.category)
                if (data.product_line[0]['label']) {
                    setProduct(data.product_line)
                }
                setSeason(data.season)
                setYear(data.year)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("出现错误！")
            setCustomLoading(false)
        })
    }

}

export default Index