import React, {createContext, useReducer} from 'react'

// 状态管理部分
export const StateContext = createContext({})
// 定义事件类型
export const COUNT_CHANGE = 'COUNT_CHANGE';
export const WEEK_COUNT_CHANGE = 'WEEK_COUNT_CHANGE';

// 定义逻辑处理部分
const reducer = (state, action) => {
    // 根据事件类型选择处理处理逻辑
    switch (action.type) {
        case 'COUNT_CHANGE':
            // 对原state进行一次深拷贝
            let countState = JSON.parse(JSON.stringify(state))
            // 获取现有未读数
            countState.count = action.data
            // 计算本周前未读数
            countState.lastCount = action.data-countState.weekCount
            return countState
        case 'WEEK_COUNT_CHANGE':
            // 对原state进行一次深拷贝
            let weekCountState = JSON.parse(JSON.stringify(state))
            // 获取本周未读数
            weekCountState.weekCount = action.weekCount
            // 计算本周前未读数
            weekCountState.lastCount = weekCountState.count-weekCountState.weekCount
            return weekCountState
        default:
            return state
    }
}

// 创建状态管理包裹UI
export const LetterShare = props => {
    // 定义所有状态初始值
    const initialState = { 'count': 0,'weekCount': 0,'lastCount':0 }
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <StateContext.Provider value={{ state, dispatch }}>
            {props.children}
        </StateContext.Provider>
    )
}

