import React, {createContext, useReducer} from 'react'

// 状态管理部分
export const GoodsContext = createContext({})
// 定义事件类型
export const ADD_GOODS_LIST = 'ADD_GOODS_LIST';
export const ADD_MENU_TAG = 'ADD_MENU_TAG';
export const ADD_SHOP_GOODS_DATA = 'ADD_SHOP_GOODS_DATA';
export const DELETE_SHOP_GOODS_DATA = 'DELETE_SHOP_GOODS_DATA';

// 定义逻辑处理部分
const reducer = (prestate, action) => {
    // 根据事件类型选择处理处理逻辑
    switch (action.type) {
        case 'ADD_GOODS_LIST':
            // 对原state进行一次深拷贝
            let nowState = JSON.parse(JSON.stringify(prestate))
            // 保存跳转的商品id
            let data = action.shop_data
            let shop = Object.keys(data)[0]
            nowState["goods_id_list"][shop] = data[shop]
            return nowState
        case 'ADD_SHOP_GOODS_DATA':
            // 对原state进行一次深拷贝
            let shopDataState = JSON.parse(JSON.stringify(prestate))
            // 保存跳转前的统计数据
            let shopTableData = action.shop_table_data
            shopDataState['shop_table_data'] = {...shopTableData,...shopDataState["shop_table_data"]}
            return shopDataState
        case 'DELETE_SHOP_GOODS_DATA':
            // 对原state进行一次深拷贝
            let shopDataRestState = JSON.parse(JSON.stringify(prestate))
            // 删除该店铺数据
            delete shopDataRestState['shop_table_data'][action.shopName]
            return shopDataRestState
        case 'ADD_MENU_TAG':
            // 对原state进行一次深拷贝
            let tagState = JSON.parse(JSON.stringify(prestate))
            return tagState
        default:
            return prestate
    }
}

// 创建状态管理包裹UI
export const ItemInfoShare = props => {
    // 定义所有状态初始值
    const initialState = { 'goods_id_list': {},'menu_tags':[],'shop_table_data':{} }
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <GoodsContext.Provider value={{ state, dispatch }}>
            {props.children}
        </GoodsContext.Provider>
    )
}

