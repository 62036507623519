import React, { useEffect } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

function Index({
    // 宽度
    chartWidth,
    // 长度
    chartHeight,
    // 积分发送
    sendPoints,
    // 积分消耗
    consumptionPoints,
    // 核销率
    rate,
    // 日期
    head
}){

     // 积分
     let integralChart = {
        tooltip: {
            trigger: 'axis',
            formatter: function (params, ticket, callback) {
                var showHtm="";
                for(var i=0;i<params.length;i++){
                    //x轴名称
                    var name = params[i].seriesName;
                    // 名称
                    var marker = params[i].marker;
                    //值
                    var value = params[i].value;
                    if(name == '核销率'){
                        showHtm+= marker +  name + '\xa0\xa0\xa0' +  value + '%<br>'
                    }else{
                        showHtm+= marker + name + '\xa0\xa0\xa0' +  value + '<br>'
                    }
                    
                }
                return showHtm;
            }
        },
        legend: {
            data: ['积分发送', '积分消耗', '核销率']
        },
        xAxis: [
            {
                type: 'category',
                data: head,
                axisPointer: {
                    type: 'shadow'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '积分发送/积分消耗',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: '核销率',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: '积分发送',
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return value;
                    }
                },
                data: sendPoints
            },
            {
                name: '积分消耗',
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return value;
                    }
                },
                data: consumptionPoints
            },
            {
                name: '核销率',
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: function (value) {
                        return value  + ' %';
                    }
                },
                data: rate
            }
        ]
    }

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('integral'));
        // 绘制图表
        reportChart.setOption(integralChart);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [chartWidth, chartHeight,head])

    return (
        <div>
            <div id='integral' style={{ width: `calc(${chartWidth})`, height: `calc(${chartHeight})`}} ></div>
        </div>
    )

}

export default Index