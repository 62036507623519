import React, { useState, useEffect, useContext } from 'react';
import { Badge, Typography, Popover, List, Tooltip, message } from 'antd'
import { Link } from "react-router-dom"
import { BellOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {COUNT_CHANGE, StateContext, WEEK_COUNT_CHANGE} from './store/stateShare'
import LetterCard from './letterCard'
import './style/letterTip.css'
import NbAxios from '../../config/utils/nbAxios'
import cookie from 'react-cookies'
import moment from "moment"
import servicePath from '../../config/apiUrl'

const { Paragraph, Title, Text } = Typography;
function LetterTip({ fontSize = "1rem", color = "#fff" }) {
    let urlPath = window.location.pathname
    let delayOpenTimer 
    let delayClosedTimer 
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)

    // 本周的第一天
    const weekDayOne = moment().startOf("week").format("YYYY-MM-DD HH:mm:ss")

    // 控制提示框是否弹出
    const [visible, setVisible] = useState(false)
    // 提示框中的消息列表
    const [letterList, setLetterList] = useState([])

    const Header = (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Title level={5} style={{ justifySelf: "start" }}>通知消息 <Tooltip placement="right" title="显示最新5条消息"><QuestionCircleOutlined /></Tooltip></Title>
            {/* <Text onClick={closedPopover} style={{ justifySelf: "end", color: "rgba(79, 157, 222, 1)", cursor: "pointer" }}><Link to="/app/letter/lookLetter">查看全部</Link></Text> */}
        </div>
    )

    const content = (
        <div onMouseEnter={clearDelayTimer} onMouseLeave={closedPopover} >
            <List
                style={{ width: '350px', height: "auto" }}
                header={Header}
                dataSource={letterList}
                renderItem={item => {
                    return (
                        <div>
                            {/* <List.Item>
                                <LetterCard
                                    id={item.id}
                                    name={item.creator_name}
                                    title={item.title}
                                    status={item.is_read}
                                    time={moment(item.realse_time,"YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm')}
                                    showContent={false}
                                    cardLink={"/app/letter/lookLetter"}
                                    visible={visible}
                                    // 向子组件传递关闭窗口函数
                                    closed={closedPopover}
                                    // 传递同步消息数量函数
                                    letterCount={getLetterCount}
                                />
                            </List.Item> */}
                        </div>
                    )
                }}
            />
        </div>
    )

    // useEffect(() => {
    //     let timer
    //     // 第一次请求未读消息数
    //     getLetterCount()
    //     // 设定定时器每5分钟请求一次未读消息
    //     timer = setInterval(() => {
    //         getLetterCount()
    //     }, 300000)
    //     return (() => {
    //         // 路径变化时清除当前定时器
    //         clearInterval(timer)
    //     })
    // }, [urlPath])

    function getLetterCount() {
        let dataProps = {
            'role_id': cookie.load('role'),
            "shop_id": cookie.load('shopList'),
            "user_id": cookie.load('id'),
            "week_first_day": weekDayOne,
        }
        NbAxios(dataProps, 'POST', servicePath.getUnreadCount).then(res => {
            if (res.success) {
                let data = res.data.总未读数
                let weekCount = res.data.本周未读数
                dispatch({ type: COUNT_CHANGE, data })
                dispatch({ type: WEEK_COUNT_CHANGE, weekCount })
            }
        })
    }

    function getLetterFive() {
        let dataProps = {
            'role_id': cookie.load('role'),
            "shop_id": cookie.load('shopList'),
            "user_id": cookie.load('id'),
        }
        NbAxios(dataProps, 'POST', servicePath.getPersonalFiveLetters).then(res => {
            if (res.success) {
                let data = res.data
                setLetterList(data)
            }
        })
    }

    function clearDelayTimer(){
        clearTimeout(delayClosedTimer)
    }

    function closedPopover() {
        clearInterval(delayOpenTimer)
        delayClosedTimer = setTimeout(()=>{
            setVisible(false)
            setLetterList([])
        },500)
    }

    function delayOpen() {
        if(urlPath==="/app/letter/lookLetter"){
            return false
        }else{
            delayOpenTimer = setTimeout(()=>{
                setVisible(true)
                getLetterFive()
            },300)
        }
    }

    return (
        <Popover placement="bottomRight" autoAdjustOverflow content={content} mouseEnterDelay={0.5} visible={visible} trigger="hover" overlayClassName={'popover-letter'} >
            <Badge title="未读消息数" size="small" style={{ cursor: "pointer", marginRight: 20 }} count={state.count} showZero={false}>
                {/* <Link to="/app/letter/lookLetter"> */}
                    <BellOutlined
                        // onMouseEnter={delayOpen}
                        // onMouseLeave={closedPopover}
                        onClick={()=>{clearTimeout(delayOpenTimer)}}
                        style={{ cursor: "pointer", fontSize: `${fontSize}`, color: `${color}` }}
                    />
                {/* </Link> */}
            </Badge>
        </Popover>
    )
}

export default LetterTip