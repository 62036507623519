import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Row, Col, Button, DatePicker, Modal, Spin, message, Collapse, Input, Form, Select, Table, Popconfirm, Image } from 'antd'
import { CloudUploadOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import servicePath from '../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import axios from 'axios'
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select

function Details(props) {

    const [form] = Form.useForm();

    const [form1] = Form.useForm()

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    }

    const layout2 = {
        wrapperCol: { span: 16 },
    }

    // 是否处理加载状态
    const [spinning, setSpinning] = useState(false)

    // 数量集合
    const [numList, setNumList] = useState([])

    // 商品编码
    const [spbmList, setSpbmList] = useState([])

    // 数据
    const [date, setDate] = useState([])

    // 添加SKU数据
    const [skuDate, setSkuDate] = useState([])

    // 添加SKU总数
    const [skuTotal, setSKUTotal] = useState([])

    // 总数页数
    const [total, setTotal] = useState(0)

    // 选中的id列表
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    // 选中的列表对象
    const [selectedRow, setSelectedRow] = useState([])

    // 采购状态
    const [status, setStatus] = useState('')

    //页面
    const [page, setPage] = useState(1)

    // 品牌
    const [trademark, setTrademark] = useState('')

    // SKU
    const [SKU, setSKU] = useState('')

    // 采购单号
    const [purchaseNumber, setPurchaseNumber] = useState('')

    // 商品编号
    const [productId, setProductId] = useState('')

    // 根据SKU创建SKU
    const [openSKU, setOpenSKU] = useState(false)

    // 打开搜索框
    const [openSearch, setOpenSearch] = useState(false)

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 表格标题
    const columns = [
        {
            title: '图片',
            key: 'image',
            dataIndex: 'image',
            width: '100px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => (
                <Image width={50} src={'https://pic.bmcsoft.cn/imge_dir/item_info/xh_outlets/' + date[index]['spbm'].substring(0, 8) + '.jpg'} />
            ),
        },
        {
            title: 'SKU',
            key: 'spbm',
            dataIndex: 'spbm',
            width: '150px',
            align: 'center',
        },
        {
            title: "数量",
            key: 'num',
            dataIndex: 'num',
            width: '100px',
            align: 'center',
        },
        {
            title: "查看",
            key: 'number',
            dataIndex: 'number',
            width: '100px',
            align: 'center',
            render: (text, record, index) => (
                <SearchOutlined onClick={() => {
                    let data = date[index]
                    let info = purchaseNumber  + ',' + data['spbm'] + ',' + data['ky'] + ',' + status
                    props.history.push({ 'pathname': '/app/presell/skudetails', 'state': { 'infomation': info } })
                }} />
            ),
        },
        {
            title: "E3库存数量",
            key: 'ky',
            dataIndex: 'ky',
            width: '100px',
            align: 'center',
        },
        {
            title: '删除SKU',
            key: 'delete',
            dataIndex: 'delete',
            width: '100px',
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => (
                <Popconfirm
                    title="确认删除?删除后不可恢复!"
                    onConfirm={affirmDelect}
                    okText="确定"
                    cancelText="取消"

                >
                    <DeleteOutlined onClick={() => {
                        let data = date[index]
                        setProductId(data['spbm'])
                    }}
                        style={{ display: status != '待采购' ? 'none' : '' }}
                    />
                </Popconfirm>
            ),
        },
    ]
    // SKU
    const skucolumns = [
        {
            title: '图片',
            key: 'image',
            dataIndex: 'image',
            width: '100px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => (
                <Image width={50} src={'https://pic.bmcsoft.cn/imge_dir/item_info/xh_outlets/' + skuDate[index]['spbm'].substring(0, 8) + '.jpg'} />
            ),
        },
        {
            title: 'SKU',
            key: 'spbm',
            dataIndex: 'spbm',
            width: '100px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '订单缺货件数',
            key: 'ddqhjs',
            dataIndex: 'ddqhjs',
            width: '80px',
            align: 'center'
        },
        {
            title: '待采购',
            key: 'dcg',
            dataIndex: 'dcg',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                text == null ? 0 : parseInt(text)
            )
        },
        {
            title: '待发货',
            key: 'dfh',
            dataIndex: 'dfh',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                text == null ? 0 : parseInt(text)
            )
        },
        {
            title: '已发货',
            key: 'yfh',
            dataIndex: 'yfh',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                text == null ? 0 : parseInt(text)
            )
        },
        {
            title: '已签收',
            key: 'yqs',
            dataIndex: 'yqs',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                text == null ? 0 : parseInt(text)
            )
        },
        {
            title: '销退仓库存',
            key: 'xtckc',
            dataIndex: 'xtckc',
            width: '120px',
            align: 'center'
        },
        {
            title: '更新时间',
            key: 'xtc_time',
            dataIndex: 'xtc_time',
            width: '150px',
            align: 'center'
        },
        {
            title: '待采购数量',
            key: 'dcgsl',
            dataIndex: 'dcgsl',
            width: '80px',
            align: 'center'
        },
        {
            title: 'E3库存数量',
            key: 'E3kc',
            dataIndex: 'E3kc',
            width: '80px',
            align: 'center'
        },
        {
            title: '更新时间',
            key: 'kc_time',
            dataIndex: 'kc_time',
            width: '150px',
            align: 'center'
        },
        {
            title: '该SKU是否已存在',
            key: 'cz',
            dataIndex: 'cz',
            width: '100px',
            align: 'center',
            fixed: 'right',
        },
    ]

    // 改变多选
    const onSelectChange = (selectedRowKeys, selectedRow) => {
        if (selectedRow.length != 0) {
            // 是否已存在
            if (selectedRow[selectedRow.length - 1]['cz'] != 1) {
                // 判断E3库存是否为null
                if (selectedRow[selectedRow.length - 1]['E3kc'] != null) {
                    // 判断E3库存是否大于0
                    if (parseInt(selectedRow[selectedRow.length - 1]['E3kc']) > 0) {
                        // 判断待采购是否大于0
                        if (parseInt(selectedRow[selectedRow.length - 1]['dcgsl']) > 0) {
                            setSelectedRow(selectedRow)
                            setSelectedRowKeys(selectedRowKeys)
                        }
                    }
                }
            }

        } else {
            setSelectedRow([])
            setSelectedRowKeys([])
        }
    }

    // 全选改变
    const onSelectAllChange = (selected, selectedRows, changeRows) => {
        // 判断是否是第一次按全选(即选中集合的长度为0)
        if (selectedRow.length == 0) {
            // 选中集合的长度为0 则进行如下操作
            let selectedRowList = []
            let selectedRowKeysList = []
            selectedRows.map((item, index) => {
                // 是否已存在
                if (item['cz'] != 1) {
                    // 判断E3库存是否为null
                    if (item['E3kc'] != null) {
                         // 判断E3库存是否大于0
                        if(item['E3kc'] > 0){
                            // 判断待采购是否大于0
                            if (parseInt(item['dcgsl']) > 0) {
                                selectedRowList.push(item)
                                selectedRowKeysList.push(item['spbm'])
                            }
                        }
                    }
                }
            })
            setSelectedRowKeys(selectedRowKeysList)
            setSelectedRow(selectedRowList)
        } else {
            // 选中集合的长度不为0,则全部置空
            setSelectedRow([])
            setSelectedRowKeys([])
        }

    }

    // 多选设置
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        onSelectAll: onSelectAllChange
    }

    useEffect(() => {
        let infoList = props.location.state.infomation.split(',')
        setPurchaseNumber(infoList[0])
        setStatus(infoList[1])
        let dataProps = {
            "cgdh": infoList[0],
            "spbm": '',
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrderSku,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }, [])


    // 获取数据
    const getDate = () => {
        let dataProps = {
            "cgdh": purchaseNumber,
            "spbm": SKU,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrderSku,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 重置
    const reset = () => {
        setSKU('')
        form.resetFields()
        let dataProps = {
            "cgdh": purchaseNumber,
            "spbm": '',
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrderSku,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 删除SKU
    const affirmDelect = () => {
        let dataProps = {
            "cgdh": purchaseNumber,
            "spbm": productId,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.deletePurchaseOrderSku,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    message.success('成功删除SKU')
                    getDate()
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 确认创建SKU
    const affirmSKU = () => {
        setEffective(1)
        selectedRow.map(item => {
            // 首先判断E3库存不为null
            if (item['E3kc'] != null) {
                // 其次判断待采购数量大于0
                if (parseInt(item['dcgsl']) > 0) {
                    // 判断E3库存和待采购数量那个小 取小的
                    if (parseInt(item['E3kc']) < parseInt(item['dcgsl'])) {
                        numList.push(item['E3kc'])
                    } else {
                        numList.push(item['dcgsl'])
                    }
                    spbmList.push(item['spbm'])
                }
            }
        })
        let dataProps = {
            "cgdh": purchaseNumber,
            "spbm": spbmList,
            'num': numList,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.addPurchaseOrderSku,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    setOpenSKU(false)
                    setNumList([])
                    setSpbmList([])
                    setSelectedRow([])
                    setSelectedRowKeys([])
                    getDate()
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )

    }

    // 取消创建SKU
    const closeSKU = () => {
        setOpenSKU(false)
        setNumList([])
        setSpbmList([])
        setSelectedRow([])
        setSelectedRowKeys([])
    }

    // 添加SKU
    const addSKU = () => {
        setSpinning(true)
        let dataProps = {
            "cgdh": purchaseNumber,
            "pp": trademark,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrderOutOfStockOrderCount,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setSpinning(false)
                if (res.data.success) {
                    setSKUTotal(res.data.total)
                    setSkuDate(res.data.data)
                    setOpenSKU(true)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // SKU查询
    const SKUSearch = () => {
        setSpinning(true)
        let dataProps = {
            "cgdh": purchaseNumber,
            "pp": trademark,
            'spbm': productId,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrderOutOfStockOrderCount,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setSpinning(false)
                if (res.data.success) {
                    setSKUTotal(res.data.total)
                    setSkuDate(res.data.data)
                    setOpenSearch(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // SKU重置
    const SKUReset = () => {
        form1.resetFields()
        setProductId('')
        setSpinning(true)
        let dataProps = {
            "cgdh": purchaseNumber,
            "pp": trademark,
            'spbm': '',
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrderOutOfStockOrderCount,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setSpinning(false)
                if (res.data.success) {
                    setSKUTotal(res.data.total)
                    setSkuDate(res.data.data)
                    setOpenSearch(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 关闭搜索框
    const onSearchClose = () => {
        setOpenSearch(false)
    }

    return (
        <div style={{ marginLeft: size.width * 0.06 }}>
            <Spin spinning={spinning} size="large" style={{ marginTop: size.height * 0.3 }}>
                <div>
                    <Form
                        form={form}
                        {...layout2}
                        size="large"
                    >
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    name="purchaseNumber"
                                    label="采购单号:"
                                    style={{ fontWeight: "bold" }}
                                >
                                    <span style={{ fontWeight: "bold", fontSize: '20px' }}>{purchaseNumber}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {/* 导入按钮区域 */}
                <div style={{ marginBottom: size.height * 0.02 }}>
                    <Button style={{ marginLeft: '10px', marginRight: '50px', display: status != '待采购' ? 'none' : '' }} type="primary" onClick={() => addSKU()} >添加SKU</Button>
                    <Button style={{ marginLeft: '10px', marginRight: '50px' }} type="primary" onClick={() => props.history.push({ 'pathname': '/app/presell/purchase' })}>返回采购单预览</Button>
                    <Button onClick={() => setOpenSearch(true)} style={{ marginLeft: '50px' }}>灵活查询</Button>
                </div>
                <Drawer
                    title="查询条件"
                    placement="right"
                    closable={false}
                    onClose={onSearchClose}
                    visible={openSearch}
                    width={500}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="SKU"
                                label="SKU"
                            >
                                <Input
                                    id='SKU'
                                    placeholder="请输入SKU"
                                    value={SKU}
                                    onChange={(e) => {
                                        setSKU(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button type="primary" style={{ marginRight: "10px", marginLeft: '150px' }} onClick={() => getDate()}>查询</Button>
                            <Button type="primary" danger onClick={() => reset()}>重置</Button>
                        </Col>
                    </Row>
                </Drawer>
                <div style={{ marginTop: size.height * 0.02, marginRight: size.width * 0.06 }}>
                    {/* 表单区域 */}
                    <Table
                        //表格列的配置描述
                        columns={columns}
                        //数据数组
                        dataSource={date}
                        pagination={false}
                        scroll={{y: size.height*0.6 }}
                        //表格行 key 的取值
                        rowKey={'id'}
                        onRow={function (record, index) {
                            return (
                                {
                                    style: {
                                        backgroundColor: index % 2 == 0 ? '#D3D3D3' : '#FFFFFF'
                                    },
                                }
                            )
                        }}
                    />
                </div>
            </Spin>

            <Modal
                title={"采购单号:" + purchaseNumber + "---添加SKU"}
                centered={true}
                visible={openSKU}
                onOk={affirmSKU}
                onCancel={closeSKU}
                okText="添加"
                cancelText="取消"
                width="1300px"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Collapse>
                    <Panel header="查询条件" key="1">
                        <Form
                            form={form1}
                            {...layout}
                        >
                            <Row gutter={16}>
                                <Col span={7}>
                                    <Form.Item
                                        name="SKU"
                                        label="SKU"
                                    >
                                        <Input
                                            id='SKU'
                                            placeholder="请输入SKU"
                                            value={productId}
                                            onChange={(e) => {
                                                setProductId(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Button type="primary" style={{ marginRight: "10px", marginLeft: '150px' }} onClick={() => SKUSearch()}>查询</Button>
                                    <Button type="primary" danger onClick={() => SKUReset()}>重置</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
                <Table
                    //表格列的配置描述
                    columns={skucolumns}
                    rowSelection={rowSelection}
                    //数据数组
                    dataSource={skuDate}
                    pagination={false}
                    scroll={{ y: 500 }}
                    //表格行 key 的取值
                    rowKey={'spbm'}
                    onRow={function (record, index) {
                        return (
                            {
                                style: {
                                    backgroundColor: index % 2 == 0 ? '#D3D3D3' : '#FFFFFF'
                                },
                            }
                        )
                    }}
                />
            </Modal>
        </div>

    )
}
export default Details