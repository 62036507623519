import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Badge, Avatar, Switch, Typography, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import './style/letterCard.css'
import servicePath from '../../config/apiUrl'
import cookie from 'react-cookies';
import { Link } from "react-router-dom"
import NbAxios from '../../config/utils/nbAxios'

const { Paragraph, Text, Title } = Typography;

function LetterCard({
    id,
    name,
    title,
    status,
    time ,
    content = "暂无内容",
    showContent = true,
    cardLink,
    ...rest }) {
    const [ownStatus,setOwnStatus] = useState(0)
    const {closed,letterCount} = {...rest}
    const [disable,setDisable] = useState(false)
    const [check,setCheck] = useState(false)
    const [loading,setLoading] = useState(false)

    function readLetter(id){
        setLoading(true)
        let dataProps = {
            'role_id': cookie.load('role'),
            "shop_id": cookie.load('shopList'),
            "letter_id":id
        }
        NbAxios(dataProps,'POST',servicePath.readLetter).then(res=>{
            if(res.success){
                message.success("消息标记已读成功！")
                // 标记成功
                setCheck(true)
                // 加载状态取消
                setLoading(false)
                // 按钮不可再次点击
                setDisable(true)
                // 设置卡片内部状态变化
                setOwnStatus(1)
                // 同步未读数变化
                letterCount && letterCount()
            }else{
                message.warn("消息标记已读失败！")
            }
        }).catch(res=>{
            message.warn("消息标记已读失败！")
        })
    }

    return (
        <div className="letter-card-wapper-index" {...rest}>
            <Badge dot count={ownStatus===1?0:status === 1 ? 0 : 1}>
                <div className="letter-card-wapper">
                    <div className="avatar">
                        <Avatar style={{ backgroundColor: 'rgba(142, 197, 242, 1)' }} size="large" >{name && name.substring(0, 1)}</Avatar>
                    </div>
                    <span className="name">{name}</span>
                    <span className="status"><Text type="secondary">{ownStatus===1?"已读":status === 1 ? "已读" : "未读"}</Text></span>
                    <span className="time"><Text type="secondary" >{time}</Text></span>
                    {
                        status === 0 && <div className="read-button"><Text type="secondary" >标记已读：</Text><Switch disabled={disable} checked={check} onClick={()=>readLetter(id)} loading={loading} checkedChildren={<CheckOutlined />} size="small" /></div>
                    }
                    <span className="message">
                        {
                            showContent ? <Title level={4}>{title}</Title> : <Paragraph style={{ width: "100%" }} ellipsis >{title}</Paragraph>
                        }
                        {
                            showContent === false &&
                            <Text onClick={closed} className="letter-card-detail" style={{ color: "rgba(79, 157, 222, 1)" }}>{cardLink && <Link to={cardLink}>详情</Link>}</Text>
                        }
                    </span>
                    {
                        showContent &&
                        <Paragraph className="content">
                            {/* {content} */}
                            <div dangerouslySetInnerHTML={{ __html: content }}>
                            </div>
                        </Paragraph>
                    }
                </div>
            </Badge>
        </div>
    )
}

LetterCard.prototype = {
    name: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.oneOf([0, 1]),
    time: PropTypes.string,
    showMes: PropTypes.bool,
    content: PropTypes.string,
    load: PropTypes.bool,
    cardLink:PropTypes.string,
    showContent: PropTypes.bool,
}

export default LetterCard