import React, { useState, useEffect } from "react";
import {Row, Button, Modal, Form,message, Upload, Typography, Radio, Spin } from 'antd'
import { QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import axios from 'axios'
import Base64 from 'base-64'
import LogoTitle from './image/logo.png'
// import '../../../static/css/work/work.css'
import NbAxios from "../../config/utils/nbAxios";
// 设置文本
const { Text } = Typography;
function Index(props) {
    // 设置选择图片样式
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">选择图片</div>
        </div>
    );
    // 设置加载中变量
    const [loading, setloading] = useState(false);
    // 设置短信的数据源
    const [formMessage] = Form.useForm();

    // 控制绿码图片对话框的可不可见
    const [isGreenCodeModalVisible, setIsGreenCodeModalVisible] = useState(false);
    // 控制绿码缩略图对话框的可不可见
    const [greenCodeVisible, setGreenCodeVisible] = useState(false);
    // 控制绿码图片缩略图
    const [greenCodeImage, setGreenCodeImage] = useState("");
    // 控制绿码图片缩略名
    const [greenCodeTitle, setGreenCodeTitle] = useState("");
    // 储存绿码图片文件列表
    const [greenCodeFileList, setGreenCodeFileList] = useState([]);
    // 控制行程码图片对话框的可不可见
    const [isTravelCodeModalVisible, setIsTravelCodeModalVisible] = useState(false);
    // 控制行程码缩略图对话框的可不可见
    const [travelCodeVisible, setTravelCodeVisible] = useState(false);
    // 控制行程码图片缩略图
    const [travelCodeImage, setTravelCodeImage] = useState("");
    // 控制行程码图片缩略名
    const [travelCodeTitle, setTravelCodeTitle] = useState("");
    // 储存行程码图片文件列表
    const [travelCodeFileList, setTravelCodeFileList] = useState([]);
    // 控制行程码图片对话框的可不可见
    const [isNucleicAcideModalVisible, setIsNucleicAcideModalVisible] = useState(false);
    // 控制行程码缩略图对话框的可不可见
    const [nucleicAcideVisible, setNucleicAcideVisible] = useState(false);
    // 控制行程码图片缩略图
    const [nucleicAcideImage, setNucleicAcideImage] = useState("");
    // 控制行程码图片缩略名
    const [nucleicAcideTitle, setNucleicAcideTitle] = useState("");
    // 储存行程码图片文件列表
    const [nucleicAcideFileList, setNucleicAcideFileList] = useState([]);
    // 控制用户补助对话框的可不可见
    const [isAddDevice, setIsAddDevice] = useState(false);
    // 数据源
    const [obj, setObj] = useState({})
    // 是否使用电脑
    const [computerUse, setComputerUse] = useState()

    // 图片上传函数
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
    // 绿码图片缩略图函数
    const handleGreenCode = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setGreenCodeImage(file.url || file.preview);
        setGreenCodeVisible(true);
        setGreenCodeTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };
    // 行程码图片缩略图函数
    const handleTravelCode = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setTravelCodeImage(file.url || file.preview);
        setTravelCodeVisible(true);
        setTravelCodeTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };
    // 行程码图片缩略图函数
    const handleNucleicAcide = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setNucleicAcideImage(file.url || file.preview);
        setNucleicAcideVisible(true);
        setNucleicAcideTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };
    // 上传三码图片函数
    const uploadImage = () => {
        if (!computerUse) {
            message.error("请填写是否使用电脑");
        } else if (greenCodeFileList.length == 0) {
            message.error("请上传绿码图片");
        } else if (travelCodeFileList.length == 0) {
            message.error("请上传行程码图片");
        } else if (nucleicAcideFileList.length == 0) {
            message.error("请上传48小时核酸图片");
        } else {
            setloading(true);
            const formData = new FormData();
            // 单个图片
            // formData.append('file',fileList[0].originFileObj)

            // 多个图片
            greenCodeFileList.forEach((item) => {
                formData.append("greenCodeImageFile", item["originFileObj"]);
            });
            travelCodeFileList.forEach((item) => {
                formData.append("travelCodeImageFile", item["originFileObj"]);
            });
            nucleicAcideFileList.forEach((item) => {
                formData.append("nucleicAcidImageFile", item["originFileObj"]);
            });
            formData.append("shopList", cookie.load("shopList"));
            formData.append("roleId", cookie.load("role"));
            formData.append("name", cookie.load("name"));
            formData.append("word_city", props.location.state.word_city)
            formData.append("company", props.location.state.company)
            formData.append("staff_name", props.location.state.staff_name)
            formData.append("staff_phone", props.location.state.staff_phone)
            formData.append("computer_use", computerUse)
            NbAxios(formData, "POST", servicePath.handleShortMessageEmployment).then(
                (res) => {
                    setloading(false);
                    if (res.success) {
                        message.success("上传成功");
                        setGreenCodeFileList([]);
                        setTravelCodeFileList([])
                        setNucleicAcideFileList([])
                    } else {
                        message.error(res.errorMsg);
                        setGreenCodeFileList([]);
                        setTravelCodeFileList([])
                        setNucleicAcideFileList([])
                    }
                }
            );
        };
    }
    return (
        <div style={{ backgroundColor: "#eee", paddingBottom:20 , height:"100vh"}}>
            <div style={{
                width:"100vw", 
                height:50,
                display:'flex', 
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:'white',
                position: 'sticky',
                top: 0,
                zIndex: 99}}>
                < img src={LogoTitle} style={{height:40}} />
            </div>
            <Spin tip="Loading..." spinning={loading}>
                {/* 基础信息板块 */}
                <div style={{ background: "#fff", marginTop: 10 }}>
                    <div style={{ display: "flex", padding: "5vw" }}>
                        <div style={{ width: 100 }}>
                            <Text strong style={{ fontSize: "15px" }}>
                                姓名：
                            </Text>
                        </div>
                        <div>
                            <Text strong type="secondary" style={{ fontSize: "15px" }}>
                                {props.location.state.staff_name}
                            </Text>
                        </div>
                    </div>
                    <div style={{ display: "flex", padding: "5vw" }}>
                        <div style={{ width: 100 }}>
                            <Text strong style={{ fontSize: "15px" }}>
                                岗位：
                            </Text>
                        </div>
                        <div>
                            <Text strong type="secondary" style={{ fontSize: "15px" }}>
                                {props.location.state.entry_post}
                            </Text>
                        </div>
                    </div>
                    <div style={{ display: "flex", padding: "5vw" }}>
                        <div style={{ width: 100 }}>
                            <Text strong style={{ fontSize: "15px" }}>
                                入职地点：
                            </Text>
                        </div>
                        <div>
                            <Text strong type="secondary" style={{ fontSize: "15px" }}>
                                {props.location.state.work_city}
                            </Text>
                        </div>
                    </div>
                    <div style={{ display: "flex", padding: "5vw" }}>
                        <div style={{ width: 100 }}>
                            <Text strong style={{ fontSize: "15px" }}>
                                办公地点：
                            </Text>
                        </div>
                        <div>
                            <Text strong type="secondary" style={{ fontSize: "15px" }}>
                                {props.location.state.work_location}
                            </Text>
                        </div>
                    </div>
                </div>
                {/* 电脑板块 */}
                <div style={{ display: "flex", padding: "5vw", backgroundColor: "#fff", marginTop: 10 }}>
                    <div>
                        <Text strong style={{ fontSize: "15px" }}>
                            公司是否提供电脑：
                        </Text>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <Radio.Group>
                                <Radio value="是" onChange={(e) => setComputerUse(e.target.value)} style={{ marginLeft: "3vw" }}>是</Radio>
                                <Radio value="否" onChange={(e) => setComputerUse(e.target.value)} style={{ marginLeft: "3vw" }}>否</Radio>
                            </Radio.Group>
                        </div>
                        <div>
                            <QuestionCircleOutlined onClick={() => { setIsAddDevice(true) }} />
                        </div>
                    </div>
                </div>
                {/* 两码一证板块 */}
                <div style={{ backgroundColor: "#fff", marginTop: 10 }}>
                    <div style={{ display: "flex", paddingTop: "5vw", justifyContent: "center" }}>
                        <div>
                            {greenCodeFileList.length == 0 ? <Button onClick={() => { setIsGreenCodeModalVisible(true) }} style={{ width: "64vw" }}>
                                健康码待上传
                            </Button> : <Button onClick={() => { setIsGreenCodeModalVisible(true) }} style={{ width: "64vw", background: "#3CB371", color: "#FFFFFF" }}>
                                健康码已选择
                            </Button>
                            }
                        </div>
                    </div>
                    <div style={{ display: "flex", paddingTop: "5vw", justifyContent: "center" }}>
                        <div>
                            {travelCodeFileList.length == 0 ? <Button onClick={() => { setIsTravelCodeModalVisible(true) }} style={{ width: "64vw" }}>
                                行程码待上传
                            </Button> : <Button onClick={() => { setIsTravelCodeModalVisible(true) }} style={{ width: "64vw", background: "#3CB371", color: "#FFFFFF" }}>
                                行程码已选择
                            </Button>
                            }
                        </div>
                    </div>
                    <div style={{ display: "flex", paddingTop: "5vw", paddingBottom:'5vw', justifyContent: "center" }}>
                        <div>
                            {nucleicAcideFileList.length == 0 ? <Button onClick={() => { setIsNucleicAcideModalVisible(true) }} style={{ width: "64vw" }}>
                                48小时核酸证明待上传
                            </Button> : <Button onClick={() => { setIsNucleicAcideModalVisible(true) }} style={{ width: "64vw", background: "#3CB371", color: "#FFFFFF" }}>
                                48小时核酸证明已选择
                            </Button>
                            }
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 20, display: "flex", justifyContent: "center"}}>
                    <Button type="primary" style={{ width: "90vw" }} onClick={() => { uploadImage() }}>
                        提交
                    </Button>
                </div>
                {/* 绿码图片添加对话框 */}
                <Modal
                    title="绿码图片添加窗口"
                    visible={isGreenCodeModalVisible}
                    onOk={() => { setIsGreenCodeModalVisible(false) }}
                    onCancel={() => {
                        setIsGreenCodeModalVisible(false)
                    }}
                    okText={"确认"}
                    cancelText={"取消"}
                    footer={[null, null]}
                    centered={true}
                >
                    <Spin tip="Loading..." spinning={loading}>
                        <Row>
                            <div style={{ marginLeft: "2vw" }}>
                                <Upload
                                    action={servicePath.development_uploadSuccess} //上传地址，填错/不填上传图片的时候都会报错
                                    listType="picture-card" // 上传列表的内建样式
                                    fileList={greenCodeFileList} //已经上传的文件列表（受控）
                                    onPreview={handleGreenCode} //点击文件链接或预览图标时的回调
                                    onChange={({ fileList }) => { setGreenCodeFileList(fileList); }} //上传文件改变时的状态
                                >
                                    {greenCodeFileList.length >= 1 ? null : uploadButton}
                                </Upload>
                            </div>
                        </Row>
                        {/* <Text style={{ marginLeft: 40 }}>选择图片</Text> */}
                        <div style={{ display: "flex", justifyContent: "end", marginRight: "2vw" }}>
                            <Button type="primary" onClick={() => setIsGreenCodeModalVisible(false)}>
                                确定
                            </Button>
                        </div>
                    </Spin>
                </Modal>
                {/* 绿码上传图片缩略图对话框 */}
                <Modal
                    visible={greenCodeVisible}
                    title={greenCodeTitle}
                    footer={null}
                    onCancel={() => { setGreenCodeVisible(false) }}
                >
                    <img alt="example" style={{ width: "100%" }} src={greenCodeImage} />
                </Modal>
                {/* 行程码图片添加对话框 */}
                <Modal
                    title="行程码图片添加窗口"
                    visible={isTravelCodeModalVisible}
                    onOk={() => { setIsTravelCodeModalVisible(false) }}
                    onCancel={() => {
                        setIsTravelCodeModalVisible(false)
                    }}
                    okText={"确认"}
                    cancelText={"取消"}
                    footer={[null, null]}
                    centered={true}
                >
                    <Spin tip="Loading..." spinning={loading}>
                        <Row>
                            <div style={{ marginLeft: "2vw" }}>
                                <Upload
                                    action={servicePath.development_uploadSuccess} //上传地址，填错/不填上传图片的时候都会报错
                                    listType="picture-card" // 上传列表的内建样式
                                    fileList={travelCodeFileList} //已经上传的文件列表（受控）
                                    onPreview={handleTravelCode} //点击文件链接或预览图标时的回调
                                    onChange={({ fileList }) => { setTravelCodeFileList(fileList); }} //上传文件改变时的状态
                                >
                                    {travelCodeFileList.length >= 1 ? null : uploadButton}
                                </Upload>
                            </div>
                        </Row>
                        {/* <Text style={{ marginLeft: 40 }}>选择图片</Text> */}
                        <div style={{ display: "flex", justifyContent: "end", marginRight: "2vw" }}>
                            <Button type="primary" onClick={() => setIsTravelCodeModalVisible(false)}>
                                确定
                            </Button>
                        </div>
                    </Spin>
                </Modal>
                {/* 行程码上传图片缩略图对话框 */}
                <Modal
                    visible={travelCodeVisible}
                    title={travelCodeTitle}
                    footer={null}
                    onCancel={() => { setTravelCodeVisible(false) }}
                >
                    <img alt="example" style={{ width: "100%" }} src={travelCodeImage} />
                </Modal>
                {/* 核酸图片添加对话框 */}
                <Modal
                    title="核酸图片添加窗口"
                    visible={isNucleicAcideModalVisible}
                    onOk={() => { setIsNucleicAcideModalVisible(false) }}
                    onCancel={() => {
                        setIsNucleicAcideModalVisible(false)
                    }}
                    okText={"确认"}
                    cancelText={"取消"}
                    footer={[null, null]}
                    centered={true}
                >
                    <Spin tip="Loading..." spinning={loading}>
                        <Row>
                            <div style={{ marginLeft: "2vw" }}>
                                <Upload
                                    action={servicePath.development_uploadSuccess} //上传地址，填错/不填上传图片的时候都会报错
                                    listType="picture-card" // 上传列表的内建样式
                                    fileList={nucleicAcideFileList} //已经上传的文件列表（受控）
                                    onPreview={handleNucleicAcide} //点击文件链接或预览图标时的回调
                                    onChange={({ fileList }) => { setNucleicAcideFileList(fileList); }} //上传文件改变时的状态
                                >
                                    {nucleicAcideFileList.length >= 1 ? null : uploadButton}
                                </Upload>
                            </div>
                        </Row>
                        {/* <Text style={{ marginLeft: 40 }}>选择图片</Text> */}
                        <div style={{ display: "flex", justifyContent: "end", marginRight: "2vw" }}>
                            <Button type="primary" onClick={() => setIsNucleicAcideModalVisible(false)}>
                                确定
                            </Button>
                        </div>
                    </Spin>
                </Modal>
                {/* 核酸上传图片缩略图对话框 */}
                <Modal
                    visible={nucleicAcideVisible}
                    title={nucleicAcideTitle}
                    footer={null}
                    onCancel={() => { setNucleicAcideVisible(false) }}
                >
                    <img alt="example" style={{ width: "100%" }} src={nucleicAcideImage} />
                </Modal>
                <Modal
                    title="用户告知书"
                    centered={true}
                    visible={isAddDevice}
                    onCancel={() => setIsAddDevice(false)}
                    footer={[]}
                    width={"100vw"}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* <Text type="danger" strong style={{marginBottom:10}}>您的补助金额预计为{detailData.subsidy}元，后续流程中IT会人工再次核实，如有变化，会另行通知。</Text> */}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center',height: "50vh", overflow: 'auto' }}>
                        <iframe
                            src="https://pic.bmcsoft.cn/imge_dir/info.html"
                            align="center"
                            style={{ width: "80vw", height: "50vh" }}> </iframe>
                    </div>
                    {/* <div style={{display:'flex', justifyContent:'center'}}>
                        <Checkbox onChange={(e)=>setIsConsentNotice(e.target.checked)}>朕已阅</Checkbox>
                    </div> */}
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: "1vh" }}>
                        <Button type="primary" onClick={() => setIsAddDevice(false)} style={{width:"70vw"}}>确认</Button>
                    </div>
                </Modal>
            </Spin>
        </div>
    )
}

export default Index