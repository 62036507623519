import Axios from 'axios';
import cookie from 'react-cookies'
import {message} from 'antd';
/**
 * 一个二次封装的axios,可携带token，鉴权不通过可清除cookie，返回数据
 * @param {Object} data 携带的参数对象
 * @param {string} method 默认为POST，请求方法POST,GET,DELETE等等。
 * @param {string} url 请求链接
 * @param {boolean} cookie 是否携带cookies
 */
export default function NbAxios(data = {}, method = 'POST', url = '', withCookie = false) {
    let config = {
        method: method,
        url: url,
        data: data,
        headers: {
            'Content-Type': 'application/json',
            'authorization': cookie.load('token')
        },
        withCredentials: withCookie,
    }
    if( method === 'GET' ){
        delete config.data
    }
    return new Promise((resolve, reject)=>{
        let reMes = {}
        Axios(config).then(
            res => {
                if (res.data.success) {
                    reMes.total = res.data.total
                    reMes.data = res.data.data
                    reMes.success = true
                } else {
                    if (res.data.errorCode === '10001') {
                        cookie.remove("id", { path: '/' });
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("shopList", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        window.location.replace('https://nb20.bmcsoft.cn/')
                    }
                    reMes.success = false
                    reMes.errorMsg = res.data.errorMsg
                    reMes.errorCode = res.data.errorCode
                }
                resolve(reMes)
            }
        ).catch(e=>{
            reMes.success = false
            reMes.errorMsg = e
            reject(reMes)
        })
    })
}
