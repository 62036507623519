import React, { useState, useEffect } from "react";
import { Tabs, Button, Modal, message, Upload, Typography, Spin, Input, Table, Select, Image } from 'antd'
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import servicePath from "../../../config/apiUrl";
import PlatformEventsCalendar from '../../../component/platformEventsCalendar'
import NbAxios from "../../../config/utils/nbAxios";
import AcceptBuried from "../../../config/utils/buried";
import BarComponents from './components/barComponents'
import downExcel from "../../../config/utils/downloadFile"
import liveDict from '../data/liveDict';
import liveNameDict from '../data/liveNameDict';
import tiktokDict from "../data/tiktokDict";
import axios from "axios";
const { Title, Text } = Typography;
const { Option } = Select
const { TabPane } = Tabs;

function Index(props) {
    // 设置选择文件按钮样式
    const uploadFileButton = (
        <div>
            <Button icon={<UploadOutlined />}>选择文件</Button>
        </div>
    );
    // 汇总数据
    const [ aggregateData, setAggregateData] = useState({
        'histogram1':{'data':['-','-'], 'ratio':'-'},
        'histogram2':{'data':['-','-'], 'ratio':'-'},
        'histogram3':{'data':['-','-'], 'ratio':'-'},
        'histogram4':{'data':['-','-'], 'ratio':'-'},
        'histogram5':{'data':['-','-'], 'ratio':'-'},
    })
    // tabs集合
    const [ tabsList ] = useState(['年份','季节','品类','价格带','TOP款','商品详情'])
    const [ tabsKey, setTabsKey ] = useState('年份')
    // 开始日期
    const [ startDate , setStartDate ] = useState(moment().subtract(1,'day').format('YYYY-MM-DD'))
    // 结束日期
    const [ endDate , setEndDate ] = useState(moment().subtract(1,'day').format('YYYY-MM-DD'))
    // 主播
    const [anchor, setAnchor] = useState("")
    // 时间段
    const [timeInterval, setTimeInterval] = useState("")
    // 上传excel列表
    const [fileList, setFileList] = useState([]);
    // 设置加载中变量
    const [loading, setloading] = useState(false);
    // 控制上传excel对话框的可不可见
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    // 基础字段
    const [ baseField ] = useState(['款数','曝光人数','点击人数','点击率（人）','成交人数','点击转化率','成交件数','成交金额','金额占比','单款贡献'])
    const [ commodityBaseField ] = useState(['款号','曝光人数','点击人数','点击率（人）','成交人数','点击转化率','成交件数','件单价','GPM','成交金额'])
    // 基础表头
    const [baseColumns] = useState(baseField.map(item=>{
        return {
            title:item,
            dataIndex:item,
            key:item,
            align: 'center',
            width:"100px",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        }
    }))
    // 商品基础表头
    const [ commodityBaseColumns] = useState(commodityBaseField.map(item=>{
        return {
            title:item,
            dataIndex:item == '款号' ? "spu": item,
            key:item,
            align: 'center',
            width:"150px",
            sorter:item == '款号' ? false: true,
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        }
    }))
    // 年份表头
    const [ yearColumns ] = useState([
        {
            title:"年份",
            dataIndex:"年份",
            key:"年份",
            align: 'center',
            width:"80px",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseColumns.map(item=>{
            return item
        })
    ])
    // 年份数据
    const [ yearData , setYearData ] = useState([])
    // 季节表头
    const [ seasonColumns ] = useState([
        {
            title:"季节",
            dataIndex:"季节",
            key:"季节",
            align: 'center',
            width:"80px",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseColumns.map(item=>{
            return item
        })
    ])
    // 季节数据
    const [ seasonData , setSeasonData ] = useState([])
    // 品类表头
    const [ categoryColumns ] = useState([
        {
            title:"品类",
            dataIndex:"品类",
            key:"品类",
            align: 'center',
            width:"80px",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseColumns.map(item=>{
            return item
        })
    ])
    // 品类数据
    const [ categoryData , setCategoryData ] = useState([])
    // 价格带表头
    const [ priceBandColumns ] = useState([
        {
            title:"价格带",
            dataIndex:"价格带",
            key:"价格带",
            align: 'center',
            width:"80px",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseColumns.map(item=>{
            return item
        })
    ])
    // 品类数据
    const [ priceBandData , setPriceBandData ] = useState([])
    // TOP表头
    const [ topBandColumns ] = useState([
        {
            title:"Top款",
            dataIndex:"排名",
            key:"排名",
            align: 'center',
            width:"80px",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseColumns.map(item=>{
            return item
        })
    ])
    // TOP数据
    const [ topData , setTopData ] = useState([])
    // 商品表头
    const [ commodityColumns ] = useState([
        {
            title:"图片",
            dataIndex:"图片",
            key:"图片",
            align: 'center',
            width:"80px",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
               <Image style={{width:"50px", height:"50px"}} src={`https://pic.bmcsoft.cn/it/tiktok/${record.spu}.jpg`} />
            ),
        },
        ...commodityBaseColumns.map(item=>{
            return item
        })
    ])
    // 商品数据
    const [ commodityData , setCommodityData ] = useState([])

    useEffect(()=>{
        getAllData()
    },[])

    function getAllData(){
        let timeIntervalList = []
        if(timeInterval.length == 0){
            timeIntervalList = ['上午','下午','晚上']
        }else{
            timeIntervalList = timeInterval
        }
        let data = {}
        data.start_date = startDate
        data.end_date = endDate
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.anchor_list = anchor
        data.tiktok_or_volcano = tiktokDict[props.location.pathname.split('/')[2]]
        data.date_detail_list = timeIntervalList
        let commodityData = data
        commodityData.sort_field = '曝光人数'
        commodityData.sort_type = 1
        const getAllData = async () => {
            await Promise.all([
                getTiktokSummaryData(data),
                getSummaryOfYearData(data),
                getSummaryOfSeasonData(data),
                getSummaryOfCategoryData(data),
                getSummaryOfPriceData(data),
                getTiktokCommodityOperationalData(commodityData),
                getSummaryOfTopData(data)
            ])
        }
        getAllData()
    }
    // 获取支付件数TOP汇总数据接口
    const getSummaryOfTopData = (data)=>{
        return NbAxios(data, "POST", servicePath.getSummaryOfTopData).then((res, rej) => {
            if (res.success) {
                setTopData(res.data['table_data']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("获取支付件数TOP汇总数据出错！")
        })
    }
    // 获取商品基础信息
    const getTiktokCommodityOperationalData = (data)=>{
        setloading(true)
        return NbAxios(data, "POST", servicePath.getTiktokCommodityOperationalData).then((res, rej) => {
            setloading(false)
            if (res.success) {
                setCommodityData(res.data['table_data']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            setloading(false)
            message.error("获取商品基础信息汇总数据出错！")
        })
    }

    // 获取价格带汇总数据
    const getSummaryOfPriceData = (data)=>{
        return NbAxios(data, "POST", servicePath.getSummaryOfPriceData).then((res, rej) => {
            if (res.success) {
                setPriceBandData(res.data['table_data']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("获取价格带汇总数据出错！")
        })
    }

    // 获取品类汇总数据
    const getSummaryOfCategoryData = (data)=>{
        return NbAxios(data, "POST", servicePath.getSummaryOfCategoryData).then((res, rej) => {
            if (res.success) {
                setCategoryData(res.data['table_data']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("获取品类汇总数据出错！")
        })
    }

    // 获取季节汇总数据
    const getSummaryOfSeasonData = (data)=>{
        return NbAxios(data, "POST", servicePath.getSummaryOfSeasonData).then((res, rej) => {
            if (res.success) {
                setSeasonData(res.data['table_data']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("获取季节汇总数据出错！")
        })
    }

    // 获取年份汇总数据
    const getSummaryOfYearData = (data)=>{
        return NbAxios(data, "POST", servicePath.getSummaryOfYearData).then((res, rej) => {
            if (res.success) {
                setYearData(res.data['table_data']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("获取年份汇总数据出错！")
        })
    }

    // 获取汇总数据
    const getTiktokSummaryData = (data)=>{
        return NbAxios(data, "POST", servicePath.getTiktokSummaryData).then((res, rej) => {
            if (res.success) {
                setAggregateData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("获取汇总数据出错！")
        })
    }

    // 上传Excel函数
    const uploadFile = () => {
        if (fileList.length == 0) {
        message.error("请选择文件");
        }else{
            setloading(true);
            // AcceptBuried('upload_live_data',"click",{},props.location.pathname,"抖音直播日报表")
            const formData = new FormData();
            fileList.forEach((item) => {
                formData.append("file_list", item["originFileObj"]);
            });
            formData.append("live_id", liveDict[props.history.location.pathname.split('/')[2]]);
            formData.append("shopList", cookie.load("shopList"));
            formData.append("roleId", cookie.load("role"));
            formData.append("name", cookie.load("name"));
            formData.append("tiktok_or_volcano", tiktokDict[props.location.pathname.split('/')[2]]);
            axios({
                method: 'POST',
                url: servicePath.uploadTiktokCommodityDetailFile,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    setloading(false);
                    if (res.data['success']) {
                        message.success("直播商品运营数据上传成功");
                        setIsUploadModalVisible(false)
                        setFileList([]);
                        getAllData()
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
        
    };

    return (
        <div style={{ paddingBottom: 100, paddingLeft: 20, paddingRight: 20 }}>
            <Spin spinning={loading} tip="数据加载中.......">
                <div style={{
                    display: 'flex',
                    justifyContent: "space-around",
                    backgroundColor: "#fff",
                    borderBottom: "solid 2px #ccc",
                    marginBottom: 10,
                    position: 'fixed',
                    top: 40,
                    zIndex: 99,
                    width: '100vw',
                    paddingTop: 10,
                    paddingBottom: 10
                }}>
                    <Title level={3} style={{ margin: 0 }} >{liveNameDict[props.location.pathname.split('/')[2]]}抖音商品报表</Title>
                    <div style={{ display: 'flex' }}>
                        <b style={{marginRight:5, lineHeight:"32px"}}>日期:</b>
                        <PlatformEventsCalendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
                        <b style={{marginRight:5, lineHeight:"32px", marginLeft:10}}>主播:</b>
                        <Input size="small" style={{width:"130px"}} onChange={(e)=>setAnchor(e.target.value)}/>
                        <b style={{marginRight:5, lineHeight:"32px", marginLeft:10}}>时段:</b>
                        <Select style={{width:80}} value={timeInterval} onChange={(value)=>setTimeInterval(value)}>
                            <Option value="上午">上午</Option>
                            <Option value="下午">下午</Option>
                            <Option value="晚上">晚上</Option>
                        </Select>
                        <Button type="primary" onClick={()=>setIsUploadModalVisible(true)} style={{ marginLeft: 20 }} >上传</Button>
                        <Button type="primary" onClick={()=>getAllData()} style={{ marginLeft: 20 }} >查询</Button>
                        <a href="https://pic.bmcsoft.cn/imge_dir/%E4%B8%8A%E4%BC%A0%E6%96%87%E4%BB%B6%E6%A8%A1%E7%89%88___0601%201941.xlsx"><Button type="link">下载文件上传模版</Button></a>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:'space-around', marginTop:80, flexWrap:'nowrap'}}>
                    <BarComponents id={"Screenings"} compareNum={'40%'} height={"150px"} title="场次" currentDate={aggregateData['histogram1']['data'][0] == '-' ? 0 : aggregateData['histogram1']['data'][0]} comparedDate={aggregateData['histogram1']['data'][1] == '-' ? 0 : aggregateData['histogram1']['data'][1]} ratio={aggregateData['histogram1']['ratio']}/>
                    <BarComponents id={"numberOfModels"} compareNum={'40%'} height={"150px"} title="款数" currentDate={aggregateData['histogram2']['data'][0] == '-' ? 0 : aggregateData['histogram2']['data'][0]} comparedDate={aggregateData['histogram2']['data'][1] == '-' ? 0 : aggregateData['histogram2']['data'][1]} ratio={aggregateData['histogram2']['ratio']}/>
                    <BarComponents id={"numberOfPieces"} compareNum={'40%'} height={"150px"} title="件数" currentDate={aggregateData['histogram3']['data'][0] == '-' ? 0 : aggregateData['histogram3']['data'][0]} comparedDate={aggregateData['histogram3']['data'][1] == '-' ? 0 : aggregateData['histogram3']['data'][1]} ratio={aggregateData['histogram3']['ratio']}/>
                    <BarComponents id={"amount"} compareNum={'40%'} height={"150px"} title="金额" currentDate={aggregateData['histogram4']['data'][0] == '-' ? 0 : aggregateData['histogram4']['data'][0]} comparedDate={aggregateData['histogram4']['data'][1] == '-' ? 0 : aggregateData['histogram4']['data'][1]} ratio={aggregateData['histogram4']['ratio']}/>
                    <BarComponents id={"unitPrice"} compareNum={'40%'} height={"150px"} title="件单价" currentDate={aggregateData['histogram5']['data'][0] == '-' ? 0 : aggregateData['histogram5']['data'][0]} comparedDate={aggregateData['histogram5']['data'][1] == '-' ? 0 : aggregateData['histogram5']['data'][1]} ratio={aggregateData['histogram5']['ratio']}/>
                </div>
                <Tabs style={{marginLeft:"20px"}} activeKey={tabsKey} onChange={(activeKey)=>setTabsKey(activeKey)}>
                    {
                    tabsList.map(item=>{
                            return <TabPane tab={item} key={item}></TabPane>
                    }) 
                    }
                </Tabs>
                <div style={{display:tabsKey == '年份' ? '':'none'}}>
                    <Table columns={yearColumns} dataSource={yearData} bordered style={{marginRight:"40px"}} pagination={false}/>
                </div>
                <div style={{display:tabsKey == '季节' ? '':'none'}}>
                    <Table columns={seasonColumns} dataSource={seasonData} bordered style={{marginRight:"40px"}} pagination={false}/>
                </div>
                <div style={{display:tabsKey == '品类' ? '':'none'}}>
                    <Table columns={categoryColumns} dataSource={categoryData} bordered style={{marginRight:"40px"}} pagination={false}/>
                </div>
                <div style={{display:tabsKey == '价格带' ? '':'none'}}>
                    <Table columns={priceBandColumns} dataSource={priceBandData} bordered style={{marginRight:"40px"}} pagination={false}/>
                </div>
                <div style={{display:tabsKey == 'TOP款' ? '':'none'}}>
                    <Table columns={topBandColumns} dataSource={topData} bordered style={{marginRight:"40px"}} pagination={false}/>
                </div>
                <div style={{display:tabsKey == '商品详情' ? '':'none'}}>
                    <Table columns={commodityColumns} dataSource={commodityData} bordered 
                     locale={{
                        cancelSort: "点击升序",
                        triggerAsc: "点击升序",
                        triggerDesc: "点击降序"
                    }}
                    style={{marginRight:"40px"}} pagination={false} scroll={{ y: 'calc(70vh - 150px)' }}
                    sortDirections={['ascend', 'descend']}
                    onChange={(pagination, filters, sorter)=>{
                        let timeIntervalList = []
                        if(timeInterval.length == 0){
                            timeIntervalList = ['上午','下午','晚上']
                        }else{
                            timeIntervalList = timeInterval
                        }
                        let data = {}
                        data.start_date = startDate
                        data.end_date = endDate
                        data.roleId = cookie.load('role')
                        data.shopList = cookie.load('shopList')
                        data.anchor_list = anchor
                        data.date_detail_list = timeIntervalList
                        data.sort_field = sorter.field
                        data.tiktok_or_volcano = tiktokDict[props.location.pathname.split('/')[2]]
                        if(sorter.order == undefined){
                            data.sort_type = 1
                        } else if(sorter.order == 'ascend'){
                            data.sort_type = 1
                        }else{
                            data.sort_type = 0
                        }
                        setloading(true)
                        return NbAxios(data, "POST", servicePath.getTiktokCommodityOperationalData).then((res, rej) => {
                            setloading(false)
                            if (res.success) {
                                setCommodityData(res.data['table_data']);
                            } else {
                                message.warn(res.errorMsg)
                            }
                        }).catch(e => {
                            setloading(false)
                            message.error("获取商品基础信息汇总数据出错！")
                        })
                    }}
                    />
                </div>
            </Spin>
            

            <Modal
                title="上传抖音商品运营数据"
                visible={isUploadModalVisible}
                onOk={()=>setIsUploadModalVisible(false)}
                onCancel={()=>{
                    setIsUploadModalVisible(false)
                    setFileList([]);
                }}
                footer={[null, null]}
                centered={true}
            >
                <Spin tip="Loading..." spinning={loading}>
                    <Upload
                    action={servicePath.development_uploadSuccess} //上传地址，填错/不填上传图片的时候都会报错
                    // listType="picture-card"                             // 上传列表的内建样式
                    fileList={fileList} //已经上传的文件列表（受控）
                    // onPreview={handleFilePreview}                           //点击文件链接或预览图标时的回调
                    onChange={({ fileList }) => {
                        setFileList(fileList);
                    }} //上传文件改变时的状态
                    multiple = {true}
                    >
                    {fileList.length >= 30 ? null : uploadFileButton}
                    <Text type='danger' >上传文件来自于抖音后台下载</Text>
                    </Upload>
                    <div style={{ marginLeft: 400 }}>
                    <Button type="primary" onClick={() => uploadFile()} disabled={fileList.length >= 1 ?false:true}>
                        上传
                    </Button>
                    </div>
                </Spin>
            </Modal>
        </div>
    )

}


export default Index