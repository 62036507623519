import React, { useEffect, useState } from 'react';
import style from './index.module.css';
import { Button, Checkbox, Tooltip, Radio, Table, Input, Space, Modal, message, Typography, DatePicker} from 'antd';
// 引入高亮组件
import Highlighter from "react-highlight-words";
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import Heatmap from './component/heatmap'
import cookie, { load } from 'react-cookies'
import servicePath from '../../../config/apiUrl';
import Axios from 'axios';
import downExcel from '../../../config/utils/downloadFile';
import NbAxios from '../../../config/utils/nbAxios';
import { DoubleLeftOutlined, CheckCircleOutlined, RightOutlined, DownOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
const {Text} = Typography
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD';
function Index(props) {
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    //定义筛选功能
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 是否是品牌方的Excel
    const [ isBrandFile, setIsBrandFile ] = useState(2)
    // 是否加载中
    const [ loading , setLoading ] = useState(false)
    // 活动id
    const [activityId, setActivityId] = useState('')
    // 计划id
    const [planId, setPlanId] = useState('')
    // 全店是否打开
    const [isShopOpen, setIsShopOpen] = useState(false)
    // 产品线是否打开
    const [isProductOpen, setIsProductOpen] = useState(false)
    // 价格带是否打开
    const [isPriceLineOpen, setIsPriceLineOpen] = useState(false)
    // 产品线
    const [productLineList, setProductLineList] = useState([])
    // 年份
    const [yearList, setYearList] = useState([])
    // 季节
    const [seasonList, setSeasonList] = useState([])
    // 品类
    const [categoryList, setCategoryList] = useState([])
    // 打开热力图
    const [ isHeatmap, setisHeatmap ] = useState(false)
    const [ heatmapPrice , setHeatmapPrice] = useState([])
    const [ heatmapMaxValue, setHeatmapMaxValue ] = useState('')
    const [ heatmapMinValue, setHeatmapMinValue ] = useState('')
    const [ heatmapData, setHeatmapData ] = useState([])
    const [ heatmapCategory, setHeatmapCategory ] = useState([])
    // 全店数据
    const [ allShopDate, setAllShopDate] = useState([])
    const [ allChooseProductList , setAllChooseProductList ] = useState([])
    const [ allChooseYearList , setAllChooseYearList ] = useState([])
    const [ allChooseSeasonList , setAllChooseSeasonList ] = useState([])
    // 品类
    const [ categoryDate , setCategoryDate ] = useState([])
    const [ categoryChooseProductList , setCategoryChooseProductList ] = useState([])
    const [ categoryChooseYearList , setCategoryChooseYearList ] = useState([])
    const [ categoryChooseSeasonList , setCategoryChooseSeasonList ] = useState([])
    // 价格带
    const [ priceDate , setPriceDate ] = useState([])
    const [ priceChooseProductList , setPriceChooseProductList ] = useState([])
    const [ priceChooseYearList , setPriceChooseYearList ] = useState([])
    const [ priceChooseSeasonList , setPriceChooseSeasonList ] = useState([])
    const [ priceChooseCategoryList , setPriceChooseCategoryList ] = useState([])
    // 是否导出数据
    const [ isDownloadFile, setIsDownloadFile] = useState(false)
    // 下载的时间选择
    const [ downloadStartTime , setDownloadStartTime]  = useState(moment().subtract(1, 'M').format('YYYY-MM-DD'))
    const [ downloadEndTime , setDownloadEndTime] = useState(moment().format('YYYY-MM-DD'))
    // 基础表头
    const [baseColumn] = useState([
        {
            title: "款数",
            dataIndex: '款数',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'center'
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'right'
                        }
                    }
                )
            },
            render: (text, record, index) => (
               <span>{text.toLocaleString()}</span>
            )
        },
        {
            title: () => (
                <span>
                    {'备货件数'}
                    <Tooltip
                        title={'天猫库存（批量修改）'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '备货件数',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'center'
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'right'
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <span>{text.toLocaleString()}</span>
            )
        },
        {
            title: () => (
                <span>
                    {'备货货值'}
                    <Tooltip
                        title={'天猫库存*最终到手价'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '备货货值',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'center'
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'right'
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <span>{text.toLocaleString()}</span>
            )
        },
        {
            title: () => (
                <span>
                    {'备货占比'}
                    <Tooltip
                        title={'备货货值在所选范围内的占比'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '备货占比',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'center'
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'right'
                        }
                    }
                )
            },

        },
        {
            title: () => (
                <span>
                    {'平均到手价'}
                    <Tooltip
                        title={'备货货值/备货件数'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '平均到手价',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'center'
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'right'
                        }
                    }
                )
            },
            render: (text, record, index) => (
               <Text style={{textAlign:'right'}}>{text.toLocaleString()}</Text>
            )
        },
        {
            title: () => (
                <span>
                    {'平均到手折扣'}
                    <Tooltip
                        title={'备货货值/备货吊牌货值'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '平均到手折扣',
            width: 130,
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'center'
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'right'
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <Text style={{textAlign:'right'}}>{text.toLocaleString()}</Text>
            )
        },
        {
            title: () => (
                <span>
                    {'备货深度'}
                    <Tooltip
                        title={'备货件数/款数'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '备货深度',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'center'
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'right'
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <Text style={{textAlign:'right'}}>{text.toLocaleString()}</Text>
            )
        },
        {
            title: () => (
                <span>
                    {'尖货款数'}
                    <Tooltip
                        title={'重点款数'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '尖货款数',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'center'
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            textAlign:'right'
                        }
                    }
                )
            },
            render: (text, record, index) => (
               <Text style={{textAlign:'right'}}>{text.toLocaleString()}</Text>
            )
        }
    ])
    // 全店店铺数据表头
    const [allShopColumns] = useState([
        {
            title: "产品线",
            dataIndex: '产品线',
            align: 'center',
        },
        {
            title: "年份季节",
            dataIndex: '年份季节',
            align: 'center',
        },
        ...baseColumn.map(item => {
            return item
        })
    ])
    // 品类表头
    const [categoryColumn] = useState([
        {
            title: "产品线",
            dataIndex: '产品线',
            // width: 40,
            align: 'center',
            ...getColumnSearchProps('产品线'),
        },
        {
            title: "年份季节",
            dataIndex: '年份季节',
            // width: 40,
            align: 'center',
            ...getColumnSearchProps('年份季节'),
        },
        {
            title: "品类",
            dataIndex: '品类',
            // width: 40,
            align: 'center',
            ...getColumnSearchProps('品类'),
        },
        ...baseColumn.map(item => {
            return item
        })
    ])
    // 价格带表头
    const [ priceLineColumn ] = useState([
        {
            title: "产品线",
            dataIndex: '产品线',
            // width: 40,
            align: 'center',
        },
        {
            title: "年份季节",
            dataIndex: '年份季节',
            // width: 40,
            align: 'center',
        },
        {
            title: "品类",
            dataIndex: '品类',
            // width: 40,
            align: 'center',
        },
        {
            title: "价格带",
            dataIndex: '价格带',
            // width: 40,
            align: 'center',
        },
        ...baseColumn.map(item => {
            return item
        })
    ])
    useEffect(()=>{
        setActivityId(props.location.state.activity_id)
        setPlanId(props.location.state.plan_id)
        searchConditionInitialization()
        searchHeatmap()
    },[])

    const searchConditionInitialization = ()=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: props.location.state.activity_id,
            plan_id: props.location.state.plan_id,
        }
        NbAxios(dataProps, "POST", servicePath.searchConditionInitialization).then(res => {
            if (res.success) {
                setCategoryList(res.data['品类']);
                setProductLineList(res.data['产品线']);
                setSeasonList(res.data['款号季节']);
                setYearList(res.data['款号年份']);
                setAllChooseProductList(res.data['产品线'])
                setAllChooseYearList(res.data['款号年份'])
                setAllChooseSeasonList(res.data['款号季节'])
                setCategoryChooseProductList(res.data['产品线'])
                setCategoryChooseYearList(res.data['款号年份'])
                setCategoryChooseSeasonList(res.data['款号季节'])
                setPriceChooseProductList(res.data['产品线'])
                setPriceChooseYearList(res.data['款号年份'])
                setPriceChooseSeasonList(res.data['款号季节'])
                setPriceChooseCategoryList(res.data['品类']);
                searchAllShopData(res.data['款号年份'], res.data['款号季节'],res.data['产品线'])
                searchCategoryData(res.data['款号年份'], res.data['款号季节'],res.data['产品线'])
                searchPriceDate(res.data['款号年份'], res.data['款号季节'],res.data['产品线'],res.data['品类'])
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询条件初始化接口出错,请联系IT')
        })
    }

    const searchHeatmap = ()=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: props.location.state.activity_id,
            plan_id: props.location.state.plan_id,
            category_data: ['半身裙','毛针织衫']
        }
        NbAxios(dataProps, "POST", servicePath.searchHeatmap).then(res => {
            if (res.success) {
               setHeatmapPrice(res.data['price_band']);
               setHeatmapCategory(res.data['category_data']);
               setHeatmapData(res.data['data']);
               setHeatmapMaxValue(res.data['max_value']);
               setHeatmapMinValue(res.data['min_value']);
               
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询条件初始化接口出错,请联系IT')
        })
    }

    const searchAllShopData = (year, season, productList)=>{
        let search = {}
        search["全店-产品线-年份-季节"] = {}
        search["全店-产品线-年份-季节"]['产品线'] = productList
        search["全店-产品线-年份-季节"]['年份'] = year
        search["全店-产品线-年份-季节"]['季节'] = season
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: props.location.state.activity_id,
            plan_id: props.location.state.plan_id,
            search:search
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchTotalInfo).then(res => {
            setLoading(false)
            if (res.success) {
                setAllShopDate(res.data["全店-产品线-年份-季节"]);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询全店-产品线-年份-季节接口出错,请联系IT')
        })
    }

    const searchCategoryData = (year, season, productList)=>{
        let search = {}
        search["产品线-年份-季节-品类"] = {}
        search["产品线-年份-季节-品类"]['产品线'] = productList
        search["产品线-年份-季节-品类"]['年份'] = year
        search["产品线-年份-季节-品类"]['季节'] = season
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: props.location.state.activity_id,
            plan_id: props.location.state.plan_id,
            search:search
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchTotalInfo).then(res => {
            setLoading(false)
            if (res.success) {
                setCategoryDate(res.data["产品线-年份-季节-品类"]);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询产品线-年份-季节-品类接口出错,请联系IT')
        })
    }

    const searchPriceDate = (year, season, productList, category)=>{
        let search = {}
        search["产品线-年份-季节-品类-价格带"] = {}
        search["产品线-年份-季节-品类-价格带"]['产品线'] = productList
        search["产品线-年份-季节-品类-价格带"]['年份'] = year
        search["产品线-年份-季节-品类-价格带"]['季节'] = season
        search["产品线-年份-季节-品类-价格带"]['品类'] = category
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: props.location.state.activity_id,
            plan_id: props.location.state.plan_id,
            search:search
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchTotalInfo).then(res => {
            setLoading(false)
            if (res.success) {
                setPriceDate(res.data["产品线-年份-季节-品类-价格带"]);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询产品线-年份-季节-品类-价格带接口出错,请联系IT')
        })
    }

    return (
        <div className={style.externalContent}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type="primary" danger icon={<DoubleLeftOutlined />} onClick={()=>{
                    props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/choicePricing/operate`, 'state': {'plan_id': planId, 'activity_id': activityId} })
                }}>返回详情</Button>
                <Button type="primary" icon={<CheckCircleOutlined />} onClick={()=>{
                    setIsDownloadFile(true)
                }}>导出结果</Button>
            </div>
            <div style={{ width: "100%", height: "32px", display: "flex", border: "1px solid #A6A6A6", backgroundColor: "#f1f1f1", marginTop: "6px", alignItems: "center", paddingLeft: "10px" }} onClick={() => {
                if (isShopOpen) {
                    setIsShopOpen(false)
                } else {
                    setIsShopOpen(true)
                }
            }}>
                {
                    isShopOpen ? <DownOutlined style={{ fontSize: "15px", marginRight: "10px" }} /> : <RightOutlined style={{ fontSize: "15px", marginRight: "10px" }} />
                }
                <span>{"全店-产品线-年份-季节"}</span>
            </div>
            <div style={{ display: isShopOpen ? "" : "none", height: "116px", border: "1px solid #ccc", padding: "10px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: "80px" }}>产品线</div>
                            <div>
                                {
                                    productLineList.map(item => {
                                        return <Checkbox style={{ width: "120px" }} checked={allChooseProductList.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...allChooseProductList]
                                                obj.push(item)
                                                setAllChooseProductList(obj)
                                            } else {
                                                let obj = [...allChooseProductList]
                                                obj.remove(item)
                                                setAllChooseProductList(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: "80px" }}>年份</div>
                            <div>
                                {
                                    yearList.map(item => {
                                        return <Checkbox style={{ width: "120px" }} checked={allChooseYearList.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...allChooseYearList]
                                                obj.push(item)
                                                setAllChooseYearList(obj)
                                            } else {
                                                let obj = [...allChooseYearList]
                                                obj.remove(item)
                                                setAllChooseYearList(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: "80px" }}>季节</div>
                            <div>
                                {
                                    seasonList.map(item => {
                                        return <Checkbox style={{ width: "120px" }} checked={allChooseSeasonList.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...allChooseSeasonList]
                                                obj.push(item)
                                                setAllChooseSeasonList(obj)
                                            } else {
                                                let obj = [...allChooseSeasonList]
                                                obj.remove(item)
                                                setAllChooseSeasonList(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button disabled={loading} size="small" style={{ border: '1px solid #C8EAFF', color: "#2A82E4" }} onClick={()=>{
                            let search = {}
                            search["全店-产品线-年份-季节"] = {}
                            search["全店-产品线-年份-季节"]['产品线'] = allChooseProductList
                            search["全店-产品线-年份-季节"]['年份'] = allChooseYearList
                            search["全店-产品线-年份-季节"]['季节'] = allChooseSeasonList
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                activity_id: props.location.state.activity_id,
                                plan_id: props.location.state.plan_id,
                                search:search
                            }
                            setLoading(true)
                            NbAxios(dataProps, "POST", servicePath.searchTotalInfo).then(res => {
                                setLoading(false)
                                if (res.success) {
                                    setAllShopDate(res.data["全店-产品线-年份-季节"]);
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            }).catch(err => {
                                setLoading(false)
                                message.warn('查询全店-产品线-年份-季节接口出错,请联系IT')
                            })
                        }}>查询</Button>
                    </div>
                </div>

            </div>
            <Table loading={loading} dataSource={allShopDate} columns={allShopColumns} size="small" pagination={false}/>
            <div style={{ width: "100%", height: "32px", display: "flex", border: "1px solid #A6A6A6", backgroundColor: "#f1f1f1", marginTop: "6px", alignItems: "center", paddingLeft: "10px" }} onClick={() => {
                if (isProductOpen) {
                    setIsProductOpen(false)
                } else {
                    setIsProductOpen(true)
                }
            }}>
                {
                    isProductOpen ? <DownOutlined style={{ fontSize: "15px", marginRight: "10px" }} /> : <RightOutlined style={{ fontSize: "15px", marginRight: "10px" }} />
                }
                <span>{"产品线-年份-季节-品类"}</span>
            </div>
            <div style={{ display: isProductOpen ? "" : "none", height: "116px", border: "1px solid #ccc", padding: "10px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: "80px" }}>产品线</div>
                            <div>
                                {
                                    productLineList.map(item => {
                                        return <Checkbox style={{ width: "120px" }} checked={categoryChooseProductList.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...categoryChooseProductList]
                                                obj.push(item)
                                                setCategoryChooseProductList(obj)
                                            } else {
                                                let obj = [...categoryChooseProductList]
                                                obj.remove(item)
                                                setCategoryChooseProductList(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: "80px" }}>年份</div>
                            <div>
                                {
                                    yearList.map(item => {
                                        return <Checkbox style={{ width: "120px" }} checked={categoryChooseYearList.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...categoryChooseYearList]
                                                obj.push(item)
                                                setCategoryChooseYearList(obj)
                                            } else {
                                                let obj = [...categoryChooseYearList]
                                                obj.remove(item)
                                                setCategoryChooseYearList(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: "80px" }}>季节</div>
                            <div>
                                {
                                    seasonList.map(item => {
                                        return <Checkbox style={{ width: "120px" }} checked={categoryChooseSeasonList.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...categoryChooseSeasonList]
                                                obj.push(item)
                                                setCategoryChooseSeasonList(obj)
                                            } else {
                                                let obj = [...categoryChooseSeasonList]
                                                obj.remove(item)
                                                setCategoryChooseSeasonList(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button size="small" style={{ border: '1px solid #C8EAFF', color: "#2A82E4" }} onClick={()=>{
                            let search = {}
                            search["产品线-年份-季节-品类"] = {}
                            search["产品线-年份-季节-品类"]['产品线'] = categoryChooseProductList
                            search["产品线-年份-季节-品类"]['年份'] = categoryChooseYearList
                            search["产品线-年份-季节-品类"]['季节'] = categoryChooseSeasonList
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                activity_id: props.location.state.activity_id,
                                plan_id: props.location.state.plan_id,
                                search:search
                            }
                            setLoading(true)
                            NbAxios(dataProps, "POST", servicePath.searchTotalInfo).then(res => {
                                setLoading(false)
                                if (res.success) {
                                    setCategoryDate(res.data["产品线-年份-季节-品类"]);
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            }).catch(err => {
                                setLoading(false)
                                message.warn('查询产品线-年份-季节-品类接口出错,请联系IT')
                            })
                        }}>查询</Button>
                    </div>
                </div>
            </div>
            <Table dataSource={categoryDate} columns={categoryColumn} size="small" pagination={false} scroll={{y:300}} />
            <div style={{ width: "100%", height: "32px", display: "flex", border: "1px solid #A6A6A6", backgroundColor: "#f1f1f1", marginTop: "6px", alignItems: "center", paddingLeft: "10px" }} onClick={() => {
                if (isPriceLineOpen) {
                    setIsPriceLineOpen(false)
                } else {
                    setIsPriceLineOpen(true)
                }
            }}>
                {
                    isPriceLineOpen ? <DownOutlined style={{ fontSize: "15px", marginRight: "10px" }} /> : <RightOutlined style={{ fontSize: "15px", marginRight: "10px" }} />
                }
                <span>{"产品线-年份-季节-品类-价格带"}</span>
            </div>
            <div style={{ display: isPriceLineOpen ? "" : "none", height: "130px", border: "1px solid #ccc", padding: "10px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: "80px" }}>产品线</div>
                            <div>
                                {
                                    productLineList.map(item => {
                                        return <Checkbox style={{ width: "120px" }} checked={priceChooseProductList.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...priceChooseProductList]
                                                obj.push(item)
                                                setPriceChooseProductList(obj)
                                            } else {
                                                let obj = [...priceChooseProductList]
                                                obj.remove(item)
                                                setPriceChooseProductList(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: "80px" }}>年份</div>
                            <div>
                                {
                                    yearList.map(item => {
                                        return <Checkbox style={{ width: "120px" }} checked={priceChooseYearList.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...priceChooseYearList]
                                                obj.push(item)
                                                setPriceChooseYearList(obj)
                                            } else {
                                                let obj = [...priceChooseYearList]
                                                obj.remove(item)
                                                setPriceChooseYearList(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: "80px" }}>季节</div>
                            <div>
                                {
                                    seasonList.map(item => {
                                        return <Checkbox style={{ width: "120px" }} checked={priceChooseSeasonList.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...priceChooseSeasonList]
                                                obj.push(item)
                                                setPriceChooseSeasonList(obj)
                                            } else {
                                                let obj = [...priceChooseSeasonList]
                                                obj.remove(item)
                                                setPriceChooseSeasonList(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: "80px" }}>品类</div>
                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                {
                                    categoryList.map(item => {
                                        return <Checkbox style={{ width: "120px" }} checked={priceChooseCategoryList.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...priceChooseCategoryList]
                                                obj.push(item)
                                                setPriceChooseCategoryList(obj)
                                            } else {
                                                let obj = [...priceChooseCategoryList]
                                                obj.remove(item)
                                                setPriceChooseCategoryList(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                        <Button size="small" style={{ border: '1px solid #C8EAFF', color: "#2A82E4" }}  onClick={()=>{
                            let search = {}
                            search["产品线-年份-季节-品类-价格带"] = {}
                            search["产品线-年份-季节-品类-价格带"]['产品线'] = priceChooseProductList
                            search["产品线-年份-季节-品类-价格带"]['年份'] = priceChooseYearList
                            search["产品线-年份-季节-品类-价格带"]['季节'] = priceChooseSeasonList
                            search["产品线-年份-季节-品类-价格带"]['品类'] = priceChooseCategoryList
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                activity_id: props.location.state.activity_id,
                                plan_id: props.location.state.plan_id,
                                search:search
                            }
                            setLoading(true)
                            NbAxios(dataProps, "POST", servicePath.searchTotalInfo).then(res => {
                                setLoading(false)
                                if (res.success) {
                                    setPriceDate(res.data["产品线-年份-季节-品类-价格带"]);
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            }).catch(err => {
                                setLoading(false)
                                message.warn('查询产品线-年份-季节-品类-价格带接口出错,请联系IT')
                            })
                        }}>查询</Button>
                        <a style={{textDecoration:'underline'}} onClick={()=>{
                            setisHeatmap(true)
                        }}>查看热力图</a>
                    </div>
                </div>
            </div>
            <Table dataSource={priceDate} columns={priceLineColumn} size="small" pagination={false} scroll={{y:300}}/>

            <Modal
                title='品类价格带分布'
                visible={isHeatmap}
                cancelText="取消"
                okText="确定"
                onCancel={()=>{
                    setisHeatmap(false)
                }}
                onOk={()=>{
                    setisHeatmap(false)
                }}
                footer={null}
                width={1000}
            >
                <Heatmap price={heatmapPrice} category={heatmapCategory} minValue={heatmapMinValue} maxValue={heatmapMaxValue} heatmapValue={heatmapData}/>
            </Modal>

            <Modal
                title="导出Excel"
                visible={isDownloadFile}
                onCancel={() => {
                    setIsDownloadFile(false)
                }}
                footer={[]}
                centered={true}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Radio.Group value={isBrandFile} onChange={(e) => {
                        setIsBrandFile(e.target.value)
                    }}>
                        {/* <Radio value={1}>导出给品牌方的Excel</Radio> */}
                        <Radio value={2}>导出上传平台的Excel</Radio>
                    </Radio.Group>
                </div>
                <div style={{ display: isBrandFile == 1 ? 'flex' : 'none', justifyContent: 'center', marginTop: 30 }}>
                    <b style={{ marginRight: 10, marginTop: 5 }}>比较活动时间:</b>
                    <RangePicker
                        disabledDate={disabledDate}
                        locale={locale}
                        value={[downloadStartTime != '' ? moment(downloadStartTime, dateFormat) : '', downloadEndTime != '' ? moment(downloadEndTime, dateFormat) : '']}
                        style={{ borderRadius: 30 }}
                        onChange={(value, dateString) => {
                            if (dateString[0] == '') {
                                message.warn('请选择合理的 时间')
                            } else {
                                setDownloadStartTime(dateString[0]);
                                setDownloadEndTime(dateString[1]);
                            }

                        }}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                    <Button disabled={effective ? true : false} type="primary" onClick={() => {
                        if (isBrandFile == 1) {
                            if (downloadStartTime == '') {
                                message.warn('请选择近期活动时间')
                            } else {
                                setEffective(1)
                                let key = "download"
                                message.loading({ content: '开始下载数据...', key, duration: 100 })
                                let dataProps = {
                                    roleId: cookie.load('role'),
                                    shopList: cookie.load('shopList'),
                                    shop_name: props.history.location.pathname.split('/')[2],
                                    activity_id: props.location.state.activity_id,
                                    plan_id: props.location.state.plan_id,
                                    start_time: downloadStartTime,
                                    end_time: downloadEndTime,
                                    creator: cookie.load('name')
                                }
                                Axios({
                                    method: 'POST',
                                    url: servicePath.downloadSelectionPricingResultDetail,
                                    data: dataProps,
                                    responseType: "blob",
                                    headers: {
                                        'authorization': cookie.load('token')
                                    },
                                }).then(res => {
                                    setEffective(0)
                                    downExcel(res)
                                    setIsDownloadFile(false)
                                    message.success({ content: "导出成功！", key })
                                }).catch(e => {
                                    setEffective(0)
                                    message.error({ content: '文件制作出错，请联系it同事！', key })
                                })
                            }
                        } else {
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                activity_id: props.location.state.activity_id,
                                plan_id: props.location.state.plan_id,
                                creator: cookie.load('name')
                            }
                            Axios({
                                data: dataProps,
                                method: 'POST',
                                url: servicePath.downloadChooseAndPriceResult,
                                responseType: "blob",
                                headers: {
                                    'Content-Type': 'application/json',  //如果写成contentType会报错
                                    'authorization': cookie.load('token')
                                }
                            }).then(
                                res => {
                                    downExcel(res)
                                }
                            ).catch(e => {
                                message.error({ content: '文件制作出错，请联系it同事！' })
                            })
                        }
                    }}>下载</Button>
                </div>
            </Modal>
        </div>
    )

}

export default Index