import React, { useState, useEffect } from 'react';
import style from './index.module.css';
import { Sketch } from '@uiw/react-color'
import moment from 'moment'
// 引入高亮组件
import Highlighter from "react-highlight-words";
import servicePath from '../../../config/apiUrl';
import locale from 'antd/es/date-picker/locale/zh_CN';
import RelationChart from './components/relationChart'
import AddTags from './components/addTags'
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios';
import downExcel from "../../../config/utils/downloadFile"
import * as echarts from 'echarts/core';
import axios from 'axios'
import { Tabs, Spin, Input, Button, Modal, Select, Image, Popconfirm, Popover, Table, DatePicker, message, Upload, Progress, Drawer, Form, Radio, TreeSelect, Typography, Cascader, Tag, Tooltip } from 'antd'
import { SearchOutlined, DownOutlined, PlusSquareOutlined, UploadOutlined, DownloadOutlined, PlusOutlined, MinusSquareOutlined, EyeOutlined, CheckOutlined, FileSearchOutlined } from '@ant-design/icons';
import { buildSegCompareObj, parseDragMeta } from '@fullcalendar/react';
import { isRegExp, merge, over, set } from 'lodash';
const { TabPane } = Tabs
const { Text } = Typography
const { Option, OptGroup } = Select
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD';
const yearFormat = 'YYYY';
function Index(props) {
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    };

    // 表头映射
    let columnDict = {
        "属性名": "name",
        "筛选策略": "search_tactics",
        "销售属性": "is_sale",
        "数据类型": "data_type",
        "属性值": "property_value",
    }
    // 店铺映射
    let shopDict = {
        "JORYA": "JORYA",
        "cr": "CAROLINE",
        "an": "ANMANI"
    }
    const imgDict = {
        'JORYA': 'JORYA',
        'ANMANI': 'an',
        'CAROLINE': 'cr',
        'EIN': 'ein',
    }
    // 数据类型
    const [dataType] = useState({
        '范围筛选': ['数字', '日期'],
        '枚举筛选': ['字符', '日期', '数字'],
        '下拉筛选': ['字符', '数字'],
        '模糊筛选': ['字符'],
        'undefined': []
    })
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 10 },
    };
    // 设定标签的策略设置
    const tagsOptionsList = [
        { label: '添加', value: '1' },
        { label: '删除', value: '2' },
        { label: '覆盖', value: '3' },
    ];
    // 设定标签策略
    const [tagsOptions, setTagsOptions] = useState('')
    // 属性
    const [attributesForm] = Form.useForm()
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    const [updataResultColumns] = useState([
        {
            title: "SPU",
            dataIndex: "SPU",
            key: "SPU",
            align: "center",
        },
        {
            title: "商品ID",
            dataIndex: "商品ID",
            key: "商品ID",
            align: "center",
        },
        {
            title: "错误信息",
            dataIndex: "error_msg",
            key: "error_msg",
            align: "center",
        },
    ])
    // 页数
    const [page, setPage] = useState(1)

    // 总数
    const [total, setTotal] = useState('')
    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    //定义筛选功能
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        查询
                    </Button>
                </div>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    // 导出菜单
    const exportMenu = (
        <div>
            {/* 暂不支持 */}
            {/* <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                }}>导出当前</Button>
            </div> */}
            <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    setIsExportAllGoods(true)
                    setExportFlag(0)
                }}>导出全部</Button>
            </div>
        </div>
    )
    // 导出模型菜单
    const exportMoldelMenu = (
        <div>
            {/* <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                }}>导出当前</Button>
            </div> */}
            <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    setIsExportAllModel(true)
                    setExportFlag(0)
                }}>全部模型</Button>
            </div>
        </div>
    )
    // 添加商品菜单
    const addProduct = (
        <div>
            <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    setIsUploadFile(true)
                    setExportFlag(0)
                }}>文件导入</Button>
            </div>
            <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    searchPropertyValueByInfo()
                    setIsSingleEntry(true)
                }}>单款录入</Button>
            </div>
        </div>
    )
    // 批量操作
    const bulkOperations = (
        <div>
            <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    if (rowSelection.selectedRowKeys.length) {
                        setIsModCategory(true)
                        setSelectUpdateFields('')
                        setUpdateFieldsType('')
                        setCategoryUpdate([])
                    } else {
                        message.warning("批量修改需先勾选商品")
                    }
                }}>批量修改</Button>
            </div>
            <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    if (rowSelection.selectedRowKeys.length) {
                        setIsModTime(true)
                    } else {
                        message.warning("修改上架时间需先勾选商品")
                    }
                }}>修改上架时间</Button>
            </div>
        </div>
    )
    // 标签
    const labelContent = (
        <div>
            <div>
                {/* <Button size='small' style={{width: "92px"}} onClick={() => {
                    let obj = [...allTagsList]
                    setAllChooseTagsList(obj)
                    setIsFilterTags(true)
                }}>筛选标签</Button> */}
            </div>
            <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    if (rowSelection.selectedRowKeys.length) {
                        let obj = [...tagsData]
                        setAllChooseTagsList(obj)
                        setIsSettingTags(true)
                        setChooseTagsList([])
                        setTagsOptions('')
                    } else {
                        message.warning("设定标签前需先勾选商品")
                    }
                }}>设定标签</Button>
            </div>
            <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    setIsEditTags(true)
                }}>编辑标签</Button>
            </div>
            <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    setIsTagUploadFile(true)
                    setExportFlag(0)
                    setTagsOptions([])
                }}>文件设定</Button>
            </div>
        </div>
    )

    // 标签名字
    const [tagName, setTagName] = useState('')
    // 标签颜色
    const [tagColor, setTagColor] = useState('#ccc')
    // 是否添加属性标签
    const [addFlag, setAddFlag] = useState(0)
    // 添加标签
    const addTags = () => {
        return <div>
            <div style={{ display: 'flex' }}>
                <span style={{ marginRight: "20px", width: '60px', textAlign: "right" }}>名字</span>
                <Input size="small" placeholder='请输入名字' style={{ width: "120px", marginRight: "20px" }} value={tagName} onChange={(e) => {
                    let patrn = new RegExp("(^[\u4E00-\u9FA5A-Za-z0-9 ]+$)");
                    if (!patrn.test(e.target.value) && e.target.value) {
                        message.warning('标签名只能填写汉字、数字、字母、空格')
                    } else {
                        setTagName(e.target.value)
                    }

                }} />

            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <span style={{ marginRight: "20px", width: '60px', textAlign: "right" }}>预览</span>
                <Popover placement="right" content={<Sketch color={tagColor} onChange={(color) => {
                    setTagColor(color.hexa);
                }} />} zIndex={10005}>
                    <div style={{ border: `1px solid ${tagColor}`, color: `${tagColor}`, backgroundColor: `${tagColor.substring(0, 7) + '19'}`, textAlign: 'center', borderRadius: "3px", padding: "0 3px", fontSize: "10px", height: "20.84px" }}>{tagName}</div>
                </Popover>
            </div>
            <div style={{ marginTop: "10px" }}>
                <Button style={{ marginLeft: "80px", borderRadius: "2px" }} size="small" type="primary" onClick={() => {
                    // 校验字符串是否全为空格
                    let reg = /^\s+$/g
                    if (tagName == '') {
                        message.warning("标签名不能为空")
                    } else if (reg.test(tagName)) {
                        message.warning("标签名不能全是空格")
                    } else {
                        addNewTag()
                    }
                }}>确定</Button>
            </div>
        </div>
    }
    // 标签列表
    const [tagsList, setTagsList] = useState([
        {
            color: "#F56464",
            name: "运营中1"
        },
        {
            color: "#B7EB8F",
            name: "主链接2"
        },
        {
            color: "#F56464",
            name: "运营中3"
        },
        {
            color: "#B7EB8F",
            name: "主链接4"
        },
        {
            color: "#F56464",
            name: "运营中5"
        },
        {
            color: "#B7EB8F",
            name: "主链接6"
        }
    ])
    // 总标签
    const [allTagsList, setAllTagsList] = useState([
        {
            color: "#F56464",
            name: "运营中1"
        },
        {
            color: "#B7EB8F",
            name: "主链接2"
        },
        {
            color: "#F56464",
            name: "运营中3"
        },
        {
            color: "#B7EB8F",
            name: "主链接4"
        },
        {
            color: "#F56464",
            name: "运营中5"
        },
        {
            color: "#B7EB8F",
            name: "主链接6"
        }
    ])
    // 月份集合
    const [monthList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
    // 总的选择标签
    const [allChooseTagsList, setAllChooseTagsList] = useState([])
    // 选择标签
    const [chooseTagsList, setChooseTagsList] = useState([])
    // 添加标签
    const addTagsList = (record, index) => {
        return <div style={{ width: "150px" }}>
            <Input size="small" placeholder="请输入关键字" suffix={<SearchOutlined />} onChange={(e) => {
                setTagsList(tagsData.filter(item => {
                    return item.name.indexOf(e.target.value) != -1
                }))
            }} />
            <div style={{ height: "75px", overflow: "auto", marginTop: "5px" }}>
                {
                    tagsList.map(item => {
                        if (record['标签'].map(obj => {
                            return obj.id
                        }).indexOf(item.id) == -1) {
                            return <Tooltip title={item.name} placement="right">
                                <div style={{ textAlign: "center", height: "25px", cursor: "pointer", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} onClick={() => {
                                    record['标签'].push(item)
                                    let dataProps = {
                                        shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                        goods_only_flag: [record['goods_only_flag']],
                                        tags: record['标签'],
                                        roleId: cookie.load('role'),
                                        shopList: cookie.load('shopList'),
                                        tagsOptions: 1
                                    }
                                    setLoading(true)
                                    NbAxios(dataProps, "POST", servicePath.setTagsForGoods).then(res => {
                                        setLoading(false)
                                        if (res.success) {
                                            message.success("添加成功")
                                            // dataSource[index]['标签'].push(item)
                                            // searchGoodsByKeysOrTags()
                                        } else {
                                            record['标签'].remove(item)
                                            message.warn({ content: res.errorMsg })
                                        }
                                    }).catch((err) => {
                                        record['标签'].remove(item)
                                        setLoading(false)
                                        // 获取500错误
                                        message.warn('商品设定标签报错，请联系IT帮忙解决')
                                    })
                                }}>{item.name}</div>
                            </Tooltip>
                        }

                    })
                }
            </div>
            <div style={{ borderTop: "1px solid #ccc", display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
                <Button size="small" icon={<PlusSquareOutlined />} type="primary" onClick={() => {
                    setTableRows(index)
                    setIsEditTags(true)
                }}>添加标签</Button>
            </div>
        </div>
    }
    // 详情添加标签
    const detailAddTags = () => {
        return <div style={{ width: "150px" }}>
            <Input size="small" placeholder="请输入关键字" suffix={<SearchOutlined />} onChange={(e) => {
                setTagsList(tagsData.filter(item => {
                    return item.name.indexOf(e.target.value) != -1
                }))

            }} />
            <div style={{ height: "75px", overflow: "auto", marginTop: "5px" }}>
                {
                    tagsList.map(item => {
                        if (goodsDetailData.tags.map(obj => {
                            return obj.id
                        }).indexOf(item.id) == -1) {
                            return <Tooltip title={item.name} placement="right">
                                <div style={{ textAlign: "center", height: "25px", cursor: "pointer", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} onClick={() => {
                                    let data = Object.assign({}, goodsDetailData);
                                    data.tags.push(item)
                                    setGoodsDetailData(data)
                                }}>{item.name}</div>
                            </Tooltip>
                        }
                    })
                }
            </div>
            <div style={{ borderTop: "1px solid #ccc", display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
                <Button size="small" icon={<PlusSquareOutlined />} type="primary" onClick={() => {
                    setIsEditTags(true)
                }}>添加标签</Button>
            </div>
        </div>
    }
    // 单款录入标签
    const singleAddTags = () => {
        return <div style={{ width: "150px" }}>
            <Input size="small" placeholder="请输入关键字" suffix={<SearchOutlined />} onChange={(e) => {
                setTagsList(tagsData.filter(item => {
                    return item.name.indexOf(e.target.value) != -1
                }))

            }} />
            <div style={{ height: "75px", overflow: "auto", marginTop: "5px" }}>
                {
                    tagsList.map(item => {
                        if (singleTagList.map(obj => {
                            return obj.id
                        }).indexOf(item.id) == -1) {
                            return <Tooltip title={item.name} placement="right">
                                <div style={{ textAlign: "center", height: "25px", cursor: "pointer", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} onClick={() => {
                                    let data = [...singleTagList]
                                    data.push(item)
                                    setSingleTagList(data)
                                }}>{item.name}</div>
                            </Tooltip>
                        }
                    })
                }
            </div>
            <div style={{ borderTop: "1px solid #ccc", display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
                <Button size="small" icon={<PlusSquareOutlined />} type="primary" onClick={() => {
                    setIsEditTags(true)
                }}>添加标签</Button>
            </div>
        </div>
    }
    // 关系图数据
    const [graph, setGraph] = useState({
        "nodes": [],
        "links": [],
        "categories": [],
    })
    // 标签值
    const [tabKey, setTabKey] = useState('productList')
    // 是否有效
    const [loading, setLoading] = useState(false)
    // ID
    const [id, setId] = useState('')
    // spu
    const [spu, setSpu] = useState('')
    // 标签
    const [tagsValue, setTagsValue] = useState([])
    // 高级筛选标签
    const [filterTags, setFilterTags] = useState([])
    // 是否筛选
    const [isFilter, setIsFilter] = useState(false)
    // 是否新增分组
    const [isAddGroup, setIsAddGroup] = useState(false)
    // 是否添加属性
    const [isAddProperty, setIsAddProperty] = useState(false)
    // 是否商品详情
    const [isDetails, setIsDetails] = useState(false)
    // 是否是修改详情
    const [isEditDetails, setIsEditDetails] = useState(false)
    // 是否上传文件
    const [isUploadFile, setIsUploadFile] = useState(false)
    // 是否上传标签文件
    const [isTagUploadFile, setIsTagUploadFile] = useState(false)
    // 是否导出所有模型
    const [isExportAllModel, setIsExportAllModel] = useState(false)
    // 是否导出全部商品
    const [isExportAllGoods, setIsExportAllGoods] = useState(false)
    // 批量修改结果弹窗
    const [isUpdateResult, setIsUpdateResult] = useState(false)
    // 可供批量修改字段
    const [updateFields, setUpdateFields] = useState([])
    // 批量修改字段选择值
    const [selectUpdateFields, setSelectUpdateFields] = useState("")
    // 批量修改输入类型
    const [updateFieldsType, setUpdateFieldsType] = useState("")
    // 批量修改结果
    const [updateResultData, setUpdateResultData] = useState(
        {
            total_success: "",
            total_fail: "",
            alter_fail_goods: []
        }
    )
    // 是否在导出中
    const [exportFlag, setExportFlag] = useState(0)
    // 是否单款录入
    const [isSingleEntry, setIsSingleEntry] = useState(false)
    // 是否展示商品详情
    const [isGoodsDetail, setIsGoodsDetail] = useState(false)
    // 商品详情数据
    const [goodsDetailData, setGoodsDetailData] = useState({
        "base": {
            "商品ID": "",
            "吊牌价": "",
            "SPU": "",
            "上新价": "",
            "盘货品类": [],
            "上架时间": "",
            "款号年份": "",
            "上市月份": ""
        },

        "detail": [],

        "tags": []
    })
    // 是否修改品类
    const [isModCategory, setIsModCategory] = useState(false)
    // 是否筛选标签
    const [isFilterTags, setIsFilterTags] = useState(false)
    // 设定标签
    const [isSettingTags, setIsSettingTags] = useState(false)
    // 编辑标签
    const [isEditTags, setIsEditTags] = useState(false)
    // 是否修改上架时间
    const [isModTime, setIsModTime] = useState(false)
    // 新增品类
    const [newCategory, setNewCategory] = useState(false)
    // 编辑品类
    const [editCategory, setEditCategory] = useState(false)
    // 品类关系表
    const [categoryRelation, setCategoryRelation] = useState(false)
    // 添加属性
    const [isAddAttributes, setIsAddAttributes] = useState(false)
    // 编辑属性
    const [isEditAttributes, setIsEditAttributes] = useState(false)
    // 删除分组
    const [deleteGroup, setDeleteGroup] = useState(false)
    // 品类关系图
    const [categoryRelationPicture, setCategoryRelationPicture] = useState(false)
    // 上传文件进度条
    const [uploadProgress, setUploadProgress] = useState(0)
    // 文档文件集合
    const [fileList, setFileList] = useState([])
    // 标签文档文件集合
    const [tagFileList, setTagFileList] = useState([])
    // 表格行数
    const [tabelRows, setTableRows] = useState('')
    // 表头字段
    const [filed] = useState(['商品ID', 'SPU', '标签', '盘货品类', '款号年份', '吊牌价', '上新价', '款号季节', '上架时间', '操作'])
    // 表头
    const [column, setColumn] = useState([])
    // 数据源
    const [dataSource, setDataSource] = useState([
        // {
        //     "SPU": "",
        //     "goods_only_flag": "",
        //     "盘货品类": "",
        //     "款号年份": "",
        //     "上市月份": "",
        //     "上新价": "",
        //     "上架时间": "",
        //     "吊牌价": "",
        //     "商品ID": "",
        //     "描述": "",
        //     "标签": []
        // }
    ])
    // 品类字典
    const [categoryFiled] = useState(['品类分组', '品类编号', '品类名称', '父节点', '操作'])
    // 品类表头
    const [categoryColumn, setCategoryColumn] = useState([])
    // 品类数据
    const [categoryData, setCategoryData] = useState([
        // {
        //     'id': '',
        //     '品类分组': "",
        //     '品类编号': "",
        //     "品类名称": "",
        //     "children": [{
        //         'pid': "",
        //         'label': "",
        //         "detail": {},
        //     }, {
        //         'pid': "",
        //         'label': "",
        //         "detail": {},
        //     }]
        // }
    ])
    // 品类单行数据
    const [categoryRowData, setCategoryRowData] = useState({
        'id': '',
        '品类分组': "",
        '品类编号': "",
        "品类名称": "",
        "fgroup_name": null,
        "fid": null,
        "flevel": null,
        "ford": null,
        "fpid": null,
        "ftitle": null,
        "group_name": null,
        "ord": null,
        "pid": '',
        "children": [{
            'pid': "",
            'label': "",
            "detail": {},
        }, {
            'pid': "",
            'label': "",
            "detail": {},
        }]
    })
    // 品类分组选择值
    const [categoryLevelSelect, setCategoryLevelSelect] = useState('')
    // 品类名称输入值
    const [categoryName, setCategoryName] = useState('')
    // 品类分组选择值
    const [categoryGroup, setCategoryGroup] = useState([])
    // 品类取值列表
    const [categoryValueList, setCategoryValueList] = useState([])
    // 品类取值选择值
    const [categoryValue, setCategoryValue] = useState([])
    // 品类关系字段
    const [categoryRelationFiled, setCategoryRelationFiled] = useState([])
    const [categoryRelationColumn, setCategoryRelationColumn] = useState([])
    const [categoryRelationData, setCategoryRelationData] = useState([{
        'L1': "女装/女士精品",
        'L2': "连衣裙",
        'L3': "连衣裙",
    }, {
        'L1': "女装/女士精品",
        'L2': "衬衫",
        'L3': "衬衫",
    }])
    // 规格字段
    const [specificationFiled] = useState(["属性名", "筛选策略", "销售属性", "数据类型", "属性值", "操作"])
    // 规格数据
    const [specificationData, setSpecificationData] = useState([
        // {
        //     title: '',
        //     pmid: '',
        //     property_model: [
        //     ]

        // }
    ])
    // 临时接受新增分组数据
    const [temporaryAddGroup, setTemporaryAddGroup] = useState([])
    // 分组名称下拉框取值
    const [groupData, setGroupData] = useState([])
    // 分组名称选中值
    const [groupValue, setGroupValue] = useState('')
    // 添加属性下拉框取值
    const [propertyData, setPropertyData] = useState([])
    // 添加属性选中值
    const [propertyValue, setPropertyValue] = useState('')
    // 错误信息
    const [errorMsg, setErrorMsg] = useState("请上传文件")
    // 错误信息
    const [tagErrorMsg, setTagErrorMsg] = useState("请上传文件")
    // 添加属性临时值
    const [temporaryProperty, setTemporaryProperty] = useState([])
    // 规格表头
    const [specificationColumn, setSpecificationColumn] = useState([])
    // 筛选策略集
    const [filterList, setFilterList] = useState(['范围筛选', '枚举筛选', '下拉筛选', '模糊筛选'])
    // 选择的id
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 标签
    const [tags, setTags] = useState([])
    // 范围数字
    const [scopeNum1, setScopeNum1] = useState('')
    const [scopeNum2, setScopeNum2] = useState('')
    const [scopeStartTime, setScopeStartTime] = useState('')
    const [scopeEndTime, setScopeEndTime] = useState('')
    // 品类级别
    const [categoryLevel, setCategoryLevel] = useState(['1', '2', '3'])
    // 单款录入
    const [categoryList, setCategoryList] = useState([{
        value: 'zhejiang',
        label: 'Zhejiang',
        children: [
            {
                value: 'hangzhou',
                label: 'Hangzhou',
            },
        ],
    },
    {
        value: 'jiangsu',
        label: 'Jiangsu',
        children: [
            {
                value: 'nanjing',
                label: 'Nanjing',
            },
        ],
    },
    ])
    const [singleId, setSingleId] = useState('')
    const [singleSpu, setSingleSpu] = useState('')
    const [singleTagPrice, setSingleTagPrice] = useState('')
    const [singleNewPrice, setSingleNewPrice] = useState('')
    const [singleCategory, setSingleCategory] = useState('')
    const [singleAddTime, setSingleAddTime] = useState('')
    const [singleListingYear, setSingleListingYear] = useState('')
    const [singleListingMonth, setSingleListingMonth] = useState('')
    const [singleTagList, setSingleTagList] = useState([])
    // 高级筛选数据
    const [highSearchData, setHighSearchData] = useState([{
        "id": '',
        "group": "",
        "name": "",
        "is_sale": '',
        "ord": '',
        "search_tactics": "",
        "data_type": "",
        "value": ""

    }])
    // 高级筛选开始时间
    const [highStartTime, setHighStartTime] = useState('')
    // 高级筛选结束时间
    const [highEndTime, setHighEndTime] = useState('')
    // 修改品类值
    const [categoryUpdate, setCategoryUpdate] = useState([])
    // 修改上架时间
    const [timeUpdate, setTimeUpdate] = useState('')
    // 搜索标签内容
    const [serachTag, setSerachTag] = useState('')
    // 单款录入属性
    const [singleData, setSingleData] = useState([
        {
            "name": "",
            "value": []
        },
        {
            "name": "",
            "value": []
        },
    ])
    // 标签选择器内容
    const [tagsData, setTagsData] = useState([{
        "id": "",
        "group": "",
        "name": "",
        "color": ""
    }
    ])
    // 树形数据
    const [treeData] = useState([
        {
            title: 'Node1',
            value: '0-0',
            children: [
                {
                    title: 'Child Node1',
                    value: '0-0-1',
                },
                {
                    title: 'Child Node2',
                    value: '0-0-2',
                },
            ],
        },
        {
            title: 'Node2',
            value: '0-1',
        },
    ])
    let singleAddDict = {
        "上新价": singleNewPrice,
        "吊牌价": singleTagPrice,
        "SPU": singleSpu,
        "上架时间": singleAddTime,
        "款号年份": singleListingYear,
        "上市月份": singleListingMonth,
    }
    // 子节点数据
    const [childData, setChildData] = useState([''])
    // 父节点数据
    const [fData, setFData] = useState("")
    // 多选设置
    const onSelect = (record, selected) => {
        let keys = [...selectedRowKeys]
        if (selected) {
            keys = [...selectedRowKeys, record.goods_only_flag]
        } else {
            keys = selectedRowKeys.filter((item) => item !== record.goods_only_flag)
        }
        setSelectedRowKeys(keys)
    };
    const onSelectAll = (selected, selectedRows, changeRows) => {
        if (selected) {
            const addCheckedKeys = changeRows.map((item) => {
                return item.goods_only_flag
            })
            setSelectedRowKeys([...selectedRowKeys, ...addCheckedKeys])
        } else {
            const subCheckedKeys = selectedRowKeys.filter((goods_only_flag) => {
                return !changeRows.some((item) => {
                    return item.goods_only_flag === goods_only_flag
                })
            })
            setSelectedRowKeys(subCheckedKeys)
        }
    }
    const rowSelection = {
        columnWidth: 10,
        selectedRowKeys,
        onSelect: onSelect,
        onSelectAll: onSelectAll
    }

    // 选择的id
    const [categorySelectedRowKeys, setCategorySelectedRowKeys] = useState([])
    // 多选设置
    const onCategorySelect = (record, selected) => {
        let keys = [...categorySelectedRowKeys]
        if (selected) {
            keys = [...categorySelectedRowKeys, record.goods_only_flag]
        } else {
            keys = categorySelectedRowKeys.filter((item) => item !== record.goods_only_flag)
        }
        setCategorySelectedRowKeys(keys)
    };
    const onCategorySelectAll = (selected, selectedRows, changeRows) => {
        if (selected) {
            const addCheckedKeys = changeRows.map((item) => {
                return item.goods_only_flag
            })
            setCategorySelectedRowKeys([...categorySelectedRowKeys, ...addCheckedKeys])
        } else {
            const subCheckedKeys = categorySelectedRowKeys.filter((goods_only_flag) => {
                return !changeRows.some((item) => {
                    return item.goods_only_flag === goods_only_flag
                })
            })
            setCategorySelectedRowKeys(subCheckedKeys)
        }
    }
    const rowCategorySelection = {
        columnWidth: 10,
        categorySelectedRowKeys,
        onSelect: onCategorySelect,
        onSelectAll: onCategorySelectAll
    }
    // 图片查看
    const imageLookContent = (id) => {
        return <div>
            <img src={`https://pic.bmcsoft.cn/it/${props.history.location.pathname.split('/')[2]}/${id}.jpg`} style={{ width: '100px', height: '100px' }} />
        </div>
    }

    useEffect(() => {
        if (singleCategory) {
            let dataProps = {
                roleId: cookie.load('role'),
                shopList: cookie.load('shopList'),
                shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                cid: singleCategory
            }
            NbAxios(dataProps, "POST", servicePath.searchPropertyModelByCategory).then(res => {
                setLoading(false)
                if (res.success) {
                    setSingleData(res.data);
                } else {
                    message.warn({ content: res.errorMsg })
                }
            }).catch((err) => {
                setLoading(false)
                // 获取500错误
                message.warn('查询属性列表报错，请联系IT帮忙解决')
            })
        }
    }, [singleCategory])
    useEffect(() => {
        setColumn(filed.map(item => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                // fixed:item == '商品ID' || item ==  "SPU" ? "left" : item == "操作" ? "right" : false,
                width: item == '商品ID' || item == "SPU" ? "10%" : item == '盘货品类' || item == "款号年份" ? "6%" : item == "吊牌价" || item == "上新价" ? "5%" : item == "上架时间" ? "8%" : item == "上架时间" ? "9%" : item == "操作" ? "10%" : item == '款号季节' ? '6%' : "33%",
                render: (text, record, index) => {
                    return item == '标签' ? <div style={{ display: 'flex', alignItems: "center", flexWrap: 'wrap', padding: "0px" }}>
                        {
                            text.map((tagItem) => {
                                /**
                                 * 鼠标右键事件 trigger="contextMenu"
                                 */
                                return <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                    dataSource[index]['标签'].remove(tagItem)
                                    let dataProps = {
                                        shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                        goods_only_flag: [record.goods_only_flag],
                                        tags: record['标签'],
                                        roleId: cookie.load('role'),
                                        shopList: cookie.load('shopList'),
                                        tagsOptions: 2
                                    }
                                    setLoading(true)
                                    NbAxios(dataProps, "POST", servicePath.setTagsForGoods).then(res => {
                                        setLoading(false)
                                        if (res.success) {
                                            message.success("修改成功")
                                        } else {
                                            message.warn({ content: res.errorMsg })
                                            // 删除失败则赋值回来
                                            dataSource[index]['标签'].push(tagItem)
                                        }
                                    }).catch((err) => {
                                        setLoading(false)
                                        // 删除失败则赋值回来
                                        dataSource[index]['标签'].push(tagItem)
                                        // 获取500错误
                                        message.warn('商品设定标签报错，请联系IT帮忙解决')
                                    })
                                }}>
                                    <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px" }}>
                                        {tagItem.name}
                                    </div>
                                </Popconfirm>
                            })
                        }
                        <Popover content={addTagsList(record, index)} placement="bottom" zIndex={900} onMouseLeave={()=>{
                            setTagsList(tagsData)
                            }}>
                            <PlusSquareOutlined />
                        </Popover>
                    </div> : item == '操作' ? <div style={{ display: "flex", justifyContent: 'space-around' }}>
                        <a onClick={() => {
                            searchPropertyValueByInfo()
                            initOneGoodsDetail(record)
                            setIsEditDetails(true)
                            setTags([])
                            setIsGoodsDetail(true)
                        }}>详情</a>
                        <Popconfirm title="是否要删除该商品信息" okText="确认" cancelText="取消" onConfirm={() => {
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                goods_only_flag: record.goods_only_flag
                            }
                            NbAxios(dataProps, "POST", servicePath.deleteGoodsByKeys).then(res => {
                                setLoading(false)
                                if (res.success) {
                                    message.success("删除商品成功")
                                    searchGoodsByKeysOrTags()
                                } else {
                                    message.warn({ content: res.errorMsg })
                                }
                            }).catch((err) => {
                                setLoading(false)
                                // 获取500错误
                                message.warn('查询属性列表报错，请联系IT帮忙解决')
                            })
                        }}>
                            <a>删除</a>
                        </Popconfirm>
                    </div> : item == '商品ID' ? <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                        <Popover content={imageLookContent(record['商品ID'])} placement="bottom">
                            <EyeOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
                        </Popover>
                        <div>{text}</div>
                    </div> : <span style={{ fontSize: "12px" }}>{text}</span>
                }
            }
        }))
    }, [tagsList, dataSource])

    useEffect(() => {
        // 初始化数据
        const getAllData = async () => {
            await Promise.all([
                // searchGoodsByKeysOrTags(),
                getAllGoodsTags(),
                searchCategoryByInfo([], ""),
                initStandardizedModelList(),
                initHighSearchForGoods(),
                searchPropertyValueByInfo(),
                batchModifyVariableName(),
            ])
        }
        getAllData()
    }, [])
    // 品类关系图
    // useEffect(()=>{
    //     if(graph.nodes.length!=0){
    //         setCategoryRelationPicture(true)
    //     }
    // },[graph])
    useEffect(() => {
        setSpecificationColumn(specificationFiled.map(item => {
            return {
                title: item,
                dataIndex: columnDict[item],
                key: columnDict[item],
                align: "center",
                width: item == "属性值" ? "30%" : "14%",
                render: (text, record, index) => {
                    return item == '操作' ? <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {/* 暂不支持 */}
                        {/* <a onClick={() => {
                            if (record['筛选策略'] == '下拉筛选') {
                                setTags(record['属性值'].split(';'))
                            }
                            attributesForm.setFieldsValue(record)
                            setIsEditAttributes(true)
                        }}>编辑</a> */}
                        <Popconfirm title="是否要删除该属性" okText="确认" cancelText="取消" onConfirm={() => {
                            delPropertyInGroupByPropertyName(record)
                        }}>
                            <a>删除</a>
                        </Popconfirm>
                    </div> : item == '销售属性' ? text == 1 ? <div>是</div> : <div>否</div> : <span style={{ fontSize: "12px" }}>{text}</span>
                }
            }
        }))

    }, [categoryValue])
    // 关系表
    useEffect(() => {
        setCategoryRelationColumn(categoryRelationFiled.map(item => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                width: "30%",
                ...getColumnSearchProps(item)
            }
        }))
    }, [categoryRelationFiled])
    useEffect(() => {
        setCategoryColumn(categoryFiled.map(item => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                width: "20%",
                render: (text, record, index) => {
                    return item == '操作' ? <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <a onClick={() => {
                            setCategoryRowData(record)
                            searchPropertyValueByInfo()
                            setFData(record.fid)
                            let childList = []
                            record.children.map(item => {
                                childList.push(item.detail.id)
                            })
                            setChildData(childList)
                            setCategoryName(record["品类名称"])
                            setEditCategory(true)
                            setCategoryLevelSelect(record["品类分组"])
                        }}>编辑</a>
                        <Popconfirm title="是否要删除该品类" okText="确认" cancelText="取消" onConfirm={() => {
                            deleteCategoryByKeys(record)
                        }}>
                            <a>删除</a>
                        </Popconfirm>
                    </div> : <span style={{ fontSize: "12px" }}>{text}</span>
                }
            }
        }))
    }, [categoryData])
    // 获取品类列表
    useEffect(() => {
        searchCategoryByCategoryLevel(categoryGroup)
        setCategoryValue([])
    }, [categoryGroup])
    // 改变页码的时候触发
    useEffect(() => {
        // 如果进行了筛选,则改变页码时调用筛选接口，否则调用初始化接口
        if (isFilterTags) {
            searchGoodsByInfo()
        } else {
            searchGoodsByKeysOrTags()
        }
    }, [page])
    // 添加新标签
    const addNewTag = () => {
        if (tagName == '') {
            message.warning("标签名未输入")
        } else if (tagsData.map(item => {
            return item.name
        }).indexOf(tagName) !== -1) {
            message.warn("标签已存在")

            //     let dataProps = {
            //         roleId: cookie.load('role'),
            //         shopList: cookie.load('shopList'),
            //         shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            //         tags_list: obj
            //     }
            //     setLoading(true)
            //     NbAxios(dataProps, "POST", servicePath.addGoodsTags).then(res => {
            //         setLoading(false)
            //         if (res.success) {
            //             message.success("添加成功")
            //             getAllGoodsTags()
            //             let obj_select = [...allChooseTagsList]
            //             obj_select.push({
            //                 group: "",
            //                 name: tagName,
            //                 color: tagColor,
            //             })
            //             setAllChooseTagsList(obj_select)
            //             setTagName('')
            //             setTagColor('')
            //         } else {
            //             message.warn({ content: res.errorMsg })
            //         }
            //     }).catch((err) => {
            //         setLoading(false)
            //         // 获取500错误
            //         message.warn('添加新标签报错，请联系IT帮忙解决')
            //     })
            // }

        } else {
            let obj = [...tagsData]
            obj.push({
                group: "",
                name: tagName,
                color: tagColor,
            })
            setTagsData(obj)
            setAllChooseTagsList(obj)
            setTagName('')
            setTagColor('')
        }
    }

    // 文档列表改变的时候出发
    const fileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx',]
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    setFileList(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setFileList(fileList)
        }
    }
    // 文档列表改变的时候触发
    const tagFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx',]
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    setTagFileList(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setTagFileList(fileList)
        }
    }

    // 关闭单款录入
    const closeSingleEntry = () => {
        setIsSingleEntry(false)
        setSingleId("")
        setSingleTagPrice("")
        setSingleCategory("")
        setSingleNewPrice('')
        setSingleTagPrice("")
        setSingleSpu("")
        setSingleAddTime("")
        setSingleListingYear("")
        setSingleListingMonth("")
        setSingleData([])
    }

    // 初始化商品列表
    const queryGoods = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            page_size: 10,
            page: page
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.queryGoodsManager).then(res => {
            setLoading(false)
            if (res.success) {
                setDataSource(res.data);
                setTotal(res.total)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('初始化商品列表查询报错，请联系IT帮忙解决')
        })
    }
    // 查询属性下拉取值
    const searchPropertyValueByInfo = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            property_info: {
                "id": 7,
                "group": "主体",
                "name": "盘货品类",
                "search_tactics": "下拉筛选",
                "data_type": "字符",
                "selection_flag": 1,
                "value": ""
            }
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchPropertyValueByInfo).then(res => {
            setLoading(false)
            if (res.success) {
                console.log(res.data);
                setCategoryList(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询属性下拉取值报错，请联系IT帮忙解决')
        })
    }
    // 上传Excel函数
    const uploadFile = () => {
        if (fileList.length == 0) {
            message.error("请选择文件");
        } else {
            setLoading(true);
            const formData = new FormData();
            fileList.forEach((item) => {
                formData.append("file", item["originFileObj"]);
            });
            formData.append("shop_name", shopDict[props.history.location.pathname.split('/')[2]]);
            formData.append("shopList", cookie.load("shopList"));
            formData.append("roleId", cookie.load("role"));
            formData.append("name", cookie.load("name"));
            axios({
                method: 'POST',
                url: servicePath.uploadGoodsExcel,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    setFileList([]);
                    setLoading(false);
                    if (res.data['success']) {
                        message.success("商品导入成功");
                        setExportFlag(2)
                        setErrorMsg('导入成功！')
                        // setIsUploadFile(false);
                        searchGoodsByKeysOrTags()
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            setExportFlag(3)
                            message.error(res.data['errorMsg'])
                            setErrorMsg(res.data['errorMsg'])
                        }
                    }
                }
            ).catch((err) => {
                setLoading(false)
                // 获取500错误
                message.warn('文件导入报错，请联系IT帮忙解决')
                setErrorMsg('文件导入报错，请联系IT帮忙解决')
            })
        }

    };
    // 上传标签Excel函数
    const tagUploadFile = () => {
        if (tagFileList.length == 0) {
            message.error("请选择文件");
        } else {
            setLoading(true);
            const formData = new FormData();
            tagFileList.forEach((item) => {
                formData.append("file", item["originFileObj"]);
            });
            formData.append("shop_name", shopDict[props.history.location.pathname.split('/')[2]]);
            formData.append("shopList", cookie.load("shopList"));
            formData.append("roleId", cookie.load("role"));
            formData.append("name", cookie.load("name"));
            formData.append("tagsOptions", tagsOptions);
            axios({
                method: 'POST',
                url: servicePath.setTagsForGoodsByFile,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    setTagFileList([]);
                    setLoading(false);
                    if (res.data['success']) {
                        message.success("标签修改成功");
                        setExportFlag(2)
                        setTagErrorMsg(res.data['errorMsg'])
                        // setIsUploadFile(false);
                        searchGoodsByKeysOrTags()
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            setExportFlag(3)
                            message.error(res.data['errorMsg'])
                            setTagErrorMsg(res.data['errorMsg'])
                        }
                    }
                }
            ).catch((err) => {
                setLoading(false)
                // 获取500错误
                message.warn('文件导入报错，请联系IT帮忙解决')
                setTagErrorMsg('文件导入报错，请联系IT帮忙解决')
            })
        }

    };
    // 单款录入添加商品
    const addNewGoods = () => {
        if (singleId || singleSpu) {
            let dataProps = {
                shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                cid: singleCategory,
                goods_id: singleId,
                tag_price: singleTagPrice,
                spu: singleSpu,
                new_price: singleNewPrice,
                on_shelves_time: singleAddTime,
                year: singleListingYear,
                month: singleListingMonth,
                new_goods_info: singleData,
                roleId: cookie.load('role'),
                shopList: cookie.load('shopList'),
                singleTagList: singleTagList,
            }
            setLoading(true)
            NbAxios(dataProps, "POST", servicePath.addNewGoods).then(res => {
                setLoading(false)
                if (res.success) {
                    message.success("商品添加成功")
                    if (singleId != '') {
                        searchGoods(singleId, tagsValue)
                    } else {
                        searchGoods(singleSpu, tagsValue)
                    }
                    // searchGoodsByKeysOrTags()
                    closeSingleEntry()
                } else {
                    message.warn({ content: res.errorMsg })
                }
            }).catch((err) => {
                setLoading(false)
                // 获取500错误
                message.warn('查询单款录入报错，请联系IT帮忙解决')
            })
        } else {
            message.error("商品ID与SPU必填其一")
        }

    }
    // 只显示最后一个级连选择
    const displayRender = (labels) => labels[labels.length - 1];

    // 查询所有商品标签
    const getAllGoodsTags = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]]
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.getAllGoodsTags).then(res => {
            setLoading(false)
            if (res.success) {
                setTagsData(res.data.tags_list)
                setTagsList(res.data.tags_list)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询所有商品标签报错，请联系IT帮忙解决')
        })
    }
    // 查询商品列表
    const searchGoodsByKeysOrTags = () => {
        let tag = []
        tagsValue.map((item) => {
            tag.push(item.split("&")[0])
        })
        let dataProps = {
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            keys: id,
            tags: tag,
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            page: page,
            page_size: 10
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchGoodsByKeysOrTags).then(res => {
            setLoading(false)
            if (res.success) {
                setDataSource(res.data)
                setTotal(res.total)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询商品列表报错，请联系IT帮忙解决')
        })
    }
    // 查询商品列表（传参版）
    const searchGoods = (id, tags) => {
        let dataProps = {
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            keys: id,
            tags: tags,
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            page: page,
            page_size: 10
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchGoodsByKeysOrTags).then(res => {
            setLoading(false)
            if (res.success) {
                setDataSource(res.data)
                setTotal(res.total)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询商品列表报错，请联系IT帮忙解决')
        })
    }
    // 批量操作-批量修改
    const alterSelectedField = () => {
        let dataProps = {
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            goods_only_flag: rowSelection.selectedRowKeys,
            selected_info: categoryUpdate,
            selected_info_name:selectUpdateFields,
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.alterSelectedField).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("修改成功")
                setIsModCategory(false)
                setUpdateResultData(res.data)
                searchGoodsByKeysOrTags()
                setIsUpdateResult(true)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('修改品类报错，请联系IT帮忙解决')
        })
    }
    // 批量操作-修改上架时间
    const alterUploadTime = () => {
        let dataProps = {
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            goods_only_flag: rowSelection.selectedRowKeys,
            on_shelves_time: timeUpdate,
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.alterUploadTime).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("修改成功")
                setIsModTime(false)
                searchGoodsByKeysOrTags()
                setTimeUpdate('')
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('修改上架时间报错，请联系IT帮忙解决')
        })
    }
    // 为商品设定标签
    const setTagsForGoods = () => {
        let dataProps = {
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            goods_only_flag: rowSelection.selectedRowKeys,
            tags: chooseTagsList,
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            tagsOptions: tagsOptions,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.setTagsForGoods).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("修改成功")
                setIsSettingTags(false)
                searchGoodsByKeysOrTags()
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('商品设定标签报错，请联系IT帮忙解决')
        })
    }
    // 编辑标签(删除)
    const editTags = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            tags_list: tagsData
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.addGoodsTags).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("编辑标签成功")
                setIsEditTags(false)
                getAllGoodsTags()
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('编辑标签报错，请联系IT帮忙解决')
        })
    }
    // 查询品类列表
    const searchCategoryByInfo = (keys, level) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            keys: keys,
            level: level,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchCategoryByInfo).then(res => {
            setLoading(false)
            if (res.success) {
                setCategoryData(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询品类列表报错，请联系IT帮忙解决')
        })
    }
    // 查询品类列表
    const searchCategoryByCategoryLevel = (level) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            level: level,
        }
        NbAxios(dataProps, "POST", servicePath.searchCategoryByCategoryLevel).then(res => {
            setLoading(false)
            if (res.success) {
                setCategoryValueList(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询品类列表报错，请联系IT帮忙解决')
        })
    }
    // 编辑品类提交
    const submitEditCategory = () => {
        let dataProps = {
            id: categoryRowData["品类编号"],
            f_data: fData,
            child_data: childData,
            category_level: categoryLevelSelect,
            category_name: categoryName,
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.submitEditCategory).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("编辑成功")
                setEditCategory(false)
                // 单行替换数据
                let obj = [...categoryData]
                obj.map((item, index) => {
                    if (item["品类编号"] == res.data["品类编号"]) {
                        obj[index] = res.data
                    }
                })
                setCategoryData(obj)
                // 重新查询品类列表
                searchCategoryByCategoryLevel(categoryGroup)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('编辑品类提交报错，请联系IT帮忙解决')
        })
    }
    // 删除品类确认
    const deleteCategoryByKeys = (record) => {
        let dataProps = {
            cid: record["品类编号"],
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.deleteCategoryByKeys).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("删除成功")
                // 单行删除数据
                let obj = [...categoryData]
                obj.map((item, index) => {
                    if (item["品类编号"] == record["品类编号"]) {
                        obj.remove(record)
                    }
                })
                setCategoryData(obj)
                searchCategoryByCategoryLevel(categoryGroup)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('删除品类提交报错，请联系IT帮忙解决')
        })
    }
    // 删除级联品类
    const delRelationCategoryByKeys = (record, itemInfo) => {
        let dataProps = {
            cid: record["品类编号"],
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            child_id: itemInfo.detail.id,
            parent_id: "",
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.delRelationCategoryByKeys).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("删除成功")
                // 单个删除子节点
                let obj = [...categoryData]
                obj.map((item) => {
                    item.children.map(citem => {
                        if (citem.detail.id == itemInfo.detail.id) {
                            item.children.remove(citem)
                        }
                    })
                })
                setCategoryData(obj)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('删除级联品类提交报错，请联系IT帮忙解决')
        })
    }
    // 新增品类
    const addNewCategory = () => {
        if (categoryName == '') {
            message.warning("品类名字必填")
        } else if (categoryLevelSelect == '') {
            message.warning('品类分组必填')
        } else {
            let dataProps = {
                f_data: fData,
                child_data: childData,
                category_level: categoryLevelSelect,
                category_name: categoryName,
                roleId: cookie.load('role'),
                shopList: cookie.load('shopList'),
                shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            }
            setLoading(true)
            NbAxios(dataProps, "POST", servicePath.addNewCategory).then(res => {
                setLoading(false)
                if (res.success) {
                    message.success("添加品类成功")
                    setNewCategory(false)
                    // // 单行插入数据
                    let obj = [...categoryData]
                    // 向开头添加数据
                    obj.unshift(res.data)
                    setCategoryData(obj)
                    // 重新查询品类列表
                    searchCategoryByCategoryLevel(categoryGroup)
                } else {
                    message.warn({ content: res.errorMsg })
                }
            }).catch((err) => {
                setLoading(false)
                // 获取500错误
                message.warn('新增品类报错，请联系IT帮忙解决')
            })
        }

    }
    // 初始化规格模型
    const initStandardizedModelList = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            keys: [],
            level: ""
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchStandardizedModelByInfo).then(res => {
            setLoading(false)
            if (res.success) {
                if (res.data.property_model) {
                    res.data.map(item => {
                        item.property_model.map(model => {
                            model["isEdit"] = false
                        })
                    })
                }
                setSpecificationData(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('初始化规格模型报错，请联系IT帮忙解决')
        })
    }
    // 筛选规格模型
    const searchStandardizedModelByInfo = (keys, level) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            keys: keys,
            level: level,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchStandardizedModelByInfo).then(res => {
            setLoading(false)
            if (res.success) {
                setSpecificationData(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('筛选规格模型报错，请联系IT帮忙解决')
        })
    }
    // 查询规格模型分组名称
    const searchPropertyGroupNameBySN = (item) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            group_name: item.title,
            cid: item.cid,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchPropertyGroupNameBySN).then(res => {
            setLoading(false)
            if (res.success) {
                setGroupData(res.data.select_data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询规格模型分组名称报错，请联系IT帮忙解决')
        })
    }
    // 查询规格模型分组属性项
    const searchPropertyByGroupName = (item, citem) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            group_name: citem.group_name,
            cid: item.cid
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchPropertyByGroupName).then(res => {
            setLoading(false)
            if (res.success) {
                setPropertyData(res.data.select_data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询规格模型分组属性项报错，请联系IT帮忙解决')
        })
    }
    // 添加属性
    const addNewPropertyForStandModel = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            group_name: temporaryAddGroup.cData.group_name,
            cid: temporaryAddGroup.data.cid,
            property_name: propertyValue,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.addNewPropertyForStandModel).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("添加成功")
                setIsAddProperty(false)
                searchStandardizedModelByInfo(categoryValue, categoryGroup)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('添加属性报错，请联系IT帮忙解决')
        })
    }
    // 删除分组
    const delPropertyGroupByGroupName = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            group_name: temporaryAddGroup.cData.group_name,
            cid: temporaryAddGroup.data.cid,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.delPropertyGroupByGroupName).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("删除成功")
                setDeleteGroup(false)
                let obj = [...specificationData]
                obj[temporaryAddGroup.index]["property_model"].remove(temporaryAddGroup.cData)
                setSpecificationData(obj)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('删除分组报错，请联系IT帮忙解决')
        })
    }
    // 删除属性
    const delPropertyInGroupByPropertyName = (record) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            group_name: record.group,
            cid: record.cid,
            property_name: record.name
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.delPropertyInGroupByPropertyName).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("删除成功")
                setDeleteGroup(false)
                searchStandardizedModelByInfo(categoryValue, categoryGroup)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('删除属性报错，请联系IT帮忙解决')
        })
    }
    // 导出规格模型
    const downloadStandardizedModel = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            cid: [],
        }
        setLoading(true)
        axios({
            data: dataProps,
            method: 'POST',
            url: servicePath.downloadStandardizedModel,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setLoading(false)
                downExcel(res)
                setExportFlag(2)
            }
        ).catch(e => {
            setLoading(false)
            setExportFlag(3)
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }
    // 导出全部模型
    const downloadGoodsByFile = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        axios({
            data: dataProps,
            method: 'POST',
            url: servicePath.downloadGoodsByFile,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setLoading(false)
                downExcel(res)
                setExportFlag(2)
            }
        ).catch(e => {
            setLoading(false)
            setExportFlag(3)
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }
    // 高级筛选初始化
    const initHighSearchForGoods = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dataProps, "POST", servicePath.initHighSearchForGoods).then(res => {
            if (res.success) {
                setHighSearchData(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('高级筛选初始化报错，请联系IT帮忙解决')
        })
    }
    // 高级筛选提交
    const searchGoodsByInfo = () => {
        let tag = []
        filterTags.map((item) => {
            tag.push(item.split("&")[0])
        })
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            search_info: {
                "data": highSearchData,
                "tags": tag,
            },
            page: page,
            page_size: 10
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchGoodsByInfo).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("筛选成功")
                setIsFilter(false)
                setDataSource(res.data)
                setTotal(res.total)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('高级筛选提交报错，请联系IT帮忙解决')
        })
    }
    // 查询商品详情
    const initOneGoodsDetail = (record) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            goods_only_flag: record.goods_only_flag,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.initOneGoodsDetail).then(res => {
            setLoading(false)
            if (res.success) {
                setGoodsDetailData(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询商品详情报错，请联系IT帮忙解决')
        })
    }
    // 单款详情修改
    const alterOneGoodsDetail = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            // goods_only_flag: record.goods_only_flag,
            goodsDetailData: goodsDetailData
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.alterOneGoodsDetail).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("修改成功")
                setIsEditDetails(true)
                // setGoodsDetailData(res.data)
                searchGoodsByKeysOrTags()
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询商品详情报错，请联系IT帮忙解决')
        })
    }
    // 查看关系图
    const getCategoryByChart = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.getCategoryByChart).then(res => {
            setLoading(false)
            if (res.success) {
                setGraph(res.data)
                let report = {
                    tooltip: {
                        // 默认会绘制一个圆点图形
                        valueFormatter: (value) => '&' + ' ' + value + '商品',
                        //效果同上一行代码，
                        /*formatter: function (params) {
                            //params[0].name表示x轴数据
                            let str = params.seriesName + '<br/>'
                            //params是数组格式
                            //设置浮层图形的样式跟随图中展示的颜色
                            str += "<span style='display:inline-block;width:10px;height:10px;border-radius:10px;background-color:" + params.color + ";'></span>" + "\t" + params.name + " : " + params.value
                            return str
                        }，*/
                    },
                    legend: [
                        {
                            // selectedMode: 'single',
                            data: res.data.categories.map(function (a) {
                                return a.name;
                            })
                        }
                    ],
                    animationDuration: 1500,
                    animationEasingUpdate: 'quinticInOut',
                    series: [
                        {
                            name: '商品品类：',
                            type: 'graph',  //绘制图像的类型为关系图
                            layout: 'force',  // 图的布局 'circular' 采用环形布局 'force' 采用力引导布局
                            // 引力布局的配置
                            force: {
                                // initLayout: 'circular'
                                // gravity: 0
                                repulsion: 100,
                                edgeLength: 50
                            },
                            data: res.data.nodes,
                            links: res.data.links,
                            categories: res.data.categories,
                            roam: true,
                            label: {
                                position: 'right',
                                formatter: '{b}'
                            },
                            lineStyle: {
                                color: 'source',
                                curveness: 0.3
                            },
                            emphasis: {
                                focus: 'adjacency',
                                lineStyle: {
                                    width: 10
                                },
                            }
                        }
                    ]
                }
                res.data.nodes.forEach(function (node) {
                    node.label = {
                        show: node.symbolSize > 3
                    };
                });
                // 基于准备好的dom，初始化echarts实例
                let reportChart = echarts.init(document.getElementById('relationChart'));
                reportChart.clear()
                // 绘制图表
                reportChart.setOption(report)
                // window捕获标签点击，表格重绘放到浏览器渲染前
                window.addEventListener('resize', () => {
                    reportChart.resize()
                });
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查看关系图报错，请联系IT帮忙解决')
        })
    }
    // 查看关系表
    const getCategoryByTable = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.getCategoryByTable).then(res => {
            setLoading(false)
            if (res.success) {
                setCategoryRelationFiled(res.data.title)
                setCategoryRelationData(res.data.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查看关系表报错，请联系IT帮忙解决')
        })
    }
    // 查询可供批量修改的字段
    const batchModifyVariableName = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.batchModifyVariableName).then(res => {
            setLoading(false)
            if (res.success) {
                setUpdateFields(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询可供批量修改的字段报错，请联系IT帮忙解决')
        })
    }
    // 查询批量下拉框取值
    const judgeSearchMethod = (updateFields) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
            search_field: updateFields
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.judgeSearchMethod).then(res => {
            setLoading(false)
            if (res.success) {
                setUpdateFieldsType(res.data);
                if (res.data.name !== '盘货品类') {
                    let obj = {
                        roleId: cookie.load('role'),
                        shopList: cookie.load('shopList'),
                        shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                        property_info: {
                            "id": updateFields,
                        }
                    }
                    setLoading(true)
                    NbAxios(obj, "POST", servicePath.searchPropertyValueByInfo).then(res => {
                        setLoading(false)
                        if (res.success) {
                            setCategoryList(res.data)
                        } else {
                            message.warn({ content: res.errorMsg })
                        }
                    }).catch((err) => {
                        setLoading(false)
                        // 获取500错误
                        message.warn('查询属性下拉取值报错，请联系IT帮忙解决')
                    })
                } else {
                    searchPropertyValueByInfo()
                }
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询批量下拉框取值报错，请联系IT帮忙解决')
        })
    }
    return (
        //  style={{ backgroundColor: '#F5F5F5', height: "calc(100vh - 40px)" ,overflow:'auto'}}
        <div style={{ backgroundColor: '#F5F5F5', height: "calc(100vh - 40px)", overflow: 'auto' }}>
            <div className={style.externalContent}>
                <div style={{ height: "40px" }}>
                    <Tabs type="card" size="small" activeKey={tabKey} onChange={(key) => {
                        setTabKey(key)
                        // 当品类分组有条件，同时切换了tab页时，清空条件
                        if (categoryGroup.length !== 0) {
                            setCategoryGroup([])
                        }
                        setCategoryValue([])
                    }}>
                        <TabPane tab={<span>商品列表</span>} key="productList"></TabPane>
                        <TabPane tab={<span>品类管理</span>} key="categoryManagement"></TabPane>
                        <TabPane tab={<span>规格模型</span>} key="specificationModel"></TabPane>
                    </Tabs>
                </div>
                <Spin style={{ display: tabKey == 'productList' ? '' : 'none' }} spinning={loading} tip="数据加载中....">
                    <div style={{ display: tabKey == 'productList' ? '' : 'none', alignItems: 'center' }}>
                        <div style={{ paddingTop: "10px", display: 'flex', alignItems: "center", paddingBottom: "10px", justifyContent: 'space-between' }}>
                            <Input style={{ width: "300px" }} placeholder="请输入商品ID或SPU,多个以空格分隔" value={id} onChange={(e) => setId(e.target.value)} onBlur={() => {
                                if (page != 1) {
                                    setPage(1)
                                } else {
                                    searchGoodsByKeysOrTags()
                                }
                            }} />
                            {/* <b style={{ width: "70px", textAlign: "right", marginLeft: "30px" }}>SPU:</b>
                    <Input style={{ marginLeft: "10px", width: "240px" }} placeholder="请输入SPU,以空格分割" value={spu} onChange={(e) => setSpu(e.target.value)} /> */}
                            <div >
                                <b style={{ width: "70px", textAlign: "right", marginLeft: "30px" }}>标签:</b>
                                <Select mode="multiple" showSearch={true} allowClear style={{ marginLeft: "10px", width: "480px" }} placeholder="请选择标签,可多选" value={tagsValue}
                                    onChange={(value) => setTagsValue(value)}
                                    onBlur={() => {
                                        if (page != 1) {
                                            setPage(1)
                                        } else {
                                            searchGoodsByKeysOrTags()
                                        }
                                    }}
                                >
                                    {tagsData.map((item) => {
                                        return <Option value={`${item.id}&${item.name}`}>{item.name}</Option>
                                    })}
                                </Select>
                                <Button type="primary" style={{ marginLeft: "10px", borderRadius: "2px" }} onClick={() => {
                                    if (page != 1) {
                                        setPage(1)
                                    } else {
                                        searchGoodsByKeysOrTags()
                                    }
                                }}>刷新</Button>
                                <Button style={{ marginLeft: "10px", borderRadius: "2px" }} onClick={() => {
                                    setId("")
                                    setTagsValue([])
                                    // 清空高级筛选
                                    let obj = [...highSearchData]
                                    obj.map(item => {
                                        if (item.search_tactics == '范围筛选') {
                                            item.value = ['', '']
                                        } else {
                                            item.value = ""
                                        }
                                    })
                                    setHighSearchData(obj)
                                    setFilterTags([])
                                    let dataProps = {
                                        shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                        keys: '',
                                        tags: [],
                                        roleId: cookie.load('role'),
                                        shopList: cookie.load('shopList'),
                                        page: page,
                                        page_size: 10
                                    }
                                    setLoading(true)
                                    NbAxios(dataProps, "POST", servicePath.searchGoodsByKeysOrTags).then(res => {
                                        setLoading(false)
                                        if (res.success) {
                                            setDataSource(res.data)
                                            setTotal(res.total)
                                        } else {
                                            message.warn({ content: res.errorMsg })
                                        }
                                    }).catch((err) => {
                                        setLoading(false)
                                        // 获取500错误
                                        message.warn('查询商品列表报错，请联系IT帮忙解决')
                                    })
                                }}>清空</Button>
                                <Button style={{ marginLeft: "10px", }} icon={<FileSearchOutlined />} onClick={() => {
                                    setIsFilter(true)
                                }}></Button>
                            </div>
                        </div>
                        <div style={{ width: "100%", height: "40px", borderRadius: "2px", display: "flex", backgroundColor: "#E5E5E5", alignItems: "center", padding: "10px" }}>
                            <Popover content={addProduct} placement="bottom" trigger="click">
                                <div style={{ border: "1px solid #fff", borderRadius: "2px", display: 'flex', height: "24px", alignItems: "center", justifyContent: "space-between", width: "92px", padding: "0 8px", cursor: 'pointer' }}>
                                    <div style={{ height: "22px" }}>添加商品</div>
                                    <DownOutlined />
                                </div>
                            </Popover>
                            <Popover content={bulkOperations} placement="bottom" trigger="click">
                                <div style={{ border: "1px solid #fff", borderRadius: "2px", display: 'flex', height: "24px", alignItems: "center", justifyContent: "space-between", width: "92px", padding: "0 8px", marginLeft: "10px", cursor: 'pointer' }}>
                                    <div style={{ height: "22px" }}>批量操作</div>
                                    <DownOutlined />
                                </div>
                            </Popover>
                            <Popover content={labelContent} placement="bottom" trigger="click">
                                <div style={{ border: "1px solid #fff", borderRadius: "2px", display: 'flex', height: "24px", alignItems: "center", justifyContent: "space-between", width: "92px", padding: "0 8px", marginLeft: "10px", cursor: 'pointer' }}>
                                    <div style={{ height: "22px" }}>标签</div>
                                    <DownOutlined />
                                </div>
                            </Popover>
                            {/* 暂不支持 */}
                            <Popover content={exportMenu} placement="bottom" trigger="click">
                                <div style={{ border: "1px solid #fff", borderRadius: "2px", display: 'flex', height: "24px", alignItems: "center", justifyContent: "space-between", width: "92px", padding: "0 8px", marginLeft: "10px", cursor: 'pointer' }}>
                                    <div style={{ height: "22px" }}>导出</div>
                                    <DownOutlined />
                                </div>
                            </Popover>
                        </div>
                        <div>
                            <Table size="small" columns={column} dataSource={dataSource} rowSelection={rowSelection} rowKey={"goods_only_flag"} pagination={paginationProps}
                                // scroll={{ x:'80vw', }}
                                expandable={{
                                    // columnWidth:10,
                                    expandedRowRender: record => <p style={{ marginLeft: "95px", fontSize: "12px", display: "flex", alignItems: "center" }}>{record['描述']}</p>,
                                }} />
                        </div>
                    </div>
                </Spin>
                <Spin style={{ display: tabKey == 'categoryManagement' ? '' : 'none' }} spinning={loading} tip="数据加载中....">
                    <div style={{ display: tabKey == 'categoryManagement' ? '' : 'none' }}>
                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', paddingTop: "10px", paddingBottom: "10px" }}>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <Button type="primary" style={{ marginRight: "10px" }} onClick={() => {
                                    searchPropertyValueByInfo()
                                    setNewCategory(true)
                                    setFData('')
                                    setCategoryName('')
                                    setCategoryLevelSelect('')
                                    setChildData([''])
                                }}>新增品类</Button>
                                <a style={{ marginRight: "10px" }} onClick={() => {
                                    setCategoryRelationPicture(true)
                                    getCategoryByChart()
                                }}>查看关系图</a>
                                <a onClick={() => {
                                    setCategoryRelation(true)
                                    getCategoryByTable()
                                }}>查看关系表</a>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Select style={{ width: "240px" }} placeholder="请选择品类分组" value={categoryGroup}
                                    onChange={value => {
                                        setCategoryGroup(value)
                                    }}
                                    allowClear	>
                                    {categoryLevel.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                    }
                                </Select>
                                <Select style={{ width: "400px", marginLeft: "10px" }} showSearch placeholder="请选择品类(可多选)(若不选择分组则默认为盘货品类)" value={categoryValue} allowClear mode="multiple"
                                    onChange={value => {
                                        setCategoryValue(value)
                                    }}>
                                    {categoryValueList.map((item) => {
                                        return <Option value={`${item.id}&${item.title}`}>{item.title}</Option>
                                    })}
                                </Select>
                                <Button type="primary" style={{ marginLeft: "10px" }} onClick={() => {
                                    let obj = []
                                    categoryValue.map((item) => {
                                        obj.push(item.split("&")[0])
                                    })
                                    searchCategoryByInfo(obj, categoryGroup)
                                }}>搜索</Button>
                                <Button style={{ marginLeft: "10px" }} onClick={() => {
                                    setCategoryGroup([])
                                    setCategoryValue([])
                                    searchCategoryByInfo([], "")
                                }}>清空</Button>
                            </div>
                        </div>
                        <div>
                            <Table dataSource={categoryData} columns={categoryColumn} size="small" rowKey={"品类编号"} childrenColumnName={"childrenRow"}
                                expandable={{
                                    expandedRowRender: record => <div style={{ display: "flex", marginLeft: "20px", flexWrap: "wrap", }}>{
                                        record["children"].length == 0 ? <div style={{ marginLeft: "10px", color: '#ccc' }}>无子节点</div> :
                                            record['children'].map(item => {
                                                return <Popconfirm trigger="contextMenu" title="是否删除该子节点" okText="确定" cancelText="取消" onConfirm={() => {
                                                    delRelationCategoryByKeys(record, item)
                                                }}>
                                                    <div style={{ padding: "2px", border: "1px solid #ccc", marginLeft: "10px", display: 'flex' }}>
                                                        <span>{item['label']}</span>
                                                    </div>
                                                </Popconfirm>
                                            })}</div>
                                }}
                            />
                        </div>
                    </div>
                </Spin>
                <Spin style={{ display: tabKey == 'specificationModel' ? '' : 'none' }} spinning={loading} tip="数据加载中....">
                    <div style={{ display: tabKey == 'specificationModel' ? '' : 'none', }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "10px", paddingBottom: "10px" }}>
                            <Popover content={exportMoldelMenu} placement="bottom" trigger="click">
                                <div style={{ border: "1px solid #ccc", borderRadius: "5px", display: 'flex', height: "24px", alignItems: "center", justifyContent: "space-between", width: "92px", padding: "0 8px", cursor: 'pointer' }}>
                                    <div style={{ height: "22px" }}>导出</div>
                                    <DownOutlined />
                                </div>
                            </Popover>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Select style={{ width: "240px" }} placeholder="请选择品类分组" value={categoryGroup}
                                    onChange={value => {
                                        setCategoryGroup(value)
                                    }}
                                    allowClear	>
                                    {categoryLevel.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                    }
                                </Select>
                                <Select style={{ width: "400px", marginLeft: "10px" }} showSearch={true} placeholder="请选择品类(可多选)(若不选择分组则默认为盘货品类)" value={categoryValue} allowClear mode="multiple"
                                    onChange={value => {
                                        setCategoryValue(value)
                                    }}>
                                    {categoryValueList.map((item) => {
                                        return <Option value={`${item.id}&${item.title}`}>{item.title}</Option>
                                    })}
                                </Select>
                                <Button type="primary" style={{ marginLeft: "10px" }} onClick={() => {
                                    let obj = []
                                    categoryValue.map((item) => {
                                        obj.push(item.split("&")[0])
                                    })
                                    searchStandardizedModelByInfo(obj, categoryGroup)
                                }}>搜索</Button>
                                <Button style={{ marginLeft: "10px" }} onClick={() => {
                                    setCategoryGroup([])
                                    setCategoryValue([])
                                    searchStandardizedModelByInfo([], '')
                                }}>清空</Button>
                            </div>
                        </div>
                        <div style={{ marginBottom: "20px", width: "100%", border: "1px solid #ccc" }}></div>
                        <div style={{ width: "100%" }}>
                            {
                                specificationData.map((item, index) => {
                                    return <div style={{ width: "98%", marginBottom: '30px' }}>
                                        <div style={{ display: "flex", justifyContent: 'center' }}>
                                            <span style={{ fontSize: "18px" }}>{item.title}</span>
                                        </div>
                                        <div style={{}}>
                                            {
                                                item.property_model.length == 0 ? '' :
                                                    item['property_model'].map((groupItem, groupIndex) => {
                                                        return <div style={{ marginTop: groupIndex != 0 ? '10px' : '' }}>
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div>
                                                                    {
                                                                        groupItem.isEdit ? <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <Input size="small" style={{ width: "150px" }} defaultValue={groupItem['group_name']} onChange={(e) => {
                                                                                let obj = [...specificationData]
                                                                                obj[index]['property_model'][groupIndex]['propertyName'] = e.target.value
                                                                                setSpecificationData(obj)
                                                                            }} />
                                                                            <CheckOutlined style={{ marginLeft: "5px" }} onClick={() => {
                                                                                let obj = [...specificationData]
                                                                                obj[index]['property_model'][groupIndex]['isEdit'] = false
                                                                                setSpecificationData(obj)
                                                                            }} />
                                                                        </div> :
                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                <span>{groupItem['group_name']}</span>
                                                                                {/* 可编辑（暂不支持） */}
                                                                                {/* <EditOutlined style={{ marginLeft: "5px" }} onClick={() => {
                                                                        let obj = [...specificationData]
                                                                        obj[index]['property_model'][groupIndex]['isEdit'] = true
                                                                        setSpecificationData(obj)
                                                                        // specificationData[index]['property_model'][groupIndex]['isEdit'] = true
                                                                    }} /> */}
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div style={{ display: 'flex' }}>
                                                                    <Button type="primary" size="small" style={{ marginRight: "20px" }} onClick={() => {
                                                                        attributesForm.setFieldsValue({
                                                                            "销售属性": "否"
                                                                        })
                                                                        setIsAddProperty(true)
                                                                        setTemporaryAddGroup({
                                                                            "data": item,
                                                                            'index': index,
                                                                            'cData': groupItem,
                                                                            'cIndex': groupIndex,
                                                                        })
                                                                        setPropertyData([])
                                                                        setPropertyValue('')
                                                                        searchPropertyByGroupName(item, groupItem)
                                                                        setAddFlag(0)
                                                                    }}>添加属性</Button>
                                                                    <Button type="primary" danger size="small" onClick={() => {
                                                                        setDeleteGroup(true)
                                                                        setTemporaryAddGroup({
                                                                            "data": item,
                                                                            'index': index,
                                                                            'cData': groupItem,
                                                                            'cIndex': groupIndex,
                                                                        })
                                                                    }}>删除分组</Button>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: "5px" }}>
                                                                <Table columns={specificationColumn} dataSource={groupItem.group_data} pagination={false} size="small" />
                                                            </div>
                                                        </div>
                                                    })
                                            }
                                            <div style={{
                                                width: "100%", border: "1px dashed #ccc", display: "flex",
                                                justifyContent: "center", alignItems: "center", height: "32px",
                                                marginTop: "5px", cursor: "pointer"
                                            }}
                                                onClick={() => {
                                                    setTemporaryAddGroup({
                                                        "data": item,
                                                        'index': index
                                                    })
                                                    setIsAddGroup(true)
                                                    setGroupValue('')
                                                    // 如果添加了分组却没有添加属性，则不去调用查询接口
                                                    if (addFlag == 0) {
                                                        searchPropertyGroupNameBySN(item)
                                                        setGroupData([])
                                                    }
                                                }} >
                                                <PlusOutlined /> <span style={{ fontSize: "14px" }} >新增分组</span>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                </Spin>

                <Modal
                    title="高级筛选"
                    visible={isFilter}
                    onCancel={() => {
                        setIsFilter(false)
                    }}
                    centered={true}
                    footer={<div style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Button style={{ width: "65px" }} onClick={() => setIsFilter(false)}>取消</Button>
                        <Button style={{ width: "65px" }} onClick={() => {
                            let obj = [...highSearchData]
                            obj.map(item => {
                                if (item.search_tactics == '范围筛选') {
                                    item.value = ['', '']
                                } else {
                                    item.value = ""
                                }
                            })
                            setHighSearchData(obj)
                            setFilterTags([])
                            setIsFilterTags(false)
                        }}>重置</Button>
                        <Button style={{ width: "65px" }} type="primary" onClick={() => {
                            searchGoodsByInfo()
                            setIsFilterTags(true)
                        }}>确定</Button>
                    </div>}
                    width={1000}
                >
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {highSearchData.map((item, index) => {
                            return <div style={{ display: "flex", alignItems: 'center' }}>
                                <span style={{ width: "110px", textAlign: "right", margin: '10px', fontSize: '10px' }}>{item.name}</span>
                                {item.search_tactics == '下拉筛选' ? <Select style={{ width: '160px', height: '32px' }} placeholder={`请选择${item.name}`} value={item.value == "" ? null : item.value} onChange={(value) => {
                                    let obj = [...highSearchData]
                                    obj[index]["value"] = value
                                    setHighSearchData(obj)
                                }}>
                                    {item.select_value.map(option => {
                                        return <Option value={option}>{option}</Option>
                                    })}
                                </Select> :
                                    item.search_tactics == '枚举筛选' ? <Input style={{ width: '160px', height: '32px' }} placeholder={`请输入${item.name}`} value={item.value == "" ? null : item.value} onChange={(e) => {
                                        let obj = [...highSearchData]
                                        obj[index]["value"] = e.target.value
                                        setHighSearchData(obj)
                                    }} /> :
                                        item.search_tactics == '范围筛选' ? item.data_type == '日期' ? <RangePicker style={{ width: "450px", height: '32px' }} size='middle' locale={locale} value={item.value[0] == '' ? ['', ''] : [moment(item.value[0], dateFormat), moment(item.value[1], dateFormat)]} onChange={(date, dateString) => {
                                            // if (dateString[0] == '') {
                                            //     message.warn('请选择正确的时间')
                                            //     return false
                                            // } else {

                                            //     setHighStartTime(dateString[0])
                                            //     setHighEndTime(dateString[1])
                                            // }
                                            let obj = [...highSearchData]
                                            obj[index]["value"] = [dateString[0], dateString[1]]
                                            setHighSearchData(obj)
                                        }} /> : <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Input style={{ width: '65px', height: '32px' }} placeholder={"上限"} value={item.value[0] == "" ? null : item.value[0]} onChange={(e) => {
                                                let obj = [...highSearchData]
                                                obj[index]["value"][0] = e.target.value
                                                setHighSearchData(obj)
                                            }} />
                                            <div style={{ width: '30px', textAlign: 'center' }}>-</div>
                                            <Input style={{ width: '65px' }} placeholder={"下限"} value={item.value[1] == "" ? null : item.value[1]} onChange={(e) => {
                                                let obj = [...highSearchData]
                                                obj[index]["value"][1] = e.target.value
                                                setHighSearchData(obj)
                                            }} />
                                        </div> :
                                            <Input style={{ width: '160px', height: '32px' }} placeholder={`请输入${item.name}`} value={item.value == "" ? null : item.value} onChange={(e) => {
                                                let obj = [...highSearchData]
                                                obj[index]["value"] = e.target.value
                                                setHighSearchData(obj)
                                            }} />}
                            </div>
                        })}
                        <div style={{ display: "flex" }}>
                            <span style={{ width: "110px", textAlign: "right", margin: '10px', fontSize: '10px' }}>标签</span>
                            <Select mode="multiple" allowClear showSearch={true} style={{ width: "740px" }} placeholder="请选择标签,可多选" value={filterTags}
                                onChange={(value) => setFilterTags(value)}
                            >
                                {tagsData.map((item) => {
                                    return <Option value={`${item.id}&${item.name}`}>{item.name}</Option>
                                })}
                            </Select>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="新增分组"
                    visible={isAddGroup}
                    onCancel={() => {
                        setIsAddGroup(false)
                    }}
                    centered={true}
                    footer={<div style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Button style={{ width: "65px" }} onClick={() => { setIsAddGroup(false) }}>取消</Button>
                        <Button style={{ width: "65px" }} type="primary" onClick={() => {
                            if (groupData.length == 0) {
                                setIsAddGroup(false)
                            } else {
                                let obj = [...specificationData]
                                obj[temporaryAddGroup.index]['property_model'].push({
                                    group_name: groupValue,
                                    group_data: []
                                })
                                setSpecificationData(obj)
                                setIsAddGroup(false)
                                message.success("新增成功")
                                groupData.remove(groupValue)
                                setAddFlag(1)
                            }
                        }} disabled={groupValue == '' ? true : false}>确定</Button>
                    </div>}
                    width={700}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "120px" }}>
                        {groupData.length == 0 ? <div>无可新增分组</div> : addFlag == 0 ? <div>
                            <b style={{ width: "70px", textAlign: "right", marginRight: '20px' }}>分组名称:</b>
                            <Select style={{ width: "300px" }} value={groupValue} onChange={value => setGroupValue(value)}>
                                {groupData.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })}
                            </Select>
                        </div> : <div>请先添加属性</div>}
                    </div>
                </Modal>
                <Modal
                    title="修改结果"
                    visible={isUpdateResult}
                    onCancel={() => {
                        setIsUpdateResult(false)
                    }}
                    centered={true}
                    onOk={() => setIsUpdateResult(false)}
                    okText="确认"
                    cancelText="取消"
                    width={700}
                >
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: "120px" }}>
                            <div>
                                <span style={{ fontSize: '16px' }}>成功条数:</span>
                                <span style={{ fontSize: '16px' }}>{updateResultData.total_success}</span>
                            </div>
                            <div>
                                <span style={{ fontSize: '16px' }}>失败条数:</span>
                                <span style={{ fontSize: '16px' }}>{updateResultData.total_fail}</span>
                            </div>
                        </div>
                        {updateResultData.alter_fail_goods.length !== 0 ?
                            <div>
                                失败详情
                                <Table columns={updataResultColumns} dataSource={updateResultData.alter_fail_goods} size='small' pagination={false} />
                            </div>
                            : ""}

                    </div>
                </Modal>
                <Modal
                    title="添加属性"
                    visible={isAddProperty}
                    onCancel={() => {
                        setIsAddProperty(false)
                    }}
                    centered={true}
                    footer={<div style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Button style={{ width: "65px" }} onClick={() => { setIsAddProperty(false) }}>取消</Button>
                        <Button style={{ width: "65px" }} type="primary" onClick={() => {
                            if (propertyData.length == 0) {
                                setIsAddProperty(false)
                            } else {
                                addNewPropertyForStandModel()
                            }
                        }} disabled={propertyValue == '' ? true : false}>确定</Button>
                    </div>}
                    width={700}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "120px" }}>
                        {propertyData.length == 0 ? <div>无可新增属性</div> : <div>
                            <b style={{ width: "70px", textAlign: "right", marginRight: '20px' }}>属性名:</b>
                            <Select style={{ width: "300px" }} value={propertyValue} onChange={value => setPropertyValue(value)}>
                                {propertyData.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })}
                            </Select>
                        </div>}
                    </div>
                </Modal>
                {/* <Modal
                    title="商品详情"
                    visible={isDetails}
                    onCancel={() => {
                        setIsDetails(false)
                    }}
                    onOk={() => {
                        setIsDetails(false)
                    }}
                    centered={true}
                    okText="确定"
                    cancelText="取消"
                    width={"810px"}
                >
                    {
                        isEditDetails ? <div style={{ padding: "20px", display: 'flex', height: "148px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center", }}>
                                <Image src='https://pic.bmcsoft.cn/it/JORYA/656728153951.jpg' style={{ width: "105px", height: "105px" }} />
                                <div>
                                    <div style={{ display: 'flex', marginLeft: "40px" }}>
                                        <span style={{ width: "56px", textAlign: "right" }}>商品ID</span>
                                        <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"666846252631"} />
                                        <span style={{ width: "56px", textAlign: "right", marginLeft: "40px" }}>吊牌价</span>
                                        <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"1980"} />
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: "40px", marginTop: "10px" }}>
                                        <span style={{ width: "56px", textAlign: "right" }}>SPU</span>
                                        <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"EJWCAQ72"} />
                                        <span style={{ width: "56px", textAlign: "right", marginLeft: "40px" }}>上新价</span>
                                        <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"1389"} />
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: "40px", marginTop: "10px" }}>
                                        <span style={{ width: "56px", textAlign: "right" }}>盘货品类</span>
                                        <Select size="small" style={{ marginLeft: "20px", width: "120px" }}></Select>
                                        <span style={{ width: "56px", textAlign: "right", marginLeft: "40px" }}>上架时间</span>
                                        <DatePicker size="small" style={{ marginLeft: "20px", width: "120px", }} value={moment("2022-02-09")} />
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: "40px", marginTop: "10px" }}>
                                        <span style={{ width: "56px", textAlign: "right" }}>款号年份</span>
                                        <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"2022年"} />
                                        <span style={{ width: "56px", textAlign: "right", marginLeft: "40px" }}>上市月份</span>
                                        <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"2月"} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a onClick={() => setIsEditDetails(false)}>预览</a>
                            </div>
                        </div>
                            : <div style={{ padding: "20px", display: 'flex', height: "148px", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center", }}>
                                    <Image src='https://pic.bmcsoft.cn/it/JORYA/656728153951.jpg' style={{ width: "105px", height: "105px" }} />
                                    <div>
                                        <div style={{ display: 'flex', marginLeft: "40px" }}>
                                            <span style={{ width: "56px", textAlign: "right" }}>商品ID</span>
                                            <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"666846252631"} disabled />
                                            <span style={{ width: "56px", textAlign: "right", marginLeft: "40px" }}>吊牌价</span>
                                            <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"1980"} disabled />
                                        </div>
                                        <div style={{ display: 'flex', marginLeft: "40px", marginTop: "10px" }}>
                                            <span style={{ width: "56px", textAlign: "right" }}>SPU</span>
                                            <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"EJWCAQ72"} disabled />
                                            <span style={{ width: "56px", textAlign: "right", marginLeft: "40px" }}>上新价</span>
                                            <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"1389"} disabled />
                                        </div>
                                        <div style={{ display: 'flex', marginLeft: "40px", marginTop: "10px" }}>
                                            <span style={{ width: "56px", textAlign: "right" }}>盘货品类</span>
                                            <Select size="small" style={{ marginLeft: "20px", width: "120px" }} disabled></Select>
                                            <span style={{ width: "56px", textAlign: "right", marginLeft: "40px" }}>上架时间</span>
                                            <DatePicker size="small" style={{ marginLeft: "20px", width: "120px", }} value={moment("2022-02-09")} disabled />
                                        </div>
                                        <div style={{ display: 'flex', marginLeft: "40px", marginTop: "10px" }}>
                                            <span style={{ width: "56px", textAlign: "right" }}>款号年份</span>
                                            <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"2022年"} disabled />
                                            <span style={{ width: "56px", textAlign: "right", marginLeft: "40px" }}>上市月份</span>
                                            <Input size="small" style={{ marginLeft: "20px", width: "120px", padding: "0px" }} value={"2月"} disabled />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a onClick={() => setIsEditDetails(true)}>编辑</a>
                                </div>
                            </div>
                    }

                </Modal> */}

                <Modal
                    title="文件导入"
                    visible={isUploadFile}
                    centered={true}
                    onCancel={() => {
                        setIsUploadFile(false)
                        setFileList([]);
                        setErrorMsg('请上传文件')
                    }}
                    footer={<div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => {
                            setIsUploadFile(false)
                            setFileList([]);
                            setErrorMsg('请上传文件')
                        }}>取消</Button>
                        <Button type="primary" onClick={() => {
                            const getAllData = async () => {
                                await Promise.all([
                                    uploadFile(),
                                    setErrorMsg('上传中'),
                                    setExportFlag(1)
                                ])
                            }
                            getAllData()
                        }} disabled={fileList.length == 0 ? true : false}>上传</Button>
                    </div>}
                >
                    {/* <Spin style={{ display: tabKey == 'productList' ? '' : 'none' }} spinning={loading} tip="数据加载中...."> */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div>
                            <a href={`${servicePath.downloadUploadGoodsExcelTemplate}?shop_name=${shopDict[props.history.location.pathname.split('/')[2]]}`}>
                                <Button>
                                    <DownloadOutlined /> 下载模版
                                </Button>
                            </a>

                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* <ExclamationCircleOutlined style={{color:"#a3986d",width:'30px'}}/> */}
                        {exportFlag == 0 ? <Progress type="circle" percent={0} /> : exportFlag == 1 ? <Spin size="large" /> : exportFlag == 2 ? <Progress type="circle" percent={100} /> : <Progress type="circle" percent={70} status="exception" />}
                    </div>
                    {/* <div style={{ marginTop: "30px" ,display: 'flex', justifyContent: 'center',alignItems:'center'}}>
                        {exportFlag == 0?<b>确认导出所有数据吗？</b>:exportFlag == 1?<b>导出中</b>:<b>导出完成</b>}
                    </div> */}
                    <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '15px' }}>
                        <b >{errorMsg}</b>
                    </div>
                    <div style={{ marginTop: "30px", textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <Upload
                                accept=".xlsx"
                                action={servicePath.allowUploadFile}                         //上传地址，填错/不填上传图片的时候都会报错
                                listType="picture"                                 // 上传列表的内建样式
                                fileList={fileList}                                //已经上传的文件列表（受控）
                                onChange={fileHandleChange}
                                multiple={true}
                            >
                                <Button>
                                    <UploadOutlined /> 上传文件
                                </Button>
                            </Upload>
                            <span style={{ marginLeft: '10px' }}>仅支持扩展名:.xlsx</span>
                        </div>
                    </div>

                    {/* </Spin> */}
                </Modal>
                <Modal
                    title="文件设定"
                    visible={isTagUploadFile}
                    centered={true}
                    onCancel={() => {
                        setIsTagUploadFile(false)
                        setTagFileList([]);
                        setTagErrorMsg('请上传文件')
                    }}
                    footer={<div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => {
                            setIsTagUploadFile(false)
                            setTagFileList([]);
                            setTagErrorMsg('请上传文件')
                        }}>取消</Button>
                        <Button type="primary" onClick={() => {
                            const getAllData = async () => {
                                await Promise.all([
                                    setTagErrorMsg('上传中'),
                                    setExportFlag(1),
                                    tagUploadFile(),
                                ])
                            }
                            if (tagsOptions.length == 0) {
                                message.warning("请选择设定标签策略")
                            } else {
                                getAllData()
                            }
                        }} disabled={tagFileList.length == 0 ? true : false}>上传</Button>
                    </div>}
                >
                    {/* <Spin style={{ display: tabKey == 'productList' ? '' : 'none' }} spinning={loading} tip="数据加载中...."> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: "10px" }}>
                            <Radio.Group options={tagsOptionsList} buttonStyle="solid" onChange={(e) => setTagsOptions(e.target.value)} value={tagsOptions} optionType="button" />
                        </div>
                        <div>

                            <a href={`${servicePath.downloadSetGoodsTagsExcelTemplate}?shop_name=${shopDict[props.history.location.pathname.split('/')[2]]}`}>
                                <Button>
                                    <DownloadOutlined /> 下载模版
                                </Button>
                            </a>

                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* <ExclamationCircleOutlined style={{color:"#a3986d",width:'30px'}}/> */}
                        {exportFlag == 0 ? <Progress type="circle" percent={0} /> : exportFlag == 1 ? <Spin size="large" /> : exportFlag == 2 ? <Progress type="circle" percent={100} /> : <Progress type="circle" percent={70} status="exception" />}
                    </div>
                    {/* <div style={{ marginTop: "30px" ,display: 'flex', justifyContent: 'center',alignItems:'center'}}>
                        {exportFlag == 0?<b>确认导出所有数据吗？</b>:exportFlag == 1?<b>导出中</b>:<b>导出完成</b>}
                    </div> */}
                    <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '15px' }}>
                        <b >{tagErrorMsg}</b>
                    </div>
                    <div style={{ marginTop: "30px", textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <Upload
                                accept=".xlsx"
                                action={servicePath.allowUploadFile}                         //上传地址，填错/不填上传图片的时候都会报错
                                listType="picture"                                 // 上传列表的内建样式
                                fileList={tagFileList}                                //已经上传的文件列表（受控）
                                onChange={tagFileHandleChange}
                                multiple={true}
                            >
                                <Button>
                                    <UploadOutlined /> 上传文件
                                </Button>
                            </Upload>
                            <span style={{ marginLeft: '10px' }}>仅支持扩展名:.xlsx</span>
                        </div>
                    </div>

                    {/* </Spin> */}
                </Modal>
                <Modal
                    title="全部模型"
                    visible={isExportAllModel}
                    centered={true}
                    onCancel={() => {
                        if(exportFlag == 1){
                            message.warning("导出中,请勿操作")
                        }else{
                            setIsExportAllModel(false)
                        }
                    }}
                    onOk={() => {
                        if(exportFlag == 1){
                            message.warning("导出中,请勿操作")
                        }else if (exportFlag == 2) {
                            setIsExportAllModel(false)
                        }else {
                            const getAllData = async () => {
                                await Promise.all([
                                    downloadStandardizedModel(),
                                    setExportFlag(1)
                                ])
                            }
                            getAllData()
                        }
                    }}
                    okText="确认"
                    cancelText="取消"
                >
                    {/* <Spin style={{ display: tabKey == 'productList' ? '' : 'none' }} spinning={loading} tip="数据加载中...."> */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <ExclamationCircleOutlined style={{color:"#a3986d",width:'30px'}}/> */}
                            {exportFlag == 0 ? <Progress type="circle" percent={0} /> : exportFlag == 1 ? <Spin size="large" /> : exportFlag == 2 ? <Progress type="circle" percent={100} /> : <Progress type="circle" percent={70} status="exception" />}
                        </div>
                        <div style={{ marginTop: "30px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {exportFlag == 0 ? <b>确认导出所有数据吗？</b> : exportFlag == 1 ? <b>导出中</b> : exportFlag == 2 ? <b>导出完成</b> : <b>导出失败</b>}
                        </div>
                    {/* </Spin> */}
                </Modal>
                <Modal
                    title="导出全部商品"
                    visible={isExportAllGoods}
                    centered={true}
                    onCancel={() => {
                        if(exportFlag == 1){
                            message.warning("导出中,请勿操作")
                        }else{
                            setIsExportAllGoods(false)
                        }
                    }}
                    onOk={() => {
                        if(exportFlag == 1){
                            message.warning("导出中,请勿操作")
                        }else if (exportFlag == 2) {
                            setIsExportAllGoods(false)
                        }else {
                            const getAllData = async () => {
                                await Promise.all([
                                    downloadGoodsByFile(),
                                    setExportFlag(1)
                                ])
                            }
                            getAllData()
                        }
                    }}
                    okText="确认"
                    cancelText="取消"
                >
                    {/* <Spin style={{ display: tabKey == 'productList' ? '' : 'none' }} spinning={loading} tip="数据加载中...."> */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <ExclamationCircleOutlined style={{color:"#a3986d",width:'30px'}}/> */}
                            {exportFlag == 0 ? <Progress type="circle" percent={0} /> : exportFlag == 1 ? <Spin size="large" /> : exportFlag == 2 ? <Progress type="circle" percent={100} /> : <Progress type="circle" percent={70} status="exception" />}
                        </div>
                        <div style={{ marginTop: "30px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {exportFlag == 0 ? <b>确认导出所有数据吗？</b> : exportFlag == 1 ? <b>导出中</b> : exportFlag == 2 ? <b>导出完成</b> : <b>导出失败</b>}
                        </div>
                    {/* </Spin> */}
                </Modal>
                <Drawer
                    title="单款录入"
                    visible={isSingleEntry}
                    onClose={() => closeSingleEntry()}
                    footer={<div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => closeSingleEntry()}>取消</Button>
                        <Button style={{ marginLeft: '10px' }} type="primary" onClick={() => {
                            addNewGoods()
                        }}>确定</Button>
                    </div>}
                    width={"768px"}
                >
                    <div style={{ padding: "10px", overflow: "auto", width: "100%" }}>
                        <div>
                            <b style={{ fontSize: '16px' }}>基础属性</b>
                        </div>
                        <div style={{ marginTop: "10px", display: "grid", gridTemplateColumns: '1fr 50%', textAlign: 'right', marginBottom: '2vh' }}>
                            <div style={{ paddingRight: 20 }}>商品ID
                                <Popover content={<div style={{ color: "red" }}>商品ID与SPU必填其一</div>}>
                                    <Input style={{ width: "160px", marginLeft: "20px" }} size="small" value={singleId} placeholder="请输入商品ID" onChange={(e) => {
                                        // 只能输入数字、字母
                                        let reg = new RegExp("(^[A-Za-z0-9]+$)");
                                        if (reg.test(e.target.value) || e.target.value.length == 0) {
                                            setSingleId(e.target.value)
                                        } else {
                                            message.error("仅可填写数字、字母")
                                        }
                                    }
                                    } />
                                </Popover>
                            </div>
                            <div style={{ paddingRight: 20 }}>吊牌价
                                <Input style={{ width: "160px", marginLeft: "20px" }} size="small" placeholder="请输入吊牌价" value={singleTagPrice} onChange={(e) => {
                                    let reg = new RegExp("^[0-9]*$");
                                    if (reg.test(e.target.value)) {
                                        setSingleTagPrice(e.target.value)
                                    } else {
                                        message.error("只能输入数字")
                                    }
                                }
                                } />
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>SPU
                                <Popover content={<div style={{ color: "red" }}>商品ID与SPU必填其一</div>}>
                                    <Input style={{ width: "160px", marginLeft: "20px" }} size="small" value={singleSpu} placeholder="请输入SPU" onChange={(e) => {
                                        // 只能输入数字、字母
                                        let reg = new RegExp("(^[A-Za-z0-9]+$)");
                                        if (reg.test(e.target.value) || e.target.value.length == 0) {
                                            setSingleSpu(e.target.value)
                                        } else {
                                            message.error("仅可填写数字、字母")
                                        }
                                    }} />
                                </Popover>
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>上新价
                                <Input style={{ width: "160px", marginLeft: "20px" }} size="small" value={singleNewPrice} placeholder="请输入上新价" onChange={(e) => {
                                    let reg = new RegExp("^[0-9]*$");
                                    if (reg.test(e.target.value)) {
                                        setSingleNewPrice(e.target.value)
                                    } else {
                                        message.error("只能输入数字")
                                    }
                                }} />
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>盘货品类
                                {/* <Cascader showSearch style={{ width: "160px", marginLeft: "20px", textAlign: 'left' }} size="small"
                                    key={categoryList}
                                    options={categoryList}
                                    // 鼠标选中即为完全选择
                                    expandTrigger="hover"
                                    // 只显示最后一级
                                    displayRender={displayRender}
                                    onChange={(value) => {
                                        setSingleCategory(value)
                                    }}
                                    placeholder="请选择"
                                    value={singleCategory}
                                    allowClear={false}
                                /> */}
                                <Select showSearch optionFilterProp="children" style={{ width: "160px", marginLeft: "20px", textAlign: 'left' }} size="small" value={singleCategory} onChange={(value) => {
                                    setSingleCategory(value)
                                }}>
                                    {
                                        categoryList.map(item => {
                                            if (Object.keys(item).indexOf("children") !== -1) {
                                                return item["children"].map(citem => {
                                                    return <OptGroup label={item.label + "-" + citem.label}>
                                                        {Object.keys(citem).indexOf("children") !== -1 ?
                                                            citem["children"].map(ccitem => {
                                                                return <Option value={ccitem.value}>{ccitem.value + "-" + ccitem.label}</Option>
                                                            })
                                                            : ""}
                                                    </OptGroup>
                                                })
                                            } else if (item.level == 3) {
                                                return <Option value={item.value}>{item.value + "-" + item.label}</Option>
                                            } else {
                                                return <OptGroup label={item.label}></OptGroup>
                                            }
                                        })
                                    }

                                </Select>
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>上架时间
                                <DatePicker disabledDate={disabledDate} locale={locale} style={{ width: "160px", marginLeft: "20px" }} size="small" value={singleAddTime == '' ? '' : moment(singleAddTime, dateFormat)} onChange={(value, dateString) => {
                                    // if (dateString == '') {
                                    //     message.warn('请选择合理的时间')
                                    //     return false
                                    // } else {
                                    //     setSingleAddTime(dateString)
                                    // }
                                    setSingleAddTime(dateString)
                                }} />
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>款号年份
                                <DatePicker disabledDate={disabledDate} locale={locale} style={{ width: "160px", marginLeft: "20px" }} size="small" picker="year" value={singleListingYear == '' ? '' : moment(singleListingYear, yearFormat)} onChange={(value, dateString) => {
                                    // if (dateString == '') {
                                    //     message.warn('请选择合理的时间')
                                    //     return false
                                    // } else {
                                    //     setSingleListingYear(dateString)
                                    // }
                                    setSingleListingYear(dateString)
                                }} />
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>上市月份
                                <Select style={{ width: "160px", marginLeft: "20px", textAlign: 'left' }} size="small" value={singleListingMonth} onChange={(value) => {
                                    setSingleListingMonth(value)
                                }}>
                                    {
                                        monthList.map(item => {
                                            return <Option value={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </div>
                        </div>

                        <div>
                            {
                                singleData.map((item, index) => {
                                    return (
                                        <div style={{ marginBottom: '1vh' }}>
                                            <b style={{ fontSize: '16px' }}>{item.name}</b>
                                            <div style={{ display: "grid", gridTemplateColumns: '1fr 50%', textAlign: 'right' }}>
                                                {item.value.map((obj, inx) => {
                                                    return obj.selection_flag == 0 ? obj.data_type == "字符" ?
                                                        <div style={{ marginTop: 10, paddingRight: 20 }}>
                                                            {obj.name}
                                                            <Input style={{ width: "160px", marginLeft: "20px" }} size="small" placeholder={`请输入${obj.name}`} value={singleAddDict[obj.name] ? singleAddDict[obj.name] : obj.value} onChange={(e) => {
                                                                let data = [...singleData]
                                                                data[index]["value"][inx]["value"] = e.target.value
                                                                setSingleData(data)
                                                            }
                                                            } disabled={Object.keys(singleAddDict).indexOf(obj.name) !== -1 || obj.name == "盘货品类" ? true : false} />
                                                        </div> : <div style={{ marginTop: 10, paddingRight: 20 }}>
                                                            {obj.name}
                                                            <Input style={{ width: "160px", marginLeft: "20px" }} size="small" placeholder={`请输入${obj.name}`} value={singleAddDict[obj.name] ? singleAddDict[obj.name] : obj.value} onChange={(e) => {
                                                                let reg = new RegExp("^[0-9]*$");
                                                                if (reg.test(e.target.value)) {
                                                                    let data = [...singleData]
                                                                    data[index]["value"][inx]["value"] = e.target.value
                                                                    setSingleData(data)
                                                                } else {
                                                                    message.error("只能输入数字")
                                                                }
                                                            }
                                                            } disabled={Object.keys(singleAddDict).indexOf(obj.name) !== -1 ? true : false} />
                                                        </div> : <div style={{ marginTop: 10, paddingRight: 20 }}>
                                                        {obj.name}
                                                        {/* <Cascader showSearch style={{ width: "160px", marginLeft: "20px", textAlign: 'left' }} size="small"
                                                            key={obj.all_value}
                                                            options={obj.all_value}
                                                            // 鼠标选中即为完全选择
                                                            expandTrigger="hover"
                                                            // 只显示最后一级
                                                            displayRender={displayRender}
                                                            onChange={(value) => {
                                                                let data = [...singleData]
                                                                data[index]["value"][inx]["value"] = value
                                                                setSingleData(data)
                                                            }}
                                                            placeholder={`请选择${obj.name}`}
                                                            value={singleAddDict[obj.name]?[singleAddDict[obj.name]]:obj.value}
                                                            disabled={Object.keys(singleAddDict).indexOf(obj.name) !== -1?true:false}
                                                            onClick={() => {
                                                                let dataProps = {
                                                                    shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                                                    property_info: obj,
                                                                    roleId: cookie.load('role'),
                                                                    shopList: cookie.load('shopList'),
                                                                }
                                                                // 查询属性下拉取值
                                                                NbAxios(dataProps, "POST", servicePath.searchPropertyValueByInfo).then(res => {
                                                                    setLoading(false)
                                                                    if (res.success) {
                                                                        let data = [...singleData]
                                                                        data[index]["value"][inx]["all_value"] = res.data
                                                                        // setSingleData(data)
                                                                        setSingleData(data)
                                                                    } else {
                                                                        message.warn({ content: res.errorMsg })
                                                                    }
                                                                }).catch((err) => {
                                                                    setLoading(false)
                                                                    // 获取500错误
                                                                    message.warn('查询属性下拉取值报错，请联系IT帮忙解决')
                                                                })
                                                            }} /> */}
                                                        <Select showSearch style={{ width: "160px", marginLeft: "20px", textAlign: 'left' }} size="small"
                                                            onChange={(value) => {
                                                                let data = [...singleData]
                                                                data[index]["value"][inx]["value"] = value
                                                                setSingleData(data)
                                                            }}
                                                            placeholder={`请选择${obj.name}`}
                                                            value={singleAddDict[obj.name] ? [singleAddDict[obj.name]] : obj.value}
                                                            disabled={Object.keys(singleAddDict).indexOf(obj.name) !== -1 ? true : false}
                                                            onClick={() => {
                                                                let dataProps = {
                                                                    shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                                                    property_info: obj,
                                                                    roleId: cookie.load('role'),
                                                                    shopList: cookie.load('shopList'),
                                                                }
                                                                // 查询属性下拉取值
                                                                NbAxios(dataProps, "POST", servicePath.searchPropertyValueByInfo).then(res => {
                                                                    setLoading(false)
                                                                    if (res.success) {
                                                                        let data = [...singleData]
                                                                        data[index]["value"][inx]["all_value"] = res.data
                                                                        // setSingleData(data)
                                                                        setSingleData(data)
                                                                    } else {
                                                                        message.warn({ content: res.errorMsg })
                                                                    }
                                                                }).catch((err) => {
                                                                    setLoading(false)
                                                                    // 获取500错误
                                                                    message.warn('查询属性下拉取值报错，请联系IT帮忙解决')
                                                                })
                                                            }}
                                                        >
                                                            {obj.all_value.map(item => {
                                                                return <Option value={item.value}>{item.label}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                })}
                                            </div>

                                        </div>)
                                })
                            }
                        </div>

                        <div>
                            <b style={{ fontSize: '16px' }}>标签</b>
                        </div>
                        <div style={{ width: "97%", padding: "2px", border: "1px solid #ccc", marginTop: "2px" }}>
                            <div style={{ display: 'flex', alignItems: "center", flexWrap: 'wrap', padding: "0px" }}>
                                {
                                    singleTagList.map((tagItem, index) => {
                                        /**
                                         * 鼠标右键事件 trigger="contextMenu"
                                         */
                                        return <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                            let data = [...singleTagList]
                                            data.remove(tagItem)
                                            setSingleTagList(data)
                                        }}>
                                            <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px" }}>
                                                {tagItem.name}
                                            </div>
                                        </Popconfirm>
                                    })
                                }
                                <Popover content={singleAddTags()} placement="bottom" trigger="hover" onMouseLeave={()=>setTagsList(tagsData)}>
                                    <PlusSquareOutlined />
                                </Popover>
                                {/* <AddTags tags={tags} setTags={setTags} disabled={isEditDetails}></AddTags> */}
                            </div>
                        </div>


                    </div>
                </Drawer>
                <Drawer
                    title="商品详情"
                    visible={isGoodsDetail}
                    onClose={() => setIsGoodsDetail(false)}
                    footer={<div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => setIsGoodsDetail(false)}>取消</Button>
                        <Button style={{ marginLeft: '10px' }} type="primary" onClick={() => {
                            // addNewGoods()
                            if (isEditDetails) {
                                setIsGoodsDetail(false)
                            } else {
                                alterOneGoodsDetail()
                            }
                        }}>确定</Button>
                    </div>}
                    width={"768px"}
                >
                    <div style={{ padding: "10px", overflow: "auto", width: "100%" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <b style={{ fontSize: '16px' }}>基础属性</b>
                            <a style={{ fontSize: '16px', marginRight: "20px" }} onClick={() => {
                                if (isEditDetails) {
                                    setIsEditDetails(false)
                                } else {
                                    setIsEditDetails(true)
                                }
                            }}>{isEditDetails ? "编辑" : "预览"}</a>
                        </div>
                        <div style={{ marginTop: "10px", display: "grid", gridTemplateColumns: '1fr 50%', textAlign: 'right', marginBottom: '2vh' }}>
                            <div style={{ paddingRight: 20 }}>商品ID
                                <Popover content={<div style={{ color: "red" }}>商品ID与SPU必填其一</div>}>
                                    <Input style={{ width: "160px", marginLeft: "20px" }} size="small" placeholder="请输入商品ID" value={goodsDetailData.base["商品ID"] == '' ? null : goodsDetailData.base["商品ID"]} onChange={(e) => {
                                        // let obj = {}; // 新的空对象
                                        // // 将 原数据 中所有的属性拷贝到其中
                                        // for (let key in goodsDetailData) {
                                        //     obj[key] = goodsDetailData[key];
                                        // }
                                        // 只能输入数字、字母
                                        let reg = new RegExp("(^[A-Za-z0-9]+$)");
                                        if (reg.test(e.target.value) || e.target.value.length == 0) {
                                            // 原数据的属性克隆到一个空对象（浅拷贝）
                                            let obj = Object.assign({}, goodsDetailData);
                                            // 改变其数据并重新赋值给原数据
                                            obj.base["商品ID"] = e.target.value
                                            setGoodsDetailData(obj)
                                        } else {
                                            message.error("仅可填写数字、字母")
                                        }
                                    }}
                                        disabled={isEditDetails} />
                                </Popover>
                            </div>
                            <div style={{ paddingRight: 20 }}>吊牌价
                                <Input style={{ width: "160px", marginLeft: "20px" }} size="small" value={goodsDetailData.base["吊牌价"] == '' ? null : goodsDetailData.base["吊牌价"]} onChange={(e) => {
                                    let reg = new RegExp("^[0-9.]*$");
                                    if (reg.test(e.target.value)) {
                                        // 原数据的属性克隆到一个空对象（浅拷贝）
                                        let obj = Object.assign({}, goodsDetailData);
                                        obj.base["吊牌价"] = e.target.value
                                        setGoodsDetailData(obj)
                                    } else {
                                        message.error("只能输入数字")
                                    }
                                }
                                }
                                    disabled={isEditDetails} />
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>SPU
                                <Popover content={<div style={{ color: "red" }}>商品ID与SPU必填其一</div>}>
                                    <Input style={{ width: "160px", marginLeft: "20px" }} size="small" value={goodsDetailData.base["SPU"] == '' ? null : goodsDetailData.base["SPU"]} onChange={(e) => {
                                        let reg = new RegExp("(^[A-Za-z0-9]+$)");
                                        if (reg.test(e.target.value) || e.target.value.length == 0) {
                                            // 原数据的属性克隆到一个空对象（浅拷贝）
                                            let obj = Object.assign({}, goodsDetailData);
                                            obj.base["SPU"] = e.target.value
                                            setGoodsDetailData(obj)
                                        } else {
                                            message.error("仅可填写数字、字母")
                                        }
                                    }}
                                        disabled={isEditDetails} />
                                </Popover>
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>上新价
                                <Input style={{ width: "160px", marginLeft: "20px" }} size="small" value={goodsDetailData.base["上新价"] == '' ? null : goodsDetailData.base["上新价"]} onChange={(e) => {
                                    let reg = new RegExp("^[0-9.]*$");
                                    if (reg.test(e.target.value)) {
                                        // 原数据的属性克隆到一个空对象（浅拷贝）
                                        let obj = Object.assign({}, goodsDetailData);
                                        obj.base["上新价"] = e.target.value
                                        setGoodsDetailData(obj)
                                    } else {
                                        message.error("只能输入数字")
                                    }
                                }}
                                    disabled={isEditDetails} />
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>盘货品类
                                {/* <Cascader showSearch style={{ width: "160px", marginLeft: "20px", textAlign: 'left' }} size="small"
                                    // key={categoryList}
                                    options={categoryList}
                                    // 鼠标选中即为完全选择
                                    expandTrigger="hover"
                                    // 只显示最后一级
                                    displayRender={displayRender}
                                    allowClear={false}
                                    onChange={(value) => {
                                        if (value.length != 0) {
                                            // 盘货品类发生更改
                                            // 原数据的属性克隆到一个空对象（浅拷贝）
                                            let obj = Object.assign({}, goodsDetailData);
                                            obj.base["盘货品类"] = value
                                            // setGoodsDetailData(obj)
                                            // 调取查询属性列表 
                                            let dataProps = {
                                                roleId: cookie.load('role'),
                                                shopList: cookie.load('shopList'),
                                                shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                                cid: value
                                            }
                                            NbAxios(dataProps, "POST", servicePath.searchPropertyModelByCategory).then(res => {
                                                setLoading(false)
                                                if (res.success) {
                                                    obj.detail = res.data
                                                    setGoodsDetailData(obj)
                                                } else {
                                                    message.warn({ content: res.errorMsg })
                                                }
                                            }).catch((err) => {
                                                setLoading(false)
                                                // 获取500错误
                                                message.warn('查询属性列表报错，请联系IT帮忙解决')
                                            })
                                        } else {
                                            // 盘货品类被删除
                                            // 原数据的属性克隆到一个空对象（浅拷贝）
                                            let obj = Object.assign({}, goodsDetailData);
                                            obj.base["盘货品类"] = ''
                                            obj.detail = []
                                            setGoodsDetailData(obj)
                                        }

                                    }}
                                    value={goodsDetailData.base["盘货品类"]}
                                    disabled={isEditDetails}
                                /> */}
                                <Select showSearch allowClear={false} optionFilterProp="children" style={{ width: "160px", marginLeft: "20px", textAlign: 'left' }} size="small" onChange={(value) => {
                                    if (value.length != 0) {
                                        // 盘货品类发生更改
                                        // 原数据的属性克隆到一个空对象（浅拷贝）
                                        let obj = Object.assign({}, goodsDetailData);
                                        obj.base["盘货品类"] = value
                                        // setGoodsDetailData(obj)
                                        // 调取查询属性列表 
                                        let dataProps = {
                                            roleId: cookie.load('role'),
                                            shopList: cookie.load('shopList'),
                                            shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                            cid: value
                                        }
                                        NbAxios(dataProps, "POST", servicePath.searchPropertyModelByCategory).then(res => {
                                            setLoading(false)
                                            if (res.success) {
                                                obj.detail = res.data
                                                setGoodsDetailData(obj)
                                            } else {
                                                message.warn({ content: res.errorMsg })
                                            }
                                        }).catch((err) => {
                                            setLoading(false)
                                            // 获取500错误
                                            message.warn('查询属性列表报错，请联系IT帮忙解决')
                                        })
                                    } else {
                                        // 盘货品类被删除
                                        // 原数据的属性克隆到一个空对象（浅拷贝）
                                        let obj = Object.assign({}, goodsDetailData);
                                        obj.base["盘货品类"] = ''
                                        obj.detail = []
                                        setGoodsDetailData(obj)
                                    }

                                }}
                                    value={goodsDetailData.base["盘货品类"]}
                                    disabled={isEditDetails}
                                >
                                    {
                                        categoryList.map(item => {
                                            if (Object.keys(item).indexOf("children") !== -1) {
                                                return item["children"].map(citem => {
                                                    return <OptGroup label={item.label + "-" + citem.label}>
                                                        {Object.keys(citem).indexOf("children") !== -1 ?
                                                            citem["children"].map(ccitem => {
                                                                return <Option value={ccitem.value}>{ccitem.value + "-" + ccitem.label}</Option>
                                                            })
                                                            : ""}
                                                    </OptGroup>
                                                })
                                            } else if (item.level == 3) {
                                                return <Option value={item.value}>{item.value + "-" + item.label}</Option>
                                            } else {
                                                return <OptGroup label={item.label}></OptGroup>
                                            }
                                        })
                                    }

                                </Select>
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>上架时间
                                <DatePicker disabledDate={disabledDate} locale={locale} style={{ width: "160px", marginLeft: "20px" }} size="small" value={goodsDetailData.base["上架时间"] == '' ? '' : moment(goodsDetailData.base["上架时间"], dateFormat)} onChange={(value, dateString) => {
                                    // if (dateString == '') {
                                    //     message.warn('请选择合理的时间')
                                    //     return false
                                    // } else {
                                    //     // 原数据的属性克隆到一个空对象（浅拷贝）
                                    //     let obj = Object.assign({}, goodsDetailData);
                                    //     obj.base["上架时间"] = dateString
                                    //     setGoodsDetailData(obj)
                                    // }
                                    // 原数据的属性克隆到一个空对象（浅拷贝）
                                    let obj = Object.assign({}, goodsDetailData);
                                    obj.base["上架时间"] = dateString
                                    setGoodsDetailData(obj)
                                }}
                                    disabled={isEditDetails} />
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>款号年份
                                <DatePicker disabledDate={disabledDate} locale={locale} style={{ width: "160px", marginLeft: "20px" }} size="small" picker="year" value={goodsDetailData.base["款号年份"] == '' ? '' : moment(goodsDetailData.base["款号年份"], yearFormat)} onChange={(value, dateString) => {
                                    // if (dateString == '') {
                                    //     message.warn('请选择合理的时间')
                                    //     return false
                                    // } else {
                                    //     // 原数据的属性克隆到一个空对象（浅拷贝）
                                    //     let obj = Object.assign({}, goodsDetailData);
                                    //     obj.base["款号年份"] = dateString
                                    //     setGoodsDetailData(obj)
                                    // }
                                    // 原数据的属性克隆到一个空对象（浅拷贝）
                                    let obj = Object.assign({}, goodsDetailData);
                                    obj.base["款号年份"] = dateString
                                    setGoodsDetailData(obj)
                                }}
                                    disabled={isEditDetails} />
                            </div>
                            <div style={{ marginTop: 10, paddingRight: 20 }}>上市月份
                                <Select style={{ width: "160px", marginLeft: "20px", textAlign: 'left' }} size="small" value={goodsDetailData.base["上市月份"] == '' ? null : goodsDetailData.base["上市月份"]} onChange={(value) => {
                                    // 原数据的属性克隆到一个空对象（浅拷贝）
                                    let obj = Object.assign({}, goodsDetailData);
                                    obj.base["上市月份"] = value
                                    setGoodsDetailData(obj)
                                }}
                                    disabled={isEditDetails}
                                >
                                    {
                                        monthList.map(item => {
                                            return <Option value={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </div>
                        </div>

                        <div>
                            {
                                goodsDetailData.detail.map((item, index) => {
                                    return (
                                        <div style={{ marginBottom: '1vh' }}>
                                            <b style={{ fontSize: '16px' }}>{item.name}</b>
                                            <div style={{ display: "grid", gridTemplateColumns: '1fr 50%', textAlign: 'right' }}>
                                                {item.value.map((obj, inx) => {
                                                    return obj.selection_flag == 0 ? obj.data_type == "字符" ?
                                                        <div style={{ marginTop: 10, paddingRight: 20 }}>
                                                            {obj.name}
                                                            <Input style={{ width: "160px", marginLeft: "20px" }} size="small" value={goodsDetailData.base[obj.name] ? goodsDetailData.base[obj.name] : obj.value} onChange={(e) => {
                                                                let data = Object.assign({}, goodsDetailData);
                                                                data.detail[index]["value"][inx]["value"] = e.target.value
                                                                setGoodsDetailData(data)
                                                            }
                                                            }
                                                                disabled={Object.keys(goodsDetailData.base).indexOf(obj.name) !== -1 || obj.name == '盘货品类' ? true : isEditDetails} />
                                                        </div> : <div style={{ marginTop: 10, paddingRight: 20 }}>
                                                            {obj.name}
                                                            <Input style={{ width: "160px", marginLeft: "20px" }} size="small" value={goodsDetailData.base[obj.name] ? goodsDetailData.base[obj.name] : obj.value} onChange={(e) => {
                                                                let reg = new RegExp("^[0-9]*$");
                                                                if (reg.test(e.target.value)) {
                                                                    let data = Object.assign({}, goodsDetailData);
                                                                    data.detail[index]["value"][inx]["value"] = e.target.value
                                                                    setGoodsDetailData(data)
                                                                } else {
                                                                    message.error("只能输入数字")
                                                                }
                                                            }
                                                            }
                                                                disabled={Object.keys(goodsDetailData.base).indexOf(obj.name) !== -1 ? true : isEditDetails} />
                                                        </div> : <div style={{ marginTop: 10, paddingRight: 20 }}>
                                                        {obj.name}
                                                        {/* <Cascader showSearch style={{ width: "160px", marginLeft: "20px", textAlign: 'left' }} size="small"
                                                            options={obj.all_value}
                                                            // 鼠标选中即为完全选择
                                                            expandTrigger="hover"
                                                            // 只显示最后一级
                                                            displayRender={displayRender}
                                                            onChange={(value) => {
                                                                let data = Object.assign({}, goodsDetailData);
                                                                data.detail[index]["value"][inx]["value"] = value
                                                                setGoodsDetailData(data)
                                                            }}
                                                            disabled={Object.keys(goodsDetailData.base).indexOf(obj.name) !== -1 ? true : isEditDetails}
                                                            value={goodsDetailData.base[obj.name] ? [goodsDetailData.base[obj.name]] : obj.value}
                                                            onClick={() => {
                                                                if (isEditDetails) {

                                                                } else {
                                                                    let dataProps = {
                                                                        shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                                                        property_info: obj,
                                                                        roleId: cookie.load('role'),
                                                                        shopList: cookie.load('shopList'),
                                                                    }
                                                                    // 查询属性下拉取值
                                                                    NbAxios(dataProps, "POST", servicePath.searchPropertyValueByInfo).then(res => {
                                                                        setLoading(false)
                                                                        if (res.success) {
                                                                            let data = Object.assign({}, goodsDetailData);
                                                                            data.detail[index]["value"][inx]["all_value"] = res.data
                                                                            setGoodsDetailData(data)
                                                                        } else {
                                                                            message.warn({ content: res.errorMsg })
                                                                        }
                                                                    }).catch((err) => {
                                                                        setLoading(false)
                                                                        // 获取500错误
                                                                        message.warn('查询属性下拉取值报错，请联系IT帮忙解决')
                                                                    })
                                                                }
                                                            }}
                                                        /> */}
                                                        <Select showSearch style={{ width: "160px", marginLeft: "20px", textAlign: 'left' }} size="small"
                                                            onChange={(value) => {
                                                                let data = Object.assign({}, goodsDetailData);
                                                                data.detail[index]["value"][inx]["value"] = value
                                                                setGoodsDetailData(data)
                                                            }}
                                                            placeholder={`请选择${obj.name}`}
                                                            disabled={Object.keys(goodsDetailData.base).indexOf(obj.name) !== -1 ? true : isEditDetails}
                                                            value={goodsDetailData.base[obj.name] ? [goodsDetailData.base[obj.name]] : obj.value}
                                                            onClick={() => {
                                                                if (isEditDetails) {

                                                                } else {
                                                                    let dataProps = {
                                                                        shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                                                        property_info: obj,
                                                                        roleId: cookie.load('role'),
                                                                        shopList: cookie.load('shopList'),
                                                                    }
                                                                    // 查询属性下拉取值
                                                                    NbAxios(dataProps, "POST", servicePath.searchPropertyValueByInfo).then(res => {
                                                                        setLoading(false)
                                                                        if (res.success) {
                                                                            let data = Object.assign({}, goodsDetailData);
                                                                            data.detail[index]["value"][inx]["all_value"] = res.data
                                                                            setGoodsDetailData(data)
                                                                        } else {
                                                                            message.warn({ content: res.errorMsg })
                                                                        }
                                                                    }).catch((err) => {
                                                                        setLoading(false)
                                                                        // 获取500错误
                                                                        message.warn('查询属性下拉取值报错，请联系IT帮忙解决')
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {obj.all_value.map(item => {
                                                                return <Option value={item.value}>{item.label}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                })}
                                            </div>

                                        </div>)
                                })
                            }
                        </div>

                        <div>
                            <b style={{ fontSize: '16px' }}>标签</b>
                        </div>
                        <div style={isEditDetails ? { width: "97%", padding: "2px", border: "1px solid #d9d9d9", marginTop: "2px", background: "#f5f5f5" } : { width: "97%", padding: "2px", border: "1px solid #ccc", marginTop: "2px" }}>
                            <div style={{ display: 'flex', alignItems: "center", flexWrap: 'wrap', padding: "0px" }}>
                                {
                                    goodsDetailData.tags.map((tagItem, index) => {
                                        /**
                                         * 鼠标右键事件 trigger="contextMenu"
                                         */
                                        return isEditDetails ? <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px" }}>
                                            {tagItem.name}
                                        </div> : <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                            let data = Object.assign({}, goodsDetailData);
                                            data.tags.remove(tagItem)
                                            setGoodsDetailData(data)
                                            // goodsDetailData.tags.remove(tagItem)
                                        }}>
                                            <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px" }}>
                                                {tagItem.name}
                                            </div>
                                        </Popconfirm>
                                    })
                                }
                                {isEditDetails ? <div style={{ height: '14px' }}></div> : <Popover content={detailAddTags()} placement="bottom" trigger="hover" onMouseLeave={()=>setTagsList(tagsData)}>
                                    <PlusSquareOutlined />
                                </Popover>}
                                {/* <AddTags tags={tags} setTags={setTags} disabled={isEditDetails}></AddTags> */}
                            </div>
                        </div>



                    </div>
                </Drawer>
                <Modal
                    visible={isModCategory}
                    title="批量修改"
                    cancelText="取消"
                    okText="确定"
                    onCancel={() => {
                        setIsModCategory(false)
                    }}
                    onOk={() => {
                        alterSelectedField()
                    }}
                    centered={true}
                >
                    <div style={{ display: 'grid', gridTemplateColumns: '150px 1fr', alignItems: 'center' }}>
                        <div style={{ textAlign: 'right' }}>字段选择:</div>
                        <div style={{ marginLeft: "20px", textAlign: 'left' }}>
                            <Radio.Group style={{ width: "300px" }} value={selectUpdateFields} onChange={(e) => {
                                judgeSearchMethod(e.target.value)
                                setSelectUpdateFields(e.target.value)
                            }
                            }>
                                {updateFields.map(item => {
                                    return <Radio value={item.id}>{item.name}</Radio>
                                })}
                            </Radio.Group>
                        </div>
                        {updateFieldsType && <div style={{ textAlign: 'right', marginTop: "20px" }}>修改内容:</div>}
                        {updateFieldsType ? updateFieldsType.search_tactics == '下拉筛选' && updateFieldsType.name == '盘货品类' ? <div>
                            <Select showSearch optionFilterProp="children" style={{ width: "240px", marginLeft: "20px", textAlign: 'left', marginTop: "20px" }} size="small" value={categoryUpdate} onChange={(value) => {
                                setCategoryUpdate(value)
                            }}>
                                {
                                    categoryList.map(item => {
                                        if (Object.keys(item).indexOf("children") !== -1) {
                                            return item["children"].map(citem => {
                                                return <OptGroup label={item.label + "-" + citem.label}>
                                                    {Object.keys(citem).indexOf("children") !== -1 ?
                                                        citem["children"].map(ccitem => {
                                                            return <Option value={ccitem.value}>{ccitem.value + "-" + ccitem.label}</Option>
                                                        })
                                                        : ""}
                                                </OptGroup>
                                            })
                                        } else if (item.level == 3) {
                                            return <Option value={item.value}>{item.value + "-" + item.label}</Option>
                                        } else {
                                            return <OptGroup label={item.label}></OptGroup>
                                        }
                                    })
                                }

                            </Select>
                        </div> : updateFieldsType.search_tactics == '下拉筛选' ? <Select showSearch optionFilterProp="children" style={{ width: "240px", marginLeft: "20px", textAlign: 'left', marginTop: "20px" }} size="small" value={categoryUpdate} onChange={(value) => {
                            setCategoryUpdate(value)
                        }}>
                            {
                                categoryList.map(item => {
                                    return <Option value={item.value}>{item.label}</Option>
                                }
                                )
                            }
                        </Select> : <Input style={{ width: "240px", marginLeft: "20px", textAlign: 'left', marginTop: "20px" }} size="small" value={categoryUpdate} onChange={(e) => {
                            setCategoryUpdate(e.target.value)
                        }} /> : ""}
                    </div>
                </Modal>
                <Modal
                    visible={isModTime}
                    title="修改上架时间"
                    cancelText="取消"
                    okText="确定"
                    onCancel={() => {
                        setIsModTime(false)
                    }}
                    onOk={() => {
                        alterUploadTime()
                    }}
                    centered={true}
                >
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <DatePicker locale={locale} style={{ width: "200px" }}
                            value={timeUpdate == '' ? '' : moment(timeUpdate, dateFormat)}
                            onChange={(value, dateString) => {
                                if (dateString == '') {
                                    message.warn('请选择合理的时间')
                                    return false
                                } else {
                                    setTimeUpdate(dateString)
                                }
                            }} />
                    </div>
                </Modal>

                <Modal
                    title="设定标签"
                    visible={isSettingTags}
                    onOk={() => {
                        if (tagsOptions) {
                            setTagsForGoods()

                        } else {
                            message.warning("请选择设定标签策略")
                        }
                    }}
                    onCancel={() => {
                        setIsSettingTags(false)
                        setAllChooseTagsList([])
                        setChooseTagsList([])
                    }}
                    okText="确定"
                    cancelText="取消"
                    centered={true}
                    width={"640px"}
                >
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: "10px" }}>
                        <Radio.Group options={tagsOptionsList} buttonStyle="solid" onChange={(e) => setTagsOptions(e.target.value)} value={tagsOptions} optionType="button" />
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr" }}>
                        <span>设定标签</span>
                        <div style={{
                            width: "100%",
                            border: "1px dashed #ccc",
                            height: "267px",
                            overflow: "auto",
                            display: 'flex',
                            alignItems: "self-start",
                            padding: "5px",
                            flexWrap: "wrap",
                            alignContent: "flex-start", // 子元素之间取消空白间隙，并把项目放在容器顶部
                            justifyContent: 'flex-start',
                        }}>
                            {
                                chooseTagsList.map(tagItem => {
                                    return <div style={{
                                        border: `1px solid ${tagItem['color'] ? tagItem['color'] : '#ccc'}`,
                                        color: `${tagItem['color'] ? tagItem['color'] : '#ccc'}`,
                                        backgroundColor: `${tagItem['color'] == null || tagItem['color'] == "None" ? '#ccc' : tagItem['color'].substring(0, 7) + '19'}`,
                                        padding: "0 3px",
                                        textAlign: 'center',
                                        borderRadius: "3px",
                                        fontSize: "10px",
                                        writingMode: "horizontal-tb",
                                        marginRight: "10px",
                                        marginTop: "10px"
                                    }}
                                        onClick={() => {
                                            // 点击添加到所有标签，并移除设定标签
                                            let obj = [...allChooseTagsList]
                                            obj.push(tagItem)
                                            setAllChooseTagsList(obj)
                                            let objAll = [...chooseTagsList]
                                            objAll.remove(tagItem)
                                            setChooseTagsList(objAll)
                                        }}>
                                        {tagItem.name}
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: "10px" }}>
                        <span>所有标签</span>
                        <div style={{
                            width: "100%",
                            border: "1px dashed #ccc",
                            height: "267px",
                            overflow: "auto",
                            display: 'flex',
                            alignItems: "self-start",
                            padding: "5px",
                            flexWrap: "wrap",
                            alignContent: "flex-start", // 子元素之间取消空白间隙，并把项目放在容器顶部
                            justifyContent: 'flex-start',
                        }}>
                            {
                                // allChooseTagsList.map(tagItem => {
                                //     return <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "20px" }} onClick={() => {
                                //         chooseTagsList.push(tagItem)
                                //         allChooseTagsList.remove(tagItem)
                                //     }}>
                                //         {tagItem.name}
                                //     </div>
                                // })
                                allChooseTagsList.map(tagItem => {
                                    return <div style={{
                                        border: `1px solid ${tagItem['color'] ? tagItem['color'] : '#ccc'}`,
                                        color: `${tagItem['color'] ? tagItem['color'] : '#ccc'}`,
                                        backgroundColor: `${tagItem['color'] == null || tagItem['color'] == "None" ? '#ccc' : tagItem['color'].substring(0, 7) + '19'}`,
                                        padding: "0 3px",
                                        textAlign: 'center',
                                        borderRadius: "3px",
                                        fontSize: "10px",
                                        writingMode: "horizontal-tb",
                                        marginRight: "10px",
                                        marginTop: "10px"
                                    }}
                                        onClick={() => {
                                            let obj = [...chooseTagsList]
                                            obj.push(tagItem)
                                            setChooseTagsList(obj)
                                            let objAll = [...allChooseTagsList]
                                            objAll.remove(tagItem)
                                            setAllChooseTagsList(objAll)
                                        }}>
                                        {tagItem.name}
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    {/* <Popover content={addTags()} placement="right" trigger="click" zIndex={10002}> */}
                    <Button size="small" icon={<PlusSquareOutlined />} type="primary" style={{ borderRadius: "2px", marginTop: "10px" }} onClick={() => {
                        setIsSettingTags(false)
                        setIsEditTags(true)
                    }}>编辑标签</Button>
                    {/* </Popover> */}
                </Modal>

                <Modal
                    title="编辑标签"
                    visible={isEditTags}
                    onOk={() => {
                        editTags()
                    }}
                    onCancel={() => {
                        setIsEditTags(false)
                    }}
                    okText="确定"
                    cancelText="取消"
                    centered={true}
                    width={"640px"}
                >
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        border: "1px solid #ccc",
                        height: "267px",
                        justifyContent: 'flex-start',
                        alignContent: "flex-start", // 子元素之间取消空白间隙，并把项目放在容器顶部
                        alignItems: "baseline",
                        padding: "10px",
                        margin: "10px"
                    }}>
                        {
                            tagsData.map(tagItem => {
                                return <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                    let obj = [...tagsData]
                                    obj.remove(tagItem)
                                    setTagsData(obj)
                                }}>
                                    <div style={{
                                        border: `1px solid ${tagItem['color'] ? tagItem['color'] : '#ccc'}`,
                                        color: `${tagItem['color'] ? tagItem['color'] : '#ccc'}`,
                                        backgroundColor: `${tagItem['color'] == null || tagItem['color'] == "None" ? '#ccc' : tagItem['color'].substring(0, 7) + '19'}`,
                                        padding: "0 3px",
                                        textAlign: 'center',
                                        borderRadius: "3px",
                                        fontSize: "10px",
                                        writingMode: "horizontal-tb",
                                        marginRight: "10px",
                                        marginTop: "10px"
                                    }}
                                    >
                                        {tagItem.name}
                                    </div>
                                </Popconfirm>
                            })
                        }
                    </div>
                    <Popover content={addTags()} placement="right" trigger="click" zIndex={10002}>
                        <Button size="small" icon={<PlusSquareOutlined />} type="primary" style={{ borderRadius: "2px", marginTop: "10px" }}>添加标签</Button>
                    </Popover>
                </Modal>

                <Modal
                    title="品类关系图"
                    visible={categoryRelationPicture}
                    okText="确定"
                    cancelText="取消"
                    centered={true}
                    onOk={() => {
                        setCategoryRelationPicture(false)
                    }}
                    onCancel={() => {
                        setCategoryRelationPicture(false)
                    }}
                    width={"1100px"}
                >
                    {/* {graph.nodes.length  == 0?<div style={{display:'flex',justifyContent:'center'}}>未查询到品类数据</div>:<RelationChart graph1={graph}/>}
                    <RelationChart graph1={graph}/> */}
                    <div>
                        <div id="relationChart" style={{ width: "1000px", height: "1000px" }}>暂无数据</div>
                    </div>
                </Modal>

                <Modal
                    title="品类关系表"
                    visible={categoryRelation}
                    okText="确定"
                    cancelText="取消"
                    centered={true}
                    onOk={() => {
                        setCategoryRelation(false)
                    }}
                    onCancel={() => {
                        setCategoryRelation(false)
                    }}
                    width={"900px"}
                >
                    <Table dataSource={categoryRelationData} columns={categoryRelationColumn} />
                </Modal>

                <Modal
                    title="添加属性"
                    visible={isAddAttributes}
                    okText="确定"
                    cancelText="取消"
                    onOk={() => {
                        setIsAddAttributes(false)
                    }}
                    onCancel={() => {
                        attributesForm.resetFields()
                        setIsAddAttributes(false)
                    }}
                    centered={true}
                    width={"640px"}
                >
                    <Form form={attributesForm} {...layout} labelAlign="right">
                        <Form.Item label="属性名" name="属性名" rules={[
                            {
                                required: true,
                                message: '请输入属性名!',
                            },
                        ]}>
                            <Input style={{ width: "160px" }} />
                        </Form.Item>
                        <Form.Item label="筛选策略" name="筛选策略" rules={[
                            {
                                required: true,
                                message: '请选择筛选策略!',
                            },
                        ]}>
                            <Select style={{ width: "160px" }}>
                                {
                                    filterList.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="销售属性" name="销售属性" rules={[
                            {
                                required: true,
                                message: '请选择销售属性!',
                            },
                        ]}>
                            <Radio.Group style={{ width: "160px" }}>
                                <Radio value={'是'}>是</Radio>
                                <Radio value={'否'}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="数据类型" name="数据类型" rules={[
                            {
                                required: true,
                                message: '请选择数据类型!',
                            },
                        ]}>
                            <Select style={{ width: "160px" }}>
                                {
                                    dataType[attributesForm.getFieldValue('筛选策略')].map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="属性值">
                            {
                                attributesForm.getFieldValue('筛选策略') == '范围筛选' && attributesForm.getFieldValue('数据类型') == '数字' ?
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Input size="small" style={{ width: "90px" }} value={scopeNum1} onChange={(e) => setScopeNum1(e.target.value)} /> <span style={{ width: "30px", marginLeft: "20px", marginRight: "20px", textAlign: "center" }}>-</span> <Input size="small" style={{ width: "90px" }} value={scopeNum2} onChange={(e) => setScopeNum2(e.target.value)} />
                                    </div> : attributesForm.getFieldValue('筛选策略') == '范围筛选' && attributesForm.getFieldValue('数据类型') == '日期' ?
                                        <RangePicker style={{ borderRadius: "20px" }} size="small" locale={locale} value={scopeStartTime == '' ? ['', ''] : [moment(scopeStartTime, dateFormat), moment(scopeEndTime, dateFormat)]} onChange={(date, dateString) => {
                                            if (dateString[0] == '') {
                                                message.warn('请选择正确的时间')
                                                return false
                                            } else {
                                                setScopeStartTime(dateString[0])
                                                setScopeEndTime(dateString[1])
                                            }
                                        }} /> :
                                        attributesForm.getFieldValue('筛选策略') == '枚举筛选' || attributesForm.getFieldValue('筛选策略') == '模糊筛选' ? <Input disabled size="small" style={{ width: "160px" }} /> :
                                            attributesForm.getFieldValue('筛选策略') == '下拉筛选' ? <div style={{ width: "360px", height: "96px", border: "1px solid #ccc", padding: "10px" }}>
                                                <AddTags tags={tags} setTags={setTags} />
                                            </div> : <span></span>
                            }
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="编辑属性"
                    visible={isEditAttributes}
                    okText="确定"
                    cancelText="取消"
                    onOk={() => {
                        setIsEditAttributes(false)
                    }}
                    onCancel={() => {
                        attributesForm.resetFields()
                        setIsEditAttributes(false)
                    }}
                    centered={true}
                    width={"640px"}
                >
                    <Form form={attributesForm} {...layout} labelAlign="right">
                        <Form.Item label="属性名" name="属性名" rules={[
                            {
                                required: true,
                                message: '请输入属性名!',
                            },
                        ]}>
                            <Input style={{ width: "160px" }} />
                        </Form.Item>
                        <Form.Item label="筛选策略" name="筛选策略" rules={[
                            {
                                required: true,
                                message: '请选择筛选策略!',
                            },
                        ]}>
                            <Select style={{ width: "160px" }}>
                                {
                                    filterList.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="销售属性" name="销售属性" rules={[
                            {
                                required: true,
                                message: '请选择销售属性!',
                            },
                        ]}>
                            <Radio.Group style={{ width: "160px" }}>
                                <Radio value={'是'}>是</Radio>
                                <Radio value={'否'}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="数据类型" name="数据类型" rules={[
                            {
                                required: true,
                                message: '请选择数据类型!',
                            },
                        ]}>
                            <Select style={{ width: "160px" }}>
                                {
                                    dataType[attributesForm.getFieldValue('筛选策略')].map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="属性值">
                            {
                                attributesForm.getFieldValue('筛选策略') == '范围筛选' && attributesForm.getFieldValue('数据类型') == '数字' ?
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Input size="small" style={{ width: "90px" }} value={scopeNum1} onChange={(e) => setScopeNum1(e.target.value)} /> <span style={{ width: "30px", marginLeft: "20px", marginRight: "20px", textAlign: "center" }}>-</span> <Input size="small" style={{ width: "90px" }} value={scopeNum2} onChange={(e) => setScopeNum2(e.target.value)} />
                                    </div> : attributesForm.getFieldValue('筛选策略') == '范围筛选' && attributesForm.getFieldValue('数据类型') == '日期' ?
                                        <RangePicker style={{ borderRadius: "20px" }} size="small" locale={locale} value={scopeStartTime == '' ? ['', ''] : [moment(scopeStartTime, dateFormat), moment(scopeEndTime, dateFormat)]} onChange={(date, dateString) => {
                                            if (dateString[0] == '') {
                                                message.warn('请选择正确的时间')
                                                return false
                                            } else {
                                                setScopeStartTime(dateString[0])
                                                setScopeEndTime(dateString[1])
                                            }
                                        }} /> :
                                        attributesForm.getFieldValue('筛选策略') == '枚举筛选' || attributesForm.getFieldValue('筛选策略') == '模糊筛选' ? <Input disabled size="small" style={{ width: "160px" }} /> :
                                            attributesForm.getFieldValue('筛选策略') == '下拉筛选' ? <div style={{ width: "360px", height: "96px", border: "1px solid #ccc", padding: "10px" }}>
                                                <AddTags tags={tags} setTags={setTags} />
                                            </div> : <span></span>
                            }
                        </Form.Item>
                    </Form>
                </Modal>

                <Drawer
                    title="新增品类"
                    visible={newCategory}
                    onClose={() => {
                        setNewCategory(false)
                    }}
                    closable={false}
                    width="550px"
                    footer={
                        <div style={{ display: "flex", justifyContent: 'flex-end', marginRight: "20px" }}>
                            <Button style={{ marginRight: "20px" }} onClick={() => {
                                setNewCategory(false)
                            }}>取消</Button>
                            <Button type='primary' onClick={() => {
                                addNewCategory()
                            }}>完成</Button>
                        </div>

                    }
                >
                    <div style={{ display: 'flex', alignItems: "center" }}>
                        <span style={{ color: "red", textAlign: 'center' }}>*</span>
                        <div style={{ width: "56px", textAlign: "right" }}>
                            品类名字
                        </div>
                        <Input style={{ width: "160px", marginLeft: "10px" }} value={categoryName} onChange={e => {
                            setCategoryName(e.target.value)
                        }} />
                        <span style={{ color: "red", marginLeft: "34px", textAlign: 'right' }}>*</span>
                        <div style={{ width: "56px", textAlign: "right" }}>品类级别</div>
                        <Select style={{ width: "160px", marginLeft: "10px" }} value={categoryLevelSelect} onChange={value => setCategoryLevelSelect(value)}>
                            {
                                categoryLevel.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </div>
                    {categoryLevelSelect == '1' || categoryLevelSelect == '' ? '' : <div style={{ display: 'flex', marginTop: "20px", alignItems: "center", marginLeft: '6px' }}>
                        <div style={{ width: "56px", textAlign: "right" }}>父节点</div>
                        <TreeSelect
                            style={{
                                width: '390px',
                                marginLeft: "10px"
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            treeData={categoryList}
                            placeholder="请选择父节点"
                            treeDefaultExpandAll
                            value={fData}
                            onChange={(value) => {
                                setFData(value)
                            }}
                            treeNodeLabelProp={"title"}
                        />
                    </div>}
                    {categoryLevelSelect == '3' || categoryLevelSelect == '' ? '' : <div>
                        <div style={{ display: 'flex', marginTop: "20px", marginLeft: '6px' }}>
                            <div style={{ width: "56px", textAlign: "right", alignItems: 'center' }}>子节点</div>
                            <div style={{ marginLeft: "10px", display: 'flex', flexDirection: "column" }}>
                                {
                                    childData.map((item, index) => {
                                        return <div style={{ marginTop: index == 0 ? "0px" : "10px", display: 'flex', alignItems: "center" }}>
                                            <TreeSelect
                                                style={{
                                                    width: '390px',

                                                }}
                                                dropdownStyle={{
                                                    maxHeight: 400,
                                                    overflow: 'auto',
                                                }}
                                                treeData={categoryList}
                                                value={childData[index]}
                                                placeholder="请选择子节点"
                                                treeDefaultExpandAll
                                                onChange={(value) => {
                                                    let childList = [...childData]
                                                    childList[index] = value
                                                    setChildData(childList)
                                                }}
                                                treeNodeLabelProp={"title"}
                                            />
                                            <MinusSquareOutlined style={{ width: "16px", height: "16px", marginLeft: "18px" }} onClick={() => {
                                                let obj = [...childData]
                                                obj.remove(item)
                                                setChildData(obj)
                                            }} />
                                        </div>
                                    })
                                }
                            </div>

                        </div>
                        <div style={{ marginLeft: "72px", marginTop: "20px", }}>
                            <Button type="primary" onClick={() => {
                                let obj = [...childData]
                                obj.push('')
                                setChildData(obj)
                            }}>添加子节点</Button>
                        </div>
                    </div>
                    }


                </Drawer>

                <Drawer
                    title="编辑品类"
                    visible={editCategory}
                    onClose={() => {
                        setEditCategory(false)
                    }}
                    closable={false}
                    footer={
                        <div style={{ display: "flex", justifyContent: 'flex-end', marginRight: "20px" }}>
                            <Button style={{ marginRight: "20px" }} onClick={() => {
                                setEditCategory(false)
                            }}>取消</Button>
                            <Button type='primary' onClick={() => {
                                submitEditCategory()
                            }}>完成</Button>
                        </div>

                    }
                    width="550px"
                >
                    <div style={{ display: 'flex', alignItems: "center" }}>
                        <div style={{ width: "56px", textAlign: "right" }}>品类名称</div>
                        <Input style={{ width: "160px", marginLeft: "10px" }} value={categoryName}
                            onChange={e => {
                                setCategoryName(e.target.value)
                            }} />
                        <div style={{ width: "56px", marginLeft: "34px", textAlign: "right" }} >品类分组</div>
                        <Select style={{ width: "160px", marginLeft: "10px" }} value={categoryLevelSelect} onChange={value => setCategoryLevelSelect(value)}>
                            {
                                categoryLevel.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </div>
                    {categoryLevelSelect == 1 ? '' : <div style={{ display: 'flex', marginTop: "20px", alignItems: "center" }}>
                        <div style={{ width: "56px", textAlign: "right" }}>父节点</div>
                        <TreeSelect
                            style={{
                                width: '390px',
                                marginLeft: "10px"
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            treeData={categoryList}
                            placeholder="请选择父节点"
                            treeDefaultExpandAll
                            value={fData}
                            onChange={(value) => {
                                setFData(value)
                            }}
                            treeNodeLabelProp={"title"}
                        />
                    </div>}

                    {categoryLevelSelect == 3 ? "" : <div>
                        <div style={{ display: 'flex', marginTop: "20px" }}>
                            <div style={{ width: "56px", textAlign: "right", alignItems: 'center' }}>子节点</div>
                            <div style={{ marginLeft: "10px", display: 'flex', flexDirection: "column" }}>
                                {
                                    childData.map((item, index) => {
                                        return <div style={{ marginTop: index == 0 ? "0px" : "10px", display: 'flex', alignItems: "center" }}>
                                            <TreeSelect
                                                style={{
                                                    width: '390px',

                                                }}
                                                dropdownStyle={{
                                                    maxHeight: 400,
                                                    overflow: 'auto',
                                                }}
                                                treeData={categoryList}
                                                value={childData[index]}
                                                placeholder="请选择子节点"
                                                treeDefaultExpandAll
                                                onChange={(value) => {
                                                    let childList = [...childData]
                                                    childList[index] = value
                                                    setChildData(childList)
                                                }}
                                                treeNodeLabelProp={"title"}
                                            />
                                            <MinusSquareOutlined style={{ width: "16px", height: "16px", marginLeft: "18px" }} onClick={() => {
                                                let obj = [...childData]
                                                obj.remove(item)
                                                setChildData(obj)
                                            }} />
                                        </div>
                                    })
                                }
                            </div>

                        </div>
                        <div style={{ marginLeft: "66px", marginTop: "20px" }}>
                            <Button type="primary" onClick={() => {
                                let obj = [...childData]
                                obj.push('')
                                setChildData(obj)
                            }}>添加子节点</Button>
                        </div>
                    </div>}

                </Drawer>

                <Modal
                    title="是否删除分组"
                    visible={deleteGroup}
                    onCancel={() => {
                        setDeleteGroup(false)
                    }}
                    onOk={() => {
                        if (addFlag == 0) {
                            delPropertyGroupByGroupName()
                        } else {
                            let obj = [...specificationData]
                            obj[temporaryAddGroup.index]["property_model"].remove(temporaryAddGroup.cData)
                            setSpecificationData(obj)
                            message.success("删除成功")
                            setDeleteGroup(false)
                            setAddFlag(0)
                        }
                    }}
                    okText="确定"
                    cancelText="取消"
                    centered={true}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Text type="danger">是否要删除该分组？</Text>
                    </div>
                </Modal>

            </div>
        </div>

    )

}

export default Index