import React, { useState, useEffect } from 'react';
import { Button, Tooltip, Table, Tag, DatePicker, message, Drawer, Form, Row, Col, Input, Modal, Typography, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { CheckCircleTwoTone, EditOutlined } from '@ant-design/icons';
import moment from "moment"
import cookie from 'react-cookies'
import NbAxios from '../../../../config/utils/nbAxios'
import servicePath from '../../../../config/apiUrl'
import Base64 from 'base-64'
// 引入高亮组件
import Highlighter from "react-highlight-words";
const {Text, Title} = Typography
const { Search } = Input;
function Index(){
    const [ form ] = Form.useForm()
    const layout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 14 },
    };
    // 页面
    const [ page , setPage ] = useState(1)
    // 总数
    const [ total , setTotal ] = useState(100)
    // id
    const [ id , setId ] = useState('')
    // 添加科目
    const [ isAdd , setIsAdd ] =  useState(false)
    // 修改
    const [isUpdate, setIsUpdate] = useState(false)
    // 禁用
    const [isDelete, setIsDelete] = useState(false)
    // 科目名称
    const [ subject , setSubject ] = useState('')
    // 设置关键字列表
    const [selectList, setSelectList] = useState([])
    // 数据
    const [ data , setData ] = useState([])
    // 表格表头
    const columns = [
        {
            title:'金蝶费用分组',
            dataIndex:'expense_group',
            key: 'expense_group',
            align: 'center',
        },
        {
            title:'金蝶费用分组编号',
            dataIndex:'expense_group_num',
            key: 'expense_group_num',
            align: 'center',
        },
        {
            title:'管理报表科目',
            dataIndex:'subject',
            key: 'subject',
            align: 'center',
        },
        {
            title:'金蝶费用项目',
            dataIndex:'expense_item',
            key: 'expense_item',
            align: 'center',
        },
        {
            title:'对应会计科目代码',
            dataIndex:'corresponding_account_code',
            key: 'corresponding_account_code',
            align: 'center',
        },
        {
            title:'会计科目全称',
            dataIndex:'full_name_of_account',
            key: 'full_name_of_account',
            align: 'center',
            // 关键字高亮显示
            render: (text, record, index) => {
                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                        searchWords={selectList}
                        autoEscape
                        textToHighlight={text ? text.toString() : ""}
                    />
                );
            },
        },
        {
            title:'对应损益表末级科目',
            dataIndex:'end_level_account_of_income_statement',
            key: 'end_level_account_of_income_statement',
            align: 'center',
        },
        {
            title:'费用项目解释',
            dataIndex:'explanation_of_expense_items',
            key: 'explanation_of_expense_items',
            align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (text, record, index) =>(
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title:'编辑',
            dataIndex:'edit',
            key: 'edit',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={()=>{
                    setId(record.subject_id)
                    form.setFieldsValue(record)
                    setIsUpdate(true)
                }} />
            ),
        }
    ]
    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    // 页面修改的时候触发
    useEffect(()=>{
        getFinancialSubject()
    },[page])

    // 查询科目
    const getFinancialSubject = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "page":page,
            "pageSize":10,
            "subject ":subject,
        }
        NbAxios(dataProps, "POST", servicePath.findFinancialSubject).then(res => {
            if (res.success) {
                setTotal(res.total);
                setData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 禁用数据
    const deleteData = ()=>{
        let value = {}
        value.is_valid = 0
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        value.id = id
        NbAxios(value, "POST", servicePath.modifyFinancialSubject).then(res => {
            if (res.success) {
               message.success('科目禁用成功')
               setIsDelete(false)
               setIsUpdate(false)
               getFinancialSubject()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 更新数据
    const updateData = (value)=>{
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        value.id = id
        value.modified_by = cookie.load('name')
        NbAxios(value, "POST", servicePath.modifyFinancialSubject).then(res => {
            if (res.success) {
               message.success('科目修改成功')
               setIsUpdate(false)
               getFinancialSubject()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 添加数据
    const addData = (value)=>{
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        value.creator  = cookie.load('name')
        NbAxios(value, "POST", servicePath.addFinancialSubject).then(res => {
            if (res.success) {
               message.success('科目添加成功')
               setIsAdd(false)
               getFinancialSubject()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div>
            <div style={{width:'100vw', backgroundColor:'#fff', padding:10, paddingLeft:20}}>
                <Title level={2} style={{margin: 0}}>科目管理</Title>
            </div>
            <div style={{display:'flex', width:'100vw', backgroundColor:'#fff', paddingLeft:20, paddingBottom:10}}>
                <Button type="primary" onClick={()=>setIsAdd(true)}>添加科目</Button>
                <div>
                    <Search
                        style={{marginLeft:20, width:300}}
                        placeholder="请输入科目全称,支持模糊查询"
                        allowClear
                        enterButton="搜索"
                        // size="large"
                        onSearch={(value)=>{
                            setSubject(value)
                            let obj = []
                            obj.push(value)
                            setSelectList(obj)
                        }}
                    />
                </div>
            </div>
            
            <div>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={paginationProps}
                    scroll={{ x: 1500 }}
                    rowKey={'id'}
                />
            </div>
            {/* 编辑科目 */}
            <Modal
                title="编辑科目"
                centered="true"
                visible={isUpdate}
                onCancel={() => setIsUpdate(false)}
                footer={[]}
                width={550}
            >
                <Form form={form} {...layout} labelAlign='left' onFinish={updateData}>
                    <Form.Item label="会计科目全称"
                        rules={[{ required: true, message: '请输入会计科目全称！' }]}
                    >
                        <Text type="secondary">{form.getFieldValue('full_name_of_account')}</Text>
                    </Form.Item>
                    <Form.Item label="对应损益表末级科目" name="end_level_account_of_income_statement"
                        rules={[{ required: true, message: '请输入对应损益表末级科目！' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="金蝶费用分组" name="expense_group">
                        <Input />
                    </Form.Item>
                    <Form.Item label="金蝶费用分组编号" name="expense_group_num">
                        <Input />
                    </Form.Item>
                    <Form.Item label="管理报表科目" name="subject">
                        <Input />
                    </Form.Item>
                    <Form.Item label="金蝶费用项目" name="expense_item">
                        <Input />
                    </Form.Item>
                    <Form.Item label="对应会计科目代码" name="corresponding_account_code">
                        <Input />
                    </Form.Item>
                    <Form.Item label="费用项目解释" name="explanation_of_expense_items">
                        <Input />
                    </Form.Item>
                    <div style={{display:'flex', justifyContent:"space-around"}}>
                        <Button type="primary" htmlType="submit">
                            更新
                        </Button>
                        <Button type="primary" danger onClick={()=>setIsDelete(true)}>禁用</Button>
                    </div>
                </Form>
            </Modal>

            {/* 添加科目 */}
            <Modal
                title="添加科目"
                centered="true"
                visible={isAdd}
                onCancel={() => setIsAdd(false)}
                footer={[]}
                width={550}
            >
                <Form form={form} {...layout} labelAlign='left' onFinish={addData}>
                    <Form.Item label="会计科目全称" name="full_name_of_account"
                        rules={[{ required: true, message: '请输入会计科目全称！' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="对应损益表末级科目" name="end_level_account_of_income_statement"
                        rules={[{ required: true, message: '请输入对应损益表末级科目！' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="金蝶费用分组" name="expense_group">
                        <Input />
                    </Form.Item>
                    <Form.Item label="金蝶费用分组编号" name="expense_group_num">
                        <Input />
                    </Form.Item>
                    <Form.Item label="管理报表科目" name="subject">
                        <Input />
                    </Form.Item>
                    <Form.Item label="金蝶费用项目" name="expense_item">
                        <Input />
                    </Form.Item>
                    <Form.Item label="对应会计科目代码" name="corresponding_account_code">
                        <Input />
                    </Form.Item>
                   
                    <Form.Item label="费用项目解释" name="explanation_of_expense_items">
                        <Input />
                    </Form.Item>
                    <div style={{display:'flex', justifyContent:"center"}}>
                        <Button type="primary" htmlType="submit">
                            添加科目
                        </Button>
                    </div>
                </Form>
            </Modal>

            {/* 禁用 */}
            <Modal
                title="禁用科目"
                centered="true"
                visible={isDelete}
                cancelText="取消"
                okText="确认"
                onCancel={() => setIsDelete(false)}
                onOk={()=>deleteData()}
                width={550}
            >
                <Text type="danger">是否要禁用" <Text strong type="danger">{form.getFieldValue('accountTitle')}</Text> "这个科目？</Text>
            </Modal>
        </div>
    )

}

export default Index