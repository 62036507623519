import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Table, Form, Button, Typography, Spin, Input, Select, Col, Collapse, Row, Modal, message, Tag, Space, Radio } from 'antd'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { MinusCircleOutlined, ExclamationCircleOutlined, EditOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import Base64 from 'base-64'
import { HTML5Backend } from 'react-dnd-html5-backend';
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'
import update from 'immutability-helper';
import Debounce from '../../config/utils/debounce'
const { Title, Text, Link } = Typography
const { Option } = Select;
const { TextArea } = Input
const { Panel } = Collapse;
const { confirm } = Modal
const type = 'DragableBodyRow';
// 操作管理页面
function Index(props) {
    const colorList = ['#fba8b4', '#86d7fb', '#b4a0f8', '#f05961', '#8babe9', '#8ed982', '#eedb71', '#eea72f', '#b9b9b9']
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };
    const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef();
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: monitor => {
                const { index: dragIndex } = monitor.getItem() || {};
                if (dragIndex === index) {
                    return {};
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                };
            },
            drop: item => {
                moveRow(item.index, index);
            },
        });
        const [, drag] = useDrag({
            type,
            item: { index },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        });
        drop(drag(ref));

        return (
            <tr
                ref={ref}
                className={`${className}${isOver ? dropClassName : ''}`}
                style={{ cursor: 'move', ...style }}
                {...restProps}
            />
        );
    };

    const components = {
        body: {
            row: DragableBodyRow,
        },
    };
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein':"444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an':"7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr':"f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11':'d4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass':'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    const [operationForm] = Form.useForm()
    const [levelForm] = Form.useForm()
    const genExtra = (item) => (
        <EditOutlined
            onClick={() => {
                setLevelId(item.id)
                setColor(item.color)
                levelForm.setFieldsValue(item)
                setIsUpdateLevel(true)
            }}
        />
    );
    // 全体员工
    const [employeeList, setEmployeeList] = useState([])
    const [ isSpinning , setIsSpinning] = useState(false)
    //层级
    const [ collapseKey, setCollapseKey ] = useState('')
    const [levelList, setLevelList] = useState([])
    const [sortLevelList, setSortLevelList] = useState([])
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 层级Id
    const [ levelId, setLevelId ] = useState('')
    // 层级名称
    const [ levelName , setLevelName ] = useState('')
    // 操作id
    const [ operationId , setOperationId ] = useState('')
    // 旧的操作名字
    const [ oldOperationName, setOldOperationName ] = useState('')
    // 操作数据
    const [operationData, setOperationData] = useState([])
    // 操作负责人
    const [operationList , setOperationList ] = useState([])
    // 是否新增菜单层级
    const [isAddLevel, setIsAddLevel] = useState(false)
    // 是否编辑菜单层级
    const [isUpdateLevel, setIsUpdateLevel] = useState(false)
    // 是否菜单层级排序
    const [isSortLevel, setIsSortLevel] = useState(false)
    // 是否添加操作
    const [isAddOperation, setIsAddOperation] = useState(false)
    // 是否编辑操作
    const [isUpdateOperation, setIsUpdateOperation] = useState(false)
    // 是否禁用操作
    const [isDeleteOperation, setIsDeleteOperation] = useState(false)
    // 颜色
    const [ color , setColor ] = useState('')
    // 操作表格表头
    const operationColumns = [
        {
            title: '操作名字',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '负责人',
            dataIndex: 'leader',
            key: 'leader',
            align: 'center',
            render: (text, record, index) => (
               <span>{text.toString()}</span>
            ),
        },
        {
            title: '人员分类',
            dataIndex: 'type_people',
            key: 'type_people',
            align: 'center',
        },
        {
            title: '版本号',
            dataIndex: 'version_number',
            key: 'version_number',
            align: 'center',
        },
        {
            title: '编辑',
            dataIndex: 'opertaion',
            key: 'opertaion',
            align: 'center',
            render: (text, record, index) => (
                <Link underline onClick={() => {
                    operationForm.resetFields()
                    setOperationId(record.id)
                    setOperationList(record.leader)
                    setOldOperationName(record.name)
                    // 设置表单的值
                    let operationParametersList = []
                    if (record.operation_parameters[0] != '') {

                        record.operation_parameters.map(item => {
                            operationParametersList.push({'operation': item})
                        })
                        record.operation_list = operationParametersList
                    } else {
                        record.operation_list = []
                    }
                    operationForm.setFieldsValue(record)
                    setIsUpdateOperation(true)
                }}>编辑</Link>
            ),
        },
    ]

    // 菜单层级排序表格表头
    const LevelSortColumns = [
        {
            title: '菜单层级名字',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            onHeaderCell:(record, index)=>{
                return (
                    {
                        style:{
                            border:'1px solid #000'
                        }
                    }
                )
            },
            onCell:(record, index)=>{
                return (
                    {
                        style:{
                            borderLeft:'1px solid #ccc',
                            borderRight:'1px solid #ccc',
                            borderBottom:'1px solid #ccc'
                        }
                    }
                )
            },
        },
    ]


    useEffect(() => {
        // 获取菜单层级
        getOtmCatalogue()
        // 获取公司所有员工
        getAllEployees()
    }, [])

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = sortLevelList[dragIndex];
            setSortLevelList(
                update(sortLevelList, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [sortLevelList],
    );

    // 获取公司所有员工
    const getAllEployees = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryPMSysUser).then(res => {
            if (res.success) {
                setEmployeeList(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取菜单层级
    const getOtmCatalogue = () => {
        setIsSpinning(true)
        let values = {}
        values.shopList = cookie.load('shopList')
        values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        values.roleId = cookie.load('role')
        NbAxios(values, "POST", servicePath.queryOtmCatalogue).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setLevelList(res.data['catalogue_list']);
                setSortLevelList(res.data['catalogue_list'])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }


    // 新增菜单层次
    const addLeavel = (values) => {
        if(color == ''){
            message.warn('请选择菜单层级颜色')
        }else{
            values.shopList = cookie.load('shopList')
            values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
            values.roleId = cookie.load('role')
            values.color = color
            NbAxios(values, "POST", servicePath.addOtmCatalogue).then(res => {
                if (res.success) {
                    message.success("新增菜单层级成功")
                    setColor('')
                    getOtmCatalogue()
                    levelForm.resetFields()
                    setIsAddLevel(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 更新菜单层次
    const updateLeavel = (values)=>{
        if(color == ''){
            message.warn('请选择菜单层级颜色')
        }else{
            values.id = levelId
            values.shopList = cookie.load('shopList')
            values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
            values.roleId = cookie.load('role')
            values.color = color
            console.log(values);
            NbAxios(values, "POST", servicePath.updateOtmCatalogue).then(res => {
                if (res.success) {
                    message.success("更新菜单层级成功")
                    getOtmCatalogue()
                    setLevelId('')
                    setColor('')
                    levelForm.resetFields()
                    setIsUpdateLevel(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 打开菜单
    const openCollapse = (key)=>{
        if(key != undefined){
            setIsSpinning(true)
            setLevelId(key.split(',')[0])
            setLevelName(key.split(',')[1])
            getOtmCataloguePopulation(key, key.split(',')[0])
        }else{
            setLevelId('')
            setLevelName('')
            setCollapseKey(key)
        }
        
       
    }

    // 获取菜单下面的操作
    const getOtmCataloguePopulation = (key, id)=>{
        let values = {}
        values.shopList = cookie.load('shopList')
        values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        values.roleId = cookie.load('role')
        values.id = id
        NbAxios(values, "POST", servicePath.queryOtmCataloguePopulation).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setOperationData(res.data['catalogue_population_list'])
                setCollapseKey(key)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 添加操作
    const addOperation = (values)=>{
        let operation_list = []
        let flag = 0
        if (values.operation_list != undefined) {
            values.operation_list.map(item => {
                if (item['operation'] != undefined) {
                    operation_list.push(item['operation'])
                } else {
                    flag = 1
                }
            })
        }
        if (flag == 0) {
            values.operation_parameters = operation_list
            values.leader_list = operationList
            values.catalogue_id = levelId
            values.shopList = cookie.load('shopList')
            values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
            values.roleId = cookie.load('role')
            values.creator = cookie.load('name')
            values.creator_id = cookie.load('id')
            values.creator_username = Base64.decode(cookie.load('email'))
            values.type_business = levelName
            values.is_upload = parseInt(values.is_upload)
            values.pre_parameters = []
            NbAxios(values, "POST", servicePath.addOtmCataloguePopulation).then(res => {
                if (res.success) {
                    message.success('成功添加操作')
                    setOperationList([])
                    operationForm.resetFields()
                    getOtmCataloguePopulation(collapseKey, levelId)
                    setIsAddOperation(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }else {
            message.warn('请正确输入操作参数名');
        }
        
        
    }

    // 更新操作
    const updateOperation = (values) => {
        let operation_list = []
        let flag = 0
        if (values.operation_list != undefined) {
            values.operation_list.map(item => {
                if (item['operation'] != undefined) {
                    operation_list.push(item['operation'])
                } else {
                    flag = 1
                }
            })
        }
        if (flag == 0){
            setEffective(1)
            if(oldOperationName != values.name){
                values.shopList = cookie.load('shopList')
                values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                values.roleId = cookie.load('role')
                values.name_old = oldOperationName
                values.name_new = values.name
                NbAxios(values, "POST", servicePath.updateOtmOperationName).then(res => {
                    if (res.success) {
                        setOldOperationName('')
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }
            values.is_upload = parseInt(values.is_upload)
            values.operation_parameters = operation_list
            values.leader_list  = operationList
            values.id = operationId
            values.shopList = cookie.load('shopList')
            values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
            values.roleId = cookie.load('role')
            values.type_business = levelName
            values.pre_parameters = []
            console.log(values);
            NbAxios(values, "POST", servicePath.updateOtmCataloguePopulation).then(res => {
                setEffective(0)
                if (res.success) {
                    message.success('成功修改该操作')
                    operationForm.resetFields()
                    setOperationList([])
                    setOperationId('')
                    getOtmCataloguePopulation(collapseKey, levelId)
                    setIsUpdateOperation(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }else{
            message.warn('请正确输入操作参数名');
        }
       
    }



    return (
        <div>
            <Spin spinning={isSpinning} tip="数据请求中..." style={{marginTop:'45vh'}} size="large">
                <div style={{ marginLeft: '50px', marginTop: "30px", display:'flex' }}>
                    <Button type="primary" onClick={() => setIsAddLevel(true)}>新增菜单层级</Button>
                    {/* <Button style={{marginLeft:20}} onClick={() => setIsSortLevel(true)}>菜单层级排序</Button> */}
                    <Button type="dashed" onClick={() => {props.history.push({'pathname': `/app/otm/${props.history.location.pathname.split('/')[3]}/eventModel`,})
                    }}>事件模型管理</Button>
                </div>
                <div style={{ marginLeft: '50px', marginRight: '50px', marginTop: '30px' }}>
                    <Collapse accordion activeKey={collapseKey} onChange={openCollapse}>
                        {
                            levelList.map(item => {
                                return <Panel header={item.name} key={item.id + ',' + item.name} extra={genExtra(item)} style={{backgroundColor:item.color}}>
                                    <Button type="primary" style={{marginLeft:10, marginTop:10, marginBottom:5}} onClick={()=>setIsAddOperation(true)}>添加操作</Button>
                                    <Table
                                        pagination={false}
                                        dataSource={operationData}
                                        columns={operationColumns}
                                    />
                                </Panel>
                            })
                        }
                    </Collapse>
                </div>
            </Spin>
            

            {/* 新增菜单层次 */}
            <Modal
                title="新增菜单层次"
                centered={true}
                footer={null}
                visible={isAddLevel}
                onCancel={() => {
                    message.success("取消新增菜单层次")
                    levelForm.resetFields()
                    setColor('')
                    setIsAddLevel(false)
                }}
            >
                <Form {...layout}  form={levelForm} onFinish={addLeavel}>
                    <Form.Item label="菜单层次名字" name="name"
                        rules={[{ required: true, message: '菜单层级名字不能为空!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="菜单层次颜色">
                        {/* <input
                            type="color"
                            name="color1"
                        /> */}
                        <Row>
                            {
                                colorList.map(item=>{
                                    return <Col span={8} style={{padding:4, border:`2px solid ${color == item ? 'red' : 'white'}`}}><Tag color={item} style={{color:'black',  width:"80px"}} onClick={()=>setColor(item)}>{item}</Tag></Col>
                                })
                            }
                        </Row>
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="primary" htmlType="submit">新增菜单层级</Button>
                    </div>
                </Form>
            </Modal>
            {/* 编辑菜单层级 */}
            <Modal
                title="编辑菜单层次"
                centered={true}
                footer={null}
                visible={isUpdateLevel}
                onCancel={() => {
                    message.success("取消编辑菜单层次")
                    setLevelId('')
                    levelForm.resetFields()
                    setColor('')
                    setIsUpdateLevel(false)
                }}
            >
                <Form {...layout}  form={levelForm} onFinish={updateLeavel}>
                    <Form.Item label="菜单层次名字" name="name"
                        rules={[
                            { required: true, message: '菜单层级名字不能为空!' },
                            {
                                validator: (rule, value, callback) => {
                                    let flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]")
                                    if (flag.test(value)) {
                                        callback('请输入正确菜单层级名字')
                                    } else callback()
                                }
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="菜单层次颜色" name="color">
                        <Row>
                            {
                                colorList.map(item=>{
                                    return <Col span={8} style={{padding:4, border:`2px solid ${color == item ? 'red' : 'white'}`}}><Tag color={item} style={{color:'black', width:"80px"}} onClick={()=>setColor(item)}>{item}</Tag></Col>
                                })
                            }
                        </Row>
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button type="primary" htmlType="submit">更新菜单层级</Button>
                        <Button type="primary" danger onClick={()=>{
                             confirm({
                                centered: true,
                                okText: "确认",
                                cancelText: '取消',
                                title: `是否要禁用'${levelForm.getFieldValue('name')}'层级?`,
                                icon: <ExclamationCircleOutlined />,
                                onOk() {
                                    let values = {}
                                    values.shopList = cookie.load('shopList')
                                    values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                                    values.roleId = cookie.load('role')
                                    values.id = levelId
                                    console.log(values);
                                    NbAxios(values, "POST", servicePath.deleteOtmCatalogue).then(res => {
                                        if (res.success) {
                                            message.success("成功禁用该菜单层级成功")
                                            getOtmCatalogue()
                                            setLevelId('')
                                            setColor('')
                                            setIsUpdateLevel(false)
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    })
                                },
                                onCancel() {
                                    message.success('取消禁用该菜单层级')
                                },
                            });
                        }}>禁用菜单层级</Button>
                    </div>
                </Form>
            </Modal>
            {/* 菜单层次排序 */}
            <Modal
                title="菜单层次排序"
                centered={true}
                visible={isSortLevel}
                cancelText="取消"
                okText="确认"
                onCancel={() => {
                    message.success("取消菜单层次排序")
                    setIsSortLevel(false)
                }}
                onOk={() => {
                    let nameList = []
                    sortLevelList.map(item=>{
                        nameList.push(item.name)
                    })
                    let values = {}
                    values.shopList = cookie.load('shopList')
                    values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                    values.roleId = cookie.load('role')
                    values.name_list = nameList
                    NbAxios(values, "POST", servicePath.sortOtmCatalogue).then(res => {
                        if (res.success) {
                            message.success("修改菜单层次排序成功")
                            getOtmCatalogue()
                            setIsSortLevel(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}
            >
                <DndProvider backend={HTML5Backend}>
                    <Table
                        pagination={false}
                        columns={LevelSortColumns}
                        dataSource={sortLevelList}
                        components={components}
                        onRow={(record, index) => ({
                            index,
                            moveRow,
                        })}
                    />
                </DndProvider>
            </Modal>
            {/* 添加操作 */}
            <Modal
                title="添加操作"
                centered={true}
                footer={null}
                visible={isAddOperation}
                onCancel={() => {
                    message.success('取消添加操作')
                    operationForm.resetFields()
                    setIsAddOperation(false)
                }}
            >
                <Form {...layout}  form={operationForm} onFinish={addOperation}>
                    <Form.Item label="操作名字" name="name"
                        rules={[{ required: true, message: '操作名字不能为空!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="人员分类" name="type_people"
                               rules={[{required: true, message: '请选择人员分类！'}]}>
                        <Select placeholder="请选择人员分类">
                            <Option value="运营">运营</Option>
                            <Option value="商品">商品</Option>
                            <Option value="视觉">视觉</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="操作描述" name="operation_detail">
                        <TextArea placeholder="请输入操作描述" rows={4}/>
                    </Form.Item>
                    <Form.Item label="操作参数名"
                               tooltip={{title: '预设的操作参数名；例如: 1件9折；需要填写两个参数名: 件、折', icon: <InfoCircleOutlined/>}}>
                        <Form.List name="operation_list">
                            {(fields, {add, remove}) => (
                                <>
                                    <Row>
                                        {fields.map((field, index) => (
                                            <Col span={12}>
                                                <Space key={field.key} style={{display: 'flex', marginBottom: 8}}
                                                       align="baseline">
                                                    <Form.Item
                                                        {...field}
                                                        label={`参数名` + (index + 1)}
                                                        name={[field.name, 'operation']}
                                                        fieldKey={[field.fieldKey, 'operation']}
                                                    >
                                                        <Input/>
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                                </Space>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                            添加参数名
                                        </Button>
                                    </Form.Item>

                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item label="负责人" name="leader_list" >
                        <Select showSearch allowClear mode="multiple" value={operationList} onChange={(value)=>setOperationList(value)}>
                            {
                                employeeList.map(item => {
                                    return <Option
                                        value={item.name}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="是否上传文件" name="is_upload" rules={[{required: true, message: '请填写是否需要上传文件！'}]}>
                        <div style={{marginLeft:30}}>
                            <Radio.Group>
                                <Radio value={1}>需要</Radio>
                                <Radio value={0}>不需要</Radio>
                            </Radio.Group>
                        </div>
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <Button type="primary" htmlType="submit">添加操作</Button>
                    </div>
                </Form>
            </Modal>
            {/* 编辑操作 */}
            <Modal
                title="查看/编辑操作"
                centered={true}
                footer={null}
                visible={isUpdateOperation}
                onCancel={() => {
                    operationForm.resetFields()
                    setIsUpdateOperation(false)
                }}
            >
                <Form {...layout}  form={operationForm} onFinish={updateOperation}>
                    <Form.Item label="操作名字" name="name"
                        rules={[{ required: true, message: '操作名字不能为空!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="人员分类" name="type_people"
                               rules={[{required: true, message: '请选择人员分类！'}]}>
                        <Select placeholder="请选择人员分类">
                            <Option value="运营">运营</Option>
                            <Option value="商品">商品</Option>
                            <Option value="视觉">视觉</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="操作描述" name="operation_detail">
                        <TextArea placeholder="请输入操作描述" rows={4}/>
                    </Form.Item>
                    <Form.Item label="操作参数名"
                               tooltip={{title: '预设的操作参数名；例如: 1件9折；需要填写两个参数名: 件、折', icon: <InfoCircleOutlined/>}}>
                        <Form.List name="operation_list">
                            {(fields, {add, remove}) => (
                                <>
                                    <Row>
                                        {fields.map((field, index) => (
                                            <Col span={12}>
                                                <Space key={field.key} style={{display: 'flex', marginBottom: 8}}
                                                       align="baseline">
                                                    <Form.Item
                                                        {...field}
                                                        label={`参数名` + (index + 1)}
                                                        name={[field.name, 'operation']}
                                                        fieldKey={[field.fieldKey, 'operation']}
                                                    >
                                                        <Input/>
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                                </Space>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                            添加参数名
                                        </Button>
                                    </Form.Item>

                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item label="负责人" name="leader_list">
                        <div>
                            <Select showSearch allowClear mode="multiple" value={operationList} onChange={(value)=>setOperationList(value)}>
                                {
                                    employeeList.map(item => {
                                        return <Option value={item.name}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                    </Form.Item>
                    <Form.Item label="是否上传文件" name="is_upload" rules={[{required: true, message: '请填写是否需要上传文件！'}]}>
                        <div style={{marginLeft:20}}>
                            <Radio.Group defaultValue={operationForm.getFieldValue('is_upload')}>
                                <Radio value={1}>需要</Radio>
                                <Radio value={0}>不需要</Radio>
                            </Radio.Group>
                        </div>
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button type="primary" htmlType="submit" disabled={effective == 1 ? true : false}>更新操作</Button>
                        <Button type="primary" danger onClick={() => setIsDeleteOperation(true)} disabled={effective == 1 ? true : false}>禁用操作</Button>
                    </div>
                </Form>
            </Modal>
            {/* 禁用操作 */}
            <Modal
                title="禁用操作"
                centered={true}
                visible={isDeleteOperation}
                cancelText="取消"
                okText="确定"
                onCancel={() => {
                    message.success('取消禁用该操作')
                    setIsDeleteOperation(false)
                }}
                onOk={() => {
                    let values = {}
                    values.shopList = cookie.load('shopList')
                    values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                    values.roleId = cookie.load('role')
                    values.id = operationId
                    NbAxios(values, "POST", servicePath.deleteOtmCataloguePopulation).then(res => {
                        if (res.success) {
                            operationForm.resetFields()
                            message.success('成功禁用该操作')
                            getOtmCataloguePopulation(collapseKey, levelId)
                            setIsDeleteOperation(false)
                            setIsUpdateOperation(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                   
                }}
            >
                <Text type="danger">是否要禁用"<Text strong type="danger">{operationForm.getFieldValue('name')}</Text>"操作</Text>
            </Modal>

        </div>
    )
}

export default Index