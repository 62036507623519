import React, { Component, useEffect, useState } from 'react';
import { Table, Typography, Space, Button, Input, Tooltip, Drawer, Tabs, Select, Tag, Image, message, Radio, Modal, DatePicker, Statistic, Badge } from 'antd'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
// 引入高亮组件
import Highlighter from "react-highlight-words";
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl';
import NbAxios from '../../../config/utils/nbAxios';
import axios from 'axios';
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import downExcel from '../../../config/utils/downloadFile';
import Axios from 'axios';
import { PlusCircleOutlined, QuestionCircleOutlined, SearchOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';
import CommodityImage from '../../dataMart/component/commodityImage';
import { Divider } from 'rc-menu';
const { Text, Link, Title } = Typography
const { CheckableTag } = Tag
const { TabPane } = Tabs
const { Option } = Select
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD';
function Index(props) {
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    const createProjectNewArr = (data) => {
        return data.reduce((result, item) => {
            //首先将name字段作为新数组result取出
            if (result.indexOf(item.productLine) < 0) {
                result.push(item.productLine)
            }
            return result
        }, []).reduce((result, productLine, shop) => {
            //将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
            const children = data.filter(item => item.productLine === productLine);
            result = result.concat(
                children.map((item, index) => ({
                    ...item,
                    rowProductLineSpan: index === 0 ? children.length : 0,//将第一行数据添加rowSpan字段
                })),
            )
            return result;
        }, [])
    }
    //定义筛选功能
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 是否加载中
    const [isLoading, setIsLoading] = useState(false)
    // 条件搜索
    const [isSearch, setIsSearch] = useState(false)
    // Tab的键
    const [tabKey, setTabKey] = useState("全店数据")
    // 汇总维度
    const [summaryDimension, setSummaryDimension] = useState({
        'product_line': [],
        'year': [],
        'season': [],
        'secondary_category': []
    })
    // 是否下载数据
    const [isDownloadFile, setIsDownloadFile] = useState(false)
    // 是否是品牌方的Excel
    const [isBrandFile, setIsBrandFile] = useState(1)
    // 下载的时间选择
    const [downloadStartTime, setDownloadStartTime] = useState('')
    const [downloadEndTime, setDownloadEndTime] = useState('')
    // 维度选择
    const [productLineSelectedTags, setProductLineSelectedTags] = useState([])
    const [yearSelectedTags, setYearSelectedTags] = useState([])
    const [seasonSelectedTags, setSeasonSelectedTags] = useState([])
    const [categorySelectedTags, setCategorySelectedTags] = useState([])
    // 全店维度
    const [allShopProductLineSelectedTags, setAllShopProductLineSelectedTags] = useState([])
    const [allShopYearSelectedTags, setAllShopYearSelectedTags] = useState([])
    const [allShopSeasonSelectedTags, setAllShopSeasonSelectedTags] = useState([])
    // 品类
    const [categoryProductLineSelectedTags, setCategoryProductLineSelectedTags] = useState([])
    const [categoryYearSelectedTags, setCategoryYearSelectedTags] = useState([])
    const [categorySeasonSelectedTags, setCategorySeasonSelectedTags] = useState([])
    const [baseColumn] = useState([
        {
            title: "款数",
            dataIndex: '款数',
            // width: 40,
            align: 'center',
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <Statistic valueStyle={{ fontSize: 13 }} value={text} />
            )
        },
        {
            // title: "备货件数\n(天猫库存)",
            title: () => (
                <span>
                    {'备货件数'}
                    <Tooltip
                        title={'天猫库存（批量修改）'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '备货件数',
            // width: 40,
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            whiteSpace: 'pre',
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <Statistic valueStyle={{ fontSize: 13 }} value={text} />
            )
        },
        {
            title: () => (
                <span>
                    {'备货货值'}
                    <Tooltip
                        title={'天猫库存*最终到手价'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '备货货值',
            // width: 40,
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            whiteSpace: 'pre',
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <Statistic valueStyle={{ fontSize: 13 }} value={text} />
            )
        },
        {
            title: () => (
                <span>
                    {'备货占比'}
                    <Tooltip
                        title={'备货货值在所选范围内的占比'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '备货占比',
            // width: 40,
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            whiteSpace: 'pre',
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },

        },
        {
            title: () => (
                <span>
                    {'平均到手价'}
                    <Tooltip
                        title={'备货货值/备货件数'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '平均到手价',
            // width: 40,
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            whiteSpace: 'pre',
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <Statistic valueStyle={{ fontSize: 13 }} value={text} />
            )
        },
        {
            title: () => (
                <span>
                    {'平均到手价折扣'}
                    <Tooltip
                        title={'备货货值/备货吊牌货值'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '平均到手价折扣',
            // width: 40,
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            whiteSpace: 'pre',
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },
        },
        {
            title: () => (
                <span>
                    {'备货深度'}
                    <Tooltip
                        title={'备货件数/款数'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '备货深度',
            // width: 40,
            align: 'center',
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <Statistic valueStyle={{ fontSize: 13 }} value={text} />
            )
        },
        {
            title: () => (
                <span>
                    {'尖货款数'}
                    <Tooltip
                        title={'重点款数'}
                    >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: '尖货款数',
            // width: 40,
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            whiteSpace: 'pre',
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <Statistic valueStyle={{ fontSize: 13 }} value={text} />
            )
        }
    ])
    // 热力图
    const [price, setPrice] = useState([])
    const [category, setCategory] = useState([])
    const [maxValue, setMaxValue] = useState('')
    const [minValue, setMinValue] = useState('')
    const [heatmapValue, setHeatmapValue] = useState([])
    // 全店店铺数据表头
    const [allShopColumn] = useState([
        {
            title: "产品线",
            dataIndex: 'productLine',
            // width: 40,
            align: 'center',
            render(_, row) {
                return {
                    children: row.productLine,
                    props: {
                        rowSpan: row.rowProductLineSpan,
                    }
                }
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },
        },
        {
            title: "年份季节",
            dataIndex: '年份季节',
            // width: 40,
            align: 'center',
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },
        },
        ...baseColumn.map(item => {
            return item
        })
    ])
    // 全店店铺数据
    const [allShopData, setAllShopData] = useState([])
    // 品类表头
    const [categoryColumn] = useState([
        {
            title: "品类",
            dataIndex: 'secondary_category',
            // width: 40,
            align: 'center',
            ...getColumnSearchProps('secondary_category'),
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            padding: 0,
                            height: "40px",
                        }
                    }
                )
            },
        },
        ...baseColumn.map(item => {
            return item
        })
    ])
    // 品类数据
    const [categoryData, setCategoryData] = useState([])
    // 品类价格集合
    const [categoryPriceList, setCategoryPriceList] = useState(['900-1200', '1200-1500', '1500-1800', '2100-2400', '2500-2800', '3000-3300', '1200-1500'])
    // 品类价格带表头
    const [categoryPriceColumn, setCategoryPriceColumn] = useState([])

    useEffect(() => {
        setCategoryPriceColumn([
            {
                title: "价格带",
                dataIndex: 'price_rank',
                // width: 40,
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 0,
                                height: "40px",
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    text != '总计' ?
                        <a style={{ textDecoration: 'underline' }} onClick={() => {
                            let summaryDimension = {}
                            summaryDimension['categorySelectedTags'] = categorySelectedTags
                            summaryDimension['yearSelectedTags'] = yearSelectedTags
                            summaryDimension['seasonSelectedTags'] = seasonSelectedTags
                            summaryDimension['productLineSelectedTags'] = productLineSelectedTags
                            summaryDimension['priceBand'] = text
                            props.history.push({
                                'pathname': `/app/${props.history.location.pathname.split('/')[2]}/selectionPricing/formal`,
                                'state': { 'name': selectionPlanName, 'summaryDimension': summaryDimension }
                            })
                        }}>{text}</a>
                        : <span>{text}</span>
                )
            },
            ...baseColumn.map(item => {
                return item
            })
        ])
    }, [categorySelectedTags, seasonSelectedTags, yearSelectedTags, productLineSelectedTags])
    const [categoryPriceRowKeys, setCategoryPriceRowKeys] = useState([])
    const [categoryPriceTabKey, setCategoryPriceTabKey] = useState('重点款')
    // 品类价格表格数据
    const [categoryPriceTableData, setCategoryPriceTableData] = useState([])
    // 选择指标
    const [checkedData, setCheckedData] = useState([])
    // 重点款id
    const [keyClothesSPU, setKeyClothesSPU] = useState([])
    // 重点款价格分布id
    const [keyClothesPriceId, setKeyClothesPriceId] = useState([
        {
            'price': '900-1200',
            'id': ['1111', '1112', '1113']
        },
        {
            'price': '1200-1500',
            'id': ['1111', '1112', '1113']
        },
        {
            'price': '1500-1800',
            'id': ['1111', '1112', '1113']
        },
        {
            'price': '1500-1800',
            'id': ['1111', '1112', '1113']
        },
        {
            'price': '1500-1800',
            'id': ['1111', '1112', '1113']
        },
        {
            'price': '1500-1800',
            'id': ['1111', '1112', '1113']
        }
    ])
    // 重点款表头
    const [keyClothesColumn, setKeyClothesColumn] = useState([])
    const [keyClothesData, setKeyClothesData] = useState([])
    // 重点款价格表头
    const [keyClothesPriceColumn, setKeyClothesPriceColumn] = useState([
        {
            title: "款号",
            dataIndex: '款号',
            width: 100,
            align: 'center',
            fixed: 'left',
        },
        {
            title: "900-1200",
            align: 'center',
            children: [
                {
                    title: '1111',
                    width: 110,
                    dataIndex: '1111',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                },
                {
                    title: '1112',
                    width: 110,
                    dataIndex: '1112',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                },
                {
                    title: '1113',
                    width: 110,
                    dataIndex: '1113',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                }
            ]
        },
        {
            title: "1200-1500",
            align: 'center',
            children: [
                {
                    title: '1111',
                    width: 110,
                    dataIndex: '1111',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                },
                {
                    title: '1112',
                    width: 110,
                    dataIndex: '1112',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                },
                {
                    title: '1113',
                    width: 110,
                    dataIndex: '1113',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                }
            ]
        },
        {
            title: "1200-1500",
            align: 'center',
            children: [
                {
                    title: '1111',
                    width: 110,
                    dataIndex: '1111',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                },
                {
                    title: '1112',
                    width: 110,
                    dataIndex: '1112',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                },
                {
                    title: '1113',
                    width: 110,
                    dataIndex: '1113',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                }
            ]
        },
        {
            title: "1200-1500",
            align: 'center',
            children: [
                {
                    title: '1111',
                    width: 110,
                    dataIndex: '1111',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                },
                {
                    title: '1112',
                    width: 110,
                    dataIndex: '1112',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                },
                {
                    title: '1113',
                    width: 110,
                    dataIndex: '1113',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                }
            ]
        },
        {
            title: "1200-1500",
            align: 'center',
            children: [
                {
                    title: '1111',
                    width: 110,
                    dataIndex: '1111',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                },
                {
                    title: '1112',
                    width: 110,
                    dataIndex: '1112',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                },
                {
                    title: '1113',
                    width: 110,
                    dataIndex: '1113',
                    align: 'center',
                    render: (text, record, index) => (
                        index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                            : <span>{text}</span>
                    )
                }
            ]
        }
    ])
    // 热力图是否展开
    const [openHeatMap, setOpenHeatMap] = useState(0)
    // 活动名字
    const [selectionPlanName, setSelectionPlanName] = useState('')
    useEffect(() => {
        // setIsSearch(true)
    }, [])
    // 设置品类价格带表头
    // useEffect(() => {
    //     setCategoryPriceColumn([
    //         {
    //             title: "",
    //             dataIndex: 'expandIcon',
    //             width: 20,
    //             align: 'center',
    //             fixed: 'left'
    //         },
    //         {
    //             title: "品类",
    //             dataIndex: 'category',
    //             width: 100,
    //             align: 'center',
    //             fixed: 'left',
    //             ...getColumnSearchProps('category'),
    //         },
    //         ...categoryPriceList.map(item => {
    //             return {
    //                 title: item,
    //                 dataIndex: item,
    //                 key: item,
    //                 align: 'center',
    //             }
    //         })
    //     ])
    // }, [categoryPriceList])
    // 设置重点款表头
    useEffect(() => {
        setKeyClothesColumn([
            {
                title: "款号",
                dataIndex: '款号',
                width: 100,
                align: 'center',
                fixed: 'left',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                backgroundColor: '#fff',
                            }
                        }
                    )
                },
            },
            ...keyClothesSPU.map(item => {
                return {
                    title: item,
                    width: 110,
                    dataIndex: item,
                    align: 'center',
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    backgroundColor: '#fff',
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        index == 0 ?
                            <CommodityImage
                                style={{ width: 65, height: 65 }}
                                id={text.split(',')[0]} sku={text.split(',')[1]}
                                shopName={props.history.location.pathname.split('/')[2]} index={index} />
                            : <span>{text}</span>
                    )
                }
            })
        ])
    }, [keyClothesSPU])
    // 设置重点款价格带表头
    useEffect(() => {
        setKeyClothesPriceColumn([
            {
                title: "款号",
                dataIndex: '款号',
                width: 100,
                align: 'center',
                fixed: 'left',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                backgroundColor: '#fff',
                            }
                        }
                    )
                },
            },
            ...keyClothesPriceId.map(item => {
                return {
                    title: item.price,
                    align: 'center',
                    children: [
                        ...item.id.map(i => {
                            return {
                                title: i,
                                width: 110,
                                dataIndex: i,
                                align: 'center',
                                onCell: (record, index) => {
                                    return (
                                        {
                                            style: {
                                                backgroundColor: '#fff',
                                            }
                                        }
                                    )
                                },
                                render: (text, record, index) => (
                                    index == 0 ? <Image style={{ width: 90, height: 90 }} src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.article.pchome.net%2F00%2F27%2F30%2F00%2Fpic_lib%2Fs960x639%2FPCHOMEdwzr1224ht20s960x639.jpg&refer=http%3A%2F%2Fimg.article.pchome.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639568890&t=372e7051828d714f27d27d0084b7fe64" />
                                        : <span>{text}</span>
                                )
                            }
                        })
                    ]
                }
            })
        ])
    }, [keyClothesPriceId])

    useEffect(() => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "name": props.location.state.name,
            'creator': cookie.load('name')
        }
        NbAxios(dataProps, "POST", servicePath.queryHeatmapData).then(res => {
            setIsLoading(false)
            console.log(res);
            if (res.success) {
                console.log(res.data);
                console.log(res.data['data']);
                setPrice(res.data['price_zone_list'])
                setCategory(res.data['category_data'])
                setMaxValue(res.data['max'])
                setMinValue(res.data['min'])
                setHeatmapValue(res.data['data'])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }, [])

    useEffect(() => {
        // prettier-ignore
        let data = heatmapValue.map(function (item) {
            return [item[1], item[0], item[2] || '-'];
        });
        let yAxisVal = ''; // 定义y轴变量
        let xAxisVal = ''; // 定义x轴变量
        let report = echarts.init(document.getElementById("heatMap"))
        report.setOption({
            tooltip: {
                position: 'top',
                formatter(e) {
                    return `${yAxisVal}<br/>${xAxisVal}: ${(e.data && e.data[2]) || '-'} `;
                }
            },
            grid: {
                height: '50%',
                top: '10%'
            },
            xAxis: {
                type: 'category',
                data: price,
                splitArea: {
                    show: true
                },
                axisPointer: {
                    show: true,
                    type: 'none',
                    trigger: 'item',
                    label: {
                        formatter(e) {
                            xAxisVal = e.value; // 重点：获取x轴的值
                            return e.value
                        }
                    }
                }
            },
            yAxis: {
                type: 'category',
                data: category,
                splitArea: {
                    show: true
                },
                axisPointer: {
                    type: 'none',
                    show: true,
                    trigger: 'item',
                    splitLine: {
                        show: false
                    },
                    label: {
                        formatter(e) {
                            yAxisVal = e.value; // 重点：获取y轴的值
                            return e.value
                        }
                    }
                }
            },
            visualMap: {
                min: minValue,
                max: maxValue,
                calculable: true,
                left: 'right',
                top: 'center',
                orient: 'vertical',
                bottom: '15%',
                inRange: {
                    color: ['#f7ee83', '#fe6347']
                }
            },
            series: [
                {
                    name: 'Punch Card',
                    type: 'heatmap',
                    data: data,
                    label: {
                        show: true
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        })
    }, [heatmapValue])

    useEffect(() => {
        setIsLoading(true)
        setSelectionPlanName(props.location.state.name);
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "name": props.location.state.name,
            'creator': cookie.load('name')
        }
        NbAxios(dataProps, "POST", servicePath.getSummaryRang).then(res => {
            console.log(res);
            if (res.success) {
                setSummaryDimension(res.data);
                setProductLineSelectedTags(res.data['product_line'])
                setYearSelectedTags(res.data['year'])
                setSeasonSelectedTags(res.data['season'])
                setAllShopProductLineSelectedTags(res.data['product_line'])
                setAllShopYearSelectedTags(res.data['year'])
                setAllShopSeasonSelectedTags(res.data['season'])
                setCategoryProductLineSelectedTags(res.data['product_line'])
                setCategoryYearSelectedTags(res.data['year'])
                setCategorySeasonSelectedTags(res.data['season'])
                getAllShopSummary(res.data['product_line'], res.data['year'], res.data['season'], props.location.state.name)
                setTabKey('全店数据')
            }
        })
    }, [])

    useEffect(() => {

    }, [])

    // 查询全店数据汇总数据接口
    const getAllShopSummary = (productLine, year, season, selectionPlanName) => {
        setIsLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "name": selectionPlanName,
            "productLine": productLine,
            "season": season,
            "year": year,
            'creator': cookie.load('name')
        }
        NbAxios(dataProps, "POST", servicePath.getAllShopSummary).then(res => {
            setIsLoading(false)
            if (res.success) {
                setAllShopData(res.data);
                setIsSearch(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 查询品类数据汇总数据接口
    const getCategorySummary = (productLine, year, season) => {
        setIsLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "name": selectionPlanName,
            "productLine": productLine,
            "season": season,
            "year": year,
            'creator': cookie.load('name')
        }
        NbAxios(dataProps, "POST", servicePath.getCategorySummary).then(res => {
            setIsLoading(false)
            if (res.success) {
                setCategoryData(res.data);
                setIsSearch(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 品类价格汇总
    const getCategoryPriceSummary = (productLine, year, season) => {
        setIsLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "name": selectionPlanName,
            "productLine": productLine,
            "season": season,
            "year": year,
            "secondary_category": categorySelectedTags,
            'creator': cookie.load('name')
        }
        NbAxios(dataProps, "POST", servicePath.getCategoryPriceSummary).then(res => {
            setIsLoading(false)
            if (res.success) {
                setCategoryPriceTableData(res.data);
                setIsSearch(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 全店数据
    const allShopProductLineHandleChange = (tag, checked) => {
        let selectedTag = [...allShopProductLineSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setAllShopProductLineSelectedTags(nextSelectedTags)
    }
    const allShopYearHandleChange = (tag, checked) => {
        let selectedTag = [...allShopYearSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setAllShopYearSelectedTags(nextSelectedTags)
    }
    const allShopSeasonHandleChange = (tag, checked) => {
        let selectedTag = [...allShopSeasonSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setAllShopSeasonSelectedTags(nextSelectedTags)
    }
    // 品类
    const categoryProductLineHandleChange = (tag, checked) => {
        let selectedTag = [...categoryProductLineSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setCategoryProductLineSelectedTags(nextSelectedTags)
    }
    const categoryYearHandleChange = (tag, checked) => {
        let selectedTag = [...categoryYearSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setCategoryYearSelectedTags(nextSelectedTags)
    }
    const categorySeasonHandleChange = (tag, checked) => {
        let selectedTag = [...categorySeasonSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setCategorySeasonSelectedTags(nextSelectedTags)
    }

    const productLineHandleChange = (tag, checked) => {
        let selectedTag = [...productLineSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setProductLineSelectedTags(nextSelectedTags)
    }
    const yearHandleChange = (tag, checked) => {
        let selectedTag = [...yearSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setYearSelectedTags(nextSelectedTags)
    }
    const seasonHandleChange = (tag, checked) => {
        let selectedTag = [...seasonSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setSeasonSelectedTags(nextSelectedTags)
    }

    const categoryHandleChange = (tag, checked) => {
        setCategorySelectedTags(tag)
    }


    return (
        <div>
            <div style={{
                borderBottom: '1px solid #ccc',
                marginTop: 3,
                position: 'fixed',
                top: 40,
                zIndex: 99,
                width: '100vw',
                backgroundColor: "#fff",
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', borderBottom: '1px solid #ccc', }}>
                    <div>
                        <Title level={4}>汇总信息</Title>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button type="primary" onClick={() => {
                            setIsDownloadFile(true)
                        }}>导出结果</Button>
                        <Button style={{ marginLeft: 10 }} type="dashed" onClick={() => {
                            setIsSearch(true)
                        }}>选择汇总范围</Button>
                        <Button danger style={{ marginLeft: 10 }} type="dashed" onClick={() => {
                            props.history.push({
                                'pathname': `/app/${props.history.location.pathname.split('/')[2]}/selectionPricing/formal`,
                                'state': { 'name': selectionPlanName }
                            })
                        }}>选款修改</Button>
                    </div>
                </div>
            </div>
            <div style={{ display: tabKey == '全店数据' ? '' : 'none', marginTop: 55 }}>
                <div>
                    <div style={{ display: 'flex', paddingTop: 10, paddingBottom: 10, paddingLeft: 23, borderBottom: "1px solid #ccc" }}>
                        <span style={{ marginRight: 5 }}>{'产品线:' + productLineSelectedTags.toString()}</span>
                        <span style={{ marginRight: 5 }}>{'年份:' + yearSelectedTags.toString()}</span>
                        <span>{'季节:' + seasonSelectedTags.toString()}</span>
                    </div>
                    <div style={{ marginTop: 5, marginLeft: 20, marginRight: 20 }}>
                        <Table
                            loading={isLoading}
                            columns={allShopColumn}
                            dataSource={createProjectNewArr(allShopData)}
                            pagination={false}
                            scroll={{ y: '72vh' }}
                            bordered
                        />
                    </div>
                </div>

            </div>
            <div style={{ display: tabKey == '品类' ? '' : 'none', marginTop: 55 }}>
                <div>
                    <div style={{ display: 'flex', paddingTop: 10, paddingBottom: 10, paddingLeft: 23, borderBottom: "1px solid #ccc" }}>
                        <span style={{ marginRight: 5 }}>{'产品线:' + productLineSelectedTags.toString()}</span>
                        <span style={{ marginRight: 5 }}>{'年份:' + yearSelectedTags.toString()}</span>
                        <span>{'季节:' + seasonSelectedTags.toString()}</span>
                    </div>
                    <div style={{ marginTop: 5, marginLeft: 20, marginRight: 20 }}>
                        <Table
                            loading={isLoading}
                            columns={categoryColumn}
                            dataSource={categoryData}
                            pagination={false}
                            scroll={{ y: '72vh' }}
                            bordered
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: tabKey == '品类价格带' ? '' : 'none', marginTop: 55 }}>
                {/* <Table columns={categoryPriceColumn} dataSource={categoryPriceData} pagination={false} /> */}
                <div style={{ marginLeft: 200 }}>
                    {
                        openHeatMap == 1 ? <a onClick={() => {
                            setOpenHeatMap(0)
                        }}>关闭</a> : <a onClick={() => {
                            setOpenHeatMap(1)
                        }}>展开热力图</a>
                    }
                </div>
                <div style={{ display: openHeatMap == 1 ? 'block' : 'none', width: "90vw", height: 550, justifyContent: 'center' }}>
                    <div id="heatMap" style={{ width: "90vw", height: 750 }}></div>
                </div>
                <div style={{ marginLeft: '5%', marginRight: '5%' }}>
                    <div style={{ display: 'flex', paddingBottom: 10, paddingLeft: 23, borderBottom: "1px solid #ccc" }}>
                        <span style={{ marginRight: 5 }}>{'产品线:' + productLineSelectedTags.toString()}</span>
                        <span style={{ marginRight: 5 }}>{'年份:' + yearSelectedTags.toString()}</span>
                        <span style={{ marginRight: 5 }}>{'季节:' + seasonSelectedTags.toString()}</span>
                        <span>{'品类:' + categorySelectedTags}</span>
                    </div>
                    <Table
                        bordered
                        dataSource={categoryPriceTableData}
                        columns={categoryPriceColumn}
                        pagination={false}
                        expandable={{
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded
                                    ? (<DownOutlined onClick={e => onExpand(record, e)} />)
                                    : (<RightOutlined onClick={e => onExpand(record, e)} />)
                            ,
                            expandedRowRender: record => (
                                <div>
                                    {/* <Tabs activeKey={categoryPriceTabKey} type="card" style={{ backgroundColor: "#fff", height: 54 }} onChange={(key) => {
                                        setCategoryPriceTabKey(key)
                                    }}>
                                        <TabPane tab="重点款" key="重点款"></TabPane>
                                        <TabPane tab="重点款价格分布" key="重点款价格分布"></TabPane>
                                    </Tabs> */}
                                    {/* style={{ display: categoryPriceTabKey == '重点款' ? '' : 'none', padding: 20 }} */}
                                    <div >
                                        <Table
                                            bordered
                                            loading={isLoading}
                                            columns={keyClothesColumn}
                                            dataSource={keyClothesData}
                                            scroll={{ x: 1000, y: 660 }}
                                            pagination={false} />
                                    </div>
                                    {/* <div style={{ display: categoryPriceTabKey == '重点款价格分布' ? '' : 'none' }}>
                                        <Table
                                            bordered
                                            columns={keyClothesPriceColumn}
                                            dataSource={keyClothesData}
                                            scroll={{ x: 1000, y: 660 }}
                                            pagination={false} />
                                    </div> */}
                                </div>
                            ),
                            expandedRowKeys: categoryPriceRowKeys,
                            // expandRowByClick: true,
                            onExpand: (expanded, record) => {
                                let temp = []
                                // 判断是否是同一行进行点击
                                if (expanded) {
                                    temp.push(record.price_rank)
                                    if (record.price_rank != '总计') {
                                        let dataProps = {
                                            "roleId": cookie.load('role'),
                                            "shopList": cookie.load('shopList'),
                                            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                            "name": selectionPlanName,
                                            "price_rank": record.price_rank,
                                            "secondary_category": categorySelectedTags,
                                            "productLine": productLineSelectedTags,
                                            "season": seasonSelectedTags,
                                            "year": yearSelectedTags,
                                            'creator': cookie.load('name')
                                        }
                                        console.log(dataProps);
                                        setIsLoading(true)
                                        NbAxios(dataProps, "POST", servicePath.getCategoryPriceDetail).then(res => {
                                            setIsLoading(false)
                                            if (res.success) {
                                                setKeyClothesSPU(res.data['commodity_spu_list']);
                                                setKeyClothesData(res.data['commodity_list']);
                                                setCategoryPriceRowKeys(temp)
                                                setCategoryPriceTabKey('重点款')
                                            } else {
                                                message.warn(res.errorMsg)
                                            }
                                        })
                                    } else {
                                        message.warn('暂时不支持')
                                        setCategoryPriceRowKeys([])
                                    }

                                } else {
                                    setCategoryPriceRowKeys(temp)
                                }

                            },
                            // expandIconColumnIndex: 4,
                        }}
                        scroll={{ x: 1000, y: 1000 }}
                        rowKey="price_rank"
                    />
                </div>

            </div>
            {/* <Drawer
                title="选择汇总范围"
                visible={isSearch}
                onClose={() => {
                    setIsSearch(false)
                }}
                width="40vw"
            >
                <Tabs activeKey={tabKey} type="card" style={{ backgroundColor: "#fff", height: 54 }} onChange={(key) => {
                    setTabKey(key)
                }}>
                    <TabPane tab="全店数据" key="全店数据"></TabPane>
                    <TabPane tab="品类" key="品类"></TabPane>
                    <TabPane tab="品类价格带" key="品类价格带"></TabPane>
                </Tabs>
                <div>
                    {
                        summaryDimension['product_line'].map(item => {
                            return <CheckableTag
                                style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                key={item}
                                checked={productLineSelectedTags.indexOf(item) > -1}
                                onChange={checked => productLineHandleChange(item, checked)}
                            >
                                {item}
                            </CheckableTag>
                        })
                    }
                </div>
                <div>
                    {
                        summaryDimension['year'].map(item => {
                            return <CheckableTag
                                style={{ border: "1px solid #ccc", width: 50, marginTop: 5, textAlign: 'center' }}
                                key={item}
                                checked={yearSelectedTags.indexOf(item) > -1}
                                onChange={checked => yearHandleChange(item, checked)}
                            >
                                {item}
                            </CheckableTag>
                        })
                    }
                </div>
                <div>
                    {
                        summaryDimension['season'].map(item => {
                            return <CheckableTag
                                style={{ border: "1px solid #ccc", width: 50, marginTop: 5, textAlign: 'center' }}
                                key={item}
                                checked={seasonSelectedTags.indexOf(item) > -1}
                                onChange={checked => seasonHandleChange(item, checked)}
                            >
                                {item}
                            </CheckableTag>
                        })
                    }
                </div>
                <div style={{ display: tabKey == '品类价格带' ? 'block' : 'none' }}>
                    {
                        summaryDimension['secondary_category'].map(item => {
                            return <CheckableTag
                                style={{ border: "1px solid #ccc", whiteSpace: 'nowrap', marginTop: 5, textAlign: 'center' }}
                                key={item}
                                checked={categorySelectedTags.indexOf(item) > -1}
                                onChange={checked => categoryHandleChange(item, checked)}
                            >
                                {item}
                            </CheckableTag>
                        })
                    }
                </div>
                <Button type="primary" style={{ marginTop: 20 }} onClick={() => {
                    // console.log(selectedTags);
                    // console.log(tabKey);
                    if (tabKey == '全店数据') {
                        if (yearSelectedTags.length == 0) {
                            message.warn('请至少选择一个年份维度')
                        } else if (seasonSelectedTags.length == 0) {
                            message.warn('请至少选择一个季节维度')
                        } else if (productLineSelectedTags.length == 0) {
                            message.warn('请至少选择一个产品线维度')
                        } else {
                            getAllShopSummary(productLineSelectedTags, yearSelectedTags, seasonSelectedTags, selectionPlanName)
                        }
                    }
                    if (tabKey == '品类') {
                        getCategorySummary(productLineSelectedTags, yearSelectedTags, seasonSelectedTags)
                    }
                    if (tabKey == '品类价格带') {
                        if (categorySelectedTags == '') {
                            message.warn('请至少选择一个品类')
                        } else if (yearSelectedTags.length == 0) {
                            message.warn('请至少选择一个年份维度')
                        } else if (seasonSelectedTags.length == 0) {
                            message.warn('请至少选择一个季节维度')
                        } else if (productLineSelectedTags.length == 0) {
                            message.warn('请至少选择一个产品线维度')
                        } else {
                            getCategoryPriceSummary(productLineSelectedTags, yearSelectedTags, seasonSelectedTags)
                        }
                    }
                }}>搜索</Button>
            </Drawer> */}
            <Drawer
                title="选择汇总范围"
                visible={isSearch}
                onClose={() => {
                    setIsSearch(false)
                }}
                width="40vw"
            >
                <Badge.Ribbon text={tabKey == "全店数据" ? '当前显示数据' : ''} color={tabKey == "全店数据" ?  'red' : 'blue'}>
                    <div style={{boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', width: '100%', height: 180, padding:20 }}>
                        <Title level={5}>全店数据</Title>
                        <div style={{display:"flex"}}>
                            <b style={{width:60}}>产品线:</b>
                            {
                                summaryDimension['product_line'].map(item => {
                                    return <CheckableTag
                                        style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                        key={item}
                                        checked={allShopProductLineSelectedTags.indexOf(item) > -1}
                                        onChange={checked => allShopProductLineHandleChange(item, checked)}
                                    >
                                        {item}
                                    </CheckableTag>
                                })
                            }
                        </div>
                        <div style={{display:"flex"}}>
                            <b style={{width:60}}>年份:</b>
                            {
                                summaryDimension['year'].map(item => {
                                    return <CheckableTag
                                        style={{ border: "1px solid #ccc", width: 50, marginTop: 5, textAlign: 'center' }}
                                        key={item}
                                        checked={allShopYearSelectedTags.indexOf(item) > -1}
                                        onChange={checked => allShopYearHandleChange(item, checked)}
                                    >
                                        {item}
                                    </CheckableTag>
                                })
                            }
                        </div>
                        <div style={{display:"flex"}}>
                            <b style={{width:60}}>季节:</b>
                            {
                                summaryDimension['season'].map(item => {
                                    return <CheckableTag
                                        style={{ border: "1px solid #ccc", width: 50, marginTop: 5, textAlign: 'center' }}
                                        key={item}
                                        checked={allShopSeasonSelectedTags.indexOf(item) > -1}
                                        onChange={checked => allShopSeasonHandleChange(item, checked)}
                                    >
                                        {item}
                                    </CheckableTag>
                                })
                            }
                        </div>
                        <Button style={{marginTop:10}} onClick={()=>{
                            setTabKey('全店数据')
                            if (allShopYearSelectedTags.length == 0) {
                                message.warn('请至少选择一个年份维度')
                            } else if (allShopSeasonSelectedTags.length == 0) {
                                message.warn('请至少选择一个季节维度')
                            } else if (allShopProductLineSelectedTags.length == 0) {
                                message.warn('请至少选择一个产品线维度')
                            } else {
                                getAllShopSummary(allShopProductLineSelectedTags, allShopYearSelectedTags, allShopSeasonSelectedTags, selectionPlanName)
                            }
                        }}>查询全店数据</Button>
                    </div>
                </Badge.Ribbon>
                <Badge.Ribbon text={tabKey == "品类" ? '当前显示数据' : ''} color={tabKey == "品类" ?  'red' : 'blue'}>
                    <div style={{boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', width: '100%', height: 180, padding:20, marginTop:10 }}>
                        <Title level={5}>品类</Title>
                        <div style={{display:"flex"}}>
                            <b style={{width:60}}>产品线:</b>
                            {
                                summaryDimension['product_line'].map(item => {
                                    return <CheckableTag
                                        style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                        key={item}
                                        checked={categoryProductLineSelectedTags.indexOf(item) > -1}
                                        onChange={checked => categoryProductLineHandleChange(item, checked)}
                                    >
                                        {item}
                                    </CheckableTag>
                                })
                            }
                        </div>
                        <div style={{display:"flex"}}>
                            <b style={{width:60}}>年份:</b>
                            {
                                summaryDimension['year'].map(item => {
                                    return <CheckableTag
                                        style={{ border: "1px solid #ccc", width: 50, marginTop: 5, textAlign: 'center' }}
                                        key={item}
                                        checked={categoryYearSelectedTags.indexOf(item) > -1}
                                        onChange={checked => categoryYearHandleChange(item, checked)}
                                    >
                                        {item}
                                    </CheckableTag>
                                })
                            }
                        </div>
                        <div style={{display:"flex"}}>
                            <b style={{width:60}}>季节:</b>
                            {
                                summaryDimension['season'].map(item => {
                                    return <CheckableTag
                                        style={{ border: "1px solid #ccc", width: 50, marginTop: 5, textAlign: 'center' }}
                                        key={item}
                                        checked={categorySeasonSelectedTags.indexOf(item) > -1}
                                        onChange={checked => categorySeasonHandleChange(item, checked)}
                                    >
                                        {item}
                                    </CheckableTag>
                                })
                            }
                        </div>
                        <Button style={{marginTop:10}} onClick={()=>{
                            setTabKey('品类')
                            getCategorySummary(categoryProductLineSelectedTags, categoryYearSelectedTags, categorySeasonSelectedTags, selectionPlanName)
                        }}>查询品类数据</Button>
                    </div>
                </Badge.Ribbon>
                <Badge.Ribbon text={tabKey == "品类价格带" ? '当前显示数据' : ''} color={tabKey == "品类价格带" ?  'red' : 'blue'}>
                    <div style={{boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', width: '100%', height: 230, padding:20, marginTop:10 }}>
                        <Title level={5}>品类价格带</Title>
                        <div style={{display:"flex"}}>
                            <b style={{width:60}}>产品线:</b>
                            <div>
                                {
                                    summaryDimension['product_line'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                            key={item}
                                            checked={productLineSelectedTags.indexOf(item) > -1}
                                            onChange={checked => productLineHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{display:"flex"}}>
                            <b style={{width:60}}>年份:</b>
                            <div>
                                {
                                    summaryDimension['year'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", width: 50, marginTop: 5, textAlign: 'center' }}
                                            key={item}
                                            checked={yearSelectedTags.indexOf(item) > -1}
                                            onChange={checked => yearHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{display:"flex"}}>
                            <b style={{width:60}}>季节:</b>
                            <div>
                                {
                                    summaryDimension['season'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", width: 50, marginTop: 5, textAlign: 'center' }}
                                            key={item}
                                            checked={seasonSelectedTags.indexOf(item) > -1}
                                            onChange={checked => seasonHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{display:"flex"}}>
                            <b style={{width:60, marginRight:summaryDimension['secondary_category'].length == 1 ? 0 : 14}}>品类:</b>
                            <div>
                                {
                                    summaryDimension['secondary_category'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", whiteSpace: 'nowrap', marginTop: 5, textAlign: 'center' }}
                                            key={item}
                                            checked={categorySelectedTags.indexOf(item) > -1}
                                            onChange={checked => categoryHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                        </div>
                        <Button style={{marginTop:10}} onClick={()=>{
                            if (categorySelectedTags == '') {
                                message.warn('请至少选择一个品类')
                            } else if (yearSelectedTags.length == 0) {
                                message.warn('请至少选择一个年份维度')
                            } else if (seasonSelectedTags.length == 0) {
                                message.warn('请至少选择一个季节维度')
                            } else if (productLineSelectedTags.length == 0) {
                                message.warn('请至少选择一个产品线维度')
                            } else {
                                setTabKey('品类价格带')
                                getCategoryPriceSummary(productLineSelectedTags, yearSelectedTags, seasonSelectedTags)
                            }
                        }}>查询品类价格带数据</Button>
                    </div>
                </Badge.Ribbon>
               
            </Drawer>
            <Modal
                title="导出Excel"
                visible={isDownloadFile}
                onCancel={() => {
                    setIsDownloadFile(false)
                }}
                footer={[]}
                centered={true}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Radio.Group value={isBrandFile} onChange={(e) => {
                        setIsBrandFile(e.target.value)
                    }}>
                        <Radio value={1}>导出给品牌方的Excel</Radio>
                        <Radio value={2}>导出上传平台的Excel</Radio>
                    </Radio.Group>
                </div>
                <div style={{ display: isBrandFile == 1 ? 'flex' : 'none', justifyContent: 'center', marginTop: 30 }}>
                    <b style={{ marginRight: 10, marginTop: 5 }}>比较活动时间:</b>
                    <RangePicker
                        disabledDate={disabledDate}
                        locale={locale}
                        value={[downloadStartTime != '' ? moment(downloadStartTime, dateFormat) : '', downloadEndTime != '' ? moment(downloadEndTime, dateFormat) : '']}
                        locale={locale} style={{ borderRadius: 30 }}
                        onChange={(value, dateString) => {
                            if (dateString[0] == '') {
                                message.warn('请选择合理的 时间')
                            } else {
                                setDownloadStartTime(dateString[0]);
                                setDownloadEndTime(dateString[1]);
                            }

                        }}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                    <Button disabled={effective ? true : false} type="primary" onClick={() => {
                        if (isBrandFile == 1) {
                            if (downloadStartTime == '') {
                                message.warn('请选择近期活动时间')
                            } else {
                                setEffective(1)
                                let key = "download"
                                message.loading({ content: '开始下载数据...', key, duration: 100 })
                                let dataProps = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "name": props.location.state.name,
                                    "start_time": downloadStartTime,
                                    "end_time": downloadEndTime,
                                    'creator': cookie.load('name')
                                }
                                Axios({
                                    method: 'POST',
                                    url: servicePath.downloadSelectionPricingResultDetail,
                                    data: dataProps,
                                    responseType: "blob",
                                    headers: {
                                        'authorization': cookie.load('token')
                                    },
                                }).then(res => {
                                    setEffective(0)
                                    downExcel(res)
                                    setIsDownloadFile(false)
                                    message.success({ content: "导出成功！", key })
                                }).catch(e => {
                                    setEffective(0)
                                    message.error({ content: '文件制作出错，请联系it同事！', key })
                                })
                            }
                        } else {
                            let dataProps = {
                                "roleId": cookie.load('role'),
                                "shopList": cookie.load('shopList'),
                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                'name': selectionPlanName,
                                'creator': cookie.load('name')
                            }
                            axios({
                                data: dataProps,
                                method: 'POST',
                                url: servicePath.downloadSelectionPricingResult,
                                responseType: "blob",
                                headers: {
                                    'Content-Type': 'application/json',  //如果写成contentType会报错
                                    'authorization': cookie.load('token')
                                }
                            }).then(
                                res => {
                                    downExcel(res)
                                }
                            ).catch(e => {
                                message.error({ content: '文件制作出错，请联系it同事！' })
                            })
                        }
                    }}>下载</Button>
                </div>
            </Modal>
        </div>
    )

}

export default Index