import React, { useEffect, useState } from 'react';
import { message, Divider, Radio } from 'antd'
import SankeyDiagram from '../component/sankeyDiagram'
import ColumnCrossing from '../component/columnCrossing'
import DetailColumnCrossing from '../component/columnCrossing'
import Intersection from '../component/intersection'
import DetailIntersection from '../component/intersection/detail_index'
import LineChart from '../component/lineChart'
import BarChart from '../component/barChart'
import Difference from '../component/difference'
import DetailDifference from '../component/difference/detail_index'
import NbAxios from '../../../config/utils/nbAxios';
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl';
function Index(props) {
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    function getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    }
    // 旭日图数据
    const [ sankeyDiagramData , setSankeyDiagramData] = useState({})
    // 差异表数据
    const [differenceData, setDifferentData] = useState([])
    // 行交差数据
    const [intersectionData, setIntersectionData] = useState([])
    // 列交差数据
    const [columnCrossingData, setColumnCrossingData] = useState([])

    // 折线图数据
    const [lineChartData, setLineChartData] = useState({})
    // 柱形图数据
    const [barChartData, setBarChartData] = useState({})
    // 模版类型
    // const [comparisonType, setComparisonType] = useState(0)
    const [comparisonType, setComparisonType] = useState(1)
    // 呈现方式
    // const [presentWays, setPresentWays] = useState([])
    const [presentWays, setPresentWays] = useState(['差异表', '行交差'])
    const [presentWaysState, setPresentWaysState] = useState([])
    // 对比短链
    const [comparsionShortChain, setComparsionShortChain] = useState('')
    // useEffect(() => {
    //     if (props.location.state['comparison_type'] == 1) {
    //         setComparisonType(props.location.state['comparison_type'])
    //         setPresentWaysState(props.location.state['selectedPresentWays']);
    //         setComparsionShortChain(props.location.state['comparsionShortChain']);
    //     } else {
            // setComparisonType(props.location.state['comparison_type'])
            // setPresentWaysState(props.location.state['selectedPresentWays']);
            // setComparsionShortChain(props.location.state['comparsionShortChain']);
    //     }

    // }, [])

    useEffect(()=>{
        if(getQueryString("comparison_type") == 1){
            setComparisonType(getQueryString('comparison_type'))
            setPresentWaysState(JSON.parse(getQueryString('selectedPresentWays')));
            setComparsionShortChain(getQueryString('comparsionShortChain'));
        }else{
            setComparisonType(getQueryString('comparison_type'))
            setPresentWaysState(JSON.parse(getQueryString('selectedPresentWays')));
            setComparsionShortChain(getQueryString('comparsionShortChain'));
        }
    },[])

    useEffect(() => {
        if (comparisonType == 1) {
            presentWaysState.map(item => {
                if (item == 'intersection') {
                    getDetailDataComparisonRecordCache(1)
                }
                if (item == 'column') {
                    getDetailDataComparisonRecordCache(2)
                }
                if (item == 'different') {
                    getDetailDataComparisonRecordCache(3)
                }
            })
        } else {
            presentWaysState.map(item => {
                if (item == 'intersection') {
                    getGroupStatisticsComparisonRecordCache(1)
                }
                if (item == 'column') {
                    getGroupStatisticsComparisonRecordCache(2)
                }
                if (item == 'different') {
                    getGroupStatisticsComparisonRecordCache(3)
                }
                if (item == 'histogram'){
                    getGroupStatisticsComparisonRecordCache(5);
                }
                if(item == 'sankey'){
                    getGroupStatisticsComparisonRecordCacheRisingSunChart()
                }
            })
        }
        setPresentWays(presentWaysState)
    }, [comparsionShortChain])

    // 查询对比记录缓存接口
    // data_type 数据类型（1：行交差，2：列交差，3：差异表，4：折线图，5：柱状图，6：旭日图）
    const getDetailDataComparisonRecordCache = (data_type) => {
        let key = "dataComparison"
        message.loading({ content: '正在计算数据...', key, duration: 100 })
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "data_type": data_type,
            "short_chain": comparsionShortChain
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailDataComparisonRecordCache).then(res => {
            if (res.success) {
                message.success({ content: '计算数据成功...', key})
                if (data_type == 1) {
                    setIntersectionData(res.data)
                }
                if (data_type == 2) {
                    setColumnCrossingData(res.data);
                }
                if (data_type == 3) {
                    setDifferentData(res.data)
                }
                
            } else {
                message.warn({ content: res.errorMsg, key,})
            }
        })
    }
    // 查询分组对比对比记录缓存接口
    // data_type 数据类型（1：行交差，2：列交差，3：差异表，4：折线图，5：柱状图，6：旭日图）
    const getGroupStatisticsComparisonRecordCache = (data_type) => {
        let key = "dataComparison"
        message.loading({ content: '正在计算数据...', key, duration: 100 })
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "data_type": data_type,
            "short_chain": comparsionShortChain
        }
        NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsComparisonRecordCache).then(res => {
            if (res.success) {
                message.success({ content: '计算数据成功...', key,})
                if (data_type == 1) {
                    setIntersectionData(res.data)
                }
                if (data_type == 2) {
                    setColumnCrossingData(res.data);
                }
                if (data_type == 3) {
                    setDifferentData(res.data)
                }
                if(data_type == 5){
                   setBarChartData(res.data)
                }
            } else {
                message.warn({ content: res.errorMsg, key,})
            }
        })

    }

    // 查询分组统计的旭日图
    const getGroupStatisticsComparisonRecordCacheRisingSunChart = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "data_type": 6,
            "short_chain": comparsionShortChain
        }
        NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsComparisonRecordCacheRisingSunChart).then(res => {
            if (res.success) {
                setSankeyDiagramData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div style={{ paddingBottom: 20 }}>
            {/* <div style={{width: "100vw", overflowX: "auto", boxShadow:"#9e9e9e 0px 0px 10px", marginTop:15  }}>
                <Difference />
            </div> */}
            {
                differenceData.map((item, index) => {
                    return <div style={{ width: "100vw", overflowX: "auto", boxShadow: "#9e9e9e 0px 0px 10px", marginTop: 15, paddingBottom: 10, display: presentWays.indexOf('different') != -1 ? '' : 'none' }}>
                        {
                            comparisonType == 1 ?
                                <DetailDifference differenceData={item} differenceIndex={index} shop={props.history.location.pathname.split('/')[2]} /> :
                                <Difference differenceData={item} index={index} shop={props.history.location.pathname.split('/')[2]} />
                        }
                    </div>
                })
            }
            {
                intersectionData.map((item, index) => {
                    return <div style={{ width: "100vw", overflowX: "auto", boxShadow: "#9e9e9e 0px 0px 10px", marginTop: 15, paddingBottom: 10, display: presentWays.indexOf('intersection') != -1 ? '' : 'none' }}>
                        {
                            comparisonType == 1 ?
                                <DetailIntersection intersectionData={item} intersectionIndex={index} shop={props.history.location.pathname.split('/')[2]} /> :
                                <Intersection intersectionData={item} index={index} shop={props.history.location.pathname.split('/')[2]} />
                        }

                    </div>
                })
            }
            {
                columnCrossingData.map((item, index) => {
                    return <div style={{ width: '100vw', overflowX: 'auto', boxShadow: "#9e9e9e 0px 0px 10px", marginTop: 15, display: presentWays.indexOf('column') != -1 ? '' : 'none' }}>
                        {
                            comparisonType == 1 ?
                                <DetailColumnCrossing columnCrossingData={item} columnCrossingIndex={index} shop={props.history.location.pathname.split('/')[2]} /> :
                                <ColumnCrossing columnCrossingData={item} index={index} shop={props.history.location.pathname.split('/')[2]} />
                        }

                    </div>
                })
            }
            <div style={{ width: '100vw', boxShadow: "#9e9e9e 0px 0px 10px" }}>
                {/* <LineChart lineChartData={lineChartData} /> */}
                {
                    presentWays.indexOf('折线图') != -1 ? <LineChart lineChartData={lineChartData} /> : <span></span>
                }
            </div>
            <div style={{ width: '100vw', boxShadow: "#9e9e9e 0px 0px 10px" }}>
                {
                    presentWays.indexOf('histogram') != -1 ? <BarChart barChartData={barChartData} /> : <span></span>
                }
            </div>
            <div style={{ width: '100vw', boxShadow: "#9e9e9e 0px 0px 10px" }}>
                {
                    presentWays.indexOf('sankey') != -1 ?
                        <SankeyDiagram
                            sankeyDiagramData={sankeyDiagramData}
                        />
                        : <span></span>
                }

            </div>
        </div>
    )

}

export default Index