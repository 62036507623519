import React, { Component, useEffect, useState } from 'react';
import { Typography, Select, message, Button, Input, Tooltip, Spin } from 'antd'
import { PlusCircleOutlined, MinusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import NbAxios from '../../../../config/utils/nbAxios';
import cookie from 'react-cookies'
import servicePath from '../../../../config/apiUrl';
const { Title, Text } = Typography
const { Option } = Select

function Index({
    // 活动名字
    name,
    //路由参数
    props,
    // 平台利益点json
    optionJson
}) {
    let countTimeout
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 检验季节数据
    const seasonList = ['春', '夏', '秋', '冬']
    // 产品线集合
    const [productLine, setProductLine] = useState([])
    // 年份季节
    const [yearSeasonList, setYearSeasonList] = useState([])
    // 定价范围
    const [pricingRangDict, setPricingRangDict] = useState([
        {
            'product': [],
            'startYearSeason': '',
            'endYearSeason': '',
        }
    ])
    // 活动名字
    const [selectionPlanName, setSelectionPlanName] = useState('')
    // 津贴
    const [allowance, setAllowance] = useState([{ 'sum': '', 'reduce': '' }])
    // 优惠券
    const [coupon, setCoupon] = useState([{ 'sum': '', 'reduce': '' }])
    // 店铺券集合
    const [shopVoucherList, setShopVoucherList] = useState([])
    // 选择的店铺券
    const [shopVoucher, setShopVoucher] = useState([])
    // 专区折扣
    const [specialDiscount, setSpecialDiscount] = useState([])
    // 限时特惠
    const [limitedTime, setLimitedTime] = useState([{ 'forward': '', 'rear': '', 'clothes': '','fold': '' }])
    // 限时特惠专区
    const [limitedTimeDiscount, setLimitedTimeDiscount] = useState('')
    // 活动价上限
    const [activityPriceCap, setActivityPriceCap] = useState('')
    // 计时器
    const [count, setCount] = useState(0)
    const [isCount, setIsCount] = useState(false)
    // 是否刚刚打开
    const [ isStart , setIsStart ] = useState(1)
    
    useEffect(() => {
        setPricingRangDict(optionJson['pricingRangDict']);
        setAllowance(optionJson['allowance']);
        setCoupon(optionJson['coupon']);
        setShopVoucher(optionJson['shopVoucher']);
        setSpecialDiscount(optionJson['specialDiscount']);
        setLimitedTime(optionJson['limitedTime']);
        setLimitedTimeDiscount(optionJson['limitedTimeDiscount']);
        setActivityPriceCap(optionJson['activityPriceCap']);
        setSelectionPlanName(name);
        setIsStart(1)
        // setSelectionPlanName("99大促正式");
        getFitTargetValue()
        getTblCouponsList()
    }, [optionJson])

    useEffect(() => {
        if (count != 0) {
            countTimeout = setTimeout(() => {
                if (count != 1) {
                    let nowcount = count - 1
                    setCount(nowcount)
                } else {
                    setCount(60)
                }

            }, 1000)
        }
    }, [count])
    
    useEffect(()=>{
        if(!isStart){
            if(specialDiscount.length == 0){
                setLimitedTime([{ 'forward': '', 'rear': '', 'clothes': '','fold': '' }])
                setLimitedTimeDiscount('')
            }
        }else{
            setIsStart(0)
        }
       
    },[specialDiscount])

    // 查询下拉菜单列表接口
    const getFitTargetValue = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "select_menu_list": ['产品线', '年份季节']
        }
        NbAxios(dataProps, "POST", servicePath.queryFitTargetValue).then(res => {
            if (res.success) {
                let productLine = res.data['产品线']
                productLine.unshift({ 'name': '全部' })
                setProductLine(productLine)
                setYearSeasonList(res.data['年份季节'])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 查询店铺券
    const getTblCouponsList = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dataProps, "POST", servicePath.queryTblCouponsList).then(res => {
            if (res.success) {
                setShopVoucherList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 商品定价接口
    const merchandiseValuation = (optionjson) => {
        setCount(60)
        setIsCount(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'name': selectionPlanName,
            'option_json': optionjson
        }
        NbAxios(dataProps, "POST", servicePath.merchandiseValuation).then(res => {
            if (res.success) {
                setCount(0)
                message.success({ content: '商品价格计算成功...' })
                props.history.push({
                    'pathname': `/app/${props.history.location.pathname.split('/')[2]}/selectionPricing/formal`,
                    'state': { 'name': selectionPlanName }
                })
            } else {
                clearTimeout(countTimeout)
                setEffective(0)
                setIsCount(false)
                setCount(0)
                message.warn({ content: res.errorMsg })
            }
        })
    }

    return (
        <div>
            <Spin tip={`利益点创建成功,正在计算商品价格,预计还需要${count}秒...`} spinning={isCount} size="large" >
                <div style={{ width:'100%' }}>
                    <div style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                        <div style={{ boxShadow: "-2px 2px 4px #9e9e9e", width: "100%" }}>
                            <div style={{ paddingTop: 10, paddingLeft: 20 }}>
                                <Title level={5}>定价范围</Title>
                            </div>
                            <div style={{ marginLeft: 30, marginTop: 10, paddingBottom: 5 }}>
                                {
                                    pricingRangDict.map((item, index) => {
                                        return <div style={{ display: 'flex', marginTop: index > 0 ? 5 : '' }}>
                                            <div>
                                                <b style={{ marginRight: 5 }}>产品线:</b>
                                                <Select value={item.product} mode="multiple" style={{ width: 200, marginRight: 10 }} onChange={(value) => {
                                                    let isAll = 0
                                                    let obj = []
                                                    value.map(item => {
                                                        if (item.indexOf('全部') != -1) {
                                                            isAll = 1
                                                            obj.push(item)
                                                        }
                                                    })
                                                    if (isAll) {
                                                        let pricingRangDictObj = [...pricingRangDict]
                                                        pricingRangDictObj[index]['product'] = obj
                                                        setPricingRangDict(pricingRangDictObj)
                                                    } else {
                                                        let pricingRangDictObj = [...pricingRangDict]
                                                        pricingRangDictObj[index]['product'] = value
                                                        setPricingRangDict(pricingRangDictObj)
                                                    }
                                                }}>
                                                    {
                                                        productLine.map(item => {
                                                            return <Option value={item.name}>{item.name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                            <div>
                                                <b style={{ marginRight: 5, marginLeft: 30 }}>起始年份季节:</b>
                                                <Select style={{ width: 200 }} value={item.startYearSeason} onChange={(value) => {
                                                    let pricingRangDictObj = [...pricingRangDict]
                                                    pricingRangDictObj[index]['startYearSeason'] = value
                                                    pricingRangDictObj[index]['endYearSeason'] = ''
                                                    setPricingRangDict(pricingRangDictObj)
                                                }}>
                                                    {
                                                        yearSeasonList.map(item => {
                                                            return <Option value={item.name}>{item.name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                            <div>
                                                <b style={{ marginRight: 5, marginLeft: 30 }}>终止年份季节:</b>
                                                <Select style={{ width: 200 }} value={item.endYearSeason} onChange={(value) => {
                                                    if (pricingRangDict[index]['startYearSeason'] != '') {
                                                        let startYearSeason = pricingRangDict[index]['startYearSeason']
                                                        let endYearSeason = value
                                                        // 获取起始年份、季节
                                                        let startYear = parseInt(startYearSeason.split('-')[0])
                                                        let startSeason = startYearSeason.split('-')[1]
                                                        // 获取终止年份、季节
                                                        let endYear = parseInt(endYearSeason.split('-')[0])
                                                        let endSeason = endYearSeason.split('-')[1]
                                                        // 对比年份: 终止年份必须大于等于起始年份
                                                        if (endYear == startYear) {
                                                            // 当对比的年份一样的时候
                                                            // 起始季节下标
                                                            const startSeasonIndex = seasonList.indexOf(startSeason)
                                                            const endSeasonIndex = seasonList.indexOf(endSeason)
                                                            // 起始季节、终止季节可以选择一样的
                                                            // 终止季节必须大于等于起始季节
                                                            if (startSeasonIndex <= endSeasonIndex) {
                                                                let pricingRangDictObj = [...pricingRangDict]
                                                                pricingRangDictObj[index]['endYearSeason'] = value
                                                                setPricingRangDict(pricingRangDictObj)
                                                            } else {
                                                                message.warn('请选择正确的起始季节、终止季节')
                                                            }
                                                        } else if (endYear > startYear) {
                                                            // 当终止年份大于起始年份,不比较季节
                                                            let pricingRangDictObj = [...pricingRangDict]
                                                            pricingRangDictObj[index]['endYearSeason'] = value
                                                            setPricingRangDict(pricingRangDictObj)
                                                        } else {
                                                            message.warn('请选择正确的起始年份、终止年份')
                                                        }
                                                    } else {
                                                        message.warn('请先选择起始年份季节')
                                                    }
                                                }}>
                                                    {
                                                        yearSeasonList.map(item => {
                                                            return <Option value={item.name}>{item.name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                            <div style={{ display: 'flex', marginLeft: 20 }}>
                                                <MinusCircleOutlined style={{ fontSize: 18, marginTop: 5, display: pricingRangDict.length == 1 ? 'none' : '' }} onClick={() => {
                                                    let obj = [...pricingRangDict]
                                                    obj.splice(index, 1)
                                                    setPricingRangDict(obj)
                                                }} />
                                                <PlusCircleOutlined style={{ fontSize: 18, marginTop: 5,  marginLeft: 10, display: pricingRangDict.length == 1 ? '' : pricingRangDict.length -1 == index ? '':'none'}} onClick={() => {
                                                    let obj = [...pricingRangDict]
                                                    let addObj = {
                                                        'product': [],
                                                        'startYearSeason': '',
                                                        'endYearSeason': '',
                                                    }
                                                    obj.push(addObj)
                                                    setPricingRangDict(obj)
                                                }} />
                                               
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ boxShadow: "-2px 2px 4px #9e9e9e", width: "100%", marginTop: 20 }}>
                            <div style={{ paddingTop: 10, paddingLeft: 20 }}>
                                <Title level={5}>利益点设置</Title>
                            </div>
                            <div style={{ marginLeft: 30, marginTop: 10}}>
                                <Text type="danger">{"系统中的折扣以100制。(如: 8折 -> 填80、75折 -> 填75)"}</Text>
                            </div>
                            <div style={{ marginLeft: 40, marginTop: 10, display: "flex", width: 390, flexWrap: 'wrap' }}>
                                <Text type="secondary">
                                    店铺券: 1999-100;2999-150; 5999-300 清仓专区: 2件8折
                                    专供21秋: 1件9折    奥莱: 1件75折 & 1件8折  商场: 1件75折
                                </Text>
                            </div>
                            <div style={{ marginLeft: 40 }}>
                                <div style={{ marginTop: 10, display: 'flex' }}>
                                    {/* <span style={{color:'red', marginTop:6, fontSize:15, marginRight:5}}>*</span> */}
                                    <b style={{ marginTop: 5, fontSize: 15 }}>津贴</b>
                                    <Tooltip title="为购物补贴，如天猫淘宝购物津贴可跨店使用，店铺及跨店满足同一门槛满减且达到满减就可使用购物津贴，如：每满300就减少30元,满600元就减少60元；" placement="topLeft">
                                        <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                    </Tooltip>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>:</b>
                                    <Input value={allowance[0]['sum']} controls={false} style={{ height: 30, marginLeft: 10, width:100 }} onChange={(e) => {
                                        // if (value == null) {
                                        //     let obj = [...allowance]
                                        //     obj[0]['sum'] = ''
                                        //     setAllowance(obj)
                                        // } else {
                                        //     let obj = [...allowance]
                                        //     obj[0]['sum'] = value
                                        //     setAllowance(obj)
                                        // }
                                        let obj = [...allowance]
                                        obj[0]['sum'] = e.target.value.replace(/[^\d^\.]+/g,'')
                                        setAllowance(obj)

                                    }} />
                                    <Text strong style={{ marginLeft: 15, marginRight: 15, fontSize: 15, marginTop: 2 }}>减</Text>
                                    <Input value={allowance[0]['reduce']} controls={false} style={{ height: 30, width:100 }} onChange={(e) => {
                                        // if (value == null) {
                                        //     let obj = [...allowance]
                                        //     obj[0]['reduce'] = ''
                                        //     setAllowance(obj)
                                        // } else {
                                        //     let obj = [...allowance]
                                        //     obj[0]['reduce'] = value
                                        //     setAllowance(obj)
                                        // }
                                        let obj = [...allowance]
                                        obj[0]['reduce'] = e.target.value.replace(/[^\d^\.]+/g,'')
                                        setAllowance(obj)
                                    }} />
                                </div>
                                <div style={{ marginTop: 10, display: 'flex' }}>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>平台购物券</b>
                                    {/* <b style={{marginTop:5, fontSize:15}}>优惠券</b> */}
                                    <Tooltip title="在规定的周期内，购买对应商品类型和额度商品时，下单结算时会减免一定金额，可在改价时根据需求进行调整；" placement="topLeft">
                                        <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                    </Tooltip>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>:</b>
                                    <div style={{ display: 'flex', width: '90%', flexWrap: 'wrap' }}>
                                        {
                                            coupon.map((item, index) => {
                                                return <div style={{ display: 'flex', width: 240, marginTop: 2 }}>
                                                    <Input value={coupon[index]['sum']} controls={false} style={{ height: 30, marginLeft: 10, width:100 }} onChange={(e) => {
                                                        // if (value == null) {
                                                        //     let obj = [...coupon]
                                                        //     obj[index]['sum'] = ''
                                                        //     setCoupon(obj)
                                                        // } else {
                                                        //     let obj = [...coupon]
                                                        //     obj[index]['sum'] = value
                                                        //     setCoupon(obj)
                                                        // }
                                                        let obj = [...coupon]
                                                        obj[index]['sum'] =  e.target.value.replace(/[^\d^\.]+/g,'')
                                                        setCoupon(obj)

                                                    }} />
                                                    <Text strong style={{ marginLeft: 15, marginRight: 15, fontSize: 15, marginTop: 2 }}>减</Text>
                                                    <Input min={0} value={coupon[index]['reduce']} controls={false} style={{ height: 30, width:100 }} onChange={(e) => {
                                                        // if (value == null) {
                                                        //     let obj = [...coupon]
                                                        //     obj[index]['reduce'] = ''
                                                        //     setCoupon(obj)
                                                        // } else {
                                                        //     let obj = [...coupon]
                                                        //     obj[index]['reduce'] = value
                                                        //     setCoupon(obj)
                                                        // }
                                                        let obj = [...coupon]
                                                        obj[index]['reduce'] =  e.target.value.replace(/[^\d^\.]+/g,'')
                                                        setCoupon(obj)
                                                    }} />
                                                   
                                                    <MinusCircleOutlined style={{ fontSize: 15, marginTop: 5, marginLeft: 5, display: coupon.length == 1 ? 'none' : '' }} onClick={() => {
                                                        let obj = [...coupon]
                                                        obj.splice(index, 1)
                                                        setCoupon(obj)
                                                    }} />
                                                </div>
                                            })
                                        }
                                        <PlusCircleOutlined style={{ fontSize: 15, marginTop: 7, marginLeft: 10 }} onClick={() => {
                                            let obj = [...coupon]
                                            let addCoupon = { 'sum': '', 'reduce': '' }
                                            obj.push(addCoupon)
                                            setCoupon(obj)
                                        }} />
                                    </div>
                                </div>
                                <div style={{ marginTop: 10, display: 'flex' }}>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>店铺券</b>
                                    <Tooltip title="分为店铺优惠券和商品优惠券，一个订单中默认优先使用优惠力度大的优惠券，仅限于优惠券所在店铺购物，不可跨店使用；在设置的有效期内，订单金额(不包括邮费)满足时才能使用，不能同时使用店铺优惠券和商品优惠券；" placement="topLeft">
                                        <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                    </Tooltip>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>:</b>
                                    <Select value={shopVoucher} allowClear mode="multiple" style={{ width: 550, marginLeft: 10 }} onChange={(value) => {
                                        setShopVoucher(value)
                                    }}>
                                        {
                                            shopVoucherList.map(item => {
                                                return <Option value={item.name}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                                <div style={{ marginTop: 10, display: 'flex' }}>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>专区折扣</b>
                                    <Tooltip title="即消费者一次性在店铺中购买特定商品达到一定件数，所购买商品可以享受特定的折扣优惠；" placement="topLeft">
                                        <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                    </Tooltip>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>:</b>
                                    <div style={{ display: 'flex', width: '90%', flexWrap: 'wrap' }}>
                                        {
                                            specialDiscount.map((item, index) => {
                                                return <div style={{ display: 'flex', width: 260, marginTop: 2 }}>
                                                    <Input value={specialDiscount[index]['clothes']} style={{ height: 30, marginLeft: 10, width: 60 }} onChange={(e) => {
                                                        // if (value == null) {
                                                        //     let obj = [...specialDiscount]
                                                        //     obj[index]['clothes'] = ''
                                                        //     setSpecialDiscount(obj)
                                                        // } else {
                                                        //     let obj = [...specialDiscount]
                                                        //     obj[index]['clothes'] = value
                                                        //     setSpecialDiscount(obj)
                                                        // }
                                                        let obj = [...specialDiscount]
                                                        obj[index]['clothes'] = e.target.value.replace(/[^\d^\.]+/g,'')
                                                        setSpecialDiscount(obj)
                                                        setLimitedTimeDiscount('')
                                                    }} />
                                                    <Text strong style={{ marginLeft: 15, marginRight: 15, fontSize: 15, marginTop: 2 }}>件</Text>
                                                    <Tooltip title="系统中的折扣以100制。(如: 8折 -> 填80、75折 -> 填75)" placement="topLeft">
                                                        <Input value={specialDiscount[index]['fold']} style={{ height: 30, marginLeft: 10, width: 60 }} onChange={(e) => {
                                                            // if (value == null) {
                                                            //     let obj = [...specialDiscount]
                                                            //     obj[index]['fold'] = ''
                                                            //     setSpecialDiscount(obj)
                                                            // } else {
                                                            //     let obj = [...specialDiscount]
                                                            //     obj[index]['fold'] = value
                                                            //     setSpecialDiscount(obj)
                                                            // }
                                                            let obj = [...specialDiscount]
                                                            obj[index]['fold'] = e.target.value.replace(/[^\d^\.]+/g,'')
                                                            setSpecialDiscount(obj)
                                                            setLimitedTimeDiscount('')
                                                        }} />
                                                    </Tooltip>
                                                    <Text strong style={{ marginLeft: 15, marginRight: 15, fontSize: 15, marginTop: 2 }}>折</Text>
                                                    <MinusCircleOutlined style={{ fontSize: 15, marginTop: 5, marginLeft: 5 }} onClick={() => {
                                                        let obj = [...specialDiscount]
                                                        obj.splice(index, 1)
                                                        setSpecialDiscount(obj)
                                                        setLimitedTimeDiscount('')
                                                    }} />
                                                </div>
                                            })
                                        }
                                        <PlusCircleOutlined style={{ fontSize: 15, marginTop: 8, marginLeft:5 }} onClick={() => {
                                            let obj = [...specialDiscount]
                                            let addSpecialDiscount = { 'clothes': '', 'fold': '' }
                                            obj.push(addSpecialDiscount)
                                            setSpecialDiscount(obj)
                                            setLimitedTimeDiscount('')
                                        }} />
                                       
                                    </div>
                                </div>
                                <div style={{ marginTop: 10, display: specialDiscount.length == 0 ? 'none' : 'flex' }}>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>限时特惠</b>
                                    <Tooltip title="（前二后四）指参与活动的淘宝卖家于指定时间段内在原活动价基础上对活动单品另设折扣让利的优惠权益；" placement="topLeft">
                                        <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                    </Tooltip>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>:</b>
                                    <Text strong style={{ marginLeft: 15, marginRight: 2, fontSize: 15, marginTop: 2 }}>前</Text>
                                    <Input value={limitedTime[0]['forward']} style={{ height: 30, marginLeft: 10, width: 60 }} onChange={(e) => {
                                        // if (value == null) {
                                        //     let obj = [...limitedTime]
                                        //     obj[0]['forward'] = ''
                                        //     setLimitedTime(obj)
                                        // } else {
                                        //     let obj = [...limitedTime]
                                        //     obj[0]['forward'] = value
                                        //     setLimitedTime(obj)
                                        // }
                                        let obj = [...limitedTime]
                                        obj[0]['forward'] = e.target.value.replace(/[^\d^\.]+/g,'')
                                        setLimitedTime(obj)

                                    }} />
                                    <Text strong style={{ marginLeft: 15, marginRight: 2, fontSize: 15, marginTop: 2 }}>后</Text>
                                    <Input value={limitedTime[0]['rear']} style={{ height: 30, marginLeft: 10, width: 60 }} onChange={(e) => {
                                        // if (value == null) {
                                        //     let obj = [...limitedTime]
                                        //     obj[0]['rear'] = ''
                                        //     setLimitedTime(obj)
                                        // } else {
                                        //     let obj = [...limitedTime]
                                        //     obj[0]['rear'] = value
                                        //     setLimitedTime(obj)
                                        // }
                                        let obj = [...limitedTime]
                                        obj[0]['rear'] = e.target.value.replace(/[^\d^\.]+/g,'')
                                        setLimitedTime(obj)
                                    }} />
                                    <Select value={limitedTimeDiscount} style={{ width: 200, marginLeft: 20 }} onChange={(value) => {
                                        setLimitedTimeDiscount(value);
                                    }}>
                                        {
                                            specialDiscount.map(item => {
                                                return <Option value={item.clothes + '件' + item.fold + '折'}>{item.clothes + '件' + item.fold + '折'}</Option>
                                            })
                                        }
                                    </Select>
                                    <Input value={limitedTime[0]['clothes']} style={{ height: 30, marginLeft: 10, width: 60 }} onChange={(e) => {
                                        // if (value == null) {
                                        //     let obj = [...limitedTime]
                                        //     obj[0]['clothes'] = ''
                                        //     setLimitedTime(obj)
                                        // } else {
                                        //     let obj = [...limitedTime]
                                        //     obj[0]['clothes'] = value
                                        //     setLimitedTime(obj)
                                        // }
                                        let obj = [...limitedTime]
                                        obj[0]['clothes'] = e.target.value.replace(/[^\d^\.]+/g,'')
                                        setLimitedTime(obj)
                                    }} />
                                    <Text strong style={{ marginLeft: 15, marginRight: 15, fontSize: 15, marginTop: 2 }}>件</Text>
                                    <Tooltip title="系统中的折扣以100制。(如: 8折 -> 填80、75折 -> 填75)" placement="topLeft">
                                        <Input value={limitedTime[0]['fold']} style={{ height: 30, marginLeft: 10, width: 60 }} onChange={(e) => {
                                            // if (value == null) {
                                            //     let obj = [...limitedTime]
                                            //     obj[0]['fold'] = ''
                                            //     setLimitedTime(obj)
                                            // } else {
                                            //     let obj = [...limitedTime]
                                            //     obj[0]['fold'] = value
                                            //     setLimitedTime(obj)
                                            // }
                                            let obj = [...limitedTime]
                                            obj[0]['fold'] = e.target.value.replace(/[^\d^\.]+/g,'')
                                            setLimitedTime(obj)
                                        }} />
                                    </Tooltip>
                                    <Text strong style={{ marginLeft: 15, marginRight: 2, fontSize: 15, marginTop: 2 }}>折</Text>

                                </div>
                                <div style={{ marginTop: 10, display: 'flex' }}>
                                    <span style={{ color: 'red', marginTop: 6, fontSize: 15, marginRight: 5 }}>*</span>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>活动价上限</b>
                                    <Tooltip title="参加双11的商品，正式活动的销售价格期间于天猫或天猫国际平台达成的最低标价的基础上让利至少10%，也就是在最低标价基础上打90折，部分类目除外。其他活动无明确要求可填100折；" placement="topLeft">
                                        <QuestionCircleOutlined style={{ marginTop: 10, marginRight: 5, marginLeft: 5 }} />
                                    </Tooltip>
                                    <b style={{ marginTop: 5, fontSize: 15 }}>:</b>
                                    <Tooltip title="系统中的折扣以100制。(如: 8折 -> 填80、75折 -> 填75)" placement="topLeft">
                                        <Input value={activityPriceCap} style={{ height: 30, marginLeft: 10, width: 60 }} onChange={(e) => {
                                            // if (value == null) {
                                            //     setActivityPriceCap('')
                                            // } else {
                                            //     setActivityPriceCap(value)
                                            // }
                                            setActivityPriceCap(e.target.value.replace(/[^\d^\.]+/g,''))
                                        }} />
                                    </Tooltip>
                                    <Text strong style={{ marginLeft: 15, marginRight: 15, fontSize: 15, marginTop: 2 }}>折</Text>
                                    <Text type="secondary">(参加双11的商品，正式活动的销售价格期间于天猫或天猫国际平台达成的最低标价的基础上让利至少10%，也就是在最低标价基础上打90折，部分类目除外。其他活动无明确要求可填100折；)</Text>
                                </div>
                                <div style={{ marginTop: 20, paddingBottom: 40, display: 'flex', justifyContent: 'center' }}>
                                    {/* disabled={effective == 1 ? true : false} */}
                                    <Button disabled type="primary" onClick={() => {
                                        let flag = 1
                                        let errorMsg = ''
                                        if (flag) {
                                            pricingRangDict.map(item => {
                                                if (item.product.length == 0) {
                                                    flag = 0
                                                    errorMsg = '请填写合理的定价范围'
                                                }
                                                if (item.startYearSeason == '') {
                                                    flag = 0
                                                    errorMsg = '请填写合理的定价范围'
                                                }
                                                if (item.endYearSeason == '') {
                                                    flag = 0
                                                    errorMsg = '请填写合理的定价范围'
                                                }
                                            })
                                        }
                                        if (flag) {
                                            allowance.map(item => {
                                                if (item.sum != '' && item.reduce == '') {
                                                    flag = 0
                                                    errorMsg = '请填写合理的津贴'
                                                }
                                                if(parseInt(item.sum) < parseInt(item.reduce)){
                                                    flag = 0
                                                    errorMsg = '请填写合理的津贴'
                                                }
                                            })
                                        }
                                        if (flag) {
                                            coupon.map(item => {
                                                if (item.sum != '' && item.reduce == '') {
                                                    flag = 0
                                                    errorMsg = '请填写合理的平台购物券'
                                                }
                                                if(parseInt(item.sum) < parseInt(item.reduce)){
                                                    flag = 0
                                                    errorMsg = '请填写合理的平台购物券'
                                                }
                                            })
                                        }
                                        if (flag) {
                                            specialDiscount.map(item => {
                                                if(item.clothes == ''){
                                                    flag = 0
                                                    errorMsg = '请填写合理的专区折扣'
                                                }
                                                if (item.clothes != '' && (item.fold == '' || parseInt(item.fold) > 100 || parseInt(item.fold) == 0 || item.fold.toString().indexOf('.') != -1)) {
                                                    flag = 0
                                                    errorMsg = '请填写合理的专区折扣,折扣必须大于0而且小于等于100(如: 8折 -> 填80、75折 -> 填75)'
                                                }
                                            })
                                        }
                                        if (flag) {
                                            limitedTime.map(item => {
                                                if (item.forward != '') {
                                                    if (item.rear == '') {
                                                        flag = 0
                                                        errorMsg = '请填写合理的限时特惠'
                                                    }
                                                }
                                                if (item.forward != '' && item.rear != '' && item.clothes == '') {
                                                    flag = 0
                                                    errorMsg = '请填写合理的限时特惠'
                                                }
                                                if (item.forward != '' && item.rear != '' && item.fold == '') {
                                                    flag = 0
                                                    errorMsg = '请填写合理的限时特惠折扣,折扣必须大于0而且小于等于100(如: 8折 -> 填80、75折 -> 填75)'
                                                }
                                                if (item.forward != '' && item.rear != '' && item.fold != '' && (parseInt(item.fold) > 100 || parseInt(item.fold) == 0 || item.fold.toString().indexOf('.') != -1)) {
                                                    flag = 0
                                                    errorMsg = '请填写合理的限时特惠折扣,折扣必须大于0而且小于等于100(如: 8折 -> 填80、75折 -> 填75)'
                                                }
                                                if (item.forward != '' && item.rear != '' && item.fold != '' && limitedTimeDiscount == '') {
                                                    flag = 0
                                                    errorMsg = '请选择合理的限时特惠专区'
                                                }
                                            })
                                        }
                                        if (flag) {
                                            if (activityPriceCap == '' || parseInt(activityPriceCap) > 100 || parseInt(activityPriceCap) == 0 || activityPriceCap.toString().indexOf('.') != -1) {
                                                flag = 0
                                                errorMsg = '请填写合理的活动价上限,活动价上限必须大于0而且小于等于100(如: 8折 -> 填80、75折 -> 填75)'
                                            }
                                        }
                                        if (flag) {
                                            setEffective(1)
                                            let key = "selectionPlan"
                                            let obj = {
                                                // 定价范围
                                                'pricingRangDict': pricingRangDict,
                                                // 津贴
                                                'allowance': allowance,
                                                // 优惠券
                                                'coupon': coupon,
                                                // 店铺券
                                                'shopVoucher': shopVoucher,
                                                //专区折扣
                                                'specialDiscount': specialDiscount,
                                                //限时特惠
                                                'limitedTime': limitedTime,
                                                //限时特惠专区
                                                'limitedTimeDiscount': limitedTimeDiscount,
                                                //活动价上限
                                                'activityPriceCap': activityPriceCap
                                            }
                                            message.loading({ content: '正在创建利益点...', key, duration: 100 })
                                            let dataProps = {
                                                "roleId": cookie.load('role'),
                                                "shopList": cookie.load('shopList'),
                                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                                "option_json": obj,
                                                'name': selectionPlanName,
                                                "creator":cookie.load('name')
                                            }
                                            NbAxios(dataProps, "POST", servicePath.createActivityInterestPoint).then(res => {
                                                if (res.success) {
                                                    message.success({ content: '创建利益点成功...', key })
                                                    merchandiseValuation(obj)
                                                } else {
                                                    setEffective(0)
                                                    message.warn({ content: res.errorMsg, key })
                                                }
                                            })
                                        } else {
                                            message.warn(errorMsg)
                                        }

                                    }}>重新设置利益点</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
            {/* <div style={{display:'flex'}}>
                <Button onClick={()=>{
                    setCount(60)
                    setIsCount(true)
                }}>开始定时器</Button>
                <Button onClick={()=>{
                    clearTimeout(countTimeout)
                    setEffective(0)
                    setIsCount(false)
                }}>关闭定时器</Button>
            </div> */}
        </div>
    )

}


export default Index