import React, { useContext, useState, useEffect } from 'react'
import { Button, Table, Modal, Descriptions, Form, Input, Space, DatePicker, Row, Col, Typography, Select,Tooltip, message, Drawer, Upload, InputNumber , Tabs} from 'antd'
import { MinusCircleOutlined, PlusOutlined, InboxOutlined,CloudDownloadOutlined } from '@ant-design/icons';
import { StateContext } from '../../raceShop/store/stateShare'
import NbAxios from '../../../config/utils/nbAxios'
import axios from 'axios'
import servicePath from '../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import cookie from 'react-cookies'
const { TabPane } = Tabs;
const { Text } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

// 分销店数据
function Index(params) {
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const layout1 = {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
    };
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    // 获取屏幕尺寸
    const size = state.size
    // 标签key 
    const [ tabKey , setTabKey ] = useState('0')
    // 页数
    const [ page , setPage ] = useState(1)
    // 总数
    const [ total, setTotal] = useState(0)
    // 店铺Id
    const [ shopId , setShopId ] = useState('')
    // 店铺名
    const [ shopName , setShopName ] = useState('')
    // 相关人数据
    const [ relevantPeople , setRelevantPeople ] = useState({})
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    // 一行数据
    const [ recordData , setRecordData ] = useState([])
    // 数据
    const [data, setData] = useState([])
    // 店铺名字
    const [ name , setName ] = useState('')
    // 旺旺id
    const [ wantId , setWantId ] = useState('')
    // 等级
    const [ rank , setRank ] = useState('')
    // 店铺类型
    const [ shopType , setShopType ] = useState('')
    // 采购公司
    const [ purchasingCompany , setPurchasingCompany] = useState('')
    // 客户类型
    const [ customerType , setCustomerType] = useState('')
    // freiol采货时间
    const [freiolPurchaseTime, setFreiolPurchaseTime] = useState([])
    // freiol采货金额
    const [freiolPurchaseAmountNumber, setFreiolPurchaseAmountNumber] = useState([])
    // acm采货时间
    const [acmPurchaseTime, setAcmPurchaseTime] = useState([])
    // acm采货金额
    const [acmPurchaseAmountNumber, setAcmPurchaseAmountNumber] = useState([])
    // 可分配人集合
    const [ assignablePerson , setAssignablePerson] = useState([])
    // 相关人
    const [lookRelevant, setLookRelevant] = useState(false)
    // 采货金额
    const [lookPurchaseAmount, setLookPurchaseAmount] = useState(false)
    // 修改采货金额
    const [updatePurchaseAmount, setUpdatePurchaseAmount] = useState(false)
    // 新增店铺信息
    const [addShop, setAddShop] = useState(false)
    // 修改店铺信息
    const [updateShop, setUpdateShop] = useState(false)
    // 打开搜索
    const [onSearch, setOnSearch] = useState(false)
    // 修改分配人
    const [ updateAssignPersonnel , setUpdateAssignPersonnel] = useState(false)
    // 是否上传文件
    const [ isUpload , setIsUpload ] = useState(false)
    // 按钮是否有效
    const [effective, setEffective] = useState(false)
    // 上传采货金额文件
    const [uploadFile, setUploadFile] = useState([])
    // 表格标题
    const columns = [
        {
            title: '店铺名',
            align: 'center',
            dataIndex: 'name',
            width: 200,
            fixed: 'left',
            render: (text, record, index) => {
                return (
                    record.url != null ?
                    <a style={{ textDecoration: 'underline' }} href={data[index]['url']} target="_blank">{text}</a>
                    : <Text type="secondary">{text}</Text>
                )
            }
        },
        {
            title: '渠道',
            align: 'center',
            dataIndex: 'channel',
            fixed: 'left',
            width: 80
        },
        {
            title: '旺旺ID',
            align: 'center',
            dataIndex: 'want_id',
            fixed: 'left',
            width: 200
        },
        {
            title: '等级',
            align: 'center',
            dataIndex: 'rank',
            width: 100
        },
        {
            title: '店铺类型',
            align: 'center',
            dataIndex: 'shop_type',
            width: 100
        },
        {
            title: '店铺客单',
            align: 'center',
            dataIndex: 'shop_customer',
            width: 150
        },
        {
            title: '上架品类',
            align: 'center',
            dataIndex: 'list_categories',
            width: 200
        },
        {
            title: '月销最高',
            align: 'center',
            dataIndex: 'monthly_sales',
            width: 100
        },
        {
            title: '链接数量',
            align: 'center',
            dataIndex: 'url_number',
            width: 100
        },
        {
            title: '店铺地址',
            align: 'center',
            dataIndex: 'shop_address',
            width: 200,
            render: (text, record, index) => {
                return (
                    text != null && text != ''? 
                    <Tooltip title={text}>
                        <a style={{ textDecoration: 'underline' }} >查看店铺地址</a>
                    </Tooltip>
                    : <span></span>
                )
            }
        },
        {
            title: '客户类型',
            align: 'center',
            dataIndex: 'customer_type',
            width: 110
        },
        {
            title: '采购公司',
            align: 'center',
            dataIndex: 'purchasing_company',
            width: 100
        },
        {
            title: '分配人员',
            align: 'center',
            dataIndex: 'assign_personnel',
            width: 200
        },
        {
            title: '查看相关人',
            align: 'center',
            dataIndex: 'id',
            width: 200,
            render: (text, record, index) => {
                return (
                    <a style={{ textDecoration: 'underline' }} onClick={() => {
                        let dataProps={
                            "roleId": cookie.load('role'),
                            "shopId": cookie.load('shopList'),
                            "shop_id":record.id,
                            "name":record.name,
                            "principal_name":cookie.load('name')
                        }
                        NbAxios(dataProps, "POST", servicePath.queryDistributionShopRelevantPeople).then(res => {
                            if (res.success) {
                                setRelevantPeople(res.data);
                                setLookRelevant(true)
                            } else {
                                message.warn(res.errorMsg)
                            }
                        })
                    }}>查看相关人</a>
                )
            }
        },
        {
            title: '查看采货金额',
            align: 'center',
            dataIndex: 'id',
            width: 200,
            render: (text, record, index) => {
                return (
                    <a style={{ textDecoration: 'underline' }} onClick={() => {
                        let dataProps={
                            "roleId": cookie.load('role'),
                            "shopId": cookie.load('shopList'),
                            "shop_id":record.id,
                            "name": record.name,
                            "principal_name":cookie.load('name')
                        }
                        NbAxios(dataProps, "POST", servicePath.queryPurchaseAmount).then(res => {
                            if(res.success){
                                setTabKey('0')
                                if(res.data.freiol!=undefined){
                                    setFreiolPurchaseTime(res.data.freiol.month)
                                    setFreiolPurchaseAmountNumber(res.data.freiol.amount)
                                    setTabKey('1')
                                }
                                if(res.data.acm != undefined){
                                    setAcmPurchaseAmountNumber(res.data.acm.amount)
                                    setAcmPurchaseTime(res.data.acm.month)
                                    setTabKey('2')
                                }
                                setShopName(record.name)
                                setShopId(record.id)
                                
                                setLookPurchaseAmount(true)
                            }else{
                                message.error(res.errorMsg)
                            }
                        })
                    }}>查看采货金额</a>
                )
            }
        },
        {
            title: '修改信息',
            align: 'center',
            dataIndex: 'id',
            width: 200,
            render: (text, record, index) => {
                return (
                    <a style={{ textDecoration: 'underline' }} onClick={() => {
                        let dataProps={
                            "roleId": cookie.load('role'),
                            "shopId": cookie.load('shopList'),
                            "shop_id":record.id,
                            "name":record.name,
                            "principal_name":cookie.load('name')
                        }
                        NbAxios(dataProps, "POST", servicePath.queryDistributionShopRelevantPeople).then(res => {
                            if (res.success) {
                                record.receiver_name = res.data.receiver_name
                                record.receiver_phone = res.data.receiver_phone
                                record.receiver_address = res.data.receiver_address
                                record.receiver_wechat = res.data.receiver_wechat
                                record.receiver_position = res.data.receiver_position
                            }
                            // else{
                            //     record.receiver_name = ""
                            //     record.receiver_phone = ""
                            //     record.receiver_address = ""
                            //     record.receiver_wechat = ""
                            //     record.receiver_position = ""
                            // }
                            setShopId(record.id);
                            form.resetFields()
                            // 设置表单的值
                            form.setFieldsValue(record)
                            setUpdateShop(true)
                        })
                        
                    }}>修改信息</a>
                )
            }
        },
        {
            title: '修改分配人',
            align: 'center',
            dataIndex: 'id',
            width: 200,
            fixed:jurisdiction.indexOf("distribution:: updateAssignPersonnel") != -1 ? 'right' : 'none',
            colSpan: jurisdiction.indexOf("distribution:: updateAssignPersonnel") != -1 ? 1 : 0,
            render: (text, record, index) => {
                return (
                    <a style={{ textDecoration: 'underline', display: jurisdiction.indexOf("distribution:: updateAssignPersonnel") != -1 ? "" : "none"  }}
                     onClick={() => {
                      setUpdateAssignPersonnel(true)
                      // 设置表单的值
                      setRecordData(record)
                      form.setFieldsValue(record)
                    }}>修改分配人</a>
                )
            }
        }
    ]

    // 表格
    let report = {
        title: {
            text: `freiol采购金额表`,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: ['采货金额']
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: freiolPurchaseTime
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '金额/元',
                position: 'left',
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    show: true,
                    onZero: true
                },
            }
        ],
        series: [
            {
                name: '金额',
                type: 'line',
                data: freiolPurchaseAmountNumber
            }
        ]
    }

    // acm数据表
    let acmReport = {
        title: {
            text: `acm采购金额表`,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: ['采货金额']
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: acmPurchaseTime
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '金额/元',
                position: 'left',
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    show: true,
                    onZero: true
                },
            }
        ],
        series: [
            {
                name: '金额',
                type: 'line',
                data: acmPurchaseAmountNumber
            }
        ]
    }


    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    // 监控采货金额是否改变
    useEffect(() => {
        if (document.getElementById('report') != null) {
            // 基于准备好的dom，初始化echarts实例
            let reportChart = echarts.init(document.getElementById('report'));
            // 绘制图表
            reportChart.setOption(report);
            window.addEventListener('resize', () => {
                reportChart.resize()
            });
        } else {
            if (lookPurchaseAmount == true) {
                 setTimeout(()=>{
                    // 基于准备好的dom，初始化echarts实例
                    let reportChart = echarts.init(document.getElementById('report'));
                    // 绘制图表
                    reportChart.setOption(report);
                    window.addEventListener('resize', () => {
                        reportChart.resize()
                    });
                 },100)
            }
        }
    }, [freiolPurchaseAmountNumber, lookPurchaseAmount])

    // 监控采货金额是否改变
    useEffect(() => {
        if (document.getElementById('acmReport') != null) {
            // 基于准备好的dom，初始化echarts实例
            let reportChart = echarts.init(document.getElementById('acmReport'));
            // 绘制图表
            reportChart.setOption(acmReport);
            window.addEventListener('resize', () => {
                reportChart.resize()
            });
        } else {
            if (lookPurchaseAmount == true) {
                 setTimeout(()=>{
                    // 基于准备好的dom，初始化echarts实例
                    let reportChart = echarts.init(document.getElementById('acmReport'));
                    // 绘制图表
                    reportChart.setOption(acmReport);
                    window.addEventListener('resize', () => {
                        reportChart.resize()
                    });
                 },100)
            }
        }
    }, [acmPurchaseAmountNumber, lookPurchaseAmount])

    useEffect(()=>{
        getDistributionShopData()
    },[page])

    useEffect(()=>{
        getAssignPersonnel()
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    },[])

    // 查询分销店铺数据
    const getDistributionShopData = ()=>{
        let dataProps={
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "principal_name":cookie.load('name'),
            "name": name,
            "want_id": wantId,
            "rank": rank,
            "shop_type":shopType,
            "purchasing_company": purchasingCompany,
            "customer_type": customerType,
            "page": page,
            "page_size": 10
        }
        NbAxios(dataProps, "POST", servicePath.queryDistributionShopData).then(res => {
            if (res.success) {
                setTotal(res.total);
                setData(res.data);
                setOnSearch(false)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 获取可分配人员
    const getAssignPersonnel = ()=>{
        let dataProps={
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryAssignPersonnel).then(res => {
            if(res.success){
                setAssignablePerson(res.data);
            }else{
                message.error(res.errorMsg)
            }
        })
    }

    // 获取采货金额
    const getPurchaseAmount = ()=>{
        let dataProps={
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "shop_id":shopId,
            "name": shopName,
            "principal_name":cookie.load('name')
        }
        NbAxios(dataProps, "POST", servicePath.queryPurchaseAmount).then(res => {
            if(res.success){
                if(res.data.freiol!=undefined){
                    setFreiolPurchaseTime(res.data.freiol.month)
                    setFreiolPurchaseAmountNumber(res.data.freiol.amount)
                }
                if(res.data.acm != undefined){
                    setAcmPurchaseAmountNumber(res.data.acm.amount)
                    setAcmPurchaseTime(res.data.acm.month)
                }
                setUpdatePurchaseAmount(false)
            }else{
                message.error(res.errorMsg)
            }
        })
    }

    // 重置搜索
    const restart = ()=>{
        let dataProps={
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "principal_name":cookie.load('name'),
            "name": '',
            "want_id": '',
            "rank": '',
            "shop_type":'',
            "purchasing_company": '',
            "customer_type": '',
            "page": 1,
            "page_size": 10
        }
        NbAxios(dataProps, "POST", servicePath.queryDistributionShopData).then(res => {
            if (res.success) {
                form.resetFields()
                setPage(1)
                setName('')
                setWantId('')
                setRank('')
                setShopType('')
                setPurchasingCompany('')
                setCustomerType('')
                setTotal(res.total);
                setData(res.data);
                setOnSearch(false)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 修改采货金额
    const onFinish = values => {
        let purchaseAmountList = []
       
        values['purchaseAmount'].map(item => {
            let obj = {
                'month': item['month'].format('YYYY-MM'),
                'brand': item['brand'],
                'amount': item['amount']
            }
            purchaseAmountList.push(obj)
        })
        let dataProps={
            "data":purchaseAmountList,
            "id":shopId,
            "principal_name":cookie.load('name'),
            "shop_name":shopName,
            'shopId':cookie.load('shopList'),
            'roleId':cookie.load('role'),
        }
        NbAxios(dataProps, "POST", servicePath.updatePurchaseAmount).then(res=>{
            if(res.success){
                message.success("数据更改成功")
                form1.resetFields()
                getPurchaseAmount()
            }else{
                message.warn(res.errorMsg)
            }
        })
        // form1.resetFields()
        // setPurchaseTime(['2020-08', '2020-09', '2020-10', '2020-11', '2020-12', '2021-01', '2021-02', '2021-03', '2021-04', '2021-05', '2021-06', '2021-07', '2021-08', '2021-09', '2021-10', '2021-11', '2021-12'])
        // setPurchaseAmountNumber(['4780','4050','18540','18540','3090','7830','3090','7830','3090','7830','3090','7830','3090','7830','10000','3090','7830'])
        // setUpdatePurchaseAmount(false)
    };

    // 新增店铺
    const addShopOk = values => {
        values.shopId = cookie.load('shopList')
        values.roleId = cookie.load('role')
        NbAxios(values, "POST", servicePath.createDistributionShopData).then(res => {
            if(res.success){
                message.success('新增店铺成功')
                form.resetFields()
                getDistributionShopData()
                setAddShop(false)
            }else{
                message.warn(res.errorMsg)
            }
        })
       
    }

    // 修改店铺
    const updateShopOk = values => {
        values.shopId = cookie.load('shopList')
        values.roleId = cookie.load('role')
        values.id = shopId
        NbAxios(values, "POST", servicePath.updateDistributionShopData).then(res => {
            if(res.success){
                message.success('更新店铺成功')
                form.resetFields()
                setShopId('')
                getDistributionShopData()
                setUpdateShop(false)
            }else{
                message.warn(res.errorMsg)
            }
        })
    }

    // 修改分配人
    const updateAssignPersonnelOk = values =>{
        values.shopId = cookie.load('shopList')
        values.roleId = cookie.load('role')
        values.name = recordData['name']
        values.shop_id = recordData['id']
        values.purchasing_company = recordData['purchasing_company']
        values.principal_name = cookie.load('name')
        values.customer_type = recordData['customer_type']
        NbAxios(values, "POST", servicePath.updateAssignPersonnel).then(res => {
            if(res.success){
                message.success('成功修改分配人')
                form.resetFields()
                setRecordData([])
                getDistributionShopData()
                setUpdateAssignPersonnel(false)
            }else{
                message.warn(res.errorMsg)
            }
        })
       
    }

    // 上传采购金额
    const uploadPurchaseAmount = ()=>{
        if(uploadFile.length == 0){
            message.warn('请选择您要上传的文件')
        }else{
            setEffective(true)
            // 上传原始文件例子
            const formData = new FormData()
            // 多个图片
            uploadFile.forEach((item) => {
                formData.append('file ', item['originFileObj']);
            })
            formData.append('shopId', cookie.load('shopList'))
            formData.append('roleId', cookie.load('role'))
            formData.append('principal_name', cookie.load('name'))
            NbAxios(formData, "POST", servicePath.uploadPurchaseAmount).then(res => {
                if(res.success){
                    setUploadFile([])
                    form.resetFields()
                    message.success('数据上传成功')
                    setIsUpload(false)
                }else{
                    message.warn(res.errorMsg)
                }
            })
        }
        
    }

    // 简历上传
  const fileHandleChange = ({ fileList }) => {
    if (fileList.length != 0) {
      // 获取最新文件的文件名
      let fileName = fileList[fileList.length - 1].originFileObj['name']
      // 提取文件名的后缀名
      let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
      // 定义允许的类型
      let fileTypes = ['xlsx']
      // 首先判断文件上传的类型
      if (fileTypes.indexOf(fileExtension) != -1) {
        let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
        // 在判断文件的大小
        if (flag) {
          // 更新文件(限制只能上传一个文件)
          let newFileList = []
          newFileList.push(fileList[fileList.length - 1])
          setUploadFile(newFileList)
        } else {
          message.error('文件大小必须小于50M')
          // 移除文件
          fileList.pop()
        }
      } else {
        message.error('文件类型不符合')
        // 移除文件
        fileList.pop()
      }
    } else {
      setUploadFile(fileList)
    }
  }

    return (
        <div>
            <div style={{ marginLeft: '50px', marginTop: "30px" }}>
                <Button type="primary" onClick={() => setAddShop(true)}>新增店铺信息</Button>
                <Button type="primary" onClick={() => setOnSearch(true)} style={{marginLeft:"20px"}}>查询</Button>
                <Button type="primary" onClick={()=>setIsUpload(true)} style={{marginLeft:"20px"}}>导入采货金额</Button>
                <a href="https://pic.bmcsoft.cn/imge_dir/福来采货金额导入表.xlsx" style={{ marginLeft: '20px' }}><Button icon={<CloudDownloadOutlined />} >下载采货金额模版</Button></a>
            </div>
            <div style={{ marginLeft: '50px', marginTop: '30px', marginRight: '50px' }}>
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={data}
                    pagination={paginationProps}
                    scroll={{ x: '70vw', y: `${size.width > 1500 ? '75vh' : '69vh'}` }}
                />
            </div>
            <Drawer
                title="查询条件"
                placement="right"
                closable={false}
                onClose={() => setOnSearch(false)}
                visible={onSearch}
                width={1100}
            >
                <Form
                    {...layout1}
                    form={form}
                    labelAlign="left"
                >
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                label="店铺名"
                                name="name"
                            >
                                <Input value={name} placeholder="请填写店铺名" onChange={(e) => setName(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="旺旺ID"
                                name="wantId"
                            >
                                <Input value={wantId} placeholder="请填写旺旺ID" onChange={(e) => setWantId(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="等级"
                                name="rank"
                            >
                                <Input value={rank} placeholder="请填写等级" onChange={(e) => setRank(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺类型"
                                name="shopType"
                            >
                                <Input value={shopType} placeholder="请填写店铺类型" onChange={(e) => setShopType(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="采购公司"
                                name="purchasingCompany"
                            >
                                <Select value={purchasingCompany}  placeholder="请填写采购公司" onChange={(value)=>setPurchasingCompany(value)}>
                                    <Option value="霞堡">霞堡</Option>
                                    <Option value="优界">优界</Option>
                                </Select>
                            </Form.Item>
                        </Col> 
                        <Col span={11}>
                            <Form.Item
                                label="客户类型"
                                name="customerType"
                            >
                                <Select value={customerType}  placeholder="请填写客户类型" onChange={(value)=>setCustomerType(value)}>
                                    <Option value="自开发-霞堡">自开发-霞堡</Option>
                                    <Option value="自开发-优界">自开发-优界</Option>
                                    <Option value="展会-霞堡">展会-霞堡</Option>
                                    <Option value="展会-优界">展会-优界</Option>
                                    <Option value="芽肌">芽肌</Option>
                                    <Option value="霞堡">霞堡</Option>
                                    <Option value="优界">优界</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button type="primary" style={{marginLeft:'10px', marginRight:'10px'}} onClick={()=>{
                                if(page != 1){
                                    setPage(1)
                                }else{
                                    getDistributionShopData()
                                }
                            }}>查询</Button>
                            <Button type="primary" danger onClick={()=>restart()}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            {/* 查看相关人 */}
            <Modal
                title="相关人"
                visible={lookRelevant}
                centered={true}
                footer={[]}
                onCancel={() => setLookRelevant(false)}
                width={1000}
            >
                {
                    relevantPeople != {}
                    ? <Descriptions bordered style={{ marginBottom: "10px" }}>
                        <Descriptions.Item label="店铺名">{relevantPeople.shop_name}</Descriptions.Item>
                        <Descriptions.Item label="收货姓名">{relevantPeople.receiver_name == null ? '' : relevantPeople.receiver_name}</Descriptions.Item>
                        <Descriptions.Item label="收货电话">{relevantPeople.receiver_phone == null ? '' : relevantPeople.receiver_phone}</Descriptions.Item>
                        <Descriptions.Item label="收货地址" span={3}>{relevantPeople.receiver_address == null ? '' : relevantPeople.receiver_address}</Descriptions.Item>
                        <Descriptions.Item label="微信">{relevantPeople.receiver_wechat == null ? '' : relevantPeople.receiver_wechat}</Descriptions.Item>
                        <Descriptions.Item label="职位">{relevantPeople.receiver_position == null ? '' : relevantPeople.receiver_position}</Descriptions.Item>
                    </Descriptions>
                    : <span></span>
                }
            </Modal>

            {/* 查看采货金额 */}
            <Modal
                title={"查看 " + shopName + " 采货金额"}
                visible={lookPurchaseAmount}
                centered={true}
                footer={[]}
                onCancel={() => {
                    setShopName('')
                    setShopId('')
                    setLookPurchaseAmount(false)
                    setTabKey('0')
                    setAcmPurchaseTime([])
                    setAcmPurchaseAmountNumber([])
                    setFreiolPurchaseTime([])
                    setFreiolPurchaseAmountNumber([])
                }}
                width={1000}
            >
                <Button onClick={() => {
                    setUpdatePurchaseAmount(true)
                }}>修改数据</Button>
                <div>
                    <Tabs activeKey={tabKey} onChange={(key)=>{setTabKey(key)}}>
                        <TabPane tab="freiol采购金额表" key={'1'} disabled={freiolPurchaseAmountNumber.length == 0 ? true: false}>
                        </TabPane>
                        <TabPane tab="acm采购金额表" key={'2'} disabled={acmPurchaseAmountNumber.length == 0 ? true: false}>
                        </TabPane>
                    </Tabs>
                    <div id="report" style={{ width: 1000, height: 415, marginTop: 10 , display:tabKey == 1 ? '': 'none'}}></div>
                    <div id="acmReport" style={{ width: 1000, height: 415, marginTop: 10 , display:tabKey == 2 ? '': 'none'}}></div>
                    <div style={{ width: 1000, height: 415, marginTop: 10 , display:tabKey == 0 ? '': 'none'}}>
                        <Text strong>暂无采购金额数据</Text>
                    </div>
                </div>
            </Modal>

            {/* 修改采货金额 */}
            <Modal
                title={"修改 " + shopName + " 采货金额"}
                visible={updatePurchaseAmount}
                centered={true}
                footer={[]}
                onCancel={() => {
                    form1.resetFields()
                    setUpdatePurchaseAmount(false)
                }}
            >
                <Form form={form1} name="purchase_amount_item" onFinish={onFinish} autoComplete="off">
                    <Form.List name="purchaseAmount">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(field => (
                                    <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'month']}
                                            fieldKey={[field.fieldKey, 'month']}
                                            rules={[{ required: true, message: '请输入采货月期' }]}
                                        >
                                            <DatePicker picker="month" locale={locale} />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'brand']}
                                            fieldKey={[field.fieldKey, 'brand']}
                                            rules={[{ required: true, message: '请选择品牌' }]}
                                        >
                                            <Select style={{width:100}}>
                                                <Option value="freiol">Freiol</Option>
                                                <Option value="acm">ACM</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'amount']}
                                            fieldKey={[field.fieldKey, 'amount']}
                                            rules={[{ required: true, message: '请输入采货金额' }]}
                                        >
                                            <InputNumber 
                                                formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                placeholder="采货金额"
                                                style={{width:200}}
                                            />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add field
                                    </Button>
                                </Form.Item>

                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 新增店铺信息 */}
            <Modal
                title="新增店铺"
                visible={addShop}
                centered={true}
                footer={[]}
                onCancel={() => {
                    form.resetFields()
                    setAddShop(false)
                }}
                width={1000}
            >
                <Form
                    form={form}
                    {...layout}
                    name="shop_item"
                    onFinish={addShopOk}
                    autoComplete="off"
                    initialValues={{
                        'assign_personnel': cookie.load('name'),
                        // 'customer_type': '',
                        // 'list_categories': '',
                        // 'monthly_sales': '',
                        // // 'performance': "",
                        // 'purchasing_company': '',
                        // 'rank': '',
                        // 'receiver_address': '',
                        // 'receiver_name': '',
                        // 'receiver_phone': '',
                        // 'receiver_position': '',
                        // 'receiver_wechat': '',
                        // 'shop_address': '',
                        // 'shop_customer': '',
                        // 'shop_type': '',
                        // 'url': '',
                        // 'url_number': '',
                        // 'want_id': ''
                    }}
                >
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                label="店铺名"
                                name={'name'}
                                rules={[{ required: true, message: '请输入店铺名' }]}
                            >
                                <Input placeholder="请输入店铺名称" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="渠道"
                                name={'channel'}
                                rules={[{ required: true, message: '请输入渠道' }]}
                            >
                                <Input placeholder="请输入渠道" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="旺旺ID"
                                name={'want_id'}
                            >
                                <Input placeholder="请输入旺旺ID" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺链接"
                                name={'url'}
                            >
                                <Input placeholder="请输入店铺链接" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺等级"
                                name={'rank'}
                            >
                                <Input placeholder="请输入店铺等级" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺类型"
                                name={'shop_type'}
                            >
                                <Input placeholder="请输入店铺类型" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺客单"
                                name={'shop_customer'}
                            >
                                <Input placeholder="请输入店铺客单" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="上架品类"
                                name={'list_categories'}
                            >
                                <Input placeholder="请输入上架品类" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="月销最高"
                                name={'monthly_sales'}
                            >
                                <Input placeholder="请输入月销最高" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="链接数量"
                                name={'url_number'}
                            >
                                <Input placeholder="请输入链接数量" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺地址"
                                name={'shop_address'}
                            >
                                <Input placeholder="请输入店铺地址" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="收货人名字"
                                name={'receiver_name'}
                            >
                                <Input placeholder="请输入收货人名字" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="收货人电话"
                                name={'receiver_phone'}
                            >
                                <Input placeholder="请输入收货人电话" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="收货人地址"
                                name={'receiver_address'}
                            >
                                <Input placeholder="请输入收货人地址" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="收货人微信"
                                name={'receiver_wechat'}
                            >
                                <Input placeholder="请输入收货人微信" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="收货人职位"
                                name={'receiver_position'}
                            >
                                <Input placeholder="请输入收货人职位" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="客户类型"
                                name={'customer_type'}
                                rules={[{ required: true, message: '请输入客户类型' }]}
                            >
                                 <Select>
                                    <Option value="自开发-霞堡">自开发-霞堡</Option>
                                    <Option value="自开发-优界">自开发-优界</Option>
                                    <Option value="展会-霞堡">展会-霞堡</Option>
                                    <Option value="展会-优界">展会-优界</Option>
                                    <Option value="霞堡">霞堡</Option>
                                    <Option value="优界">优界</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="采购公司"
                                name={'purchasing_company'}
                            >
                                <Select>
                                    <Option value="霞堡">霞堡</Option>
                                    <Option value="优界">优界</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="分配人员"
                                name={'assign_personnel'}
                            >
                                {/* <Input placeholder="请输入分配人员" /> */}
                                <Text>{cookie.load('name')}</Text>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: 800 }}>
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 修改店铺信息 */}
            <Modal
                title="修改店铺信息"
                visible={updateShop}
                centered={true}
                footer={[]}
                onCancel={() => {
                    form.resetFields()
                    setUpdateShop(false)
                }}
                width={1000}
            >
                <Form
                    form={form}
                    {...layout}
                    name="shop_item"
                    onFinish={updateShopOk}
                    autoComplete="off"
                >
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                label="店铺名"
                                name={'name'}
                                rules={[{ required: true, message: '请输入店铺名' }]}
                            >
                                {/* <Input placeholder="请输入店铺名称" /> */}
                                <Text>{form.getFieldValue('name')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="渠道"
                                name={'channel'}
                                rules={[{ required: true, message: '请输入渠道' }]}
                            >
                                <Text>{form.getFieldValue('channel')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="旺旺ID"
                                name={'want_id'}
                            >
                                <Input placeholder="请输入旺旺ID" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺链接"
                                name={'url'}
                            >
                                <Input placeholder="请输入店铺链接" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺等级"
                                name={'rank'}
                            >
                                <Input placeholder="请输入店铺等级" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺类型"
                                name={'shop_type'}
                            >
                                <Input placeholder="请输入店铺类型" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺客单"
                                name={'shop_customer'}
                            >
                                <Input placeholder="请输入店铺客单" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="上架品类"
                                name={'list_categories'}
                            >
                                <Input placeholder="请输入上架品类" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="月销最高"
                                name={'monthly_sales'}
                            >
                                <Input placeholder="请输入月销最高" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="链接数量"
                                name={'url_number'}
                            >
                                <Input placeholder="请输入链接数量" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="店铺地址"
                                name={'shop_address'}
                            >
                                <Input placeholder="请输入店铺地址" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="收货人名字"
                                name={'receiver_name'}
                            >
                                <Input placeholder="请输入收货人名字" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="收货人电话"
                                name={'receiver_phone'}
                            >
                                <Input placeholder="请输入收货人电话" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="收货人地址"
                                name={'receiver_address'}
                            >
                                <Input placeholder="请输入收货人地址" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="收货人微信"
                                name={'receiver_wechat'}
                            >
                                <Input placeholder="请输入收货人微信" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="收货人职位"
                                name={'receiver_position'}
                            >
                                <Input placeholder="请输入收货人职位" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="客户类型"
                                name={'customer_type'}
                            >
                                <Text>{form.getFieldValue('customer_type')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="采购公司"
                                name={'purchasing_company'}
                            >
                                <Select defaultValue={form.getFieldValue('purchasing_company')}>
                                    <Option value="霞堡">霞堡</Option>
                                    <Option value="优界">优界</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="分配人员"
                                name={'assign_personnel'}
                            >
                                <Text>{cookie.load('name')}</Text>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: 800 }}>
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 修改分配人 */}
            <Modal
                title="修改分配人"
                visible={updateAssignPersonnel}
                centered={true}
                footer={[]}
                onCancel={() => {
                    setRecordData([])
                    form.resetFields()
                    setUpdateAssignPersonnel(false)
                }}
            >
                <Form
                    form={form}
                    {...layout}
                    name="shop_item"
                    onFinish={updateAssignPersonnelOk}
                >
                    <Form.Item
                        label="名字"
                        name={'assign_personnel'}
                        rules={[{ required: true, message: '请输入名字' }]}
                    >
                        <Select>
                           {
                               assignablePerson.map(item=>{
                                    return <Option value={item}>{item}</Option>
                               })
                           }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: 400 }}>
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="上传采货金额"
                visible={isUpload}
                centered={true}
                okText="确定"
                cancelText="取消"
                onOk={uploadPurchaseAmount}
                onCancel={() => {
                    setUploadFile([])
                    form.resetFields()
                    setIsUpload(false)
                }}
            >
                 <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={uploadFile}
                    onChange={fileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
            </Modal>
        </div>
    )

}


export default Index