import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Tag, Form, Select, Space } from 'antd'
import { EditOutlined, DeleteOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import servicePath from '../../config/apiUrl'
import Highlighter from 'react-highlight-words';
import axios from 'axios'
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios'
import '../../static/css/Table.css'
import '../../static/css/index.css'
const { confirm } = Modal
const { Option } = Select

function Menu(props) {

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    //页面
    const [page, setPage] = useState(1)

    //菜单数据
    const [data, setData] = useState([])

    //备份
    const [backups, setBackups] = useState([])

    //父菜单数据
    const [partData] = useState([{ "id": 0, "name": "--" }])

    // 角色集合
    const [roleList, setRoleList] = useState([])

    // 选择角色
    const [chooseRoleList, setChooseRoleList] = useState([])

    //板块数据
    const [shops, setShops] = useState([])

    //选中菜单现有的板块
    const [shopList, setShopList] = useState([])

    //选中的板块
    const [chooseShops, setChooseShops] = useState([])

    //菜单ID
    const [id, setId] = useState(-1)

    //父菜单ID
    const [pId, setPId] = useState(-1)

    //菜单名字
    const [name, setName] = useState('')

    //父菜单名字
    const [pName, setPName] = useState('')

    //路径
    const [path, setPath] = useState('')

    //所选菜单的所有模版
    const [chooseMenuOfShop, setChooseMenuOfShop] = useState([])

    //是否添加菜单
    const [addMenu, setAddMenu] = useState(false)

    // 是否修改菜单
    const [updateMenu, setUpdateMenu] = useState(false)

    //查看菜单所在板块
    const [watchShop, setWatchShop] = useState(false)

    // 配置人员信息
    const [openUpdateRole, setOpenUpdateRole] = useState(false)

    // 筛选字段
    const [searchText, setSearchText] = useState('')

    // 筛选关键词
    const [searchedColumn, setSearchedColumn] = useState('')

    // 筛选信息
    const [searchMessage, setSearchMessage] = useState('暂无筛选信息')

    //第一次筛选的关键字
    const [column1, setColumn1] = useState('')

    //第一次筛选的值
    const [text1, setText1] = useState('')

    //筛选次数
    const [count, setCount] = useState(0)

    // 筛选功能
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        // searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                // setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    // 根据筛选信息更新数据
    useEffect(() => {

        if (searchedColumn == "name") {
            if (count == 1) {
                let newData = backups.filter(item => item.name.toString().toLowerCase().includes(searchText.toLowerCase()))
                setData(newData)
            }
        } else {
            setData(backups)
            let newMessage = '暂无筛选信息'
            setSearchMessage(newMessage)
        }
    }, [searchText, searchedColumn])

    // 筛选选择
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        // 将次数保存到新的值
        let newCount = count
        if (newCount == 0) {
            //第一次筛选，之前没有关键字查询，直接次数加一
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
            //保存第一次筛选的关键字和值
            setColumn1(dataIndex)
            setText1(selectedKeys[0])
            newCount = newCount + 1;
            let newMessage = "筛选条件:在" + "\xa0\xa0" + "菜单名称" + "\xa0\xa0" + "筛选" + "\xa0\xa0" + selectedKeys[0]
            setSearchMessage(newMessage)
        } else {
            //有一个关键字查询，判断是否是同一个字段查询
            if (column1 == dataIndex) {
                //如果是都一个字段，次数不进行改变
                setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                setColumn1(dataIndex)
                setText1(selectedKeys[0])
                let newMessage = "筛选条件:在" + "\xa0\xa0" + "菜单名称" + "\xa0\xa0" + "筛选" + "\xa0\xa0" + selectedKeys[0]
                setSearchMessage(newMessage)
            }
        }
        //保存次数
        setCount(newCount)
    };

    // 筛选清空
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
        setSearchedColumn('')
        // 次数减一
        let newCount = count - 1;
        setCount(newCount)
    };

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    const columns = [
        {
            title: '菜单名字',
            key: 'name',
            dataIndex: 'name',
            width: '200px',
            align: 'center',
            ...getColumnSearchProps('name'),
        },
        {
            title: '父菜单名字',
            key: 'pName',
            dataIndex: 'pName',
            width: '200px',
            align: 'center'
        },
        {
            title: '菜单路径',
            key: 'path',
            dataIndex: 'path',
            width: '200px',
            align: 'center'
        },
        {
            title: '菜单所在板块',
            key: 'shops',
            dataIndex: 'shops',
            width: '400px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Button size="small" type="dashed" onClick={() => {
                        openWatchShop(index)
                    }}>查看菜单所在板块</Button>
                )
            }
        },
        {
            title: '状态',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == 1 ? <Tag color="red">禁用</Tag>
                    : <Tag color="blue">正常</Tag>
            ),
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={() => openUpdate(index)} />
            ),
        },
        {
            title: '删除/恢复',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == 1 ? <RedoOutlined onClick={() => recoverMenu(index)} />
                    : <DeleteOutlined onClick={() => deleteMenu(index)} />
            ),
        },
        {
            title: '配置角色',
            key: 'modification',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={() => {
                    setId(data[index]['id']);
                    let shopsObj = data[index]['shops']
                    let shopList = []
                    shopsObj.map(item=>{
                        shopList.push(item.id)
                    })
                    setShopList(shopList);
                    setOpenUpdateRole(true)
                }} />
            ),
        }
    ]

    // 获取所有角色
    useEffect(() => {
        queryAllMenu()
        queryPartMenu()
        queryAllShop()
        getAllRole()
    }, [])

    //查询全部菜单
    const queryAllMenu = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shop_Id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryMenu,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    if (count == 0) {
                        // 没有筛选的时候，数据就不进行处理
                        setData(res.data['data']);
                        setBackups(res.data['data'])
                    } else {
                        // 有筛选的时候，数据进行过滤
                        let newData = res.data['data'].filter(item => item.name.toString().toLowerCase().includes(searchText.toLowerCase()))
                        setData(newData)
                        setBackups(res.data['data'])
                    }
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    //查询出所有父菜单
    const queryPartMenu = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shop_Id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryPartMenu,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    //首先是lise清空
                    partData.splice(0, partData.length)
                    //首先是
                    partData.push({ id: 0, name: "--" })
                    res.data['data'].map(
                        function (item) {
                            partData.push(item)
                        }
                    )
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    //查询所有板块
    const queryAllShop = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryNorShop,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setShops(res.data['data']);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    // 获取所有角色
    const getAllRole = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryNorRoles,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    let data = res.data['data']
                    let roleList = [
                        {
                            id: '0',
                            name: '全选'
                        }
                    ]
                    data.map(item => {
                        roleList.push(item)
                    })
                    setRoleList(roleList)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    //选中的板块
    const chooseShop = (value) => {
        setChooseShops(value)
    }

    //选中父菜单
    const choosePartMenu = (value) => {
        setPId(partData[value]['id'])
        setPName(partData[value]['name'])
    }

    //确认添加
    const addOk = () => {
        // 向后端传递数据
        if (pId == -1) {
            message.error('父菜单必选')
        } else if (name == '') {
            message.error('菜单名必填')
        } else if (pName == '') {
            message.error('父菜单必选')
        } else if (path == '') {
            message.error('路径必填')
        } else {
            let dataProps = {
                'pId': pId,
                'name': name,
                'pName': pName,
                'path': path,
                'shopIds': chooseShops,
                "role_Id": cookie.load('role'),
                "shop_Id": cookie.load('shopList')
            }

            axios({
                method: 'POST',
                url: servicePath.addMenu,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        // 提示用户成功添加菜单
                        message.success('成功添加菜单')
                        setPId(-1)
                        setName('')
                        setPName('')
                        setPath('')
                        setChooseShops([])
                        form.resetFields();
                        queryPartMenu()
                        // 关闭弹框
                        setAddMenu(false)
                        queryAllMenu()
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            //提示用户添加菜单失败
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }

    }

    //取消添加
    const addCancel = () => {
        // 提示用户取消添加菜单
        message.success('取消添加菜单')
        setPId(0)
        setName('')
        setPName('')
        setPath('')
        setChooseShops([])
        form.resetFields();
        // 关闭弹框
        setAddMenu(false)
    }

    //打开修改菜单
    const openUpdate = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        form.resetFields()
        //显示现在的板块信息
        data[index]['shops'].map(function (item) {
            shopList.push(item.id)
        })

        setChooseShops(shopList)
        setId(data[index]['id'])
        setPId(data[index]['pId'])
        setName(data[index]['name'])
        setPath(data[index]['path'])
        setPName(data[index]['pName'])
        setUpdateMenu(true)

    }

    //确认修改
    const updateOk = () => {
        if (id == -1) {
            message.error('菜单ID必选')
        }
        else if (pId == -1) {
            message.error('父菜单必选')
        } else if (name == '') {
            message.error('菜单名必填')
        } else if (pName == '') {
            message.error('父菜单必选')
        } else if (path == '') {
            message.error('路径必填')
        } else {
            // 向后端传递参数
            let dataProps = {
                'id': id,
                'pId': pId,
                'name': name,
                'pName': pName,
                'path': path,
                'shopIds': chooseShops,
                "role_Id": cookie.load('role'),
                "shop_Id": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.updateMenu,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        // 提示用户成功添加菜单
                        message.success('成功修改菜单')
                        setId(-1)
                        setPId(-1)
                        setName('')
                        setPName('')
                        setPath('')
                        setChooseShops([])
                        setShopList([])
                        form.resetFields();
                        // 关闭弹框
                        setUpdateMenu(false)
                        queryAllMenu()
                    } else {

                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            //提示用户更新菜单失败
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }

    }
    //取消修改
    const updateCancel = () => {
        // 提示用户成功添加菜单
        message.success('取消修改菜单')
        setId(-1)
        setPId(-1)
        setName('')
        setPName('')
        setPath('')
        setChooseShops([])
        setShopList([])
        form.resetFields();
        // 关闭弹框
        setUpdateMenu(false)
        queryAllMenu()
    }

    const deleteMenu = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        let id = data[index]['id']

        confirm({
            title: '确定禁用这个菜单',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                //向后端传递的参数
                let dataProps = {
                    'menuId': id.toString(),
                    "role_Id": cookie.load('role'),
                    "shop_Id": cookie.load('shopList')
                }
                axios({
                    method: 'POST',
                    url: servicePath.deleteMenu,
                    data: dataProps,
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization': cookie.load('token')
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功禁用这个菜单")
                            queryAllMenu()
                            queryPartMenu()
                        } else {
                            if (res.data['errorCode'] == '10001') {
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            }
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消删除这个菜单')
            }
        })


    }

    const recoverMenu = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        let id = data[index]['id']

        confirm({
            title: '确定恢复这个菜单',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                //向后端传递的参数
                let dataProps = {
                    'menuId': id.toString(),
                    "role_Id": cookie.load('role'),
                    "shop_Id": cookie.load('shopList')
                }
                axios({
                    method: 'POST',
                    url: servicePath.recoverMenu,
                    data: dataProps,
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization': cookie.load('token')
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功恢复这个菜单")
                            queryAllMenu()
                            queryPartMenu()
                        } else {
                            if (res.data['errorCode'] == '10001') {
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            }
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消恢复这个菜单')
            }
        })
    }

    //查看菜单所在板块
    const openWatchShop = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        setChooseMenuOfShop(data[index]['shops'])
        setWatchShop(true)
    }

    //关闭菜单所在菜单
    const closeSynopsis = () => {
        setChooseMenuOfShop([])
        setWatchShop(false)
    }

    // 确认配置角色
    const openUpdateRoleOk = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "shopList": shopList,
            "menuId": id,
            "roleIdList": chooseRoleList
        }
        NbAxios(dataProps,"POST", servicePath.batchProcessingRoleMenu).then(res=>{
            if(res.success){
                message.success('成功配置角色菜单权限')
                setChooseRoleList([])
                setShopList([])
                setId(-1)
                setOpenUpdateRole(false)
            }else{
                message.error(res.errorMsg)
            }
        })
    }   

    // 取消配置角色
    const openUpdateRoleCancel = () => {
        message.success('取消配置角色')
        setChooseRoleList([])
        setShopList([])
        setId(-1)
        setOpenUpdateRole(false)
    }

    return (
        <>
            {/* 导航区域 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.02 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                    <Breadcrumb.Item>菜单管理</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* 按钮区域 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06 }}>
                <Row>
                    <Col span={3}>
                        <Button type="primary" onClick={() => setAddMenu(true)}>新增菜单</Button>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06 }}>
                <div style={{ marginTop: '20px', marginBottom: "10px" }}><Tag color="pink" style={{ height: "30px", lineHeight: "30px", fontSize: "20px" }}>{searchMessage}</Tag></div>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={data}
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                />
            </div>

            {/* 添加菜单 */}
            <Modal
                title="添加菜单"
                visible={addMenu}
                onOk={addOk}
                onCancel={addCancel}
                okText="确认添加"
                cancelText="取消添加"
            >
                <Form form={form} {...layout} labelAlign='left'>
                    <Form.Item label="菜单名字" name="name">
                        {/* 填写菜单名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请填写菜单名字"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="请输入菜单名字"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="菜单路径" name="path">
                        {/* 填写菜单名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请填写菜单路径"
                            placement="topLeft"
                        >
                            <Input
                                id="path"
                                size="default"
                                placeholder="请填写菜单路径"
                                onChange={(e) => {
                                    setPath(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="父菜单" name="shop">
                        {/* 选择父菜单 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择父菜单"
                            placement="topLeft"
                        >
                            <Select placeholder="请选择父菜单" style={{ width: 315 }} onChange={choosePartMenu}>
                                {
                                    partData.map(function (item, index) {
                                        return (
                                            <Option key={index} value={index}>{item.name + '--' + item.path}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="板块" name="shop">
                        {/* 选择板块 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择板块"
                            placement="topLeft"
                        >
                            <Select placeholder="请选择板块" mode="multiple" style={{ width: 310 }} onChange={chooseShop}>
                                {
                                    shops.map(function (item) {
                                        return (
                                            <Option key={item.id} value={item.id}>{item.shopname}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 修改菜单 */}
            <Modal
                title="修改菜单"
                visible={updateMenu}
                onOk={updateOk}
                onCancel={updateCancel}
                okText="确认修改"
                cancelText="取消修改"
            >
                <Form form={form} {...layout} labelAlign='left'>
                    <Form.Item label="菜单名称" name="name">
                        {/* 填写菜单名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请填写菜单名字"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="请输入菜单名字"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="菜单路径" name="path">
                        {/* 填写菜单名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请填写菜单路径"
                            placement="topLeft"
                        >
                            <Input
                                id="path"
                                size="default"
                                placeholder="请填写菜单路径"
                                value={path}
                                onChange={(e) => {
                                    setPath(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="父菜单" name="shop">
                        {/* 选择父菜单 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择父菜单"
                            placement="topLeft"
                        >
                            <Select defaultValue={pName} placeholder="请选择父菜单" style={{ width: 315 }}
                                onChange={choosePartMenu}>
                                {
                                    partData.map(function (item, index) {
                                        return (
                                            <Option key={index} value={index}>{item.name + '--' + item.path}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="板块" name="shop">
                        {/* 选择板块 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择板块"
                            placement="topLeft"
                        >
                            <Select defaultValue={shopList} placeholder="请选择板块" mode="multiple" style={{ width: 310 }}
                                onChange={chooseShop}>
                                {
                                    shops.map(function (item) {
                                        return (
                                            <Option key={item.id} value={item.id}>{item.shopname}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 查看所在板块 */}
            <Modal
                title="菜单所在板块"
                visible={watchShop}
                onCancel={closeSynopsis}
                footer={[]}
            >
                {
                    chooseMenuOfShop.map(item => {
                        return <Tag key={item.name} color="blue">{item.name}</Tag>
                    })
                }
            </Modal>

            {/* 配置角色 */}
            <Modal
                title="配置角色"
                visible={openUpdateRole}
                onCancel={openUpdateRoleCancel}
                onOk={openUpdateRoleOk}
                okText="确认"
                cancelText="取消"
            >
                <Select value={chooseRoleList}  mode="multiple" placeholder="请选择角色" style={{ width: 370 }} onChange={(value) => setChooseRoleList(value)}>
                    {
                        roleList.map(item => {
                            return <Option value={item.id}>{item.name}</Option>
                        })
                    }
                </Select>
            </Modal>
        </>
    )


}

export default Menu