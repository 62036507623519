import React, { useEffect } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

function Index({
    province,
    provinceCovidDate,
    provinceNewLocal,
    provinceNewAsymptomatic
}){
    let report = {
        title: {
            text: `${province}疫情数据`
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['新增确诊人数', '新增无症状人数']
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: provinceCovidDate,
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: '新增确诊人数',
                type: 'line',
                data: provinceNewLocal,
            },
            {
                name: '新增无症状人数',
                type: 'line',
                data: provinceNewAsymptomatic,
            }
        ]
    }
    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('covid'));
        reportChart.clear()
        // 绘制图表
        reportChart.setOption(report)
        // window捕获标签点击，表格重绘放到浏览器渲染前
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [provinceNewAsymptomatic])


    return (
        <div>
            <div id="covid" style={{ height: "400px", width: "100vw" }}></div>
        </div>
    )

}


export default Index