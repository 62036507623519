import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment'
import { Form, Row, Table, Button, Col, Tooltip, Input, Popconfirm, Select, Modal, Drawer, DatePicker, Tag , message} from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../../config/apiUrl'
import '../../static/css/index.css'
import locale from 'antd/es/date-picker/locale/zh_CN';
const { Option } = Select
const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;
const { Search } = Input;
// 团队管理
function Team(props) {

    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    };

    const layout1 = {
        labelCol: { span: 5 },
        wrapperCol: { span: 20 },
    };

    const [form] = Form.useForm();

    // 页数
    const [page, setPage] = useState(1)

    // 总数
    const [total, setTotal] = useState('')

    // 团队Id
    const [ teamId , setTeamId ] = useState('')

    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])

    // 搜索类型
    const [ searchType , setSearchType ] = useState('关键字')

    // 公司成员
    const [ peopleList , setPeopleList ] = useState([])

    // 数据
    const [date, setDate] = useState([])

    // 团队名称
    const [name, setName] = useState("")

    // 团队简介
    const [tdjj, setTdjj] = useState("")

    // 负责人
    const [people, setPeople] = useState("")

    // 创建者
    const [creator, setCreator] = useState('')

    // 成员
    const [member, setMember] = useState([])

    // 打开创建团队
    const [createTeam, setCreateTeam] = useState(false)

    // 打开修改团队
    const [updateTeam, setUpdateTeam] = useState(false)

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 打开查询条件
    const [openSearch, setOpenSearch] = useState(false)

    // 查看成员
    const [ openMember , setOpenMember ] = useState(false)

    // 打开简介
    const [openTdjj, setOpenTdjj] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 表格标题
    const columns = [
        {
            title: '团队名称',
            key: 'name',
            dataIndex: 'name',
            width: '80px',
            align: 'center',
        },
        {
            title: '负责人',
            key: 'director',
            dataIndex: 'director',
            width: '80px',
            align: 'center',
        },
        {
            title: '创建人',
            key: 'founder',
            dataIndex: 'founder',
            width: '80px',
            align: 'center',
        },
        {
            title: '人数',
            key: 'count',
            dataIndex: 'count',
            width: '80px',
            align: 'center',
        },
        {
            title: '创建时间',
            key: 'create_time',
            dataIndex: 'create_time',
            width: '80px',
            align: 'center',
        },
        {
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            width: '80px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Row style={{ display: date[index]['is_valid'] == 0 ? "none" : "" }}>
                        <Col span={6} style={{ display: cookie.load("name") == date[index]['director'] || cookie.load("name") == date[index]['founder'] ? "" : "none"}}>
                            <Tooltip placement="top" title="编辑">
                                <EditOutlined onClick={() => {
                                    form.resetFields()
                                    setTeamId(date[index]['id'])
                                    setCreator(date[index]['creator'])
                                    setName(date[index]['name'])
                                    setPeople(date[index]['director'])
                                    setMember(date[index]['member'])
                                    setUpdateTeam(true)
                                }} />
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <Tooltip placement="top" title="查看成员">
                                <SearchOutlined onClick={()=>{
                                    setMember(date[index]['member'])
                                    setOpenMember(true)}}/>
                            </Tooltip>
                        </Col>
                    </Row>
                )
            }
        }
    ]

    // 初始化数据
    useEffect(() => {
        getAllPeople()
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    }, [])

    useEffect(()=>{
        getAllTeamInfo()
    },[page])

    // 获取所有公司人员
    const getAllPeople = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method:'POST',
            data:dataProps,
            url:servicePath.queryPMSysUser,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res=>{
                if(res.data.success){
                    setPeopleList(res.data.data);
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取团队数据
    const getAllTeamInfo = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "page": page,
            "page_size": 10,
        }
        axios({
            method:'POST',
            data:dataProps,
            url:servicePath.queryTeamInfo,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res=>{
                if(res.data.success){
                    setTotal(res.data.total)
                    setDate(res.data.data);
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 确认创建团队
    const createOk = () => {
        let dataProps = {
            "name":name,
            "director":people,
            "principal_uuid":cookie.load('id'),
            "members":member,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method:'POST',
            data:dataProps,
            url:servicePath.createTeam,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res=>{
                if(res.data.success){
                   message.success('团队创建成功')
                   setName('')
                   setPeople('')
                   setMember([])
                   getAllTeamInfo()
                   setCreateTeam(false)
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 取消创建团队
    const createCancel = () => {
        message.success('取消创建团队')
        setName('')
        setPeople('')
        setMember([])
        setCreateTeam(false)
    }

    // 结束团队
    const deleteTeam = () => {
        let dataProps = {
            "team_id":teamId,
            "principal_uuid":cookie.load('id'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method:'POST',
            data:dataProps,
            url:servicePath.dissolveTeam,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res=>{
                if(res.data.success){
                   message.success('成功解散团队')
                   setTeamId('')
                   setName('')
                   setPeople('')
                   setMember([])
                   getAllTeamInfo()
                   setUpdateTeam(false)
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 取消结束团队
    const cancelDelete = () => {

    }

    // 确认修改团队
    const updateOk = () => {
        let dataProps = {
            "team_id":teamId,
            "name":name,
            "director":people,
            "principal_uuid":cookie.load('id'),
            "members":member,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method:'POST',
            data:dataProps,
            url:servicePath.updateTeam,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res=>{
                if(res.data.success){
                   message.success('团队修改成功')
                   setTeamId('')
                   setName('')
                   setPeople('')
                   setMember([])
                   getAllTeamInfo()
                   setUpdateTeam(false)
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 取消修改团队
    const updateCancel = () => {
        message.success('取消修改团队')
        setTeamId('')
        setName('')
        setPeople('')
        setMember([])
        setUpdateTeam(false)
    }

    // 关闭查看项目简介
    const openTdjjCancel = () => {
        setTdjj('')
        setOpenTdjj(false)
    }

    // 关闭查询弹框
    const onSearchClose = () => {
        form.resetFields()
        setOpenSearch(false)
    }

    // 关闭查询弹框
    const openMemberCancel = () => {
        setOpenMember(false)
    }

    return (
        <div>
            {/* 按钮区域 */}
            <div style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.03 }}>
                <Row>
                    <Col style={{marginRight:'20px'}}>
                        <Button type="primary" style={{ display: jurisdiction.indexOf("pm::createTeam") != -1 ? "" : "none" }} onClick={() => {form.resetFields();setCreateTeam(true)}}>创建团队</Button>
                    </Col>
                    {/* <Col style={{width:'700px'}}> */}
                        {/* <Search placeholder="查询关键字/时间" onSearch={(value)=>console.log(value)} enterButton /> */}
                        {/* <Input.Group compact>
                            <Select style={{ width: '15%' }} defaultValue="关键字" onChange={(value)=>setSearchType(value)}>
                                <Option value="关键字">关键字</Option>
                                <Option value="时间">时间</Option>
                            </Select>
                            {
                                searchType == '关键字'
                                ? <Search style={{ width: '70%' }} placeholder="查询关键字" onSearch={(value)=>console.log(value)} enterButton /> 
                                : <DatePicker.RangePicker locale={locale} style={{ width: '70%' }} />
                            }
                        </Input.Group>
                    </Col> */}
                </Row>
                {/* <Button onClick={() => setOpenSearch(true)} style={{ marginLeft: size.width * 0.02 }}>条件查询</Button> */}
            </div>
            {/* 项目表格 */}
            <div style={{ marginTop: size.height * 0.03 }}>
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={paginationProps}
                    // 行属性
                    onRow = {(record, index)=>{
                        return (
                            {
                                style: {
                                    // padding: '0',
                                    // height:'35px',
                                    color:record['is_valid']==0 ? '#ccc':''
                                    // display: record['is_valid']==0 ? 'none':''
                                },
                            }
                        )
                    }}
                />
            </div>

            {/* 创建项目弹框 */}
            <Modal
                title="创建团队"
                centered={true}
                visible={createTeam}
                maskClosable={false}
                onOk={createOk}
                onCancel={createCancel}
                okText="确认"
                cancelText="取消"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="团队名称" name="name"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请填写团队名称,名称格式:最后加上“团队”两个字"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                placeholder="请填写团队名称"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="负责人" name="people"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请填写负责人"
                            placement="topLeft"
                        >
                            <Input
                                id = "people"
                                placeholder="请填写负责人"
                                value={people}
                                onChange={(e) => {
                                    setPeople(e.target.value);
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="成员" name="people"
                    >
                        <Tooltip
                            trigger={['focus']}
                            title="请选择成员"
                            placement="topLeft"
                        >
                            <Select
                                showSearch
                                placeholder="请选择成员"
                                mode="multiple"
                                defaultValue={member}
                                onChange={(value) => {
                                    setMember(value);
                                }}
                            >
                                {
                                    peopleList.map(item=>{
                                        return <Option value={item.name}  disabled={item.name == people ? true : false}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 编辑项目弹框 */}
            <Modal
                title="编辑团队"
                centered={true}
                visible={updateTeam}
                maskClosable={false}
                onOk={updateOk}
                onCancel={updateCancel}
                okText="确认"
                cancelText="取消"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="团队名称" name="name"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请填写团队名称"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                placeholder="请填写团队名称"
                                value={name}
                                disabled
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="负责人" name="people"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请填写负责人"
                            placement="topLeft"
                        >
                             <Input
                                id = "people"
                                placeholder="请填写负责人"
                                value={people}
                                onChange={(e) => {
                                    setPeople(e.target.value);
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="成员" name="people"
                    >
                        <Tooltip
                            trigger={['focus']}
                            title="请选择成员"
                            placement="topLeft"
                        >
                            <Select
                                showSearch
                                placeholder="请选择成员"
                                mode="multiple"
                                defaultValue={member}
                                onChange={(value) => {
                                    setMember(value);
                                }}
                            >
                                {
                                    peopleList.map(item=>{
                                        return <Option value={item.name} disabled={item.name == people ? true : false}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                    {/* <Form.Item label="团队简介" name="tdjj"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请填写团队简介"
                            placement="topLeft"
                        >
                            <TextArea rows={4} placeholder="请填写团队简介" defaultValue={tdjj}/>
                        </Tooltip>
                    </Form.Item> */}
                    <Popconfirm
                            title="确认解散?解散后不可恢复!"
                            onConfirm={deleteTeam}
                            onCancel={cancelDelete}
                            placement="top"
                            okText="确定"
                            cancelText="取消"
                        >
                           <Col style={{marginLeft:'330px'}}>
                                <Button type="primary" danger>解散团队</Button>
                            </Col>
                        </Popconfirm>
                </Form>
            </Modal>

            {/* 查看简介 */}
            <Modal
                title="查看团队简介"
                centered={true}
                visible={openTdjj}
                onCancel={openTdjjCancel}
                footer={null}
            >
                {tdjj}
            </Modal>

            {/* 查看成员 */}
            <Modal
                title="查看成员"
                centered={true}
                visible={openMember}
                onCancel={openMemberCancel}
                footer={null}
            >
                {
                    member.map(item=>{
                       return <span >{item} </span>
                    })
                }
            </Modal>

            {/* 查询条件 */}
            <Drawer
                title="查询条件"
                placement="right"
                closable={false}
                onClose={onSearchClose}
                visible={openSearch}
                width={700}
            >
                <Form
                    form={form}
                    {...layout1}
                    labelAlign='left'
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="团队名称"
                            >
                                <Input
                                    id='name'
                                    placeholder="请输入团队名称"

                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="people"
                                label="负责人"
                            >
                                <Input
                                    id='people'
                                    placeholder="请输入负责人"

                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="creator"
                                label="创建人"
                            >
                                <Input
                                    id='creator'
                                    placeholder="请输入创建人"

                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <Form.Item
                                name="lxsj"
                                label="创建时间"
                            >
                                <RangePicker locale={locale} />
                            </Form.Item>
                        </Col>
                        <Col style={{ marginLeft: 380 }}>
                            <Button type="primary">查询</Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )

}

export default Team