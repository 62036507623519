import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Tag  } from 'antd'
import style from  './CursorItem.module.css'
import classNames from 'classnames'
import { TeamOutlined, PlusOutlined } from '@ant-design/icons';

const { Paragraph, Text, Title } = Typography;
function CursorItem({
    children,
    className="",
    width,
    height,
    title,
    introduce,
    maxNum,
    signNum,
    teachear,
    tags,
    cursorType,
    action,
    endTime,
    openTime,
    showMore,
    titleClick,
    showIntroduce,
    onlyTitle,
    teachType='面授',
    isAttend,
    onShow,
    onHidden,
    bgColor,
    cardTitleHeight=200,
    titleAlign,
    courseKind,
    introduceRowNum = 2,
    withoutOpentime,
    buttonClick,
    introduceClick,
    ...rest
}) {
    // 卡片整体的宽高
    const [cardHeight,setHeight] = useState(cardTitleHeight)
    const [cardWidth,setWidth] = useState(width)
    // 卡片上部分的高度
    const [cardUp,setcardUp] = useState(cardTitleHeight)
    // 定义不同action对应的按钮
    const actionButton = {
        '报名': <Button className={style.actionButton} size="large" style={{ background: 'rgba(79,157,222)', lineHeight: 1 }} shape="circle-outline" onClick={buttonClick}><PlusOutlined style={{ color: 'white', fontSize: "1.1rem", fontWeight: "600" }} /></Button>,
        '旁听': <Button className={classNames(style.actionButton,{[style.otherButton]:['旁听','维护','发布','求课'].includes(action)})} size="large" style={{ background: 'rgba(79,157,222)', lineHeight: 1 }}  onClick={buttonClick}>我要旁听</Button>,
        '维护': <Button className={classNames(style.actionButton,{[style.otherButton]:['旁听','维护','发布','求课'].includes(action)})} size="large" style={{ background: 'rgba(79,157,222)', lineHeight: 1 }}  onClick={buttonClick}>维护</Button>,
        '发布': <Button className={classNames(style.actionButton,{[style.otherButton]:['旁听','维护','发布','求课'].includes(action)})} size="large" style={{ background: 'rgba(79,157,222)', lineHeight: 1 }}  onClick={buttonClick}>发布</Button>,
        '求课': <Button className={classNames(style.actionButton,{[style.otherButton]:['旁听','维护','发布','求课'].includes(action)})} size="large" style={{ background: 'rgba(79,157,222)', lineHeight: 1 , borderRadius:"20px"}}  onClick={buttonClick}>求课</Button>,
    }
    // 十种颜色随机
    const color = [
        // 蓝色
        '#495aff',
        // 灰色
        '#323232',
        // 靛青色
        '#2b5876',
        // 淡紫色
        '#4e4376',
        // 亮粉色
        '#ff758c',
        // 淡粉色
        '#ff7eb3',
        // 紫色
        '#b721ff',
        // 哑光蓝
        'rgb(68, 129, 235)',
        // 亮蓝色
        'rgb(4, 190, 254)',
        '#9890e3'
    ]

    const [selectColor, setColor] = useState(color[0])

    useEffect(() => {
        // 给卡片设置初始背景色
        setColor(color[Math.floor(Math.random() * 10)])
        if(showIntroduce){
            setHeight(height)
        }
    }, [])

    const showMes = ()=>{
        if(showIntroduce || onlyTitle){
            return false
        }
        onShow && onShow()
        setHeight(height)
    }
    const hiddenMes = ()=>{
        if(showIntroduce || onlyTitle){
            return false
        }
        onHidden && onHidden()
        setHeight(cardTitleHeight)
    }

    return (
        //卡片尺寸默认250x320
        <div className={`${style.cursorItemWapper} ${className}`}  onMouseLeave={hiddenMes} onMouseEnter={showMes} style={{ width: cardWidth || 250, height: cardHeight || 350 }} {...rest} >
            <div className={style.cursorUpErea} style={{ background:bgColor || selectColor,height:cardUp,gridTemplateColumns:"1fr",gridTemplateRows:`${!cursorType && !maxNum && !signNum && !tags && `1fr`}`,gridTemplateAreas:`${!cursorType && !maxNum && !signNum && "'cursor-title' 'cursor-tags'"}` }}>
                <div className={style.cursorUpRowFirst}>
                    {cursorType &&
                        <Text className={style.cursorTypeText}>{cursorType}</Text>}
                    {signNum >= maxNum ?
                        <div className={style.cursorPeople}>已满员</div> :
                        <div className={style.cursorPeople}>{  maxNum && <TeamOutlined />}{signNum}{ ( maxNum) && '/'}{maxNum}</div>
                    }
                </div>
                <div className={style.upMiddleRow}>
                    <Title level={3} onClick={titleClick && titleClick} ellipsis={{ rows: 2, expandable: false }} className={style.cursorTitle} style={{ margin: 0 ,textAlign:`${titleAlign && 'center'}`}}>{title}</Title>
                    {openTime && <Text className={style.cursorStartTime}>开课时间：{openTime}{teachType && <span className={style.teachTypeText}>{teachType}</span>}</Text>}
                </div>
                <div className={style.cursorTagsWapper}>
                    {tags && tags.map((item, index) => {
                        return (
                            <Text className={style.cursorTag} key={`${item}${index}`}>#{item}</Text>
                        )
                    })}
                </div>
            </div>
            <div className={classNames(style.cursorDownErea,{[style.withoutOpentime]:withoutOpentime})} style={{display:`${onlyTitle && "none"}`}}>
                {
                    introduce && <Paragraph type="secondary" ellipsis className={style.cursorIntroduce}  onClick={introduceClick}>简介：{introduce}</Paragraph >
                }
                {endTime &&
                    <Text type="secondary" className={style.cursorTimeErea}>
                        报名截止：{endTime}{isAttend && <span className={style.isAttendText}>可蹭课</span>}
                    </Text>
                }
                <div className={style.cursorTeacher}>
                    {teachear &&
                        <div className={style.cursorTeacherWapper}>
                            {
                                courseKind == undefined ? <Text type="secondary" className={style.cursorTeacherSuffix}>{"创建者："}</Text>
                                : <Text type="secondary" className={style.cursorTeacherSuffix}>{parseInt(courseKind)==2?"引领者：":"分享人："}</Text>
                            }
                            <Text type="secondary" className={style.cursorTeacherPrefix}>
                                {teachear}
                            </Text>
                        </div>
                    }
                </div>
                {action && actionButton[action]}
            </div>
        </div>
    )
}

CursorItem.propTypes = {
    children: PropTypes.any,
    // 卡片宽度
    width: PropTypes.number,
    // 卡片高度
    height: PropTypes.number,
    // 课程标题
    title: PropTypes.string,
    // 课程介绍
    introduce: PropTypes.string,
    // 上限人数
    maxNum: PropTypes.number,
    // 已报名人数
    signNum: PropTypes.number,
    // 老师
    teachear: PropTypes.string,
    // 标签列表
    tags: PropTypes.array,
    // 课程二级分类
    cursorType: PropTypes.string,
    // 卡片动作按钮
    action: PropTypes.oneOf(['报名', '旁听', '维护', '发布']),
    // 报名截止时间
    endTime: PropTypes.string,
    // 开课时间
    openTime: PropTypes.string,
    // 标题点击事件
    titleClick: PropTypes.func,
    // 显示介绍部分的事件
    onShow: PropTypes.func,
    // 隐藏介绍的事件
    onHidden: PropTypes.func,
    // 卡片上部分的高度
    cardTitleHeight: PropTypes.number,
    // 简介行数
    introduceRowNum: PropTypes.number
}

export default CursorItem