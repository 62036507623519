import React, { useContext, useState, useEffect, memo, useMemo } from 'react';
import style from './style.module.css';
import TargetCard from './components/targetCard';
import IndicatorCard from './components/indicatorCard';
import SaleImage from './components/saleImage'
import { StateContext } from '../raceShop/store/stateShare';
import { Table, Row, Col, Select, Radio, Modal, Form, Input, DatePicker, Typography, Tooltip, Button, message, Spin, Popover, Checkbox } from 'antd';
import { CloudDownloadOutlined, SearchOutlined, RightOutlined, SettingOutlined, LeftOutlined } from '@ant-design/icons';
import Debounce from '../../config/utils/debounce';
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import NbAxios from '../../config/utils/nbAxios';
import cookie from 'react-cookies';
import servicePath from '../../config/apiUrl'
import Axios from 'axios';
import { GoodsContext } from '../goodsReport/store/itemInfoShare'
const MemoTargetCard = memo(TargetCard)
const MemoIndicatorCard = memo(IndicatorCard)
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title, Paragraph, Text, Link } = Typography;
function SaleBoard(props) {
    let shopIdDict = {
        'jr': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'gs':'703247c1-a200-42bc-b70f-742dbab27aa4'
    }
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    // 经营数据看版数据
    const [indicatorCardList, setIndicatorCardList] = useState(['转化率', '支付人数', '支付件数', '人均加购件数', '加购人数', '加购件数', '访客数', '实销金额', '退款金额', '销售额'])
    const [allIndicatorCardList] = useState(['转化率', '支付人数', '支付件数', '人均加购件数', '加购人数', '加购件数', '访客数', '实销金额', '退款金额', '销售额'])
    const settingContent = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Checkbox style={{ marginLeft: 8 }} checked={indicatorCardList.sort().toString() == allIndicatorCardList.sort().toString() ? true : false}
                onClick={(e) => {
                    if (e.target.checked) {
                        setIndicatorCardList(allIndicatorCardList)
                    } else {
                        setIndicatorCardList([])
                    }
                }}>全选</Checkbox>
            {
                allIndicatorCardList.map(item => {
                    return <Checkbox checked={indicatorCardList.indexOf(item) != -1 ? true : false} onClick={(e) => {
                        if (e.target.checked) {
                            let obj = [...indicatorCardList]
                            obj.push(item)
                            setIndicatorCardList(obj)
                        } else {
                            let obj = [...indicatorCardList]
                            obj.remove(item)
                            setIndicatorCardList(obj)
                        }
                    }}>{item}</Checkbox>
                })
            }
        </div>
    )
    // 获取状态管理中的值
    const { state, dispatch } = useContext(GoodsContext)
    // 商品IDlist
    const [goodsIdList, setGoodsIdList] = useState([])
    // 判断是不是首次加载
    const [count, setCount] = useState(1)
    const [setting, setSetting] = useState(false)
    // 绑定表单数据
    const [targetForm] = Form.useForm()
    // 设置目标的类型
    const [targetType, setTargetType] = useState('支付')
    // 当前店铺名
    const [shopName, setShopName] = useState(props.location.pathname.split('/')[3])
    // 选择的周期类型
    const [dateType, setDateType] = useState('day')
    // 开始日期
    const [startDate, setStartDate] = useState(moment().subtract(1, 'day').startOf(dateType))
    // 结束日期  
    const [endDate, setEndDate] = useState(moment().subtract(1, 'day').endOf(dateType))
    // 选择的周期类型
    const [dateCycle, setDateCycle] = useState(1)
    // 获取一月的天数
    const monthDays = moment().daysInMonth()
    // 获取一月的第几天
    const nowInMonth = parseInt(moment().format('DD'))
    // 获取一年的第几天
    const nowInyear = moment().dayOfYear()
    // 获取一年的天数
    const yearDays = parseInt(moment().format('YYYY')) / 4 === 0 ? 366 : 365
    // 目标弹窗默认的年份
    const [targetYear, setTargetYear] = useState(moment())
    // 本周销售
    const [weekPay, setWeekPay] = useState(1)
    // 上周销售
    const [lastWeekPay, setLastWeekPay] = useState(1)
    // 同比周
    const [weekCompare, setWeekCompare] = useState(1)
    // 本月销售
    const [monthPay, setMonthPay] = useState(1)
    // 上月销售
    const [lastMonthPay, setLastMonthPay] = useState(1)
    // 同比本月
    const [monthCompare, setMonthCompare] = useState(1)
    // 本年销售
    const [yearPay, setYearPay] = useState(1)
    // 去年销售
    const [lastYearPay, setLastYearPay] = useState(1)
    // 同比去年
    const [yearCompare, setYearCompare] = useState(1)
    // 退款率
    const [monthRefundRate, setMonthRefundRate] = useState(1)
    // 同比退款率
    const [monthRefundRateCompare, setMonthRefundRateCompare] = useState(1)
    // 年目标
    const [yearTarget, setYearTarget] = useState(1)
    // 月目标
    const [monthTarget, setMonthTarget] = useState(1)
    // 年实销目标
    const [yearGetTarget, setYearGetTarget] = useState(1)
    // 月实销目标
    const [monthGetTarget, setMonthGetTarget] = useState(1)
    // 退款率目标
    const [monthRefundRateTarget, setMonthRefundRateTarget] = useState(1)
    // 月实销
    const [monthGetMoney, setMonthGetMoney] = useState(1)
    // 上月实销
    const [lastmonthGetMoney, setLastMonthGetMoney] = useState(1)
    // 年实销
    const [yearGetMoney, setyearGetMoney] = useState(1)
    // 去年实销
    const [lastYearGetMoney, setLastYearGetMoney] = useState(1)
    // 月实销同比
    const [monthGetCompare, setMonthGetCompare] = useState(1)
    // 年实销同比
    const [yearGetCompare, setYearGetCompare] = useState(1)
    // 上月退款
    const [lastMonthRefund, setLastMonthRefund] = useState(1)
    // 销售额
    const [saleMoney, setSaleMoney] = useState(0)
    // 销售额同比
    const [saleMoneyCompare, setSaleMoneyCompare] = useState(0)
    // 退款金额
    const [refundMoney, setRefundMoney] = useState(0)
    // 退款金额同比
    const [refundMoneyCompare, setRefundMoneyCompare] = useState(0)
    // 退款率
    const [refundRate, setRefundRate] = useState(0)
    // 转化率
    const [changeRate, setChangeRate] = useState(0)
    // 转化率同比
    const [changeRateCompare, setChangeRateCompare] = useState(0)
    // 访客数
    const [visitor, setVisitor] = useState(0)
    // 访客数同比
    const [visitorCompare, setVisitorCompare] = useState(0)
    // 支付件数
    const [payPiece, setPayPiece] = useState(0)
    // 支付件数同比
    const [payPieceCompare, setPayPieceCompare] = useState(0)
    // 支付人数
    const [payPeople, setPayPeople] = useState(0)
    // 支付人数同比
    const [payPeopleCompare, setPayPeopleCompare] = useState(0)
    // 实销金额
    const [actualPay, setActualPay] = useState(0)
    // 实销金额同比
    const [actualPayCompare, setActualPayCompare] = useState(0)
    // 加购件数
    const [addPiece, setAddPiece] = useState(0)
    // 加购件数加购率
    const [addPieceRate, setAddPieceRate] = useState(0)
    // 加购件数同比
    const [addPieceCompare, setAddPieceCompare] = useState(0)
    // 商品加购人数
    const [goodsAddPeople, setGoodsAddPeople] = useState(0)
    // 商品加购人数同比
    const [goodsAddPeopleCompare, setGoodsAddPeopleCompare] = useState(0)
    // 商品加购人数加购率
    const [goodsAddPeopleRate, setGoodsAddPeopleRate] = useState(0)
    // 人均加购件数
    const [perAdd, setPerAdd] = useState(0)
    // 人均加购件数同比
    const [perAddCompare, setPerAddCompare] = useState(0)
    // 下载按钮的状态
    const [downStatus, setDownStatus] = useState(false)
    // 排除按钮的加载状态
    const [excludeLoading, setExcludeLoading] = useState(false)
    // 加入计算按钮的状态
    const [addLoading, setAddLoading] = useState(false)
    // 是否查看已排除商品
    const [lookExclude, setLookExclude] = useState(false)
    // 搜索关键字
    const [keywords, setKeywords] = useState('')
    // 排序标志位
    const [sort, setSort] = useState('DESC')
    // 需要排序的字段
    const [sortField, setSortField] = useState('pay_money')
    // 页码
    const [page, setPage] = useState(1)
    // 页面长度
    const [pageSize, setPageSize] = useState(10)
    // 数据总长度
    const [total, setTotal] = useState(10)
    // 表格状态
    const [tableLoading, setTableLoading] = useState(false)
    // 是否查看整体数据
    const [overallData, setOverallData] = useState(true)
    //定义分页器的参数
    const paginationProps = {
        showSizeChanger: false,
        showQuickJumper: true,
        // 总页数
        total: total,
        //页码改变时，设置当前页码和每页显示条数
        current: page,
        onChange: (page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
        },
    }
    //定义选中的行数
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 此函数selectedRowKey为一个数组，需要取最后一个元素才能获得当前点击行
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    //行选择器的参数
    const rowSelection = {
        //隐藏选择所有行
        hideSelectAll: false,
        fixed: true,
        //选中的行数
        selectedRowKeys: selectedRowKeys,
        //行数变化的回调
        onChange: onSelectChange,
    }
    const colNameList = [
        // {
        //     title: 'sku',
        //     key: "sku",
        //     width: 120
        // },
        {
            title: '品牌',
            key: "brand_code",
            width: 100
        },
        {
            title: '天猫类目',
            key: "category1",
            width: 150
        },
        {
            title: '季节',
            key: "season",
            width: 100
        },
        {
            title: '产品线',
            key: "product_line",
            width: 100
        },
        {
            title: '年份',
            key: "year",
            width: 100
        },
        {
            title: '上架时间',
            key: "new_time",
            width: 120
        },
        {
            title: '吊牌价',
            key: "price",
            width: 100
        },
        {
            title: '访客数',
            key: "visitors",
            sorter: true,
            width: 100
        },
        {
            title: '加购件数',
            key: "add_nums",
            sorter: true,
            width: 130
        },
        {
            title: '加购率',
            key: "add_rate",
            sorter: true,
            width: 100
        },
        {
            title: '支付金额',
            key: "pay_money",
            sorter: true,
            width: 150
        },
        {
            title: '支付件数',
            key: "pay_pices",
            sorter: true,
            width: 150
        },
        {
            title: '转化率%',
            key: "change_rate",
            sorter: true,
            width: 130
        },
        {
            title: '件单价',
            key: "unit_price",
            sorter: true,
            width: 130
        },
        // {
        //     title: '退款件数',
        //     key: "refund_num",
        //     sorter: true,
        //     width: 130
        // },
        {
            title: '退款金额',
            key: "refund_money",
            sorter: true,
            width: 130
        },
        // {
        //     title: '退款率%',
        //     key: "refund_rate",
        //     sorter: true,
        //     width: 130
        // },
        {
            title: '实销件数',
            key: "get_nums",
            sorter: true,
            width: 130
        }
    ]
    const [data, setData] = useState([])
    const columns = [
        {
            title: '',
            dataIndex: 'pic',
            key: 'pic',
            fixed: "left",
            align: 'center',
            width: 80,
            onHeaderCell: onHeaderCell,
            render: (item, record, index) => {
                // return <img width={'100%'} src={`https://pic.bmcsoft.cn/it/${shopName}/${record.sku}.jpg`}   />
                return <SaleImage shopName={shopName == 'jr' ? 'JORYA' : shopName} sku={record.sku} id={record.goods_id} />
            }
        },
        {
            title: '商品名称',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
            fixed: "left",
            ellipsis: true,
            width: 300,
            onHeaderCell: onHeaderCell,
            shouldCellUpdate: shouldCellUpdate,
            render: (item, record, index) => {
                return <Tooltip title={item} placement='topLeft'>
                    <div className={style.tableItemInfo}>
                        <a href={`https://detail.tmall.com/item.htm?id=${record.goods_id}`} style={{ color: "rgb(24,144,255)" }} target="_blank" >{item}</a>
                        <a href={`https://detail.tmall.com/item.htm?id=${record.goods_id}`} target="_blank" >{record.sku || '-'}</a>
                    </div>
                </Tooltip>
            }
        },
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                sorter: item.sorter,
                sortOrder: item.key === sortField && changeSortField(item.key),
                align: 'center',
                width: item.width,
                onHeaderCell: onHeaderCell,
                shouldCellUpdate: shouldCellUpdate,
                render: (row, index, record) => {
                    if (item.key === "new_time") {
                        return row ? moment(row).format('YYYY-MM-DD') : "-"
                    }
                    if (row !== null) {
                        return row
                    } else {
                        return '-'
                    }
                }
            }
        })
    ]

    const targetMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const formList = targetMonths.map((item, index) => {
        return {
            name: `${item}`,
            label: `${item}月`
        }
    })

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    };

    const targetFooter = (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" onClick={submitTarget} >提交</Button>
            <Button onClick={controlSetting} >取消</Button>
        </div>
    )

    const searchIcon = (
        <div className="header-search-icon"><SearchOutlined /></div>
    )

    // 表格上的操作区域
    const header = (
        <div className={style.tableControll} >
            <div className={style.leftControll} >
                <Title level={5} style={{ margin: 0 }} >商品统计数据</Title>
                <Radio.Group onChange={tableLookExclude} value={lookExclude}>
                    <Radio value={false}>全量商品</Radio>
                    <Radio value={true}>已排除商品</Radio>
                </Radio.Group>
            </div>
            <div className={style.middleControll} >
                <Input autoFocus suffix={searchIcon} value={keywords}
                    onChange={searchChange} onPressEnter={searchEnter}
                    placeholder="搜索商品id，名称" allowClear style={{ width: 300, justifySelf: "center" }} />
            </div>
            <div className={style.rightControll} >
                <Tooltip placement="bottomLeft" title="排除统计条件查询全部商品" >
                    <Button disabled={goodsIdList.length != 0 ? false : true} onClick={() => {
                        setGoodsIdList([])
                        setPage(1)
                    }} type="primary" style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}>排除统计条件查询全部商品</Button>
                </Tooltip>
                <Tooltip placement="bottomLeft" title="计算实销排除的商品" >
                    <Button loading={excludeLoading} danger disabled={!(selectedRowKeys.length > 0 && !lookExclude)} onClick={setExcludeGoodsId} type="primary" style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}>计算排除</Button>
                </Tooltip>
                <Tooltip placement="bottomLeft" title="将商品加入实销计算" >
                    <Button type="primary" loading={addLoading} disabled={!(lookExclude && selectedRowKeys.length > 0)} onClick={setExcludeGoodsIdFailure} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}>加入计算</Button>
                </Tooltip>
                <Tooltip placement="bottomLeft" title="下载所选日期的全量商品数据" >
                    <Button loading={downStatus} onClick={exportTableData} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}>全量商品数据导出</Button>
                </Tooltip>
                <Tooltip placement="bottomLeft" title="下载商品基础信息缺失导入模板" >
                    <Button loading={downStatus} type="link" onClick={exportLostGoodsData} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}>基础信息有缺？</Button>
                </Tooltip>
                {/* <Tooltip placement="bottomLeft" title="查看已排除计算的商品" >
                    <Button onClick={tableLookExclude} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}>{lookExclude ? "查看全量商品" : "查看已排除商品"}</Button>
                </Tooltip> */}
            </div>
        </div>
    )

    useEffect(() => {
        if (props.location.state != undefined) {
            setStartDate(moment(props.location.state.start_date, "YYYY-MM-DD"))
            setEndDate(moment(props.location.state.end_date, "YYYY-MM-DD"))
            setGoodsIdList(props.location.state.idList)
        } else {
            getTimeRangesaleStatistical()
            getTableData().then(() => getTableHeaderData())
            setCount(2)
        }
    }, [])

    useEffect(() => {
        getTableData().then(() => getTableHeaderData())
        setCount(2)
    }, [goodsIdList])

    // useEffect(() => {
    //     getTimeRangesaleStatistical()
    // }, [])

    // useEffect(() => {
    //     count === 2 && getTableHeaderData()
    // }, [startDate, endDate])

    useEffect(() => {
        count === 2 && getTableData()
    }, [page, pageSize, lookExclude, sortField, sort, goodsIdList])

    // 封装memo，阻止重复渲染
    function useMyMemo(data, deps) {
        return useMemo(() => {
            return data
        }, [deps ? deps : data])
    }
    return (
        <div className={style.saleIndexWapper} >
            <div style={{ width: "100%", borderBottom: "1px solid #ccc", display: "flex", justifyContent: 'space-between' }}>
                <div>
                    {
                        overallData ? <Link onClick={() => {
                            setOverallData(false)
                        }}>隐藏店铺整体数据</Link> : <Link onClick={() => {
                            setOverallData(true)
                        }}>查看店铺整体数据</Link>
                    }
                </div>
            </div>
            <div className={style.targetRow} style={{ display: overallData ? '' : 'none' }}>
                <MemoTargetCard title={useMyMemo('本周销售')} compared={useMyMemo(weekCompare)} type={useMyMemo("周")} finsh={useMyMemo(weekPay)} target={1} timePercent={100} lastNumber={lastWeekPay} lastTitle={useMyMemo('上周销售')}/>
                <MemoTargetCard showSetting compared={useMyMemo(monthCompare)} settingClick={useMyMemo(() => controlSetting('支付'), '支付')} title={useMyMemo('本月销售')} lastTitle={useMyMemo('去年同月销售')} type={useMyMemo("月")} timePercent={useMyMemo(nowInMonth / monthDays)} finsh={useMyMemo(monthPay)} target={useMyMemo(monthTarget)} lastNumber={lastMonthPay} />
                <MemoTargetCard showSetting compared={useMyMemo(yearCompare)} settingClick={useMyMemo(() => controlSetting('支付'), '支付')} title={useMyMemo('本年销售')} lastTitle={useMyMemo('去年销售')} type={useMyMemo("年")} timePercent={useMyMemo(nowInyear / yearDays)} finsh={useMyMemo(yearPay)} target={useMyMemo(yearTarget)}  lastNumber={lastYearPay} />
                <MemoTargetCard showSetting compared={useMyMemo(monthGetCompare)} settingClick={useMyMemo(() => controlSetting('实销'), '实销')} title={useMyMemo('本月实销')} lastTitle={useMyMemo('去年同月实销')} type={useMyMemo("月")} timePercent={useMyMemo(nowInMonth / monthDays)} finsh={useMyMemo(monthGetMoney)} target={useMyMemo(monthGetTarget)}  lastNumber={lastmonthGetMoney} />
                <MemoTargetCard showSetting compared={useMyMemo(yearGetCompare)} settingClick={useMyMemo(() => controlSetting('实销'), '实销')} title={useMyMemo('本年实销')} lastTitle={useMyMemo('去年实销')} type={useMyMemo("年")} timePercent={useMyMemo(nowInyear / yearDays)} finsh={useMyMemo(yearGetMoney)} target={useMyMemo(yearGetTarget)}  lastNumber={lastYearGetMoney} />
                <MemoTargetCard compared={useMyMemo(monthRefundRateCompare)} settingClick={useMyMemo(() => controlSetting('退款率'), '退款率')} title={useMyMemo('本月退款率')} lastTitle={useMyMemo('上月退款')} type={useMyMemo("月")} timePercent={useMyMemo(nowInMonth / monthDays)} finsh={useMyMemo(monthRefundRate)} target={useMyMemo(monthRefundRateTarget)} lastNumber={lastMonthRefund}  />
            </div>
            <div className={style.DataArea}>
                <div>
                    <div className={style.controlBar}>
                        <div className={style.controlBarLeft}>
                            <Title level={3} style={{ margin: 0 }} >经营数据看板</Title>
                            {/* <Text style={{ lineHeight: '33px' }}>
                            {`统计日期 ${startDate.format('YYYY-MM-DD')} ~ ${endDate.format('YYYY-MM-DD')}`}
                        </Text> */}
                        </div>
                        <div className={style.controlBarRight}>
                            <Modal
                                title={`设置${targetType}目标`}
                                visible={setting}
                                // onClose={controlSetting}
                                onCancel={controlSetting}
                                footer={targetFooter}
                                height={'50vh'}
                                placement="top"
                                width={800}
                            >

                                <Form
                                    {...layout}
                                    form={targetForm}
                                    style={{ height: "fit-content", overflowY: "auto" }}
                                >
                                    <Row>
                                        <Col style={{ height: 45 }} span={24} >
                                            <Form.Item
                                                labelCol={{ span: 2 }}
                                                wrapperCol={{ span: 10 }}
                                                label="年份"
                                                name={'year'}
                                            >
                                                <DatePicker defaultValue={targetYear} value={targetYear} onChange={yearChange} picker="year" />
                                            </Form.Item>
                                        </Col>
                                        {formList.map((item, index) => {
                                            return (
                                                <Col style={{ height: 60 }} span={12}>
                                                    <Form.Item
                                                        label={item.label}
                                                        name={item.name}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Form>
                            </Modal>
                            <Popover placement="bottom" title={"配置经营数据看板"} content={settingContent} trigger="click">
                                <Button style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}><SettingOutlined /></Button>
                            </Popover>
                            <RangePicker
                                ranges={{
                                    '今天': [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }}
                                locale={locale}
                                style={{ height: 28, width: 240 }}
                                value={[startDate, endDate]}
                                format="YYYY-MM-DD"
                                onChange={dateChange}
                            />
                            <Tooltip placement="bottomLeft" title="选择今日" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateTypeChange('day')}
                                >
                                    <Text>日</Text>
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="选择本周" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateTypeChange('week')}
                                >
                                    <Text>周</Text>
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="选择本月" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateTypeChange('month')}
                                >
                                    <Text>月</Text>
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="周期减1" >
                                <Button
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                    onClick={() => dateCycleChange('sub')}
                                >
                                    <LeftOutlined style={{ lineHeight: "10px", fontSize: 12 }} />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="bottomLeft" title="周期加1" >
                                <Button
                                    onClick={() => dateCycleChange('add')}
                                    style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}>
                                    <RightOutlined style={{ lineHeight: "10px", fontSize: 12 }} />
                                </Button>
                            </Tooltip>
                            {/*  onClick={getTableData} */}
                            <Button style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }} type="primary" onClick={() => {
                                const getData = async () => {
                                    await Promise.all([
                                        getTableHeaderData(),
                                        getTableData()
                                    ])
                                }
                                getData()
                            }}>查询</Button>
                            {/* <Button onClick={getTableData} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}><SyncOutlined /></Button>
                        <Button onClick={getTimeRangesaleStatistical} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}><SyncOutlined /></Button> */}
                        </div>
                    </div>
                    <div className={style.indicatorCardRow} style={{ display: overallData ? '' : 'none' }}>
                        <MemoIndicatorCard title={useMyMemo('销售额')} value={useMyMemo(saleMoney)} compare={useMyMemo(saleMoneyCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} titleKey={'销售额'} />
                        <MemoIndicatorCard title={useMyMemo(`退款金额(${refundRate})`)} titleKey={'退款金额'} value={useMyMemo(refundMoney)} compare={useMyMemo(refundMoneyCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('实销金额')} titleKey={'实销金额'} value={useMyMemo(actualPay)} compare={useMyMemo(actualPayCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('访客数')} titleKey={'访客数'} value={useMyMemo(visitor)} compare={useMyMemo(visitorCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo(`加购件数(${addPieceRate})`)} titleKey={'加购件数'} value={useMyMemo(addPiece)} compare={useMyMemo(addPieceCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo(`加购人数(${goodsAddPeopleRate})`)} titleKey={'加购人数'} value={useMyMemo(`${goodsAddPeople}`)} compare={useMyMemo(goodsAddPeopleCompare)} iconStatus={useMyMemo('down')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('人均加购件数')} titleKey={'人均加购件数'} value={useMyMemo(perAdd)} compare={useMyMemo(perAddCompare)} iconStatus={useMyMemo('down')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('支付件数')} titleKey={'支付件数'} value={useMyMemo(payPiece)} compare={useMyMemo(payPieceCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('支付人数')} titleKey={'支付人数'} value={useMyMemo(payPeople)} compare={useMyMemo(payPeopleCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                        <MemoIndicatorCard title={useMyMemo('转化率')} titleKey={'转化率'} value={useMyMemo(changeRate)} compare={useMyMemo(changeRateCompare)} iconStatus={useMyMemo('up')} indicatorCardList={indicatorCardList} />
                    </div>
                </div>
                <Spin spinning={tableLoading} >
                    <Table
                        title={() => header}
                        scroll={{ x: 1400 }}
                        columns={columns}
                        dataSource={data}
                        rowClassName={style.tableRow}
                        pagination={paginationProps}
                        rowKey={record => record.goods_id}
                        rowSelection={rowSelection}
                        onChange={tableChange}
                    // bordered
                    />
                </Spin>
            </div>
        </div>
    )

    // 控制单元格的渲染时机
    function shouldCellUpdate(record, prevRecord) {
        return false
    }

    // 改变指定字段的排序状态
    function changeSortField(sortType) {
        // 如果
        if (sort === 'ASC') {
            return 'ascend'
        } else if (sort === 'DESC') {
            return 'descend'
        } else {
            return false
        }
    }

    // 表格变化时
    function tableChange(pagination, filters, sorter, extra) {
        setSortField(sorter.field)
        let order = sorter.order
        if (order === 'ascend') {
            setSort('ASC')
        } else if (order === 'descend') {
            setSort('DESC')
        } else {
            setSort(false)
        }
    }

    // 搜索词变化
    function searchChange(e) {
        let value = e.target.value
        value.replace(' ')
        setKeywords(value)
    }

    // 搜索确认时
    function searchEnter() {
        if (page !== 1) {
            setPage(1)
        } else {
            getTableData()
        }
        setSelectedRowKeys([])
    }

    // 切换是否查看排除商品
    function tableLookExclude(e) {
        setSelectedRowKeys([])
        setKeywords('')
        setPage(1)
        setLookExclude(e.target.value)
    }

    // 将排除的商品重新加入计算
    function setExcludeGoodsIdFailure() {
        let data = {}
        data.create_man = cookie.load('name')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.shop = shopName
        data.goods_list = selectedRowKeys
        setAddLoading(true)
        NbAxios(data, 'POST', servicePath.setExcludeGoodsIdFailure).then(res => {
            if (res.success) {
                message.success('操作成功，正在为您重新计算！')
                setSelectedRowKeys([])
                // 加入计算后重新获取计算数据
                getTimeRangesaleStatistical()
                getTableData()
            } else {
                message.error(res.errorMsg)
            }
            setAddLoading(false)
        }).catch(e => {
            message.error('出现错误!')
            setAddLoading(false)
        })
    }

    // 排除指定商品方法
    function setExcludeGoodsId() {
        let data = {}
        data.create_man = cookie.load('name')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.shop = shopName
        data.goods_list = selectedRowKeys
        setExcludeLoading(true)
        NbAxios(data, 'POST', servicePath.setExcludeGoodsId).then(res => {
            if (res.success) {
                message.success('操作成功，正在为您重新计算！')
                setSelectedRowKeys([])
                // 加入计算后重新获取计算数据
                getTimeRangesaleStatistical()
            } else {
                message.error(res.errorMsg)
            }
            setExcludeLoading(false)
        }).catch(e => {
            setExcludeLoading(false)
            message.error('出现错误!')
        })
    }

    // 获得表头数据
    function getTableHeaderData() {
        let data = {}
        data.start_date = startDate.format('YYYY-MM-DD')
        data.end_date = endDate.format('YYYY-MM-DD')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.shop_name = shopName
        data.last_start_date = moment(startDate).subtract(1, dateType).format('YYYY-MM-DD')
        data.last_end_date = moment(endDate).subtract(1, dateType).format('YYYY-MM-DD')
        return NbAxios(data, 'POST', servicePath.getGoodsTotalData).then(res => {
            if (res.success) {
                let data = res.data
                new Promise((re, rj) => {
                    setSaleMoney(data.销售额)
                    setSaleMoneyCompare(data.销售额同比)
                    re(true)
                }).then(() => {
                    setRefundMoney(data.退款金额)
                    setRefundMoneyCompare(data.退款金额同比)
                    setRefundRate(data.退款率)
                }).then(() => {
                    setChangeRate(data.转化率)
                    setChangeRateCompare(data.转化率同比)
                }).then(() => {
                    setVisitor(data.访客数)
                    setVisitorCompare(data.访客数同比)
                }).then(() => {
                    setPayPiece(data.支付件数)
                    setPayPieceCompare(data.支付件数同比)
                    setPayPeople(data.支付人数)
                    setPayPeopleCompare(data.支付人数同比)
                }).then(() => {
                    setActualPay(data.实销金额)
                    setActualPayCompare(data.实销金额同比)
                    setAddPiece(data.加购件数)
                    setAddPieceRate(data.加购件数加购率)
                    setAddPieceCompare(data.加购件数同比)
                }).then(() => {
                    setGoodsAddPeople(data.商品加购人数)
                    setGoodsAddPeopleCompare(data.商品加购人数同比)
                    setGoodsAddPeopleRate(data.商品加购人数加购率)
                    setPerAdd(data.人均加购件数)
                    setPerAddCompare(data.人均加购件数同比)
                })
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获得表格数据
    function getTableData() {
        let data = {}
        data.start_date = startDate.format('YYYY-MM-DD')
        data.end_date = endDate.format('YYYY-MM-DD')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.look_exclude = lookExclude
        data.shop_name = shopName
        data.page = page
        data.page_size = pageSize
        data.keywords = keywords
        data.sort_field = sortField
        data.sort = sort
        data.goods_id_list = goodsIdList
        setTableLoading(true)
        NbAxios(data, 'POST', servicePath.getGoodsTableTotalLength).then(res => {
            if (res.success) {
                setTotal(res.total)
            } else {
                setData([])
            }
        })
        return NbAxios(data, 'POST', servicePath.getGoodsTableData).then(res => {
            if (res.success) {
                setData(res.data)
            } else {
                setData([])
                message.warn(res.errorMsg)
            }
            setTableLoading(false)
        }).catch(e => {
            setTableLoading(false)
        })
    }

    // 周期加减函数
    function dateCycleChange(type) {
        if (type === 'add') {
            setStartDate(moment(startDate).add(1, dateType).startOf(dateType))
            setEndDate(moment(startDate).add(1, dateType).endOf(dateType))
        } else if (type === 'sub') {
            setStartDate(moment(startDate).subtract(1, dateType).startOf(dateType))
            setEndDate(moment(startDate).subtract(1, dateType).endOf(dateType))
        }
    }

    // 设置日期框选择周期
    function dateTypeChange(type) {
        setDateType(type)
        switch (type) {
            case 'day':
                setStartDate(moment())
                setEndDate(moment())
                break
            case 'week':
                setStartDate(moment().startOf('week'))
                setEndDate(moment().endOf('week'))
                break
            case 'month':
                setStartDate(moment().startOf('month'))
                setEndDate(moment().endOf('month'))
                break
            default:
                break;
        }
    }

    // 目标年份改变
    function yearChange(date, dateString) {
        if (!date) {
            message.warn('时间不可以为空！')
            setTargetYear(moment())
            return false
        } else {
            setTargetYear(date)
            getTargetData(date, targetType)
        }
    }

    // 获取看板头部周，月，年统计值
    function getTimeRangesaleStatistical() {
        let data = {}
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        // 今年年份
        data.year = moment().format('YYYY')
        // 今年月份
        data.month = moment().format('M')
        // 今年和去年同天日期
        data.day = moment().format('YYYY-MM-DD')
        data.last_day = moment().subtract(1, 'year').format('YYYY-MM-DD')
        // 计算年的日期，去年
        data.year_start = moment().format('YYYY-01-01')
        data.last_year_start = moment().subtract(1, 'year').format('YYYY-01-01')
        // 计算月日期，去年同月
        data.month_start = moment().format('YYYY-MM-01')
        data.last_month_start = moment().subtract(1, 'year').format('YYYY-MM-01')
        // 计算周日期,上周
        data.week_start = moment().startOf('week').format('YYYY-MM-DD')
        data.last_week = moment().subtract(1, 'week').format('YYYY-MM-DD')
        data.last_week_start = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD')
        // data.shop_name = shopName
        data.shopId = shopIdDict[props.location.pathname.split('/')[3]]
        return NbAxios(data, 'POST', servicePath.getSalesStatisticsData).then(res => {
            if (res.success) {
                let data = res.data
                setMonthRefundRate(data.current_month_refund_rate)
                setMonthRefundRateCompare(data.current_month_refund_rate_compare)
                setWeekPay(data.week_pay)
                setLastWeekPay(data.last_week_pay)
                setWeekCompare(data.week_compare)
                setMonthPay(data.month_pay)
                setLastMonthPay(data.last_month_pay)
                setMonthCompare(data.month_compare)
                setMonthTarget(data.month_target)
                setYearPay(data.year_pay)
                setLastYearPay(data.last_year_pay)
                setYearCompare(data.year_compare)
                setYearTarget(data.year_target)
                setYearGetTarget(data.year_get_target)
                setyearGetMoney(data.year_get_money)
                setLastYearGetMoney(data.last_year_get_money_number)
                setYearGetCompare(data.year_get_money_compare)
                setMonthGetTarget(data.month_get_target)
                setLastMonthGetMoney(data.last_month_get_money_number)
                setMonthGetMoney(data.month_get_money)
                setMonthGetCompare(data.month_get_money_compare)
                setLastMonthRefund(data.last_cgtkje)
            }
        })
    }

    // 提交目标
    function submitTarget() {
        let value = targetForm.getFieldsValue()
        value.year = targetYear.format('YYYY')
        value.create_man = cookie.load('name')
        value.role = cookie.load('role')
        value.shop_list = cookie.load('shopList')
        value.shop = shopName
        value.target_type = targetType
        for (let key in value) {
            if (targetMonths.indexOf(parseInt(key)) != -1) {
                let patrn = /^[0-9]*[1-9][0-9]*$/
                if (!patrn.exec(value[key])) {
                    message.warn('请填写正整数')
                    return false
                }
                if (['', undefined].indexOf(value[key]) != -1) {
                    value[key] = 0
                }
            }
        }
        NbAxios(value, 'POST', servicePath.setSaleBorderTarget).then(res => {
            if (res.success) {
                message.success('目标设置成功!')
                setSetting(false)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.warn("设置目标出现错误！")
        })
    }
    // 打开关闭设置对话框
    function controlSetting(type) {
        setSetting(setting ? false : true)
        if (!setting) {
            setTargetType(type)
            getTargetData(targetYear, type)
        }
    }

    // 获取目标
    function getTargetData(year, type) {
        let data = {}
        data.year = year.format('YYYY')
        data.shop = shopName
        data.target_type = type
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        NbAxios(data, 'POST', servicePath.getTargetByYear).then(
            res => {
                if (res.success) {
                    let data = res.data
                    targetForm.setFieldsValue(data)
                } else {
                    message.warn(res.errorMsg)
                    let a = {}
                    for (let i = 0; i < targetMonths.length; i++) {
                        a[i + 1] = 0
                    }
                    targetForm.setFieldsValue(a)
                }
            }
        )
    }

    // 导出表格数据
    function exportTableData() {
        let data = {}
        data.start_date = startDate.format('YYYY-MM-DD')
        data.end_date = endDate.format('YYYY-MM-DD')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.shop_name = shopName
        setDownStatus(true)
        message.success('文件开始制作，请耐心等待...')
        Axios({
            method: 'POST',
            url: servicePath.getGoodsItemReport,
            data: data,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
        }).then(res => {
            downExcel(res)
            setDownStatus(false)
            message.success('文件下载成功！')
        }).catch(e => {
            message.error('文件制作出错，请联系it同事！')
        })
    }

    // 导出商品基础信息缺失导入模板
    function exportLostGoodsData() {
        let data = {}
        data.start_date = startDate.format('YYYY-MM-DD')
        data.end_date = endDate.format('YYYY-MM-DD')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.shop_name = shopName
        setDownStatus(true)
        message.success('文件开始制作，请耐心等待...')
        Axios({
            method: 'POST',
            url: servicePath.getGoodsItemLostExcel,
            data: data,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
        }).then(res => {
            downExcel(res)
            setDownStatus(false)
            message.success('文件下载成功！')
        }).catch(e => {
            message.error('文件制作出错，请联系it同事！')
        })
    }

    function downExcel(response) {
        // 提取文件名
        const fileName = response.headers['content-disposition']
        // 将二进制流转为blob
        const blob = new Blob([response.data])
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
            window.navigator.msSaveBlob(blob, decodeURI(fileName))
        } else {
            // 创建新的URL并指向File对象或者Blob对象的地址
            const blobURL = window.URL.createObjectURL(blob)
            // 创建a标签，用于跳转至下载链接
            const tempLink = document.createElement('a')
            tempLink.style.display = 'none'
            tempLink.href = blobURL
            tempLink.setAttribute('download', decodeURI(fileName))
            // 兼容：某些浏览器不支持HTML5的download属性
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank')
            }
            // 挂载a标签
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            // 释放blob URL地址
            window.URL.revokeObjectURL(blobURL)
        }
    }

    // 日期变化 
    function dateChange(dates, dateStrings) {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    // 控制单元格表头样式，去掉padding文字居左
    function onHeaderCell(record, rowIndex) {
        return (
            {
                style: {
                    padding: '0',
                    height: '30px',
                    background: '#ffffff',
                    borderTop: "1px solid #f6f6f6"
                }
            }
        )
    }
}

export default SaleBoard

