import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment'
import { Form, Row, message, Button, Col, DatePicker, Input, Select, Upload, Progress, Drawer, Avatar, Tag, Table, Menu, Dropdown, Modal, Tooltip, Card } from 'antd'
import { UploadOutlined, ClockCircleOutlined, MinusOutlined, PlusOutlined, createFromIconfontCN, DownOutlined, FieldTimeOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import BraftEditor from 'braft-editor'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = 'YYYY.MM.DD';


function Overview() {
    var colors = ['#5793f3', '#d14a61', '#675bba', '#00a8e1', '#e30039 '];
    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 任务完成率
    let finishingRate = {
        color: colors,
        title: {
            text: `任务完成率` + parseInt(20 / 22 * 100) + `%`,
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                labelLine: {
                    show: false
                },
                label: {
                    show: false,
                },

                data: [
                    { value: 20, name: '已完成' },
                    { value: 2, name: '待处理' },
                ]
            }
        ]
    }

    // 任务完成率
    let delayRate = {
        color: colors,
        title: {
            text: `任务延误率` + parseInt(2 / 22 * 100) + `%`,
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                labelLine: {
                    show: false
                },
                label: {
                    show: false,
                },

                data: [
                    { value: 20, name: '按时完成' },
                    { value: 2, name: '延误' },
                ]
            }
        ]
    }

    // 任务完成率
    let tagRate = {
        color: colors,
        title: {
            text: `紧要程度`,
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                labelLine: {
                    show: false
                },
                label: {
                    show: false,
                },

                data: [
                    { value: 20, name: '严重' },
                    { value: 10, name: '主要' },
                    { value: 6, name: '次要' },
                    { value: 2, name: '不重要' },
                ]
            }
        ]
    }

    // 任务类型
    let typeRate = {
        color: colors,
        title: {
            text: `任务类型`,
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                labelLine: {
                    show: false
                },
                label: {
                    show: false,
                },

                data: [
                    { value: 20, name: '严重' },
                    { value: 10, name: '主要' },
                    { value: 6, name: '次要' },
                    { value: 2, name: '不重要' },
                ]
            }
        ]
    }

    // 燃尽图
    let burnDown = {
        title: {
            text: '燃尽图'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: ['实际剩余', '计划剩余']
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
        {
            name: '实际剩余',
            data: [26, 22, 18, 14, 13, 11, 9],
            type: 'line',
        }, {
            name: '计划剩余',
            data: [26, 24, 22, 20, 18, 16, 14],
            type: 'line',
        }]
    }

    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('finishingreport'));
        // 绘制图表
        reportChart.setOption(finishingRate);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [finishingRate])

    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('delayreport'));
        // 绘制图表
        reportChart.setOption(delayRate);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [delayRate])

    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('tagreport'));
        // 绘制图表
        reportChart.setOption(tagRate);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [tagRate])

    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('burnDown'));
        // 绘制图表
        reportChart.setOption(burnDown);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [burnDown])

    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('typereport'));
        // 绘制图表
        reportChart.setOption(typeRate);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [typeRate])


    return (
        <div style={{ paddingBottom: size.height * 0.1 }}>
            <div style={{ marginLeft: '100px', marginTop: '5px' }}>
                <Row>
                    <Col style={{ fontWeight: 'bold', fontSize: '15px', marginRight: '20px' }}>
                        请选择项目:
                    </Col>
                    <Col>
                        <Select defaultValue="福来海外旗舰店" style={{ width: 320 }} onChange={(value) => console.log(value)}>
                            <Option value="福来海外旗舰店">福来海外旗舰店</Option>
                            <Option value="卓雅天猫旗舰店">卓雅天猫旗舰店</Option>
                        </Select>
                    </Col>
                </Row>

            </div>
            <div>
                <Card title="福来海外旗舰店" style={{ marginLeft: '100px', marginRight: '82px' }}>
                    <Row>
                        <Col span={2}>
                          
                        </Col>
                        <Col span={4}>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>今日新增任务</div>
                                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>10</div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>今日新完成任务</div>
                                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>2</div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>任务(未完成/完成)</div>
                                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>6/20</div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>成员人数</div>
                                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>4</div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>本月目标完成</div>
                                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>80%</div>
                            </div>
                        </Col>
                        <Col span={2}>
                          
                        </Col>
                    </Row>
                </Card>
            </div>
            <div style={{ marginLeft: '100px', height: '400px', marginRight: '50px', marginTop: '10px' }}>
                <Row>
                    <Col span={13} style={{backgroundColor: 'white', marginRight:'20px' }}>
                        <div id="burnDown" style={{ width: size.width * 0.40, height: 400 }}></div>
                    </Col>
                    <Col span={10} style={{ height: '400px', backgroundColor: 'white' }}>
                        <div style={{ height: '50%' }}>
                            <Row>
                                <Col span={12}>
                                    <div id="finishingreport" style={{ width: size.width * 0.20, height: 200 }}></div>
                                </Col>
                                <Col span={12}>
                                    <div id="delayreport" style={{ width: size.width * 0.20, height: 200 }}></div>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ height: '50%' }}>
                           <Row>
                                <Col span={12}>
                                    <div id="tagreport" style={{ width: size.width * 0.20, height: 200 }}></div>
                                </Col>
                                <Col span={12}>
                                    <div id="typereport" style={{ width: size.width * 0.20, height: 200 }}></div>
                                </Col>
                           </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

export default Overview