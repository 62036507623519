import React, { useEffect } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import Debounce from 'lodash/debounce'


function LineChart({
    id,
    title,
    legend = [],
    data = [],
    height,
    date = [],
    maxTemp=[],
    minTemp=[],
    dateTitle = "",
    ...rest
}){
    let report = {
        title: {
            text: title,
            left: 'center',
            textStyle:{
                fontSize:20
            }
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              // prettier-ignore
              data: date,
              axisLabel:{ 
                rotate : 60 
              }
            }
        ],
        yAxis: [
            {
                type: 'value',
                position: 'left',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                position: 'right',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: dateTitle,
                type: 'bar',
                data: data
              },
              {
                name: '最高温度',
                type: 'line',
                yAxisIndex: 1,
                data: maxTemp
              },
              {
                name: '最低温度',
                type: 'line',
                yAxisIndex: 1,
                data: minTemp
              }
        ]
    }
    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById(id));
        reportChart.clear()
        // 绘制图表
        reportChart.setOption(report)
        // window捕获标签点击，表格重绘放到浏览器渲染前
        window.addEventListener('click', e => {
            if (e.target.getAttribute("class") === "ant-tabs-tab-btn") {
                requestAnimationFrame(() =>reportChart.resize({
                        animation:{duration:300,easing:"linear",delay:1}
                    })
                )
            }
        }, true);
        window.addEventListener('resize', Debounce(() => reportChart.resize(), 100));
        return () => {
            // window捕获标签点击，表格重绘放到浏览器渲染前
            window.addEventListener('click', e => {
                if (e.target.getAttribute("class") === "ant-tabs-tab-btn") {
                    reportChart.clear()
                    requestAnimationFrame(() =>reportChart.resize({
                            animation:{duration:300,easing:"linear",delay:1}
                        })
                    )
                }
            }, true);
            window.removeEventListener('resize', Debounce(() => reportChart.resize(), 100));
        }
    }, [data,minTemp])

    return (
        <div id={id} style={{ width: '100%', height: height || "400px" }} {...rest} ></div>
    )
}

export default LineChart