import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, DatePicker, Input, Descriptions, Form, Select, Steps, Collapse, Spin } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import axios from 'axios'
import moment from 'moment';
import '../../static/css/Table.css'
import '../../static/css/index.css'
const { Panel } = Collapse
const { Step } = Steps;
const { Option } = Select
const monthFormat = 'YYYY-MM-DD';

//营销管理
function Marketing(props) {

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const [form1] = Form.useForm()

    //页面
    const [page, setPage] = useState(1)

    // 总数页数
    const [total, setTotal] = useState(0)

    //数据
    const [data, setData] = useState([])

    // 合作业务
    const [businessList] = useState(
        [
            "全案营销",
            "年框全案",
            "casebyc",
            "ase",
            "kol/主播",
            "单次合作",
        ]
    )

    //合作状态
    const [statusList] = useState(
        [
            "未激活",
            "洽谈中",
            "合作中",
            "合作结束"
        ]
    )

    //甲方名称
    const [partyList, setPartyList] = useState([])

    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])

    //id
    const [id, setId] = useState(0)

    // 老数据
    const [oldDate, setOldDate] = useState([])

    //甲方
    const [party, setParty] = useState('')

    //甲方id
    const [partyId, setPartyId] = useState(-1)

    // 品牌
    const [trademark, setTrademark] = useState('')

    //产品
    const [product, setProduct] = useState('')

    //合作业务
    const [business, setBussinuss] = useState('')

    //合作状态
    const [status, setStatus] = useState('')

    //合作开始时间
    const [startTime, setStartTime] = useState('')

    //合作结束时间
    const [endTime, setEndTime] = useState('')

    //结账日期
    const [accounsTime, setAccounsTime] = useState('')

    //预算
    const [budget, setBudget] = useState('')

    //费用
    const [cost, setCost] = useState('')

    //毛利
    const [gross, setGross] = useState('')

    //负责人
    const [principal, setPrincipal] = useState('')

    //业务联系电话
    const [phone, setPhone] = useState('')

    //营销名称
    const [marketingName, setMarketingName] = useState('')

    //创建人
    const [creator, setCreator] = useState('')

    //当前位置
    const [current, setCurrent] = useState(-1)

    //状态1
    const [status1, setStatus1] = useState('wait')

    //状态2
    const [status2, setStatus2] = useState('wait')

    //状态3
    const [status3, setStatus3] = useState('wait')


    //状态4
    const [status4, setStatus4] = useState('wait')

    //是否增加营销信息
    const [addMarketing, setAddMarketing] = useState(false)

    //是否更新营销信息
    const [updateMarketing, setUpdateMarketing] = useState(false)

    //是否查看明细
    const [lookDetail, setLookDetail] = useState(false)

    // 搜索营销名称
    const [searchName, setSearchName] = useState('')

    // 搜索甲方名称
    const [searchParty, setSearchParty] = useState('')

    // 是否是检索状态
    const [isSearch, setIsSearch] = useState(0)

    // 是否是加载状态
    const [isLoading, setIsLoading] = useState(false)


    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //获取权限代码
    useEffect(() => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    }, [])

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    //表格属性
    const columns = [
        {
            title: "营销名称",
            key: 'name',
            dataIndex: 'name',
            width: '200px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: "甲方名称",
            key: 'party',
            dataIndex: 'party',
            width: '200px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: "品牌",
            key: 'trademark',
            dataIndex: 'trademark',
            width: '200px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '客户产品',
            key: 'product',
            dataIndex: 'product',
            width: '200px',
            align: 'center',
            fixed: 'left',
        }, {
            title: '负责人',
            key: 'principal',
            dataIndex: 'principal',
            width: '200px',
            align: 'center'
        },
        {
            title: '创建人',
            key: 'creator',
            dataIndex: 'creator',
            width: '200px',
            align: 'center'
        },
        {
            title: "创建时间",
            key: 'createTime',
            dataIndex: 'createTime',
            width: '200px',
            align: 'center'
        },
        {
            title: '查看明细',
            key: 'search',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <SearchOutlined onClick={() => openDetail(index)} />
            ),
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            colSpan: jurisdiction.indexOf("kol::updateMarketing") != -1 ? 1 : 0,
            render: (text, record, index) => (
                <EditOutlined style={{ display: jurisdiction.indexOf("kol::updateMarketing") != -1 ? "" : "none" }} onClick={() => openUpdate(index)} />
            ),
        },
    ]

    //获取甲方信息数据
    useEffect(() => {
        getAllParty()
    }, [])

    const getAllParty = () => {
        let dataProps = {
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role')
        }
        axios({
            method: 'POST',
            url: servicePath.getNorParty,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setPartyList(res.data['data']);
                } else {
                    // 这里是获取初始化数据，不用提示
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    //获取所有的营销信息
    useEffect(() => {
        getData()
    }, [page])

    // 获取数据
    const getData = () => {
        if (isSearch == 0) {
            getAllMarketing()
        } else {
            search()
        }
    }

    const getAllMarketing = () => {
        setIsLoading(true)
        let dataProps = {
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role'),
            'page': page
        }
        axios({
            method: 'POST',
            url: servicePath.getMarketing,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setData(res.data['data'])
                    setTotal(res.data['total'])
                    setIsLoading(false)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            }
        )
    }

    //确认添加
    const addOk = () => {
        if (marketingName == '') {
            message.error('营销名字不能为空')
        } else if (partyId == '') {
            message.error('甲方必选')
        } else if (trademark == '') {
            message.error('品牌必填')
        } else if (product == '') {
            message.error('客户产品不能为空')
        } else if (business == '') {
            message.error('合作业务必选')
        } else if (status == '') {
            message.error('合作状态必选')
        } else if (budget == '') {
            message.error('预算不能为空')
        } else if (principal == '') {
            message.error('负责人不能为空')
        } else if (phone == '') {
            message.error('业务联系电话不能为空')
        } else {
            let dataProps = {
                "shopId": cookie.load('shopList'),
                'roleId': cookie.load('role'),
                'name': marketingName,
                'party_id': partyId,
                'trademark': trademark,
                'product': product,
                'status': status,
                'business': business,
                'start_time': startTime,
                'end_time': endTime,
                'accouns_time': accounsTime,
                "budget": budget,
                'cost': cost,
                "gross": gross,
                'principal': principal,
                'phone': phone,
                "operator": cookie.load('name'),
            }
            axios({
                method: 'POST',
                url: servicePath.addMarketing,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json', //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success('成功增加营销方案')
                        setMarketingName('')
                        setPartyId(-1)
                        setTrademark('')
                        setProduct('')
                        setBussinuss('')
                        setStatus('')
                        setStartTime('')
                        setEndTime('')
                        setAccounsTime('')
                        setBudget('')
                        setCost('')
                        setGross('')
                        setPrincipal('')
                        setPhone('')
                        form.resetFields()
                        getData()
                        setAddMarketing(false)
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
    }

    //取消添加
    const addCancel = () => {
        message.success('取消增加营销方案')
        setMarketingName('')
        setPartyId(-1)
        setProduct('')
        setTrademark('')
        setBussinuss('')
        setStatus('')
        setStartTime('')
        setEndTime('')
        setAccounsTime('')
        setBudget('')
        setCost('')
        setGross('')
        setPrincipal('')
        setPhone('')
        form.resetFields()
        setAddMarketing(false)
    }

    //  打开更新弹框
    const openUpdate = (index) => {
        //根据页改变下标
        form.resetFields()
        setOldDate(data[index])
        setId(data[index]['id'])
        setPartyId(data[index]['party_id'])
        setParty(data[index]['party'])
        setProduct(data[index]['product'])
        setTrademark(data[index]['trademark'])
        setMarketingName(data[index]['name'])
        setBussinuss(data[index]['business'])
        setStatus(data[index]['marketing_status'])
        setStartTime(data[index]['start_time'])
        setEndTime(data[index]['end_time'])
        setAccounsTime(data[index]['accouns_time'])
        setBudget(data[index]['budget'])
        setCost(data[index]['cost'])
        setGross(data[index]['gross'])
        setPrincipal(data[index]['principal'])
        setPhone(data[index]['phone'])
        setCreator(data[index]['creator'])
        setUpdateMarketing(true)
    }

    // 确认更新
    const updateOk = () => {
        let dataProps = {
            'id': id,
            "oldDate": oldDate,
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role'),
            'name': marketingName,
            'party_id': partyId,
            'trademark': trademark,
            'product': product,
            'business': business,
            'start_time': startTime,
            'end_time': endTime,
            'accouns_time': accounsTime,
            "budget": budget,
            'cost': cost,
            "gross": gross,
            'principal': principal,
            'phone': phone,
            'marketing_status': status,
            "operator": cookie.load('name'),
        }
        axios({
            method: 'POST',
            url: servicePath.updateMarketing,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    message.success('成功修改营销方案')
                    setId(-1)
                    setOldDate([])
                    setMarketingName('')
                    setPartyId(-1)
                    setProduct('')
                    setBussinuss('')
                    setTrademark('')
                    setStatus('')
                    setStartTime('')
                    setEndTime('')
                    setAccounsTime('')
                    setBudget('')
                    setCost('')
                    setGross('')
                    setPrincipal('')
                    setPhone('')
                    form.resetFields()
                    getData()
                    setUpdateMarketing(false)
                } else {
                    if (res.data['errorCode'] == '401') {
                        message.error(res.data['errorMsg'])
                        setId(-1)
                        setOldDate([])
                        setMarketingName('')
                        setPartyId(-1)
                        setProduct('')
                        setBussinuss('')
                        setTrademark('')
                        setStatus('')
                        setStartTime('')
                        setEndTime('')
                        setAccounsTime('')
                        setBudget('')
                        setCost('')
                        setGross('')
                        setPrincipal('')
                        setPhone('')
                        form.resetFields()
                        getData()
                        setUpdateMarketing(false)
                    } else if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )

    }

    //取消更新
    const updateCancel = () => {
        message.success('取消修改营销方案')
        setId(-1)
        setOldDate([])
        setMarketingName('')
        setPartyId(-1)
        setTrademark('')
        setProduct('')
        setBussinuss('')
        setStatus('')
        setStartTime('')
        setEndTime('')
        setAccounsTime('')
        setBudget('')
        setCost('')
        setGross('')
        setPrincipal('')
        setPhone('')
        form.resetFields()
        setUpdateMarketing(false)
    }

    //打开查看明细
    const openDetail = (index) => {
        //根据页改变下标
        setBussinuss(data[index]['business'])
        setStatus(data[index]['marketing_status'])
        setStartTime(data[index]['start_time'])
        setEndTime(data[index]['end_time'])
        setAccounsTime(data[index]['accouns_time'])
        setBudget(data[index]['budget'])
        setCost(data[index]['cost'])
        setGross(data[index]['gross'])
        setPrincipal(data[index]['principal'])
        setPhone(data[index]['phone'])
        setLookDetail(true)
        // 状态属性设置
        if (data[index]['marketing_status'] == '未激活') {
            setCurrent(0)
            setStatus1('process')
        } else if (data[index]['marketing_status'] == '洽谈中') {
            setCurrent(1)
            setStatus1('finish')
            setStatus2('process')
        } else if (data[index]['marketing_status'] == '合作中') {
            setCurrent(2)
            setStatus1('finish')
            setStatus2('finish')
            setStatus3('process')
        } else if (data[index]['marketing_status'] == '合作结束') {
            setCurrent(3)
            setStatus1('finish')
            setStatus2('finish')
            setStatus3('finish')
            setStatus4('process')
        }
    }

    // 关闭查看明细
    const closeDetail = () => {
        setStatus1('wait')
        setStatus2('wait')
        setStatus3('wait')
        setStatus4('wait')
        setCurrent(-1)
        setMarketingName('')
        setBussinuss('')
        setStatus('')
        setStartTime('')
        setEndTime('')
        setAccounsTime('')
        setBudget('')
        setCost('')
        setGross('')
        setPrincipal('')
        setPhone('')
        setLookDetail(false)
    }

    //打开新增框架
    const openAdd = () => {
        setAddMarketing(true);
        setStartTime(moment().format("YYYY-MM-DD"));
        setEndTime(moment().add('30', 'day').format("YYYY-MM-DD"))
        setAccounsTime(moment().add('30', 'day').format("YYYY-MM-DD"))
    }

    // 条件查询
    const search = () => {
        // 修改isSearch的值,说明是搜索状态
        setIsSearch(1)
        // 出现加载
        setIsLoading(true)

        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            'name': searchName,
            "party": searchParty,
            "page": page,
        }
        axios({
            method: 'POST',
            url: servicePath.searchMarketing,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setData(res.data['data'])
                    setTotal(res.data['total'])
                    setIsLoading(false)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 重置
    const reset = () => {
        // 数据清空
        setSearchName('')
        setSearchParty('')
        // 表单清空
        form1.resetFields();
        // 关闭搜索状态
        setIsSearch(0)
        // 将当前页设置为第一页
        setPage(1)
        // 如果当前页面是1的话。手动请求数据
        if (page == 1) {
            getAllMarketing()
        }
    }



    return (
        <div>
            <Spin spinning={isLoading} size="large" tip="数据请求中....." style={{ marginTop: size.height * 0.3 }}>
                {/* 按钮区域 */}
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06 }}>
                    <Row>
                        <Col span={3}>
                            <Button style={{ display: jurisdiction.indexOf("kol::addMarketing") != -1 ? "" : "none" }} type="primary" onClick={() => openAdd()}>新增营销信息</Button>
                        </Col>
                    </Row>
                </div>
                <Collapse style={{ marginRight: size.width * 0.06, marginLeft: size.width * 0.06, marginTop: size.height * 0.02 }}>
                    <Panel header="检索条件">
                        <Form
                            form={form1}
                            labelAlign='left'
                            style={{
                                padding: 24,
                                background: '#fbfbfb',
                            }}
                        >
                            <Row gutter={16}>
                                <Col span={5}>
                                    <Form.Item
                                        name="name"
                                        label="营销名称"
                                    >
                                        <Input
                                            placeholder="请输入营销名称"
                                            value={searchName}
                                            onChange={(e) => {
                                                setSearchName(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        name="type"
                                        label="甲方名称"
                                    >
                                        <Input
                                            placeholder="请输入甲方名称"
                                            value={searchParty}
                                            onChange={(e) => {
                                                setSearchParty(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Button type="primary"
                                        onClick={() => {
                                            setPage(1)
                                            setTimeout(search(), 2000)
                                        }}
                                        style={{ marginRight: "10px" }}>检索</Button>
                                    <Button type="primary" onClick={() => reset()} danger>重置</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06 }}>
                    {/* 表单区域 */}
                    <Table
                        //表格列的配置描述
                        columns={columns}
                        //数据数组
                        dataSource={data}
                        pagination={paginationProps}
                        scroll={{ x:'80vw', y: `${size.width>1500?'70vh':'55vh'}` }}
                        //表格行 key 的取值
                        rowKey={'id'}
                    />
                </div>
            </Spin>
            {/* 增加营销信息 */}
            <Modal
                title="增加营销信息"
                visible={addMarketing}
                centered={true}
                onOk={addOk}
                onCancel={addCancel}
                width={1000}
                okText="确认添加"
                cancelText="取消添加"
            >
                <Form form={form} {...layout} labelAlign='left'>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="营销名称" name="marketingName">
                                {/* 填写营销名称 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写营销名称"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="marketingName"
                                        size="default"
                                        placeholder="填写营销名称"
                                        onChange={(e) => {
                                            setMarketingName(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="甲方名称" name="rank">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请选择甲方名称"
                                    placement="topLeft"
                                >
                                    <Select showSearch placeholder="请选择甲方名称" style={{ width: 310 }} onChange={(value) => setPartyId(partyList[partyList.map(item => item.name).indexOf(value)]['id'])}>
                                        {
                                            partyList.map(function (item) {
                                                return (
                                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="客户品牌" name="trademark">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写客户品牌"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="trademark"
                                        size="default"
                                        placeholder="填写客户品牌"
                                        onChange={(e) => {
                                            setTrademark(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="客户产品" name="product">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写客户产品"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="product"
                                        size="default"
                                        placeholder="填写客户产品"
                                        onChange={(e) => {
                                            setProduct(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="合作业务" name="business">
                                {/* 填写客户名称 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请选择合作业务"
                                    placement="topLeft"
                                >
                                    <Select placeholder="请选择合作业务" style={{ width: 310 }} onChange={(value) => setBussinuss(value)}>
                                        {
                                            businessList.map(function (item) {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合作状态" name="status">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请选择合作状态"
                                    placement="topLeft"
                                >
                                    <Select placeholder="请选择合作状态" style={{ width: 310 }} onChange={(value) => setStatus(value)}>
                                        {
                                            statusList.map(function (item) {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="合作开始时间" name="startTime">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写合作开始时间"
                                    placement="topLeft"
                                >
                                    <DatePicker defaultValue={moment()} placeholder="请填写合作开始时间" style={{ width: 310 }} onChange={(date, dateString) => { setStartTime(dateString) }} />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合作结束时间" name="endTime">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写合作结束时间"
                                    placement="topLeft"
                                >
                                    <DatePicker defaultValue={moment().add('30', 'day')} placeholder="请填写合作结束时间" style={{ width: 310 }} onChange={(date, dateString) => { setEndTime(dateString) }} />
                                </Tooltip>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="结账时间" name="accounts">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写结账日期"
                                    placement="topLeft"
                                >
                                    <DatePicker defaultValue={moment().add('30', 'day')} placeholder="请填写结账时间" style={{ width: 310 }} onChange={(date, dateString) => { setAccounsTime(dateString) }} />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="预算" name="budget">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写预算"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="budget"
                                        size="default"
                                        placeholder="请填写预算"
                                        onChange={(e) => {
                                            setBudget(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="费用" name="cost">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写费用"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="cost"
                                        size="default"
                                        placeholder="请填写费用"
                                        onChange={(e) => {
                                            setCost(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="毛利" name="gross">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写毛利"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="gross"
                                        size="default"
                                        placeholder="请填写毛利"
                                        onChange={(e) => {
                                            setGross(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="业务负责人" name="principal">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写业务负责人"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="principal"
                                        size="default"
                                        placeholder="请填写业务负责人"
                                        onChange={(e) => {
                                            setPrincipal(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="业务联络电话" name="phone">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写业务联络电话"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="phone"
                                        size="default"
                                        placeholder="请填写业务联络电话"
                                        onChange={(e) => {
                                            setPhone(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {/* 更新营销信息 */}
            <Modal
                title="更新营销信息"
                centered={true}
                visible={updateMarketing}
                onOk={updateOk}
                onCancel={updateCancel}
                width={1000}
                okText="确认更新"
                cancelText="取消更新"
            >
                <Form form={form} {...layout} labelAlign='left'>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="营销名称" name="marketingName">
                                {/* 填写营销名称 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写营销名称"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="marketingName"
                                        size="default"
                                        placeholder="填写营销名称"
                                        value={marketingName}
                                        onChange={(e) => {
                                            setMarketingName(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="甲方名称" name="rank">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请选择甲方名称"
                                    placement="topLeft"
                                >
                                    <Select defaultValue={party} showSearch placeholder="请选择甲方名称" style={{ width: 310 }} onChange={(value) => setPartyId(partyList[partyList.map(item => item.name).indexOf(value)]['id'])}>
                                        {
                                            partyList.map(function (item) {
                                                return (
                                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="客户品牌" name="trademark">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写客户品牌"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="product"
                                        size="trademark"
                                        value={trademark}
                                        placeholder="填写客户品牌"
                                        onChange={(e) => {
                                            setTrademark(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="客户产品" name="product">
                                {/* 填写客户行业 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="填写客户产品"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="product"
                                        size="default"
                                        value={product}
                                        placeholder="填写客户产品"
                                        onChange={(e) => {
                                            setProduct(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="合作业务" name="business">
                                {/* 填写客户名称 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请选择合作业务"
                                    placement="topLeft"
                                >
                                    <Select defaultValue={business} placeholder="请选择合作业务" style={{ width: 310 }} onChange={(value) => setBussinuss(value)}>
                                        {
                                            businessList.map(function (item) {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合作状态" name="status">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请选择合作状态"
                                    placement="topLeft"
                                >
                                    <Select defaultValue={status} placeholder="请选择合作状态" style={{ width: 310 }} onChange={(value) => setStatus(value)}>
                                        {
                                            statusList.map(function (item) {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Tooltip>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="合作开始时间" name="startTime">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写合作开始时间"
                                    placement="topLeft"
                                >
                                    <DatePicker defaultValue={moment(startTime, monthFormat)} placeholder="请填写合作开始时间" style={{ width: 310 }} onChange={(date, dateString) => { setStartTime(dateString) }} />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合作结束时间" name="endTime">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写合作结束时间"
                                    placement="topLeft"
                                >
                                    <DatePicker defaultValue={moment(endTime, monthFormat)} placeholder="请填写合作结束时间" style={{ width: 310 }} onChange={(date, dateString) => { setEndTime(dateString) }} />
                                </Tooltip>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="结账时间" name="accounts">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写结账日期"
                                    placement="topLeft"
                                >
                                    <DatePicker defaultValue={moment(accounsTime, monthFormat)} placeholder="请填写结账时间" style={{ width: 310 }} onChange={(date, dateString) => { setAccounsTime(dateString) }} />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="预算" name="budget">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写预算"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="budget"
                                        size="default"
                                        placeholder="请填写预算"
                                        value={budget}
                                        onChange={(e) => {
                                            setBudget(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="费用" name="cost">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写费用"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="cost"
                                        size="default"
                                        placeholder="请填写费用"
                                        value={cost}
                                        onChange={(e) => {
                                            setCost(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="毛利" name="gross">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写毛利"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="gross"
                                        size="default"
                                        placeholder="请填写毛利"
                                        value={gross}
                                        disabled={gross == "****" ? true : false}
                                        onChange={(e) => {
                                            setGross(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="业务负责人" name="principal">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写业务负责人"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="principal"
                                        size="default"
                                        value={principal}
                                        placeholder="请填写业务负责人"
                                        onChange={(e) => {
                                            setPrincipal(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="业务联络电话" name="phone">
                                {/* 填写菜单名字 */}
                                <Tooltip
                                    trigger={['focus']}
                                    title="请填写业务联络电话"
                                    placement="topLeft"
                                >
                                    <Input
                                        id="phone"
                                        size="default"
                                        placeholder="请填写业务联络电话"
                                        value={phone}
                                        onChange={(e) => {
                                            setPhone(e.target.value)
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {/* 查看明细 */}
            <Modal
                title="明细表"
                visible={lookDetail}
                onCancel={closeDetail}
                width={1000}
                footer={[]}
                centered={true}
            >
                <Steps
                    current={current}
                    type="navigation"
                    className="site-navigation-steps"
                >
                    <Step status={status1} title="未激活" />
                    <Step status={status2} title="洽谈中" />
                    <Step status={status3} title="合作中" />
                    <Step status={status4} title="合作结束" />
                </Steps>
                <Descriptions
                    bordered
                    column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >

                    <Descriptions.Item label="合作业务">{business}</Descriptions.Item>
                    <Descriptions.Item label="合作状态">{status}</Descriptions.Item>
                    <Descriptions.Item label="合作开始时间">{startTime}</Descriptions.Item>
                    <Descriptions.Item label="合租结束时间">{endTime}</Descriptions.Item>
                    <Descriptions.Item label="结账日期">{accounsTime}</Descriptions.Item>
                    <Descriptions.Item label="预算">{budget}</Descriptions.Item>
                    <Descriptions.Item label="费用">{cost}</Descriptions.Item>
                    <Descriptions.Item label="毛利"> {gross}</Descriptions.Item>
                    <Descriptions.Item label="业务负责人"> {principal}</Descriptions.Item>
                    <Descriptions.Item label="业务联络电话">{phone}</Descriptions.Item>

                </Descriptions>
            </Modal>
        </div>
    )

}

export default Marketing