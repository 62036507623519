import React, {useState, useEffect, useCallback} from 'react';
import {Breadcrumb, Row, Col, Button, Table, Modal, notification, message, Input, Select, Form, Tag, Space} from 'antd'
import {EditOutlined, DeleteOutlined, RedoOutlined, SearchOutlined} from '@ant-design/icons';
import servicePath from '../../config/apiUrl'
import axios from 'axios'
import Base64 from 'base-64'
import cookie from 'react-cookies'
import '../../static/css/Table.css'


function Task(props){

    //数据
    const [ date , setDate ] = useState([])

    //页面
    const [page, setPage] = useState(1)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger:false,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }


    const columns = [
        {
            title: '创建人邮箱',
            key: 'creator',
            dataIndex: 'creator',
            width: '200px',
            align: 'center',
        },
        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == 0? <Tag>代执行</Tag> : text == 1 ? <Tag color="blue">执行中</Tag>
                    : text == 3 ? <Tag color="green">执行完毕</Tag>
                    :  <Tag color="red">执行错误</Tag> 
            ),
        },
        {
            title: '创建时间',
            key: 'time',
            dataIndex: 'time',
            width: '200px',
            align: 'center'
        },{
            title:'查看详情',
            key:'search',
            dataIndex:'search',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <SearchOutlined onClick={() => {
                    index = (page - 1) * 10 + index
                    props.history.push({'pathname':'/app/jillion/jillion','state':{'taskId':date[index]['id']}})
                }}/>
            ),
        }
        
    ]

    // 获取任务列表
    useEffect(()=>{
        getAllTask()
    },[page])

    const getAllTask = ()=>{
        console.log(cookie.load('token'));
        let dataProps = {
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role'),
            'email': Base64.decode(cookie.load('email'))
        }
        axios({
            method: 'POST',
            url: servicePath.getJillonTask,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setDate(res.data['data'])
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    return (
        <div>
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06}}>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                    scroll={{ x:'80vw', y: `${size.width>1500?'70vh':'63vh'}` }}
                />
            </div>

            
        </div>
    )


}

export default Task