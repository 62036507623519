import React, { useEffect, useState } from 'react';
import { Tabs, Typography, Select, Tooltip, DatePicker, Modal, Input, Button, Radio, Popconfirm, Tag, TreeSelect, Table, Popover, Checkbox, message, Form, Drawer, Spin } from 'antd'
import { RightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import cookie from 'react-cookies'
import style from './index.module.css';
import PlatformEventsCalendar from '../../../component/platformEventsCalendar'
import NbAxios from '../../../config/utils/nbAxios';
import servicePath from '../../../config/apiUrl';
import downExcel from '../../../config/utils/downloadFile';
import Axios from 'axios';
const { Title, Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker
const { TabPane } = Tabs
const { TextArea } = Input
const { SHOW_PARENT } = TreeSelect;
const dateFormat = 'YYYY-MM-DD';
function Index(props) {
    const [updatePlanForm] = Form.useForm();
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };
    function disabledDate(current) {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }
    const options = {
        '商品基础信息': 'orange',
        '当前时间数据': 'green',
        '全量商品排行': 'red'
    }
    // 计划修改名字
    const [updatePlanName,setUpdatePlanName] = useState('')
    // 活动名字
    const [newActivityDict, setNewActivityDict] = useState({
        'activity_id':'',
        'activity_name': '',
        'activity_show_time': '',
        'description': '',
        'logo': '',
        'participants': '',
        'plans': [],
        'status_name': '',
    })
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 标签值
    const [tabKey, setTabKey] = useState('home')
    // 计划数组
    const [planList, setPlanList] = useState([
        {
            'name': '520计划1',
            'time': '2022-05-01 12:08:00',
            'creator': "胡思彬",
            'remark': '这个一个备注哦这个一个备注哦这个一个备注哦这个一个备注哦这个一个备注哦这个一个备注哦这个一个备注哦这个一个备注哦这个一个备注哦这个一个备注哦这个一个备注哦这个一个备注哦这个一个备注哦'
        },
        {
            'name': '520计划1',
            'time': '2022-05-01 12:08:00',
            'creator': "胡思彬",
            'remark': '这个一个备注哦'
        },
        {
            'name': '520计划1',
            'time': '2022-05-01 12:08:00',
            'creator': "胡思彬",
            'remark': '这个一个备注哦'
        },
        {
            'name': '520计划1',
            'time': '2022-05-01 12:08:00',
            'creator': "胡思彬",
            'remark': '这个一个备注哦'
        },
        {
            'name': '520计划1',
            'time': '2022-05-01 12:08:00',
            'creator': "胡思彬",
            'remark': '这个一个备注哦'
        },
        {
            'name': '520计划1',
            'time': '2022-05-01 12:08:00',
            'creator': "胡思彬",
            'remark': '这个一个备注哦'
        },
        {
            'name': '520计划1',
            'time': '2022-05-01 12:08:00',
            'creator': "胡思彬",
            'remark': '这个一个备注哦'
        },
        {
            'name': '520计划1',
            'time': '2022-05-01 12:08:00',
            'creator': "胡思彬",
            'remark': '这个一个备注哦'
        },
        {
            'name': '520计划1',
            'time': '2022-05-01 12:08:00',
            'creator': "胡思彬",
            'remark': '这个一个备注哦'
        }

    ])
    // 相关指标
    const [relatedIndex, setRelatedIndex] = useState([])
    // 活动
    const [activityList, setactivityList] = useState([])
    // 添加计划
    const [addPlan, setAddPlan] = useState(false)
    // 活动开始时间
    const [startDate, setStartDate] = useState('')
    // 活动结束时间
    const [endDate, setEndDate] = useState('')
    // 历史活动开始时间
    const [historyStartDate, setHistoryStartDate] = useState('')
    const [historyEndDate, setHistoryEndDate] = useState('')
    // 活动详情
    const [activityDetailList, setActivityDetailList] = useState([])
    // 活动
    const [historyActivityList, setHistoryActivityList] = useState([])
    // 计划详情
    const [planDetailList, setPlanDetailList] = useState([])
    // 选择活动
    const [chooseActivityDetail, setChooseActivityDetail] = useState({})
    // 选择计划
    const [choosePlanDetail, setChoosePlanDetail] = useState({})
    // 计划详情
    const [planDetail, setPlanDetail] = useState({})
    // 商品排行
    const [commodityRankingList, setCommodityRankingList] = useState(['支付金额', '支付商品件数', '加购件数'])
    // 选择商品排行
    const [chooseCommodityRankingList, setChooseCommodityRankingList] = useState(['支付金额', '加购件数', '支付商品件数'])
    // 商品基础数据
    const [commodityBaseList, setCommodityBaseList] = useState(['产品线', '上市年份', '品类', '季节'])
    // 选择商品基础数据
    const [chooseCommodityBaseList, setChooseCommodityBaseList] = useState(['产品线', '品类', '季节', '上市年份',])
    // 时间数据
    const [dateDataList, setDateDataList] = useState(['年份', '月份'])
    // 选择时间数据
    const [chooseDateDataList, setChooseDateDataList] = useState(['年份', '月份'])
    // 调价规则标签
    const [ruleTabKeyList] = useState(['指标系数', '预警级别', '配置调价幅度',])
    // 选择调价标签
    const [chooseRuleTabKey, setChooseRuleTabKey] = useState('指标系数')
    // 树型下拉框选择
    const [groupTreeDataSelect, setGroupTreeDataSelect] = useState([])
    // 新计划
    const [newPlanDict, setNewPlanDict] = useState({
        'plan_name': '',
        'activity_id': '',
        'activity_name': '',
        'remarks': '',
        'checkActivityStime': '',
        'checkActivityEtime': '',
    })
    // 校验活动最低价时间选择
    const [checkTime, setCheckTime] = useState(['',''])
    // 是否校验活动最低价
    const [isCheckActivity, setIsCheckActivity] = useState(0)
    // 沿用活动最低价
    const [followActivityPriceDiscount, setFollowActivityPriceDiscount] = useState('100')
    // 选择数据
    const [chooseGroupTreeData, setChooseGroupTreeData] = useState([])
    // 指标下拉框选择
    const [indexTreeDataSelect, setIndexTreeDataSelect] = useState([])
    // 选择指标下拉框
    const [chooseIndexTreeData, setChooseIndexTreeData] = useState([])
    // 设置调价幅度
    const [isSettingPrice, setIsSettingPrice] = useState(false)
    // 查看选款计划
    const [lookPlan, setLookPlan] = useState(false)
    // 历史活动id
    const [historyActivityId, setHistoryActivityId] = useState('')
    // 历史计划
    const [historyPlanList, setHistoryPlanList] = useState([])
    // 设置指标系数
    const [isCoefficients, setIsCoefficients] = useState(false)
    const [coefficientsColumns, setCoefficientsColumns] = useState([])
    const [coefficientsData, setCoefficientsData] = useState([])
    // 是否导出数据
    const [ isDownloadFile, setIsDownloadFile] = useState(false)
    // 下载的时间选择
    const [ downloadStartTime , setDownloadStartTime]  = useState(moment().subtract(1, 'M').format('YYYY-MM-DD'))
    const [ downloadEndTime , setDownloadEndTime] = useState(moment().format('YYYY-MM-DD'))
    // 是否是品牌方的Excel
    const [ isBrandFile, setIsBrandFile ] = useState(2)
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 活动id
    const [activityId, setActivityId] = useState('')
    // 计划id
    const [planId, setPlanId] = useState('')
    // 分组逻辑
    const groupTagRender = (props) => {
        const { label, value, closable, onClose } = props;

        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                color={value.indexOf('-') != -1 ? options[value.split('-')[0]] : options[value]}
                onMouseDown={onPreventMouseDown}
                // closable={closable}             // 是否可以删除标签
                onClose={onClose}
                style={{
                    marginRight: 3,
                }}
            >
                {label}
            </Tag>
        );
    };
    const groupTProps = {
        treeData: groupTreeDataSelect,
        value: chooseGroupTreeData,
        tagRender: groupTagRender,
        // onChange: (newValue) => {
        //     setChooseGroupTreeData(newValue);
        // },
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'Please select',
        style: {
            width: '100%',
        },
    };
    // 指标逻辑
    const indexTProps = {
        treeData: indexTreeDataSelect,
        value: chooseIndexTreeData,
        tagRender: groupTagRender,
        // onChange: (newValue) => {
        //     setChooseIndexTreeData(newValue);
        // },
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'Please select',
        style: {
            width: '100%',
        },
    }
    // 预警表头
    const [earlyWarningColumns] = useState([
        {
            title: '序号',
            key: '序号',
            dataIndex: '序号',
            align: 'center',
        },
        {
            title: '级别',
            key: 'level',
            dataIndex: 'level',
            align: 'center',
        },
        {
            title: '取值',
            key: 'value',
            dataIndex: 'value',
            align: 'center',
        },
    ])

    // 配置调价幅度表头
    const [adjustPriceColumns] = useState([
        {
            title: '序号',
            key: '序号',
            dataIndex: '序号',
            align: 'center',
        },
        {
            title: '类别名称',
            key: 'class_name',
            dataIndex: 'class_name',
            align: 'center',
        },
        {
            title: '取值',
            key: 'value',
            dataIndex: 'value',
            align: 'center',
        },
    ])
    // 配置调价幅度数据
    const [adjustPriceData, setAdjustPriceData ] = useState([])

    // 生命周期预警表头
    const [lifeCycleColumns] = useState([
        {
            title: '级别',
            key: 'level',
            dataIndex: 'level',
            align: 'center',
        },
        {
            title: '取值',
            key: 'value',
            dataIndex: 'value',
            align: 'center',
        },
    ])

    // 生命周期数据
    const [ lifeData , setLifeData ] = useState([])
    // 业绩目标数据
    const [ performanceData, setPerformanceData] = useState([])

    // 业绩预警表头
    const [performanceColumns] = useState([
        {
            title: '序号',
            key: '序号',
            dataIndex: '序号',
            align: 'center',
        },
        {
            title: '取值',
            key: '取值',
            dataIndex: '取值',
            align: 'center',
        },
    ])

    const [warnColumns, setWarnColumns] = useState([
        {
            title: '序号',
            key: '序号',
            dataIndex: '序号',
            align: 'center',
        },
        {
            title: '预警1',
            key: '预警1',
            dataIndex: '预警1',
            align: 'center',
        },
        {
            title: '预警2',
            key: '预警2',
            dataIndex: '预警2',
            align: 'center',
        },
        {
            title: '商品类别',
            key: '商品类别',
            dataIndex: '商品类别',
            align: 'center',
        },
        {
            title: '掉价范围',
            key: '掉价范围',
            dataIndex: '掉价范围',
            align: 'center',
        }
    ])

    const [warnData , setWarnData ] = useState([])

    // 计划修改
    const [isUpdate, setIsUpdate] = useState(false)
    // 可不可提交
    const [disabled,setDisabled] = useState(false)
    // 校验规则名
    const [ruleMsg,setRuleMsg] = useState('')


    useEffect(() => {
        initialDate()
    }, [])

    // 初始化数据
    const initialDate = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2]
        }
        setLoading(true)
        const getData = async () => {
            await Promise.all([
                searchNewPlanInActivity(dataProps),
                initializeAssociatedActivities(dataProps),
                searchPricingRulesIndex(dataProps),
                searchIndexCoefficientGroup(dataProps),
                searchAlterLevel(dataProps),
                searchRulePrceRange(dataProps),
                searchGoodsClass(dataProps)
            ])
        }
        getData()
    }
    //查询商品类别表
    const searchGoodsClass = (dataProps)=>{
        NbAxios(dataProps, "POST", servicePath.searchGoodsClass).then(res => {
            if (res.success) {
                setAdjustPriceData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询预警级别接口出错,请联系IT')
        })
    }
    //查询调价规则的配置调价幅度
    const searchRulePrceRange = (dataProps)=>{
        NbAxios(dataProps, "POST", servicePath.searchRulePrceRange).then(res => {
            if (res.success) {
                let obj = res.data.columns
                obj.map(item=>{
                    item['align'] = 'center'
                })
                setWarnColumns(res.data.columns);
                setWarnData(res.data.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询预警级别接口出错,请联系IT')
        })
    }
    // 查询预警级别接口
    const searchAlterLevel = (dataProps)=>{
        NbAxios(dataProps, "POST", servicePath.searchAlterLevel).then(res => {
            if (res.success) {
                setLifeData(res.data[0]['data'])
                setPerformanceData(res.data[1]['data'])
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询预警级别接口出错,请联系IT')
        })
    }
    // 查询指标系数的分组逻辑
    const searchIndexCoefficientGroup = (dataProps) => {
        NbAxios(dataProps, "POST", servicePath.searchIndexCoefficientGroup).then(res => {
            if (res.success) {
                let chooseGroupTreeDataObj = []
                res.data.caues_name.map(item => {
                    chooseGroupTreeDataObj.push(item.info)
                })
                setChooseGroupTreeData(chooseGroupTreeDataObj)
                let chooseIndexTreeDataObj = []
                res.data.score_point.map(item => {
                    chooseIndexTreeDataObj.push(item.info)
                })
                setChooseIndexTreeData(chooseIndexTreeDataObj)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询指标系数的分组逻辑出错,请联系IT')
        })
    }
    // 查询调价规则相关指标
    const searchPricingRulesIndex = (dataProps) => {
        NbAxios(dataProps, "POST", servicePath.searchPricingRulesIndex).then(res => {
            if (res.success) {
                setGroupTreeDataSelect(res.data['1']);
                setIndexTreeDataSelect(res.data['2']);
                setRelatedIndex(res.data['相关指标']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询调价规则相关指标接口出错,请联系IT')
        })
    }
    // 查询最新计划对应的活动
    const searchNewPlanInActivity = (dataProps) => {
        NbAxios(dataProps, "POST", servicePath.searchNewPlanInActivity).then(res => {
            setLoading(false)
            if (res.success) {
                setNewActivityDict(res.data)
                console.log(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询最新计划对应的活动出错,请联系IT')
        })
    }

    const initializeAssociatedActivities = (dataProps) => {
        NbAxios(dataProps, "POST", servicePath.initializeAssociatedActivities).then(res => {
            setLoading(false)
            if (res.success) {
                setactivityList(res.data)
                console.log(res.data);
            }else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询最新计划对应的活动出错,请联系IT')
        })
    }
    // 修改计划状态
    const alterPlanUseStatus=(dataProps)=>{
        NbAxios(dataProps, "POST", servicePath.alterPlanUseStatus).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("修改成功")
                console.log(res.data);
            }else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询最新计划对应的活动出错,请联系IT')
        })
    }
    return (
        <div style={{ backgroundColor: '#F5F5F5', height: "calc(100vh - 40px)" ,overflow:"auto"}}>
            {/* style={{width:"1440px", margin:'0 auto', paddingLeft:"40px", paddingRight:"40px", paddingBottom:"40px"}} */}
            <div className={style.externalContent} >
                <div style={{ height: "40px" }}>
                    <Tabs size="small" activeKey={tabKey} onChange={(key) => { setTabKey(key) }}>
                        <TabPane tab={<span>&emsp;首页&emsp;</span>} key="home"></TabPane>
                        <TabPane tab={<span>历史计划</span>} key="plan"></TabPane>
                        <TabPane tab={<span>调价规则</span>} key="rule"></TabPane>
                        <TabPane disabled tab={<span>未来标签</span>} key="future"></TabPane>
                    </Tabs>
                </div>
                <Spin style={{ display: tabKey == 'home' ? '' : 'none'}} spinning={loading} tip="数据加载中....">
                    <div style={{ height: "calc(100% + 120px)", width: "100%", backgroundColor: "#fff", padding: "40px", display: tabKey == 'home' ? 'grid' : 'none', gridTemplateColumns: "58.3% 41.7%",overflow:'auto'}} >
                        <div>
                            <div style={{ display: 'flex', }}>
                                <div style={{ marginRight: '40px' }}>
                                    <img style={{ width: "150px", height: "100px", borderRadius: "5px" }} src={`http:${newActivityDict['logo']}`} />
                                </div>
                                <div>
                                    <div style={{ fontSize: "16px", color: "black" }}>
                                        {newActivityDict['activity_name']}
                                    </div>
                                    <div style={{ fontSize: "14px", color: "black", marginTop: '8px' }}>
                                        {newActivityDict['activity_show_time']}
                                    </div>
                                    <div style={{ fontSize: "12px", color: "rgba(112, 112, 112, 1);", marginTop: '8px', width: "508px", display: 'flex', flexWrap: 'wrap' }}>
                                        {newActivityDict['description']}
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop: "15px", height: "2px", marginBottom: "10px" }}></div>
                            <div style={{ width: "100%", height: "calc(100vh - 347px)", border: "1px solid rgba(229, 229, 229, 1);", overflow: "auto" }}>
                                {
                                    newActivityDict['plans'].map(item => {
                                        return <div style={{ padding: "10px", }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ color: "#0091FF", fontSize: "14px", fontWeight: 'bolder', width: "113px" }}>
                                                        {item.plan_name}
                                                    </div>
                                                    <Popconfirm title={item.is_plan_used == 1?"是否弃用本计划":"是否要启用本计划"} okText="确认" cancelText="取消" onConfirm={() => {
                                                        let dataProps = {
                                                            roleId: cookie.load('role'),
                                                            shopList: cookie.load('shopList'),
                                                            shop_name: props.history.location.pathname.split('/')[2],
                                                            activity_id:newActivityDict.activity_id,
                                                            plan_id:item.id,
                                                        }
                                                        console.log(newActivityDict);
                                                        console.log(dataProps);
                                                        if(item.is_plan_used == 1){
                                                            dataProps["is_plan_used"] = item.is_plan_used = 0
                                                            alterPlanUseStatus(dataProps)
                                                        }else{
                                                            dataProps["is_plan_used"] = item.is_plan_used = 1
                                                            alterPlanUseStatus(dataProps)
                                                        }
                                                    }}>
                                                        {
                                                            item.is_plan_used == 1 ? <div style={{ display: 'flex', alignItems: "center" }}>
                                                                <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#52C41A" }}></div>
                                                                <span style={{ marginLeft: '5px' }}>使用中</span>
                                                            </div> : <div style={{ display: 'flex', alignItems: "center" }}>
                                                                <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#cccccc" }}></div>
                                                                <span style={{ marginLeft: '5px' }}>未使用</span>
                                                            </div>
                                                        }
                                                    </Popconfirm>
                                                </div>
                                                <div style={{ fontSize: "12px", }}>
                                                    <Text type="secondary" style={{ marginRight: 20 }}>{item.creator_email}</Text>
                                                    <Text type="secondary">{item.last_operate_time}</Text>
                                                </div>
                                            </div>
                                            <div style={{ paddingLeft: '33px', paddingTop: '10px', width: "100%", display: 'flex', fontSize: '12px' }}>
                                                <Text type="secondary" style={{ marginRight: 20 }}>{"上次操作时间"}</Text>
                                                <Text type="secondary" style={{ marginRight: 20 }}>{item.last_operate_time}</Text>
                                                <Text type="secondary" style={{ marginRight: 20 }}>{"上次操作人"}</Text>
                                                <Text type="secondary">{item.creator_email}</Text>
                                            </div>
                                            <Tooltip title={item.remark} placement="topLeft">
                                                <div style={{ paddingLeft: '33px', marginTop: "10px", width: "90%", fontSize: '12px', overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                    <Text type="secondary" style={{ marginRight: 40 }}>{item.remarks}</Text>
                                                </div>
                                            </Tooltip>
                                            <div style={{ paddingLeft: '33px', paddingTop: "10px", width: "100%", display: 'flex', justifyContent: 'flex-end', fontSize: '12px' }}>
                                                <a type="secondary" style={{ marginRight: 5, textDecoration: "underline" }} onClick={() => {
                                                    updatePlanForm.setFieldsValue({ 'plan_name': item.plan_name, 'remarks': item.remarks, 'plan_id': item.id })
                                                    setIsUpdate(true)
                                                }}>{'计划修改'}</a>
                                                <a type="secondary" style={{ marginRight: 5, textDecoration: "underline" }} onClick={() => {
                                                    props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/choicePricing/operate`, 'state': {'plan_id': item.id,  'activity_id': newActivityDict['activity_id']} })
                                                }}>{'选款修改'}</a>
                                                <a type="secondary" style={{ marginRight: 5, textDecoration: "underline" }} onClick={() => {
                                                    props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/choicePricing/summary`, 'state': {'plan_id': item.id,  'activity_id': newActivityDict['activity_id']} })
                                                }}>{'查询汇总'}</a>
                                                <a type="secondary" style={{ marginRight: 5, textDecoration: "underline" }} onClick={()=>{
                                                    setPlanId(item.id)
                                                    setActivityId(newActivityDict['activity_id'])
                                                    setIsDownloadFile(true)
                                                }}>{'结果导出'}</a>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ paddingLeft: '95px' }}>
                            <div style={{ paddingLeft: '10px', fontSize: '18px', color: 'rgba(0, 0, 0, 1)' }}>
                                创建新计划
                            </div>
                            <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "20px", marginBottom: "20px" }}></div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ color: "red" }}>*</span>
                                <div style={{ fontSize: '14px', color: 'rgba(0,0,0,1)', width: "56px" }}>计划名字</div>
                                <Input style={{ marginLeft: '20px', width: "300px", height: "30px" }} placeholder="请输入计划名字" value={newPlanDict['plan_name']}onChange={(e) => {
                                    // 只能输入汉字、数字、字母、空格    空格与引号是为了输入法输入连续
                                    let reg = new RegExp("(^[\u4E00-\u9FA5A-Za-z0-9 ']+$)");   
                                    if(reg.test(e.target.value) || e.target.value.length == 0){
                                        let obj = Object.assign({},newPlanDict)
                                        obj['plan_name'] = e.target.value
                                        setNewPlanDict(obj)
                                    }else{
                                        message.error("仅可填写汉字、数字、字母")
                                    }
                                }} />
                            </div>
                            <div style={{ display: 'flex', marginTop: "10px", alignItems: 'center' }}>
                                <span style={{ color: "red" }}>*</span>
                                <div style={{ fontSize: '14px', color: 'rgba(0,0,0,1)', lineHeight: '30px', width: "56px" }}>关联活动</div>
                                <Select placeholder="请选择关联活动" style={{ marginLeft: '20px', width: "300px", height: "30px" }} onChange={(value) => {
                                    newPlanDict['activity_id'] = value.split(',')[0]
                                    newPlanDict['activity_name'] = value.split(',')[1]
                                }}
                                onClick={()=>{
                                    if(activityList.length==0){
                                        message.warning("暂无数据")
                                    }
                                }}>
                                    {
                                        activityList.map(item => {
                                            return <Option value={item.activity_id + ',' + item.activity_name}>{item.activity_name}</Option>
                                        })
                                    }
                                </Select>
                            </div>
                            <div style={{ display: 'flex', marginTop: "10px", alignItems: 'center' }}>
                                <div style={{ fontSize: '14px', color: 'rgba(0,0,0,1)', width: "56px", marginLeft: "10px" }}>备注</div>
                                <div>
                                    <TextArea rows={2} placeholder="请输入" style={{ marginLeft: '17px', width: "300px" }} onChange={(e) => {
                                        newPlanDict['remarks'] = e.target.value
                                    }} maxLength={100} showCount/>
                                </div>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Button style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px" }} size="small" onClick={() => {
                                    setAddPlan(true)
                                }}>设置沿用计划</Button>
                            </div>
                            <div style={{ marginTop: '20px', width: "100%" }}>
                                <Text type="secondary" style={{ fontSize: '12px' }}>添加沿用计划是为了新建的选款计划能够沿用依据活动的相关数据，包括活动价、到手价、优惠券设定、专区设定。</Text>
                            </div>
                            <div style={{ marginTop: '20px', width: "400px", border: '1px solid #ccc', padding: '10px', display: JSON.stringify(planDetail) == "{}" ? 'none' : '' }}>
                                <div style={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px" }} size="small" onClick={() => setPlanDetail({})}>取消</Button>
                                </div>
                                <div style={{ marginTop: '5px', display: 'flex' }}>
                                    <div>
                                        <Text style={{ fontSize: "14px" }} type="secondary">活动:</Text>
                                    </div>
                                    <div style={{ padding: "0px 10px 10px 10px" }}>
                                        <div>
                                            <Text style={{ fontSize: '14px', padding: "0px" }}>{planDetail['activity_name']}</Text>
                                        </div>
                                        <div>
                                            <Text style={{ fontSize: '12px', padding: "0px" }} type="secondary">{planDetail['activity_show_time']}</Text>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '5px', display: 'flex' }}>
                                    <div>
                                        <Text style={{ fontSize: "14px" }} type="secondary">计划:</Text>
                                    </div>
                                    <div style={{ padding: "0px 10px 10px 10px" }}>
                                        <div>
                                            <Text style={{ fontSize: '14px', padding: "0px" }}>{planDetail['plan_name']}</Text>
                                        </div>
                                        <div style={{ overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "228px" }}>
                                            <Tooltip title={planDetail['remarks']}>
                                                <Text style={{ fontSize: '12px', padding: "0px" }} type="secondary">{planDetail['remarks']}</Text>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '5px', display: 'flex' }}>
                                    <div>
                                        <Text style={{ fontSize: "14px", height: '24px', lineHeight: "24px" }} type="secondary">活动最低折扣:</Text>
                                    </div>
                                    <div style={{ marginLeft: "30px", display: 'flex' }}>
                                        <Input style={{ height: '24px', width: '51px' }} value={followActivityPriceDiscount} onChange={(e) => {
                                            let reg = new RegExp("^(\\d|[1-9]\\d|100)$");             
                                            if(reg.test(e.target.value) || e.target.value.length == 0){
                                                setFollowActivityPriceDiscount(e.target.value)
                                            }else{
                                                message.error("只能输入0-100数字")
                                            }
                                        }} />
                                        <Popover placement="right" title={''} content={"\"90\"表示9折"} trigger="hover">
                                            <QuestionCircleOutlined style={{ marginLeft: '10px', fontSize: "15px", marginTop: '2px' }} />
                                        </Popover>
                                    </div>
                                </div>
                                <div style={{ marginTop: '5px' }}>
                                    <Text style={{ color: "rgba(56, 56, 56, 0.8)", fontSize: "12px" }}>注：活动价最低折扣表示，当沿用计划的活动价是直接进行折扣处理。</Text>
                                </div>
                            </div>
                            <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "20px", marginBottom: "10px" }}></div>
                            {/* 暂不支持 */}
                            <div style={{ display: "flex", marginBottom: "10px", alignItems: 'center' }}>
                                <span>校验活动最低价</span>
                                <Checkbox style={{ marginLeft: "10px" }} checked={isCheckActivity == 0 ? false : true} onChange={(e) => {
                                    if (e.target.checked) {
                                        setIsCheckActivity(1)
                                    } else {
                                        setIsCheckActivity(0)
                                        newPlanDict['checkActivityStime'] = ''
                                        newPlanDict['checkActivityEtime'] = ''
                                    }
                                }}></Checkbox>
                                <RangePicker disabledDate={disabledDate} locale={locale} style={{ marginLeft: '10px', height: "30px", borderRadius: "20px", display: isCheckActivity == 1 ? '' : "none" }} 
                                value={checkTime[0] == '' ? ['', ''] : 
                                [moment(checkTime[0], dateFormat), moment(checkTime[1], dateFormat)]} 
                                onChange={(date, dateString) => {
                                    if (dateString[0] == '') {
                                        message.warn('请选择合理的时间')
                                        return false
                                    } else {
                                        setCheckTime([dateString[0],dateString[1]])
                                        newPlanDict['checkActivityStime'] = dateString[0]
                                        newPlanDict['checkActivityEtime'] = dateString[1]
                                    }
                                }} />
                            </div>
                            <Button type="primary" onClick={() => {
                                let errFlag = 0
                                newPlanDict['shopList'] = cookie.load('shopList')
                                newPlanDict['roleId'] = cookie.load('role')
                                newPlanDict['shop_name'] = props.history.location.pathname.split('/')[2]
                                newPlanDict['user_email'] = cookie.load('name')
                                if (newPlanDict['plan_name'] == '') {
                                    message.warn('填写计划名字')
                                }else if(newPlanDict['plan_name'].match(/^[ ]+$/)){
                                    message.warn('计划名字不能全为空格')
                                } else if (newPlanDict['activity_id'] == '') {
                                    message.warn('请选择关联活动')
                                } else {
                                    if (JSON.stringify(planDetail) != "{}") {
                                        if (followActivityPriceDiscount == '') {
                                            errFlag = 1
                                        } else {
                                            newPlanDict['follow_activity_id'] = planDetail['activity_id']
                                            newPlanDict['follow_paln_id'] = planDetail['plan_id']
                                            newPlanDict['follow_activity_price_discount'] = followActivityPriceDiscount
                                        }
                                    }
                                    if (errFlag) {
                                        message.warn('请填写活动最低折扣')
                                    } else {
                                        NbAxios(newPlanDict, "POST", servicePath.initializationPlan).then(res => {
                                            if (res.success) {
                                                let data = res.data
                                                props.history.push({ 'pathname': '/app/JORYA/choicePricing/benefit', 'state': { 'plan_id': data['plan_id'], 'activity_id': data['activity_id'], 'follow_activity_id': data['follow_activity_id'], 'follow_paln_id': data['follow_paln_id'] } })
                                                message.success('计划创建成功')
                                            } else {
                                                message.error(res['errorMsg'])
                                            }
                                        })
                                    }
                                }


                            }}>创建</Button>
                        </div>
                    </div>
                </Spin>
                <Spin spinning={loading} tip="数据加载中......">
                   <div style={{ height: "calc(100vh - 120px)", width: "100%", backgroundColor: "#fff", padding: "40px", display: tabKey == 'plan' ? '' : 'none' }}>
                    <div style={{ padding: "10px", display: "flex", width: "800px", margin: "0 auto", }}>
                        <div style={{ fontSize: '14px', color: "rgba(56, 56, 56, 1);", marginRight: "58px" }}>活动时间</div>
                        <RangePicker disabledDate={disabledDate} locale={locale} style={{ marginLeft: '10px', height: "30px", borderRadius: "20px" }} value={historyStartDate == '' ? ['', ''] : [moment(historyStartDate, dateFormat), moment(historyEndDate, dateFormat)]} onChange={(date, dateString) => {
                            if (dateString[0] == '') {
                                message.warn('请选择合理的时间')
                                return false
                            } else {
                                setHistoryStartDate(dateString[0])
                                setHistoryEndDate(dateString[1])
                            }
                        }} />
                        <Button style={{ marginLeft: "50px" }} size="small" type="primary" onClick={() => {
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                search_start_time: historyStartDate,
                                search_end_time: historyEndDate,
                                activity_name: '',
                                plan_name: '',
                                flag: 1
                            }
                            setLoading(true)
                            NbAxios(dataProps, "POST", servicePath.chooseAndPricingPlanFirst).then(res => {
                                setLoading(false)
                                if (res.success) {
                                    setHistoryActivityList(res.data);
                                    
                                }else {
                                    message.warn(res.errorMsg)
                                }
                                }).catch(err => {
                                    setLoading(false)
                                    message.warn('查询选款定价活动计划出错,请联系IT')
                                })
                        }}>查询</Button>
                    </div>
                    <div style={{ width: "800px", margin: "0 auto", height: "calc(100vh - 270px)", display: 'flex', flexDirection: 'column', paddingBottom: "20px", overflow: "auto" }}>
                        {
                            historyActivityList.map(item => {
                                return <div style={{ display: 'flex', marginTop: '15px' }} onClick={() => {
                                    setHistoryActivityId(item.activity_id)
                                    setHistoryPlanList(item.plan);
                                    setLookPlan(true)
                                }}>
                                    <div>
                                        <img style={{ width: "108px", height: "66px", borderRadius: "5px" }} src={`http:${item['logo']}`} />
                                    </div>
                                    <div style={{ marginLeft: '22px' }}>
                                        <div style={{ fontSize: "14px", fontWeight: 'bold' }}>{item.activity_name}</div>
                                        <div style={{ fontSize: "12px" }}>{item.activity_show_time}</div>
                                        <div style={{ fontSize: "12px", marginTop: "9px", color: "rgba(112, 112, 112, 1)" }}>{item.description}</div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div> 
                </Spin>
                
                <Spin style={{ display: tabKey == 'rule' ? '' : 'none'}} spinning={loading} tip="数据加载中....">
                    <div style={{ height: "calc(100vh - 120px)", width: "100%", backgroundColor: "#fff", padding: "40px", display: tabKey == 'rule' ? '' : 'none' }}>
                        <div>
                            <Text>在选款定价中，需要为当前商品匹配其对应的对标数据，用于预估其能否在生命周期中达成销售目标，完成业绩。</Text>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: '30% 70%' }}>
                            <div style={{ marginTop: "22px" }}>
                                <Text style={{ fontSize: "14px", fontWeight: 'bold' }}>相关指标</Text>
                                <div>
                                    {
                                        relatedIndex.map((item, index) => {
                                            return <div>
                                                <div style={{ marginTop: index == 0 ? '22px' : "14px" }}>
                                                    <Tag color={item.color}>{item.name}</Tag>
                                                </div>
                                                <div style={{ marginTop: "12px" }}>
                                                    <Select style={{ width: '300px' }} mode="multiple" value={item.value}>
                                                        {
                                                            item.value.map(item => {
                                                                return <option value={item}>{item}</option>
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{ marginTop: "22px", marginBottom: "20px" }}>
                                <div style={{ display: 'grid', gridTemplateColumns: "1fr 95px" }}>
                                    <div>
                                        <div style={{ paddingLeft: '25px', paddingTop: '20px', display: chooseRuleTabKey == '指标系数' ? '' : 'none', border: "1px dashed #ccc" }}>
                                            <div>
                                                <Text>根据【商品基础信息】、【当前信息】获取关于【全量商品排行-14天】的指标值及指标系数，进行计算得分。</Text>
                                            </div>
                                            <div style={{ marginTop: "21px", padding: '10px' }}>
                                                <div style={{ marginLeft: "10px" }}>
                                                    <Text>1. 指标系数设置的分组逻辑</Text>
                                                </div>
                                                <div style={{ marginTop: '10px' }}>
                                                    <TreeSelect {...groupTProps} />
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "21px", padding: '10px' }}>
                                                <div style={{ marginLeft: "10px" }}>
                                                    <Text>2. 指标</Text>
                                                </div>
                                                <div style={{ marginTop: '10px' }}>
                                                    <TreeSelect {...indexTProps} />
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "21px", padding: '10px' }}>
                                                <div style={{ marginLeft: "10px" }}>
                                                    <Text>3. 指标系数</Text>
                                                </div>
                                                <div style={{ marginTop: '10px' }}>
                                                    <Button size="small" style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px" }} onClick={() => {
                                                        let dataProps = {
                                                            roleId: cookie.load('role'),
                                                            shopList: cookie.load('shopList'),
                                                            shop_name: props.history.location.pathname.split('/')[2]
                                                        }
                                                        setLoading(true)
                                                        NbAxios(dataProps, "POST", servicePath.searchIndexCoefficient).then(res => {
                                                            setLoading(false)
                                                            if (res.success) {
                                                                let obj = res.data.columns
                                                                obj.map(item=>{
                                                                    item['align'] = 'center'
                                                                })
                                                                setCoefficientsColumns(res.data.columns);
                                                                setCoefficientsData(res.data.data);
                                                                setIsCoefficients(true)
                                                            } else {
                                                                message.warn(res.errorMsg)
                                                            }
                                                        }).catch(err => {
                                                            setLoading(false)
                                                            message.warn('查询指标系数的分组逻辑接口出错,请联系IT')
                                                        })
                                                    }}>设置指标系数</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ paddingLeft: '21px', paddingTop: '21px', display: chooseRuleTabKey == '预警级别' ? '' : 'none', border: "1px dashed #ccc" }}>
                                            <div>
                                                <Text>根据【商品基础信息】、【当前信息】获取关于【全量商品排行-14天】的指标值及指标系数，进行计算得分。</Text>
                                            </div>
                                            <div style={{ marginTop: "21px", padding: '10px' }}>
                                                <div style={{ marginLeft: "10px" }}>
                                                    <Text>1. 生命周期预警</Text>
                                                </div>
                                                <div style={{ marginLeft: "10px", marginTop: '10px' }}>
                                                    <Text>生命周期预警级别用于表示在当前时间下，基于商品的生命周期，能否在其生命周期中完成销售的不同程度。</Text>
                                                </div>
                                                <div style={{ marginLeft: "10px", marginTop: '10px', width: "300px" }}>
                                                    <Table size="small" columns={earlyWarningColumns} dataSource={lifeData} pagination={false}/>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "21px", padding: '10px' }}>
                                                <div style={{ marginLeft: "10px" }}>
                                                    <Text>2. 业绩目标预警</Text>
                                                </div>
                                                <div style={{ marginLeft: "10px", marginTop: '10px' }}>
                                                    <Text>业绩目标预警表示截止当前时间点，近14天内，距离对标数据的近14天应当已经完成销售目标的达成情况。</Text>
                                                </div>
                                                <div style={{ marginLeft: "10px", marginTop: '10px', width: "300px" }}>
                                                    <Table size="small" columns={earlyWarningColumns} dataSource={performanceData} pagination={false}/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*  height: "calc(100vh - 320px)", */}
                                        <div style={{ paddingLeft: '21px', paddingTop: '21px', display: chooseRuleTabKey == '配置调价幅度' ? '' : 'none', border: "1px dashed #ccc" }}>
                                            <div>
                                                <Text>对于当前商品的指标得分全店排名情况，划分不同商品类别；不同的商品类别，在不同的预警条件下，调价幅度不同。</Text>
                                            </div>
                                            <div style={{ marginTop: "21px", padding: '10px' }}>
                                                <div style={{ marginLeft: "10px" }}>
                                                    <Text>1. 商品类别的划分</Text>
                                                </div>
                                                <div style={{ marginLeft: "10px", marginTop: '10px' }}>
                                                    <Text>类别的划分，通过全店的排名情况进行划分，例如类别名称为A，取值为100，表示Top100的划定为A类别商品；</Text>
                                                </div>
                                                <div style={{ marginLeft: "10px", marginTop: '10px', width: "300px" }}>
                                                    <Table size="small" columns={adjustPriceColumns} dataSource={adjustPriceData} pagination={false}/>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "21px", padding: '10px' }}>
                                                <div style={{ marginLeft: "10px" }}>
                                                    <Text>2. 调价幅度</Text>
                                                </div>
                                                <div style={{ marginLeft: "10px", marginTop: '10px' }}>
                                                    <Text>在不同的预警条件下，调价幅度不同。</Text>
                                                </div>
                                                <div style={{ marginLeft: "10px", marginTop: '10px' }}>
                                                    <Button size="small" style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px" }} onClick={() => {
                                                        setIsSettingPrice(true)
                                                    }}>设置调价幅度</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: "95px" }}>
                                        {
                                            ruleTabKeyList.map(item => {
                                                return <div style={{ width: "130px", paddingLeft: "11px", paddingTop: "8px", paddingRight: "28px", paddingBottom: "8px", borderLeft: chooseRuleTabKey == item ? '4px solid #1890FF' : '4px solid #eee', fontWeight: 'bold' }} onClick={() => {
                                                    setChooseRuleTabKey(item)
                                                }}>{item}</div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>

            </div>

            <Modal
                title="设置沿用计划"
                visible={addPlan}
                okText="确定"
                cancelText="取消"
                onOk={() => {
                    if (JSON.stringify(choosePlanDetail) == "{}") {
                        message.warn('请选择计划')
                    } else {
                        let planDetailObj = {
                            'activity_id': chooseActivityDetail['activity_id'],
                            'activity_name': chooseActivityDetail['activity_name'],
                            'activity_show_time': chooseActivityDetail['activity_show_time'],
                            'plan_name': choosePlanDetail['plan_name'],
                            'plan_id': choosePlanDetail['plan_id'],
                            'remarks': choosePlanDetail['remarks'],
                        }
                        setChooseActivityDetail({})
                        setChoosePlanDetail({})
                        setActivityDetailList([])
                        setPlanDetailList([])
                        setPlanDetail(planDetailObj)
                        setAddPlan(false)
                    }
                }}
                onCancel={() => {
                    setAddPlan(false)
                    setChooseActivityDetail({})
                    setChoosePlanDetail({})
                    setActivityDetailList([])
                    setPlanDetailList([])
                }}
                centered={true}
                width={'800px'}
            >
                <div style={{ width: '100%' }}>
                    <div style={{ padding: "20px", display: 'flex' }}>
                        <Text style={{ fontSize: "16px", marginRight: "20px" }}>活动时间</Text>
                        <PlatformEventsCalendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                        <Button type="primary" size="small" style={{ marginLeft: "20px" }} onClick={() => {
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                search_start_time: startDate,
                                search_end_time: endDate,
                                activity_name: '',
                                plan_name: '',
                                flag: 1
                            }
                            setPlanDetailList([])
                            NbAxios(dataProps, "POST", servicePath.chooseAndPricingPlanFirst).then(res => {
                                if (res.success) {
                                    setActivityDetailList(res.data);
                                    if(res.data.length == 0){
                                        message.warning("此时间段内暂无活动,请重新选择时间")
                                    }
                                }
                            })
                        }}>查询</Button>
                    </div>
                    <div style={{ padding: "20px", display: 'flex', justifyContent: "space-around" }}>
                        <div style={{ marginRight: "20px", height: "280px", width: "320px", border: "1px solid #ccc", borderRadius: "5px", overflowY: "auto" }}>
                            {
                                activityDetailList.map(item => {
                                    return <div style={{ padding: "10px", width: "300px", backgroundColor: chooseActivityDetail.activity_id == item.activity_id ? 'rgb(212,230,250)' : '#fff' }} onClick={() => {
                                        setChooseActivityDetail(item)
                                        let dataProps = {
                                            roleId: cookie.load('role'),
                                            shopList: cookie.load('shopList'),
                                            shop_name: props.history.location.pathname.split('/')[2],
                                            search_start_time: startDate,
                                            search_end_time: endDate,
                                            activity_id: item.activity_id,
                                            plan_name: '',
                                            flag: 2
                                        }
                                        NbAxios(dataProps, "POST", servicePath.chooseAndPricingPlanFirst).then(res => {
                                            if (res.success) {
                                                setPlanDetailList(res.data);
                                                if (res.data.length == 0){
                                                    message.warning("此活动暂无可沿用的计划,请重新选择活动")
                                                }
                                            }
                                        })
                                    }}>
                                        <div>
                                            <Text style={{ fontSize: '14px' }}>{item.activity_name}</Text>
                                        </div>
                                        <div>
                                            <Text style={{ fontSize: '12px' }} type="secondary">{item.activity_show_time}</Text>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{ marginRight: "20px", height: "280px", overflowY: "auto", width: "320px", border: "1px solid #ccc", borderRadius: "5px" }}>
                            {
                                planDetailList.map(item => {
                                    return <div style={{ padding: "10px", width: "300px", backgroundColor: choosePlanDetail.plan_id == item.plan_id ? 'rgb(212,230,250)' : '#fff' }} onClick={() => {
                                        setChoosePlanDetail(item)
                                    }}>
                                        <div>
                                            <Text style={{ fontSize: '14px' }}>{item.plan_name}</Text>
                                        </div>
                                        <div style={{ overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "250px" }}>
                                            <Tooltip title={item.remarks}>
                                                <Text style={{ fontSize: '12px' }} type="secondary">{item.remarks}</Text>
                                            </Tooltip>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                title="预警级别与调价幅度"
                centered={true}
                visible={isSettingPrice}
                okText="确定"
                cancelText="取消"
                onCancel={() => {
                    setIsSettingPrice(false)
                }}
                onOk={() => {
                    setIsSettingPrice(false)
                }}
                width={'832px'}
            >
                <div>
                    <div>
                        对于当前商品的不同商品类别，在不同的预警条件下，调价幅度不同。
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: "29% 69%", marginTop: "24px" }}>
                        <div>
                            <div style={{ display: 'flex', height: "32px", width: "240px", alignItems: 'center', border: "1px solid #000", backgroundColor: "#eeeeee", padding: "2px" }}>
                                <RightOutlined />
                                <Text>预警1：生命周期预警</Text>
                            </div>
                            <div style={{ width: "240px" }}>
                                <Table size="small" columns={lifeCycleColumns} dataSource={lifeData} pagination={false} />
                            </div>
                            <div style={{ display: 'flex', height: "32px", width: "240px", alignItems: 'center', border: "1px solid #000", backgroundColor: "#eeeeee", padding: "2px" }}>
                                <RightOutlined />
                                <Text>预警2：业绩目标预警</Text>
                            </div>
                            <div style={{ width: "240px" }}>
                                <Table size="small" columns={performanceColumns} dataSource={performanceData} pagination={false} />
                            </div>
                        </div>
                        <div style={{ width: "90%", marginLeft: "30px" }}>
                            <Table size="small" columns={warnColumns} dataSource={warnData} pagination={false}/>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                title="计划修改"
                visible={isUpdate}
                okText="确定"
                cancelText="取消"
                onOk={() => {
                    let value = updatePlanForm.getFieldValue()
                    if(value.plan_name){
                        value.roleId = cookie.load('role')
                        value.shopList = cookie.load('shopList')
                        value.user_email = cookie.load('name')
                        value.shop_name = props.history.location.pathname.split('/')[2]
                        console.log(value);
                        setLoading(true)
                        NbAxios(value, "POST", servicePath.modifyPlanInfo).then(res => {
                            setLoading(false)
                            if (res.success) {
                                message.success('成功修改计划')
                                setIsUpdate(false)
                                let dataProps = {
                                    roleId: cookie.load('role'),
                                    shopList: cookie.load('shopList'),
                                    shop_name: props.history.location.pathname.split('/')[2]
                                }
                                if (lookPlan) {
                                    let dataProps = {
                                        roleId: cookie.load('role'),
                                        shopList: cookie.load('shopList'),
                                        shop_name: props.history.location.pathname.split('/')[2],
                                        search_start_time: historyStartDate,
                                        search_end_time: historyEndDate,
                                        activity_id: historyActivityId,
                                        plan_name: '',
                                        flag: 2
                                    }
                                    setLoading(true)
                                    NbAxios(dataProps, "POST", servicePath.chooseAndPricingPlanFirst).then(res => {
                                        setLoading(false)
                                        if (res.success) {
                                            setHistoryPlanList(res.data);
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    }).catch(err => {
                                        setLoading(false)
                                        message.warn('查询选款定价活动的计划出错,请联系IT')
                                    })
                                }
                                searchNewPlanInActivity(dataProps)
    
                            } else {
                                message.warn(res.errorMsg)
                            }
                        }).catch(err => {
                            setLoading(false)
                            message.warn('计划修改出错,请联系IT')
                        })
                    }else{
                        message.warning("计划名称不能为空")
                    }
                    
                }}
                onCancel={() => {
                    updatePlanForm.resetFields()
                    setIsUpdate(false)
                }}
                centered={true}
                okButtonProps={{ disabled: disabled }}
                // cancelButtonProps={{ disabled: disabled }}
            >
                <Form form={updatePlanForm} {...layout} labelAlign='left'>
                    <Form.Item label="计划名字" name="plan_name" rules={[
                        { required: true , message: "计划名称不能为空",
                        },
                        {
                            validator: (rule, value = '', callback) => {
                                let patrn = new RegExp("(^[\u4E00-\u9FA5A-Za-z0-9 ']+$)");   // 空格与引号是为了输入法输入连续
                                // let patrn = //
                                try {
                                    if (!patrn.test(value)) {
                                        callback('计划名称只能填写汉字、数字、字母')
                                    } else {
                                        callback()
                                    }
                                } catch (error) {
                                    callback(error)
                                }
                            }
                        },
                    ]}>
                        <Input onChange={()=>{
                            // 校验是否通过规则
                            try{
                                updatePlanForm.validateFields().then(res=>{
                                    setDisabled(false)
                                },rej=>{
                                    setDisabled(true)
                                });
                            }catch(e){
                                console.log(e);
                            }
                        }}/>
                    </Form.Item>
                    <Form.Item label="备注" name='remarks'>
                        <TextArea rows={3} />
                    </Form.Item>
                </Form>
            </Modal>

            <Drawer
                title="选款计划"
                visible={lookPlan}
                width={'500px'}
                onClose={() => { setLookPlan(false) }}
            >
                <Spin spinning={loading} tip="数据加载中......">
                    {
                        historyPlanList.map(item => {
                            return <div style={{ padding: "10px", }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ color: "#0091FF", fontSize: "14px", fontWeight: 'bolder', width: "113px" }}>
                                            {item.plan_name}
                                        </div>
                                        {
                                            item.is_plan_used == 1 ? <div style={{ display: 'flex', alignItems: "center" }}>
                                                <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#52C41A" }}></div>
                                                <span style={{ marginLeft: '5px' }}>使用中</span>
                                            </div> : <div style={{ display: 'flex', alignItems: "center" }}>
                                                <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#cccccc" }}></div>
                                                <span style={{ marginLeft: '5px' }}>未使用</span>
                                            </div>

                                        }

                                    </div>
                                    <div style={{ fontSize: "12px", }}>
                                        <Text type="secondary" style={{ marginRight: 20 }}>{item.creator_email}</Text>
                                        <Text type="secondary">{item.last_operate_time}</Text>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: '33px', paddingTop: '10px', width: "100%", display: 'flex', fontSize: '12px' }}>
                                    <Text type="secondary" style={{ marginRight: 20 }}>{"上次操作时间"}</Text>
                                    <Text type="secondary" style={{ marginRight: 20 }}>{item.last_operate_time}</Text>
                                    <Text type="secondary" style={{ marginRight: 20 }}>{"上次操作人"}</Text>
                                    <Text type="secondary">{item.creator_email}</Text>
                                </div>
                                <Tooltip title={item.remark} placement="topLeft">
                                    <div style={{ paddingLeft: '33px', marginTop: "10px", width: "90%", fontSize: '12px', overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                        <Text type="secondary" style={{ marginRight: 40 }}>{item.remarks}</Text>
                                    </div>
                                </Tooltip>
                                <div style={{ paddingLeft: '33px', paddingTop: "10px", width: "100%", display: 'flex', justifyContent: 'flex-end', fontSize: '12px' }}>
                                    <a type="secondary" style={{ marginRight: 5, textDecoration: "underline" }} onClick={() => {
                                        updatePlanForm.setFieldsValue({ 'plan_name': item.plan_name, 'remarks': item.remarks, 'plan_id': item.plan_id })
                                        setIsUpdate(true)
                                    }}>{'计划修改'}</a>
                                    <a type="secondary" style={{ marginRight: 5, textDecoration: "underline" }} onClick={() => {
                                        props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/choicePricing/operate`, 'state': {'plan_id': item.plan_id , 'activity_id': historyActivityId} })
                                    }}>{'选款修改'}</a>
                                    <a type="secondary" style={{ marginRight: 5, textDecoration: "underline" }} onClick={() => {
                                        props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/choicePricing/summary`, 'state': {'plan_id': item.plan_id , 'activity_id': historyActivityId} })
                                    }}>{'查询汇总'}</a>
                                    <a type="secondary" style={{ marginRight: 5, textDecoration: "underline" }} onClick={()=>{
                                        setPlanId(item.plan_id)
                                        setActivityId(historyActivityId)
                                        setIsDownloadFile(true)
                                    }}>{'结果导出'}</a>
                                </div>
                            </div>
                        })
                    }
                </Spin>

            </Drawer>
            <Modal
                title="设置指标系数"
                visible={isCoefficients}
                onCancel={() => {
                    setIsCoefficients(false)
                }}
                placement={'left'}
                width={'500px'}
                centered={true}
                footer={null}
            >
                <Table size="small" columns={coefficientsColumns} dataSource={coefficientsData} pagination={{pageSize:10}} />
            </Modal>

            <Modal
                title="导出Excel"
                visible={isDownloadFile}
                onCancel={() => {
                    setActivityId('')
                    setPlanId('')
                    setIsDownloadFile(false)
                }}
                footer={[]}
                centered={true}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Radio.Group value={isBrandFile} onChange={(e) => {
                        setIsBrandFile(e.target.value)
                    }}>
                        {/* <Radio value={1}>导出给品牌方的Excel</Radio> */}
                        <Radio value={2}>导出上传平台的Excel</Radio>
                    </Radio.Group>
                </div>
                <div style={{ display: isBrandFile == 1 ? 'flex' : 'none', justifyContent: 'center', marginTop: 30 }}>
                    <b style={{ marginRight: 10, marginTop: 5 }}>比较活动时间:</b>
                    <RangePicker
                        disabledDate={disabledDate}
                        locale={locale}
                        value={[downloadStartTime != '' ? moment(downloadStartTime, dateFormat) : '', downloadEndTime != '' ? moment(downloadEndTime, dateFormat) : '']}
                        style={{ borderRadius: 30 }}
                        onChange={(value, dateString) => {
                            if (dateString[0] == '') {
                                message.warn('请选择合理的 时间')
                            } else {
                                setDownloadStartTime(dateString[0]);
                                setDownloadEndTime(dateString[1]);
                            }

                        }}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                    <Button disabled={effective ? true : false} type="primary" onClick={() => {
                        if (isBrandFile == 1) {
                            if (downloadStartTime == '') {
                                message.warn('请选择近期活动时间')
                            } else {
                                setEffective(1)
                                let key = "download"
                                message.loading({ content: '开始下载数据...', key, duration: 100 })
                                let dataProps = {
                                    roleId: cookie.load('role'),
                                    shopList: cookie.load('shopList'),
                                    shop_name: props.history.location.pathname.split('/')[2],
                                    activity_id: activityId,
                                    plan_id: planId,
                                    start_time: downloadStartTime,
                                    end_time: downloadEndTime,
                                    creator: cookie.load('name')
                                }
                                Axios({
                                    method: 'POST',
                                    url: servicePath.downloadSelectionPricingResultDetail,
                                    data: dataProps,
                                    responseType: "blob",
                                    headers: {
                                        'authorization': cookie.load('token')
                                    },
                                }).then(res => {
                                    setEffective(0)
                                    downExcel(res)
                                    setIsDownloadFile(false)
                                    message.success({ content: "导出成功！", key })
                                }).catch(e => {
                                    setEffective(0)
                                    message.error({ content: '文件制作出错，请联系it同事！', key })
                                })
                            }
                        } else {
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                activity_id: activityId,
                                plan_id: planId,
                                creator: cookie.load('name')
                            }
                            Axios({
                                data: dataProps,
                                method: 'POST',
                                url: servicePath.downloadChooseAndPriceResult,
                                responseType: "blob",
                                headers: {
                                    'Content-Type': 'application/json',  //如果写成contentType会报错
                                    'authorization': cookie.load('token')
                                }
                            }).then(
                                res => {
                                    downExcel(res)
                                }
                            ).catch(e => {
                                message.error({ content: '文件制作出错，请联系it同事！' })
                            })
                        }
                    }}>下载</Button>
                </div>
            </Modal>
        </div>
    )

}

export default Index