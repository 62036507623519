import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, Table, Modal, Descriptions, Spin, Input, Select, Form, Tabs, Tag, message, Collapse } from 'antd'
import { CheckCircleOutlined, SendOutlined, CommentOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl'
import axios from 'axios'
import '../../../static/css/index.css'
const { Panel } = Collapse;

function Kol(props) {

    const [form] = Form.useForm();
    //数据
    const [date, setDate] = useState([])

    //页面
    const [page, setPage] = useState(1)

    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])

    // 是否在加载
    const [isLoading, setIsLoading] = useState(false)

    //联系方式
    const [contact, setContact] = useState('-1')

    // 总数页数
    const [total, setTotal] = useState(0)

    //KOL名称
    const [kolName, setKolName] = useState('')

    //KOLId
    const [kolId, setKolId] = useState(-1)

    //总藏赞
    const [praise, setPraise] = useState('')

    //平均阅读数
    const [avgRead, setAvgRead] = useState('')

    //场均观看量
    const [avgView, setAvgView] = useState('')

    //平均互动量
    const [avgInteraction, setAvgInteraction] = useState('')

    //推广产品
    const [product, setProduct] = useState('')

    // 合作形式
    const [status, setStatus] = useState('')

    // 合作开始时间
    const [startTime, setStartTime] = useState('')

    //合作结束时间
    const [endTime, setEndTime] = useState('')

    //阅读量
    const [read, setRead] = useState('')

    //互动量
    const [interaction, setInteraction] = useState('')

    //阅读成本
    const [readCost, setReadCost] = useState(0)

    //互动量成本
    const [interactionCost, setInteractionCost] = useState(0)

    //销量
    const [sales, setSales] = useState('')

    //销售额
    const [sale, setSale] = useState('')

    //ROI
    const [roi, setRoi] = useState(0)

    //内容链接
    const [contentUrl, setContentUrl] = useState('')

    //投放渠道
    const [platform, setPlatform] = useState('')

    //账号链接
    const [platfromUrl, setPlatformUrl] = useState('')

    //粉丝数
    const [fans, setFans] = useState('')

    //评级
    const [rate, setRate] = useState('')

    // 搜索名称
    const [searchName, setSearchName] = useState('')

    // 搜索平台
    const [searchPlatfrom, setSearchPlatform] = useState('')

    // 搜索产品
    const [searchProduct, setSearchProduct] = useState('')

    //含税费用
    const [cost, setCost] = useState('')

    //是否查看明细
    const [lookDetail, setLookDetail] = useState(false)

    // 是否是搜索状态
    const [isSearch, setIsSearch] = useState(0)

    //是否打开联系方式弹框
    const [isPhone, setIsPhone] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    //获取权限代码
    useEffect(() => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    }, [])

    // 表格标题
    const columns = [
        {
            title: 'KOL名字',
            key: 'name',
            dataIndex: 'name',
            width: '100px',
            align: 'center'
        },
        {
            title: '平台',
            key: 'platform',
            dataIndex: 'platform',
            width: '100px',
            align: 'center',
        },
        {
            title: '产品',
            key: 'product',
            dataIndex: 'product',
            width: '100px',
            align: 'center',
        },
        {
            title: '联系方式',
            key: 'contact',
            dataIndex: 'contact',
            width: '100px',
            align: 'center',
            colSpan: jurisdiction.indexOf("kol::lookPhoneOfKol") != -1 ? 1 : 0,
            render: (text, record, index) => (
                <Button style={{ display: jurisdiction.indexOf("kol::lookPhoneOfKol") != -1 ? "" : "none" }} type="dashed" onClick={() => { openPhone(index) }}>查看联系方式</Button>
            ),
        },
        {
            title: '评级',
            key: 'rate',
            dataIndex: 'rate',
            width: '100px',
            align: 'center',
            render: (text, record, index) => (
                text == "超出预期" ? <Tag color="blue">{text}</Tag> : text == "达到预期" ? <Tag color="green">{text}</Tag> : <Tag color="red">{text}</Tag>
            ),
        },
        {
            title: '查看明细',
            key: 'search',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <SearchOutlined onClick={() => openDetail(index)} />
            ),
        },
    ]

    // 当页面切换的时候修改数据
    useEffect(() => {
       getData()
    }, [page])

    const getData = ()=>{
        if (isSearch == 0) {
            getAllKolOfPut()
        } else {
            search()
        }
    }

    //  获取kol推广数据
    const getAllKolOfPut = () => {
        setIsLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            'page': page,
        }
        axios({
            method: 'POST',
            url: servicePath.getAllKolOfPut,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setDate(res.data['data'])
                    setTotal(res.data['total'])
                    setIsLoading(false)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //获取KOL的联系方式
    const getPhone = (id) => {
        let dataProps = {
            "kol_id": id,
            "operator": cookie.load('name'),
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role')
        }
        axios({
            method: 'POST',
            url: servicePath.getPhoneOfKOl,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setContact(res.data['data'])
                    setIsPhone(true)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //打开查看明细
    const openDetail = (index) => {
        //根据页改变下标
        // index = (page - 1) * 10 + index
        setProduct(date[index]['product'])
        setStatus(date[index]['status'])
        setStartTime(date[index]['startTime'])
        setEndTime(date[index]['endTime'])
        setPraise(date[index]['praise'])
        setKolName(date[index]['name'])
        setAvgRead(date[index]['avgRead'])
        setAvgView(date[index]['avgView'])
        setAvgInteraction(date[index]['avgInteraction'])
        setContentUrl(date[index]['contentUrl'])
        setRead(date[index]['read'])
        setInteraction(date[index]['interaction'])
        setReadCost(date[index]['readCost'])
        setInteractionCost(date[index]['interactionCost'])
        setCost(date[index]['cost'])
        setSale(date[index]['sale'])
        setSales(date[index]['sales'])
        setRoi(date[index]['roi'])
        setPlatform(date[index]['platform'])
        setPlatformUrl(date[index]['platfromUrl'])
        setFans(date[index]['fans'])
        setLookDetail(true)
    }

    // 关闭查看明细
    const closeDetail = () => {
        setProduct('')
        setStatus('')
        setStartTime('')
        setEndTime('')
        setPraise('')
        setAvgRead('')
        setAvgView('')
        setAvgInteraction('')
        setContentUrl('')
        setRead('')
        setInteraction('')
        setReadCost(0)
        setInteractionCost(0)
        setCost('')
        setSale('')
        setSales('')
        setRoi('')
        setPlatform('')
        setPlatformUrl('')
        setFans('')
        setLookDetail(false)
    }

    // 条件查询
    const search = () => {
        // 修改isSearch的值,说明是搜索状态
        setIsSearch(1)
        // 出现加载
        setIsLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            'name': searchName,
            "product": searchProduct,
            "platform": searchPlatfrom,
            "rate": rate,
            "page": page,
        }
        axios({
            method: 'POST',
            url: servicePath.conditionPutOfKOL,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setDate(res.data['data'])
                    setTotal(res.data['total'])
                    setIsLoading(false)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 重置
    const reset = () => {
        // 数据清空
        setSearchName('')
        setSearchPlatform('')
        setSearchProduct('')
        setRate('')
        // 表单清空
        form.resetFields();
        // 关闭搜索状态
        setIsSearch(0)
        // 将当前页设置为第一页
        setPage(1)
        // 如果当前页面是1的话。手动请求数据
        if (page == 1) {
            getAllKolOfPut()
        }
    }

    // 打开联系方式
    const openPhone = (index) => {
        getPhone(date[index]['id'])
    }

    // 关闭联系方式
    const closePhone = () => {
        setContact('-1')
        setIsPhone(false)
    }



    return (
        <div>
            <Spin spinning={isLoading} size="large" tip="数据请求中......" style={{ marginTop: size.height * 0.3 }}>
                <Collapse style={{ marginRight: size.width * 0.06 }}>
                    <Panel header="检索条件">
                        <Form
                            form={form}
                            style={{
                                padding: 24,
                                background: '#fbfbfb',
                            }}
                            labelAlign='left'
                        >
                            <Row gutter={16}>
                                <Col span={5}>
                                    <Form.Item
                                        name="name"
                                        label="KOL名字"
                                    >
                                        <Input
                                            placeholder="请输入KOL名字"
                                            value={searchName}
                                            onChange={(e) => {
                                                setSearchName(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        name="platform"
                                        label="平台"
                                    >
                                        <Input
                                            placeholder="请输入平台"
                                            value={searchPlatfrom}
                                            onChange={(e) => {
                                                setSearchPlatform(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        name="produce"
                                        label="产品"
                                    >
                                        <Input
                                            placeholder="请输入产品"
                                            value={searchProduct}
                                            onChange={(e) => {
                                                setSearchProduct(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        name="rate"
                                        label="评级"
                                    >
                                        <Input
                                            placeholder="请输入评级"
                                            value={rate}
                                            onChange={(e) => {
                                                setRate(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Button type="primary"
                                        onClick={() => {
                                            setPage(1)
                                            setTimeout(search(),2000)
                                            
                                        }}
                                        style={{ marginRight: "10px" }}>检索</Button>
                                    <Button type="primary" onClick={() => reset()} danger>重置</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
                <div style={{ marginTop: size.height * 0.025, marginRight: size.width * 0.06 }}>
                    {/* 表单区域 */}
                    <Table
                        //表格列的配置描述
                        columns={columns}
                        //数据数组
                        dataSource={date}
                        pagination={paginationProps}
                        //表格行 key 的取值
                        rowKey={'id'}
                        scroll={{ x:'80vw', y: `${size.width>1500?'70vh':'65vh'}` }}
                    />
                </div>
            </Spin>


            {/* 查看明细 */}
            <Modal
                visible={lookDetail}
                onCancel={closeDetail}
                width={1000}
                centered={true}
                footer={[]}
            >
                <Descriptions
                    title="明细表"
                    bordered
                    column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                    <Descriptions.Item label="总藏赞">{praise}</Descriptions.Item>
                    <Descriptions.Item label="平均阅读数">{avgRead}</Descriptions.Item>
                    <Descriptions.Item label="场均观看量">{avgView}</Descriptions.Item>
                    <Descriptions.Item label="平均互动量">{avgInteraction}</Descriptions.Item>
                    <Descriptions.Item label="推广产品">{product}</Descriptions.Item>
                    <Descriptions.Item label="合作形式">{status}</Descriptions.Item>
                    <Descriptions.Item label="含税费用">{cost}</Descriptions.Item>
                    <Descriptions.Item label="合作开始时间">{startTime} </Descriptions.Item>
                    <Descriptions.Item label="合作结束时间">{endTime} </Descriptions.Item>
                    <Descriptions.Item label="阅读量">{read}</Descriptions.Item>
                    {/* <Descriptions.Item label="内容连接" span={3}> {contentUrl}</Descriptions.Item> */}
                    <Descriptions.Item label="内容连接"> <a href={contentUrl} target="_blank">点击查看</a></Descriptions.Item>
                    {/* <a href={network} target="_blank">点击查看</a> */}
                    <Descriptions.Item label="互动量">{interaction}</Descriptions.Item>
                    <Descriptions.Item label="阅读成本">{readCost}</Descriptions.Item>
                    <Descriptions.Item label="互动成本">{interactionCost}</Descriptions.Item>
                    <Descriptions.Item label="投放渠道">{platform} </Descriptions.Item>
                    {/* <Descriptions.Item label="账号链接">{platfromUrl} </Descriptions.Item> */}
                    <Descriptions.Item label="KOL名称">{kolName} </Descriptions.Item>
                    <Descriptions.Item label="账号链接"><a href={platfromUrl} target="_blank">点击查看</a> </Descriptions.Item>
                    <Descriptions.Item label="粉丝数">{fans} </Descriptions.Item>
                    <Descriptions.Item label="销量"> {sales}</Descriptions.Item>
                    <Descriptions.Item label="销售额">{sale} </Descriptions.Item>
                    <Descriptions.Item label="ROI">{roi} </Descriptions.Item>

                </Descriptions>
            </Modal>

            {/* 联系方式 */}
            <Modal
                title="联系方式"
                visible={isPhone}
                onCancel={closePhone}
                footer={[]}
            >
                <text>
                    {contact}
                </text>
            </Modal>
        </div>
    )

}

export default Kol