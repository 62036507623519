/* 选款定价时的前端计算方法
author: chen binjie
create time: 2021-11-22

update author: chen binjie
update time: 2021-11-25

注意：
开发测试时的测试方法在代码的最后
*/

/* 将整数向上取整至以9结尾 */
function _mathCeilTo9(x) {
    if (x % 10 === 0) {
        x = x + 1
    } else {
        x = x
    }
    return Math.ceil(x / 10) * 10 - 1
}

/* 将整数向下取整至以9结尾 */
function _mathFloorTo9(x) {
    if (x % 10 === 9) {
        x = x
    } else {
        x = x + 1
    }
    return Math.floor(x / 10 - 1) * 10 + 9
}

/* 将整数向下取整至以10结尾 */
function _mathFloorTo10(x) {
    x = x / 10
    return Math.floor(x) * 10
}

/* 计算可用津贴 */
function _getAvailableAllowance(price = 0, _platformAllowance = []) {
    if (Array.prototype.isPrototypeOf(_platformAllowance) && _platformAllowance.length === 0) {
        return 0;
    } else {
        if (price === 0) {
            return _platformAllowance[0]["consequence"] / _platformAllowance[0]["antecedents"]
        } else {
            return Math.floor(price / _platformAllowance[0]["antecedents"]) * _platformAllowance[0]["consequence"]
        }

    }
}

/* 计算可用平台购物券 */
function _getAvailableCoupon(price, _get_dis = 0, _platformCoupon = []) {
    let _cut = 0
    let _price = 0
    if (Array.prototype.isPrototypeOf(_platformCoupon) && _platformCoupon.length === 0) {
        return {cut: _cut, price: _price};
    } else {
        for (let coupon of _platformCoupon) {
            let antecedents = coupon["antecedents"]
            let consequence = coupon["consequence"]
            if (price >= antecedents) {  // 活动价大于等于券的金额才可使用
                if (_cut < consequence) {  // 找到能使用的最大的一张券
                    _cut = consequence
                    _price = antecedents
                }
            }
        }
        if (_get_dis === 1) {
            return _cut / _price
        } else {
            return {cut: _cut, price: _price}
        }

    }
}

/* 计算可用店铺优惠券 */
function _getAvailableShopCoupon(price, _get_dis = 0, _shopCoupon = []) {
    let _cut = 0
    let _price = 0
    let _discount = 0
    if (Array.prototype.isPrototypeOf(_shopCoupon) && _shopCoupon.length === 0) {
        return 0;
    } else {
        for (let coupon of _shopCoupon) {
            let antecedents = coupon["antecedents"]
            let consequence = coupon["consequence"]
            let _coupon_discount = consequence / antecedents  // 券的折扣
            if (price >= antecedents) {  // 活动价大于等于券的金额才可使用
                if (_discount < _coupon_discount) {  // 找到能使用的最大的一张券
                    _cut = consequence
                    _price = antecedents
                }
            }
        }
        if (_get_dis === 1) {
            return _cut / _price  // 返回折扣
        } else {
            return {cut: _cut, price: _price}  // 返回券的明细
        }

    }
}

/* 根据名称查找专区折扣 */
function _findPartitionDiscount(_participateName, _basicInformation) {
    // 从多件多折专区 和 限时专区中查找对应的折扣
    let partObj = _basicInformation['list_partition_discounts_name'].filter(item => item.name === _participateName);

    if (JSON.stringify(partObj) === '[]') {
        partObj = _basicInformation['list_lim_time_part_discounts_name'].filter(item => item.name === _participateName);
        if (JSON.stringify(partObj) === '[]') {
            return false
        } else {
            return partObj[0]
        }
    } else {
        return partObj[0]
    }
}

/* 根据名称查找所有关联的专区 */
function _findRelationPartition(_participateName, _basicInformation) {
    // 这里可能有 bug
    let _targetPartName = _participateName.split('_')[0];

    let _relationPart = []
    let partObj = _basicInformation['list_partition_discounts_name'].filter(item => item.name === _targetPartName);
    if (JSON.stringify(partObj) !== '[]') {
        _relationPart.push(partObj[0]["name"])
    }
    partObj = _basicInformation['list_lim_time_part_discounts_name'].filter(
        item => item.name.slice(0, _targetPartName.length) === _targetPartName
    );
    if (JSON.stringify(partObj) !== '[]') {
        _relationPart.push(partObj[0]["name"])
    }
    return _relationPart
}

/* 计算价格的主要方法
* 参数说明
* is_participate  是否使用平台购物券
* basicInformation  基础信息数据块
* priceList  价格数据块
* changeLog  改变的值：{到手价: '1000'}{商品券: '1000'}{"活动价": '1159'}{"单件折扣": '90'}
* participate_name 专区名字
* */


export default function calculatePrice(is_participate, is_use_shop_coupon, basicInformation, priceList, changeLog, participateName) {
    function filterPlatformAllowance(value, index, array) {
        if (value['name'] === "津贴")
            return value
    }

    function filterPlatformCoupons(value, index, array) {
        if (value['name'] === "平台")
            return value
    }

    function filterShopCoupons(value, index, array) {
        if (value['name'] === "店铺")
            return value
    }

    // 根据名称查找专区折扣
    //console.log(participateName, basicInformation);
    let objPartDiscount = _findPartitionDiscount(participateName, basicInformation)
    // 津贴
    let platformAllowance = basicInformation['platform_coupons'].filter(filterPlatformAllowance);
    // 平台购物券
    let platformCoupons = basicInformation['platform_coupons'].filter(filterPlatformCoupons);
    // 店铺优惠券券
    let shopCoupons = basicInformation['shop_coupons'].filter(filterShopCoupons);
    // 活动价上限折扣
    let disOfActPriceCap = basicInformation['discount_of_act_price_cap'] / 100;


    // 判定是否使用平台购物券
    let usePlatformCoupons = 0
    if (is_participate.toString() === "0") {
        usePlatformCoupons = 0
    } else if (is_participate.toString() === "1") {
        usePlatformCoupons = 1
    } else {
        //console.log("is_participate 参数错误")
        return 0
    }

    // 判定是否使用商品优惠券
    let useShopCoupons = 0
    if (is_use_shop_coupon.toString() === "0") {
        useShopCoupons = 0
    } else if (is_use_shop_coupon.toString() === "1") {
        useShopCoupons = 1
    } else {
        //console.log("is_use_shop_coupon 参数错误")
        return 0
    }

    // 输入：到手价
    if (changeLog.hasOwnProperty('到手价')) {
        // 修改到手价，修改的是极限到手价！
        let result = [];
        let _price = changeLog['到手价'];
        //console.log("输入: 极限到手价", _price, "修改前极限到手价", priceList['value']['极限到手价']);
        //console.log("输入: 数据", JSON.parse(JSON.stringify(priceList)));

        /*
        这是不使用平台购物券的算法
        */
        priceList['value']['极限到手价'] = _price;

        priceList['value']['极限到手折扣'] = priceList['value']['极限到手价'] / basicInformation['确认吊牌价'];
        priceList['value']['极限到手折扣'] = priceList['value']['极限到手折扣'].toFixed(2);  // 保留两位小数

        priceList['value']['极限到手价-底线价'] = priceList['value']['极限到手价'] - basicInformation['底线价'];
        priceList['value']['极限到手价-底线价'] = priceList['value']['极限到手价-底线价'].toFixed(0);  // 保留两位小数

        // 使用活动价，在不使用平台购物券的情况下进行计算，在 objPriceList 对象中已有【商品券】或【店铺券】保持不变。

        // 临时的数据
        // 当为专区计算时，从专区数据中获取【商品数量】
        let goodsCount = objPartDiscount['antecedents']
        // 当为专区计算时，从专区数据中获取【专区的折扣】
        let partition_discount = 1 - objPartDiscount['consequence'] / 100
        partition_discount = partition_discount.toFixed(2);  // 保留两位小数

        // 参不参与多件的 重要的区别 ！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
        if (participateName === "不参与多件") {
            goodsCount = 1
            partition_discount = 1 - priceList['value']['单件折扣'] // 单件折扣保持不变，无需修改
        }

        // 计算津贴的折扣，可以提到函数的开始
        let allowanceDiscount = _getAvailableAllowance(0, platformAllowance)

        priceList['value']['凑单到手价'] = priceList['value']['极限到手价']

        priceList['value']['折扣计算价'] = (
            (
               Number(priceList['value']['凑单到手价']) * Number(goodsCount)
                + Number(priceList['value']['商品券凑10'])
                + Number(priceList['value']['店铺券立减'])
            )
            / (1 - partition_discount - allowanceDiscount)
        )
        priceList['value']['折扣计算价'] = priceList['value']['折扣计算价'].toFixed(0);  // 保留两位小数

        priceList['value']['活动价'] = priceList['value']['折扣计算价'] / goodsCount
        priceList['value']['活动价'] = priceList['value']['活动价'].toFixed(0);  // 保留两位小数

        priceList['value']['可用津贴'] = _getAvailableAllowance(priceList['value']['折扣计算价'], platformAllowance)

        priceList['value']['折扣立减'] = priceList['value']['折扣计算价'] * partition_discount
        priceList['value']['折扣立减'] = priceList['value']['折扣立减'].toFixed(0);  // 保留两位小数

        priceList['value']['到手价'] = (
            (
                priceList['value']['折扣计算价']
                - priceList['value']['折扣立减']
                - priceList['value']['可用津贴']
                - priceList['value']['商品券凑10']
                - priceList['value']['店铺券立减']
            )
            / goodsCount
        )
        priceList['value']['到手价'] = priceList['value']['到手价'].toFixed(0);  // 保留两位小数

        priceList['value']['到手折扣'] = priceList['value']['到手价'] / basicInformation['确认吊牌价'];
        priceList['value']['到手折扣'] = priceList['value']['到手折扣'].toFixed(2);  // 保留两位小数

        priceList['value']['到手价-底线价'] = priceList['value']['到手价'] - basicInformation['底线价'];
        priceList['value']['到手价-底线价'] = priceList['value']['到手价-底线价'].toFixed(0);  // 保留两位小数

        /*
        这是使用平台购物券的算法
        */
        priceList['value']['使用平台购物券极限到手价'] = _price;

        priceList['value']['使用平台购物券极限到手折扣'] = priceList['value']['使用平台购物券极限到手价'] / basicInformation['确认吊牌价'];
        priceList['value']['使用平台购物券极限到手折扣'] = priceList['value']['使用平台购物券极限到手折扣'].toFixed(2);  // 保留两位小数

        priceList['value']['使用平台购物券极限到手价-底线价'] = priceList['value']['使用平台购物券极限到手价'] - basicInformation['底线价'];
        priceList['value']['使用平台购物券极限到手价-底线价'] = priceList['value']['使用平台购物券极限到手价-底线价'].toFixed(0);  // 保留两位小数

        let objAvailableCoupon = _getAvailableCoupon(
            priceList['value']['使用平台购物券极限到手价'] * goodsCount, 0, platformCoupons
        );  // 能够使用的平台购物券
        priceList['value']['平台购物券条件'] = Number(objAvailableCoupon['price'])
        priceList['value']['平台购物券立减'] = Number(objAvailableCoupon['cut'])

        priceList['value']['使用平台购物券凑单到手价'] = priceList['value']['使用平台购物券极限到手价']

        priceList['value']['使用平台购物券折扣计算价'] = (
            (
                Number(priceList['value']['使用平台购物券凑单到手价']) * Number(goodsCount)
                + Number(priceList['value']['平台购物券立减'])
                + Number(priceList['value']['使用平台购物券商品券凑10'])
                + Number(priceList['value']['使用平台购物券店铺券立减'])
            )
            / (1 - partition_discount - allowanceDiscount)
        )
        priceList['value']['使用平台购物券折扣计算价'] = priceList['value']['使用平台购物券折扣计算价'].toFixed(0);  // 保留两位小数
        priceList['value']['使用平台购物券活动价'] = priceList['value']['使用平台购物券折扣计算价'] / goodsCount
        priceList['value']['使用平台购物券活动价'] = priceList['value']['使用平台购物券活动价'].toFixed(0);  // 保留两位小数

        priceList['value']['使用平台购物券可用津贴'] = _getAvailableAllowance(priceList['value']['使用平台购物券折扣计算价'], platformAllowance)

        priceList['value']['使用平台购物券折扣立减'] = priceList['value']['使用平台购物券折扣计算价'] * partition_discount
        priceList['value']['使用平台购物券折扣立减'] = priceList['value']['使用平台购物券折扣立减'].toFixed(0);  // 保留两位小数

        priceList['value']['使用平台购物券到手价'] = (
            (
                priceList['value']['使用平台购物券折扣计算价']
                - priceList['value']['使用平台购物券折扣立减'] // 专区折扣
                - priceList['value']['使用平台购物券可用津贴']  // 津贴折扣
                - priceList['value']['平台购物券立减']   // 平台购物券
                - priceList['value']['使用平台购物券商品券凑10']   // 商品券
                - priceList['value']['使用平台购物券店铺券立减']  // 店铺券
            )
            / goodsCount
        )
        priceList['value']['使用平台购物券到手价'] = priceList['value']['使用平台购物券到手价'].toFixed(0);  // 保留两位小数

        priceList['value']['使用平台购物券到手折扣'] = (priceList['value']['使用平台购物券到手价'] / basicInformation['确认吊牌价']);
        priceList['value']['使用平台购物券到手折扣'] = priceList['value']['使用平台购物券到手折扣'].toFixed(2);  // 保留两位小数

        priceList['value']['使用平台购物券到手价-底线价'] = priceList['value']['使用平台购物券到手价'] - basicInformation['底线价'];

        // 这里需要替换一下，别问为什么，解释起来太难了。总之就是因为使用平台购物券，造成修改到手价的时候需要替换！！！！！
        let firstPriceList = JSON.parse(JSON.stringify(priceList))
        let firstUseCouponsPriceList = JSON.parse(JSON.stringify(priceList))
        let thirdPriceList = JSON.parse(JSON.stringify(priceList))
        let thirdUseCouponsPriceList = JSON.parse(JSON.stringify(priceList))

        //console.log("不使用平台购物券的活动价向上去整", firstPriceList['value']['活动价'], _mathCeilTo9(Number(firstPriceList['value']['活动价'])))
        firstPriceList = calculatePrice(
            is_participate,
            is_use_shop_coupon,
            basicInformation,
            firstPriceList,
            {"活动价": _mathCeilTo9(Number(firstPriceList['value']['活动价']))},
            participateName)
        // //console.log("firstPriceList", JSON.parse(JSON.stringify(firstPriceList)))

        //console.log("使用平台购物券的活动价向上去整", firstUseCouponsPriceList['value']['使用平台购物券活动价'], _mathCeilTo9(Number(firstUseCouponsPriceList['value']['使用平台购物券活动价'])))
        firstUseCouponsPriceList = calculatePrice(
            is_participate,
            is_use_shop_coupon,
            basicInformation,
            firstUseCouponsPriceList,
            {"活动价": _mathCeilTo9(Number(firstUseCouponsPriceList['value']['使用平台购物券活动价']))},
            participateName)
        // //console.log("firstUseCouponsPriceList", JSON.parse(JSON.stringify(firstUseCouponsPriceList)))

        // 过滤出使用平台购物券的字段进行更新对应的值
        let _replaceKeys = []
        for (let _key in firstPriceList["value"]) {
            _replaceKeys.push(_key);
        }
        _replaceKeys = _replaceKeys.filter(item => item.indexOf("平台购物券") !== -1);
        for (let _key of _replaceKeys) {
            firstPriceList["value"][_key] = firstUseCouponsPriceList["value"][_key]
        }
        // //console.log("替换数据之后的 firstPriceList", JSON.parse(JSON.stringify(firstPriceList)))
        let resultFirst = JSON.parse(JSON.stringify(firstPriceList))

        // //console.log("中间", priceList['value']['活动价'])

        //console.log("不使用平台购物券的活动价向下去整", thirdPriceList['value']['活动价'], _mathFloorTo9(Number(thirdPriceList['value']['活动价'])))
        thirdPriceList = calculatePrice(
            is_participate,
            is_use_shop_coupon,
            basicInformation,
            thirdPriceList,
            {"活动价": _mathFloorTo9(Number(thirdPriceList['value']['活动价']))},
            participateName)
        // //console.log("thirdPriceList", thirdPriceList)

        //console.log("使用平台购物券的活动价向上去整", thirdUseCouponsPriceList['value']['使用平台购物券活动价'], _mathCeilTo9(Number(thirdUseCouponsPriceList['value']['使用平台购物券活动价'])))
        thirdUseCouponsPriceList = calculatePrice(
            is_participate,
            is_use_shop_coupon,
            basicInformation,
            thirdUseCouponsPriceList,
            {"活动价": _mathCeilTo9(Number(thirdUseCouponsPriceList['value']['使用平台购物券活动价']))},
            participateName)
        // //console.log("thirdUseCouponsPriceList", thirdUseCouponsPriceList)

        for (let _key of _replaceKeys) {
            thirdPriceList["value"][_key] = thirdUseCouponsPriceList["value"][_key]
        }
        // //console.log("替换数据之后的 firstPriceList", JSON.parse(JSON.stringify(firstPriceList)))
        let resultThird = JSON.parse(JSON.stringify(thirdPriceList))

        result[0] = {"data": resultFirst, "check": 0}
        result[1] = {"data": priceList}
        result[2] = {"data": resultThird, "check": 0}
        return result
        // }
    }

    // 输入：活动价
    if (changeLog.hasOwnProperty('活动价')) {

        let objPriceList = JSON.parse(JSON.stringify(priceList))

        let _price = changeLog['活动价'];
        //console.log("输入: 活动价", _price);
        //console.log("输入: 数据", JSON.parse(JSON.stringify(priceList)));

        /*
        这是不使用平台购物券的算法
        */
        objPriceList['value']['活动价'] = _price;
        // 使用活动价，在不使用平台购物券的情况下进行计算，在 objPriceList 对象中已有商品券保持不变。

        // 临时的数据
        // 当为专区计算时，从专区数据中获取【商品数量】
        let goodsCount = objPartDiscount['antecedents']
        // 当为专区计算时，从专区数据中获取【专区的折扣】
        let partition_discount = 1 - objPartDiscount['consequence'] / 100
        // 参不参与多件的 重要的区别 ！！！
        if (participateName === "不参与多件") {
            goodsCount = 1
            partition_discount = 1 - priceList['value']['单件折扣']
        }

        // 计算津贴的折扣，可以提到函数的开始
        let allowanceDiscount = _getAvailableAllowance(0, platformAllowance)

        // 折扣计算价, 考虑活动价上线最低折扣
        objPriceList['value']['折扣计算价'] = objPriceList['value']['活动价'] * goodsCount

        objPriceList['value']['折扣立减'] = objPriceList['value']['折扣计算价'] * partition_discount
        objPriceList['value']['折扣立减'] = objPriceList['value']['折扣立减'].toFixed(0);  // 保留两位小数

        objPriceList['value']['可用津贴'] = _getAvailableAllowance(objPriceList['value']['折扣计算价'], platformAllowance)

        // 可用的店铺券
        if (useShopCoupons === 1) {
            let objAvailableShopCoupon = _getAvailableShopCoupon(
                priceList['value']['折扣计算价'], 0, shopCoupons
            );  // 能够使用的平台购物券
            objPriceList['value']['店铺券条件'] = Number(objAvailableShopCoupon['price'])
            objPriceList['value']['店铺券立减'] = Number(objAvailableShopCoupon['cut'])
            objPriceList['value']['商品券凑10'] = 0
        } else {
            objPriceList['value']['店铺券条件'] = 0
            objPriceList['value']['店铺券立减'] = 0
        }

        // 到手价
        objPriceList['value']['到手价'] = (
            (
                objPriceList['value']['折扣计算价']
                - objPriceList['value']['可用津贴']
                - objPriceList['value']['折扣立减']
                - objPriceList['value']['商品券凑10']
                - objPriceList['value']['店铺券立减']
            ) / goodsCount
        )
        objPriceList['value']['到手价'] = objPriceList['value']['到手价'].toFixed(0);  // 保留两位小数

        objPriceList['value']['到手折扣'] = objPriceList['value']['到手价'] / basicInformation['确认吊牌价'];
        objPriceList['value']['到手折扣'] = objPriceList['value']['到手折扣'].toFixed(2);  // 保留两位小数

        objPriceList['value']['到手价-底线价'] = objPriceList['value']['到手价'] - basicInformation['底线价'];

        objPriceList['value']['凑单到手价'] = (
            (
                objPriceList['value']['折扣计算价'] * (1 - allowanceDiscount - partition_discount)
                - objPriceList['value']['商品券凑10']
                - objPriceList['value']['店铺券立减']
            )
            / goodsCount
        )
        objPriceList['value']['凑单到手价'] = objPriceList['value']['凑单到手价'].toFixed(0);  // 保留两位小数

        objPriceList['value']['极限到手价'] = Math.min(objPriceList['value']['凑单到手价'], objPriceList['value']['到手价'])

        objPriceList['value']['极限到手折扣'] = objPriceList['value']['极限到手价'] / basicInformation['确认吊牌价'];
        objPriceList['value']['极限到手折扣'] = objPriceList['value']['极限到手折扣'].toFixed(2);  // 保留两位小数

        objPriceList['value']['极限到手价-底线价'] = objPriceList['value']['极限到手价'] - basicInformation['底线价'];

        /*
        这是使用平台购物券的算法
        */
        objPriceList['value']['使用平台购物券活动价'] = _price;

        objPriceList['value']['使用平台购物券折扣计算价'] = (
            objPriceList['value']['使用平台购物券活动价'] * goodsCount
        )
        objPriceList['value']['使用平台购物券折扣计算价'] = objPriceList['value']['使用平台购物券折扣计算价'].toFixed(0);

        objPriceList['value']['使用平台购物券折扣立减'] = (
            objPriceList['value']['使用平台购物券折扣计算价'] * partition_discount
        )
        objPriceList['value']['使用平台购物券折扣立减'] = objPriceList['value']['使用平台购物券折扣立减'].toFixed(0);  // 保留两位小数

        // 可用津贴
        objPriceList['value']['使用平台购物券可用津贴'] = _getAvailableAllowance(objPriceList['value']['使用平台购物券折扣计算价'], platformAllowance)

        // 能够使用的平台购物券的折扣
        let objAvailableCoupon = _getAvailableCoupon(
            objPriceList['value']['使用平台购物券折扣计算价'], 0, platformCoupons);
        objPriceList['value']['平台购物券条件'] = objAvailableCoupon['price']
        objPriceList['value']['平台购物券立减'] = objAvailableCoupon['cut']

        // 可用的店铺券
        if (useShopCoupons === 1) {
            let objAvailableShopCoupon = _getAvailableShopCoupon(
                priceList['value']['使用平台购物券折扣计算价'], 0, shopCoupons
            );  // 能够使用的平台购物券
            objPriceList['value']['使用平台购物券店铺券条件'] = Number(objAvailableShopCoupon['price'])
            objPriceList['value']['使用平台购物券店铺券立减'] = Number(objAvailableShopCoupon['cut'])
            objPriceList['value']['使用平台购物券商品券凑10'] = 0
        } else {
            objPriceList['value']['使用平台购物券店铺券条件'] = 0
            objPriceList['value']['使用平台购物券店铺券立减'] = 0
        }

        objPriceList['value']['使用平台购物券到手价'] = (
            (
                objPriceList['value']['使用平台购物券折扣计算价']
                - objPriceList['value']['使用平台购物券可用津贴']
                - objPriceList['value']['使用平台购物券折扣立减']
                - objPriceList['value']['平台购物券立减']
                - objPriceList['value']['使用平台购物券商品券凑10']
                - objPriceList['value']['使用平台购物券店铺券立减']
            )
            / goodsCount
        )  // 到手价需要向上去整至各位
        objPriceList['value']['使用平台购物券到手价'] = Math.ceil(objPriceList['value']['使用平台购物券到手价']).toFixed(0);  // 保留两位小数

        objPriceList['value']['使用平台购物券到手折扣'] = (
            objPriceList['value']['使用平台购物券到手价'] / basicInformation['确认吊牌价']
        );
        objPriceList['value']['使用平台购物券到手折扣'] = objPriceList['value']['使用平台购物券到手折扣'].toFixed(2);  // 保留两位小数

        objPriceList['value']['使用平台购物券到手价-底线价'] = objPriceList['value']['使用平台购物券到手价'] - basicInformation['底线价'];
        objPriceList['value']['使用平台购物券到手价-底线价'] = objPriceList['value']['使用平台购物券到手价-底线价'].toFixed(0);  // 保留两位小数

        objPriceList['value']['使用平台购物券凑单到手价'] = (
            (
                objPriceList['value']['使用平台购物券折扣计算价'] * (1 - allowanceDiscount - partition_discount)
                - objPriceList['value']['平台购物券立减']
                - objPriceList['value']['使用平台购物券商品券凑10']
                - objPriceList['value']['使用平台购物券店铺券立减']
            )
            / goodsCount
        )
        objPriceList['value']['使用平台购物券凑单到手价'] = objPriceList['value']['使用平台购物券凑单到手价'].toFixed(0);  // 保留两位小数

        objPriceList['value']['使用平台购物券极限到手价'] = Math.min(
            objPriceList['value']['使用平台购物券凑单到手价'], objPriceList['value']['使用平台购物券到手价']
        )
        objPriceList['value']['使用平台购物券极限到手折扣'] = objPriceList['value']['使用平台购物券极限到手价'] / basicInformation['确认吊牌价'];
        objPriceList['value']['使用平台购物券极限到手折扣'] = objPriceList['value']['使用平台购物券极限到手折扣'].toFixed(2);  // 保留两位小数

        objPriceList['value']['使用平台购物券极限到手价-底线价'] = objPriceList['value']['使用平台购物券极限到手价'] - basicInformation['底线价'];
        return objPriceList
    }

    // 输入：商品券
    if (changeLog.hasOwnProperty('商品券')) {

        let _price = changeLog['商品券'];
        //console.log("输入: 商品券", _price);

        let objPriceList = JSON.parse(JSON.stringify(priceList))
        //console.log("输入: 数据", JSON.parse(JSON.stringify(priceList)));
        /*
        这是不使用平台购物券的算法
        */
        // 修改商品券进行计算时，【活动价】不变去进行计算！！！
        objPriceList['value']['商品券凑10'] = _price

        // 临时的数据
        // 当为专区计算时，从专区数据中获取【商品数量】
        let goodsCount = objPartDiscount['antecedents']
        // 当为专区计算时，从专区数据中获取【专区的折扣】
        let partition_discount = 1 - objPartDiscount['consequence'] / 100
        // 参不参与多件的 重要的区别 ！！！
        if (participateName === "不参与多件") {
            goodsCount = 1
            partition_discount = 1 - priceList['value']['单件折扣']
        }

        // 计算津贴的折扣，可以提到函数的开始
        let allowanceDiscount = _getAvailableAllowance(0, platformAllowance)

        // 折扣计算价, 考虑活动价上线最低折扣
        objPriceList['value']['折扣计算价'] = objPriceList['value']['活动价'] * goodsCount

        objPriceList['value']['折扣立减'] = objPriceList['value']['折扣计算价'] * partition_discount

        objPriceList['value']['可用津贴'] = _getAvailableAllowance(objPriceList['value']['折扣计算价'], platformAllowance)

        // 店铺券！！！但是当商品券不为0的时候，店铺券不能使用。即店铺券和商品券互斥。
        if (_price.toString() !== "0") {
            objPriceList['value']['店铺券条件'] = 0
            objPriceList['value']['店铺券立减'] = 0
        } else {
            let objAvailableShopCoupon = _getAvailableShopCoupon(
                priceList['value']['折扣计算价'], 0, shopCoupons
            );  // 能够使用的平台购物券
            objPriceList['value']['店铺券条件'] = Number(objAvailableShopCoupon['price'])
            objPriceList['value']['店铺券立减'] = Number(objAvailableShopCoupon['cut'])
        }

        objPriceList['value']['到手价'] = (
            (
                objPriceList['value']['折扣计算价']
                - objPriceList['value']['可用津贴']
                - objPriceList['value']['折扣立减']
                - objPriceList['value']['商品券凑10']
                - objPriceList['value']['店铺券立减']
            ) / goodsCount
        )
        objPriceList['value']['到手价'] = objPriceList['value']['到手价'].toFixed(0);  // 保留两位小数

        objPriceList['value']['到手折扣'] = objPriceList['value']['到手价'] / basicInformation['确认吊牌价'];
        objPriceList['value']['到手折扣'] = objPriceList['value']['到手折扣'].toFixed(2);  // 保留两位小数

        objPriceList['value']['到手价-底线价'] = objPriceList['value']['到手价'] - basicInformation['底线价'];

        objPriceList['value']['凑单到手价'] = (
            (
                objPriceList['value']['折扣计算价'] * (1 - allowanceDiscount - partition_discount)
                - objPriceList['value']['商品券凑10']
                - objPriceList['value']['店铺券立减']
            )
            / goodsCount
        )
        objPriceList['value']['凑单到手价'] = objPriceList['value']['凑单到手价'].toFixed(0);  // 保留两位小数

        objPriceList['value']['极限到手价'] = Math.min(objPriceList['value']['凑单到手价'], objPriceList['value']['到手价'])

        objPriceList['value']['极限到手折扣'] = objPriceList['value']['极限到手价'] / basicInformation['确认吊牌价'];
        objPriceList['value']['极限到手折扣'] = objPriceList['value']['极限到手折扣'].toFixed(2);  // 保留两位小数

        objPriceList['value']['极限到手价-底线价'] = objPriceList['value']['极限到手价'] - basicInformation['底线价'];

        /*
        这是使用平台购物券的算法
        */
        objPriceList['value']['使用平台购物券商品券凑10'] = _price

        // 修改商品券进行计算时，【活动价】不变去进行计算！！！

        objPriceList['value']['使用平台购物券折扣计算价'] = (
            objPriceList['value']['使用平台购物券活动价'] * goodsCount
        )
        objPriceList['value']['使用平台购物券折扣计算价'] = objPriceList['value']['使用平台购物券折扣计算价'].toFixed(0);

        objPriceList['value']['使用平台购物券折扣立减'] = (
            objPriceList['value']['使用平台购物券折扣计算价'] * partition_discount
        )
        objPriceList['value']['使用平台购物券折扣立减'] = objPriceList['value']['使用平台购物券折扣立减'].toFixed(0);  // 保留两位小数

        // 可用津贴
        objPriceList['value']['使用平台购物券可用津贴'] = _getAvailableAllowance(objPriceList['value']['使用平台购物券折扣计算价'], platformAllowance)

        // 能够使用的平台购物券的折扣
        let objAvailableCoupon = _getAvailableCoupon(objPriceList['value']['使用平台购物券折扣计算价'], 0, platformCoupons);
        objPriceList['value']['平台购物券条件'] = objAvailableCoupon['price']
        objPriceList['value']['平台购物券立减'] = objAvailableCoupon['cut']

        // 可用的店铺券
        if (_price.toString() !== "0") {
            objPriceList['value']['使用平台购物券店铺券条件'] = 0
            objPriceList['value']['使用平台购物券店铺券立减'] = 0
        } else {
            let objAvailableShopCoupon = _getAvailableShopCoupon(
                priceList['value']['使用平台购物券折扣计算价'], 0, shopCoupons
            );  // 能够使用的平台购物券
            objPriceList['value']['使用平台购物券店铺券条件'] = Number(objAvailableShopCoupon['price'])
            objPriceList['value']['使用平台购物券店铺券立减'] = Number(objAvailableShopCoupon['cut'])
        }

        objPriceList['value']['使用平台购物券到手价'] = (
            (
                objPriceList['value']['使用平台购物券折扣计算价']
                - objPriceList['value']['使用平台购物券可用津贴']
                - objPriceList['value']['使用平台购物券折扣立减']
                - objPriceList['value']['平台购物券立减']
                - objPriceList['value']['使用平台购物券商品券凑10']
                - objPriceList['value']['使用平台购物券店铺券立减']
            )
            / goodsCount
        )  // 到手价需要向上去整至各位
        objPriceList['value']['使用平台购物券到手价'] = Math.ceil(objPriceList['value']['使用平台购物券到手价']).toFixed(0);  // 保留两位小数

        objPriceList['value']['使用平台购物券到手折扣'] = (
            objPriceList['value']['使用平台购物券到手价'] / basicInformation['确认吊牌价']
        );
        objPriceList['value']['使用平台购物券到手折扣'] = objPriceList['value']['使用平台购物券到手折扣'].toFixed(2);  // 保留两位小数

        objPriceList['value']['使用平台购物券到手价-底线价'] = objPriceList['value']['使用平台购物券到手价'] - basicInformation['底线价'];
        objPriceList['value']['使用平台购物券到手价-底线价'] = objPriceList['value']['使用平台购物券到手价-底线价'].toFixed(0);  // 保留两位小数

        objPriceList['value']['使用平台购物券凑单到手价'] = (
            (
                objPriceList['value']['使用平台购物券折扣计算价'] * (1 - allowanceDiscount - partition_discount)
                - objPriceList['value']['平台购物券立减']
                - objPriceList['value']['使用平台购物券商品券凑10']
                - objPriceList['value']['使用平台购物券店铺券立减']
            )
            / goodsCount
        )
        objPriceList['value']['使用平台购物券凑单到手价'] = objPriceList['value']['使用平台购物券凑单到手价'].toFixed(0);  // 保留两位小数

        objPriceList['value']['使用平台购物券极限到手价'] = Math.min(
            objPriceList['value']['使用平台购物券凑单到手价'], objPriceList['value']['使用平台购物券到手价']
        )
        objPriceList['value']['使用平台购物券极限到手折扣'] = objPriceList['value']['使用平台购物券极限到手价'] / basicInformation['确认吊牌价'];
        objPriceList['value']['使用平台购物券极限到手折扣'] = objPriceList['value']['使用平台购物券极限到手折扣'].toFixed(2);  // 保留两位小数

        objPriceList['value']['使用平台购物券极限到手价-底线价'] = objPriceList['value']['使用平台购物券极限到手价'] - basicInformation['底线价'];
        return objPriceList
    }

    // 输入：单件折扣
    if (changeLog.hasOwnProperty('单件折扣')) {
        // 页面上填写 80折。则传入之后应该是 0.2
        let _discount = changeLog['单件折扣'];
        //console.log("输入: 单件折扣", _discount);

        let objPrice1 = JSON.parse(JSON.stringify(priceList))
        //console.log("输入: 数据", JSON.parse(JSON.stringify(priceList)));
        /*
        这是不使用平台购物券的算法
        */
        // 不参与多件的折扣为单件折扣
        let partitionDiscount = _discount
        objPrice1['value']['单件折扣'] = 1 - partitionDiscount
        objPrice1['value']['单件折扣'] = objPrice1['value']['单件折扣'].toFixed(2);  // 保留两位小数

        // 修改【单件折扣】进行计算时，【活动价】【商品券】不变去进行计算！！！！！
        let goodsCount = 1  // 单件折扣商品数量为 1

        // 计算津贴的折扣，可以提到函数的开始
        let allowanceDiscount = _getAvailableAllowance(0, platformAllowance)

        // 折扣计算价, 考虑活动价上线最低折扣
        objPrice1['value']['折扣计算价'] = objPrice1['value']['活动价'] * goodsCount
        objPrice1['value']['折扣立减'] = objPrice1['value']['折扣计算价'] * partitionDiscount

        objPrice1['value']['可用津贴'] = _getAvailableAllowance(objPrice1['value']['折扣计算价'], platformAllowance)

        // 店铺券！！！但是当商品券不为0的时候，店铺券不能使用。即店铺券和商品券互斥。
        // if (objPrice1['value']['商品券'].toString() !== "0") {
        //     objPriceList['value']['店铺券条件'] = 0
        //     objPriceList['value']['店铺券立减'] = 0
        // } else {
        //     let objAvailableShopCoupon = _getAvailableShopCoupon(
        //         priceList['value']['折扣计算价'], 0, shopCoupons
        //     );  // 能够使用的平台购物券
        //     objPriceList['value']['店铺券条件'] = Number(objAvailableShopCoupon['price'])
        //     objPriceList['value']['店铺券立减'] = Number(objAvailableShopCoupon['cut'])
        // }

        objPrice1['value']['到手价'] = (
            (
                objPrice1['value']['折扣计算价']
                - objPrice1['value']['可用津贴']
                - objPrice1['value']['折扣立减']
                - objPrice1['value']['商品券凑10']
                - objPrice1['value']['店铺券立减']
            ) / goodsCount
        )
        objPrice1['value']['到手价'] = objPrice1['value']['到手价'].toFixed(0);  // 保留两位小数

        objPrice1['value']['到手折扣'] = objPrice1['value']['到手价'] / basicInformation['确认吊牌价'];
        objPrice1['value']['到手折扣'] = objPrice1['value']['到手折扣'].toFixed(2);  // 保留两位小数

        objPrice1['value']['到手价-底线价'] = objPrice1['value']['到手价'] - basicInformation['底线价'];

        objPrice1['value']['凑单到手价'] = (
            (
                objPrice1['value']['折扣计算价'] * (1 - allowanceDiscount - partitionDiscount)
                - objPrice1['value']['商品券凑10']
                - objPrice1['value']['店铺券立减']
            )
            / goodsCount
        )
        objPrice1['value']['凑单到手价'] = objPrice1['value']['凑单到手价'].toFixed(0);  // 保留两位小数

        objPrice1['value']['极限到手价'] = Math.min(objPrice1['value']['凑单到手价'], objPrice1['value']['到手价'])

        objPrice1['value']['极限到手折扣'] = objPrice1['value']['极限到手价'] / basicInformation['确认吊牌价'];
        objPrice1['value']['极限到手折扣'] = objPrice1['value']['极限到手折扣'].toFixed(2);  // 保留两位小数

        objPrice1['value']['极限到手价-底线价'] = objPrice1['value']['极限到手价'] - basicInformation['底线价'];

        /*
        这是使用平台购物券的算法
        */
        objPrice1['value']['使用平台购物券单件折扣'] = 1 - partitionDiscount

        objPrice1['value']['使用平台购物券折扣计算价'] = (
            objPrice1['value']['使用平台购物券活动价'] * goodsCount
        )
        objPrice1['value']['使用平台购物券折扣计算价'] = objPrice1['value']['使用平台购物券折扣计算价'].toFixed(0);

        objPrice1['value']['使用平台购物券折扣立减'] = (
            objPrice1['value']['使用平台购物券折扣计算价'] * partitionDiscount
        )
        objPrice1['value']['使用平台购物券折扣立减'] = objPrice1['value']['使用平台购物券折扣立减'].toFixed(0);  // 保留两位小数

        // 可用津贴
        objPrice1['value']['使用平台购物券可用津贴'] = _getAvailableAllowance(objPrice1['value']['使用平台购物券折扣计算价'], platformAllowance)

        // 能够使用的平台购物券的折扣
        let objAvailableCoupon = _getAvailableCoupon(objPrice1['value']['使用平台购物券折扣计算价'], 0, platformCoupons);
        objPrice1['value']['平台购物券条件'] = objAvailableCoupon['price']
        objPrice1['value']['平台购物券立减'] = objAvailableCoupon['cut']

        objPrice1['value']['使用平台购物券到手价'] = (
            (
                objPrice1['value']['使用平台购物券折扣计算价']
                - objPrice1['value']['使用平台购物券可用津贴']
                - objPrice1['value']['使用平台购物券折扣立减']
                - objPrice1['value']['平台购物券立减']
                - objPrice1['value']['使用平台购物券商品券凑10']
                - objPrice1['value']['使用平台购物券店铺券立减']
            )
            / goodsCount
        )  // 到手价需要向上去整至各位
        objPrice1['value']['使用平台购物券到手价'] = Math.ceil(objPrice1['value']['使用平台购物券到手价']).toFixed(0);  // 保留两位小数

        objPrice1['value']['使用平台购物券到手折扣'] = (
            objPrice1['value']['使用平台购物券到手价'] / basicInformation['确认吊牌价']
        );
        objPrice1['value']['使用平台购物券到手折扣'] = objPrice1['value']['使用平台购物券到手折扣'].toFixed(2);  // 保留两位小数

        objPrice1['value']['使用平台购物券到手价-底线价'] = objPrice1['value']['使用平台购物券到手价'] - basicInformation['底线价'];
        objPrice1['value']['使用平台购物券到手价-底线价'] = objPrice1['value']['使用平台购物券到手价-底线价'].toFixed(0);  // 保留两位小数

        objPrice1['value']['使用平台购物券凑单到手价'] = (
            (
                objPrice1['value']['使用平台购物券折扣计算价'] * (1 - allowanceDiscount - partitionDiscount)
                - objPrice1['value']['平台购物券立减']
                - objPrice1['value']['使用平台购物券商品券凑10']
                - objPrice1['value']['使用平台购物券店铺券立减']
            )
            / goodsCount
        )
        objPrice1['value']['使用平台购物券凑单到手价'] = objPrice1['value']['使用平台购物券凑单到手价'].toFixed(0);  // 保留两位小数

        objPrice1['value']['使用平台购物券极限到手价'] = Math.min(
            objPrice1['value']['使用平台购物券凑单到手价'], objPrice1['value']['使用平台购物券到手价']
        )
        objPrice1['value']['使用平台购物券极限到手折扣'] = objPrice1['value']['使用平台购物券极限到手价'] / basicInformation['确认吊牌价'];
        objPrice1['value']['使用平台购物券极限到手折扣'] = objPrice1['value']['使用平台购物券极限到手折扣'].toFixed(2);  // 保留两位小数

        objPrice1['value']['使用平台购物券极限到手价-底线价'] = objPrice1['value']['使用平台购物券极限到手价'] - basicInformation['底线价'];
        return objPrice1
    }
}

/* 计算相关联的专区的价格的方法，因为存在专区绑定，当修改活动价是，相关联的专区需要同时变更活动价
* 参数说明
* data  一行完整的商品数据
* participateName  变更的专区的名称
* priceListIndex  变更的活动价在专区推荐价格中的下标
* */

function calculateRelationPartitionPrice(data, participateName, priceListIndex) {
// function calculateRelationPartitionPrice(data, participateName, priceListIndex) {
    // let objPrice1 = JSON.parse(JSON.stringify(priceList))
    //console.log("计算相关联的专区价格");
    //console.log("数据变更的位置：", participateName, "下标", priceListIndex);
    //console.log("输入：数据", data);

    // 首先取到活动价变更的数据块
    let objExamplePriceData = data[participateName]
    //console.log("objExamplePriceData", objExamplePriceData);

    // 变更的价格
    let changeActPrice = objExamplePriceData['priceList'][priceListIndex]['value']['活动价']
    //console.log("活动价变更的数据块", objExamplePriceData)
    //console.log("活动价变更", changeActPrice)

    // 查找所有的关联专区
    let listRelationParts = _findRelationPartition(participateName, data["basicInformation"])
    //console.log("根据名称查找所有关联的专区", listRelationParts)

    for (let value of listRelationParts) {
        for (let _objPriceIndex in data[value]['priceList']) {
            let bestValueIndex = 0
            if (participateName === value && _objPriceIndex == priceListIndex) {
                continue;
            }
            //console.log("----------")
            //console.log("计算之前的数据", data[value]['priceList'][_objPriceIndex])

            // 依照参照的 活动价分别更新每一个价格数据块
            let _objPrice = JSON.parse(JSON.stringify(data[value]['priceList'][_objPriceIndex]))
            data[value]['priceList'][_objPriceIndex] = calculatePrice(
                1,
                data["is_use_shop_coupon"],
                data['basicInformation'],
                _objPrice,
                {"活动价": changeActPrice},
                participateName
            )
            // 依照参照的活动价计算完成之后，还需要更新 bestValue 的值
            //console.log("被标记的价格数据块", _objPrice['sign'])
            if (_objPrice['sign'] > bestValueIndex) {
                for (let _key in data[value]['priceList'][_objPriceIndex]['value']) {
                    data[value]['bestValue'] = data[value]['priceList'][_objPriceIndex]['value']
                }

            }
            //console.log("计算之后的数据", data[value]['priceList'][_objPriceIndex])
        }
    }
    //console.log("联动计算结束数据", data)
    return data
}

/* 测试数据 */

// 测试：计算价格的主要方法
// 是否参加活动
let is_participate = 1;
// 基础信息
let basicInformation = {
    "底线折扣": 0.3,
    "历史最低标价": 1289,
    "确认吊牌价": 2489,
    "底线价": 746,
    "活动价": 1289,
    "活动价的历史最低折扣": 1,
    "活动价折扣": 0.52,
    "platform_coupons": [
        {
            "name": "津贴",
            "antecedents": 200,
            "consequence": 20
        }
    ],
    "shop_coupons": [
        {
            "name": "店铺",
            "antecedents": 1500,
            "consequence": 100
        },
        {
            "name": "店铺",
            "antecedents": 2500,
            "consequence": 200
        },
        {
            "name": "店铺",
            "antecedents": 3500,
            "consequence": 250
        },
        {
            "name": "店铺",
            "antecedents": 4500,
            "consequence": "350"
        }
    ],
    "dict_goods_coupons": [],
    "list_partition_discounts_name": [
        {
            "name": "3件80折",
            "value": "3'件80折",
            "antecedents": "3",
            "consequence": "80"
        }
    ],
    "list_lim_time_part_discounts_name": [
        {
            "name": "3件80折_前2后4",
            "value": "4'件75折",
            "antecedents": "4",
            "consequence": "75"
        }
    ],
    "discount_of_act_price_cap": "100"
}
// 价格数据
let priceList = {
    "title": [
        "活动价",
        "到手价",
        "到手折扣",
        "到手价-底线价",
        "极限到手价",
        "极限到手折扣",
        "极限到手价-底线价",
        "商品券"
    ],
    "value": {
        "商品券": 0,
        "商品券凑10": 0,
        "活动价": 1289,
        "折扣计算价": 3867,
        "专区折扣": "3件80折",
        "折扣立减": 773,
        "店铺券条件": 3500,
        "店铺券立减": 250,
        "可用津贴": 380,
        "到手价": 822,
        "到手折扣": 0.33,
        "到手价-底线价": 76,
        "凑单到手价": 819,
        "极限到手价": 819,
        "极限到手折扣": 0.33,
        "极限到手价-底线价": 73,
        "使用平台购物券商品券": 0,
        "使用平台购物券商品券凑10": 0,
        "使用平台购物券活动价": 0,
        "使用平台购物券折扣计算价": 0,
        "使用平台购物券折扣立减": 0,
        "使用平台购物券可用津贴": 0,
        "平台购物券条件": 0,
        "平台购物券立减": 0,
        "使用平台购物券店铺券条件": 0,
        "使用平台购物券店铺券立减": 0,
        "使用平台购物券到手价": 0,
        "使用平台购物券到手折扣": 0,
        "使用平台购物券到手价-底线价": 0,
        "使用平台购物券凑单到手价": 0,
        "使用平台购物券极限到手价": 0,
        "使用平台购物券极限到手折扣": 0,
        "使用平台购物券极限到手价-底线价": 0
    },
    "sign": 0
};

// 变更数据
let participateName = "3件80折";
// let participateName = "不参与多件";
let changeLog = {"到手价": '750'}
// let changeLog = {"活动价": '1159'}
// / {到手价: '1000'}/{商品券: '1000'}{"活动价": '1159'}{"单件折扣": '90'}
// let changeLog = {"到手价": '800'}
// let changeLog = {"单件折扣": 0.1}
let is_use_shop_coupon = 1;
let result = calculatePrice(is_participate, is_use_shop_coupon, basicInformation, priceList, changeLog, participateName);
//console.log(result)

// 测试：计算相关的专区的价格方法
let participate_name = "3件90折";
let data = {
    "_id": "VSKZL10180_616172979503",
    "spu": "VSKZL10180",
    "goods_id": "616172979503",
    "goods_only_flag": "VSKZL10180_616172979503",
    "confirmed": 0,
    "is_delete": 0,
    "is_participate": 0,
    "is_use_platform_coupon": 0,
    "is_use_platform_allowance": 0,
    "is_members": 0,
    "is_use_shop_coupon": 1,
    "is_use_goods_coupon": 0,
    "basicInformation": {
        "底线折扣": 0.3,
        "历史最低标价": 1289,
        "确认吊牌价": 2489,
        "底线价": 746,
        "活动价": 1029,
        "活动价的历史最低折扣": 0.8,
        "活动价折扣": 0.41,
        "platform_coupons": [
            {
                "name": "津贴",
                "antecedents": 200,
                "consequence": 20
            }
        ],
        "shop_coupons": [
            {
                "name": "店铺",
                "antecedents": 1500,
                "consequence": 100
            },
            {
                "name": "店铺",
                "antecedents": 2500,
                "consequence": 200
            },
            {
                "name": "店铺",
                "antecedents": 3500,
                "consequence": 250
            },
            {
                "name": "店铺",
                "antecedents": 4500,
                "consequence": "350"
            }
        ],
        "dict_goods_coupons": [],
        "list_partition_discounts_name": [
            {
                "name": "3件80折",
                "value": "3'件80折",
                "antecedents": "3",
                "consequence": "80"
            }
        ],
        "list_lim_time_part_discounts_name": [
            {
                "name": "3件80折_前2后4",
                "value": "4'件75折",
                "antecedents": "4",
                "consequence": "75"
            }
        ],
        "discount_of_act_price_cap": "80"
    },
    "tag": [],
    "3件80折": {
        "sign": 0,
        "bestTitle": [
            "活动价",
            "到手价",
            "到手折扣",
            "到手价-底线价",
            "极限到手价",
            "极限到手折扣",
            "极限到手价-底线价"
        ],
        "bestValue": {
            "商品券": 0,
            "商品券凑10": 0,
            "活动价": 1029,
            "折扣计算价": 3087,
            "专区折扣": "3件80折",
            "折扣立减": 617,
            "店铺券条件": 2500,
            "店铺券立减": 200,
            "可用津贴": 300,
            "到手价": 657,
            "到手折扣": 0.26,
            "到手价-底线价": -89,
            "凑单到手价": 654,
            "极限到手价": 654,
            "极限到手折扣": 0.26,
            "极限到手价-底线价": -92,
            "使用平台购物券商品券": 0,
            "使用平台购物券商品券凑10": 0,
            "使用平台购物券活动价": 0,
            "使用平台购物券折扣计算价": 0,
            "使用平台购物券折扣立减": 0,
            "使用平台购物券可用津贴": 0,
            "平台购物券条件": 0,
            "平台购物券立减": 0,
            "使用平台购物券店铺券条件": 0,
            "使用平台购物券店铺券立减": 0,
            "使用平台购物券到手价": 0,
            "使用平台购物券到手折扣": 0,
            "使用平台购物券到手价-底线价": 0,
            "使用平台购物券凑单到手价": 0,
            "使用平台购物券极限到手价": 0,
            "使用平台购物券极限到手折扣": 0,
            "使用平台购物券极限到手价-底线价": 0
        },
        "priceList": [
            {
                "title": [
                    "活动价",
                    "到手价",
                    "到手折扣",
                    "到手价-底线价",
                    "极限到手价",
                    "极限到手折扣",
                    "极限到手价-底线价",
                    "商品券"
                ],
                "value": {
                    "商品券": 0,
                    "商品券凑10": 0,
                    "活动价": 1029,
                    "折扣计算价": 3087,
                    "专区折扣": "3件80折",
                    "折扣立减": 617,
                    "店铺券条件": 2500,
                    "店铺券立减": 200,
                    "可用津贴": 300,
                    "到手价": 657,
                    "到手折扣": 0.26,
                    "到手价-底线价": -89,
                    "凑单到手价": 654,
                    "极限到手价": 654,
                    "极限到手折扣": 0.26,
                    "极限到手价-底线价": -92,
                    "使用平台购物券商品券": 0,
                    "使用平台购物券商品券凑10": 0,
                    "使用平台购物券活动价": 0,
                    "使用平台购物券折扣计算价": 0,
                    "使用平台购物券折扣立减": 0,
                    "使用平台购物券可用津贴": 0,
                    "平台购物券条件": 0,
                    "平台购物券立减": 0,
                    "使用平台购物券店铺券条件": 0,
                    "使用平台购物券店铺券立减": 0,
                    "使用平台购物券到手价": 0,
                    "使用平台购物券到手折扣": 0,
                    "使用平台购物券到手价-底线价": 0,
                    "使用平台购物券凑单到手价": 0,
                    "使用平台购物券极限到手价": 0,
                    "使用平台购物券极限到手折扣": 0,
                    "使用平台购物券极限到手价-底线价": 0
                },
                "sign": 0
            },
            {
                "title": [
                    "活动价",
                    "到手价",
                    "到手折扣",
                    "到手价-底线价",
                    "极限到手价",
                    "极限到手折扣",
                    "极限到手价-底线价",
                    "商品券"
                ],
                "value": {
                    "商品券": 0,
                    "商品券凑10": 0,
                    "活动价": 1029,
                    "折扣计算价": 3087,
                    "专区折扣": "3件80折",
                    "折扣立减": 617,
                    "店铺券条件": 2500,
                    "店铺券立减": 200,
                    "可用津贴": 300,
                    "到手价": 657,
                    "到手折扣": 0.26,
                    "到手价-底线价": -89,
                    "凑单到手价": 654,
                    "极限到手价": 654,
                    "极限到手折扣": 0.26,
                    "极限到手价-底线价": -92,
                    "使用平台购物券商品券": 0,
                    "使用平台购物券商品券凑10": 0,
                    "使用平台购物券活动价": 0,
                    "使用平台购物券折扣计算价": 0,
                    "使用平台购物券折扣立减": 0,
                    "使用平台购物券可用津贴": 0,
                    "平台购物券条件": 0,
                    "平台购物券立减": 0,
                    "使用平台购物券店铺券条件": 0,
                    "使用平台购物券店铺券立减": 0,
                    "使用平台购物券到手价": 0,
                    "使用平台购物券到手折扣": 0,
                    "使用平台购物券到手价-底线价": 0,
                    "使用平台购物券凑单到手价": 0,
                    "使用平台购物券极限到手价": 0,
                    "使用平台购物券极限到手折扣": 0,
                    "使用平台购物券极限到手价-底线价": 0
                },
                "sign": 0
            },
            {
                "title": [
                    "活动价",
                    "到手价",
                    "到手折扣",
                    "到手价-底线价",
                    "极限到手价",
                    "极限到手折扣",
                    "极限到手价-底线价",
                    "商品券"
                ],
                "value": {
                    "商品券": 0,
                    "商品券凑10": 0,
                    "活动价": 1029,
                    "折扣计算价": 3087,
                    "专区折扣": "3件80折",
                    "折扣立减": 617,
                    "店铺券条件": 2500,
                    "店铺券立减": 200,
                    "可用津贴": 300,
                    "到手价": 657,
                    "到手折扣": 0.26,
                    "到手价-底线价": -89,
                    "凑单到手价": 654,
                    "极限到手价": 654,
                    "极限到手折扣": 0.26,
                    "极限到手价-底线价": -92,
                    "使用平台购物券商品券": 0,
                    "使用平台购物券商品券凑10": 0,
                    "使用平台购物券活动价": 0,
                    "使用平台购物券折扣计算价": 0,
                    "使用平台购物券折扣立减": 0,
                    "使用平台购物券可用津贴": 0,
                    "平台购物券条件": 0,
                    "平台购物券立减": 0,
                    "使用平台购物券店铺券条件": 0,
                    "使用平台购物券店铺券立减": 0,
                    "使用平台购物券到手价": 0,
                    "使用平台购物券到手折扣": 0,
                    "使用平台购物券到手价-底线价": 0,
                    "使用平台购物券凑单到手价": 0,
                    "使用平台购物券极限到手价": 0,
                    "使用平台购物券极限到手折扣": 0,
                    "使用平台购物券极限到手价-底线价": 0
                },
                "sign": 0
            }
        ]
    },
    "3件80折_前2后4": {
        "sign": 1,
        "bestTitle": [
            "活动价",
            "到手价",
            "到手折扣",
            "到手价-底线价",
            "极限到手价",
            "极限到手折扣",
            "极限到手价-底线价"
        ],
        "bestValue": {
            "商品券": 0,
            "商品券凑10": 0,
            "活动价": 1029,
            "折扣计算价": 4116,
            "专区折扣": "3件80折_前2后4",
            "折扣立减": 1029,
            "店铺券条件": 3500,
            "店铺券立减": 250,
            "可用津贴": 400,
            "到手价": 609,
            "到手折扣": 0.24,
            "到手价-底线价": -137,
            "凑单到手价": 606,
            "极限到手价": 606,
            "极限到手折扣": 0.24,
            "极限到手价-底线价": -140,
            "使用平台购物券商品券": 0,
            "使用平台购物券商品券凑10": 0,
            "使用平台购物券活动价": 0,
            "使用平台购物券折扣计算价": 0,
            "使用平台购物券折扣立减": 0,
            "使用平台购物券可用津贴": 0,
            "平台购物券条件": 0,
            "平台购物券立减": 0,
            "使用平台购物券店铺券条件": 0,
            "使用平台购物券店铺券立减": 0,
            "使用平台购物券到手价": 0,
            "使用平台购物券到手折扣": 0,
            "使用平台购物券到手价-底线价": 0,
            "使用平台购物券凑单到手价": 0,
            "使用平台购物券极限到手价": 0,
            "使用平台购物券极限到手折扣": 0,
            "使用平台购物券极限到手价-底线价": 0
        },
        "priceList": [
            {
                "title": [
                    "活动价",
                    "到手价",
                    "到手折扣",
                    "到手价-底线价",
                    "极限到手价",
                    "极限到手折扣",
                    "极限到手价-底线价",
                    "商品券"
                ],
                "value": {
                    "商品券": 0,
                    "商品券凑10": 0,
                    "活动价": 1029,
                    "折扣计算价": 4116,
                    "专区折扣": "3件80折_前2后4",
                    "折扣立减": 1029,
                    "店铺券条件": 3500,
                    "店铺券立减": 250,
                    "可用津贴": 400,
                    "到手价": 609,
                    "到手折扣": 0.24,
                    "到手价-底线价": -137,
                    "凑单到手价": 606,
                    "极限到手价": 606,
                    "极限到手折扣": 0.24,
                    "极限到手价-底线价": -140,
                    "使用平台购物券商品券": 0,
                    "使用平台购物券商品券凑10": 0,
                    "使用平台购物券活动价": 0,
                    "使用平台购物券折扣计算价": 0,
                    "使用平台购物券折扣立减": 0,
                    "使用平台购物券可用津贴": 0,
                    "平台购物券条件": 0,
                    "平台购物券立减": 0,
                    "使用平台购物券店铺券条件": 0,
                    "使用平台购物券店铺券立减": 0,
                    "使用平台购物券到手价": 0,
                    "使用平台购物券到手折扣": 0,
                    "使用平台购物券到手价-底线价": 0,
                    "使用平台购物券凑单到手价": 0,
                    "使用平台购物券极限到手价": 0,
                    "使用平台购物券极限到手折扣": 0,
                    "使用平台购物券极限到手价-底线价": 0
                },
                "sign": 1
            },
            {
                "title": [
                    "活动价",
                    "到手价",
                    "到手折扣",
                    "到手价-底线价",
                    "极限到手价",
                    "极限到手折扣",
                    "极限到手价-底线价",
                    "商品券"
                ],
                "value": {
                    "商品券": 0,
                    "商品券凑10": 0,
                    "活动价": 1029,
                    "折扣计算价": 4116,
                    "专区折扣": "3件80折_前2后4",
                    "折扣立减": 1029,
                    "店铺券条件": 3500,
                    "店铺券立减": 250,
                    "可用津贴": 400,
                    "到手价": 609,
                    "到手折扣": 0.24,
                    "到手价-底线价": -137,
                    "凑单到手价": 606,
                    "极限到手价": 606,
                    "极限到手折扣": 0.24,
                    "极限到手价-底线价": -140,
                    "使用平台购物券商品券": 0,
                    "使用平台购物券商品券凑10": 0,
                    "使用平台购物券活动价": 0,
                    "使用平台购物券折扣计算价": 0,
                    "使用平台购物券折扣立减": 0,
                    "使用平台购物券可用津贴": 0,
                    "平台购物券条件": 0,
                    "平台购物券立减": 0,
                    "使用平台购物券店铺券条件": 0,
                    "使用平台购物券店铺券立减": 0,
                    "使用平台购物券到手价": 0,
                    "使用平台购物券到手折扣": 0,
                    "使用平台购物券到手价-底线价": 0,
                    "使用平台购物券凑单到手价": 0,
                    "使用平台购物券极限到手价": 0,
                    "使用平台购物券极限到手折扣": 0,
                    "使用平台购物券极限到手价-底线价": 0
                },
                "sign": 0
            },
            {
                "title": [
                    "活动价",
                    "到手价",
                    "到手折扣",
                    "到手价-底线价",
                    "极限到手价",
                    "极限到手折扣",
                    "极限到手价-底线价",
                    "商品券"
                ],
                "value": {
                    "商品券": 0,
                    "商品券凑10": 0,
                    "活动价": 1029,
                    "折扣计算价": 4116,
                    "专区折扣": "3件80折_前2后4",
                    "折扣立减": 1029,
                    "店铺券条件": 3500,
                    "店铺券立减": 250,
                    "可用津贴": 400,
                    "到手价": 609,
                    "到手折扣": 0.24,
                    "到手价-底线价": -137,
                    "凑单到手价": 606,
                    "极限到手价": 606,
                    "极限到手折扣": 0.24,
                    "极限到手价-底线价": -140,
                    "使用平台购物券商品券": 0,
                    "使用平台购物券商品券凑10": 0,
                    "使用平台购物券活动价": 0,
                    "使用平台购物券折扣计算价": 0,
                    "使用平台购物券折扣立减": 0,
                    "使用平台购物券可用津贴": 0,
                    "平台购物券条件": 0,
                    "平台购物券立减": 0,
                    "使用平台购物券店铺券条件": 0,
                    "使用平台购物券店铺券立减": 0,
                    "使用平台购物券到手价": 0,
                    "使用平台购物券到手折扣": 0,
                    "使用平台购物券到手价-底线价": 0,
                    "使用平台购物券凑单到手价": 0,
                    "使用平台购物券极限到手价": 0,
                    "使用平台购物券极限到手折扣": 0,
                    "使用平台购物券极限到手价-底线价": 0
                },
                "sign": 0
            }
        ]
    },
    "不参与多件": {
        "sign": 0,
        "bestTitle": [
            "活动价",
            "单件折扣",
            "到手价",
            "到手折扣",
            "到手价-底线价",
            "极限到手价",
            "极限到手折扣",
            "极限到手价-底线价"
        ],
        "bestValue": {
            "商品券": 0,
            "商品券凑10": 0,
            "活动价": 1029,
            "折扣计算价": 1029,
            "到手价": 746,
            "到手折扣": 0.3,
            "到手价-底线价": 0,
            "店铺券条件": 0,
            "店铺券立减": 0,
            "可用津贴": 100,
            "单件折扣": 0.82,
            "折扣立减": 185,
            "凑单到手价": 741,
            "极限到手价": 741,
            "极限到手折扣": 0.3,
            "极限到手价-底线价": -5,
            "使用平台购物券商品券": 0,
            "使用平台购物券商品券凑10": 0,
            "使用平台购物券活动价": 0,
            "使用平台购物券折扣计算价": 0,
            "使用平台购物券到手价": 0,
            "使用平台购物券到手折扣": 0,
            "使用平台购物券到手价-底线价": 0,
            "使用平台购物券可用津贴": 0,
            "平台购物券条件": 0,
            "平台购物券立减": 0,
            "使用平台购物券店铺券条件": 0,
            "使用平台购物券店铺券立减": 0,
            "使用平台购物券折扣立减": 0,
            "使用平台购物券单件折扣": 0,
            "使用平台购物券凑单到手价": 0,
            "使用平台购物券极限到手价": 0,
            "使用平台购物券极限到手折扣": 0,
            "使用平台购物券极限到手价-底线价": 0
        },
        "priceList": [
            {
                "title": [
                    "活动价",
                    "单件折扣",
                    "到手价",
                    "到手折扣",
                    "到手价-底线价",
                    "极限到手价",
                    "极限到手折扣",
                    "极限到手价-底线价",
                    "商品券"
                ],
                "value": {
                    "商品券": 0,
                    "商品券凑10": 0,
                    "活动价": 1029,
                    "折扣计算价": 1029,
                    "到手价": 746,
                    "到手折扣": 0.3,
                    "到手价-底线价": 0,
                    "店铺券条件": 0,
                    "店铺券立减": 0,
                    "可用津贴": 100,
                    "单件折扣": 0.82,
                    "折扣立减": 185,
                    "凑单到手价": 741,
                    "极限到手价": 741,
                    "极限到手折扣": 0.3,
                    "极限到手价-底线价": -5,
                    "使用平台购物券商品券": 0,
                    "使用平台购物券商品券凑10": 0,
                    "使用平台购物券活动价": 0,
                    "使用平台购物券折扣计算价": 0,
                    "使用平台购物券到手价": 0,
                    "使用平台购物券到手折扣": 0,
                    "使用平台购物券到手价-底线价": 0,
                    "使用平台购物券可用津贴": 0,
                    "平台购物券条件": 0,
                    "平台购物券立减": 0,
                    "使用平台购物券店铺券条件": 0,
                    "使用平台购物券店铺券立减": 0,
                    "使用平台购物券折扣立减": 0,
                    "使用平台购物券单件折扣": 0,
                    "使用平台购物券凑单到手价": 0,
                    "使用平台购物券极限到手价": 0,
                    "使用平台购物券极限到手折扣": 0,
                    "使用平台购物券极限到手价-底线价": 0
                },
                "sign": 0
            },
            {
                "title": [
                    "活动价",
                    "单件折扣",
                    "到手价",
                    "到手折扣",
                    "到手价-底线价",
                    "极限到手价",
                    "极限到手折扣",
                    "极限到手价-底线价",
                    "商品券"
                ],
                "value": {
                    "商品券": 0,
                    "商品券凑10": 0,
                    "活动价": 1029,
                    "折扣计算价": 1029,
                    "到手价": 746,
                    "到手折扣": 0.3,
                    "到手价-底线价": 0,
                    "店铺券条件": 0,
                    "店铺券立减": 0,
                    "可用津贴": 100,
                    "单件折扣": 0.82,
                    "折扣立减": 185,
                    "凑单到手价": 741,
                    "极限到手价": 741,
                    "极限到手折扣": 0.3,
                    "极限到手价-底线价": -5,
                    "使用平台购物券商品券": 0,
                    "使用平台购物券商品券凑10": 0,
                    "使用平台购物券活动价": 0,
                    "使用平台购物券折扣计算价": 0,
                    "使用平台购物券到手价": 0,
                    "使用平台购物券到手折扣": 0,
                    "使用平台购物券到手价-底线价": 0,
                    "使用平台购物券可用津贴": 0,
                    "平台购物券条件": 0,
                    "平台购物券立减": 0,
                    "使用平台购物券店铺券条件": 0,
                    "使用平台购物券店铺券立减": 0,
                    "使用平台购物券折扣立减": 0,
                    "使用平台购物券单件折扣": 0,
                    "使用平台购物券凑单到手价": 0,
                    "使用平台购物券极限到手价": 0,
                    "使用平台购物券极限到手折扣": 0,
                    "使用平台购物券极限到手价-底线价": 0
                },
                "sign": 0
            }
        ]
    },
    "recommended_partition": {
        "partition_name": "3件80折_前2后4",
        "_class": "no_coupon",
        "到手价": 609,
        "_class_index": 0,
        "商品券": 0,
        "商品券凑10": 0,
        "活动价": 1029,
        "折扣计算价": 4116,
        "专区折扣": "3件80折_前2后4",
        "折扣立减": 1029,
        "店铺券条件": 3500,
        "店铺券立减": 250,
        "可用津贴": 400,
        "到手折扣": 0.24,
        "到手价-底线价": -137,
        "凑单到手价": 606,
        "极限到手价": 606,
        "极限到手折扣": 0.24,
        "极限到手价-底线价": -140,
        "使用平台购物券商品券": 0,
        "使用平台购物券商品券凑10": 0,
        "使用平台购物券活动价": 0,
        "使用平台购物券折扣计算价": 0,
        "使用平台购物券折扣立减": 0,
        "使用平台购物券可用津贴": 0,
        "平台购物券条件": 0,
        "平台购物券立减": 0,
        "使用平台购物券店铺券条件": 0,
        "使用平台购物券店铺券立减": 0,
        "使用平台购物券到手价": 0,
        "使用平台购物券到手折扣": 0,
        "使用平台购物券到手价-底线价": 0,
        "使用平台购物券凑单到手价": 0,
        "使用平台购物券极限到手价": 0,
        "使用平台购物券极限到手折扣": 0,
        "使用平台购物券极限到手价-底线价": 0
    },
    "88会员": {
        "bestTitle": [
            "活动价",
            "到手价",
            "到手折扣"
        ],
        "bestValue": {
            "partition_name": "3件80折_前2后4",
            "_class": "no_coupon",
            "到手价": 609,
            "_class_index": 0,
            "商品券": 0,
            "商品券凑10": 0,
            "活动价": 1029,
            "折扣计算价": 4116,
            "专区折扣": "3件80折_前2后4",
            "折扣立减": 1029,
            "店铺券条件": 3500,
            "店铺券立减": 250,
            "可用津贴": 400,
            "到手折扣": 0.24,
            "到手价-底线价": -137,
            "凑单到手价": 606,
            "极限到手价": 575.7,
            "极限到手折扣": 0.24,
            "极限到手价-底线价": -140,
            "使用平台购物券商品券": 0,
            "使用平台购物券商品券凑10": 0,
            "使用平台购物券活动价": 0,
            "使用平台购物券折扣计算价": 0,
            "使用平台购物券折扣立减": 0,
            "使用平台购物券可用津贴": 0,
            "平台购物券条件": 0,
            "平台购物券立减": 0,
            "使用平台购物券店铺券条件": 0,
            "使用平台购物券店铺券立减": 0,
            "使用平台购物券到手价": 0,
            "使用平台购物券到手折扣": 0,
            "使用平台购物券到手价-底线价": 0,
            "使用平台购物券凑单到手价": 0,
            "使用平台购物券极限到手价": 0,
            "使用平台购物券极限到手折扣": 0,
            "使用平台购物券极限到手价-底线价": 0,
            "sign": 0
        },
        "sign": 0
    },
    "general": {
        "partition_name": "3件80折_前2后4",
        "_class": "no_coupon",
        "到手价": 609,
        "_class_index": 0,
        "商品券": 0,
        "商品券凑10": 0,
        "活动价": 1029,
        "折扣计算价": 4116,
        "专区折扣": "3件80折_前2后4",
        "折扣立减": 1029,
        "店铺券条件": 3500,
        "店铺券立减": 250,
        "可用津贴": 400,
        "到手折扣": 0.24,
        "到手价-底线价": -137,
        "凑单到手价": 606,
        "极限到手价": 606,
        "极限到手折扣": 0.24,
        "极限到手价-底线价": -140,
        "使用平台购物券商品券": 0,
        "使用平台购物券商品券凑10": 0,
        "使用平台购物券活动价": 0,
        "使用平台购物券折扣计算价": 0,
        "使用平台购物券折扣立减": 0,
        "使用平台购物券可用津贴": 0,
        "平台购物券条件": 0,
        "平台购物券立减": 0,
        "使用平台购物券店铺券条件": 0,
        "使用平台购物券店铺券立减": 0,
        "使用平台购物券到手价": 0,
        "使用平台购物券到手折扣": 0,
        "使用平台购物券到手价-底线价": 0,
        "使用平台购物券凑单到手价": 0,
        "使用平台购物券极限到手价": 0,
        "使用平台购物券极限到手折扣": 0,
        "使用平台购物券极限到手价-底线价": 0
    },
    "系统专区": "3件80折_前2后4",
    "归属专区": [],
    "上一次归属专区": [
        "3件80折_前2后4",
        "3件80折_前2后4"
    ],
    "系统专区下标": 0,
    "归属专区下标": [],
    "上一次归属专区下标": [
        0,
        0
    ]
}

let priceListIndex = 0
// let result = calculateRelationPartitionPrice(data, participate_name, priceListIndex)
// let result = _findRelationPartition(participate_name, basicInformation)
// //console.log(result)
