import React, {useState, useEffect, useCallback} from 'react';
import {Spin, Row, Button, Table, message, Tag, Drawer, Form, Col, Input, DatePicker, Select} from 'antd'
import {DownloadOutlined} from '@ant-design/icons';
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import downExcel from '../../config/utils/downloadFile'
import axios from 'axios'

const {RangePicker} = DatePicker
const {Option} = Select;

function CommodityCrowdTask() {

    const [form] = Form.useForm();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
    }

    //数据
    const [date, setDate] = useState([])

    //页面
    const [page, setPage] = useState(1)

    // 总数
    const [total, setTotal] = useState(0)

    // 任务名
    const [task, setTask] = useState('')

    // 下载任务创建人
    const [founder, setFounder] = useState('')

    // 文件名
    const [fileName, setFileName] = useState('')

    // 任务状态
    const [taskState, setTaskState] = useState('')

    // 开始时间(关键时间)
    const [keyTimeStartTime, setKeyTimeStartTime] = useState('')

    // 结束时间(关键时间)
    const [keyTimeEndTime, setKeyTimeEndTime] = useState('')

    // 开始时间（下载任务创建时间）
    const [startTime, setStartTime] = useState('')

    // 结束时间（下载任务创建时间）
    const [endTime, setEndTime] = useState('')

    // 是否加载中
    const [loading, setLoading] = useState(false)

    // 打开查询条件
    const [openSearch, setOpenSearch] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        },
        // showTotal: ((total) => {
        //     return `共 ${total} 条`;
        // }),
    }

    // 表头
    const columns = [
        {
            title: '任务编号',
            key: 'id',
            dataIndex: 'id',
            width: '60px',
            align: 'center'
        },
        {
            title: '任务名字',
            key: 'task',
            dataIndex: 'task',
            width: '202px',
            align: 'center'
        },
        {
            title: '文件名称',
            key: 'file_name',
            dataIndex: 'file_name',
            width: '202px',
            align: 'center'
        },
        {
            title: '创建人',
            key: 'founder',
            dataIndex: 'founder',
            width: '80px',
            align: 'center'
        },
        {
            title: '下载条件',
            key: 'parameter_name',
            dataIndex: 'parameter_name',
            width: '202px',
            align: 'center'
        },
        {
            title: '关键时间描述',
            key: 'key_time',
            dataIndex: 'key_time',
            width: '202px',
            align: 'center'
        },
        {
            title: '关键时间开始',
            key: 'key_time_start_time',
            dataIndex: 'key_time_start_time',
            width: '202px',
            align: 'center'
        },
        {
            title: '关键时间开始',
            key: 'key_time_end_time',
            dataIndex: 'key_time_end_time',
            width: '202px',
            align: 'center'
        },
        {
            title: '任务状态',
            key: 'task_state',
            dataIndex: 'task_state',
            width: '202px',
            align: 'center',
            render: (text, record, index) => (
                text == '0' ? <Tag>未执行</Tag> :
                    text == '1' ? <Tag color="blue">正在执行</Tag> :
                        text == '2' ? <Tag color="green">执行成功</Tag> :
                            text == '3' ? <Tag color="red">执行异常</Tag> :
                                <Tag color="red">执行异常</Tag>
            ),
        },
        {
            title: '创建时间',
            key: 'create_time',
            dataIndex: 'create_time',
            width: '202px',
            align: 'center'
        },
        {
            title: '操作',
            key: 'task_state',
            dataIndex: 'task_state',
            width: '202px',
            align: 'center',
            render: (text, record, index) => (
                // text == '2' ? <DownloadOutlined onClick={()=>console.log(servicePath.downloadFile + '?principal_uuid=' + cookie.load('id') + '&file_uuid=' + date[index]['file_uuid'] + '&roleId=' + cookie.load('role')+ '&shopId=' + cookie.load('shopList'))}/> : <div></div>
                text == '2' ?
                    // <a href={servicePath.downloadFile + '?principal_uuid=' + cookie.load('id') + '&file_uuid=' + date[index]['file_uuid'] + '&roleId=' + cookie.load('role') + '&shopId=' + cookie.load('shopList')} target="_Blank">
                    //     <DownloadOutlined />
                    // </a>
                    <DownloadOutlined onClick={() => downloadFile(date[index]['file_uuid'])}/>
                    : <div></div>
            ),
        }
    ]

    useEffect(() => {
        getDate()
    }, [page])

    // 获取数据
    const getDate = () => {
        setLoading(true)
        let dataProps = {
            "task": task,
            "founder": founder,
            "file_name": fileName,
            "task_state": taskState,
            "start_time": startTime,
            "key_time_start": keyTimeStartTime,
            "key_time_end": keyTimeEndTime,
            "end_time": endTime,
            "page": page,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryFileTask).then(res => {
            setLoading(false)
            if (res.success) {
                setTotal(res.total)
                setDate(res.data);
                setOpenSearch(false)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 重置
    const restart = () => {
        setTask('')
        setFounder('')
        setFileName('')
        setStartTime('')
        setEndTime('')
        setKeyTimeEndTime('')
        setKeyTimeStartTime('')
        form.resetFields()
        setLoading(true)
        let dataProps = {
            "task": "",
            "founder": "",
            "file_name": "",
            "task_state": "",
            "start_time": "",
            "end_time": "",
            "key_time_start": "",
            "key_time_end": "",
            "page": 1,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryFileTask).then(res => {
            setLoading(false)
            if (res.success) {
                setTotal(res.total)
                setDate(res.data);
                setOpenSearch(false)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 关闭查询弹框
    const onSearchClose = () => {
        setOpenSearch(false)
    }

    //  下载文件
    const downloadFile = (file_uuid) => {
        let data = {}
        data.principal_uuid = cookie.load('id')
        data.file_uuid = file_uuid
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        axios({
            data: data,
            method: 'POST',
            url: servicePath.downloadFile,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                downExcel(res)
            }
        ).catch(e => {
            message.error({content: '文件制作出错，请联系it同事！'})
        })
    }


    return (
        <>
            <Spin spinning={loading} size="large" style={{alignItems: "center", top: '40%'}}>
                <div style={{height: '80vh'}}>
                    {/* 按钮区域 */}
                    <div style={{marginTop: size.height * 0.01, marginLeft: size.width * 0.03}}>
                        <Row>
                            <Button type="primary" onClick={() => setOpenSearch(true)} style={{ marginLeft: '1vw', marginRight: '1vw' }}>条件查询</Button>
                        </Row>
                    </div>
                    <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.03, marginRight: size.width * 0.03 }}>
                        {/* 表单区域 */}
                        <Table
                            //表格列的配置描述s
                            columns={columns}
                            //数据数组
                            dataSource={date}
                            pagination={paginationProps}
                            scroll={{ x: '80vw', y: `${size.width > 1500 ? '65vh' : '55vh'}` }}
                            //表格行 key 的取值
                            rowKey={'id'}
                        />
                    </div>
                </div>
                {/* 查询条件 */}
                <Drawer
                    title="查询条件"
                    placement="right"
                    closable={false}
                    onClose={onSearchClose}
                    visible={openSearch}
                    width={600}
                >
                    <Form
                        form={form}
                        {...layout}
                        labelAlign='left'
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="founder"
                                    label="任务创建人"
                                >
                                    <Input
                                        id='founder'
                                        placeholder="任务创建人"
                                        value={founder}
                                        onChange={(e) => {
                                            setFounder(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="taskTime"
                                    label="任务创建时间"
                                >
                                    <RangePicker key="taskTime" locale={locale} showTime onChange={(date, dateString) => {
                                        setStartTime(dateString[0]);
                                        setEndTime(dateString[1]);
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="taskState"
                                    label="任务状态"
                                >
                                    <Select placeholder="请选择任务状态" style={{ width: 360 }} onChange={(value) => setTaskState(value)}>
                                        <Option value="0">未执行</Option>
                                        <Option value="1">正在执行</Option>
                                        <Option value="2">执行成功</Option>
                                        <Option value="3">执行异常</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="keyTimeStartTime"
                                    label="关键时间开始时间"
                                >
                                    {/* <RangePicker key="keyTime" locale={locale} showTime onChange={(date, dateString) => {
                                        setKeyTimeStartTime(dateString[0]);
                                        setKeyTimeEndTime(dateString[1]);
                                    }} /> */}
                                    <DatePicker key="keyTimeStartTime" locale={locale} showTime onChange={(date, dateString) => {
                                        setKeyTimeStartTime(dateString);
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="keyTimeEndTime"
                                    label="关键时间开始时间"
                                >
                                    <DatePicker key="keyTimeEndTime" locale={locale} showTime onChange={(date, dateString) => {
                                        setKeyTimeEndTime(dateString);
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Button type="primary" onClick={() => {
                                    if (page == 1) {
                                        getDate()
                                    } else {
                                        setPage(1);
                                    }
                                }}>查询</Button>
                                <Button type="primary" danger style={{ marginLeft: 10 }} onClick={() => { setPage(1); restart() }}>重置</Button>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </Spin>
        </>
    )

}

export default CommodityCrowdTask
