import React, { useEffect, useState } from 'react';
import { Input, Typography, Checkbox, message, Modal, Button, Spin, Table, Select, Upload, Tooltip } from 'antd'
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl';
import NbAxios from '../../../config/utils/nbAxios';
import axios from 'axios'
import PlatformEventsCalendar from '../../../component/platformEventsCalendar'
import TargetCard from './components/targetCard'
import webUrl from '../../../config/webUrl';
import AcceptBuried from '../../../config/utils/buried';
import liveDict from '../data/liveDict';
import liveNameDict from '../data/liveNameDict';
import { set } from 'lodash';
import { ReactComponent as Down } from '../report/svg/down.svg'
import { ReactComponent as Up } from '../report/svg/up.svg'
const { Title, Text,Paragraph } = Typography;
const { Option } = Select
function Index(props) {
    // 设置选择文件按钮样式
    const uploadFileButton = (
        <div>
            <Button icon={<UploadOutlined />}>选择文件</Button>
        </div>
    );
    // 设置加载中变量
    const [loading, setloading] = useState(false);
    // 控制上传excel对话框的可不可见
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    // 控制上传费用对话框的可不可见
    const [isCostUploadModalVisible, setIsCostUploadModalVisible] = useState(false);
    // 上传excel列表
    const [fileList, setFileList] = useState([]);
    // 上传费用列表
    const [costFileList, setCostFileList] = useState([]);
    // 获取一月的天数
    const monthDays = moment().daysInMonth()
    // 获取一月的第几天
    const nowInMonth = parseInt(moment().format('DD'))
    // 获取一周的第几天
    const nowInWeek = parseInt(moment().format('e')) + 1
    // 日粉丝
    const [dailyFansData, setDailyFansData] = useState({})
    // 日销售
    const [dailySaleData, setSaleFansData] = useState({})
    // 累计粉丝
    const [grandFansData, setGrandFansData] = useState({})
    // 累计销售
    const [grandSaleData, setGrandSaleData] = useState({})
    // 日粉丝图例
    const [dailyFansLegend, setDailyFansLegend] = useState({
        "data": [],
        "fans": {},
        "legend": [],
        "money": {},
        "xAxis": []
    })
    // 累计粉丝图例
    const [grandFansLegend, setGrandFansLegend] = useState({
        "data": [],
        "fans": {},
        "legend": [],
        "money": {},
        "xAxis": []
    })
    // 饼图数据
    const [durationReportData, setDurationReportData] = useState([])
    // 人群规模数据
    const [viewRateOptionData, setViewRateOptionData] = useState([
        {
            'legend': [],
            "xAxis": [],
            "data": [],
        },
    ])
    // 直播间流转数据
    const [liveOptionData, setLiveOptionData] = useState([
        {
            'legend': [],
            "xAxis": [],
            "data": [],
        },
    ])
    // 主播效率值数据
    const [anchorOptionData, setAnchorOptionData] = useState([
        {
            'legend': [],
            "xAxis": [],
            "data": [],
        },
    ])
    // 表格选择
    const [echartLegendSelected, setEchartLegendSelected] = useState({ '上午': true, '下午': true, '晚上': true, })
    // 开始日期
    const [startDate, setStartDate] = useState(moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'))
    // 结束日期
    const [endDate, setEndDate] = useState(moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD'))
    // 详情数据
    const [detailData, setDetailData] = useState([])
    // 详情数据表头
    const [detailColumns, setDetailColumns] = useState([])
    // 汇总数据
    const [sumData, setSumData] = useState([])
    // 日统计数据
    const [dayData, setDayData] = useState([])
    // 周统计数据
    const [weekData, setWeekData] = useState([])
    // 月统计数据
    const [monthData, setMonthData] = useState([])
    // 时间段
    const [timeInterval, setTimeInterval] = useState("")
    // 主播
    const [anchor, setAnchor] = useState("")
    // 人群规模数据维度选择
    const [dimensionList, setDimensionList] = useState(["直播间曝光人数", "直播间进入人数", "商品曝光人数", "成交人数", "新增粉丝数"])
    // 直播间流转率数据维度选择
    const [liveDimensionList, setLiveDimensionList] = useState(["看播率", "进入曝光率", "曝光点击率", "点击成交转化率"])
    // 主播效率值维度选择
    const [anchorDimensionList, setAnchorDimensionList] = useState(["GPM", "平均在线人数", "最高在线人数", "人均观看时长", "互动率", "粉丝转化率"])
    // 已选中的人群规模数据维度
    const [dimensionCheckedList, setDimensionCheckedList] = useState([])
    // 已选中的直播间流转率维度
    const [liveDimensionCheckedList, setLiveDimensionCheckedList] = useState([])
    // 已选中的主播效率值维度
    const [anchorDimensionCheckedList, setAnchorDimensionCheckedList] = useState([])
    // 汇总表格
    const sumTable = [
        {
            title: "直播间成交金额",
            dataIndex: "直播间成交金额",
            key: "直播间成交金额",
            width: 120,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "当天观看",
            dataIndex: "当天观看",
            key: "当天观看",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "平均在线",
            dataIndex: "平均在线",
            key: "平均在线",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "最高在线",
            dataIndex: "最高在线",
            key: "最高在线",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "人均观看时长",
            dataIndex: "人均观看时长",
            key: "人均观看时长",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "直播间曝光人数",
            dataIndex: "直播间曝光人数",
            key: "直播间曝光人数",
            width: 120,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "看播率",
            dataIndex: "看播率",
            key: "看播率",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "直播间进入人数",
            dataIndex: "直播间进入人数",
            key: "直播间进入人数",
            width: 120,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "进入-曝光转化率",
            dataIndex: "进入-曝光转化率",
            key: "进入-曝光转化率",
            width: 120,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "商品曝光人数",
            dataIndex: "商品曝光人数",
            key: "商品曝光人数",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "曝光-点击转化率",
            dataIndex: "曝光-点击转化率",
            key: "曝光-点击转化率",
            width: 120,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
            // render(text, record, index) {
            //     return (
            //         <div>{text !== "" ? text.split(",")[0] + "%" + "," + text.split(",")[1]: ""}</div>
            //     )
            // }
        },
        {
            title: "商品点击人数",
            dataIndex: "商品点击人数",
            key: "商品点击人数",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "点击-成交转化率",
            dataIndex: "点击-成交转化率",
            key: "点击-成交转化率",
            width: 120,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "成交人数",
            dataIndex: "成交人数",
            key: "成交人数",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "内容互动人数",
            dataIndex: "内容互动人数",
            key: "内容互动人数",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "互动率",
            dataIndex: "互动率",
            key: "互动率",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "直播时长",
            dataIndex: "直播时长",
            key: "直播时长",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "直播场次",
            dataIndex: "直播场次",
            key: "直播场次",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "成交金额",
            dataIndex: "成交金额",
            key: "成交金额",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
        {
            title: "费用",
            dataIndex: "费用",
            key: "费用",
            width: 100,
            align: "center",
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            render(text, record, index) {
                return (
                    text.split(',')[1] == '-'?
                    text:
                    parseInt(text.split(',')[1]) > 0 ?
                    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Up width={12} height={12} style={{marginRight:15}}/>
                    </div> : <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                        <span>{text.split(",")[0]}</span>
                        <span>,</span>
                        <span>{text.split(",")[1]}</span>
                        <Down width={12} height={12} style={{marginRight:15}}/>
                    </div> 
                )
            }
        },
    ]
    // 日粉丝新增趋势/金额趋势
    let dailyFans = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: dailyFansLegend["legend"]
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            data: dailyFansLegend["xAxis"]
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                position: 'right',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: dailyFansLegend["data"]
    }
    // 累计日粉丝新增趋势/金额趋势
    let grandFans = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: grandFansLegend["legend"]
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            data: grandFansLegend["xAxis"]
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                position: 'right',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: grandFansLegend["data"]
    }
    // 时长
    let durationReport = {
        title: {
            text: '时长数据汇总',
            left: 'center',
            textStyle: {
                fontSize: 20
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: ['上午', '下午', '晚上'],
            top: 'bottom',
            selected: echartLegendSelected,
            show: false
        },
        series: [
            {
                name: '时长数据汇总',
                type: 'pie',
                selectedMode: 'single',
                radius: '50%',
                data: durationReportData["live_duration"],
                itemStyle: {
                    normal: {
                        label: {
                            show: true,
                            formatter: '{b} : {c} ({d}%)',
                            textStyle: {
                                fontSize: 15
                            }
                        },
                        labelLine: { show: true }
                    },
                    fontSize: 20,
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
    // 观看
    let watchReport = {
        title: {
            text: '观看数据汇总',
            left: 'center',
            textStyle: {
                fontSize: 20
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: ['上午', '下午', '晚上'],
            top: 'bottom',
            selected: echartLegendSelected,
        },
        series: [
            {
                name: '观看数据汇总',
                type: 'pie',
                selectedMode: 'single',
                radius: '50%',
                data: durationReportData["viewers"],
                itemStyle: {
                    normal: {
                        label: {
                            show: true,
                            formatter: '{b} : {c} ({d}%)',
                            textStyle: {
                                fontSize: 15
                            }
                        },
                        labelLine: { show: true }
                    },
                    fontSize: 20,
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
    // 销量
    let saleReport = {
        title: {
            text: '销量数据汇总',
            left: 'center',
            textStyle: {
                fontSize: 20
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: ['上午', '下午', '晚上'],
            top: 'bottom',
            selected: echartLegendSelected,
            show: false
        },
        series: [
            {
                name: '销量数据汇总',
                type: 'pie',
                selectedMode: 'single',
                radius: '50%',
                data: durationReportData["money"],
                itemStyle: {
                    normal: {
                        label: {
                            show: true,
                            formatter: '{b} : {c} ({d}%)',
                            textStyle: {
                                fontSize: 15
                            }
                        },
                        labelLine: { show: true }
                    },
                    fontSize: 20,
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
    // 人群规模数据
    let viewRateOption = {
        title: {
            text: '人群规模数据',
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: viewRateOptionData["legend"]
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            axisLabel:{
                rotate:60
            },
            data: viewRateOptionData["xAxis"]
        },
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                axisLabel: {
                    formatter: '{value}'
                }
            },
        ],
        series: viewRateOptionData["data"]
    };
    // 直播间流转数据
    let entryExposureOption = {
        title: {
            text: '直播间流转数据',
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: liveOptionData["legend"]
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            axisLabel:{
                rotate:60
            },
            data: liveOptionData["xAxis"]
        },
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                axisLabel: {
                    formatter: '{value}'
                }
            },
        ],
        series: liveOptionData["data"]
    };
    // 主播效率值
    let exposureClickOption = {
        title: {
            text: '主播效率值',
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: anchorOptionData["legend"]
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            data: anchorOptionData["xAxis"]
        },
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                axisLabel: {
                    formatter: '{value}'
                }
            },
        ],
        series: anchorOptionData["data"]
    };
    // // 点击-成交转化率
    // let clickDealOption = {
    //     title: {
    //         text: '点击-成交转化率',
    //     },
    //     tooltip: {
    //         trigger: 'axis'
    //     },
    //     legend: {
    //         data: viewRateOptionData[3]["legend"]
    //     },
    //     grid: {
    //         left: '3%',
    //         right: '4%',
    //         bottom: '3%',
    //         containLabel: true
    //     },
    //     xAxis: {
    //         type: 'category',
    //         axisTick: {
    //             alignWithLabel: true
    //         },
    //         data: viewRateOptionData[3]["xAxis"]
    //     },
    //     yAxis: {
    //         type: 'value'
    //     },
    //     series: viewRateOptionData[3]["data"]
    // };
    // 上传Excel函数
    const uploadFile = () => {
        if (fileList.length == 0) {
            message.error("请选择文件");
        } else {
            setloading(true);
            AcceptBuried('upload_live_data', "click", {}, props.location.pathname, "抖音直播日报表")
            const formData = new FormData();
            fileList.forEach((item) => {
                formData.append("details_file", item["originFileObj"]);
            });
            formData.append("live_id", liveDict[props.history.location.pathname.split('/')[2]]);
            formData.append("shopList", cookie.load("shopList"));
            formData.append("roleId", cookie.load("role"));
            formData.append("name", cookie.load("name"));
            axios({
                method: 'POST',
                url: servicePath.uploadDetailsFile,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    setloading(false);
                    if (res.data['success']) {
                        message.success("直播详情上传成功");
                        setFileList([]);
                        setIsUploadModalVisible(false);
                        tiktokSearch()
                        search()
                        searchFansMoneyMonth()
                        searchCumulativeFansMoneyMonth()
                        searchLiveDuration()
                        searchVariousPercentages()
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }

    };
    // 上传费用函数
    const uploadCostFile = () => {
        if (costFileList.length == 0) {
            message.error("请选择文件");
        } else {
            setloading(true);
            AcceptBuried('upload_live_data', "click", {}, props.location.pathname, "抖音直播日报表")
            const formData = new FormData();
            costFileList.forEach((item) => {
                formData.append("file", item["originFileObj"]);
            });
            formData.append("live_id", liveDict[props.history.location.pathname.split('/')[2]]);
            formData.append("shopList", cookie.load("shopList"));
            formData.append("roleId", cookie.load("role"));
            formData.append("name", cookie.load("name"));
            axios({
                method: 'POST',
                url: servicePath.uploadLiveCostFile,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    setloading(false);
                    if (res.data['success']) {
                        message.success("直播详情上传成功");
                        setCostFileList([]);
                        setIsCostUploadModalVisible(false);
                        tiktokSearch()
                        search()
                        searchFansMoneyMonth()
                        searchCumulativeFansMoneyMonth()
                        searchLiveDuration()
                        searchVariousPercentages()
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }

    };
    
    // 查询直播详情接口
    const search = () => {
        let dataProps = {
            startDate: startDate,
            endDate: endDate,
            timeInterval: timeInterval,
            anchor: anchor,
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            live_id: liveDict[props.history.location.pathname.split('/')[2]]
        }
        setloading(true)
        NbAxios(dataProps, "POST", servicePath.liveBroadcastDetailsQuery).then(
            (res) => {
                setloading(false);
                if (res.success) {
                    // 表头函数设置
                    res.data[0].map(item => {
                        item['onHeaderCell'] = (record, index) => {
                            return (
                                {
                                    style: {
                                        fontSize: '15px',
                                        padding: 0
                                    }
                                }
                            )
                        }
                        item['onCell'] = (record, index) => {
                            return (
                                {
                                    style: {
                                        fontSize: '15px',
                                        padding: 0
                                    }
                                }
                            )
                        }
                        item['render'] = (text, record, index) => (
                            index == 2 && item.title !=='日期'?
                            <Paragraph style={{ margin: 0 }} editable={{onChange:(value)=>
                            {
                                let obj = [...res.data[1]]
                                obj[index][item.key] = value
                                setDetailData(obj)
                                // 修改接口
                                let dataProps = {
                                    "field" : obj[index]["date"],
                                    "update": obj[index][item.key],
                                    "id" : item.id,
                                    'roleId': cookie.load('role'),
                                    'shopList': cookie.load('shopList'),
                                    "live_id" : liveDict[window.location.href.split('/')[4]],
                                }
                                setloading(true);
                                NbAxios(dataProps, "POST", servicePath.liveBroadcastDetailsUpdate).then(
                                    (res) => {
                                        setloading(false);
                                        if (res.success) {
                                        message.success("修改成功");
                                        } else {
                                        message.error(res.errorMsg);
                                        }
                                    }
                                ).catch((err) => {
                                    setloading(false)
                                    // 获取500错误
                                    message.warn('修改接口报错，请联系IT帮忙解决')
                                })
                                
                            }
                        }}>{text}</Paragraph> : <span>{text}</span>
                        )
                    })
                    setDetailColumns(res.data[0])
                    setDetailData(res.data[1])
                    searchVariousPercentages()
                } else {
                    message.error(res.errorMsg);
                }
            }
        )
    }
    // 查询月粉丝新增和金额接口
    const searchFansMoneyMonth = () => {
        let dataProps = {
            "year_month": moment().format('YYYY-MM'),
            'roleId': cookie.load('role'),
            'shopList': cookie.load('shopList'),
            "live_id": liveDict[props.history.location.pathname.split('/')[2]]
        }
        NbAxios(dataProps, "POST", servicePath.searchFansMoneyMonth).then(
            (res) => {
                setloading(false);
                if (res.success) {
                    setDailyFansLegend(res.data)
                    setDailyFansData(res.data["fans"])
                    setSaleFansData(res.data["money"])
                } else {
                    message.error(res.errorMsg);
                }
            }
        );
    }
    // 查询月累积粉丝新增和金额接口
    const searchCumulativeFansMoneyMonth = () => {
        let dataProps = {
            "year_month": moment().format('YYYY-MM'),
            'roleId': cookie.load('role'),
            'shopList': cookie.load('shopList'),
            "live_id": liveDict[props.history.location.pathname.split('/')[2]]
        }
        NbAxios(dataProps, "POST", servicePath.searchCumulativeFansMoneyMonth).then(
            (res) => {
                setloading(false);
                if (res.success) {
                    setGrandFansLegend(res.data)
                    setGrandFansData(res.data["fans"])
                    setGrandSaleData(res.data["money"])
                } else {
                    message.error(res.errorMsg);
                }
            }
        );
    }
    // 查询直播饼图数据
    const searchLiveDuration = () => {
        let dataProps = {
            "year_month": moment().format('YYYY-MM'),
            'roleId': cookie.load('role'),
            'shopList': cookie.load('shopList'),
            "live_id": liveDict[props.history.location.pathname.split('/')[2]]
        }
        NbAxios(dataProps, "POST", servicePath.searchLiveDuration).then(
            (res) => {
                setloading(false);
                if (res.success) {
                    setDurationReportData(res.data)
                } else {
                    message.error(res.errorMsg);
                }
            }
        );
    }
    // 查询直播折线图数据
    const searchVariousPercentages = () => {
        let dataProps = {
            "time": endDate,
            'roleId': cookie.load('role'),
            'shopList': cookie.load('shopList'),
            "live_id": liveDict[props.history.location.pathname.split('/')[2]]
        }
        NbAxios(dataProps, "POST", servicePath.searchVariousPercentages).then(
            (res) => {
                setloading(false);
                if (res.success) {
                    setViewRateOptionData(res.data)
                } else {
                    message.error(res.errorMsg);
                }
            }
        );
    }
    // 抖音日周月查询
    const tiktokSearch = () => {
        let dataProps = {
            'roleId': cookie.load('role'),
            'shopList': cookie.load('shopList'),
            "live_id": liveDict[props.history.location.pathname.split('/')[2]]
        }
        setloading(true)
        NbAxios(dataProps, "POST", servicePath.tiktokSearch).then(
            (res) => {
                setloading(false);
                if (res.success) {
                    setDayData(res.data[0])
                    setWeekData(res.data[1])
                    setMonthData(res.data[2])
                    setSumData(res.data[3])
                } else {
                    message.error(res.errorMsg);
                }
            }
        );
    }
    useEffect(() => {
        AcceptBuried('page_views', "view", { "page_type": "初始化页面" }, props.location.pathname, "抖音直播日报表")
        tiktokSearch()
        search()
        searchFansMoneyMonth()
        searchCumulativeFansMoneyMonth()
        searchLiveDuration()
        searchVariousPercentages()
    }, [])

    useEffect(() => {
        let dailyFansChart = echarts.init(document.getElementById('dailyFans'))
        dailyFansChart.clear();    //清除残留渲染
        // 绘制图表
        dailyFansChart.setOption(dailyFans);
        window.addEventListener('resize', () => {
            dailyFansChart.resize()
        });
        let grandFansChart = echarts.init(document.getElementById('grandFans'))
        grandFansChart.clear();    //清除残留渲染
        // 绘制图表
        grandFansChart.setOption(grandFans);
        window.addEventListener('resize', () => {
            grandFansChart.resize()
        });
    }, [grandFansLegend, dailyFansLegend])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('durationReport'));
        reportChart.clear();    //清除残留渲染
        // 绘制图表
        reportChart.setOption(durationReport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [echartLegendSelected, durationReportData])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('watchReport'));
        reportChart.clear();    //清除残留渲染
        // 绘制图表
        reportChart.setOption(watchReport);
        reportChart.on('legendselectchanged', function (params) {
            setEchartLegendSelected(params['selected']);
        })
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [echartLegendSelected, durationReportData])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('saleReport'));
        reportChart.clear();    //清除残留渲染
        // 绘制图表
        reportChart.setOption(saleReport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [echartLegendSelected, durationReportData])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById("viewRate"));
        reportChart.clear();    //清除残留渲染
        // 绘制图表
        reportChart.setOption(viewRateOption);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [viewRateOptionData])
    useEffect(() => {
        let reportChart = echarts.init(document.getElementById("entryExposure"));
        reportChart.clear();    //清除残留渲染
        // 绘制图表
        reportChart.setOption(entryExposureOption);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [liveOptionData])
    useEffect(() => {
        let reportChart = echarts.init(document.getElementById("exposureClick"));
        reportChart.clear();    //清除残留渲染
        // 绘制图表
        reportChart.setOption(exposureClickOption);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [anchorOptionData])
    // useEffect(() => {
    //     let reportChart = echarts.init(document.getElementById("clickDeal"));
    //     reportChart.clear();    //清除残留渲染
    //     // 绘制图表
    //     reportChart.setOption(clickDealOption);
    //     window.addEventListener('resize', () => {
    //         reportChart.resize()
    //     });
    // }, [viewRateOptionData])

    return (
        <div style={{ paddingBottom: 100 }}>
            <Spin tip="Loading..." spinning={loading}>
                <div style={{
                    display: 'flex',
                    justifyContent: "space-around",
                    backgroundColor: "#fff",
                    borderBottom: "solid 2px #ccc",
                    marginBottom: 10,
                    position: 'fixed',
                    top: 40,
                    zIndex: 99,
                    width: '100vw',
                    paddingTop: 10,
                    paddingBottom: 10
                }}>
                    <Title level={3} style={{ margin: 0 }} >{liveNameDict[props.location.pathname.split('/')[2]]}直播日报表</Title>
                    <div style={{ display: "flex" }}>
                        <div>
                            <Button
                                type="primary"
                                onClick={() => setIsUploadModalVisible(true)}
                                style={{ marginLeft: 20 }}
                            >上传</Button>
                        </div>
                        <div>
                            <Button
                                type="primary"
                                // onClick={()=>props.history.push({
                                //         // 'pathname':`/app/${props.history.location.pathname.split('/')[2]}/liveDaily/target`,

                                //     })}
                                onClick={() => {
                                    window.open(`${webUrl}${`/app/${props.history.location.pathname.split('/')[2]}/liveDaily/target`}`)
                                }}
                                style={{ marginLeft: 20 }}
                            >目标</Button>
                        </div>
                        <div>
                            <Button
                                type="primary"
                                // onClick={()=>props.history.push({
                                //         // 'pathname':`/app/${props.history.location.pathname.split('/')[2]}/liveDaily/target`,

                                //     })}
                                onClick={() => setIsCostUploadModalVisible(true)}
                                style={{ marginLeft: 20 }}
                            >上传费用</Button>
                        </div>
                        <a href="https://pic.bmcsoft.cn/imge_dir/052610272315/052610272315.html" target="_Blank" style={{ marginLeft: 10 }}><Button type="link">操作手册</Button></a>
                    </div>
                </div>
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 80 }}>
                    {/* <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 100, paddingTop: 20 }}>
                            <Title level={5}>日维度</Title>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {dayData.map((item) => {
                                return (
                                    <div>
                                        <TargetCard target={item["target"]} finsh={item["finish"]} compared={item["compared"]} title={item["title"]} lastTitle={"昨日同期"} timePercent={100} lastNumber={item["last_day_finish"]} />
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 100, paddingTop: 20 }}>
                            <Title level={5}>周维度</Title>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {weekData.map((item) => {
                                return (
                                    <div>
                                        <TargetCard target={item["week_target"]} finsh={item["week_finish"]} compared={item["week_compared"]} title={item["title"]} lastTitle={"上周同期"} timePercent={item["time_percent"]} lastNumber={item["last_week_finish"]} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 100, paddingTop: 20 }}>
                            <Title level={5}>月维度</Title>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {monthData.map((item) => {
                                return (
                                    <div>
                                        <TargetCard target={item["month_target"]} finsh={item["month_finish"]} compared={item["month_compared"]} title={item["title"]} lastTitle={"上月同期"} timePercent={item["time_percent"]} lastNumber={item["last_month_finish"]} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ width: '50%', boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginRight: 5 }}>
                            <div style={{ width: '60%', display: 'flex', justifyContent: 'center' }}>
                                <b>日粉丝新增趋势/金额趋势</b>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div id="dailyFans" style={{ width: '60%', height: 300 }}></div>
                                <div style={{ display: 'flex', height: 300, justifyContent: 'space-around', flexDirection: 'column', alignItems: 'center', width: '40%' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: 50, justifyContent: 'center' }}>
                                            <b>粉丝</b>
                                        </div>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>早</Text>
                                        <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{dailyFansData['上午']}</Text>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>中</Text>
                                        <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{dailyFansData['下午']}</Text>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>晚</Text>
                                        <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{dailyFansData['晚上']}</Text>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: 50, justifyContent: 'center' }}>
                                            <b>销售</b>
                                        </div>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>早</Text>
                                        <Tooltip title={dailySaleData['上午']}>
                                            <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{dailySaleData['上午']}</Text>
                                        </Tooltip>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>中</Text>
                                        <Tooltip title={dailySaleData['下午']}>
                                            <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{dailySaleData['下午']}</Text>
                                        </Tooltip>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>晚</Text>
                                        <Tooltip title={dailySaleData['晚上']}>
                                            <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{dailySaleData['晚上']}</Text>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '50%', boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)' }}>
                            <div style={{ width: '60%', display: 'flex', justifyContent: 'center' }}>
                                <b>累计日粉丝新增趋势/金额趋势</b>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div id="grandFans" style={{ width: '60%', height: 300 }}></div>
                                <div style={{ display: 'flex', height: 300, justifyContent: 'space-around', flexDirection: 'column', alignItems: 'center', width: '40%' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: 50, justifyContent: 'center' }}>
                                            <b>粉丝</b>
                                        </div>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>早</Text>
                                        <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{grandFansData['上午']}</Text>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>中</Text>
                                        <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{grandFansData['下午']}</Text>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>晚</Text>
                                        <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{grandFansData['晚上']}</Text>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: 50, justifyContent: 'center' }}>
                                            <b>销售</b>
                                        </div>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>早</Text>
                                        <Tooltip title={grandSaleData['上午']}>
                                            <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{grandSaleData['上午']}</Text>
                                        </Tooltip>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>中</Text>
                                        <Tooltip title={grandSaleData['下午']}>
                                            <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{grandSaleData['下午']}</Text>
                                        </Tooltip>
                                        <Text type="secondary" style={{ width: 20, display: 'flex', justifyContent: 'center' }}>晚</Text>
                                        <Tooltip title={grandSaleData['晚上']}>
                                            <Text style={{ width: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{grandSaleData['晚上']}</Text>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 100, paddingTop: 20 }}>
                            <Title level={5}>汇总值</Title>
                        </div>
                        <Table dataSource={sumData} columns={sumTable} pagination={false} scroll={{ x: 1500 ,y:400}} />
                    </div>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 20, display: 'flex', justifyContent: 'space-around' }}>
                        <div id="durationReport" style={{ width: '100%', height: "40vh" }}></div>
                        <div id="watchReport" style={{ width: '100%', height: "40vh" }}></div>
                        <div id="saleReport" style={{ width: '100%', height: "40vh" }}></div>
                    </div>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 20, paddingTop: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                            <b style={{ marginRight: 5 }}>日期:</b>
                            <PlatformEventsCalendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                            <b style={{ marginRight: 5, marginLeft: 10 }}>时段:</b>
                            <Select size="small" style={{ width: 80 }} value={timeInterval} onChange={(value) => setTimeInterval(value)}>
                                <Option value="上午">上午</Option>
                                <Option value="下午">下午</Option>
                                <Option value="晚上">晚上</Option>
                            </Select>
                            <b style={{ marginRight: 5, marginLeft: 10 }}>主播:</b>
                            <div style={{ marginRight: 5 }}>
                                <Input size="small" onChange={(e) => setAnchor(e.target.value)} />
                            </div>
                            <div>
                                <Tooltip title="搜索">
                                    <Button type="primary" shape="circle" icon={<SearchOutlined />} size="small" onClick={() => {
                                        search()
                                    }} />
                                </Tooltip>
                            </div>
                        </div>
                        <Table dataSource={detailData} columns={detailColumns} pagination={false} scroll={{ x: 1500, y: 400 }} />
                    </div>
                   
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 20}}>
                        <div style={{display:"flex", justifyContent:'center', alignItems:'center',height:"40px"}}>
                        <div>
                                <b style={{ marginRight: 5 }}>选择维度:</b>
                                {dimensionList.map((item) => {
                                    return (
                                        <Checkbox style={{ marginLeft: 5 }} checked={dimensionCheckedList.indexOf(item) != -1 ? true : false}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    if (dimensionCheckedList.length == 2){
                                                        message.warning("最多可展现两个维度的数据")
                                                    }else{
                                                        let obj = []
                                                        obj = [...dimensionCheckedList]
                                                        obj.push(item)
                                                        setDimensionCheckedList(obj)
                                                    }
                                                }else {
                                                    let obj = []
                                                    obj = [...dimensionCheckedList]
                                                    obj.remove(item)
                                                    setDimensionCheckedList(obj)
                                                }
                                            }}>
                                            {item}
                                        </Checkbox>
                                    )
                                })}
                        </div>
                            <div style={{marginLeft:20}}>
                                <Button type="primary" size='small' onClick={() =>{
                                    if(dimensionCheckedList.length == 0){
                                        message.error("最少得选择一个维度")
                                    }else{
                                        let dataProps = {
                                            'roleId': cookie.load('role'),
                                            'shopList': cookie.load('shopList'),
                                            "live_id": liveDict[props.history.location.pathname.split('/')[2]],
                                            "start_date" : startDate,
                                            "end_date" : endDate,
                                            "anchor": anchor,
                                            "date_detail": timeInterval,
                                            "legend_list" :dimensionCheckedList
                                        }
                                        setloading(true)
                                        NbAxios(dataProps, "POST", servicePath.getTiktokLiveLineChartData).then(
                                            (res) => {
                                                setloading(false);
                                                if (res.success) {
                                                    setViewRateOptionData(res.data.table_data)
                                                } else {
                                                    message.error(res.errorMsg);
                                                }
                                            }
                                        );
                                    }
                                }}>确定</Button>
                            </div>
                            <div style={{marginLeft:20}}>
                                <Button type="danger" size='small' onClick={() =>{
                                    setDimensionCheckedList([])
                                }}>清空</Button>
                            </div>
                        </div>
                        <div id="viewRate" style={{ width: '100%', height: "40vh" }}></div>
                    </div>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 20}}>
                        <div style={{display:"flex", justifyContent:'center', alignItems:'center',height:"40px"}}>
                        <div>
                                <b style={{ marginRight: 5 }}>选择维度:</b>
                                {liveDimensionList.map((item) => {
                                    return (
                                        <Checkbox style={{ marginLeft: 5 }} checked={liveDimensionCheckedList.indexOf(item) != -1 ? true : false}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    if (liveDimensionCheckedList.length == 2){
                                                        message.warning("最多可展现两个维度的数据")
                                                    }else{
                                                        let obj = []
                                                        obj = [...liveDimensionCheckedList]
                                                        obj.push(item)
                                                        setLiveDimensionCheckedList(obj)
                                                    }
                                                }else {
                                                    let obj = []
                                                    obj = [...liveDimensionCheckedList]
                                                    obj.remove(item)
                                                    setLiveDimensionCheckedList(obj)
                                                }
                                            }}>
                                            {item}
                                        </Checkbox>
                                    )
                                })}
                        </div>
                            <div style={{marginLeft:20}}>
                                <Button type="primary" size='small' onClick={() =>{
                                    if(liveDimensionCheckedList.length == 0){
                                        message.error("最少得选择一个维度")
                                    }else{
                                        let dataProps = {
                                            'roleId': cookie.load('role'),
                                            'shopList': cookie.load('shopList'),
                                            "live_id": liveDict[props.history.location.pathname.split('/')[2]],
                                            "start_date" : startDate,
                                            "end_date" : endDate,
                                            "anchor": anchor,
                                            "date_detail": timeInterval,
                                            "legend_list" :liveDimensionCheckedList
                                        }
                                        setloading(true)
                                        NbAxios(dataProps, "POST", servicePath.getTiktokLiveLineChartData).then(
                                            (res) => {
                                                setloading(false);
                                                if (res.success) {
                                                    setLiveOptionData(res.data.table_data)
                                                } else {
                                                    message.error(res.errorMsg);
                                                }
                                            }
                                        );
                                    }
                                }}>确定</Button>
                            </div>
                            <div style={{marginLeft:20}}>
                                <Button type="danger" size='small' onClick={() =>{
                                    setLiveDimensionCheckedList([])
                                }}>清空</Button>
                            </div>
                        </div>
                        <div id="entryExposure" style={{ width: '100%', height: "40vh" }}></div>
                    </div>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 20}}>
                        <div style={{display:"flex", justifyContent:'center', alignItems:'center',height:"40px"}}>
                        <div>
                                <b style={{ marginRight: 5 }}>选择维度:</b>
                                {anchorDimensionList.map((item) => {
                                    return (
                                        <Checkbox style={{ marginLeft: 5 }} checked={anchorDimensionCheckedList.indexOf(item) != -1 ? true : false}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    if (anchorDimensionCheckedList.length == 2){
                                                        message.warning("最多可展现两个维度的数据")
                                                    }else{
                                                        let obj = []
                                                        obj = [...anchorDimensionCheckedList]
                                                        obj.push(item)
                                                        setAnchorDimensionCheckedList(obj)
                                                    }
                                                }else {
                                                    let obj = []
                                                    obj = [...anchorDimensionCheckedList]
                                                    obj.remove(item)
                                                    setAnchorDimensionCheckedList(obj)
                                                }
                                            }}>
                                            {item}
                                        </Checkbox>
                                    )
                                })}
                        </div>
                            <div style={{marginLeft:20}}>
                                <Button type="primary" size='small' onClick={() =>{
                                    if(anchorDimensionCheckedList.length == 0){
                                        message.error("最少得选择一个维度")
                                    }else{
                                        let dataProps = {
                                            'roleId': cookie.load('role'),
                                            'shopList': cookie.load('shopList'),
                                            "live_id": liveDict[props.history.location.pathname.split('/')[2]],
                                            "start_date" : startDate,
                                            "end_date" : endDate,
                                            "anchor": anchor,
                                            "date_detail": timeInterval,
                                            "legend_list" :anchorDimensionCheckedList
                                        }
                                        setloading(true)
                                        NbAxios(dataProps, "POST", servicePath.getTiktokLiveLineChartData).then(
                                            (res) => {
                                                setloading(false);
                                                if (res.success) {
                                                    setAnchorOptionData(res.data.table_data)
                                                } else {
                                                    message.error(res.errorMsg);
                                                }
                                            }
                                        );
                                    }
                                }}>确定</Button>
                            </div>
                            <div style={{marginLeft:20}}>
                                <Button type="danger" size='small' onClick={() =>{
                                    setAnchorDimensionCheckedList([])
                                }}>清空</Button>
                            </div>
                        </div>
                        <div id="exposureClick" style={{ width: '100%', height: "40vh" }}></div>
                    </div>
                    {/* <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 20, display: 'flex', justifyContent: 'space-around' }}>
                        <div id="exposureClick" style={{ width: '50%', height: "40vh" }}></div>
                        <div id="clickDeal" style={{ width: '50%', height: "40vh" }}></div>
                    </div> */}
                </div>
                {/* 上传抖音直播详情窗口 */}
                <Modal
                    title="上传抖音直播详情窗口"
                    visible={isUploadModalVisible}
                    onOk={() => setIsUploadModalVisible(false)}
                    onCancel={() => {
                        setIsUploadModalVisible(false)
                        setFileList([]);
                    }}
                    footer={[null, null]}
                    centered={true}
                >
                    <Spin tip="Loading..." spinning={loading}>
                        <Upload
                            action={servicePath.development_uploadSuccess} //上传地址，填错/不填上传图片的时候都会报错
                            // listType="picture-card"                             // 上传列表的内建样式
                            fileList={fileList} //已经上传的文件列表（受控）
                            // onPreview={handleFilePreview}                           //点击文件链接或预览图标时的回调
                            onChange={({ fileList }) => {
                                setFileList(fileList);
                            }} //上传文件改变时的状态
                            multiple={true}
                        >
                            {fileList.length >= 30 ? null : uploadFileButton}
                            <Text type='danger' >上传文件来自于抖音后台下载</Text>
                        </Upload>
                        <div style={{ marginLeft: 400 }}>
                            <Button type="primary" onClick={() => uploadFile()} disabled={fileList.length >= 1 ? false : true}>
                                上传
                            </Button>
                        </div>
                    </Spin>
                </Modal>
                {/* 上传抖音直播费用详情窗口 */}
                <Modal
                    title="上传费用窗口"
                    visible={isCostUploadModalVisible}
                    onOk={() => setIsCostUploadModalVisible(false)}
                    onCancel={() => {
                        setIsCostUploadModalVisible(false)
                        setCostFileList([]);
                    }}
                    footer={[null, null]}
                    centered={true}
                >
                    <Spin tip="Loading..." spinning={loading}>
                        <Upload
                            action={servicePath.development_uploadSuccess} //上传地址，填错/不填上传图片的时候都会报错
                            // listType="picture-card"                             // 上传列表的内建样式
                            fileList={costFileList} //已经上传的文件列表（受控）
                            // onPreview={handleFilePreview}                           //点击文件链接或预览图标时的回调
                            onChange={({ fileList }) => {
                                setCostFileList(fileList);
                            }} //上传文件改变时的状态
                            multiple={true}
                        >  
                            {costFileList.length >= 30 ? null : uploadFileButton}
                            <Text type='danger' >上传文件来自于抖音后台下载</Text>
                        </Upload>
                        <div style={{ marginLeft: 400 }}>
                            <Button type="primary" onClick={() => uploadCostFile()} disabled={costFileList.length >= 1 ? false : true}>
                                上传
                            </Button>
                        </div>
                    </Spin>
                </Modal>
            </Spin>

        </div>
    )

}

export default Index