import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Tag, Form, Select, Space, Collapse, Spin, DatePicker, Layout} from 'antd'
import {CloseCircleOutlined, SearchOutlined, DownloadOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/date-picker/locale/zh_CN';
import servicePath from '../../../config/apiUrl'
import 'moment/locale/zh-cn';
import cookie from 'react-cookies';
import NbAxios from '../../../config/utils/nbAxios';


// Z11报表下载
function Z11Report(props) {
    // 时间选择
    const { RangePicker } = DatePicker;

    // 折叠面板
    const { Panel } = Collapse;

    // 样式
    const style = { padding: '20px 20px 20px 20px' }

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // Z11周报查询条件 weekly_report_time
    const [weeklyReportTime, setWeeklyReportTime] = useState('')

    // Z11周报时间条件设置
    const onChangeWeeklyReportTime = (date, dateString)=>{
        setWeeklyReportTime(dateString)
    }

    // Z11周报下载
    const getWeeklyReportTime = () => {
        if (weeklyReportTime == ''){
            message.error("没有选择时间")
        } else {
            let data = {}
            data.date = weeklyReportTime
            data.roleId = cookie.load('role')
            data.shopId = cookie.load('shopList')
            data.principal_uuid = cookie.load('id')
            NbAxios(data, 'POST', servicePath.getWeeklyReportTime).then(res=>{
                if(res.success){
                    message.success('任务创建成功')
                }else{
                    message.warn(res.errorMsg)
                }
            })
            // window.open(servicePath.getWeeklyReportTime + "?date=" + weeklyReportTime, '_blank')
        }
    }

    // Z11流量报表查询条件 flow_report_time
    const [flowReportTime, setFlowReportTime] = useState('')

    // Z11流量报表时间条件设置
    const onChangeFlowReportTime = (date, dateString)=>{
        setFlowReportTime(dateString)
    }

    // Z11流量报表下载
    const getFlowReportTime = () => {
        if (flowReportTime == ''){
            message.error("没有选择时间")
        } else {
            window.open(servicePath.getFlowReportTime + "?time=" + flowReportTime, '_blank')
        }
    }

    return (
        <>
            {/* 导航区域 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.02 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>取数</Breadcrumb.Item>
                    <Breadcrumb.Item>Z11</Breadcrumb.Item>
                    <Breadcrumb.Item>Z11天猫旗舰店</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            {/* 查询条件 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06 }}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Collapse activeKey={1}>
                            <Panel header="Z11周报下载" key="1" showArrow={false} style={{textAlign: 'center'}}>
                                <Row gutter={16} style={style}>
                                    <Col span={12} style={{textAlign: 'center'}}>
                                        <DatePicker locale={locale} onChange={onChangeWeeklyReportTime}/>
                                    </Col>
                                    <Col span={12}>
                                        <Button type="primary" icon={<DownloadOutlined />} style={{float:'left'}} onClick={getWeeklyReportTime}>
                                            下载
                                        </Button>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={12}>
                        <Collapse activeKey={1}>
                            <Panel header="Z11流量报表下载" key="1" showArrow={false} style={{textAlign: 'center'}}>
                                <Row gutter={16} style={style}>
                                    <Col span={12} style={{textAlign: 'center'}}>
                                        <DatePicker locale={locale} onChange={onChangeFlowReportTime}/>
                                    </Col>
                                    <Col span={12}>
                                        <Button type="primary" icon={<DownloadOutlined />} style={{float:'left'}} onClick={getFlowReportTime}>
                                            下载
                                        </Button>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Z11Report