import React, { useState, useEffect, useCallback } from 'react';
import { Form, Select, DatePicker, Button, Card, message } from 'antd'
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios'
import servicePath from '../../../config/apiUrl'
import '../../../static/css/index.css'
import locale from 'antd/es/date-picker/locale/zh_CN';
const { Option } = Select
const { RangePicker } = DatePicker
function DownloadCommodityCrowd() {

    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    }

    // 店铺
    const [shop, setShop] = useState([])

    // 订单创建时间开始时间
    const [orderCreateTimeStart, setOrderCreateTimeStart] = useState('')

    // 订单创建时间结束时间
    const [orderCreateTimeEnd, setOrderCreateTimeEnd] = useState('')

    // 订单支付时间开始时间
    const [orderPayTimeStart, setOrderPayTimeStart] = useState('')

    // 订单支付时间结束时间
    const [orderPayTimeEnd, setOrderPayTimeEnd] = useState('')

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 创建任务
    const createTask = () => {
        if (shop.length == 0) {
            message.warn('请选择店铺')
        } else if (orderCreateTimeStart == '' && orderCreateTimeEnd == '' && orderPayTimeStart == '' && orderPayTimeEnd == '') {
            message.warn('请选择订单创建时间或者订单支付时间')
        } else {
            let dataProps = {
                "shop_list": shop,
                "order_create_time_start": orderCreateTimeStart,
                "order_create_time_end": orderCreateTimeEnd,
                "order_pay_time_start": orderPayTimeStart,
                "order_pay_time_end": orderPayTimeEnd,
                "principal_uuid": cookie.load('id'),
                "roleId": cookie.load('role'),
                "shopId": cookie.load('shopList'),
            }
            NbAxios(dataProps, "POST", servicePath.createBasicFileTask).then(res => {
                if (res.success) {
                    message.success('任务创建成功')
                    form.resetFields()
                    setShop([])
                    setOrderCreateTimeStart('')
                    setOrderCreateTimeEnd('')
                    setOrderPayTimeEnd('')
                    setOrderPayTimeStart('')
                } else {
                    message.error(res.errorMsg)
                }
            })
        }

    }

    return (
        <>
            <div style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.03 }}>
                <Card title="创建下载订单-商品-人群任务" style={{ height: '300px', width: '600px' }}>
                    <Form
                        form={form}
                        {...layout}
                        labelAlign='left'
                    >
                        <Form.Item
                            name="shop"
                            label="店铺"
                        >
                            <Select mode="multiple" placeholder="请选择店铺" onChange={(value) => setShop(value)} style={{ width: '365px' }}>
                                <Option value="京东">京东</Option>
                                <Option value="奥莱">奥莱</Option>
                                <Option value="恩曼琳">恩曼琳</Option>
                                <Option value="卡洛琳">卡洛琳</Option>
                                <Option value="JORYA">JORYA</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="orderCreateTime"
                            label="订单创建时间"
                        >
                            <RangePicker locale={locale} showTime onChange={(date, dateString) => {
                                setOrderCreateTimeStart(dateString[0]);
                                setOrderCreateTimeEnd(dateString[1]);
                            }} />
                        </Form.Item>
                        <Form.Item
                            name="orderPayTime"
                            label="订单支付时间"
                        >
                            <RangePicker locale={locale} showTime onChange={(date, dateString) => {
                                setOrderPayTimeStart(dateString[0]);
                                setOrderPayTimeEnd(dateString[1]);
                            }} />
                        </Form.Item>
                        <Button type="primary" style={{ marginLeft: '415px' }} onClick={() => createTask()}>创建任务</Button>
                    </Form>
                </Card>
            </div>
        </>
    )

}

export default DownloadCommodityCrowd