import React, { useState, useEffect } from 'react';
import { Divider, Table, Input, Space, Button, } from 'antd'
import { EyeOutlined, EyeInvisibleOutlined , SearchOutlined} from '@ant-design/icons';
// 引入高亮组件
import Highlighter from "react-highlight-words";
import CommodityImage from '../commodityImage';
// 行交差组件
function Index({
    // 数据
    intersectionData,
    // 下标
    intersectionIndex,
    // 店铺
    shop
}) {

    //定义筛选功能
    const [searchText, setSearchText] = useState('')

    const [searchedColumn, setSearchedColumn] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };
    // 分组
    const [group, setGroup] = useState(-1)
    // 表格是否展示
    const [isShow, setIsShow] = useState(1)

    //数据集
    const [ data, setData ] = useState([])
    // 表头集合
    const [ columnList , setColumnList] = useState([])
    // 表头
    const [ column, setColumn] = useState([])
    useEffect(()=>{
        setData(intersectionData['data']);
        setColumnList(intersectionData['columnList']);
    },[intersectionData])

    useEffect(()=>{
        let column = [
            {
                title:"数据集",
                dataIndex:"数据集",
                key:"数据集",
                align:'center',
                fixed:true,
                ...getColumnSearchProps('数据集'),
                render: (text, record, index) => (
                    <div onMouseEnter={()=>{
                        setGroup(record.group);
                    }} onMouseLeave={()=>{
                        setGroup(-1);
                    }}style={{backgroundColor:group == record.group ? '#75cce8' : ''}}>
                        <CommodityImage style={{width:60}} index={intersectionIndex + "," + index} shopName={shop} sku={text.split(',')[1]} id={text.split(',')[0]}/>
                    </div>
                )
            },
            ...columnList.map((item)=>{
                return  {
                    title:item.name,
                    dataIndex:item.name,
                    key:item.name,
                    align:'center',
                    render: (text, record, index) => (
                        <div onMouseEnter={()=>{
                            setGroup(record.group);
                        }} onMouseLeave={()=>{
                            setGroup(-1);
                        }} style={{backgroundColor:group == record.group ? '#75cce8' : ''}}>{text}</div>
                    )
                }
            })
        ]
        setColumn(column)
    },[group,columnList])

    return (
        <div style={{ paddingBottom: 10 }}>
            <Divider orientation="left">行交差</Divider>
            <div style={{ display: 'grid', gridTemplateColumns: "10px 1fr" }}>
                {
                    isShow == 0 ? <EyeOutlined style={{ marginLeft: 7, color: "#039be5", fontSize: 20 }} onClick={() => {
                        setIsShow(1)
                    }} /> :
                        <EyeInvisibleOutlined style={{ marginLeft: 7, color: "#039be5", fontSize: 20 }} onClick={() => {
                            setIsShow(0)
                        }} />
                }
                <div style={{ backgroundColor: '#fff', width: 'calc(98vw - 10px)', marginLeft: 20, display: isShow == 1 ? '' : 'none' }}>
                    <Table dataSource={data} columns={column} pagination={false} scroll={{ y: 200, x: 1000 }} />
                </div>
            </div>
        </div>
    )
}

export default Index