import React, { useEffect, useState } from 'react';
import style from './position.module.css';
import { Button, Row, Col, Descriptions, Table, message, Tooltip, Typography, Form, Modal, Input, Spin } from 'antd';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
import servicePath from '../../../config/apiUrl';
import cookie from 'react-cookies';
import NbAxios from "../../../config/utils/nbAxios";
import BraftEditor from 'braft-editor'
const { Paragraph, Title } = Typography;
const { TextArea } = Input;

function Position() {

    // 富文本编辑器控件
    const extendControls = ['bold', 'italic', 'underline', 'text-color', 'separator', 'link',]
    // 设置编辑器初始内容
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''))
    // 搜索表单
    const [searchForm] = Form.useForm()
    // 职位信息表单
    const [positionForm] = Form.useForm()
    // 新增职位窗口
    const [addPositionModal, setAddPositionModal] = useState(false)
    // 修改的职位id
    const [positionId, setPositionId] = useState('')
    // 修改职位窗口
    const [updatePositionModal, setUpdatePositionModal] = useState(false)
    // 修改职位窗口
    const columns = [
        {
            title: '职位名称',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            align: 'left',
            width: 100,
        },
        {
            title: '职位类别',
            dataIndex: 'type',
            width: 100,
            fixed: 'left',
            key: 'type',
            align: 'center',
        },
        {
            title: '工作地点',
            dataIndex: 'work_address',
            width: 100,
            fixed: 'left',
            key: 'work_address',
            align: 'center',
        },
        {
            title: '培养计划',
            dataIndex: 'training_plan',
            key: 'training_plan',
            width: 100,
            align: 'center',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            width: 100,
            align: 'center',
            render: (text, record, index) => moment(text).format('YYYY-MM-DD')
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 120,
            align: 'center',
            render: (text, record, index) =>
                <>
                <Button type="link" onClick={() => openUpdateModal(record)} >
                        修改
                </Button>
                <Button type="link" >
                        展开
                </Button>
                </>
        }
    ]

    // 页码
    const [page, setPage] = useState(1)
    // 页面长度
    const [pageSize, setPageSize] = useState(10)
    // 数据总长度
    const [total, setTotal] = useState(10)
    //定义分页器的参数
    const paginationProps = {
        showSizeChanger: false,
        showQuickJumper: true,
        // 总页数
        total: total,
        //页码改变时，设置当前页码和每页显示条数
        current: page,
        onChange: (page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
        },
    }
    //定义选中的行数
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 此函数selectedRowKey为一个数组，需要取最后一个元素才能获得当前点击行
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    //行选择器的参数
    const rowSelection = {
        //隐藏选择所有行
        hideSelectAll: false,
        fixed: true,
        //选中的行数
        selectedRowKeys: selectedRowKeys,
        //行数变化的回调
        onChange: onSelectChange,
    }
    // 表格中的数据
    const [tableData, setTableData] = useState([])
    // 表格数据加载状态
    const [tableLoading, setTableLoading] = useState(false)
    useEffect(() => {
        searchPosition()
    }, [page, pageSize])

    return (
        <div className={style.positionIndexWapper} >
            <div className={style.searchArea} >
                <div className={style.controlBar}>
                    <div className={style.controlBarLeft}>
                        <Title level={3} style={{ margin: 0 }} >职位基础信息</Title>
                    </div>
                    <div className={style.controlBarRight}>

                    </div>
                </div>
                <div className={style.searchFrom} >
                    <Form
                        form={searchForm}
                    >
                        <Row gutter={8} >
                            <Col span={6}>
                                <Form.Item
                                    label="职位名称"
                                    name='name'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={searchPosition} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="职位类别"
                                    name='type'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={searchPosition} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="培养计划"
                                    name='training_plan'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={searchPosition} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="工作地点"
                                    name='work_address'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={searchPosition} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div className={style.controllButtons}>
                        <Button type="primary" style={{ justifySelf: "flex-end" }} onClick={openAddModal} >新增</Button>
                        {/* <Button type="primary" style={{ justifySelf: "flex-end" }}  >修改</Button> */}
                        <Button type="primary" style={{ justifySelf: "flex-end" }} onClick={searchPosition} >查询</Button>
                        <Button style={{ justifySelf: "flex-end" }} onClick={() => {
                            setPage(1)
                            searchForm.resetFields()
                            searchPosition()
                        }} >重置</Button>
                    </div>
                </div>
            </div>
            <div className={style.interViewTablesArea}>
                <Spin spinning={tableLoading} tip="面试人员数据加载中..." >
                    <Table
                        rowKey={record => record.id}
                        // rowSelection={rowSelection}
                        pagination={paginationProps}
                        columns={columns}
                        dataSource={tableData}
                        expandable={{
                            expandRowByClick: true,
                            expandIconColumnIndex: 6,
                            expandedRowRender: record => <div className={style.rowExpandable} style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: record.work_requirements }} ></div>,
                            rowExpandable: () => true,
                            columnWidth: 1,
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <span onClick={e => onExpand(record, e)} ></span>
                                ) : (
                                        <span onClick={e => onExpand(record, e)} ></span>
                                    )
                        }}
                        scroll={{ x: 800, y: '50vh' }}
                    />
                </Spin>
                <Modal title={"新增职位"} width="50vw" visible={addPositionModal} onOk={addPosition} onCancel={closeAddModal} >
                    <Form
                        form={positionForm}
                    >
                        <Descriptions
                            // contentStyle={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            labelStyle={{ textAlign: "center", lineHeight: 2.2 }}
                            column={4}
                            // bordered
                            colon={false}
                        >
                            <Descriptions.Item span={2} label="职位名称" >
                                <Form.Item
                                    name='name'
                                    style={{ margin: 0 }}
                                >
                                    <Input placeholder="请输入职位名称" allowClear />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="职位类别" >
                                <Form.Item
                                    name='type'
                                    style={{ margin: 0 }}
                                >
                                    <Input placeholder="请输入职位类别" allowClear />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="培养计划" >
                                <Form.Item
                                    name='training_plan'
                                    style={{ margin: 0 }}
                                >
                                    <Input placeholder="请输入培养计划" allowClear />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="工作地点" >
                                <Form.Item
                                    name='work_address'
                                    style={{ margin: 0 }}
                                >
                                    <Input placeholder="请输入工作地点" allowClear />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item span={4} label="岗位要求">
                                <Form.Item
                                    style={{ margin: 0 }}
                                >
                                    <BraftEditor
                                        contentStyle={{ height: '30vh' }}
                                        defaultValue={editorState}
                                        value={editorState}
                                        extendControls={extendControls}
                                        onChange={editorChange}
                                    />
                                </Form.Item>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                </Modal>
                <Modal title={"修改职位"} width="50vw" visible={updatePositionModal} onOk={updatePosition} onCancel={closeUpdateModal} >
                    <Form
                        form={positionForm}
                    >
                        <Descriptions
                            // contentStyle={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            labelStyle={{ textAlign: "center", lineHeight: 2.2 }}
                            column={4}
                            // bordered
                            colon={false}
                        >
                            <Descriptions.Item span={2} label="职位名称" >
                                <Form.Item
                                    name='name'
                                    style={{ margin: 0 }}
                                >
                                    <Input placeholder="请输入职位名称" disabled allowClear />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="职位类别" >
                                <Form.Item
                                    name='type'
                                    style={{ margin: 0 }}
                                >
                                    <Input placeholder="请输入职位类别" allowClear />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="培养计划" >
                                <Form.Item
                                    name='training_plan'
                                    style={{ margin: 0 }}
                                >
                                    <Input placeholder="请输入培养计划" allowClear />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="工作地点" >
                                <Form.Item
                                    name='work_address'
                                    style={{ margin: 0 }}
                                >
                                    <Input placeholder="请输入工作地点" allowClear />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item span={4} label="岗位要求">
                                <Form.Item
                                    style={{ margin: 0 }}
                                >
                                    <BraftEditor
                                        contentStyle={{ height: '30vh' }}
                                        defaultValue={editorState}
                                        value={editorState}
                                        extendControls={extendControls}
                                        onChange={editorChange}
                                    />
                                </Form.Item>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                </Modal>
            </div>
        </div>
    )

    // 编辑内容触发
    function editorChange(editorState) {
        setEditorState(editorState)
    }
    // 打开新增职位窗口
    function openAddModal() {
        setAddPositionModal(true)
    }

    // 关闭新增函数窗口
    function closeAddModal() {
        setAddPositionModal(false)
    }

    // 打开修改职位窗口
    function openUpdateModal(value) {
        positionForm.setFieldsValue(value)
        setPositionId(value.id)
        value.work_requirements && setEditorState(BraftEditor.createEditorState(value.work_requirements))
        setUpdatePositionModal(true)
    }

    // 关闭修改函数窗口
    function closeUpdateModal() {
        positionForm.resetFields()
        setEditorState(BraftEditor.createEditorState(''))
        setUpdatePositionModal(false)
    }

    // 修改职位函数
    function updatePosition() {
        let data = {}
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.principal_uuid = cookie.load('id')
        let position = positionForm.getFieldsValue()
        if (editorState.toHTML() === '<p></p>') {
            message.warn('岗位要求不能为空！')
            return false
        }
        data.id = positionId
        data.work_requirements = editorState.toHTML()
        for (let key in position) {
            if (key === 'work_requirements') {
                continue
            }
            if (position[key]) {
                // 去空格
                let str = position[key].replace(/\s+/g, "")
                if (str) {
                    data[key] = str
                } else {
                    message.warn('请将信息补充完善！')
                    return false
                }
            } else {
                message.warn('请将信息补充完善！')
                return false
            }
        }
        NbAxios(data, 'POST', servicePath.updateRecruitPosition).then(res => {
            if (res.success) {
                message.success('修改职位成功！')
                positionForm.resetFields()
                setEditorState(BraftEditor.createEditorState(''))
                closeUpdateModal()
                searchPosition()
            } else {
                message.warn(res.errorMsg)
            }
            setTableLoading(false)
        }).catch(e => {
            message.error('获取面试人员名单出错！')
            setTableLoading(false)
        })
    }

    // 新增职位函数
    function addPosition() {
        let data = {}
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.principal_uuid = cookie.load('id')
        let position = positionForm.getFieldsValue()
        if (editorState.toHTML() === '<p></p>') {
            message.warn('岗位要求不能为空！')
            return false
        }
        data.work_requirements = editorState.toHTML()
        for (let key in position) {
            if (key === 'work_requirements') {
                continue
            }
            if (position[key]) {
                // 去空格
                let str = position[key].replace(/\s+/g, "")
                if (str) {
                    data[key] = str
                } else {
                    message.warn('请将信息补充完善！')
                    return false
                }
            } else {
                message.warn('请将信息补充完善！')
                return false
            }
        }
        NbAxios(data, 'POST', servicePath.createRecruitPosition).then(res => {
            if (res.success) {
                message.success('新增职位成功！')
                positionForm.resetFields()
                setEditorState(BraftEditor.createEditorState(''))
                searchPosition()
                setAddPositionModal(false)
            } else {
                message.warn(res.errorMsg)
            }
            setTableLoading(false)
        }).catch(e => {
            message.error('获取面试人员名单出错！')
            setTableLoading(false)
        })
    }


    // 搜索职位列表
    function searchPosition() {
        let data = {}
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.principal_uuid = cookie.load('id')
        data.page = page
        data.page_size = pageSize
        let searchData = searchForm.getFieldsValue()
        for (let key in searchData) {
            if (searchData[key]) {
                // 去空格
                data[key] = searchData[key].replace(/\s+/g, "")
            }
        }
        setTableLoading(true)
        NbAxios(data, 'POST', servicePath.getRecruitPosition).then(res => {
            if (res.success) {
                let data = res.data
                let total = res.total
                setTotal(total)
                setTableData(data)
            } else {
                message.warn(res.errorMsg)
            }
            setTableLoading(false)
        }).catch(e => {
            message.error('获取职位出错！')
            setTableLoading(false)
        })
    }
}

export default Position