import React, { useEffect, useState } from 'react';
import { Input, Typography, Checkbox, message, Modal, Button, Spin, Table, Select, DatePicker, Popconfirm, Radio ,Tabs, Popover} from 'antd'
import cookie from 'react-cookies'
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import servicePath from '../../config/apiUrl';
import NbAxios from '../../config/utils/nbAxios';
import axios from 'axios'
import style from './index.module.css'
import downExcel from "../../config/utils/downloadFile"
const { Title, Text, Paragraph } = Typography;
const { Option } = Select
const { TabPane } = Tabs
const { RangePicker } = DatePicker
const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';
function Item(props){
    var date = new Date();
    // 类型
    const [calculation_type] = useState(props.history.location.pathname.split('/')[3])
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 选择的年份
    const [startTime, setStartTime] = useState(moment())
    // 表头字段
    const [filed] = useState(['公司', '项目' , '店铺', '人员类型', '一月总人数', '二月总人数', '三月总人数', '四月总人数', '五月总人数', '六月总人数', '七月总人数', '八月总人数', '九月总人数', '十月总人数', '十一月总人数', '十二月总人数'])
    // 表头
    const [column, setColumn] = useState([])
    // 数据源
    const [dataSource, setDataSource] = useState([])
    useEffect(() => {
        setColumn(filed.reduce((result,item) => {
            if(item.indexOf('月总')!==-1){
                result.push({
                    title: item,
                    dataIndex: item,
                    key: item,
                    align: "center",
                })
                return result
            }else{
                result.push({
                    title: item,
                    dataIndex: item,
                    key: item,
                    align: "center",
                    fixed:'left',
                    width:item == '公司' || item == '店铺'?'220px':'100px'
                })
                return result
            }
        },[]))
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shopId: cookie.load('shopId'),
            calculation_type: calculation_type,
            year: date.getFullYear().toString(),
        }
        countItemShow(dataProps)
    }, [])
    // 项目人员统计呈现接口
    const countItemShow = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.countItemShow).then(res => {
            setLoading(false)
            if (res.success) {
                setDataSource(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('项目人员统计呈现接口出错,请联系IT')
        })
    }
    // 项目人员个数下载接口
    const downloadCountItemShow = (dataProps) => {
        setLoading(true)
        axios({
            data: dataProps,
            method: 'POST',
            url: servicePath.downloadCountItemShow,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setLoading(false)
                downExcel(res)
            }
        ).catch(e => {
            setLoading(false)
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }
    return (
        <div className={style.initial}>
            <Spin spinning={loading} tip="数据加载中....">
                <div className={style.externalContent}>
                    <div style={{display:'flex' ,justifyContent:'space-between',marginBottom:'20px'}}>
                        <h2>项目人员数量表</h2>
                        <div className={style.ButtonEnd} style={{ marginTop: '20px',alignItems:'center' }}>
                            <div>年份:</div>
                            <DatePicker locale={locale} picker="year" size='small' style={{ height: '24px' ,width:'220px' ,margin:'0 20px 0 20px'}}
                                value={startTime}
                                onChange={(value, dateString) => {
                                    if(value){
                                        setStartTime(value)
                                    }else{
                                        message.warning('年份不能为空')
                                    }
                                }} />
                            <Button type='primary' size='small' onClick={() => {
                                let dataProps = {
                                    roleId: cookie.load('role'),
                                    shopList: cookie.load('shopList'),
                                    shopId: cookie.load('shopId'),
                                    change_member: cookie.load('name'),
                                    calculation_type: calculation_type,
                                    year:startTime.format('YYYY')
                                }
                                countItemShow(dataProps)
                            }}>查询</Button>
                            <Button size='small' style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center', margin: '0px 20px 0px 20px' }}
                                onClick={() => {
                                    let dataProps = {
                                        roleId: cookie.load('role'),
                                        shopList: cookie.load('shopList'),
                                        shopId: cookie.load('shopId'),
                                        change_member: cookie.load('name'),
                                        calculation_type: calculation_type,
                                        year:startTime.format('YYYY')
                                    }
                                    downloadCountItemShow(dataProps)
                                }}>下载</Button>
                        </div>
                    </div>
                                
                    <Table size="small" columns={column} dataSource={dataSource} rowKey={"员工ID"} pagination={{showSizeChanger:true}}
                        scroll={{ x: 2000 }}
                    />
                </div>
            </Spin>
        </div>
    )
}
export default Item