import React, { useState, useEffect } from "react";
import {
    message,
    Button,
    Input,
    Select,
    Spin,
    Typography,
    Radio,
    DatePicker,
    Checkbox,
    Table,
} from "antd";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import servicePath from "../../config/apiUrl";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import NbAxios from "../../config/utils/nbAxios";
import cookie from "react-cookies";
import style from "./index.module.css"
import downExcel from "../../config/utils/downloadFile"
import axios from "axios";
const { Text } = Typography;
// 设置选择器
const { Option } = Select;
const { Title } = Typography;
function Index() {
    // 规定时间格式
    const dateFormat1 = "YYYY";
    // 规定时间格式
    const dateFormat = "YYYY-MM";
    // 行业—品类趋势表
    // 设置加载中变量
    const [loading, setloading] = useState(false);
    // 行业—品类趋势表时间1选择器
    const [industryCategoryTrendTime1, setIndustryCategoryTrendTime1] = useState("")
    // 行业—品类趋势表时间2选择器
    const [industryCategoryTrendTime2, setIndustryCategoryTrendTime2] = useState("")
    // 设置Top前的输入框
    const [industryCategoryTrendTopNumber, setIndustryCategoryTrendTopNumber] = useState([])
    // 行业—品类趋势表数据
    const [industryCategoryTrendTableData, setIndustryCategoryTrendTableData] = useState([])
    // 设置选中的行业—品类趋势表品类列表
    const [industryCategoryTrendChecked, setIndustryCategoryTrendChecked] = useState("");
    // 行业—品类趋势表数据
    const [industryCategoryTrendTableDate, setIndustryCategoryTrendTableDate] = useState([])

    // 核心品类热词趋势表
    // 核心品类热词趋势表时间1选择器
    const [coreCategoryHotwordTime1, setCoreCategoryHotwordTime1] = useState("")
    // 核心品类热词趋势表时间2选择器
    const [coreCategoryHotwordTime2, setCoreCategoryHotwordTime2] = useState("")
    // 设置Top前的输入框
    const [coreCategoryHotwordTopNumber, setCoreCategoryHotwordTopNumber] = useState([])
    // 核心品类热词趋势表品类列表
    const [coreCategoryHotwordList, setCoreCategoryHotwordList] = useState([])
    // 设置选中的核心品类热词趋势表品类列表
    const [coreCategoryHotwordChecked, setCoreCategoryHotwordChecked] = useState("");
    // 核心品类热词趋势表数据
    const [coreCategoryHotwordTableDate, setCoreCategoryHotwordTableDate] = useState([])


    // 行业TOP分析表
    // 行业TOP分析时间选择器
    const [industryTopTime, setIndustryTopTime] = useState("")
    // 设置Top前的输入框
    const [industryTopNumber, setIndustryTopNumber] = useState([])
    // 行业TOP分析表品类列表
    const [industryTopList, setIndustryTopList] = useState([])
    // 设置选中的行业TOP分析表品类列表
    const [industryTopChecked, setIndustryTopChecked] = useState("");
    // 行业TOP分析表数据
    const [industryTopTableDate, setIndustryTopTableDate] = useState([])

    // 初始化
    useEffect(() => {
        initialization()
    }, [])
    // 切换维度时清空品类列表
    // useEffect(() => {
    //     setCompetitiveCheckedList([])
    // }, [analysisDimension])
    // 初始化函数
    const initialization = () => {
        setIndustryTopList([])
        // setCoreCategoryHotwordList(["羽绒服", "毛针织衫", "毛呢外套", "裤子", "皮革", "连衣裙", "短外套", "牛仔裤", "风衣", "卫衣/绒衫", "棉衣/棉服", "大码女装", "T恤", "半身裙", "衬衫", "西装","唐装_民族服装_舞台服装"])
        // setIndustryTopList(["羽绒服", "毛针织衫", "毛呢外套", "裤子", "皮革", "连衣裙", "短外套", "牛仔裤", "风衣", "卫衣/绒衫", "棉衣/棉服", "大码女装", "T恤", "半身裙", "衬衫", "西装","唐装_民族服装_舞台服装"])
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.industryAll).then(
            (res) => {
                setloading(false)
                if (res.success) {
                    setCoreCategoryHotwordList(res.data[0])
                    setIndustryTopList(res.data[1])
                } else {
                    message.warn(res.errorMsg);
                }
            }
        );

    }
    // 行业—品类趋势表时间1选择函数
    const onIndustryCategoryTrendTime1 = (date, dateString) => {
        if (dateString == "") {
            message.warn("请选择正确的时间");
            return false;
        } else {
            setIndustryCategoryTrendTime1(dateString);
        }
    };
    // 行业—品类趋势表时间2选择函数
    const onIndustryCategoryTrendTime2 = (date, dateString) => {
        if (dateString == "") {
            message.warn("请选择正确的时间");
            return false;
        } else {
            setIndustryCategoryTrendTime2(dateString);
        }
    };
    // 核心品类热词趋势表时间1选择器
    const onCoreCategoryHotwordTime1 = (date, dateString) => {
        if (dateString == "") {
            message.warn("请选择正确的时间");
            return false;
        } else {
            setCoreCategoryHotwordTime1(dateString);
        }
    };
    // 核心品类热词趋势表时间2选择器
    const onCoreCategoryHotwordTime2 = (date, dateString) => {
        if (dateString == "") {
            message.warn("请选择正确的时间");
            return false;
        } else {
            setCoreCategoryHotwordTime2(dateString);
        }
    };
    // 行业TOP分析时间选择器函数
    const onIndustryTopTime = (date, dateString) => {
        if (dateString == "") {
            message.warn("请选择正确的时间");
            return false;
        } else {
            setIndustryTopTime(dateString);
        }
    };

    // 行业—品类趋势表提示时间1未设置
    const industryCategoryTrendWarningTime1 = () => {
        message.warning('时间1数据未设置')
    }
    // 行业—品类趋势表提示时间2未设置
    const industryCategoryTrendWarningTime2 = () => {
        message.warning('时间2数据未设置')
    }
    // 行业—品类趋势表提示Top前未设置
    const industryCategoryTrendWarningTop = () => {
        message.warning('Top前数据未设置')
    }
    // 行业—品类趋势表提示时间1未设置
    const coreCategoryHotwordWarningTime1 = () => {
        message.warning('时间1数据未设置')
    }
    // 行业—品类趋势表提示时间2未设置
    const coreCategoryHotwordWarningTime2 = () => {
        message.warning('时间2数据未设置')
    }
    // 行业—品类趋势表提示Top前未设置
    const coreCategoryHotwordWarningTop = () => {
        message.warning('Top前数据未设置')
    }
    // 行业—品类趋势表提示品类选中未设置
    const coreCategoryHotwordWarningChecked = () => {
        message.warning('品类选中未设置')
    }

    // 行业TOP分析表提示时间未设置
    const industryTopWarningTime = () => {
        message.warning('时间数据未设置')
    }
    // 表提示Top前未设置
    const industryTopWarningNumber = () => {
        message.warning('Top前数据未设置')
    }
    // 行业TOP分析表提示品类选中未设置
    const industryTopWarningChecked = () => {
        message.warning('品类选中未设置')
    }
    // 行业—品类趋势表表头
    const [industryCategoryTrendColumns,setIndustryCategoryTrendColumns] = useState([
        {
            title: "全网排名",
            dataIndex: "ranking",
            key: "ranking",
            width: 50,
            align: "center",
        },
        {
            title: "全网",
            children: [
                {
                    title: "品类",
                    dataIndex: "category",
                    key: "category",
                    width: 50,
                    align: "center",
                },
                {
                    title: "时间1",
                    dataIndex: "time11",
                    key: "time11",
                    width: 50,
                    align: "center",
                },
                {
                    title: "时间2",
                    dataIndex: "time12",
                    key: "time12",
                    width: 50,
                    align: "center",
                },
                {
                    title: "同比",
                    dataIndex: "year_on_year1",
                    key: "year_on_year1",
                    width: 50,
                    align: "center",
                },
            ]
        },
        {
            title: "天猫",
            children: [
                {
                    title: "天猫排名",
                    dataIndex: "tmall_category",
                    key: "tmall_category",
                    width: 50,
                    align: "center",
                },
                {
                    title: "时间1",
                    dataIndex: "time21",
                    key: "time21",
                    width: 50,
                    align: "center",
                },
                {
                    title: "时间2",
                    dataIndex: "time22",
                    key: "time22",
                    width: 50,
                    align: "center",
                },
                {
                    title: "同比",
                    dataIndex: "year_on_year2",
                    key: "year_on_year2",
                    width: 50,
                    align: "center",
                },

            ]

        },

    ])

    // 核心品类热词趋势表头
    const coreCategoryHotwordColumns = [
        {
            title: "排名",
            dataIndex: "ranking",
            key: "ranking",
            width: 50,
            align: "center",
        },
        {
            title: "时间段+品类",
            dataIndex: "time_slot_category",
            key: "time_slot_category",
            width: 50,
            align: "center",
        },
        {
            title: "搜索人数",
            dataIndex: "searching_people",
            key: "searching_people  ",
            width: 50,
            align: "center",
        }
    ]
    // 行业TOP分析表数据表头
    const industryTopColumns = [
        {
            title: "日期",
            dataIndex: "date",
            key: "date",
            width: 50,
            align: "center",
        },
        {
            title: "商品名称",
            dataIndex: "trade_name",
            key: "trade_name",
            width: 50,
            align: "center",
        },
        {
            title: "店铺",
            dataIndex: "shop",
            key: "shop",
            width: 50,
            align: "center",
        },
        {
            title: "平台",
            dataIndex: "platform",
            key: "platform",
            width: 50,
            align: "center",
        },
        {
            title: "类目名称",
            dataIndex: "category_name",
            key: "category_name",
            width: 50,
            align: "center",
        },
        {
            title: "行业排名",
            dataIndex: "industry_ranking",
            key: "industry_ranking",
            width: 50,
            align: "center",
        },
        {
            title: "交易金额",
            dataIndex: "transaction_amount",
            key: "transaction_amount",
            width: 50,
            align: "center",
        },
        {
            title: "交易增长幅度",
            dataIndex: "transaction_growth",
            key: "transaction_growth",
            width: 50,
            align: "center",
        },
        {
            title: "支付转化率",
            dataIndex: "payment_conversion_rate",
            key: "payment_conversion_rate",
            width: 50,
            align: "center",
        },
    ]
    return (
        <div>
            <Spin tip="Loading..." spinning={loading}>
                <div style={{ display: "flex",position:'fixed', top:40, zIndex:99, backgroundColor:'#fff' }}>
                    <div style={{display:'flex', justifyContent:'space-around', width:'100vw', borderBottom: "1px dashed #ccc"}}>
                        <div style={{marginTop:'0.8vh'}}>
                            <Title level={3}>商品企划——行业</Title>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop:'0.4vh' }}>
                            <a href="#1F">
                                <Button style={{ marginTop: 6, marginRight: 10 }}>行业—品类趋势表</Button>
                            </a>
                            <a href="#2F">
                                <Button style={{ marginTop: 6, marginRight: 10 }}>核心品类热词趋势表</Button>
                            </a>
                            <a href="#3F">
                                <Button style={{ marginTop: 6, marginRight: 10 }}>行业TOP分析表</Button>
                            </a>
                        </div>
                    </div>
                </div>
                {/* 行业—品类趋势表 */}
                <div style={{ width: "80vw", border: "1px dashed #ccc", marginLeft: "10vw", marginTop: 50 }} id="1F">
                    <div style={{ display: "flex", justifyContent: 'center', marginTop: 20 }}>
                        <Title level={3}>行业—品类趋势表</Title>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 15 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>时间1：</Text>
                        </div>

                        <div style={{ marginLeft: 20 }}>
                            <DatePicker
                                locale={locale}
                                picker="year"
                                onChange={onIndustryCategoryTrendTime1}
                                value={
                                    industryCategoryTrendTime1 == ""
                                        ? ""
                                        : moment(industryCategoryTrendTime1, dateFormat1)
                                }
                                format={dateFormat1}
                                style={{ width: 188 }}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}        // 禁止选择超过今天的日期 
                            />
                        </div>
                        <div style={{ marginTop: 12, marginLeft: 15 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>时间2：</Text>
                        </div>
                        <div style={{ marginTop: 10, marginLeft: 20 }}>
                            <DatePicker
                                locale={locale}
                                picker="year"
                                onChange={onIndustryCategoryTrendTime2}
                                value={
                                    industryCategoryTrendTime2 == ""
                                        ? ""
                                        : moment(industryCategoryTrendTime2, dateFormat1)
                                }
                                format={dateFormat1}
                                style={{ width: 188 }}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}        // 禁止选择超过今天的日期 
                            />
                        </div>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 10 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>排名前：</Text>
                        </div>

                        <div style={{ marginLeft: 20 }}>
                            <Input style={{ width: 188 }} value={industryCategoryTrendTopNumber} onChange={(e) => {
                                let obj = [...industryCategoryTrendTopNumber]
                                obj = e.target.value
                                setIndustryCategoryTrendTopNumber(obj)
                            }} />

                        </div >
                    </div>
                    <div style={{ display: "flex", float: "right", marginRight: 50, marginTop: 20 }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                if (industryCategoryTrendTime1 == "") {
                                    industryCategoryTrendWarningTime1()
                                } else if (industryCategoryTrendTime2 == "") {
                                    industryCategoryTrendWarningTime2()
                                } else if (industryCategoryTrendTopNumber == false) {
                                    industryCategoryTrendWarningTop()
                                }else{
                                    setloading(true)
                                    setIndustryCategoryTrendColumns([
                                        {
                                            title: "全网排名",
                                            dataIndex: "ranking",
                                            key: "ranking",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "全网",
                                            children: [
                                                {
                                                    title: "品类",
                                                    dataIndex: "category",
                                                    key: "category",
                                                    width: 50,
                                                    align: "center",
                                                },
                                                {
                                                    title: industryCategoryTrendTime1,
                                                    dataIndex: "time11",
                                                    key: "time11",
                                                    width: 50,
                                                    align: "center",
                                                },
                                                {
                                                    title: industryCategoryTrendTime2,
                                                    dataIndex: "time12",
                                                    key: "time12",
                                                    width: 50,
                                                    align: "center",
                                                },
                                                {
                                                    title: "同比",
                                                    dataIndex: "year_on_year1",
                                                    key: "year_on_year1",
                                                    width: 50,
                                                    align: "center",
                                                },
                                            ]
                                        },
                                        {
                                            title: "天猫",
                                            children: [
                                                {
                                                    title: "天猫排名",
                                                    dataIndex: "tmall_rank",
                                                    key: "tmall_rank",
                                                    width: 50,
                                                    align: "center",
                                                },
                                                {
                                                    title: industryCategoryTrendTime1,
                                                    dataIndex: "time21",
                                                    key: "time21",
                                                    width: 50,
                                                    align: "center",
                                                },
                                                {
                                                    title: industryCategoryTrendTime2,
                                                    dataIndex: "time22",
                                                    key: "time22",
                                                    width: 50,
                                                    align: "center",
                                                },
                                                {
                                                    title: "同比",
                                                    dataIndex: "year_on_year2",
                                                    key: "year_on_year2",
                                                    width: 50,
                                                    align: "center",
                                                },
                                
                                            ]
                                        },
                                    ])
                                    let dataProps = {
                                        industryCategoryTrendTime1:industryCategoryTrendTime1,
                                        industryCategoryTrendTime2:industryCategoryTrendTime2,
                                        industryCategoryTrendTopNumber:industryCategoryTrendTopNumber,
                                        roleId:cookie.load('role'),
                                        shopList:cookie.load('shopList'),
                                    }
                                    NbAxios(dataProps, "POST", servicePath.industryCategory).then(
                                        (res) => {
                                            setloading(false)
                                            if (res.success) {
                                                message.success("查询成功");
                                                setIndustryCategoryTrendTableDate(res.data)
                                            } else {
                                                message.warn(res.errorMsg);
                                            }
                                        }
                                    );
                                }
                            }}
                        >
                            

                            确定</Button>
                        <Button type="primary" onClick={()=>{
                            if(industryCategoryTrendTableDate.length == 0){
                                message.error("未生成表格")
                            }else{
                                setloading(true)
                                // 数据传入调接口
                                let dataProps = {
                                    industryCategoryTrendTime1:industryCategoryTrendTime1,
                                    industryCategoryTrendTime2:industryCategoryTrendTime2,
                                    industryCategoryTrendTableDate:industryCategoryTrendTableDate
                                }
                                axios({
                                    data: dataProps,
                                    method: 'POST',
                                    url: servicePath.downloadIndustryCategory,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        setloading(false)
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    setloading(false)
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }
                        }}>下载</Button>
                    </div>
                    <div>
                        <Table
                            dataSource={industryCategoryTrendTableDate}
                            columns={industryCategoryTrendColumns}
                            columnTitle="64px"
                            scroll={{ y: 500 }}
                            pagination={false}
                        />
                    </div>
                </div>

                {/* 核心品类热词趋势表 */}
                <div style={{ width: "80vw", border: "1px dashed #ccc", marginLeft: "10vw", marginTop: 50 }} id="2F">
                    <div style={{ display: "flex", justifyContent: 'center', marginTop: 20 }}>
                        <Title level={3}>核心品类热词趋势表</Title>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 15 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>时间1：</Text>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <DatePicker
                                locale={locale}
                                picker="month"
                                onChange={onCoreCategoryHotwordTime1}
                                value={
                                    coreCategoryHotwordTime1 == ""
                                        ? ""
                                        : moment(coreCategoryHotwordTime1, dateFormat)
                                }
                                format={dateFormat}
                                style={{ width: 188 }}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}        // 禁止选择超过今天的日期 
                            />
                        </div>
                        <div style={{ marginTop: 12, marginLeft: 15 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>时间2：</Text>
                        </div>
                        <div style={{ marginTop: 10, marginLeft: 20 }}>
                            <DatePicker
                                locale={locale}
                                picker="month"
                                onChange={onCoreCategoryHotwordTime2}
                                value={
                                    coreCategoryHotwordTime2 == ""
                                        ? ""
                                        : moment(coreCategoryHotwordTime2, dateFormat)
                                }
                                format={dateFormat}
                                style={{ width: 188 }}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}        // 禁止选择超过今天的日期 
                            />
                        </div>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 10 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>排名前：</Text>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <Input style={{ width: 188 }} value={coreCategoryHotwordTopNumber} onChange={(e) => {
                                let obj = [...coreCategoryHotwordTopNumber]
                                obj = e.target.value
                                setCoreCategoryHotwordTopNumber(obj)
                            }} />

                        </div >
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 30 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>品类：</Text>
                        </div>
                        <div style={{ marginLeft: 20, marginTop: 5 }}>
                            <Radio.Group
                                onChange={(e) => { setCoreCategoryHotwordChecked(e.target.value) }}
                                value={coreCategoryHotwordChecked}

                            >
                                {coreCategoryHotwordList.map((item) => (
                                    <Radio value={item}>{item}</Radio>
                                ))}

                            </Radio.Group>
                        </div>

                    </div>
                    <div style={{ display: "flex", float: "right", marginRight: 50, marginTop: 20 }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                if (coreCategoryHotwordTime1 == "") {
                                    coreCategoryHotwordWarningTime1()
                                } else if (coreCategoryHotwordTime2 == "") {
                                    coreCategoryHotwordWarningTime2()
                                } else if (coreCategoryHotwordTopNumber == false) {
                                    coreCategoryHotwordWarningTop()
                                } else if (coreCategoryHotwordChecked == false) {
                                    coreCategoryHotwordWarningChecked()
                                }else{
                                    let dataProps = {
                                        coreCategoryHotwordTime1: coreCategoryHotwordTime1,
                                        coreCategoryHotwordTime2: coreCategoryHotwordTime2,
                                        coreCategoryHotwordTopNumber: coreCategoryHotwordTopNumber,
                                        coreCategoryHotwordChecked: coreCategoryHotwordChecked,
                                        roleId:cookie.load('role'),
                                        shopList:cookie.load('shopList'),
                                    }
                                    setloading(true)
                                    NbAxios(dataProps, "POST", servicePath.coreCategory).then(
                                        (res) => {
                                            setloading(false)
                                            if (res.success) {
                                                message.success("查询成功");
                                                setCoreCategoryHotwordTableDate(res.data)
                                            } else {
                                                message.warn(res.errorMsg);
                                            }
                                        }
                                    );
                                }
                            }}
                        >

                            确定</Button>
                        <Button type="primary" onClick={()=>{
                            if(coreCategoryHotwordTableDate.length == 0){
                                message.error("未生成表格")
                            }else{
                                setloading(true)
                                // 数据传入调接口
                                let dataProps = {
                                    coreCategoryHotwordTime1: coreCategoryHotwordTime1,
                                        coreCategoryHotwordTime2: coreCategoryHotwordTime2,
                                        coreCategoryHotwordTopNumber: coreCategoryHotwordTopNumber,
                                        coreCategoryHotwordChecked: coreCategoryHotwordChecked
                                }
                                axios({
                                    data: dataProps,
                                    method: 'POST',
                                    url: servicePath.downloadCoreCategory,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        setloading(false)
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    setloading(false)
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }
                        }}>下载</Button>
                    </div>
                    <div>
                        <Table
                            dataSource={coreCategoryHotwordTableDate}
                            columns={coreCategoryHotwordColumns}
                            columnTitle="64px"
                            scroll={{ y :500}}
                            pagination={false}
                        />
                    </div>
                </div>
                {/* 行业TOP分析表 */}
                <div style={{ width: "80vw", border: "1px dashed #ccc", marginLeft: "10vw", marginTop: 50 }} id="3F">
                    <div style={{ display: "flex", justifyContent: 'center', marginTop: 20 }}>
                        <Title level={3}>行业TOP分析表</Title>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 30 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>时间：</Text>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <DatePicker
                                locale={locale}
                                picker="month"
                                onChange={onIndustryTopTime}
                                value={
                                    industryTopTime == ""
                                        ? ""
                                        : moment(industryTopTime, dateFormat)
                                }
                                format={dateFormat}
                                style={{ width: 188 }}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}        // 禁止选择超过今天的日期 
                            />
                        </div>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 30 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>品类：</Text>
                        </div>
                        <div style={{ marginLeft: 20, marginTop: 5 }}>
                            <Radio.Group
                                onChange={(e) => setIndustryTopChecked(e.target.value)}
                                value={industryTopChecked}
                            >
                                {industryTopList.map((item) => (
                                    <Radio value={item}>{item}</Radio>
                                ))}

                            </Radio.Group>
                        </div>
                    </div>
                    <div style={{ display: "flex", float: "right", marginRight: 50, marginTop: 20 }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                if (industryTopTime == "") {
                                    industryTopWarningTime()
                                }else if (industryTopChecked == false) {
                                    industryTopWarningChecked()
                                }else{
                                    let dataProps={
                                        industryTopTime:industryTopTime,
                                        industryTopChecked:industryTopChecked,
                                        roleId:cookie.load('role'),
                                        shopList:cookie.load('shopList'),
                                    }
                                    NbAxios(dataProps, "POST", servicePath.industryTop).then(
                                        (res) => {
                                            setloading(false)
                                            if (res.success) {
                                                message.success("查询成功");
                                                setIndustryTopTableDate(res.data)
                                            } else {
                                                message.warn(res.errorMsg);
                                            }
                                        }
                                    );
                                }
                            }}
                        >

                            确定</Button>
                        <Button type="primary" onClick={()=>{
                            if(industryTopTableDate.length == 0){
                                message.error("未生成表格")
                            }else{
                                setloading(true)
                                // 数据传入调接口
                                let dataProps = {
                                    industryTopTime:industryTopTime,
                                    industryTopChecked:industryTopChecked
                                }
                                axios({
                                    data: dataProps,
                                    method: 'POST',
                                    url: servicePath.downloadIndustryTop,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        setloading(false)
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    setloading(false)
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }
                        }}>下载</Button>
                    </div>
                    <div style={{marginBottom:100}}>
                        <Table
                            dataSource={industryTopTableDate}
                            columns={industryTopColumns}
                            scroll={{y:500}}
                            pagination={false}
                        />
                    </div>
                </div>
            </Spin>

        </div>

    )
}

export default Index;