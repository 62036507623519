import React, { Component } from 'react';
import { DatePicker, Select, Spin, Tooltip } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
const { RangePicker } = DatePicker;
const { Option } = Select;
function MarkChartUi(props) {
    const { isLoading, monthChange, start,disabledMonth, end, slectFiled, allFiled, select, cateory } = props
    return (
        <div>
            <Spin
                tip='数据加载中，请稍等...'
                spinning={isLoading}
            >
                <span style={{ fontSize: '1.1rem' }}>选择时间轴：</span>
                <Tooltip title="请先选择时间轴" defaultVisible={true} color={"blue"}>
                <RangePicker
                    picker='month'
                    locale={locale}
                    value={[start, end]}
                    onChange={monthChange}
                    disabledDate={disabledMonth}
                />
                </Tooltip>
                <span style={{ marginLeft: 10, fontSize: '1.1rem' }}>选择查询字段：</span>
                <Select style={{ width: 200, }} onChange={slectFiled} value={select} placeholder={'选择查询字段'}>
                    {allFiled.map((item, index) => {
                        return (
                            <Option key={index} value={item}>{item}</Option>
                        )
                    })}
                </Select>
                <div id='cateory' style={{ width: '90vw', height: '75vh', padding: 20 }}>
                </div>
            </Spin>
        </div>
    )
}

export default MarkChartUi