import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Select, Breadcrumb, Cascader, DatePicker, Space, Tabs, Button, Row, Col, message, Tooltip } from 'antd';
import cookie from 'react-cookies'
import moment from 'moment'
import Product from './productRank'
import Store from './storeRank'
import axios from 'axios';
import servicePath from '../../config/apiUrl'
import { StateContext } from '../raceShop/store/stateShare'
import locale from 'antd/es/date-picker/locale/zh_CN';

const { TabPane } = Tabs

//父子组件共享类目
export const TitleContxet = React.createContext();
//父子组件共享店铺商品切换的值
export const shopOrProductContxet = React.createContext();
//父子组件共享时间
export const dateContxet = React.createContext();
export const weekContext = React.createContext();
export const monthContext = React.createContext();
//父子组件共享渲染控制参数,
export const renderProductContext = React.createContext();
export const renderStoreContext = React.createContext();

function MarketRank(props) {
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)

    // 获取size数据
    let size = state.size

    //定义类目选择数组
    const [category, setCategory] = useState(props.location.pathname.split('/')[4])

    //定义选择的类目
    const [title, setTitle] = useState([])

    //定义选择商品还是店铺
    const [shopOrProduct, setShopOrProduct] = useState('店铺')

    //定义选择的月份
    const [month, setMonth] = useState(moment().endOf('month').subtract(1, 'month'))

    //月变化的回调
    const monthChange = (date, dateString) => {
        if (date === null || date === '') {
            return false
        }
        setMonth(date)
    }

    //选择商品还是店铺,并切换回调，用于控制请求商品还是店铺页面的数据
    const ShopOrProduct = (e) => {
        setShopOrProduct(e)
    }

    //类目的筛选
    const filter = (inputValue, path) => {
        return (path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1))
    }

    //定义不可选择的日期，当前日周月以及之后的日期不可选择
    const disabledMonth = (current) => {
        return current && current > moment().endOf('month').subtract(1, 'month');
    }

    const [tip, setTip] = useState(true)

    // 跳转数据导出页面
    function download() {
        props.history.push(`/app/peek/markrRank/${category}`)
    }

    return (
        <div>
            <div style={{marginTop: size.height * 0.01, marginLeft: size.width * 0.04, marginRight: size.width * 0.03 }}>
                <DatePicker
                    disabledDate={disabledMonth}
                    picker='month'
                    onChange={monthChange}
                    placeholder='选择查询月份'
                    locale={locale}
                    value={month}
                />
                <Button type='primary' onClick={download} style={{marginLeft:20}}>
                    导出数据
                </Button>
                {/* 父子组件传值 */}
                <shopOrProductContxet.Provider value={shopOrProduct}>
                    {/* <dateContxet.Provider value={date}> */}
                    <TitleContxet.Provider value={title}>
                        {/* <weekContext.Provider value={week}> */}
                        <monthContext.Provider value={month} >
                            <Tabs
                                defaultActiveKey='店铺'
                                onChange={ShopOrProduct}
                            >
                                <TabPane tab='店铺排行' key='店铺' >
                                    <Store
                                        month={month}
                                        shopOrProduct={shopOrProduct}
                                        category={category}
                                        download={download}
                                    />
                                </TabPane>
                                {/* <TabPane tab='商品' key='商品'>
                                            <Product />
                                        </TabPane> */}
                            </Tabs>
                        </monthContext.Provider>
                        {/* </weekContext.Provider> */}
                    </TitleContxet.Provider>
                    {/* </dateContxet.Provider> */}
                </shopOrProductContxet.Provider>
                {/* 父子组件传值结束 */}
            </div>
        </div>
    )
}

export default MarketRank