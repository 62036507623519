import React, { useState, useEffect } from 'react';
import { Button, Radio, Table, Space, DatePicker, message, Drawer, Form, Row, Col, Input, Modal, Typography, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import moment from "moment"
import cookie from 'react-cookies'
import NbAxios from '../../../../config/utils/nbAxios'
import servicePath from '../../../../config/apiUrl'
import Base64 from 'base-64'
import Highlighter from 'react-highlight-words';
const {Text, Title} = Typography
const { Search } = Input;
function Index(){

     // 定义筛选功能开始
     const [searchText, setSearchText] = useState('')
     const [searchedColumn, setSearchedColumn] = useState('')
     const getColumnSearchProps = dataIndex => ({
         filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
             <div style={{padding: 8}}>
                 <Input
                     placeholder={`搜索${dataIndex}`}
                     value={selectedKeys[0]}
                     onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                     onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                     style={{width: 188, marginBottom: 8, display: 'block'}}
                 />
                 <Space>
                     <Button
                         type="primary"
                         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                         icon={<SearchOutlined/>}
                         size="small"
                         style={{width: 90}}
                     >
                         查询
                     </Button>
                     <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                         重置
                     </Button>
                 </Space>
             </div>
         ),
         filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
         onFilter: (value, record) =>
             record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
         onFilterDropdownVisibleChange: visible => {
         },
         render: text =>
             searchedColumn === dataIndex ? (
                 <Highlighter
                     highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                     searchWords={[searchText]}
                     autoEscape
                     textToHighlight={text.toString()}
                 />
             ) : (
                 text
             ),
     });
     const handleSearch = (selectedKeys, confirm, dataIndex) => {
         confirm();
         setSearchText(selectedKeys[0])
         setSearchedColumn(dataIndex)
     }
     const handleReset = clearFilters => {
         clearFilters();
         setSearchText('')
     };
     // 定义筛选功能结束
    const [ form ] = Form.useForm()
    const layout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 14 },
    };
    // id
    const [ id , setId ] = useState('')
    // 页面
    const [ page , setPage ] = useState(1)
    // 总数
    const [ total , setTotal ] = useState(100)
    // 添加科目
    const [ isAdd , setIsAdd ] =  useState(false)
    // 修改
    const [isUpdate, setIsUpdate] = useState(false)
    // 禁用
    const [isDelete, setIsDelete] = useState(false)
    // 编辑科目
    const [ updateSubject , setUpdateSubject] = useState(false)
    // 选择的key
    const [ selectedRowKeys , setSelectedRowKeys ] = useState([])
    // 设置关键字列表
    const [selectList, setSelectList] = useState([])
    // 项目名称
    const [ project , setProject ] = useState('')
    // 数据
    const [ data , setData ] = useState([])

    // 科目数据
    const [ subjectData , setSubjectData ] = useState([])
    // 项目表格表头
    const projectColumns = [
        {
            title:'名称',
            dataIndex:'project',
            key: 'project',
            align: 'center',
        },
        {
            title:'编辑科目',
            dataIndex:'name',
            key: 'name',
            align: 'center',
            render: (text, record, index) => (
                <a onClick={()=>findProjectSubject(record.project_id)}>编辑科目</a>
            ),
        },
        {
            title:'编辑',
            dataIndex:'edit',
            key: 'edit',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={()=>{
                    setId(record.project_id)
                    form.setFieldsValue(record)
                    setIsUpdate(true)
                }} />
            ),
        }
    ]
    // 科目表格表头
    const sujectColumns = [
        {
            title:'金蝶费用分组',
            dataIndex:'expense_group',
            key: 'expense_group',
            align: 'center',
            ...getColumnSearchProps('expense_group'),
        },
        {
            title:'对应会计科目代码',
            dataIndex:'corresponding_account_code',
            key: 'corresponding_account_code',
            align: 'center',
            ...getColumnSearchProps('corresponding_account_code'),
        },
        {
            title:'会计科目全称',
            dataIndex:'full_name_of_account',
            key: 'full_name_of_account',
            align: 'center',
            ...getColumnSearchProps('full_name_of_account'),
        },
        {
            title:'对应损益表末级科目',
            dataIndex:'end_level_account_of_income_statement',
            key: 'end_level_account_of_income_statement',
            align: 'center',
            ...getColumnSearchProps('end_level_account_of_income_statement'),
        },
    ]
    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    useEffect(()=>{
        getProjectData()
        getFinancialSubject()
    },[])

    //查询项目下面所有科目
    const findProjectSubject = (id)=>{
        let value = {}
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        value.creator = cookie.load('name')
        value.id = id
        NbAxios(value, "POST", servicePath.findFinancialProjectSubject).then(res => {
            if (res.success) {
                setId(id)
                let idList = []
                res.data.map(item=>{
                    idList.push(item.subject_id)
                })
                setSelectedRowKeys(idList)
                setUpdateSubject(true)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

     // 查询科目
     const getFinancialSubject = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "page":page,
            "pageSize":1000,
        }
        NbAxios(dataProps, "POST", servicePath.findFinancialSubject).then(res => {
            if (res.success) {
                console.log(res.data);
                setSubjectData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取项目数据
    const getProjectData = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "page":page,
            "pageSize":10,
            "project ":project,
        }
        NbAxios(dataProps, "POST", servicePath.findFinancialProject).then(res => {
            if (res.success) {
                setTotal(res.total);
                setData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    const onSelectChange = selectedRowKeys =>{
        setSelectedRowKeys(selectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const updateData = (value)=>{
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        value.creator = cookie.load('name')
        value.id = id
        NbAxios(value, "POST", servicePath.modifyFinancialProject).then(res => {
            if (res.success) {
               message.success('项目修改成功')
               setIsUpdate(false)
               setId('')
               getProjectData()
            } else {
                message.warn(res.errorMsg)
            }
        })

    }

    const addData = (value)=>{
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        value.creator = cookie.load('name')
        NbAxios(value, "POST", servicePath.addFinancialProject).then(res => {
            if (res.success) {
               message.success('项目添加成功')
               setIsAdd(false)
               getProjectData()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 禁用项目
    const deleteProject = ()=>{
        let value = {}
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        value.creator = cookie.load('name')
        value.id = id
        NbAxios(value, "POST", servicePath.deleteFinancialProject).then(res => {
            if (res.success) {
               message.success('项目禁用成功')
               setIsDelete(false)
               setIsUpdate(false)
               setId('')
               getProjectData()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 编辑项目、科目管理
    const updateProjectSubject = ()=>{
        let value = {}
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        value.creator = cookie.load('name')
        value.project_id = id
        value.subject_id_list = selectedRowKeys
        NbAxios(value, "POST", servicePath.modifyFinancialProjectSubject).then(res => {
            if (res.success) {
                message.success('项目、科目修改成功')
                setUpdateSubject(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
        setUpdateSubject(false)
    }

    return (
        <div>
            <div style={{width:'100vw', backgroundColor:'#fff', padding:10, paddingLeft:20}}>
                <Title level={2} style={{margin: 0}}>项目管理</Title>
            </div>
            <div style={{display:'flex', width:'100vw', backgroundColor:'#fff', paddingLeft:20, paddingBottom:10}}>
                <Button type="primary" onClick={()=>setIsAdd(true)}>添加项目</Button>
                <div>
                    <Search
                        style={{marginLeft:20, width:300}}
                        placeholder="请输入科目全称,支持模糊查询"
                        allowClear
                        enterButton="搜索"
                        // size="large"
                        onSearch={(value)=>{
                            setProject(value)
                            let obj = []
                            obj.push(value)
                            setSelectList(obj)
                        }}
                    />
                </div>
            </div>
            <div >
                <Table
                    columns={projectColumns}
                    pagination={paginationProps}
                    dataSource={data}
                    scroll={{ x: 1500 }}
                    rowKey={'id'}
                />
            </div>
            {/* 编辑项目 */}
            <Modal
                title="编辑项目"
                centered="true"
                visible={isUpdate}
                onCancel={() => setIsUpdate(false)}
                footer={[]}
                width={550}
            >
                <Form form={form} {...layout} labelAlign='left' onFinish={updateData}>
                    <Form.Item label="项目名称" name="project">
                        <Input />
                    </Form.Item>
                    <div style={{display:'flex', justifyContent:"space-around"}}>
                        <Button type="primary" htmlType="submit">
                            更新
                        </Button>
                        <Button type="primary" danger onClick={()=>setIsDelete(true)}>禁用</Button>
                    </div>
                </Form>
            </Modal>

            {/* 添加项目 */}
            <Modal
                title="添加项目"
                centered="true"
                visible={isAdd}
                onCancel={() => setIsAdd(false)}
                footer={[]}
                width={550}
            >
                <Form form={form} {...layout} labelAlign='left' onFinish={addData}>
                    <Form.Item label="项目名称" name="project"
                        rules={[{ required: true, message: '请输入项目名称！' }]}
                    >
                        <Input />
                    </Form.Item>
                    <div style={{display:'flex', justifyContent:"center"}}>
                        <Button type="primary" htmlType="submit">
                            添加项目
                        </Button>
                    </div>
                </Form>
            </Modal>

            {/* 禁用 */}
            <Modal
                title="禁用科目"
                centered="true"
                visible={isDelete}
                cancelText="取消"
                okText="确认"
                onCancel={() => setIsDelete(false)}
                onOk={()=>{deleteProject()}}
                width={550}
            >
                <Text type="danger">是否要禁用" <Text strong type="danger">{form.getFieldValue('accountTitle')}</Text> "这个科目？</Text>
            </Modal>

            <Modal
                title="编辑项目-科目"
                centered="true"
                visible={updateSubject}
                cancelText="取消"
                okText="确认"
                onCancel={() => setUpdateSubject(false)}
                onOk={()=>updateProjectSubject()}
                width={800}
            >
                <Table
                    columns={sujectColumns}
                    dataSource={subjectData}
                    rowSelection={rowSelection}
                    pagination={false}
                    rowKey={'subject_id'}
                 />
            </Modal>
        </div>
    )

}

export default Index