import React, { Component } from 'react';

function Circle({width,height,color,percent,...rest}) {
    return (
        <svg style={{width:width,height:height}} viewBox="0 0 100 100" {...rest}>
            <path class="ant-progress-circle-trail"
                d="M 50,50 m 0,-47 a 47,47 0 1 1 0,94 a 47,47 0 1 1 0,-94"
                stroke-linecap="round"
                stroke-width="0"
                fill-opacity="0"
                style={{
                    strokeDasharray: "295.31px, 295.31px",
                    strokeDashoffset: 0,
                    transition: 'stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s'
                }}
            >
            </path>
            <path className="ant-progress-circle-path" d="M 50,50 m 0,-47 a 47,47 0 1 1 0,94 a 47,47 0 1 1 0,-94"
                stroke=""
                stroke-linecap="round"
                stroke-width="6"
                opacity="1"
                fill-opacity="0"
                style={{
                    strokeDasharray: `${295.31*percent}px, 295.31px`,
                    strokeDashoffset: 0,
                    transition: 'stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s',
                    stroke: color
                }}
            >
            </path>
        </svg>
    )
}
export default Circle


