import React, {Component} from 'react';
import {Typography} from 'antd'
import SaleImage from '../../../../saleBoard/components/saleImage'
import style from './topCard.module.css'

const {Text} = Typography;


function TopCard({
                     shopName,
                     sku,
                     id,
                     topClick
                 }) {


    return (
        <div>
            <div className={style.card} onClick={() => topClick(id)}>
                <SaleImage style={{height: 100, width: 100}} shopName={shopName} id={id} sku={sku}/>
                <Text type="secondary" style={{marginTop: "2px"}}>{id}</Text>
            </div>
        </div>
    )

}

export default TopCard