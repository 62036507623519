import React, {useEffect, useState } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

function Index(
    graph={
        "nodes":[],
        "links":[],
        "categories":[],
    }
) {

    let report = {
        tooltip: {
            // 默认会绘制一个圆点图形
            valueFormatter: (value) => '&' + ' ' + value + '商品',
            //效果同上一行代码，
            /*formatter: function (params) {
                console.log(params)
                //params[0].name表示x轴数据
                let str = params.seriesName + '<br/>'
                //params是数组格式
                //设置浮层图形的样式跟随图中展示的颜色
                str += "<span style='display:inline-block;width:10px;height:10px;border-radius:10px;background-color:" + params.color + ";'></span>" + "\t" + params.name + " : " + params.value
                return str
            }，*/
        },
        legend: [
            {
                // selectedMode: 'single',
                data: graph.categories.map(function (a) {
                    return a.name;
                })
            }
        ],
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
            {
                name: '商品品类：',
                type: 'graph',  //绘制图像的类型为关系图
                layout: 'force',  // 图的布局 'circular' 采用环形布局 'force' 采用力引导布局
                // 引力布局的配置
                force: {
                    // initLayout: 'circular'
                    // gravity: 0
                    repulsion: 100,
                    edgeLength: 50
                },
                data: graph.nodes,
                links: graph.links,
                categories: graph.categories,
                roam: true,
                label: {
                    position: 'right',
                    formatter: '{b}'
                },
                lineStyle: {
                    color: 'source',
                    curveness: 0.3
                },
                emphasis: {
                    focus: 'adjacency',
                    lineStyle: {
                        width: 10
                    },
                }
            }
        ]
    }
    useEffect(()=>{
        graph.nodes.forEach(function (node) {
            node.label = {
                show: node.symbolSize > 3
            };
        });
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('relationChart'));
        reportChart.clear()
        // 绘制图表
        reportChart.setOption(report)
        // window捕获标签点击，表格重绘放到浏览器渲染前
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[graph])


    return (
        <div>
            <div id="relationChart" style={{width:"1000px", height:"1000px"}}></div>
        </div>
    )

}

export default Index