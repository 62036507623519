import cookie from 'react-cookies'
import Base64 from 'base-64'
import uuid from './uuid';
import myBrowser from './myBrowser';
// 接受埋点参数
export default function AcceptBuried(eventName = '', event_type = '', eventVariable={} , path="", module = ""){
    // 浏览器类型
    let browserType = myBrowser()
    // console.log(browserType);
    let userAgent=navigator.userAgent.toLowerCase(), s, o = {};
    // 浏览器版本
    let browserVersion = (userAgent.match(/(?:firefox|opera|safari|chrome|msie)[\/: ]([\d.]+)/))[1]
    // console.log(browserVersion);
    // 用户信息
    let userId = cookie.load('id')
    // console.log(userId);
    // 用户邮箱
    let userEmail = Base64.decode(cookie.load('email'))
    // console.log(userEmail);
    // 时间戳
    let timestamp = new Date().getTime();
    // console.log(timestamp);
    // console.log(eventName);
    // console.log(eventVariable);
    // uuid
    let jsonUuid = uuid()
    let jsonData = {
        id:jsonUuid,
        trigger_time : timestamp,
        event_name: eventName,
        event_variable: eventVariable,
        event_type :event_type,
        default_variable:{
            browser_type: browserType,
            browser_version: "97.0.4758.109",
            user_id : userId,
            user_email : userEmail,
            function_path:path,
            function_module:module
        }
    }
    localStorage.setItem(jsonUuid,JSON.stringify(jsonData));
}



