import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Tag, Form, Select, Space, Collapse, Spin, DatePicker} from 'antd'
import {CloseCircleOutlined, SearchOutlined, DownloadOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import axios from 'axios'
import '../../static/css/Table.css'
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment';

// 插件爬虫文件导出
function Download(props) {
    // 查询条件 task_id
    const [taskId, setTaskId] = useState('')

    // 查询条件 task_type
    const [taskType, setTaskType] = useState('')

    const chooseTaskType = (value) => {
        setTaskType(value)
    }

    // 查询条件 status
    const [status, setStatus] = useState('')

    const chooseStatus = (value) => {
        setStatus(value)
    }

    // 查询条件 start_time
    const [startTime, setStartTime] = useState('')

    // 查询条件 end_time
    const [endTime, setEndTime] = useState('')

    // 查询条件 creator
    const [creator, setCreator] = useState('')

    // 折叠面板
    const { Panel } = Collapse;

    // 查询条件表单
    const style = { padding: '8px 20px 0 20px' };

    // 时间选择
    const { RangePicker } = DatePicker;

    // 时间改变的方法
    const onPickerChange = (date, dateString)=>{
        setStartTime(dateString[0])
        setEndTime(dateString[1])
    }

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // 定义每页显示数量,默认为10
    const [pageSize, setPageSize] = useState(10)

    // 定义当前页码,默认为1
    const [page, setPage] = useState(1)

    // 定义总页数，由接口返回
    const [total, setTotal] = useState(0)

    //定义筛选功能
    const [searchText, setSearchText] = useState('')

    const [searchedColumn, setSearchedColumn] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    // ref={node => {
                    //     this.searchInput = node;
                    // }}
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };

    // 结果表的表头
    const storeColumns = [
        {
            title: 'id',
            dataIndex: 'task_id',
            key: 'task_id',
            align: 'center',
            width: 100,
            ...getColumnSearchProps('task_id'),
            sorter: (a, b) => {
                setPage(1)
                return a.task_id - b.task_id
            },
        },
        {
            title: '任务类型',
            dataIndex: 'task_type',
            key: 'task_type',
            align: 'center',
            width: 120,
            // fixed: 'left',
            render: (text, record, index) => {
                if(text == "1"){
                    return "商品关键字搜索结果爬取"
                } else if(text == "2") {
                    return "店铺关键字搜索结果爬取"
                } else if(text == "3") {
                    return "店铺所有商品列表爬取"
                } else if(text == "4") {
                    return "商品评论爬取(仅支持天猫)"
                } else if(text == "5") {
                    return "商品报活动价格爬取"
                } else if(text == "6") {
                    return "生意参谋预售活动商品详情爬取"
                } else if(text == "7") {
                    return "唯品会店铺商品爬取"
                } else if(text == "8") {
                    return "天猫用户清关信息爬取并上传至CRM"
                } else if(text == "9") {
                    return "手淘直播商品数据爬取"
                } else if(text == "10") {
                    return "直播广告投放花费监控"
                } else if(text == "11") {
                    return "抖音退货单"
                } else if(text == "12") {
                    return "抖音商品信息修改"
                } else if(text == "13") {
                    return "飞瓜推广视频数据"
                } else {
                    return "未知任务类型"
                }
            }
        },
        {
            title: '任务状态',
            dataIndex: 'status',
            key: 'status',
            // width: 200,
            align: 'center',
            // fixed: 'left',
            render: (text, record, index) => {
                if(text == null || text == "" || text == "nan"){
                    text = record.spider_task_status
                }
                if (text == "0") {
                    return "待执行"
                } else if (text == "1") {
                    return "执行中"
                } else if (text == "3") {
                    return "执行完毕"
                } else if (text == "4") {
                    return "执行出错"
                } else if (text == "2") {
                    return "手动关闭"
                } else {
                    return "未知任务状态"
                }
            },
        },
        {
            title: '任务创建人',
            dataIndex: 'creator',
            key: 'creator',
            align: 'center',
            // width: 200,
            render: (text, record, index) => {
                // if (text == null || text == "" || text == "nan") {
                //     text = record.spider_task_creator
                // }
                return text
            }
        },
        {
            title: '任务创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: 'center',
            // width: 100,
        },
        {
            title: '备注',
            dataIndex: 'keywords',
            key: 'keywords',
            align: 'center',
            // width: 100,
        },
        {
            title: '下载',
            dataIndex: 'task_id',
            key: 'task_id',
            align: 'center',
            // width: 100,
            render: (text, record, index) => {
                if (record.status == "3"){
                    let str = "&shopId=" + cookie.load('shopList') + "&roleId=" + cookie.load('role')
                    if (record.task_type == "1"){
                        return <a href={servicePath.getTaskType1Data + text + str} target={"_blank"}><DownloadOutlined /></a>
                    } else if (record.task_type == "2"){
                        return <a href={servicePath.getTaskType2Data + text + str} target={"_blank"}><DownloadOutlined /></a>
                    } else if (record.task_type == "3"){
                        return <a href={servicePath.getTaskType3Data + text + str} target={"_blank"}><DownloadOutlined /></a>
                    } else if (record.task_type == "4"){
                        return <a href={servicePath.getTaskType4Data + text + str} target={"_blank"}><DownloadOutlined /></a>
                    } else if (record.task_type == "5"){
                        return <a href={servicePath.getTaskType5Data + text + str} target={"_blank"}><DownloadOutlined /></a>
                    } else if (record.task_type == "6"){
                        return <a href={servicePath.getTaskType6Data + text + str} target={"_blank"}><DownloadOutlined /></a>
                    } else if (record.task_type == "7"){
                        return <a href={servicePath.getTaskType7Data + text + str} target={"_blank"}><DownloadOutlined /></a>
                    } else if (record.task_type == "9"){
                        return <a href={servicePath.getTaskType9Data + text + str} target={"_blank"}><DownloadOutlined /></a>
                    } else if (record.task_type == "11"){
                        return <a href={servicePath.getTaskType11Data + text + str} target={"_blank"}><DownloadOutlined /></a>
                    } else if (record.task_type == "13"){
                        return <a href={servicePath.getTaskType13Data + text + str} target={"_blank"}><DownloadOutlined /></a>
                    } else {
                        return <CloseCircleOutlined />
                    }
                }
                else {
                    return <CloseCircleOutlined />
                }
            }
        },
        {
            title: '查看',
            dataIndex: 'keywords',
            key: 'keywords',
            align: 'center',
            // width: 100,
            render: (text, record, index) => {
                if (record.status == "3"){
                    if (record.task_type == "4"){
                        // var url = "http://localhost:3000/app/spiderPlugin/ciyun?item_id=" + text
                        var url = "https://nb20.bmcsoft.cn/app/spiderPlugin/ciyun?item_id=" + text
                        return <a href={url} target={"_blank"} style={{color:'blue'}}>查看评论词云</a>
                    }
                }
                return "暂无查看信息"
            }
        },
    ];

    //设置表格加载状态
    const [isLoading, setIsLoading] = useState(false)

    // 定义数据
    const [data, setData] = useState([])

    // 获取插件爬虫任务所有列表
    const selectSpiderPluginTask = () => {
        let dataUrl = ""
        if (taskType == 0){
            message.error("请选择任务类型")
            return 0
        } else if (taskType == 1){
            dataUrl = servicePath.getSpiderPluginTask1
        } else if (taskType == 2){
            dataUrl = servicePath.getSpiderPluginTask2
        } else if (taskType == 3){
            dataUrl = servicePath.getSpiderPluginTask3
        } else if (taskType == 4){
            dataUrl = servicePath.getSpiderPluginTask4
        } else if (taskType == 5){
            dataUrl = servicePath.getSpiderPluginTask5
        } else if (taskType == 6){
            dataUrl = servicePath.getSpiderPluginTask6
        } else if (taskType == 7){
            dataUrl = servicePath.getSpiderPluginTask7
        } else if (taskType == 8){
            dataUrl = servicePath.getSpiderPluginTask8
        } else if (taskType == 9){
            dataUrl = servicePath.getSpiderPluginTask9
        } else if (taskType == 10){
            dataUrl = servicePath.getSpiderPluginTask10
        } else if (taskType == 11){
            dataUrl = servicePath.getSpiderPluginTask11
        } else if (taskType == 12){
            dataUrl = servicePath.getSpiderPluginTask12
        } else if (taskType == 13){
            dataUrl = servicePath.getSpiderPluginTask13
        } else {
            message.error("该任务类型不支持文件下载")
            return 0
        }
        setIsLoading(true)
        // 请求参数
        let dataProps = {
            "shopId": cookie.load('shopList'),
            "roleId": cookie.load('role'),
            "pageSize": pageSize,
            "page": page,
            "task_id": taskId,
            "task_type": taskType,
            "status": status,
            "start_time": startTime,
            "end_time": endTime,
            "creator": creator
        };
        // 请求接口
        axios({
            method: 'POST',
            url: dataUrl,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    //获取数据成功
                    let data = res.data.data
                    let total = res.data.total
                    setTotal(total)
                    setData(data)
                }
                else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
                setIsLoading(false)
            }
        ).catch(error => {
            setIsLoading(false)
        })
    }

    // 获取插件爬虫任务所有列表，开始直接执行一次
    // useEffect(() => {
    //     selectSpiderPluginTask()
    //     },[]
    // )

    // 分页器参数
    const paginationProps = {
        showSizeChanger: false,
        current: page,
        total: total,
        pageSize: pageSize, // 每页条数
        onChange: (page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
        },
    }
    useEffect(() => {
        if(taskType != ''){
            selectSpiderPluginTask()
        }
    }, [page])

    return (
        <div style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.03, marginRight: size.width * 0.03 }} >
            {/* 查询条件 */}
            <div >
                <Collapse>
                    <Panel header="查询条件">
                        <Form
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                        >
                            <Row>
                                <Col span={8}>
                                    <div style={style}>
                                        <Form.Item
                                            label="任务类型"
                                            name="taskType"
                                        >
                                            <Select onChange={chooseTaskType} defaultValue="">
                                                <Select.Option value="">--请选择任务类型--</Select.Option>
                                                <Select.Option value="1">商品关键字搜索结果爬取</Select.Option>
                                                <Select.Option value="2">店铺关键字搜索结果爬取</Select.Option>
                                                <Select.Option value="3">店铺所有商品列表爬取</Select.Option>
                                                <Select.Option value="4">商品评论爬取(仅支持天猫)</Select.Option>
                                                <Select.Option value="5">商品报活动价格爬取</Select.Option>
                                                <Select.Option value="6">生意参谋预售活动商品详情爬取</Select.Option>
                                                <Select.Option value="7">唯品会店铺商品爬取</Select.Option>
                                                <Select.Option value="8">天猫用户清关信息爬取并上传至CRM</Select.Option>
                                                <Select.Option value="9">手淘直播商品数据爬取</Select.Option>
                                                <Select.Option value="10">直播广告投放花费监控</Select.Option>
                                                <Select.Option value="11">抖音退货单</Select.Option>
                                                <Select.Option value="12">抖音商品信息修改</Select.Option>
                                                <Select.Option value="13">飞瓜推广视频数据</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={style}>
                                        <Form.Item
                                            label="任务状态"
                                            name="status"
                                        >
                                            <Select onChange={chooseStatus} defaultValue="">
                                                <Select.Option value="">--请选择任务状态--</Select.Option>
                                                <Select.Option value="0">待执行</Select.Option>
                                                <Select.Option value="1">执行中</Select.Option>
                                                <Select.Option value="3">执行完毕</Select.Option>
                                                <Select.Option value="4">执行出错</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={style}>
                                        <Form.Item
                                            label="任务创建人"
                                            name="creator"
                                        >
                                            <Input
                                                onChange={(e) => {
                                                    setCreator(e.target.value)
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={16}>
                                    <div style={style}>
                                        <Form.Item
                                            label="任务时间"
                                            name="time"
                                        >
                                            <div style={{}}>
                                                <RangePicker
                                                    locale={locale}
                                                    defaultValue={moment('2015-01-01', 'YYYY-MM-DD')}
                                                    onChange={onPickerChange}
                                                    placeholder={['开始时间','结束时间']}
                                                />
                                            </div>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{}}>
                                        <Button type="primary" htmlType="submit" onClick={()=>{
                                            setPage(1)
                                            selectSpiderPluginTask()
                                        }}>
                                            查询
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                    </Panel>
                </Collapse>
            </div>

            {/* 结果表 */}
            <div style={{ marginTop: size.height * 0.01}}>
                <Spin
                    tip='数据请求中，请稍等...'
                    spinning={isLoading}
                >
                    <Table
                        columns={storeColumns}
                        dataSource={data}
                        pagination={paginationProps}
                        scroll={{ x:'80vw', y: `${size.width>1500?'58vh':'45vh'}` }}
                    />
                </Spin>
            </div>
        </div>
    )
}

export default Download