import React, { useState, useEffect, useContext } from 'react';
import { Typography, DatePicker, message, Form, Row, Spin, Select, Button, Table } from 'antd'
import moment from 'moment'
import { StateContext } from '../../raceShop/store/stateShare'
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios'
import servicePath from '../../../config/apiUrl'
const { RangePicker } = DatePicker
const { Title } = Typography
const { Option } = Select
const dateFormat = 'YYYY-MM-DD';
function Index(props) {
    // 店铺对应id字典
    const shopIdDict = {
        airinum:"ff6176e6-da1f-4354-b51c-033aed89967e"
    }
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    // 获取屏幕尺寸
    const size = state.size
    // 表格汇总
    const [tableSummary] = useState(['访客数', '访客数增速', '访客市场占有率', '访客市场占有率增速', '店铺转化率', '店铺转化率增速', '爆款转化率', '爆款转化率增速', '天猫店铺周UV', '天猫店铺周UV占比', '爆款周UV', '爆款周UV占比', '爆款UV占全店UV比例', '爆款UV占全店UV比例增速'])
    // 显示表格集合
    const [showTable, setShowTable] = useState([])
    // 店铺名字
    const [shopName, setShopName] = useState('')
    // 开始时间
    const [startTime, setStartTime] = useState(moment().subtract(91, "days").format('YYYY-MM-DD'))
    // 结束时间
    const [endTime, setEndTime] = useState(moment().subtract(1, "days").format('YYYY-MM-DD'))
    // 时间轴
    const [timeColumns, setTimeColumns] = useState([])
    // 访客数数据
    const [visitorData, setVisitorData] = useState([])
    // 访客增速数据
    const [visitorGrowthData, setVisitorGrowthData] = useState([])
    // 占有率数据
    const [possessData, setPossessData] = useState([])
    // 占有率增速数据
    const [possessGrowthData, setPossessGrowthData] = useState([])
    // 转化率数据
    const [transformData, setTransformData] = useState([])
    // 转化率增速数据
    const [transformGrowthData, setTransformGrowthData] = useState([])
    // 爆款转化率数据
    const [explosionTransformData, setExplosionTransformData] = useState([])
    // 爆款转化率增速数据
    const [explosionTransformGrowthData, setExplosionTransformGrowthData] = useState([])
    // 爆款周UV
    const [explosionUVData, setExplosionUVData] = useState([])
    // 爆款周UV占比
    const [explosionUVGrowthData, setExplosionUVGrowthData] = useState([])
    // 店铺周UV数据
    const [shopWeekUVData, setShopWeekUVData] = useState([])
    // 店铺周UV增速数据
    const [shopWeekUVGrowthData, setShopWeekUVGrowthData] = useState([])
    // 爆款周UV数据
    const [explosionWeekUVData, setExplosionWeekUVData] = useState([])
    // 爆款周UV增速数据
    const [explosionWeekUVGrowthData, setExplosionWeekUVGrowthData] = useState([])
    // 请求状态
    const [isSpinning, setIsSpinning] = useState(false)
    useEffect(() => {
        setShopName(props.location.pathname.split('/')[2])
    }, [])

    useEffect(() => {
        setVisitorData([])
        setVisitorGrowthData([])
        setPossessData([])
        setTransformData([])
        setTransformGrowthData([])
        setExplosionTransformData([])
        setExplosionTransformGrowthData([])
        setExplosionUVData([])
        setExplosionUVGrowthData([])
        setShopWeekUVData([])
        setShopWeekUVGrowthData([])
        setExplosionWeekUVData([])
        setExplosionWeekUVGrowthData([])
        getStandardHeadData()
        setShowTable(['访客数', '访客市场占有率', '天猫店铺周UV'])
    }, [startTime, endTime])


    // 获取表头日期接口
    const getStandardHeadData = ()=>{
        let dataProps = {}
        dataProps.shopId = shopIdDict[shopName]
        dataProps.roleId = cookie.load('role')
        dataProps.shopList = cookie.load('shopList')
        dataProps.start_time = startTime
        dataProps.end_time = endTime
        NbAxios(dataProps, "POST", servicePath.getStandardHeadData).then(res => {
            if (res.success) {
                setTimeColumns(res.data['time_index']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 访客表头
    const visitorColumns = [
        {
            title: '淘系口罩-访客',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left',
            render: (text, record, index) => (
                text == '3345739112' ? <span>{'airinum旗舰店'}</span>
                :text == '3017095037' ? <span>{'mehow旗舰店'}</span>
                :text == '3351172141' ? <span>{'ohsunny旗舰店'}</span>
                :<span>{text}</span>
            ),
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]
    // 访客增速
    const visitorGrowthColumns = [
        {
            title: '访客增速',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left',
            render: (text, record, index) => (
                text == '3345739112' ? <span>{'airinum旗舰店'}</span>
                :text == '3017095037' ? <span>{'mehow旗舰店'}</span>
                :text == '3351172141' ? <span>{'ohsunny旗舰店'}</span>
                :<span>{text}</span>
            ),
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]
    // 市场占有率
    const possessColumns = [
        {
            title: '访客市场占有率',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left',
            render: (text, record, index) => (
                text == '3345739112' ? <span>{'airinum旗舰店'}</span>
                :text == '3017095037' ? <span>{'mehow旗舰店'}</span>
                :text == '3351172141' ? <span>{'ohsunny旗舰店'}</span>
                :<span>{text}</span>
            ),
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    // 市场占有率增速
    const possessGrowthColumns = [
        {
            title: '访客市场占有率增速',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left',
            render: (text, record, index) => (
                text == '3345739112' ? <span>{'airinum旗舰店'}</span>
                :text == '3017095037' ? <span>{'mehow旗舰店'}</span>
                :text == '3351172141' ? <span>{'ohsunny旗舰店'}</span>
                :<span>{text}</span>
            ),
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    //转化率
    const transformDataColumns = [
        {
            title: '店铺转化率',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left',
            render: (text, record, index) => (
                text == '3345739112' ? <span>{'airinum旗舰店'}</span>
                :text == '3017095037' ? <span>{'mehow旗舰店'}</span>
                :text == '3351172141' ? <span>{'ohsunny旗舰店'}</span>
                :<span>{text}</span>
            ),
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    //转化率增速
    const transformGrowthColumns = [
        {
            title: '店铺转化率增速',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left',
            render: (text, record, index) => (
                text == '3345739112' ? <span>{'airinum旗舰店'}</span>
                :text == '3017095037' ? <span>{'mehow旗舰店'}</span>
                :text == '3351172141' ? <span>{'ohsunny旗舰店'}</span>
                :<span>{text}</span>
            ),
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    //爆款UV
    const explosionUVColumns = [
        {
            title: '爆款UV占全店UV比例',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left'
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    //爆款UV增速
    const explosionUVGrowthColumns = [
        {
            title: '爆款UV占全店UV比例增速',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left'
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    //天猫店铺周UV
    const shopWeekUVDataColumns = [
        {
            title: '天猫店铺周UV',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left'
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    //天猫店铺周UV占比
    const shopWeekUVDataGrowthColumns = [
        {
            title: '天猫店铺周UV占比',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left'
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    //爆款周UV
    const explosionWeekUVDataColumns = [
        {
            title: '爆款周UV',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left'
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    //爆款周UV占比
    const explosionWeekUVGrowthDataColumns = [
        {
            title: '爆款周UV占比',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left'
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    //爆款转化率
    const explosionTransformDataColumns = [
        {
            title: '爆款转化率',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left'
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    //爆款转化率增速
    const explosionTransformGrowthDataColumns = [
        {
            title: '爆款转化率增速',
            dataIndex: 'index',
            key: 'index',
            width: 150,
            fixed: 'left'
        },
        ...timeColumns.map(item => {
            return {
                title: item,
                key: item,
                dataIndex: item,
                width: 120
            }
        })
    ]

    const onTimeChange = (dates, dateStrings) => {
        if (dateStrings[0] == '') {
            message.warn('请选择正确的时间')
            return false
        } else {
            setStartTime(moment(dates[0]).format('YYYY-MM-DD'))
            setEndTime(moment(dates[1]).format('YYYY-MM-DD'))
        }
    }

    // 查询
    const Search = () => {
        if (showTable.indexOf('访客数') == 0 || showTable.indexOf('访客数增速') == 0 || showTable.indexOf('访客市场占有率') == 0 || showTable.indexOf('访客市场占有率增速') == 0 
        || showTable.indexOf('天猫店铺周UV') == 0 || showTable.indexOf('天猫店铺周UV占比') == 0) {
            getVisitorData()
        }
        if (showTable.indexOf('店铺转化率') == 0 || showTable.indexOf('店铺转化率增速')==0){
            getCompeteShopPayTransformRateData()
        }
        if (showTable.indexOf('爆款转化率')== 0 || showTable.indexOf('爆款转化率增速')==0){
            getCompeteItemPayTransformRateData()
        }
        if (showTable.indexOf('爆款UV占全店UV比例')== 0 || showTable.indexOf('爆款UV占全店UV比例增速')==0){
            getCompeteItemViewsRelevantData()
        }
        if (showTable.indexOf('天猫店铺周UV')== 0 || showTable.indexOf('天猫店铺周UV占比')==0){
            getCompeteShopViewsData()
        }
        if (showTable.indexOf('爆款周UV') == 0 || showTable.indexOf('爆款周UV占比')==0){
            getCompeteItemViewsData()
        }
    }

    // 获取爆款UV数据接口
    const getCompeteItemViewsData = ()=>{
        if (explosionWeekUVGrowthData.length == 0) {
            setIsSpinning(true)
            let dataProps = {}
            dataProps.shopId = shopIdDict[shopName]
            dataProps.roleId = cookie.load('role')
            dataProps.shopList = cookie.load('shopList')
            dataProps.start_time = startTime
            dataProps.end_time = endTime
            NbAxios(dataProps, "POST", servicePath.getCompeteItemViewsData).then(res => {
                setIsSpinning(false)
                if (res.success) {
                    setExplosionWeekUVData(res.data['table1']);
                    setExplosionWeekUVGrowthData(res.data['table2']);
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 获取店铺转化率接口
    const getCompeteShopPayTransformRateData = ()=>{
        if (transformData.length == 0) {
            setIsSpinning(true)
            let dataProps = {}
            dataProps.shopId = shopIdDict[shopName]
            dataProps.roleId = cookie.load('role')
            dataProps.shopList = cookie.load('shopList')
            dataProps.start_time = startTime
            dataProps.end_time = endTime
            NbAxios(dataProps, "POST", servicePath.getCompeteShopPayTransformRateData).then(res => {
                setIsSpinning(false)
                if (res.success) {
                    setTransformData(res.data['table1']);
                    setTransformGrowthData(res.data['table2']);
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 获取访客数
    const getVisitorData = () => {
        if (visitorData.length == 0) {
            setIsSpinning(true)
            let dataProps = {}
            dataProps.shopId = shopIdDict[shopName]
            dataProps.roleId = cookie.load('role')
            dataProps.shopList = cookie.load('shopList')
            dataProps.start_time = startTime
            dataProps.end_time = endTime
            NbAxios(dataProps, "POST", servicePath.getViewsRelevantData).then(res => {
                setIsSpinning(false)
                if (res.success) {
                    setVisitorData(res.data['table1']);
                    setVisitorGrowthData(res.data['table2']);
                    setPossessData(res.data['table3']);
                    setPossessGrowthData(res.data['table4']);
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    //获取爆款转化率相关接口
    const getCompeteItemPayTransformRateData = ()=>{
        if(explosionTransformData.length == 0){
            setIsSpinning(true)
            let dataProps = {}
            dataProps.shopId = shopIdDict[shopName]
            dataProps.roleId = cookie.load('role')
            dataProps.shopList = cookie.load('shopList')
            dataProps.start_time = startTime
            dataProps.end_time = endTime
            NbAxios(dataProps, "POST", servicePath.getCompeteItemPayTransformRateData).then(res => {
                setIsSpinning(false)
                if (res.success) {
                    setExplosionTransformData(res.data['table1']);
                    setExplosionTransformGrowthData(res.data['table2']);
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    //获取爆款UV占全店UV比例
    const getCompeteItemViewsRelevantData = ()=>{
        if(explosionUVData.length == 0){
            setIsSpinning(true)
            let dataProps = {}
            dataProps.shopId = shopIdDict[shopName]
            dataProps.roleId = cookie.load('role')
            dataProps.shopList = cookie.load('shopList')
            dataProps.start_time = startTime
            dataProps.end_time = endTime
            NbAxios(dataProps, "POST", servicePath.getCompeteItemViewsRelevantData).then(res => {
                setIsSpinning(false)
                if (res.success) {
                    setExplosionUVData(res.data['table1']);
                    setExplosionUVGrowthData(res.data['table2']);
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    //获取天猫店铺周UV
    const getCompeteShopViewsData = ()=>{
        if(explosionUVData.length == 0){
            setIsSpinning(true)
            let dataProps = {}
            dataProps.shopId = shopIdDict[shopName]
            dataProps.roleId = cookie.load('role')
            dataProps.shopList = cookie.load('shopList')
            dataProps.start_time = startTime
            dataProps.end_time = endTime
            NbAxios(dataProps, "POST", servicePath.getCompeteShopViewsData).then(res => {
                setIsSpinning(false)
                if (res.success) {
                    console.log(res.data);
                    setShopWeekUVData(res.data['table1']);
                    setShopWeekUVGrowthData(res.data['table2']);
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    return (
        <div>
            <div style={{
                backgroundColor: "#fff",
                borderBottom: "solid 2px #ccc",
                marginBottom: 10,
                // height:70,
            }}>
                <div style={{
                    position: 'fixed',
                    top: 40,
                    zIndex: 99,
                    width:'100vw',
                    backgroundColor:'#fff'
                }}>
                    <div style={{ display: 'flex', justifyContent: "space-around"}}>
                        <Title level={2}>{shopName}行业数据</Title>
                        <RangePicker picker="week" locale={locale} onChange={onTimeChange} style={{ height: "30px", marginTop: 10 }}
                            value={[moment(startTime, dateFormat), moment(endTime, dateFormat)]}
                        />
                    </div>
                    <div style={{ width: '100vw', display: 'flex', marginTop:-35}}>
                        <Form style={{ width: "80vw", marginTop:-20 }}>
                            <Form.Item label="显示条件" style={{ marginTop: 40, marginLeft: 10 }}>
                                <Select mode="multiple" value={showTable}
                                    onChange={(value) => {
                                        setShowTable(value)
                                    }}
                                    style={{ width: '100%', marginTop: 10, marginLeft: 6 }}>
                                    {
                                        tableSummary.map(item => {
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                        <Button type="primary" style={{ marginTop: 30, marginLeft: 20 }} onClick={() => Search()}>查询</Button>
                    </div>
                </div>
                <Spin spinning={isSpinning} tip="数据请求中.....">
                    <div style={{marginTop:100}}>
                    <div style={{ display: showTable.indexOf('访客数') != -1 ? '' : 'none' }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={visitorData}
                            columns={visitorColumns}
                            pagination={false}
                            />
                            
                    </div>
                    <div style={{ display: showTable.indexOf('访客数增速') != -1 ? '' : 'none' , marginTop:10}}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={visitorGrowthData}
                            columns={visitorGrowthColumns}
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('访客市场占有率') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={possessData}
                            columns={possessColumns}
                            pagination={false}
                             />
                    </div>
                    <div style={{ display: showTable.indexOf('访客市场占有率增速') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={possessGrowthData}
                            columns={possessGrowthColumns}
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('店铺转化率') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={transformData}
                            columns={transformDataColumns}
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('店铺转化率增速') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={transformGrowthData}
                            columns={transformGrowthColumns} 
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('爆款转化率') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={explosionTransformData}
                            columns={explosionTransformDataColumns} 
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('爆款转化率增速') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={explosionTransformGrowthData}
                            columns={explosionTransformGrowthDataColumns} 
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('天猫店铺周UV') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={shopWeekUVData}
                            columns={shopWeekUVDataColumns} 
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('天猫店铺周UV占比') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={shopWeekUVGrowthData}
                            columns={shopWeekUVDataGrowthColumns} 
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('爆款周UV') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={explosionWeekUVData}
                            columns={explosionWeekUVDataColumns} 
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('爆款周UV占比') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={explosionWeekUVGrowthData}
                            columns={explosionWeekUVGrowthDataColumns} 
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('爆款UV占全店UV比例') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={explosionUVData}
                            columns={explosionUVColumns} 
                            pagination={false}
                            />
                    </div>
                    <div style={{ display: showTable.indexOf('爆款UV占全店UV比例增速') != -1 ? '' : 'none', marginTop:10 }}>
                        <Table
                            scroll={{ x: 1300 }}
                            dataSource={explosionUVGrowthData}
                            columns={explosionUVGrowthColumns} 
                            pagination={false}
                            />
                    </div>
                    </div>
                </Spin>

            </div>
        </div>
    )

}

export default Index