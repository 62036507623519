import React from 'react';
import { Spin, Table, Tabs, Button } from 'antd'
import './static/markborder.css'
import MarkChart from './markChart'
const { TabPane } = Tabs
function MarkBorderUi(props) {
    const { size, columns, data, title, isLoading, cateory, download, paginationProps, tabsChange } = props

    return (
        <div style={{ marginLeft: size.width * 0.04 }}>
            <Tabs
                defaultActiveKey='数据'
                size={"large"}
            >
                <TabPane tab='数据' key='数据'  >
                    <div className='marke-border' style={{  marginRight: size.width * 0.04 }}>
                        <Spin
                            tip='数据请求中，请稍等...'
                            spinning={isLoading}
                        >
                            <Table
                                columns={columns}
                                dataSource={data}
                                paginationProps={paginationProps}
                                title={title}
                                scroll={{ x: size.width * 0.8,y: `${size.width>1500?'90vh':'56vh'}`}}
                            />
                        </Spin>
                    </div>
                </TabPane>
                <TabPane tab='图表' key='图表' >
                    <MarkChart
                        cateory={cateory}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}
export default MarkBorderUi