import React, { useEffect, useState } from 'react';
import { DatePicker, Typography, Radio, message, Table, Select, Button, Spin } from 'antd'
import moment from 'moment'
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'
import axios from 'axios';
import SaleImage from '../saleBoard/components/saleImage'
import locale from 'antd/es/date-picker/locale/zh_CN';
import DownExcel from '../../config/utils/downloadFile';
const { Title } = Typography;
const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = 'YYYY-MM-DD';

function Index(props) {

    const [ newOldCountList] = useState([50,50,50,50,50,50,50,50,50,50,50,50])

    const [ preferenceCategoryList] = useState([40,40,40,40,40,40,40,40,40,40,40,40])

    const [ memberCountList ] = useState([10,10,28,10,10,10,10,10,10,10,10,10])

    const [ regionAnalysisCountList ] = useState([10,10,28,10,10,10,10,10,10,10,10,10])

    const [ topCountList] = useState([30,30,30,30,30,30,30,30,30,30,30,30])

    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    // 开始时间
    const [startTime, setStartTime] = useState(moment().subtract(1, "month").format('YYYY-MM-DD'))
    // 结束时间
    const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DD'))
    // 新老客数据
    const [newOldData, setNewOldData] = useState([])
    const [ newOldCount , setNewOldCount ] = useState(0)
    const [consumptionLevelData, setConsumptionLevelData] = useState([])
    // 新客TOP
    const [newTopData, setNewTopData] = useState([])
    const [oldTopData, setOldTopData] = useState([])
    // 地域数据
    const [areaData, setAreaData] = useState([])
    // 品类数据
    const [categoryData, setCategoryData] = useState([])
    // 新老客加载
    const [newOldLoading, setNewOldLoading] = useState(false)
    // 会员消费等级加载
    const [memberLoading, setMemberLoading] = useState(false)
    const [ memberCount , setMemberCount ] = useState(0)
    // 老客TOP款
    const [oldTopLoading, setOldTopLoading] = useState(false)
    // 新客TOP款
    const [newTopLoading, setNewTopLoading] = useState(false)
    const [ topCount , setTopCount ] = useState(0)
    // 地域
    const [regionAnalysisLoading, setRegionAnalysisLoading] = useState(false)
    const [ regionAnalysisCount , setRegionAnalysisTopCount ] = useState(0)
    // 下载会员数据
    const [downloadConsumptionFileLoading, setDownloadConsumptionFileLoading] = useState(false)
    // 品类
    const [preferenceCategoryLoading, setPreferenceCategoryLoading] = useState(false)
    const [preferenceCategoryCount, setPreferenceCategoryCount] = useState(0)
    // 维度
    const [dimension, setDimension] = useState(1)
    // 会员等级
    const [membershipLevel, setMembershipLevel] = useState(1)
    // 时间是否修改
    const [ isTimeChange , setIsTimeChange ] = useState(false)
    // 获取数据
    const getAllData = async () => {
        await Promise.all([
            getConsumerAnalysisNewOldData(),
            getConsumptionLevelsData(),
            getOldTopSingleAnalysisData(),
            getNewTopSingleAnalysisData(),
            getRegionAnalysisData(),
            getPreferenceCategoryAnalysisData()
        ])
    }


    useEffect(()=>{
        setIsTimeChange(true)
    },[startTime, endTime])

    useEffect(() => {
        setNewOldData([])
        setNewTopData([])
        setOldTopData([])
        setAreaData([])
        setCategoryData([])
        setConsumptionLevelData([])
        getAllData()
        setIsTimeChange(false)
    }, [dimension])

    //定时任务
    // 1000ms 等于 1s
    useEffect(()=>{
        //  当开始的时候，开始进行计时
        if(memberCount != 0){
            setTimeout(()=>{
                if(memberLoading){
                    let count = memberCount - 1
                    setMemberCount(count)
                }else{
                    setMemberCount(0)
                }
              
            },1000  )
        }
    },[memberCount])

    useEffect(()=>{
        //  当开始的时候，开始进行计时
        if(topCount != 0){
            setTimeout(()=>{
                if(newTopLoading){
                    let count = topCount - 1
                    setTopCount(count)
                }else{
                    setTopCount(0)
                }
              
            },1000  )
        }
    },[topCount])

    useEffect(()=>{
        //  当开始的时候，开始进行计时
        if(preferenceCategoryCount != 0){
            setTimeout(()=>{
                if(preferenceCategoryLoading){
                    let count = preferenceCategoryCount - 1
                    setPreferenceCategoryCount(count)
                }else{
                    setPreferenceCategoryCount(0)
                }
                
            },1000  )
        }
    },[preferenceCategoryCount])

    useEffect(()=>{
        //  当开始的时候，开始进行计时
        if(newOldCount != 0){
            setTimeout(()=>{
                if(newOldLoading){
                    let count = newOldCount - 1
                    setNewOldCount(count)
                }else{
                    setNewOldCount(0)
                }
                
            },1000  )
        }
    },[newOldCount])

    useEffect(()=>{
        //  当开始的时候，开始进行计时
        if(regionAnalysisCount != 0){
            setTimeout(()=>{
                if(regionAnalysisLoading){
                    let count = regionAnalysisCount - 1
                    setRegionAnalysisTopCount(count)
                }else{
                    setRegionAnalysisTopCount(0)
                }
               
            },1000  )
        }
    },[regionAnalysisCount])


    useEffect(() => {
        const getData = async () => {
            await Promise.all([
                getRegionAnalysisData(),
                getPreferenceCategoryAnalysisData()
            ])
        }
        getData()

    }, [membershipLevel])

    //导出用户名单接口
    const downloadConsumptionFile = () => {
        setDownloadConsumptionFileLoading(true)
        let data = {}
        data.shopId =  shopIdDict[props.history.location.pathname.split('/')[2]]
        data.start_time = startTime
        data.end_time = endTime
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        data.is_actual_sales = dimension
        data.consumer_rank = membershipLevel
        axios({
            data: data,
            method: 'POST',
            url: servicePath.downloadConsumptionFile,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setDownloadConsumptionFileLoading(false)
                DownExcel(res)
            }
        ).catch(e => {
            message.error({content: '文件制作出错，请联系it同事！'})
        })

    }

    //偏好品类分析数据
    const getPreferenceCategoryAnalysisData = () => {
        setPreferenceCategoryLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'start_time': startTime,
            'end_time': endTime,
            'is_actual_sales': dimension,
            'consumer_rank': membershipLevel
        }
        let startList = startTime.split("-")
        let endList = endTime.split("-")
        let startMon = parseInt(startList[1]) - 1
        let endMon = parseInt(endList[1]) - 1
        let count = 0
        if (startMon == endMon){
            count = count + preferenceCategoryList[startMon]
        }else{
            for(let i=startMon ;i<= endMon; i++){
                count = count + preferenceCategoryList[i]
            }
        }
        setPreferenceCategoryCount(count)
        NbAxios(dataProps, "POST", servicePath.getPreferenceCategoryAnalysisData).then(res => {
            setPreferenceCategoryLoading(false)
            if (res.success) {
                setCategoryData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 地域TOP分析数据
    const getRegionAnalysisData = () => {
        setRegionAnalysisLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'start_time': startTime,
            'end_time': endTime,
            'is_actual_sales': dimension,
            'consumer_rank': membershipLevel
        }
        let startList = startTime.split("-")
        let endList = endTime.split("-")
        let startMon = parseInt(startList[1]) - 1
        let endMon = parseInt(endList[1]) - 1
        let count = 0
        if (startMon == endMon){
            count = count + regionAnalysisCountList[startMon]
        }else{
            for(let i=startMon ;i<= endMon; i++){
                count = count + regionAnalysisCountList[i]
            }
        }
        setRegionAnalysisTopCount(count)
        NbAxios(dataProps, "POST", servicePath.getRegionAnalysisData).then(res => {
            setRegionAnalysisLoading(false)
            if (res.success) {
                setAreaData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    //消费者人数分析新老客接口
    const getConsumerAnalysisNewOldData = () => {
        setNewOldLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'start_time': startTime,
            'end_time': endTime,
            'is_actual_sales': dimension
        }
        let startList = startTime.split("-")
        let endList = endTime.split("-")
        let startMon = parseInt(startList[1]) - 1
        let endMon = parseInt(endList[1]) - 1
        let count = 0
        if (startMon == endMon){
            count = count + newOldCountList[startMon]
        }else{
            for(let i=startMon ;i<= endMon; i++){
                count = count + newOldCountList[i]
            }
        }
        setNewOldCount(count)
        NbAxios(dataProps, "POST", servicePath.getConsumerAnalysisNewOldData).then(res => {
            // setNewOldLoading(false)
            if (res.success) {
                var obj = res.data
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    'start_time': moment(startTime).subtract(1, 'month').format('YYYY-MM-DD'),
                    'end_time': moment(endTime).subtract(1, 'month').format('YYYY-MM-DD'),
                    'key': '环比',
                    'is_actual_sales': dimension
                }
                NbAxios(dataProps, "POST", servicePath.getConsumerAnalysisData).then(res => {
                    if (res.success) {
                        res.data.map((item, index) => {
                            obj[index]['Chain'] = item['环比']
                        })
                        let dataProp = {
                            "roleId": cookie.load('role'),
                            "shopList": cookie.load('shopList'),
                            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                            'start_time': moment(startTime).subtract(1, 'year').format('YYYY-MM-DD'),
                            'end_time': moment(endTime).subtract(1, 'year').format('YYYY-MM-DD'),
                            'key': '同比',
                            'is_actual_sales': dimension
                        }
                        NbAxios(dataProp, "POST", servicePath.getConsumerAnalysisData).then(res => {
                            setNewOldLoading(false)
                            if (res.success) {
                                res.data.map((item, index) => {
                                    obj[index]['onYear'] = item['同比']
                                })
                                setNewOldData(obj);
                            } else {
                                message.warn(res.errorMsg)
                            }
                        })
                    } else {
                        setNewOldLoading(false)
                        message.warn(res.errorMsg)
                    }
                })

            } else {
                setNewOldLoading(false)
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取会员消费层次数据接口
    const getConsumptionLevelsData = () => {
        setMemberLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'start_time': startTime,
            'end_time': endTime,
            'is_actual_sales': dimension
        }
        let startList = startTime.split("-")
        let endList = endTime.split("-")
        let startMon = parseInt(startList[1]) - 1
        let endMon = parseInt(endList[1]) - 1
        let count = 0
        if (startMon == endMon){
            count = count + memberCountList[startMon]
        }else{
            for(let i=startMon ;i<= endMon; i++){
                count = count + memberCountList[i]
            }
        }
        setMemberCount(count)
        NbAxios(dataProps, "POST", servicePath.getConsumptionLevelsData).then(res => {
            setMemberLoading(false)
            if (res.success) {
                setConsumptionLevelData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取老客TOP款分析数据接口
    const getOldTopSingleAnalysisData = () => {
        setOldTopLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'start_time': startTime,
            'end_time': endTime,
            'is_actual_sales': dimension,
            'is_new_customers': 2
        }
        let startList = startTime.split("-")
        let endList = endTime.split("-")
        let startMon = parseInt(startList[1]) - 1
        let endMon = parseInt(endList[1]) - 1
        let count = 0
        if (startMon == endMon){
            count = count + topCountList[startMon]
        }else{
            for(let i=startMon ;i<= endMon; i++){
                count = count + topCountList[i]
            }
        }
        setTopCount(count)
        NbAxios(dataProps, "POST", servicePath.getTopSingleAnalysisData).then(res => {
            setOldTopLoading(false)
            if (res.success) {
                setOldTopData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取新客TOP款分析数据接口
    const getNewTopSingleAnalysisData = () => {
        setNewTopLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'start_time': startTime,
            'end_time': endTime,
            'is_actual_sales': dimension,
            'is_new_customers': 1
        }
        NbAxios(dataProps, "POST", servicePath.getTopSingleAnalysisData).then(res => {
            setNewTopLoading(false)
            if (res.success) {
                setNewTopData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 时间修改函数
    const onTimeChange = (dates, dateStrings) => {
        if (dateStrings[0] == '') {
            message.warn('请选择正确的时间')
            return false
        } else {
            setStartTime(dateStrings[0])
            setEndTime(dateStrings[1])
        }
    }

    // 新老客
    const newOldColumns = [
        {
            title: '',
            key: 'id',
            dataIndex: 'id',
            align: 'center',
            width: 130
        },
        {
            title: '新客',
            key: 'new',
            dataIndex: 'new',
            align: 'center',
        },
        {
            title: '老客',
            key: 'old',
            dataIndex: 'old',
            align: 'center',
        },
        {
            title: '同比',
            key: 'onYear',
            dataIndex: 'onYear',
            align: 'center',
        },
        {
            title: '环比',
            key: 'Chain',
            dataIndex: 'Chain',
            align: 'center',
        }
    ]

    // 消费等级
    const consumptionLevelColumns = [
        {
            title: '消费等级分层',
            key: 'id',
            dataIndex: 'id',
            align: 'center',
            width: 140
        },
        {
            title: '人数',
            key: '人数',
            dataIndex: '人数',
            align: 'center',
        },
        {
            title: '人数占比',
            key: '人数占比',
            dataIndex: '人数占比',
            align: 'center',
        },
        {
            title: '金额',
            key: '金额',
            dataIndex: '金额',
            align: 'center',
        },
        {
            title: '金额占比',
            key: '金额占比',
            dataIndex: '金额占比',
            align: 'center',
        },
        {
            title: '件数',
            key: '件数',
            dataIndex: '件数',
            align: 'center',
        },
        {
            title: '人均消费件数',
            key: '人均消费件数',
            dataIndex: '人均消费件数',
            align: 'center',
        },
        {
            title: '期间消费频次',
            key: '期间消费频次',
            dataIndex: '期间消费频次',
            align: 'center',
        },
        {
            title: '单次购买金额',
            key: '单次购买金额',
            dataIndex: '单次购买金额',
            align: 'center',
        },
        {
            title: '单次购买件数',
            key: '单次购买件数',
            dataIndex: '单次购买件数',
            align: 'center',
        },

    ]

    // 新客TOP
    const newTopColumns = [
        {
            title: '款号',
            key: '款号',
            dataIndex: '款号',
            align: 'center',
        },
        {
            title: '销售金额',
            key: '销售金额',
            dataIndex: '销售金额',
            align: 'center',
        },
        {
            title: '销售件数',
            key: '销售件数',
            dataIndex: '销售件数',
            align: 'center',
        },
        {
            title: '件单价',
            key: '件单价',
            dataIndex: '件单价',
            align: 'center',
        },
        {
            title: '平均折扣',
            key: '平均折扣',
            dataIndex: '平均折扣',
            align: 'center',
        },
    ]

    // 老客TOP
    const oldTopColumns = [
        {
            title: '款号',
            key: '款号',
            dataIndex: '款号',
            align: 'center',
        },
        {
            title: '销售金额',
            key: '销售金额',
            dataIndex: '销售金额',
            align: 'center',
        },
        {
            title: '销售件数',
            key: '销售件数',
            dataIndex: '销售件数',
            align: 'center',
        },
        {
            title: '件单价',
            key: '件单价',
            dataIndex: '件单价',
            align: 'center',
        },
        {
            title: '平均折扣',
            key: '平均折扣',
            dataIndex: '平均折扣',
            align: 'center',
        },
    ]

    // 地域
    const areaColumns = [
        {
            title: '省份',
            key: '省份',
            dataIndex: '省份',
            align: 'center',
            width: 140
        },
        {
            title: '人数',
            key: '人数',
            dataIndex: '人数',
            align: 'center',
        },
        {
            title: '消费金额',
            key: '消费金额',
            dataIndex: '消费金额',
            align: 'center',
        },
        {
            title: '购买频次',
            key: '购买频次',
            dataIndex: '购买频次',
            align: 'center',
        },
        {
            title: '人均消费',
            key: '人均消费',
            dataIndex: '人均消费',
            align: 'center',
        },
        {
            title: '平均件单',
            key: '平均件单',
            dataIndex: '平均件单',
            align: 'center',
        },
    ]
    // 品类
    const categoryColumns = [
        {
            title: '品类',
            key: '品类',
            dataIndex: '品类',
            align: 'center',
            width: 140
        },
        {
            title: '消费件数',
            key: '消费件数',
            dataIndex: '消费件数',
            align: 'center',
        },
        {
            title: '消费金额',
            key: '消费金额',
            dataIndex: '消费金额',
            align: 'center',
        },
        {
            title: '金额占比',
            key: '金额占比',
            dataIndex: '金额占比',
            align: 'center',
        },
        {
            title: '平均单价',
            key: '平均单价',
            dataIndex: '平均单价',
            align: 'center',
        },
        {
            title: '平均折扣',
            key: '平均折扣',
            dataIndex: '平均折扣',
            align: 'center',
        },
    ]
    return (
        <div>
            <Spin spinning={downloadConsumptionFileLoading}>
                <div style={{ paddingBottom: 20 }}>
                    {/* 会员消费数据分析 */}
                    <div style={{
                        display: 'flex',
                        justifyContent: "space-around",
                        backgroundColor: "#fff",
                        borderBottom: "solid 2px #ccc",
                        marginBottom: 10,
                        position: 'fixed',
                        top: 40,
                        zIndex: 99,
                        width:'100vw'
                    }}>
                        <Title
                            level={2}>{props.location.pathname.split('/')[2]}会员消费数据分析</Title>
                        <div style={{ display: 'flex' }}>
                            <Radio.Group style={{ marginTop: 10, marginRight: 5 }} value={dimension} onChange={(e) => setDimension(e.target.value)}>
                                <Radio value={1}>实销</Radio>
                                <Radio value={2}>支付</Radio>
                            </Radio.Group>
                            <RangePicker style={{ height: "28px", marginTop: 10 }}
                                locale={locale}
                                value={[moment(startTime, dateFormat), moment(endTime, dateFormat)]}
                                onChange={onTimeChange} />
                            <Button style={{height: "28px", marginTop:10, marginLeft:10}} type="primary" onClick={()=>{
                                setIsTimeChange(false)
                                setNewOldData([])
                                setNewTopData([])
                                setOldTopData([])
                                setAreaData([])
                                setCategoryData([])
                                setConsumptionLevelData([])
                                getAllData()
                            }} disabled={isTimeChange ? false : true}>查询</Button>
                        </div>
                    </div>
                    <div style={{ marginLeft: 20, marginRight: 20, marginTop:70 }}>
                        <Spin tip={`数据请求中,预计还需要${newOldCount}秒...`} spinning={newOldLoading}>
                            <Table dataSource={newOldData} columns={newOldColumns} pagination={false} />
                        </Spin>
                        {/* loading={memberLoading}  */}
                        <Spin tip={`数据请求中,预计还需要${memberCount}秒...`} spinning={memberLoading}>
                            <Table dataSource={consumptionLevelData} columns={consumptionLevelColumns} pagination={false} style={{ marginTop: 10 }} />
                        </Spin>
                        {/* <Table dataSource={consumptionLevelData} columns={consumptionLevelColumns} pagination={false} loading={<Spin tip="数据请求中..." spinning={memberLoading}></Spin>} style={{ marginTop: 10 }} /> */}
                        <div style={{ display: "flex", marginTop: 5 }}>
                            <div style={{ width: "49vw", marginBottom: 10 }}>
                                <Title level={4}>新客TOP款</Title>
                                <Spin tip={`数据请求中,预计还需要${topCount}秒...`} spinning={newTopLoading}>
                                    <Table dataSource={newTopData} columns={newTopColumns} pagination={false} />
                                </Spin>
                            </div>
                            <div style={{ width: "49vw", marginLeft: "2vw", marginBottom: 10 }}>
                                <Title level={4}>老客TOP款</Title>
                                <Spin tip={`数据请求中,预计还需要${topCount}秒...`} spinning={newTopLoading}>
                                    <Table dataSource={oldTopData} columns={oldTopColumns} loading={oldTopLoading} pagination={false} />
                                </Spin>
                            </div>
                        </div>
                        {/* 选择会员数 */}
                        <div style={{ display: 'flex', marginTop: 5, justifyContent: 'space-between' }}>
                            <div>
                                <b style={{ marginRight: 10, marginTop: 5 }}>选择会员规模:</b>
                                <Select width={200} value={membershipLevel} onChange={(value) => {
                                    setMembershipLevel(value)
                                }}>
                                    <Option value={1}>10%的会员</Option>
                                    <Option value={2}>30%的会员</Option>
                                    <Option value={3}>50%的会员</Option>
                                    <Option value={4}>100%会员</Option>
                                </Select>
                            </div>
                            <Button type="dashed" onClick={() => downloadConsumptionFile()}>全量数据导出</Button>
                        </div>
                        <div style={{ display: "flex", marginTop: 5 }}>
                            <div style={{ width: "49vw" }}>
                                <Spin tip={`数据请求中,预计还需要${regionAnalysisCount}秒...`} spinning={regionAnalysisLoading}>
                                    <Table dataSource={areaData} columns={areaColumns} pagination={false} />
                                </Spin>
                            </div>
                            <div style={{ width: "49vw", marginLeft: "2vw" }}>
                                 <Spin tip={`数据请求中,预计还需要${preferenceCategoryCount}秒...`} spinning={preferenceCategoryLoading}>
                                    <Table dataSource={categoryData} columns={categoryColumns} pagination={{ pageSize: 10 }} />
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )

}

export default Index