import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment'
import { Form, Row, message, Button, Col, DatePicker, Input, Select, Upload, Progress, Drawer, Descriptions, Tag, Table, Menu, Dropdown, Modal, Tooltip, Spin, Tabs, List, Avatar, Space } from 'antd'
import { UploadOutlined, ClockCircleOutlined, MinusOutlined, PlusOutlined, createFromIconfontCN, DownOutlined, FieldTimeOutlined, MessageOutlined, LikeOutlined, StarOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import BraftEditor from 'braft-editor'
import Base64 from 'base-64'
import '../../static/css/index.css'
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search, TextArea } = Input;
const { TabPane } = Tabs
const dateFormat = 'YYYY.MM.DD';
function Task(props) {

    const IconText = ({ icon, text }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    // 样式
    const getItemStyle = (isDragging, draggableStyle, ind, item) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: 8 * 2,
        margin: `0 0 8px 0`,
        // borderLeft:'5px solid red',
        borderLeftColor: colorList[ind],
        borderLeftStyle: 'solid',
        // change background colour if dragging
        // background: isDragging ? "lightgreen" : "white",
        background: "white",
        display: item.look == false ? 'none' : '',
        // styles we need to apply on draggables
        ...draggableStyle
    });
    // 单个的样式
    const getListStyle = isDraggingOver => ({
        // background: isDraggingOver ? "lightblue" : "lightgrey",
        // background: isDraggingOver ? "lightblue" : "white",
        background: 'white',
        padding: 8,
        width: 300
    });

    const MyIcon = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_770237_8mapj81d1vx.js', // 在 iconfont.cn 上生成
    });

    const iconList = [
        <MyIcon type="icon-daibande" />,
        <MyIcon type="icon-jinhangzhong" />,
        <MyIcon type="icon-zanhuan" />,
        <MyIcon type="icon-myiwancheng" />,
        <MyIcon type="icon-yijujue" />
    ]

    const menu = (
        <Menu onClick={(e) => {
            console.log(Base64.encode(encodeURIComponent(creator)));
            console.log(Base64.encode(encodeURIComponent(principalName)));
            if (creator == cookie.load('name') || principalName == cookie.load('name')) {
                const formData = new FormData()
                // 任务负责人
                formData.append('director', principalName)
                // 任务编码
                formData.append('id', taskId)
                // 任务状态
                formData.append('state', e.key)
                // 操作人
                formData.append('principal', cookie.load('name'))
                // 店铺ID
                formData.append('shopId', cookie.load('shopList'))
                // 角色ID
                formData.append('roleId', cookie.load('role'))
                axios({
                    method: 'POST',
                    url: servicePath.updateProjectManagementStateTask,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("任务更新成功")
                            setTaskId('')
                            setPrincipalName('')
                            setPage(1)
                            // 获取任务
                            restart()
                        } else {
                            if (res.data['errorCode'] == '10001') {
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            } else {
                                message.error(res.data['errorMsg'])
                            }
                        }
                    }
                )
            } else {
                message.warn("您不是任务负责人或者负责人，不能修改任务状态")
            }

        }}>
            <Menu.Item key="1">
                {iconList[0]}待办中
          </Menu.Item>
            <Menu.Item key="2">
                {iconList[1]}进行中
          </Menu.Item>
            <Menu.Item key="3">
                {iconList[2]}暂缓
          </Menu.Item>
            <Menu.Item key="4">
                {iconList[3]}已完成
          </Menu.Item>
            <Menu.Item key="5">
                {iconList[4]}已拒绝
          </Menu.Item>
        </Menu>
    );

    const [form] = Form.useForm();

    const [form1] = Form.useForm()

    const [form2] = Form.useForm()

    const [form3] = Form.useForm()

    const [form4] = Form.useForm()

    const [form5] = Form.useForm()

    const [form6] = Form.useForm()

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 20 },
    }

    const layout2 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    }

    // 优先级集合
    const priorityList = [
        '严重',
        '主要',
        '次要',
        '不重要'
    ]

    // 富文本编辑器控件
    const extendControls = ['bold', 'italic', 'underline', 'text-color', 'separator', 'link',]

    // 状态list
    const statusList = ['待办的', '进行中', '暂缓', '已完成', '已拒绝']

    // 状态颜色
    const colorList = ['orange', 'blue', 'purple', 'gray', 'red']

    // 文件名
    const [fileList, setFileList] = useState([])

    // 评论list
    const [commentList, setCommentList] = useState(
        [
            {
                name: '胡思彬',
                title: 'Ant Design Title 1',
                time: '2020-12-15 13:00:00'
            },
            {
                name: '唐铎',
                title: 'Ant Design Title 2',
                time: '2020-12-15 14:00:00'
            },
            {
                name: '章梓安',
                title: 'Ant Design Title 3',
                time: '2020-12-15 16:00:00'
            },
            {
                name: '胡毅超',
                title: 'Ant Design Title 4',
                time: '2020-12-15 17:00:00'
            },
        ]
    )

    // 设置编辑器初始内容
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''))

    // 模式
    const [pattern, setPattern] = useState('list')

    // 数据
    const [date, setDate] = useState([])

    // 页数
    const [page, setPage] = useState(1)

    //  总数
    const [total, setTotal] = useState('')

    // 任务状态
    const [taskState, setTaskState] = useState('')

    // 数据
    const [state, setState] = useState([])

    // 进度
    const [percent, setPercent] = useState(30)

    // 文档文件集合
    const [docList, setDocList] = useState([])

    // 任务id
    const [taskId, setTaskId] = useState('')

    // 查询任务id
    const [searchTaskId, setSearchTaskId] = useState('')

    // 任务
    const [task, setTask] = useState({})

    // 父任务
    const [parTask, setParTask] = useState('')

    // 父任务名字
    const [parTaskName, setParTaskName] = useState('')

    // 人员
    const [peopleList, setPeopleList] = useState([])

    // 任务名字
    const [name, setName] = useState('')

    // 子任务名
    const [subtaskName, setSubTaskName] = useState('')

    // 查询任务名字
    const [searchName, setSearchName] = useState('')

    // 天数
    const [day, setDay] = useState('')

    // 修改天数
    const [updateDay, setUpdateDay] = useState('')

    // 小时
    const [hour, setHour] = useState('')

    // 修改小时
    const [updateHour, setUpdateHour] = useState('')

    // 项目
    const [project, setProject] = useState('0')

    // 项目Id
    const [projectId, setProjectId] = useState('0')

    // 评论
    const [comment, setComment] = useState('')

    // 查询项目
    const [searchProject, setSearchProject] = useState('0')

    // 项目名字
    const [projectName, setProjectName] = useState('')

    // 项目集
    const [projectList, setProjectList] = useState([])

    // 团队
    const [team, setTeam] = useState('')

    // 查询团队
    const [searchTeam, setSearchTeam] = useState('')

    // 团队名字
    const [teamName, setTeamName] = useState('')

    // 团队Id
    const [teamId, setTeamId] = useState('')

    // 已有团队名字
    const [teamNames, setTeamNames] = useState('')

    // 团队集
    const [teamList, setTeamList] = useState([])

    // 搜索团队集
    const [searchTeamList, setSearchTeamList] = useState([])

    // 负责人
    const [principal, setPrincipal] = useState('')

    // 负责人名字
    const [principalName, setPrincipalName] = useState('')

    // 子任务负责人
    const [subtaskPrincipal, setSubtaskPrincipal] = useState('')

    // 查询负责人
    const [searchPrincipal, setSearchPrincipal] = useState('')

    // 协助者
    const [coadjutant, setCoadjutant] = useState([])

    // 协助者名字
    const [coadjutantName, setCoadjutantName] = useState([])

    // 是否修改了协组者 
    const [isUpdateCoadjutant, setIsUpdateCoadjutant] = useState(false)

    // 创建人
    const [creator, setCreator] = useState('')

    // 查询创建人
    const [searchCreator, setSearchCreator] = useState('')

    // 父任务集
    const [principalTaskList, setPrincipalTaskList] = useState([])

    // 任务开始时间
    const [startTime, setStartTime] = useState('')

    // 任务结束时间
    const [endTime, setEndTime] = useState('')

    // 更新任务开始时间
    const [updateStartTime, setUpdateStartTime] = useState('')

    // 更新任务结束时间
    const [updateEndTime, setUpdateEndTime] = useState('')

    // 查询任务开始时间
    const [searchStartTime, setSearchStartTime] = useState('')

    //  查询任务结束时间
    const [searchEndTime, setSearchEndTime] = useState('')

    //  查询创建开始时间
    const [searchCreateStartTime, setSearchCreateStartTime] = useState('')

    //  查询创建结束时间
    const [searchCreateEndTime, setSearchCreateEndTime] = useState('')

    // 优先级
    const [priority, setPriority] = useState('')

    // 打开查询条件
    const [openSearch, setOpenSearch] = useState(false)

    // 新增任务
    const [createTask, setCreateTask] = useState(false)

    // 修改任务
    const [updateTask, setUpdateTask] = useState(false)

    // 是否在加载
    const [isLoading, setIsLoading] = useState(false)

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 添加子任务
    const [addSubtask, setAddSubtask] = useState(false)

    // 延长任务时间
    const [overtime, setOvertime] = useState(false)

    // 添加评论
    const [addComment, setAddComment] = useState(false)

    // 修改评论
    const [updateComment, setUpdateComment] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 表格标题
    const columns = [
        {
            title: '状态',
            key: 'state',
            dataIndex: 'state',
            width: '50px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => {
                return <Dropdown overlay={menu} onClick={() => {
                    setCreator(date[index]['founder'])
                    setPrincipalName(date[index]['director'])
                    setTaskId(date[index]['id'])
                }}>
                    <a className="ant-dropdown-link">
                        {iconList[text - 1]} <DownOutlined />
                    </a>
                </Dropdown>
            }
        },
        {
            title: '任务编号',
            key: 'id',
            dataIndex: 'id',
            width: '100px',
            align: 'center',
            fixed: 'left',
            // render: (text, record, index) => (
            //     <CopyToClipboard text={`https://nb20.bmcsoft.cn/app/myWorkbench?task_id=` + text} onCopy={() => message.success('复制成功')}>
            //         <a style={{ textDecoration: 'underline' }}>{text}</a>
            //     </CopyToClipboard>
            // )
        },
        {
            title: '任务名',
            key: 'name',
            dataIndex: 'name',
            width: '100px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => {
                return <a onClick={() => {
                    form1.resetFields()
                    form.resetFields()
                    form2.resetFields()
                    form3.resetFields()
                    form4.resetFields()
                    setTask(date[index]);
                    getTaskComment(date[index]['id'])
                    setParTaskName(date[index]['pid_name'])
                    setCreator(date[index]['founder'])
                    setTaskState(date[index]['state'])
                    setTaskId(date[index]['id'])
                    setName(date[index]['name'])
                    setUpdateStartTime(date[index]['start_time'])
                    setUpdateEndTime(date[index]['end_time'])
                    setUpdateDay(date[index]['day'])
                    setUpdateHour(date[index]['hour'])
                    setStartTime(date[index]['start_time'])
                    setDay(date[index]['day'])
                    setHour(date[index]['hour'])
                    setProjectId(date[index]['project_id'])
                    setTeamId(date[index]['team_id'])
                    setTeamNames(date[index]['team'])
                    setPrincipalName(date[index]['director'])
                    setCoadjutantName(date[index]['collaborators'])
                    setPriority(date[index]['priority'])
                    setParTask(date[index]['pid'])
                    setFileList(date[index]['file_name'])
                    setPercent(parseInt(date[index]['progress']))
                    setEditorState(BraftEditor.createEditorState(date[index]['introduction']));
                    getUpdatePeopleList(date[index]['project_id'], date[index]['team_id'])
                }} style={{ textDecoration: 'underline' }}>{text}</a>
            }
        },
        {
            title: '时间安排',
            key: 'time',
            dataIndex: 'time',
            width: '200px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    date[index]['start_time'] != null
                        ? <div>{date[index]['start_time'].substring(0, 10)} - {date[index]['end_time'].substring(0, 10)}</div>
                        : <div></div>
                )
            }
        },
        {
            title: '所属项目',
            key: 'project',
            dataIndex: 'project',
            width: '100px',
            align: 'center',
        },
        {
            title: '所属团队',
            key: 'team',
            dataIndex: 'team',
            width: '100px',
            align: 'center',
        },
        {
            title: '创建时间',
            key: 'create_time',
            dataIndex: 'create_time',
            width: '100px',
            align: 'center',
        },
        {
            title: '负责人',
            key: 'director',
            dataIndex: 'director',
            width: '100px',
            align: 'center',
        },
        {
            title: '创建人',
            key: 'founder',
            dataIndex: 'founder',
            width: '100px',
            align: 'center',
        },
        {
            title: '申请延迟任务时间',
            key: 'operation',
            dataIndex: 'operation',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return <div style={{ display: date[index]['founder'] == cookie.load('name') || date[index]['director'] == cookie.load('name') ? '' : 'none' }}>
                    <FieldTimeOutlined
                        style={{ display: date[index]['start_time'] != null ? '' : 'none' }}
                        onClick={() => {
                            form1.resetFields()
                            setCreator(date[index]['founder'])
                            setPrincipalName(date[index]['director'])
                            setTaskId(date[index]['id'])
                            setStartTime(date[index]['start_time'])
                            setEndTime(date[index]['end_time'])
                            setDay(date[index]['day'])
                            setHour(date[index]['hour'])
                            setUpdateStartTime(date[index]['start_time'])
                            setUpdateEndTime(date[index]['end_time'])
                            setUpdateDay(date[index]['day'])
                            setUpdateHour(date[index]['hour'])
                            setOvertime(true)
                        }}
                    />
                </div>

            }
        }
    ]

    useEffect(() => {
        getTaskFounderProjectList()
        getPrincipalTaskList()
    }, [])

    useEffect(() => {
        getTask()
    }, [page])

    // 模块切换的时候调用
    useEffect(() => {
        if (pattern == 'list') {
            getTask()
        } else {
            getStateTask()
        }
    }, [pattern])

    // 项目修改的时候修改团队
    useEffect(() => {
        getTaskFounderTeamList()
    }, [project])

    useEffect(() => {
        updateTaskFounderTeamList()
    }, [projectId])

    // // 数据修改时候重新渲染数据
    // useEffect(() => {
    //     setState([commissionList, conductList, deferList, completedList, declineList])
    // }, [commissionList])

    // 负责人修改的时候
    useEffect(() => {
        // 协作者置空
        form2.resetFields()
        setCoadjutant([])
        setCoadjutantName([])
    }, [principal])

    // 项目修改的时候
    useEffect(() => {
        // 协作者置空
        form2.resetFields()
        setCoadjutant([])
        setCoadjutantName([])
        // 负责人置空
        form3.resetFields()
        setPrincipal('')
        setPrincipalName('')
        // 团队置空
        form4.resetFields()
        setTeam('')
        setTeamId('0')
        setTeamNames('')
        getCollaboratorsList()
    }, [project])

    // 团队修改的时候
    useEffect(() => {
        // 协作者置空
        form2.resetFields()
        setCoadjutant([])
        setCoadjutantName([])
        // 负责人置空
        form3.resetFields()
        setPrincipal('')
        setPrincipalName('')
        getCollaboratorsList()
    }, [team])

    // 搜索的时候项目修改的时候
    useEffect(() => {
        // 团队置空
        form6.resetFields()
        setSearchTeam('')
        getSearchCollaboratorsList()
        getSearchTaskFounderTeamList()
    }, [searchProject])

    // 搜索的时候团队修改的时候
    useEffect(() => {
        getSearchCollaboratorsList()
    }, [searchTeam])


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    // 移动函数
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        // 判断是不是leader
        if (removed['founder'] == cookie.load('name') || removed['director'] == cookie.load('name')) {
            destClone.splice(droppableDestination.index, 0, removed);
            const result = {};
            result[droppableSource.droppableId] = sourceClone;
            result[droppableDestination.droppableId] = destClone;
            // 每次移动都要请求一次更新接口，然后请求一次所有数据的接口
            const formData = new FormData()
            // 任务负责人
            formData.append('director', removed.director)
            // 任务编码
            formData.append('id', removed.id)
            // 任务状态
            formData.append('state', parseInt(droppableDestination.droppableId) + 1)
            // 操作人
            formData.append('principal', cookie.load('name'))
            // 店铺ID
            formData.append('shopId', cookie.load('shopList'))
            // 角色ID
            formData.append('roleId', cookie.load('role'))
            axios({
                method: 'POST',
                url: servicePath.updateProjectManagementStateTask,
                data: formData,
                headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success("任务更新成功")
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
            return result;
        }
        return 0
    };

    // 拖动函数
    function onDragEnd(result) {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;
        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            const newState = [...state];
            newState[sInd] = items;
            setState(newState);
        } else {
            const result = move(state[sInd], state[dInd], source, destination);
            if (result != 0) {
                const newState = [...state];
                newState[sInd] = result[sInd];
                newState[dInd] = result[dInd];
                setState(newState.filter(group => group.length));
            } else {
                message.warn("您不是任务负责人或者负责人，不能修改任务状态")
            }
        }
    }

    // 获取任务评论
    const getTaskComment = (id) => {
        let dataProps = {
            "task_id": id,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryTaskReview,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setCommentList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取当前人所在项目
    const getTaskFounderProjectList = () => {
        let dataProps = {
            "founder": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryTaskFounderProjectList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setProjectList(res.data.data)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )

    }
    // 获取当前人所在团队列表
    const getTaskFounderTeamList = () => {
        let dataProps = {
            "project_id": project,
            "founder": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryTaskFounderTeamList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTeamList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )

    }

    // 获取当前人所在项目的团队列表
    const getSearchTaskFounderTeamList = () => {
        let dataProps = {
            "project_id": searchProject,
            "founder": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryTaskFounderTeamList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setSearchTeamList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )

    }

    // 更新当前人所在团队列表
    const updateTaskFounderTeamList = () => {
        let dataProps = {
            "project_id": projectId,
            "founder": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryTaskFounderTeamList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTeamList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )

    }

    // 查询父任务列表接口
    const getPrincipalTaskList = () => {
        let dataProps = {
            "principal": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryPrincipalTaskList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setPrincipalTaskList(res.data.data)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 查询任务协作人列表接口
    const getCollaboratorsList = () => {
        let dataProps = {
            "team_id": team,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        // 用于更新的时候，项目是否改变的时候处理
        if (project != '0') {
            dataProps['project_id'] = project
        } else {
            dataProps['project_id'] = projectId
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryCollaboratorsList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setPeopleList(res.data.data)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 搜索的时候查询任务协作人列表接口
    const getSearchCollaboratorsList = () => {
        let dataProps = {
            "project_id": searchProject,
            "team_id": searchTeam,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryCollaboratorsList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setPeopleList(res.data.data)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取修改时候的人员列表
    const getUpdatePeopleList = (projectId, teamId) => {
        setIsLoading(true)
        let dataProps = {
            "project_id": projectId,
            "team_id": teamId,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryCollaboratorsList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setIsLoading(false)
                if (res.data.success) {
                    setPeopleList(res.data.data)
                    setUpdateTask(true)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 关闭查询弹框
    const onSearchClose = () => {
        setOpenSearch(false)
    }

    // 关闭新增任务
    const onCreateTask = () => {
        setCoadjutant([])
        setFileList([])
        setDocList([])
        setParTaskName('')
        setTaskId('')
        setParTask('')
        setName('')
        setProjectId('0')
        setTeamId('')
        setTeamNames('')
        setTask({})
        setProjectName('')
        setProject('0')
        setTeamName('')
        setTeam('')
        setPrincipal('')
        setUpdateStartTime('')
        setUpdateEndTime('')
        setPriority('')
        setUpdateDay('')
        setUpdateHour('')
        setDay('')
        setHour('')
        setTaskState('')
        setPercent('')
        setEditorState(BraftEditor.createEditorState(''))
        form1.resetFields()
        form2.resetFields()
        form3.resetFields()
        form4.resetFields()
        setCreateTask(false)
    }

    // 关闭修改任务
    const onUpdateTask = () => {
        setCoadjutant([])
        setIsUpdateCoadjutant(false)
        setFileList([])
        setDocList([])
        setTaskId('')
        setParTask('')
        setName('')
        setParTaskName('')
        setProjectId('0')
        setTeamId('')
        setTeamNames('')
        setTask({})
        setProjectName('')
        setProject('0')
        setTeamName('')
        setTeam('')
        setPrincipal('')
        setUpdateStartTime('')
        setUpdateEndTime('')
        setPriority('')
        setUpdateDay('')
        setUpdateHour('')
        setDay('')
        setHour('')
        setTaskState('')
        setPercent('')
        setEditorState(BraftEditor.createEditorState(''))
        form1.resetFields()
        form2.resetFields()
        form3.resetFields()
        form4.resetFields()
        setUpdateTask(false)
    }

    // 编辑内容触发
    const editorChange = (editorState) => {
        setEditorState(editorState)
    }

    // 文档列表改变的时候出发
    const fileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'zip', 'rar', 'gif', 'jpg', 'jpeg', 'png', 'jpg', 'pptx', 'txt']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    setDocList(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                }
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setDocList(fileList)
        }
    }

    // 增加进度
    const increase = () => {
        let percents = percent + 10;
        if (percents > 100) {
            percents = 100;
        }
        setPercent(percents)
    };

    // 减少进度
    const decline = () => {
        let percents = percent - 10;
        if (percents < parseInt(task['progress'])) {
            percents = parseInt(task['progress'])
        }
        setPercent(percents)
    };

    //确认添加子任务
    const addSubtaskOk = () => {

        setEffective(1)

        // 上传文件
        const formData = new FormData()
        // 多个文件
        docList.forEach((item) => {
            formData.append('file_list', item['originFileObj'])
        })
        // 父任务编码
        formData.append('pid', taskId)
        // 父任务名字
        formData.append('pid_name', parTaskName)
        // 任务名字
        formData.append('name', subtaskName)
        // 任务负责人
        formData.append('director', subtaskPrincipal)
        // 任务创建人
        formData.append('founder', cookie.load('name'))
        // 店铺ID
        formData.append('shopId', cookie.load('shopList'))
        // 角色ID
        formData.append('roleId', cookie.load('role'))
        // 项目名字
        formData.append('project', task['project'])
        // 项目id
        formData.append('project_id', task['project_id'])
        //  团队名字
        formData.append('team', task['team'])
        //  团队id
        formData.append('team_id', task['team_id'])
        // 优先级
        formData.append('priority', 2)
        axios({
            method: 'POST',
            url: servicePath.createProjectManagementTask,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data['success']) {
                    message.success("子任务创建成功")
                    setCoadjutant([])
                    setFileList([])
                    setDocList([])
                    setParTaskName('')
                    setTaskId('')
                    setParTask('')
                    setName('')
                    setProjectId('0')
                    setTeamId('')
                    setTeamNames('')
                    setTask({})
                    setProjectName('')
                    setProject('0')
                    setTeamName('')
                    setTeam('')
                    setPrincipal('')
                    setUpdateStartTime('')
                    setUpdateEndTime('')
                    setPriority('')
                    setUpdateDay('')
                    setUpdateHour('')
                    setTaskState('')
                    setPercent('')
                    setEditorState(BraftEditor.createEditorState(''))
                    form1.resetFields()
                    form2.resetFields()
                    form3.resetFields()
                    form4.resetFields()
                    setPage(1)
                    // 获取任务
                    restart()
                    setAddSubtask(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 取消添加子任务
    const addSubtaskCancel = () => {
        message.success('取消创建子任务')
        setAddSubtask(false)
    }

    // 新建任务
    const createNewTask = () => {
        if (principal == '') {
            message.warn('负责人不能为空')
        } else if (!(/(^[0-9]\d*$)/.test(day))) {
            message.warn('请正确填写天数')
        } else {
            setEffective(1)

            // 上传文件
            const formData = new FormData()
            // 多个文件
            docList.forEach((item) => {
                formData.append('file_list', item['originFileObj'])
            })
            // 父任务编码
            formData.append('pid', parTask)
            // 父任务名字
            formData.append('pid_name', parTaskName)
            // 任务名字
            formData.append('name', name)
            // 项目名字
            formData.append('project', projectName)
            // 项目id
            formData.append('project_id', project)
            //  团队名字
            formData.append('team', teamName)
            //  团队id
            formData.append('team_id', team)
            // 任务负责人
            formData.append('director', principal)
            // 任务协作人
            formData.append('collaborators', coadjutant)
            // 任务开始时间
            formData.append('start_time', startTime)
            // 任务结束时间
            formData.append('end_time', endTime)
            // 工期天
            formData.append('day', day)
            // 工期时
            formData.append('hour', hour)
            // 优先级
            formData.append('priority', priority)
            // 任务类型
            formData.append('type', '')
            // 任务描述
            formData.append('introduction', editorState.toHTML())
            // 任务创建人
            formData.append('founder', cookie.load('name'))
            // 店铺ID
            formData.append('shopId', cookie.load('shopList'))
            // 角色ID
            formData.append('roleId', cookie.load('role'))

            axios({
                method: 'POST',
                url: servicePath.createProjectManagementTask,
                data: formData,
                headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
            }).then(
                res => {
                    setEffective(0)
                    if (res.data['success']) {
                        message.success("任务创建成功")
                        setCoadjutant([])
                        setDocList([])
                        setParTask('')
                        setParTaskName('')
                        setName('')
                        setProjectName('')
                        setProject('0')
                        setTeamName('')
                        setTeam('')
                        setPrincipal('')
                        setStartTime('')
                        setEndTime('')
                        setPriority('')
                        setDay('')
                        setHour('')
                        setEditorState(BraftEditor.createEditorState(''))
                        form1.resetFields()
                        form2.resetFields()
                        form3.resetFields()
                        form4.resetFields()
                        setPage(1)
                        // 获取任务
                        restart()
                        setCreateTask(false)
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }

    }

    // 获取任务
    const getTask = () => {
        setIsLoading(true)
        let dataProps = {
            "id": searchTaskId,
            "name": searchName,
            "create_start_time": searchCreateStartTime,
            "create_end_time": searchCreateEndTime,
            "task_start_time": searchStartTime,
            "task_end_time": searchEndTime,
            "director": searchPrincipal,
            "project_id": searchProject,
            "team_id": searchTeam,
            "principal": cookie.load('name'),
            "page": page,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryProjectManagementTask,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setIsLoading(false)
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data);
                    setOpenSearch(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取任务状态看板
    const getStateTask = () => {
        setIsLoading(true)
        let dataProps = {
            "id": searchTaskId,
            "name": searchName,
            "create_start_time": searchCreateStartTime,
            "create_end_time": searchCreateEndTime,
            "task_start_time": searchStartTime,
            "task_end_time": searchEndTime,
            "director": searchPrincipal,
            "project_id": searchProject,
            "team_id": searchTeam,
            "principal": cookie.load('name'),
            "page": page,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryProjectManagementStateTask,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setIsLoading(false)
                if (res.data.success) {
                    setState([res.data.data[1], res.data.data[2], res.data.data[3], res.data.data[4], res.data.data[5]])
                    setOpenSearch(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 重置查询任务
    const restart = () => {
        form5.resetFields()
        form6.resetFields()
        setSearchTaskId('')
        setSearchName('')
        setSearchCreateStartTime('')
        setSearchCreateEndTime('')
        setSearchStartTime('')
        setSearchEndTime('')
        setSearchPrincipal('')
        setSearchProject('')
        setSearchTeam('')
        setIsLoading(true)
        let dataProps = {
            "id": '',
            "name": '',
            "create_start_time": '',
            "create_end_time": '',
            "task_start_time": '',
            "task_end_time": '',
            "director": '',
            "project_id": '',
            "team_id": '',
            "principal": cookie.load('name'),
            "page": 1,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryProjectManagementTask,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setIsLoading(false)
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data);
                    setOpenSearch(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 修改任务
    const okUpdateTask = () => {
        if (principalName == '' && principal == '') {
            message.warn('负责人不能为空')
        } else if (!(/(^[0-9]\d*$)/.test(updateDay))) {
            message.warn('请正确填写天数')
        } else {
            setEffective(1)
            // 上传文件
            const formData = new FormData()
            // 多个文件
            docList.forEach((item) => {
                formData.append('file_list', item['originFileObj'])
            })
            if (project == '0') {
                // 项目名字
                formData.append('project', task['project'])
                // 项目id
                formData.append('project_id', task['project_id'])
            } else {
                // 项目名字
                formData.append('project', projectName)
                // 项目id
                formData.append('project_id', project)
            }
            if (team == '') {
                //  团队名字
                formData.append('team', teamNames)
                //  团队id
                formData.append('team_id', teamId)
            } else {
                //  团队名字
                formData.append('team', teamName)
                //  团队id
                formData.append('team_id', team)
            }
            if (principal == '') {
                // 任务负责人
                formData.append('director', principalName)
            } else {
                // 任务负责人
                formData.append('director', principal)
            }
            if (coadjutant.length == 0 && isUpdateCoadjutant == false) {
                // 任务协作人
                formData.append('collaborators', coadjutantName)
            } else {
                // 任务协作人
                formData.append('collaborators', coadjutant)
            }
            // 任务编码
            formData.append('id', taskId)
            // 父任务编码
            formData.append('pid', parTask)
            // 父任务名字
            formData.append('pid_name', parTaskName)
            // 任务名字
            formData.append('name', name)
            // 任务开始时间
            formData.append('start_time', updateStartTime)
            // 任务结束时间
            formData.append('end_time', updateEndTime)
            // 工期天
            formData.append('day', updateDay)
            // 工期时
            formData.append('hour', updateHour)
            // 优先级
            formData.append('priority', priority)
            // 任务类型
            formData.append('type', '')
            // 任务描述
            formData.append('introduction', editorState.toHTML())
            // 操作人
            formData.append('principal', cookie.load('name'))
            // 店铺ID
            formData.append('shopId', cookie.load('shopList'))
            // 角色ID
            formData.append('roleId', cookie.load('role'))
            // 任务状态
            formData.append('state', taskState)
            // 任务进度
            formData.append('progress', percent)
            axios({
                method: 'POST',
                url: servicePath.updateProjectManagementStateTask,
                data: formData,
                headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
            }).then(
                res => {
                    setEffective(0)
                    if (res.data['success']) {
                        message.success("任务更新成功")
                        setCoadjutant([])
                        setDocList([])
                        setFileList([])
                        setTaskId('')
                        setParTask('')
                        setParTaskName('')
                        setName('')
                        setProjectId('0')
                        setTeamId('')
                        setTeamNames('')
                        setTask({})
                        setProjectName('')
                        setProject('0')
                        setTeamName('')
                        setTeam('')
                        setPrincipal('')
                        setUpdateStartTime('')
                        setUpdateEndTime('')
                        setPriority('')
                        setIsUpdateCoadjutant(false)
                        setUpdateDay('')
                        setUpdateHour('')
                        setStartTime('')
                        setEndTime('')
                        setDay('')
                        setHour('')
                        setTaskState('')
                        setPercent('')
                        setFileList([])
                        setEditorState(BraftEditor.createEditorState(''))
                        form1.resetFields()
                        form2.resetFields()
                        form3.resetFields()
                        form4.resetFields()
                        setPage(1)
                        // 获取任务
                        restart()
                        setUpdateTask(false)
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
    }

    // 确认延长任务时间
    const overtimeOk = () => {
        if (!(/(^[0-9]\d*$)/.test(updateDay))) {
            message.warn('请正确填写天数')
        } else if (parseInt(updateEndTime) < parseInt(endTime.substring(0, 10))) {
            message.warn('请选择合理的任务结束时间')
        } else if (parseInt(updateDay) < parseInt(day)) {
            message.warn('请选择合理的预计工期')
        } else if (parseInt(updateDay) == parseInt(day) && parseInt(updateHour) <= parseInt(hour)) {
            message.warn('请选择合理的预计工期')
        } else {
            setEffective(1)
            const formData = new FormData()
            // 任务负责人
            formData.append('director', principalName)
            // 任务编码
            formData.append('id', taskId)
            // 任务开始时间
            formData.append('start_time', updateStartTime)
            // 任务结束时间
            formData.append('end_time', updateEndTime)
            // 工期天
            formData.append('day', updateDay)
            // 工期时
            formData.append('hour', updateHour)
            // 操作人
            formData.append('principal', cookie.load('name'))
            // 店铺ID
            formData.append('shopId', cookie.load('shopList'))
            // 角色ID
            formData.append('roleId', cookie.load('role'))
            axios({
                method: 'POST',
                url: servicePath.updateProjectManagementStateTask,
                data: formData,
                headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
            }).then(
                res => {
                    setEffective(0)
                    if (res.data['success']) {
                        message.success('确认延长任务时间')
                        setTaskId('')
                        setPrincipalName('')
                        setUpdateStartTime('')
                        setUpdateEndTime('')
                        setUpdateDay('')
                        setUpdateHour('')
                        setDay('')
                        setHour('')
                        setStartTime('')
                        setEndTime('')
                        setOvertime(false)
                        setPage(1)
                        // 获取任务
                        restart()
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
    }

    // 取消延长任务时间
    const overtimeCancel = () => {
        message.success('取消延长任务时间')
        setTaskId('')
        setPrincipalName('')
        setUpdateStartTime('')
        setUpdateEndTime('')
        setUpdateDay('')
        setUpdateHour('')
        setDay('')
        setHour('')
        setStartTime('')
        setEndTime('')
        setOvertime(false)
    }

    // 添加评论
    const addCommentOk = () => {
        if (comment == '') {
            message.warn('评论不能为空')
        } else {
            setEffective(1)
            let dataProps = {
                "task_id": taskId,
                "content": comment,
                "principal_uuid": cookie.load('id'),
                "roleId": cookie.load('role'),
                "shopId": cookie.load('shopList'),
            }
            axios({
                method: 'POST',
                url: servicePath.createTaskReview,
                data: dataProps,
                headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
            }).then(
                res => {
                    setEffective(0)
                    if (res.data.success) {
                        message.success('添加评论成功')
                        getTaskComment(taskId)
                        setAddComment(false)
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }

    }

    // 取消添加评论
    const addCommentCancel = () => {
        message.success('取消添加评论')
        setComment('')
        setAddComment(false)
    }


    return (
        <div style={{ paddingBottom: size.height * 0.1 }}>
            <Spin spinning={isLoading} size="large" tip="Loading......" style={{ marginTop: size.height * 0.3 }}>
                {/* 按钮区域 */}
                <div style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.03 }}>
                    <Row>
                        <Col>
                            <Button type="primary" onClick={() => { form1.resetFields(); setCreateTask(true); }} icon={<PlusOutlined />}>创建任务</Button>
                        </Col>
                        <Col style={{ marginLeft: size.width * 0.02 }}>
                            <Button onClick={() => setOpenSearch(true)}>条件查询</Button>
                        </Col>
                        <Col style={{ marginLeft: size.width * 0.02 }}>
                            <Button onClick={() => setPattern('list')}>列表模式</Button>
                        </Col>
                        <Col style={{ marginLeft: size.width * 0.02 }}>
                            <Button onClick={() => setPattern('board')}>状态看板</Button>
                        </Col>
                    </Row>
                </div>
                <div style={{ marginTop: size.height * 0.05, margin: '0 auto', width: size.width * 0.9, display: pattern == "board" ? "" : "none" }} >
                    <div>
                        <div style={{ display: "flex" }}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                {state.map((el, ind) => (
                                    <Droppable key={ind} droppableId={`${ind}`}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                                {...provided.droppableProps}
                                            >
                                                <div style={{ fontWeight: 'bold', fontSize: '15px', border: '1px solid #ccc' }}>{statusList[ind]}</div>
                                                <div style={{ height: size.height * 0.7, overflow: "scroll", backgroundColor: 'lightgrey', padding: '10px' }}>
                                                    {el.map((item, index) => (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={item.id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style,
                                                                        ind,
                                                                        item
                                                                    )}
                                                                    // onClick={() => { setTask(item); setEditorState(BraftEditor.createEditorState(item.content)); setUpdateTask(true) }}
                                                                    onClick={() => {
                                                                        form1.resetFields()
                                                                        form.resetFields()
                                                                        form2.resetFields()
                                                                        form3.resetFields()
                                                                        form4.resetFields()
                                                                        setTask(item);
                                                                        getTaskComment(item.id)
                                                                        setCreator(item.founder)
                                                                        setTaskState(item.state)
                                                                        setTaskId(item.id)
                                                                        setName(item.name)
                                                                        setStartTime(item.start_time)
                                                                        setEndTime(item.end_time)
                                                                        setDay(item.day)
                                                                        setProjectId(item.project_id)
                                                                        setTeamId(item.team_id)
                                                                        setTeamNames(item.team)
                                                                        setPrincipalName(item.director)
                                                                        setCoadjutantName(item.collaborators)
                                                                        setPriority(item.priority)
                                                                        setParTask(item.pid)
                                                                        setParTaskName(item.pid_name)
                                                                        setFileList(item.file_name)
                                                                        setPercent(parseInt(item.progress))
                                                                        setEditorState(BraftEditor.createEditorState(item.introduction));
                                                                        getUpdatePeopleList(item.project_id, item.team_id)
                                                                    }}
                                                                >

                                                                    <Tooltip
                                                                        title={`创建人:` + item.founder + `,负责人:` + item.director + `,任务名称:` + item.name}
                                                                        placement="right"
                                                                    >
                                                                        <div style={{ height: 70 }}>
                                                                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.name}</div>
                                                                            <Row>
                                                                                <Col style={{ marginRight: 10 }}><ClockCircleOutlined /></Col>
                                                                                <Col>{item.end_time == null ? "" : item.end_time.substring(0, 10)}</Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col style={{ marginRight: 40 }}><Tag color="blue">{priorityList[parseInt(item.priority) - 1]}</Tag></Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Tooltip>

                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                ))}
                            </DragDropContext>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: size.height * 0.02, display: pattern == "list" ? "" : "none" }}>
                    {/* 表单区域 */}
                    <Table
                        //表格列的配置描述
                        columns={columns}
                        //数据数组
                        dataSource={date}
                        pagination={paginationProps}
                        scroll={{ x: 1000 }}
                        //表格行 key 的取值
                        rowKey={'id'}
                    />
                </div>
                {/* 查询条件 */}
                <Drawer
                    title="查询条件"
                    placement="right"
                    closable={false}
                    onClose={onSearchClose}
                    visible={openSearch}
                    width={800}
                >
                    <Form
                        form={form5}
                        {...layout}
                        labelAlign='left'
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="orderNumber"
                                    label="编号"
                                >
                                    <Input
                                        id='orderNumber'
                                        placeholder="请输入编号"
                                        value={searchTaskId}
                                        onChange={(e) => {
                                            setSearchTaskId(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="任务名字"
                                >
                                    <Input
                                        id='name'
                                        placeholder="请输入任务名字"
                                        value={searchName}
                                        onChange={(e) => {
                                            setSearchName(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="create"
                                    label="创建时间"
                                >
                                    <RangePicker locale={locale} onChange={(value, dateString) => {
                                        setSearchCreateStartTime(dateString[0])
                                        setSearchCreateEndTime(dateString[1])
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="start"
                                    label="任务时间"
                                >
                                    <RangePicker locale={locale} onChange={(value, dateString) => {
                                        setSearchStartTime(dateString[0])
                                        setSearchEndTime(dateString[1])
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="project"
                                    label="项目"
                                >
                                    <Select defaultValue={searchProject == 0 ? '' : searchProject} placeholder="请选择项目" style={{ width: 210 }} onChange={(value) => setSearchProject(value)}>
                                        {
                                            projectList.map((item) => (
                                                <Option value={item.id}>{item.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form
                                    form={form6}
                                    labelAlign='left'
                                    {...layout}>
                                    <Form.Item
                                        name="team"
                                        label="团队"
                                    >
                                        <Select defaultValue={searchTeam} placeholder="请选择团队" style={{ width: 210 }} onChange={(value) => setSearchTeam(value)}>
                                            {
                                                searchTeamList.map((item) => (
                                                    <Option value={item.id}>{item.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="principal"
                                    label="负责人"
                                >
                                    <Input
                                        id='principal'
                                        placeholder="请输入负责人"
                                        value={searchPrincipal}
                                        onChange={(e) => {
                                            setSearchPrincipal(e.target.value)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginLeft: 110 }}>
                                <Button type="primary" onClick={() => {
                                    if (page == 1) {
                                        getTask()
                                    } else {
                                        setPage(1);
                                    }
                                }}>查询</Button>
                                <Button type="primary" danger style={{ marginLeft: 10 }} onClick={() => { setPage(1); restart() }}>重置</Button>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>

                {/* 新增任务 */}
                <Drawer
                    title="新增任务"
                    placement="right"
                    closable={false}
                    onClose={onCreateTask}
                    visible={createTask}
                    width={1000}
                >
                    <Form
                        form={form1}
                        {...layout2}
                        labelAlign='left'
                    >
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="任务名字"
                                >
                                    <Input
                                        id='name'
                                        placeholder="请输入任务名字"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="project"
                                    label="项目"
                                >
                                    <Select placeholder="请选择项目" style={{ width: 260 }} onChange={(value) => {
                                        setProject(value)
                                        setProjectName(projectList[projectList.map(item => item.id).indexOf(value)]['name']);
                                    }}>
                                        {
                                            projectList.map((item) => (
                                                <Option value={item.id}>{item.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form form={form4} {...layout1} labelAlign='left'>
                                    <Form.Item
                                        name="team"
                                        label="团队"
                                    >
                                        <Select placeholder="请选择团队" style={{ width: 260 }} onChange={(value) => {
                                            setTeam(value)
                                            setTeamName(teamList[teamList.map(item => item.id).indexOf(value)]['name']);
                                        }}>
                                            {
                                                teamList.map((item) => (
                                                    <Option value={item.id}>{item.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={12}>
                                <Form form={form3} {...layout1} labelAlign='left'>
                                    <Form.Item
                                        name="principal"
                                        label="负责人"
                                    >
                                        <Select placeholder="请选择负责人" style={{ width: 270 }} showSearch onChange={(value) => setPrincipal(value)}>
                                            {
                                                peopleList.map(item => {
                                                    return <Option value={item.name}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={12}>
                                <Form form={form2} {...layout1} labelAlign='left'>
                                    <Form.Item
                                        name="coadjutant"
                                        label="协作者"
                                    >
                                        <Select placeholder="请选择协作者" style={{ width: 260 }} showSearch onChange={(value) => setCoadjutant(value)} mode="multiple">
                                            {
                                                peopleList.map(item => {
                                                    return <Option value={item.name} disabled={item.name == principal ? true : false}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="time"
                                    label="任务时间"
                                >
                                    <RangePicker locale={locale} onChange={(value, dateString) => {
                                        setStartTime(dateString[0])
                                        setEndTime(dateString[1])
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="duration"
                                    label="预计工期"
                                >
                                    <Tooltip placement="leftTop" title="注意:天数必须填正整数，以8小时为一天">
                                        <Input.Group compact>
                                            <Input
                                                style={{ width: '50%' }}
                                                addonAfter="天"
                                                value={day}
                                                onChange={(e) => {
                                                    setDay(e.target.value)
                                                }}
                                            />
                                            <Select defaultValue={hour} style={{ width: '30%' }} onChange={(value) => setHour(value)} >
                                                <Option value="0">0</Option>
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                                <Option value="6">6</Option>
                                                <Option value="7">7</Option>
                                            </Select><span style={{ backgroundColor: '#fafafa', padding: '0 11px', height: '32px', lineHeight: '32px', color: "rgba(0, 0, 0, 0.85)", fontWeight: 'normal', fontSize: "14px", textAlign: 'center', border: '1px solid #d9d9d9' }}>时</span>
                                        </Input.Group>
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="yxj"
                                    label="优先级"
                                >
                                    <Select placeholder="请选择优先级" style={{ width: 260 }} onChange={(value) => setPriority(value)}>
                                        <Option value="1">严重</Option>
                                        <Option value="2">主要</Option>
                                        <Option value="3">次要</Option>
                                        <Option value="4">不重要</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col span={12}>
                                <Form.Item
                                    name="frw"
                                    label="关联父任务"
                                >
                                    <Select placeholder="请选择关联父任务" style={{ width: 260 }} onChange={(value) => {
                                        setParTask(value)
                                        setParTaskName(principalTaskList[principalTaskList.map(item => item.id).indexOf(value)]['name']);
                                    }}>
                                        {
                                            principalTaskList.map((item) => (
                                                <Option value={item.id}>{item.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            {/* <Col span={12}>
                                <Form.Item
                                    name="type"
                                    label="任务类型"
                                >
                                    <Select placeholder="请选择任务类型" style={{ width: 260 }} disabled>
                                     
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <div style={{ height: 400, border: '1px solid #ccc' }}>
                                <BraftEditor
                                    contentStyle={{ height: 300 }}
                                    value={editorState}
                                    extendControls={extendControls}
                                    onChange={editorChange}
                                />
                            </div>
                        </Row>
                        <div>
                            <Upload
                                accept=".doc,.docx,.xlsx,.xls,.pdf,.zip,.rar,.txt,image/*"
                                action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                                listType="picture"                                 // 上传列表的内建样式
                                fileList={docList}                                //已经上传的文件列表（受控）
                                onChange={fileHandleChange}
                            >
                                <Button>
                                    <UploadOutlined /> 文件
                                </Button>
                            </Upload>
                        </div>
                        <div>
                            <Row style={{ marginTop: '10px' }}>
                                <Button type="primary" disabled={effective == 1 ? true : false} onClick={() => createNewTask()}>新建</Button>
                                <Button type="primary" disabled={effective == 1 ? true : false} danger style={{ marginLeft: 20 }}>取消</Button>
                            </Row>
                        </div>
                    </Form>
                </Drawer>

                {/* 修改任务 */}
                <Drawer
                    title="修改任务"
                    placement="right"
                    closable={false}
                    onClose={onUpdateTask}
                    visible={updateTask}
                    width={1000}
                    okButtonProps={{ disabled: effective == 1 ? true : false }}
                    cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                >
                    <Form
                        form={form1}
                        {...layout2}
                        labelAlign='left'
                    >
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="任务名字"
                                >
                                    <Input
                                        id='name'
                                        placeholder="请输入任务名字"
                                        defaultValue={name}
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="project"
                                    label="项目"
                                >
                                    <Select defaultValue={projectId} style={{ width: 260 }} onChange={(value) => {
                                        setProject(value)
                                        setProjectName(projectList[projectList.map(item => item.id).indexOf(value)]['name']);
                                    }}>
                                        {
                                            projectList.map((item) => (
                                                <Option value={item.id}>{item.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form form={form4} {...layout1} labelAlign='left'>
                                    <Form.Item
                                        name="team"
                                        label="团队"
                                    >
                                        <Select defaultValue={teamId == null ? '' : parseInt(teamId)} style={{ width: 260 }} onChange={(value) => {
                                            setTeam(value)
                                            setTeamName(teamList[teamList.map(item => item.id).indexOf(value)]['name']);
                                        }}>
                                            {
                                                teamList.map((item) => (
                                                    <Option value={item.id}>{item.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={12}>
                                <Form form={form3} {...layout1} labelAlign='left'>
                                    <Form.Item
                                        name="principal"
                                        label="负责人"
                                    >
                                        <Select defaultValue={principalName} style={{ width: 270 }} onSearch onChange={(value) => setPrincipal(value)}>
                                            {
                                                peopleList.map(item => {
                                                    return <Option value={item.name}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={12}>
                                <Form form={form2} {...layout1} labelAlign='left'>
                                    <Form.Item
                                        name="coadjutant"
                                        label="协作者"
                                    >
                                        <Select defaultValue={coadjutantName} style={{ width: 260 }} onSearch onChange={(value) => { setCoadjutant(value); setIsUpdateCoadjutant(true) }} mode="multiple">
                                            {
                                                peopleList.map(item => {
                                                    return <Option value={item.name} disabled={item.name == principalName || item.name == principal ? true : false}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="time"
                                    label="任务时间"
                                >
                                    {
                                        startTime != null
                                            ? <RangePicker locale={locale} defaultValue={[moment(updateStartTime, dateFormat), moment(updateEndTime, dateFormat)]} disabled />
                                            : <RangePicker locale={locale} onChange={(value, dateString) => {
                                                setUpdateStartTime(dateString[0])
                                                setUpdateEndTime(dateString[1])
                                            }} />
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="duration"
                                    label="预计工期"
                                >
                                    <Tooltip placement="leftTop" title="注意:天数必须填正整数，以8小时为一天">
                                        <Input.Group compact>
                                            <Input
                                                style={{ width: '50%' }}
                                                addonAfter="天"
                                                value={updateDay}
                                                disabled={day != 0 || hour != 0 ? true : false}
                                                onChange={(e) => {
                                                    setUpdateDay(e.target.value)
                                                }}
                                            />
                                            <Select defaultValue={updateHour} style={{ width: '30%' }} onChange={(value) => setUpdateHour(value)} disabled={day != 0 || hour != 0 ? true : false}>
                                                <Option value="0">0</Option>
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                                <Option value="6">6</Option>
                                                <Option value="7">7</Option>
                                            </Select><span style={{ backgroundColor: '#fafafa', padding: '0 11px', height: '32px', lineHeight: '32px', color: "rgba(0, 0, 0, 0.85)", fontWeight: 'normal', fontSize: "14px", textAlign: 'center', border: '1px solid #d9d9d9' }}>时</span>
                                        </Input.Group>
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="yxj"
                                    label="优先级"
                                >
                                    <Select defaultValue={priority} style={{ width: 260 }} onChange={(value) => setPriority(value)}>
                                        <Option value="1">严重</Option>
                                        <Option value="2">主要</Option>
                                        <Option value="3">次要</Option>
                                        <Option value="4">不重要</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col span={12}>
                                <Form.Item
                                    name="frw"
                                    label="关联父任务"
                                >
                                    <Select defaultValue={parTask} placeholder="请选择关联父任务" style={{ width: 260 }} onChange={(value) => setParTask(value)}>
                                        {
                                            principalTaskList.map((item) => (
                                                <Option value={item.id}>{item.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            {/* <Col span={12}>
                                <Form.Item
                                    name="type"
                                    label="任务类型"
                                >
                                    <Select placeholder="请选择任务类型" style={{ width: 260 }} disabled>
                                        
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <div style={{ height: 400, border: '1px solid #ccc' }}>
                                <BraftEditor
                                    contentStyle={{ height: 300 }}
                                    value={editorState}
                                    extendControls={extendControls}
                                    onChange={editorChange}
                                />
                            </div>
                        </Row>
                        <div>
                            <Row>
                                任务进度:
                            <Col span={20} style={{ marginLeft: '5px' }}>
                                    <Progress percent={percent} />
                                </Col>
                                <Col style={{ marginLeft: '10px' }}>
                                    <Button.Group>
                                        <Button onClick={decline} icon={<MinusOutlined />} disabled={(principalName == cookie.load('name') || creator == cookie.load('name')) && (parseInt(taskState) != 4 && parseInt(taskState) != 5) ? false : true} />
                                        <Button onClick={increase} icon={<PlusOutlined />} disabled={(principalName == cookie.load('name') || creator == cookie.load('name')) && (parseInt(taskState) != 4 && parseInt(taskState) != 5) ? false : true} />
                                    </Button.Group>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            {
                                fileList.map((item, index) => {
                                    return <a target="_blank" href={servicePath.downloadTaskFile + '?principal=' + cookie.load('name') + '&file_uuid=' + task['file_uuid'][index] + '&roleId=' + cookie.load('role') + '&shopId=' + cookie.load('shopList')}><Tag color="blue">{item}</Tag></a>
                                })
                            }
                        </div>
                        <div>
                            <Upload
                                accept=".doc,.docx,.xlsx,.xls,.pdf,.zip,.rar,.txt,image/*"
                                action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                                listType="picture"                                 // 上传列表的内建样式
                                fileList={docList}                                //已经上传的文件列表（受控）
                                onChange={fileHandleChange}
                            >
                                <Button>
                                    <UploadOutlined /> 文件
                                </Button>
                            </Upload>
                        </div>
                        <div>
                            <Row style={{ marginTop: '10px' }}>
                                {/* <Button type="primary" onClick={() => { setAddSubtask(true); }} danger>创建子任务</Button> */}
                                <Button type="primary" style={{ marginLeft: 20, display: principalName == cookie.load('name') || creator == cookie.load('name') ? '' : 'none' }} onClick={() => { okUpdateTask() }} disabled={effective == 0 ? false : true}>修改</Button>
                                <Button style={{ marginLeft: 20 }} onClick={() => setAddComment(true)}>发表评论</Button>
                            </Row>
                        </div>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="评论" key="1">
                                {
                                    commentList.length != 0
                                        ? <List
                                            itemLayout="horizontal"
                                            dataSource={commentList}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={
                                                            item.founder == null ? <span></span> :
                                                                <Avatar>{item.founder.substring(0, 1)}</Avatar>}
                                                        title={item.founder}
                                                        description={item.create_time}
                                                    />
                                                    {item.content}
                                                </List.Item>
                                            )}
                                        />
                                        : <div></div>
                                }

                            </TabPane>
                        </Tabs>
                    </Form>
                </Drawer>

                {/* 添加子任务 */}
                <Modal
                    title="添加子任务"
                    centered={true}
                    visible={addSubtask}
                    maskClosable={false}
                    onOk={addSubtaskOk}
                    onCancel={addSubtaskCancel}
                    okText="确认"
                    cancelText="取消"
                    okButtonProps={{ disabled: effective == 1 ? true : false }}
                    cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                >
                    <Form form={form} {...layout1} labelAlign='left'>
                        <Form.Item label="任务名称" name="name"
                            rules={[{ required: true }]}>
                            <Tooltip
                                trigger={['focus']}
                                title="请填写任务名称"
                                placement="topLeft"
                            >
                                <Input
                                    id="name"
                                    placeholder="请填写任务名称"
                                    value={subtaskName}
                                    style={{ width: 270 }}
                                    onChange={(e) => {
                                        setSubTaskName(e.target.value);
                                    }}
                                />
                            </Tooltip>
                        </Form.Item>
                        <Form.Item label="负责人" name="principal"
                            rules={[{ required: true }]}>
                            <Tooltip
                                trigger={['focus']}
                                title="请选择负责人"
                                placement="topLeft"
                            >
                                <Select placeholder="请选择负责人" style={{ width: 270 }} showSearch onChange={(value) => setSubtaskPrincipal(value)}>
                                    {
                                        peopleList.map(item => {
                                            return <Option value={item.name}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Tooltip>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* 延迟任务时间 */}
                <Modal
                    title="延迟任务时间"
                    centered={true}
                    visible={overtime}
                    maskClosable={false}
                    onOk={overtimeOk}
                    onCancel={overtimeCancel}
                    okText="确认"
                    cancelText="取消"
                    okButtonProps={{ disabled: effective == 1 ? true : false }}
                    cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                    width={1000}
                >

                    {
                        startTime != null
                            ? <Descriptions bordered>
                                <Descriptions.Item label="时间安排">{startTime.substring(0, 10)} - {endTime.substring(0, 10)}</Descriptions.Item>
                                <Descriptions.Item label="预计工期">{day}天{hour}时</Descriptions.Item>
                            </Descriptions>
                            : <div></div>
                    }
                    <Form
                        form={form1}
                        {...layout1}
                        labelAlign='left'
                    >
                        <Row style={{ marginTop: '30px' }}>
                            <Col span={12}>
                                <Form.Item
                                    name="time"
                                    label="任务结束时间"
                                >
                                    {
                                        <DatePicker
                                            defaultValue={moment(endTime, dateFormat)}
                                            locale={locale}
                                            onChange={(value, dateString) => {
                                                setUpdateEndTime(dateString)
                                            }} />
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="duration"
                                    label="预计工期"
                                >
                                   <Tooltip placement="top" title="注意:天数必须填正整数，以8小时为一天">
                                        <Input.Group compact>
                                            <Input
                                                style={{ width: '50%' }}
                                                addonAfter="天"
                                                value={updateDay}
                                                onChange={(e) => {
                                                    setUpdateDay(e.target.value)
                                                }}
                                            />
                                            <Select value={updateHour} style={{ width: '30%' }} onChange={(value) => setUpdateHour(value)} >
                                                <Option value="0">0</Option>
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                                <Option value="6">6</Option>
                                                <Option value="7">7</Option>
                                            </Select><span style={{ backgroundColor: '#fafafa', padding: '0 11px', height: '32px', lineHeight: '32px', color: "rgba(0, 0, 0, 0.85)", fontWeight: 'normal', fontSize: "14px", textAlign: 'center', border: '1px solid #d9d9d9' }}>时</span>
                                        </Input.Group>
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                {/* 添加评论 */}
                <Modal
                    title="添加评论"
                    centered={true}
                    visible={addComment}
                    maskClosable={false}
                    onOk={addCommentOk}
                    onCancel={addCommentCancel}
                    okText="确认"
                    cancelText="取消"
                    okButtonProps={{ disabled: effective == 1 ? true : false }}
                    cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                >
                    <TextArea value={comment} rows={4} onChange={(e) => setComment(e.target.value)} />
                </Modal>
            </Spin>
        </div>
    )

}

export default Task