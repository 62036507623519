import React, { useEffect } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

function Index({
    // 宽度
    chartWidth,
    // 长度
    chartHeight,
    // 时间
    head,
    // 互动人数
    interactionsNumber,
    // 互动次数
    interactions,
    // 发送积分
    sendPoints

}) {

    // 互动
    let interactiveChart = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999'
                }
            }
        },
        legend: {
            data: ['互动人数', '互动次数', '互动发送积分']
        },
        xAxis: [
            {
                type: 'category',
                data: head,
                axisPointer: {
                    type: 'shadow'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '互动人数/互动次数',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: '互动发送积分',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: '互动人数',
                type: 'line',
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' ml';
                    }
                },
                data: interactionsNumber
            },
            {
                name: '互动次数',
                type: 'line',
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' ml';
                    }
                },
                data: interactions
            },
            {
                name: '互动发送积分',
                type: 'bar',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' °C';
                    }
                },
                data: sendPoints
            }
        ]
    }

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('interactive'));
        // 绘制图表
        reportChart.setOption(interactiveChart);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [chartWidth, chartHeight, sendPoints])

    return (
        <div>
            <div id='interactive' style={{ width: `calc(${chartWidth})`, height: `calc(${chartHeight})` }} ></div>
        </div>
    )

}

export default Index