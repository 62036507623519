import React, {useEffect } from 'react';
import {Typography} from 'antd'
const {Title} = Typography
function Index({
    id,
    title,
    awareness,
    interest,
    purchase,
    loyalty,
    aiRate,
    ipRate,
    plRate
}){
    function draw(awareness,interest,purchase,loyalty, aiRate, ipRate, plRate){
        var canvas = document.getElementById(id);
        canvas.height = 0
        canvas.width = 0
        canvas.width = 400
        canvas.height = 300
        if(!canvas.getContext) return;
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "#2a36b1";
      	//绘制矩形 
        ctx.fillRect (0, 0, 200, 50);
        ctx.fillStyle = "#ffffff";
        ctx.font="14px Verdana"
        ctx.fillText("认知:", 100,20);
        ctx.fillText(awareness == '' ? '' : parseInt(awareness).toLocaleString(), 100,40);
        ctx.stroke();   
        ctx.fillStyle = "#3b50ce";
        ctx.fillRect (0, 60, 200, 50);
        ctx.fillStyle = "#ffffff";
        ctx.font="14px Verdana"
        ctx.fillText("兴趣:", 100,80);
        ctx.fillText(interest == '' ? '' : parseInt(interest).toLocaleString(), 100,100);
        ctx.stroke();   
        ctx.fillStyle = "#455ede";
      	//绘制矩形 
        ctx.fillRect (0, 120, 200, 50);
        ctx.fillStyle = "#ffffff";
        ctx.font="14px Verdana"
        ctx.fillText("购买", 100,140);
        ctx.fillText(purchase == '' ? '' : parseInt(purchase).toLocaleString(), 100,160);
        ctx.stroke();
        //绘制矩形  
        ctx.fillStyle = "#4e6cef";
        ctx.fillRect (0, 180, 200, 50);
        ctx.fillStyle = "#ffffff";
        ctx.font="14px Verdana"
        ctx.fillText("忠诚", 100,200);
        ctx.fillText(loyalty == '' ? '' : parseInt(loyalty).toLocaleString(), 100,220);
        ctx.stroke();   
        // ctx.fillStyle = "#5677fc";
        // ctx.fillRect (0, 240, 200, 50);
        // ctx.fillStyle = "#ffffff";
        // ctx.font="14px Verdana"
        // ctx.fillText("成交金额", 100,260);
        // ctx.fillText(turnover, 100,280);
        // ctx.stroke();   
        // ctx.beginPath();
        // ctx.moveTo(300, 120);//起始位置
        // // ctx.lineTo(350, 150); 
        // ctx.lineTo(350, 120);//停止位置
        // ctx.stroke();
        //开始一个新的绘制路径   
        ctx.beginPath();   
        //设置线条颜色为蓝色   
        ctx.strokeStyle = "#ccc";   
        //设置路径起点坐标   
        ctx.moveTo(200, 15);   
        //定义中间点坐标1   
        ctx.lineTo(250, 15);   
        //定义中间点坐标2   
        ctx.lineTo(250, 75);   
        //定义中间点坐标3(这是最后一个中间点，也就是终点)   
        ctx.lineTo(200, 75);   
        //按照绘制路径顺序连接各个坐标点   
        ctx.fillStyle = "#000";
        ctx.font="14px Verdana"
        ctx.fillText("A->I转化率" + aiRate, 260,50);
        ctx.stroke();   
        //关闭绘制路径   
        ctx.closePath();
        //开始一个新的绘制路径   
        ctx.beginPath();   
        //设置线条颜色为蓝色   
        ctx.strokeStyle = "#ccc";   
        //设置路径起点坐标   
        ctx.moveTo(200, 85);   
        //定义中间点坐标1   
        ctx.lineTo(250, 85);   
        //定义中间点坐标2   
        ctx.lineTo(250, 145);   
        //定义中间点坐标3(这是最后一个中间点，也就是终点)   
        ctx.lineTo(200, 145);   
        //按照绘制路径顺序连接各个坐标点   
        ctx.fillStyle = "#000";
        ctx.font="14px Verdana"
        ctx.fillText("I->P转化率:" + ipRate, 260,120);
        ctx.stroke();   
        //关闭绘制路径   
        ctx.closePath();
         //开始一个新的绘制路径   
         ctx.beginPath();   
         //设置线条颜色为蓝色   
         ctx.strokeStyle = "#ccc";   
         //设置路径起点坐标   
         ctx.moveTo(200, 155);   
         //定义中间点坐标1   
         ctx.lineTo(250, 155);   
         //定义中间点坐标2  
         ctx.lineTo(250, 215);   
         //定义中间点坐标3(这是最后一个中间点，也就是终点)   
         ctx.lineTo(200, 215);   
         //按照绘制路径顺序连接各个坐标点   
         ctx.fillStyle = "#000";
         ctx.font="14px Verdana"
         ctx.fillText("P->L转化率:" + plRate, 260,190);
         ctx.stroke();   
         //关闭绘制路径   
         ctx.closePath();
    }
    useEffect(()=>{
        // let visitors = "776711"
        // let productVisitor = "725193"
        // let additionalPurchases = "64803"
        // let payPurchases = "8354"
        // let IPVUV = "94.58%"
        // let addRate = "8.93%"
        // let addConversionRate = "12.89%"
        draw(awareness,interest,purchase, loyalty, aiRate, ipRate, plRate);
    },[plRate])

    return (
        <div>
            <Title level={4} style={{marginLeft:100}}>{title}</Title>
            <canvas id={id} width="400" height="300" style={{display:'flex', justifyContent:'center'}}></canvas>
        </div>
    )

}

export default Index