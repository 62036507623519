import React, { useState } from 'react';
import { Tabs } from 'antd'
import AddWork from "./inside/addWork";
import Message from './inside/message'
const { TabPane } = Tabs;

function InsideSystem(props) {

     // 当前tab值
     const [ curTabKey , setCurTabKey ] = useState('1')

     // 切换tab
     const changeTabs = (activeKey)=>{
         setCurTabKey(activeKey)
     }
   
    return(
        <>
             {/* <Tabs style={{marginLeft: '10px'}} defaultActiveKey={curTabKey} tabPosition="left" onChange={changeTabs}>
                 <TabPane disabled tab=" " key="0">
                 </TabPane>
                 <TabPane disabled tab=" " key="0">
                 </TabPane>
                 <TabPane disabled tab=" " key="0">
                 </TabPane>
                 <TabPane tab="我的工单记录" key="1">
                     {curTabKey === '1' ? <AddWork history={props.history}/> : <div></div>}
                 </TabPane> */}
            {/*<TabPane tab="我的消息" key="2">*/}
            {/*    {curTabKey ==='2' ? <Message history={props.history}/>: <div></div> } */}
            {/*</TabPane>*/}
            {/* </Tabs> */}
            <AddWork history={props.history}/>
        </>
    )

}

export default InsideSystem