import React, { useState, useEffect } from 'react';
import { Typography, Card, Descriptions, Row, Col, message, DatePicker,Divider } from 'antd';
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import Base64 from 'base-64'
import moment from 'moment'
import servicePath from '../../../config/apiUrl'
const { Title, Paragraph, Text, Link } = Typography;
function Index(props) {
    const gridStyle = {
        width: '25%',
        textAlign: 'center',
    };

    // 平台数据
    const [platformSeries, setPlatformSeries] = useState([])
    // 平台时间
    const [platformTime, setPlatformTime] = useState([])
    // kol报价时间
    const [kolCostTime , setKolCostTime ] = useState([])
    // kol报价数据
    const [kolCostSeries , setKolCostSeries ] = useState([])
    // 测试数据
    const [list, setList] = useState([1, 2, 3, 4, 5, 6])
    // 年份
    const [ year , setYear ] = useState(moment().format('YYYY'))
    // 投放数据
    const [ launch , setLaunch] = useState([])
    // 表格
    let report = {
        title: {
            text: '平台数据'
        },
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: platformTime
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
                onZero: true
            },
        },
        series: platformSeries
    }

    // 报价数据
    let reportQuotation = {
        title: {
            text: '报价'
        },
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data:kolCostTime
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
                onZero: true
            },
        },
        series: kolCostSeries
    }

    useEffect(() => {
        // console.log(props.location.state);
        // console.log(props.location.state.platform);
        // console.log(props.location.state.kol_id);
        // console.log(props.location.state.nickname);
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "platform":props.location.state.platform,
            "kol_id": props.location.state.kol_id,
            "nickname":props.location.state.nickname,
            "year":year,
            "principal":cookie.load('name'),
            "principal_uuid":cookie.load('id'),
            "principal_email":Base64.decode(cookie.load('email'))
        }
        NbAxios(dataProps, "POST", servicePath.queryPlatformKolEchart).then(res => {
            if (res.success) {
                setPlatformTime(res.data.month)
                setPlatformSeries(res.data.series)
            } else {
                message.warn(res.errorMsg)
            }
        })
        NbAxios(dataProps,"POST",servicePath.queryKolCost).then(res=>{
            if (res.success) {
                setKolCostTime(res.data.month)
                setKolCostSeries(res.data.series)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }, [])

    useEffect(()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "platform":props.location.state.platform,
            "kol_id": props.location.state.kol_id,
            "nickname":props.location.state.nickname,
            "year":year,
            "principal":cookie.load('name'),
            "principal_uuid":cookie.load('id'),
            "principal_email":Base64.decode(cookie.load('email'))
        }
        NbAxios(dataProps,"POST",servicePath.queryKolLaunch).then(res=>{
            if (res.success) {
                setLaunch(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    },[year])

    useEffect(()=>{
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('report'));
        // 绘制图表
        reportChart.setOption(report);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[platformSeries])

    useEffect(()=>{
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('quotation'));
        // 绘制图表
        reportChart.setOption(reportQuotation);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[kolCostSeries])



    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: "80vw", height: ' 95vh', marginTop: "10px", border: '1px solid #ccc', borderRadius: '25px', overflow:'auto' }}>
                <Card title={props.location.state.nickname} style={{ marginTop: "30px" }}>
                    <Row>
                        <Col span={4}>
                            <Card title="平台">{props.location.state.platform}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="联系方式">{props.location.state.contact}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="所属">{props.location.state.belonging}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="形式">{props.location.state.form}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="人设标签">{props.location.state.human_label}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="笔记标签">{props.location.state.note_label}</Card>
                        </Col>
                    </Row>
                </Card>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div id="report" style={{ width: '70vw', height: '40vh', marginTop: 100 }}></div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div id="quotation" style={{ width: '70vw', height: '40vh', marginTop: 100 }}></div>
                </div>
                <div style={{display:'flex',justifyContent:"center"}}>
                    <DatePicker 
                        value={moment(year, 'YYYY')} 
                        picker="year" 
                        style={{marginBottom:30}}
                        disabledDate={(current)=>{
                            return current > moment().endOf('year');
                        }}
                        onChange={(date, dateString)=>{
                            setYear(dateString);
                        }}
                    />
                </div>
                <div>
                    {
                        launch.length != 0 ?
                        launch.map(item => {
                            return (
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{marginTop:'10px',width: '70vw',}}>
                                    <Divider>{item['launch_name']}</Divider>
                                    <Descriptions bordered >
                                        <Descriptions.Item label="账号链接"><a href={item['account_link']} target="_blank">点击查看</a></Descriptions.Item>
                                        <Descriptions.Item label="推广产品">{item['product_promotion']}</Descriptions.Item>
                                        <Descriptions.Item label="合作形式">{item['form_of_cooperation']}</Descriptions.Item>
                                        <Descriptions.Item label="含税费用">{item['expenses_including_tax']}</Descriptions.Item>
                                        <Descriptions.Item label="合作开始时间">{item['start_time_of_cooperation']}</Descriptions.Item>
                                        <Descriptions.Item label="合作结束时间">{item['end_time_of_cooperation']}</Descriptions.Item>
                                        <Descriptions.Item label="内容链接"><a href={item['content_links']} target="_blank">点击查看</a></Descriptions.Item>
                                        <Descriptions.Item label="阅读量">{item['reading_number']}</Descriptions.Item>
                                        <Descriptions.Item label="阅读成本">{item['reading_cost']}</Descriptions.Item>
                                        <Descriptions.Item label="平均阅读量">{item['average_of_reading']}</Descriptions.Item>
                                        <Descriptions.Item label="互动量">{item['interaction_number']}</Descriptions.Item>
                                        <Descriptions.Item label="互动成本">{item['interaction_cost']}</Descriptions.Item>
                                        <Descriptions.Item label="平均互动量">{item['average_of_interaction']}</Descriptions.Item>
                                        <Descriptions.Item label="销量">{item['sales']}</Descriptions.Item>
                                        <Descriptions.Item label="销售额">{item['sales_number']}</Descriptions.Item>
                                        <Descriptions.Item label="ROI">{item['roi']}</Descriptions.Item>
                                    </Descriptions>
                                </div>
                              </div>
                            )
                        })
                        : <span></span>
                    }
                </div>
            </div>
        </div>
    )

}


export default Index