import React, {Component} from 'react';
import style from './style.module.css'
import {Typography, Tooltip, message} from 'antd'
import {QuestionCircleOutlined} from '@ant-design/icons';

const {Title, Paragraph, Text, Link} = Typography;

function TitleCard({
                       project,
                       sum,
                       target,
                       finshRate,
                       date
                   }) {
    return (
        <div className={style.cardWapper} style={{
            width: '11vw',
            height: project.indexOf("汇总") != -1 ? '180px' : '150px',
            marginRight: project.indexOf("汇总") != -1 ? '10px' : '0px'
        }}>
            {project &&
            <div className={style.channel}>
                <Title ellipsis level={5} style={{margin: 0}} className={style.channelText}>{project}</Title>
            </div>
            }
            <Title level={3} style={{margin: 0}}>{sum ? sum : 0}</Title>
            <div style={{display: "flex", flexDirection: 'column'}}>
                <div className={style.hasFinsh} style={{flex: 1}}>
                    <Text className={style.channelTarget}>
                        <Text type="secondary">目标：</Text>
                        <Text type="secondary">{target ? target : 0}{target == 1 &&
                        <Tooltip placement="right" title="请联系it同事维护目标数据"><QuestionCircleOutlined/></Tooltip>}</Text>
                    </Text>
                </div>
                <div className={style.finshRate} style={{flex: 1}}>
                    <Text type="secondary">完成率：</Text>
                    {
                        parseFloat(finshRate) < 50 ? <Text type="success">{finshRate}</Text>
                            : parseFloat(finshRate) > 100 ?
                            // <Link style={{cursor: "default"}}>{finshRate ? finshRate : ''}</Link>
                            <Text type="danger">{finshRate ? finshRate : ''}</Text>
                            : <Text type="secondary">{finshRate}</Text>
                    }

                </div>
                {
                    date != undefined ?
                        <div className={style.hasFinsh} style={{flex: 1}}>
                            <Text className={style.channelTarget}>
                                <Text strong>最新上传日期：</Text>
                                <Text strong>{date}</Text>
                            </Text>
                        </div> : <span></span>
                }

            </div>
        </div>
    )
}

export default TitleCard