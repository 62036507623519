import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import style from './indicatorCard.module.css'
import {ReactComponent as Down} from './svg/down.svg'
import {ReactComponent as Up} from './svg/up.svg'
import { Typography } from 'antd'
const { Title, Paragraph, Text, Link } = Typography;

function IndicatorCard({
    title,
    value,
    type='周',
    compare,
    iconStatus = 'default',
    indicatorCardList,
    titleKey
}) {
    const textIcon = {
        'up':<Up width={12} height={12} />,
        'down':<Down width={12} height={12} />,
        'default':'-',
    }

    const [ indicatorCard, setIndicatorCard] = useState([])

    useEffect(()=>{
        setIndicatorCard(indicatorCardList)
    },[indicatorCardList])

    return (
        <div className={style.cardWapper} style={{display:indicatorCard.indexOf(titleKey) != -1 ? 'flex':'none'}}>
            <Text>{title}</Text>
            <Title level={4} style={{margin:0}} >{value}</Title>
            <div className={style.compareRow}>                 
                <Text type="secondary" >{`较上期同比`}</Text>
                <Text type="secondary" style={{display:'flex',alignItems:'center'}} >{compare}% {( compare === '-' && '' ) || ( parseFloat(compare)>0 && textIcon['up'] ) || ( parseFloat(compare)<0 && textIcon['down'] ) } </Text>
            </div>
        </div>
    )
}

IndicatorCard.propTypes = {

}

export default IndicatorCard

