import React, { useEffect, useState } from 'react';
import { Typography, Tabs, Table, Badge, Card, Tooltip, Tag, Input, Button, Radio, Select, DatePicker, message, Checkbox, Col, Row, Drawer, Spin, Popover, Modal } from 'antd'
import { SearchOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined, DeleteOutlined, ReloadOutlined, MinusCircleOutlined, PlusOutlined, CloseCircleOutlined, CloseOutlined, createFromIconfontCN } from '@ant-design/icons'
import moment from 'moment'
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios';
import servicePath from '../../../config/apiUrl';
import style from './index.module.css'
import { DraggableArea } from 'react-draggable-tags';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { resolve } from '@sentry/utils';
const { Title, Text } = Typography
const { TabPane } = Tabs
const { Option } = Select
const { RangePicker } = DatePicker
// 日期格式
const dateFormat = 'YYYY-MM-DD'
function Index(props) {
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    let currentMissCache = 0
    const IconFont = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_2817613_yizb8f2tkvi.js',
    });
    // 店铺字典
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    // 对象是否一样
    Object.prototype.vequal = function (obj) {
        var props1 = Object.getOwnPropertyNames(this);
        var props2 = Object.getOwnPropertyNames(obj);
        if (props1.length != props2.length) {
            return false;
        }
        for (var i = 0, max = props1.length; i < max; i++) {
            var propName = props1[i];
            if (this[propName] !== obj[propName]) {
                return false;
            }
        }
        return true;
    }
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };

    Array.prototype.indexRemove = function (obj) {
        for (var i = 0; i < this.length; i++) {
            var temp = this[i];
            if (!isNaN(obj)) {
                temp = i;
            }
            if (temp == obj) {
                for (var j = i; j < this.length; j++) {
                    this[j] = this[j + 1];
                }
                this.length = this.length - 1;
            }
        }
    }
    // 删除数组中第一个匹配的元素，成功则返回位置索引，失败则返回 -1。
    Array.prototype.deleteElementByValue = function (varElement) {
        var numDeleteIndex = -1;
        for (var i = 0; i < this.length; i++) {
            // 严格比较，即类型与数值必须同时相等。
            if (this[i] === varElement) {
                this.splice(i, 1);
                numDeleteIndex = i;
                break;
            }
        }
        return numDeleteIndex;
    }
    // 唯一行
    Array.prototype.unique = function () {
        //必须先进行排序
        this.sort();
        var newArr = new Array();
        //放入第一个数组头数据。
        var temp = this[0];
        newArr.push(temp);
        for (var i = 1; i < this.length; i++) {
            if (temp != this[i]) { //判断数组中，是否拥有该值。
                temp = this[i]; //放置到数组里面。
                newArr.push(this[i]);
            }
        }
        return newArr;
    }
    //去重
    function listRemoveRepeat(c) {
        var result = [];
        for (var i = 0; i < c.length; i++) {
            var flag = true;
            var temp = c[i];
            for (var j = i + 1; j < c.length; j++) {
                if (temp === c[j]) {
                    flag = false;
                    break;
                }
            }
            if (flag) {
                result.push(temp);
            }
        }
        return result;
    }
    //交集
    function listIntersection(a, b) {
        var result = [];
        for (var i = 0; i < b.length; i++) {
            var temp = b[i];
            for (var j = 0; j < a.length; j++) {
                if (temp === a[j]) {
                    result.push(temp);
                    break;
                }
            }
        }
        return result;
    }
    //差集:所有属于a且不属于b的元素组成的集合
    function listDifference(a, b) {
        var clone = a.slice(0);
        for (var i = 0; i < b.length; i++) {
            var temp = b[i];
            for (var j = 0; j < clone.length; j++) {
                if (temp === clone[j]) {
                    clone.splice(j, 1);
                }
            }
        }
        return listRemoveRepeat(clone);
    }
    //并集
    function listUnion(a, b) {
        return listRemoveRepeat(a.concat(b));
    }
    // 是否需要周期性生产
    const [periodicProduction, setPeriodicProduction] = useState(0)
    // 是否新建明细数据模版
    const [isCreateDetailDataTemplate, setIsCreateDetailDataTemplate] = useState(false)
    // 明细模版名字
    const [detailDataTemplateName, setDetailDataTemplateName] = useState('')
    // 数据时间标志位
    const [dataTimeFlag, setDataTimeFlag] = useState(-1)
    // 短链缺失集合
    const [missList, setMissList] = useState([])
    // 短链缺失的标志位
    const [missFlag, setMissFlag] = useState(0)
    // 相同索引
    const [sameIndex, setSameIndex] = useState(0)
    // 对比目的
    const [comparsionPurpose, setComparsionPurpose] = useState('')
    // 查询明细数据指标
    const [detailDataTemplateSearch, setDetailDataTemplateSearch] = useState(false)
    // 模版指标
    const [templateIndicatorList, setTemplateIndicatorList] = useState([])
    // 指标选择
    const [searchItemDate, setSearchItemDate] = useState([
        {
            "situation": "商品基础信息",
            "date": []
        },
        {
            "situation": "生意参谋全量数据",
            "head": [
                "指标",
                "近7天",
                "近14天",
                "近30天",
                "日",
                "周",
                "月",
                "年",
                "产品全周期"
            ],
            "date": [
                {
                    "option": [
                        {
                            "id": "",
                            "name": "下单转换率",
                            "disable": 1
                        },
                        {
                            "id": 348,
                            "name": "下单转换率_近7天",
                            "disable": 1
                        },
                        {
                            "id": 393,
                            "name": "下单转换率_近14天",
                            "disable": 1
                        },
                        {
                            "id": 438,
                            "name": "下单转换率_近30天",
                            "disable": 1
                        },
                        {
                            "id": 483,
                            "name": "下单转换率_日",
                            "disable": 1
                        },
                        {
                            "id": 528,
                            "name": "下单转换率_周",
                            "disable": 1
                        },
                        {
                            "id": 573,
                            "name": "下单转换率_月",
                            "disable": 1
                        },
                        {
                            "id": 618,
                            "name": "下单转换率_年",
                            "disable": 1
                        },
                        {
                            "id": 663,
                            "name": "下单转换率_产品全周期",
                            "disable": 1
                        }
                    ]
                }
            ]
        }
    ])
    // 生意参谋指标
    // const [allBusinessStaffDate, setAllBusinessStaffDate] = useState([
    //     {
    //         "option": [
    //             {
    //                 "id": "",
    //                 "name": "下单转换率",
    //                 "disable": 1
    //             },
    //             {
    //                 "id": 348,
    //                 "name": "下单转换率_近7天",
    //                 "disable": 1
    //             },
    //             {
    //                 "id": 393,
    //                 "name": "下单转换率_近14天",
    //                 "disable": 1
    //             },
    //             {
    //                 "id": 438,
    //                 "name": "下单转换率_近30天",
    //                 "disable": 1
    //             },
    //             {
    //                 "id": 483,
    //                 "name": "下单转换率_日",
    //                 "disable": 1
    //             },
    //             {
    //                 "id": 528,
    //                 "name": "下单转换率_周",
    //                 "disable": 1
    //             },
    //             {
    //                 "id": 573,
    //                 "name": "下单转换率_月",
    //                 "disable": 1
    //             },
    //             {
    //                 "id": 618,
    //                 "name": "下单转换率_年",
    //                 "disable": 1
    //             },
    //             {
    //                 "id": 663,
    //                 "name": "下单转换率_产品全周期",
    //                 "disable": 1
    //             }
    //         ]
    //     }
    // ])
    const [allBusinessStaffDate, setAllBusinessStaffDate] = useState([])
    const [chooseBusinessStaffDate, setChooseBusinessStaffDate] = useState([
        {
            "option": [
                {
                    "id": "",
                    "name": "下单转换率",
                    "disable": 1
                },
                {
                    "id": 348,
                    "name": "下单转换率_近7天",
                    "disable": 1
                },
                {
                    "id": 393,
                    "name": "下单转换率_近14天",
                    "disable": 1
                },
                {
                    "id": 438,
                    "name": "下单转换率_近30天",
                    "disable": 1
                },
                {
                    "id": 483,
                    "name": "下单转换率_日",
                    "disable": 1
                },
                {
                    "id": 528,
                    "name": "下单转换率_周",
                    "disable": 1
                },
                {
                    "id": 573,
                    "name": "下单转换率_月",
                    "disable": 1
                },
                {
                    "id": 618,
                    "name": "下单转换率_年",
                    "disable": 1
                },
                {
                    "id": 663,
                    "name": "下单转换率_产品全周期",
                    "disable": 1
                }
            ]
        }
    ])
    // 选择生意参谋关键字
    const [businessStaffKey, setBusinessStaffKey] = useState('')
    // Tab的键
    const [detailDataTemplateTabKey, setDetailDataTemplateTabKey] = useState("指标选择")
    // 加载数据配置
    const [isSpinning, setIsSpinning] = useState(false)
    // Tab的键
    const [tabSituationKey, setTabSituationKey] = useState("商品基础信息")
    // 已选指标
    const [selectedIndicator, setSelectedIndicator] = useState([{ id: '304', name: '图片' }, { id: '308', name: 'ID' }, { id: '309', name: '款号' }])
    // 固定指标
    const [fixedIndicator] = useState([{ id: '304', name: '图片' }, { id: '308', name: 'ID' }, { id: '309', name: '款号' }])
    const [titleIndex, setTitleIndex] = useState(-1)
    const [rowIndex, setRowIndex] = useState(-1)
    const [chosedList, setChosedList] = useState([])
    const [chooseIndex, setChooseIndex] = useState([])
    // 场景Tabkey
    const [scenesTabKey, setScenesTabKey] = useState('过往对比记录')
    // 过往数据
    const [pastData, setPastData] = useState([])
    // 对比类型
    const [comparisonType, setComparisonType] = useState(0)
    // 过往数据表头
    const [pastColumns] = useState([
        {
            title: '对比目的',
            dataIndex: 'objective',
            key: 'objective',
            align: "center",
            width: 200,
            fixed: "left",
            render: (text, record, index) => (
                record.is_top == 1 ?
                    <Badge.Ribbon text="置顶" placement="start" color="red">
                        <Card size="small" bordered={false}>
                            <Text type={"secondary"}>{text}</Text>
                        </Card>
                    </Badge.Ribbon>
                    : <Card size="small" bordered={false}>
                        <Text type={"secondary"}>{text}</Text>
                    </Card>
            )
        },
        {
            title: '模板类型',
            dataIndex: 'comparison_type',
            key: 'comparison_type',
            align: "center",
            width: 100,
            render: (text, record, index) => (
                <div>
                    {
                        text == 1 ? '明细数据模版' : '分组数据模版'
                    }
                </div>
            )
        },
        {
            title: '包含的数据缓存',
            dataIndex: 'cache_name_1',
            key: 'cache_name_1',
            align: "center",
            width: 300,
            render: (text, record, index) => (
                <div>
                    <div>{record.cache_name_1}</div>
                    <div>{record.cache_name_2}</div>
                </div>
            )
        },
        {
            title: '对比时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: "center",
            width: 150,
        },
        {
            title: '状态',
            dataIndex: 'is_valid',
            key: 'is_valid',
            align: "center",
            width: 50,
            render: (text, record, index) => (
                record.is_valid == 1 ?
                    <Tag color="cyan">正常</Tag>
                    : <Tag color="red">禁用</Tag>
            )
        },
        {
            title: '操作',
            dataIndex: 'name',
            key: 'name',
            align: "center",
            width: 200,
            render: (text, record, index) => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    {
                        record.is_valid == 1 ? <Tooltip title="查看细节">
                            <SearchOutlined onClick={() => {
                                setComparisonType(record.comparison_type)
                                setComparsionShortChain(record.short_chain)
                                if (record.comparison_type == 1) {
                                    setOpenDetailPresentWays(true)
                                }else{
                                    setOpenGroupPresentWays(true)
                                }
                            }} />
                        </Tooltip> : <span></span>
                    }
                    {
                        record.is_top == 1 ?
                            <Tooltip title="取消置顶">
                                <VerticalAlignBottomOutlined onClick={()=>{
                                    let dataProps = {
                                        "roleId": cookie.load('role'),
                                        "shopList": cookie.load('shopList'),
                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                        "comparison_record_id": record.id
                                    }
                                    NbAxios(dataProps, "POST", servicePath.cancelComparisonRecord).then(res => {
                                        if (res.success) {
                                            message.success('成功取消置顶')
                                            if (pastDataPage != 1) {
                                                setPastDataPage(1)
                                            } else {
                                                getDataComparisonRecord()
                                            }
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    })
                                }} />
                            </Tooltip> :
                            <Tooltip title="置顶">
                                <VerticalAlignTopOutlined onClick={()=>{
                                    let dataProps = {
                                        "roleId": cookie.load('role'),
                                        "shopList": cookie.load('shopList'),
                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                        "comparison_record_id": record.id
                                    }
                                    NbAxios(dataProps, "POST", servicePath.topComparisonRecord).then(res => {
                                        if (res.success) {
                                            message.success('成功置顶')
                                            if (pastDataPage != 1) {
                                                setPastDataPage(1)
                                            } else {
                                                getDataComparisonRecord()
                                            }
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    })
                                }}/>
                            </Tooltip>
                    }
                    {
                        record.is_valid == 1 ?
                            <Tooltip title="弃用">
                                <DeleteOutlined onClick={()=>{
                                    let dataProps = {
                                        "roleId": cookie.load('role'),
                                        "shopList": cookie.load('shopList'),
                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                        "comparison_record_id": record.id
                                    }
                                    NbAxios(dataProps, "POST", servicePath.deleteComparisonRecord).then(res => {
                                        if (res.success) {
                                            message.success('成功弃用')
                                            if (pastDataPage != 1) {
                                                setPastDataPage(1)
                                            } else {
                                                getDataComparisonRecord()
                                            }
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    })
                                }}/>
                            </Tooltip> :
                            <Tooltip title="恢复">
                                <ReloadOutlined onClick={()=>{
                                    let dataProps = {
                                        "roleId": cookie.load('role'),
                                        "shopList": cookie.load('shopList'),
                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                        "comparison_record_id": record.id
                                    }
                                    NbAxios(dataProps, "POST", servicePath.recoveryComparisonRecord).then(res => {
                                        if (res.success) {
                                            message.success('成功恢复')
                                            if (pastDataPage != 1) {
                                                setPastDataPage(1)
                                            } else {
                                                getDataComparisonRecord()
                                            }
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    })
                                }}/>
                            </Tooltip>
                    }
                </div>
            )
        },
    ])
    // 过往数据页面
    const [pastDataPage, setPastDataPage] = useState(1)
    // 过往数据总数
    const [pastDataTotle, setPastDataTotle] = useState(100)
    // 关键字
    const [keyWord, setKeyWord] = useState('')
    //设置每个页面显示个数
    const pastDataPaginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: pastDataPage,
        total: pastDataTotle,
        onChange: page => {
            //改变页码
            setPastDataPage(page)
        }
    }

    // 模型类型
    const [modelType, setModelType] = useState('分组模版')
    // 数据指标
    /**
     * type 类型 模版/自选指标
     * data 数据 模型id/指标list
     */
    // 缓存集合
    const [cacheList, setCacheList] = useState(['缓存1', '缓存2', '缓存3'])
    // 数据时间
    const [dataTime, setDataTime] = useState([])
    // 数据指标类型
    const [isExitDetailTemplate, setIsExitDetailTemplate] = useState(1)
    // 分组统计
    const [isExitGroupTemplate, setIsExitGroupTemplate] = useState(1)
    // 模版集合
    const [modelList, setModelList] = useState(['模型1', '模型2', '模型3'])
    // 分组数据模版
    const [groupModelList, setGroupModelList] = useState([])
    const [groupModelId, setGroupModelId] = useState('')
    const [groupModelTemplateId, setGroupTemplateId] = useState('')
    const [groupModelSelectValue, setGroupModelSelectValue] = useState('')
    // 明细数据模版
    const [detailModelList, setDetailModelList] = useState([])
    // 模版id
    const [modelId, setModelId] = useState('')
    // 明细数据模版id
    const [detailModelId, setDetailModelId] = useState('')
    // 明细数据模版下拉框
    const [detailModelSelectValue, setDetailModelSelectValue] = useState('')
    // 已选明细数据指标
    const [detailSelectedIndicator, setDetailSelectedIndicator] = useState([])
    const [groupDetailSelectedIndicator, setGroupDetailSelectedIndicator] = useState([])
    
    // 明细数据缓存列表
    const [detailCacheList, setDetailCacheList] = useState([])
    // 分组数据缓存列表
    const [groupCacheList, setGroupCacheList] = useState([])
    // 已选指标
    const [chosedNorm, setChosedNorm] = useState(['支付金额', '支付件数', '支付人数'])
    // 是否存在汇总模版
    const [isExitSummaryTemplate, setIsExitSummaryTemplate] = useState(1)
    // 模版分组维度
    const [modelGroupDimensionsList, setModelGroupDimensionsList] = useState([])
    // 模版统计指标
    const [modelStatisticalIndicatorsList, setModelStatisticalIndicatorsList] = useState([])
    // 模版分组维度
    const [modelGroupDimensions, setModelGroupDimensions] = useState([])
    // 模版统计指标
    const [modelStatisticalIndicators, setModelStatisticalIndicators] = useState([])
    // 分组维度
    const [groupDimensions, setGroupDimensions] = useState([])
    // 统计指标
    const [statisticalIndicators, setStatisticalIndicators] = useState([])
    // 方案数据
    const [planData, setPlanData] = useState([
        {
            'value': '',
            'planList': ['方案1', '方案2', '方案3']
        }
    ])
    // 方案list
    const [planList, setPlanList] = useState(['方案1', '方案2', '方案3'])
    // 对比类型
    const [contrastType, setContrastType] = useState(2)
    // 维度数据
    const [atomicData, setAtomicData] = useState([
        {
            'short_chain':'',
            'value': [],
            'atomicDimension':[]
        }
    ])
    const [selectedAtomicData, setSelectedAtomicData] = useState([])
    // 原子维度
    const [atomicDimension, setAtomicDimension] = useState([
        {
            atomic: ['年']
        },
        {
            atomic: ['20-季节', '21-季节']
        },
        {
            atomic: ['20-春', '20-夏', '20-秋', '20-冬', '21-春', '21-夏', '21-秋', '21-冬']
        }
    ])
    // 选择维度
    const [selectAtomic, setSelectAtomic] = useState([])
    // 选择维度行数
    const [selectAtomicIndex, setSelectAtomicIndex] = useState(-1)
    // 对比方案短链 short_chain
    const [comparsionShortChain, setComparsionShortChain] = useState('')
    // 打开明细数据呈现方式
    const [openDetailPresentWays, setOpenDetailPresentWays] = useState(false)
    // 打开分组数据呈现方式
    const [openGroupPresentWays, setOpenGroupPresentWays] = useState(false)
    // 呈现方式
    const [presentWays, setPresentWays] = useState(['行交差', '列交差', '差异表', '折线图', '柱状图', '旭日图'])
    // 明细数据的呈现方式
    const [detailPresentWays, setDetailPresentWays] = useState([
        {
            'name':'行交差',
            'key':"intersection"
        },{
            'name':'列交差',
            'key':"column"
        },{
            'name':'差异表',
            'key':"different"
        }
    ])
    // 分组数据的呈现方式
    const [groupPresentWays, setGroupPresentWays] = useState([
        {
            'name':'行交差',
            'key':"intersection"
        },{
            'name':'列交差',
            'key':"column"
        },{
            'name':'差异表',
            'key':"different"
        },{
            'name':'柱状图',
            'key':'histogram'
        },{
            'name':'旭日图',
            'key':'sankey'
        }]
    )
    // 选择的呈现方式
    const [selectedPresentWays, setSelectedPresentWays] = useState([])
    // 旭日图比较指标
    const [comparsionOfSunburstList, setComparsionOfSunburstList] = useState(['支付金额', '支付件数'])
    // 旭日图比较指标数据
    const [comparsionOfSunburst, setComparsionOfSunburst] = useState('')
    // 横着气泡
    const horizontallContent = (
        <div style={{ display: "flex" }}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                // 获取竖着可选的id
                let idObj = []
                let itemObj = []
                data.map(item => {
                    if (item['option'][chooseIndex].disable) {
                        idObj.push(item['option'][chooseIndex].id)
                        itemObj.push(item['option'][chooseIndex])
                    }
                })
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.push(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.push(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>全选</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                // 获取竖着可选的id
                let idObj = []
                let itemObj = []
                data.map(item => {
                    if (item['option'][chooseIndex].disable) {
                        idObj.push(item['option'][chooseIndex].id)
                        itemObj.push(item['option'][chooseIndex])
                    }
                })
                let chosedArr = [...chosedList]
                let idIntersectArr = listIntersection(chosedArr, idObj)
                let idUnionArr = listUnion(chosedArr, idObj)
                let idMinusArr = listDifference(idUnionArr, idIntersectArr)
                setChosedList(idMinusArr)
                let itemArr = [...selectedIndicator]
                let itemIntersectArr = listIntersection(itemArr, itemObj)
                let itemUnionArr = listUnion(itemArr, itemObj)
                let itemMinusArr = listDifference(itemUnionArr, itemIntersectArr)
                setSelectedIndicator(itemMinusArr)
                //   let arr = [...chosedList]
                //   obj.map(item=>{
                //     if(chosedList.indexOf(item) != -1){
                //         // 在选中的集合中不存在,添加新的数据
                //         arr.remove(item)
                //     }
                //   })
                //   setChosedList(arr)
            }}>反选</Button>
            <Button type="primary" danger onClick={() => {
                let data = chooseBusinessStaffDate
                // 获取竖着可选的id
                let idObj = []
                let itemObj = []
                data.map(item => {
                    if (item['option'][chooseIndex].disable) {
                        idObj.push(item['option'][chooseIndex].id)
                        itemObj.push(item['option'][chooseIndex])
                    }
                })
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.remove(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.remove(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>清空</Button>
        </div>
    )

    // 竖着气泡
    const verticalContent = (
        <div style={{ display: "flex" }}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                let idObj = []
                let itemObj = []
                data[rowIndex]['option'].map(item => {
                    if (item.disable) {
                        idObj.push(item.id)
                        itemObj.push(item)
                    }
                })
                // 删除第一个
                idObj.shift()
                itemObj.shift()
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.push(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.push(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>全选</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                let idObj = []
                let itemObj = []
                data[rowIndex]['option'].map(item => {
                    if (item.disable) {
                        idObj.push(item.id)
                        itemObj.push(item)
                    }
                })
                // 删除第一个
                idObj.shift()
                itemObj.shift()
                let chosedArr = [...chosedList]
                let idIntersectArr = listIntersection(chosedArr, idObj)
                let idUnionArr = listUnion(chosedArr, idObj)
                let idMinusArr = listDifference(idUnionArr, idIntersectArr)
                setChosedList(idMinusArr)
                let itemArr = [...selectedIndicator]
                let itemIntersectArr = listIntersection(itemArr, itemObj)
                let itemUnionArr = listUnion(itemArr, itemObj)
                let itemMinusArr = listDifference(itemUnionArr, itemIntersectArr)
                setSelectedIndicator(itemMinusArr)
                //   let arr = [...chosedList]
                //   obj.map(item=>{
                //     if(chosedList.indexOf(item) != -1){
                //         // 在选中的集合中不存在,添加新的数据
                //         arr.remove(item)
                //     }
                //   })
                //   setChosedList(arr)
            }}>反选</Button>
            <Button type="primary" danger onClick={() => {
                let data = chooseBusinessStaffDate
                let idObj = []
                let itemObj = []
                data[rowIndex]['option'].map(item => {
                    if (item.disable) {
                        idObj.push(item.id)
                        itemObj.push(item)
                    }
                })
                // 删除第一个
                idObj.shift()
                itemObj.shift()
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.remove(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.remove(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>清空</Button>
        </div>
    )

    // 筛选功能
    const searchContent = (
        <div style={{ display: 'flex' }}>
            <Input width={400} value={businessStaffKey} style={{ marginRight: 10 }} onChange={(e) => {
                setBusinessStaffKey(e.target.value)
            }} />
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let obj = []
                allBusinessStaffDate.map(item => {
                    if (item.option[0]['name'].indexOf(businessStaffKey) != -1) {
                        obj.push(item)
                    }
                })
                setChooseBusinessStaffDate(obj)
            }}>查询</Button>
            <Button type="primary" danger onClick={() => {
                setBusinessStaffKey('')
                let obj = [...allBusinessStaffDate]
                setChooseBusinessStaffDate(obj)
            }}>重置</Button>
        </div>
    )
    // 获取场景接口
    useEffect(() => {
        queryIndex()
        getDetailDataTemplate()
        getGroupDataTemplate()
    }, [])

    useEffect(() => {
        if (detailModelId != '') {
            getDetailDataTemplateDetails()
            getDetailDataTemplateCache()
        }
    }, [detailModelId])

    useEffect(() => {
        if (groupModelId != '') {
            getDetailDataTemplateDimensionIndicators(groupModelTemplateId);
        }
    }, [groupModelId])

    useEffect(()=>{
        if(modelStatisticalIndicators.length != 0){
            queryGroupStatisticsTemplateCacheList(groupModelId)
            queryGroupStatisticsTemplateDetail(groupModelId);
            getGroupDetailDataTemplateDetails(groupModelTemplateId)
        }
    },[modelStatisticalIndicators])

    //
    useEffect(() => {
        if (missFlag == 1) {
            let key = "dataComparsion"
            message.loading({ content: '开始创建数据对比方案...', key, duration: 100 })
            // console.log(detailModelSelectValue.split(",")[0]);
            // let cacheName = []
            // dataTime.map(item=>{
            //     let name = detailModelSelectValue.split(",")[0] + '-' + item.start_time + '-' + item.end_time
            //     cacheName.push(name)
            // })
            // console.log(cacheName);
            // 对比类型
            let type = modelType == '明细模版' ? 1 : 2
            // console.log(type);
            let cache_name_1 = ''
            let cache_start_time_1 = ''
            let cache_end_time_1 = ''
            let cache_short_chain_1 = ''
            let cache_name_2 = ''
            let cache_start_time_2 = ''
            let cache_end_time_2 = ''
            let cache_short_chain_2 = ''
            dataTime.map((item, index) => {
                if (index == 0) {
                    let name = detailModelSelectValue.split(",")[0] + '-' + item.start_time + '-' + item.end_time
                    cache_name_1 = name
                    cache_start_time_1 = item.start_time
                    cache_end_time_1 = item.end_time
                    cache_short_chain_1 = item.short_chain
                } else if (index == 1) {
                    let name = detailModelSelectValue.split(",")[0] + '-' + item.start_time + '-' + item.end_time
                    cache_name_2 = name
                    cache_start_time_2 = item.start_time
                    cache_end_time_2 = item.end_time
                    cache_short_chain_2 = item.short_chain
                }
            })
            let dataProps = {
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                "type": type,
                "template_id": detailModelId,
                "name": '',
                "cache_name_1": cache_name_1,
                "cache_start_time_1": cache_start_time_1,
                "cache_end_time_1": cache_end_time_1,
                "cache_short_chain_1": cache_short_chain_1,
                "cache_name_2": cache_name_2,
                "cache_start_time_2": cache_start_time_2,
                "cache_end_time_2": cache_end_time_2,
                "cache_short_chain_2": cache_short_chain_2,
                "objective": comparsionPurpose,
                "is_index": sameIndex,
                "creator_name": cookie.load('name'),
                "is_top": 0
            }
            console.log(dataProps);
            NbAxios(dataProps, "POST", servicePath.createDataComparisonRecord).then(res => {
                if (res.success) {
                    message.success({ content: '数据对比方案创建成功', key })
                    window.location.href = `https://nb20.bmcsoft.cn/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison?selectedPresentWays=${JSON.stringify(selectedPresentWays)}&comparsionShortChain=${res.data['short_chain']}&comparison_type=1`
                    // window.location.href(`/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison?selectedPresentWays=${JSON.stringify(selectedPresentWays)}&comparsionShortChain=${res.data['short_chain']}&comparison_type=${type}`)
                    // props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison`, 
                    // 'state': { 'selectedPresentWays': selectedPresentWays, 'comparsionShortChain': res.data['short_chain'], 'comparison_type': type } })
                } else {
                    message.warn(res.errorMsg)
                    setMissFlag(0)
                    setDataTimeFlag(-1)
                }
            })
        }
    }, [missFlag])

    useEffect(() => {
        let key = "dataComparsion"
        if (dataTimeFlag == 1) {
            dataTime.map((item, index) => {
                if (item.short_chain == '') {
                    message.loading({ content: '正在判断数据是否存在明细缓存...', key, duration: 100 })
                    checkDetailDataCache(item, index, missList.length);
                } else {
                    setMissFlag(1)
                }
            })
        } else if (dataTimeFlag == 0) {
            setDataTimeFlag(-1)
            message.warn({ content: '请选择两组时间间隔相同的数据...', key })
        }
    }, [dataTimeFlag])

    useEffect(() => {
        if (scenesTabKey == '过往对比记录') {
            if (pastDataPage != 1) {
                setPastDataPage(1)
            } else {
                getDataComparisonRecord()
            }
        }
    }, [scenesTabKey])

    useEffect(() => {
        if (scenesTabKey == '过往对比记录') {
            getDataComparisonRecord()
        }
    }, [pastDataPage])

    const getGroupDetailDataTemplateDetails = (templateId) => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "template_id": templateId
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplateDetails).then(res => {
            if (res.success) {
                setGroupDetailSelectedIndicator(res.data['data'][0]['selected_indicator']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询明细模板对应的指标和维度接口
    const getDetailDataTemplateDimensionIndicators = (templateId) => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'template_id': templateId,
        }

        NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplateDimensionIndicators).then(res => {
            if (res.success) {
                setModelGroupDimensions(res.data['dimension']);
                setModelStatisticalIndicators(res.data['indicators']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 根据分组统计模版查询缓存
    const queryGroupStatisticsTemplateCacheList = (templateId)=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "template_id":templateId
        }
        NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateCacheList).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setDataTime([
                    {
                        'type': 1,
                        'data': '',
                        'short_chain': '',
                        'start_time': '',
                        'end_time': '',
                        'cacheList': res.data
                    }
                ])
                setGroupCacheList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询原子表格接口
    const queryAtomicTable = (short_chain) => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "short_chain": short_chain,
        }
        NbAxios(dataProps, "POST", servicePath.queryAtomicTable).then(res => {
            if (res.success) {
                let obj = [...atomicData]
                obj[atomicData.length - 1 ]['short_chain'] = short_chain 
                obj[atomicData.length - 1 ]['atomicDimension'] = res.data['condition']
                setAtomicData(obj)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询分组统计模板详情接口
    const queryGroupStatisticsTemplateDetail = (templateId) => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "shop": props.history.location.pathname.split('/')[2],
            "template_id": templateId
        }
        NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateDetail).then(res => {
            if (res.success) {
                setModelGroupDimensionsList(res.data.dimension_list)
                setModelStatisticalIndicatorsList(res.data.indicator_list)
                let dimension_list = res.data.dimension_list
                let indicator_list = res.data.indicator_list
                let group_dimensions = []
                let statistical_indicators = []
                modelGroupDimensions.map(item=>{
                    if(dimension_list.indexOf(item.id) != -1){
                        group_dimensions.push(item)
                    }
                })
                setGroupDimensions(group_dimensions);
                modelStatisticalIndicators.map(item=>{
                    item.indicatorList.map(i=>{
                        if(indicator_list.indexOf(i.id.toString()) != -1){
                            statistical_indicators.push(i)
                        }
                    })
                })
                setStatisticalIndicators(statistical_indicators);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询个人对比记录接口
    const getDataComparisonRecord = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "creator_name": cookie.load('name'),
            "key_word": keyWord,
            "page_size": 10,
            "page": pastDataPage,
        }
        NbAxios(dataProps, "POST", servicePath.queryDataComparisonRecord).then(res => {
            if (res.success) {
                console.log(res.data);
                setPastDataTotle(res.total);
                setPastData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取明细数据模版
    const getDetailDataTemplate = () => {
        setIsSpinning(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "creator_name": cookie.load('name'),
            "page_size": 100,
            "page": 1,
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplate).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setDetailModelList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取分组统计模版
    const getGroupDataTemplate = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "creator_name": cookie.load('name'),
            "page_size": 100,
            "page": 1,
        }
        NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateList).then(res => {
            if (res.success) {
                console.log(res.data);
                setGroupModelList(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }



    // 获取场景
    const queryIndex = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]]
        }
        NbAxios(dataProps, "POST", servicePath.queryDataMartIndex).then(res => {
            if (res.success) {
                setAllBusinessStaffDate(res.data['1']['date']);
                setChooseBusinessStaffDate(res.data['1']['date']);
                setSearchItemDate(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 获取明细数据模版缓存接口
    const getDetailDataTemplateCache = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'template_id': detailModelId,
            "creator_name": cookie.load('name')
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplateCache).then(res => {
            if (res.success) {
                setDataTime([
                    {
                        'type': 1,
                        'data': '',
                        'short_chain': '',
                        'start_time': '',
                        'end_time': '',
                        'cacheList': res.data
                    }
                ])
                setDetailCacheList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询明细数据模板详情接口
    const getDetailDataTemplateDetails = () => {
        setIsSpinning(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "template_id": detailModelId
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplateDetails).then(res => {
            setIsSpinning(false)
            if (res.success) {
                // console.log(res.data['data'][0]['selected_indicator']);
                setDetailSelectedIndicator(res.data['data'][0]['selected_indicator']);

            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    //判断是否存在明细数据缓存
    const checkDetailDataCache = (item, index, missCacheLength) => {
        let key = "dataComparsion"
        message.loading({ content: '正在判断选择的时间是否存在明细缓存...', key, duration: 100 })
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "template_id": detailModelId,
            "start_time": item.start_time,
            "end_time": item.end_time
        }
        NbAxios(dataProps, "POST", servicePath.checkDetailDataCache).then(res => {
            if (res.success) {
                // 标记当前处理到第几个缺失短链的数据
                // let nowMissCaches = currentMissCache
                // console.log("========1======");
                // console.log(nowMissCaches);
                // nowMissCaches = nowMissCaches + 1
                //  缓存数据存在
                // console.log(res.data['short_chain']);
                currentMissCache = currentMissCache + 1
                let obj = [...dataTime]
                obj[index]['short_chain'] = res.data['short_chain']
                setDataTime(obj)
                if (currentMissCache == missCacheLength) {
                    setMissFlag(1)
                }
            } else {
                message.loading({ content: '正在生产明细数据缓存...', key, duration: 100 })
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "template_id": detailModelId,
                    "start_time": item.start_time,
                    "end_time": item.end_time,
                    "creator_name": cookie.load('name'),
                }
                NbAxios(dataProps, "POST", servicePath.createDetailDataCache).then(res => {
                    if (res.success) {
                        currentMissCache = currentMissCache + 1
                        let obj = [...dataTime]
                        obj[index]['short_chain'] = res.data['short_chain']
                        setDataTime(obj)
                        if (currentMissCache == missCacheLength) {
                            setMissFlag(1)
                        }
                    } else {
                        message.warn({ content: res.errorMsg, key })
                    }
                })
            }
        })
    }

    const checkDataTimeInterval = (dataTimeItem) => {
        let start_time_1 = ''
        let end_time_1 = ''
        let start_time_2 = ''
        let end_time_2 = ''
        dataTimeItem.map((item, index) => {
            if (index == 0) {
                start_time_1 = item.start_time
                end_time_1 = item.end_time
            } else if (index == 1) {
                start_time_2 = item.start_time
                end_time_2 = item.end_time
            }
        })
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "start_time_1": start_time_1,
            "end_time_1": end_time_1,
            "start_time_2": start_time_2,
            "end_time_2": end_time_2,
        }
        NbAxios(dataProps, "POST", servicePath.checkDataTimeInterval).then(res => {
            if (res.success) {
                setDataTimeFlag(1)
            } else {
                setDataTimeFlag(0)
            }
        })
    }

    //判断是否存在明细数据缓存
    const checkGroupDetailDataCache = (startTime , endTime) => {
        setEffective(1)
        let key = "checkDetailData"
        message.loading({ content: '正在校验是否存在明细数据缓存...', key, duration: 100 })
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "template_id": groupModelTemplateId,
            "start_time": startTime,
            "end_time": endTime
        }
        NbAxios(dataProps, "POST", servicePath.checkDetailDataCache).then(res => {
            if (res.success) {
                message.loading({ content: '正在生产原子表...', key, duration: 100 })
                createAtomicTable(res.data['short_chain'], startTime , endTime)
            } else {
                // message.warn(res.errorMsg)
                message.loading({ content: '正在生产明细数据缓存...', key, duration: 100 })
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "template_id": groupModelTemplateId,
                    "start_time": startTime,
                    "end_time": endTime,
                    "creator_name": cookie.load('name'),
                }
                NbAxios(dataProps, "POST", servicePath.createDetailDataCache).then(res => {
                    if(res.success){
                        message.loading({ content: '正在生产原子表...', key, duration: 100 })
                        createAtomicTable(res.data['short_chain'], startTime , endTime)
                    }else{
                        setEffective(0)
                        message.warn({ content: res.errorMsg, key })
                    }
                })
            }
        })
    }

    // 生产原子表
    const createAtomicTable = (short_chain, startTime , endTime)=>{
        let key = "checkDetailData"
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "template_id": '',
            "short_chain":short_chain,
            // "short_chain":res.data['short_chain'],
            "start_time": startTime,
            "end_time": endTime,
            "creator_name": cookie.load('name'),
            "name":'',
            "purpose":"",
            'dimension_list':modelGroupDimensionsList,
            'indicator_list':modelStatisticalIndicatorsList,
            "group_statistics_template_id":groupModelId
        }
        NbAxios(dataProps, "POST", servicePath.createAtomicTable).then(res => {
            if(res.success){
                setEffective(0)
                message.success({ content: '成功生产原子表...', key})
                queryAtomicTable(res.data.short_chain)
            }else{
                setEffective(0)
                message.warn({ content: res.errorMsg, key })
            }
        })
    }

    return (
        <div>
            <div style={
                {
                    display: 'flex',
                    justifyContent: "space-around",
                    backgroundColor: "#fff",
                    borderBottom: "solid 2px #ccc",
                    paddingTop: 5,
                    marginBottom: 10,
                    position: 'fixed',
                    top: 40,
                    zIndex: 99,
                    width: '100vw'
                }
            } >
                <Title level={2} > {props.location.pathname.split('/')[2]}数据对比 </Title>
                <div></div>
            </div>
            <div style={{ width: '100vw', paddingLeft: 20, paddingRight: 20, marginTop: 70 }}>
                <Tabs activeKey={scenesTabKey} type="card" onChange={(key) => {
                    setScenesTabKey(key)
                }}>
                    <TabPane tab="过往对比记录" key="过往对比记录"></TabPane>
                    <TabPane tab="新建数据对比" key="新建数据对比"></TabPane>
                </Tabs>
                <div style={{ display: scenesTabKey == "过往对比记录" ? '' : 'none' }}>
                    <div style={{ display: 'flex' }}>
                        <Input style={{ width: 400, marginRight: 10 }} placeholder="支持根据对比目的模糊查询" onChange={(e) => {
                            setKeyWord(e.target.value)
                        }} />
                        <Button type="primary">查询</Button>
                    </div>
                    <Table
                        dataSource={pastData}
                        columns={pastColumns}
                        pagination={pastDataPaginationProps}
                        scroll={{ x: 100, y: '65vh' }}
                    />
                </div>
                <div style={{ display: scenesTabKey == "新建数据对比" ? '' : 'none', border: '1px solid #ccc', padding: 20, backgroundColor: "#fff" }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: "140px 700px" }}>
                            <Text strong>选择数据模型类型:</Text>
                            <Radio.Group value={modelType} onChange={(e) => {
                                setModelId('')
                                setIsExitDetailTemplate(1)
                                setDataTime([
                                    {
                                        'type': 1,
                                        'data': '',
                                        'short_chain': '',
                                        'start_time': '',
                                        'end_time': '',
                                        'cacheList': []
                                    }
                                ])
                                setAtomicData([
                                    {
                                        'value': [],
                                        'short_chain':'',
                                        'atomicDimension':[]
                                    }
                                ])
                                setComparsionPurpose('')
                                setSameIndex(0)
                                setSelectedAtomicData([])
                                currentMissCache = 0
                                setMissFlag(0)
                                setModelType(e.target.value);
                            }}>
                                <Radio value="明细模版">{"明细模版"}</Radio>
                                <Radio value="分组模版">{"分组模版"}</Radio>
                                <Radio value="对比方案模版" disabled>{"对比方案模版"}</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {
                            modelType == '明细模版' ?
                                <div>
                                    <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                        <Text strong>选择数据指标:</Text>
                                        <div style={{ display: 'grid', gridTemplateColumns: "300px 400px" }}>
                                            <Radio.Group value={isExitDetailTemplate} onChange={(e) => {
                                                if (e.target.value == 1) {
                                                    setDataTime([
                                                        {
                                                            'type': 1,
                                                            'data': '',
                                                            'short_chain': '',
                                                            'start_time': '',
                                                            'end_time': '',
                                                            'cacheList': []
                                                        }
                                                    ])
                                                } else {
                                                    setDataTime([
                                                        {
                                                            'type': 2,
                                                            'data': '',
                                                            'short_chain': '',
                                                            'start_time': '',
                                                            'end_time': '',
                                                            'cacheList': []
                                                        }
                                                    ])
                                                }
                                                setDetailSelectedIndicator([])
                                                setComparsionPurpose('')
                                                setSameIndex(0)
                                                currentMissCache = 0
                                                setMissFlag(0)
                                                setDetailModelSelectValue('')
                                                setDetailSelectedIndicator([])
                                                setIsExitDetailTemplate(e.target.value)
                                            }}>
                                                <Radio value={1}>{"选择明细模版"}</Radio>
                                                <Radio value={2}>{"自定义指标"}</Radio>
                                            </Radio.Group>
                                            {
                                                isExitDetailTemplate == 1 ?
                                                    <Select showSearch value={detailModelSelectValue} onChange={(value) => {
                                                        // console.log(value.split(","));
                                                        // console.log(value.split(",")[1]);
                                                        setDetailModelSelectValue(value)
                                                        setDetailModelId(value.split(",")[1])

                                                    }}>
                                                        {
                                                            detailModelList.map(item => {
                                                                return <Option value={item.name + ',' + item.id}>{item.name}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                    :
                                                    allBusinessStaffDate.length == 0 ?
                                                        <Tooltip placement="topLeft" title="指标数据加载中....." color="cyan">
                                                            <Button type="primary" style={{ width: 100 }} disabled={true}>自定义指标</Button>
                                                        </Tooltip>
                                                        :
                                                        <Button type="primary" style={{ width: 100 }} onClick={() => {
                                                            setDetailDataTemplateSearch(true)
                                                        }}>自定义指标</Button>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ display: detailSelectedIndicator.length == 0 ? 'none' : 'block' }}>
                                        <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 3 }}>
                                            <Text strong>已选指标:</Text>
                                            <div style={{ display: 'flex', width: 700, flexWrap: "wrap" }}>
                                                {
                                                    detailSelectedIndicator.map((item, index) => {
                                                        return <div style={{
                                                            margin: "3px",
                                                            fontSize: '13px',
                                                            border: " 1px dashed #cccccc",
                                                            borderRadius: '4px',
                                                            padding: "0 8px",
                                                            lineHeight: "30px", color: "#666666",
                                                            backgroundColor: "rgba(255, 255, 255, 0.7);",
                                                            whiteSpace: 'nowrap'
                                                        }}>{item.name}</div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 3 }}>
                                            {
                                                isExitDetailTemplate == 1 ? <Text strong>选择缓存/时间:</Text>
                                                    : <Text strong>选择时间:</Text>
                                            }
                                            {
                                                isExitDetailTemplate == 1 ?
                                                    <div>
                                                        {
                                                            dataTime.map((item, index) => {
                                                                return <div style={{ display: 'grid', gridTemplateColumns: "300px 400px", marginBottom: 5 }}>
                                                                    <Radio.Group value={item.type} onChange={(e) => {
                                                                        let obj = [...dataTime]
                                                                        obj[index]['type'] = e.target.value
                                                                        obj[index]['data'] = ''
                                                                        setDataTime(obj);
                                                                    }}>
                                                                        <Radio value={1}>{"选择缓存"}</Radio>
                                                                        <Radio value={2}>{"自定义时间"}</Radio>
                                                                    </Radio.Group>
                                                                    {
                                                                        item.type == 1 ? <div style={{ display: 'flex' }}>
                                                                            <Select style={{ width: 400 }} showSearch value={item.data} onChange={(value) => {
                                                                                let flag = 1
                                                                                dataTime.map(item => {
                                                                                    if (item.type == 1) {
                                                                                        if (item.data == value) {
                                                                                            flag = 0
                                                                                        }
                                                                                    }
                                                                                })
                                                                                if (flag) {
                                                                                    let obj = [...dataTime]
                                                                                    obj[index]['data'] = value
                                                                                    obj[index]['short_chain'] = value.split(',')[1]
                                                                                    obj[index]['start_time'] = value.split(',')[2]
                                                                                    obj[index]['end_time'] = value.split(',')[3]
                                                                                    setDataTime(obj)
                                                                                } else {
                                                                                    message.warn('该缓存已被选择了')
                                                                                }

                                                                            }}>
                                                                                {
                                                                                    item.cacheList.map((item, index) => {
                                                                                        return <Option value={item.name + ',' + item.short_chain + ',' + item.start_time + ',' + item.end_time}>{item.name}</Option>
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                            <MinusCircleOutlined style={{ marginTop: 10, marginLeft: 10, display: dataTime.length == 1 ? 'none' : 'block' }} onClick={() => {
                                                                                let obj = [...dataTime]
                                                                                obj.splice(index, 1)
                                                                                setDataTime(obj)
                                                                            }} />
                                                                        </div>
                                                                            : <div style={{ display: 'flex' }}>
                                                                                <RangePicker disabledDate={disabledDate} locale={locale} value={item['start_time'] == '' ? ['', ''] : [moment(item['start_time'], dateFormat), moment(item['end_time'], dateFormat)]} onChange={(date, dateString) => {
                                                                                    if (dateString[0] == '') {
                                                                                        message.warn('请选择正确的时间')
                                                                                        return false
                                                                                    } else {
                                                                                        let obj = [...dataTime]
                                                                                        obj[index]['start_time'] = dateString[0]
                                                                                        obj[index]['end_time'] = dateString[1]
                                                                                        setDataTime(obj)
                                                                                    }
                                                                                }} />
                                                                                <MinusCircleOutlined style={{ marginTop: 10, marginLeft: 10, display: dataTime.length == 1 ? 'none' : 'block' }} onClick={() => {
                                                                                    let obj = [...dataTime]
                                                                                    obj.splice(index, 1)
                                                                                    setDataTime(obj)
                                                                                }} />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            })
                                                        }
                                                        <div style={{ width: '700px', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                                            {
                                                                dataTime.length < 2 ? <Button icon={<PlusOutlined />} type="dashed" style={{ width: '300px' }} onClick={() => {
                                                                    let cacheList = [...detailCacheList]
                                                                    let currentCacheList = []
                                                                    dataTime.map(item => {
                                                                        if (item.type == 1) {
                                                                            // todo 对象删除还没有处理好
                                                                            cacheList.map(cacheListItem => {
                                                                                if (cacheListItem.short_chain != item.short_chain) {
                                                                                    currentCacheList.push(cacheListItem)
                                                                                }
                                                                            })
                                                                        } else {
                                                                            currentCacheList = cacheList
                                                                        }
                                                                    })
                                                                    let obj = [...dataTime]
                                                                    let addObj = {
                                                                        'type': 1,
                                                                        'data': '',
                                                                        'short_chain': '',
                                                                        'start_time': '',
                                                                        'end_time': '',
                                                                        'cacheList': currentCacheList
                                                                    }
                                                                    obj.push(addObj)
                                                                    setDataTime(obj)
                                                                }}>添加分组</Button> : <span></span>
                                                            }

                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        {
                                                            dataTime.map((item, index) => {
                                                                return <div style={{ marginBottom: 5 }}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <RangePicker disabledDate={disabledDate} locale={locale} value={item['start_time'] == '' ? ['', ''] : [moment(item['start_time'], dateFormat), moment(item['end_time'], dateFormat)]} onChange={(date, dateString) => {
                                                                            if (dateString[0] == '') {
                                                                                message.warn('请选择正确的时间')
                                                                                return false
                                                                            } else {
                                                                                let obj = [...dataTime]
                                                                                obj[index]['start_time'] = dateString[0]
                                                                                obj[index]['end_time'] = dateString[1]
                                                                                setDataTime(obj)
                                                                            }
                                                                        }} />
                                                                        <MinusCircleOutlined style={{ marginTop: 10, marginLeft: 10, display: dataTime.length == 1 ? 'none' : 'block' }} onClick={() => {
                                                                            let obj = [...dataTime]
                                                                            obj.splice(index, 1)
                                                                            setDataTime(obj)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                        <div style={{ width: '700px', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                                            {
                                                                dataTime.length < 2 ? <Button icon={<PlusOutlined />} type="dashed" style={{ width: '300px' }} onClick={() => {
                                                                    let cacheList = [...detailCacheList]
                                                                    let currentCacheList = []
                                                                    dataTime.map(item => {
                                                                        if (item.type == 1) {
                                                                            // todo 对象删除还没有处理好
                                                                            cacheList.map(cacheListItem => {
                                                                                if (cacheListItem.short_chain != item.short_chain) {
                                                                                    currentCacheList.push(cacheListItem)
                                                                                }
                                                                            })
                                                                        } else {
                                                                            currentCacheList = cacheList
                                                                        }
                                                                    })
                                                                    let obj = [...dataTime]
                                                                    let addObj = {
                                                                        'type': 2,
                                                                        'data': '',
                                                                        'short_chain': '',
                                                                        'start_time': '',
                                                                        'end_time': '',
                                                                        'cacheList': currentCacheList
                                                                    }
                                                                    obj.push(addObj)
                                                                    setDataTime(obj)
                                                                }}>添加分组</Button> : <span></span>
                                                            }

                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                            <Text strong>交并差计算:</Text>
                                            <Checkbox value={sameIndex} onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSameIndex(1)
                                                } else {
                                                    setSameIndex(0)
                                                }
                                            }}>只对比具有相同索引的数据（交计算）</Checkbox>
                                        </div>
                                        <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                            <Text strong>呈现方式:</Text>
                                            <div style={{ width: 700 }}>
                                                <Row>
                                                    {
                                                        detailPresentWays.map(item => {
                                                            return <Col span={8}>
                                                                <Checkbox value={selectedPresentWays.indexOf(item.key) != -1 ? true : false} onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        let obj = [...selectedPresentWays]
                                                                        obj.push(item.key)
                                                                        setSelectedPresentWays(obj)
                                                                    } else {
                                                                        let obj = [...selectedPresentWays]
                                                                        obj.deleteElementByValue(item.key)
                                                                        setSelectedPresentWays(obj)
                                                                    }
                                                                }}>{item.name}</Checkbox>
                                                            </Col>
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                            {
                                                selectedPresentWays.indexOf('旭日图') != -1 ?
                                                    <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                        <Text strong>旭日图比较指标:</Text>
                                                        <Select style={{ width: 400 }} showSearch value={comparsionOfSunburst} onChange={(value) => {
                                                            setComparsionOfSunburst(value)
                                                        }}>
                                                            {
                                                                statisticalIndicators.map(item => {
                                                                    return <Option value={item.name}>{item.name}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </div> : <span></span>
                                            }
                                        </div>

                                        <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: 3 }}>
                                                    <span style={{ color: "red", fontSize: 20 }}>*</span>
                                                </div>
                                                <Text strong>对比目的:</Text>
                                            </div>
                                            <Input value={comparsionPurpose} style={{ width: 700 }} onChange={(e) => {
                                                setComparsionPurpose(e.target.value)
                                            }} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                            <Button type="primary" style={{ width: 200 }} onClick={() => {
                                                if (comparsionPurpose != '') {
                                                    // 判断是否有两组数据
                                                    let key = "dataComparsion"
                                                    if (dataTime.length == 2) {
                                                        message.loading({ content: '正在判断两组数据选择的时间是否正确...', key, duration: 100 })
                                                        // 判断是否选择了正确的时间或者缓存
                                                        let timeFlag = 1
                                                        let missList = []
                                                        dataTime.map((item, index) => {
                                                            if (item.start_time != '') {
                                                                if (item.short_chain == '') {
                                                                    missList.push(item)
                                                                }
                                                            } else {
                                                                timeFlag = 0
                                                            }

                                                        })
                                                        if (timeFlag) {
                                                            setMissList(missList)
                                                            checkDataTimeInterval(dataTime)
                                                        } else {
                                                            message.warn({ content: '请选择正确缓存或者时间...', key })
                                                        }
                                                    } else {
                                                        message.warn({ content: '请选择两组缓存或者时间...', key })
                                                    }
                                                } else {
                                                    message.warn('请先填写对比目的...')
                                                }


                                            }}>对比数据</Button>
                                            {/* {
                                           missFlag == 0 ? <Button type="primary" onClick={()=>{
                                                if(dataTime.length == 2){
                                                    let timeFlag = 1
                                                    let missList = []
                                                    dataTime.map((item, index)=>{
                                                        
                                                        if(item.start_time != ''){
                                                            if(item.short_chain == ''){
                                                                missList.push(item)
                                                            }
                                                        }else{
                                                            timeFlag = 0
                                                        }
                                                        
                                                    }) 
                                                   if(timeFlag){
                                                        dataTime.map((item, index)=>{
                                                            if(item.short_chain == ''){
                                                                checkDetailDataCache(item, index, missList.length);
                                                            }else{
                                                                setMissFlag(1)
                                                            }
                                                        })
                                                   }else{
                                                        message.warn('请正确选择缓存或者时间') 
                                                   }
                                                }else{
                                                    message.warn('请选择两组缓存或者时间')
                                                }
                                               
                                           }}>校验是否存在明细缓存</Button>
                                           : <Button type="primary" onClick={()=>{
                                               console.log(dataTime);
                                           }}>对比数据</Button>
                                       } */}
                                        </div>
                                    </div>

                                </div>
                                :
                                modelType == '分组模版' ?
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                            <Text strong>选择分组模版:</Text>
                                            <div style={{ display: 'grid', gridTemplateColumns: "300px 400px" }}>
                                                <Radio.Group value={isExitGroupTemplate} onChange={(e) => {
                                                    setDataTime([
                                                        {
                                                            'type': 1,
                                                            'data': '',
                                                            'cacheList': ['缓存1', '缓存2', '缓存3']
                                                        }
                                                    ])
                                                    setAtomicData([
                                                        {
                                                            'value': [],
                                                            'short_chain':'',
                                                            'atomicDimension':[]
                                                        }
                                                    ])
                                                    setSelectedAtomicData([])
                                                    setGroupModelSelectValue('')
                                                    setGroupModelId('')
                                                    setIsExitGroupTemplate(e.target.value);
                                                }}>
                                                    <Radio value={1}>{"选择分组模版"}</Radio>
                                                    <Radio value={0} disabled>{"自定义分组模版"}</Radio>
                                                </Radio.Group>
                                                <div>
                                                    {
                                                        isExitGroupTemplate == 1 ?
                                                            <Select style={{ width: 300 }} showSearch value={groupModelSelectValue} onChange={(value) => {
                                                                setGroupModelSelectValue(value)
                                                                setGroupModelId(value.split(',')[1])
                                                                setGroupTemplateId(value.split(',')[2])
                                                            }}>
                                                                {
                                                                    groupModelList.map(item => {
                                                                        return <Option value={item.name + ',' + item.id + ',' + item.template_id}>{item.name}</Option>
                                                                    })
                                                                }
                                                            </Select>
                                                            : <span></span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            isExitGroupTemplate == 0 ?
                                                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                    <Text strong>选择数据指标:</Text>
                                                    <div style={{ display: 'grid', gridTemplateColumns: "300px 400px" }}>
                                                        <Radio.Group value={isExitDetailTemplate} onChange={(e) => {
                                                            setDataTime([
                                                                {
                                                                    'type': 1,
                                                                    'data': '',
                                                                    'cacheList': ['缓存1', '缓存2', '缓存3']
                                                                }
                                                            ])
                                                            setAtomicData([
                                                                {
                                                                    'value': [],
                                                                    'short_chain':'',
                                                                    'atomicDimension':[]
                                                                }
                                                            ])
                                                            setSelectedAtomicData([])
                                                            setIsExitDetailTemplate(e.target.value)
                                                        }}>
                                                            <Radio value={1}>{"选择明细模版"}</Radio>
                                                            <Radio value={2}>{"自定义指标"}</Radio>
                                                        </Radio.Group>
                                                        {
                                                            isExitDetailTemplate == 1 ?
                                                                <Select showSearch value={modelId} onChange={(value) => {
                                                                    setModelId(value)
                                                                }}>
                                                                    {
                                                                        modelList.map(item => {
                                                                            return <Option value={item}>{item}</Option>
                                                                        })
                                                                    }
                                                                </Select>
                                                                :
                                                                allBusinessStaffDate.length == 0 ?
                                                                    <Tooltip placement="topLeft" title="指标数据加载中....." color="cyan">
                                                                        <Button type="primary" style={{ width: 100 }} disabled={true}>自定义指标</Button>
                                                                    </Tooltip>
                                                                    :
                                                                    <Button type="primary" style={{ width: 100 }} onClick={() => {
                                                                        setDetailDataTemplateSearch(true)
                                                                    }}>自定义指标</Button>
                                                        }
                                                    </div>
                                                </div>
                                                : <span></span>
                                        }
                                        <div style={{ display: groupDetailSelectedIndicator.length == 0 ? 'none' : 'block' }}>
                                            <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 3 }}>
                                                <Text strong>已选指标:</Text>
                                                <div style={{ width:600, display: 'flex', flexWrap:"wrap" }}>
                                                    {
                                                        groupDetailSelectedIndicator.map((item, index) => {
                                                            return <div style={{
                                                                margin: "3px",
                                                                fontSize: '13px',
                                                                border: " 1px dashed #cccccc",
                                                                borderRadius: '4px',
                                                                padding: "0 8px",
                                                                lineHeight: "30px", color: "#666666",
                                                                backgroundColor: "rgba(255, 255, 255, 0.7);",
                                                                whiteSpace: 'nowrap'
                                                            }}>{item.name}</div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {
                                                isExitGroupTemplate == 0 ?
                                                    <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 3 }}>
                                                        {
                                                            isExitDetailTemplate == 1 ? <Text strong>选择明细缓存/时间:</Text>
                                                                : <Text strong>选择时间:</Text>
                                                        }
                                                        {
                                                            isExitDetailTemplate == 1 ?
                                                                <div>
                                                                    {
                                                                        dataTime.map((item, index) => {
                                                                            return <div style={{ display: 'grid', gridTemplateColumns: "300px 400px", marginBottom: 5 }}>
                                                                                <Radio.Group value={item.type} onChange={(e) => {
                                                                                    let obj = [...dataTime]
                                                                                    obj[index]['type'] = e.target.value
                                                                                    obj[index]['data'] = ''
                                                                                    setDataTime(obj);
                                                                                }}>
                                                                                    <Radio value={1}>{"选择明细缓存"}</Radio>
                                                                                    <Radio value={2}>{"自定义时间"}</Radio>
                                                                                </Radio.Group>
                                                                                {
                                                                                    item.type == 1 ? <div style={{ display: 'flex' }}>
                                                                                        <Select style={{ width: 400 }} showSearch value={item.data} onChange={(value) => {
                                                                                            let flag = 1
                                                                                            dataTime.map(item => {
                                                                                                if (item.type == 1) {
                                                                                                    if (item.data == value) {
                                                                                                        flag = 0
                                                                                                    }
                                                                                                }
                                                                                            })
                                                                                            if (flag) {
                                                                                                let obj = [...dataTime]
                                                                                                obj[index]['data'] = value
                                                                                                setDataTime(obj)
                                                                                            } else {
                                                                                                message.warn('该缓存已被选择了')
                                                                                            }

                                                                                        }}>
                                                                                            {
                                                                                                item.cacheList.map(item => {
                                                                                                    return <Option value={item}>{item}</Option>
                                                                                                })
                                                                                            }
                                                                                        </Select>
                                                                                        <MinusCircleOutlined style={{ marginTop: 10, marginLeft: 10, display: dataTime.length == 1 ? 'none' : 'block' }} onClick={() => {
                                                                                            let obj = [...dataTime]
                                                                                            obj.splice(index, 1)
                                                                                            setDataTime(obj)
                                                                                            let atomicDataObj = [...atomicData]
                                                                                            atomicDataObj.indexRemove(index)
                                                                                            setAtomicData(atomicDataObj)
                                                                                        }} />
                                                                                    </div>
                                                                                        : <div style={{ display: 'flex' }}>
                                                                                            <DatePicker value={item.data == '' ? '' : moment(item.data, dateFormat)} onChange={(date, dateString) => {
                                                                                                if (dateString == '') {
                                                                                                    message.warn('请选择正确的时间')
                                                                                                    return false
                                                                                                } else {
                                                                                                    let obj = [...dataTime]
                                                                                                    obj[index]['data'] = dateString
                                                                                                    setDataTime(obj)
                                                                                                }
                                                                                            }} />
                                                                                            <MinusCircleOutlined style={{ marginTop: 10, marginLeft: 10, display: dataTime.length == 1 ? 'none' : 'block' }} onClick={() => {
                                                                                                let obj = [...dataTime]
                                                                                                obj.splice(index, 1)
                                                                                                setDataTime(obj)
                                                                                                let atomicDataObj = [...atomicData]
                                                                                                atomicDataObj.indexRemove(index)
                                                                                                setAtomicData(atomicDataObj)
                                                                                            }} />
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        })
                                                                    }
                                                                    <div style={{ width: '700px', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                                                        {
                                                                            dataTime.length < 2 ? <Button icon={<PlusOutlined />} type="dashed" style={{ width: '300px' }} onClick={() => {
                                                                                let currentCacheList = [...cacheList]
                                                                                dataTime.map(item => {
                                                                                    if (item.type == 1) {
                                                                                        currentCacheList.deleteElementByValue(item.data)
                                                                                    }
                                                                                })
                                                                                let obj = [...dataTime]
                                                                                let addObj = {
                                                                                    'type': 1,
                                                                                    'data': '',
                                                                                    'cacheList': currentCacheList
                                                                                }
                                                                                obj.push(addObj)
                                                                                setDataTime(obj)
                                                                                let atomicDataObj = [...atomicData]
                                                                                let addAtomicDataObj = {
                                                                                    'value': [],
                                                                                    'short_chain':'',
                                                                                    'atomicDimension':[]
                                                                                }
                                                                                atomicDataObj.push(addAtomicDataObj)
                                                                                setAtomicData(atomicDataObj)
                                                                            }}>添加分组</Button> : <span></span>
                                                                        }

                                                                    </div>
                                                                </div>
                                                                :
                                                                <div>
                                                                    {
                                                                        dataTime.map((item, index) => {
                                                                            return <div style={{ marginBottom: 5 }}>
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <DatePicker value={item.data == '' ? '' : moment(item.data, dateFormat)} onChange={(date, dateString) => {
                                                                                        if (dateString == '') {
                                                                                            message.warn('请选择正确的时间')
                                                                                            return false
                                                                                        } else {
                                                                                            let obj = [...dataTime]
                                                                                            obj[index]['data'] = dateString
                                                                                            setDataTime(obj)
                                                                                        }
                                                                                    }} />
                                                                                    <MinusCircleOutlined style={{ marginTop: 10, marginLeft: 10 }} onClick={() => {
                                                                                        let obj = [...dataTime]
                                                                                        obj.splice(index, 1)
                                                                                        setDataTime(obj)
                                                                                        let atomicDataObj = [...atomicData]
                                                                                        atomicDataObj.indexRemove(index)
                                                                                        setAtomicData(atomicDataObj)
                                                                                    }} />
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                    <div style={{ width: '700px', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                                                        {
                                                                            dataTime.length < 2 ? <Button icon={<PlusOutlined />} type="dashed" style={{ width: '300px' }} onClick={() => {
                                                                                let currentCacheList = [...cacheList]
                                                                                dataTime.map(item => {
                                                                                    if (item.type == 1) {
                                                                                        currentCacheList.deleteElementByValue(item.data)
                                                                                    }
                                                                                })
                                                                                let obj = [...dataTime]
                                                                                let addObj = {
                                                                                    'type': 1,
                                                                                    'data': '',
                                                                                    'cacheList': currentCacheList
                                                                                }
                                                                                obj.push(addObj)
                                                                                setDataTime(obj)
                                                                                let atomicDataObj = [...atomicData]
                                                                                let addAtomicDataObj =  {
                                                                                    'value': [],
                                                                                    'short_chain':'',
                                                                                    'atomicDimension':[]
                                                                                }
                                                                                atomicDataObj.push(addAtomicDataObj)
                                                                                setAtomicData(atomicDataObj)
                                                                            }}>添加分组</Button> : <span></span>
                                                                        }

                                                                    </div>
                                                                </div>
                                                        }
                                                    </div> : <span></span>
                                            }
                                            {
                                                isExitGroupTemplate == 0 ?
                                                    <div>
                                                        <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                            <Text strong>自定义分组逻辑:</Text>
                                                            <Button type="primary" style={{ width: 130 }}>定义分组逻辑</Button>
                                                        </div>
                                                    </div>
                                                    : <span></span>
                                            }
                                            <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 3 }}>
                                                <Text strong>已选分组逻辑:</Text>
                                                <div>
                                                    <div style={{ display: 'grid', gridTemplateColumns: "80px 1fr", marginTop: 3 }}>
                                                        <Text strong>分组维度:</Text>
                                                        <div style={{ display: 'flex' }}>
                                                            {
                                                                groupDimensions.map((item, index) => {
                                                                    return <div style={{
                                                                        margin: "3px",
                                                                        fontSize: '13px',
                                                                        border: " 1px dashed #cccccc",
                                                                        borderRadius: '4px',
                                                                        padding: "0 8px",
                                                                        lineHeight: "30px", color: "#666666",
                                                                        backgroundColor: "rgba(255, 255, 255, 0.7);",
                                                                        whiteSpace: 'nowrap'
                                                                    }}>{item.name}</div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'grid', gridTemplateColumns: "80px 1fr", marginTop: 3 }}>
                                                        <Text strong>统计指标:</Text>
                                                        <div style={{ display: 'flex' }}>
                                                            {
                                                                statisticalIndicators.map((item, index) => {
                                                                    return <div style={{
                                                                        margin: "3px",
                                                                        fontSize: '13px',
                                                                        border: " 1px dashed #cccccc",
                                                                        borderRadius: '4px',
                                                                        padding: "0 8px",
                                                                        lineHeight: "30px", color: "#666666",
                                                                        backgroundColor: "rgba(255, 255, 255, 0.7);",
                                                                        whiteSpace: 'nowrap'
                                                                    }}>{item.name}</div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isExitGroupTemplate == 1 ?
                                                    <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 3 }}>
                                                        <Text strong>选择分组缓存/时间:</Text>
                                                        <div>
                                                            {
                                                                dataTime.map((item, index) => {
                                                                    return <div style={{ display: 'grid', gridTemplateColumns: "300px 400px", marginBottom: 5 }}>
                                                                        <Radio.Group value={item.type} onChange={(e) => {
                                                                            let obj = [...dataTime]
                                                                            obj[index]['type'] = e.target.value
                                                                            obj[index]['data'] = ''
                                                                            setDataTime(obj);
                                                                        }}>
                                                                            <Radio value={1}>{"选择分组缓存"}</Radio>
                                                                            <Radio value={2}>{"自定义时间"}</Radio>
                                                                        </Radio.Group>
                                                                        {
                                                                            item.type == 1 ? <div style={{ display: 'flex' }}>
                                                                                <Select style={{ width: 400 }} showSearch value={item.data} onChange={(value) => {
                                                                                    let flag = 1
                                                                                    dataTime.map(item => {
                                                                                        if (item.type == 1) {
                                                                                            if (item.data == value) {
                                                                                                flag = 0
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                    if (flag) {
                                                                                        let obj = [...dataTime]
                                                                                        obj[index]['data'] = value
                                                                                        obj[index]['short_chain'] = value.split(',')[1]
                                                                                        obj[index]['start_time'] = value.split(',')[2]
                                                                                        obj[index]['end_time'] = value.split(',')[3]
                                                                                        queryAtomicTable(value.split(',')[1])
                                                                                        setDataTime(obj)
                                                                                    } else {
                                                                                        message.warn('该缓存已被选择了')
                                                                                    }

                                                                                }}>
                                                                                    {
                                                                                        item.cacheList.map(item => {
                                                                                            return <Option value={item.name + ',' + item.short_chain + ',' + item.start_time + ',' + item.end_time}>{item.name}</Option>
                                                                                        })
                                                                                    }
                                                                                </Select>
                                                                                <MinusCircleOutlined style={{ marginTop: 10, marginLeft: 10, display: dataTime.length == 1 ? 'none' : 'block' }} onClick={() => {
                                                                                    let obj = [...dataTime]
                                                                                    obj.splice(index, 1)
                                                                                    setDataTime(obj)
                                                                                    let atomicDataObj = [...atomicData]
                                                                                    atomicDataObj.indexRemove(index)
                                                                                    setAtomicData(atomicDataObj)
                                                                                }} />
                                                                            </div>
                                                                                : <div style={{ display: 'flex' }}>
                                                                                    <RangePicker disabledDate={disabledDate} locale={locale} value={item['start_time'] == '' ? ['', ''] : [moment(item['start_time'], dateFormat), moment(item['end_time'], dateFormat)]} onChange={(date, dateString) => {
                                                                                        if (dateString[0] == '') {
                                                                                            message.warn('请选择正确的时间')
                                                                                            return false
                                                                                        } else {
                                                                                            let obj = [...dataTime]
                                                                                            obj[index]['start_time'] = dateString[0]
                                                                                            obj[index]['end_time'] = dateString[1]
                                                                                            setDataTime(obj)
                                                                                        }
                                                                                    }} />
                                                                                    <Button disabled={effective == 1 ? true : false} type="dashed" style={{marginLeft:5}} onClick={()=>{
                                                                                        setEffective(1)
                                                                                        checkGroupDetailDataCache(dataTime[index]['start_time'], dataTime[index]['end_time'])
                                                                                    }}>确定</Button>
                                                                                    <MinusCircleOutlined style={{ marginTop: 10, marginLeft: 10, display: dataTime.length == 1 ? 'none' : 'block' }} onClick={() => {
                                                                                        let obj = [...dataTime]
                                                                                        obj.splice(index, 1)
                                                                                        setDataTime(obj)
                                                                                        let atomicDataObj = [...atomicData]
                                                                                        atomicDataObj.indexRemove(index)
                                                                                        setAtomicData(atomicDataObj)
                                                                                    }} />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                })
                                                            }
                                                            <div style={{ width: '700px', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                                                {
                                                                    dataTime.length < 2 ? <Button icon={<PlusOutlined />} type="dashed" style={{ width: '300px' }} onClick={() => {
                                                                        let cacheList = [...groupCacheList]
                                                                        let currentCacheList = []
                                                                        dataTime.map(item => {
                                                                            if (item.type == 1) {
                                                                                // todo 对象删除还没有处理好
                                                                                cacheList.map(cacheListItem => {
                                                                                    if (cacheListItem.short_chain != item.short_chain) {
                                                                                        currentCacheList.push(cacheListItem)
                                                                                    }
                                                                                })
                                                                            } else {
                                                                                currentCacheList = cacheList
                                                                            }
                                                                        })
                                                                        let obj = [...dataTime]
                                                                        let addObj = {
                                                                            'type': 1,
                                                                            'data': '',
                                                                            'short_chain': '',
                                                                            'start_time': '',
                                                                            'end_time': '',
                                                                            'cacheList': currentCacheList
                                                                        }
                                                                        obj.push(addObj)
                                                                        setDataTime(obj)
                                                                        let atomicDataObj = [...atomicData]
                                                                        let addAtomicDataObj =  {
                                                                            'value': [],
                                                                            'short_chain':'',
                                                                            'atomicDimension':[]
                                                                        }
                                                                        atomicDataObj.push(addAtomicDataObj)
                                                                        setAtomicData(atomicDataObj)
                                                                    }}>添加分组</Button> : <span></span>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <span></span>
                                            }

                                            <div>
                                                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                    <Text strong>选择对比维度:</Text>
                                                    <div>
                                                        {
                                                            atomicData.map((atomicItem, atomicIndex) => {
                                                                return <div style={{ border: '1px solid #ccc', marginTop: 5 }}>
                                                                    {
                                                                        atomicItem.atomicDimension.map((item, index) => {
                                                                            return <div style={{ display: 'grid', gridTemplateColumns: "80px 1fr", }}>
                                                                                <Text strong>{"第" + (index + 1) + "维度"}</Text>
                                                                                <div>
                                                                                    {
                                                                                        item.atomic.map(i => {
                                                                                            return <Checkbox
                                                                                                value={i}
                                                                                                checked={atomicItem['value'].indexOf(i) != -1 ? true : false}
                                                                                                onChange={(e) => {
                                                                                                    if (e.target.checked) {
                                                                                                        // 当点击的行不同的时候
                                                                                                        if (index != selectAtomicIndex) {
                                                                                                            let obj = [...atomicData]
                                                                                                            obj.map(item => {
                                                                                                                item.value = []
                                                                                                            })
                                                                                                            obj[atomicIndex]['value'].push(i)
                                                                                                            setAtomicData(obj)
                                                                                                            setSelectAtomicIndex(index)
                                                                                                        } else {
                                                                                                            let obj = [...atomicData]
                                                                                                            obj[atomicIndex]['value'] = []
                                                                                                            obj[atomicIndex]['value'].push(i)
                                                                                                            setAtomicData(obj)
                                                                                                        }
                                                                                                    } else {
                                                                                                        let obj = [...atomicData]
                                                                                                        obj[atomicIndex]['value'] = []
                                                                                                        setAtomicData(obj)
                                                                                                    }
                                                                                                }}
                                                                                            >{i}</Checkbox>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            })
                                                        }
                                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                                            <Button type="primary" disabled={atomicData.length == 2 ? atomicData[1]['value'] != '' && atomicData[0]['value'] != '' ? false : true : true} onClick={() => {
                                                                let selectedAtomicDataObj = [...selectedAtomicData]
                                                                let mes = atomicData[0]['value'] + ' 对比 ' + atomicData[1]['value']
                                                                let value1 = atomicData[0]['value']
                                                                let value2 = atomicData[1]['value']
                                                                let short_chain1 = atomicData[0]['short_chain']
                                                                let short_chain2 = atomicData[1]['short_chain']
                                                                let flag = 0
                                                                selectedAtomicData.map(item => {
                                                                    if (item.message == mes) {
                                                                        flag = 1
                                                                    }
                                                                })
                                                                if (flag) {
                                                                    message.warn('该维度对比已存在')
                                                                } else {
                                                                    let obj = {
                                                                        message: mes,
                                                                        value1:value1[0],
                                                                        value2:value2[0],
                                                                        short_chain1:short_chain1,
                                                                        short_chain2:short_chain2
                                                                    }
                                                                    selectedAtomicDataObj.push(obj)
                                                                    setSelectedAtomicData(selectedAtomicDataObj)
                                                                    let atomicDataObj = [...atomicData]
                                                                    atomicDataObj.map(item => {
                                                                        item.value = []
                                                                    })
                                                                    setAtomicData(atomicDataObj)
                                                                }

                                                            }}>确定添加该维度</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                    <Text strong>已选对比维度:</Text>
                                                    <div style={{ display: "flex" }}>
                                                        {
                                                            selectedAtomicData.map((item, index) => {
                                                                return <div style={{
                                                                    margin: "3px",
                                                                    fontSize: '13px',
                                                                    border: " 1px dashed #cccccc",
                                                                    borderRadius: '4px',
                                                                    padding: "0 8px",
                                                                    lineHeight: "30px", color: "#666666",
                                                                    backgroundColor: "rgba(255, 255, 255, 0.7);",
                                                                    whiteSpace: 'nowrap',
                                                                    display: 'flex'
                                                                }}>
                                                                    <span>{item.message}</span>
                                                                    <CloseCircleOutlined style={{ marginTop: 8, marginLeft: 3 }} onClick={() => {
                                                                        let obj = [...selectedAtomicData]
                                                                        obj.indexRemove(index)
                                                                        setSelectedAtomicData(obj)
                                                                    }} />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                    <Text strong>交并差计算:</Text>
                                                    <Checkbox>只对比具有相同索引的数据（交计算）</Checkbox>
                                                </div>
                                                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                    <Text strong>呈现方式:</Text>
                                                    <div style={{ width: 700 }}>
                                                        <Row>
                                                            {
                                                                groupPresentWays.map(item => {
                                                                    return <Col span={8}>
                                                                        <Checkbox value={selectedPresentWays.indexOf(item.key) != -1 ? true : false} onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                let obj = [...selectedPresentWays]
                                                                                obj.push(item.key)
                                                                                setSelectedPresentWays(obj)
                                                                            } else {
                                                                                let obj = [...selectedPresentWays]
                                                                                obj.deleteElementByValue(item.key)
                                                                                setSelectedPresentWays(obj)
                                                                            }
                                                                        }}>{item.name}</Checkbox>
                                                                    </Col>
                                                                })
                                                            }
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                <Text strong>对比目的:</Text>
                                                <Input value={comparsionPurpose} style={{ width: 700 }} onChange={(e) => {
                                                    setComparsionPurpose(e.target.value)
                                                }}/>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                                <Button disabled={effective == 1 ? true : false} type="primary" style={{ width: 200 }} onClick={()=>{
                                                    if(selectedAtomicData.length != 0){
                                                        // let short_chain1 = selectedAtomicData[0]['short_chain1']
                                                        // let short_chain2 = selectedAtomicData[0]['short_chain2']
                                                        let value_list1 = []
                                                        let value_list2 = []
                                                        selectedAtomicData.map(item=>{
                                                            value_list1.push(item.value1)
                                                            value_list2.push(item.value2)
                                                        })
                                                        // 两个valueList
                                                        // console.log(value_list1);
                                                        // console.log(value_list2);
                                                        // 分组模版id
                                                        // console.log(groupModelId);
                                                        if(comparsionPurpose != ''){
                                                            setEffective(1)
                                                            let cache_name_1 = ''
                                                            let cache_start_time_1 = ''
                                                            let cache_end_time_1 = ''
                                                            let cache_short_chain_1 = ''
                                                            let cache_name_2 = ''
                                                            let cache_start_time_2 = ''
                                                            let cache_end_time_2 = ''
                                                            let cache_short_chain_2 = ''
                                                            dataTime.map((item, index)=>{
                                                                if (index == 0) {
                                                                    let name = groupModelSelectValue.split(",")[0] + '-' + item.start_time + '-' + item.end_time
                                                                    cache_name_1 = name
                                                                    cache_start_time_1 = item.start_time
                                                                    cache_end_time_1 = item.end_time
                                                                    cache_short_chain_1 = item.short_chain
                                                                } else if (index == 1) {
                                                                    let name = groupModelSelectValue.split(",")[0] + '-' + item.start_time + '-' + item.end_time
                                                                    cache_name_2 = name
                                                                    cache_start_time_2 = item.start_time
                                                                    cache_end_time_2 = item.end_time
                                                                    cache_short_chain_2 = item.short_chain
                                                                }
                                                            })
                                                            let dataProps = {
                                                                "roleId": cookie.load('role'),
                                                                "shopList": cookie.load('shopList'),
                                                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                                                "type": 2,
                                                                "template_id": groupModelId,
                                                                "name": '',
                                                                "cache_name_1": cache_name_1,
                                                                "cache_start_time_1": cache_start_time_1,
                                                                "cache_end_time_1": cache_end_time_1,
                                                                "cache_short_chain_1": cache_short_chain_1,
                                                                "cache_name_2": cache_name_2,
                                                                "cache_start_time_2": cache_start_time_2,
                                                                "cache_end_time_2": cache_end_time_2,
                                                                "cache_short_chain_2": cache_short_chain_2,
                                                                "objective": comparsionPurpose,
                                                                "is_index": sameIndex,
                                                                "creator_name": cookie.load('name'),
                                                                "is_top": 0,
                                                                "value1": value_list1,
                                                                "value2":value_list2
                                                            }
                                                            NbAxios(dataProps, "POST", servicePath.createGroupStatisticsComparisonRecord).then(res => {
                                                                setEffective(0)
                                                                if (res.success) {
                                                                    message.success({ content: '数据对比方案创建成功' })
                                                                    window.location.href = `https://nb20.bmcsoft.cn/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison?selectedPresentWays=${JSON.stringify(selectedPresentWays)}&comparsionShortChain=${res.data['short_chain']}&comparison_type=2`
                                                                    // window.location.href(`/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison?selectedPresentWays=${JSON.stringify(selectedPresentWays)}&comparsionShortChain=${res.data['short_chain']}&comparison_type=2`)
                                                                    // props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison`, 
                                                                    // 'state': { 'selectedPresentWays': selectedPresentWays, 'comparsionShortChain': res.data['short_chain'], 'comparison_type': type } })
                                                                } else {
                                                                    message.warn(res.errorMsg)
                                                                }
                                                            })
                                                        }else{
                                                            message.warn('请先填写对比目的...')
                                                        }
                                                    }else{
                                                        message.warn('请先选择对比维度')
                                                    }
                                                   
                                                }}>对比数据</Button>
                                            </div>
                                        </div>
                                    </div>

                                    : modelType == '对比方案模版' ?
                                        <div>
                                            <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                <Text strong>选择方案名称:</Text>
                                                <div>
                                                    {
                                                        planData.map((item, index) => {
                                                            return <div style={{ marginBottom: 5, display: 'flex' }}>
                                                                <Select style={{ width: 400 }} showSearch value={item.value} onChange={(value) => {
                                                                    let flag = 1
                                                                    planData.map(item => {
                                                                        if (item.value == value) {
                                                                            flag = 0
                                                                        }
                                                                    })
                                                                    if (flag) {
                                                                        let obj = [...planData]
                                                                        obj[index]['value'] = value
                                                                        setDataTime(obj)
                                                                    } else {
                                                                        message.warn('该方案已被选择了')
                                                                    }
                                                                }}>
                                                                    {
                                                                        item.planList.map(i => {
                                                                            return <Option value={i}>{i}</Option>
                                                                        })
                                                                    }
                                                                </Select>
                                                                <MinusCircleOutlined style={{ marginTop: 10, marginLeft: 10, display: planData.length == 1 ? 'none' : 'block' }} onClick={() => {
                                                                    let obj = [...planData]
                                                                    obj.splice(index, 1)
                                                                    setPlanData(obj)
                                                                }} />
                                                            </div>
                                                        })
                                                    }
                                                    <div style={{ width: '400px', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                                        {
                                                            planData.length < 4 ? <Button icon={<PlusOutlined />} type="dashed" style={{ width: '300px' }} onClick={() => {
                                                                let currentPlanList = [...planList]
                                                                planData.map(item => {
                                                                    currentPlanList.deleteElementByValue(item.value)
                                                                })
                                                                let obj = [...planData]
                                                                let addObj = {
                                                                    'value': '',
                                                                    'planList': currentPlanList
                                                                }
                                                                obj.push(addObj)
                                                                setPlanData(obj)
                                                            }}>添加方案</Button> : <span></span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                <Text strong>交并差计算:</Text>
                                                <Checkbox>只对比具有相同索引的数据（交计算）</Checkbox>
                                            </div>
                                            <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                <Text strong>呈现方式:</Text>
                                                <div style={{ width: 700 }}>
                                                    <Row>
                                                        {
                                                            presentWays.map(item => {
                                                                return <Col span={8}>
                                                                    <Checkbox value={selectedPresentWays.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            let obj = [...selectedPresentWays]
                                                                            obj.push(item)
                                                                            setSelectedPresentWays(obj)
                                                                        } else {
                                                                            let obj = [...selectedPresentWays]
                                                                            obj.deleteElementByValue(item)
                                                                            setSelectedPresentWays(obj)
                                                                        }
                                                                    }}>{item}</Checkbox>
                                                                </Col>
                                                            })
                                                        }
                                                    </Row>
                                                </div>
                                            </div>
                                            {
                                                selectedPresentWays.indexOf('旭日图') != -1 ?
                                                    <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                        <Text strong>旭日图比较指标:</Text>
                                                        <Select style={{ width: 400 }} showSearch value={comparsionOfSunburst} onChange={(value) => {
                                                            setComparsionOfSunburst(value)
                                                        }}>
                                                            {
                                                                statisticalIndicators.map(item => {
                                                                    return <Option value={item.id}>{item.name}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </div> : <span></span>
                                            }
                                            <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                                <Text strong>对比目的:</Text>
                                                <Input style={{ width: 700 }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                                <Button type="primary" style={{ width: 200 }}>对比数据</Button>
                                            </div>
                                        </div> : <span></span>
                        }
                    </div>
                </div>
            </div>

            {/* 明细数据模版细节 */}
            <Drawer
                title="明细数据模版细节"
                visible={detailDataTemplateSearch}
                onClose={() => {
                    setDetailDataTemplateSearch(false)
                }}
                width={'80vw'}
            >
                <Spin spinning={isSpinning} tip="数据请求中.......">
                    <div style={{ display: detailDataTemplateTabKey == "指标选择" ? '' : 'none' }}>
                        {/* border:"1px solid #ccc", */}
                        <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 10 }}>
                            <div style={{ display: 'grid', gridTemplateColumns: "1fr 450px", backgroundColor: 'white' }}>
                                <div style={{ marginLeft: '20px', marginTop: 10, display: 'grid', gridTemplateColumns: "70px 1fr" }}>
                                    <b style={{ marginTop: 10 }}>已选指标:</b>
                                    <div >
                                        <DraggableArea
                                            className={style.indicatorContent}
                                            tags={selectedIndicator}
                                            render={({ tag, index }) => (
                                                <div style={{
                                                    margin: "3px",
                                                    fontSize: '13px',
                                                    border: " 1px dashed #cccccc",
                                                    borderRadius: '4px',
                                                    padding: "0 8px",
                                                    lineHeight: "30px", color: "#666666",
                                                    backgroundColor: "rgba(255, 255, 255, 0.7);",
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    <span>{tag.name}</span><CloseOutlined style={{ marginTop: 10, marginLeft: 13, display: JSON.stringify(fixedIndicator).indexOf(JSON.stringify(tag)) != -1 ? 'none' : '' }} onClick={() => {
                                                        let obj = [...selectedIndicator]
                                                        obj.remove(tag)
                                                        setSelectedIndicator(obj)
                                                        let arr = [...chosedList]
                                                        if (arr.indexOf(tag.id) != -1) {
                                                            arr.remove(tag.id)
                                                        }
                                                        setChosedList(arr)
                                                    }} />
                                                </div>
                                            )}
                                            onChange={tags => setSelectedIndicator(tags)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: 10 }}>
                                    <Button onClick={() => {
                                        setIsCreateDetailDataTemplate(true)
                                        // setDetailDataTemplateSearch(false)
                                        // setDetailSelectedIndicator(selectedIndicator)
                                    }} type="primary" disabled={templateIndicatorList.vequal(selectedIndicator) ? true : false}>新建明细数据模版</Button>
                                </div>
                            </div>
                            <div style={{ display: 'grid', height: 54, gridTemplateColumns: "1fr 400px", backgroundColor: 'white' }}>
                                <Tabs type="card" activeKey={tabSituationKey} style={{ backgroundColor: "#fff", height: 54 }} onChange={(key) => {
                                    setTabSituationKey(key)
                                }}>
                                    {
                                        searchItemDate.map(item => {
                                            return <TabPane tab={item.situation} key={item.situation}>
                                            </TabPane>
                                        })
                                    }
                                </Tabs>
                                <div style={{ display: 'flex' }}>
                                    {/* <Button type="primary" style={{ width: '80px', marginRight: '20px', marginTop: 10 }}>确定</Button>
                                <Button type="dashed" style={{ marginTop: 10 }}>生成模版</Button>
                                <Button type="link" style={{ marginTop: 10 }}>下载数据</Button> */}
                                </div>
                            </div>
                            <div style={{ height: 350 }}>
                                {
                                    tabSituationKey == '商品基础信息' ?
                                        searchItemDate[searchItemDate.findIndex(o => o.situation == tabSituationKey)]['date'].map(item => {
                                            return <div style={{ display: 'grid', gridTemplateColumns: "80px 1fr", marginTop: 5, marginLeft: 10 }}>
                                                <Text type="secondary">{item.name}</Text>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                    {
                                                        item.option.map(item => {
                                                            return <Col>
                                                                <Checkbox
                                                                    checked={JSON.stringify(selectedIndicator).indexOf(JSON.stringify(item)) != -1 ? true : false}
                                                                    value={item} style={{ width: 150, pointerEvents: JSON.stringify(fixedIndicator).indexOf(JSON.stringify(item)) != -1 ? 'none' : '', }} onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            let obj = [...selectedIndicator]
                                                                            obj.push(item)
                                                                            setSelectedIndicator(obj)
                                                                        } else {
                                                                            let obj = [...selectedIndicator]
                                                                            obj.remove(item)
                                                                            setSelectedIndicator(obj)
                                                                        }
                                                                    }}>{item.name}</Checkbox>
                                                            </Col>
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        }) :
                                        tabSituationKey == '生意参谋全量数据' ? <div style={{ width: '70vw', marginLeft: 20 }}>
                                            <Row>
                                                {
                                                    searchItemDate[searchItemDate.findIndex(o => o.situation == tabSituationKey)]['head'].map((item, index) => {
                                                        return index == 0 ?
                                                            <Popover content={searchContent}>
                                                                <Col span={2} style={{ border: "1px solid #ccc", textAlign: 'center', backgroundColor: '#97dcff' }}>{item}</Col>
                                                            </Popover> :
                                                            <Popover content={horizontallContent} trigger="hover" onMouseEnter={() => {
                                                                setChooseIndex(index)
                                                            }}>
                                                                <Col span={2} onMouseEnter={() => {
                                                                    setTitleIndex(index)
                                                                }} onMouseLeave={() => {
                                                                    setTitleIndex('')
                                                                }} style={{ border: "1px solid #ccc", textAlign: 'center', backgroundColor: titleIndex != 0 && index == titleIndex ? '#29b6f6' : '#c3ecff' }}>{item}</Col>
                                                            </Popover>
                                                    })
                                                }
                                            </Row>
                                            <div className={style.tableContent}>
                                                {
                                                    chooseBusinessStaffDate.map((item, item_index) => {
                                                        return <Row >
                                                            {
                                                                item.option.map((i, i_index) => {
                                                                    return <Col onMouseEnter={() => {
                                                                        setRowIndex(item_index);
                                                                        setTitleIndex(i_index)
                                                                    }} onMouseLeave={() => {
                                                                        setRowIndex(-1);
                                                                        setTitleIndex(-1)
                                                                    }} span={2} style={{
                                                                        border: "1px solid #ccc",
                                                                        textAlign: 'center',
                                                                        pointerEvents: i.disable == 0 ? 'none' : '',
                                                                        backgroundColor: (item_index == rowIndex && i_index < titleIndex && i_index != 0) || (i_index == titleIndex && item_index < rowIndex && i_index != 0) ? '#81d4fa' :
                                                                            (item_index == rowIndex && i_index == titleIndex) ? '#29b6f6' :
                                                                                (item_index == rowIndex && i_index == 0) ? '#29b6f6' :
                                                                                    i_index == 0 ? '#97dcff' :
                                                                                        item_index % 2 == 0 ? '#eee' : ''
                                                                        // chosedList.indexOf(i.id) != -1 ? '#29b6f6' : '',
                                                                    }}>{i_index == 0 ?
                                                                        <Popover content={verticalContent} trigger="hover" onMouseEnter={() => {
                                                                            setRowIndex(item_index);
                                                                        }}>
                                                                            <span>{i.name}</span>
                                                                        </Popover>
                                                                        : chosedList.indexOf(i.id) != -1 ? <span onClick={() => {
                                                                            if (chosedList.indexOf(i.id) == -1) {
                                                                                // 在选中的集合中不存在,添加新的数据
                                                                                let obj = [...selectedIndicator]
                                                                                obj.push(i)
                                                                                setSelectedIndicator(obj)
                                                                                let idObj = [...chosedList]
                                                                                idObj.push(i.id)
                                                                                setChosedList(idObj)
                                                                            } else {
                                                                                // 在选中的集合中存在,删除数据
                                                                                let obj = [...selectedIndicator]
                                                                                obj.remove(i)
                                                                                setSelectedIndicator(obj)
                                                                                let idObj = [...chosedList]
                                                                                idObj.remove(i.id)
                                                                                setChosedList(idObj)
                                                                            }
                                                                        }}>
                                                                            <IconFont type="icon-dagou" style={{ width: '100%', height: '100%', fontSize: 25 }} />
                                                                        </span>
                                                                            : i.disable == 0 ? <span>
                                                                                <IconFont type="icon-hr" style={{ width: '100%', height: '100%', fontSize: 30 }} />
                                                                            </span> : <span onClick={() => {
                                                                                if (chosedList.indexOf(i.id) == -1) {
                                                                                    // 在选中的集合中不存在,添加新的数据
                                                                                    let obj = [...selectedIndicator]
                                                                                    obj.push(i)
                                                                                    setSelectedIndicator(obj)
                                                                                    let idObj = [...chosedList]
                                                                                    idObj.push(i.id)
                                                                                    setChosedList(idObj)
                                                                                } else {
                                                                                    // 在选中的集合中存在,删除数据
                                                                                    let obj = [...selectedIndicator]
                                                                                    obj.remove(i)
                                                                                    setSelectedIndicator(obj)
                                                                                    let idObj = [...chosedList]
                                                                                    idObj.remove(i.id)
                                                                                    setChosedList(idObj)
                                                                                }
                                                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                        }</Col>
                                                                })
                                                            }
                                                        </Row>
                                                    })
                                                }
                                            </div>
                                        </div> : <span></span>
                                }
                            </div>
                        </div>
                    </div>
                </Spin>

            </Drawer>

            {/* 新建明细数据模版 */}
            <Modal
                title="新建明细数据模版"
                visible={isCreateDetailDataTemplate}
                centered={true}
                onCancel={() => {
                    setIsCreateDetailDataTemplate(false)
                }}
                footer={[]}
            >
                <div style={{ display: 'flex' }}>
                    <b style={{ marginRight: 20 }}>模版名字:</b>
                    <Input style={{ width: 300 }} value={detailDataTemplateName} onChange={(e) => {
                        setDetailDataTemplateName(e.target.value)
                    }} />
                </div>
                <Checkbox style={{ marginTop: 5 }} checked={periodicProduction == 1 ? true : false} onChange={(e) => {
                    if (e.target.checked) {
                        setPeriodicProduction(1)
                    } else {
                        setPeriodicProduction(0)
                    }
                }}>数据是否周期性生产</Checkbox>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 10 }}>
                    <Button type="primary" onClick={() => {
                        if (detailDataTemplateName == '') {
                            message.warn('请先填写模版名字')
                        } else {
                            let dataProps = {
                                "roleId": cookie.load('role'),
                                "shopList": cookie.load('shopList'),
                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                "is_cycle": periodicProduction,
                                "selected_indicator": selectedIndicator,
                                "name": detailDataTemplateName,
                                "choose_list": chosedList,
                                'creator_name': cookie.load('name'),
                                "shop": props.history.location.pathname.split('/')[2],
                                "is_temporary": 0,
                            }
                            NbAxios(dataProps, "POST", servicePath.createDetailDataTemplate).then(res => {
                                if (res.success) {
                                    message.success('成功生产数据提取模版')
                                    let selectValue = res.data['template_name'] + ',' + res.data['template_id']
                                    setDetailModelSelectValue(selectValue)
                                    setDetailModelId(res.data['template_id'])
                                    setDetailDataTemplateSearch(false)
                                    setIsCreateDetailDataTemplate(false)
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }

                    }}>确定</Button>
                    <Button type="primary" danger onClick={() => {
                        setIsCreateDetailDataTemplate(false)
                    }}>取消</Button>
                </div>
            </Modal>

            {/* 选择明细数据呈现方式 */}
            <Modal
                visible={openDetailPresentWays}
                title="选择明细数据呈现方式"
                centered={true}
                footer={[]}
                onCancel={() => {
                    setOpenDetailPresentWays(false)
                }}
                onOk={() => {
                    props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison`, 'state': { 'selectedPresentWays': selectedPresentWays, 'comparsionShortChain': comparsionShortChain, 'comparison_type': comparisonType } })
                }}
                okText="确定"
                cancelText="取消"
            >
                <Row>
                    {
                        detailPresentWays.map(item => {
                            return <Col span={8}>
                                <Checkbox value={selectedPresentWays.indexOf(item.key) != -1 ? true : false} onChange={(e) => {
                                    if (e.target.checked) {
                                        let obj = [...selectedPresentWays]
                                        obj.push(item.key)
                                        setSelectedPresentWays(obj)
                                    } else {
                                        let obj = [...selectedPresentWays]
                                        obj.deleteElementByValue(item.key)
                                        setSelectedPresentWays(obj)
                                    }
                                }}>{item.name}</Checkbox>
                            </Col>
                        })
                    }
                </Row>
                <div style={{display:'flex', justifyContent:'flex-end', marginTop:10}}>
                    {/* target="_blank" */}
                    <a href={`/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison?selectedPresentWays=${JSON.stringify(selectedPresentWays)}&comparsionShortChain=${comparsionShortChain}&comparison_type=${comparisonType}`} 
                    >
                        <Button type="primary">确定</Button>
                    </a>
                    <Button type="primary" danger style={{marginLeft:10}} onClick={()=>{
                        setOpenGroupPresentWays(false)
                    }}>取消</Button>
                </div>
            </Modal>

             {/* 选择分组统计数据呈现方式 */}
             <Modal
                visible={openGroupPresentWays}
                title="选择分组统计数据呈现方式"
                centered={true}
                footer={[]}
                onCancel={() => {
                    setOpenGroupPresentWays(false)
                }}
                onOk={() => {
                    // props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison`, 
                    // 'state': { 'selectedPresentWays': selectedPresentWays, 'comparsionShortChain': comparsionShortChain, 'comparison_type': comparisonType } })
                }}
                okText="确定"
                cancelText="取消"
            >
                <Row>
                    {
                        groupPresentWays.map(item => {
                            return <Col span={8}>
                                <Checkbox value={selectedPresentWays.indexOf(item.key) != -1 ? true : false} onChange={(e) => {
                                    if (e.target.checked) {
                                        let obj = [...selectedPresentWays]
                                        obj.push(item.key)
                                        setSelectedPresentWays(obj)
                                    } else {
                                        let obj = [...selectedPresentWays]
                                        obj.deleteElementByValue(item.key)
                                        setSelectedPresentWays(obj)
                                    }
                                }}>{item.name}</Checkbox>
                            </Col>
                        })
                    }
                </Row>
                <div style={{display:'flex', justifyContent:'flex-end', marginTop:10}}>
                    {/* target="_blank" */}
                    <a href={`/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison?selectedPresentWays=${JSON.stringify(selectedPresentWays)}&comparsionShortChain=${comparsionShortChain}&comparison_type=${comparisonType}`} 
                    >
                        <Button type="primary">确定</Button>
                    </a>
                    <Button type="primary" danger style={{marginLeft:10}} onClick={()=>{
                        setOpenGroupPresentWays(false)
                    }}>取消</Button>
                </div>
            </Modal>

        </div>
    )

}

export default Index