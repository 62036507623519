import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  message,
  Modal,
  Button,
  Spin,
  Table,
  Select,
  DatePicker,
  Popconfirm,
  Collapse,
  Tabs,
  Popover,
  Space,
  Progress,
  Upload,
} from "antd";
import {
  SearchOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import cookie from "react-cookies";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import servicePath from "../../config/apiUrl";
import NbAxios from "../../config/utils/nbAxios";
import AntdDragModal from "../../component/antdDragModel";
import style from "./index.module.css";
// #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 start
import axios from "axios";
import downExcel from "../../config/utils/downloadFile";
// #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 start
// 引入高亮组件
import Highlighter from "react-highlight-words";
import AcceptBuried from "../../config/utils/buried";
import useDebounce from "../../hook/useDebounce";
const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { TextArea } = Input;
// #I69RTC NB20人资时间选择器去除时分秒 start
const dateFormat = "YYYY-MM-DD";
// #I69RTC NB20人资时间选择器去除时分秒end
function Management(props) {
  var tableDict = {
    estimate: "预估",
    budget: "预算",
    real: "实际",
  };
  const outDict = {
    0: "-",
    1: "是",
  };
  const modelDict = {
    0: "预估",
    1: "预算",
    2: "实际",
    estimate: 0,
    budget: 1,
    real: 2,
  };
  const fieldsDict = {
    员工姓名: "member_name",
    员工ID: "employeeId",
    月薪: "amount_of_month",
    法定节假日加班费用: "amount_of_statutory_holiday_break",
    电脑补贴: "amount_of_computer",
    加班补贴: "amount_of_overtime",
    客服晚班补贴: "amount_of_customer_service_night_shift",
    住宿补贴: "amount_of_accommodation",
    扣款: "amount_of_cut_payment",
    子女教育支出扣除: "amount_of_children_education",
    继续教育支出扣除: "amount_of_continuing_education",
    住房贷款利息支出扣除: "amount_of_deduction_of_housing_loan_interest",
    住房租金支出扣除: "amount_of_housing_rent",
    赡养老人支出扣除: "amount_of_support_the_elderly",
    通讯费: "amount_of_communications",
    婴幼儿照护费用: "baby_care",
    员工关怀: "worker_care",
    公积金开始缴纳时间: "accumulation_fund_start_time",
    社保缴纳地: "social_security_station",
    是否外包: "outsource",
    开始年份月份日期: "start_year_month_day",
    结束年份月份日期: "end_year_month_day",
    最高月薪: "max_amount_of_month",
    最低月薪: "min_amount_of_month",
    月薪判断系数: "rate_of_amount_of_month",
    公积金基数: "accumulation_fund_cardinal",
    社保基数: "social_security_cardinal",
    是否特殊: "special_status",
    公司: "company",
    公司简称: "company",
    社保缴纳系数: "rate_of_social_security",
    社保缴纳数: "amount_of_social_security",
    社保项目: "social_security_subject",
    社保最低基数: "min_social_security",
    社保最高基数: "max_social_security",
    公积金最低基数: "min_worker_accumulation",
    公积金最高基数: "max_worker_accumulation",
    staff: "employee",
    project: "project",
    pay: "薪酬",
    socialSecurity: "社保",
    accumulationFund: "公积金",
    personalIncomeTax: "个税",
    Form: "表单",
    calculation: "重新计算",
    sync: "数据同步",
  };
  // 类型
  const [calculation_type] = useState(
    props.history.location.pathname.split("/")[3]
  );
  /**
   * 获取指定date所在月的第一天
   * 指定时间date形式不限：2022-05-20、2022/05/20...
   */
  const getMonthFirst = (dateString) => {
    const firstDate = moment(dateString).startOf("month").format("YYYY-MM-DD");
    return firstDate;
  };

  /**
   * 获取指定date所在月的最后一天
   * 指定时间date形式不限：2022-05-20、2022/05/20...
   */
  const getMonthLast = (dateString) => {
    const lastDate = moment(dateString).endOf("month").format("YYYY-MM-DD");
    return lastDate;
  };

  // 递归拷贝(深拷贝)
  var deepCopy = function (source) {
    var result = {};
    for (var key in source) {
      result[key] =
        typeof source[key] === "object" &&
        source[key] !== null &&
        !Array.isArray(source[key])
          ? deepCopy(source[key])
          : source[key];
    }
    return result;
  };
  // 对象移除
  Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
      this.splice(index, 1);
    }
  };
  // 更多操作菜单
  const moreActions = (
    <div>
      <div>
        <Button
          size="small"
          style={{ width: "100px" }}
          onClick={() => {
            if (selectedPayRowKeys.length == 0) {
              message.warning("请先勾选需要修改的信息");
            } else {
              setInsertPayData({});
              setUpdatePayModel(true);
            }
          }}
        >
          批量修改
        </Button>
      </div>
      <div>
        <Button
          size="small"
          style={{ width: "100px" }}
          onClick={() => {
            if (selectedPayRowKeys.length == 0) {
              message.warning("请先勾选需要修改的信息");
            } else {
              setSplitTime("");
              setFrontPay("");
              setUpdateMonthPayModel(true);
            }
          }}
        >
          切分薪酬时间
        </Button>
      </div>
      <div>
        <Button
          size="small"
          style={{ width: "100px" }}
          onClick={() => {
            setIsUploadFile(true);
            setExportFlag(0);
          }}
        >
          文件修改
        </Button>
      </div>
    </div>
  );
  // 薪酬汇总选择的id
  const [selectedPayRowKeys, setSelectedPayRowKeys] = useState([]);
  // 多选设置
  const onPaySelect = (record, selected) => {
    let keys = [...selectedPayRowKeys];
    if (selected) {
      keys = [...selectedPayRowKeys, record.id];
    } else {
      keys = selectedPayRowKeys.filter((item) => item !== record.id);
    }
    setSelectedPayRowKeys(keys);
  };
  const onPaySelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      const addCheckedKeys = changeRows.map((item) => {
        return item.id;
      });
      setSelectedPayRowKeys([...selectedPayRowKeys, ...addCheckedKeys]);
    } else {
      const subCheckedKeys = selectedPayRowKeys.filter((id) => {
        return !changeRows.some((item) => {
          return item.id === id;
        });
      });
      setSelectedPayRowKeys(subCheckedKeys);
    }
  };
  const rowPaySelection = {
    columnWidth: 30,
    selectedRowKeys: selectedPayRowKeys,
    onSelect: onPaySelect,
    onSelectAll: onPaySelectAll,
  };
  // 获取域名state
  function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  }
  // #I6A6KH NB20人资通过员工状态和身份证号自动生成生日和性别 start
  // 获取身份证性别
  function getGenderByIdNumber(idNumber) {
    if (idNumber) {
      let genderCode; // 性别代码
      if (idNumber.length == 18) {
        // 二代身份证号码长度为18位（第17位为性别代码）
        genderCode = idNumber.charAt(16);
      } else if (idNumber.length == 15) {
        // 一代身份证号码长度为15位（第15位为性别代码）
        genderCode = idNumber.charAt(14);
      }
      if (genderCode && !isNaN(genderCode)) {
        // 两代身份证号码的性别代码都为男奇女偶
        if (parseInt(genderCode) % 2 == 0) {
          return "女";
        }
        return "男";
      }
    }
  }
  // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start
  // 筛选策略
  const [strategyList, setStrategyList] = useState([
    { id: "=", value: "=" },
    { id: "<", value: "<" },
    { id: "<=", value: "≤" },
    { id: ">", value: ">" },
    { id: ">=", value: "≥" },
    { id: "!=", value: "≠" },
    { id: "LIKE", value: "包含" },
  ]);
  // 是否上传文件
  const [isUploadFile, setIsUploadFile] = useState(false);
  // 是否在导出中
  const [exportFlag, setExportFlag] = useState(0);
  // 文档文件集合
  const [fileList, setFileList] = useState([]);
  // 错误信息
  const [errorMsg, setErrorMsg] = useState("请上传文件");
  // 选中的开始筛选策略
  const [startStrategy, setStartStrategy] = useState("");
  // 筛选的开始时间
  const [startTimeFilter, setStartTimeFilter] = useState(null);
  // 选中的结束筛选策略
  const [endStrategy, setEndStrategy] = useState("");
  // 筛选的结束时间
  const [endTimeFilter, setEndTimeFilter] = useState(null);
  // 选中的公司开始筛选策略
  const [companyStartStrategy, setCompanyStartStrategy] = useState("");
  // 筛选的公司开始时间
  const [companyStartTimeFilter, setCompanyStartTimeFilter] = useState(null);
  // 选中的公司结束筛选策略
  const [companyEndStrategy, setCompanyEndStrategy] = useState("");
  // 筛选的公司结束时间
  const [companyEndTimeFilter, setCompanyEndTimeFilter] = useState(null);
  // 选中的部门开始筛选策略
  const [departmentStartStrategy, setDepartmentStartStrategy] = useState("");
  // 筛选的部门开始时间
  const [departmentStartTimeFilter, setDepartmentStartTimeFilter] =
    useState(null);
  // 选中的部门结束筛选策略
  const [departmentEndStrategy, setDepartmentEndStrategy] = useState("");
  // 筛选的部门结束时间
  const [departmentEndTimeFilter, setDepartmentEndTimeFilter] = useState(null);
  // 选中的项目开始筛选策略
  const [projectStartStrategy, setProjectStartStrategy] = useState("");
  // 筛选的项目开始时间
  const [projectStartTimeFilter, setProjectStartTimeFilter] = useState(null);
  // 选中的项目结束筛选策略
  const [projectEndStrategy, setProjectEndStrategy] = useState("");
  // 筛选的项目结束时间
  const [projectEndTimeFilter, setProjectEndTimeFilter] = useState(null);
  // 选中的店铺开始筛选策略
  const [shopStartStrategy, setShopStartStrategy] = useState("");
  // 筛选的店铺开始时间
  const [shopStartTimeFilter, setShopStartTimeFilter] = useState(null);
  // 选中的店铺结束筛选策略
  const [shopEndStrategy, setShopEndStrategy] = useState("");
  // 筛选的店铺结束时间
  const [shopEndTimeFilter, setShopEndTimeFilter] = useState(null);
  // 选中的岗位开始筛选策略
  const [postStartStrategy, setPostStartStrategy] = useState("");
  // 筛选的岗位开始时间
  const [postStartTimeFilter, setPostStartTimeFilter] = useState(null);
  // 选中的岗位结束筛选策略
  const [postEndStrategy, setPostEndStrategy] = useState("");
  // 筛选的岗位结束时间
  const [postEndTimeFilter, setPostEndTimeFilter] = useState(null);
  // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end
  // #I6A6KH NB20人资通过员工状态和身份证号自动生成生日和性别 end
  // 折叠面板key
  const [panelKey, setPanelKey] = useState([]);
  // 折叠面板key
  const [panelKeyBase, setPanelKeyBase] = useState([]);
  // 标签值
  const [tabKey, setTabKey] = useState(
    getQueryString("tabKey") ? getQueryString("tabKey") : "staff"
  );
  // // 社保管理标签值
  // const [socialSecurityTabKey, setSocialSecurityTabKey] = useState('socialBase')
  // // 公积金管理标签值
  // const [accumulationFundTabKey, setAccumulationFundTabKey] = useState('accumulationFundBase')
  // 是否有效
  const [loading, setLoading] = useState(false);
  // 关联项目列表
  const [relationProjectList, setRelationProjectList] = useState([]);
  // 关联店铺列表
  const [relationShopList, setRelationShopList] = useState([]);
  // BP管理人列表
  const [BPList, setBPList] = useState([]);
  // 员工ID列表
  const [staffIDList, setStaffIDList] = useState([]);
  // 选中的员工ID
  const [staffID, setStaffID] = useState([]);
  // 人员列表
  const [staffList, setStaffList] = useState([]);
  // 选中的人员
  const [staffData, setStaffData] = useState("");
  // 公司列表
  const [companyList, setCompanyList] = useState([]);
  // 选中的公司
  const [company, setCompany] = useState([]);
  // 生日月份列表
  const [birthdayMonthlist, setBirthdayMonthList] = useState([
    { id: "01", value: "一月" },
    { id: "02", value: "二月" },
    { id: "03", value: "三月" },
    { id: "04", value: "四月" },
    { id: "05", value: "五月" },
    { id: "06", value: "六月" },
    { id: "07", value: "七月" },
    { id: "08", value: "八月" },
    { id: "09", value: "九月" },
    { id: "10", value: "十月" },
    { id: "11", value: "十一月" },
    { id: "12", value: "十二月" },
  ]);
  // 是否删除下拉框
  const [delectList, setDelectList] = useState([
    { id: 1, value: "在职" },
    { id: 0, value: "离职" },
  ]);
  // 电脑补贴列表
  const [computerList, setComputerList] = useState([0, 50, 100, 150, 180, 200]);
  // 法定节假日加班费用列表
  const [holidayList, setHolidayrList] = useState([0, 72.73, 148.57]);
  // 是否外包
  const [out, setOut] = useState([
    { id: 1, value: "是" },
    { id: 0, value: "否" },
  ]);
  // 选中的生日月份
  const [birthdayMonth, setBirthdayMonth] = useState([]);
  // 人员类型列表
  const [staffTypeList, setStaffTypeList] = useState([
    {
      id: 0,
      value: "正式员工",
    },
    // {
    //     id: 1,
    //     value: '离职员工'
    // },
    // {
    //     id: 2,
    //     value: '待入职'
    // },
    {
      id: 3,
      value: "实习生",
    },
    {
      id: 4,
      value: "挂靠",
    },
    {
      id: 5,
      value: "返聘",
    },
    {
      id: 6,
      value: "兼职",
    },
  ]);
  // 性别列表
  const [sexList, setSexList] = useState([
    {
      id: "男",
      value: "男",
    },
    {
      id: "女",
      value: "女",
    },
  ]);
  // 选中的人员类型
  const [staffType, setStaffType] = useState([]);
  // 人员类型列表
  const [staffStateList, setStaffStateList] = useState([
    {
      id: 0,
      value: "离职",
    },
    {
      id: 1,
      value: "在职员工",
    },
    {
      id: 2,
      value: "待入职",
    },
  ]);
  // 选中的员工状态
  const [staffState, setStaffState] = useState([]);
  // 表头字段
  const [filed] = useState([
    "员工ID",
    "姓名",
    "公司",
    "员工类型",
    "员工状态",
    "入职时间",
    "预计离职时间",
    "身份证号",
    "性别",
    "手机号",
    "邮箱",
    "生日",
    "操作",
  ]);
  // 表头
  const [column, setColumn] = useState([]);
  // 数据源
  const [dataSource, setDataSource] = useState([]);
  // #I67FC1 NB20人资系统人员项目新增店铺参数 start
  // 人员项目表头字段
  const [projectFiled] = useState([
    "员工ID",
    "员工姓名",
    "部门",
    "项目",
    "项目参数",
    "店铺",
    "店铺参数",
    "岗位",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // #I67FC1 NB20人资系统人员项目新增店铺参数 end
  // 人员项目表头
  const [projectColumn, setProjectColumn] = useState([]);
  // 人员项目数据源
  const [projectDataSource, setProjectDataSource] = useState([]);
  // 公司部门表头字段
  const [companyFiled] = useState(["公司", "部门", "备注", "操作"]);
  // 公司部门表头
  const [companyColumn, setCompanyColumn] = useState([]);
  // 公司部门数据源
  const [companyDataSource, setCompanyDataSource] = useState([]);
  // 部门项目表头字段
  const [departmantFiled] = useState(["部门", "项目", "备注", "操作"]);
  // 部门项目表头
  const [departmantColumn, setDepartmantColumn] = useState([]);
  // 部门项目数据源
  const [departmantDataSource, setDepartmantDataSource] = useState([]);
  // 项目店铺表头字段
  const [shopFiled] = useState(["项目", "店铺", "备注", "操作"]);
  // 部门项目表头
  const [shopColumn, setShopColumn] = useState([]);
  // 部门项目数据源
  const [shopDataSource, setShopDataSource] = useState([]);
  // 岗位表头字段
  const [postFiled] = useState([
    "岗位",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 岗位表头
  const [postColumn, setPostColumn] = useState([]);
  // 岗位数据源
  const [postDataSource, setPostDataSource] = useState([]);
  // 公司表头字段
  const [companyTableFiled] = useState([
    "公司全称",
    "公司简称",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 公司表头
  const [companyTableColumn, setCompanyTableColumn] = useState([]);
  // 公司数据源
  const [companyTableDataSource, setCompanyTableDataSource] = useState([]);
  // 部门表头字段
  const [departmentTableFiled] = useState([
    "部门",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 部门表头
  const [departmentTableColumn, setDepartmentTableColumn] = useState([]);
  // 部门数据源
  const [departmentTableDataSource, setDepartmentTableDataSource] = useState(
    []
  );
  // 项目表头字段
  const [projectTableFiled] = useState([
    "项目",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 项目表头
  const [projectTableColumn, setProjectTableColumn] = useState([]);
  // 项目数据源
  const [projectTableDataSource, setProjectTableDataSource] = useState([]);
  // 店铺表头字段
  const [shopTableFiled] = useState([
    "店铺",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 店铺表头
  const [shopTableColumn, setShopTableColumn] = useState([]);
  // 店铺数据源
  const [shopTableDataSource, setShopTableDataSource] = useState([]);
  // 薪酬汇总表头字段
  const [payFiled] = useState([
    "员工ID",
    "员工姓名",
    "月薪",
    "法定节假日加班费用",
    "电脑补贴",
    "加班补贴",
    "客服晚班补贴",
    "住宿补贴",
    "扣款",
    "子女教育支出扣除",
    "继续教育支出扣除",
    "住房贷款利息支出扣除",
    "住房租金支出扣除",
    "赡养老人支出扣除",
    "通讯费",
    "婴幼儿照护费用",
    "员工关怀",
    "公积金开始缴纳时间",
    "社保缴纳地",
    "是否外包",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 薪酬汇总表头
  const [payColumn, setPayColumn] = useState([]);
  // 薪酬汇总数据源
  const [payDataSource, setPayDataSource] = useState([]);
  // 社保基数判别实际表头字段
  const [socialBaseFiled] = useState([
    "社保缴纳地",
    "最低月薪",
    "最高月薪",
    "月薪判断系数",
    "社保基数",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 社保基数判别实际表表头
  const [socialBaseColumn, setSocialBaseColumn] = useState([]);
  // 社保基数判别实际表数据源
  const [socialBaseDataSource, setSocialBaseDataSource] = useState([]);
  // 社保缴纳费用参数表头字段
  const [socialPayFiled] = useState([
    "社保缴纳地",
    "公司",
    "社保项目",
    "社保缴纳系数",
    "社保缴纳数",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 社保缴纳费用参数表表头
  const [socialPayColumn, setSocialPayColumn] = useState([]);
  // 社保缴纳费用参数表数据源
  const [socialPayDataSource, setSocialPayDataSource] = useState([]);
  // 社保基数预估表头字段
  const [socialBaseEstimateFiled] = useState([
    "员工ID",
    "员工姓名",
    "社保缴纳地",
    "社保基数",
    "是否特殊",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 社保基数预估表表头
  const [socialBaseEstimateColumn, setSocialBaseEstimateColumn] = useState([]);
  // 社保基数预估表数据源
  const [socialBaseEstimateDataSource, setSocialBaseEstimateDataSource] =
    useState([]);
  // 社保基数范围预估表头字段
  const [socialBaseRangeEstimateFiled] = useState([
    "社保缴纳地",
    "社保最低基数",
    "社保最高基数",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 社保基数范围预估表表头
  const [socialBaseRangeEstimateColumn, setSocialBaseRangeEstimateColumn] =
    useState([]);
  // 社保基数范围预估表数据源
  const [
    socialBaseRangeEstimateDataSource,
    setSocialBaseRangeEstimateDataSource,
  ] = useState([]);
  // 公积金基数判别表头字段
  const [accumulationFundBaseFiled] = useState([
    "社保缴纳地",
    "最低月薪",
    "最高月薪",
    "月薪判断系数",
    "公积金基数",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 公积金基数判别表头
  const [accumulationFundBaseColumn, setAccumulationFundBaseColumn] = useState(
    []
  );
  // 公积金基数判别表数据源
  const [accumulationFundBaseDataSource, setAccumulationFundBaseDataSource] =
    useState([]);
  // 公积金预估表头字段
  const [accumulationFundEstimateFiled] = useState([
    "员工ID",
    "员工姓名",
    "社保缴纳地",
    "公积金基数",
    "是否特殊",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 公积金预估表表头
  const [accumulationFundEstimateColumn, setAccumulationFundEstimateColumn] =
    useState([]);
  // 公积金预估表数据源
  const [
    accumulationFundEstimateDataSource,
    setAccumulationFundEstimateDataSource,
  ] = useState([]);
  // 公积金范围判别预估表头字段
  const [accumulationFundRangeEstimateFiled] = useState([
    "社保缴纳地",
    "公积金最低基数",
    "公积金最高基数",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 公积金范围判别预估表表头
  const [
    accumulationFundRangeEstimateColumn,
    setAccumulationFundRangeEstimateColumn,
  ] = useState([]);
  // 公积金范围判别预估表数据源
  const [
    accumulationFundRangeEstimateDataSource,
    setAccumulationFundRangeEstimateDataSource,
  ] = useState([]);
  // 个人所得税参数表头字段
  const [taxFiled] = useState([
    "最小纳税所得额",
    "最大纳税所得额",
    "税率",
    "速算扣除",
    "开始年份月份日期",
    "结束年份月份日期",
    "操作",
  ]);
  // 个人所得税参数表头
  const [taxColumn, setTaxColumn] = useState([]);
  // 个人所得税参数数据源
  const [taxDataSource, setTaxDataSource] = useState([]);
  // 表单表头字段
  const [formFiled] = useState([
    "ID",
    "用户名",
    "模块",
    "表名",
    "时间",
    "操作",
  ]);
  // 表单表头
  const [formColumn, setFormColumn] = useState([]);
  // 表单数据源
  const [formDataSource, setFormDataSource] = useState([
    {
      id: 1,
      ID: "1",
      用户名: "张三",
      模块: "预估",
      表名: "预估表-张三",
      时间: "2022-8-22",
      status: 0,
    },
  ]);
  // 新增薪酬汇总的数据
  const [insertPayData, setInsertPayData] = useState({});
  // 批量修改薪酬汇总
  const [updatePayModel, setUpdatePayModel] = useState(false);
  // 批量修改月薪
  const [updateMonthPayModel, setUpdateMonthPayModel] = useState(false);
  // 是否删除人员弹窗
  const [deleteStaff, setDeleteStaff] = useState(false);
  // 是否删除人员项目弹窗
  const [deleteStaffProject, setDeleteStaffProject] = useState(false);
  // 修改人员信息弹窗
  const [updateStaffInfoModel, setUpdateStaffInfoModel] = useState(false);
  // 修改人员项目信息弹窗
  const [updateStaffProjectModel, setUpdateStaffProjectModel] = useState(false);
  // 修改薪酬汇总弹窗
  const [updateOnePayModel, setUpdateOnePayModel] = useState(false);
  // 修改社保弹窗
  const [updateSocialBaseModel, setUpdateSocialBaseModel] = useState(false);
  // 修改社保范围弹窗
  const [updateSocialRangeModel, setUpdateSocialRangeModel] = useState(false);
  // 切分月薪弹窗
  const [splitPayModel, setSplitPayModel] = useState(false);
  // 切分时间弹窗
  const [splitTimeModel, setSplitTimeModel] = useState(false);
  // 切分公积金月薪弹窗
  const [splitAccumulationFundPayModel, setSplitAccumulationFundPayModel] =
    useState(false);
  // 切分公积金时间弹窗
  const [splitAccumulationFundTimeModel, setSplitAccumulationFundTimeModel] =
    useState(false);
  // 切分个税纳税弹窗
  const [splitTaxPayModel, setSplitTaxPayModel] = useState(false);
  // 切分个税时间弹窗
  const [splitTaxTimeModel, setSplitTaxTimeModel] = useState(false);
  // 修改公积金弹窗
  const [updateAccumulationFundModel, setUpdateAccumulationFundModel] =
    useState(false);
  // 修改公积金范围弹窗
  const [
    updateAccumulationFundRangeModel,
    setUpdateAccumulationFundRangeModel,
  ] = useState(false);
  // 修改个税弹窗
  const [updateTaxModel, setUpdateTaxModel] = useState(false);
  // 修改社保基数判别实际表弹窗表头
  const [updateModelTitle, setUpdateModelTitle] = useState("");
  // 修改人员信息下标
  const [updateStaffIndex, setUpdateStaffIndex] = useState("");
  // 新增人员信息弹窗
  const [insertStaffInfoModel, setInsertStaffInfoModel] = useState(false);
  // 新增人员项目弹窗
  const [insertStaffProjectModel, setInsertStaffProjectModel] = useState(false);
  // 新增薪酬汇总弹窗
  const [insertPayModel, setInsertPayModel] = useState(false);
  // 新增公司部门弹窗
  const [insertcompanyDepartmentModel, setInsertcompanyDepartmentModel] =
    useState(false);
  // 新增部门项目弹窗
  const [insertDepartmentProjectModel, setInsertDepartmentProjectModel] =
    useState(false);
  // 新增项目店铺弹窗
  const [insertProjectShopModel, setInsertProjectShopModel] = useState(false);
  // 新增岗位弹窗
  const [insertPostModel, setInsertPostModel] = useState(false);
  // 内部推荐弹窗
  const [recommendModel, setRecommendModel] = useState(false);
  // 修改人员信息
  const [updateStaff, setUpdateStaff] = useState({});
  // 修改社保字段设置
  const [updateField, setUpdateField] = useState([]);
  // 新增社保弹窗
  const [insertSocialModel, setInsertSocialModel] = useState(false);
  // 新增社保字段设置
  const [socialFiled, setSocialFiled] = useState([]);
  // 新增公积金字段数据
  const [insertAccumulationFundFiledData, setInsertAccumulationFundFiledData] =
    useState({});
  // 新增公积金弹窗
  const [insertAccumulationFundModel, setInsertAccumulationFundModel] =
    useState(false);
  // 新增公积金字段设置
  const [accumulationFundFiled, setAccumulationFundFiled] = useState([]);
  // 新增社保字段数据
  const [insertSocialFiledData, setInsertSocialFiledData] = useState({});
  // 新增个税弹窗
  const [insertTaxModel, setInsertTaxModel] = useState(false);
  // 新增个税数据
  const [taxFiledData, setInsertTaxFiledData] = useState({});
  // 修改社保基数判别表
  const [updateSocialBase, setUpdateSocialBase] = useState({});
  // 填写的员工ID
  const [staffName, setStaffName] = useState("");
  // 社保缴纳地列表
  const [socialAddList, setSocialAddList] = useState([]);
  // 选中的社保缴纳地
  const [socialAdd, setSocialAdd] = useState([]);
  // 社保基数列表
  const [socialBaseList, setSocialBaseList] = useState([]);
  // 选中的社保基数
  const [socialBase, setSocialBase] = useState([]);
  // 公积金基数列表
  const [accumulationFundBaseList, setAccumulationFundBaseList] = useState([]);
  // 选中的公积金基数
  const [accumulationFundBase, setAccumulationFundBase] = useState([]);
  // 部门列表
  const [departmentList, setDepartmentList] = useState([]);
  // 选中的部门
  const [department, setDepartment] = useState([]);
  // 项目列表
  const [projectList, setProjectList] = useState([]);
  // 选中的项目
  const [project, setProject] = useState([]);
  // 是否特殊列表
  const [specialList] = useState([
    { id: 0, value: "否" },
    { id: 1, value: "是" },
  ]);
  // 店铺列表
  const [shopList, setShopList] = useState([]);
  // 选中的店铺
  const [shop, setShop] = useState([]);
  // 岗位列表
  const [postList, setPostList] = useState([]);
  // 选中的岗位
  const [post, setPost] = useState([]);
  // 选中的公司部门查询中的公司
  const [companyDepartmentCompany, setCompanyDepartmentCompany] = useState([]);
  // 选中的公司部门查询中的部门
  const [companyDepartment, setCompanyDepartment] = useState([]);
  // 选中的部门项目查询中的部门
  const [departmentItemDepartment, setDepartmentItemDepartment] = useState([]);
  // 选中的部门项目查询中的项目
  const [departmentItem, setDepartmentItem] = useState([]);
  // 选中的项目店铺查询中的项目
  const [itemShopItem, setItemShopItem] = useState([]);
  // 选中的项目店铺查询中的铺
  const [itemShop, setItemShop] = useState([]);
  // 新增人员信息生日时间
  const [timeBirthday, setTimeBirthday] = useState("");
  // 新增人员信息入职时间
  const [timeInsert, setTimeInsert] = useState("");
  // 新增人员信息预计离职时间
  const [timeDead, setTimeDead] = useState("");
  // 新增人员信息的员工ID
  const [staffIDInsert, setStaffIDInsert] = useState("");
  // 新增人员信息的员工状态
  const [staffStateInsert, setStaffStateInsert] = useState("");
  // 新增人员信息的员工姓名
  const [staffNameInsert, setStaffNameInsert] = useState("");
  // 新增人员信息的员工身份证
  const [staffIDCardInsert, setStaffIDCardInsert] = useState("");
  // 新增人员信息的员工手机号
  const [staffPhoneInsert, setStaffPhoneInsert] = useState("");
  // 新增人员信息的员工性别
  const [staffSexInsert, setStaffSexInsert] = useState("");
  // 新增人员信息的员工邮箱
  const [staffEmailInsert, setStaffEmailInsert] = useState("");
  // 新增人员信息的公司ID
  const [companyIDInsert, setCompanyIDInsert] = useState("");
  // 新增人员信息的BP管理人
  const [BPInsert, setBPInsert] = useState("");
  // 新增人员项目信息的员工姓名
  const [staffProjectNameInsert, setStaffProjectNameInsert] = useState("");
  // #I67FC1 NB20人资系统人员项目新增店铺参数 start
  // 新增人员项目信息的店铺参数
  const [staffProjectShopParameterInsert, setStaffProjectShopParameterInsert] =
    useState("");
  // #I67FC1 NB20人资系统人员项目新增店铺参数 end
  // 新增人员项目信息的部门
  const [staffProjectDepartmentInsert, setStaffProjectDepartmentInsert] =
    useState("");
  // 新增人员项目信息的项目
  const [staffProjectInsert, setStaffProjectInsert] = useState("");
  // 新增人员项目信息的店铺
  const [staffProjectShopInsert, setStaffProjectShopInsert] = useState("");
  // 新增人员项目信息的项目参数
  const [staffProjectParameterInsert, setStaffProjectParameterInsert] =
    useState("");
  // 新增人员项目信息的岗位
  const [staffProjectPostInsert, setStaffProjectPostInsert] = useState("");
  // 新增人员项目信息的入职时间
  const [staffProjectTimeInsert, setStaffProjectTimeInsert] = useState("");
  // 新增人员项目信息的离职时间
  const [staffProjectDeadTime, setStaffProjectDeadTime] = useState("");
  // 新增公司部门信息的公司
  const [companyInsert, setCompanyInsert] = useState("");
  // 新增公司部门信息的部门
  const [companyDepartmentInsert, setCompanyDepartmentInsert] = useState("");
  // 新增公司部门信息的备注
  const [companyRemarkInsert, setCompanyRemarkInsert] = useState("");
  // 新增部门项目信息的部门
  const [departmentInsert, setDepartmentInsert] = useState("");
  // 新增部门项目信息的项目
  const [departmentProjectInsert, setDepartmentProjectInsert] = useState("");
  // 新增部门项目信息的备注
  const [departmentRemarkInsert, setDepartmentRemarkInsert] = useState("");
  // 新增项目店铺信息的项目
  const [projectInsert, setProjectInsert] = useState("");
  // 新增项目店铺信息的店铺
  const [projectShopInsert, setProjectShopInsert] = useState("");
  // 新增项目店铺信息的备注
  const [projectRemarkInsert, setProjectRemarkInsert] = useState("");
  // 新增岗位信息的岗位
  const [postInsert, setPostInsert] = useState("");
  // 新增公司简称
  const [companyShort, setCompanyShort] = useState("");
  // 新增字段
  const [insertItem, setInsertItem] = useState("");
  // 内部推荐的员工ID
  const [recommendStaffID, setRecommendStaffID] = useState("");
  // 内部推荐的员工姓名
  const [recommendStaffName, setRecommendStaffName] = useState("");
  // 内部推荐的奖金
  const [recommendMoney, setRecommendMoney] = useState("");
  // 内部推荐的年份月份
  const [recommendDate, setRecommendDate] = useState("");
  // 新增开始年份月份日期
  const [insertStartTime, setInsertStartTime] = useState("");
  // 新增结束年份月份日期
  const [insertEndTime, setInsertEndTime] = useState("");
  // 社保项目列表
  const [socailProjectList, setSocailProjectList] = useState([
    { id: "社保-公司部分", value: "社保-公司部分" },
    { id: "社保-个人养老保险", value: "社保-个人养老保险" },
    { id: "社保-个人医疗保险", value: "社保-个人医疗保险" },
    { id: "社保-个人失业保险", value: "社保-个人失业保险" },
  ]);
  // 切分月薪输入值
  const [splitPay, setSplitPay] = useState("");
  // 切分时间输入值
  const [splitTime, setSplitTime] = useState("");
  // 前月薪判断系数
  const [frontPay, setFrontPay] = useState("");
  // 后月薪判断系数
  const [afterPay, setAfterPay] = useState("");
  // 前社保基数
  const [frontSocailBase, setFrontSocailBase] = useState("");
  // 后社保基数
  const [afterSocailBase, setAfterSocailBase] = useState("");
  // 修改字段原有值
  const [oldData, setOldData] = useState({});
  // 判断是否为薪资专员
  const [salarySpecialist, setSalarySpecialist] = useState(0);
  // 下拉框字典
  const [selectDict, setSelectDict] = useState({});
  //定义筛选功能
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  // 人员权限
  const [isFinance, setIsFinance] = useState(1);
  // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start
  // 全量公司列表
  const [companySelectList, setCompanySelectList] = useState([]);
  // 全量部门列表
  const [departmentSelectList, setDepartmentSelectList] = useState([]);
  // 全量项目列表
  const [projectSelectList, setProjectSelectList] = useState([]);
  // 全量店铺列表
  const [shopSelectList, setShopSelectList] = useState([]);
  // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`搜索${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            查询
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            重置
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //     setTimeout(() => this.searchInput.select());
      // }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // 员工板块初始化
  useEffect(() => {
    let obj = [];
    filed.map((item) => {
      if (isFinance !== 1 || item !== "操作") {
        obj.push({
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // #I6A4HP NB20人资优化表格宽度 start
          width:
            item == "操作" || item == "员工ID"
              ? "100px"
              : item == "姓名"
              ? "120px"
              : item == "身份证号"
              ? "200px"
              : item == "性别"
              ? "80px"
              : "",
          // #I6A4HP NB20人资优化表格宽度 end
          fixed:
            item == "操作"
              ? "right"
              : item == "员工ID" || item == "姓名"
              ? "left"
              : "",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                {" "}
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (
                        (key.indexOf("时间") !== -1 ||
                          key.indexOf("生日") !== -1) &&
                        obj[key]
                      ) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateStaff(obj);
                    setUpdateField(filed);
                    setOldData(record);
                    setUpdateStaffInfoModel(true);
                    AcceptBuried(
                      "Modify_employee_information",
                      "click",
                      {},
                      props.location.pathname,
                      "人资预估管理"
                    );
                  }}
                >
                  修改
                </a>
                {/* #I6DXU4 NB20人资新增员工项目薪酬的删除功能 start*/}
                <Popconfirm
                  title="是否删除该记录"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    deleteMemberInformation(record);
                  }}
                >
                  <a>删除</a>
                </Popconfirm>
                {/*  #I6DXU4 NB20人资新增员工项目薪酬的删除功能 end*/}
              </div>
            ) : (
              text
            );
          },
        });
      }
    });
    setColumn(obj);
    // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 start
  }, [isFinance, dataSource]);
  // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 end
  // 获取所有下拉框取值
  useEffect(() => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
    };
    AcceptBuried(
      "Page_views",
      "view",
      { Page_name: "人资预估管理" },
      props.location.pathname,
      "人资预估管理"
    );
    // 初始化下拉框数据
    const getAllData = async () => {
      await Promise.all([
        getCompany(dataProps),
        getDepartment(dataProps),
        getItem(dataProps),
        getShop(dataProps),
        getStation(dataProps),
        getBPList(dataProps),
        getSocialSecurityStation(dataProps),
        getMemberID(),
        // judgeSalarySpecialist(dataProps),
        getUserId(dataProps),
      ]);
    };
    getAllData();
  }, []);
  const selectDictFn = () => {
    setSelectDict({
      部门: departmentList,
      项目: projectList,
      店铺: shopList,
      岗位: postList,
      员工类型: staffTypeList,
      公司: companyList,
      性别: sexList,
      状态: delectList,
      社保缴纳地: socialAddList,
      // '社保基数': socialBaseList,
      员工姓名: staffList,
      是否特殊: specialList,
      // '公积金基数':accumulationFundBaseList,
      社保项目: socailProjectList,
    });
  };
  // 防抖
  const debouncer = useDebounce(selectDictFn);
  // 下拉框对应字典初始化
  useEffect(() => {
    debouncer();
  }, [
    departmentList,
    projectList,
    shopList,
    postList,
    staffTypeList,
    companyList,
    socialAddList,
    socialBaseList,
    accumulationFundBaseList,
    staffList,
  ]);
  // 项目板块初始化
  useEffect(() => {
    let obj = [];
    projectFiled.map((item) => {
      if (isFinance !== 1 || item !== "操作") {
        obj.push({
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: "9%",
          render: (text, record, index) => {
            // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 start
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    //深克隆一份数据
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateStaff(obj);
                    setOldData(record);
                    setUpdateModelTitle("修改人员项目关系");
                    setUpdateField(projectFiled);
                    setUpdateStaffProjectModel(true);
                    AcceptBuried(
                      "Modify_personnel - project_information",
                      "click",
                      {},
                      props.location.pathname,
                      "人资预估管理"
                    );
                  }}
                >
                  修改
                </a>
                <Popconfirm
                  title="是否删除该记录"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    deleteMemberItemInformation(record);
                  }}
                >
                  <a>删除</a>
                </Popconfirm>
              </div>
            ) : (
              text
            );
            // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 end
          },
        });
      }
    });
    setProjectColumn(obj);
    setCompanyColumn(
      companyFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: "9%",
          render: (text, record, index) => {
            return item == "操作" ? (
              record.status == 1 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {/* <a onClick={() => {
                            setUpdateStaffIndex(index)
                            setUpdateStaff(record)
                            setUpdateModelTitle("修改公司部门关系")
                            setUpdateField(companyFiled)
                            setUpdateStaffProjectModel(true)
                        }}>编辑</a> */}
                  <Popconfirm
                    title="是否删除该记录"
                    okText="确定"
                    cancelText="取消"
                    onConfirm={() => {
                      deleteRelationshipCompanyDepartment(record);
                    }}
                  >
                    <a>删除</a>
                  </Popconfirm>
                </div>
              ) : (
                <Popconfirm
                  title="是否复用该记录"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    backedRelationshipCompanyDepartment(record);
                  }}
                >
                  <a style={{ color: "red" }}>复用</a>
                </Popconfirm>
              )
            ) : (
              text
            );
          },
        };
      })
    );
    setDepartmantColumn(
      departmantFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: "9%",
          render: (text, record, index) => {
            return item == "操作" ? (
              record.status == 1 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Popconfirm
                    title="是否删除该记录"
                    okText="确定"
                    cancelText="取消"
                    onConfirm={() => {
                      deleteRelationshipDepartmentItem(record);
                    }}
                  >
                    <a>删除</a>
                  </Popconfirm>
                </div>
              ) : (
                <Popconfirm
                  title="是否复用该记录"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    backedRelationshipDepartmentItem(record);
                  }}
                >
                  <a style={{ color: "red" }}>复用</a>
                </Popconfirm>
              )
            ) : (
              text
            );
          },
        };
      })
    );
    setShopColumn(
      shopFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: "9%",
          render: (text, record, index) => {
            return item == "操作" ? (
              record.status == 1 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Popconfirm
                    title="是否删除该记录"
                    okText="确定"
                    cancelText="取消"
                    onConfirm={() => {
                      deleteRelationshipItemShop(record);
                    }}
                  >
                    <a>删除</a>
                  </Popconfirm>
                </div>
              ) : (
                <Popconfirm
                  title="是否复用该记录"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    backedRelationshipItemShop(record);
                  }}
                >
                  <a style={{ color: "red" }}>复用</a>
                </Popconfirm>
              )
            ) : (
              text
            );
          },
        };
      })
    );
    setPostColumn(
      postFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: item == '操作' ? "25%" : "75%",
          ...(item == "岗位" ? getColumnSearchProps("岗位") : ""),
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateStaff(obj);
                    setUpdateModelTitle("修改岗位");
                    setUpdateField(postFiled);
                    setUpdateStaffProjectModel(true);
                  }}
                >
                  编辑
                </a>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setCompanyTableColumn(
      companyTableFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          ...(item == "公司"
            ? getColumnSearchProps("公司")
            : item == "公司全称"
            ? getColumnSearchProps("公司全称")
            : ""),
          // width: item == '操作' ? "25%" : "75%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateStaff(obj);
                    setUpdateModelTitle("修改公司");
                    setUpdateField(companyTableFiled);
                    setUpdateStaffProjectModel(true);
                  }}
                >
                  编辑
                </a>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setDepartmentTableColumn(
      departmentTableFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: item == '操作' ? "25%" : "75%",
          ...(item == "部门" ? getColumnSearchProps("部门") : ""),
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateStaff(obj);
                    setUpdateModelTitle("修改部门");
                    setUpdateField(departmentTableFiled);
                    setUpdateStaffProjectModel(true);
                  }}
                >
                  编辑
                </a>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setProjectTableColumn(
      projectTableFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: item == '操作' ? "25%" : "75%",
          ...(item == "项目" ? getColumnSearchProps("项目") : ""),
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateStaff(obj);
                    setUpdateModelTitle("修改项目");
                    setUpdateField(projectTableFiled);
                    setUpdateStaffProjectModel(true);
                  }}
                >
                  编辑
                </a>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setShopTableColumn(
      shopTableFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: item == '操作' ? "25%" : "75%",
          ...(item == "店铺" ? getColumnSearchProps("店铺") : ""),
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateStaff(obj);
                    setUpdateModelTitle("修改店铺");
                    setUpdateField(shopTableFiled);
                    setUpdateStaffProjectModel(true);
                  }}
                >
                  编辑
                </a>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );

    // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 start
  }, [isFinance, projectDataSource]);
  // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 end
  // 薪酬板块初始化
  useEffect(() => {
    setPayColumn(
      payFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // #I6A4HP NB20人资优化表格宽度 start
          // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 start
          width:
            item == "操作"
              ? "100px"
              : item == "员工ID"
              ? "80px"
              : item == "员工姓名"
              ? "120px"
              : item.includes("日期") || item.includes("时间")
              ? "145px"
              : item == "是否外包"
              ? "80px"
              : item == "社保缴纳地"
              ? "90px"
              : "",
          // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 end
          // #I6A4HP NB20人资优化表格宽度 end
          fixed:
            item == "操作"
              ? "right"
              : item == "员工ID" || item == "员工姓名"
              ? "left"
              : "",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (obj[key] == "-") {
                        obj[key] = "";
                      }
                      if (
                        (key.indexOf("月份") !== -1 ||
                          key.indexOf("时间") !== -1) &&
                        obj[key]
                      ) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateStaff(obj);
                    setUpdateField(payFiled);
                    setOldData(record);
                    setUpdateOnePayModel(true);
                  }}
                >
                  编辑
                </a>
                {/* #I6DXU4 NB20人资新增员工项目薪酬的删除功能 start*/}
                <Popconfirm
                  title="是否删除该记录"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    deleteMemberPayInformation(record);
                  }}
                >
                  <a>删除</a>
                </Popconfirm>
                {/*#I6DXU4 NB20人资新增员工项目薪酬的删除功能 end*/}
              </div>
            ) : item == "是否外包" ? (
              outDict[text]
            ) : (
              text
            );
          },
        };
      })
    );
    let obj = {};
    payFiled.map((item) => {
      if (item == `员工关怀`) {
        obj[item] = "100";
      } else {
        obj[item] = "";
      }
    });
    setInsertPayData(obj);
    //    #I6DXU4 NB20人资新增员工项目薪酬的删除功能 start
  }, [payDataSource]);
  // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 end
  // 社保板块初始化
  useEffect(() => {
    setSocialBaseColumn(
      socialBaseFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: "11%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    // let obj = JSON.parse(JSON.stringify(record))
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateSocialBase(obj);
                    setUpdateModelTitle(
                      `修改社保基数判别${tableDict[calculation_type]}表`
                    );
                    setUpdateSocialBaseModel(true);
                    setOldData(record);
                    setUpdateField(socialBaseFiled);
                  }}
                >
                  编辑
                </a>
                {/* #I661M1  NB20人资社保公积金编辑优化 start*/}
                {/* <a onClick={() => {
                            setUpdateModelTitle(`切分社保基数判别${tableDict[calculation_type]}表`)
                            setSplitPay('')
                            setAfterPay('')
                            setFrontPay('')
                            setAfterSocailBase('')
                            setFrontSocailBase('')
                            setOldData(record)
                            setSplitPayModel(true)
                        }}>切分月薪</a>
                        <a onClick={() => {
                            setUpdateModelTitle(`切分社保基数判别${tableDict[calculation_type]}表`)
                            setSplitTime('')
                            setAfterPay('')
                            setFrontPay('')
                            setAfterSocailBase('')
                            setFrontSocailBase('')
                            setOldData(record)
                            setSplitTimeModel(true)
                        }}>切分时间</a> */}
                {/* #I661M1  NB20人资社保公积金编辑优化 end*/}
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setSocialPayColumn(
      socialPayFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: "11%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateSocialBase(obj);
                    setUpdateModelTitle("修改社保缴纳费用参数表");
                    setUpdateSocialBaseModel(true);
                    setOldData(record);
                    setUpdateField(socialPayFiled);
                  }}
                >
                  编辑
                </a>
                {/* #I661M1  NB20人资社保公积金编辑优化 start*/}
                {/* <a onClick={() => {
                            setUpdateModelTitle(`切分社保缴纳费用参数表`)
                            setSplitTime('')
                            setAfterPay('')
                            setFrontPay('')
                            setAfterSocailBase('')
                            setFrontSocailBase('')
                            setOldData(record)
                            setSplitTimeModel(true)
                        }}>切分时间</a> */}
                {/* #I661M1  NB20人资社保公积金编辑优化 end*/}
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setSocialBaseEstimateColumn(
      socialBaseEstimateFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          width: "11%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateSocialBase(obj);
                    setUpdateModelTitle(
                      `修改社保基数${tableDict[calculation_type]}表`
                    );
                    setUpdateSocialBaseModel(true);
                    setOldData(record);
                    setUpdateField(socialBaseEstimateFiled);
                  }}
                >
                  编辑
                </a>
                <Popconfirm
                  title="是否删除该记录"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    deleteSocialSecurity(record);
                  }}
                >
                  <a>删除</a>
                </Popconfirm>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setSocialBaseRangeEstimateColumn(
      socialBaseRangeEstimateFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          width: "11%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateSocialBase(obj);
                    setUpdateModelTitle(
                      `修改社保基数范围判别${tableDict[calculation_type]}表`
                    );
                    setUpdateSocialRangeModel(true);
                    setOldData(record);
                    setUpdateField(socialBaseRangeEstimateFiled);
                  }}
                >
                  编辑
                </a>
                {/* <Popconfirm title="是否删除该记录" okText="确定" cancelText="取消" onConfirm={() => {
                            deleteSocialSecurity(record)
                        }}>
                            <a>删除</a>
                        </Popconfirm> */}
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
  }, []);
  // 公积金板块初始化
  useEffect(() => {
    setAccumulationFundBaseColumn(
      accumulationFundBaseFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: "11%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateSocialBase(obj);
                    setOldData(record);
                    setUpdateModelTitle(
                      `修改公积金基数判别${tableDict[calculation_type]}表`
                    );
                    setUpdateAccumulationFundModel(true);
                    setUpdateField(accumulationFundBaseFiled);
                  }}
                >
                  编辑
                </a>
                {/* #I661M1  NB20人资社保公积金编辑优化 start*/}
                {/* <a onClick={() => {
                            setUpdateModelTitle(`切分公积金基数判别${tableDict[calculation_type]}表`)
                            setSplitPay('')
                            setAfterPay('')
                            setFrontPay('')
                            setAfterSocailBase('')
                            setFrontSocailBase('')
                            setOldData(record)
                            setSplitAccumulationFundPayModel(true)
                        }}>切分月薪</a>
                        <a onClick={() => {
                            setUpdateModelTitle(`切分公积金基数判别${tableDict[calculation_type]}表`)
                            setSplitTime('')
                            setAfterPay('')
                            setFrontPay('')
                            setAfterSocailBase('')
                            setFrontSocailBase('')
                            setOldData(record)
                            setSplitAccumulationFundTimeModel(true)
                        }}>切分时间</a> */}
                {/* #I661M1  NB20人资社保公积金编辑优化 end*/}
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setAccumulationFundEstimateColumn(
      accumulationFundEstimateFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          width: "11%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateSocialBase(obj);
                    setOldData(record);
                    setUpdateModelTitle(
                      `修改公积金基数${tableDict[calculation_type]}表`
                    );
                    setUpdateAccumulationFundModel(true);
                    setUpdateField(accumulationFundEstimateFiled);
                  }}
                >
                  编辑
                </a>
                <Popconfirm
                  title="是否删除该记录"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    deleteAccumulationFund(record);
                  }}
                >
                  <a>删除</a>
                </Popconfirm>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setAccumulationFundRangeEstimateColumn(
      accumulationFundRangeEstimateFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: "11%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateSocialBase(obj);
                    setOldData(record);
                    setUpdateModelTitle(
                      `修改公积金基数范围判别${tableDict[calculation_type]}表`
                    );
                    setUpdateAccumulationFundRangeModel(true);
                    setUpdateField(accumulationFundRangeEstimateFiled);
                  }}
                >
                  编辑
                </a>
                {/* <Popconfirm title="是否删除该记录" okText="确定" cancelText="取消" onConfirm={() => {
                            deleteAccumulationFund(record)
                        }}>
                            <a>删除</a>
                        </Popconfirm> */}
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
  }, []);
  // 个税表单初始化
  useEffect(() => {
    setTaxColumn(
      taxFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          width: "11%",
          render: (text, record, index) => {
            return item == "操作" && record["最大纳税所得额"] > 0 ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateStaffIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("月份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateSocialBase(obj);
                    setOldData(record);
                    setUpdateTaxModel(true);
                    setUpdateField(taxFiled);
                  }}
                >
                  编辑
                </a>
                <a
                  onClick={() => {
                    setSplitPay("");
                    setAfterPay("");
                    setFrontPay("");
                    setAfterSocailBase("");
                    setFrontSocailBase("");
                    setOldData(record);
                    setSplitTaxPayModel(true);
                  }}
                >
                  切分纳税
                </a>
                <a
                  onClick={() => {
                    setSplitTime("");
                    setAfterPay("");
                    setFrontPay("");
                    setAfterSocailBase("");
                    setFrontSocailBase("");
                    setOldData(record);
                    setSplitTaxTimeModel(true);
                  }}
                >
                  切分时间
                </a>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setFormColumn(
      formFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width: "11%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Popconfirm
                  title="是否删除该记录"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    deleteAllSubject(record);
                  }}
                >
                  <a>删除</a>
                </Popconfirm>
              </div>
            ) : item == "模块" ? (
              modelDict[text]
            ) : (
              text
            );
          },
        };
      })
    );
  }, []);
  // 数据初始化
  useEffect(() => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
      change_member: cookie.load("name"),
    };
    if (tabKey == "project") {
      const getAllData = async () => {
        await Promise.all([
          getRelationshipCompanyDepartment(dataProps),
          getRelationshipDepartmentItem(dataProps),
          getRelationshipItemShop(dataProps),
          getMemberItemInformation(dataProps),
        ]);
      };
      getAllData();
    } else if (tabKey == "staff") {
      getMemberInformation(dataProps);
    } else if (tabKey == "pay") {
      getMemberPayInformation(dataProps);
    } else if (tabKey == "socialSecurity") {
      findSocialSecurity(dataProps);
      getSocialBaseList();
      searchNewWorkerSocialSecurity(dataProps);
      searchWorkerSocialSecuritySubject(dataProps);
      findSocialSecurityRange(dataProps);
    } else if (tabKey == "accumulationFund") {
      searchWorkerAccumulationFund(dataProps);
      searchWorkerAccumulationFundEstimate(dataProps);
      findWorkerAccumulationRange(dataProps);
      getAccumulationFundBaseList();
    } else if (tabKey == "personalIncomeTax") {
      findPersonalIncomeTaxMaintain(dataProps);
    } else if (tabKey == "Form") {
      getAllSubjectName(dataProps);
    }
  }, [tabKey]);
  // 公司和部门关系逻辑删除接口
  const deleteRelationshipCompanyDepartment = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      id: record.id,
    };
    AcceptBuried(
      "company - department_delete",
      "click",
      dataProps,
      props.location.pathname,
      "人资预估管理"
    );
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.deleteRelationshipCompanyDepartment)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("删除成功");
          record.status = 0;
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("公司和部门关系逻辑删除接口出错,请联系IT");
      });
  };
  // 公司和部门关系逻辑恢复接口
  const backedRelationshipCompanyDepartment = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      id: record.id,
    };
    AcceptBuried(
      "company - department_multiplexing",
      "click",
      dataProps,
      props.location.pathname,
      "人资预估管理"
    );
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.backedRelationshipCompanyDepartment)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("复用成功");
          record.status = 1;
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("公司和部门关系逻辑删除接口出错,请联系IT");
      });
  };

  // 查询公司-部门关系
  const getRelationshipCompanyDepartment = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getRelationshipCompanyDepartment)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setCompanyDataSource(res.data.data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询公司和部门之间的关系出错,请联系IT");
      });
  };
  // 部门和项目关系逻辑删除接口
  const deleteRelationshipDepartmentItem = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      id: record.id,
    };
    setLoading(true);
    AcceptBuried(
      "department - project_delete",
      "click",
      dataProps,
      props.location.pathname,
      "人资预估管理"
    );
    NbAxios(dataProps, "POST", servicePath.deleteRelationshipDepartmentItem)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("删除成功");
          record.status = 0;
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("部门和项目关系逻辑删除接口出错,请联系IT");
      });
  };
  // 部门和项目关系逻辑恢复接口
  const backedRelationshipDepartmentItem = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      id: record.id,
    };
    AcceptBuried(
      "department - project_multiplexing",
      "click",
      dataProps,
      props.location.pathname,
      "人资预估管理"
    );
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.backedRelationshipDepartmentItem)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("复用成功");
          record.status = 1;
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("部门和项目关系逻辑恢复接口出错,请联系IT");
      });
  };
  // 部门项目关系查询
  const getRelationshipDepartmentItem = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getRelationshipDepartmentItem)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setDepartmantDataSource(res.data.data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询部门与项目之间的关系出错,请联系IT");
      });
  };
  // 项目和店铺关系查询接口
  const getRelationshipItemShop = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getRelationshipItemShop)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setShopDataSource(res.data.data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("项目和店铺关系查询接口出错,请联系IT");
      });
  };
  // 项目和店铺关系逻辑删除接口
  const deleteRelationshipItemShop = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      id: record.id,
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.deleteRelationshipItemShop)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("删除成功");
          record.status = 0;
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("项目和店铺关系逻辑删除接口出错,请联系IT");
      });
  };
  // 项目和店铺关系逻辑删除恢复接口
  const backedRelationshipItemShop = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      id: record.id,
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.backedRelationshipItemShop)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("复用成功");
          record.status = 1;
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("项目和店铺关系逻辑删除恢复接口出错,请联系IT");
      });
  };
  // 查询公司列表
  const getCompany = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getCompany)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.company_id_list.map((item) => {
            obj.push({
              id: item.company_id,
              value: item.company,
            });
          });
          // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start
          let selectObj = [];
          res.data.company_select_list.map((item) => {
            selectObj.push({
              id: item.company_id,
              value: item.company,
            });
          });
          setCompanyList(obj);
          setCompanySelectList(selectObj);
          // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end
          setCompanyTableDataSource(res.data.company_data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询公司列表出错,请联系IT");
      });
  };
  // 查询部门列表
  const getDepartment = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getDepartment)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.department_id_list.map((item) => {
            obj.push({
              id: item.department_id,
              value: item.department,
            });
          });
          // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start
          let selectObj = [];
          res.data.department_select_list.map((item) => {
            selectObj.push({
              id: item.department_id,
              value: item.department,
            });
          });
          setDepartmentList(obj);
          setDepartmentSelectList(selectObj);
          // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end
          setDepartmentTableDataSource(res.data.department_data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询部门列表出错,请联系IT");
      });
  };
  // 查询项目列表
  const getItem = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getItem)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.item_id_list.map((item) => {
            obj.push({
              id: item.item_id,
              value: item.item,
            });
          });
          // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start
          let selectObj = [];
          res.data.item_select_list.map((item) => {
            selectObj.push({
              id: item.item_id,
              value: item.item,
            });
          });
          setProjectList(obj);
          setProjectSelectList(selectObj);
          // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end
          setProjectTableDataSource(res.data.item_data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询项目列表出错,请联系IT");
      });
  };
  // 查询店铺列表
  const getShop = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getShop)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.shop_id_list.map((item) => {
            obj.push({
              id: item.shop_id,
              value: item.shop,
            });
          });
          // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start
          let selectObj = [];
          res.data.shop_select_list.map((item) => {
            selectObj.push({
              id: item.shop_id,
              value: item.shop,
            });
          });
          setShopList(obj);
          setShopSelectList(selectObj);
          // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end
          setShopTableDataSource(res.data.shop_data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询店铺列表出错,请联系IT");
      });
  };
  // 查询岗位列表
  const getStation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getStation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.station_id_list.map((item) => {
            obj.push({
              id: item.station_id,
              value: item.station,
            });
          });
          setPostList(obj);
          setPostDataSource(res.data.station_data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询岗位列表出错,请联系IT");
      });
  };
  // 查询BP管理人
  const getBPList = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getBPList)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.data_list.map((item) => {
            obj.push({
              id: item.user_id,
              value: item.user_name,
            });
          });
          setBPList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询BP管理人出错,请联系IT");
      });
  };
  // 查询社保缴纳地接口
  const getSocialSecurityStation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getSocialSecurityStation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.map((item) => {
            obj.push({
              id: item,
              value: item,
            });
          });
          setSocialAddList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询社保缴纳地接口出错,请联系IT");
      });
  };
  // 公司修改接口
  const changeCompany = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.changeCompany)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          // 重新获取公司列表及下拉框
          getCompany(dataProps);
          // 重新获取公司部门关系
          getRelationshipCompanyDepartment(dataProps);
          setUpdateStaffProjectModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("公司修改接口出错,请联系IT");
      });
  };
  // 部门修改接口
  const changeDepartment = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.changeDepartment)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          // 重新获取部门列表及下拉框
          getDepartment(dataProps);
          // 重新获取公司部门关系
          getRelationshipCompanyDepartment(dataProps);
          // 重新获取部门项目关系
          getRelationshipDepartmentItem(dataProps);
          setUpdateStaffProjectModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("部门修改接口出错,请联系IT");
      });
  };
  // 项目修改接口
  const changeItem = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.changeItem)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          // 重新获取部门列表及下拉框
          getItem(dataProps);
          // 重新获取部门项目关系
          getRelationshipDepartmentItem(dataProps);
          // 重新获取项目店铺关系
          getRelationshipItemShop(dataProps);
          setUpdateStaffProjectModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("项目修改接口出错,请联系IT");
      });
  };
  // 店铺修改接口
  const changeShop = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.changeShop)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          // 重新获取部门列表及下拉框
          getShop(dataProps);
          // 重新获取项目店铺关系
          getRelationshipItemShop(dataProps);
          setUpdateStaffProjectModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("店铺修改接口出错,请联系IT");
      });
  };
  // 岗位修改接口
  const changeStation = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.changeStation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          // 重新获取岗位列表及下拉框
          getStation(dataProps);
          setUpdateStaffProjectModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("岗位修改接口出错,请联系IT");
      });
  };
  // 员工项目信息修改接口
  const changeMemberItemInformation = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.changeMemberItemInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            employeeId: staffID,
            member_name: staffName,
            department_id: department,
            item_id: project,
            shop_id: shop,
            station_id: post,
            member_type: staffType,
          };
          // 重新获取员工项目信息
          getMemberItemInformation(dataProps);
          setUpdateStaffProjectModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工项目信息修改接口出错,请联系IT");
      });
  };
  // 公司新增接口
  const addCompany = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.addCompany)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          // let obj = [...companyTableDataSource]
          // obj[updateStaffIndex] = updateStaff
          // setCompanyTableDataSource(obj)
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          // 重新获取公司列表及下拉框
          getCompany(dataProps);
          setInsertPostModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("公司新增接口出错,请联系IT");
      });
  };
  // 部门新增接口
  const addDepartment = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.addDepartment)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          // 重新获取部门列表及下拉框
          getDepartment(dataProps);
          setInsertPostModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("部门新增接口出错,请联系IT");
      });
  };
  // 项目新增接口
  const addItem = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.addItem)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          // 重新获取项目列表及下拉框
          getItem(dataProps);
          setInsertPostModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("项目新增接口出错,请联系IT");
      });
  };
  // 店铺新增接口
  const addHRShop = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.addHRShop)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          // 重新获取店铺列表及下拉框
          getShop(dataProps);
          setInsertPostModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("店铺新增接口出错,请联系IT");
      });
  };
  // 岗位新增接口
  const addStation = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.addStation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          // 重新获取岗位列表及下拉框
          getStation(dataProps);
          setInsertPostModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("岗位新增接口出错,请联系IT");
      });
  };
  // 员工项目信息查询接口
  const getMemberItemInformation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getMemberItemInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setProjectDataSource(res.data.data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工项目信息查询接口出错,请联系IT");
      });
  };
  // 员工ID列表查询
  const getMemberID = () => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getEmployeeIdList)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          let idList = [];
          res.data.data_list.map((item) => {
            obj.push({
              id: item["员工ID"] + "-" + item["姓名"],
              value: item["员工ID"] + "-" + item["姓名"],
            });
            idList.push(item["员工ID"]);
          });
          // 员工ID去重
          let uniqueIdList = [...new Set(idList)];
          uniqueIdList.sort((a, b) => {
            return a - b; // 升序
          });
          setStaffIDList(uniqueIdList);
          setStaffList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工ID列表查询出错,请联系IT");
      });
  };
  // 员工基础信息查询
  const getMemberInformation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getMemberInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          res.data.data_list.map((item) => {
            staffTypeList.map((obj) => {
              if (obj.id == item["员工类型ID"]) {
                item["员工类型"] = obj.value;
              }
            });
            staffStateList.map((obj) => {
              if (obj.id == item["员工状态ID"]) {
                item["员工状态"] = obj.value;
              }
            });
            item["状态ID"] = item["状态"];
          });
          setDataSource(res.data.data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工基础信息查询接口出错,请联系IT");
      });
  };
  // 员工基础信息修改接口
  const changeMemberInformation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeMemberInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            employeeId: staffID,
            member_name: staffName,
            company_id: company,
            birthday: birthdayMonth,
            member_type: staffType,
          };
          getMemberInformation(dataProps);
          getMemberID();
          setUpdateStaffInfoModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工基础信息修改接口出错,请联系IT");
      });
  };
  // 员工薪酬信息查询接口
  const getMemberPayInformation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getMemberPayInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          res.data.data_list.map((item, index) => {
            item["id"] = item["员工ID"] + item["开始年份月份日期"];
          });
          setPayDataSource(res.data.data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工薪酬信息查询接口出错,请联系IT");
      });
  };
  // 员工基础信息新增接口
  const addMemberInformation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addMemberInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          setInsertStaffInfoModel(false);
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffName,
            company_id: company,
            birthday: birthdayMonth,
            member_type: staffType,
          };
          getMemberInformation(dataProps);
          getMemberID();
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工基础信息新增接口出错,请联系IT");
      });
  };
  // 员工薪酬信息新增接口
  const addMemberPayInformation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addMemberPayInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffName,
            department_id: department,
            item_id: project,
            shop_id: shop,
            station_id: post,
            member_type: staffType,
          };
          getMemberPayInformation(dataProps);
          setInsertPayModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工薪酬信息新增接口出错,请联系IT");
      });
  };
  // 员工薪酬信息修改接口
  const changeMemberPayInformation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeMemberPayInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffName,
            department_id: department,
            item_id: project,
            shop_id: shop,
            station_id: post,
            member_type: staffType,
          };
          getMemberPayInformation(dataProps);
          setUpdatePayModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工薪酬信息新增接口出错,请联系IT");
      });
  };
  // 获取社保基数列表
  const getSocialBaseList = () => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
      change_member: cookie.load("name"),
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.findSocialSecurity)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.map((item) => {
            obj.push(item["社保基数"]);
          });
          let uniqueBase = [...new Set(obj)];
          uniqueBase.sort((a, b) => {
            return a - b; // 升序
          });
          // 将数组改成字典
          setSocialBaseList(
            uniqueBase.reduce((result, item) => {
              result.push({
                id: item,
                value: item,
              });
              return result;
            }, [])
          );
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("获取社保基数列表接口出错,请联系IT");
      });
  };
  // 员工社保基数查询接口
  const findSocialSecurity = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.findSocialSecurity)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setSocialBaseEstimateDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询社保基数表参数接口出错,请联系IT");
      });
  };
  // 社保基数判别信息查询
  const searchNewWorkerSocialSecurity = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.searchNewWorkerSocialSecurity)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setSocialBaseDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("社保基数判别信息查询接口出错,请联系IT");
      });
  };
  // 员工社保缴纳系数查询接口
  const searchWorkerSocialSecuritySubject = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.searchWorkerSocialSecuritySubject)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setSocialPayDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("社保缴纳费用参数查询接口出错,请联系IT");
      });
  };
  // 获取公积金基数列表
  const getAccumulationFundBaseList = () => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
      change_member: cookie.load("name"),
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.searchWorkerAccumulationFundEstimate)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.map((item) => {
            obj.push(item["公积金基数"]);
          });
          let uniqueBase = [...new Set(obj)];
          uniqueBase.sort((a, b) => {
            return a - b; // 升序
          });
          // 将数组改成字典
          setAccumulationFundBaseList(
            uniqueBase.reduce((result, item) => {
              result.push({
                id: item,
                value: item,
              });
              return result;
            }, [])
          );
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("获取公积金基数列表接口出错,请联系IT");
      });
  };
  // 查询公积金基数判别预估接口
  const searchWorkerAccumulationFund = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.searchWorkerAccumulationFund)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setAccumulationFundBaseDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询公积金基数判别预估接口出错,请联系IT");
      });
  };
  // 个人所得税变更规则查询接口
  const findPersonalIncomeTaxMaintain = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.findPersonalIncomeTaxMaintain)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setTaxDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("个人所得税变更规则查询接口出错,请联系IT");
      });
  };
  // 查询公积金基数表接口
  const searchWorkerAccumulationFundEstimate = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.searchWorkerAccumulationFundEstimate)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setAccumulationFundEstimateDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询公积金基数表接口出错,请联系IT");
      });
  };
  // 新增新人社保基数判别
  const addNewWorkerSocialSecurity = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addNewWorkerSocialSecurity)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            social_security_cardinal: socialBase,
          };
          searchNewWorkerSocialSecurity(dataProps);
          setInsertSocialModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("新增新人社保基数判别出错,请联系IT");
      });
  };
  // 新增社保缴纳费用参数接口
  const addSocialSecuritySubject = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addSocialSecuritySubject)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            social_security_cardinal: socialBase,
          };
          searchWorkerSocialSecuritySubject(dataProps);
          setInsertSocialModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("新增社保缴纳费用参数接口出错,请联系IT");
      });
  };
  // 新增社保基数接口
  const addSocialSecurityBase = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addSocialSecurityBase)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            social_security_cardinal: socialBase,
          };
          searchNewWorkerSocialSecurity(dataProps);
          setInsertSocialModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("新增社保基数接口出错,请联系IT");
      });
  };
  // 员工社保基数修改接口
  const changeSocialSecurity = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeSocialSecurity)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            social_security_cardinal: socialBase,
          };
          setUpdateSocialBaseModel(false);
          searchNewWorkerSocialSecurity(dataProps);
        } else {
          alert(res.errorMsg);
          // alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工社保基数修改接口出错,请联系IT");
      });
  };
  // 社保缴纳系数修改接口
  const changeSocialSecuritySubject = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeSocialSecuritySubject)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            social_security_cardinal: socialBase,
          };
          setUpdateSocialBaseModel(false);
          setSplitTimeModel(false);
          searchWorkerSocialSecuritySubject(dataProps);
        } else {
          {
            /* #I661M1  NB20人资社保公积金编辑优化 start*/
          }
          // alert(res.errorMsg);
          alert(res.errorMsg);
          {
            /* #I661M1  NB20人资社保公积金编辑优化 end*/
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("社保缴纳系数修改接口出错,请联系IT");
      });
  };
  // 员工社保基数新增修改接口
  const addChangeSocialSecurity = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addChangeSocialSecurity)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            social_security_cardinal: socialBase,
          };
          setUpdateSocialBaseModel(false);
          findSocialSecurity(dataProps);
          getSocialBaseList();
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工社保基数新增修改接口出错,请联系IT");
      });
  };
  // 公积金基数判断修改数据接口
  const changeAccumulationFundJudgmentMaintain = (dataProps) => {
    setLoading(true);
    NbAxios(
      dataProps,
      "POST",
      servicePath.changeAccumulationFundJudgmentMaintain
    )
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            accumulation_fund_cardinal: accumulationFundBase,
          };
          setUpdateAccumulationFundModel(false);
          setSplitAccumulationFundPayModel(false);
          setSplitAccumulationFundTimeModel(false);
          searchWorkerAccumulationFund(dataProps);
        } else {
          {
            /* #I661M1  NB20人资社保公积金编辑优化 start*/
          }
          // alert(res.errorMsg);
          alert(res.errorMsg);
          {
            /* #I661M1  NB20人资社保公积金编辑优化 end*/
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("公积金基数判断修改数据接口出错,请联系IT");
      });
  };
  // 个人所得税变更规则修改接口
  const addPersonalIncomeTaxMaintain = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addPersonalIncomeTaxMaintain)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
          };
          setUpdateTaxModel(false);
          setSplitTaxPayModel(false);
          setSplitTaxTimeModel(false);
          findPersonalIncomeTaxMaintain(dataProps);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("个人所得税变更规则修改接口出错,请联系IT");
      });
  };
  // 个人所得税变更规则新增接口
  const changePersonalIncomeTaxMaintain = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changePersonalIncomeTaxMaintain)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
          };
          setInsertTaxModel(false);
          findPersonalIncomeTaxMaintain(dataProps);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("个人所得税变更规则新增接口出错,请联系IT");
      });
  };
  // 员工社保基数新增接口
  const addSocialSecurity = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addSocialSecurity)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            social_security_cardinal: socialBase,
          };
          setInsertSocialModel(false);
          findSocialSecurity(dataProps);
          getSocialBaseList();
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工社保基数新增接口出错,请联系IT");
      });
  };
  // 员工社保基数修改接口
  const changeSocialSecurityThird = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeSocialSecurityThird)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            social_security_cardinal: socialBase,
          };
          setUpdateSocialBaseModel(false);
          findSocialSecurity(dataProps);
          getSocialBaseList();
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工社保基数修改接口出错,请联系IT");
      });
  };
  // 公积金基数新增接口
  const addAccumulationFund = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addAccumulationFund)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            accumulation_fund_cardinal: accumulationFundBase,
          };
          setInsertAccumulationFundModel(false);
          searchWorkerAccumulationFundEstimate(dataProps);
          getAccumulationFundBaseList();
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("公积金基数新增接口出错,请联系IT");
      });
  };
  // 公积金基数判断新增数据接口
  const addAccumulationFundJudgmentMaintain = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addAccumulationFundJudgmentMaintain)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            accumulation_fund_cardinal: accumulationFundBase,
          };
          setInsertAccumulationFundModel(false);
          searchWorkerAccumulationFund(dataProps);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("公积金基数判断新增数据接口出错,请联系IT");
      });
  };
  // 公积金基数修改接口
  const changeAccumulationFund = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeAccumulationFund)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            accumulation_fund_cardinal: accumulationFundBase,
          };
          setUpdateAccumulationFundModel(false);
          searchWorkerAccumulationFundEstimate(dataProps);
          getAccumulationFundBaseList();
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("公积金基数修改接口出错,请联系IT");
      });
  };
  // 重新计算接口
  const recalculate = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.recalculate)
      .then((res) => {
        if (res.success) {
          setLoading(false);
          message.success("重新计算成功");
        } else {
          setLoading(false);
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("重新计算接口出错,请联系IT");
      });
  };
  // // 判断薪资专员接口
  // const judgeSalarySpecialist = (dataProps) => {
  //     setLoading(true)
  //     NbAxios(dataProps, "POST", servicePath.judgeSalarySpecialist).then(res => {
  //         setLoading(false)
  //         if (res.success) {
  //             setSalarySpecialist(res.data)
  //         } else {
  //             alert(res.errorMsg);
  //         }
  //     }).catch(err => {
  //         setLoading(false)
  //         message.warn('判断薪资专员接口出错,请联系IT')
  //     })

  // }
  // 拆分表查询所有矩阵接口
  const getAllSubjectName = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getAllSubjectName)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setFormDataSource(res.data.data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("拆分表查询所有矩阵接口出错,请联系IT");
      });
  };
  // 拆分表删除缓存接口
  const deleteAllSubject = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      id: record.ID,
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
      change_member: cookie.load("name"),
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.deleteAllSubject)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("删除成功");
          getAllSubjectName(dataProps);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("拆分表删除缓存接口出错,请联系IT");
      });
  };
  // 员工社保基数删除接口
  const deleteSocialSecurity = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      employeeId: record["员工ID"],
      ID: record["ID"],
      start_year_month_day: record["开始年份月份日期"],
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
      change_member: cookie.load("name"),
      change_time: record["变更时间"],
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.deleteSocialSecurity)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("删除成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            change_member: cookie.load("name"),
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            social_security_cardinal: socialBase,
          };
          findSocialSecurity(dataProps);
          getSocialBaseList();
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工社保基数删除接口出错,请联系IT");
      });
  };
  // 公积金基数删除接口
  const deleteAccumulationFund = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      employeeId: record["员工ID"],
      ID: record["ID"],
      start_year_month_day: record["开始年份月份日期"],
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
      change_member: cookie.load("name"),
      change_time: record["变更时间"],
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.deleteAccumulationFund)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("删除成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            change_member: cookie.load("name"),
            name: staffName,
            employeeId: staffID,
            social_security_station: socialAdd,
            accumulation_fund_cardinal: accumulationFundBase,
          };
          searchWorkerAccumulationFundEstimate(dataProps);
          getAccumulationFundBaseList();
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("公积金基数删除接口出错,请联系IT");
      });
  };
  // 查询NB用户映射接口
  const getUserId = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getUserId)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setIsFinance(res.data.is_finance);
          setSalarySpecialist(res.data.remuneration_specialist);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询NB用户映射接口出错,请联系IT");
      });
  };
  // 员工薪酬信息单个修改接口
  const changeMemberPayInformationSingle = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeMemberPayInformationSingle)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffName,
            department_id: department,
            item_id: project,
            shop_id: shop,
            station_id: post,
            member_type: staffType,
          };
          getMemberPayInformation(dataProps);
          setUpdateOnePayModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工薪酬信息单个修改接口出错,请联系IT");
      });
  };
  // 薪酬表中的切分时间接口
  const splitSalaryTime = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.splitSalaryTime)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffName,
            department_id: department,
            item_id: project,
            shop_id: shop,
            station_id: post,
            member_type: staffType,
          };
          setSelectedPayRowKeys([]);
          getMemberPayInformation(dataProps);
          setUpdateMonthPayModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("薪酬表切分时间接口出错,请联系IT");
      });
  };
  // 查询社保基数范围接口
  const findSocialSecurityRange = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.findSocialSecurityRange)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setSocialBaseRangeEstimateDataSource(res.data.data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询社保基数范围接口出错,请联系IT");
      });
  };
  // 新增社保基数范围接口
  const addSocialSecurityRange = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addSocialSecurityRange)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            change_member: cookie.load("name"),
          };
          findSocialSecurityRange(dataProps);
          setInsertSocialModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("新增社保基数范围接口出错,请联系IT");
      });
  };
  // 修改社保基数范围接口
  const changeSocialSecurityRange = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeSocialSecurityRange)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            change_member: cookie.load("name"),
          };
          findSocialSecurityRange(dataProps);
          setUpdateSocialRangeModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("修改社保基数范围接口出错,请联系IT");
      });
  };
  // 查询公积金基数范围接口
  const findWorkerAccumulationRange = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.findWorkerAccumulationRange)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setAccumulationFundRangeEstimateDataSource(res.data.data_list);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询公积金基数范围接口出错,请联系IT");
      });
  };
  // 新增公积金基数范围接口
  const addWorkerAccumulationRange = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addWorkerAccumulationRange)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            change_member: cookie.load("name"),
          };
          findWorkerAccumulationRange(dataProps);
          setInsertAccumulationFundModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("新增公积金基数范围接口出错,请联系IT");
      });
  };
  // 修改公积金基数范围接口
  const changeWorkerAccumulationRange = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeWorkerAccumulationRange)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
            change_member: cookie.load("name"),
          };
          findWorkerAccumulationRange(dataProps);
          setUpdateAccumulationFundRangeModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("修改公积金基数范围接口出错,请联系IT");
      });
  };
  // 预估到预算数据同步接口
  const estimateToBudgetDataSynchronous = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.estimateToBudgetDataSynchronous)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("数据同步成功");
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("预估到预算数据同步接口接口出错,请联系IT");
      });
  };
  {
    /* #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 start*/
  }
  // 社保基数预估表单下载接口
  const downloadSocialSecurityEstimate = () => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      name: cookie.load("name"),
      // #I6DH9C NB20人资系统员工、项目、薪酬信息下载接口加nb_id参数 start
      nb_user_id: cookie.load("id"),
      // #I6DH9C NB20人资系统员工、项目、薪酬信息下载接口加nb_id参数 end
      calculation_type: calculation_type,
    };
    setLoading(true);
    axios({
      data: dataProps,
      method: "POST",
      url: servicePath.downloadSocialSecurityEstimate,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json", //如果写成contentType会报错
        authorization: cookie.load("token"),
      },
    })
      .then((res) => {
        setLoading(false);
        downExcel(res);
      })
      .catch((e) => {
        setLoading(false);
        message.error({ content: "文件制作出错，请联系it同事！" });
      });
  };
  // 公积金基数预估表单下载接口
  const downloadAccumulationFundEstimate = () => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      name: cookie.load("name"),
      // #I6DH9C NB20人资系统员工、项目、薪酬信息下载接口加nb_id参数 start
      nb_user_id: cookie.load("id"),
      // #I6DH9C NB20人资系统员工、项目、薪酬信息下载接口加nb_id参数 end
      calculation_type: calculation_type,
    };
    setLoading(true);
    axios({
      data: dataProps,
      method: "POST",
      url: servicePath.downloadAccumulationFundEstimate,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json", //如果写成contentType会报错
        authorization: cookie.load("token"),
      },
    })
      .then((res) => {
        setLoading(false);
        downExcel(res);
      })
      .catch((e) => {
        setLoading(false);
        message.error({ content: "文件制作出错，请联系it同事！" });
      });
  };
  // 员工项目信息下载接口
  const downloadMemberItemInformation = () => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      name: cookie.load("name"),
      // #I6DH9C NB20人资系统员工、项目、薪酬信息下载接口加nb_id参数 start
      nb_user_id: cookie.load("id"),
      // #I6DH9C NB20人资系统员工、项目、薪酬信息下载接口加nb_id参数 end
      calculation_type: calculation_type,
      employeeId: staffID,
      member_name: staffName,
      company_id: company,
      birthday: birthdayMonth,
      member_type: staffType,
    };
    setLoading(true);
    AcceptBuried(
      "Downloader - project_information",
      "click",
      {},
      props.location.pathname,
      "人资预估管理"
    );
    axios({
      data: dataProps,
      method: "POST",
      url: servicePath.downloadMemberItemInformation,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json", //如果写成contentType会报错
        authorization: cookie.load("token"),
      },
    })
      .then((res) => {
        setLoading(false);
        downExcel(res);
      })
      .catch((e) => {
        setLoading(false);
        message.error({ content: "文件制作出错，请联系it同事！" });
      });
  };
  // 员工薪酬信息下载接口
  const downloadMemberPayInformation = () => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      name: cookie.load("name"),
      // #I6DH9C NB20人资系统员工、项目、薪酬信息下载接口加nb_id参数 start
      nb_user_id: cookie.load("id"),
      // #I6DH9C NB20人资系统员工、项目、薪酬信息下载接口加nb_id参数 end
      calculation_type: calculation_type,
      employeeId: staffID,
      member_name: staffName,
      department_id: department,
      item_id: project,
      shop_id: shop,
      station_id: post,
    };
    setLoading(true);
    axios({
      data: dataProps,
      method: "POST",
      url: servicePath.downloadMemberPayInformation,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json", //如果写成contentType会报错
        authorization: cookie.load("token"),
      },
    })
      .then((res) => {
        setLoading(false);
        downExcel(res);
      })
      .catch((e) => {
        setLoading(false);
        message.error({ content: "文件制作出错，请联系it同事！" });
      });
  };
  // 员工表单下载接口
  const downloadMemberEstimate = () => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      name: cookie.load("name"),
      // #I6DH9C NB20人资系统员工、项目、薪酬信息下载接口加nb_id参数 start
      nb_user_id: cookie.load("id"),
      // #I6DH9C NB20人资系统员工、项目、薪酬信息下载接口加nb_id参数 end
      calculation_type: calculation_type,
      employeeId: staffID,
      member_name: staffName,
      company_id: company,
      birthday: birthdayMonth,
      member_type: staffType,
    };
    setLoading(true);
    axios({
      data: dataProps,
      method: "POST",
      url: servicePath.downloadMemberEstimate,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json", //如果写成contentType会报错
        authorization: cookie.load("token"),
      },
    })
      .then((res) => {
        setLoading(false);
        downExcel(res);
      })
      .catch((e) => {
        setLoading(false);
        message.error({ content: "文件制作出错，请联系it同事！" });
      });
  };
  {
    /* #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 end*/
  }
  // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 start
  // 员工基础信息删除接口
  const deleteMemberInformation = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      employeeId: record["员工ID"],
      entry_time: record["入职时间"],
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
      change_member: cookie.load("name"),
      change_time: record["变更时间"],
    };
    AcceptBuried(
      "Delete_employee_information",
      "click",
      dataProps,
      props.location.pathname,
      "人资预估管理"
    );
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.deleteMemberInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("删除成功");
          let obj = [...dataSource];
          obj.remove(record);
          setDataSource(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工基础信息删除接口出错,请联系IT");
      });
  };
  // 员工项目信息删除接口
  const deleteMemberItemInformation = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      employeeId: record["员工ID"],
      department_id: record["部门ID"],
      item_id: record["项目ID"],
      shop_id: record["店铺ID"],
      station_id: record["岗位ID"],
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
      change_member: cookie.load("name"),
      start_year_month_day: record["开始年份月份日期"],
    };
    AcceptBuried(
      "Delete_Person - Project_Information",
      "click",
      dataProps,
      props.location.pathname,
      "人资预估管理"
    );
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.deleteMemberItemInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("删除成功");
          let obj = [...projectDataSource];
          obj.remove(record);
          setProjectDataSource(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工项目信息删除接口出错,请联系IT");
      });
  };
  // 员工薪酬信息删除接口
  const deleteMemberPayInformation = (record) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      employeeId: record["员工ID"],
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
      change_member: cookie.load("name"),
      start_year_month_day: record["开始年份月份日期"],
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.deleteMemberPayInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("删除成功");
          let obj = [...payDataSource];
          obj.remove(record);
          setPayDataSource(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工薪酬信息删除接口出错,请联系IT");
      });
  };
  // 上传Excel函数
  const uploadFile = () => {
    if (fileList.length == 0) {
      message.error("请选择文件");
    } else {
      setLoading(true);
      const formData = new FormData();
      fileList.forEach((item) => {
        formData.append("file", item["originFileObj"]);
      });
      formData.append("calculation_type", calculation_type);
      formData.append("shopList", cookie.load("shopList"));
      formData.append("roleId", cookie.load("role"));
      formData.append("change_member", cookie.load("name"));
      formData.append("nb_user_id", cookie.load("id"));
      NbAxios(formData, "POST", servicePath.uploadMemberPayInformation)
        .then((res) => {
          setFileList([]);
          setLoading(false);
          if (res.success) {
            message.success("导入成功");
            setExportFlag(2);
            setErrorMsg("导入成功！");
            let dataProps = {
              roleId: cookie.load("role"),
              shopList: cookie.load("shopList"),
              shopId: cookie.load("shopId"),
              change_member: cookie.load("name"),
              nb_user_id: cookie.load("id"),
              calculation_type: calculation_type,
              employeeId: staffID,
              member_name: staffName,
              department_id: department,
              item_id: project,
              shop_id: shop,
              station_id: post,
              member_type: staffType,
            };
            getMemberPayInformation(dataProps);
            setIsUploadFile(false);
          } else {
            setExportFlag(3);
            message.error(res.errorMsg);
            setErrorMsg(res.errorMsg);
          }
        })
        .catch((err) => {
          setFileList([]);
          setLoading(false);
          setExportFlag(3);
          // 获取500错误
          message.warn("文件导入报错，请联系IT帮忙解决");
          setErrorMsg("文件导入报错，请联系IT帮忙解决");
        });
    }
  };
  // 文档列表改变的时候出发
  const fileHandleChange = ({ fileList }) => {
    if (fileList.length != 0) {
      // 获取最新文件的文件名
      let fileName = fileList[fileList.length - 1].originFileObj["name"];
      // 提取文件名的后缀名
      let fileExtension = fileName
        .substring(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
      // 定义允许的类型
      let fileTypes = ["xlsx"];
      // 首先判断文件上传的类型
      if (fileTypes.indexOf(fileExtension) != -1) {
        let flag =
          fileList[fileList.length - 1].originFileObj["size"] / 1024 / 1024 <
          50;
        // 在判断文件的大小
        if (flag) {
          setFileList(fileList);
        } else {
          message.error("文件大小必须小于50M");
          // 移除文件
          fileList.pop();
        }
      } else {
        message.error("文件类型不符合");
        // 移除文件
        fileList.pop();
      }
    } else {
      setFileList(fileList);
    }
  };
  // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 end
  return (
    <div className={style.initial}>
      <div className={style.externalContent}>
        <Tabs
          type="card"
          size="small"
          activeKey={tabKey}
          onChange={(key) => {
            // 触发重新计算接口
            if (key == "calculation") {
              let dataProps = {
                roleId: cookie.load("role"),
                shopList: cookie.load("shopList"),
                shopId: cookie.load("shopId"),
                change_member: cookie.load("name"),
                nb_user_id: cookie.load("id"),
                calculation_type: calculation_type,
              };
              recalculate(dataProps);
            } else if (key == "sync") {
              let dataProps = {
                roleId: cookie.load("role"),
                shopList: cookie.load("shopList"),
                shopId: cookie.load("shopId"),
                change_member: cookie.load("name"),
                nb_user_id: cookie.load("id"),
                calculation_type: calculation_type,
              };
              estimateToBudgetDataSynchronous(dataProps);
            } else {
              setTabKey(key);
              setStaffType([]);
              setStaffID([]);
              setStaffData("");
              setCompany([]);
              setBirthdayMonth([]);
              setDepartment([]);
              setProject([]);
              setPost([]);
              setShop([]);
              setStaffName("");
              setSocialBase([]);
              setSocialAdd([]);
              // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start
              setStartStrategy("");
              setEndStrategy("");
              setStartTimeFilter(null);
              setEndTimeFilter(null);
              // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end
              setStaffState([]);
              // AcceptBuried(
              //   fieldsDict[key],
              //   "click",
              //   {},
              //   props.location.pathname,
              //   "人资预估管理"
              // );
            }
          }}
        >
          <TabPane tab={<span>员工</span>} key="staff"></TabPane>
          <TabPane tab={<span>项目</span>} key="project"></TabPane>
          {isFinance !== 1 && (
            <TabPane tab={<span>薪酬</span>} key="pay"></TabPane>
          )}
          {isFinance !== 1 && salarySpecialist && (
            <TabPane tab={<span>社保</span>} key="socialSecurity"></TabPane>
          )}
          {isFinance !== 1 && salarySpecialist && (
            <TabPane tab={<span>公积金</span>} key="accumulationFund"></TabPane>
          )}
          {isFinance !== 1 && salarySpecialist && (
            <TabPane tab={<span>个税</span>} key="personalIncomeTax"></TabPane>
          )}
          {isFinance !== 1 && salarySpecialist && (
            <TabPane tab={<span>表单</span>} key="Form"></TabPane>
          )}
          {salarySpecialist && (
            <TabPane tab={<span>重新计算</span>} key="calculation"></TabPane>
          )}
          {salarySpecialist && (
            <TabPane tab={<span>数据同步</span>} key="sync"></TabPane>
          )}
        </Tabs>
        <div style={{ display: tabKey == "staff" ? "" : "none" }}>
          <div className={style.staffInfo}>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>员工ID</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  showSearch
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  value={staffID}
                  onChange={(value) => {
                    setStaffID(value);
                  }}
                >
                  {staffIDList.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>姓名</Text>
              </div>
              <div>
                <div>
                  {/* <Select className={style.staffInfoSelectWidth} allowClear value={staffData} onChange={(value) => {
                                        setStaffData(value)
                                    }}>
                                        {staffList.map(item => (
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select> */}
                  <Input
                    style={{ width: "240px" }}
                    allowClear
                    value={staffName}
                    placeholder="请填写 支持模糊查询"
                    onChange={(e) => {
                      setStaffName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>公司</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  value={company}
                  onChange={(value) => {
                    setCompany(value);
                  }}
                >
                  {companyList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>生日月份</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  value={birthdayMonth}
                  onChange={(value) => {
                    setBirthdayMonth(value);
                  }}
                >
                  {birthdayMonthlist.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {/* <div style={{ marginRight: '30px' }}>
                            <div>
                                <Text>手机号</Text>
                            </div>
                            <div>
                                <Select className={style.staffInfoSelectWidth} allowClear value={phone} onChange={(value) => {
                                    setPhone(value)
                                }}>
                                    {phoneList.map(item => (
                                        <Option value={item}>{item}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div> */}
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>员工类型</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  value={staffType}
                  onChange={(value) => {
                    setStaffType(value);
                  }}
                >
                  {staffTypeList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display:
                  tabKey == "additionalSum" || tabKey == "rewardSum"
                    ? "none"
                    : "",
              }}
            >
              <div>
                <Text>员工状态</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  mode="multiple"
                  maxTagCount="responsive"
                  value={staffState}
                  onChange={(value) => {
                    setStaffState(value);
                  }}
                >
                  {staffStateList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>入职时间筛选</Text>
              </div>
              <div>
                <Select
                  style={{ width: "80px", marginRight: "20px" }}
                  allowClear
                  value={startStrategy}
                  onChange={(value) => {
                    setStartStrategy(value);
                  }}
                >
                  {strategyList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
                <DatePicker
                  value={startTimeFilter}
                  locale={locale}
                  onChange={(value, dateString) => {
                    setStartTimeFilter(value);
                  }}
                />
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>离职时间筛选</Text>
              </div>
              <div>
                <Select
                  style={{ width: "80px", marginRight: "20px" }}
                  allowClear
                  value={endStrategy}
                  onChange={(value) => {
                    setEndStrategy(value);
                  }}
                >
                  {strategyList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
                <DatePicker
                  value={endTimeFilter}
                  locale={locale}
                  onChange={(value, dateString) => {
                    setEndTimeFilter(value);
                  }}
                />
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
              </div>
            </div>
          </div>
          <div className={style.ButtonEnd} style={{ marginTop: "20px" }}>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  shopId: cookie.load("shopId"),
                  nb_user_id: cookie.load("id"),
                  calculation_type: calculation_type,
                  employeeId: staffID,
                  member_name: staffName,
                  company_id: company,
                  birthday: birthdayMonth,
                  member_type: staffType,
                  // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start
                  entry_time: startTimeFilter
                    ? startTimeFilter.format(dateFormat)
                    : startTimeFilter,
                  start_select: startStrategy,
                  leave_time: endTimeFilter
                    ? endTimeFilter.format(dateFormat)
                    : endTimeFilter,
                  end_select: endStrategy,
                  // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end
                  member_status: staffState,
                };
                let burieProps = {
                  Employee_ID: staffID.toString(),
                  full_name: staffName,
                  company: company.toString(),
                  Month_of_Birth: birthdayMonth.toString(),
                  Employee_type: staffType.toString(),
                  Employee_status: staffState.toString(),
                  Entry_time_filter: startTimeFilter
                    ? startTimeFilter.format(dateFormat)
                    : startTimeFilter,
                  Resignation_time_filter: endTimeFilter
                    ? endTimeFilter.format(dateFormat)
                    : endTimeFilter,
                  calculation_type: calculation_type,
                };
                AcceptBuried(
                  "Query_employee_information",
                  "change",
                  burieProps,
                  props.location.pathname,
                  "人资预估管理"
                );
                getMemberInformation(dataProps);
              }}
            >
              查询
            </Button>
            <Button
              size="small"
              style={{
                borderColor: "#0091FF",
                color: "#0091FF",
                borderRadius: "3px",
                textAlign: "center",
                margin: "0px 20px 0px 20px",
              }}
              onClick={() => {
                setStaffID([]);
                setStaffName("");
                setCompany([]);
                setBirthdayMonth([]);
                setStaffType([]);
                setStaffState([]);
                setStartStrategy("");
                setEndStrategy("");
                setEndTimeFilter(null);
                setStartTimeFilter(null);
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  shopId: cookie.load("shopId"),
                  nb_user_id: cookie.load("id"),
                  calculation_type: calculation_type,
                };
                AcceptBuried(
                  "Reset_employee_information",
                  "click",
                  {},
                  props.location.pathname,
                  "人资预估管理"
                );
                getMemberInformation(dataProps);
              }}
            >
              重置
            </Button>
          </div>
        </div>
        <div
          style={{
            display: tabKey == "project" || tabKey == "pay" ? "" : "none",
          }}
        >
          <div className={style.staffInfo}>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>员工ID</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  showSearch
                  value={staffID}
                  onChange={(value) => {
                    setStaffID(value);
                  }}
                >
                  {staffIDList.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>姓名</Text>
              </div>
              <div>
                <Input
                  style={{ width: "240px" }}
                  allowClear
                  value={staffName}
                  placeholder="请填写 支持模糊查询"
                  onChange={(e) => {
                    setStaffName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>部门</Text>
              </div>
              <div>
                <div>
                  <Select
                    className={style.staffInfoSelectWidth}
                    allowClear
                    mode="multiple"
                    maxTagCount="responsive"
                    optionFilterProp="title"
                    value={department}
                    onChange={(value) => {
                      setDepartment(value);
                    }}
                  >
                    {departmentList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>项目</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  optionFilterProp="title"
                  value={project}
                  onChange={(value) => {
                    setProject(value);
                  }}
                >
                  {projectList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>店铺</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  optionFilterProp="title"
                  value={shop}
                  onChange={(value) => {
                    setShop(value);
                  }}
                >
                  {shopList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>岗位</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  allowClear
                  mode="multiple"
                  optionFilterProp="title"
                  value={post}
                  onChange={(value) => {
                    setPost(value);
                  }}
                >
                  {postList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>员工类型</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  value={staffType}
                  onChange={(value) => {
                    setStaffType(value);
                  }}
                >
                  {staffTypeList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display:
                  tabKey == "additionalSum" || tabKey == "rewardSum"
                    ? "none"
                    : "",
              }}
            >
              <div>
                <Text>员工状态</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  mode="multiple"
                  maxTagCount="responsive"
                  value={staffState}
                  onChange={(value) => {
                    setStaffState(value);
                  }}
                >
                  {staffStateList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>开始时间筛选</Text>
              </div>
              <div>
                <Select
                  style={{ width: "80px", marginRight: "20px" }}
                  allowClear
                  value={startStrategy}
                  onChange={(value) => {
                    setStartStrategy(value);
                  }}
                >
                  {strategyList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
                <DatePicker
                  value={startTimeFilter}
                  locale={locale}
                  onChange={(value, dateString) => {
                    setStartTimeFilter(value);
                  }}
                />
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>结束时间筛选</Text>
              </div>
              <div>
                <Select
                  style={{ width: "80px", marginRight: "20px" }}
                  allowClear
                  value={endStrategy}
                  onChange={(value) => {
                    setEndStrategy(value);
                  }}
                >
                  {strategyList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
                <DatePicker
                  value={endTimeFilter}
                  locale={locale}
                  onChange={(value, dateString) => {
                    setEndTimeFilter(value);
                  }}
                />
              </div>
            </div>
            {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end */}
          </div>
          <div className={style.ButtonEnd} style={{ marginTop: "10px" }}>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  shopId: cookie.load("shopId"),
                  nb_user_id: cookie.load("id"),
                  employeeId: staffID,
                  member_name: staffName,
                  department_id: department,
                  item_id: project,
                  shop_id: shop,
                  station_id: post,
                  member_type: staffType,
                  calculation_type: calculation_type,
                  // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start
                  start_year_month_day: startTimeFilter
                    ? startTimeFilter.format(dateFormat)
                    : startTimeFilter,
                  start_select: startStrategy,
                  end_year_month_day: endTimeFilter
                    ? endTimeFilter.format(dateFormat)
                    : endTimeFilter,
                  end_select: endStrategy,
                  // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end
                  member_status: staffState,
                };
                if (tabKey == "project") {
                  let burieProps = {
                    Employee_ID: staffID.toString(),
                    name: staffName,
                    department: department.toString(),
                    project: project.toString(),
                    shop: shop.toString(),
                    post: post.toString(),
                    Employee_type: staffType.toString(),
                    Employee_status: staffState.toString(),
                    Start_time_filter: startTimeFilter
                      ? startTimeFilter.format(dateFormat)
                      : startTimeFilter,
                    End_time_filter: endTimeFilter
                      ? endTimeFilter.format(dateFormat)
                      : endTimeFilter,
                    calculation_type: calculation_type,
                  };
                  AcceptBuried(
                    "Query_project_information",
                    "change",
                    burieProps,
                    props.location.pathname,
                    "人资预估管理"
                  );
                  getMemberItemInformation(dataProps);
                } else {
                  getMemberPayInformation(dataProps);
                }
              }}
            >
              查询
            </Button>
            <Button
              size="small"
              style={{
                borderColor: "#0091FF",
                color: "#0091FF",
                borderRadius: "3px",
                textAlign: "center",
                margin: "0px 20px 0px 20px",
              }}
              onClick={() => {
                setStaffName("");
                setDepartment([]);
                setProject([]);
                setPost([]);
                setShop([]);
                setStaffType([]);
                setStaffID([]);
                // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start
                setStartStrategy("");
                setEndStrategy("");
                setStartTimeFilter(null);
                setEndTimeFilter(null);
                // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end
                setStaffState([]);
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  shopId: cookie.load("shopId"),
                  nb_user_id: cookie.load("id"),
                  calculation_type: calculation_type,
                };
                if (tabKey == "project") {
                  AcceptBuried(
                    "Reset_project_information",
                    "click",
                    {},
                    props.location.pathname,
                    "人资预估管理"
                  );
                  getMemberItemInformation(dataProps);
                } else {
                  getMemberPayInformation(dataProps);
                }
              }}
            >
              重置
            </Button>
          </div>
        </div>
        <div style={{ display: tabKey == "socialSecurity" ? "" : "none" }}>
          <div className={style.staffInfo}>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>员工ID</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  showSearch
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  value={staffID}
                  onChange={(value) => {
                    setStaffID(value);
                  }}
                >
                  {staffIDList.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>姓名</Text>
              </div>
              <div>
                <Input
                  style={{ width: "240px" }}
                  allowClear
                  value={staffName}
                  placeholder="请填写 支持模糊查询"
                  onChange={(e) => {
                    setStaffName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>社保缴纳地</Text>
              </div>
              <div>
                <div>
                  <Select
                    className={style.staffInfoSelectWidth}
                    allowClear
                    mode="multiple"
                    optionFilterProp="title"
                    maxTagCount="responsive"
                    value={socialAdd}
                    onChange={(value) => {
                      setSocialAdd(value);
                    }}
                  >
                    {socialAddList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>社保基数</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  optionFilterProp="title"
                  value={socialBase}
                  onChange={(value) => {
                    setSocialBase(value);
                  }}
                >
                  {socialBaseList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>公司</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  value={company}
                  onChange={(value) => {
                    setCompany(value);
                  }}
                >
                  {companyList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>项目</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  optionFilterProp="title"
                  value={project}
                  onChange={(value) => {
                    setProject(value);
                  }}
                >
                  {projectList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>店铺</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  optionFilterProp="title"
                  value={shop}
                  onChange={(value) => {
                    setShop(value);
                  }}
                >
                  {shopList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>员工类型</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  value={staffType}
                  onChange={(value) => {
                    setStaffType(value);
                  }}
                >
                  {staffTypeList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display:
                  tabKey == "additionalSum" || tabKey == "rewardSum"
                    ? "none"
                    : "",
              }}
            >
              <div>
                <Text>员工状态</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  mode="multiple"
                  maxTagCount="responsive"
                  value={staffState}
                  onChange={(value) => {
                    setStaffState(value);
                  }}
                >
                  {staffStateList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>开始时间筛选</Text>
              </div>
              <div>
                <Select
                  style={{ width: "80px", marginRight: "20px" }}
                  allowClear
                  value={startStrategy}
                  onChange={(value) => {
                    setStartStrategy(value);
                  }}
                >
                  {strategyList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
                <DatePicker
                  value={startTimeFilter}
                  locale={locale}
                  onChange={(value, dateString) => {
                    setStartTimeFilter(value);
                  }}
                />
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>结束时间筛选</Text>
              </div>
              <div>
                <Select
                  style={{ width: "80px", marginRight: "20px" }}
                  allowClear
                  value={endStrategy}
                  onChange={(value) => {
                    setEndStrategy(value);
                  }}
                >
                  {strategyList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
                <DatePicker
                  value={endTimeFilter}
                  locale={locale}
                  onChange={(value, dateString) => {
                    setEndTimeFilter(value);
                  }}
                />
                {/*  #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end  */}
              </div>
            </div>
          </div>
          <div className={style.ButtonEnd} style={{ marginTop: "20px" }}>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  nb_user_id: cookie.load("id"),
                  name: staffName,
                  employeeId: staffID,
                  social_security_station: socialAdd,
                  social_security_cardinal: socialBase,
                  calculation_type: calculation_type,
                  // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start
                  start_year_month_day: startTimeFilter
                    ? startTimeFilter.format(dateFormat)
                    : startTimeFilter,
                  start_select: startStrategy,
                  end_year_month_day: endTimeFilter
                    ? endTimeFilter.format(dateFormat)
                    : endTimeFilter,
                  end_select: endStrategy,
                  company_id: company,
                  item_id: project,
                  shop_id: shop,
                  // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end
                  member_type: staffType,
                  member_status: staffState,
                };

                if (panelKey.indexOf("socialPay") !== -1) {
                  searchWorkerSocialSecuritySubject(dataProps);
                }
                if (panelKey.indexOf("socialBase") !== -1) {
                  searchNewWorkerSocialSecurity(dataProps);
                }
                if (panelKey.indexOf("socialBaseRangeEstimate") !== -1) {
                  findSocialSecurityRange(dataProps);
                }
                if (panelKey.indexOf("socialBaseEstimate") !== -1) {
                  findSocialSecurity(dataProps);
                }
              }}
            >
              查询
            </Button>
            <Button
              size="small"
              style={{
                borderColor: "#0091FF",
                color: "#0091FF",
                borderRadius: "3px",
                textAlign: "center",
                margin: "0px 20px 0px 20px",
              }}
              onClick={() => {
                setStaffName("");
                setStaffID([]);
                setSocialBase([]);
                setSocialAdd([]);
                // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start
                setStartStrategy("");
                setEndStrategy("");
                setCompany("");
                setProject("");
                setShop("");
                setStartTimeFilter(null);
                setEndTimeFilter(null);
                // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end
                setStaffType([]);
                setStaffState([]);
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  calculation_type: calculation_type,
                  nb_user_id: cookie.load("id"),
                };
                if (panelKey.indexOf("socialBaseEstimate") !== -1) {
                  findSocialSecurity(dataProps);
                }
                if (panelKey.indexOf("socialPay") !== -1) {
                  searchWorkerSocialSecuritySubject(dataProps);
                }
                if (panelKey.indexOf("socialBase") !== -1) {
                  searchNewWorkerSocialSecurity(dataProps);
                }
                if (panelKey.indexOf("socialBaseRangeEstimate") !== -1) {
                  findSocialSecurityRange(dataProps);
                }
              }}
            >
              重置
            </Button>
          </div>
        </div>
        <div style={{ display: tabKey == "accumulationFund" ? "" : "none" }}>
          <div className={style.staffInfo}>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>员工ID</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  showSearch
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  value={staffID}
                  onChange={(value) => {
                    setStaffID(value);
                  }}
                >
                  {staffIDList.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>姓名</Text>
              </div>
              <div>
                <Input
                  style={{ width: "240px" }}
                  allowClear
                  value={staffName}
                  placeholder="请填写 支持模糊查询"
                  onChange={(e) => {
                    setStaffName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>社保缴纳地</Text>
              </div>
              <div>
                <div>
                  <Select
                    className={style.staffInfoSelectWidth}
                    allowClear
                    mode="multiple"
                    maxTagCount="responsive"
                    optionFilterProp="title"
                    value={socialAdd}
                    onChange={(value) => {
                      setSocialAdd(value);
                    }}
                  >
                    {socialAddList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>公积金基数</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  optionFilterProp="title"
                  value={accumulationFundBase}
                  onChange={(value) => {
                    setAccumulationFundBase(value);
                  }}
                >
                  {accumulationFundBaseList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {/*#I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>公司</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  value={company}
                  onChange={(value) => {
                    setCompany(value);
                  }}
                >
                  {companyList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>项目</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  optionFilterProp="title"
                  value={project}
                  onChange={(value) => {
                    setProject(value);
                  }}
                >
                  {projectList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>店铺</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  optionFilterProp="title"
                  value={shop}
                  onChange={(value) => {
                    setShop(value);
                  }}
                >
                  {shopList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>员工类型</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  value={staffType}
                  onChange={(value) => {
                    setStaffType(value);
                  }}
                >
                  {staffTypeList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display:
                  tabKey == "additionalSum" || tabKey == "rewardSum"
                    ? "none"
                    : "",
              }}
            >
              <div>
                <Text>员工状态</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  mode="multiple"
                  maxTagCount="responsive"
                  value={staffState}
                  onChange={(value) => {
                    setStaffState(value);
                  }}
                >
                  {staffStateList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>开始时间筛选</Text>
              </div>
              <div>
                <Select
                  style={{ width: "80px", marginRight: "20px" }}
                  allowClear
                  value={startStrategy}
                  onChange={(value) => {
                    setStartStrategy(value);
                  }}
                >
                  {strategyList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
                <DatePicker
                  value={startTimeFilter}
                  locale={locale}
                  onChange={(value, dateString) => {
                    setStartTimeFilter(value);
                  }}
                />
              </div>
            </div>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>结束时间筛选</Text>
              </div>
              <div>
                <Select
                  style={{ width: "80px", marginRight: "20px" }}
                  allowClear
                  value={endStrategy}
                  onChange={(value) => {
                    setEndStrategy(value);
                  }}
                >
                  {strategyList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
                <DatePicker
                  value={endTimeFilter}
                  locale={locale}
                  onChange={(value, dateString) => {
                    setEndTimeFilter(value);
                  }}
                />
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end   */}
              </div>
            </div>
          </div>
          <div className={style.ButtonEnd} style={{ marginTop: "20px" }}>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  nb_user_id: cookie.load("id"),
                  name: staffName,
                  employeeId: staffID,
                  social_security_station: socialAdd,
                  accumulation_fund_cardinal: accumulationFundBase,
                  calculation_type: calculation_type,
                  // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start
                  start_year_month_day: startTimeFilter
                    ? startTimeFilter.format(dateFormat)
                    : startTimeFilter,
                  start_select: startStrategy,
                  end_year_month_day: endTimeFilter
                    ? endTimeFilter.format(dateFormat)
                    : endTimeFilter,
                  end_select: endStrategy,
                  company_id: company,
                  item_id: project,
                  shop_id: shop,
                  // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end
                  member_type: staffType,
                  member_status: staffState,
                };
                if (
                  panelKey.indexOf("accumulationFundBaseDistinguish") !== -1
                ) {
                  searchWorkerAccumulationFund(dataProps);
                }
                if (panelKey.indexOf("accumulationFundRange") !== -1) {
                  findWorkerAccumulationRange(dataProps);
                }
                if (panelKey.indexOf("accumulationFundBase") !== -1) {
                  searchWorkerAccumulationFundEstimate(dataProps);
                }
              }}
            >
              查询
            </Button>
            <Button
              size="small"
              style={{
                borderColor: "#0091FF",
                color: "#0091FF",
                borderRadius: "3px",
                textAlign: "center",
                margin: "0px 20px 0px 20px",
              }}
              onClick={() => {
                setStaffName("");
                setStaffID([]);
                setSocialBase([]);
                setAccumulationFundBase([]);
                setSocialAdd([]);
                // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start
                setStartStrategy("");
                setEndStrategy("");
                setStartTimeFilter(null);
                setEndTimeFilter(null);
                setCompany("");
                setProject("");
                setShop("");
                // #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end
                setStaffType([]);
                setStaffState([]);
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  calculation_type: calculation_type,
                  nb_user_id: cookie.load("id"),
                };
                if (
                  panelKey.indexOf("accumulationFundBaseDistinguish") !== -1
                ) {
                  searchWorkerAccumulationFund(dataProps);
                }
                if (panelKey.indexOf("accumulationFundRange") !== -1) {
                  findWorkerAccumulationRange(dataProps);
                }
                if (panelKey.indexOf("accumulationFundBase") !== -1) {
                  searchWorkerAccumulationFundEstimate(dataProps);
                }
              }}
            >
              重置
            </Button>
          </div>
        </div>
        <div style={{ display: tabKey == "personalIncomeTax" ? "" : "none" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>个人所得税参数</h3>
            <div>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  let obj = {};
                  taxFiled.map((item) => {
                    obj[item] = "";
                  });
                  setInsertTaxFiledData(obj);
                  setInsertTaxModel(true);
                }}
              >
                新增
              </Button>
            </div>
          </div>
          <div>
            <Table
              size="small"
              columns={taxColumn}
              dataSource={taxDataSource}
              rowKey={"id"}
            />
          </div>
        </div>
        <div style={{ display: tabKey == "Form" ? "" : "none" }}>
          <h3>保存表单明细</h3>
          <div>
            <Table
              size="small"
              columns={formColumn}
              dataSource={formDataSource}
              rowKey={"id"}
            />
          </div>
        </div>
        {/* <div style={{ display: tabKey == 'calculation' ? '' : 'none' }}>
                    <h3 style={{textAlign:'center'}}>是否触发重新计算?</h3>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <Button style={{margin:'0px 20px 0px 20px'}}>取消</Button>
                        <Button type='danger'>确定</Button>
                    </div>
                </div> */}
      </div>
      <Spin
        style={{ display: tabKey == "staff" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "staff" ? "" : "none",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>基础信息</h3>
            <div className={style.ButtonEnd}>
              {/* #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 start*/}
              <Button
                size="small"
                className={style.buleButton}
                style={{ marginRight: "20px" }}
                onClick={() => {
                  AcceptBuried(
                    "Download_employee_information",
                    "click",
                    {},
                    props.location.pathname,
                    "人资预估管理"
                  );
                  downloadMemberEstimate();
                }}
              >
                下载
              </Button>
              {/* #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 end*/}
              {isFinance !== 1 && (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setStaffIDInsert("");
                    setStaffStateInsert("");
                    setStaffNameInsert("");
                    setStaffIDCardInsert("");
                    setStaffPhoneInsert("");
                    setStaffSexInsert("");
                    setTimeBirthday("");
                    setStaffEmailInsert("");
                    setCompanyIDInsert("");
                    setBPInsert("");
                    setTimeInsert("");
                    setTimeDead(moment("9999-01-01", dateFormat));
                    setInsertStaffInfoModel(true);
                    AcceptBuried(
                      "New_employee_information",
                      "click",
                      {},
                      props.location.pathname,
                      "人资预估管理"
                    );
                  }}
                >
                  新增
                </Button>
              )}
              {/* <Button size='small' style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center', margin: '0px 20px 0px 20px' }}
                                onClick={() => {
                                    if (selectedRowKeys.length == 0) {
                                        message.warning("需先勾选人员信息")
                                    } else {
                                        setDeleteStaff(true)
                                    }
                                }}>删除</Button> */}
            </div>
          </div>
          <div>
            {/*// #I6A4HP NB20人资优化表格宽度 start*/}
            <Table
              size="small"
              columns={column}
              dataSource={dataSource}
              rowKey={"员工ID"}
              scroll={{ x: 1800 }}
            />
            {/*// #I6A4HP NB20人资优化表格宽度 end*/}
          </div>
        </div>
      </Spin>
      <Spin
        style={{ display: tabKey == "project" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "project" ? "" : "none",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>人员-项目</h3>
            <div className={style.ButtonEnd}>
              <Button
                size="small"
                className={style.buleButton}
                style={{ marginRight: "20px" }}
                onClick={() => {
                  downloadMemberItemInformation();
                }}
              >
                下载
              </Button>
              {isFinance !== 1 && (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    // setStaffProjectIDInsert('')
                    setStaffProjectNameInsert("");
                    setStaffProjectDepartmentInsert("");
                    setStaffProjectParameterInsert("");
                    setStaffProjectInsert("");
                    setStaffProjectShopInsert("");
                    setStaffProjectPostInsert("");
                    // setStaffProjectTypeInsert('')
                    setStaffProjectTimeInsert("");
                    setStaffProjectDeadTime("");
                    // setStaffProjectRemarkInsert('')
                    setInsertStaffProjectModel(true);
                    AcceptBuried(
                      "New_Personnel - Project_Information",
                      "click",
                      {},
                      props.location.pathname,
                      "人资预估管理"
                    );
                  }}
                >
                  新增
                </Button>
              )}
              {/* <Button size='small' style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center', margin: '0px 20px 0px 20px' }}
                                onClick={() => {
                                    if (selectedProjectRowKeys.length == 0) {
                                        message.warning("需先勾选人员信息")
                                    } else {
                                        setDeleteStaffProject(true)
                                    }
                                }}>删除</Button> */}
            </div>
          </div>
          <div>
            <Table
              size="small"
              columns={projectColumn}
              dataSource={projectDataSource}
            />
          </div>
          {isFinance !== 1 && (
            <Collapse
              value={panelKey}
              onChange={(key) => {
                let keyDict = {
                  companyDepartment: "Company - Department",
                  departmentProject: "Department - Project",
                  projectShop: "Project - Store",
                };
                AcceptBuried(
                  keyDict[key],
                  "click",
                  {},
                  props.location.pathname,
                  "人资预估管理"
                );
                setPanelKey(key);
              }}
              style={{ marginTop: "20px" }}
            >
              <Panel header="公司-部门" key="companyDepartment">
                <div
                  style={{
                    marginRight: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "20px", marginLeft: "20px" }}>
                    <Text>公司</Text>
                  </div>
                  <div>
                    <Select
                      className={style.staffInfoSelectWidth}
                      mode="multiple"
                      maxTagCount="responsive"
                      allowClear
                      showSearch
                      optionFilterProp="title"
                      value={companyDepartmentCompany}
                      onChange={(value) => {
                        setCompanyDepartmentCompany(value);
                      }}
                    >
                      {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start*/}
                      {companySelectList.map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                      {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end*/}
                    </Select>
                  </div>
                  <div style={{ marginRight: "20px", marginLeft: "20px" }}>
                    <Text>部门</Text>
                  </div>
                  <div>
                    <div>
                      <Select
                        className={style.staffInfoSelectWidth}
                        allowClear
                        mode="multiple"
                        maxTagCount="responsive"
                        showSearch
                        optionFilterProp="title"
                        value={companyDepartment}
                        onChange={(value) => {
                          setCompanyDepartment(value);
                        }}
                      >
                        {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start*/}
                        {departmentSelectList.map((item) => (
                          <Option value={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                        {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end*/}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className={style.ButtonEnd}>
                  <Button
                    style={{
                      borderColor: "#0091FF",
                      color: "#0091FF",
                      borderRadius: "3px",
                      textAlign: "center",
                      margin: "0px 20px 0px 20px",
                    }}
                    size="small"
                    onClick={() => {
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        company_id: companyDepartmentCompany,
                        department_id: companyDepartment,
                      };
                      let burieProps = {
                        company: companyDepartmentCompany.toString(),
                        department: companyDepartment.toString(),
                        calculation_type: calculation_type,
                      };
                      AcceptBuried(
                        "Query_company - department",
                        "click",
                        burieProps,
                        props.location.pathname,
                        "人资预估管理"
                      );
                      getRelationshipCompanyDepartment(dataProps);
                    }}
                  >
                    查询
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setCompanyInsert("");
                      setCompanyDepartmentInsert("");
                      setCompanyRemarkInsert("");
                      setInsertcompanyDepartmentModel(true);
                      AcceptBuried(
                        "New_company - department",
                        "change",
                        {},
                        props.location.pathname,
                        "人资预估管理"
                      );
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={companyColumn}
                    dataSource={companyDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
              <Panel header="部门-项目" key="departmentProject">
                <div
                  style={{
                    marginRight: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "20px", marginLeft: "20px" }}>
                    <Text>部门</Text>
                  </div>
                  <div>
                    <div>
                      <Select
                        className={style.staffInfoSelectWidth}
                        allowClear
                        mode="multiple"
                        maxTagCount="responsive"
                        showSearch
                        optionFilterProp="title"
                        value={departmentItemDepartment}
                        onChange={(value) => {
                          setDepartmentItemDepartment(value);
                        }}
                      >
                        {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start*/}
                        {departmentSelectList.map((item) => (
                          <Option value={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                        {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end*/}
                      </Select>
                    </div>
                  </div>
                  <div style={{ marginRight: "20px", marginLeft: "20px" }}>
                    <Text>项目</Text>
                  </div>
                  <div>
                    <Select
                      className={style.staffInfoSelectWidth}
                      mode="multiple"
                      maxTagCount="responsive"
                      allowClear
                      showSearch
                      optionFilterProp="title"
                      value={departmentItem}
                      onChange={(value) => {
                        setDepartmentItem(value);
                      }}
                    >
                      {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start*/}
                      {projectSelectList.map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                      {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end*/}
                    </Select>
                  </div>
                </div>
                <div className={style.ButtonEnd}>
                  <Button
                    style={{
                      borderColor: "#0091FF",
                      color: "#0091FF",
                      borderRadius: "3px",
                      textAlign: "center",
                      margin: "0px 20px 0px 20px",
                    }}
                    size="small"
                    onClick={() => {
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        department_id: departmentItemDepartment,
                        item_id: departmentItem,
                      };
                      let burieProps = {
                        department: departmentItemDepartment.toString(),
                        project: departmentItem.toString(),
                        calculation_type: calculation_type,
                      };
                      AcceptBuried(
                        "Query_department - item",
                        "click",
                        burieProps,
                        props.location.pathname,
                        "人资预估管理"
                      );
                      getRelationshipDepartmentItem(dataProps);
                    }}
                  >
                    查询
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setDepartmentInsert("");
                      setDepartmentProjectInsert("");
                      setDepartmentRemarkInsert("");
                      setInsertDepartmentProjectModel(true);
                      AcceptBuried(
                        "New_department - project",
                        "click",
                        {},
                        props.location.pathname,
                        "人资预估管理"
                      );
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={departmantColumn}
                    dataSource={departmantDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
              <Panel header="项目-店铺" key="projectShop">
                <div
                  style={{
                    marginRight: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "20px", marginLeft: "20px" }}>
                    <Text>项目</Text>
                  </div>
                  <div>
                    <Select
                      className={style.staffInfoSelectWidth}
                      mode="multiple"
                      maxTagCount="responsive"
                      showSearch
                      optionFilterProp="title"
                      allowClear
                      value={itemShopItem}
                      onChange={(value) => {
                        setItemShopItem(value);
                      }}
                    >
                      {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start*/}
                      {projectSelectList.map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                      {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end*/}
                    </Select>
                  </div>
                  <div style={{ marginRight: "20px", marginLeft: "20px" }}>
                    <Text>店铺</Text>
                  </div>
                  <div>
                    <Select
                      className={style.staffInfoSelectWidth}
                      mode="multiple"
                      maxTagCount="responsive"
                      allowClear
                      showSearch
                      optionFilterProp="title"
                      value={itemShop}
                      onChange={(value) => {
                        setItemShop(value);
                      }}
                    >
                      {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start*/}
                      {shopSelectList.map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                      {/*// #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end*/}
                    </Select>
                  </div>
                </div>
                <div className={style.ButtonEnd}>
                  <Button
                    style={{
                      borderColor: "#0091FF",
                      color: "#0091FF",
                      borderRadius: "3px",
                      textAlign: "center",
                      margin: "0px 20px 0px 20px",
                    }}
                    size="small"
                    onClick={() => {
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        item_id: itemShopItem,
                        shop_id: itemShop,
                      };
                      getRelationshipItemShop(dataProps);
                      let burieProps = {
                        project: itemShopItem,
                        shop: itemShop,
                        calculation_type: calculation_type,
                      };
                      AcceptBuried(
                        "Query_Item - Store",
                        "click",
                        burieProps,
                        props.location.pathname,
                        "人资预估管理"
                      );
                    }}
                  >
                    查询
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setProjectInsert("");
                      setProjectShopInsert("");
                      setProjectRemarkInsert("");
                      setInsertProjectShopModel(true);
                      AcceptBuried(
                        "New_Item - Store",
                        "click",
                        {},
                        props.location.pathname,
                        "人资预估管理"
                      );
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={shopColumn}
                    dataSource={shopDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
            </Collapse>
          )}

          {isFinance !== 1 && (
            <Collapse
              value={panelKeyBase}
              onChange={(key) => setPanelKeyBase(key)}
              style={{ marginTop: "20px" }}
            >
              <Panel header="公司" key="company">
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "30px" }}>
                    <div>
                      <Text>开始时间筛选</Text>
                    </div>
                    <div>
                      <Select
                        style={{ width: "80px", marginRight: "20px" }}
                        allowClear
                        value={companyStartStrategy}
                        onChange={(value) => {
                          setCompanyStartStrategy(value);
                        }}
                      >
                        {strategyList.map((item) => (
                          <Option value={item.id}>{item.value}</Option>
                        ))}
                      </Select>
                      <DatePicker
                        value={companyStartTimeFilter}
                        locale={locale}
                        onChange={(value, dateString) => {
                          setCompanyStartTimeFilter(value);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginRight: "30px" }}>
                    <div>
                      <Text>结束时间筛选</Text>
                    </div>
                    <div>
                      <Select
                        style={{ width: "80px", marginRight: "20px" }}
                        allowClear
                        value={companyEndStrategy}
                        onChange={(value) => {
                          setCompanyEndStrategy(value);
                        }}
                      >
                        {strategyList.map((item) => (
                          <Option value={item.id}>{item.value}</Option>
                        ))}
                      </Select>
                      <DatePicker
                        value={companyEndTimeFilter}
                        locale={locale}
                        onChange={(value, dateString) => {
                          setCompanyEndTimeFilter(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end*/}
                <div className={style.ButtonEnd}>
                  {/*#I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                  <Button
                    style={{
                      borderColor: "#0091FF",
                      color: "#0091FF",
                      borderRadius: "3px",
                      textAlign: "center",
                      margin: "0px 20px 0px 20px",
                    }}
                    size="small"
                    onClick={() => {
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        start_year_month_day: companyStartStrategy
                          ? companyStartTimeFilter.format(dateFormat)
                          : companyStartTimeFilter,
                        start_select: companyStartStrategy,
                        end_year_month_day: companyEndTimeFilter
                          ? companyEndTimeFilter.format(dateFormat)
                          : companyEndTimeFilter,
                        end_select: companyEndStrategy,
                      };
                      getCompany(dataProps);
                    }}
                  >
                    查询
                  </Button>
                  <Button
                    size="small"
                    type="danger"
                    style={{
                      borderRadius: "3px",
                      textAlign: "center",
                      marginRight: "20px",
                    }}
                    onClick={() => {
                      setCompanyStartStrategy("");
                      setCompanyEndStrategy("");
                      setCompanyStartTimeFilter(null);
                      setCompanyEndTimeFilter(null);
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        nb_user_id: cookie.load("id"),
                      };
                      getCompany(dataProps);
                    }}
                  >
                    重置
                  </Button>
                  {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end*/}
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setPostInsert("");
                      setInsertStartTime("");
                      setInsertEndTime("");
                      setInsertItem("公司");
                      setInsertPostModel(true);
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={companyTableColumn}
                    dataSource={companyTableDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
              <Panel header="部门" key="department">
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "30px" }}>
                    <div>
                      <Text>开始时间筛选</Text>
                    </div>
                    <div>
                      <Select
                        style={{ width: "80px", marginRight: "20px" }}
                        allowClear
                        value={departmentStartStrategy}
                        onChange={(value) => {
                          setDepartmentStartStrategy(value);
                        }}
                      >
                        {strategyList.map((item) => (
                          <Option value={item.id}>{item.value}</Option>
                        ))}
                      </Select>
                      <DatePicker
                        value={departmentStartTimeFilter}
                        locale={locale}
                        onChange={(value, dateString) => {
                          setDepartmentStartTimeFilter(value);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginRight: "30px" }}>
                    <div>
                      <Text>结束时间筛选</Text>
                    </div>
                    <div>
                      <Select
                        style={{ width: "80px", marginRight: "20px" }}
                        allowClear
                        value={departmentEndStrategy}
                        onChange={(value) => {
                          setDepartmentEndStrategy(value);
                        }}
                      >
                        {strategyList.map((item) => (
                          <Option value={item.id}>{item.value}</Option>
                        ))}
                      </Select>
                      <DatePicker
                        value={departmentEndTimeFilter}
                        locale={locale}
                        onChange={(value, dateString) => {
                          setDepartmentEndTimeFilter(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end*/}
                <div className={style.ButtonEnd}>
                  {/*#I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                  <Button
                    style={{
                      borderColor: "#0091FF",
                      color: "#0091FF",
                      borderRadius: "3px",
                      textAlign: "center",
                      margin: "0px 20px 0px 20px",
                    }}
                    size="small"
                    onClick={() => {
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        start_year_month_day: departmentStartStrategy
                          ? departmentStartTimeFilter.format(dateFormat)
                          : departmentStartTimeFilter,
                        start_select: departmentStartStrategy,
                        end_year_month_day: departmentEndTimeFilter
                          ? departmentEndTimeFilter.format(dateFormat)
                          : departmentEndTimeFilter,
                        end_select: departmentEndStrategy,
                      };
                      getDepartment(dataProps);
                    }}
                  >
                    查询
                  </Button>
                  <Button
                    size="small"
                    type="danger"
                    style={{
                      borderRadius: "3px",
                      textAlign: "center",
                      marginRight: "20px",
                    }}
                    onClick={() => {
                      setDepartmentStartStrategy("");
                      setDepartmentEndStrategy("");
                      setDepartmentStartTimeFilter(null);
                      setDepartmentEndTimeFilter(null);
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        nb_user_id: cookie.load("id"),
                      };
                      getDepartment(dataProps);
                    }}
                  >
                    重置
                  </Button>
                  {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end*/}
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setPostInsert("");
                      setInsertStartTime("");
                      setInsertEndTime("");
                      setInsertItem("部门");
                      setInsertPostModel(true);
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={departmentTableColumn}
                    dataSource={departmentTableDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
              <Panel header="项目" key="project">
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "30px" }}>
                    <div>
                      <Text>开始时间筛选</Text>
                    </div>
                    <div>
                      <Select
                        style={{ width: "80px", marginRight: "20px" }}
                        allowClear
                        value={projectStartStrategy}
                        onChange={(value) => {
                          setProjectStartStrategy(value);
                        }}
                      >
                        {strategyList.map((item) => (
                          <Option value={item.id}>{item.value}</Option>
                        ))}
                      </Select>
                      <DatePicker
                        value={projectStartTimeFilter}
                        locale={locale}
                        onChange={(value, dateString) => {
                          setProjectStartTimeFilter(value);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginRight: "30px" }}>
                    <div>
                      <Text>结束时间筛选</Text>
                    </div>
                    <div>
                      <Select
                        style={{ width: "80px", marginRight: "20px" }}
                        allowClear
                        value={projectEndStrategy}
                        onChange={(value) => {
                          setProjectEndStrategy(value);
                        }}
                      >
                        {strategyList.map((item) => (
                          <Option value={item.id}>{item.value}</Option>
                        ))}
                      </Select>
                      <DatePicker
                        value={projectEndTimeFilter}
                        locale={locale}
                        onChange={(value, dateString) => {
                          setProjectEndTimeFilter(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end*/}
                <div className={style.ButtonEnd}>
                  {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                  <Button
                    style={{
                      borderColor: "#0091FF",
                      color: "#0091FF",
                      borderRadius: "3px",
                      textAlign: "center",
                      margin: "0px 20px 0px 20px",
                    }}
                    size="small"
                    onClick={() => {
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        start_year_month_day: projectStartStrategy
                          ? projectStartTimeFilter.format(dateFormat)
                          : projectStartTimeFilter,
                        start_select: projectStartStrategy,
                        end_year_month_day: projectEndTimeFilter
                          ? projectEndTimeFilter.format(dateFormat)
                          : projectEndTimeFilter,
                        end_select: projectEndStrategy,
                      };
                      getItem(dataProps);
                    }}
                  >
                    查询
                  </Button>
                  <Button
                    size="small"
                    type="danger"
                    style={{
                      borderRadius: "3px",
                      textAlign: "center",
                      marginRight: "20px",
                    }}
                    onClick={() => {
                      setProjectStartStrategy("");
                      setProjectEndStrategy("");
                      setProjectStartTimeFilter(null);
                      setProjectEndTimeFilter(null);
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        nb_user_id: cookie.load("id"),
                      };
                      getItem(dataProps);
                    }}
                  >
                    重置
                  </Button>
                  {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end*/}
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setPostInsert("");
                      setInsertStartTime("");
                      setInsertEndTime("");
                      setInsertItem("项目");
                      setInsertPostModel(true);
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={projectTableColumn}
                    dataSource={projectTableDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
              <Panel header="店铺" key="shop">
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "30px" }}>
                    <div>
                      <Text>开始时间筛选</Text>
                    </div>
                    <div>
                      <Select
                        style={{ width: "80px", marginRight: "20px" }}
                        allowClear
                        value={shopStartStrategy}
                        onChange={(value) => {
                          setShopStartStrategy(value);
                        }}
                      >
                        {strategyList.map((item) => (
                          <Option value={item.id}>{item.value}</Option>
                        ))}
                      </Select>
                      <DatePicker
                        value={shopStartTimeFilter}
                        locale={locale}
                        onChange={(value, dateString) => {
                          setShopStartTimeFilter(value);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginRight: "30px" }}>
                    <div>
                      <Text>结束时间筛选</Text>
                    </div>
                    <div>
                      <Select
                        style={{ width: "80px", marginRight: "20px" }}
                        allowClear
                        value={shopEndStrategy}
                        onChange={(value) => {
                          setShopEndStrategy(value);
                        }}
                      >
                        {strategyList.map((item) => (
                          <Option value={item.id}>{item.value}</Option>
                        ))}
                      </Select>
                      <DatePicker
                        value={shopEndTimeFilter}
                        locale={locale}
                        onChange={(value, dateString) => {
                          setShopEndTimeFilter(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end*/}
                <div className={style.ButtonEnd}>
                  {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                  <Button
                    style={{
                      borderColor: "#0091FF",
                      color: "#0091FF",
                      borderRadius: "3px",
                      textAlign: "center",
                      margin: "0px 20px 0px 20px",
                    }}
                    size="small"
                    onClick={() => {
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        start_year_month_day: shopStartStrategy
                          ? shopStartTimeFilter.format(dateFormat)
                          : shopStartTimeFilter,
                        start_select: shopStartStrategy,
                        end_year_month_day: shopEndTimeFilter
                          ? shopEndTimeFilter.format(dateFormat)
                          : shopEndTimeFilter,
                        end_select: shopEndStrategy,
                      };
                      getShop(dataProps);
                    }}
                  >
                    查询
                  </Button>
                  <Button
                    size="small"
                    type="danger"
                    style={{
                      borderRadius: "3px",
                      textAlign: "center",
                      marginRight: "20px",
                    }}
                    onClick={() => {
                      setShopStartStrategy("");
                      setShopEndStrategy("");
                      setShopStartTimeFilter(null);
                      setShopEndTimeFilter(null);
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        nb_user_id: cookie.load("id"),
                      };
                      getShop(dataProps);
                    }}
                  >
                    重置
                  </Button>
                  {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end*/}
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setPostInsert("");
                      setInsertStartTime("");
                      setInsertEndTime("");
                      setInsertItem("店铺");
                      setInsertPostModel(true);
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={shopTableColumn}
                    dataSource={shopTableDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
              <Panel header="岗位" key="post">
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "30px" }}>
                    <div>
                      <Text>开始时间筛选</Text>
                    </div>
                    <div>
                      <Select
                        style={{ width: "80px", marginRight: "20px" }}
                        allowClear
                        value={postStartStrategy}
                        onChange={(value) => {
                          setPostStartStrategy(value);
                        }}
                      >
                        {strategyList.map((item) => (
                          <Option value={item.id}>{item.value}</Option>
                        ))}
                      </Select>
                      <DatePicker
                        value={postStartTimeFilter}
                        locale={locale}
                        onChange={(value, dateString) => {
                          setPostStartTimeFilter(value);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginRight: "30px" }}>
                    <div>
                      <Text>结束时间筛选</Text>
                    </div>
                    <div>
                      <Select
                        style={{ width: "80px", marginRight: "20px" }}
                        allowClear
                        value={postEndStrategy}
                        onChange={(value) => {
                          setPostEndStrategy(value);
                        }}
                      >
                        {strategyList.map((item) => (
                          <Option value={item.id}>{item.value}</Option>
                        ))}
                      </Select>
                      <DatePicker
                        value={postEndTimeFilter}
                        locale={locale}
                        onChange={(value, dateString) => {
                          setPostEndTimeFilter(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 end*/}
                <div className={style.ButtonEnd}>
                  {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                  <Button
                    style={{
                      borderColor: "#0091FF",
                      color: "#0091FF",
                      borderRadius: "3px",
                      textAlign: "center",
                      margin: "0px 20px 0px 20px",
                    }}
                    size="small"
                    onClick={() => {
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        start_year_month_day: postStartStrategy
                          ? postStartTimeFilter.format(dateFormat)
                          : postStartTimeFilter,
                        start_select: postStartStrategy,
                        end_year_month_day: postEndTimeFilter
                          ? postEndTimeFilter.format(dateFormat)
                          : postEndTimeFilter,
                        end_select: postEndStrategy,
                      };
                      getStation(dataProps);
                    }}
                  >
                    查询
                  </Button>
                  <Button
                    size="small"
                    type="danger"
                    style={{
                      borderRadius: "3px",
                      textAlign: "center",
                      marginRight: "20px",
                    }}
                    onClick={() => {
                      setPostStartStrategy("");
                      setPostEndStrategy("");
                      setPostStartTimeFilter(null);
                      setPostEndTimeFilter(null);
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        calculation_type: calculation_type,
                        nb_user_id: cookie.load("id"),
                      };
                      getStation(dataProps);
                    }}
                  >
                    重置
                  </Button>
                  {/* #I6BUGS NB20人资员工、项目、社保、公积金模块新增筛选条件 start*/}
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setPostInsert("");
                      setInsertStartTime("");
                      setInsertEndTime("");
                      setInsertItem("岗位");
                      setInsertPostModel(true);
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={postColumn}
                    dataSource={postDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
            </Collapse>
          )}
        </div>
      </Spin>
      <Spin
        style={{ display: tabKey == "pay" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{ display: tabKey == "pay" ? "" : "none", marginTop: "20px" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>薪酬汇总</h3>
            <div className={style.ButtonEnd}>
              {/* #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 start*/}
              <Button
                size="small"
                className={style.buleButton}
                style={{ marginRight: "20px" }}
                onClick={() => {
                  downloadMemberPayInformation();
                }}
              >
                下载
              </Button>
              {/* #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 end*/}
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  let obj = {};
                  payFiled.map((item) => {
                    if (item == `员工关怀`) {
                      obj[item] = "100";
                    } else {
                      obj[item] = "";
                    }
                  });
                  setInsertPayData(obj);
                  setInsertPayModel(true);
                }}
              >
                新增
              </Button>
              <Popover placement="bottom" content={moreActions} trigger="click">
                <Button
                  size="small"
                  style={{
                    borderColor: "#0091FF",
                    color: "#0091FF",
                    borderRadius: "3px",
                    textAlign: "center",
                    margin: "0px 20px 0px 20px",
                  }}
                >
                  批量操作
                </Button>
              </Popover>
            </div>
          </div>
          <div>
            {/*// #I6A4HP NB20人资优化表格宽度 start*/}
            <Table
              size="small"
              columns={payColumn}
              dataSource={payDataSource}
              rowSelection={rowPaySelection}
              rowKey={"id"}
              scroll={{ x: 3300 }}
            />
            {/*// #I6A4HP NB20人资优化表格宽度 end*/}
          </div>
        </div>
      </Spin>
      <Spin
        style={{ display: tabKey == "socialSecurity" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "socialSecurity" ? "" : "none",
            marginTop: "20px",
          }}
        >
          {/* <Tabs size="small" activeKey={socialSecurityTabKey} onChange={(key) => {
                            setSocialSecurityTabKey(key)
                        }}>
                            <TabPane tab={<span>社保基数判别实际表</span>} key="socialBase"></TabPane>
                            <TabPane tab={<span>社保缴纳费用参数表</span>} key="socialPay"></TabPane>
                            <TabPane tab={<span>社保基数判别预算表</span>} key="socialBaseDistinguishBudget"></TabPane>
                            <TabPane tab={<span>社保基数预估表</span>} key="socialBaseEstimate"></TabPane>
                            <TabPane tab={<span>社保基数实际表</span>} key="socialBaseActual"></TabPane>
                            <TabPane tab={<span>社保基数预算表</span>} key="socialBaseBudget"></TabPane>
                        </Tabs> */}
          <div className={style.socialTabel}>
            <Collapse
              value={panelKey}
              onChange={(key) => setPanelKey(key)}
              style={{ marginTop: "20px" }}
            >
              <Panel
                header={`社保基数判别${tableDict[calculation_type]}表`}
                key="socialBase"
              >
                <div className={style.ButtonEnd}>
                  {/* <Button size='small' type='danger' style={{ marginRight: '20px' }}>重新计算</Button> */}
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setSocialFiled(socialBaseFiled);
                      let obj = {};
                      socialBaseFiled.map((item) => {
                        obj[item] = "";
                      });
                      setUpdateModelTitle(
                        `新增社保基数判别${tableDict[calculation_type]}表`
                      );
                      setInsertSocialFiledData(obj);
                      setInsertSocialModel(true);
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={socialBaseColumn}
                    dataSource={socialBaseDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
              <Panel header={`社保缴纳费用参数表`} key="socialPay">
                <div className={style.ButtonEnd}>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setSocialFiled(socialPayFiled);
                      let obj = {};
                      socialPayFiled.map((item) => {
                        obj[item] = "";
                      });
                      setUpdateModelTitle(`新增社保缴纳费用参数表`);
                      setInsertSocialFiledData(obj);
                      setInsertSocialModel(true);
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={socialPayColumn}
                    dataSource={socialPayDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
              <Panel
                header={`社保基数范围判别${tableDict[calculation_type]}表`}
                key="socialBaseRangeEstimate"
              >
                <div className={style.ButtonEnd}>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setSocialFiled(socialBaseRangeEstimateFiled);
                      let obj = {};
                      socialBaseRangeEstimateFiled.map((item) => {
                        obj[item] = "";
                      });
                      setUpdateModelTitle(
                        `新增社保基数范围判别${tableDict[calculation_type]}表`
                      );
                      setInsertSocialFiledData(obj);
                      setInsertSocialModel(true);
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={socialBaseRangeEstimateColumn}
                    dataSource={socialBaseRangeEstimateDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
              <Panel
                header={`社保基数${tableDict[calculation_type]}表`}
                key="socialBaseEstimate"
              >
                <div className={style.ButtonEnd}>
                  {/* #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 start*/}
                  <Button
                    size="small"
                    className={style.buleButton}
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      downloadSocialSecurityEstimate();
                    }}
                  >
                    下载
                  </Button>
                  {/* #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 end*/}
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setSocialFiled(socialBaseEstimateFiled);
                      let obj = {};
                      socialBaseEstimateFiled.map((item) => {
                        obj[item] = "";
                      });
                      setUpdateModelTitle(
                        `新增社保基数${tableDict[calculation_type]}表`
                      );
                      setInsertSocialFiledData(obj);
                      setInsertSocialModel(true);
                    }}
                  >
                    新增
                  </Button>
                </div>
                <div>
                  <Table
                    size="small"
                    columns={socialBaseEstimateColumn}
                    dataSource={socialBaseEstimateDataSource}
                    rowKey={"id"}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
          {/* <div className={style.socialTabel}>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <h3>社保基数预算表</h3>
                            <Button type='primary' size='small' onClick={() => {
                                setSocialFiled(socialBaseBudgetFiled)
                                let obj = {}
                                socialBaseBudgetFiled.map(item => {
                                    obj[item] = ""
                                })
                                setInsertSocialFiledData(obj)
                                setInsertSocialModel(true)
                            }}>新增</Button>
                        </div>
                        <div>
                            <Table size="small" columns={socialBaseBudgetColumn} dataSource={socialBaseBudgetDataSource} rowKey={"id"} pagination={paginationProps}
                            />
                        </div>
                    </div>
                    <div className={style.socialTabel}>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <h3>社保基数判别预算表</h3>
                            <Button type='primary' size='small' onClick={() => {
                                setSocialFiled(socialBaseDistinguishBudgetFiled)
                                let obj = {}
                                socialBaseDistinguishBudgetFiled.map(item => {
                                    obj[item] = ""
                                })
                                setInsertSocialFiledData(obj)
                                setInsertSocialModel(true)
                            }}>新增</Button>
                        </div>
                        <div>
                            <Table size="small" columns={socialBaseDistinguishBudgetColumn} dataSource={socialBaseDistinguishBudgetDataSource} rowKey={"id"} pagination={paginationProps}
                            />
                        </div>
                    </div> */}
          {/* <div style={{display: socialSecurityTabKey == 'socialBaseActual' ? '' : 'none'}}>
                            <div style={{display:'flex', justifyContent:"space-between"}}>
                                <h3>社保基数实际表</h3>
                                <Button type='primary' size='small' onClick={()=>{
                                    setSocialFiled(socialBaseActualFiled)
                                    let obj = {}
                                    socialBaseActualFiled.map(item=>{
                                        obj[item] = ""
                                    })
                                    setInsertSocialFiledData(obj)
                                    setInsertSocialModel(true)
                                }}>新增</Button>
                            </div>
                            <div>
                                <Table size="small" columns={socialBaseActualColumn} dataSource={socialBaseActualDataSource} rowKey={"id"} pagination={paginationProps}
                                />
                            </div>
                        </div> */}
        </div>
      </Spin>
      <Spin
        style={{ display: tabKey == "accumulationFund" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "accumulationFund" ? "" : "none",
            marginTop: "20px",
          }}
        >
          {/* <Tabs size="small" activeKey={accumulationFundTabKey} onChange={(key) => {
                        setAccumulationFundTabKey(key)
                    }}>
                        <TabPane tab={<span>公积金基数实际表</span>} key="accumulationFundBase"></TabPane>
                        <TabPane tab={<span>公积金基数判别预算表</span>} key="accumulationFundDistinguishBudget"></TabPane>
                        <TabPane tab={<span>公积金预估表</span>} key="accumulationFundEstimate"></TabPane>
                        <TabPane tab={<span>公积金实际表</span>} key="accumulationFundActual"></TabPane>
                        <TabPane tab={<span>公积金预算表</span>} key="accumulationFundBudget"></TabPane>
                    </Tabs> */}
          <Collapse
            value={panelKey}
            onChange={(key) => setPanelKey(key)}
            style={{ marginTop: "20px" }}
          >
            <Panel
              header={`公积金基数判别${tableDict[calculation_type]}表`}
              key="accumulationFundBaseDistinguish"
            >
              <div className={style.ButtonEnd}>
                {/* <Button size='small' type='danger' style={{ marginRight: '20px' }}>修改校验</Button> */}
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setAccumulationFundFiled(accumulationFundBaseFiled);
                    let obj = {};
                    accumulationFundBaseFiled.map((item) => {
                      obj[item] = "";
                    });
                    setInsertAccumulationFundFiledData(obj);
                    setUpdateModelTitle(
                      `新增公积金基数判别${tableDict[calculation_type]}表`
                    );
                    setInsertAccumulationFundModel(true);
                  }}
                >
                  新增
                </Button>
              </div>
              <div>
                <Table
                  size="small"
                  columns={accumulationFundBaseColumn}
                  dataSource={accumulationFundBaseDataSource}
                />
              </div>
            </Panel>
            <Panel
              header={`公积金基数范围判别${tableDict[calculation_type]}表`}
              key="accumulationFundRange"
            >
              <div className={style.ButtonEnd}>
                {/* <Button size='small' type='danger' style={{ marginRight: '20px' }}>重新计算</Button> */}
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setAccumulationFundFiled(
                      accumulationFundRangeEstimateFiled
                    );
                    let obj = {};
                    accumulationFundRangeEstimateFiled.map((item) => {
                      obj[item] = "";
                    });
                    setUpdateModelTitle(
                      `新增公积金基数范围判别${tableDict[calculation_type]}表`
                    );
                    setInsertAccumulationFundFiledData(obj);
                    setInsertAccumulationFundModel(true);
                  }}
                >
                  新增
                </Button>
              </div>
              <div>
                <Table
                  size="small"
                  columns={accumulationFundRangeEstimateColumn}
                  dataSource={accumulationFundRangeEstimateDataSource}
                />
              </div>
            </Panel>
            <Panel
              header={`公积金基数${tableDict[calculation_type]}表`}
              key="accumulationFundBase"
            >
              <div className={style.ButtonEnd}>
                {/* #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 start*/}
                <Button
                  size="small"
                  className={style.buleButton}
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    downloadAccumulationFundEstimate();
                  }}
                >
                  下载
                </Button>
                {/* #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 end*/}
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setAccumulationFundFiled(accumulationFundEstimateFiled);
                    let obj = {};
                    accumulationFundEstimateFiled.map((item) => {
                      obj[item] = "";
                    });
                    setUpdateModelTitle(
                      `新增公积金基数${tableDict[calculation_type]}表`
                    );
                    setInsertAccumulationFundFiledData(obj);
                    setInsertAccumulationFundModel(true);
                  }}
                >
                  新增
                </Button>
              </div>
              <div>
                <Table
                  size="small"
                  columns={accumulationFundEstimateColumn}
                  dataSource={accumulationFundEstimateDataSource}
                />
              </div>
            </Panel>
          </Collapse>
          {/* <div className={style.socialTabel}>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <h3>{`公积金基数判别${tableDict[calculation_type]}表`}</h3>
                            <Button type='primary' size='small' onClick={() => {
                                setAccumulationFundFiled(accumulationFundBaseFiled)
                                let obj = {}
                                accumulationFundBaseFiled.map(item => {
                                    obj[item] = ""
                                })
                                setInsertAccumulationFundFiledData(obj)
                                setUpdateModelTitle(`新增公积金基数判别${tableDict[calculation_type]}表`)
                                setInsertAccumulationFundModel(true)
                            }}>新增</Button>
                        </div>
                        <div>
                            <Table size="small" columns={accumulationFundBaseColumn} dataSource={accumulationFundBaseDataSource}
                            />
                        </div>
                    </div> */}
          {/* <div className={style.socialTabel}>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <h3>公积金基数判别预算表</h3>
                            <Button type='primary' size='small' onClick={() => {
                                setAccumulationFundFiled(accumulationFundBaseFiled)
                                let obj = {}
                                accumulationFundBaseFiled.map(item => {
                                    obj[item] = ""
                                })
                                setInsertAccumulationFundFiledData(obj)
                                setInsertAccumulationFundModel(true)
                            }}>新增</Button>
                        </div>
                        <div>
                            <Table size="small" columns={accumulationFundDistinguishBudgetColumn} dataSource={accumulationFundDistinguishBudgetDataSource} rowKey={"id"} pagination={paginationProps}
                            />
                        </div>
                    </div>
                    <div className={style.socialTabel}>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <h3>公积金基数预算表</h3>
                            <Button type='primary' size='small' onClick={() => {
                                setAccumulationFundFiled(accumulationFundEstimateFiled)
                                let obj = {}
                                accumulationFundEstimateFiled.map(item => {
                                    obj[item] = ""
                                })
                                setInsertAccumulationFundFiledData(obj)
                                setInsertAccumulationFundModel(true)
                            }}>新增</Button>
                        </div>
                        <div>
                            <Table size="small" columns={accumulationFundBudgetColumn} dataSource={accumulationFundBudgetDataSource} rowKey={"id"} pagination={paginationProps}
                            />
                        </div>
                    </div> */}
          {/* <div className={style.socialTabel}>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <h3>{`公积金基数范围判别${tableDict[calculation_type]}表`}</h3>
                            <Button type='primary' size='small' onClick={() => {
                                setAccumulationFundFiled(accumulationFundRangeEstimateFiled)
                                let obj = {}
                                accumulationFundRangeEstimateFiled.map(item => {
                                    obj[item] = ""
                                })
                                setUpdateModelTitle(`新增公积金基数范围判别${tableDict[calculation_type]}表`)
                                setInsertAccumulationFundFiledData(obj)
                                setInsertAccumulationFundModel(true)
                            }}>新增</Button>
                        </div>
                        <div>
                            <Table size="small" columns={accumulationFundRangeEstimateColumn} dataSource={accumulationFundRangeEstimateDataSource}
                            />
                        </div>
                    </div> */}
          {/* <div className={style.socialTabel}>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <h3>{`公积金基数${tableDict[calculation_type]}表`}</h3>
                            <Button type='primary' size='small' onClick={() => {
                                setAccumulationFundFiled(accumulationFundEstimateFiled)
                                let obj = {}
                                accumulationFundEstimateFiled.map(item => {
                                    obj[item] = ""
                                })
                                setUpdateModelTitle(`新增公积金基数${tableDict[calculation_type]}表`)
                                setInsertAccumulationFundFiledData(obj)
                                setInsertAccumulationFundModel(true)
                            }}>新增</Button>
                        </div>
                        <div>
                            <Table size="small" columns={accumulationFundEstimateColumn} dataSource={accumulationFundEstimateDataSource}
                            />
                        </div>
                    </div> */}
          {/* <div style={{display: accumulationFundTabKey == 'accumulationFundActual' ? '' : 'none'}}>
                        <div style={{display:'flex', justifyContent:"space-between"}}>
                            <h3>公积金实际表</h3>
                            <Button type='primary' size='small' onClick={()=>{
                                setAccumulationFundFiled(accumulationFundEstimateFiled)
                                let obj = {}
                                accumulationFundEstimateFiled.map(item=>{
                                    obj[item] = ""
                                })
                                setInsertAccumulationFundFiledData(obj)
                                setInsertAccumulationFundModel(true)
                            }}>新增</Button>
                        </div>
                        <div>
                            <Table size="small" columns={accumulationFundActualColumn} dataSource={accumulationFundActualDataSource} rowKey={"id"} pagination={paginationProps}
                            />
                        </div>
                    </div> */}
        </div>
      </Spin>
      {/* <AntdDragModal
        title="删除人员信息"
        visible={deleteStaff}
        onCancel={() => {
          setDeleteStaff(false);
        }}
        onOk={() => {
          let obj = [...dataSource];
          obj.map((item, index) => {
            if (selectedRowKeys.indexOf(item.id) !== -1) {
              obj.splice(index, 1);
            }
          });
          setSelectedRowKeys([]);
          setDataSource(obj);
          setDeleteStaff(false);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        children={
          <div style={{ textAlign: "center" }}>是否确认删除员工信息？</div>
        }
      /> */}
      <AntdDragModal
        title="修改人员信息"
        visible={updateStaffInfoModel}
        onCancel={() => {
          setUpdateStaffInfoModel(false);
          AcceptBuried(
            "Modify_employee_information - cancel",
            "click",
            {},
            props.location.pathname,
            "人资预估管理"
          );
        }}
        onOk={() => {
          if (
            updateStaff["入职时间"].format(dateFormat) ===
            updateStaff["预计离职时间"].format(dateFormat)
          ) {
            message.warning("入职时间不能等于预计离职时间");
          } else {
            let dataProps = {
              id: updateStaff.id,
              roleId: cookie.load("role"),
              shopList: cookie.load("shopList"),
              shopId: cookie.load("shopId"),
              change_member: cookie.load("name"),
              calculation_type: calculation_type,
              employeeId: oldData["员工ID"],
              new_employeeId: updateStaff["员工ID"],
              ID_card: updateStaff["身份证号"],
              member_name: updateStaff["姓名"],
              sex: updateStaff["性别"],
              phone_number: updateStaff["手机号"],
              e_mail: updateStaff["邮箱"],
              birthday: updateStaff["生日"]
                ? updateStaff["生日"].format(dateFormat)
                : updateStaff["生日"],
              member_type: updateStaff["员工类型ID"],
              company_id: updateStaff["公司ID"],
              entry_time: updateStaff["入职时间"]
                ? updateStaff["入职时间"].format(dateFormat)
                : updateStaff["入职时间"],
              leave_time: updateStaff["预计离职时间"]
                ? updateStaff["预计离职时间"].format(dateFormat)
                : updateStaff["预计离职时间"],
              status: updateStaff["员工状态ID"],
              // #I66HN2 NB20前台人资系统员工基础信息修改接口优化 start
              old_entry_time: oldData["入职时间"],
              // #I66HN2 NB20前台人资系统员工基础信息修改接口优化 end
            };
            changeMemberInformation(dataProps);
            let burieProps = {
              name: updateStaff["姓名"],
              ID_number: updateStaff["身份证号"],
              company: updateStaff["公司ID"],
              birthday: updateStaff["生日"]
                ? updateStaff["生日"].format(dateFormat)
                : updateStaff["生日"],
              Employee_type: updateStaff["员工类型ID"],
              cell_phone_number: updateStaff["手机号"],
              Gender: updateStaff["性别"],
              mailbox: updateStaff["邮箱"],
              Date_of_entry: updateStaff["入职时间"]
                ? updateStaff["入职时间"].format(dateFormat)
                : updateStaff["入职时间"],
              Estimated_departure_time: updateStaff["预计离职时间"]
                ? updateStaff["预计离职时间"].format(dateFormat)
                : updateStaff["预计离职时间"],
              calculation_type: calculation_type,
            };
            AcceptBuried(
              "Modify_employee_information - confirm",
              "click",
              burieProps,
              props.location.pathname,
              "人资预估管理"
            );
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width={800}
        children={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {updateField.map((item) => {
              return item !== "操作" && item !== "员工状态" ? (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "120px 1fr",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      textAlign: "right",
                    }}
                  >
                    <Text>{item}</Text>
                  </div>
                  {/* #I69RTC NB20人资时间选择器去除时分秒 start*/}
                  <div style={{ marginBottom: "10px" }}>
                    {item.indexOf("生日") !== -1 ||
                    item.indexOf("时间") !== -1 ? (
                      <DatePicker
                        locale={locale}
                        style={{ width: "220px" }}
                        // {/* #I69RTC NB20人资时间选择器去除时分秒 end*/}
                        value={updateStaff[item]}
                        onChange={(value, dateString) => {
                          let obj = Object.assign({}, updateStaff);
                          obj[item] = value;
                          setUpdateStaff(obj);
                        }}
                      />
                    ) : selectDict[item] ? (
                      <Select
                        value={
                          item == "性别"
                            ? updateStaff[item]
                            : updateStaff[`${item}ID`]
                        }
                        style={{ width: "220px" }}
                        allowClear
                        showSearch
                        optionFilterProp="title"
                        onChange={(value) => {
                          if (item == "性别") {
                            let obj = Object.assign({}, updateStaff);
                            obj[item] = value;
                            setUpdateStaff(obj);
                          } else {
                            let obj = Object.assign({}, updateStaff);
                            obj[`${item}ID`] = value;
                            setUpdateStaff(obj);
                          }
                        }}
                      >
                        {selectDict[item].map((item) => (
                          <Option value={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        value={updateStaff[item]}
                        onChange={(e) => {
                          let reg = new RegExp("^[0-9.]*$");
                          if (item == "员工ID") {
                            if (reg.test(e.target.value)) {
                              let obj = Object.assign({}, updateStaff);
                              obj[item] = e.target.value;
                              setUpdateStaff(obj);
                            } else {
                              message.error("只能输入数字");
                            }
                          } else {
                            let obj = Object.assign({}, updateStaff);
                            obj[item] = e.target.value;
                            setUpdateStaff(obj);
                          }
                        }}
                        style={{ width: "220px" }}
                        disabled={item == "员工ID" ? true : false}
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      {/* 项目通用修改弹窗 */}
      <AntdDragModal
        title={updateModelTitle}
        visible={updateStaffProjectModel}
        onCancel={() => {
          setUpdateStaffProjectModel(false);
          AcceptBuried(
            "Modify_Personnel - Project_Information_Cancel",
            "click",
            {},
            props.location.pathname,
            "人资预估管理"
          );
        }}
        onOk={() => {
          let dataProps = {
            id: updateStaff.id,
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            start_year_month_day: updateStaff["开始年份月份日期"]
              ? updateStaff["开始年份月份日期"].format(dateFormat)
              : "",
            end_year_month_day: updateStaff["结束年份月份日期"]
              ? updateStaff["结束年份月份日期"].format(dateFormat)
              : "",
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
          };
          if (
            updateStaff["开始年份月份日期"] > updateStaff["结束年份月份日期"]
          ) {
            message.warning("开始时间不能大于结束时间");
            return false;
          }
          if (updateStaff["项目参数"] <= 0 || updateStaff["项目参数"] > 1) {
            message.warning("请填写正确的项目参数");
            return false;
          }
          if (updateModelTitle == "修改公司") {
            dataProps["company"] = updateStaff["公司简称"];
            dataProps["company_full_name"] = updateStaff["公司全称"];
            changeCompany(dataProps);
          } else if (updateModelTitle == "修改部门") {
            dataProps["department"] = updateStaff["部门"];
            changeDepartment(dataProps);
          } else if (updateModelTitle == "修改项目") {
            dataProps["item"] = updateStaff["项目"];
            changeItem(dataProps);
          } else if (updateModelTitle == "修改店铺") {
            dataProps["shop"] = updateStaff["店铺"];
            changeShop(dataProps);
          } else if (updateModelTitle == "修改岗位") {
            dataProps["station"] = updateStaff["岗位"];
            changeStation(dataProps);
          } else if (updateModelTitle == "修改人员项目关系") {
            dataProps["employeeId"] = oldData["员工ID"];
            dataProps["new_employeeId"] = updateStaff["员工ID"];
            dataProps["member_name"] = updateStaff["员工姓名"];
            dataProps["department_id"] = updateStaff["部门ID"];
            dataProps["item_id"] = updateStaff["项目ID"];
            dataProps["item_ratio"] = updateStaff["项目参数"];
            // #I67FC1 NB20人资系统人员项目新增店铺参数 start
            dataProps["shop_ratio"] = updateStaff["店铺参数"];
            // #I67FC1 NB20人资系统人员项目新增店铺参数 end
            dataProps["shop_id"] = updateStaff["店铺ID"];
            dataProps["station_id"] = updateStaff["岗位ID"];
            dataProps["key_start_year_month_day"] = oldData["开始年份月份日期"];
            dataProps["key_department_id"] = oldData["部门ID"];
            dataProps["key_item_id"] = oldData["项目ID"];
            dataProps["key_shop_id"] = oldData["店铺ID"];
            dataProps["key_station_id"] = oldData["岗位ID"];
            let burieProps = {
              Employee_name: updateStaff["员工姓名"],
              department: updateStaff["部门ID"],
              project: updateStaff["项目ID"],
              Project_parameters: updateStaff["项目参数"],
              Store: updateStaff["店铺ID"],
              Store_parameters: updateStaff["店铺参数"],
              post: updateStaff["岗位ID"],
              Start_Year_Month_Date: updateStaff["开始年份月份日期"]
                ? updateStaff["开始年份月份日期"].format(dateFormat)
                : "",
              End_Year_Month_Date: updateStaff["结束年份月份日期"]
                ? updateStaff["结束年份月份日期"].format(dateFormat)
                : "",
              calculation_type: calculation_type,
            };
            AcceptBuried(
              "Modify_Personnel - Project_Information_Confirm",
              "click",
              burieProps,
              props.location.pathname,
              "人资预估管理"
            );
            changeMemberItemInformation(dataProps);
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width={900}
        children={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {updateField.map((item) => {
              return item !== "操作" && item !== "员工ID" ? (
                <div className={style.updateProject}>
                  <div
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      textAlign: "right",
                    }}
                  >
                    <Text>{item}</Text>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                    {item.indexOf("月份") !== -1 ? (
                      <DatePicker
                        locale={locale}
                        style={{ width: "220px" }}
                        // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                        value={updateStaff[item]}
                        onChange={(value, dateString) => {
                          let obj = Object.assign({}, updateStaff);
                          obj[item] = value;
                          setUpdateStaff(obj);
                        }}
                      />
                    ) : selectDict[item] &&
                      updateModelTitle == "修改人员项目关系" ? (
                      <Select
                        value={
                          item == "员工姓名"
                            ? updateStaff["员工ID"] + "-" + updateStaff[item]
                            : updateStaff[`${item}ID`]
                        }
                        style={{ width: "220px" }}
                        allowClear
                        showSearch
                        optionFilterProp="title"
                        onChange={(value) => {
                          if (item == "员工姓名") {
                            let obj = Object.assign({}, updateStaff);
                            obj["员工ID"] = value.split("-")[0];
                            obj[item] = value.split("-")[1];
                            setUpdateStaff(obj);
                          } else {
                            let obj = Object.assign({}, updateStaff);
                            obj[`${item}ID`] = value;
                            setUpdateStaff(obj);
                          }
                        }}
                      >
                        {selectDict[item].map((item) => (
                          <Option value={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        value={updateStaff[item]}
                        onChange={(e) => {
                          let reg = new RegExp("^[0-9.]*$");
                          // #I67FC1 NB20人资系统人员项目新增店铺参数 start
                          if (item == "项目参数" || item == "店铺参数") {
                            // #I67FC1 NB20人资系统人员项目新增店铺参数 end
                            if (reg.test(e.target.value)) {
                              let obj = Object.assign({}, updateStaff);
                              obj[item] = e.target.value;
                              setUpdateStaff(obj);
                            } else {
                              message.error("只能输入数字");
                            }
                          } else {
                            let obj = Object.assign({}, updateStaff);
                            obj[item] = e.target.value;
                            setUpdateStaff(obj);
                          }
                        }}
                        style={{ width: "220px" }}
                        disabled={
                          item == "员工ID" || item == "姓名" ? true : false
                        }
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      {/* <AntdDragModal
        title="删除人员项目信息"
        visible={deleteStaffProject}
        onCancel={() => {
          setDeleteStaffProject(false);
        }}
        onOk={() => {
          let obj = [...projectDataSource];
          obj.map((item, index) => {
            if (selectedProjectRowKeys.indexOf(item.id) !== -1) {
              obj.splice(index, 1);
            }
          });
          setSelectedProjectRowKeys([]);
          setProjectDataSource(obj);
          setDeleteStaffProject(false);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        children={
          <div style={{ textAlign: "center" }}>是否确认删除员工信息？</div>
        }
      /> */}
      <AntdDragModal
        title="员工信息新增"
        visible={insertStaffInfoModel}
        onCancel={() => {
          setInsertStaffInfoModel(false);
          AcceptBuried(
            "New_Employee_Info - Cancel",
            "click",
            {},
            props.location.pathname,
            "人资预估管理"
          );
        }}
        onOk={() => {
          if (staffNameInsert === "") {
            message.warning("员工姓名必填");
          } else if (staffStateInsert === "") {
            message.warning("员工状态必填");
          } else if (timeInsert === "") {
            message.warning("入职时间必填");
          } else if (companyIDInsert === "") {
            message.warning("公司必填");
          } else if (BPInsert === "") {
            message.warning("BP管理人必填");
          } else if (timeBirthday === "") {
            message.warning("生日必填");
          } else if (!timeDead) {
            message.warning("预计离职时间必填");
          } else if (
            timeDead.format(dateFormat) === timeInsert.format(dateFormat)
          ) {
            message.warning("入职时间不能等于预计离职时间");
          } else {
            let dataProps = {
              roleId: cookie.load("role"),
              shopList: cookie.load("shopList"),
              shopId: cookie.load("shopId"),
              change_member: cookie.load("name"),
              employeeId: "",
              calculation_type: calculation_type,
              member_type: staffStateInsert,
              member_name: staffNameInsert,
              ID_card: staffIDCardInsert,
              phone_number: staffPhoneInsert ? staffPhoneInsert : null,
              sex: staffSexInsert,
              birthday: timeBirthday ? timeBirthday.format(dateFormat) : null,
              e_mail: staffEmailInsert,
              company_id: companyIDInsert ? companyIDInsert : null,
              BPInsert: BPInsert,
              entry_time: timeInsert ? timeInsert.format(dateFormat) : null,
              leave_time: timeDead ? timeDead.format(dateFormat) : null,
            };
            let burieProps = {
              Employee_name: staffNameInsert,
              identity_card: staffIDCardInsert,
              company: companyIDInsert ? companyIDInsert : null,
              birthday: timeBirthday ? timeBirthday.format(dateFormat) : null,
              Employee_type: staffStateInsert,
              cell_phone_number: staffPhoneInsert ? staffPhoneInsert : null,
              Gender: staffSexInsert,
              mailbox: staffEmailInsert,
              BP_Administrator: BPInsert,
              Date_of_entry: timeInsert ? timeInsert.format(dateFormat) : null,
              Estimated_departure_time: timeDead
                ? timeDead.format(dateFormat)
                : null,
              calculation_type: calculation_type,
            };
            AcceptBuried(
              "New_employee_information - confirm",
              "click",
              burieProps,
              props.location.pathname,
              "人资预估管理"
            );
            addMemberInformation(dataProps);
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="800px"
        children={
          <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
            {/* <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
                        <div>员工ID</div>
                        <div>
                            <Input style={{ width: '300px' }} value={staffIDInsert} onChange={e => {
                                setStaffIDInsert(e.target.value)
                            }} />
                        </div>
                    </div> */}
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>
                <span style={{ color: "red" }}>*</span>员工姓名
              </div>
              <div>
                <Input
                  style={{ width: "300px" }}
                  value={staffNameInsert}
                  onChange={(e) => setStaffNameInsert(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>身份证</div>
              <div>
                <Input
                  style={{ width: "300px" }}
                  value={staffIDCardInsert}
                  onChange={(e) => setStaffIDCardInsert(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              {/*// #I6A6KH NB20人资通过员工状态和身份证号自动生成生日和性别 start*/}
              <div>
                <span style={{ color: "red" }}>*</span>员工类型
              </div>
              <div>
                <Select
                  style={{ width: "300px" }}
                  value={staffStateInsert}
                  onChange={(value) => {
                    // if(value == 2){
                    //     // 待入职默认生日为当前年6月
                    //     setTimeBirthday(moment(moment().year().toString()+'-06-01',dateFormat))
                    // }else{
                    //     if(staffIDCardInsert === ''){
                    //         message.warning("员工状态非待入职需先填写身份证")
                    //         return false
                    //     }else{
                    //         const sfzReg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                    //         if(sfzReg.test(staffIDCardInsert)){
                    //             let year = staffIDCardInsert.substring(6,10)
                    //             let month = staffIDCardInsert.substring(10,12)
                    //             let day = staffIDCardInsert.substring(12,14)
                    //             // 从身份证获取生日
                    //             setTimeBirthday(moment(`${year}-${month}-${day}`,dateFormat))
                    //             // 从身份证获取性别
                    //             setStaffSexInsert(getGenderByIdNumber(staffIDCardInsert))
                    //         }else{
                    //             message.warning('身份证格式错误')
                    //             return false
                    //         }
                    //     }
                    // }
                    if (staffIDCardInsert !== "") {
                      const sfzReg =
                        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                      if (sfzReg.test(staffIDCardInsert)) {
                        let year = staffIDCardInsert.substring(6, 10);
                        let month = staffIDCardInsert.substring(10, 12);
                        let day = staffIDCardInsert.substring(12, 14);
                        // 从身份证获取生日
                        setTimeBirthday(
                          moment(`${year}-${month}-${day}`, dateFormat)
                        );
                        // 从身份证获取性别
                        setStaffSexInsert(
                          getGenderByIdNumber(staffIDCardInsert)
                        );
                      } else {
                        message.warning("身份证格式错误");
                        return false;
                      }
                    }
                    setStaffStateInsert(value);
                  }}
                >
                  {staffTypeList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
              </div>
            </div>
            {/*// #I6A6KH NB20人资通过员工状态和身份证号自动生成生日和性别 end*/}
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>
                <span style={{ color: "red" }}>*</span>公司
              </div>
              <div>
                <Select
                  style={{ width: "300px" }}
                  value={companyIDInsert}
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  onChange={(value) => setCompanyIDInsert(value)}
                >
                  {companyList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>手机号</div>
              <div>
                <Input
                  style={{ width: "300px" }}
                  value={staffPhoneInsert}
                  onChange={(e) => setStaffPhoneInsert(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>性别</div>
              <div>
                <Select
                  style={{ width: "300px" }}
                  value={staffSexInsert}
                  onChange={(value) => setStaffSexInsert(value)}
                >
                  {sexList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>
                <span style={{ color: "red" }}>*</span>生日
              </div>
              <div>
                <DatePicker
                  locale={locale}
                  style={{ width: "300px" }}
                  value={timeBirthday}
                  onChange={(value, dateString) => {
                    setTimeBirthday(value);
                  }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>邮箱</div>
              <div>
                <Input
                  style={{ width: "300px" }}
                  value={staffEmailInsert}
                  onChange={(e) => setStaffEmailInsert(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>
                <span style={{ color: "red" }}>*</span>BP管理人
              </div>
              <div>
                <Select
                  style={{ width: "300px" }}
                  value={BPInsert}
                  onChange={(value) => setBPInsert(value)}
                >
                  {BPList.map((item) => (
                    <Option value={item.id}>{item.value}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>
                <span style={{ color: "red" }}>*</span>入职时间
              </div>
              <div>
                {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                <DatePicker
                  locale={locale}
                  style={{ width: "300px" }}
                  // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                  value={timeInsert}
                  onChange={(value, dateString) => {
                    if (dateString == "") {
                      message.warn("请选择合理的时间");
                      return false;
                    } else if (value < moment() && staffIDCardInsert === "") {
                      message.warning("入职时间小于当前时间需先填写身份证");
                      return false;
                    } else {
                      setTimeInsert(value);
                    }
                  }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>
                <span style={{ color: "red" }}>*</span>预计离职时间
              </div>
              <div>
                {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                <DatePicker
                  locale={locale}
                  style={{ width: "300px" }}
                  // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                  value={timeDead}
                  onChange={(value, dateString) => {
                    setTimeDead(value);
                  }}
                />
              </div>
            </div>
          </div>
        }
      />
      <AntdDragModal
        title="新增人员-项目"
        visible={insertStaffProjectModel}
        onCancel={() => {
          setInsertStaffProjectModel(false);
          AcceptBuried(
            "New_Personnel - Project_Information_ Cancel",
            "click",
            {},
            props.location.pathname,
            "人资预估管理"
          );
        }}
        onOk={() => {
          if (staffProjectParameterInsert == "") {
            message.warning("项目参数必填");
            return false;
          } else if (staffProjectNameInsert == "") {
            message.warning("员工姓名必填");
            return false;
          } else if (staffProjectDepartmentInsert == "") {
            message.warning("部门必填");
            return false;
          } else if (staffProjectInsert == "") {
            message.warning("项目必填");
            return false;
          } else if (staffProjectShopInsert == "") {
            message.warning("店铺必填");
            return false;
          } else if (staffProjectPostInsert == "") {
            message.warning("岗位必填");
            return false;
          } else if (staffProjectTimeInsert == "") {
            message.warning("开始年份月份日期必填");
            return false;
          } else if (staffProjectDeadTime == "") {
            message.warning("结束年份月份日期必填");
            return false;
            // #I67FC1 NB20人资系统人员项目新增店铺参数 start
          } else if (staffProjectShopParameterInsert == "") {
            message.warning("店铺参数必填");
            return false;
          }
          // #I67FC1 NB20人资系统人员项目新增店铺参数 end
          if (
            staffProjectParameterInsert > 1 ||
            staffProjectParameterInsert <= 0
          ) {
            message.warning("项目参数填写有误");
          } else if (staffProjectTimeInsert > staffProjectDeadTime) {
            message.warning("开始时间不能大于结束时间");
            // #I67FC1 NB20人资系统人员项目新增店铺参数 start
          } else if (
            staffProjectShopParameterInsert > 1 ||
            staffProjectShopParameterInsert <= 0
          ) {
            message.warning("店铺参数填写有误");
            // #I67FC1 NB20人资系统人员项目新增店铺参数 end
          } else {
            let dataProps = {
              roleId: cookie.load("role"),
              shopList: cookie.load("shopList"),
              shopId: cookie.load("shopId"),
              change_member: cookie.load("name"),
              calculation_type: calculation_type,
              // staffProjectIDInsert: staffProjectIDInsert,
              member_name: staffProjectNameInsert,
              department_id: staffProjectDepartmentInsert,
              item_ratio: staffProjectParameterInsert,
              // #I67FC1 NB20人资系统人员项目新增店铺参数 start
              shop_ratio: staffProjectShopParameterInsert,
              // #I67FC1 NB20人资系统人员项目新增店铺参数 end
              item_id: staffProjectInsert,
              shop_id: staffProjectShopInsert,
              station_id: staffProjectPostInsert,
              // staffProjectTypeInsert: staffProjectTypeInsert,
              start_year_month_day: staffProjectTimeInsert
                ? staffProjectTimeInsert.format(dateFormat)
                : staffProjectTimeInsert,
              end_year_month_day: staffProjectDeadTime
                ? staffProjectDeadTime.format(dateFormat)
                : staffProjectDeadTime,
            };
            let burieProps = {
              Employee_name: staffProjectNameInsert,
              department: staffProjectDepartmentInsert,
              project: staffProjectPostInsert,
              shop: staffProjectShopInsert,
              post: staffProjectPostInsert,
              Project_parameters: staffProjectParameterInsert,
              Store_parameters: staffProjectShopParameterInsert,
              Start_Year_Month_Date: staffProjectTimeInsert
                ? staffProjectTimeInsert.format(dateFormat)
                : staffProjectTimeInsert,
              End_Year_Month_Date: staffProjectDeadTime
                ? staffProjectDeadTime.format(dateFormat)
                : staffProjectDeadTime,
              calculation_type: calculation_type,
            };
            AcceptBuried(
              "New_Personnel - Project_Information_Confirm",
              "click",
              burieProps,
              props.location.pathname,
              "人资预估管理"
            );
            // 员工项目信息新增接口
            NbAxios(dataProps, "POST", servicePath.addMemberItemInformation)
              .then((res) => {
                setLoading(false);
                if (res.success) {
                  message.success("添加成功");
                  let dataProps = {
                    roleId: cookie.load("role"),
                    shopList: cookie.load("shopList"),
                    shopId: cookie.load("shopId"),
                    change_member: cookie.load("name"),
                    calculation_type: calculation_type,
                    nb_user_id: cookie.load("id"),
                    employeeId: staffID,
                    member_name: staffName,
                    department_id: department,
                    item_id: project,
                    shop_id: shop,
                    station_id: post,
                    member_type: staffType,
                  };
                  getMemberItemInformation(dataProps);
                  setInsertStaffProjectModel(false);
                } else {
                  alert(res.errorMsg);
                }
              })
              .catch((err) => {
                setLoading(false);
                message.warn("员工项目信息新增接口,请联系IT");
              });
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="800px"
        children={
          <Spin
            style={{ display: tabKey == "project" ? "" : "none" }}
            spinning={loading}
            tip="数据加载中...."
          >
            <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
              {/* <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
                            <div>员工ID</div>
                            <div>
                                <Input style={{ width: '300px' }} value={staffProjectIDInsert} onChange={e => {
                                    setStaffProjectIDInsert(e.target.value)
                                }} />
                            </div>
                        </div> */}
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>
                  <span style={{ color: "red" }}>*</span>员工姓名
                </div>
                <div>
                  <Select
                    style={{ width: "300px" }}
                    value={staffProjectNameInsert}
                    onChange={(value) => setStaffProjectNameInsert(value)}
                    showSearch
                  >
                    {staffList.map((item) => (
                      <Option value={item.value}>{item.value}</Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>
                  <span style={{ color: "red" }}>*</span>部门
                </div>
                <div>
                  <Select
                    style={{ width: "300px" }}
                    value={staffProjectDepartmentInsert}
                    allowClear
                    showSearch
                    optionFilterProp="title"
                    onChange={(value) => {
                      setStaffProjectDepartmentInsert(value);
                      setRelationProjectList([]);
                      setRelationShopList([]);
                      setStaffProjectInsert("");
                      setStaffProjectShopInsert("");
                      let dataProps = {
                        roleId: cookie.load("role"),
                        shopList: cookie.load("shopList"),
                        change_member: cookie.load("name"),
                        calculation_type: calculation_type,
                        department_id: [value],
                        // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start
                        select_status: 1,
                        // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end
                      };
                      setLoading(true);
                      NbAxios(
                        dataProps,
                        "POST",
                        servicePath.getRelationshipDepartmentItem
                      )
                        .then((res) => {
                          setLoading(false);
                          if (res.success) {
                            let obj = [];
                            res.data.data_list.map((item) => {
                              if (item.status == 1) {
                                obj.push({
                                  id: item["项目ID"],
                                  value: item["项目"],
                                });
                              }
                            });
                            setRelationProjectList(obj);
                          } else {
                            alert(res.errorMsg);
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          message.warn("查询部门与项目之间的关系出错,请联系IT");
                        });
                    }}
                  >
                    {departmentList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              {relationProjectList.length !== 0 && (
                <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                  <div>
                    <span style={{ color: "red" }}>*</span>项目
                  </div>
                  <div>
                    <Select
                      style={{ width: "300px" }}
                      allowClear
                      showSearch
                      optionFilterProp="title"
                      value={staffProjectInsert}
                      onChange={(value) => {
                        setStaffProjectInsert(value);
                        setRelationShopList([]);
                        setStaffProjectShopInsert("");
                        setLoading(true);
                        let dataProps = {
                          roleId: cookie.load("role"),
                          shopList: cookie.load("shopList"),
                          change_member: cookie.load("name"),
                          calculation_type: calculation_type,
                          item_id: [value],
                          // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 start
                          select_status: 1,
                          // #I6A9IG NB20人资优化公司、部门、项目、店铺筛选下拉框取值 end
                        };
                        NbAxios(
                          dataProps,
                          "POST",
                          servicePath.getRelationshipItemShop
                        )
                          .then((res) => {
                            setLoading(false);
                            if (res.success) {
                              let obj = [];
                              res.data.data_list.map((item) => {
                                if (item.status == 1) {
                                  obj.push({
                                    id: item["店铺ID"],
                                    value: item["店铺"],
                                  });
                                }
                              });
                              setRelationShopList(obj);
                            } else {
                              alert(res.errorMsg);
                            }
                          })
                          .catch((err) => {
                            setLoading(false);
                            message.warn("项目和店铺关系查询接口出错,请联系IT");
                          });
                      }}
                    >
                      {relationProjectList.map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
              {relationShopList.length !== 0 && (
                <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                  <div>
                    <span style={{ color: "red" }}>*</span>店铺
                  </div>
                  <div>
                    <Select
                      style={{ width: "300px" }}
                      allowClear
                      showSearch
                      optionFilterProp="title"
                      value={staffProjectShopInsert}
                      onChange={(value) => setStaffProjectShopInsert(value)}
                    >
                      {relationShopList.map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>
                  <span style={{ color: "red" }}>*</span>项目参数
                </div>
                <div>
                  <Input
                    style={{ width: "300px" }}
                    value={staffProjectParameterInsert}
                    onChange={(e) => {
                      let reg = new RegExp("^[0-9.]*$");
                      if (reg.test(e.target.value)) {
                        setStaffProjectParameterInsert(e.target.value);
                      } else {
                        message.error("只能输入数字");
                      }
                    }}
                  />
                </div>
                {/* #I67FC1 NB20人资系统人员项目新增店铺参数 start   */}
              </div>
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>
                  <span style={{ color: "red" }}>*</span>店铺参数
                </div>
                <div>
                  <Input
                    style={{ width: "300px" }}
                    value={staffProjectShopParameterInsert}
                    onChange={(e) => {
                      let reg = new RegExp("^[0-9.]*$");
                      if (reg.test(e.target.value)) {
                        setStaffProjectShopParameterInsert(e.target.value);
                      } else {
                        message.error("只能输入数字");
                      }
                    }}
                  />
                </div>
                {/* #I67FC1 NB20人资系统人员项目新增店铺参数 end   */}
              </div>
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>
                  <span style={{ color: "red" }}>*</span>岗位
                </div>
                <div>
                  <Select
                    style={{ width: "300px" }}
                    allowClear
                    showSearch
                    optionFilterProp="title"
                    value={staffProjectPostInsert}
                    onChange={(value) => setStaffProjectPostInsert(value)}
                  >
                    {postList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              {/* <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
                            <div>员工类型</div>
                            <div>
                                <Select style={{ width: '300px' }} value={staffProjectTypeInsert} onChange={value => setStaffProjectTypeInsert(value)}>
                                    {companyIDInsertList.map(item => (
                                        <Option value={item}>{item}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div> */}
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>
                  <span style={{ color: "red" }}>*</span>开始年份月份日期
                </div>
                <div>
                  {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                  <DatePicker
                    locale={locale}
                    style={{ width: "300px" }}
                    // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                    value={staffProjectTimeInsert}
                    onChange={(value, dateString) => {
                      // if (dateString == '') {
                      //     message.warn('请选择合理的时间')
                      //     return false
                      // } else {
                      //     setStaffProjectTimeInsert(dateString)
                      // }
                      setStaffProjectTimeInsert(value);
                    }}
                  />
                </div>
              </div>
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>
                  <span style={{ color: "red" }}>*</span>结束年份月份日期
                </div>
                <div>
                  {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                  <DatePicker
                    locale={locale}
                    style={{ width: "300px" }}
                    // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                    value={staffProjectDeadTime}
                    onChange={(value, dateString) => {
                      setStaffProjectDeadTime(value);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
                        <div>备注</div>
                        <div>
                            <TextArea style={{ width: '675px' }} value={staffProjectRemarkInsert} onChange={e => setStaffProjectRemarkInsert(e.target.value)} />
                        </div>
                    </div> */}
          </Spin>
        }
      />
      <AntdDragModal
        title="新增公司-部门"
        visible={insertcompanyDepartmentModel}
        onCancel={() => {
          setInsertcompanyDepartmentModel(false);
          AcceptBuried(
            "New_company - department_cancel",
            "click",
            {},
            props.location.pathname,
            "人资预估管理"
          );
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            change_member: cookie.load("name"),
            company_id: companyInsert,
            department_id: companyDepartmentInsert,
            remarks: companyRemarkInsert,
          };
          let burieProps = {
            company: companyInsert,
            department: companyDepartmentInsert,
            calculation_type: calculation_type,
          };
          AcceptBuried(
            "New_Personnel - Project_Information_Confirm",
            "click",
            burieProps,
            props.location.pathname,
            "人资预估管理"
          );
          NbAxios(
            dataProps,
            "POST",
            servicePath.addRelationshipCompanyDepartment
          )
            .then((res) => {
              setLoading(false);
              if (res.success) {
                message.success("添加成功");
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  change_member: cookie.load("name"),
                  company_id: companyDepartmentCompany,
                  department_id: companyDepartment,
                };
                getRelationshipCompanyDepartment(dataProps);
                setInsertcompanyDepartmentModel(false);
              } else {
                alert(res.errorMsg);
              }
            })
            .catch((err) => {
              setLoading(false);
              message.warn("公司和部门关系新增接口,请联系IT");
            });
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="800px"
        children={
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>公司</div>
                <div>
                  <Select
                    style={{ width: "300px" }}
                    allowClear
                    value={companyInsert}
                    showSearch
                    optionFilterProp="title"
                    onChange={(value) => {
                      setCompanyInsert(value);
                    }}
                  >
                    {companyList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>部门</div>
                <div>
                  <Select
                    style={{ width: "300px" }}
                    allowClear
                    value={companyDepartmentInsert}
                    showSearch
                    optionFilterProp="title"
                    onChange={(value) => {
                      setCompanyDepartmentInsert(value);
                    }}
                  >
                    {departmentList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>备注</div>
              <div>
                <TextArea
                  style={{ width: "675px" }}
                  value={companyRemarkInsert}
                  onChange={(e) => setCompanyRemarkInsert(e.target.value)}
                />
              </div>
            </div>
          </div>
        }
      />
      <AntdDragModal
        title="新增部门-项目"
        visible={insertDepartmentProjectModel}
        onCancel={() => {
          setInsertDepartmentProjectModel(false);
          AcceptBuried(
            "New_department - project_cancel",
            "click",
            {},
            props.location.pathname,
            "人资预估管理"
          );
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            change_member: cookie.load("name"),
            department_id: departmentInsert,
            item_id: departmentProjectInsert,
            remarks: departmentRemarkInsert,
          };
          let burieProps = {
            department: departmentInsert,
            project: departmentProjectInsert,
            calculation_type: calculation_type,
          };
          AcceptBuried(
            "New_department - project_confirm",
            "click",
            burieProps,
            props.location.pathname,
            "人资预估管理"
          );
          // 部门和项目关系新增接口
          NbAxios(dataProps, "POST", servicePath.addRelationshipDepartmentItem)
            .then((res) => {
              setLoading(false);
              if (res.success) {
                message.success("添加成功");
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  change_member: cookie.load("name"),
                  department_id: departmentItemDepartment,
                  item_id: departmentItem,
                };
                getRelationshipDepartmentItem(dataProps);
                setInsertDepartmentProjectModel(false);
              } else {
                alert(res.errorMsg);
              }
            })
            .catch((err) => {
              setLoading(false);
              message.warn("部门和项目关系新增接口,请联系IT");
            });
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="800px"
        children={
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>部门</div>
                <div>
                  <Select
                    style={{ width: "300px" }}
                    allowClear
                    showSearch
                    optionFilterProp="title"
                    value={departmentInsert}
                    onChange={(value) => {
                      setDepartmentInsert(value);
                    }}
                  >
                    {departmentList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>项目</div>
                <div>
                  <Select
                    style={{ width: "300px" }}
                    allowClear
                    showSearch
                    optionFilterProp="title"
                    value={departmentProjectInsert}
                    onChange={(value) => {
                      setDepartmentProjectInsert(value);
                    }}
                  >
                    {projectList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>备注</div>
              <div>
                <TextArea
                  style={{ width: "675px" }}
                  value={departmentRemarkInsert}
                  onChange={(e) => setDepartmentRemarkInsert(e.target.value)}
                />
              </div>
            </div>
          </div>
        }
      />
      <AntdDragModal
        title="新增项目-店铺"
        visible={insertProjectShopModel}
        onCancel={() => {
          setInsertProjectShopModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            change_member: cookie.load("name"),
            item_id: projectInsert,
            shop_id: projectShopInsert,
            remarks: projectRemarkInsert,
          };

          // 项目和店铺关系新增接口
          NbAxios(dataProps, "POST", servicePath.addRelationshipItemShop)
            .then((res) => {
              setLoading(false);
              if (res.success) {
                message.success("添加成功");
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  change_member: cookie.load("name"),
                  item_id: itemShopItem,
                  shop_id: itemShop,
                };
                getRelationshipItemShop(dataProps);
                setInsertDepartmentProjectModel(false);
              } else {
                alert(res.errorMsg);
              }
            })
            .catch((err) => {
              setLoading(false);
              message.warn("项目和店铺关系新增接口,请联系IT");
            });
          setInsertProjectShopModel(false);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="800px"
        children={
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>项目</div>
                <div>
                  <Select
                    style={{ width: "300px" }}
                    allowClear
                    showSearch
                    optionFilterProp="title"
                    value={projectInsert}
                    onChange={(value) => {
                      setProjectInsert(value);
                    }}
                  >
                    {projectList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>店铺</div>
                <div>
                  <Select
                    style={{ width: "300px" }}
                    allowClear
                    showSearch
                    optionFilterProp="title"
                    value={projectShopInsert}
                    onChange={(value) => {
                      setProjectShopInsert(value);
                    }}
                  >
                    {shopList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>备注</div>
              <div>
                <TextArea
                  style={{ width: "675px" }}
                  value={projectRemarkInsert}
                  onChange={(e) => setProjectRemarkInsert(e.target.value)}
                />
              </div>
            </div>
          </div>
        }
      />
      <AntdDragModal
        title={`新增${insertItem}`}
        visible={insertPostModel}
        onCancel={() => {
          setInsertPostModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            start_year_month_day: insertStartTime
              ? insertStartTime.format(dateFormat)
              : insertStartTime,
            end_year_month_day: insertEndTime
              ? insertEndTime.format(dateFormat)
              : insertEndTime,
            change_member: cookie.load("name"),
          };
          if (insertItem == "公司") {
            dataProps["company"] = companyShort;
            dataProps["company_full_name"] = postInsert;
            addCompany(dataProps);
          } else if (insertItem == "部门") {
            dataProps["department"] = postInsert;
            addDepartment(dataProps);
          } else if (insertItem == "项目") {
            dataProps["item"] = postInsert;
            addItem(dataProps);
          } else if (insertItem == "店铺") {
            dataProps["shop"] = postInsert;
            addHRShop(dataProps);
          } else if (insertItem == "岗位") {
            dataProps["station"] = postInsert;
            addStation(dataProps);
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="800px"
        children={
          <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>
                {insertItem == "公司" ? insertItem + "全称" : insertItem}
              </div>
              <div>
                <Input
                  style={{ width: "300px" }}
                  value={postInsert}
                  onChange={(e) => {
                    setPostInsert(e.target.value);
                  }}
                />
              </div>
            </div>
            {insertItem == "公司" && (
              <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <div>
                  {insertItem == "公司" ? insertItem + "简称" : insertItem}
                </div>
                <div>
                  <Input
                    style={{ width: "300px" }}
                    value={companyShort}
                    onChange={(e) => {
                      setCompanyShort(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>开始年份月份日期</div>
              <div>
                {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                <DatePicker
                  locale={locale}
                  style={{ width: "300px" }}
                  // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                  value={insertStartTime}
                  onChange={(value, dateString) => {
                    setInsertStartTime(value);
                  }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <div>结束年份月份日期</div>
              <div>
                {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                <DatePicker
                  locale={locale}
                  style={{ width: "300px" }}
                  // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                  value={insertEndTime}
                  onChange={(value, dateString) => {
                    setInsertEndTime(value);
                  }}
                />
              </div>
            </div>
          </div>
        }
      />
      <AntdDragModal
        title="新增薪酬汇总"
        visible={insertPayModel}
        onCancel={() => {
          setInsertPayModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
          };
          if (insertPayData["员工姓名"] === "") {
            message.warning("员工姓名不能为空");
          } else if (insertPayData["月薪"] === "") {
            message.warning("月薪不能为空");
          } else if (insertPayData["是否外包"] === "") {
            message.warning("是否外包不能为空");
          } else if (insertPayData["开始年份月份日期"] === "") {
            message.warning("开始年份月份日期不能为空");
          } else if (insertPayData["结束年份月份日期"] === "") {
            message.warning("结束年份月份日期不能为空");
            // {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
          } else if (
            insertPayData["公积金开始缴纳时间"] &&
            insertPayData["公积金开始缴纳时间"]
              .format(dateFormat)
              .split("-")[2] !== "01"
          ) {
            message.warning("公积金开始缴纳时间不为一号");
            // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
          } else {
            payFiled.map((item) => {
              if (
                (item.indexOf("月份") !== -1 || item.indexOf("时间") !== -1) &&
                insertPayData[item]
              ) {
                dataProps[fieldsDict[item]] =
                  insertPayData[item].format(dateFormat);
              } else {
                dataProps[fieldsDict[item]] = insertPayData[item];
              }
            });
            addMemberPayInformation(dataProps);
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="1200px"
        children={
          <div
            style={{ display: "grid", gridTemplateColumns: "33.3% 33.3% 1fr" }}
          >
            {payFiled.map((item) => {
              return item !== "员工ID" && item !== "操作" ? (
                <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                  <div>{item}</div>
                  {item == "电脑补贴" ||
                  item == "是否外包" ||
                  item == "员工姓名" ||
                  item == "社保缴纳地" ? (
                    <div>
                      <Select
                        style={{ width: "300px" }}
                        value={insertPayData[item]}
                        showSearch
                        allowClear
                        optionFilterProp="title"
                        onChange={(value) => {
                          let obj = Object.assign({}, insertPayData);
                          obj[item] = value;
                          setInsertPayData(obj);
                        }}
                      >
                        {item == "电脑补贴"
                          ? computerList.map((item) => (
                              <Option value={item} title={item}>
                                {item}
                              </Option>
                            ))
                          : item == "法定节假日加班费用"
                          ? holidayList.map((item) => (
                              <Option value={item} title={item}>
                                {item}
                              </Option>
                            ))
                          : item == "员工姓名"
                          ? staffList.map((item) => (
                              <Option value={item.value} title={item.value}>
                                {item.value}
                              </Option>
                            ))
                          : item == "社保缴纳地"
                          ? socialAddList.map((item) => (
                              <Option value={item.id} title={item.value}>
                                {item.value}
                              </Option>
                            ))
                          : out.map((item) => (
                              <Option value={item.id} title={item.value}>
                                {item.value}
                              </Option>
                            ))}
                        {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                      </Select>
                    </div>
                  ) : item.indexOf("月份") !== -1 ||
                    item.indexOf("时间") !== -1 ? (
                    <DatePicker
                      locale={locale}
                      style={{ width: "300px" }}
                      // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                      value={insertPayData[item]}
                      onChange={(value, dateString) => {
                        let obj = Object.assign({}, insertPayData);
                        obj[item] = value;
                        setInsertPayData(obj);
                      }}
                    />
                  ) : (
                    <div>
                      <Input
                        style={{ width: "300px" }}
                        value={insertPayData[item]}
                        onChange={(e) => {
                          if (
                            item !== "员工ID" &&
                            item !== "员工姓名" &&
                            item !== "社保缴纳地" &&
                            item !== "是否外包" &&
                            item.indexOf("日期") == -1 &&
                            item.indexOf("时间")
                          ) {
                            let reg = new RegExp("^[0-9.]*$");
                            if (reg.test(e.target.value)) {
                              let obj = Object.assign({}, insertPayData);
                              obj[item] = e.target.value;
                              setInsertPayData(obj);
                            } else {
                              message.error("只能输入数字");
                            }
                          } else {
                            let obj = Object.assign({}, insertPayData);
                            obj[item] = e.target.value;
                            setInsertPayData(obj);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <AntdDragModal
        title="批量修改薪酬汇总"
        visible={updatePayModel}
        onCancel={() => {
          setUpdatePayModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
          };
          {
            /*  #I69RTC NB20人资时间选择器去除时分秒 start*/
          }
          if (
            insertPayData["公积金开始缴纳时间"] &&
            insertPayData["公积金开始缴纳时间"]
              .format(dateFormat)
              .split("-")[2] !== "01"
          ) {
            message.warning("公积金开始缴纳时间不为一号");
            return false;
          }
          {
            /*  #I69RTC NB20人资时间选择器去除时分秒 end*/
          }
          // 修改字段主键
          let obj = [];
          selectedPayRowKeys.map((item) => {
            payDataSource.map((data) => {
              if (data.id == item) {
                obj.push({
                  employeeId: data["员工ID"],
                  start_year_month_day: data["开始年份月份日期"],
                });
              }
            });
          });
          dataProps["key"] = obj;
          // 修改字段值
          let changeList = {};
          payFiled.map((item) => {
            if (insertPayData[item] || insertPayData[item] == 0) {
              if (
                (item.indexOf("月份") !== -1 || item.indexOf("时间") !== -1) &&
                insertPayData[item]
              ) {
                changeList[fieldsDict[item]] =
                  insertPayData[item].format(dateFormat);
              } else {
                changeList[fieldsDict[item]] = insertPayData[item];
              }
            }
          });
          dataProps["change"] = changeList;
          changeMemberPayInformation(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="1200px"
        children={
          <div
            style={{ display: "grid", gridTemplateColumns: "33.3% 33.3% 1fr" }}
          >
            {payFiled.map((item) => {
              return item !== "员工ID" &&
                item !== "员工姓名" &&
                item.indexOf("月份") == -1 &&
                item.indexOf("时间") == -1 &&
                item !== "操作" &&
                item !== "社保缴纳地" &&
                item !== "是否外包" ? (
                <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                  <div>{item}</div>
                  {item == "电脑补贴" ||
                  item == "是否外包" ||
                  item == "员工姓名" ||
                  item == "社保缴纳地" ? (
                    <div>
                      <Select
                        style={{ width: "300px" }}
                        value={insertPayData[item]}
                        showSearch
                        allowClear
                        optionFilterProp="title"
                        onChange={(value) => {
                          let obj = Object.assign({}, insertPayData);
                          obj[item] = value;
                          setInsertPayData(obj);
                        }}
                      >
                        {item == "电脑补贴"
                          ? computerList.map((item) => (
                              <Option value={item} title={item}>
                                {item}
                              </Option>
                            ))
                          : item == "法定节假日加班费用"
                          ? holidayList.map((item) => (
                              <Option value={item} title={item}>
                                {item}
                              </Option>
                            ))
                          : item == "员工姓名"
                          ? staffList.map((item) => (
                              <Option value={item.value} title={item.value}>
                                {item.value}
                              </Option>
                            ))
                          : item == "社保缴纳地"
                          ? socialAddList.map((item) => (
                              <Option value={item.id} title={item.value}>
                                {item.value}
                              </Option>
                            ))
                          : out.map((item) => (
                              <Option value={item.id} title={item.value}>
                                {item.value}
                              </Option>
                            ))}
                        {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                      </Select>
                    </div>
                  ) : item.indexOf("月份") !== -1 ||
                    item.indexOf("时间") !== -1 ? (
                    <DatePicker
                      locale={locale}
                      style={{ width: "300px" }}
                      // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                      value={insertPayData[item]}
                      onChange={(value, dateString) => {
                        let obj = Object.assign({}, insertPayData);
                        obj[item] = value;
                        setInsertPayData(obj);
                      }}
                    />
                  ) : (
                    <div>
                      <Input
                        style={{ width: "300px" }}
                        value={insertPayData[item]}
                        onChange={(e) => {
                          if (
                            item !== "员工ID" &&
                            item !== "员工姓名" &&
                            item !== "社保缴纳地" &&
                            item !== "是否外包" &&
                            item.indexOf("日期") == -1 &&
                            item.indexOf("时间")
                          ) {
                            let reg = new RegExp("^[0-9.]*$");
                            if (reg.test(e.target.value)) {
                              let obj = Object.assign({}, insertPayData);
                              obj[item] = e.target.value;
                              setInsertPayData(obj);
                            } else {
                              message.error("只能输入数字");
                            }
                          } else {
                            let obj = Object.assign({}, insertPayData);
                            obj[item] = e.target.value;
                            setInsertPayData(obj);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <AntdDragModal
        title={"切分薪酬时间"}
        visible={updateMonthPayModel}
        onCancel={() => {
          setUpdateMonthPayModel(false);
        }}
        onOk={() => {
          let selectedData = [];
          selectedPayRowKeys.map((item) => {
            payDataSource.map((data) => {
              if (data.id == item) {
                selectedData.push(data);
              }
            });
          });
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            selected_data: selectedData,
            split_time: splitTime.format(dateFormat),
            new_amount_of_month: frontPay,
          };
          if (frontPay === "" || splitTime === "") {
            message.warning("所有字段必填");
            return false;
          }
          splitSalaryTime(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="600px"
        children={
          <div
            style={{
              display: "grid",
              alignItems: "center",
              gridTemplateColumns: "50% 1fr",
            }}
          >
            <div>
              <div style={{ marginRight: "20px" }}>切分时间:</div>
              {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
              <DatePicker
                locale={locale}
                style={{ width: "220px" }}
                // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                value={splitTime}
                onChange={(value, dateString) => {
                  setSplitTime(value);
                }}
              />
            </div>
            <div>
              <div style={{ marginRight: "20px" }}>修改后月薪:</div>
              <Input
                value={frontPay}
                onChange={(e) => {
                  let reg = new RegExp("^[0-9.]*$");
                  if (reg.test(e.target.value)) {
                    setFrontPay(e.target.value);
                  } else {
                    message.error("只能输入数字");
                  }
                }}
                style={{ width: "220px" }}
              />
            </div>
          </div>
        }
      />
      <AntdDragModal
        title="修改薪酬汇总"
        visible={updateOnePayModel}
        onCancel={() => {
          setUpdateOnePayModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
          };
          dataProps.key_start_year_month_day = oldData["开始年份月份日期"];
          // {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
          if (
            updateStaff["公积金开始缴纳时间"] &&
            updateStaff["公积金开始缴纳时间"]
              .format(dateFormat)
              .split("-")[2] !== "01"
          ) {
            message.warning("公积金开始缴纳时间不为一号");
            return false;
          }
          // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
          // 修改字段值
          payFiled.map((item) => {
            if (updateStaff[item] || updateStaff[item] == 0) {
              if (
                (item.indexOf("月份") !== -1 || item.indexOf("时间") !== -1) &&
                updateStaff[item]
              ) {
                dataProps[fieldsDict[item]] =
                  updateStaff[item].format(dateFormat);
              } else {
                dataProps[fieldsDict[item]] = updateStaff[item];
              }
            }
          });
          dataProps["new_employeeId"] = updateStaff["员工ID"];
          dataProps["employeeId"] = oldData["员工ID"];
          dataProps["member_name"] = oldData["员工姓名"];
          dataProps["new_member_name"] = updateStaff["员工姓名"];
          changeMemberPayInformationSingle(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="1200px"
        children={
          <div
            style={{ display: "grid", gridTemplateColumns: "33.3% 33.3% 1fr" }}
          >
            {payFiled.map((item) => {
              return item !== "员工ID" && item !== "操作" ? (
                <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                  <div>{item}</div>
                  {item == "电脑补贴" ||
                  item == "是否外包" ||
                  item == "员工姓名" ||
                  item == "社保缴纳地" ? (
                    <div>
                      <Select
                        style={{ width: "300px" }}
                        value={
                          item == "员工姓名"
                            ? updateStaff["员工ID"] + "-" + updateStaff[item]
                            : updateStaff[item]
                        }
                        showSearch
                        allowClear
                        optionFilterProp="title"
                        onChange={(value) => {
                          if (item == "员工姓名") {
                            let obj = Object.assign({}, updateStaff);
                            obj["员工ID"] = value.split("-")[0];
                            obj[item] = value.split("-")[1];
                            setUpdateStaff(obj);
                          } else {
                            let obj = Object.assign({}, updateStaff);
                            obj[item] = value;
                            setUpdateStaff(obj);
                          }
                        }}
                      >
                        {item == "电脑补贴"
                          ? computerList.map((item) => (
                              <Option value={item} title={item}>
                                {item}
                              </Option>
                            ))
                          : item == "法定节假日加班费用"
                          ? holidayList.map((item) => (
                              <Option value={item} title={item}>
                                {item}
                              </Option>
                            ))
                          : item == "员工姓名"
                          ? staffList.map((item) => (
                              <Option value={item.value} title={item.value}>
                                {item.value}
                              </Option>
                            ))
                          : item == "社保缴纳地"
                          ? socialAddList.map((item) => (
                              <Option value={item.id} title={item.value}>
                                {item.value}
                              </Option>
                            ))
                          : out.map((item) => (
                              <Option value={item.id} title={item.value}>
                                {item.value}
                              </Option>
                            ))}
                        {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                      </Select>
                    </div>
                  ) : item.indexOf("月份") !== -1 ||
                    item.indexOf("时间") !== -1 ? (
                    <DatePicker
                      locale={locale}
                      style={{ width: "300px" }}
                      // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                      value={updateStaff[item]}
                      onChange={(value, dateString) => {
                        let obj = Object.assign({}, updateStaff);
                        obj[item] = value;
                        setUpdateStaff(obj);
                      }}
                    />
                  ) : (
                    <div>
                      <Input
                        style={{ width: "300px" }}
                        value={updateStaff[item]}
                        onChange={(e) => {
                          if (
                            item !== "员工ID" &&
                            item !== "员工姓名" &&
                            item !== "社保缴纳地" &&
                            item !== "是否外包" &&
                            item.indexOf("日期") == -1 &&
                            item.indexOf("时间")
                          ) {
                            let reg = new RegExp("^[0-9.]*$");
                            if (reg.test(e.target.value)) {
                              let obj = Object.assign({}, updateStaff);
                              obj[item] = e.target.value;
                              setUpdateStaff(obj);
                            } else {
                              message.error("只能输入数字");
                            }
                          } else {
                            let obj = Object.assign({}, updateStaff);
                            obj[item] = e.target.value;
                            setUpdateStaff(obj);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <Modal
        title="内部推荐"
        visible={recommendModel}
        onCancel={() => {
          setRecommendModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            recommendStaffID: recommendStaffID,
            recommendStaffName: recommendStaffName,
            recommendMoney: recommendMoney,
            recommendDate: recommendDate,
          };
          setRecommendModel(false);
          setRecommendStaffID("");
          setRecommendStaffName("");
          setRecommendMoney("");
          setRecommendDate("");
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="500px"
      >
        <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
          <div>员工ID</div>
          <div>
            <Input
              style={{ width: "350px" }}
              value={recommendStaffID}
              onChange={(e) => {
                setRecommendStaffID(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
          <div>员工姓名</div>
          <div>
            <Input
              style={{ width: "350px" }}
              value={recommendStaffName}
              onChange={(e) => {
                setRecommendStaffName(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
          <div>内部推荐奖金</div>
          <div>
            <Input
              style={{ width: "350px" }}
              value={recommendMoney}
              onChange={(e) => {
                setRecommendMoney(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
          <div>年份月份</div>
          <div>
            <DatePicker
              locale={locale}
              style={{ width: "350px" }}
              value={
                recommendDate == "" ? "" : moment(recommendDate, dateFormat)
              }
              onChange={(value, dateString) => {
                setRecommendDate(dateString);
              }}
            />
          </div>
        </div>
      </Modal>
      <AntdDragModal
        title={updateModelTitle}
        visible={updateSocialBaseModel}
        onCancel={() => {
          setUpdateSocialBaseModel(false);
        }}
        onOk={() => {
          // let obj = [...projectDataSource]
          // obj[updateStaffIndex] = updateStaff
          // setDataSource(obj)
          const startDate = updateSocialBase["开始年份月份日期"]
            ? updateSocialBase["开始年份月份日期"].format(dateFormat)
            : "";
          const endDate = updateSocialBase["结束年份月份日期"]
            ? updateSocialBase["结束年份月份日期"].format(dateFormat)
            : "";
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            old_social_security_station: oldData["社保缴纳地"],
            old_min_amount_of_month: oldData["最低月薪"],
            old_max_amount_of_month: oldData["最高月薪"],
            old_rate_of_amount_of_month: oldData["月薪判断系数"],
            old_social_security_cardinal: oldData["社保基数"],
            old_start_year_month_day: oldData["开始年份月份日期"],
            old_end_year_month_day: oldData["结束年份月份日期"],
            change_flag: 1,
            rate_of_amount_of_month: updateSocialBase["月薪判断系数"],
            social_security_cardinal: updateSocialBase["社保基数"],
            start_year_month_day: startDate,
            end_year_month_day: endDate,
          };
          if (
            updateSocialBase["月薪判断系数"] > 1 ||
            updateSocialBase["月薪判断系数"] < 0
          ) {
            message.warning("请填写正确的月薪判断系数");
            return false;
          }
          if (
            updateSocialBase["社保缴纳系数"] > 1 ||
            updateSocialBase["社保缴纳系数"] < 0
          ) {
            message.warning("请填写正确的社保缴纳系数");
            return false;
          }
          if (startDate >= endDate) {
            message.warning("开始年份月份日期不能大于或等于结束年份月份日期");
            return false;
          }
          if (
            updateModelTitle ==
            `修改社保基数判别${tableDict[calculation_type]}表`
          ) {
            dataProps.min_amount_of_month = updateSocialBase["最低月薪"];
            dataProps.max_amount_of_month = updateSocialBase["最高月薪"];
            if (
              (updateSocialBase["月薪判断系数"] == "0" &&
                updateSocialBase["社保基数"] != "0") ||
              (updateSocialBase["月薪判断系数"] != "0" &&
                updateSocialBase["社保基数"] == "0")
            ) {
              // #I661M1 NB20人资社保公积金编辑优化 start
              // // 前端修改
              // let data = [...socialBaseDataSource]
              // let obj = {...updateSocialBase}
              // Object.keys(obj).map(key => {
              //     if (key.indexOf('月份') !== -1 && obj[key]) {
              //         obj[key] = obj[key].format(dateFormat)
              //     }
              // })
              // data[updateStaffIndex] = obj
              // setSocialBaseDataSource(data)
              // message.success('修改成功')
              // setUpdateSocialBaseModel(false)
              let obj = { ...updateSocialBase };
              Object.keys(obj).map((key) => {
                if (key.indexOf("月份") !== -1 && obj[key]) {
                  obj[key] = obj[key].format(dateFormat);
                }
              });
              dataProps.table_data = [obj];
              dataProps.old_table_data = [oldData];
              // #I661M1 NB20人资社保公积金编辑优化 end
              changeSocialSecurity(dataProps);
            } else {
              message.warning("月薪判断系数和社保基数有且仅有一个为0");
            }
          } else if (updateModelTitle == "修改社保缴纳费用参数表") {
            if (
              (updateSocialBase["社保缴纳系数"] == "0" &&
                updateSocialBase["社保缴纳数"] != "0") ||
              (updateSocialBase["社保缴纳系数"] != "0" &&
                updateSocialBase["社保缴纳数"] == "0")
            ) {
              // #I661M1 NB20人资社保公积金编辑优化 start
              // dataProps.rate_of_social_security = updateSocialBase['社保缴纳系数']
              // dataProps.amount_of_social_security = updateSocialBase['社保缴纳数']
              dataProps.old_company = oldData["公司ID"];
              dataProps.old_social_security_subject = oldData["社保项目"];
              // changeSocialSecuritySubject(dataProps)
              // 前端修改
              // let data = [...socialPayDataSource]
              // let obj = {...updateSocialBase}
              // Object.keys(obj).map(key => {
              //     if (key.indexOf('月份') !== -1 && obj[key]) {
              //         obj[key] = obj[key].format(dateFormat)
              //     }
              // })
              // data[updateStaffIndex] = obj
              // setSocialPayDataSource(data)
              // message.success('修改成功')
              // setUpdateSocialBaseModel(false)
              let obj = { ...updateSocialBase };
              Object.keys(obj).map((key) => {
                if (key.indexOf("月份") !== -1 && obj[key]) {
                  obj[key] = obj[key].format(dateFormat);
                }
              });
              dataProps.table_data = [obj];
              dataProps.old_table_data = [oldData];
              // #I661M1 NB20人资社保公积金编辑优化 end
              changeSocialSecuritySubject(dataProps);
            } else {
              message.warning("社保缴纳系数和社保缴纳数有且仅有一个为0");
            }
          } else {
            const startFirstDate = getMonthFirst(startDate);
            const endLastDate = getMonthLast(endDate);
            // #I6H8WG NB20人资新增社保公积金只能选择每月一号判断 start
            if (startDate === startFirstDate && endDate === endLastDate) {
              dataProps.old_employeeId = oldData["员工ID"];
              dataProps.old_member_name = oldData["员工姓名"];
              dataProps.old_social_security_station = oldData["社保缴纳地"];
              dataProps.old_social_security_cardinal = oldData["社保基数"];
              dataProps.original_change_time = oldData["变更时间"];
              dataProps.old_special_status = oldData["是否特殊"];
              dataProps.old_id = oldData["ID"];
              dataProps.special_status = updateSocialBase["是否特殊"];
              dataProps.new_employeeId = updateSocialBase["员工ID"];
              dataProps.member_name = updateSocialBase["员工姓名"];
              // addChangeSocialSecurity(dataProps)
              changeSocialSecurityThird(dataProps);
            } else {
              message.error(
                "开始时间只能选择每月一号,结束时间只能选择每月最后一号"
              );
            }
            //  #I6H8WG NB20人资新增社保公积金只能选择每月一号判断 end
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width={900}
        children={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {updateField.map((item) => {
              // #I661M1 NB20人资社保公积金编辑优化 start
              return item !== "操作" ? (
                <div className={style.updateProject}>
                  {/* #I661M1 NB20人资社保公积金编辑优化 end */}
                  <div
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      textAlign: "right",
                    }}
                  >
                    <Text>{item}</Text>
                  </div>
                  <div>
                    {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                    {item.indexOf("月份") !== -1 ? (
                      <DatePicker
                        locale={locale}
                        style={{ width: "220px", marginBottom: "10px" }}
                        // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                        value={updateSocialBase[item]}
                        onChange={(value, dateString) => {
                          let obj = Object.assign({}, updateSocialBase);
                          obj[item] = value;
                          setUpdateSocialBase(obj);
                        }}
                      />
                    ) : selectDict[item] ? (
                      <Select
                        value={
                          item == "员工姓名"
                            ? updateSocialBase["员工ID"] +
                              "-" +
                              updateSocialBase[item]
                            : updateSocialBase[item]
                        }
                        style={{ width: "220px", marginBottom: "10px" }}
                        allowClear
                        showSearch
                        optionFilterProp="title"
                        onChange={(value) => {
                          let obj = Object.assign({}, updateSocialBase);
                          if (item == "员工姓名") {
                            obj["员工ID"] = value.split("-")[0];
                            obj[item] = value.split("-")[1];
                            setUpdateSocialBase(obj);
                          } else {
                            obj[item] = value;
                            setUpdateSocialBase(obj);
                          }
                        }}
                        // #I6EMPE NB20人资公积金社保修改弹窗禁止修改员工姓名与ID start
                        disabled={item === "员工姓名" ? true : false}
                        // #I6EMPE NB20人资公积金社保修改弹窗禁止修改员工姓名与ID end
                      >
                        {selectDict[item].map((item) => (
                          <Option value={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        value={updateSocialBase[item]}
                        onChange={(e) => {
                          let reg = new RegExp("^[0-9.]*$");
                          if (reg.test(e.target.value)) {
                            let obj = Object.assign({}, updateSocialBase);
                            obj[item] = e.target.value;
                            setUpdateSocialBase(obj);
                          } else {
                            message.error("只能输入数字");
                          }
                        }}
                        style={{ width: "220px", marginBottom: "10px" }}
                        // #I6EMPE NB20人资公积金社保修改弹窗禁止修改员工姓名与ID start
                        disabled={item === "员工ID" ? true : false}
                        // #I6EMPE NB20人资公积金社保修改弹窗禁止修改员工姓名与ID end
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <AntdDragModal
        title={updateModelTitle}
        visible={updateSocialRangeModel}
        onCancel={() => {
          setUpdateSocialRangeModel(false);
        }}
        onOk={() => {
          // let obj = [...projectDataSource]
          // obj[updateStaffIndex] = updateStaff
          // setDataSource(obj)
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            key_social_security_station: oldData["社保缴纳地"],
            key_start_year_month_day: oldData["开始年份月份日期"],
          };
          // 修改字段值
          socialBaseRangeEstimateFiled.map((item) => {
            if (updateSocialBase[item] || updateSocialBase[item] == 0) {
              if (
                (item.indexOf("月份") !== -1 || item.indexOf("时间") !== -1) &&
                updateSocialBase[item]
              ) {
                dataProps[fieldsDict[item]] =
                  updateSocialBase[item].format(dateFormat);
              } else {
                dataProps[fieldsDict[item]] = updateSocialBase[item];
              }
            }
          });
          // #I661M1 NB20人资社保公积金编辑优化 start
          let obj = { ...updateSocialBase };
          Object.keys(obj).map((key) => {
            if (key.indexOf("月份") !== -1 && obj[key]) {
              obj[key] = obj[key].format(dateFormat);
            }
          });
          dataProps.table_data = [obj];
          dataProps.old_table_data = [oldData];
          // #I661M1 NB20人资社保公积金编辑优化 end
          changeSocialSecurityRange(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width={900}
        children={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {updateField.map((item) => {
              return item !== "操作" ? (
                <div className={style.updateProject}>
                  <div
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      textAlign: "right",
                    }}
                  >
                    <Text>{item}</Text>
                  </div>
                  <div>
                    {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                    {item.indexOf("月份") !== -1 ? (
                      <DatePicker
                        locale={locale}
                        style={{ width: "220px", marginBottom: "10px" }}
                        // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                        value={updateSocialBase[item]}
                        onChange={(value, dateString) => {
                          let obj = Object.assign({}, updateSocialBase);
                          obj[item] = value;
                          setUpdateSocialBase(obj);
                        }}
                      />
                    ) : selectDict[item] ? (
                      <Select
                        value={updateSocialBase[item]}
                        style={{ width: "220px", marginBottom: "10px" }}
                        allowClear
                        showSearch
                        optionFilterProp="title"
                        onChange={(value) => {
                          let obj = Object.assign({}, updateSocialBase);
                          obj[item] = value;
                          setUpdateSocialBase(obj);
                        }}
                      >
                        {selectDict[item].map((item) => (
                          <Option value={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        value={updateSocialBase[item]}
                        onChange={(e) => {
                          let reg = new RegExp("^[0-9.]*$");
                          if (reg.test(e.target.value)) {
                            let obj = Object.assign({}, updateSocialBase);
                            obj[item] = e.target.value;
                            setUpdateSocialBase(obj);
                          } else {
                            message.error("只能输入数字");
                          }
                        }}
                        style={{ width: "220px", marginBottom: "10px" }}
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <AntdDragModal
        title={updateModelTitle}
        visible={updateAccumulationFundModel}
        onCancel={() => {
          setUpdateAccumulationFundModel(false);
        }}
        onOk={() => {
          // let obj = [...projectDataSource]
          // obj[updateStaffIndex] = updateStaff
          // setDataSource(obj)
          const startDate = updateSocialBase["开始年份月份日期"]
            ? updateSocialBase["开始年份月份日期"].format(dateFormat)
            : "";
          const endDate = updateSocialBase["结束年份月份日期"]
            ? updateSocialBase["结束年份月份日期"].format(dateFormat)
            : "";
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            old_social_security_station: oldData["社保缴纳地"],
            old_min_amount_of_month: oldData["最低月薪"],
            old_max_amount_of_month: oldData["最高月薪"],
            old_rate_of_amount_of_month: oldData["月薪判断系数"],
            old_accumulation_fund_cardinal: oldData["公积金基数"],
            original_change_time: oldData["变更时间"],
            old_start_year_month_day: oldData["开始年份月份日期"],
            old_end_year_month_day: oldData["结束年份月份日期"],
            change_flag: 1,
            min_amount_of_month: updateSocialBase["最低月薪"],
            max_amount_of_month: updateSocialBase["最高月薪"],
            rate_of_amount_of_month: updateSocialBase["月薪判断系数"],
            accumulation_fund_cardinal: updateSocialBase["公积金基数"],
            start_year_month_day: startDate,
            end_year_month_day: endDate,
          };
          if (startDate >= endDate) {
            message.warning("开始年份月份日期不能大于或等于结束年份月份日期");
            return false;
          }
          if (
            updateModelTitle ==
            `修改公积金基数判别${tableDict[calculation_type]}表`
          ) {
            if (
              (updateSocialBase["月薪判断系数"] != "0" &&
                updateSocialBase["公积金基数"] == "0") ||
              (updateSocialBase["月薪判断系数"] == "0" &&
                updateSocialBase["公积金基数"] != "0")
            ) {
              if (
                updateSocialBase["月薪判断系数"] < 0 ||
                updateSocialBase["月薪判断系数"] > 1
              ) {
                message.warning("请填写正确的月薪判断系数");
              } else {
                // #I661M1 NB20人资社保公积金编辑优化 start
                let obj = { ...updateSocialBase };
                Object.keys(obj).map((key) => {
                  if (key.indexOf("月份") !== -1 && obj[key]) {
                    obj[key] = obj[key].format(dateFormat);
                  }
                });
                dataProps.table_data = [obj];
                dataProps.old_table_data = [oldData];
                changeAccumulationFundJudgmentMaintain(dataProps);
                // // 前端修改
                // let data = [...accumulationFundBaseDataSource]
                // let obj = {...updateSocialBase}
                // Object.keys(obj).map(key => {
                //     if (key.indexOf('月份') !== -1 && obj[key]) {
                //         obj[key] = obj[key].format(dateFormat)
                //     }
                // })
                // data[updateStaffIndex] = obj
                // setAccumulationFundBaseDataSource(data)
                // message.success('修改成功')
                // setUpdateAccumulationFundModel(false)
                // #I661M1 NB20人资社保公积金编辑优化 end
              }
            } else {
              message.warning("月薪判断系数和公积金基数有且只有一个为0");
            }
          } else {
            const startFirstDate = getMonthFirst(startDate);
            const endLastDate = getMonthLast(endDate);
            // #I6H8WG NB20人资新增社保公积金只能选择每月一号判断 start
            if (startDate === startFirstDate && endDate === endLastDate) {
              dataProps.old_employeeId = oldData["员工ID"];
              dataProps.old_member_name = oldData["员工姓名"];
              dataProps.old_special_status = oldData["是否特殊"];
              dataProps.special_status = updateSocialBase["是否特殊"];
              dataProps.old_id = oldData["ID"];
              dataProps.new_employeeId = updateSocialBase["员工ID"];
              dataProps.member_name = updateSocialBase["员工姓名"];
              changeAccumulationFund(dataProps);
            } else {
              message.error(
                "开始时间只能选择每月一号,结束时间只能选择每月最后一号"
              );
            }
            //  #I6H8WG NB20人资新增社保公积金只能选择每月一号判断 end
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width={900}
        children={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {updateField.map((item) => {
              // #I661M1 NB20人资社保公积金编辑优化 start
              return item !== "操作" ? (
                <div className={style.updateProject}>
                  {/* #I661M1 NB20人资社保公积金编辑优化 end */}
                  <div
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      textAlign: "right",
                    }}
                  >
                    <Text>{item}</Text>
                  </div>
                  <div>
                    {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                    {item.indexOf("月份") !== -1 ? (
                      <DatePicker
                        locale={locale}
                        style={{ width: "220px", marginBottom: "10px" }}
                        // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                        value={updateSocialBase[item]}
                        onChange={(value, dateString) => {
                          let obj = Object.assign({}, updateSocialBase);
                          obj[item] = value;
                          setUpdateSocialBase(obj);
                        }}
                      />
                    ) : selectDict[item] ? (
                      <Select
                        value={
                          item == "员工姓名"
                            ? updateSocialBase["员工ID"] +
                              "-" +
                              updateSocialBase[item]
                            : updateSocialBase[item]
                        }
                        style={{ width: "220px", marginBottom: "10px" }}
                        allowClear
                        showSearch
                        optionFilterProp="title"
                        onChange={(value) => {
                          let obj = Object.assign({}, updateSocialBase);
                          if (item == "员工姓名") {
                            obj["员工ID"] = value.split("-")[0];
                            obj[item] = value.split("-")[1];
                            setUpdateSocialBase(obj);
                          } else {
                            obj[item] = value;
                            setUpdateSocialBase(obj);
                          }
                        }}
                        // #I6EMPE NB20人资公积金社保修改弹窗禁止修改员工姓名与ID start
                        disabled={item === "员工姓名" ? true : false}
                        // #I6EMPE NB20人资公积金社保修改弹窗禁止修改员工姓名与ID end
                      >
                        {selectDict[item].map((item) => (
                          <Option value={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        value={updateSocialBase[item]}
                        onChange={(e) => {
                          let reg = new RegExp("^[0-9.]*$");
                          if (reg.test(e.target.value)) {
                            let obj = Object.assign({}, updateSocialBase);
                            obj[item] = e.target.value;
                            setUpdateSocialBase(obj);
                          } else {
                            message.error("只能输入数字");
                          }
                        }}
                        style={{ width: "220px", marginBottom: "10px" }}
                        // #I6EMPE NB20人资公积金社保修改弹窗禁止修改员工姓名与ID start
                        disabled={item === "员工ID" ? true : false}
                        // #I6EMPE NB20人资公积金社保修改弹窗禁止修改员工姓名与ID end
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <AntdDragModal
        title={updateModelTitle}
        visible={updateAccumulationFundRangeModel}
        onCancel={() => {
          setUpdateAccumulationFundRangeModel(false);
        }}
        onOk={() => {
          // let obj = [...projectDataSource]
          // obj[updateStaffIndex] = updateStaff
          // setDataSource(obj)
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            key_social_security_station: oldData["社保缴纳地"],
            key_start_year_month_day: oldData["开始年份月份日期"],
          };
          // 修改字段值
          accumulationFundRangeEstimateFiled.map((item) => {
            if (updateSocialBase[item] || updateSocialBase[item] == 0) {
              if (
                (item.indexOf("月份") !== -1 || item.indexOf("时间") !== -1) &&
                updateSocialBase[item]
              ) {
                dataProps[fieldsDict[item]] =
                  updateSocialBase[item].format(dateFormat);
              } else {
                dataProps[fieldsDict[item]] = updateSocialBase[item];
              }
            }
          });
          // #I661M1 NB20人资社保公积金编辑优化 start
          let obj = { ...updateSocialBase };
          Object.keys(obj).map((key) => {
            if (key.indexOf("月份") !== -1 && obj[key]) {
              obj[key] = obj[key].format(dateFormat);
            }
          });
          dataProps.table_data = [obj];
          dataProps.old_table_data = [oldData];
          // #I661M1 NB20人资社保公积金编辑优化 end
          changeWorkerAccumulationRange(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width={900}
        children={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {updateField.map((item) => {
              return item !== "操作" ? (
                <div className={style.updateProject}>
                  <div
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      textAlign: "right",
                    }}
                  >
                    <Text>{item}</Text>
                  </div>
                  <div>
                    {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                    {item.indexOf("月份") !== -1 ? (
                      <DatePicker
                        locale={locale}
                        style={{ width: "220px", marginBottom: "10px" }}
                        // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                        value={updateSocialBase[item]}
                        onChange={(value, dateString) => {
                          let obj = Object.assign({}, updateSocialBase);
                          obj[item] = value;
                          setUpdateSocialBase(obj);
                        }}
                      />
                    ) : selectDict[item] ? (
                      <Select
                        value={updateSocialBase[item]}
                        style={{ width: "220px", marginBottom: "10px" }}
                        allowClear
                        showSearch
                        optionFilterProp="title"
                        onChange={(value) => {
                          let obj = Object.assign({}, updateSocialBase);
                          obj[item] = value;
                          setUpdateSocialBase(obj);
                        }}
                      >
                        {selectDict[item].map((item) => (
                          <Option value={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        value={updateSocialBase[item]}
                        onChange={(e) => {
                          let reg = new RegExp("^[0-9.]*$");
                          if (reg.test(e.target.value)) {
                            let obj = Object.assign({}, updateSocialBase);
                            obj[item] = e.target.value;
                            setUpdateSocialBase(obj);
                          } else {
                            message.error("只能输入数字");
                          }
                        }}
                        style={{ width: "220px", marginBottom: "10px" }}
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <AntdDragModal
        title="修改个人所得税参数表"
        visible={updateTaxModel}
        onCancel={() => {
          setUpdateTaxModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            old_min_taxable_income: oldData["最小纳税所得额"],
            old_max_taxable_income: oldData["最大纳税所得额"],
            old_tax_rate: oldData["税率"],
            old_quick_calculation_deduction: oldData["速算扣除"],
            old_start_year_month_day: oldData["开始年份月份日期"],
            old_end_year_month_day: oldData["结束年份月份日期"],
            change_flag: 1,
            tax_rate: updateSocialBase["税率"],
            quick_calculation_deduction: updateSocialBase["速算扣除"],
          };
          addPersonalIncomeTaxMaintain(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width={900}
        children={
          <div
            style={{ textAlign: "right", display: "flex", flexWrap: "wrap" }}
          >
            {updateField.map((item) => {
              return item !== "操作" &&
                item !== "最小纳税所得额" &&
                item !== "最大纳税所得额" &&
                item.indexOf("日期") == -1 ? (
                <div className={style.updateProject}>
                  <div style={{ marginRight: "10px", marginBottom: "10px" }}>
                    <Text>{item}</Text>
                  </div>
                  <div>
                    {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                    {item.indexOf("月份") !== -1 ? (
                      <DatePicker
                        locale={locale}
                        style={{ width: "220px", marginBottom: "10px" }}
                        // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                        value={updateSocialBase[item]}
                        onChange={(value, dateString) => {
                          let obj = Object.assign({}, updateSocialBase);
                          obj[item] = value;
                          setUpdateSocialBase(obj);
                        }}
                      />
                    ) : selectDict[item] ? (
                      <Select
                        value={updateSocialBase[item]}
                        style={{ width: "220px", marginBottom: "10px" }}
                        allowClear
                        showSearch
                        optionFilterProp="title"
                        onChange={(value) => {
                          let obj = Object.assign({}, updateSocialBase);
                          obj[item] = value;
                          setUpdateSocialBase(obj);
                        }}
                      >
                        {selectDict[item].map((item) => (
                          <Option value={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        value={updateSocialBase[item]}
                        onChange={(e) => {
                          let reg = new RegExp("^[0-9.]*$");
                          if (reg.test(e.target.value)) {
                            let obj = Object.assign({}, updateSocialBase);
                            obj[item] = e.target.value;
                            setUpdateSocialBase(obj);
                          } else {
                            message.error("只能输入数字");
                          }
                        }}
                        style={{ width: "220px", marginBottom: "10px" }}
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <AntdDragModal
        title={updateModelTitle}
        visible={insertSocialModel}
        onCancel={() => {
          setInsertSocialModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            calculation_type: calculation_type,
            change_member: cookie.load("name"),
          };
          console.log(insertSocialFiledData["开始年份月份日期"]);
          const startDate = insertSocialFiledData["开始年份月份日期"]
            ? insertSocialFiledData["开始年份月份日期"].format(dateFormat)
            : "";
          const endDate = insertSocialFiledData["结束年份月份日期"]
            ? insertSocialFiledData["结束年份月份日期"].format(dateFormat)
            : "";
          if (insertSocialFiledData["社保缴纳地"] === "") {
            message.warning("社保缴纳地必填");
          } else if (startDate === "") {
            message.warning("开始年份月份日期必填");
          } else if (endDate === "") {
            message.warning("结束年份月份日期必填");
          } else if (insertSocialFiledData["最低月薪"] === "") {
            message.warning("最低月薪必填");
          } else if (insertSocialFiledData["最高月薪"] === "") {
            message.warning("最高月薪必填");
          } else if (insertSocialFiledData["月薪判断系数"] === "") {
            message.warning("月薪判断系数必填");
          } else if (insertSocialFiledData["社保基数"] === "") {
            message.warning("社保基数必填");
          } else if (insertSocialFiledData["公司"] === "") {
            message.warning("公司必填");
          } else if (insertSocialFiledData["社保项目"] === "") {
            message.warning("社保项目必填");
          } else if (insertSocialFiledData["社保缴纳数"] === "") {
            message.warning("社保缴纳数必填");
          } else if (insertSocialFiledData["社保缴纳系数"] === "") {
            message.warning("社保缴纳系数必填");
          } else if (insertSocialFiledData["是否特殊"] === "") {
            message.warning("是否特殊必填");
          } else if (
            updateModelTitle ==
              `新增社保基数判别${tableDict[calculation_type]}表` &&
            ((insertSocialFiledData["月薪判断系数"] != "0" &&
              insertSocialFiledData["社保基数"] != "0") ||
              (insertSocialFiledData["月薪判断系数"] == "0" &&
                insertSocialFiledData["社保基数"] == "0"))
          ) {
            message.warning("月薪判断系数与社保基数有且仅有一个0");
          } else if (
            updateModelTitle == "新增社保缴纳费用参数表" &&
            ((insertSocialFiledData["社保缴纳系数"] != "0" &&
              insertSocialFiledData["社保缴纳数"] != "0") ||
              (insertSocialFiledData["社保缴纳系数"] == "0" &&
                insertSocialFiledData["社保缴纳数"] == "0"))
          ) {
            message.warning("社保缴纳系数与社保缴纳数有且仅有一个0");
          } else if (startDate >= endDate) {
            message.warning("开始年份月份日期不能大于或等于结束年份月份日期");
          } else {
            socialFiled.map((item) => {
              if (item.indexOf("月份") !== -1 && insertSocialFiledData[item]) {
                dataProps[fieldsDict[item]] =
                  insertSocialFiledData[item].format(dateFormat);
              } else {
                dataProps[fieldsDict[item]] = insertSocialFiledData[item];
              }
            });
            if (
              updateModelTitle ==
              `新增社保基数判别${tableDict[calculation_type]}表`
            ) {
              if (
                insertSocialFiledData["最低月薪"] >
                insertSocialFiledData["最高月薪"]
              ) {
                message.warning("最低月薪不得高于最高月薪");
                return false;
              }
              if (
                insertSocialFiledData["月薪判断系数"] > 1 ||
                insertSocialFiledData["月薪判断系数"] < 0
              ) {
                message.warning("月薪判断系数不能大于1或小于0");
                return false;
              }
              addNewWorkerSocialSecurity(dataProps);
            } else if (updateModelTitle == `新增社保缴纳费用参数表`) {
              if (
                insertSocialFiledData["社保缴纳系数"] > 1 ||
                insertSocialFiledData["社保缴纳系数"] < 0
              ) {
                message.warning("社保缴纳系数不能大于1或小于0");
                return false;
              }
              if (startDate.split("-")[0] === endDate.split("-")[0]) {
                if (startDate.split("-")[1] === endDate.split("-")[1]) {
                  message.warning(
                    "开始年份月份日期与结束年份月份日期不能同年同月"
                  );
                  return false;
                }
              }
              addSocialSecuritySubject(dataProps);
            } else if (
              updateModelTitle == `新增社保基数${tableDict[calculation_type]}表`
            ) {
              const startFirstDate = getMonthFirst(startDate);
              const endLastDate = getMonthLast(endDate);
              // #I6H8WG NB20人资新增社保公积金只能选择每月一号判断 start
              if (startDate === startFirstDate && endDate === endLastDate) {
                addSocialSecurity(dataProps);
              } else {
                message.error(
                  "开始时间只能选择每月一号,结束时间只能选择每月最后一号"
                );
              }
              //  #I6H8WG NB20人资新增社保公积金只能选择每月一号判断 end
            } else if (
              updateModelTitle ==
              `新增社保基数范围判别${tableDict[calculation_type]}表`
            ) {
              addSocialSecurityRange(dataProps);
            }
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="800px"
        children={
          <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
            {socialFiled.map((item) => {
              return item !== "操作" && item != "员工ID" ? (
                <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                  <div>{item}</div>
                  {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                  {item.indexOf("月份") !== -1 ? (
                    <DatePicker
                      locale={locale}
                      style={{ width: "300px" }}
                      // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                      value={insertSocialFiledData[item]}
                      onChange={(value, dateString) => {
                        let obj = Object.assign({}, insertSocialFiledData);
                        obj[item] = value;
                        setInsertSocialFiledData(obj);
                      }}
                    />
                  ) : selectDict[item] ? (
                    <Select
                      value={insertSocialFiledData[item]}
                      style={{ width: "300px" }}
                      allowClear
                      showSearch
                      optionFilterProp="title"
                      onChange={(value) => {
                        let obj = Object.assign({}, insertSocialFiledData);
                        obj[item] = value;
                        setInsertSocialFiledData(obj);
                      }}
                    >
                      {selectDict[item].map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      style={{ width: "300px" }}
                      value={insertSocialFiledData[item]}
                      onChange={(e) => {
                        let obj = Object.assign({}, insertSocialFiledData);
                        obj[item] = e.target.value;
                        setInsertSocialFiledData(obj);
                      }}
                    />
                  )}
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <AntdDragModal
        title={updateModelTitle}
        visible={insertAccumulationFundModel}
        onCancel={() => {
          setInsertAccumulationFundModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
          };
          const startDate = insertAccumulationFundFiledData["开始年份月份日期"]
            ? insertAccumulationFundFiledData["开始年份月份日期"].format(
                dateFormat
              )
            : "";
          const endDate = insertAccumulationFundFiledData["结束年份月份日期"]
            ? insertAccumulationFundFiledData["结束年份月份日期"].format(
                dateFormat
              )
            : "";
          if (staffData === "" || endDate === "") {
            message.error("开始时间结束时间不能为空");
            return false;
          }
          accumulationFundFiled.map((item) => {
            if (
              item.indexOf("月份") !== -1 &&
              insertAccumulationFundFiledData[item]
            ) {
              dataProps[fieldsDict[item]] =
                insertAccumulationFundFiledData[item].format(dateFormat);
            } else {
              dataProps[fieldsDict[item]] =
                insertAccumulationFundFiledData[item];
            }
          });
          if (
            insertAccumulationFundFiledData["月薪判断系数"] > 1 ||
            insertAccumulationFundFiledData["月薪判断系数"] < 0
          ) {
            message.warning("请输入正确的月薪判断系数");
            return false;
          }
          if (startDate >= endDate) {
            message.warning("开始年份月份日期不能大于或等于结束年份月份日期");
            return false;
          }
          if (
            updateModelTitle ==
            `新增公积金基数判别${tableDict[calculation_type]}表`
          ) {
            if (
              (insertAccumulationFundFiledData["月薪判断系数"] == "0" &&
                insertAccumulationFundFiledData["公积金基数"] !== "0") ||
              (insertAccumulationFundFiledData["月薪判断系数"] !== "0" &&
                insertAccumulationFundFiledData["公积金基数"] == "0")
            ) {
              addAccumulationFundJudgmentMaintain(dataProps);
            } else {
              message.warning("月薪判断系数和公积金基数有且仅有一个为0");
            }
          } else if (
            updateModelTitle == `新增公积金基数${tableDict[calculation_type]}表`
          ) {
            const startFirstDate = getMonthFirst(startDate);
            const endLastDate = getMonthLast(endDate);
            // #I6H8WG NB20人资新增社保公积金只能选择每月一号判断 start
            if (startDate === startFirstDate && endDate === endLastDate) {
              addAccumulationFund(dataProps);
            } else {
              message.error(
                "开始时间只能选择每月一号,结束时间只能选择每月最后一号"
              );
            }
            // #I6H8WG NB20人资新增社保公积金只能选择每月一号判断 end
          } else if (
            updateModelTitle ==
            `新增公积金基数范围判别${tableDict[calculation_type]}表`
          ) {
            addWorkerAccumulationRange(dataProps);
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="800px"
        children={
          <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
            {accumulationFundFiled.map((item) => {
              return item !== "操作" && item !== "员工ID" ? (
                <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                  <div>{item}</div>
                  {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                  {item.indexOf("月份") !== -1 ? (
                    <DatePicker
                      locale={locale}
                      style={{ width: "300px" }}
                      // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                      value={insertAccumulationFundFiledData[item]}
                      onChange={(value, dateString) => {
                        let obj = Object.assign(
                          {},
                          insertAccumulationFundFiledData
                        );
                        obj[item] = value;
                        setInsertAccumulationFundFiledData(obj);
                      }}
                    />
                  ) : selectDict[item] ? (
                    <Select
                      value={insertAccumulationFundFiledData[item]}
                      style={{ width: "300px" }}
                      allowClear
                      showSearch
                      optionFilterProp="title"
                      onChange={(value) => {
                        let obj = Object.assign(
                          {},
                          insertAccumulationFundFiledData
                        );
                        obj[item] = value;
                        setInsertAccumulationFundFiledData(obj);
                      }}
                    >
                      {selectDict[item].map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      style={{ width: "300px" }}
                      value={insertAccumulationFundFiledData[item]}
                      onChange={(e) => {
                        let obj = Object.assign(
                          {},
                          insertAccumulationFundFiledData
                        );
                        obj[item] = e.target.value;
                        setInsertAccumulationFundFiledData(obj);
                      }}
                    />
                  )}
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <AntdDragModal
        title="新增个人所得税参数"
        visible={insertTaxModel}
        onCancel={() => {
          setInsertTaxModel(false);
        }}
        onOk={() => {
          if (taxFiledData["最小纳税所得额"] === "") {
            message.warning("最小纳税所得额必填");
          } else if (taxFiledData["最大纳税所得额"] === "") {
            message.warning("最大纳税所得额必填");
          } else if (taxFiledData["税率"] === "") {
            message.warning("税率必填");
          } else if (taxFiledData["速算扣除"] === "") {
            message.warning("速算扣除必填");
          } else if (taxFiledData["开始年份月份日期"] === "") {
            message.warning("开始年份月份日期必填");
          } else {
            let dataProps = {
              roleId: cookie.load("role"),
              shopList: cookie.load("shopList"),
              change_member: cookie.load("name"),
              calculation_type: calculation_type,
              min_taxable_income: taxFiledData["最小纳税所得额"],
              max_taxable_income: taxFiledData["最大纳税所得额"],
              tax_rate: taxFiledData["税率"],
              quick_calculation_deduction: taxFiledData["速算扣除"],
              start_year_month_day: taxFiledData["开始年份月份日期"]
                ? taxFiledData["开始年份月份日期"].format(dateFormat)
                : taxFiledData["开始年份月份日期"],
              end_year_month_day: taxFiledData["结束年份月份日期"]
                ? taxFiledData["结束年份月份日期"].format(dateFormat)
                : taxFiledData["结束年份月份日期"],
            };
            changePersonalIncomeTaxMaintain(dataProps);
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="800px"
        children={
          <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
            {taxFiled.map((item) => {
              return item !== "操作" ? (
                <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                  <div>{item}</div>
                  {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
                  {item.indexOf("月份") !== -1 ? (
                    <DatePicker
                      locale={locale}
                      style={{ width: "300px" }}
                      // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                      value={taxFiledData[item]}
                      onChange={(value, dateString) => {
                        let obj = Object.assign({}, taxFiledData);
                        obj[item] = value;
                        setInsertTaxFiledData(obj);
                      }}
                    />
                  ) : selectDict[item] ? (
                    <Select
                      value={taxFiledData[item]}
                      style={{ width: "300px" }}
                      allowClear
                      showSearch
                      optionFilterProp="title"
                      onChange={(value) => {
                        let obj = Object.assign({}, taxFiledData);
                        obj[item] = value;
                        setInsertTaxFiledData(obj);
                      }}
                    >
                      {selectDict[item].map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      style={{ width: "300px" }}
                      value={taxFiledData[item]}
                      onChange={(e) => {
                        let obj = Object.assign({}, taxFiledData);
                        obj[item] = e.target.value;
                        setInsertTaxFiledData(obj);
                      }}
                    />
                  )}
                </div>
              ) : (
                ""
              );
            })}
          </div>
        }
      />
      <AntdDragModal
        title={updateModelTitle}
        visible={splitPayModel}
        onCancel={() => {
          setSplitPayModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            old_social_security_station: oldData["社保缴纳地"],
            old_min_amount_of_month: oldData["最低月薪"],
            old_max_amount_of_month: oldData["最高月薪"],
            old_rate_of_amount_of_month: oldData["月薪判断系数"],
            old_social_security_cardinal: oldData["社保基数"],
            old_start_year_month_day: oldData["开始年份月份日期"],
            old_end_year_month_day: oldData["结束年份月份日期"],
            change_flag: 2,
            month_pay: splitPay,
            before_rate_of_amount_of_month_1: frontPay,
            after_rate_of_amount_of_month_1: afterPay,
            before_social_security_cardinal_1: frontSocailBase,
            after_social_security_cardinal_1: afterSocailBase,
          };
          if (
            splitPay === "" ||
            afterPay === "" ||
            frontPay === "" ||
            frontSocailBase === "" ||
            afterSocailBase === ""
          ) {
            message.warning("所有字段必填");
            return false;
          }
          if (afterPay === frontPay && afterSocailBase === frontSocailBase) {
            message.error("不能输入前后完全一致的月薪系数与社保基数");
            return false;
          }
          if (
            (afterPay !== "0" && afterSocailBase !== "0") ||
            (frontPay !== "0" && frontSocailBase !== "0")
          ) {
            message.error("月薪系数和社保基数必须有一个为0");
            return false;
          }
          if (
            (afterPay == 0 && afterSocailBase == 0) ||
            (frontPay == 0 && frontSocailBase == 0)
          ) {
            message.error("月薪系数和社保基数有且仅有一个为0");
            return false;
          }
          if (afterPay > 1 || frontPay > 1) {
            message.error("月薪系数不能超过1");
            return false;
          }
          changeSocialSecurity(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="600px"
        children={
          <div>
            <div style={{ alignItems: "center" }}>
              <div style={{ marginRight: "20px" }}>切分月薪:</div>
              <Input
                value={splitPay}
                onChange={(e) => {
                  let reg = new RegExp("^[0-9.]*$");
                  if (reg.test(e.target.value)) {
                    setSplitPay(e.target.value);
                  } else {
                    message.error("只能输入数字");
                  }
                }}
                style={{ width: "220px" }}
              />
            </div>
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "50% 1fr",
              }}
            >
              <div>
                <div style={{ marginRight: "20px" }}>前月薪判断系数:</div>
                <Input
                  value={frontPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>后月薪判断系数:</div>
                <Input
                  value={afterPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{`前社保基数:`}</div>
                {/* <Select style={{width:'220px'}}  value={frontSocailBase} onChange={(value) => {
                                setFrontSocailBase(value)
                            }}>
                                {socialBaseList.map(item => (
                                    <Option value={item.id}>{item.value}</Option>
                                ))}
                            </Select> */}
                <Input
                  value={frontSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{`后社保基数:`}</div>
                {/* <Select style={{width:'220px'}}  value={afterSocailBase} onChange={(value) => {
                                setAfterSocailBase(value)
                            }}>
                                {socialBaseList.map(item => (
                                    <Option value={item.id}>{item.value}</Option>
                                ))}
                            </Select> */}
                <Input
                  value={afterSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
            </div>
          </div>
        }
      />
      <AntdDragModal
        title={updateModelTitle}
        visible={splitAccumulationFundPayModel}
        onCancel={() => {
          setSplitAccumulationFundPayModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            old_social_security_station: oldData["社保缴纳地"],
            old_min_amount_of_month: oldData["最低月薪"],
            old_max_amount_of_month: oldData["最高月薪"],
            old_rate_of_amount_of_month: oldData["月薪判断系数"],
            old_start_year_month_day: oldData["开始年份月份日期"],
            old_end_year_month_day: oldData["结束年份月份日期"],
            change_flag: 2,
            month_pay: splitPay,
            before_rate_of_amount_of_month_1: frontPay,
            after_rate_of_amount_of_month_1: afterPay,
            before_accumulation_fund_cardinal_1: frontSocailBase,
            after_accumulation_fund_cardinal_1: afterSocailBase,
          };
          if (
            splitPay === "" ||
            afterPay === "" ||
            frontPay === "" ||
            frontSocailBase === "" ||
            afterSocailBase === ""
          ) {
            message.warning("所有字段必填");
            return false;
          }
          if (afterPay === frontPay && afterSocailBase === frontSocailBase) {
            message.error("不能输入前后完全一致的月薪系数与公积金基数");
            return false;
          }
          if (
            (afterPay !== "0" && afterSocailBase !== "0") ||
            (frontPay !== "0" && frontSocailBase !== "0")
          ) {
            message.error("月薪系数和公积金基数必须有一个为0");
            return false;
          }
          if (
            (afterPay == 0 && afterSocailBase == 0) ||
            (frontPay == 0 && frontSocailBase == 0)
          ) {
            message.error("月薪系数和公积金基数有且仅有一个为0");
            return false;
          }
          if (afterPay > 1 || frontPay > 1) {
            message.error("月薪系数不能超过1");
            return false;
          }
          changeAccumulationFundJudgmentMaintain(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="600px"
        children={
          <div>
            <div style={{ alignItems: "center" }}>
              <div style={{ marginRight: "20px" }}>切分月薪:</div>
              <Input
                value={splitPay}
                onChange={(e) => {
                  let reg = new RegExp("^[0-9.]*$");
                  if (reg.test(e.target.value)) {
                    setSplitPay(e.target.value);
                  } else {
                    message.error("只能输入数字");
                  }
                }}
                style={{ width: "220px" }}
              />
            </div>
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "50% 1fr",
              }}
            >
              <div>
                <div style={{ marginRight: "20px" }}>前月薪判断系数:</div>
                <Input
                  value={frontPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>后月薪判断系数:</div>
                <Input
                  value={afterPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{`前公积金基数:`}</div>
                {/* <Select style={{width:'220px'}}  value={frontSocailBase} onChange={(value) => {
                                    setFrontSocailBase(value)
                                }}>
                                    {accumulationFundBaseList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select> */}
                <Input
                  value={frontSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{`后公积金基数:`}</div>
                {/* <Select style={{width:'220px'}}  value={afterSocailBase} onChange={(value) => {
                                    setAfterSocailBase(value)
                                }}>
                                    {accumulationFundBaseList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select> */}
                <Input
                  value={afterSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
            </div>
          </div>
        }
      />
      <AntdDragModal
        title={"切分纳税"}
        visible={splitTaxPayModel}
        onCancel={() => {
          setSplitTaxPayModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            old_min_taxable_income: oldData["最小纳税所得额"],
            old_max_taxable_income: oldData["最大纳税所得额"],
            old_tax_rate: oldData["税率"],
            old_quick_calculation_deduction: oldData["速算扣除"],
            old_start_year_month_day: oldData["开始年份月份日期"],
            old_end_year_month_day: oldData["结束年份月份日期"],
            change_flag: 2,
            taxable_income: splitPay,
            before_tax_rate_1: frontPay,
            after_tax_rate_1: afterPay,
            before_quick_calculation_deduction_1: frontSocailBase,
            after_quick_calculation_deduction_1: afterSocailBase,
          };
          if (
            splitPay === "" ||
            afterPay === "" ||
            frontPay === "" ||
            frontSocailBase === "" ||
            afterSocailBase === ""
          ) {
            message.warning("所有字段必填");
            return false;
          }
          if (afterPay === frontPay && afterSocailBase === frontSocailBase) {
            message.error("不能输入前后完全一致的税率与速算扣除");
            return false;
          }

          addPersonalIncomeTaxMaintain(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="600px"
        children={
          <div>
            <div style={{ alignItems: "center" }}>
              <div style={{ marginRight: "20px" }}>切分纳税所得额:</div>
              <Input
                value={splitPay}
                onChange={(e) => {
                  let reg = new RegExp("^[0-9.]*$");
                  if (reg.test(e.target.value)) {
                    setSplitPay(e.target.value);
                  } else {
                    message.error("只能输入数字");
                  }
                }}
                style={{ width: "220px" }}
              />
            </div>
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "50% 1fr",
              }}
            >
              <div>
                <div style={{ marginRight: "20px" }}>前税率:</div>
                <Input
                  value={frontPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>后税率:</div>
                <Input
                  value={afterPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{`前速算扣除:`}</div>
                {/* <Select style={{width:'220px'}}  value={frontSocailBase} onChange={(value) => {
                                    setFrontSocailBase(value)
                                }}>
                                    {accumulationFundBaseList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select> */}
                <Input
                  value={frontSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{`后速算扣除:`}</div>
                {/* <Select style={{width:'220px'}}  value={afterSocailBase} onChange={(value) => {
                                    setAfterSocailBase(value)
                                }}>
                                    {accumulationFundBaseList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select> */}
                <Input
                  value={afterSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
            </div>
          </div>
        }
      />
      <AntdDragModal
        title={updateModelTitle}
        visible={splitTimeModel}
        onCancel={() => {
          setSplitTimeModel(false);
        }}
        onOk={() => {
          if (
            splitTime === "" ||
            afterPay === "" ||
            frontPay === "" ||
            frontSocailBase === "" ||
            afterSocailBase === ""
          ) {
            message.warning("所有字段必填");
            return false;
          }
          if (afterPay === frontPay && afterSocailBase === frontSocailBase) {
            message.error("不能输入前后完全一致的系数与基数");
            return false;
          }
          if (
            (afterPay !== "0" && afterSocailBase !== "0") ||
            (frontPay !== "0" && frontSocailBase !== "0")
          ) {
            message.error("系数和基数必须有一个为0");
            return false;
          }
          if (
            (afterPay == "0" && afterSocailBase == "0") ||
            (frontPay == "0" && frontSocailBase == "0")
          ) {
            message.error("系数和基数有且仅有一个为0");
            return false;
          }
          if (afterPay > 1 || frontPay > 1) {
            message.error("系数不能超过1");
            return false;
          }
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            old_social_security_station: oldData["社保缴纳地"],
            old_min_amount_of_month: oldData["最低月薪"],
            old_max_amount_of_month: oldData["最高月薪"],
            old_rate_of_amount_of_month: oldData["月薪判断系数"],
            old_social_security_cardinal: oldData["社保基数"],
            old_start_year_month_day: oldData["开始年份月份日期"],
            old_end_year_month_day: oldData["结束年份月份日期"],
            change_flag: 3,
            new_year_month_date: splitTime
              ? splitTime.format(dateFormat)
              : splitTime,
            before_rate_of_amount_of_month_2: frontPay,
            after_rate_of_amount_of_month_2: afterPay,
            before_social_security_cardinal_2: frontSocailBase,
            after_social_security_cardinal_2: afterSocailBase,
          };
          if (
            updateModelTitle ==
            `切分社保基数判别${tableDict[calculation_type]}表`
          ) {
            changeSocialSecurity(dataProps);
          } else if (updateModelTitle == `切分社保缴纳费用参数表`) {
            dataProps.old_company = oldData["公司ID"];
            dataProps.old_social_security_subject = oldData["社保项目"];
            dataProps.before_rate_of_social_security = frontPay;
            dataProps.after_rate_of_social_security = afterPay;
            dataProps.before_amount_of_social_security = frontSocailBase;
            dataProps.after_amount_of_social_security = afterSocailBase;
            changeSocialSecuritySubject(dataProps);
          }
        }}
        cancelText="取消"
        okText="确认"
        children={
          <div>
            <div style={{ alignItems: "center" }}>
              <div style={{ marginRight: "20px" }}>切分时间:</div>
              {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
              <DatePicker
                locale={locale}
                style={{ width: "220px" }}
                // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                value={splitTime}
                onChange={(value, dateString) => {
                  setSplitTime(value);
                }}
              />
            </div>
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "50% 1fr",
              }}
            >
              <div>
                <div style={{ marginRight: "20px" }}>
                  {updateModelTitle ==
                  `切分社保基数判别${tableDict[calculation_type]}表`
                    ? "前月薪判断系数:"
                    : "前社保缴纳系数"}
                </div>
                <Input
                  value={frontPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>
                  {updateModelTitle ==
                  `切分社保基数判别${tableDict[calculation_type]}表`
                    ? "后月薪判断系数:"
                    : "后社保缴纳系数"}
                </div>
                <Input
                  value={afterPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>
                  {updateModelTitle ==
                  `切分社保基数判别${tableDict[calculation_type]}表`
                    ? "前社保基数:"
                    : "前社保缴纳数"}
                </div>
                {/* {updateModelTitle == `切分社保基数判别${tableDict[calculation_type]}表`?<Select style={{width:'220px'}}  value={frontSocailBase} onChange={(value) => {
                                setFrontSocailBase(value)
                            }}>
                                {socialBaseList.map(item => (
                                    <Option value={item.id}>{item.value}</Option>
                                ))}
                            </Select>:<Input value={frontSocailBase} onChange={(e)=>{
                                let reg = new RegExp("^[0-9.]*$");
                                if (reg.test(e.target.value)) {
                                    setFrontSocailBase(e.target.value)
                                } else {
                                    message.error("只能输入数字")
                                }
                            }} style={{width:'220px'}} />} */}
                <Input
                  value={frontSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>
                  {updateModelTitle ==
                  `切分社保基数判别${tableDict[calculation_type]}表`
                    ? "后社保基数:"
                    : "后社保缴纳数"}
                </div>
                <Input
                  value={afterSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
                {/* {updateModelTitle == `切分社保基数判别${tableDict[calculation_type]}表`?<Select style={{width:'220px'}}  value={afterSocailBase} onChange={(value) => {
                                setAfterSocailBase(value)
                            }}>
                                {socialBaseList.map(item => (
                                    <Option value={item.id}>{item.value}</Option>
                                ))}
                            </Select>:<Input value={afterSocailBase} onChange={(e)=>{
                                let reg = new RegExp("^[0-9.]*$");
                                if (reg.test(e.target.value)) {
                                    setAfterSocailBase(e.target.value)
                                } else {
                                    message.error("只能输入数字")
                                }
                            }} style={{width:'220px'}} />} */}
              </div>
            </div>
          </div>
        }
        width="600px"
      />
      <AntdDragModal
        title={updateModelTitle}
        visible={splitAccumulationFundTimeModel}
        onCancel={() => {
          setSplitAccumulationFundTimeModel(false);
        }}
        onOk={() => {
          if (
            splitTime === "" ||
            afterPay === "" ||
            frontPay === "" ||
            frontSocailBase === "" ||
            afterSocailBase === ""
          ) {
            message.warning("所有字段必填");
            return false;
          }
          if (afterPay === frontPay && afterSocailBase === frontSocailBase) {
            message.error("不能输入前后完全一致的月薪判断系数与公积金基数");
            return false;
          }
          if (
            (afterPay !== "0" && afterSocailBase !== "0") ||
            (frontPay !== "0" && frontSocailBase !== "0")
          ) {
            message.error("月薪判断系数和公积金基数必须有一个为0");
            return false;
          }
          if (
            (afterPay == "0" && afterSocailBase == "0") ||
            (frontPay == "0" && frontSocailBase == "0")
          ) {
            message.error("月薪系数和公积金基数有且仅有一个为0");
            return false;
          }
          if (afterPay > 1 || frontPay > 1) {
            message.error("月薪判断系数不能超过1");
            return false;
          }
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            old_social_security_station: oldData["社保缴纳地"],
            old_min_amount_of_month: oldData["最低月薪"],
            old_max_amount_of_month: oldData["最高月薪"],
            old_start_year_month_day: oldData["开始年份月份日期"],
            old_end_year_month_day: oldData["结束年份月份日期"],
            change_flag: 3,
            new_year_month_date: splitTime
              ? splitTime.format(dateFormat)
              : splitTime,
            before_rate_of_amount_of_month_2: frontPay,
            after_rate_of_amount_of_month_2: afterPay,
            before_accumulation_fund_cardinal_2: frontSocailBase,
            after_accumulation_fund_cardinal_2: afterSocailBase,
          };
          changeAccumulationFundJudgmentMaintain(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="600px"
        children={
          <div>
            <div style={{ alignItems: "center" }}>
              <div style={{ marginRight: "20px" }}>切分时间:</div>
              {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
              <DatePicker
                locale={locale}
                style={{ width: "220px" }}
                // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                value={splitTime}
                onChange={(value, dateString) => {
                  setSplitTime(value);
                }}
              />
            </div>
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "50% 1fr",
              }}
            >
              <div>
                <div style={{ marginRight: "20px" }}>{"前月薪判断系数:"}</div>
                <Input
                  value={frontPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{"后月薪判断系数:"}</div>
                <Input
                  value={afterPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{"前公积金基数"}</div>
                {/* <Select style={{width:'220px'}}  value={frontSocailBase} onChange={(value) => {
                                    setFrontSocailBase(value)
                                }}> 
                                    {accumulationFundBaseList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select> */}
                <Input
                  value={frontSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{"后公积金基数"}</div>
                {/* <Select style={{width:'220px'}}  value={afterSocailBase} onChange={(value) => {
                                    setAfterSocailBase(value)
                                }}>
                                    {accumulationFundBaseList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select> */}
                <Input
                  value={afterSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
            </div>
          </div>
        }
      />
      <AntdDragModal
        title={"切分个税参数表时间"}
        visible={splitTaxTimeModel}
        onCancel={() => {
          setSplitTaxTimeModel(false);
        }}
        onOk={() => {
          if (
            splitTime === "" ||
            afterPay === "" ||
            frontPay === "" ||
            frontSocailBase === "" ||
            afterSocailBase === ""
          ) {
            message.warning("所有字段必填");
            return false;
          }
          if (afterPay === frontPay && afterSocailBase === frontSocailBase) {
            message.error("不能输入前后完全一致的月薪判断系数与社保基数");
            return false;
          }
          if (
            (afterPay !== "0" && afterSocailBase !== "0") ||
            (frontPay !== "0" && frontSocailBase !== "0")
          ) {
            message.error("月薪判断系数和社保基数必须有一个为0");
            return false;
          }
          if (
            (afterPay == "0" && afterSocailBase == "0") ||
            (frontPay == "0" && frontSocailBase == "0")
          ) {
            message.error("月薪系数和社保基数有且仅有一个为0");
            return false;
          }
          if (afterPay > 1 || frontPay > 1) {
            message.error("月薪判断系数不能超过1");
            return false;
          }
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            old_min_taxable_income: oldData["最小纳税所得额"],
            old_max_taxable_income: oldData["最大纳税所得额"],
            old_tax_rate: oldData["税率"],
            old_quick_calculation_deduction: oldData["速算扣除"],
            old_start_year_month_day: oldData["开始年份月份日期"],
            old_end_year_month_day: oldData["结束年份月份日期"],
            change_flag: 3,
            new_year_month_date: splitTime
              ? splitTime.format(dateFormat)
              : splitTime,
            before_tax_rate_2: frontPay,
            after_tax_rate_2: afterPay,
            before_quick_calculation_deduction_2: frontSocailBase,
            after_quick_calculation_deduction_2: afterSocailBase,
          };
          addPersonalIncomeTaxMaintain(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="600px"
        children={
          <div>
            <div style={{ alignItems: "center" }}>
              <div style={{ marginRight: "20px" }}>切分时间:</div>
              {/*  #I69RTC NB20人资时间选择器去除时分秒 start*/}
              <DatePicker
                locale={locale}
                style={{ width: "220px" }}
                // {/*  #I69RTC NB20人资时间选择器去除时分秒 end*/}
                value={splitTime}
                onChange={(value, dateString) => {
                  setSplitTime(value);
                }}
              />
            </div>
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "50% 1fr",
              }}
            >
              <div>
                <div style={{ marginRight: "20px" }}>前税率:</div>
                <Input
                  value={frontPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>后税率:</div>
                <Input
                  value={afterPay}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterPay(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{`前速算扣除:`}</div>
                {/* <Select style={{width:'220px'}}  value={frontSocailBase} onChange={(value) => {
                                    setFrontSocailBase(value)
                                }}>
                                    {accumulationFundBaseList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select> */}
                <Input
                  value={frontSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setFrontSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
              <div>
                <div style={{ marginRight: "20px" }}>{`后速算扣除:`}</div>
                {/* <Select style={{width:'220px'}}  value={afterSocailBase} onChange={(value) => {
                                    setAfterSocailBase(value)
                                }}>
                                    {accumulationFundBaseList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select> */}
                <Input
                  value={afterSocailBase}
                  onChange={(e) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(e.target.value)) {
                      setAfterSocailBase(e.target.value);
                    } else {
                      message.error("只能输入数字");
                    }
                  }}
                  style={{ width: "220px" }}
                />
              </div>
            </div>
          </div>
        }
      />
      <AntdDragModal
        title="文件修改"
        visible={isUploadFile}
        centered={true}
        onCancel={() => {
          setIsUploadFile(false);
          setFileList([]);
          setErrorMsg("请上传文件");
        }}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                setIsUploadFile(false);
                setFileList([]);
                setErrorMsg("请上传文件");
              }}
            >
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setErrorMsg("上传中");
                setExportFlag(1);
                uploadFile();
              }}
              disabled={fileList.length == 0 ? true : false}
            >
              上传
            </Button>
          </div>
        }
        children={
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <a
                  href={`${servicePath.templateMemberPayInformation}?calculation_type=${calculation_type}`}
                >
                  <Button>
                    <DownloadOutlined /> 下载模版
                  </Button>
                </a>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <ExclamationCircleOutlined style={{color:"#a3986d",width:'30px'}}/> */}
              {exportFlag == 0 ? (
                <Progress type="circle" percent={0} />
              ) : exportFlag == 1 ? (
                <Spin size="large" />
              ) : exportFlag == 2 ? (
                <Progress type="circle" percent={100} />
              ) : (
                <Progress type="circle" percent={70} status="exception" />
              )}
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                fontSize: "15px",
              }}
            >
              <b>{errorMsg}</b>
            </div>
            <div
              style={{
                marginTop: "30px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <Upload
                  accept=".xlsx"
                  action={servicePath.allowUploadFile} //上传地址，填错/不填上传图片的时候都会报错
                  listType="picture" // 上传列表的内建样式
                  fileList={fileList} //已经上传的文件列表（受控）
                  onChange={fileHandleChange}
                  multiple={true}
                >
                  <Button>
                    <UploadOutlined /> 上传文件
                  </Button>
                </Upload>
                <span style={{ marginLeft: "10px" }}>仅支持扩展名:.xlsx</span>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
}

export default Management;
