import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, DatePicker, Modal, Upload, message, Collapse, Input, Form, Select, Table, Image, Drawer,Tooltip } from 'antd'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { CloudUploadOutlined, CloudDownloadOutlined, EditOutlined } from '@ant-design/icons';
import servicePath from '../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import axios from 'axios'
import '../../../static/css/index.css'
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select
function BackOrders(props) {

    const [form] = Form.useForm();

    const [form1] = Form.useForm()

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    }

    // 数据
    const [date, setDate] = useState([])

    // 采购单数据
    const [ cgdDate , setCgdDate ] = useState([])

    // 总数页数
    const [total, setTotal] = useState(0)

    // Id
    const [id, setId] = useState(0)

    //页面
    const [page, setPage] = useState(1)

    // 备注时订单号
    const [remarkOrderNumber, setRemarkOrderNumber] = useState('')

    // 订单号
    const [orderNumber, setOrderNumber] = useState('')

    // 平台
    const [platform, setPlatfrom] = useState('')

    // 店铺
    const [shop, setShop] = useState('')

    // 买家账号
    const [buyer, setBuyer] = useState('')

    // 开始时间
    const [startTime, setStartTime] = useState('')

    // 结束时间
    const [endTime, setEndTime] = useState('')

    // 款号
    const [styleNumber, setStyleNumber] = useState('')

    // 商品编号
    const [productId, setProductId] = useState('')

    // 商品名称
    const [productName, setProductName] = useState('')

    // 采购单号
    const [purchase, setPurchase] = useState('')

    // 商品状态
    const [status, setStatus] = useState('')

    // 品牌
    const [trademark, setTrademark] = useState('')

    // 是否采货
    const [collect, setCollect] = useState(0)

    // 业务人员备注
    const [businessRemark, setBusinessRemark] = useState('')

    // 客服备注
    const [servicerRemark, setServiceRemark] = useState('')

    // 仓库备注
    const [warehouseRemark, setWareHouseRemark] = useState('')

    // 打开备注
    const [openRemark, setOpenRemark] = useState(false)

    // 打开搜索框
    const [openSearch, setOpenSearch] = useState(false)

    // 打开采购单
    const [ openCgd , setOpenCgd ] = useState(false)

    // 上传缺货单
    const [uploadBackOrders, setUploadBackOrders] = useState(false)

    // 上传销退仓单
    const [uploadRefund, setUploadRefund] = useState(false)

    // 缺货单列表
    const [backOrdersList, setBackOrdersList] = useState([])

    // 销退仓列表
    const [refundList, setRefundList] = useState([])

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    //缺货订单更新时间
    const [ qhddgxsj , setQhddgxsj ] = useState('')

    // 销退仓单更新时间
    const [ xtcdgxsj , setXtcdgxsj] = useState('')

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 表格标题
    const columns = [
        {
            title: '图片',
            key: 'image',
            dataIndex: 'image',
            width: '100px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => (
                <Image width={50} src={'https://pic.bmcsoft.cn/imge_dir/item_info/xh_outlets/' + date[index]['kh'] + '.jpg'} />
            ),
        },
        {
            title: '订单号',
            key: 'ddh',
            dataIndex: 'ddh',
            width: '100px',
            align: 'center',
            fixed: 'left',
            render:(text,record,index)=>(
                <CopyToClipboard text={text} onCopy={() => message.success('复制成功')}>
                    <span>{text}</span>
                </CopyToClipboard>
            )
        },
        {
            title: '店铺',
            key: 'dp',
            dataIndex: 'dp',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                <CopyToClipboard text={text} onCopy={() => message.success('复制成功')}>
                    <span>{text}</span>
                </CopyToClipboard>
            )
        },
        {
            title: '付款日期',
            key: 'fkrq',
            dataIndex: 'fkrq',
            width: '120px',
            align: 'center'
        },
        {
            title: '款号',
            key: 'kh',
            dataIndex: 'kh',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                <CopyToClipboard text={text} onCopy={() => message.success('复制成功')}>
                    <span>{text}</span>
                </CopyToClipboard>
            )
        },
        {
            title: '商品编号',
            key: 'spbm',
            dataIndex: 'spbm',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                <CopyToClipboard text={text} onCopy={() => message.success('复制成功')}>
                    <span>{text}</span>
                </CopyToClipboard>
            )
        },
        {
            title: '颜色集规格',
            key: 'ysjgg',
            dataIndex: 'ysjgg',
            width: '150px',
            align: 'center'
        },
        {
            title: '数量',
            key: 'sl',
            dataIndex: 'sl',
            width: '100px',
            align: 'center'
        },
        {
            title: '剩余待采购数量',
            key: 'sycgsl',
            dataIndex: 'sycgsl',
            width: '100px',
            align: 'center'
        },
        {
            title: '商品状态',
            key: 'spzt',
            dataIndex: 'spzt',
            width: '100px',
            align: 'center'
        },
        {
            title: '查看采购单',
            key: 'orderNumber',
            dataIndex: 'orderNumber',
            width: '150px',
            align: 'center',
            render: (text, record, index) => (
                <Button
                    onClick={()=>getAllCgd(date[index]['id'])}
                    style={{ display: parseInt(date[index]['sycgsl']) < parseInt(date[index]['sl']) ? '' : 'none' }}
                >查看采购单</Button>
            ),
        },
        {
            title: '采购状态',
            key: 'cgzt',
            dataIndex: 'cgzt',
            width: '100px',
            align: 'center'
        },
        {
            title: '查看备注',
            key: 'remark',
            dataIndex: 'remark',
            width: '100px',
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => (
                <Button onClick={() => {
                    // 首先清空原来的数据
                    form1.resetFields();
                    setWareHouseRemark(date[index]['ckbz'])
                    setServiceRemark(date[index]['kfbz'])
                    setBusinessRemark(date[index]['spbz'])
                    setRemarkOrderNumber(date[index]['ddh']);
                    setId(date[index]['id'])
                    setOpenRemark(true)
                }}>查看备注</Button>
            ),
        },
    ]

    // 采购单标题
    const cgdColumns = [
        {
            title: '采购单号',
            key: 'cgdh',
            dataIndex: 'cgdh',
            width: '120px',
            align: 'center',
            render: (text, record, index) => (
                <a onClick={() => {
                    props.history.push({
                        'pathname': '/app/presell/purchase',
                        'state': {
                            'orderNumber': text
                        }
                    })
                }}>{text}</a>
            ),
        },
        {
            title: '采购单创建人',
            key: 'cgdcjr',
            dataIndex: 'cgdcjr',
            width: '120px',
            align: 'center'
        },
        {
            title: '采购单创建时间',
            key: 'cgdcjsj',
            dataIndex: 'cgdcjsj',
            width: '120px',
            align: 'center'
        },
        {
            title: '采购状态',
            key: 'cgzt',
            dataIndex: 'cgzt',
            width: '120px',
            align: 'center'
        },
    ]

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 初始化数据
    useEffect(()=>{
        getFileUploadTime()
    },[])

    // 获取文件上传时间
    const getFileUploadTime = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.getFileTime,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setQhddgxsj(res.data.data.qhddgxsj);
                    setXtcdgxsj(res.data.data.xtcdgxsj)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 页面切换的时候，请求接口
    useEffect(() => {
        getAllDate()
    }, [page])

    // 获取数据
    const getAllDate = () => {
        let dataProps = {
            "ddh": orderNumber,
            "pt": platform,
            "dp": shop,
            "mjzh": buyer,
            "kssj": startTime,
            "jssj": endTime,
            "kh": styleNumber,
            "spbm": productId,
            "spmc": productName,
            "cgdh": purchase,
            "spzt": status,
            "pp": trademark,
            "cg": collect,
            "page": page,
            "pageSize": "10",
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getOutOfStockOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }


    // 上传缺货单
    const uploadBackOrdersButton = (
        <Button type="primary">
            <CloudUploadOutlined /> 上传缺货单
        </Button>
    );

    // 上传销退仓单
    const uploadRefundButton = (
        <Button type="primary">
            <CloudUploadOutlined /> 上传销退仓单
        </Button>
    )

    // 上传商品基础信息
    const uploadBaseInfoButton = (
        <Button type="primary">
            <CloudUploadOutlined /> 上传商品基础信息
        </Button>
    )

    // 缺货订单对应的采购单
    const getAllCgd = (id)=>{
        let dataProps = {
            "id": id,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        console.log(dataProps);
        axios({
            method: 'POST',
            url: servicePath.getOutOfStockOrderPurchaseOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                   setCgdDate(res.data.data);
                   setOpenCgd(true)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }



    // 缺货单列表改变的时候出发
    const backOrdersHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx', 'xls', 'csv']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                // let flag = fileList[fileList.length-1].originFileObj['size'] / 1024 / 1024 < 50
                // // 在判断文件的大小
                // if (flag){
                //     setDocList(fileList)
                // }else{
                //     message.error('文件大小必须小于50M')
                // }
                setBackOrdersList(fileList)
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setBackOrdersList(fileList)
        }
    }


    // 销退仓单列表改变的时候出发
    const refundHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx', 'xls', 'csv']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                // let flag = fileList[fileList.length-1].originFileObj['size'] / 1024 / 1024 < 50
                // // 在判断文件的大小
                // if (flag){
                //     setDocList(fileList)
                // }else{
                //     message.error('文件大小必须小于50M')
                // }
                setRefundList(fileList)
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setRefundList(fileList)
        }
    }


    // 关闭销退仓单
    const closeUploadRefund = () => {
        message.success("取消上传销退仓单")
        setUploadRefund(false)
    }

    // 确认销退仓单
    const affirmUploadRefund = () => {
        setEffective(1)
        // 上传原始文件例子
        const formData = new FormData()
        refundList.forEach((item) => {
            formData.append('file', item['originFileObj']);
        })
        formData.append('roleId', cookie.load('role'));
        formData.append('shopId', cookie.load('shopList'));
        axios({
            method: 'POST',
            url: servicePath.uploadRefundOrder,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    message.success("成功上传销退仓单")
                    setRefundList([])
                    getAllDate()
                    getFileUploadTime()
                    setUploadRefund(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }


    // 关闭上传缺货单
    const closeUploadBackOrders = () => {
        message.success("取消上传缺货订单")
        setUploadBackOrders(false)
    }

    // 确认上传缺货单
    const affirmUploadBackOrders = () => {
        setEffective(1)
        // 上传原始文件例子
        const formData = new FormData()
        backOrdersList.forEach((item) => {
            formData.append('file', item['originFileObj']);
        })
        formData.append('roleId', cookie.load('role'));
        formData.append('shopId', cookie.load('shopList'));
        axios({
            method: 'POST',
            url: servicePath.uploadOutOfStockOrder,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    message.success("成功上传缺货订单")
                    setBackOrdersList([])
                    getAllDate()
                    getFileUploadTime()
                    setUploadBackOrders(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 确认修改备注
    const affirmUpdateRemark = () => {
        let dataProps = {
            "id": id,
            "spbz": businessRemark,
            "kfbz": servicerRemark,
            "ckbz": warehouseRemark,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.updateOutOfStockOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    message.success("备注修改成功")
                    getAllDate()
                    setRemarkOrderNumber('')
                    setBusinessRemark('')
                    setServiceRemark('')
                    setWareHouseRemark('')
                    setOpenRemark(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )

    }

    // 取消修改备注
    const closeUpdateRemark = () => {
        message.success("取消修改备注")
        setRemarkOrderNumber('')
        setBusinessRemark('')
        setServiceRemark('')
        setWareHouseRemark('')
        setOpenRemark(false)
    }

    // 查询条件
    const search = () => {
        if (page == 1) {
            getAllDate()
        } else {
            setPage(1)
        }
        setOpenSearch(false)
    }

    // 重置条件
    const reset = () => {
        form.resetFields()
        setOrderNumber('')
        setPlatfrom('')
        setShop('')
        setBuyer('')
        setStartTime('')
        setEndTime('')
        setStyleNumber('')
        setProductId('')
        setProductName('')
        setStatus('')
        setTrademark('')
        setPage(1)
        setCollect(true)
        setOpenSearch(false)
        let dataProps = {
            "ddh": "",
            "pt": "",
            "dp": "",
            "mjzh": "",
            "kssj": "",
            "jssj": "",
            "kh": "",
            "spbm": "",
            "spmc": "",
            "cgdh": "",
            "spzt": "",
            "pp": "",
            "cg": "",
            "page": "1",
            "pageSize": "10",
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getOutOfStockOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 关闭搜索框
    const onSearchClose = () => {
        setOpenSearch(false)
    }

    // 关闭查看采购单
    const closeOpenCgd = ()=>{
        setOpenCgd(false)
    }

    return (
        <div >
            {/* 导入按钮区域 */}
            <div style={{ marginTop: size.height * 0.02, marginBottom: size.height * 0.02, marginLeft: size.width * 0.02}}>
                <Tooltip placement="top" title={`上传更新时间:`+qhddgxsj}>
                    <Button icon={<CloudUploadOutlined />} type="primary" style={{ marginLeft: '10px', marginRight: '50px' }} onClick={() => setUploadBackOrders(true)}>导入缺货单</Button>
                </Tooltip>
                <Tooltip placement="top" title={`上传更新时间:`+xtcdgxsj}>
                    <Button icon={<CloudUploadOutlined />} type="primary" style={{ marginRight: '50px' }} onClick={() => setUploadRefund(true)}>导入销退仓单</Button>
                </Tooltip>
                <a href="https://pic.bmcsoft.cn/imge_dir/work/114/缺货单文件模板.xlsx" style={{ marginRight: '50px' }}><Button icon={<CloudDownloadOutlined />} >下载缺货单模版</Button></a>
                <a href="https://pic.bmcsoft.cn/imge_dir/work/114/销退仓单文件模板.xlsx"><Button icon={<CloudDownloadOutlined />} >下载销退仓单文件模板</Button></a>
                <Button onClick={() => setOpenSearch(true)} style={{ marginLeft: '50px' }}>灵活查询</Button>
            </div>
            <Drawer
                title="查询条件"
                placement="right"
                closable={false}
                onClose={onSearchClose}
                visible={openSearch}
                width={500}
            >
                <Form
                    form={form}
                    {...layout}
                    labelAlign='left'
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="orderNumber"
                                label="订单号"
                            >
                                <Input
                                    id='orderNumber'
                                    placeholder="请输入订单号"
                                    value={orderNumber}
                                    onChange={(e) => {
                                        setOrderNumber(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="platform"
                                label="平台"
                            >
                                <Select
                                    placeholder="请选择平台"
                                    onChange={(value) => { setPlatfrom(value); }}
                                >
                                    <Option value="">请选择平台</Option>
                                    <Option value="京东">京东</Option>
                                    <Option value="抖音">抖音</Option>
                                    <Option value="淘宝天猫">淘宝天猫</Option>
                                    <Option value="拼多多">拼多多</Option>
                                    <Option value="头条放心购">头条放心购</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="shop"
                                label="店铺"
                            >
                                <Input
                                    id='shop'
                                    value={shop}
                                    placeholder="请输入店铺"
                                    onChange={(e) => {
                                        setShop(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="buyer"
                                label="买家账号"
                            >
                                <Input
                                    id='buyer'
                                    value={buyer}
                                    placeholder="请输入买家账号"
                                    onChange={(e) => {
                                        setBuyer(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="styleNumber"
                                label="款号"
                            >
                                <Input
                                    id='styleNumber'
                                    value={styleNumber}
                                    placeholder="请输入款号"
                                    onChange={(e) => {
                                        setStyleNumber(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="productId"
                                label="商品编号"
                            >
                                <Input
                                    id='productId'
                                    value={productId}
                                    placeholder="请输入商品编号"
                                    onChange={(e) => {
                                        setProductId(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="productName"
                                label="商品名称"
                            >
                                <Input
                                    id='productName'
                                    value={productName}
                                    placeholder="请输入商品名称"
                                    onChange={(e) => {
                                        setProductName(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="status"
                                label="状态"
                            >
                                <Select
                                    placeholder="请选择状态"
                                    onChange={(value) => { setStatus(value); }}
                                >
                                    <Option value="">请选择状态</Option>
                                    <Option value="异常">异常</Option>
                                    <Option value="非异常">非异常</Option>
                                    <Option value="全部">全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="trademark"
                                label="品牌"
                            >
                                <Select
                                    placeholder="请选择品牌"
                                    onChange={(value) => { setTrademark(value); }}
                                >
                                    <Option value="">请选择品牌</Option>
                                    <Option value="恩曼琳">恩曼琳</Option>
                                    <Option value="卡洛琳">卡洛琳</Option>
                                    <Option value="巨式">巨式</Option>
                                    <Option value="卓雅">卓雅</Option>
                                    <Option value="卓雅周末">卓雅周末</Option>
                                    <Option value="其他品牌">其他品牌</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <Form.Item
                                name="time"
                                label="付款日期"
                            >
                                <RangePicker
                                    showTime
                                    locale={locale}
                                    style={{ width: 375 }}
                                    format="YYYY-MM-DD HH:mm"
                                    onChange={(dates, dateStrings) => {
                                        setStartTime(dateStrings[0]);
                                        setEndTime(dateStrings[1]);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button type="primary" style={{ marginRight: "10px", marginLeft: '150px' }} onClick={() => search()}>查询</Button>
                            <Button type="primary" danger onClick={() => reset()}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <div style={{marginLeft: size.width * 0.02 }}>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                    scroll={{ x:'70vw', y: `${size.width>1500?'70vh':'63vh'}` }}
                />
            </div>
            <Modal
                title="上传缺货单"
                centered={true}
                visible={uploadBackOrders}
                maskClosable={false}
                onOk={affirmUploadBackOrders}
                onCancel={closeUploadBackOrders}
                okText="确认上传"
                cancelText="取消上传"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Upload
                    action={servicePath.image}                          //上传地址，填错/不填上传图片的时候都会报错
                    listType="picture"                             // 上传列表的内建样式
                    fileList={backOrdersList}                                 //已经上传的文件列表（受控）
                    onChange={backOrdersHandleChange}                             //上传文件改变时的状态
                    accept=".xlsx,.xls,.csv"                                    //设置文件上传类型
                >
                    {backOrdersList.length >= 1 ? null : uploadBackOrdersButton}
                </Upload>
            </Modal>


            <Modal
                title="上传销退仓单"
                centered={true}
                visible={uploadRefund}
                maskClosable={false}
                onOk={affirmUploadRefund}
                onCancel={closeUploadRefund}
                okText="确认上传"
                cancelText="取消上传"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Upload
                    action={servicePath.image}                          //上传地址，填错/不填上传图片的时候都会报错
                    listType="picture"                                  // 上传列表的内建样式
                    fileList={refundList}                                 //已经上传的文件列表（受控）
                    onChange={refundHandleChange}                             //上传文件改变时的状态
                    accept=".xlsx,.xls,.csv"                                    //设置文件上传类型
                >
                    {refundList.length >= 1 ? null : uploadRefundButton}
                </Upload>
            </Modal>
            {/* 查询备注信息 */}
            <Modal
                title="查看备注信息"
                centered={true}
                visible={openRemark}
                maskClosable={false}
                onOk={affirmUpdateRemark}
                onCancel={closeUpdateRemark}
                okText="确认修改"
                cancelText="取消修改"
            >
                <Form
                    form={form1}
                    {...layout1}
                    labelAlign='left'
                >
                    <Form.Item
                        name="orderNumber"
                        label="订单号"
                    >
                        <Input
                            id='orderNumber'
                            disabled
                            defaultValue={remarkOrderNumber}
                        />
                    </Form.Item>
                    <Form.Item
                        name="business"
                        label="业务人员备注"
                    >
                        <Input.TextArea
                            id='business'
                            disabled={cookie.load('roleName').indexOf('商品') != -1 ? false : true}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            allowClear
                            defaultValue={businessRemark}
                            value={businessRemark}
                            onChange={(e) => {
                                setBusinessRemark(e.target.value)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="servicer"
                        label="客服备注"
                    >
                        <Input.TextArea
                            id='servicer'
                            disabled={cookie.load('roleName').indexOf('客服') != -1 ? false : true}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            allowClear
                            defaultValue={servicerRemark}
                            value={servicerRemark}
                            onChange={(e) => {
                                setServiceRemark(e.target.value)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="warehouse"
                        label="仓库备注"
                    >
                        <Input.TextArea
                            id='warehouse'
                            disabled={cookie.load('roleName').indexOf('仓库') != -1 ? false : true}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            allowClear
                            defaultValue={warehouseRemark}
                            value={warehouseRemark}
                            onChange={(e) => {
                                setWareHouseRemark(e.target.value)
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {/* 查看采购单信息 */}
            <Modal
                title="查看采购单信息"
                centered={true}
                visible={openCgd}
                footer={[]}
                onCancel={closeOpenCgd}
                width={size.width*0.8}
            >
                 <Table
                    //表格列的配置描述
                    columns={cgdColumns}
                    //数据数组
                    dataSource={cgdDate}
                    pagination={false}
                    // scroll={{ y: size.height*0.6 }}
                    //表格行 key 的取值
                    rowKey={'id'}
                />
            </Modal>
        </div>
    )

}

export default BackOrders