import React, { useEffect, useState } from 'react';
import { Table, Typography, Button, Checkbox, Tabs, Tag, Collapse, Tooltip, Modal, Input, Spin, message, Drawer, Row, Col, Popover, Popconfirm } from 'antd'
import { SearchOutlined, DeleteOutlined, RedoOutlined, EyeInvisibleOutlined, EyeOutlined, DownOutlined, RightOutlined, GroupOutlined, CloseCircleOutlined, PlusCircleOutlined, InboxOutlined, CloseOutlined, createFromIconfontCN } from '@ant-design/icons';
import moment from 'moment'
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios';
import servicePath from '../../../config/apiUrl';
import style from './index.module.css'
import { DraggableArea } from 'react-draggable-tags';
const { Title, Text, Link } = Typography
const { TabPane } = Tabs;
const { CheckableTag } = Tag;
const { Panel } = Collapse;


function Index(props) {
    const IconFont = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_2817613_yizb8f2tkvi.js',
    });
    // 定义筛选功能结束
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    function removeByVal(arrylist, val) {
        for (var i = 0; i < arrylist.length; i++) {
            if (arrylist[i] == val) {
                arrylist.splice(i, 1);
                break;
            }
        }
    }
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };

    // 对象是否一样
    Object.prototype.vequal = function (obj) {
        var props1 = Object.getOwnPropertyNames(this);
        var props2 = Object.getOwnPropertyNames(obj);
        if (props1.length != props2.length) {
            return false;
        }
        for (var i = 0, max = props1.length; i < max; i++) {
            var propName = props1[i];
            if (this[propName] !== obj[propName]) {
                return false;
            }
        }
        return true;
    }
    // 唯一行
    Array.prototype.unique = function () {
        //必须先进行排序
        this.sort();
        var newArr = new Array();
        //放入第一个数组头数据。
        var temp = this[0];
        newArr.push(temp);
        for (var i = 1; i < this.length; i++) {
            if (temp != this[i]) { //判断数组中，是否拥有该值。
                temp = this[i]; //放置到数组里面。
                newArr.push(this[i]);
            }
        }
        return newArr;
    }
    //去重
    function listRemoveRepeat(c) {
        var result = [];
        for (var i = 0; i < c.length; i++) {
            var flag = true;
            var temp = c[i];
            for (var j = i + 1; j < c.length; j++) {
                if (temp === c[j]) {
                    flag = false;
                    break;
                }
            }
            if (flag) {
                result.push(temp);
            }
        }
        return result;
    }
    //交集
    function listIntersection(a, b) {
        var result = [];
        for (var i = 0; i < b.length; i++) {
            var temp = b[i];
            for (var j = 0; j < a.length; j++) {
                if (temp === a[j]) {
                    result.push(temp);
                    break;
                }
            }
        }
        return result;
    }
    //差集:所有属于a且不属于b的元素组成的集合
    function listDifference(a, b) {
        var clone = a.slice(0);
        for (var i = 0; i < b.length; i++) {
            var temp = b[i];
            for (var j = 0; j < clone.length; j++) {
                if (temp === clone[j]) {
                    clone.splice(j, 1);
                }
            }
        }
        return listRemoveRepeat(clone);
    }
    //并集
    function listUnion(a, b) {
        return listRemoveRepeat(a.concat(b));
    }
    // 维度数据
    const [atomicData, setAtomicData] = useState([
        {
            'short_chain': '',
            'value': [],
            'atomicDimension': []
        },
        {
            'short_chain': '',
            'value': [],
            'atomicDimension': []
        }
    ])
     // 分组数据的呈现方式
     const [groupPresentWays, setGroupPresentWays] = useState([
        {
            'name':'行交差',
            'key':"intersection"
        },{
            'name':'列交差',
            'key':"column"
        },{
            'name':'差异表',
            'key':"different"
        },{
            'name':'柱状图',
            'key':'histogram'
        },{
            'name':'旭日图',
            'key':'sankey'
        }]
    )
    // 选择维度行数
    const [selectAtomicIndex, setSelectAtomicIndex] = useState(-1)
    const [selectedAtomicData, setSelectedAtomicData] = useState([])
    // 相同索引
    const [sameIndex, setSameIndex] = useState(0)
    // 对比目的
    const [comparsionPurpose, setComparsionPurpose] = useState('')
    // 加载数据配置
    const [isSpinning, setIsSpinning] = useState(false)
    // 标签页集合
    const [tabList, setTabList] = useState(['明细数据模版', '明细数据缓存', '分组数据模版', '分组数据缓存', '自定义模版'])
    // 当前的标签
    const [activeTab, setActiveTab] = useState('明细数据模版')
    // 明细数据模版数据
    const [detailDataTemplateData, setDetailDataTemplateData] = useState([])
    // 明细数据页面
    const [detailDataTemplatePage, setDetailDataTemplatePage] = useState(1)
    // 明细数据总数
    const [detailDataTemplateTotle, setDetailDataTemplateTotle] = useState(100)
    // 明细数据关键词
    const [detailDataTemplateWordKey, setDetailDataTemplateWordKey] = useState('')
    // 查询明细数据指标
    const [detailDataTemplateSearch, setDetailDataTemplateSearch] = useState(false)
    // 是否新建明细数据模版
    const [isCreateDetailDataTemplate, setIsCreateDetailDataTemplate] = useState(false)
    // 明细模版名字
    const [detailDataTemplateName, setDetailDataTemplateName] = useState('')
    // Tab的键
    const [detailDataTemplateTabKey, setDetailDataTemplateTabKey] = useState("指标选择")
    // 是否需要周期性生产
    const [periodicProduction, setPeriodicProduction] = useState(0)
    // const [chosedList, setChosedList] = useState([])
    // 明细数据模版表头
    const [detailDataTemplateColumns] = useState([
        {
            title: '模版名字',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '是否周期生成',
            dataIndex: 'is_cycle',
            key: 'is_cycle',
            align: "center",
            render: (text, record, index) => (
                <span>{text == 1 ? '是' : '否'}</span>
            )
        },
        {
            title: '用途',
            dataIndex: 'purpose',
            key: 'purpose',
            align: "center",
        },
        {
            title: '生产时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: "center",
            render: (text, record, index) => (
                <span>{moment(text).format('YYYY-MM-DD')}</span>
            )
        },
        {
            title: '状态',
            dataIndex: 'is_valid',
            key: 'is_valid',
            align: "center",
            render: (text, record, index) => (
                <span>{text == 1 ? '正常' : '禁用'}</span>
            )
        },
        {
            title: '是否公开',
            dataIndex: 'is_open',
            key: 'is_open',
            align: "center",
            render: (text, record, index) => (
                <span>{text == 1 ? '公开' : '不公开'}</span>
            )
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            align: "center",
            render: (text, record, index) => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Tooltip title="查看模版细节">
                        <SearchOutlined onClick={() => {
                            getDetailDataTemplateDetails(record.id)
                        }} />
                    </Tooltip>
                    <Tooltip title="进行分组统计">
                        <GroupOutlined onClick={() => {
                            let { id } = record
                            props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/groupStatistics`, 'state': { 'templateId': id, 'start_time': '', 'end_time': '' } })
                        }} />
                    </Tooltip>
                    {
                        record.is_valid == '1' ? <Tooltip title="禁用模版">
                            <Popconfirm title="是否确认删除？" okText="确定" cancelText="取消" onConfirm={() => {
                                let { id } = record
                                let dataProps = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "template_id": id
                                }
                                NbAxios(dataProps, "POST", servicePath.deleteDetailDataTemplate).then(res => {
                                    if (res.success) {
                                        getDetailDataTemplate()
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                })
                            }}>
                                <DeleteOutlined />
                            </Popconfirm>
                        </Tooltip>
                            : <Tooltip title="启用模版">
                                <Popconfirm title="是否确认恢复？" okText="确定" cancelText="取消" onConfirm={() => {
                                    let { id } = record
                                    let dataProps = {
                                        "roleId": cookie.load('role'),
                                        "shopList": cookie.load('shopList'),
                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                        "template_id": id
                                    }
                                    NbAxios(dataProps, "POST", servicePath.recoveryDetailDataTemplate).then(res => {
                                        if (res.success) {
                                            getDetailDataTemplate()
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    })
                                }}>
                                    <RedoOutlined />
                                </Popconfirm>
                            </Tooltip>
                    }
                    {
                        record.is_open == 1 ? <Tooltip title="不公开模版">
                            <Popconfirm title="是否不公开模版?" okText="确定" cancelText="取消" onConfirm={() => {
                                let { id } = record
                                let dataProps = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "template_id": id
                                }
                                NbAxios(dataProps, "POST", servicePath.cancelOpenDetailDataTemplate).then(res => {
                                    if (res.success) {
                                        getDetailDataTemplate()
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                })
                            }}>
                                <EyeInvisibleOutlined />
                            </Popconfirm>
                        </Tooltip>
                            : <Tooltip title="公开模版">
                                <Popconfirm title="是否公开模版?" okText="确定" cancelText="取消" onConfirm={() => {
                                    let { id } = record
                                    let dataProps = {
                                        "roleId": cookie.load('role'),
                                        "shopList": cookie.load('shopList'),
                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                        "template_id": id
                                    }
                                    NbAxios(dataProps, "POST", servicePath.openDetailDataTemplate).then(res => {
                                        if (res.success) {
                                            getDetailDataTemplate()
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    })
                                }}>
                                    <EyeOutlined />
                                </Popconfirm>
                            </Tooltip>
                    }
                </div>
            )
        },
    ])
    //设置每个页面显示个数
    const detailDataTemplatePaginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: detailDataTemplatePage,
        total: detailDataTemplateTotle,
        onChange: page => {
            //改变页码
            setDetailDataTemplatePage(page)
        }
    }
    // 点击明细数据
    const [detailDataTemplateExpandedRowKeys, setDetailDataTemplateExpandedRowKeys] = useState([])
    // 缓存表头
    const [detailCacheDataColumns] = useState([
        {
            title: '缓存名字',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '生产时间',
            dataIndex: 'start_time',
            key: 'start_time',
            align: "center",
            render: (text, record, index) => (
                <span>{moment(text).format('YYYY-MM-DD')}</span>
            )
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            align: "center",
            render: (text, record, index) => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Tooltip title="查看缓存数据">
                        <SearchOutlined onClick={() => {
                            let { id, template_id } = record
                            props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/commodityTable`, 'state': { 'cacheId': id, "templateId": template_id } })
                        }} />
                    </Tooltip>
                    <Tooltip title="进行分组统计">
                        <GroupOutlined onClick={() => {
                            let { template_id, start_time, end_time } = record
                            props.history.push({
                                'pathname': `/app/${props.history.location.pathname.split('/')[2]}/groupStatistics`,
                                'state': { 'templateId': template_id, 'start_time': start_time, 'end_time': end_time }
                            })
                        }} />
                    </Tooltip>
                </div>
            )
        }
    ])
    // 缓存数据
    const [detailCacheData, setDetailCacheData] = useState([])
    // 进行对比
    const [isCompared, setIsCompare] = useState(false)
    // 进行分组对比
    const [isGroupCompared, setIsGroupCompared] = useState(false)
    // 选择的id
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRow, setSelectedRow] = useState([])
    // 明细数据对比形式集合
    const [detailDataCompareList, setDetailDataCompareList] = useState(['列交差', '行交差', '差异表'])
    // 选择的呈现方式
    const [selectedPresentWays, setSelectedPresentWays] = useState([])
    // Tab的键
    const [tabSituationKey, setTabSituationKey] = useState("商品基础信息")
    // 已选指标
    const [selectedIndicator, setSelectedIndicator] = useState([{ id: '304', name: '图片' }, { id: '308', name: 'ID' }, { id: '309', name: '款号' }])
    // 固定指标
    const [fixedIndicator] = useState([{ id: '304', name: '图片' }, { id: '308', name: 'ID' }, { id: '309', name: '款号' }])
    const [titleIndex, setTitleIndex] = useState(-1)
    const [rowIndex, setRowIndex] = useState(-1)
    const [chosedList, setChosedList] = useState([])
    const [chooseIndex, setChooseIndex] = useState([])
    // 生意参谋指标
    const [allBusinessStaffDate, setAllBusinessStaffDate] = useState([
        {
            "option": [
                {
                    "id": "",
                    "name": "下单转换率",
                    "disable": 1
                },
                {
                    "id": 348,
                    "name": "下单转换率_近7天",
                    "disable": 1
                },
                {
                    "id": 393,
                    "name": "下单转换率_近14天",
                    "disable": 1
                },
                {
                    "id": 438,
                    "name": "下单转换率_近30天",
                    "disable": 1
                },
                {
                    "id": 483,
                    "name": "下单转换率_日",
                    "disable": 1
                },
                {
                    "id": 528,
                    "name": "下单转换率_周",
                    "disable": 1
                },
                {
                    "id": 573,
                    "name": "下单转换率_月",
                    "disable": 1
                },
                {
                    "id": 618,
                    "name": "下单转换率_年",
                    "disable": 1
                },
                {
                    "id": 663,
                    "name": "下单转换率_产品全周期",
                    "disable": 1
                }
            ]
        }
    ])
    const [chooseBusinessStaffDate, setChooseBusinessStaffDate] = useState([
        {
            "option": [
                {
                    "id": "",
                    "name": "下单转换率",
                    "disable": 1
                },
                {
                    "id": 348,
                    "name": "下单转换率_近7天",
                    "disable": 1
                },
                {
                    "id": 393,
                    "name": "下单转换率_近14天",
                    "disable": 1
                },
                {
                    "id": 438,
                    "name": "下单转换率_近30天",
                    "disable": 1
                },
                {
                    "id": 483,
                    "name": "下单转换率_日",
                    "disable": 1
                },
                {
                    "id": 528,
                    "name": "下单转换率_周",
                    "disable": 1
                },
                {
                    "id": 573,
                    "name": "下单转换率_月",
                    "disable": 1
                },
                {
                    "id": 618,
                    "name": "下单转换率_年",
                    "disable": 1
                },
                {
                    "id": 663,
                    "name": "下单转换率_产品全周期",
                    "disable": 1
                }
            ]
        }
    ])
    // 选择生意参谋关键字
    const [businessStaffKey, setBusinessStaffKey] = useState('')
    // 模版指标
    const [templateIndicatorList, setTemplateIndicatorList] = useState([])
    // 指标选择
    const [searchItemDate, setSearchItemDate] = useState([
        {
            "situation": "商品基础信息",
            "date": []
        },
        {
            "situation": "生意参谋全量数据",
            "head": [
                "指标",
                "近7天",
                "近14天",
                "近30天",
                "日",
                "周",
                "月",
                "年",
                "产品全周期"
            ],
            "date": [
                {
                    "option": [
                        {
                            "id": "",
                            "name": "下单转换率",
                            "disable": 1
                        },
                        {
                            "id": 348,
                            "name": "下单转换率_近7天",
                            "disable": 1
                        },
                        {
                            "id": 393,
                            "name": "下单转换率_近14天",
                            "disable": 1
                        },
                        {
                            "id": 438,
                            "name": "下单转换率_近30天",
                            "disable": 1
                        },
                        {
                            "id": 483,
                            "name": "下单转换率_日",
                            "disable": 1
                        },
                        {
                            "id": 528,
                            "name": "下单转换率_周",
                            "disable": 1
                        },
                        {
                            "id": 573,
                            "name": "下单转换率_月",
                            "disable": 1
                        },
                        {
                            "id": 618,
                            "name": "下单转换率_年",
                            "disable": 1
                        },
                        {
                            "id": 663,
                            "name": "下单转换率_产品全周期",
                            "disable": 1
                        }
                    ]
                }
            ]
        }
    ])
    // 是否生产数据提取模版
    const [isGenerateDataTemplate, setIsGenerateDataTemplate] = useState(false)
    const [isSearchGenerateDataTemplate, setIsSearchGenerateDataTemplate] = useState(false)
    // 横着气泡
    const horizontallContent = (
        <div style={{ display: "flex" }}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                // 获取竖着可选的id
                let idObj = []
                let itemObj = []
                data.map(item => {
                    if (item['option'][chooseIndex].disable) {
                        idObj.push(item['option'][chooseIndex].id)
                        itemObj.push(item['option'][chooseIndex])
                    }
                })
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.push(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.push(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>全选</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                // 获取竖着可选的id
                let idObj = []
                let itemObj = []
                data.map(item => {
                    if (item['option'][chooseIndex].disable) {
                        idObj.push(item['option'][chooseIndex].id)
                        itemObj.push(item['option'][chooseIndex])
                    }
                })
                let chosedArr = [...chosedList]
                let idIntersectArr = listIntersection(chosedArr, idObj)
                let idUnionArr = listUnion(chosedArr, idObj)
                let idMinusArr = listDifference(idUnionArr, idIntersectArr)
                setChosedList(idMinusArr)
                let itemArr = [...selectedIndicator]
                let itemIntersectArr = listIntersection(itemArr, itemObj)
                let itemUnionArr = listUnion(itemArr, itemObj)
                let itemMinusArr = listDifference(itemUnionArr, itemIntersectArr)
                setSelectedIndicator(itemMinusArr)
                //   let arr = [...chosedList]
                //   obj.map(item=>{
                //     if(chosedList.indexOf(item) != -1){
                //         // 在选中的集合中不存在,添加新的数据
                //         arr.remove(item)
                //     }
                //   })
                //   setChosedList(arr)
            }}>反选</Button>
            <Button type="primary" danger onClick={() => {
                let data = chooseBusinessStaffDate
                // 获取竖着可选的id
                let idObj = []
                let itemObj = []
                data.map(item => {
                    if (item['option'][chooseIndex].disable) {
                        idObj.push(item['option'][chooseIndex].id)
                        itemObj.push(item['option'][chooseIndex])
                    }
                })
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.remove(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.remove(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>清空</Button>
        </div>
    )

    // 竖着气泡
    const verticalContent = (
        <div style={{ display: "flex" }}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                let idObj = []
                let itemObj = []
                data[rowIndex]['option'].map(item => {
                    if (item.disable) {
                        idObj.push(item.id)
                        itemObj.push(item)
                    }
                })
                // 删除第一个
                idObj.shift()
                itemObj.shift()
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.push(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.push(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>全选</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                let idObj = []
                let itemObj = []
                data[rowIndex]['option'].map(item => {
                    if (item.disable) {
                        idObj.push(item.id)
                        itemObj.push(item)
                    }
                })
                // 删除第一个
                idObj.shift()
                itemObj.shift()
                let chosedArr = [...chosedList]
                let idIntersectArr = listIntersection(chosedArr, idObj)
                let idUnionArr = listUnion(chosedArr, idObj)
                let idMinusArr = listDifference(idUnionArr, idIntersectArr)
                setChosedList(idMinusArr)
                let itemArr = [...selectedIndicator]
                let itemIntersectArr = listIntersection(itemArr, itemObj)
                let itemUnionArr = listUnion(itemArr, itemObj)
                let itemMinusArr = listDifference(itemUnionArr, itemIntersectArr)
                setSelectedIndicator(itemMinusArr)
                //   let arr = [...chosedList]
                //   obj.map(item=>{
                //     if(chosedList.indexOf(item) != -1){
                //         // 在选中的集合中不存在,添加新的数据
                //         arr.remove(item)
                //     }
                //   })
                //   setChosedList(arr)
            }}>反选</Button>
            <Button type="primary" danger onClick={() => {
                let data = chooseBusinessStaffDate
                let idObj = []
                let itemObj = []
                data[rowIndex]['option'].map(item => {
                    if (item.disable) {
                        idObj.push(item.id)
                        itemObj.push(item)
                    }
                })
                // 删除第一个
                idObj.shift()
                itemObj.shift()
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.remove(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.remove(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>清空</Button>
        </div>
    )

    // 筛选功能
    const searchContent = (
        <div style={{ display: 'flex' }}>
            <Input width={400} value={businessStaffKey} style={{ marginRight: 10 }} onChange={(e) => {
                setBusinessStaffKey(e.target.value)
            }} />
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let obj = []
                allBusinessStaffDate.map(item => {
                    if (item.option[0]['name'].indexOf(businessStaffKey) != -1) {
                        obj.push(item)
                    }
                })
                setChooseBusinessStaffDate(obj)
            }}>查询</Button>
            <Button type="primary" danger onClick={() => {
                setBusinessStaffKey('')
                let obj = [...allBusinessStaffDate]
                setChooseBusinessStaffDate(obj)
            }}>重置</Button>
        </div>
    )
    // 分组相关数据
    // 是否编辑分组逻辑
    const [isEditGroup, setIsEditGroup] = useState(false)
    // 拖拽类型
    const [dragType, setDragType] = useState('')
    // 拖拽数据
    const [dragDate, setDragDate] = useState({})
    // 分组标签
    const [groupTabKey, setGroupTabKey] = useState('分组逻辑')
    // 选择的指标
    const [groupSelectedIndicator, setGroupSelectedIndicator] = useState([])
    const [oldGroupSelectedIndicator, setOldGroupSelectedIndicator] = useState([])
    // 维度集合
    const [groupDimensionList, setGroupDimensionList] = useState([])
    // 指标集合
    const [groupIndicatorList, setGroupIndicatorList] = useState([])
    // 分组维度
    const [groupingDimensions, setGroupingDimensions] = useState([])
    const [oldGroupingDimensions, setOldGroupingDimensions] = useState([])
    // 分组数据模版数据
    const [groupDataTemplateData, setGroupDataTemplateData] = useState([])
    // 分组数据模版页面
    const [groupDataTemplatePage, setGroupDataTemplatePage] = useState(1)
    // 分组数据模版总数
    const [groupDataTemplateTotle, setGroupDataTemplateTotle] = useState(100)
    // 分组数据关键词
    const [groupDataTemplateWordKey, setGroupDataTemplateWordKey] = useState('')
    // 分组数据模版表头
    const [groupDataTemplateColumns] = useState([
        {
            title: '模版名字',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '用途',
            dataIndex: 'purpose',
            key: 'purpose',
            align: "center",
        },
        {
            title: '生产时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: "center",
            render: (text, record, index) => (
                <span>{moment(text).format('YYYY-MM-DD')}</span>
            )
        },
        {
            title: '状态',
            dataIndex: 'is_valid',
            key: 'is_valid',
            align: "center",
            render: (text, record, index) => (
                <span>{text == 1 ? '正常' : '禁用'}</span>
            )
        },
        {
            title: '是否公开',
            dataIndex: 'is_open',
            key: 'is_open',
            align: "center",
            render: (text, record, index) => (
                <span>{text == 1 ? '公开' : '不公开'}</span>
            )
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            align: "center",
            render: (text, record, index) => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Tooltip title="查看模版细节">
                        <SearchOutlined onClick={() => {
                            let { id } = record
                            let dataProps = {
                                "roleId": cookie.load('role'),
                                "shopList": cookie.load('shopList'),
                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                "shop": props.history.location.pathname.split('/')[2],
                                "template_id": id
                            }
                            NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateDetail).then(res => {
                                if (res.success) {
                                    setGroupIndicatorList(res.data['indicators'])
                                    // setGroupDimensionList(res.data['dimension'])
                                    let dimensionList = res.data['dimension']
                                    let indicatorList = res.data['indicators']
                                    let groupingDimensions = []
                                    let selectedIndicator = []
                                    res.data['dimension_list'].map(item => {
                                        dimensionList.map(dimension => {
                                            if (dimension['id'] == item) {
                                                groupingDimensions.push(dimension)
                                                dimensionList.remove(dimension)
                                            }
                                        })
                                    })
                                    setGroupDimensionList(dimensionList)
                                    setGroupingDimensions(groupingDimensions)
                                    setOldGroupingDimensions(groupingDimensions)
                                    res.data['indicator_list'].map(item => {
                                        indicatorList.map(indicator => {
                                            indicator.indicatorList.map(indicatorItem => {
                                                if (indicatorItem['id'] == item) {
                                                    selectedIndicator.push(indicatorItem)
                                                }
                                            })
                                        })
                                    })
                                    setGroupSelectedIndicator(selectedIndicator);
                                    setOldGroupSelectedIndicator(selectedIndicator);
                                    setIsEditGroup(true)
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }} />
                    </Tooltip>
                    {
                        record.is_valid == '1' ? <Tooltip title="禁用模版">
                            <Popconfirm title="是否确认删除？" okText="确定" cancelText="取消" onConfirm={() => {
                                let { id } = record
                                let dataProps = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "template_id": id
                                }
                                NbAxios(dataProps, "POST", servicePath.deleteGroupStatisticsTemplate).then(res => {
                                    if (res.success) {
                                        message.success('成功禁用模版')
                                        getGroupStatisticsTemplateList()
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                })
                            }}>
                                <DeleteOutlined />
                            </Popconfirm>
                        </Tooltip>
                            : <Tooltip title="启用模版">
                                <Popconfirm title="是否确认恢复？" okText="确定" cancelText="取消" onConfirm={() => {
                                    let { id } = record
                                    let dataProps = {
                                        "roleId": cookie.load('role'),
                                        "shopList": cookie.load('shopList'),
                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                        "template_id": id
                                    }
                                    NbAxios(dataProps, "POST", servicePath.recoveryGroupStatisticsTemplate).then(res => {
                                        if (res.success) {
                                            message.success('成功启用模版')
                                            getGroupStatisticsTemplateList()
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    })
                                }}>
                                    <RedoOutlined />
                                </Popconfirm>
                            </Tooltip>
                    }
                    {
                        record.is_open == 1 ? <Tooltip title="不公开模版">
                            <Popconfirm title="是否不公开模版?" okText="确定" cancelText="取消" onConfirm={() => {
                                let { id } = record
                                let dataProps = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "template_id": id
                                }
                                NbAxios(dataProps, "POST", servicePath.cancelOpenGroupStatisticsTemplate).then(res => {
                                    if (res.success) {
                                        getGroupStatisticsTemplateList()
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                })
                            }}>
                                <EyeInvisibleOutlined />
                            </Popconfirm>
                        </Tooltip>
                            : <Tooltip title="公开模版">
                                <Popconfirm title="是否公开模版?" okText="确定" cancelText="取消" onConfirm={() => {
                                    let { id } = record
                                    let dataProps = {
                                        "roleId": cookie.load('role'),
                                        "shopList": cookie.load('shopList'),
                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                        "template_id": id
                                    }
                                    NbAxios(dataProps, "POST", servicePath.openGroupStatisticsTemplate).then(res => {
                                        if (res.success) {
                                            getGroupStatisticsTemplateList()
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    })
                                }}>
                                    <EyeOutlined />
                                </Popconfirm>
                            </Tooltip>
                    }
                </div>
            )
        },
    ])
    //设置每个页面显示个数
    const groupDataTemplatePaginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: groupDataTemplatePage,
        total: groupDataTemplateTotle,
        onChange: page => {
            //改变页码
            setGroupDataTemplatePage(page)
        }
    }
    // 分组缓存表头
    const [groupCacheDataColumns] = useState([
        {
            title: '缓存名字',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '生产时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: "center",
            render: (text, record, index) => (
                <span>{moment(text).format('YYYY-MM-DD')}</span>
            )
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            align: "center",
            render: (text, record, index) => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Tooltip title="查看缓存数据">
                        <SearchOutlined onClick={() => {
                            let item = record
                            let obj = {
                                'id': item.id,
                                'name':item.name,
                                "start_time": item.start_time,
                                "short_chain": item.short_chain,
                                "end_time": item.end_time,
                                'template_id': item.template_id,
                                'is_temporary': item.is_temporary
                            }
                            props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/groupStatistics`, 'state': { 'cacheDataDict': obj } })
                        }} />
                    </Tooltip>
                    {/* <Tooltip title="进行分组统计">
                        <GroupOutlined onClick={() => {
                            props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/groupStatistics`, })
                        }} />
                    </Tooltip> */}
                </div>
            )
        }
    ])
    // 分组缓存数据
    const [groupCacheData, setGroupCacheData] = useState([])
    // 点击分组数据
    const [groupDataTemplateExpandedRowKeys, setGroupDataTemplateExpandedRowKeys] = useState([])
    // 多选设置
    const onSelectChange = (selectedRowKeys, selectedRow) => {
        if (selectedRowKeys.length > 2) {
            message.warn('目前仅支持两组数据进行对比')
        } else {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRow(selectedRow)
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    // 获取场景接口
    useEffect(() => {
        queryIndex()
    }, [])

    // 标签页变化的时候
    useEffect(() => {
        setDetailDataTemplateExpandedRowKeys([])
        setGroupDataTemplateExpandedRowKeys([])
        if (activeTab == '明细数据模版') {
            if (detailDataTemplatePage != 1) {
                setDetailDataTemplatePage(1)
            } else {
                getDetailDataTemplate()
            }
        } else if (activeTab == '明细数据缓存') {
            getDetailDataTemplateCacheList()
        } else if (activeTab == '分组数据模版') {
            if (groupDataTemplatePage != 1) {
                setGroupDataTemplatePage(1)
            } else {
                getGroupStatisticsTemplateList()
            }
        } else if (activeTab == '分组数据缓存') {
            queryGroupStatisticsCacheList()
        }
    }, [activeTab])

    // 获取明细数据模板
    useEffect(() => {
        if (activeTab == '明细数据模版') {
            getDetailDataTemplate()
        }
    }, [detailDataTemplatePage])

    // 获取分组统计模板
    useEffect(() => {
        if (activeTab == '分组数据模版') {
            getGroupStatisticsTemplateList()
        }
    }, [groupDataTemplatePage])
    // 查询分组统计缓存列表
    const queryGroupStatisticsCacheList = () => {
        setIsSpinning(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'creator_name': cookie.load('name'),
        }
        NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsCacheList).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setGroupCacheData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询分组统计模板列表接口
    const getGroupStatisticsTemplateList = () => {
        setIsSpinning(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "creator_name": cookie.load('name'),
            "page_size": 10,
            "page": groupDataTemplatePage,
            'key_word': groupDataTemplateWordKey
        }
        NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateList).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setGroupDataTemplateTotle(res.total);
                setGroupDataTemplateData(res.data);
                // setIsCreateDetailDataTemplate(false)
                // setDetailDataTemplateSearch(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取场景
    const queryIndex = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]]
        }
        NbAxios(dataProps, "POST", servicePath.queryDataMartIndex).then(res => {
            if (res.success) {
                setAllBusinessStaffDate(res.data['1']['date']);
                setChooseBusinessStaffDate(res.data['1']['date']);
                setSearchItemDate(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取明细数据模版
    const getDetailDataTemplate = () => {
        setIsSpinning(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "creator_name": cookie.load('name'),
            "page_size": 10,
            "page": detailDataTemplatePage,
            'key_word': detailDataTemplateWordKey
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplate).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setDetailDataTemplateTotle(res.total);
                setDetailDataTemplateData(res.data);
                setIsCreateDetailDataTemplate(false)
                setDetailDataTemplateSearch(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询明细数据模板详情接口
    const getDetailDataTemplateDetails = (templateId) => {
        setIsSpinning(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "template_id": templateId
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplateDetails).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setTemplateIndicatorList(res.data['data'][0]['selected_indicator'])
                setSelectedIndicator(res.data['data'][0]['selected_indicator']);
                setChosedList(res.data['data'][0]['choose_list']);
                setDetailDataTemplateSearch(true)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取明细数据缓存
    const getDetailDataTemplateCacheList = () => {
        setIsSpinning(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "creator_name": cookie.load('name')
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplateCacheList).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setDetailCacheData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询原子表格接口
    const queryAtomicTable = (short_chain, index) => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "short_chain": short_chain,
        }
        NbAxios(dataProps, "POST", servicePath.queryAtomicTable).then(res => {
            if (res.success) {
                let obj = [...atomicData]
                obj[index]['short_chain'] = short_chain
                obj[index]['atomicDimension'] = res.data['condition']
                setAtomicData(obj)
                if(index == 1){
                    setIsGroupCompared(true)
                }
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div>
            <div style={
                {
                    display: 'flex',
                    justifyContent: "space-around",
                    backgroundColor: "#fff",
                    borderBottom: "solid 2px #ccc",
                    paddingTop: 5,
                    marginBottom: 10,
                    position: 'fixed',
                    top: 40,
                    zIndex: 99,
                    width: '100vw'
                }
            } >
                <Title level={2} > {props.location.pathname.split('/')[2]}数据管理 </Title>
                <div></div>
            </div>
            <div style={{ marginLeft: 50, marginTop: 70 }}>
                <Tabs activeKey={activeTab} type="card" onChange={(key) => {
                    setSelectedRowKeys([])
                    setActiveTab(key)
                }}>
                    {
                        tabList.map(item => {
                            return <TabPane tab={item} key={item} disabled={item == '自定义模版' ? true : false}></TabPane>
                        })
                    }
                </Tabs>
            </div>
            <Spin spinning={isSpinning} tip="数据加载中.......">
                <div style={{ marginLeft: 50, marginRight: 50 }}>
                    <div style={{ display: activeTab == "明细数据模版" ? '' : 'none' }}>
                        <div style={{ display: 'flex' }}>
                            <Input value={detailDataTemplateWordKey} style={{ width: 400, marginRight: 10 }} placeholder="支持根据模版名字/用途的模糊查询" onChange={(e) => {
                                setDetailDataTemplateWordKey(e.target.value)
                            }} />
                            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                                if (detailDataTemplatePage != 1) {
                                    setDetailDataTemplatePage(1)
                                } else {
                                    getDetailDataTemplate()
                                }
                            }}>查询</Button>
                            <Button onClick={() => {
                                setIsSpinning(true)
                                let dataProps = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "creator_name": cookie.load('name'),
                                    "page_size": 10,
                                    "page": detailDataTemplatePage,
                                    'key_word': ''
                                }
                                NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplate).then(res => {
                                    setIsSpinning(false)
                                    if (res.success) {
                                        setDetailDataTemplateWordKey('')
                                        setDetailDataTemplateTotle(res.total);
                                        setDetailDataTemplateData(res.data);
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                })
                            }} type="primary" danger>重置</Button>
                            {
                                detailDataTemplateData.length == 0 ?  
                                <Link style={{marginTop:5, marginLeft:10}} strong onClick={() => {
                                    props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/commodityTable` })
                                }}>{"暂时数据,请到商品宽表页面创建明细数据模版."}</Link> : ''
                            }
                        </div>
                        <Table
                            dataSource={detailDataTemplateData}
                            columns={detailDataTemplateColumns}
                            pagination={detailDataTemplatePaginationProps}
                            // expandable={{
                            // expandIcon: ({ expanded, onExpand, record }) =>
                            //     expanded
                            //       ? (<DownOutlined onClick={e => onExpand(record, e)} style={{display:'flex', justifyContent:'flex-start'}} />)
                            //       : (<RightOutlined onClick={e => onExpand(record, e)} />),
                            //         expandIconColumnIndex: 0,

                            // }}
                            expandable={{
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded
                                        ? (<DownOutlined onClick={e => onExpand(record, e)} style={{ display: 'flex', justifyContent: 'flex-start' }} />)
                                        : (<RightOutlined onClick={e => onExpand(record, e)} />),
                                expandedRowRender: record => <div>
                                    <Button disabled={selectedRowKeys.length == 2 ? false : true} type="primary" onClick={() => {
                                        if (selectedRow[1]['template_id'] == selectedRow[0]['template_id']) {
                                            setIsCompare(true)
                                        } else {
                                            message.warn('请选择相同的模版')
                                        }
                                        // setIsCompare(true)
                                    }}>对比</Button>
                                    <Table
                                        columns={detailCacheDataColumns}
                                        dataSource={detailCacheData}
                                        pagination={{ pageSize: 10 }}
                                        rowSelection={rowSelection}
                                        rowKey={'id'}
                                    />
                                </div>,
                                expandIconColumnIndex: 0,
                                expandedRowKeys: detailDataTemplateExpandedRowKeys,
                                // expandRowByClick: true,
                                onExpand: (expanded, record) => {
                                    let temp = []
                                    // 判断是否是同一行进行点击
                                    if (expanded) {
                                        // 第一次点击的时候
                                        let dataProps = {
                                            "roleId": cookie.load('role'),
                                            "shopList": cookie.load('shopList'),
                                            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                            'template_id': record.id,
                                            "creator_name": cookie.load('name')
                                        }
                                        NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplateCache).then(res => {
                                            if (res.success) {
                                                setDetailCacheData(res.data);
                                                temp.push(record.id)
                                                setDetailDataTemplateExpandedRowKeys(temp)
                                            } else {
                                                message.warn(res.errorMsg)
                                            }
                                        })

                                    } else {
                                        setDetailDataTemplateExpandedRowKeys(temp)
                                    }
                                },
                            }}
                            rowKey={'id'}
                        />
                    </div>
                    <div style={{ display: activeTab == "明细数据缓存" ? '' : 'none' }}>
                        {/* <div style={{ display: 'flex' }}>
                            <Input style={{ width: 400, marginRight: 10 }} placeholder="支持根据缓存名字模糊查询" />
                            <Button type="primary">查询</Button>
                        </div> */}
                        <Button style={{ marginTop: 10, marginBottom: 5 }} disabled={selectedRowKeys.length == 2 ? false : true} type="primary" onClick={() => {
                            if (selectedRow[1]['template_id'] == selectedRow[0]['template_id']) {
                                setIsCompare(true)
                            } else {
                                message.warn('请选择相同的模版')
                            }
                        }}>对比</Button>
                        <Table
                            columns={detailCacheDataColumns}
                            dataSource={detailCacheData}
                            pagination={{ pageSize: 10 }}
                            rowSelection={rowSelection}
                            rowKey={'id'}
                        />
                    </div>
                    <div style={{ display: activeTab == "分组数据模版" ? '' : 'none' }}>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ width: 400, marginRight: 10 }} placeholder="支持根据模版名字/用途的模糊查询" onChange={(e) => {
                                setGroupDataTemplateWordKey(e.target.value)
                            }} />
                            <Button type="primary" onClick={() => {
                                if (groupDataTemplatePage != 1) {
                                    setGroupDataTemplatePage(1)
                                } else {
                                    getGroupStatisticsTemplateList()
                                }
                            }}>查询</Button>
                        </div>
                        <Table
                            dataSource={groupDataTemplateData}
                            columns={groupDataTemplateColumns}
                            pagination={groupDataTemplatePaginationProps}
                            expandable={{
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded
                                        ? (<DownOutlined onClick={e => onExpand(record, e)} style={{ display: 'flex', justifyContent: 'flex-start' }} />)
                                        : (<RightOutlined onClick={e => onExpand(record, e)} />),
                                expandedRowRender: record => <div>
                                    <Button disabled={selectedRowKeys.length == 2 ? false : true} type="primary" onClick={() => {
                                        if (selectedRow[1]['template_id'] == selectedRow[0]['template_id']) {
                                            queryAtomicTable(selectedRow[0]['short_chain'],0)
                                            queryAtomicTable(selectedRow[1]['short_chain'],1)
                                        } else {
                                            message.warn('请选择相同的模版')
                                        }
                                    }}>对比</Button>
                                    <Table
                                        columns={groupCacheDataColumns}
                                        dataSource={groupCacheData}
                                        pagination={false}
                                        rowSelection={rowSelection}
                                        rowKey={'id'}
                                    />
                                </div>,
                                expandIconColumnIndex: 0,
                                expandedRowKeys: groupDataTemplateExpandedRowKeys,
                                // expandRowByClick: true,
                                onExpand: (expanded, record) => {
                                    let temp = []
                                    // 判断是否是同一行进行点击
                                    if (expanded) {
                                        // 第一次点击的时候
                                        setIsSpinning(true)
                                        let dataProps = {
                                            "roleId": cookie.load('role'),
                                            "shopList": cookie.load('shopList'),
                                            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                            "template_id": record.id
                                        }
                                        NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateCacheList).then(res => {
                                            setIsSpinning(false)
                                            if (res.success) {
                                                setGroupCacheData(res.data);
                                                temp.push(record.id)
                                                setGroupDataTemplateExpandedRowKeys(temp)
                                            } else {
                                                message.warn(res.errorMsg)
                                            }
                                        })

                                    } else {
                                        setGroupDataTemplateExpandedRowKeys(temp)
                                    }
                                },
                            }}
                            rowKey={'id'}
                        />
                    </div>
                    <div style={{ display: activeTab == "分组数据缓存" ? '' : 'none' }}>
                        {/* <div style={{ display: 'flex' }}>
                            <Input style={{ width: 400, marginRight: 10 }} placeholder="支持根据缓存名字的模糊查询" />
                            <Button type="primary">查询</Button>
                        </div> */}
                        <Button disabled={selectedRowKeys.length == 2 ? false : true} style={{ marginTop: 10, marginBottom: 5 }} type="primary" onClick={() => {
                            if (selectedRow[1]['template_id'] == selectedRow[0]['template_id']) {
                                queryAtomicTable(selectedRow[0]['short_chain'],0)
                                queryAtomicTable(selectedRow[1]['short_chain'],1)
                            } else {
                                message.warn('请选择相同的模版')
                            }
                        }}>对比</Button>
                        <Table
                            columns={groupCacheDataColumns}
                            dataSource={groupCacheData}
                            pagination={{ pageSize: 10 }}
                            rowSelection={rowSelection}
                            rowKey={'id'}
                        />
                    </div>
                </div>
            </Spin>


            <Modal
                title="请选择对比形式"
                visible={isCompared}
                centered={true}
                okText="确定"
                cancelText="取消"
                onOk={() => {
                    if (comparsionPurpose != '') {
                        let cache_name_1 = selectedRow[0]['name']
                        let cache_start_time_1 = selectedRow[0]['start_time']
                        let cache_end_time_1 = selectedRow[0]['end_time']
                        let cache_short_chain_1 = selectedRow[0]['short_chain']
                        let cache_name_2 = selectedRow[1]['name']
                        let cache_start_time_2 = selectedRow[1]['start_time']
                        let cache_end_time_2 = selectedRow[1]['end_time']
                        let cache_short_chain_2 = selectedRow[1]['short_chain']
                        let dataProps = {
                            "roleId": cookie.load('role'),
                            "shopList": cookie.load('shopList'),
                            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                            "type": 1,
                            "template_id": selectedRow[0]['template_id'],
                            "name": '',
                            "cache_name_1": cache_name_1,
                            "cache_start_time_1": cache_start_time_1,
                            "cache_end_time_1": cache_end_time_1,
                            "cache_short_chain_1": cache_short_chain_1,
                            "cache_name_2": cache_name_2,
                            "cache_start_time_2": cache_start_time_2,
                            "cache_end_time_2": cache_end_time_2,
                            "cache_short_chain_2": cache_short_chain_2,
                            "objective": comparsionPurpose,
                            "is_index": sameIndex,
                            "creator_name": cookie.load('name'),
                            "is_top": 0
                        }
                        NbAxios(dataProps, "POST", servicePath.createDataComparisonRecord).then(res => {
                            if (res.success) {
                                message.success('数据对比方案创建成功')
                                props.history.push({
                                    'pathname': `/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison`,
                                    'state': { 'selectedPresentWays': selectedPresentWays, 'comparsionShortChain': res.data['short_chain'], 'comparison_type': 1 }
                                })
                            } else {
                                message.warn(res.errorMsg)
                            }
                        })
                    } else {
                        message.warn('请先填写对比目的')
                    }

                }}
                onCancel={() => {
                    setComparsionPurpose('')
                    setSameIndex(0)
                    setSelectedPresentWays([])
                    setIsCompare(false)
                }}
            >
                <div>
                    <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: 3 }}>
                                <span style={{ color: "red", fontSize: 20 }}>*</span>
                            </div>
                            <Text strong>对比目的:</Text>
                        </div>
                        <Input value={comparsionPurpose} style={{ width: 300 }} onChange={(e) => {
                            setComparsionPurpose(e.target.value)
                        }} />
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                        <Text strong>交并差计算:</Text>
                        <Checkbox value={sameIndex} onChange={(e) => {
                            if (e.target.checked) {
                                setSameIndex(1)
                            } else {
                                setSameIndex(0)
                            }
                        }}>只对比具有相同索引的数据（交计算）</Checkbox>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                        <Text strong>呈现方式:</Text>
                        <div style={{ display: 'flex' }}>
                            {
                                detailDataCompareList.map(item => {
                                    return <Checkbox
                                        checked={selectedPresentWays.indexOf(item) != -1 ? true : false}
                                        value={item} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...selectedPresentWays]
                                                obj.push(item)
                                                setSelectedPresentWays(obj)
                                            } else {
                                                let obj = [...selectedPresentWays]
                                                // obj.remove(item)
                                                removeByVal(obj, item)
                                                setSelectedPresentWays(obj)
                                            }
                                        }}>{item}</Checkbox>
                                })
                            }
                        </div>
                    </div>

                </div>
            </Modal>

            <Modal
                title="请编辑对比方案"
                visible={isGroupCompared}
                centered={true}
                okText="确定"
                cancelText="取消"
                onOk={() => {
                    if(selectedAtomicData.length != 0){
                        let value_list1 = []
                        let value_list2 = []
                        selectedAtomicData.map(item=>{
                            value_list1.push(item.value1)
                            value_list2.push(item.value2)
                        })
                        if (comparsionPurpose != '') {
                            let cache_name_1 = selectedRow[0]['name']
                            let cache_start_time_1 = selectedRow[0]['start_time']
                            let cache_end_time_1 = selectedRow[0]['end_time']
                            let cache_short_chain_1 = selectedRow[0]['short_chain']
                            let cache_name_2 = selectedRow[1]['name']
                            let cache_start_time_2 = selectedRow[1]['start_time']
                            let cache_end_time_2 = selectedRow[1]['end_time']
                            let cache_short_chain_2 = selectedRow[1]['short_chain']
                            let dataProps = {
                                "roleId": cookie.load('role'),
                                "shopList": cookie.load('shopList'),
                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                "type": 2,
                                "template_id": selectedRow[0]['template_id'],
                                "name": '',
                                "cache_name_1": cache_name_1,
                                "cache_start_time_1": cache_start_time_1,
                                "cache_end_time_1": cache_end_time_1,
                                "cache_short_chain_1": cache_short_chain_1,
                                "cache_name_2": cache_name_2,
                                "cache_start_time_2": cache_start_time_2,
                                "cache_end_time_2": cache_end_time_2,
                                "cache_short_chain_2": cache_short_chain_2,
                                "objective": comparsionPurpose,
                                "is_index": sameIndex,
                                "creator_name": cookie.load('name'),
                                "is_top": 0,
                                "value1": value_list1,
                                "value2":value_list2
                            }
                            NbAxios(dataProps, "POST", servicePath.createGroupStatisticsComparisonRecord).then(res => {
                                if (res.success) {
                                    message.success('数据对比方案创建成功')
                                    window.location.href = `https://nb20.bmcsoft.cn/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison?selectedPresentWays=${JSON.stringify(selectedPresentWays)}&comparsionShortChain=${res.data['short_chain']}&comparison_type=2`
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        } else {
                            message.warn('请先填写对比目的')
                        }
                    }else{
                        message.warn('请先选择对比维度')
                    }
                   
                    
                }}
                onCancel={() => {
                    setIsGroupCompared(false)
                }}
                width={800}
            >
                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                    <Text strong>选择对比维度:</Text>
                    <div>
                        {
                            atomicData.map((atomicItem, atomicIndex) => {
                                return <div style={{ border: '1px solid #ccc', marginTop: 5 }}>
                                    {
                                        atomicItem.atomicDimension.map((item, index) => {
                                            return <div style={{ display: 'grid', gridTemplateColumns: "80px 1fr", }}>
                                                <Text strong>{"第" + (index + 1) + "维度"}</Text>
                                                <div>
                                                    {
                                                        item.atomic.map(i => {
                                                            return <Checkbox
                                                                value={i}
                                                                checked={atomicItem['value'].indexOf(i) != -1 ? true : false}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        // 当点击的行不同的时候
                                                                        if (index != selectAtomicIndex) {
                                                                            let obj = [...atomicData]
                                                                            obj.map(item => {
                                                                                item.value = []
                                                                            })
                                                                            obj[atomicIndex]['value'].push(i)
                                                                            setAtomicData(obj)
                                                                            setSelectAtomicIndex(index)
                                                                        } else {
                                                                            let obj = [...atomicData]
                                                                            obj[atomicIndex]['value'] = []
                                                                            obj[atomicIndex]['value'].push(i)
                                                                            setAtomicData(obj)
                                                                        }
                                                                    } else {
                                                                        let obj = [...atomicData]
                                                                        obj[atomicIndex]['value'] = []
                                                                        setAtomicData(obj)
                                                                    }
                                                                }}
                                                            >{i}</Checkbox>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            })
                        }
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                            <Button type="primary" disabled={atomicData.length == 2 ? atomicData[1]['value'] != '' && atomicData[0]['value'] != '' ? false : true : true} onClick={() => {
                                let selectedAtomicDataObj = [...selectedAtomicData]
                                let mes = atomicData[0]['value'] + ' 对比 ' + atomicData[1]['value']
                                let value1 = atomicData[0]['value']
                                let value2 = atomicData[1]['value']
                                let short_chain1 = atomicData[0]['short_chain']
                                let short_chain2 = atomicData[1]['short_chain']
                                let flag = 0
                                selectedAtomicData.map(item => {
                                    if (item.message == mes) {
                                        flag = 1
                                    }
                                })
                                if (flag) {
                                    message.warn('该维度对比已存在')
                                } else {
                                    let obj = {
                                        message: mes,
                                        value1: value1[0],
                                        value2: value2[0],
                                        short_chain1: short_chain1,
                                        short_chain2: short_chain2
                                    }
                                    selectedAtomicDataObj.push(obj)
                                    setSelectedAtomicData(selectedAtomicDataObj)
                                    let atomicDataObj = [...atomicData]
                                    atomicDataObj.map(item => {
                                        item.value = []
                                    })
                                    setAtomicData(atomicDataObj)
                                }

                            }}>确定添加该维度</Button>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                    <Text strong>已选对比维度:</Text>
                    <div style={{ display: "flex", width:600, flexWrap:'wrap' }}>
                        {
                            selectedAtomicData.map((item, index) => {
                                return <div style={{
                                    margin: "3px",
                                    fontSize: '13px',
                                    border: " 1px dashed #cccccc",
                                    borderRadius: '4px',
                                    padding: "0 8px",
                                    lineHeight: "30px", color: "#666666",
                                    backgroundColor: "rgba(255, 255, 255, 0.7);",
                                    whiteSpace: 'nowrap',
                                    display: 'flex'
                                }}>
                                    <span>{item.message}</span>
                                    <CloseCircleOutlined style={{ marginTop: 8, marginLeft: 3 }} onClick={() => {
                                        let obj = [...selectedAtomicData]
                                        obj.indexRemove(index)
                                        setSelectedAtomicData(obj)
                                    }} />
                                </div>
                            })
                        }
                    </div>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 3 }}>
                            <span style={{ color: "red", fontSize: 20 }}>*</span>
                        </div>
                        <Text strong>对比目的:</Text>
                    </div>
                    <Input value={comparsionPurpose} style={{ width: 300 }} onChange={(e) => {
                        setComparsionPurpose(e.target.value)
                    }} />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                    <Text strong>交并差计算:</Text>
                    <Checkbox value={sameIndex} onChange={(e) => {
                        if (e.target.checked) {
                            setSameIndex(1)
                        } else {
                            setSameIndex(0)
                        }
                    }}>只对比具有相同索引的数据（交计算）</Checkbox>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                    <Text strong>呈现方式:</Text>
                    <div style={{ width: 700 }}>
                        <Row>
                            {
                                groupPresentWays.map(item => {
                                    return <Col span={8}>
                                        <Checkbox value={selectedPresentWays.indexOf(item.key) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...selectedPresentWays]
                                                obj.push(item.key)
                                                setSelectedPresentWays(obj)
                                            } else {
                                                let obj = [...selectedPresentWays]
                                                obj.deleteElementByValue(item.key)
                                                setSelectedPresentWays(obj)
                                            }
                                        }}>{item.name}</Checkbox>
                                    </Col>
                                })
                            }
                        </Row>
                    </div>
                </div>
            </Modal>

            <Drawer
                title="明细数据模版细节"
                visible={detailDataTemplateSearch}
                onClose={() => {
                    setDetailDataTemplateSearch(false)
                }}
                width={'80vw'}
            >
                <Spin spinning={isSpinning} tip="数据请求中.......">
                    <div style={{ display: detailDataTemplateTabKey == "指标选择" ? '' : 'none' }}>
                        {/* border:"1px solid #ccc", */}
                        <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 10 }}>
                            <div style={{ display: 'grid', gridTemplateColumns: "1fr 450px", backgroundColor: 'white' }}>
                                <div style={{ marginLeft: '20px', marginTop: 10, display: 'grid', gridTemplateColumns: "70px 1fr" }}>
                                    <b style={{ marginTop: 10 }}>已选指标:</b>
                                    <div >
                                        <DraggableArea
                                            className={style.indicatorContent}
                                            tags={selectedIndicator}
                                            render={({ tag, index }) => (
                                                <div style={{
                                                    margin: "3px",
                                                    fontSize: '13px',
                                                    border: " 1px dashed #cccccc",
                                                    borderRadius: '4px',
                                                    padding: "0 8px",
                                                    lineHeight: "30px", color: "#666666",
                                                    backgroundColor: "rgba(255, 255, 255, 0.7);",
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    <span>{tag.name}</span><CloseOutlined style={{ marginTop: 10, marginLeft: 13, display: JSON.stringify(fixedIndicator).indexOf(JSON.stringify(tag)) != -1 ? 'none' : '' }} onClick={() => {
                                                        let obj = [...selectedIndicator]
                                                        obj.remove(tag)
                                                        setSelectedIndicator(obj)
                                                        let arr = [...chosedList]
                                                        if (arr.indexOf(tag.id) != -1) {
                                                            arr.remove(tag.id)
                                                        }
                                                        setChosedList(arr)
                                                    }} />
                                                </div>
                                            )}
                                            onChange={tags => setSelectedIndicator(tags)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: 10 }}>
                                    <Button onClick={() => {
                                        setIsCreateDetailDataTemplate(true)
                                    }} type="primary" disabled={templateIndicatorList.vequal(selectedIndicator) ? true : false}>新建明细数据模版</Button>
                                </div>
                            </div>
                            <div style={{ display: 'grid', height: 54, gridTemplateColumns: "1fr 400px", backgroundColor: 'white' }}>
                                <Tabs type="card" activeKey={tabSituationKey} style={{ backgroundColor: "#fff", height: 54 }} onChange={(key) => {
                                    setTabSituationKey(key)
                                }}>
                                    {
                                        searchItemDate.map(item => {
                                            return <TabPane tab={item.situation} key={item.situation}>
                                            </TabPane>
                                        })
                                    }
                                </Tabs>
                                <div style={{ display: 'flex' }}>
                                    {/* <Button type="primary" style={{ width: '80px', marginRight: '20px', marginTop: 10 }}>确定</Button>
                                <Button type="dashed" style={{ marginTop: 10 }}>生成模版</Button>
                                <Button type="link" style={{ marginTop: 10 }}>下载数据</Button> */}
                                </div>
                            </div>
                            <div style={{ height: 350 }}>
                                {
                                    tabSituationKey == '商品基础信息' ?
                                        searchItemDate[searchItemDate.findIndex(o => o.situation == tabSituationKey)]['date'].map(item => {
                                            return <div style={{ display: 'grid', gridTemplateColumns: "80px 1fr", marginTop: 5, marginLeft: 10 }}>
                                                <Text type="secondary">{item.name}</Text>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                    {
                                                        item.option.map(item => {
                                                            return <Col>
                                                                <Checkbox
                                                                    checked={JSON.stringify(selectedIndicator).indexOf(JSON.stringify(item)) != -1 ? true : false}
                                                                    value={item} style={{ width: 150, pointerEvents: JSON.stringify(fixedIndicator).indexOf(JSON.stringify(item)) != -1 ? 'none' : '', }} onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            let obj = [...selectedIndicator]
                                                                            obj.push(item)
                                                                            setSelectedIndicator(obj)
                                                                        } else {
                                                                            let obj = [...selectedIndicator]
                                                                            obj.remove(item)
                                                                            setSelectedIndicator(obj)
                                                                        }
                                                                    }}>{item.name}</Checkbox>
                                                            </Col>
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        }) :
                                        tabSituationKey == '生意参谋全量数据' ? <div style={{ width: '70vw', marginLeft: 20 }}>
                                            <Row>
                                                {
                                                    searchItemDate[searchItemDate.findIndex(o => o.situation == tabSituationKey)]['head'].map((item, index) => {
                                                        return index == 0 ?
                                                            <Popover content={searchContent}>
                                                                <Col span={2} style={{ border: "1px solid #ccc", textAlign: 'center', backgroundColor: '#97dcff' }}>{item}</Col>
                                                            </Popover> :
                                                            <Popover content={horizontallContent} trigger="hover" onMouseEnter={() => {
                                                                setChooseIndex(index)
                                                            }}>
                                                                <Col span={2} onMouseEnter={() => {
                                                                    setTitleIndex(index)
                                                                }} onMouseLeave={() => {
                                                                    setTitleIndex('')
                                                                }} style={{ border: "1px solid #ccc", textAlign: 'center', backgroundColor: titleIndex != 0 && index == titleIndex ? '#29b6f6' : '#c3ecff' }}>{item}</Col>
                                                            </Popover>
                                                    })
                                                }
                                            </Row>
                                            <div className={style.tableContent}>
                                                {
                                                    chooseBusinessStaffDate.map((item, item_index) => {
                                                        return <Row >
                                                            {
                                                                item.option.map((i, i_index) => {
                                                                    return <Col onMouseEnter={() => {
                                                                        setRowIndex(item_index);
                                                                        setTitleIndex(i_index)
                                                                    }} onMouseLeave={() => {
                                                                        setRowIndex(-1);
                                                                        setTitleIndex(-1)
                                                                    }} span={2} style={{
                                                                        border: "1px solid #ccc",
                                                                        textAlign: 'center',
                                                                        pointerEvents: i.disable == 0 ? 'none' : '',
                                                                        backgroundColor: (item_index == rowIndex && i_index < titleIndex && i_index != 0) || (i_index == titleIndex && item_index < rowIndex && i_index != 0) ? '#81d4fa' :
                                                                            (item_index == rowIndex && i_index == titleIndex) ? '#29b6f6' :
                                                                                (item_index == rowIndex && i_index == 0) ? '#29b6f6' :
                                                                                    i_index == 0 ? '#97dcff' :
                                                                                        item_index % 2 == 0 ? '#eee' : ''
                                                                        // chosedList.indexOf(i.id) != -1 ? '#29b6f6' : '',
                                                                    }}>{i_index == 0 ?
                                                                        <Popover content={verticalContent} trigger="hover" onMouseEnter={() => {
                                                                            setRowIndex(item_index);
                                                                        }}>
                                                                            <span>{i.name}</span>
                                                                        </Popover>
                                                                        : chosedList.indexOf(i.id) != -1 ? <span onClick={() => {
                                                                            if (chosedList.indexOf(i.id) == -1) {
                                                                                // 在选中的集合中不存在,添加新的数据
                                                                                let obj = [...selectedIndicator]
                                                                                obj.push(i)
                                                                                setSelectedIndicator(obj)
                                                                                let idObj = [...chosedList]
                                                                                idObj.push(i.id)
                                                                                setChosedList(idObj)
                                                                            } else {
                                                                                // 在选中的集合中存在,删除数据
                                                                                let obj = [...selectedIndicator]
                                                                                obj.remove(i)
                                                                                setSelectedIndicator(obj)
                                                                                let idObj = [...chosedList]
                                                                                idObj.remove(i.id)
                                                                                setChosedList(idObj)
                                                                            }
                                                                        }}>
                                                                            <IconFont type="icon-dagou" style={{ width: '100%', height: '100%', fontSize: 25 }} />
                                                                        </span>
                                                                            : i.disable == 0 ? <span>
                                                                                <IconFont type="icon-hr" style={{ width: '100%', height: '100%', fontSize: 30 }} />
                                                                            </span> : <span onClick={() => {
                                                                                if (chosedList.indexOf(i.id) == -1) {
                                                                                    // 在选中的集合中不存在,添加新的数据
                                                                                    let obj = [...selectedIndicator]
                                                                                    obj.push(i)
                                                                                    setSelectedIndicator(obj)
                                                                                    let idObj = [...chosedList]
                                                                                    idObj.push(i.id)
                                                                                    setChosedList(idObj)
                                                                                } else {
                                                                                    // 在选中的集合中存在,删除数据
                                                                                    let obj = [...selectedIndicator]
                                                                                    obj.remove(i)
                                                                                    setSelectedIndicator(obj)
                                                                                    let idObj = [...chosedList]
                                                                                    idObj.remove(i.id)
                                                                                    setChosedList(idObj)
                                                                                }
                                                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                        }</Col>
                                                                })
                                                            }
                                                        </Row>
                                                    })
                                                }
                                            </div>
                                        </div> : <span></span>
                                }
                            </div>
                        </div>
                    </div>
                </Spin>

            </Drawer>

            {/* 新建明细数据模版 */}
            <Modal
                title="新建明细数据模版"
                visible={isCreateDetailDataTemplate}
                centered={true}
                onCancel={() => {
                    setIsCreateDetailDataTemplate(false)
                }}
                footer={[]}
            >
                <div style={{ display: 'flex' }}>
                    <b style={{ marginRight: 20 }}>模版名字:</b>
                    <Input style={{ width: 300 }} value={detailDataTemplateName} onChange={(e) => {
                        setDetailDataTemplateName(e.target.value)
                    }} />
                </div>
                <Checkbox style={{ marginTop: 5 }} checked={periodicProduction == 1 ? true : false} onChange={(e) => {
                    if (e.target.checked) {
                        setPeriodicProduction(1)
                    } else {
                        setPeriodicProduction(0)
                    }
                }}>数据是否周期性生产</Checkbox>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 10 }}>
                    <Button type="primary" onClick={() => {
                        if (detailDataTemplateName == '') {
                            message.warn('请先填写模版名字')
                        } else {
                            let dataProps = {
                                "roleId": cookie.load('role'),
                                "shopList": cookie.load('shopList'),
                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                "is_cycle": periodicProduction,
                                "selected_indicator": selectedIndicator,
                                "name": detailDataTemplateName,
                                "choose_list": chosedList,
                                'creator_name': cookie.load('name'),
                                "shop": props.history.location.pathname.split('/')[2],
                                "is_temporary": 0,
                            }
                            NbAxios(dataProps, "POST", servicePath.createDetailDataTemplate).then(res => {
                                if (res.success) {
                                    message.success('成功生产数据提取模版')
                                    getDetailDataTemplate()
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }

                    }}>确定</Button>
                    <Button type="primary" danger onClick={() => {
                        setIsCreateDetailDataTemplate(false)
                    }}>取消</Button>
                </div>
            </Modal>

            {/* 编辑分组逻辑 */}
            <Drawer
                visible={isEditGroup}
                onClose={() => {
                    setIsEditGroup(false)
                }}
                title="筛选条件"
                width='80vw'
            >
                <Tabs activeKey={groupTabKey} type="card" style={{ backgroundColor: "#fff", height: 54 }} onChange={(key) => {
                    setGroupTabKey(key)
                }}>
                    <TabPane tab="分组逻辑" key="分组逻辑">
                    </TabPane>
                    {/* <TabPane tab="筛选范围" key="筛选范围">
                    </TabPane> */}
                </Tabs>
                <div style={{ display: groupTabKey == '分组逻辑' ? '' : 'none', width: "100%" }}>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', padding: 20 }}>
                        <Text strong style={{ fontSize: 16, marginTop: 10 }} > 统计指标: </Text>
                        <div style={{ marginLeft: 50 }}>
                            {
                                groupIndicatorList.map(item => {
                                    return <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr" }}>
                                            <Text strong>{item.indicatorName.name}:</Text>
                                            <div>
                                                {
                                                    item.indicatorList.map(i => {
                                                        return <Checkbox
                                                            checked={JSON.stringify(groupSelectedIndicator).indexOf(JSON.stringify(i)) != -1 ? true : false}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    let obj = [...groupSelectedIndicator]
                                                                    obj.push(i)
                                                                    setGroupSelectedIndicator(obj)
                                                                } else {
                                                                    let obj = [...groupSelectedIndicator]
                                                                    obj.remove(i)
                                                                    setGroupSelectedIndicator(obj)
                                                                }
                                                            }}>{i.name}</Checkbox>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{ marginTop: 10, display: 'grid', gridTemplateColumns: "50px 1fr" }} >
                            <Text strong style={{ fontSize: 16, marginTop: 10 }} > 维度: </Text> <div style={{ display: 'flex', flexWrap: 'nowrap', overflow: "auto" }} >
                                {groupDimensionList.map(item => {
                                    return <div onDragStart={() => {
                                        setDragType('dimension')
                                        setDragDate(item)
                                    }}
                                        draggable={true}
                                        style={{
                                            margin: "3px",
                                            fontSize: '13px',
                                            border: " 1px dashed #cccccc",
                                            borderRadius: '4px',
                                            padding: "0 8px",
                                            lineHeight: "30px",
                                            color: "#666666",
                                            backgroundColor: "rgba(255, 255, 255, 0.7);",
                                            whiteSpace: 'nowrap'
                                        }}>
                                        <span> {item.name} </span> </div>
                                })
                                }</div>
                        </div>
                        <div style={{ marginTop: 10, display: 'grid', gridTemplateColumns: "70px 1fr" }}
                            onDragEnter={() => {
                                if (dragType == 'dimension') {
                                    // js动态给对象修改样式
                                    let obj = document.getElementById('groupingDimensions')
                                    obj.style.border = "2px solid #a3e9a4"
                                    // 一秒之后自动回复原来的样子
                                    setTimeout(() => {
                                        let obj = document.getElementById('groupingDimensions')
                                        obj.style.border = "1px dashed #cccccc"
                                    }, 1000)
                                    // 分组维度添加一个数据，维度集合减少一个数据
                                    if (groupingDimensions.indexOf(dragDate) == -1) {
                                        let data = [...groupingDimensions]
                                        data.push(dragDate)
                                        setGroupingDimensions(data)
                                        let arr = [...groupDimensionList]
                                        arr.remove(dragDate)
                                        setGroupDimensionList(arr)
                                    }
                                } else {
                                    let obj = document.getElementById('groupingDimensions')
                                    obj.style.border = "2px solid #fa6c60"
                                    setTimeout(() => {
                                        let obj = document.getElementById('groupingDimensions')
                                        obj.style.border = "1px dashed #cccccc"
                                    }, 1000)
                                }
                            }}
                        >
                            <Text strong style={{ fontSize: 16, marginTop: 12 }} > 分组维度: </Text>
                            <div id="groupingDimensions" style={{ width: '100%', border: "1px dashed #cccccc", height: 50, marginTop: 5, display: 'flex', flexWrap: 'nowrap', overflow: "auto", marginLeft: 4 }}>
                                <DraggableArea tags={groupingDimensions} render={({ tag, index }) => (
                                    <div style={{
                                        margin: "3px",
                                        fontSize: '13px',
                                        border: " 1px dashed #cccccc",
                                        borderRadius: '4px',
                                        padding: "0 8px",
                                        lineHeight: "30px",
                                        color: "#666666",
                                        backgroundColor: "rgba(255, 255, 255, 0.7);",
                                        whiteSpace: 'nowrap',
                                    }} >
                                        <span> {tag.name} </span><CloseOutlined style={{ marginTop: 12, marginLeft: 13 }} onClick={() => {
                                            let obj = [...groupingDimensions]
                                            obj.remove(tag)
                                            setGroupingDimensions(obj)
                                            let arr = [...groupDimensionList]
                                            arr.push(tag)
                                            setGroupDimensionList(arr)
                                        }} />
                                    </div>
                                )} onChange={tags => setGroupingDimensions(tags)} />
                            </div>
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <Button type="primary"
                                disabled={oldGroupSelectedIndicator.vequal(groupSelectedIndicator)
                                    && oldGroupingDimensions.vequal(groupingDimensions) ? true : false}>
                                新建分组统计模版</Button>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Index