import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment'
import { Form, Row, Table, Button, Col, Tooltip, Input, Popconfirm, Select, Modal, DatePicker, Drawer, Radio, Tag, message } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../../config/apiUrl'
import '../../static/css/index.css'
import locale from 'antd/es/date-picker/locale/zh_CN';
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;
const { Search } = Input;
// 项目管理
function Project(props) {

    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    };

    const layout1 = {
        labelCol: { span: 5 },
        wrapperCol: { span: 20 },
    };

    const [form] = Form.useForm();

    // 项目id
    const [projectId, setProjectId] = useState('')

    // 页数
    const [page, setPage] = useState(1)

    // 总数
    const [total, setTotal] = useState('')

    // 搜索类型
    const [searchType, setSearchType] = useState('关键字')

    // 数据
    const [date, setDate] = useState([])

    // 项目名称
    const [name, setName] = useState("")

    // 负责人
    const [people, setPeople] = useState("")

    // 创建者
    const [creator, setCreator] = useState('')

    // 立项时间
    const [startTime, setStartTime] = useState('')

    // 类型
    const [type, setType] = useState('')

    // 阶段
    const [state, setState] = useState('')

    // 团队
    const [team, setTeam] = useState([])

    // 选择的团队
    const [chooseTeam, setChooseTeam] = useState(['A'])

    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])


    // 打开创建项目
    const [createProject, setCreateProject] = useState(false)

    // 打开修改项目
    const [updateProject, setUpdateProject] = useState(false)

    // 查看项目简介
    const [openXmjj, setOpenXmjj] = useState(false)

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 打开查询条件
    const [openSearch, setOpenSearch] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 表格标题
    const columns = [
        {
            title: '名称',
            key: 'name',
            dataIndex: 'name',
            width: '80px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '负责人',
            key: 'director',
            dataIndex: 'director',
            width: '80px',
            align: 'center',
        },
        {
            title: '创建人',
            key: 'founder',
            dataIndex: 'founder',
            width: '80px',
            align: 'center',
        },
        {
            title: '阶段',
            key: 'state',
            dataIndex: 'state',
            width: '80px',
            align: 'center',
            render: (text, record, index) => (
                text == '1' ? <Tag color="green">启动阶段</Tag> :
                    text == '2' ? <Tag color="orange">规划阶段</Tag> :
                        text == '3' ? <Tag color="blue">执行阶段</Tag> :
                            text == '4' ? <Tag color="red">收尾阶段</Tag> :
                                <Tag color="green">启动阶段</Tag>
            )
        },
        {
            title: '类型',
            key: 'genre',
            dataIndex: 'genre',
            width: '80px',
            align: 'center',
            render: (text, record, index) => (
                text == '0' ? <Tag color="blue">项目组合</Tag> :
                    text == '1' ? <Tag color="orange">项目集</Tag> :
                        text == '2' ? <Tag color="green">项目</Tag> :
                            <Tag color="green">项目</Tag>
            )
        },
        {
            title: '团队数量',
            key: 'team_number',
            dataIndex: 'team_number',
            width: '80px',
            align: 'center',
        },
        {
            title: '成员数量',
            key: 'user_number',
            dataIndex: 'user_number',
            width: '80px',
            align: 'center',
        },
        {
            title: '总览',
            key: 'xmjj',
            dataIndex: 'xmjj',
            width: '80px',
            align: 'center',
            render: (text, record, index) => {
                return <Tooltip placement="top" title="开发中,尽请期待">
                    <Button disabled onClick={() => {
                        props.history.push({ 'pathname': '/app/pm/overview_managment' })
                    }}>查看总览</Button>
                </Tooltip>
            }
        },
        {
            title: '开始时间',
            key: 'start_time',
            dataIndex: 'start_time',
            width: '80px',
            align: 'center',
            render: (text, record, index) => {
                return <div>{text.slice(0, 10)}</div>
            }

        },
        {
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            width: '80px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <div style={{ display: date[index]['is_valid'] == 0 ? "none" : "" }}>
                        <Row style={{ display: cookie.load("name") == date[index]['director'] || cookie.load("name") == date[index]['founder'] ?  "" : "none" }}>
                            <Col span={12} >
                                <Tooltip placement="top" title="编辑项目">
                                    <EditOutlined onClick={() => {
                                        form.resetFields();
                                        setProjectId(date[index]['id'])
                                        setName(date[index]['name'])
                                        setPeople(date[index]['director'])
                                        setStartTime(date[index]['start_time'])
                                        setType(date[index]['genre'])
                                        setState(date[index]['state']);
                                        setUpdateProject(true)
                                    }} />
                                </Tooltip>
                            </Col>
                            <Col span={12}>
                                <Tooltip placement="top" title="查看详情">
                                    <SearchOutlined onClick={() => { props.history.push({ 'pathname': '/app/pm/member_managment', 'state': { 'project': date[index]['id'], 'director': date[index]['director_uuid'], 'founder': date[index]['founder_uuid'] } }) }} />
                                </Tooltip>
                            </Col>
                        </Row>
                    </div>

                )
            }
        }
    ]

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 初始化数据
    useEffect(() => {
        getAllProject()
    }, [page])

    // 获取权限列表
    useEffect(()=>{
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    },[])

    // 获取项目数据
    const getAllProject = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "page": page,
            "page_size": 10,
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryProject,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    console.log(res.data.data);
                    setTotal(res.data.total);
                    setDate(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 确认创建项目
    const createOk = () => {
        setEffective(1)
        let dataProps = {
            "name": name,
            "director": people,
            "start_time": startTime,
            "genre": 2,
            "principal_uuid": cookie.load('id'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        console.log(dataProps);
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.createProject,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    message.success('成功创建项目')
                    setName('')
                    setPeople('')
                    setStartTime('')
                    setType('')
                    getAllProject()
                    setCreateProject(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 取消创建项目
    const createCancel = () => {
        message.success('取消创建项目')
        setCreateProject(false)
    }

    // 结束项目
    const deleteProject = () => {
        setEffective(1)
        let dataProps = {
            "project_id": projectId,
            "principal_uuid": cookie.load("id"),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.deleteProject,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    message.success('确认项目完结')
                    setProjectId('')
                    setName('')
                    setPeople('')
                    setStartTime('')
                    setType('')
                    setState('')
                    getAllProject()
                    setUpdateProject(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 取消结束项目
    const cancelDelete = () => {

    }

    // 确认修改项目
    const updateOk = () => {
        setEffective(1)
        let dataProps = {
            "id": projectId,
            "name": name,
            "director": people,
            "start_time": startTime,
            "state": state,
            "principal_uuid": cookie.load("id"),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.updateProject,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    message.success('成功更新项目')
                    setProjectId('')
                    setName('')
                    setPeople('')
                    setStartTime('')
                    setType('')
                    setState('')
                    getAllProject()
                    setUpdateProject(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 取消修改项目
    const updateCancel = () => {
        message.success('取消更新项目')
        setProjectId('')
        setName('')
        setPeople('')
        setStartTime('')
        setType('')
        setState('')
        setUpdateProject(false)
    }

    // 关闭查看项目简介
    const openXmjjCancel = () => {
        setOpenXmjj(false)
    }

    // 关闭查询弹框
    const onSearchClose = () => {
        form.resetFields()
        setOpenSearch(false)
    }

    return (
        <div style={{ paddingBottom: size.height * 0.1 }}>
            {/* 按钮区域 */}
            <div style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.03 }}>
                <Row>
                    <Col style={{ marginRight: '20px' }}>
                        <Button type="primary" style={{ display: jurisdiction.indexOf("pm::createProject") != -1 ? "" : "none" }} onClick={() => { form.resetFields(); setCreateProject(true) }}>创建项目</Button>
                    </Col>
                    {/* <Col style={{width:'700px'}}> */}
                    {/* <Search placeholder="查询关键字/时间" onSearch={(value)=>console.log(value)} enterButton /> */}
                    {/* <Input.Group compact>
                            <Select style={{ width: '15%' }} defaultValue="关键字" onChange={(value)=>setSearchType(value)}>
                                <Option value="关键字">关键字</Option>
                                <Option value="时间">时间</Option>
                            </Select>
                            {
                                searchType == '关键字'
                                ? <Search style={{ width: '70%' }} placeholder="查询关键字" onSearch={(value)=>console.log(value)} enterButton /> 
                                : <DatePicker.RangePicker locale={locale} style={{ width: '70%' }} />
                            }
                        </Input.Group>
                    </Col> */}
                </Row>
                {/* <Button onClick={() => setOpenSearch(true)} style={{ marginLeft: size.width * 0.02 }}>条件查询</Button> */}
            </div>
            {/* 项目表格 */}
            <div style={{ marginTop: size.height * 0.03 }}>
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={paginationProps}
                    onRow={(record, index) => {
                        return (
                            {
                                style: {
                                    // padding: '0',
                                    // height:'35px',
                                    color: record['is_valid'] == 0 ? '#ccc' : ''
                                    // display: record['is_valid']==0 ? 'none':''
                                },
                            }
                        )
                    }}
                />
            </div>

            {/* 创建项目弹框 */}
            <Modal
                title="创建项目"
                centered={true}
                visible={createProject}
                maskClosable={false}
                onOk={createOk}
                onCancel={createCancel}
                okText="确认"
                cancelText="取消"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Form form={form} {...layout} labelAlign='left'>
                    <Form.Item label="名称" name="name"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请填写项目名称"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                placeholder="请填写项目名称"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="负责人" name="people"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请填写负责人"
                            placement="topLeft"
                        >
                            <Input
                                id="people"
                                placeholder="请填写负责人"
                                value={people}
                                onChange={(e) => {
                                    setPeople(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="开始日期" name="start_time"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请选择开始日期"
                            placement="topLeft"
                        >
                            <DatePicker locale={locale} style={{ width: 320 }} onChange={(date, dateString) => setStartTime(dateString)} />
                        </Tooltip>
                    </Form.Item>
                    {/* <Form.Item label="类型" name="type"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请选择立项时间"
                            placement="topLeft"
                        >
                            <Radio.Group onChange={(e) => setType(e.target.value)}>
                                <Radio value={2}>项目</Radio>
                                <Radio value={1}>项目集</Radio>
                                <Radio value={0}>项目组合</Radio>
                            </Radio.Group>
                        </Tooltip>
                    </Form.Item> */}
                </Form>
            </Modal>

            {/* 编辑项目弹框 */}
            <Modal
                title="编辑项目"
                centered={true}
                visible={updateProject}
                maskClosable={false}
                onOk={updateOk}
                onCancel={updateCancel}
                okText="确认"
                cancelText="取消"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Form form={form} {...layout} labelAlign='left'>
                    <Form.Item label="项目名称" name="name"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请填写项目名称"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                placeholder="请填写项目名称"
                                value={name}
                                disabled
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="负责人" name="people"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请填写负责人"
                            placement="topLeft"
                        >
                            <Input
                                id="people"
                                placeholder="请填写负责人"
                                defaultValue={people}
                                value={people}
                                onChange={(e) => {
                                    setPeople(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="开始时间" name="xmjj"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请选择开始时间"
                            placement="topLeft"
                        >
                            <DatePicker defaultValue={moment(startTime, dateFormat)} format={dateFormat} locale={locale} style={{ width: 320 }} onChange={(date, dateString) => setStartTime(dateString)} />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="项目阶段" name="xmjd">
                        <Select defaultValue={state} style={{ width: 320 }} onChange={(value) => setState(value)}>
                            <Option value="1">启动项目</Option>
                            <Option value="2">规划项目</Option>
                            <Option value="3">执行项目</Option>
                            <Option value="4">收尾项目</Option>
                        </Select>
                    </Form.Item>
                    {/* <Form.Item label="类型" name="type"
                        rules={[{ required: true }]}>
                        <Tooltip
                            trigger={['focus']}
                            title="请选择立项时间"
                            placement="topLeft"
                        >
                            <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
                                <Radio value={'2'}>项目</Radio>
                                <Radio value={'1'}>项目集</Radio>
                                <Radio value={'0'}>项目组合</Radio>
                            </Radio.Group>
                        </Tooltip>
                    </Form.Item> */}
                    <Popconfirm
                        title="确认结束?结束后不可恢复!"
                        onConfirm={deleteProject}
                        onCancel={cancelDelete}
                        placement="top"
                        okText="确定"
                        cancelText="取消"
                    >
                        <Col style={{ marginLeft: '330px' }}>
                            <Button type="primary" danger>项目结束</Button>
                        </Col>
                    </Popconfirm>
                </Form>
            </Modal>

            {/* 查看简介 */}
            <Modal
                title="查看项目简介"
                centered={true}
                visible={openXmjj}
                onCancel={openXmjjCancel}
                footer={null}
            >
                {/* {xmjj} */}
            </Modal>

            {/* 查询条件 */}
            <Drawer
                title="查询条件"
                placement="right"
                closable={false}
                onClose={onSearchClose}
                visible={openSearch}
                width={700}
            >
                <Form
                    form={form}
                    {...layout1}
                    labelAlign='left'
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="项目名称"
                            >
                                <Input
                                    id='name'
                                    placeholder="请输入项目名称"

                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="people"
                                label="负责人"
                            >
                                <Input
                                    id='people'
                                    placeholder="请输入负责人"

                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="creator"
                                label="创建人"
                            >
                                <Input
                                    id='creator'
                                    placeholder="请输入创建人"

                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <Form.Item
                                name="lxsj"
                                label="立项时间"
                            >
                                <RangePicker locale={locale} />
                            </Form.Item>
                        </Col>
                        <Col style={{ marginLeft: 380 }}>
                            <Button type="primary">查询</Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )

}

export default Project