import React, { useState } from 'react';
import { Table, Typography, Button, Checkbox, Tabs, Tag, Collapse, Tooltip, Modal, Input, Spin, message, Drawer, Row, Col, Popover, Popconfirm } from 'antd'
import { SearchOutlined, DeleteOutlined, RedoOutlined, EyeInvisibleOutlined, EyeOutlined, DownOutlined, RightOutlined, GroupOutlined, MinusCircleOutlined, PlusCircleOutlined, InboxOutlined, CloseOutlined, createFromIconfontCN } from '@ant-design/icons';
import moment from 'moment'
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios';
import servicePath from '../../../config/apiUrl';


function Index(){

    // 数据
    const [date , setDate] = useState([])

    // 表头
    const [ column , setColumn ] = useState([
        {
            title:'名字',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title:'父级模版',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title:'父级缓存',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title:'时间',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title:'操作',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => (
                <div style={{display:'flex', justifyContent:'space-around'}}>
                    <a>查看</a>
                    <a>销毁</a>
                </div>
            )
        }
    ])


    return (
        <div>
            <Table columns={column} dataSource={date} />
        </div>
    )

}

export default Index
