import React, { useEffect, useState } from 'react';
import { DatePicker, message } from 'antd'
import moment from 'moment'
import servicePath from '../../config/apiUrl'
import cookie from 'react-cookies'
// 引入 ECharts 主模块
import * as echarts from 'echarts';
// 引入3D
import 'echarts-gl'
import Axios from 'axios';
import MarkChartUi from './markChartUi'

function MarkChart(props) {
    // 加载状态
    const [isLoading, setIsloading] = useState(false)
    const { cateory } = props
    // 所选择的数据
    const [selectData, setSelectData] = useState([])
    // 类目轴
    const [cateoryzhou, setCateoryZhou] = useState([])
    // 所选类目
    const [selectCate, setSelectCate] = useState([])
    // 时间轴
    const [monthY, setMonthY] = useState([])
    // 选择的类目
    const [select, setSelect] = useState('交易金额')
    // 获取的数据
    const [chartData, setChartData] = useState([])
    // 所有可选字段
    const [allFiled, setAllFiled] = useState([])

    let data = [['12a', 'Saturday', 5], [0, 1, 1], [0, 2, 0], [0, 3, 0], [6, 23, 6]];

    // 图表数据
    let option = {
        title: {
            text: `行业数据一览`,
            textStyle: {
                fontSize: 30,
                fontWeight: 600
            }
        },
        color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026'],
        tooltip: {
            // show: true,
            formatter: (params) => {
                let data = params.data
                return `类目：${cateoryzhou[data[0]]} <br />  时间：${monthY[data[1]]} <br />${select}：${data[2]}`
            },
        },
        visualMap: {
            max: 20,
            inRange: {
                color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026']
            }
        },
        xAxis3D: {
            // name: '类目',
            type: 'category',
            data: cateoryzhou,
            textStyle: {
                fontSize: 30,
            },
        },
        yAxis3D: {
            // name: '时间',
            type: 'category',
            data: monthY
        },
        zAxis3D: {
            type: 'value'
        },
        grid3D: {
            boxWidth: cateoryzhou.length * 5,
            boxDepth: (monthY.length) * 10,
            viewControl: {
                rotateSensitivity: [5, 5],
            },
            light: {
                main: {
                    intensity: 1.2,
                    shadow: true
                },
                ambient: {
                    intensity: 0.3
                }
            }
        },
        // 统一数据集
        // dataset: {
        //     dimensions: [
        //         '类目',
        //         '月份',
        //         `${select}`,
        //     ],
        //     source: selectData
        // },
        series: [{
            name: `${select}`,
            type: 'bar3D',
            itemStyle: {
                opacity: 0.7
            },
            // 单独设置数据
            data: chartData[select],
            // shading: 'lambert', //设置阴影模式
            // 设置倒角
            bevelSize: 0.2,
        }]
    }

    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let cateoryChart = echarts.init(document.getElementById('cateory'));
        // 绘制图表
        cateoryChart.setOption(option);
        window.addEventListener('resize', () => {
            cateoryChart.resize()
        });
    }, [monthY, cateoryzhou, chartData, select, selectData])
    // 开始月份
    const [startMonth, setStartMonth] = useState('')
    // 结束月份
    const [endMonth, setEndMonth] = useState('')
    // 月份变化
    function monthChange(date, dateString) {
        if (date === null) {
            message.warn('时间不能为空')
            return false
        }
        setStartMonth(date[0])
        setEndMonth(date[1])
    }

    useEffect(() => {
        getCateoryBar()
    }, [startMonth, endMonth])
    function getCateoryBar() {
        if (startMonth === '' || endMonth === '') {
            return false
        }
        let data = {}
        data.start = startMonth.format('YYYY-MM')
        data.end = endMonth.format('YYYY-MM')
        data.cateory = cateory
        data.shopList = cookie.load("shopList")
        data.roleId = cookie.load("role")
        setIsloading(true)
        setChartData([])
        setCateoryZhou([])
        setMonthY([])
        setAllFiled([])
        Axios({
            data: data,
            method: 'POST',
            url: servicePath.getCateoryBar,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data
                setChartData(data.chart_data)
                setCateoryZhou(data.cateory)
                setMonthY(data.time_list)
                setAllFiled(data.filed)
            } else {
                message.error(res.data.errorMsg)
            }
            setIsloading(false)
        }).catch(e => {
            message.error('暂无数据')
            setIsloading(false)
            setChartData([])
        })
    }


    function slectFiled(value) {
        setSelect(value)
        setSelectData(chartData[value])
        // selectData.unshift(['类目', '月份', `${select}`])
    }
    return (
        <MarkChartUi
            isLoading={isLoading}
            monthChange={monthChange}
            start={startMonth}
            end={endMonth}
            allFiled={allFiled}
            select={select}
            slectFiled={slectFiled}
            cateory={cateoryzhou}
            disabledMonth = {disabledMonth}
        />
    )
    // 不可选的周
    function disabledMonth(current) {
        // 不可选前一周
        return current && current > moment().endOf('month').subtract(1, 'month');
    }
}

export default MarkChart