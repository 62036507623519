import React, { Component, useEffect, useState, useRef, useCallback } from 'react';
import { Button, Table, Typography, Tag, Drawer, Checkbox, Input, Select, Popover, Tooltip, Modal, Tabs, Radio, Popconfirm, Form, message, Spin } from 'antd'
import { EyeOutlined, QuestionCircleOutlined, createFromIconfontCN, CloseOutlined, MenuOutlined} from '@ant-design/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard'
import style from './index.module.css';
import Axios from 'axios';
import NbAxios from '../../../config/utils/nbAxios';
import servicePath from '../../../config/apiUrl';
import cookie, { load } from 'react-cookies'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
const type = 'DraggableBodyRow';
const { Title, Text, Paragraph } = Typography
const { TabPane } = Tabs
const { Option } = Select
const {TextArea} = Input
function Index(props) {
    // 两个数组取差集
    function arrayAminusB(arrA, arrB) {
        return arrA.filter(v => !arrB.includes(v));
    }
    const [updatePlanForm] = Form.useForm();
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };
    const imgDict = {
        'JORYA':'JORYA',
        'ANMANI':'an',
        'CAROLINE':'cr',
        'EIN':'ein',
    }

    const MyIcon = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_3455825_ij4p05m0k5l.js', // 在 iconfont.cn 上生成
    });
    // 图片查看
    const imageLookContent = (id) => {
        return <div>
            <img src={`https://pic.bmcsoft.cn/it/${imgDict[props.history.location.pathname.split('/')[2]]}/${id}.jpg`} style={{ width: '100px', height: '100px' }} />
        </div>
    }
    // 更多操作菜单
    const moreActions = (
        <div>
            <div>
                <Button size='small' style={{ width: "100px" }} onClick={() => {
                }}>查看利益点</Button>
            </div>
            <div>
            <a href={`${servicePath.downloadCalculateDetail}?roleId=${cookie.load('role')}&shopList=${cookie.load('shopList')}&plan_id=${props.location.state.plan_id}`}>
                <Button size='small' style={{ width: "100px" }} onClick={() => {
                    }}>下载计算明细</Button>
            </a>
            </div>
        </div>
    )
    // 查看更多信息
    const showMoreInfo = (moreData)=>{
        return <div style={{width:"400px"}}>
            {
                moreData.map(item=>{
                   return <div>
                        <div><b>{item.key}</b></div>
                        <div style={{marginLeft:"20px", display:'flex', flexWrap:'wrap', width:"380px"}}>
                            {
                                item.data.map(dataItem=>{
                                   return <div style={{width:"190px"}}>
                                        <Text>{dataItem.key}</Text> : <Text type="secondary">{dataItem.data}</Text>
                                   </div>
                                })
                            }
                        </div>
                   </div>
                })
            }
        </div>
    }
    /**
     * 
     * <div>
            
        </div>
     */
    // 商品标记
    const [ goodsOnlyFlag , setGoodsOnlyFlag ] = useState('')
    // 搜索框
    const searchRef = useRef(null)
    // 显示表头
    const [ priceShowTitle , setPriceShowTitle ] = useState([])
    // 加载
    const [loading, setLoading] = useState(false)
    // 活动id
    const [activityId, setActivityId] = useState('')
    // 计划id
    const [planId, setPlanId] = useState('')
    const [columns, setColumns] = useState([])
    const [tableParams, setTableParams] = useState({})
    // 数据
    const [date, setDate] = useState([])
    // 是否是查询状态
    const [ searchFlag , setSearchFlag ] = useState(0)
    // 总数
    const [total, setTotal] = useState(40)
    // 当前页面
    const [page, setPage] = useState(1)
    // 页面大小
    const [pageSize, setPageSize] = useState(10)
    // 专区
    const [ downZoneList , setDownZoneList ] = useState([])
    // 商品选择项
    const [goodsFilterAddFields, setGoodsFilterAddFields] = useState([])
    // 商品筛选项
    const [goodsFilterList, setGoodsFilterList] = useState([])
    // 查询筛选条件
    const [searchFilter, setSearchFilter] = useState({})
    // 产品线
    const [ productLineList , setProductLineList ] = useState([])
    // 品类
    const [ categoryList, setCategoryList ] = useState([])
    // 在架状态
    const [ shelfStatusList , setShelfStatusList ] = useState([])
    // 季节
    const [ seasonList , setSeasonList ] = useState([])
    // 年份
    const [ yearList , setYearList ] = useState([])
    // 库存平台
    const [ inventoryPlatformList , setInventoryPlatformList] = useState([])
    // 标签
    const [ labelList , setLabelList ] = useState([])
    // 选择的id
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRow, setSelectedRow] = useState([])
    // 选择的id
    const [moreSelectedRowKeys, setMoreSelectedRowKeys] = useState([])
    const [moreSelectedRow, setMoreSelectedRow] = useState([])
    // 是否查询
    const [isSearch, setIsSearch] = useState(false)
    // 是否批量操作
    const [isBulkOperations, setIsBulkOperations] = useState(false)
    // 批量操作的标签
    const [bulkOperationTabs, setBulkOpertionTabs] = useState('设定标记')
    // 是否修改
    const [ isOperation , setIsOperation ] = useState(0)
    // 专区集合
    const [zoneList, setZoneList] = useState([])
    // 选择专区
    const [chooseZone, setChooseZone] = useState([])
    // 是否确认价格
    const [isConfirmPrice, setIsComfirmPrice] = useState('')
    // 是否加入计划
    const [isAddPlan, setIsAddPlan] = useState('')
    // 价格类型
    const [priceType, setPriceType] = useState('活动价')
    // 是否改变了优惠券
    const [checkChange,setCheckChange] = useState(false)
    // 专区集合
    const [couponList, setCouponList] = useState([])
    // 批量数据
    const [batchConfiguration , setBatchConfiguration ] = useState({
        'coupons':[],
        'discounts':[],
        'mutex_coupons':[]
    })
    // 选择专区
    const [chooseCoupon, setChooseCoupon] = useState([])
    // 是否查看更多
    const [isLookMore, setIsLookMore] = useState(false)
    // 编辑说明
    const [ isEditing , setIsEdting ] = useState(false)
    // 说明
    const [ explain , setExplain] = useState('')
    // 查询更多的数据
    const [moreData, setMoreData] = useState({
        "goods_info_title": ["goods_id", "spu", "吊牌价"],
        "goods_info": {
            "pic_url": "",
            "吊牌价": 900,
            "goods_id": "674559433280",
            "spu": "EJWACQ85"
        },
        "is_confirmed": 0,
        "is_add_plan": 0,
        "explain": "说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明说明",
        "price_show_title": [
            "活动价",
            "到手价",
            "到手折扣",
            "到手差价",
            "极限到手",
            "极限折扣",
            "极限差价",
            "单件折扣",
            "商品券",
            "专区"
        ],
        "overview": {
            "专区": "3件75折",
            "到手价": "999",
            "到手折扣": "0.6868",
            "到手差价": "-10",
            "活动价": "10999",
            "单件折扣": "0.90",
            "极限到手": "989",
            "极限折扣": "0.8080",
            "极限差价": "0",
            "used_coupons": [
                {
                    "info": "每满300-50",
                    "shord_name": "津贴",
                    "logo_color": "#D0021B",
                }
            ]
        },
        "partitions_data": [
            {
                "_id": 1,
                "专区": "3件75折",
                "到手价": "",
                "到手折扣": "",
                "到手差价": "",
                "活动价": "",
                "单件折扣": "",
                "极限到手价": "",
                "极限折扣": "",
                "极限差价": "",

                "coupons": [
                    {
                        "info": "每满300-50",
                        "shord_name": "津贴",
                        "color": "#D0021B",
                    }
                ]
            },
        ],
        "show_info":[],
        "belong_partition": ["1", "2"],
        "coupons_list": ["商品优惠券", "商品券", "平台津贴", "平台购物券"],
        "coupons_checked": ["商品优惠券", "商品券"],
        "tag": [
            {
                "info": "主链接",
                "shord_name": "主",
                "color": "#D0021B",
            },
        ],
    })
    // 更多字段
    const [ moreFiled ] = useState(['专区','活动价','到手价','到手折扣','到手差价','极限到手','极限折扣','极限差价','单件折扣','商品券','优惠券'])
    // 更多表头
    const [ moreColumns, setMoreColumns ] = useState([])
    // 固定变量
    const [ fixedVariableList ] = useState(['活动价','极限到手'])
    // 选择固定变量
    const [ chooseFixedVariable, setChooseFixedVariable] = useState('')
    // 打开时优惠券
    const [ openCoupons , setOpenCoupons ] = useState([])
    // 选择优惠券
    const [ checkedCoupons , setCheckedCoupons ] = useState([])
    // 选择价格修改选项
    const [ choosePriceOption , setChoosePriceOption ] = useState('')
    const [ priceValue , setPriceValue ] = useState('')
    // 是否确定
    const [ isConfimed , setIsConfimed ] = useState(null)
    // 是否加入计划
    const [ isSearchAddPlan, setIsSearchAddPlan ] = useState(null)
    // id
    const [ goodsId, setGoodsId] = useState('')
    const [ spu, setSpu] = useState('')
    const [ chooseDownZone, setChooseDownZone ] = useState([])
    // 库存大小
    const [ minStock , setMinStock ] = useState('')
    const [ maxStock , setMaxStock ] = useState('')
    // 选择的基础信息
    const [baseInfo, setBaseInfo] = useState(null)
    // 选择平台
    const [ choosePlatform , setChoosePlatform ] = useState('')
    // 选择在架状态
    const [ chooseShelfStatus, setChooseShelfStatus ] = useState('')
    // 选择产品线
    const [ chooseProductLine, setChooseProductLine ] = useState([])
    // 选择季节
    const [ chooseSeason, setChooseSeason ] = useState([])
    // 选择年份
    const [ chooseYear , setChooseYear ] = useState([])
    // 选择品类
    const [ chooseCategory, setChooseCategory ] = useState([])
    // 选择标签
    const [ chooseLabel, setChooseLabel ] = useState([])
    // 选择值
    const [ chooseValueList , setChooseValueList ] = useState([])
    // 极限差价
    const [ minLimitSpread, setMinLimitSpread ] = useState('')
    const [ maxLimitSpread, setMaxLimitSpread ] = useState('')
    // 底线折扣
    const [ minBottomLine , setMinBottomLine] = useState('')
    const [ maxBottomLine , setMaxBottomLine] = useState('')
    // 价格带
    const [ minPriceBand , setMinPriceBand ] = useState('')
    const [ maxPriceBand , setMaxPriceBand ] = useState('')
    // 与沿用活动价差
    const [ minActivitySpread, setMinActivitySpread ] = useState('')
    const [ maxActivitySpread, setMaxActivitySpread ] = useState('')
    // 单件折扣
    const [ oneDiscount , setOneDiscount ] = useState('')
    // 商品券
    const [ commodityVoucher, setCommodityVoucher] = useState('')
    // 显示配置弹窗
    const [tableHeaderModel, setTableHeaderModel] = useState(false) 
    // 显示配置的数据源
    const [tableHeaderData, setTabelHeaderData] = useState([
        {
            'name':'商品信息',
            'select_list' : ['年份','季节','产品线'],
        },
        {
            'name':'分组字段',
            'select_list' : ['1','2','3'],
        },
        {
            'name':'销售年份',
            'select_list' : ['2022','2021','2020','2019','2018','2017','2016','2015','2014'],
        }
    ])
    // 选择的配置数据
    const [checkTableHeader, setCheckTableHeader] = useState({})
    const [tableHeaderColumns, setTableHeaderColumns] = useState([
        {
            title: '维度',
            dataIndex: '维度',
            align: 'center',
        },
        {
            title: '字段',
            dataIndex: '字段',
            align: 'center',
        },
    ])
    // 显示配置的表格数据
    const [tableHeaderDataSource, setTableHeaderDataSource] = useState([])
    // 表头数据
    const [columnsData, setColumnsData] = useState({
        'left':[],
        'mid':[],
        'right':[],
    })
    useEffect(()=>{
        setMoreColumns(moreData['price_show_title'].map(item=>{
            return {
                title:item == 'used_coupons' ? '优惠券' : item,
                dataIndex: item,
                align: 'center',
                width: item == '专区' ? "120px" :"100px",
                render: (text, record, index) => (
                    item == 'used_coupons' ? 
                    <div style={{display:"flex", flexWrap:'wrap'}}>
                       {
                           record['used_coupons'].map(item=>{
                                return <div style={{display:"flex"}}>
                                    <div style={{border:`1px solid ${item['logo_color']}`, color:`${item['logo_color']}`, backgroundColor:`${item['logo_color'].substring(0,7) + '19'}`, padding:"0 3px", textAlign:'center', borderRadius:"3px", fontSize:"10px"}}>{item.coupon_short_name}</div>
                                </div>
                           })
                       }
                    </div>
                    :<span>{text}</span>
                ),
            }
        }))
    },[moreData])
    
    useEffect(()=>{
        if(moreSelectedRow.length != 0){
            let atHandPrice = 100000000
            let nowIndex = 0
            moreSelectedRow.map((item,index)=>{
                if(item['到手价'] < atHandPrice){
                    nowIndex = index
                    atHandPrice = parseFloat(item['到手价'])
                }
            })
            moreData['overview'] = moreSelectedRow[nowIndex]
        }
    },[moreSelectedRow])

    // 多选设置
    const onSelect = (record, selected) => {
        let keys = [...selectedRowKeys]
        if (selected) {
            keys = [...selectedRowKeys, record.goods_only_flag]
        } else {
            keys = selectedRowKeys.filter((item) => item !== record.goods_only_flag)
        }
        setSelectedRowKeys(keys)
    };
    const onSelectAll = (selected, selectedRows, changeRows) => {
        if (selected) {
          const addCheckedKeys = changeRows.map((item) => {
            return item.goods_only_flag
          })
          setSelectedRowKeys([...selectedRowKeys, ...addCheckedKeys])
        } else {
          const subCheckedKeys = selectedRowKeys.filter((goods_only_flag) => {
            return !changeRows.some((item) => {
              return item.goods_only_flag === goods_only_flag
            })
          })
          setSelectedRowKeys(subCheckedKeys)
        }
    }
    const rowSelection = {
        columnWidth: 50,
        selectedRowKeys,
        onSelect: onSelect,
        onSelectAll:onSelectAll
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown)   // 添加全局事件监听键盘事件
        return () => {
            window.removeEventListener('keydown', onKeyDown)    //销毁
        }
    }, [])

    // 拖拽设置
    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef(null);
        const [{ isOver, dropClassName }, drop] = useDrop({
          accept: type,
          collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
      
            if (dragIndex === index) {
              return {};
            }
      
            return {
              isOver: monitor.isOver(),
              dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
          },
          drop: (item) => {
            moveRow(item.index, index);
          },
        });
        const [, drag] = useDrag({
            type,
            item: {
                index,
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        drop(drag(ref));
        return (
          <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{
              cursor: 'move',
              ...style,
            }}
            {...restProps}
          />
        );
    };
    const components = {
        body: {
          row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
        const dragRow = tableHeaderDataSource[dragIndex];
        setTableHeaderDataSource(
            update(tableHeaderDataSource, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
              ],
            }),
          );
        },
        [tableHeaderDataSource],
    );
    const onKeyDown = (e) => {
        // 点击ctrl键事件
        // console.log(e.ctrlKey);
        // 监听组合按键
        if (e.keyCode == 49 && e.ctrlKey) {
            // console.log("ctrl + 1 组合按键执行")
            setIsSearch(true)
            searchRef.current.focus();
        }
    }
    

    // 表格分页器设置
    const paginationProps = {
        size: "small",
        // total: total,
        position: ["bottomCenter"],
        // showTotal: () => `一共${total}款商品`,
        showSizeChanger: true,
        current: page,
        pageSize: pageSize,
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize)
        },
        onChange: (page) => {
            setPage(page)
        }
    }
    // 多选设置
    const onMoreSelectChange = (selectedRowKeys, selectedRow) => {
        setMoreSelectedRowKeys(selectedRowKeys)
        setMoreSelectedRow(selectedRow)
    };
    const moreRowSelection = {
        columnWidth: 5,
        selectedRowKeys:moreSelectedRowKeys,
        onChange: onMoreSelectChange,
    }
    // 点击筛选触发函数
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams(filters)
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: props.location.state.activity_id,
            plan_id: props.location.state.plan_id,
            page_size:pageSize,
            page_no:page,
            search_filter:searchFilter,
        }
        // initializePriceAdjustment(props.location.state.activity_id, props.location.state.plan_id)
        if(filters){
            searchGoods2(dataProps)
        }
      };
    // 点击拷贝
    const copyhander=(e)=>{
        const range = document.createRange()
        window.getSelection().removeAllRanges()
        range.selectNode(e.target)
        window.getSelection().addRange(range)
        const successful = document.execCommand('copy')
        if(successful){
            message.success("复制成功")
        }
    }
    // 一页个数修改的时候
    // useEffect(() => {
    //     if(activityId != ''){
    //         if(searchFlag!==1){
    //             if(page == 1){
    //                 // initializePriceAdjustment()
    //                 let dataProps = {
    //                     roleId: cookie.load('role'),
    //                     shopList: cookie.load('shopList'),
    //                     shop_name: props.history.location.pathname.split('/')[2],
    //                     activity_id: props.location.state.activity_id,
    //                     plan_id: props.location.state.plan_id,
    //                     page_size:pageSize,
    //                     page_no:page,
    //                     search_filter:null,
    //                 }
    //                 searchGoods2(dataProps)
    //             }else{
    //                 setPage(1)
    //             }
    //         }
    //     }
    // }, [pageSize])

    useEffect(()=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: props.location.state.activity_id,
            plan_id: props.location.state.plan_id,
            page_size:pageSize,
            page_no:page,
            search_filter:searchFilter,
            table_filter:null,
        }
        // initializePriceAdjustment(props.location.state.activity_id, props.location.state.plan_id)
        searchGoods2(dataProps)
    },[])

    useEffect(()=>{
        setActivityId(props.location.state.activity_id);
        setPlanId(props.location.state.plan_id);
        initializeQueryFiltering()
        batchSetupInitialization()
        searchGoodsInfoFields()
        initShowConfigWindow(props.location.state.activity_id,props.location.state.plan_id)
    },[])

    useEffect(()=>{
        // setColumns([
        //     {
        //         title: () => (
        //             <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center", marginLeft: "20px" }}>
        //                 <a style={{ textDecoration: "underline" }}>名词解释</a>
        //                 <a style={{ textDecoration: "underline", marginLeft: '50px' }}>查看利益点</a>
        //             </div>
        //         ),
        //         dataIndex: '商品',
        //         align: 'center',
        //         width: "180px",
        //         onHeaderCell: (record, index) => {
        //             return (
        //                 {
        //                     style: {
        //                         fontSize: '15px',
        //                         height: "40px",
        //                         padding: "0px"
        //                     }
        //                 }
        //             )
        //         },
        //         render: (text, record, index) => (
        //             <div style={{ width: "250px", display: 'block' }}>
        //                 <div style={{ width: "250px", display: 'flex' }}>
        //                     <div style={{ width: '140px', textAlign: 'left' }}>
        //                         <span style={{ marginRight: 2 }}>id:</span>
        //                         {/* <span style={{ marginRight: 5 ,cursor:'pointer'}} onClick={copyhander} >{record['goods_info']['商品ID']}</span> */}
        //                         <CopyToClipboard text={record['goods_info']['商品ID']} onCopy={() => message.success('复制成功')}>
        //                             <span style={{marginRight: 5 ,cursor:'pointer'}}>{record['goods_info']['商品ID']}</span>
        //                         </CopyToClipboard>
        //                     </div>
        //                     <div>
        //                         <span style={{ marginRight: 2 }}>SPU:</span>
        //                         {/* <span style={{cursor:'pointer'}} onClick={copyhander} >{record['goods_info']['SPU']}</span> */}
        //                         <CopyToClipboard text={record['goods_info']['SPU']} onCopy={() => message.success('复制成功')}>
        //                             <span style={{cursor:'pointer'}}>{record['goods_info']['SPU']}</span>
        //                         </CopyToClipboard>
        //                     </div>
        //                 </div>
        //                 <div style={{ width: "250px", display: 'flex' }}>
        //                     <div style={{ width: '140px', textAlign: 'left' }}>
        //                         <span style={{ marginRight: 2 }}>吊牌价:</span>
        //                         <span style={{ marginRight: 5 }}>{record['goods_info']['吊牌价']}</span>
        //                     </div>
        //                     <Popover trigger="click" content={showMoreInfo(moreData['show_info'])} placement="bottom">
        //                         <a style={{marginRight:"60px"}} onClick={()=>{
        //                             setMoreData({
        //                                 "goods_info_title": [],
        //                                 "goods_info": {
        //                                     "pic_url": "",
        //                                     "吊牌价": '',
        //                                     "goods_id": "",
        //                                     "spu": ""
        //                                 },
        //                                 "is_confirmed": 0,
        //                                 "is_add_plan": 0,
        //                                 "explain": "",
        //                                 "price_show_title": [
        //                                     "活动价",
        //                                     "到手价",
        //                                     "到手折扣",
        //                                     "到手差价",
        //                                     "极限到手",
        //                                     "极限折扣",
        //                                     "极限差价",
        //                                     "单件折扣",
        //                                     "商品券",
        //                                     "专区"
        //                                 ],
        //                                 "overview": {
        //                                     "专区": "",
        //                                     "到手价": "",
        //                                     "到手折扣": "",
        //                                     "到手差价": "",
        //                                     "活动价": "",
        //                                     "单件折扣": "",
        //                                     "极限到手": "",
        //                                     "极限折扣": "",
        //                                     "极限差价": "",
        //                                     "used_coupons": []
        //                                 },
        //                                 "partitions_data": [],
        //                                 "show_info":[],
        //                                 "belong_partition": [],
        //                                 "coupons_list": [],
        //                                 "coupons_checked": [],
        //                                 "tag": [],
        //                             })
        //                             let dataProps = {
        //                                 roleId: cookie.load('role'),
        //                                 shopList: cookie.load('shopList'),
        //                                 shop_name: props.history.location.pathname.split('/')[2],
        //                                 activity_id: activityId,
        //                                 plan_id: planId,
        //                                 goods_only_flag:record.goods_only_flag,
        //                             }
        //                             setLoading(true)
        //                             NbAxios(dataProps, "POST", servicePath.searchSingleItemInformation).then(res => {
        //                                 setLoading(false)
        //                                 if (res.success) {
        //                                     if(res.data.length == 0){
        //                                         message.warning('商品无更多信息')
        //                                     }else{
        //                                         setMoreData(res.data[0]);
        //                                     }
        //                                     // setOpenCoupons(res.data[0]['coupons_checked'])
        //                                     // setCheckedCoupons(res.data[0]['coupons_checked'])
        //                                     // let moreSelectedRowKey = []
        //                                     // res.data[0]['partitions_data'].map(item=>{
        //                                     //     if(item.is_checked == 1){
        //                                     //         moreSelectedRowKey.push(item._id)
        //                                     //     }
        //                                     // })
        //                                     // setMoreSelectedRowKeys(moreSelectedRowKey)
        //                                     // // setIsLookMore(true)
        //                                 } else {
        //                                     message.warn(res.errorMsg)
        //                                 }
        //                             }).catch(err => {
        //                                 setLoading(false)
        //                                 message.warn('商品状态-确认价格出错,请联系IT')
        //                             })
        //                         }}>更多</a>
        //                     </Popover>
        //                 </div>
        //                 <div style={{ display: 'flex' }}>
        //                     <Popover content={imageLookContent(record['goods_info']['pic_url'])} placement="bottom">
        //                         <EyeOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
        //                     </Popover>
        //                     <div style={{display:'flex'}}>
        //                         {
        //                             record.overview.used_coupons.map(item => {
        //                                 return <div style={{border:`1px solid ${item['logo_color']}`, color:`${item['logo_color']}`, backgroundColor:`${item['logo_color'].substring(0,7) + '19'}`, textAlign:'center', borderRadius:"3px", padding:"0 3px", fontSize:"10px", height:"20.84px", marginRight:"1px"}}>{item.coupon_short_name}</div>
        //                             })
        //                         }
        //                     </div>
        //                 </div>
        //             </div>
        //         )
        //     },
        //     {
        //         title: () => (
        //             <span style={{ width: "100%", display: 'flex', justifyContent: 'space-around', height: "40px", lineHeight: "40px" }}>
        //                 {
        //                     priceShowTitle.map(item=>{
        //                         return <span style={{ width: "60px" }}>{item}</span>
        //                     })
        //                 }
        //             </span>
        //         ),
        //         dataIndex: '商品',
        //         align: 'center',
        //         width: '500px',
        //         onHeaderCell: (record, index) => {
        //             return (
        //                 {
        //                     style: {
        //                         fontSize: '15px',
        //                         height: "40px",
        //                         padding: "0px"
        //                     }
        //                 }
        //             )
        //         },
        //         onCell: (record, index) => {
        //             return (
        //                 {
        //                     style: {
        //                         fontSize: '15px',
        //                         padding: "0px"
        //                     }
        //                 }
        //             )
        //         },
        //         render: (text, record, index) => (
        //             <div style={{ width: "100%", display: 'flex', justifyContent: 'space-around' }}>
        //                 {
        //                     priceShowTitle.map(item => {
        //                         return <span style={{ width: "60px" }}>{record.overview[item]}</span>
        //                     })
        //                 }
        //             </div>
        //         )
        //     },
        //     {
        //         // title: () => (
        //         //     <div style={{ display: 'flex', justifyContent: "space-around", height: "40px", alignItems: 'center' }}>
        //         //         <Button type="primary" danger size="small" style={{ borderRadius: "3px" }} onClick={()=>{
        //         //             let dataProps = {
        //         //                 roleId: cookie.load('role'),
        //         //                 shopList: cookie.load('shopList'),
        //         //                 shop_name: props.history.location.pathname.split('/')[2],
        //         //                 activity_id: activityId,
        //         //                 plan_id: planId,
        //         //             }
        //         //             setLoading(true)
        //         //             Axios({
        //         //                 method: 'POST',
        //         //                 url: servicePath.triggerSummaryCalculation,
        //         //                 data: dataProps,
        //         //                 headers: {
        //         //                     'authorization': cookie.load('token')
        //         //                 },
        //         //                 // 设置超时时间为3分钟
        //         //                 timeout:180000
        //         //             }).then(res=>{
        //         //                 setLoading(false)
        //         //                 if(res.data.success){
        //         //                     message.success('汇总计算成功')
        //         //                     props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/choicePricing/summary`, 'state': {'plan_id': planId, 'activity_id': activityId} })
        //         //                 }else{
        //         //                     setLoading(false)
        //         //                     if (res.data.errorCode === '10001') {
        //         //                         cookie.remove("id", { path: '/' });
        //         //                         cookie.remove("role", { path: '/' });
        //         //                         cookie.remove("shop", { path: '/' })
        //         //                         cookie.remove("shopName", { path: '/' })
        //         //                         cookie.remove("shopList", { path: '/' })
        //         //                         cookie.remove("name", { path: '/' })
        //         //                         cookie.remove("roleName", { path: '/' })
        //         //                         cookie.remove("email", { path: '/' })
        //         //                         cookie.remove("phone", { path: '/' })
        //         //                         cookie.remove("watermark", { path: '/' })
        //         //                         cookie.remove("flag", { path: '/' })
        //         //                         cookie.remove("token", { path: '/' })
        //         //                         window.location.replace('https://nb20.bmcsoft.cn/')
        //         //                     }else{
        //         //                         message.error(res.data['errorMsg'])
        //         //                     }
        //         //                 }
        //         //             }).catch(err => {
        //         //                 setLoading(false)
        //         //                 message.warn('触发汇总计算出错,请联系IT')
        //         //             })
        //         //         }}>结束</Button>
        //         //         <Button size="small" style={{ borderRadius: "3px", border: '1px solid #C8EAFF', color: "#2A82E4" }}>操作日志</Button>
                        
        //         //     </div>
        //         // ),
        //         title:'商品',
        //         dataIndex: '商品',
        //         align: 'center',
        //         width: '200px',
        //         onHeaderCell: (record, index) => {
        //             return (
        //                 {
        //                     style: {
        //                         fontSize: '15px',
        //                         height: "40px",
        //                         padding: "0px"
        //                     }
        //                 }
        //             )
        //         },
        //         onCell: (record, index) => {
        //             return (
        //                 {
        //                     style: {
        //                         fontSize: '15px',
        //                         padding: "0px"
        //                     }
        //                 }
        //             )
        //         },
        //         render: (text, record, index) => (
        //             <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', paddingLeft: "10px", paddingRight: "10px" }}>
        //                 <div style={{ display: "flex" }}>
        //                     <div>
        //                         <div>
        //                             <a style={{ textDecoration: "underline" }} onClick={() =>{
        //                                 setCheckChange(false)
        //                                 setGoodsOnlyFlag(record.goods_only_flag)
        //                                 searchSingleItemInformation(record.goods_only_flag)
        //                             }}>查看更多</a>
        //                         </div>
        //                         <div>
        //                             <a style={{ textDecoration: "underline" }} onClick={()=>{
        //                                 updatePlanForm.setFieldsValue({ 'explain': record.explain,'goods_only_flag':record.goods_only_flag })
        //                                 setIsEdting(true)
        //                             }}>编辑说明</a>
        //                         </div>
        //                     </div>
        //                     <div style={{ marginLeft: "10px" }}>
        //                         <div style={{ display: "flex", flexWrap:'wrap'}}>
        //                             {
        //                                 record.tag.map(item => {
        //                                     return <div style={{ borderRadius: "5px", color: "#fff", backgroundColor: `${item.color}`, fontSize: "12px", padding: "2px", marginRight: "2px" }}>{item.shord_name}</div>
        //                                 })
        //                             }
        //                         </div>
        //                         <div style={{ width: "100px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", wordBreak: "break-all" }}>
        //                             <Tooltip title={record.explain} placement="topLeft">{record.explain}</Tooltip>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div>
        //                     <div>
        //                        {
        //                             record.is_confirmed == 0 ? <Popconfirm
        //                                 title="你确定要确认吗？"
        //                                 okText="确定"
        //                                 cancelText="取消"
        //                                 onConfirm={() => goodsStatusPriceConfirm(record.goods_only_flag, 1)}
        //                                 onCancel={() => { }}
        //                                 placement="bottom"
        //                             >
        //                                 <a style={{ color: "red", textDecoration: "underline" }}>待确认</a>
        //                             </Popconfirm> : <Popconfirm
        //                                 title="你确定取消确认吗？"
        //                                 okText="确定"
        //                                 cancelText="取消"
        //                                 onConfirm={() =>goodsStatusPriceConfirm(record.goods_only_flag, 0)}
        //                                 onCancel={() => { }}
        //                                 placement="bottom"
        //                             >
        //                                 <a style={{ textDecoration: "underline" }}>已确认</a>
        //                             </Popconfirm>
        //                        }
        //                     </div>
        //                     <div>
        //                         {
        //                             record.is_add_plan == 0 ? <Popconfirm
        //                                 title="你确定要参加计划吗？"
        //                                 okText="确定"
        //                                 cancelText="取消"
        //                                 onConfirm={() => goodStatusAddIntoPlan(record.goods_only_flag, 1)}
        //                                 onCancel={() => { }}
        //                                 placement="bottom"
        //                             >
        //                                 <a style={{ color: "red", textDecoration: "underline" }}>加入计划</a>
        //                             </Popconfirm>: <Popconfirm
        //                                 title="你确定要移除计划吗？"
        //                                 okText="确定"
        //                                 cancelText="取消"
        //                                 onConfirm={() => goodStatusAddIntoPlan(record.goods_only_flag, 0)}
        //                                 onCancel={() => { }}
        //                                 placement="bottom"
        //                             >
        //                                 <a style={{textDecoration: "underline" }}>移出计划</a>
        //                             </Popconfirm>
        //                         }
                               
        //                     </div>
        //                 </div>
        //             </div>
        //         )
        //     }
        // ])
        setColumns([
            ...columnsData.left.map(item=>{
                return {
                        title: item.title,
                        dataIndex: item.title,
                        key: item.title,
                        align: "center",
                        fixed:'left',
                        filters:item.filters.length == 0?'':item.filters,
                        // filterMode: 'tree',
                        filterSearch: true,
                        // onHeaderCell: (record, index) => {
                        //     return (
                        //         {
                        //             style: {
                        //                 fontSize: '15px',
                        //                 height: "40px",
                        //                 padding: "0px"
                        //             }
                        //         }
                        //     )
                        // },
                        onCell: (record, index) => {
                            return (
                                {
                                    style: {
                                        fontSize: '10px',
                                        padding: "0px"
                                    }
                                }
                            )
                        },
                        // ...getColumnSearchProps(item.title),
                        onFilter: (value, record) => record[item.title] == value,
                        render:(text, record, index)=>(
                            <CopyToClipboard text={text} onCopy={() => message.success('复制成功')}>
                                <span style={{marginRight: 5 ,cursor:'pointer'}}>{text}</span>
                            </CopyToClipboard>
                        )
                    }
        }),
        ...columnsData.mid.map(item=>{
            return {
                title: item.title,
                dataIndex: item.title,
                key: item.title,
                align: "center",
                filters:item.filters.length == 0?'':item.filters,
                // filterMode: 'tree',
                filterSearch: true,
                // onHeaderCell: (record, index) => {
                //     return (
                //         {
                //             style: {
                //                 fontSize: '15px',
                //                 height: "40px",
                //                 padding: "0px"
                //             }
                //         }
                //     )
                // },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '10px',
                                padding: "0px"
                            }
                        }
                    )
                },
                onFilter: (value, record) => record[item.title] == value,
            }
        }),
        ...columnsData.right.map(item=>{
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                fixed:'right',
                // onHeaderCell: (record, index) => {
                //     return (
                //         {
                //             style: {
                //                 fontSize: '15px',
                //                 height: "40px",
                //                 padding: "0px"
                //             }
                //         }
                //     )
                // },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '10px',
                                padding: "0px"
                            }
                        }
                    )
                },
                render: (text, record, index) => {
                    return item == '操作' ?  <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', paddingLeft: "10px", paddingRight: "10px" }}>
                    <div style={{ display: "flex" }}>
                        <div>
                            <div>
                                <a style={{ textDecoration: "underline" }} onClick={() =>{
                                    setCheckChange(false)
                                    setGoodsOnlyFlag(record.goods_only_flag)
                                    searchSingleItemInformation(record.goods_only_flag)
                                }}>查看</a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                           {
                                record.is_confirmed == 0 ? <Popconfirm
                                    title="你确定要确认吗？"
                                    okText="确定"
                                    cancelText="取消"
                                    onConfirm={() => goodsStatusPriceConfirm(record, 1)}
                                    onCancel={() => { }}
                                    placement="bottom"
                                >
                                    <a style={{ color: "red", textDecoration: "underline" }}>待确认</a>
                                </Popconfirm> : <Popconfirm
                                    title="你确定取消确认吗？"
                                    okText="确定"
                                    cancelText="取消"
                                    onConfirm={() =>goodsStatusPriceConfirm(record, 0)}
                                    onCancel={() => { }}
                                    placement="bottom"
                                >
                                    <a style={{ textDecoration: "underline" }}>已确认</a>
                                </Popconfirm>
                           }
                        </div>
                        <div>
                            {
                                record.is_add_plan == 0 ? <Popconfirm
                                    title="你确定要参加计划吗？"
                                    okText="确定"
                                    cancelText="取消"
                                    onConfirm={() => goodStatusAddIntoPlan(record, 1)}
                                    onCancel={() => { }}
                                    placement="bottom"
                                >
                                    <a style={{ color: "red", textDecoration: "underline" }}>加入计划</a>
                                </Popconfirm>: <Popconfirm
                                    title="你确定要移除计划吗？"
                                    okText="确定"
                                    cancelText="取消"
                                    onConfirm={() => goodStatusAddIntoPlan(record, 0)}
                                    onCancel={() => { }}
                                    placement="bottom"
                                >
                                    <a style={{textDecoration: "underline" }}>移出计划</a>
                                </Popconfirm>
                            }
                           
                        </div>
                    </div>
                </div>: text
                }
            }
        }),
    ])
    },[activityId, planId, priceShowTitle, selectedRowKeys,moreData,columnsData])

    // 批量设置初始化接口
    const batchSetupInitialization = ()=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: props.location.state.activity_id,
            plan_id: props.location.state.plan_id,
        }
        NbAxios(dataProps, "POST", servicePath.batchSetupInitialization).then(res => {
            if (res.success) {
                setBatchConfiguration(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('批量设置初始化接口出错,请联系IT')
        })
    }

    // 调价页面 -初始化查询筛选商品
    const initializeQueryFiltering = ()=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: props.location.state.activity_id,
            plan_id: props.location.state.plan_id,
        }
        NbAxios(dataProps, "POST", servicePath.initializeQueryFiltering).then(res => {
            if (res.success) {
                setPriceShowTitle(res.data['price_show_title']);
                setDownZoneList(res.data['drop_down_box_data']['专区']);
                setProductLineList(res.data['drop_down_box_data']['产品线']);
                setCategoryList(res.data['drop_down_box_data']['品类']);
                setShelfStatusList(res.data['drop_down_box_data']['在架状态']);
                setSeasonList(res.data['drop_down_box_data']['款号季节']);
                setYearList(res.data['drop_down_box_data']['款号年份']);
                setInventoryPlatformList(res.data['drop_down_box_data']['库存平台']);
                setLabelList(res.data['drop_down_box_data']['标签']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('初始化查询筛选商品出错,请联系IT')
        })
    }

    // 初始化调价页面
    const initializePriceAdjustment = ()=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: props.location.state.activity_id,
            plan_id: props.location.state.plan_id,
            page_size:pageSize,
            page_no:page
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.initializePriceAdjustment).then(res => {
            setLoading(false)
            if (res.success) {
                setDate(res.data['data']);
                setTotal(res.data['total'])
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('初始化调价页面出错,请联系IT')
        })
    }

    // 商品状态-确认价格
    const goodsStatusPriceConfirm = (record, is_confirmed)=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId,
            goods_only_flag:record.goods_only_flag,
            is_confirmed:is_confirmed
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.goodsStatusPriceConfirm).then(res => {
            setLoading(false)
            if (res.success) {
                message.success('修改成功')
            //    searchGoods2(dataProps)
                record.is_confirmed = is_confirmed
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('商品状态-确认价格出错,请联系IT')
        })
    }

    // 商品状态-加入计划
    const goodStatusAddIntoPlan = (record, is_add_plan)=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId,
            goods_only_flag:record.goods_only_flag,
            is_add_plan:is_add_plan
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.goodStatusAddIntoPlan).then(res => {
            setLoading(false)
            if (res.success) {
                message.success('修改成功')
                // batchSettingQueryAllGoods(goods_only_flag)
                // searchGoods2(dataProps)
                record.is_add_plan = is_add_plan
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('商品状态-加入计划出错,请联系IT')
        })
    }

    // 查看更多数据
    const searchSingleItemInformation = (goods_only_flag)=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId,
            goods_only_flag:goods_only_flag,
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchSingleItemInformation).then(res => {
            setLoading(false)
            if (res.success) {
                if(res.data.length == 0){
                    message.warning('商品无更多信息')
                }else{
                    setMoreData(res.data[0]);
                    setOpenCoupons(res.data[0]['coupons_checked'])
                    setCheckedCoupons(res.data[0]['coupons_checked'])
                    let moreSelectedRowKey = []
                    res.data[0]['partitions_data'].map(item=>{
                        if(item.is_checked == 1){
                            moreSelectedRowKey.push(item._id)
                        }
                    })
                    setMoreSelectedRowKeys(moreSelectedRowKey)
                    setIsLookMore(true)
                }
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查看更多数据出错,请联系IT')
        })
    }
    // 查询商品
    const searchGoods = (selectedRowKeys)=>{
        if((minStock != '' || maxStock != '' || chooseShelfStatus != '') && choosePlatform == ''){
            message.warn('请选择平台名称')
        }else if(choosePlatform != '' && minStock == '' && maxStock == ''){
            message.warn('请填写上限/下限任意一个')
        }else{
            let search_filter = {
                "is_confirmed": isConfimed, 
                "is_add_plan": isSearchAddPlan,  
                "商品ID" :goodsId,  
                "SPU" : spu, 
                "partitions_data": chooseDownZone,
                "stock" : {
                    "inventory_up" : maxStock,     
                    "inventory_down" : minStock,   
                    "platform_name":choosePlatform,      
                    "goods_status":chooseShelfStatus,        
                },        
                "info": chooseValueList,   
                "极限差价" :[minLimitSpread,maxLimitSpread],
                "底线折扣":[minBottomLine,maxBottomLine],
                "价格带":[minPriceBand,maxPriceBand],
                "与沿用活动价差":[minActivitySpread,maxActivitySpread],
                'goods_filter_list':goodsFilterList
            }
            let dataProps = {
                roleId: cookie.load('role'),
                shopList: cookie.load('shopList'),
                shop_name: props.history.location.pathname.split('/')[2],
                activity_id: activityId,
                plan_id: planId,
                search_filter:search_filter,
                goods_only_flag_checked:selectedRowKeys
            }
            searchGoods2(dataProps)
        }
        
    }

    // 批量设置后查询
    const batchSettingQueryAllGoods = (goodsOnlyFlagChecked)=>{
        let goodsOnlyFlag = []
        date.map(item=>{
            goodsOnlyFlag.push(item.goods_only_flag)
        })
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId,
            // 选中的
            goods_only_flag_checked:goodsOnlyFlagChecked,
            // 商品的所有唯一标记
            goods_only_flag:goodsOnlyFlag
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.batchSettingQueryAllGoods).then(res => {
            setLoading(false)
            if (res.success) {
                setDate(res.data['goods'])
                setSelectedRowKeys(res.data['goods_checked_flag']);
                setIsSearch(false)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询商品出错,请联系IT')
        })
        
    }
    // 查询商品基础信息字段
    const searchGoodsInfoFields = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
        }
        NbAxios(dataProps, "POST", servicePath.searchGoodsInfoFields).then(res => {
            if (res.success) {
                setGoodsFilterAddFields(res.data)
            }
        })
    }
    // 显示配置初始化的接口
    const initShowConfigWindow = (activityId,planId) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId,
        } 
        NbAxios(dataProps, "POST", servicePath.initShowConfigWindow).then(res => {
            if (res.success) {
                setTabelHeaderData(res.data.show_config)
                setCheckTableHeader(res.data.checkTableHeader)
                setTableHeaderDataSource(res.data.config_list)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('显示配置初始化的接口出错,请联系IT')
        })
    }
    // 显示配置提交的接口
    const submitShowConfigWindow = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.submitShowConfigWindow).then(res => {
            setLoading(false)
            if (res.success) {
                message.success('提交成功')
                setTableHeaderModel(false)
                let search_filter = {
                    "is_confirmed": isConfimed, 
                    "is_add_plan": isSearchAddPlan,  
                    "商品ID" :goodsId,  
                    "SPU" : spu, 
                    "partitions_data": chooseDownZone,
                    "stock" : {
                        "inventory_up" : maxStock,     
                        "inventory_down" : minStock,   
                        "platform_name":choosePlatform,      
                        "goods_status":chooseShelfStatus,        
                    },        
                    "info": chooseValueList,   
                    "极限差价" :[minLimitSpread,maxLimitSpread],
                    "底线折扣":[minBottomLine,maxBottomLine],
                    "价格带":[minPriceBand,maxPriceBand],
                    "与沿用活动价差":[minActivitySpread,maxActivitySpread],
                    'goods_filter_list':goodsFilterList
                }
                setSearchFilter(search_filter)
                let dataProps = {
                    roleId: cookie.load('role'),
                    shopList: cookie.load('shopList'),
                    shop_name: props.history.location.pathname.split('/')[2],
                    activity_id: activityId,
                    plan_id: planId,
                    search_filter:search_filter,
                    goods_only_flag_checked:selectedRowKeys
                }
                searchGoods2(dataProps)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('显示配置提交的接口出错,请联系IT')
        })
    }

    //调价页面表格查询接口
    const searchGoods2 = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchGoods2).then(res => {
            setLoading(false)
            if (res.success) {
                setColumnsData(res.data)
                setDate(res.data.goods)
                setIsSearch(false)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('调价页面表格查询接口出错,请联系IT')
        })
    }
    return (
        <div className={style.externalContent}>
            {/* marginTop: 5  */}
            <div style={{ padding: "40px" }}>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div>
                        <Button type="primary" size="small" style={{ borderRadius: "3px" ,marginRight:'10px'}} onClick={() => setIsSearch(true)}>查询筛选</Button>
                        <Button disabled={selectedRowKeys.length != 0 ? false : true} size="small" style={{ borderRadius: "3px", border: '1px solid #C8EAFF', color: "#2A82E4" }} onClick={() => setIsBulkOperations(true)}>批量操作</Button>
                    </div>
                    <div>
                        <Button size='small' style={{marginBottom:'10px'}} type='primary' onClick={()=>setTableHeaderModel(true)}>显示配置</Button>
                        <Popover placement='bottom' content={moreActions} trigger="click">
                            <Button size='small' style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center', margin: '0px 0px 0px 20px' }}
                            >更多</Button>
                        </Popover>
                    </div>
                </div>
                <Table columns={columns} bordered dataSource={date}
                    rowSelection={rowSelection} rowKey={'goods_only_flag'}
                    scroll={{ y: 'calc(90vh - 150px)',x:150*columns.length}}
                    pagination={searchFlag == 1 ? {position: ["bottomCenter"], showSizeChanger:true, pageSize:pageSize, onShowSizeChange:(current, pageSize)=>{setPageSize(pageSize)} } : paginationProps}
                    size="small"
                    // onChange={handleTableChange}
                    loading={loading}
                />
                <div style={{display:'flex',justifyContent:'flex-end'}}>
                    <Button type="primary" danger size="small" style={{ borderRadius: "3px" }} 
                    onClick={()=>{
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                activity_id: activityId,
                                plan_id: planId,
                            }
                            setLoading(true)
                            Axios({
                                method: 'POST',
                                url: servicePath.triggerSummaryCalculation,
                                data: dataProps,
                                headers: {
                                    'authorization': cookie.load('token')
                                },
                                // 设置超时时间为3分钟
                                timeout:180000
                            }).then(res=>{
                                setLoading(false)
                                if(res.data.success){
                                    message.success('汇总计算成功')
                                    props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/choicePricing/summary`, 'state': {'plan_id': planId, 'activity_id': activityId} })
                                }else{
                                    setLoading(false)
                                    if (res.data.errorCode === '10001') {
                                        cookie.remove("id", { path: '/' });
                                        cookie.remove("role", { path: '/' });
                                        cookie.remove("shop", { path: '/' })
                                        cookie.remove("shopName", { path: '/' })
                                        cookie.remove("shopList", { path: '/' })
                                        cookie.remove("name", { path: '/' })
                                        cookie.remove("roleName", { path: '/' })
                                        cookie.remove("email", { path: '/' })
                                        cookie.remove("phone", { path: '/' })
                                        cookie.remove("watermark", { path: '/' })
                                        cookie.remove("flag", { path: '/' })
                                        cookie.remove("token", { path: '/' })
                                        window.location.replace('https://nb20.bmcsoft.cn/')
                                    }else{
                                        message.error(res.data['errorMsg'])
                                    }
                                }
                            }).catch(err => {
                                setLoading(false)
                                message.warn('触发汇总计算出错,请联系IT')
                            })
                        }}>调价完成</Button>
                </div>
                
            </div>
            {/* <Drawer
                title="查询筛选"
                visible={isSearch}
                onClose={() => {
                    setIsSearch(false)
                }}
                bodyStyle={{ padding: '0px' }}
                width={'650px'}
                footer={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button danger type="primary" disabled={loading} onClick={()=>{
                        initializePriceAdjustment()
                        setSelectedRowKeys([])
                        setIsSearch(false)
                        setSearchFlag(0)
                        setIsConfimed(-1)
                        setIsSearchAddPlan(-1)
                        setGoodsId('')
                        setSpu('')
                        setChooseDownZone([])
                        setChoosePlatform('')
                        setMinStock('')
                        setMaxStock('')
                        setChooseShelfStatus('')
                        setChooseCategory([])
                        setChooseSeason([])
                        setChooseYear([])
                        setChooseLabel([])
                        setChooseProductLine([])
                        setChooseValueList([])
                        setMinLimitSpread('')
                        setMaxLimitSpread('')
                        setMinBottomLine('')
                        setMaxBottomLine('')
                        setMinPriceBand('')
                        setMaxPriceBand('')
                        setMinActivitySpread('')
                        setMaxActivitySpread('')
                    }}>重置</Button>
                    <Button disabled={searchFlag == 0 || loading ? true : false} type="primary" style={{ marginLeft: 5 }} onClick={()=>searchGoods(selectedRowKeys)}>筛选当前</Button>
                    <Button disabled={loading} style={{ marginLeft: 5 }} onClick={()=>searchGoods([])}>筛选全部</Button>
                </div>}
                // footer={<div style={{display: 'flex', justifyContent: 'flex-end'}}>
                //     <Button type="primary" >筛选全部</Button>
                //     <Button style={{ marginLeft: 5 }}>筛选当前</Button>
                // </div>}
            >
                <div style={{ padding: "10px", marginLeft: '26px', marginRight: "26px", fontSize: '14px' }}>
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button type="primary" danger size="small" onClick={()=>{
                            setIsConfimed(-1)
                            setIsSearchAddPlan(-1)
                            setGoodsId('')
                            setSpu('')
                            setChooseDownZone([])
                            setChoosePlatform('')
                            setMinStock('')
                            setMaxStock('')
                            setChooseShelfStatus('')
                        }}>清空</Button>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            价格确认:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Radio.Group value={isConfimed} onChange={(e)=>{
                                setIsConfimed(e.target.value)
                            }}>
                                <Radio value={1}>{"已确认"}</Radio>
                                <Radio value={0}>{"待确认"}</Radio>
                                <Radio value={-1} ref={searchRef}>{"不选"}</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            参加活动:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Radio.Group value={isSearchAddPlan} onChange={(e)=>{
                                setIsSearchAddPlan(e.target.value)
                            }}>
                                <Radio value={1}>{"已加入"}</Radio>
                                <Radio value={0}>{"已移出"}</Radio>
                                <Radio value={-1}>{"不选"}</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            商品ID
                            <Tooltip title="多个商品ID按空格分割"><QuestionCircleOutlined style={{ marginLeft: '3px' }} /></Tooltip>:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "400px" , padding:"0px"}} placeholder="请输入" value={goodsId} onChange={(e)=>{
                                let reg = new RegExp("^[0-9 ]*$");
                                if (reg.test(e.target.value)) {
                                    setGoodsId(e.target.value)
                                } else {
                                    message.error("只能输入数字以及空格")
                                }
                            }}/>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            SPU:
                            <Tooltip title="多个SPU按空格分割"><QuestionCircleOutlined style={{ marginLeft: '3px' }} /></Tooltip>:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "400px", padding:"0px" }} placeholder="请输入" value={spu} onChange={(e)=>{
                                let reg = new RegExp("^[A-Za-z0-9 ]*$");
                                if (reg.test(e.target.value)) {
                                    setSpu(e.target.value)
                                } else {
                                    message.error("只能输入数字,字母以及空格")
                                }
                            }}/>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            划定专区:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Select maxTagCount="responsive" mode="multiple" value={chooseDownZone} style={{ width: "400px" }} size="small" placeholder="请选择" onChange={(value)=>setChooseDownZone(value)}>
                               {
                                    downZoneList.map(item=>{
                                        return <Option value={item}>{item}</Option>
                                    })
                               }
                            </Select>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            库存:
                            <Tooltip title="若要筛选库存，则上下限必填其一"><QuestionCircleOutlined style={{ marginLeft: '3px' }} /></Tooltip>:
                        </div>
                        <div style={{ display: 'flex', alignItems:"center" }}>
                            <Input size="small" style={{ height: "24px", width: "50px", marginRight: "8px", padding:'0px' }} placeholder="请输入" value={minStock} onChange={(e)=>{
                                let reg = new RegExp("^[0-9]*$");
                                if (reg.test(e.target.value)) {
                                    setMinStock(e.target.value)
                                } else {
                                    message.error("只能输入数字")
                                }
                            }}/>
                            <div type="secondary" style={{ lineHeight: "24px" }}>-</div>
                            <Input size="small" style={{ height: "24px", width: "50px", marginLeft: "8px", padding:'0px', marginRight: "12px" }} placeholder="请输入" value={maxStock} onChange={(e)=>{
                                let reg = new RegExp("^[0-9]*$");
                                if (reg.test(e.target.value)) {
                                    setMaxStock(e.target.value)
                                } else {
                                    message.error("只能输入数字")
                                }
                            }} />
                            <Select style={{ width: "100px" }} size="small" placeholder="请选择" value={choosePlatform == ''?null:choosePlatform} onChange={(value)=>setChoosePlatform(value)}>
                                {
                                    inventoryPlatformList.map(item=>{
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                            <Text style={{marginLeft:"5px", marginRight:"5px"}}>{"在架状态"}</Text>
                            <Select style={{ width: "100px" }} size="small" placeholder="请选择" value={chooseShelfStatus} onChange={(value)=>setChooseShelfStatus(value)}>
                                {
                                    shelfStatusList.map(item=>{
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "10px", marginBottom: "10px" }}></div>
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button type="primary" danger size="small" onClick={()=>{
                            // setChooseCategory([])
                            // setChooseSeason([])
                            // setChooseYear([])
                            // setChooseLabel([])
                            // setChooseProductLine([])
                            // setChooseValueList([])
                            let goodsFilterAddFieldsObj = [...goodsFilterAddFields]
                            goodsFilterAddFieldsObj.map(item => {
                                item['value'] = []
                            })
                            setGoodsFilterAddFields(goodsFilterAddFieldsObj)
                        }}>清空</Button>
                    </div>
                    <div style={{ display: 'flex',flexWrap:'wrap' }}>
                        {
                            goodsFilterAddFields.map((item, index) => {
                                return <div style={{ display: 'flex' }}>
                                    <Text type="secondary" strong style={{ width: "80px", textAlign: "right", margin: '10px', fontSize: '10px' }}>{item.title}</Text>
                                    <Select style={{ width: "250px", marginRight: '30px' }} maxTagCount="responsive" mode="multiple" placeholder={`请下拉选择${item.title}`} value={item.value} onChange={(value) => {
                                        let obj = [...goodsFilterAddFields]
                                        obj[index]['value'] = value
                                        setGoodsFilterAddFields(obj)
                                    }}>
                                        {
                                            item.select.map(item => {
                                                return <Option value={item}>{item}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            })
                        }
                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'flex-end', marginLeft: "80px" }}>
                            <Button size="small" style={{ borderColor: "#0091FF", borderRadius: "3px" }} onClick={()=>{
                                // if(goodsFilterAddFields.length == 0){
                                //     message.warn('请填写合理的产品线、季节、上市年份、品类、标签')
                                // }else{
                                //     let map_fields_value = {
                                //         '产品线':chooseProductLine,
                                //         '季节':chooseSeason,
                                //         '上市年份':chooseYear,
                                //         '三级品类':chooseCategory,
                                //         '标签':chooseLabel,
                                //     }
                                //     let dataProps = {
                                //         roleId: cookie.load('role'),
                                //         shopList: cookie.load('shopList'),
                                //         shop_name: props.history.location.pathname.split('/')[2],
                                //         map_fields_value: map_fields_value,
                                //     }
                                //     NbAxios(dataProps, "POST", servicePath.splitCartesianProduct).then(res => {
                                //         if (res.success) {
                                //             let obj = [...chooseValueList]
                                //             res.data.map(item=>{
                                //                 obj.push(item)
                                //             })
                                //             setChooseValueList(obj)
                                //             setChooseCategory([])
                                //             setChooseSeason([])
                                //             setChooseYear([])
                                //             setChooseLabel([])
                                //             setChooseProductLine([])
                                //         }
                                //     })
                                // }
                                // 判断有没有输入筛选条件
                                let flag = 0
                                goodsFilterAddFields.map(item => {
                                    if (item.value.length != 0) {
                                        flag = 1
                                    }
                                })
                                if (flag == 1) {
                                    let map_fields_value = {}
                                    goodsFilterAddFields.map(item => {
                                        map_fields_value[item.id] = item.value
                                    })
                                    console.log(map_fields_value);
                                    let dataProps = {
                                        roleId: cookie.load('role'),
                                        shopList: cookie.load('shopList'),
                                        shop_name: props.history.location.pathname.split('/')[2],
                                        map_fields_value: map_fields_value,
                                    }
                                    console.log("入参", dataProps);
                                    NbAxios(dataProps, "POST", servicePath.splitCartesianProduct).then(res => {
                                        if (res.success) {
                                            let obj = [...chooseValueList]
                                            res.data.map(item=>{
                                                obj.push(item)
                                            })
                                            console.log(obj);
                                            setChooseValueList(obj)
                                            let goodsFilterAddFieldsObj = [...goodsFilterAddFields]
                                            goodsFilterAddFieldsObj.map(item => {
                                                item['value'] = []
                                            })
                                            setGoodsFilterAddFields(goodsFilterAddFieldsObj)
                                            console.log(goodsFilterAddFieldsObj);
                                        }
                                    })
                                } else {
                                    message.warning("请先选择筛选条件")
                                }
                                
                            }}>添加条件</Button>
                        </div>
                    </div>
                    <div style={{ width: "100%", maxHeight: "140px", borderRadius: "3px", border: "1px solid rgba(166, 166, 166, 1)", marginTop: '7px', display:'flex', flexWrap:'wrap',  alignContent: 'flex-start', overflow: 'auto'  }}>
                        {
                            chooseValueList.map((item, index) => {
                                return <div style={{ border: "1px solid #ccc", padding: "5px", height: "24px", display: 'flex', alignItems: "center", margin:"5px" }}>
                                    <div >{item.value}</div>
                                    <CloseOutlined onClick={() => {
                                        let obj = [...chooseValueList]
                                        obj.splice(index, 1)
                                        setChooseValueList(obj)
                                    }} />
                                </div>
                            })
                        }
                    </div>
                    <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "10px", marginBottom: "10px" }}></div>
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button type="primary" danger size="small" onClick={()=>{
                            setMinLimitSpread('')
                            setMaxLimitSpread('')
                            setMinBottomLine('')
                            setMaxBottomLine('')
                            setMinPriceBand('')
                            setMaxPriceBand('')
                            setMinActivitySpread('')
                            setMaxActivitySpread('')
                        }}>清空</Button>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            极限差价:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "80px", marginRight: "8px", padding:"0px" }} placeholder="请输入"  value={minLimitSpread} onChange={(e)=>setMinLimitSpread(e.target.value.replace(/[^\d^\.]+/g,''))}/>
                            <div type="secondary" style={{ lineHeight: "24px" }}>-</div>
                            <Input style={{ height: "24px", width: "80px", marginLeft: "8px", marginRight: "12px", padding:"0px" }} placeholder="请输入"  value={maxLimitSpread} onChange={(e)=>setMaxLimitSpread(e.target.value.replace(/[^\d^\.]+/g,''))}/>
                            <Tooltip title="">
                                <QuestionCircleOutlined style={{ lineHeight: "24px" }} />
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            底线折扣:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "80px", marginRight: "8px" , padding:"0px"}} placeholder="请输入" value={minBottomLine} onChange={(e)=>setMinBottomLine(e.target.value.replace(/[^\d^\.]+/g,''))}/>
                            <div type="secondary" style={{ lineHeight: "24px" }}>-</div>
                            <Input style={{ height: "24px", width: "80px", marginLeft: "8px", marginRight: "12px", padding:"0px" }} placeholder="请输入" value={maxBottomLine} onChange={(e)=>setMaxBottomLine(e.target.value.replace(/[^\d^\.]+/g,''))} />
                            <Tooltip title="">
                                <QuestionCircleOutlined style={{ lineHeight: "24px" }} />
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            价格带:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "80px", marginRight: "8px", padding:"0px" }} placeholder="请输入" value={minPriceBand} onChange={(e)=>setMinPriceBand(e.target.value.replace(/[^\d^\.]+/g,''))} />
                            <div type="secondary" style={{ lineHeight: "24px" }}>-</div>
                            <Input style={{ height: "24px", width: "80px", marginLeft: "8px", marginRight: "12px", padding:"0px" }} placeholder="请输入" value={maxPriceBand} onChange={(e)=>setMaxPriceBand(e.target.value.replace(/[^\d^\.]+/g,''))} />
                            <Tooltip title="">
                                <QuestionCircleOutlined style={{ lineHeight: "24px" }} />
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            与沿用活动价差:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "80px", marginRight: "8px", padding:"0px" }} placeholder="请输入" value={minActivitySpread} onChange={(e)=>setMinActivitySpread(e.target.value.replace(/[^\d^\.]+/g,''))} />
                            <div type="secondary" style={{ lineHeight: "24px" }}>-</div>
                            <Input style={{ height: "24px", width: "80px", marginLeft: "8px", marginRight: "12px", padding:"0px" }} placeholder="请输入" value={maxActivitySpread} onChange={(e)=>setMaxActivitySpread(e.target.value.replace(/[^\d^\.]+/g,''))} />
                            <Tooltip title="">
                                <QuestionCircleOutlined style={{ lineHeight: "24px" }} />
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                        <Text type="secondary">此处的筛选用于筛选中的筛选，即在筛选的结果上进一步筛选！</Text>
                    </div>
                </div>
            </Drawer> */}
            <Drawer
                title="查询筛选"
                visible={isSearch}
                onClose={() => {
                    setIsSearch(false)
                }}
                bodyStyle={{ padding: '0px' }}
                width={'650px'}
                footer={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* <Button danger type="primary" disabled={loading} onClick={()=>{
                        initializePriceAdjustment()
                        setSelectedRowKeys([])
                        setIsSearch(false)
                        setSearchFlag(0)
                        setIsConfimed(-1)
                        setIsSearchAddPlan(-1)
                        setGoodsId('')
                        setSpu('')
                        setChooseDownZone([])
                        setChoosePlatform('')
                        setMinStock('')
                        setMaxStock('')
                        setChooseShelfStatus('')
                        setChooseCategory([])
                        setChooseSeason([])
                        setChooseYear([])
                        setChooseLabel([])
                        setChooseProductLine([])
                        setChooseValueList([])
                        setMinLimitSpread('')
                        setMaxLimitSpread('')
                        setMinBottomLine('')
                        setMaxBottomLine('')
                        setMinPriceBand('')
                        setMaxPriceBand('')
                        setMinActivitySpread('')
                        setMaxActivitySpread('')
                    }}>重置</Button> */}
                    <Button disabled={searchFlag == 0 || loading ? true : false}style={{ marginLeft: 5 }} onClick={()=>searchGoods(selectedRowKeys)}>筛选当前</Button>
                    <Button disabled={loading} style={{ marginLeft: 5 }} onClick={()=>searchGoods([])}  type="primary">筛选全部</Button>
                </div>}
                // footer={<div style={{display: 'flex', justifyContent: 'flex-end'}}>
                //     <Button type="primary" >筛选全部</Button>
                //     <Button style={{ marginLeft: 5 }}>筛选当前</Button>
                // </div>}
            >
                <div style={{ padding: "10px", marginLeft: '26px', marginRight: "26px", fontSize: '14px' }}>
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button type="primary" danger size="small" onClick={()=>{
                            setIsConfimed(null)
                            setIsSearchAddPlan(null)
                            setGoodsId('')
                            setSpu('')
                            setChooseDownZone([])
                            setChoosePlatform('')
                            setMinStock('')
                            setMaxStock('')
                            setChooseShelfStatus('')
                            setBaseInfo(null)
                            setGoodsFilterList([])
                            setMinLimitSpread('')
                            setMaxLimitSpread('')
                            setMinBottomLine('')
                            setMaxBottomLine('')
                            setMinPriceBand('')
                            setMaxPriceBand('')
                            setMinActivitySpread('')
                            setMaxActivitySpread('')
                        }}>清空</Button>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            价格确认:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Select value={isConfimed} onChange={(value)=>{
                                setIsConfimed(value)
                            }} style={{width:'135px'}} placeholder='请选择' size='small' allowClear>
                                <Option value = {1}>{"已确认"}</Option>
                                <Option value = {0}>{"待确认"}</Option>
                            </Select>
                        </div>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            参加活动:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Select value={isSearchAddPlan} onChange={(value)=>{
                                setIsSearchAddPlan(value)
                            }} style={{width:'135px'}} placeholder='请选择' size='small' allowClear>
                                <Option value = {1}>{"已加入"}</Option>
                                <Option value = {0}>{"已移除"}</Option>
                            </Select>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            商品ID
                            <Tooltip title="多个商品ID按空格分割"><QuestionCircleOutlined style={{ marginLeft: '3px' }} /></Tooltip>:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "400px", padding:'1px 7px'}} placeholder="请输入" value={goodsId} onChange={(e)=>{
                                let reg = new RegExp("^[0-9 ]*$");
                                if (reg.test(e.target.value)) {
                                    setGoodsId(e.target.value)
                                } else {
                                    message.error("只能输入数字以及空格")
                                }
                            }}/>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            SPU:
                            <Tooltip title="多个SPU按空格分割"><QuestionCircleOutlined style={{ marginLeft: '3px' }} /></Tooltip>:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "400px", padding:'1px 7px' }} placeholder="请输入" value={spu} onChange={(e)=>{
                                let reg = new RegExp("^[A-Za-z0-9 ]*$");
                                if (reg.test(e.target.value)) {
                                    setSpu(e.target.value)
                                } else {
                                    message.error("只能输入数字,字母以及空格")
                                }
                            }}/>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            划定专区:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Select maxTagCount="responsive" mode="multiple" value={chooseDownZone} style={{ width: "400px" }} size="small" placeholder="请选择" onChange={(value)=>setChooseDownZone(value)}>
                               {
                                    downZoneList.map(item=>{
                                        return <Option value={item}>{item}</Option>
                                    })
                               }
                            </Select>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            库存:
                            <Tooltip title="若要筛选库存，则上下限必填其一"><QuestionCircleOutlined style={{ marginLeft: '3px' }} /></Tooltip>:
                        </div>
                        <div style={{ display: 'flex', alignItems:"center" }}>
                            <Input size="small" style={{ height: "24px", width: "60px", marginRight: "8px", padding:'1px 7px' }} placeholder="请输入" value={minStock} onChange={(e)=>{
                                let reg = new RegExp("^[0-9]*$");
                                if (reg.test(e.target.value)) {
                                    setMinStock(e.target.value)
                                } else {
                                    message.error("只能输入数字")
                                }
                            }}/>
                            <div type="secondary" style={{ lineHeight: "24px" }}>-</div>
                            <Input size="small" style={{ height: "24px", width: "60px", marginLeft: "8px", padding:'1px 7px', marginRight: "12px" }} placeholder="请输入" value={maxStock} onChange={(e)=>{
                                let reg = new RegExp("^[0-9]*$");
                                if (reg.test(e.target.value)) {
                                    setMaxStock(e.target.value)
                                } else {
                                    message.error("只能输入数字")
                                }
                            }} />
                            <Select style={{ width: "100px" }} size="small" placeholder="请选择" value={choosePlatform == ''?null:choosePlatform} onChange={(value)=>setChoosePlatform(value)}>
                                {
                                    inventoryPlatformList.map(item=>{
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                            {/* <Text style={{marginLeft:"5px", marginRight:"5px"}}>{"在架状态"}</Text>
                            <Select style={{ width: "100px" }} size="small" placeholder="请选择" value={chooseShelfStatus} onChange={(value)=>setChooseShelfStatus(value)}>
                                {
                                    shelfStatusList.map(item=>{
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select> */}
                        </div>
                    </div>
                    <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "10px", marginBottom: "10px" }}></div>
                    {/* <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button type="primary" danger size="small" onClick={()=>{
                            // setChooseCategory([])
                            // setChooseSeason([])
                            // setChooseYear([])
                            // setChooseLabel([])
                            // setChooseProductLine([])
                            // setChooseValueList([])
                            let goodsFilterAddFieldsObj = [...goodsFilterAddFields]
                            goodsFilterAddFieldsObj.map(item => {
                                item['value'] = []
                            })
                            setGoodsFilterAddFields(goodsFilterAddFieldsObj)
                        }}>清空</Button>
                    </div> */}
                    <div style={{ display: 'grid', marginTop: "5px" ,gridTemplateColumns:'130px 1fr'}}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            基础信息
                        </div>
                        <div style={{ display: 'flex' ,justifyContent:'flex-end',marginRight:'50px'}}>
                            <Select style={{ height: "24px", width: "130px", padding:'1px 7px' }} placeholder='请选择' size='small' value={baseInfo}  onChange={(value)=>{
                                setBaseInfo(value)
                                
                            }} allowClear> 
                            {
                                goodsFilterAddFields.map((item, index) => {
                                    if(item.search_tactics == '下拉筛选'){
                                        return <Option value={item.id}>{item.title}</Option>
                                    }
                                })
                            }
                            </Select>
                            <Button size="small" style={{ borderColor: "#0091FF", borderRadius: "3px" ,marginLeft:'20px'}} onClick={()=>{
                                // 判断有没有选择基础信息
                                if (baseInfo) {
                                    let obj = [...goodsFilterList]
                                    goodsFilterAddFields.map(item=>{
                                        if(item.id == baseInfo){
                                            obj.push(item)
                                        }
                                    })
                                    setGoodsFilterList(obj)
                                    setBaseInfo(null)
                                } else {
                                    message.warning("请先选择基础信息")
                                }
                                
                            }}>添加条件</Button>
                        </div>
                    </div>
                    {goodsFilterList.map((item,index)=>{
                            return <div style={{display: 'grid', marginTop: "5px" ,gridTemplateColumns:'130px 1fr'}}>
                                <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                                    {item.title}
                                </div>
                                <div>
                                    <Select value={item.value} size='small' placeholder={`请选择${item.title}`} allowClear mode='multiple' maxTagCount="responsive"style={{width:'400px'}} onChange={(value)=>{
                                        let obj = [...goodsFilterList]
                                        obj[index]['value'] = value
                                        setGoodsFilterList(obj)
                                    }}>
                                        {item.select.map(obj=>(
                                            <Option value={obj}>{obj}</Option>
                                        ))}
                                    </Select>
                                    <CloseOutlined style={{marginLeft:'5px'}} onClick={()=>{
                                        let obj = [...goodsFilterList]
                                        obj.remove(item)
                                        setGoodsFilterList(obj)
                                    }}/>
                                </div>
                            </div>
                        })}
                    {/* <div style={{ display: 'flex',flexWrap:'wrap' }}>
                        {
                            goodsFilterAddFields.map((item, index) => {
                                return <div style={{ display: 'flex' }}>
                                    <Text type="secondary" strong style={{ width: "80px", textAlign: "right", margin: '10px', fontSize: '10px' }}>{item.title}</Text>
                                    <Select style={{ width: "250px", marginRight: '30px' }} maxTagCount="responsive" mode="multiple" placeholder={`请下拉选择${item.title}`} value={item.value} onChange={(value) => {
                                        let obj = [...goodsFilterAddFields]
                                        obj[index]['value'] = value
                                        setGoodsFilterAddFields(obj)
                                    }}>
                                        {
                                            item.select.map(item => {
                                                return <Option value={item}>{item}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            })
                        }
                    </div> */}
                    {/* <div style={{ width: "100%", maxHeight: "140px", borderRadius: "3px", border: "1px solid rgba(166, 166, 166, 1)", marginTop: '7px', display:'flex', flexWrap:'wrap',  alignContent: 'flex-start', overflow: 'auto'  }}>
                        {
                            chooseValueList.map((item, index) => {
                                return <div style={{ border: "1px solid #ccc", padding: "5px", height: "24px", display: 'flex', alignItems: "center", margin:"5px" }}>
                                    <div >{item.value}</div>
                                    <CloseOutlined onClick={() => {
                                        let obj = [...chooseValueList]
                                        obj.splice(index, 1)
                                        setChooseValueList(obj)
                                    }} />
                                </div>
                            })
                        }
                    </div> */}
                    <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "10px", marginBottom: "10px" }}></div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            极限差价:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "80px", marginRight: "8px", padding:"0px" }} placeholder="请输入"  value={minLimitSpread} onChange={(e)=>setMinLimitSpread(e.target.value.replace(/[^\d^\.]+/g,''))}/>
                            <div type="secondary" style={{ lineHeight: "24px" }}>-</div>
                            <Input style={{ height: "24px", width: "80px", marginLeft: "8px", marginRight: "12px", padding:"0px" }} placeholder="请输入"  value={maxLimitSpread} onChange={(e)=>setMaxLimitSpread(e.target.value.replace(/[^\d^\.]+/g,''))}/>
                            <Tooltip title="">
                                <QuestionCircleOutlined style={{ lineHeight: "24px" }} />
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            底线折扣:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "80px", marginRight: "8px" , padding:"0px"}} placeholder="请输入" value={minBottomLine} onChange={(e)=>setMinBottomLine(e.target.value.replace(/[^\d^\.]+/g,''))}/>
                            <div type="secondary" style={{ lineHeight: "24px" }}>-</div>
                            <Input style={{ height: "24px", width: "80px", marginLeft: "8px", marginRight: "12px", padding:"0px" }} placeholder="请输入" value={maxBottomLine} onChange={(e)=>setMaxBottomLine(e.target.value.replace(/[^\d^\.]+/g,''))} />
                            <Tooltip title="">
                                <QuestionCircleOutlined style={{ lineHeight: "24px" }} />
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            价格带:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "80px", marginRight: "8px", padding:"0px" }} placeholder="请输入" value={minPriceBand} onChange={(e)=>setMinPriceBand(e.target.value.replace(/[^\d^\.]+/g,''))} />
                            <div type="secondary" style={{ lineHeight: "24px" }}>-</div>
                            <Input style={{ height: "24px", width: "80px", marginLeft: "8px", marginRight: "12px", padding:"0px" }} placeholder="请输入" value={maxPriceBand} onChange={(e)=>setMaxPriceBand(e.target.value.replace(/[^\d^\.]+/g,''))} />
                            <Tooltip title="">
                                <QuestionCircleOutlined style={{ lineHeight: "24px" }} />
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div style={{ width: "130px", height: '24px', lineHeight: '24px', textAlign: 'right', fontSize: "14px", paddingRight: "20px" }}>
                            与沿用活动价差:
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input style={{ height: "24px", width: "80px", marginRight: "8px", padding:"0px" }} placeholder="请输入" value={minActivitySpread} onChange={(e)=>setMinActivitySpread(e.target.value.replace(/[^\d^\.]+/g,''))} />
                            <div type="secondary" style={{ lineHeight: "24px" }}>-</div>
                            <Input style={{ height: "24px", width: "80px", marginLeft: "8px", marginRight: "12px", padding:"0px" }} placeholder="请输入" value={maxActivitySpread} onChange={(e)=>setMaxActivitySpread(e.target.value.replace(/[^\d^\.]+/g,''))} />
                            <Tooltip title="">
                                <QuestionCircleOutlined style={{ lineHeight: "24px" }} />
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                        <Text type="secondary">当前范围筛选功能，有待开发，敬请期待</Text>
                    </div>
                </div>
            </Drawer>
            <Modal
                title="批量设置"
                visible={isBulkOperations}
                cancelText="取消"
                okText="确定"
                onCancel={() => { 
                    setChooseCoupon([])
                    setChooseZone([])
                    setIsComfirmPrice('')
                    setIsAddPlan('')
                    setOneDiscount('')
                    setCommodityVoucher('')
                    setPriceType('')
                    setPriceValue('')
                    setChooseCoupon([])
                    setIsOperation(0)
                    setIsBulkOperations(false) }}
                onOk={() => {{
                    let price = {}
                    price[choosePriceOption] = priceValue == '' ? '' : parseFloat(priceValue)
                    let dataProps = {
                        roleId: cookie.load('role'),
                        shopList: cookie.load('shopList'),
                        shop_name: props.history.location.pathname.split('/')[2],
                        activity_id: activityId,
                        plan_id: planId,
                        partitions_data:chooseZone,
                        is_confirmed:isConfirmPrice,
                        is_add_plan:isAddPlan,
                        single_piece_discount:oneDiscount,
                        commodity_coupon:  chooseCoupon.indexOf('商品优惠券') != -1 ? commodityVoucher : '' ,
                        coupons:chooseCoupon,
                        price:price,
                        goods_only_flag:selectedRowKeys,
                        search_filter:searchFilter,
                    }
                    setLoading(true)
                    if(bulkOperationTabs == '设定标记'){
                        NbAxios(dataProps, "POST", servicePath.batchSettingGoodsFlag).then(res => {
                            setLoading(false)
                            if (res.success) {
                                setChooseCoupon([])
                                setChooseZone([])
                                setIsComfirmPrice('')
                                setIsAddPlan('')
                                setOneDiscount('')
                                setCommodityVoucher('')
                                setPriceType('')
                                setPriceValue('')
                                setChoosePriceOption('')
                                setChooseCoupon([])
                                setIsOperation(0)
                                setIsBulkOperations(false) 
                                // batchSettingQueryAllGoods(selectedRowKeys)
                                searchGoods2(dataProps)
                            } else {
                                message.warn(res.errorMsg)
                            }
                        }).catch(err => {
                            setLoading(false)
                            message.warn('批量设置更改商品设定标记接口出错,请联系IT')
                        })
                    }else{
                        if(choosePriceOption == ''){
                            setLoading(false)
                            message.warn('请选择固定变量，否则无法进行计算')
                        }else{
                            NbAxios(dataProps, "POST", servicePath.batchSettingToChangeGoods).then(res => {
                                setLoading(false)
                                if (res.success) {
                                    setChooseCoupon([])
                                    setChooseZone([])
                                    setIsComfirmPrice('')
                                    setIsAddPlan('')
                                    setOneDiscount('')
                                    setCommodityVoucher('')
                                    setPriceType('')
                                    setPriceValue('')
                                    setChoosePriceOption('')
                                    setChooseCoupon([])
                                    setIsOperation(0)
                                    setIsBulkOperations(false) 
                                    // batchSettingQueryAllGoods(selectedRowKeys)
                                    searchGoods2(dataProps)
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            }).catch(err => {
                                setLoading(false)
                                message.warn('批量设置更改商品设定价格接口出错,请联系IT')
                            })
                        }
                    }
                    
                }}}
                centered={true}
                okButtonProps={{ disabled: loading }}
                cancelButtonProps={{ disabled:loading }}
            >
                <Tabs activeKey={bulkOperationTabs} onChange={(key) => setBulkOpertionTabs(key)} style={{padding:"0px"}}>
                    <TabPane disabled={isOperation == 1 ? true :false} tab={<span style={{ height: "22px", lineHeight: "22px" }}><span style={{ width: "60px", fontSize: "14px", marginRight: '5px' }}>设定标记</span><QuestionCircleOutlined /></span>} key="设定标记">
                        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                            <div style={{ fontSize: "14px" }}>指定专区</div>
                            <div style={{ display: 'flex', padding: "10px" }}>
                                {
                                    batchConfiguration['discounts'].map(item => {
                                        return <Checkbox checked={chooseZone.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            setIsOperation(1)
                                            if (e.target.checked) {
                                                let obj = [...chooseZone]
                                                obj.push(item)
                                                setChooseZone(obj)
                                            } else {
                                                let obj = [...chooseZone]
                                                obj.remove(item)
                                                setChooseZone(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                            <div style={{ fontSize: "14px" }}>确认/取消</div>
                            <div style={{ padding: "10px" }}>
                                <Radio.Group onChange={(e) => {
                                    setIsOperation(1)
                                    setIsComfirmPrice(e.target.value)
                                }} value={isConfirmPrice}>
                                    <Radio value={1}>确认价格</Radio>
                                    <Radio value={0}>取消确认</Radio>
                                </Radio.Group>
                            </div>
                            <div style={{ fontSize: "14px" }}>加入/移除计划</div>
                            <div style={{ padding: "10px" }}>
                                <Radio.Group onChange={(e) => {
                                    setIsOperation(1)
                                    setIsAddPlan(e.target.value)
                                }} value={isAddPlan}>
                                    <Radio value={1}>加入计划</Radio>
                                    <Radio value={0}>移除计划</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane disabled={isOperation == 1 ? true :false} tab={<span style={{ height: "22px", lineHeight: "22px" }}><span style={{ width: "60px", fontSize: "14px", marginRight: '5px' }}>设定价格</span><QuestionCircleOutlined /></span>} key="设定价格">
                        <div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontSize: "14px", width: "60px", height: '32px', marginRight: "2px", textAlign: 'center', lineHeight: "32px" }}>单件折扣</div>
                                <QuestionCircleOutlined style={{ marginTop: '3px', lineHeight: "32px" }} />
                                <Input style={{ height: "32px", width: "100px", marginLeft: "10px" }} placeholder="请输入" value={oneDiscount} onChange={(e)=>{
                                    setIsOperation(1)
                                    setOneDiscount(e.target.value.replace(/[^\d^\.]+/g,''))
                                }} />
                                <div style={{display: chooseCoupon.indexOf('商品优惠券') != -1 ? "flex" : 'none'}}>
                                    <div style={{ fontSize: "14px", width: "60px", height: '32px', marginRight: "2px", marginLeft: "10px", textAlign: 'center', lineHeight: "32px" }}>商品券</div>
                                    <QuestionCircleOutlined style={{ marginTop: '3px', lineHeight: "32px" }} />
                                    <Input style={{ height: "32px", width: "100px", marginLeft: "10px" }} placeholder="请输入" value={commodityVoucher} onChange={(e)=>{
                                        setIsOperation(1)
                                        setCommodityVoucher(e.target.value.replace(/[^\d^\.]+/g,''))
                                    }}/>
                                </div>
                            </div>
                            <div style={{ marginTop: '10px',display:'flex' }}>
                                <div style={{ fontSize: "14px", width: "60px", height: '32px', marginRight: "2px", textAlign: 'center', lineHeight: "32px" }}>固定变量</div>
                                <Select style={{ height: "32px", width: "88px" }} value={choosePriceOption} onChange={(value)=>setChoosePriceOption(value)} placeholder="固定变量必选">
                                    <Option value={"活动价"}>活动价</Option>
                                    <Option value={"极限到手"}>极限到手</Option>
                                </Select>
                                <Input style={{ height: "32px", width: "100px", marginTop: "1px" }} placeholder="请输入" value={priceValue} onChange={(e)=>{
                                    setIsOperation(1)
                                    setPriceValue(e.target.value.replace(/[^\d^\.]+/g,''))
                                }}/>
                            </div>
                            <div style={{ marginTop: "12px", fontSize: "10px" }}>
                                到手价和活动价只能选其一，为了控制唯一变量。不填意为保持不变。
                            </div>
                            <div style={{ marginTop: "29px", display: 'flex', height: "21px", lineHeight: "21px" }}>
                                <div style={{ fontSize: "14px", marginRight: "17px" }}>券码</div>
                                <QuestionCircleOutlined style={{ marginTop: '3px' }} />
                                <div style={{ fontSize: "10px", marginLeft: "7px" }}>查看互斥规则</div>
                            </div>
                            <div style={{ width: "100%", display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    batchConfiguration['coupons'].map(item => {
                                        return <Checkbox checked={chooseCoupon.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            setIsOperation(1)
                                            if (e.target.checked) {
                                                let obj = [...chooseCoupon]
                                                if(batchConfiguration['mutex_coupons'][item]){
                                                    // 删除互斥的数据
                                                    batchConfiguration['mutex_coupons'][item].map(item=>{
                                                        obj.remove(item)
                                                    })
                                                }
                                                obj.push(item)
                                                setChooseCoupon(obj)
                                            } else {
                                                let obj = [...chooseCoupon]
                                                obj.remove(item)
                                                setChooseCoupon(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </Modal>
            <Modal
                title="查看单款信息"
                visible={isLookMore}
                // onOk={() => {
                //     let goodsOnlyFlag = []
                //     goodsOnlyFlag.push(moreData['goods_only_flag'])
                //     batchSettingQueryAllGoods(goodsOnlyFlag)
                //     setIsLookMore(false)
                // }}
                onCancel={() => {
                    let goodsOnlyFlag = []
                        goodsOnlyFlag.push(moreData['goods_only_flag'])
                        // batchSettingQueryAllGoods(goodsOnlyFlag)\
                        let dataProps = {
                            roleId: cookie.load('role'),
                            shopList: cookie.load('shopList'),
                            shop_name: props.history.location.pathname.split('/')[2],
                            activity_id: activityId,
                            plan_id: planId,
                            search_filter:searchFilter,
                            goods_only_flag_checked:selectedRowKeys
                        }
                        searchGoods2(dataProps)
                        setChoosePriceOption('')
                        setPriceValue('')
                        setCheckedCoupons([])
                        setOneDiscount('')
                        setCommodityVoucher('')
                        setIsLookMore(false)
                }}
                // okText={"确定"}
                // cancelText={"取消"}
                centered={true}
                footer={<div>
                    <Button onClick={()=>{
                        let goodsOnlyFlag = []
                        goodsOnlyFlag.push(moreData['goods_only_flag'])
                        let dataProps = {
                            roleId: cookie.load('role'),
                            shopList: cookie.load('shopList'),
                            shop_name: props.history.location.pathname.split('/')[2],
                            activity_id: activityId,
                            plan_id: planId,
                            search_filter:searchFilter,
                            goods_only_flag_checked:selectedRowKeys
                        }
                        searchGoods2(dataProps)
                        // batchSettingQueryAllGoods(goodsOnlyFlag)
                        setChoosePriceOption('')
                        setPriceValue('')
                        setCheckedCoupons([])
                        setOneDiscount('')
                        setCommodityVoucher('')
                        setIsLookMore(false)
                    }}>关闭</Button>
                </div>}
                width='1250px'
            >
                <Spin spinning={loading} tip="数据加载中......">
                    <div>
                        <div style={{ display: 'grid', gridTemplateColumns:"400px 1fr" }}>
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: "20px" }}>
                                        <img src={`https://pic.bmcsoft.cn/it/${imgDict[props.history.location.pathname.split('/')[2]]}/${moreData['goods_info']['pic_url']}.jpg`} style={{ width: '120px', height: '120px' }} />
                                    </div>
                                    <div style={{ width: "300px" }}>
                                        <div>
                                            <div style={{display:'flex'}}>
                                                <div style={{marginRight:"10px"}}>
                                                    <CopyToClipboard text={moreData['goods_info']['SPU']} onCopy={() => message.success('复制成功')}>
                                                        <a style={{fontSize:"20px"}}>{moreData['goods_info']['SPU']}</a>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                            <div>
                                                <CopyToClipboard text={moreData['goods_info']['商品ID']} onCopy={() => message.success('复制成功')}>
                                                    <span style={{textDecoration: "underline",cursor:'pointer'}}>ID: {moreData['goods_info']['商品ID']}</span>
                                                </CopyToClipboard>
                                            </div>
                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                                <div>
                                                    <span style={{fontSize:"14px"}}><MyIcon type="icon-renminbi" style={{fontSize:"16px", marginRight:"10px"}} />{moreData['goods_info']['吊牌价']}</span>
                                                </div>
                                                <div>
                                                    <Popover content={showMoreInfo(moreData['show_info'])} placement="bottom">
                                                        <a style={{marginRight:"60px"}}>更多</a>
                                                    </Popover>
                                                </div>
                                        </div>
                                            <div style={{marginTop:"10px"}}>
                                                {
                                                    moreData['tag'].map(item=>{
                                                    return <Tag color={item['color']} >{item['shord_name']}</Tag>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Tooltip title={moreData['explain']}>
                                    <div style={{ overflow: 'hidden', whiteSpace: "nowrap", textOverflow: "ellipsis", marginTop: "6px", width:"230px", fontSize:"10px"}}>{moreData['explain']}</div>
                                </Tooltip>
                            </div>
                            <div style={{width:"100%"}}>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <div style={{fontSize:"18px", fontWeight:"bolder"}}>{moreData['overview']['专区']}</div>
                                    <div style={{ display: "flex"}}>
                                        <div style={{ marginRight: "10px" }}>
                                            {
                                                moreData['is_add_plan'] == 0 ?<Popconfirm
                                                    title="你确定要参加计划吗？"
                                                    okText="确定"
                                                    cancelText="取消"
                                                    onConfirm={() => {
                                                        let dataProps = {
                                                            roleId: cookie.load('role'),
                                                            shopList: cookie.load('shopList'),
                                                            shop_name: props.history.location.pathname.split('/')[2],
                                                            activity_id: activityId,
                                                            plan_id: planId,
                                                            goods_only_flag:moreData['goods_only_flag'],
                                                            is_add_plan:1,
                                                            search_filter:searchFilter,
                                                        }
                                                        setLoading(true)
                                                        NbAxios(dataProps, "POST", servicePath.goodStatusAddIntoPlan).then(res => {
                                                            setLoading(false)
                                                            if (res.success) {
                                                                message.success('修改成功')
                                                                moreData['is_add_plan'] = 1
                                                                let goodsOnlyFlag = []
                                                                goodsOnlyFlag.push(moreData['goods_only_flag'])
                                                                // batchSettingQueryAllGoods(goodsOnlyFlag)
                                                                // searchGoods2(dataProps)
                                                            } else {
                                                                message.warn(res.errorMsg)
                                                            }
                                                        }).catch(err => {
                                                            setLoading(false)
                                                            message.warn('商品状态-确认价格出错,请联系IT')
                                                        })
                                                    }}
                                                    onCancel={() => { }}
                                                    placement="bottom"
                                                >
                                                    <a style={{ color: "red", textDecoration: "underline" }}>加入计划</a>
                                                </Popconfirm>: <Popconfirm
                                                    title="你确定要移除计划吗？"
                                                    okText="确定"
                                                    cancelText="取消"
                                                    onConfirm={() => {
                                                        let dataProps = {
                                                            roleId: cookie.load('role'),
                                                            shopList: cookie.load('shopList'),
                                                            shop_name: props.history.location.pathname.split('/')[2],
                                                            activity_id: activityId,
                                                            plan_id: planId,
                                                            goods_only_flag:moreData['goods_only_flag'],
                                                            is_add_plan:0,
                                                            search_filter:searchFilter,
                                                        }
                                                        setLoading(true)
                                                        NbAxios(dataProps, "POST", servicePath.goodStatusAddIntoPlan).then(res => {
                                                            setLoading(false)
                                                            if (res.success) {
                                                                message.success('修改成功')
                                                                moreData['is_add_plan'] = 0
                                                                let goodsOnlyFlag = []
                                                                goodsOnlyFlag.push(moreData['goods_only_flag'])
                                                                // batchSettingQueryAllGoods(goodsOnlyFlag)
                                                                // searchGoods2(dataProps)
                                                            } else {
                                                                message.warn(res.errorMsg)
                                                            }
                                                        }).catch(err => {
                                                            setLoading(false)
                                                            message.warn('商品状态-确认价格出错,请联系IT')
                                                        })
                                                    }}
                                                    onCancel={() => { }}
                                                    placement="bottom"
                                                >
                                                    <a style={{textDecoration: "underline" }}>移出计划</a>
                                                </Popconfirm>
                                            }
                                        </div>
                                        <div>
                                            {
                                                moreData['is_confirmed'] == 0 ? <Popconfirm
                                                    title="你确定要确认吗？"
                                                    okText="确定"
                                                    cancelText="取消"
                                                    onConfirm={() => {
                                                        let dataProps = {
                                                            roleId: cookie.load('role'),
                                                            shopList: cookie.load('shopList'),
                                                            shop_name: props.history.location.pathname.split('/')[2],
                                                            activity_id: activityId,
                                                            plan_id: planId,
                                                            goods_only_flag:moreData['goods_only_flag'],
                                                            is_confirmed:1,
                                                            search_filter:searchFilter,
                                                        }
                                                        setLoading(true)
                                                        NbAxios(dataProps, "POST", servicePath.goodsStatusPriceConfirm).then(res => {
                                                            setLoading(false)
                                                            if (res.success) {
                                                                message.success('修改成功')
                                                                moreData['is_confirmed'] = 1
                                                                let goodsOnlyFlag = []
                                                                goodsOnlyFlag.push(moreData['goods_only_flag'])
                                                                // batchSettingQueryAllGoods(goodsOnlyFlag)
                                                                // searchGoods2(dataProps)
                                                            } else {
                                                                message.warn(res.errorMsg)
                                                            }
                                                        }).catch(err => {
                                                            setLoading(false)
                                                            message.warn('商品状态-确认价格出错,请联系IT')
                                                        })
                                                    }}
                                                    onCancel={() => { }}
                                                    placement="bottom"
                                                >
                                                    <a style={{ color: "red", textDecoration: "underline" }}>待确认</a>
                                                </Popconfirm> : <Popconfirm
                                                    title="你确定取消确认吗？"
                                                    okText="确定"
                                                    cancelText="取消"
                                                    onConfirm={() => {
                                                        let dataProps = {
                                                            roleId: cookie.load('role'),
                                                            shopList: cookie.load('shopList'),
                                                            shop_name: props.history.location.pathname.split('/')[2],
                                                            activity_id: activityId,
                                                            plan_id: planId,
                                                            goods_only_flag:moreData['goods_only_flag'],
                                                            is_confirmed:0,
                                                            search_filter:searchFilter,
                                                        }
                                                        setLoading(true)
                                                        NbAxios(dataProps, "POST", servicePath.goodsStatusPriceConfirm).then(res => {
                                                            setLoading(false)
                                                            if (res.success) {
                                                                message.success('修改成功')
                                                                moreData['is_confirmed'] = 0
                                                                let goodsOnlyFlag = []
                                                                goodsOnlyFlag.push(moreData['goods_only_flag'])
                                                                // batchSettingQueryAllGoods(goodsOnlyFlag)
                                                                // searchGoods2(dataProps)
                                                            } else {
                                                                message.warn(res.errorMsg)
                                                            }
                                                        }).catch(err => {
                                                            setLoading(false)
                                                            message.warn('商品状态-确认价格出错,请联系IT')
                                                        })
                                                    }}
                                                    onCancel={() => { }}
                                                    placement="bottom"
                                                >
                                                    <a style={{ textDecoration: "underline" }}>已确认</a>
                                                </Popconfirm>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:"100%", display:'flex', justifyContent:'space-around'}}>
                                    <div style={{width:"160px", borderRadius:"20px", padding:"5px", backgroundColor:"#DCF3FD"}}>
                                        <div style={{textAlign:'center'}}>
                                            <Text style={{fontSize:"15px"}}>活动价</Text>
                                        </div>
                                        <div style={{textAlign:'center'}}>
                                            <Text style={{fontWeight:"bolder", fontSize:"30px"}}>{moreData['overview']['活动价']}</Text>
                                        </div>
                                    </div>
                                    <div style={{width:"160px", borderRadius:"20px", padding:"5px", backgroundColor:"#D8EDFF"}}>
                                        <div style={{textAlign:'center'}}>
                                            <Text style={{fontSize:"15px"}}>单价折扣</Text>
                                        </div>
                                        <div style={{textAlign:'center'}}>
                                            <Text style={{fontWeight:"bolder", fontSize:"30px"}}>{moreData['overview']['折扣']}</Text>
                                        </div>
                                    </div>
                                    <div style={{width:"200px", borderRadius:"20px", padding:"5px", display:'grid', gridTemplateColumns:"70% 30%", backgroundColor:"#F9E0F7"}}>
                                    <div>
                                            <div style={{textAlign:'center'}}>
                                                <Text style={{fontSize:"15px"}}>到手价</Text>
                                            </div>
                                            <div style={{textAlign:'center'}}>
                                                <Text style={{fontWeight:"bolder", fontSize:"30px"}}>{moreData['overview']['到手价']}</Text>
                                            </div>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
                                            <div>
                                                <div style={{fontSize:"10px", color:"#9e9e9e"}}>到手折扣</div>
                                                <div style={{fontSize:"4px"}}>{moreData['overview']['到手折扣']}</div>
                                            </div>
                                            <div>
                                                <div style={{fontSize:"10px", color:"#9e9e9e"}}>到手差价</div>
                                                <div style={{fontSize:"4px"}}>{moreData['overview']['到手差价']}</div>
                                            </div>
                                    </div>
                                    </div>
                                    <div style={{width:"200px", borderRadius:"20px", padding:"5px", display:'grid', gridTemplateColumns:"70% 30%", backgroundColor:"#F7F1D2"}}>
                                    <div>
                                            <div style={{textAlign:'center'}}>
                                                <Text style={{fontSize:"15px"}}>极限到手</Text>
                                            </div>
                                            <div style={{textAlign:'center'}}>
                                                <Text style={{fontWeight:"bolder", fontSize:"30px"}}>{moreData['overview']['极限到手']}</Text>
                                            </div>
                                    </div>
                                        <div style={{display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
                                            <div>
                                                <div style={{fontSize:"10px", color:"#9e9e9e"}}>极限折扣</div>
                                                <div style={{fontSize:"4px"}}>{moreData['overview']['极限折扣']}</div>
                                            </div>
                                            <div>
                                                <div style={{fontSize:"10px", color:"#9e9e9e"}}>极限差价</div>
                                                <div style={{fontSize:"4px"}}>{moreData['overview']['极限差价']}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginLeft:"20px", marginTop:"10px", display:'flex'}}>
                                    {
                                        moreData['overview']['used_coupons'].map(item=>{
                                            return <div style={{display:"flex", marginLeft:"2px"}}>
                                                <div style={{border:`1px solid ${item['logo_color']}`, color:`${item['logo_color']}`, backgroundColor:`${item['logo_color'].substring(0,7) + '19'}`, textAlign:'center', borderRadius:"3px", padding:"0 3px", fontSize:"10px", height:"20.84px"}}>{item.coupon_short_name}</div>
                                                <div style={{marginLeft:"3px", lineHeight:"20.84px"}}>{item.info}</div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop:"5px", height:"2px", marginBottom:"10px"}}></div>
                        <div style={{display:'grid', gridTemplateColumns:"80% 20%", marginTop:"20px"}}>
                            <div style={{marginRight:"25px"}}>
                                <Table columns={moreColumns} dataSource={moreData['partitions_data']} pagination={false} 
                                rowSelection={moreRowSelection} size="small" rowKey={'_id'} />
                            </div>
                            <div>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <div>变量修改</div>
                                    <a style={{color: "red", textDecoration: "underline"}} onClick={()=>{
                                         let dataProps = {
                                            roleId: cookie.load('role'),
                                            shopList: cookie.load('shopList'),
                                            shop_name: props.history.location.pathname.split('/')[2],
                                            activity_id: activityId,
                                            plan_id: planId,
                                            coupons:checkedCoupons,
                                            goods_only_flag: moreData['goods_only_flag'],
                                        }
                                        setLoading(true)
                                        NbAxios(dataProps, "POST", servicePath.resetPriceOfGood).then(res => {
                                            setLoading(false)
                                            if (res.success) {
                                                moreData['partitions_data'] = res.data['sys_partitions_data']
                                            } else {
                                                message.warn(res.errorMsg)
                                            }
                                        }).catch(err => {
                                            setLoading(false)
                                            message.warn('重置单款价格出错,请联系IT')
                                        })
                                    }}>重置</a>
                                </div>
                                <div style={{display:"flex", marginTop:"9px"}}>
                                    <Select size="small" placeholder="请选择" style={{width:"120px"}} value={choosePriceOption} onChange={(value)=>setChoosePriceOption(value)}>
                                        <Option value={"活动价"}>活动价</Option>
                                        <Option value={"极限到手"}>极限到手</Option>
                                    </Select>
                                    <Input size="small" style={{marginLeft:"10px", width:"90px"}} value={priceValue} onChange={(e)=>setPriceValue(e.target.value.replace(/[^\d^\.]+/g,''))} />
                                </div>
                                {/* <div style={{marginTop:"18px", display:"flex", justifyContent:'flex-end'}}>
                                    <Button style={{ border: '1px solid #C8EAFF', color: "#2A82E4", display:priceValue == '' || choosePriceOption == '' ? 'none':'' }} onClick={()=>{
                                        let price = {}
                                        price[`${choosePriceOption}`] = priceValue
                                        let dataProps = {
                                            roleId: cookie.load('role'),
                                            shopList: cookie.load('shopList'),
                                            shop_name: props.history.location.pathname.split('/')[2],
                                            activity_id: activityId,
                                            plan_id: planId,
                                            goods_only_flag:moreData['goods_only_flag'],
                                            price:price,
                                            coupons:checkedCoupons,

                                        }
                                        console.log(dataProps);
                                        setLoading(true)
                                        NbAxios(dataProps, "POST", servicePath.adjustPriceOfSingle).then(res => {
                                            setLoading(false)
                                            if (res.success) {
                                                searchSingleItemInformation(moreData['goods_only_flag'])
                                            }
                                        }).catch(err => {
                                            setLoading(false)
                                            message.warn('调整单款价格出错,请联系IT')
                                        })
                                    }}>确认</Button>
                                </div> */}
                                <div>
                                    <Text style={{display:checkedCoupons.length === openCoupons.length && checkedCoupons.sort().toString() === openCoupons.sort().toString() ? 'none' : '', fontSize:"10px"}} type="danger">优惠券变更，需要在上方选择固定变量！！！</Text>
                                    <Text style={{display:oneDiscount == '' ? 'none' : '', fontSize:"10px"}} type="danger">单件折扣变更，需要在上方选择固定变量！！！</Text>
                                </div>
                                <div style={{marginTop:"5px", display:'flex', justifyContent:'flex-start'}}>
                                    优惠券选定
                                </div>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    {
                                        moreData['coupons_list'].map(item=>{
                                        return <div style={{display:'flex', alignItems:"center"}}>
                                                <Checkbox checked={checkedCoupons.indexOf(item) != -1 ? true: false} style={{marginLeft:"5px"}} onChange={(e)=>{
                                                    setCheckChange(true)
                                                if (e.target.checked) {
                                                    let obj = [...checkedCoupons]
                                                    if (moreData['mutex_coupons'][item]){
                                                        // 删除互斥的数据
                                                        moreData['mutex_coupons'][item].map(item=>{
                                                        obj.remove(item)
                                                        })
                                                        obj.push(item)
                                                        setCheckedCoupons(obj)
                                                    }else{
                                                        obj.push(item)
                                                        setCheckedCoupons(obj)
                                                    }
                                                } else {
                                                    // moreData['coupons_checked'].remove(item)
                                                    let obj = [...checkedCoupons]
                                                    obj.remove(item)
                                                    setCheckedCoupons(obj)
                                                }
                                            }}>{item}</Checkbox>
                                            <Input disabled={checkedCoupons.indexOf('商品优惠券') != -1 ? false : true} size="small" style={{marginLeft:"10px", width:"90px", marginRight:"10px", display:item == "商品优惠券" ? '' : 'none'}} value={commodityVoucher} onChange={(e)=>setCommodityVoucher(e.target.value.replace(/[^\d^\.]+/g,''))} />
                                        </div>
                                        })
                                    }
                                </div>
                                <div style={{display:'flex', marginTop:'15px'}}>
                                    <span>单件折扣</span>
                                    <Input size="small" style={{marginLeft:"10px", width:"90px", marginRight:"10px",}} value={oneDiscount} onChange={(e)=>setOneDiscount(e.target.value.replace(/[^\d^\.]+/g,''))} />
                                    <Button size="small" style={choosePriceOption || priceValue || oneDiscount || checkChange?{ border: '1px solid #C8EAFF', color: "#2A82E4" }:{display:'none'}} onClick={()=>{
                                        let errflag = 0
                                        let errmessage = ''
                                        if(!(checkedCoupons.length === openCoupons.length && checkedCoupons.sort().toString() === openCoupons.sort().toString())){
                                            if(choosePriceOption == '' ){
                                                errflag = 1
                                                errmessage = '优惠券变更，需要在上方选择固定变量！！！'
                                            }
                                        }
                                        if(oneDiscount != ''){
                                            if(choosePriceOption == ''){
                                                errflag = 1
                                                errmessage = '单件折扣变更，需要在上方选择固定变量！！！'
                                            }
                                        }
                                        if(errflag){
                                            message.warn(errmessage)
                                        }else{
                                            let price = {}
                                            price[choosePriceOption] = parseFloat(priceValue)
                                            let dataProps = {
                                                roleId: cookie.load('role'),
                                                shopList: cookie.load('shopList'),
                                                shop_name: props.history.location.pathname.split('/')[2],
                                                activity_id: activityId,
                                                plan_id: planId,
                                                coupons:checkedCoupons,
                                                goods_only_flag: moreData['goods_only_flag'],
                                                price:price,
                                                oneDiscount:oneDiscount == '' ? '' : parseFloat(oneDiscount) ,
                                                commodityVoucher:  chooseCoupon.indexOf('商品优惠券') != -1 ? commodityVoucher == '' ? '' : parseFloat(commodityVoucher) : '',
                                            }
                                            setLoading(true)
                                            NbAxios(dataProps, "POST", servicePath.adjustPriceOfSingle).then(res => {
                                                setLoading(false)
                                                if (res.success) {
                                                    searchSingleItemInformation(moreData['goods_only_flag']);
                                                } else {
                                                    message.warn(res.errorMsg)
                                                }
                                            }).catch(err => {
                                                setLoading(false)
                                                message.warn('调整单款价格出错,请联系IT')
                                            })
                                        }
                                    }}>确认</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
                
            </Modal>

            <Modal
                title="编辑说明"
                visible={isEditing}
                centered={true}
                okText="确定"
                cancelText="取消"
                onOk={()=>{
                    let value = updatePlanForm.getFieldValue()
                    value.roleId = cookie.load('role')
                    value.shopList = cookie.load('shopList')
                    value.user_email = cookie.load('name')
                    value.shop_name = props.history.location.pathname.split('/')[2]
                    value.activity_id = activityId
                    value.plan_id = planId
                    NbAxios(value, "POST", servicePath.descriptionInterface).then(res => {
                        if (res.success) {
                            message.success('说明编辑成功')
                            // initializePriceAdjustment()
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                activity_id: props.location.state.activity_id,
                                plan_id: props.location.state.plan_id,
                                page_size:pageSize,
                                page_no:page,
                                search_filter:searchFilter,
                            }
                            searchGoods2(dataProps)
                            setIsEdting(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    }).catch(err => {
                        message.warn('编辑说明出错,请联系IT')
                    })
                }}
                onCancel={()=>{
                    setIsEdting(false)
                }}
            >
                <Form form={updatePlanForm} {...layout} labelAlign='left'>
                    <Form.Item label="说明" name='explain'>
                        <TextArea rows={3} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="显示配置"
                visible={tableHeaderModel}
                centered={true}
                okText="确定"
                cancelText="取消"
                width={'800px'}
                onOk={()=>{
                    if(tableHeaderDataSource.length !== 0){
                        let dataProps = {
                            roleId : cookie.load('role'),
                            shopList : cookie.load('shopList'),
                            user_email : cookie.load('name'),
                            shop_name: props.history.location.pathname.split('/')[2],
                            activity_id : activityId,
                            plan_id : planId,
                            config_list:tableHeaderDataSource,
                            checkTableHeader:checkTableHeader,
                        }
                        submitShowConfigWindow(dataProps)
                    }else{
                        message.warning('配置表为空')
                    }
                    
                }}
                onCancel={()=>{
                    setTableHeaderModel(false)
                }}
            >
                <div style={{display:'grid',gridTemplateColumns:'60% 1fr', alignItems:'center',gridRowGap:'3ch',gridColumnGap:'2ch'}}>
                    <div>
                        {tableHeaderData.map(item=>{
                            return <div>
                                <div>
                                    {item.name}
                                </div>
                                <div>
                                    <Select style={{width:'100%'}} showSearch allowClear mode='multiple' 
                                        value={checkTableHeader[item.name]}
                                        onChange={(value)=>{
                                            let obj = {...checkTableHeader}
                                            obj[item.name] = value
                                            setCheckTableHeader(obj)
                                            // 将数据写入表格
                                            let data = [...tableHeaderDataSource]
                                            let addFiled = []   // 存放表格里相同维度的字段
                                            data.map(obj=>{
                                                // 如果数据表中有该维度,则判断是否有列表里的字段
                                                if(obj['维度'] == item.name){
                                                    // 如果有,则将字段添加进列表
                                                    addFiled.push(obj['字段'])
                                                }
                                            })
                                            // 如果该维度字段不为空
                                            if(addFiled.length!==0){
                                                // 取选择器选中的字段与表格所有该维度的字段作差集
                                                let addList = arrayAminusB(value,addFiled)
                                                // 差集即为需要添加进表格的字段
                                                addList.map(obj=>{
                                                    data.push({
                                                        '维度':item.name,
                                                        '字段':obj,
                                                        'id':item.name+'_'+obj
                                                    })
                                                })
                                                // 取表格所有该维度的字段与选择器选中的字段作差集
                                                let cutList = arrayAminusB(addFiled,value)
                                                // 差集即为需要删除表格的字段
                                                cutList.map(obj=>{
                                                    data.map((data1,index)=>{ 
                                                        if(data1.id == item.name+'_'+obj){
                                                            data.splice(index,1)
                                                        }
                                                    })
                                                })
                                            }else{
                                                // 如果整个表格都没有该维度,则需要添加进表格内
                                                data.push({
                                                    '维度':item.name,
                                                    '字段':value[0],
                                                    'id':item.name+'_'+value[0]
                                                })
                                            }
                                            setTableHeaderDataSource(data)
                                        }}
                                    >
                                        {item.select_list.map(obj=>(
                                                <Option value={obj}>{obj}</Option>
                                            )
                                        )}
                                    </Select>
                                </div>
                            </div>
                        })}
                    </div>
                    <div>
                        <div>{'显示顺序(可拖拽式排序)'}</div>
                        <DndProvider backend={HTML5Backend}>
                            <Table columns={tableHeaderColumns} size='small' dataSource={tableHeaderDataSource} rowKey={'id'} scroll={{y:500}} pagination={false} 
                                components={components}
                                onRow={(_, index) => {
                                const attr = {
                                    index,
                                    moveRow,
                                };
                                return attr;
                                }}
                            /> 
                        </DndProvider>
                    </div>
                </div>
            </Modal>
        </div>
    )

}


export default Index

