import { useRef } from "react";

// 防抖
const useDebounce = (fn, delay = 2000, dep = []) => {
  //  使用 useRef 的目的是：保留上一次的timer，以至于让 if 语句走通，然后清除上一次的 timer
  // 否则，没有清除定时器，达不到防抖效果
  const { current } = useRef({ fn, timer: null });
  current.fn = fn;
  return (...args) => {
    if (current.timer) clearTimeout(current.timer);
    current.timer = setTimeout(current.fn, delay, ...args);
  };
};

export default useDebounce;
