let S_COLOR = "#fd3e3e"
let A_COLOR = "#4a7fe5"

const dataSource = [
    { title: '618开门红', start: '2020-06-01', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2020-06-03', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2020-06-01',end:'2020-06-04', backgroundColor: S_COLOR },
    { title: '618狂欢购', start: '2020-06-16', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2020-06-20', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2020-06-16',end:'2020-06-21', backgroundColor: S_COLOR },
    { title: '狂暑季', start: '2020-07-10', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-07-12', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-07-10',end:'2020-07-13', backgroundColor: A_COLOR },
    { title: '狂欢购', start: '2020-07-19', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-07-21', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-07-19',end:'2020-07-22', backgroundColor: A_COLOR },
    { title: '彩妆周', start: '2020-07-28', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-07-30', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-07-28',end:'2020-08-01', backgroundColor: A_COLOR },
    { title: '88会员节', start: '2020-08-06', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2020-08-08', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2020-08-06',end:'2020-08-09', backgroundColor: S_COLOR },
    { title: '秋冬新风尚', start: '2020-08-12', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-08-14', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-08-12',end:'2020-08-15', backgroundColor: A_COLOR },
    { title: '七夕节', start: '2020-08-21', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-08-25', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-08-21',end:'2020-08-26', backgroundColor: A_COLOR },
    { title: '99聚划算', start: '2020-09-09', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2020-09-11', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2020-09-09',end:'2020-09-12', backgroundColor: S_COLOR },
    { title: '潮人节', start: '2020-09-21', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-09-23', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-09-21',end:'2020-09-24', backgroundColor: A_COLOR },
    { title: '集团日', start: '2020-09-27', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-09-29', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-09-27',end:'2020-09-30', backgroundColor: A_COLOR },
    { title: '国庆狂欢', start: '2020-10-01', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-10-07', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-10-01',end:'2020-10-08', backgroundColor: A_COLOR },
    { title: '冬焕新', start: '2020-10-16', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-10-18', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-10-16',end:'2020-10-19', backgroundColor: A_COLOR },
    { title: '双11预售', start: '2020-10-21', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2020-10-31', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2020-10-21',end:'2020-11-01', backgroundColor: S_COLOR },
    { title: '双11开门红', start: '2020-11-01', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2020-11-03', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2020-11-01',end:'2020-11-04', backgroundColor: S_COLOR },
    { title: '双11狂欢购', start: '2020-11-11', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2020-11-11',end:'2020-11-12', backgroundColor: S_COLOR },
    { title: '双12盛典', start: '2020-12-10', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2020-12-12', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2020-12-10',end:'2020-12-13', backgroundColor: S_COLOR },
    { title: '双旦', start: '2020-12-21', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-12-25', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-12-21',end:'2020-12-26', backgroundColor: A_COLOR },
    { title: '新年狂欢', start: '2020-12-28', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-12-31', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2020-12-28',end:'2021-01-01', backgroundColor: A_COLOR },
    { title: '保暖节', start: '2021-01-07', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-01-09', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-01-07',end:'2021-01-10', backgroundColor: A_COLOR },
    { title: '年货节', start: '2021-01-20', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-01-25', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-01-20',end:'2021-01-26', backgroundColor: S_COLOR },
    { title: '后年货节', start: '2021-01-26', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-01-31', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-01-26',end:'2021-02-01', backgroundColor: A_COLOR },
    { title: '不打烊', start: '2021-02-01', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-02-19', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-02-01',end:'2021-02-20', backgroundColor: A_COLOR },
    { title: '开门红', start: '2021-02-20', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-02-22', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-02-20',end:'2021-02-23', backgroundColor: A_COLOR },
    { title: '女王节', start: '2021-03-05', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-03-08', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-03-05',end:'2021-03-09', backgroundColor: S_COLOR },
    { title: '新风尚', start: '2021-03-24', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-03-26', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-03-24',end:'2021-03-27', backgroundColor: A_COLOR },
    { title: '特卖节', start: '2021-04-17', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-04-19', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-04-17',end:'2021-04-20', backgroundColor: S_COLOR },
    { title: '55吾折天', start: '2021-05-01', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-05-05', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-05-01',end:'2021-05-06', backgroundColor: S_COLOR },
    { title: '国潮大赏', start: '2021-05-10', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-05-12', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-05-10',end:'2021-05-13', backgroundColor: A_COLOR },
    { title: '520礼遇季', start: '2021-05-18', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-05-20', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-05-18',end:'2021-05-21', backgroundColor: A_COLOR },
    { title: '618开门红', start: '2021-06-01', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-06-03', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-06-01',end:'2021-06-04', backgroundColor: A_COLOR },
    { title: '618风尚日', start: '2021-06-07', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-06-09', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-06-07',end:'2021-06-10', backgroundColor: S_COLOR },
    { title: '618狂欢购', start: '2021-06-16', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-06-20', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-06-16',end:'2021-06-21', backgroundColor: S_COLOR },
    { title: '狂暑季', start: '2021-07-14', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-07-16', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-07-14',end:'2021-07-17', backgroundColor: S_COLOR },
    { title: '新风尚', start: '2021-07-29', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-07-31', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-07-29',end:'2021-08-01', backgroundColor: A_COLOR },
    { title: '88会员节', start: '2021-08-06', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-08-08', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-08-06',end:'2021-08-09', backgroundColor: S_COLOR },
    { title: '七夕节', start: '2021-08-11', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-08-14', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-08-11',end:'2021-08-15', backgroundColor: A_COLOR },
    { title: '秋冬新风尚', start: '2021-08-25', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-08-27', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-08-25',end:'2021-08-28', backgroundColor: A_COLOR },
    { title: '99聚划算', start: '2021-09-09', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-09-11', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-09-09',end:'2021-09-12', backgroundColor: S_COLOR },
    { title: '天猫潮流周', start: '2021-09-22', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-09-24', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-09-22',end:'2021-09-25', backgroundColor: A_COLOR },
    { title: '国庆狂欢', start: '2021-10-01', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-10-07', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-10-01',end:'2021-10-08', backgroundColor: A_COLOR },
    { title: '冬焕新', start: '2021-10-15', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-10-17', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-10-15',end:'2021-10-18', backgroundColor: A_COLOR },
    { title: '双11预售', start: '2021-10-21', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-10-31', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-10-21',end:'2021-11-01', backgroundColor: S_COLOR },
    { title: '双11开门红', start: '2021-11-01', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-11-03', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-11-01',end:'2021-11-04', backgroundColor: S_COLOR },
    { title: '双11狂欢购', start: '2021-11-11', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-11-11',end:'2021-11-12', backgroundColor: S_COLOR },
    { title: '双12盛典', start: '2021-12-12', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2021-12-14', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2021-12-12',end:'2021-12-15', backgroundColor: S_COLOR },
    { title: '双旦', start: '2021-12-21', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-12-25', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-12-21',end:'2021-12-26', backgroundColor: A_COLOR },
    { title: '新年狂欢', start: '2021-12-30', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-01-03', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2021-12-30',end:'2022-01-04', backgroundColor: A_COLOR },
    { title: '年货节', start: '2022-01-09', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-01-15', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-01-09',end:'2022-01-16', backgroundColor: S_COLOR },
    { title: '后年货节', start: '2022-01-16', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-01-21', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-01-16',end:'2022-01-22', backgroundColor: A_COLOR },
    { title: '不打烊', start: '2022-01-24', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-02-08', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-01-24',end:'2022-02-09', backgroundColor: A_COLOR },
    { title: '暖春季', start: '2022-02-15', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-02-17', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-02-15',end:'2022-02-18', backgroundColor: A_COLOR },
    { title: '女王节', start: '2022-03-04', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-03-08', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-03-04',end:'2022-03-09', backgroundColor: S_COLOR },
    { title: '新势力周', start: '2022-03-21', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-03-24', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-03-21',end:'2022-03-25', backgroundColor: A_COLOR },
    { title: '天猫初夏上新—正式', start: '2022-04-12', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-04-15', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-04-12',end:'2022-04-16', backgroundColor: A_COLOR },
    { title: '乐活节-正式', start: '2022-04-23', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-04-26', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-04-23',end:'2022-04-27', backgroundColor: A_COLOR },
    { title: '55吾折天—正式', start: '2022-04-29', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-05-05', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-04-29',end:'2022-05-06', backgroundColor: S_COLOR },
    { title: '520告白季-预热', start: '2022-05-16', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-05-17', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-05-16',end:'2022-05-18', backgroundColor: A_COLOR },
    { title: '520告白季', start: '2022-05-18', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-05-20', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-05-18',end:'2022-05-21', backgroundColor: A_COLOR },
    { title: '618预售', start: '2022-05-26', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-05-30', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-05-26',end:'2022-05-31', backgroundColor: S_COLOR },
    { title: '618开门红', start: '2022-05-31', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-06-03', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-05-31',end:'2022-06-04', backgroundColor: S_COLOR },
    { title: '风尚日预热', start: '2022-06-04', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-06-06', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-06-04',end:'2022-06-07', backgroundColor: A_COLOR },
    { title: '风尚日正式', start: '2022-06-07', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-06-09', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-06-07',end:'2022-06-10', backgroundColor: A_COLOR },
    { title: '狂欢日-预热', start: '2022-06-14', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-06-14',end:'2022-06-15', backgroundColor: A_COLOR },
    { title: '狂欢日-正式', start: '2022-06-15', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-06-20', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-06-15',end:'2022-06-21', backgroundColor: A_COLOR },
    { title: '狂暑季-预热', start: '2022-07-11', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-07-12', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-07-11',end:'2022-07-13', backgroundColor: S_COLOR },
    { title: '狂暑季-正式', start: '2022-07-13', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-07-15', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-07-13',end:'2022-07-16', backgroundColor: S_COLOR },
    { title: '盛夏潮搭季-预热', start: '2022-07-25', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2  022-07-26', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-07-25',end:'2022-07-27', backgroundColor: A_COLOR },
    { title: '盛夏潮搭季-正式', start: '2022-07-27', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-07-29', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-07-27',end:'2022-07-30', backgroundColor: A_COLOR },
    { title: '七夕节-预热', start: '2022-07-30', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-07-30',end:'2022-07-31', backgroundColor: A_COLOR },
    { title: '七夕节季-正式', start: '2022-07-31', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-08-04', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-07-31',end:'2022-08-05', backgroundColor: A_COLOR },
    { title: '88会员日-预热', start: '2022-08-07', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-08-07',end:'2022-08-08', backgroundColor: A_COLOR },
    { title: '88会员日-正式', start: '2022-08-08', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-08-11', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-08-08',end:'2022-08-12', backgroundColor: A_COLOR },
    { title: '秋冬新势力周-预热', start: '2022-08-21', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-08-21',end:'2022-08-22', backgroundColor: A_COLOR },
    { title: '秋冬新势力周-正式', start: '2022-08-22', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-08-25', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-08-22',end:'2022-08-26', backgroundColor: A_COLOR },
    { title: '99划算节-预售', start: '2022-09-01', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-09-06', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-09-01',end:'2022-09-07', backgroundColor: S_COLOR },
    { title: '99划算节-预热', start: '2022-09-04', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-09-05', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-09-04',end:'2022-09-06', backgroundColor: S_COLOR },
    { title: '99划算节-正式', start: '2022-09-06', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-09-12', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-09-06',end:'2022-09-13', backgroundColor: S_COLOR },
    { title: '潮流上新-预热', start: '2022-09-17', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-09-18', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-09-17',end:'2022-09-19', backgroundColor: A_COLOR },
    { title: '潮流上新-正式', start: '2022-09-19', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-09-21', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-09-19',end:'2022-09-22', backgroundColor: A_COLOR },
    { title: '羊毛品类日-预热', start: '2022-09-24', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-09-25', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-09-24',end:'2022-09-26', backgroundColor: A_COLOR },
    { title: '羊毛品类日-正式', start: '2022-09-26', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-09-28', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-09-26',end:'2022-09-29', backgroundColor: A_COLOR },
    { title: '国庆狂欢季-预热', start: '2022-09-29', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-09-29',end:'2022-09-30', backgroundColor: A_COLOR },
    { title: '国庆狂欢季-正式', start: '2022-09-30', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-10-07', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-09-30',end:'2022-10-08', backgroundColor: A_COLOR },
    { title: '服饰冬焕新-预热', start: '2022-10-08', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-10-09', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-10-08',end:'2022-10-10', backgroundColor: A_COLOR },
    { title: '服饰冬焕新-正式', start: '2022-10-10', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-10-12', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-10-10',end:'2022-10-13', backgroundColor: A_COLOR },
    { title: '双11抢先购-预售', start: '2022-10-24', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-10-31', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-10-24',end:'2022-11-01', backgroundColor: S_COLOR },
    { title: '双11抢先购-预热', start: '2022-10-28', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-10-31', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-10-28',end:'2022-11-01', backgroundColor: S_COLOR },
    { title: '双11抢先购-正式', start: '2022-11-01', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-11-03', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-11-01',end:'2022-11-04', backgroundColor: S_COLOR },
    { title: '双11狂欢日-预热', start: '2022-11-04', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-11-09', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-11-04',end:'2022-11-10', backgroundColor: S_COLOR },
    { title: '双11狂欢日-正式', start: '2022-11-10', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-11-11', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-11-10',end:'2022-11-12', backgroundColor: S_COLOR },
    { title: '保暖季(A)-正式', start: '2022-12-01', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-12-03', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-12-01',end:'2022-12-04', backgroundColor: A_COLOR },
    { title: '双12(S)-预热', start: '2022-12-08', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-12-09', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-12-08',end:'2022-12-10', backgroundColor: S_COLOR },
    { title: '双12(S)-正式', start: '2022-12-10', backgroundColor: S_COLOR,  display:'list-item' },
    { title: '', start: '2022-12-12', backgroundColor: S_COLOR, display:'list-item' },
    { title: '', start: '2022-12-10',end:'2022-12-13', backgroundColor: S_COLOR },
    { title: '双旦礼遇季-正式', start: '2022-12-20', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-12-25', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-12-20',end:'2022-12-26', backgroundColor: A_COLOR },
    { title: '年货节-预热', start: '2022-12-27', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2022-12-28', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-12-27',end:'2022-12-29', backgroundColor: A_COLOR },
    { title: '年货节-正式', start: '2022-12-29', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2023-01-04', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2022-12-29',end:'2023-01-05', backgroundColor: A_COLOR },
    { title: '年货买不停-正式', start: '2023-01-05', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2023-01-10', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2023-01-05',end:'2023-01-11', backgroundColor: A_COLOR },
    { title: '春节不打烊-正式', start: '2023-01-14', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2023-01-28', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2023-01-14',end:'2023-01-29', backgroundColor: A_COLOR },
    { title: '情人节活动-正式', start: '2023-02-10', backgroundColor: A_COLOR,  display:'list-item' },
    { title: '', start: '2023-02-14', backgroundColor: A_COLOR, display:'list-item' },
    { title: '', start: '2023-02-10',end:'2023-02-15', backgroundColor: A_COLOR },
]


export default dataSource