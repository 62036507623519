import React, { useEffect, useState } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import { Divider, Radio } from 'antd'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
function Index({
    // 柱状图数据
    barChartData
    // 指标
    // barChartLegend,
    // // X轴
    // barChartXAxis,
    // // 折线数据
    // barChartSeries
}) {

    const [ barChartDataDict, setBarChartDataDict] = useState({})
    const [ radioList, setRadioList] = useState([])
    const [ radioValue, setRadioValue] = useState('')

    useEffect(()=>{
        if(Object.keys(barChartData).length != 0){
            setBarChartDataDict(barChartData)
            setRadioList(barChartData['radioList'])
            setRadioValue(barChartData['radioList'][0])
        }
        
    },[barChartData])

    useEffect(()=>{
        if(radioValue!=''){
            console.log("执行了");
            let report = echarts.init(document.getElementById('barReport'))
            report.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data:barChartDataDict[radioValue]['legend'],
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: { show: false },
                        data: barChartDataDict[radioValue]['xAxis'],
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series:barChartDataDict[radioValue]['series']
            })
        }
    },[radioValue])
    // 表格是否展示
    const [ isShow , setIsShow ] = useState(1)
    return (
        <div>
            <Divider orientation="left">柱形图</Divider>
            <div style={{display:'flex', justifyContent:'flex-end', marginRight:30}}>
                <Radio.Group value={radioValue} onChange={(e)=>{
                    setRadioValue(e.target.value);
                }}>
                {
                    radioList.map(item=>{
                        return <Radio value={item}>{item}</Radio>
                    })
                }
                </Radio.Group>
            </div>
            <div style={{display:'grid', gridTemplateColumns:"10px 1fr"}}>
            {
                isShow == 0 ? <EyeOutlined style={{marginLeft:7, color:"#039be5", fontSize:20}} onClick={()=>{
                    setIsShow(1)
                }} />:
                <EyeInvisibleOutlined style={{marginLeft:7, color:"#039be5", fontSize:20}} onClick={()=>{
                    setIsShow(0)
                }} />
            }
            <div id="barReport" style={{width:'calc(98vw - 17px)', height:500, backgroundColor:'#fff',marginLeft:20, display:isShow == 1 ? '' : 'none'}}></div>
          </div>
        </div>
    )
}

export default Index