import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Popover, message, Modal, Table, Typography, Select, Divider, Popconfirm, Drawer, Form, Spin, Tooltip } from 'antd'
import { PlusSquareOutlined, SearchOutlined, EyeOutlined, CheckCircleTwoTone,ExclamationCircleOutlined } from '@ant-design/icons'
import { Sketch } from '@uiw/react-color'
import style from './index.module.css';
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios';
import servicePath from '../../../config/apiUrl';

const { Title } = Typography;
const { Option } = Select;


function Index(props) {

    const videoRef = useRef(null)
    //定义记录
    const[record,setRecord] = useState({})
    // 下标
    const [index, setIndex] = useState(0)
    // 编辑属性
    const [editForm] = Form.useForm()
    // 品类分组选择值
    const [categoryLevelSelect, setCategoryLevelSelect] = useState('')
    const [categoryLabelSelect, setCategoryLabelSelect] = useState([])
    // 品类级别
    const [categoryLevel, setCategoryLevel] = useState(['视频', '图片', '音频'])
    const [categoryLabel, setCategoryLabel] = useState([])
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 总的选择标签
    const [allChooseTagsList, setAllChooseTagsList] = useState([])
    // 编辑标签
    const [isEditTags, setIsEditTags] = useState(false)
    // 定义查询名称输入
    const [selectNameValue, setSelectNameValue] = useState('');
    // 标签名字
    const [tagName, setTagName] = useState('')
    // 标签颜色
    const [tagColor, setTagColor] = useState('#cccccc')
    // 标签列表
    const [tagsList, setTagsList] = useState([
        {
            color: "#F56464",
            name: "运营中1"
        },
        {
            color: "#B7EB8F",
            name: "主链接2"
        },
        {
            color: "#F56464",
            name: "运营中3"
        },
        {
            color: "#B7EB8F",
            name: "主链接4"
        },
        {
            color: "#F56464",
            name: "运营中5"
        },
        {
            color: "#B7EB8F",
            name: "主链接6"
        }
    ])

    // 标签选择器内容
    const [tagsData, setTagsData] = useState([{
        "id": "",
        "group": "",
        "name": "测试",
        "color": ""
    }
    ])
    // 定义表格总数
    const [total, setTotal] = useState(0)
    // 定义页数
    const [page, setPage] = useState(1)
    // 表头
    const [ columns , setColumns] = useState([])
    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,	// 当前页数
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    // 定义is_image
    const [isImage, setIsImage] = useState(0)

    //定义address
    const [address, setAddress] = useState('')

    // 定义素材id
    const [materialId, setMaterialId] = useState([])
    //数据
    const [dataSource, setDataSource] = useState([])
    // 打开编辑弹框
    const [isModalOpen, setIsModalOpen] = useState(false)
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        editForm.resetFields()
        setAddress()
        videoRef.current.pause()
        setIsModalOpen(false);
    };



    //打开查询弹框
    const [isModalOpenSelect, setIsModalOpenSelect] = useState(false)
    const handleOkSelect = () => {
        setIsModalOpenSelect(false);
    };
    const handleCancelSelect = () => {
        setIsModalOpenSelect(false);
    };

    // 定义编辑保存
    const onEditFinish = (values) => {
        values["materialId"] = materialId;
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        // console.log('Success:', values);
        setLoading(true)
        NbAxios(values, "POST", servicePath.editShortVideoMaterial).then(res => {
            setLoading(false)
            if (res.success) {
                // console.log(res)
                handleCancel()
                editForm.resetFields()
                videoRef.current.pause()
                message.success('编辑素材表成功！')
                getSelectData()
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('编辑素材表报错，请联系IT帮忙解决')
        })
    };

    // 定义操作删除
    const onDelectFinish = (materialId) => {
        let values = {}
        values["materialId"] = materialId;
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        // console.log('Success:', values);
        setLoading(true)
        NbAxios(values, "POST", servicePath.delectShortVideoMaterial).then(res => {
            setLoading(false)
            if (res.success) {
                message.success('删除素材表成功！')
                getSelectData()
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('删除素材表报错，请联系IT帮忙解决')
        })
    };

    // 查询素材函数
    const getSelectData = () => {
        let tag_list = [] 
        categoryLabelSelect.map(item=>{
            let tag_id = parseInt(item.split(',')[0])
            tag_list.push(tag_id)
        })
        let dataProps = {
            "show_name": selectNameValue,
            "file_type": categoryLevelSelect,
            "tags": tag_list,
            "page": page,
            'shopList': cookie.load('shopList'),
            'roleId': cookie.load('role'),
        }
        setLoading(true)
        // console.log(dataProps)
        NbAxios(dataProps, "POST", servicePath.selectShortVideoMaterial).then(res => {
            setLoading(false)
            if (res.success) {
                // getSelectData(res.data);
                handleCancelSelect()
                setDataSource(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询素材表报错，请联系IT帮忙解决')
        })
    }

    // 展示所有标签
    const onShowTags = () => {
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        // console.log('Success:', values);
        NbAxios(values, "POST", servicePath.showTagsShortVideoMaterial).then(res => {
            setLoading(false)
            if (res.success) {
                setTagsData(res.data.data['tags_list'])
                setTagsList(res.data.data['tags_list'])
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('展示所有标签报错，请联系IT帮忙解决')
        })
    };

    // 定义编辑标签
    const editTags = () => {
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        values["tags_list"] = tagsData;
        // console.log('Success:', values);
        setLoading(true)
        NbAxios(values, "POST", servicePath.editTagsShortVideoMaterial).then(res => {
            setLoading(false)
            if (res.success) {
                setIsEditTags(false)
                onShowTags()
                message.success('编辑标签成功！')
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('编辑标签报错，请联系IT帮忙解决')
        })
    };

    // 渲染页面
    useEffect(() => {
        getSelectData()
        onShowTags()
    }, [page])

    useEffect(()=>{
        setColumns([
            {
                width: '40PX',
                align: 'center',
                render: (text, record, index) => {
                    return <div>
                        {/* 眼睛 */}
                        <Popover content={imageLookContent(record['is_image'],record['materialId_relative_address'])} placement="bottom">
                            <EyeOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
                        </Popover>
                    </div>
                }
            },
            {
                align: 'center',
                title: '状态',
                dataIndex: 'is_edit_completely',
                key: 'is_edit_completely',
                render: (text, record, index) => {
                    return <div>
                        {text == 1?
                            <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '18px', marginRight: '10px' }} />:                
                            <ExclamationCircleOutlined   style={{ fontSize: '18px', marginRight: '10px',color: "#FFBB00"}} />
                        }
                        
                        
                    </div>
                }
    
            },
            {
                align: 'center',
                title: '文件名字',
                dataIndex: 'show_name',
                key: 'show_name',
            },
    
            {
                align: 'center',
                title: '文件类型',
                dataIndex: 'file_type',
                key: 'file_type',
            },
            {
                align: 'center',
                title: '标签',
                dataIndex: 'tags',
                key: 'tags',
                render: (text, record, index) => {
                    return <div>
                        {/* 加号标签
                        <Popover content={addTagsList()} placement="bottom" zIndex={900} onMouseLeave={() => {
                            setTagsList(tagsData)
                        }}>
                            <PlusSquareOutlined />
                        </Popover> */}
                        <div style={{ display: 'flex', alignItems: "center", flexWrap: 'wrap', padding: "0px" }}>
                            {
                                text.map((tagItem) => {
                                    /**
                                     * 鼠标右键事件 trigger="contextMenu"
                                     */
                                    return <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                        record['tags'].remove(tagItem)
                                        let dataProps = {
                                            // shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                            materialId: record['materialId'],
                                            tags: record['tags'],
                                            roleId: cookie.load('role'),
                                            shopList: cookie.load('shopList'),
                                            tags_options: 2
                                        }
                                        setLoading(true)
                                        NbAxios(dataProps, "POST", servicePath.addTagsShortVideoMaterial).then(res => {
                                            setLoading(false)
                                            if (res.success) {
                                                message.success("删除成功")
                                            } else {
                                                // 删除失败则赋值回来
                                                record['tags'].push(tagItem)
                                                message.warn({ content: res.errorMsg })
                                            }
                                        }).catch((err) => {
                                            // 删除失败则赋值回来
                                            dataSource[index]['tags'].push(tagItem)
                                            setLoading(false)
                                            
                                            // 获取500错误
                                            message.warn('素材表删除标签失败，请联系IT帮忙解决')
                                        })
    
                                    }}>
                                        <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px" }}>
                                            {tagItem.name}
                                        </div>
                                    </Popconfirm>
                                })
                            }
                            <Popover content={addTagsList(record, index)} placement="bottom" zIndex={900} onMouseLeave={() => {
                                setTagsList(tagsData)
                            }}>
                                <PlusSquareOutlined />
                            </Popover>
                        </div>
                    </div>
                }
            },
            {
                align: 'center',
                title: '上传时间',
                dataIndex: 'find_time',
                key: 'find_time',
            },
            {
                align: 'center',
                title: '操作',
                dataIndex: 'act',
                key: 'act',
                render: (text, record, index) => {
                    return <div >
                        <a onClick={() => {
                            // console.log(text)
                            // console.log(record)
                            // console.log(index)
                            // console.log(record["materialId"])
                            setMaterialId(record["materialId"])  //取id
                            setRecord(record)                    //取记录
                            setIndex(index)                     //下标
                            // console.log(record)
                            editForm.setFieldsValue(record)   //默认原数据
                            setIsImage(record["is_image"])    //取is_image
                            setAddress(record["materialId_relative_address"])   //取素材地址
                            setIsModalOpen(true)
                        }}>编辑</a>
                        <Popconfirm title="是否要删除该素材信息" okText="确认" cancelText="取消" onConfirm={()=>onDelectFinish(record["materialId"])}  >
                            <a style={{ marginLeft: '20px' }} onClick={() => {
                                // setMaterialId(record["materialId"])  //取id 
    
                            }}>删除</a>
                        </Popconfirm>
                    </div>
                }
            },
        ])
    },[tagsData, dataSource, tagsList])

    // 添加标签
    const addTags = () => {
        return <div>
            <div style={{ display: 'flex' }}>
                <span style={{ marginRight: "20px", width: '60px', textAlign: "right" }}>名字</span>
                <Input size="small" placeholder='请输入名字' style={{ width: "120px", marginRight: "20px" }} value={tagName} onChange={(e) => {
                    let patrn = new RegExp("(^[\u4E00-\u9FA5A-Za-z0-9 ]+$)");
                    if (!patrn.test(e.target.value) && e.target.value) {
                        message.warning('标签名只能填写汉字、数字、字母、空格')
                    } else {
                        setTagName(e.target.value)
                    }

                }} />

            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <span style={{ marginRight: "20px", width: '60px', textAlign: "right" }}>预览</span>
                <Popover placement="right" content={<Sketch color={tagColor} onChange={(color) => {
                    setTagColor(color.hexa);
                }} />} zIndex={10005}>
                    <div style={{ border: `1px solid ${tagColor}`, color: `${tagColor}`, backgroundColor: `${tagColor.substring(0, 7) + '19'}`, textAlign: 'center', borderRadius: "3px", padding: "0 3px", fontSize: "10px", height: "20.84px" }}>{tagName}</div>
                </Popover>
            </div>
            <div style={{ marginTop: "10px" }}>
                <Button style={{ marginLeft: "80px", borderRadius: "2px" }} size="small" type="primary" onClick={() => {
                    // 校验字符串是否全为空格
                    let reg = /^\s+$/g
                    if (tagName == '') {
                        message.warning("标签名不能为空")
                    } else if (reg.test(tagName)) {
                        message.warning("标签名不能全是空格")
                    } else {
                        addNewTag()
                    }
                }}>确定</Button>
            </div>
        </div>
    }
    // 添加标签
    const addTagsList = (record) => {
        return <div style={{ width: "150px" }}>
            <Input size="small" placeholder="请输入关键字" suffix={<SearchOutlined />} onChange={(e) => {
                setTagsList(tagsData.filter(item => {
                    return item.name.indexOf(e.target.value) != -1
                }))
            }} />
            <div style={{ height: "150px", overflow: "auto", marginTop: "5px" }}>
                {
                    tagsList.map(item => {
                        if(JSON.stringify(record) != '{}'){
                            if (record['tags'].map(obj => {
                            return obj.id
                        }).indexOf(item.id) == -1) {
                            return <Tooltip title={item.name} placement="right">
                                <div style={{ textAlign: "center", height: "25px", cursor: "pointer", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} onClick={() => {
                                    record['tags'].push(item)
                                    let dataProps = {
                                        // shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                        materialId: record['materialId'],
                                        tags: record['tags'],
                                        roleId: cookie.load('role'),
                                        shopList: cookie.load('shopList'),
                                        tags_options: 1
                                    }
                                    setLoading(true)
                                    NbAxios(dataProps, "POST", servicePath.addTagsShortVideoMaterial).then(res => {
                                        setLoading(false)
                                        if (res.success) {
                                            message.success("添加成功")
                                        } else {
                                            record['tags'].remove(item)
                                            message.warn({ content: res.errorMsg })
                                        }
                                    }).catch((err) => {
                                        record['tags'].remove(item)
                                        setLoading(false)
                                        // 获取500错误
                                        message.warn('素材表添加标签报错，请联系IT帮忙解决')
                                    })
                                }}>{item.name}</div>
                            </Tooltip>
                        }
                        }
                        

                    })
                }
            </div>
            <div style={{ borderTop: "1px solid #ccc", display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
                <Button size="small" icon={<PlusSquareOutlined />} type="primary" onClick={() => {
                    // setTableRows(index)
                    setIsEditTags(true)
                }}>添加标签</Button>
            </div>
        </div>
    }
    // 添加新标签
    const addNewTag = () => {
        if (tagName == '') {
            message.warning("标签名未输入")
        } else if (tagsData.map(item => {
            return item.name
        }).indexOf(tagName) !== -1) {
            message.warn("标签已存在")

        } else {
            let obj = [...tagsData]
            obj.push({
                group: "",
                name: tagName,
                color: tagColor,
            })
            setTagsData(obj)
            setAllChooseTagsList(obj)
            setTagName('')
            setTagColor('')
        }
    }
    // 图片查看
    const imageLookContent = (is_image,address) => {
        return <div onMouseLeave={()=>{
            videoRef.current.pause()
        }}>
            {is_image == 0?
            <video controlslist="nodownload" ref={videoRef} src={`https://pic.bmcsoft.cn/shortvideo/material_library/`+address} width="180" height="320" controls={true} autoPlay preload="auto" style={{ objectFit: 'fill' }}></video>:
            // <video width="320" height="240" controls>
            //     <source src={`https://pic.bmcsoft.cn/shortvideo/material_library/`+address} type='video/mp4'></source>
            // </video>:                
            <img src={`https://pic.bmcsoft.cn/shortvideo/material_library/`+address} style={{ width: '180px', height: '320px' }} />
            }
        </div>
    }


    return (
        <div style={{ backgroundColor: '#F5F5F5', height: "calc(100vh - 40px)", overflow: 'auto' }}>
            <div className={style.externalContent}>
                {/* <p  style={{  fontSize: '20px',}} >短视频素材表</p> */}
                <div style={
                    {
                        display: 'flex',
                        justifyContent: "space-around",
                        backgroundColor: "#fff",
                        borderBottom: "solid 2px #ccc",
                        marginBottom: 10,
                        // position: 'fixed',
                        // top: 1,
                        // zIndex: 99,
                        width: '100%',
                        margin: '5px auto 0',
                        // marginTop: '20px',
                        // paddingTop:'20px'
                    }
                } >
                    <Title style={{ marginLeft: "10px" }} level={4} > 短视频素材表 </Title>
                    <div style={{ marginRight: "10px" }}><Button type="primary" style={{ marginTop: 3 }} onClick={() => { setIsModalOpenSelect(true) }} > 查询 </Button></div>
                </div>
                <div style={{ margin: '0 auto', backgroundColor: '#fff', marginTop: '40px' }}>
                    <Table size="small" dataSource={dataSource} columns={columns} pagination={paginationProps} />
                </div>
            </div>
            {/* 条件查找抽屉 */}
            <Drawer title="条件查找" visible={isModalOpenSelect} onClose={handleCancelSelect}>
                <div >
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "80px" }}>文件名称:</div>
                        <Input value={selectNameValue} onChange={(e) => setSelectNameValue(e.target.value)} placeholder='请输入名称' style={{ width: "160px", marginLeft: "20px" }} />

                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <div style={{ width: "80px" }}>文件类型:</div>
                        <Select style={{ width: "160px", marginLeft: "20px" }} value={categoryLevelSelect} onChange={value => setCategoryLevelSelect(value)} >
                            {
                                categoryLevel.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>

                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <div style={{ width: "80px" }}>标签:</div>
                        <Select mode="multiple" allowClear maxTagCount='responsive' style={{ width: "160px", marginLeft: "20px" }} value={categoryLabelSelect} onChange={value => setCategoryLabelSelect(value)}>
                            {
                                tagsData.map(item => {
                                    return <Option value={item.id + ',' + item.name}>{item.name}</Option>
                                })
                            }
                        </Select>

                    </div>
                    <div style={{ marginTop: "100px" }}>
                        <Button type="primary" danger onClick={()=>{
                            setSelectNameValue('')
                            setCategoryLabelSelect([])
                            setCategoryLevelSelect('')
                            let dataProps = {
                                "show_name": '',
                                "file_type": '',
                                "tags": [],
                                "page": 1,
                                'shopList': cookie.load('shopList'),
                                'roleId': cookie.load('role'),
                            }
                            setLoading(true)
                            // console.log(dataProps)
                            NbAxios(dataProps, "POST", servicePath.selectShortVideoMaterial).then(res => {
                                setLoading(false)
                                if (res.success) {
                                    setPage(1)
                                    handleCancelSelect()
                                    setDataSource(res.data)
                                } else {
                                    message.warn({ content: res.errorMsg })
                                }
                            }).catch((err) => {
                                setLoading(false)
                                // 获取500错误
                                message.warn('查询素材表报错，请联系IT帮忙解决')
                            })
                        }}>重置</Button>
                        <Button type="primary" style={{ marginLeft: "20px" }} onClick={() => { getSelectData() }} >查询</Button>
                    </div>

                </div>
            </Drawer >
            {/* 编辑标签弹窗 */}
            <Modal
                title="编辑标签"
                visible={isEditTags}
                onOk={() => {
                    editTags()
                }}
                onCancel={() => {
                    setIsEditTags(false)
                }}
                okText="确定"
                cancelText="取消"
                centered={true}
                width={"640px"}
            >
                <Spin tip='数据请求中，请稍等...' spinning={loading}  >
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        border: "1px solid #ccc",
                        height: "267px",
                        justifyContent: 'flex-start',
                        alignContent: "flex-start", // 子元素之间取消空白间隙，并把项目放在容器顶部
                        alignItems: "baseline",
                        padding: "10px",
                        margin: "10px"
                    }}>
                        {
                            tagsData.map(tagItem => {
                                return <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                    let obj = [...tagsData]
                                    obj.remove(tagItem)
                                    setTagsData(obj)
                                }}>
                                    <div style={{
                                        border: `1px solid ${tagItem['color'] ? tagItem['color'] : '#ccc'}`,
                                        color: `${tagItem['color'] ? tagItem['color'] : '#ccc'}`,
                                        backgroundColor: `${tagItem['color'] == null || tagItem['color'] == "None" ? '#ccc' : tagItem['color'].substring(0, 7) + '19'}`,
                                        padding: "0 3px",
                                        textAlign: 'center',
                                        borderRadius: "3px",
                                        fontSize: "10px",
                                        writingMode: "horizontal-tb",
                                        marginRight: "10px",
                                        marginTop: "10px"
                                    }}
                                    >
                                        {tagItem.name}
                                    </div>
                                </Popconfirm>
                            })
                        }
                    </div>
                    <Popover content={addTags()} placement="right" trigger="click" zIndex={10002}>
                        <Button icon={<PlusSquareOutlined />} type="primary" style={{ borderRadius: "2px", marginTop: "10px" }}>添加标签</Button>
                    </Popover>
                </Spin>

            </Modal>
            {/* 编辑素材弹窗 */}
            <Modal title="编辑素材" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <div>
                    {/* <div><p>{`https://pic.bmcsoft.cn/shortvideo/material_library/`+address}</p></div> */}
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                    <div style={{width:'100%'}}>
                        {isImage == 0?
                            <div style={{display:'flex', justifyContent:"center"}}>
                                <video controlslist="nodownload" ref={videoRef} src={`https://pic.bmcsoft.cn/shortvideo/material_library/`+address} width="180" height="320" controls={true} preload="auto" style={{ objectFit: 'fill', }}></video>
                            </div>:
                            // <video src={`https://pic.bmcsoft.cn/shortvideo/material_library/`+address} width="320" height="240" controls={true} autoPlay preload="auto" style={{ objectFit: 'fill' }}>
                            //     {/* <source src={`https://pic.bmcsoft.cn/shortvideo/material_library/`+address} type='video/mp4'></source> */}
                            // </video>:
                            <img src={`https://pic.bmcsoft.cn/shortvideo/material_library/`+address} style={{ width: '180px', height: '320px' }} />
                        }
                        <Divider />
                    </div>
                    </div>

                    <Form onFinish={onEditFinish} form={editForm}>
                        <div style={{ display: "flex", marginTop: '30px' }}>
                            <Form.Item label="文件名称" name="show_name">
                                <Input placeholder='请输入名称' style={{ width: "120px", marginLeft: "20px" }} />
                            </Form.Item>


                            <Form.Item label="文件类型" name="file_type" style={{ marginLeft: "20px" }}>
                                <Select style={{ width: "120px", marginLeft: "20px" }} placeholder="请选择" >
                                    {
                                        categoryLevel.map(item => {
                                            return <Option value={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div>
                            <b style={{fontSize:"20px"}}>标签:</b>
                            {/*<Popover content={addTagsList(record)} placement="bottom" zIndex={9000} onMouseLeave={() => {*/}
                            {/*    setTagsList(tagsData)*/}
                            {/*}}>*/}
                            {/*    <PlusSquareOutlined style={{ marginLeft: "20px" }} />*/}
                            {/*</Popover>*/}
                            <div style={{ display: 'flex', alignItems: "center", flexWrap: 'wrap', padding: "0px" , marginTop:"5px"}}>
                                {
                                    JSON.stringify(record)==="{}"?<p></p>: record['tags'].map((tagItem) => {
                                        /**
                                         * 鼠标右键事件 trigger="contextMenu"
                                         */
                                        return <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                            dataSource[index]['tags'].remove(tagItem)
                                            let dataProps = {
                                                // shop_name: shopDict[props.history.location.pathname.split('/')[2]],
                                                materialId: record['materialId'],
                                                tags: record['tags'],
                                                roleId: cookie.load('role'),
                                                shopList: cookie.load('shopList'),
                                                tags_options: 2
                                            }
                                            setLoading(true)
                                            NbAxios(dataProps, "POST", servicePath.addTagsShortVideoMaterial).then(res => {
                                                setLoading(false)
                                                if (res.success) {
                                                    message.success("删除成功")
                                                } else {
                                                    // 删除失败则赋值回来
                                                    dataSource[index]['tags'].push(tagItem)
                                                    message.warn({ content: res.errorMsg })
                                                }
                                            }).catch((err) => {
                                                // 删除失败则赋值回来
                                                dataSource[index]['tags'].push(tagItem)
                                                setLoading(false)

                                                // 获取500错误
                                                message.warn('素材表删除标签失败，请联系IT帮忙解决')
                                            })

                                        }}>
                                            <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px" }}>
                                                {tagItem.name}
                                            </div>
                                        </Popconfirm>
                                    })
                                }
                                <Popover content={addTagsList(record, index)} placement="bottom" zIndex={1300} onMouseLeave={() => {
                                    setTagsList(tagsData)
                                }}>
                                    <PlusSquareOutlined />
                                </Popover>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-around", marginTop: '30px' }}>
                            <Button type="primary" danger onClick={() => { handleCancel() }} >
                                取消
                            </Button>
                            <Button type="primary" htmlType="submit">
                                确定
                            </Button>
                        </div>

                    </Form>


                </div>
            </Modal>
        </div>

    )









}

export default Index