import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Tag, Form, Select, Space } from 'antd'
import { EditOutlined, DeleteOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import servicePath from '../../config/apiUrl'
import Highlighter from 'react-highlight-words'
import cookie from 'react-cookies'
import axios from 'axios'   
import '../../static/css/Table.css'
import '../../static/css/index.css'
const { confirm } = Modal
const { Option } = Select

function Role(props) {

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };


    const [form] = Form.useForm();

    //页面
    const [page, setPage] = useState(1)

    //角色数据
    const [data, setData] = useState([])

    //备份
    const [backups, setBackups] = useState([])

    //角色名字
    const [userName, setUserName] = useState('')

    //角色描述
    const [userDesc, setUserDesc] = useState('')

    //角色id
    const [id, setId] = useState(0)

    //有无水印
    const [watermark, setWatermark] = useState('')

    //设置添加角色是否打开
    const [visible, setVisible] = useState(false)

    //设置修改角色是否打开
    const [isUpdate, setIsUpdate] = useState(false)

    // 筛选字段
    const [searchText, setSearchText] = useState('')

    // 筛选关键词
    const [searchedColumn, setSearchedColumn] = useState('')

    // 筛选信息
    const [searchMessage, setSearchMessage] = useState('暂无筛选信息')

    //第一次筛选的关键字
    const [column1, setColumn1] = useState('')

    //第一次筛选的值
    const [text1, setText1] = useState('')

    //筛选次数
    const [count, setCount] = useState(0)

    // 筛选功能
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        // searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
            </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
            </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                // setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    // 根据筛选信息更新数据
    useEffect(() => {

        if (searchedColumn == "name") {
            if (count == 1) {
                let newData = backups.filter(item => item.name.toString().toLowerCase().includes(searchText.toLowerCase()))
                setData(newData)
            }
        } else {
            setData(backups)
            let newMessage = '暂无筛选信息'
            setSearchMessage(newMessage)
        }
    }, [searchText, searchedColumn])

    // 筛选选择
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        // 将次数保存到新的值
        let newCount = count
        if (newCount == 0) {
            //第一次筛选，之前没有关键字查询，直接次数加一
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
            //保存第一次筛选的关键字和值
            setColumn1(dataIndex)
            setText1(selectedKeys[0])
            newCount = newCount + 1;
            let newMessage = "筛选条件:在"+"\xa0\xa0"+"角色名称"+"\xa0\xa0"+"筛选" + selectedKeys[0]
            setSearchMessage(newMessage)
        } else {
            //有一个关键字查询，判断是否是同一个字段查询
            if (column1 == dataIndex) {
                //如果是都一个字段，次数不进行改变
                setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                setColumn1(dataIndex)
                setText1(selectedKeys[0])
                let newMessage ="筛选条件:在"+"\xa0\xa0"+"角色名称"+"\xa0\xa0"+"筛选" + selectedKeys[0]
                setSearchMessage(newMessage)
            }
        }
        //保存次数
        setCount(newCount)
    };

    // 筛选清空
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
        setSearchedColumn('')
        // 次数减一
        let newCount = count - 1;
        setCount(newCount)
    };


    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })


    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    //设置表格标题
    const columns = [
        {
            title: '角色名称',
            key: 'name',
            dataIndex: 'name',
            width: '400px',
            align: 'center',
            ...getColumnSearchProps('name'),
        },
        {
            title: '角色描述',
            key: 'desc',
            dataIndex: 'desc',
            width: '400px',
            align: 'center'
        }, {
            title: '水印权限',
            key: 'watermark',
            dataIndex: 'watermark',
            width: '400px',
            align: 'center',
            render: (text, record, index) => (
                text == "e163030e-ef1c-4ae9-8d4c-b0baba319e2b" ? <Tag color="red">有水印</Tag>
                    : <Tag color="blue">无水印</Tag>
            ),
        },
        {
            title: '状态',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == true ? <Tag color="red">禁用</Tag>
                    : <Tag color="blue">正常</Tag>
            ),
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={() => updateRole(index)} />
            ),
        },
        {
            title: '禁用/恢复',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == true ? <RedoOutlined onClick={() => recoverRole(index)} />
                    : <DeleteOutlined onClick={() => deleteRole(index)} />
            ),
        }

    ];

    // 获取所有角色
    useEffect(() => {
        queryAllRoles()
    }, [])

    //查询全部角色
    const queryAllRoles = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryRoles,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    if (count == 0) {
                        // 没有筛选的时候，数据就不进行处理
                        setData(res.data['data']);
                        setBackups(res.data['data'])
                    } else {
                        // 有筛选的时候，数据进行过滤
                        let newData = res.data['data'].filter(item => item.name.toString().toLowerCase().includes(searchText.toLowerCase()))
                        setData(newData)
                        setBackups(res.data['data'])
                    }
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //弹出添加角色弹框
    const addRole = () => {
        setVisible(true)
    }

    //确认添加角色，并且关闭弹框
    const handleOk = e => {
        if (userName == '') {
            message.error('角色名不能为空')
        } else if (userDesc == '') {
            message.error('角色描述不能为空')
        } else if (watermark == '') {
            message.error('角色水印权限不能为空')
        } else {
            //向后端传递的参数
            let dataProps = {
                'name': userName,
                'desc': userDesc,
                'watermark': watermark,
                "role_Id": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }

            axios({
                method: 'POST',
                url: servicePath.addRole,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        //提示用户成功添加角色
                        message.success('成功添加角色')
                        setUserName('')
                        setUserDesc('')
                        setWatermark('')
                        //关闭弹框
                        setVisible(false)
                        //重新请求角色列表
                        queryAllRoles()
                    } else {
                        //提示用户添加角色失败
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }

                    }

                }
            )
        }

    }

    //取消添加角色，并且关闭弹框
    const handleCancel = e => {
        message.success('取消添加角色')
        setUserName('')
        setUserDesc('')
        setWatermark('')
        setVisible(false)
    }

    // 删除角色操作
    const deleteRole = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        //获取角色id
        let roleId = data[index]['id']

        confirm({
            title: '确定禁用这个角色',
            okText: '确定',
            cancelText: '取消',
            onOk() {

                //向后端传递的参数
                let dataProps = {
                    'roleId': roleId.toString(),
                    "role_Id": cookie.load('role'),
                    "shopId": cookie.load('shopList')
                }
                axios({
                    method: 'POST',
                    url: servicePath.deleteRole,
                    data: dataProps,
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization':cookie.load('token')
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功禁用这个角色")
                            queryAllRoles()
                        }else {
                            if(res.data['errorCode'] == '10001'){
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            }else{
                                message.error(res.data['errorMsg'])
                            }
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消禁用这个角色')
            }
        })
    }

    // 恢复角色操作
    const recoverRole = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        //获取角色id
        let roleId = data[index]['id']

        confirm({
            title: '确定恢复这个角色',
            okText: '确定',
            cancelText: '取消',
            onOk() {

                //向后端传递的参数
                let dataProps = {
                    'roleId': roleId.toString(),
                    "role_Id": cookie.load('role'),
                    "shopId": cookie.load('shopList')
                }
                axios({
                    method: 'POST',
                    url: servicePath.recoverRole,
                    data: dataProps,
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization':cookie.load('token')
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功恢复这个角色")
                            queryAllRoles()
                        }else {
                            if(res.data['errorCode'] == '10001'){
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            }else{
                                message.error(res.data['errorMsg'])
                            }
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消恢复这个角色')
            }
        })
    }

    //弹出更新角色弹框
    const updateRole = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        form.resetFields()
        setId(data[index]['id'])
        setUserName(data[index]['name'])
        setUserDesc(data[index]['desc'])
        setWatermark(data[index]['watermark'])
        setIsUpdate(true)
    }


    //更新角色
    const updateOk = () => {
        if (userName == '') {
            message.error('角色名不能为空')
        } else if (userDesc == '') {
            message.error('角色描述不能为空')
        } else if (watermark == '') {
            message.error('角色水印权限不能为空')
        } else {
            let dataProps = {
                'id': id,
                'name': userName,
                'desc': userDesc,
                'watermark': watermark,
                "role_Id": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.updateRole,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success('成功修改角色')
                        setId('')
                        setUserName('')
                        setUserDesc('')
                        setWatermark('')
                        queryAllRoles()
                        setIsUpdate(false)
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }


    }

    //取消更新角色
    const updateCancel = () => {
        message.success('取消修改角色')
        setId('')
        setUserName('')
        setUserDesc('')
        setWatermark('')
        setIsUpdate(false)
    }


    return (
        <>
            {/* 导航区域 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.02 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                    <Breadcrumb.Item>角色管理</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* 按钮区域 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06 }}>
                <Row>
                    <Col span={3}>
                        <Button type="primary" onClick={() => addRole()}>新增角色</Button>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06 }}>
                <div style={{ marginTop: '20px', marginBottom: "10px" }}><Tag color="pink" style={{ height: "30px", lineHeight: "30px", fontSize: "20px" }}>{searchMessage}</Tag></div>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={data}
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                />
            </div>
            {/* 对话框 添加角色 */}
            <Modal
                title="添加角色"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="确认添加"
                cancelText="取消添加"
            >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="角色名称" name="userName">
                        {/* 角色名称 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入角色名称'
                            placement="topLeft"
                        >
                            <Input
                                id="userName"
                                size="default"
                                placeholder="请输入角色名称"
                                value={userName}
                                onChange={(e) => {
                                    setUserName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="角色描述" name="userDesc">
                        {/* 角色描述 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入角色描述'
                            placement="topLeft"
                        >
                            <Input
                                id="userDesc"
                                size="default"
                                placeholder="请输入角色描述"
                                value={userDesc}
                                onChange={(e) => {
                                    setUserDesc(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="水印权限" name="watermark">
                        {/* 选择父菜单 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择是否有水印"
                            placement="topLeft"
                        >
                            <Select placeholder="请选择是否有水印" style={{ width: 310 }} onChange={(value) => setWatermark(value)}>
                                <Option key='e163030e-ef1c-4ae9-8d4c-b0baba319e2b' value='e163030e-ef1c-4ae9-8d4c-b0baba319e2b'>有水印</Option>
                                <Option key='a0a596e6-b2f7-4ee5-bb80-eb61f1824eb0' value='a0a596e6-b2f7-4ee5-bb80-eb61f1824eb0'>无水印</Option>
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 对话框 修改角色 */}
            <Modal
                title="修改角色"
                visible={isUpdate}
                onOk={updateOk}
                onCancel={updateCancel}
                okText="确认修改"
                cancelText="取消修改"
            >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="角色名称" name="userName">
                        {/* 角色名称 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入角色名称'
                            placement="topLeft"
                        >
                            <Input
                                id="userName"
                                size="default"
                                placeholder="请输入角色名称"
                                value={userName}
                                onChange={(e) => {
                                    setUserName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="角色描述" name="userDesc">
                        {/* 角色描述 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入角色描述'
                            placement="topLeft"
                        >
                            <Input
                                id="userDesc"
                                size="default"
                                placeholder="请输入角色描述"
                                value={userDesc}
                                onChange={(e) => {
                                    setUserDesc(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="水印权限" name="watermark">
                        {/* 选择父菜单 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择是否有水印"
                            placement="topLeft"
                        >
                            <Select defaultValue={watermark} placeholder="请选择是否有水印" style={{ width: 310 }} onChange={(value) => setWatermark(value)}>
                                <Option key='e163030e-ef1c-4ae9-8d4c-b0baba319e2b' value='e163030e-ef1c-4ae9-8d4c-b0baba319e2b'>有水印</Option>
                                <Option key='a0a596e6-b2f7-4ee5-bb80-eb61f1824eb0' value='a0a596e6-b2f7-4ee5-bb80-eb61f1824eb0'>无水印</Option>
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default Role