import React, {useState, useEffect, useContext} from 'react';
import {
    Table,
    Progress,
    Button,
    DatePicker,
    message,
    Form,
    Popover,
    Input,
    Row,
    Modal,
    Upload,
    Select,
    Collapse,
    Typography
} from 'antd'
import {InboxOutlined, DownloadOutlined} from '@ant-design/icons';
import NbAxios from '../../../config/utils/nbAxios'
import servicePath from '../../../config/apiUrl'
import {StateContext} from '../../raceShop/store/stateShare'
import cookie from 'react-cookies'
import moment from "moment"
import Base64 from 'base-64'
import locale from 'antd/es/date-picker/locale/zh_CN';
import downExcel from '../../../config/utils/downloadFile'
import Axios from 'axios'
const {Dragger} = Upload;
const {Option} = Select;
const {Panel} = Collapse;
const {Text} = Typography;
function Index() {

    // 折叠面板key
    const [collapseKey, setCollapseKey] = useState('')

    const dateFormat = 'YYYY-MM'
    // 搜索事业部
    const [division, setDivision] = useState('')
    const [divisionList, setDivisionList] = useState([])
    // 搜索板块
    const [plate, setPlate] = useState('')
    const [plateList, setPlateList] = useState([])
    // 搜索项目
    const [project, setProject] = useState('')
    const [searchProject, setSearchProject] = useState('')
    const [searchProjectTag, setSearchProjectTag] = useState(0)
    // 搜索店铺
    const [shop, setShop] = useState('')
    const [searchShop, setSearchShop] = useState('')
    const [searchShopTag, setSearchShopTag] = useState(0)
    // 项目输入框
    const projectContent = (
        <div>
            <div style={{ display: 'flex', }}>
                <div><Input style={{ width: 140 }} value={searchProject} onChange={(e) => { setSearchProject(e.target.value) }} /></div>
                <div style={{ lineHeight: '30px', marginLeft: 10, fontSize: '15px' }}><a onClick={() => {
                    setProject(searchProject)
                    setSearchProjectTag(1)
                    setCloseProjectBubble(false)
                }}>确定</a></div>
                <div style={{ lineHeight: '30px', marginLeft: 5, fontSize: '15px' }}><a onClick={() => {
                    setProject('')
                    setSearchProject('')
                    setSearchProjectTag(0)
                    setCloseProjectBubble(false)
                }}>重置</a></div>
            </div>
        </div>
    )

    // 店铺输入框
    const shopContent = (
        <div>
            <div style={{ display: 'flex', }}>
                <div><Input style={{ width: 140 }} value={searchShop} onChange={(e) => { setSearchShop(e.target.value) }} /></div>
                <div style={{ lineHeight: '30px', marginLeft: 10, fontSize: '15px' }}><a onClick={() => {
                    setShop(searchShop)
                    setSearchShopTag(1)
                    setCloseShopBubble(false)
                }}>确定</a></div>
                <div style={{ lineHeight: '30px', marginLeft: 5, fontSize: '15px' }}><a onClick={() => {
                    setShop('')
                    setSearchShop('')
                    setSearchShopTag(0)
                    setCloseShopBubble(false)
                }}>重置</a></div>
            </div>
        </div>
    )

    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    //总数
    const [total, setTotal] = useState()
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    //页数
    const [page, setPage] = useState(1)
    // 本月开始日期
    const [monthStart, setMonthStart] = useState(moment().startOf('month'))
    // 本月结束日期
    const [monthEnd, setMonthEnd] = useState(moment().endOf('month'))
    // 表格列
    const [tableColumns, setTableColumns] = useState([])
    // 关闭气泡
    const [closeProjectBubble, setCloseProjectBubble] = useState(false)
    const [closeShopBubble, setCloseShopBubble] = useState(false)
    // 是否上传预算目标
    const [uploadBudgetTarget, setUploadBudgetTarget] = useState(false)
    // 预算目标文件
    const [budgetTargetFile, setBudgetTargetFile] = useState([])
    // 是否上传数据汇总
    const [uploadDataSummary, setuploadDataSummary] = useState(false)
    //  数据汇总文件
    const [dataSummaryFile, setDataSummaryFile] = useState([])
    //数据
    const [data, setData] = useState([])
    // 按钮是否有效
    const [effective, setEffective] = useState(false)
    //表头
    const columns = [
        {
            title: '事业部',
            dataIndex: 'division',
            align: 'center',
            width: 80,
            fixed: 'left',
            render(_, row) {
                return {
                    children: row.division,
                    props: {
                        rowSpan: row.rowDivisionSpan,
                    }
                }
            }
        },
        {
            title: '板块',
            dataIndex: 'plate',
            align: 'center',
            width: 80,
            fixed: 'left',
            render(_, row) {
                return {
                    children: row.plate,
                    props: {
                        rowSpan: row.rowPlateSpan,
                    }
                }
            }
        },
        {
            title: '项目',
            dataIndex: 'project',
            align: 'center',
            width: 80,
            fixed: 'left',
            render(_, row) {
                return {
                    children: row.project,
                    props: {
                        rowSpan: row.rowSpan,
                    }
                }
            }
        },
        {
            title: '店铺名称',
            dataIndex: 'shop',
            align: 'center',
            width: 100,
            fixed: 'left',
            render(_, row) {
                return {
                    children: row.shop,
                    props: {
                        rowSpan: row.rowShopSpan,
                    }
                }
            }
        },
        {
            title: '',
            align: 'center',
            width: 80,
            fixed: 'left',
            dataIndex: 'dimension',
        },
        {
            title: '当月目标',
            align: 'center',
            width: 90,
            fixed: 'left',
            dataIndex: 'achieveGoals',
            render: (text, record, index) => (
                record['dimension'] != '退款' ? <span>{parseInt(text)}</span> : <span></span>
            ),
        },
        {
            title: '完成进度',
            align: 'center',
            width: 150,
            fixed: 'left',
            dataIndex: 'completeSchedule',
            render: (text, record, index) => (
                record['dimension'] != '退款' ?
                    <span style={{display: "flex"}}>
                        <Progress
                            percent={text.replace("%", "")}
                            size="small"
                            style={{width: 100}}
                            showInfo={false}
                        />
                       <Text type="secondary" style={{marginLeft: 5}}>{text}</Text>
                    </span>

                    : <span>退款率:{record['refund_rate']} </span>

            ),
        },
        {
            title: '当月完成',
            align: 'center',
            width: 100,
            fixed: 'left',
            dataIndex: 'complete',
        },
        ...tableColumns.map((item) => {
            return {
                title: item,
                dataIndex: `${monthEnd.format('YYYY')}-${item}`,
                key: item,
                align: 'center',
                width: 100,
                onCell: (value) => {
                    let s = value[`${monthEnd.format('YYYY')}-${item}`];
                    return (
                        {
                            style: {
                                padding: 0,
                                height: "20px",
                                color: parseFloat(s) < 0 ? "red" : "black"
                            }
                        }
                    )
                },
                render: (item, record, index) => {
                    if (item) {
                        return item
                    } else {
                        if (item === 0) {
                            return 0
                        } else {
                            return "-"
                        }
                    }
                }
            }
        })
    ]

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 9, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 日期发生改变的时候
    useEffect(() => {
        let res = getBetweenDateStr(monthStart.format('YYYY-MM-DD'), monthEnd.format('YYYY-MM-DD'))
        setTableColumns(res)
        if (page == 1) {
            getData()
        } else {
            setPage(1)
        }
    }, [monthStart, monthEnd])

    // 查询事业部、店铺、项目改变的时候
    useEffect(() => {
        if (page == 1) {
            getData()
        } else {
            setPage(1)
        }
    }, [shop, project, division, plate])

    // 页数修改的时候
    useEffect(() => {
        getData()
    }, [page])

    useEffect(()=>{
        // 获取接口权限
        queryJurisdiction()
        // 获取事业部
        queryDivisionPlate()
    },[])

    // 获取事业部、板块
    const queryDivisionPlate = ()=>{
        let dataProps = {
            "role_id": cookie.load('role'),
        }
        NbAxios(dataProps,"POST", servicePath.queryDivisionPlate).then(res=>{
            if(res.success){
                setDivisionList(res.data['division']);
                setPlateList(res.data['plate']);
            }else{
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取接口权限
    const queryJurisdiction = ()=>{
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        NbAxios(dataProps,"POST", servicePath.getRoleShopPorts).then(res=>{
            if(res.success){
                setJurisdiction(res.data);
            }else{
                message.warn(res.errorMsg)
            }
        })
    }

    //获取数据
    const getData = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "end_date": monthEnd.format('YYYY-MM-DD'),
            "start_date": monthStart.format('YYYY-MM-DD'),
            'plate': plate,
            'division':division,
            "project": project,
            "shop": shop,
            "page": page,
            "page_size": 3
        }
        NbAxios(dataProps, "POST", servicePath.querySalesPerformance).then(res => {
            if (res.success) {
                setTotal(res.total)
                setData(res.data);
                setCollapseKey('')
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    const createProjectNewArr = (data) => {
        return data.reduce((result, item) => {
            //首先将name字段作为新数组result取出
            if (result.indexOf(item.project) < 0) {
                result.push(item.project)
            }
            return result
        }, []).reduce((result, project, shop) => {
            //将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
            const children = data.filter(item => item.project === project);
            result = result.concat(
                children.map((item, index) => ({
                    ...item,
                    rowSpan: index === 0 ? children.length : 0,//将第一行数据添加rowSpan字段
                })),
            )
            return result;
        }, [])
    }

    const createShopNewArr = (data) => {
        return data.reduce((result, item) => {
            //首先将name字段作为新数组result取出
            if (result.indexOf(item.shop) < 0) {
                result.push(item.shop)
            }
            return result
        }, []).reduce((result, shop) => {
            //将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
            const children = data.filter(item => item.shop === shop);
            result = result.concat(
                children.map((item, index) => ({
                    ...item,
                    rowShopSpan: index === 0 ? children.length : 0,//将第一行数据添加rowSpan字段
                })),
            )
            return result;
        }, [])
    }

    const createDivisionNewArr = (data) => {
        return data.reduce((result, item) => {
            //首先将name字段作为新数组result取出
            if (result.indexOf(item.division) < 0) {
                result.push(item.division)
            }
            return result
        }, []).reduce((result, division) => {
            //将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
            const children = data.filter(item => item.division === division);
            result = result.concat(
                children.map((item, index) => ({
                    ...item,
                    rowDivisionSpan: index === 0 ? children.length : 0,//将第一行数据添加rowSpan字段
                })),
            )
            return result;
        }, [])
    }

    const createPlateNewArr = (data) => {
        return data.reduce((result, item) => {
            //首先将name字段作为新数组result取出
            if (result.indexOf(item.plate) < 0) {
                result.push(item.plate)
            }
            return result
        }, []).reduce((result, plate) => {
            //将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
            const children = data.filter(item => item.plate === plate);
            result = result.concat(
                children.map((item, index) => ({
                    ...item,
                    rowPlateSpan: index === 0 ? children.length : 0,//将第一行数据添加rowSpan字段
                })),
            )
            return result;
        }, [])
    }

    // 不可选的周
    function disabledMonth(current) {
        // 不可选2020年得数据以及后一月的数据
        // return current <= moment('2020-12-31') || current > moment().endOf("month");
        return current > moment().endOf("month");
    }

    //月份改变
    function monthChange(date, dateString) {
        if (date === null || date === '' || date === undefined) {
            message.warn('请选择正确的月份')
            return false
        }
        setMonthStart(moment(dateString).startOf('month'));
        setMonthEnd(moment(dateString).endOf('month'));
    }

    // 预算目标文件上传
    const budgetTargetFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setBudgetTargetFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setBudgetTargetFile(fileList)
        }
    }

    // 汇总数据文件上传
    const dataSummaryFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setDataSummaryFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setDataSummaryFile(fileList)
        }
    }

    //上传预算目标
    const uploadBudgetTargetFile = ()=>{
        if(budgetTargetFile.length == 0){
            message.warn('请选择您要上传的文件')
        }else{
            setEffective(true)
            // 上传原始文件例子
            const formData = new FormData()
            // 多个图片
            budgetTargetFile.forEach((item) => {
                formData.append('file ', item['originFileObj']);
            })
            formData.append('shopId', cookie.load('shopList'))
            formData.append('roleId', cookie.load('role'))
            formData.append('principal_name', cookie.load('name'))
            formData.append('principal_uuid', cookie.load('id'))
            formData.append('principal_email', Base64.decode(cookie.load('email')))
            NbAxios(formData, "POST", servicePath.uploadBudgetTarget).then(res => {
                setEffective(false)
                if(res.success){
                    message.success('数据上传成功')
                    setUploadBudgetTarget(false)
                    setBudgetTargetFile([])
                    getData()
                }else{
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    //上传汇总数据
    const uploadDataSummaryFile = ()=>{
        if(dataSummaryFile.length == 0){
            message.warn('请选择您要上传的文件')
        }else{
            setEffective(true)
            // 上传原始文件例子
            const formData = new FormData()
            // 多个图片
            dataSummaryFile.forEach((item) => {
                formData.append('file ', item['originFileObj']);
            })
            formData.append('shopId', cookie.load('shopList'))
            formData.append('roleId', cookie.load('role'))
            formData.append('principal_name', cookie.load('name'))
            formData.append('principal_uuid', cookie.load('id'))
            formData.append('principal_email', Base64.decode(cookie.load('email')))
            NbAxios(formData, "POST", servicePath.uploadDataSummary).then(res => {
                setEffective(false)
                if(res.success){
                    message.success('数据上传成功')
                    setuploadDataSummary(false)
                    setDataSummaryFile([])
                    getData()
                }else{
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    //下载业绩报表
    const download=()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "end_date": monthEnd.format('YYYY-MM-DD'),
            "start_date": monthStart.format('YYYY-MM-DD'),
        }
        Axios({
            method: 'POST',
            url: servicePath.downloadSalesPerformance,
            data: dataProps,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
        }).then(res => {
            downExcel(res)
        }).catch(e => {
            message.error({content: '文件制作出错，请联系it同事！'})
        })

    }

    //下载业绩汇总上传模版
    const downloadDataSummary = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "end_date": monthEnd.format('YYYY-MM-DD'),
            "start_date": monthStart.format('YYYY-MM-DD'),
        }
        Axios({
            method: 'POST',
            url: servicePath.downloadDataSummary,
            data: dataProps,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
        }).then(res => {
            downExcel(res)
        }).catch(e => {
            message.error({content: '文件制作出错，请联系it同事！'})
        })
    }

    return (
        <div>
            <div style={{marginLeft: 20, marginTop: 10, display: 'flex'}}>
                <Button type="primary" onClick={() => download()} style={{
                    marginRight: 10,
                    display: jurisdiction.indexOf("sale::downloadPerformance") != -1 ? "" : "none"
                }}>下载业绩报表</Button>
                <Button type="primary" style={{marginRight: 10}}
                        onClick={() => setuploadDataSummary(true)}>上传每周数据汇总</Button>
                <Button type="primary" style={{
                    marginRight: 10,
                    display: jurisdiction.indexOf("sale::uploadExpected") != -1 ? "" : "none"
                }} onClick={() => setUploadBudgetTarget(true)}>上传预算目标</Button>
                <Button type="link" onClick={() => downloadDataSummary()}>
                    下载业绩数据汇总上传模版
                </Button>
                <Button type="link" style={{display: jurisdiction.indexOf("sale::uploadExpected") != -1 ? "" : "none"}}>
                    <a href={"https://pic.bmcsoft.cn/imge_dir/预算目标上传模版.xlsx"}>下载预算目标上传模版</a>
                </Button>
            </div>
            <Collapse style={{marginLeft: 20, marginTop: 10}} activeKey={collapseKey} onChange={(key) => {
                setCollapseKey(key);
            }}>
                <Panel header="查询条件" key="1">
                    <div style={{marginTop: 10, display: 'flex'}}>
                        <Form>
                            <Row>
                                <Form.Item label="月份" style={{marginLeft: 30}}>
                                    <DatePicker
                                        locale={locale}
                                        defaultValue={moment(monthStart, dateFormat)}
                                        disabledDate={disabledMonth}
                                        // line-height: 1.5715
                                        style={{height: 32}}
                                        onChange={monthChange} picker="month"/>
                                </Form.Item>
                                <Form.Item label="事业部" style={{marginLeft: 30}}>
                                    <Select value={division} style={{width: 120}}
                                            onChange={(value) => setDivision(value)}>
                                        <Option value={''}>{''}</Option>
                                        {
                                            divisionList.map(item => {
                                                return <Option value={item}>{item}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="板块" style={{marginLeft: 30}}>
                                    <Select value={plate} style={{width: 120}} onChange={(value) => setPlate(value)}>
                                        <Option value={''}>{''}</Option>
                                        {
                                            plateList.map(item => {
                                                return <Option value={item}>{item}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="项目" style={{marginLeft: 30}}>
                                    <Popover
                                        placement="right"
                                        content={projectContent}
                                        trigger="click"
                                        visible={closeProjectBubble}
                                        onVisibleChange={visible => setCloseProjectBubble(visible)}
                                    >
                                        <Button style={{height: 32}} size="small">自定义{searchProjectTag == 1 ?
                                            <span>: {searchProject}</span> : <span></span>}</Button>
                                    </Popover>
                                </Form.Item>
                                <Form.Item label="店铺" style={{marginLeft: 30}}>
                                    <Popover
                                        placement="right"
                                        content={shopContent}
                                        trigger="click"
                                        visible={closeShopBubble}
                                        onVisibleChange={visible => setCloseShopBubble(visible)}
                                    >
                                        <Button style={{height: 32}} size="small">自定义{searchShopTag == 1 ?
                                            <span>: {searchShop}</span> : <span></span>}</Button>
                                    </Popover>
                                </Form.Item>
                            </Row>

                        </Form>
                    </div>
                </Panel>
            </Collapse>
            <div style={{marginTop: 10}}>
                <Table
                    bordered
                    dataSource={createProjectNewArr(createShopNewArr(createDivisionNewArr(createPlateNewArr(data))))}
                    columns={columns}
                    page={page}
                    pagination={paginationProps}
                    scroll={{x: 3000, y: `${state.size.width > 1500 ? '60vh' : '56vh'}`}}
                />
            </div>

            <Modal
                title="上传预算目标"
                visible={uploadBudgetTarget}
                centered={true}
                okText="确定"
                cancelText="取消"
                onOk={() => {
                    uploadBudgetTargetFile()
                }}
                onCancel={() => {
                    setBudgetTargetFile([])
                    setUploadBudgetTarget(false)
                }}
                okButtonProps={{disabled: effective == 1 ? true : false}}
                cancelButtonProps={{disabled: effective == 1 ? true : false}}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={budgetTargetFile}
                    onChange={budgetTargetFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
            </Modal>

            <Modal
                title="上传业绩数据汇总文件"
                visible={uploadDataSummary}
                centered={true}
                okText="确定"
                cancelText="取消"
                onOk={() => {
                    uploadDataSummaryFile()
                }}
                onCancel={() => {
                    setDataSummaryFile([])
                    setuploadDataSummary(false)
                }}
                okButtonProps={{disabled: effective == 1 ? true : false}}
                cancelButtonProps={{disabled: effective == 1 ? true : false}}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={dataSummaryFile}
                    onChange={dataSummaryFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
            </Modal>
        </div>
    )


    // 计算两个日期之间的日期
    function getBetweenDateStr(start, end) {
        let result = [];
        let beginDay = start.split("-");
        let endDay = end.split("-");
        let diffDay = new Date();
        let dateList = new Array;
        let i = 0;
        diffDay.setDate(beginDay[2]);
        diffDay.setMonth(beginDay[1] - 1);
        diffDay.setFullYear(beginDay[0]);
        let startDay = start.substring(5, start.size)
        result.push(startDay);
        while (i == 0) {
            var countDay = diffDay.getTime() + 24 * 60 * 60 * 1000;
            diffDay.setTime(countDay);
            dateList[2] = diffDay.getDate();
            dateList[1] = diffDay.getMonth() + 1;
            dateList[0] = diffDay.getFullYear();
            if (String(dateList[1]).length == 1) { dateList[1] = "0" + dateList[1] };
            if (String(dateList[2]).length == 1) { dateList[2] = "0" + dateList[2] };
            result.push(dateList[1] + "-" + dateList[2]);
            if (dateList[0] == endDay[0] && dateList[1] == endDay[1] && dateList[2] == endDay[2]) {
                i = 1;
            }
        };
        // result.reverse()
        return result;
    }

}

export default Index