import React, { useEffect, useState } from 'react'
import moment from 'moment'
import tippy from 'tippy.js'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction"
import locale from '@fullcalendar/core/locales/zh-cn';
import './index.css'
import dataSource from '../platformEventsCalendar/dataSource'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import "@fullcalendar/timegrid/main.css";
const { Text } = Typography

function Index({
    // 开始时间
    startDate,
    // 设置开始时间
    setStartDate,
    // 结束时间
    endDate,
    // 设置结束时间
    setEndDate
}) {

    function delElByIndex(arr, index) {
        for (var i = index, len = arr.length - 1; i < len; i++)
            arr[i] = arr[i + 1];
        arr.length = len;
    };
    // 打开日历
    const [isOpenCalendar, setIsOpenCalendar] = useState(true)
    // 第一次点击
    const [firstClick, setFirstClick] = useState(startDate)
    // 第二次点击
    const [secondClick, setSecondClick] = useState(endDate)
    // 事件
    const [events, setEvents] = useState(dataSource)
    // 日历开始时间
    const [calendarStartDate, setCalendarStartDate] = useState('')
    const [calendarEndDate, setCalendarEndDate] = useState('')
    // 当月活动
    const [monthActivities, setMonthActivities] = useState([])

    useEffect(() => {
        if (endDate != '') {
            let obj = [...events]
            let start = startDate
            let end = endDate
            let endTime = moment(end).add(1, 'day').format('YYYY-MM-DD')
            let newEvent = { title: '', start: start, end: endTime, backgroundColor: "#b3e5fc", id: "userClicks", display: 'background' }
            obj.map((item, index) => {
                if (item.id == 'userClicks') {
                    delElByIndex(obj, index)
                }
            })
            obj.push(newEvent)
            setEvents(obj)
        } else if(endDate == '' && startDate == ''){
            let obj = [...events]
            obj.map((item, index) => {
                if (item.id == 'userClicks') {
                    delElByIndex(obj, index)
                }
            })
            setEvents(obj)
        }
    }, [endDate])

    useEffect(() => {
        let obj = []
        dataSource.map(item => {
            if (item.start >= calendarStartDate && item.start <= calendarEndDate) {
                obj.push(item)
            }
        })
        setMonthActivities(obj)
    }, [calendarEndDate])

    const handleDateClick = (arg) => {
        if (firstClick == '' && secondClick == '') {
            setFirstClick(arg.dateStr)
            let start = arg.dateStr
            let end = arg.dateStr
            let endTime = moment(end).add(1, 'day').format('YYYY-MM-DD')
            let obj = [...events]
            let newEvent = { title: '', start: start, end: endTime, backgroundColor: "#b3e5fc", id: "userClicks", display: 'background' }
            obj.push(newEvent)
            setEvents(obj)
        } else if (firstClick != '' && secondClick == '') {
            setSecondClick(arg.dateStr)
            if (firstClick < arg.dateStr) {
                setStartDate(firstClick)
                setEndDate(arg.dateStr)
                let obj = [...events]
                let start = firstClick
                let end = arg.dateStr
                let endTime = moment(end).add(1, 'day').format('YYYY-MM-DD')
                let newEvent = { title: '', start: start, end: endTime, backgroundColor: "#b3e5fc", id: "userClicks", display: 'background' }
                obj.map((item, index) => {
                    if (item.id == 'userClicks') {
                        delElByIndex(obj, index)
                    }
                })
                obj.push(newEvent)
                setEvents(obj)
            } else {
                setStartDate(arg.dateStr)
                setEndDate(firstClick)
                let obj = [...events]
                let start = arg.dateStr
                let end = firstClick
                let endTime = moment(end).add(1, 'day').format('YYYY-MM-DD')
                let newEvent = { title: '', start: start, end: endTime, backgroundColor: "#b3e5fc", id: "userClicks", display: 'background' }
                obj.map((item, index) => {
                    if (item.id == 'userClicks') {
                        delElByIndex(obj, index)
                    }
                })
                obj.push(newEvent)
                setEvents(obj)
            }

        } else {
            setStartDate('')
            setEndDate('')
            setFirstClick(arg.dateStr)
            setSecondClick('')
            let obj = [...events]
            let start = arg.dateStr
            let end = arg.dateStr
            let endTime = moment(end).add(1, 'day').format('YYYY-MM-DD')
            let newEvent = { title: '', start: start, end: endTime, backgroundColor: "#b3e5fc", id: "userClicks", display: 'background' }
            obj.map((item, index) => {
                if (item.id == 'userClicks') {
                    delElByIndex(obj, index)
                }
            })
            obj.push(newEvent)
            setEvents(obj)
        }
    }

    Date.prototype.Format = function (fmt) { //author: meizz 
        var o = {
            "M+": this.getMonth() + 1, //月份 
            "d+": this.getDate(), //日 
            "h+": this.getHours(), //小时 
            "m+": this.getMinutes(), //分 
            "s+": this.getSeconds(), //秒 
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
            "S": this.getMilliseconds() //毫秒 
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    }

    useEffect(() => {
        setIsOpenCalendar(false)
    }, [])

    return (
        <div style={{position:"relative"}} onMouseLeave={() => {
            setIsOpenCalendar(false)
            if (firstClick != '' && secondClick == '') {
                setFirstClick('')
                setSecondClick('')
                let obj = [...events]
                obj.map((item, index) => {
                    if (item.id == 'userClicks') {
                        delElByIndex(obj, index)
                    }
                })
                setEvents(obj)
            }
        }}>
            <div style={{ width: "650px", height: '550px',display: isOpenCalendar ? '' : 'none', backgroundColor: 'white', zIndex: 1000, position:'absolute', top:'-550px', left:'-270px', border:'1px solid #ccc', borderRadius:"20px" , padding:20}}>
                <FullCalendar
                    // interactionPlugin 移动使用插件
                    // dayGridPlugin 日期网格插件
                    plugins={[dayGridPlugin, interactionPlugin]}
                    // 头部展示
                    headerToolbar={{
                        //  默认显示当前年月
                        left: "title",
                        // 设置中间显示的内容
                        // center: "title",
                        // 右侧上一月，下一月，今天(逗号为紧相邻，空格为有间隙，不写哪个就不展示哪个按钮)
                        right: "prev,next today"
                    }}
                    initialView="dayGridMonth"
                    dateClick={handleDateClick}
                    locale={locale}
                    // droppable={true}
                    events={events}
                    eventMouseEnter={function (info) {
                        if (info.event._def.title != '') {
                            tippy(info.el, {
                                content: info.event._def.title,
                                theme: 'tomato',
                                allowHTML: true
                            })
                        }
                    }}
                    // 查询数据，获取当前日历的开始时间、结束时间
                    datesSet={function (info) {
                        let start_day = new Date(info.startStr)
                        let start = start_day.Format("yyyy-MM-dd")
                        setCalendarStartDate(start);
                        let end_day = new Date(info.endStr)
                        let end = end_day.Format("yyyy-MM-dd")
                        setCalendarEndDate(end);
                    }}
                />
            </div>
            <div style={{ display: 'flex', position:'absolute', top:'0', left:'-270px' }}>
                <div style={{ border: '1px solid #ccc', display: 'flex', borderRadius:"20px" }} onClick={() => {
                    setIsOpenCalendar(true)
                }}>
                    <div style={{ width: 100, display: 'flex', justifyContent: 'center' }}>{startDate}</div>
                    <div style={{ paddingLeft: 5, paddingRight: 5, color: '#ccc' }}>——</div>
                    <div style={{ width: 100, display: 'flex', justifyContent: 'center' }}>{endDate}</div>
                    <div style={{ marginLeft: 10, marginRight: 5, display: startDate != '' ? '' : 'none' }}><CloseCircleOutlined onClick={() => {
                        setSecondClick('')
                        setFirstClick('')
                        setEndDate('')
                        setStartDate('')
                        let obj = [...events]
                        obj.map((item, index) => {
                            if (item.id == 'userClicks') {
                                delElByIndex(obj, index)
                            }
                        })
                        setEvents(obj)
                    }} /></div>
                </div>
            </div>
        </div>

    )

}

export default Index