import React, { useEffect, useState } from "react";
import { Input, message, Modal, Button, Spin, Table } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import cookie from "react-cookies";
import servicePath from "../../../config/apiUrl";
import NbAxios from "../../../config/utils/nbAxios";
import style from "./index.module.css";
import Barcode from "../../pos/components/barcode";
function Search(props) {
  // 表头列表
  const [columnList] = useState([
    "序号",
    "商家编码",
    "数量",
    "金额",
    "结算价",
    "结算金额",
  ]);
  // 打印表头列表
  const [printColumnList] = useState([
    "序号",
    "商家编码",
    "数量",
    "金额",
    "结算价",
    "结算金额",
  ]);
  // 设置打印表头
  const [printColumn, setPrintColumn] = useState([]);
  // 设置表头
  const [column, setColumn] = useState([]);
  // 表格明细内容
  const [tableData, setTableData] = useState([]);
  // 原订单
  const [originalPrice, setOriginalPrice] = useState([]);
  // 订单条形码
  const [orderBarCode, setOrderBarCode] = useState("");
  // 订单编号
  const [orderCode, setOrderCode] = useState("");
  // 是否有效
  const [loading, setLoading] = useState(false);
  // 是否打开打印窗口
  const [isOrderPrint, setIsOrderPrint] = useState(false);
  useEffect(() => {
    setColumn(
      columnList.map((item, index) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          width: "5%",
          render: (text, record, index) => {
            return item === "金额" || item === "结算金额" || item === "结算价"
              ? typeof text === "number"
                ? text.toFixed(2)
                : text
              : text;
          },
        };
      })
    );
  }, [tableData]);
  useEffect(() => {
    setPrintColumn(
      printColumnList.map((item, index) => {
        return {
          title: item == "应付金额" ? "应退金额" : item,
          dataIndex: item,
          key: item,
          align: "center",
          width: "5%",
          render: (text, record, index) => {
            return item !== "序号" && item !== "商家编码" && item !== "数量"
              ? typeof text === "number"
                ? text.toFixed(2)
                : text
              : text;
          },
          onCell: function (column) {
            return {
              style: {
                // width: 80,
                // overflow: 'hidden',
                // whiteSpace: 'nowrap',
                // textOverflow:'ellipsis',
                // cursor:'pointer',
                fontSize: 5,
              },
            };
          },
          onHeaderCell: function (column) {
            return {
              style: {
                // width: 80,
                // overflow: 'hidden',
                // whiteSpace: 'nowrap',
                // textOverflow:'ellipsis',
                // cursor:'pointer',
                fontSize: 5,
              },
            };
          },
        };
      })
    );
  }, []);
  // 查询订单
  const queryOrderInfoNew = (order_code) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      order_code: order_code,
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.queryOrderInfoNew)
      .then((res) => {
        setLoading(false);
        setOrderBarCode("");
        setOrderCode(order_code);
        if (res.success) {
          message.success("查询订单成功");
          res.data.map((item, index) => {
            item["序号"] = index + 1;
          });
          totalAdd(res.data, setTableData);
          // 原订单存储
          totalAdd(res.data, setOriginalPrice);
          // setTableData(res.data)
        } else {
          // setOrderBarCode('')
          message.warn({ content: res.errorMsg });
        }
      })
      .catch((err) => {
        setOrderBarCode("");
        setLoading(false);
        // 获取500错误
        message.warn("查询订单接口报错，请联系IT帮忙解决");
      });
  };
  //该方法参数 content：需要打印的元素
  const printFun = (content) => {
    var Window = window.open(
      "",
      "页面打印",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no"
    );
    var style = "<style type='text/css'></style>";
    Window.document.write(content + style);
    Window.focus();
    Window.document.close(); //关闭document的输出流, 显示选定的数据
    Window.print(); //打印当前窗口
    return Window;
  };
  // 总计计算和增加
  const totalAdd = (obj, setTableData) => {
    // 如果最后一列是总计，则删除
    if (obj[obj.length - 1]) {
      if (obj[obj.length - 1]["序号"] === "合计") {
        obj.pop();
      }
    }
    // 计算总计
    let sumds = 0;
    let num = 0;
    // let sumdj = 0;
    obj.map((item) => {
      // if (item["金额"]) {
      //   sumdj = sumdj + item["金额"];
      // }
      if (item["结算金额"]) {
        sumds = sumds + item["结算金额"];
      }
      if (item["数量"]) {
        num = num + item["数量"];
      }
    });
    obj.push({
      序号: "合计",
      // 金额: sumdj,
      结算金额: sumds,
      数量: num,
    });
    setTableData(obj);
  };

  return (
    <div className={style.initial}>
      <Spin spinning={loading} tip="数据加载中....">
        <div
          style={{
            marginTop: "20px",
            alignItems: "center",
            fontSize: "30px",
            cursor: "pointer",
            marginLeft: "50px",
          }}
          onClick={() =>
            props.history.push({ pathname: `/app/pos2023/initial` })
          }
        >
          <ArrowLeftOutlined />
          返回
        </div>
        <div className={style.externalContent}>
          <div className={style.mak} style={{ fontSize: "40px" }}>
            查询订单
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <span style={{ marginRight: "10px" }}>订单条形码:</span>
              <Input
                value={orderBarCode}
                onChange={(e) => {
                  setOrderBarCode(e.target.value);
                }}
                // 自动获取焦点
                ref={function (input) {
                  if (input != null) {
                    input.focus();
                  }
                }}
                onPressEnter={(e) => {
                  if (e.target.value.length !== 20) {
                    message.warning(
                      "订单编码输入有误,请在再次尝试并且切换英文输入法"
                    );
                    setOrderBarCode("");
                  } else {
                    queryOrderInfoNew(e.target.value);
                  }
                }}
                style={{ width: "200px" }}
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Table
              columns={column}
              dataSource={tableData}
              size="small"
              pagination={false}
            />
          </div>
          <div className={style.endButton}>
            <Button
              type="primary"
              onClick={() => {
                if (originalPrice.length !== 0) {
                  setIsOrderPrint(true);
                } else {
                  message.warning("还未查询订单");
                }
              }}
            >
              打印订单
            </Button>
          </div>
        </div>
      </Spin>

      <Modal
        title="打印窗口"
        visible={isOrderPrint}
        onCancel={() => {
          setIsOrderPrint(false);
        }}
        centered={true}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                var windows = printFun(
                  document.getElementById("print").innerHTML
                );
                windows.close();
              }}
            >
              打印
            </Button>
          </div>
        }
        width={1000}
      >
        {/* style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "120px" */}
        <div id="print">
          <h1 style={{ textAlign: "center" }}>销售订单明细</h1>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "500px" }}>
              <div style={{ display: "grid", gridTemplateColumns: "70px 1fr" }}>
                <div>交易时间:</div>
                <div>{moment().format("YYYY-MM-DD HH:mm:ss")}</div>
                <div>订单编号:</div>
                <div>{orderCode}</div>
                <div>单位:</div>
                <div>元</div>
              </div>
            </div>
            <div>
              {/* <BarCodeSVG /> */}
              <Barcode value={orderCode} height={50} width={2} />
            </div>
          </div>

          {/* <div style={{ fontSize: '30px' }}>总金额:{total}</div> */}
          <Table
            columns={printColumn}
            dataSource={tableData}
            size="small"
            pagination={false}
          />
          <div style={{ display: "flex" }}>
            <div style={{ width: "180px" }}>
              <div style={{ display: "grid", gridTemplateColumns: "70px 1fr" }}>
                <div>结算金额:</div>
                <div style={{ textAlign: "right" }}>
                  {tableData.length !== 0
                    ? tableData[tableData.length - 1]["结算金额"].toFixed(2) +
                      "元"
                    : ""}
                </div>
                {/* <div>应付金额:</div>
                <div style={{ textAlign: "right" }}>
                  {tableData.length !== 0
                    ? tableData[tableData.length - 1]["应付金额"].toFixed(2) +
                      "元"
                    : ""}
                </div> */}
              </div>
            </div>
            <div style={{ display: "inline-flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "70px 1fr",
                  height: "88px",
                  marginLeft: "120px",
                }}
              >
                <div>数量合计:</div>
                <div>
                  {tableData.length !== 0
                    ? tableData[tableData.length - 1]["数量"] + "件"
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            如需开票请持有此单在收银柜台登记，我司将以邮件形式将电子发票信息发送至您的邮箱！
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Search;
