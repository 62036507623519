import React, { Component, useEffect, useState } from 'react';
import { Tabs, Typography, Collapse, Radio, Checkbox, Button, Spin, Statistic, Modal, Upload, message, Form, DatePicker, Table, Space, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl';
import locale from 'antd/es/date-picker/locale/zh_CN';
import exportPdf from '../../config/utils/html2pdf'
import cookie from 'react-cookies'
import downExcel from '../../config/utils/downloadFile';
import { DraggableArea } from 'react-draggable-tags';
import style from './index.module.css'
import axios from 'axios'
import moment from 'moment'
import AcceptBuried from '../../config/utils/buried'
import PlatformEventsCalendar from '../../component/platformEventsCalendar'
const { Dragger } = Upload;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { CheckableTag } = Tag
const { Title, Text, Paragraph } = Typography

function Index(props) {
    String.prototype.replaceAll = function(reallyDo, replaceWith, ignoreCase) {  
        if (!RegExp.prototype.isPrototypeOf(reallyDo)) {  
            return this.replace(new RegExp(reallyDo, (ignoreCase ? "gi": "g")), replaceWith);  
        } else {  
            return this.replace(reallyDo, replaceWith);  
        }  
    } 

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'qda':"2715f3fa-0dae-4dc7-bcfc-0dd912314e40"
    }
    // 日期格式
    const dateFormat = 'YYYY.MM.DD'
    // tab关键词
    const [tabKey, setTabKey] = useState('经营分析维度')
    // 折叠框key
    const [collapseKey, setCollapseKey] = useState('1')
    // 单选款key
    const [radioKey, setRadioKey] = useState('')
    // 维度筛选
    const [filterDimension, setFilterDimension] = useState({
        'product_line': [],
        'year': [],
        'season': [],
        'category': [],
        'price': [],
        'season_status':[],
        'band_rank_of_pay_money':[],
        'grade_of_pay_money':[],
        'sales_season':[]
    })
    // top款
    const [ bandRankOfPayMoneySelectedTags, setBandRankOfPayMoneySelectedTags] = useState([])
    const [ bandRankOfPayMoneySelectedKey, setBandRankOfPayMoneySelectedKey] = useState(false)
    // 当季过季
    const [ seasonStatusSelectedTags, setSeasonStatusSelectedTags] = useState([])
    // 产品线
    const [productLineSelectedTags, setProductLineSelectedTags] = useState([])
    const [productLineSelectedKey, setProductLineSelectedKey] = useState(false)
    //年份
    const [yearSelectedTags, setYearSelectedTags] = useState([])
    const [yearSelectedKey, setYearSelectedKey] = useState(false)
    // 季节
    const [seasonSelectedTags, setSeasonSelectedTags] = useState([])
    const [seasonSelectedKey, setSeasonSelectedKey] = useState(false)
    // 品类
    const [categorySelectedTags, setCategorySelectedTags] = useState([])
    const [categorySelectedKey, setCategorySelectedKey] = useState(false)
    // 价格带
    const [priceSelectedTags, setPriceSelectedTags] = useState([])
    const [priceSelectedKey, setPriceSelectedKey] = useState(false)
    // 销售等级
    const [gradeOfPayMoneyTags,setGradeOfPayMoneyTags] = useState([])
    const [gradeOfPayMoneySelectedKey, setGradeOfPayMoneySelectedKey] = useState(false)
    // 销售季节
    const [salesSeasonSelectedTags,setSalesSeasonSelectedTags] = useState([])
    const [salesSeasonSelectedKey, setSalesSeasonSelectedKey] = useState(false)
    // 对比开始时间1
    const [oneStartTime, setOneStartTime] = useState('')
    // 对比结束时间1
    const [oneEndTime, setOneEndTime] = useState('')
    // 对比开始时间2
    const [twoStartTime, setTwoStartTime] = useState('')
    // 对比结束时间2
    const [twoEndTime, setTwoEndTime] = useState('')
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 对比数据
    const [comparedData, setComparedData] = useState(
        {
            // 数据
            'data': [],
            // 基础表头
            'baseColumnList': [],
            // 时间段1表头
            'oneColumnList': [],
            // 时间段2表头
            'twoColumnList': [],
            // 对比表头
            'differentColumnList': [],
            // 图利
            'legend': []
        }
    )
    // 基础表头
    const [baseColumnList, setBaseColumnList] = useState([])
    // 时间段1表头
    const [oneColumnList, setOneColumnList] = useState([])
    // 时间段2表头
    const [twoColumnList, setTwoColumnList] = useState([])
    // 对比表头
    const [differentColumnList, setDifferentColumnList] = useState([])
    // 图例
    const [legend, setLegend] = useState([])
    // 表头
    const [column, setColumn] = useState()
    // 销售表头字段
    const [ saleColumnField ] = useState(['动销款','销量','销售额','销售占比','件单价','折扣','单款贡献'])
    const [ saleComparedColumnField] = useState(['动销款对比','销售额对比','销量对比','件单价对比','单款贡献对比','折扣对比'])
    // 预热
    const [ warmColumnField ] = useState(['访客数','加购件数','收藏人数','加购款数','加购占比'])
    const [ warmComparedColumnField] = useState(['访客数对比','加购件数对比','收藏人数对比'])
    // 数据
    const [data, setData] = useState([])
    const [ allData , setAllData ] = useState([])
    // 维度排序
    const [ dimensionalOrdering , setDimensionalOrdering] = useState([])

    // useEffect(()=>{ 
    //     setColumn([
    //         {
    //             title: '数据项',
    //             dataIndex: '数据项',
    //             key: '数据项',
    //             align: 'center',
    //             width:200,
    //             onHeaderCell: (record, index) => {
    //                 return (
    //                     {
    //                         style: {
    //                             fontSize: '10px',
    //                             padding: 0
    //                         }
    //                     }
    //                 )
    //             },
    //             onCell: (record, index) => {
    //                 return (
    //                     {
    //                         style: {
    //                             fontSize: '10px',
    //                             padding: 0
    //                         }
    //                     }
    //                 )
    //             },
    //         },
    //         ...columnField.map(item=>{
    //             return {
    //                 title:item,
    //                 dataIndex:item+'_x',
    //                 key:item+'_x',
    //                 align: 'center',
    //                 onHeaderCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 backgroundColor:'#fa897b',
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 onCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 render: (text, record, index) => (
    //                     text.toString().indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
    //                     <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
    //                 )
    //             }
    //         }),
    //         ...comparedColumnField.map(item=>{
    //             return {
    //                 title:item,
    //                 dataIndex:item,
    //                 key:item,
    //                 align: 'center',
    //                 onHeaderCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 backgroundColor:'#ffdd94',
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 onCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 render: (text, record, index) => (
    //                     text.toString().indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
    //                     <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
    //                 )
    //             }
    //         }),
    //         ...columnField.map(item=>{
    //             return {
    //                 title:item,
    //                 dataIndex:item+'_y',
    //                 key:item+'_y',
    //                 align: 'center',
    //                 onHeaderCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 backgroundColor:'#75cce8',
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 onCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 render: (text, record, index) => (
    //                     text.toString().indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
    //                     <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
    //                 )
    //             }
    //         })
    //     ])
    // },[oneEndTime, twoEndTime])

    useEffect(() => {
        AcceptBuried('page_views',"view",{"page_type":"初始化页面"},props.location.pathname,"商品经营报表")
        getCommodityOperationTableFieldData()
    }, [])

    useEffect(()=>{
        if(bandRankOfPayMoneySelectedTags.length == filterDimension['band_rank_of_pay_money'].length && filterDimension['band_rank_of_pay_money'].length != 0){
            setBandRankOfPayMoneySelectedKey(true)
        }else{
            setBandRankOfPayMoneySelectedKey(false)
        }
        if(bandRankOfPayMoneySelectedTags.length != 0){
            let addObj = {
                id:'band_rank_of_pay_money',
                key:'销售额排名',
                name:'TOP款'
            }
            if(JSON.stringify(dimensionalOrdering).indexOf(JSON.stringify(addObj)) == -1){
                let dimensionalOrderingObj = [...dimensionalOrdering]
                dimensionalOrderingObj.push(addObj)
                setDimensionalOrdering(dimensionalOrderingObj)
            }
        }else{
            let obj = [...dimensionalOrdering]
            obj.map((item,index)=>{
                if(item.name == 'TOP款'){
                    obj.splice(index,1)
                }
            })
            setDimensionalOrdering(obj)
        }
    },[bandRankOfPayMoneySelectedTags])

    useEffect(()=>{
        if(productLineSelectedTags.length == filterDimension['product_line'].length && filterDimension['product_line'].length != 0){
            setProductLineSelectedKey(true)
        }else{
            setProductLineSelectedKey(false)
        }
        if(productLineSelectedTags.length != 0){
            let addObj = {
                id:'product_line',
                key:'产品线',
                name:'产品线'
            }
            if(JSON.stringify(dimensionalOrdering).indexOf(JSON.stringify(addObj)) == -1){
                let dimensionalOrderingObj = [...dimensionalOrdering]
                dimensionalOrderingObj.push(addObj)
                setDimensionalOrdering(dimensionalOrderingObj)
            }
        }else{
            let obj = [...dimensionalOrdering]
            obj.map((item,index)=>{
                if(item.name == '产品线'){
                    obj.splice(index,1)
                }
            })
            setDimensionalOrdering(obj)
        }
    },[productLineSelectedTags])

    useEffect(()=>{
        if(yearSelectedTags.length == filterDimension['year'].length && filterDimension['year'].length != 0){
            setYearSelectedKey(true)
        }else{
            setYearSelectedKey(false)
        }
        if(yearSelectedTags.length != 0){
            let addObj = {
                id:'year',
                key:'年份',
                name:'年份'
            }
            if(JSON.stringify(dimensionalOrdering).indexOf(JSON.stringify(addObj)) == -1){
                let dimensionalOrderingObj = [...dimensionalOrdering]
                dimensionalOrderingObj.push(addObj)
                setDimensionalOrdering(dimensionalOrderingObj)
            }
        }else{
            let obj = [...dimensionalOrdering]
            obj.map((item,index)=>{
                if(item.name == '年份'){
                    obj.splice(index,1)
                }
            })
            setDimensionalOrdering(obj)
        }
    },[yearSelectedTags])

    useEffect(()=>{
        if(seasonSelectedTags.length == filterDimension['season'].length && filterDimension['season'].length != 0){
            setSeasonSelectedKey(true)
        }else{
            setSeasonSelectedKey(false)
        }
        if(seasonSelectedTags.length != 0){
            let addObj = {
                id:'season',
                key:'季节',
                name:'季节'
            }
            if(JSON.stringify(dimensionalOrdering).indexOf(JSON.stringify(addObj)) == -1){
                let dimensionalOrderingObj = [...dimensionalOrdering]
                dimensionalOrderingObj.push(addObj)
                setDimensionalOrdering(dimensionalOrderingObj)
            }
        }else{
            let obj = [...dimensionalOrdering]
            obj.map((item,index)=>{
                if(item.name == '季节'){
                    obj.splice(index,1)
                }
            })
            setDimensionalOrdering(obj)
        }
    },[seasonSelectedTags])

    useEffect(()=>{
        if(categorySelectedTags.length == filterDimension['category'].length && filterDimension['category'].length != 0){
            setCategorySelectedKey(true)
        }else{
            setCategorySelectedKey(false)
        }
        if(categorySelectedTags.length != 0){
            let addObj = {
                id:'category2',
                key:'品类',
                name:'品类'
            }
            if(JSON.stringify(dimensionalOrdering).indexOf(JSON.stringify(addObj)) == -1){
                let dimensionalOrderingObj = [...dimensionalOrdering]
                dimensionalOrderingObj.push(addObj)
                setDimensionalOrdering(dimensionalOrderingObj)
            }
        }else{
            let obj = [...dimensionalOrdering]
            obj.map((item,index)=>{
                if(item.name == '品类'){
                    obj.splice(index,1)
                }
            })
            setDimensionalOrdering(obj)
        }
    },[categorySelectedTags])

    useEffect(()=>{
        if(priceSelectedTags.length == filterDimension['price'].length && filterDimension['price'].length != 0){
            setPriceSelectedKey(true)
        }else{
            setPriceSelectedKey(false)
        }
        if(priceSelectedTags.length != 0){
            let addObj = {
                id:'price_band',
                key:'价格带',
                name:'价格带'
            }
            if(JSON.stringify(dimensionalOrdering).indexOf(JSON.stringify(addObj)) == -1){
                let dimensionalOrderingObj = [...dimensionalOrdering]
                dimensionalOrderingObj.push(addObj)
                setDimensionalOrdering(dimensionalOrderingObj)
            }
        }else{
            let obj = [...dimensionalOrdering]
            obj.map((item,index)=>{
                if(item.name == '价格带'){
                    obj.splice(index,1)
                }
            })
            setDimensionalOrdering(obj)
        }
    },[priceSelectedTags])
    
    useEffect(()=>{
        if(gradeOfPayMoneyTags.length == filterDimension['grade_of_pay_money'].length && filterDimension['grade_of_pay_money'].length != 0){
            setGradeOfPayMoneySelectedKey(true)
        }else{
            setGradeOfPayMoneySelectedKey(false)
        }
        if(gradeOfPayMoneyTags.length != 0){
            let addObj = {
                id:'grade_of_pay_money',
                key:'销售等级',
                name:'销售等级'
            }
            if(JSON.stringify(dimensionalOrdering).indexOf(JSON.stringify(addObj)) == -1){
                let dimensionalOrderingObj = [...dimensionalOrdering]
                dimensionalOrderingObj.push(addObj)
                setDimensionalOrdering(dimensionalOrderingObj)
            }
        }else{
            let obj = [...dimensionalOrdering]
            obj.map((item,index)=>{
                if(item.name == '销售等级'){
                    obj.splice(index,1)
                }
            })
            setDimensionalOrdering(obj)
        }
    },[gradeOfPayMoneyTags])

    useEffect(()=>{
        if(salesSeasonSelectedTags.length == filterDimension['sales_season'].length && filterDimension['sales_season'].length != 0){
            setSalesSeasonSelectedKey(true)
        }else{
            setSalesSeasonSelectedKey(false)
        }
        if(salesSeasonSelectedTags.length != 0){
            let addObj = {
                id:'sales_season',
                key:'销售季节',
                name:'销售季节'
            }
            if(JSON.stringify(dimensionalOrdering).indexOf(JSON.stringify(addObj)) == -1){
                let dimensionalOrderingObj = [...dimensionalOrdering]
                dimensionalOrderingObj.push(addObj)
                setDimensionalOrdering(dimensionalOrderingObj)
            }
        }else{
            let obj = [...dimensionalOrdering]
            obj.map((item,index)=>{
                if(item.name == '销售季节'){
                    obj.splice(index,1)
                }
            })
            setDimensionalOrdering(obj)
        }
    },[salesSeasonSelectedTags])
    // useEffect(() => {
    //     setData(comparedData['data'])
    //     setBaseColumnList(comparedData['baseColumnList'])
    //     setOneColumnList(comparedData['oneColumnList'])
    //     setTwoColumnList(comparedData['twoColumnList'])
    //     setDifferentColumnList(comparedData['differentColumnList'])
    //     setLegend(comparedData['legend'])
    // }, [comparedData])

    useEffect(()=>{
        setBandRankOfPayMoneySelectedTags([])
        setBandRankOfPayMoneySelectedKey(false)
        // 不需要
        setSeasonStatusSelectedTags([])
        setYearSelectedTags([])
        setYearSelectedKey(false)
        setSeasonSelectedTags([])
        setSeasonSelectedKey(false)
        setDimensionalOrdering([])
        setCategorySelectedTags([])
        setCategorySelectedKey(false)
        setProductLineSelectedTags([])
        setProductLineSelectedKey(false)
        setPriceSelectedTags([])
        setPriceSelectedKey(false)
        setGradeOfPayMoneyTags([])
        setGradeOfPayMoneySelectedKey(false)
    },[radioKey])

    // useEffect(() => {
    //     setColumn([
    //         ...baseColumnList.map((item, baseColumIndex) => {
    //             return {
    //                 title: item.name,
    //                 dataIndex: item.key,
    //                 key: item.key,
    //                 align: 'center',
    //                 fixed: true,
    //                 sorter:true,
    //                 width: item.name == '价格带' || '产品线' ? 90 : item.name == '二级品类' ? 120 : 50,
    //                 onHeaderCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 backgroundColor: item.group == 1 ? '#fa897b ' : item.group == 2 ? '#ffdd94' : item.group == 0 ? '#ffffff' : '#75cce8',
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 onCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 render(text, row, index) {
    //                     if (index != data.length - 1) {
    //                         return <span>{text}</span>
    //                     } else {
    //                         return {
    //                             children: <span>{text}</span>,
    //                             props: { colSpan: baseColumIndex != 0 ? 0 : baseColumnList.length }
    //                         }
    //                     }
    //                 },
    //             }
    //         }),
    //         ...oneColumnList.map(item => {
    //             return {
    //                 title: item.name,
    //                 dataIndex: item.key,
    //                 key: item.key,
    //                 align: 'center',
    //                 width: 100,
    //                 sorter:true,
    //                 onHeaderCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 backgroundColor: item.group == 1 ? '#fa897b ' : item.group == 2 ? '#ffdd94' : item.group == 0 ? '#ffffff' : '#75cce8',
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 onCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
                    // render: (text, record, index) => (
                    //     text.indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
                    //     <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
                    // )
    //             }
    //         }),
    //         ...twoColumnList.map(item => {
    //             return {
    //                 title: item.name,
    //                 dataIndex: item.key,
    //                 key: item.key,
    //                 align: 'center',
    //                 width: 100,
    //                 sorter:true,
    //                 onHeaderCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 backgroundColor: item.group == 1 ? '#fa897b ' : item.group == 2 ? '#ffdd94' : item.group == 0 ? '#ffffff' : '#75cce8',
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 onCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 render: (text, record, index) => (
    //                     text.indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
    //                     <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
    //                 )
    //             }
    //         }),
    //         ...differentColumnList.map(item => {
    //             return {
    //                 title: item.name,
    //                 dataIndex: item.key,
    //                 key: item.key,
    //                 align: 'center',
    //                 width: 100,
    //                 onHeaderCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 whiteSpace: 'pre',
    //                                 backgroundColor: item.group == 1 ? '#fa897b ' : item.group == 2 ? '#ffdd94' : item.group == 0 ? '#ffffff' : '#75cce8',
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 onCell: (record, index) => {
    //                     return (
    //                         {
    //                             style: {
    //                                 fontSize: '10px',
    //                                 padding: 0
    //                             }
    //                         }
    //                     )
    //                 },
    //                 render: (text, record, index) => (
    //                     <Statistic valueStyle={{ fontSize: 13, textAlign:'right' }} value={text} />
    //                 )
    //             }
    //         })
    //     ])
    // }, [data])

    // 获取维度数据
    const getCommodityOperationTableFieldData = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dataProps, "POST", servicePath.getCommodityOperationTableFieldData).then(res => {
            if (res.success) {
                setFilterDimension(res.data['field_data']);
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => { 
            // 获取500错误
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }
    // TOP款
    const bandRankOfPayMoneyHandleChange = (tag, checked) => {
        let selectedTag = [...bandRankOfPayMoneySelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setBandRankOfPayMoneySelectedTags(nextSelectedTags)
    }
    // 当季过季
    const seasonStatusHandleChange = (tag, checked) => {
        let selectedTag = [...seasonStatusSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setSeasonStatusSelectedTags(nextSelectedTags)
    }
    // 产品线
    const productLineHandleChange = (tag, checked) => {
        let selectedTag = [...productLineSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setProductLineSelectedTags(nextSelectedTags)
    }
    // 年份
    const yearHandleChange = (tag, checked) => {
        let selectedTag = [...yearSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setYearSelectedTags(nextSelectedTags)
    }
    // 季节
    const seasonHandleChange = (tag, checked) => {
        let selectedTag = [...seasonSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setSeasonSelectedTags(nextSelectedTags)
    }
    // 品类
    const categoryHandleChange = (tag, checked) => {
        let selectedTag = [...categorySelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setCategorySelectedTags(nextSelectedTags)
    }
    // 品类
    const priceHandleChange = (tag, checked) => {
        let selectedTag = [...priceSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setPriceSelectedTags(nextSelectedTags)
    }
    // 销售等级
    const gradeHandleChange = (tag, checked) => {
        let selectedTag = [...gradeOfPayMoneyTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setGradeOfPayMoneyTags(nextSelectedTags)
    }
    // 销售季节
    const salesSeasonHandleChange = (tag, checked) => {
        let selectedTag = [...salesSeasonSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setSalesSeasonSelectedTags(nextSelectedTags)
    }
    // 查询数据
    const getData = ()=>{
        if (radioKey == '') {
            message.warn('请选择模版')
        } else if (oneStartTime == '') {
            message.warn('请选择正确时间维度1')
        } else if (twoStartTime == '') {
            message.warn('请选择正确时间维度2')
        } else if (productLineSelectedTags.length == 0 && yearSelectedTags.length == 0 && seasonSelectedTags.length == 0 && categorySelectedTags.length == 0 && priceSelectedTags.length == 0 && seasonStatusSelectedTags.length == 0 && bandRankOfPayMoneySelectedTags.length == 0 && gradeOfPayMoneyTags.length == 0) {
            message.warn('至少选择一个维度')
        } else {
            setData([])
            setColumn([])
            let dimensionalOrderingList = []
            dimensionalOrdering.map(item=>{
                dimensionalOrderingList.push(item.id)
            })
            let dataProps = {}
            dataProps.dimensionalOrderingList = dimensionalOrderingList
            dataProps.roleId = cookie.load('role')
            dataProps.shopList = cookie.load('shopList')
            dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
            dataProps['oneStartTime'] = oneStartTime
            dataProps['oneEndTime'] = oneEndTime
            dataProps['twoStartTime'] = twoStartTime
            dataProps['twoEndTime'] = twoEndTime
            dataProps['productLine'] = productLineSelectedTags
            dataProps['year'] = yearSelectedTags
            dataProps['season'] = seasonSelectedTags
            dataProps['category'] = categorySelectedTags
            dataProps['price'] = priceSelectedTags
            dataProps['season_status'] = seasonStatusSelectedTags
            dataProps['band_rank_of_pay_money'] = bandRankOfPayMoneySelectedTags
            dataProps['grade_of_pay_money'] = gradeOfPayMoneyTags
            dataProps['sales_season'] = salesSeasonSelectedTags
            AcceptBuried('query_commodity_dimension',"view",dataProps,props.location.pathname,"商品经营报表")
            setEffective(1)
            if (radioKey == '销售模版') {
                NbAxios(dataProps, "POST", servicePath.getCommodityOperationTableSalesData).then(res => {
                    setEffective(0)
                    if (res.success) {
                        // let baseColumnList = []
                        // dimensionalOrdering.map(item=>{
                        //     let baseColumnObj = {
                        //         'name':item.key,
                        //         'key':item.key,
                        //         'group':0,
                        //     }
                        //     baseColumnList.push(baseColumnObj)
                        // })
                        // res.data.baseColumnList = baseColumnList
                        setLegend([oneStartTime.replaceAll('-','.') + '——' + oneEndTime.replaceAll('-','.') ,'对比',twoStartTime.replaceAll('-','.')  + '——' + twoEndTime.replaceAll('-','.') ])
                        setData(res.data)
                        // setAllData(res.data.data)
                        // setComparedData(res.data);
                        setColumn([
                            {
                                title: '数据项',
                                dataIndex: '数据项',
                                key: '数据项',
                                align: 'center',
                                width:200,
                                onHeaderCell: (record, index) => {
                                    return (
                                        {
                                            style: {
                                                fontSize: '10px',
                                                padding: 0
                                            }
                                        }
                                    )
                                },
                                onCell: (record, index) => {
                                    return (
                                        {
                                            style: {
                                                fontSize: '10px',
                                                padding: 0
                                            }
                                        }
                                    )
                                },
                            },
                            ...saleColumnField.map(item=>{
                                return {
                                    title:item,
                                    dataIndex:item+'_x',
                                    key:item+'_x',
                                    align: 'center',
                                    onHeaderCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    backgroundColor:'#fa897b',
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    onCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    render: (text, record, index) => (
                                        text.toString().indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
                                        <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
                                    )
                                }
                            }),
                            ...saleComparedColumnField.map(item=>{
                                return {
                                    title:item,
                                    dataIndex:item,
                                    key:item,
                                    align: 'center',
                                    onHeaderCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    backgroundColor:'#ffdd94',
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    onCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    render: (text, record, index) => (
                                        text.toString().indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
                                        <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
                                    )
                                }
                            }),
                            ...saleColumnField.map(item=>{
                                return {
                                    title:item,
                                    dataIndex:item+'_y',
                                    key:item+'_y',
                                    align: 'center',
                                    onHeaderCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    backgroundColor:'#75cce8',
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    onCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    render: (text, record, index) => (
                                        text.toString().indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
                                        <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
                                    )
                                }
                            })
                        ])
                        setCollapseKey('0')
                    } else {
                        message.warn({ content: res.errorMsg })
                    }
                }).catch((err) => { 
                    // 获取500错误
                    setEffective(0)
                    message.warn('查询接口报错，请联系IT帮忙解决')
                })
            } else if(radioKey == '预热模版'){
                NbAxios(dataProps, "POST", servicePath.getCommodityOperationTablePreheatData).then(res => {
                    setEffective(0)
                    if (res.success) {
                        setLegend([oneStartTime.replaceAll('-','.') + '——' + oneEndTime.replaceAll('-','.') ,'对比',twoStartTime.replaceAll('-','.')  + '——' + twoEndTime.replaceAll('-','.') ])
                        setData(res.data)
                        // let baseColumnList = []
                        // dimensionalOrdering.map(item=>{
                        //     let baseColumnObj = {
                        //         'name':item.key,
                        //         'key':item.key,
                        //         'group':0,
                        //     }
                        //     baseColumnList.push(baseColumnObj)
                        // })
                        // res.data.baseColumnList = baseColumnList
                        // setAllData(res.data.data)
                        // setComparedData(res.data);
                        setColumn([
                            {
                                title: '数据项',
                                dataIndex: '数据项',
                                key: '数据项',
                                align: 'center',
                                width:200,
                                onHeaderCell: (record, index) => {
                                    return (
                                        {
                                            style: {
                                                fontSize: '10px',
                                                padding: 0
                                            }
                                        }
                                    )
                                },
                                onCell: (record, index) => {
                                    return (
                                        {
                                            style: {
                                                fontSize: '10px',
                                                padding: 0
                                            }
                                        }
                                    )
                                },
                            },
                            ...warmColumnField.map(item=>{
                                return {
                                    title:item,
                                    dataIndex:item+'_x',
                                    key:item+'_x',
                                    align: 'center',
                                    onHeaderCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    backgroundColor:'#fa897b',
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    onCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    render: (text, record, index) => (
                                        text.toString().indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
                                        <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
                                    )
                                }
                            }),
                            ...warmComparedColumnField.map(item=>{
                                return {
                                    title:item,
                                    dataIndex:item,
                                    key:item,
                                    align: 'center',
                                    onHeaderCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    backgroundColor:'#ffdd94',
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    onCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    render: (text, record, index) => (
                                        text.toString().indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
                                        <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
                                    )
                                }
                            }),
                            ...warmColumnField.map(item=>{
                                return {
                                    title:item,
                                    dataIndex:item+'_y',
                                    key:item+'_y',
                                    align: 'center',
                                    onHeaderCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    backgroundColor:'#75cce8',
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    onCell: (record, index) => {
                                        return (
                                            {
                                                style: {
                                                    fontSize: '10px',
                                                    padding: 0
                                                }
                                            }
                                        )
                                    },
                                    render: (text, record, index) => (
                                        text.toString().indexOf('%') != -1 ? <div style={{textAlign:'right'}}>{text}</div>:
                                        <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={parseInt(text)} />
                                    )
                                }
                            })
                        ])
                        setCollapseKey('0')
                    } else {
                        message.warn({ content: res.errorMsg })
                    }
                }).catch((err) => { 
                    // 获取500错误
                    setEffective(0)
                    message.warn('查询借口报错，请联系IT帮忙解决')
                })
            }
        }
    }
    return (
        <div>
            <Spin tip={`数据请求中....`} spinning={effective ? true : false} size="large" style={{marginTop:'30vh'}}>
                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 10, borderBottom: "1px solid #ccc" }}>
                    <Title level={4}>商品经营报表</Title>
                    <div>
                        {/* <a href="https://pic.bmcsoft.cn/imge_dir/010414474101/010414474101.html" target="_Blank"><Button type="link">操作手册</Button></a> */}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start", width: '90vw', margin: "0 auto", }}>
                    <Tabs type="card" activeKey={tabKey} onChange={(key) => {
                        setTabKey(key)
                    }} >
                        <TabPane tab={"经营分析维度"} key='经营分析维度'></TabPane>
                    </Tabs>
                </div>
                <div style={{ display: tabKey == "经营分析维度" ? '' : 'none', width: '90vw', margin: "0 auto", }}>
                    <Collapse activeKey={collapseKey} onChange={(key) => {
                        setCollapseKey(key);
                    }}>
                        <Panel header="查询维度" key="1" >
                            <div style={{ display: 'flex' }}>
                                <b style={{ marginRight: 10, marginTop: 5, fontSize: 15 }}>时间维度1:</b>
                                {/* <RangePicker
                                    value={oneStartTime == '' ? ['', ''] : [moment(oneStartTime, dateFormat), moment(oneEndTime, dateFormat)]}
                                    style={{ marginRight: 40 }} locale={locale}
                                    format="YYYY.MM.DD"
                                    disabledDate={disabledDate}
                                    onChange={(value, dateString) => {
                                        if (dateString[0] == '') {
                                            message.warn('请选择正确的时间')
                                        } else {
                                            setOneStartTime(dateString[0])
                                            setOneEndTime(dateString[1])
                                        }
                                    }} /> */}
                                    <div style={{marginTop:5}}>
                                        <PlatformEventsCalendar startDate={oneStartTime} setStartDate={setOneStartTime} endDate={oneEndTime} setEndDate={setOneEndTime}/>
                                    </div>
                                <b style={{ marginRight: 10, marginTop: 5, fontSize: 15, marginLeft:20 }}>时间维度2:</b>
                                {/* <RangePicker
                                    value={twoStartTime == '' ? ['', ''] : [moment(twoStartTime, dateFormat), moment(twoEndTime, dateFormat)]}
                                    locale={locale}
                                    format="YYYY.MM.DD"
                                    disabledDate={disabledDate}
                                    onChange={(value, dateString) => {
                                        if (dateString[0] == '') {
                                            message.warn('请选择正确的时间')
                                        } else {
                                            setTwoStartTime(dateString[0])
                                            setTwoEndTime(dateString[1])
                                        }
                                    }}
                                /> */}
                                <div style={{marginTop:5}}>
                                    <PlatformEventsCalendar startDate={twoStartTime} setStartDate={setTwoStartTime} endDate={twoEndTime} setEndDate={setTwoEndTime}/>
                                </div>
                                <Text strong type="danger" style={{marginLeft:20}}>(应对时间内的当年当季对比)</Text>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <b style={{ marginRight: 18, marginTop: 5, fontSize: 15 }}>模版选择:</b>
                                <Radio.Group style={{ marginTop: 7 }} onChange={(e) => {
                                    setRadioKey(e.target.value)
                                }} value={radioKey}>
                                    <Radio value={'销售模版'}>销售模版</Radio>
                                    <Radio value={'预热模版'}>预热模版</Radio>
                                    {/* <Radio value={'对比模版'}>对比模版</Radio>
                                    <Radio value={'分析模版'}>分析模版</Radio> */}
                                </Radio.Group>
                            </div>
                            {/* <div style={{ display: "flex", marginTop: 10  }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10 }}>季节状态:</b>
                                <Checkbox onChange={(e) => {
                                    if (e.target.checked) {
                                        setSeasonStatusSelectedTags(filterDimension['season_status'])
                                    } else {
                                        setSeasonStatusSelectedTags([])
                                    }
                                }}>全部</Checkbox>
                                {
                                    filterDimension['season_status'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                            key={item}
                                            checked={seasonStatusSelectedTags.indexOf(item) > -1}
                                            onChange={checked => seasonStatusHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div> */}
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10 }}>产品线:</b>
                                <Checkbox checked={productLineSelectedKey} onChange={(e) => {
                                    if (e.target.checked) {
                                        setProductLineSelectedKey(true)
                                        setProductLineSelectedTags(filterDimension['product_line'])
                                    } else {
                                        setProductLineSelectedKey(false)
                                        setProductLineSelectedTags([])
                                    }
                                }}>全部</Checkbox>
                                {
                                    filterDimension['product_line'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                            key={item}
                                            checked={productLineSelectedTags.indexOf(item) > -1}
                                            onChange={checked => productLineHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10 }}>年份:</b>
                                <Checkbox checked={yearSelectedKey} onChange={(e) => {
                                    if (e.target.checked) {
                                        setYearSelectedKey(true)
                                        setYearSelectedTags(filterDimension['year'])
                                    } else {
                                        setYearSelectedKey(false)
                                        setYearSelectedTags([])
                                    }
                                }}>全部</Checkbox>
                                {
                                    filterDimension['year'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                            key={item}
                                            checked={yearSelectedTags.indexOf(item) > -1}
                                            onChange={checked => yearHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10 }}>季节:</b>
                                <Checkbox checked={seasonSelectedKey} onChange={(e) => {
                                    if (e.target.checked) {
                                        setSeasonSelectedKey(true)
                                        setSeasonSelectedTags(filterDimension['season'])
                                    } else {
                                        setSeasonSelectedKey(false)
                                        setSeasonSelectedTags([])
                                    }
                                }}>全部</Checkbox>
                                {
                                    filterDimension['season'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                            key={item}
                                            checked={seasonSelectedTags.indexOf(item) > -1}
                                            onChange={checked => seasonHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                            <div style={{ display: radioKey == '预热模版' ? 'none' : "grid", marginTop: 10, gridTemplateColumns: '74px 60px 1fr' }}>
                            <b style={{ display: 'block', marginRight: 10 }} >销售季节:</b>
                                <Checkbox checked={salesSeasonSelectedKey} onChange={(e) => {
                                    if (e.target.checked) {
                                        setSalesSeasonSelectedKey(true)
                                        setSalesSeasonSelectedTags(filterDimension['sales_season'])
                                    } else {
                                        setSalesSeasonSelectedKey(false)
                                        setSalesSeasonSelectedTags([])
                                    }
                                }}>全部</Checkbox>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                    {
                                        filterDimension['sales_season'].map(item => {
                                            return <CheckableTag
                                                style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                                key={item}
                                                checked={salesSeasonSelectedTags.indexOf(item) > -1}
                                                onChange={checked => salesSeasonHandleChange(item, checked)}
                                            >
                                                {item}
                                            </CheckableTag>
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{ display: "grid", marginTop: 10, gridTemplateColumns: '74px 60px 1fr' }}>
                                <b style={{ display: 'block', marginRight: 10 }} >品类:</b>
                                <div>
                                    <Checkbox checked={categorySelectedKey}  onChange={(e) => {
                                        if (e.target.checked) {
                                            setCategorySelectedKey(true)
                                            setCategorySelectedTags(filterDimension['category'])
                                        } else {
                                            setCategorySelectedKey(false)
                                            setCategorySelectedTags([])
                                        }
                                    }}>全部</Checkbox>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                    {
                                        filterDimension['category'].map(item => {
                                            return <CheckableTag
                                                style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                                key={item}
                                                checked={categorySelectedTags.indexOf(item) > -1}
                                                onChange={checked => categoryHandleChange(item, checked)}
                                            >
                                                {item}
                                            </CheckableTag>
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{ display: radioKey == '预热模版'|| gradeOfPayMoneyTags.length!==0 ? 'none' : "grid", marginTop: 10, gridTemplateColumns: '74px 60px 1fr' }}>
                                <b style={{ display: 'block', marginRight: 10 }} >TOP款:</b>
                                <div>
                                    <Checkbox checked={bandRankOfPayMoneySelectedKey} onChange={(e) => {
                                        if (e.target.checked) {
                                            setBandRankOfPayMoneySelectedKey(true)
                                            setBandRankOfPayMoneySelectedTags(filterDimension['band_rank_of_pay_money'])
                                        } else {
                                            setBandRankOfPayMoneySelectedKey(false)
                                            setBandRankOfPayMoneySelectedTags([])
                                        }
                                    }}>全部</Checkbox>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                    {
                                        filterDimension['band_rank_of_pay_money'].map(item => {
                                            return <CheckableTag
                                                style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                                key={item}
                                                checked={bandRankOfPayMoneySelectedTags.indexOf(item) > -1}
                                                onChange={checked => bandRankOfPayMoneyHandleChange(item, checked)}
                                            >
                                                {item}
                                            </CheckableTag>
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{ display: radioKey == '预热模版' ? 'none' : "flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10 }}>价格带:</b>
                                <Checkbox checked={priceSelectedKey} onChange={(e) => {
                                    if (e.target.checked) {
                                        setPriceSelectedKey(true)
                                        setPriceSelectedTags(filterDimension['price'])
                                    } else {
                                        setPriceSelectedKey(false)
                                        setPriceSelectedTags([])
                                    }
                                }}>全部</Checkbox>
                                {
                                    filterDimension['price'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                            key={item}
                                            checked={priceSelectedTags.indexOf(item) > -1}
                                            onChange={checked => priceHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                            <div style={{ display: radioKey == '预热模版'|| bandRankOfPayMoneySelectedTags.length!==0 ? 'none' : "flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10 }}>销售等级:</b>
                                <Checkbox checked={gradeOfPayMoneySelectedKey} onChange={(e) => {
                                    if (e.target.checked) {
                                        setGradeOfPayMoneySelectedKey(true)
                                        setGradeOfPayMoneyTags(filterDimension['grade_of_pay_money'])
                                    } else {
                                        setGradeOfPayMoneySelectedKey(false)
                                        setGradeOfPayMoneyTags([])
                                    }
                                }}>全部</Checkbox>
                                {
                                    filterDimension['grade_of_pay_money'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                            key={item}
                                            checked={gradeOfPayMoneyTags.indexOf(item) > -1}
                                            onChange={checked => gradeHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10, marginTop:10 }}>维度排序:</b>
                                <div id="dimensionalOrdering" style={{ width: '100%', border: "1px dashed #cccccc", height: 50, marginTop: 5, display: 'flex', flexWrap: 'nowrap', overflow: "auto", marginLeft: 4 }}>
                                    <DraggableArea tags={dimensionalOrdering} render={({ tag, index }) => (
                                        <div style={{
                                            margin: "3px",
                                            fontSize: '13px',
                                            border: " 1px dashed #cccccc",
                                            borderRadius: '4px',
                                            padding: "0 8px",
                                            lineHeight: "30px",
                                            color: "#666666",
                                            backgroundColor: "rgba(255, 255, 255, 0.7);",
                                            whiteSpace: 'nowrap',
                                        }} >
                                            <span> {tag.name} </span>
                                        </div>
                                    )} onChange={tags => setDimensionalOrdering(tags)} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                <Button disabled={effective} type="primary" onClick={() => getData()}>确定</Button>
                                <Button disabled={effective} style={{ marginLeft: 10 }} type="primary" danger onClick={() => {
                                    setOneStartTime('')
                                    setOneEndTime('')
                                    setTwoStartTime('')
                                    setTwoEndTime('')
                                    setSeasonStatusSelectedTags([])
                                    setYearSelectedTags([])
                                    setYearSelectedKey(false)
                                    setSeasonSelectedTags([])
                                    setSeasonSelectedKey(false)
                                    setDimensionalOrdering([])
                                    setCategorySelectedTags([])
                                    setCategorySelectedKey(false)
                                    setProductLineSelectedTags([])
                                    setProductLineSelectedKey(false)
                                    setPriceSelectedTags([])
                                    setPriceSelectedKey(false)
                                    setGradeOfPayMoneyTags([])
                                    setGradeOfPayMoneySelectedKey(false)
                                    setRadioKey('')
                                    AcceptBuried('reset',"click",{},props.location.pathname,"商品经营报表")
                                }}>重置</Button>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
                <div style={{display:'flex', justifyContent:'flex-end', width: '90vw', margin: "0 auto", marginTop:5}}>
                    <a href="https://pic.bmcsoft.cn/imge_dir/021113133842/021113133842.html" target="_Blank" style={{marginRight:10}}><Button type="link">操作手册</Button></a>
                    <Button type="dashed" onClick={()=>{
                        AcceptBuried('print',"click",{},props.location.pathname,"商品经营报表")
                        exportPdf("商品经营报表")
                    }}>导出PDF</Button>
                </div>
                <div id="exportPdf">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Text strong style={{ fontSize: 20 }}>{oneStartTime == '' ? '' : oneStartTime + '——' + oneEndTime}</Text>
                        <Text style={{ marginLeft: 20, marginRight: 20, fontSize: 20 }} strong>对比</Text>
                        <Text strong style={{ fontSize: 20 }}>{twoStartTime == '' ? '' : twoStartTime + '——' + twoEndTime}</Text>
                    </div>
                    <div style={{ display: 'flex', width: '90vw', justifyContent: 'space-between', margin: "0 auto",marginBottom:5}}>
                    <div style={{display:'flex'}}>
                            <Text strong style={{ fontSize: 16, marginRight: 10 }}>图例:</Text>
                            {
                                legend.map((item, index) => {
                                    return <div style={{ display: "flex" }}>
                                        <div style={{ width: 30, height: 15, marginTop: 5, backgroundColor: index == 0 ? '#fa897b ' : index == 1 ? '#ffdd94' : '#75cce8' }}></div>
                                        <Text strong style={{ marginLeft: 5, marginRight: 10 }}>{item}</Text>
                                    </div>
                                })
                            }
                    </div>
                    {/* <div style={{display:'flex'}}>
                        <a href="https://pic.bmcsoft.cn/imge_dir/021113133842/021113133842.html" target="_Blank" style={{marginRight:10}}><Button type="link">操作手册</Button></a>
                        <Button type="dashed" onClick={()=>{
                                if (radioKey == '') {
                                    message.warn('请选择模版')
                                } else if (oneStartTime == '') {
                                    message.warn('请选择正确时间维度1')
                                } else if (twoStartTime == '') {
                                    message.warn('请选择正确时间维度2')
                                } else if (productLineSelectedTags.length == 0 && yearSelectedTags.length == 0 && seasonSelectedTags.length == 0 && categorySelectedTags.length == 0 && priceSelectedTags.length == 0 && seasonStatusSelectedTags.length == 0 && bandRankOfPayMoneySelectedTags.length == 0) {
                                    message.warn('至少选择一个维度')
                                } else {
                                    let dimensionalOrderingList = []
                                    dimensionalOrdering.map(item=>{
                                        dimensionalOrderingList.push(item.id)
                                    })
                                    let dataProps = {}
                                    dataProps.dimensionalOrderingList = dimensionalOrderingList
                                    dataProps.roleId = cookie.load('role')
                                    dataProps.shopList = cookie.load('shopList')
                                    dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
                                    dataProps['oneStartTime'] = oneStartTime
                                    dataProps['oneEndTime'] = oneEndTime
                                    dataProps['twoStartTime'] = twoStartTime
                                    dataProps['twoEndTime'] = twoEndTime
                                    dataProps['productLine'] = productLineSelectedTags
                                    dataProps['year'] = yearSelectedTags
                                    dataProps['season'] = seasonSelectedTags
                                    dataProps['category'] = categorySelectedTags
                                    dataProps['price'] = priceSelectedTags
                                    dataProps['season_status'] = seasonStatusSelectedTags
                                    dataProps['band_rank_of_pay_money'] = bandRankOfPayMoneySelectedTags
                                    setEffective(1)
                                    if (radioKey == '销售模版') {
                                        axios({
                                            data: dataProps,
                                            method: 'POST',
                                            url: servicePath.downloadCommodityOperationTableSalesData,
                                            responseType: "blob",
                                            headers: {
                                                'Content-Type': 'application/json',  //如果写成contentType会报错
                                                'authorization': cookie.load('token')
                                            }
                                        }).then(
                                            res => {
                                                setEffective(0)
                                                downExcel(res)
                                            }
                                        ).catch(e => {
                                            setEffective(0)
                                            message.error({ content: '文件制作出错，请联系it同事！' })
                                        })
                                    }else if (radioKey == '预热模版') {
                                        axios({
                                            data: dataProps,
                                            method: 'POST',
                                            url: servicePath.downloadCommodityOperationTablePreheatData,
                                            responseType: "blob",
                                            headers: {
                                                'Content-Type': 'application/json',  //如果写成contentType会报错
                                                'authorization': cookie.load('token')
                                            }
                                        }).then(
                                            res => {
                                                setEffective(0)
                                                downExcel(res)
                                            }
                                        ).catch(e => {
                                            setEffective(0)
                                            message.error({ content: '文件制作出错，请联系it同事！' })
                                        })
                                    }
                                }
                        }}>下载</Button>
                        <Button type="dashed" onClick={()=>{
                            exportPdf("商品经营报表")
                        }}>导出PDF</Button>
                    </div> */}
                    </div>
                    <div style={{ width: '90vw', margin: "0 auto", }} >
                        <Table 
                            // locale={{
                            //     cancelSort: "点击取消排序",
                            //     triggerAsc: "点击升序",
                            //     triggerDesc: "点击降序"
                            // }}
                            // onChange={(pagination, filters, sorter) => {
                            //     if(sorter['order'] == undefined){
                            //         setData(allData)
                            //     }else{
                            //         setEffective(1)
                            //         let dimensionalOrderingList = []
                            //         dimensionalOrdering.map(item=>{
                            //             dimensionalOrderingList.push(item.id)
                            //         })
                            //         let dataProps = {}
                            //         dataProps.dimensionalOrderingList = dimensionalOrderingList
                            //         dataProps.roleId = cookie.load('role')
                            //         dataProps.shopList = cookie.load('shopList')
                            //         dataProps.data = data
                            //         dataProps.df_field_name = sorter['columnKey']
                            //         dataProps.is_asc = sorter['order']
                            //         dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
                            //         console.log(dataProps);
                            //         NbAxios(dataProps, "POST", servicePath.getCommodityOperationTableDfOrder).then(res => {
                            //             setEffective(0)
                            //             if (res.success) {
                            //                 setData(res.data.data);
                            //             } else {
                            //                 message.warn(res.errorMsg)
                            //             }
                            //         }).catch((err) => { 
                            //             // 获取500错误
                            //             setEffective(0)
                            //             message.warn('查询接口报错，请联系IT帮忙解决')
                            //         })
                            //     }

                            // }}
                            rowClassName={style.orange}
                            loading={effective ? true : false} columns={column} dataSource={data} bordered 
                            pagination={false} scroll={{ y: 'calc(90vh - 300px)', x: 1000 }}
                            rowKey={"key"} />
                    </div>
                </div>
            </Spin>
        </div>
    )

}

export default Index