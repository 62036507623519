import React, {useState, useEffect} from 'react';
import {Form, DatePicker, Select, message, Typography, Divider, Empty, Tag} from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from "moment"
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios'
import servicePath from '../../../config/apiUrl'
import TitleCard from '../salesPerformanceSummary/components/titleCard'
const {CheckableTag} = Tag

function Index(props){
    // 不可选日期
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    // 日期格式
    const dateFormat = 'YYYY-MM-DD'
    // 搜索项目
    const [project, setProject] = useState('')
    const [projectList, setProjectList] = useState([])
    // 年份
    const [year, setYear] = useState(moment().format('YYYY'))
    // 开始日期
    const [startDate, setStartDate] = useState(moment().startOf('month'))
    // 结束日期
    const [endDate, setEndDate] = useState(moment().endOf('month'))
    // 月份
    const [month, setMonth] = useState(moment().startOf('month').format('MM'))
    // 月数据
    const [monthData, setMonthData] = useState([])
    // 季度
    const [quarterly, setQuarterly] = useState(moment().quarter())
    // 季度数据
    const [quarterlyData, setQuarterlyData] = useState([])
    // 年数据
    const [yearData, setYearData] = useState([])
    useEffect(()=>{
        if (props.history.location.pathname.split('/')[3] == 'standard') {
            getProjectByDivision('标品事业部')
        } else if (props.history.location.pathname.split('/')[3] == 'unstandard') {
            getProjectByDivision('非标事业部')
        }
    },[])
     // 监听日期、项目变化
     useEffect(() => {
        //查询每个月店铺业绩汇总
        getMonthShopSummary()
        // 修改年份
        setYear(startDate.format('YYYY'));
    }, [startDate, endDate, project])

    // 监听月份
    useEffect(() => {
        setQuarterly(Math.floor((month % 3 == 0 ? (month / 3) : (month / 3 + 1))));
    }, [month])

    // 监听季度、项目变化
    useEffect(()=>{
        // 计算季度结束日期
        let endMonth = 3 * parseInt(quarterly) //当季度最后一个月
        /* 对月数进行格式化 */
        if (endMonth < 10)
            endMonth = '0' + endMonth
        else
            endMonth += ''
        let endMonthDays = moment(year + '-' + endMonth).daysInMonth(); // 末尾月天数
        let endDays = year + '-' + endMonth + '-' + endMonthDays //完整年月日整合
        let dataProps = {
            "start_date": moment(moment(year + '-01-01').toDate()).quarter(quarterly).format('YYYY-MM-DD'),
            "end_date": endDays,
            "project": project,
            "shop_id": cookie.load('shopList'),
            "role_id": cookie.load('role'),
        }
        NbAxios(dataProps, "POST", servicePath.queryYearQuarterlyShopSalesSummary).then(res => {
            if (res.success) {
                setQuarterlyData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    },[quarterly, project])

    // 监听年份、项目变化
    useEffect(()=>{
        let dataProps = {
            "start_date": moment(year).startOf('year').format('YYYY-MM-DD'),
            "end_date": moment(year).endOf('year').format('YYYY-MM-DD'),
            "project": project,
            "shop_id": cookie.load('shopList'),
            "role_id": cookie.load('role'),
        }
        NbAxios(dataProps, "POST", servicePath.queryYearQuarterlyShopSalesSummary).then(res => {
            if (res.success) {
                setYearData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    },[year, project])

    //查询每个月店铺业绩汇总
    const getMonthShopSummary = ()=>{
        if(project == ''){

        }else{
            let dataProps = {
                "shop_id": cookie.load('shopList'),
                "role_id": cookie.load('role'),
                "start_date": startDate.format('YYYY-MM-DD'),
                "end_date": endDate.format('YYYY-MM-DD'),
                "project": project
            }
            NbAxios(dataProps, "POST", servicePath.queryMonthShopSummary).then(res => {
                if (res.success) {
                    console.log(res.data);
                    setMonthData(res.data);
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 获取项目
    const getProjectByDivision = (division)=>{
        let dataProps = {
            "role_id": cookie.load('role'),
            "division": division
        }
        NbAxios(dataProps, "POST", servicePath.queryProjectByDivision).then(res => {
            if (res.success) {
                setProjectList(res.data);
                setProject(res.data[0])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    //月份改变
    function monthChange(date, dateString) {
        if (date === null || date === '' || date === undefined) {
            message.warn('请选择正确的月份')
            return false
        }
        setStartDate(moment(dateString).startOf('month'));
        setEndDate(moment(dateString).endOf('month'));
        setMonth(moment(dateString).startOf('month').format('MM'))
    }
     // 范围选择
     const rangeHandleChange = (tag, checked) => {
        setProject(tag)
    }

    return (
        <div style={{marginLeft: 30, marginTop: 20}}>
            <div>
                <Form style={{display:'flex'}}>
                    <Form.Item label="时间">
                        <DatePicker
                            disabledDate={disabledDate}
                            locale={locale}
                            picker="month"
                            onChange={monthChange}
                            defaultValue={moment(startDate, dateFormat)}
                        />
                    </Form.Item>
                    <Form.Item label="项目" style={{marginLeft:30}}>
                        {projectList.map(tag => (
                                <CheckableTag
                                    style={{fontSize:"15px"}}
                                    key={tag}
                                    checked={project == tag}
                                    onChange={checked => rangeHandleChange(tag, checked)}
                                >
                                    {tag}
                                </CheckableTag>
                        ))}
                    </Form.Item>
                </Form>
            </div>
            <Divider orientation="left">{month}月单月实销业绩与完成率</Divider>
            <div style={{display: 'flex'}}>
                <div style={{width: '100vw', display: "flex", alignItems: 'center'}}>
                    {
                        monthData.length == 0 ?
                            <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}><Empty/></div> :
                            monthData.map(item => {
                                return <TitleCard
                                    project={item.shop}
                                    sum={item.sale}
                                    target={item.goal_sale}
                                    finshRate={item.completion_rate}
                                    date={item.date}
                                />
                            })
                    }
                </div>         
            </div>
            <Divider orientation="left">{quarterly}季度累计实销业绩与完成率</Divider>
            <div style={{width: '100vw', display: "flex", alignItems: 'center'}}>
                {
                    quarterlyData.length == 0 ?
                        <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}><Empty/></div> :
                        quarterlyData.map(item => {
                            return <TitleCard
                                project={item.shop}
                                sum={item.sale}
                                target={item.goal_sale}
                                finshRate={item.completion_rate}
                            />
                        })
                }
            </div>
            <Divider orientation="left">{year}年累计实销业绩与完成率</Divider>
            <div style={{display: 'flex'}}>
                <div style={{width: '100vw', display: "flex", alignItems: 'center'}}>
                    {
                        yearData.length == 0 ?
                            <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}><Empty/></div> :
                            yearData.map(item => {
                                return <TitleCard
                                    project={item.shop}
                                    sum={item.sale}
                                    target={item.goal_sale}
                                    finshRate={item.completion_rate}
                                />
                            })
                    }
                </div>
            </div>
        </div>
    )

}

export default Index