import React, { useContext, useEffect, useState } from 'react';
import CursorItem from '../components/CursorItem'
import { Carousel, Input, Button, Drawer, Descriptions, Row, Col, Modal,Tooltip,Typography, Tabs, Pagination, message, Checkbox , Divider} from 'antd'
import { SearchOutlined, FlagOutlined,QuestionCircleOutlined, BulbOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { StateContext } from '../../raceShop/store/stateShare'
import Debounce from '../../../config/utils/debounce'
import NbAxios from '../../../config/utils/nbAxios'
import servicePath from '../../../config/apiUrl'
import moment from 'moment'
import cookie from 'react-cookies'
import './style.css'
import titleUrl from '../static/img/nightCursor.png'
import { useTrail, animated } from 'react-spring'
const { Paragraph, Text, Title } = Typography;
const { TabPane } = Tabs

function OrderCursor(props) {

    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    // 报名状态参数对应的含义字典
    const orderStatus = {
        0: '我要报名',
        1: "报名成功",
        2: "重新报名",
        3: "报名中",
        4: "未能开课",
        5: "已结课",
        6: "报名还未开启"
    }

    // 占位卡片信息
    const zhanweiCardMes = [
        {
            types_name:"热烈欢迎",
            course_name:"等你来开课！",
            tags_name:"show出风采",
            course_clock:"就是现在"
        }
    ]

    // 用户对应报名状态
    const [userOrderStatus, setUserOrderStatus] = useState(0)

    // 课程类型
    const [learnType, setLearnType] = useState('可报名')

    // 可旁听列表
    const [fullCursor, setFullCursor] = useState([])

    // 可报名列表
    const [canOrderCursor, setCanOrderCursor] = useState([])

    // 分页器总数字典
    const pageTotal = {
        '可报名':canOrderCursor,
        '旁听':fullCursor
    }

    // 搜索框中的值
    const [searchValue, setSearchValue] = useState('')

    // 每页数量
    const [pageSize, setPageSize] = useState(10)

    // 页码
    const [page, setPage] = useState(1)

    // 数据总量
    const [total, setTotal] = useState(1)

    // 课程详情
    const [cursorDetail, setCursorDetail] = useState()

    // 右侧推荐
    const [gogogo, setGogogo] = useState([
        {
            title: <div><BulbOutlined style={{ marginRight: 3 }} />来做老师</div>,
            bgColor: "rgba(68,129,235)",
            path:"cursorBase"
        },
        {
            title: <div><FlagOutlined style={{ marginRight: 3 }} />学习中心</div>,
            bgColor: "rgba(0,212,156)",
            path:"myStudy"
        },
        {
            title: <div><UnorderedListOutlined style={{ marginRight: 3 }} />全部课程</div>,
            bgColor: "rgba(135,206,235)",
            path:"teachCourse"
        }
    ])

    // 动画配置
    const trailAnimes = useTrail(learnType == '可报名' ? canOrderCursor.length : fullCursor.length, {
        transform: "translate3d(0px,0px,0px)",
        from: { transform: "translate3d(30px,20px,0px)" },
        config: {
            mass: 0.8,
            tension: 280,
            friction: 20,
        },
        delay: 200,
    })

    // 头部中的输入框
    const searchIcon = (
        <div className="header-search-icon"><SearchOutlined /></div>
    )
    // 抽屉是否打开
    const [visible, setVisible] = useState(false)

    // 轮播下的头部
    const Header = (
        <div style={{ display: "grid", alignItems: "center", justifyContent: "space-between", gap: 10, gridTemplateColumns: "repeat(3,1fr)" }}>
            {/* <Title level={5} style={{ justifySelf: "start" }}>所有通知消息 <Tooltip placement="right" title="显示所有消息"><QuestionCircleOutlined /></Tooltip></Title> */}
            <Tabs type="card" style={{ height: 40 }} onChange={tabChange}>
                <TabPane tab={'约课'} key={'可报名'}></TabPane>
                <TabPane tab={<span>
                    旁听 
                    <Tooltip placement="right" title={'约满的课可以在这里参加旁听'} defaultVisible={true} color={'red'}>
                        <QuestionCircleOutlined style={{marginLeft:'8px'}} />
                    </Tooltip>
                </span>} key={'旁听'} ></TabPane>
            </Tabs>
            <Input autoFocus onPressEnter={Debounce(searchChange, 700, true)} onChange={Debounce(searchChange, 700)} suffix={searchIcon} placeholder="搜搜你想听的课" style={{ width: 300, justifySelf: "center", borderRadius: 20 }} />
            <Pagination size="small" hideOnSinglePage simple current={page} total={searchCursor(pageTotal[learnType]).length} pageSize={pageSize} style={{ justifySelf: "flex-end" }} onChange={pageChange} />
        </div>
    )

    // 课程所有分类
    const [courseType , setCourseType ]  = useState([])

    // 多选框配置
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    // 用户选择的课程分类
    const [ chooseCourseTypes , setChooseCourseTypes ] = useState([])

    // 打开选择课程分类
    const [openCourseType , setOpenCourseType ]  = useState(false)

    useEffect(() => {
        if (state.size.height < 700) {
            setPageSize(5)
        } else {
            setPageSize(10)
        }
    }, [state.size])

    useEffect(() => {
        // 获取所有课程
        getAllCursor()
        // 查询用户拥有课程分类
        getOwnTypeList()
    }, [])

    useEffect(()=>{
        if(courseType.length != 0){
            setOpenCourseType(true)
        }
    },[courseType])

    // 报名按钮是否可以点击,默认可以点击
    const [btnDisable, setBtnDisable] = useState(false)
    // 抽屉页脚
    const footer = (
        <Button type="primary" onClick={orderCursor} disabled={btnDisable} style={{ borderRadius: "50px" }}>{orderStatus[userOrderStatus]}</Button>
    );

    // 轮播图高度
    const [carouselHeight, setCarouselHeight] = useState(state.size.height < 700 ? 230 : 280)

    return (
        <div style={{ margin: "auto", height: state.size.height - 43, background: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="order-cursor-index " style={{ width: state.size.width < 1400 ? "80vw" : "85vw", height: "inherit" }}>
                <div className="cursor-carousel-wapper">
                    <Carousel style={{
                        width: "100%",
                        height: carouselHeight,
                        overflow: "hidden", borderRadius: "20px"
                    }} autoplay>
                        {[1].map((item, index) => {
                            return (
                                <CursorItem
                                    // title={'特大消息！BMC夜校开课啦！'}
                                    width={"60vw"}
                                    cardTitleHeight={carouselHeight}
                                    bgColor={`url(${titleUrl})`}
                                    onlyTitle
                                />
                            )
                        })}
                    </Carousel>
                    <div className="cursor-top-right">
                        {gogogo.map((item, index) => {
                            return (
                                index != 2 ?
                                <CursorItem
                                    title={item.title}
                                    width={"100%"}
                                    bgColor={item.bgColor}
                                    height={parseInt(carouselHeight / 3)}
                                    cardTitleHeight={parseInt(carouselHeight / 3)}
                                    titleAlign
                                    onlyTitle
                                    onClick={()=>goOther(item.path)}
                                />
                                : <Tooltip placement="left" title={'点击这里进行求课'} defaultVisible={true} color={'red'}>
                                    <CursorItem
                                        title={item.title}
                                        width={"100%"}
                                        bgColor={item.bgColor}
                                        height={parseInt(carouselHeight / 3)}
                                        cardTitleHeight={parseInt(carouselHeight / 3)}
                                        titleAlign
                                        onlyTitle
                                        onClick={()=>goOther(item.path)}
                                    />
                                </Tooltip>
                            )
                        })}
                    </div>
                </div>
                <div className="cursor-content-wapper">
                    <div className="cursor-content-header">
                        {Header}
                    </div>
                    <div className="cursor-content">
                        {(learnType == '可报名' ? searchCursor(canOrderCursor) : searchCursor(fullCursor)).slice((page - 1) * pageSize, pageSize * page).map((item, index) => {
                            return (
                                <animated.div key={index} style={trailAnimes[index]} >
                                    <CursorItem
                                        courseKind={item.course_kind}
                                        className={`my-cursor`}
                                        title={item.course_name}
                                        introduce={item.course_introduction}
                                        maxNum={item.participant_ceiling}
                                        signNum={item.number}
                                        width={'16vw'}
                                        teachear={item.creator_name}
                                        openTime={item.course_clock=="就是现在"?"就是现在": moment(item.course_clock).format('MM-DD HH:mm')}
                                        endTime={moment(item.registration_end_time).format('MM-DD HH:mm')}
                                        bgColor={item.course_cover}
                                        tags={item.tags_name.split(',')}
                                        cursorType={item.types_name}
                                        teachType={item.form == 2 ? '线上' : '面授'}
                                        action={learnType == '可报名' ? '报名' : "旁听"}
                                        onClick={item.course_clock=="就是现在"?()=>goOther('cursorBase'): () => getCursorDetail(item)}
                                        isAttend={item.allow_audit}
                                        onlyTitle
                                    // showIntroduce
                                    />
                                </animated.div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <Drawer
                title={footer}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
                width={600}
                height={280}
                // footer={footer}
                zIndex={100000000}
                footerStyle={{ border: "none", padding: 10, }}
                bodyStyle={{ height: 200 }}
            >
                <div
                    style={{ display: "grid", height: "100%", gridTemplateRows: "200px 1fr" }}
                >
                    {cursorDetail &&
                        <CursorItem
                            className={`my-cursor-draw`}
                            title={cursorDetail.course_name}
                            maxNum={cursorDetail.participant_ceiling}
                            signNum={cursorDetail.number}
                            bgColor={cursorDetail.course_cover}
                            width={'100%'}
                            teachear={cursorDetail.creator_name}
                            openTime={moment(cursorDetail.course_clock).format('MM-DD HH:mm')}
                            tags={cursorDetail.tags_name.split(',')}
                            cursorType={cursorDetail.types_name}
                            teachType={cursorDetail.form == 2 ? '线上' : '面授'}
                            onlyTitle
                            // showIntroduce
                        />
                    }
                    <div className="order-draw-cursor-detail scroll-bule" style={{ height: "100%", overflow: "auto", padding: 10 }} >
                        <Descriptions  >
                            <Descriptions.Item label={(cursorDetail && { 2: '引领者', 1: '授课老师' }[cursorDetail.course_kind]) || "授课老师"} span={2} >{cursorDetail && { 2: cursorDetail.leader_list == undefined ? '' : cursorDetail.leader_list.join(','), 1: cursorDetail.creator_name }[cursorDetail.course_kind]}</Descriptions.Item>
                            <Descriptions.Item label={"课程时长"} span={1}><span>{cursorDetail && cursorDetail.course_duration}</span></Descriptions.Item>
                            <Descriptions.Item label="报名截止" span={2}>{cursorDetail && moment(cursorDetail.registration_end_time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
                            <Descriptions.Item label={"反馈时限"} span={1}>{cursorDetail && cursorDetail.feedback_time}天内</Descriptions.Item>
                            <Descriptions.Item label="课程类型" span={2} >{cursorDetail && { 1: '分享', 2: "讨论" }[cursorDetail.course_kind]}</Descriptions.Item>
                            <Descriptions.Item label="能否旁听" span={1} >{cursorDetail && cursorDetail.allow_audit ? '是' : '否'}</Descriptions.Item>
                            {/* <Descriptions.Item label="报名类型" span={1} >约课</Descriptions.Item> */}
                            {/* <Descriptions.Item label="前置知识" span={2} >js基础</Descriptions.Item> */}
                            <Descriptions.Item label="课程介绍 " span={3} >
                                {cursorDetail && cursorDetail.course_introduction}
                            </Descriptions.Item>
                            <Descriptions.Item label="课程详情 " span={3} >
                                <Paragraph  >
                                    {cursorDetail && cursorDetail.course_details}
                                </Paragraph>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>
            </Drawer>

            
            {/* 选择感兴趣的分类 */}
            <Modal 
                title="请选择你感兴趣的课程"
                visible={openCourseType}
                onCancel={()=>setChooseCourseTypes(false)}
                footer={[]}
                centered={true}
                maskClosable={false}
                closable={false}
                width={800}
            >
                <Checkbox indeterminate={indeterminate} onChange={(e)=>{
                     setChooseCourseTypes(e.target.checked ? courseType : []);
                     setIndeterminate(false);
                     setCheckAll(e.target.checked);
                }} checked={checkAll}>
                    全选
                </Checkbox>
                <Divider />
                <Checkbox.Group style={{ width: '100%' }} value={chooseCourseTypes} onChange={(list)=>{
                    setChooseCourseTypes(list);
                    setIndeterminate(!!list.length && list.length < courseType.length);
                    setCheckAll(list.length === courseType.length);
                }}>
                   <Row>
                        {
                            courseType.map(item=>{
                                return <Col span={8}>
                                    <Checkbox value={item}>{item}</Checkbox>
                                </Col>
                            })
                        }
                        <Col span={8}></Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <Button style={{marginTop:'10px'}} onClick={()=>{
                                // console.log(chooseCourseTypes)
                                let dataProps = {}
                                dataProps.shopId = cookie.load('shopList')
                                dataProps.roleId = cookie.load('role')
                                dataProps.principal_uuid = cookie.load('id')
                                dataProps.type_name_list = chooseCourseTypes
                                NbAxios(dataProps, 'POST', servicePath.createNsFocusType).then(res => {
                                    if (res.success) {
                                        message.success('您已经关注相关分类啦')
                                        setOpenCourseType(false)
                                    }
                                }).catch(e => {
                                })
                            }}>确定</Button>
                        </Col>
                   </Row>
                </Checkbox.Group>
            </Modal>
        </div>
    )

    // 一个搜索函数
    function searchCursor(arr){
        return arr.filter((item) => {
            if(item.course_name === "等你来开课！"){
                return true
            }else{
                let res = item.creator_number.toString().toUpperCase().search(searchValue) != -1||item.creator_email.toString().toUpperCase().search(searchValue) != -1 || item.tags_name.toString().toUpperCase().search(searchValue) != -1 || item.types_name.toString().toUpperCase().search(searchValue) != -1 || item.course_name.toString().toUpperCase().search(searchValue) != -1 || item.course_introduction.toString().toUpperCase().search(searchValue) != -1 ||  item.creator_name.toString().toUpperCase().search(searchValue) != -1
                return res
            }
        })
    }

    // 分页器页码变化时
    function pageChange(page) {
        setPage(page)
    }
    // tabs标签变化时
    function tabChange(key) {
        if (key == '可报名') {
            setTotal(canOrderCursor.length)
        } else if (key == '旁听') {
            setTotal(fullCursor.length)
        }
        setLearnType(key)
    }
    // 搜索内容变化时
    function searchChange(e) {
        if (searchValue === e.target.value) {
            return false
        }
        setPage(1)
        setSearchValue((e.target.value).toString().toUpperCase())
    }

    // 抽屉关闭
    function onClose() {
        setVisible(false)
    }

    // 抽屉打开
    // function openDraw(value) {
    //     getCursorDetail(value)
        
    // }

    // 报名接口
    function orderCursor() {
        let dataProps = {}
        dataProps.shopId = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.ns_courses_publish_id = cursorDetail.ns_courses_publish_id
        dataProps.principal_uuid = cookie.load('id')
        NbAxios(dataProps, 'POST', servicePath.createNsCoursesParticipant).then(res => {
            if (res.success) {
                setBtnDisable(true)
                setUserOrderStatus(3)
                goOther('myStudy')
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            console.log(e);
        })
    }

    // 获取课程详情
    function getCursorDetail(cursor) {
        let dataProps = {}
        dataProps.shopId = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.ns_courses_publish_id = cursor.id
        dataProps.principal_uuid = cookie.load('id')
        setCursorDetail()
        setBtnDisable(false)
        NbAxios(dataProps, 'POST', servicePath.queryNsCoursesPublishDetail).then(res => {
            if (res.success) {
                let data = res.data
                setCursorDetail(data)
                if ([1, 3, 4].indexOf(data.register_status) != -1) {
                    setBtnDisable(true)
                } else if (data.register_status == 2) {
                    message.warn('报名失败了，您可以选择重新报名！')
                    setBtnDisable(false)
                }
                setUserOrderStatus(data.register_status)
                if(moment(data.registration_start_time) > moment()){
                    setBtnDisable(true)
                    setUserOrderStatus(6)
                }
                setVisible(true)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            console.log(e);
        })
    }

    // 获取所有课程
    function getAllCursor() {
        let dataProps = {}
        dataProps.shopId = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        NbAxios(dataProps, 'POST', servicePath.queryNsCoursesPublishMainList).then(res => {
            if (res.success) {
                let data = res.data
                if(data.可报名.length==0){
                    data.可报名=zhanweiCardMes
                }
                if(data.旁听.length==0){
                    data.旁听=zhanweiCardMes
                }
                setFullCursor(data.旁听)
                setCanOrderCursor(data.可报名)
            }
        }).catch(e => {
            console.log(e);
        })
    }

    // 页面跳转
    function goOther(path){
        props.history.push(path) 
    }

    // 查询用户拥有课程分类
    function getOwnTypeList(){
        let dataProps = {}
        dataProps.shopId = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.principal_uuid = cookie.load('id')
        NbAxios(dataProps, 'POST', servicePath.queryNsFocusTypeList).then(res => {
            if (res.success) {
                if(res.total == 0){
                    // 查询课程分类
                    getCursorType()
                }
            }
        }).catch(e => {
        })
    }

    // 获取课程分类
    function getCursorType(){
        let dataProps = {
            "creator_id": cookie.load('id'),
            "type_name": "1",
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryCursorTypes).then(res => {
            if (res.success) {
                let types_list = []
                res.data.all_types.map(item=>{
                    types_list.push(item.label)
                })
                setCourseType(types_list);
            } else {
                message.error(res.errorMsg)
            }
        })
    }

}

export default OrderCursor