import React, { useEffect, useState, useMemo } from 'react';
import style from './targetCard.module.css';
import { Progress, Typography, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Circle from '../circle'
import { ReactComponent as Down } from './svg/down.svg'
import { ReactComponent as Up } from './svg/up.svg'


const { Title, Paragraph, Text, Link } = Typography;
function TargetCard({
    target,
    finsh,
    compared,
    showSetting,
    title,
    settingClick,
    timePercent = 0,
    lastTitle,
    lastNumber,
    ...rest
}) {
    let compareNum = parseFloat(compared)
    // let titleModle = {
    //     '周': ['本周已完成', '目标'],
    //     '月': ['已完成', <>{showSetting ? <Link onClick={settingClick} style={{ marginLeft: 2 }}>{'目标'}</Link> : '目标'}</>],
    //     '年': ['已完成', <><Link onClick={settingClick} style={{ marginLeft: 2 }}>{showSetting && '目标'}</Link></>]
    // }
    let titleModle = ['已完成', <>{showSetting ? <Link onClick={settingClick} style={{ marginLeft: 2 }}>{'目标'}</Link> : '目标'}</>]

    const textIcon = {
        'up': <Up width={10} height={10} />,
        'down': <Down width={10} height={10} />,
        'default': '-',
    }

    // 根据金额转换单位的函数,传入数字长度
    function translateMoney(num) {
        if (num === undefined || num === null) {
            num = 0
        }
        // 获取数字的长度
        let length = num.toString().length
        let res
        if (length < 5) {
            return num
        }
        else if (length >= 5 && length < 9) {
            res = ((num / 10000)).toFixed(1) + '万'
            return res
        }
        else if (length >= 9) {
            res = (num / 100000000).toFixed(1) + '亿'
            return res
        }
    }

    // useMemo缓存返回变量，对应依赖变化才重新计算
    const finshTranslateMoney= useMemo(()=>translateMoney(finsh),[finsh])
    const targetTranslateMoney= useMemo(()=>translateMoney(target),[target])

    return (
        <div className={style.targetCardWapper} {...rest} >
            <div className={style.leftArea}>
                <div className={style.dashboard}>
                    <Progress
                        className={style.progress}
                        type="circle"
                        percent={((parseFloat(finsh) / parseFloat(target) ) * 100).toFixed(0)}
                        format={targetFormat}
                        strokeColor= {'rgb(24,144,255)'}
                    />
                    <Circle className={style.progressCircle} width={105} height={105} percent={timePercent} color={'#faad14'} />
                </div>

                <div className={style.leftLegend} >
                    <Text type="secondary" className={style.payLegend} >支付</Text>
                    <Text type="secondary" className={style.dateLegend} >日期</Text>
                </div>
            </div>
            <div className={style.rightArea} >
                <div className={style.titleRow} >
                    <Text className={style.titleText}>
                        {title} 
                    </Text>
                    <Text className={style.yearText}>
                        {'环比' }{compared}
                        {compareNum > 0 && <Up width={12} height={12} />}
                        {compareNum < 0 && <Down width={12} height={12} />}
                    </Text>
                </div>
                {
                    <div className={style.titleRow}>
                        <Text className={style.lastTitleText}>
                            {lastTitle}
                        </Text>
                        <Text className={style.yearText}>
                            <Text style={{ margin: 0 , fontWeight:'bold'}}>{lastNumber}</Text>
                        </Text>
                    </div> 
                }
                <div className={style.valueRow} >
                    <div style={{ marginRight:15}}>
                        <Text type="secondary" >{titleModle[0]}</Text>
                        <div>
                            <Text style={{ margin: 0 , fontWeight:'bold'}}>{finsh}</Text>
                        </div>
                    </div>
                    <div>
                        <Text type="secondary" >{titleModle[1]}</Text>
                        <div>
                            {/* <Text style={{ margin: 0 , fontWeight:'bold'}}>{type == '周' ? '-' :target}</Text> */}
                            <Text style={{ margin: 0 , fontWeight:'bold'}}>{target}</Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    function targetFormat(percent) {
        return (
            <div className={style.targetPercentContent} >
                {/* <Title level={3} style={{ margin: 0 }} >{type == '周' ? '-' : percent + '%'}</Title> */}
                <Title level={3} style={{ margin: 0 }} >{  target == 0 ?'-':((parseFloat(finsh) / parseFloat(target) ) * 100).toFixed(0) + '%'}</Title>
                <Text style={{ fontSize: ".8rem" }} type="secondary" >{target == 0?'暂无目标':'目标达成'}</Text>
            </div>
        )
    }
}

TargetCard.propTypes = {

}

export default TargetCard

