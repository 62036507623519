import React, {useState, useEffect, useCallback} from 'react';
import {Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Tag, Form, Select, Space, Popover, Typography} from 'antd'
import {EditOutlined, DeleteOutlined, RedoOutlined, SearchOutlined} from '@ant-design/icons';
import servicePath from '../../config/apiUrl'
import Highlighter from 'react-highlight-words';
import axios from 'axios'
import Base64 from 'base-64'
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios'
import '../../static/css/Table.css'
import '../../static/css/index.css'
const {confirm} = Modal
const {Option} = Select
const { Text } = Typography;

function User(props) {

    // 搜索昵称
    const [ searchName , setSearchName ] = useState('')
    const [ searchNameTag , setSearchNameTag ] = useState(0)

    // 昵称输入框
    const nameContent = (
        <div>
             <div style={{ display: 'flex', }}>
                <div><Input style={{width:140}} value={searchName} onChange={(e)=>{setSearchName(e.target.value)}}/></div>
                <div style={{ lineHeight: '30px', marginLeft: 10, fontSize:'15px' }}><a onClick={()=>{
                    setName(searchName)
                    setSearchNameTag(1)
                    setCloseNameBubble(false)
                }}>确定</a></div> 
                <div style={{ lineHeight: '30px', marginLeft: 5 , fontSize:'15px'}}><a onClick={()=>{
                     setName('')
                     setSearchName('')
                     setSearchNameTag(0)
                     setCloseNameBubble(false)
                }}>重置</a></div>
             </div>
        </div>
    )

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    //页面
    const [page, setPage] = useState(1)

    // 总数
    const [ total , setTotal ] = useState(0)

    //用户色数据
    const [data, setData] = useState([])

    //备份
    const [backups, setBackups] = useState([])

    //用户id
    const [userId, setUserId] = useState(0)

    //角色id
    const [roleId, setRoleId] = useState(0)

    //角色名
    const [roleName, setRoleName] = useState('')

    //用户名
    const [username, setUsername] = useState('')

    //密码
    const [password, setPassword] = useState('')

    //真实名字
    const [name, setName] = useState('')

    //邮件
    const [email, setEmail] = useState('')

    //手机号
    const [phone, setPhone] = useState('')

    // 工号
    const [ number , setNumber ] = useState('')

    //设置添加用户开关
    const [addUser, setAddUser] = useState(false)

    //设置添加邮箱用户开关
    const [addEmailUser, setAddEmailUser] = useState(false)

    //设置修改用户开关
    const [updateUser, setUpdateUser] = useState(false)

    //正常角色集合
    const [roles, setRoles] = useState([])

    // 筛选字段
    const [searchText, setSearchText] = useState('')

    // 筛选关键词
    const [searchedColumn, setSearchedColumn] = useState('')
 
    // 筛选信息
    const [searchMessage, setSearchMessage] = useState('暂无筛选信息')
 
    //第一次筛选的关键字
    const [column1, setColumn1] = useState('')
 
    //第一次筛选的值
    const [text1, setText1] = useState('')
 
    //筛选次数
    const [count, setCount] = useState(0)

    //关闭气泡
    const [closeNameBubble, setCloseNameBubble] = useState(false)

    // 筛选功能
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        // searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
            </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
            </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                // setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    // 根据筛选信息更新数据
    useEffect(() => {
        
        if (searchedColumn == "name") {
            if (count == 1) {
                let newData = backups.filter(item => item.name.toString().toLowerCase().includes(searchText.toLowerCase()))
                setData(newData)
            }
        } else {
            setData(backups)
            let newMessage = '暂无筛选信息'
            setSearchMessage(newMessage)
        }
    }, [searchText, searchedColumn])

    // 筛选选择
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        // 将次数保存到新的值
        let newCount = count
        if (newCount == 0) {
            //第一次筛选，之前没有关键字查询，直接次数加一
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
            //保存第一次筛选的关键字和值
            setColumn1(dataIndex)
            setText1(selectedKeys[0])
            newCount = newCount + 1;
            let newMessage = "筛选条件:在"+"\xa0\xa0"+"真实姓名"+"\xa0\xa0"+"筛选" + "\xa0\xa0" + selectedKeys[0]
            setSearchMessage(newMessage)
        } else {
            //有一个关键字查询，判断是否是同一个字段查询
            if (column1 == dataIndex) {
                //如果是都一个字段，次数不进行改变
                setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                setColumn1(dataIndex)
                setText1(selectedKeys[0])
                let newMessage = "筛选条件:在"+"\xa0\xa0"+"真实姓名"+"\xa0\xa0"+"筛选" + "\xa0\xa0" + selectedKeys[0]
                setSearchMessage(newMessage)
            }
        }
        //保存次数
        setCount(newCount)
    };

    // 筛选清空
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
        setSearchedColumn('')
        // 次数减一
        let newCount = count - 1;
        setCount(newCount)
    };

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger:false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 获取所有角色
    useEffect(() => {
        queryAllNorRoles()
    }, [])

    // 获取所有用户
    useEffect(()=>{
        queryAllUser()
    },[page, name])

    //查询全部用户
    const queryAllUser = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "page":page,
            "name": name,
            "page_size":10,
        }
        axios({
            method: 'POST',
            url: servicePath.queryUser,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setData(res.data['data']);
                    setTotal(res.data['total'])
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //查询全部正常角色
    const queryAllNorRoles = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryNorRoles,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setRoles(res.data['data']);
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    const columns = [
        {
            title: '用户名',
            key: 'username',
            dataIndex: 'username',
            width: '200px',
            align: 'center',   
        },
        {
            title: '角色名',
            key: 'role',
            dataIndex: 'role',
            width: '200px',
            align: 'center'
        },
        {
            title: '真实姓名',
            key: 'name',
            dataIndex: 'name',
            width: '200px',
            align: 'center',
        },
        {
            title: '邮箱',
            key: 'email',
            dataIndex: 'email',
            width: '200px',
            align: 'center'
        },
        {
            title: '手机号',
            key: 'phone',
            dataIndex: 'phone',
            width: '200px',
            align: 'center'
        },
        {
            title: '状态',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == 0 ? <Tag color="red">禁用</Tag>
                    : <Tag color="blue">正常</Tag>
            ),
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={() => {
                    setUserId(record.id)
                    form.resetFields()
                    // 设置表单的值
                    form.setFieldsValue(record)
                    setUpdateUser(true)
                }}/>
            ),
        },
        {
            title: '禁用/恢复',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == 0 ? <RedoOutlined onClick={() => recoverUser(index)}/>
                    : <DeleteOutlined onClick={() => deleteUser(index)}/>
            ),
        }
    ]

    //选择角色
    const chooseRole = (value) => {
        setRoleId(value)
    }

    //确认添加用户
    const addOk = () => {
        if(username==''){
            message.error('用户名不能为空')
        }else if(roleId==''){
            message.error('角色必须选择')
        }else if(password==''){
            message.error('密码不能为空')
        }else if(name==''){
            message.error('真实姓名不能为空')
        }else if(email==''){
            message.error('邮箱不能为空')
        }else if(phone==''){
            message.error('手机号不能为空')
        }else if(number==''){
            message.error('工号不能为空')
        }else{
            // Base64加密之后的密码
            let basePassword = Base64.encode(password)
            //向后端传递的参数
            let dataProps = {
                'roleId': roleId,
                'username': username+'@bmcsoft.cn',
                'password': basePassword,
                'name': name,
                'email': email+'@bmcsoft.cn',
                'phone': phone,
                "number":number,
                "role_Id": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.addUser,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        //提示用户成功添加角色
                        message.success('成功添加用户')
                        setRoleId(0)
                        setUsername('')
                        setPassword('')
                        setName('')
                        setEmail('')
                        setPhone('')
                        setNumber('')
                        form.resetFields();
                        //关闭弹框
                        setAddUser(false)
                        //重新请求角色列表
                        queryAllUser()
                    } else {
                        //提示用户添加角色失败
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }

                    }
                }
            )
        }

    }

    //取消添加用户
    const addCancel = () => {
        message.success('取消添加用户')
        form.resetFields();
        setRoleId(0)
        setUsername('')
        setPassword('')
        setName('')
        setEmail('')
        setPhone('')
        setNumber('')
        setAddUser(false)
    }

    //弹出更新用户弹框
    const updateUserModel = (index) => {
        index = (page - 1) * 10 + index
        form.resetFields();
        setUserId(data[index]['id'])
        setRoleId(data[index]['roleId'])
        let tag = data[index]['username'].indexOf('@')
        let username = data[index]['username'].substring(0,tag)
        setUsername(username)
        setName(data[index]['name'])
        let emailTag = data[index]['email'].indexOf('@')
        let email = data[index]['username'].substring(0,emailTag)
        setEmail(email)
        setPhone(data[index]['phone'])
        setNumber(data[index]['number'])
        setRoleName(data[index]['role'])
        setUpdateUser(true)
    }

    //确认更新用户
    const updateOk = () => {
        if(username==''){
            message.error('用户名不能为空')
        }else if(roleId==''){
            message.error('角色必须选择')
        }else if(name==''){
            message.error('真实姓名不能为空')
        }else if(email==''){
            message.error('邮箱不能为空')
        }else if(phone==''){
            message.error('手机号不能为空')
        }else if(number==''){
            message.error('工号不能为空')
        }else{
             // Base64加密之后的密码
            let basePassword = Base64.encode(password)
            let dataProps = {
                'userId': userId,
                'roleId': roleId,
                'username': username+'@bmcsoft.cn',
                'password': basePassword,
                'number':number,
                'name': name,
                'email': email+'@bmcsoft.cn',
                'phone': phone,
                "role_Id": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.updateUser,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success('成功修改角色')
                        form.resetFields();
                        setRoleId(0)
                        setRoleName('')
                        setUsername('')
                        setPassword('')
                        setName('')
                        setEmail('')
                        setPhone('')
                        setNumber('')
                        setUpdateUser(false)
                        queryAllUser()
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
       

    }

    //取消更新用户
    const updateCancel = () => {
        message.success('取消更新用户')
        form.resetFields();
        setRoleId(0)
        setRoleName('')
        setUsername('')
        setPassword('')
        setName('')
        setEmail('')
        setPhone('')
        setNumber('')
        setUpdateUser(false)
    }

    // 删除用户操作
    const deleteUser = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        // //获取角色id
        // let userId = data[index]['id']
        // 获取用户邮箱
        let email = data[index]['email']
        confirm({
            title: '确定禁用这个用户',
            okText: '确定',
            cancelText: '取消',
            onOk() {

                //向后端传递的参数
                let dataProps = {   
                    "email": email,
                    "roleId": cookie.load('role'),
                    "shopId": cookie.load('shopList')
                }
                axios({
                    method: 'POST',
                    url: servicePath.disableNbUser,
                    data: dataProps,
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization':cookie.load('token')
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功禁用这个用户")
                            queryAllUser()
                        }else{
                            if(res.data['errorCode'] == '10001'){
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            }else{
                                message.error(res.data['errorMsg'])
                            }
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消禁用这个用户')
            }
        })
    }

    //恢复用户操作
    const recoverUser = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        //获取角色id
        // let userId = data[index]['id']
        // 获取用户邮箱
        let email = data[index]['email']
        confirm({
            title: '确定恢复这个用户',
            okText: '确定',
            cancelText: '取消',
            onOk() {

                //向后端传递的参数
                let dataProps = {
                    "email": email,
                    "role_Id": cookie.load('role'),
                    "shopId": cookie.load('shopList')
                }
                axios({
                    method: 'POST',
                    url: servicePath.recoveryNbUser,
                    data: dataProps,
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization':cookie.load('token')
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功恢复这个用户")
                            queryAllUser()
                        }else{
                            if(res.data['errorCode'] == '10001'){
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            }else{
                                message.error(res.data['errorMsg'])
                            }
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消恢复这个用户')
            }
        })
    }

    //新增用户
    const addUserOk = values =>{
        if (values.employee_id == undefined && values.mobile == undefined) {
            message.warn('薪人薪事ID/手机号其中一个必填')
        } else {
            values.shopId = cookie.load('shopList')
            values.roleId = cookie.load('role')
            NbAxios(values, "POST", servicePath.addNbUser).then(res => {
                if (res.success) {
                    message.success('新增用户成功')
                    setAddEmailUser(false)
                    form.resetFields()
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
      
    }

    //更新用户
    const updateUserOk = values=>{
        values.user_id = userId
        values.shopId = cookie.load('shopList')
        values.roleId = cookie.load('role')
        NbAxios(values, "POST", servicePath.updateUser).then(res => {
            if (res.success) {
                message.success('成功更新用户基本信息')
                queryAllUser()
                setUpdateUser(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    return (
        <>
            {/* 导航区域 */}
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.02}}>
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                    <Breadcrumb.Item>用户管理</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* 按钮区域 */}
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.06}}>
                <Row>
                    {/* <Col style={{marginRight:"20px"}}>
                        <Button type="primary" onClick={() => setAddUser(true)}>新增用户</Button>
                    </Col> */}
                    <Col>
                        <Button type="primary" onClick={() => setAddEmailUser(true)} style={{marginRight:30}}>新增NB20用户</Button>
                    </Col>
                    <Col>
                        {/* <Button  */}
                        <Popover 
                            placement="right" 
                            content={nameContent} 
                            trigger="click" 
                            visible={closeNameBubble} 
                            onVisibleChange={visible=>setCloseNameBubble(visible)}
                        >
                            <Button>根据名字查询用户{searchNameTag == 1 ? <span>: {searchName}</span> : <span></span> }</Button>
                        </Popover>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06}}>
                {/* <div style={{ marginTop: '20px', marginBottom: "10px" }}><Tag color="pink" style={{ height: "30px", lineHeight: "30px", fontSize: "20px" }}>{searchMessage}</Tag></div> */}
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={data}
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                />
            </div>
            {/* 对话框 添加用户 */}
            <Modal
                title="添加用户"
                visible={addUser}
                onOk={addOk}
                onCancel={addCancel}
                okText="确认添加"
                cancelText="取消添加"
            >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="用户名" name="username">
                        {/* 用户名 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入用户名'
                            placement="topLeft"
                        >
                            <Input
                                id="userName"
                                size="default"
                                placeholder="请输入用户名"
                                value={username}
                                addonAfter="@bmcsoft.cn"
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="密码" name="password">
                        {/* 密码 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入密码'
                            placement="topLeft"
                        >
                            <Input.Password
                                id="password"
                                size="default"
                                placeholder="请输入密码"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="真实名字" name="name">
                        {/* 真实名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入真实名字'
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="请输入真实名字"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="邮箱" name="email">
                        {/* 邮件 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入邮箱'
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="请输入邮箱"
                                addonAfter="@bmcsoft.cn"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="手机号" name="phone">
                        {/* 手机号 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入手机号'
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="请输入手机号"
                                value={phone}
                                onChange={(e) => {
                                    setPhone(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="工号" name="number">
                        <Tooltip
                            trigger={['focus']}
                            title='请输入工号'
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="请输入工号"
                                value={number}
                                onChange={(e) => {
                                    setNumber(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="角色" name="role">
                        {/* 选择角色 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请选择角色'
                            placement="topLeft"
                        >
                            <Select placeholder="请选择角色" style={{width: 310}} onChange={(value) => chooseRole(value)}>
                                {
                                    roles.map(function (item) {
                                        return (
                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 对话框 修改用户 */}
            <Modal
                title="修改用户"
                visible={updateUser}
                onCancel={updateCancel}
                footer={null}
            >
                <Form form={form} {...layout}  labelAlign='left' onFinish={updateUserOk}>
                    <div>
                        <Form.Item label="用户名" name="username">
                            <Text onClick={()=>console.log(form.getFieldValue('username'))}>{form.getFieldValue('username')}</Text>
                        </Form.Item>
                        <Form.Item label="真实名字" name="name">
                            <Text>{form.getFieldValue('name')}</Text>
                        </Form.Item>
                        <Form.Item label="邮箱" name="email">
                            <Text>{form.getFieldValue('email')}</Text>
                        </Form.Item>
                        <Form.Item label="手机号" name="phone">
                            <Text>{form.getFieldValue('phone')}</Text>
                        </Form.Item>
                        <Form.Item label="角色" name="role">
                            <Select value={form.getFieldValue('role')} style={{width: 310}}>
                                {
                                    roles.map(function (item) {
                                        return (
                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 400 }}>
                                确定
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>

            <Modal
                title="新增NB20用户"
                visible={addEmailUser}
                footer={null}
                centered={true}
                onCancel={() => {
                    setAddEmailUser(false)
                    form.resetFields()
                }}
            >
                <Form
                    form={form}
                    {...layout}
                    name="sys_user"
                    onFinish={addUserOk}
                    autoComplete="off"
                >
                    <Form.Item
                        label="薪人薪事ID"
                        name={'employee_id'}
                    >
                        <Input placeholder="请输入薪人薪事ID" />
                    </Form.Item>
                    <Form.Item
                        label="用户手机号"
                        name={'mobile'}
                    >
                        <Input placeholder="请输入用户手机号" />
                    </Form.Item>
                    {/* <Form.Item
                        label="工号"
                        name={'number'}
                    >
                        <Input placeholder="请输入工号" />
                    </Form.Item> */}
                    <Form.Item
                        label="用户邮箱"
                        name={'email'}
                        rules={[{ required: true, message: '请输入用户邮箱' }]}
                    >
                        <Input placeholder="请输入用户邮箱" />
                    </Form.Item>
                    <Form.Item
                        label="用户邮箱密码"
                        name={'password'}
                        rules={[{ required: true, message: '请输入用户邮箱密码' }]}
                    >
                        <Input placeholder="请输入用户邮箱密码" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: 200 }}>
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )

}

export default User