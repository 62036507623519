import React, { useState, useEffect, useCallback } from 'react'
import { Transfer, Modal, Table, Tag, Button, Input, Form, message} from 'antd'
import difference from 'lodash/difference'
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import '../../../static/css/index.css'


function Team(props) {

    // const project = pr

    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    }

    // 自定义表转移
    const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
        <Transfer {...restProps} showSelectAll={false}>
            {({
                direction,
                filteredItems,
                onItemSelectAll,
                onItemSelect,
                selectedKeys: listSelectedKeys,
                disabled: listDisabled,
            }) => {
                const columns = direction === 'left' ? leftColumns : rightColumns;

                const rowSelection = {
                    getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows
                            .filter(item => !item.disabled)
                            .map(({ key }) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, listSelectedKeys)
                            : difference(listSelectedKeys, treeSelectedKeys);
                        onItemSelectAll(diffKeys, selected);
                    },
                    onSelect({ key }, selected) {
                        onItemSelect(key, selected);
                    },
                    selectedRowKeys: listSelectedKeys,
                };

                return (
                    <Table
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        style={{ pointerEvents: listDisabled ? 'none' : null }}
                        onRow={({ key, disabled: itemDisabled }) => ({
                            onClick: () => {
                                if (itemDisabled || listDisabled) return;
                                onItemSelect(key, !listSelectedKeys.includes(key));
                            },
                        })}
                    />
                );
            }}
        </Transfer>
    );

    // 标志位
    const [ flag , setFlag ] = useState(0)

    // 项目id
    const [ projectId , setProjectId ] = useState('')

    // 数据
    const [data, setData] = useState([])

    // 查看详情
    const [openDetails, setOpenDetails] = useState(false)

    // 模拟团队人
    const [team, setTeam] = useState([])

    // 选中的团队
    const [targetKeys, setTargetKeys] = useState([])

    // 团队名字
    const [ name , setName ] = useState('')

    // 负责人
    const [principal , setPrincipal] = useState('')

    // 人数
    const [ number , setNumber ] = useState('')

    // 原先团队数量
    const [oldTeamNumber , setOldTeamNumber ] = useState('')

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 左边表格标题
    const leftTableColumns = [
        {
            title: '可选择团队',
            children: [
                {
                    dataIndex: 'name',
                    title: '团队名字',
                    width: '100px',
                },
                {
                    dataIndex: 'director',
                    title: '负责人',
                    width: '50px'
                },
                {
                    dataIndex: 'count',
                    title: '人数',
                    width: '50px',
                },
                {
                    dataIndex: 'key',
                    title: '查询详情',
                    width: '50px',
                    render: (text, record, index) => (
                        // supplierList[supplierList.map(item => item.organization).indexOf(value)]['id']
                        <Button onClick={() => {
                            form.resetFields()
                            let team = data[data.map(item=>item.key).indexOf(text)]
                            setName(team['name'])
                            setPrincipal(team['director'])
                            setNumber(team['count'])
                            setTeam(team['member'])
                            setOpenDetails(true)
                        }}>查询详情</Button>
                    ),
                },
            ]
        }
    ];

    // 右边表格标题
    const rightTableColumns = [
        {
            title: '已存在团队',
            children: [
                {
                    dataIndex: 'name',
                    title: '团队名字',
                    width: '100px',
                },
                {
                    dataIndex: 'director',
                    title: '负责人',
                    width: '50px'
                },
                {
                    dataIndex: 'count',
                    title: '人数',
                    width: '50px',
                },
                {
                    dataIndex: 'key',
                    title: '查询详情',
                    width: '50px',
                    render: (text, record, index) => (
                        <Button onClick={() => {
                            form.resetFields()
                            let team = data[data.map(item=>item.key).indexOf(text)]
                            setName(team['name'])
                            setPrincipal(team['director'])
                            setNumber(team['count'])
                            setTeam(team['member'])
                            setOpenDetails(true)
                        }}>查询详情</Button>
                    ),
                }
            ]
        },  
    ];

     

      useEffect(()=>{
          setProjectId(props.project)
          getExisTeamData()
          getTeamData()
      },[])
  
      // 获取已存在团队数据
      const getExisTeamData=()=>{
          let dataProps = {
              "roleId": cookie.load('role'),
              "shopId": cookie.load('shopList'),
              "project_id": props.project,
              "name": cookie.load('name'),
          }
          axios({
              method: 'POST',
              data: dataProps,
              url: servicePath.queryProjectExisTeamData,
              headers: {
                  'Content-Type': 'application/json',  //如果写成contentType会报错
                  'authorization': cookie.load('token')
              }
          }).then(
              res => {
                  if (res.data.success) {
                        setTargetKeys(res.data.data);
                        setOldTeamNumber(res.data.data.length)
                  } else {
                      if (res.data['errorCode'] == '10001') {
                          message.error(res.data['errorMsg'])
                          cookie.remove("role", { path: '/' });
                          cookie.remove("shop", { path: '/' })
                          cookie.remove("shopName", { path: '/' })
                          cookie.remove("name", { path: '/' })
                          cookie.remove("roleName", { path: '/' })
                          cookie.remove("email", { path: '/' })
                          cookie.remove("phone", { path: '/' })
                          cookie.remove("watermark", { path: '/' })
                          cookie.remove("flag", { path: '/' })
                          cookie.remove("token", { path: '/' })
                          props.history.push("/")
                      } else {
                          message.error(res.data['errorMsg'])
                      }
                  }
              }
          )
      }
  
      // 获取团队数据
      const getTeamData = ()=>{
          let dataProps = {
              "roleId": cookie.load('role'),
              "shopId": cookie.load('shopList'),
          }
          axios({
              method: 'POST',
              data: dataProps,
              url: servicePath.queryProjectTeamData,
              headers: {
                  'Content-Type': 'application/json',  //如果写成contentType会报错
                  'authorization': cookie.load('token')
              }
          }).then(
              res => {
                  if (res.data.success) {
                        setData(res.data.data);
                  } else {
                      if (res.data['errorCode'] == '10001') {
                          message.error(res.data['errorMsg'])
                          cookie.remove("role", { path: '/' });
                          cookie.remove("shop", { path: '/' })
                          cookie.remove("shopName", { path: '/' })
                          cookie.remove("name", { path: '/' })
                          cookie.remove("roleName", { path: '/' })
                          cookie.remove("email", { path: '/' })
                          cookie.remove("phone", { path: '/' })
                          cookie.remove("watermark", { path: '/' })
                          cookie.remove("flag", { path: '/' })
                          cookie.remove("token", { path: '/' })
                          props.history.push("/")
                      } else {
                          message.error(res.data['errorMsg'])
                      }
                  }
              }
          )
      }
  

    const onChange = (nextTargetKeys) => {
        setFlag(1)
        setTargetKeys(nextTargetKeys)
    }

    const detailCancel = () => {
        form.resetFields()
        setOpenDetails(false)
    }
    // 保存操作
    const save =()=>{
        let dataProps = {
            "old_team_number": oldTeamNumber,
            "new_team_number": targetKeys.length,
            "team_ids": targetKeys,
            "project_id": projectId,
            "principal_uuid": cookie.load('id'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.updateProjectTeamData,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                      message.success('数据保存成功')
                      getExisTeamData()
                      setTargetKeys([])
                      setFlag(0)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }
    return (
        <div style={{ paddingBottom: size.height*0.1 }}>
            <div style={{marginTop:size.height*0.03, marginBottom: size.height*0.03}}>
                <Button type="primary" onClick={() => save()} disabled={flag == 0 ? true : false}>保存</Button>
            </div>
            <TableTransfer
                locale={{itemUnit: '个团队', itemsUnit: '个团队', searchPlaceholder: '请输入搜索内容'}}
                dataSource={data}
                targetKeys={targetKeys}
                showSearch={true}
                onChange={onChange}
                filterOption={(inputValue, item) =>
                    item.name.indexOf(inputValue) !== -1 || item.director.indexOf(inputValue) !== -1
                }
                leftColumns={leftTableColumns}
                rightColumns={rightTableColumns}
            />


            <Modal
                title="团队详情"
                centered={true}
                visible={openDetails}
                onCancel={detailCancel}
                footer={null}
                zIndex="100"
            >
                <Form
                    form={form}
                    {...layout}
                    labelAlign='left'
                >
                    <Form.Item
                        name="name"
                        label="团队名称"
                    >
                        <Input
                            defaultValue={name}
                            disabled
                         />
                    </Form.Item>
                    <Form.Item
                        name="principal"
                        label="负责人"
                    >
                        <Input
                            defaultValue={principal}
                            disabled
                         />
                    </Form.Item>
                    <Form.Item
                        name="number"
                        label="人数"
                    >
                        <Input
                            defaultValue={number}
                            disabled
                         />
                    </Form.Item>
                    <Form.Item
                        name="team"
                        label="团队成员"
                    >
                        {
                            team.map(item=>{
                                return <span >{item} </span>
                            })
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )

}

export default Team