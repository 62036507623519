import React, { useEffect, useState, useCallback } from 'react'
import { Carousel, Statistic, Row, Divider, Col } from 'antd'
import { MobileOutlined, TeamOutlined, PayCircleFilled, createFromIconfontCN } from '@ant-design/icons';
import axios from 'axios'
import servicePath from '../../config/apiUrl'
import cookie from 'react-cookies'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import '../../static/css/president/president.css'

function Overview() {
    var colors = ['#5793f3', '#d14a61', '#675bba', '#00a8e1', '#e30039 '];

    // 创建一个icon对象
    const IconFont = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_1315010_bn86xz15qdh.js',
    });
    // 轮播图的数据
    const [data, setData] = useState([])
    // 轮播图的店铺数据
    const [shopList] = useState(['iv_tmall', 'z11_tmall', 'xianghong_tmall', 'an_tmall', 'ivorycoat_tmall', 'xh_outlets', 'freiol_tmall', 'zhuoya_tmall', 'cr_tmall', 'qda_tmall',])
    // 店铺
    const [shop, setShop] = useState('')
    // 支付金额
    const [pyAmount, setPyAmount] = useState([])
    // 支付买家
    const [buyers, setBuyers] = useState([])
    // 支付子订单数
    const [pySubOrders, setPaySubOrder] = useState([])
    // 访客数
    const [visitors, setVisitors] = useState([])
    // 浏览量
    const [pageviews, setPageViews] = useState([])
    // 前一天支付金额
    const [prePyAmount, setPrePyAmount] = useState([])
    // 前一天支付买家
    const [preBuyers, setPreBuyers] = useState([])
    // 前一天支付子订单数
    const [prePySubOrders, setPrePaySubOrder] = useState([])
    // 前一天访客数
    const [preVisitors, setPreVisitors] = useState([])
    // 前一天浏览量
    const [prePageviews, setPrePageViews] = useState([])
    // 近七天支付金额
    const [weekPyAmount, setWeekPyAmount] = useState([])
    // 近七天支付买家
    const [weekBuyers, setWeekBuyers] = useState([])
    // 近七天支付子订单数
    const [weekPySubOrders, setWeekPaySubOrder] = useState([])
    // 近七天访客数
    const [weekVisitors, setWeekVisitors] = useState([])
    // 近七天浏览量
    const [weekPageviews, setWeekPageViews] = useState([])
    // 近三十天天支付金额
    const [monthPyAmount, setMonthPyAmount] = useState([])
    // 近三十天天支付买家
    const [monthBuyers, setMonthBuyers] = useState([])
    // 近三十天天支付子订单数
    const [monthPySubOrders, setMonthPaySubOrder] = useState([])
    // 近三十天天访客数
    const [monthVisitors, setMonthVisitors] = useState([])
    // 近三十天天浏览量
    const [monthPageviews, setMonthPageViews] = useState([])
    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 第一组数据初始化
    useEffect(() => {
        setShop(shopList[0])
    }, [])

    useEffect(() => {
        if (shop != '') {
            getOverView(shop)
        }
    }, [shop])


    const getOverView = (shop) => {
        let data = {}
        data.shop = shop
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        axios({
            data: data,
            method: 'POST',
            url: servicePath.getOverViewData,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setData(res.data.data.table['0']);
                let data = res.data.data.chart['0']
                setPyAmount(data.py_amount)
                setBuyers(data.buyers)
                setPaySubOrder(data.py_sub_orders)
                setVisitors(data.visitors)
                setPageViews(data.pageviews)
                setPrePyAmount(data.pre_py_amount)
                setPreBuyers(data.pre_buyers)
                setPrePaySubOrder(data.pre_py_sub_orders)
                setPreVisitors(data.pre_visitors)
                setPrePageViews(data.pre_pageviews)
                setWeekPyAmount(data.week_py_amount)
                setWeekBuyers(data.week_buyers)
                setWeekPaySubOrder(data.week_py_sub_orders)
                setWeekVisitors(data.week_visitors)
                setWeekPageViews(data.week_pageviews)
                setMonthPyAmount(data.month_py_amount)
                setMonthBuyers(data.month_buyers)
                setMonthPaySubOrder(data.month_py_sub_orders)
                setMonthVisitors(data.month_visitors)
                setMonthPageViews(data.month_pageviews)
            }
        )
    }

    // 构建echarts
    let overCharts = {
        color: colors,
        title: {
            text: `${data.shop == undefined ? '' : data.shop}日度报表`
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '100px',
            right: '15%',
            left: '6%',
        },
        dataZoom: [{
            type: "inside"         //详细配置可见echarts官网
        }],
        // toolbox: {
        //     feature: {
        //         dataView: { show: true, readOnly: false },
        //         restore: { show: true },
        //         saveAsImage: { show: true }
        //     }
        // },
        legend: {
            data: [
                {
                    name: "支付金额",
                    textStyle: {
                        align: 'left',
                        padding: [0, 36, 0, 0]
                    }
                }, {
                    name: "支付买家",
                    textStyle: {
                        align: 'left',
                        padding: [0, 36, 0, 0]
                    }
                }, {
                    name: "支付子订单数",
                    textStyle: {
                        align: 'left',
                        padding: [0, 36, 0, 0]
                    }
                }, {
                    name: "访客数",
                    textStyle: {
                        align: 'left',
                        padding: [0, 36, 0, 0]
                    }
                }, '浏览量', '', '前一天支付金额', '前一天支付买家', '前一天支付子订单数', '前一天访客数', '前一天浏览量', '',
                '近七天支付金额', '近七天支付买家', '近七天支付子订单数', '近七天访客数', '近七天浏览量', '',
                '近一月支付金额', '近一月支付买家', '近一月支付子订单数', '近一月访客数', '近一月浏览量'],
            selected: {
                '支付买家': false,
                '支付子订单数': false,
                '访客数': false,
                '前一天支付买家': false,
                '前一天支付子订单数': false,
                '前一天访客数': false,
                '近七天支付买家': false,
                '近七天支付子订单数': false,
                '近七天访客数': false,
                '近一月支付买家': false,
                '近一月支付子订单数': false,
                '近一月访客数': false,
            }
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '支付金额/元',
                position: 'right',
                offset: 80,
                axisLabel: {
                    formatter: function (data) {
                        return data;
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: colors[2]
                    },
                    onZero: true
                },
            },
            {
                type: 'value',
                name: '支付买家数/支付子订单',
                position: 'left',
                offset: 0,
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: colors[1]
                    },
                    onZero: true
                },
            },
            {
                type: 'value',
                name: '访客数/浏览量',
                position: 'right',
                offset: 0,
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: colors[3]
                    },
                    onZero: true
                },
            },
        ],
        series: [
            {
                name: '支付金额',
                type: 'line',
                yAxisIndex: 0,
                data: pyAmount,
                itemStyle: {
                    normal: {
                        lineStyle: {   //系列级个性化折线样式
                            type: 'solid'
                        }
                    }
                },
            },
            {
                name: '支付买家',
                type: 'line',
                yAxisIndex: 1,
                data: buyers,
                itemStyle: {
                    normal: {
                        lineStyle: {   //系列级个性化折线样式
                            type: 'solid'
                        }
                    }
                },
            },
            {
                name: '支付子订单数',
                type: 'line',
                yAxisIndex: 1,
                data: pySubOrders,
                itemStyle: {
                    normal: {
                        lineStyle: {   //系列级个性化折线样式
                            type: 'solid'
                        }
                    }
                },
            },
            {
                name: '访客数',
                type: 'line',
                yAxisIndex: 2,
                data: visitors,
                itemStyle: {
                    normal: {
                        lineStyle: {   //系列级个性化折线样式
                            type: 'solid'   //实线
                        }
                    }
                },
            },
            {
                name: '浏览量',
                type: 'line',
                yAxisIndex: 2,
                data: pageviews,
                itemStyle: {
                    normal: {
                        lineStyle: {   //系列级个性化折线样式
                            type: 'solid'
                        }
                    }
                },
            },
            {
                name: '前一天支付金额',
                type: 'line',
                yAxisIndex: 0,
                data: prePyAmount,
                itemStyle: {
                    normal: {
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '前一天支付买家',
                type: 'line',
                yAxisIndex: 1,
                data: preBuyers,
                itemStyle: {
                    normal: {
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '前一天支付子订单数',
                type: 'line',
                yAxisIndex: 1,
                data: prePySubOrders,
                itemStyle: {
                    normal: {
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '前一天访客数',
                type: 'line',
                yAxisIndex: 2,
                data: preVisitors,
                itemStyle: {
                    normal: {
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'   //实线
                        }
                    }
                },
            },
            {
                name: '前一天浏览量',
                type: 'line',
                yAxisIndex: 2,
                data: prePageviews,
                itemStyle: {
                    normal: {
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '近七天支付金额',
                type: 'line',
                yAxisIndex: 0,
                data: weekPyAmount,
                itemStyle: {
                    normal: {
                        color: '#DAA520',
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '近七天支付买家',
                type: 'line',
                yAxisIndex: 1,
                data: weekBuyers,
                itemStyle: {
                    normal: {
                        color: '#FFA500',
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '近七天支付子订单数',
                type: 'line',
                yAxisIndex: 1,
                data: weekPySubOrders,
                itemStyle: {
                    normal: {
                        color: '#FF8C00',
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '近七天访客数',
                type: 'line',
                yAxisIndex: 2,
                data: weekVisitors,
                itemStyle: {
                    normal: {
                        color: '#FF4500',
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'   //实线
                        }
                    }
                },
            },
            {
                name: '近七天浏览量',
                type: 'line',
                yAxisIndex: 2,
                data: weekPageviews,
                itemStyle: {
                    normal: {
                        color: '#FF6347',
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '近一月支付金额',
                type: 'line',
                yAxisIndex: 0,
                data: monthPyAmount,
                itemStyle: {
                    normal: {
                        color: '#0000FF',
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '近一月支付买家',
                type: 'line',
                yAxisIndex: 1,
                data: monthBuyers,
                itemStyle: {
                    normal: {
                        color: '#0000CD',
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '近一月支付子订单数',
                type: 'line',
                yAxisIndex: 1,
                data: monthPySubOrders,
                itemStyle: {
                    normal: {
                        color: '#191970',
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
            {
                name: '近一月访客数',
                type: 'line',
                yAxisIndex: 2,
                data: monthVisitors,
                itemStyle: {
                    normal: {
                        color: '#00008B',
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'   //实线
                        }
                    }
                },
            },
            {
                name: '近一月浏览量',
                type: 'line',
                yAxisIndex: 2,
                data: monthPageviews,
                itemStyle: {
                    normal: {
                        color: '#000080',
                        lineStyle: {   //系列级个性化折线样式
                            type: 'dashed'
                        }
                    }
                },
            },
        ]
    };

    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('report'));
        // 绘制图表
        reportChart.setOption(overCharts);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [overCharts])


    // 第一个轮播图的数据
    const overItem = (
        <div style={{ width: size.width, border: '5px solid red', height: '200px' }}>
            <Row>
                <Col style={{ fontWeight: 'bold', fontSize: '24px', marginTop: size.height * 0.03, marginLeft: size.width * 0.30, color: 'white' }}>
                    {data.shop}
                </Col>
            </Row>
            <Row>
                <Col style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.03 }}>
                    <IconFont type="iconzhifu" style={{ fontSize: '60px', height: '80px', lineHeight: '80px' }} />
                </Col>
                <Col style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.01, width: '150px' }}>
                    <Col style={{ fontWeight: 'bold', fontSize: '14px', color: 'white' }}>支付金额(元)</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '3px', color: 'white' }}>
                        <Statistic valueStyle={{ color: 'white' }} value={data.py_amount} />
                    </Col>
                    <Col style={{ fontSize: '15px', marginTop: '3px' }}>
                        <Row>
                            <Col style={{ marginRight: '5px' }}>
                                <MobileOutlined style={{ fontSize: '20px', color: 'white' }} />
                            </Col>
                            <div style={{ fontSize: '15px', color: 'white' }}>
                                {data.mobile_py_amount}%
                            </div>
                        </Row>
                    </Col>
                </Col>
                <Col style={{ marginLeft: size.width * 0.12, marginTop: size.height * 0.01 }}>
                    <IconFont type="iconfangke" style={{ fontSize: '60px', height: '80px', lineHeight: '80px' }} />
                </Col>
                <Col style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.01, width: '120px' }}>
                    <Col style={{ fontWeight: 'bold', fontSize: '14px', color: 'white' }}>访客数</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '3px', color: 'white' }}>
                        <Statistic valueStyle={{ color: 'white' }} value={data.visitors} />
                    </Col>
                    <Col style={{ fontSize: '15px', marginTop: '3px' }}>
                        <Row>
                            <Col style={{ marginRight: '5px' }}>
                                <MobileOutlined style={{ fontSize: '20px', color: 'white' }} />
                            </Col>
                            <div style={{ fontSize: '15px', color: 'white' }}>
                                {data.mobile_visitors}%
                            </div>
                        </Row>
                    </Col>
                </Col>
                <Col style={{ marginLeft: size.width * 0.12, marginTop: size.height * 0.01 }}>
                    <IconFont type="iconzhifumaijiashu" style={{ fontSize: '60px', height: '80px', lineHeight: '80px' }} />
                </Col>
                <Col style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.01, width: '180px' }}>
                    <Col style={{ fontWeight: 'bold', fontSize: '14px', color: 'white' }}>支付买家数</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '3px' }}>
                        <Statistic valueStyle={{ color: 'white' }} value={data.buyers} />
                    </Col>
                    <Col style={{ fontSize: '15px', marginTop: '3px' }}>
                        <Row>
                            <Col style={{ marginRight: '5px' }}>
                                <MobileOutlined style={{ fontSize: '20px', color: 'white' }} />
                            </Col>
                            <div style={{ fontSize: '15px', color: 'white' }}>
                                {data.mobile_buyers}%
                            </div>
                        </Row>
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.03 }}>
                    <IconFont type="iconzhifudingdan" style={{ fontSize: '60px', height: '80px', lineHeight: '80px' }} />
                </Col>
                <Col style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.01, width: '150px' }}>
                    <Col style={{ fontWeight: 'bold', fontSize: '14px', color: 'white' }}>支付子订单</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '3px' }}>
                        <Statistic valueStyle={{ color: 'white' }} value={data.py_sub_orders} />
                    </Col>
                    <Col style={{ fontSize: '15px', marginTop: '3px' }}>
                        <Row>
                            <Col style={{ marginRight: '5px' }}>
                                <MobileOutlined style={{ fontSize: '20px', color: 'white' }} />
                            </Col>
                            <div style={{ fontSize: '15px', color: 'white' }}>
                                {data.mobile_py_sub_orders}%
                            </div>
                        </Row>
                    </Col>
                </Col>
                <Col style={{ marginLeft: size.width * 0.12, marginTop: size.height * 0.01 }}>
                    <IconFont type="iconliulanliang" style={{ fontSize: '60px', height: '80px', lineHeight: '80px' }} />
                </Col>
                <Col style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.01, width: '120px' }}>
                    <Col style={{ fontWeight: 'bold', fontSize: '14px', color: 'white' }}>浏览量</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '3px' }}>
                        <Statistic valueStyle={{ color: 'white' }} value={data.pageviews} />
                    </Col>
                    <Col style={{ fontSize: '15px', marginTop: '3px' }}>
                        <Row>
                            <Col style={{ marginRight: '5px' }}>
                                <MobileOutlined style={{ fontSize: '20px', color: 'white' }} />
                            </Col>
                            <div style={{ fontSize: '15px', color: 'white' }}>
                                {data.mobile_pageviews}%
                            </div>
                        </Row>
                    </Col>
                </Col>
                <Col style={{ marginLeft: size.width * 0.12, marginTop: size.height * 0.01 }}>
                    <IconFont type="iconpaihang" style={{ fontSize: '60px', height: '80px', lineHeight: '80px' }} />
                </Col>
                <Col style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.01, width: '180px' }}>
                    <Col style={{ fontWeight: 'bold', fontSize: '14px', color: 'white' }}>近30天支付金额排行</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '3px' }}>
                        <Statistic valueStyle={{ color: 'white' }} value={data.shop_rank} suffix="名" />
                    </Col>
                    <Col style={{ fontSize: '15px', marginTop: '3px', color: 'white' }}>
                        <Row>
                            <div style={{ fontSize: '15px', color: 'white' }}>
                                第{data.shop_level}层级
                            </div>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </div>
    )


    const overItem1 = (
        <div className='over-view'>
            <div className='over-view-carousel-title'>
                {data.shop}
            </div>
            <div className='over-view-carousel'>
                <div className='over-view-carousel-unit'>
                    <div className='left-area'>
                        <IconFont className='left-icon' type="iconzhifu" />
                    </div>
                    <div className='right-area'>
                        <div className='filed-name'>支付金额(元)</div>
                        <Statistic className='filed-name-value' valueStyle={{ color: 'white' }} value={data.py_amount} />
                        <div className='mobile-icon-wapper'>
                            <MobileOutlined className='mobile-icon' />
                            <div className='filed-icon-value'>
                                {data.mobile_py_amount}%
                    </div>
                        </div>
                    </div>
                </div>
                <div className='over-view-carousel-unit'>
                    <div className='left-area'>
                        <IconFont className='left-icon' type="iconfangke" />
                    </div>
                    <div className='right-area'>
                        <div className='filed-name'>访客数</div>
                        <Statistic className='filed-name-value' valueStyle={{ color: 'white' }} value={data.visitors} />
                        <div className='mobile-icon-wapper'>
                            <MobileOutlined className='mobile-icon' />
                            <div className='filed-icon-value'>
                                {data.mobile_visitors}%
                    </div>
                        </div>
                    </div>
                </div>
                <div className='over-view-carousel-unit'>
                    <div className='left-area'>
                        <IconFont className='left-icon' type="iconzhifumaijiashu" />
                    </div>
                    <div className='right-area'>
                        <div className='filed-name'>支付买家数</div>
                        <Statistic className='filed-name-value' valueStyle={{ color: 'white' }} value={data.buyers} />
                        <div className='mobile-icon-wapper'>
                            <MobileOutlined className='mobile-icon' />
                            <div className='filed-icon-value'>
                                {data.mobile_buyers}%
                    </div>
                        </div>
                    </div>
                </div>
                <div className='over-view-carousel-unit'>
                    <div className='left-area'>
                        <IconFont className='left-icon' type="iconzhifudingdan" />
                    </div>
                    <div className='right-area'>
                        <div className='filed-name'>支付子订单</div>
                        <Statistic className='filed-name-value' valueStyle={{ color: 'white' }} value={data.py_sub_orders} />
                        <div className='mobile-icon-wapper'>
                            <MobileOutlined className='mobile-icon' />
                            <div className='filed-icon-value'>
                                {data.mobile_py_sub_orders}%
                    </div>
                        </div>
                    </div>
                </div>
                <div className='over-view-carousel-unit'>
                    <div className='left-area'>
                        <IconFont className='left-icon' type="iconliulanliang" />
                    </div>
                    <div className='right-area'>
                        <div className='filed-name'>浏览量</div>
                        <Statistic className='filed-name-value' valueStyle={{ color: 'white' }} value={data.pageviews} />
                        <div className='mobile-icon-wapper'>
                            <MobileOutlined className='mobile-icon' />
                            <div className='filed-icon-value'>
                                {data.mobile_pageviews}%
                        </div>
                        </div>
                    </div>
                </div>
                <div className='over-view-carousel-unit'>
                    <div className='left-area'>
                        <IconFont className='left-icon' type="iconpaihang" />
                    </div>
                    <div className='right-area'>
                        <div className='filed-name'>近30天支付金额排行</div>
                        <Statistic className='filed-name-value rank-name' valueStyle={{ color: 'white' }} value={data.shop_rank} />
                        <div className='mobile-icon-wapper'>
                            <MobileOutlined className='mobile-icon ' />
                            <div className='filed-icon-value'>
                                第{data.shop_level}层级
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


    return (
        <>
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>点击上方数据区域，可以暂停切换店铺</div>
            <div className='report-father-index' >
                <div style={{ width:'75vw', border: '1px solid #ccc', marginLeft: size.width * 0.1, backgroundColor: '#87CEFA' }}>
                    {/*
                参数解释
                    autoplay 允许自动播放
                    autoplaySpeed  自动播放速度 int 毫秒为单位 
                    例如: autoplaySpeed={5000}  等5秒 切换一次 
                    dots false 下面的滑块消失
                    beforeChange    滑动前调用
                    beforeChange函数参数:    oldIndex, newIndex  (老的下标，新的下标)
                    // beforeChange={(oldIndex, newIndex)=>{console.log(newIndex);}}    
                    speed 动画速度即使 轮播图切换的速度 int类型 这里可以设置大一点,这样子就可以把请求到的数据完整的渲染在页面上面
              */}
                    <Carousel autoplay autoplaySpeed={30000} dotPosition="top"
                        speed={10000}
                        beforeChange={(oldIndex, newIndex) => { setShop(shopList[newIndex]) }}

                    >
                        {overItem1}
                        {overItem1}
                        {overItem1}
                        {overItem1}
                        {overItem1}
                        {overItem1}
                        {overItem1}
                        {overItem1}
                        {overItem1}
                        {overItem1}
                    </Carousel>
                </div>
                <div id="report" style={{ width: size.width * 0.8, height: '65vh', marginLeft: size.width * 0.1 }}></div>
            </div>
        </>
    )

}

export default Overview