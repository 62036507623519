import React, { Component, useState } from 'react';
import { Typography, Input, Button, message } from 'antd'
import Logo from './image/logo.png'
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl';


function Index(){

    const [ phone , setPhone ] = useState('')


    return (
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:'center'}}>
            <div style={{marginTop:"100px"}}>
                <img src={Logo} style={{width:"100vw", height:"500px", backgroundSize:"100% 100%"}} />
            </div>
            <div style={{marginTop:"50px"}}>
                <Input placeholder='请输入手机号' style={{width:"150px"}} value={phone} onChange={(e)=>setPhone(e.target.value.replace(/[^\d^\.]+/g,''))} />
            </div>
            <div style={{marginTop:"50px"}}>
                <Button style={{width:"150px"}} type="primary" onClick={()=>{
                   if(phone == ''){
                        message.warn('请输入手机号')
                   }else{
                        let dataProps = {
                            phone: phone
                        }
                        NbAxios(dataProps, "POST", servicePath.anniversaryLuckDrawSignIn).then(res => {
                            if (res.success) {
                                message.success('签到成功')
                                setPhone('')
                            } else {
                                message.warn(res.errorMsg)
                            }
                        }).catch(err => {
                            message.warn('签到接口出错,请联系IT')
                        })
                   }
                }}>签到</Button>
            </div>
        </div>
    )

}


export default Index