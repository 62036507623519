import React, { useEffect, useState } from 'react';
import { DatePicker, Typography, message, Divider, Table, Select, Input, Space, Button,Checkbox, Statistic } from 'antd'
import { createFromIconfontCN, DownOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment'
// 引入高亮组件
import Highlighter from "react-highlight-words";
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'
import axios from 'axios';
import downExcel from '../../config/utils/downloadFile';
import locale from 'antd/es/date-picker/locale/zh_CN';
import AcceptBuried from '../../config/utils/buried';
const { Title } = Typography;
const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = 'YYYY-MM-DD';

function Index(props) {
    function disabledDate(current) {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'qda':"2715f3fa-0dae-4dc7-bcfc-0dd912314e40"
    }
    const MyIcon = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_3122083_9f3ozkh4lem.js', // 在 iconfont.cn 上生成
    });
    //定义筛选功能
    const [searchText, setSearchText] = useState('')

    const [searchedColumn, setSearchedColumn] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };
    // 范围选择
    const [scope, setScope] = useState('area')
    // 总的排序
    const [ totalSort ] = useState({
        'area':[
            {
                name:'销售金额升序',
                key:'amount_of_should_pay,True' 
            },
            {
                name:'销售金额降序',
                key:'amount_of_should_pay,False' 
            },
            {
                name:'实销金额升序',
                key:'amount_of_real_pay,True' 
            },
            {
                name:'实销金额降序',
                key:'amount_of_real_pay,False' 
            },
            {
                name:'退款金额升序',
                key:'refund_money,True' 
            },
            {
                name:'退款金额降序',
                key:'refund_money,False' 
            },
            {
                name:'销售件数升序',
                key:'count_of_goods,True' 
            },
            {
                name:'销售件数降序',
                key:'count_of_goods,False' 
            },
            {
                name:'平均件单价升序',
                key:'avg_price_of_goods,True' 
            },
            {
                name:'平均件单价降序',
                key:'avg_price_of_goods,False' 
            },
            {
                name:'平均客单价升序',
                key:'avg_price_of_order,True' 
            },
            {
                name:'平均客单价降序',
                key:'avg_price_of_order,False' 
            },
            {
                name:'订单数升序',
                key:'count_of_order,True' 
            },
            {
                name:'订单数降序',
                key:'count_of_order,False' 
            },
            {
                name:'人数升序',
                key:'number_of_customer,True' 
            },
            {
                name:'人数降序',
                key:'number_of_customer,False' 
            },
        ],
        'city':[
            {
                name:'销售金额升序',
                key:'amount_of_should_pay,True' 
            },
            {
                name:'销售金额降序',
                key:'amount_of_should_pay,False' 
            },
            {
                name:'实销金额升序',
                key:'amount_of_real_pay,True' 
            },
            {
                name:'实销金额降序',
                key:'amount_of_real_pay,False' 
            },
            {
                name:'退款金额升序',
                key:'refund_money,True' 
            },
            {
                name:'退款金额降序',
                key:'refund_money,False' 
            },
            {
                name:'销售件数升序',
                key:'count_of_goods,True' 
            },
            {
                name:'销售件数降序',
                key:'count_of_goods,False' 
            },
            {
                name:'平均件单价升序',
                key:'avg_price_of_goods,True' 
            },
            {
                name:'平均件单价降序',
                key:'avg_price_of_goods,False' 
            },
            {
                name:'平均客单价升序',
                key:'avg_price_of_order,True' 
            },
            {
                name:'平均客单价降序',
                key:'avg_price_of_order,False' 
            },
            {
                name:'订单数升序',
                key:'count_of_order,True' 
            },
            {
                name:'订单数降序',
                key:'count_of_order,False' 
            },
            {
                name:'人数升序',
                key:'number_of_customer,True' 
            },
            {
                name:'人数降序',
                key:'number_of_customer,False' 
            },
        ],
        'commodit':[
            {
                name:'销售金额升序',
                key:'amount_of_should_pay,True' 
            },
            {
                name:'销售金额降序',
                key:'amount_of_should_pay,False' 
            },
            {
                name:'实销金额升序',
                key:'amount_of_real_pay,True' 
            },
            {
                name:'实销金额降序',
                key:'amount_of_real_pay,False' 
            },
            {
                name:'退款金额升序',
                key:'refund_money,True' 
            },
            {
                name:'退款金额降序',
                key:'refund_money,False' 
            },
            {
                name:'销售件数升序',
                key:'count_of_goods,True' 
            },
            {
                name:'销售件数降序',
                key:'count_of_goods,False' 
            },
            {
                name:'新客数升序',
                key:'new,True' 
            },
            {
                name:'新客数降序',
                key:'new,False' 
            },
            {
                name:'老客数升序',
                key:'old,True' 
            },
            {
                name:'老客数降序',
                key:'old,False' 
            },
            {
                name:'总人数升序',
                key:'number_of_customer,True' 
            },
            {
                name:'总人数降序',
                key:'number_of_customer,False' 
            },
            
        ],
        'download':[
            {
                name:'',
                key:''
            }
        ]
    })
    // 排序
    const [ sort, setSort ] = useState('')
    // 是否去掉购物金、券、邮费
    const [ isTicket, setIsTicket] = useState(1)
    // 查询开始时间
    const [ startTime , setStartTime] = useState('')
    // 查询解释时间
    const [ endTime , setEndTime] = useState('')
    // 区域基础表头
    const [baseAreaColumns] = useState([
        {
            title: '销售金额',
            dataIndex: 'amount_of_should_pay',
            key: 'amount_of_should_pay',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '实销金额',
            dataIndex: 'amount_of_real_pay',
            key: 'amount_of_real_pay',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '退款金额',
            dataIndex: 'refund_money',
            key: 'refund_money',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '销售件数',
            dataIndex: 'count_of_goods',
            key: 'count_of_goods',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '平均件单价',
            dataIndex: 'avg_price_of_goods',
            key: 'avg_price_of_goods',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '平均客单价',
            dataIndex: 'avg_price_of_order',
            key: 'avg_price_of_order',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '订单数',
            dataIndex: 'count_of_order',
            key: 'count_of_order',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '人数',
            dataIndex: 'number_of_customer',
            key: 'number_of_customer',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
    ])
    // 省区域表头
    const [provinceAreaColumns] = useState([
        {
            title: '省',
            dataIndex: 'province',
            key: 'province',
            align: 'center',
            ...getColumnSearchProps('province'),
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseAreaColumns.map(item => {
            return item
        })

    ])
    // 市区域表头
    const [cityAreaColumns] = useState([
        {
            title: '市',
            dataIndex: 'city',
            key: 'city',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseAreaColumns.map(item => {
            return item
        })
    ])
    // 商品SPU表头
    const [commoditySPUColumns] = useState([
        {
            title: '商品SPU',
            dataIndex: 'spu',
            key: 'spu',
            align: 'center',
            ...getColumnSearchProps('spu'),
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '销售金额',
            dataIndex: 'amount_of_should_pay',
            key: 'amount_of_should_pay',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '实销金额',
            dataIndex: 'amount_of_real_pay',
            key: 'amount_of_real_pay',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '退款金额',
            dataIndex: 'refund_money',
            key: 'refund_money',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '销售件数',
            dataIndex: 'count_of_goods',
            key: 'count_of_goods',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '件单价',
            dataIndex: 'avg_price_of_goods',
            key: 'avg_price_of_goods',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '客单价',
            dataIndex: 'avg_price_of_order',
            key: 'avg_price_of_order',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '价格带',
            dataIndex: 'price_band',
            key: 'price_band',
            align: 'center',
            ...getColumnSearchProps('price_band'),
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '新客数',
            dataIndex: 'new',
            key: 'new',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '老客数',
            dataIndex: 'old',
            key: 'old',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '总人数',
            dataIndex: 'number_of_customer',
            key: 'number_of_customer',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
    ])
    // 商品SPU页数
    const [commoditySPUPage, setCommoditySPUPage] = useState(1)
    // 商品SPU总数
    const [commoditySPUTotal, setCommoditySPUTotal] = useState(100)
    // 商品SPU数据
    const [commoditySPUDate, setCommoditySPUDate] = useState([])
    const [commoditySPULoading, setCommoditySPUDateLoading] = useState(false)
    // 商品品类表头
    const [commodityCategoryColumns] = useState([
        {
            title: '品类',
            dataIndex: 'category2',
            key: 'category2',
            align: 'center',
            ...getColumnSearchProps('品类'),
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '销售金额',
            dataIndex: 'amount_of_should_pay',
            key: 'amount_of_should_pay',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '实销金额',
            dataIndex: 'amount_of_real_pay',
            key: 'amount_of_real_pay',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '退款金额',
            dataIndex: 'refund_money',
            key: 'refund_money',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '销售件数',
            dataIndex: 'count_of_goods',
            key: 'count_of_goods',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '新客数',
            dataIndex: 'new',
            key: 'new',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '老客数',
            dataIndex: 'old',
            key: 'old',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
        {
            title: '总人数',
            dataIndex: 'number_of_customer',
            key: 'number_of_customer',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text.toLocaleString()}</div>
            )
        },
    ])
    // 商品品类数据
    const [ commodityCategoryData , setCommodityCategoryData ] = useState([])
    const [ commodityCategoryLoading , setCommodityCategoryLoading ] = useState(false)
    // 区域key
    const [areaRowKey, setAreaRowKey] = useState([])
    // 新客区域key
    const [newAreaRowKey, setNewAreaRowKey] = useState([])
    // 老客区域key
    const [oldAreaRowKey, setOldAreaRowKey] = useState([])
    // 区域数据
    const [areaData, setAreaData] = useState([])
    // 区域人数
    const [areaDataNum, setAreaDataNum] = useState('')
    // 区域数据loading
    const [areaDataLoading, setAreaDataLoading] = useState(false)
    // 总的区域市级数据
    const [allAreaCityData, setAllAreaCityData] = useState({})
    // 区域市级数据
    const [areaCityData, setAreaCityData] = useState([])
    // 新客区域数据
    const [newAreaData, setNewAreaData] = useState([])
    const [newAreaDataLoading, setNewAreaDataLoading] = useState(false)
    // 总新客区域数据
    const [allNewAreaData, setAllNewAreaData] = useState({})
    // 区域市级数据
    const [newAreaCityData, setNewAreaCityData] = useState([])
    const [newAreaDataNum, setNewAreaDataNum] = useState('')
    // 老客区域数据
    const [oldAreaData, setOldAreaData] = useState([])
    const [oldAreaDataLoading, setOldAreaDataLoading] = useState(false)
    const [oldAreaDataNum, setOldAreaDataNum] = useState('')
    // 总老客区域数据
    const [allOldAreaData, setAllOldAreaData] = useState({})
    // 区域市级数据
    const [oldAreaCityData, setOldAreaCityData] = useState([])

    // 城市等级
    // 城市等级区域表头
    const [cityLevelColumns] = useState([
        {
            title: '城市等级',
            dataIndex: 'level',
            key: 'level',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseAreaColumns.map(item => {
            return item
        })
    ])
    // 城市等级数据
    const [cityLevelData, setCityLevelData] = useState([])
    const [cityLevelLoading, setCityLevelLoading] = useState(false)
    // 总的城市等级数据
    const [allCitylevelCityData, setAllCitylevelCityData] = useState({})
    // 城市等级市级数据
    const [citylevelCityData, setCitylevelCityData] = useState([
        {
            'id': 1,
            'city': '嘉兴市',
            'name': 1000
        },
        {
            'id': 2,
            'city': '温州市',
            'name': 1000
        }
    ])
    // 新客城市等级数据
    const [newCityLevelData, setNewCityLevelData] = useState([])
    const [newCityLevelLoading, setNewCityLevelLoading] = useState(false)
    // 总的新客城市等级数据
    const [allNewCitylevelCityData, setAllNewCitylevelCityData] = useState({
        "浙江省": [
            {
                'id': 1,
                'city': '嘉兴市',
                'name': 1000
            },
            {
                'id': 2,
                'city': '温州市',
                'name': 1000
            }
        ]
    })
    // 新客城市等级市级数据
    const [newCitylevelCityData, setNewCitylevelCityData] = useState([
        {
            'id': 1,
            'city': '嘉兴市',
            'name': 1000
        },
        {
            'id': 2,
            'city': '温州市',
            'name': 1000
        }
    ])
    // 老客城市等级数据
    const [oldCityLevelData, setOldCityLevelData] = useState([])
    const [oldCityLevelLoading, setOldCityLevelLoading] = useState(false)
    // 总的老客城市等级数据
    const [allOldCitylevelCityData, setAllOldCitylevelCityData] = useState({
        "浙江省": [
            {
                'id': 1,
                'city': '嘉兴市',
                'name': 1000
            },
            {
                'id': 2,
                'city': '温州市',
                'name': 1000
            }
        ]
    })
    // 老客城市等级市级数据
    const [oldCitylevelCityData, setOldCitylevelCityData] = useState([
        {
            'id': 1,
            'city': '嘉兴市',
            'name': 1000
        },
        {
            'id': 2,
            'city': '温州市',
            'name': 1000
        }
    ])

    useEffect(()=>{
        AcceptBuried('page_views',"view",{"page_type":"初始化页面"},props.location.pathname,"订单日常分析")
        setStartTime('')
        setEndTime('')
        setIsTicket(1)
        setSort(totalSort[scope][0]['key'])
        setAllAreaCityData({})
        setAllNewAreaData({})
        setAllOldAreaData({})
        setAreaData([])
        setNewAreaData([])
        setOldAreaData([])
        setCityLevelData([])
        setNewCityLevelData([])
        setOldCityLevelData([])
        setCommoditySPUDate([])
        setCommodityCategoryData([])
    },[scope])

    //设置每个页面显示个数
    const commoditySPUPaginationProps = {
        current: commoditySPUPage,
        total: commoditySPUTotal,
        showTotal: (total) => `总共 ${total} 商品`,
        onChange: (page) => {
            setCommoditySPUPage(page)
        }
    }

    // 获取区域数据
    const getAreaData = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
            "order_by_field_name":sort.split(',')[0],
            "is_asc":sort.split(',')[1]
        }
        values.is_old_new = 0
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        setAreaDataLoading(true)
        NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderProvince).then(res => {
            setAreaDataLoading(false)
            if (res.success) {
                setAreaData(res.data.data);
                setAllAreaCityData({})
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setAreaDataLoading(false)
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }
    // 获取区域新客数据
    const getNewAreaData = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
            "order_by_field_name":sort.split(',')[0],
            "is_asc":sort.split(',')[1]
        }
        values.is_old_new = 1
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        setNewAreaDataLoading(true)
        NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderProvince).then(res => {
            setNewAreaDataLoading(false)
            if (res.success) {
                setNewAreaData(res.data.data);
                setAllNewAreaData({})
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setNewAreaDataLoading(false)
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }
     // 获取订单对应时间内总客人数接口
     const getOrderAnalysisCountOfCustomer = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
        }
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.queryOrderAnalysisCountOfCustomer).then(res => {
            if (res.success) {
                setAreaDataNum(res.data.num);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }
    // 获取订单对应时间内老客人数接口
    const getOrderAnalysisCountOfOldCustomer = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
        }
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.queryOrderAnalysisCountOfOldCustomer).then(res => {
            if (res.success) {
                setOldAreaDataNum(res.data.num);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }
      // 获取订单对应时间内老客人数接口
      const getOrderAnalysisCountOfNewCustomer = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
        }
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.queryOrderAnalysisCountOfNewCustomer).then(res => {
            if (res.success) {
                setNewAreaDataNum(res.data.num);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }
    // 获取区域老客数据
    const getOldAreaData = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
            "order_by_field_name":sort.split(',')[0],
            "is_asc":sort.split(',')[1]
        }
        values.is_old_new = 2
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        setOldAreaDataLoading(true)
        NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderProvince).then(res => {
            setOldAreaDataLoading(false)
            if (res.success) {
                setOldAreaData(res.data.data);
                setAllOldAreaData({})
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setOldAreaDataLoading(false)
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }
    // 获取城市等级
    const getCityLevelData = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
            "order_by_field_name":sort.split(',')[0],
            "is_asc":sort.split(',')[1]
        }
        values.is_old_new = 0
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        setCityLevelLoading(true)
        NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderCitylevel).then(res => {
            setCityLevelLoading(false)
            if (res.success) {
                setCityLevelData(res.data.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setCityLevelLoading(false)
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }
    // 获取新客城市等级
    const getNewCityLevelData = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
            "order_by_field_name":sort.split(',')[0],
            "is_asc":sort.split(',')[1]
        }
        values.is_old_new = 1
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        setNewCityLevelLoading(true)
        NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderCitylevel).then(res => {
            setNewCityLevelLoading(false)
            if (res.success) {
                setNewCityLevelData(res.data.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setNewCityLevelLoading(false)
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }
    // 获取老客城市等级
    const getOldCityLevelData = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
            "order_by_field_name":sort.split(',')[0],
            "is_asc":sort.split(',')[1]
        }
        values.is_old_new = 2
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        setOldCityLevelLoading(true)
        NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderCitylevel).then(res => {
            setOldCityLevelLoading(false)
            if (res.success) {
                setOldCityLevelData(res.data.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setOldCityLevelLoading(false)
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }

    // 获取商品汇总数据
    const getCommoditySPUDate = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
            "order_by_field_name":sort.split(',')[0],
            "is_asc":sort.split(',')[1]
        }
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        setCommoditySPUDateLoading(true)
        NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderSpu).then(res => {
            setCommoditySPUDateLoading(false)
            console.log(res);
            if (res.success) {
                setCommoditySPUDate(res.data.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setCommoditySPUDateLoading(false)
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }

    //获取品类汇总数据
    const getOrderCategory2 = ()=>{
        let values = {
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_ticket": isTicket,
            "order_by_field_name":sort.split(',')[0],
            "is_asc":sort.split(',')[1]
        }
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        setCommodityCategoryLoading(true)
        NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderCategory2).then(res => {
            setCommodityCategoryLoading(false)
            if (res.success) {
                setCommodityCategoryData(res.data.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setCommodityCategoryLoading(false)
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: "space-around",
                backgroundColor: "#fff",
                borderBottom: "solid 2px #ccc",
                marginBottom: 10,
                position: 'fixed',
                top: 40,
                zIndex: 99,
                width: '100vw'
            }}>
                <Title level={3}>{props.location.pathname.split('/')[2]}订单日常分析</Title>
                <div></div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: "50px 1fr", marginTop: 50 }}>
                <div style={{ width: '100%', height: '100%', borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc', paddingBottom: 20 }}>
                    <div style={{ width: '100%' }} onClick={() => {
                        setScope('area')
                    }}>
                        <MyIcon type="icon-fuwuquyu" style={{ fontSize: 30, display: 'flex', justifyContent: 'center', padding: 10, backgroundColor: scope == 'area' ? '#ccc' : '#fff' }} />
                    </div>
                    <div style={{ width: '100%', marginTop: 20 }} onClick={() => {
                        setScope('city')
                    }}>
                        <MyIcon type="icon-chengshiicon" style={{ fontSize: 30, display: 'flex', justifyContent: 'center', padding: 10, backgroundColor: scope == 'city' ? '#ccc' : '#fff' }} />
                    </div>
                    <div style={{ width: '100%', marginTop: 20 }} onClick={() => {
                        setScope('commodit')
                    }}>
                        <MyIcon type="icon-shangpin" style={{ fontSize: 30, display: 'flex', justifyContent: 'center', padding: 10, backgroundColor: scope == 'commodit' ? '#ccc' : '#fff' }} />
                    </div>
                    {/* <div style={{ width: '100%', marginTop: 20 }} onClick={() => {
                        setScope('download')
                    }}>
                        <MyIcon type="icon-yunduanxiazai" style={{ fontSize: 30, display: 'flex', justifyContent: 'center', padding: 10, backgroundColor: scope == 'download' ? '#ccc' : '#fff' }} />
                    </div> */}
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 10 }}>
                        <div>
                            <b style={{ fontSize: 16, marginRight: 10, display:scope == 'download' ? 'none' : '' }}>排序:</b>
                            <Select style={{ width: 240, display:scope == 'download' ? 'none' : '' }} value={sort} onChange={(value)=>{
                                setSort(value)
                            }}>
                                {
                                    totalSort[scope].map(item=>{
                                        return <Option value={item.key}>{item.name}</Option>
                                    })
                                }
                            </Select>
                            <Checkbox style={{ marginLeft: 30 }} value={isTicket ? false : true} onChange={(e) => {
                                    if (e.target.checked) {
                                        setIsTicket(0)
                                    } else {
                                        setIsTicket(1)
                                    }
                                }}>去掉购物金、券、邮费</Checkbox>
                        </div>
                        <div style={{display:'flex'}}>
                            <RangePicker
                                locale={locale}
                                disabledDate={disabledDate}
                                value={startTime == '' ? ['', ''] : [moment(startTime, dateFormat), moment(endTime, dateFormat)]}
                                format="YYYY-MM-DD"
                                onChange={(value, dateString) => {
                                    if (dateString[0] == '') {
                                        message.warn('请选择正确的时间')
                                    } else {
                                        setStartTime(dateString[0])
                                        setEndTime(dateString[1])
                                    }
                                }}
                            />
                            <Button type="primary" style={{marginLeft:10}} onClick={()=>{
                                if(scope == 'area'){
                                    if(startTime == ''){
                                        message.warn('请选择正确的时间范围')
                                    }else{
                                        let dataProps = {
                                            "start_time": startTime,
                                            "end_time": endTime,
                                            "is_ticket": isTicket,
                                            "order_by_field_name":sort.split(',')[0],
                                            "is_asc":sort.split(',')[1],
                                            "scope":scope
                                        }
                                        AcceptBuried('query_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                        const getAreaDataList = async () => {
                                            await Promise.all([
                                                getAreaData(),
                                                getNewAreaData(),
                                                getOldAreaData(),
                                                getOrderAnalysisCountOfCustomer(),
                                                getOrderAnalysisCountOfOldCustomer(),
                                                getOrderAnalysisCountOfNewCustomer(),
                                                
                                            ])
                                        }
                                        getAreaDataList()
                                    }
                                }else if(scope == 'city'){
                                    if(startTime == ''){
                                        message.warn('请选择正确的时间范围')
                                    }else{
                                        let dataProps = {
                                            "start_time": startTime,
                                            "end_time": endTime,
                                            "is_ticket": isTicket,
                                            "order_by_field_name":sort.split(',')[0],
                                            "is_asc":sort.split(',')[1],
                                            "scope":scope
                                        }
                                        AcceptBuried('query_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                        const getCityDataList = async () => {
                                            await Promise.all([
                                                getCityLevelData(),
                                                getNewCityLevelData(),
                                                getOldCityLevelData()
                                            ])
                                        }
                                        getCityDataList()
                                    }
                                }else if(scope == 'commodit'){
                                    let dataProps = {
                                        "start_time": startTime,
                                        "end_time": endTime,
                                        "is_ticket": isTicket,
                                        "order_by_field_name":sort.split(',')[0],
                                        "is_asc":sort.split(',')[1],
                                        "scope":scope
                                    }
                                    AcceptBuried('query_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                    const getOrderList = async () => {
                                        await Promise.all([
                                            getCommoditySPUDate(),
                                            getOrderCategory2()
                                        ])
                                    }
                                    getOrderList()
                                }
                            }}>查询</Button>
                        </div>
                    </div>
                    <div style={{ display: scope == 'area' ? '' : 'none', marginLeft: 20, marginRight: 20 }}>
                        <Divider orientation="left">{`地域总人数：${areaDataNum}人`}</Divider>
                        <div style={{display: areaData.length == 0 ? 'none' : 'flex', justifyContent:'flex-end', marginBottom:5}}>
                            <Button type="dashed" onClick={()=>{
                                let values = {}
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
                                values.data = areaData
                                values.table_head = provinceAreaColumns
                                values.start_time = startTime
                                values.end_time = endTime
                                values.name = '地域数据'
                                let dataProps = {
                                    "name": '下载省份数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderDataFile,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载省份数据</Button>
                            <Button type="dashed" style={{marginLeft:5}} onClick={()=>{
                                let values = {
                                    "start_time": startTime,
                                    "end_time": endTime,
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "is_ticket": isTicket,
                                    "is_old_new": 0,
                                    "query_order_city": '',
                                    "order_by_field_name":sort.split(',')[0],
                                    "is_asc":sort.split(',')[1]
                                }
                                values.table_head = cityAreaColumns
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.name = '地域市级数据'
                                let dataProps = {
                                    "name": '下载市级数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderAnalysisOrderCity,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载市级数据</Button>
                        </div>
                        <Table bordered
                            columns={provinceAreaColumns}
                            loading={areaDataLoading}
                            dataSource={areaData} pagination={false} scroll={{ y: 350 }}
                            expandable={{
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded
                                        ? (<DownOutlined onClick={e => onExpand(record, e)} />)
                                        : (<RightOutlined onClick={e => onExpand(record, e)} />)
                                ,
                                expandedRowRender: record => (
                                    <div >
                                        <Table
                                            bordered
                                            columns={cityAreaColumns}
                                            dataSource={areaCityData}
                                            scroll={{ x: 1000, y: 660 }}
                                            pagination={false} />
                                    </div>
                                ),
                                expandedRowKeys: areaRowKey,
                                expandRowByClick: true,
                                onExpand: (expanded, record) => {
                                    let temp = []
                                    // 判断是否是同一行进行点击
                                    if (expanded) {
                                        temp.push(record.province)
                                        if (allAreaCityData[record.province] == undefined) {
                                            let values = {
                                                "start_time": startTime,
                                                "end_time": endTime,
                                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                                "is_ticket": isTicket,
                                                "is_old_new": 0,
                                                "province": record.province,
                                                "order_by_field_name":sort.split(',')[0],
                                                "is_asc":sort.split(',')[1]
                                            }
                                            values.roleId = cookie.load('role')
                                            values.shopList = cookie.load('shopList')
                                            setAreaDataLoading(true)
                                            NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderCity).then(res => {
                                                setAreaDataLoading(false)
                                                if (res.success) {
                                                    setAreaCityData(res.data.data);
                                                    allAreaCityData[record.province] = res.data.data
                                                    setAllAreaCityData(allAreaCityData)
                                                    setAreaRowKey(temp)
                                                } else {
                                                    message.warn(res.errorMsg)
                                                }
                                            }).catch((err) => { 
                                                // 获取500错误
                                                setAreaDataLoading(false)
                                                message.warn('查询接口报错，请联系IT帮忙解决')
                                            })
                                        } else {
                                            setAreaCityData(allAreaCityData[record.province])
                                            setAreaRowKey(temp)
                                        }
                                    }else{
                                        setAreaRowKey(temp)
                                    }
                                    
                                },
                            }}
                            rowKey="province" />
                        <Divider orientation="left">{`新客总人数：${newAreaDataNum}人`}</Divider>
                        <div style={{display:newAreaData.length == 0 ? 'none' : 'flex', justifyContent:'flex-end', marginBottom:5}}>
                            <Button type="dashed" onClick={()=>{
                                let values = {}
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
                                values.data = newAreaData
                                values.table_head = provinceAreaColumns
                                values.start_time = startTime
                                values.end_time = endTime
                                values.name = '地域新客数据'
                                let dataProps = {
                                    "name": '下载新客省份数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderDataFile,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载新客省份数据</Button>
                            <Button type="dashed" style={{marginLeft:5}} onClick={()=>{
                                let values = {
                                    "start_time": startTime,
                                    "end_time": endTime,
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "is_ticket": isTicket,
                                    "is_old_new": 1,
                                    "query_order_city": '',
                                    "order_by_field_name":sort.split(',')[0],
                                    "is_asc":sort.split(',')[1]
                                }
                                values.table_head = cityAreaColumns
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.name = '地域市级数据'
                                let dataProps = {
                                    "name": '下载新客市级数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderAnalysisOrderCity,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载新客市级数据</Button>
                        </div>
                        <Table bordered
                            columns={provinceAreaColumns}
                            loading={newAreaDataLoading}
                            dataSource={newAreaData} pagination={false} scroll={{ y: 350 }}
                            expandable={{
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded
                                        ? (<DownOutlined onClick={e => onExpand(record, e)} />)
                                        : (<RightOutlined onClick={e => onExpand(record, e)} />)
                                ,
                                expandedRowRender: record => (
                                    <div >
                                        <Table
                                            bordered
                                            columns={cityAreaColumns}
                                            dataSource={newAreaCityData}
                                            scroll={{ x: 1000, y: 660 }}
                                            pagination={false} />
                                    </div>
                                ),
                                expandedRowKeys: newAreaRowKey,
                                // expandRowByClick: true,
                                onExpand: (expanded, record) => {
                                    let temp = []
                                    // 判断是否是同一行进行点击
                                    if (expanded) {
                                        temp.push(record.province)
                                        if (allNewAreaData[record.province] == undefined) {
                                            let values = {
                                                "start_time": startTime,
                                                "end_time": endTime,
                                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                                "is_ticket": isTicket,
                                                "is_old_new": 1,
                                                "province": record.province,
                                                "order_by_field_name":sort.split(',')[0],
                                                "is_asc":sort.split(',')[1]
                                            }
                                            values.roleId = cookie.load('role')
                                            values.shopList = cookie.load('shopList')
                                            setNewAreaDataLoading(true)
                                            NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderCity).then(res => {
                                                setNewAreaDataLoading(false)
                                                if (res.success) {
                                                    setNewAreaCityData(res.data.data);
                                                    allNewAreaData[record.province] = res.data.data
                                                    setAllNewAreaData(allNewAreaData)
                                                    setNewAreaRowKey(temp)
                                                } else {
                                                    message.warn(res.errorMsg)
                                                }
                                            }).catch((err) => { 
                                                // 获取500错误
                                                setNewAreaDataLoading(false)
                                                message.warn('查询接口报错，请联系IT帮忙解决')
                                            })
                                        } else {
                                            setNewAreaCityData(allNewAreaData[record.province])
                                            setNewAreaRowKey(temp)
                                        }
                                    }else{
                                        setNewAreaRowKey(temp)
                                    }

                                },
                            }}
                            rowKey="province" />
                        <Divider orientation="left">{`老客总人数：${oldAreaDataNum}人`}</Divider>
                        <div style={{display:oldAreaData.length == 0 ? 'none' : 'flex', justifyContent:'flex-end', marginBottom:5}}>
                            <Button type="dashed" onClick={()=>{
                                let values = {}
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
                                values.data = oldAreaData
                                values.table_head = provinceAreaColumns
                                values.start_time = startTime
                                values.end_time = endTime
                                values.name = '地域老客数据'
                                let dataProps = {
                                    "name": '下载老客省份数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderDataFile,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载老客省份数据</Button>
                            <Button type="dashed" style={{marginLeft:5}} onClick={()=>{
                                let values = {
                                    "start_time": startTime,
                                    "end_time": endTime,
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "is_ticket": isTicket,
                                    "is_old_new": 2,
                                    "query_order_city": '',
                                    "order_by_field_name":sort.split(',')[0],
                                    "is_asc":sort.split(',')[1]
                                }
                                values.table_head = cityAreaColumns
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.name = '地域市级数据'
                                let dataProps = {
                                    "name": '下载老客市级数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderAnalysisOrderCity,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载老客市级数据</Button>
                        </div>
                        <Table bordered
                            columns={provinceAreaColumns}
                            loading={oldAreaDataLoading}
                            dataSource={oldAreaData} pagination={false} scroll={{ y: 350 }}
                            expandable={{
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded
                                        ? (<DownOutlined onClick={e => onExpand(record, e)} />)
                                        : (<RightOutlined onClick={e => onExpand(record, e)} />)
                                ,
                                expandedRowRender: record => (
                                    <div >
                                        <Table
                                            bordered
                                            columns={cityAreaColumns}
                                            dataSource={oldAreaCityData}
                                            scroll={{ x: 1000, y: 660 }}
                                            pagination={false} />
                                    </div>
                                ),
                                expandedRowKeys: oldAreaRowKey,
                                // expandRowByClick: true,
                                onExpand: (expanded, record) => {
                                    let temp = []
                                    // 判断是否是同一行进行点击
                                    if (expanded) {
                                        temp.push(record.province)
                                        if (allOldAreaData[record.province] == undefined) {
                                            let values = {
                                                "start_time": startTime,
                                                "end_time": endTime,
                                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                                "is_ticket": isTicket,
                                                "is_old_new": 2,
                                                "province": record.province,
                                                "order_by_field_name":sort.split(',')[0],
                                                "is_asc":sort.split(',')[1]
                                            }
                                            values.roleId = cookie.load('role')
                                            values.shopList = cookie.load('shopList')
                                            setOldAreaDataLoading(true)
                                            NbAxios(values, "POST", servicePath.queryOrderAnalysisOrderCity).then(res => {
                                                setOldAreaDataLoading(false)
                                                if (res.success) {
                                                    setOldAreaCityData(res.data.data);
                                                    allOldAreaData[record.province] = res.data.data
                                                    setAllOldAreaData(allOldAreaData)
                                                    setOldAreaRowKey(temp)
                                                } else {
                                                    message.warn(res.errorMsg)
                                                }
                                            }).catch((err) => { 
                                                // 获取500错误
                                                setNewAreaDataLoading(false)
                                                message.warn('查询接口报错，请联系IT帮忙解决')
                                            })
                                        } else {
                                            setOldAreaCityData(allOldAreaData[record.province])
                                            setOldAreaRowKey(temp)
                                        }
                                    }else{
                                        setOldAreaRowKey(temp)
                                    }

                                },
                            }}
                            rowKey="province" />
                    </div>
                    <div style={{ display: scope == 'city' ? '' : 'none', marginLeft: 20, marginRight: 20 }}>
                        <Divider orientation="left">城市等级销售</Divider>
                        <div style={{display:cityLevelData.length == 0 ? 'none' : 'flex', justifyContent:'flex-end', marginBottom:5}}>
                            <Button type="dashed" onClick={()=>{
                                let values = {}
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
                                values.data = cityLevelData
                                values.table_head = cityLevelColumns
                                values.start_time = startTime
                                values.end_time = endTime
                                values.name = '城市等级销售数据'
                                let dataProps = {
                                    "name": '下载城市等级销售数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderDataFile,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载城市等级销售数据</Button>
                        </div>
                        <Table bordered
                            columns={cityLevelColumns}
                            dataSource={cityLevelData} pagination={false} scroll={{ y: 350 }}
                            loading={cityLevelLoading}
                            // expandable={{
                            //     expandIcon: ({ expanded, onExpand, record }) =>
                            //         expanded
                            //             ? (<DownOutlined onClick={e => onExpand(record, e)} />)
                            //             : (<RightOutlined onClick={e => onExpand(record, e)} />)
                            //     ,
                            //     expandedRowRender: record => (
                            //         <div >
                            //             <Table
                            //                 bordered
                            //                 columns={cityAreaColumns}
                            //                 dataSource={[]}
                            //                 scroll={{ x: 1000, y: 660 }}
                            //                 pagination={false} />
                            //         </div>
                            //     ),
                            //     expandedRowKeys: areaRowKey,
                            //     // expandRowByClick: true,
                            //     onExpand: (expanded, record) => {
                            //         let temp = []
                            //         // 判断是否是同一行进行点击
                            //         if (expanded) {
                            //             temp.push(record.province)
                            //         }
                            //         setAreaRowKey(temp)
                            //         console.log(record.province);
                            //         console.log(allAreaCityData[record.province]);
                            //         if (allAreaCityData[record.province] == undefined) {
                            //             setAreaCityData([
                            //                 {
                            //                     'id': 1,
                            //                     'city': '嘉兴市1',
                            //                     'name': 1000
                            //                 },
                            //                 {
                            //                     'id': 2,
                            //                     'city': '温州市1',
                            //                     'name': 1000
                            //                 }
                            //             ])
                            //             allAreaCityData[record.province] = [
                            //                 {
                            //                     'id': 1,
                            //                     'city': '嘉兴市1',
                            //                     'name': 1000
                            //                 },
                            //                 {
                            //                     'id': 2,
                            //                     'city': '温州市1',
                            //                     'name': 1000
                            //                 }
                            //             ]
                            //             setAllAreaCityData(allAreaCityData)
                            //         } else {
                            //             console.log("从总数据集提取");
                            //             setAreaCityData(allAreaCityData[record.province])
                            //         }
                            //     },
                            // }}
                            rowKey="province" />
                        <Divider orientation="left">新客城市等级销售</Divider>
                        <div style={{display:newCityLevelData.length == 0 ? 'none' : 'flex', justifyContent:'flex-end', marginBottom:5}}>
                            <Button type="dashed" onClick={()=>{
                                let values = {}
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
                                values.data = newCityLevelData
                                values.table_head = cityLevelColumns
                                values.start_time = startTime
                                values.end_time = endTime
                                values.name = '新客城市等级销售数据'
                                let dataProps = {
                                    "name": '下载新客城市等级销售数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderDataFile,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载新客城市等级销售数据</Button>
                        </div>
                        <Table bordered
                            columns={cityLevelColumns} loading={newCityLevelLoading}
                            dataSource={newCityLevelData} pagination={false} scroll={{ y: 350 }}
                            // expandable={{
                            //     expandIcon: ({ expanded, onExpand, record }) =>
                            //         expanded
                            //             ? (<DownOutlined onClick={e => onExpand(record, e)} />)
                            //             : (<RightOutlined onClick={e => onExpand(record, e)} />)
                            //     ,
                            //     expandedRowRender: record => (
                            //         <div >
                            //             <Table
                            //                 bordered
                            //                 columns={cityAreaColumns}
                            //                 dataSource={newAreaCityData}
                            //                 scroll={{ x: 1000, y: 660 }}
                            //                 pagination={false} />
                            //         </div>
                            //     ),
                            //     expandedRowKeys: newAreaRowKey,
                            //     // expandRowByClick: true,
                            //     onExpand: (expanded, record) => {
                            //         let temp = []
                            //         // 判断是否是同一行进行点击
                            //         if (expanded) {
                            //             temp.push(record.province)
                            //         }
                            //         setNewAreaRowKey(temp)

                            //     },
                            // }}
                            rowKey="province" />
                        <Divider orientation="left">老客城市等级销售</Divider>
                        <div style={{display:oldCityLevelData.length == 0 ? 'none' : 'flex', justifyContent:'flex-end', marginBottom:5}}>
                            <Button type="dashed" onClick={()=>{
                                let values = {}
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
                                values.data = oldCityLevelData
                                values.table_head = cityLevelColumns
                                values.start_time = startTime
                                values.end_time = endTime
                                values.name = '老客城市等级销售数据'
                                let dataProps = {
                                    "name": '下载老客城市等级销售数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderDataFile,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载老客城市等级销售数据</Button>
                        </div>
                        <Table bordered
                            columns={cityLevelColumns} loading={oldCityLevelLoading}
                            dataSource={oldCityLevelData} pagination={false} scroll={{ y: 350 }}
                            // expandable={{
                            //     expandIcon: ({ expanded, onExpand, record }) =>
                            //         expanded
                            //             ? (<DownOutlined onClick={e => onExpand(record, e)} />)
                            //             : (<RightOutlined onClick={e => onExpand(record, e)} />)
                            //     ,
                            //     expandedRowRender: record => (
                            //         <div >
                            //             <Table
                            //                 bordered
                            //                 columns={cityAreaColumns}
                            //                 dataSource={oldAreaCityData}
                            //                 scroll={{ x: 1000, y: 660 }}
                            //                 pagination={false} />
                            //         </div>
                            //     ),
                            //     expandedRowKeys: oldAreaRowKey,
                            //     // expandRowByClick: true,
                            //     onExpand: (expanded, record) => {
                            //         let temp = []
                            //         // 判断是否是同一行进行点击
                            //         if (expanded) {
                            //             temp.push(record.province)
                            //         }
                            //         setOldAreaRowKey(temp)

                            //     },
                            // }}
                            rowKey="province" />
                    </div>
                    <div style={{ display: scope == 'commodit' ? '' : 'none', marginLeft: 20, marginRight: 20 }}>
                        <div style={{display:commoditySPUDate.length == 0 ? 'none' : 'flex', justifyContent:'flex-end', marginBottom:5}}>
                            <Button type="dashed" onClick={()=>{
                                let values = {}
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
                                values.data = commoditySPUDate
                                values.table_head = commoditySPUColumns
                                values.start_time = startTime
                                values.end_time = endTime
                                values.name = '商品销售数据'
                                let dataProps = {
                                    "name": '下载商品销售数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderDataFile,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载商品销售数据</Button>
                        </div>
                        <Table columns={commoditySPUColumns}
                            scroll={{ y: 350 }} bordered
                            loading={commoditySPULoading}
                            style={{marginTop:40}}
                            pagination={{pageSize:20}} dataSource={commoditySPUDate} />
                         <div style={{display:commodityCategoryData.length == 0 ? 'none' : 'flex', justifyContent:'flex-end', marginBottom:5}}>
                            <Button type="dashed" onClick={()=>{
                                let values = {}
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.shopId = shopIdDict[props.history.location.pathname.split('/')[2]]
                                values.data = commodityCategoryData
                                values.table_head = commodityCategoryColumns
                                values.start_time = startTime
                                values.end_time = endTime
                                values.name = '商品品类销售数据'
                                let dataProps = {
                                    "name": '下载商品品类销售数据'
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"订单日常分析")
                                axios({
                                    data: values,
                                    method: 'POST',
                                    url: servicePath.downloadOrderDataFile,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载商品品类销售数据</Button>
                        </div>
                        <Table columns={commodityCategoryColumns} dataSource={commodityCategoryData}
                            scroll={{ y: 350 }} bordered pagination={false}  style={{marginTop:40}}
                            loading={commodityCategoryLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Index