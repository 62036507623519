import React, { useEffect, useState, useRef, useContext } from 'react';
import { Steps, Typography, Select, DatePicker, Modal, Input, Button, Tag, Cascader, Checkbox, message, Popover, Table, List, Pagination, Spin, Tooltip } from 'antd'
import { CheckCircleFilled, CloseOutlined, QuestionCircleOutlined, CloseCircleFilled, ExclamationCircleFilled, DeleteOutlined } from '@ant-design/icons';
import { Sketch } from '@uiw/react-color'
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl';
import Axios from 'axios';
import NbAxios from '../../../config/utils/nbAxios';
import style from './index.module.css';
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import PlatformEventsCalendar from '../../../component/platformEventsCalendar'
const { Title, Text, Paragraph } = Typography
const { Option, OptGroup } = Select
const { RangePicker } = DatePicker
const { Step } = Steps
const EditableContext = React.createContext(null);
const dateFormat = 'YYYY-MM-DD';

function Index(props) {
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        console.log(index);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    const options = {
        '季节': 'orange',
        '产品线': 'orange',
        '上市年份': 'orange',
        '品类': 'orange',
        '年份': 'green',
        '月份': 'green'
    }
    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={options[value]}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{
                    marginRight: 3,
                }}
            >
                {label}
            </Tag>
        );
    };
    // 加载
    const [loading, setLoading] = useState(false)
    // 活动id
    const [activityId, setActivityId] = useState('')
    // 计划id
    const [planId, setPlanId] = useState('')
    //  沿用活动id
    const [followActivityId, setFollowActivityId] = useState('')
    // 沿用计划id
    const [followPlanId, setFollowPlanId] = useState('')
    // 当前步骤
    const [currentStep, setCurrentStep] = useState(0)
    // // 产品线
    // const [productLineList, setProductLineList] = useState([])
    // // 年份
    // const [yearList, setYearList] = useState([])
    // // 季节
    // const [seasonList, setSeasonList] = useState([])
    // 商品选择项
    const [goodsFilterAddFields, setGoodsFilterAddFields] = useState([])
    // 商品标签集合
    const [productLabelList, setProductLabelList] = useState([])
    // 运营状态
    const [operationStatueList, setOperationStatueList] = useState([{
        "group": '',
        "tags": []
    }])
    // 选择运营状态
    const [chooseOperationStatue, setChooseOperationStatue] = useState([])
    // 校验商品分组
    const [checkPacket, setCheckPacket] = useState(false)
    // 时间数据
    const [dateDataList, setDateDataList] = useState(['年份', '月份'])
    // 分组选择字段
    const [fieldsBeUsed, setFieldsBeUsed] = useState([])
    // 选择时间数据
    const [chooseDateDataList, setChooseDateDataList] = useState(['年份', '月份'])
    // 对标组数据
    const [benchmarkingData, setBenchmarkingData] = useState([
        {
            value: '年份',
            label: '年份',
            children: [
                {
                    value: '22',
                    label: '22',
                },
                {
                    value: '21',
                    label: '21',
                },
                {
                    value: '20',
                    label: '20',
                },
            ],
        },
        {
            value: '季节',
            label: '季节',
            children: [
                {
                    value: '春',
                    label: '春',
                },
                {
                    value: '夏',
                    label: '夏',
                },
                {
                    value: '秋',
                    label: '秋',
                },
            ],
        }
    ])
    // 对标时间
    const [compareGoodsTime, setCompareGoodsTime] = useState('')
    // 当前组的数据
    const [nowGroupData, setNowGroupData] = useState([])
    // 时间
    const [time, setTime] = useState('')
    const [piece, setPiece] = useState('')
    const [fold, setFold] = useState('')
    const [isBound, setIsBound] = useState(false)
    const [boundtime, setBoundtime] = useState('')
    const [boundpiece, setBoundpiece] = useState('')
    const [boundfold, setBoundfold] = useState('')
    // 专区集合
    const [zoneList, setZoneList] = useState([])
    // 优惠券
    const [couponList, setCouponList] = useState([])
    // 是否官方
    const [official, setOfficial] = useState(0)
    // 优惠券
    const [chooseCoupon, setChooseCoupon] = useState({ 'coupon_name': '' })
    // 满减类型
    const [calculateType, setCalculateType] = useState('满')
    // 前因
    const [antecedents, setAntecedents] = useState('')
    // 后果
    const [consequence, setConsequence] = useState('')
    // 优惠券
    const [coupon, setCoupon] = useState([])
    // 管理优惠券
    const [manageCoupon, setManageCoupon] = useState(false)
    // 添加优惠券表头
    const [addCouponColumns, setAddCouponColumns] = useState([])
    // 添加优惠券数据
    const [addCouponData, setAddCouponData] = useState([])
    // 是否校验通过
    const [checkFlag, setCheckFlag] = useState(0)
    // 会员标签
    const [memberPrice, setMemberPrice] = useState([])
    // 选择会员标签
    const [memberPriceChecked, setMemberPriceChecked] = useState([])
    // 显示数据
    const [showField, setShowField] = useState([])
    useEffect(() => {
        setAddCouponColumns([
            {
                title: '序号',
                key: '序号',
                dataIndex: '序号',
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 0
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                ),
            },
            {
                title: '名称',
                key: 'coupon_name',
                dataIndex: 'coupon_name',
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 0
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <Input size="small" style={{ width: "80px", padding: "0px", fontSize: "12px", border: "none" }} value={text} onChange={(e) => {
                        let obj = [...addCouponData]
                        obj[index]['coupon_name'] = e.target.value
                        setAddCouponData(obj)
                    }} />
                )
            },
            {
                title: '简称',
                key: 'coupon_short_name',
                dataIndex: 'coupon_short_name',
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 0
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <Input maxLength={3} size="small" style={{ width: "80px", padding: "0px", fontSize: "12px", border: "none" }} value={text} onChange={(e) => {
                        let obj = [...addCouponData]
                        obj[index]['coupon_short_name'] = e.target.value
                        setAddCouponData(obj)
                    }} />
                )
            },
            {
                title: '叠加',
                key: 'is_stackable',
                dataIndex: 'is_stackable',
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 0
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <Checkbox size="small" checked={text == 0 ? false : true} onChange={(e) => {
                        if (e.target.checked) {
                            let obj = [...addCouponData]
                            obj[index]['is_stackable'] = 1
                            setAddCouponData(obj)
                        } else {
                            let obj = [...addCouponData]
                            obj[index]['is_stackable'] = 0
                            setAddCouponData(obj)
                        }
                    }}></Checkbox>
                ),
            },
            {
                title: '预览',
                key: 'coupon_short_name',
                dataIndex: 'coupon_short_name',
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 0
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <div style={{ display: 'flex' }}>
                        <Popover trigger="click" title="" content={<Sketch color={record['logo_color']} onChange={(color) => {
                            let obj = [...addCouponData]
                            obj[index]['logo_color'] = color.hexa
                            setAddCouponData(obj)
                        }} />}>
                            <div style={{ border: `1px solid ${record['logo_color']}`, color: `${record['logo_color']}`, backgroundColor: `${record['logo_color'].substring(0, 7) + '19'}`, textAlign: 'center', borderRadius: "3px", padding: "0 3px", fontSize: "10px", height: "20.84px" }}>{text}</div>
                        </Popover>
                    </div>
                )
            },
            {
                title: '操作',
                key: '操作',
                dataIndex: '操作',
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 0
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <div style={{ display: 'flex' }}>
                        {/* <Popover trigger="click" title="" content={<Sketch color={record['logo_color']} onChange={(color) => {
                            let obj = [...addCouponData]
                            obj[index]['logo_color'] = color.hexa
                            setAddCouponData(obj)
                        }} />}>
                            <a style={{ textDecoration: 'underline' }}>改色</a>
                        </Popover> */}
                        <a style={{ textDecoration: 'underline', marginLeft: "10px" }} onClick={() => {
                            let obj = [...addCouponData]
                            obj.splice(index, 1)
                            setAddCouponData(obj)
                            let mutuallyCouponObj = [...mutuallyCoupon]
                            mutuallyCouponObj.map((mutuallyCouponItem, mutuallyCouponIndex) => {
                                if (mutuallyCouponItem.coupon1 == record['coupon_name'] || mutuallyCouponItem.coupon2 == record['coupon_name']) {
                                    mutuallyCouponObj.splice(mutuallyCouponIndex, 1)
                                }
                            })
                            setMutuallyCoupon(mutuallyCouponObj)
                        }}>删除</a>
                    </div>
                ),
            },
        ])
    }, [addCouponData])
    // 互斥券
    const [mutuallyCouponColumns, setMutuallyCouponColumns] = useState([])
    const [mutuallyCoupon, setMutuallyCoupon] = useState([])
    // 商品基础
    const [commodityBasisList, setCommdityBasisList] = useState([])
    const [chooseCommodityBasis, setChooseCommodityBasis] = useState([])
    // 销售情况
    const [saleList, setSaleList] = useState([])
    const [chooseSale, setChooseSale] = useState([])
    // 近期时间
    const [recentTimeList, setRecentTimeList] = useState([])
    const [recentSaleList, setRecentSaleList] = useState([])
    // 选择近期时间
    const [chooseRecentTime, setChooseRecentTime] = useState([])
    const [chooseRecentSale, setChooseRecentSale] = useState([])
    // 商品标记
    const [productMarkList, setProductMarkList] = useState([])
    const [chooseProductMark, setChooseProductMark] = useState([])
    // 定价情况
    const [pricingList, setPricingList] = useState({ 'fields': [], '活动名称': [], '计划名称': [] })
    const [choosePricing, setChoosePricing] = useState([])
    // 选择活动销售
    const [chooseEventSales, setChooseEventSales] = useState([])
    // 库存数据
    const [inventoryDataList, setInventoryDataList] = useState([])
    // 选择库存数据
    const [chooseInventoryData, setChooseInventoryData] = useState([])
    // 活动最低价
    const [activityLowestPriceList, setActivityLowestPriceList] = useState([])
    const [chooseActivityLowestPrice, setChooseActivityLowestPrice] = useState([])
    // 指定活动销售情况
    const [activitySaleList, setActivitySaleList] = useState({ 'fields': [], '活动名称': [] })
    // 添加计划
    const [addPlan, setAddPlan] = useState(false)
    // 活动开始时间
    const [startDate, setStartDate] = useState('')
    // 活动结束时间
    const [endDate, setEndDate] = useState('')
    // 活动详情
    const [activityDetailList, setActivityDetailList] = useState([])
    // 计划详情
    const [planDetailList, setPlanDetailList] = useState([])
    // 选择活动
    const [chooseActivityDetail, setChooseActivityDetail] = useState([])
    // 选择活动
    const [chooseActivityList, setChooseActivityList] = useState([])
    // 选择计划
    const [choosePlanDetail, setChoosePlanDetail] = useState([])
    // 查询活动
    const [searchActivity, setSearchActivity] = useState(false)
    // 校验商品数据
    const [checkProductDict, setCheckProductDict] = useState({
        '分组匹配关系': [],
        'fields_be_used': [],
        'fields_value': [],
        'compare_goods_time': '',
        'cheak_flag': 0,
    })
    // 对标组下拉框
    const [checkProductOption, setCheckProductOption] = useState([])
    // 相关指标
    const [relatedIndex, setRelatedIndex] = useState([])
    // 活动
    const [historyActivityList, setHistoryActivityList] = useState([
        {
            'id': 1,
            'name': "2020天猫520礼遇节1",
            'startTime': "2022-05-18 00:00:00",
            'endTime': "2022-05-20 00:00:00",
            'describe': "520礼遇季作为年度重要的礼赠活动，围绕告白送礼心智，以新意告白为主题，打造潮流礼遇季。"
        },
        {
            'id': 2,
            'name': "2020天猫520礼遇节2",
            'startTime': "2022-05-18 00:00:00",
            'endTime': "2022-05-20 00:00:00",
            'describe': "520礼遇季作为年度重要的礼赠活动，围绕告白送礼心智，以新意告白为主题，打造潮流礼遇季。"
        },
        {
            'id': 3,
            'name': "2020天猫520礼遇节3",
            'startTime': "2022-05-18 00:00:00",
            'endTime': "2022-05-20 00:00:00",
            'describe': "520礼遇季作为年度重要的礼赠活动，围绕告白送礼心智，以新意告白为主题，打造潮流礼遇季。"
        },
        {
            'id': 4,
            'name': "2020天猫520礼遇节4",
            'startTime': "2022-05-18 00:00:00",
            'endTime': "2022-05-20 00:00:00",
            'describe': "520礼遇季作为年度重要的礼赠活动，围绕告白送礼心智，以新意告白为主题，打造潮流礼遇季。"
        },
        {
            'id': 5,
            'name': "2020天猫520礼遇节5",
            'startTime': "2022-05-18 00:00:00",
            'endTime': "2022-05-20 00:00:00",
            'describe': "520礼遇季作为年度重要的礼赠活动，围绕告白送礼心智，以新意告白为主题，打造潮流礼遇季。"
        },
        {
            'id': 6,
            'name': "2020天猫520礼遇节6",
            'startTime': "2022-05-18 00:00:00",
            'endTime': "2022-05-20 00:00:00",
            'describe': "520礼遇季作为年度重要的礼赠活动，围绕告白送礼心智，以新意告白为主题，打造潮流礼遇季。"
        },
        {
            'id': 3,
            'name': "2020天猫520礼遇节3",
            'startTime': "2022-05-18 00:00:00",
            'endTime': "2022-05-20 00:00:00",
            'describe': "520礼遇季作为年度重要的礼赠活动，围绕告白送礼心智，以新意告白为主题，打造潮流礼遇季。"
        },
        {
            'id': 4,
            'name': "2020天猫520礼遇节4",
            'startTime': "2022-05-18 00:00:00",
            'endTime': "2022-05-20 00:00:00",
            'describe': "520礼遇季作为年度重要的礼赠活动，围绕告白送礼心智，以新意告白为主题，打造潮流礼遇季。"
        },
        {
            'id': 5,
            'name': "2020天猫520礼遇节5",
            'startTime': "2022-05-18 00:00:00",
            'endTime': "2022-05-20 00:00:00",
            'describe': "520礼遇季作为年度重要的礼赠活动，围绕告白送礼心智，以新意告白为主题，打造潮流礼遇季。"
        },
        {
            'id': 6,
            'name': "2020天猫520礼遇节6",
            'startTime': "2022-05-18 00:00:00",
            'endTime': "2022-05-20 00:00:00",
            'describe': "520礼遇季作为年度重要的礼赠活动，围绕告白送礼心智，以新意告白为主题，打造潮流礼遇季。"
        }
    ])
    useEffect(() => {
        setMutuallyCouponColumns([
            {
                title: '序号',
                key: '序号',
                dataIndex: '序号',
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 2
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                ),
            },
            {
                title: '优惠券1',
                key: 'coupon1',
                dataIndex: 'coupon1',
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 2
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <Select size="small" style={{ width: "100px" }} value={text} onChange={(value) => {
                        let obj = [...mutuallyCoupon]
                        obj[index]['coupon1'] = value
                        setMutuallyCoupon(obj)
                    }}>
                        {
                            addCouponData.map(item => {
                                return <Option value={item.coupon_name}>{item.coupon_name}</Option>
                            })
                        }
                    </Select>
                ),
            },
            {
                title: '优惠券2',
                key: 'coupon2',
                dataIndex: 'coupon2',
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 2
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <Select size="small" style={{ width: "100px" }} value={text} onChange={(value) => {
                        let obj = [...mutuallyCoupon]
                        obj[index]['coupon2'] = value
                        setMutuallyCoupon(obj)
                    }}>
                        {
                            addCouponData.map(item => {
                                return <Option value={item.coupon_name}>{item.coupon_name}</Option>
                            })
                        }
                    </Select>
                ),
            },
            {
                title: '操作',
                key: '操作',
                dataIndex: '操作',
                align: 'center',
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                padding: 2
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <div style={{ display: 'flex' }}>
                        <a style={{ textDecoration: 'underline', marginLeft: "10px" }} onClick={() => {
                            let obj = [...mutuallyCoupon]
                            obj.splice(index, 1)
                            setMutuallyCoupon(obj)
                        }}>删除</a>
                    </div>
                ),
            },
        ])
    }, [addCouponData, mutuallyCoupon])

    useEffect(() => {
        setActivityId(props.location.state.activity_id)
        setPlanId(props.location.state.plan_id)
        setFollowActivityId(props.location.state.follow_activity_id)
        setFollowPlanId(props.location.state.follow_paln_id)
        if (currentStep == 0) {
            searchGoodsFilterLabelAndCondition(props.location.state.activity_id, props.location.state.plan_id, 0)
            filterTagsOfGoods()
            searchGoodsInfoFields()
        } else if (currentStep == 1) {
            const getData = async () => {
                await Promise.all([
                    searchPlanDiscountsAndVip(props.location.state.activity_id, props.location.state.plan_id),
                    searchPlanCoupons(props.location.state.activity_id, props.location.state.plan_id),
                    searchChooseAndPricingCoupons(),
                ])
            }
            getData()
        } else if (currentStep == 2) {
            // searchFieldOfPlan(props.location.state.activity_id, props.location.state.plan_id)
            referDataConfigOfPlan(props.location.state.activity_id, props.location.state.plan_id)
        }
    }, [currentStep])


    // useEffect(() => {
    //     if (goodsFilterAddFields.length != 0) {
    //         if (goodsFilterAddFields[0]['select'].length == 0) {
    //             searchGoodsInfoFields()
    //         }
    //     }
    // }, [goodsFilterAddFields])

    // 查询计划的可显示字段(新)
    const referDataConfigOfPlan = (activityId, planId) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.referDataConfigOfPlan).then(res => {
            setLoading(false)
            if (res.success) {
                // setChooseCommodityBasis(res.data['show_field_checked']['商品信息']);
                // setChooseProductMark(res.data['show_field_checked']['商品标记']);
                // setChooseInventoryData(res.data['show_field_checked']['库存数据']);
                setChooseEventSales(res.data['show_field_checked']['指定活动的销售情况'])
                setChoosePricing(res.data['show_field_checked']['指定活动计划的定价情况'])
                setChooseActivityLowestPrice(res.data['show_field_checked']['校验活动时间段内最低价'])
                // setChooseSale(res.data['show_field_checked']['沿用活动销售情况']);
                setRecentSaleList(res.data['show_field_checked']['近期销售情况']['keys']);
                setRecentTimeList(res.data['show_field_checked']['近期销售情况']['time_dim']);
                setCommdityBasisList(res.data['show_field']['商品信息']);
                // setSaleList(res.data['show_field']['沿用活动销售情况']);
                // setProductMarkList(res.data['show_field']['商品标记']);
                setRecentSaleList(res.data['show_field']['近期销售情况']['keys']);
                setRecentTimeList(res.data['show_field']['近期销售情况']['time_dim']);
                setPricingList(res.data['show_field']['指定活动计划的定价情况'])
                setActivitySaleList(res.data['show_field']['指定活动的销售情况'])
                // setInventoryDataList(res.data['show_field']['库存数据'])
                setActivityLowestPriceList(res.data['show_field']['校验活动时间段内最低价'])
                
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询计划的可显示字段出错,请联系IT')
        })
    }

    // 查询计划的可显示字段
    const searchFieldOfPlan = (activityId, planId) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchFieldOfPlan).then(res => {
            setLoading(false)
            if (res.success) {
                setChooseCommodityBasis(res.data['show_field_checked']['商品信息']);
                setChooseProductMark(res.data['show_field_checked']['商品标记']);
                setChooseInventoryData(res.data['show_field_checked']['库存数据']);
                setChooseEventSales(res.data['show_field_checked']['指定活动的销售情况'])
                setChoosePricing(res.data['show_field_checked']['指定活动计划的定价情况'])
                setChooseActivityLowestPrice(res.data['show_field_checked']['校验活动时间段内最低价'])
                setChooseSale(res.data['show_field_checked']['沿用活动销售情况']);
                setRecentSaleList(res.data['show_field_checked']['近期销售情况']['keys']);
                setRecentTimeList(res.data['show_field_checked']['近期销售情况']['time_dim']);
                setCommdityBasisList(res.data['show_field']['商品信息']);
                setSaleList(res.data['show_field']['沿用活动销售情况']);
                setProductMarkList(res.data['show_field']['商品标记']);
                setRecentSaleList(res.data['show_field']['近期销售情况']['keys']);
                setRecentTimeList(res.data['show_field']['近期销售情况']['time_dim']);
                setPricingList(res.data['show_field']['指定活动计划的定价情况'])
                setActivitySaleList(res.data['show_field']['指定活动的销售情况'])
                setInventoryDataList(res.data['show_field']['库存数据'])
                setActivityLowestPriceList(res.data['show_field']['校验活动时间段内最低价'])
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询计划的可显示字段出错,请联系IT')
        })
    }

    // 查询（沿用）计划的优惠券
    const searchPlanCoupons = (activityId, planId) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchPlanCoupons).then(res => {
            setLoading(false)
            if (res.success) {
                setCoupon(res.data.coupons);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询（沿用）计划的优惠券出错,请联系IT')
        })
    }

    // 查询店铺选款定价的优惠券
    const searchChooseAndPricingCoupons = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
        }
        NbAxios(dataProps, "POST", servicePath.searchChooseAndPricingCoupons).then(res => {
            if (res.success) {
                setCouponList(res.data.coupon_info)
                setAddCouponData(res.data.coupon_info)
                setMutuallyCoupon(res.data.coupons_mutex)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('查询店铺选款定价的优惠券出错,请联系IT')
        })
    }
    // 查询（沿用）计划的专区、会员
    const searchPlanDiscountsAndVip = (activityId, planId) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchPlanDiscountsAndVip).then(res => {
            setLoading(false)
            if (res.success) {
                setZoneList(res.data.discounts)
                setMemberPrice(res.data.member_price)
                setMemberPriceChecked(res.data.member_price_checked)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询（沿用）计划的专区、会员出错,请联系IT')
        })
    }

    // 查询商品基础信息字段
    const searchGoodsInfoFields = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
        }
        NbAxios(dataProps, "POST", servicePath.searchGoodsInfoFields).then(res => {
            if (res.success) {
                // let obj = [...goodsFilterAddFields]
                // obj.map((item, index) => {
                //     // if (item.title == '年份') {
                //     //     let selectList = []
                //     //     res.data['values']['上市年份'].map(item => {
                //     //         selectList.push('20' + item)
                //     //     })
                //     //     obj[index]['select'] = selectList
                //     // } else {
                //     //     obj[index]['select'] = res.data['values'][item.title]
                //     // }
                //     obj[index]['select'] = res.data['values'][item.title]
                // })
                // console.log(obj);
                console.log("1111", res.data);
                setGoodsFilterAddFields(res.data)
            }
        })
    }
    //创建计划步骤页-查询（沿用）计划的商品的过滤标签与条件
    const searchGoodsFilterLabelAndCondition = (activityId, planId, search_flag) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId,
            search_flag: search_flag
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchGoodsFilterLabelAndCondition).then(res => {
            setLoading(false)
            if (res.success) {
                let goodsFilterAddFields = []
                res.data.goods_filter_add_fields['商品信息'].map(item => {
                    let obj = {
                        title: item,
                        select: [],
                        value: []
                    }
                    goodsFilterAddFields.push(obj)
                })
                console.log(res.data);
                if (res.data.compare_goods_time) {
                    setCompareGoodsTime(res.data.compare_goods_time);
                } else {
                    setCompareGoodsTime(moment().format('YYYY-MM-DD'))
                }
                setCheckFlag(res.data.goods_filter_check_flag);
                // setGoodsFilterAddFields(goodsFilterAddFields)
                // setOperationStatueList(res.data['goods_filter_cut_fields']['运营状态']);
                setChooseOperationStatue(res.data['goods_filter_cut']);
                setProductLabelList(res.data['goods_filter_add']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询（沿用）计划的商品的过滤标签与条件出错,请联系IT')
        })
    }
    // 查询计划所有商品匹配关系
    const searchPlanMatchingRelationships = (activityId, planId) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.searchPlanMatchingRelationships).then(res => {
            setLoading(false)
            if (res.success) {
                console.log(res.data);
                console.log("下拉框", checkProductOption);
                setNowGroupData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询计划所有商品匹配关系出错,请联系IT')
        })
    }
    // 设置计划的利益点
    const setPlanBenefitPoint = (currentStep) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId,
            coupons: coupon,
            discounts: zoneList,
            member_price_checked: memberPriceChecked
        }
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.setPlanBenefitPoint).then(res => {
            setLoading(false)
            if (res.success) {
                setCurrentStep(currentStep)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('设置计划的利益点出错,请联系IT')
        })
    }

    // 生成选款定价计划
    const createChooseAndpricing = (flag) => {
        if (choosePricing.length != 0 && pricingList['活动名称'].length == 0) {
            message.warn('请在指定活动计划的定价情况: 添加计划')
        } else if (choosePricing.length == 0 && pricingList['活动名称'].length != 0) {
            message.warn('请在指定活动计划的定价情况: 选择指标')
        } else if (chooseEventSales.length == 0 && activitySaleList['活动名称'].length != 0) {
            message.warn('请在指定活动的销售情况: 选择指标')
        } else if (chooseEventSales.length != 0 && activitySaleList['活动名称'].length == 0) {
            message.warn('请在指定活动的销售情况: 添加活动')
        } else {
            let pricingListObj = pricingList
            pricingListObj['fields'] = choosePricing
            let activitySaleListObj = activitySaleList
            activitySaleListObj['fields'] = chooseEventSales
            let show_fields_checked = {
                '商品信息': chooseCommodityBasis,
                '沿用活动销售情况': chooseSale,
                '近期销售情况': {
                    'time_dim': chooseRecentTime,
                    'keys': chooseRecentSale,
                },
                '商品标记': chooseProductMark,
                '指定活动计划的定价情况': pricingListObj,
                '指定活动的销售情况': activitySaleListObj,
                '库存数据': chooseInventoryData,
                '校验活动时间段内最低价': chooseActivityLowestPrice,
            }
            let dataProps = {
                roleId: cookie.load('role'),
                shopList: cookie.load('shopList'),
                shop_name: props.history.location.pathname.split('/')[2],
                activity_id: activityId,
                plan_id: planId,
                flag: flag,
                show_fields_checked: show_fields_checked
            }
            setLoading(true)
            Axios({
                method: 'POST',
                url: servicePath.createChooseAndpricing,
                data: dataProps,
                headers: {
                    'authorization': cookie.load('token')
                },
                // 设置超时时间为3分钟
                timeout: 180000
            }).then(res => {
                setLoading(false)
                if (res.data.success) {
                    if (flag == 0) {
                        setCurrentStep(1)
                    } else {
                        message.success('价格计算成功')
                        props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/choicePricing/operate`, 'state': { 'plan_id': planId, 'activity_id': activityId } })
                    }
                } else {
                    setLoading(false)
                    if (res.data.errorCode === '10001') {
                        cookie.remove("id", { path: '/' });
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("shopList", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        window.location.replace('https://nb20.bmcsoft.cn/')
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }).catch(err => {
                setLoading(false)
                message.warn('生成选款定价计划出错,请联系IT')
            })
        }

    }
    // 保存计划框选商品接口
    const retainCheckConditions = (activityId, planId) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId,
            checkFlag: checkFlag,
            goods_filter_add: productLabelList,
            goods_filter_cut: chooseOperationStatue,
            compare_goods_time: compareGoodsTime,
        }
        setLoading(true)
        console.log("入参", dataProps)
        NbAxios(dataProps, "POST", servicePath.retainCheckConditions).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("保存成功")
                setFieldsBeUsed(res.data.fields_be_used)
                console.log(res.data);
                setCompareGoodsTime(res.data.compare_goods_time)
                verifyGroupMatching(activityId, planId, res.data.fields_be_used)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('保存计划框选商品接口出错,请联系IT')
        })
    }
    // 校验分组匹配接口
    const verifyGroupMatching = (activityId, planId, fieldsBeUsed) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
            activity_id: activityId,
            plan_id: planId,
            checkFlag: checkFlag,
            compare_goods_time: compareGoodsTime,
            check_data: {
                "fields_be_used": fieldsBeUsed,
                "分组匹配关系": nowGroupData
            },
        }
        setLoading(true)
        console.log("入参", dataProps)
        NbAxios(dataProps, "POST", servicePath.verifyGroupMatching).then(res => {
            setLoading(false)
            if (res.success) {
                message.success("检验成功")
                console.log(res.data);
                setNowGroupData(res.data['分组匹配关系'])
                setCheckFlag(res.data.check_flag)
                setCheckProductOption(res.data.fields_value)
                setCompareGoodsTime(res.data.compare_goods_time)
                setCheckPacket(true)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('校验分组匹配接口接口出错,请联系IT')
        })
    }
    // 获取商品标签接口
    const filterTagsOfGoods = () => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shop_name: props.history.location.pathname.split('/')[2],
        }
        setLoading(true)
        console.log("入参", dataProps)
        NbAxios(dataProps, "POST", servicePath.filterTagsOfGoods).then(res => {
            setLoading(false)
            if (res.success) {
                setOperationStatueList(res.data);
                console.log(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('校验分组匹配接口接口出错,请联系IT')
        })
    }
    return (
        <div className={style.externalContent}>
            {/* marginTop:"63px", */}
            <div className={style.editContent} >
                <Steps current={currentStep}>
                    <Step title="框选商品" description="筛选参与本次选款定价的商品的最大范围" />
                    <Step title="设置利益点" description="设定本场活动选款定价的利益点" />
                    <Step title="配置显示数据" description="此步骤用于配置在选款定价过程中显示的商品信息数据" />
                </Steps>
            </div>
            <div style={{ width: '980px', margin: "0 auto", paddingTop: "10px", display: currentStep == 0 ? '' : 'none' }}>
                <Spin spinning={loading} tip="数据加载中">
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: 'center' }}>
                        {
                            goodsFilterAddFields.map((item, index) => {
                                return <div style={{ display: 'flex' }}>
                                    <Text type="secondary" strong style={{ width: "80px", textAlign: "right", margin: '10px', fontSize: '10px' }}>{item.title}</Text>
                                    <Select style={{ width: "195px", marginRight: '30px' }} maxTagCount="responsive" mode="multiple" placeholder={`请下拉选择${item.title}`} value={item.value} onChange={(value) => {
                                        let obj = [...goodsFilterAddFields]
                                        obj[index]['value'] = value
                                        setGoodsFilterAddFields(obj)
                                    }}>
                                        {
                                            item.select.map(item => {
                                                return <Option value={item}>{item}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            })
                        }
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <Button style={{ border: '1px solid #C8EAFF', color: "#2A82E4" }} onClick={() => {
                            // 判断有没有输入筛选条件
                            let flag = 0
                            goodsFilterAddFields.map(item => {
                                if (item.value.length != 0) {
                                    flag = 1
                                }
                            })
                            if (flag == 1) {
                                setCheckFlag(0)
                                let map_fields_value = {}
                                goodsFilterAddFields.map(item => {
                                    map_fields_value[item.id] = item.value
                                })
                                console.log(map_fields_value);
                                let dataProps = {
                                    roleId: cookie.load('role'),
                                    shopList: cookie.load('shopList'),
                                    shop_name: props.history.location.pathname.split('/')[2],
                                    map_fields_value: map_fields_value,
                                }
                                console.log("入参", dataProps);
                                NbAxios(dataProps, "POST", servicePath.splitCartesianProduct).then(res => {
                                    if (res.success) {
                                        let obj = [...productLabelList]
                                        res.data.map(item => {
                                            obj.push(item)
                                        })
                                        setProductLabelList(obj)
                                        console.log(obj);
                                        let goodsFilterAddFieldsObj = [...goodsFilterAddFields]
                                        goodsFilterAddFieldsObj.map(item => {
                                            item['value'] = []
                                        })
                                        setGoodsFilterAddFields(goodsFilterAddFieldsObj)
                                        console.log(goodsFilterAddFieldsObj);
                                    }
                                })
                            } else {
                                message.warning("请先选择筛选条件")
                            }

                        }}>添加筛选条件</Button>
                        <Button style={{ border: '1px solid #C8EAFF', color: "#2A82E4", marginLeft: '10px' }} onClick={() => {
                            setProductLabelList([])
                            setCheckFlag(0)
                        }}>清空</Button>
                    </div>
                    <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "20px", marginBottom: "20px" }}></div>
                    <div style={{ maxHeight: '105px', width: "100%", display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', overflow: 'auto' }}>
                        {
                            productLabelList.map((item, index) => {
                                return <div style={{ border: "1px solid #ccc", padding: "5px", height: "24px", marginRight: '20px', marginTop: '20px', display: 'flex', alignItems: "center" }}>
                                    <div >{item.value}</div>
                                    <CloseOutlined onClick={() => {
                                        let obj = [...productLabelList]
                                        obj.splice(index, 1)
                                        setProductLabelList(obj)
                                        setCheckFlag(-1)
                                    }} />
                                </div>
                            })
                        }
                    </div>
                    <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop: "20px", marginBottom: "20px" }}></div>
                    <div style={{ marginTop: "10px" }}>
                        <div><Text style={{ fontSize: "16px" }}>过滤商品标记</Text></div>
                        {operationStatueList.map(item => {
                            return <div style={{ marginTop: "10px", display: 'grid', gridTemplateColumns: '90px 1fr' }}>
                                <div style={{ width: "90px" }}>{item.group}</div>
                                <div>
                                    {
                                        item.tags.map(tags => {
                                            return <Checkbox value={tags} checked={JSON.stringify(chooseOperationStatue).indexOf(JSON.stringify(tags)) != -1 ? true : false} onChange={(e) => {
                                                if (e.target.checked) {
                                                    let obj = [...chooseOperationStatue]
                                                    obj.push(tags)
                                                    setChooseOperationStatue(obj)
                                                } else {
                                                    let obj = [...chooseOperationStatue]
                                                    // obj.remove(tags)
                                                    obj.map((tag, index) => {
                                                        if (tag.id == tags.id) {
                                                            obj.splice(index, 1)
                                                        }
                                                    })
                                                    setChooseOperationStatue(obj)
                                                }
                                                setCheckFlag(-1)
                                            }}
                                                style={{ marginLeft: "0px", marginRight: '10px' }}>{tags.name}</Checkbox>
                                        })
                                    }
                                </div>
                                <div>
                                    {/* {
                               operationStatueList.map(item => {
                                   return <Checkbox value={item} checked={chooseOperationStatue.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                       if (e.target.checked) {
                                           let obj = [...chooseOperationStatue]
                                           obj.push(item)
                                           setChooseOperationStatue(obj)
                                       } else {
                                           let obj = [...chooseOperationStatue]
                                           obj.remove(item)
                                           setChooseOperationStatue(obj)
                                       }
                                       setCheckFlag(-1)
                                   }}
                                   style={{marginLeft:"0px",marginRight:'10px'}}>{item}</Checkbox>
                               })
                           } */}
                                </div>
                            </div>

                        })}
                    </div>
                    <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "20px", marginBottom: "20px" }}></div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <div>
                            <a style={{ textDecoration: 'underline', marginTop: '2px', display: followActivityId == null ? 'none' : '' }} onClick={() => {
                                searchGoodsFilterLabelAndCondition(followActivityId, followPlanId, 1)
                            }}>沿用商品</a>
                        </div>
                        <div style={{ display: "flex" }}>
                            {/* <Badge size="default" color="green" count={111} text={"校验通过"} /> */}
                            {
                                checkFlag == 0 ?
                                    <div style={{ display: 'flex' }}>
                                        <CloseCircleFilled style={{ fontSize: "16px", color: "red", marginTop: "10px", marginRight: "5px" }} /><Text style={{ marginTop: "7px" }}>校验不通过</Text>
                                    </div> :
                                    checkFlag == -1 ?
                                        <div style={{ display: 'flex' }}>
                                            <ExclamationCircleFilled style={{ fontSize: "16px", color: "orange", marginTop: "10px", marginRight: "5px" }} /><Text style={{ marginTop: "7px" }}>待校验</Text>
                                        </div> :
                                        <div style={{ display: 'flex' }}>
                                            <CheckCircleFilled style={{ fontSize: "16px", color: "green", marginTop: "10px", marginRight: "5px" }} /><Text style={{ marginTop: "7px" }}>校验通过</Text>
                                        </div>

                            }

                            <Button style={{ marginLeft: "36px", borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center' }} onClick={() => {
                                let dataProps1 = {
                                    roleId: cookie.load('role'),
                                    shopList: cookie.load('shopList'),
                                    shop_name: props.history.location.pathname.split('/')[2],
                                }
                                NbAxios(dataProps1, "POST", servicePath.searchPricingRulesIndex).then(res => {
                                    if (res.success) {
                                        setRelatedIndex(res.data['相关指标']);
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                }).catch(err => {
                                    message.warn('查询调价规则相关指标接口出错,请联系IT')
                                })
                                if (productLabelList.length == 0) {
                                    message.warn('请圈选商品最大范围！')
                                } else {
                                    // let dataProps = {
                                    //     roleId: cookie.load('role'),
                                    //     shopList: cookie.load('shopList'),
                                    //     shop_name: props.history.location.pathname.split('/')[2],
                                    //     activity_id: activityId,
                                    //     plan_id: planId,
                                    //     goods_filter_add: productLabelList,
                                    //     goods_filter_cut: chooseOperationStatue,
                                    //     compare_goods_time: compareGoodsTime,
                                    //     check_flag: 0
                                    // }
                                    // setLoading(true)
                                    // console.log("入参",dataProps);
                                    // NbAxios(dataProps, "POST", servicePath.checkPlanCondition).then(res => {
                                    //     setLoading(false)
                                    //     if (res.success) {
                                    //         setCompareGoodsTime(res.data.compare_goods_time);
                                    //         setNowGroupData(res.data['分组匹配关系'])
                                    //         setCheckProductDict(res.data);
                                    //         console.log(res.data);
                                    //         setCheckPacket(true)
                                    //     } else {
                                    //         message.warn(res.errorMsg)
                                    //     }
                                    // }).catch(err => {
                                    //     setLoading(false)
                                    //     message.warn('保存并校验计划框选商品条件接口,请联系IT')
                                    // })
                                    retainCheckConditions(activityId, planId)
                                }

                            }}>校验商品分组匹配</Button>
                        </div>
                    </div>
                </Spin>
                <div className={style.bottomButton}>
                    <Button type="primary" onClick={() => setCurrentStep(1)} disabled={checkFlag == -1 || checkFlag == 0 ? true : false}>下一步</Button>
                </div>
            </div>
            <div style={{ width: '980px', margin: "0 auto", paddingTop: "10px", display: currentStep == 1 ? '' : "none" }}>
                <Spin spinning={loading} tip="数据加载中">
                    <div style={{ display: 'grid', gridTemplateColumns: "50% 50%" }}>
                        <div style={{ paddingTop: "18px", paddingLeft: "51px" }}>
                            <div style={{ fontSize: "16px", display: 'flex' }}>
                                <div>设定专区</div>
                                <Popover placement="right" title={''} content={"\"90\"表示9折"} trigger="hover">
                                    <QuestionCircleOutlined style={{ marginLeft: '10px', fontSize: "15px", marginTop: '2px' }} />
                                </Popover>
                            </div>
                            <div style={{ padding: '10px 10px 10px 0px' }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', width: "300px" }}>
                                        <Input showCount style={{ width: "100px", height: "30px", padding: "2px" }} value={time} maxLength={8} onChange={(e) => {
                                            setTime(e.target.value)
                                        }} />
                                        <Input style={{ width: "40px", height: "30px", marginLeft: "10px", padding: "2px" }} value={piece} onChange={(e) => {
                                            let reg = new RegExp("^[0-9]*$");
                                            if (reg.test(e.target.value)) {
                                                setPiece(e.target.value)
                                            } else {
                                                message.warning("只能输入数字")
                                            }
                                        }} />
                                        <div style={{ marginLeft: "5px", marginRight: "5px", lineHeight: "30px" }}>件</div>
                                        <Input style={{ width: "40px", height: "30px", padding: "2px" }} value={fold} onChange={(e) => {
                                            let reg = new RegExp("^[0-9]*$");
                                            if (reg.test(e.target.value)) {
                                                setFold(e.target.value)
                                            } else {
                                                message.warning("只能输入数字")
                                            }
                                        }} />
                                        <div style={{ marginLeft: "5px", marginRight: "5px", lineHeight: "30px" }}>折</div>
                                    </div>
                                    <div style={{ display: isBound ? 'flex' : 'none', width: "205px" }}>
                                        <div style={{ marginRight: "5px", lineHeight: "30px" }}>绑</div>
                                        <Input style={{ width: "90px", height: "30px", padding: "2px" }} value={boundtime} onChange={(e) => {
                                            setBoundtime(e.target.value)
                                        }} />
                                        <Input style={{ width: "40px", height: "30px", marginLeft: "10px", padding: "2px" }} value={boundpiece} onChange={(e) => {
                                            setBoundpiece(e.target.value)
                                        }} />
                                        <div style={{ marginLeft: "5px", marginRight: "5px", lineHeight: "30px" }}>件</div>
                                        <Input style={{ width: "40px", height: "30px", padding: "2px" }} value={boundfold} onChange={(e) => {
                                            setBoundfold(e.target.value)
                                        }} />
                                        <div style={{ marginLeft: "5px", marginRight: "5px", lineHeight: "30px" }}>折</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Button size="small" style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center' }} onClick={() => {
                                    if (official == 1) {
                                        if (isBound) {
                                            if (time == '' || piece == '' || fold == '' || boundtime == '' || boundpiece == '' || boundfold == '') {
                                                message.warn('请正确填写专区')
                                            } else if(fold>100 || boundfold>100){
                                                message.warn('折扣不能超出100')
                                                return false
                                            } else {
                                                let zoneListObj = [...zoneList]
                                                zoneListObj.push(`官方 ${time} ${piece}件${fold}折 - ${boundtime} ${boundpiece}件${boundfold}折`)
                                                setZoneList(zoneListObj)
                                            }
                                        } else {
                                            if (time == '' || piece == '' || fold == '') {
                                                message.warn('请正确填写专区')
                                            } else if(fold>100){
                                                message.warn('折扣不能超出100')
                                                return false
                                            } else {
                                                let zoneListObj = [...zoneList]
                                                zoneListObj.push(`官方 ${time} ${piece}件${fold}折`)
                                                setZoneList(zoneListObj)
                                            }
                                        }
                                    } else {
                                        if (isBound) {
                                            if (time == '' || piece == '' || fold == '' || boundtime == '' || boundpiece == '' || boundfold == '') {
                                                message.warn('请正确填写专区')
                                            } else if(fold>100 || boundfold>100){
                                                message.warn('折扣不能超出100')
                                                return false
                                            } else {
                                                let zoneListObj = [...zoneList]
                                                zoneListObj.push(`${time} ${piece}件${fold}折 - ${boundtime} ${boundpiece}件${boundfold}折`)
                                                setZoneList(zoneListObj)
                                            }
                                        } else {
                                            if (time == '' || piece == '' || fold == '') {
                                                message.warn('请正确填写专区')
                                            } else if(fold>100){
                                                message.warn('折扣不能超出100')
                                                return false
                                            } else {
                                                let zoneListObj = [...zoneList]
                                                zoneListObj.push(`${time} ${piece}件${fold}折`)
                                                setZoneList(zoneListObj)
                                            }
                                        }
                                    }
                                    setTime('')
                                    setPiece('')
                                    setFold('')
                                    setBoundtime('')
                                    setBoundpiece('')
                                    setBoundfold('')
                                    setIsBound(false)
                                    setOfficial(0)
                                }}>添加专区</Button>
                                {
                                    isBound ? <Button size="small" style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center', marginLeft: "13px" }} onClick={() => {
                                        setIsBound(false)
                                    }}>解除绑定</Button> : <Button size="small" style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center', marginLeft: "13px" }} onClick={() => {
                                        setIsBound(true)
                                    }}>添加绑定</Button>
                                }
                                <div>
                                    <Checkbox style={{ marginLeft: "10px" }} checked={official == 1 ? true : false} onChange={(e) => {
                                        if (e.target.checked) {
                                            setOfficial(1)
                                        } else {
                                            setOfficial(0)
                                        }
                                    }}>官方活动</Checkbox>
                                </div>
                            </div>
                            <div style={{ width: "calc(980px/2)", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "20px", marginBottom: "20px" }}></div>
                            <div style={{ width: "calc(980px/2)", maxHeight: "170px", padding: "10px" }}>
                                <div style={{ maxHeight: "110px", display: "flex", flexWrap: "wrap", alignContent: 'flex-start' }}>
                                    {
                                        zoneList.map((item, index) => {
                                            return <div style={{ border: "1px solid #0091FF", height: "24px", marginRight: '20px', borderRadius: "3px", marginTop: "5px", display: 'flex' }}>
                                                <div style={{ lineHeight: "24px" }}>{item}</div>
                                                <div style={{ lineHeight: "24px" }}><CloseOutlined onClick={() => {
                                                    let zoneListObj = [...zoneList]
                                                    zoneListObj.splice(index, 1)
                                                    setZoneList(zoneListObj)
                                                }} /></div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{ width: "calc(980px/2)", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "10px", marginBottom: "10px" }}></div>
                            <a style={{ textDecoration: 'underline', display: followActivityId == null ? 'none' : '' }} onClick={() => searchPlanDiscountsAndVip(followActivityId, followPlanId)}>沿用专区</a>
                            {/* 暂不支持 */}
                            {/* <div style={{ fontSize: "16px", padding: "10px 10px 10px 0px" }}>
                                设定会员
                            </div>
                            <div style={{ padding: "10px 10px 10px 0px" }}>
                                {
                                    memberPrice.map(item => {
                                        return <Checkbox value={item} checked={memberPriceChecked.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...memberPriceChecked]
                                                obj.push(item)
                                                setMemberPriceChecked(obj)
                                            } else {
                                                let obj = [...memberPriceChecked]
                                                obj.remove(item)
                                                setMemberPriceChecked(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div> */}
                        </div>
                        <div style={{ paddingTop: "18px", paddingLeft: "51px" }}>
                            <div style={{ fontSize: "16px" }}>设定优惠券</div>
                            <div style={{ padding: '10px 10px 10px 0px' }}>
                                <div style={{ display: "flex" }}>
                                    <Select style={{ width: "120px", height: "30px" }} value={chooseCoupon['coupon_name']} onChange={(value) => {
                                        setChooseCoupon(couponList.filter(item => item.coupon_name == value)[0])
                                        setCalculateType('满')
                                        setAntecedents('')
                                        setConsequence('')
                                    }}>
                                        {
                                            couponList.map(item => {
                                                return <Option value={item.coupon_name}>{item.coupon_name}</Option>
                                            })
                                        }
                                    </Select>
                                    <Select style={{ width: "70px", marginLeft: "5px", height: "30px" }} value={calculateType} onChange={(value) => setCalculateType(value)}>
                                        <Option value={"每满"}>{"每满"}</Option>
                                        <Option value={"满"}>{"满"}</Option>
                                    </Select>
                                    <Input style={{ marginLeft: "5px", width: "64px", height: "30px" }} value={antecedents} onChange={(e) => setAntecedents(e.target.value)} />
                                    <div style={{ marginLeft: "5px", marginRight: "5px", lineHeight: "30px" }}>减</div>
                                    <Input style={{ width: "64px", height: "30px" }} value={consequence} onChange={(e) => setConsequence(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Button size="small" style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center' }} onClick={() => {
                                    if (JSON.stringify(chooseCoupon) == "{}" || calculateType == '' || antecedents == '' || consequence == '') {
                                        message.warn('请正确填写优惠券')
                                    } else {
                                        // let logo_color = chooseCoupon.split('-')[1]
                                        // let coupon_name = chooseCoupon.split('-')[0]
                                        let errflag = 0
                                        coupon.map(item => {
                                            if (item['coupon_name'] == chooseCoupon['coupon_name'] && item.calculate_type == calculateType && item.antecedents == antecedents && item.consequence == consequence) {
                                                errflag = 1
                                            }
                                        })
                                        if (errflag) {
                                            message.warn('该优惠券已存在')
                                        } else {
                                            let couponObj = [...coupon]
                                            let newCouponObj = JSON.parse(JSON.stringify(chooseCoupon))
                                            newCouponObj['calculate_type'] = calculateType
                                            newCouponObj['antecedents'] = antecedents
                                            newCouponObj['consequence'] = consequence
                                            delete newCouponObj["create_time"]
                                            delete newCouponObj["creator_email"]
                                            delete newCouponObj["last_operate_time"]
                                            delete newCouponObj["last_operator_email"]
                                            couponObj.push(newCouponObj)
                                            setCoupon(couponObj)
                                            setChooseCoupon({ 'coupon_name': '' })
                                            setCalculateType('满')
                                            setAntecedents('')
                                            setConsequence('')
                                        }

                                    }
                                }}>添加优惠券</Button>
                            </div>
                            <div style={{ width: "calc(980px/2)", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "20px", marginBottom: "20px" }}></div>
                            <div style={{ width: "calc(980px/2)", maxHeight: "170px", padding: "10px" }}>
                                <div style={{ maxHeight: "110px", display: "flex", flexWrap: "wrap", alignContent: 'flex-start' }}>
                                    {
                                        coupon.map((item, index) => {
                                            return <div style={{ border: `1px solid ${item.logo_color}`, height: "30px", marginRight: '20px', padding: "3px", borderRadius: "8px", marginTop: "5px", display: 'flex' }}>
                                                <div style={{ backgroundColor: item.logo_color, color: "#fff", borderRadius: "5px", fontSize: '12px', padding: "2px" }}>{item.coupon_name}</div>
                                                <div style={{ marginLeft: '14px', display: item.calculate_type == '满' ? 'none' : '' }}>{item.calculate_type}</div>
                                                <div style={{ marginLeft: item.calculate_type == '满' ? '14px' : '0px' }}>{item.antecedents}</div>
                                                <div>{'-'}</div>
                                                <div>{item.consequence}</div>
                                                <div style={{ marginLeft: '10px', marginRight: "2px" }}><CloseOutlined onClick={() => {
                                                    let couponObj = [...coupon]
                                                    couponObj.splice(index, 1)
                                                    setCoupon(couponObj)
                                                }} /></div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{ width: "calc(980px/2)", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', height: "2px", marginTop: "20px", marginBottom: "20px" }}></div>
                            <div style={{ display: 'flex' }}>
                                <a style={{ textDecoration: 'underline' }} onClick={() => setManageCoupon(true)}>管理优惠券</a>
                                <a style={{ textDecoration: 'underline', marginLeft: '20px', display: followActivityId == null ? 'none' : '' }} onClick={() => {
                                    searchPlanCoupons(followActivityId, followPlanId)
                                }}>沿用优惠券</a>
                            </div>
                        </div>
                    </div>
                </Spin>
                <div className={style.bottomButton} style={{marginTop:'20px'}}>
                    <Button disabled={loading} type="primary" style={{ marginRight: "20px" }} onClick={() => {
                        setPlanBenefitPoint(0)
                    }}>上一步</Button>
                    <Button disabled={loading} type="primary" onClick={() => {
                        setPlanBenefitPoint(2)
                    }}>下一步</Button>
                </div>
            </div>
            <div style={{ width: '980px',margin:'0 auto',paddingTop: "10px", display: currentStep == 2 ? '' : "none" }}>
                <Spin spinning={loading} tip="数据加载中">
                    {/* <div style={{ display: 'grid', gridTemplateColumns: "60% 40%" }}> */}
                    {/* , borderRadius: "20px", border: "1px solid #ccc",  */}
                    <div className={style.displayContent} style={{ width: "100%", padding: "10px 10px 108px 10px" }}>
                        {/* <div style={{ fontSize: "12px" }}>商品信息</div>
                        <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop: "5px", height: "2px", marginBottom: "10px" }}></div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'self-start', flexWrap: 'wrap' }}>
                            {
                                commodityBasisList.map((item, index) => {
                                    return <Checkbox checked={chooseCommodityBasis.indexOf(item) != -1 ? true : false} style={{ width: "120px", marginLeft: "5px" }} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...chooseCommodityBasis]
                                            obj.push(item)
                                            setChooseCommodityBasis(obj)
                                        } else {
                                            let obj = [...chooseCommodityBasis]
                                            obj.remove(item)
                                            setChooseCommodityBasis(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div> */}
                        <div style={{ fontSize: "12px", marginTop: "10px", display: followActivityId == null ? 'none' : '' }}>沿用活动的销售情况</div>
                        <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop: "5px", height: "2px", marginBottom: "10px", display: followActivityId == null ? 'none' : '' }}></div>
                        <div style={{ justifyContent: 'flex-start', alignItems: 'self-start', flexWrap: 'wrap', display: followActivityId == null ? 'none' : 'flex' }}>
                            {
                                saleList.map((item, index) => {
                                    return <Checkbox checked={chooseSale.indexOf(item) != -1 ? true : false} style={{ width: "120px", marginLeft: "5px" }} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...chooseSale]
                                            obj.push(item)
                                            setChooseSale(obj)
                                        } else {
                                            let obj = [...chooseSale]
                                            obj.remove(item)
                                            setChooseSale(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                        <div style={{ fontSize: "12px", marginTop: "10px" }}>近期销售情况</div>
                        <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop: "5px", height: "2px", marginBottom: "10px" }}></div>
                        <div style={{ display: 'flex', marginTop: "10px", justifyContent:'space-between',alignItems:'center'}}>
                            <div style={{ fontSize: '12px', marginRight: "16px" }}>选择时间维度:</div>
                            <div style={{ display: 'flex' }}>
                                {
                                    recentTimeList.map((item, index) => {
                                        return <Checkbox checked={chooseRecentTime.indexOf(item) != -1 ? true : false} style={{ width: "120px", marginLeft: "5px" }} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...chooseRecentTime]
                                                obj.push(item)
                                                setChooseRecentTime(obj)
                                            } else {
                                                let obj = [...chooseRecentTime]
                                                obj.remove(item)
                                                setChooseRecentTime(obj)
                                            }
                                        }}>{item}</Checkbox>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'self-start', flexWrap: 'wrap', marginTop: '10px' }}>
                            {
                                recentSaleList.map((item, index) => {
                                    return <Checkbox checked={chooseRecentSale.indexOf(item) != -1 ? true : false} style={{ width: "120px", marginLeft: "5px" }} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...chooseRecentSale]
                                            obj.push(item)
                                            setChooseRecentSale(obj)
                                        } else {
                                            let obj = [...chooseRecentSale]
                                            obj.remove(item)
                                            setChooseRecentSale(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                        {/* <div style={{ fontSize: "12px", marginTop: "10px" }}>商品标记</div>
                        <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop: "5px", height: "2px", marginBottom: "10px" }}></div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'self-start', flexWrap: 'wrap', marginTop: '10px' }}>
                            {
                                productMarkList.map((item, index) => {
                                    return <Checkbox checked={chooseProductMark.indexOf(item) != -1 ? true : false} style={{ marginLeft: "5px" }} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...chooseProductMark]
                                            obj.push(item)
                                            setChooseProductMark(obj)
                                        } else {
                                            let obj = [...chooseProductMark]
                                            obj.remove(item)
                                            setChooseProductMark(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div> */}
                        {/* </div> */}
                        {/* , borderRadius: "20px", border: "1px solid #ccc"  */}
                        {/* <div className={style.displayContent} style={{ width: "100%", padding: "10px 10px 108px 10px"}}> */}
                        <div style={{ marginTop: "14px", display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: "12px", lineHeight: '22px' }}>指定活动计划的定价情况</div>
                            <Button size="small" style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center' }} onClick={() => setAddPlan(true)}>添加计划</Button>
                        </div>
                        <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop: "5px", height: "2px", marginBottom: "10px" }}></div>
                        <div style={{ marginTop: "10px", padding: "10px", display: 'flex', flexDirection: "column", maxHeight: "100px", overflow: 'auto' }}>
                            {
                                pricingList['活动名称'].map((item, index) => {
                                    return <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "2px" }}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "200px", marginRight: "10px" }}>
                                                <Tooltip title={item} placement="topLeft">
                                                    <span>{item}</span>
                                                </Tooltip>
                                            </div>
                                            <div style={{ overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "200px" }}>
                                                <Tooltip title={pricingList['计划名称'][index]} placement="topLeft">
                                                    <span>{pricingList['计划名称'][index]}</span>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div>
                                            <DeleteOutlined onClick={() => {
                                                pricingList['计划名称'].splice(index, 1)
                                                pricingList['活动名称'].splice(index, 1)
                                            }} />
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'self-start', flexWrap: 'wrap', marginTop: "5px" }}>
                            {
                                pricingList['fields'].map((item, index) => {
                                    return <Checkbox size="small" checked={choosePricing.indexOf(item) != -1 ? true : false} style={{ width: "110px", marginLeft: "5px" }} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...choosePricing]
                                            obj.push(item)
                                            setChoosePricing(obj)
                                        } else {
                                            let obj = [...choosePricing]
                                            obj.remove(item)
                                            setChoosePricing(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                        <div style={{ marginTop: "14px", display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: "12px", lineHeight: '22px' }}>指定活动的销售情况</div>
                            <Button size="small" style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center' }} onClick={() => setSearchActivity(true)}>添加活动</Button>
                        </div>
                        <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop: "5px", height: "2px", marginBottom: "10px" }}></div>
                        <div style={{ marginTop: "10px", padding: "10px", display: 'flex', flexDirection: "column", maxHeight: "100px", overflow: 'auto' }}>
                            {
                                activitySaleList['活动名称'].map((item, index) => {
                                    return <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "2px" }}>
                                        <div style={{ overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "300px", marginRight: "10px" }}>
                                            <Tooltip title={item} placement="topLeft">
                                                <span>{item}</span>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <DeleteOutlined onClick={() => {
                                                activitySaleList['活动名称'].splice(index, 1)
                                            }} />
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'self-start', flexWrap: 'wrap' }}>
                            {
                                activitySaleList['fields'].map((item, index) => {
                                    return <Checkbox checked={chooseEventSales.indexOf(item) != -1 ? true : false} style={{ width: "120px", marginLeft: "5px" }} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...chooseEventSales]
                                            obj.push(item)
                                            setChooseEventSales(obj)
                                        } else {
                                            let obj = [...chooseEventSales]
                                            obj.remove(item)
                                            setChooseEventSales(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                        {/* <div style={{ marginTop: "14px", display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: "12px", lineHeight: '22px' }}>库存数据</div>
                        </div>
                        <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop: "5px", height: "2px", marginBottom: "10px" }}></div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'self-start', flexWrap: 'wrap' }}>
                            {
                                inventoryDataList.map((item, index) => {
                                    return <Checkbox checked={chooseInventoryData.indexOf(item) != -1 ? true : false} style={{ width: "120px", marginLeft: "5px" }} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...chooseInventoryData]
                                            obj.push(item)
                                            setChooseInventoryData(obj)
                                        } else {
                                            let obj = [...chooseInventoryData]
                                            obj.remove(item)
                                            setChooseInventoryData(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div> */}
                        <div style={{ marginTop: "14px", display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: "12px", lineHeight: '22px' }}>校验时间段内活动最低价</div>
                        </div>
                        <div style={{ width: "100%", backgroundImage: 'linear-gradient(to right, #0091FF , #fff)', marginTop: "5px", height: "2px", marginBottom: "10px" }}></div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'self-start', flexWrap: 'wrap' }}>
                            {
                                activityLowestPriceList.map((item, index) => {
                                    return <Checkbox checked={chooseActivityLowestPrice.indexOf(item) != -1 ? true : false} style={{ width: "120px", marginLeft: "5px" }} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...chooseActivityLowestPrice]
                                            obj.push(item)
                                            setChooseActivityLowestPrice(obj)
                                        } else {
                                            let obj = [...chooseActivityLowestPrice]
                                            obj.remove(item)
                                            setChooseActivityLowestPrice(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                    </div>
                    {/* </div> */}
                </Spin>

                <div className={style.bottomButton} >
                    <Button type="primary" style={{ marginRight: "20px" }} onClick={() => createChooseAndpricing(0)}>上一步</Button>
                    <Button type="primary" onClick={() => createChooseAndpricing(1)}>完成</Button>
                </div>
            </div>
            <Modal
                title="校验商品分组匹配"
                visible={checkPacket}
                onCancel={() => {
                    setCheckPacket(false)
                }}
                onOk={() => {
                    // 校验通过 直接关闭
                    if (checkFlag == 1) {
                        setCheckPacket(false)
                    } else {
                        // let dataProps = {
                        //     roleId: cookie.load('role'),
                        //     shopList: cookie.load('shopList'),
                        //     shop_name: props.history.location.pathname.split('/')[2],
                        //     activity_id: activityId,
                        //     plan_id: planId,
                        //     compare_goods_time: compareGoodsTime,
                        //     check_data: {
                        //         "fields_be_used": checkProductDict['fields_be_used'],
                        //         "分组匹配关系": nowGroupData
                        //     },
                        // }
                        // NbAxios(dataProps, "POST", servicePath.updateGroupMatchingRelationship).then(res => {
                        //     if (res.success) {
                        //         setCheckFlag(res.data.check_flag)
                        //         setCompareGoodsTime(res.data.compare_goods_time)
                        //         setNowGroupData(res.data['分组匹配关系'])
                        //     } else {
                        //         message.warn(res.errorMsg)
                        //     }
                        // }).catch(err => {
                        //     message.warn('更新校验商品对标的分组匹配关系,请联系IT')
                        // })
                        verifyGroupMatching(activityId, planId, fieldsBeUsed)
                    }

                }}
                centered={true}
                okText={checkFlag == 1 ? "确认" : "校验匹配"}
                cancelText={"取消"}
                width={860}
            >
                <Spin spinning={loading} tip="数据加载中.....">
                    <div style={{ display: 'flex', marginLeft: '31px', justifyContent: 'space-between' }}>
                        <div style={{ lineHeight: '32px' }}>指定两个分组进行对应，组内的排名顺序一一匹配，确定匹配关系</div>
                        <div style={{ display: 'flex' }}>
                            <Button style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center' }} onClick={() => {
                                searchPlanMatchingRelationships(activityId, planId)
                            }}>查看当前匹配</Button>
                        </div>
                    </div>
                    <div style={{ marginLeft: '31px', marginTop: "10px", display: 'grid', gridTemplateColumns: "50% 50%" }}>
                        <div style={{ padding: "10px" }}>
                            <div>相关指标</div>
                            <div>
                                {
                                    relatedIndex.map((item, index) => {
                                        return <div>
                                            <div style={{ marginTop: index == 0 ? '22px' : "14px" }}>
                                                <Tag color={item.color}>{item.name}</Tag>
                                            </div>
                                            <div style={{ marginTop: "12px" }}>
                                                <Select style={{ width: '300px' }} mode="multiple" value={item.value}>
                                                    {
                                                        item.value.map(item => {
                                                            return <option value={item}>{item}</option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <Text style={{ fontSize: "16px" }}>选择对标组时间</Text>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <Text style={{ fontSize: "14px" }}>根据时间获取全量商品排行数据得到对标组的商品。</Text>
                            </div>
                            <div style={{ marginTop: "10px", display: 'flex' }}>
                                <DatePicker defaultValue={compareGoodsTime == '' ? '' : moment(compareGoodsTime, dateFormat)} style={{ height: 24, marginRight: 20 }} locale={locale} onChange={(date, dateString) => {
                                    if (dateString == '') {
                                        message.warn('请选择合理的时间')
                                        return false
                                    } else {
                                        setCompareGoodsTime(dateString);
                                        setCheckFlag(-1)
                                    }
                                }} />
                                {/* <Button style={{ border: '1px solid #C8EAFF', color: "#2A82E4" }} size="small" onClick={() => {
                                    let dataProps = {
                                        roleId: cookie.load('role'),
                                        shopList: cookie.load('shopList'),
                                        shop_name: props.history.location.pathname.split('/')[2],
                                        activity_id: activityId,
                                        plan_id: planId,
                                        goods_filter_add: productLabelList,
                                        goods_filter_cut: chooseOperationStatue,
                                        compare_goods_time: compareGoodsTime,
                                        check_flag: 0
                                    }
                                    setLoading(true)
                                    console.log(dataProps);
                                    NbAxios(dataProps, "POST", servicePath.checkPlanCondition).then(res => {
                                        setLoading(false)
                                        if (res.success) {
                                            setNowGroupData(res.data['分组匹配关系'])
                                            setCheckFlag(res.data['cheak_flag'])
                                            setCheckProductDict(res.data);
                                        } else {
                                            message.warn(res.errorMsg)
                                        }
                                    }).catch(err => {
                                        setLoading(false)
                                        message.warn('保存并校验计划框选商品条件接口,请联系IT')
                                    })
                                }}>确认</Button> */}
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <div>商品分组匹配校验状态</div>
                                {
                                    checkFlag == 0 ? <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <CloseCircleFilled style={{ fontSize: "16px", color: "red", marginRight: "8px", marginTop: '2px', marginLeft: "20px" }} /><Text>校验不通过</Text>
                                    </div> :
                                        checkFlag == -1 ? <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ExclamationCircleFilled style={{ fontSize: "16px", color: "orange", marginRight: "8px", marginTop: '2px', marginLeft: "20px" }} /><Text>待校验</Text>
                                        </div> :
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CheckCircleFilled style={{ fontSize: "16px", color: "green", marginRight: "8px", marginTop: '2px', marginLeft: "20px" }} /><Text>校验通过</Text>
                                            </div>
                                }
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "grid", gridTemplateColumns: '80px 1fr' }}>
                                <div style={{ width: "87px", marginTop: "5px" }}>
                                    分组字段
                                </div>
                                <div>
                                    <Select
                                        mode="multiple"
                                        showArrow
                                        tagRender={tagRender}
                                        // defaultValue={checkProductDict['fields_be_used']}
                                        value={fieldsBeUsed}
                                        style={{ width: "280px", marginLeft: "29px" }}
                                        onChange={(value) => {
                                            setFieldsBeUsed(value)
                                            console.log(value);
                                            // checkProductDict['fields_be_used'] = value
                                            setCheckFlag(0)
                                            // console.log(checkProductDict['fields_be_used']);
                                        }}
                                    >
                                        {
                                            relatedIndex.map((item, index) => {
                                                let data = []
                                                if (item.name != '全量商品排行-14天') {
                                                    item.value.map(obj => {
                                                        data.push(obj)
                                                    })
                                                }
                                                return data.map((item) => {
                                                    return <Option value={item}>{item}</Option>
                                                })
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: "30px", justifyContent: 'center' }}>
                                {/* <div style={{ width: "90px", marginTop: "25px" }}>
                                    分组匹配关系
                                </div> */}
                                <div style={{ width: "calc(100% - 10px)" }}>
                                    <div style={{ display: "flex", justifyContent: 'space-around', width: "100%" }}>
                                        <div>当前组</div>
                                        <div>对标组</div>
                                    </div>
                                    <div style={{ height: '350px', overflow: "auto" }}>
                                        {
                                            nowGroupData.map((item, index) => {
                                                return <div style={{ display: "flex", justifyContent: 'space-around', width: "100%", marginTop: '5px', alignItems: "center" }}>
                                                    <span style={{ width: "170px", borderRadius: "3px", display: 'flex', justifyContent: 'center' }}>{item['当前组']}</span>
                                                    <div>{">"}</div>
                                                    {/* <Cascader onChange={(value) => {
                                                        let obj = [...nowGroupData]
                                                        obj[index]['对标组'] = value
                                                        setNowGroupData(obj)
                                                        setCheckFlag(-1)
                                                    }} value={item['对标组']} expandTrigger="hover" style={{ width: "170px", borderRadius: "3px" }} options={checkProductOption} placeholder="请选择对标组" /> */}
                                                    <Select style={{ width: "170px", borderRadius: "3px" }} key={index} allowClear showSearch onChange={(value) => {
                                                        console.log(value);
                                                        let obj = [...nowGroupData]
                                                        obj[index]['对标组'] = value
                                                        setNowGroupData(obj)
                                                        setCheckFlag(-1)

                                                    }}
                                                        value={item['对标组'].length == 0 ? [] : item['对标组'][1]}
                                                    >
                                                        {checkProductOption.map(item => {
                                                            return (
                                                                <OptGroup label={item.label}>
                                                                    {item.children.map(obj => {
                                                                        return (
                                                                            <Option value={[item.value, obj.value]} >{obj.label}</Option>
                                                                        )
                                                                    })}
                                                                </OptGroup>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            })
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>

            </Modal>
            <Modal
                title="管理优惠券"
                visible={manageCoupon}
                onCancel={() => setManageCoupon(false)}
                onOk={() => {
                    let flag = 0
                    mutuallyCoupon.map(item => {
                        if (item.coupon1 == item.coupon2) {
                            flag = 1
                        }
                    })
                    if (flag) {
                        message.warn('请选择不同的互斥券')
                    } else {
                        addCouponData.map((item, index) => {
                            item['shop_name'] = props.history.location.pathname.split('/')[2]
                            item['last_operator_email'] = cookie.load('name')
                            item['last_operate_time'] = moment().format('YYYY-MM-DD')
                            item['index'] = index
                        })
                        mutuallyCoupon.map((item, index) => {
                            item['shop_name'] = props.history.location.pathname.split('/')[2]
                            item['creator_email'] = cookie.load('name')
                            item['create_time'] = moment().format('YYYY-MM-DD')
                            item['index'] = index
                        })
                        let coupons_data = {
                            'coupons': addCouponData,
                            'coupons_mutex': mutuallyCoupon,
                        }
                        let dataProps = {
                            roleId: cookie.load('role'),
                            shopList: cookie.load('shopList'),
                            shop_name: props.history.location.pathname.split('/')[2],
                            coupons_data: coupons_data,
                        }
                        setLoading(true)
                        NbAxios(dataProps, "POST", servicePath.manageChooseAndPricingCoupons).then(res => {
                            setLoading(false)
                            if (res.success) {
                                addCouponData.map((couponsDataItem) => {
                                    coupon.map((couponItem, couponIndex) => {
                                        if (couponItem['coupon_name'] == couponsDataItem['coupon_name']) {
                                            coupon[couponIndex]['logo_color'] = couponsDataItem['logo_color']
                                        }
                                    })
                                })
                                message.success('优惠券管理成功')
                                searchChooseAndPricingCoupons()
                                setManageCoupon(false)
                            } else {
                                message.warn(res.errorMsg)
                            }
                        }).catch(err => {
                            setLoading(false)
                            message.warn('管理选款定价优惠券出错,请联系IT')
                        })
                    }
                }}
                okText="确定"
                cancelText="取消"
                width={"1200px"}
                centered={true}
                maskClosable={false}
            >
                <div style={{ display: 'grid', gridTemplateColumns: "49% 49%" }}>
                    <div style={{ marginRight: "5px" }}>
                        <a style={{ textDecoration: 'underline' }} onClick={() => {
                            let obj = [...addCouponData]
                            obj.push({
                                'coupon_name': '',
                                'coupon_short_name': '',
                                'is_stackable': 0,
                                'logo_color': '#000000ff'
                            })
                            setAddCouponData(obj)
                        }}>添加优惠券</a>
                        <Table loading={loading} size="small" pagination={false} columns={addCouponColumns}
                            dataSource={addCouponData} bordered
                        />
                    </div>
                    <div>
                        <a style={{ textDecoration: 'underline' }} onClick={() => {
                            let obj = [...mutuallyCoupon]
                            obj.push({
                                'coupon1': '',
                                'coupon2': '',
                            })
                            setMutuallyCoupon(obj)
                        }}>添加优惠券互斥</a>
                        <Table loading={loading} size="small" pagination={false} columns={mutuallyCouponColumns}
                            dataSource={mutuallyCoupon} bordered
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                title="添加计划"
                visible={addPlan}
                okText="确定"
                cancelText="取消"
                onOk={() => {
                    if (JSON.stringify(choosePlanDetail) == "{}") {
                        message.warn('请选择计划')
                    } else {
                        choosePlanDetail.map(item => {
                            pricingList['活动名称'].push(chooseActivityDetail.activity_name)
                            pricingList['计划名称'].push(item)
                        })
                        setActivityDetailList([])
                        setPlanDetailList([])
                        setStartDate('')
                        setEndDate('')
                        setAddPlan(false)
                    }
                }}
                onCancel={() => {
                    setActivityDetailList([])
                    setPlanDetailList([])
                    setStartDate('')
                    setEndDate('')
                    setAddPlan(false)
                }}
                centered={true}
                width={'800px'}
            >
                <div style={{ width: '100%' }}>
                    <div style={{ padding: "20px", display: 'flex' }}>
                        <Text style={{ fontSize: "16px", marginRight: "20px" }}>活动时间</Text>
                        <PlatformEventsCalendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                        <Button type="primary" size="small" style={{ marginLeft: "20px" }} onClick={() => {
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                search_start_time: startDate,
                                search_end_time: endDate,
                                activity_name: '',
                                plan_name: '',
                                flag: 1
                            }
                            NbAxios(dataProps, "POST", servicePath.chooseAndPricingPlanFirst).then(res => {
                                if (res.success) {
                                    setActivityDetailList(res.data);
                                }
                            })
                        }}>查询</Button>
                    </div>
                    <div style={{ padding: "20px", display: 'flex', justifyContent: "space-around" }}>
                        <div style={{ marginRight: "20px", height: "280px", width: "320px", border: "1px solid #ccc", borderRadius: "5px", overflowY: "auto" }}>
                            {
                                activityDetailList.map(item => {
                                    return <div style={{ padding: "10px", width: "300px", backgroundColor: chooseActivityDetail.activity_id == item.activity_id ? 'rgb(212,230,250)' : '#fff' }} onClick={() => {
                                        setChooseActivityDetail(item)
                                        setChoosePlanDetail([])
                                        let dataProps = {
                                            roleId: cookie.load('role'),
                                            shopList: cookie.load('shopList'),
                                            shop_name: props.history.location.pathname.split('/')[2],
                                            search_start_time: startDate,
                                            search_end_time: endDate,
                                            activity_id: item.activity_id,
                                            plan_name: '',
                                            flag: 2
                                        }
                                        NbAxios(dataProps, "POST", servicePath.chooseAndPricingPlanFirst).then(res => {
                                            if (res.success) {
                                                setPlanDetailList(res.data);
                                            }
                                        })
                                    }}>
                                        <div>
                                            <Text style={{ fontSize: '14px' }}>{item.activity_name}</Text>
                                        </div>
                                        <div>
                                            <Text style={{ fontSize: '12px' }} type="secondary">{item.activity_show_time}</Text>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{ marginRight: "20px", height: "280px", overflowY: "auto", width: "320px", border: "1px solid #ccc", borderRadius: "5px" }}>
                            {
                                planDetailList.map(item => {
                                    return <div style={{ padding: "10px", width: "300px", backgroundColor: choosePlanDetail.indexOf(item.plan_name) != -1 ? 'rgb(212,230,250)' : '#fff' }} onClick={() => {
                                        if (choosePlanDetail.indexOf(item.plan_name) != -1) {
                                            let obj = [...choosePlanDetail]
                                            obj.splice(choosePlanDetail.indexOf(item.plan_name), 1)
                                            setChoosePlanDetail(obj)
                                        } else {
                                            let choosePlanDetailObj = [...choosePlanDetail]
                                            choosePlanDetailObj.push(item.plan_name)
                                            setChoosePlanDetail(choosePlanDetailObj)
                                        }
                                    }}>
                                        <div>
                                            <Text style={{ fontSize: '14px' }}>{item.plan_name}</Text>
                                        </div>
                                        <div style={{ overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: "250px" }}>
                                            <Tooltip title={item.remarks} placement="topLeft">
                                                <Text style={{ fontSize: '12px' }} type="secondary">{item.remarks}</Text>
                                            </Tooltip>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                title="查询活动"
                visible={searchActivity}
                okText="确定"
                cancelText="取消"
                onOk={() => {
                    chooseActivityList.map(item => {
                        activitySaleList['活动名称'].push(item)
                    })
                    setSearchActivity(false)
                    setActivityDetailList([])
                    setChooseActivityList([])
                    setStartDate('')
                    setEndDate('')
                }}
                onCancel={() => {
                    setSearchActivity(false)
                    setActivityDetailList([])
                    setChooseActivityList([])
                    setStartDate('')
                    setEndDate('')
                }}
                centered={true}
                width={'800px'}
            >
                <div style={{ width: '100%' }}>
                    <div style={{ padding: "20px", display: 'flex', justifyContent: "center" }}>
                        <Text style={{ fontSize: "16px", marginRight: "20px" }}>活动时间</Text>
                        <PlatformEventsCalendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                        <Button type="primary" size="small" style={{ marginLeft: "20px" }} onClick={() => {
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shop_name: props.history.location.pathname.split('/')[2],
                                search_start_time: startDate,
                                search_end_time: endDate,
                                activity_name: '',
                                plan_name: '',
                                flag: 1
                            }
                            NbAxios(dataProps, "POST", servicePath.chooseAndPricingPlanFirst).then(res => {
                                if (res.success) {
                                    setActivityDetailList(res.data);
                                }
                            })
                        }}>查询</Button>
                    </div>
                    <div style={{ padding: "20px", display: 'flex', justifyContent: "center" }}>
                        <div style={{ marginRight: "20px", height: "280px", width: "320px", border: "1px solid #ccc", borderRadius: "5px", overflowY: "auto" }}>
                            {
                                activityDetailList.map((item, index) => {
                                    return <div style={{ padding: "10px", width: "300px", backgroundColor: chooseActivityList.indexOf(item.activity_name) != -1 ? 'rgb(212,230,250)' : '#fff' }} onClick={() => {
                                        if (chooseActivityList.indexOf(item.activity_name) != -1) {
                                            let obj = [...chooseActivityList]
                                            obj.splice(chooseActivityList.indexOf(item.activity_name), 1)
                                            setChooseActivityList(obj)
                                        } else {
                                            let obj = [...chooseActivityList]
                                            obj.push(item.activity_name)
                                            setChooseActivityList(obj)
                                        }

                                    }}>
                                        <div>
                                            <Text style={{ fontSize: '14px' }}>{item.activity_name}</Text>
                                        </div>
                                        <div>
                                            <Text style={{ fontSize: '12px' }} type="secondary">{item.activity_show_time}</Text>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}

export default Index