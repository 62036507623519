import React, { useEffect, useState } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
// 引入3D
import 'echarts-gl'
import { Divider , Radio} from 'antd'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

function Index({
    // 折线图数据
    lineChartData
    // // 指标
    // lineChartLegend,
    // // X轴
    // lineChartXAxis,
    // // 折线数据
    // lineChartSeries
}){

    const [ lineChartDataDict, setLineChartDataDict] = useState({})
    const [ radioList, setRadioList] = useState([])
    const [ radioValue, setRadioValue] = useState('')

    useEffect(()=>{
      // console.log(Object.keys(lineChartData).length );
      if( Object.keys(lineChartData).length != 0){
        setLineChartDataDict(lineChartData)
        setRadioList(lineChartData['radioList'])
        setRadioValue(lineChartData['radioList'][0])
      }
    },[lineChartData])

    useEffect(()=>{
      console.log(radioValue);
      if(radioValue!=''){
        console.log(lineChartDataDict);
        console.log(radioValue);
        console.log(lineChartDataDict[radioValue]['legend']);
        let report = echarts.init(document.getElementById('lineReport'))
        report.clear();    //清除残留渲染
        report.setOption( {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: lineChartDataDict[radioValue]['legend']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: lineChartDataDict[radioValue]['xAxis']
            },
            yAxis: {
              type: 'value'
            },
            series: lineChartDataDict[radioValue]['series']
        })
        window.addEventListener('resize', () => {
            report.resize()
        });
      }
    },[radioValue])
    // 表格是否展示
    const [ isShow , setIsShow ] = useState(1)

    return (
        <div>
          <Divider orientation="left">折线图</Divider>
          <div style={{display:'flex', justifyContent:'flex-end', marginRight:30}}>
            <Radio.Group value={radioValue} onChange={(e)=>{
              setRadioValue(e.target.value);
            }}>
              {
                radioList.map(item=>{
                    return <Radio value={item}>{item}</Radio>
                })
              }
            </Radio.Group>
          </div>
          <div style={{display:'grid', gridTemplateColumns:"10px 1fr"}}>
            {
                isShow == 0 ? <EyeOutlined style={{marginLeft:7, color:"#039be5", fontSize:20}} onClick={()=>{
                    setIsShow(1)
                }} />:
                <EyeInvisibleOutlined style={{marginLeft:7, color:"#039be5", fontSize:20}} onClick={()=>{
                    setIsShow(0)
                }} />
            }
            <div id="lineReport" style={{width:'calc(98vw - 17px)', height:500, backgroundColor:'#fff',marginLeft:20, display:isShow == 1 ? '' : 'none'}}></div>
          </div>
        </div>
    )

}

export default Index