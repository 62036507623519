import React, { useState, useEffect, useContext } from 'react'
import { Col, Row, Modal, Tabs, Typography, Pagination, Drawer, Form, Tag, List, Avatar, Button, Upload, message, Input, Descriptions, PageHeader } from 'antd';
import CursorItem from '../components/CursorItem'
import { SoundOutlined, ExclamationCircleOutlined, UploadOutlined, TeamOutlined, LeftOutlined } from '@ant-design/icons';
import './style.css'
import moment from 'moment'
import servicePath from '../../../config/apiUrl'
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios'
import { useTrail, animated } from 'react-spring'
import { StateContext } from '../../raceShop/store/stateShare'
const { TabPane } = Tabs;
const { TextArea, Search } = Input;
const { Text, Paragraph, Title } = Typography
const { CheckableTag } = Tag;

function TeachCourse(props) {
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    // 类型标签
    const [tabKey, setTabKey] = useState(0)
    // 关键字
    const [keyWord, setKeyWord] = useState('')
    // 课程总数
    const [total, setTotal] = useState(1000)
    // 当前页面课程
    const [currentCourse, setCurrentCourse] = useState([])
    // 当前总课程
    const [allData, setAllData] = useState([])
    // 全部课程
    const [ allCourse , setAllCourse ] = useState([])
    // IT课程
    const [itData, setItData] = useState([])
    // 店铺运营课程
    const [storeData, setStoreData] = useState([])
    // 商品经营课程
    const [commodityData, setCommodityData] = useState([])
    // 项目管理课程
    const [projectData, setProjectData] = useState([])
    //  视觉视频课程
    const [visualData, setVisualData] = useState([])
    // 财务知识课程
    const [financialData, setFinancialData] = useState([])
    // 人力资源课程
    const [humanData, setHumanData] = useState([])
    // 生活技能课程
    const [liftData, setLiftData] = useState([])
    // 整合营销课程
    const [integratedData, setIntegratedData] = useState([])
    // 当前页数
    const [page, setPage] = useState(1)
    // 每页数量
    const [pageSize, setPageSize] = useState(10)
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 全部
    const allTab = (<div>全部</div>)
    // 店铺运营
    const storeTab = (<div>店铺运营</div>)
    // 商品经营
    const commodityTab = (<div>商品经营</div>)
    // 信息技术
    const itTab = (<div>信息技术</div>)
    // 项目管理
    const projectTab = (<div>项目管理</div>)
    // 视觉视频
    const visualTab = (<div>视觉视频</div>)
    // 财务知识
    const financialTab = (<div>财务知识</div>)
    // 人力资源
    const humanTab = (<div>人力资源</div>)
    // 生活技能
    const liftTab = (<div>生活技能</div>)
    // 整合营销
    const integratedTab = (<div>整合营销</div>)
    // 类别
    const Header = (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10, }}>
            <Tabs type="card" style={{ height: 40 }} onChange={tabChange}>
                <TabPane tab={allTab} key={0}></TabPane>
                <TabPane tab={integratedTab} key={1}></TabPane>
                <TabPane tab={storeTab} key={2}></TabPane>
                <TabPane tab={commodityTab} key={3}></TabPane>
                <TabPane tab={visualTab} key={4}></TabPane>
                <TabPane tab={financialTab} key={5}></TabPane>
                <TabPane tab={humanTab} key={6}></TabPane>
                <TabPane tab={itTab} key={7}></TabPane>
                <TabPane tab={projectTab} key={8}></TabPane>
                <TabPane tab={liftTab} key={9}></TabPane>
            </Tabs>
        </div>
    )


    // 类别标签切换的时候
    function tabChange(key) {
        setPage(1)
        // 修改全部课程的数据 (即把对应状态的课程数据赋值给全部课程)
        switch (parseInt(key)) {
            case 0:
                setAllData(allCourse)
                break;
            case 1:
                setAllData(integratedData)
                break;
            case 2:
                setAllData(storeData)
                break;
            case 3:
                setAllData(commodityData)
                break;
            case 4:
                setAllData(visualData)
                break;
            case 5:
                setAllData(financialData)
                break;
            case 6:
                setAllData(humanData)
                break;
            case 7:
                setAllData(itData)
                break;
            case 8:
                setAllData(projectData)
                break;
            case 9:
                setAllData(liftData)
                break;
        }
        setTabKey(key)
    }


    useEffect(() => {
        getNsCourseSeek()
    }, [keyWord])

    // 全部课程修改的时候
    useEffect(() => {
        // 初始化是当前课程的前十条数据
        let course = allData.slice(0, pageSize)
        setCurrentCourse(course)
        setTotal(allData.length)
        setPage(1)
    }, [allData])

    // 页面大小改变的时候
    useEffect(() => {
        // 初始化是当前课程的前十条数据
        let course = allData.slice(0, pageSize)
        setCurrentCourse(course)
        setTotal(allData.length)
        setPage(1)
    }, [pageSize])

    // 个数改变
    useEffect(() => {
        if (state.size.height < 800) {
            setPageSize(8)
        } else if (state.size.height > 800 && state.size.height < 1080) {
            setPageSize(12)
        } else {
            setPageSize(16)
        }
    }, [state.size.height])

    // 查询已求课、可求课、已约课接口
    const getNsCourseSeek = () => {
        let dataProps = {
            "principal_uuid": cookie.load('id'),
            "roleId": cookie.load('role'),
            "key_word": keyWord,
            "shopId": cookie.load('shopList')
        }
        NbAxios(dataProps, "POST", servicePath.queryNsCourseSeek).then(res => {
            if (res.success) {
                setAllCourse(res.data.全部课程)
                setProjectData(res.data.项目管理)
                setVisualData(res.data.视觉视频)
                setFinancialData(res.data.财务知识)
                setHumanData(res.data.人力资源)
                setLiftData(res.data.生活技能)
                setIntegratedData(res.data.整合营销)
                setCommodityData(res.data.商品经营)
                setStoreData(res.data.店铺运营)
                setItData(res.data.信息技术)
                switch (parseInt(tabKey)) {
                    case 0:
                        setAllData(res.data.全部课程)
                        break;
                    case 1:
                        setAllData(res.data.整合营销)
                        break;
                    case 2:
                        setAllData(res.data.店铺运营)
                        break;
                    case 3:
                        setAllData(res.data.商品经营)
                        break;
                    case 4:
                        setAllData(res.data.视觉视频)
                        break;
                    case 5:
                        setAllData(res.data.财务知识)
                        break;
                    case 6:
                        setAllData(res.data.人力资源)
                        break;
                    case 7:
                        setAllData(res.data.信息技术)
                        break;
                    case 8:
                        setAllData(res.data.项目管理)
                        break;
                    case 9:
                        setAllData(res.data.项目管理)
                        break;
                }
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 页面修改函数
    function pageChange(page, pageSize) {
        let course = allData.slice((page - 1) * pageSize, pageSize * page)
        setCurrentCourse(course)
        setPage(page)
    }

    // 请求开课
    const requestCourse = (id) => {
        setEffective(1)
        let dataProps = {
            "principal_uuid": cookie.load('id'),
            "roleId": cookie.load('role'),
            "course_id": id,
            "shopId": cookie.load('shopList')
        }
        NbAxios(dataProps, "POST", servicePath.createNsCourseSeek).then(res => {
            setEffective(0)
            if (res.success) {
                message.success('求课成功')
                getNsCourseSeek()
            } else {
                if (res.errorMsg == '该课程正在发布中') {
                    reminder(id, res.errorCode)
                } else {
                    message.error(res.errorMsg)
                }
            }
        })
    }

    // 课程提醒
    function reminder(course_id, courses_publish_id) {
        Modal.confirm({
            title: '该课程正在发布/报名中',
            content: '课程进行中',
            icon: <ExclamationCircleOutlined />,
            okText: '前往查看详情',
            cancelText: '求下一次开课',
            onOk: () => {
                props.history.push({ 'pathname': '/app/cursor/order' })
            },
            onCancel: () => {
                let dataProps = {
                    "principal_uuid": cookie.load('id'),
                    "roleId": cookie.load('role'),
                    "course_id": course_id,
                    "courses_publish_id": courses_publish_id,
                    "shopId": cookie.load('shopList')
                }
                NbAxios(dataProps, "POST", servicePath.createNextNsCourseSeek).then(res => {
                    if (res.success) {
                        message.success('求课成功')
                        getNsCourseSeek()
                    } else {
                        message.error(res.errorMsg)
                    }
                })
            }
        })
    }

    // 打开详情
    function info(message) {
        Modal.info({
            zIndex: 100000000000000,
            title: message,
            centered: true,
            okText: '知晓',
            onOk() { },
        });
    }


    return (
        <div className="my-cursor-page-wapper" style={{ margin: 'auto' }}>
            <div className="my-cursor-page">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PageHeader
                        className="site-page-header"
                        backIcon={<LeftOutlined />}
                        onBack={() => props.history.push({ 'pathname': '/app/cursor/order' })}
                        style={{ padding: '0px' }}
                        title="全部课程"
                    />
                    {/* <div style={{ width: 700, margin: 'auto'}}></div> */}
                    <Search placeholder="搜索课名/创建人" onSearch={(value) => setKeyWord(value)} enterButton style={{ width: 700, margin: 'auto' }} />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    {Header}
                </div>
                <div className="teach-cursor-content">
                    {
                        // currentCourse.length != 0 ? 
                        //     <div>
                        //         {

                        //     }
                        //     </div>
                        // : <div><Text strong>{'当前类目暂无课程...'}</Text></div>
                        currentCourse.map(item => {
                            return <CursorItem
                                courseKind={item.course_kind}
                                className={`my-cursor`}
                                title={item.course_name}
                                introduce={item.course_introduction}
                                // maxNum={item.participant_ceiling}
                                // signNum={item.number}
                                width={'90%'}
                                height={310}
                                teachear={item.creator_name}
                                // openTime={item.course_clock=="就是现在"?"就是现在": moment(item.course_clock).format('MM-DD HH:mm')}
                                // endTime={moment(item.registration_end_time).format('MM-DD HH:mm')}
                                // bgColor={item.course_cover}
                                // tags={item.tags_name.split(',')}
                                cursorType={item.states}
                                introduceRowNum={2}
                                withoutOpentime
                                // teachType={item.form == 2 ? '线上' : '面授'}
                                action={item.states == '可求课' ? '求课' : ''}
                                // onClick={item.course_clock=="就是现在"?()=>goOther('cursorBase'): () => getCursorDetail(item)}
                                isAttend={item.allow_audit}
                                introduceClick={() => info(item.course_introduction)}
                                buttonClick={() => requestCourse(item.course_id)}
                            // showIntroduce
                            />
                        })
                    }
                </div>
            </div>
            <Pagination pageSize={pageSize} simple hideOnSinglePage={true} current={total == 0 ? 0 : page} total={total} onChange={pageChange} />
        </div>
    )

}


export default TeachCourse