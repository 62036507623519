import React, { useCallback, useRef, useState } from 'react';
import { Input, Button, Popover, message, Modal, Table, InputNumber, Typography, Select, Popconfirm, DatePicker, Form, Space, Upload, Tooltip, Drawer, Spin, Result, Steps, Image } from 'antd'
import { PlusSquareOutlined, SearchOutlined, EyeOutlined, CheckCircleTwoTone, QuestionCircleOutlined, InboxOutlined, CheckSquareOutlined, CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import cookie from 'react-cookies'
import { Sketch } from '@uiw/react-color'
import style from './index.module.css';
import locale from 'antd/es/date-picker/locale/zh_CN';
import servicePath from '../../../config/apiUrl';
import NbAxios from '../../../config/utils/nbAxios';
import { useEffect } from 'react';
import axios from 'axios';
import downExcel from '../../../config/utils/downloadFile';
const { Title, Text } = Typography;
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD';
const { Dragger } = Upload;
const { Option } = Select;
const { Step } = Steps

function Index() {

    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    }
    // 移动开始数据
    const [dragStart, setDragStart] = useState(-1)
    // 标签选择器内容
    const [tagsData, setTagsData] = useState([{
        "id": "",
        "group": "",
        "name": "",
        "color": ""
    }
    ])
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 总的选择标签
    const [allChooseTagsList, setAllChooseTagsList] = useState([])
    // 编辑标签
    const [isEditTags, setIsEditTags] = useState(false)
    // 标签名字
    const [tagName, setTagName] = useState('')
    // 标签颜色
    const [tagColor, setTagColor] = useState('#cccccc')
    // 标签列表
    const [tagsList, setTagsList] = useState([
        {
            color: "#F56464",
            name: "运营中1"
        },
        {
            color: "#B7EB8F",
            name: "主链接2"
        },
        {
            color: "#F56464",
            name: "运营中3"
        },
        {
            color: "#B7EB8F",
            name: "主链接4"
        },
        {
            color: "#F56464",
            name: "运营中5"
        },
        {
            color: "#B7EB8F",
            name: "主链接6"
        }
    ])
    // 品类分组选择值
    const [categoryLevelSelect, setCategoryLevelSelect] = useState('')
    // 品类级别
    const [categoryLevel, setCategoryLevel] = useState(['视频', '图片', '音频'])
    // 开始时间
    const [startTime, setStartTime] = useState('')
    // 结束时间
    const [endTime, setEndTime] = useState('')
    // // 文案数据
    // const [resultData, setResultData] = useState([
    //     // 一行的数据
    //     {
    //         "id": "",                    //文案id
    //         "subtitle": "",            //字幕
    //         "oral_broadcast": "",       //口播
    //         "number_of_mirrors": "",    //分镜数
    //         "line_number": "",          //行号
    //         "mirrors_list": [
    //             {
    //                 "clause_tag_id": "",            //分镜标签id
    //                 "copywriting_id": "",        //文案id
    //                 "mirrors_line_number": "",    //分镜行号
    //                 "mirrors_tag": []       //分镜标签
    //             },
    //         ]
    //     }])

    //字幕口播输入
    const [subtitleData, setSubtitleData] = useState([
        {
            "id":"",
            "line_number": "",            //行号
            "subtitle": "",                //字幕
            "oral_broadcast": "",          //口播
        }
    ])
    // 字幕口播切词结果数据
    const [resultData, setResultData] = useState([
        {
                "line_number": "",            //行号
                "subtitle": "",                //字幕
                "subtitle_demarcate_words": [{
                    "word":"",
                    "is_valid":"" 
                }],    //字幕切词结果
                
                "oral_broadcast": "",
                "oral_broadcast_demarcate_words": [{
                    "word":"",
                    "is_valid":"" 
                }],      //口播切词结果        
                "number_of_mirrors":1,    //分镜数
                "mirrors_list":[
                    {
                        "clause_tag_id":"",            //分镜标签id
                        "copywriting_id":"",        //文案id
                        "mirrors_line_number":"",    //分镜行号
                        "mirrors_tag":[]            //分镜标签
                    }
                ]
        }        
    ])


    // 定义提交表单
    const layout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 16 },
    };
    const layoutPlan = {
        labelCol: { span: 7.5 },
        wrapperCol: { span: 10 },
    };
    const layoutNext = {
        labelCol: { span: 7.5 },
        wrapperCol: { span: 10 },
    };
    const layoutNextWord = {
        labelCol: { span: 7.5 },
        wrapperCol: { span: 26 },
    };

    const [form] = Form.useForm();
    const [formTwo] = Form.useForm();

    const [wordForm] = Form.useForm();
    // 定义表格总数
    const [total, setTotal] = useState(0)
    // 定义页数
    const [page, setPage] = useState(1)
    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,	// 当前页数
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    // 定义查询名称输入
    const [selectNameValue, setSelectNameValue] = useState('');
    // 定义序号输入
    const [orderNameValue, setOrderNameValue] = useState('');
    // 定义延展数量输入
    const [extensionQuantity, setExtensionQuantity] = useState('');
    // 定义编辑人输入
    const [editMemberValue, setEditMemberValue] = useState('');
    // 下拉编辑人选项
    const [editMember, setEditMember] = useState(['小美', '小明', '小帅'])
    // 定义字数最小输入
    const [wordCountMinValue, setWordCountMinValue] = useState();
    // 定义字数最大输入
    const [wordCountMaxValue, setWordCountMaxValue] = useState();
    // 定义标签输入
    const [labelValue, setLabelValue] = useState('');
    // 编辑属性
    const [editForm] = Form.useForm()
    // 当前步骤
    const [currentStep, setCurrentStep] = useState(0)
    // 当前步骤2
    const [currentTwoStep, setCurrentTwoStep] = useState(1)
    // 当前步骤3
    const [currentThreeStep, setCurrentThreeStep] = useState(2)
    // 当前步骤4
    const [currentFourStep, setCurrentFourStep] = useState(3)
    // 定义计划id
    const [planId, setPlanId] = useState('')
    // 定义计划截止时间
    const [plannedDeadline, setPlannedDeadline] = useState('')
    // 参考图文件
    const [referenceDrawingFile, setReferenceDrawingFile] = useState([])
    // 配乐
    const [musicRelativeFile, setMusicRelativeFile] = useState([])
    //配乐标签
    const [bgmTags, setBgmTags] = useState([])
    //计划1整体标签
    const [entiretyTags, setEntiretyTags] = useState([])
    



    //打开查询弹框
    const [isModalOpenSelect, setIsModalOpenSelect] = useState(false)
    const handleOkSelect = () => {
        setIsModalOpenSelect(false);
    };
    const handleCancelSelect = () => {
        setIsModalOpenSelect(false);
    };
    //创建计划按钮
    const [isPlanOpen, setIsPlanOpen] = useState(false)
    const planOk = () => {
        setIsPlanOpen(false);
    };
    const planCancel = () => {
        // setIsPlanOpen(false);
    };

    //点击创建计划下一步2按钮
    const [isNextOpen, setIsNextOpen] = useState(false)
    const nextOk = () => {
        setIsNextOpen(false);
    };
    const nextCancel = () => {
        // setIsNextOpen(false);
    };
    //点击创建计划下一步3按钮
    const [isNextWordOpen, setIsNextWordOpen] = useState(false)
    const nextWordOk = () => {
        setIsNextWordOpen(false);
    };
    const nextWordCancel = () => {
        // setIsNextWordOpen(false);
    };
    //点击创建计划下一步4按钮
    const [isNextTotalOpen, setIsNextTotalOpen] = useState(false)
    const nextTotalOk = () => {
        setIsNextTotalOpen(false);
    };
    const nextTotalCancel = () => {
        setIsNextTotalOpen(false);
    };


    //点击更多配乐查看
    const [bgmOpen, setBgmOpen] = useState(false)
    const bgmOk = () => {
        setBgmOpen(false);
    };
    const bgmCancel = () => {
        setBgmOpen(false);
    };

    //点击更多文案查看
    const [copyWritingOpen, setCopyWritingOpen] = useState(false)
    const copyWritingOk = () => {
        setCopyWritingOpen(false);
    };
    const copyWritingCancel = () => {
        setCopyWritingOpen(false);
    };



    // const onChange = (date, dateString) => {
    //     if (dateString == '') {
    //         message.warn('请选择合理的时间')
    //         console.log(date)
    //         console.log(dateString)
    //         return false
    //     } else {
    //         setPlannedDeadline(dateString)
    //     }
    // };

    // 数据
    const [dataSource, setDataSource] = useState([])
    // 表头
    const [columns, setColumns] = useState([])




    //文案表头
    const copywritingColumns = [
        {
            title: '标签',
            dataIndex: 'mirrors_tag',
            key: 'mirrors_tag',
            align: 'center',
            render: (text) => {
                return <div style={{ display: 'flex' }}>
                    {
                        text.map(item => {
                            return <span style={{ marginRight: "5px" }}>{item.name}</span>
                        })
                    }
                </div>
            }
        },
    ]
    // 图片查看
    const imageLookContent = (referenceDrawingFile,address) => {
        return <div>
            <img src={`https://pic.bmcsoft.cn/shortvideo/material_library/`+address} style={{ width: '130px'}} />
        </div>
    }

    // 操作更多
    const addMore = (record) => {
        return <div style={{ width: "400px", height: '150px' }}>

            <div style={{ justifyContent: "center", alignItems: "center", float: 'left', paddingTop: "10px" }}>
                {imageLookContent(record['reference_drawing_file'],record['reference_drawing_relative_address'])}
            </div>
            <div style={{ whitespace: 'pre-wrap', justifyContent: "center", alignItems: "center", float: 'left', paddingTop: "10px", marginLeft: '20px' }}>

                <div>字数：<Text>{record['number_of_word']}</Text></div>
                <div style={{ display: 'flex' }}>
                    <div>配乐：</div>
                    <a onClick={() => { setBgmOpen(true) }}>查看</a>
                </div>
                <div>参考链接：{record['reference_link'] == ''?<span></span>:<a href ={record['reference_link']} target="_blank">查看</a>}</div>
                <div>计划截止时间：<Text>{record['planned_deadline']}</Text></div>
                <div style={{ display: 'flex' }}>
                    <div>文案：</div>
                    <a onClick={() => { setCopyWritingOpen(true) }}>查看</a>
                </div>

            </div>
        </div>
    }

    //添加整体标签
    const addEntiretyTagsList = () => {
        return <div style={{ width: "150px" }}>
            <Input size="small" placeholder="请输入关键字" suffix={<SearchOutlined />} onChange={(e) => {
                setTagsList(tagsData.filter(item => {
                    return item.name.indexOf(e.target.value) != -1
                }))
            }} />
            <div style={{ height: "150px", overflow: "auto", marginTop: "5px" }}>
                {
                    tagsList.map(item => {
                        if (entiretyTags?.map(obj => {
                            return obj.id
                        }).indexOf(item.id) == -1) {
                            return <Tooltip title={item.name} placement="right" zIndex={1400}>
                                <div style={{ textAlign: "center", height: "25px", cursor: "pointer", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} onClick={() => {
                                    entiretyTags.push(item)
                                }}>{item.name}</div>
                            </Tooltip>
                        }
                    })
                }
            </div>
            <div style={{ borderTop: "1px solid #ccc", display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
                <Button size="small" icon={<PlusSquareOutlined />} type="primary" onClick={() => {
                    // setTableRows(index)
                    setIsEditTags(true)
                }}>添加标签</Button>
            </div>
        </div>
    }

    //添加配乐2标签
    const addBgmTagsList = () => {
        return <div style={{ width: "150px" }}>
            <Input size="small" placeholder="请输入关键字" suffix={<SearchOutlined />} onChange={(e) => {
                setTagsList(tagsData.filter(item => {
                    return item.name.indexOf(e.target.value) != -1
                }))
            }} />
            <div style={{ height: "150px", overflow: "auto", marginTop: "5px" }}>
                {
                    tagsList.map(item => {
                        if (bgmTags.map(obj => {
                            return obj.id
                        }).indexOf(item.id) == -1) {
                            return <Tooltip title={item.name} placement="right" zIndex={1400}>
                                <div style={{ textAlign: "center", height: "25px", cursor: "pointer", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} onClick={() => {
                                    bgmTags.push(item)
                                }}>{item.name}</div>
                            </Tooltip>
                        }
                    })
                }
            </div>
            <div style={{ borderTop: "1px solid #ccc", display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
                <Button size="small" icon={<PlusSquareOutlined />} type="primary" onClick={() => {
                    // setTableRows(index)
                    setIsEditTags(true)
                }}>添加标签</Button>
            </div>
        </div>
    }

    // 添加分镜标签
    const addMirrorsTagList = (index, mirrors_list_index) => {
        return <div style={{ width: "150px" }}>
            <Input size="small" placeholder="请输入关键字" suffix={<SearchOutlined />} onChange={(e) => {
                setTagsList(tagsData.filter(item => {
                    return item.name.indexOf(e.target.value) != -1
                }))
            }} />
            <div style={{ height: "150px", overflow: "auto", marginTop: "5px" }}>
                {
                    tagsList.map(item => {
                        if (resultData[index]['mirrors_list'][mirrors_list_index]['mirrors_tag'].map(obj => {
                            return obj.id
                        }).indexOf(item.id) == -1) {
                            return <Tooltip title={item.name} placement="right" zIndex={1400}>
                                <div style={{ textAlign: "center", height: "25px", cursor: "pointer", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} onClick={() => {
                                    // bgmTags.push(item)
                                    resultData[index]['mirrors_list'][mirrors_list_index]['mirrors_tag'].push(item)
                                }}>{item.name}</div>
                            </Tooltip>
                        }
                    })
                }
            </div>
            <div style={{ borderTop: "1px solid #ccc", display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
                <Button size="small" icon={<PlusSquareOutlined />} type="primary" onClick={() => {
                    // setTableRows(index)
                    setIsEditTags(true)
                }}>添加标签</Button>
            </div>
        </div>
    }

    // 添加标签
    const addTagsList = () => {
        return <div style={{ width: "150px" }}>
            <Input size="small" placeholder="请输入关键字" suffix={<SearchOutlined />} onChange={(e) => {
                setTagsList(tagsData.filter(item => {
                    return item.name.indexOf(e.target.value) != -1
                }))
            }} />
            <div style={{ borderTop: "1px solid #ccc", display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
                <Button size="small" icon={<PlusSquareOutlined />} type="primary" onClick={() => {
                    // setTableRows(index)
                    setIsEditTags(true)
                }}>添加标签</Button>
            </div>
        </div>
    }
    // 添加标签
    const addTags = () => {
        return <div>
            <div style={{ display: 'flex' }}>
                <span style={{ marginRight: "20px", width: '60px', textAlign: "right" }}>名字</span>
                <Input size="small" placeholder='请输入名字' style={{ width: "120px", marginRight: "20px" }} value={tagName} onChange={(e) => {
                    let patrn = new RegExp("(^[\u4E00-\u9FA5A-Za-z0-9 ]+$)");
                    if (!patrn.test(e.target.value) && e.target.value) {
                        message.warning('标签名只能填写汉字、数字、字母、空格')
                    } else {
                        setTagName(e.target.value)
                    }

                }} />

            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <span style={{ marginRight: "20px", width: '60px', textAlign: "right" }}>预览</span>
                <Popover placement="right" content={<Sketch color={tagColor} onChange={(color) => {
                    setTagColor(color.hexa);
                }} />} zIndex={10005}>
                    <div style={{ border: `1px solid ${tagColor}`, color: `${tagColor}`, backgroundColor: `${tagColor.substring(0, 7) + '19'}`, textAlign: 'center', borderRadius: "3px", padding: "0 3px", fontSize: "10px", height: "20.84px" }}>{tagName}</div>
                </Popover>
            </div>
            <div style={{ marginTop: "10px" }}>
                <Button style={{ marginLeft: "80px", borderRadius: "2px" }} size="small" type="primary" onClick={() => {
                    // 校验字符串是否全为空格
                    let reg = /^\s+$/g
                    if (tagName == '') {
                        message.warning("标签名不能为空")
                    } else if (reg.test(tagName)) {
                        message.warning("标签名不能全是空格")
                    } else {
                        addNewTag()
                    }
                }}>确定</Button>
            </div>
        </div>
    }
    // 添加新标签
    const addNewTag = () => {
        if (tagName == '') {
            message.warning("标签名未输入")
        } else if (tagsData.map(item => {
            return item.name
        }).indexOf(tagName) !== -1) {
            message.warn("标签已存在")

        } else {
            let obj = [...tagsData]
            obj.push({
                group: "",
                name: tagName,
                color: tagColor,
            })
            setTagsData(obj)
            setAllChooseTagsList(obj)
            setTagName('')
            setTagColor('')
        }
    }

    // 参考图文件上传
    const referenceDrawingHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['png', 'jpg']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setReferenceDrawingFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setReferenceDrawingFile(fileList)
        }
    }

    // 配乐文件上传
    const musicRelativeHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['mp4', 'mp3', 'mov', 'm4v']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setMusicRelativeFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setMusicRelativeFile(fileList)
        }
    }

    // 时间修改函数
    const onTimeChange = (dates, dateStrings) => {
        if (dateStrings[0] == '') {
            message.warn('请选择正确的时间')
            return false
        } else {
            setStartTime(dateStrings[0])
            setEndTime(dateStrings[1])
        }
    }
    // 定义编辑标签
    const editTags = () => {
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        values["tags_list"] = tagsData;
        // console.log('Success:', values);
        setLoading(true)
        NbAxios(values, "POST", servicePath.editTagsShortVideoMaterial).then(res => {
            setLoading(false)
            if (res.success) {
                setIsEditTags(false)
                onShowTags()
                message.success('编辑标签成功！')
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('编辑标签报错，请联系IT帮忙解决')
        })
    };
    //定义查询函数
    const getSelectData = () => {
        let dataProps = {
            "product_specifications": selectNameValue,
            "direction": categoryLevelSelect,
            "number": orderNameValue,
            "extension_quantity": extensionQuantity,
            "editor": editMemberValue,
            "min_number_of_word": wordCountMinValue,
            "max_number_of_word": wordCountMaxValue,
            "planned_deadline_start": startTime,
            "planned_deadline_end": endTime,
            'shopList': cookie.load('shopList'),
            'roleId': cookie.load('role'),
        }
        // console.log(dataProps);
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.selectShortVideoPlan).then(res => {
            setLoading(false)
            if (res.success) {
                setTotal(res.total)
                // getSelectData(res.data);
                handleCancelSelect()
                setDataSource(res.data)
                // console.log(res.data)
            } else {
                // console.log(res);
                // message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询计划表报错，请联系IT帮忙解决')
        })
    }
    // 定义操作删除
    const onDelectFinish = (planId) => {
        let values = {}
        values["planId"] = planId;
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        // console.log('Success:', values);
        setLoading(true)
        NbAxios(values, "POST", servicePath.deleteShortVideoPlan).then(res => {
            setLoading(false)
            if (res.success) {
                message.success('删除计划表成功！')
                getSelectData()
                setPlanId('')
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('删除计划表报错，请联系IT帮忙解决')
        })
    };

    //定义查询计划1
    const getPlanData = (planId) => {
        let dataProps = {
            'planId': planId,
            'shopList': cookie.load('shopList'),
            'roleId': cookie.load('role'),
        }
        setLoading(true)
        // console.log("查询1",dataProps);
        NbAxios(dataProps, "POST", servicePath.searchPlanStepOne).then(res => {
            setLoading(false)
            if (res.success) {
                // getSelectData(res.data);
                form.setFieldsValue(res.data)
                // console.log("返回数据1",res.data);
                //计划截止时间
                if(JSON.stringify(res.data) != "{}"){
                    setPlannedDeadline(res.data.planned_deadline)
                }
                //整体标签
                if(JSON.stringify(res.data) != "{}"){
                    setEntiretyTags(res.data.tags)
                }
                //配乐2标签
                if(JSON.stringify(res.data) != "{}"){
                    setEntiretyTags(res.data.bgm_tag)
                }
                let referenceDrawingUrl = res.data.reference_drawing_relative_address
                if (referenceDrawingUrl != null) {
                    setReferenceDrawingFile([{
                        'status': "done",
                        'url': referenceDrawingUrl
                    }])
                }
                let musicRelativeUrl = res.data.music_relative_address
                if (musicRelativeUrl != null) {
                    setMusicRelativeFile([{
                        'status': "done",
                        'url': musicRelativeUrl
                    }])
                }
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询计划表报错，请联系IT帮忙解决')
        })
    };
    //定义编辑1
    const onEditPlanFinish = (values) => {
        // console.log(values);
        // values.planned_deadline = values.planned_deadline.format(dateFormat)
        // 上传原始文件例子
        if(plannedDeadline == ''){
            message.warn("请选择计划截止时间！")
        }else{
            const formData = new FormData()
            // 参考图
            referenceDrawingFile.forEach((item) => {
                formData.append('reference_drawing_file', item['originFileObj']);
            })
            // console.log(entiretyTags);
            // 配乐
            musicRelativeFile.forEach((item) => {
                formData.append('music_relative_file', item['originFileObj'])
            })
            let entiretyTagsObj = []
            entiretyTags.forEach((item) => {
                entiretyTagsObj.push(item['id'])
            })
            // console.log(referenceDrawingFile);
            // console.log(musicRelativeFile);
            let bgmObj = []
            bgmTags.forEach((item) => {
                bgmObj.push(item['id'])
            })
            formData.append('roleId', cookie.load('role'))
            formData.append('shopList', cookie.load('shopList'))
            formData.append('planId', planId)       
            formData.append('product_specifications', values.product_specifications)
            formData.append('direction', values.direction)
            formData.append('number', values.number)
            formData.append('extension_quantity', values.extension_quantity)
            formData.append('reference_link', values.reference_link)
            // formData.append('reference_drawing_file', referenceDrawingFile)
            // formData.append('music_relative_file', musicRelativeFile)
            formData.append('editor', values.editor)
            formData.append('music_relative_tags_list', bgmObj)
            formData.append('music_relative_tags', values.music_relative_tags)
            formData.append('entirety_tags',entiretyTagsObj)        
            formData.append('planned_deadline', plannedDeadline)   
            
            // console.log('编辑1:', formData);
            setLoading(true)
            NbAxios(formData, "POST", servicePath.createPlanStepOne).then(res => {
                setLoading(false)
                if (res.success) {
                    setPlanId(res.data.planId)
                    setPlannedDeadline('')   //清空计划截止时间
                    setEntiretyTags([])      //清空整体标签
                    // console.log("数据查看：",res.data)
                    // planCancel()
                    editForm.resetFields()
                    form.resetFields()
                    setTagsList(tagsData)
                    message.success('编辑成功！')
                    // getPlanData()
                    setIsPlanOpen(false)
                    setIsNextOpen(true)
                    getPlanNextData(res.data.planId)       
                } else {
                    message.warn({ content: res.errorMsg })
                }
            }).catch((err) => {
                setLoading(false)
                // 获取500错误
                message.warn('编辑报错，请联系IT帮忙解决')
            })
        }  
    };

    //定义查询计划2
    const getPlanNextData = (planId) => {
        let dataProps = {
            'planId': planId,
            'shopList': cookie.load('shopList'),
            'roleId': cookie.load('role'),
        }
        setLoading(true)
        // console.log('查询2:', dataProps)
        NbAxios(dataProps, "POST", servicePath.searchPlanStepTwo).then(res => {
            setLoading(false)
            if (res.success) {
                // setPlanId(planId)
                // getSelectData(res.data);
                formTwo.setFieldsValue({planId:planId})   // 传值
                setSubtitleData(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询计划表报错，请联系IT帮忙解决')
        })
    };

    //定义编辑2
    const onEditNextFinish = () => {
        let values ={}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        values["planId"] = planId;                                   // 计划ID
        values["copywriting_data"] = subtitleData;                    //文案数据
        // console.log('编辑2:', values);
        setLoading(true)
        NbAxios(values, "POST", servicePath.editPlanStepTwo).then(res => {
            setLoading(false)
            if (res.success) {
                // console.log(res)
                message.success('编辑成功！')
                // 步骤二的字幕、口播 传给步骤三
                let obj = [...resultData]
                obj.map((item,index)=>{
                item['subtitle'] =  subtitleData[index]['subtitle']
                item['oral_broadcast'] =  subtitleData[index]['oral_broadcast']
                })
                setResultData(obj)
                //清空
                formTwo.resetFields()
                setSubtitleData([
                    {
                        "id":"",
                        "line_number":"",            //行数
                        "oral_broadcast":"",      //口播
                        "subtitle":"",          //字幕
                    } 
                ])    
                setIsNextOpen(false)     //关闭2
                setIsNextWordOpen(true)  //打开3
                getPlanNextDataThree()   //查询3  
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('编辑报错，请联系IT帮忙解决')
        })
    };

    //定义查询计划3
    const getPlanNextDataThree = () => {
        let dataProps = {
            'planId': planId,
            'shopList': cookie.load('shopList'),
            'roleId': cookie.load('role'),
        }
        setLoading(true)
        // console.log('查询计划3:', dataProps);
        NbAxios(dataProps, "POST", servicePath.searchPlanStepThree).then(res => {
            setLoading(false)
            if (res.success) {
                // getSelectData(res.data);
                // console.log("查询3",res.data);
                setResultData(res.data)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('查询计划表报错，请联系IT帮忙解决')
        })
    };
    //定义编辑3
    const onEditNextWordFinish = () => {
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        values["planId"] = planId;                                   // 计划ID
        values["copywriting_demarcate_words_data"] = resultData;                    //文案数据
        // console.log('编辑3:', values);
        setLoading(true)
        NbAxios(values, "POST", servicePath.editPlanStepThree).then(res => {
            setLoading(false)
            if (res.success) {
                // console.log(res)
                message.success('编辑成功！')               
                setResultData([
                    {
                            "line_number": "",            //行号
                            "subtitle": "",                //字幕
                            "subtitle_demarcate_words": [{
                                "word":"",
                                "is_valid":"" 
                            }],    //字幕切词结果
                            
                            "oral_broadcast": "",
                            "oral_broadcast_demarcate_words": [{
                                "word":"",
                                "is_valid":"" 
                            }],      //口播切词结果        
                            "number_of_mirrors":"",    //分镜数
                            "mirrors_list":[
                                {
                                    "clause_tag_id":"",            //分镜标签id
                                    "copywriting_id":"",        //文案id
                                    "mirrors_line_number":"",    //分镜行号
                                    "mirrors_tag":[]            //分镜标签
                                }
                            ]
                    }   
                ])
                setIsNextWordOpen(false);    //关闭3
                setIsNextTotalOpen(true);   //打开4
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('编辑报错，请联系IT帮忙解决')
        })
    };
    //下载素材
    const screenVideoData = (planId) => {
        let dataProps = {'planId': planId,}
        // console.log(dataProps)
        setLoading(true)
        let key = "download"
        message.loading({content:'文件正在制作', key ,duration:300000})
        axios({
            method: 'POST',
            url: servicePath.screenVideoData,
            data: dataProps,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
            // 设置超时时间为5分钟
            timeout:300000
        }).then(res => {
                setLoading(false)
                downExcel(res)
                getSelectData()  //查询计划
                setPlanId('')   //清空计划id
                setIsNextTotalOpen(false); 
                message.success({content:'文件制作成功！', key})
            }).catch(e => {
            setLoading(false)
            message.error({ content: '文件制作出错，请联系it同事！' , key })
        })
    };

    //下载素材
    const screenVideoDataDownload = (planId) => {
        let dataProps = {'planId': planId,}
        // console.log(dataProps)
        setLoading(true)
        let key = "download"
        message.loading({content:'文件下载中...', key ,duration:300000})
        axios({
            method: 'POST',
            url: servicePath.screenVideoDataDownload,
            data: dataProps,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
            // 设置超时时间为5分钟
            timeout:300000
        }).then(res => {
                setLoading(false)
                downExcel(res)
                getSelectData()  //查询计划
                setPlanId('')   //清空计划id
                setIsNextTotalOpen(false); 
                message.success({content:'文件下载成功！', key})
            }).catch(e => {
            setLoading(false)
            message.error({ content: '文件下载出错，请联系it同事！' , key })
        })
    };

    // 初始化页面
    useEffect(() => {
        onShowTags()
    }, [])


    useEffect(() => {
        setColumns([
            {
                align: 'center',
                title: '产品规格',
                dataIndex: 'product_specifications',
                key: 'product_specifications',
            },

            {
                align: 'center',
                title: '内容方向',
                dataIndex: 'direction',
                key: 'direction',
            },
            {
                align: 'center',
                title: '序号',
                dataIndex: 'number',
                key: 'number',
            },
            {
                align: 'center',
                title: '延展数量',
                dataIndex: 'extension_quantity',
                key: 'extension_quantity',
            },
            {
                align: 'center',
                title: '编辑人',
                dataIndex: 'editor',
                key: 'editor',
            },
            {
                align: 'center',
                title: '编辑时间',
                dataIndex: 'create_time',
                key: 'create_time',
            },

            {
                width: '400PX',
                align: 'center',
                title: '整体标签',
                dataIndex: 'tags',
                key: 'tags',
                render: (text) => {
                    return <div style={{ display: 'flex', alignItems: "center", flexWrap: 'wrap', padding: "0px" }}>
                        {
                            text ? text.map((tagItem) => {
                                return <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px" }}>
                                    {tagItem.name}
                                </div>
                            }) : <span></span>
                        }
                    </div>
                }
            },
            {
                align: 'center',
                title: '操作',
                dataIndex: 'act',
                key: 'act',
                render: (text, record, index) => {
                    return <div >

                        <a disabled = {loading == true ? true:false} style={{visibility:record["zip_file_dir"] == '' || record["zip_file_dir"] == null ? 'hidden' : ''}}  onClick={() => {
                            screenVideoDataDownload(record["planId"])  //下载素材
                        }}>下载素材</a>
                        <Popover content={addMore(record)} placement="bottom" zIndex={1000} onMouseLeave={() => {
                            setTagsList(tagsData)
                            // console.log(record)
                        }}>
                            <a style={{ marginLeft: '20px' }}>更多</a>
                        </Popover>

                        <a style={{ marginLeft: '20px' }} onClick={() => {
                            setPlanId(record["planId"])  //取id 
                            // console.log(record);
                            setIsPlanOpen(true)
                            // form.setFieldsValue({
                            //     'product_specifications': record['product_specifications'],
                            //     'direction': record['direction'],
                            //     'number': record['number'],
                            //     'extension_quantity': record['extension_quantity'],
                            //     'reference_link': record['reference_link'],
                            //     'planned_deadline': moment(record['planned_deadline'],dateFormat),
                            //     'reference_drawing_relative_address': record['reference_drawing_relative_address'],
                            //     'entirety_tags': record['tags'],

                            // })
                            getPlanData(record["planId"])
                            // setEntiretyTags(record['tags'])
                            // setPlanId(record["planId"])  //取id 
                        }}>编辑</a>
                        <Popconfirm title="是否要删除该视频" okText="确认" cancelText="取消" onConfirm={()=>onDelectFinish(record['planId'])} >
                            <a style={{ marginLeft: '20px' }} onClick={() => {
                                setPlanId(record["planId"])  //取id 
                            }}>删除</a>
                        </Popconfirm>
                    </div>
                }
            },
        ])
    }, [tagsData, dataSource])


    // 渲染页面
    useEffect(() => {
        getSelectData()
    }, [page])

    // 展示所有标签
    const onShowTags = () => {
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        // console.log('Success:', values);
        NbAxios(values, "POST", servicePath.showTagsShortVideoMaterial).then(res => {
            setLoading(false)
            if (res.success) {
                // console.log(res.data)
                setTagsData(res.data.data['tags_list'])
                setTagsData(res.data.data['tags_list'])
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('展示所有标签报错，请联系IT帮忙解决')
        })
    };



    return (
        <div style={{ backgroundColor: '#F5F5F5', height: "calc(100vh - 40px)", overflow: 'auto' }}>
            <div className={style.externalContent}>
                <div style={
                    {
                        display: 'flex',
                        justifyContent: "space-around",
                        backgroundColor: "#fff",
                        borderBottom: "solid 2px #ccc",
                        marginBottom: 10,
                        // position: 'fixed',
                        // top: 1,
                        // zIndex: 99,
                        width: '100%',
                        margin: '5px auto 0',
                        // marginTop: '20px',
                        // paddingTop:'20px'
                    }
                } >
                    <Title style={{ marginLeft: "10px" }} level={4} > 短视频计划表 </Title>
                    <div >
                        <Button onClick={() => { setIsModalOpenSelect(true) }} > 查询 </Button>
                        <Button style={{ marginLeft: "20px" }} type="primary" onClick={() => { 
                            setIsPlanOpen(true)
                            form.resetFields()
                            getPlanData('')
                            }} > 创建计划 </Button>
                    </div>
                </div>
                <div style={{ margin: '0 auto', backgroundColor: '#fff', marginTop: '40px' }}>
                    <Table size="small" dataSource={dataSource} columns={columns} pagination={paginationProps} />
                </div>
            </div>

            {/* 条件查询抽屉 */}
            <Drawer title="条件查询" visible={isModalOpenSelect} onClose={handleCancelSelect} width={"500px"}>
                <div style={{ margin: "0 auto", display: "table" }}>
                    <Form form={editForm} {...layout} labelAlign="right">
                        <div >
                            <Form.Item label="产品规格" >
                                <Input value={selectNameValue}
                                    onChange={(e) => setSelectNameValue(e.target.value)}
                                    placeholder='请输入文件名称进行模糊匹配'
                                    style={{ width: "220px", marginLeft: "20px" }}
                                />
                            </Form.Item>

                        </div>
                        <div >
                            <Form.Item label="内容方向"  >
                                <Select style={{ width: "220px", marginLeft: "20px" }} value={categoryLevelSelect} onChange={value => setCategoryLevelSelect(value)} >
                                    {
                                        categoryLevel.map(item => {
                                            return <Option value={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div >
                            <Form.Item label="序号">
                                <Input value={orderNameValue}
                                    onChange={(e) => setOrderNameValue(e.target.value)}
                                    style={{ width: "220px", marginLeft: "20px" }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="延展数量" >
                                <Input value={extensionQuantity}
                                    onChange={(e) => setExtensionQuantity(e.target.value)}
                                    placeholder='请输入文件名称进行模糊匹配'
                                    style={{ width: "220px", marginLeft: "20px" }}
                                />
                            </Form.Item>
                        </div>
                        <div >
                            <Form.Item label="编辑人" >
                                <Select style={{ width: "220px", marginLeft: "20px" }} value={editMemberValue} onChange={value => setEditMemberValue(value)} >
                                    {
                                        editMember.map(item => {
                                            return <Option value={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div >
                            <Form.Item label="字数" >
                                <div style={{ display: "flex" }}>
                                    <Input value={wordCountMinValue} onChange={(e) => setWordCountMinValue(e.target.value)} style={{ width: 90, textAlign: 'center', marginLeft: "20px" }} placeholder="Minimum" />
                                    <Input className="site-input-split"
                                        style={{ width: 30, borderLeft: 0, borderRight: 0, pointerEvents: 'none', }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Input className="site-input-right" value={wordCountMaxValue} onChange={(e) => setWordCountMaxValue(e.target.value)}
                                        style={{ width: 90, textAlign: 'center', }}
                                        placeholder="Maximum"
                                    />
                                </div>
                            </Form.Item>
                        </div>
                        <div >
                            <Form.Item label="整体标签" >
                                <Input value={labelValue}
                                    onChange={(e) => setLabelValue(e.target.value)}
                                    style={{ width: "220px", marginLeft: "20px" }}
                                />
                            </Form.Item>
                        </div>
                        <div >
                            <Form.Item label="计划截止时间" >
                                <RangePicker style={{ width: "220px", lineHeight: "30px", marginLeft: "20px" }}
                                    locale={locale}
                                    value={startTime == '' ? ['', ''] : [moment(startTime, dateFormat),moment(endTime, dateFormat)]}
                                    // onChange={() => onTimeChange()} 
                                    onChange={(date, dateString)=>{
                                        if(dateString[0]==''){
                                            message.warn('请选择合理的时间')
                                            return false
                                        }else{
                                            setStartTime(dateString[0])
                                            setEndTime(dateString[1])
                                        }
                                    }}
                                    
                                />

                            </Form.Item>
                        </div>
                        <div style={{ marginTop: "100px" }}>
                            <Button type="primary" danger style={{ marginLeft: "70px" }}onClick={()=>{
                                setSelectNameValue('')
                                setCategoryLevelSelect('')
                                let dataProps = {
                                    "product_specifications": '',
                                    "direction": '',
                                    "number": '',
                                    "extension_quantity": '',
                                    "editor": '',
                                    "min_number_of_word": '',
                                    "max_number_of_word": '',
                                    "planned_deadline_start": '',
                                    "planned_deadline_end": '',
                                    'shopList': cookie.load('shopList'),
                                    'roleId': cookie.load('role'),
                                }
                                setLoading(true)
                                // console.log(dataProps)
                                NbAxios(dataProps, "POST", servicePath.selectShortVideoPlan).then(res => {
                                    setLoading(false)
                                    if (res.success) {
                                        handleCancelSelect()
                                        setDataSource(res.data)
                                    } else {
                                        message.warn({ content: res.errorMsg })
                                    }
                                }).catch((err) => {
                                    setLoading(false)
                                    // 获取500错误
                                    message.warn('查询计划表报错，请联系IT帮忙解决')
                                })
                            }}>重置</Button>
                            <Button type="primary" style={{ marginLeft: "60px" }}
                                onClick={() => { getSelectData();handleCancelSelect() }}
                            >查询</Button>
                        </div>

                    </Form>
                </div>
            </Drawer>
            {/* 编辑标签弹窗 */}
            <Modal
                title="编辑标签"
                visible={isEditTags}
                // onOk={() => {
                //     editTags()
                // }}
                onCancel={() => {
                    setIsEditTags(false)
                }}
                okText="确定"
                cancelText="取消"
                centered={true}
                width={"640px"}
            >
                <Spin tip='数据请求中，请稍等...' spinning={loading}  >
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        border: "1px solid #ccc",
                        height: "267px",
                        justifyContent: 'flex-start',
                        alignContent: "flex-start", // 子元素之间取消空白间隙，并把项目放在容器顶部
                        alignItems: "baseline",
                        padding: "10px",
                        margin: "10px"
                    }}>
                        {
                            tagsData.map(tagItem => {
                                return <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                    let obj = [...tagsData]
                                    obj.remove(tagItem)
                                    setTagsData(obj)
                                }}>
                                    <div style={{
                                        border: `1px solid ${tagItem['color'] ? tagItem['color'] : '#ccc'}`,
                                        color: `${tagItem['color'] ? tagItem['color'] : '#ccc'}`,
                                        backgroundColor: `${tagItem['color'] == null || tagItem['color'] == "None" ? '#ccc' : tagItem['color'].substring(0, 7) + '19'}`,
                                        padding: "0 3px",
                                        textAlign: 'center',
                                        borderRadius: "3px",
                                        fontSize: "10px",
                                        writingMode: "horizontal-tb",
                                        marginRight: "10px",
                                        marginTop: "10px"
                                    }}
                                    >
                                        {tagItem.name}
                                    </div>
                                </Popconfirm>
                            })
                        }
                    </div>
                    <Popover content={addTags()} placement="right" trigger="click" zIndex={10002}>
                        <Button icon={<PlusSquareOutlined />} type="primary" style={{ borderRadius: "2px", marginTop: "10px" }}>添加标签</Button>
                    </Popover>
                </Spin>

            </Modal>
            {/* 创建计划1弹窗 */}
            <Modal title="编辑计划"  centered     visible={isPlanOpen} onOk={planOk} onCancel={planCancel} footer={false} width={"1000px"} maskClosable={false} closable={false}>
                <Form onFinish={onEditPlanFinish} form={form} {...layoutPlan}  >
                    {/* 步骤条 */}
                    <div style={{ display: 'flex', width: '960px', }}>
                        <Steps current={currentStep}>
                            <Step title="" />
                            <Step title="" />
                            <Step title="" />
                            <Step title="" />
                        </Steps>
                    </div>
                    {/* 产品规格、内容方向、序号 */}
                    <div style={{ display: 'flex', marginTop: "20px", justifyContent: 'space-around' }}>
                        <Form.Item label="产品规格：" name="product_specifications" rules={[{ required: true, }]}>
                            <Input style={{ width: "160px", height: '30px', marginLeft: "10px", }} onChange={(e) => e.target.value} />
                        </Form.Item>
                        <Form.Item label="内容方向：" name="direction" rules={[{ required: true, }]}>
                            <Input style={{ width: "160px", height: '30px', marginLeft: "10px", }} onChange={(e) => e.target.value} />
                        </Form.Item>
                        <div style={{ display: "flex" }}>
                            <Tooltip title="当月同产品同方向的序号">
                                <QuestionCircleOutlined style={{ lineHeight: '30px', width: "12px", fontSize: "10px" }} />
                            </Tooltip>
                            <Form.Item label="序号：" name="number" rules={[{ required: true, }]}>
                                <Input style={{ width: "160px", height: '30px', marginLeft: "10px" }} onChange={(e) => e.target.value} />
                            </Form.Item>
                        </div>

                    </div>
                    {/* 延展数量、参考链接 */}
                    <div style={{ display: 'flex', marginLeft: "10px", justifyContent: 'space-around' }}>
                        <div style={{ display: "flex" }}>
                            <Tooltip title="同一个脚本需要延展数量的视频数">
                                <QuestionCircleOutlined style={{ lineHeight: '30px', width: "12px", fontSize: "10px" }} />
                            </Tooltip>
                            <Form.Item label="延展数量：" name="extension_quantity" >
                                <Input style={{ width: "160px", height: '30px', marginLeft: "10px" }} onChange={(e) => e.target.value} />
                            </Form.Item>
                        </div>
                        <Form.Item label="参考链接：" name="reference_link" style={{ marginLeft: "20px" }} >
                            <Input style={{ width: "475px", height: '30px', marginLeft: "10px" }}  onChange={(e) => e.target.value}/>
                        </Form.Item>
                    </div>
                    {/* 计划截止时间 */}
                    <div style={{ display: 'flex', marginLeft: "10px", marginTop: "20px" }}>
                        <div style ={{color:"red",fontSize:"20px",lineHeight:"32px",marginTop:"2px",marginRight:"2px"}}>*</div>
                        <Form.Item label="计划截止时间：" rules={[{ required: true, }]} >
                            <DatePicker  locale={locale} value = {plannedDeadline == ''?'':moment(plannedDeadline,dateFormat)} 
                                onChange={(date,dateStrings) =>{
                                    if(dateStrings == ''){
                                        message.warn( "请选择正确的时间" )
                                    }else{
                                        setPlannedDeadline(dateStrings)
                                    }
                                }} 
                            style={{ marginLeft: "20px", width: "160px" }} />
                        </Form.Item>
                        <Form.Item label="整体标签："  style={{ marginLeft: "70px" }} >
                            <div style={{ display: 'flex', marginLeft: "20px" }}>
                                {
                                    entiretyTags?.map((tagItem) => {
                                        /**
                                         * 鼠标右键事件 trigger="contextMenu"
                                         */
                                        return <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                            entiretyTags.remove(tagItem)

                                        }}>
                                            <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px", whiteSpace: "nowrap", lineHeight: "25px" }}>
                                                {tagItem.name}
                                            </div>
                                        </Popconfirm>
                                    })
                                }
                                <Popover content={addEntiretyTagsList()} placement="bottom" zIndex={1300} onMouseLeave={() => {
                                    setTagsList(tagsData)
                                }}>
                                    <PlusSquareOutlined style={{ lineHeight: "25px", marginTop: "3px" }} />
                                </Popover>
                            </div>

                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', marginLeft: "60px" }}>
                        <Form.Item label="参考图：" >
                            <div style={{ width: "500px", marginLeft: "60px" }}>
                                <Dragger
                                    // accept=".pdf .doc .docx"
                                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                                    fileList={referenceDrawingFile}
                                    onChange={referenceDrawingHandleChange}
                                    listType="picture"
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                                    <p className="ant-upload-hint">
                                        支持扩展名：.png .jpg...
                                    </p>
                                </Dragger>
                            </div>
                        </Form.Item>
                    </div>

                    <div style={{ display: 'flex', marginLeft: "70px" }}>
                        <Form.Item label="配乐：" name="music_relative_address">
                            <div style={{ width: "500px", marginLeft: "60px" }}>
                                <Dragger
                                    // accept=".pdf .doc .docx"
                                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                                    fileList={musicRelativeFile}
                                    onChange={musicRelativeHandleChange}
                                    listType=""
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                                    <p className="ant-upload-hint">
                                        支持扩展名：.mp3 .mp4 .mov .m4v...
                                    </p>
                                </Dragger>
                            </div>

                        </Form.Item>
                    </div>
                    <div style={{ display: "flex", marginLeft: "40px" }}>
                        <Form.Item label="配乐2："  style={{ marginLeft: "30px" }}>
                            <div style={{ display: 'flex', marginLeft: "20px" }}>
                                {
                                    bgmTags.map((tagItem) => {
                                        /**
                                         * 鼠标右键事件 trigger="contextMenu"
                                         */
                                        return <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                            bgmTags.remove(tagItem)

                                        }}>
                                            <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px", whiteSpace: "nowrap", lineHeight: "25px" }}>
                                                {tagItem.name}
                                            </div>
                                        </Popconfirm>
                                    })
                                }
                                <Popover content={addBgmTagsList()} placement="bottom" zIndex={1300} onMouseLeave={() => {
                                    setTagsList(tagsData)
                                }}>
                                    <PlusSquareOutlined style={{ lineHeight: "25px", marginTop: "3px" }} />
                                </Popover>
                            </div>
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                        <Button type="primary" htmlType='submit' onClick={() => {
                            // console.log(form.getFieldValue());                        
                            }}> 下一步 </Button>
                    </div>

                </Form>
            </Modal>
            {/* 创建计划2弹窗 */}
            <Modal title="编辑计划"  centered  visible={isNextOpen} onOk={nextOk} onCancel={nextCancel} footer={false} width={"1000px"} maskClosable={false} closable={false}>
                <Form  form = {formTwo} {...layoutNext} >
                    {/* 步骤条 */}
                    <div style={{ display: 'flex', width: '960px', marginBottom: '30px' }}>
                        <Steps current={currentTwoStep}>
                            <Step title="" />
                            <Step title="" />
                            <Step title="" />
                            <Step title="" />
                        </Steps>
                    </div>
                    <div >
                        {
                            subtitleData.map((item, index) => {
                                return <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', }}>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <CloseOutlined style={{ marginTop: '10px', marginRight: "10px" }} 
                                        // onClick={() => {
                                        //     subtitleData.splice(index, 1)
                                        // }}
                                        onClick={() => {
                                            let obj = [...subtitleData]
                                            obj.splice(index, 1)
                                            // obj.remove(item)
                                            setSubtitleData(obj)
                                        }}
                                         />
                                    </div>
                                    <div style={{ display: 'flex', width: '1000px', marginTop: "20px", marginLeft: "60px" }}>
                                        <Form.Item label="字幕：">
                                            <Input style={{ width: "300px", height: '30px', marginLeft: "10px", }}
                                                value={subtitleData[index]['subtitle']} onChange={(e) => {
                                                    let obj = [...subtitleData]
                                                    obj[index]['subtitle'] = e.target.value
                                                    setSubtitleData(obj)
                                                }}
                                            />
                                        </Form.Item>
                                        {/* <CheckSquareOutlined style={{ marginLeft: "20px" }} />
                                            <span style={{ marginLeft: "10px" }}>勾选则口播填入相同内容</span> */}
                                        <Form.Item label="口播：" style={{ marginLeft: "52px", }} >
                                            <Input style={{ width: "300px", height: '30px', marginLeft: "10px", }}
                                                value={subtitleData[index]['oral_broadcast']} onChange={(e) => {
                                                    let obj = [...subtitleData]
                                                    obj[index]['oral_broadcast'] = e.target.value
                                                    obj[index]['line_number'] = subtitleData.length
                                                    setSubtitleData(obj)
                                                }}
                                            />
                                        </Form.Item>
                                    </div>

                                </div>
                            })
                        }
                    </div>
                    {/* 新增一行 */}
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }} >
                        <Button type="dashed" style={{ marginTop: "40px", width: "1000px" }} onClick={() => {
                            let length = subtitleData.length
                            let obj = [...subtitleData]
                            obj.push({
                                "id":"",
                                "subtitle": "",            //字幕
                                "oral_broadcast": "",       //口播
                                "line_number": length,          //行号
                            })
                            setSubtitleData(obj)
                        }}>
                            新增一行
                        </Button>
                    </div>

                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "60px" }}>
                        <Button type="primary" onClick={() => { setIsNextOpen(false);setIsPlanOpen(true); getPlanData(planId)}} > 上一步 </Button>
                        <Button style={{ marginLeft: "400px" }} type="primary" onClick={()=>onEditNextFinish()} > 下一步 </Button>
                    </div>
                </Form>
            </Modal>
            {/* 创建计划3弹窗 */}
            <Modal title="编辑计划"  centered   visible={isNextWordOpen} onOk={nextWordOk} onCancel={nextWordCancel} footer={false} width={"1000px"} maskClosable={false} closable={false}>
                <Form onFinish={onEditNextWordFinish}  {...layoutNextWord} >
                    {/* 步骤条 */}
                    <div style={{ display: 'flex', width: '960px', }}>
                        <Steps current={currentThreeStep}>
                            <Step title="" />
                            <Step title="" />
                            <Step title="" />
                            <Step title="" />
                        </Steps>
                    </div>
                    <div>
                        {
                            resultData.map((item, index) => {
                                return <div style={{ width: '900px', boxShadow: "0px 0px 10px rgba(100,100,100,0.4)" }}>
                                    <div style={{ display: 'flex', width: '800px', marginTop: "20px", marginLeft: "60px" }}>
                                        {/* 字幕 */}
                                        <Form.Item label="字幕：" style={{ marginTop: "20px"}}>
                                            <div style={{ width: "200px", whitespace: 'pre-wrap', justifyContent: "center", alignItems: "center", marginLeft: "50px" }}>
                                                {item['subtitle']}
                                            </div>
                                        </Form.Item>
                                        {/* 口播 */}
                                        <Form.Item label="口播：" style={{ marginLeft:"60px", marginTop: "20px"}}>
                                        <div style={{ width: "200px", whitespace: 'pre-wrap', justifyContent: "center", alignItems: "center", marginLeft: "50px" }}>
                                            {item['oral_broadcast']}
                                        </div>
                                        </Form.Item>

                                    </div>
                                    {/* 字幕切词 */}
                                    <div style={{ display: 'flex', width: '800px',  marginLeft: "40px" }}>
                                        <Form.Item label="字幕切词："  >
                                            <div style={{ border: '1px dashed #000', width: "500px", height: "40px", display: 'flex', whitespace: 'pre-wrap', marginLeft: "40px" }}>
                                                {
                                                    item.subtitle_demarcate_words.map((wordItem, wordIndex) => {
                                                        return <Text style={{ color: wordItem.is_valid == 1 ? '#1F97FE' : '#ccc', marginLeft: "20px", lineHeight: "40px" }} onClick={() => {
                                                            if (wordItem.is_valid) {
                                                                wordItem.is_valid = 0
                                                            } else {
                                                                wordItem.is_valid = 1
                                                            }
                                                        }}>{wordItem['word']}</Text>
                                                    })
                                                }
                                            </div>
                                        </Form.Item>
                                        
                                    </div>
                                    <div style={{ display: 'flex', width: '800px',  marginLeft: "40px" }}>
                                        {/* 口播切词 */}
                                        <Form.Item label="口播切词：">
                                            <div style={{ border: '1px dashed #000', width: "500px", height: "40px", display: 'flex', whitespace: 'pre-wrap', marginLeft: "40px" }}>
                                                {
                                                    item.oral_broadcast_demarcate_words.map((wordItem, wordIndex) => {
                                                        return <Text style={{ color: wordItem.is_valid == 1 ? '#1F97FE' : '#ccc', marginLeft: "20px", lineHeight: "40px" }} onClick={() => {
                                                            if (wordItem.is_valid) {
                                                                resultData[index]['oral_broadcast_demarcate_words'][wordIndex]['is_valid'] = 0
                                                            } else {
                                                                resultData[index]['oral_broadcast_demarcate_words'][wordIndex]['is_valid'] = 1
                                                            }
                                                        }}>{wordItem['word']}</Text>
                                                    })
                                                }
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex', width: '1000px',  marginLeft: "32px" }}>
                                        <Form.Item label="分镜数量：" >
                                            <InputNumber min={1} max={8} value={resultData[index]['number_of_mirrors']} style={{ marginLeft: "42px" }}
                                                onChange={(value) => {
                                                    if (value>0 & value<9){
                                                        resultData[index]['number_of_mirrors'] = value
                                                        if (value > resultData[index]['mirrors_list'].length) {
                                                            let num = parseInt(value) - resultData[index]['mirrors_list'].length
                                                            for (let i = 0; i < num; i++) {   
                                                                resultData[index]['mirrors_list'].push({
                                                                    "clause_tag_id": "",            //分镜标签id
                                                                    "copywriting_id": "",        //文案id
                                                                    "mirrors_line_number": "",    //分镜行号
                                                                    "mirrors_tag": []           //分镜标签
                                                                },)
                                                            }
                                                        } else if (value < resultData[index]['mirrors_list'].length) {
                                                            let num = resultData[index]['mirrors_list'].length - parseInt(value)
                                                            for (let i = 0; i < num; i++) {
                                                                resultData[index]['mirrors_list'].pop()
                                                            }
                                                        }
                                                    }else{
                                                        message.warn("请输入正确的分镜数量")
                                                    }
                                        
     
                                                }} />
                                        </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: "32px",  }}>
                                        <Form.Item label="分镜标签">
                                            {
                                                /**
                                                 * draggable 开启可移动属性
                                                 * onDragStart 事件主体是被拖放元素，在开始拖放被拖放元素时触发
                                                 * onDragEnter 事件主体是目标元素，在被拖放元素进入某元素时触发
                                                 */
                                                resultData[index]['mirrors_list'].map((mirrors_list_item, mirrors_list_index) => {
                                                    return <div draggable onDragStart={() => {
                                                        setDragStart(mirrors_list_index);
                                                    }} onDragEnter={() => {
                                                        if (dragStart != mirrors_list_index) {
                                                            resultData[index]['mirrors_list'].splice(mirrors_list_index, 0,
                                                                resultData[index]['mirrors_list'].splice(dragStart, 1)[0])
                                                            setDragStart(-1)
                                                        }
                                                        // console.log(changeIndex(resultData[index]['mirrors_list'],dragStart, mirrors_list_index));
                                                    }} style={{ border: '1px dashed #000', width: "600px", height: "25px", display: 'flex', whitespace: 'pre-wrap', marginLeft: "42px", marginBottom: "5px" }} >
                                                        <div style={{ display: 'flex' }}>
                                                            {
                                                                mirrors_list_item.mirrors_tag.map((tagItem) => {
                                                                    /**
                                                                     * 鼠标右键事件 trigger="contextMenu"
                                                                     */
                                                                    return <Popconfirm trigger="contextMenu" title="是否删除该标签" okText="确定" cancelText="取消" onConfirm={() => {
                                                                        resultData[index]['mirrors_list'][mirrors_list_index]['mirrors_tag'].remove(tagItem)

                                                                    }}>
                                                                        <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px", whiteSpace: "nowrap", lineHeight: "20px" }}>
                                                                            {tagItem.name}
                                                                        </div>
                                                                    </Popconfirm>
                                                                })
                                                            }
                                                            <Popover content={addMirrorsTagList(index, mirrors_list_index)} placement="bottom" zIndex={1000} onMouseLeave={() => {
                                                                setTagsList(tagsData)
                                                            }}>
                                                                <PlusSquareOutlined style={{ lineHeight: '25px', marginLeft: "10px" }} />
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </Form.Item>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "40px" }}>
                        <Button type="primary" onClick={() => { setIsNextWordOpen(false); setIsNextOpen(true); getPlanNextData(planId) }} onOk={planOk}> 上一步 </Button>
                        <Button style={{ marginLeft: "400px" }} type="primary" onClick={() => {onEditNextWordFinish()}} > 下一步 </Button>
                    </div>
                </Form>
            </Modal>
            {/* 创建计划4弹窗 */}
            <Modal title="编辑计划"  centered visible={isNextTotalOpen} onOk={nextTotalOk} onCancel={nextTotalCancel} footer={false} width={"1000px"} maskClosable={false}>
                <div style={{ display: 'flex', width: '960px', }}>
                    <Steps current={currentFourStep}>
                        <Step title="" />
                        <Step title="" />
                        <Step title="" />
                        <Step title="" />
                    </Steps>
                </div>
                <div>
                    <Result status="success" title="任务创建完成" subTitle="可前往素材表进行查看" />
                </div>
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "40px" }}>
                    <Button type="primary" onClick={() => { setIsNextTotalOpen(false); setIsNextWordOpen(true);getPlanNextDataThree() }} > 上一步 </Button>
                    <Button style={{ marginLeft: "400px" }} onClick={() => { screenVideoData(planId) }} type="primary" danger > 下载素材 </Button>
                </div>
            </Modal>


            {/* 配乐查看弹窗 */}
            <Modal title="音频播放"  centered visible={bgmOpen} onOk={bgmOk} onCancel={bgmCancel} footer={false} width={"400px"}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <video width="320" height="240" controls>
                        <source src={`https://pic.bmcsoft.cn/shortvideo/material_library/2fbdc7e2-7f1b-54f2-a34f-3dbb0c8c0124.mp4`} type='video/mp4'></source>
                    </video>
                </div>
            </Modal>
            {/* 文案查看弹窗 */}
            <Modal title="文案"  centered visible={copyWritingOpen} onOk={copyWritingOk} onCancel={copyWritingCancel} footer={false} width={"1000px"}>
                <Form form={form} {...layoutNextWord} >
                    <div>
                        {
                            resultData.map((item, index) => {
                                return <div style={{ width: '900px', boxShadow: "0px 0px 10px rgba(100,100,100,0.4)", overflow: 'auto' }}>
                                    {/* 字幕、字幕切词 */}
                                    <div style={{ display: 'flex', marginTop: "20px", justifyContent: 'space-around' }}>
                                        <Form.Item label="字幕：" name="subtitle" >
                                            <div style={{ whitespace: 'pre-wrap', justifyContent: "center", alignItems: "center", marginLeft: "60px" }} >{item.subtitle}</div>
                                        </Form.Item>
                                        <Form.Item label="字幕切词：" name="scale" >
                                            <div style={{ border: '1px dashed #000', width: "300px", height: "40px", display: 'flex', whitespace: 'pre-wrap', marginLeft: "40px" }}>
                                                {
                                                    item.subtitle_demarcate_words.map((wordItem, wordIndex) => {
                                                        return <Text style={{  marginLeft: "20px", lineHeight: "40px" }} >{wordItem.word}</Text>
                                                    })
                                                }
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: "20px", justifyContent: 'space-around' }}>
                                        <Form.Item label="口播：" name="direction" >
                                            <div style={{ marginLeft: "60px" }} >{item.oral_broadcast}</div>
                                        </Form.Item>
                                        <Form.Item label="口播切词：" name="scale" >
                                            <div style={{ border: '1px dashed #000', width: "300px", height: "40px", display: 'flex', whitespace: 'pre-wrap', marginLeft: "40px" }}>
                                                {
                                                    item.oral_broadcast_demarcate_words.map((wordItem, wordIndex) => {
                                                        return <Text style={{  marginLeft: "20px", lineHeight: "40px" }} >{wordItem.word}</Text>
                                                    })
                                                }
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "20px" }}>
                                        <Form.Item label="分镜标签：" >
                                            <div style={{ display: 'flex', alignItems: "center", flexWrap: 'wrap', padding: "0px" }}>
                                                {
                                                    resultData[index]['mirrors_list']['mirrors_tag'] ? resultData[index]['mirrors_list']['mirrors_tag'].map((tagItem) => {
                                                        return <div style={{ border: `1px solid ${tagItem['color']}`, color: `${tagItem['color']}`, backgroundColor: `${tagItem['color'].substring(0, 7) + '19'}`, padding: "0 3px", textAlign: 'center', borderRadius: "3px", fontSize: "10px", marginRight: "5px" }}>
                                                            {tagItem.name}
                                                        </div>
                                                    }) : <span></span>
                                                }
                                            </div>
                                        </Form.Item>
                                    </div>

                                </div>
                            })
                        }
                    </div>
                </Form>
            </Modal>

        </div>
    )
}

export default Index
