import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, DatePicker, Modal, Upload, message, Collapse, Input, Form, Select, Table, Popconfirm, Tooltip, Drawer } from 'antd'
import { CloudUploadOutlined, DeleteOutlined, EditOutlined, SearchOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard'
import servicePath from '../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import '../../../static/css/index.css'
import axios from 'axios'
const { RangePicker } = DatePicker;
const { Option } = Select
function Preview(props) {

    const [form] = Form.useForm();

    const [form1] = Form.useForm()

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    }

    // 数据
    const [date, setDate] = useState([])


    // 总数页数
    const [total, setTotal] = useState(0)

    //页面
    const [page, setPage] = useState(1)

    // id
    const [id, setId] = useState(0)

    // 操作时候的采购单号
    const [operationPurchaseNumber, setOperationPurchaseNumber] = useState('')

    // 采购单号
    const [purchaseNumber, setPurchaseNumber] = useState('')

    // 创建人
    const [creator, setCreator] = useState('')

    // 开始时间
    const [startTime, setStartTime] = useState('')

    // 结束时间
    const [endTime, setEndTime] = useState('')

    // 品牌
    const [trademark, setTrademark] = useState('')

    // 操作时品牌
    const [operationTrademark, setOperationTrademark] = useState('')

    // 修改采购状态
    const [updateStatus, setUpdateStatus] = useState('')

    // 采购状态
    const [status, setStatus] = useState('')

    // 打开创建采购单
    const [openCreate, setOpenCreate] = useState(false)

    // 打开修改采购单
    const [openUpdate, setOpenUpdate] = useState(false)

    // 上传采购单
    const [uploadPurchaseOrders, setUploadPurchaseOrders] = useState(false)

    // 打开搜索框
    const [openSearch, setOpenSearch] = useState(false)

    // 采购单列表
    const [purchaseOrdersList, setPurchaseOrdersList] = useState([])

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 初始化数据
    useEffect(() => {
        if (props.location.state == undefined) {
            getDate()
        } else {
            let dataProps = {
                "cgdh": props.location.state.orderNumber,
                "cgzt": status,
                "cgdcjr": creator,
                "kssj": startTime,
                "jssj": endTime,
                "pp": trademark,
                "page": "1",
                "pageSize": "10",
                "roleId": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.getPurchaseOrder,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data.success) {
                        setTotal(res.data.total)
                        setDate(res.data.data)
                    } else {
                        message.error(res.data.errorMsg)
                    }
                }
            )
        }
    }, [])



    // 获取数据
    const getDate = () => {
        let dataProps = {
            "cgdh": purchaseNumber,
            "cgzt": status,
            "cgdcjr": creator,
            "kssj": startTime,
            "jssj": endTime,
            "pp": trademark,
            "page": page,
            "pageSize": "10",
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 表格标题
    const columns = [
        {
            title: '采购单号',
            key: 'cgdh',
            dataIndex: 'cgdh',
            width: '150px',
            align: 'center',
            fixed: 'left',
            render:(text,record,index)=>(
                <CopyToClipboard text={text} onCopy={() => message.success('复制成功')}>
                    <span>{text}</span>
                </CopyToClipboard>
            )
        },
        {
            title: '创建时间',
            key: 'cgdcjsj',
            dataIndex: 'cgdcjsj',
            width: '100px',
            align: 'center'
        },
        {
            title: '采购状态',
            key: 'cgzt',
            dataIndex: 'cgzt',
            width: '100px',
            align: 'center'
        },
        {
            title: '创建人',
            key: 'cgdcjr',
            dataIndex: 'cgdcjr',
            width: '100px',
            align: 'center'
        },
        {
            title: '查看采购单',
            key: 'date',
            dataIndex: 'date',
            width: '100px',
            align: 'center',
            render: (text, record, index) => (
                <SearchOutlined onClick={() => {
                    let data = date[index]
                    let info = data['cgdh'] + ',' + data['cgzt']
                    props.history.push({ 'pathname': '/app/presell/details', 'state': { 'infomation': info } })
                }} />
            ),
        },
        {
            title: '下载E3采购单',
            key: 'styleNumber',
            dataIndex: 'styleNumber',
            width: '100px',
            align: 'center',
            render: (text, record, index) => (
                <a href={servicePath.downloadPurchaseOrderE3 + '?cgdh=' + date[index]['cgdh']} target={"_blank"}><CloudDownloadOutlined style={{ fontSize: '20px' }} /></a>
            ),
        },
        {
            title: '下载聚水潭采购单',
            key: 'productId',
            dataIndex: 'productId',
            width: '150px',
            align: 'center',
            render: (text, record, index) => (
                <a href={servicePath.downloadPurchaseOrderJST + '?cgdh=' + date[index]['cgdh']} target={"_blank"}><CloudDownloadOutlined style={{ fontSize: '20px' }} /></a>
            ),
        },
        {
            title: '操作',
            key: 'delete',
            dataIndex: 'delete',
            width: '150px',
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => (
                <Row>
                    <Col>
                        <Tooltip title="修改">
                            <EditOutlined style={{fontSize:'20px', marginLeft:'35px'}} onClick={() => {
                                form1.resetFields()
                                let data = date[index]
                                setOperationPurchaseNumber(data['cgdh'])
                                setOperationTrademark(data['pp'])
                                setId(data['id'])
                                setUpdateStatus(data['cgzt'])
                                setOpenUpdate(true)
                            }} />
                        </Tooltip>
                    </Col>
                    <Col style={{display: date[index]['cgzt'] != '待采购' ? 'none': ''}}>
                        <Tooltip title="删除">
                            <Popconfirm
                                title="确认删除?删除后不可恢复!"
                                onConfirm={affirmDelect}
                                onCancel={cancelDelect}
                                okText="确定"
                                cancelText="取消"
                            >
                                <DeleteOutlined style={{fontSize:'20px', marginLeft:'30px'}} onClick={() => {
                                    let data = date[index]
                                    setOperationPurchaseNumber(data['cgdh'])
                                    setId(data['id'])
                                }} />
                            </Popconfirm>
                        </Tooltip>
                    </Col>
                </Row>

            ),
        },
    ]

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 页面修改的时候
    useEffect(()=>{
        getDate()
    },[page])

    // 上传缺货单
    const uploadPreviewOrdersButton = (
        <Button type="primary">
            <CloudUploadOutlined /> 上传采购单
        </Button>
    );

    // 初始化数据
    useEffect(() => {
        if (props.location.state != undefined) {
            let orderNumber = props.location.state.orderNumber
            setPurchaseNumber(orderNumber)
        }
    }, [])



    // 缺货单列表改变的时候出发
    const previewOrdersHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx', 'xls', 'csv']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                // let flag = fileList[fileList.length-1].originFileObj['size'] / 1024 / 1024 < 50
                // // 在判断文件的大小
                // if (flag){
                //     setDocList(fileList)
                // }else{
                //     message.error('文件大小必须小于50M')
                // }
                setPurchaseOrdersList(fileList)
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setPurchaseOrdersList(fileList)
        }
    }


    // 确认上传采购单
    const affirmUploadPurchaseOrders = () => {
        setEffective(1)
        // 上传原始文件例子
        const formData = new FormData()
        purchaseOrdersList.forEach((item) => {
            formData.append('file', item['originFileObj']);
            formData.append('cgdcjr', cookie.load('name'));
            formData.append('roleId', cookie.load('role'));
            formData.append('shopId', cookie.load('shopList'));
        })
        axios({
            method: 'POST',
            url: servicePath.uploadPurchaseOrder,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    message.success("成功上传采购单")
                    getDate()
                    setUploadPurchaseOrders(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }


    // 关闭上传采购单
    const closeUploadPurchaseOrders = () => {
        message.success("取消上传采购单")
        setUploadPurchaseOrders(false)
    }

    // 确认创建采购单
    const affirmCreate = () => {
        if (operationPurchaseNumber == '') {
            message.error('采购单号不能为空')
        }else if(!/^\d{12}$/.test(operationPurchaseNumber)){
            message.error('采购单号必须为12位纯数字')
        }else{
            let dataProps = {
                "cgdh": operationPurchaseNumber,
                "cgdcjr": cookie.load('name'),
                "id": [],
                "spbm": [],
                "num": [],
                "roleId": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.creatPurchaseOrder,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data.success) {
                        message.success("采购单创建成功")
                        let info = operationPurchaseNumber + ',' + '待采购'
                        setOperationPurchaseNumber('')
                        // 创建成功跳转
                        props.history.push({ 'pathname': '/app/presell/details', 'state': { 'infomation': info } })
                        setOpenCreate(false)
                    } else {
                        message.error(res.data.errorMsg)
                    }
                }
            )
        }
        
    }

    // 取消创建采购单
    const closeCreate = () => {
        message.success("取消创建采购单")
        setOpenCreate(false)
    }

    // 确认修改采购单
    const affirmUpdate = () => {
        let dataProps = {
            "id": id,
            "cgzt": updateStatus,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.updatePurchaseOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    message.success("成功修改采购单状态")
                    setOperationPurchaseNumber('')
                    setOperationTrademark('')
                    setOpenUpdate(false)
                    setUpdateStatus('')
                    getDate()
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 取消修改采购单
    const closeUpdate = () => {
        message.success("取消修改采购单状态")
        setOperationPurchaseNumber('')
        setOperationTrademark('')
        setUpdateStatus('')
        setOpenUpdate(false)
    }

    // 确认删除采购单
    const affirmDelect = () => {
        let dataProps = {
            "id": id,
            "cgdh": operationPurchaseNumber,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.deletePurchaseOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    message.success("成功删除该采购单")
                    getDate()
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }
    // 取消删除采购单
    const cancelDelect = () => {

    }

    // 查询条件
    const search = () => {
        if (page == 1) {
            getDate()
        } else {
            setPage(1)
        }
        setOpenSearch(false)
    }

    // 重置条件
    const reset = () => {
        form.resetFields()
        setPurchaseNumber('')
        setStatus('')
        setStartTime('')
        setEndTime('')
        setTrademark('')
        setCreator('')
        let dataProps = {
            "cgdh": "",
            "cgzt": "",
            "cgdcjr": "",
            "kssj": "",
            "jssj": "",
            "pp": "",
            "page": "1",
            "pageSize": "10",
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data)
                    setOpenSearch(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

      // 关闭搜索框
      const onSearchClose = () => {
        setOpenSearch(false)
    }

    return (
        <div style={{ marginLeft: size.width * 0.06 }}>
            {/* 导入按钮区域 */}
            <div style={{ marginTop: size.height * 0.02, marginBottom: size.height * 0.02 }}>
                <Button style={{ marginLeft: '10px', marginRight: '50px' }} type="primary" onClick={() => setOpenCreate(true)}>创建采购单</Button>
                <Button icon={<CloudUploadOutlined />} type="primary" onClick={() => setUploadPurchaseOrders(true)}>导入E3采购单</Button>
                <Button onClick={() => setOpenSearch(true)} style={{ marginLeft: '50px' }}>灵活查询</Button>
            </div>
            <Drawer
                title="查询条件"
                placement="right"
                closable={false}
                onClose={onSearchClose}
                visible={openSearch}
                width={500}
            >
                 <Form
                        form={form}
                        {...layout}
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="purchaseNumber"
                                    label="采购单号"
                                >
                                    <Input
                                        id='purchaseNumber'
                                        placeholder="请输入采购单号"
                                        value={purchaseNumber}
                                        defaultValue={purchaseNumber}
                                        onChange={(e) => {
                                            setPurchaseNumber(e.target.value)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="platform"
                                    label="采购状态"
                                >
                                    <Select
                                        placeholder="请选择采购状态"
                                        onChange={(value) => { setStatus(value); }}
                                    >
                                        <Option value="待采购">待采购</Option>
                                        <Option value="待发货">待发货</Option>
                                        <Option value="已发货">已发货</Option>
                                        <Option value="已签收">已签收</Option>
                                        <Option value="已入库">已入库</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="creator"
                                    label="创建人"
                                >
                                    <Input
                                        id='creator'
                                        value={creator}
                                        placeholder="请输入创建人"
                                        onChange={(e) => {
                                            setCreator(e.target.value)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            
                            <Col span={12}>
                                <Form.Item
                                    name="trademark"
                                    label="品牌"
                                >
                                    <Select
                                        placeholder="请选择品牌"
                                        onChange={(value) => { setTrademark(value); }}
                                    >
                                        <Option value="恩曼琳">恩曼琳</Option>
                                        <Option value="卡洛琳">卡洛琳</Option>
                                        <Option value="巨式">巨式</Option>
                                        <Option value="卓雅">卓雅</Option>
                                        <Option value="卓雅周末">卓雅周末</Option>
                                        <Option value="其他品牌">其他品牌</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="time"
                                    label="采购时间"
                                >
                                    <RangePicker
                                        showTime
                                        locale={locale}
                                        style={{ width: 375 }}
                                        format="YYYY-MM-DD HH:mm"
                                        onChange={(dates, dateStrings) => {
                                            setStartTime(dateStrings[0]);
                                            setEndTime(dateStrings[1]);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button type="primary" style={{ marginRight: "10px", marginLeft: '150px' }} onClick={() => search()}>查询</Button>
                                <Button type="primary" danger onClick={() => reset()}>重置</Button>
                            </Col>
                        </Row>
                    </Form>
            </Drawer>
            <div style={{ marginTop: size.height * 0.02, marginRight: size.width * 0.06 }}>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                    scroll={{ x:'70vw', y: `${size.width>1500?'75vh':'69vh'}` }}
                    onRow={function (record, index) {
                        return (
                            {
                                style: {
                                    backgroundColor: index % 2 == 0 ? '#D3D3D3' : '#FFFFFF'
                                },
                            }
                        )
                    }}
                />
            </div>
            <Modal
                title="上传采购单"
                centered={true}
                visible={uploadPurchaseOrders}
                maskClosable={false}
                onOk={affirmUploadPurchaseOrders}
                onCancel={closeUploadPurchaseOrders}
                okText="确认上传"
                cancelText="取消上传"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Upload
                    action={servicePath.image}                          //上传地址，填错/不填上传图片的时候都会报错
                    listType="picture"                             // 上传列表的内建样式
                    fileList={purchaseOrdersList}                                 //已经上传的文件列表（受控）
                    onChange={previewOrdersHandleChange}                             //上传文件改变时的状态
                    accept=".xlsx,.xls,.csv"                                    //设置文件上传类型
                >
                    {purchaseOrdersList.length >= 1 ? null : uploadPreviewOrdersButton}
                </Upload>
            </Modal>

            <Modal
                title="创建采购单"
                centered={true}
                visible={openCreate}
                maskClosable={false}
                onOk={affirmCreate}
                onCancel={closeCreate}
                okText="创建采购单"
                cancelText="取消"
            >
                <Form form={form1} {...layout1}  labelAlign='left'>
                    <Form.Item
                        name="purchase"
                        label="采购单号"
                    >
                        <Input
                            id='purchase'
                            value={operationPurchaseNumber}
                            placeholder="请输入采购单号"
                            onChange={(e) => {
                                setOperationPurchaseNumber(e.target.value)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="creator"
                        label="采购单创建人"
                    >
                        <Input
                            id='creator'
                            defaultValue={cookie.load('name')}
                            disabled
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="修改采购单"
                centered={true}
                visible={openUpdate}
                onOk={affirmUpdate}
                onCancel={closeUpdate}
                okText="保存"
                cancelText="取消"
            >
                <Form form={form1} {...layout1}  labelAlign='left'>
                    <Form.Item
                        name="purchase"
                        label="采购单号"
                    >
                        <Input
                            id='trademark'
                            defaultValue={operationPurchaseNumber}
                            disabled={true}
                        />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="采购单状态"
                    >
                        <Select
                            placeholder="请选择采购状态"
                            defaultValue={updateStatus}
                            onChange={(value) => { setUpdateStatus(value); }}
                        >
                            <Option value="待发货">待发货</Option>
                            <Option value="已发货">已发货</Option>
                            <Option value="已签收">已签收</Option>
                            <Option value="已入库">已入库</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>


        </div>
    )

}

export default Preview