import React, { useEffect, useState } from 'react';
import { Typography, DatePicker, Divider, Timeline, Modal, Button, message, Tooltip, Radio } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment'
import NbAxios from '../../../config/utils/nbAxios';
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl';
import Axios from 'axios';
import axios from 'axios';
import downExcel from '../../../config/utils/downloadFile';
const { Title, Text } = Typography
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD';
function Index(props) {
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 活动标签
    const [activityLabel, setActivityLabel] = useState([])
    // 选择活动标签
    const [ chooseActivityLable , setChooseActivityLable ] = useState('')
    // 活动信息集合
    const [ activityMessageList , setActivityMessageList] = useState([])
    // 活动信息
    const [ activityMessage, setActivityMessage] = useState({})
    // 打开活动信息
    const [ openActivityMessage, setOpenActivityMessage] = useState(false)
    // 当前开始时间
    // const [startTime, setStartTime] = useState(moment().subtract(1, 'M').format('YYYY-MM-DD'))
    const [startTime, setStartTime] = useState(moment().format('YYYY-MM-DD'))
    // 当前开始时间
    const [endTime, setEndTime] = useState(moment().add(1,'M').format('YYYY-MM-DD'))
    // 是否下载数据
    const [ isDownloadFile , setIsDownloadFile ] = useState(false)
    // 是否是品牌方的Excel
    const [ isBrandFile, setIsBrandFile ] = useState(1)
    // 下载的时间选择
    const [ downloadStartTime , setDownloadStartTime]  = useState(moment().subtract(1, 'M').format('YYYY-MM-DD'))
    const [ downloadEndTime , setDownloadEndTime] = useState(moment().format('YYYY-MM-DD'))
    useEffect(()=>{
        getActivityLabel()
    },[])

    useEffect(()=>{
        if(chooseActivityLable != ''){
            getSelectionPricingSchemeList()
        }
    },[chooseActivityLable])
    // 查询活动标签接口
    const getActivityLabel = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "start_time": startTime,
            "end_time": endTime
        }
        NbAxios(dataProps, "POST", servicePath.queryActivityLabel).then(res => {
            if (res.success) {
                setActivityLabel(res.data);
                setChooseActivityLable(res.data[0])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 查询选款定价历史记录接口
    const getSelectionPricingSchemeList = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "start_time": startTime,
            "end_time": endTime,
            'label':chooseActivityLable
        }
        NbAxios(dataProps, "POST", servicePath.querySelectionPricingSchemeList).then(res => {
            if (res.success) {
                setActivityMessageList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

   
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '70vw 28vw' }}>
            <div>
                <div style={{display:chooseActivityLable == '' ? 'none' : ''}}>
                    <div style={{marginTop:'30vh', marginLeft:'30vw', }}>
                        <Title level={3}>本次选款是预售活动还是正式活动?</Title>
                    </div>
                    <div style={{marginTop:100,marginLeft:'20vw', display:'flex'}}>
                        {/* <div style={{width:300, height:200, border:"1px solid #ccc", display:"flex", justifyContent:'center', alignItems:'center', fontWeight:350, fontSize:30}}>
                            预售活动
                        </div> */}
                        <Tooltip placement="topLeft" title={"功能暂时不提供"} color={'cyan'}>
                            <Button 
                                disabled
                                style={{width:300, height:200, border:"1px solid #ccc", 
                                display:"flex", 
                                justifyContent:'center', alignItems:'center', 
                                fontWeight:350, fontSize:30}}
                            >预售活动</Button>
                        </Tooltip>
                        <Button 
                            style={{width:300, height:200, 
                            border:"1px solid #ccc", display:"flex",
                            justifyContent:'center', alignItems:'center', 
                            fontWeight:350, fontSize:30, 
                            marginLeft:'10vw'}}
                            onClick={()=>{
                                let dataProps = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "label": chooseActivityLable,
                                    "type": "正式",
                                    "creator":cookie.load('name')
                                }
                                NbAxios(dataProps, "POST", servicePath.createSelectionPricingScheme).then(res => {
                                    if (res.success) {
                                        // /app/vgrass/selectionPricing/formalBenefit
                                        message.success('成功创建选款方案，请前往编辑')
                                        getSelectionPricingSchemeList()
                                        props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/selectionPricing/formalBenefit`, 
                                        'state':{'name':res.data['name'],'creator':res.data['creator'],'create_time': res.data['create_time'],'update_time':res.data['update_time']}})
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                })
                            }}
                        >
                            正式活动
                        </Button>
                    </div>
                </div>
            </div>
            <div style={{ borderLeft: '1px solid #ccc', height: 'calc(100vh - 40px)' }}>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                    <Title level={3}>历史记录</Title>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                    <RangePicker value={[moment(startTime, dateFormat),moment(endTime, dateFormat)]} locale={locale} style={{ borderRadius: 30 }} 
                        onChange={(value, dateString)=>{
                            if(dateString[0] == ''){
                                message.warn('请选择合理的时间')
                            }else{
                                setStartTime(dateString[0]);
                                setEndTime(dateString[1]);
                            }
                        }} 
                    />
                    <SearchOutlined style={{marginTop:10, marginLeft:10, display:downloadEndTime == '' ? 'none' : ''}} onClick={()=>{
                        getActivityLabel()
                    }}/>
                </div>
                <Divider />
                <div>
                    <Text style={{ marginLeft: 10, fontSize: 20 }}>选择活动:</Text>
                </div>
                <div style={{ display: "flex", marginLeft: 30, flexWrap: "wrap" }}>
                    {
                        activityLabel.map(item => {
                            return <div style={{
                                width: 200, border: chooseActivityLable == item ? '1px solid #ff3131' : '1px solid #188fff',
                                color: chooseActivityLable == item ? '#ff3131' : '#188fff', marginRight: 20, display: 'flex',
                                justifyContent: "center", borderRadius: 50, marginTop: 10
                            }} onClick={()=>{
                                setChooseActivityLable(item)
                            }}>{item}</div>
                        })
                    }
                </div>
                <Divider />
                <div style={{marginLeft:30, height:"55vh", overflow:'auto'}}>
                    <Timeline>
                        {
                            activityMessageList.map(item=>{
                                return  <Timeline.Item color={item.type == 1 ? 'blue':'red'}>
                                <div style={{ boxShadow: "-2px 2px 4px #9e9e9e",padding:20, fontSize:14}} onClick={()=>{
                                    setActivityMessage(item);
                                    setOpenActivityMessage(true)
                                }}>
                                    <div><span style={{marginRight:5}}>活动名称:</span><span>{item.name}</span></div>
                                    <div><span style={{marginRight:5}}>创建人:</span><span>{item.creator}</span></div>
                                    <div><span style={{marginRight:5}}>创建时间:</span><span>{item.create_time}</span></div>
                                    <div><span style={{marginRight:5}}>更新时间:</span><span>{item.update_time}</span></div>
                                </div>
                            </Timeline.Item>
                            })
                        }
                    </Timeline>
                </div>
            </div>

            {/* 活动信息 */}
            <Modal
                title=""
                centered={true}
                footer={[]}
                visible={openActivityMessage}
                onCancel={()=>{
                    setOpenActivityMessage(false)
                }}
                closable={false}
            >
                <div>
                    <div style={{display:'flex', justifyContent:'center'}}><span style={{marginRight:5}}>活动名称:</span><span>{activityMessage['name']}</span></div>
                    <div style={{display:'flex', justifyContent:'center'}}><span style={{marginRight:5}}>创建人:</span><span>{activityMessage['creator']}</span></div>
                    <div style={{display:'flex', justifyContent:'center'}}><span style={{marginRight:5}}>创建时间:</span><span>{activityMessage['create_time']}</span></div>
                    <div style={{display:'flex', justifyContent:'center'}}><span style={{marginRight:5}}>更新时间:</span><span>{activityMessage['update_time']}</span></div>
                    <div style={{display:'flex', justifyContent:"space-around", marginTop:20}}>
                        {/* style={{display:activityMessage['isEdit'] ? '':'none'}} */}
                        <Button type="primary"  onClick={()=>{
                            props.history.push({
                                'pathname': `/app/${props.history.location.pathname.split('/')[2]}/selectionPricing/formal`,
                                'state': { 'name': activityMessage['name'] }
                            })    
                        }}>选款修改</Button>
                        <Button type="primary" onClick={()=>{
                            props.history.push({
                                'pathname': `/app/${props.history.location.pathname.split('/')[2]}/selectionPricing/formalSummary`,
                                'state': { 'name': activityMessage['name'] }
                            })    
                        }}>汇总信息</Button>
                        <Button type="primary" danger onClick={()=>setIsDownloadFile(true)}>导出Excel</Button>
                        <Button onClick={()=>{
                            setOpenActivityMessage(false)
                        }}>取消</Button>
                    </div>
                </div>

            </Modal>
                        
            <Modal
                title="导出Excel"
                visible={isDownloadFile}
                onCancel={()=>{
                    setIsDownloadFile(false)
                }}
                footer={[]}
                centered={true}
            >
                <div style={{display:'flex', justifyContent:'center'}}>
                    <Radio.Group value={isBrandFile} onChange={(e)=>{
                        setIsBrandFile(e.target.value)
                    }}>
                        <Radio value={1}>导出给品牌方的Excel</Radio>
                        <Radio value={2}>导出上传平台的Excel</Radio>
                    </Radio.Group>
                </div>
                <div style={{display:isBrandFile == 1 ? 'flex' : 'none', justifyContent:'center', marginTop:30}}>
                    <b style={{marginRight:10, marginTop:5}}>比较活动时间:</b>
                    <RangePicker 
                        disabledDate={disabledDate}
                        value={[moment(downloadStartTime, dateFormat),moment(downloadEndTime, dateFormat)]} 
                        locale={locale} style={{ borderRadius: 30 }}  
                        onChange={(value, dateString) => {
                            if (dateString[0] == '') {
                                message.warn('请选择合理的时间')
                            } else {
                                setDownloadStartTime(dateString[0]);
                                setDownloadEndTime(dateString[1]);
                            }

                        }}
                    />
                </div>
                <div style={{display:'flex', justifyContent:'center', marginTop:30}}>
                <Button disabled={effective ? true : false} type="primary" onClick={()=>{
                        if(isBrandFile == 1){
                            if(downloadStartTime == ''){
                                message.warn('请选择近期活动时间')
                            }else{
                                setEffective(1)
                                let key = "download"
                                message.loading({ content: '开始下载数据...', key, duration: 100 })
                                let dataProps = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "name": activityMessage['name'],
                                    "start_time": downloadStartTime,
                                    "end_time": downloadEndTime,
                                    'creator':cookie.load('name')
                                }
                                Axios({
                                    method: 'POST',
                                    url: servicePath.downloadSelectionPricingResultDetail,
                                    data: dataProps,
                                    responseType: "blob",
                                    headers: {
                                        'authorization': cookie.load('token')
                                    },
                                }).then(res => {
                                    setEffective(0)
                                    downExcel(res)
                                    setIsDownloadFile(false)
                                    message.success({ content: "导出成功！", key })
                                }).catch(e => {
                                    setEffective(0)
                                    message.error({ content: '文件制作出错，请联系it同事！', key })
                                })
                            }
                        }else{
                            let dataProps = {
                                "roleId": cookie.load('role'),
                                "shopList": cookie.load('shopList'),
                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                'name': activityMessage['name'],
                                'creator':cookie.load('name')
                            }
                            axios({
                                data: dataProps,
                                method: 'POST',
                                url: servicePath.downloadSelectionPricingResult,
                                responseType: "blob",
                                headers: {
                                    'Content-Type': 'application/json',  //如果写成contentType会报错
                                    'authorization': cookie.load('token')
                                }
                            }).then(
                                res => {
                                    downExcel(res)
                                }
                            ).catch(e => {
                                message.error({ content: '文件制作出错，请联系it同事！' })
                            })
                        }
                    }}>下载</Button>
                </div>
            </Modal>
        </div>
    )

}


export default Index