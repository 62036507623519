import React, { useState, useEffect } from 'react';
import {Image, Skeleton, Typography, Badge, Input, Button, message} from 'antd'
import NbAxios from '../../../config/utils/nbAxios';
const { Title, Text } = Typography
function Index() {
    // 点心
    const [dessertList, setDesserList] = useState([])
    // 取餐码
    const [ takeCode , setTakeCode ] = useState('')
    useEffect(()=>{
        getHalfMealTodayDessert()
    },[])

    const getHalfMealTodayDessert = ()=>{
        let values = {}
        NbAxios(values, "POST", 'http://192.168.66.115:35000/queryTodayDessert').then(res => {
            if (res.success) {
                setDesserList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div style={{position:"relative", height:'100vh'}}>
             <div style={{
                display: 'flex',
                justifyContent: "center",
                backgroundColor: "#fff",
                borderBottom: "solid 2px #ccc",
                position: 'fixed',
                top: 0,
                zIndex: 99,
                width:'100vw',
                paddingTop:10
            }}>
                <Title level={5}>今日点心</Title>
            </div>
            <div style={{height:'100%', width:'100vw', paddingTop:50, overflow:'auto', paddingBottom:100}}>
                <div>
                    {
                        dessertList.map(item=>{
                            return <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                {
                                    item.image == '' ? <Skeleton.Image style={{ width: '80vw', height: 135 }} /> : <Image src={`https://pic.bmcsoft.cn/` + item.image} style={{ width: '80vw', height: 135 }} />
                                }
                                <Text type="secondary" style={{marginTop:5, marginBottom:5}}>{item.name}</Text>
                            </div>
                        })
                    }
                </div>
            </div>
            <div style={{position: "absolute",bottom: 0, borderTop:"1px solid #ccc", width:'100vw', backgroundColor: "#fff", paddingBottom:20}}>
                <div style={{display:'flex', paddingTop:5}}>
                    <b style={{marginRight:20, marginLeft:10, marginTop:5}}>取餐码:</b>
                    <Input value={takeCode} style={{width:'50vw'}} onChange={(e)=>{
                        setTakeCode(e.target.value)
                    }}/>
                </div>
                <div style={{display:'flex', justifyContent:'center', paddingTop:10}}>
                    <Button type="primary" onClick={()=>{
                        if(takeCode.length == 0){
                            message.warn('请输入正确的取餐码')
                        }else{
                            let values = {}
                            values.takeCode = takeCode
                            NbAxios(values, "POST", 'http://192.168.66.115:35000/checkTakeCode').then(res => {
                                if (res.success) {
                                    message.success('取餐成功')
                                    setTakeCode('')
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }
                    }}>确认取餐</Button>
                </div>
            </div>
        </div>
    )

}

export default Index