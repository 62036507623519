import React, { useEffect } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

function Index({
    fansData,
    newFans,
    increaseFans,
    takeOffFans,
    chartWidth,
    chartHeight
}){

    // 日粉丝新增趋势/金额趋势
    let fansChartOption = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['新增粉丝数', '净增粉丝数', '取关粉丝数']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: fansData
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
          {
            name: '新增粉丝数',
            type: 'line',
            data: newFans
          },
          {
            name: '净增粉丝数',
            type: 'line',
            data: increaseFans
          },
          {
            name: '取关粉丝数',
            type: 'bar',
            data: takeOffFans
          }
        ]
    }

    useEffect(()=>{
        let fansChart = echarts.init(document.getElementById('fans'))
        fansChart.clear();    //清除残留渲染
        // 绘制图表
        fansChart.setOption(fansChartOption);
        window.addEventListener('resize', () => {
            fansChart.resize()
        });
    },[fansData])

    return (
        <div id="fans" style={{ width: `calc(${chartWidth})`, height: `calc(${chartHeight})`}}></div>
    )

}

export default Index