import React, {useEffect, useState} from 'react';
import {
    Table,
    Form,
    Button,
    Typography,
    Row,
    Input,
    Select,
    message,
    Space,
    Popconfirm,
    Modal,
    Collapse,
    Spin
} from 'antd'
import {DeleteOutlined, SearchOutlined, DownOutlined, RightOutlined} from '@ant-design/icons';
import cookie from 'react-cookies'
import Base64 from 'base-64'
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'
import Highlighter from 'react-highlight-words';

const {Title, Text} = Typography
const {Option} = Select;
const {TextArea} = Input
const {Panel} = Collapse;

// 创建事件模型
function Index(props) {
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv':"3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein':"444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an':"7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr':"f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11':'d4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass':'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }

    // 定义筛选功能开始
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };
    // 定义筛选功能结束
    const [form] = Form.useForm();

    const genExtra = (id, name) => (
        // <Popconfirm
        //     // placement="rightTop"
        //     title="删除操作"
        //     onConfirm={() => {
        //         let dataProps = {}
        //         dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        //         dataProps.roleId = cookie.load('role')
        //         dataProps.otm_stage_model_id = id
        //         NbAxios(dataProps, "POST", servicePath.deleteOtmStageModel).then(res => {
        //             if (res.success) {
        //                 message.success("阶段删除成功")
        //                 getOtmStageModel()
        //             } else {
        //                 message.warn(res.errorMsg)
        //             }
        //         })
        //     }}
        //     onCancel={() => { }}
        //     okText="确定"
        //     cancelText="取消"
        // >
        //     <DeleteOutlined />
        // </Popconfirm>
        <DeleteOutlined onClick={() => {
            setStageId(id)
            setStageName(name)
            setDeleteStage(true)
        }}/>
    );
    // 打开的key
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    // 添加操作打开的key
    const [addExpandedRowKeys, setAddExpandedRowKeys] = useState([])
    // 添加操作模型
    const [addOperation, setAddOperation] = useState(false)
    // 阶段id
    const [stageId, setStageId] = useState('')
    // 阶段
    const [ stage , setStage ] = useState('')
    // 阶段名称
    const [stageName, setStageName] = useState('')
    // 新增阶段
    const [addStage, setAddStage] = useState(false)
    // 删除阶段
    const [deleteStage, setDeleteStage] = useState(false)
    // 选择的id
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 添加的时候选择的id
    // const [addSelectedRowKeys, setAddSelectedRowKeys] = useState([])
    // 事件阶段列表
    const [stageList, setStageList] = useState([])
    // 数据
    const [data, setData] = useState([])
    // 数据是否在加载中
    const [isSpinning, setIsSpinning] = useState(false)
    // 折叠框key
    const [collapseKey, setCollapseKey] = useState('')
    // 已存在操作
    const [existedOperationData, setExistedOperationData] = useState([])
    // 操作数据
    const [operationData, setOperationData] = useState([])
    useEffect(() => {
        // 查询事件阶段列表
        getOtmStageList()
        //查询事件阶段模型
        getOtmStageModel()
        // 查询操作模型接口
        getOtmOperationModel()
    }, [])

    let operationColumns = [
        {
            title: '操作名字',
            dataIndex: 'operation_name',
            key: 'operation_name',
            align: 'center',
            ...getColumnSearchProps('operation_name'),
        },
        {
            title: '业务分类',
            dataIndex: 'type_business',
            key: 'type_business',
            align: 'center',
            ...getColumnSearchProps('type_business'),
        },
        {
            title: '人员分类',
            dataIndex: 'type_people',
            key: 'type_people',
            align: 'center',
            ...getColumnSearchProps('type_people'),
        },
    ]

    // 查询操作模型接口
    const getOtmOperationModel = () => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.type_business = ''
        dataProps.operation_name = ''
        dataProps.page = 1
        dataProps.page_size = 1000
        NbAxios(dataProps, "POST", servicePath.queryOtmOperationModel).then(res => {
            if (res.success) {
                setOperationData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询事件阶段模型
    const getOtmStageModel = () => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.otm_event_model_id = props.location.state['id']
        dataProps.otm_event_model_name = props.location.state['name']
        dataProps.level = props.location.state['level']
        NbAxios(dataProps, "POST", servicePath.queryOtmStageModel).then(res => {
            if (res.success) {
                setData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询事件阶段列表
    const getOtmStageList = () => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.level = props.location.state.level
        NbAxios(dataProps, "POST", servicePath.queryOtmStageList).then(res => {
            if (res.success) {
                setStageList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 多选设置
    const onSelectChange = (value) => {
        setSelectedRowKeys(value)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    //添加的时候多设置
    // const onAddSelectChange = selectedRowKeys => {
    //     setAddSelectedRowKeys(selectedRowKeys)
    // };
    // const addRowSelection = {
    //     addSelectedRowKeys,
    //     onChange: onAddSelectChange,
    // }

    //新增阶段
    const addStageFinish = value => {
        setIsSpinning(true)
        value.name = props.location.state.name+ '-' + stage
        value.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        value.shopList = cookie.load('shopList')
        value.roleId = cookie.load('role')
        value.otm_event_model_id = props.location.state['id']
        value.otm_event_model_name = props.location.state['name']
        value.level = props.location.state['level']
        value.creator = cookie.load('name')
        value.creator_id = cookie.load('id')
        value.creator_username = Base64.decode(cookie.load('email'))
        NbAxios(value, "POST", servicePath.addOtmStageModel).then(res => {
            setIsSpinning(false)
            if (res.success) {
                message.success("事件阶段创建成功")
                setStage('')
                getOtmStageModel()
                form.resetFields()
                setAddStage(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询折叠框
    const openCollapse = (id) => {
        setIsSpinning(true)
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.otm_stage_model_id = id
        NbAxios(dataProps, "POST", servicePath.queryOtmStageModelOperationModelOperation).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setExistedOperationData(res.data);
                setCollapseKey(id)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 删除操作
    const deleteOperation = () => {
        setIsSpinning(true)
        let dataProps = {}
        dataProps.id = selectedRowKeys
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        NbAxios(dataProps, "POST", servicePath.deleteOtmStageModelOperationModelOperation).then(res => {
            setIsSpinning(false)
            if (res.success) {
                message.success("删除阶段模型操作模型成功")
                setSelectedRowKeys([])
                openCollapse(collapseKey)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div>
            <div style={{marginLeft: '50px', marginTop: "30px"}}>
                <Title level={3}>{props.location.state.name}</Title>
            </div>
            <div style={{marginLeft: '50px',}}>
                <Button type="primary" onClick={() => {
                    form.resetFields()
                    setAddStage(true)
                }} style={{marginRight: 10}}>新增阶段</Button>
                <Button type="dashed" onClick={() => {
                    props.history.push({'pathname': `/app/otm/${props.history.location.pathname.split('/')[3]}/event`,})
                }}>事件创建</Button>
            </div>
            <div style={{marginTop: '10px', marginLeft: '50px', marginRight: '50px'}}>
                <Collapse accordion activeKey={collapseKey} onChange={openCollapse}>
                    {
                        data.map(item => {
                            return <Panel header={item.name} key={item.id} extra={genExtra(item.id, item.name)}>
                                <div>
                                    <Spin tip="数据加载中....." spinning={isSpinning}>
                                        <Row>
                                            <div>
                                                <Button type="primary"
                                                        onClick={() => setAddOperation(true)}>添加操作</Button>
                                            </div>
                                            <div>
                                                <Button type="primary" danger style={{marginLeft: '10px'}}
                                                        onClick={deleteOperation}>删除操作</Button>
                                            </div>
                                        </Row>
                                        <div>
                                            <Table
                                                //表格列的配置描述
                                                columns={operationColumns}
                                                //数据数组
                                                dataSource={existedOperationData}
                                                rowSelection={rowSelection}
                                                pagination={false}
                                                //可展开设置
                                                expandable={{
                                                    expandIcon: ({expanded, onExpand, record}) =>
                                                        expanded
                                                            ? (<DownOutlined onClick={e => onExpand(record, e)}/>)
                                                            : (<RightOutlined onClick={e => onExpand(record, e)}/>)
                                                    ,
                                                    expandedRowRender: record => (
                                                        <div>
                                                            <div style={{marginTop: "30px", display: 'flex'}}>
                                                                <Title level={5} style={{
                                                                    marginRight: "10px",
                                                                    height: "10px",
                                                                    lineHeight: "10px"
                                                                }}>操作描述:</Title>
                                                                <Text type="secondary" style={{
                                                                    marginRight: '20px',
                                                                    height: "10px",
                                                                    lineHeight: "10px"
                                                                }}>{record.operation_detail}</Text>
                                                            </div>
                                                            <div style={{marginTop: "30px", display: 'flex'}}>
                                                                <Title level={5} style={{
                                                                    marginRight: "10px",
                                                                    height: "10px",
                                                                    lineHeight: "10px"
                                                                }}>操作参数名:</Title>
                                                                {
                                                                    record.operation_parameters.map(item => {
                                                                        return <Text type="secondary" style={{
                                                                            marginRight: '20px',
                                                                            height: "10px",
                                                                            lineHeight: "10px"
                                                                        }}>{item}</Text>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    ),
                                                    expandedRowKeys: expandedRowKeys,
                                                    // 通过点击行来展开子行
                                                    expandRowByClick: true,
                                                    onExpand: (expanded, record) => {
                                                        let temp = []
                                                        // 判断是否是同一行进行点击
                                                        if (expanded) {
                                                            temp.push(record.id)
                                                        }
                                                        setExpandedRowKeys(temp)
                                                    },
                                                }}
                                                rowKey={'id'}
                                            />
                                        </div>
                                    </Spin>

                                </div>
                            </Panel>
                        })
                    }
                </Collapse>
            </div>

            {/* 添加操作 */}
            <Modal
                title="添加操作"
                visible={addOperation}
                onCancel={() => {
                    setSelectedRowKeys([])
                    setAddOperation(false)
                }}
                onOk={() => {
                    if (selectedRowKeys.length == 0) {
                        message.warn('请选择操作')
                    } else {
                        let value = {}
                        value.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                        value.shopList = cookie.load('shopList')
                        value.roleId = cookie.load('role')
                        value.otm_stage_model_id = collapseKey
                        value.otm_operation_model_id = selectedRowKeys
                        value.level = props.location.state['level']
                        value.creator = cookie.load('name')
                        value.creator_id = cookie.load('id')
                        value.creator_username = Base64.decode(cookie.load('email'))
                        NbAxios(value, "POST", servicePath.addOtmStageModelOperationModelOperation).then(res => {
                            if (res.success) {
                                message.success("新增阶段模型操作模型成功")
                                setSelectedRowKeys([])
                                openCollapse(collapseKey)
                                setAddOperation(false)
                            } else {
                                message.warn(res.errorMsg)
                            }
                        })
                    }
                }}
                okText="确定"
                cancelText="取消"
                centered={true}
                width={1000}
            >
                <div>
                    <Table
                        //表格列的配置描述
                        columns={operationColumns}
                        //数据数组
                        dataSource={operationData}
                        rowSelection={rowSelection}
                        pagination={{pageSize: 5}}
                        //可展开设置
                        expandable={{
                            expandIcon: ({expanded, onExpand, record}) =>
                                expanded
                                    ? (<DownOutlined onClick={e => onExpand(record, e)}/>)
                                    : (<RightOutlined onClick={e => onExpand(record, e)}/>)
                            ,
                            expandedRowRender: record => (
                                <div>
                                    <div style={{marginTop: "30px", display: 'flex'}}>
                                        <Title level={5} style={{
                                            marginRight: "10px",
                                            height: "10px",
                                            lineHeight: "10px"
                                        }}>操作描述:</Title>
                                        <Text type="secondary" style={{
                                            marginRight: '20px',
                                            height: "10px",
                                            lineHeight: "10px"
                                        }}>{record.operation_detail}</Text>
                                    </div>
                                    <div style={{marginTop: "30px", display: 'flex'}}>
                                        <Title level={5} style={{
                                            marginRight: "10px",
                                            height: "10px",
                                            lineHeight: "10px"
                                        }}>操作参数名:</Title>
                                        {
                                            record.operation_parameters.map(item => {
                                                return <Text type="secondary" style={{
                                                    marginRight: '20px',
                                                    height: "10px",
                                                    lineHeight: "10px"
                                                }}>{item}</Text>
                                            })
                                        }
                                    </div>
                                </div>
                            ),
                            expandedRowKeys: addExpandedRowKeys,
                            // 通过点击行来展开子行
                            expandRowByClick: true,
                            onExpand: (expanded, record) => {
                                let temp = []
                                // 判断是否是同一行进行点击
                                if (expanded) {
                                    temp.push(record.id)
                                }
                                setAddExpandedRowKeys(temp)
                            },
                        }}
                        rowKey={'id'}
                    />
                </div>

            </Modal>

            {/* 新增阶段 */}
            <Modal
                title="新增阶段"
                centered={true}
                visible={addStage}
                footer={[]}
                onCancel={() => setAddStage(false)}
            >
                <Form form={form} name="add_stage_model" onFinish={addStageFinish} autoComplete="off">
                    <Form.Item label="名称">
                        <Text type="secondary">{
                            stage == '' ? props.location.state.name : props.location.state.name+ '-' + stage
                        }</Text>
                    </Form.Item>
                    <Form.Item label="阶段" name="stage" rules={[{required: true, message: '阶段不能为空'}]}>
                        <Select placeholder="请选择阶段" showSearch onChange={(value)=>setStage(value)}>
                            {
                                stageList.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginLeft: 380}}>
                            新增阶段
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="禁用阶段"
                centered="true"
                onCancel={() => {
                    setStageId('')
                    setStageName('')
                    setDeleteStage(false)
                }}
                onOk={() => {
                    let dataProps = {}
                    dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                    dataProps.shopList = cookie.load('shopList')
                    dataProps.roleId = cookie.load('role')
                    dataProps.otm_stage_model_id = stageId
                    NbAxios(dataProps, "POST", servicePath.deleteOtmStageModel).then(res => {
                        if (res.success) {
                            message.success("阶段禁用成功")
                            getOtmStageModel()
                            setStageId('')
                            setStageName('')
                            setDeleteStage(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}
                okText="确认"
                cancelText="取消"
                visible={deleteStage}
                width={600}
            >
                是否禁用 “ {<Text strong>{stageName}</Text>} ”阶段
            </Modal>
        </div>
    )

}

export default Index