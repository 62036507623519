import React, { useState, useEffect } from 'react';
import { Button, Typography, Col, Row, Checkbox, Table, Upload, message, Modal, Select, Input, DatePicker, Progress, Radio} from 'antd'
import style from './index.module.css';
import cookie from 'react-cookies'
import moment from 'moment'
import axios from 'axios';
import downExcel from '../../../config/utils/downloadFile';
import servicePath from '../../../config/apiUrl';
import locale from 'antd/es/date-picker/locale/zh_CN';
import NbAxios from '../../../config/utils/nbAxios';
import CommodityImage from '../../commodityRanking/components/commodityImage';
import PlatformEventsCalendar from '../../../component/platformEventsCalendar'
import { MenuOutlined, CaretRightOutlined, CaretDownOutlined, InboxOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import Axios from 'axios';
import uuid from '../../../config/utils/uuid'
const { Title } = Typography
// 文件上传组建
const { Dragger } = Upload;
const { Option } = Select
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD';
function Index(props) {
    function disabledDate(current) {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }
    // let shopIdDict = {
    //     "aecc4073-4fc8-41c7-9d6a-e37b69947c3e": "JORYA",
    //     "3ed0b079-36ca-4701-b6b1-643cc4d41066":"iv",
    //     "444b93cc-a5d0-4886-a4b2-d5f8de27e945":"ein",
    //     "7f1324e7-0d75-4d94-bace-911d5fbd4727":"an",
    //     "f115bbea-bb18-439e-bda8-8d1e26719fee":"cr",
    //     'd4867ead-6724-4e15-b941-77be33fb6b9f':"z11",
    //     'b67c4cef-3f68-4bae-9dd8-12400e5e99b1':"mesux",
    //     'fc84f6bf-1e5f-4566-a49c-cad309658dbc':"vgrass",
    //     '703247c1-a200-42bc-b70f-742dbab27aa4':"gs",
    //     '2715f3fa-0dae-4dc7-bcfc-0dd912314e40':"qda",
    // }
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'qda': '2715f3fa-0dae-4dc7-bcfc-0dd912314e40',
        'gs':'703247c1-a200-42bc-b70f-742dbab27aa4',
    }
    // 可选菜单
    const [choseMenuList, setChoseMenuList] = useState([])
    // 已选择菜单
    const [chosedMenus, setChosedMenus] = useState([])
    // 确认已选择菜单
    const [confirmChosedMenus, setConfirmChosedMenus] = useState([])
    // 是否显示搜索区域
    const [isShowSearch, setIsShowSearch] = useState(false)
    // 表头
    const [columns, setColumns] = useState([])
    // 数据
    const [dataSource, setDataSource] = useState([])
    // 上传文件进度条
    const [ uploadProgress, setUploadProgress ] = useState(0)
    // 是否上传文件
    const [isBaseUpload, setIsBaseUpload] = useState(false)
    // 商品基础信息文件
    const [baseFile, setBaseFile] = useState([])
    // 是否上传图片文件
    const [isImageUpload, setIsImageUpload] = useState(false)
    // 图片文件
    const [imageFile, setImageFile] = useState([])
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 可选项
    const [choseList, setChoseList] = useState([])
    // 已存在选
    const [exitChoseList, setExitChostList] = useState([])
    const [searchData, setSearchData] = useState([])
    // 设置页码变量
    const [page, setPage] = useState(1);
    // 设置总数变量
    const [total, setTotal] = useState();
    // 是否加载中
    const [loading, setLoading] = useState(false)
    // 文件上传
    const [ uploadLoading, setUploadLoading ] = useState(false)
    // 过程的key
    const [ progressKey, setProgressKey] = useState('')
    // 计时器
    const [count, setCount] = useState(540000)
    // 缺少基础数据数量
    const [ lostItemInfoNumber , setLostItemInfoNumber ] = useState(0)
    // 下载缺失商品基础信息
    const [ downloadLostItemInfo, setDownloadLostItemInfo] = useState(false)
    // 开始时间
    const [ startDate , setStartDate ] = useState(moment().subtract(1,'year').format('YYYY-MM-DD'))
    // 结束时间
    const [ endDate , setEndDate ] = useState(moment().format('YYYY-MM-DD'))
    // 加购件数
    const [  isPurchaseNum, setIsPurchaseNum] = useState(0)
    // 支付件数
    const [ isPayGoodsNum, setIsPayGoodsNum] = useState(0)
    // 是否全量
    const [ isAll, setIsAll] = useState(0)
    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,
        total: total,
        style:{marginRight:50},
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    // 控制计时器
    let countTimeout
    useEffect(()=>{
        if(uploadLoading && count != 0){
            countTimeout = window.setTimeout(()=>{
                let dataProps = {}
                dataProps['shopId'] = shopIdDict[props.history.location.pathname.split('/')[2]]
                dataProps['shopList'] = cookie.load('shopList')
                dataProps['roleId'] = cookie.load('role')
                dataProps['progress_key'] = progressKey
                NbAxios(dataProps, "POST", servicePath.getUploadProgress).then(res => {
                    if (res.success) {
                        setUploadProgress(res.data['progress_rate'])
                        setCount(count - 1)
                    } else {
                        message.warn(res.errorMsg)
                    }
                }).catch((err) => {
                    setCount(0)
                    message.warn('获取上传进度报错，请联系IT帮忙解决')
                })
            },2000)
        }else{
            setLoading(false)
            setUploadLoading(false)
            setProgressKey('')
            setUploadProgress(0)
            window.clearTimeout(countTimeout)
        }
    },[uploadLoading, count])

    useEffect(() => {
        getFilterCondition()
        queryDisplayFieldData()
        getRecentLostItemInfoNumber()
    }, [])

    useEffect(() => {
        queryItemInfoData(searchData)
    }, [page, confirmChosedMenus])

    // useEffect(()=>{
    //     queryItemInfoData([])
    // },[shopIdDict[props.history.location.pathname.split('/')[2]]])

    useEffect(() => {
        setColumns([
            {
                title: '商品ID',
                dataIndex: '商品ID',
                key: '商品ID',
                width: 100,
                align: "center",
                fixed: 'left',
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '10px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '10px',
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <div>
                        <div>
                            <a href={`https://detail.tmall.com/item.htm?id=${text}`} target='_blank'>
                                <CommodityImage width={70} shopName={props.history.location.pathname.split('/')[2]} sku={record.spu} id={text} index={index} />
                            </a>
                        </div>
                        <div>
                            {text}
                        </div>
                    </div>
                )
            },
            ...confirmChosedMenus.map(item => {
                return {
                    title: item,
                    dataIndex: item,
                    key: item,
                    width: 100,
                    align: "center",
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                }
                            }
                        )
                    },
                }
            })
        ])
    }, [confirmChosedMenus])

    // 获取近期缺失商品基础信息数量
    const getRecentLostItemInfoNumber =()=>{
        let dataProps = {}
        dataProps['shopId'] = shopIdDict[props.history.location.pathname.split('/')[2]]
        dataProps['shopList'] = cookie.load('shopList')
        dataProps['roleId'] = cookie.load('role')
        NbAxios(dataProps, "POST", servicePath.getRecentLostItemInfoNumber).then(res => {
            if (res.success) {
                setLostItemInfoNumber(res.data.num);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获取近期缺失商品基础信息数量报错，请联系IT帮忙解决')
        })
    }

    // 导出缺失商品基础信息表格
    const downloadLostItemInfoData = ()=>{
        let dataProps = {}
        dataProps['shopId'] = shopIdDict[props.history.location.pathname.split('/')[2]]
        dataProps['shopList'] = cookie.load('shopList')
        dataProps['roleId'] = cookie.load('role')
        dataProps['start_time'] = startDate
        dataProps['end_time'] = endDate
        dataProps['is_purchase_num'] = isPurchaseNum
        dataProps['is_pay_goods_num'] = isPayGoodsNum
        dataProps['is_all'] = isAll
        setLoading(true)
        axios({
            method: 'POST',
            url: servicePath.downloadLostItemInfoData,
            data: dataProps,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
            // 设置超时时间为5分钟
            timeout:300000
        }).then(res => {
                setLoading(false)
                downExcel(res)
            }).catch(e => {
            setLoading(false)
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }

    // 查询展示字段数据接口
    const queryDisplayFieldData = ()=>{
        let dataProps = {}
        dataProps['shopId'] = shopIdDict[props.history.location.pathname.split('/')[2]]
        dataProps['shopList'] = cookie.load('shopList')
        dataProps['roleId'] = cookie.load('role')
        NbAxios(dataProps, "POST", servicePath.queryDisplayFieldData).then(res => {
            if (res.success) {
                setChoseMenuList(res.data['item_info_list']);
                setChosedMenus(res.data['item_info_list']);
                setConfirmChosedMenus(res.data['item_info_list'])
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('查询展示字段数据报错，请联系IT帮忙解决')
        })
    }

    // 查询商品基础信息接口
    const queryItemInfoData = (searchData) => {
        let dataProps = {}
        dataProps['shopId'] = shopIdDict[props.history.location.pathname.split('/')[2]]
        dataProps['shopList'] = cookie.load('shopList')
        dataProps['roleId'] = cookie.load('role')
        dataProps['condition_list'] = searchData
        dataProps['field_list'] = confirmChosedMenus
        dataProps['page'] = page
        dataProps['page_size'] = 10
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.queryItemInfoData).then(res => {
            setLoading(false)
            if (res.success) {
                let fildList = []
                res.data['field_list'].map(item => {
                    if (item != '商品ID') {
                        fildList.push(item)
                    }
                })
                setDataSource(res.data['item_info_list']);
                setTotal(res.data['total'])
                setIsShowSearch(false)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            setLoading(false)
            message.warn('查询商品基础信息报错，请联系IT帮忙解决')
        })

    }

    // 获取所有筛选条件
    const getFilterCondition = () => {
        let dataProps = {}
        dataProps['shopId'] = shopIdDict[props.history.location.pathname.split('/')[2]]
        dataProps['shopList'] = cookie.load('shopList')
        dataProps['roleId'] = cookie.load('role')
        NbAxios(dataProps, "POST", servicePath.getFilterCondition).then(res => {
            if (res.success) {
                setChoseList(res.data['filter_field_list'])
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获取所有筛选条件报错，请联系IT帮忙解决')
        })
    }
    // 商品基础信息文件上传
    const baseFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setBaseFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setBaseFile(fileList)
        }
    }
    // 上传商品基础信息
    const uploadBaseFile = () => {
        if (baseFile.length != 0) {
            setEffective(1)
            let progressKey = uuid()
            setProgressKey(progressKey)
            const formData = new FormData()
            // 多个图片
            baseFile.forEach((item) => {
                formData.append('file', item['originFileObj']);
            })
            formData.append('shopList', cookie.load('shopList'));
            formData.append('shopId',  shopIdDict[props.history.location.pathname.split('/')[2]])
            formData.append('roleId', cookie.load('role'));
            formData.append('progress_key', progressKey)
            setLoading(true)
            setUploadLoading(true)
            Axios({
                method: 'POST',
                url: servicePath.uploadItemInfoFile,
                data: formData,
                headers: {
                    'authorization': cookie.load('token')
                },
                // 设置超时时间为20分钟
                timeout:1200000
            }).then(res=>{
                setLoading(false)
                setUploadLoading(false)
                window.clearTimeout(countTimeout)
                if(res.data.success){
                    setIsBaseUpload(false)
                    setProgressKey('')
                    setBaseFile([])
                    message.success({content:res.data.data['message'], duration:10, style:{ whiteSpace:'pre'}})
                }else{
                    if (res.data.errorCode === '10001') {
                        cookie.remove("id", { path: '/' });
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("shopList", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        window.location.replace('https://nb20.bmcsoft.cn/')
                    }else{
                        message.error({content:res.data['errorMsg'], duration:10, style:{ whiteSpace:'pre'}})
                    }
                }
            }).catch((err) => {
                setLoading(false)
                setUploadLoading(false)
                setProgressKey('')
                setUploadProgress(0)
                window.clearTimeout(countTimeout)
                message.warn('上传商品基础信息报错，请联系IT帮忙解决')
            })
            // NbAxios(formData, "POST", servicePath.checkJewelryItemInfo).then(res => {
            //     setEffective(0)
            //     if (res.success) {
            //         setBaseFile([])
            //         message.success('文件数据上传成功')
            //         setAddData(res.data['add']);
            //         setUpdateData(res.data['update']);
            //         setIsResult(true)
            //         setIsBaseUpload(false)
            //     } else {
            //         message.warn(res.errorMsg)
            //     }
            // })
        } else {
            message.warn('请选择文件')
        }
    }
    // 商品图片文件上传
    const imageFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['rar']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setImageFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setImageFile(fileList)
        }
    }
    // 上传商品基础信息
    const uploadImageFile = () => {
        if (imageFile.length != 0) {
            setEffective(1)
            const formData = new FormData()
            // 多个图片
            imageFile.forEach((item) => {
                formData.append('file', item['originFileObj']);
            })
            formData.append('shopList', cookie.load('shopList'));
            formData.append('shopId', '0188223a-5046-4afe-a43e-8762d3bd71ed')
            formData.append('roleId', cookie.load('role'));
            // NbAxios(formData, "POST", servicePath.checkJewelryItemInfo).then(res => {
            //     setEffective(0)
            //     if (res.success) {
            //         setBaseFile([])
            //         message.success('文件数据上传成功')
            //         setAddData(res.data['add']);
            //         setUpdateData(res.data['update']);
            //         setIsResult(true)
            //         setIsBaseUpload(false)
            //     } else {
            //         message.warn(res.errorMsg)
            //     }
            // })
        } else {
            message.warn('请选择文件')
        }
    }

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: "space-around",
                backgroundColor: "#fff",
                borderBottom: "solid 2px #ccc",
                marginBottom: 10,
                position: 'fixed',
                top: 40,
                zIndex: 99,
                width: '100vw'
            }}>
                <Title level={3}>{props.history.location.pathname.split('/')[2].toUpperCase()}商品基础信息</Title>
                <div></div>
            </div>
            <div style={{ marginLeft: 10, marginRight: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 50, }}>
                    <div style={{ display: 'flex' }}>
                        <Button  disabled={loading ? true : false} type="primary" style={{ marginRight: 10 }} onClick={() => {
                            setIsBaseUpload(true)
                        }}>上传商品基础信息表格</Button>
                        {/* <Button disabled={loading ? true : false} type="dashed" style={{ marginRight: 10 }} onClick={() => {
                            setIsImageUpload(true)
                        }}>上传商品图片</Button> */}
                        <Button disabled={loading ? true : false} type="primary" danger style={{ marginRight: 10 }} onClick={()=>{
                            let dataProps = {}
                            dataProps['shopId'] = shopIdDict[props.history.location.pathname.split('/')[2]]
                            dataProps['shopList'] = cookie.load('shopList')
                            dataProps['roleId'] = cookie.load('role')
                            dataProps['condition_list'] = searchData
                            dataProps['field_list'] = confirmChosedMenus
                            setLoading(true)
                            axios({
                                data: dataProps,
                                method: 'POST',
                                url: servicePath.downloadItemInfoData,
                                responseType: "blob",
                                headers: {
                                    'Content-Type': 'application/json',  //如果写成contentType会报错
                                    'authorization': cookie.load('token')
                                }
                            }).then(
                                res => {
                                    setLoading(false)
                                    downExcel(res)
                                }
                            ).catch(e => {
                                setLoading(false)
                                message.error({ content: '文件制作出错，请联系it同事！' })
                            })
                        }}>下载商品基础信息</Button>
                        <Button disabled={loading ? true : false} onClick={()=>{
                            let dataProps = {}
                            dataProps['shopId'] = shopIdDict[props.history.location.pathname.split('/')[2]]
                            dataProps['shopList'] = cookie.load('shopList')
                            dataProps['roleId'] = cookie.load('role')
                            setLoading(true)
                            axios({
                                data: dataProps,
                                method: 'POST',
                                url: servicePath.downloadItemInfoUploadFileTemplate,
                                responseType: "blob",
                                headers: {
                                    'Content-Type': 'application/json',  //如果写成contentType会报错
                                    'authorization': cookie.load('token')
                                }
                            }).then(
                                res => {
                                    setLoading(false)
                                    downExcel(res)
                                }
                            ).catch(e => {
                                setLoading(false)
                                message.error({ content: '文件制作出错，请联系it同事！' })
                            })
                        }}>下载商品基础信息上传模版</Button>
                        <Button type="dashed" onClick={()=>{setDownloadLostItemInfo(true)}}>{`近期缺少${lostItemInfoNumber}条商品基础数据`}</Button>
                    </div>
                    <div className={style.menuContent} onMouseOver={() => {
                        // js 操作对象css的值
                        let obj = document.getElementById('choseMenuContent')
                        obj.style.display = 'block';
                    }} onMouseOut={() => {
                        // js 操作对象css的值
                        let obj = document.getElementById('choseMenuContent')
                        obj.style.display = 'none';
                    }}>
                        <MenuOutlined className={style.menuIcon} />
                        <div className={style.choseMenuContent} id="choseMenuContent">
                            <Button type="primary" style={{ marginTop: 10, marginLeft:10 }} onClick={() => {
                                // js 操作对象css的值
                                let obj = document.getElementById('choseMenuContent')
                                obj.style.display = 'none';
                                setConfirmChosedMenus(chosedMenus)
                            }}>确定</Button>
                            <Checkbox.Group value={chosedMenus} style={{ marginTop: 10, marginLeft: 20, height:'180px', overflowY:'auto' }} onChange={(value) => {
                                setChosedMenus(value)
                            }}>
                                <Row>
                                    {
                                        choseMenuList.map(item => {
                                            return <Col span={8} style={{ marginTop: 5 }}>
                                                <Checkbox value={item}>{item}</Checkbox>
                                            </Col>
                                        })
                                    }
                                </Row>
                            </Checkbox.Group>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 10, border: "1px solid #ccc", fontSize: 16, height: 30 }} onClick={() => {
                    if (isShowSearch) {
                        setIsShowSearch(false)
                    } else {
                        setIsShowSearch(true)
                    }
                }}>
                    {
                        isShowSearch ? <CaretDownOutlined style={{ marginLeft: 10, marginTop: 5 }} />
                            : <CaretRightOutlined style={{ marginLeft: 10, marginTop: 5 }} />
                    }
                    <div style={{ marginLeft: 5 }}>查询条件</div>
                </div>
                <div style={{ height: 300, width: '100%', border: '1px solid #ccc', display: isShowSearch ? '' : 'none', paddingLeft: 10, paddingRight: 10, paddingTop: 10, alignContent: 'flex-start' }}>
                    <div style={{ display: 'flex' }}>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                            let flag = 1
                            let errMsg = ''
                            searchData.map((item, index) => {
                                if (flag) {
                                    if (item.condition_type == '') {
                                        errMsg = `请选择查询纬度`
                                        flag = 0
                                    }
                                    if (item.condition_type == 1) {
                                        // 判断是否是数组
                                        if (typeof (item.value) == 'object') {
                                            errMsg = `请输入${index + 1}选项正确的值`
                                            flag = 0
                                        }
                                    }
                                    if (item.condition_type == 2) {
                                        if (item.value.length == 0) {
                                            errMsg = `请输入${index + 1}选项正确的值`
                                            flag = 0
                                        }
                                    }
                                    if (item.condition_type == 3) {
                                        if (Object.keys(item.value).length != 0) {
                                            if (item.value['value'] == undefined) {
                                                errMsg = `请输入${index + 1}选项正确的值`
                                                flag = 0
                                            }
                                        } else {
                                            errMsg = `请输入${index + 1}选项正确的值`
                                            flag = 0
                                        }
                                    }
                                }

                            })
                            if (flag == 0) {
                                message.warn(errMsg)
                            } else {
                                if (page == 1) {
                                    queryItemInfoData(searchData)
                                } else {
                                    setPage(1)
                                }
                            }
                        }}>查询</Button>
                        <Button type="primary" danger onClick={() => {
                            setSearchData([])
                            queryItemInfoData([])
                        }}>重置</Button>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
                        {
                            searchData.map((item, index) => {
                                return <Col style={{ width: 350, display: 'flex' }}>
                                    <Select style={{ width: 100 }} value={searchData[index]['sort']} onChange={(value) => {
                                        let flag = 1
                                        exitChoseList.map(item => {
                                            if (item.name == value.split(',')[0]) {
                                                flag = 0
                                            }
                                        })
                                        if (flag) {
                                            if (searchData[index]['name'] != '') {
                                                let obj = [...exitChoseList]
                                                obj.splice(index, 1)
                                                let objValue = {
                                                    'name': value.split(',')[0],
                                                    'field': value.split(',')[1],
                                                    'condition_type': value.split(',')[2]
                                                }
                                                obj.push(objValue)
                                                setExitChostList(obj)
                                                let searchDataObj = [...searchData]
                                                setSearchData([])
                                                searchDataObj[index]['sort'] = value
                                                searchDataObj[index]['name'] = value.split(',')[0]
                                                searchDataObj[index]['field'] = value.split(',')[1]
                                                searchDataObj[index]['condition_type'] = value.split(',')[2]
                                                if (value.split(',')[2] == '4') {
                                                    let dataProps = {}
                                                    dataProps['shopId'] = shopIdDict[props.history.location.pathname.split('/')[2]]
                                                    dataProps['shopList'] = cookie.load('shopList')
                                                    dataProps['roleId'] = cookie.load('role')
                                                    dataProps['field'] = value.split(',')[0]
                                                    NbAxios(dataProps, "POST", servicePath.getFieldSelectMenu).then(res => {
                                                        if (res.success) {
                                                            searchDataObj[index]['value_chose_list'] = res.data['select_menu_list']
                                                        } else {
                                                            message.warn(res.errorMsg)
                                                        }
                                                    }).catch((err) => {
                                                        message.warn('获取所有筛选条件报错，请联系IT帮忙解决')
                                                    })
                                                }
                                                setSearchData(searchDataObj)
                                            } else {
                                                let obj = [...exitChoseList]
                                                setExitChostList([])
                                                let objValue = {
                                                    'name': value.split(',')[0],
                                                    'field': value.split(',')[1],
                                                    'condition_type': value.split(',')[2]
                                                }
                                                obj.push(objValue)
                                                setExitChostList(obj)
                                                let searchDataObj = [...searchData]
                                                setSearchData([])
                                                searchDataObj[index]['sort'] = value
                                                searchDataObj[index]['name'] = value.split(',')[0]
                                                searchDataObj[index]['field'] = value.split(',')[1]
                                                searchDataObj[index]['condition_type'] = value.split(',')[2]
                                                if (value.split(',')[2] == '4') {
                                                    let dataProps = {}
                                                    dataProps['shopId'] = shopIdDict[props.history.location.pathname.split('/')[2]]
                                                    dataProps['shopList'] = cookie.load('shopList')
                                                    dataProps['roleId'] = cookie.load('role')
                                                    dataProps['field'] = value.split(',')[0]
                                                    NbAxios(dataProps, "POST", servicePath.getFieldSelectMenu).then(res => {
                                                        if (res.success) {
                                                            searchDataObj[index]['value_chose_list'] = res.data['select_menu_list']
                                                        } else {
                                                            message.warn(res.errorMsg)
                                                        }
                                                    }).catch((err) => {
                                                        message.warn('获取所有筛选条件报错，请联系IT帮忙解决')
                                                    })
                                                }
                                                setSearchData(searchDataObj)
                                            }
                                        } else {
                                            message.warn('已存在这个选择项了')
                                        }
                                    }}>
                                        {
                                            item.chose_list.map(i => {
                                                return <Option value={i.name + ',' + i.field + ',' + i.condition_type}>{i.name}</Option>
                                            })
                                        }
                                    </Select>
                                    {
                                        searchData[index]['condition_type'] == '1' ? <Input style={{ width: 150 }} value={searchData[index]['value']} onChange={(e) => {
                                            let obj = [...searchData]
                                            obj[index]['value'] = e.target.value
                                            setSearchData(obj)
                                        }} /> : searchData[index]['condition_type'] == '2' ? <RangePicker value={searchData[index]['value'][0] == undefined ? ['', ''] : [moment(searchData[index]['value'][0], dateFormat), moment(searchData[index]['value'][1], dateFormat)]} locale={locale} onChange={(value, dateString) => {
                                            let time_arr = []
                                            let obj = [...searchData]
                                            if (dateString[0] == '') {
                                                message.warn('请选择合理的时间')
                                                return false
                                            } else {
                                                time_arr.push(dateString[0])
                                                time_arr.push(dateString[1])
                                            }
                                            obj[index]['value'] = time_arr
                                            setSearchData(obj)
                                        }} /> : searchData[index]['condition_type'] == '3' ?
                                            <div style={{ display: 'flex', width: 250 }}>
                                                <Select width={50} value={searchData[index]['value']['conform'] == undefined ? '' : searchData[index]['value']['conform']} onChange={(value) => {
                                                    let obj = [...searchData]
                                                    let conformDict = {}
                                                    conformDict['conform'] = value
                                                    obj[index]['value'] = conformDict
                                                    setSearchData(obj)
                                                }}>
                                                    <Option value={">"}>{">"}</Option>
                                                    <Option value={"<"}>{"<"}</Option>
                                                    <Option value={"<="}>{"<="}</Option>
                                                    <Option value={">="}>{">="}</Option>
                                                    <Option value={"="}>{"="}</Option>
                                                </Select>
                                                <Input disabled={searchData[index]['value']['conform'] == undefined ? true : false} style={{ width: 100 }} value={searchData[index]['value']['value']} onChange={(e) => {
                                                    let obj = [...searchData]
                                                    obj[index]['value']['value'] = e.target.value
                                                    setSearchData(obj)
                                                }} />
                                            </div> : <Select value={searchData[index]['value']} style={{ width: 200 }} mode="multiple" onChange={(value) => {
                                                let obj = [...searchData]
                                                obj[index]['value'] = value
                                                setSearchData(obj)
                                            }}>
                                                {
                                                    item.value_chose_list.map(i => {
                                                        return <Option value={i}>{i}</Option>
                                                    })
                                                }
                                            </Select>
                                    }
                                    <MinusCircleOutlined style={{ marginTop: 5, marginLeft: 10, marginRight: 10 }} onClick={() => {
                                        if (searchData[index]['sort'] != '') {
                                            let exitChoseObj = [...exitChoseList]
                                            setExitChostList([])
                                            exitChoseObj.splice(index, 1)
                                            setExitChostList(exitChoseObj)
                                            let obj = [...searchData]
                                            obj.splice(index, 1);
                                            setSearchData(obj)
                                        } else {
                                            let obj = [...searchData]
                                            obj.splice(index, 1);
                                            setSearchData(obj)
                                        }
                                    }} />
                                </Col>
                            })
                        }
                        <PlusCircleOutlined style={{ marginLeft: 10, marginTop: 5 }} onClick={() => {
                            let obj = [...searchData]
                            setSearchData([])
                            let arr3 = [...choseList]
                            // 已存在key集合
                            exitChoseList.map(item => {
                                arr3.map((i, index) => {
                                    if (i.name == item.name) {
                                        arr3.splice(index, 1)
                                    }
                                })
                            })
                            obj.push({
                                'sort': '',
                                'name': '',
                                'field': '',
                                'condition_type': '',
                                'value': [],
                                'chose_list': arr3,
                                'value_chose_list': []
                            })
                            setSearchData(obj);
                        }} />
                    </div>
                </div>
                <Table scroll={{ y: 'calc(103vh - 300px)', x: 1000 }} dataSource={dataSource}
                    pagination={paginationProps}
                    loading={loading}
                    columns={columns} style={{ marginTop: 10  }} />
            </div>
            <Modal
                title="上传商品基础信息"
                visible={isBaseUpload}
                centered={true}
                okText="确定"
                cancelText="取消"
                maskClosable={false}
                closable={false}
                onOk={() => uploadBaseFile()}
                onCancel={() => {
                    message.success('取消上传商品基础信息')
                    setLoading(false)
                    setUploadLoading(false)
                    setProgressKey('')
                    setUploadProgress(0)
                    setBaseFile([])
                    window.clearTimeout(countTimeout)
                    setIsBaseUpload(false)
                }}
                okButtonProps={{ disabled: loading == 1 ? true : false }}
                cancelButtonProps={{ disabled: loading == 1 ? true : false }}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={baseFile}
                    onChange={baseFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
                <Progress percent={uploadProgress} />
            </Modal>
            <Modal
                title="上传商品图片"
                visible={isImageUpload}
                centered={true}
                okText="确定"
                cancelText="取消"
                maskClosable={true}
                onOk={() => uploadImageFile()}
                onCancel={() => {
                    message.success('取消上传商品图片')
                    setImageFile([])
                    setIsImageUpload(false)
                }}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={imageFile}
                    onChange={imageFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.rar文件
                    </p>
                </Dragger>
            </Modal>

            <Modal
                title="导出近期缺失商品基础信息表格"
                visible={downloadLostItemInfo}
                footer={null}
                onCancel={()=>{
                    setIsAll(0)
                    setIsPayGoodsNum(0)
                    setIsPurchaseNum(0)
                    setStartDate(moment().subtract(1,'year').format('YYYY-MM-DD'))
                    setEndDate(moment().format('YYYY-MM-DD'))
                    setDownloadLostItemInfo(false)
                }}
                centered={true}
            >
                <div>
                    <div style={{display:'flex'}}>
                        <b style={{marginTop:3, marginRight:20}}>时间范围:</b> 
                        <PlatformEventsCalendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
                    </div>
                    <div style={{display:'flex', marginTop:20}}>
                        <b style={{marginTop:3, width:230}}>是否有加购件数:</b> 
                        <Radio.Group value={isPurchaseNum} onChange={(e)=>{setIsPurchaseNum(e.target.value)}}>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>
                    </div>
                    <div style={{display:'flex'}}>
                        <b style={{marginTop:3, width:230}}>是否有支付件数:</b> 
                        <Radio.Group value={isPayGoodsNum} onChange={(e)=>{setIsPayGoodsNum(e.target.value)}}>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>
                    </div>
                    <div style={{display:'flex'}}>
                        <b style={{marginTop:3, width:230}}>是否全量:</b> 
                        <Radio.Group value={isAll} onChange={(e)=>{setIsAll(e.target.value)}}>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div style={{marginTop:20, display:'flex', justifyContent:'space-around'}}>
                    <Button disabled={loading ? true : false} type="primary" onClick={()=>downloadLostItemInfoData()}>导出</Button>
                    <Button disabled={loading ? true : false} type="primary" onClick={()=>{
                        setIsAll(0)
                        setIsPayGoodsNum(0)
                        setIsPurchaseNum(0)
                        setStartDate(moment().subtract(1,'year').format('YYYY-MM-DD'))
                        setEndDate(moment().format('YYYY-MM-DD'))
                        setDownloadLostItemInfo(false)
                    }} danger>取消</Button>
                </div>
            </Modal>
        </div>
    )

}

export default Index