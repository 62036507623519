import React, { Component } from 'react';
import style from './style.module.css'
import { Typography,Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons';
const { Title, Paragraph, Text, Link } = Typography;
function TitleCard({
    channel,
    hasFinshNum,
    target,
    finshRate,
    inTotal,
}) {
    return (
        <div className={style.cardWapper} >
            {channel &&
                <div className={style.channel}>
                    <Title ellipsis level={5} style={{ margin: 0 }} className={style.channelText}>{channel}回款</Title>
                </div>
            }
            <div className={style.hasFinsh}>
                <Title level={3} style={{ margin: 0 }}>{hasFinshNum ? hasFinshNum : 0}</Title>
                <Text className={style.channelTarget}>
                    <Text type="secondary">目标：</Text>
                    <Text type="secondary">{target ? target : 0}{target==1&&<Tooltip placement="right" title="请联系it同事维护目标数据"><QuestionCircleOutlined /></Tooltip>}</Text>
                </Text>
            </div>
            <div className={style.finshRate}>
                <Text type="secondary">完成率：</Text>
                <Text type="secondary">{finshRate ? finshRate : 0}%</Text>
            </div>
            <div className={style.inTotal}>
                <Text type="secondary">占比：</Text>
                <Text type="secondary">{inTotal ? inTotal : 0 }%</Text>
            </div>

        </div>
    )
}

export default TitleCard