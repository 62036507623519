import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import Index from './index'
import Login from './login'
import Meeting from './meeting'
import Print from './supplyChain/print'
import takeMeal from './halfMeal/takeMeal'
import Prize from './prize'
import WorkMessage from './workMessage/index'
import WorkMessageLogin from './workMessage/login'
import SignIn from './singIn'
import ShowPeople from './singIn/showPeople'
import OrderMeeting from './oa/orderMeeting.js'
import 'antd/dist/antd.css'
//一级路由配置
function Main() {
    //主路由配置
    return (
        <Router>
            <Route path="/app/oa/ordermeeting" exact component = {OrderMeeting}/>
            <Route path="/app" component={Index}/>
            <Route path = "/" exact component={Login} />
            <Route path="/meeting/:id" component = {Meeting} />
            <Route path="/takeMeal" component = {takeMeal} />
            <Route path="/print"  component = {Print}/>
            <Route path="/prize"  component = {Prize}/>
            <Route path="/workMessage/index"  component = {WorkMessage}/>
            <Route path="/workMessage/login"  component = {WorkMessageLogin}/>
            <Route path="/signIn"  component={SignIn}/>
            <Route path="/showPeople"  component={ShowPeople}/>
        </Router>
    )
}

export default Main

