import React, { useEffect, useState } from 'react';
import style from './index.module.css';
import webUrl from '../../../config/webUrl';
import servicePath from '../../../config/apiUrl';
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios';
import {  EyeOutlined,SearchOutlined } from '@ant-design/icons'
import {Button, Table, Popover, Tag, Popconfirm, Modal, message} from 'antd'

function Index(props){

    // 数据
    const [ date, setdate] = useState([])
    // 表头
    const [columnsList] = useState([
        {title:'状态', name:'status'},
        {title:'文件名字', name:'filename'},
        {title:'行业类目', name:'industry_category'},
        {title:'作者', name:'author'},
        {title:'报告年份', name:'year'},
        {title:'内容标签', name:'content_label'},
        {title:'网站站点', name:'web'},
        {title:'操作', name:'operation'},
    ])
    const [ columns, setColumns ] = useState([])
    // 选择的key
    const [ selectedRowKeys , setSelectedRowKeys ] = useState([])
    const [ selectedRow , setSelectedRow ] = useState([])
    // 设置页码变量
    const [page, setPage] = useState(1);
    // 设置总数变量
    const [total, setTotal] = useState();
    // 是否加载中
    const [loading, setLoading] = useState(false)
    // 是否审批
    const [ isApproval, setIsApproval] = useState(false)
    // 标签展示框
    const labelContent =(data)=> {
       return <div style={{width:"240px", display:'flex', padding:"5px", flexWrap:'wrap'}}>
         {
             data.map(item=>{
                return <Tag color="blue" style={{marginTop:"2px"}}>{item}</Tag>
            })
         }
       </div>
    }
    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    // 多选设置
    const onSelectChange = (selectedRowKeys,selectedRows) =>{
        setSelectedRowKeys(selectedRowKeys)
        setSelectedRow(selectedRows);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    useEffect(()=>{
        setColumns([
            ...columnsList.map(item=>{
                return {
                    title: item.title,
                    align: 'center',
                    dataIndex: item.name,
                    key: item.name,
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 5
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div>
                            {
                                item.title == '状态'? <span>
                                    {
                                        text == 1 ? <Tag color="gold">待审核</Tag>
                                        :text == 3 ? <Tag color="geekblue">上架</Tag>
                                        :text == 2 ? <Tag color="cyan">待上架</Tag>
                                        :<Tag color="red">驳回</Tag>
                                    }
                                </span>
                                : item.title == '文件名字' ?  <a href={`https://pic.bmcsoft.cn/BMC知识库/`+item.filepath} target="_blank">{item.filename}</a>
                                :item.title == '内容标签' ? <Popover placement="bottom" content={labelContent(text)}><a><EyeOutlined /></a> </Popover>
                                :item.title == '网站站点' ? <a href={text} target="_blank"><SearchOutlined /> </a>
                                :item.title == '操作'? <div >
                                    {
                                        record.status == 1 ? 
                                        <div style={{display:'flex', justifyContent:'space-around'}}>
                                            <Popconfirm title="是否通过这个报告?" okText="确定" cancelText="取消" onConfirm={()=>{
                                                let report_id_list = []
                                                report_id_list.push(record.report_id)  
                                                reviewChangeReportKnoledge(report_id_list, 3)
                                            }}>
                                                <Button size="small" type="primary">通过</Button>
                                            </Popconfirm>
                                            <Popconfirm title="是否驳回这个报告?" okText="确定" cancelText="取消" onConfirm={()=>{
                                                let report_id_list = []
                                                report_id_list.push(record.report_id)  
                                                reviewChangeReportKnoledge(report_id_list, 4)
                                            }}>
                                                <Button size="small" type="primary" danger>驳回</Button>
                                            </Popconfirm>
                                        </div> : 
                                        record.status == 3 ?
                                        <div style={{display:'flex', justifyContent:'space-around'}}>
                                            <Popconfirm title="是否撤回这个报告?" okText="确定" cancelText="取消" onConfirm={()=>{
                                                let report_id_list = []
                                                report_id_list.push(record.report_id)  
                                                reviewChangeReportKnoledge(report_id_list, 2)
                                            }}>
                                                <Button size="small" type="primary" danger>撤回</Button>
                                            </Popconfirm>
                                            <div style={{width:"47.8px"}}></div>
                                        </div> :
                                        record.status == 2 ?
                                        <div style={{display:'flex', justifyContent:'space-around'}}>
                                            <Popconfirm title="是否上架这个报告?" okText="确定" cancelText="取消" onConfirm={()=>{
                                                let report_id_list = []
                                                report_id_list.push(record.report_id)  
                                                reviewChangeReportKnoledge(report_id_list, 3)
                                            }}>
                                                <Button size="small" type="primary">上架</Button>
                                            </Popconfirm>
                                            <div style={{width:"47.8px"}}></div>
                                        </div> :<span></span>
                                    }
                                    
                                </div>
                                :<span>{text}</span>
                            }
                        </div>
                    )
                }
            }),
        ])
    },[])

    useEffect(()=>{
        reviewShowReportKnoledge()
    },[page])

    // 获取审核报告
    const reviewShowReportKnoledge = ()=>{
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        values['page'] = page
        setLoading(true)
        NbAxios(values, "POST", servicePath.reviewShowReportKnoledge).then(res => {
            setLoading(false)
            if (res.success) {
                setTotal(res.total);
                setdate(res.data);
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('获取审核报告报错，请联系IT帮忙解决')
        })
    }

    // 修改状态
    const reviewChangeReportKnoledge = (report_id_list, status)=>{
        let values = {}
        values["roleId"] = cookie.load('role');
        values["shopList"] = cookie.load('shopList');
        values['report_id_list'] = report_id_list
        values['status'] = status
        setLoading(true)
        NbAxios(values, "POST", servicePath.reviewChangeStatusKnoledge).then(res => {
            setLoading(false)
            if (res.success) {
                message.success('操作成功')
                reviewShowReportKnoledge();
                setSelectedRowKeys([])
                setSelectedRow([]);
                setIsApproval(false)
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('获取审核报告报错，请联系IT帮忙解决')
        })  
    }

    return (
        <div style={{ backgroundColor: '#F5F5F5', height: "calc(100vh - 40px)", overflow: 'auto' }}>
            <div className={style.externalContent}>
              <div style={{display:"flex", justifyContent:'space-between'}}>
                <a style={{lineHeight:"30px"}} onClick={()=>{
                        window.open(`${webUrl}/app/knowledge/search`)
                }}>跳转搜索界面</a>
                <div style={{display:'flex'}}>
                    {/* selectedRowKeys.length == 0 ? true : false */}
                    <Button type="primary" style={{marginRight:"10px"}} onClick={()=>setIsApproval(true)} disabled={true}>批量审批</Button>
                    {/* <Button type="primary" danger onClick={()=>setIsReject(true)} disabled={selectedRowKeys.length == 0 ? true : false}>驳回</Button> */}
                </div>
              </div>
                <div style={{marginTop:"20px"}}>
                    <Table 
                        columns={columns} 
                        dataSource={date}
                        rowSelection={rowSelection}
                        pagination={paginationProps}
                        loading={loading}
                        scroll={{ y: 'calc(80vh - 300px)', x: 1000 }} 
                        rowKey={'report_id'}
                     />
                </div>
            </div> 

            <Modal
                title="批量审批报告"
                visible={isApproval}
                centered={true}
                maskClosable={false}
                onCancel={()=>{
                    reviewChangeReportKnoledge(selectedRowKeys, 4)
                }}
                onOk={()=>{
                    reviewChangeReportKnoledge(selectedRowKeys, 3)
                }}
                okText="通过"
                cancelText="驳回"
            >
                <div style={{display:"flex", flexDirection:"column", alignContent:'center'}}>
                    {
                        selectedRow.map(item=>{
                            return <a>{item.filename}</a>
                        })
                    }
                </div>
            </Modal>
        </div>
    )

}


export default Index