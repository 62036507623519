import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment'
import { Form, Row, message, Button, Col, DatePicker, Input, Select, Upload, Progress, Drawer, Avatar, Tag, Table, Menu, Dropdown } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import BraftEditor from 'braft-editor'
import BreafTable from 'braft-extensions/dist/table'
import '../../../../static/css/index.css'
import 'braft-extensions/dist/table.css'
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = 'YYYY';

function Member(props) {

    // 输出表格默认不带边框，如果需要边框，设置参数exportAttrString为'border="1" style="border-collapse: collapse"'
    const options = {
        defaultColumns: 5, // 默认列数
        defaultRows: 5, // 默认行数
        withDropdown: true, // 插入表格前是否弹出下拉菜单
        columnResizable: true, // 是否允许拖动调整列宽，默认false
        exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
        // includeEditors: ['id-1'], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
        // excludeEditors: ['id-2']  // 指定该模块对哪些BraftEditor无效
    };

    BraftEditor.use(BreafTable(options));

    const [form] = Form.useForm();

    const [form1] = Form.useForm()

    const [form2] = Form.useForm()

    const [form3] = Form.useForm()

    const [form4] = Form.useForm()

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 12 },
    }

    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 20 },
    }

    // 周期对象
    const [week, setWeek] = useState({})

    // 年份
    const [year, setYear] = useState(moment().locale('zh-cn').format('YYYY'))

    // 今年
    const [nowYear] = useState(moment().locale('zh-cn').format('YYYY'))

    // 周期集合
    const [weekList, setWeekList] = useState([])

    // 下标
    const [index, setIndex] = useState(0)

    // 名字
    const [ name , setName ] = useState('')

    // 项目
    const [project, setProject] = useState('')

    // 团队
    const [team, setTeam] = useState('')

    // 周期
    const [ period , setPeriod ] = useState('')

    // 项目集
    const [projectList, setProjectList] = useState([])

    // 团队集
    const [teamList, setTeamList] = useState([])

    // 团队名字
    const [teamName, setTeamName] = useState('')

    // 项目名字
    const [projectName, setProjectName] = useState('')

    // 人员
    const [peopleList, setPeopleList] = useState([])

    // 文件名
    const [fileNameList, setFileNameList] = useState([])

    // 文件id
    const [fileIdList, setFileIdList] = useState([])

    // 进度
    const [percent, setPercent] = useState(30)

    // 设置编辑器初始内容
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''))

    // 任务
    const [task, setTask] = useState({})


    // 内容
    const [content , setContent ] = useState('')

    // 任务列表 
    const [taskList, setTaskList] = useState([])

    // 数据
    const [date, setDate] = useState([])

    // 打开周报
    const [openReport, setOpenReport] = useState(false)

    // 修改任务
    const [updateTask, setUpdateTask] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    useEffect(() => {
        getTaskFounderProjectList()
        getTaskFounderTeamList()
    }, [])

    useEffect(() => {
        getMemberWeeklyReport()
        setTeam('')
        getTaskFounderTeamList()
    }, [project])

    useEffect(()=>{
        getMemberWeeklyReport()
    },[team])

    // 一年的周期
    useEffect(() => {
        let dataProps = {
            "year": year.toString(),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryWeekList,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data.success) {
                    setIndex(0)
                    setWeekList(res.data.data)
                    setWeek(res.data.data[0])
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }, [year])

    useEffect(() => {
        setWeek(weekList[index])
    }, [index])

    useEffect(()=>{
        getMemberWeeklyReport()
    },[week])

    const getTaskFounderProjectList = () => {
        let dataProps = {
            "founder": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryTaskFounderProjectList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setProjectList(res.data.data)
                    if(res.data.data.length != 1){
                        setProject(res.data.data[1].id)
                    }
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )

    }

    // 获取当前人所在团队列表
    const getTaskFounderTeamList = () => {
        let dataProps = {
            "founder": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        if (project == '') {
            dataProps['project_id'] = 0
        } else {
            dataProps['project_id'] = project
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryTaskFounderTeamList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTeamList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )

    }

    // 搜索的时候查询任务协作人列表接口
    const getSearchCollaboratorsList = () => {
        let dataProps = {
            "project_id": project,
            "team_id": team,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryCollaboratorsList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setPeopleList(res.data.data)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取成员周报
    const getMemberWeeklyReport = ()=>{
        if(week != null){
            let dataProps = {
                "project_id": project,
                "team_id": team,
                "principal": cookie.load('name'),
                "year": year,
                "period": week.period,
                "roleId": cookie.load('role'),
                "shopId": cookie.load('shopList'),
            }
            axios({
                method: 'POST',
                url: servicePath.queryMemberWeeklyReport,
                data: dataProps,
                headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
            }).then(
                res=>{
                    if(res.data.success){
                        setDate(res.data.data)
                    }else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
    }

    // 关闭周报
    const closeReport = () => {
        setName('')
        setContent('')
        setOpenReport(false)
    }

    // 关闭任务详情
    const onUpdateTask = () => {
        setUpdateTask(false)
    }

    // 查看详情
    const lookDetail = (item)=>{
        setPeriod(item.period)
        setName(item.founder)
        setContent(item.content)
        getOwnWeeklyReportDetail(item.id)
    }

     // 获取个人周报详情
     const getOwnWeeklyReportDetail = (id) => {
        let dataProps = {
            "id": id,
            "principal": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryOwnWeeklyReportDetail,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data.success) {
                    setFileNameList(res.data.data[0].file_name);
                    setFileIdList(res.data.data[0].file_uuid)
                    setOpenReport(true)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }


    return (
        <div style={{ paddingBottom: size.height * 0.1 }}>
            <div style={{ display: 'block' }}>
                <Row>
                    <Col>
                        <div style={{marginLeft:'20px'}}>
                            <DatePicker
                                locale={locale}
                                defaultValue={moment(year, dateFormat)}
                                value={moment(year, dateFormat)}
                                format={dateFormat}
                                onChange={(date, dateString) => {
                                    if (dateString <= nowYear) {
                                        setYear(dateString)
                                    } else {
                                        message.warn('请选择合理的年份')
                                    }
                                }}
                                picker="year"
                            />
                        </div>
                    </Col>
                    <Col style={{ marginLeft: size.width * 0.4 }}>
                    <Form
                            form={form}
                            {...layout}
                            labelAlign='left'
                        >
                            <Row>
                                    {/* <Col>
                                    <Select placeholder="搜索成员" style={{ width: 270 }} showSearch onChange={(value) => setPeopleName(value)}>
                                        {
                                            peopleList.map(item => {
                                                return <Option value={item.name}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Col> */}
                                <Form.Item
                                     name="project"
                                     label="项目"
                                >
                                    <Col>
                                        <Select value={project} placeholder="搜索项目" style={{ width: 200, marginRight:50 }} onChange={(value) => {
                                            setProject(value)
                                            setProjectName(projectList[projectList.map(item => item.id).indexOf(value)]['name']);
                                        }}>
                                            {
                                                projectList.map((item) => (
                                                    <Option value={item.id}>{item.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Col>
                                </Form.Item>
                                 <Form.Item
                                    name="team"
                                    label="团队"
                                >
                                     <Col>
                                        <Select value={team} placeholder="搜索团队" style={{ width: 200 }} onChange={(value) => {
                                            setTeam(value)
                                            setTeamName(teamList[teamList.map(item => item.id).indexOf(value)]['name']);
                                        }}>
                                            {
                                                teamList.map((item) => (
                                                    <Option value={item.id}>{item.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Col>
                                </Form.Item>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <div style={{ margin: '0 auto', width: '500px' }}>
                    <Row>
                        <Col>
                            <LeftOutlined style={{ fontSize: '30px', fontWeight: 'bold', marginRight: '30px', marginTop: '10px', display: index == weekList.length-1 ? 'none' : '' }} onClick={() => setIndex(index + 1)} />
                        </Col>
                        <Col>
                            {
                                week != null 
                                ?  <div>
                                    <div style={{ fontSize: '25px', fontWeight: 'bold' }}>{year}年 第{week.period}周</div>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }}>{week.start_date} - {week.end_date}</div>
                                </div>
                                : <div>false</div>
                            }
                           
                        </Col>
                        <Col>
                            <RightOutlined style={{ fontSize: '30px', fontWeight: 'bold', marginLeft: '30px', marginTop: '10px', display: index == 0 ? 'none' : '' }} onClick={() => setIndex(index - 1)} />
                        </Col>
                    </Row>
                </div>
            </div>
            <div>
                <Row>
                    {
                        date.map(item => {
                            return (
                                <Col span={6} style={{ marginTop: '10px' }} onClick={() => lookDetail(item)}>
                                    <div style={{ height: '110px', width: size.width * 0.2 < 300 ? 300 : size.width * 0.2, border: '1px solid #ccc', borderTop: '3px solid #87CEFA', }}>
                                        <div style={{ marginLeft: '10px' }}>
                                            <div style={{ fontSize: '20px' }}>{item.founder} 最后更新于 {item.update_time.substring(0, 10)}</div>
                                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', webkitLineClamp: '3', webkitBoxOrient: 'vertical', }}>{item.content.replace(/<[^>]*>|<\/[^>]*>/gm, "")}</div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
            <Drawer
                closable={false}
                onClose={closeReport}
                visible={openReport}
                width={900}
            >
                <div style={{ fontSize: '30px', fontWeight: 'bold' }}>
                    {name}的第{period}周周报
                </div>
                <div style={{ height: 400, overflow: "scroll", border: '1.5px solid #ccc' }} dangerouslySetInnerHTML={{ __html: content }}></div>
                <div>
                    <div style={{ fontSize: '15px', fontWeight: 'bold', marginTop: '10px' }}>相关文件</div>
                    <div>
                        {
                            fileNameList.map((item, index) => {
                                return <a target="_blank" href={servicePath.downloadWeeklyReportFile + '?principal=' + cookie.load('name') + '&file_uuid=' + fileIdList[index] + '&roleId=' + cookie.load('role') + '&shopId=' + cookie.load('shopList')}><Tag color="blue">{item}</Tag></a>
                            })
                        }
                    </div>
                </div>
                {/* <div>
                    <div style={{ fontSize: '15px', fontWeight: 'bold', marginTop: '10px' }}>相关任务</div>
                    <div>
                        {
                            taskList.map(item => {
                                return <Tag onClick={() => { setTask(item); setUpdateTask(true); setEditorState(BraftEditor.createEditorState(item.content)); }} color="blue">{item.name}</Tag>
                            })
                        }
                    </div>
                </div> */}
            </Drawer>

            {/* 查看任务
            <Drawer
                title="查看任务"
                placement="right"
                closable={false}
                onClose={onUpdateTask}
                visible={updateTask}
                width={1000}
            >
                <Form
                    form={form1}
                    {...layout1}
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="任务名字"
                            >
                                <Input
                                    id='name'
                                    placeholder="请输入任务名字"
                                    defaultValue={task['name']}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="project"
                                label="项目"
                            >
                                <Select defaultValue={task['project']} style={{ width: 260 }} disabled>
                                    <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form form={form4} {...layout1}>
                                <Form.Item
                                    name="team"
                                    label="团队"
                                >
                                    <Select defaultValue={task['team']} style={{ width: 260 }} disabled>
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Form form={form3} {...layout1}>
                                <Form.Item
                                    name="principal"
                                    label="负责人"
                                >
                                    <Select defaultValue={task['principal']} style={{ width: 270 }} disabled>

                                    </Select>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Form form={form2} {...layout1}>
                                <Form.Item
                                    name="coadjutant"
                                    label="协作者"
                                >
                                    <Select defaultValue={task['coadjutant']} style={{ width: 260 }} disabled mode="multiple">

                                    </Select>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="time"
                                label="任务时间"
                            >
                                <RangePicker locale={locale} defaultValue={[moment(task['startTime'], dateFormat), moment(task['endTime'], dateFormat)]} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="duration"
                                label="预计工期"
                            >
                                <Input
                                    id='duration'
                                    placeholder="请输入预计工期(小时)"
                                    defaultValue={task['duration']}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="yxj"
                                label="优先级"
                            >
                                <Select style={{ width: 260 }} defaultValue={task['yxj']} disabled>
                                    <Option value="严重">严重</Option>
                                    <Option value="主要">主要</Option>
                                    <Option value="次要">次要</Option>
                                    <Option value="不重要">不重要</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <div style={{ height: 300, border: '1px solid #ccc' }}>
                            <BraftEditor
                                contentStyle={{ height: 200 }}
                                value={editorState}
                            />
                        </div>
                    </Row>
                    <div>
                        <Row>
                            任务进度:
                            <Col span={20} style={{ marginLeft: '5px' }}>
                                <Progress percent={percent} />
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {
                            fileList.map(item => {
                                return <Tag color="blue">{item}</Tag>
                            })
                        }
                    </div>
                </Form>
            </Drawer> */}
        </div>
    )

}


export default Member