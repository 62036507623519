import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Input,
  Typography,
  message,
  Modal,
  Button,
  Spin,
  Table,
  Select,
  DatePicker,
  Tabs,
  Popover,
} from "antd";
import cookie from "react-cookies";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import servicePath from "../../config/apiUrl";
import NbAxios from "../../config/utils/nbAxios";
import webUrl from "../../config/webUrl";
import style from "./index.module.css";
import useDebounce from "../../hook/useDebounce";
const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const dateFormat = "YYYY-MM-DD";
function Summary(props) {
  const fieldsDict = {
    员工ID: "employeeId",
    员工姓名: "member_name",
    月薪: "amount_of_month",
    法定节假日加班费用: "amount_of_statutory_holiday_break",
    电脑补贴: "amount_of_computer",
    加班补贴: "amount_of_overtime",
    客服晚班补贴: "amount_of_customer_service_night_shift",
    住宿补贴: "amount_of_accommodation",
    扣款: "amount_of_cut_payment",
    子女教育支出扣除: "amount_of_children_education",
    继续教育支出扣除: "amount_of_continuing_education",
    住房贷款利息支出扣除: "amount_of_deduction_of_housing_loan_interest",
    住房租金支出扣除: "amount_of_housing_rent",
    赡养老人支出扣除: "amount_of_support_the_elderly",
    通讯费: "amount_of_communications",
    婴幼儿照护费用: "baby_care",
    员工关怀: "worker_care",
    公积金开始缴纳时间: "accumulation_fund_start_time",
    社保缴纳地: "social_security_station",
    是否外包: "outsource",
    开始年份月份日期: "start_year_month_day",
    结束年份月份日期: "end_year_month_day",
    最高月薪: "max_amount_of_month",
    最低月薪: "min_amount_of_month",
    月薪判断系数: "rate_of_amount_of_month",
    公积金基数: "accumulation_fund_cardinal",
    社保基数: "social_security_cardinal",
    科目: "subject",
    费用: "amount_of_expenses",
    月份: "month",
    年份: "year",
    公司ID: "company_id",
    部门ID: "department_id",
    项目ID: "item_id",
    店铺ID: "shop_id",
    绩效奖金: "amount_of_achievements",
    项目参数: "item_ratio",
    // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(7/7) start
    专票税率: "special_ticket_tax_rate",
    // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(7/7) end
  };
  const disabledList = [
    "公司",
    "部门",
    "项目",
    "项目参数",
    "店铺",
    "岗位",
    "店铺参数",
  ];
  var date = new Date();
  // 递归拷贝(深拷贝)
  var deepCopy = function (source) {
    var result = {};
    for (var key in source) {
      result[key] =
        typeof source[key] === "object" &&
        source[key] !== null &&
        !Array.isArray(source[key])
          ? deepCopy(source[key])
          : source[key];
    }
    return result;
  };
  // 对象移除
  Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
      this.splice(index, 1);
    }
  };
  // 更多操作菜单
  const moreActions = (
    <div>
      {/* <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                }}>上传</Button>
            </div> */}
      <div>
        <Button size="small" style={{ width: "92px" }} onClick={() => {}}>
          修改
        </Button>
      </div>
      {/* <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                }}>删除</Button>
            </div> */}
      <div>
        <a>
          <Button
            size="small"
            style={{ width: "92px" }}
            onClick={() => {
              window.open(
                `${webUrl}${`/app/humanResources/${calculation_type}/management?tabKey=personalIncomeTax`}`
              );
            }}
          >
            管理
          </Button>
        </a>
      </div>
    </div>
  );
  // 社保汇总更多操作菜单
  const socailMoreActions = (
    <div>
      {/* <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    if(selectedRowKeys.length !== 0){
                        setMonth([])
                        setSocialCompany('')
                        setSocialOld('')
                        setSocialMedical('')
                        setSocialJob('')
                        setUpdateSocailModel(true)
                    }else{
                        message.warning("请先勾选信息")
                    }
                }}>编辑</Button>
            </div> */}
      <div>
        <Button
          size="small"
          style={{ width: "92px" }}
          onClick={() => {
            if (selectedRowKeys.length !== 0) {
              setMonth("");
              setSocialCompany("");
              setSocialOld("");
              setSocialMedical("");
              setSocialJob("");
              setMakeUpSocailModel(true);
            } else {
              message.warning("请先勾选信息");
            }
          }}
        >
          补缴费用
        </Button>
      </div>
      <div>
        <Button
          size="small"
          style={{ width: "92px" }}
          onClick={() => {
            window.open(
              `${webUrl}${`/app/humanResources/${calculation_type}/management?tabKey=socialSecurity`}`
            );
          }}
        >
          社保管理
        </Button>
      </div>
    </div>
  );
  // 公积金汇总更多操作菜单
  const accumulationFundMoreActions = (
    <div>
      {/* <div>
                <Button size='small' style={{ width: "92px" }} onClick={() => {
                    if(selectedRowKeys.length !== 0){
                        setMonth([])
                        setSocialCompany('')
                        setPersonal('')
                        setUpdateAccumulationFundModel(true)
                    }else{
                        message.warning("请先勾选信息")
                    }
                }}>编辑</Button>
            </div> */}
      <div>
        <Button
          size="small"
          style={{ width: "92px" }}
          onClick={() => {
            if (selectedRowKeys.length !== 0) {
              setMonth("");
              setSocialCompany("");
              setPersonal("");
              setMakeUpAccumulationFundModel(true);
            } else {
              message.warning("请先勾选信息");
            }
          }}
        >
          补缴费用
        </Button>
      </div>
      <div>
        <Button
          size="small"
          style={{ width: "92px" }}
          onClick={() => {
            window.open(
              `${webUrl}${`/app/humanResources/${calculation_type}/management?tabKey=accumulationFund`}`
            );
          }}
        >
          公积金管理
        </Button>
      </div>
    </div>
  );
  // 工资汇总选择的id
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // 工资汇总多选设置
  const onSelect = (record, selected) => {
    let keys = [...selectedRowKeys];
    if (selected) {
      keys = [...selectedRowKeys, record["ID"]];
    } else {
      keys = selectedRowKeys.filter((item) => item !== record["ID"]);
    }
    setSelectedRowKeys(keys);
  };
  const onSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      const addCheckedKeys = changeRows.map((item) => {
        return item["ID"];
      });
      setSelectedRowKeys([...selectedRowKeys, ...addCheckedKeys]);
    } else {
      const subCheckedKeys = selectedRowKeys.filter((员工ID) => {
        return !changeRows.some((item) => {
          return item["ID"] === 员工ID;
        });
      });
      setSelectedRowKeys(subCheckedKeys);
    }
  };
  const rowSelection = {
    columnWidth: 30,
    selectedRowKeys,
    onSelect: onSelect,
    onSelectAll: onSelectAll,
  };
  // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(3/7) start
  // 判断是否必填
  const mustDictMap = (filed, filedData) => {
    let flag = 0;
    filed.map((item) => {
      if (
        IDList.indexOf(item) !== -1
          ? insertData[`${item}ID`] == ""
          : !filedData[item] &&
            !item.includes("ID") &&
            !notRequired.includes(item)
      ) {
        message.warning(`${item}必填`);
        flag = 1;
      }
    });
    // 判断是否全部必填
    if (flag) {
      return false;
    } else {
      return true;
    }
  };
  // 新增字段字典映射
  const InsertDictMap = (dataProps, filed, filedData) => {
    filed.map((item) => {
      if (item.includes("年份") && filedData[item]) {
        dataProps[fieldsDict[item]] = filedData[item].format("YYYY");
      } else if (item.indexOf("时间") !== -1 && filedData[item]) {
        dataProps[fieldsDict[item]] = filedData[item].format(dateFormat);
        //  #I6QHBT NB20人资新增绩效奖金参数传递错误 start
      } else if (IDList.indexOf(item) !== -1) {
        dataProps[fieldsDict[`${item}ID`]] = filedData[`${item}ID`];
        //  #I6QHBT NB20人资新增绩效奖金参数传递错误 end
      } else {
        dataProps[fieldsDict[item]] = filedData[item];
      }
    });
  };
  // 非必填项
  const [notRequired] = useState(["操作", "员工ID", "备注", "专票税率"]);
  // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(3/7) end

  // 类型
  const [calculation_type] = useState(
    props.history.location.pathname.split("/")[3]
  );
  // 是否有效
  const [loading, setLoading] = useState(false);
  // 标签值
  const [tabKey, setTabKey] = useState("paySum");
  // 选中的员工ID
  const [staffID, setStaffID] = useState([]);
  // 员工ID列表
  const [staffIDList, setStaffIDList] = useState([]);
  // 选中的人员
  const [staffData, setStaffData] = useState("");
  // BP管理人列表
  const [BPList, setBPList] = useState([]);
  // 人员列表
  const [staffList, setStaffList] = useState([]);
  // 选中的社保缴纳地
  const [socialAdd, setSocialAdd] = useState([]);
  // 社保缴纳地列表
  const [socialAddList, setSocialAddList] = useState([]);
  // 选中的公司
  const [company, setCompany] = useState([]);
  // 公司列表
  const [companyList, setCompanyList] = useState([]);
  // 选中的部门
  const [department, setDepartmant] = useState([]);
  // 部门列表
  const [departmentList, setDepartmentList] = useState([]);
  // 选中的项目
  const [project, setProject] = useState([]);
  // 项目列表
  const [projectList, setProjectList] = useState([]);
  // 选中的员工类型
  const [staffType, setStaffType] = useState([]);
  // 员工类型列表
  const [staffTypeList, setStaffTypeList] = useState([
    {
      id: 0,
      value: "正式员工",
    },
    // {
    //     id: 1,
    //     value: '离职员工'
    // },
    // {
    //     id: 2,
    //     value: '待入职'
    // },
    {
      id: 3,
      value: "实习生",
    },
    {
      id: 4,
      value: "挂靠",
    },
    {
      id: 5,
      value: "返聘",
    },
    {
      id: 6,
      value: "兼职",
    },
  ]);
  // 选中的店铺
  const [shop, setShop] = useState([]);
  // 店铺列表
  const [shopList, setShopList] = useState([]);
  // 选中的岗位
  const [post, setPost] = useState([]);
  // 岗位列表
  const [postList, setPostList] = useState([]);
  // 选中的科目
  const [subject, setSubject] = useState([]);
  // 科目列表
  const [subjectList, setSubjectList] = useState([
    { id: "残疾人保障金", value: "残疾人保障金" },
    { id: "工会经费", value: "工会经费" },
    { id: "人力外包服务费", value: "人力外包服务费" },
    { id: "兼职", value: "兼职" },
    { id: "主播", value: "主播" },
  ]);
  // 工资汇总表头字段
  const [payFiled] = useState([
    "员工ID",
    "姓名",
    "公司",
    "部门",
    "项目",
    "项目参数",
    "店铺",
    "店铺参数",
    "岗位",
    "员工类型",
    "员工状态",
    "入职时间",
    "月薪",
    "法定节假日加班费用",
    "电脑补贴",
    "加班补贴",
    "客服晚班补贴",
    "住宿补贴",
    "扣款",
    "一月工资",
    "二月工资",
    "三月工资",
    "四月工资",
    "五月工资",
    "六月工资",
    "七月工资",
    "八月工资",
    "九月工资",
    "十月工资",
    "十一月工资",
    "十二月工资",
    "全年工资累计",
  ]);
  // 工资汇总表头
  const [payColumn, setPayColumn] = useState([]);
  // 工资汇总数据源
  const [payDataSource, setPayDataSource] = useState([]);
  // 专项扣除汇总表头字段
  const [deductionFiled] = useState([
    "员工ID",
    "姓名",
    "公司",
    "部门",
    "项目",
    "项目参数",
    "店铺",
    "店铺参数",
    "岗位",
    "员工类型",
    "员工状态",
    "入职时间",
    "子女教育支出扣除",
    "继续教育支出扣除",
    "住房贷款利息支出扣除",
    "住房租金支出扣除",
    "赡养老人支出扣除",
    "通讯费",
    "婴幼儿照护费用",
    "一月专项扣除",
    "二月专项扣除",
    "三月专项扣除",
    "四月专项扣除",
    "五月专项扣除",
    "六月专项扣除",
    "七月专项扣除",
    "八月专项扣除",
    "九月专项扣除",
    "十月专项扣除",
    "十一月专项扣除",
    "十二月专项扣除",
  ]);
  // 专项扣除汇总表头
  const [deductionColumn, setDeductionColumn] = useState([]);
  // 专项扣除汇总表数据源
  const [deductionDataSource, setDeductionDataSource] = useState([]);
  // 社保汇总表头字段
  const [socialFiled] = useState([
    "员工ID",
    "姓名",
    "公司",
    "部门",
    "项目",
    "项目参数",
    "店铺",
    "店铺参数",
    "岗位",
    "员工类型",
    "员工状态",
    "入职时间",
    "社保缴纳地",
    "社保基数",
    "是否外包",
    "一月公司部分",
    "二月公司部分",
    "三月公司部分",
    "四月公司部分",
    "五月公司部分",
    "六月公司部分",
    "七月公司部分",
    "八月公司部分",
    "九月公司部分",
    "十月公司部分",
    "十一月公司部分",
    "十二月公司部分",
    "补缴-公司部分",
    "补缴-个人养老保险",
    "补缴-个人医疗保险",
    "补缴-个人失业保险",
  ]);
  // 社保汇总表头
  const [socialColumn, setSocialColumn] = useState([]);
  // 社保汇总表数据源
  const [socialDataSource, setSocialDataSource] = useState([]);
  // 公积金汇总表头字段
  const [accumulationFundFiled] = useState([
    "员工ID",
    "姓名",
    "公司",
    "部门",
    "项目",
    "项目参数",
    "店铺",
    "店铺参数",
    "岗位",
    "员工类型",
    "员工状态",
    "入职时间",
    "社保缴纳地",
    "公积金开始缴纳时间",
    "是否外包",
    "一月公司部分",
    "二月公司部分",
    "三月公司部分",
    "四月公司部分",
    "五月公司部分",
    "六月公司部分",
    "七月公司部分",
    "八月公司部分",
    "九月公司部分",
    "十月公司部分",
    "十一月公司部分",
    "十二月公司部分",
    "补缴月份",
    "补缴-公司部分",
    "补缴-个人部分",
  ]);
  // 公积金汇总表头
  const [accumulationFundColumn, setAccumulationFundColumn] = useState([]);
  // 公积金汇总表数据源
  const [AccumulationFundDataSource, setAccumulationFundDataSource] = useState(
    []
  );
  // 个税汇总表头字段
  const [taxFiled] = useState([
    "员工ID",
    "姓名",
    "公司",
    "部门",
    "项目",
    "项目参数",
    "店铺",
    "店铺参数",
    "岗位",
    "员工类型",
    "员工状态",
    "入职时间",
    "一月应纳税所得额",
    "二月应纳税所得额",
    "三月应纳税所得额",
    "四月应纳税所得额",
    "五月应纳税所得额",
    "六月应纳税所得额",
    "七月应纳税所得额",
    "八月应纳税所得额",
    "九月应纳税所得额",
    "十月应纳税所得额",
    "十一月应纳税所得额",
    "十二月应纳税所得额",
    "全年应纳税累计",
  ]);
  // 个税汇总表头
  const [taxColumn, setTaxColumn] = useState([]);
  // 个税汇总表数据源
  const [taxDataSource, setTaxDataSource] = useState([]);
  // 额外科目汇总表头字段
  const [additionalFiled] = useState([
    "员工ID",
    "员工姓名",
    "公司",
    "部门",
    "项目",
    "项目参数",
    "店铺",
    "店铺参数",
    "岗位",
    "科目",
    "费用",
    "专票税率",
    "年份",
    "月份",
    "操作",
  ]);
  // 额外科目汇总表头
  const [additionalColumn, setAdditionalColumn] = useState([]);
  // 额外科目汇总表数据源
  const [additionalDataSource, setAdditionalDataSource] = useState([]);
  // 绩效奖金汇总表头字段
  const [rewardFiled] = useState([
    "公司",
    "部门",
    "项目",
    "店铺",
    "绩效奖金",
    "年份",
    "月份",
    "操作",
  ]);
  // 绩效奖金汇总表头
  const [rewardlColumn, setRewardColumn] = useState([]);
  // 绩效奖金汇总表数据源
  const [rewardDataSource, setRewardDataSource] = useState([]);
  // 修改上架时间
  const [socialTime, setSocialTime] = useState(moment());
  // 修改弹窗表头
  const [updateModelTitle, setUpdateMoelTitle] = useState("");
  // 修改人员信息下标
  const [updateIndex, setUpdateIndex] = useState("");
  // 修改弹窗
  const [updateModel, setUpdateModel] = useState(false);
  // 补缴社保弹窗
  const [makeUpSocailModel, setMakeUpSocailModel] = useState(false);
  // 补缴公积金弹窗
  const [makeUpAccumulationFundModel, setMakeUpAccumulationFundModel] =
    useState(false);
  // 月份列表
  const [monthList] = useState([
    "一月",
    "二月",
    "三月",
    "四月",
    "五月",
    "六月",
    "七月",
    "八月",
    "九月",
    "十月",
    "十一月",
    "十二月",
  ]);
  // 修改月份
  const [month, setMonth] = useState("");
  // 修改社保-单位部分
  const [socialCompany, setSocialCompany] = useState("");
  // 修改社保-个人养老保险
  const [socialOld, setSocialOld] = useState("");
  // 修改社保-个人医疗保险
  const [socialMedical, setSocialMedical] = useState("");
  // 修改社保-个人失业保险
  const [socialJob, setSocialJob] = useState("");
  // 修改公积金-个人部分
  const [personal, setPersonal] = useState("");
  // 修改字段设置
  const [updateField, setUpdateField] = useState([]);
  // 待修改记录
  const [updateData, setUpdateData] = useState({});
  // 新增的标题
  const [insertTitle, setInsertTitle] = useState("");
  // 新增字段设置
  const [insertField, setInsertField] = useState([]);
  // 新增的数据
  const [insertData, setInsertData] = useState({});
  // 新增弹窗
  const [insertPayModel, setInsertPayModel] = useState(false);
  // 修改前记录
  const [oldData, setOldData] = useState({});
  // 下拉框字典
  const [selectDict, setSelectDict] = useState({});
  // 关联下拉框字典
  const [relationSelectDict, setRelationSelectDict] = useState({});
  // 需要ID的字段
  const [IDList] = useState(["公司", "部门", "项目", "店铺", "岗位"]);
  // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(6/6) start
  // 员工对应公司列表
  const [staffCompany, setStaffCompany] = useState([]);
  // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(6/6) end
  // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(4/7) start
  // 需要多选的字段
  const [multipleList, setMultipleList] = useState(["月份"]);
  // 员工状态列表
  const [staffStateList, setStaffStateList] = useState([
    {
      id: 0,
      value: "离职",
    },
    {
      id: 1,
      value: "在职员工",
    },
    {
      id: 2,
      value: "待入职",
    },
  ]);
  // 选中的员工状态
  const [staffState, setStaffState] = useState([]);
  function selectDictFn(...args) {
    console.log("执行赋值");
    console.log(args);
    setSelectDict({
      部门: departmentList,
      项目: projectList,
      店铺: shopList,
      岗位: postList,
      员工类型: staffTypeList,
      公司: companyList,
      员工状态: staffTypeList,
      社保缴纳地: socialAddList,
      员工姓名: staffList,
      月份: monthList.reduce((result, item) => {
        result.push({
          id: item,
          value: item,
        });
        return result;
      }, []),
      科目: subjectList,
    });
    console.log({
      部门: departmentList,
      项目: projectList,
      店铺: shopList,
      岗位: postList,
      员工类型: staffTypeList,
      公司: companyList,
      员工状态: staffTypeList,
      社保缴纳地: socialAddList,
      员工姓名: staffList,
      月份: monthList.reduce((result, item) => {
        result.push({
          id: item,
          value: item,
        });
        return result;
      }, []),
      科目: subjectList,
    });
    setRelationSelectDict({
      // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(5/7) start
      公司: [],
      // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(5/7) end
      部门: [],
      项目: [],
      店铺: [],
      员工状态: staffTypeList,
      社保缴纳地: socialAddList,
      员工姓名: staffList,
      月份: monthList.reduce((result, item) => {
        result.push({
          id: item,
          value: item,
        });
        return result;
      }, []),
      岗位: postList,
      科目: subjectList,
    });
  }
  // 防抖
  const debouncer = useDebounce(selectDictFn);
  // 下拉框对应字典初始化
  useEffect(() => {
    debouncer(1, 24, 5);
  }, [
    departmentList,
    projectList,
    shopList,
    postList,
    staffTypeList,
    companyList,
    socialAddList,
  ]);
  // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(4/7) end
  // 工资汇总板块初始化
  useEffect(() => {
    setPayColumn(
      payFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          fixed: item == "员工ID" || item == "姓名" ? "left" : "",
          width:
            item == "员工ID"
              ? "80px"
              : item == "姓名"
              ? "120px"
              : item == "法定节假日加班费用"
              ? "200px"
              : item == "公司"
              ? "250px"
              : "",
          render: (text, record, index) => {
            return item.indexOf("工资") !== -1 && text !== ""
              ? text.toFixed(2)
              : text;
          },
        };
      })
    );
    setDeductionColumn(
      deductionFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          fixed: item == "员工ID" || item == "姓名" ? "left" : "",
          width:
            item == "员工ID"
              ? "80px"
              : item == "姓名"
              ? "120px"
              : item == "住房贷款利息支出扣除"
              ? "200px"
              : item == "公司"
              ? "250px"
              : "",
          // width:"8%",
          // render: (text, record, index) => {
          //     return item == '操作' ? <a onClick={()=>{
          //         setUpdateIndex(index)
          //         setUpdateStaff(record)
          //         setUpdateStaffInfoModel(true)
          //     }}>修改</a>: text
          // }
        };
      })
    );
    setSocialColumn(
      socialFiled.map((item) => {
        return {
          title:
            item.indexOf("月公司部分") !== -1
              ? () => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <div>{item}</div>
                    <div>{item.split("月")[0] + "月个人养老保险"}</div>
                    <div>{item.split("月")[0] + "月个人医疗保险"}</div>
                    <div>{item.split("月")[0] + "月个人失业保险"}</div>
                  </div>
                )
              : item,
          dataIndex: item,
          key: item,
          align: "center",
          // width:"8%",
          fixed: item == "员工ID" || item == "姓名" ? "left" : "",
          width:
            item == "员工ID"
              ? "80px"
              : item == "姓名"
              ? "120px"
              : item.indexOf("月公司部分") !== -1
              ? "550px"
              : item == "公司"
              ? "250px"
              : "",
          render: (text, record, index) => {
            return item.indexOf("月公司部分") !== -1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <span>
                  {record.part[item] !== ""
                    ? record.part[item].toFixed(2)
                    : record.part[item]}
                </span>
                <span>
                  {record.part[item.split("月")[0] + "月个人养老保险"] !== ""
                    ? record.part[
                        item.split("月")[0] + "月个人养老保险"
                      ].toFixed(2)
                    : record.part[item.split("月")[0] + "月个人养老保险"]}
                </span>
                <span>
                  {record.part[item.split("月")[0] + "月个人医疗保险"] !== ""
                    ? record.part[
                        item.split("月")[0] + "月个人医疗保险"
                      ].toFixed(2)
                    : record.part[item.split("月")[0] + "月个人医疗保险"]}
                </span>
                <span>
                  {record.part[item.split("月")[0] + "月个人失业保险"] !== ""
                    ? record.part[
                        item.split("月")[0] + "月个人失业保险"
                      ].toFixed(2)
                    : record.part[item.split("月")[0] + "月个人失业保险"]}
                </span>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setAccumulationFundColumn(
      accumulationFundFiled.map((item) => {
        return {
          title:
            item.indexOf("月公司部分") !== -1
              ? () => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <div>{item}</div>
                    <div>{item.split("月")[0] + "月个人部分"}</div>
                  </div>
                )
              : item,
          dataIndex: item,
          key: item,
          align: "center",
          fixed: item == "员工ID" || item == "姓名" ? "left" : "",
          width:
            item == "员工ID"
              ? "80px"
              : item == "姓名"
              ? "120px"
              : item.indexOf("月公司部分") !== -1
              ? "250px"
              : "",
          render: (text, record, index) => {
            return item.indexOf("月公司部分") !== -1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <span>
                  {record.part[item] !== ""
                    ? record.part[item].toFixed(2)
                    : record.part[item]}
                </span>
                <span>
                  {record.part[item.split("月")[0] + "月个人部分"] !== ""
                    ? record.part[item.split("月")[0] + "月个人部分"].toFixed(2)
                    : record.part[item.split("月")[0] + "月个人部分"]}
                </span>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setTaxColumn(
      taxFiled.map((item) => {
        return {
          title:
            item.indexOf("应纳税所得额") !== -1
              ? () => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <div>{item}</div>
                    <div>{item.split("月")[0] + "月税率"}</div>
                    <div>{item.split("月")[0] + "月速算扣除"}</div>
                    <div>{item.split("月")[0] + "月个人所得税"}</div>
                  </div>
                )
              : item,
          dataIndex: item,
          key: item,
          align: "center",
          fixed: item == "员工ID" || item == "姓名" ? "left" : "",
          width:
            item == "员工ID"
              ? "80px"
              : item == "姓名"
              ? "120px"
              : item.indexOf("应纳税所得额") !== -1
              ? "450px"
              : "",
          render: (text, record, index) => {
            return item.indexOf("应纳税所得额") !== -1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <span>
                  {record.part[item] !== ""
                    ? record.part[item].toFixed(2)
                    : record.part[item]}
                </span>
                <span>{record.part[item.split("月")[0] + "月税率"]}</span>
                <span>
                  {record.part[item.split("月")[0] + "月速算扣除"] !== ""
                    ? record.part[item.split("月")[0] + "月速算扣除"].toFixed(2)
                    : record.part[item.split("月")[0] + "月速算扣除"]}
                </span>
                <span>
                  {record.part[item.split("月")[0] + "月个人所得税"] !== ""
                    ? record.part[item.split("月")[0] + "月个人所得税"].toFixed(
                        2
                      )
                    : record.part[item.split("月")[0] + "月个人所得税"]}
                </span>
              </div>
            ) : item == "全年应纳税累计" && text !== "" ? (
              text.toFixed(2)
            ) : (
              text
            );
          },
        };
      })
    );
    setAdditionalColumn(
      additionalFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width:"8%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("年份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateData(obj);
                    setOldData(record);
                    setUpdateMoelTitle("修改额外科目汇总表");
                    setUpdateModel(true);
                    setUpdateField(additionalFiled);
                  }}
                >
                  编辑
                </a>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
    setRewardColumn(
      rewardFiled.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          // width:"8%",
          render: (text, record, index) => {
            return item == "操作" ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  onClick={() => {
                    setUpdateIndex(index);
                    // 对当前列进行深拷贝，并将时间转为日期格式
                    let obj = deepCopy(record);
                    Object.keys(obj).map((key) => {
                      if (key.indexOf("年份") !== -1 && obj[key]) {
                        obj[key] = moment(obj[key], dateFormat);
                      }
                    });
                    setUpdateData(obj);
                    setOldData(record);
                    setUpdateMoelTitle("修改绩效奖金汇总表");
                    setUpdateModel(true);
                    setUpdateField(rewardFiled);
                  }}
                >
                  编辑
                </a>
              </div>
            ) : (
              text
            );
          },
        };
      })
    );
  }, []);
  // 数据初始化
  useEffect(() => {
    setLoading(true);
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      change_member: cookie.load("name"),
      nb_user_id: cookie.load("id"),
      calculation_type: calculation_type,
    };
    // 初始化下拉框数据
    const getAllData = async () => {
      await Promise.all([
        getCompany(dataProps),
        getDepartment(dataProps),
        getItem(dataProps),
        getShop(dataProps),
        getStation(dataProps),
        getBPList(dataProps),
        getSocialSecurityStation(dataProps),
        // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(4/6) start
        // getMemberInformation(dataProps),
        getRightsBudgetList(dataProps),
        // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(4/6) end
      ]);
    };
    getAllData();
  }, []);
  // 切tab页获取数据
  useEffect(() => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      change_member: cookie.load("name"),
      calculation_type: calculation_type,
      nb_user_id: cookie.load("id"),
      year: date.getFullYear(),
    };
    if (tabKey == "paySum") {
      salaryShow(dataProps);
    } else if (tabKey == "deductionSum") {
      specialDeductionShow(dataProps);
    } else if (tabKey == "socialSum") {
      socialSecurityShow(dataProps);
    } else if (tabKey == "accumulationFundSum") {
      accumulationFundShow(dataProps);
    } else if (tabKey == "taxSum") {
      personalIncomeTaxShow(dataProps);
    } else if (tabKey == "additionalSum") {
      outSubjectExpensesShow(dataProps);
    } else if (tabKey == "rewardSum") {
      achievementBonusCalculation(dataProps);
    }
  }, [tabKey]);
  // 查询公司列表
  const getCompany = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getCompany)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.company_id_list.map((item) => {
            obj.push({
              id: item.company_id,
              value: item.company,
            });
          });

          setCompanyList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询公司列表出错,请联系IT");
      });
  };
  // 查询部门列表
  const getDepartment = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getDepartment)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.department_id_list.map((item) => {
            obj.push({
              id: item.department_id,
              value: item.department,
            });
          });

          setDepartmentList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询部门列表出错,请联系IT");
      });
  };
  // 查询项目列表
  const getItem = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getItem)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.item_id_list.map((item) => {
            obj.push({
              id: item.item_id,
              value: item.item,
            });
          });
          setProjectList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询项目列表出错,请联系IT");
      });
  };
  // 查询店铺列表
  const getShop = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getShop)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.shop_id_list.map((item) => {
            obj.push({
              id: item.shop_id,
              value: item.shop,
            });
          });
          setShopList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询店铺列表出错,请联系IT");
      });
  };
  // 查询岗位列表
  const getStation = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getStation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.station_id_list.map((item) => {
            obj.push({
              id: item.station_id,
              value: item.station,
            });
          });
          setPostList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询岗位列表出错,请联系IT");
      });
  };
  // 查询BP管理人
  const getBPList = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getBPList)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.data_list.map((item) => {
            obj.push({
              id: item.user_id,
              value: item.user_name,
            });
          });
          setBPList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询BP管理人出错,请联系IT");
      });
  };
  // 查询社保缴纳地接口
  const getSocialSecurityStation = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getSocialSecurityStation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.map((item) => {
            obj.push({
              id: item,
              value: item,
            });
          });
          setSocialAddList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询社保缴纳地接口出错,请联系IT");
      });
  };
  // 工资呈现接口
  const salaryShow = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.salaryShow)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setPayDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("工资呈现接口出错,请联系IT");
      });
  };
  // 员工基础信息查询
  const getMemberInformation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getMemberInformation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          let idList = [];
          res.data.data_list.map((item) => {
            obj.push({
              id: item["员工ID"] + "-" + item["姓名"],
              value: item["员工ID"] + "-" + item["姓名"],
            });
            idList.push(item["员工ID"]);
          });
          // 员工ID去重
          let uniqueIdList = [...new Set(idList)];
          uniqueIdList.sort((a, b) => {
            return a - b; // 升序
          });
          setStaffIDList(uniqueIdList);
          setStaffList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("员工基础信息查询接口出错,请联系IT");
      });
  };
  // 当月专项附加扣除呈现接口
  const specialDeductionShow = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.specialDeductionShow)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setDeductionDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("当月专项附加扣除呈现接口出错,请联系IT");
      });
  };
  // 社保呈现接口
  const socialSecurityShow = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.socialSecurityShow)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          res.data.map((item, index) => {
            item["ID"] = item["员工ID"] + "-" + index;
          });
          setSocialDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("社保呈现接口出错,请联系IT");
      });
  };
  // 公积金呈现接口
  const accumulationFundShow = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.accumulationFundShow)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          res.data.map((item, index) => {
            item["ID"] = item["员工ID"] + "-" + index;
          });
          setAccumulationFundDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("公积金呈现接口出错,请联系IT");
      });
  };
  // 个人所得税呈现接口
  const personalIncomeTaxShow = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.personalIncomeTaxShow)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setTaxDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("个人所得税呈现接口出错,请联系IT");
      });
  };
  // 费用汇总呈现接口
  const outSubjectExpensesShow = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.outSubjectExpensesShow)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.map((item, index) => {
            obj.push(item["科目"]);
          });
          setAdditionalDataSource(res.data);
          let uniqueSubject = [...new Set(obj)];
          // setSubjectList(uniqueSubject)
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("费用汇总呈现接口出错,请联系IT");
      });
  };
  // 绩效奖金汇总呈现接口
  const achievementBonusCalculation = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.achievementBonusCalculation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setRewardDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("绩效奖金汇总呈现接口出错,请联系IT");
      });
  };
  // 额外费用修改接口
  const changeOutSubjectExpenses = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeOutSubjectExpenses)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffData,
            socialAdd: socialAdd,
            shop_id: shop,
            station_id: post,
            company_id: company,
            department_id: department,
            item_id: project,
            member_type: staffType,
            year: socialTime.format("YYYY"),
            subject: subject,
            nb_user_id: cookie.load("id"),
          };
          outSubjectExpensesShow(dataProps);
          setUpdateModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("额外费用修改接口出错,请联系IT");
      });
  };
  // 绩效奖金修改接口
  const changeAchievementBonus = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.changeAchievementBonus)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("修改成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffData,
            socialAdd: socialAdd,
            shop_id: shop,
            station_id: post,
            company_id: company,
            department_id: department,
            item_id: project,
            member_type: staffType,
            year: socialTime.format("YYYY"),
            subject: subject,
            nb_user_id: cookie.load("id"),
          };
          achievementBonusCalculation(dataProps);
          setUpdateModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("绩效奖金修改接口出错,请联系IT");
      });
  };
  // 额外费用新增接口
  const addOutSubjectExpenses = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addOutSubjectExpenses)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffData,
            socialAdd: socialAdd,
            shop_id: shop,
            station_id: post,
            company_id: company,
            department_id: department,
            item_id: project,
            member_type: staffType,
            year: socialTime.format("YYYY"),
            subject: subject,
            nb_user_id: cookie.load("id"),
          };
          outSubjectExpensesShow(dataProps);
          setInsertPayModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("额外费用新增接口出错,请联系IT");
      });
  };
  // 绩效奖金新增接口
  const addAchievementBonus = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addAchievementBonus)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("新增成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffData,
            socialAdd: socialAdd,
            shop_id: shop,
            station_id: post,
            company_id: company,
            department_id: department,
            item_id: project,
            member_type: staffType,
            year: socialTime.format("YYYY"),
            subject: subject,
            nb_user_id: cookie.load("id"),
          };
          achievementBonusCalculation(dataProps);
          setInsertPayModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("绩效奖金新增接口出错,请联系IT");
      });
  };
  // 补缴社保费用接口
  const supplementaryPaymentSocialSecurity = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.supplementaryPaymentSocialSecurity)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("补缴成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffData,
            socialAdd: socialAdd,
            shop_id: shop,
            station_id: post,
            company_id: company,
            department_id: department,
            item_id: project,
            member_type: staffType,
            year: socialTime.format("YYYY"),
            subject: subject,
            nb_user_id: cookie.load("id"),
          };
          socialSecurityShow(dataProps);
          setMakeUpSocailModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("补缴社保费用接口出错,请联系IT");
      });
  };
  // 补缴公积金费用接口
  const supplementaryPaymentAccumulationFund = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.supplementaryPaymentAccumulationFund)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("补缴成功");
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            employeeId: staffID,
            member_name: staffData,
            socialAdd: socialAdd,
            shop_id: shop,
            station_id: post,
            company_id: company,
            department_id: department,
            item_id: project,
            member_type: staffType,
            year: socialTime.format("YYYY"),
            subject: subject,
            nb_user_id: cookie.load("id"),
          };
          accumulationFundShow(dataProps);
          setMakeUpAccumulationFundModel(false);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("补缴公积金费用接口出错,请联系IT");
      });
  };
  // 查询公司-部门关系
  const getRelationshipCompanyDepartment = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getRelationshipCompanyDepartment)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.data_list.map((item) => {
            if (item.status == 1) {
              obj.push({
                id: item["部门ID"],
                value: item["部门"],
              });
            }
          });
          let objDict = Object.assign(relationSelectDict, {});
          objDict["部门"] = obj;
          setRelationSelectDict(objDict);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询公司和部门之间的关系出错,请联系IT");
      });
  };
  // 部门项目关系查询
  const getRelationshipDepartmentItem = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getRelationshipDepartmentItem)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.data_list.map((item) => {
            if (item.status == 1) {
              obj.push({
                id: item["项目ID"],
                value: item["项目"],
              });
            }
          });
          let objDict = Object.assign(relationSelectDict, {});
          objDict["项目"] = obj;
          setRelationSelectDict(objDict);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询部门与项目之间的关系出错,请联系IT");
      });
  };
  // 项目和店铺关系查询接口
  const getRelationshipItemShop = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getRelationshipItemShop)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          res.data.data_list.map((item) => {
            if (item.status == 1) {
              obj.push({
                id: item["店铺ID"],
                value: item["店铺"],
              });
            }
          });
          let objDict = Object.assign(relationSelectDict, {});
          objDict["店铺"] = obj;
          setRelationSelectDict(objDict);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("项目和店铺关系查询接口出错,请联系IT");
      });
  };
  // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(3/6) start
  // 获取对应管理人员列表接口
  const getRightsBudgetList = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getRightsBudgetList)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = [];
          let idList = [];
          setStaffCompany(res.data.data_list);
          res.data.data_list.map((item) => {
            obj.push({
              id: item["员工ID"] + "-" + item["姓名"],
              value: item["员工ID"] + "-" + item["姓名"],
            });
            idList.push(item["员工ID"]);
          });
          // 员工ID去重
          let uniqueIdList = [...new Set(idList)];
          uniqueIdList.sort((a, b) => {
            return a - b; // 升序
          });
          setStaffIDList(uniqueIdList);
          setStaffList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("获取对应管理人员列表接口出错,请联系IT");
      });
  };
  // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(3/6) end
  return (
    <div className={style.initial}>
      <div className={style.externalContent}>
        <Tabs
          type="card"
          size="small"
          activeKey={tabKey}
          onChange={(key) => {
            setTabKey(key);
            setStaffID([]);
            setStaffData("");
            setSocialAdd([]);
            setShop([]);
            setPost([]);
            setCompany([]);
            setDepartmant([]);
            setProject([]);
            setStaffType([]);
            setSelectedRowKeys([]);
            setSocialTime(moment());
            setSubject([]);
            setStaffState([]);
          }}
        >
          <TabPane tab={<span>工资汇总</span>} key="paySum"></TabPane>
          <TabPane tab={<span>专项扣除汇总</span>} key="deductionSum"></TabPane>
          <TabPane tab={<span>社保汇总</span>} key="socialSum"></TabPane>
          <TabPane
            tab={<span>公积金汇总</span>}
            key="accumulationFundSum"
          ></TabPane>
          <TabPane tab={<span>个人所得税汇总</span>} key="taxSum"></TabPane>
          <TabPane
            tab={<span>额外科目汇总</span>}
            key="additionalSum"
          ></TabPane>
          <TabPane tab={<span>绩效奖金汇总</span>} key="rewardSum"></TabPane>
        </Tabs>
        <div>
          <div className={style.staffInfo}>
            <div style={{ marginRight: "30px" }}>
              <div>
                <Text>年份</Text>
              </div>
              <div>
                <DatePicker
                  locale={locale}
                  picker="year"
                  size="small"
                  className={style.staffInfoSelectWidth}
                  style={{ height: "32px" }}
                  value={socialTime}
                  onChange={(value, dateString) => {
                    if (value) {
                      setSocialTime(value);
                    } else {
                      message.warning("必须选择年份");
                    }
                  }}
                />
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display: tabKey == "rewardSum" ? "none" : "",
              }}
            >
              <div>
                <Text>员工ID</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  showSearch
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  value={staffID}
                  onChange={(value) => {
                    setStaffID(value);
                  }}
                >
                  {staffIDList.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display: tabKey == "rewardSum" ? "none" : "",
              }}
            >
              <div>
                <Text>姓名</Text>
              </div>
              <div>
                <div>
                  {/* <Select className={style.staffInfoSelectWidth} allowClear value={staffData} onChange={(value)=>{
                                    setStaffData(value)
                                }}>
                                    {staffList.map(item=>(
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select> */}
                  <Input
                    style={{ width: "240px" }}
                    allowClear
                    value={staffData}
                    placeholder="请填写 支持模糊查询"
                    onChange={(e) => {
                      setStaffData(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display: tabKey == "additionalSum" ? "none" : "",
              }}
            >
              <div>
                <Text>公司</Text>
              </div>
              <div>
                <div>
                  <Select
                    className={style.staffInfoSelectWidth}
                    mode="multiple"
                    maxTagCount="responsive"
                    allowClear
                    showSearch
                    optionFilterProp="title"
                    value={company}
                    onChange={(value) => {
                      setCompany(value);
                    }}
                  >
                    {companyList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display: tabKey == "additionalSum" ? "none" : "",
              }}
            >
              <div>
                <Text>部门</Text>
              </div>
              <div>
                <div>
                  <Select
                    className={style.staffInfoSelectWidth}
                    mode="multiple"
                    maxTagCount="responsive"
                    allowClear
                    showSearch
                    optionFilterProp="title"
                    value={department}
                    onChange={(value) => {
                      setDepartmant(value);
                    }}
                  >
                    {departmentList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display: tabKey == "additionalSum" ? "none" : "",
              }}
            >
              <div>
                <Text>项目</Text>
              </div>
              <div>
                <div>
                  <Select
                    className={style.staffInfoSelectWidth}
                    mode="multiple"
                    maxTagCount="responsive"
                    allowClear
                    showSearch
                    optionFilterProp="title"
                    value={project}
                    onChange={(value) => {
                      setProject(value);
                    }}
                  >
                    {projectList.map((item) => (
                      <Option value={item.id} title={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display:
                  tabKey !== "socialSum" && tabKey !== "accumulationFundSum"
                    ? "none"
                    : "",
              }}
            >
              <div>
                <Text>社保缴纳地</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  value={socialAdd}
                  onChange={(value) => {
                    setSocialAdd(value);
                  }}
                >
                  {socialAddList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display: tabKey == "additionalSum" ? "none" : "",
              }}
            >
              <div>
                <Text>店铺</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  value={shop}
                  onChange={(value) => {
                    setShop(value);
                  }}
                >
                  {shopList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display:
                  tabKey == "additionalSum" || tabKey == "rewardSum"
                    ? "none"
                    : "",
              }}
            >
              <div>
                <Text>岗位</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  mode="multiple"
                  maxTagCount="responsive"
                  value={post}
                  onChange={(value) => {
                    setPost(value);
                  }}
                >
                  {postList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display:
                  tabKey == "additionalSum" || tabKey == "rewardSum"
                    ? "none"
                    : "",
              }}
            >
              <div>
                <Text>员工类型</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  mode="multiple"
                  maxTagCount="responsive"
                  value={staffType}
                  onChange={(value) => {
                    setStaffType(value);
                  }}
                >
                  {staffTypeList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display:
                  tabKey == "additionalSum" || tabKey == "rewardSum"
                    ? "none"
                    : "",
              }}
            >
              <div>
                <Text>员工状态</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  mode="multiple"
                  maxTagCount="responsive"
                  value={staffState}
                  onChange={(value) => {
                    setStaffState(value);
                  }}
                >
                  {staffStateList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginRight: "30px",
                display: tabKey !== "additionalSum" ? "none" : "",
              }}
            >
              <div>
                <Text>科目</Text>
              </div>
              <div>
                <Select
                  className={style.staffInfoSelectWidth}
                  allowClear
                  showSearch
                  optionFilterProp="title"
                  mode="multiple"
                  maxTagCount="responsive"
                  value={subject}
                  onChange={(value) => {
                    setSubject(value);
                  }}
                >
                  {subjectList.map((item) => (
                    <Option value={item.id} title={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className={style.ButtonEnd} style={{ marginTop: "20px" }}>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  shopId: cookie.load("shopId"),
                  change_member: cookie.load("name"),
                  calculation_type: calculation_type,
                  employeeId: staffID,
                  member_name: staffData,
                  socialAdd: socialAdd,
                  shop_id: shop,
                  station_id: post,
                  company_id: company,
                  department_id: department,
                  item_id: project,
                  member_type: staffType,
                  year: socialTime.format("YYYY"),
                  subject: subject,
                  member_status: staffState,
                  nb_user_id: cookie.load("id"),
                };
                // if (socialTime) {
                //     message.warning("必须选择年份")
                //     return false
                // }
                if (tabKey == "paySum") {
                  salaryShow(dataProps);
                } else if (tabKey == "deductionSum") {
                  specialDeductionShow(dataProps);
                } else if (tabKey == "socialSum") {
                  socialSecurityShow(dataProps);
                } else if (tabKey == "accumulationFundSum") {
                  accumulationFundShow(dataProps);
                } else if (tabKey == "taxSum") {
                  personalIncomeTaxShow(dataProps);
                } else if (tabKey == "additionalSum") {
                  outSubjectExpensesShow(dataProps);
                } else if (tabKey == "rewardSum") {
                  achievementBonusCalculation(dataProps);
                }
              }}
            >
              查询
            </Button>
            <Button
              size="small"
              style={{
                borderColor: "#0091FF",
                color: "#0091FF",
                borderRadius: "3px",
                textAlign: "center",
                margin: "0px 20px 0px 20px",
              }}
              onClick={() => {
                setStaffID([]);
                setStaffData("");
                setSocialAdd([]);
                setShop([]);
                setPost([]);
                setCompany([]);
                setDepartmant([]);
                setProject([]);
                setStaffType([]);
                setSocialTime(moment());
                setSubject([]);
                setStaffState([]);
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  shopId: cookie.load("shopId"),
                  change_member: cookie.load("name"),
                  calculation_type: calculation_type,
                  year: date.getFullYear(),
                  nb_user_id: cookie.load("id"),
                };
                if (tabKey == "paySum") {
                  salaryShow(dataProps);
                } else if (tabKey == "deductionSum") {
                  specialDeductionShow(dataProps);
                } else if (tabKey == "socialSum") {
                  socialSecurityShow(dataProps);
                } else if (tabKey == "accumulationFundSum") {
                  accumulationFundShow(dataProps);
                } else if (tabKey == "taxSum") {
                  personalIncomeTaxShow(dataProps);
                } else if (tabKey == "additionalSum") {
                  outSubjectExpensesShow(dataProps);
                } else if (tabKey == "rewardSum") {
                  achievementBonusCalculation(dataProps);
                }
              }}
            >
              重置
            </Button>
          </div>
        </div>
      </div>
      <Spin
        style={{ display: tabKey == "paySum" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "paySum" ? "" : "none",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>工资费用预估</h3>
            <div className={style.ButtonEnd}>
              {/* <Popover placement='bottom' content={moreActions} trigger="click"> */}
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  window.open(
                    `${webUrl}/app/humanResources/${calculation_type}/management?tabKey=pay`
                  );
                }}
              >
                薪酬管理
              </Button>
              {/* </Popover> */}
            </div>
          </div>
          <div>
            <Table
              size="small"
              columns={payColumn}
              dataSource={payDataSource}
              scroll={{ x: 4200 }}
            />
          </div>
        </div>
      </Spin>
      <Spin
        style={{ display: tabKey == "deductionSum" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "deductionSum" ? "" : "none",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>专项扣除费用预估</h3>
            <div className={style.ButtonEnd}>
              {/* <Popover placement='bottom' content={moreActions} trigger="click"> */}
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  window.open(
                    `${webUrl}/app/humanResources/${calculation_type}/management?tabKey=pay`
                  );
                }}
              >
                专项扣除管理
              </Button>
              {/* </Popover> */}
            </div>
          </div>
          <div>
            <Table
              size="small"
              columns={deductionColumn}
              dataSource={deductionDataSource}
              scroll={{ x: 4500 }}
            />
          </div>
        </div>
      </Spin>
      <Spin
        style={{ display: tabKey == "socialSum" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "socialSum" ? "" : "none",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>社保费用预估</h3>
            <div className={style.ButtonEnd}>
              <Popover
                placement="bottom"
                content={socailMoreActions}
                trigger="click"
              >
                <Button
                  size="small"
                  style={{
                    borderColor: "#0091FF",
                    color: "#0091FF",
                    borderRadius: "3px",
                    textAlign: "center",
                    margin: "0px 20px 0px 20px",
                  }}
                >
                  更多操作
                </Button>
              </Popover>
            </div>
          </div>
          <div>
            <Table
              size="small"
              columns={socialColumn}
              dataSource={socialDataSource}
              rowSelection={rowSelection}
              rowKey={"ID"}
              scroll={{ x: 9500 }}
            />
          </div>
        </div>
      </Spin>
      <Spin
        style={{ display: tabKey == "accumulationFundSum" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "accumulationFundSum" ? "" : "none",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>公积金汇总</h3>
            <div className={style.ButtonEnd}>
              {/* <DatePicker locale={locale} picker="year" size='small' style={{ width: "150px", height:'24px'}}
                                value={socialTime == '' ? '' : moment(socialTime, dateFormat)}
                                onChange={(value, dateString) => {
                                    setSocialTime(dateString)
                                }} /> */}
              <Popover
                placement="bottom"
                content={accumulationFundMoreActions}
                trigger="click"
              >
                <Button
                  size="small"
                  style={{
                    borderColor: "#0091FF",
                    color: "#0091FF",
                    borderRadius: "3px",
                    textAlign: "center",
                    margin: "0px 20px 0px 20px",
                  }}
                >
                  更多操作
                </Button>
              </Popover>
            </div>
          </div>
          <div>
            <Table
              size="small"
              columns={accumulationFundColumn}
              dataSource={AccumulationFundDataSource}
              rowSelection={rowSelection}
              rowKey={"ID"}
              scroll={{ x: 6000 }}
            />
          </div>
        </div>
      </Spin>
      <Spin
        style={{ display: tabKey == "taxSum" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "taxSum" ? "" : "none",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>个税汇总</h3>
            <div className={style.ButtonEnd}>
              {/* <DatePicker locale={locale} picker="year" size='small' style={{ width: "150px", height:'24px'}}
                                value={socialTime == '' ? '' : moment(socialTime, dateFormat)}
                                onChange={(value, dateString) => {
                                    setSocialTime(dateString)
                                }} /> */}
              {/* <Popover placement='bottom' content={moreActions} trigger="click"> */}
              <Button
                size="small"
                type="primary"
                onClick={() =>
                  window.open(
                    `${webUrl}${`/app/humanResources/${calculation_type}/management?tabKey=personalIncomeTax`}`
                  )
                }
              >
                个税管理
              </Button>
              {/* </Popover> */}
            </div>
          </div>
          <div>
            <Table
              size="small"
              columns={taxColumn}
              dataSource={taxDataSource}
              scroll={{ x: 7000 }}
            />
          </div>
        </div>
      </Spin>
      <Spin
        style={{ display: tabKey == "additionalSum" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "additionalSum" ? "" : "none",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>额外科目费用汇总</h3>
            <div className={style.ButtonEnd}>
              {/* <DatePicker locale={locale} picker="year" size='small' style={{ width: "150px", height:'24px'}}
                                value={socialTime == '' ? '' : moment(socialTime, dateFormat)}
                                onChange={(value, dateString) => {
                                    setSocialTime(dateString)
                                }} /> */}
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  let obj = {};
                  // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(5/7) start
                  additionalFiled.map((item) => {
                    if (IDList.indexOf(item) !== -1) {
                      obj[`${item}ID`] = "";
                    } else if (multipleList.includes(item)) {
                      obj[item] = [];
                    } else {
                      obj[item] = "";
                    }
                  });
                  // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(5/7) end
                  setInsertField(additionalFiled);
                  setInsertTitle("新增额外科目费用汇总");
                  setInsertData(obj);
                  let selectObj = Object.assign(relationSelectDict, {});
                  selectObj["公司"] = [];
                  setRelationSelectDict(selectObj);
                  setInsertPayModel(true);
                }}
              >
                新增
              </Button>
            </div>
          </div>
          <div>
            <Table
              size="small"
              columns={additionalColumn}
              dataSource={additionalDataSource}
            />
          </div>
        </div>
      </Spin>
      <Spin
        style={{ display: tabKey == "rewardSum" ? "" : "none" }}
        spinning={loading}
        tip="数据加载中...."
      >
        <div
          className={style.externalContent}
          style={{
            display: tabKey == "rewardSum" ? "" : "none",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>绩效奖金汇总</h3>
            <div className={style.ButtonEnd}>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  let obj = {};
                  // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(6/7) start
                  rewardFiled.map((item) => {
                    if (IDList.indexOf(item) !== -1) {
                      obj[`${item}ID`] = "";
                    } else if (multipleList.includes(item)) {
                      obj[item] = [];
                    } else {
                      obj[item] = "";
                    }
                  });
                  // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(6/7) start
                  setInsertField(rewardFiled);
                  setInsertTitle("新增绩效奖金汇总");
                  setInsertData(obj);

                  let selectObj = Object.assign(relationSelectDict, {});
                  selectObj["公司"] = companyList;
                  setRelationSelectDict(selectObj);
                  setInsertPayModel(true);
                }}
              >
                新增
              </Button>
            </div>
          </div>
          <div>
            <Table
              size="small"
              columns={rewardlColumn}
              dataSource={rewardDataSource}
            />
          </div>
        </div>
      </Spin>
      <Modal
        title={updateModelTitle}
        visible={updateModel}
        onCancel={() => {
          setUpdateModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            calculation_type: calculation_type,
            nb_user_id: cookie.load("id"),
          };
          updateField.map((item) => {
            if (IDList.indexOf(item) !== -1) {
              dataProps[fieldsDict[`${item}ID`]] = updateData[`${item}ID`];
            } else {
              if (item.indexOf("年份") !== -1 && updateData[item] !== "") {
                dataProps[fieldsDict[item]] = updateData[item].format("YYYY");
              } else {
                dataProps[fieldsDict[item]] = updateData[item];
              }
            }
          });
          if (updateModelTitle == "修改额外科目汇总表") {
            dataProps["year_key"] = oldData["年份"];
            dataProps["month_key"] = oldData["月份"];
            changeOutSubjectExpenses(dataProps);
          } else if (updateModelTitle == "修改绩效奖金汇总表") {
            dataProps["company_id_key"] = oldData["公司ID"];
            dataProps["department_id_key"] = oldData["部门ID"];
            dataProps["item_id_key"] = oldData["项目ID"];
            dataProps["shop_id_key"] = oldData["店铺ID"];
            dataProps["year_key"] = oldData["年份"];
            dataProps["month_key"] = oldData["月份"];
            changeAchievementBonus(dataProps);
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width={800}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {updateField.map((item) => {
            return item !== "操作" &&
              item !== "员工ID" &&
              item !== "员工姓名" ? (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "120px 1fr",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                    textAlign: "right",
                  }}
                >
                  <Text>{item}</Text>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  {item.indexOf("年份月份") !== -1 ? (
                    <DatePicker
                      locale={locale}
                      style={{ width: "220px" }}
                      value={updateData[item]}
                      onChange={(value, dateString) => {
                        let obj = Object.assign({}, updateData);
                        obj[item] = value;
                        setUpdateData(obj);
                      }}
                    />
                  ) : selectDict[item] ? (
                    <Select
                      value={
                        IDList.indexOf(item) !== -1
                          ? updateData[`${item}ID`]
                          : updateData[item]
                      }
                      style={{ width: "220px" }}
                      allowClear
                      showSearch
                      optionFilterProp="title"
                      onChange={(value) => {
                        let obj = Object.assign({}, updateData);
                        if (IDList.indexOf(item) !== -1) {
                          obj[`${item}ID`] = value;
                        } else {
                          obj[item] = value;
                        }
                        setUpdateData(obj);
                      }}
                      disabled={
                        updateModelTitle == "修改额外科目汇总表" &&
                        disabledList.indexOf(item) !== -1
                          ? true
                          : false
                      }
                    >
                      {selectDict[item].map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  ) : item == "年份" ? (
                    <DatePicker
                      locale={locale}
                      style={{ width: "220px" }}
                      value={updateData[item]}
                      onChange={(value, dateString) => {
                        let obj = Object.assign({}, updateData);
                        obj[item] = value;
                        setUpdateData(obj);
                      }}
                      picker="year"
                    />
                  ) : (
                    <Input
                      value={updateData[item]}
                      onChange={(e) => {
                        let obj = Object.assign({}, updateData);
                        obj[item] = e.target.value;
                        setUpdateData(obj);
                      }}
                      style={{ width: "220px" }}
                      disabled={
                        updateModelTitle == "修改额外科目汇总表" &&
                        disabledList.indexOf(item) !== -1
                          ? true
                          : false
                      }
                    />
                  )}
                </div>
              </div>
            ) : (
              ""
            );
          })}
        </div>
      </Modal>
      {/* <Modal
                title="修改社保费用"
                visible={updateSocailModel}
                onCancel={() => {
                    setUpdateSocailModel(false)
                }}
                onOk={() => {
                    let dataProps = {
                        roleId: cookie.load('role'),
                        shopList: cookie.load('shopList'),
                        month: month,
                        socialCompany: socialCompany,
                        socialOld: socialOld,
                        socialMedical: socialMedical,
                        socialJob: socialJob,
                        calculation_type:calculation_type,
                    }
                    setUpdateSocailModel(false)
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                <div className={style.updateSocail}>
                    <div>月份</div>
                    <Select value={month} allowClear mode='multiple' maxTagCount="responsive" onChange={(value) => {
                        setMonth(value)
                    }}>
                        {monthList.map(item => (
                            <Option value={item}>{item}</Option>
                        ))}
                    </Select>
                    <div>社保-公司部分</div>
                    <Input value={socialCompany} onChange={(e) => setSocialCompany(e.target.value)} />
                    <div>社保-个人养老保险</div>
                    <Input value={socialOld} onChange={(e) => setSocialOld(e.target.value)} />
                    <div>社保-个人医疗保险</div>
                    <Input value={socialMedical} onChange={(e) => setSocialMedical(e.target.value)} />
                    <div>社保-个人失业保险</div>
                    <Input value={socialJob} onChange={(e) => setSocialJob(e.target.value)} />
                </div>
            </Modal> */}
      <Modal
        title="补缴费用弹窗"
        visible={makeUpSocailModel}
        onCancel={() => {
          setMakeUpSocailModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            payment_month: month,
            payment_company: socialCompany,
            payment_elderly: socialOld,
            payment_medical: socialMedical,
            payment_unemployment: socialJob,
            calculation_type: calculation_type,
            company_field: "补缴-公司部分",
            elderly_field: "补缴-个人养老保险",
            medical_field: "补缴-个人医疗保险",
            unemployment_field: "补缴-个人失业保险",
            change_member: cookie.load("name"),
            nb_user_id: cookie.load("id"),
          };
          let obj = [];
          socialDataSource.map((item) => {
            if (selectedRowKeys.indexOf(item["ID"]) !== -1) {
              obj.push({
                old_employeeId: item["员工ID"],
                old_name: item["姓名"],
                old_social_security_station: item["社保缴纳地"],
                old_year: socialTime.format("YYYY"),
                old_company_id: item["公司ID"],
              });
            }
          });
          dataProps.selected_list = obj;
          supplementaryPaymentSocialSecurity(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width={800}
      >
        <div className={style.updateSocail}>
          <div>补缴月份</div>
          <Select
            value={month}
            allowClear
            showSearch
            style={{ textAlign: "left" }}
            onChange={(value) => {
              setMonth(value);
            }}
          >
            {monthList.map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
          <div>补缴-公司部分</div>
          <Input
            value={socialCompany}
            onChange={(e) => setSocialCompany(e.target.value)}
          />
          <div>补缴-个人养老保险</div>
          <Input
            value={socialOld}
            onChange={(e) => setSocialOld(e.target.value)}
          />
          <div>补缴-个人医疗保险</div>
          <Input
            value={socialMedical}
            onChange={(e) => setSocialMedical(e.target.value)}
          />
          <div>补缴-个人失业保险</div>
          <Input
            value={socialJob}
            onChange={(e) => setSocialJob(e.target.value)}
          />
        </div>
      </Modal>
      {/* <Modal
                title="修改公积金费用"
                visible={updateAccumulationFundModel}
                onCancel={() => {
                    setUpdateAccumulationFundModel(false)
                }}
                onOk={() => {
                    let dataProps = {
                        roleId: cookie.load('role'),
                        shopList: cookie.load('shopList'),
                        month: month,
                        socialCompany: socialCompany,
                        personal: personal,
                    }
                    setUpdateAccumulationFundModel(false)
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                <div className={style.updateSocail}>
                    <div>月份</div>
                    <Select value={month}  style={{textAlign:'left'}} allowClear onChange={(value) => {
                        setMonth(value)
                    }}>
                        {monthList.map(item => (
                            <Option value={item}>{item}</Option>
                        ))}
                    </Select>
                    <div>社保-公司部分</div>
                    <Input value={socialCompany} onChange={(e) => setSocialCompany(e.target.value)} />
                    <div>社保-个人部分</div>
                    <Input value={personal} onChange={(e) => setPersonal(e.target.value)} />
                </div>
            </Modal> */}
      <Modal
        title="补缴费用弹窗"
        visible={makeUpAccumulationFundModel}
        onCancel={() => {
          setMakeUpAccumulationFundModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            nb_user_id: cookie.load("id"),
            payment_month: month,
            payment_company: socialCompany,
            payment_personal: personal,
            calculation_type: calculation_type,
            company_field: "补缴-公司部分",
            personal_field: "补缴-个人部分",
            change_member: cookie.load("name"),
          };
          let obj = [];
          AccumulationFundDataSource.map((item) => {
            if (selectedRowKeys.indexOf(item["ID"]) !== -1) {
              obj.push({
                old_employeeId: item["员工ID"],
                old_name: item["姓名"],
                old_social_security_station: item["社保缴纳地"],
                old_year: socialTime.format("YYYY"),
                old_company_id: item["公司ID"],
              });
            }
          });
          dataProps.selected_list = obj;
          supplementaryPaymentAccumulationFund(dataProps);
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width={800}
      >
        <div className={style.updateSocail}>
          <div>补缴月份</div>
          <Select
            value={month}
            style={{ textAlign: "left" }}
            allowClear
            showSearch
            onChange={(value) => {
              setMonth(value);
            }}
          >
            {monthList.map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
          <div>补缴-公司部分</div>
          <Input
            value={socialCompany}
            onChange={(e) => setSocialCompany(e.target.value)}
          />
          <div>补缴-个人部分</div>
          <Input
            value={personal}
            onChange={(e) => setPersonal(e.target.value)}
          />
        </div>
      </Modal>
      <Modal
        title={insertTitle}
        visible={insertPayModel}
        onCancel={() => {
          setInsertPayModel(false);
        }}
        onOk={() => {
          let dataProps = {
            roleId: cookie.load("role"),
            shopList: cookie.load("shopList"),
            shopId: cookie.load("shopId"),
            change_member: cookie.load("name"),
            nb_user_id: cookie.load("id"),
            calculation_type: calculation_type,
          };
          // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(1/7) start

          // let flag = false
          // insertField.map(item => {
          //     if(IDList.indexOf(item)!== -1?insertData[`${item}ID`] == '':insertData[item] == '' && item !=='员工ID'  && item !=='操作'){
          //         flag = true
          //     }
          //     if(IDList.indexOf(item)!== -1){
          //         dataProps[fieldsDict[`${item}ID`]] = insertData[`${item}ID`]
          //     }else{
          //         if(item.indexOf('年份')!==-1&&insertData[item]){
          //             dataProps[fieldsDict[item]] = insertData[item].format('YYYY')
          //         }else{
          //             dataProps[fieldsDict[item]] = insertData[item]
          //         }
          //     }
          // })
          // if(flag){
          //     message.warning('所有字段必填')
          //     return false
          // }
          // 判断是否必填
          if (!mustDictMap(insertField, insertData)) {
            return false;
          }
          // 字典映射
          InsertDictMap(dataProps, insertField, insertData);
          // #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(1/7) end
          if (insertTitle == "新增额外科目费用汇总") {
            addOutSubjectExpenses(dataProps);
          } else if (insertTitle == "新增绩效奖金汇总") {
            addAchievementBonus(dataProps);
          }
        }}
        centered={true}
        cancelText="取消"
        okText="确认"
        width="800px"
      >
        <Spin
          style={{ display: tabKey == "project" ? "" : "none" }}
          spinning={loading}
          tip="数据加载中...."
        >
          <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
            {insertField.map((item) => {
              return item !== "员工ID" && item !== "操作" ? (
                <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                  {/* #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(2/7) start */}
                  <div>
                    {!notRequired.includes(item) && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                    {item}
                  </div>
                  {item == "员工姓名" ? (
                    <div>
                      <Select
                        style={{ width: "300px" }}
                        value={insertData[item]}
                        allowClear
                        showSearch
                        optionFilterProp="title"
                        onChange={(value) => {
                          let obj = Object.assign({}, insertData);
                          obj[item] = value;
                          // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(1/6) start
                          obj["部门ID"] = "";
                          obj["项目ID"] = "";
                          obj["公司ID"] = "";
                          let companyList = [];
                          staffCompany.map((item) => {
                            if (
                              value &&
                              item["员工ID"] == value.split("-")[0]
                            ) {
                              // 公司自带赋值
                              obj["公司ID"] = item["公司ID"];
                              // 添加公司列表
                              companyList.push({
                                id: item["公司ID"],
                                value: item["公司名字"],
                              });
                              // 查询公司-部门关系
                              let dataProps = {
                                roleId: cookie.load("role"),
                                shopList: cookie.load("shopList"),
                                change_member: cookie.load("name"),
                                calculation_type: calculation_type,
                                company_id: [item["公司ID"]],
                              };
                              getRelationshipCompanyDepartment(dataProps);
                            }
                          });
                          setInsertData(obj);
                          let objDict = Object.assign(relationSelectDict, {});
                          objDict["公司"] = companyList;
                          setRelationSelectDict(objDict);
                          // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(1/6) end
                        }}
                      >
                        {item == "员工姓名"
                          ? staffList.map((item) => (
                              <Option value={item.value} title={item.value}>
                                {item.value}
                              </Option>
                            ))
                          : ""}
                        {/*  #I6532W NB20人资呈现页面额外科目汇总新增专票税率和月份变多选 此处(2/7) end */}
                      </Select>
                    </div>
                  ) : relationSelectDict[item] ? (
                    <Select
                      mode={multipleList.includes(item) ? "multiple" : ""}
                      value={
                        IDList.indexOf(item) !== -1
                          ? insertData[`${item}ID`]
                          : insertData[item]
                      }
                      style={{ width: "300px" }}
                      allowClear
                      showSearch
                      optionFilterProp="title"
                      onChange={(value) => {
                        let obj = Object.assign({}, insertData);
                        if (IDList.indexOf(item) !== -1) {
                          obj[`${item}ID`] = value;
                        } else {
                          obj[item] = value;
                        }
                        setInsertData(obj);
                        let dataProps = {
                          roleId: cookie.load("role"),
                          shopList: cookie.load("shopList"),
                          change_member: cookie.load("name"),
                          calculation_type: calculation_type,
                        };
                        // 获取关联关系列表
                        if (item == "公司") {
                          dataProps.company_id = [value];
                          getRelationshipCompanyDepartment(dataProps);
                        } else if (item == "部门") {
                          dataProps.department_id = [value];
                          getRelationshipDepartmentItem(dataProps);
                        } else if (item == "项目") {
                          dataProps.item_id = [value];
                          getRelationshipItemShop(dataProps);
                        }
                      }}
                      onClick={() => {
                        if (relationSelectDict[item].length == 0) {
                          // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(2/6) start
                          if (
                            item == "公司" &&
                            !insertData[`员工姓名`] &&
                            insertTitle !== "新增绩效奖金汇总" // #I6J44T NB20人资修复汇总页面新增绩效奖金的bug
                          ) {
                            message.warning("请先选择员工");
                            // I65T6S 额外科目汇总新增只显示管理的员工联动公司  此处(2/6) end
                          } else if (item == "部门" && !insertData[`公司ID`]) {
                            message.warning("请先选择公司");
                          } else if (item == "项目" && !insertData[`部门ID`]) {
                            message.warning("请先选择部门");
                          } else if (item == "店铺" && !insertData[`项目ID`]) {
                            message.warning("请先选择项目");
                          }
                        }
                      }}
                    >
                      {relationSelectDict[item].map((item) => (
                        <Option value={item.id} title={item.value}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  ) : item.indexOf("月份") !== -1 ||
                    item.indexOf("时间") !== -1 ? (
                    <DatePicker
                      locale={locale}
                      style={{ width: "300px" }}
                      value={insertData[item]}
                      onChange={(value, dateString) => {
                        let obj = Object.assign({}, insertData);
                        obj[item] = value;
                        setInsertData(obj);
                      }}
                    />
                  ) : item == "年份" ? (
                    <DatePicker
                      locale={locale}
                      style={{ width: "300px" }}
                      value={insertData[item]}
                      onChange={(value, dateString) => {
                        let obj = Object.assign({}, insertData);
                        obj[item] = value;
                        setInsertData(obj);
                      }}
                      picker="year"
                    />
                  ) : (
                    <div>
                      <Input
                        style={{ width: "300px" }}
                        value={insertData[item]}
                        onChange={(e) => {
                          let obj = Object.assign({}, insertData);
                          obj[item] = e.target.value;
                          setInsertData(obj);
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
              );
            })}
          </div>
          {/* <div style={{marginLeft:'20px',marginBottom:'10px'}}>
                        <div>备注</div>
                        <div>
                            <TextArea style={{width:'675px'}} value={projectRemarkInsert} onChange={e=>setProjectRemarkInsert(e.target.value)}/>
                        </div>
                    </div> */}
        </Spin>
      </Modal>
    </div>
  );
}
export default Summary;
