import React, {useEffect, useState} from 'react';
import {Button, message, Spin, Form, Drawer, Avatar, List, Collapse, Typography, DatePicker, Modal, Table, Input, Space} from 'antd'
import {SearchOutlined} from '@ant-design/icons';
import Gannt from './components/index'
import Base64 from 'base-64'
import NbAxios from '../../../config/utils/nbAxios'
import servicePath from '../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import moment from 'moment'
import axios from 'axios'
import downExcel from '../../../config/utils/downloadFile'
import Highlighter from 'react-highlight-words';
import style from './style.module.css';
const {Panel} = Collapse;
const {Text, Link} = Typography
const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;
function Index(props) {
    // 定义筛选功能开始
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };
    // 定义筛选功能结束
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv':"3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein':"444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an':"7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr':"f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11':'d4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass':'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    // 操作表单
    const [operatingForm] = Form.useForm()
    // 添加操作表单
    const [addOperatingForm] = Form.useForm()
    // 操作反馈详情
    const [operationFeedbackDetailForm] = Form.useForm()
    // 工作反馈
    const [workFeedForm] = Form.useForm()
    // 数据
    const [data, setData] = useState([])
    // 目录
    const [catalogueDict, setCatalogueDict] = useState([])
    // 事件id
    const [eventId, setEventId] = useState('')
    // 悬停时间
    const [hoverTime, setHoverTime] = useState('')
    // 操作详情是否打开
    const [operatingOpen, setOperatingOpen] = useState(false)
    // 操作id
    const [operatingId, setOperatingId] = useState('')
    // 主线事件集合
    const [mainEvent, setMainEvent] = useState([])
    // 是否添加操作
    const [isAddOperation, setIsAddOperation] = useState(false)
    // 添加操作数据
    const [addOperationData, setAddOperationData] = useState({})
    // 目录下标
    const [catalogueIndex, setCatalogueIndex] = useState('')
    // 目录父下标
    const [catalogueParentIndex, setCatalogueParentIndex] = useState('')
    // 全体员工
    const [employeeList, setEmployeeList] = useState([])
    // 提交工作结果
    const [submitWorkFeedback, setSubmitWorkFeedback] = useState(false)
    // 查看日报
    const [lookDaily, setLookDaily] = useState(false)
    // 日报key
    const [dailyKey, setDailyKey] = useState('-1')
    // 日报时间
    const [dailyTime, setDailyTime] = useState(moment().format('YYYY-MM-DD'))
    // 操作是否完成
    const [isComplete, setIsComplete] = useState(0)
    // 文档文件集合
    const [docList, setDocList] = useState([])
    // 文件list
    const [fileList, setFileList] = useState([])
    // 是否更新
    const [isUpdate, setIsUpdate] = useState(0)
    // 反馈id
    const [feedbackId, setFeedbackId] = useState('')
    // 操作反馈
    const [information, setInformation] = useState('')
    // 未完成操作
    const [undo, setUndo] = useState([])
    // cem
    const [cem, setCem] = useState([])
    // otm
    const [otm, setOtm] = useState([])
    // 商品
    const [com, setCom] = useState([])
    // 主利益
    const [inter, setInter] = useState([])
    // 聚划算
    const [effect, setEffect] = useState([])
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    // 删除操作
    const [isDelete, setIsDelete] = useState(false)
    // 操作详情
    const [detail, setDetail] = useState('')
    // 加载数据配置
    const [ isSpinning , setIsSpinning ] = useState(false)
    // 记录上次操作的父Id
    const [ operationParentId , setOperationParentId  ] = useState('')
    // 操作名字
    const [ operationName , setOperationName ] = useState('')
    // 操作父名字
    const [ operationParentName , setOperationParentName ] = useState('')
    // 操作数据
    const [ operationData , setOperationData ] = useState([])
    // 个性定制操作
    const [ isCustomize , setIsCustomize ] = useState(false)
    // 选择的id
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 名字
    const [nameList, setNameList] = useState([])
    // 开始日期
    const [startDate, setStartDate] = useState(moment().subtract(10, "day").format(dateFormat))
    // 结束日期  
    const [endDate, setEndDate] = useState(moment().add(10, "day").format(dateFormat))
    // 是否文件上传
    const [ isUpload , setIsUpload ] = useState(0)
    // 模型集合
    const [ modalList , setModalList ] = useState([])
    // 模型id
    const [ modalId , setModalId ] = useState('')
    // 多选设置
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        let selectedRowsNameList = []
        selectedRows.map(item => {
            selectedRowsNameList.push(item.name)
        })
        setNameList(selectedRowsNameList);
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }
    useEffect(() => {
        // 查询OTM阶段
        // getOtmOperationList()
        // 获取公司所有人
        getAllEployees()
        // 获取权限代码
        getJurisdiction()
    }, [])

    useEffect(()=>{
        setCatalogueDict([])
        // 获取所有时间数据
        getAllData()
    },[startDate, endDate])

    // 定制操作表头
    const customizeColumns = [
        {
            title: '菜单层级',
            dataIndex: 'catalogue_name',
            key: 'catalogue_name',
            align: 'center',
            ...getColumnSearchProps('catalogue_name'),
        },
        {
            title: '操作名字',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            ...getColumnSearchProps('name'),
        }
    ]

    //获取权限代码
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        NbAxios(dataProps, "POST", servicePath.getRoleShopPorts).then(res => {
            if (res.success) {
                setJurisdiction(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取公司所有员工
    const getAllEployees = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryPMSysUser).then(res => {
            if (res.success) {
                setEmployeeList(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询OTM阶段
    const getOtmOperationList = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.queryOtmOperationList).then(res => {
            if (res.success) {
                setMainEvent(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 监控操作id
    useEffect(() => {
        if (operatingId != '') {
            getOtmOperation()
        }
    }, [operatingId])


    // 监控事件id
    useEffect(() => {
        if (eventId != '') {
            setCatalogueDict([])
            getSingleData(eventId)
        }
    }, [eventId])

    useEffect(()=>{
       if(modalId != '' && mainEvent.length != 0){
           setIsSpinning(true)
            let dataProps = {
                "operation_model_id": modalId,
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
            }
            NbAxios(dataProps, "POST", servicePath.queryOtmOperationModelDetailName).then(res => {
                setIsSpinning(false)
                if (res.success) {
                    //操作名称
                    addOperationData['operation_name'] = res.data['operation_name']
                    //业务分类
                    addOperationData['type_business'] = res.data['type_business']
                    // 操作描述
                    addOperationData['operation_detail'] = res.data['operation_detail']
                    // 操作负责人
                    addOperationData['operation_principal'] = res.data['leader_list'][0]
                    // 阶段
                    addOperationData['event'] = mainEvent[0]['otm_stage_name']
                    addOperatingForm.resetFields()
                   // 设置表单的值
                    addOperatingForm.setFieldsValue(addOperationData) 
                    setIsAddOperation(true)
                }else {
                    message.warn(res.errorMsg)
                }
            })
       }
    },[modalId,mainEvent])

    // 监听目录下标
    useEffect(() => {
        if (catalogueIndex != '') {
            setIsSpinning(true)
            let dataModelProps = {
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[3]],
                'time':hoverTime
            }
            NbAxios(dataModelProps, "POST", servicePath.queryOtmOperationList).then(res => {
                if (res.success) {
                    console.log(res.data[0]['otm_stage_name']);
                    addOperationData['event'] = res.data[0]['otm_stage_name']
                    setMainEvent(res.data);
                } else {
                    message.warn(res.errorMsg)
                }
            })
            let dataProps = {
                "name": operationName,
                "stage": operationParentName,
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
            }
            NbAxios(dataProps, "POST", servicePath.queryOtmOperationModelListName).then(res => {
                if (res.success) {
                    if(catalogueIndex == 1){
                        setIsSpinning(false)
                        //操作名称
                        addOperationData['operation_name'] = '主利益点'
                        // 阶段
                        addOperatingForm.resetFields()
                        // 设置表单的值
                        addOperatingForm.setFieldsValue(addOperationData)
                        setModalList([])
                        setIsAddOperation(true)
                    } else if(catalogueIndex == 2){
                        setIsSpinning(false)
                        //操作名称
                        addOperationData['operation_name'] = '聚划算'
                        // 阶段
                        addOperatingForm.resetFields()
                        // 设置表单的值
                        addOperatingForm.setFieldsValue(addOperationData)
                        setModalList([])
                        setIsAddOperation(true)
                    }else{
                        setModalList(res.data);
                        setModalId(res.data[0]['id'])
                    }
                }else {
                message.warn(res.errorMsg)
            }
            })
            
        }
    }, [catalogueIndex])

    // 获取操作详情
    const getOtmOperation = () => {
        let dataProps = {
            "otm_operation_id": operatingId,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.queryOtmOperation).then(res => {
            setIsSpinning(false)
            if (res.success) {
                getworkFeedbackDetail()
                getOtmOperationFeedbackFile()
                setIsComplete(res.data['is_complete']);
                setIsUpload(res.data['is_upload'])
                setOperatingOpen(true)
                operatingForm.resetFields()
                setDetail(res.data['operation_detail'])
                // 设置表单的值
                operatingForm.setFieldsValue({
                    'otm_stage_name': res.data['otm_stage_name'],
                    'leader': res.data['leader'],
                    'leader_id': res.data['leader_id'],
                    'leader_username': res.data['leader_username'],
                    'operation_detail': res.data['operation_detail'],
                    'time': [moment(res.data['operation_start'], dateFormat), moment(res.data['operation_end'], dateFormat)]
                })
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 完成操作接口
    const completeOperation = () => {
        setIsSpinning(true)
        let dataProps = {
            "operation_id": operatingId,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "creator": cookie.load('name'),
            "creator_id": cookie.load('id'),
            "creator_username": Base64.decode(cookie.load('email')),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.completeOtmOperation).then(res => {
            if (res.success) {
                message.success('完成操作')
                getOtmOperation()
            } else {
                setIsSpinning(false)
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取所有数据
    const getAllData = () => {
        setIsSpinning(true)
        let dataProps = {
            "user_uuid": cookie.load('id'),
            "start_time": startDate,
            "end_time": endDate,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.getOtmData).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setData(res.data['data'])
                setCatalogueDict(res.data['catalogue_dict'])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取单个事件数据
    const getSingleData = (eventId) => {
        setIsSpinning(true)
        let dataProps = {
            "user_uuid": cookie.load('id'),
            "start_time": startDate,
            "end_time": endDate,
            "uuid": eventId,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.getOtmSingleData).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setData(res.data['data'])
                setCatalogueDict(res.data['catalogue_dict'])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 重置
    const reset = () => {
        setIsSpinning(true)
        let dataProps = {
            "user_uuid": cookie.load('id'),
            "start_time": startDate,
            "end_time": endDate,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.getOtmData).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setData(res.data['data'])
                setCatalogueDict(res.data['catalogue_dict'])
            } else {
                message.warn(res.errorMsg)
            }
        })
        setHoverTime('')
    }
    // 获取数据
    const getData = () => {
        let dataProps = {
            "user_uuid": cookie.load('id'),
            "start_time": startDate,
            "end_time": endDate,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.getOtmData).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setData(res.data['data'])
                setCatalogueDict(res.data['catalogue_dict'])
                setEventId('')
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 更新操作
    const updateOperating = values => {
        setIsSpinning(true)
        values['otm_operation_id'] = operatingId
        values['start_time'] = values['time'][0].format('YYYY-MM-DD')
        values['end_time'] = values['time'][1].format('YYYY-MM-DD')
        values['roleId'] = cookie.load('role')
        values['shopList'] = cookie.load('shopList')
        values['shopId'] = shopIdDict[props.history.location.pathname.split('/')[3]]
        if (values['leader'].indexOf(",") != -1) {
            let leader = values['leader'].split(',')
            values['leader'] = leader[1]
            values['leader_id'] = leader[0]
            values['leader_username'] = leader[2]
        }
        NbAxios(values, "POST", servicePath.updateOtmOperation).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setOperatingId('')
                setOperatingOpen(false)
                setCatalogueDict([])
                getData()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 添加事件
    const addOperating = values => {
        if(values['event'].indexOf(",") != -1){
            values['otm_event_uuid'] = values['event'].split(',')[1]
            values['otm_stage_id'] = values['event'].split(',')[0]
        }else{
            values['otm_event_uuid'] = mainEvent[0]['otm_event_uuid']
            values['otm_stage_id'] = mainEvent[0]['otm_stage_id']
        }
        values['start_time'] = values['time'][0].format('YYYY-MM-DD')
        values['end_time'] = values['time'][1].format('YYYY-MM-DD')
        values['roleId'] = cookie.load('role')
        values['shopList'] = cookie.load('shopList')
        values['shopId'] = shopIdDict[props.history.location.pathname.split('/')[3]]
        if (values['operation_principal'].indexOf(",") != -1) {
            let leader = values['operation_principal'].split(',')
            values['leader'] = leader[1]
            values['leader_id'] = leader[0]
            values['leader_username'] = leader[2]
        }else{
            values['leader'] =  values['operation_principal']
        }
        values['creator'] = cookie.load('name')
        values['creator_id'] = cookie.load('id')
        values['creator_username'] = Base64.decode(cookie.load('email'))
        NbAxios(values, "POST", servicePath.addOtmOperation).then(res => {
            if (res.success) {
                setCatalogueIndex('')
                setModalId('')
                setMainEvent([])
                setIsAddOperation(false)
                setCatalogueDict([])
                getData()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 文档列表改变的时候出发
    const fileHandleChange = ({fileList}) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'zip', 'rar', 'txt', 'csv']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    //   // 更新文件(限制只能上传一个文件)
                    //   let newFileList = []
                    //   newFileList.push(fileList[fileList.length - 1])
                    setDocList(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setDocList(fileList)
        }
    }

    // 获取工作反馈内容
    const getWorkFeedbackContent = () => {
        getWorkFeedback()
        // getOtmOperationFeedbackFile()
    }

    // 获取工作反馈文件
    const getOtmOperationFeedbackFile = () => {
        let dataProps = {
            "operation_id": operatingId,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.queryOtmOperationFeedbackFile).then(res => {
            if (res.success) {
                setFileList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取工作详情
    const getworkFeedbackDetail = () => {
        let dataProps = {
            "operation_id": operatingId,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.queryOtmOperationFeedback).then(res => {
            if (res.success) {
                if (res.data.length == 0) {
                    setInformation('')
                } else {
                    setInformation(res.data[0]['information'])
                }

            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取工作反馈
    const getWorkFeedback = () => {
        let dataProps = {
            "operation_id": operatingId,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]]
        }
        NbAxios(dataProps, "POST", servicePath.queryOtmOperationFeedback).then(res => {
            if (res.success) {
                if (res.data.length == 0) {
                    setIsUpdate(0)
                    let data = {'information': ''}
                    workFeedForm.setFieldsValue(data)
                    setSubmitWorkFeedback(true)
                } else {
                    setFeedbackId(res.data[0]['id'])
                    setIsUpdate(1)
                    workFeedForm.setFieldsValue(res.data[0])
                    setSubmitWorkFeedback(true)
                }

            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 提交工作反馈
    const sumbitWorkFeedback = (value) => {
        if (isUpdate == 0) {
            value.shopList = cookie.load('shopList')
            value.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
            value.roleId = cookie.load('role')
            value.operation_id = operatingId
            value.creator = cookie.load('name')
            value.creator_id = cookie.load('id')
            value.creator_username = Base64.decode(cookie.load('email'))
            NbAxios(value, "POST", servicePath.addOtmOperationFeedback).then(res => {
                if (res.success) {
                    message.success('新增工作反馈成功')
                    getworkFeedbackDetail()
                } else {
                    message.warn(res.errorMsg)
                }
            })
        } else {
            value.shopList = cookie.load('shopList')
            value.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
            value.roleId = cookie.load('role')
            value.operation_id = operatingId
            value.creator = cookie.load('name')
            value.creator_id = cookie.load('id')
            value.id = feedbackId
            value.creator_username = Base64.decode(cookie.load('email'))
            NbAxios(value, "POST", servicePath.updateOtmOperationFeedback).then(res => {
                if (res.success) {
                    message.success('更新工作反馈成功')
                    getworkFeedbackDetail()
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
        if (docList.length != 0) {
            const formData = new FormData()
            // 多个图片
            docList.forEach((item) => {
                formData.append('file', item['originFileObj']);
            })
            formData.append('shopList', cookie.load('shopList'));
            formData.append('shopId', shopIdDict[props.history.location.pathname.split('/')[3]])
            formData.append('roleId', cookie.load('role'));
            formData.append('operation_id', operatingId);
            formData.append('creator', cookie.load('name'));
            formData.append('creator_id', cookie.load('id'));
            formData.append('creator_username', Base64.decode(cookie.load('email')));
            NbAxios(formData, "POST", servicePath.addOtmOperationFeedbackFile).then(res => {
                if (res.success) {
                    message.success('上传操作反馈文件成功')
                    setDocList([])
                    getOtmOperationFeedbackFile()
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
        setSubmitWorkFeedback(false)
    }

    // 下载文件
    const downFile = (file_uuid) => {
        let dataProps = {
            "file_uuid": file_uuid,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[3]],
            "principal": cookie.load('name'),
            "principal_id": cookie.load('id'),
            "principal_username": Base64.decode(cookie.load('email'))
        }
        axios({
            data: dataProps,
            method: 'POST',
            url: servicePath.downloadOtmOperationFeedbackFile,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                downExcel(res)
            }
        ).catch(e => {
            message.error({content: '文件制作出错，请联系it同事！'})
        })
    }

    // 查看日报
    const lookDailyClick = (time) => {
        let dataProps = {}
        dataProps.shopList = cookie.load('shopList')
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.roleId = cookie.load('role')
        dataProps.time = time
        NbAxios(dataProps, "POST", servicePath.getOtmReportData).then(res => {
            if (res.success) {
                setUndo(res.data['undo']);
                setCem(res.data['do']['CEM'])
                setOtm(res.data['do']['OTM'])
                setCom(res.data['do']['商品'])
                setInter(res.data['do']['主利益点'])
                setEffect(res.data['do']['聚划算'])
                setLookDaily(true)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 删除操作
    const deleteOperation = () => {
        setIsSpinning(true)
        let values = {}
        values['operation_id'] = operatingId
        values['roleId'] = cookie.load('role')
        values['shopList'] = cookie.load('shopList')
        values['shopId'] = shopIdDict[props.history.location.pathname.split('/')[3]]
        NbAxios(values, "POST", servicePath.deleteOtmOperation).then(res => {
            if (res.success) {
                setOperatingId('')
                setOperatingOpen(false)
                setCatalogueDict([])
                getData()
                setIsDelete(false)
                setOperatingOpen(false)
                setOperatingId('')
                if (props.location.pathname.indexOf("confirmGannt") == -1) {
                    setIsComplete(0)
                }
            } else {
                setIsSpinning(false)
                message.warn(res.errorMsg)
            }
        })
    }

    // 个性化定制
    const customize = ()=>{
        let values = {}
        values.shopList = cookie.load('shopList')
        values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        values.roleId = cookie.load('role')
        values.uuid = cookie.load('id')
        NbAxios(values, "POST", servicePath.queryOtmCataloguePersonal).then(res => {
            if (res.success) {
                setSelectedRowKeys(res.data['otm_catalogue_personal_list']);
                setNameList(res.data['otm_catalogue_personal_name_list']);
                setOperationData(res.data['otm_catalogue_population_list']);
                setIsCustomize(true)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 日期变化 
    function dateChange(dates, dateStrings) {
       if (dateStrings[0] == '') {
            message.warn('请选择合理的时间')
            return false
        }else{
            setStartDate(dateStrings[0])
            setEndDate(dateStrings[1]);
        }
    }

    return (
        <div id="app">
            {/* 按钮区域 */}
            <div style={{
                display: 'flex',
                marginLeft: 30,
                marginTop: 10,
                justifyContent:'space-between'
            }}>
                <div style={{display: Object.keys(catalogueDict).length == 0 ? 'none' : 'flex'}}>
                    <Button type="primary" onClick={() => {
                        setEventId('')
                        setCatalogueDict([])
                        reset()
                    }} style={{marginRight: 10}}>重置</Button>
                    <Button onClick={() => lookDailyClick(moment().format('YYYY-MM-DD'))} style={{marginRight: 10}}>日报</Button>
                    <Button onClick={()=>customize()}>个性化定制</Button>
                </div>
                <div>
                    <RangePicker 
                        value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                        locale={locale}
                        style={{height: 28, width: 240}}
                        onChange={dateChange}
                    />
                </div>
            </div>
            <Spin spinning={isSpinning} size="large" tip="数据加载中...">
                {
                    Object.keys(catalogueDict).length == 0 ?
                        <div style={{display: "flex", justifyContent: 'center', height: '40vh', alignItems: 'center'}}>
                            <Text type="secondary">本店铺的OTM为空，请移步OTM事件创建。注意：<Link onClick={()=>{
                                props.history.push({'pathname': `/app/otm/${props.history.location.pathname.split('/')[3]}/event`,})
                            }}>OTM事件创建</Link>需先完成<Link onClick={()=>{
                                props.history.push({'pathname': `/app/otm/${props.history.location.pathname.split('/')[3]}/operation`,})
                            }}>
                                    OTM操作模型的创建
                                </Link>与<Link onClick={()=>{
                                props.history.push({'pathname': `/app/otm/${props.history.location.pathname.split('/')[3]}/eventModel`,})
                            }}>OTM事件模型的创建</Link></Text>
                        </div>
                        : <Gannt data={data} catalogueDict={catalogueDict}
                                setEventId={setEventId} hoverTime={hoverTime}
                                setHoverTime={setHoverTime} addOperatingForm={addOperatingForm}
                                operatingOpen={operatingOpen} setOperatingOpen={setOperatingOpen}
                                setOperatingId={setOperatingId} operatingForm={operatingForm}
                                updateOperating={updateOperating} isAddOperation={isAddOperation}
                                setIsAddOperation={setIsAddOperation} setCatalogueIndex={setCatalogueIndex}
                                setAddOperationData={setAddOperationData} addOperating={addOperating}
                                mainEvent={mainEvent} setCatalogueParentIndex={setCatalogueParentIndex}
                                employeeList={employeeList} props={props} setSubmitWorkFeedback={setSubmitWorkFeedback}
                                submitWorkFeedback={submitWorkFeedback} workFeedForm={workFeedForm}
                                isComplete={isComplete} setIsComplete={setIsComplete} operatingId={operatingId}
                                completeOperation={completeOperation} docList={docList} fileHandleChange={fileHandleChange}
                                sumbitWorkFeedback={sumbitWorkFeedback} getWorkFeedbackContent={getWorkFeedbackContent}
                                fileList={fileList} operationFeedbackDetailForm={operationFeedbackDetailForm}
                                downFile={downFile} information={information} jurisdiction={jurisdiction}
                                setIsDelete={setIsDelete} operationParentId={operationParentId} setOperationParentId={setOperationParentId}
                                isUpload = {isUpload} setOperationName={setOperationName} setOperationParentName={setOperationParentName}
                                setModalId = {setModalId} modalList = {modalList} modalId = {modalId}
                        />
                }
            </Spin>
           
            <Drawer
                title="日报"
                placement="right"
                visible={lookDaily}
                onClose={() => {
                    setDailyTime(moment().format('YYYY-MM-DD'))
                    setLookDaily(false)
                    setDailyKey('-1')
                }}
                width={700}
                zIndex={900}
            >
                <DatePicker locale={locale} style={{marginBottom: 5}} onChange={(date, dateString) => {
                    if (dateString == '') {
                        message.warn('请选择合理的时间')
                        return false
                    } else {
                        setDailyKey('-1')
                        setDailyTime(dateString)
                        lookDailyClick(dateString)
                    }
                }} value={moment(dailyTime, dateFormat)}/>
                <Collapse accordion activeKey={dailyKey} onChange={(key) => setDailyKey(key)}>
                    <Panel header={`OTM(${otm.length}个)`} key="1">
                        {
                            otm.length == 0 ? <span></span>
                                : <List
                                    itemLayout="horizontal"
                                    dataSource={otm}
                                    renderItem={item => (
                                        <List.Item
                                            actions={item.file_uuid == null ? <span></span> : [<a key="list-loadmore-edit"
                                                                                                onClick={() => downFile(item.file_uuid)}>下载文件</a>]}
                                        >
                                            <List.Item.Meta
                                                onClick={() => {
                                                    setOperatingId(item.id)
                                                }}
                                                avatar={<Avatar>{item.leader.substring(0, 1)}</Avatar>}
                                                title={<Text>{item.leader}</Text>}
                                                description={item.information}
                                            />
                                        </List.Item>
                                    )}
                                />
                        }
                    </Panel>
                    <Panel header={`CEM(${cem.length}个)`} key="2">
                        {
                            cem.length == 0 ? <span></span>
                                : <List
                                    itemLayout="horizontal"
                                    dataSource={cem}
                                    renderItem={item => (
                                        <List.Item
                                            actions={item.file_uuid == null ? <span></span> : [<a key="list-loadmore-edit"
                                                                                                onClick={() => downFile(item.file_uuid)}>下载文件</a>]}
                                        >
                                            <List.Item.Meta
                                                onClick={() => {
                                                    setOperatingId(item.id)
                                                }}
                                                avatar={<Avatar>{item.leader.substring(0, 1)}</Avatar>}
                                                title={<Text>{item.leader}</Text>}
                                                description={item.information}
                                            />
                                        </List.Item>
                                    )}
                                />
                        }
                    </Panel>
                    <Panel header={`商品(${com.length}个)`} key="3">
                        {
                            com.length == 0 ? <span></span>
                                : <List
                                    itemLayout="horizontal"
                                    dataSource={com}
                                    renderItem={item => (
                                        <List.Item
                                            actions={item.file_uuid == null ? <span></span> : [<a key="list-loadmore-edit"
                                                                                                onClick={() => downFile(item.file_uuid)}>下载文件</a>]}
                                        >
                                            <List.Item.Meta
                                                onClick={() => {
                                                    setOperatingId(item.id)
                                                }}
                                                avatar={<Avatar>{item.leader.substring(0, 1)}</Avatar>}
                                                title={<Text>{item.leader}</Text>}
                                                description={item.information}
                                            />
                                        </List.Item>
                                    )}
                                />
                        }
                    </Panel>
                    <Panel header={`主利益点(${inter.length}个)`} key="4">
                        {
                            inter.length == 0 ? <span></span>
                                : <List
                                    itemLayout="horizontal"
                                    dataSource={inter}
                                    renderItem={item => (
                                        <List.Item
                                            actions={item.file_uuid == null ? <span></span> : [<a key="list-loadmore-edit"
                                                                                                onClick={() => downFile(item.file_uuid)}>下载文件</a>]}
                                        >
                                            <List.Item.Meta
                                                onClick={() => {
                                                    setOperatingId(item.id)
                                                }}
                                                avatar={<Avatar>{item.leader.substring(0, 1)}</Avatar>}
                                                title={<Text>{item.leader}</Text>}
                                                description={item.information}
                                            />
                                        </List.Item>
                                    )}
                                />
                        }
                    </Panel>
                    <Panel header={`聚划算(${effect.length}个)`} key="5">
                        {
                            effect.length == 0 ? <span></span>
                                : <List
                                    itemLayout="horizontal"
                                    dataSource={effect}
                                    renderItem={item => (
                                        <List.Item
                                            actions={item.file_uuid == null ? <span></span> : [<a key="list-loadmore-edit"
                                                                                                onClick={() => downFile(item.file_uuid)}>下载文件</a>]}
                                        >
                                            <List.Item.Meta
                                                onClick={() => {
                                                    setOperatingId(item.id)
                                                }}
                                                avatar={<Avatar>{item.leader.substring(0, 1)}</Avatar>}
                                                title={<Text>{item.leader}</Text>}
                                                description={item.information}
                                            />
                                        </List.Item>
                                    )}
                                />
                        }
                    </Panel>
                    <Panel header={`未完成(${undo.length}个)`} key="6">
                        {
                            undo.length == 0 ? <span></span>
                                : <List
                                    itemLayout="horizontal"
                                    dataSource={undo}
                                    renderItem={item => (
                                        <List.Item onClick={() => {
                                            setOperatingId(item.id)
                                        }}>
                                            <List.Item.Meta
                                                avatar={<Avatar>{item.leader.substring(0, 1)}</Avatar>}
                                                title={<Text>{item.leader}</Text>}
                                                description={item.operation_detail}
                                            />
                                        </List.Item>
                                    )}
                                />
                        }
                    </Panel>
                </Collapse>


            </Drawer>

            <Modal
                title="是否要删除该操作"
                visible={isDelete}
                onCancel={() => {
                    setIsDelete(false)
                }}
                onOk={deleteOperation}
                okText="确认"
                cancelText="取消"
                centered={true}
                zIndex={1005}
            >
                <span>是否要删除<Text strong>{detail}</Text></span>
            </Modal>

            {/* 定制操作 */}
            <Modal
                title="定制操作"
                visible={isCustomize}
                onCancel={()=>{
                    message.success("取消定制操作")
                    setIsCustomize(false)
                }}
                onOk={()=>{
                    let values = {}
                    values.shopList = cookie.load('shopList')
                    values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                    values.roleId = cookie.load('role')
                    values.uuid = cookie.load('id')
                    values.id_list = selectedRowKeys
                    NbAxios(values, "POST", servicePath.updateOtmCataloguePersonal).then(res => {
                        if (res.success) {
                            message.success('成功定制操作')
                            setSelectedRowKeys([])
                            setNameList([])
                            setCatalogueDict([])
                            getAllData()
                            setIsCustomize(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                <span className={style.conditions}>
                    {
                        nameList.map(item => {
                            return <span className={style.condition}>{item}</span>
                        })
                    }
                </span>
                <Table 
                    dataSource={operationData} 
                    columns={customizeColumns} 
                    rowSelection={rowSelection}
                    rowKey={'id'}
                    />
            </Modal>
        </div>

    )


}

export default Index