import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function html2pdf(fileName){
    const element = document.getElementById('exportPdf'); // 需要导出的pdf页面
    html2canvas(element, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      const dataImg = new Image();
      dataImg.src = canvas.toDataURL('image/png');
      // FileSaver.saveAs(`${dataImg.src}`, `11.jpg`);
      // message.success('下载中...', 1);
    //   这两句是转化为图片下载下来
      const pdf = new jsPDF('p', 'pt', 'a4');
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;
      const pageHeight = (contentWidth / 592.28) * 841.89;
      let leftHeight = contentHeight;
      let position = 0;
      const imgWidth = 595.28;
      const imhHeight = (592.28 / contentWidth) * contentHeight;
      if (leftHeight < pageHeight) {
        pdf.addImage(dataImg.src, 'JPEG', 0, position, imgWidth, imhHeight);
      } else {
        while (leftHeight > 0) {
          pdf.addImage(dataImg.src, 'JPEG', 0, position, imgWidth, imhHeight);
          leftHeight -= pageHeight;
          position -= 841.89;
          if (leftHeight > 0) {
            pdf.addPage();
          }
        }
      }
      pdf.save(`${fileName}.pdf`);
    });
  };
