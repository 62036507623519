import React, { useEffect, useCallback, useState, useContext } from 'react';
import { message, DatePicker, Select, Button, Spin } from 'antd'
import Axios from 'axios'
import moment from 'moment'
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import { StateContext, ADD_CHART_DATA } from './store/stateShare'
import locale from 'antd/es/date-picker/locale/zh_CN';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
const { Option } = Select;
function RaceShopLine(props) {
    const { RangePicker } = DatePicker;
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    // 存储竞店今年数据
    const [nowData, setNowData] = useState([])
    // 去年数据
    const [lastData, setLastData] = useState([])
    // 所有可选字段
    const [allFiled, setAllfiled] = useState([])
    // 定义x轴线
    const [xzhou, setXzhou] = useState([])
    // 店铺名称
    const [shopName, setShopName] = useState('')
    // 今年汇总至
    const [nowTotal, setNowTotal] = useState(0)
    // 去年汇总至
    const [lastTotal, setLastTotal] = useState(0)
    // 累计同比增长率
    const [addRate, setAddRate] = useState(0)
    // 数据加载状态
    const [isLoading, setIsLoading] = useState(false)
    //获取屏幕的长宽
    let size = state.size
    // 本店店铺名
    let shop = props.location.pathname.split('/')[3]
    // 竞店店铺id
    let raceShop = props.location.pathname.split('/')[4]
    let chartData = state.chartData == undefined?{}:state.chartData

    // 开始时间,开始时间和结束时间首次加载先从状态管理中获取，如果没有的话，使用默认时间
    const [start, setStart] = useState(chartData[raceShop] == undefined?moment().subtract(15, 'day'):moment(chartData[raceShop].start_time))
    // 结束时间
    const [end, setEnd] = useState(chartData[raceShop] == undefined?moment().subtract(1, 'day'):moment(chartData[raceShop].end_time))
    function rangeChange(date, dateString) {
        if (date === null || date[0] === '') {
            return false
        }
        setStart(date[0])
        setEnd(date[1])
    }

    // 检测是否第一次获取数据
    const [checkFir, setCheckFir] = useState(true)

    useEffect(() => {
        if (checkFir && state.chartData !== undefined) {
            // 如果是第一次加载页面且状态管理中有数据，则从状态管理中获取数据
            let a = Object.keys(state.chartData)
            // 要查询的店铺是否存在于状态管理
            let exit = a.indexOf(raceShop)
            if (exit !== -1) {
                let data = state.chartData[raceShop]
                dealData(data)
                setCheckFir(false)
            } else {
                // 如果新状态管理不存在该店铺数据，转接口请求
                getRaceShopLine()
            }
        } else {
            getRaceShopLine()
        }
    }, [start, end])
    function getRaceShopLine() {
        let data = {}
        if (start === undefined || start === '') {
            message.warn('请选择开始时间')
            return false
        } else if (end === undefined || end === '') {
            message.warn('请选择结束时间')
            return false
        }
        data.start_time = start.format('YYYY-MM-DD')
        data.end_time = end.format('YYYY-MM-DD')
        data.shop = shop
        data.race_shop = raceShop
        data.role_id = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        setIsLoading(true)
        setNowData([])
        setLastData([])
        setAllfiled([])
        setXzhou([])
        setNowTotal(0)
        setLastTotal(0)
        setAddRate(0)
        Axios(
            {
                data: data,
                method: 'POST',
                url: servicePath.getRaceShopLine,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }
        ).then(res => {
            if (res.data.success) {
                let data = res.data.data
                data.start_time = start
                data.end_time = end
                if (state.chartData !== undefined) {
                    let chartData = state.chartData
                    chartData[raceShop] = data
                    dispatch({ type: ADD_CHART_DATA, chartData })
                } else {
                    let chartData = {}
                    chartData[raceShop] = data
                    dispatch({ type: ADD_CHART_DATA, chartData })
                }
                dealData(data)
                setCheckFir(false)
                setIsLoading(false)
                message.success('数据获取成功！')
            } else {
                if(res.data['errorCode'] == '10001'){
                    message.error(res.data['errorMsg'])
                    cookie.remove("role", { path: '/' });
                    cookie.remove("shop", { path: '/' })
                    cookie.remove("shopName", { path: '/' })
                    cookie.remove("name", { path: '/' })
                    cookie.remove("roleName", { path: '/' })
                    cookie.remove("email", { path: '/' })
                    cookie.remove("phone", { path: '/' })
                    cookie.remove("watermark", { path: '/' })
                    cookie.remove("flag", { path: '/' })
                    cookie.remove("token", { path: '/' })
                    props.history.push("/")
                }else{
                    message.error(res.data['errorMsg']);
                    setNowData([])
                    setLastData([])
                    setAllfiled([])
                    setXzhou([])
                    setIsLoading(false)
                }
            }
        }).catch(e=>{
            setIsLoading(false)
            message.warn('该天暂无数据，it同学正在努力补齐数据');
        })
    }

    //处理后台数据的方法
    function dealData(data) {
        if (data === undefined || data === null) {
            return false
        }
        setNowData(data.now_year)
        setLastData(data.last_year)
        setAllfiled(data.filed_list)
        setXzhou(data.time_list)
        if (data.now_year[0]['店铺名称']) {
            setShopName(data.now_year[0]['店铺名称'])
        } else if (data.last_year[0]['店铺名称']) {
            setShopName(data.last_year[0]['店铺名称'])
        }
        let a = getChartData(data.now_year, [`${option}`], [], true).reduce(getSum)
        let b = getChartData(data.last_year, [`去年${option}`], [], true).reduce(getSum)
        setNowTotal(parseFloat(a).toFixed(2))
        setLastTotal(parseFloat(b).toFixed(2))
        let rate = ((parseFloat(a) - parseFloat(b)) * 100 / parseFloat(b)).toFixed(2)
        if (rate === NaN) {
            rate = 0
        } else if (rate === Infinity) {
            rate = 0
        }
        if (rate === 'NaN') {
            rate = 0
        } else if (rate === 'Infinity') {
            rate = '-'
        }
        setAddRate(rate)
    }
    let colors = ['#5793f3', '#d14a61', '#675bba', '#00a8e1', '#e30039 '];
    // 图表数据
    const [option, setOption] = useState('全店访客数')
    let report = {
        color: colors,
        title: {
            text: `${shopName}同比报表`,
            textStyle: {
                fontSize: 24,
            },
            left:'center',
            textAlign:'left',
            subtext: `${option}累计：${nowTotal} \n${option}去年累计：${lastTotal} \n${option}同比增长：${addRate}%`,
            subtextStyle: {
                color: '#333',
                fontSize: 20,
                fontWeight: '600',
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        dataZoom: [{
            type: "inside"         //详细配置可见echarts官网
        }],
        legend: {
            data: [`${option}`, `去年${option}`, `${option}同比增长率`],
            left:'left',
            selected: {
            }
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: xzhou,
                axisLine: {
                    onZero: true,
                    onZeroAxisIndex: 1
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                position: 'left',
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(100,100,100)'
                    },
                    onZero: true
                },
            },
            {
                type: 'value',
                name: '同比增长率',
                position: 'right',
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: colors[1]
                    },
                    onZero: true
                },
            },
        ],
        series: [
            {
                name: `去年${option}`,
                type: 'bar',
                data: getChartData(lastData, [`去年${option}`], [], true),
                itemStyle: {
                    normal: {
                        color: 'rgba(100,100,100,0.9)'
                    }
                },
                emphasis: {
                    itemStyle: {
                        color: 'rgba(100,100,100)',   //hover时改变柱子颜色
                        borderWidth: 2,
                        borderColor: '#2F4654',
                        borderType: 'solid'
                    }
                },
            },
            {
                name: `${option}`,
                type: 'bar',
                data: getChartData(nowData, [`${option}`], [], true),
                itemStyle: {
                    normal: {
                        color: 'rgba(27,146,255,0.9)'
                    }
                },
                emphasis: {
                    itemStyle: {
                        color: 'rgba(27,146,255)',   //hover时改变柱子颜色
                        borderWidth: 2,
                        borderColor: '#2F4654',
                        borderType: 'solid'
                    }
                },
            },
            {
                name: `${option}同比增长率`,
                type: 'line',
                yAxisIndex: 1,
                itemStyle: {
                    normal: {
                        color: 'rgba(201,1,1)'
                    }
                },
                emphasis: {
                    itemStyle: {
                        color: '#2F4654',   //hover时改变柱子颜色
                        borderWidth: 1,
                        borderColor: '#2F4654',
                        borderType: 'solid'
                    }
                },
                data: getChartData(nowData, [`${option}同比增长率`], [], true)
            },
        ]
    };
    // 求和
    function getSum(total, num) {
        let a = parseFloat(total)
        let b = parseFloat(num)
        if (a === NaN) {
            return 0 + 0
        } else {
            return a + b;
        }
    }
    // 客户RMF等级分析饼图
    useEffect(() => {
        let raceShopLine = echarts.init(document.getElementById(`race-shop-line`), 'light');
        raceShopLine.setOption(report);
        // 监听屏幕变化，重绘图表
        window.addEventListener('resize', () => {
            raceShopLine.resize()
        });
    }, [report, nowData, lastData])
    // 选择字段变化时
    function slectFiled(value) {
        setOption(value)
        let list1 = getChartData(nowData, [`${value}`], [], true)
        let list2 = getChartData(lastData, [`去年${value}`], [], true)
        let a = 0
        let b = 0
        a = list1.reduce(getSum)
        b = list2.reduce(getSum)
        setNowTotal(parseFloat(a).toFixed(2))
        setLastTotal(parseFloat(b).toFixed(2))
        let rate = ((parseFloat(a) - parseFloat(b)) * 100 / parseFloat(b)).toFixed(2)
        if (rate === 'NaN') {
            rate = 0
        } else if (rate === 'Infinity') {
            rate = '-'
        }
        setAddRate(rate)
    }
    // 返回总览页面
    function backToRace() {
        props.history.push(`/app/raceshop/${shop}`)
    }
    // 快速选择活动时间
    function quickDate(){
        setStart(moment('2020-10-21'))
        setEnd(moment('2020-11-11'))
    }
    return (
        <div style={{ marginTop: size.height * 0.03, marginLeft: size.width * 0.04}}>
                <Button type='link' style={{fontSize:'1.1rem' }} onClick={backToRace}>返回竞店总览</Button>
            
            <div style={{ display: 'flex', fontSize: '1.1rem', justifyContent: 'flex-start', alignItems: 'center' }}>
                <span >选择查询时间区间：</span>
                <RangePicker
                    locale={locale}
                    disabledDate={disabledDate}
                    onChange={rangeChange}
                    value={[start, end]}
                />
                <span style={{ marginLeft: 20 }}>选择查询字段：</span>
                <Select style={{ width: 200, }} onChange={slectFiled} placeholder={'选择查询字段'} defaultValue={'全店访客数'}>
                    {allFiled.map((item, index) => {
                        return (
                            <Option key={index} value={item}>{item}</Option>
                        )
                    })}
                </Select>
            </div>
            <div style={{ display: 'flex', height: '80vh', justifyContent: 'center', alignItems: 'center' }}>
                <Spin
                    tip='数据请求中，请稍等...'
                    spinning={isLoading}
                >
                    <div id='race-shop-line' style={{ width: '85vw', height: '70vh', }}></div>
                </Spin>
            </div>
        </div>
    )
    // 不可选的周
    function disabledDate(current) {
        // 不可选前一周
        return current && current > moment().endOf('day').subtract(1, 'day');
    }
    // 通过给出的数据和字段得到echarts数据格式，参数有需要处理的字段send_filed，得到字段filed，返回数据是否为数组is_arr
    function getChartData(data, send_filed, filed, is_arr) {
        let total_list = []
        let list = {}
        if (is_arr) {
            if (data === null) {
                return false
            }
            data.map((item, index) => {
                total_list.push(item[send_filed[0]])
            })
        } else {
            if (data === null) {
                return false
            }
            data.map((item, index) => {
                list[filed[0]] = item[send_filed[0]]
                list[filed[1]] = item[send_filed[1]]
                total_list.push(list)
                list = {}
            })
        }
        return total_list
    }
}

export default RaceShopLine