import React, { useContext, useState, useEffect } from 'react';
import MarkBorderUi from './markBorderUi'
import { StateContext } from '../raceShop/store/stateShare'
import { Button, Spin, Tag, Table, Input, Space, message, DatePicker, } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import Axios from 'axios';
import servicePath from '../../config/apiUrl'
import cookie from 'react-cookies'

function MarkBorder(props) {
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    //定义当前页码,默认为1
    const [page, setPage] = useState(1)
    // 类目
    let cateory = props.location.pathname.split('/')[4]
    // 表格数据集
    const [datasource, setDatasource] = useState([])
    // 选择的月份
    const [month, setMonth] = useState(moment().subtract(1, 'month'))
    // 表格加载状态
    const [isLoading, setIsloading] = useState(false)
    //定义分页器参数
    const paginationProps = {
        showSizeChanger: false,
        current: page,
    }
    //定义筛选功能
    const [searchText, setSearchText] = useState('')

    const [searchedColumn, setSearchedColumn] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        查询
                             </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                     </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };

    function sort(a, b, filed) {
        if (b[filed] === undefined || b[filed] === null || b[filed] === '') {
            b = 0
        }
        if (a[filed] === undefined || a[filed] === null || a[filed] === '') {
            a = 0
        }
        setPage(1)
        return b[filed] - a[filed]
    }

    function tagColor(text, record, index) {
        let color = 'blue';
        if (text == null) {
            return text
        }else if (text.indexOf('-') !== -1) {
            color = 'green'
        }
        return (
            <Tag color={color} key={index}>
                {text}
            </Tag>
        )
    }
    // 表格列
    const productColumns = [
        {
            title: '子行业名称',
            dataIndex: '子行业名称',
            key: '子行业名称',
            align: 'center',
            fixed: 'left',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            ...getColumnSearchProps('子行业名称')
            // sorter: (a, b) => {
            //     if (b.全店访客数 === undefined || b.全店访客数 === null || b.全店访客数 === '') {
            //         b = 0
            //     }
            //     if (a.全店访客数 === undefined || a.全店访客数 === null || a.全店访客数 === '') {
            //         a = 0
            //     }
            //     setPage(1)
            //     return b.全店访客数 - a.全店访客数
            // },
        },
        {
            title: '父行业名称',
            dataIndex: '父行业名称',
            key: '父行业名称',
            align: 'center',
            fixed: 'left',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            ...getColumnSearchProps('父行业名称')
        },
        {
            title: '交易金额',
            dataIndex: '交易金额',
            key: '交易金额',
            align: 'center',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a, b) => sort(a, b, '交易金额')
        },
        {
            title: '成交金额同比%',
            dataIndex: '成交金额同比',
            key: '成交金额同比',
            align: 'center',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a, b) => sort(a, b, '成交金额同比'),
            // render:(text, record, index)=>{tagColor(text, record, index)}
        },
        {
            title: '成交金额占比%',
            dataIndex: '成交金额占比',
            key: '成交金额占比',
            align: 'center',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a, b) => sort(a, b, '成交金额占比')
        },
        {
            title: '买家数',
            dataIndex: '买家数',
            key: '买家数',
            align: 'center',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a, b) => sort(a, b, '买家数')
        },
        {
            title: '买家数同比%',
            dataIndex: '买家数同比',
            key: '买家数同比',
            align: 'center',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a, b) => sort(a, b, '买家数')
        },
        {
            title: '搜索人数',
            dataIndex: '搜索人数',
            key: '搜索人数',
            align: 'center',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a, b) => sort(a, b, '搜索人数')
        },
        {
            title: '买家数占比%',
            dataIndex: '买家数占比',
            key: '买家数占比',
            align: 'center',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: (a, b) => sort(a, b, '买家数占比')
        },
    ];

    //定义表头的函数
    function header() {
        return (
            <div >
                <DatePicker
                    picker='month'
                    locale={locale}
                    disabledDate={disabledMonth}
                    value={month}
                    style={{ marginRight: '1rem' }}
                    onChange={monthChange}
                    style = {{margin:`0 10px`}}
                />
                <Button type='primary' style={{marginRight:10}} onClick={download}>导出数据</Button>
                {`${month.format('YYYY-MM')}月统计 共${datasource.length}条数据 `}
            </div>
        )
    }
    // 不可选的周
    function disabledMonth(current) {
        // 不可选前一周
        return current && current > moment().endOf('month').subtract(1, 'month');
    }
    // 月扽变化
    function monthChange(date, dateString) {
        if (date === null) {
            message.warn('请选择时间')
        } else {
            setMonth(date)
        }
    }

    useEffect(() => {
        getMarkData()
    }, [month])


    function getMarkData() {
        let data = {}
        data.month = month.format('YYYY-MM')
        data.cateory = cateory
        setIsloading(true)
        data.shopList = cookie.load("shopList")
        data.roleId = cookie.load("role")
        Axios({
            data: data,
            method: 'POST',
            url: servicePath.getMarkData,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    let data = res.data.data
                    setDatasource(data)
                } else {
                    message.error(res.data.errorMsg);
                    setDatasource([])
                }
                setIsloading(false)
            }
        ).catch(e=>{
            setDatasource([])
            setIsloading(false)
        })
    }
    function download(){
        props.history.push(`/app/peek/markrRank/${cateory}`)
    }
    return (
        <div>
            <MarkBorderUi
                size={state.size}
                columns={productColumns}
                data={datasource}
                locale={locale}
                disabledDate={disabledMonth}
                title={header}
                isLoading={isLoading}
                paginationProps={paginationProps}
                month={month}
                monthChange={monthChange}
                cateory = {cateory}
                download={download}
            />
        </div>

    )
}

export default MarkBorder
