/**
 * 
 * @param {func} func 要执行的函数
 * @param {number} wait 等待毫秒数
 * @param {boolean} immediate 是否立马执行
 */
export default function Debounce(func,wait,immediate){
    let timeout,result,context;

    function debounceFunc (event){
        if(typeof event === "object"){
            // 保存事件！大坑
            event.persist()
        }
        // 获取上下文
        context = this;
        timeout && clearTimeout(timeout)
        if(immediate){
            // 第一次立即执行,此时对timeout取反则为真
            let callNow = !timeout;
            // 对timeout赋值，并在wait事件之后置空
            timeout = setTimeout(()=>{
                timeout = null;
            },wait)
            if(callNow){
                result=func(event);
            }
        }else{
            // 非立即执行，此处需对setTimeout传递事件参数
            timeout = setTimeout(func,wait,event);
        }
        return result;
    }

    debounceFunc.cancel = function(){
        clearTimeout(timeout)
        // 清除变量值，防止内存泄漏
        timeout = null
    }
    return debounceFunc;
}