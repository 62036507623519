import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Collapse, Form, Select, Space, Spin } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import axios from 'axios'
import '../../static/css/Table.css'
import '../../static/css/index.css'
const { Panel } = Collapse
const { confirm } = Modal
const { Option } = Select

// 甲方信息管理
function Party(props) {

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const [form1] = Form.useForm();

    //页面
    const [page, setPage] = useState(1)

    // 总数页数
    const [total, setTotal] = useState(0)

    //数据
    const [data, setData] = useState([])

    // 老数据
    const [oldData, setOldData] = useState([])

    //备份
    const [backups, setBackups] = useState([])

    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])

    //级别
    const [rankList] = useState([
        "10万以下",
        "10～50万",
        "50～200万",
        "200～500万",
        "500～800万",
        "800～1000万",
        "1000万+"

    ])

    //合作形式
    const [shapeList] = useState([
        '年度框架客户',
        '全案客户',
        '媒介客户',
        '其他'
    ])

    //id
    const [id, setId] = useState(-1)

    //行业
    const [business, setBusiness] = useState('')

    //名称
    const [name, setName] = useState('')

    //来源
    const [source, setSource] = useState('')

    //级别
    const [rank, setRank] = useState('')

    //地址
    const [address, setAddress] = useState('')

    //网站
    const [network, setNetwork] = useState('')

    //合作形式
    const [shape, setShape] = useState(-1)

    // 搜索名字
    const [searchName, setSearchName] = useState('')

    // 是否添加甲方
    const [addParty, setAddParty] = useState(false)

    //是否更新甲方
    const [updateParty, setUpdateParty] = useState(false)

    // 是否是检索状态
    const [isSearch, setIsSearch] = useState(0)

    // 是否是加载状态
    const [isLoading, setIsLoading] = useState(false)


    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    //表格属性
    const columns = [
        {
            title: '名称',
            key: 'name',
            dataIndex: 'name',
            width: '200px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '行业',
            key: 'business',
            dataIndex: 'business',
            width: '200px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '来源',
            key: 'source',
            dataIndex: 'source',
            width: '200px',
            align: 'center'
        },
        {
            title: '级别',
            key: 'rank',
            dataIndex: 'rank',
            width: '200px',
            align: 'center',
        },
        {
            title: '地址',
            key: 'address',
            dataIndex: 'address',
            width: '300px',
            align: 'center'
        },
        {
            title: '企业网站',
            key: 'network',
            dataIndex: 'network',
            width: '200px',
            align: 'center'
        },
        {
            title: '合作形式',
            key: 'shape',
            dataIndex: 'shape',
            width: '200px',
            align: 'center',
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            colSpan: jurisdiction.indexOf("kol::updateParty") != -1 ? 1 : 0,
            render: (text, record, index) => (
                <EditOutlined style={{ display: jurisdiction.indexOf("kol::updateParty") != -1 ? "" : "none" }} onClick={() => openUpdate(index)} />
            ),
        },
        // {
        //     title: '禁用/恢复',
        //     key: 'delete',
        //     dataIndex: 'delete',
        //     width: '200px',
        //     align: 'center',
        //     render: (text, record, index) => (
        //         <DeleteOutlined onClick={() => deleteParty(index)}/>
        //     ),
        // }
    ]

    //获取权限代码
    useEffect(() => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    }, [])

    //获取甲方信息数据
    useEffect(() => {
        if (isSearch == 0) {
            getAllParty()
        } else {
            search()
        }
    }, [page])

    const getAllParty = () => {
        setIsLoading(true)
        let dataProps = {
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role'),
            "page": page
        }
        axios({
            method: 'POST',
            url: servicePath.getAllParty,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setData(res.data['data']);
                    setTotal(res.data['total'])
                    setIsLoading(false)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //确认添加
    const addOk = () => {
        if (name == '') {
            message.error('甲方名字不能为空')
        } else if (business == '') {
            message.error('甲方行业不能为空')
        } else if (source == '') {
            message.error('来源不能为空')
        } else if (business == '') {
            message.error('甲方行业不能为空')
        } else if (rank == '') {
            message.error('级别不能为空')
        } else if (shape == '') {
            message.error('合作形式不能为空')
        } else {
            let dataProps = {
                'name': name,
                'business': business,
                'source': source,
                'rank': rank,
                'address': address,
                'network': network,
                'shape': shape,
                'operator': cookie.load('name'),
                "shopId": cookie.load('shopList'),
                'roleId': cookie.load('role')
            }
            axios({
                method: 'POST',
                url: servicePath.addParty,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success("成功添加甲方")
                        form.resetFields();
                        setName('')
                        setBusiness('')
                        setSource('')
                        setRank('')
                        setAddress('')
                        setNetwork('')
                        setShape('')
                        getAllParty()
                        setAddParty(false)
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }

    }

    //取消添加
    const addCancel = () => {
        message.success("取消添加甲方")
        setAddParty(false)
    }

    //打开修改甲方信息
    const openUpdate = (index) => {
        //根据页改变下标
        setOldData(data[index])
        setId(data[index]['id'])
        setBusiness(data[index]['business'])
        setName(data[index]['name'])
        setSource(data[index]['source'])
        setRank(data[index]['rank'])
        setAddress(data[index]['address'])
        setNetwork(data[index]['network'])
        setShape(data[index]['shape'])
        form.resetFields();
        setUpdateParty(true)
    }

    //确认更新
    const updateOk = () => {
        if (name == '') {
            message.error('甲方名字不能为空')
        } else if (business == '') {
            message.error('甲方行业不能为空')
        } else if (source == '') {
            message.error('来源不能为空')
        } else if (business == '') {
            message.error('甲方行业不能为空')
        } else if (rank == '') {
            message.error('级别不能为空')
        } else if (shape == '') {
            message.error('合作形式不能为空')
        } else {
            let dataProps = {
                'oldData': oldData,
                'id': id,
                'name': name,
                'business': business,
                'source': source,
                'rank': rank,
                'address': address,
                'network': network,
                'shape': shape,
                'operator': cookie.load('name'),
                "shopId": cookie.load('shopList'),
                'roleId': cookie.load('role')
            }
            axios({
                method: 'POST',
                url: servicePath.updateParty,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success("成功修改甲方")
                        form.resetFields();
                        setId(-1)
                        setOldData([])
                        setName('')
                        setBusiness('')
                        setSource('')
                        setRank('')
                        setAddress('')
                        setNetwork('')
                        setShape('')
                        getAllParty()
                        setUpdateParty(false)
                    } else {
                        if (res.data['errorCode'] == '401') {
                            message.error(res.data['errorMsg'])
                            getAllParty()
                            setUpdateParty(false)
                        } else if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
    }

    //取消更新
    const updateCancel = () => {
        message.success("取消修改甲方")
        setOldData([])
        setName('')
        setBusiness('')
        setSource('')
        setRank('')
        setAddress('')
        setNetwork('')
        setShape('')
        form.resetFields();
        setUpdateParty(false)
    }

    // 条件查询
    const search = () => {
        // 修改isSearch的值,说明是搜索状态
        setIsSearch(1)
        // 出现加载
        setIsLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            'name': searchName,
            "page": page,
        }
        axios({
            method: 'POST',
            url: servicePath.searchParty,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setData(res.data['data'])
                    setTotal(res.data['total'])
                    setIsLoading(false)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 重置
    const reset = () => {
        // 数据清空
        setSearchName('')
        // 表单清空
        form1.resetFields();
        // 关闭搜索状态
        setIsSearch(0)
        // 将当前页设置为第一页
        setPage(1)
        // 如果当前页面是1的话。手动请求数据
        if (page == 1) {
            getAllParty()
        }
    }

    return (
        <div style={{ paddingBottom: size.height*0.1 }}>
            <Spin spinning={isLoading} size="large" tip="数据请求中....." style={{ marginTop: size.height * 0.3 }}>
                {/* 导航区域 */}
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.02 }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>首页</Breadcrumb.Item>
                        <Breadcrumb.Item>整合营销管理</Breadcrumb.Item>
                        <Breadcrumb.Item>甲方信息管理</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/* 按钮区域 */}
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06 }}>
                    <Row>
                        <Col span={3}>
                            <Button style={{ display: jurisdiction.indexOf("kol::addParty") != -1 ? "" : "none" }} type="primary" onClick={() => setAddParty(true)}>新增甲方信息</Button>
                        </Col>
                    </Row>
                </div>
                <Collapse style={{ marginRight: size.width * 0.06, marginLeft: size.width * 0.06, marginTop: size.height * 0.02 }}>
                    <Panel header="检索条件">
                        <Form
                            form={form1}
                            style={{
                                padding: 24,
                                background: '#fbfbfb',
                            }}
                            labelAlign='left'
                        >
                            <Row gutter={16}>
                                <Col span={5}>
                                    <Form.Item
                                        name="name"
                                        label="甲方名字"
                                    >
                                        <Input
                                            placeholder="请输入甲方名字"
                                            value={searchName}
                                            onChange={(e) => {
                                                setSearchName(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Button type="primary"
                                        onClick={() => {
                                            setPage(1)
                                            setTimeout(search(),2000)
                                        }}
                                        style={{ marginRight: "10px" }}>检索</Button>
                                    <Button type="primary" onClick={() => reset()} danger>重置</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06 }}>
                    {/* 表单区域 */}
                    <Table
                        //表格列的配置描述s
                        columns={columns}
                        //数据数组
                        dataSource={data}
                        pagination={paginationProps}
                        scroll={{ x:'80vw', y: `${size.width>1500?'65vh':'55vh'}` }}
                        //表格行 key 的取值
                        rowKey={'id'}
                    />
                </div>
            </Spin>

            {/* 增加甲方 */}
            <Modal
                title="添加甲方信息"
                visible={addParty}
                centered={true}
                onOk={addOk}
                onCancel={addCancel}
                okText="确认添加"
                cancelText="取消添加"
            >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="客户名称" name="name">
                        {/* 填写客户名称 */}
                        <Tooltip
                            trigger={['focus']}
                            title="填写客户名称"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="填写客户名称"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="客户行业" name="business">
                        {/* 填写客户行业 */}
                        <Tooltip
                            trigger={['focus']}
                            title="填写客户行业"
                            placement="topLeft"
                        >
                            <Input
                                id="business"
                                size="default"
                                placeholder="填写客户行业"
                                onChange={(e) => {
                                    setBusiness(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="客户来源" name="source">
                        {/* 填写菜单名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请填写客户来源"
                            placement="topLeft"
                        >
                            <Input
                                id="source"
                                size="default"
                                placeholder="请填写客户来源"
                                onChange={(e) => {
                                    setSource(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="客户级别" name="rank">
                        {/* 填写菜单名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择客户级别"
                            placement="topLeft"
                        >
                            <Select placeholder="请选择客户级别" style={{ width: 315 }} onChange={(value) => { setRank(value) }}>
                                {
                                    rankList.map(function (item) {
                                        return (
                                            <Option key={item} value={item}>{item}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="客户地址" name="address">
                        {/* 填写客户地址 */}
                        <Tooltip
                            trigger={['focus']}
                            title="填写客户地址"
                            placement="topLeft"
                        >
                            <Input
                                id="address"
                                size="default"
                                placeholder="填写客户地址"
                                onChange={(e) => {
                                    setAddress(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="企业网站" name="network">
                        {/* 填写客户地址 */}
                        <Tooltip
                            trigger={['focus']}
                            title="填写企业网站"
                            placement="topLeft"
                        >
                            <Input
                                id="network"
                                size="default"
                                placeholder="填写企业网站"
                                onChange={(e) => {
                                    setNetwork(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="合作形式" name="shape">
                        <Tooltip
                            trigger={['focus']}
                            title="请选择合作形式"
                            placement="topLeft"
                        >
                            <Select placeholder="请选择合作形式" style={{ width: 315 }} onChange={(value) => { setShape(value) }}>
                                {
                                    shapeList.map(function (item) {
                                        return (
                                            <Option key={item} value={item}>{item}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>


            {/* 更新甲方 */}
            <Modal
                title="更新甲方信息"
                visible={updateParty}
                onOk={updateOk}
                onCancel={updateCancel}
                centered={true}
                okText="确认更新"
                cancelText="取消更新"
            >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="客户名称" name="name">
                        {/* 填写客户名称 */}
                        <Tooltip
                            trigger={['focus']}
                            title="填写客户名称"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                value={name}
                                placeholder="填写客户名称"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="客户行业" name="business">
                        {/* 填写客户行业 */}
                        <Tooltip
                            trigger={['focus']}
                            title="填写客户行业"
                            placement="topLeft"
                        >
                            <Input
                                id="business"
                                size="default"
                                value={business}
                                placeholder="填写客户行业"
                                onChange={(e) => {
                                    setBusiness(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>

                    <Form.Item label="客户来源" name="source">
                        {/* 填写菜单名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请填写客户来源"
                            placement="topLeft"
                        >
                            <Input
                                id="source"
                                size="default"
                                value={source}
                                placeholder="请填写客户来源"
                                onChange={(e) => {
                                    setSource(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="客户级别" name="rank">
                        {/* 填写菜单名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择客户级别"
                            placement="topLeft"
                        >
                            <Select defaultValue={rank} placeholder="请选择客户级别" defaultValue={rank} style={{ width: 315 }}
                                onChange={(value) => { setRank(value) }}>
                                {
                                    rankList.map(function (item) {
                                        return (
                                            <Option key={item} value={item}>{item}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="客户地址" name="address">
                        {/* 填写客户地址 */}
                        <Tooltip
                            trigger={['focus']}
                            title="填写客户地址"
                            placement="topLeft"
                        >
                            <Input
                                id="address"
                                size="default"
                                value={address}
                                placeholder="填写客户地址"
                                onChange={(e) => {
                                    setAddress(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="企业网站" name="network">
                        {/* 填写客户地址 */}
                        <Tooltip
                            trigger={['focus']}
                            title="填写企业网站"
                            placement="topLeft"
                        >
                            <Input
                                id="network"
                                size="default"
                                value={network}
                                placeholder="填写企业网站"
                                onChange={(e) => {
                                    setNetwork(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="合作形式" name="shape">
                        {/* 填写菜单名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择合作形式"
                            placement="topLeft"
                        >
                            <Select defaultValue={shape} placeholder="请选择合作形式" style={{ width: 315 }} onChange={(value) => { setShape(value) }}>
                                {
                                    shapeList.map(function (item) {
                                        return (
                                            <Option key={item} value={item}>{item}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )


}

export default Party