import React, { Component, useEffect, useState } from 'react';
import { Tabs, Typography, Skeleton, Image, Checkbox, Button, Pagination, Divider, Input, Modal, Upload, message, Form, DatePicker, Table, Space, Tag} from 'antd';
import { EditOutlined, DeleteOutlined, InboxOutlined, SearchOutlined } from '@ant-design/icons';
import servicePath from '../../../config/apiUrl';
import NbAxios from '../../../config/utils/nbAxios'
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import axios from 'axios'
import downExcel from '../../../config/utils/downloadFile';
// 引入高亮组件
import Highlighter from "react-highlight-words";
import moment from 'moment'
const { Dragger } = Upload;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Search } = Input
const { Title, Text } = Typography
function Index() {
    function disabledDate(current) {
        // Can not select days before today and today
        return current < moment().startOf('day');
    }
    // 日期格式
    const dateFormat = 'YYYY-MM-DD'
    const [dessertForm] = Form.useForm()
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    }
    //定义筛选功能
    const [searchText, setSearchText] = useState('')

    const [searchedColumn, setSearchedColumn] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };
    // tab关键词
    const [tabKey, setTabKey] = useState('点心库')
    // 详情
    const [detailTabKey, setDetailTabKey] = useState('点心信息')
    // 点心
    const [allDessertList, setAllDesserList] = useState([])
    const [dessertList, setDesserList] = useState([])
    // 点心Id
    const [dessertId, setDessertId] = useState('')
    // 剩余点心
    const [remainingDessertList, setRemainingDesserList] = useState([])
    // 点心当前页数
    const [dessertCurrent, setDessertCurrent] = useState(1)
    // 选择好的点心
    const [chooseDessert, setChooseDessert] = useState([])
    // 点心图片
    const [dessertFile, setDessertFile] = useState([])
    // 点心名字
    const [dessertName, setDessertName] = useState([])
    // 搜索点心名字
    const [searchDessertName, setSearchDessertName] = useState([])
    // 新增点心
    const [addDessert, setAddDessert] = useState(false)
    // 修改点心
    const [updateDessert, setUpdateDessert] = useState(false)
    // 发布点心集合
    const [releaseDessertList, setReleaseDessertList] = useState([])
    // 发布计划
    const [releasePlan, setReleasePlan] = useState(false)
    // 修改计划
    const [updatePlan, setUpdatePlan] = useState(false)
    // 查看详情
    const [lookDetail, setLookDetail] = useState(false)
    // 查询计划日期开始日期
    const [searchPlanStartTime, setSearchPlanStartTime] = useState('')
    // 查询计划日期结束日期
    const [searchPlanEndTime, setSearchPlanEndTime] = useState('')
    // 新增计划点心
    const [addPlanDessert, setAddPlanDessert] = useState(false)
    // 计划Id
    const [planId, setPlanId] = useState('')
    // 计划时间
    const [planData, setPlanData] = useState('')
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 计划表头
    const [planColumns, setPlanColumns] = useState([])
    // 点心表头
    const [dessertColumns] = useState([
        {
            title: '图片',
            dataIndex: 'image',
            key: 'image',
            align: 'center',
            width: 50,
            render: (text, record, index) => (
                text == '' ? <Skeleton.Image style={{ width: 100, height: 100, margin: "0 auto" }} /> : <Image src={`https://pic.bmcsoft.cn/` + text} style={{ width: 100, height: 100 }} />
            )
        },
        {
            title: '名字',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: 150,
            ...getColumnSearchProps('name')
        }
    ])
    // 人员表头
    const [personnelColumns] = useState([
        {
            title: '名字',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            ...getColumnSearchProps('name')
        },
        {
            title: '地区',
            dataIndex: 'place',
            key: 'place',
            align: 'center',
        },
        {
            title: '部门',
            dataIndex: 'department',
            key: 'department',
            align: 'center',
            ...getColumnSearchProps('department')
        },
        {
            title: '是否取走',
            dataIndex: 'is_take',
            key: 'is_take',
            align: 'center',
            render: (text, record, index) => (
                text ?  <Tag color="cyan">已取走</Tag> :  <Tag color="red">未取走</Tag>
            )
        }
    ])
    // 计划数据
    const [planDate, setPlanDate] = useState([])
    // 人员数据
    const [personnelData, setPersonnelData] = useState([])
    //页面
    const [page, setPage] = useState(1)
    // 总数
    const [total, setTotal] = useState(100)
    //多选
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    useEffect(() => {
        // setDesserList
        let dessertList = allDessertList.slice(0, 15)
        setDessertCurrent(1)
        setDesserList(dessertList)
    }, [allDessertList])
    // 初始化参数
    useEffect(() => {
        getAllDessertList()
        getJurisdiction()
    }, [])

    useEffect(()=>{
        setPlanColumns([
            {
                title: '用餐日期',
                dataIndex: 'release_date',
                key: 'release_date',
                align: 'center',
                width: 50,
            },
            {
                title: '人数',
                dataIndex: 'count',
                key: 'count',
                align: 'center',
                width: 100,
            },
            {
                title: '报名截止时间',
                dataIndex: 'end_time',
                key: 'end_time',
                align: 'center',
                width: 100,
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                align: 'center',
                width: 150,
                render: (text, record, index) => (
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <a style={{ textDecoration: 'underline', display:jurisdiction.indexOf("halfMeal::operationData") != -1 ? "" : "none" }} onClick={() => {
                            let values = {}
                            values.roleId = cookie.load('role')
                            values.shopList = cookie.load('shopList')
                            values.planId = record.id
                            NbAxios(values, "POST", servicePath.queryHalfMealPlanDessert).then(res => {
                                if (res.success) {
                                    setReleaseDessertList(res.data);
                                    setPlanId(record.id)
                                    setPlanData(record.release_date)
                                    setUpdatePlan(true)
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }}>修改计划</a>
                        <a style={{ textDecoration: 'underline', display:jurisdiction.indexOf("halfMeal::operationData") != -1 ? "" : "none"  }} onClick={() => {
                            deleteReleasePlan(record.id)
                        }}>取消发布</a>
                        <a style={{ textDecoration: 'underline', display:jurisdiction.indexOf("halfMeal::operationData") != -1 ? "" : "none"  }} onClick={() => {
                            let values = {}
                            values.roleId = cookie.load('role')
                            values.shopList = cookie.load('shopList')
                            values.planId = record.id
                            NbAxios(values, "POST", servicePath.queryHalfMealPlanDessert).then(res => {
                                if (res.success) {
                                    setReleaseDessertList(res.data);
                                    let chooseDessert = []
                                    res.data.map(item=>{
                                        chooseDessert.push(item.id)
                                    })
                                    setChooseDessert(chooseDessert)
                                    setReleasePlan(true)
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }}>再次发布</a>
                        <a style={{ textDecoration: 'underline' }} onClick={() => {
                             let values = {}
                             values.roleId = cookie.load('role')
                             values.shopList = cookie.load('shopList')
                             values.planId = record.id
                             NbAxios(values, "POST", servicePath.queryHalfMealPlanDessert).then(res => {
                                 if (res.success) {
                                     setReleaseDessertList(res.data);
                                     setLookDetail(true)
                                 } else {
                                     message.warn(res.errorMsg)
                                 }
                             })
                             NbAxios(values, "POST", servicePath.queryHalfMealPlanPeople).then(res => {
                                if (res.success) {
                                    setPersonnelData(res.data);
                                    setPlanId(record.id)
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }}>查看详情</a>
                    </div>
                )
            },
        ])
    },[jurisdiction])

    // 获取所有角色/店铺对应的权限
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": ['1fe8c8a8-24d7-4378-96f8-00823a11ea28']
        }
        NbAxios(dataProps, "POST", servicePath.getRoleShopPorts).then(res => {
            if (res.success) {
                console.log(res.data);
                setJurisdiction(res.data)
            }
        })
    }


    useEffect(() => {
        getHalfMealPlan()
    }, [page])

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current:page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 获取计划
    const getHalfMealPlan = () => {
        let values = {}
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        values.startReleaseDate = searchPlanStartTime
        values.endReleaseDate = searchPlanEndTime
        values.page = page
        NbAxios(values, "POST", servicePath.queryHalfMealPlan).then(res => {
            if (res.success) {
                setTotal(res.total);
                setPlanDate(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取所有点心
    const getAllDessertList = () => {
        let values = {}
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.queryHalfMealAllDessert).then(res => {
            if (res.success) {
                setAllDesserList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    //商品列表可整行选择
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRows(selectedRows)

        },
    };

    // 预算点心图片上传
    const dessertFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['png', 'jpg']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setDessertFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setDessertFile(fileList)
        }
    }

    // 移除点心弹框
    const deleteDessertModal = (id) => {
        Modal.warning({
            title: '是否要移除该点心',
            maskClosable: true,
            centered: true,
            okText: '确定',
            onOk() {
                let values = {}
                values.roleId = cookie.load('role')
                values.shopList = cookie.load('shopList')
                values.dessertId = id
                NbAxios(values, "POST", servicePath.deleteHalfMealDessert).then(res => {
                    if (res.success) {
                        message.success('移除点心成功')
                        getAllDessertList()
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            },
        });
    }

    // 移除点心弹框
    const deleteReleaseDessertModal = (item) => {
        Modal.warning({
            title: '是否要移除该点心',
            maskClosable: true,
            centered: true,
            okText: '确定',
            onOk() {
                let obj = [...releaseDessertList]
                obj.remove(item)
                setReleaseDessertList(obj)
            },
        });
    }
    // 取消发布
    const deleteReleasePlan = (id) => {
        Modal.warning({
            title: '是否取消发布该计划',
            maskClosable: true,
            centered: true,
            okText: '确定',
            onOk() {
                let values = {}
                values.roleId = cookie.load('role')
                values.shopList = cookie.load('shopList')
                values.planId = id
                NbAxios(values, "POST", servicePath.deleteHalfMealPlan).then(res => {
                    if (res.success) {
                        message.success("成功取消该发布")
                        getHalfMealPlan()
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            },
        });
    }
    // 点心页面修改函数
    function dessertPageChange(page, pageSize) {
        // js数组筛选
        let dessertList = allDessertList.filter((item, key, arr) => {
            return item.name.includes(searchDessertName)
        })
        dessertList = dessertList.slice((page - 1) * 15, 15 * page)
        setDesserList(dessertList)
        setDessertCurrent(page)
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: 10, borderBottom: "1px solid #ccc" }}>
                <Title level={4}>0.5餐后台管理</Title>
                <div>
                    <a href="https://pic.bmcsoft.cn/imge_dir/010414474101/010414474101.html" target="_Blank"><Button type="link">操作手册</Button></a>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", }}>
                <Tabs type="card" activeKey={tabKey} onChange={(key) => {
                    setTabKey(key)
                }} >
                    <TabPane tab={"点心库"} key='点心库'></TabPane>
                    <TabPane tab={"计划管理"} key='计划管理'></TabPane>
                </Tabs>
                <div></div>
            </div>
            {/* 图片占位符 */}
            {/* <Skeleton.Image /> */}
            <div style={{ display: tabKey == '点心库' ? "" : 'none', height: "calc(80vh - 50px)", width: '90vw', margin: "0 auto", border: '1px solid #ccc', }}>
                <div style={{ marginTop: 5, display: 'flex', justifyContent: 'space-between' }}>
                    <Button type="primary" style={{ marginLeft: 100, visibility: jurisdiction.indexOf("halfMeal::operationData") != -1 ? "" : "hidden" }} onClick={() => {
                        setDessertName('')
                        setDessertFile([])
                        setAddDessert(true)
                    }}>新增</Button>
                    <div style={{ display: 'flex' }}>
                        <Search value={searchDessertName} style={{ width: '30vw' }} placeholder="请输入点心名字进行查询" onChange={(e) => {
                            setSearchDessertName(e.target.value)
                        }} onSearch={() => {
                            // js数组筛选
                            let dessertList = allDessertList.filter((item, key, arr) => {
                                return item.name.includes(searchDessertName)
                            })
                            dessertList = dessertList.slice((dessertCurrent - 1) * 15, 15 * dessertCurrent)
                            setDesserList(dessertList);
                        }} enterButton="搜索" />
                        <Button type="primary" danger onClick={()=>{
                            setSearchDessertName('')
                            // js数组筛选
                            let dessertList = allDessertList.filter((item, key, arr) => {
                                return item.name.includes('')
                            })
                            dessertList = dessertList.slice((dessertCurrent - 1) * 15, 15 * dessertCurrent)
                            setDesserList(dessertList);
                        }}>重置</Button>
                    </div>
                    <Button disabled={chooseDessert.length == 0 ? true : false} type="primary" style={{ marginRight: 50, visibility: jurisdiction.indexOf("halfMeal::operationData") != -1 ? "" : "hidden" }} danger onClick={() => {
                        let releaseDessertList = []
                        allDessertList.map(item => {
                            if (chooseDessert.indexOf(item.id) != -1) {
                                releaseDessertList.push(item)
                            }
                        })
                        setReleaseDessertList(releaseDessertList)
                        setReleasePlan(true)
                    }}>发布</Button>
                </div>
                <div style={{
                    display: "flex", flexWrap: 'wrap', justifyContent: 'flex-start', paddingTop: 20, paddingLeft: 30, paddingBottom: 20, height: "calc(68vh - 50px)",
                    overflow:'auto', backgroundColor:'#fff'
                }}>
                    {
                        dessertList.map(item => {
                            return <div style={{
                                width: 'calc(90vw/6)',
                                boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)',
                                display: 'flex', alignItems: 'center', flexDirection: 'column',
                                justifyContent: 'center', marginLeft: 60, marginTop: 20, cursor: 'pointer',
                                height: 280
                            }} >
                                {
                                    item.image == '' ? <Skeleton.Image style={{ width: '10vw', height: 135, margin: "0 auto" }} /> : <Image src={`https://pic.bmcsoft.cn/` + item.image} style={{ width: '10vw', height: 135 }} />
                                }
                                <div style={{ display: 'flex', marginTop: 20, justifyContent: 'center', width: '100%' }} onClick={() => {
                                    if (chooseDessert.indexOf(item.id) != -1) {
                                        let obj = [...chooseDessert]
                                        obj.remove(item.id)
                                        setChooseDessert(obj)
                                    } else {
                                        let obj = [...chooseDessert]
                                        obj.push(item.id)
                                        setChooseDessert(obj)
                                    }
                                }}>
                                    <Checkbox style={{ marginRight: 10 }} checked={chooseDessert.indexOf(item.id) != -1 ? true : false} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...chooseDessert]
                                            obj.push(item.id)
                                            setChooseDessert(obj)
                                        } else {
                                            let obj = [...chooseDessert]
                                            obj.remove(item.id)
                                            setChooseDessert(obj)
                                        }
                                    }}></Checkbox>
                                    <Text strong style={{ fontSize: 15, marginRight: 20 }}>{item.name}</Text>
                                </div>
                                <Divider style={{}} />
                                <div style={{ width: "80%", display:jurisdiction.indexOf("halfMeal::operationData") != -1 ? "flex" : "none", justifyContent: 'space-between' }}>
                                    <EditOutlined style={{ fontSize: 18 }} onClick={() => {
                                        setDessertId(item.id)
                                        setDessertName(item.name)
                                        setDessertFile([])
                                        setUpdateDessert(true)
                                    }} />
                                    <DeleteOutlined style={{ fontSize: 18 }} onClick={() => {
                                        deleteDessertModal(item.id)
                                    }} />
                                </div>
                            </div>
                        })
                    }
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: 20 }}>
                    <Pagination
                        // 根据搜索框来确定总数
                        total={allDessertList.filter((item, key, arr) => {
                            return item.name.includes(searchDessertName)
                        }).length}
                        current={dessertCurrent} pageSize={15} onChange={(page) => {
                            dessertPageChange(page)
                        }} />
                </div>
            </div>
            <div style={{ display: tabKey == '计划管理' ? "" : 'none', width: '90vw', margin: "0 auto" }}>
                <div style={{ display: "flex" }}>
                    <b style={{ marginRight: 20 }}>查询用餐日期:</b>
                    <RangePicker
                        locale={locale}
                        value={searchPlanStartTime == '' ? ['', ''] : [moment(searchPlanStartTime, dateFormat), moment(searchPlanEndTime, dateFormat)]}
                        format="YYYY-MM-DD"
                        onChange={(value, dateString) => {
                            if (dateString[0] == '') {
                                message.warn('请选择正确的时间')
                            } else {
                                setSearchPlanStartTime(dateString[0])
                                setSearchPlanEndTime(dateString[1])
                            }
                        }}
                    />
                    <Button type="primary" style={{ marginLeft: 15, marginRight: 5 }} onClick={() =>{
                        if(page != 1){
                            setPage(1)
                        }else{
                            getHalfMealPlan()
                        }
                    }}>查询</Button>
                    <Button type="primary" onClick={() => {
                        let values = {}
                        values.roleId = cookie.load('role')
                        values.shopList = cookie.load('shopList')
                        values.startReleaseDate = ''
                        values.endReleaseDate = ''
                        values.page = page
                        NbAxios(values, "POST", servicePath.queryHalfMealPlan).then(res => {
                            if (res.success) {
                                setPage(1)
                                setSearchPlanStartTime('')
                                setSearchPlanEndTime('')
                                setTotal(res.total);
                                setPlanDate(res.data);
                            } else {
                                message.warn(res.errorMsg)
                            }
                        })
                    }} danger>重置</Button>
                </div>
                <Table
                    style={{ marginTop: 10 }}
                    pagination={paginationProps}
                    columns={planColumns}
                    scroll={{ x: 800, y: 'calc(80vh - 190px)' }}
                    dataSource={planDate}
                    bordered
                />
            </div>
            <Modal
                title="新增点心"
                centered={true}
                footer={null}
                visible={addDessert}
                onCancel={() => {
                    setAddDessert(false)
                    setDessertFile([])
                    setDessertName('')
                    dessertForm.resetFields()
                }}
            >
                <Form form={dessertForm}>
                    <Form.Item label="名字" name="name" rules={[{ required: true, message: '请填写点心名字' }]}>
                        <Input value={dessertName} onChange={(e) => {
                            setDessertName(e.target.value)
                        }} />
                    </Form.Item>
                    <Form.Item label="图片">
                        <Dragger
                            accept="image/*"
                            action={servicePath.findTrue}                         //上传地址，填错/不填上传图片的时候都会报错
                            fileList={dessertFile}
                            onChange={dessertFileHandleChange}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                            <p className="ant-upload-hint">
                                仅支持上传一份后缀为.jpg .png文件
                            </p>
                        </Dragger>
                    </Form.Item>
                </Form>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button type='primary' onClick={() => {
                        if (dessertName != '') {
                            setEffective(1)
                            const formData = new FormData()
                            // 多个图片
                            dessertFile.forEach((item) => {
                                formData.append('imageFile', item['originFileObj']);
                            })
                            formData.append('name', dessertName)
                            formData.append('roleId', cookie.load('role'));
                            formData.append('shopList', cookie.load('shopList'));
                            NbAxios(formData, "POST", servicePath.addHalfMealDessert).then(res => {
                                setEffective(0)
                                if (res.success) {
                                    message.success('新增点心成功')
                                    setAddDessert(false)
                                    setDessertFile([])
                                    setDessertName('')
                                    dessertForm.resetFields()
                                    getAllDessertList()
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        } else {
                            message.warn('请输入点心名字')
                        }
                    }}>确定</Button>
                    <Button type='primary' danger onClick={() => {
                        setAddDessert(false)
                        setDessertFile([])
                        setDessertName('')
                        dessertForm.resetFields()
                    }}>取消</Button>
                </div>
            </Modal>
            <Modal
                title="修改点心"
                centered={true}
                footer={null}
                visible={updateDessert}
                onCancel={() => {
                    setUpdateDessert(false)
                    setDessertFile([])
                    setDessertName('')
                    setDessertId('')
                    dessertForm.resetFields()
                }}
            >
                <Form form={dessertForm}>
                    <Form.Item label="名字" name="name" rules={[{ required: true, message: '请填写点心名字' }]}>
                        <div>
                            <Input value={dessertName} onChange={(e) => {
                                setDessertName(e.target.value)
                            }} />
                        </div>
                    </Form.Item>
                    <Form.Item label="图片">
                        <Dragger
                            accept="image/*"
                            action={servicePath.findTrue}                         //上传地址，填错/不填上传图片的时候都会报错
                            fileList={dessertFile}
                            onChange={dessertFileHandleChange}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                            <p className="ant-upload-hint">
                                仅支持上传一份后缀为.jpg .png文件
                            </p>
                        </Dragger>
                    </Form.Item>
                </Form>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button type='primary' disabled={effective == 1 ? true : false} onClick={() => {
                        if (dessertName != '') {
                            setEffective(1)
                            const formData = new FormData()
                            // 多个图片
                            dessertFile.forEach((item) => {
                                formData.append('imageFile', item['originFileObj']);
                            })
                            formData.append('name', dessertName)
                            formData.append('roleId', cookie.load('role'));
                            formData.append('shopList', cookie.load('shopList'));
                            formData.append('dessertId', dessertId)
                            NbAxios(formData, "POST", servicePath.updateHalfMealDessert).then(res => {
                                setEffective(0)
                                if (res.success) {
                                    message.success('修改点心成功')
                                    setUpdateDessert(false)
                                    setDessertFile([])
                                    setDessertName('')
                                    setDessertId('')
                                    dessertForm.resetFields()
                                    getAllDessertList()
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        } else {
                            message.warn('请输入点心名字')
                        }
                    }}>确定</Button>
                    <Button type='primary' disabled={effective == 1 ? true : false} danger onClick={() => {
                        setUpdateDessert(false)
                        setDessertFile([])
                        setDessertName('')
                        setDessertId('')
                        dessertForm.resetFields()
                    }}>取消</Button>
                </div>
            </Modal>
            {/* 发布计划 */}
            <Modal
                title="发布计划"
                visible={releasePlan}
                footer={[]}
                centered={true}
                onCancel={() => {
                    setReleasePlan(false)
                    dessertForm.resetFields()
                    setChooseDessert([])
                }}
                width={1000}
            >
                <Form form={dessertForm} onFinish={(values) => {
                    values.releaseDate = values.releaseDate.format("YYYY-MM-DD")
                    values.snackIdList = chooseDessert
                    values.roleId = cookie.load('role')
                    values.shopList = cookie.load('shopList')
                    NbAxios(values, "POST", servicePath.releaseHalfMealPlan).then(res => {
                        if (res.success) {
                            setReleasePlan(false)
                            dessertForm.resetFields()
                            setChooseDessert([])
                            getHalfMealPlan()
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}>
                    <Form.Item label="用餐日期" name="releaseDate" rules={[{ required: true, message: '请选择用餐日期' }]}>
                        <DatePicker locale={locale} disabledDate={disabledDate} />
                    </Form.Item>
                    <div style={{ width: "100%", display: 'flex', flexWrap: 'wrap' }}>
                        {
                            releaseDessertList.map(item => {
                                return <div style={{
                                    width: 440,
                                    marginRight: 10,
                                    boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)',
                                    display: 'flex', alignItems: 'center', flexDirection: 'column',
                                    justifyContent: 'center', marginTop: 20, cursor: 'pointer',
                                    height: 200
                                }} >
                                    {
                                        item.image == '' ? <Skeleton.Image style={{ width: '10vw', height: 135, margin: "0 auto" }} /> : <Image src={`https://pic.bmcsoft.cn/` + item.image} style={{ width: '10vw', height: 135 }} />
                                    }
                                    <Text strong style={{ fontSize: 15, marginRight: 20, marginTop: 20 }}>{item.name}</Text>
                                </div>
                            })
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 20 }}>
                        <Button type="primary" disabled={effective == 1 ? true : false} htmlType="submit">确定</Button>
                        <Button type="primary" disabled={effective == 1 ? true : false} danger onClick={() => {
                            setReleasePlan(false)
                            dessertForm.resetFields()
                            setChooseDessert([])
                        }}>取消</Button>
                    </div>
                </Form>

            </Modal>
            {/* 修改计划 */}
            <Modal
                title="修改计划"
                visible={updatePlan}
                footer={[]}
                centered={true}
                onCancel={() => {
                    setUpdatePlan(false)
                    setPlanId('')
                    setPlanData('')
                    setReleaseDessertList([])
                    dessertForm.resetFields()
                }}
                width={1000}
            >
                <Form>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex' }}>
                            <b>用餐日期:</b>
                            {/* <Text type="secondary" style={{marginLeft:10}}>{planData}</Text> */}
                            <DatePicker value={moment(planData, dateFormat)} locale={locale} dateFormat={dateFormat} onChange={(value, dateString) => {
                                if (dateString == '') {
                                    message.warn('请选择正确的时间')
                                } else {
                                    setPlanData(dateString)
                                }
                            }} />
                        </div>
                        <Button type="primary" onClick={() => {
                            let remainingDessertList = []
                            allDessertList.map(item => {
                                if (JSON.stringify(releaseDessertList).indexOf(JSON.stringify(item)) == -1) {
                                    remainingDessertList.push(item)
                                }
                            })
                            setRemainingDesserList(remainingDessertList)
                            setAddPlanDessert(true)
                        }}>增加点心</Button>
                    </div>
                    <div style={{ width: "100%", display: 'flex', flexWrap: 'wrap' }}>
                        {
                            releaseDessertList.map(item => {
                                return <div style={{
                                    width: 440,
                                    marginRight: 10,
                                    boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)',
                                    display: 'flex', alignItems: 'center', flexDirection: 'column',
                                    justifyContent: 'center', marginTop: 20, cursor: 'pointer',
                                    height: 200
                                }} >
                                    {
                                        item.image == '' ? <Skeleton.Image style={{ width: '10vw', height: 135, margin: "0 auto" }} /> : <Image src={`https://pic.bmcsoft.cn/` + item.image} style={{ width: '10vw', height: 135 }} />
                                    }
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Text strong style={{ fontSize: 15, marginRight: 20, marginTop: 20 }}>{item.name}</Text>
                                        <DeleteOutlined style={{ fontSize: 15, marginTop: 22 }} onClick={() => {
                                            deleteReleaseDessertModal(item)
                                        }} />
                                    </div>

                                </div>
                            })
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 20 }}>
                        <Button type="primary" disabled={effective == 1 ? true : false} onClick={()=>{
                             if(planData == ''){
                                message.warn('请选择用餐日期')
                            }else{
                                let releaseDessertIdList = []
                                releaseDessertList.map(item=>{
                                    releaseDessertIdList.push(item.id)
                                })
                                let values = {}
                                values.roleId = cookie.load('role')
                                values.shopList = cookie.load('shopList')
                                values.snackIdList = releaseDessertIdList
                                values.planId = planId
                                values.releaseDate = planData
                                NbAxios(values, "POST", servicePath.updateHalfMealPlan).then(res => {
                                    if (res.success) {
                                        message.success('成功修改计划')
                                        setPlanId('')
                                        setPlanData('')
                                        setReleaseDessertList([])
                                        setUpdatePlan(false)
                                        getHalfMealPlan()
                                        dessertForm.resetFields()
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                })
                            }
                        }}>确定</Button>
                        <Button type="primary" disabled={effective == 1 ? true : false} danger onClick={() => {
                            setUpdatePlan(false)
                            setPlanId('')
                            setPlanData('')
                            setReleaseDessertList([])
                            dessertForm.resetFields()
                        }}>取消</Button>
                    </div>
                </Form>

            </Modal>

            {/* 添加点心 */}
            <Modal
                title="添加点心"
                visible={addPlanDessert}
                centered={true}
                onCancel={() => {
                    setAddPlanDessert(false)
                    setRemainingDesserList([])
                    setSelectedRows([])
                    setSelectedRowKeys([])
                }}
                footer={[]}
            >
                <Table
                    style={{ width: "100%" }}
                    rowSelection={rowSelection}
                    pagination={{ pageSize: 5 }}
                    dataSource={remainingDessertList}
                    columns={dessertColumns}
                    rowKey={'id'} />
                <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-around' }}>
                    <Button type="primary" onClick={() => {
                        let obj = [...releaseDessertList]
                        selectedRows.map(item => {
                            obj.push(item)
                        })
                        setReleaseDessertList(obj)
                        setRemainingDesserList([])
                        setSelectedRows([])
                        setSelectedRowKeys([])
                        setAddPlanDessert(false)
                    }}>确定</Button>
                    <Button type="primary" danger onClick={()=>{
                        setAddPlanDessert(false)
                        setRemainingDesserList([])
                        setSelectedRows([])
                        setSelectedRowKeys([])
                    }}>取消</Button>
                </div>
            </Modal>

            {/* 查看详情 */}
            <Modal
                title="查看详情"
                visible={lookDetail}
                footer={[]}
                centered={true}
                onCancel={() => {
                    setLookDetail(false)
                    dessertForm.resetFields()
                }}
                width={1000}
            >
                <div style={{ display: "flex", justifyContent: "flex-start", }}>
                    <Tabs type="card" activeKey={detailTabKey} onChange={(key) => {
                        setDetailTabKey(key)
                    }} >
                        <TabPane tab={"点心信息"} key='点心信息'></TabPane>
                        <TabPane tab={"人员信息"} key='人员信息'></TabPane>
                    </Tabs>
                </div>
                <div style={{ display: detailTabKey == '点心信息' ? "flex" : 'none', width: "100%", flexWrap: 'wrap' }}>
                    {
                        releaseDessertList.map(item => {
                            return <div style={{
                                width: 440,
                                marginRight: 10,
                                boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)',
                                display: 'flex', alignItems: 'center', flexDirection: 'column',
                                justifyContent: 'center', marginTop: 20, cursor: 'pointer',
                                height: 200
                            }} >
                                {
                                    item.image == '' ? <Skeleton.Image style={{ width: '10vw', height: 135, margin: "0 auto" }} /> : <Image src={`https://pic.bmcsoft.cn/` + item.image} style={{ width: '10vw', height: 135 }} />
                                }
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Text strong style={{ fontSize: 15, marginRight: 20, marginTop: 20 }}>{item.name}</Text>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div style={{ display: detailTabKey == '人员信息' ? "flex" : 'none', width: "100%", flexWrap: 'wrap' }}>
                    <Button type="dashed" onClick={()=>{
                          let values = {}
                          values.roleId = cookie.load('role')
                          values.shopList = cookie.load('shopList')
                          values.planId = planId
                          axios({
                            data: values,
                            method: 'POST',
                            url: servicePath.downloadHalfMealPlanPeople,
                            responseType: "blob",
                            headers: {
                                'Content-Type': 'application/json',  //如果写成contentType会报错
                                'authorization': cookie.load('token')
                            }
                        }).then(
                            res => {
                                downExcel(res)
                            }
                        ).catch(e => {
                            message.error({ content: '文件制作出错，请联系it同事！' })
                        })
                    }}>导出表格</Button>
                    <Table style={{ width: '100%' }} columns={personnelColumns} dataSource={personnelData} pagination={{ pageSize: 5 }} />
                </div>
            </Modal>
        </div>
    )

}

export default Index