import React, { useEffect, useState } from 'react';
import { Input, Typography, Checkbox, message, Modal, Button, Spin, Table, Select, DatePicker, Popconfirm, Radio ,Tabs, Popover} from 'antd'
import cookie from 'react-cookies'
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import servicePath from '../../config/apiUrl';
import NbAxios from '../../config/utils/nbAxios';
import webUrl from '../../config/webUrl';
import style from './index.module.css'
const { Title, Text, Paragraph } = Typography;
const { Option } = Select
const { TabPane } = Tabs
const { RangePicker } = DatePicker
const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';
function Difference(props){
    // 类型
    const [calculation_type] = useState(props.history.location.pathname.split('/')[3])
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 标签值
    const [tabKey, setTabKey] = useState('difference')
    // 选择时间1
    const [time1,setTime1] = useState('')
    // 选择时间2
    const [time2,setTime2] = useState('')
    // 表单列表1
    const [formList1, setFormList1] = useState([{id:0,value:'预估表'},{id:1,value:'预算表'}])
    // 选中的表单1
    const [form1,setForm1] = useState('')
    // 选中的表单2
    const [form2,setForm2] = useState('')
    // 表格选择器列表1
    const [tableList1, setTableList1] = useState([])
    // 表格选择器列表2
    const [tableList2, setTableList2] = useState([])
    // 表格1
    const [table1, setTable1] = useState('')
    // 表格2
    const [table2, setTable2] = useState('')
    // 显示数据选择器列表
    const [displayList, setDisplayList] = useState(['全选', '费用', '编制人数', '人数'])
    // 显示数据选择器已选中列表
    const [displayChecked, setDisplayChecked] = useState([])
    // 差异选择器列表
    const [differenceList, setDifferenceList] = useState(['只显示差异'])
    // 差异选择器已选中列表
    const [differenceChecked, setDifferenceChecked] = useState(['只显示差异'])
    // 表头字段
    const [filed] = useState(['数据项', '一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'])
    // 表头
    const [column, setColumn] = useState([])
    // 数据源
    const [dataSource, setDataSource] = useState([])
    // {
    //     "key":1,
    //     "数据项":"11111",
    //     "姓名":"周琦",
    //     "一月费用_x":100,
    //     "一月费用_y":200,
    //     "一月费用差额":100,
    //     "一月费用差额占比":0.5,
    //     "一月编制人数_x":200,
    //     "一月编制人数_y":300,
    //     "一月编制人数差额":100,
    //     "一月编制人数差额占比":0.5,
    //     "一月人数_x":300,
    //     "一月人数_y":400,
    //     "一月人数差额":100,
    //     "一月人数差额占比":0.5,
    //     "二月费用":500,
    //     "二月编制人数":600,
    //     "二月人数":700,
    //     "children":[
    //         {
    //             "key":'1-1',
    //             "数据项": "非标分摊",
    //             "一月费用_x":100,
    //             "一月费用_y":200,
    //             "一月费用差额":100,
    //             "一月费用差额占比":0.5,
    //             "一月编制人数_x":200,
    //             "一月编制人数_y":300,
    //             "一月编制人数差额":100,
    //             "一月编制人数差额占比":0.5,
    //             "一月人数_x":300,
    //             "一月人数_y":400,
    //             "一月人数差额":100,
    //             "一月人数差额占比":0.5,
    //         }
    //     ]
    // }
    // setColumn(filed.reduce((result,item) => {
    //     if(item.indexOf("月") !== -1){
    //         if(displayChecked.indexOf('费用')!==-1 && item.indexOf('费用')!==-1){
    //             result.push({
    //                 title: item,
    //                 dataIndex: item + '_x',
    //                 key: item + '_x',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text!==''? text.toFixed(2):text
    //                 }
    //             },
    //             {
    //                 title: item,
    //                 dataIndex: item + '_y',
    //                 key: item + '_y',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text!==''? text.toFixed(2):text
    //                 }
    //             },
    //             {
    //                 title: item + '差额',
    //                 dataIndex: item + '差额',
    //                 key: item + '差额',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text!==''? text.toFixed(2):text
    //                 }
    //             },
    //             {
    //                 title: item + '差额占比',
    //                 dataIndex: item + '差额占比',
    //                 key: item + '差额占比',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text
    //                 }
    //             },
    //             )
    //         }
    //         if(displayChecked.indexOf('编制人数')!==-1 && item.indexOf('编制人数')!==-1){
    //             result.push({
    //                 title: item,
    //                 dataIndex: item + '_x',
    //                 key: item + '_x',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text!==''? text.toFixed(2):text
    //                 }
    //             },
    //             {
    //                 title: item,
    //                 dataIndex: item + '_y',
    //                 key: item + '_y',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text!==''? text.toFixed(2):text
    //                 }
    //             },
    //             {
    //                 title: item + '差额',
    //                 dataIndex: item + '差额',
    //                 key: item + '差额',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text!==''? text.toFixed(2):text
    //                 }
    //             },
    //             {
    //                 title: item + '差额占比',
    //                 dataIndex: item + '差额占比',
    //                 key: item + '差额占比',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text
    //                 }
    //             },
    //             )
    //         }
    //         if(displayChecked.indexOf('人数')!==-1 && item.indexOf('人数')!==-1){
    //             result.push({
    //                 title: item,
    //                 dataIndex: item + '_x',
    //                 key: item + '_x',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text!==''? text.toFixed(2):text
    //                 }
    //             },
    //             {
    //                 title: item,
    //                 dataIndex: item + '_y',
    //                 key: item + '_y',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text!==''? text.toFixed(2):text
    //                 }
    //             },
    //             {
    //                 title: item + '差额',
    //                 dataIndex: item + '差额',
    //                 key: item + '差额',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text!==''? text.toFixed(2):text
    //                 }
    //             },
    //             {
    //                 title: item + '差额占比',
    //                 dataIndex: item + '差额占比',
    //                 key: item + '差额占比',
    //                 align: "center",
    //                 render: (text, record, index) => {
    //                     return text
    //                 }
    //             },
    //             )
    //         }
    //         return result
    //     }else{
    //         result.push({
    //             title: item,
    //             dataIndex: item,
    //             key: item,
    //             align: "center",
    //             fixed:item == '数据项' ? 'left':'',
    //             width:item == '数据项'? "250px":'',
    //         })
    //         return result
    //     }
    // },[]))
    useEffect(()=>{
        if(displayChecked.length!==0){
            setColumn(filed.reduce((result,item) => {
                if(item.indexOf("月") !== -1){
                    displayChecked.map(obj=>{
                        if(obj =='全选'){
                            return false
                        }
                        result.push({
                            title: item + obj,
                            dataIndex: item + obj + '_x',
                            key: item + obj + '_x',
                            align: "center",
                            render: (text, record, index) => {
                                return text!==''? text.toFixed(2):text
                            }
                        },
                        {
                            title: item + obj,
                            dataIndex: item + obj + '_y',
                            key: item + obj + '_y',
                            align: "center",
                            render: (text, record, index) => {
                                return text!==''? text.toFixed(2):text
                            }
                        },
                        {
                            title: item + obj + '差额',
                            dataIndex: item + obj + '差额',
                            key: item + obj + '差额',
                            align: "center",
                            render: (text, record, index) => {
                                return text!==''? text.toFixed(2):text
                            }
                        },
                        {
                            title: item + obj + '差额占比',
                            dataIndex: item + obj + '差额占比',
                            key: item + obj + '差额占比',
                            align: "center",
                            render: (text, record, index) => {
                                return text
                            }
                        },
                        )
                    })
                    return result
                }else{
                    result.push({
                        title: item,
                        dataIndex: item,
                        key: item,
                        align: "center",
                        fixed:item == '数据项' ? 'left':'',
                        width:item == '数据项'? "250px":'',
                    })
                    return result
                }
            },[]))
        }else{
            setColumn([])
        }
        
    },[displayChecked])
    // 拆分表查询所有矩阵接口
    const getAllSubjectName = (dataProps,setTable) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.getAllSubjectName).then(res => {
            setLoading(false)
            if (res.success) {
                let obj = []
                res.data.data_list.map(item=>{
                   obj.push(item['表名'])
                })
                setTable(obj)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('拆分表查询所有矩阵接口出错,请联系IT')
        })

    }
    // 对照呈现接口
    const compareShow = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.compareShow).then(res => {
            setLoading(false)
            if (res.success) {
                setDataSource(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('对照呈现接口出错,请联系IT')
        })

    }
    return (
        <div className={style.initial}>
            <Spin spinning={loading} tip="数据加载中....">
                <div className={style.externalContent}>
                    <Tabs type="card" size="small" activeKey={tabKey} onChange={(key) => {
                            setTabKey(key)
                        }}>
                        <TabPane tab={<span>差异汇总</span>} key="difference"></TabPane>
                    </Tabs>
                    <div style={{display:tabKey !=='difference'?'none':''}}>
                        <div style={{display:'flex',justifyContent:'center',marginBottom:'20px'}}>
                            <div className={style.diffBorder}>
                                <div style={{textAlign:"right"}}>时间1:</div>
                                <DatePicker locale={locale} picker='year' size='small' className={style.staffInfoSelectWidth} style={{height:'32px'}}
                                    value={time1}
                                    onChange={(value, dateString) => {
                                        setTime1(value)
                                        if(form1!==''){
                                            let dataProps = {
                                                roleId: cookie.load('role'),
                                                shopList: cookie.load('shopList'),
                                                calculation_type: calculation_type,
                                                nb_user_id:cookie.load('id'),
                                                change_member:cookie.load('name'),
                                                mode:form1,
                                                year:value.format('YYYY')
                                            }
                                            getAllSubjectName(dataProps,setTableList1)
                                        }
                                    }} />
                                <div style={{textAlign:"right"}}>表单1:</div>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <Radio.Group value={form1} onChange={e=>{
                                        if(time1){
                                            // let dataProps = {
                                            //     roleId: cookie.load('role'),
                                            //     shopList: cookie.load('shopList'),
                                            //     calculation_type: calculation_type,
                                            //     nb_user_id:cookie.load('id'),
                                            //     change_member:cookie.load('name'),
                                            //     mode:e.target.value,
                                            //     year:time1.format('YYYY')
                                            // }
                                            // getAllSubjectName(dataProps,setTableList1)
                                            setForm1(e.target.value)
                                        }else{
                                            message.warning('请先选择时间')
                                        }
                                    }}>
                                        {formList1.map(item=>{
                                            return <Radio value={item.id}>{item.value}</Radio>
                                        })}
                                    </Radio.Group>
                                    
                                </div>
                                <div style={{textAlign:"right"}}>表格1:</div>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <Select style={{width:'350px'}} value={table1} onChange={value=>{
                                        setTable1(value)
                                    }} onClick={()=>{
                                        if(form1!==''){
                                            let dataProps = {
                                                roleId: cookie.load('role'),
                                                shopList: cookie.load('shopList'),
                                                calculation_type: calculation_type,
                                                nb_user_id:cookie.load('id'),
                                                change_member:cookie.load('name'),
                                                mode:form1,
                                                year:time1.format('YYYY')
                                            }
                                            getAllSubjectName(dataProps,setTableList1)
                                        }else{
                                            message.warning('请先选择表单')
                                        }
                                    }}>
                                        {tableList1.map(item=>(
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className={style.diffBorder}>
                                <div style={{textAlign:"right"}}>时间2:</div>
                                <DatePicker locale={locale} picker='year' size='small' className={style.staffInfoSelectWidth} style={{height:'32px'}}
                                    value={time2}
                                    onChange={(value, dateString) => {
                                        setTime2(value)
                                        if(form2!==''){
                                            let dataProps = {
                                                roleId: cookie.load('role'),
                                                shopList: cookie.load('shopList'),
                                                calculation_type: calculation_type,
                                                nb_user_id:cookie.load('id'),
                                                change_member:cookie.load('name'),
                                                mode:form2,
                                                year:value.format('YYYY')
                                            }
                                            getAllSubjectName(dataProps,setTableList2)
                                        }
                                    }} />
                                <div style={{textAlign:"right"}}>表单2:</div>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <Radio.Group value={form2} onChange={e=>{
                                        if(time2){
                                            // let dataProps = {
                                            //     roleId: cookie.load('role'),
                                            //     shopList: cookie.load('shopList'),
                                            //     calculation_type: calculation_type,
                                            //     nb_user_id:cookie.load('id'),
                                            //     change_member:cookie.load('name'),
                                            //     mode:e.target.value,
                                            //     year:time2.format('YYYY')
                                            // }
                                            // getAllSubjectName(dataProps,setTableList2)
                                            setForm2(e.target.value)
                                        }else{
                                            message.warning('请先选择时间')
                                        }
                                    }}>
                                        {formList1.map(item=>{
                                            return <Radio value={item.id}>{item.value}</Radio>
                                        })}
                                    </Radio.Group>
                                   
                                </div>
                                <div style={{textAlign:"right"}}>表格2:</div>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <Select style={{width:'350px'}} value={table2} onChange={value=>{
                                        setTable2(value)
                                    }} onClick={()=>{
                                        if(form2!==''){
                                            let dataProps = {
                                                roleId: cookie.load('role'),
                                                shopList: cookie.load('shopList'),
                                                calculation_type: calculation_type,
                                                nb_user_id:cookie.load('id'),
                                                change_member:cookie.load('name'),
                                                mode:form2,
                                                year:time2.format('YYYY')
                                            }
                                            getAllSubjectName(dataProps,setTableList2)
                                        }else{
                                            message.warning('请先选择表单')
                                        }
                                    }}>
                                        {tableList2.map(item=>(
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className={style.detailGrid} style={{display:tabKey !=='difference'?'none':'',marginLeft:'30px'}}>
                        <span className={style.gridSpan}>显示数据:</span>
                        <div className={style.gridCheck}>
                            {
                                displayList.map(item => {
                                    return <Checkbox style={{marginLeft:0,marginRight:'8px'}} checked={displayChecked.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                        setDataSource([])
                                        if (e.target.checked) {
                                            // 全部选中
                                            if (item == '全选') {
                                                let obj = []
                                                displayList.map((item) => {
                                                    obj.push(item)
                                                })
                                                setDisplayChecked(obj)
                                            } else {
                                                let obj = [...displayChecked]
                                                obj.push(item)
                                                // 取差集
                                                let temp = displayList.concat(obj).filter(item => !obj.includes(item))
                                                // 如果除了全选之外，所有已选中，则将全选加入
                                                if(temp.length == 1 && temp[0] == '全选'){
                                                    obj.push('全选')
                                                }
                                                setDisplayChecked(obj)
                                            }
                                        } else {
                                            // 全部取消
                                            if (item == '全选') {
                                                setDisplayChecked([])
                                            } else {
                                                let obj = []
                                                obj = [...displayChecked]
                                                obj.remove(item)
                                                // 如果没有全选，则去除全选
                                                if(obj.indexOf('全选') !== -1){
                                                    obj.remove('全选')
                                                }
                                                setDisplayChecked(obj)
                                            }
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                        <span className={style.gridSpan}>显示差异:</span>
                        <div className={style.gridCheck}>
                            {
                                differenceList.map(item => {
                                    return <Checkbox style={{marginLeft:0,marginRight:'8px'}} checked={differenceChecked.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                        setDataSource([])
                                        if (e.target.checked) {
                                            // 全部选中
                                            if (item == '全选') {
                                                let obj = []
                                                differenceList.map((item) => {
                                                    obj.push(item)
                                                })
                                                setDifferenceChecked(obj)
                                            } else {
                                                let obj = [...differenceChecked]
                                                obj.push(item)
                                                // 取差集
                                                let temp = differenceList.concat(obj).filter(item => !obj.includes(item))
                                                // 如果除了全选之外，所有已选中，则将全选加入
                                                if(temp.length == 1 && temp[0] == '全选'){
                                                    obj.push('全选')
                                                }
                                                setDifferenceChecked(obj)
                                            }
                                        } else {
                                            // 全部取消
                                            if (item == '全选') {
                                                setDifferenceChecked([])
                                            } else {
                                                let obj = []
                                                obj = [...differenceChecked]
                                                obj.remove(item)
                                                // 如果没有全选，则去除全选
                                                if(obj.indexOf('全选') !== -1){
                                                    obj.remove('全选')
                                                }
                                                setDifferenceChecked(obj)
                                            }
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                    </div>
                    <div style={{textAlign:'center',display:tabKey !=='difference'?'none':'flex',justifyContent:'center'}}>
                        <Button size='small' type='primary' style={{borderRadius: "3px"}} onClick={()=>{
                            if(table1&&table2){
                                if(displayChecked.length == 0){
                                    message.warning('请选择显示字段')
                                }else{
                                    let dataProps={
                                        roleId: cookie.load('role'),
                                        shopList: cookie.load('shopList'),
                                        calculation_type: calculation_type,
                                        nb_user_id:cookie.load('id'),
                                        change_member:cookie.load('name'),
                                        table_name1:table1,
                                        table_name2:table2,
                                        show_list:displayChecked,
                                        diff_data:differenceChecked.includes('只显示差异')?1:0,
                                    }
                                    compareShow(dataProps)
                                }
                            }else{
                                message.warning('表格未选定')
                            }
                            
                        }}>对比</Button>
                        <Button size='small' type='danger' style={{margin:'0 20px 0 20px',borderRadius: "3px"}} onClick={()=>{
                            setTime1('')
                            setTime2('')
                            setForm1('')
                            setForm2('')
                            setTable1('')
                            setTable2('')
                            setTableList1([])
                            setTableList2([])
                            setDisplayChecked([])
                            setDataSource([])
                        }}>重置</Button>
                    </div>
                </div>
                <div className={style.externalContent} style={{marginTop:'20px',display:tabKey !=='difference'?'none':''}}>
                    <h3>差异汇总表</h3>
                    <Table size="small" columns={column} dataSource={dataSource} rowKey={"key"} 
                        scroll={{ y: 'calc(98vh - 150px)',x:displayChecked.length == 4 ?25000:displayChecked.length == 2?17000:displayChecked.length == 1?8500:''}} pagination={false}
                    />
                </div>
            </Spin>
            
        </div>
    )
}
export default Difference