import React, { useState, useEffect, useCallback } from 'react';
import { DatePicker, Row, Col, Button, Table, Drawer, Tooltip, Radio, Input, Select, Form, message, Modal, Popconfirm, Upload, Image } from 'antd'
import { SearchOutlined, UploadOutlined, DeleteOutlined, CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor'
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import axios from 'axios'
import moment from 'moment'
import Base64 from 'base-64'
import locale from 'antd/es/date-picker/locale/zh_CN';
import '../../static/css/index.css'
import 'braft-editor/dist/index.css'
const { Option } = Select
const { TextArea } = Input;
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'


// 经销供应商管理
function Supplier() {

    // 上传图片按钮
    const uploadButton = (
        <Button>
            <UploadOutlined /> 上传
        </Button>
    );

    const [form] = Form.useForm()

    const [form1] = Form.useForm()

    const layout1 = {
        labelCol: { span: 10 },
        wrapperCol: { span: 18 },
    };
    const layout2 = {
        labelCol: { span: 8 },
        wrapperCol: { span: 18 },
    };

    // 数据
    const [date, setDate] = useState([])

    // 报价数据
    const [ offerDate , setOfferDate ] = useState([])

    //页面
    const [page, setPage] = useState(1)

    // 报价页面
    const [ offerPage , setOfferPage ] = useState(1)

    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])

    // 总数
    const [total, setTotal] = useState(0)

    // 报价总数
    const [ offerTotle, setOffeTotle ] = useState(0)

    // id
    const [id, setId] = useState('')

    // 供应商名称
    const [name, setName] = useState('')

    // 查询供应商名称
    const [searchName, setSearchName] = useState('')

    // 经营品牌
    const [trademark, setTrademerk] = useState('')

    // 查询经营品牌
    const [searchTrademark, setSearchTrademerk] = useState('')

    // 经营类目
    const [category, setCategory] = useState('')

    // 查询经营类目
    const [searchCategory, setSearchCategory] = useState('')

    // 起订量
    const [order, setOrder] = useState('')

    // 是否合作
    const [cooperation, setCooperation] = useState('')

    // 查询是否合作
    const [searchCooperation, setSearchCooperation] = useState('')

    // 是否授权
    const [authorization, setAuthorization] = useState('')

    // 查询是否授权
    const [searchAuthorization, setSearchAuthorization] = useState('')

    // 授权时间
    const [ time , setTime ] = useState('')

    // 联系方式
    const [phone, setPhone] = useState('')

    // 联系人
    const [ lxr , setLxr  ] = useState('')

    // 微信QQ
    const [wxqq, setWxqq] = useState('')

    // 合作产品明细
    const [hzcpmx, setHzcpmx] = useState('')

    // 采购优惠
    const [cgyh, setCgyh] = useState('')

    // 报价文件
    const [fileList, setFileList] = useState([])

    // 供应商文件
    const [supplierFile , setSupplierFile ] = useState([])

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 备注
    const [remark, setRemark] = useState('')

    // 查询
    const [search, setSearch] = useState(false)

    // 查询备注
    const [lookRemark, setLookRemark] = useState(false)

    // 查看详情
    const [detail, setDetail] = useState(false)

    // 打开上传
    const [openUpload, setOpenUpload] = useState(false)

    // 打开上传供应商框
    const [ openUploadSupplier , setOpenUploadSupplier ] = useState(false)

    // 查看报价
    const [ openOffer , setOpenOffer ] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 设置报价页面显示个数
    const offerPageinationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: offerTotle,
        onChange: page => {
            //改变页码
            setOfferPage(page)
        }
    }

    //获取权限代码
    useEffect(() => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    }, [])

    // 表格标题
    const columns = [
        {
            title: '供应商名称',
            key: 'gysmc',
            dataIndex: 'gysmc',
            width: '100px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '经营品牌',
            key: 'jypp',
            dataIndex: 'jypp',
            width: '100px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '经营类目',
            key: 'jylm',
            dataIndex: 'jylm',
            width: '100px',
            align: 'center'
        },
        {
            title: '起订量',
            key: 'qdl',
            dataIndex: 'qdl',
            width: '100px',
            align: 'center'
        },
        {
            title: '是否合作',
            key: 'sfhz',
            dataIndex: 'sfhz',
            width: '100px',
            align: 'center'
        },
        {
            title: '是否授权',
            key: 'sfysq',
            dataIndex: 'sfysq',
            width: '100px',
            align: 'center'
        },
        {
            title: '授权时间',
            key: 'sqsj',
            dataIndex: 'sqsj',
            width: '100px',
            align: 'center'
        },
        {
            title: '报价信息',
            key: 'id',
            dataIndex: 'id',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return <Tooltip placement="top" title="查看报价信息">
                            <SearchOutlined onClick={()=>{
                                setId(text);
                                let dataProps = {
                                    "gys_id": text,
                                    "page": offerPage,
                                    "page_size":10,
                                    "roleId": cookie.load('role'),
                                    "shopId": cookie.load('shopList')
                                }
                                axios({
                                    method: 'POST',
                                    data: dataProps,
                                    url: servicePath.queryFoodDistributionSupplierBjData,
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        if (res.data.success) {
                                           setOffeTotle(res.data.total);
                                           setOfferDate(res.data.data);
                                           setOpenOffer(true)
                                        } else {
                                            message.error(res.data.errorMsg)
                                        }
                                    }
                                )
                            }} />
                        </Tooltip>
            }
        },
        {
            title: '数据更新时间',
            key: 'sjgxsj',
            dataIndex: 'sjgxsj',
            width: '100px',
            align: 'center'
        },
        {
            title: '备注',
            key: 'bz',
            dataIndex: 'bz',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return <Tooltip placement="top" title="查看备注">
                    <SearchOutlined onClick={() => {
                        setId(date[index]['id'])
                        setRemark(date[index]['bz'])
                        setLookRemark(true)
                    }} />
                </Tooltip>
            }
        },
        {
            title:'报价文件',
            key:'id',
            dataIndex:'id',
            width: '50px',
            align: 'center',
            onHeaderCell:function(record, rowIndex){
                return (
                    {
                        style: {
                            display: jurisdiction.indexOf("petFood::uploadOffer") != -1 ? "" : "none"
                        },
                    }
                )
            },
            onCell:function(record, rowIndex){
                return (
                    {
                        style: {
                            display: jurisdiction.indexOf("petFood::uploadOffer") != -1 ? "" : "none"
                        },
                    }
                )
            },
            render: (text, record, index) => {
                return <Row>
                   <Col span={12}>
                        <Tooltip placement="top" title="下载报价文件">
                            <a href={servicePath.downloadFoodDistributionSupplierBjFile+"?gysmc="+date[index]['gysmc']+"&gys_id="+text+"&name="+cookie.load('name')+"&roleId="+cookie.load('role')+"&shopId="+cookie.load('shopList')}><DownloadOutlined /></a>
                        </Tooltip>
                    </Col>
                    <Col span={12}>
                        <Tooltip placement="top" title="上传报价文件">
                            <CloudUploadOutlined onClick={() => {
                                setId(date[index]['id']);
                                setName(date[index]['gysmc'])
                                setOpenUpload(true)
                            }} />
                        </Tooltip>
                    </Col>
                </Row>
            }
        },
        {
            title: '操作',
            key: 'operate',
            dataIndex: 'operate',
            width: '50px',
            align: 'center',
            onHeaderCell:function(record, rowIndex){
                return (
                    {
                        style: {
                            display: jurisdiction.indexOf("petFood::updateSupplier") != -1 || jurisdiction.indexOf("petFood::deleteSupplier") != -1 ? "" : "none"
                        },
                    }
                )
            },
            onCell:function(record, rowIndex){
                return (
                    {
                        style: {
                            display: jurisdiction.indexOf("petFood::updateSupplier") != -1 || jurisdiction.indexOf("petFood::deleteSupplier") != -1 ? "" : "none"
                        },
                    }
                )
            },
            render: (text, record, index) => {
                return <Row>
                    <Col span={12} style={{ display: jurisdiction.indexOf("petFood::updateSupplier") != -1 ? "" : "none"}}>
                        <Tooltip placement="top" title="查看详情" >
                            <SearchOutlined onClick={() => {
                                form1.resetFields()
                                setId(date[index]['id']);
                                setName(date[index]['gysmc']);
                                setTrademerk(date[index]['jypp'])
                                setCategory(date[index]['jylm'])
                                setOrder(date[index]['qdl'])
                                setCooperation(date[index]['sfhz'])
                                setAuthorization(date[index]['sfysq'])
                                setTime(date[index]['sjgxsj'])
                                setPhone(date[index]['lxfs'])
                                setWxqq(date[index]['wxqq'])
                                setHzcpmx(date[index]['hzcpmx'])
                                setCgyh(date[index]['cgyh'])
                                setLxr(date[index]['lxr'])
                                setDetail(true)
                            }} />
                        </Tooltip>
                    </Col>
                    <Col span={12} style={{ display: jurisdiction.indexOf("petFood::deleteSupplier") != -1 ? "" : "none"}}>
                        <Popconfirm
                            title="确认删除?删除后不可恢复!"
                            onConfirm={orderDelete}
                            onCancel={cancelDelete}
                            okText="确定"
                            cancelText="取消"
                        >
                            <DeleteOutlined onClick={()=>setId(date[index]['id'])} />
                        </Popconfirm>
                    </Col>
                </Row>
            }
        }
    ]

    // 报价表格标题
    const offerColumns = [
        {
            title: '品牌',
            key: 'pp',
            dataIndex: 'pp',
            width: '100px',
            align: 'center',
        },
        {
            title: '中文名',
            key: 'spmz',
            dataIndex: 'spmz',
            width: '100px',
            align: 'center',
        },
        {
            title: '编码',
            key: 'spbm',
            dataIndex: 'spbm',
            width: '100px',
            align: 'center',
        },
        {
            title: '规格',
            key: 'gg',
            dataIndex: 'gg',
            width: '100px',
            align: 'center',
        },
        {
            title: '品牌',
            key: 'pp',
            dataIndex: 'pp',
            width: '100px',
            align: 'center',
        },
        {
            title: '批发价',
            key: 'pfj',
            dataIndex: 'pfj',
            width: '100px',
            align: 'center',
        },
        {
            title: '零售价',
            key: 'lsj',
            dataIndex: 'lsj',
            width: '100px',
            align: 'center',
        }
    ]

    // 供应商页面修改的时候触发
    useEffect(() => {
        getData()
    }, [page])

    // 获取数据
    const getData = ()=>{
        let dataProps = {
            "gysmc":searchName,
            "jypp": searchTrademark,
            "jylm": searchCategory,
            "sfhz": searchCooperation,
            "sfysq": searchAuthorization,
            "page": page,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryFoodDistributionSupplierData,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                   setTotal(res.data.total);
                   setDate(res.data.data);
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 关闭查询
    const closeSearch = () => {
        setSearch(false)
    }

    // 确认修改备注
    const okLookRemark = ()=>{
        setEffective(1)
        let dataProps = {
            "id": id,
            "bz": remark,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.updateFoodDistributionSupplierData,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res=>{
                setEffective(0)
                if(res.data.success){
                    message.success("成功修改该供应商备注")
                    getData()
                    setId('')
                    setRemark('')
                    setLookRemark(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 关闭查看备注
    const cancelLookRemark = () => {
        setRemark('')
        setLookRemark(false)
    }

    // 关闭查看详情
    const cancelDetail = () => {
        setName('');
        setTrademerk('')
        setCategory('')
        setOrder('')
        setCooperation('')
        setAuthorization('')
        setTime('')
        setPhone('')
        setWxqq('')
        setHzcpmx('')
        setCgyh('')
        setDetail(false)
    }

    // 确认修改详情
    const okDetail = ()=>{
        setEffective(1)
        let dataProps = {
            "id": id,
            "gysmc": name,
            "jypp": trademark,
            "jylm": category,
            "lxfs": phone,
            "wxqq": wxqq,
            "qdl": order,
            "sfhz": cooperation,
            "hzcpmx": hzcpmx,
            "cgyh": cgyh,
            "sfysq": authorization,
            "sqsj": time,
            'lxr':lxr,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.updateFoodDistributionSupplierData,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res=>{
                setEffective(0)
                if(res.data.success){
                    message.success("成功修改该供应商信息")
                    setId('')
                    setName('');
                    setTrademerk('')
                    setCategory('')
                    setOrder('')
                    setCooperation('')
                    setAuthorization('')
                    setTime('')
                    setPhone('')
                    setWxqq('')
                    setHzcpmx('')
                    setCgyh('')
                    getData()
                    setDetail(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 确定删除
    const orderDelete = () => {
        let dataProps = {
            "id": id,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.deleteFoodDistributionSupplierData,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                   message.success("成功删除供应商信息")
                   getData()
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 取消删除
    const cancelDelete = () => {
        message.success("取消删除供应商信息")
    }

    // 确定上传
    const affirmUpload = () => {
        setEffective(1)
        const formData = new FormData()
        fileList.forEach((item) => {
            formData.append('file', item['originFileObj']);
        })
        formData.append('gysmc', name);
        formData.append('gys_id', id);
        formData.append('roleId', cookie.load('role'));
        formData.append('shopId', cookie.load('shopList'));
        axios({
            method: 'POST',
            url: servicePath.uploadFoodDistributionSupplierBjFile,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    message.success("成功上传供应商报价文件")
                    setFileList([])
                    setName('')
                    setId('')
                    setOpenUpload(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
        
    }

    // 取消上传
    const closeUpload = () => {
        message.success("取消上传供应商报价文件")
        setFileList([])
        setOpenUpload(false)
    }


    // 确定上传供应商文件
    const affirmUploadSupplier = () => {
        setEffective(1)
        // 上传原始文件例子
        const formData = new FormData()
        supplierFile.forEach((item) => {
            formData.append('file', item['originFileObj']);
        })
        formData.append('roleId', cookie.load('role'));
        formData.append('shopId', cookie.load('shopList'));
        axios({
            method: 'POST',
            url: servicePath.uploadFoodDistributionSupplierFile,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    message.success("成功上传供应商文件")
                    setSupplierFile([])
                    getData()
                    setOpenUploadSupplier(false)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
        
    }

    // 取消上传供应商文件
    const closeUploadSupplier = () => {
        message.success("取消上传供应商文件")
        setSupplierFile([])
        setOpenUploadSupplier(false)
    }

    // 查询
    const searchDate = ()=>{
        getData()
        setSearch(false)
    }

    // 重置
    const reset = ()=>{
        form.resetFields()
        setName('')
        setTrademerk('')
        setCategory('')
        setCooperation('')
        setAuthorization('')
        let dataProps = {
            "gysmc":'',
            "jypp": '',
            "jylm": '',
            "sfhz": '',
            "sfysq": '',
            "page": page,
            "page_size": 10,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryFoodDistributionSupplierData,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                   setTotal(res.data.total);
                   setDate(res.data.data);
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
        setSearch(false)
    }
    
    // 报价文件改变的时候触发
    const filesHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx' ]
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                // let flag = fileList[fileList.length-1].originFileObj['size'] / 1024 / 1024 < 50
                // // 在判断文件的大小
                // if (flag){
                //     setDocList(fileList)
                // }else{
                //     message.error('文件大小必须小于50M')
                // }
                setFileList(fileList)
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setFileList(fileList)
        }
    }

    // 供应商文件改变的时候触发
    const supplierFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                // let flag = fileList[fileList.length-1].originFileObj['size'] / 1024 / 1024 < 50
                // // 在判断文件的大小
                // if (flag){
                //     setDocList(fileList)
                // }else{
                //     message.error('文件大小必须小于50M')
                // }
                setSupplierFile(fileList)
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setSupplierFile(fileList)
        }
    }

    return (
        <div style={{ paddingBottom:size.height*0.1 }}>
            {/* 按钮区域 */}
            <div style={{ marginTop: size.height * 0.01 }}>
                <Row>
                    <Col style={{ marginLeft: '80px' }}>
                        <Button type="primary" onClick={()=>setOpenUploadSupplier(true)} style={{display:jurisdiction.indexOf("petFood::uploadFile") != -1 ? "" : "none"}}>上传供应商数据</Button>
                    </Col>
                    <Col style={{ marginLeft: '40px' }}>
                        <Button type="primary" icon={<SearchOutlined />} onClick={() => setSearch(true)}>查询数据</Button>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: size.height * 0.025 }}>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={paginationProps}
                    scroll={{ x: 1000 }}
                    //表格行 key 的取值
                    rowKey={'id'}
                />
            </div>

            {/* 查看备注 */}
            <Modal
                title="查看/修改备注"
                centered={true}
                visible={lookRemark}
                onOk={okLookRemark}
                onCancel={cancelLookRemark}
                okText="保存"
                cancelText="取消"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <TextArea placeholder="请添加备注" allowClear value={remark} onChange={(e)=>setRemark(e.target.value)} />
            </Modal>

            {/* 查询详情 */}
            <Modal
                title="查看/修改详情"
                centered={true}
                visible={detail}
                onOk = {okDetail}
                onCancel={cancelDetail}
                okText="确认修改"
                cancelText="取消"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                width={800}
            >
                <Form form={form1} {...layout1}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="供应商名称"
                                name="name"
                            >
                                <Input
                                    defaultValue={name}
                                    value={name}
                                    placeholder="请输入供应商名称"
                                    onChange={(e) => {
                                        setName(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="经营品牌"
                                name="trademark"
                            >
                                <Input
                                    value={trademark}
                                    defaultValue={trademark}
                                    placeholder="请输入经营品牌"
                                    onChange={(e) => {
                                        setTrademerk(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="经营类目"
                                name="category"
                            >
                                <Input
                                    value={category}
                                    defaultValue={category}
                                    placeholder="请输入经营品牌"
                                    onChange={(e) => {
                                        setCategory(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="起订量"
                                name="order"
                            >
                                <Input
                                    value={order}
                                    defaultValue={order}
                                    placeholder="请输入起订量"
                                    onChange={(e) => {
                                        setOrder(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="是否合作"
                                name="cooperation"
                            >
                                <Radio.Group defaultValue={cooperation} key={cooperation} onChange={(e) => setCooperation(e.target.value)} value={cooperation}>
                                    <Radio value="是">是</Radio>
                                    <Radio value="否">否</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="是否授权"
                                name="authorization"
                            >
                                <Radio.Group defaultValue={authorization}  key={authorization} onChange={(e) => setAuthorization(e.target.value)} value={authorization}>
                                    <Radio value="有">有</Radio>
                                    <Radio value="无">无</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="联系方式"
                                name="phone"
                            >
                                <Input
                                    value={phone}
                                    defaultValue={phone}
                                    placeholder="请输入联系方式"
                                    onChange={(e) => {
                                        setPhone(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="微信/QQ"
                                name="wxqq"
                            >
                                <Input
                                    value={wxqq}
                                    defaultValue={wxqq}
                                    placeholder="请输入微信/QQ"
                                    onChange={(e) => {
                                        setWxqq(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="合作产品明细"
                                name="hzcpmx">
                                <TextArea placeholder="请输入合作产品明细" allowClear defaultValue={hzcpmx} value={hzcpmx} rows={4} onChange={(e) => setHzcpmx(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="采购优惠"
                                name="cgyh">
                                <TextArea placeholder="请输入采购优惠" allowClear value={cgyh} defaultValue={cgyh} rows={4} onChange={(e) => setCgyh(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="联系人"
                                name="lxr">
                                 <Input
                                    value={lxr}
                                    defaultValue={lxr}
                                    placeholder="请输入联系人"
                                    onChange={(e) => {
                                        setLxr(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="数据更新时间"
                                name="time"
                            >
                                {/* <DatePicker defaultValue={moment(time, dateFormat)} locale={locale} disabled /> */}
                                <span>{time}</span>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {/* 上传报价文件 */}
            <Modal
                title="上传报价文件"
                centered={true}
                visible={openUpload}
                maskClosable={false}
                onOk={affirmUpload}
                onCancel={closeUpload}
                okText="确认上传"
                cancelText="取消上传"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Upload
                    action={servicePath.image}                          //上传地址，填错/不填上传图片的时候都会报错
                    listType="picture"                             // 上传列表的内建样式
                    fileList={fileList}                                 //已经上传的文件列表（受控）
                    onChange={filesHandleChange}                             //上传文件改变时的状态
                    accept=".doc,.docx,.xlsx,.xls,.pdf,image/*"                                    //设置文件上传类型
                >
                    {fileList.length >= 1 ? null : uploadButton}
                </Upload>
            </Modal>

             {/* 上传供应商数据 */}
             <Modal
                title="上传供应商数据"
                centered={true}
                visible={openUploadSupplier}
                maskClosable={false}
                onOk={affirmUploadSupplier}
                onCancel={closeUploadSupplier}
                okText="确认上传"
                cancelText="取消上传"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Upload
                    action={servicePath.image}                          //上传地址，填错/不填上传图片的时候都会报错
                    listType="picture"                             // 上传列表的内建样式
                    fileList={supplierFile}                                 //已经上传的文件列表（受控）
                    onChange={supplierFileHandleChange}                             //上传文件改变时的状态
                    accept=".xlsx"                                    //设置文件上传类型
                >
                    {supplierFile.length >= 1 ? null : uploadButton}
                </Upload>
            </Modal>

            {/* 供应商报价 */}
            <Modal
                title={name}报价
                centered={true}
                footer={null}
                visible={openOffer}
                onCancel={()=>{setId('');setOfferPage(1);setOpenOffer(false);}}
                width={1400}
            >
                 {/* 表单区域 */}
                 <Table
                    //表格列的配置描述
                    columns={offerColumns}
                    //数据数组
                    dataSource={offerDate}
                    pagination={offerPageinationProps}
                    scroll={{ y: size.height * 0.6, x: 1000 }}
                    //表格行 key 的取值
                    rowKey={'id'}
                />
            </Modal>

            {/*  条件查询 */}
            <Drawer
                title="条件查询"
                closable={false}
                onClose={closeSearch}
                visible={search}
                width={600}
            >
                <Form form={form} {...layout1} labelAlign='left'>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="供应商名称"
                                name="searchName"
                            >
                                <Input
                                    value={searchName}
                                    placeholder="请输入供应商名称"
                                    onChange={(e) => {
                                        setSearchName(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="经营品牌"
                                name="searchTrademark"
                            >
                                <Input
                                    value={searchTrademark}
                                    placeholder="请输入经营品牌"
                                    onChange={(e) => {
                                        setSearchTrademerk(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="经营类目"
                                name="searchCategory"
                            >
                                <Input
                                    value={searchCategory}
                                    placeholder="请输入经营品牌"
                                    onChange={(e) => {
                                        setSearchCategory(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="是否合作"
                                name="searchCooperation"
                            >
                                <Radio.Group key={searchCooperation} onChange={(e) => setSearchCooperation(e.target.value)} value={cooperation}>
                                    <Radio value="是">是</Radio>
                                    <Radio value="否">否</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="是否授权"
                                name="searchAuthorization"
                            >
                                <Radio.Group key={searchAuthorization} onChange={(e) => setSearchAuthorization(e.target.value)} value={authorization}>
                                    <Radio value="有">有</Radio>
                                    <Radio value="无">无</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                    </Row>
                    <Button type="primary" style={{ marginLeft: '30px' }} onClick={()=>searchDate()}>查询</Button>
                    <Button type="primary" style={{ marginLeft: '20px' }} danger onClick={()=>reset()}>重置</Button> 
                </Form>
            </Drawer>
        </div>
    )

}

export default Supplier