import React, { useEffect } from 'react';
import style from './index.module.css';
import { Typography } from 'antd'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import { ReactComponent as Down } from '../svg/down.svg'
import { ReactComponent as Up } from '../svg/up.svg'
const { Title, Paragraph, Text, Link } = Typography;


function ChartCard({
    // 新增会员人数
    newMemberNum,
    // 新增会员人数同比
    newMemberNumYear,
    // 新增会员人数环比
    newMemberNumChain,
    // 会员总数
    allMemberNum,
    // 会员总数同比
    allMemberNumYear,
    // 会员总数环比
    allMemberNumChain,
    // 退会人数
    withdrawMemberNum,
    // 退会人数同比
    withdrawMemberNumYear,
     // 退会人数环比
    withdrawMemberNumChain,
    // 图表数据
    chartData,
    // 宽度
    chartWidth,
    // 长度
    chartHeight
}) {


    let chart = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c}人'
        },
        // legend: {
        //     orient: 'vertical',
        //     left: 'left',
        //     data: ['新增会员人数', '退会人数']
        // },
        series: [
            {
                type: 'funnel',
                width: '40%',
                height: '45%',
                // left: '5%',
                top: '50%',
                data: [
                    chartData[0],
                ]
            },
            {
                type: 'funnel',
                width: '40%',
                height: '45%',
                top: '5%',
                sort: 'ascending',
                data: [
                    chartData[1],
                ]
            }
        ]
    }

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('chartData'));
        // 绘制图表
        reportChart.setOption(chart);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [chartData, chartWidth, chartHeight])

    return (
        <div className={style.targetCardWapper}>
            <div className={style.leftArea}>
                <div id='chartData' style={{ width: `calc(${chartWidth})`, height: `calc(${chartHeight})`}}></div>
            </div>
            <div style={{fontSize:'0.1vw', display:newMemberNum == '' ? 'none' : ''}}>
                <b>{"新增会员人数"}:</b>
                <Text>{newMemberNum}</Text>
                <div style={{display:'flex'}}>
                    <div style={{marginRight:10}}>
                        <Text style={{ display: "flex", alignItems: "center" }}>
                            同比{newMemberNumYear}
                            {newMemberNumYear > 0 && <Up width={12} height={12} style={{marginRight:15}}/>}
                            {newMemberNumYear < 0 && <Down width={12} height={12} style={{marginRight:15}}/>}
                        </Text>
                    </div>
                    <div>
                        环比{newMemberNumChain}
                        {newMemberNumChain > 0 && <Up width={12} height={12} />}
                        {newMemberNumChain < 0 && <Down width={12} height={12} />}
                    </div>
                </div>
                <div style={{marginTop:30}}>
                    <b>{"会员总数"}:</b>
                    <Text>{allMemberNum}</Text>
                    <div style={{display:'flex'}}>
                        <div style={{marginRight:10}}>
                            <Text style={{ display: "flex", alignItems: "center" }}>
                                同比{allMemberNumYear}
                                {allMemberNumYear > 0 && <Up width={12} height={12} style={{marginRight:15}}/>}
                                {allMemberNumYear < 0 && <Down width={12} height={12} style={{marginRight:15}}/>}
                            </Text>
                        </div>
                        <div>
                            环比{allMemberNumChain}
                            {allMemberNumChain > 0 && <Up width={12} height={12} />}
                            {allMemberNumChain < 0 && <Down width={12} height={12} />}
                        </div>
                    </div>
                </div>
                <div style={{marginTop:30}}>
                    <b >{"退会人数"}:</b>
                    <Text>{withdrawMemberNum}</Text>
                    <div style={{display:'flex'}}>
                        <div style={{marginRight:10}}>
                            <Text style={{ display: "flex", alignItems: "center" }}>
                                同比{withdrawMemberNumYear}
                                {withdrawMemberNumYear > 0 && <Up width={12} height={12} style={{marginRight:15}}/>}
                                {withdrawMemberNumYear < 0 && <Down width={12} height={12} style={{marginRight:15}}/>}
                            </Text>
                        </div>
                        <div>
                            环比{withdrawMemberNumChain}
                            {withdrawMemberNumChain > 0 && <Up width={12} height={12} />}
                            {withdrawMemberNumChain < 0 && <Down width={12} height={12} />}
                        </div>
                    </div>
               </div>
            </div>
        </div>
    )

}

export default ChartCard