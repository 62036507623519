import React, { Component } from 'react';
import style from './index.module.css';
import { Progress, Typography } from 'antd';
import { ReactComponent as Down } from '../svg/down.svg'
import { ReactComponent as Up } from '../svg/up.svg'
const { Title, Paragraph, Text, Link } = Typography;

function DataCard({
    // 总指标
    overallIndicator,
    // 总指标数据
    overallIndicatorDate,
    // 总指标同比
    overallIndicatorYear,
    // 总指标环比
    overallIndicatorChain,
    // 会员指标
    memberIndicator,
    // 会员指标数据
    memberIndicatorDate,
    // 会员指标同比
    memberIndicatorYear,
    // 会员指标环比
    memberIndicatorChain,
    // 占比
    proportion
}) {
    return (
        <div className={style.targetCardWapper} style={{display:proportion == '' ? 'none' : ''}}>
            <div className={style.leftArea}>
                <div className={style.dashboard}>
                    <Progress
                        className={style.progress}
                        type="circle"
                        percent={proportion}
                        // format={targetFormat}
                        strokeColor='rgb(24,144,255)'
                        width={'5vw'} 
                    />
                </div>
            </div>
            <div style={{fontSize:'0.1vw'}}>
                <div>
                    <div style={{display:'flex', flexWrap:'wrap'}}>
                        <div>
                            <b>{overallIndicator}:</b>
                        </div>
                        <div>
                            <Text>{overallIndicatorDate}</Text>
                        </div>
                    </div>
                    <div style={{display:'flex', flexWrap:'wrap'}}>
                        <div style={{width:90}}>
                            <Text style={{ display: "flex", alignItems: "center" }}>
                                环比<span style={{marginLeft:3}}>{overallIndicatorChain}</span>
                                {parseFloat(overallIndicatorChain) > 0 && <Up width={12} height={12} />}
                                {parseFloat(overallIndicatorChain) < 0 && <Down width={12} height={12} />}
                            </Text>
                        </div>
                        <div>
                            <Text style={{ display: "flex", alignItems: "center" }}>
                                同比<span style={{marginLeft:3}}>{overallIndicatorYear}</span>
                                {parseFloat(overallIndicatorYear) > 0 && <Up width={12} height={12} style={{marginRight:10}}/>}
                                {parseFloat(overallIndicatorYear) < 0 && <Down width={12} height={12} style={{marginRight:10}}/>}
                            </Text>
                        </div>
                    </div>
                </div>
                <div style={{marginTop:10, fontSize:'0.1vw'}}>
                    <div style={{display:'flex', flexWrap:'wrap'}}>
                        <div>
                            <b>{memberIndicator}:</b>
                        </div>
                        <div>
                            <Text>{memberIndicatorDate}</Text>
                        </div>
                    </div>
                    <div style={{display:'flex', flexWrap:'wrap'}}>
                        <div style={{width:90}}>
                            <Text style={{ display: "flex", alignItems: "center" }}>
                                环比<span style={{marginLeft:3}}>{memberIndicatorChain}</span>
                                {parseFloat(memberIndicatorChain) > 0 && <Up width={12} height={12} />}
                                {parseFloat(memberIndicatorChain) < 0 && <Down width={12} height={12} />}
                            </Text>
                        </div>
                        <div>
                            <Text style={{ display: "flex", alignItems: "center" }}>
                                同比<span style={{marginLeft:3}}>{memberIndicatorYear}</span>
                                {parseFloat(memberIndicatorYear) > 0 && <Up width={12} height={12} style={{marginRight:10}}/>}
                                {parseFloat(memberIndicatorYear) < 0 && <Down width={12} height={12} style={{marginRight:10}}/>}
                            </Text>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataCard