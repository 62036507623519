import styled from "styled-components";

export const DailyWrapper = styled.div`
    background-color: #f5f5f5;
    height: calc(100vh - 40px);
    overflow: auto;
`

export const ExternalContent = styled.div`
    width: 1440px;
    margin: 0 auto ;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-top: 20px;
    /* height: 100%; */
    /* overflow:hidden; */
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.4);
`

export const TopContent = styled.div`
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    border-bottom: solid 2px #ccc;
    width: 100%;
    margin: 5px auto 0;

    .TitleContent{

    }

    .buttonContent{
        display: flex;
    }

    .buttonLeftContent{
        margin-right: 10px;
    }
`

export const TabsContent = styled.div`
`

export const CenterButton = styled.div`
    display: flex;
    justify-content: space-between;
`