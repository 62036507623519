import React, { useEffect, useState } from 'react';
import style from './index.module.css';
import AcceptBuried from '../../config/utils/buried';
import PopularItems from './components/popularItems'

function Index(props){
    // 选择模块
    const [ chooseModule, setChooseModule] = useState('爆款单品诊断')
    
    useEffect(()=>{
        AcceptBuried('page_views',"view",{"page_name":chooseModule,"page_type":"初始化页面"},props.location.pathname,"女装/女装爆款打造")
    },[chooseModule])

    return (
        <div className={style.overallContent}>
            {/* 左侧 */}
            <div className={style.leftContent} style={{height:'100vh', backgroundColor:'white'}}>
                <div style={{ backgroundColor: chooseModule ==  '爆款单品诊断' ? '#eee': 'white' , paddingLeft:'10px', fontWeight:chooseModule ==  '爆款单品诊断' ? 'bold' : 400}} onClick={()=>{setChooseModule('爆款单品诊断')}}>爆款单品诊断</div>
                <div style={{ backgroundColor: chooseModule ==  '爆款梯队' ? '#eee': 'white', paddingLeft:'10px', fontWeight:chooseModule ==  '爆款梯队' ? 'bold' : 400}} onClick={()=>{setChooseModule('爆款梯队')}}>爆款梯队</div>
                <div style={{ backgroundColor: chooseModule ==  '投放预算分配' ? '#eee': 'white', paddingLeft:'10px', fontWeight:chooseModule ==  '投放预算分配' ? 'bold' : 400}} onClick={()=>{setChooseModule('投放预算分配')}}>投放预算分配</div>
                <div style={{ backgroundColor: chooseModule ==  '爆款日常运营' ? '#eee': 'white', paddingLeft:'10px', fontWeight:chooseModule ==  '爆款日常运营' ? 'bold' : 400}} onClick={()=>{setChooseModule('爆款日常运营')}}>爆款日常运营</div>
                <div style={{ backgroundColor: chooseModule ==  '爆款经营知识体系' ? '#eee': 'white', paddingLeft:'10px', fontWeight:chooseModule ==  '爆款经营知识体系' ? 'bold' : 400}} onClick={()=>{setChooseModule('爆款经营知识体系')}}>爆款经营知识体系</div>
            </div>
            <div>
                <div style={{display: chooseModule ==  '爆款单品诊断' ? '' : 'none', marginLeft:10, marginTop:10, marginRight:10 }}>
                    <PopularItems pathName={props.location.pathname} />
                </div>
            </div>
        </div>
    )

}

export default Index