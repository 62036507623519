import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Spin, Tabs, Typography, Pagination, Drawer, Form, Tag, List, Avatar, Button, Modal, Upload, message, Input, Descriptions, PageHeader, Checkbox, Divider } from 'antd';
import { LoadingOutlined, createFromIconfontCN, UploadOutlined, TeamOutlined, LeftOutlined } from '@ant-design/icons';
import './style.css'
import moment from 'moment'
import servicePath from '../../../config/apiUrl'
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios'
import { StateContext } from '../../raceShop/store/stateShare'
const { TabPane } = Tabs;
const { TextArea, Search } = Input;
const { Text, Paragraph, Title } = Typography
const { CheckableTag } = Tag;
function OrderCursor(props) {

    // 十种颜色随机
    const color = [
        // 蓝色
        '#495aff',
        // 灰色
        '#323232',
        // 靛青色
        '#2b5876',
        // 淡紫色
        '#4e4376',
        // 亮粉色
        '#ff758c',
        // 淡粉色
        '#ff7eb3',
        // 紫色
        '#b721ff',
        // 哑光蓝
        'rgb(68, 129, 235)',
        // 亮蓝色
        'rgb(4, 190, 254)',
        '#9890e3'
    ]

    const [selectColor, setColor] = useState(color[0])

    // 上传文件按钮
    const uploadButton = (
        <Button>
            <UploadOutlined /> 文件
        </Button>
    );

    const [form1] = Form.useForm()
    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 20 },
    }
    const layout2 = {
        labelCol: { span: 3 },
        wrapperCol: { span: 18 },
    }

    // 时间条件查询
    const tagsTimeData = ['全部时长', '1小时以下', '1-2小时', '2-3小时'];

    // 选择tag
    const [selectedTimeTags, setSelectedTimeTags] = useState('全部时长')

    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)

    const [allCursorList, setAllCursorList] = useState([])

    const [cursorList, setCursorList] = useState([])

    // 全部课程
    const [allClassList, setAllClassList] = useState([])

    // 待开课课程
    const [toStartClassList, setToStartClassList] = useState([])
    // 预约中课程
    const [ bookingClassList , setBookingClassList ] = useState([])
    // 预约成功课程
    const [ bookingSuccessClassList , setBookingSuccessClassList] = useState([])
    // 预约失败
    const [ bookingFailClassList , setBookingFailClassList ] = useState([])
    // 已结课课程
    const [endClassList, setEndClassList] = useState([])

    // 未能开课课程
    const [failedToStartClassList, setFailedToStartClassList] = useState([])

    const antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;
    // 创建一个icon对象
    const IconFont = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_2312382_kq2jcqxr74s.js',
    });

    const BtnIconFont = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_2314025_he5fueddz3j.js',
    });
    // 评论列表
    const [commentList, setCommentList] = useState({})
    // 数据总数
    const [total, setTotal] = useState(100000)
    // 文档列表
    const [docList, setDocList] = useState([])
    // 当前页码
    const [page, setPage] = useState(1)
    // 标签
    const [tabKey, setTabKey] = useState(0)
    // 课程id
    const [cursorId, setCursorId] = useState('')
    // 课程名字
    const [cursorName, setCursorName] = useState('')
    // 课程详情
    const [cursorDetail, setCursorDetail] = useState('')
    // 课程简介
    const [ cursorIntroduction, setCursorIntroduction ] = useState('')
    // 课程评论
    const [cursorComment, setCursorComment] = useState('')
    // 课程类型
    const [cursorKind, setCursorKind] = useState(0)
    // 领导者
    const [leaderList, setLeaderList] = useState([])
    // 跟随者
    const [followerList, setFollowerList] = useState([])
    // 课件
    const [courseware, setCourseware] = useState([])
    // 课件key集合
    const [coursewareKeyList, setCoursewareKeyList] = useState([])
    // 评论key集合
    const [commentKeyList, setCommentKeyList] = useState([])
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 是否加载状态
    const [allLoading, setAllLoading] = useState(false)
    // 是否加载状态
    const [bookingLoading, setBookingLoading] = useState(false)
    // 是否加载状态
    const [bookingSuccessLoading, setBookingSuccessLoading] = useState(false)
    // 是否加载状态
    const [bookingFailureLoading, setBookingFailureLoading] = useState(false)
    // 是否加载状态
    const [closedLoading, setClosedLoading] = useState(false)
    // 是否加载
    const [commentLoading, setCommentLoading] = useState(false)
    // 是否加载
    const [materialLoading, setMaterialLoading] = useState(false)
    // 是否打开详情
    const [openDetail, setOpenDetail] = useState(false)
    // 是否添加评论
    const [addComment, setAddComment] = useState(false)
    // 打开选择课程分类
    const [openCourseType , setOpenCourseType ]  = useState(false)
    // 课程所有分类
    const [courseType , setCourseType ]  = useState([])
    // 多选框配置
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    // 用户选择的课程分类
    const [ chooseCourseTypes , setChooseCourseTypes ] = useState([])
    // 全部课程修改的时候
    useEffect(() => {
        let cursorList = allCursorList.slice(0, 10)
        // 1.默认取前10条数据
        setCursorList(cursorList)
        // 2.重新统计个数
        setTotal(allCursorList.length)
        // 3.把当前的页数设置为1
        setPage(1)
    }, [allCursorList])

    // 初始化参数
    useEffect(() => {
        getNsCoursesPublishList()
        getOwnTypeList()
    }, [])

    useEffect(()=>{
        if(courseType.length != 0){
            setOpenCourseType(true)
        }
    },[courseType])

    // 课程标签切换
    function tabChange(key) {
        setPage(1)
        // 修改全部课程的数据 (即把对应状态的课程数据赋值给全部课程)
        switch (parseInt(key)) {
            case 0:
                setAllCursorList(allClassList)
                break;
            case 1:
                setAllCursorList(bookingSuccessClassList)
                break;
            case 2:
                setAllCursorList(bookingFailClassList)
                break;
            case 3:
                setAllCursorList(bookingClassList)
                break;
            case 4:
                setAllCursorList(failedToStartClassList)
                break;
            case 5:
                setAllCursorList(endClassList)
                break;
        }
        setTabKey(key)
    }

    // 评论课件标签切换
    function detailTabChange(key) {

    }

    // 文档列表改变的时候出发
    const fileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'zip', 'rar', 'gif', 'jpg', 'jpeg', 'png', 'jpg', 'pptx', 'txt']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    setDocList(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setDocList(fileList)
        }
    }

    // 全部
    const allTab = (
        <div>
            <Spin indicator={antIcon} spinning={allLoading}>
                全部
            </Spin>
        </div>
    )
    
    // 预约中
    const bookingTab = (
        <div>
            <Spin indicator={antIcon} spinning={bookingSuccessLoading}>
                预约中
            </Spin>
        </div>
    )

    // 预约成功，待开课标签
    const bookingSuccessTab = (
        <div>
            <Spin indicator={antIcon} spinning={bookingSuccessLoading}>
                待开课
            </Spin>
        </div>
    )
    // 未能开课，预约失败标签
    const bookingFailureTab = (
        <div>
            <Spin indicator={antIcon} spinning={bookingFailureLoading}>
                预约失败
            </Spin>
        </div>
    )
    // 已结业标签
    const closedTab = (
        <div>
            <Spin indicator={antIcon} spinning={closedLoading}>
                已结课
            </Spin>
        </div>
    )

    // 未能开课
    const failedTab = (
        <div>
            <Spin indicator={antIcon} spinning={closedLoading}>
                未能开课
            </Spin>
        </div>
    )

    // 评论标签
    const commentTab = (
        <div>
            <Spin indicator={antIcon} spinning={commentLoading}>
                评论
            </Spin>
        </div>
    )

    // 课件标签
    const materialTab = (
        <div>
            <Spin indicator={antIcon} spinning={materialLoading}>
                课件
            </Spin>
        </div>
    )
    const Header = (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10 }}>
            <Tabs type="card" style={{ height: 40 }} onChange={tabChange}>
                <TabPane tab={allTab} key={0}></TabPane>
                <TabPane tab={bookingTab} key={3}></TabPane>
                <TabPane tab={bookingSuccessTab} key={1}></TabPane>
                <TabPane tab={closedTab} key={5}></TabPane>
                <TabPane tab={bookingFailureTab} key={2}></TabPane>
                <TabPane tab={failedTab} key={4}></TabPane>
            </Tabs>
        </div>
    )
    function pageChange(page, pageSize) {
        let cursorList = allCursorList.slice((page - 1) * 10, 10 * page)
        setCursorList(cursorList)
        setPage(page)
    }

    // 查询已发布的课程
    const getNsCoursesPublishList = (key) => {
        let dataProps = {
            "key_word": key == undefined ? '' : key,
            "principal_uuid": cookie.load('id'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        NbAxios(dataProps, "POST", servicePath.queryNsCoursesParticipantList).then(res => {
            if (res.success) {
                // 预约中
                setBookingClassList(res.data[3])
                // 预约成功
                setBookingSuccessClassList(res.data[1])
                // 预约失败
                setBookingFailClassList(res.data[2])
                // 已结课
                setEndClassList(res.data[5])
                // 未能开课
                setFailedToStartClassList(res.data[4])
                // 全部课程
                setAllClassList(res.data['all'])
                // 初始化参数
                // setAllCursorList(res.data['all'])
                switch (parseInt(tabKey)) {
                    case 0:
                        setAllCursorList(res.data['all'])
                        break;
                    case 1:
                        setAllCursorList(res.data[1])
                        break;
                    case 2:
                        setAllCursorList(res.data[2])
                        break;
                    case 3:
                        setAllCursorList(res.data[3])
                        break;
                    case 4:
                        setAllCursorList(res.data[4])
                        break;
                    case 5:
                        setAllCursorList(res.data[5])
                        break;
                }
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 查看详情
    const lookDetail = (item) => {
        setOpenDetail(true)
    }

    // 关闭查看详情
    const onCloseDetail = () => {
        setCommentKeyList([])
        setCommentList([])
        setCourseware([])
        setCursorDetail([]);
        setOpenDetail(false)
    }

    // 确认添加评论
    const addCommentOk = () => {
        if(docList.length == 0 && cursorComment == ''){
            message.warn('请输入正确的评论信息')
        }else{
            setEffective(1)
            const formData = new FormData()
            docList.forEach((item) => {
                formData.append('file_list', item['originFileObj'])
            })
            // 操作人uuid
            formData.append('principal_uuid', cookie.load('id'))
            // 店铺id
            formData.append('shopId', cookie.load('shopList'))
            // 角色id
            formData.append('roleId', cookie.load('role'))
            // 发布课程id
            formData.append('ns_courses_publish_id', cursorId)
            // 评论内容
            formData.append('comment', cursorComment)
            NbAxios(formData, "POST", servicePath.creatNsCoursesPublishComment).then(res => {
                setEffective(0)
                if (res.success) {
                    message.success('评论成功')
                    setCursorComment('')
                    setDocList([])
                    getCursorBaseMessage()
                    setAddComment(false)
                } else {
                    message.error(res.errorMsg)
                }
            })
        }
        

    }

    // 取消添加评论
    const addCommentCancel = () => {
        message.success('取消添加评论')
        setCursorComment('')
        setDocList([])
        setAddComment(false)
    }

    // 选择标签
    const changeTimeTags = (tag, checked) => {
        setSelectedTimeTags(tag)
    }

    // 获取课程信息
    const getCursorBaseMessage = (id) => {
        let dataProps = {
            "ns_courses_publish_id": id == undefined ? cursorId : id,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        NbAxios(dataProps, "POST", servicePath.queryNsCoursesPublishDetail).then(res => {
            if (res.success) {
                let commentKey = Object.keys(res.data['comment_list'])
                setCommentKeyList(commentKey)
                setCommentList(res.data['comment_list'])
                let keys = Object.keys(res.data['file_list'])
                setCoursewareKeyList(keys);
                setCourseware(res.data['file_list'])
                setCursorDetail(res.data['course_details']);
                setOpenDetail(true)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 获取课程分类
    const getCursorType = ()=>{
        let dataProps = {
            "creator_id": cookie.load('id'),
            "type_name": "1",
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryCursorTypes).then(res => {
            if (res.success) {
                let types_list = []
                res.data.all_types.map(item=>{
                    types_list.push(item.label)
                })
                setCourseType(types_list);
                
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 查询用户拥有课程分类
    const getOwnTypeList=()=>{
        let dataProps = {}
        dataProps.shopId = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.principal_uuid = cookie.load('id')
        NbAxios(dataProps, 'POST', servicePath.queryNsFocusTypeList).then(res => {
            if (res.success) {
               setChooseCourseTypes(res.data)
            }
        }).catch(e => {
        })
    }


    return (
        <div className="my-cursor-page-wapper" style={{ margin: 'auto' }}>
            <div className="my-cursor-page">
                <div style={{ display: 'flex', justifyContent: 'center' , alignItems:'center'}}>
                    <PageHeader
                        className="site-page-header"
                        backIcon={<LeftOutlined />}
                        onBack={() =>  props.history.push({ 'pathname': '/app/cursor/order'})}
                        style={{padding:'0px'}}
                        title="我的学习"
                    />
                    <Search placeholder="搜索关键字" onSearch={(value) => getNsCoursesPublishList(value)} enterButton style={{ width: 700, margin: 'auto' }} />
                    {/* <div>
                        <Button icon={<BtnIconFont type="icon-fabu" />} type="primary" style={{ borderRadius: '20px' }} onClick={()=>{
                            props.history.push({ 'pathname': '/app/cursor/cursorBase'})
                        }}>发布课程</Button>
                    </div> */}
                    <div>
                        <Button style={{ borderRadius: '20px'}} type="primary" onClick={()=>getCursorType()}>修改关注课程类别</Button>
                    </div>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    {Header}
                </div>
                <div className="cursor-page-list">
                    {
                        cursorList.length != 0 ? <Row>
                            {
                                cursorList.map(item => {
                                    return <Col style={{ width: "100%" }} onClick={() => {
                                        setCursorName(item.course_name)
                                        setCursorId(item.courses_publish_id)
                                        setCursorKind(item.course_kind)
                                        setFollowerList(item.follower_list)
                                        setLeaderList(item.leader_list)
                                        setCursorIntroduction(item.course_introduction)
                                        getCursorBaseMessage(item.courses_publish_id)
                                    }}>
                                        <Row span={24} style={{ justifyContent: 'center' }}>
                                            <Col style={{ marginTop: 10 }}>
                                                <div className="cursor-up-my-base-erea" style={{ background: item.course_cover == "" ? selectColor : item.course_cover, borderRadius:"20px", height:'135px' }}>
                                                    <Title level={5} ellipsis={{ rows: 2, expandable: false }} className="cursor-title" style={{ margin: 0 }}>{item.course_name}</Title>
                                                </div>
                                            </Col>
                                            <Col style={{ marginTop: '15px', marginLeft: '.5vw' }}>
                                                <Row className="my-cursor-row">
                                                    <Col span={17}>
                                                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                            {
                                                                item.types_search_key_id.split('-').map(item => {
                                                                    return <Tag color="blue" style={{ borderRadius: '20px', height: '25px', padding: '5px', lineHeight: '11px' }}>{item}</Tag>
                                                                })
                                                            }
                                                            <Col>
                                                                <Title level={4} style={{ width: '400px', overflow: 'hidden', textOverflow: "ellipsis", whiteSpace: "nowrap", margin: '0px' }}>{item.course_name}</Title>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={7}>
                                                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Col span={3}>
                                                                {
                                                                    item.participant_role == 5 ? <Text type="success">{'旁听'}</Text> : ''
                                                                }
                                                            </Col>
                                                            <Col span={3}>
                                                                {
                                                                    item.course_kind == 2 ? <Text type="secondary">{'讨论'}</Text>
                                                                        : <Text type="secondary">{'分享'}</Text>
                                                                }
                                                            </Col>
                                                            <Col span={3}>
                                                                {
                                                                    item.form == 1 ? <Text type="secondary">{'面授'}</Text>
                                                                        : <Text type="secondary">{'线上'}</Text>
                                                                }
                                                            </Col>
                                                            <Col span={6}>
                                                                {
                                                                    item.allow_audit == 1 ? <Text type="secondary">{'可旁观'}</Text>
                                                                        : <Text type="secondary">{'不可旁观'}</Text>
                                                                }

                                                            </Col>
                                                            <Col span={5}>
                                                                <Text type="secondary">{item.course_duration + '小时'}</Text>
                                                            </Col>
                                                            
                                                            <Col>
                                                                {
                                                                  item.number >= item.participant_ceiling ?
                                                                  <div className="cursor-people">已满员</div> :
                                                                  <div className="cursor-people"><TeamOutlined />{item.number}/{item.participant_ceiling}</div>
                                                                }
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: '1vh'}}>
                                                    <div style={{ width: '414px', overflow: 'hidden', textOverflow: "ellipsis", whiteSpace: "nowrap", marginTop:'10px'}}>
                                                        <Col span={22} style={{ marginLeft: '8px' }}>
                                                            {
                                                                item.course_kind == 2 ? item.leader_list.map(item => {
                                                                    return <span style={{ marginLeft: '7px' }}>{item}</span>
                                                                })
                                                                    : <span style={{ marginLeft: '7px' }}>{item.creator_name}</span>
                                                            }
                                                        </Col>
                                                    </div>
                                                </Row>
                                                <Row style={{ marginBottom: '1vh' }}>
                                                    <Col>
                                                        {item.tags_name.split(',').map((item, index) => {
                                                            return <Tag color="orange">{item}</Tag>
                                                        })}
                                                    </Col>
                                                    <Col>
                                                        <Text strong style={{ marginLeft: '1vw', marginRight: '1vw' }}>报名截止: {moment(item.registration_end_time).format('YYYY-MM-DD HH:mm')}</Text>
                                                    </Col>
                                                    <Col>
                                                        <Text >开课时间:{moment(item.course_clock).format('YYYY-MM-DD HH:mm')}</Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {
                                                        item.register_status == 2 ? <div>
                                                            <Row>
                                                                <Col>
                                                                    <Text strong style={{marginRight:'10px'}}>预约失败原因:</Text>
                                                                </Col>
                                                                <Col>
                                                                    <Paragraph type="secondary">
                                                                        {item.participant_msg}
                                                                    </Paragraph>
                                                                </Col>
                                                            </Row>
                                                        </div> : <div className="course_introduction_style">
                                                            <Paragraph ellipsis type="secondary">
                                                                {item.course_introduction}
                                                            </Paragraph>
                                                        </div>
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                })
                            }
                        </Row> : <div><Text strong>{(bookingLoading || bookingFailureLoading || bookingSuccessLoading || closedLoading) ? '正在为您加载消息...' : '暂无课程信息...'}</Text></div>}
                </div>
            </div>
            <Pagination simple current={page} total={total} hideOnSinglePage onChange={pageChange} />
            <Drawer
                title={cursorName}
                placement="right"
                closable={false}
                onClose={onCloseDetail}
                visible={openDetail}
                width={1050}
                zIndex="10"
            >
                <div className="detail-page-list">
                    <Descriptions bordered >
                        {
                            cursorKind == 2 ?
                                <Descriptions.Item label="引领者" span={3} style={{ width: 750 }}>
                                    {
                                        leaderList.join(',')
                                    }
                                </Descriptions.Item>
                                : ''
                        }
                        {
                            cursorKind == 2 ?
                                <Descriptions.Item label="跟随者" span={3} style={{ width: 750 }}>
                                    {
                                        followerList.join(',')
                                    }
                                </Descriptions.Item>
                                : ''
                        }
                        <Descriptions.Item label="课程简介" span={3}>
                            <Paragraph  style={{ width: 750 }} type="secondary" >
                                {cursorIntroduction}
                            </Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label="课程详情" span={3}>
                            <Paragraph style={{ width: 750 }} type="secondary" >
                                {cursorDetail}
                            </Paragraph>
                        </Descriptions.Item>
                    </Descriptions>
                    <div style={{ margin: 10 }}>
                        <Button type="primary" onClick={() => setAddComment(true)}>评论</Button>
                    </div>
                    <Tabs type="card" onChange={detailTabChange}>
                        <TabPane tab={commentTab} key={1}>
                            {
                                commentKeyList.length != 0
                                    ? <List
                                        itemLayout="horizontal"
                                        dataSource={commentKeyList}
                                        renderItem={item => (
                                            <div style={{ height: 150, marginTop: '10px' }}>
                                                {
                                                    commentList[item] != undefined ?
                                                        <div>
                                                            <Row>
                                                                <Col>
                                                                    {
                                                                        <Avatar size="large" >{commentList[item]['principal'].substring(0, 1)}</Avatar>
                                                                    }
                                                                </Col>
                                                                <Col style={{ marginLeft: 10 }}>
                                                                    <div>
                                                                        <Title level={5}>{commentList[item]['principal']}</Title>
                                                                    </div>
                                                                    <div>
                                                                        <Text type="secondary">{commentList[item]['create_time']}</Text>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Paragraph ellipsis={{ rows: 3 }}>
                                                                    {commentList[item]['comment']}
                                                                </Paragraph>
                                                            </Row>
                                                            <Row>
                                                                {
                                                                    commentList[item]['file_list'].map(item => {
                                                                        return <a target="_blank" 
                                                                                href={servicePath.downloadNsCoursesPublishCommentFile+'?shopId=' 
                                                                                + cookie.load('shopList')+"&roleId="+cookie.load('role')+"&ns_courses_publish_id="
                                                                                + cursorId + "&file_uuid="+ item.file_uuid+"&file_name="+item.file_name
                                                                                +"&file_type="+item.file_type + "&principal_uuid="+cookie.load('id')}> <Tag color="processing">{item.file_name}</Tag></a>
                                                                    })
                                                                }

                                                            </Row>
                                                        </div>
                                                        : <div></div>
                                                }

                                            </div>
                                        )}
                                    />
                                    : <div></div>
                            }
                        </TabPane>
                        <TabPane tab={materialTab} key={2}>
                            {
                                coursewareKeyList.length != 0
                                    ? <List
                                        itemLayout="horizontal"
                                        dataSource={coursewareKeyList}
                                        renderItem={item => (
                                            <div style={{ marginTop: '10px' }}>
                                                <Row>
                                                    <Col>
                                                        {
                                                            courseware[item] != undefined ? 
                                                            courseware[item]['file_type'] == 'docx' ? <IconFont type="icon-doc" style={{ fontSize: '30px', height: '40px', lineHeight: '40px' }} />
                                                                : courseware[item]['file_type'] == 'doc' ? <IconFont type="icon-doc" style={{ fontSize: '30px', height: '40px', lineHeight: '40px' }} />
                                                                    : courseware[item]['file_type'] == 'xlsx' ? <IconFont type="icon-xlsx" style={{ fontSize: '30px', height: '40px', lineHeight: '40px' }} />
                                                                        : courseware[item]['file_type'] == 'xls' ? <IconFont type="icon-xlsx" style={{ fontSize: '30px', height: '40px', lineHeight: '40px' }} />
                                                                            : courseware[item]['file_type'] == 'pdf' ? <IconFont type="icon-pdf" style={{ fontSize: '30px', height: '40px', lineHeight: '40px' }} />
                                                                                : courseware[item]['file_type'] == 'rar' ? <IconFont type="icon-rar" style={{ fontSize: '30px', height: '40px', lineHeight: '40px' }} />
                                                                                    : courseware[item]['file_type'] == 'ppt' ? <IconFont type="icon-ppt" style={{ fontSize: '30px', height: '40px', lineHeight: '40px' }} />
                                                                                        : courseware[item]['file_type'] == 'pptx' ? <IconFont type="icon-ppt" style={{ fontSize: '30px', height: '40px', lineHeight: '40px' }} />
                                                                                            : courseware[item]['file_type'] == 'zip' ? <IconFont type="icon-zip" style={{ fontSize: '30px', height: '40px', lineHeight: '40px' }} />
                                                                                                : courseware[item]['file_type'] == '7z' ? <IconFont type="icon-7z" style={{ fontSize: '30px', height: '40px', lineHeight: '40px' }} />
                                                                                                    : <div></div>
                                                            : <div></div>
                                                        }
                                                    </Col>
                                                    <Col style={{ marginLeft: 10 }}>
                                                        {
                                                            courseware[item] != undefined 
                                                            ? <a target="_blank" 
                                                                href={servicePath.downloadNsCoursesPublishFile+'?shopId=' 
                                                                + cookie.load('shopList')+"&roleId="+cookie.load('role')+"&ns_courses_publish_id="
                                                                + cursorId + "&file_uuid="+ courseware[item]['file_uuid']+"&file_name="+courseware[item]['file_name']
                                                                +"&file_type="+courseware[item]['file_type'] + "&principal_uuid="+cookie.load('id')}> <Title level={5}>{courseware[item]['file_name']}</Title></a>
                                                            : <div></div>
                                                        }
                                                       
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    />
                                    : <div></div>
                            }
                        </TabPane>
                    </Tabs>
                </div>
            </Drawer>

            <Modal
                title="添加评论"
                centered={true}
                visible={addComment}
                onOk={addCommentOk}
                onCancel={addCommentCancel}
                okText="保存"
                cancelText="取消"
                zIndex="999"
                maskClosable={false}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <TextArea
                    rows={4}
                    placeholder="请填写评论"
                    value={cursorComment}
                    onChange={(e) => {
                        setCursorComment(e.target.value)
                    }}
                ></TextArea>
                <Upload
                    action={servicePath.image}                          //上传地址，填错/不填上传图片的时候都会报错
                    listType="picture"                             // 上传列表的内建样式
                    fileList={docList}                                 //已经上传的文件列表（受控）
                    onChange={fileHandleChange}                             //上传文件改变时的状态
                    accept=".doc,.docx,.xlsx,.xls,.pdf,.zip,.rar,.txt,image/*"  //设置文件上传类型
                >
                    {docList.length >= 5 ? null : uploadButton}
                </Upload>
                <div>
                    <span style={{ margin: '0 8px', color: '#87CEFA' }}>
                        允许上传.doc,.docx,.xlsx,.xls,.pdf,.zip,.rar,.png,.jpg,.pptx,.txt
                        </span>
                </div>
            </Modal>

             {/* 选择感兴趣的分类 */}
             <Modal 
                title="请选择你感兴趣的课程"
                visible={openCourseType}
                onCancel={()=>setOpenCourseType(false)}
                footer={[]}
                centered={true}
                width={800}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Checkbox indeterminate={indeterminate} onChange={(e)=>{
                     setChooseCourseTypes(e.target.checked ? courseType : []);
                     setIndeterminate(false);
                     setCheckAll(e.target.checked);
                }} checked={checkAll}>
                    全选
                </Checkbox>
                <Divider />
                <Checkbox.Group style={{ width: '100%' }} value={chooseCourseTypes} onChange={(list)=>{
                    setChooseCourseTypes(list);
                    setIndeterminate(!!list.length && list.length < courseType.length);
                    setCheckAll(list.length === courseType.length);
                }}>
                   <Row>
                        {
                            courseType.map(item=>{
                                return <Col span={8}>
                                    <Checkbox value={item}>{item}</Checkbox>
                                </Col>
                            })
                        }
                        <Col span={8}></Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <Button style={{marginTop:'10px'}} type="primary" onClick={()=>{
                                setEffective(1)
                                let dataProps = {}
                                dataProps.shopId = cookie.load('shopList')
                                dataProps.roleId = cookie.load('role')
                                dataProps.principal_uuid = cookie.load('id')
                                dataProps.type_name_list = chooseCourseTypes
                                NbAxios(dataProps, 'POST', servicePath.updateNsFocusType).then(res => {
                                    if (res.success) {
                                        setEffective(0)
                                        message.success('您已经成功修改关注的分类')
                                        getOwnTypeList()
                                        setOpenCourseType(false)
                                    }
                                }).catch(e => {
                                })
                            }}>确定</Button>
                        </Col>
                   </Row>
                </Checkbox.Group>
            </Modal>
        </div>
    )
}

export default OrderCursor