import React, { useState, useEffect } from 'react';
import { Form, Upload, Table, Input, Tooltip,PageHeader, Button,Spin, Modal, message, DatePicker,notification, Divider, Row, Col, Typography, Badge, Card } from 'antd';
import { PlusOutlined,PaperClipOutlined, ReconciliationOutlined, UserOutlined, InboxOutlined } from '@ant-design/icons';
import NbAxios from '../../../config/utils/nbAxios';
import { UploadOutlined } from "@ant-design/icons";
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl';
import downExcel from '../../../config/utils/downloadFile';
import locale from 'antd/es/date-picker/locale/zh_CN';
import axios from 'axios'
/* 其他 */
const { TextArea } = Input;
const { Title, Text } = Typography
// 文件上传组建
const { Dragger } = Upload;
const dateFormat = 'YYYY-MM-DD';
//时间框
const { RangePicker } = DatePicker;

// 工厂页面
function Index(props) {
    
    const layout = {
        labelCol: { span: 9 },
        wrapperCol: { span: 16 },
    };
    // 表格填写数据获取
    const [form] = Form.useForm()
    const [dockingForm] = Form.useForm()
    const [problemForm] = Form.useForm()
    const [modifiedForm] = Form.useForm()
    /* useState */
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    //是否显示新增工厂信息
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    // 基础消息修改弹框(定义一些函数控制窗口的开关)
    const [isModifiedProblem, setIsModifiedProblem] = useState(false);
    // 质量问题新增弹窗
    const [isProblemXinzengModalVisible, setIsProblemXinzengModalVisible] = useState(false);
    const showProblemXinzengModal = () => {

        setIsProblemXinzengModalVisible(true);
    };

    const handleProblemXinzengOk = () => {
        setIsProblemXinzengModalVisible(false);
    };

    const handleProblemXinzengCancel = () => {
        setIsProblemXinzengModalVisible(false);
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">请选择文件</div>
        </div>
    );
    const uploadFileButton = (
        <div>
            <Button icon={<UploadOutlined />}>选择文件</Button>
        </div>
    );
    // 设置加载中变量
    const [loading, setloading] = useState(false);
    // 原来的数据
    const [ImageID, setImageID] = useState(1)
    // 上传对话框设置
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    // 控制图片对话框的可不可见
    const [previewVisible, setPreviewVisible] = useState(false);
    // 控制缩略图
    const [previewImage, setPreviewImage] = useState("");
    // 控制缩略名
    const [previewTitle, setPreviewTitle] = useState("");
    // 储存文件列表
    const [fileList, setFileList] = useState([]);
    // 上传图片对话框设置新增
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    // 上传图片对话框设置新增
    const [isImageModalVisible2, setIsImageModalVisible2] = useState(false);
    //是否显示对接人弹窗
    const [isDuijierenModalVisible, setIsDuijierenModalVisible] = useState(false);
    const showDuijierenModal = () => {
        setIsDuijierenModalVisible(true);
    };
    const handleDuijierenOk = () => {
        setIsDuijierenModalVisible(false);
    };
    const handleDuijierenCancel = () => {
        setIsDuijierenModalVisible(false);
    };
    // 控制附件表格是否打开
    const [isdownloadData, setIsDownloadData] = useState(false)
    //  是否显示附件下载弹窗
    const [isFujianModalVisible, setIsFujianModalVisible] = useState(false);
    const showFujianModal = () => {
        setIsFujianModalVisible(true);
    };
    const handleFujianOk = () => {
        setIsFujianModalVisible(false);
    };

    const handleFujianCancel = () => {
        setIsFujianModalVisible(false);
    };
    // 工厂详情修改接口弹窗
    const [isFactoryXiugaiModalVisible, setIsFactoryXiugaiModalVisible] = useState(false);
    const showFactoryXiugaiModal = () => {

        setIsFactoryXiugaiModalVisible(true);
    };

    const handleFactoryXiugaiOk = () => {
        setIsFactoryXiugaiModalVisible(false);
    };

    const handleFactoryXiugaiCancel = () => {
        form.resetFields()
        setIsFactoryXiugaiModalVisible(false);
    };

    // 对接人主对接人设定弹窗
    const [isMainOppositePersonXinzengModalVisible, setIsMainOppositePersonXinzengModalVisible] = useState(false);
    const showMainOppositePersonXinzengModal = () => {

        setIsMainOppositePersonXinzengModalVisible(true);
    };

    const handleMainOppositePersonXinzengOk = () => {
        setIsMainOppositePersonXinzengModalVisible(false);
    };

    const handleMainOppositePersonXinzengCancel = () => {
        setIsMainOppositePersonXinzengModalVisible(false);
    };
    // 对接人新增弹窗
    const [isOppositePersonXinzengModalVisible, setIsOppositePersonXinzengModalVisible] = useState(false);
    const showOppositePersonXinzengModal = () => {

        setIsOppositePersonXinzengModalVisible(true);
    };

    const handleOppositePersonXinzengOk = () => {
        setIsOppositePersonXinzengModalVisible(false);
    };

    const handleOppositePersonXinzengCancel = () => {
        dockingForm.resetFields()
        setIsOppositePersonXinzengModalVisible(false);
    };
    // 下载表格数据
    const [downloadData, setDownloadData] = useState([])
    // 对接人修改弹窗
    const [isOppositePersonXiugaiModalVisible, setIsOppositePersonXiugaiModalVisible] = useState(false);

    const handleOppositePersonXiugaiOk = () => {
        setIsOppositePersonXiugaiModalVisible(false);
    };

    const handleOppositePersonXiugaiCancel = () => {
        dockingForm.resetFields()
        setIsOppositePersonXiugaiModalVisible(false);
    };
    // 上传附件区分认证证书和流水线设备的clssification
    const [classification, setClassification] = useState(1)
    // 设置工厂id
    const [factoryid, setFactoryId] = useState(1)
    // 设置质量问题id
    const [problemid, setProblemId] = useState(1)
    // 工厂数据
    const [factoryData, setFactoryData] = useState([])
    // 对接人数据
    const [oppositePersonData, setOppositePersonData] = useState([])
    // 预算目标文件
    const [baseFile, setBaseFile] = useState([])
    // 是否上传文件
    const [isUpload, setIsUpload] = useState(false)
    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 下载报价单列名
    const downloadcolumns = [
        {
            title: '时间',
            dataIndex: 'problem_time',
            align: "problem_time",
        },
        {
            title: '供应商',
            dataIndex: 'supplier',
            align: "supplier",
            align: 'center',
            width: 200,
            fixed:true,
        },
        {
            title: '质量问题',
            dataIndex: 'problem_name',
            align: "problem_name",
            align: 'center',
            width: 400,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '图片',
            dataIndex: 'file_address',
            align: "file_address",
            align: 'center',
            width: 100,
            render:(text)=> <img src={'https://pic.bmcsoft.cn/' + text}/>
        },
        {
            title: '操作',
            dataIndex: 'chaozhuo',
            key: 'chaozhuo',
            align: 'center',
            render: (text, record, index) => (
                // 参数分别为当前行的值，当前行数据，行索引
                <div style={{ display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none" }}>
                <a style={{marginLeft:5}} onClick={() => {
                    setProblemId(record.id)
                    factoryfile_delect(record.id)
                }}>删除</a>
                
                <a style={{marginLeft:10}} onClick={() => {
                    setProblemId(record.id)
                    setImageID(record.file_id)
                    // modifiedForm.setFieldsValue(record)
                    setClassification(3)
                    setIsModifiedProblem(true);
                }}> 修改</a>
                </div>
            ),
        },
    ];
    /* 接口 */
    //初始默认界面调用find
    useEffect(() => {
        getJurisdiction()
        findFactory();
    }, [])
    // 图片上传函数
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const PreviewhandleCancel = () => {
        setPreviewVisible(false);
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };


    const showImageModal = () => {
        setIsImageModalVisible(true);
    };
    const showImageModal2 = () => {
        setIsImageModalVisible2(true);
    };
    const handleImageOk = () => {
        setIsImageModalVisible(false);
      };
    const handleImageCancel = () => {
        setFileList([]);
        setIsImageModalVisible(false);
    };
    const handleImageCancel2 = () => {
        setFileList([]);
        setIsImageModalVisible2(false);
    };
    
    // 上传图片函数
    const uploadImage = () => {
        if (fileList.length == 0) {
            message.error("请选择图片");
        }
        setloading(true);
        const formData = new FormData();
        // 单个图片
        // formData.append('file',fileList[0].originFileObj)

        // 多个图片
        fileList.forEach((item) => {
            formData.append("file", item["originFileObj"]);
        });
        formData.append('factory_id', factoryid)
        formData.append('classification', classification)
        formData.append("shopList", cookie.load('shopList'));
        formData.append("roleId", cookie.load('role'));
        formData.append("name", cookie.load('name'));
        NbAxios(formData, "POST", servicePath.uploadFactoryFile).then(res => {
            setloading(false);
            if (res.success) {
                setImageID(res.data)
                message.success("图片上传成功");
                setFileList([]);
                setIsImageModalVisible(false);
                setIsImageModalVisible2(false);
            } else {
                message.warn(res["errorMsg"])
            }
        })
    };
    // 获取所有角色/店铺对应的权限
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": ['82328e58-068b-4a27-9bd0-9d1649cb8e6e']
        }
        NbAxios(dataProps, "POST", servicePath.getRoleShopPorts).then(res => {
            if (res.success) {
                setJurisdiction(res.data)
            }
        })
    }
    //查找工厂实力详情
    const findFactory = () => {
        let obj = {
            "supplier_id": props.location.state.workOrderId,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.findFactory).then(res => {
            if (res.success) {
                setFactoryData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    //工厂信息新增
    const addFactory = (values) => {
        //获取上个界面传入的供应商id
        values.supplier_id = props.location.state.workOrderId
        values.corporate_name = props.location.state.corporate_name
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POSt", servicePath.addFactory).then(res => {
            if (res.success) {
                message.success('工厂新增成功');
                //新增成功后调用，显示一遍页面
                handleOk();
                form.resetFields();
                findFactory()
            } else {
                message.warn(res.errorMsg)
            }

        })
    }

    //  上传附件信息
    const uploadFactoryFile = () => {
        if (baseFile.length != 0) {
            setEffective(1)
            const formData = new FormData()
            // 多个图片
            baseFile.forEach((item) => {
                formData.append('file', item['originFileObj']);

            })
            formData.append('factory_id', factoryid)
            formData.append('classification', classification)
            formData.append('roleId', cookie.load('role'));
            formData.append('shopList', cookie.load('shopList'));
            NbAxios(formData, "POST", servicePath.uploadFactoryFile).then(res => {
                setEffective(0)
                if (res.success) {
                    message.success('文件数据上传成功');           
                    setBaseFile([]);
                    setIsUpload(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        } else {
            message.warn('请选择文件')
        }
    }
    // 预算目标文件上传
    const baseFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['zip', 'xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setBaseFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setBaseFile(fileList)
        }
    }
    // 附件信息下载
    const downloadFactoryFile = (factoryid, classification) => {
        let data = {
            'factory_id': factoryid,
            'classification': classification,
            'roleId': cookie.load('role'),
            'shopList': cookie.load('shopList')
        }
        axios({
            data: data,
            method: 'POST',
            url: servicePath.downloadFactoryFile,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                downExcel(res)
            }
        ).catch(e => {
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }

    // 工厂详情删除接口
    const delectFactory = (factoryid) => {
        let obj = {
            'factory_id': factoryid,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.delectFactory).then(res => {
            if (res.success) {
                message.success('工厂删除成功')
                findFactory()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 工厂是否删除确认弹框
    function factory_delect(factoryid) {
        Modal.confirm({
            title: '',
            width: "25%",
            okText: '确定',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                delectFactory(factoryid)
            },
            onClose: () => {

            },
            content: (
                <div>
                    <span>
                        是否删除该工厂信息？
                    </span>
                </div>
            ),

        });
    }

    //  工厂详情修改接口(onfinish=>values)
    const modifiedFactory = (values) => {
        values.factory_id = factoryid
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.modifiedFactory).then(res => {
            if (res.success) {
                message.success('工厂修改成功');
                handleFactoryXiugaiOk()
                findFactory()
                form.resetFields()
            } else {
                message.warn(res.errorMsg)
            }
        })

    }

    // 对接人详情查找接口
    const findOppositePerson = (factoryid) => {
        let obj = {
            'factory_id': factoryid,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.findOppositePerson).then(res => {
            if (res.success) {
                setOppositePersonData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 对接人详情新增接口
    const addOppositePersdon = (values) => {
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        values.factory_id = factoryid
        NbAxios(values, "POST", servicePath.addOppositePersdon).then(res => {
            if (res.success) {
                dockingForm.resetFields()
                handleOppositePersonXinzengOk()
                message.success('对接人新增成功');
                findOppositePerson(factoryid)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 主对接人设定id
    const mainOppositePerson = (id) => {
        let obj = {
            'id': id,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.mainOppositePerson).then(res => {
            if (res.success) {
                message.success('主对接人设定成功');
                handleMainOppositePersonXinzengOk()
                findOppositePerson(factoryid)
            } else {
                message.warn(res.errorMsg)
            }
        })

    }
    // 对接人修改
    const [mainId, setMainId] = useState()
    const modifiedOppositePerson = (values) => {
        values.id = mainId
        values.factory_id = factoryid
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.modifiedOppositePerson).then(res => {
            if (res.success) {
                findOppositePerson(factoryid)
                dockingForm.resetFields()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 主对接人详情删除
    const delectOppositePerson = (id) => {
        let obj = {
            'id': id,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.delectOppositePerson).then(res => {
            if (res.success) {
                message.success('对接人删除成功');
                findOppositePerson(factoryid)
            } else {
                message.warn(res.errorMsg)
            }
        })

    }
    // 对接人是否删除确认弹框
    function oppositeperson_delect(id) {
        Modal.confirm({
            title: '',
            width: "25%",
            okText: '确定',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                delectOppositePerson(id)
            },
            onClose: () => {

            },
            content: (
                <div>
                    <span>
                        是否删除该对接人信息？
                    </span>
                </div>
            ),

        });
    }
    // 质量问题查询
    const findFactoryFile = (id, classification) => {
        let obj = {
            "factory_id": id,
            "classification": classification,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.findFactoryFile).then(res => {
            if (res.success) {
                setDownloadData(res.data)
                setIsDownloadData(true)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 质量问题新增
    const addProblem = (values) => {
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        values.creator = cookie.load('creator')
        values.factory_id = factoryid
        values.supplier = props.location.state.corporate_name
        values.file_id = ImageID
        values.problem_time = values.problem_time.format("YYYY-MM-DD")
        NbAxios(values, "POST", servicePath.addProblem).then(res => {
            if (res.success) {
                message.success('质量问题新增成功');
                handleProblemXinzengCancel();
                //新增成功,清空显示的数据
                problemForm.resetFields();
                //新增成功后调用，显示一遍页面
                findFactoryFile(factoryid, 3)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
      // 质量问题文件修改
      const modifiedProblem = (values) => {
        values.id =problemid
        values.problem_time = values.problem_time.format("YYYY-MM-DD")
        values.file_id = ImageID
        values.creator = cookie.load('creator')
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.modifiedProblem).then(res => {
            if (res.success) {
                message.success('质量问题文件修改成功');
                modifiedForm.resetFields();
                setIsModifiedProblem(false)
                findFactoryFile(factoryid, 3)
            } else {
                message.warn(res.errorMsg)
            }
        })

    }

    // 质量问题文件删除
    const delectFactoryFile = (id) => {
        let obj = {
            'id': id,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.delectFactoryFile).then(res => {
            if (res.success) {
                message.success('质量问题文件删除成功');
                findFactoryFile(factoryid, 3)
            } else {
                message.warn(res.errorMsg)
            }
        })

    }
    // 质量问题文件是否删除确认弹框
    function factoryfile_delect(id) {
        Modal.confirm({
            title: '',
            width: "25%",
            okText: '确定',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                delectFactoryFile(id)
            },
            onClose: () => {

            },
            content: (
                <div>
                    <span>
                        是否删除该质量问题文件？
                    </span>
                </div>
            ),

        });
    }
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-around", marginBottom: 20, marginTop: 10, borderBottom: "1px solid #ccc" }}>
                <Title level={4}>工厂实力详情</Title>
                <div style={{ display: "flex" }}>
                    <a href="https://pic.bmcsoft.cn/imge_dir/122717595311/122717595311.html" target="_Blank"><Button type="link">操作手册</Button></a>
                    <Button type="primary" onClick={showModal} style={{ marginRight: 200,display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "flex" : "none" }}>新增</Button>
                </div>
            </div>
            <div style={{ width: "98vw", height: '70vh', overflow: 'auto' }}>
                {
                    factoryData.map((item, index) => {
                        return (
                            <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', padding: 20, marginTop: 10, marginLeft: 20, marginRight: 20 }}>
                                <span style={{ display: "flex", justifyContent: "flex-end", marginRight: 100 }}>
                                    <Button
                                        icon={<ReconciliationOutlined />}
                                        style={{ marginRight: 10 }}
                                        onChange={() => {

                                            setFactoryId(item.factory_id)
                                        }}
                                        onClick={() => {

                                            props.history.push({
                                                'pathname': '/app/three/pets/sms/order',
                                                'state': {
                                                    'factory_id': item.factory_id,
                                                    'corporate_name': item.corporate_name,
                                                    'factory_name': item.factory_name,
                                                }
                                            })
                                        }}>订单</Button>
                                    <div style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "flex" : "none"}}>
                                        <Button
                                            style={{ marginRight: 10 }}
                                            icon={<UserOutlined />}
                                            onClick={() => {
                                                setFactoryId(item.factory_id)
                                                showDuijierenModal()
                                                findOppositePerson(item.factory_id);
                                            }

                                            }>对接人</Button>
                                        <Button

                                            onClick={() => {
                                                showFujianModal()
                                                setFactoryId(item.factory_id)
                                            }}
                                        >附件上传</Button>
                                    </div>
                                </span>
                                <br></br>
                                <div style={{ marginLeft: 100 }}>
                                    <Row>
                                        <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="工厂名称：" name="factory_name" size="large" rules={[{ required: true }]} style={{ width: 260 }}>
                                            <span>{item.factory_name}</span>
                                        </Form.Item>
                                    </Row>
                                    <Row >
                                        <Col span={6}>
                                            <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="品类：" name="category" size="large" rules={[{ required: true }]} style={{ width: 260 }}>
                                                <span>{item.category}</span>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="产能：" name="capacity" size="large" rules={[{ required: true }]} style={{ width: 260 }}>
                                                <span>{item.capacity}</span>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="产值：" name="output_value" size="large" rules={[{ required: true }]} style={{ width: 260 }}>
                                                <span>{item.output_value}</span>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="占地面积：" name="area_covered" size="large" rules={[{ required: true }]} style={{ width: 260 }}>
                                                <span>{item.area_covered}</span>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            <Form.Item label="人员数量：" name="number_of_personnel" rules={[{ required: true }]} style={{ width: 260 }}>
                                                <span>{item.number_of_personnel}</span>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none"}}>
                                            <Form.Item label="认证证书：" name="certification_certificate" size="large" rules={[{ required: true }]} style={{ width: 260 }}>
                                                <Button
                                                    type="link"
                                                    icon={<PaperClipOutlined />}
                                                    onClick={() => {
                                                        downloadFactoryFile(item.factory_id, 1)
                                                    }}
                                                >附件</Button>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none"}}>
                                            <Form.Item label="流水线设备：" name="assembly_line_equipment" size="large" rules={[{ required: true }]} style={{ width: 260 }}>
                                                <Button
                                                    type="link"
                                                    icon={<PaperClipOutlined />}
                                                    onClick={() => {
                                                        downloadFactoryFile(item.factory_id, 2)
                                                    }}
                                                >附件</Button>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none"}}>
                                            <Form.Item label="质量问题：" name="quality_problems" style={{ width: 260 }}>
                                                <Button
                                                    type="link"
                                                    icon={<PaperClipOutlined />}
                                                    onClick={() => {
                                                        // setIsDownloadData(true)
                                                        setFactoryId(item.factory_id)
                                                        findFactoryFile(item.factory_id, 3)
                                                    }}
                                                >附件查看</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none"}}>
                                        <Col span={6} offset={18}>
                                            <Button
                                                type="primary"
                                                size="small"
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    showFactoryXiugaiModal()
                                                    form.setFieldsValue(item)
                                                    setFactoryId(item.factory_id)
                                                }}

                                            >修改</Button>

                                            <Button
                                                type="primary"
                                                size="small"
                                                onClick={() => {
                                                    factory_delect(item.factory_id)
                                                    setFactoryId(item.factory_id)
                                                }}
                                            >删除</Button>
                                        </Col>

                                    </Row>

                                    <Divider />
                                </div>

                            </div>

                        )

                    })
                }
            </div>
            {/* model弹窗 */}
            {/* 新增工厂信息弹窗 */}
            <Modal
                title="新增工厂信息"
                visible={isModalVisible}
                onCancel={() => {
                    handleCancel();
                    form.resetFields();
                }
                }
                footer={[]}
                centered={true}
                width={1000}
            >
                <div>
                    <Form {...layout} labelAlign='left' form={form} onFinish={addFactory}>
                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="工厂名称：" name="factory_name" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="品类：" name="category" size="large" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="产能：" name="capacity" size="large" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="产值：" name="output_value" size="large" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="占地面积：" name="area_covered" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="人员数量：" name="number_of_personnel" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    确定
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        handleCancel();
                                        form.resetFields();
                                    }
                                    }>
                                    取消
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </div>

            </Modal>
            {/* 附件上传弹窗 */}
            <Modal
                title="附件上传"
                footer={[]}
                width={600}
                centered={true}
                visible={isFujianModalVisible}
                onCancel={handleFujianCancel}>
                <Row>
                    <Col span={12}>
                        <Form.Item label="认证证书：" name="certification_certificate" >
                            <Button
                                type="link"
                                icon={<PaperClipOutlined />}
                                onClick={() => {
                                    setClassification(1)
                                    setIsUpload(true)

                                }}
                            >
                                附件上传</Button>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="流水线设备：" name="assembly_line_equipment" >
                            <Button
                                type="link"
                                icon={<PaperClipOutlined />}
                                onClick={() => {
                                    setClassification(2)
                                    setIsUpload(true)
                                }}
                            >
                                附件上传</Button>
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row>
                    <Col span={12}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => handleFujianOk()}
                        >
                            确定
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button type="primary" danger onClick={() => handleFujianCancel()}>
                            取消
                        </Button>
                    </Col>
                </Row> */}
            </Modal>
            {/* 对接人表弹窗 */}
            <Modal
                title="对接人表"
                visible={isDuijierenModalVisible}
                onCancel={handleDuijierenCancel}
                width={800}
                centered={true}
                footer={[]}
            >
                <div style={{ display: "flex", justifyContent: "flex-end" }} >
                    <Row >
                        <Button
                            type="primary"
                            style={{ marginLeft: 20 }}
                            onClick={showOppositePersonXinzengModal}>新增</Button>
                        <Button
                            type="primary"
                            style={{ marginLeft: 20 }}
                            onClick={() => {
                                showMainOppositePersonXinzengModal()
                            }}
                        >主对接人</Button>
                    </Row>
                </div>
                <br></br>
                <div>
                    {
                        oppositePersonData.map((item, index) => {
                            if (index === 0) {
                                return (
                                    <Badge.Ribbon text="主对接人" color="red">
                                        <Form form={form} style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', padding: 20, marginTop: 5 }}>
                                            <div>
                                                <Row >
                                                    <Col span={12}>
                                                        <Form.Item label="对接人：" name="opposite_person" style={{ width: 260 }}>
                                                            <span>{item.opposite_person}</span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label="联系方式：" name="contact_information" size="large" style={{ width: 260 }}>
                                                            <span>{item.contact_information}</span>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div>
                                                <Row >
                                                    <Col span={12}>
                                                        <Form.Item label="部门：" name="department" style={{ width: 260 }}>
                                                            <span>{item.department}</span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label="直接上级：" name="direct_superior" size="large" style={{ width: 260 }}>
                                                            <span>{item.direct_superior}</span>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Form.Item label="备注：" name="remarks" style={{ width: 260 }}>
                                                            <span>{item.remarks}</span>
                                                        </Form.Item>
                                                    </Col>

                                                </Row>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Row >
                                                    <Button
                                                        type="primary"
                                                        style={{ marginLeft: 20 }}
                                                        onClick={() => {
                                                            setMainId(item.id)
                                                            dockingForm.setFieldsValue(item)
                                                            setIsOppositePersonXiugaiModalVisible(true);
                                                        }}>修改</Button>
                                                    <Button
                                                        type="primary"
                                                        style={{ marginLeft: 20 }}
                                                        onClick={() => {
                                                            oppositeperson_delect(item.id)

                                                        }}>删除</Button>
                                                </Row>

                                            </div>
                                        </Form>
                                    </Badge.Ribbon>
                                )

                            } else {
                                return (
                                    <Form form={form} style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', padding: 20, marginTop: 5 }}>
                                        <div>
                                            <Row >
                                                <Col span={12}>
                                                    <Form.Item label="对接人：" name="opposite_person" style={{ width: 260 }}>
                                                        <span>{item.opposite_person}</span>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="联系方式：" name="contact_information" size="large" style={{ width: 260 }}>
                                                        <span>{item.contact_information}</span>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row >
                                                <Col span={12}>
                                                    <Form.Item label="部门：" name="department" style={{ width: 260 }}>
                                                        <span>{item.department}</span>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="直接上级：" name="direct_superior" size="large" style={{ width: 260 }}>
                                                        <span>{item.direct_superior}</span>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Form.Item label="备注：" name="remarks" style={{ width: 260 }}>
                                                        <span>{item.remarks}</span>
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Row >
                                                <Button
                                                    type="primary"
                                                    style={{ marginLeft: 20 }}
                                                    onClick={() => {
                                                        setMainId(item.id)
                                                        dockingForm.setFieldsValue(item)
                                                        setIsOppositePersonXiugaiModalVisible(true);
                                                    }}>修改</Button>
                                                <Button
                                                    type="primary"
                                                    style={{ marginLeft: 20 }}
                                                    onClick={() => {
                                                        oppositeperson_delect(item.id)
                                                    }}>删除</Button>
                                            </Row>

                                        </div>

                                    </Form>

                                )
                            }
                        })
                    }
                </div>
            </Modal>
            {/* 上传附件弹窗 */}
            <Modal
                title="上传附件"
                visible={isUpload}
                centered={true}
                okText="确定"
                cancelText="取消"
                maskClosable={true}
                onOk={() => uploadFactoryFile()}
                onCancel={() => {
                    message.success('取消上传附件')
                    setBaseFile([])
                    setIsUpload(false)
                }}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.findTrue}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={baseFile}
                    onChange={baseFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        {classification == 1 || classification == 2 ? ' 仅支持上传一份后缀为.zip文件' : ' 仅支持上传一份后缀为.xls .xlsx 文件'}

                    </p>
                </Dragger>
            </Modal>
            {/* 新增对接人弹窗 */}
            <Modal
                title="新增对接人"
                visible={isOppositePersonXinzengModalVisible}
                onCancel={() => {
                    handleOppositePersonXinzengCancel();
                    form.resetFields();
                }}
                width={800}
                centered={true}
                footer={[]} >
                <Form name="addOppositePersdon" form={dockingForm} onFinish={addOppositePersdon}>
                    <div>

                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="对接人：" name="opposite_person" rules={[{ required: true }]} style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="联系方式：" name="contact_information" size="large" rules={[{ required: true }]} style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="部门：" name="department" rules={[{ required: true }]} style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="直接上级：" name="direct_superior" size="large" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col>
                                <Form.Item label="备注：" name="remarks" style={{ width: 500 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <Divider />
                    <Row>
                        <Col span={12}>
                            <Button
                                type="primary"
                                htmlType="submit">
                                确定
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                type="primary"
                                danger
                                onClick={() => {
                                    handleOppositePersonXinzengCancel();
                                    form.resetFields();
                                }
                                }>
                                取消
                            </Button>
                        </Col>
                    </Row>
                </Form>


            </Modal>
            {/* 主对接人设定弹窗 */}
            <Modal
                title="主对接人设定"
                visible={isMainOppositePersonXinzengModalVisible}
                onOk={handleMainOppositePersonXinzengOk}
                onCancel={handleMainOppositePersonXinzengCancel}
                width={400}
                centered={true}
                footer={[]} >
                <div>
                    {
                        // 输出每个对接人
                        oppositePersonData.map((item, index) => {
                            return (
                                <div>
                                    <Button
                                        type="text"
                                        icon={<UserOutlined />}
                                        onClick={() => {
                                            mainOppositePerson(item.id)
                                        }}>{item.opposite_person}</Button>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
            {/* 对接人修改弹窗 */}
            <Modal
                title="对接人修改"
                visible={isOppositePersonXiugaiModalVisible}
                onCancel={handleOppositePersonXiugaiCancel}
                width={800}
                centered={true}
                footer={[]} >
                <Form form={dockingForm} onFinish={modifiedOppositePerson}>
                    <div>

                        <Row >
                            <Col span={12}>
                                <Form.Item label="对接人：" name="opposite_person" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="联系方式：" name="contact_information" size="large" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row >
                            <Col span={12}>
                                <Form.Item label="部门：" name="department" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="直接上级：" name="direct_superior" size="large" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col>
                                <Form.Item label="备注：" name="remarks" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <Divider />
                    <Row>
                        <Col span={12}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={() => {
                                    handleOppositePersonXiugaiOk()
                                }}>
                                确定
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                type="primary"
                                danger
                                onClick={() => handleOppositePersonXiugaiCancel()}>
                                取消
                            </Button>
                        </Col>
                    </Row>
                </Form>

            </Modal>
            {/* 工厂详情修改弹窗 */}
            <Modal
                title="工厂详情修改弹窗"
                visible={isFactoryXiugaiModalVisible}
                onCancel={handleFactoryXiugaiCancel}
                width={800}
                centered={true}
                footer={[]} >
                <div>
                    <Form {...layout} labelAlign='left' form={form} onFinish={modifiedFactory}>
                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="工厂名称：" name="factory_name" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="品类：" name="category" size="large" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="产能：" name="capacity" size="large" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="产值：" name="output_value" size="large" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="占地面积：" name="area_covered" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="人员数量：" name="number_of_personnel" rules={[{ required: true }]} style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    确定
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => handleFactoryXiugaiCancel()}>
                                    取消
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </div>
            </Modal>
            {/* 报价单附件展示弹窗 */}
            <Modal
                title="质量问题附件"
                // 控制弹框是否打开
                visible={isdownloadData}
                onCancel={() => {
                    setIsDownloadData(false)
                }}
                width={1000}
                centered={true}
                footer={[]}
            >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        style={{ marginRight: 10 }}
                        type="primary"
                        onClick={() => {
                            setClassification(3)
                            showProblemXinzengModal();
                        }}>新增</Button>
                </div>
                <p></p>
                <Table
                        align="center"
                        columns={downloadcolumns}
                        dataSource={downloadData}
                        pagination={false}
                    />
            </Modal>
            {/* 新增质量问题弹窗 */}
            <Modal
                title="新增质量问题"
                visible={isProblemXinzengModalVisible}
                onCancel={() => {
                    handleProblemXinzengCancel();
                    problemForm.resetFields();
                }}
                width={800}
                centered={true}
                footer={[]} >
                <Form name="addOppositePersdongys" form={problemForm} onFinish={addProblem}>
                    <div>

                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="日期：" name="problem_time"  style={{ width: 260 }}>
                                     <DatePicker dateFormat={dateFormat} locale={locale}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="供应商：" name="supplier" size="large" style={{ width: 260 }}>
                                    <Text>{props.location.state.corporate_name}</Text>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col>
                                <Form.Item label="质量问题：" name="problem_name" style={{ width: 500 }}>
                                    <TextArea rows={4} placeholder="请填写质量问题" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <p></p>
                    {/* 上传图片按钮 */}
                        <Button
                            type="primary"
                            onClick={showImageModal}
                            
                        >
                            上传质量问题图片
                        </Button>
                    <p></p>
                    <Row>
                        <Col span={12}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                >
                                确定
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                type="primary"
                                danger
                                onClick={() => {
                                    handleProblemXinzengCancel();
                                    problemForm.resetFields();
                                }
                                }>
                                取消
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
             {/* 图片添加对话框 */}
            <Modal
                title="图片添加窗口"
                visible={isImageModalVisible}
                onCancel={handleImageCancel}
                footer={[]} 
                centered={true}
            >
                <Spin tip="Loading..." spinning={loading}>
                <Row>
                    <div style={{ marginLeft: 15 }}>
                    <Upload
                        action={servicePath.uploadImage} //上传地址，填错/不填上传图片的时候都会报错
                        listType="picture-card" // 上传列表的内建样式
                        fileList={fileList} //已经上传的文件列表（受控）
                        onPreview={handlePreview} //点击文件链接或预览图标时的回调
                        onChange={handleChange} //上传文件改变时的状态
                    >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                    </div>
                </Row>
                <Text type="danger">可以选择图片(图片后缀为.jpg 或 .png)</Text>
                <div style={{ marginLeft: 400 }}>
                    <Button type="primary" onClick={() => uploadImage()}>
                    上传
                    </Button>
                </div>
                </Spin>
            </Modal>
            {/* 图片缩略图对话框 */}
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
            {/* 修改基础消息弹窗 */}
            <Modal
                title="修改质量问题"
                visible={isModifiedProblem}
                onCancel={() => {
                    setIsModifiedProblem(false);
                    findFactoryFile(factoryid,3)
                    modifiedForm.resetFields();
                }}
                width={1000}
                centered={true}
                footer={[]} >
                <div>
                    <Form  form={modifiedForm} onFinish={modifiedProblem}>
                        <Row >
                            <Col >
                                <Form.Item label="时间" name="problem_time" size="large"  style={{ width: 350 }}  >
                                    <DatePicker dateFormat={dateFormat} locale={locale}/>
                                </Form.Item>
                            </Col>
                            <Col >
                                <Form.Item label="供应商" name="supplier" size="large"  style={{ width: 350 }}>
                                    <Text>{props.location.state.corporate_name}</Text>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                             <Form.Item label="质量问题：" name="problem_name" style={{ width: 500 }}>
                                    <TextArea rows={4} placeholder="请填写质量问题" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <p></p>
                        {/* 上传图片按钮 */}
                            <Button
                                type="primary"
                                onClick={showImageModal2}
                                >
                                    修改质量问题图片
                            </Button>
                        <p></p>
                        <Row>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    确定
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        setIsModifiedProblem(false);
                                        findFactoryFile(factoryid,3)
                                    }}>
                                    取消
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </div>
            </Modal>
             {/* 图片添加对话框 */}
             <Modal
                title="图片添加窗口"
                visible={isImageModalVisible2}
                onCancel={handleImageCancel2}
                footer={[]} 
                centered={true}
            >
                <Spin tip="Loading..." spinning={loading}>
                <Row>
                    <div style={{ marginLeft: 15 }}>
                    <Upload
                        action={servicePath.uploadImage} //上传地址，填错/不填上传图片的时候都会报错
                        listType="picture-card" // 上传列表的内建样式
                        fileList={fileList} //已经上传的文件列表（受控）
                        onPreview={handlePreview} //点击文件链接或预览图标时的回调
                        onChange={handleChange} //上传文件改变时的状态
                    >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                    </div>
                </Row>
                <Text type="danger">可以选择图片(图片后缀为.jpg 或 .png)</Text>
                <div style={{ marginLeft: 400 }}>
                    <Button type="primary" onClick={() => uploadImage()}>
                    上传
                    </Button>
                </div>
                </Spin>
            </Modal>
            {/* 图片缩略图对话框 */}
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );

}

export default Index;