import React, { useEffect, useState } from 'react';
import { Input, Typography, Checkbox, message, Modal, Button, Spin, Table, Select, DatePicker, Popconfirm, Radio, Tabs, Popover, Form } from 'antd'
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl';
import NbAxios from '../../config/utils/nbAxios';
import style from './index.module.css'
const { Title, Text, Paragraph } = Typography;
const { Option } = Select
const { TabPane } = Tabs
const { RangePicker } = DatePicker
const { TextArea } = Input;
function Jurisdiction(props) {
    const statusDict = {
        0:'待确认',
        1:'已确认',
        2:'已拒绝',
    }
    // 基础信息选择的id
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 基础信息多选设置
    const onSelect = (record, selected) => {
        let keys = [...selectedRowKeys]
        if (selected) {
            keys = [...selectedRowKeys, record["ID"]]
        } else {
            keys = selectedRowKeys.filter((item) => item !== record["ID"])
        }
        setSelectedRowKeys(keys)
    };
    const onSelectAll = (selected, selectedRows, changeRows) => {
        if (selected) {
            const addCheckedKeys = changeRows.map((item) => {
                return item["ID"]
            })
            setSelectedRowKeys([...selectedRowKeys, ...addCheckedKeys])
        } else {
            const subCheckedKeys = selectedRowKeys.filter((ID) => {
                return !changeRows.some((item) => {
                    return item["ID"] === ID
                })
            })
            setSelectedRowKeys(subCheckedKeys)
        }
    }
    const rowSelection = {
        columnWidth: 10,
        selectedRowKeys,
        onSelect: onSelect,
        onSelectAll: onSelectAll
    }
    // 标签值
    const [tabKey, setTabKey] = useState('management')
    // 类型
    const [calculation_type] = useState(props.history.location.pathname.split('/')[3])
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 表头字段
    const [filed] = useState(['员工ID', '身份证号', '姓名', '性别', '手机号', '邮箱', '生日', '员工类型', '公司', '部门', '项目', '项目参数', '店铺', '岗位', '员工状态', '入职时间', '预计离职时间'])
    // 表头
    const [column, setColumn] = useState([])
    // 数据源
    const [dataSource, setDataSource] = useState([])
    // 转出关系表头字段
    const [transferFiled] = useState(['时间', '员工ID', '员工姓名', '性别', '原负责人', '事件', '状态', '操作'])
    // 表头
    const [transferColumn, setTransferColumn] = useState([])
    // 数据源
    const [transferDataSource, setTransferDataSource] = useState([])
    // bp列表
    const [BPList, setBPList] = useState([])
    // 选中的BP
    const [BPName,setBPName] = useState('')
    // 用户ID
    const [userID, setUserID] = useState('')
    // 转出弹窗
    const [changeModel, setChangeModel] = useState(false)
    // 人员类型列表
    const [staffTypeList, setStaffTypeList] = useState([
        {
            id: 0,
            value: '正式员工'
        },
        // {
        //     id: 1,
        //     value: '离职员工'
        // },
        // {
        //     id: 2,
        //     value: '待入职'
        // },
        {
            id: 3,
            value: '实习生'
        },
        {
            id: 4,
            value: '挂靠'
        },
        {
            id: 5,
            value: '返聘'
        },
        {
            id: 6,
            value: '兼职'
        },
    ])
    // 人员类型列表
    const [staffStateList, setStaffStateList] = useState([
        {
            id: 0,
            value: '离职'
        },
        {
            id: 1,
            value: '在职员工'
        },
        {
            id: 2,
            value: '待入职'
        },
    ])
    useEffect(() => {
        setColumn(filed.map(item => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                // width: item == '数据项' ? "6%" : '',
                // render: (text, record, index) => {
                //     return item.indexOf("月费用") !== -1 ? <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: "center", marginLeft: "20px" }}>
                //         <span>
                //             {record[item]?record[item].toFixed(2):record[item]}
                //         </span>
                //         <span>
                //             {record[item.split("月")[0] + "月编制人数"]?record[item.split("月")[0] + "月编制人数"].toFixed(2):record[item.split("月")[0] + "月编制人数"]}
                //         </span>
                //         <span>
                //             {record[item.split("月")[0] + "月人数"]?record[item.split("月")[0] + "月人数"].toFixed(2):record[item.split("月")[0] + "月人数"]}
                //         </span>
                //     </div> : text
                // }
            }
        }))
        setTransferColumn(transferFiled.map(item => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                // width: item == '数据项' ? "6%" : '',
                render: (text, record, index) => {
                    return item == '操作' ? <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: "center", marginLeft: "20px" }}>
                        <a style={{color:record['状态'] == 1|| record['状态'] == 2 ?'#ccc':'',cursor:record['状态'] == 0?'':'not-allowed'}} onClick={()=>{
                            if(record['状态'] == 0){
                                let dataProps = {
                                    roleId: cookie.load('role'),
                                    shopList: cookie.load('shopList'),
                                    shopId: cookie.load('shopId'),
                                    nb_user_id: cookie.load('id'),
                                    user_name: cookie.load('name'),
                                    employeeId:record['员工ID'],
                                    member_name: record['员工姓名'],
                                    calculation_type:calculation_type,
                                }
                                confirmChangeBp(dataProps)
                            }
                        }}>确认</a>
                        <a style={{color:record['状态'] == 1|| record['状态'] == 2?'#ccc':'red',cursor:record['状态'] == 0?'':'not-allowed'}} onClick={()=>{
                            if(record['状态'] == 0){
                                let dataProps = {
                                    roleId: cookie.load('role'),
                                    shopList: cookie.load('shopList'),
                                    shopId: cookie.load('shopId'),
                                    nb_user_id: cookie.load('id'),
                                    user_name: cookie.load('name'),
                                    employeeId:record['员工ID'],
                                    member_name: record['员工姓名'],
                                    calculation_type:calculation_type,
                                }
                                rejectBp(dataProps)
                            }
                        }}>拒绝</a>
                    </div> : item == '状态'? <span style={{color: text == 2?'red':''}}>{statusDict[text]}</span> :text
                }
            }
        }))
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shopId: cookie.load('shopId'),
            user_name: cookie.load('name'),
            nb_user_id: cookie.load('id'),
            calculation_type:calculation_type,
        }
        const getAllData = async ()=>{
            await Promise.all([
                getBpList(dataProps),
                getBp(dataProps),
                getBpInformation(dataProps),
            ])
        }
        getAllData()  
    }, [])
    // BP列表查询接口
    const getBpList = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.getBpList).then(res => {
            setLoading(false)
            if (res.success) {
                setBPList(res.data.data_list)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('BP列表查询接口出错,请联系IT')
        })
    }
    // BP权限人员查询接口
    const getBp = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.getBp).then(res => {
            setLoading(false)
            if (res.success) {
                setTransferDataSource(res.data.data_list)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('BP权限人员查询接口出错,请联系IT')
        })
    }
    
    // BP权限人员转移接口
    const changeBp = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.changeBp).then(res => {
            setLoading(false)
            if (res.success) {
                message.success('转移成功')
                setChangeModel(false)
                let dataProps = {
                    roleId: cookie.load('role'),
                    shopList: cookie.load('shopList'),
                    shopId: cookie.load('shopId'),
                    user_name: cookie.load('name'),
                    nb_user_id: cookie.load('id'),
                    calculation_type:calculation_type,
                }
                setSelectedRowKeys([])
                getBp(dataProps)
                getBpInformation(dataProps)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('BP权限人员转移接口出错,请联系IT')
        })

    }
    // 权限人员确认接口
    const confirmChangeBp = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.confirmChangeBp).then(res => {
            setLoading(false)
            if (res.success) {
                message.success('确认成功')
                let dataProps = {
                    roleId: cookie.load('role'),
                    shopList: cookie.load('shopList'),
                    shopId: cookie.load('shopId'),
                    user_name: cookie.load('name'),
                    nb_user_id: cookie.load('id'),
                    calculation_type:calculation_type,
                }
                getBp(dataProps)
                getBpInformation(dataProps)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('权限人员确认接口出错,请联系IT')
        })

    }
    // BP权限人员拒绝接口
    const rejectBp = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.rejectBp).then(res => {
            setLoading(false)
            if (res.success) {
                message.success('拒绝成功')
                let dataProps = {
                    roleId: cookie.load('role'),
                    shopList: cookie.load('shopList'),
                    shopId: cookie.load('shopId'),
                    user_name: cookie.load('name'),
                    nb_user_id: cookie.load('id'),
                    calculation_type:calculation_type,
                }
                getBp(dataProps)
                getBpInformation(dataProps)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('BP权限人员拒绝接口出错,请联系IT')
        })

    }
    // 查询用户ID接口
    // const getUserId = (dataProps) => {
    //     setLoading(true)
    //     NbAxios(dataProps, "POST", servicePath.getUserId).then(res => {
    //         setLoading(false)
    //         if (res.success) {
    //             console.log(res.data);
    //         } else {
    //             message.warn(res.errorMsg)
    //         }
    //     }).catch(err => {
    //         setLoading(false)
    //         message.warn('查询用户ID接口出错,请联系IT')
    //     })

    // }
    // BP权限人员基础信息查询接口
    const getBpInformation = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.getBpInformation).then(res => {
            setLoading(false)
            if (res.success) {
                res.data.data_list.map((item,index)=>{
                    item.ID = index
                    staffTypeList.map(obj=>{
                        if(obj.id === item['员工类型']){
                            item['员工类型'] = obj.value
                        }
                    })
                    staffStateList.map(obj=>{
                        if(obj.id === item['员工状态']){
                            item['员工状态'] = obj.value
                        }
                    })

                })
                setDataSource(res.data.data_list)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('BP权限人员基础信息查询接口出错,请联系IT')
        })

    }
    return (
        <div className={style.initial}>
            <Spin spinning={loading} tip="数据加载中....">
                <div className={style.externalContent}>
                    <Tabs type="card" size="small" activeKey={tabKey} onChange={(key) => {
                            setTabKey(key)
                        }}>
                        <TabPane tab={<span>权限管理</span>} key="management"></TabPane>
                    </Tabs>
                    <div style={{display:tabKey !=='management'?'none':''}}>
                        <Table size="small" columns={transferColumn} dataSource={transferDataSource}
                                pagination={false}
                            />
                    </div>
                </div>
                <div className={style.externalContent} style={{ marginTop: '20px',display:tabKey !=='management'?'none':'' }}>
                    <h3>基础信息</h3>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                        <Button size='small' type='primary' style={{ borderRadius: "3px", marginLeft: '20px' }} onClick={()=>{
                            
                            if(selectedRowKeys.length!==0){
                                setChangeModel(true)
                            }else{
                                message.warning('请先勾选员工')
                            }
                        }}>转出</Button>
                    </div>
                    <Table size="small" columns={column} dataSource={dataSource} rowKey={"ID"}
                        scroll={{ x: 2000 }}  rowSelection={rowSelection} 
                    />
                </div>
                <Modal
                title={'人员转出'}
                visible={changeModel}
                onCancel={() => {
                    setChangeModel(false)
                }}
                onOk={() => {
                    if(BPName == ''){
                        message.warning('BP必选')
                        return false
                    }
                    // 获取员工ID
                    let obj = []
                    dataSource.map(item=>{
                        if(selectedRowKeys.indexOf(item.ID)!== -1){
                            obj.push(item['员工ID'])
                        } 
                    })
                    // 去重
                    let uniqueIdList = [...new Set(obj)]
                    let dataProps = {
                        roleId: cookie.load('role'),
                        shopList: cookie.load('shopList'),
                        shopId: cookie.load('shopId'),
                        nb_user_id: cookie.load('id'),
                        user_name: cookie.load('name'),
                        employeeId: uniqueIdList,
                        // member_name: 员工名称.
                        new_user_id: BPName.split(':')[2] ,
                        new_user_name:BPName.split(':')[1].split(' ')[0],
                        calculation_type: calculation_type,

                    }
                    changeBp(dataProps)
                    
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width="600px"
            >
                <div style={{alignItems:'center',display:'flex',justifyContent:'center'}}>
                    <Select showSearch allowClear style={{width:'300px'}} value={BPName} onChange={(value)=>setBPName(value)}>
                        {BPList.map(item=>(
                            <Option value={`BP姓名:${item.user_name} 员工ID:${item.user_id}`}>{item.user_name}</Option>
                        ))}
                    </Select>
                </div>
            </Modal>
            </Spin>
        </div>
    )
}
export default Jurisdiction