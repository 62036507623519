import React, { useState, useEffect } from 'react';
import {Button, Table, Typography, Modal, Form, Select, Col, Row, Radio, Input, message, Spin, Divider, Checkbox} from 'antd'
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl';
import Base64 from 'base-64'
import cookie from 'react-cookies'
import axios from 'axios'
// import InfoHtml from './info.html'
const {Title, Text} = Typography;
const {Option} = Select
function Index(){
    let timer;
    // 来源
    const [ source , setSource] = useState('')
    // 类型
    const [ type , setType] = useState('')
    // 工作地点
    const [ place , setPlace] = useState('')
    // 岗位
    const [ post , setPost] = useState('')
    const [ baseForm ] = Form.useForm()
    // 数据
    const [ data , setData ] = useState([])
    // 详细数据
    const [ detailData , setDetailData ] = useState({})
    // 添加设置
    const [ addDevice , setAddDevice] = useState(false)
    // 添加类型
    const [ addType , setAddType ] = useState('')
    // 是否添加设备
    const [ isAddDevice , setIsAddDevice] = useState(false)
    // 是否点击的确认按钮
    const [ isConfirm , setIsConfirm ] = useState(false)
    // 是否显示创建数据工单
    const [ isAddWork , setIsAddWork ] = useState(false)
    // 是否显示下载
    const [ isShowDownload , setIsShowDownload] = useState(false)
    // 是否在加载
    const [ isSpinning, setIsSpinning ] = useState(false)
    // 是否同意告知书
    const [ isConsentNotice, setIsConsentNotice] = useState(false)
    // 基础信息
    const [ BaseData , setBaseData ] = useState({})
    const [ newBaseData , setNewBaseData ] = useState({})
    const [ oldBaseData , setOldBaseData ] = useState({})
    // 查看详情
    const [ isLookDetail , setIsLookDetail ] = useState(false)
    //计时器
    const [ time , setTime ] = useState(0)
    // 表格表头
    const columns = [
        {
            title:'类型',
            key:'type',
            dataIndex:'type',
            align: 'center',
        },
        {
            title:'来源',
            key:'source',
            dataIndex:'source',
            align: 'center',
        },
        {
            title:'补助金额',
            key:'subsidy',
            dataIndex:'subsidy',
            align: 'center',
        },
        {
            title:'补助解释',
            key:'reason',
            dataIndex:'reason',
            align: 'center',
        },
        {
            title:'查看详情',
            key:'details',
            dataIndex:'details',
            align: 'center',
            render: (text, record, index) => (
                <a onClick={()=>{
                    setIsLookDetail(true)
                    setBaseData(record)
                }}>查看详情</a>
            )
        },
    ]

    useEffect(()=>{
        getDevice()
    },[])

    // 获取设备信息
    const getDevice =()=>{
        let dataProps = {
            "email":Base64.decode(cookie.load('email'))
        }
        NbAxios(dataProps, "POST", servicePath.getDevice).then(res => {
            if(res.success){
                setData(res.data)
            }
        })
    }

     //定时任务
     // 1000ms 等于 1s
     useEffect(()=>{
        //  当开始的时候，开始进行计时
        console.log(time);
        if(time > 0){
            console.log("定时器执行中");
            setTimeout(()=>{
                // Object.keys(detailData).length != 0
                if(Object.keys(newBaseData).length == 0){
                    // 这里去请求接口
                    getPcBaseInfo()
                    setTime(time+1)
                }
            },1000 * 4 )
        }
    },[time])

    // 确认基本信息
    const confirmBase = (value)=>{
        setIsConfirm(true)
        if(value.type == '台式'){
            setIsAddWork(true)
            message.warn('暂时台式配置标准，请联系IT帮忙解决')
        // value.post == 'it' || value.post == '客服' || value.post == '视觉'
        }else if( value.post == '仓库'){
            setIsAddWork(true)
            message.warn('暂时未获取该岗位配置标准，请联系IT帮忙解决')
        }else{
            setTime(1)
            setIsSpinning(true)
            setIsAddWork(false)
            setIsShowDownload(true)
            // getCalculateComputerSubsidyAmount()
           
        }
    }


    // 获取电脑基础信息
    const getPcBaseInfo = ()=>{
        let dataProps = {
            "email":Base64.decode(cookie.load('email'))
        }
        NbAxios(dataProps, "POST", servicePath.getPcBaseInfo).then(res => {
            if(res.success){
                // 暂停定时器
                setTime(0)
                setNewBaseData(res.data['new']);
                setOldBaseData(res.data['old']);
                getCalculateComputerSubsidyAmount(res.data['new'])
            }
        })
    }

    // 获取电脑补助金额
    const getCalculateComputerSubsidyAmount = (dataProps)=>{
        dataProps.post = post
        NbAxios(dataProps, "POST", servicePath.calculateComputerSubsidyAmount).then(res => {
            setIsSpinning(false)
            if(res.success){
                if(res.data['message'] == undefined){
                    setDetailData(res.data)
                }else{
                    message.warn(res.data['message'])
                    setIsAddWork(true)
                }
            }else{
                message.warn(res.errorMsg)
            }
        })
    }

    // 添加电脑设备补助
    const addPcSubsidy = ()=>{
        let dataProps = detailData
        dataProps.new_pc_base_id = newBaseData.id
        dataProps.email = Base64.decode(cookie.load('email'))
        dataProps.name =cookie.load('name')
        dataProps.source = source
        dataProps.type = type
        dataProps.place = place
        dataProps.post = post
        if(oldBaseData.id == undefined){
            dataProps.old_pc_base_id = ''
        }else{
            dataProps.old_pc_base_id = oldBaseData.id
        }
        NbAxios(dataProps, "POST", servicePath.addPcSubsidy).then(res => {
            if(res.success){
               getDevice()
               Modal.info({
                centered:'true',
                title: '数据已上传，请在薪人薪事提交自带电脑补助申请。',
                width:500,
                okText:"朕已了解",
                onOk() {},
              })
               baseForm.resetFields()
               setAddType('')
               setDetailData({})
               setNewBaseData({})
               setOldBaseData({})
               setIsConfirm(false)
               setAddDevice(false)
               setIsSpinning(false)
               setIsConsentNotice(false)
               setIsAddDevice(false)
               setIsShowDownload(false)
            }else{
                message.warn(res.errorMsg)
            }
        })
    }

    // 取消添加此设备
    const cancelDevice = ()=>{
        let dataProps = newBaseData
        dataProps.new_pc_base_id = newBaseData.id
        NbAxios(dataProps, "POST", servicePath.cancelDevice).then(res => {
            if(res.success){
                message.success('成功取消添加设备')
                baseForm.resetFields()
                setAddType('')
                setDetailData({})
                setNewBaseData({})
                setOldBaseData({})
                setIsConfirm(false)
                setAddDevice(false)
                setIsSpinning(false)
                setIsShowDownload(false)
            }else{
                message.warn(res.errorMsg)
            }
        })
    }

    // 创建数据工单
    const createWork = ()=>{
         // 上传图片
        // 上传原始文件例子
        const formData = new FormData()
        // 多个图片
        formData.append('imageFile', []);
        // 多个文件
        formData.append('fileList', [])
        // 任务状态
        formData.append('status', 1)
        // 标题
        formData.append('title', "添加设备")
        // 优先级
        formData.append('priority', "紧急")
        // 类型
        formData.append('type', "IT工单")
        // 内容
        formData.append('content', "未获取设备配置标准，请IT部门帮忙")
        // 创建人
        formData.append('creator', cookie.load('name'))
        // 创建人工号
        formData.append('number', Base64.decode(cookie.load('number')))
        // 处理人名称
        formData.append('acceptor', '唐铎')
        // 处理人工号
        formData.append('acceptorNumber', '1804100055')
        axios({
            method: 'POST',
            url: servicePath.createWorkOrder,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res=>{
                if (res.data['success']) {
                    message.success("工单创建成功")
                    baseForm.resetFields()
                    setAddDevice(false)
                }else{
                    message.error(res.data['errorMsg'])
                }
            }
        )
    }

    return (
        <div style={{backgroundColor: "#fff"}}>
            <div style={{
                position: 'fixed',
                top: 40,
                zIndex: 99,
                display: 'flex',
                justifyContent: "space-around",
                borderBottom:"1px solid #ccc",
                width:'100vw'
            }}>
                <Title level={2}>我的设备</Title>
                <div style={{display:'flex'}}>
                    <Button type="primary" style={{marginTop:10}} onClick={()=>{
                        setNewBaseData({})
                        setType('')
                        setPlace('')
                        setPost('')
                        setSource('')
                        setAddDevice(true)
                    }}>添加设备</Button>
                    <a href="https://pic.bmcsoft.cn/imge_dir/090917352733/090917352733.html" target="_Blank" style={{marginTop:10}}><Button type="link">操作手册</Button></a>
                </div>
            </div>
            <div style={{paddingTop:20,paddingLeft:10, paddingRight:10, marginTop:70}}>
                <Table columns={columns} dataSource={data} pagination={false} />
            </div>
            <Modal
                title="添加设备"
                centered={true}
                visible={addDevice}
                footer={null}
                onCancel={()=>{
                    baseForm.resetFields()
                    if(newBaseData.id != undefined){
                        cancelDevice()
                    }else{
                        // 暂停定时器
                        setTime(0)
                        setNewBaseData({'a':1111});
                        setAddType('')
                        setDetailData({})
                        setOldBaseData({})
                        setIsConfirm(false)
                        setAddDevice(false)
                        setIsSpinning(false)
                        setIsShowDownload(false)
                    }
                    
                }}
                width={1200}
                maskClosable={false}
            >
                {/* 选择是自带还是公司提供 */}
                {/* pointerEvents: shareData[index]['result'] == '成功' ? 'none' : '' */}
                <div style={{marginLeft:20, marginBottom:20,  pointerEvents: isConfirm ? 'none' : ''}}>
                    <Radio.Group onChange={(e)=>{
                        setAddType(e.target.value);
                        setSource(e.target.value)
                        if(e.target.value == '公司提供'){
                            setIsShowDownload(true)
                        }else{
                            setIsShowDownload(false)
                        }
                    }} value={addType}>
                        {/* <Radio value={"公司提供"}>公司提供</Radio> */}
                        <Radio value={"自带设备"}>自带设备</Radio>
                    </Radio.Group>
                </div>
                {/* 自带电脑填写的数据 */}
                <div style={{display:addType == '自带设备' ? '' : 'none', pointerEvents: isConfirm ? 'none' : ''}}>
                    <Form form={baseForm} onFinish={confirmBase}>
                        <Row>
                            <Col span={7}>
                                <Form.Item label="工作地点" name="place">
                                    <Select style={{width:150}} onSearch onChange={(value)=>{
                                        setPlace(value);
                                    }}>
                                        <Option value="嘉兴">嘉兴</Option>
                                        <Option value="杭州">杭州</Option>
                                        <Option value="上海">上海</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item label="岗位" name="post" >
                                    <Select style={{width:150}} onSearch onChange={(value)=>{
                                        setPost(value);
                                    }}>
                                        <Option value="业务">业务</Option>
                                        <Option value="财务">财务</Option>
                                        <Option value="行政">行政</Option>
                                        <Option value="人资">人资</Option>
                                        <Option value="视觉">视觉</Option>
                                        <Option value="客服">客服</Option>
                                        <Option value="仓库">仓库</Option>
                                        <Option value="IT">IT</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item label="类型" name="type">
                                    <Select style={{width:150}} onSearch onChange={(value)=>{
                                        setType(value);
                                    }}>
                                        <Option value="笔记本">笔记本</Option>
                                        <Option value="台式">台式</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                               <Button type="primary" htmlType="submit" style={{display:isConfirm ? 'none' : ''}}>确定</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {/* 公司提供 */}
                <div style={{display:addType == '公司提供' ? '' : 'none'}}>
                    <Form >
                        <div>
                            <Form.Item label="资产编码" name="coding">
                                <Input style={{width:150}} />
                            </Form.Item>      
                        </div>
                    </Form>
                </div>
                {/* 下载提取电脑基本信息小程序 */}
                <div style={{display: isShowDownload ? '' : 'none'}}>
                    <div style={{display:'flex'}}>
                        <b>提取电脑基本信息小程序: </b>
                        <a style={{marginLeft:20, fontSize:15}} href="https://pic.bmcsoft.cn/imge_dir/get_configuration/get_configuration_for_windows.zip" target="_Blank">windows</a>
                        <div style={{marginLeft:10, marginRight:10}}>|</div>
                        <a style={{fontSize:15}} href="https://pic.bmcsoft.cn/imge_dir/get_configuration/get_configuration_for_mac.zip" target="_Blank">macOS</a>
                    </div>
                    <div style={{display:'flex',  marginTop:10}}>
                        <div style={{width:600, borderRight:"1px solid #ccc", display: addType == '公司提供' ? "none" : 'flex', flexDirection:'column', alignItems:'center' }}>
                            <Title level={4} style={{textAlign:'center'}}>基准配置</Title>
                            <div style={{display:'flex', justifyContent:"space-between", fontSize:15, width:200}}>
                                <b style={{marginRight:20}}>CPU:</b>
                                <div>i5-1135G7</div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:200}}>
                                <b style={{marginRight:20}}>内存:</b>
                                <div>8GB</div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:200}}>
                                <b style={{marginRight:20}}>硬盘:</b>
                                <div>512GB</div>
                            </div>
                        </div>
                        <div style={{width: addType == '公司提供' ? 1200 : 600 ,display:'flex', flexDirection:'column', alignItems:'center' }}>
                            <Spin spinning={isSpinning} tip="请执行提取电脑基本信息小程序并且等待数据计算.....">
                                <Title level={4} style={{textAlign:'center'}}>您的配置</Title>
                                <div style={{display:'flex', justifyContent:"space-between", fontSize:15, width:400}}>
                                    <b style={{marginRight:20}}>CPU:</b>
                                    <div>{detailData.cpu}</div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:400}}>
                                    <b style={{marginRight:20}}>内存:</b>
                                    <div>{detailData.ram}GB</div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:400}}>
                                    <b style={{marginRight:20}}>硬盘:</b>
                                    <div>{detailData.disk}GB</div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:400}}>
                                    <b style={{marginRight:20}}>有线物理地址:</b>
                                    <div>{detailData.wired_physical_address}</div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:400}}>
                                    <b style={{marginRight:20}}>无线物理地址:</b>
                                    <div>{detailData.wireless_physical_address}</div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:400}}>
                                    <b style={{marginRight:20}}>操作系统版本:</b>
                                    <div>{detailData.operating_system_version}</div>
                                </div>
                            </Spin>
                        </div>
                    </div>
                </div>
                <div style={{display: Object.keys(detailData).length != 0 ? 'flex' : 'none', justifyContent:'center', marginTop:50}}>
                    {/* <Text type="danger"><b >初始补助金额预计:</b><span >{detailData.subsidy}元，后续流程中IT会人工再次核实，如有变化，会另行通知。</span></Text> */}
                    <Text type="danger">{`您的岗位为“${post}”,补助档位为[${detailData.subsidy_gradient_list}]`}</Text>
                </div>
                <div style={{display: Object.keys(detailData).length != 0 ? 'flex' : 'none', justifyContent:'center', marginTop:5}}>
                    {/* <Text type="danger"><b >初始补助金额预计:</b><span >{detailData.subsidy}元，后续流程中IT会人工再次核实，如有变化，会另行通知。</span></Text> */}
                    <Text type="danger">{detailData.reason == '' ? `您的上传配置符合标准；` + `故初始补助金额预计为${detailData.subsidy}元，后续流程中IT会人工再次核实，如有变化，会另行通知。`: `但是由于上传配置中：${detailData.reason}` + `故初始补助金额预计为${detailData.subsidy}元，后续流程中IT会人工再次核实，如有变化，会另行通知。`}</Text>
                </div>
                {
                    Object.keys(oldBaseData).length != 0 ? <div>
                        <Divider />
                        <Title style={{marginRight:20}} level={5}>历史设备信息:</Title>
                        <div style={{display:'flex', marginTop:10}}>
                            <Row style={{width:1200}}>
                                <Col span={8} style={{display:'flex'}}>
                                    <b >CPU:</b>
                                    <div style={{marginLeft:10}}>{oldBaseData.cpu}</div>
                                </Col>
                                <Col span={8} style={{display:'flex'}}>
                                    <b >内存:</b>
                                    <div style={{marginLeft:10}}>{oldBaseData.ram}GB</div>
                                </Col>
                                <Col span={8} style={{display:'flex'}}>
                                    <b>有线物理地址:</b>
                                    <div style={{marginLeft:10}}>{oldBaseData.wired_physical_address}</div>
                                </Col>
                            </Row>
                        </div>
                        <div style={{display:'flex' ,marginTop:5}}>
                            <Row style={{width:1200}}>
                                <Col span={8} style={{display:'flex'}}>
                                    <b >硬盘:</b>
                                    <div style={{marginLeft:10}}>{oldBaseData.disk}GB</div>
                                </Col>
                                <Col span={8} style={{display:'flex'}}>
                                    <b >操作系统版本:</b>
                                    <div style={{marginLeft:10}}>{oldBaseData.operating_system_version}</div>
                                </Col>
                                <Col span={8} style={{display:'flex'}}>
                                    <b>无线物理地址:</b>
                                    <div style={{marginLeft:10}}>{oldBaseData.wireless_physical_address}</div>
                                </Col>
                            </Row>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', marginTop:10}}>
                            <Text type="danger"><b >当前补助金额:</b><span >{oldBaseData.subsidy}元</span></Text>
                        </div>
                    </div>: <span></span>
                }
                
                <div style={{display: Object.keys(detailData).length != 0 ? 'flex' : 'none', justifyContent:'space-around', marginTop:50}}>
                    <Button type="primary" onClick={()=>setIsAddDevice(true)}>确认添加此设备并提交申请</Button>
                    <Button type="primary" danger onClick={()=>cancelDevice()}>取消添加设备</Button>
                </div>
                <div style={{display: isAddWork ? 'flex' : 'none', justifyContent:'center', marginTop:50}}>
                    <Button type="primary" danger onClick={()=>createWork()}>联系IT解决</Button>
                </div>
            </Modal>
            <Modal
                title="用户告知书"
                centered={true}
                visible={isAddDevice}
                onCancel={()=>setIsAddDevice(false)}
                footer={[]}
                width={500}
            >
                <div style={{display:'flex', justifyContent:'center'}}>
                    <Text type="danger" strong style={{marginBottom:10}}>您的补助金额预计为{detailData.subsidy}元，后续流程中IT会人工再次核实，如有变化，会另行通知。</Text>
                </div>
                <div style={{height:400, overflow:'auto'}}>
                    <iframe
                        src="https://pic.bmcsoft.cn/imge_dir/info.html"
                        align="center"
                        style={{ width: 400, height: 380 }}> </iframe>
                </div>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <Checkbox onChange={(e)=>setIsConsentNotice(e.target.checked)}>朕已阅</Checkbox>
                </div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:10}}>
                    <Button type="primary" disabled={isConsentNotice?false:true} onClick={()=>addPcSubsidy()}>确认</Button>
                    <Button type="primary" danger onClick={()=>setIsAddDevice(false)}>再考虑一下</Button>
                </div>
            </Modal>

            <Modal
                visible={isLookDetail}
                onCancel={()=>{
                    setBaseData({})
                    setIsLookDetail(false)
                }}
                footer={null}
                centered={true}
            >
                 <Title level={4} style={{textAlign:'center'}}>您的配置</Title>
                <div style={{display:'flex', justifyContent:"space-between", fontSize:15, width:400}}>
                    <b style={{marginRight:20}}>CPU:</b>
                    <div>{BaseData.cpu}</div>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:400}}>
                    <b style={{marginRight:20}}>内存:</b>
                    <div>{BaseData.ram}GB</div>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:400}}>
                    <b style={{marginRight:20}}>硬盘:</b>
                    <div>{BaseData.disk}GB</div>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:400}}>
                    <b style={{marginRight:20}}>有线物理地址:</b>
                    <div>{BaseData.wired_physical_address}</div>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:400}}>
                    <b style={{marginRight:20}}>无线物理地址:</b>
                    <div>{BaseData.wireless_physical_address}</div>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', fontSize:15, marginTop:10, width:400}}>
                    <b style={{marginRight:20}}>操作系统版本:</b>
                    <div>{BaseData.operating_system_version}</div>
                </div>
            </Modal>
        </div>
    )

}


export default Index