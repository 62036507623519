import React, { useContext, useState, useEffect } from 'react';
import { StateContext } from '../../raceShop/store/stateShare';
import style from './interview.module.css';
import { Button, Row, Col, Tabs, Table, message, Tooltip, Typography, Form, Modal, Input, Spin } from 'antd';
import servicePath from '../../../config/apiUrl';
import cookie from 'react-cookies';
import Axios from 'axios';
import NbAxios from "../../../config/utils/nbAxios";
import DownloadFile from "../../../config/utils/downloadFile"
import moment from 'moment';
const { TabPane } = Tabs
const { Paragraph, Title } = Typography
function InterView() {
    // 绑定面试详情表单
    const [detailForm] = Form.useForm()
    // 绑定表单数据
    const [interviewForm] = Form.useForm()
    // 搜索表单
    const [searchForm] = Form.useForm()
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    // 按钮是否置灰
    const [buttonDisable, setButtonDisable] = useState(true)
    // 添加地点信息的弹框是否打开
    const [placeModal, setPlaceModal] = useState(false)
    // 面试地点
    const [interviewPlace, setInterviewPlace] = useState('')
    // 面试状态
    const [interviewStatus, setInterviewStatus] = useState(1)
    // 面试通过状态
    const [interviewSuccess, setInterviewSuccess] = useState(1)
    // 表格中的数据
    const [tableData, setTableData] = useState([])
    // 表格数据加载状态
    const [tableLoading, setTableLoading] = useState(false)
    // 面试未通过原因
    const [interviewDetail, setInterviewDetail] = useState('')
    // 是否可以修改面试详情
    const [canUpdateDetail, setCanUpdateDetail] = useState(false)
    // 确认未通过人员模态框开关
    const [notPass, setNoPass] = useState(false)
    // 修改面试详情模态框开关
    const [detailModal, setDetailModal] = useState(false)
    // 状态字典
    const statusDict = {
        1: '简历筛选不通过',
        2: '面试不通过',
        3: 'offer未接受',
        4: '未入职',
        5: '未通过',
    }
    // 页码
    const [page, setPage] = useState(1)
    // 页面长度
    const [pageSize, setPageSize] = useState(10)
    // 数据总长度
    const [total, setTotal] = useState(10)
    //定义分页器的参数
    const paginationProps = {
        showSizeChanger: false,
        showQuickJumper: true,
        // 总页数
        total: total,
        //页码改变时，设置当前页码和每页显示条数
        current: page,
        onChange: (page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
        },
    }
    //定义选中的行数
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 此函数selectedRowKey为一个数组，需要取最后一个元素才能获得当前点击行
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    //行选择器的参数
    const rowSelection = {
        //隐藏选择所有行
        hideSelectAll: false,
        fixed: true,
        //选中的行数
        selectedRowKeys: selectedRowKeys,
        //行数变化的回调
        onChange: onSelectChange,
    }
    // 表格列配置
    const columns = [
        {
            title: '名字',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            align: 'center',
            width: 80,
        },
        {
            title: '应聘职位',
            dataIndex: 'position_name',
            width: 100,
            fixed: 'left',
            key: 'position_name',
            align: 'center',
        },
        {
            title: '应聘计划',
            dataIndex: 'programme_name',
            width: 120,
            fixed: 'left',
            key: 'programme_name',
            align: 'center',
        },
        {
            title: interviewStatus === 2 ? '面试地点' : '',
            dataIndex: interviewStatus === 2 ? 'interview_place' : '',
            width: interviewStatus === 2 ? 120 : 0,
            fixed: 'left',
            key: interviewStatus === 2 ? 'interview_place' : '',
            align: 'center',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
            width: 200,
        },
        {
            title: '性别',
            dataIndex: 'gender',
            key: 'gender',
            width: 80,
            align: 'center',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
            width: 160,
            align: 'center',
            render: (text, record, index) =>
                <Paragraph copyable={{ tooltips: true }} title={text} placement='topLeft' style={{ margin: 0 }} >
                    {text}
                </Paragraph>
        },
        {
            title: '家庭所在地',
            dataIndex: 'address',
            key: 'address',
            width: 150,
            align: 'center',
        },
        {
            title: '学历',
            dataIndex: 'education',
            key: 'education',
            width: 120,
            align: 'center',
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: '学校',
            dataIndex: 'school',
            key: 'school',
            width: 150,
            align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (text, record, index) =>
                <Tooltip title={text} placement='topLeft'>
                    {text}
                </Tooltip>
        },
        {
            title: '专业',
            dataIndex: 'major',
            width: 150,
            key: 'major',
            align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (text, record, index) =>
                <Tooltip title={text} placement='topLeft'>
                    {text}
                </Tooltip>
        },
        {
            title: '毕业时间',
            dataIndex: 'education_end_time',
            width: 130,
            key: 'education_end_time',
            align: 'center',
            render: (item, record, index) => {
                if (item) {
                    return (
                        <div>{moment(item, 'YYYY-MM-DD').format('YYYY年MM月')}</div>
                    )
                }
            }
        },
        {
            title: interviewStatus === 5 ? '不通过原因' : '',
            dataIndex: interviewStatus === 5 ? 'interview_details' : '',
            width: interviewStatus === 5 ? 200 : 0,
            fixed: 'right',
            key: interviewStatus === 5 ? 'interview_details' : '',
            align: 'center',
        },
        // {
        //     title: interviewStatus === 2 ? '面试详情' : '',
        //     dataIndex: interviewStatus === 2 ? 'interview_details' : '',
        //     width: interviewStatus === 2 ? 200 : 0,
        //     fixed: 'right',
        //     key: interviewStatus === 2 ? 'interview_details' : '',
        //     align: 'center',
        // },
        {
            title: '简历',
            dataIndex: 'resume',
            width: 80,
            key: 'resume',
            align: 'center',
            fixed: 'right',
            render: (item, record, index) => {
                return (
                    <Button type="link" onClick={() => getPeopleResume(record.id)} >下载</Button>
                )
            }
        }
    ]

    useEffect(() => {
        getInterviewMans()
    }, [interviewStatus, page, pageSize])

    useEffect(() => {
        if (interviewStatus === 5 || interviewStatus === 4) {
            setButtonDisable(true)
        } else {
            if (selectedRowKeys.length > 0) {
                setButtonDisable(false)
            } else {
                setButtonDisable(true)
            }
        }
        if (interviewStatus === 5 || interviewStatus === 2) {
            selectedRowKeys.length === 1 ? setCanUpdateDetail(false) : setCanUpdateDetail(true)
        } else {
            setCanUpdateDetail(true)
        }
    }, [selectedRowKeys, interviewStatus])
    return (
        <div className={style.InterViewIndexWapper} >
            <div className={style.searchArea} >
                <div className={style.controlBar}>
                    <div className={style.controlBarLeft}>
                        <Title level={3} style={{ margin: 0 }} >面试人员管理</Title>
                    </div>
                    <div className={style.controlBarRight}>

                    </div>
                </div>
                <div className={style.searchFrom} >
                    <Form
                        form={searchForm}
                    >
                        <Row gutter={8} >
                            <Col span={6}>
                                <Form.Item
                                    label="姓名"
                                    name='name'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="招聘职位"
                                    name='position_name'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="招聘计划"
                                    name='programme_name'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="邮箱"
                                    name='email'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8} >
                            <Col span={6}>
                                <Form.Item
                                    label="性别"
                                    name='gender'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="手机号"
                                    name='phone'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="家庭所在地"
                                    name='address'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="学历"
                                    name='education'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8} >
                            <Col span={6}>
                                <Form.Item
                                    label="学校"
                                    name='school'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="专业"
                                    name='major'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }}  />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="毕业时间"
                                    name='education_end_time'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={()=>{
                                        if(page == 1){
                                            getInterviewMans()
                                        }else{
                                            setPage(1)
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div className={style.controllButtons}>
                        <Button type="primary" disabled={buttonDisable} onClick={interviewPass} >通过</Button>
                        <Button danger type="primary" disabled={buttonDisable} onClick={interviewFailed} >不通过</Button>
                        <Button type="primary" disabled={canUpdateDetail} onClick={openDetailModal}  >{"修改未通过原因"}</Button>
                        <Button type="primary" style={{justifySelf:"flex-end"}}  onClick={()=>{
                            if(page == 1){
                                getInterviewMans()
                            }else{
                                setPage(1)
                            }
                        }} >查询</Button>
                        <Button   style={{justifySelf:"flex-end"}}  onClick={()=>{
                            searchForm.resetFields()
                            if(page == 1){
                                getInterviewMans()
                            }else{
                                setPage(1)
                            }
                            // getInterviewMans()
                        }} >重置</Button>
                    </div>
                </div>
            </div>
            <div className={style.tabsArea}>
                <Tabs type="card" defaultActiveKey={interviewStatus} style={{ height: 40 }} onChange={tabChange}>
                    <TabPane tab={'简历筛选'} key={1}></TabPane>
                    <TabPane tab={'面试中'} key={2}></TabPane>
                    <TabPane tab={'已发offer'} key={3}></TabPane>
                    <TabPane tab={'已入职'} key={4}></TabPane>
                    <TabPane tab={'未通过'} key={5}></TabPane>
                </Tabs>
            </div>
            <div className={style.interViewTablesArea}>
                <Spin spinning={tableLoading} tip="面试人员数据加载中..." >
                    <Table
                        rowKey={record => record.id}
                        rowSelection={rowSelection}
                        pagination={paginationProps}
                        columns={columns}
                        dataSource={tableData}
                        scroll={{ x: 1400 }}
                    />
                </Spin>
                <Modal title={interviewStatus === 1 ? "添加面试信息" : "请确认人员"} visible={placeModal} onOk={InterviewModalOk} onCancel={closedInterviewModal} >
                    <Form
                        form={interviewForm}
                    >
                        {
                            (interviewStatus === 1) &&
                            <Form.Item
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 15 }}
                                label="面试地点"
                                name={'interviewPlace'}
                            >
                                <Input value={interviewPlace} onChange={placeChange} />
                            </Form.Item>
                        }
                        <Form.Item
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 15 }}
                            label="人员"
                            name={'people'}
                        >
                            <div>
                                {
                                    tableData.filter((item, index) => {
                                        if (selectedRowKeys.indexOf(item.id) !== -1) {
                                            return true
                                        }
                                    }).map((item, index) => {
                                        return (
                                            <span  key={item.uuid+item.position_name} style={{ margin: 5 }} >{item.name}</span>
                                        )
                                    })
                                }
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title={interviewStatus === 1 ? "添加面试信息" : "请确认人员"} visible={placeModal} onOk={InterviewModalOk} onCancel={closedInterviewModal} >
                    <Form
                        form={interviewForm}
                    >
                        {
                            (interviewStatus === 1) &&
                            <Form.Item
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 15 }}
                                label="面试地点"
                                name={'interviewPlace'}
                            >
                                <Input value={interviewPlace} onChange={placeChange} />
                            </Form.Item>
                        }
                        <Form.Item
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 15 }}
                            label="人员"
                            name={'people'}
                        >
                            <div>
                                {
                                    tableData.filter((item, index) => {
                                        if (selectedRowKeys.indexOf(item.id) !== -1) {
                                            return true
                                        }
                                    }).map((item, index) => {
                                        return (
                                            <span key={item.uuid+item.position_name} style={{ margin: 5 }} >{item.name}</span>
                                        )
                                    })
                                }
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title={"请确认人员"} visible={notPass} onOk={interviewStatusFailed} onCancel={()=>{setNoPass(false)}} >
                    <Form
                    >
                        <Form.Item
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 15 }}
                            label="人员"
                            name={'people'}
                        >
                            <div>
                                {
                                    tableData.filter((item, index) => {
                                        if (selectedRowKeys.indexOf(item.id) !== -1) {
                                            return true
                                        }
                                    }).map((item, index) => {
                                        return (
                                            <span key={item.uuid+item.position_name} style={{ margin: 5 }} >{item.name}</span>
                                        )
                                    })
                                }
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title={"修改面试失败原因"} visible={detailModal} onOk={updateInterviewDetail} onCancel={ClosedDetail} >
                    <Form
                        form={detailForm}
                    >
                        <Form.Item
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 15 }}
                            label="面试详情"
                            name={'detail'}
                        >
                            <Input defaultValue={interviewDetail} value={interviewDetail} onChange={changeDetail} />
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 15 }}
                            label="人员"
                            name={'people'}
                        >
                            <div>
                                {
                                    tableData.filter((item, index) => {
                                        if (selectedRowKeys.indexOf(item.id) !== -1) {
                                            return true
                                        }
                                    }).map((item, index) => {
                                        return (
                                            <span key={item.uuid+item.position_name} style={{ margin: 5 }} >{item.name}</span>
                                        )
                                    })
                                }
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    )
    // 打开修改面试详情的弹框
    async function openDetailModal() {
        let man = tableData.filter((item, index) => {
            if (selectedRowKeys.indexOf(item.id) !== -1) {
                return true
            }
        })
        detailForm.setFieldsValue({ 'detail': man[0].interview_details })
        setDetailModal(true)
    }

    // 关闭面试详情框
    function ClosedDetail() {
        setDetailModal(false)
        setInterviewDetail('')
    }
    // 面试详情变化
    function changeDetail(e) {
        let value = e.target.value
        if (value === '') {
            message.warn('请填写字段内容！')
            return false
        }
        setInterviewDetail(value)
    }

    // 点击确认修改面试详情按钮
    function updateInterviewDetail() {
        let data = {}
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.id = selectedRowKeys[0]
        if (interviewDetail === '') {
            message.warn('请填写字段内容！')
            return false
        }
        data.interview_details = interviewDetail
        NbAxios(data, 'POST', servicePath.updateRecruitInterviewManagement).then(res => {
            if (res.success) {
                message.success('面试详情修改成功！')
                getInterviewMans()
                setDetailModal(false)
                setInterviewDetail('')
            } else {
                message.warn(res.errorMsg)
            }
        }
        ).catch(e => {
            message.error('出现错误！')
        })

    }

    // 关闭添加面试信息弹窗
    function closedInterviewModal() {
        setInterviewPlace('')
        setPlaceModal(false)
    }

    // 面试信息弹窗点击ok按钮
    async function InterviewModalOk() {
        let value = interviewForm.getFieldsValue()
        if (value.interviewPlace === '') {
            message.warn('面试地点不可以为空！')
            return false
        }
        let a = await interviewStatusSuccess()
        if (a) {
            closedInterviewModal()
            getInterviewMans()
        }
    }

    // 面试地点改变时
    function placeChange(e) {
        let value = e.target.value
        if (value === '') {
            message.warn('面试地点不可以为空！')
            return false
        }
        setInterviewPlace(value)
    }

    // 点击通过按钮调用的函数
    function interviewPass() {
        if (interviewStatus === 1) {
            setPlaceModal(true)
        } else {
            if (1 < interviewStatus < 4) {
                setPlaceModal(true)
                // let a = await interviewStatusSuccess()
                // if (a) {
                //     getInterviewMans()
                // }
            }
        }
    }

    // 点击不通过按钮调用的函数
    function interviewFailed() {
        setNoPass(true)
    }

    // 面试进入下一阶段
    function interviewStatusSuccess() {
        let data = {}
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.id_list = selectedRowKeys
        if (interviewStatus === 1) {
            data.interview_place = interviewPlace
        }
        data.interview_status = parseInt(interviewStatus) + 1
        return new Promise((resolve, reject) => {
            NbAxios(data, 'POST', servicePath.adoptRecruitInterviewManagement).then(res => {
                if (res.success) {
                    message.success('修改人员状态成功')
                    resolve(true)
                } else {
                    message.warn(res.errorMsg)
                    reject(false)
                }
            }
            ).catch(e => {
                message.error('出现错误！')
                reject(false)
            })
        })
    }

    // 面试未通过接口
    function interviewStatusFailed() {
        let data = {}
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.id_list = selectedRowKeys
        data.interview_details = statusDict[interviewStatus]
        data.interview_success_status = 0
        NbAxios(data, 'POST', servicePath.failedRecruitInterviewManagement).then(res => {
            if (res.success) {
                message.success('人员状态已修改为未通过')
                getInterviewMans()
                setNoPass(false)
            } else {
                message.warn(res.errorMsg)
            }
        }
        ).catch(e => {
            setNoPass(false)
            message.error('出现错误！')
        })
    }

    // 标签变化
    function tabChange(value) {
        let status = parseInt(value)
        if (status === 5) {
            setInterviewSuccess(0)
        } else {
            setInterviewSuccess(1)
        }
        setInterviewStatus(status);
        setSelectedRowKeys([])
    }

    // 获取表格面试人员数据
    function getInterviewMans() {
        // setPage(1)
        let data = {}
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.interview_success_status = interviewSuccess
        if (interviewSuccess !== 0) {
            data.interview_status = interviewStatus
        }
        data.page = page
        data.page_size = pageSize
        let searchData = searchForm.getFieldsValue()
        for(let key in searchData){
            if(searchData[key]){
                // 去空格
                data[key] = searchData[key].replace(/\s+/g,"")
            }
        }
        setTableLoading(true)
        NbAxios(data, 'POST', servicePath.getRecruitInterviewManagement).then(res => {
            if (res.success) {
                let data = res.data
                let total = res.total
                setTotal(total)
                setTableData(data)
            } else {
                message.warn(res.errorMsg)
            }
            setTableLoading(false)
        }).catch(e => {
            message.error('获取面试人员名单出错！')
            setTableLoading(false)
        })
    }

    // 获取面试人员简历
    function getPeopleResume(peopleId) {
        let data = {}
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.uuid = peopleId
        data.principal_uuid = cookie.load('id')
        Axios({
            method: 'POST',
            url: servicePath.downloadResume,
            data: data,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
        }).then(res=>{
            DownloadFile(res)
        })
    }
}

export default InterView;