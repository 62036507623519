import React, { useState, useEffect } from 'react';
import style from './index.module.css';
import { Button, Typography, Select, Input, DatePicker, message, Table, Modal, Upload } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { InboxOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment'
import cookie from 'react-cookies'
import NbAxios from '../../../../config/utils/nbAxios';
import servicePath from '../../../../config/apiUrl';
import ModulLogo from '../static/image/logo.png'
const { Title, Text } = Typography;
const { Option } = Select
const { RangePicker } = DatePicker
const { Search } = Input
// 文件上传组建
const { Dragger } = Upload;
// 日期格式
const dateFormat = 'YYYY-MM-DD'
function Index(props) {
    let filedDict = {
        '款号':'spu',
        '创建时间':'order_create_time',
        '支付时间':'order_pay_time',
        '联系电话':'lxsj',
        '省份':'province',
        '城市':'city',
        '地域':'area',
        "城市等级":"level",
        "支付件数":"count_of_goods",
        "支付金额":"amount_of_real_pay",
        '序号':"index"
    }
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'qda': '2715f3fa-0dae-4dc7-bcfc-0dd912314e40',
        'gs':'703247c1-a200-42bc-b70f-742dbab27aa4'
    }
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    // 订单时间
    const [orderTime, setOrderTime] = useState('')
    // 开始时间
    const [startDate, setStartDate] = useState('')
    // 结束时间
    const [endDate, setEndDate] = useState('')
    // 手机号
    const [ phone, setPhone] = useState('')
    // 是否上传
    const [ isUpload , setIsUpload ] = useState(false)
    // 供应商
    const [file, setFile] = useState([])
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 人群商品报表字段
    const [ commodityField ] = useState(['序号','联系电话','款号','创建时间','支付时间','支付金额','支付件数','省份','城市','地域','城市等级'])
    // 人群商品表
    const [ commodityColumns ] = useState(commodityField.map(item=>{
        return {
            title:item,
            dataIndex:filedDict[item],
            key:filedDict[item],
            align: 'center',
        }
    }))
    // 人群商品数据
    const [ commodityDate , setCommodityDate ] = useState([])
    // 汇总字段
    const [ summaryField ] = useState(['款号','支付金额','支付件数'])
    // 汇总表
    const [ summaryColumns ] = useState(summaryField.map(item=>{
        return {
            title:item,
            dataIndex:filedDict[item],
            key:filedDict[item],
            align: 'center',
        }
    }))
    const [ summaryDate, setSummaryDate ] = useState([])
    // 电话号码
    const [ phoneList , setPhoneList ] = useState([])
    useEffect(()=>{
        getMaxOrderPayTime()
        if(props.location.state == undefined){

        }else{
            if(props.location.state.phoneList.length == 0){

            }else{
                setPhoneList(props.location.state.phoneList)
            }
        }
    },[])

     // 预算目标文件上传
     const fileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx', 'xls']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setFile(fileList)
        }
    }

     // 订单最新数据日期查询接口
     const getMaxOrderPayTime = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dataProps, "POST", servicePath.getMaxOrderPayTime).then(res => {
            if (res.success) {
                setOrderTime(res.data['order_pay_time']);
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('订单最新数据日期查询报错，请联系IT帮忙解决')
        })
    }

      // 上传人群数据接口
      const uploadPhoneNumber = () => {
        if (file.length != 0) {
            setEffective(1)
            const formData = new FormData()
            // 多个图片
            file.forEach((item) => {
                formData.append('file', item['originFileObj']);
            })
            formData.append('roleId', cookie.load('role'));
            formData.append('shopList', cookie.load('shopList'));
            formData.append('shopId',shopIdDict[props.history.location.pathname.split('/')[2]])
            NbAxios(formData, "POST", servicePath.uploadPhoneNumber).then(res => {
                setEffective(0)
                if (res.success) {
                    setPhoneList(res.data['lxsj'].toString());
                    setIsUpload(false)
                } else {
                    message.warn(res.errorMsg)
                }
            }).catch((err) => {
                // 获取500错误
                setEffective(0)
                message.warn('上传人群数据接口报错，请联系IT帮忙解决')
            })
        } else {
            message.warn('请选择文件')
        }
    }

    return (
        <div>
            <div className={style.externalContent} >
                <div style={{display:'flex', justifyContent:'space-between', alignItems:"center", borderBottom:'1px solid #ccc', paddingBottom:"10px", height:"80px"}}>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <img src={ModulLogo} style={{height:"50px", width:"120px", backgroundSize:'100% 100%'}}/>
                        <Text strong style={{letterSpacing:"5px"}}>人群商品分析</Text>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{display: "flex", flexDirection:'column'}}>
                            <div style={{display:'flex'}}>
                                <div>
                                    <b>当前订单更新至:</b> {orderTime}
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div style={{display:'flex', marginTop:"20px", justifyContent:'space-between'}}>
                    <div style={{display:"flex"}}>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <span style={{color:"red", marginRight:"5px", fontSize:"15px"}}>*</span>
                            <span style={{marginRight:"5px"}}>订单日期</span>
                            <RangePicker locale={locale} disabledDate={disabledDate} value={startDate != '' ? [moment(startDate , dateFormat), moment(endDate,dateFormat)] : ['','']} 
                            onChange={(value, dateString) => {
                                if (dateString[0] == '') {
                                    message.warn('请选择正确的时间')
                                } else {
                                    setStartDate(dateString[0])
                                    setEndDate(dateString[1])
                                }
                            }} />
                    </div>
                    <div style={{marginLeft:"10px", display:"flex"}}>
                        <Input allowClear value={phoneList} style={{width:"400px"}} placeholder='请输入手机号(多个手机号以"-"分割)' onChange={(e)=>{setPhoneList(e.target.value)}}/>
                        <Button icon={<SearchOutlined />} onClick={()=>{
                            if(startDate == ''){
                                message.warn('请先选择订单时间')
                            }else{
                                let dataProps = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "start_date":startDate,
                                    "end_date":endDate,
                                    "lxsj":phoneList.toString()
                                }
                                setEffective(1)
                                NbAxios(dataProps, "POST", servicePath.getCircleCrowdGoods).then(res => {
                                    setEffective(0)
                                    if (res.success) {
                                        console.log(res.data);
                                        setCommodityDate(res.data['data_list']);
                                        setSummaryDate(res.data['total_list']);
                                    } else {
                                        message.warn({ content: res.errorMsg })
                                    }
                                }).catch((err) => {
                                    // 获取500错误
                                    setEffective(0)
                                    message.warn('人群圈选商品查询报错，请联系IT帮忙解决')
                                })
                            }
                        }}></Button>
                    </div>
                    </div>
                    <div style={{display:'flex', alignItems:"center"}}>
                        <a href="https://pic.bmcsoft.cn/imge_dir/070816133276/070816133276.html" target="_Blank" style={{marginLeft:10}}><Button type="link">操作手册</Button></a>
                        <Button type="primary" onClick={()=>{
                            setIsUpload(true)
                        }}>上传人群数据</Button>
                    </div>
                </div>
                <div>
                    <div style={{width:"100%", display:'flex', marginTop:'30px', marginBottom:"5px"}}>
                        <Title level={4}>商品表</Title>
                    </div>
                    <div>
                        <Table loading={effective ? true : false} columns={commodityColumns} dataSource={commodityDate} size="small" pagination={{showQuickJumper:true, pageSize:10, showSizeChanger:false }} />
                    </div>
                    <div style={{width:"100%", display:'flex', marginTop:'5px', marginBottom:"5px"}}>
                        <Title level={4}>汇总表</Title>
                    </div>
                    <div>
                        <Table loading={effective ? true : false} columns={summaryColumns} dataSource={summaryDate} size="small" pagination={{showQuickJumper:true, pageSize:10, showSizeChanger:false }}/>
                    </div>
                </div>
            </div>

             {/* 上传人群数据弹窗 */}
             <Modal
                title="上传人群数据"
                visible={isUpload}
                centered={true}
                okText="确定"
                cancelText="取消"
                maskClosable={true}
                onOk={() => {
                    uploadPhoneNumber();
                }}
                onCancel={() => {
                    setFile([])
                    setIsUpload(false)
                }}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.findTrue}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={file}
                    onChange={fileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx .xls 文件
                    </p>
                </Dragger>
            </Modal>
        </div>
    )


}

export default Index