import React, { createContext, useReducer, useEffect, useCallback, useState } from 'react'

// 状态管理部分
export const StateContext = createContext({})
// 定义事件类型
export const SIZE_CHANGE = 'SIZE_CHANGE'
export const DATE_CHANGE = 'DATE_CHANGE'
export const RACE_SHOP_LIST = 'RACE_SHOP_LIST'
export const ADD_CHART_DATA = 'ADD_CHART_DATA'

// 定义逻辑处理部分
const reducer = (state, action) => {
    // 根据事件类型选择处理处理逻辑
    switch (action.type) {
        case 'SIZE_CHANGE':
            // 对原state进行一次深拷贝
            let newState = JSON.parse(JSON.stringify(state))
            // 改变state中的屏幕尺寸
            newState.size = action.size
            // 返回新的尺寸
            return newState
        case 'DATE_CHANGE':
            // 对原state进行一次深拷贝
            let dateState = JSON.parse(JSON.stringify(state))
            dateState.raceShopDate = action.date
            // 返回新的日期
            return dateState
        case 'RACE_SHOP_LIST':
            let RaceShopList = JSON.parse(JSON.stringify(state))
            RaceShopList.raceShopList = action.data
            return RaceShopList
        case 'ADD_CHART_DATA':
            let newChart = JSON.parse(JSON.stringify(state))
            newChart.chartData = action.chartData
            return newChart
        default:
            return state
    }
}

// 创建状态管理包裹UI
export const StateShare = props => {

    //获取屏幕的长宽
    const [size, setSize] = useState({
        width: document.body.clientWidth,
        height: document.body.clientHeight
    })
    //设置屏幕大小的方法 useCallback可以缓存方法  useMemo缓存变量  
    const onResize = useCallback(() => {
        setSize({
            width: document.body.clientWidth,
            height: document.body.clientHeight
        })
    }, [])
    //设置为空数组，代表login界面销毁时（也就是不加载时），才return取消window监听事件。只有在login组件刚加载时，才生效，组件销毁时失效
    useEffect(() => {
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])
    // 定义所有状态初始值
    const initialState = { 'size': size }
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        // 监控屏幕尺寸变化并派发到共享仓库
        dispatch({ type: SIZE_CHANGE, size })
    }, [size])
    return (
        <StateContext.Provider value={{ state, dispatch }}>
            {props.children}
        </StateContext.Provider>
    )
}
