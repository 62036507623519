import React, { useState, useEffect } from 'react';
import {Button, Typography, Tabs, Table, Modal, Radio, Upload, message, Select, DatePicker, Spin} from 'antd'
import moment from 'moment'
import axios from 'axios';
import downExcel from '../../../config/utils/downloadFile';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {  InboxOutlined,} from '@ant-design/icons'
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios';
import {DailyWrapper, ExternalContent, TopContent, TabsContent, CenterButton} from './style'
import servicePath from '../../../config/apiUrl';
// #I6E80K NB20店铺日报表埋点 start
import AcceptBuried from '../../../config/utils/buried';
// #I6E80K NB20店铺日报表埋点 end
const { Title } = Typography
const { Dragger } = Upload;
const {Option} = Select
// 日期格式
const dateFormat = 'YYYY-MM-DD'
const { RangePicker } = DatePicker
// #I6E80K NB20店铺日报表埋点 start
function Index(props){
    // #I6E80K NB20店铺日报表埋点 end
    // 基础字段
    const [ baseField] = useState([
        { title:'商品访客数', key:"product_visitors"},
        { title:'成交金额', key:"transaction_amount"},
        { title:'退款金额', key:"refund_amount"},
        { title:'成交人数', key:"transactions_number"},
        { title:'成交订单数', key:"close_orders_number"},
        { title:'商品点击人数', key:"clicks_number"},
        { title:'种草金额', key:"recommend_amount"},
        { title:'种草订单量', key:"recommend_orders_number"},
    ])
    // 月统计
    const [ monthColumns, setMonthColumns] = useState([])
    const [ monthData, setMonthData ] = useState([])
    // 日统计
    const [ dailyColumns, setDailyColumns] = useState([])
    const [ dailyData, setDailyData ] = useState([])
    // 店铺数据
    const [ storeColumns, setStoreColumns] = useState([])
    const [ storeData, setStoreData ] = useState([])
    // tab列表
    const [ tabsItems ] = useState(['店铺月统计','日统计表','店铺数据'] )
    // tab关键词
    const [ tabActiveKey, setTabActiveKey ] = useState('店铺月统计')
    // 是否上传种草数据
    const [ isUploadRecommend, setIsUploadRecommend ] = useState(false)
    // 是否上传聚水潭数据
    const [ isUploadPoolFile, setIsUploadPoolFile ] = useState(false)
    // 是否上传电商罗盘数据
    const [ isUploadOnlineFile,setIsUploadOnlineFile ] = useState(false)
    // 是否下载月统计数据
    const [ isDownload, setIsDownload ] = useState(false)
    // 是否下载商品数据
    const [ isDownloadCommodity, setIsDownloadCommodity] = useState(false)
    // 种草数据
    const [ recommendFile, setRecommendFile ] = useState([])
    // 聚水潭数据
    const [ poolFile, setPoolFile ] = useState([])
    // 电商罗盘数据
    const [ onlineFile , setOnlineFile] = useState([])
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 数据
    const [ allShopList, setAllShopList ] = useState([])
    const [ typeList , setTypeList] = useState(['近一天','近七天','当月数据'])
    // 月统计店铺
    const [ monthShop, setMonthShop ] = useState('')
    const [ monthStartDate , setMonthStartDate ] = useState('')
    const [ monthEndDate , setMonthEndDate ] = useState('')
    const [ downMonthShop, setDownMonthShop ] = useState([])
    const [ downMonthStartDate , setDownMonthStartDate ] = useState('')
    const [ downMonthEndDate , setDownMonthEndDate ] = useState('')
    // 日统计
    const [ dailyType , setDailyType ] = useState('近一天')
    const [ dailyStartDate , setDailyStartDate ] = useState('')
    const [ dailyEndDate , setDailyEndDate ] = useState('')
    // 日统计
    const [storeShop, setStoreShop ] = useState('')
    const [ storeType , setStoreType ] = useState('近一天')
    const [ storeStartDate , setStoreStartDate ] = useState('')
    const [ storeEndDate , setStoreEndDate ] = useState('')
    // 店铺名字
    const [ shopList , setShopList ] = useState([])
    const [ chooseShop , setChooseShop ] = useState('')
    useEffect(()=>{
        // #I6E80K NB20店铺日报表埋点 start
        AcceptBuried('page_views',"view",{"page_type":"初始化页面"},props.location.pathname,"店铺日报表")
        // #I6E80K NB20店铺日报表埋点 end
        getStoreGroupShopName()
        setMonthColumns([
            {
                title:'日期',
                dataIndex:'date',
                key:'date',
                align: 'center',
                width:"100px",
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
            },
            ...baseField.map(item=>{
                return {
                    title:item.title,
                    dataIndex:item.key,
                    key:item.key,
                    align: 'center',
                    width:"100px",
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        ])
        setDailyColumns([
            {
                title:'店铺',
                dataIndex:'store',
                key:'store',
                align: 'center',
                width:"120px",
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
            },
            ...baseField.map(item=>{
                return {
                    title:item.title,
                    dataIndex:item.key,
                    key:item.key,
                    align: 'center',
                    width:"100px",
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        ])
         setStoreColumns([
            ...baseField.map(item=>{
                return {
                    title:item.title,
                    dataIndex:item.key,
                    key:item.key,
                    align: 'center',
                    width:"100px",
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        ])
    },[])

    // 种草文件上传
    const recommendFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    // let newFileList = []
                    // newFileList.push(fileList[fileList.length - 1])
                    setRecommendFile(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setRecommendFile(fileList)
        }
    }
    // 聚水潭数据
    const poolFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setPoolFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setRecommendFile(fileList)
        }
    }
    // 电商罗盘数据
    const onlineFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    // let newFileList = []
                    // newFileList.push(fileList[fileList.length - 1])
                    setOnlineFile(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setRecommendFile(fileList)
        }
    }
    // 上传种草文件
    const uploadRecommendFile = ()=>{
        if(recommendFile.length == 0){
            message.warn('请选择你要上传的种草文件')
        }else{
            const formData = new FormData()
            // 报告文件
            recommendFile.forEach((item) => {
                formData.append('files', item['originFileObj']);
            })
            formData.append('roleId', cookie.load('role'))
            formData.append('shopList', cookie.load('shopList'))
            setLoading(true)
            // #I6E80K NB20店铺日报表埋点 start
            AcceptBuried('upload_recommend_file',"click",{},props.location.pathname,"店铺日报表")
            // #I6E80K NB20店铺日报表埋点 end
            NbAxios(formData, "POST", servicePath.updateStoreGroupStoreDetail).then(res => {
                setLoading(false)
                if (res.success) {
                    message.success('上传种草文件成功')
                    setRecommendFile([])
                    setIsUploadRecommend(false)
                } else {
                    message.warn({ content: res.errorMsg })
                }
            }).catch((err) => {
                setLoading(false)
                // 获取500错误
                message.warn('上传种草文件报错，请联系IT帮忙解决')
            })
        }
    }
    // 上传聚水潭文件
    const uploadPoolFile = ()=>{
        if(poolFile.length == 0){
            message.warn('请选择你要上传的聚水潭文件')
        }else{
            const formData = new FormData()
            // 报告文件
            poolFile.forEach((item) => {
                formData.append('files', item['originFileObj']);
            })
            formData.append('roleId', cookie.load('role'))
            formData.append('shopList', cookie.load('shopList'))
            setLoading(true)
            NbAxios(formData, "POST", servicePath.updateStoreGroupJushuitanData).then(res => {
                setLoading(false)
                if (res.success) {
                    message.success('上传聚水潭文件成功')
                    setPoolFile([])
                    setIsUploadPoolFile(false)
                } else {
                    message.warn({ content: res.errorMsg })
                }
            }).catch((err) => {
                setLoading(false)
                // 获取500错误
                message.warn('上传种草文件报错，请联系IT帮忙解决')
            })
        }
    }

    // 上传电商罗盘数据
    const uploadOnlineFile =  ()=>{
        if(chooseShop == ''){
            message.warn('请选择店铺')
        }else{
            if(onlineFile.length == 0){
                message.warn('请选择你要上传的电商罗盘文件')
            }else{
                const formData = new FormData()
                // 报告文件
                onlineFile.forEach((item) => {
                    formData.append('files', item['originFileObj']);
                })
                formData.append('shop_name', chooseShop)
                formData.append('roleId', cookie.load('role'))
                formData.append('shopList', cookie.load('shopList'))
                setLoading(true)
                // #I6E80K NB20店铺日报表埋点 start
                AcceptBuried('upload_online_file',"click",{},props.location.pathname,"店铺日报表")
                // #I6E80K NB20店铺日报表埋点 end
                NbAxios(formData, "POST", servicePath.updateStoreGroupDailyData).then(res => {
                    setLoading(false)
                    if (res.success) {
                        message.success('上传电商罗盘文件成功')
                        setChooseShop('')
                        setOnlineFile([])
                        setIsUploadOnlineFile(false)
                    } else {
                        message.warn({ content: res.errorMsg })
                    }
                }).catch((err) => {
                    setLoading(false)
                    // 获取500错误
                    message.warn('上传种草文件报错，请联系IT帮忙解决')
                })
            }
        }
      
    }

    // 获取都有店铺名字
    const getStoreGroupShopName = ()=>{
        let value = {}
        value['roleId'] = cookie.load('role')
        value['shopList'] = cookie.load('shopList')
        NbAxios(value, "POST", servicePath.getStoreGroupShopName).then(res => {
            if (res.success) {
                setShopList(res['data']['shop_data_list']);
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            setLoading(false)
            // 获取500错误
            message.warn('获取都有店铺名字报错，请联系IT帮忙解决')
        })
    }

    // 获取月度数据
    const shopMonthStatistic = (shop, startDate, endDate, download)=>{
        if(shop.length == 0){
            message.warn("请选择店铺")
        }else if(startDate == ''){
            message.warn('请选择月份')
        }else{
            let value = {}
            value['now_time'] = []
            value['now_time'].push(startDate)
            value['now_time'].push(endDate)
            value['shop_list'] = shop
            // value['shop_list'].push(monthShop)
            value['roleId'] = cookie.load('role')
            value['shopList'] = cookie.load('shopList')
            value['download'] = download
            setLoading(true)
            // #I6E80K NB20店铺日报表埋点 start
            let dataProps ={
                monthShop:shop,
                now_time:[startDate,endDate],
                download:download
            }
            AcceptBuried('query_download_monthly_report',"click",dataProps,props.location.pathname,"店铺日报表")
            // #I6E80K NB20店铺日报表埋点 end
            if(download == 0){
                NbAxios(value, "POST", servicePath.shopStoreGroupMonthStatistic).then(res => {
                    setLoading(false)
                    if (res.success) {
                        setMonthData(res['data']['shop_data_list']);
                    } else {
                        message.warn({ content: res.errorMsg })
                    }
                }).catch((err) => {
                    setLoading(false)
                    // 获取500错误
                    message.warn('获取店铺月统计报错，请联系IT帮忙解决')
                })
            }else{
                axios({
                    method: 'POST',
                    url: servicePath.shopStoreGroupMonthStatistic,
                    data: value,
                    responseType: "blob",
                    headers: {
                        'authorization': cookie.load('token')
                    },
                    // 设置超时时间为5分钟
                    timeout:300000
                }).then(res => {
                        setLoading(false)
                        downExcel(res)
                        setDownMonthShop([])
                        setDailyStartDate('')
                        setDailyEndDate('')
                        setIsDownload(false)
                    }).catch(e => {
                    setLoading(false)
                    message.error({ content: '文件制作出错，请联系it同事！' })
                })
            }
        }
    }

    // 获取日数据
    const shopDailyStatistic = (nowTime, selectTime, download)=>{
        let value = {}
        value['now_time'] = nowTime
        value['select_time'] = selectTime
        value['roleId'] = cookie.load('role')
        value['shopList'] = cookie.load('shopList')
        value['download'] = download
        console.log(value);
        setLoading(true)
        // #I6E80K NB20店铺日报表埋点 start
        let dataProps ={
            select_time:nowTime,
            now_time:selectTime,
            download:download
        }
        AcceptBuried('query_download_daily_report',"click",dataProps,props.location.pathname,"店铺日报表")
        // #I6E80K NB20店铺日报表埋点 end
        if(download == 0){
             NbAxios(value, "POST", servicePath.shopStoreGroupDailyStatistic).then(res => {
                setLoading(false)
                if (res.success) {
                    setDailyData(res['data']['shop_data_list']);
                } else {
                    message.warn({ content: res.errorMsg })
                }
            }).catch((err) => {
                setLoading(false)
                // 获取500错误
                message.warn('获取店铺月统计报错，请联系IT帮忙解决')
            })
        }else{
            axios({
                method: 'POST',
                url: servicePath.shopStoreGroupDailyStatistic,
                data: value,
                responseType: "blob",
                headers: {
                    'authorization': cookie.load('token')
                },
                // 设置超时时间为5分钟
                timeout:300000
            }).then(res => {
                    setLoading(false)
                    downExcel(res)
                }).catch(e => {
                setLoading(false)
                message.error({ content: '文件制作出错，请联系it同事！' })
            })
        }
       
    }
    
    // 获取店铺数据
    const shopTotalStatistic = (nowTime, selectTime, shopList, download)=>{
        if(shopList.length == 0){
            message.warn('请选择店铺')
        }else{
            let value = {}
            value['now_time'] = nowTime
            value['select_time'] = selectTime
            value['shop_list'] = shopList
            value['roleId'] = cookie.load('role')
            value['shopList'] = cookie.load('shopList')
            value['download'] = download
            console.log(value);
            setLoading(true)
            // #I6E80K NB20店铺日报表埋点 start
            let dataProps ={
                select_time:nowTime,
                now_time:selectTime,
                download:download,
                shop_list:shopList
            }
            AcceptBuried('query_download_shop_report',"click",dataProps,props.location.pathname,"店铺日报表")
            // #I6E80K NB20店铺日报表埋点 end
            if(download == 0){
                 NbAxios(value, "POST", servicePath.shopStoreGroupTotalStatistic).then(res => {
                    setLoading(false)
                    if (res.success) {
                        setStoreData(res['data']['shop_data_list']);
                    } else {
                        message.warn({ content: res.errorMsg })
                    }
                }).catch((err) => {
                    setLoading(false)
                    // 获取500错误
                    message.warn('获取店铺数据报错，请联系IT帮忙解决')
                })
            }else{
                axios({
                    method: 'POST',
                    url: servicePath.shopStoreGroupTotalStatistic,
                    data: value,
                    responseType: "blob",
                    headers: {
                        'authorization': cookie.load('token')
                    },
                    // 设置超时时间为5分钟
                    timeout:300000
                }).then(res => {
                        setLoading(false)
                        downExcel(res)
                    }).catch(e => {
                    setLoading(false)
                    message.error({ content: '文件制作出错，请联系it同事！' })
                })
            }
        }
        
       
    }
    // 下载商品明细数据
    const downloadCommodity=()=>{
        let value = {}
        value['roleId'] = cookie.load('role')
        value['shopList'] = cookie.load('shopList')
        setLoading(true)
        axios({
            method: 'POST',
            url: servicePath.shopStoredownloadGoodsDetail,
            data: value,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
            // 设置超时时间为5分钟
            timeout:300000
        }).then(res => {
                setLoading(false)
                downExcel(res)
            }).catch(e => {
            setLoading(false)
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }
    return (
        <DailyWrapper>
            <ExternalContent>
                <TopContent>
                    <Title className='TitleContent' level={4} >店铺日报表  </Title>
                    <div className='buttonContent'>
                        <Button type="primary" disabled={loading} className='buttonLeftContent' onClick={()=>setIsUploadRecommend(true)}>上传种草数据</Button>
                        {/* <Button type="primary" onClick={()=>setIsUploadPoolFile(true)}>上传聚水潭数据</Button> */}
                        <Button type="primary" disabled={loading} onClick={()=>setIsUploadOnlineFile(true)}>上传罗盘数据</Button>
                        <Button type="primary" disabled={loading} danger onClick={()=>downloadCommodity()}>下载商品明细</Button>
                    </div>
                </TopContent>
                <TabsContent>
                    <Tabs activeKey={tabActiveKey} onChange={(key)=>{
                        // #I6E80K NB20店铺日报表埋点 start
                        AcceptBuried('module_selection',"click",{"tabActiveKey":key},props.location.pathname,"店铺日报表")
                        // #I6E80K NB20店铺日报表埋点 end
                        setTabActiveKey(key)
                    }}>
                        {
                            tabsItems.map(item=>{
                                return <Tabs.TabPane tab={item} key={item} />
                            })
                        }
                    </Tabs>
                </TabsContent>
                <div style={{display:tabActiveKey == '店铺月统计' ? '' : 'none'}}>
                    <Spin spinning={loading} tip="数据请求中....">
                        <CenterButton>
                            <div style={{display:'flex'}}>
                                <b style={{marginRight:"10px", lineHeight:"32px"}}>店铺：</b>
                                <Select showSearch style={{ width:"200px" }} placeholder="请选择店铺" value={monthShop} onChange={(value)=>{setMonthShop(value)}}>
                                {
                                    shopList.map(item=>{
                                        return <Option value={item.key}>{item.key}</Option>
                                    })
                                }
                                </Select>
                                <b style={{marginRight:"10px", marginLeft:"10px", lineHeight:"32px"}}>月份：</b>
                                <RangePicker picker="month" style={{marginLeft:"10px"}} locale={locale} 
                                value={monthStartDate == '' ? ['', ''] : [moment(monthStartDate, dateFormat), moment(monthEndDate, dateFormat)]}
                                onChange={(value, dateString) => {
                                    if (dateString[0] == '') {
                                        message.warn('请选择正确的时间')
                                    } else {
                                        setMonthStartDate(dateString[0])
                                        setMonthEndDate(dateString[1])
                                    }
                                }}
                                />
                            </div>
                            <div style={{display:'flex'}}>
                                <Button type="primary" onClick={()=>{
                                    let shopObj = []
                                    if(monthShop != ''){
                                        shopObj.push(monthShop)
                                    }
                                    shopMonthStatistic(shopObj, monthStartDate, monthEndDate, 0)
                                }}>查询</Button>
                                <Button type="primary" danger style={{marginLeft:"10px"}} onClick={()=>setIsDownload(true)}>下载数据</Button>
                            </div>
                        </CenterButton>
                        <div style={{ marginTop:'20px' }}>
                            <Table columns={monthColumns} dataSource={monthData}
                                pagination={false}  
                            />
                        </div>
                    </Spin>
                </div>
                <div style={{display:tabActiveKey == '日统计表' ? '' : 'none'}}>
                    <Spin spinning={loading} tip="数据请求中....">
                        <CenterButton>
                            <div style={{display:'flex'}}>
                                {/* <Select style={{ width:"150px" }} placeholder="请选择店铺" value={monthShop} onChange={(value)=>{setMonthShop(value)}}></Select> */}
                                <div>
                                    <Radio.Group value={dailyType} onChange={(e)=>{
                                        setDailyStartDate('')
                                        setDailyEndDate('')
                                        setDailyType(e.target.value)
                                    }}>
                                        {
                                            typeList.map(item=>{
                                                return <Radio value={item}>{item}</Radio>
                                            })
                                        }
                                    </Radio.Group>
                                </div>
                                <RangePicker style={{marginLeft:"10px"}} locale={locale} 
                                    value={dailyStartDate == '' ? ['', ''] : [moment(dailyStartDate, dateFormat), moment(dailyEndDate, dateFormat)]}
                                    onChange={(value, dateString) => {
                                        if (dateString[0] == '') {
                                            message.warn('请选择正确的时间')
                                        } else {
                                            setDailyType('')
                                            setDailyStartDate(dateString[0])
                                            setDailyEndDate(dateString[1])
                                        }
                                    }}
                                />
                            </div>
                            <div style={{display:'flex'}}>
                                <Button type="primary" onClick={()=>{
                                    let dateObj = []
                                    if(dailyStartDate != ''){
                                        dateObj.push(dailyStartDate)
                                        dateObj.push(dailyEndDate)
                                    }
                                    shopDailyStatistic(dailyType, dateObj, 0)
                                }}>查询</Button>
                                <Button type="primary" danger style={{marginLeft:"10px"}} onClick={()=>{
                                    let dateObj = []
                                    if(dailyStartDate != ''){
                                        dateObj.push(dailyStartDate)
                                        dateObj.push(dailyEndDate)
                                    }
                                    shopDailyStatistic(dailyType, dateObj, 1)
                                }}>下载数据</Button>
                            </div>
                        </CenterButton>
                        <div style={{ marginTop:'20px' }}>
                            <Table columns={dailyColumns} dataSource={dailyData}
                                pagination={false}  
                            />
                        </div>
                    </Spin>
                </div>
                <div style={{display:tabActiveKey == '店铺数据' ? '' : 'none'}}>
                    <Spin spinning={loading} tip="数据请求中....">
                        <CenterButton>
                            <div style={{display:'flex'}}>
                                <b style={{marginRight:"10px", lineHeight:"32px"}}>店铺：</b>
                                <Select showSearch style={{ width:"280px" }} placeholder="请选择店铺" value={storeShop} onChange={(value)=>{
                                    setStoreShop(value)
                                }}>
                                    {
                                        shopList.map(item=>{
                                            return <Option value={item.key}>{item.key}</Option>
                                        })
                                    }
                                </Select>
                                <div style={{marginLeft:"10px"}}>
                                    <Radio.Group value={storeType} onChange={(e)=>{
                                        setStoreStartDate('')
                                        setStoreEndDate('')
                                        setStoreType(e.target.value)
                                    }}>
                                        {
                                            typeList.map(item=>{
                                                return <Radio value={item}>{item}</Radio>
                                            })
                                        }
                                    </Radio.Group>
                                </div>
                                <RangePicker style={{marginLeft:"10px"}} locale={locale} 
                                    value={storeStartDate == '' ? ['', ''] : [moment(storeStartDate, dateFormat), moment(storeEndDate, dateFormat)]}
                                    onChange={(value, dateString) => {
                                        if (dateString[0] == '') {
                                            message.warn('请选择正确的时间')
                                        } else {
                                            setStoreType('')
                                            setStoreStartDate(dateString[0])
                                            setStoreEndDate(dateString[1])
                                        }
                                    }}
                                />
                            </div>
                            <div style={{display:'flex'}}>
                                <Button type="primary" onClick={()=>{
                                    let dateObj = []
                                    if(storeStartDate != ''){
                                        dateObj.push(storeStartDate)
                                        dateObj.push(storeEndDate)
                                    }
                                    let shopObj = []
                                    shopObj.push(storeShop)
                                    shopTotalStatistic(storeType, dateObj, shopObj, 0)
                                }}>查询</Button>
                                <Button type="primary" danger style={{marginLeft:"10px"}} onClick={()=>{
                                    let dateObj = []
                                    if(storeStartDate != ''){
                                        dateObj.push(storeStartDate)
                                        dateObj.push(storeEndDate)
                                    }
                                    let shopObj = []
                                    shopObj.push(storeShop)
                                    shopTotalStatistic(storeType, dateObj, shopObj, 1)
                                }}>下载数据</Button>
                            </div>
                        </CenterButton>
                        <div style={{ marginTop:'20px' }}>
                            <Table columns={storeColumns} dataSource={storeData}
                                pagination={false}  
                            />
                        </div>
                    </Spin>
                   
                </div>
            </ExternalContent>

            <Modal
                title="上传种草数据"
                visible={isUploadRecommend}
                onCancel={()=>{
                    setRecommendFile([])
                    setIsUploadRecommend(false)
                }}
                footer={null}
                centered={true}
            >
                <div>
                    <Dragger
                        // accept=".pdf .doc .docx"
                        action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                        fileList={recommendFile}
                        onChange={recommendFileHandleChange}
                        multiple={true}
                        // listType="picture"
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                        <p className="ant-upload-hint">
                            支持扩展名：.xlsx
                        </p>
                    </Dragger>
                </div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:"40px"}}>
                    <Button type="primary" danger onClick={()=>{
                        setRecommendFile([])
                        setIsUploadRecommend(false)
                    }} disabled={loading}>取消</Button>
                    <Button type="primary" onClick={()=>uploadRecommendFile()} disabled={loading}>上传</Button>
                </div>
            </Modal>
            <Modal
                title="上传聚水潭数据"
                visible={isUploadPoolFile}
                onCancel={()=>{
                    setPoolFile([])
                    setIsUploadPoolFile(false)
                }}
                footer={null}
                centered={true}
            >
                <div>
                    <Dragger
                        // accept=".pdf .doc .docx"
                        action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                        fileList={poolFile}
                        onChange={poolFileHandleChange}
                        // multiple={true}
                        // listType="picture"
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                        <p className="ant-upload-hint">
                            支持扩展名：.xlsx
                        </p>
                    </Dragger>
                </div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:"40px"}}>
                    <Button type="primary" danger onClick={()=>{
                        setPoolFile([])
                        setIsUploadPoolFile(false)
                    }} disabled={loading}>取消</Button>
                    <Button type="primary" onClick={()=>uploadPoolFile()} disabled={loading}>上传</Button>
                </div>
            </Modal>
            <Modal
                title="上传电商罗盘数据"
                visible={isUploadOnlineFile}
                onCancel={()=>{
                    setChooseShop('')
                    setOnlineFile([])
                    setIsUploadOnlineFile(false)
                }}
                footer={null}
                centered={true}
            >
                <div>
                    <div style={{display:'flex'}}>
                        <b style={{marginRight:"5px"}}>店铺名字：</b>
                        <Select showSearch style={{width:"300px", marginBottom:'5px'}} value={chooseShop} onChange={(value)=>setChooseShop(value)}>
                            {
                                shopList.map(item=>{
                                    return <Option value={item.key}>{item.key}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <Dragger
                        // accept=".pdf .doc .docx"
                        action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                        fileList={onlineFile}
                        onChange={onlineFileHandleChange}
                        multiple={true}
                        // listType="picture"
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                        <p className="ant-upload-hint">
                            支持扩展名：.xlsx
                        </p>
                    </Dragger>
                </div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:"40px"}}>
                    <Button type="primary" danger onClick={()=>{
                        setChooseShop('')
                        setOnlineFile([])
                        setIsUploadOnlineFile(false)
                    }} disabled={loading}>取消</Button>
                    <Button type="primary" onClick={()=>uploadOnlineFile()} disabled={loading}>上传</Button>
                </div>
            </Modal>
            <Modal
                title="下载店铺月统计数据"
                visible={isDownload}
                onCancel={()=>{
                   setDownMonthShop([])
                   setDailyStartDate('')
                   setDailyEndDate('')
                   setIsDownload(false)
                }}
                footer={null}
                centered={true}
            >
                <div>
                    <div style={{display:'flex'}}>
                        <b style={{width:"80px",lineHeight:'32px'}}>店铺名字：</b>
                        <Select showSearch  mode="multiple" maxTagCount = 'responsive' placeholder= '请选择店铺'
                        style={{width:"300px"}} value={downMonthShop} onChange={(value)=>setDownMonthShop(value)}>
                            {
                                shopList.map(item=>{
                                    return <Option value={item.key}>{item.key}</Option>
                                })
                            }
                        </Select>
                       
                    </div>
                    <div style={{display:"flex", marginTop:"5px"}}>
                        <b style={{width:"80px",lineHeight:"32px"}}>月份：</b>
                        <RangePicker picker="month" locale={locale}  style={{width:"300px"}}
                            value={downMonthEndDate == '' ? ['', ''] : [moment(downMonthEndDate, dateFormat), moment(downMonthEndDate, dateFormat)]}
                            onChange={(value, dateString) => {
                                if (dateString[0] == '') {
                                    message.warn('请选择正确的时间')
                                } else {
                                    setDownMonthStartDate(dateString[0])
                                    setDownMonthEndDate(dateString[1])
                                }
                            }}
                        />
                    </div>
                </div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:"40px"}}>
                    <Button type="primary" danger onClick={()=>{
                        setDownMonthShop([])
                        setDailyStartDate('')
                        setDailyEndDate('')
                        setIsDownload(false)
                    }} disabled={loading}>取消</Button>
                    <Button type="primary" onClick={()=>shopMonthStatistic(downMonthShop, downMonthStartDate, downMonthEndDate, 1)} disabled={loading}>下载</Button>
                </div>
            </Modal>
        </DailyWrapper>
    )

}

export default Index
