import React, { useEffect, useState } from 'react';
import { DatePicker, Typography, Progress, message, Table, Spin, Input, Space, Button, Checkbox, Statistic } from 'antd'
import { createFromIconfontCN, DownOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment'
import PlatformEventsCalendar from '../../component/platformEventsCalendarUp'
// 引入高亮组件
import Highlighter from "react-highlight-words";
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'
import Hierarchy from './component/hierarchy'
import axios from 'axios';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import downExcel from '../../config/utils/downloadFile';
import locale from 'antd/es/date-picker/locale/zh_CN';
import AcceptBuried from '../../config/utils/buried';
const { Title, Text } = Typography;
const dateFormat = 'YYYY-MM-DD';


function Index(props) {

    function disabledDate(current) {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'gs':'703247c1-a200-42bc-b70f-742dbab27aa4'
    }
    // 是否加载
    const [ loading , setLoading ] = useState(false)
    // 开始日期
    const [nowDate, setNowDate] = useState(moment().subtract(2, 'd').format('YYYY-MM-DD'))
    // 对比日期
    const [compareDate, setCompareDate] = useState(moment().subtract(3, 'd').format('YYYY-MM-DD'))
    // 当前图表数据
    const [currentChartData, setCurrentChartData] = useState([])
    // 对比图表数据
    const [compareChartData, setCompareChartData] = useState([])
    // 开始时间
    const [ startDate , setStartDate ] = useState(moment().subtract(17, 'd').format('YYYY-MM-DD'))
    // 结束时间
    const [ endDate , setEndDate ] = useState(moment().subtract(2, 'd').format('YYYY-MM-DD'))
    // AIPL
    const [ awareness1 , setAwareness1] = useState('')
    const [ awareness2 , setAwareness2] = useState('')
    const [ interest1 , setInterest1 ] = useState('')
    const [ interest2 , setInterest2 ] = useState('')
    const [ purchase1 , setPurchase1 ] = useState('') 
    const [ purchase2 , setPurchase2 ] = useState('') 
    const [ loyalty1, setLoyalty1 ] = useState('')
    const [ loyalty2, setLoyalty2 ] = useState('')
    const [ aiRate1 , setAiRate1] = useState('')
    const [ aiRate2 , setAiRate2] = useState('')
    const [ ipRate1, setIpRate1 ] = useState('')
    const [ ipRate2, setIpRate2 ] = useState('')
    const [ plRate1, setPlRate1 ] = useState('')
    const [ plRate2, setPlRate2 ] = useState('')
    // 活跃消费者
    const [ currentActiveConsumers, setCurrentActiveConsumers] = useState({
        'ratio':'',
        'value':'',
    })
    const [ compareActiveConsumers, setCompareActiveConsumers] = useState({
        'ratio':'',
        'value':'',
    })
    // 消费者资产
    const [ currentConsumerAssets, setCurrentConsumerAssets] = useState({
        'ratio':'',
        'value':'',
    })
    const [ compareConsumerAssets, setCompareConsumerAssets] = useState({
        'ratio':'',
        'value':'',
    })
    // 增加率图表
    const [ awarenessData , setAwarenessData ] = useState([])
    const [ interestData , setInterestData ] = useState([])
    const [ purchaseData , setPurchaseData ] = useState([])
    const [ loyaltyData , setLoyaltyData ] = useState([])
    const [ growthRateData , setGrowthRateData ] = useState([])
    // 当前图表
    let currentChart = {
        color:[ 
            "#1da8ed", 
            "#f7d51f",
            "#f5903f",
            "#f05054"
        ],
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c}千'
        },
        series: [
            {
                name: 'Funnel',
                type: 'funnel',
                left: '10%',
                top: 60,
                bottom: 60,
                width: '80%',
                min: 0,
                max: 100,
                minSize: '0%',
                maxSize: '100%',
                sort:'none',
                gap: 2,
                label: {
                    show: true,
                    // position: 'inside'
                },
                labelLine: {
                    length: 10,
                    lineStyle: {
                        width: 1,
                        type: 'solid'
                    }
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1
                },
                emphasis: {
                    label: {
                        fontSize: 20
                    }
                },
                data: currentChartData
            }
        ]
    }
    // 对比图表
    let compareChart = {
        color:[ 
            "#1da8ed", 
            "#f7d51f",
            "#f5903f",
            "#f05054"
        ],
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c}千'
        },
        series: [
            {
                name: 'Funnel',
                type: 'funnel',
                left: '10%',
                top: 60,
                bottom: 60,
                width: '80%',
                min: 0,
                max: 100,
                minSize: '0%',
                maxSize: '100%',
                sort:"none",
                gap: 2,
                label: {
                    show: true,
                    // position: 'inside'
                },
                labelLine: {
                    length: 10,
                    lineStyle: {
                        width: 1,
                        type: 'solid'
                    }
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1
                },
                emphasis: {
                    label: {
                        fontSize: 20
                    }
                },
                data: compareChartData
            }
        ]
    }

    // 区域基础表头
    const [dataColumns] = useState([
        {
            title: '日期',
            dataIndex: '日期',
            key: '日期',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <div style={{ fontSize: 13, textAlign:'right'}}>{text}</div>
            )
        },
        {
            title: '活跃消费者',
            dataIndex: '活跃消费者',
            key: '活跃消费者',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <div style={{ fontSize: 13, textAlign:'right'}}>{text == '-' || text ==null ? '-' : text.toLocaleString()}</div>
            )
        },
        {
            title: '认知',
            dataIndex: '认知',
            key: '认知',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 <div style={{ fontSize: 13, textAlign:'right'}}>{text == '-' || text ==null ? '-' : text.toLocaleString()}</div>
            )
        },
        {
            title: '兴趣',
            dataIndex: '兴趣',
            key: '兴趣',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 <div style={{ fontSize: 13, textAlign:'right'}}>{text == '-' || text ==null || text ==null ? '-' : text.toLocaleString()}</div>
            )
        },
        {
            title: '购买',
            dataIndex: '购买',
            key: '购买',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 <div style={{ fontSize: 13, textAlign:'right'}}>{text == '-' || text ==null ? '-' : text.toLocaleString()}</div>
            )
        },
        {
            title: '忠诚',
            dataIndex: '忠诚',
            key: '忠诚',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 <div style={{ fontSize: 13, textAlign:'right'}}>{text == '-' || text ==null ? '-' : text.toLocaleString()}</div>
            )
        },
        {
            title: '消费者资产',
            dataIndex: '消费者资产',
            key: '消费者资产',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <div style={{ fontSize: 13, textAlign:'right'}}>{text == '-' || text ==null ? '-' : text.toLocaleString()}</div>
            )
        },
        {
            title: '消费者周增长率',
            dataIndex: '消费者周增长率',
            key: '消费者周增长率',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text}</div>
            )
        },
        {
            title: '潜客-顾客比',
            dataIndex: '潜客-顾客比',
            key: '潜客-顾客比',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text}</div>
            )
        },
        {
            title: '关系周加深率',
            dataIndex: '关系周加深率',
            key: '关系周加深率',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text}</div>
            )
        },
        {
            title: '认知新增',
            dataIndex: '认知新增',
            key: '认知新增',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text}</div>
            )
        },
        {
            title: '兴趣新增',
            dataIndex: '兴趣新增',
            key: '兴趣新增',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text}</div>
            )
        },
        {
            title: '购买新增',
            dataIndex: '购买新增',
            key: '购买新增',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text}</div>
            )
        },
        {
            title: '忠诚新增',
            dataIndex: '忠诚新增',
            key: '忠诚新增',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text}</div>
            )
        },
        {
            title: '消费者资产新增',
            dataIndex: '消费者资产新增',
            key: '消费者资产新增',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text}</div>
            )
        },
        {
            title: '消费者周增长率新增',
            dataIndex: '消费者周增长率新增',
            key: '消费者周增长率新增',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                 // <Statistic valueStyle={{ fontSize: 13, textAlign:'right'}} value={text} />
                <div style={{ fontSize: 13, textAlign:'right'}}>{text}</div>
            )
        },
    ])
    let growthRateOption = {
        title: {
            text: 'AIPL增长率趋势'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter:function(params) {
            var str = params[0].name + '<br>'
            for(let item of params) {
                str += item.seriesName + ' : ' + item.value + '%<br>'
            }
            return str;
            }
        },
        legend: {
            data: ['认知', '兴趣', '购买', '忠诚']
        },
        xAxis: {
          type: 'category',
          data: growthRateData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '认知',
            data: awarenessData,
            type: 'line'
          },
          {
            name: '兴趣',
            data: interestData,
            type: 'line'
          },
          {
            name: '购买',
            data: purchaseData,
            type: 'line'
          },
          {
            name: '忠诚',
            data: loyaltyData,
            type: 'line'
          }
        ]
    }
    useEffect(() => {
        let reportChart = echarts.init(document.getElementById("growthRate"));
        reportChart.clear();    //清除残留渲染
        // 绘制图表
        reportChart.setOption(growthRateOption);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [loyaltyData])
    // 指标基础表头
    const [indexColumns] = useState([
        // {
        //     title: '名称',
        //     dataIndex: '名称',
        //     key: '名称',
        //     align: 'center',
        //     onHeaderCell: (record, index) => {
        //         return (
        //             {
        //                 style: {
        //                     fontSize: '10px',
        //                     padding: 0
        //                 }
        //             }
        //         )
        //     },
        //     onCell: (record, index) => {
        //         return (
        //             {
        //                 style: {
        //                     fontSize: '10px',
        //                     padding: 0
        //                 }
        //             }
        //         )
        //     },
        //     render: (text, record, index) => (
        //         <div style={{ fontSize: 13, textAlign:'center'}}>{text}</div>
        //     )
        // },  
        {
            title: '绝对值',
            dataIndex: '绝对值',
            key: '绝对值',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <div style={{ fontSize: 13, textAlign:'center'}}>{text}</div>
            )
        },
        {
            title: '增长率',
            dataIndex: '增长率',
            key: '增长率',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <div style={{ fontSize: 13, textAlign:'center'}}>{text}</div>
            )
        }
    ])
    // 指标数据
    const [ indexData , setIndexData] = useState([
        {'绝对值':'','增长率':''},
        {'绝对值':'','增长率':''},
        {'绝对值':'','增长率':''},
        {'绝对值':'','增长率':''},
        {'绝对值':'','增长率':''},
        {'绝对值':'','增长率':''},
        {'绝对值':'','增长率':''},
    ])
    // 数据
    const [ dataSource, setDataSource ] = useState([])

    useEffect(()=>{
        AcceptBuried('page_views',"view",{"page_type":"初始化页面"},props.location.pathname,"人群资产")
        const getData = async () => {
            await Promise.all([ 
                getCrowdAssetsData(),
                getCrowdAssetsRecentData(),
                getCrowdAssetsRecentRateData()
            ])
        }
        getData()
    },[])

    // useEffect(() => {
    //     let reportChart = echarts.init(document.getElementById('currentChartData'));
    //     // 绘制图表
    //     reportChart.setOption(currentChart);
    //     window.addEventListener('resize', () => {
    //         reportChart.resize()
    //     });
    // }, [currentChartData])

    // useEffect(()=>{
    //     let reportChart = echarts.init(document.getElementById('compareChartData'));
    //     // 绘制图表
    //     reportChart.setOption(compareChart);
    //     window.addEventListener('resize', () => {
    //         reportChart.resize()
    //     });
    // },[compareChartData])

    // 获取人群资产近15天增长率趋势数据
    const getCrowdAssetsRecentRateData = ()=>{
        let values = {
            "roleId":  cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": nowDate,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(values, "POST", servicePath.getCrowdAssetsRecentRateData).then(res => {
            setLoading(false)
            if (res.success) {
                setGrowthRateData(res.data['index']);
                setAwarenessData(res.data['认知']);
                setInterestData(res.data['兴趣']);
                setPurchaseData(res.data['购买']);
                setLoyaltyData(res.data['忠诚']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setLoading(false)
            message.warn('获取人群资产近15天增长率趋势数据报错，请联系IT帮忙解决')
        })
    }

    // 获取人群资产数据接口
    const getCrowdAssetsData = ()=>{
        let values = {
            "roleId":  cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "this_date": nowDate,
            "last_date": compareDate,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getCrowdAssetsData).then(res => {
            setLoading(false)
            if (res.success) {
                setAwareness1(res.data['本期数据']['认知'])
                setInterest1(res.data['本期数据']['兴趣'])
                setPurchase1(res.data['本期数据']['购买'])
                setLoyalty1(res.data['本期数据']['忠诚'])
                setAiRate1(res.data['本期数据']['认知-兴趣'])
                setIpRate1(res.data['本期数据']['兴趣-购买'])
                setPlRate1(res.data['本期数据']['购买-忠诚'])
                setCurrentActiveConsumers(res.data['本期数据']['活跃消费者'])
                setCurrentConsumerAssets(res.data['本期数据']['消费者资产'])
                setIndexData(res.data['对比'])
                setAwareness2(res.data['上期数据']['认知'])
                setInterest2(res.data['上期数据']['兴趣'])
                setPurchase2(res.data['上期数据']['购买'])
                setLoyalty2(res.data['上期数据']['忠诚'])
                setAiRate2(res.data['上期数据']['认知-兴趣'])
                setIpRate2(res.data['上期数据']['兴趣-购买'])
                setPlRate2(res.data['上期数据']['购买-忠诚'])
                setCompareActiveConsumers(res.data['上期数据']['活跃消费者'])
                setCompareConsumerAssets(res.data['上期数据']['消费者资产'])
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setLoading(false)
            message.warn('查询人群资产数据报错，请联系IT帮忙解决')
        })
    }
    // 获取人群资产对比数据接口
    const getCrowdAssetsContrastData = ()=>{
        let values = {
            "roleId":  cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date1": nowDate,
            "date2": compareDate,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getCrowdAssetsContrastData).then(res => {
            setLoading(false)
            if (res.success) {
                setIndexData(res.data.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setLoading(false)
            message.warn('查询图表接口报错，请联系IT帮忙解决')
        })
    }

    // 获取人群资产进15天数据接口
    const getCrowdAssetsRecentData = ()=>{
        let values = {
            "roleId":  cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "start_time": startDate,
            "end_time":endDate,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getCrowdAssetsRecentData).then(res => {
            setLoading(false)
            if (res.success) {
                setDataSource(res.data.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            // 获取500错误
            setLoading(false)
            message.warn('查询图表接口报错，请联系IT帮忙解决')
        })
    }

    return (
        <div>
            <Spin spinning={loading}>
                <div style={{
                    display: 'flex',
                    justifyContent: "space-around",
                    backgroundColor: "#fff",
                    borderBottom: "solid 2px #ccc",
                    marginBottom: 10,
                    position: 'fixed',
                    top: 40,
                    zIndex: 99,
                    width: '100vw'
                }}>
                    <Title level={3}>{props.location.pathname.split('/')[2]}人群资产</Title>
                    <div style={{ display: 'flex', marginTop: 5 }}>
                        <b style={{ marginRight: 10, marginTop: 5 }}>当前时间:</b>
                        <DatePicker disabledDate={disabledDate} value={nowDate == '' ? '' : moment(nowDate, dateFormat)} style={{ height: 30, marginRight: 10 }} locale={locale} onChange={(date, dateString) => {
                            if (dateString == '') {
                                message.warn('请选择合理的时间')
                                return false
                            } else {
                                setNowDate(dateString);
                            }
                        }}/>
                        <b style={{ marginRight: 10, marginTop: 5 }}>对比时间:</b>
                        <DatePicker disabledDate={disabledDate} value={compareDate == '' ? '' : moment(compareDate, dateFormat)} style={{ height: 30, marginRight: 10 }} locale={locale} onChange={(date, dateString) => {
                            if (dateString == '') {
                                message.warn('请选择合理的时间')
                                return false
                            } else {
                                setCompareDate(dateString);
                            }
                        }} />
                        <Button size="small" type="primary" style={{ marginTop: 3 }} onClick={()=>{
                            if(nowDate == ''){
                                message.warn('请选择合理的当前时间')
                            }else if(compareDate == ''){
                                message.warn('请选择合理的对比时间')
                            }else{
                                setIndexData([
                                    {'绝对值':'','增长率':''},
                                    {'绝对值':'','增长率':''},
                                    {'绝对值':'','增长率':''},
                                    {'绝对值':'','增长率':''},
                                    {'绝对值':'','增长率':''},
                                    {'绝对值':'','增长率':''},
                                    {'绝对值':'','增长率':''},
                                ])
                                setCurrentActiveConsumers({
                                    'ratio':'',
                                    'value':'',
                                })
                                setCompareActiveConsumers({
                                    'ratio':'',
                                    'value':'',
                                })
                                setCurrentConsumerAssets({
                                    'ratio':'',
                                    'value':'',
                                })
                                setCompareConsumerAssets({
                                    'ratio':'',
                                    'value':'',
                                })
                                setDataSource([])
                                let dataProps={
                                    "nowDate":nowDate,
                                    "compareDate":compareDate
                                }
                                AcceptBuried('query_all_page_data', "click", dataProps, props.location.pathname, "人群资产")
                                const getData = async () => {
                                    await Promise.all([ 
                                        getCrowdAssetsData(),
                                        getCrowdAssetsRecentRateData()
                                    ])
                                }
                                getData()
                            }
                        }}>查询</Button>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 50, justifyContent: 'space-around', marginLeft:60, marginRight:60 }}>
                    <div>
                        <div style={{width:'30vw', display:'flex', justifyContent:'center'}}>
                            <Hierarchy title={nowDate} id={"FunnelEchart1"} awareness={awareness1} interest={interest1} purchase={purchase1} loyalty={loyalty1} aiRate={aiRate1} ipRate={ipRate1} plRate={plRate1}/>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <b style={{marginRight:10}}>活跃消费者:</b>
                            <Progress percent={currentActiveConsumers['ratio']} size="small" style={{width:'15vw'}} showInfo={false} />
                            <Text type="secondary" style={{marginLeft:10}}>{currentActiveConsumers['value']}</Text>
                        </div>
                        <div style={{ display: 'flex', marginTop:'18px' }}>
                            <b style={{marginRight:10}}>消费者资产:</b>
                            <Progress percent={currentConsumerAssets['ratio']} size="small" style={{width:'15vw'}} showInfo={false}/>
                            <Text type="secondary" style={{marginLeft:10}}>{currentConsumerAssets['value']}</Text>
                        </div>
                    </div>
                    <div style={{width:500}}>
                        <div style={{display:'flex'}}>
                            <b style={{fontSize:22, width:250, textAlign:'center'}}>增长量</b>
                            <b style={{fontSize:22, width:250, textAlign:'center'}}>增长率</b>
                        </div>
                        <div style={{height: "18vh", display:indexData[0]['绝对值'] == '' ? 'none' :''}}>
                            <div style={{display:'flex',  marginTop:'20px'}}>
                                <div style={{width:"19vw", display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[0]['绝对值']}</Text>
                                </div>
                                <div style={{width:"19vw", display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[0]['增长率']}</Text>
                                </div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-around', marginTop:'35px'}}>
                                <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[1]['绝对值']}</Text>
                                </div>
                                <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[1]['增长率']}</Text>
                                </div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-around', marginTop:'43px'}}>
                                <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[2]['绝对值']}</Text>
                                </div>
                                <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[2]['增长率']}</Text>
                                </div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-around', marginTop:'40px'}}>
                                <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[3]['绝对值']}</Text>
                                </div>
                                <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[3]['增长率']}</Text>
                                </div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-around', marginTop:'68px'}}>
                                <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[4]['绝对值']}</Text>
                                </div>
                                <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[4]['增长率']}</Text>
                                </div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-around', marginTop:'28px'}}>
                                <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[5]['绝对值']}</Text>
                                </div>
                                <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                    <Text>{indexData[5]['增长率']}</Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{width:'30vw', display:'flex', justifyContent:'center'}}>
                            <Hierarchy title={compareDate} id={"FunnelEchart2"} awareness={awareness2} interest={interest2} purchase={purchase2} loyalty={loyalty2} aiRate={aiRate2} ipRate={ipRate2} plRate={plRate2}/>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <b style={{marginRight:10}}>活跃消费者:</b>
                            <Progress percent={compareActiveConsumers['ratio']} size="small" style={{width:'15vw'}} showInfo={false}/>
                            <Text type="secondary" style={{marginLeft:10}}>{compareActiveConsumers['value']}</Text>
                        </div>
                        <div style={{ display: 'flex', marginTop:'18px' }}>
                            <b style={{marginRight:10}}>消费者资产:</b>
                            <Progress percent={compareConsumerAssets['ratio']} size="small" style={{width:'15vw'}} showInfo={false}/>
                            <Text type="secondary" style={{marginLeft:10}}>{compareConsumerAssets['value']}</Text>
                        </div>
                    </div>
                </div>
            </Spin>
            <div style={{marginLeft:20, marginRight:20, marginTop:20,}}>
                <div id="growthRate" style={{ width: '100%', height: "40vh" }}></div>
            </div>
            <div style={{marginLeft:20, marginRight:20, marginTop:20, paddingBottom:100}}>
                <div style={{display:'flex', justifyContent:'flex-end', marginBottom:10, marginRight:300}}>
                    <PlatformEventsCalendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
                    <Button style={{ padding: "0 5px", width: 45, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "white", marginLeft:10 }} type="primary" onClick={()=>{
                        let dataProps = {
                            "startDate":startDate,
                            "endDate":endDate
                        }
                        AcceptBuried('query_detailed_data', "click", dataProps, props.location.pathname, "人群资产")
                        getCrowdAssetsRecentData()
                    }}>查询</Button>
                    <Button style={{ padding: "0 5px", width: 45, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "white", marginLeft:10 }} type="primary" danger onClick={()=>{
                        let values = {
                            "roleId":  cookie.load('role'),
                            "shopList": cookie.load('shopList'),
                            "start_time": startDate,
                            "end_time":endDate,
                            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                        }
                        let dataProps = {
                            "startDate":startDate,
                            "endDate":endDate
                        }
                        AcceptBuried('download_detailed_data', "click", dataProps, props.location.pathname, "人群资产")
                        setLoading(true)
                        axios({
                            data: values,
                            method: 'POST',
                            url: servicePath.downloadCrowdAssetsData,
                            responseType: "blob",
                            headers: {
                                'Content-Type': 'application/json',  //如果写成contentType会报错
                                'authorization': cookie.load('token')
                            }
                        }).then(
                            res => {
                                setLoading(false)
                                downExcel(res)
                            }
                        ).catch(e => {
                            setLoading(false)
                            message.error({ content: '文件制作出错，请联系it同事！' })
                        })
                    }}>下载</Button>
                </div>
                <Table columns={dataColumns} dataSource={dataSource} bordered pagination={false} scroll={{y:'500px'}} loading={loading}/>
            </div>
        </div>
    )

}

export default Index