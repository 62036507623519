import React, { useEffect, useState, useCallback } from 'react';
import { Button, DatePicker, message, Breadcrumb,Select } from 'antd'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import servicePath from '../../config/apiUrl'
import cookie from 'react-cookies'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import Axios from 'axios';
const { Option } = Select;

function DaliyCharts(props) {
    var colors = ['#5793f3', '#d14a61', '#675bba', '#00a8e1', '#e30039 '];

    // 品牌字典
    const trademarkDict = {
        'joomilim': 'joomilim',
        'billymargot': 'billymargot',
        'ivorycoat': 'ivorycoat',
        'airinum': 'airinum',
        'jr': 'JORYA',
        'qda': 'QDA',
        'an': '恩曼琳',
        'xh': '经销',
        'z11': 'Z11',
        'cr': '卡洛琳',
        'freiol': '福来',
        'iv': '常春藤',
        // 'joomilim': 'joomilim',
        // 'billymargot':'billymargot'
        'vgrass': 'vgrass',
        'EIN':'EIN',
        'mesux': "mesux",
        'threePets':'threePets',
        'gs':'gs'
    }

         // 店铺字典
    const storeDict = {
        '':[
            {
                'name':'请选择店铺',
                'value':''
            }
        ],
         'ivorycoat':[
             {
                 'name':'ivorycoat海外旗舰店',
                 'value':'ivorycoat'
             }
         ],
        'jr':[
            {
                'name':'天猫',
                'value':'jr_tmall'
            },
            // {
            //     'name':'京东',
            //     'value':'jr_JD'
            // }
        ],
        'an':[
            {
                'name':'天猫',
                'value':'an_tmall'
            }
        ],
        'qda':[
            {
                'name':'天猫',
                'value':'qda_tmall'
            } 
        ],
        'iv':[
            {
                'name':'天猫',
                'value':'iv_tmall'
            }
        ],
        'freiol':[
            {
                'name':'freiol旗舰店',
                'value':'freiol_tm'
            },
            {
                'name':'freiol福来官方品牌店',
                'value':'freiol'
            },
            {
                'name':'FREIOL海外旗舰店',
                'value':'freiol_tmgj'
            },
        ],
        'xh':[
            {
                'name':'欣贺女装outlets天猫店',
                'value':'xh_outlets'
            },
            {
                'name':'欣贺女装outlets淘宝店',
                'value':'xh_outlets_c'
            },
            {
                'name':'LY高端精选女装店',
                'value':'likeforlike'
            }
        ],
        'z11':[
            {
                'name':'Z11天猫店',
                'value':'Z11_tmall'
            },
            {
                'name':'Z11官方企业店',
                'value':'Z11_c'
            }
        ],
        'cr':[
            {
                'name':'卡洛琳天猫店',
                'value':'cr_tmall'
            }
        ],
        'airinum':[
            {
                'name':'airinum旗舰店',
                'value':'airinum'
            }
        ],
        'joomilim':[
            {
                'name':'天猫',
                'value':'joomilim_tmall'
            }
        ],
        'billymargot':[
            {
                'name':'billymargot海外旗舰店',
                'value':'billymargot'
            }
        ],
        'vgrass':[
            {
                'name': 'vgrass女装旗舰店',
                'value': 'vgrass'
            }
        ],
        'ein': [
            {
                'name': 'EIN天猫店',
                'value': 'EIN_tmall'
            },
        ],
        'mesux': [
            {
                'name': 'mesux天猫店',
                'value': 'mesux_tmall'
            }
        ],
        'threePets': [
            {
                'name': '三只小宠天猫旗舰店',
                'value': 'threePets_tmall'
            }
        ],
        'gs':[
            {
                'name': '巨式天猫旗舰店',
                'value': 'gs_tmall'
            }
        ],

    }

    // 品牌代号
    const [trademark] = useState(props.location.state.trademark)
    // 品牌名
    const [trademarkName] = useState(trademarkDict[trademark])
    // 店铺集合
    const [ shopList ] = useState(storeDict[trademark])
    // 店铺
    const [ shop , setShop ] = useState(storeDict[trademark][0]['value'])
    // 店铺名字
    const [ shopName , setShopName ] = useState(storeDict[trademark][0]['name'])
    // 回款金额目标
    const [getTarget, setGetTarget] = useState('7000000')
    // 定义月份
    const [selectMonth, setSelectMonth] = useState()
    // 定义开始时间
    const [startDate, setStartDate] = useState(moment().subtract(31, 'day').format('YYYY-MM-DD'))
    // 定义结束时间
    const [endDate, setEndDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'))
    // 时间轴
    const [xDate, setXDate] = useState([])
    // 件单价
    const [onePrice, setOnePrice] = useState()
    // 加购件数
    const [add, setAdd] = useState([])
    // 回款完成率
    const [getRate, setGetRate] = useState([])
    // 支付目标
    const [payTarget, setPayTarget] = useState()
    // 回款目标
    const [remittance, setRemittance] = useState([])
    // 实际支付金额
    const [pay, setPay] = useState([])
    // 实际回款金额
    const [getMoney, setGetMoney] = useState([])
    // 客单价
    const [customerPrice, setCustomerPrice] = useState([])
    // 支付退款金额
    const [goMoney, setGoMoney] = useState([])
    // 支付买家数
    const [payCustomers, setPayCustomers] = useState([])
    // 支付件数
    const [payNums, setPayNums] = useState([])
    // 支付完成率
    const [payRate, setPayRate] = useState([])
    // 支付累计金额
    const [payAdd, setPayAdd] = useState([])
    // 支付转化率
    const [payChange, setPayChange] = useState([])
    // 回款累计
    const [getAdd, setGetAdd] = useState([])
    // 访客数
    const [uv, setUv] = useState([])
    // 商品访客数
    const [spfks , setSpfks ] = useState([])
    // 新买家数
    const [xmjs , setXmjs ] = useState([])
    // 老买家数
    const [lmjs , setLmjs ] = useState([])
    // 新买家金额
    const [xmjje , setXmjje ] = useState([])
    // 老买家金额
    const [lmjje , setLmjje ] = useState([])
    // 加购人数
    const [jgrs , setJgrs ] = useState([])
    // 收藏人数
    const [scrs , setScrs ] = useState([])
    // 收藏件数
    const [ scjs , setScjs ] = useState([])

    let report = {
        color: colors,
        title: {
            text: `${trademarkName}月度报表`,
            subtext: `支付目标 ${payTarget == null ? '' : payTarget}\n回款目标 ${remittance == null ? '' : remittance}\n统计时间：${startDate}至${endDate}`
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        dataZoom: [{
            type: "inside"         //详细配置可见echarts官网
        }],
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        legend: {
            data: ['实际支付金额', '支付退款金额', '实际回款金额', '支付累计金额', '回款累计金额', '支付完成率', '回款完成率'],
            selected: {
                '支付完成率': payRate[0] == null ? false : true,
                '回款完成率': getRate[0] == null ? false : true,
            }
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: xDate
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '金额/万元',
                position: 'left',
                axisLabel: {
                    formatter: function (data) {
                        return (data / 10000);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: colors[2]
                    },
                    show: true,
                    onZero: true
                },
            },
            {},
            {
                type: 'value',
                name: '完成率%',
                position: 'right',
                offset: 0,
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: colors[1]
                    },
                    show: true,
                    onZero: true
                },
            },
        ],
        series: [
            {
                name: '实际支付金额',
                type: 'bar',
                data: pay
            },
            {
                name: '支付退款金额',
                type: 'bar',
                data: goMoney
            },

            {
                name: '实际回款金额',
                type: 'bar',
                data: getMoney
            },
            {
                name: '支付累计金额',
                type: 'bar',
                data: payAdd
            },
            {
                name: '回款累计金额',
                type: 'bar',
                data: getAdd
            },

            {
                name: '支付完成率',
                type: 'line',
                yAxisIndex: 2,
                data: payRate
            },
            {
                name: '回款完成率',
                type: 'line',
                yAxisIndex: 2,
                data: getRate
            },
        ]
    };
    let others = {
        color: colors,
        title: {
            text: `${trademarkName}运营数据`,
            subtext: `支付目标 ${payTarget == null ? '' : payTarget}\n回款目标 ${remittance == null ? '' : remittance}\n统计时间：${startDate}至${endDate}`
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '100px',
            right: '12%',
            left: '10%',
            bottom: '25%'
        },
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        dataZoom: [{
            type: "inside"         //详细配置可见echarts官网
        }],
        legend: {
            data: ['件单价', '客单价', '加购件数', '支付买家数', '支付件数', '访客数', '支付转化率','商品访客数','',
            '新买家数','老买家数','新买家支付金额','老买家支付金额','加购人数','收藏人数','收藏件数'],
            selected: {
                '件单价': true,
                '客单价': true,
                '加购件数': true,
                '支付买家数': true,
                '支付件数': true,
                '访客数': false,
                '支付转化率': true,
                '商品访客数': true,
                '新买家数': true,
                '老买家数': true,
                '新买家支付金额': true,
                '老买家支付金额': true,
                '加购人数': true,
                '收藏人数': true,
                '收藏件数': true,
            }
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: xDate
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '数量/(件,人)',
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                position: 'left',
                offset: 60,
                axisLine: {
                    lineStyle: {
                        color: colors[0]
                    },
                    show: true,
                    onZero: false
                },
            },
            {
                type: 'value',
                name: '单价/元',
                position: 'left',
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: colors[1]
                    },
                    show: true,
                    onZero: false
                },
            },
            {
                type: 'value',
                name: '支付转化率/万分',
                position: 'right',
                offset: 0,
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: colors[2]
                    },
                    show: true,
                    onZero: false
                },
            },
            {
                type: 'value',
                name: '支付金额/元',
                position: 'right',
                offset: 80,
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: colors[1]
                    },
                    show: true,
                    onZero: false
                },
            },
        ],
        series: [
            {
                name: '件单价',
                type: 'bar',
                yAxisIndex: 2,
                data: onePrice
            },
            {
                name: '客单价',
                yAxisIndex: 2,
                type: 'bar',
                data: customerPrice
            },
            {
                name: '加购件数',
                type: 'bar',
                data: add
            },
            {
                name: '支付买家数',
                type: 'bar',
                data: payCustomers
            },

            {
                name: '支付件数',
                type: 'bar',
                data: payNums
            },
            {
                name: '访客数',
                type: 'bar',
                data: uv
            },
            {
                name: '商品访客数',
                type: 'bar',
                data: spfks
            },
            {
                name: '新买家数',
                type: 'bar',
                data: xmjs
            },
            {
                name: '老买家数',
                type: 'bar',
                data: lmjs
            },
            {
                name: '老买家支付金额',
                type: 'bar',
                yAxisIndex: 3,
                data: lmjje
            },
            {
                name: '新买家支付金额',
                type: 'bar',
                yAxisIndex: 3,
                data: xmjje
            },
            {
                name: '加购人数',
                type: 'bar',
                data: jgrs
            },
            {
                name: '收藏人数',
                type: 'bar',
                data: scrs
            },
            {
                name: '收藏件数',
                type: 'bar',
                data: scjs
            },
            {
                name: '支付转化率',
                type: 'line',
                yAxisIndex: 2,
                data: payChange.map(item => { return item * 100 })
            },
        ]
    };

    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById('report'));
        // 绘制图表
        reportChart.setOption(report);
        window.addEventListener('resize', ()=>{
            reportChart.resize()
        });
    }, [report])

    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let othersChart = echarts.init(document.getElementById('others'));
        // 绘制图表
        othersChart.setOption(others);
        window.addEventListener('resize', ()=>{
            othersChart.resize()
        });
    }, [others])

    // 选择月份时
    const monthChange = (data, dataString) => {
        if (data === null) {
            return false
        } else {
            setSelectMonth(data.format('YYY-MM-DD'))
            let a = moment(data).startOf('month').format('YYYY-MM-DD')
            let b = moment(data).endOf('month').format('YYYY-MM-DD')
            setStartDate(a)
            setEndDate(b)
        }
    }
    // 仅查看当前月数据
    const checkNow = () => {
        let a = moment().startOf('month').format('YYYY-MM-DD')
        let b = moment().subtract(1, 'day').format('YYYY-MM-DD')
        setStartDate(a)
        setEndDate(b)
    }
    // 获取金额报表数据
    const getMoneyData = () => {
        let data = {}
        data.startTime = startDate
        data.endTime = endDate
        data.shop = shop
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        clearALLFiled()
        Axios({
            data: data,
            method: 'POST',
            url: servicePath.getBusinessStaffDailyResport,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    let data = res.data.data.data_dict_chart
                    setXDate(data.date)
                    setOnePrice(data.件单价)
                    setAdd(data.加购件数)
                    setGetRate(data.回款完成率)
                    setPayTarget(data.支付目标[0])
                    setRemittance(data.回款目标[0])
                    setPay(data.支付金额)
                    setGetMoney(data.实际回款)
                    setCustomerPrice(data.客单价)
                    setGoMoney(data.成功退款金额)
                    setPayCustomers(data.支付买家数)
                    setPayNums(data.支付件数)
                    setPayRate(data.支付完成率)
                    setPayAdd(data.支付累计金额)
                    setPayChange(data.支付转化率)
                    setGetAdd(data.回款累计金额)
                    setUv(data.访客数)
                    setSpfks(data.商品访客数)
                    setLmjs(data.老买家数)
                    setXmjs(data.新买家数)
                    setLmjje(data.老买家金额)
                    setXmjje(data.新买家金额)
                    setJgrs(data.加购人数)
                    setScrs(data.收藏人数)
                    setScjs(data.收藏件数)
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg']);
                    }
                }
            }
        ).catch(
            e=>{
                console.log(e);
            }
        )
    }

    // 监控开始时间和结束时间的变化请求数据
    useEffect(() => {
        if(shop != ''){
            getMoneyData()
        }
        // getOthers()
    }, [startDate, endDate])
    const check30 = () => {
        let a = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD')
        let b = moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD')
        setStartDate(a)
        setEndDate(b)
    }
    const check60 = () => {
        let a = moment().startOf('month').subtract(2, 'month').format('YYYY-MM-DD')
        let b = moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD')
        setStartDate(a)
        setEndDate(b)
    }
    const check90 = () => {
        let a = moment().startOf('month').subtract(3, 'month').format('YYYY-MM-DD')
        let b = moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD')
        setStartDate(a)
        setEndDate(b)
    }
    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

     // 查看图标的函数
     const lookTable = ()=>{
        let shopName = trademark == 'jr' ? 'zhuoya' : trademark
        props.history.push({ 'pathname': '/app/bussiness/'+shopName+'/daily'})
    }

    // 切换店铺
    const changeShop = (value)=>{
        // 切换店铺的值
        setShop(value)
        // 修改店铺名称
        setShopName(shopList[shopList.map(item => item.value).indexOf(value)]['name']);
        // 因切换店铺发起请求
        let data = {}
        data.shop = value
        data.startTime = startDate
        data.endTime = endDate
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        clearALLFiled()
        Axios({
            data: data,
            method: 'POST',
            url: servicePath.getBusinessStaffDailyResport,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res=>{
                if (res.data.success){
                    let data = res.data.data.data_dict_chart
                    setXDate(data.date)
                    setOnePrice(data.件单价)
                    setAdd(data.加购件数)
                    setGetRate(data.回款完成率)
                    setPayTarget(data.支付目标[0])
                    setRemittance(data.回款目标[0])
                    setPay(data.支付金额)
                    setGetMoney(data.实际回款)
                    setCustomerPrice(data.客单价)
                    setGoMoney(data.成功退款金额)
                    setPayCustomers(data.支付买家数)
                    setPayNums(data.支付件数)
                    setPayRate(data.支付完成率)
                    setPayAdd(data.支付累计金额)
                    setPayChange(data.支付转化率)
                    setGetAdd(data.回款累计金额)
                    setUv(data.访客数)
                    setSpfks(data.商品访客数)
                    setLmjs(data.老买家数)
                    setXmjs(data.新买家数)
                    setLmjje(data.老买家金额)
                    setXmjje(data.新买家金额)
                    setJgrs(data.加购人数)
                    setScrs(data.收藏人数)
                    setScjs(data.收藏件数)
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg']);
                    }
                }
            }
        )
    }

    return (
        <>
            <div style={{ marginLeft: size.width * 0.05, marginRight: size.width * 0.02, marginTop: size.height * 0.02 }}>
                <div style={{ marginRight: size.width * 0.02, marginTop: size.height * 0.01 }}>
                    <div>
                        {/* <span style={{ marginRight: 10 }}>查看每月报表:</span> */}
                        <Select style={{ width: 190, marginRight:10 }} value={shop} onChange={changeShop}>
                            {
                                shopList.map(function(item){
                                    return (
                                        <Option value={item.value}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                        <DatePicker
                            picker="month"
                            locale={locale}
                            onChange={monthChange}
                            disabledDate={disableMonth}
                            style={{ width: 130 }}
                        />
                        <Button style={{ marginLeft: 10 }} type='primary' onClick={checkNow} >点此查看当月</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' onClick={check30} >前一月数据</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' onClick={check60} >前两月数据</Button>
                        <Button style={{ marginLeft: 10, marginRight: 10 }} type='primary' onClick={check90} >前三月数据</Button>
                        <span style={{ marginRight: 10 }}>当前时间跨度:</span>
                        <span >{startDate + '至'}</span>
                        <span style={{ marginRight: 10 }}>{endDate}</span>
                        <Button style={{ marginRight: '20px' }} onClick={lookTable}>查看表格</Button>
                    </div>
                    <div id="report" style={{ width: size.width*0.80, height: 415, marginTop: 10 }}></div>
                    <div id="others" style={{ width: size.width*0.80, height: 415 }}></div>
                </div>
            </div>
        </>
    )

    // 不可选的周
    function disableMonth(current) {
        // 不可选前一周
        return current && moment().endOf('month') < current;
    }
    function clearALLFiled(){
        setXDate([])
        setOnePrice()
        setAdd([])
        setGetRate([])
        setPayTarget()
        setRemittance([])
        setPay([])
        setGetMoney([])
        setCustomerPrice([])
        setGoMoney([])
        setPayCustomers([])
        setPayNums([])
        setPayRate([])
        setPayAdd([])
        setPayChange([])
        setGetAdd([])
        setUv([])
        setSpfks([])
        setLmjs([])
        setXmjs([])
        setLmjje([])
        setXmjje([])
        setJgrs([])
        setScrs([])
        setScjs([])
    }
}

export default DaliyCharts