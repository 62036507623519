import React, { useEffect, useState } from 'react';
import { Input, Typography, Checkbox, message, Modal, Button, Spin, Table, Select, DatePicker, Popconfirm, Radio ,Tabs, Popover} from 'antd'
import cookie from 'react-cookies'
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import servicePath from '../../config/apiUrl';
import NbAxios from '../../config/utils/nbAxios';
import style from './index.module.css'
const { Title, Text, Paragraph } = Typography;
const { Option } = Select
const { TabPane } = Tabs
const { RangePicker } = DatePicker
const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';
function Change(props){
    // 标签值
    const [tabKey, setTabKey] = useState('change')
    // 类型
    const [calculation_type] = useState(props.history.location.pathname.split('/')[3])
    // 是否有效
    const [loading, setLoading] = useState(false)
    // 选择的开始时间
    const [startTime, setStartTime] = useState('')
    // 选择的结束时间
    const [endTime, setEndTime] = useState('')
    // 表头字段
    const [filed] = useState(['员工ID', '员工姓名', '原公司', '原部门', '原项目', '原店铺', '原岗位', '现公司', '现部门', '现项目', '现店铺', '现岗位', '插入时间', '变更时间','变更人'])
    // 表头
    const [column, setColumn] = useState([])
    // 数据源
    const [dataSource, setDataSource] = useState([])
    // 选中的员工ID
    const [staffID, setStaffID] = useState('')
    // 员工ID列表
    const [staffIDList, setStaffIDList] = useState([])
    // 选中的人员
    const [staffData, setStaffData] = useState('')
    // BP管理人列表
    const [BPList, setBPList] = useState([])
    // 人员列表
    const [staffList, setStaffList] = useState([])
    // 选中的社保缴纳地
    const [socialAdd, setSocialAdd] = useState([])
    // 社保缴纳地列表
    const [socialAddList, setSocialAddList] = useState([])
    // 选中的公司
    const [company, setCompany] = useState([])
    // 公司列表
    const [companyList, setCompanyList] = useState([])
    // 选中的部门
    const [department, setDepartmant] = useState([])
    // 部门列表
    const [departmentList, setDepartmentList] = useState([])
    // 选中的项目
    const [project, setProject] = useState([])
    // 项目列表
    const [projectList, setProjectList] = useState([])
    // 选中的员工类型
    const [staffType, setStaffType] = useState([])
    // 员工类型列表
    const [staffTypeList, setStaffTypeList] = useState([
        {
            id: 0,
            value: '在职员工'
        },
        // {
        //     id: 1,
        //     value: '离职员工'
        // },
        // {
        //     id: 2,
        //     value: '待入职'
        // },
        {
            id: 3,
            value: '实习生'
        },
        {
            id: 4,
            value: '挂靠'
        },
        {
            id: 5,
            value: '返聘'
        },
        {
            id: 6,
            value: '兼职'
        },
    ])
    // 选中的店铺
    const [shop, setShop] = useState([])
    // 店铺列表
    const [shopList, setShopList] = useState([])
    // 选中的岗位
    const [post, setPost] = useState([])
    // 岗位列表
    const [postList, setPostList] = useState([])
    // 选中的科目
    const [subject, setSubject] = useState([])
    // 科目列表
    const [subjectList, setSubjectList] = useState([])
    useEffect(() => {
        setColumn(filed.map(item => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: "center",
                // width: item == '数据项' ? "6%" : '',
                // render: (text, record, index) => {
                //     return item.indexOf("月费用") !== -1 ? <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: "center", marginLeft: "20px" }}>
                //         <span>
                //             {record[item]?record[item].toFixed(2):record[item]}
                //         </span>
                //         <span>
                //             {record[item.split("月")[0] + "月编制人数"]?record[item.split("月")[0] + "月编制人数"].toFixed(2):record[item.split("月")[0] + "月编制人数"]}
                //         </span>
                //         <span>
                //             {record[item.split("月")[0] + "月人数"]?record[item.split("月")[0] + "月人数"].toFixed(2):record[item.split("月")[0] + "月人数"]}
                //         </span>
                //     </div> : text
                // }
            }
        }))
        setLoading(true)
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            shopId: cookie.load('shopId'),
            change_member: cookie.load('name'),
            calculation_type: calculation_type,
        }
        // 初始化下拉框数据
        const getAllData = async () => {
            await Promise.all([
                getCompany(dataProps),
                getDepartment(dataProps),
                getItem(dataProps),
                getShop(dataProps),
                getStation(dataProps),
                // getSocialSecurityStation(dataProps),
                // getMemberInformation(dataProps)
            ])
        }
        getAllData()
    }, [])
    // 查询公司列表
    const getCompany = (dataProps) => {
        NbAxios(dataProps, "POST", servicePath.getCompany).then(res => {
            setLoading(false)
            if (res.success) {
                let obj = []
                res.data.company_id_list.map(item => {
                    obj.push({
                        id: item.company_id,
                        value: item.company
                    })

                })

                setCompanyList(obj)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询公司列表出错,请联系IT')
        })
    }
    // 查询部门列表
    const getDepartment = (dataProps) => {
        NbAxios(dataProps, "POST", servicePath.getDepartment).then(res => {
            setLoading(false)
            if (res.success) {
                let obj = []
                res.data.department_id_list.map(item => {
                    obj.push({
                        id: item.department_id,
                        value: item.department
                    })

                })

                setDepartmentList(obj)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询部门列表出错,请联系IT')
        })
    }
    // 查询项目列表
    const getItem = (dataProps) => {
        NbAxios(dataProps, "POST", servicePath.getItem).then(res => {
            setLoading(false)
            if (res.success) {
                let obj = []
                res.data.item_id_list.map(item => {
                    obj.push({
                        id: item.item_id,
                        value: item.item,
                    })

                })
                setProjectList(obj)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询项目列表出错,请联系IT')
        })
    }
    // 查询店铺列表
    const getShop = (dataProps) => {
        NbAxios(dataProps, "POST", servicePath.getShop).then(res => {
            setLoading(false)
            if (res.success) {
                let obj = []
                res.data.shop_id_list.map(item => {
                    obj.push({
                        id: item.shop_id,
                        value: item.shop
                    })

                })
                setShopList(obj)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询店铺列表出错,请联系IT')
        })
    }
    // 查询岗位列表
    const getStation = (dataProps) => {
        NbAxios(dataProps, "POST", servicePath.getStation).then(res => {
            setLoading(false)
            if (res.success) {
                let obj = []
                res.data.station_id_list.map(item => {
                    obj.push({
                        id: item.station_id,
                        value: item.station
                    })

                })
                setPostList(obj)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('查询岗位列表出错,请联系IT')
        })
    }
    // 人员变更记录呈现接口
    const memberChangeShow = (dataProps) => {
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.memberChangeShow).then(res => {
            setLoading(false)
            if (res.success) {
                setDataSource(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('人员变更记录呈现接口出错,请联系IT')
        })
    }
    return (
        <div className={style.initial}>
            <Spin spinning={loading} tip="数据加载中....">
                <div className={style.externalContent}>
                    <Tabs type="card" size="small" activeKey={tabKey} onChange={(key) => {
                            setTabKey(key)
                        }}>
                        <TabPane tab={<span>人员变更记录</span>} key="change"></TabPane>
                    </Tabs>
                    <div style={{display:tabKey !=='change'?'none':''}}>
                    <div className={style.staffInfo}>
                        {/* <div style={{ marginRight: '30px'}}>
                            <div>
                                <Text>员工ID</Text>
                            </div>
                            <div>
                                <Select className={style.staffInfoSelectWidth} showSearch allowClear value={staffID} onChange={(value) => {
                                    setStaffID(value)
                                }}>
                                    {staffIDList.map(item => (
                                        <Option value={item}>{item}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div> */}
                        <div style={{ marginRight: '30px' }}>
                            <div>
                                <Text>时间</Text>
                            </div>
                            <div>
                                <RangePicker locale={locale} style={{ width: "240px" }}
                                value={startTime == '' ? ['', ''] : [moment(startTime, dateFormat), moment(endTime, dateFormat)]}
                                onChange={(date, dateString) => {
                                    setStartTime(dateString[0])
                                    setEndTime(dateString[1])
                                }
                                } />
                            </div>
                        </div>
                        <div style={{ marginRight: '30px'}}>
                            <div>
                                <Text>姓名</Text>
                            </div>
                            <div>
                                <div>
                                    {/* <Select className={style.staffInfoSelectWidth} allowClear value={staffData} onChange={(value)=>{
                                    setStaffData(value)
                                }}>
                                    {staffList.map(item=>(
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select> */}
                                    <Input style={{ width: '240px' }} allowClear value={staffData} placeholder='请填写 支持模糊查询' onChange={(e) => {
                                        setStaffData(e.target.value)
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginRight: '30px'}}>
                            <div>
                                <Text>公司</Text>
                            </div>
                            <div>
                                <div>
                                    <Select className={style.staffInfoSelectWidth} mode='multiple' maxTagCount="responsive" allowClear value={company} onChange={(value) => {
                                        setCompany(value)
                                    }}>
                                        {companyList.map(item => (
                                            <Option value={item.id}>{item.value}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginRight: '30px'}}>
                            <div>
                                <Text>部门</Text>
                            </div>
                            <div>
                                <div>
                                    <Select className={style.staffInfoSelectWidth} mode='multiple' maxTagCount="responsive" allowClear value={department} onChange={(value) => {
                                        setDepartmant(value)
                                    }}>
                                        {departmentList.map(item => (
                                            <Option value={item.id}>{item.value}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginRight: '30px'}}>
                            <div>
                                <Text>项目</Text>
                            </div>
                            <div>
                                <div>
                                    <Select className={style.staffInfoSelectWidth} mode='multiple' maxTagCount="responsive" allowClear value={project} onChange={(value) => {
                                        setProject(value)
                                    }}>
                                        {projectList.map(item => (
                                            <Option value={item.id}>{item.value}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ marginRight: '30px'}}>
                            <div>
                                <Text>社保缴纳地</Text>
                            </div>
                            <div>
                                <Select className={style.staffInfoSelectWidth} mode='multiple' maxTagCount="responsive" allowClear value={socialAdd} onChange={(value) => {
                                    setSocialAdd(value)
                                }}>
                                    {socialAddList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div> */}
                        <div style={{ marginRight: '30px'}}>
                            <div>
                                <Text>店铺</Text>
                            </div>
                            <div>
                                <Select className={style.staffInfoSelectWidth} mode='multiple' maxTagCount="responsive" allowClear value={shop} onChange={(value) => {
                                    setShop(value)
                                }}>
                                    {shopList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div style={{ marginRight: '30px'}}>
                            <div>
                                <Text>岗位</Text>
                            </div>
                            <div>
                                <Select className={style.staffInfoSelectWidth} allowClear mode='multiple' maxTagCount="responsive" value={post} onChange={(value) => {
                                    setPost(value)
                                }}>
                                    {postList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div style={{ marginRight: '30px'}}>
                            <div>
                                <Text>员工类型</Text>
                            </div>
                            <div>
                                <Select className={style.staffInfoSelectWidth} allowClear mode='multiple' maxTagCount="responsive" value={staffType} onChange={(value) => {
                                    setStaffType(value)
                                }}>
                                    {staffTypeList.map(item => (
                                        <Option value={item.id}>{item.value}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        
                        {/* <div style={{ marginRight: '30px'}}>
                            <div>
                                <Text>科目</Text>
                            </div>
                            <div>
                                <Select className={style.staffInfoSelectWidth} allowClear mode='multiple' maxTagCount="responsive" value={subject} onChange={(value) => {
                                    setSubject(value)
                                }}>
                                    {subjectList.map(item => (
                                        <Option value={item}>{item}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div> */}
                    </div>
                    <div className={style.ButtonEnd} style={{ marginTop: '20px' }}>
                        <Button type='primary' size='small' onClick={() => {
                            if(startTime === ''||endTime ===''){
                                message.warning('必须选择时间')
                                return false
                            }
                            let dataProps = {
                                roleId: cookie.load('role'),
                                shopList: cookie.load('shopList'),
                                shopId: cookie.load('shopId'),
                                change_member: cookie.load('name'),
                                calculation_type: calculation_type,
                                employeeId: staffID,
                                member_name: staffData,
                                socialAdd: socialAdd,
                                old_shop_id: shop,
                                old_station_id: post,
                                old_company_id: company,
                                old_department_id: department,
                                old_item_id: project,
                                member_type: staffType,
                                subject: subject,
                                start_time:startTime,
                                end_time:endTime,
                            }
                            memberChangeShow(dataProps)
                        }}>查询</Button>
                        <Button size='small' style={{ borderColor: "#0091FF", color: "#0091FF", borderRadius: "3px", textAlign: 'center', margin: '0px 20px 0px 20px' }}
                            onClick={() => {
                                setStaffID('')
                                setStaffData('')
                                setSocialAdd([])
                                setShop([])
                                setPost([])
                                setCompany([])
                                setDepartmant([])
                                setProject([])
                                setStaffType([])
                                setStartTime('')
                                setEndTime('')
                                let dataProps = {
                                    roleId: cookie.load('role'),
                                    shopList: cookie.load('shopList'),
                                    shopId: cookie.load('shopId'),
                                    change_member: cookie.load('name'),
                                    calculation_type: calculation_type,
                                }
                                setDataSource([])
                            }}>重置</Button>
                    </div>
                    </div>
                </div>
                <div className={style.externalContent} style={{ marginTop: '20px',display:tabKey !=='change'?'none':'' }}>
                    <h3>基础信息</h3>
                    <Table size="small" columns={column} dataSource={dataSource}
                        scroll={{ x: 3000 }}
                    />
                </div>
            </Spin>
        </div>
    )
}
export default Change