import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Row, Col, Button, DatePicker, Modal, Spin, message, Collapse, Input, Form, Select, Table, Image, Drawer, Tag } from 'antd'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import servicePath from '../../../config/apiUrl'
import cookie from 'react-cookies'
import locale from 'antd/es/date-picker/locale/zh_CN';
import axios from 'axios'
import '../../../static/css/index.css'
const { RangePicker } = DatePicker;
const { Option } = Select
const {TabPane} = Tabs
function BackOrders() {

    const [form] = Form.useForm();

    const [form1] = Form.useForm();

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    // 数量集合
    const [numList, setNumList] = useState([])

    // 商品编码
    const [spbmList, setSpbmList] = useState([])

    // 数据
    const [date, setDate] = useState([])

    // 缺货订单数据
    const [backorders, setBackorders] = useState([])

    // 选中的id列表
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    // 选中的列表对象
    const [selectedRow, setSelectedRow] = useState([])

    // 是否处理加载状态
    const [spinning, setSpinning] = useState(false)

    // 总数页数
    const [total, setTotal] = useState('')

    //页面
    const [page, setPage] = useState(1)

    // 采购单号
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('')

    // 订单号
    const [orderNumber, setOrderNumber] = useState('')

    // 平台
    const [platform, setPlatfrom] = useState('')

    // 店铺
    const [shop, setShop] = useState('')

    // 买家账号
    const [buyer, setBuyer] = useState('')

    // 开始时间
    const [startTime, setStartTime] = useState('')

    // 结束时间
    const [endTime, setEndTime] = useState('')

    // 款号
    const [styleNumber, setStyleNumber] = useState('')

    // 商品编号
    const [productId, setProductId] = useState('')

    // 商品名称
    const [productName, setProductName] = useState('')

    // 采购单号
    const [purchase, setPurchase] = useState('')

    // 商品状态
    const [status, setStatus] = useState('')

    // 品牌
    const [trademark, setTrademark] = useState('')

    // 是否采货
    const [collect, setCollect] = useState(true)

    // 创建采购单
    const [createOrder, setCreateOrder] = useState(false)

    // 打开搜索框
    const [openSearch, setOpenSearch] = useState(false)

    // 按钮是否有效
    const [ effective , setEffective ] = useState(0)


    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 表格标题
    const columns = [
        {
            title: '图片',
            key: 'image',
            dataIndex: 'image',
            width: '100px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => (
                <Image width={50} src={'https://pic.bmcsoft.cn/imge_dir/item_info/xh_outlets/' + date[index]['spbm'].substring(0, 8) + '.jpg'} />
            ),
        },
        {
            title: 'SKU',
            key: 'spbm',
            dataIndex: 'spbm',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                <CopyToClipboard text={text} onCopy={() => message.success('复制成功')}>
                    <span>{text}</span>
                </CopyToClipboard>
            )
        },
        {
            title: '订单缺货件数',
            key: 'ddqhjs',
            dataIndex: 'ddqhjs',
            width: '150px',
            align: 'center'
        },
        {
            title: '待采购',
            key: 'dcg',
            dataIndex: 'dcg',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                text == null ? 0 : parseInt(text)
            )
        },
        {
            title: '待发货',
            key: 'dfh',
            dataIndex: 'dfh',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                text == null ? 0 : parseInt(text)
            )
        },
        {
            title: '已发货',
            key: 'yfh',
            dataIndex: 'yfh',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                text == null ? 0 : parseInt(text)
            )
        },
        {
            title: '已签收',
            key: 'yqs',
            dataIndex: 'yqs',
            width: '100px',
            align: 'center',
            render:(text,record,index)=>(
                text == null ? 0 : parseInt(text)
            )
        },
        {
            title: '本地仓库存',
            key: 'bdkc',
            dataIndex: 'bdkc',
            width: '120px',
            align: 'center'
        },
        {
            title: '更新时间',
            key: 'bdkc_time',
            dataIndex: 'bdkc_time',
            width: '120px',
            align: 'center'
        },
        {
            title: '销退仓库存',
            key: 'xtckc',
            dataIndex: 'xtckc',
            width: '120px',
            align: 'center'
        },
        {
            title: '更新时间',
            key: 'xtc_time',
            dataIndex: 'xtc_time',
            width: '150px',
            align: 'center'
        },
        {
            title: '待采购数量',
            key: 'dcgsl',
            dataIndex: 'dcgsl',
            width: '120px',
            align: 'center'
        },
        {
            title: 'E3库存数量',
            key: 'e3kc',
            dataIndex: 'e3kc',
            width: '120px',
            align: 'center'
        },
        {
            title: '更新时间',
            key: 'e3kc_time',
            dataIndex: 'e3kc_time',
            width: '150px',
            align: 'center'
        }
    ]

    // 表格标题
    const columns1 = [
        {
            title: 'SKU',
            key: 'kh',
            dataIndex: 'kh',
            width: '100px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '订单号',
            key: 'ddh',
            dataIndex: 'ddh',
            width: '100px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '平台',
            key: 'pt',
            dataIndex: 'pt',
            width: '100px',
            align: 'center'
        },
        {
            title: '品牌',
            key: 'pp',
            dataIndex: 'pp',
            width: '100px',
            align: 'center'
        },
        {
            title: '店铺',
            key: 'dp',
            dataIndex: 'dp',
            width: '100px',
            align: 'center'
        },
        {
            title: '买家账号',
            key: 'mjzh',
            dataIndex: 'mjzh',
            width: '150px',
            align: 'center'
        },
        {
            title: '付款日期',
            key: 'fkrq',
            dataIndex: 'fkrq',
            width: '150px',
            align: 'center'
        },
        {
            title: '商品编号',
            key: 'spbm',
            dataIndex: 'spbm',
            width: '100px',
            align: 'center'
        },
        {
            title: '商品名称',
            key: 'spmc',
            dataIndex: 'spmc',
            width: '200px',
            align: 'center'
        },
        {
            title: '颜色集规格',
            key: 'ysjgg',
            dataIndex: 'ysjgg',
            width: '150px',
            align: 'center'
        },
        {
            title: '数量',
            key: 'sl',
            dataIndex: 'sl',
            width: '100px',
            align: 'center'
        },
        {
            title: '剩余采购数据',
            key: 'sycgsl',
            dataIndex: 'sycgsl',
            width: '100px',
            align: 'center'
        },
        {
            title: '采购数量',
            key: 'cg',
            dataIndex: 'cg',
            width: '100px',
            align: 'center'
        }
    ]

    // 改变多选
    const onSelectChange = (selectedRowKeys, selectedRow) => {
        if (selectedRow.length != 0) {
            // 判断E3库存是否为null
            if (selectedRow[selectedRow.length - 1]['e3kc'] != null) {
                // 判断E3库存是否大于0
                if (parseInt(selectedRow[selectedRow.length - 1]['e3kc']) > 0) {
                    // 判断待采购是否等于0
                    if (selectedRow[selectedRow.length - 1]['dcg'] == null){
                        // 判断待采购是否大于0
                        if (parseInt(selectedRow[selectedRow.length - 1]['dcgsl']) > 0) {
                            setSelectedRow(selectedRow)
                            setSelectedRowKeys(selectedRowKeys)
                        }
                    }
                }
            }
        } else {
            setSelectedRow([])
            setSelectedRowKeys([])
        }

    }

    // 全选改变
    const onSelectAllChange = (selected, selectedRows, changeRows) => {
        // 判断是否是第一次按全选(即选中集合的长度为0)
        if (selectedRow.length == 0) {
            // 选中集合的长度为0 则进行如下操作
            let selectedRowList = []
            let selectedRowKeysList = []
            selectedRows.map((item, index) => {
                // 判断E3库存是否为null
                if (item['e3kc'] != null) {
                    // 判断E3库存是否大于0
                    if(item['e3kc'] > 0){
                        // 判断待采购是否等于0
                        if (item['dcg'] == null){
                             // 判断待采购是否大于0
                            if (parseInt(item['dcgsl']) > 0) {
                                selectedRowList.push(item)
                                selectedRowKeysList.push(item['spbm'])
                            }
                        }
                    }
                }
            })
            setSelectedRowKeys(selectedRowKeysList)
            setSelectedRow(selectedRowList)
        } else {
            // 选中集合的长度不为0,则全部置空
            setSelectedRow([])
            setSelectedRowKeys([])
        }

    }

    // 多选设置
    const rowSelection = {
        hideSelectAll: false,
        selectedRowKeys,
        onSelectAll: onSelectAllChange,
        onChange: onSelectChange

    }

    // 获取数据
    const getAllDate = () => {
        setSpinning(true)
        setSelectedRow([])
        setSelectedRowKeys([])
        let dataProps = {
            "ddh": orderNumber,
            "pt": platform,
            "dp": shop,
            "mjzh": buyer,
            "kssj": startTime,
            "jssj": endTime,
            "kh": styleNumber,
            "spbm": productId,
            "spmc": productName,
            "cgdh": purchase,
            "spzt": status,
            "pp": trademark,
            "cg": collect,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getOutOfStockOrderCount,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setSpinning(false)
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )

    }

    // 打开创建采购单
    const openCreateOrders = () => {
        if(selectedRow.length == 0){
            message.warn('不能创建空的采购单')
        }else{
            selectedRow.map(item => {
                // 首先判断E3库存不为null
                if (item['e3kc'] != null) {
                    // 其次判断待采购数量大于0
                    if (parseInt(item['dcgsl']) > 0) {
                        // 判断E3库存和待采购数量那个小 取小的
                        if (parseInt(item['e3kc']) < parseInt(item['dcgsl'])) {
                            numList.push(item['e3kc'])
                        } else {
                            numList.push(item['dcgsl'])
                        }
                        spbmList.push(item['spbm'])
                    }
                }
            })
            setSpinning(true)
            let dataProps = {
                "spbm": spbmList,
                "num": numList,
                "roleId": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.getOutOfStockOrderList,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    setSpinning(false)
                    if (res.data.success) {
                        form1.resetFields()
                        setBackorders(res.data.data)
                        setCreateOrder(true)
                    } else {
                        message.error(res.data.errorMsg)
                    }
                }
            )
        }
        
    }


    // 确认创建采购单
    const affirmCreateOrders = () => {
        if (purchaseOrderNumber == '') {
            message.error('采购单号不能为空')
        }else if(!/^\d{12}$/.test(purchaseOrderNumber)){
            message.error('采购单号必须为12位纯数字')
        } else {
            setEffective(1)
            let idList = []
            let sycgslList = []
            let cgslList = []
            backorders.map(item => {
                idList.push(item['id'])
                sycgslList.push(item['sycgsl'])
                cgslList.push(item['cg'])
            })
            let dataProps = {
                "cgdh": purchaseOrderNumber,
                "cgdcjr": cookie.load('name'),
                "pp": trademark,
                "id": idList,
                "sycgsl":sycgslList,
                "cg":cgslList,
                "spbm": spbmList,
                "num": numList,
                "roleId": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.creatPurchaseOrder,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    setEffective(0)
                    setSpinning(false)
                    if (res.data.success) {
                        message.success("采购单创建成功")
                        setCreateOrder(false)
                        setSelectedRow([])
                        setSelectedRowKeys([])
                        setNumList([])
                        setSpbmList([])
                        setPage(1)
                        setPurchaseOrderNumber('')
                        getAllDate()
                    } else {
                        message.error(res.data.errorMsg)
                    }
                }
            )
        }
    }

    // 取消创建采购单
    const closeCreateOrders = () => {
        message.success("取消创建采购单")
        setSelectedRow([])
        setSelectedRowKeys([])
        setNumList([])
        setSpbmList([])
        setPurchaseOrderNumber('')
        setCreateOrder(false)
    }

    // 查询条件
    const search = () => {
        // 首先清空数据
        setDate([])
        // 再请求最新的数据
        getAllDate()
        setOpenSearch(false)
    }

    // 重置
    const reset = () => {
        form.resetFields()
        setOrderNumber('')
        setPlatfrom('')
        setShop('')
        setBuyer('')
        setStartTime('')
        setEndTime('')
        setStyleNumber('')
        setProductId('')
        setProductName('')
        setPurchase('')
        setStatus('')
        setTrademark('')
        setCollect(true)
        setDate([])
        setOpenSearch(false)
    }

    // 关闭搜索框
    const onSearchClose = () => {
        setOpenSearch(false)
    }



    return (
        <div>
            <Spin spinning={spinning} size="large" style={{ marginTop: size.height * 0.3 }}>
                {/* 导入按钮区域 */}
                <div style={{ marginTop: size.height * 0.02, marginBottom: size.height * 0.02, marginLeft: size.width * 0.02 }}>
                    <Button type="primary" style={{ marginLeft: '10px', marginRight: '50px' }} onClick={() => openCreateOrders()}>根据SKU创建采购单</Button>
                    <Button onClick={() => setOpenSearch(true)} style={{ marginLeft: '50px' }}>灵活查询</Button>
                    <Tag style={{fontWeight:'bold',marginLeft: '50px', fontSize:'20px'}} color="red">待采购数据=订单缺货件数-待采购-待发货-已发货-已签收-本地仓库存-(销退仓库存-1)</Tag>
                </div>
                <Drawer
                    title="查询条件"
                    placement="right"
                    closable={false}
                    onClose={onSearchClose}
                    visible={openSearch}
                    width={500}
                >
                    <Form
                        form={form}
                        {...layout}
                        labelAlign='left'
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="platform"
                                    label="平台"
                                >
                                    <Select
                                        placeholder="请选择平台"
                                        onChange={(value) => { setPlatfrom(value); }}
                                    >
                                        <Option value="">请选择平台</Option>
                                        <Option value="京东">京东</Option>
                                        <Option value="抖音">抖音</Option>
                                        <Option value="淘宝天猫">淘宝天猫</Option>
                                        <Option value="拼多多">拼多多</Option>
                                        <Option value="头条放心购">头条放心购</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="trademark"
                                    label="品牌"
                                >
                                    <Select
                                        placeholder="请选择品牌"
                                        onChange={(value) => { setTrademark(value); }}
                                    >
                                        <Option value="">请选择品牌</Option>
                                        <Option value="恩曼琳">恩曼琳</Option>
                                        <Option value="卡洛琳">卡洛琳</Option>
                                        <Option value="巨式">巨式</Option>
                                        <Option value="卓雅">卓雅</Option>
                                        <Option value="卓雅周末">卓雅周末</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="SKU"
                                    label="SKU"
                                >
                                    <Input
                                        id='SKU'
                                        value={productId}
                                        placeholder="请输入SKU"
                                        onChange={(e) => {
                                            setProductId(e.target.value)
                                        }}
                                    ></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}></Col>
                            <Col span={12}>
                                <Form.Item
                                    name="time"
                                    label="付款日期"
                                >
                                    <RangePicker
                                        showTime
                                        locale={locale}
                                        style={{ width: 375 }}
                                        format="YYYY-MM-DD HH:mm"
                                        onChange={(dates, dateStrings) => {
                                            setStartTime(dateStrings[0]);
                                            setEndTime(dateStrings[1]);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="primary" style={{ marginRight: "10px", marginLeft: '150px' }} onClick={() => search()}>查询</Button>
                                <Button type="primary" danger onClick={() => reset()}>重置</Button>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
                <div style={{ marginTop: size.height * 0.025, marginRight: size.width * 0.06, width:'100%' }}>
                    {/* 表单区域 */}
                    <Table
                        //表格列的配置描述
                        columns={columns}
                        rowSelection={rowSelection}
                        //数据数组
                        dataSource={date}
                        pagination={false}
                        //表格行 key 的取值
                        scroll={{ x:'70vw', y: `${size.width>1500?'75vh':'69vh'}` }}
                        rowKey={'spbm'}
                        onRow={function (record, index) {
                            return (
                                {
                                    style: {
                                        backgroundColor: index % 2 == 0 ? '#D3D3D3' : '#FFFFFF'
                                    },
                                }
                            )
                        }}
                    />
                </div>
            </Spin>

            <Modal
                title="创建采购单"
                centered={true}
                visible={createOrder}
                onOk={affirmCreateOrders}
                onCancel={closeCreateOrders}
                okText="创建采购单"
                cancelText="取消"
                width={size.width*0.8}
                okButtonProps = {{disabled : effective == 1 ? true : false}}
                cancelButtonProps = {{disabled : effective == 1 ? true : false}}
            >
                <Form form={form1} {...layout}  labelAlign='left'>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="采购单号" name="purchaseOrderNumber">
                                <Input
                                    id='purchaseOrderNumber'
                                    value={purchaseOrderNumber}
                                    placeholder="请输入采购单号"
                                    onChange={(e) => {
                                        setPurchaseOrderNumber(e.target.value)
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="采购单创建人" name="people">
                                <Input
                                    id='people'
                                    disabled
                                    defaultValue={cookie.load('name')}
                                ></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="已中SKU详情" key="1">
                            <Table
                                //表格列的配置描述
                                columns={columns}
                                //数据数组
                                dataSource={selectedRow}
                                pagination={false}
                                scroll={{ y: 600, x: 1200 }}
                                //表格行 key 的取值
                                rowKey={'id'}
                                onRow={function (record, index) {
                                    return (
                                        {
                                            style: {
                                                backgroundColor: index % 2 == 0 ? '#D3D3D3' : '#FFFFFF'
                                            },
                                        }
                                    )
                                }}
                            />
                        </TabPane>
                        <TabPane tab="相关缺货单" key="2">
                            <Table
                                //表格列的配置描述
                                columns={columns1}
                                //数据数组
                                dataSource={backorders}
                                pagination={false}
                                scroll={{ y: 600, x: 1200 }}
                                //表格行 key 的取值
                                rowKey={'id'}
                                onRow={function (record, index) {
                                    return (
                                        {
                                            style: {
                                                backgroundColor: index % 2 == 0 ? '#D3D3D3' : '#FFFFFF'
                                            },
                                        }
                                    )
                                }}
                            />
                        </TabPane>
                    </Tabs>
                </Form>
            </Modal>
        </div>
    )

}

export default BackOrders