import React, { useState, useEffect } from 'react';
import { Table, Input, Space, Button, Divider, Radio, Typography, Checkbox } from 'antd'
// 引入高亮组件
import Highlighter from "react-highlight-words";
import { SearchOutlined , EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import CommodityImage from '../commodityImage';
const { Text } = Typography
function Index({
    // 差异表数据
    differenceData,
    // 下标
    differenceIndex,
    // 店铺
    shop
}) {

    // 删除数组中第一个匹配的元素，成功则返回位置索引，失败则返回 -1。
    Array.prototype.deleteElementByValue = function (varElement) {
        var numDeleteIndex = -1;
        for (var i = 0; i < this.length; i++) {
            // 严格比较，即类型与数值必须同时相等。
            if (this[i] === varElement) {
                this.splice(i, 1);
                numDeleteIndex = i;
                break;
            }
        }
        return numDeleteIndex;
    }
    //定义筛选功能
    const [searchText, setSearchText] = useState('')

    const [searchedColumn, setSearchedColumn] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };

    // 表格是否展示
    const [isShow, setIsShow] = useState(1)

    const [column, setColumn] = useState([])

    const [data, setData] = useState([])

    // 表头集合
    const [columnList1, setColumnList1] = useState([])

    // 表头集合
    const [columnList2, setColumnList2] = useState([])

    // 表头集合
    const [columnList3, setColumnList3] = useState([])
    // 表头集合
    const [column1, setColumn1] = useState([])
    const [column2, setColumn2] = useState([])
    const [column3, setColumn3] = useState([])
    // 多选款
    const [checkboxList, setCheckboxList] = useState([])

    // 选择的多选款
    const [selectedCheck, setSelectedCheck] = useState([])
    // 单选框
    const [radioList, setRadioList] = useState([])
    const [radioValue, setRadioValue] = useState(0)
    // 图例
    const [legend, setLegend] = useState([])
    // 数据下标
    const [ dataIndex , setDataIndex ] = useState(-1)
    // 数据集
    const [ dataSet , setDataSet] = useState('')

    useEffect(() => {
        let column = [
            {
                title: '数据集',
                dataIndex: "数据集",
                key: "数据集",
                align: 'center',
                fixed: true,
                width: 100,
                onHeaderCell:(record, index)=>{
                    return (
                        {
                            style:{
                                whiteSpace:'pre'
                            }
                        }
                    )
                },
                ...getColumnSearchProps('数据集'),
                render: (text, record, index) => (
                    // <div>{text.split(',')}</div>
                    <CommodityImage style={{width:60}} index={differenceIndex} shopName={shop} sku={text.split(',')[1]} id={text.split(',')[0]}/>
                )
            },
            ...column1.map((item) => {
                return {
                    title: item.name,
                    dataIndex: item.key,
                    key: item.key,
                    align: 'center',
                    width: 100,
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    whiteSpace:'pre',
                                    backgroundColor: item.group == 1 ? '#fa897b ' : item.group == 2 ? '#ffdd94' : '#75cce8'
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div onMouseEnter={()=>{
                            setDataIndex(item.index);
                            setDataSet(record['数据集']);
                        }} onMouseLeave={()=>{
                            setDataIndex(-1);
                            setDataSet('');
                        }}style={{backgroundColor: item.index == dataIndex && dataSet == record['数据集'] ? '#b7dde9':''}}>{text}</div>
                    )
                }
            }),
            ...column2.map((item) => {
                return {
                    title: item.name,
                    dataIndex: item.key,
                    key: item.key,
                    align: 'center',
                    width: 100,
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    whiteSpace:'pre',
                                    backgroundColor: item.group == 1 ? '#fa897b ' : item.group == 2 ? '#ffdd94' : '#75cce8'
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div onMouseEnter={()=>{
                            setDataIndex(item.index);
                            setDataSet(record['数据集']);
                        }} onMouseLeave={()=>{
                            setDataIndex(-1);
                            setDataSet('');
                        }}style={{backgroundColor: item.index == dataIndex && dataSet == record['数据集'] ? '#b7dde9':''}}>{text}</div>
                    )
                }
            }),
            ...column3.map((item) => {
                return {
                    title: item.name,
                    dataIndex: item.key,
                    key: item.key,
                    align: 'center',
                    width: 100,
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    whiteSpace:'pre',
                                    backgroundColor: item.group == 1 ? '#fa897b ' : item.group == 2 ? '#ffdd94' : '#75cce8'
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div onMouseEnter={()=>{
                            setDataIndex(item.index);
                            setDataSet(record['数据集']);
                        }} onMouseLeave={()=>{
                            setDataIndex(-1);
                            setDataSet('');
                        }} style={{backgroundColor: item.index == dataIndex && dataSet == record['数据集'] ? '#b7dde9':''}}>{text}</div>
                    )
                }
            })
        ]
        setColumn(column)
    }, [column3, dataIndex, dataSet])

    useEffect(() => {
        setData(differenceData['data']);
        setColumnList1(differenceData['columnList1']);
        setColumnList2(differenceData['columnList2']);
        setColumnList3(differenceData['columnList3']);
        setColumn1(differenceData['columnList1']);
        setColumn2(differenceData['columnList2']);
        setColumn3(differenceData['columnList3']);
        setCheckboxList(differenceData['checkboxList']);
        setSelectedCheck(differenceData['selectedCheck']);
        setRadioList(differenceData['radioList'])
        setRadioValue(differenceData['radioValue'])
        setLegend(differenceData['legend'])
    }, [differenceData])

   
    return (
        <div>
            <Divider orientation="left">差异表</Divider>
            <div style={{ display: 'grid', gridTemplateColumns: "10px 1fr" }}>
                {
                    isShow == 0 ? <EyeOutlined style={{ marginLeft: 7, color: "#039be5", fontSize: 20 }} onClick={() => {
                        setIsShow(1)
                    }} /> :
                        <EyeInvisibleOutlined style={{ marginLeft: 7, color: "#039be5", fontSize: 20 }} onClick={() => {
                            setIsShow(0)
                        }} />
                }
                <div style={{ width: 'calc(98vw - 10px)', marginLeft: 20, display: isShow == 1 ? '' : 'none' }}>
                    <div style={{ display: 'flex', justifyContent: "space-between"}}>
                        <div style={{ display: 'flex'}}>
                            <Text strong style={{ fontSize: 16, marginRight: 10 }}>图例:</Text>
                            {
                                legend.map((item, index) => {
                                    return <div style={{ display: "flex" }}>
                                        <div style={{ width: 30, height: 15, marginTop: 5, backgroundColor: index == 0 ? '#fa897b ' : index == 1 ? '#ffdd94' : '#75cce8' }}></div>
                                        <Text strong style={{ marginLeft: 5, marginRight: 10 }}>{item}</Text>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{ marginLeft: 20, display: isShow == 1 ? '' : 'none' }}>
                            <Radio.Group value={radioValue} onChange={(e) => {
                                let indexList = selectedCheck
                                let column1 = []
                                columnList1.map(item => {
                                    if (indexList.indexOf(item.index) != -1) {
                                        column1.push(item)
                                    }
                                })
                                let column2 = []
                                columnList2.map(item => {
                                    if (indexList.indexOf(item.index) != -1) {
                                        column2.push(item)
                                    }
                                })
                                let column3 = []
                                columnList3.map(item => {
                                    if (indexList.indexOf(item.index) != -1) {
                                        column3.push(item)
                                    }
                                })
                                setRadioValue(e.target.value)
                                if (e.target.value == 0) {
                                    setColumn1(column1)
                                    setColumn2(column2)
                                    setColumn3(column3)
                                } else if (e.target.value == 1) {
                                    setColumn1(column1)
                                    setColumn2(column3)
                                    setColumn3(column2)
                                } else {
                                    setColumn1(column2)
                                    setColumn2(column1)
                                    setColumn3(column3)
                                }
                            }}>
                                {
                                    radioList.map((item, index) => {
                                        return <Radio value={index}>{item}</Radio>
                                    })
                                }
                            </Radio.Group>
                        </div>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr 200px" }}>
                        <Text strong>可选指标</Text>
                        <div style={{ width: '100%' }}>
                            {
                                checkboxList.map(item => {
                                    return <Checkbox checked={selectedCheck.indexOf(item.index) != -1 ? true : false}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...selectedCheck]
                                                obj.push(item.index)
                                                setSelectedCheck(obj)
                                            } else {
                                                let obj = [...selectedCheck]
                                                obj.deleteElementByValue(item.index)
                                                setSelectedCheck(obj)
                                            }
                                        }}
                                        value={item.index}>{item.name}</Checkbox>
                                })
                            }
                        </div>
                        <div style={{ display: 'flex', marginLeft: 20 }}>
                            <Button type="primary" onClick={() => {
                                let indexList = selectedCheck
                                let column1 = []
                                columnList1.map(item => {
                                    if (indexList.indexOf(item.index) != -1) {
                                        column1.push(item)
                                    }
                                })
                                let column2 = []
                                columnList2.map(item => {
                                    if (indexList.indexOf(item.index) != -1) {
                                        column2.push(item)
                                    }
                                })
                                let column3 = []
                                columnList3.map(item => {
                                    if (indexList.indexOf(item.index) != -1) {
                                        column3.push(item)
                                    }
                                })
                                if (radioValue == 0) {
                                    setColumn1(column1)
                                    setColumn2(column2)
                                    setColumn3(column3)
                                } else if (radioValue == 1) {
                                    setColumn1(column1)
                                    setColumn2(column3)
                                    setColumn3(column2)
                                } else {
                                    setColumn1(column2)
                                    setColumn2(column1)
                                    setColumn3(column3)
                                }
                            }}>确定</Button>
                            <Button type="primary" danger onClick={() => {
                                setSelectedCheck([])
                            }}>清空</Button>
                        </div>
                    </div>
                    <Table bordered={true} style={{marginTop:5,}} dataSource={data} columns={column} pagination={false} scroll={{ y: 300, x: 1000 }} />
                </div>
            </div>

        </div>
    )

}


export default Index