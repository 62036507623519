import React, {useState, useEffect, useCallback} from 'react';
import {Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Select, Form, Tag, Space} from 'antd'
import {EditOutlined, DeleteOutlined, RedoOutlined, SearchOutlined} from '@ant-design/icons';
import servicePath from '../../config/apiUrl'
import Highlighter from 'react-highlight-words';
import axios from 'axios'
import cookie from 'react-cookies'
import '../../static/css/Table.css'
import '../../static/css/index.css'
const {confirm} = Modal
const {Option} = Select

function Shop() {

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm()

    //页面
    const [page, setPage] = useState(1)

    //板块数据
    const [data, setData] = useState([])

    //备份
    const [backups, setBackups] = useState([])

    //板块Id
    const [id, setId] = useState(0)

    //板块名字
    const [shopname, setShopname] = useState('')

    //板块英文名
    const [engname, setEngname] = useState('')

    //板块类型名字
    const [type, setType] = useState('')

    //是否增加板块
    const [addShop, setAddShop] = useState(false)

    //是否修改板块
    const [updateShop, setUpdateShop] = useState(false)

    // 筛选字段
    const [searchText, setSearchText] = useState('')

    // 筛选关键词
    const [searchedColumn, setSearchedColumn] = useState('')

    // 筛选信息
    const [searchMessage, setSearchMessage] = useState('暂无筛选信息')

    //第一次筛选的关键字
    const [column1, setColumn1] = useState('')

    //第一次筛选的值
    const [text1, setText1] = useState('')

    //筛选次数
    const [count, setCount] = useState(0)

   // 筛选功能
   const getColumnSearchProps = dataIndex => ({
       filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
           <div style={{ padding: 8 }}>
               <Input
                   ref={node => {
                       // searchInput = node;
                   }}
                   placeholder={`Search ${dataIndex}`}
                   value={selectedKeys[0]}
                   onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                   onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                   style={{ width: 188, marginBottom: 8, display: 'block' }}
               />
               <Space>
                   <Button
                       type="primary"
                       onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       icon={<SearchOutlined />}
                       size="small"
                       style={{ width: 90 }}
                   >
                       Search
           </Button>
                   <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                       Reset
           </Button>
               </Space>
           </div>
       ),
       filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
       onFilter: (value, record) =>
           record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
       onFilterDropdownVisibleChange: visible => {
           if (visible) {
               // setTimeout(() => searchInput.select());
           }
       },
       render: text =>
           searchedColumn === dataIndex ? (
               <Highlighter
                   highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                   searchWords={[searchText]}
                   autoEscape
                   textToHighlight={text.toString()}
               />
           ) : (
                   text
               ),
   });

   // 根据筛选信息更新数据
   useEffect(() => {
       
       if (searchedColumn == "shopname") {
           if (count == 1) {
               let newData = backups.filter(item => item.shopname.toString().toLowerCase().includes(searchText.toLowerCase()))
               setData(newData)
           }
       } else {
           setData(backups)
           let newMessage = '暂无筛选信息'
           setSearchMessage(newMessage)
       }
   }, [searchText, searchedColumn])

   // 筛选选择
   const handleSearch = (selectedKeys, confirm, dataIndex) => {
       confirm();
       // 将次数保存到新的值
       let newCount = count
       if (newCount == 0) {
           //第一次筛选，之前没有关键字查询，直接次数加一
           setSearchText(selectedKeys[0])
           setSearchedColumn(dataIndex)
           //保存第一次筛选的关键字和值
           setColumn1(dataIndex)
           setText1(selectedKeys[0])
           newCount = newCount + 1;
           let newMessage = "筛选条件:在"+"\xa0\xa0"+"板块名称"+"\xa0\xa0"+"筛选" + "\xa0\xa0" + selectedKeys[0]
           setSearchMessage(newMessage)
       } else {
           //有一个关键字查询，判断是否是同一个字段查询
           if (column1 == dataIndex) {
               //如果是都一个字段，次数不进行改变
               setSearchText(selectedKeys[0])
               setSearchedColumn(dataIndex)
               setColumn1(dataIndex)
               setText1(selectedKeys[0])
               let newMessage = "筛选条件:在"+"\xa0\xa0"+"板块名称"+"\xa0\xa0"+"筛选" + "\xa0\xa0" + selectedKeys[0]
               setSearchMessage(newMessage)
           }
       }
       //保存次数
       setCount(newCount)
   };

   // 筛选清空
   const handleReset = clearFilters => {
       clearFilters();
       setSearchText('')
       setSearchedColumn('')
       // 次数减一
       let newCount = count - 1;
       setCount(newCount)
   };

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger:false,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    const columns = [
        {
            title: '名字',
            key: 'shopname',
            dataIndex: 'shopname',
            width: '200px',
            align: 'center',
            ...getColumnSearchProps('shopname'),
        },
        {
            title: '代号',
            key: 'engname',
            dataIndex: 'engname',
            width: '200px',
            align: 'center'
        },
        {
            title: '类型',
            key: 'type',
            dataIndex: 'type',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <Tag color="blue">{text}</Tag>
            )
        },
        {
            title: '状态',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == true ? <Tag color="red">禁用</Tag>
                    : <Tag color="blue">正常</Tag>
            ),
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={() => updateShopModel(index)}/>
            ),
        },
        {
            title: '禁用/恢复',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == true ? <RedoOutlined onClick={() => recoverShop(index)}/>
                    : <DeleteOutlined onClick={() => shopRelevanceMenus(index)}/>
            ),
        }
    ]

    // 获取所有板块
    useEffect(() => {
        queryAllShops()
    }, [])


    // 查询所有板块
    const queryAllShops = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryShops,
            data: dataProps,
            header: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (count == 0){
                    // 没有筛选的时候，数据就不进行处理
                    setData(res.data['data']);
                    setBackups(res.data['data'])
                }else{
                    // 有筛选的时候，数据进行过滤
                    let newData = res.data['data'].filter(item => item.shopname.toString().toLowerCase().includes(searchText.toLowerCase()))
                    setData(newData)
                    setBackups(res.data['data'])
                }
            }
        )
    }

    // 确认添加
    const addOk = () => {
        if (shopname== ''){
            message.error('板块名字不能为空')
        }else if(engname == ''){
            message.error('板块拼音不能为空')
        }else if(type == ''){
            message.error('板块类型必选')
        }else{
            //向后端传递数据
            let dataProps = {
                'shopName': shopname,
                'engName': engname,
                'type': type,
                "role_Id": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.addShop,
                data: dataProps,
                header: {
                    'Content-Type': 'application/json'  //如果写成contentType会报错
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        //提示用户成功添加板块
                        message.success('成功添加板块')
                        setType('')
                        setEngname('')
                        setShopname('')
                        form.resetFields();
                        //关闭弹框
                        setAddShop(false)
                        //重新请求板块列表
                        queryAllShops()
                    } else {
                        //提示用户添加板块失败
                        message.error(res.data['errorMsg'])
                    }
                }
            )
        }
        
    }

    //取消添加
    const addCancel = () => {
        //提示用户成功添加板块
        message.success('取消添加板块')
        setType('')
        setEngname('')
        setShopname('')
        form.resetFields();
        //关闭弹框
        setAddShop(false)
    }

    //弹出更新板块弹框
    const updateShopModel = (index) => {
        index = (page - 1) * 10 + index
        form.resetFields();
        setId(data[index]['id'])
        setShopname(data[index]['shopname'])
        setEngname(data[index]['engname'])
        setType(data[index]['type'])
        setUpdateShop(true)
    }

    //确认修改
    const updateOk = () => {
        if(id == ''){
            message.error('板块必选')
        }else if (shopname== ''){
            message.error('板块名字不能为空')
        }else if(engname == ''){
            message.error('板块拼音不能为空')
        }else if(type == ''){
            message.error('板块类型必选')
        }else{
            let dataProps = {
                'shopId': id,
                'shopName': shopname,
                'engName': engname,
                'type': type,
                "role_Id": cookie.load('role'),
                "shop_Id": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.updateShop,
                data: dataProps,
                header: {
                    'Content-Type': 'application/json'  //如果写成contentType会报错
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success('成功修改板块')
                        form.resetFields();
                        setId(0)
                        setType('')
                        setEngname('')
                        setShopname('')
                        setUpdateShop(false)
                        queryAllShops()
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            )
        }
      
    }

    //取消修改
    const updateCancel = () => {
        message.success('取消修改板块')
        form.resetFields();
        setId(0)
        setType('')
        setEngname('')
        setShopname('')
        setUpdateShop(false)
    }

    //查询板块是否还关联菜单并且删除板块
    const shopRelevanceMenus = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        //获取角色id
        let shopId = data[index]['id']
        let dataProps = {
            'shopId': shopId.toString(),
            "role_Id": cookie.load('role'),
            "shop_Id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.shopRelevanceMenu,
            data: dataProps,
            header: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    confirm({
                        title: '该板块没有有关联菜单,是否禁用该板块',
                        okText: '确定',
                        cancelText: '取消',
                        onOk() {
                            axios({
                                method: 'POST',
                                url: servicePath.deleteShop,
                                data: dataProps,
                                header: {
                                    'Content-Type': 'application/json'  //如果写成contentType会报错
                                }
                            }).then(
                                res => {
                                    if (res.data['success']) {
                                        message.success("成功禁用这个板块")
                                        queryAllShops()
                                    }
                                }
                            )
                        },
                        onCancel() {
                            message.success('取消禁用这个板块')
                        }
                    })
                } else {
                    confirm({
                        title: '该板块有有关联菜单,是否禁用该板块',
                        okText: '确定',
                        cancelText: '取消',
                        onOk() {
                            axios({
                                method: 'POST',
                                url: servicePath.deleteShop,
                                data: dataProps,
                                header: {
                                    'Content-Type': 'application/json'  //如果写成contentType会报错
                                }
                            }).then(
                                res => {
                                    if (res.data['success']) {
                                        message.success("成功禁用这个板块")
                                        queryAllShops()
                                    }
                                }
                            )
                        },
                        onCancel() {
                            message.success('取消禁用这个板块')
                        }
                    })
                }
            }
        )
    }

    const recoverShop = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        //获取角色id
        let shopId = data[index]['id']
        let dataProps = {
            'shopId': shopId.toString(),
            "role_Id": cookie.load('role'),
            "shop_Id": cookie.load('shopList')
        }
        confirm({
            title: '是否恢复这个板块',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                axios({
                    method: 'POST',
                    url: servicePath.recoverShop,
                    data: dataProps,
                    header: {
                        'Content-Type': 'application/json'  //如果写成contentType会报错
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功恢复这个板块")
                            queryAllShops()
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消恢复这个板块')
            }
        })
    }


    return (
        <>
            {/* 导航区域 */}
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.02}}>
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                    <Breadcrumb.Item>板块管理</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* 按钮区域 */}
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.06}}>
                <Row>
                    <Col span={3}>
                        <Button type="primary" onClick={() => setAddShop(true)}>新增板块</Button>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06}}>
                <div style={{ marginTop: '20px', marginBottom: "10px" }}><Tag color="pink" style={{ height: "30px", lineHeight: "30px", fontSize: "20px" }}>{searchMessage}</Tag></div>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={data}
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                />
            </div>

            {/* 对话框 添加板块 */}
            <Modal
                title="添加板块"
                visible={addShop}
                onOk={addOk}
                onCancel={addCancel}
                okText="确认添加"
                cancelText="取消添加"
            >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="板块名字" name="shopname">
                        {/* 板块名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入板块名字'
                            placement="topLeft"
                        >
                            <Input
                                id="shopname"
                                size="default"
                                placeholder="请输入板块名字"
                                value={shopname}
                                onChange={(e) => {
                                    setShopname(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="板块代号" name="engname">
                        {/* 板块英文名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入板块代号'
                            placement="topLeft"
                        >
                            <Input
                                id="engname"
                                size="default"
                                placeholder="请输入板块代号"
                                value={engname}
                                onChange={(e) => {
                                    setEngname(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="板块类型" name="shoptype">
                        {/* 选择板块类型 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择板块类型"
                            placement="topLeft"
                        >
                            <Select placeholder="请选择板块类型" style={{width: 315}}
                                    onChange={(value) => setType(value)}>
                                <Option key="竞品" value="竞品">竞品</Option>
                                <Option key="经销" value="经销">经销</Option>
                                <Option key="星云" value="星云">星云</Option>
                                <Option key="NB" value="NB">NB</Option>
                                <Option key="代运营" value="代运营">代运营</Option>
                                <Option key="后台管理" value="后台管理">后台管理</Option>
                            </Select>

                        </Tooltip>
                    </Form.Item>
                </Form>

            </Modal>

            {/* 对话框 修改板块 */}
            <Modal
                title="修改板块"
                visible={updateShop}
                onOk={updateOk}
                onCancel={updateCancel}
                okText="确认修改"
                cancelText="取消修改"
            >
                <Form form={form} {...layout}  labelAlign='left'>
                    <Form.Item label="板块名字" name="shopname">
                        {/* 板块名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入板块名字'
                            placement="topLeft"
                        >
                            <Input
                                id="shopname"
                                size="default"
                                placeholder="请输入板块名字"
                                value={shopname}
                                onChange={(e) => {
                                    setShopname(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="板块代号" name="engname">
                        {/* 板块英文名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title='请输入板块代号'
                            placement="topLeft"
                        >
                            <Input
                                id="engname"
                                size="default"
                                placeholder="请输入板块代号"
                                value={engname}
                                onChange={(e) => {
                                    setEngname(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="板块类型" name="shoptype">
                        {/* 选择板块类型 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择板块类型"
                            placement="topLeft"
                        >
                            <Select defaultValue={type} style={{width: 315}}
                                    onChange={(value) => setType(value)}>
                                <Option key="竞品" value="竞品">竞品</Option>
                                <Option key="经销" value="经销">经销</Option>
                                <Option key="星云" value="星云">星云</Option>
                                <Option key="NB" value="NB">NB</Option>
                                <Option key="代运营" value="代运营">代运营</Option>
                                <Option key="后台管理" value="后台管理">后台管理</Option>
                            </Select>

                        </Tooltip>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}

export default Shop