import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Tag, Form, Select, Space } from 'antd'
import { EditOutlined, DeleteOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import servicePath from '../../config/apiUrl'
import Highlighter from 'react-highlight-words';
import axios from 'axios'
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios'
import '../../static/css/Table.css'
import '../../static/css/index.css'
const { confirm } = Modal
const { Option } = Select

function Port(props) {

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    //页面
    const [page, setPage] = useState(1)

    //接口数据
    const [data, setData] = useState([])

    //备份
    const [backups, setBackups] = useState([])

    //菜单数据
    const [menuData, setMenuData] = useState([])

    //接口名字
    const [name, setName] = useState('')

    //ID
    const [id, setId] = useState(0)

    //菜单ID
    const [menuId, setMenuId] = useState(0)

    //权限代码
    const [permission, setPermission] = useState('')

    //是否添加接口
    const [addPort, setAddPort] = useState(false)

    // 是否修改接口
    const [updatePort, setUpdatePort] = useState(false)

    // 筛选字段
    const [searchText, setSearchText] = useState('')

    // 筛选关键词
    const [searchedColumn, setSearchedColumn] = useState('')

    // 筛选信息
    const [searchMessage, setSearchMessage] = useState('暂无筛选信息')

    //第一次筛选的关键字
    const [column1, setColumn1] = useState('')

    //第一次筛选的值
    const [text1, setText1] = useState('')

    //筛选次数
    const [count, setCount] = useState(0)

    // 配置人员信息
    const [openUpdateRole, setOpenUpdateRole] = useState(false)

    // 角色集合
    const [roleList, setRoleList] = useState([])

    // 选择角色
    const [chooseRoleList, setChooseRoleList] = useState([])

    // 筛选功能
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        // searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
            </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
            </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                // setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    // 根据筛选信息更新数据
    useEffect(() => {

        if (searchedColumn == "name") {
            if (count == 1) {
                let newData = backups.filter(item => item.name.toString().toLowerCase().includes(searchText.toLowerCase()))
                setData(newData)
            }
        } else {
            setData(backups)
            let newMessage = '暂无筛选信息'
            setSearchMessage(newMessage)
        }
    }, [searchText, searchedColumn])

    // 筛选选择
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        // 将次数保存到新的值
        let newCount = count
        if (newCount == 0) {
            //第一次筛选，之前没有关键字查询，直接次数加一
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
            //保存第一次筛选的关键字和值
            setColumn1(dataIndex)
            setText1(selectedKeys[0])
            newCount = newCount + 1;
            let newMessage = "筛选条件:在" + "\xa0\xa0" + "接口名称" + "\xa0\xa0" + "筛选" + "\xa0\xa0" + selectedKeys[0]
            setSearchMessage(newMessage)
        } else {
            //有一个关键字查询，判断是否是同一个字段查询
            if (column1 == dataIndex) {
                //如果是都一个字段，次数不进行改变
                setSearchText(selectedKeys[0])
                setSearchedColumn(dataIndex)
                setColumn1(dataIndex)
                setText1(selectedKeys[0])
                let newMessage = "筛选条件:在" + "\xa0\xa0" + "接口名称" + "\xa0\xa0" + "筛选" + "\xa0\xa0" + selectedKeys[0]
                setSearchMessage(newMessage)
            }
        }
        //保存次数
        setCount(newCount)
    };

    // 筛选清空
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
        setSearchedColumn('')
        // 次数减一
        let newCount = count - 1;
        setCount(newCount)
    };



    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    const columns = [
        {
            title: '接口名字',
            key: 'name',
            dataIndex: 'name',
            width: '200px',
            align: 'center',
            ...getColumnSearchProps('name'),
        },
        {
            title: '所在菜单',
            key: 'menu',
            dataIndex: 'menu',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <div>{data[index]['menu'] + '---' + data[index]['path']}</div>
            ),
        },
        {
            title: '接口权限代码',
            key: 'permission',
            dataIndex: 'permission',
            width: '200px',
            align: 'center'
        },
        {
            title: '状态',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == 1 ? <Tag color="red">禁用</Tag>
                    : <Tag color="blue">正常</Tag>
            ),
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={() => updatePortModel(index)} />
            ),
        },
        {
            title: '禁用/恢复',
            key: 'delete',
            dataIndex: 'delete',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == 1 ? <RedoOutlined onClick={() => recoverPort(index)} />
                    : <DeleteOutlined onClick={() => deletePort(index)} />
            ),
        },
        {
            title: '配置角色',
            key: 'modification',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <EditOutlined onClick={() => {
                    setId(data[index]['id']);
                    setMenuId(data[index]['menuId'])
                    setOpenUpdateRole(true)
                }} />
            ),
        }
    ]

    //初始化数据
    useEffect(() => {
        //获取所有接口
        queryAllPort()

        //获取所有子菜单
        queryAllMenu()

        // 获取所有角色
        getAllRole()
    }, [])

    //查询全部接口
    const queryAllPort = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryPort,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    if (count == 0) {
                        // 没有筛选的时候，数据就不进行处理
                        setData(res.data['data']);
                        setBackups(res.data['data'])
                    } else {
                        // 有筛选的时候，数据进行过滤
                        let newData = res.data['data'].filter(item => item.name.toString().toLowerCase().includes(searchText.toLowerCase()))
                        setData(newData)
                        setBackups(res.data['data'])
                    }
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    //查询子菜单
    const queryAllMenu = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shop_Id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryMenu,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {

                if (res.data['success']) {
                    setMenuData(res.data['data']);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    // 获取所有角色
    const getAllRole = () => {
        let dataProps = {
            "role_Id": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.queryNorRoles,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    let data = res.data['data']
                    let roleList = [
                        {
                            id: '0',
                            name: '全选'
                        }
                    ]
                    data.map(item => {
                        roleList.push(item)
                    })
                    setRoleList(roleList)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }
                }
            }
        )
    }

    //选择菜单
    const chooseMenu = (value) => {
        let menuId = value.split(',')[0]
        setMenuId(menuId)
    }


    //确认添加
    const addOk = () => {
        if (name == '') {
            message.error('接口名字必填')
        } else if (permission == '') {
            message.error('权限代码必填')
        } else if (menuId == '') {
            message.error('菜单必选')
        } else {
            // 向后端传递数据
            let dataProps = {
                'name': name,
                'permission': permission,
                'menu_id': menuId,
                "role_Id": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }

            axios({
                method: 'POST',
                url: servicePath.addPort,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        // 提示用户成功添加接口
                        message.success('成功添加接口')
                        setName('')
                        setPermission('')
                        setMenuId(0)
                        form.resetFields();
                        // 关闭弹框
                        setAddPort(false)
                        queryAllPort()
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            //提示用户添加接口失败
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }

    }

    //取消添加
    const addCancel = () => {
        // 提示用户成功添加接口
        message.success('取消添加接口')
        setName('')
        setPermission('')
        setMenuId(0)
        form.resetFields();
        // 关闭弹框
        setAddPort(false)
        queryAllPort()
    }

    //打开修改接口窗口
    const updatePortModel = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        form.resetFields();
        setId(data[index]['id'])
        setName(data[index]['name'])
        setMenuId(data[index]['menuId'])
        setPermission(data[index]['permission'])
        setUpdatePort(true)
    }


    //确认修改
    const updateOk = () => {
        if (id == '') {
            message.error('接口必选')
        }
        else if (name == '') {
            message.error('接口名字必填')
        } else if (permission == '') {
            message.error('权限代码必填')
        } else if (menuId == '') {
            message.error('菜单必选')
        } else {
            let dataProps = {
                'id': id,
                'name': name,
                'permission': permission,
                'menu_id': menuId,
                "role_Id": cookie.load('role'),
                "shopId": cookie.load('shopList')
            }
            axios({
                method: 'POST',
                url: servicePath.updatePort,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        // 提示用户接口更新成功
                        message.success('成功修改接口')
                        setId(0)
                        setMenuId(0)
                        setName('')
                        setPermission('')
                        form.resetFields();
                        // 关闭弹框
                        setUpdatePort(false);
                        queryAllPort()
                    } else {
                        if (res.data['errorCode'] == '10001') {
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        } else {
                            //提示用户更新接口失败
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
    }

    //取消修改
    const updateCancel = () => {
        // 提示用户接口更新成功
        message.success('取消修改接口')
        setId(0)
        setMenuId(0)
        setName('')
        setPermission('')
        form.resetFields();
        // 关闭弹框
        setUpdatePort(false)
        queryAllPort()
    }

    // 禁用接口
    const deletePort = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        let id = data[index]['id']
        confirm({
            title: '确定禁用这个接口',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                //向后端传递的参数
                let dataProps = {
                    'id': id.toString(),
                    "role_Id": cookie.load('role'),
                    "shopId": cookie.load('shopList')
                }
                axios({
                    method: 'POST',
                    url: servicePath.deletePort,
                    data: dataProps,
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization': cookie.load('token')
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功禁用这个接口")
                            queryAllPort()
                        } else {
                            if (res.data['errorCode'] == '10001') {
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            }
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消禁用这个接口')
            }
        })
    }

    // 恢复接口
    const recoverPort = (index) => {
        //根据页改变下标
        index = (page - 1) * 10 + index
        let id = data[index]['id']
        confirm({
            title: '确定恢复这个接口',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                //向后端传递的参数
                let dataProps = {
                    'id': id.toString(),
                    "role_Id": cookie.load('role'),
                    "shopId": cookie.load('shopList')
                }
                axios({
                    method: 'POST',
                    url: servicePath.recoverPort,
                    data: dataProps,
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization': cookie.load('token')
                    }
                }).then(
                    res => {
                        if (res.data['success']) {
                            message.success("成功恢复这个接口")
                            queryAllPort()
                        } else {
                            if (res.data['errorCode'] == '10001') {
                                message.error(res.data['errorMsg'])
                                cookie.remove("role", { path: '/' });
                                cookie.remove("shop", { path: '/' })
                                cookie.remove("shopName", { path: '/' })
                                cookie.remove("name", { path: '/' })
                                cookie.remove("roleName", { path: '/' })
                                cookie.remove("email", { path: '/' })
                                cookie.remove("phone", { path: '/' })
                                cookie.remove("watermark", { path: '/' })
                                cookie.remove("flag", { path: '/' })
                                cookie.remove("token", { path: '/' })
                                props.history.push("/")
                            }
                        }
                    }
                )
            },
            onCancel() {
                message.success('取消恢复这个接口')
            }
        })
    }

    // 确认配置角色
    const openUpdateRoleOk = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "permId": id,
            "menuId": menuId,
            "roleIdList": chooseRoleList
        }
        NbAxios(dataProps, "POST", servicePath.batchProcessingRolePort).then(res => {
            if (res.success) {
                message.success('成功配置角色接口权限')
                setChooseRoleList([])
                setId(0)
                setMenuId(0)
                setOpenUpdateRole(false)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 取消配置角色
    const openUpdateRoleCancel = () => {
        message.success('取消配置角色')
        setChooseRoleList([])
        setId(0)
        setMenuId(0)
        setOpenUpdateRole(false)
    }


    return (
        <>
            {/* 导航区域 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.02 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                    <Breadcrumb.Item>接口管理</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* 按钮区域 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06 }}>
                <Row>
                    <Col span={3}>
                        <Button type="primary" onClick={() => setAddPort(true)}>新增接口</Button>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06 }}>
                <div style={{ marginTop: '20px', marginBottom: "10px" }}><Tag color="pink" style={{ height: "30px", lineHeight: "30px", fontSize: "20px" }}>{searchMessage}</Tag></div>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={data}
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                />
            </div>

            {/* 添加接口 */}
            <Modal
                title="添加接口"
                visible={addPort}
                onOk={addOk}
                onCancel={addCancel}
                okText="确认添加"
                cancelText="取消添加"
            >
                <Form form={form} {...layout} labelAlign='left'>
                    <Form.Item label="接口名字" name="name">
                        {/* 填写接口名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请填写接口名字"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="请输入接口名字"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="接口权限代码" name="permission">
                        {/* 填写接口权限 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请填写接口权限比如(admin::addUser)"
                            placement="topLeft"
                        >
                            <Input
                                id="path"
                                size="default"
                                placeholder="请填写接口权限比如(admin::addUser)"
                                onChange={(e) => {
                                    setPermission(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="所在菜单" name="shop">
                        {/* 选择菜单 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择菜单"
                            placement="topLeft"
                        >
                            <Select showSearch placeholder="请选择菜单" style={{ width: 310 }} onChange={chooseMenu}>
                                {
                                    menuData.map(function (item, index) {
                                        return (
                                            <Option key={item.id + ',' + item.name + ',' + item.path} value={item.id + ',' + item.name + ',' + item.path}>{item.name + '--' + item.path}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 修改接口 */}
            <Modal
                title="修改接口"
                visible={updatePort}
                onOk={updateOk}
                onCancel={updateCancel}
                okText="确认修改"
                cancelText="取消修改"
            >
                <Form form={form} {...layout} labelAlign='left'>
                    <Form.Item label="接口名字" name="name">
                        {/* 填写接口名字 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请填写接口名字"
                            placement="topLeft"
                        >
                            <Input
                                id="name"
                                size="default"
                                placeholder="请输入接口名字"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="接口权限代码" name="permission">
                        {/* 填写接口权限代码 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请填写接口权限代码"
                            placement="topLeft"
                        >
                            <Input
                                id="permission"
                                size="default"
                                placeholder="请填写接口权限代码"
                                value={permission}
                                onChange={(e) => {
                                    setPermission(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="所在菜单" name="shop">
                        {/* 选择菜单 */}
                        <Tooltip
                            trigger={['focus']}
                            title="请选择菜单"
                            placement="topLeft"
                        >
                            <Select showSearch defaultValue={menuId} placeholder="请选择菜单" style={{ width: 310 }}
                                onChange={chooseMenu}>
                                {
                                    menuData.map(function (item) {
                                        return (
                                            <Option key={item.id + ',' + item.name + ',' + item.path} value={item.id + ',' + item.name + ',' + item.path}>{item.name + '--' + item.path}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>
            {/* 配置角色 */}
            <Modal
                title="配置角色"
                visible={openUpdateRole}
                onCancel={openUpdateRoleCancel}
                onOk={openUpdateRoleOk}
                okText="确认"
                cancelText="取消"
            >
                <Select value={chooseRoleList} mode="multiple" placeholder="请选择角色" style={{ width: 370 }} onChange={(value) => setChooseRoleList(value)}>
                    {
                        roleList.map(item => {
                            return <Option value={item.id}>{item.name}</Option>
                        })
                    }
                </Select>
            </Modal>

        </>
    )

}

export default Port