import React, {useEffect, useState} from 'react';
import {Table, Form, Button, Typography, Row, Input, Select, Drawer, message, Popconfirm, Modal, Divider} from 'antd'
import {DownOutlined, RightOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import cookie from 'react-cookies'
import Base64 from 'base-64'
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'

const {Title, Text} = Typography
const {Option} = Select;
const {TextArea} = Input

// 创建事件模型
function Index(props) {

    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv':"3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein':"444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an':"7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr':"f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11':'d4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass':'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }

    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 16},
    };
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    // 页数
    const [page, setPage] = useState(1)
    // 总数
    const [total, setTotal] = useState(0)
    // 打开的key
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    // 查询事件名字
    const [searchModelName, setSearchModelName] = useState('')
    // 查询事件类型
    const [searchModelType, setSearchModelType] = useState('')
    // 查询事件级别
    const [searchModelLevel, setSearchModelLevel] = useState('')
    // 事件Id
    const [eventModelId, setEventModelId] = useState('')
    // 事件名字
    const [eventModelName, setEventModelName] = useState('')
    // 添加操作模型
    const [addModel, setAddModel] = useState(false)
    // 禁用操作模型
    const [deleteModel, setDeleteModel] = useState(false)
    // 修改操作模型
    const [updateModel, setUpdateModel] = useState(false)
    // 查看详情
    const [lookDetails, setLookDetails] = useState(false)
    // 事件等级
    const [levelList, setLevelList] = useState([])
    // 数据
    const [data, setData] = useState([])
    // 查询操作模型
    const [selectModel, setSelectModel] = useState(false)
    // 详情数据
    const [detailsData, setDetailsData] = useState([])
    // 事件模型类型
    const [type, setType] = useState('')
    // 事件级别
    const [level, setLevel] = useState('')

    let columns = [
        {
            title: '模型名字',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '模型类型',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
        },
        {
            title: '模型级别',
            dataIndex: 'level',
            key: 'level',
            align: 'center',
        },
        {
            title: '详情',
            dataIndex: 'level',
            key: 'level',
            align: 'center',
            render: (text, record, index) => {
                return <span><a onClick={() => {
                    let dataProps = {}
                    dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                    dataProps.shopList = cookie.load('shopList')
                    dataProps.roleId = cookie.load('role')
                    dataProps.id = record.id
                    NbAxios(dataProps, "POST", servicePath.queryOtmModel).then(res => {
                        if (res.success) {
                            setDetailsData(res.data);
                            setLookDetails(true)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}>查看详情</a> / <a
                    onClick={() => {
                        props.history.push({
                            'pathname': `/app/otm/${props.history.location.pathname.split('/')[3]}/subEventModel`,
                            'state': {'id': record.id, 'name': record.name, "level": record.level}
                        })
                    }}
                >修改详情</a></span>
            }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            align: 'center',
            render: (text, record, index) => {
                return <span>
                    <a onClick={() => {
                        form.resetFields()
                        setEventModelId(record.id);
                        // 设置表单的值
                        form.setFieldsValue(record)
                        setUpdateModel(true)
                    }}>修改</a> / <a onClick={() => {
                    setEventModelId(record.id);
                    setEventModelName(record.name)
                    setDeleteModel(true)
                }}>禁用</a>
                    {/* <Popconfirm
                        placement="rightTop"
                        title="确认禁用"
                        onConfirm={() => {
                            let dataProps = {}
                            dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                            dataProps.roleId = cookie.load('role')
                            dataProps.id = record.id
                            NbAxios(dataProps, "POST", servicePath.deleteOtmEventModel).then(res=>{
                                if (res.success) {
                                    message.success("事件模型禁用成功")
                                    getOtmEventModel()
                                    setEventModelId('')
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }}
                        onCancel={() => { }}
                        okText="确定"
                        cancelText="取消"
                    >
                        <a>禁用</a>
                    </Popconfirm> */}
                </span>
            }
        }
    ]

    let detailsColumns = [
        {
            title: '操作名字',
            dataIndex: 'operation_name',
            key: 'operation_name',
            align: 'center',
        },
        {
            title: '业务分类',
            dataIndex: 'type_business',
            key: 'type_business',
            align: 'center',
        },
        {
            title: '人员分类',
            dataIndex: 'type_people',
            key: 'type_people',
            align: 'center',
        }
    ]

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    useEffect(() => {
        getOtmEventModel()
    }, [page])

    useEffect(() => {
        getOtmLevelList()
    }, [])


    useEffect(() => {
        getOtmLevelList()
    }, [type])

    // 查询事件模型
    const getOtmEventModel = () => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.name = searchModelName
        dataProps.type = searchModelType
        dataProps.level = searchModelLevel
        dataProps.page = page
        dataProps.page_size = 10
        NbAxios(dataProps, "POST", servicePath.queryOtmEventModel).then(res => {
            if (res.success) {
                setTotal(res.total);
                setData(res.data);
                setSelectModel(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询事件等级列表
    const getOtmLevelList = () => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.type = type
        NbAxios(dataProps, "POST", servicePath.queryOtmLevelList).then(res => {
            if (res.success) {
                setLevelList(res.data);
                form1.resetFields()
                setLevel('')
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 添加事件模型
    const addModelFinish = values => {
        if (values.type == '') {
            message.warn('请先选择模型类型')
        } else if (level == '') {
            message.warn('请先选择模型级别')
        } else {
            values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
            values.shopList = cookie.load('shopList')
            values.roleId = cookie.load('role')
            values.creator = cookie.load('name')
            values.creator_id = cookie.load('id')
            values.creator_username = Base64.decode(cookie.load('email'))
            values.level = level
            NbAxios(values, "POST", servicePath.addOtmEventModel).then(res => {
                if (res.success) {
                    message.success("事件模型创建成功")
                    getOtmEventModel()
                    setAddModel(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 修改事件模型
    const updateModelFinish = values => {
        values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        values.shopList = cookie.load('shopList')
        values.roleId = cookie.load('role')
        values.creator = cookie.load('name')
        values.creator_id = cookie.load('id')
        values.creator_username = Base64.decode(cookie.load('email'))
        values.id = eventModelId
        NbAxios(values, "POST", servicePath.updateOtmEventModel).then(res => {
            if (res.success) {
                message.success("事件模型修改成功")
                getOtmEventModel()
                setEventModelId('')
                setUpdateModel(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 重置
    const restart = () => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.name = ''
        dataProps.type = ''
        dataProps.level = ''
        dataProps.page = 1
        dataProps.page_size = 10
        NbAxios(dataProps, "POST", servicePath.queryOtmEventModel).then(res => {
            if (res.success) {
                setSearchModelLevel('')
                setSearchModelName('')
                setSearchModelType('')
                form.resetFields()
                setTotal(res.total);
                setData(res.data);
                setSelectModel(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div>
            <div style={{marginLeft: '50px', marginTop: "30px"}}>
                <Button type="primary" onClick={() => {
                    form.resetFields()
                    setAddModel(true)
                }} style={{marginRight: 10}}>新增事件模型</Button>
                <Button type="primary" onClick={() => setSelectModel(true)} style={{marginRight: 10}}>条件查询</Button>
                <Button type="dashed" onClick={() => {
                    props.history.push({'pathname': `/app/otm/${props.history.location.pathname.split('/')[3]}/event`,})
                }}>事件创建</Button>
            </div>
            <div style={{marginLeft: '50px', marginTop: '30px', marginRight: '50px'}}>
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={data}
                    pagination={paginationProps}
                />
            </div>
            <Modal
                title="新增事件模型"
                centered="true"
                footer={null}
                onCancel={() => {
                    form.resetFields()
                    setAddModel(false)
                }}
                visible={addModel}
                width={500}
            >
                <Form form={form} name="add_event_model" onFinish={addModelFinish} autoComplete="off">
                    <Form.Item label="模型名字" name="name">
                        <Input placeholder="请输入模型名字"/>
                    </Form.Item>
                    <Form.Item label="模型类型" name="type">
                        <Select placeholder="请选择模型类型" showSearch value={type} onChange={(value) => setType(value)}>
                            <Option value="平台">平台</Option>
                            <Option value="上新">上新</Option>
                        </Select>
                    </Form.Item>
                    <Form form={form1}>
                        <Form.Item label="模型级别" name="level">
                            <Select placeholder="请选择模型级别" showSearch value={level}
                                    onChange={(value) => setLevel(value)}>
                                {
                                    levelList.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginLeft: 300}}>
                            创建事件模型
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="修改事件模型"
                centered="true"
                footer={null}
                onCancel={() => {
                    form.resetFields()
                    setEventModelId('')
                    setUpdateModel(false)
                }}
                visible={updateModel}
                width={500}
            >
                <Form form={form} name="update_event_model" onFinish={updateModelFinish} autoComplete="off">
                    <Form.Item label="模型名字" name="name">
                        <Input placeholder="请输入模型名字"/>
                    </Form.Item>
                    <Form.Item label="模型类型" name="type">
                        {/* <Select placeholder="请选择模型类型" value={form.getFieldValue('type')}>
                            <Option value="平台">平台</Option>
                            <Option value="店铺">店铺</Option>
                            <Option value="上新">上新</Option>
                        </Select> */}
                        <Text type="secondary">{form.getFieldValue('type')}</Text>
                    </Form.Item>
                    <Form.Item label="模型级别" name="level">
                        {/* <Select placeholder="请选择模型级别" value={form.getFieldValue('level')} showSearch>
                            {
                                levelList.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select> */}
                        <Text type="secondary">{form.getFieldValue('level')}</Text>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginLeft: 300}}>
                            修改事件模型
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="参看详情"
                onCancel={() => {
                    setDetailsData([])
                    setLookDetails(false)
                }}
                visible={lookDetails}
                footer={[]}
                centered={true}
                width={1000}
            >
                {
                    detailsData.map(item => {
                        return <div>
                            <Divider>{item.stage}</Divider>
                            <Table
                                //表格列的配置描述
                                columns={detailsColumns}
                                //数据数组
                                dataSource={item.data}
                                pagination={false}
                                //可展开设置
                                expandable={{
                                    expandIcon: ({expanded, onExpand, record}) =>
                                        expanded
                                            ? (<DownOutlined onClick={e => onExpand(record, e)}/>)
                                            : (<RightOutlined onClick={e => onExpand(record, e)}/>)
                                    ,
                                    expandedRowRender: record => (
                                        <div>
                                            <div style={{marginTop: "30px", display: 'flex'}}>
                                                <Title level={5} style={{
                                                    marginRight: "10px",
                                                    height: "10px",
                                                    lineHeight: "10px"
                                                }}>操作描述:</Title>
                                                <Text type="secondary" style={{
                                                    marginRight: '20px',
                                                    height: "10px",
                                                    lineHeight: "10px"
                                                }}>{record.operation_detail}</Text>
                                            </div>
                                            <div style={{marginTop: "30px", display: 'flex'}}>
                                                <Title level={5} style={{
                                                    marginRight: "10px",
                                                    height: "10px",
                                                    lineHeight: "10px"
                                                }}>操作参数名:</Title>
                                                {
                                                    record.operation_parameters.map(item => {
                                                        return <Text type="secondary" style={{
                                                            marginRight: '20px',
                                                            height: "10px",
                                                            lineHeight: "10px"
                                                        }}>{item}</Text>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    ),
                                    expandedRowKeys: expandedRowKeys,
                                    // 通过点击行来展开子行
                                    expandRowByClick: true,
                                    onExpand: (expanded, record) => {
                                        let temp = []
                                        // 判断是否是同一行进行点击
                                        if (expanded) {
                                            temp.push(record.id)
                                        }
                                        setExpandedRowKeys(temp)
                                    },
                                }}
                                rowKey={'id'}
                            />
                        </div>
                    })
                }
            </Modal>
            {/* 条件查询 */}
            <Drawer
                title="条件查询"
                placement="right"
                visible={selectModel}
                onClose={() => setSelectModel(false)}
                width={500}
            >
                <Form {...layout} form={form} labelAlign="left">
                    <Form.Item label="模型名字" name="searchOperationName">
                        <Input value={searchModelName} placeholder="请输入要查询的模型名字"
                               onChange={(e) => setSearchModelName(e.target.value)}/>
                    </Form.Item>
                    <Form.Item label="模型类型" name="type">
                        <Select placeholder="请选择模型类型" showSearch value={searchModelType}
                                onChange={(value) => setSearchModelType(value)}>
                            <Option value="平台">平台</Option>
                            <Option value="店铺">店铺</Option>
                            <Option value="上新">上新</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="模型级别" name="level">
                        <Select placeholder="请选择模型级别" showSearch value={searchModelLevel}
                                onChange={(value) => setSearchModelLevel(value)}>
                            {
                                levelList.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>
                <div style={{display: 'flex'}}>
                    <Button type="primary" style={{marginRight: 10}} onClick={() => {
                        if (page != 1) {
                            setPage(1)
                        } else {
                            getOtmEventModel()
                        }
                    }}>查询</Button>
                    <Button type="primary" danger onClick={() => {
                        setPage(1)
                        restart()
                    }}>重置</Button>
                </div>
            </Drawer>

            <Modal
                title="禁用事件模型"
                centered="true"
                onCancel={() => {
                    setEventModelId('')
                    setEventModelName('')
                    setDeleteModel(false)
                }}
                onOk={() => {
                    let dataProps = {}
                    dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                    dataProps.shopList = cookie.load('shopList')
                    dataProps.roleId = cookie.load('role')
                    dataProps.id = eventModelId
                    NbAxios(dataProps, "POST", servicePath.deleteOtmEventModel).then(res => {
                        if (res.success) {
                            message.success("事件模型禁用成功")
                            getOtmEventModel()
                            setEventModelId('')
                            setEventModelName('')
                            setDeleteModel(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}
                okText="确认"
                cancelText="取消"
                visible={deleteModel}
                width={600}
            >
                是否禁用 “ {<Text strong>{eventModelName}</Text>} ”事件模型
            </Modal>
        </div>
    )

}

export default Index