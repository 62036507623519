let ipUrl = 'https://nbapi.bmcsoft.cn/'


let colorCss = {
    darkCss : ipUrl + 'static/css/antd.dark.css',
    minCss : ipUrl + 'static/css/antd.min.css'
}


export default colorCss
