import React, { useEffect, useState } from 'react';
import style from './milkyway.module.css';
import { Button, Row, Col, Descriptions, Table, message, Tooltip, Typography, Form, Modal, Input, Spin, Card } from 'antd';
import moment from 'moment';
import servicePath from '../../config/apiUrl';
import cookie from 'react-cookies';
import NbAxios from "../../config/utils/nbAxios";
const { Paragraph, Title } = Typography;
const { TextArea } = Input;

function Position() {
    // 搜索表单
    const [searchForm] = Form.useForm()
    // 职位信息表单
    const [positionForm] = Form.useForm()
    // 新增职位窗口
    const [addPositionModal, setAddPositionModal] = useState(false)
    // 修改的职位id
    const [positionId, setPositionId] = useState('')
    // 修改职位窗口
    const [updatePositionModal, setUpdatePositionModal] = useState(false)
    // 公司总数
    const [totalCompany,setTotalCompany] = useState(0)
    // 本月新增
    const [monthNew,setMonthNew] = useState(0)
    // 修改职位窗口
    const columns = [
        {
            title: '联系人',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            align: 'left',
            width: 100,
        },
        {
            title: '手机',
            dataIndex: 'phone',
            width: 100,
            fixed: 'left',
            key: 'phone',
            align: 'center',
        },
        {
            title: '邮编',
            dataIndex: 'email_code',
            width: 100,
            fixed: 'left',
            key: 'email_code',
            align: 'center',
        },
        {
            title: '公司',
            dataIndex: 'company',
            key: 'company',
            width: 100,
            align: 'center',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            width: 100,
            align: 'center',
            render: (text, record, index) => moment(text).format('YYYY-MM-DD')
        }
    ]

    // 页码
    const [page, setPage] = useState(1)
    // 页面长度
    const [pageSize, setPageSize] = useState(10)
    // 数据总长度
    const [total, setTotal] = useState(10)
    //定义分页器的参数
    const paginationProps = {
        showSizeChanger: false,
        showQuickJumper: true,
        // 总页数
        total: total,
        //页码改变时，设置当前页码和每页显示条数
        current: page,
        onChange: (page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
        },
    }
    //定义选中的行数
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 此函数selectedRowKey为一个数组，需要取最后一个元素才能获得当前点击行
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    //行选择器的参数
    const rowSelection = {
        //隐藏选择所有行
        hideSelectAll: false,
        fixed: true,
        //选中的行数
        selectedRowKeys: selectedRowKeys,
        //行数变化的回调
        onChange: onSelectChange,
    }
    // 表格中的数据
    const [tableData, setTableData] = useState([])
    // 表格数据加载状态
    const [tableLoading, setTableLoading] = useState(false)
    useEffect(() => {
        searchPosition()
    }, [page, pageSize])

    useEffect(()=>{
        getTotalData()
    },[])

    return (
        <div className={style.positionIndexWapper} >
            <div className={style.searchArea} >
                <title className={style.controlBar}>
                    <div className={style.controlBarLeft}>
                        <Title level={3} style={{ margin: 0 }} >一枕星河官网客户信息</Title>
                        {'客户总数'+totalCompany+'/'+'本月新增'+monthNew}
                    </div>
                    <div className={style.controlBarRight}>

                    </div>
                </title>
                <div className={style.searchFrom} >
                    <Form
                        form={searchForm}
                    >
                        <Row gutter={8} >
                            <Col span={6}>
                                <Form.Item
                                    label="联系人"
                                    name='name'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={searchPosition} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="手机"
                                    name='phone'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={searchPosition} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="邮编"
                                    name='email_code'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={searchPosition} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="公司"
                                    name='company'
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Input onPressEnter={searchPosition} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div className={style.controllButtons}>
                        {/* <Button type="primary" style={{ justifySelf: "flex-end" }} onClick={openAddModal} >新增</Button> */}
                        {/* <Button type="primary" style={{ justifySelf: "flex-end" }}  >修改</Button> */}
                        <Button type="primary" style={{ justifySelf: "flex-end" }} onClick={getTotalData} >查询</Button>
                        <Button style={{ justifySelf: "flex-end" }} onClick={() => {
                            setPage(1)
                            searchForm.resetFields()
                            searchPosition()
                        }} >重置</Button>
                    </div>
                </div>
            </div>
            <div className={style.interViewTablesArea}>
                <Spin spinning={tableLoading} tip="客户信息加载中..." >
                    <Table
                        rowKey={record => record.id}
                        pagination={paginationProps}
                        columns={columns}
                        dataSource={tableData}
                        scroll={{ x: 800, y: '50vh' }}
                    />
                </Spin>
            </div>
        </div>
    )


    // 搜索职位列表
    function searchPosition() {
        let data = {}
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.page = page
        data.page_size = pageSize
        let searchData = searchForm.getFieldsValue()
        for (let key in searchData) {
            if (searchData[key]) {
                // 去空格
                data[key] = searchData[key].replace(/\s+/g, "")
            }
        }
        setTableLoading(true)
        NbAxios(data, 'POST', servicePath.getContactCompany).then(res => {
            if (res.success) {
                let data = res.data
                let total = res.total
                console.log(res);
                setTotal(total)
                setTableData(data)
            } else {
                message.warn(res.errorMsg)
            }
            setTableLoading(false)
        }).catch(e => {
            message.error('获取信息出错！')
            setTableLoading(false)
        })
    }

    // 获取统计信息
    function getTotalData() {
        let data = {}
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.date = moment().format('YYYY-MM')
        NbAxios(data, 'POST', servicePath.getTotalCompany).then(res => {
            if (res.success) {
                setTotalCompany(res.data.total)
                setMonthNew(res.data.month_company_num)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error('获取信息出错！')
        })
    }
}

export default Position