import React, { useEffect, useState } from 'react';
import { Table, Tabs, DatePicker, Spin, Skeleton, message, Empty, Input, Typography, Collapse, Image, Slider, Radio, Select, Button } from 'antd';
import { SearchOutlined, CaretRightOutlined, LeftOutlined } from '@ant-design/icons';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl';
import NbAxios from '../../../config/utils/nbAxios';
import TopCard from './components/topCard'
import Chart from './chat'
import axios from 'axios'
import PlatformEventsCalendar from '../../../component/platformEventsCalendar'
import webUrl from '../../../config/webUrl';
import AcceptBuried from '../../../config/utils/buried';
import { set } from 'lodash';
import { ReactComponent as Down } from '../report/svg/down.svg'
import { ReactComponent as Up } from '../report/svg/up.svg'
import style from './skuAnaysis.module.css';
import liveDict from '../data/liveDict';
const { Title, Text } = Typography;
const { Option } = Select
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Search } = Input;
// 日期格式
const dateFormat = 'YYYY-MM-DD'

function Index(props) {
    let shopDict = {
        'xin': '欣贺',
        'jw':"卓雅周末",
        'threePet':'三只小宠',
        'ai':'AIVEI',
        'DY':'澄光',
    }
    // tab关键字
    const [tabsKey, setTabsKey] = useState('0')
    // 折叠面板的key
    const [collapseKey, setCollapseKey] = useState(0)
    // 获取店铺名
    let shopName = props.history.location.pathname.split('/')[2]
    // 开始日期
    const [startMonth, setStartMonth] = useState(moment().subtract(14, 'day'))
    // 结束日期  
    const [endMonth, setEndMonth] = useState(moment())
    // 搜索商品id
    const [searchItem, setSearchItem] = useState('')
    // 当前查看的商品id
    const [item, setItem] = useState('');
    // 加载状态
    const [infoLoading, setInfoLoading] = useState(false)
    // 表格标签数据
    const [tableLegend, setTableLegend] = useState(['件单价', '成交件数', '成交金额', '点击量','讲解次数','曝光量','曝光-点击率', '点击成交转化率' ])
    // 表格数据
    const [tableData, setTableData] = useState([])
    // 图表数据
    const [chartData, setChartData] = useState([])
    // 成交件数 图1/柱图
    const [payNum, setPayNum] = useState([])
    // 讲解次数 图1/折线
    const [payMoney, setPayMoney] = useState([])
    // 成交金额 图一/柱图
    const [visitor, setVisitor] = useState([])
    // 曝光量 图一/折线
    const [discount, setDiscount] = useState([])
    // 曝光点击率  图1/折线
    const [addRate, setAddRate] = useState([])
    // 点击-成交转化率  图1/折线
    const [clickRate, setClickRate] = useState([])
    // 点击量 图1/折线
    const [onePrice, setOnePrice] = useState([])
    // 件单价 图2/折线
    const [changeRate, setChangeRate] = useState([])
    const [col, setCol] = useState([])
    // 时间顺序
    const [chronologically, setChronologically] = useState(1)
    // 商品信息
    const [itemInfo, setItemInfo] = useState([])
    // 设置加载中变量
    const [loading, setloading] = useState(false);
    // 销售
    const saleSeries = [
        {
            name: "讲解次数",
            data: payMoney,
            yAxisIndex: 1,
            type: 'bar',
            itemStyle: {
                color: 'rgba(255, 15, 72,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(255, 15, 72)',
                }
            }
        },
        {
            name: "成交金额",
            data: visitor,
            type: 'bar',
            itemStyle: {
                color: 'rgba(78, 203, 117,.85)',
                barBorderRadius: [10, 10, 0, 0],
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(78, 203, 117)',
                }
            }
        },
        {
            name: "成交件数",
            data: payNum,
            type: 'bar',
            itemStyle: {
                color: 'rgba(255, 224, 65,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(255, 224, 65)',
                }
            }

        },
        {
            name: "件单价",
            data: changeRate,
            yAxisIndex: 2,
            type: 'bar',
            itemStyle: {
                color: 'rgba(114, 88, 180,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(114, 88, 180)',
                }
            }
        },
        {
            name: "曝光量",
            data: discount,
            type: 'bar',
            yAxisIndex: 2,
            itemStyle: {
                color: 'rgba(78, 203, 117,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(78, 203, 117)',
                }
            }
        },
        {
            name: "点击量",
            data: onePrice,
            type: 'bar',
            itemStyle: {
                color: 'rgba(76, 175, 254,.85)',
                barBorderRadius: [10, 10, 0, 0],

            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(76, 175, 254)',
                }
            }
        },
        {
            name: "曝光-点击率",
            data: addRate,
            type: 'line',
            yAxisIndex: 2,
            itemStyle: {
                color: 'rgba(76, 175, 254,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(76, 175, 254)',
                }
            }
        },
        {
            name: "点击成交转化率",
            data: clickRate,
            type: 'line',
            yAxisIndex: 2,
            itemStyle: {
                color: 'rgba(76, 175, 254,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(76, 175, 254)',
                }
            }
        },

    ]
    // 销售y
    const saleY = [
        {
            type: 'value',
            position: 'left',
            name: '数量/(人,件)',
            nameLocation: "end",
            nameTextStyle: {
                color: "rgba(0, 0, 0, 0.7)",
                fontWeight: 600,
                align: "right",
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.4)",
                },
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
        {
            type: 'value',
            offset: 80,
            position: 'left',
            name: '金额/元',
            nameLocation: "end",
            nameTextStyle: {
                color: "rgba(0, 0, 0, 0.7)",
                fontWeight: 600,
                align: "right",
            },
            axisLabel: {
                formatter: function (data) {
                    return (data);
                }
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.4)",
                },
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
        {
            type: 'value',
            position: 'right',
            name: '百分比率%',
            nameLocation: "end",
            nameTextStyle: {
                color: "rgba(0, 0, 0, 0.7)",
                fontWeight: 600,
                align: "left",
            },
            axisLabel: {
                formatter: function (data) {
                    return (data);
                }
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.4)",
                },
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
    ]
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    function monthChange(e) {
        if (e) {
            setStartMonth(e[0])
            setEndMonth(e[1])
        }
    }
    const getData1 = (id) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopId: shopName,
            goods_id: id,
            shopList: cookie.load('shopList'),
            live_id: liveDict[props.history.location.pathname.split('/')[2]]
        }
        setloading(true)
        return NbAxios(dataProps, "POST", servicePath.getCommodityDetailData).then((res, rej) => {
            if (res.success) {
                setloading(false)
                message.success("查询成功")
                setItemInfo(res.data.data)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            setloading(false)
            message.error("出现错误！")
        })
    }
    const echartData = (id) => {
        let dataProps = {
            roleId: cookie.load('role'),
            shopId: shopName,
            goods_id: id,
            shopList: cookie.load('shopList'),
            live_id: liveDict[props.history.location.pathname.split('/')[2]],
            start_date: startMonth.format('YYYY-MM-DD'),
            end_date: endMonth.format('YYYY-MM-DD'),
        }
        setloading(true)
        return NbAxios(dataProps, "POST", servicePath.getCommodityRecentLiveData).then((res, rej) => {
            if (res.success) {
                setloading(false)
                message.success("查询成功")
                // setItemInfo()
                setTableData(res.data.table_data)
                let chartData = res.data.echarts_data
                setChartData(chartData);
                setPayNum(chartData.成交件数.data.reverse())
                setPayMoney(chartData.讲解次数.data.reverse())
                setChangeRate(chartData.件单价.data.reverse())
                setVisitor(chartData.成交金额.data.reverse())
                setDiscount(chartData.曝光量.data.reverse())
                setClickRate(chartData.点击成交转化率.data.reverse())
                setAddRate(chartData["曝光-点击率"].data.reverse())
                setOnePrice(chartData.点击量.data.reverse())
                setCol(res.data.date)
            } else {
                setInfoLoading(false)
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            setloading(false)
            message.error("出现错误！")
        })
        
    }
    function getMonthBetween(start, end) {//传入的格式YYYY-MM
        var result = [];
        var s = start.split("-");
        var e = end.split("-");
        var min = new Date();
        var max = new Date();
        min.setFullYear(s[0], s[1] * 1 - 1, 1);//开始日期
        max.setFullYear(e[0], e[1] * 1 - 1, 1);//结束日期
        var curr = min;
        while (curr <= max) {
            var month = curr.getMonth()
            result.push(moment(curr).format('YYYY-MM'));
            curr.setMonth(month + 1);
        }
        return result;
    }
    useEffect(() => {
        if (chronologically) {
            if (chartData.length != 0) {
                setPayNum(payNum.reverse())
                setPayMoney(payMoney.reverse())
                setChangeRate(changeRate.reverse())
                setVisitor(visitor.reverse())
                setDiscount(discount.reverse())
                setAddRate(addRate.reverse())
                setClickRate(clickRate.reverse())
                setOnePrice(onePrice.reverse())
                setCol(col.reverse())
            }
        } else {
            if (chartData.length != 0) {
                setPayNum(payNum.reverse())
                setPayMoney(payMoney.reverse())
                setChangeRate(changeRate.reverse())
                setVisitor(visitor.reverse())
                setDiscount(discount.reverse())
                setAddRate(addRate.reverse())
                setClickRate(clickRate.reverse())
                setOnePrice(onePrice.reverse())
                setCol(col.reverse())
            }
        }
    }, [chronologically])
    return (
        <div className={style.goodsIndexWapper}>
            <Spin spinning={loading} tip="数据加载中.......">
                <div className={style.DataArea}>
                    <div className={style.controlBar}>
                        <div className={style.controlBarLeft}>
                            <Title level={3} style={{ margin: 0 }}>{shopDict[shopName]}单款数据统计</Title>
                        </div>
                        <div className={style.controlBarMiddle}>
                            <Input value={searchItem} placeholder="请输入Id" onChange={(e) => {
                                setSearchItem(e.target.value);
                            }} addonAfter={<SearchOutlined onClick={() => {
                                setItem(searchItem)
                                if(searchItem) {
                                    const getAllData = async () => {
                                        await Promise.all([
                                            getData1(searchItem),
                                            echartData(searchItem),
                                        ])
                                    }
                                    getAllData()
                                }else{
                                    message.warning("请输入商品id")
                                }
                            }} />} />
                            {/* <Search value={item} placeholder="请输入Id" onSearch={selectItem}/> */}
                            {/* <DebounceSelect
                            showSearch
                            value={item}
                            placeholder="搜索id"
                            suffixIcon={<SearchOutlined />}
                            fetchOptions={id=>fetchUserList(id,shopName)}
                            onChange={selectItem}
                            style={{ width: '100%' }}
                        /> */}
                        </div>
                        <div className={style.controlBarRight}>
                            <div>
                                <b>时间轴: </b>
                                <Radio.Group value={chronologically} onChange={(e) => {
                                    let chronologically = ''
                                    if (e.target.value == 1) {
                                        chronologically = '正序'
                                    } else {
                                        chronologically = '倒序'
                                    }
                                    let buriedProps = {
                                        "chronologically": chronologically
                                    }
                                    AcceptBuried('choose_timeline', "click", buriedProps, props.location.pathname, "单款数据分析")
                                    setChronologically(e.target.value)
                                }}>
                                    <Radio value={1}>正序</Radio>
                                    <Radio value={0}>倒序</Radio>
                                </Radio.Group>
                                <RangePicker
                                    disabledDate={disabledDate}
                                    ranges={{
                                        '今天': [moment(), moment()],
                                        '本周': [moment().startOf('week'), moment().endOf('week')],
                                        '本月': [moment().startOf('month'), moment().endOf('month')],
                                    }}
                                    locale={locale}
                                    style={{ height: 28, width: 250 }}
                                    value={[startMonth, endMonth]}
                                    onChange={monthChange}
                                    format="YYYY-MM-DD"
                                    picker="day"
                                />
                                <Button style={{ marginLeft: "10px" }} type="primary" onClick={() => {
                                    if (searchItem) {
                                        const getAllData = async () => {
                                            await Promise.all([
                                                getData1(searchItem),
                                                echartData(searchItem),
                                            ])
                                        }
                                        getAllData()
                                    }else{
                                        message.warning("请输入商品id")
                                    }
                                    // let res = getMonthBetween(startMonth.format('YYYY-MM-DD'), endMonth.format('YYYY-MM-DD')).reverse()
                                    // setCol(res)
                                }}>查询</Button>
                            </div>
                            {/* <Button onClick={getData} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}><SyncOutlined /></Button> */}
                        </div>
                    </div>
                    <div className={style.itemInfo} >
                        <Skeleton avatar={{ shape: "square", size: 145 }} title={false} loading={itemInfo.length == 0 || false} active={infoLoading} paragraph={{ rows: 5 }} >
                            {/* <img src={`http://img.alicdn.com/bao/uploaded/i2/637882657523/O1CN01azo7Mw1qfa91UUzh1_!!0-item_pic.jpg_180x180.jpg`} /> */}
                            {/* <img
                            className={style.itemMainImg}
                            data-sku={itemInfo.length !== 0 && itemInfo[0]['value']}
                            // data-src={item && `https://pic.bmcsoft.cn/it/${shopName}/${shopName == 'vgrass' ? item.value : item.label}.jpg`}
                            data-id={itemInfo.length !== 0 && itemInfo[1]['value']}
                        /> */}
                            {/* <Image src={`https://pic.bmcsoft.cn/it/${shopName}/${itemInfo.length !== 0 && itemInfo[1]['value']}.jpg`} style={{ width: '130px', height: '130px' }}
                            fallback="https://gimg2.baidu.com/image_search/src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20170712%2F713ea78d708c4e4696ddfc78c1c3f2c5.png&refer=http%3A%2F%2F5b0988e595225.cdn.sohucs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653630406&t=74bf22102152d082aa1cae092af719f1"
                        /> */}
                            <Image style={{ width: '130px', height: '130px' }} src={`https://pic.bmcsoft.cn/it/tiktok/${itemInfo.length !== 0 && itemInfo[0]['value']}.jpg`} fallback="https://gimg2.baidu.com/image_search/src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20170712%2F713ea78d708c4e4696ddfc78c1c3f2c5.png&refer=http%3A%2F%2F5b0988e595225.cdn.sohucs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653630406&t=74bf22102152d082aa1cae092af719f1" />
                            <div className={style.itemSecond}>
                                <ul className={style.itemAttrs}>
                                    {itemInfo.length !== 0 && itemInfo.map((item, index) => {
                                        return <li className={style.attr}>{`${item.label}：${item.value || "暂无"}`}</li>
                                    })}
                                </ul>
                            </div>
                        </Skeleton>
                    </div>
                    <Tabs activeKey={tabsKey} onChange={(value) => {
                        let type = ''
                        if (value == '0') {
                            type = "销售分析"
                        } else if (value == '1') {
                            type = "潜力分析"
                        } else {
                            type = "地域分析"
                        }
                        let buriedProps = {
                            "dimensions_name": type
                        }
                        AcceptBuried('view_dimensions', "click", buriedProps, props.location.pathname, "单款数据分析")
                        setTabsKey(value)
                    }}>
                        <TabPane tab="销售分析" key={'0'}>
                            {
                                itemInfo.length == 0 ? <Empty className={style.noData}
                                    description={item != '' ? "数据加载中..." : "选择一款商品分析"}></Empty> :
                                    <div>
                                        <div className={style.sale}>
                                            <Chart title="单款销售情况分析图" id="chart" xZhou={col} legend={tableLegend}
                                                series={saleSeries} yAxis={saleY} />
                                        </div>
                                        <Table
                                            bordered
                                            className={style.saleTable}
                                            pagination={false}
                                            dataSource={tableData}
                                            scroll={{x:1500,y:1000}}
                                            columns={
                                                [
                                                    {
                                                        title: '维度',
                                                        dataIndex: 'filed',
                                                        key: 'filed',
                                                        fixed: "left",
                                                        align: 'center',
                                                        width: 80
                                                    },
                                                    {
                                                        title: '合计',
                                                        dataIndex: 'total',
                                                        key: 'total',
                                                        fixed: "left",
                                                        align: 'center',
                                                        width: 80,
                                                        render: (text, record, index) => (
                                                            <div style={{ textAlign: 'right' }}>{text}</div>
                                                        )
                                                    },
                                                    ...col.map(item => {
                                                        return {
                                                            title: item,
                                                            dataIndex: item,
                                                            key: item,
                                                            align: 'center',
                                                            width: 100,
                                                            render: (text, record, index) => (
                                                                <div style={{ textAlign: 'right' }}>{text}</div>
                                                            )
                                                        }
                                                    })
                                                ]
                                            }
                                        />
                                    </div>
                            }
                        </TabPane>
                    </Tabs>
                </div>
            </Spin>

        </div>
    )
}

export default Index