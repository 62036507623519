import React, { useEffect } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import { ReactComponent as Down } from '../svg/down.svg'
import { ReactComponent as Up } from '../svg/up.svg'
import {Typography} from 'antd'
import Debounce from 'lodash/debounce'
const {Text, Title} = Typography


function BarComponents({
    id,
    title,
    compareNum,
    height = '150px',
    currentDate = 0.5,
    comparedDate = 0.5,
    ratio
}){


    return (
        <div>
            <div>
                <Title level={4}>{title}</Title>
            </div>
            <div style={{display:'flex'}}>
                <div style={{ width: 30, height: 15, marginTop: 5, backgroundColor: '#fa897b' }}></div>
                <Text strong style={{ marginLeft: 5, marginRight: 10 }}>{"对比数据"}</Text>
                <div style={{ width: 30, height: 15, marginTop: 5, backgroundColor: '#ffdd94' }}></div>
                <Text strong style={{ marginLeft: 5, marginRight: 10 }}>{"当前数据"}</Text>
            </div>
            <div style={{display:'flex', marginTop:'10px', height:height}}>
                {
                    comparedDate == 0 && currentDate == 0 ?
                    <div style={{display:'flex', alignItems:'end'}}>
                        <div style={{width:"25px", height:'0px', backgroundColor:"#fa897b"}}></div>
                        <div style={{width:"25px", height:'0px', backgroundColor:"#ffdd94"}}></div>
                    </div>
                    :<div style={{display:'flex', alignItems:'end'}}>
                        <div style={{width:"25px", height:`calc(${comparedDate} * ${height} / (${comparedDate} + ${currentDate}))`, backgroundColor:"#fa897b"}}></div>
                        <div style={{width:"25px", height:`calc(${currentDate} * ${height} / (${comparedDate} + ${currentDate}))`, backgroundColor:"#ffdd94"}}></div>
                    </div>
                }
                
                <div style={{display:'flex', alignItems:'center', marginLeft:"20px"}}>
                    <div style={{fontSize:'20px'}}>
                        <Title level={1}>{currentDate}</Title>
                    </div>
                    {parseInt(ratio) > 0 && <Up width={12} height={12} />}
                    {parseInt(ratio) < 0 && <Down width={12} height={12} />}
                    <Text type={parseInt(ratio) > 0 ? 'danger' : 'success'} style={{fontSize:"20px", marginLeft:"5px"}}>{ratio}</Text>
                </div>
            </div>
        </div>
    )

}


export default BarComponents