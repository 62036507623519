import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment'
import { Form, Row, message, Button, Col, DatePicker, Input, Select, Upload, Progress, Drawer, Alert, Tag, Table, Menu, Dropdown } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import '../../../../static/css/index.css'
import BraftEditor from 'braft-editor'
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = 'YYYY.MM.DD';

function Member(props) {


    const [form] = Form.useForm();

    const [form1] = Form.useForm()

    const [form2] = Form.useForm()

    const [form3] = Form.useForm()

    const [form4] = Form.useForm()

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 12 },
    }

    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 20 },
    }

    // 进度
    const [percent, setPercent] = useState(30)

    // 设置编辑器初始内容
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''))

    // 人名
    const [name, setName] = useState('')

    // 内容
    const [content, setContent] = useState('')

    // 文件名列表
    const [fileNameList, setFileNameList] = useState([])

    // 文件id列表
    const [fileIdList, setFileIdList] = useState([])

    // 任务列表 
    const [taskList, setTaskList] = useState([])

    // 项目
    const [project, setProject] = useState('')

    // 团队
    const [team, setTeam] = useState('')

    // 项目集
    const [projectList, setProjectList] = useState([])

    // 团队集
    const [teamList, setTeamList] = useState([])

    // 项目名字
    const [projectName, setProjectName] = useState('')

    // 团队名字
    const [teamName, setTeamName] = useState('')

    // 人员
    const [peopleList, setPeopleList] = useState([])

    // 成员名字
    const [peopleName, setPeopleName] = useState('')

    // 日期
    const [date, setDate] = useState(moment().locale('zh-cn').format('YYYY-MM-DD'))

    // 数据
    const [data, setData] = useState([])

    // 打开日报
    const [openReport, setOpenReport] = useState(false)

    // 任务名字
    const [taskname, setTaskName] = useState('')

    // 任务项目
    const [taskproject, setTaskProject] = useState('')

    // 任务团队
    const [taskteam, setTaskTeam] = useState('')

    // 任务负责人
    const [director, setDirector] = useState('')

    // 任务协助人
    const [collaborators, setCollaborators] = useState([])

    // 任务开始时间
    const [startTime, setStartTime] = useState('')

    // 任务结束时间
    const [endTime, setEndTime] = useState('')

    // 任务天
    const [day, setDay] = useState('')

    // 任务小时
    const [hour, setHour] = useState('')

    // 父任务名
    const [pName, setPName] = useState('')

    // 任务内容
    const [taskContent, setTaskContent] = useState('')

    // 任务进度
    const [progress, setProgress] = useState(0)

    // 任务优先级
    const [priority, setPriority] = useState('')

    // 任务文件名
    const [taskFileNameList, setTaskFileNameList] = useState([])

    // 任务文件id
    const [taskFileIdList, setTaskFileIdList] = useState([])


    // 修改任务
    const [updateTask, setUpdateTask] = useState(false)


    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    useEffect(() => {
        getTaskFounderProjectList()
        getTaskFounderTeamList()
    }, [])

    useEffect(() => {
        getMemberDailyReport()
        setTeam('')
        getTaskFounderTeamList()
    }, [project])

    useEffect(() => {
        getMemberDailyReport()
    }, [team])

    useEffect(()=>{
        getMemberDailyReport()
    },[date])

    // 关闭周报
    const closeReport = () => {
        setOpenReport(false)
    }

    // 关闭任务详情
    const onUpdateTask = () => {
        setUpdateTask(false)
    }

    const getTaskFounderProjectList = () => {
        let dataProps = {
            "founder": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryTaskFounderProjectList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setProjectList(res.data.data)
                    if(res.data.data[1] != undefined){
                        setProject(res.data.data[1].id)
                    }
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )

    }

    // 获取当前人所在团队列表
    const getTaskFounderTeamList = () => {
        let dataProps = {
            "founder": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        if (project == '') {
            dataProps['project_id'] = 0
        } else {
            dataProps['project_id'] = project
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryTaskFounderTeamList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTeamList(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )

    }

    // 搜索的时候查询任务协作人列表接口
    const getSearchCollaboratorsList = () => {
        let dataProps = {
            "project_id": project,
            "team_id": team,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryCollaboratorsList,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setPeopleList(res.data.data)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 查询成员的日报接口
    const getMemberDailyReport = () => {
        let dataProps = {
            "project_id": project,
            "team_id": team,
            "date": date,
            "principal": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryMemberDailyReport,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data.success) {
                    setData(res.data.data)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 查询详情
    const lookDetail = (item) => {
        setContent(item.content)
        setName(item.founder)
        let dataProps = {
            "id": item.id,
            "name": item.founder,
            "date": date,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryMemberDailyReportDetail,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data.success) {
                    setTaskList(res.data.data.task)
                    setFileNameList(res.data.data.file_name);
                    setFileIdList(res.data.data.file_uuid)
                    setOpenReport(true)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取任务
    const getStateTask = (id) => {
        let dataProps = {
            "id": id,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        console.log(dataProps);
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryTaskDetail,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    console.log(res.data.data);
                    setTaskName(res.data.data[0].name)
                    setTaskProject(res.data.data[0].project)
                    setTaskTeam(res.data.data[0].team)
                    setDirector(res.data.data[0].director)
                    setCollaborators(res.data.data[0].collaborators)
                    setStartTime(res.data.data[0].start_time)
                    setEndTime(res.data.data[0].end_time)
                    setDay(res.data.data[0].day)
                    setHour(res.data.data[0].hour)
                    setPriority(res.data.data[0].priority)
                    setPName(res.data.data[0].pid_name)
                    setProgress(res.data.data[0].progress)
                    setTaskContent(res.data.data[0].introduction)
                    setTaskFileNameList(res.data.data[0].file_name)
                    setTaskFileIdList(res.data.data[0].file_uuid)
                    setUpdateTask(true)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    return (
        <div style={{ paddingBottom: size.height * 0.1 }}>
            <div style={{ display: 'block' }}>
                <Row>
                    <Col>
                        <div>
                            <DatePicker
                                locale={locale}
                                defaultValue={moment(moment().locale('zh-cn').format('YYYY-MM-DD'), dateFormat)}
                                format={dateFormat}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}
                                onChange={(date, dateString) => {
                                    setDate(dateString)
                                }}
                            />
                        </div>
                    </Col>
                    <Col style={{ marginLeft: size.width * 0.4 }}>
                        <Form
                            form={form}
                            {...layout}
                            labelAlign='left'
                        >
                            <Row>
                                    {/* <Col>
                                    <Select placeholder="搜索成员" style={{ width: 270 }} showSearch onChange={(value) => setPeopleName(value)}>
                                        {
                                            peopleList.map(item => {
                                                return <Option value={item.name}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Col> */}
                                <Form.Item
                                     name="project"
                                     label="项目"
                                >
                                    <Col>
                                        <Select value={project} placeholder="搜索项目" style={{ width: 200, marginRight:50 }} onChange={(value) => {
                                            setProject(value)
                                            setProjectName(projectList[projectList.map(item => item.id).indexOf(value)]['name']);
                                        }}>
                                            {
                                                projectList.map((item) => (
                                                    <Option value={item.id}>{item.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Col>
                                </Form.Item>
                                 <Form.Item
                                    name="team"
                                    label="团队"
                                >
                                     <Col>
                                        <Select value={team} placeholder="搜索团队" style={{ width: 200 }} onChange={(value) => {
                                            setTeam(value)
                                            setTeamName(teamList[teamList.map(item => item.id).indexOf(value)]['name']);
                                        }}>
                                            {
                                                teamList.map((item) => (
                                                    <Option value={item.id}>{item.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Col>
                                </Form.Item>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
            <div>
                <Row>
                    {
                        data.map(item => {
                            return (
                                <Col span={6} style={{ marginTop: '10px' }} onClick={() => lookDetail(item)}>
                                    <div style={{ height: '110px', width: size.width * 0.2 < 300 ? 300 : size.width * 0.2, border: '1px solid #ccc', borderTop: '3px solid #87CEFA', }}>
                                        <div style={{ marginLeft: '10px' }}>
                                            <div style={{ fontSize: '20px' }}>{item.founder}</div>
                                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', webkitLineClamp: '3', webkitBoxOrient: 'vertical', }}>{item.content.replace(/<[^>]*>|<\/[^>]*>/gm, "")}</div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
            <Drawer
                closable={false}
                onClose={closeReport}
                visible={openReport}
                width={900}
            >
                <div style={{ fontSize: '30px', fontWeight: 'bold' }}>
                    {name}日报
                </div>
                <div style={{ height: 400, overflow: "scroll", border: '1.5px solid #ccc' }} dangerouslySetInnerHTML={{ __html: content }}></div>
                <div>
                    <div style={{ fontSize: '15px', fontWeight: 'bold', marginTop: '10px' }}>相关文件</div>
                    <div>
                        {
                            fileNameList.map((item, index) => {
                                return <a target="_blank" href={servicePath.downloadDailyReportFile + '?principal=' + cookie.load('name') + '&file_uuid=' + fileIdList[index] + '&roleId=' + cookie.load('role') + '&shopId=' + cookie.load('shopList')}><Tag color="blue">{item}</Tag></a>
                            })
                        }
                    </div>
                </div>
                <div>
                    <div style={{ fontSize: '15px', fontWeight: 'bold', marginTop: '10px' }}>相关任务</div>
                    <div>
                        {
                            taskList.map(item => {
                                return (
                                    <div>
                                        <Alert
                                            message={item.task_name + `的进度从` + item.progress_first + `%进行到` + item.progress_second + `%`}
                                            type="info"
                                            onClick={() => getStateTask(item.task_id)} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Drawer>

            {/* 查看任务 */}
            <Drawer
                title="查看任务"
                placement="right"
                closable={false}
                onClose={onUpdateTask}
                visible={updateTask}
                width={1000}
            >
                <Form
                    form={form1}
                    {...layout1}
                    labelAlign='left'
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="任务名字"
                            >
                                {taskname}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="project"
                                label="项目"
                            >
                                {taskproject}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form form={form4} {...layout1} labelAlign='left'>
                                <Form.Item
                                    name="team"
                                    label="团队"
                                >
                                    {taskteam}
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Form form={form3} {...layout1} labelAlign='left'>
                                <Form.Item
                                    name="principal"
                                    label="负责人"
                                >
                                    {director}
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Form form={form2} {...layout1} labelAlign='left'>
                                <Form.Item
                                    name="coadjutant"
                                    label="协作者"
                                >
                                    {
                                        collaborators.map(item => {
                                            return <span >{item} </span>
                                        })
                                    }
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="time"
                                label="任务时间"
                            >
                                {startTime} - {endTime}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="duration"
                                label="预计工期"
                            >
                                {day}天{hour}小时
                                </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="yxj"
                                label="优先级"
                            >
                                {
                                    priority == "1" ? '严重'
                                        : priority == "2" ? '主要'
                                            : priority == "3" ? '次要'
                                                : priority == "4" ? '不重要'
                                                    : ''
                                }

                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="frw"
                                label="关联父任务"
                            >
                                {
                                    pName
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="type"
                                label="任务类型"
                            >

                            </Form.Item>
                        </Col>
                        <div style={{ height: 500, width: 1000, border: '1px solid #ccc', overflow: 'auto' }}>
                            <div dangerouslySetInnerHTML={{ __html: taskContent }}></div>
                        </div>
                    </Row>
                    <div>
                        <Row>
                            任务进度:
                                <Col span={20} style={{ marginLeft: '5px' }}>
                                <Progress percent={progress} />
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {
                            taskFileNameList.map((item, index) => {
                                return <a target="_blank" href={servicePath.downloadTaskFile + '?principal=' + cookie.load('name') + '&file_uuid=' + taskFileIdList[index] + '&roleId=' + cookie.load('role') + '&shopId=' + cookie.load('shopList')}><Tag color="blue">{item}</Tag></a>
                            })
                        }
                    </div>
                </Form>
            </Drawer>
        </div>
    )

}


export default Member