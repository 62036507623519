import React, {useEffect, useState} from 'react';
import {DatePicker, Typography, Divider, message, Table, Button} from 'antd'
import moment from 'moment'
import cookie from 'react-cookies'
import downExcel from '../../config/utils/downloadFile';
import axios from 'axios';
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'
import SaleImage from '../saleBoard/components/saleImage'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import locale from 'antd/es/date-picker/locale/zh_CN';
const {Title} = Typography;
const {RangePicker} = DatePicker
const dateFormat = 'YYYY-MM-DD';

function Index(props) {

    let columns = [
        {
            title: '流量来源',
            dataIndex: 'flow_source',
            key: 'flow_source',
        },
        {
            title: '访客数',
            dataIndex: 'visitor_num',
            key: 'visitor_num',
        },
        {
            title: '访客占比',
            dataIndex: 'visitor_percentage',
            key: 'visitor_percentage',
            render: (text, record, index) => (
                <span>{text}%</span>
            )
        },
        {
            title: '加购数',
            dataIndex: 'add_car_consumer_num',
            key: 'add_car_consumer_num',
        },
        {
            title: '加购占比',
            dataIndex: 'add_car_consumer_percentage',
            key: 'add_car_consumer_percentage',
            render: (text, record, index) => (
                <span>{text}%</span>
            )
        },
        {
            title: '销售额',
            dataIndex: 'pay_money',
            key: 'pay_money',
        },
        {
            title: '销售占比',
            dataIndex: 'pay_money_percentage',
            key: 'pay_money_percentage',
            render: (text, record, index) => (
                <span>{text}%</span>
            )
        },
        {
            title: '支付买家数',
            dataIndex: 'pay_consumer_num',
            key: 'pay_consumer_num',
        },
        {
            title: '转化率',
            dataIndex: 'pay_transform_rate',
            key: 'pay_transform_rate',
            render: (text, record, index) => (
                <span>{text}%</span>
            )
        },
        {
            title: 'UV价值',
            dataIndex: 'uv_value',
            key: 'uv_value',
        },
    ];

    // 流量top10表格
    let trafficColumns = [
        {
            title: '流量来源',
            dataIndex: 'source_detail',
            key: 'source_detail',
        },
        {
            title: '访客数',
            dataIndex: 'visitor_num',
            key: 'visitor_num',
        },
        {
            title: '支付买家数',
            dataIndex: 'pay_consumer_num',
            key: 'pay_consumer_num',
        },
        {
            title: '支付金额',
            dataIndex: 'pay_money',
            key: 'pay_money',
        },
        {
            title: '转化率',
            dataIndex: 'pay_transform_rate',
            key: 'pay_transform_rate',
            render: (text, record, index) => (
                <span>{text}%</span>
            )
        },
        {
            title: 'UV价值',
            dataIndex: 'uv_value',
            key: 'uv_value',
        },
    ]
    // 流量商品
    let commodityColumns = [
        {
            title: '商品Id',
            dataIndex: 'goods_id',
            key: 'goods_id',
            width: 100,
            render: (text, record, index) => (
                <a href={`https://detail.tmall.com/item.htm?id=${text}`} target='_blank'>
                    <SaleImage shopName={props.location.pathname.split('/')[2] == 'jr' ? 'JORYA' : props.location.pathname.split('/')[2]} sku={record.sku} id={text}/>
                </a>
            )
        },
        {
            title: '访客数',
            dataIndex: 'visitor_num',
            key: 'visitor_num',
        },
        {
            title: '支付买家数',
            dataIndex: 'order_buyer',
            key: 'order_buyer',
        },
        {
            title: '支付件数',
            dataIndex: 'pay_goods_num',
            key: 'pay_goods_num',
        },
        {
            title: '支付金额',
            dataIndex: 'pay_money',
            key: 'pay_money',
        },
        {
            title: '转化率',
            dataIndex: 'pay_transform_rate',
            key: 'pay_transform_rate',
            render: (text, record, index) => (
                <span>{text}%</span>
            )
        },
        {
            title: 'UV价值',
            dataIndex: 'uv_value',
            key: 'uv_value',
        },
    ]

    // 访客数数据
    const [visitorNumDate, setVisitorNumDate] = useState([])
    // 加购人数数据
    const [addCarConsumerDate, setAddCarConsumerDate] = useState([])
    // 销售金额汇总
    const [payMoneyDate, setPayMoneyDate] = useState([])
    // 表格数据
    const [tableDate, setTableDate] = useState([])
    // top10流量数据
    const [trafficDate, setTrafficDate] = useState([])
    // top10流量商品
    const [commodityDate, setCommodityDate] = useState([])
    // 访客数折线图数据
    const [series, setSeries] = useState([])
    const [time, setTime] = useState([])
    // 访客数表格
    let visitorNumreport = {
        title: {
            text: '访客数据汇总',
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: ['淘内免费', '付费流量', '自主访问', '大促会场', '淘外媒体'],
            top: 'bottom'
        },
        series: [
            {
                name: '访客数据汇总',
                type: 'pie',
                selectedMode: 'single',
                radius: '50%',
                // label: {
                //     position: 'inner',
                //     fontSize: 14,
                // },
                data: visitorNumDate,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }

    // 加购人数汇总
    let addCarConsumerreport = {
        title: {
            text: '加购人数汇总',
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: ['淘内免费', '付费流量', '自主访问', '大促会场', '淘外媒体'],
            top: 'bottom'
        },
        series: [
            {
                name: '加购人数汇总',
                type: 'pie',
                selectedMode: 'single',
                radius: '50%',
                // label: {
                //     position: 'inner',
                //     fontSize: 14,
                // },
                data: addCarConsumerDate,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }

    // 销售金额表格
    let payMoneyreport = {
        title: {
            text: '销售金额汇总',
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: ['淘内免费', '付费流量', '自主访问', '大促会场', '淘外媒体'],
            top: 'bottom'
        },
        series: [
            {
                name: '销售金额汇总',
                type: 'pie',
                selectedMode: 'single',
                radius: '50%',
                // label: {
                //     position: 'inner',
                //     fontSize: 14,
                // },
                data: payMoneyDate,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }

    // 表格
    let visitorReport = {
        title: {
            text: `访客数`,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: {show: false, readOnly: false},
                restore: {show: false},
                saveAsImage: {show: false}
            }
        },
        legend: {
            data: ['淘内免费', '付费流量', '自主访问', '大促会场', '淘外媒体'],
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: time
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '访客数/个',
                position: 'left',
                axisLine: {
                    show: true,
                    onZero: true
                },
            }
        ],
        series: series
    }

    // 开始时间
    const [startTime, setStartTime] = useState(moment().subtract(1, "days").format('YYYY-MM-DD'))
    // 结束时间
    const [endTime, setEndTime] = useState(moment().subtract(1, "days").format('YYYY-MM-DD'))

    useEffect(() => {
        // getPieChartSummary()
        // getFlowTable()
        // getTopFlowSource()
        // getTopFlowCommodity()
        // getVisitorNumTrend()

        const getData = async () => {
            await Promise.all([getPieChartSummary(),
                getFlowTable(),
                getTopFlowSource(),
                getTopFlowCommodity(),
                getVisitorNumTrend()])
        }
        getData()
    }, [startTime, endTime])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('visitorNumreport'));
        // 绘制图表
        reportChart.setOption(visitorNumreport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [visitorNumDate])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('addCarConsumerreport'));
        // 绘制图表
        reportChart.setOption(addCarConsumerreport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [addCarConsumerDate])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('visitorReport'));
        // 绘制图表
        reportChart.setOption(visitorReport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [series])


    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('payMoneyreport'));
        // 绘制图表
        reportChart.setOption(payMoneyreport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [payMoneyDate])

    // 获取访客趋势
    const getVisitorNumTrend = () => {
        let dataProps = {}
        dataProps.shop_id = cookie.load('shopList')
        dataProps.role_id = cookie.load('role')
        dataProps.shop = props.location.pathname.split('/')[2]
        dataProps.start_time = startTime
        dataProps.end_time = endTime
        NbAxios(dataProps, "POST", servicePath.queryVisitorNumTrend).then(res => {
            if (res.success) {
                setTime(res.data['month'])
                setSeries(res.data['series'])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取表格数据
    const getFlowTable = () => {
        let dataProps = {}
        dataProps.shop_id = cookie.load('shopList')
        dataProps.role_id = cookie.load('role')
        dataProps.shop = props.location.pathname.split('/')[2]
        dataProps.start_time = startTime
        dataProps.end_time = endTime
        NbAxios(dataProps, "POST", servicePath.queryFlowTable).then(res => {
            if (res.success) {
                setTableDate(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取TOP10流量来源
    const getTopFlowSource = () => {
        let dataProps = {}
        dataProps.shop_id = cookie.load('shopList')
        dataProps.role_id = cookie.load('role')
        dataProps.shop = props.location.pathname.split('/')[2]
        dataProps.start_time = startTime
        dataProps.end_time = endTime
        NbAxios(dataProps, "POST", servicePath.queryTopFlowSource).then(res => {
            if (res.success) {
                setTrafficDate(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取TOP10流量商品
    const getTopFlowCommodity = () => {
        let dataProps = {}
        dataProps.shop_id = cookie.load('shopList')
        dataProps.role_id = cookie.load('role')
        dataProps.shop = props.location.pathname.split('/')[2]
        dataProps.start_time = startTime
        dataProps.end_time = endTime
        NbAxios(dataProps, "POST", servicePath.queryTopFlowCommodity).then(res => {
            if (res.success) {
                console.log(res.data);
                setCommodityDate(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取饼图数据
    const getPieChartSummary = () => {
        let dataProps = {}
        dataProps.shop_id = cookie.load('shopList')
        dataProps.role_id = cookie.load('role')
        dataProps.shop = props.location.pathname.split('/')[2]
        dataProps.start_time = startTime
        dataProps.end_time = endTime
        NbAxios(dataProps, "POST", servicePath.queryPieChartSummary).then(res => {
            if (res.success) {
                setVisitorNumDate(res.data['visitor_num_data']);
                setAddCarConsumerDate(res.data['add_car_consumer_num_data']);
                setPayMoneyDate(res.data['pay_money_data']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 时间修改函数
    const onTimeChange = (dates, dateStrings) => {
        if (dateStrings[0] == '') {
            message.warn('请选择正确的时间')
            return false
        } else {
            setStartTime(dateStrings[0])
            setEndTime(dateStrings[1])
        }
    }


    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: "space-around",
                backgroundColor: "#fff",
                borderBottom: "solid 2px #ccc",
                marginBottom: 10,
                position: 'fixed',
                top: 40,
                zIndex: 99,
                width:'100vw'
            }}>
                <Title
                    level={2}>{props.location.pathname.split('/')[2] == 'jr' ? 'JORYA' : props.location.pathname.split('/')[2]}流量数据汇总</Title>
                <RangePicker style={{height: "28px", marginTop: 10}}
                             locale={locale}
                             value={[moment(startTime, dateFormat), moment(endTime, dateFormat)]}
                             onChange={onTimeChange}/>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: "space-around",
                backgroundColor: "#fff",
                width: "90vw",
                marginLeft: "5vw",
                marginTop:80
            }}>
                <div id="visitorNumreport" style={{width: '100%', height: "30vh"}}></div>
                <div id="addCarConsumerreport" style={{width: '100%', height: "30vh"}}></div>
                <div id="payMoneyreport" style={{width: '100%', height: "30vh"}}></div>
            </div>
            <div style={{marginTop: 30, display:'flex', justifyContent:'flex-end', width: '95vw'}}>
                <Button type="link" onClick={()=>{
                    let dataProps = {}
                    dataProps.shopList = cookie.load('shopList')
                    dataProps.roleId = cookie.load('role')
                    dataProps.shopId = props.location.pathname.split('/')[2]
                    dataProps.start_time = startTime
                    dataProps.end_time = endTime
                    dataProps.data = tableDate
                    dataProps.table_head = columns
                    dataProps.name = "流量来源"
                    axios({
                        data: dataProps,
                        method: 'POST',
                        url: servicePath.downloadTrafficSummaryFile,
                        responseType: "blob",
                        headers: {
                            'Content-Type': 'application/json',  //如果写成contentType会报错
                            'authorization': cookie.load('token')
                        }
                    }).then(
                        res => {
                            downExcel(res)
                        }
                    ).catch(e => {
                        message.error({ content: '文件制作出错，请联系it同事！' })
                    })
                }}>下载数据</Button>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
                <Table
                    dataSource={tableDate}
                    columns={columns}
                    pagination={false}
                    style={{width: '90vw'}}
                    bordered={true}
                />
            </div>
            <div style={{
                width: '90vw',
                backgroundColor: "#fff",
                marginLeft: "5vw",
                marginTop: 20,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr'
            }}>
                <div>
                    <div style={{display:'flex', justifyContent:'space-between', width:'43vw'}}>
                        <Title level={4} style={{marginLeft: 10, marginTop: 10}}>TOP10流量来源</Title>
                        <Button type="link" onClick={()=>{
                            let dataProps = {}
                            dataProps.shopList = cookie.load('shopList')
                            dataProps.roleId = cookie.load('role')
                            dataProps.shopId = props.location.pathname.split('/')[2]
                            dataProps.start_time = startTime
                            dataProps.end_time = endTime
                            dataProps.data = trafficDate
                            dataProps.table_head = trafficColumns
                            dataProps.name = "Top10流量来源"
                            axios({
                                data: dataProps,
                                method: 'POST',
                                url: servicePath.downloadTrafficSummaryFile,
                                responseType: "blob",
                                headers: {
                                    'Content-Type': 'application/json',  //如果写成contentType会报错
                                    'authorization': cookie.load('token')
                                }
                            }).then(
                                res => {
                                    downExcel(res)
                                }
                            ).catch(e => {
                                message.error({ content: '文件制作出错，请联系it同事！' })
                            })
                        }}>下载数据</Button>
                    </div>
                    <Table
                        dataSource={trafficDate}
                        columns={trafficColumns}
                        pagination={false}
                        bordered={true}
                        style={{width: "95%"}}
                        onRow={function (record, rowIndex) {
                            return (
                                {
                                    style: {
                                        height: '100px'
                                    },
                                }
                            )
                        }}
                    />
                </div>
                <div>
                    <div style={{display:'flex', justifyContent:'space-between', width:'43vw'}}>
                        <Title level={4} style={{marginLeft: 10, marginTop: 10}}>TOP10流量商品</Title>
                        <Button type="link" onClick={()=>{
                            let dataProps = {}
                            dataProps.shopList = cookie.load('shopList')
                            dataProps.roleId = cookie.load('role')
                            dataProps.shopId = props.location.pathname.split('/')[2]
                            dataProps.start_time = startTime
                            dataProps.end_time = endTime
                            dataProps.data = commodityDate
                            dataProps.table_head = commodityColumns
                            dataProps.name = "Top10流量商品"
                            axios({
                                data: dataProps,
                                method: 'POST',
                                url: servicePath.downloadTrafficSummaryFile,
                                responseType: "blob",
                                headers: {
                                    'Content-Type': 'application/json',  //如果写成contentType会报错
                                    'authorization': cookie.load('token')
                                }
                            }).then(
                                res => {
                                    downExcel(res)
                                }
                            ).catch(e => {
                                message.error({ content: '文件制作出错，请联系it同事！' })
                            })
                        }}>下载数据</Button>
                    </div>
                    <Table
                        dataSource={commodityDate}
                        columns={commodityColumns}
                        pagination={false}
                        bordered={true}
                        style={{width: "95%"}}
                    />
                </div>
            </div>
            <div style={{display: 'flex', backgroundColor: "#fff", width: "90vw", marginLeft: "5vw", marginTop: 10}}>
                <div id="visitorReport" style={{width: '100%', height: "30vh"}}></div>
            </div>
        </div>
    )

}


export default Index