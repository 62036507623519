import React, { useState, useEffect } from 'react';
import { Spin, Typography, DatePicker, Input, Select, message, Checkbox, Button, Table, Image, Radio, Tooltip } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { PlusCircleOutlined, MinusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment'
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl';
import cookie from 'react-cookies'
import axios from 'axios';
import downExcel from '../../config/utils/downloadFile';
import style from './index.module.css';
import AcceptBuried from '../../config/utils/buried';
import { DraggableArea } from 'react-draggable-tags';
import { logRoles } from '@testing-library/react';
const { Title, Text } = Typography;
const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = 'YYYY-MM-DD';
function Index(props) {
    function getWeek(date) {
        // date = formatTimebytype(date, 'yyyy-MM-dd');//将日期转换成yyyy-mm-dd格式
        date = new Date(date);
        var date2 = new Date(date.getFullYear(), 0, 1);
        var day1 = date.getDay();
        if (day1 == 0) day1 = 7;
        var day2 = date2.getDay();
        if (day2 == 0) day2 = 7;
        let d = Math.round((date.getTime() - date2.getTime() + (day2 - day1) * (24 * 60 * 60 * 1000)) / 86400000);
        //当周数大于53则为下一年的第一周
        if ((Math.ceil(d / 7) + 1) > 53) {
            return (date.getFullYear()) + "1"
        }
        return date.getFullYear() + "," + (Math.ceil(d / 7) + 1) + "";
    }


    function itemarray(a, b) {
        var arry = [];
        for (var d = a; d <= b; d++) {
            arry.push(d);
        }
        return arry;
    }

    // 获取年/周数据
    function getAllWeekList(startWeekDate, endWeekDate) {
        if (startWeekDate != '' || endWeekDate != '') {
            let startYear = startWeekDate.split('-')[0]
            let endYear = endWeekDate.split('-')[0]
            // 不跨年
            if (parseInt(startYear) == parseInt(endYear)) {
                let startWeek = getWeek(startWeekDate)
                let endWeek = getWeek(endWeekDate)
                let startWeekNum = startWeek.split(',')[1]
                let endWeekNum = endWeek.split(',')[1]
                let weekArray = itemarray(parseInt(startWeekNum), parseInt(endWeekNum))
                let weekList = []
                weekArray.map(item => {
                    weekList.push(startYear + '-' + item)
                })
                return weekList
            } else {
                let yearInterval = endYear - startYear - 1
                // 差一年的情况
                if (yearInterval == 0) {
                    // 小的年份的最后一周
                    let startEndWeek = getWeek(String(startYear) + '-12-31').split(',')[1]
                    let startWeek = getWeek(startWeekDate).split(',')[1]
                    let startWeekArray = itemarray(parseInt(startWeek), parseInt(startEndWeek))
                    let weekList = []
                    startWeekArray.map(item => {
                        weekList.push(startYear + '-' + item)
                    })
                    let endWeek = getWeek(endWeekDate).split(',')[1]
                    let endWeekArray = itemarray(1, parseInt(endWeek))
                    endWeekArray.map(item => {
                        weekList.push(endYear + '-' + item)
                    })
                    return weekList
                } else {
                    // 差多年的情况
                    // 最小的年
                    // 小的年份的最后一周
                    let startEndWeek = getWeek(String(startYear) + '-12-31').split(',')[1]
                    let startWeek = getWeek(startWeekDate).split(',')[1]
                    let startWeekArray = itemarray(parseInt(startWeek), parseInt(startEndWeek))
                    let weekList = []
                    startWeekArray.map(item => {
                        weekList.push(startYear + '-' + item)
                    })
                    // [1,2]
                    // 2019 + 1 = 2020  2019 + 2= 2021
                    // yearInterval 2 
                    // 2022-2019-1 = 2
                    let yearIntervalList = itemarray(1, parseInt(yearInterval))
                    yearIntervalList.map(item => {
                        let intervalYear = parseInt(startYear) + item
                        let intervalWeekList = itemarray(1, parseInt(getWeek(String(intervalYear) + '-12-31').split(',')[1]))
                        intervalWeekList.map(item => {
                            weekList.push(intervalYear + '-' + item)
                        })
                    })
                    let endWeek = getWeek(endWeekDate).split(',')[1]
                    let endWeekArray = itemarray(1, parseInt(endWeek))
                    endWeekArray.map(item => {
                        weekList.push(endYear + '-' + item)
                    })
                    return weekList
                }
            }
        }
    }
    //数组中删除指定一项数据
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    function disabledDate(current) {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }
    // 是否加载
    const [loading, setLoading] = useState(false)
    // 是否打开
    const [isOpen, setIsOpen] = useState(true)
    // 统计时间
    const [statisticsTime, setStatisticsTime] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD'))
    // TOP款前
    const [top, setTop] = useState('')
    // 年份季节
    const [yearSeasonList, setYearSeasonList] = useState([])
    // 品牌集合
    const [brandList, setBrandList] = useState([])
    const [ xinBrandList] = useState(['COCOON/可可尼','MO＆CO．/摩安珂','DAZZLE/地素','D’ZZIT','MISS SIXTY','LANCY FROM 25/朗姿','音儿','PSALTER/诗篇','INSUN/恩裳','LIMEFLARE/莱茵福莱尔','雅莹 ','SANDRO','MAXRIENY'])
    const [ einBrandList] = useState(['MO＆CO．/摩安珂','JNBY/江南布衣','EDITION','ICICLE/之禾','麦檬','致知','致知（服饰）','SANDRO','THEORY','独束','INSIS FEMME','CHICJOC','IMMI（服饰）','IMMI','麻霖','可然出品','FUSSED URBAN COLLECTION'])
    // 品类集合
    const [categoryList, setCategoryList] = useState([])
    // 维度筛选
    const [newTime, setNewTime] = useState([])
    const [newStartTime, setNewStartTime] = useState('')
    const [newEndTime, setNewEndTime] = useState('')
    const [yearSeason, setYearSeason] = useState([])
    const [yearSeasonCheck, setYearSeasonCheck] = useState(false)
    const [brand, setBrand] = useState([])
    const [brandCheck, setBrandCheck] = useState(false)
    const [xinBrandCheck, setXinBrandCheck] = useState(false)
    const [einBrandCheck, setEinBrandCheck] = useState(false)
    const [category, setCategory] = useState([])
    const [categoryCheck, setCategoryCheck] = useState(false)
    // 本期周期时间
    const [periodStartTime, setPeriodStartTime] = useState('')
    const [periodEndTime, setPeriodEndTime] = useState('')
    // 基础可选字段
    const [baseOptionalFields, setBaseOptionalFields] = useState(['商品标题', '商品类目', '上架时间', '所属行业', '所属店铺', '品牌', '颜色', '货号/款号', '年份季节', '材质成分', '销售渠道类型', '适用年龄'])
    const [choosedBaseOptionalFields, setChoosedBaseOptionalFields] = useState([])
    const [baseOptionalFieldsCheck, setBaseOptionalFieldsCheck] = useState(false)
    // 销售可选字段
    const [saleOptionalFields, setSaleOptionalFields] = useState(['商品划线价', '历史最高价', '历史最低价', '首日销量', '首日收藏', '上新30天销量', '日销量', '日销售额', '日收藏量', '月销量', '月销售额', '周销量', '周销售额', '总销量', '总销售额',])
    const [choosedSaleOptionalFields, setChoosedSaleOptionalFields] = useState([])
    const [saleOptionalFieldsCheck, setSaleOptionalFieldsCheck] = useState(false)
    // 统计可选字段
    const [statisticsOptionalFields, setStatisticsOptionalFields] = useState(['总收藏', '月收藏', '库存', '总评价', '商品状态', '开卖时间', '开卖首日销量', '上架次数', '统计时间', '统计时间最后价格', '统计时间最高价', '统计时间最低价', '统计时间最后库存',])
    const [choosedStatisticsOptionalFields, setChoosedStatisticsOptionalFields] = useState([])
    const [sstatisticsOptionalFieldsCheck, setStatisticsOptionalFieldsCheck] = useState(false)
    // 已选字段
    const [choosedFields, setChoosedFields] = useState([])
    // 开始周数
    const [startWeekDate, setStartWeekDate] = useState('')
    const [endWeekDate, setEndWeekDate] = useState('')
    // 显示内容
    const [displayContent, setDisplayContent] = useState('')
    // 数据
    const [data, setData] = useState([])
    const [allAata, setAllData] = useState([])
    // 标题
    const [columns, setColumns] = useState([])
    // 表头
    const [ tableHeadList , setTableHeadList ] = useState([])
    // 移动区域是否显示删除
    const [ draggableFlag , setDraggableFlag] = useState('')
    // useEffect(() => {
    //     let baseOptionalObj = []
    //     baseOptionalFields.map(item => {
    //         let dict = {
    //             id: item,
    //             name: item,
    //         }
    //         baseOptionalObj.push(dict)
    //     })
    //     setBaseOptionalFields(baseOptionalObj)
    //     let saleOptionalObj = []
    //     saleOptionalFields.map(item => {
    //         let dict = {
    //             id: item,
    //             name: item,
    //         }
    //         saleOptionalObj.push(dict)
    //     })
    //     setSaleOptionalFields(saleOptionalObj)
    //     let statisticsOptionalObj = []
    //     statisticsOptionalFields.map(item => {
    //         let dict = {
    //             id: item,
    //             name: item,
    //         }
    //         statisticsOptionalObj.push(dict)
    //     })
    //     setStatisticsOptionalFields(statisticsOptionalObj)
    // }, [])

    useEffect(()=>{
        // 欣贺品牌是否全部在里面
        let xinFlag = true
        xinBrandList.map(item=>{
            if(brand.indexOf(item) == -1){
                xinFlag = false
            }
        })
        if(xinFlag){
            setXinBrandCheck(true)
        }else{
            setXinBrandCheck(false)
        }
        let einFlag = true
        einBrandList.map(item=>{
            if(brand.indexOf(item) == -1){
                einFlag = false
            }
        })
        if(einFlag){
            setEinBrandCheck(true)
        }else{
            setEinBrandCheck(false)
        }
    },[brand])

    useEffect(() => {
        if (choosedBaseOptionalFields.length == baseOptionalFields.length) {
            setBaseOptionalFieldsCheck(true)
        } else {
            setBaseOptionalFieldsCheck(false)
        }
    }, [choosedBaseOptionalFields])

    useEffect(() => {
        if (choosedSaleOptionalFields.length == saleOptionalFields.length) {
            setSaleOptionalFieldsCheck(true)
        } else {
            setSaleOptionalFieldsCheck(false)
        }
    }, [choosedSaleOptionalFields])

    useEffect(() => {
        if (choosedStatisticsOptionalFields.length == statisticsOptionalFields.length) {
            setStatisticsOptionalFieldsCheck(true)
        } else {
            setStatisticsOptionalFieldsCheck(false)
        }
    }, [choosedStatisticsOptionalFields])

    useEffect(() => {
        let periodObj = [{
            id: "本期销量",
            name: "本期销量",
        }, {
            id: "本期销售额",
            name: "本期销售额",
        }, {
            id: "本期收藏量",
            name: "本期收藏量",
        }]
        if (periodStartTime != '') {
            let choosedFieldsObj = [...choosedFields]
            periodObj.map(item => {
                if (choosedFieldsObj.indexOf(item) == -1) {
                    choosedFieldsObj.push(item)
                }
            })
            setChoosedFields(choosedFieldsObj)
        } else {
            let choosedFieldsObj = [...choosedFields]
            let obj = []
            choosedFieldsObj.map(item => {
                if (item.name != '本期销量' && item.name != '本期销售额' && item.name != '本期收藏量') {
                    obj.push(item)
                }

            })
            setChoosedFields(obj);
        }
    }, [periodStartTime])

    // 获取基础信息
    useEffect(() => {
        AcceptBuried('page_views',"view",{"page_type":"初始化页面"},props.location.pathname,"竞品新品追踪板块")
        const getData = async () => {
            await Promise.all([
                getYearSeasonList(),
                // getBrandList(),
                getCategoryList(),
                readCompetitorTrackingBasic()
            ])
        }
        getData()
    }, [])

    // 获取字段模版
    const readCompetitorTrackingBasic = () => {
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "_id": cookie.load('id')
        }
        NbAxios(values, "POST", servicePath.readCompetitorTrackingBasic).then(res => {
            if (res.success) {
                if (res.data != null) {
                    setChoosedBaseOptionalFields(res.data['womanCompetitiveTracking']['choosedBaseOptionalFields']);
                    setChoosedStatisticsOptionalFields(res.data['womanCompetitiveTracking']['choosedStatisticsOptionalFields']);
                    setChoosedSaleOptionalFields(res.data['womanCompetitiveTracking']['choosedSaleOptionalFields']);
                    setChoosedFields(res.data['womanCompetitiveTracking']['choosedFields']);
                }
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获取字段模版错误，请联系IT帮忙解决')
        })
    }

    // 获取品类
    const getCategoryList = () => {
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "basic": "category_name"
        }
        NbAxios(values, "POST", servicePath.searchCompetitorTrackingBasic).then(res => {
            if (res.success) {
                setCategoryList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获取品类错误，请联系IT帮忙解决')
        })
    }

    // 获取年份季节
    const getYearSeasonList = () => {
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "basic": "year_season"
        }
        NbAxios(values, "POST", servicePath.searchCompetitorTrackingBasic).then(res => {
            if (res.success) {
                setYearSeasonList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获取年份季节错误，请联系IT帮忙解决')
        })
    }

    // 获取竞品品牌
    const getBrandList = () => {
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "basic": "brand"
        }
        NbAxios(values, "POST", servicePath.searchCompetitorTrackingBasic).then(res => {
            if (res.success) {
                setBrandList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            message.warn('获竞品品牌错误，请联系IT帮忙解决')
        })
    }


    // useEffect(() => {
    //     let obj = []
    //     optionalFields.map(item => {
    // let dict = {
    //     'id': item,
    //     'name': item,
    // }
    // obj.push(dict)
    //     })
    //     setOptionalFields(obj);
    //     let weekNum = 52
    //     let nowTime = getWeek(moment().format('YYYY-MM-DD'))
    //     let nowYear = nowTime.split(',')[0]
    //     let nowWeek = nowTime.split(',')[1]
    //     setNowYear(parseInt(nowYear))
    //     setNowWeek(parseInt(nowWeek))
    //     let remainingWeek = weekNum - nowWeek
    //     let weekYearObj = []
    //     let nowWeekList = itemarray(1, parseInt(nowWeek))
    //     nowWeekList.map(item => {
    //         weekYearObj.push(nowYear + ',' + item)
    //     })
    //     let remainingWeekList = itemarray(52 - remainingWeek, 52)
    //     remainingWeekList.map(item => {
    //         weekYearObj.push((nowYear - 1) + ',' + item)
    //     })
    //     let endClick = (nowYear - 1) + ',' + (52 - remainingWeek)
    //     setEndClick(endClick)
    //     setStartClick(nowTime)
    //     setChooseWeek(weekYearObj);
    // }, [])

    return (
        <div>
            <Spin spinning={loading}>
                <div style={{
                    display: 'flex',
                    justifyContent: "space-around",
                    backgroundColor: "#fff",
                    borderBottom: "solid 2px #ccc",
                    marginBottom: 10,
                    position: 'fixed',
                    top: 40,
                    zIndex: 99,
                    width: '100vw'
                }}>
                    <Title level={3}>竞品新品追踪</Title>
                    <div></div>
                </div>
                <div style={{ marginLeft: 10, marginRight: 10, marginTop: 50, border: "1px solid #ccc" }}>
                    <div style={{ borderBottom: "1px solid #ccc", paddingBottom: 5 }} >
                        <div style={{ marginLeft: 100 }} onClick={() => {
                            if (isOpen) {
                                setIsOpen(false)
                            } else {
                                setIsOpen(true)
                            }
                        }}>
                            <Text style={{ fontWeight: "bolder", fontSize: 18 }}>设置条件</Text>
                        </div>
                    </div>
                    <div style={{ display: isOpen ? '' : 'none', }}>
                        <div className={style.setConditions} >
                            <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', margin: 10, width: "100vw" }}>
                                <div style={{ marginLeft: 20, marginTop: 10 }}>
                                    <Text style={{ fontWeight: "bolder", fontSize: 16 }}>筛选维度:</Text>
                                </div>
                                <div style={{ display: 'flex', marginTop: 5, paddingBottom: 10 }}>
                                    <Text type="secondary" style={{ marginLeft: 20, marginTop: 5 }}>统计日期:</Text>
                                    <DatePicker value={statisticsTime == '' ? '' : moment(statisticsTime, dateFormat)}
                                        disabledDate={disabledDate} locale={locale} style={{ marginLeft: 20, height: 30, marginTop: 5 }}
                                        onChange={(date, dateString) => {
                                            if (dateString == '') {
                                                message.warn('请选择合理的时间')
                                                return false
                                            } else {
                                                setStatisticsTime(dateString)
                                            }
                                        }}
                                    />

                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr" }}>
                                    <Text type="secondary" style={{ marginLeft: 20, marginTop: 10 }}>上新时间:</Text>
                                    <div style={{ display: 'flex' }}>
                                        <RangePicker locale={locale} disabledDate={disabledDate} 
                                        value={newStartTime == '' ? ['', ''] : [moment(newStartTime, dateFormat),moment(newEndTime, dateFormat)]} picker="month"
                                        onChange={(date, dateString) => {
                                            setNewStartTime(dateString[0])
                                            setNewEndTime(dateString[1])
                                        }} />
                                    </div>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: 10, width: "100%" }}>
                                    <Text type="secondary" style={{ marginLeft: 20 }}>TOP款前:</Text>
                                    <div style={{display:'flex'}}>
                                        <Input style={{ height: 30, width: 100 }} value={top} onChange={(e) => {
                                            setTop(e.target.value.replace(/[^\d^]+/g, ''))
                                        }} />
                                        <Text type="danger" style={{marginLeft:10, marginTop:5}}>(填写数字时可以选择多个竞品品牌，不填的时候只能选择一个竞品品牌)</Text>
                                    </div>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: 10 }}>
                                    <Text type="secondary" style={{ marginLeft: 20 }}>年份季节:</Text>
                                    <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                        <Checkbox style={{ marginLeft: 8 }} checked={yearSeasonCheck} onChange={(e) => {
                                            if (e.target.checked) {
                                                setYearSeasonCheck(true)
                                                setYearSeason(yearSeasonList)
                                            } else {
                                                setYearSeasonCheck(false)
                                                setYearSeason([])
                                            }
                                        }}>全部</Checkbox>
                                        {
                                            yearSeasonList.map(item => {
                                                return <Checkbox checked={yearSeason.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                                    if (e.target.checked) {
                                                        let obj = [...yearSeason]
                                                        obj.push(item)
                                                        setYearSeason(obj)
                                                    } else {
                                                        let obj = [...yearSeason]
                                                        obj.remove(item)
                                                        setYearSeason(obj)
                                                    }
                                                }}>{item}</Checkbox>
                                            })
                                        }
                                    </div>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: 10 }}>
                                    <Text type="secondary" style={{ marginLeft: 20 }}>竞品品牌:</Text>
                                    {/* <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                        <Checkbox style={{ marginLeft: 8 }} checked={brandCheck} onChange={(e) => {
                                            if (e.target.checked) {
                                                setBrandCheck(true)
                                                setBrand(brandList)
                                            } else {
                                                setBrandCheck(false)
                                                setBrand([])
                                            }
                                        }}>全部</Checkbox>
                                        {
                                            brandList.map(item => {
                                                return <Checkbox checked={brand.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                                    if (e.target.checked) {
                                                        let obj = [...brand]
                                                        obj.push(item)
                                                        setBrand(obj)
                                                    } else {
                                                        let obj = [...brand]
                                                        obj.remove(item)
                                                        setBrand(obj)
                                                    }
                                                }}>{item}</Checkbox>
                                            })
                                        }
                                    </div> */}
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: 5}}>
                                    <Text type="secondary" style={{ marginLeft: 47 }}>欣贺:</Text>
                                    <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                        <Checkbox style={{ marginLeft:8, width:80 }} checked={xinBrandCheck} onChange={(e) => {
                                            if (e.target.checked) {
                                                setXinBrandCheck(true)
                                                let obj = [...brand]
                                                xinBrandList.map(item=>{
                                                    obj.push(item)
                                                })
                                                setBrand(obj)
                                            } else {
                                                setXinBrandCheck(false)
                                                let obj = [...brand]
                                                xinBrandList.map(item=>{
                                                    obj.remove(item)
                                                })
                                                setBrand(obj)
                                            }
                                        }}>全部</Checkbox>
                                        {
                                            xinBrandList.map(item => {
                                                return <Tooltip title={item}>
                                                    <Checkbox style={{width:80 , overflow:"hidden", whiteSpace:'nowrap', textOverflow:"ellipsis"}} checked={brand.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            let obj = [...brand]
                                                            obj.push(item)
                                                            setBrand(obj)
                                                        } else {
                                                            let obj = [...brand]
                                                            obj.remove(item)
                                                            setBrand(obj)
                                                        }
                                                    }}>{item}</Checkbox>
                                                </Tooltip>
                                            })
                                        }
                                    </div>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: 5, borderBottom:"1px dashed #ccc", paddingBottom:5}}>
                                    <Text type="secondary" style={{ marginLeft: 47 }}>EIN:</Text>
                                    <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                        <Checkbox style={{ marginLeft:8,  width:80 }} checked={einBrandCheck} onChange={(e) => {
                                            if (e.target.checked) {
                                                setEinBrandCheck(true)
                                                let obj = [...brand]
                                                einBrandList.map(item=>{
                                                    obj.push(item)
                                                })
                                                setBrand(obj)
                                            } else {
                                                setEinBrandCheck(false)
                                                let obj = [...brand]
                                                einBrandList.map(item=>{
                                                    obj.remove(item)
                                                })
                                                setBrand(obj)
                                            }
                                        }}>全部</Checkbox>
                                        {
                                            einBrandList.map(item => {
                                                return <Tooltip title={item}>
                                                    <Checkbox style={{width:80 , overflow:"hidden", whiteSpace:'nowrap', textOverflow:"ellipsis"}} checked={brand.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            let obj = [...brand]
                                                            obj.push(item)
                                                            setBrand(obj)
                                                        } else {
                                                            let obj = [...brand]
                                                            obj.remove(item)
                                                            setBrand(obj)
                                                        }
                                                    }}>{item}</Checkbox>
                                                </Tooltip>
                                            })
                                        }
                                    </div>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: 10 }}>
                                    <Text type="secondary" style={{ marginLeft: 20 }}>品类:</Text>
                                    <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                        <Checkbox style={{ marginLeft:8, width:80 }} checked={categoryCheck} onChange={(e) => {
                                            if (e.target.checked) {
                                                setCategoryCheck(true)
                                                setCategory(categoryList)
                                            } else {
                                                setCategoryCheck(false)
                                                setCategory([])
                                            }
                                        }}>全部</Checkbox>
                                        {
                                            categoryList.map(item => {
                                                return <Tooltip title={item}>
                                                    <Checkbox style={{width:80 , overflow:"hidden", whiteSpace:'nowrap', textOverflow:"ellipsis"}} checked={category.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            let obj = [...category]
                                                            obj.push(item)
                                                            setCategory(obj)
                                                        } else {
                                                            let obj = [...category]
                                                            obj.remove(item)
                                                            setCategory(obj)
                                                        }
                                                    }}>{item}</Checkbox>
                                                </Tooltip>
                                            })
                                        }
                                    </div>
                                </div>
                               
                                <div style={{ marginLeft: 20, marginTop: 20 }}>
                                    <Text style={{ fontWeight: "bolder", fontSize: 16 }}>字段选择:</Text>
                                </div>

                                <div style={{ marginLeft: 12, marginTop: 5, display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: 10, width: '100%' }}>
                                        <Text type="secondary" style={{ marginLeft: 20 }}>本期时间:</Text>
                                        <div style={{ display: 'flex' }}>
                                            <RangePicker disabledDate={disabledDate} locale={locale}
                                                value={periodStartTime == '' ? ['', ''] : [moment(periodStartTime, dateFormat), moment(periodEndTime, dateFormat)]}
                                                style={{ height: 30 }}
                                                format="YYYY-MM-DD"
                                                onChange={(value, dateString) => {
                                                    // if (dateString[0] == '') {
                                                    //     message.warn('请选择正确的时间')
                                                    // } else {
                                                    //     setStartTime(dateString[0])
                                                    //     setEndTime(dateString[1])
                                                    // }
                                                    setPeriodStartTime(dateString[0])
                                                    setPeriodEndTime(dateString[1])

                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: 10 }}>
                                        <Text type="secondary" style={{ marginLeft: 20 }}>基础信息:</Text>
                                        <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                            <Checkbox style={{ marginLeft:8, width:100 }} checked={baseOptionalFieldsCheck} onChange={(e) => {
                                                if (e.target.checked) {
                                                    setBaseOptionalFieldsCheck(true)
                                                    setChoosedBaseOptionalFields(baseOptionalFields)
                                                    let choosedFieldsObj = [...choosedFields]
                                                    let choosedFieldsName = []
                                                    choosedFieldsObj.map(item => {
                                                        choosedFieldsName.push(item.name)
                                                    })
                                                    baseOptionalFields.map(item => {
                                                        if (choosedFieldsName.indexOf(item) == -1) {
                                                            choosedFieldsObj.push({
                                                                id: item,
                                                                name: item
                                                            })
                                                        }
                                                    })
                                                    setChoosedFields(choosedFieldsObj)
                                                } else {
                                                    setBaseOptionalFieldsCheck(false)
                                                    setChoosedBaseOptionalFields([])
                                                    let choosedFieldsObj = [...choosedFields]
                                                    let newChoosedFieldsObj = []
                                                    choosedFieldsObj.map(choosedFieldsObjItem => {
                                                        if (baseOptionalFields.indexOf(choosedFieldsObjItem.name) == -1) {
                                                            newChoosedFieldsObj.push(choosedFieldsObjItem)
                                                        }
                                                    })
                                                    setChoosedFields(newChoosedFieldsObj)
                                                }
                                            }}>全部</Checkbox>
                                            {
                                                baseOptionalFields.map(item => {
                                                    return <Tooltip title={item}>
                                                        <Checkbox style={{width:100 , overflow:"hidden", whiteSpace:'nowrap', textOverflow:"ellipsis"}} checked={choosedBaseOptionalFields.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                                            if (e.target.checked) {
                                                                let choosedBaseOptionalFieldsObj = [...choosedBaseOptionalFields]
                                                                choosedBaseOptionalFieldsObj.push(item)
                                                                setChoosedBaseOptionalFields(choosedBaseOptionalFieldsObj)
                                                                let choosedFieldsObj = [...choosedFields]
                                                                choosedFieldsObj.push({
                                                                    id: item,
                                                                    name: item
                                                                })
                                                                setChoosedFields(choosedFieldsObj)
                                                            } else {
                                                                let obj = [...choosedBaseOptionalFields]
                                                                obj.remove(item)
                                                                setChoosedBaseOptionalFields(obj)
                                                                let choosedFieldsObj = [...choosedFields]
                                                                let newChoosedFieldsObj = []
                                                                choosedFieldsObj.map(choosedFieldsObjItem => {
                                                                    if (choosedFieldsObjItem.name != item) {
                                                                        newChoosedFieldsObj.push(choosedFieldsObjItem)
                                                                    }
                                                                })
                                                                setChoosedFields(newChoosedFieldsObj)
                                                            }
                                                        }}>{item}</Checkbox>
                                                    </Tooltip>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: 10 }}>
                                        <Text type="secondary" style={{ marginLeft: 20 }}>销售数据:</Text>
                                        <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                            <Checkbox style={{ marginLeft:8, width:100 }}  checked={saleOptionalFieldsCheck} onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSaleOptionalFieldsCheck(true)
                                                    setChoosedSaleOptionalFields(saleOptionalFields)
                                                    let choosedFieldsObj = [...choosedFields]
                                                    let choosedFieldsName = []
                                                    choosedFieldsObj.map(item => {
                                                        choosedFieldsName.push(item.name)
                                                    })
                                                    saleOptionalFields.map(item => {
                                                        if (choosedFieldsName.indexOf(item) == -1) {
                                                            choosedFieldsObj.push({
                                                                id: item,
                                                                name: item
                                                            })
                                                        }
                                                    })
                                                    setChoosedFields(choosedFieldsObj)
                                                } else {
                                                    setSaleOptionalFieldsCheck(false)
                                                    setChoosedSaleOptionalFields([])
                                                    let choosedFieldsObj = [...choosedFields]
                                                    let newChoosedFieldsObj = []
                                                    choosedFieldsObj.map(choosedFieldsObjItem => {
                                                        if (saleOptionalFields.indexOf(choosedFieldsObjItem.name) == -1) {
                                                            newChoosedFieldsObj.push(choosedFieldsObjItem)
                                                        }
                                                    })
                                                    setChoosedFields(newChoosedFieldsObj)
                                                }
                                            }}>全部</Checkbox>
                                            {
                                                saleOptionalFields.map(item => {
                                                    return <Tooltip title={item}>
                                                        <Checkbox style={{width:100 , overflow:"hidden", whiteSpace:'nowrap', textOverflow:"ellipsis"}} checked={choosedSaleOptionalFields.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                                            if (e.target.checked) {
                                                                let choosedSaleOptionalFieldsObj = [...choosedSaleOptionalFields]
                                                                choosedSaleOptionalFieldsObj.push(item)
                                                                setChoosedSaleOptionalFields(choosedSaleOptionalFieldsObj)
                                                                let choosedFieldsObj = [...choosedFields]
                                                                choosedFieldsObj.push({
                                                                    id: item,
                                                                    name: item
                                                                })
                                                                setChoosedFields(choosedFieldsObj)
                                                            } else {
                                                                let obj = [...choosedSaleOptionalFields]
                                                                obj.remove(item)
                                                                setChoosedSaleOptionalFields(obj)
                                                                let choosedFieldsObj = [...choosedFields]
                                                                let newChoosedFieldsObj = []
                                                                choosedFieldsObj.map(choosedFieldsObjItem => {
                                                                    if (choosedFieldsObjItem.name != item) {
                                                                        newChoosedFieldsObj.push(choosedFieldsObjItem)
                                                                    }
                                                                })
                                                                setChoosedFields(newChoosedFieldsObj)
                                                            }
                                                        }}>{item}</Checkbox>
                                                    </Tooltip>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr", marginTop: 10 }}>
                                        <Text type="secondary" style={{ marginLeft: 20 }}>统计数据:</Text>
                                        <div style={{ display: 'flex', flexWrap: "wrap" }}>
                                            <Checkbox style={{ marginLeft:8, width:100 }} checked={sstatisticsOptionalFieldsCheck} onChange={(e) => {
                                                if (e.target.checked) {
                                                    setStatisticsOptionalFieldsCheck(true)
                                                    setChoosedStatisticsOptionalFields(statisticsOptionalFields)
                                                    let choosedFieldsObj = [...choosedFields]
                                                    let choosedFieldsName = []
                                                    choosedFieldsObj.map(item => {
                                                        choosedFieldsName.push(item.name)
                                                    })
                                                    statisticsOptionalFields.map(item => {
                                                        if (choosedFieldsName.indexOf(item) == -1) {
                                                            choosedFieldsObj.push({
                                                                id: item,
                                                                name: item
                                                            })
                                                        }
                                                    })
                                                    setChoosedFields(choosedFieldsObj)
                                                } else {
                                                    setStatisticsOptionalFieldsCheck(false)
                                                    setChoosedStatisticsOptionalFields([])
                                                    let choosedFieldsObj = [...choosedFields]
                                                    let newChoosedFieldsObj = []
                                                    choosedFieldsObj.map(choosedFieldsObjItem => {
                                                        if (statisticsOptionalFields.indexOf(choosedFieldsObjItem.name) == -1) {
                                                            newChoosedFieldsObj.push(choosedFieldsObjItem)
                                                        }
                                                    })
                                                    setChoosedFields(newChoosedFieldsObj)
                                                }
                                            }}>全部</Checkbox>
                                            {
                                                statisticsOptionalFields.map(item => {
                                                    return <Tooltip title={item}>
                                                        <Checkbox style={{width:100 , overflow:"hidden", whiteSpace:'nowrap', textOverflow:"ellipsis"}} checked={choosedStatisticsOptionalFields.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                                            if (e.target.checked) {
                                                                let choosedStatisticsOptionalFieldsObj = [...choosedStatisticsOptionalFields]
                                                                choosedStatisticsOptionalFieldsObj.push(item)
                                                                setChoosedStatisticsOptionalFields(choosedStatisticsOptionalFieldsObj)
                                                                let choosedFieldsObj = [...choosedFields]
                                                                choosedFieldsObj.push({
                                                                    id: item,
                                                                    name: item
                                                                })
                                                                setChoosedFields(choosedFieldsObj)
                                                            } else {
                                                                let obj = [...choosedStatisticsOptionalFields]
                                                                obj.remove(item)
                                                                setChoosedStatisticsOptionalFields(obj)
                                                                let choosedFieldsObj = [...choosedFields]
                                                                let newChoosedFieldsObj = []
                                                                choosedFieldsObj.map(choosedFieldsObjItem => {
                                                                    if (choosedFieldsObjItem.name != item) {
                                                                        newChoosedFieldsObj.push(choosedFieldsObjItem)
                                                                    }
                                                                })
                                                                setChoosedFields(newChoosedFieldsObj)
                                                            }
                                                        }}>{item}</Checkbox>
                                                    </Tooltip>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '98%', border: "1px dashed #cccccc", height: 40, marginTop: 5, display: 'flex', flexWrap: 'nowrap', overflow: "auto", marginLeft: 4 }}>
                                    <DraggableArea tags={choosedFields} render={({ tag, index }) => (
                                        <div style={{
                                            margin: "3px",
                                            fontSize: '13px',
                                            border: " 1px dashed #cccccc",
                                            borderRadius: '4px',
                                            padding: "0 8px",
                                            lineHeight: "30px",
                                            color: "#666666",
                                            backgroundColor: "rgba(255, 255, 255, 0.7);",
                                            whiteSpace: 'nowrap',
                                            display:'flex'
                                        }} onMouseEnter={()=>{setDraggableFlag(tag.name)}} onMouseLeave={()=>{setDraggableFlag('')}} >
                                            <div> {tag.name} </div>
                                            <CloseOutlined style={{marginTop:9, marginLeft:5, display:draggableFlag == tag.name ? '' : 'none'}} onClick={()=>{
                                                if(baseOptionalFields.indexOf(tag.name) != -1){
                                                    let obj = [...choosedBaseOptionalFields]
                                                    obj.remove(tag.name)
                                                    setChoosedBaseOptionalFields(obj)
                                                }else if(saleOptionalFields.indexOf(tag.name) != -1){
                                                    let obj = [...choosedSaleOptionalFields]
                                                    obj.remove(tag.name)
                                                    setChoosedSaleOptionalFields(obj)
                                                }else if(statisticsOptionalFields.indexOf(tag.name) != -1){
                                                    let obj = [...choosedStatisticsOptionalFields]
                                                    obj.remove(tag.name)
                                                    setChoosedStatisticsOptionalFields(obj)
                                                }
                                                let choosedFieldsItem = [...choosedFields]
                                                choosedFieldsItem.remove(tag)
                                                setChoosedFields(choosedFieldsItem)
                                            }} />
                                        </div>
                                    )} onChange={tags => setChoosedFields(tags)} />
                                </div>
                                <div style={{ marginLeft: 20, marginTop: 20 }}>
                                    <Text style={{ fontWeight: "bolder", fontSize: 16 }}>周期选择:</Text>
                                </div>
                                <div style={{ display: 'flex', marginTop: 5, paddingBottom: 10 }}>
                                    <Text type="secondary" style={{ marginLeft: 20, marginTop: 5 }}>统计周期:</Text>
                                    <RangePicker disabledDate={disabledDate} locale={locale}
                                        value={startWeekDate == '' ? ['', ''] : [moment(startWeekDate, dateFormat), moment(endWeekDate, dateFormat)]}
                                        style={{ marginLeft: 20, height: 30, marginTop: 5 }}
                                        onChange={(value, dateString) => {
                                            if (value == null) {
                                                setStartWeekDate('')
                                                setEndWeekDate('')
                                                setDisplayContent('')
                                            } else {
                                                setStartWeekDate(moment(value[0]).format('YYYY-MM-DD'))
                                                setEndWeekDate(moment(value[1]).format('YYYY-MM-DD'))
                                            }

                                        }} />

                                    <Text type="secondary" className={style.interval}>显示内容:</Text>
                                    <div style={{ display: 'flex', marginLeft: 20, marginTop: 5 }}>
                                        <Radio.Group value={displayContent} onChange={(e) => {
                                            setDisplayContent(e.target.value)
                                        }}>
                                            <Radio value={"TOP"}>TOP</Radio>
                                            <Radio value={"金额"}>金额</Radio>
                                            <Radio value={"件数"}>件数</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', paddingBottom: 5 }}>
                            <Button style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)", marginRight: 3 }} type="primary" onClick={() => {
                                if (statisticsTime != '') {
                                    if((top == '' && brand.length == 1) || (top != '')){
                                        if ((startWeekDate != '' && displayContent != '') || (startWeekDate == '' && displayContent == '')) {
                                            let obj = []
                                            choosedFields.map(item => {
                                                if (item.name != '本期销量' && item.name != '本期销售额' && item.name != '本期收藏量') {
                                                    obj.push(item)
                                                }
    
                                            })
                                            let keepBaseObj = {
                                                '_id': cookie.load('id'),
                                                'womanCompetitiveTracking': {
                                                    'choosedBaseOptionalFields': choosedBaseOptionalFields,
                                                    'choosedSaleOptionalFields': choosedSaleOptionalFields,
                                                    'choosedStatisticsOptionalFields': choosedStatisticsOptionalFields,
                                                    'choosedFields': obj,
                                                },
                                                "roleId": cookie.load('role'),
                                                "shopList": cookie.load('shopList'),
                                            }
                                            NbAxios(keepBaseObj, "POST", servicePath.keepCompetitorTrackingBasic).then(res => {
                                            }).catch((err) => {
                                                message.warn('保存字段模版错误，请联系IT帮忙解决')
                                            })
                                            let allWeekList = []
                                            if (startWeekDate != '') {
                                                allWeekList = getAllWeekList(startWeekDate, endWeekDate)
                                            }
                                            let choosedFieldsObj = []
                                            let tableFields = []
                                            choosedFields.map(item => {
                                                choosedFieldsObj.push(item.id)
                                                tableFields.push(item.id)
                                            })
                                            choosedFieldsObj.push('商品id', '商品主图链接', '商品链接',)
                                            tableFields.push('商品id', '商品主图链接', '商品链接',)
                                            allWeekList.map(item=>{
                                                tableFields.push(item)
                                            })
                                            let dataProps = {
                                                "roleId": cookie.load('role'),
                                                "shopList": cookie.load('shopList'),
                                                // 统计时间 必填
                                                'statisticsTime': statisticsTime,
                                                // 上线时间 list 可以为空
                                                'newStartTime': newStartTime,
                                                'newEndTime': newEndTime,
                                                // 年份季节 list 可以为空
                                                'yearSeason': yearSeason,
                                                // 竞品品牌 list 可以为空
                                                'brand': brand,
                                                // 品类 list 可以为空
                                                'category_name': category,
                                                // TOP款前几 可以为空
                                                'top': top,
                                                // 本期开始时间  可以为空
                                                'periodStartTime': periodStartTime,
                                                // 本期结束时间  可以为空
                                                'periodEndTime': periodEndTime,
                                                // 筛选字段，如果有本期时间则一定有本期销量、本期销售额、本期收藏量
                                                'field': choosedFieldsObj,
                                                // 统计周期
                                                'weekList': allWeekList,
                                                // 周期显示内容
                                                'displayContent': displayContent
                                            }
                                            AcceptBuried('query_competitive_data', "click", dataProps, props.location.pathname, "竞品新品追踪板块")
                                            setLoading(true)
                                            axios({
                                                method: 'POST',
                                                url: servicePath.searchCompetitorTrackingProducts,
                                                data: dataProps,
                                                headers: {
                                                    'authorization': cookie.load('token')
                                                },
                                                // 设置超时时间为3分钟
                                                timeout:180000
                                            }).then(res=>{
                                                setLoading(false)
                                                if (res.data.success) {
                                                    setData(res.data.data);
                                                    setAllData(res.data.data)
                                                    setColumns([
                                                        {
                                                            title: 'ID',
                                                            dataIndex: '商品id',
                                                            key: '商品id',
                                                            align: 'center',
                                                            fixed: 'left',
                                                            width: 100,
                                                            onHeaderCell: (record, index) => {
                                                                return (
                                                                    {
                                                                        style: {
                                                                            fontSize: '10px',
                                                                            padding: 0
                                                                        }
                                                                    }
                                                                )
                                                            },
                                                            onCell: (record, index) => {
                                                                return (
                                                                    {
                                                                        style: {
                                                                            fontSize: '10px',
                                                                            padding: 0
                                                                        }
                                                                    }
                                                                )
                                                            },
                                                            render: (text, record, index) => (
                                                                <div>
                                                                    <Image src={record['商品主图链接']} style={{ width: '80px', height: "80px" }} />
                                                                    <div>
                                                                        <a style={{ textDecoration: 'underline' }} href={record['商品链接']} target="_blank">{text}</a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        ...choosedFields.map(item => {
                                                            return {
                                                                title: item.name,
                                                                dataIndex: item.name,
                                                                key: item.name,
                                                                align: 'center',
                                                                width: 80,
                                                                sorter: item.name.indexOf('销售额') != -1 ||  item.name.indexOf('销量') != -1  ? true : false,
                                                                onHeaderCell: (record, index) => {
                                                                    return (
                                                                        {
                                                                            style: {
                                                                                fontSize: '10px',
                                                                                padding: 0
                                                                            }
                                                                        }
                                                                    )
                                                                },
                                                                onCell: (record, index) => {
                                                                    return (
                                                                        {
                                                                            style: {
                                                                                fontSize: '10px',
                                                                                padding: 0
                                                                            }
                                                                        }
                                                                    )
                                                                },
                                                                render: (text, record, index) => (
                                                                    <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                                                                )
                                                            }
                                                        }),
                                                        ...allWeekList.length != 0 ? allWeekList.map(item => {
                                                            return {
                                                                title: item,
                                                                dataIndex: item,
                                                                key: item,
                                                                align: 'center',
                                                                width: 80,
                                                                sorter: true,
                                                                onHeaderCell: (record, index) => {
                                                                    return (
                                                                        {
                                                                            style: {
                                                                                fontSize: '10px',
                                                                                padding: 0,
                                                                                // backgroundColor:'#bdbdbd'
                                                                            }
                                                                        }
                                                                    )
                                                                },
                                                                onCell: (record, index) => {
                                                                    return (
                                                                        {
                                                                            style: {
                                                                                fontSize: '10px',
                                                                                padding: 0
                                                                            }
                                                                        }
                                                                    )
                                                                },
                                                                render: (text, record, index) => (
                                                                    <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                                                                )
                                                            }
                                                        }) : ''
                                                    ])
                                                    setTableHeadList(tableFields)
                                                    setIsOpen(false)
                                                } else {
                                                    message.warn(res.data.errorMsg)
                                                }
                                            }).catch((err) => {
                                                setLoading(false)
                                                message.warn('查询竞品数据错误，请联系IT帮忙解决')
                                            })
                                            // NbAxios(dataProps, "POST", servicePath.searchCompetitorTrackingProducts).then(res => {
                                            //     setLoading(false)
                                            //     if (res.success) {
                                            //         setData(res.data);
                                            //         setColumns([
                                            //             {
                                            //                 title: 'ID',
                                            //                 dataIndex: '商品id',
                                            //                 key: '商品id',
                                            //                 align: 'center',
                                            //                 fixed: 'left',
                                            //                 width: 100,
                                            //                 onHeaderCell: (record, index) => {
                                            //                     return (
                                            //                         {
                                            //                             style: {
                                            //                                 fontSize: '10px',
                                            //                                 padding: 0
                                            //                             }
                                            //                         }
                                            //                     )
                                            //                 },
                                            //                 onCell: (record, index) => {
                                            //                     return (
                                            //                         {
                                            //                             style: {
                                            //                                 fontSize: '10px',
                                            //                                 padding: 0
                                            //                             }
                                            //                         }
                                            //                     )
                                            //                 },
                                            //                 render: (text, record, index) => (
                                            //                     <div>
                                            //                         <Image src={record['商品主图链接']} style={{ width: '80px', height: "80px" }} />
                                            //                         <div>
                                            //                             <a style={{ textDecoration: 'underline' }} href={record['商品链接']} target="_blank">{text}</a>
                                            //                         </div>
                                            //                     </div>
                                            //                 )
                                            //             },
                                            //             ...choosedFields.map(item => {
                                            //                 return {
                                            //                     title: item.name,
                                            //                     dataIndex: item.name,
                                            //                     key: item.name,
                                            //                     align: 'center',
                                            //                     width: 100,
                                            //                     onHeaderCell: (record, index) => {
                                            //                         return (
                                            //                             {
                                            //                                 style: {
                                            //                                     fontSize: '10px',
                                            //                                     padding: 0
                                            //                                 }
                                            //                             }
                                            //                         )
                                            //                     },
                                            //                     onCell: (record, index) => {
                                            //                         return (
                                            //                             {
                                            //                                 style: {
                                            //                                     fontSize: '10px',
                                            //                                     padding: 0
                                            //                                 }
                                            //                             }
                                            //                         )
                                            //                     },
                                            //                     render: (text, record, index) => (
                                            //                         <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                                            //                     )
                                            //                 }
                                            //             }),
                                            //             ...allWeekList.length != 0 ? allWeekList.map(item => {
                                            //                 return {
                                            //                     title: item,
                                            //                     dataIndex: item,
                                            //                     key: item,
                                            //                     align: 'center',
                                            //                     width: 100,
                                            //                     onHeaderCell: (record, index) => {
                                            //                         return (
                                            //                             {
                                            //                                 style: {
                                            //                                     fontSize: '10px',
                                            //                                     padding: 0
                                            //                                 }
                                            //                             }
                                            //                         )
                                            //                     },
                                            //                     onCell: (record, index) => {
                                            //                         return (
                                            //                             {
                                            //                                 style: {
                                            //                                     fontSize: '10px',
                                            //                                     padding: 0
                                            //                                 }
                                            //                             }
                                            //                         )
                                            //                     },
                                            //                     render: (text, record, index) => (
                                            //                         <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                                            //                     )
                                            //                 }
                                            //             }) : ''
                                            //         ])
                                            //         setTableHeadList(tableFields)
                                            //         setIsOpen(false)
                                            //     } else {
                                            //         message.warn(res.errorMsg)
                                            //     }
                                            // }).catch((err) => {
                                            //     setLoading(false)
                                            //     message.warn('查询竞品数据错误，请联系IT帮忙解决')
                                            // })
                                        } else {
                                            message.warn('请选择统计周期以及显示内容')
                                        }
                                    }else{
                                        message.warn('当填写好TOP款前数字时，可以选择多个竞品品牌；不填TOP款前数字的时候，只能选择一个竞品品牌')
                                    }
                                } else {
                                    message.warn('请选择合理的统计时间')
                                }


                            }}><Text style={{ color: '#fff' }}>确定</Text></Button>
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: 10, marginRight: 10, display:data.length == 0 ? 'none' : 'flex', justifyContent:'flex-end', marginTop:5, marginBottom:5}}>
                    <Button style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)", marginRight: 3 }} type="primary" onClick={()=>{
                        AcceptBuried('download_competitor_data', "click", {"data_download":"数据下载"}, props.location.pathname, "竞品新品追踪板块")
                        let dataProps = {
                            "roleId": cookie.load('role'),
                            "shopList": cookie.load('shopList'),
                            'data': data,
                            'date': statisticsTime,
                            'name': '竞品新品数据',
                            'table_head_list': tableHeadList
                        }
                        setLoading(true)
                        axios({
                            data: dataProps,
                            method: 'POST',
                            url: servicePath.downloadCompetitorTrackingProduct,
                            responseType: "blob",
                            headers: {
                                'Content-Type': 'application/json',  //如果写成contentType会报错
                                'authorization': cookie.load('token')
                            }
                        }).then(
                            res => {
                                setLoading(false)
                                downExcel(res)
                            }
                        ).catch(e => {
                            setLoading(false)
                            message.error({ content: '文件制作出错，请联系it同事！' })
                        })
                    }}>
                        <Text style={{ color: '#fff' }}>下载</Text>
                    </Button>
                </div>
                <div style={{ marginLeft: 10, marginRight: 10, marginTop: 5, display:data.length == 0 ? 'none' : '' ,paddingBottom:100}}>
                    <Table 
                    locale={{
                        cancelSort: "点击取消排序",
                        triggerAsc: "点击升序",
                        triggerDesc: "点击降序"
                    }}
                    dataSource={data} columns={columns} bordered pagination={false} scroll={{ y: 'calc(95vh - 300px)', x: 1000 }} onChange={(pagination, filters, sorter, extra)=>{
                            // console.log(sorter['columnKey']);
                            // console.log(sorter['order']);
                            if(sorter['order'] == undefined){
                                setData(allAata)
                            }else{
                                let dataProps = {}
                                dataProps.roleId = cookie.load('role')
                                dataProps.shopList = cookie.load('shopList')
                                dataProps.data = data
                                dataProps.df_field_name = sorter['columnKey']
                                dataProps.is_asc = sorter['order']
                                setLoading(true)
                                NbAxios(dataProps, "POST", servicePath.sortCompetitorTrackingProducts).then(res => {
                                    setLoading(false)
                                    if (res.success) {
                                        setData(res.data);
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                }).catch((err) => { 
                                    // 获取500错误
                                    setLoading(false)
                                    message.warn('查询接口报错，请联系IT帮忙解决')
                                })
                            }
                           
                    }} />
                </div>
            </Spin>

        </div>
    )

}

export default Index