import React, { useEffect, useState, useRef } from 'react';
import { DatePicker, Typography, message, Divider, Table, Select, Input, Space, Button, Checkbox, Statistic, Spin } from 'antd'
import moment from 'moment'
import { Responsive, WidthProvider , GridLayout} from 'react-grid-layout';
import AcceptBuried from '../../config/utils/buried';
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'
import axios from 'axios';
import SplitTimeChart from './components/splitTimeChart'
import TimePeriodAccumulationChart from './components/timePeriodAccumulationChart'
import PlatformEventsCalendarUp from '../../component/platformEventsCalendarUp'
import FansChart from './components/fansCard'
import DataCard from './components/dataCard';
import ChartCard from './components/chartCard';
import IntegralChart from './components/integralChart'
import InteractiveChart from './components/interactiveChart'
import downExcel from '../../config/utils/downloadFile';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
const { Title, Text } = Typography;
const { Option } = Select
const dateFormat = 'YYYY-MM-DD';

function Index(props) {
    const ResponsiveGridLayout = WidthProvider(Responsive);
    //分时段趋势
    const splitTimeRef = useRef(null)
    function disabledDate(current) {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'gs':'703247c1-a200-42bc-b70f-742dbab27aa4',
        'qda':"2715f3fa-0dae-4dc7-bcfc-0dd912314e40"
    }
    // 分时段趋势
    const [splitTimeHead, setSplitTimeHead] = useState([])
    const [splitTimeData, setSplitTimeData] = useState([])
    const [splitTimeAdd, setSplitTimeAdd] = useState([])
    const [splitTimeReduce, setSplitTimeReduce] = useState([])
    // 时段累计
    const [ timePeriodAccumulationHead , setTimePeriodAccumulationHead] = useState([])
    const [ timePeriodAccumulationData , setTimePeriodAccumulationData] = useState([])
    const [ timePeriodAccumulationAdd , setTimePeriodAccumulationAdd] = useState([])
    const [ timePeriodAccumulationReduce , setTimePeriodAccumulationReduce] = useState([])
    // 粉丝数图表数据
    const [ newFans, setNewFans ] = useState([])
    const [ increaseFans, setIncreaseFans ] = useState([])
    const [ takeOffFans, setTakeOffFans ] = useState([])
    const [ fansData , setFansData ] = useState([])
    // 屏幕高度
    const [ innerHeight, setInnerHeight ] = useState('11.5vh')
    const [layout, setLayout] = useState([
        { i: 'a', x: 0, y: 0, w: 6, h: 3, minW: 2, minH: 2},
        { i: 'b', x: 6, y: 0, w: 6, h: 3, minW: 2, minH: 2},
        { i: 'c', x: 0, y: 0, w: 6, h: 3, minW: 2, minH: 2},
        { i: 'd', x: 6, y: 0, w: 6, h: 3, minW: 6, minH: 2},
        { i: 'e', x: 0, y: 0, w: 6, h: 3, minW: 2, minH: 2},
        { i: 'f', x: 6, y: 0, w: 6, h: 3, minW: 2, minH: 2},
    ])
    // 是否加载
    const [loading, setLoading] = useState(false)
    // 日期
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    // 数据时间
    const [dataTime, setDataTime] = useState([])
    // 开始时间
    const [ startTime , setStartTime ] = useState(moment().subtract(15, 'd').format('YYYY-MM-DD'))
    const [ endTime , setEndTime ] = useState(moment().format('YYYY-MM-DD'))
    // 数据
    const [itemData, setItemData] = useState([])
    // 表头
    const [columns, setColumns] = useState([])
    // 会员图表加载
    const [ memberChartSpinning , setMemberCharSpinning] = useState(false)
    // 会员数据
    const [ memberData , setMemberData ] = useState({
        '入会人数':'',
        '入会人数同比':'',
        '入会人数环比':'',
        '会员规模':'',
        '会员规模同比':'',
        '会员规模环比':'',
        '退会人数':'',
        '退会人数同比':'',
        '退会人数环比':'',
        '图表':[],
    })
    // 成交金额
    const [ turnoverData, setTurnoverData] = useState({
        '总成交金额':'',
        '总成交金额环比':'',
        '总成交金额同比':'',
        '会员成交金额':'',
        '会员成交金额同比':'',
        '会员成交金额环比':'',
        '占比':'',
    })
    // 会员成交人数
    const [ memberTransactionsData, setMemberTransactionsData] = useState({
        '总成交人数':'',
        '总成交人数环比':'',
        '总成交人数同比':'',
        '会员成交人数':'',
        '会员成交人数同比':'',
        '会员成交人数环比':'',
        '占比':'',
    })
    // 活跃人数
    const [activePeopleData, setActivePeopleData] = useState({
        '总活跃人数':'',
        '总活跃人数环比':'',
        '总活跃人数同比':'',
        '活跃会员人数':'',
        '活跃会员人数同比':'',
        '活跃会员人数环比':'',
        '占比':'',
    })
    // 互动数据
    const [ interactiveData , setInteractiveData ] = useState({
        'head':[],
        '互动人数':[],
        '互动发送积分':[],
        '互动次数':[],
    })
    // 积分数据
    const [ integralData, setIntegralData] = useState({
        'head':[],
        '积分发送':[],
        '积分消耗':[],
        '核销率':[],
    })

    // 明细表头
    const [detailsColumns] = useState([
        {
            title: '日期',
            dataIndex: '日期',
            key: '日期',
            align: 'center',
            fixed: 'left',
            width: 100, 
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '10px',
                            padding: 0
                        }
                    }
                )
            },
            render: (text, record, index) => (
                <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
            )
        },
        {
            title:'粉丝规模',
            children:[
                {
                    title: '累计粉丝数',
                    dataIndex: '累计粉丝数',
                    key: '累计粉丝数',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '新增粉丝数',
                    dataIndex: '新增粉丝数',
                    key: '新增粉丝数',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                }
            ]
        },
        {
            title:'会员规模',
            children:[
                {
                    title: '会员规模',
                    dataIndex: '会员规模',
                    key: '会员规模',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '入会人数',
                    dataIndex: '入会人数',
                    key: '入会人数',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '昨日退会人数',
                    dataIndex: '昨日退会人数',
                    key: '昨日退会人数',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                }
            ]
        },
        {
            title:'会员贡献',
            children:[
                {
                    title: '总成交金额',
                    dataIndex: '总成交金额',
                    key: '总成交金额',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '成交金额占比',
                    dataIndex: '成交金额占比',
                    key: '成交金额占比',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '总成交人数',
                    dataIndex: '总成交人数',
                    key: '总成交人数',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '成交人数占比',
                    dataIndex: '成交人数占比',
                    key: '成交人数占比',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '活跃会员人数',
                    dataIndex: '活跃会员人数',
                    key: '活跃会员人数',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '活跃会员占比',
                    dataIndex: '活跃会员占比',
                    key: '活跃会员占比',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '同行活跃会员占比',
                    dataIndex: '同行活跃会员占比',
                    key: '同行活跃会员占比',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                }
            ]
        },
        {
            title:'会员互动情况',
            children:[
                {
                    title: '积分核销率',
                    dataIndex: '积分核销率',
                    key: '积分核销率',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '交易发送积分',
                    dataIndex: '交易发送积分',
                    key: '交易发送积分',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '互动发送积分',
                    dataIndex: '互动发送积分',
                    key: '互动发送积分',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '积分消耗数',
                    dataIndex: '积分消耗数',
                    key: '积分消耗数',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '互动人数',
                    dataIndex: '互动人数',
                    key: '互动人数',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                },
                {
                    title: '互动次数',
                    dataIndex: '互动次数',
                    key: '互动次数',
                    align: 'center',
                    width: 100, 
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                }
            ]
        }
    ])
    // 明细数据
    const [ detailsData , setDetailsData ] = useState([])

    useEffect(()=>{
        setColumns([
            {
                title: '指标',
                dataIndex: 'item',
                key: 'item',
                align: 'center',
                fixed: 'left',
                width: 100,
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '10px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '10px',
                                padding: 0
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                )
            },
            ...dataTime.map(item => {
                return {
                    title: item,
                    dataIndex: item,
                    key: item,
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '10px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div style={{ fontSize: 13, textAlign: 'center' }}>{text}</div>
                    )
                }
            })
        ])
    },[dataTime])

    useEffect(()=>{
        AcceptBuried('page_views',"view",{"page_type":"初始化页面"},props.location.pathname,"会员报表")
        getDataForDay()
    },[])

    // 获取日数据
    const getDataForDay = (dateString)=>{
        setItemData([])
        setSplitTimeHead([])
        setSplitTimeData([])
        setSplitTimeAdd([])
        setSplitTimeReduce([])
        setNewFans([])
        setIncreaseFans([])
        setTakeOffFans([])
        setFansData([])
        setMemberData({
            '入会人数':'',
            '入会人数同比':'',
            '入会人数环比':'',
            '会员规模':'',
            '会员规模同比':'',
            '会员规模环比':'',
            '退会人数':'',
            '退会人数同比':'',
            '退会人数环比':'',
            '图表':[],
        })
        setTurnoverData({
            '总成交金额':'',
            '总成交金额环比':'',
            '总成交金额同比':'',
            '会员成交金额':'',
            '会员成交金额同比':'',
            '会员成交金额环比':'',
            '占比':'',
        })
        setMemberTransactionsData({
            '成交人数':'',
            '成交人数环比':'',
            '成交人数同比':'',
            '会员成交人数':'',
            '会员成交人数同比':'',
            '会员成交人数环比':'',
            '占比':'',
        })
        setActivePeopleData({
            '活跃人数':'',
            '活跃人数环比':'',
            '活跃人数同比':'',
            '活跃会员数':'',
            '活跃会员数同比':'',
            '活跃会员数环比':'',
            '占比':'',
        })
        setInteractiveData({
            'head':[],
            '互动人数':[],
            '互动发送积分':[],
            '互动次数':[],
        })
        setIntegralData({
            'head':[],
            '积分发送':[],
            '积分消耗':[],
            '核销率':[],
        })
        let dataProps={
            "date":dateString == undefined ? date : dateString,
            "cycle":"日"
        }
        AcceptBuried('query_all_page_data', "click", dataProps, props.location.pathname, "会员报表")
        const getData = async () => {
            await Promise.all([
                getFansDataForDay(dateString),
                getMemberDataForDay(dateString),
                getInteractionDataForDay(dateString),
                getSummaryTableData()
            ])
        }
        getData()
    }

    // 获取周数据
    const getDataForWeek = ()=>{
        setItemData([])
        setSplitTimeHead([])
        setSplitTimeData([])
        setSplitTimeAdd([])
        setSplitTimeReduce([])
        setNewFans([])
        setIncreaseFans([])
        setTakeOffFans([])
        setFansData([])
        setMemberData({
            '入会人数':'',
            '入会人数同比':'',
            '入会人数环比':'',
            '会员规模':'',
            '会员规模同比':'',
            '会员规模环比':'',
            '退会人数':'',
            '退会人数同比':'',
            '退会人数环比':'',
            '图表':[],
        })
        setTurnoverData({
            '总成交金额':'',
            '总成交金额环比':'',
            '总成交金额同比':'',
            '会员成交金额':'',
            '会员成交金额同比':'',
            '会员成交金额环比':'',
            '占比':'',
        })
        setMemberTransactionsData({
            '成交人数':'',
            '成交人数环比':'',
            '成交人数同比':'',
            '会员成交人数':'',
            '会员成交人数同比':'',
            '会员成交人数环比':'',
            '占比':'',
        })
        setActivePeopleData({
            '活跃人数':'',
            '活跃人数环比':'',
            '活跃人数同比':'',
            '活跃会员数':'',
            '活跃会员数同比':'',
            '活跃会员数环比':'',
            '占比':'',
        })
        setInteractiveData({
            'head':[],
            '互动人数':[],
            '互动发送积分':[],
            '互动次数':[],
        })
        setIntegralData({
            'head':[],
            '积分发送':[],
            '积分消耗':[],
            '核销率':[],
        })
        let dataProps={
            "date":date,
            "cycle":"周"
        }
        AcceptBuried('query_all_page_data', "click", dataProps, props.location.pathname, "会员报表")
        const getData = async () => {
            await Promise.all([
                getFansDataForWeek(),
                getMemberDataForWeek(),
                getInteractionDataForWeek(),
                // getSummaryTableDataForWeek()
            ])
        }
        getData()
    }

    // 获取月数据
    const getDataForMonth = ()=>{
        setItemData([])
        setSplitTimeHead([])
        setSplitTimeData([])
        setSplitTimeAdd([])
        setSplitTimeReduce([])
        setNewFans([])
        setIncreaseFans([])
        setTakeOffFans([])
        setFansData([])
        setMemberData({
            '入会人数':'',
            '入会人数同比':'',
            '入会人数环比':'',
            '会员规模':'',
            '会员规模同比':'',
            '会员规模环比':'',
            '退会人数':'',
            '退会人数同比':'',
            '退会人数环比':'',
            '图表':[],
        })
        setTurnoverData({
            '总成交金额':'',
            '总成交金额环比':'',
            '总成交金额同比':'',
            '会员成交金额':'',
            '会员成交金额同比':'',
            '会员成交金额环比':'',
            '占比':'',
        })
        setMemberTransactionsData({
            '成交人数':'',
            '成交人数环比':'',
            '成交人数同比':'',
            '会员成交人数':'',
            '会员成交人数同比':'',
            '会员成交人数环比':'',
            '占比':'',
        })
        setActivePeopleData({
            '活跃人数':'',
            '活跃人数环比':'',
            '活跃人数同比':'',
            '活跃会员数':'',
            '活跃会员数同比':'',
            '活跃会员数环比':'',
            '占比':'',
        })
        setInteractiveData({
            'head':[],
            '互动人数':[],
            '互动发送积分':[],
            '互动次数':[],
        })
        setIntegralData({
            'head':[],
            '积分发送':[],
            '积分消耗':[],
            '核销率':[],
        })
        let dataProps={
            "date":date,
            "cycle":"月"
        }
        AcceptBuried('query_all_page_data', "click", dataProps, props.location.pathname, "会员报表")
        const getData = async () => {
            await Promise.all([
                getFansDataForMonth(),
                getMemberDataForMonth(),
                getInteractionDataForMonth(),
                // getSummaryTableDataForMonth()
            ])
        }
        getData()
    }
    // 获取日的粉丝
    const getFansDataForDay = (dateString)=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": dateString == undefined ? date : dateString,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "cycle":"日"
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getFansData).then(res => {
            setLoading(false)
            if (res.success) {
                setNewFans(res.data.chart2['新增粉丝数']);
                setIncreaseFans(res.data.chart2['净增粉丝数']);
                setTakeOffFans(res.data.chart2['取关粉丝数']);
                setFansData(res.data.table_head);
                setSplitTimeHead(res.data.chart1.chart_head);
                setSplitTimeData(res.data.chart1.data);
                setSplitTimeAdd(res.data.chart1.add);
                setSplitTimeReduce(res.data.chart1.reduce);
                setDataTime(res.data.table_head);
                setItemData(res.data.table);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询粉丝日数据接口报错，请联系IT帮忙解决')
        })
    }

    // 获取汇总表格日数据
    const getSummaryTableDataForDay = ()=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": date,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getSummaryTableDataForDay).then(res => {
            setLoading(false)
            if (res.success) {
                 setDetailsData(res.data.table_data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询汇总表格日数据接口报错，请联系IT帮忙解决')
        })
    }

    // 获取会员日数
    const getMemberDataForDay = (dateString)=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": dateString == undefined ? date : dateString,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "cycle":"日"
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getMemberData).then(res => {
            setLoading(false)
            if (res.success) {
               let obj1 = res.data.table1
               obj1['图表'] = res.data.chart1
               setMemberData(obj1);
               let obj2 = res.data.table2
               obj2['占比'] = res.data.chart2
               setTurnoverData(obj2)
               let obj3 = res.data.table3
               obj3['占比'] = res.data.chart3
               setMemberTransactionsData(obj3)
               let obj4 = res.data.table4
               obj4['占比'] = res.data.chart4
               setActivePeopleData(obj4)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询会员日数据错误，请联系IT帮忙解决')
        })
    }

    // 获取积分互动日数据
    const getInteractionDataForDay = (dateString)=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": dateString == undefined ? date : dateString,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "cycle":"日"
        }
        setMemberCharSpinning(true)
        NbAxios(values, "POST", servicePath.getInteractionData).then(res => {
            setMemberCharSpinning(false)
            if (res.success) {
                let obj1 = res.data.chart1
                obj1['head'] = res.data.table_head
                setIntegralData(obj1)
                let obj2 = res.data.chart2
                obj2['head'] = res.data.table_head
                setInteractiveData(obj2)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询会员日数据错误，请联系IT帮忙解决')
        })
    }

    // 获取汇总表格周数据
    const getSummaryTableData = ()=>{
        let buriedProps = {
            'startDate':startTime,
            "endDate":endTime
        }
        AcceptBuried('query_detailed_data', "click", buriedProps, props.location.pathname, "会员报表")
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "start_time": startTime,
            "end_time": endTime,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getSummaryTableData).then(res => {
            setLoading(false)
            console.log(res.data);
            if (res.success) {
                 setDetailsData(res.data.table_data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询汇总表格数据接口报错，请联系IT帮忙解决')
        })
    }

    // 获取周的粉丝
    const getFansDataForWeek = ()=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": date,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "cycle":"周"
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getFansData).then(res => {
            setLoading(false)
            if (res.success) {
                setSplitTimeHead(res.data.chart1.chart_head);
                setSplitTimeData(res.data.chart1.data);
                setSplitTimeAdd(res.data.chart1.add);
                setSplitTimeReduce(res.data.chart1.reduce);
                setNewFans(res.data.chart2['新增粉丝数']);
                setIncreaseFans(res.data.chart2['净增粉丝数']);
                setTakeOffFans(res.data.chart2['取关粉丝数']);
                setFansData(res.data.table_head);
                setDataTime(res.data.table_head);
                setItemData(res.data.table);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查粉丝周数据接口报错，请联系IT帮忙解决')
        })
    }

    // 获取会员周数据
    const getMemberDataForWeek = ()=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": date,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "cycle":"周"
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getMemberData).then(res => {
            setLoading(false)
            if (res.success) {
                let obj1 = res.data.table1
               obj1['图表'] = res.data.chart1
               setMemberData(obj1);
               let obj2 = res.data.table2
               obj2['占比'] = res.data.chart2
               setTurnoverData(obj2)
               let obj3 = res.data.table3
               obj3['占比'] = res.data.chart3
               setMemberTransactionsData(obj3)
               let obj4 = res.data.table4
               obj4['占比'] = res.data.chart4
               setActivePeopleData(obj4)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询会员周数据错误，请联系IT帮忙解决')
        })
    }

    // 获取汇总表格月数据
    const getSummaryTableDataForMonth = ()=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": date,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getSummaryTableDataForMonth).then(res => {
            setLoading(false)
            if (res.success) {
                 setDetailsData(res.data.table_data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询汇总表格月数据接口报错，请联系IT帮忙解决')
        })
    }

    // 获取月的粉丝
    const getFansDataForMonth = ()=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": date,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "cycle":"月"
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getFansData).then(res => {
            setLoading(false)
            if (res.success) {
                setSplitTimeHead(res.data.chart1.chart_head);
                setSplitTimeData(res.data.chart1.data);
                setSplitTimeAdd(res.data.chart1.add);
                setSplitTimeReduce(res.data.chart1.reduce);
                setNewFans(res.data.chart2['新增粉丝数']);
                setIncreaseFans(res.data.chart2['净增粉丝数']);
                setTakeOffFans(res.data.chart2['取关粉丝数']);
                setFansData(res.data.table_head);
                setDataTime(res.data.table_head);
                setItemData(res.data.table);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询粉丝月数据接口报错，请联系IT帮忙解决')
        })
    }

    // 获取积分互动周数据
    const getInteractionDataForWeek = ()=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": date,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "cycle":"周"
        }
        setMemberCharSpinning(true)
        NbAxios(values, "POST", servicePath.getInteractionData).then(res => {
            setMemberCharSpinning(false)
            if (res.success) {
                let obj1 = res.data.chart1
                obj1['head'] = res.data.table_head
                setIntegralData(obj1)
                let obj2 = res.data.chart2
                obj2['head'] = res.data.table_head
                setInteractiveData(obj2)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询会员日数据错误，请联系IT帮忙解决')
        })
    }

    // 获取会员月数据
    const getMemberDataForMonth = ()=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": date,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "cycle":"月"
        }
        setLoading(true)
        NbAxios(values, "POST", servicePath.getMemberData).then(res => {
            setLoading(false)
            if (res.success) {
               let obj1 = res.data.table1
               obj1['图表'] = res.data.chart1
               setMemberData(obj1);
               let obj2 = res.data.table2
               obj2['占比'] = res.data.chart2
               setTurnoverData(obj2)
               let obj3 = res.data.table3
               obj3['占比'] = res.data.chart3
               setMemberTransactionsData(obj3)
               let obj4 = res.data.table4
               obj4['占比'] = res.data.chart4
               setActivePeopleData(obj4)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询会员月数据错误，请联系IT帮忙解决')
        })
    }

     // 获取积分互动月数据
     const getInteractionDataForMonth = ()=>{
        let values = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "date": date,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "cycle":"月"
        }
        setMemberCharSpinning(true)
        NbAxios(values, "POST", servicePath.getInteractionData).then(res => {
            setMemberCharSpinning(false)
            if (res.success) {
                let obj1 = res.data.chart1
                obj1['head'] = res.data.table_head
                setIntegralData(obj1)
                let obj2 = res.data.chart2
                obj2['head'] = res.data.table_head
                setInteractiveData(obj2)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setLoading(false)
            message.warn('查询积分互动月数据错误，请联系IT帮忙解决')
        })
    }
   
    return (
        <div>
            <Spin spinning={loading}>
                <div style={{
                    display: 'flex',
                    justifyContent: "space-around",
                    backgroundColor: "#fff",
                    borderBottom: "solid 2px #ccc",
                    marginBottom: 10,
                    position: 'fixed',
                    top: 40,
                    zIndex: 99,
                    width: '100vw'
                }}>
                    <Title level={3}>{props.location.pathname.split('/')[2]}会员报表</Title>
                    <div style={{ display: 'flex', marginTop: 10, width: 350, justifyContent: 'space-around' }}>
                        <DatePicker defaultValue={date == '' ? '' : moment(date, dateFormat)} locale={locale} style={{ height: 26 }}
                            disabledDate={disabledDate}
                            onChange={(date, dateString) => {
                                if (dateString == '') {
                                    message.warn('请选择合理的时间')
                                    return false
                                } else {
                                    getDataForDay(dateString)
                                    setDate(dateString);
                                }
                            }}
                        />
                        <Button
                            style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                            onClick={() => {
                                getDataForDay()
                            }}
                        >
                            <Text>日</Text>
                        </Button>
                        <Button
                            style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                            onClick={() => {
                                getDataForWeek()
                            }}
                        >
                            <Text>周</Text>
                        </Button>
                        <Button
                            style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                            onClick={() => {
                                getDataForMonth()
                            }}
                        >
                            <Text>月</Text>
                        </Button>
                        <Button
                            type="primary"
                            disabled={detailsData.length == 0 ? true : false}
                            style={{ padding: "0 5px", width: 100, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                            onClick={()=>{
                                let dataProps = {}
                                dataProps.shopList = cookie.load('shopList')
                                dataProps.roleId = cookie.load('role')
                                dataProps.shopId = props.location.pathname.split('/')[2]
                                dataProps.date = date
                                dataProps.data = detailsData
                                dataProps.table_head = detailsColumns
                                dataProps.name = "明细数据"
                                console.log(dataProps);
                                setLoading(true)
                                axios({
                                    data: dataProps,
                                    method: 'POST',
                                    url: servicePath.downloadSummaryTableData,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        setLoading(false)
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                      // 获取500错误
                                    setLoading(false)
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}
                        >
                            <Text style={{ color: 'white' }}>明细数据导出</Text>
                        </Button>
                        {/* <Button type="dashed"
                            style={{ padding: "0 5px", width: 90, 
                            height: 26, display: "flex", 
                            justifyContent: "center", alignItems: "center", 
                            color: "rgba(0,0,0,0.5)", marginLeft:110 }}
                            onClick={()=>{
                                setLayout([
                                    { i: 'a', x: 0, y: 0, w: 6, h: 3, minW: 2, minH: 2},
                                    { i: 'b', x: 6, y: 0, w: 6, h: 3, minW: 2, minH: 2},
                                    { i: 'c', x: 0, y: 0, w: 6, h: 3, minW: 2, minH: 2},
                                    { i: 'd', x: 6, y: 0, w: 6, h: 3, minW: 6, minH: 2},
                                    { i: 'e', x: 0, y: 0, w: 6, h: 3, minW: 2, minH: 2},
                                    { i: 'f', x: 6, y: 0, w: 6, h: 3, minW: 2, minH: 2},
                                ])
                            }}
                        >
                            <Text>页面布局重置</Text>
                        </Button> */}
                    </div>
                </div>
                <div style={{marginLeft: 20, marginTop: 50}}>
                    <Title level={4}>粉丝数据</Title>
                </div>
                <div style={{ marginLeft: 20, marginRight: 20, display:dataTime.length == 0 ? 'none' :''  }}>
                    <Table dataSource={itemData} columns={columns} bordered pagination={false} />
                </div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:5}}>
                    <SplitTimeChart 
                        chartWidth={'48vw'}
                        chartHeight={'300px'}
                        // chartWidth={`100vw/13*${layout[layout.findIndex(o => o.i == 'a')]['w']}`} 
                        // chartHeight={`${innerHeight}*${layout[layout.findIndex(o => o.i == 'a')]['h']}`}
                        head = {splitTimeHead}
                        data = {splitTimeData}
                        add = {splitTimeAdd}
                        reduce = {splitTimeReduce}
                    />
                    <FansChart  chartWidth={'48vw'} chartHeight={'300px'}
                        newFans={newFans} increaseFans={increaseFans} 
                        takeOffFans={takeOffFans} fansData={fansData}
                    />
                </div>
                <div style={{marginLeft: 20, marginTop: 20}}>
                    <Title level={4}>会员数据</Title>
                </div>
                <div style={{display:'flex', justifyContent:'space-around'}}>
                    <ChartCard newMemberNum={memberData['入会人数']} newMemberNumYear={memberData['入会人数同比']} newMemberNumChain={memberData['入会人数环比']}
                        allMemberNum={memberData['会员规模']} allMemberNumYear={memberData['会员规模同比']} allMemberNumChain={memberData['会员规模环比']}
                        withdrawMemberNum={memberData['退会人数']} withdrawMemberNumYear={memberData['退会人数同比']} withdrawMemberNumChain={memberData['退会人数环比']}
                        chartWidth={`100vw/20*${layout[layout.findIndex(o => o.i == 'c')]['w']}`} chartHeight={`${innerHeight}*${layout[layout.findIndex(o => o.i == 'c')]['h']}`}
                        chartData={memberData['图表']} />
                    <div style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                        <DataCard overallIndicator={"总成交金额"} memberIndicator={"会员成交金额"} proportion={turnoverData['占比']}
                            overallIndicatorYear={turnoverData['总成交金额同比']} memberIndicatorYear={turnoverData['会员总数同比']} memberIndicatorChain={turnoverData['会员成交金额环比']} 
                            overallIndicatorChain={turnoverData['总成交金额环比']} overallIndicatorDate={turnoverData['总成交金额']} memberIndicatorDate={turnoverData['会员成交金额']} />
                        <DataCard overallIndicator={"总成交人数"} memberIndicator={"会员成交人数"}  proportion={memberTransactionsData['占比']}
                            overallIndicatorYear={memberTransactionsData['总成交人数同比']} memberIndicatorYear={memberTransactionsData['会员成交人数同比']} memberIndicatorChain={memberTransactionsData['会员成交人数环比']} 
                            overallIndicatorChain={memberTransactionsData['总成交人数环比']} overallIndicatorDate={memberTransactionsData['总成交人数']} memberIndicatorDate={memberTransactionsData['会员成交人数']} />
                        <DataCard overallIndicator={"总活跃人数"} memberIndicator={"活跃会员人数"} proportion={activePeopleData['占比']}
                            overallIndicatorYear={activePeopleData['总活跃人数同比']} memberIndicatorYear={activePeopleData['活跃会员人数同比']} memberIndicatorChain={activePeopleData['活跃会员人数环比']} 
                            overallIndicatorChain={activePeopleData['总活跃人数环比']} overallIndicatorDate={activePeopleData['总活跃人数']} memberIndicatorDate={activePeopleData['活跃会员人数']} />
                    </div>
                </div>
                <Spin spinning={memberChartSpinning} tip="数据加载中.....">
                    <div style={{display:'flex', justifyContent:'space-around'}}>
                        <IntegralChart
                            chartWidth={'48vw'} 
                            chartHeight={'400px'}
                            head={integralData['head']} rate={integralData['核销率']} consumptionPoints={integralData['积分消耗']} sendPoints={integralData['积分发送']}
                        />
                        <InteractiveChart 
                            chartWidth={'48vw'} 
                            chartHeight={'400px'}
                            head={interactiveData['head']} interactionsNumber={interactiveData['互动人数']} interactions={interactiveData['互动次数']} sendPoints={interactiveData['互动发送积分']}
                        />
                    </div>
                </Spin>
                {/* <ResponsiveGridLayout isResizable={true} style={{marginTop:80, marginLeft:10, marginRight:10 }} className="layout"  
                cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}  breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                onResizeStop={(changeLayout, changeLayouts)=>{
                    let newLayout = [...layout]
                    newLayout.map(item=>{
                        //  changeLayouts['i'] 找到移动的那个区域
                        if(item.i == changeLayouts['i']){
                            // 根据移动的区域获取现在的x、y、w、h
                            changeLayout.map(i=>{
                                if(i.i == changeLayouts['i']){
                                    item.x = i.x 
                                    item.y = i.y 
                                    item.w = i.w 
                                    item.h = i.h
                                    item.minW = i.minW
                                    item.minH = i.minH
                                }
                            })
                          
                        }
                    })
                    setLayout(newLayout);
                }}
                onDragStop={(changeLayout, changeLayouts)=>{
                    let newLayout = [...layout]
                    newLayout.map(item=>{
                        //  changeLayouts['i'] 找到移动的那个区域
                        if(item.i == changeLayouts['i']){
                            // 根据移动的区域获取现在的x、y、w、h
                            changeLayout.map(i=>{
                                if(i.i == changeLayouts['i']){
                                    item.x = i.x 
                                    item.y = i.y 
                                    item.w = i.w 
                                    item.h = i.h
                                    item.minW = i.minW
                                    item.minH = i.minH
                                }
                            })
                          
                        }
                    })
                    setLayout(newLayout);
                }}>
                    <div key={'a'} data-grid={layout[layout.findIndex(o => o.i == 'a')]} style={{padding:0}}>
                        <SplitTimeChart 
                            chartWidth={`100vw/13*${layout[layout.findIndex(o => o.i == 'a')]['w']}`} 
                            chartHeight={`${innerHeight}*${layout[layout.findIndex(o => o.i == 'a')]['h']}`}
                            head = {splitTimeHead}
                            data = {splitTimeData}
                            add = {splitTimeAdd}
                            reduce = {splitTimeReduce}
                        />
                    </div>
                    <div key={'b'}  data-grid={layout[layout.findIndex(o => o.i == 'b')]} >
                    <TimePeriodAccumulationChart 
                        chartWidth={`100vw/13*${layout[layout.findIndex(o => o.i == 'b')]['w']}`} 
                        chartHeight={`${innerHeight}*${layout[layout.findIndex(o => o.i == 'b')]['h']}`} 
                        head={timePeriodAccumulationHead}
                        data={timePeriodAccumulationData}
                        add={timePeriodAccumulationAdd}
                        reduce={timePeriodAccumulationReduce}
                    />
                    </div>
                    <div key={'c'}  data-grid={layout[layout.findIndex(o => o.i == 'c')]} >
                        <ChartCard newMemberNum={memberData['新增会员数']} newMemberNumYear={memberData['新增会员数同比']} newMemberNumChain={memberData['新增会员数环比']}
                            allMemberNum={memberData['会员总数']} allMemberNumYear={memberData['会员总数同比']} allMemberNumChain={memberData['会员总数环比']}
                            withdrawMemberNum={memberData['退会人数']} withdrawMemberNumYear={memberData['退会人数同比']} withdrawMemberNumChain={memberData['退会人数环比']}
                            chartWidth={`100vw/20*${layout[layout.findIndex(o => o.i == 'c')]['w']}`} chartHeight={`${innerHeight}*${layout[layout.findIndex(o => o.i == 'c')]['h']}`}
                            chartData={memberData['图表']} />
                    </div>
                    <div key={'d'}  data-grid={layout[layout.findIndex(o => o.i == 'd')]} >
                        <div style={{ display: 'flex', justifyContent:'space-around', width: `calc(\`100vw/13*${layout[layout.findIndex(o => o.i == 'd')]['w']}\`)`, height:`calc(${innerHeight}*${layout[layout.findIndex(o => o.i == 'c')]['h']})`, alignItems:'center' }}>
                            <DataCard overallIndicator={"总成交金额"} memberIndicator={"会员成交金额"} proportion={turnoverData['占比']}
                            overallIndicatorYear={turnoverData['总成交金额同比']} memberIndicatorYear={turnoverData['会员总数同比']} memberIndicatorChain={turnoverData['会员成交金额环比']} 
                            overallIndicatorChain={turnoverData['总成交金额环比']} overallIndicatorDate={turnoverData['总成交金额']} memberIndicatorDate={turnoverData['会员成交金额']} />
                            <DataCard overallIndicator={"成交人数"} memberIndicator={"会员成交人数"}  proportion={memberTransactionsData['占比']}
                            overallIndicatorYear={memberTransactionsData['成交人数同比']} memberIndicatorYear={memberTransactionsData['会员成交人数同比']} memberIndicatorChain={memberTransactionsData['会员成交人数环比']} 
                            overallIndicatorChain={memberTransactionsData['成交人数环比']} overallIndicatorDate={memberTransactionsData['成交人数']} memberIndicatorDate={memberTransactionsData['会员成交人数']} />
                            <DataCard overallIndicator={"活跃人数"} memberIndicator={"活跃会员人数"} proportion={activePeopleData['占比']}
                            overallIndicatorYear={activePeopleData['活跃人数同比']} memberIndicatorYear={activePeopleData['活跃会员数同比']} memberIndicatorChain={activePeopleData['活跃会员数环比']} 
                            overallIndicatorChain={activePeopleData['活跃人数环比']} overallIndicatorDate={activePeopleData['活跃人数']} memberIndicatorDate={activePeopleData['活跃会员数']} />
                        </div>
                    </div>
                    <div key={'e'}  data-grid={layout[layout.findIndex(o => o.i == 'e')]} >
                        <IntegralChart
                            chartWidth={`100vw/13*${layout[layout.findIndex(o => o.i == 'e')]['w']}`} 
                            chartHeight={`${innerHeight}*${layout[layout.findIndex(o => o.i == 'e')]['h']}`}
                            head={integralData['head']} rate={integralData['核销率']} consumptionPoints={integralData['积分消耗']} sendPoints={integralData['积分发送']}
                        />
                    </div>
                    <div key={'f'}  data-grid={layout[layout.findIndex(o => o.i == 'f')]} >
                        <InteractiveChart 
                            chartWidth={`100vw/13*${layout[layout.findIndex(o => o.i == 'f')]['w']}`} 
                            chartHeight={`${innerHeight}*${layout[layout.findIndex(o => o.i == 'f')]['h']}`}
                            head={interactiveData['head']} interactionsNumber={interactiveData['互动人数']} interactions={interactiveData['互动次数']} sendPoints={interactiveData['互动发送积分']}
                        />
                    </div>
                </ResponsiveGridLayout> */}
                <div style={{marginLeft:10, marginRight:10, display:'flex', justifyContent:'flex-end', marginBottom:5}}>
                    <div>
                        <PlatformEventsCalendarUp setEndDate={setEndTime} setStartDate={setStartTime} endDate={endTime} startDate={startTime} />
                    </div>
                    <Button type="primary" onClick={()=>{getSummaryTableData()}}
                    style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", marginRight:10}}>查询数据</Button>
                    <Button type="dashed" disabled={detailsData.length == 0 ? true : false}
                        style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)", marginRight:270}}
                        onClick={()=>{
                            let buriedProps = {
                                'startDate':startTime,
                                "endDate":endTime
                            }
                            AcceptBuried('download_detailed_data', "click", buriedProps, props.location.pathname, "会员报表")
                            let dataProps = {}
                            dataProps.shopList = cookie.load('shopList')
                            dataProps.roleId = cookie.load('role')
                            dataProps.shopId = props.location.pathname.split('/')[2]
                            dataProps.date = date
                            dataProps.data = detailsData
                            dataProps.table_head = detailsColumns
                            dataProps.name = "明细数据"
                            setLoading(true)
                            axios({
                                data: dataProps,
                                method: 'POST',
                                url: servicePath.downloadSummaryTableData,
                                responseType: "blob",
                                headers: {
                                    'Content-Type': 'application/json',  //如果写成contentType会报错
                                    'authorization': cookie.load('token')
                                }
                            }).then(
                                res => {
                                    // 获取500错误
                                    setLoading(false)
                                    downExcel(res)
                                }
                            ).catch(e => {
                                // 获取500错误
                                setLoading(false)
                                message.error({ content: '文件制作出错，请联系it同事！' })
                            })
                        }}
                        >
                            <Text>明细数据导出</Text>
                    </Button>
                </div>
                <div style={{marginLeft:10, marginRight:10, paddingBottom:100}}>
                    <Table columns={detailsColumns} dataSource={detailsData} bordered pagination={false} scroll={{y:'500px'}}/>
                </div>
            </Spin>

        </div>
    )

}

export default Index