import React, { useEffect, useState } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import { Divider, Tree, Typography , Radio} from 'antd';
import { DownOutlined,EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const { Text } = Typography

function Index({
    sankeyDiagramData,
}) {
    const [ sankeyDiagramDataList1, setSankeyDiagramDataList1] = useState([])
    const [ sankeyDiagramDataList2, setSankeyDiagramDataList2] = useState([])
    const [ sankeyDiagramDataList3, setSankeyDiagramDataList3] = useState([])
    const [ sankeyDiagramDataList4, setSankeyDiagramDataList4] = useState([])

    const [ sankeyDiagramTreeData1 , setSankeyDiagramTreeData1] = useState([])
    const [ sankeyDiagramTreeData2 , setSankeyDiagramTreeData2] = useState([])
    const [ sankeyDiagramTreeData3 , setSankeyDiagramTreeData3] = useState([])
    const [ sankeyDiagramTreeData4 , setSankeyDiagramTreeData4] = useState([])

    const [dataList1, setDataList1] = useState([])
    const [dataList2, setDataList2] = useState([])
    const [dataList3, setDataList3] = useState([])
    const [dataList4, setDataList4] = useState([])

    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
    const [data4, setData4] = useState([])

    const [treeData1, setTreeData1] = useState([])
    const [treeData2, setTreeData2] = useState([])
    const [treeData3, setTreeData3] = useState([])
    const [treeData4, setTreeData4] = useState([])

    const [ radioList , setRadioList ] = useState([])
    const [ radioValue , setRadioValue] = useState('')

    useEffect(()=>{
        if(sankeyDiagramData['radioList']!= undefined){
            setRadioList(sankeyDiagramData['radioList']);
            setRadioValue(sankeyDiagramData['radioList'][0]);
        }
    },[sankeyDiagramData])

    useEffect(()=>{
        if(radioList.length != 0){
            setSankeyDiagramDataList1(sankeyDiagramData['data'][radioValue]['sankey_diagram_data_list'][0]);
            setSankeyDiagramDataList2(sankeyDiagramData['data'][radioValue]['sankey_diagram_data_list'][1]);
            setSankeyDiagramDataList3(sankeyDiagramData['data'][radioValue]['sankey_diagram_data_list'][2]);
            setSankeyDiagramDataList4(sankeyDiagramData['data'][radioValue]['sankey_diagram_data_list'][3]);
            setSankeyDiagramTreeData1(sankeyDiagramData['data'][radioValue]['tree_data'][0])
            setSankeyDiagramTreeData2(sankeyDiagramData['data'][radioValue]['tree_data'][1])
        }
    },[radioValue])

    useEffect(() => {
        setDataList1(sankeyDiagramDataList1)
        setDataList2(sankeyDiagramDataList2)
        setDataList3(sankeyDiagramDataList3)
        setDataList4(sankeyDiagramDataList4)
        setData1(sankeyDiagramDataList1)
        setData2(sankeyDiagramDataList2)
        setData3(sankeyDiagramDataList3)
        setData4(sankeyDiagramDataList4)
        setTreeData1(sankeyDiagramTreeData1)
        setTreeData2(sankeyDiagramTreeData2)
        setTreeData3(sankeyDiagramTreeData3)
        setTreeData4(sankeyDiagramTreeData4)
    }, [sankeyDiagramTreeData2])

   

    let report1 = {
        series: {
            id: 'report1',
            type: 'sunburst',
            nodeClick: false,
            data: data1,
            radius: [0, '90%'],
            label: {
                rotate: 'radial'
            },
        },
        tooltip: {
            enterable: true,
        }
    }
    let report2 = {
        series: {
            id: 'report2',
            type: 'sunburst',
            nodeClick: false,
            data: data2,
            radius: [0, '90%'],
            label: {
                rotate: 'radial'
            }
        },
        tooltip: {
            enterable: true,
        }
    }

    let report3 = {
        series: {
            id: 'report3',
            type: 'sunburst',
            nodeClick: false,
            data: data3,
            radius: [0, '90%'],
            label: {
                rotate: 'radial'
            }
        },
        tooltip: {
            enterable: true,
        }
    }

    let report4 = {
        series: {
            id: 'report4',
            type: 'sunburst',
            nodeClick: false,
            data: data4,
            radius: [0, '90%'],
            label: {
                rotate: 'radial'
            }
        },
        tooltip: {
            enterable: true,
        }
    }

    useEffect(() => {
        if (dataList4.length != 0) {
            // 基于准备好的dom，初始化echarts实例
            let reportChart1 = echarts.init(document.getElementById('report1'));
            let reportChart2 = echarts.init(document.getElementById('report2'));
            let reportChart3 = echarts.init(document.getElementById('report3'));
            let reportChart4 = echarts.init(document.getElementById('report4'));
            reportChart2.on('mouseover', function (params) {
                reportChart1.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart1.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })

            })
            reportChart2.on('mouseout', function (params) {
                reportChart1.dispatchAction({
                    type: 'downplay',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart1.dispatchAction({
                    type: 'hideTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })

                reportChart3.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })

                reportChart4.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
            })
            reportChart1.on('mouseover', function (params) {
                reportChart2.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart2.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
            })
            reportChart1.on('mouseout', function (params) {
                reportChart2.dispatchAction({
                    type: 'downplay',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart2.dispatchAction({
                    type: 'hideTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'downplay',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'hideTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'downplay',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'hideTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
            })
            reportChart3.on('mouseover', function (params) {
                reportChart2.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart2.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart1.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart1.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
            })
            reportChart3.on('mouseout', function (params) {
                reportChart2.dispatchAction({
                    type: 'downplay',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart2.dispatchAction({
                    type: 'hideTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart1.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart1.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart4.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
            })
            reportChart4.on('mouseover', function (params) {
                reportChart2.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart2.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart1.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart1.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
            })
            reportChart4.on('mouseout', function (params) {
                reportChart2.dispatchAction({
                    type: 'downplay',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart2.dispatchAction({
                    type: 'hideTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart3.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart1.dispatchAction({
                    type: 'highlight',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
                reportChart1.dispatchAction({
                    type: 'showTip',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                })
            })
            // 绘制图表
            reportChart1.setOption(report1);
            reportChart2.setOption(report2);
            reportChart3.setOption(report3);
            reportChart4.setOption(report4);
        }
    }, [data1, data2, data3, data4])
    // 表格是否展示
    const [ isShow , setIsShow ] = useState(1)
    return (
        <div style={{ backgroundColor: "white",marginLeft:20, width:'calc(98vw - 17px)' }}>
            <Divider orientation="left">旭日图</Divider>
            {
                isShow == 0 ? <EyeOutlined style={{marginLeft:7, color:"#039be5", fontSize:20}} onClick={()=>{
                    setIsShow(1)
                }} />:
                <EyeInvisibleOutlined style={{marginLeft:7, color:"#039be5", fontSize:20}} onClick={()=>{
                    setIsShow(0)
                }} />
            }
            <div style={{display:'flex', justifyContent:'flex-end'}}>
                <Radio.Group value={radioValue} onChange={(e)=>{
                    setRadioValue(e.target.value);
                }}>
                    {
                        radioList.map(item=>{
                            return <Radio value={item}>{item}</Radio>
                        })
                    }
                </Radio.Group>
            </div>
            <div style={{ display:isShow == 1 ? 'flex' : 'none', flexWrap: 'wrap', width:'calc(98vw - 17px)' }}>
                {
                    dataList1.length != 0 ?
                        <div style={{ display: dataList1[0]['efficient'] == 0 ? "none" : 'grid', gridTemplateColumns: "150px calc((98vw - 20px)/2 - 150px)" }}>
                            <div style={{ height: '400px', overflowY: 'auto' }}>
                                <Tree
                                    showLine
                                    defaultExpandAll={true}
                                    switcherIcon={<DownOutlined />}
                                    onSelect={(selectedKeys, info) => {
                                        if (selectedKeys.length != 0) {
                                            if (selectedKeys[0] != '全部') {
                                                let keyList = selectedKeys[0].split('-')
                                                // console.log(keyList);
                                                let keys = []
                                                keyList.map(item => {
                                                    if (keys[keys.length - 1] == undefined) {
                                                        keys.push(item)
                                                    } else {
                                                        keys.push(keys[keys.length - 1] + '-' + item)

                                                    }
                                                })
                                                // 处理第一个
                                                let obj1 = dataList1
                                                let objList1 = []
                                                keys.map((item, index) => {
                                                    // console.log(obj);
                                                    obj1.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj1 = i
                                                                objList1.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj1 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj1 = i
                                                                objList1.push(obj1)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData1(objList1);
                                                // 处理第二个
                                                let obj2 = dataList2
                                                let objList2 = []
                                                keys.map((item, index) => {
                                                    obj2.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj2 = i
                                                                objList2.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj2 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj2 = i
                                                                objList2.push(obj2)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData2(objList2);
                                                // 处理第三个
                                                let obj3 = dataList3
                                                let objList3 = []
                                                keys.map((item, index) => {
                                                    obj3.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj3 = i
                                                                objList3.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj3 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj3 = i
                                                                objList3.push(obj3)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData3(objList3);
                                                // 处理第四个
                                                let obj4 = dataList4
                                                let objList4 = []
                                                keys.map((item, index) => {
                                                    obj4.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj4 = i
                                                                objList4.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj4 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj4 = i
                                                                objList4.push(obj4)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData4(objList4);
                                            } else {
                                                let obj1 = [...dataList1]
                                                setData1(obj1)
                                                let obj2 = [...dataList2]
                                                setData2(obj2)
                                                let obj3 = [...dataList3]
                                                setData3(obj3)
                                                let obj4 = [...dataList4]
                                                setData4(obj4)
                                            }
                                        }


                                    }}
                                    treeData={treeData1}
                                />
                            </div>
                            <div>
                                <div id="report1" style={{ width: 'calc((98vw - 20px)/2 - 150px)', height: '400px', marginTop: 10 }}></div>
                                <div style={{ display: data1[0]['value'] != 0 ? 'none' : 'flex', justifyContent: 'center', marginTop: '-200px' }}>
                                    <Text strong type="danger">该维度暂无该指标数据</Text>
                                </div>
                            </div>
                        </div> : <span></span>
                }
                {
                    dataList2.length != 0 ?
                        <div style={{ display: dataList2[0]['efficient'] == 0 ? "none" : 'grid', gridTemplateColumns: "calc((98vw - 20px)/2 - 150px) 150px" }}>
                            <div>
                                <div id="report2" style={{ width: 'calc((98vw - 20px)/2 - 150px)', height: '400px', marginTop: 10 }}></div>
                                <div style={{ display: data2[0]['value'] != 0 ? 'none' : 'flex', justifyContent: 'center', marginTop: '-200px' }}>
                                    <Text strong type="danger">该维度暂无该指标数据</Text>
                                </div>
                            </div>
                            <div style={{ height: '400px', overflowY: 'auto' }}>
                                <Tree
                                    showLine
                                    defaultExpandAll={true}
                                    switcherIcon={<DownOutlined />}
                                    onSelect={(selectedKeys, info) => {
                                        if (selectedKeys.length != 0) {
                                            if (selectedKeys[0] != '全部') {
                                                let keyList = selectedKeys[0].split('-')
                                                // console.log(keyList);
                                                let keys = []
                                                keyList.map(item => {
                                                    if (keys[keys.length - 1] == undefined) {
                                                        keys.push(item)
                                                    } else {
                                                        keys.push(keys[keys.length - 1] + '-' + item)

                                                    }
                                                })
                                                // 处理第一个
                                                let obj1 = dataList1
                                                let objList1 = []
                                                keys.map((item, index) => {
                                                    // console.log(obj);
                                                    obj1.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj1 = i
                                                                objList1.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj1 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj1 = i
                                                                objList1.push(obj1)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData1(objList1);
                                                // 处理第二个
                                                let obj2 = dataList2
                                                let objList2 = []
                                                keys.map((item, index) => {
                                                    obj2.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj2 = i
                                                                objList2.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj2 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj2 = i
                                                                objList2.push(obj2)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData2(objList2);
                                                // 处理第三个
                                                let obj3 = dataList3
                                                let objList3 = []
                                                keys.map((item, index) => {
                                                    obj3.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj3 = i
                                                                objList3.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj3 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj3 = i
                                                                objList3.push(obj3)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData3(objList3);
                                                // 处理第四个
                                                let obj4 = dataList4
                                                let objList4 = []
                                                keys.map((item, index) => {
                                                    obj4.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj4 = i
                                                                objList4.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj4 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj4 = i
                                                                objList4.push(obj4)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData4(objList4);
                                            } else {
                                                let obj1 = [...dataList1]
                                                setData1(obj1)
                                                let obj2 = [...dataList2]
                                                setData2(obj2)
                                                let obj3 = [...dataList3]
                                                setData3(obj3)
                                                let obj4 = [...dataList4]
                                                setData4(obj4)
                                            }
                                        }


                                    }}
                                    treeData={treeData2}
                                />
                            </div>

                        </div> : <span></span>
                }
                {
                    dataList3.length != 0 ?
                        <div style={{ display: dataList3[0]['efficient'] == 0 ? "none" : 'grid', gridTemplateColumns: "150px calc((98vw - 20px)/2 - 150px)" }}>
                            <div style={{ height: '400px', overflowY: 'auto' }}>
                                <Tree
                                    showLine
                                    defaultExpandAll={true}
                                    switcherIcon={<DownOutlined />}
                                    onSelect={(selectedKeys, info) => {
                                        // let key = selectedKeys[0]
                                        // console.log(key);
                                        if (selectedKeys.length != 0) {
                                            if (selectedKeys[0] != '全部') {
                                                let keyList = selectedKeys[0].split('-')
                                                // console.log(keyList);
                                                let keys = []
                                                keyList.map(item => {
                                                    if (keys[keys.length - 1] == undefined) {
                                                        keys.push(item)
                                                    } else {
                                                        keys.push(keys[keys.length - 1] + '-' + item)

                                                    }
                                                })
                                                // 处理第一个
                                                let obj1 = dataList1
                                                let objList1 = []
                                                keys.map((item, index) => {
                                                    // console.log(obj);
                                                    obj1.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj1 = i
                                                                objList1.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj1 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj1 = i
                                                                objList1.push(obj1)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData1(objList1);
                                                // 处理第二个
                                                let obj2 = dataList2
                                                let objList2 = []
                                                keys.map((item, index) => {
                                                    obj2.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj2 = i
                                                                objList2.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj2 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj2 = i
                                                                objList2.push(obj2)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData2(objList2);
                                                // 处理第三个
                                                let obj3 = dataList3
                                                let objList3 = []
                                                keys.map((item, index) => {
                                                    obj3.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj3 = i
                                                                objList3.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj3 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj3 = i
                                                                objList3.push(obj3)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData3(objList3);
                                                // 处理第四个
                                                let obj4 = dataList4
                                                let objList4 = []
                                                keys.map((item, index) => {
                                                    obj4.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj4 = i
                                                                objList4.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj4 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj4 = i
                                                                objList4.push(obj4)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData4(objList4);
                                            } else {
                                                let obj1 = [...dataList1]
                                                setData1(obj1)
                                                let obj2 = [...dataList2]
                                                setData2(obj2)
                                                let obj3 = [...dataList3]
                                                setData3(obj3)
                                                let obj4 = [...dataList4]
                                                setData4(obj4)
                                            }
                                        }


                                    }}
                                    treeData={treeData3}
                                />
                            </div>
                            <div>
                                <div id="report3" style={{ width: 'calc((98vw - 20px)/2 - 150px)', height: '400px', marginTop: 10 }}></div>
                                <div style={{ display: data3[0]['value'] != 0 ? 'none' : 'flex', justifyContent: 'center', marginTop: '-200px' }}>
                                    <Text strong type="danger">该维度暂无该指标数据</Text>
                                </div>
                            </div>
                        </div> : <span></span>
                }
                {
                    dataList4.length != 0 ?
                        <div style={{ display: dataList4[0]['efficient'] == 0 ? "none" : 'grid', gridTemplateColumns: "calc((98vw - 20px)/2 - 150px) 150px" }}>
                            <div>
                                <div id="report4" style={{ width: 'calc((98vw - 20px)/2 - 150px)', height: '400px', marginTop: 10 }}></div>
                                <div style={{ display: data4[0]['value'] != 0 ? 'none' : 'flex', justifyContent: 'center', marginTop: '-200px' }}>
                                    <Text strong type="danger">该维度暂无该指标数据</Text>
                                </div>
                            </div>
                            <div style={{ height: '400px', overflowY: 'auto' }}>
                                <Tree
                                    showLine
                                    defaultExpandAll={true}
                                    switcherIcon={<DownOutlined />}
                                    onSelect={(selectedKeys, info) => {
                                        // let key = selectedKeys[0]
                                        // console.log(key);
                                        if (selectedKeys.length != 0) {
                                            if (selectedKeys[0] != '全部') {
                                                let keyList = selectedKeys[0].split('-')
                                                // console.log(keyList);
                                                let keys = []
                                                keyList.map(item => {
                                                    if (keys[keys.length - 1] == undefined) {
                                                        keys.push(item)
                                                    } else {
                                                        keys.push(keys[keys.length - 1] + '-' + item)

                                                    }
                                                })
                                                // 处理第一个
                                                let obj1 = dataList1
                                                let objList1 = []
                                                keys.map((item, index) => {
                                                    // console.log(obj);
                                                    obj1.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj1 = i
                                                                objList1.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj1 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj1 = i
                                                                objList1.push(obj1)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData1(objList1);
                                                // 处理第二个
                                                let obj2 = dataList2
                                                let objList2 = []
                                                keys.map((item, index) => {
                                                    obj2.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj2 = i
                                                                objList2.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj2 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj2 = i
                                                                objList2.push(obj2)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData2(objList2);
                                                // 处理第三个
                                                let obj3 = dataList3
                                                let objList3 = []
                                                keys.map((item, index) => {
                                                    obj3.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj3 = i
                                                                objList3.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj3 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj3 = i
                                                                objList3.push(obj3)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData3(objList3);
                                                // 处理第四个
                                                let obj4 = dataList4
                                                let objList4 = []
                                                keys.map((item, index) => {
                                                    obj4.map(i => {
                                                        if (index != keys.length - 1) {
                                                            if (i.key == selectedKeys) {
                                                                obj4 = i
                                                                objList4.push(obj1)
                                                            } else if (i.key == item) {
                                                                // console.log("要处理的数据:",i);
                                                                obj4 = i.children
                                                            }
                                                        } else {
                                                            if (i.key == selectedKeys) {
                                                                obj4 = i
                                                                objList4.push(obj4)
                                                            }
                                                        }

                                                    })
                                                })
                                                setData4(objList4);
                                            } else {
                                                let obj1 = [...dataList1]
                                                setData1(obj1)
                                                let obj2 = [...dataList2]
                                                setData2(obj2)
                                                let obj3 = [...dataList3]
                                                setData3(obj3)
                                                let obj4 = [...dataList4]
                                                setData4(obj4)
                                            }
                                        }

                                    }}
                                    treeData={treeData4}
                                />
                            </div>
                        </div> : <span></span>
                }

            </div>

        </div>
    )

}

export default Index