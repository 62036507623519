import React, { useState, useEffect, useRef } from 'react';
import { Image, Typography, Collapse, Radio, Checkbox, Button, Spin, Statistic, Drawer, Upload, message, Form, DatePicker, Table, Space, Tag } from 'antd';
import PlatformEventsCalendar from '../../component/platformEventsCalendar'
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl';
import webUrl from '../../config/webUrl';
import AcceptBuried from '../../config/utils/buried';
const { Title, Text, Paragraph } = Typography
const { Panel } = Collapse;
const { CheckableTag } = Tag
function Index(props) {
    //数组中删除指定一项数据
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    //表格
    const tableRef1 = useRef(null)
    const tableRef2 = useRef(null)
    const [elRefs] = useState([tableRef1, tableRef2])
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'qda': '2715f3fa-0dae-4dc7-bcfc-0dd912314e40',
        'gs':'703247c1-a200-42bc-b70f-742dbab27aa4'
    }
    // 图例
    const [legend, setLegend] = useState([])
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 折叠框key
    const [collapseKey, setCollapseKey] = useState(false)
    // 单选款key
    const [radioKey, setRadioKey] = useState('访客数')
    // 维度筛选
    const [filterDimension, setFilterDimension] = useState({
        'product_line': [],
        'year': [],
        'season': [],
        'category': [],
        'price': [],
        'season_status': [],
        'band_rank_of_pay_money': []
    })
    // 数据
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    // 加载
    const [oneLoading, setOneLoading] = useState(false)
    const [twoLoading, setTwoLoading] = useState(false)
    // 表头
    const [columns, setColumns] = useState([])
    const [columnsList] = useState(['货号', '主图', '品类', '产品线', '季节', ])
    // 维度
    const [ dimensionList] = useState(['访客数', '收藏人数', '加购件数', '加购率', '支付件数', '支付金额', '件单价', '支付转化率'])
    const [ chooseDimension, setChooseDimension ] = useState([])
    // 对比开始时间1
    const [oneStartTime, setOneStartTime] = useState('')
    // 对比结束时间1
    const [oneEndTime, setOneEndTime] = useState('')
    // 对比开始时间2
    const [twoStartTime, setTwoStartTime] = useState('')
    // 对比结束时间2
    const [twoEndTime, setTwoEndTime] = useState('')
    // 产品线
    const [productLineSelectedTags, setProductLineSelectedTags] = useState([])
    const [productLineSelectedKey, setProductLineSelectedKey] = useState(false)
    //年份
    const [yearSelectedTags, setYearSelectedTags] = useState([])
    const [yearSelectedKey, setYearSelectedKey] = useState(false)
    const [yearSelectedTags1, setYearSelectedTags1] = useState([])
    const [yearSelectedKey1, setYearSelectedKey1] = useState(false)
    const [yearSelectedTags2, setYearSelectedTags2] = useState([])
    const [yearSelectedKey2, setYearSelectedKey2] = useState(false)
    // 季节
    const [seasonSelectedTags, setSeasonSelectedTags] = useState([])
    const [seasonSelectedKey, setSeasonSelectedKey] = useState(false)
    // 品类
    const [categorySelectedTags, setCategorySelectedTags] = useState([])
    const [categorySelectedKey, setCategorySelectedKey] = useState(false)
    // 价格带
    const [priceSelectedTags, setPriceSelectedTags] = useState([])
    const [priceSelectedKey, setPriceSelectedKey] = useState(false)
    // 排序字段
    const [orderList] = useState(['访客数', '收藏人数', '加购件数', '支付件数', '支付金额'])
    useEffect(() => {
        AcceptBuried('page_views',"view",{"page_type":"初始化页面"},props.location.pathname,"商品TOP榜")
        setCollapseKey(true)
        getCommodityOperationTableFieldData()
    }, [])

    useEffect(()=>{
        setColumns([
            {
                title: 'TOP',
                align: 'center',
                dataIndex: 'TOP',
                key: 'TOP',
                width: '50px',
                fixed: 'left',
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                render: (text, record, index) => (
                    <div>
                        <text>{index + 1}</text>
                    </div>
                )
            },
            ...columnsList.map(item => {
                return {
                    title: item,
                    align: 'center',
                    dataIndex: item == '货号' ? "款号_x" : item + '_x',
                    key: item == '货号' ? "款号_x" : item + '_x',
                    width: item == "季节" ? "40px" : item == '主图' ? '30px': item == "款号"  ? "80px" : '60px',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    backgroundColor:'#fa897b',
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div>
                            {
                                item == '主图' ? 
                                <a href={`https://detail.tmall.com/item.htm?id=${text}`} target='_blank'>
                                    <img src={`https://pic.bmcsoft.cn/it/${props.history.location.pathname.split('/')[2] == 'JORYA' ? props.history.location.pathname.split('/')[2] : props.history.location.pathname.split('/')[2].toLowerCase()}/${record['主图_x']}.jpg`} style={{ width: '30px', height:  '30px' }} /> 
                                </a>
                                : item == '货号' ? <a href={`${webUrl}${`/app/${props.history.location.pathname.split('/')[2]}/base/skuAnaysis`}?goodId=${record['主图_x']}`} target="_blank">{text}</a>
                                : <text>{text}</text>
                            }
                        </div>
                    )
                }
            }),
            ...chooseDimension.map(item=>{
                return {
                    title: item,
                    align: 'center',
                    dataIndex: item + '_x',
                    key: item + '_x',
                    width:'60px',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    backgroundColor:'#fa897b',
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    }
                }
            }),
            ...chooseDimension.map(item=>{
                return {
                    title: item + '对比',
                    align: 'center',
                    dataIndex: item + '_y',
                    key: item + '_y',
                    width:'60px',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    backgroundColor:'#ffdd94',
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div>
                            {
                                record[item + '_x'] == '-' || record[item + '_y'] == '-' ? '-':((parseFloat(record[item + '_x']) - parseFloat(record[item + '_y']))/ parseFloat(record[item + '_y']) * 100).toFixed(2).toString()+'%'
                            }
                        </div>
                    )
                }
            }),
            ...columnsList.map(item => {
                return {
                    title: item,
                    align: 'center',
                    dataIndex: item == '货号' ? "款号_y" : item + '_y',
                    key: item == '货号' ? "款号_y" : item + '_y',
                    width: item == "季节" ? "40px" : item == '主图' ? '30px': item == "款号" ? "80px" : '60px',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    backgroundColor:'#75CCE8',
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <div>
                            {
                                item == '主图' ? 
                                <a href={`https://detail.tmall.com/item.htm?id=${text}`} target='_blank'>
                                    <img src={`https://pic.bmcsoft.cn/it/${props.history.location.pathname.split('/')[2] == 'JORYA' ? props.history.location.pathname.split('/')[2] : props.history.location.pathname.split('/')[2].toLowerCase()}/${record['主图_y']}.jpg`} style={{ width: '30px', height:  '30px' }} /> 
                                </a>
                                : item == '货号' ? <a href={`${webUrl}${`/app/${props.history.location.pathname.split('/')[2]}/base/skuAnaysis`}?goodId=${record['主图_y']}`} target="_blank">{text}</a>
                                : <text>{text}</text>
                            }
                        </div>
                    )
                }
            }),
            ...chooseDimension.map(item=>{
                return {
                    title: item,
                    align: 'center',
                    dataIndex: item + '_y',
                    key: item + '_y',
                    width:'60px',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    backgroundColor:'#75CCE8',
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    }
                }
            }),
        ])
    },[chooseDimension])

    useEffect(() => {
        if (productLineSelectedTags.length == filterDimension['product_line'].length && filterDimension['product_line'].length != 0) {
            setProductLineSelectedKey(true)
        } else {
            setProductLineSelectedKey(false)
        }
    }, [productLineSelectedTags])

    useEffect(() => {
        if (yearSelectedTags.length == filterDimension['year'].length && filterDimension['year'].length != 0) {
            setYearSelectedKey(true)
        } else {
            setYearSelectedKey(false)
        }
    }, [yearSelectedTags])

    useEffect(() => {
        if (seasonSelectedTags.length == filterDimension['season'].length && filterDimension['season'].length != 0) {
            setSeasonSelectedKey(true)
        } else {
            setSeasonSelectedKey(false)
        }
    }, [seasonSelectedTags])

    useEffect(() => {
        if (categorySelectedTags.length == filterDimension['category'].length && filterDimension['category'].length != 0) {
            setCategorySelectedKey(true)
        } else {
            setCategorySelectedKey(false)
        }
    }, [categorySelectedTags])

    useEffect(() => {
        if (priceSelectedTags.length == filterDimension['price'].length && filterDimension['price'].length != 0) {
            setPriceSelectedKey(true)
        } else {
            setPriceSelectedKey(false)
        }
    }, [priceSelectedTags])

    // 产品线
    const productLineHandleChange = (tag, checked) => {
        let selectedTag = [...productLineSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setProductLineSelectedTags(nextSelectedTags)
    }
    // 年份
    const yearHandleChange = (tag, checked) => {
        let selectedTag = [...yearSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setYearSelectedTags(nextSelectedTags)
    }
    const yearHandleChange1 = (tag, checked) => {
        let selectedTag = [...yearSelectedTags1]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setYearSelectedTags1(nextSelectedTags)
    }
    const yearHandleChange2 = (tag, checked) => {
        let selectedTag = [...yearSelectedTags2]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setYearSelectedTags2(nextSelectedTags)
    }
    // 季节
    const seasonHandleChange = (tag, checked) => {
        let selectedTag = [...seasonSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setSeasonSelectedTags(nextSelectedTags)
    }
    // 品类
    const categoryHandleChange = (tag, checked) => {
        let selectedTag = [...categorySelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setCategorySelectedTags(nextSelectedTags)
    }
    // 品类
    const priceHandleChange = (tag, checked) => {
        let selectedTag = [...priceSelectedTags]
        const nextSelectedTags = checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag);
        setPriceSelectedTags(nextSelectedTags)
    }

    // 获取维度数据
    const getCommodityOperationTableFieldData = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dataProps, "POST", servicePath.getCommodityRankFieldData).then(res => {
            if (res.success) {
                setFilterDimension(res.data);
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }
    // 重置
    const reset = () => {
        setRadioKey('访客数')
        setOneStartTime('')
        setOneEndTime('')
        setTwoStartTime('')
        setTwoEndTime('')
        setOneStartTime('')
        setProductLineSelectedKey(false)
        setProductLineSelectedTags([])
        // setYearSelectedKey(false)
        // setYearSelectedTags([])
        setYearSelectedKey1(false)
        setYearSelectedTags1([])
        setYearSelectedKey2(false)
        setYearSelectedTags2([])
        setSeasonSelectedKey(false)
        setSeasonSelectedTags([])
        setCategorySelectedKey(false)
        setCategorySelectedTags([])
        setPriceSelectedKey(false)
        setPriceSelectedTags([])
    }

    // 获取第一组数据
    const getOneData = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "product_line": productLineSelectedTags,
            "year": yearSelectedTags,
            "season": seasonSelectedTags,
            "category": categorySelectedTags,
            "price": priceSelectedTags,
            "rank_field": radioKey,
            "start_date": oneStartTime,
            "end_date": oneEndTime,
        }
        setOneLoading(true)
        NbAxios(dataProps, "POST", servicePath.getCommodityRankData).then(res => {
            setOneLoading(false)
            if (res.success) {
                setData1(res.data.data);
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            // 获取500错误
            setOneLoading(false)
            message.warn('获取第一组数据报错，请联系IT帮忙解决')
        })
    }
    // 获取第二组数据
    const getTwoData = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "product_line": productLineSelectedTags,
            "year": yearSelectedTags,
            "season": seasonSelectedTags,
            "category": categorySelectedTags,
            "price": priceSelectedTags,
            "rank_field": radioKey,
            "start_date": twoStartTime,
            "end_date": twoEndTime,
        }
        setTwoLoading(true)
        NbAxios(dataProps, "POST", servicePath.getCommodityRankData).then(res => {
            setTwoLoading(false)
            if (res.success) {
                setData2(res.data.data);
                setCollapseKey(false);
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            // 获取500错误
            setTwoLoading(false)
            message.warn('获取第二组数据报错，请联系IT帮忙解决')
        })
    }

    // 获取数据
    const getCommodityRankFieldData = () => {
        if (oneStartTime != '' && oneEndTime != '' && twoStartTime != '' && twoEndTime != '') {
            let dataProps = {
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                "product_line": productLineSelectedTags,
                "year": yearSelectedTags,
                "season": seasonSelectedTags,
                "category": categorySelectedTags,
                "price": priceSelectedTags,
                "rank_field": radioKey,
                "start_date1": oneStartTime,
                "end_date1": oneEndTime,
                "start_date2": twoStartTime,
                "end_date2": twoEndTime,
                "year1": yearSelectedTags1,
                "year2": yearSelectedTags2,
            }
            console.log(dataProps)
            let buriedProps = {
                "product_line": productLineSelectedTags.toString(),
                "year": yearSelectedTags.toString(),
                "season": seasonSelectedTags.toString(),
                "category": categorySelectedTags.toString(),
                "price": priceSelectedTags.toString(),
                "rank_field": radioKey,
                "start_date1": oneStartTime,
                "end_date1": oneEndTime,
                "start_date2": twoStartTime,
                "end_date2": twoEndTime,
            }
            AcceptBuried('query_top_commodity_dimension',"click",buriedProps,props.location.pathname,"商品TOP榜")
            setTwoLoading(true)
            NbAxios(dataProps, "POST", servicePath.getCommodityRankData).then(res => {
                setTwoLoading(false)
                if (res.success) {
                    setData1(res.data.data);
                    setLegend([oneStartTime.replaceAll('-','.') + '——' + oneEndTime.replaceAll('-','.'),'对比' ,twoStartTime.replaceAll('-','.')  + '——' + twoEndTime.replaceAll('-','.') ])
                    setCollapseKey(false);
                } else {
                    message.warn({ content: res.errorMsg })
                }
            }).catch((err) => {
                // 获取500错误
                setTwoLoading(false)
                message.warn('获取第二组数据报错，请联系IT帮忙解决')
            })
        } else {
            message.warn('请选择时间维度1、2')
        }

    }

    // 联动主函数
    const handleOnScrollCapture = (e, index) => {
        if (elRefs[index]?.current) {
            const scrolledTable = elRefs[index]?.current.querySelector(".ant-table-body");
            elRefs.forEach((el, i) => {
                if (i !== index) {
                    const otherTable = el.current.querySelector(".ant-table-body");
                    otherTable.scrollTop = scrolledTable.scrollTop;
                    otherTable.scrollLeft = scrolledTable.scrollLeft;
                }
            })
        }
    }

    return (
        <div style={{paddingBottom:"100px"}}>
            <Spin tip={`数据请求中....`} spinning={effective ? true : false} size="large" style={{ marginTop: '30vh' }}>
                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 10, borderBottom: "1px solid #ccc" }}>
                    <Title level={4}>商品TOP榜</Title>
                    <div>
                        {/* <a href="https://pic.bmcsoft.cn/imge_dir/010414474101/010414474101.html" target="_Blank"><Button type="link">操作手册</Button></a> */}
                    </div>
                </div>
                {/* <div style={{ width: "98vw", margin: "0 auto" }}>
                    <Collapse activeKey={collapseKey} onChange={(key) => {
                        setCollapseKey(key);
                    }}>
                        <Panel header="查询维度" key="1" >
                            <div style={{ display: 'flex' }}>
                                <b style={{ marginRight: 10, marginTop: 5, fontSize: 15 }}>时间维度1:</b>
                                <div style={{ marginTop: 5 }}>
                                    <PlatformEventsCalendar startDate={oneStartTime} setStartDate={setOneStartTime} endDate={oneEndTime} setEndDate={setOneEndTime} />
                                </div>
                                <b style={{ marginRight: 10, marginTop: 5, fontSize: 15, marginLeft: 20 }}>时间维度2:</b>
                                <div style={{ marginTop: 5 }}>
                                    <PlatformEventsCalendar startDate={twoStartTime} setStartDate={setTwoStartTime} endDate={twoEndTime} setEndDate={setTwoEndTime} />
                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10 }}>产品线:</b>
                                <Checkbox checked={productLineSelectedKey} onChange={(e) => {
                                    if (e.target.checked) {
                                        setProductLineSelectedKey(true)
                                        setProductLineSelectedTags(filterDimension['product_line'])
                                    } else {
                                        setProductLineSelectedKey(false)
                                        setProductLineSelectedTags([])
                                    }
                                }}>全部</Checkbox>
                                {
                                    filterDimension['product_line'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                            key={item}
                                            checked={productLineSelectedTags.indexOf(item) > -1}
                                            onChange={checked => productLineHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10 }}>年份:</b>
                                <Checkbox checked={yearSelectedKey} onChange={(e) => {
                                    if (e.target.checked) {
                                        setYearSelectedKey(true)
                                        setYearSelectedTags(filterDimension['year'])
                                    } else {
                                        setYearSelectedKey(false)
                                        setYearSelectedTags([])
                                    }
                                }}>全部</Checkbox>
                                {
                                    filterDimension['year'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                            key={item}
                                            checked={yearSelectedTags.indexOf(item) > -1}
                                            onChange={checked => yearHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10 }}>季节:</b>
                                <Checkbox checked={seasonSelectedKey} onChange={(e) => {
                                    if (e.target.checked) {
                                        setSeasonSelectedKey(true)
                                        setSeasonSelectedTags(filterDimension['season'])
                                    } else {
                                        setSeasonSelectedKey(false)
                                        setSeasonSelectedTags([])
                                    }
                                }}>全部</Checkbox>
                                {
                                    filterDimension['season'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                            key={item}
                                            checked={seasonSelectedTags.indexOf(item) > -1}
                                            onChange={checked => seasonHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                            <div style={{ display: "grid", marginTop: 10, gridTemplateColumns: '74px 60px 1fr' }}>
                                <b style={{ display: 'block', marginRight: 10 }} >品类:</b>
                                <div>
                                    <Checkbox checked={categorySelectedKey} onChange={(e) => {
                                        if (e.target.checked) {
                                            setCategorySelectedKey(true)
                                            setCategorySelectedTags(filterDimension['category'])
                                        } else {
                                            setCategorySelectedKey(false)
                                            setCategorySelectedTags([])
                                        }
                                    }}>全部</Checkbox>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                    {
                                        filterDimension['category'].map(item => {
                                            return <CheckableTag
                                                style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                                key={item}
                                                checked={categorySelectedTags.indexOf(item) > -1}
                                                onChange={checked => categoryHandleChange(item, checked)}
                                            >
                                                {item}
                                            </CheckableTag>
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{ display:"flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10 }}>价格带:</b>
                                <Checkbox checked={priceSelectedKey} onChange={(e) => {
                                    if (e.target.checked) {
                                        setPriceSelectedKey(true)
                                        setPriceSelectedTags(filterDimension['price'])
                                    } else {
                                        setPriceSelectedKey(false)
                                        setPriceSelectedTags([])
                                    }
                                }}>全部</Checkbox>
                                {
                                    filterDimension['price'].map(item => {
                                        return <CheckableTag
                                            style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                            key={item}
                                            checked={priceSelectedTags.indexOf(item) > -1}
                                            onChange={checked => priceHandleChange(item, checked)}
                                        >
                                            {item}
                                        </CheckableTag>
                                    })
                                }
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <b style={{ width: 65, display: 'block', marginRight: 10, marginTop: 10 }}>排序规则:</b>
                                <Radio.Group style={{ marginTop: 7 }} onChange={(e) => {
                                    setRadioKey(e.target.value)
                                }} value={radioKey}>
                                    {
                                        orderList.map(item=>{
                                            return <Radio value={item}>{item}</Radio>
                                        })
                                    }
                                </Radio.Group>
                            </div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <Button disabled={twoLoading} type="primary" style={{marginRight:'20px'}} onClick={()=>getCommodityRankFieldData()}>查询</Button>
                                <Button disabled={twoLoading} type="primary" danger onClick={()=>reset()}>重置</Button>
                            </div>
                        </Panel>
                    </Collapse>
                </div> */}
                <div style={{marginLeft: "1vw", marginRight:"1vw", display:'flex', justifyContent:"flex-end"}}>
                    <Button type="primary" size="small" onClick={()=>setCollapseKey(true)}>条件查询</Button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', height:'7px' }}>
                    <div style={{display:'flex', marginLeft: "1vw"}}>
                        <Text strong style={{ fontSize: 16, marginRight: 10 }}>图例:</Text>
                        {
                            legend.map((item, index) => {
                                return <div style={{ display: "flex" }}>
                                    <div style={{ width: 30, height: 15, marginTop: 5, backgroundColor: index == 0 ? '#fa897b ' : index == 1 ? '#ffdd94' : '#75CCE8' }}></div>
                                    <Text strong style={{ marginLeft: 5, marginRight: 10 }}>{item}</Text>
                                </div>
                            })
                        }
                    </div>
                    <div>
                        {
                            dimensionList.map(item=>{
                                return <Checkbox checked={chooseDimension.indexOf(item) > -1} onChange={(e) => {
                                    if (e.target.checked) {
                                        let buriedProps = {
                                            "filed_name": item,
                                            "is_checked": true
                                        }
                                        AcceptBuried('selected_field',"click",buriedProps,props.location.pathname,"商品TOP榜")
                                        let obj = [...chooseDimension]
                                        obj.push(item)
                                        setChooseDimension(obj)
                                    } else {
                                        let buriedProps = {
                                            "filed_name": item,
                                            "is_checked": false
                                        }
                                        AcceptBuried('selected_field',"click",buriedProps,props.location.pathname,"商品TOP榜")
                                        let obj = [...chooseDimension]
                                        obj.remove(item)
                                        setChooseDimension(obj)
                                    }
                                }}>{item}</Checkbox>
                            })
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 20 }}>
                    <div style={{ width: "98vw" }} onScrollCapture={(e) => handleOnScrollCapture(e, 0)}>
                        <Table loading={twoLoading} dataSource={data1} columns={columns} bordered={true} scroll={{ y: 'calc(98vh - 150px)', x: 1000 }} pagination={false} />
                    </div>
                </div>
            </Spin>
            <Drawer
                title="条件查询"
                visible={collapseKey}
                onClose={()=>setCollapseKey(false)}
                width={1200}
            >
                <div style={{ display: 'flex' }}>
                    <b style={{ marginRight: 10, marginTop: 5, fontSize: 15 }}>时间维度1:</b>
                    <div style={{ marginTop: 5 }}>
                        <PlatformEventsCalendar startDate={oneStartTime} setStartDate={setOneStartTime} endDate={oneEndTime} setEndDate={setOneEndTime} />
                    </div>
                    <b style={{ marginRight: 10, marginTop: 5, fontSize: 15, marginLeft: 20 }}>时间维度2:</b>
                    <div style={{ marginTop: 5 }}>
                        <PlatformEventsCalendar startDate={twoStartTime} setStartDate={setTwoStartTime} endDate={twoEndTime} setEndDate={setTwoEndTime} />
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                    <b style={{ width: 65, display: 'block', marginRight: 10 }}>年份1:</b>
                    <Checkbox checked={yearSelectedKey1} onChange={(e) => {
                        if (e.target.checked) {
                            setYearSelectedKey1(true)
                            setYearSelectedTags1(filterDimension['year'])
                        } else {
                            setYearSelectedKey1(false)
                            setYearSelectedTags1([])
                        }
                    }}>全部</Checkbox>
                    {
                        filterDimension['year'].map(item => {
                            return <CheckableTag
                                style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                key={item}
                                checked={yearSelectedTags1.indexOf(item) > -1}
                                onChange={checked => yearHandleChange1(item, checked)}
                            >
                                {item}
                            </CheckableTag>
                        })
                    }
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                    <b style={{ width: 65, display: 'block', marginRight: 10 }}>年份2:</b>
                    <Checkbox checked={yearSelectedKey2} onChange={(e) => {
                        if (e.target.checked) {
                            setYearSelectedKey2(true)
                            setYearSelectedTags2(filterDimension['year'])
                        } else {
                            setYearSelectedKey2(false)
                            setYearSelectedTags2([])
                        }
                    }}>全部</Checkbox>
                    {
                        filterDimension['year'].map(item => {
                            return <CheckableTag
                                style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                key={item}
                                checked={yearSelectedTags2.indexOf(item) > -1}
                                onChange={checked => yearHandleChange2(item, checked)}
                            >
                                {item}
                            </CheckableTag>
                        })
                    }
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                    <b style={{ width: 65, display: 'block', marginRight: 10 }}>产品线:</b>
                    <Checkbox checked={productLineSelectedKey} onChange={(e) => {
                        if (e.target.checked) {
                            setProductLineSelectedKey(true)
                            setProductLineSelectedTags(filterDimension['product_line'])
                        } else {
                            setProductLineSelectedKey(false)
                            setProductLineSelectedTags([])
                        }
                    }}>全部</Checkbox>
                    {
                        filterDimension['product_line'].map(item => {
                            return <CheckableTag
                                style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                key={item}
                                checked={productLineSelectedTags.indexOf(item) > -1}
                                onChange={checked => productLineHandleChange(item, checked)}
                            >
                                {item}
                            </CheckableTag>
                        })
                    }
                </div>
                {/* <div style={{ display: "flex", marginTop: 10 }}>
                    <b style={{ width: 65, display: 'block', marginRight: 10 }}>年份:</b>
                    <Checkbox checked={yearSelectedKey} onChange={(e) => {
                        if (e.target.checked) {
                            setYearSelectedKey(true)
                            setYearSelectedTags(filterDimension['year'])
                        } else {
                            setYearSelectedKey(false)
                            setYearSelectedTags([])
                        }
                    }}>全部</Checkbox>
                    {
                        filterDimension['year'].map(item => {
                            return <CheckableTag
                                style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                key={item}
                                checked={yearSelectedTags.indexOf(item) > -1}
                                onChange={checked => yearHandleChange(item, checked)}
                            >
                                {item}
                            </CheckableTag>
                        })
                    }
                </div> */}
                <div style={{ display: "flex", marginTop: 10 }}>
                    <b style={{ width: 65, display: 'block', marginRight: 10 }}>季节:</b>
                    <Checkbox checked={seasonSelectedKey} onChange={(e) => {
                        if (e.target.checked) {
                            setSeasonSelectedKey(true)
                            setSeasonSelectedTags(filterDimension['season'])
                        } else {
                            setSeasonSelectedKey(false)
                            setSeasonSelectedTags([])
                        }
                    }}>全部</Checkbox>
                    {
                        filterDimension['season'].map(item => {
                            return <CheckableTag
                                style={{ border: "1px solid #ccc", width: 50, textAlign: 'center' }}
                                key={item}
                                checked={seasonSelectedTags.indexOf(item) > -1}
                                onChange={checked => seasonHandleChange(item, checked)}
                            >
                                {item}
                            </CheckableTag>
                        })
                    }
                </div>
                <div style={{ display: "grid", marginTop: 10, gridTemplateColumns: '74px 60px 1fr' }}>
                    <b style={{ display: 'block', marginRight: 10 }} >品类:</b>
                    <div>
                        <Checkbox checked={categorySelectedKey} onChange={(e) => {
                            if (e.target.checked) {
                                setCategorySelectedKey(true)
                                setCategorySelectedTags(filterDimension['category'])
                            } else {
                                setCategorySelectedKey(false)
                                setCategorySelectedTags([])
                            }
                        }}>全部</Checkbox>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        {
                            filterDimension['category'].map(item => {
                                return <CheckableTag
                                    style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                    key={item}
                                    checked={categorySelectedTags.indexOf(item) > -1}
                                    onChange={checked => categoryHandleChange(item, checked)}
                                >
                                    {item}
                                </CheckableTag>
                            })
                        }
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                    <b style={{ width: 65, display: 'block', marginRight: 10 }}>价格带:</b>
                    <Checkbox checked={priceSelectedKey} onChange={(e) => {
                        if (e.target.checked) {
                            setPriceSelectedKey(true)
                            setPriceSelectedTags(filterDimension['price'])
                        } else {
                            setPriceSelectedKey(false)
                            setPriceSelectedTags([])
                        }
                    }}>全部</Checkbox>
                    {
                        filterDimension['price'].map(item => {
                            return <CheckableTag
                                style={{ border: "1px solid #ccc", textAlign: 'center' }}
                                key={item}
                                checked={priceSelectedTags.indexOf(item) > -1}
                                onChange={checked => priceHandleChange(item, checked)}
                            >
                                {item}
                            </CheckableTag>
                        })
                    }
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                    <b style={{ width: 65, display: 'block', marginRight: 10, marginTop: 10 }}>排序规则:</b>
                    <Radio.Group style={{ marginTop: 7 }} onChange={(e) => {
                        setRadioKey(e.target.value)
                    }} value={radioKey}>
                        {
                            orderList.map(item => {
                                return <Radio value={item}>{item}</Radio>
                            })
                        }
                    </Radio.Group>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button disabled={twoLoading} type="primary" style={{ marginRight: '20px' }} onClick={() => getCommodityRankFieldData()}>查询</Button>
                    <Button disabled={twoLoading} type="primary" danger onClick={() => {
                        AcceptBuried('reset',"click",{},props.location.pathname,"商品TOP榜")
                        reset()
                    }}>重置</Button>
                </div>
            </Drawer>
        </div>
    )

}


export default Index