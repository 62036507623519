import React, { useState, useEffect, useCallback } from 'react';
import { Tabs } from 'antd'
import Kol from './search/kol'
import KolTrench from './search/kolTrench'
const { TabPane } = Tabs;

function Retrieval(props) {

    // 当前tab值
    const [ curTabKey , setCurTabKey ] = useState('1')

    // 切换tab
    const changeTabs = (activeKey)=>{
        setCurTabKey(activeKey)
    }
  
   
    return(
        <>
             <Tabs style={{marginLeft:'10px'}} defaultActiveKey={curTabKey} tabPosition="left" onChange={changeTabs}>
                <TabPane tab="KOL推广检索" key="1">
                    {curTabKey ==='1' ? <Kol history={props.history}/>: <div></div> } 
                </TabPane>
                <TabPane tab="KOL渠道检索" key="2">
                    {curTabKey ==='2' ? <KolTrench history={props.history}/>: <div></div> } 
                </TabPane>
            </Tabs>
        </>
    )

}

export default Retrieval