import React, { useEffect, useState, useContext, useRef } from 'react';
import { Col, Row, Spin, Tabs, Typography, Pagination, Drawer, Form, Tag, Tooltip, Radio, Button, Modal, Upload, message, Input, Select, Cascader, DatePicker, InputNumber, Descriptions, PageHeader } from 'antd';
import { LoadingOutlined, createFromIconfontCN, UploadOutlined, PlusOutlined, FormOutlined, LeftOutlined, AlignLeftOutlined} from '@ant-design/icons';
import './style.css'
import servicePath from '../../../config/apiUrl'
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios'
import moment from 'moment'
import { TweenOneGroup } from 'rc-tween-one';
import { StateContext } from '../../raceShop/store/stateShare'
import locale from 'antd/es/date-picker/locale/zh_CN';
import Debounce from '../../../config/utils/debounce'
import CursorItem from '../components/CursorItem'
const { TabPane } = Tabs;
const { TextArea, Search } = Input;
const { Text, Paragraph, Title } = Typography
const { CheckableTag } = Tag;
const { Option } = Select
const { RangePicker } = DatePicker;
function OrderCursor(props) {


    // 上传文件按钮
    const uploadButton = (
        <Button>
            <UploadOutlined /> 文件
        </Button>
    );
    // 标签
    const forMap = tag => {
        let tagElem = (
            <Tag
                closable
                onClose={e => {
                    e.preventDefault();
                    handleClose(tag);
                }}
            >
                {tag}
            </Tag>
        );
        return (
            <span key={tag} style={{ display: 'inline-block' }}>
                {tagElem}
            </span>
        );
    };
    //标签输入框
    const inputRef = useRef(null)
    // 十种颜色随机
    const color = [
        // 蓝色
        '#495aff',
        // 灰色
        '#323232',
        // 靛青色
        '#2b5876',
        // 淡紫色
        '#4e4376',
        // 亮粉色
        '#ff758c',
        // 淡粉色
        '#ff7eb3',
        // 紫色
        '#b721ff',
        // 哑光蓝
        'rgb(68, 129, 235)',
        // 亮蓝色
        'rgb(4, 190, 254)',
        '#9890e3'
    ]
    // 选择颜色
    const [selectColor, setColor] = useState(color[0])
    const [form1] = Form.useForm()
    const [form2] = Form.useForm()
    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
    }
    const layout2 = {
        labelCol: { span: 3 },
        wrapperCol: { span: 18 },
    }

    // 课程标签
    const [cursorTags, setCursorTags] = useState([])
    // 标签是否输入
    const [tagsInputVisible, setTagsIpueVisible] = useState(false)
    // 标签值
    const [tagsInputValue, setTagsInputValue] = useState('')
    // 标签
    const [tagChild, setTagChild] = useState([])
    // 分类选择
    const [classificationOption, setClassificationOption] = useState([])
    // 时间条件查询
    const tagsTimeData = ['全部时长', '1小时以下', '1-2小时', '2-3小时'];
    // 选择tag
    const [selectedTimeTags, setSelectedTimeTags] = useState('全部时长')
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    // 所有课程
    const [allCursorList, setAllCursorList] = useState([])
    // 当前课程
    const [cursorList, setCursorList] = useState([])
    // 创建一个icon对象
    const IconFont = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_2312382_kq2jcqxr74s.js',
    });
    const BtnIconFont = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_2314025_he5fueddz3j.js',
    });
    // 标签颜色
    const tagsColor = [
        'magenta',
        'orange',
        'geekblue',
        'purple',
        'lime'
    ]
    // 数据总数
    const [total, setTotal] = useState(1000)
    // 文档列表
    const [docList, setDocList] = useState([])
    // 当前页码
    const [page, setPage] = useState(1)
    // 公司人员
    const [allPeople, setAllPeople] = useState([])
    // 引领者       
    const [leaderList, setLeaderList] = useState([])
    // 跟随者
    const [followerList, setFollowerList] = useState([])
    // 搜索条件
    const [search, setSearch] = useState('')
    // 标签
    const [tabKey, setTabKey] = useState(0)
    // 课程id
    const [cursorId, setCursorId] = useState('')
    // 课程名称
    const [cursorName, setCursorName] = useState('')
    // 课程分类
    const [cursorClassification, setCursorClassification] = useState([])
    // 课程分类字符串
    const [cursorClassificationStr, setCursorClassificationStr] = useState('')
    // 课程形式
    const [cursorForm, setCursorForm] = useState(1)
    // 是否可以旁观
    const [isLookOn, setIsLookOn] = useState(0)
    // 最小人数
    const [minPeople, setMinPeople] = useState(0)
    // 最大人数
    const [maxPeople, setMaxPeople] = useState(0)
    // 简介
    const [cursorIntroduction, setCursorIntroduction] = useState('')
    // 报名开始时间
    const [cursorSignUpStartTime, setCursorSignUPStartTime] = useState(moment())
    // 报名结束时间
    const [cursorSignUpEndTime, setCursorSignUPEndTime] = useState(moment())
    // 反馈时间
    const [cursorFeedbackTime, setCursorFeedbackTime] = useState('')
    // 课程开始时间
    const [cursorStartTime, setCursorStartTime] = useState(moment())
    // 课时
    const [cursorClass, setCursorClass] = useState('')
    // 课程详情
    const [cursorDetails, setCursorDetails] = useState('')
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 是否加载
    const [commentLoading, setCommentLoading] = useState(false)
    // 是否加载
    const [materialLoading, setMaterialLoading] = useState(false)
    // 是否打开详情
    const [openDetail, setOpenDetail] = useState(false)
    // 是否添加评论
    const [addComment, setAddComment] = useState(false)
    // 是否创建课程
    const [addCursor, setAddCursor] = useState(false)
    // 是否发布课程
    const [releaseCursor, setReleaseCursor] = useState(false)
    // 预览
    const [preview, setPreview] = useState(false)
    // 课程类型
    const [cursorType, setCursorType] = useState(1)
    // 封面颜色
    const [cursorColor, setCursorColor] = useState('')

    const antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;
    // 课件标签
    const materialTab = (
        <div>
            <Spin indicator={antIcon} spinning={materialLoading}>
                课件
            </Spin>
        </div>
    )

    // 评论课件标签切换
    function detailTabChange(key) {

    }

    // 文档列表改变的时候出发
    const fileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'zip', 'rar', 'gif', 'jpg', 'jpeg', 'png', 'jpg', 'pptx', 'txt']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    setDocList(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                }
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setDocList(fileList)
        }
    }
    // 标签休息的时候
    useEffect(() => {
        let tagChilds = cursorTags.map(forMap)
        setTagChild(tagChilds)
    }, [cursorTags])

    // // 领导者修改
    // useEffect(() => {
    //     // 跟随者清空
    //     setFollowerList([])
    // }, [leaderList])

    // 课程类型修改的时候
    useEffect(()=>{
        if(cursorType == 1){
            // 领导者清空
            setLeaderList([])
        }else{
            let leaderOwnList = [cookie.load('name')]
            setLeaderList(leaderOwnList)
            
        }
     
    },[cursorType])

    // 初始化参数
    useEffect(() => {
        // 查询课程分类
        getCursorType()
        // 查询所有人
        getPMSysUser()
        // 查询课程基础信息集合
        getCursorBase()
    }, [])

    // 全部课程修改的时候
    useEffect(() => {
        // 初始化是当前课程的前十条数据
        let cursor = allCursorList.slice(0, 10)
        setCursorList(cursor)
        setTotal(allCursorList.length)
        setPage(1)
    }, [allCursorList])

    // 获取课程基础信息集合
    const getCursorBase = () => {
        let dataProps = {
            "q": cookie.load('id'),
            "creator_id": cookie.load('id'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryCursorBase).then(res => {
            if (res.success) {
                setAllCursorList(res.data)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 搜索课程
    const searchCursor = (key) => {
        let dataProps = {
            "q": key == '' ? cookie.load('id') : key,
            "creator_id": cookie.load('id'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryCursorBase).then(res => {
            if (res.success) {
                setAllCursorList(res.data)
            } else {
                message.error(res.errorMsg)
            }
        })
    }


    // 获取课程分类
    const getCursorType = () => {
        let dataProps = {
            "creator_id": cookie.load('id'),
            "type_name": "1",
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryCursorTypes).then(res => {
            if (res.success) {
                setClassificationOption(res.data.types_tree)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 获取公司所有人
    const getPMSysUser = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryPMSysUser).then(res => {
            if (res.success) {
                setAllPeople(res.data);
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    function pageChange(page, pageSize) {
        let cursor = allCursorList.slice((page - 1) * 10, 10 * page)
        setCursorList(cursor)
        setPage(page)
    }

    // 关闭查看详情
    const onCloseDetail = () => {
        form1.resetFields()
        setCursorClassificationStr('')
        setCursorName('')
        setCursorIntroduction('')
        setCursorId('')
        setOpenDetail(false)
    }

    // 确认发布
    const previewOk = () => {
        
        if (cursorColor == '') {
            message.warn('请选择颜色')
        } else if (cursorColor == '#ffffff') {
            message.warn('请选择其他颜色')
        } else if (cursorForm == '') {
            message.warn('课程形式不能为空')
        } else if (cursorSignUpStartTime == '' || cursorSignUpStartTime == '') {
            message.warn('报名开始时间/报名结束时间不能为空')
        } else if (cursorClass == '') {
            message.warn('课程时长不能为空')
        } else if (cursorFeedbackTime == '') {
            message.warn('反馈时长不能为空')
        }  else if (maxPeople == '' || minPeople == '') {
            message.warn('参与人数不能为空')
        } else if (cursorStartTime == '') {
            message.warn('开课时间不能为空')
        } else if (cursorStartTime < cursorSignUpEndTime) {
            message.warn('课程开始时间要晚于报名结束时间')
        } else if(cursorSignUpStartTime < moment().format('YYYY-MM-DD')){
            message.warn('报名开始时间必须晚于当前时间')
        }else if(cursorTags.length == 0){
            message.warn('标签不能为空')
        }else if(cursorDetails == ''){
            message.warn('课程详情不能为空')
        }else {
            setEffective(1)
            const formData = new FormData()
            docList.forEach((item) => {
                formData.append('file_list', item['originFileObj'])
            })
            // 课程信息id
            formData.append('course_id', cursorId)
            // 课程形式
            formData.append('form', cursorForm)
            // 课程封面
            formData.append('course_cover', cursorColor)
            // 课程种类
            formData.append('course_kind', cursorType)
            // 报名开始时间
            formData.append('registration_start_time', cursorSignUpStartTime)
            // 报名结束时间
            formData.append('registration_end_time', cursorSignUpEndTime)
            // 课程时长
            formData.append('course_duration', cursorClass)
            // 反馈时长
            formData.append('feedback_time', cursorFeedbackTime)
            // 开课时间
            formData.append('course_clock', cursorStartTime)
            // 参与人数上限
            formData.append('participant_ceiling', maxPeople)
            // 参与人数下限
            formData.append('participant_floor', minPeople)
            // 旁听许可
            formData.append('allow_audit', isLookOn)
            // 标签名字
            formData.append('tags_name_list', cursorTags)
            // 引领者数组
            formData.append('leader_list', leaderList)
            // 追随者数组
            formData.append('follower_list', followerList)
            // 操作人uuid
            formData.append('principal_uuid', cookie.load('id'))
            // 店铺id
            formData.append('shopId', cookie.load('shopList'))
            // 课程详情
            formData.append('course_details', cursorDetails)
            // 角色id
            formData.append('roleId', cookie.load('role'))
            NbAxios(formData, "POST", servicePath.createNsCoursesPublish).then(res => {
                setEffective(0)
                if (res.success) {
                    message.success('课程发布成功')
                    props.history.push({ 'pathname': '/app/cursor/myCursor' })
                    form1.resetFields()
                    setDocList([])
                    setCursorId('')
                    setCursorForm(1)
                    setCursorColor('')
                    setCursorType(1)
                    setCursorSignUPEndTime('')
                    setCursorSignUPStartTime('')
                    setCursorStartTime('')
                    setMaxPeople(0)
                    setMinPeople(0)
                    setIsLookOn(0)
                    setCursorClass('')
                    setCursorFeedbackTime('')
                    setCursorDetails('')
                    setCursorTags([])
                    setLeaderList([])
                    setFollowerList([])
                    setPreview(false)
                    setReleaseCursor(false)
                } else {
                    message.error(res.errorMsg)
                }
            })
        }
    }

    // 取消预览
    const previewCancel = () => {
        setPreview(false)
    }

    // 选择标签
    const changeTimeTags = (tag, checked) => {
        setSelectedTimeTags(tag)
    }

    const handleInputChange = e => {
        setTagsInputValue(e.target.value)
    };

    const showInput = () => {
        setTagsIpueVisible(true)
        setTimeout(function () { inputRef.current.focus(); }, 0);
    };

    // 确认添加课程
    const addCursorOk = () => {
        if (cursorName == '') {
            message.warn('课程名称不能为空')
        } else if (cursorClassification.length == 0) {
            message.warn('课程分类不能为空')
        } else {
            setEffective(1)
            let dataProps = {
                "name": cursorName,
                "introduction": cursorIntroduction,
                "creator_id": cookie.load('id'),
                "course_type": cursorClassification,
                "roleId": cookie.load('role'),
                "shopId": cookie.load('shopList'),
            }
            NbAxios(dataProps, "POST", servicePath.createCursorBase).then(res => {
                setEffective(0)
                if (res.success) {
                    message.success('成功创建课程')
                    setCursorName('')
                    setCursorIntroduction('')
                    setCursorClassification([])
                    form1.resetFields()
                    getCursorBase()
                    setAddCursor(false)
                } else {
                    message.error(res.errorMsg)
                }
            })
        }
    }

    // 取消添加课程
    const addCursorCancel = () => {
        message.success('取消创建课程')
        setCursorName('')
        setCursorIntroduction('')
        setCursorClassification([])
        form1.resetFields()
        setAddCursor(false)
    }

    // 确认发布课程
    const releaseCursorOk = () => {
        setPreview(true)
    }

    // 取消发布课程
    const releaseCursorCancel = () => {
        message.success('取消发布课程')
        form1.resetFields()
        setDocList([])
        setCursorId('')
        setCursorForm(1)
        setCursorColor('')
        setCursorType(1)
        setCursorSignUPEndTime('')
        setCursorSignUPStartTime('')
        setCursorStartTime('')
        setMaxPeople(0)
        setMinPeople(0)
        setIsLookOn(0)
        setCursorClass('')
        setCursorFeedbackTime('')
        setCursorDetails('')
        setCursorTags([])
        setLeaderList([])
        setFollowerList([])
        setPreview(false)
        setReleaseCursor(false)
    }


    const handleInputConfirm = () => {
        let tags
        if (tagsInputValue && cursorTags.indexOf(tagsInputValue) === -1) {
            tags = [...cursorTags, tagsInputValue];
        }
        if (tags != undefined) {
            setCursorTags(tags)
            setTagsInputValue('')
            setTagsIpueVisible(false)
        } else {
            setTagsInputValue('')
            setTagsIpueVisible(false)
        }
    };

    const handleClose = (removedTag) => {
        let tags = cursorTags.filter(tag => tag !== removedTag);
        setCursorTags(tags)
    }

    // 时间限制
    function disabledDate(current) {
        // Can not select days before today and today
        return current < moment().startOf('day');
        
      }

    return (
        <div className="my-cursor-page-wapper" style={{ margin: 'auto' }}>
            <div className="cursor-page">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PageHeader
                        className="site-page-header"
                        backIcon={<LeftOutlined />}
                        onBack={() => props.history.push({ 'pathname': '/app/cursor/order' })}
                        style={{ padding: '0px' }}
                        title="课程信息"
                    />
                    <Search placeholder="搜索关键字" onSearch={(value) => searchCursor(value)} enterButton style={{ width: 700, margin: 'auto' }} />
                    <div>
                        {/* , backgroundColor:'#4682B4', color:'white', */}
                        <Button icon={<AlignLeftOutlined />} type="primary" style={{ borderRadius: '20px', marginRight:'20px'}} onClick={()=>props.history.push({'pathname': '/app/cursor/myCursor'})}>查看已发布课程</Button>
                        {/* backgroundColor:"#6495ED", */}
                        <Button icon={<FormOutlined />} type="primary"  style={{ borderRadius: '20px',  color:'white' }} onClick={() => setAddCursor(true)}>创建课程</Button>
                    </div>
                </div>
                <div className="cursor-page-list">
                    {
                        cursorList.length != 0 ? <Row>
                            {
                                cursorList.map(item => {
                                    return <Col style={{ width: "100%" }}>
                                        <Row span={24} style={{ justifyContent: 'center' }}>
                                            <Col style={{ marginTop: 10 }} onClick={() => {
                                                setCursorName(item.course_name)
                                                setCursorIntroduction(item.course_introduction)
                                                // setCursorClassification(item.types_search_key_id.split('-'));\
                                                setCursorClassificationStr(item.types_search_key_id)
                                                setCursorId(item.id)
                                                setOpenDetail(true)
                                            }}>
                                                <div className="cursor-up-base-erea" style={{ background: '#91d5ff', borderRadius:"20px", height:'120px' }}>
                                                    <Title level={5} ellipsis={{ rows: 2, expandable: false }} className="cursor-title" style={{ margin: 0 }}>{item.course_name}</Title>
                                                </div>
                                            </Col>
                                            <Col style={{ marginTop: '15px', marginLeft: '.5vw' }}>
                                                <Row className="my-cursor-row">
                                                    <Col span={23}>
                                                        <Row>
                                                            {
                                                                item.types_search_key_id.split('-').map(item => {
                                                                    return <Tag color="blue" style={{ borderRadius: '20px', height: '25px', padding: '5px', lineHeight: '11px' }}>{item}</Tag>
                                                                })
                                                            }
                                                            <Col>
                                                                <Title level={4} onClick={() => {
                                                                   setCursorName(item.course_name)
                                                                   setCursorIntroduction(item.course_introduction)
                                                                   // setCursorClassification(item.types_search_key_id.split('-'));\
                                                                   setCursorClassificationStr(item.types_search_key_id)
                                                                   setCursorId(item.id)
                                                                   setOpenDetail(true)
                                                                }}>{item.course_name}</Title>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={1}>
                                                        <Button type="primary" icon={<BtnIconFont type="icon-fabu" />} style={{ borderRadius: '20px' }} onClick={() => {
                                                            setCursorId(item.course_id)
                                                            setCursorName(item.course_name)
                                                            setReleaseCursor(true)
                                                        }}>发布</Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className="course_introduction_style">
                                                        <Paragraph  ellipsis={{rows:3}} type="secondary">
                                                            {item.course_introduction}
                                                        </Paragraph>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                })
                            }
                        </Row> : <div><Text strong>{'暂无课程信息...'}</Text></div>}
                </div>
            </div>
            <Pagination simple page={page} total={total} hideOnSinglePage onChange={pageChange} />
            <Drawer
                title="课程详情"
                placement="right"
                closable={false}
                onClose={onCloseDetail}
                visible={openDetail}
                width={700}
                zIndex="10"
            >
                <div className="detail-page-list">
                    <Form
                        form={form1}
                        labelAlign="left"
                        {...layout2}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label="课程名称"
                                >
                                    <Text type="secondary">{cursorName}</Text>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label="分类"
                                >
                                    {/* <div>
                                        <Cascader
                                            value={cursorClassification}
                                            options={classificationOption}
                                        />
                                    </div> */}
                                    <Text type="secondary">{cursorClassificationStr}</Text>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form
                            form={form1}
                            labelAlign="left"
                            {...layout2}
                        >
                            <Form.Item
                                name="introduce"
                                label="课程简介"
                            >
                                {/* editable={{ onChange: setCursorIntroduction }} */}
                                <Paragraph type="secondary" >
                                    {cursorIntroduction}
                                </Paragraph>
                            </Form.Item>
                        </Form>
                    </Form>
                    {/* <Row>
                        <Col>
                            <Button type="primary">确认更新</Button>
                        </Col>
                        <Col>
                            <Button type="primary" danger style={{ marginLeft: "10px" }}>删除课程</Button>
                        </Col>
                    </Row> */}
                </div>
            </Drawer>

            <Modal
                title="预览"
                centered={true}
                visible={preview}
                onOk={previewOk}
                onCancel={previewCancel}
                okText="确定"
                cancelText="取消"
                zIndex="999"
                maskClosable={false}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Form
                    form={form1}
                    labelAlign="left"
                    {...layout1}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="cursorType"
                                label="颜色选择"
                            >
                                <input
                                    type="color"
                                    onChange={Debounce((e) => setCursorColor(e.target.value), 300)}
                                    name="color1"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="cursorType"
                        label="预览"
                    >
                        <CursorItem
                            title={cursorName}
                            width={"300px"}
                            cardTitleHeight={"180px"}
                            tags={cursorTags}
                            bgColor={cursorColor}
                            openTime={cursorStartTime}
                            teachType={cursorForm == 1 ? '面授' : '线上'}
                            endTime={'01-05 17:00'}
                            onlyTitle
                        />
                    </Form.Item>
                </Form>

            </Modal>

            <Modal
                title="创建课程"
                centered={true}
                visible={addCursor}
                onOk={addCursorOk}
                onCancel={addCursorCancel}
                okText="保存"
                cancelText="取消"
                zIndex="999"
                maskClosable={false}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                width={1000}
            >
                <Form
                    form={form1}
                    labelAlign="left"
                    {...layout1}
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="课程名称"
                            >
                                <Input
                                    id="path"
                                    size="default"
                                    placeholder="请填写课程名称"
                                    value={cursorName}
                                    onChange={(e) => {
                                        setCursorName(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="cursorClassification"
                                label="分类"
                            >
                                <Cascader
                                    placeholder="请选择课程分类"
                                    options={classificationOption}
                                    onChange={(value) => setCursorClassification(value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form
                        form={form1}
                        labelAlign="left"
                        {...layout2}
                    >
                        <Form.Item
                            name="introduce"
                            label="课程简介"
                        >
                            <TextArea
                                placeholder="请填写课程简介"
                                showCount
                                maxLength={200}
                                rows={5}
                                onChange={(e) => {
                                    setCursorIntroduction(e.target.value)
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Form>
            </Modal>

            <Modal
                title={`发布` + cursorName}
                centered={true}
                visible={releaseCursor}
                onOk={releaseCursorOk}
                onCancel={releaseCursorCancel}
                okText="保存"
                cancelText="取消"
                zIndex="999"
                maskClosable={false}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                width={1600}
            >
                <Form
                    form={form1}
                    >
                    <Form.Item
                        name="introduce"
                        >
                        <Descriptions bordered column={4}>
                            <Descriptions.Item label="上课形式">
                                <Radio.Group value={cursorForm} onChange={(e) => setCursorForm(e.target.value)} >
                                    <Radio value={1}>面授</Radio>
                                    <Radio value={2}>线上</Radio>
                                </Radio.Group>
                            </Descriptions.Item>
                            <Descriptions.Item label="可否旁观">
                                <Radio.Group value={isLookOn} onChange={(e) => setIsLookOn(e.target.value)} >
                                    <Radio value={0}>不允许</Radio>
                                    <Radio value={1}>允许</Radio>
                                </Radio.Group>
                            </Descriptions.Item>
                            <Descriptions.Item label="人数范围">
                                <Input.Group compact>
                                    <InputNumber
                                        className="site-input-right"
                                        min={0}
                                        style={{
                                            width: 70,
                                            textAlign: 'center',
                                        }}
                                        value={minPeople}
                                        onChange={(value) => {
                                            setMinPeople(value)
                                        }}
                                    />
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: 30,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <InputNumber
                                        className="site-input-right"
                                        min={minPeople}
                                        style={{
                                            width: 70,
                                            textAlign: 'center',
                                        }}
                                        value={maxPeople}
                                        onChange={(value) => {
                                            setMaxPeople(value)
                                        }}
                                    />
                                </Input.Group>
                            </Descriptions.Item>
                            <Descriptions.Item label="课程类型">
                                <Radio.Group onChange={(e) => setCursorType(e.target.value)} value={cursorType}>
                                    <Radio value={1}>分享</Radio>
                                    <Radio value={2}>讨论</Radio>
                                </Radio.Group>
                            </Descriptions.Item>
                            <Descriptions.Item label="引领者" span={2}>
                                <Row>
                                    <Col span={22}>
                                        <Select mode="multiple" value={leaderList} disabled={cursorType == 1 ? true : false} allowClear showSearch style={{ width: "100%" }} onChange={(value) => { setLeaderList(value) }}>
                                            {
                                                allPeople.map(item => {
                                                    return <Option value={item.name} disabled={item.name == cookie.load('name') ? true : false}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label="报名时间">
                                <RangePicker
                                    key="cursorSignUpTime"
                                    placeholder=""
                                    format={'YYYY-MM-DD'}
                                    style={{ width: '240px' }}
                                    locale={locale}
                                    disabledDate={disabledDate}
                                    onChange={(value, dateString) => {
                                        if(value == null){
                                            return false
                                        }else{
                                            setCursorSignUPStartTime(value[0].format('YYYY-MM-DD'))
                                            setCursorSignUPEndTime(value[1].format('YYYY-MM-DD'))
                                        }
                                    }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="反馈时间">
                                <Select style={{ width: '240px' }} onChange={(value) => { setCursorFeedbackTime(value) }}>
                                    <Option value="3">课后3天内</Option>
                                    <Option value="7">课后一周内</Option>
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label="开课时间">
                                <DatePicker disabledDate={disabledDate} locale={locale} placeholder="" format={'YYYY-MM-DD HH:mm'} style={{ width: '170px' }} showTime onChange={(value, dateString) => {
                                   
                                    if(value == null){
                                        return false
                                    }else{
                                        // setCursorStartTime(value.format('YYYY-MM-DD HH:mm'))
                                        if(value.format('YYYY-MM-DD HH:mm') < cursorSignUpEndTime){
                                            message.warn('课程开始时间要晚于报名结束时间')
                                        }else{
                                            setCursorStartTime(value.format('YYYY-MM-DD HH:mm'))
                                        }
                                    }
                                }} />
                            </Descriptions.Item>
                            <Descriptions.Item label="课时">
                                <InputNumber
                                    className="site-input-right"
                                    step={0.5}
                                    min={0}
                                    style={{
                                        width: '200px',
                                        textAlign: 'center',
                                    }}
                                    onChange={(value) => {
                                        setCursorClass(value)
                                    }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="标签" span={2}>
                                <Row>
                                    <Col>
                                        <TweenOneGroup
                                            enter={{
                                                scale: 0.8,
                                                opacity: 0,
                                                type: 'from',
                                                duration: 100,
                                                onComplete: e => {
                                                    e.target.style = '';
                                                },
                                            }}
                                            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                            appear={false}
                                        >
                                            {tagChild}
                                        </TweenOneGroup>
                                    </Col>
                                    <Col>
                                        {
                                            cursorTags.length < 5 ? <div>
                                                {tagsInputVisible && (
                                                    <Input
                                                        ref={inputRef}
                                                        type="text"
                                                        size="small"
                                                        style={{ width: 78 }}
                                                        value={tagsInputValue}
                                                        onChange={handleInputChange}
                                                        onBlur={handleInputConfirm}
                                                        onPressEnter={handleInputConfirm}
                                                    />
                                                )}
                                                {!tagsInputVisible && (
                                                    <Tag onClick={showInput} className="site-tag-plus">
                                                        <PlusOutlined /> New Tag
                                                    </Tag>
                                                )}
                                            </div>
                                                : <div></div>
                                        }
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label="课程详情" span={2}>
                                <TextArea onChange={(e) => setCursorDetails(e.target.value)} />
                            </Descriptions.Item>
                            <Descriptions.Item label="上传课件" span={4}>
                                <Upload
                                    action={servicePath.image}                          //上传地址，填错/不填上传图片的时候都会报错
                                    listType="picture"                             // 上传列表的内建样式
                                    fileList={docList}                                 //已经上传的文件列表（受控）
                                    onChange={fileHandleChange}                             //上传文件改变时的状态
                                    className="curosr-upload-list-inline"
                                    accept=".doc,.docx,.xlsx,.xls,.pdf,.zip,.rar,.txt,image/*"  //设置文件上传类型
                                >
                                    {docList.length >= 5 ? null : uploadButton}
                                </Upload>
                                <div>
                                    <span style={{ margin: '0 8px', color: '#87CEFA' }}>
                                        允许上传.doc,.docx,.xlsx,.xls,.pdf,.zip,.rar,.png,.jpg,.pptx,.txt
                                </span>
                                </div>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    )
}

export default OrderCursor