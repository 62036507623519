import React, { useState, useEffect } from 'react';
import { Button, Spin, Table, Typography, Checkbox, message, Input, DatePicker } from 'antd'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import exportPdf from '../../../config/utils/html2pdf'
import Hierarchy from '../component/hierarchy'
import moment from 'moment'
import CommodityImage from '../../commodityRanking/components/commodityImage';
const { Title, Text } = Typography;
const { TextArea } = Input
const dateFormat = 'YYYY-MM-DD';
function Index(props) {
    function disabledDate(current) {
        // return current > moment().endOf('day');
        return current > moment().subtract(7, 'd').endOf('week');
    }
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'gs':'703247c1-a200-42bc-b70f-742dbab27aa4',
        'qda':'2715f3fa-0dae-4dc7-bcfc-0dd912314e40'
    }
    // 指标数据
    const [ indexData , setIndexData] = useState([
        {'绝对值':'','增长率':''},
        {'绝对值':'','增长率':''},
        {'绝对值':'','增长率':''},
        {'绝对值':'','增长率':''},
        {'绝对值':'','增长率':''},
    ])
    // 日期
    const [ date, setDate ] = useState(moment().subtract(7, 'd').format('YYYY-MM-DD'))
    // 周期开始时间
    const [ weekStartDate, setWeekStartDate ] = useState('')
    const [ weekEndDate, setWeekEndDate ] = useState('')
    // 是否加载
    const [loading, setLoading] = useState(false)
    // 访客数数据
    const [visitorNumDate, setVisitorNumDate] = useState([])
    // 加购人数数据
    const [addCarConsumerDate, setAddCarConsumerDate] = useState([])
    // 销售金额汇总
    const [payMoneyDate, setPayMoneyDate] = useState([])
    // 漏斗图数据
    const [ funnelEchartData1, setFunnelEchartData1 ] = useState([])
    const [ funnelEchartData2, setFunnelEchartData2 ] = useState([])
    // 运营表头
    const [operationTableColumns, setOperationTableColumns] = useState([])
    // 运营数据
    const [ operationTableData , setOperationTableData] = useState([])
    // 运营可选字段
    const [ operationField] = useState(['加购转化率（件）','访客数','加购件数','客单价', '新客销售','新客占比','平均加购件数','商品访客数','加购人数','支付件数','件单价','商品访客跳转率','加购率（件）','加购率（人）','支付金额','客件数'])
    // 已选运营可选字段
    const [ chooseOperationField, setChooseOperationField] = useState(['访客数','加购件数','加购转化率（件）','客单价', '新客销售','新客占比','平均加购件数','商品访客数','加购人数','支付件数','件单价','商品访客跳转率','加购率（件）','加购率（人）','支付金额','客件数'])
    // 流量基础字段
    const [ trafficBaseField] = useState(['访客数','加购数','支付金额','转化率','UV价值'])
    // 流量表头
    const [trafficTableColumns] = useState([
        {
            title: '流量数据',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            children: [
                {
                    title: '流量来源',
                    dataIndex: '流量来源',
                    key: '流量来源',
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            ]
        },
        {
            title:'本周',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            children:trafficBaseField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-本周',
                    key:item+'-本周',
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        },
        {
            title:'环比',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            children: trafficBaseField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-环比',
                    key:item+'-环比',
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        },
        {
            title:'上周',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            children:trafficBaseField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-上周',
                    key:item+'-上周',
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        }
    ])
    // 流量数据
    const [trafficTableDate, setTrafficTableDate] = useState([])
    // 流量TOP表头
    const [trafficTopTableColumns, setTrafficTopTableColumns] = useState([])
    // 浏览TOP数据
    const [trafficTopTableData, setTrafficTopTableData] = useState([])
    // 商品TOP表头
    const [thisCommodityTopTableColumns, setThisCommodityTopTableColumns] = useState([])
    const [lastCommodityTopTableColumns, setLastCommodityTopTableColumns] = useState([])
    // 商品Top
    const [thisWeekCommodityTopTableData, setThisWeekCommodityTopTableData] = useState([])
    const [lastWeekCommodityTopTableData, setLastWeekCommodityTopTableData] = useState([])
    // 浏览TOP数据
    const [commodityTopTableData, setCommodityTopTableData] = useState([])
    // 商品表头
    const [commodityColumns] = useState([
        {
            title: '商品汇总数据',
            dataIndex: '商品汇总数据',
            key: '商品汇总数据',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '销售金额',
            dataIndex: '销售金额',
            key: '销售金额',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '支付件数',
            dataIndex: '支付件数',
            key: '支付件数',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '件单价',
            dataIndex: '件单价',
            key: '件单价',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '动销款数',
            dataIndex: '动销款数',
            key: '动销款数',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '单款贡献',
            dataIndex: '单款贡献',
            key: '单款贡献',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '折扣',
            dataIndex: '折扣',
            key: '折扣',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        }
    ])
    // 商品数据
    const [ commodityData , setCommodityData ] = useState([])
    // 超级推荐表头
    const [superRecommendedColumns] = useState([
        {
            title: '图片',
            dataIndex: 'source',
            key: 'source',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '点击',
            dataIndex: 'source',
            key: 'source',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '花费',
            dataIndex: 'source',
            key: 'source',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '单价',
            dataIndex: 'source',
            key: 'source',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: '点击率',
            dataIndex: 'source',
            key: 'source',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        {
            title: 'ROI(3天)',
            dataIndex: 'source',
            key: 'source',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
    ])
    // 流量数据字段
    const [trafficField] = useState(['访客数', '支付金额', '加购转化率', 'UV价值'])
    // 商品字段
    const [commodityField] = useState(['访客数', '支付金额','加购件数', '加购转化率', 'UV价值'])
    // 选择流量数据字段
    const [chooseTrafficField, setChooseTrafficField] = useState(['访客数', '支付金额'])
    // 基础字段
    const [ baseField] = useState(['销售金额','支付件数','件单价','折扣','单款贡献','动销款数'])
    // 基础表头
    const [ baseColumns ] = useState([
        {
            title:'本周',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            children:baseField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-本周',
                    key:item+'-本周',
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        },
        {
            title:'环比',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            children: baseField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-环比',
                    key:item+'-环比',
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        },
        {
            title:'上周',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            children:baseField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-上周',
                    key:item+'-上周',
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        }
    ])
    // 产品线表头
    const [ productLineColumns ] = useState([
        {
            title: '产品线',
            dataIndex: '产品线',
            key: '产品线',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseColumns.map(item=>{
            return item
        })
    ])
    // 产品线数据
    const [ productData , setProductData ] = useState([])
    // 季节表头
    const [ seasonColumns] = useState([
        {
            title: '季节',
            dataIndex: '季节',
            key: '季节',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseColumns.map(item=>{
            return item
        })
    ])
    // 季节数据
    const [ seasonData, setSeasonData ] = useState([])
    // 品类表头
    const [ categoryColumns] = useState([
        {
            title: '品类',
            dataIndex: '品类',
            key: '品类',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseColumns.map(item=>{
            return item
        })
    ])
    // 店铺名字
    const [ shopName , setShopName ] = useState('')
    // 品类数据
    const [ categoryData , setCategoryData] = useState([])
    // Top表头
    const [ topColumns] = useState([
        {
            title: '排名',
            dataIndex: '排名',
            key: '排名',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...baseColumns.map(item=>{
            return item
        })
    ])
    // Top数据
    const [ topData , setTopData ] = useState([])
    // 运营表头
    const [ operationalBaseField ] = useState(['访客数','加购件数','收藏人数','加购率','单款加购'])
    // 运营基础表头
    const [ operationalBaseColumns ] = useState([
        {
            title:'本周',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            children:operationalBaseField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-本周',
                    key:item+'-本周',
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        },
        {
            title:'环比',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            children: operationalBaseField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-环比',
                    key:item+'-环比',
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        },
        {
            title:'上周',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            children:operationalBaseField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-上周',
                    key:item+'-上周',
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        }
    ])
    // 运营产品线表头
    const [ operationalProductLineColumns ] = useState([
        {
            title: '产品线',
            dataIndex: '产品线',
            key: '产品线',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...operationalBaseColumns.map(item=>{
            return item
        })
    ])
    // 运营产品线数据
    const [ operationalProductData , setOperationalProductData ] = useState([])
    // 运营季节表头
    const [ operationalSeasonColumns] = useState([
        {
            title: '季节',
            dataIndex: '季节',
            key: '季节',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...operationalBaseColumns.map(item=>{
            return item
        })
    ])
    // 运营季节数据
    const [ operationalSeasonData, setOperationalSeasonData ] = useState([])
    // 运营品类表头
    const [ operationalCategoryColumns] = useState([
        {
            title: '品类',
            dataIndex: '品类',
            key: '品类',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...operationalBaseColumns.map(item=>{
            return item
        })
    ])
    // 运营品类数据
    const [ operationalCategoryData , setOperationalCategoryData] = useState([])
    // 运营Top表头
    const [ operationalTopColumns] = useState([
        {
            title: '排名',
            dataIndex: '排名',
            key: '排名',
            align: 'center',
            onHeaderCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
            onCell: (record, index) => {
                return (
                    {
                        style: {
                            fontSize: '15px',
                            padding: 0
                        }
                    }
                )
            },
        },
        ...operationalBaseColumns.map(item=>{
            return item
        })
    ])
    // 运营Top数据
    const [ operationalTopData , setOperationalTopData ] = useState([])
    // 书写结论
    const [ conclusion1, setConclusion1] = useState('')
    const [ conclusion2, setConclusion2] = useState('')
    // 访客数
    const [ visitors1 , setVisitors1] = useState('')
    const [ visitors2 , setVisitors2] = useState('')
    // 商品访客数
    const [ productVisitor1, setProductVisitor1]= useState('')
    const [ productVisitor2, setProductVisitor2]= useState('')
    // 加购件数
    const [ additionalPurchases1, setAdditionalPurchases1] = useState('')
    const [ additionalPurchases2, setAdditionalPurchases2] = useState('')
    // 支付件数
    const [ payPurchases1, setPayPurchases1] = useState('')
    const [ payPurchases2, setPayPurchases2] = useState('')
    // 成交金额
    const [ turnover1, setTurnover1] = useState('')
    const [ turnover2, setTurnover2] = useState('')
    // IPVUV
    const [ IPVUV1, setIPVUV1] = useState('')
    const [ IPVUV2, setIPVUV2] = useState('')
    // 加购率
    const [ addRate1, setAddRate1] = useState('')
    const [ addRate2, setAddRate2] = useState('')
    // 加购转化率
    const [ addConversionRate1, setAddConversionRate1] = useState('')
    const [ addConversionRate2, setAddConversionRate2] = useState('')
    // 表格选择
    const [echartLegendSelected, setEchartLegendSelected] = useState({'淘内免费':true, '付费流量':true, '自主访问':true, '大促会场':true, '淘外媒体':true})
   
    // 访客数表格
    let visitorNumreport = {
        title: {
            text: '访客数据汇总',
            left: 'center',
            textStyle:{
                fontSize:20
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: ['淘内免费', '付费流量', '自主访问', '大促会场', '淘外媒体'],
            top: 'bottom',
            selected: echartLegendSelected,
            show:false
        },
        series: [
            {
                name: '访客数据汇总',
                type: 'pie',
                selectedMode: 'single',
                radius: '50%',
                // label: {
                //     position: 'inner',
                //     fontSize: 14,
                // },
                // data: visitorNumDate,
                data: visitorNumDate,
                itemStyle: {
                    normal: {
                        label: {
                            show: true,
                            formatter: '{b} : {c} ({d}%)',
                            textStyle:{
                                fontSize:15
                            }
                        },
                        labelLine: { show: true }
                    },
                    fontSize:20,
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }

    // 加购人数汇总
    let addCarConsumerreport = {
        title: {
            text: '加购人数汇总',
            left: 'center',
            textStyle:{
                fontSize:20
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: ['淘内免费', '付费流量', '自主访问', '大促会场', '淘外媒体'],
            top: 'bottom',
            selected: echartLegendSelected,
        },
        series: [
            {
                name: '加购人数汇总',
                type: 'pie',
                selectedMode: 'single',
                radius: '50%',
                // label: {
                //     position: 'inner',
                //     fontSize: 14,
                // },
                // data: addCarConsumerDate,
                data:  addCarConsumerDate,
                itemStyle: {
                    normal: {
                        label: {
                            show: true,
                            formatter: '{b} : {c} ({d}%)',
                            textStyle:{
                                fontSize:15
                            }
                        },
                        labelLine: { show: true }
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }

    // 销售金额表格
    let payMoneyreport = {
        title: {
            text: '销售金额汇总',
            left: 'center',
            textStyle:{
                fontSize:20
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: ['淘内免费', '付费流量', '自主访问', '大促会场', '淘外媒体'],
            top: 'bottom',
            selected: echartLegendSelected,
            show:false
        },
        series: [
            {
                name: '销售金额汇总',
                type: 'pie',
                selectedMode: 'single',
                radius: '50%',
                // label: {
                //     position: 'inner',
                //     fontSize: 14,
                // },
                // data: payMoneyDate,
                data:  payMoneyDate,
                itemStyle: {
                    normal: {
                        label: {
                            show: true,
                            formatter: '{b} : {c} ({d}%)',
                            textStyle:{
                                fontSize:15
                            }
                        },
                        labelLine: { show: true }
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }

    useEffect(()=>{
       let data = props.history.location.state
       setVisitors1(data['visitors1'])
       setVisitors2(data['visitors2'])
       setProductVisitor1(data['productVisitor1'])
       setProductVisitor2(data['productVisitor2'])
       setAdditionalPurchases1(data['additionalPurchases1'])
       setAdditionalPurchases2(data['additionalPurchases2'])
       setPayPurchases1(data['payPurchases1'])
       setPayPurchases2(data['payPurchases2'])
       setTurnover1(data['turnover1'])
       setTurnover2(data['turnover2'])
       setIPVUV1(data['IPVUV1'])
       setIPVUV2(data['IPVUV2'])
       setAddRate1(data['addRate1'])
       setAddRate2(data['addRate2'])
       setAddConversionRate1(data['addConversionRate1'])
       setAddConversionRate2(data['addConversionRate2'])
       setIndexData(data['indexData'])
       setChooseOperationField(data['chooseOperationField'])
       setOperationTableData(data['operationTableData'])
       setVisitorNumDate(data['visitorNumDate'])
       setAddCarConsumerDate(data['addCarConsumerDate'])
       setPayMoneyDate(data['payMoneyDate'])
       setTrafficTableDate(data['trafficTableDate'])
       setChooseTrafficField(data['chooseTrafficField'])
       setTrafficTopTableData(data['trafficTopTableData'])
       setThisWeekCommodityTopTableData(data['thisWeekCommodityTopTableData'])
       setLastWeekCommodityTopTableData(data['lastWeekCommodityTopTableData'])
       setCommodityData(data['commodityData'])
       setProductData(data['productData'])
       setSeasonData(data['seasonData'])
       setCategoryData(data['categoryData'])
       setConclusion1(data['conclusion1'])
       setConclusion2(data['conclusion2'])
       setTopData(data['topData'])
       setOperationalTopData(data['operationalTopData'])
       setOperationalCategoryData(data['operationalCategoryData'])
       setOperationalSeasonData(data['operationalSeasonData'])
       setOperationalProductData(data['operationalProductData'])
    },[])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('visitorNumreport'));
        reportChart.clear();    //清除残留渲染
        // 绘制图表
        reportChart.setOption(visitorNumreport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [visitorNumDate])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('addCarConsumerreport'));
        // 绘制图表
        reportChart.setOption(addCarConsumerreport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [addCarConsumerDate])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('payMoneyreport'));
        // 绘制图表
        reportChart.setOption(payMoneyreport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [payMoneyDate])

    useEffect(()=>{
        setTrafficTopTableColumns([
            {
                title: 'TOP10流量接口',
                align: 'center',
                dataIndex: '商品',
                key:'商品' ,
                width: 100,
            },
            {
                title:'本周',
                align: 'center',
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                children:trafficField.map(item=>{
                    return {
                        title:item,
                        dataIndex:item+'本周',
                        key:item+'本周',
                        align: 'center',
                        onHeaderCell: (record, index) => {
                            return (
                                {
                                    style: {
                                        fontSize: '15px',
                                        padding: 0
                                    }
                                }
                            )
                        },
                        onCell: (record, index) => {
                            return (
                                {
                                    style: {
                                        fontSize: '15px',
                                        padding: 0
                                    }
                                }
                            )
                        },
                    }
                })
            },
            {
                title:'环比',
                align: 'center',
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                children: trafficField.map(item=>{
                    return {
                        title:item,
                        dataIndex:item+'环比',
                        key:item+'环比',
                        align: 'center',
                        onHeaderCell: (record, index) => {
                            return (
                                {
                                    style: {
                                        fontSize: '15px',
                                        padding: 0
                                    }
                                }
                            )
                        },
                        onCell: (record, index) => {
                            return (
                                {
                                    style: {
                                        fontSize: '15px',
                                        padding: 0
                                    }
                                }
                            )
                        },
                    }
                })
            },
            {
                title:'上周',
                align: 'center',
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                children:trafficField.map(item=>{
                    return {
                        title:item,
                        dataIndex:item+'上周',
                        key:item+'上周',
                        align: 'center',
                        onHeaderCell: (record, index) => {
                            return (
                                {
                                    style: {
                                        fontSize: '15px',
                                        padding: 0
                                    }
                                }
                            )
                        },
                        onCell: (record, index) => {
                            return (
                                {
                                    style: {
                                        fontSize: '15px',
                                        padding: 0
                                    }
                                }
                            )
                        },
                    }
                })
            }
           
        ])
        setLastCommodityTopTableColumns([
            {
                title: 'TOP10商品接口',
                align: 'center',
                dataIndex: '图片',
                key:'图片',
                width: 100,
                render: (text, record, index) => (
                    <img src={text} style={{width:80, height:80}} />
                )
            },
            ...commodityField.map(item=>{
                return  {
                    title:item,
                    align: 'center',
                    dataIndex: item,
                    key:item,
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                       
                }
            })
           
        ])
        setThisCommodityTopTableColumns([
            {
                title: 'TOP10商品接口',
                align: 'center',
                dataIndex: '图片',
                key:'图片',
                width: 100,
                render: (text, record, index) => (
                    <img src={text} style={{width:80, height:80}} />
                )
            },
            ...commodityField.map(item=>{
                return  {
                    title:item,
                    align: 'center',
                    dataIndex: item,
                    key:item,
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                       
                }
            })
           
        ])
    },[shopName])

    useEffect(()=>{
        setOperationTableColumns([
            {
                title: '核心字段',
                dataIndex: '核心字段',
                key: '核心字段',
                align: 'center',
                onHeaderCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
                onCell: (record, index) => {
                    return (
                        {
                            style: {
                                fontSize: '15px',
                                padding: 0
                            }
                        }
                    )
                },
            },
            ...chooseOperationField.map(item=>{
                return {
                    title: item,
                    dataIndex: item,
                    key: item,
                    align: 'center',
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                    onCell: (record, index) => {
                        return (
                            {
                                style: {
                                    fontSize: '15px',
                                    padding: 0
                                }
                            }
                        )
                    },
                }
            })
        ])
    },[chooseOperationField])

    return (
        <div>
            <Spin spinning={loading}>
                <div style={{
                    display: 'flex',
                    justifyContent: "space-around",
                    backgroundColor: "#fff",
                    borderBottom: "solid 2px #ccc",
                    marginBottom: 10,
                    position: 'fixed',
                    top: 40,
                    zIndex: 99,
                    width: '100vw'
                }}>
                    <Title level={3}>预览周报</Title>
                    <div style={{ display: 'flex', marginTop: 5 }}>
                        {/* <DatePicker disabledDate={disabledDate} defaultValue={date == '' ? '' : moment(date, dateFormat)} style={{height:35, marginRight:10}} picker="week" locale={locale} onChange={(date, dateString)=>{
                            setDate(moment(date).format('YYYY-MM-DD'));
                        }}/> */}
                        <Button type="primary" danger onClick={() => {
                            exportPdf("周报数据")
                        }}>导出PDF</Button>
                    </div>
                </div>
                <div id="exportPdf" style={{ marginTop: 80 }}>
                    <div style={{display:'flex', width:'98vw'}}>
                        <div style={{width:"30vw", display:'flex', justifyContent:'center'}}>
                            <Hierarchy id={"FunnelEchart1"} title={"本周数据"} visitors={visitors1} productVisitor={productVisitor1} additionalPurchases={additionalPurchases1} 
                                payPurchases={payPurchases1} IPVUV={IPVUV1} addRate={addRate1} addConversionRate={addConversionRate1} turnover={turnover1}/>
                        </div>
                        <div style={{width:"38vw"}}>
                            <div style={{display:'flex'}}>
                                <div style={{width:"19vw", display:'flex', justifyContent:'center'}}>
                                    <b style={{fontSize:22, width:250, textAlign:'center'}}>增长量</b>
                                </div>
                                <div style={{width:"19vw", display:'flex', justifyContent:'center'}}>
                                    <b style={{fontSize:22, width:250, textAlign:'center'}}>增长率</b>
                                </div>
                            </div>
                            <div style={{height: "18vh", display:indexData[0]['绝对值'] == '' ? 'none' :''}}>
                                <div style={{display:'flex',  marginTop:'20px'}}>
                                    <div style={{width:"19vw", display:'flex', justifyContent:'center'}}>
                                        <Text>{indexData[0]['绝对值']}</Text>
                                    </div>
                                    <div style={{width:"19vw", display:'flex', justifyContent:'center'}}>
                                        <Text>{indexData[0]['增长率']}</Text>
                                    </div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-around', marginTop:'35px'}}>
                                    <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                        <Text>{indexData[1]['绝对值']}</Text>
                                    </div>
                                    <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                        <Text>{indexData[1]['增长率']}</Text>
                                    </div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-around', marginTop:'43px'}}>
                                    <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                        <Text>{indexData[2]['绝对值']}</Text>
                                    </div>
                                    <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                        <Text>{indexData[2]['增长率']}</Text>
                                    </div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-around', marginTop:'40px'}}>
                                    <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                        <Text>{indexData[3]['绝对值']}</Text>
                                    </div>
                                    <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                        <Text>{indexData[3]['增长率']}</Text>
                                    </div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-around', marginTop:'40px'}}>
                                    <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                        <Text>{indexData[4]['绝对值']}</Text>
                                    </div>
                                    <div style={{width:250, display:'flex', justifyContent:'center'}}>
                                        <Text>{indexData[4]['增长率']}</Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width:"30vw", display:'flex', justifyContent:'center'}}>
                            <Hierarchy id={"FunnelEchart2"} title={"上周数据"} visitors={visitors2} productVisitor={productVisitor2} additionalPurchases={additionalPurchases2} 
                                payPurchases={payPurchases2} turnover={turnover2} IPVUV={IPVUV2} addRate={addRate2} addConversionRate={addConversionRate2}/>
                        </div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Table columns={operationTableColumns} dataSource={operationTableData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ display: 'flex', marginLeft: 150, marginTop: 10, marginBottom:10 }}>
                        <Text strong>结论:</Text>
                        <Text style={{width: '80vw', marginLeft: 50}}>{conclusion1}</Text>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: "space-around",
                        backgroundColor: "#fff",
                        width: "98vw",
                        marginTop:100
                        // marginLeft: "5vw",
                    }}>
                        <div id="visitorNumreport" style={{ width: '100%', height: "40vh" }}></div>
                        <div id="addCarConsumerreport" style={{ width: '100%', height: "40vh" }}></div>
                        <div id="payMoneyreport" style={{ width: '100%', height: "40vh" }}></div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Table columns={trafficTableColumns} dataSource={trafficTableDate} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Table columns={trafficTopTableColumns} dataSource={trafficTopTableData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Table columns={operationalProductLineColumns} dataSource={operationalProductData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Table columns={operationalSeasonColumns} dataSource={operationalSeasonData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Table columns={operationalCategoryColumns} dataSource={operationalCategoryData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ marginTop: 20, paddingBottom:100 }}>
                        <Table columns={operationalTopColumns} dataSource={operationalTopData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{display:'flex', justifyContent:'space-around', marginTop:80}}>
                        <div style={{width:"45vw"}}>
                            <Title level={4}>上周商品TOP10</Title>
                            <Table columns={lastCommodityTopTableColumns} dataSource={lastWeekCommodityTopTableData} bordered pagination={false}/>
                        </div>
                        <div style={{width:"45vw"}}>
                            <Title level={4}>本周商品TOP10</Title>
                            <Table columns={thisCommodityTopTableColumns} dataSource={thisWeekCommodityTopTableData} bordered pagination={false}/>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginLeft: 150, marginTop: 10, marginBottom:10 }}>
                        <Text strong>结论:</Text>
                        <Text style={{width: '80vw', marginLeft: 50}}>{conclusion2}</Text>
                    </div>

                    {/* <div style={{ marginTop: 20 }}>
                        <Table columns={commodityColumns} dataSource={trafficTableDate} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginLeft: 20, marginRight: 20, marginTop: 10 }}>
                        <div>
                            <Text strong style={{fontSize:20}}>TOP10</Text>
                            <Table columns={superRecommendedColumns} dataSource={trafficTableDate} style={{ width: '45vw' }} bordered pagination={false} />
                        </div>
                        <div>
                            <Text strong style={{fontSize:25}}>直通车</Text>
                            <Table columns={superRecommendedColumns} dataSource={trafficTableDate} style={{ width: '45vw' }} bordered pagination={false} />
                        </div>
                    </div> */}
                     <div style={{ marginTop: 100 }}>
                        <Table columns={commodityColumns} dataSource={commodityData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Table columns={productLineColumns} dataSource={productData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Table columns={seasonColumns} dataSource={seasonData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Table columns={categoryColumns} dataSource={categoryData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Table columns={topColumns} dataSource={topData} style={{ marginLeft: 20, marginRight: 20 }} bordered pagination={false} />
                    </div>
                </div>
            </Spin>
        </div>
    )

}


export default Index