import React, { useEffect } from 'react';
// import Debounce from '../../config/utils/debounce';
import Debounce from 'lodash/debounce'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

function Chart({
    id,
    title,
    xZhou = [],
    barData = [],
    lineData = [],
    legend = [],
    series = [],
    height,
    yAxis = [],
    ...rest
}) {

    let labelOption = {
        show: true,
        align: 'center',
        position: 'top',
        distance: 5,
        fontSize: "1rem",
        color: "rgba(0,0,0,.8)"
    };
    let report = {
        title: {
            text: title,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '10%',
        },
        dataZoom: [{
            type: "inside"         //详细配置可见echarts官网
        }],
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: legend,
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    show: false,
                    alignWithLabel: true
                },
                axisLabel: {
                    rotate: 0
                },
                data: xZhou,
                axisLine: {
                    lineStyle: {
                        color: "rgba(0, 0, 0, 0.4)"
                    },
                    onZero: true,
                    show: true
                },
            }
        ],
        yAxis: yAxis,
        series: series
    };
    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById(id));
        // 绘制图表
        reportChart.setOption(report)
        // window捕获标签点击，表格重绘放到浏览器渲染前
        window.addEventListener('click', e => {
            if (e.target.getAttribute("class") === "ant-tabs-tab-btn") {
                requestAnimationFrame(() =>reportChart.resize({
                        animation:{duration:300,easing:"linear",delay:1}
                    })
                )
            }
        }, true);
        window.addEventListener('resize', Debounce(() => reportChart.resize(), 100));
        return () => {
            // window捕获标签点击，表格重绘放到浏览器渲染前
            window.addEventListener('click', e => {
                if (e.target.getAttribute("class") === "ant-tabs-tab-btn") {
                    requestAnimationFrame(() =>reportChart.resize({
                            animation:{duration:300,easing:"linear",delay:1}
                        })
                    )
                }
            }, true);
            window.removeEventListener('resize', Debounce(() => reportChart.resize(), 100));
        }
    }, [xZhou, barData, lineData])
    return (
        <div id={id} style={{ width: '100%', height: height || "400px" }} {...rest} ></div>
    )
}

export default Chart