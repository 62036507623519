import React, { useEffect } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

function Index({
    price,
    category,
    maxValue,
    minValue,
    heatmapValue
}){

    useEffect(() => {
        // prettier-ignore
        let data = heatmapValue.map(function (item) {
            return [item[1], item[0], item[2] || '-'];
        });
        let yAxisVal = ''; // 定义y轴变量
        let xAxisVal = ''; // 定义x轴变量
        let report = echarts.init(document.getElementById("heatMap"))
        report.setOption({
            tooltip: {
                position: 'top',
                formatter(e) {
                    return `${yAxisVal}<br/>${xAxisVal}: ${(e.data && e.data[2]) || '-'} `;
                }
            },
            grid: {
                height: '50%',
                top: '10%'
            },
            xAxis: {
                type: 'category',
                data: price,
                splitArea: {
                    show: true
                },
                axisPointer: {
                    show: true,
                    type: 'none',
                    trigger: 'item',
                    label: {
                        formatter(e) {
                            xAxisVal = e.value; // 重点：获取x轴的值
                            return e.value
                        }
                    }
                }
            },
            yAxis: {
                type: 'category',
                data: category,
                splitArea: {
                    show: true
                },
                axisPointer: {
                    type: 'none',
                    show: true,
                    trigger: 'item',
                    splitLine: {
                        show: false
                    },
                    label: {
                        formatter(e) {
                            yAxisVal = e.value; // 重点：获取y轴的值
                            return e.value
                        }
                    }
                }
            },
            visualMap: {
                min: minValue,
                max: maxValue,
                calculable: true,
                left: 'right',
                top: 'center',
                orient: 'vertical',
                bottom: '15%',
                inRange: {
                    color: ['#f7ee83', '#fe6347']
                }
            },
            series: [
                {
                    name: 'Punch Card',
                    type: 'heatmap',
                    data: data,
                    label: {
                        show: true
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        })
    }, [heatmapValue])


    return (
        <div id="heatMap" style={{ width: "900px", height: 750 }}></div>
    )

}

export default Index