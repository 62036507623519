import React, { useEffect, useState } from 'react';
import { Typography, DatePicker, message, Table, Button, Modal } from 'antd'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import moment from 'moment'
import cookie from 'react-cookies'
import Base64 from 'base-64'
import locale from 'antd/es/date-picker/locale/zh_CN';
import NbAxios from '../../../config/utils/nbAxios'
import servicePath from '../../../config/apiUrl'
import style from './style.module.css';
const { RangePicker } = DatePicker
const { Title, Paragraph } = Typography
const dateFormat = 'YYYY-MM-DD';

function Index(props) {
    // 店铺名字
    const [shopName, setShopName] = useState('')
    // 开始时间
    const [startTime, setStartTime] = useState(moment().subtract(1, "month").format('YYYY-MM-DD'))
    // 结束时间
    const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DD'))
    // R数据
    const [ rData , setRData ] = useState([])
    // M数据
    const [ mData , setMData ] = useState([])
    // F数据
    const [ fData , setFData ] = useState([])
    // 表格数据
    const [ data , setData ] = useState([])
    const columns = [
        {
            title: 'index',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'F_times',
            dataIndex: 'F_times',
            key: 'F_times',
        },
        {
            title: 'M_money',
            dataIndex: 'M_money',
            key: 'M_money',
        },
        {
            title: 'R_days',
            dataIndex: 'R_days',
            key: 'R_days',
        },
    ]
    useEffect(() => {
        setShopName(props.location.pathname.split('/')[2])
    }, [])

    useEffect(() => {
        if (shopName != '') {
            let dataProps = {}
            dataProps.shopList = cookie.load('shopList')
            dataProps.shop_name = shopName
            dataProps.roleId = cookie.load('role')
            dataProps.start_time = startTime
            dataProps.end_time = endTime
            NbAxios(dataProps, "POST", servicePath.getKMeansRmfData).then(res => {
                if (res.success) {
                    setData(res.data);
                    let r_data = []
                    let m_data = []
                    let f_data = []
                    res.data.map(item=>{
                        r_data.push(item.R_days)
                        m_data.push(item.M_money)
                        f_data.push(item.F_times)
                    })
                    setRData(r_data);
                    setMData(m_data)
                    setFData(f_data)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }, [startTime, endTime, shopName])

    // R 表格
    let R_Report = {
        title: {
            text: `R`,
            left: 'center',
            subtext: '最近一次消费 (Recency):最后一次消费时间到所有人群中的最后一次消费的间隔天数'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: ['0', '1', '2', '3']
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: rData,
            type: 'line'
        }]
    }

     // M 表格
    let M_Report = {
        title: {
            text: `M`,
            left: 'center',
            subtext: '消费金额 (Monetary):选取时间内的平均消费金额',
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: ['0', '1', '2', '3']
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: mData,
            type: 'line'
        }]
    }

    // F 表格
    let F_Report = {
        title: {
            text: `F`,
            left: 'center',
            subtext: '消费频率 (Frequency):选取时间内的平均消费次数',
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: ['0', '1', '2', '3']
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: fData,
            type: 'line'
        }]
    }

    // 时间修改函数
    const onTimeChange = (dates, dateStrings) => {
        if (dateStrings[0] == '') {
            message.warn('请选择正确的时间')
            return false
        } else {
            setStartTime(dateStrings[0])
            setEndTime(dateStrings[1])
        }
    }

    useEffect(()=>{
        let reportChart = echarts.init(document.getElementById('R_Report'));
        // 绘制图表
        reportChart.setOption(R_Report);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[rData])

    useEffect(()=>{
        let reportChart = echarts.init(document.getElementById('M_Report'));
        // 绘制图表
        reportChart.setOption(M_Report);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[mData])

    useEffect(()=>{
        let reportChart = echarts.init(document.getElementById('F_Report'));
        // 绘制图表
        reportChart.setOption(F_Report);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[fData])

    // 创建下载任务
    const createTask = ()=>{
        if(startTime == ''){
            message.warn('请选择开始时间')
        }else {
            let dataProps = {}
            dataProps.shop_name = shopName
            dataProps.start_time = startTime
            dataProps.end_time = endTime
            dataProps.principal = cookie.load('name')
            dataProps.principal_id = cookie.load('id')
            dataProps.principal_username = Base64.decode(cookie.load('email'))
            dataProps.shopList = cookie.load('shopList')
            dataProps.shop_name = shopName
            dataProps.roleId = cookie.load('role')
            NbAxios(dataProps, "POST", servicePath.createRfmFileTask).then(res => {
                if (res.success) {
                    message.success('下载任务创建成功')
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    const openNotification = () => {
        Modal.info({
            title: '名词解释',
            width:"900px",
            content: (
              <div style={{width:'800px'}}>
                <div style={{marginTop:5}}>R，F和M均最小，说明不久前刚消费过，且21年上半年消费频次和消费金额很低，属于低忠诚度，低活跃和低付费能力的用户，建议引流到分销</div>
                <div style={{marginTop:5}}>R，F，M均一般，活跃度一般，消费能力一般，属于仍在活跃，但不够忠诚极易被竞品抢走的用户，建议对这批用户多进行一些品牌上的宣传，同时通过活动刺激他们多活跃，多消费，提升忠诚度</div>
                <div style={{marginTop:5}}>R很小，F，M最大，说明最近刚消费过，且近半年消费频次和消费金额都非常高，属于高忠诚度，高活跃和高付费能力的用户，是最需要重点呵护的用户，建议安排专员一对一服务</div>
                <div style={{marginTop:5}}>R最大，F和M很小，说明离上次消费已经很久了，且21年上半年消费频次和消费金额很低，属于已流失或已沉默用户，建议通过短信或Email或其它方式召回或唤醒</div>
              </div>
            ),
            okText:"知道了",
            onOk() {},
          });
      };



    return (
        <div style={{backgroundColor: "#fff",height:'100%'}}>
            <div style={{
                display: 'flex',
                justifyContent: "space-around",
                borderBottom: "solid 2px #ccc",
                marginBottom: 10,
                position: 'fixed',
                top: 40,
                zIndex: 99,
                width:"100vw"
            }}>
                <Title level={2}>{shopName}_RFM人群分类数据</Title>
                <div style={{display:'flex'}}>
                    <RangePicker style={{ height: "28px", marginTop: 10 }}
                        locale={locale}
                        value={[moment(startTime, dateFormat), moment(endTime, dateFormat)]}
                        onChange={onTimeChange} />
                    <Button type="primary" onClick={()=>createTask()} style={{marginTop: 10, marginLeft:10, height:'28px'}}>创建下载任务</Button>
                    <Button type="text" onClick={openNotification} style={{marginTop: 10, marginLeft:10, height:'28px'}}>名词解释</Button>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: "space-between", marginLeft:10, marginTop:25 }}>
                <div id="R_Report" style={{width: '30vw', height: '50vh', marginTop: 40}}></div>
                <div id="F_Report" style={{width: '30vw', height: '50vh', marginTop: 40}}></div>
                <div id="M_Report" style={{width: '30vw', height: '50vh', marginTop: 40}}></div>
            </div>
            <div style={{marginLeft:20, marginRight:20}}>
                <Table dataSource={data} columns={columns} pagination={false} />
            </div>
            {/* <div style={{marginLeft:20}}> 
                <div className={style.introduction}>R，F和M均最小，说明不久前刚消费过，且21年上半年消费频次和消费金额很低，属于低忠诚度，低活跃和低付费能力的用户，建议引流到分销</div>
                <div className={style.introduction}>R，F，M均一般，活跃度一般，消费能力一般，属于仍在活跃，但不够忠诚极易被竞品抢走的用户，建议对这批用户多进行一些品牌上的宣传，同时通过活动刺激他们多活跃，多消费，提升忠诚度</div>
                <div className={style.introduction}>R很小，F，M最大，说明最近刚消费过，且近半年消费频次和消费金额都非常高，属于高忠诚度，高活跃和高付费能力的用户，是最需要重点呵护的用户，建议安排专员一对一服务</div>
                <div className={style.introduction}>R最大，F和M很小，说明离上次消费已经很久了，且21年上半年消费频次和消费金额很低，属于已流失或已沉默用户，建议通过短信或Email或其它方式召回或唤醒</div>
            </div> */}
        </div>
    )

}


export default Index