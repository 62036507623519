import React, {useState, useEffect, useCallback} from 'react';
import {Breadcrumb, Row, Col, Button, Table, Modal, notification, message, Input, Select, Form, Tag, Space} from 'antd'
import {EditOutlined, DeleteOutlined, RedoOutlined, SearchOutlined} from '@ant-design/icons';
import servicePath from '../../config/apiUrl'
import Highlighter from 'react-highlight-words';
import axios from 'axios'
import cookie from 'react-cookies'
import '../../static/css/Table.css'

// 巨量数据监控
function Jillion(props){

    //数据
    const [ date , setDate ] = useState([])

    //页面
    const [page, setPage] = useState(1)

    //最大值
    const [ maxLevel , setMaxLevel ] = useState('')

    //最小值
    const [ minLevel , setMinLevel ] = useState('')

    //是否开始查询
    const [ search , setSearch ] = useState(0)

    //计时器
    const [ time , setTime ] = useState(0)

    //当前时间
    const [ currentTime , setCurrentTime ] = useState('')

    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //定时任务---一分钟执行一次
    useEffect(()=>{
        setTimeout(()=>{
            if(date.length != 0){
                // 这里去请求接口
                getAllJillon()
            }
          setTime(time+1)
        },1000*20 )
    },[time])
   
    //数据发现改变的时候，获取第一条数据进行判断是否在预定值之外
    useEffect(()=>{
        if(date.length != 0 ){
            if (date[0]['cost']<minLevel){
                openLowNotification(date[0]['time'],date[0]['cost'])
            }else if(date[0]['cost']>maxLevel){
                openHightNotification(date[0]['time'],date[0]['cost'])
            }
        }
    },[date])

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger:false,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

     //获取权限代码
     useEffect(() => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    }, [])


    const columns = [
        {
            title: '时间',
            key: 'time',
            dataIndex: 'time',
            width: '200px',
            align: 'center',
        },
        {
            title: '预算剩余',
            key: 'balance',
            dataIndex: 'balance',
            width: '200px',
            align: 'center'
        },
        {
            title: '花费',
            key: 'cost',
            dataIndex: 'cost',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                text == 0? <Tag color="black">{text}</Tag> : text > maxLevel ? <Tag color="red">{text}</Tag>
                    : text <  minLevel ? <Tag color="green">{text}</Tag>
                    :  <Tag color="blue">{text}</Tag> 
            ),
        },
    ]


    //请求数据
    const getAllJillon = ()=>{
        let dataProps = {
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role'),
            "taskId": props.location.state.taskId,
        }
        axios({
            method: 'POST',
            url: servicePath.getJillon,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    if (currentTime !=res.data['data'][0]['time'] ){
                        setCurrentTime(res.data['data'][0]['time'])
                        setDate(res.data['data'])
                    }
                } else {
                    // 这里是获取初始化数据，不用提示
                    message.success(res.data['errorMsg'])
                }
            }
        )
    }

    //低于最低速率
    const openLowNotification = (time,rate) => {
        const args = {
          message: time+','+rate+'低于最低花费,'+'需要去调整出价',
          duration: 15,
        };
        notification.open(args);
    };

    //高于最高速率
    const openHightNotification = (time,rate) => {
        const args = {
          message: time+','+rate+'高于最高花费'+'需要去调整出价',
          duration: 15,
        };
        notification.open(args);
    };

    // 查询数据
    const searchDate = ()=>{
        if (maxLevel == '' || minLevel == ''){
            message.error('请先填写花钱速率的最大/最小值')
        }else{
            getAllJillon()
            setSearch(1)
        }
       
    }

    const getExcel =()=>{
        let url = servicePath.getJillonExcel+'?taskId='+props.location.state.taskId+'&roleId='+cookie.load('role')+'&shopId='+cookie.load('shop')
        window.location.href = url 
    }

    return (
        <div style={{ paddingBottom: size.height*0.1 }}>
           {/* 导航区域 */}
           <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.02}}>
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>监控管理</Breadcrumb.Item>
                    <Breadcrumb.Item>巨量监控</Breadcrumb.Item>
                </Breadcrumb>
            </div>  
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.02}}>
               <Row>
                    <Col style={{marginRight:'10px',fontWeight:'bold', lineHeight:'30px'}}>最低值</Col>
                    <Col><Input value={minLevel} style={{height:"30px"}} onChange={(e)=>{setMinLevel(e.target.value)}}  /></Col>
                   <Col style={{marginRight:'10px',fontWeight:'bold', lineHeight:'30px',marginLeft:'20px'}}>最高值</Col>
                   <Col><Input value={maxLevel} style={{height:"30px"}} onChange={(e)=>{setMaxLevel(e.target.value)}}  /></Col>
                   <Button style={{marginLeft:'20px',marginRight:'10px'}} onClick={()=>searchDate()}>查询</Button>
                   <Button style={{ display: jurisdiction.indexOf("jillion::excel") != -1  && date.length!=0 ? "" : "none" }} onClick={()=>getExcel()}>导出数据</Button>
               </Row>
            </div>  
            <div style={{marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06, display: search == 1 ? "" : "none"}}>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                />
            </div>

            
        </div>
    )
}

export default Jillion