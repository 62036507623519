import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Tag, Form, Select, Space, Collapse, Spin, DatePicker} from 'antd'
import {CloseCircleOutlined, SearchOutlined, DownloadOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import axios from 'axios'
import '../../static/css/Table.css'
import '../../static/css/index.css'
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment';

// 商品评论词云
function Ciyun(props) {
    // 折叠面板
    const { Panel } = Collapse;

    // 表单
    const [form] = Form.useForm();

    // 样式
    const style = { padding: '20px 20px 0 20px' }

    // input只写属性
    const [inputDisabledStyle, setInputDisabledStyle] = useState('')

    // 词云图样式
    const [baseImgDisplay, setBaseImgDisplay] = useState('none')

    const baseImgStyle = {width: '80%', height:'100%', display: baseImgDisplay}

    // 图片base64编码
    const [baseImg, setBaseImg] = useState('')

    // 查询条件 itemId
    const [itemId, setItemId] = useState('')

    // 设置表格加载状态
    const [isLoading, setIsLoading] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // 设置表格数据
    const [dataSource, setDataSource] = useState([])

    // 设置表格头
    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '词语',
            dataIndex: 'word',
            key: 'word',
        },
        {
            title: '词频',
            dataIndex: 'frequency',
            key: 'frequency',
        },
    ];

    // 获取词语词频
    const selectCiyun = () => {
        setIsLoading(true)
        setBaseImgDisplay('none')
        // 请求参数
        let dataProps = {
            "shopId": cookie.load('shopList'),
            "roleId": cookie.load('role'),
            "item_id": form.getFieldValue("itemId"),
        };
        console.log(dataProps)
        // 请求接口
        axios({
            method: 'POST',
            url: servicePath.getCiun,
            data: dataProps,
            header: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                setDataSource([])
                if (res.data.success) {
                    //获取数据成功
                    let data = res.data.data
                    // setBaseImg("data:image/jpg;base64," + data.base64)
                    setBaseImg('http://192.168.3.136:8888/imge_dir/wordcloud/' + data.itemId + '.png')
                    const wordList = JSON.parse(data.word)
                    const dataList = []
                    Object.keys(wordList).map((key,index) => {
                        dataList.push({
                            "id": index + 1,
                            "word": key,
                            "frequency": wordList[key]
                        })
                    });
                    setDataSource(dataList)
                    setBaseImgDisplay('')
                }
                else {
                    message.error(res.data.errorMsg)
                }
                setIsLoading(false)
            }
        ).catch(error => {
            setIsLoading(false)
        })
    }

    // 获取URL参数 iteam_id
    const getURLItemId = (name) => {
        // 取得url中?后面的字符
        var query = window.location.search.substring(1);
        // 把参数按&拆分成数组
        var param_arr = query.split("&");
        for (var i = 0; i < param_arr.length; i++) {
            var pair = param_arr[i].split("=");
            if (pair[0] == name) {
                return pair[1];
            }
        }
        return (false);
    }

    // 开始执行一次
    useEffect(() => {
        if (getURLItemId("item_id")){
            form.setFieldsValue({
                "itemId": getURLItemId("item_id")
            })
            setInputDisabledStyle("disabled")
            selectCiyun()
        }
        },[]
    )

    return (
        <>
            {/* 导航区域 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.02 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>BMC工具箱</Breadcrumb.Item>
                    <Breadcrumb.Item>插件爬虫</Breadcrumb.Item>
                    <Breadcrumb.Item>评论词云</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            {/* 查询条件 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06 }}>
                <div style={{backgroundColor: 'white'}}>
                    <Form
                        form={form}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        labelAlign='left'
                    >
                        <Row>
                            <Col span={6}>
                                <div style={style}>
                                    <Form.Item
                                        label="商品ID"
                                        name="itemId"
                                    >
                                        <Input
                                            disabled={inputDisabledStyle}
                                            onChange={(e) => {
                                                setItemId(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={4}>
                                <div style={style}>
                                    <Button type="primary" htmlType="submit" onClick={selectCiyun}>
                                        查询
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>

            </div>

            {/* 结果表 */}
            <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06, marginBottom: size.height * 0.1}}>
                <Spin
                    tip='数据请求中，请稍等...'
                    spinning={isLoading}
                >
                    <Row>
                        <Col span={14}>
                            <div style={{backgroundColor: 'white', padding: '20px 20px 20px 20px'}}>
                                <img src={baseImg} style={baseImgStyle}/>
                            </div>
                        </Col>
                        <Col span={2}>
                        </Col>
                        <Col span={8}>
                            <div style={{backgroundColor: 'white', padding: '20px 20px 20px 20px'}}>
                                <Table
                                    columns={columns}
                                    dataSource={dataSource}
                                    scroll={{ y: size.height*0.6 }}
                                    pagination={ false }
                                    bordered={ true}
                                />
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </>
    )
}

export default Ciyun