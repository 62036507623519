import React, { useState, useEffect } from 'react';
import { Form, Upload, Table, Tag, Input, Tooltip, Button, Modal, message, DatePicker, Drawer, Divider, Row, Col, Typography } from 'antd';
import { LeftOutlined, InboxOutlined } from '@ant-design/icons';
import NbAxios from '../../../config/utils/nbAxios';
import servicePath from '../../../config/apiUrl';
import downExcel from '../../../config/utils/downloadFile';
import cookie from 'react-cookies'
import axios from 'axios'
import locale from 'antd/lib/date-picker/locale/zh_CN';
// 商品ID
const { RangePicker } = DatePicker;
const { Title } = Typography
// 主页面函数
function Index(props) {
    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 16 },
    };
    // 表格填写数据获取
    const [form] = Form.useForm()

    const [commodityid, setCommodityId] = useState(1)
    // 需要操作停用或者恢复的商品行id
    const [caozuoid, setCaozuoId] = useState(1)
    //表格的列名
    const columns = [
        {
            title: '品牌',
            dataIndex: 'brand',
            key: 'brand',
            align: 'center',
            width: 100,
        },
        {
            title: '商品条形码',
            dataIndex: 'bar_code',
            key: 'bar_code',
            align: 'center',
            width: 110,

        },
        {
            title: '商品编码',
            dataIndex: 'commodity_code',
            key: 'commodity_code',
            align: 'center',
            width: 100,

        },
        {
            title: '商品名称',
            dataIndex: 'trade_name',
            key: 'trade_name',
            align: 'center',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '产品类型',
            dataIndex: 'product_type',
            key: 'product_type',
            align: 'center',
            width: 100,
        },
        {
            title: '规格',
            dataIndex: 'specifications',
            key: 'specifications',
            align: 'center',
            width: 100,

        },
        {
            title: '报价',
            dataIndex: 'offer',
            key: 'offer',
            align: 'center',
            width: 120,
            render: text => (
                <span>
                    {text != '' ? parseFloat(text).toFixed(4) : text}
                </span>
            )
        },
        {
            title: '品类',
            dataIndex: 'category',
            key: 'category',
            align: 'center',
            width: 100,

        },
        {
            title: '系列',
            dataIndex: 'series',
            key: 'series',
            align: 'center',
            width: 100,

        },
        {
            title: '更新时间',
            dataIndex: 'update_time',
            key: 'update_time',
            align: 'center',
            width: 120,
        },
        {
            title: '是否停用',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: 100,
            render: state => (
                <div>
                    {
                        state == '停用' ?
                            <Tag color={'red'} key={state}>
                                {state}
                            </Tag>
                            :
                            <Tag color={'blue'} key={state}>
                                {state}
                            </Tag>
                    }
                </div>
            ),
        },
        {
            title: '操作',
            dataIndex: 'chaozhuo',
            key: 'chaozhuo',
            align: 'center',
            width: 100,
            render: (text, record, index) => (
                // 参数分别为当前行的值，当前行数据，行索引
                <div style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "flex" : "none", justifyContent:'center'}}>
                    {record.state == "正常" ?
                        <a onClick={() => {
                            // 修改时保存原有值
                            form.setFieldsValue(record)
                            setIsChangeVisible(true)
                            setCommodityId(record.id)
                        }}>修改</a>
                        :
                        <span></span>
                    }

                </div>
            ),
        },
    ];
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    //  上传订单弹框
    const [isUploadVisible, setIsUploadVisible] = useState(false);
    const showUploadModal = () => {

        setIsUploadVisible(true);
    };
    // 搜索json
    const [searchJson, setSearchJson] = useState({})
    const handleUploadOk = () => {
        setIsUploadVisible(false);
    };

    const handleUploadCancel = () => {
        setIsUploadVisible(false);
    };
    //  修改订单弹框
    const [isChangeVisible, setIsChangeVisible] = useState(false);


    // 商品基础信息修改
    const modifiedcommodity = (values) => {
        values["id"] = commodityid
        values['roleId'] = cookie.load('role')
        values['shopList'] = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.modified_commodity).then(res => {
            if (res.success) {
                message.success('商品修改成功');
                findCommodity(searchJson)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    //搜索框
    const [visible, setVisible] = useState(false);
    const [value, setValue] = React.useState(1);
    //搜索框，在进入Drawer抽屉查询时，内容已经存在供应商名和工厂名
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    // 表格数据
    const [data, setData] = useState([])
    // 分页展示
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(100)
    useEffect(() => {
        findCommodity(searchJson)
    }, [page])
    useEffect(()=>{
        getJurisdiction()
    },[])
    // 获取所有角色/店铺对应的权限
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": ['82328e58-068b-4a27-9bd0-9d1649cb8e6e']
        }
        NbAxios(dataProps, "POST", servicePath.getRoleShopPorts).then(res => {
            if (res.success) {
                setJurisdiction(res.data)
            }
        })
    }
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    //多选
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    //商品详情查找接口
    const findCommodity = (value) => {
        value["page"] = page
        value['roleId'] = cookie.load('role')
        value['shopList'] = cookie.load('shopList')
        setSearchJson(value)
        NbAxios(value, "POST", servicePath.findCommodity).then(res => {
            if (res.success) {
                onClose()
                setTotal(res.total);
                setData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    //商品列表可整行选择
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            // setCaozuoId(selectedRows[0].id)
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRows(selectedRows)

        },
    };
    const Demo = () => {
        const [selectionType, setSelectionType] = useState < 'checkbox' | 'radio' > ('checkbox');
    }
    // 商品详情是否删除确认弹框
    function delect() {
        Modal.confirm({
            title: '',
            width: "25%",
            okText: '确定',
            cancelText: '取消',
            centered:true,
            onOk: () => {
                delectCommodity()
            },
            onClose: () => {

            },
            content: (
                <div> 是否删除该行信息？ </div>
            ),

        });
    }
    //商品详情删除接口
    const delectCommodity = () => {

        let obj = {
            id: selectedRowKeys,
            shopList:cookie.load('shopList'),
            roleId:cookie.load('role'),
        }

        NbAxios(obj, "POST", servicePath.delectCommodity).then(res => {
            if (res.success) {
                setTotal(res.total);
                setData(res.data)
                setSelectedRowKeys([])
                findCommodity(searchJson)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 预算目标文件
    const [baseFile, setBaseFile] = useState([])
    //商品详情上传接口
    // 文件上传组建
    const { Dragger } = Upload;
    // 是否上传文件
    const [isUpload, setIsUpload] = useState(false)
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    //  上传附件信息
    const uploadCommodity = () => {

        if (baseFile.length != 0) {
            setEffective(1)
            const formData = new FormData()
            // 多个图片
            baseFile.forEach((item) => {
                formData.append('commodity_file', item['originFileObj']);

            })
            formData.append('roleId', cookie.load('role'));
            formData.append('shopList', cookie.load('shopList'));
            NbAxios(formData, "POST", servicePath.uploadCommodity).then(res => {
                setEffective(0)
                if (res.success) {
                    setIsUploadVisible(false)
                    setBaseFile([]);
                    message.success('文件数据上传成功');
                    setIsUpload(false)
                    findCommodity(searchJson)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        } else {
            message.warn('请选择文件')
        }
    }
    // 预算目标文件上传
    const baseFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx', 'jpeg']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setBaseFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setBaseFile(fileList)
        }
    }
    //商品详情下载接口
    const downloadCommodity = () => {
        let data = searchJson
        // data.shopList = cookie.load('shopList')
        axios({
            data: data,
            method: 'POST',
            url: servicePath.downloadCommodity,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                downExcel(res)
            }
        ).catch(e => {
            message.error({ content: '文件制作出错，请联系it同事！' })
        })
    }
    // 商品详情是否停用确认弹框
    function deactivate() {
        Modal.confirm({
            title: '',
            width: "25%",
            okText: '确定',
            cancelText: '取消',
            centered:true,
            onOk: () => {
                deactivateCommodity()


            },
            onClose: () => {

            },
            content: (
                <div>是否停用该行信息？</div>
            ),

        });
    }
    //商品详情停用接口
    const deactivateCommodity = () => {
        let obj = {
            id: selectedRowKeys,
            shopList:cookie.load('shopList'),
            roleId:cookie.load('role'),
        }

        NbAxios(obj, "POST", servicePath.deactivateCommodity).then(res => {
            if (res.success) {
                setTotal(res.total);
                setData(res.data)
                setSelectedRowKeys([])
                findCommodity(searchJson)
            } else {
                message.warn(res.errorMsg)
            }
        })

    }
    // 商品详情是否恢复确认弹框
    function recovery() {
        Modal.confirm({
            title: '',
            width: "25%",
            okText: '确定',
            cancelText: '取消',
            centered:true,
            onOk: () => {
                recoveryCommodity()


            },
            onClose: () => {

            },
            content: (
                <div> 是否恢复该行信息？</div>
            ),

        });
    }
    //商品详情恢复接口
    const recoveryCommodity = () => {
        let obj = {
            id: selectedRowKeys,
            shopList:cookie.load('shopList'),
            roleId:cookie.load('role'),

        }
        NbAxios(obj, "POST", servicePath.recoveryCommodity).then(res => {
            if (res.success) {
                setTotal(res.total);
                setData(res.data)
                setSelectedRowKeys([])
                findCommodity(searchJson)
            } else {
                message.warn(res.errorMsg)
            }
        })

    }
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-around", marginBottom: 20, marginTop: 10, borderBottom: "1px solid #ccc" }}>
                <Title level={4}>商品管理</Title>
                <div style={{ display: "flex" }}>
                    <Button type="link" style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 && selectedRowKeys.length == 0 ? "" : "none"}}>
                        <a href={"https://pic.bmcsoft.cn/imge_dir/商品基础信息上传模版.xlsx"}>下载商品基础信息上传模版</a>
                    </Button>
                    <a href="https://pic.bmcsoft.cn/imge_dir/122717595311/122717595311.html" target="_Blank"><Button type="link">操作手册</Button></a>
                    <Button type="primary" style={{ marginRight: 20, display:selectedRowKeys.length == 0 ? "" : "none" }}
                        onClick={() => {
                            setVisible(true)
                        }}>
                        条件查询
                    </Button>
                    <Button type="primary" style={{ marginRight: 20, display:jurisdiction.indexOf("threepets::operationSMSData") != -1 && selectedRowKeys.length == 0 ? "flex" : "none"  }}
                        onClick={() => {                                                  
                            showUploadModal()
                        }}
                    >上传</Button>
                    {/* <Button danger type="primary" style={{marginRight:20, display:jurisdiction.indexOf("threepets::exportSMSData") != -1 && selectedRowKeys.length == 0 ? "flex" : "none"}}
                        onClick={()=>{
                            downloadCommodity()
                        }}
                    >下载</Button> */}
                    <Button danger type="primary" style={{marginRight:20}}
                        onClick={()=>{
                            downloadCommodity()
                        }}
                    >下载</Button>
                    <div style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 && selectedRowKeys.length != 0 ? "flex" : "none"}}>
                       
                        <Button danger type="primary" style={{ marginRight: 20 }}
                            onClick={() => {
                                delect()
                            }}
                        >删除</Button>
                        <Button type="primary" style={{ marginRight: 20 }}
                            onClick={() => {
                                deactivate()
                            }}
                        >停用</Button>
                        <Button type="primary" style={{ marginRight: 20 }}
                            onClick={() => {
                                recovery()
                            }}
                        >恢复</Button>
                    </div>
                </div>
            </div>
            <Table
                style={{marginRight:20, marginLeft:20}}
                rowSelection={rowSelection}
                bordered
                scroll={{ x: 900, y: 'calc(100vh - 250px)' }}
                columns={columns}
                dataSource={data}
                pagination={paginationProps}
                hideOnSinglePage={true}
                rowKey={'id'}
            />
            <Modal
                title="修改商品信息"
                visible={isChangeVisible}
                width={1000}
                onCancel={() => {
                    setIsChangeVisible(false)
                }}
                footer={[]}
            >
                <div>
                    {
                        <Form form={form} onFinish={modifiedcommodity}  {...layout} labelAlign='left'>
                            <Row >
                                <Col span={12}>
                                    <Form.Item label="品牌:" name="brand" size="large" style={{ width: 260 }}>
                                        <span type="secondary">{form.getFieldValue('brand')}</span>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="商品编码:" name="commodity_code" size="large" style={{ width: 260 }}>
                                        <span type="secondary">{form.getFieldValue('commodity_code')}</span>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="商品条形码:" name="bar_code" size="large" style={{ width: 260 }}>
                                        <span type="secondary">{form.getFieldValue('bar_code')}</span>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="报价:" name="offer" size="large" style={{ width: 260 }}>
                                        <span type="secondary">{form.getFieldValue('offer')}</span>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="商品名称:" name="trade_name" size="large" style={{ width: 260 }}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="产品类型:" name="product_type" size="large" style={{ width: 260 }}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="规格:" name="specifications" size="large" style={{ width: 260 }}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="品类:" name="category" size="large" style={{ width: 260 }}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="系列:" name="series" size="large" style={{ width: 260 }}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div style={{display:'flex', justifyContent:'space-around'}}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    onClick={() => {
                                        setIsChangeVisible(false)
                                        }
                                    }
                                >
                                    确定
                                </Button>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() =>
                                        setIsChangeVisible(false)
                                    }>
                                    取消
                                </Button>
                            </div>
                        </Form>
                    }
                </div>
            </Modal>
            <Modal
                title="上传订单"
                visible={isUploadVisible}
                centered={true}
                okText="确定"
                cancelText="取消"
                maskClosable={true}
                onOk={() => uploadCommodity()}
                onCancel={() => {
                    message.success('取消上传商品基础信息')
                    setBaseFile([])
                    setIsUploadVisible(false)
                }}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.findTrue}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={baseFile}
                    onChange={baseFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
            </Modal>
            <Drawer
                title="商品条件查询"
                placement="right"
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                width={720}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={findCommodity}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="brand"
                                label="品牌"
                            >
                                <Input placeholder="请输入品牌" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="commodity_code"
                                label="商品编码"
                            >
                                <Input placeholder="请输入商品编码" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="bar_code"
                                label="商品条形码"
                            >
                                <Input placeholder="请输入商品条形码" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="trade_name"
                                label="商品名称"
                            >
                                <Input placeholder="请输入商品名称" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="specifications"
                                label="规格"
                            >
                                <Input placeholder="请输入规格" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="offer"
                                label="报价"
                            >
                                <Input placeholder="请输入报价" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="product_type"
                                label="产品类型"
                            >
                                <Input placeholder="请输入产品类型" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="category"
                                label="品类"
                            >
                                <Input placeholder="请输入品类" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="series"
                                label="系列"
                            >
                                <Input placeholder="请输入系列" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="update_time"
                                label="更新时间"
                            >
                                <RangePicker locale={locale}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: 'space-around' }}>
                        <Button type="primary" htmlType="submit"
                            onClick={() => {
                                onClose()
                            }}>
                            查询
                        </Button>
                        <Button type="primary" onClick={() => {
                            //重置form
                            form.resetFields();
                            findCommodity({})
                        }}>
                            重置
                        </Button>
                    </div>
                </Form>
            </Drawer>
        </div>
    )
};


export default Index;



