import React, { useContext, useState } from 'react';
import { StateContext } from '../../raceShop/store/stateShare'
import { Button, Card, DatePicker, message } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import Axios from 'axios';
import servicePath from '../../../config/apiUrl'

function MarkRankReport(props) {

    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)

    // 获取屏幕尺寸
    const size = state.size

    // 行业数据日期
    const [markRankMonth, setMarkRankMonth] = useState()

    // 行业大盘数据日期
    const [markBorderMonth, setMarkBorderMonth] = useState()

    // 店铺名
    let cateory = props.location.pathname.split('/')[4]

    // 下载行业数据的方法
    function reportMarkRank() {
        if (markRankMonth === null || markRankMonth === undefined || markRankMonth === '') {
            message.warn('请选择月份')
            return false
        } else {
            let date = markRankMonth.format('YYYY-MM')
            window.open(servicePath.getMarkRankReport + "?cateory=" + cateory + "&date=" + date, '_blank')
        }
    }

    function reportMarkBorder() {
        if (markBorderMonth === null || markBorderMonth === undefined || markBorderMonth === '') {
            message.warn('请选择月份')
            return false
        } else {
            let date = markBorderMonth.format('YYYY-MM')
            window.open(servicePath.getMarkBorderReport + "?cateory=" + cateory + "&date=" + date, '_blank')
        }
    }

    // 市场排行月份变化调用的方法
    function changeMonth(date, dateString) {
        console.log(dateString);
        if (date !== null || date !== '') {
            setMarkRankMonth(date)
        }
    }

    // 市场大盘月份变化调用的方法
    function changeMarkBorderMonth(date, dateString) {
        console.log(dateString);
        if (date !== null || date !== '') {
            setMarkBorderMonth(date)
        }
    }

    return (
        <div style={{
            marginTop: size.height * 0.03,
            marginLeft: size.width * 0.04,
            paddingBottom: size.height * 0.1,
            display: 'flex',
        }}>
            <Card style={{ width: 400 }} title={`${cateory}市场店铺排行数据`} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <DatePicker
                        locale={locale}
                        disabledDate={disabledMonth}
                        onChange={changeMonth}
                        picker='month'
                        value={markRankMonth}
                    />
                    <Button type={'primary'} onClick={reportMarkRank}>
                        下载
                    </Button>
                </div>
            </Card>
            <Card style={{ width: 400, marginLeft: 20 }} title={`${cateory}行业大盘数据`} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <DatePicker
                        locale={locale}
                        disabledDate={disabledMonth}
                        onChange={changeMarkBorderMonth}
                        picker='month'
                        value={markBorderMonth}
                    />
                    <Button type={'primary'} onClick={reportMarkBorder}>
                        下载
                    </Button>
                </div>
            </Card>
        </div>
    )
    // 不可选的周
    function disabledDate(current) {
        // 不可选前一周
        return current && current > moment().endOf('day').subtract(1, 'day');
    }

    // 不可选的月
    function disabledMonth(current) {
        // 不可选前一周
        return current && current > moment().endOf('month').subtract(1, 'month');
    }
}

export default MarkRankReport