import React, { useState, useEffect } from 'react';
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl';
import {Button, message} from 'antd'

function Index(){

    const [ number , setNumber ] = useState('')

    useEffect(()=>{
        queryAnniversaryLuckDrawSignIn()
    },[])

    const queryAnniversaryLuckDrawSignIn = ()=>{
        NbAxios({}, "POST", servicePath.queryAnniversaryLuckDrawSignIn).then(res => {
            if (res.success) {
                setNumber(res.data.number);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            message.warn('签到接口出错,请联系IT')
        })
    }

    return (
        <div style={{width:"100vw", height:"100vh",display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <div>
                <b>当前签到人数:</b>
                <text>{number}</text>
            </div>
            <div style={{marginTop:"5px"}}>
                <Button onClick={()=>{
                    queryAnniversaryLuckDrawSignIn()
                }}>重新计算当前签到人数</Button>
            </div>
        </div>
    )

}

export default Index