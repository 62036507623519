import React, {useState, useEffect} from 'react';
import {Form, DatePicker, Select, message, Typography, Divider, Empty, Tag} from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from "moment"
import cookie from 'react-cookies'
import NbAxios from '../../../config/utils/nbAxios'
import servicePath from '../../../config/apiUrl'
import TitleCard from './components/titleCard'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

const {CheckableTag} = Tag
const {RangePicker} = DatePicker;
const {Option} = Select;
const {Title} = Typography;

function Index(props) {
    // 不可选日期
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }

    // 日期格式
    const dateFormat = 'YYYY-MM-DD'
    // 搜索板块
    const [plate, setPlate] = useState('')
    const [plateList, setPlateList] = useState([])
    // 年份
    const [year, setYear] = useState(moment().format('YYYY'))
    // 季度
    const [quarterly, setQuarterly] = useState(moment().quarter())
    // 开始日期
    const [startDate, setStartDate] = useState(moment().startOf('month'))
    // 结束日期
    const [endDate, setEndDate] = useState(moment().endOf('month'))
    // 月份
    const [month, setMonth] = useState(moment().startOf('month').format('MM'))
    // 月数据
    const [monthData, setMonthData] = useState([])
    // 季度数据
    const [quarterlyData, setQuarterlyData] = useState([])
    // 年数据
    const [yearData, setYearData] = useState([])
    // 实销数据
    const [saleSeries, setSaleSeries] = useState([])
    // 完成率数据
    const [completionSeries, setCompletionSeries] = useState([])
    // 图例
    const [legend, setLegend] = useState([])
    useEffect(() => {
        if (props.history.location.pathname.split('/')[3] == 'standard') {
            setPlate('标品事业部')
            queryDivisionPlate('标品事业部')
        } else if (props.history.location.pathname.split('/')[3] == 'unstandard') {
            setPlate('非标事业部')
            queryDivisionPlate('非标事业部')
        }
        // queryDivisionPlate()
    }, [])

    // 监听日期变化
    useEffect(() => {

        // 查询每个月板块下面的各个项目业绩汇总
        if (plate == '标品事业部' || plate == '非标事业部') {
            getMonthDivisionSalesSummary()
        } else {
            getMonthPlateSalesSummary()
        }
        // 修改年份
        setYear(startDate.format('YYYY'));
    }, [startDate, endDate, plate])

    // 监听月份
    useEffect(() => {
        setQuarterly(Math.floor((month % 3 == 0 ? (month / 3) : (month / 3 + 1))));

    }, [month])

    // 监听季度变化
    useEffect(() => {
        // 计算季度结束日期
        let endMonth = 3 * parseInt(quarterly) //当季度最后一个月
        /* 对月数进行格式化 */
        if (endMonth < 10)
            endMonth = '0' + endMonth
        else
            endMonth += ''
        let endMonthDays = moment(year + '-' + endMonth).daysInMonth(); // 末尾月天数
        let endDays = year + '-' + endMonth + '-' + endMonthDays //完整年月日整合
        if (plate == '标品事业部' || plate == '非标事业部') {
            let dataProps = {
                "start_date": moment(moment(year + '-01-01').toDate()).quarter(quarterly).format('YYYY-MM-DD'),
                "end_date": endDays,
                "division": plate,
                "shop_id": cookie.load('shopList'),
                "role_id": cookie.load('role'),
            }
            NbAxios(dataProps, "POST", servicePath.queryYearQuarterlyDivisionSalesSummary).then(res => {
                if (res.success) {
                    setQuarterlyData(res.data);
                } else {
                    message.warn(res.errorMsg)
                }
            })
        } else {
            let dataProps = {
                "start_date": moment(moment(year + '-01-01').toDate()).quarter(quarterly).format('YYYY-MM-DD'),
                "end_date": endDays,
                "plate": plate,
                "shop_id": cookie.load('shopList'),
                "role_id": cookie.load('role'),
            }
            NbAxios(dataProps, "POST", servicePath.queryYearQuarterlyPlateSalesSummary).then(res => {
                if (res.success) {
                    setQuarterlyData(res.data);
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }, [quarterly, plate])

    // 监听年份变化
    useEffect(() => {
        if (year != '') {
            if (plate != '') {
                if (plate == '标品事业部' || plate == '非标事业部') {
                    let dataProps = {
                        "start_date": moment(year).startOf('year').format('YYYY-MM-DD'),
                        "end_date": moment(year).endOf('year').format('YYYY-MM-DD'),
                        "division": plate,
                        "shop_id": cookie.load('shopList'),
                        "role_id": cookie.load('role'),
                    }
                    NbAxios(dataProps, "POST", servicePath.queryYearQuarterlyDivisionSalesSummary).then(res => {
                        if (res.success) {
                            setYearData(res.data);
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                    NbAxios(dataProps, "POST", servicePath.queryYearDivisionSalesSummaryEchart).then(res => {
                        if (res.success) {
                            setSaleSeries(res.data['sale_series_data']);
                            setCompletionSeries(res.data['completion_series_data']);
                            setLegend(res.data['legend']);
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                } else {
                    let dataProps = {
                        "start_date": moment(year).startOf('year').format('YYYY-MM-DD'),
                        "end_date": moment(year).endOf('year').format('YYYY-MM-DD'),
                        "plate": plate,
                        "shop_id": cookie.load('shopList'),
                        "role_id": cookie.load('role'),
                    }
                    NbAxios(dataProps, "POST", servicePath.queryYearQuarterlyPlateSalesSummary).then(res => {
                        if (res.success) {
                            setYearData(res.data);
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                    NbAxios(dataProps, "POST", servicePath.queryYearPlateSalesSummaryEchart).then(res => {
                        if (res.success) {
                            setSaleSeries(res.data['sale_series_data']);
                            setCompletionSeries(res.data['completion_series_data']);
                            setLegend(res.data['legend']);
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }

            }
        }
    }, [year, plate])

    // 获取事业部、板块
    const queryDivisionPlate = (division) => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "division": division
        }
        NbAxios(dataProps, "POST", servicePath.queryDivisionPlateByDivision).then(res => {
            if (res.success) {
                setPlateList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 表格处理
    // 实销表格
    let salesReport = {
        title: {
            text: `${plate}板块实销数据汇总`,
            textStyle: {
                fontSize: 14
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: {show: false, readOnly: false},
                restore: {show: false},
                saveAsImage: {show: false}
            }
        },
        legend: {
            data: legend
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: [`${year}-01`, `${year}-02`, `${year}-03`, `${year}-04`, `${year}-05`, `${year}-06`, `${year}-07`, `${year}-08`, `${year}-09`, `${year}-10`, `${year}-11`, `${year}-12`]
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '实销金额/万元',
                position: 'left',
                offset: 0,
                axisLabel: {
                    formatter: function (data) {
                        return (data / 10000);
                    }
                },
                axisLine: {
                    show: true,
                    onZero: true
                },
            }
        ],
        series: saleSeries
    }

    let completionReport = {
        title: {
            text: `${plate}板块完成率汇总`,
            textStyle: {
                fontSize: 14
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: {show: false, readOnly: false},
                restore: {show: false},
                saveAsImage: {show: false}
            }
        },
        legend: {
            data: legend
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: [`${year}-01`, `${year}-02`, `${year}-03`, `${year}-04`, `${year}-05`, `${year}-06`, `${year}-07`, `${year}-08`, `${year}-09`, `${year}-10`, `${year}-11`, `${year}-12`]
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '完成率%',
                position: 'left',
                offset: 0,
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    show: true,
                    onZero: true
                },
            },
        ],
        series: completionSeries
    }

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('salesReport'));
        reportChart.clear();    //清除残留渲染
        // 绘制图表
        reportChart.setOption(salesReport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [legend])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('completionReport'));
        reportChart.clear();    //清除残留渲染
        // 绘制图表
        reportChart.setOption(completionReport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [legend])

    //月份改变
    function monthChange(date, dateString) {
        if (date === null || date === '' || date === undefined) {
            message.warn('请选择正确的月份')
            return false
        }
        setStartDate(moment(dateString).startOf('month'));
        setEndDate(moment(dateString).endOf('month'));
        setMonth(moment(dateString).startOf('month').format('MM'))
    }

    //查询每个月事业部下面的各个项目业绩汇总
    const getMonthDivisionSalesSummary = () => {
        let dataProps = {
            "shop_id": cookie.load('shopList'),
            "role_id": cookie.load('role'),
            "start_date": startDate.format('YYYY-MM-DD'),
            "end_date": endDate.format('YYYY-MM-DD'),
            "division": plate
        }
        NbAxios(dataProps, "POST", servicePath.queryMonthDivisionSalesSummary).then(res => {
            if (res.success) {
                setMonthData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 查询每个月板块下面的各个项目业绩汇总
    const getMonthPlateSalesSummary = () => {
        if (plate == '') {
            // message.warn("请选择板块")
        } else {
            let dataProps = {
                "shop_id": cookie.load('shopList'),
                "role_id": cookie.load('role'),
                "start_date": startDate.format('YYYY-MM-DD'),
                "end_date": endDate.format('YYYY-MM-DD'),
                "plate": plate
            }
            NbAxios(dataProps, "POST", servicePath.queryMonthPlateSalesSummary).then(res => {
                if (res.success) {
                    setMonthData(res.data);
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }
    // 范围选择
    const rangeHandleChange = (tag, checked) => {
        setPlate(tag)
    }


    return (
        <div style={{marginLeft: 30, marginTop: 20}}>
            <div>
                <Form style={{display: 'flex'}}>
                    <Form.Item label="时间">
                        <DatePicker
                            disabledDate={disabledDate}
                            locale={locale}
                            picker="month"
                            onChange={monthChange}
                            defaultValue={moment(startDate, dateFormat)}
                        />
                    </Form.Item>
                    <Form.Item label="事业部/板块" style={{marginLeft: 30}}>
                        {plateList.map(tag => (
                            <CheckableTag
                                // style={{lineHeight:3, fontSize:"14px"}}
                                key={tag}
                                checked={plate.indexOf(tag) > -1}
                                onChange={checked => rangeHandleChange(tag, checked)}
                            >
                                {tag}
                            </CheckableTag>
                        ))}
                    </Form.Item>
                </Form>
            </div>
            <Divider orientation="left">{month}月单月实销业绩与完成率</Divider>
            <div style={{display: 'flex'}}>
                <div style={{width: '90vw', display: "flex", alignItems: 'center', flexWrap:'wrap'}}>
                    {
                        monthData.length == 0 ?
                            <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}><Empty/></div> :
                            monthData.map(item => {
                                return <TitleCard
                                    project={item.project}
                                    sum={item.sale}
                                    target={item.goal_sale}
                                    finshRate={item.completion_rate}
                                    date={item.date}
                                />
                            })
                    }
                </div>
            </div>
            <Divider orientation="left">{quarterly}季度累计实销业绩与完成率</Divider>
            <div style={{width: '90vw', display: "flex", alignItems: 'center', flexWrap:'wrap'}}>
                {
                    quarterlyData.length == 0 ?
                        <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}><Empty/></div> :
                        quarterlyData.map(item => {
                            return <TitleCard
                                project={item.project}
                                sum={item.sale}
                                target={item.goal_sale}
                                finshRate={item.completion_rate}
                            />
                        })
                }
            </div>
            <Divider orientation="left">{year}年累计实销业绩与完成率</Divider>
            <div style={{display: 'flex'}}>
                <div style={{width: '90vw', display: "flex", alignItems: 'center', flexWrap:'wrap'}}>
                    {
                        yearData.length == 0 ?
                            <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}><Empty/></div> :
                            yearData.map(item => {
                                return <TitleCard
                                    project={item.project}
                                    sum={item.sale}
                                    target={item.goal_sale}
                                    finshRate={item.completion_rate}
                                />
                            })
                    }
                </div>
            </div>
            {/* display:'flex', justifyContent:'center' */}
            <Divider></Divider>
            <div style={{display: "flex"}}>
                <div id="salesReport" style={{width: '45vw', height: '38vh'}}></div>
                <div id="completionReport" style={{width: '49vw', height: '38vh'}}></div>
            </div>
        </div>
    )

}

export default Index