import React, { useEffect, useState } from 'react';
import { Input, Typography, Checkbox, message, Modal, Button, Spin, Table, Select, Upload, Tooltip,Tag } from 'antd'
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl';
import NbAxios from '../../config/utils/nbAxios';
import axios from 'axios'
import webUrl from '../../config/webUrl';
import AcceptBuried from '../../config/utils/buried';
import style from './index.module.css'
import { set } from 'lodash';
const { Title, Text,Paragraph } = Typography;
const { Option } = Select
function Initial(props) {

    return (
        <div className={style.initial}>
            <div className={style.externalContent}>
                <div className={style.dis} style={{fontSize:'50px'}}>Pos系统</div>
                <div className={style.dis}>
                    <Button style={{fontSize:'30px',width:"300px",height:'150px',textAlign:'center',marginRight:"200px"}} 
                        onClick={()=>props.history.push({'pathname':`/app/pos/create` })}>创建订单</Button>
                    <Button style={{fontSize:'30px',width:"300px",height:'150px',textAlign:'center'}} 
                        onClick={()=>props.history.push({'pathname':`/app/pos/chargeBack` })}>创建退货单</Button>
                </div>
                <div className={style.dis}>
                    <Button style={{fontSize:'30px',width:"300px",height:'150px',textAlign:'center'}} 
                            onClick={()=>props.history.push({'pathname':`/app/pos/gift` })}>赠品中心</Button>
                </div>
            </div>
        </div>
    )
}

export default Initial