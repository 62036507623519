import React, { useState, useEffect, useCallback } from 'react';
import { Select, Form, Modal ,TimePicker, Checkbox, Popconfirm, Breadcrumb, Table, Statistic, DatePicker, Input, Radio, Tag, Button, Row, Col, message, Tooltip, Rate, Divider, Cascader, Transfer } from 'antd';
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import difference from 'lodash/difference'
import Base64 from 'base-64'
import moment from 'moment'
import '../../static/css/meeting.css'
import '../../static/css/index.css'
import { LockOutlined, CheckCircleOutlined, StarFilled } from '@ant-design/icons';
import Axios from 'axios';
import locale from 'antd/es/date-picker/locale/zh_CN';
const { TextArea } = Input
const { RangePicker } = TimePicker;
const { Option } = Select
const { Countdown } = Statistic;
function Meeting(props) {
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [form2] = Form.useForm()
    const [form3] = Form.useForm()
    const [form4] = Form.useForm()
    const dateFormat = 'HH:mm'
    //以下为查询部分的参数
    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const layout2 = {
        labelCol: { span: 7 },
        wrapperCol: { span: 16 },
    };

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
    }


    // 自定义表转移
    const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
        <Transfer {...restProps} showSelectAll={false}>
            {({
                direction,
                filteredItems,
                onItemSelectAll,
                onItemSelect,
                selectedKeys: listSelectedKeys,
                disabled: listDisabled,
            }) => {
                const columns = direction === 'left' ? leftColumns : rightColumns;

                const rowSelection = {
                    getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows
                            .filter(item => !item.disabled)
                            .map(({ key }) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, listSelectedKeys)
                            : difference(listSelectedKeys, treeSelectedKeys);
                        onItemSelectAll(diffKeys, selected);
                    },
                    onSelect({ key }, selected) {
                        onItemSelect(key, selected);
                    },
                    selectedRowKeys: listSelectedKeys,
                };

                return (
                    <Table
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        pagination={paginationProps}
                        style={{ pointerEvents: listDisabled ? 'none' : null }}
                        onRow={({ key, disabled: itemDisabled }) => ({
                            style: {
                                // 创建人不可选
                                pointerEvents: key == cookie.load('id') ? 'none' : ''
                            },
                            onClick: () => {
                                if (itemDisabled || listDisabled) return;
                                onItemSelect(key, !listSelectedKeys.includes(key));
                            },
                        })}
                    />
                );
            }}
        </Transfer>
    );

    // 会议室条件参数
    const options = [
        { label: '白板', value: 'white_board' },
        { label: '投影', value: 'projection' },
        { label: '电视', value: 'tv' }
    ];

    // 会议室条件字典
    const optionDict = {
        'white_board': '白板',
        'projection': '投影',
        'tv': '电视'
    }
    // 查询的会议室数据
    const [searchRoomDate, setSearchRoomDate] = useState([])
    // 倒计时牌
    const [countDown, setCountDown] = useState(1)
    // 地区
    const [area, setArea] = useState('')
    // 人数
    const [peopleNumber, setPeopleNumber] = useState('')
    // 会议室类型
    const [meetingType, setMeetingType] = useState('')
    // 条件
    const [conditions, setConditions] = useState([])
    // 查询id
    const [searchId, setSearchId] = useState('')
    // 查询的会议室Id
    const [searchMeetingRoomId, setSearchMeetingRoomId] = useState('')
    // 查询的会议室
    const [searchMeetingRoom, setSearchMeetingRoom] = useState('')
    // 预约会议室
    const [addMeeting, setAddMeeting] = useState(false)
    // 是否需要果盘
    const [compote, setCompote] = useState(0)
    // 是否需要水瓶
    const [water, setWater] = useState(0)
    // 会议室的果盘
    const [meetingCompote, setMeetingCompote] = useState(0)
    // 会议室的水瓶
    const [meetingWater, setMeetingWater] = useState(0)
    // moment().add(1, 'm').locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')
    // 倒计时时间
    const [countTime, setCountTime] = useState('')
    // 会议室类型
    const [meetingTypeList, setMeetingTypeList] = useState([])
    // 会议室详情数据
    const [meetingDetailDate, setMeetingDetailDate] = useState([])
    // 查看会议室详情
    const [meetingDetail, setMeetingDetail] = useState(false)
    // 确认预约招待会议室
    const [reminder, setReminder] = useState(false)
    // 确认智能预约招待会议室
    const [smartReminder, setSmartReminder] = useState(false)

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 全体员工
    const [employeeList, setEmployeeList] = useState([])


    // 参与会议室人的id
    const [meetingPeopleIdList, setMeetingPeopleIdList] = useState([])

    // 参与会议室人的名字
    const [meetingPeopleNameList, setMeetingPeopleNameList] = useState([])

    // 参与会议室人的邮箱
    const [meetingPeopleEmailList, setMeetingPeopleEmailList] = useState([])

    //定义查询的会议室
    const [searchRoomId, setsearchRoomId] = useState()
    // 定义设定会议室级联选择器value的参数
    const [cascander, setCascander] = useState([])
    // 定义查询时的日期（每周星期一的日期）
    const [searchDate, setSearchDate] = useState(moment().format('YYYY-MM-DD'))
    // 定义查询的会议类型,是否为我参与的会议
    const [myMeeting, setMyMeeting] = useState(false)
    // 定义选择查看的会议类型，我的会议或者所有会议
    const [searchMeetingType, setSearchMeetingType] = useState('所有会议')
    // 当前周所有会议详情
    const [allDeatils, setAllDetails] = useState()

    // 以下为预约部分的参数
    // 定义是否为预约的参数，分为预约和修改两种情况
    const [detail, setdetail] = useState(false)
    // 定义会议室的预约人工号
    const [employeeId, setEmployeeId] = useState(Base64.decode(cookie.load('number')))
    // 定义会议室的预约人姓名
    const [name, setName] = useState(cookie.load('name'))
    // 定义预约的会议室id
    const [selectRoom, setSelectRoom] = useState('')
    // 定义预约的会议日期
    const [meetingDate, setMeetingDate] = useState(moment().format('YYYY-MM-DD'))
    // 定义不可选择的日期
    const [disableDate, setDisableDate] = useState()
    // 定义预约会议的开始时间
    const [startTime, setStartTime] = useState()
    // 定义预约会议的结束时间
    const [endTime, setEndTime] = useState()
    // 显示的预约会议的开始时间
    const [showStartedTime, setShowStartedTime] = useState()
    // 显示的预约会议的结束时间
    const [showEndTime, setShowEndTime] = useState()
    // 定义会议主题信息
    const [meetingTheme, setMeetingTheme] = useState('')
    // 定义会议内容信息
    const [meetingContent, setMeetingContent] = useState('')
    // 定义会议的参会人员
    const [meetingMembers, setMeetingMembers] = useState()
    // 定义预约对话框是否弹出的参数
    const [order, setOrder] = useState(false)
    // 定义所有人员的数组
    const [allPeople, setAllPeople] = useState([])
    // 默认不可选择的时间
    const [disableHour, setDisableHour] = useState([1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23, 0])
    //定义会议室的数组
    const [meettingRoom, setMeettingRoom] = useState([])
    // 定义会议室的地址
    const [meetingPlace, setMeetingPlace] = useState([
        {
            id: '嘉兴',
            name: '嘉兴'
        },
        {
            id: '杭州',
            name: '杭州'
        }
    ])
    // 选择的会议室地址
    const [chooseMeetingPlace, setChooseMeetingPlace] = useState('嘉兴')
    // 控制预约会议，参会人处提醒气泡的参数 
    const [tip, setTip] = useState(true)

    // 会议详情部分的参数

    // 单元格会议室id
    const [meetingId, setMeetingId] = useState([])
    // 会议创建人的姓名
    const [createManName, setCreateManName] = useState('')
    // 控制查看会议详情时判断选页面渲染结果的参数
    const [createMan, setCreateMan] = useState(false)
    // 会议室名称
    const [roomName, setRoomName] = useState('')
    // 取消预约的会议室id
    const [deleteRoomId, setDeleteRoomId] = useState('')
    // 不可预约的时间集合
    const [disChooseTime, setDisChooseTime] = useState([1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23, 0])

    // 条件查询
    const [smartOrder, setSmartOrder] = useState(false)

    // 智能查询会议室
    const [smartMeeting, setSmartMeeting] = useState(false)

    // 选择会议室参与人
    const [chooseMeetingPeople, setChooseMeetingPeople] = useState(false)

    // 站内信集合
    const [letterIdList, setLetterIdList] = useState('')

    // 左边表格标题
    const leftTableColumns = [
        {
            title: '可选择个人',
            children: [
                {
                    dataIndex: 'name',
                    key: 'name',
                    title: '名字',
                    width: '50px',
                },
                {
                    dataIndex: 'department',
                    key: 'department',
                    title: '部门',
                    width: '50px',
                },
                {
                    dataIndex: 'email',
                    key: 'email',
                    title: '邮箱',
                    width: '50px',
                }
            ]
        }
    ];

    // 右边表格标题
    const rightTableColumns = [
        {
            title: '已存在个人',
            children: [
                {
                    dataIndex: 'name',
                    key: 'name',
                    title: '名字',
                    width: '50px',
                },
                {
                    dataIndex: 'department',
                    key: 'department',
                    title: '部门',
                    width: '50px',
                },
                {
                    dataIndex: 'email',
                    key: 'email',
                    title: '邮箱',
                    width: '50px',
                }
            ]
        }
    ];


    // 获取当前的时间
    useEffect(() => {
        // 日期改变的时候，时间也变成默认值
        form1.resetFields()
        setStartTime(moment().add(1, 'hour').format('HH:00'))
        setEndTime(moment().add(1, 'hour').format('HH:30'))
        let now_date = new Date()
        var now_day = moment().locale('zh-cn').format('YYYY-MM-DD');
        // 判断是不是今天
        if (meetingDate == now_day) {
            // 要是今天的话,禁止选的时间点加上已经过去的时间点
            let now_hour = parseInt(now_date.getHours())
            for (let i = 9; i <= now_hour; i++) {
                disChooseTime.push(i)
            }
        } else {
            setDisChooseTime([1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23, 0])
        }

    }, [meetingDate])

    // 获取全体人员
    useEffect(() => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        Axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryPMSysUser,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setEmployeeList(res.data.data)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }, [])

    // 会议室类型修改的时候
    useEffect(() => {
        form3.resetFields()
        setWater(0)
        setCompote(0)
    }, [meetingType])

    // 会议室改变的时候
    useEffect(() => {
        setMeetingType('')
        form4.resetFields()
        let jiaxing_room = ['6bc57a88-fd0e-42a4-a94a-91035e869d0e',
            '84ad11fb-efcf-4b1a-a4d9-2611814d4569', '9561b94f-92b8-42c0-919f-46dd524f9d42',
            'b644f125-3ed9-4dc9-a727-72bff33bed2f', '1da3bc6a-e880-4679-a2d6-87be1d4e3c89',
            '21b430a2-8bdf-4f0a-9057-8faadca8cedd', '22b430a2-8bdf-4f0a-9057-8faadca8cedd',
            '3ff6d85a-d32b-4328-8f14-3dd8275d970c', '428c9b6e-9885-44c7-bfec-6b0d0451359d']
        if (jiaxing_room.indexOf(selectRoom) != -1) {
            setMeetingTypeList(['培训', '招待', '活动', '普通'])
        } else {
            setMeetingTypeList(['培训', '活动', '普通'])
        }

    }, [selectRoom])

    // 取消预约
    const cancleOrder = () => {
        form.resetFields()
        form1.resetFields()
        form4.resetFields()
        form3.resetFields()
        setPeopleNumber('')
        setMeetingType('')
        setCompote(0)
        setSelectRoom('')
        setMeetingTheme('')
        setMeetingContent('')
        setCompote('')
        setWater('')
        setMeetingPeopleIdList([])
        setMeetingPeopleNameList([])
        setMeetingPeopleEmailList([])
        // setMeetingDate(moment().format('YYYY-MM-DD'))
        cookie.remove('meeting_time', { path: '/' })
        cookie.remove('meeting_place', { path: '/' })
        setOrder(false)
    }

    // 定义表单格式的参数,标签占左边5个格栅，录入占右边16个格栅
    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    }

    //获取屏幕的长宽
    const [size, setSize] = useState({
        width: document.body.clientWidth,
        height: document.body.clientHeight
    })

    // 颜色库
    const colors = [
        // 蓝色
        '#4facfe',
        // 粉色
        '#fa709a',
        '#fda085',
        // 橘色
        '#7ac5d8',
        // 淡紫
        '#bbc1bf',
        '#002c53',
        '#ffa510',
        '#0c84c6',
        '#ffbd66',
        '#f74d4d',
        '#2455a4',
        '#41b7ac',
    ]

    //设置屏幕大小的方法 useCallback可以缓存方法  useMemo缓存变量  
    const onResize = useCallback(() => {
        setSize({
            width: document.body.clientWidth,
            height: document.body.clientHeight
        })
    }, [])

    //设置为空数组，代表login界面销毁时（也就是不加载时），才return取消window监听事件。只有在login组件刚加载时，才生效，组件销毁时失效
    useEffect(() => {
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    // 控制单元格表头样式，去掉padding文字居左
    const onHeaderCell = (record, rowIndex) => {
        return (
            {
                style: {
                    padding: '0',
                    height: '70px',
                    background: '#ffffff',
                }
            }
        )
    }
    // 所有会议信息
    const [dataSource, setDataSource] = useState([
    ])

    // 对表格数据深拷贝，防止修改操作影响表格数据
    const dataSource1 = JSON.parse(JSON.stringify(dataSource))
    // 删除拷贝对象中的key和time属性
    dataSource1.map((item, index) => {
        delete item.region
        delete item.name
        delete item.meeting_room_uuid
    })

    // 定义获取对象所有value的方法,通过该方法获取所有会议id
    const getObjectValue = (obj) => {
        let values = []
        for (let b = 0; b < obj.length; b++) {
            for (let a in obj[b]) {
                if (!values.includes(obj[b][a])) { values.push(obj[b][a]) }
            }
        }
        return values
    }

    // 控制表格颜色数量的参数
    const [numOfColors, setNumOfColors] = useState(2)

    // 配对会议id和颜色产生对象，arr1为会议id数组，arr2为颜色数组
    const getMeetingColor = (idArr, colorArr) => {
        // 声明新对象
        let MeetingColor = {}
        // 循环id配对色值
        for (let i = 0; i < idArr.length; i++) {
            if (idArr[i] !== "") {
                MeetingColor[idArr[i]] = colorArr[i % numOfColors]
            }
        }
        return MeetingColor
    }

    // 定义存储会议id和颜色值的常量
    let meetingColor = getMeetingColor(getObjectValue(dataSource1), colors)

    // 定义所有的会议时间段,动态生成时间短表头
    const [timeRange, setTimeRange] = useState([
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
    ])

    // 根据定义的时间段循环生成表头
    const columns = [
        {
            title: '办公地点',
            dataIndex: 'region',
            key: 'region',
            width: 80,
            align: 'center',
            fixed: 'left',
            onHeaderCell: onHeaderCell,
            render:(text, record, index)=>{
                return (
                    <Tooltip title={text}>
                        {text}
                    </Tooltip>
                )
            }
        },
        {
            title: '会议室',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: 80,
            fixed: 'left',
            onHeaderCell: onHeaderCell,
            render:(text, record, index)=>{
                return (
                    <Tooltip title={text}>
                        {text}
                    </Tooltip>
                )
            }
        },
        // ...解构map返回的所有对象
        ...timeRange.map((item, index) => {
            return (
                {
                    title: item,
                    dataIndex: item,
                    key: item,
                    // align:'center',
                    // width: '3vw',
                    onCell: (record, rowIndex) => {
                        let meeting_id = record[item]
                        const color = meeting_id !== '' ? meetingColor[meeting_id] : item === '18:00' ? '#eee' : null
                        return (
                            {
                                style: {
                                    position: 'relative',
                                    padding: '0',
                                    height: '1vh',
                                    background: color,
                                    // 加上index
                                    zIndex: meeting_id !== '' || item === '18:00' ? 1000 : 1
                                },
                                onClick: event => {
                                    if (record[item]) {
                                        setMeetingId(record[item])
                                        showMeeting(record[item])
                                    } else {
                                        if (item !== '18:00') {
                                            cookie.save('meeting_place', { 'place': record['region'], 'room': record['name'], 'room_id': record['meeting_room_uuid'] }, { path: '/' })
                                            getCell(item, rowIndex)
                                        }
                                    }
                                },
                            }
                        )
                    },
                    onHeaderCell: onHeaderCell,
                    render: (text, record, index) => {
                        let color = item == '18:00' ? '#eee' : null
                        return (
                            <div className={'meeting-table-cell'} style={{
                                width: '100%', height: '100%',
                                background: `${color}`
                            }}></div>
                        )
                    },
                }
            )
        })
    ]

    // 用户点击表格索引的数组
    let clickCellArr = []

    // 用户已选择的会议室行索引数组
    let clickRoomIndex = []

    /**
     * 用户点击的所有单元格
     * @param {string} clickKey 点击单元格的key
     * @param {number} rowIndex 点击单元格的行索引
     */
    function getCell(clickKey, rowIndex) {
        // 点击单元格在每行中的位置
        let columnIndex = timeRange.indexOf(clickKey)
        // 单元格一行的长度
        let rowLength = timeRange.length
        // 获取所点击的单元格在单元格集中的位置
        let cellIndex = rowIndex * rowLength + columnIndex
        // 
        clickRoomIndex.push(rowIndex)
        // 判断用户是否换会议室预约的操作（点击新的一行）
        if (clickRoomIndex.length === 2) {
            if (clickRoomIndex[0] !== clickRoomIndex[1]) {
                // 换行操作
                clickRoomIndex.shift(clickRoomIndex[0])
                // 清楚原有单元格颜色
                clickCellArr.length = 0
                // 加入新点击的单元格index
                clickCellArr.push(cellIndex)
                changCell(clickCellArr)
            } else {
                // 不换行的操作
                clickRoomIndex.shift(clickRoomIndex[0])
                // 把最新点击的单元格加入数组
                if (clickCellArr.indexOf(cellIndex) !== -1) {
                    // 点击了相同单元格，不做任何操作
                    if (clickCellArr[0] + 1 === clickCellArr[1]) {
                        // clearCell(clickCellArr)
                        clickCellArr = [cellIndex]
                        changCell(clickCellArr)
                    } else {
                        return false
                    }
                }
                clickCellArr.push(cellIndex)
                if (clickCellArr.length === 1) {
                    // 如果第一次点击，变色
                    changCell(clickCellArr)
                } else {
                    // 对数组进行排序
                    if (clickCellArr.length === 2) {
                        clickCellArr.sort((a, b) => a - b)
                        changCell(clickCellArr)
                    } else if (clickCellArr.length === 3) {
                        clickCellArr.sort((a, b) => a - b)
                        if (clickCellArr.indexOf(cellIndex) === 1) {
                            // 如果新点击单元格在两者中间，清楚原有颜色
                            // clearCell(clickCellArr)
                            // 去除末尾单元格
                            clickCellArr.pop()
                            changCell(clickCellArr)
                        } else {
                            // clearCell(clickCellArr)
                            // 如果新点击单元格在两者中间，去除末尾单元格
                            clickCellArr.splice(1, 1)
                            changCell(clickCellArr)
                        }
                    }
                }
            }
        } else {
            clickCellArr.push(cellIndex)
            changCell(clickCellArr)
        }
    }

    /**
     * 改变单元格颜色
     * @param {list} arr 单元格起始数组
     */
    function changCell(arr) {
        let meetingTableCell = document.querySelectorAll('.meeting-table-cell')
        // 去除原有颜色
        for (let i = 0; i < meetingTableCell.length; i++) {
            if (meetingTableCell[i].classList.contains("has-click")) {
                meetingTableCell[i].classList.remove("has-click")
            }
        }
        // 重新赋予颜色
        if (arr.length === 1) {
            // 获取点击单元格
            let clickCell = meetingTableCell[arr[0]]
            // 增加点击后样式
            clickCell.classList.add("has-click")
            cookie.save('meeting_time', arr, { path: '/' })
            // setOrderCan(false)
        } else {
            for (let i = arr[0]; i <= arr[arr.length - 1]; i++) {
                // 获取父节点的颜色样式
                let parent = meetingTableCell[i].parentNode.style.background
                // 检测测到有颜色的格子
                if (parent !== '') {
                    // 且颜色不是灰色(18:00禁选区域)
                    if (parent !== 'rgb(238, 238, 238)') {
                        // 去除原有颜色
                        for (let i = 0; i < meetingTableCell.length; i++) {
                            if (meetingTableCell[i].classList.contains("has-click")) {
                                meetingTableCell[i].classList.remove("has-click")
                            }
                        }
                        clickCellArr = []
                        cookie.save('meeting_time', [], { path: '/' })
                        message.warn('会议时间冲突')
                        break
                    }
                } else {
                    // 获取点击单元格
                    let clickCell = meetingTableCell[i]
                    // 增加点击后样式
                    clickCell.classList.add("has-click")
                    cookie.save('meeting_time', arr, { path: '/' })
                }
            }
        }
    }

    // 清楚所有单元格颜色的函数
    function clearCell() {
        let meetingTableCell = document.querySelectorAll('.meeting-table-cell')
        for (let i = 0; i < meetingTableCell.length; i++) {
            // 获取单元格
            let clickCell = meetingTableCell[i]
            // 如果单元格有hasclick标记，移除
            if (clickCell.classList.contains("has-click")) {
                meetingTableCell[i].classList.remove("has-click")
            }
        }
    }
    // 查询会议室数据
    const searchColumns = [
        {
            title: '会议室名称',
            dataIndex: 'meetingRoom',
            key: 'meetingRoom',
            render: (text, record, index) => {
                return (
                    searchRoomDate[index]['meetingPRoom'] + '-' + searchRoomDate[index]['meetingRoom']
                )
            },
        },
        {
            title: '锁获得者',
            dataIndex: 'orderManName',
            key: 'orderManName',
        },
        {
            title: '白板',
            dataIndex: 'white_board',
            key: 'white_board',
            render: (text, record, index) => {
                return (
                    <Rate disabled defaultValue={text} />
                )
            },
        },
        {
            title: '投影',
            dataIndex: 'projection',
            key: 'projection',
            render: (text, record, index) => {
                return (
                    <Rate disabled defaultValue={text} />
                )
            },
        },
        {
            title: '电视',
            dataIndex: 'tv',
            key: 'tv',
            render: (text, record, index) => {
                return (
                    <Rate disabled defaultValue={text} />
                )
            },
        },
        {
            title: '操作',
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => (
                searchRoomDate[index]['orderManName'] == cookie.load('name') ?
                    <Tooltip placement="top" title="预约会议室">
                        <CheckCircleOutlined style={{ fontSize: '20px', color: '#00FF00' }}
                            onClick={() => {
                                // 查询id
                                setSearchId(searchRoomDate[index]['searchId']);
                                // 查询的会议室id
                                setSearchMeetingRoomId(searchRoomDate[index]['meetingRoomId'])
                                // 查询的会议室名字
                                setSearchMeetingRoom(searchRoomDate[index]['meetingRoom'])
                                setAddMeeting(true)
                            }} />
                    </Tooltip>
                    :
                    <Tooltip placement="top" title="会议室预约冲突,请一分钟之后查询">
                        <LockOutlined style={{ fontSize: '20px', color: 'red' }} />
                    </Tooltip>
            ),
        }
    ]

    // 会议室详情表格标题
    const meetingDetailColumns = [
        {
            title: '会议室名字',
            key: 'name',
            dataIndex: 'name',
            width: '120px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => {
                return (
                    meetingDetailDate[index]['pname'] + '-' + meetingDetailDate[index]['name']
                )
            },
        },
        {
            title: '人数区间',
            key: 'max',
            dataIndex: 'max',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    '[' + meetingDetailDate[index]['min'] + ',' + meetingDetailDate[index]['max'] + ']'
                )
            },
        },
        {
            title: '白板权重',
            key: 'white_board',
            dataIndex: 'white_board',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Rate disabled defaultValue={text} style={{ color: 'orange', }} character={<StarFilled style={{ fontSize: '12px' }} />} />
                )
            },
        },
        {
            title: '投影权重',
            key: 'projection',
            dataIndex: 'projection',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Rate disabled defaultValue={text} style={{ color: 'orange' }} character={<StarFilled style={{ fontSize: '12px' }} />} />
                )
            },
        },
        {
            title: '电视权重',
            key: 'tv',
            dataIndex: 'tv',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Rate disabled defaultValue={text} style={{ color: 'orange' }} character={<StarFilled style={{ fontSize: '12px' }} />} />
                )
            },
        },
        {
            title: '培训权重',
            key: 'train',
            dataIndex: 'train',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Rate disabled defaultValue={text} style={{ color: 'orange' }} character={<StarFilled style={{ fontSize: '12px' }} />} />
                )
            },
        },
        {
            title: '招待权重',
            key: 'serve',
            dataIndex: 'serve',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Rate disabled defaultValue={text} style={{ color: 'orange' }} character={<StarFilled style={{ fontSize: '12px' }} />} />
                )
            },
        },
        {
            title: '活动权重',
            key: 'activity',
            dataIndex: 'activity',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Rate disabled defaultValue={text} style={{ color: 'orange' }} character={<StarFilled style={{ fontSize: '12px' }} />} />
                )
            },
        },
        {
            title: '普通权重',
            key: 'common',
            dataIndex: 'common',
            width: '100px',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Rate disabled defaultValue={text} style={{ color: 'orange' }} character={<StarFilled style={{ fontSize: '12px' }} />} />
                )
            },
        },
    ]

    // 定义选择的会议类型
    const selectMeetingType = (value) => {
        setSearchMeetingType(value)
    }

    // 选择查询的会议室
    const searchRoom = (value, select) => {
        if (select !== []) {
            // setsearchRoomId(value[1])
            // setCascander(value)
        } else {
            return false
        }
    }
    // 周数变化时,设置时间为选择周的周一
    const weekChange = (date, datString) => {
        if (date === null || date === '' || date === undefined) {
            message.warn('请选择会议时间')
            return false
        }
        setMeetingDate(date.format('YYYY-MM-DD'))
        setSearchDate(date.format('YYYY-MM-DD'));
    }

    // 加载时查询一次所有会议室
    useEffect(() => {
        getAllRoom()
        let tip = document.querySelector('.tip-wapper')
        tip.style.display = 'block'
        setTimeout(() => {
            tip.style.display = 'none'
        }, 5000)
        // 组件退出时，清空会议cookie
        return (() => {
            cookieBye()
        })
    }, [])

    // 隐藏消息
    function missTip() {
        let tip = document.querySelector('.tip-wapper')
        tip.style.display = 'none'
    }
    // 移除会议的cookie
    function cookieBye() {
        try {
            cookie.remove('meeting_time', { path: '/' })
        } catch (error) {

        }
        try {
            cookie.remove('meeting_place', { path: '/' })
        } catch (error) {
            return false
        }
    }

    // 查询所有的会议室
    const getAllRoom = () => {
        let data = {}
        // 角色
        data.roleId = cookie.load('role')
        // 模块集合
        data.shopList = cookie.load('shopList')
        Axios({
            method: 'POST',
            data: data,
            url: servicePath.getAllRoom,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    let room = res.data.data
                    setMeettingRoom(room)
                    setsearchRoomId(room[0].children[0].value)
                    setCascander([room[0].label, room[0].children[0].label])
                } else {
                    errMsg(res.data['errorCode'], res.data['errorMsg'])
                }
            }
        )
    }

    // 加载时查询当前周会议,监控会议类型变化,会议室变化,日期变化
    useEffect(() => {
        clearCell()
        cookieBye()
        getAllMeeting()
    }, [chooseMeetingPlace, searchDate, searchMeetingType])

    // 查询当前周所有会议
    function getAllMeeting() {
        if (chooseMeetingPlace === '' || chooseMeetingPlace === undefined) {
            message.warn('选择会议地址')
            return false
        }
        if (searchDate === '' || searchDate === undefined) {
            message.warn('选择会议时间')
            return false
        }
        let data = {}
        // 查询日期(每周星期一的日期 YYYY-MM-DD)
        data.region = chooseMeetingPlace
        data.time = searchDate
        // 查询人的工号
        data.orderManId = cookie.load('id')
        // 角色
        data.roleId = cookie.load('role')
        // 模块集合
        data.shopList = cookie.load('shopList')
        Axios({
            method: 'POST',
            data: data,
            url: searchMeetingType === '所有会议' ? servicePath.queryUsageOfRoom : servicePath.queryOwnUsageRoom,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setDataSource(res.data.data)
                } else {
                    errMsg(res.data['errorCode'], res.data['errorMsg'])
                }
            }
        )
    }
    // 以下为预约会议室的函数
    // 预约会议室id
    const selectOrderRoom = (value, option) => {
        // 设置所选会议室的id
        if (value == []) {
            setSelectRoom()
        } else {
            setSelectRoom(value[1])
        }
    }
    // 预约日期变化时
    const dateChange = (date, dateString) => {
        if (date === null) {
            setMeetingDate()
        } else {
            setMeetingDate(date.format('YYYY-MM-DD'))
        }
    }
    // 预约时的时间范围变化函数
    const timeRangeChange = (time, timeString) => {
        // 对范围选择器来说，传入参数time和timeString为数组
        if (time === null) {
            setStartTime(moment().add(1, 'hour').format('HH:00'))
            setEndTime(moment().add(1, 'hour').format('HH:30'))
        } else {
            setStartTime(time[0].format('HH:mm'))
            setEndTime(time[1].format('HH:mm'))
        }
    }
    // 会议主题变化时
    const meetingThemeChange = (e) => {
        setMeetingTheme(e.target.value);
    }
    // 会议内容变化时
    const meetingContentChange = (e) => {
        setMeetingContent(e.target.value)
    }

    // 点击表格帮助用户设置的地点
    const [place, setPlace] = useState('')
    // 设置预约时用户是否可以选择会议室
    const [meetingSelectDisable, setMeetingSelectDisable] = useState(false)
    // 打开预定窗口
    const beginOrder = () => {
        form1.resetFields()
        form.resetFields()
        try {
            // 以下为时间操作
            let a = cookie.load('meeting_time')
            let timeLength = timeRange.length
            if (a.length === 1) {
                let start_time = timeRange[a[0] % timeLength]
                let end_time = timeRange[(a[0] + 1) % timeLength]
                setStartTime(moment(start_time, 'HH:mm').format('HH:mm'))
                setEndTime(moment(end_time, 'HH:mm').format('HH:mm'))
            } else {
                let start_time = timeRange[a[0] % timeLength]
                let end_time = timeRange[(a[1] + 1) % timeLength]
                setStartTime(moment(start_time, 'HH:mm').format('HH:mm'))
                setEndTime(moment(end_time, 'HH:mm').format('HH:mm'))
            }
        } catch (error) {
            setStartTime(moment().add(1, 'hour').format('HH:00'))
            setEndTime(moment().add(1, 'hour').format('HH:30'))
        }
        try {
            // 获取会议室信息
            let placeMes = cookie.load('meeting_place')
            setPlace(placeMes)
            setSelectRoom(placeMes.room_id)
            setCascander([placeMes.place, placeMes.room])
            // 如果成功设置会议室，会议室选择级联框置灰
            setMeetingSelectDisable(true)
            message.success('已为您选择会议室')
        } catch (error) {
            // 如果设置会议室失败，用户自己选
            setMeetingSelectDisable(false)
        }
        // 以下为设置地点操作
        setMeetingType('')
        setOrder(true)
    }

    // 打开智能预约查询条件
    const beginSmartOrder = () => {
        form1.resetFields()
        form.resetFields()
        try {
            // 以下为时间操作
            let a = cookie.load('meeting_time')
            let timeLength = timeRange.length
            if (a.length === 1) {
                let start_time = timeRange[a[0] % timeLength]
                let end_time = timeRange[(a[0] + 1) % timeLength]
                setStartTime(moment(start_time, 'HH:mm').format('HH:mm'))
                setEndTime(moment(end_time, 'HH:mm').format('HH:mm'))
            } else {
                let start_time = timeRange[a[0] % timeLength]
                let end_time = timeRange[(a[1] + 1) % timeLength]
                setStartTime(moment(start_time, 'HH:mm').format('HH:mm'))
                setEndTime(moment(end_time, 'HH:mm').format('HH:mm'))
            }
        } catch (error) {
            setStartTime(moment().add(1, 'hour').format('HH:00'))
            setEndTime(moment().add(1, 'hour').format('HH:30'))
        }
        // 以下为设置地点操作
        setMeetingType('')
        setSmartOrder(true);
    }

    // 预定会议窗口点击确定时
    const orderRoom = () => {
        if (selectRoom == '') {
            message.error('请选择会议室')
        } else if (endTime == '' || startTime == '') {
            message.error('请选择正确会议时间')
        } else if (meetingTheme == '') {
            message.error('会议主题不能为空')
        } else if (meetingContent == '') {
            message.error('会议内容不能为空')
        } else if (meetingType == '') {
            message.error('会议类型必选')
        } else {
            if (meetingType == '招待') {
                setReminder(true)
            } else {
                orderMeeting()
            }
        }

    }

    // 预约会议室
    const orderMeeting = () => {
        setEffective(1)
        let data = {}
        data.name = name
        data.meeting_room_id = selectRoom
        data.order_man_id = cookie.load('id')
        data.order_man_number = employeeId
        data.order_man_email = Base64.decode(cookie.load('email'))
        data.meeting_people_id_list = meetingPeopleIdList
        data.meeting_people_name_list = meetingPeopleNameList
        data.meeting_people_email_list = meetingPeopleEmailList
        data.meeting_date = meetingDate
        data.start_time = startTime
        data.end_time = endTime
        data.meeting_title = meetingTheme
        data.meeting_content = meetingContent
        data.people_number = (meetingPeopleIdList.length + 1).toString()
        data.meeting_type = meetingType
        data.compote = compote
        data.water = water
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        Axios({
            data: data,
            method: 'POST',
            url: servicePath.orderRoom,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setEffective(0)
                if (res.data.success) {
                    form.resetFields()
                    form1.resetFields()
                    setPeopleNumber('')
                    setMeetingType('')
                    setCompote(0)
                    setSelectRoom('')
                    setMeetingTheme('')
                    setMeetingContent('')
                    setCompote('')
                    setWater('')
                    setMeetingPeopleIdList([])
                    setMeetingPeopleNameList([])
                    setMeetingPeopleEmailList([])
                    // setMeetingDate(moment().format('YYYY-MM-DD'))
                    setOrder(false)
                    setReminder(false)
                    getAllMeeting()
                    clearCell()
                    cookieBye()
                    message.success('预约成功！')
                } else {
                    errMsg(res.data['errorCode'], res.data['errorMsg'])
                }
            }
        )
    }

    // 确认预约招待会议室
    const affirmReminder = () => {
        orderMeeting()
    }

    // 取消预约招待会议室
    const cancleReminder = () => {
        setPeopleNumber('')
        setMeetingType('')
        setCompote(0)
        setSelectRoom('')
        setMeetingTheme('')
        setMeetingContent('')
        setCompote('')
        setWater('')
        setMeetingPeopleIdList([])
        setMeetingPeopleNameList([])
        setMeetingPeopleEmailList([])
        setReminder(false)
    }

    // 关闭会议详情
    const cancleDetail = () => {
        setSelectRoom('')
        setRoomName('')
        setDeleteRoomId('')
        setCreateMan(false)
        setCreateManName('')
        setMeetingDate(moment().weekday(0).format('YYYY-MM-DD'))
        setMeetingTheme('')
        setMeetingContent('')
        setPeopleNumber('')
        setMeetingType('')
        setMeetingCompote(0)
        setMeetingWater(0)
        setdetail(false)
    }
    // 控制会议详情的函数
    const showMeeting = (id) => {
        // 查询会议详情
        let data = {}
        data.meeting_id = id
        data.orderManId = cookie.load('id')
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        Axios({
            data: data,
            method: 'POST',
            url: servicePath.meetingDeatil,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data[0]
                if (data.flag) {
                    // 如果是创建人
                    setLetterIdList(data.letter_id_list)
                    setRoomName(data.room_name)
                    setDeleteRoomId(data.meeting_room_id)
                    setCreateMan(data.flag)
                    setCreateManName(data.name)
                    setShowStartedTime(moment(data.start_time, 'kk:mm').format('kk:mm'))
                    setShowEndTime(moment(data.end_time, 'kk:mm').format('kk:mm'))
                    setMeetingDate(moment(data.meeting_date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                    setMeetingTheme(data.meeting_title)
                    setMeetingContent(data.meeting_content)
                    setPeopleNumber(data.people_number)
                    setMeetingType(data.meeting_type)
                    setMeetingCompote(data.compote)
                    setMeetingWater(data.water)
                } else {
                    // 如果不是会议创建人
                    setCreateMan(data.flag)
                    setRoomName(data.room_name)
                    setCreateManName(data.name)
                    setShowStartedTime(moment(data.start_time, 'kk:mm').format('kk:mm'))
                    setShowEndTime(moment(data.end_time, 'kk:mm').format('kk:mm'))
                    setMeetingDate(moment(data.meeting_date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                }
                setdetail(true)
            } else {
                errMsg(res.data['errorCode'], res.data['errorMsg'])
            }
        })

    }

    // 定义取消会议的函数
    const orderDelete = () => {
        let data = {}
        data.letter_id_list = letterIdList
        data.meeting_title = meetingTheme
        data.order_man_id = cookie.load('id')
        data.meeting_id = meetingId
        data.meeting_room_id = deleteRoomId
        data.number = employeeId
        data.name = name
        data.meeting_date = meetingDate
        data.start_time = showStartedTime
        data.end_time = showEndTime
        data.meeting_type = meetingType
        data.people_number = peopleNumber
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        Axios({
            data: data,
            method: 'POST',
            url: servicePath.orderDelete,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setdetail(false)
                    getAllMeeting()
                    message.success('会议取消成功！')
                } else {
                    errMsg(res.data['errorCode'], res.data['errorMsg'])
                }
            }
        )
    }

    // 提早结束会议
    const aheadDelete = () => {
        let data = {}
        data.meeting_id = meetingId
        data.meeting_room_id = deleteRoomId
        data.order_man_id =  cookie.load('id')
        data.name = name
        data.meeting_date = meetingDate
        data.start_time = showStartedTime
        data.end_time = showEndTime
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        Axios({
            data: data,
            method: 'POST',
            url: servicePath.aheadDelete,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setdetail(false)
                    getAllMeeting()
                    message.success('会议已提早结束！')
                } else {
                    errMsg(res.data['errorCode'], res.data['errorMsg'])
                }
            }
        )
    }

    const cancelDelete = () => {
        message.warn('已取消！')
    }

    const cancelahead = () => {
        message.warn('已取消！')
    }

    // 确定智能条件查询
    const affirmSmartOrder = () => {
        if (area == '') {
            message.error('会议室地方必选')
        } else if (startTime == '' & endTime == '') {
            message.error('会议室时间必选')
        }  else if (meetingType == '') {
            message.error('会议类型必选')
        } else {
            if (meetingType == '招待') {
                setSmartReminder(true)
            } else {
                smartOrderMeeting()
            }
        }
    }

    // 智能查询会议室
    const smartOrderMeeting = () => {
        let dataprops = {
            "area": area,
            "date": meetingDate,
            "start_time": startTime,
            "end_time": endTime,
            "people_number": (meetingPeopleIdList.length + 1).toString(),
            "meeting_type": meetingType,
            "order_man_name": cookie.load('name'),
            "order_man_id": cookie.load('id'),
            "condition": conditions,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList')
        }
        Axios({
            data: dataprops,
            method: 'POST',
            url: servicePath.searchMeeting,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setSmartOrder(false)
                    setSmartReminder(false)
                    setCountDown(1)
                    setCountTime(moment().add(1, 'm').locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'))
                    setSearchRoomDate(res.data.data)
                    setSmartMeeting(true)
                } else {
                    message.error(res.data['errorMsg'])
                }
            }
        )
    }

    // 确认预约招待会议室
    const affirmSmartReminder = () => {
        smartOrderMeeting()
    }

    // 取消预约招待会议室
    const cancleSmartReminder = () => {

        setSmartReminder(false)
    }


    // 重新查询
    const requery = () => {
        let dataprops = {
            "area": area,
            "date": meetingDate,
            "start_time": startTime,
            "end_time": endTime,
            "people_number": (meetingPeopleIdList.length + 1).toString(),
            "meeting_type": meetingType,
            "order_man_name": cookie.load('name'),
            "order_man_id": cookie.load('id'),
            "condition": conditions,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList')
        }
        Axios({
            data: dataprops,
            method: 'POST',
            url: servicePath.searchMeeting,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setCountDown(1)
                    setCountTime(moment().add(1, 'm').locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'))
                    setSearchRoomDate(res.data.data)
                } else {
                    errMsg(res.data['errorMsg'])
                }
            }
        )
    }

    // 取消智能条件查询
    const cancleSmartOrder = () => {
        message.success('取消智能预约会议室')
        form1.resetFields()
        form2.resetFields()
        setArea('')
        setMeetingType('')
        setPeopleNumber('')
        setMeetingPeopleIdList([])
        setMeetingPeopleNameList([])
        setMeetingPeopleEmailList([])
        setMeetingDate(moment().format('YYYY-MM-DD'))
        setStartTime(moment().add(1, 'hour').format('HH:00'))
        setEndTime(moment().add(1, 'hour').format('HH:30'))
        setConditions([])
        setSmartOrder(false)
    }

    // 取消智能查询会议室
    const cancleSmartMeeting = () => {
        message.success('取消智能预约会议室')
        form1.resetFields()
        form2.resetFields()
        setArea('')
        setMeetingType('')
        setPeopleNumber('')
        setMeetingDate(moment().format('YYYY-MM-DD'))
        setStartTime(moment().add(1, 'hour').format('HH:00'))
        setEndTime(moment().add(1, 'hour').format('HH:30'))
        setConditions([])
        setSmartMeeting(false)
    }

    // 其他条件改变
    const condition = (checkedValues) => {
        setConditions(checkedValues)
    }

    // 确定智能预约会议室
    const addOrderMeeting = () => {
        setEffective(1)
        if (meetingTheme == '') {
            message.error('会议主题不能为空')
        } else if (meetingContent == '') {
            message.error('会议内容不能为空')
        } else {
            let dataprops = {
                "search_id": searchId,
                "order_man_id": cookie.load('id'),
                "meeting_people_id_list": meetingPeopleIdList,
                "meeting_people_name_list": meetingPeopleNameList,
                "meeting_people_email_list": meetingPeopleEmailList,
                "meeting_room_id": searchMeetingRoomId,
                "meeting_date": meetingDate,
                "start_time": startTime,
                "end_time": endTime,
                "meeting_title": meetingTheme,
                "meeting_content": meetingContent,
                "people_number": (meetingPeopleIdList.length + 1).toString(),
                "meeting_type": meetingType,
                "compote": compote,
                "water": water,
                "order_man_email": Base64.decode(cookie.load('email')),
                "order_man_name": cookie.load('name'),
                "order_man_number": Base64.decode(cookie.load('number')),
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList')
            }
            Axios({
                data: dataprops,
                method: 'POST',
                url: servicePath.smartSubscribeMeeting,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization': cookie.load('token')
                }
            }).then(
                res => {
                    setEffective(0)
                    if (res.data.success) {
                        message.success('会议室预约成功')
                        form1.resetFields()
                        form2.resetFields()
                        setAddMeeting(false)
                        setSmartMeeting(false)
                        setArea('')
                        setMeetingType('')
                        setPeopleNumber('')
                        setMeetingPeopleIdList([])
                        setMeetingPeopleNameList([])
                        setMeetingPeopleEmailList([])
                        // setMeetingDate(moment().format('YYYY-MM-DD'))
                        setStartTime(moment().add(1, 'hour').format('HH:00'))
                        setEndTime(moment().add(1, 'hour').format('HH:30'))
                        setConditions([])
                        clearCell()
                        cookieBye()
                        getAllMeeting()
                    } else {
                        form2.resetFields()
                        message.error(res.data.errorMsg)
                        setAddMeeting(false)
                    }
                }
            )
        }
    }

    // 取消智能预约会议室
    const cancleOrderMeeting = () => {
        form2.resetFields()
        setMeetingPeopleIdList([])
        setMeetingPeopleNameList([])
        setMeetingPeopleEmailList([])
        message.success('取消预约该会议室')
        setAddMeeting(false)
    }

    // 获取会议室详情
    const getMeetingDetail = () => {
        Axios({
            method: 'POST',
            url: servicePath.getMeetingDetails,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setMeetingDetailDate(res.data.data)
                    setMeetingDetail(true)
                }
            }
        )
    }

    // 关闭会议室详情
    const cancleMeetingDeatil = () => {
        setMeetingDetail(false)
    }


    // 会议地址变化时
    function meetingPlaceChange(value) {
        if (value === '' || value === null || value === undefined) {
            message.warn('请选择会议地址')
            return false
        }
        setChooseMeetingPlace(value)
    }
    // 提示框的弹出与关闭
    const [tipVisible, setTipVisible] = useState(true)


    // 确认参与会议室的人员
    const addChooseMeetingPeople = () => {
        setMeetingPeopleIdList(meetingPeopleIdList);
        let nameList = []
        let emailList = []
        meetingPeopleIdList.map(id => {
            let person = employeeList[employeeList.map(item => item.key).indexOf(id)]
            nameList.push(person.name)
            emailList.push(person.email)
        })
        setMeetingPeopleNameList(nameList);
        setMeetingPeopleEmailList(emailList);
        setChooseMeetingPeople(false)
    }

    // 取消参与会议室的人员
    const cancleChooseMeetingPeople = () => {
        setChooseMeetingPeople(false)
    }

    // 参与会议室人员修改
    const onMeetingPeopleChange = (nextTargetKeys) => {
        setMeetingPeopleIdList(nextTargetKeys)
    }

    return (
        <div className='oa-meeting-index'>
            <div style={{ marginTop: size.height * 0.03 , marginLeft: size.width * 0.04 }}>
                <div className={'tip-wapper'} onClick={missTip}>
                    Tip:点击表格区域选择会议室及预约时间，再点预约按钮，可以更快捷哦！
                </div>
            </div>
            <div className='meeting' style={{ marginLeft: size.width * 0.04, marginRight: size.width * 0.04, paddingTop:80 }}>
                <div className='btn' >
                    <Row >
                        <Select className='choose-meeting' value={searchMeetingType} style={{ width: '120px' }} placeholder='会议类型' onSelect={selectMeetingType}>
                            <Option value='我的会议'>我的会议</Option>
                            <Option value='所有会议'>所有会议</Option>
                        </Select>
                        <Select className='choose-meeting' value={chooseMeetingPlace} style={{ marginLeft: 20, width: '120px' }} placeholder='选择会议地址' onSelect={meetingPlaceChange}>
                            {meetingPlace.map((item, index) => {
                                return (
                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                )
                            })}
                        </Select>
                        <DatePicker
                            locale={locale}
                            className='cool'
                            style={{ borderRadius: 10, marginLeft: 20 }}
                            onChange={weekChange}
                            disabledDate={disabledDate}
                            defaultValue={moment()}
                        />
                        <div >
                            <Button onClick={beginOrder} type='primary' style={{ marginLeft: '20px', borderRadius: '.3rem' }}>预约</Button>
                            <Button onClick={beginSmartOrder} type='primary' style={{ marginLeft: '20px', borderRadius: '.3rem' }}>智能预约</Button>
                            <Button onClick={() => getMeetingDetail()} type='primary' style={{ marginLeft: '20px', borderRadius: '.3rem' }}>会议室详情</Button>
                            <Modal
                                title='预约会议室'
                                visible={order}
                                onOk={orderRoom}
                                onCancel={cancleOrder}
                                destroyOnClose
                                centered={true}
                                okText={'预约'}
                                cancelText={'取消'}
                                okButtonProps={{ disabled: effective == 1 ? true : false }}
                                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                            >
                                <Form
                                    {...layout}
                                    form={form}
                                    labelAlign='left'
                                >
                                    <Form.Item
                                        label='会议室'
                                        name='meetingRoom'
                                        rules={[{ required: true }]}
                                    >
                                        <Cascader disabled={meetingSelectDisable} placeholder='选择会议室' style={{ width: '180px', borderRadius: 10 }} options={meettingRoom} onChange={selectOrderRoom} />
                                        <div>
                                            {`${place === '' || place === undefined ? '' : place.place + '/' + place.room}`}
                                        </div>
                                    </Form.Item>
                                    <Col>
                                        <Form
                                            {...layout}
                                            form={form4}
                                            labelAlign='left'
                                        >
                                            <Form.Item
                                                label="会议性质"
                                                name="type"
                                                rules={[{ required: true }]}
                                            >
                                                <Select className='choose-meeting' placeholder='请选择会议性质' onChange={(value) => setMeetingType(value)}>
                                                    {
                                                        meetingTypeList.map(item => {
                                                            return (
                                                                <Option value={item}>{item}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Form>

                                    </Col>
                                    <Form.Item
                                        label='选择日期'
                                        name='date'
                                        rules={[{ required: true }]}
                                        initialValue={moment(meetingDate)}
                                    >
                                        <DatePicker
                                            locale={locale}
                                            style={{ borderRadius: 10 }}
                                            picker="date"
                                            placeholder='会议日期'
                                            // defaultValue={moment(meetingDate)}
                                            value={meetingDate}
                                            disabledDate={disabledDate}
                                            onChange={dateChange}
                                        />
                                    </Form.Item>
                                    <Form
                                        {...layout}
                                        form={form1}
                                        labelAlign='left'
                                    >
                                        <Form.Item
                                            label='选择时间'
                                            name='time'
                                            rules={[{ required: true }]}
                                        >
                                            <RangePicker
                                                locale={locale}
                                                format='HH:mm'
                                                minuteStep={30}
                                                hourStep={1}
                                                showNow={true}
                                                allowClear={true}
                                                hideDisabledOptions={true}
                                                defaultValue={[moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')]}
                                                value={[startTime, endTime]}
                                                onChange={timeRangeChange}
                                                // disabledHours={() => [1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23, 0]}
                                                disabledHours={() => disChooseTime}
                                            />
                                        </Form.Item>
                                    </Form>
                                    <Form.Item
                                        label='会议主题'
                                        name='theme'
                                        rules={[{ required: true }]}
                                    >
                                        <TextArea
                                            placeholder='填写会议主题'
                                            onChange={meetingThemeChange}
                                            value={meetingTheme}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label='会议内容'
                                        name='meetingContent'
                                        rules={[{ required: true }]}
                                    >
                                        <TextArea
                                            placeholder='输入会议的内容，以及注意事项等等...'
                                            onChange={meetingContentChange}
                                            value={meetingContent}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label='参会人员'
                                        name='meetingPeople'
                                    >
                                        {
                                            meetingPeopleNameList.map(item => {
                                                return <span >{item} </span>
                                            })
                                        }
                                    </Form.Item>
                                    {
                                        meetingType == '招待' ?
                                            (
                                                <Form
                                                    form={form3}
                                                    {...layout2}
                                                    labelAlign='left'
                                                >
                                                    <Form.Item
                                                        label="是否需要果盘"
                                                        name="compote"
                                                    >
                                                        <Radio.Group onChange={(e) => setCompote(e.target.value)} value={compote}>
                                                            <Radio value={1}>YES</Radio>
                                                            <Radio value={0}>NO</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="是否需要瓶装水"
                                                        name="water"
                                                    >
                                                        <Radio.Group onChange={(e) => setWater(e.target.value)} value={water}>
                                                            <Radio value={1}>YES</Radio>
                                                            <Radio value={0}>NO</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <Tag color="#f50" style={{ marginLeft: '30px' }}>预约【招待】性质的会议成功之后,不允许取消</Tag>
                                                </Form>
                                            ) : ''
                                    }
                                    <Button onClick={() => setChooseMeetingPeople(true)} type="primary"  style={{marginTop:'10px', marginLeft: '30px'}}>选择参会人员</Button>
                                </Form>
                            </Modal>
                            {/* 会议详情 */}
                            <Modal
                                title='会议详情'
                                visible={detail}
                                onCancel={cancleDetail}
                                footer={null}
                            >
                                {createMan ?
                                    <Form
                                        {...layout}
                                    >
                                        <Form.Item
                                            label='会议室'
                                        >
                                            <span>{roomName}</span>
                                        </Form.Item>
                                        <Form.Item
                                            label='预约人'
                                        >
                                            <span>{createManName}</span>
                                        </Form.Item>
                                        <Form.Item
                                            label='会议日期'
                                        >
                                            <span>
                                                {meetingDate}
                                            </span>
                                        </Form.Item>
                                        <Form.Item
                                            label='会议时间'
                                        >
                                            <span>
                                                {`${showStartedTime}-${showEndTime}`}
                                            </span>
                                        </Form.Item>
                                        <Form.Item
                                            label='会议类型'
                                        >
                                            <span>
                                                {meetingType}
                                            </span>
                                        </Form.Item>
                                        <Form.Item
                                            label='会议人数'
                                        >
                                            <span>
                                                {peopleNumber}
                                            </span>
                                        </Form.Item>
                                        <Form.Item
                                            label='会议主题'
                                        >
                                            <span>
                                                {meetingTheme}
                                            </span>
                                        </Form.Item>
                                        <Form.Item
                                            label='会议内容'
                                        >
                                            <span>
                                                {meetingContent}
                                            </span>
                                        </Form.Item>
                                        {
                                            meetingType == '招待' ? (
                                                <Form>
                                                    <Form.Item
                                                        label='是否需要果盘'
                                                    >
                                                        <span>
                                                            {meetingCompote == 1 ? '需要' : '不需要'}
                                                        </span>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label='是否需要水瓶'
                                                    >
                                                        <span>
                                                            {meetingWater == 1 ? '需要' : '不需要'}
                                                        </span>
                                                    </Form.Item>
                                                </Form>
                                            ) : ''
                                        }

                                        <Form.Item>
                                            <Row>
                                                <Popconfirm
                                                    title="确认删除?删除后不可恢复!"
                                                    onConfirm={aheadDelete}
                                                    onCancel={cancelahead}
                                                    okText="确定"
                                                    cancelText="取消"
                                                >
                                                    <Button type={'primary'} style={{ marginLeft: '90px' }}>提早结束</Button>
                                                </Popconfirm>
                                                <Popconfirm
                                                    title="确认删除?删除后不可恢复!"
                                                    onConfirm={orderDelete}
                                                    onCancel={cancelDelete}
                                                    okText="确定"
                                                    cancelText="取消"
                                                >
                                                    {/* display:meetingType =='招待' ? 'none':'' */}
                                                    <Button type={'primary'} style={{ marginLeft: '30px', display: meetingType == '招待' ? 'none' : '' }}>取消该预约</Button>
                                                </Popconfirm>
                                            </Row>
                                        </Form.Item>
                                    </Form> : <Form
                                        {...layout}
                                    >
                                        <Form.Item
                                            label='会议室'
                                        >
                                            <span>{roomName}</span>
                                        </Form.Item>
                                        <Form.Item
                                            label='预约人'
                                        >
                                            <span>{createManName}</span>
                                        </Form.Item>
                                        <Form.Item
                                            label='会议日期'
                                        >
                                            <span>
                                                {meetingDate}
                                            </span>
                                        </Form.Item>
                                        <Form.Item
                                            label='会议时间'
                                        >
                                            <span>
                                                {`${showStartedTime}-${showEndTime}`}
                                            </span>
                                        </Form.Item>
                                    </Form>
                                }
                            </Modal>

                            {/* 确认预约招待会议室 */}
                            <Modal
                                title="注意"
                                visible={reminder}
                                onOk={affirmReminder}
                                onCancel={cancleReminder}
                                centered={true}
                                okText={'确认'}
                                cancelText={'取消'}
                                okButtonProps={{ disabled: effective == 1 ? true : false }}
                                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                            >
                                <div style={{ textAlign: 'center' }}>预约【招待】性质的会议成功之后,不允许取消</div>
                            </Modal>

                            {/* 确认预约招待会议室 */}
                            <Modal
                                title="注意"
                                visible={smartReminder}
                                onOk={affirmSmartReminder}
                                onCancel={cancleSmartReminder}
                                centered={true}
                                okText={'确认'}
                                cancelText={'取消'}
                                okButtonProps={{ disabled: effective == 1 ? true : false }}
                                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
                            >
                                <div style={{ textAlign: 'center' }}>预约【招待】性质的会议成功之后,不允许取消</div>
                            </Modal>
                            {/* 智能查询 */}
                            <Modal
                                title='查询条件'
                                visible={smartOrder}
                                onOk={affirmSmartOrder}
                                onCancel={cancleSmartOrder}
                                centered={true}
                                okText={'查询'}
                                cancelText={'取消'}
                            >
                                <Form form={form2} {...layout} labelAlign='left'>
                                    <Col>
                                        <Form.Item
                                            label="地区"
                                            name="area"
                                            rules={[{ required: true }]}
                                        >
                                            <Select className='choose-meeting' placeholder='请选择地区' onChange={(value) => setArea(value)}>
                                                <Option value='嘉兴'>嘉兴</Option>
                                                {/* <Option value='杭州'>杭州</Option> */}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label="会议性质"
                                            name="type"
                                            rules={[{ required: true }]}
                                        >
                                            <Select className='choose-meeting' placeholder='请选择会议性质' onChange={(value) => setMeetingType(value)}>
                                                <Option value='培训'>培训</Option>
                                                <Option value='招待'>招待</Option>
                                                <Option value='活动'>活动</Option>
                                                <Option value='普通'>普通</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label='会议日期'
                                            name='date'
                                            rules={[{ required: true }]}
                                        >
                                            <DatePicker
                                                locale={locale}
                                                style={{ borderRadius: 10 }}
                                                picker="date"
                                                placeholder='会议日期'
                                                defaultValue={moment(meetingDate, dateFormat)}
                                                value={meetingDate}
                                                disabledDate={disabledDate}
                                                onChange={dateChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form
                                            {...layout}
                                            form={form1}
                                            labelAlign='left'
                                        >
                                            <Form.Item
                                                label='会议时间'
                                                name='time'
                                                rules={[{ required: true }]}
                                            >
                                                <RangePicker
                                                    locale={locale}
                                                    format='HH:mm'
                                                    minuteStep={30}
                                                    hourStep={1}
                                                    showNow={true}
                                                    allowClear={true}
                                                    hideDisabledOptions={true}
                                                    defaultValue={[moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')]}
                                                    value={[startTime, endTime]}
                                                    onChange={timeRangeChange}
                                                    disabledHours={() => disChooseTime}
                                                />
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label='会议主题'
                                            name='theme'
                                            rules={[{ required: true }]}
                                        >
                                            <TextArea
                                                placeholder='填写会议主题'
                                                onChange={(e) => {
                                                    setMeetingTheme(e.target.value);
                                                }}
                                                value={meetingTheme}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label='会议内容'
                                            name='meetingContent'
                                            rules={[{ required: true }]}
                                        >
                                            <TextArea
                                                placeholder='输入会议的内容，以及注意事项等等...'
                                                onChange={(e) => {
                                                    setMeetingContent(e.target.value)
                                                }}
                                                value={meetingContent}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Form.Item
                                        label='参会人员'
                                        name='meetingPeople'
                                    >
                                        {
                                            meetingPeopleNameList.map(item => {
                                                return <span >{item} </span>
                                            })
                                        }
                                    </Form.Item>
                                    <Col>
                                        <Form.Item
                                            label="其他条件"
                                            name="condition"
                                        >
                                            <Checkbox.Group options={options} onChange={condition} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        {
                                            meetingType == '招待' ?
                                                (
                                                    <Form
                                                        form={form3}
                                                        {...layout2}
                                                        labelAlign='left'
                                                    >
                                                        <Form.Item
                                                            label="是否需要果盘"
                                                            name="compote"
                                                        >
                                                            <Radio.Group onChange={(e) => setCompote(e.target.value)} value={compote}>
                                                                <Radio value={1}>YES</Radio>
                                                                <Radio value={0}>NO</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="是否需要瓶装水"
                                                            name="water"
                                                        >
                                                            <Radio.Group onChange={(e) => setWater(e.target.value)} value={water}>
                                                                <Radio value={1}>YES</Radio>
                                                                <Radio value={0}>NO</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        <Tag color="#f50" style={{ marginLeft: '30px' }}>预约【招待】性质的会议,不允许取消</Tag>
                                                    </Form>
                                                ) : ''
                                        }
                                    </Col>
                                  
                                    <Button onClick={() => setChooseMeetingPeople(true)} type="primary" style={{marginTop:'10px', marginLeft: '30px'}}>选择参会人员</Button>
                                </Form>
                            </Modal>

                            {/* 智能查询 */}
                            <Modal
                                title='满足条件的会议室'
                                visible={smartMeeting}
                                footer={[]}
                                onCancel={cancleSmartMeeting}
                                okText={'查询'}
                                cancelText={'取消'}
                                width={size.width * 0.8}
                            >
                                <div style={{ marginLeft: 1000 }}>
                                    {
                                        countDown == 1 ? <Countdown title="目前会议室已为您锁定,请在时间范围内选择会议室" value={countTime} format="mm:ss" onFinish={() => { setCountDown(0); setSearchRoomDate([]) }} />
                                            : <Button type="primary" onClick={() => requery()}>重新查询</Button>
                                    }

                                </div>
                                <Table dataSource={searchRoomDate} columns={searchColumns} pagination={false} />
                            </Modal>


                            {/* 智能预约会议室 */}
                            <Modal
                                title='智能预约会议室'
                                visible={addMeeting}
                                onOk={addOrderMeeting}
                                onCancel={cancleOrderMeeting}
                                okText={'预约'}
                                cancelText={'取消'}
                                okButtonProps={{ disabled: effective == 1 ? true : false }}
                                cancelButtonProps={{ disabled: effective == 1 ? true : false }}>
                                <Form form={form2} {...layout1} labelAlign='left'>
                                    <Form.Item
                                        label="会议室名字"
                                        name="name"
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            defaultValue={searchMeetingRoom}
                                            disabled
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="会议室类型"
                                        name="meeting_type"
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            defaultValue={meetingType}
                                            disabled
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="会议日期"
                                        name="date"
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            defaultValue={meetingDate}
                                            disabled
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label='会议时间'
                                        rules={[{ required: true }]}
                                    >
                                        <span>
                                            {`${startTime}-${endTime}`}
                                        </span>
                                    </Form.Item>
                                    <Form.Item
                                        label='会议主题'
                                        name='theme'
                                        rules={[{ required: true }]}
                                    >
                                        <TextArea
                                            placeholder='填写会议主题'
                                            disabled={true}
                                            value={meetingTheme}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label='会议内容'
                                        name='meetingContent'
                                        rules={[{ required: true }]}
                                    >
                                        <TextArea
                                            placeholder='输入会议的内容，以及注意事项等等...'
                                            disabled={true}
                                            value={meetingContent}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="其他条件"
                                        name="conditions"
                                        rules={[{ required: true }]}
                                    >
                                        {
                                            conditions.length != 0 ? conditions.map((item) => {
                                                return <Tag color="blue">{optionDict[item]}</Tag>
                                            }) : ''
                                        }
                                    </Form.Item>
                                    <Form.Item
                                        label='参会人员'
                                        name='meetingPeople'
                                    >
                                        {
                                            meetingPeopleNameList.map(item => {
                                                return <span >{item} </span>
                                            })
                                        }
                                    </Form.Item>
                                    {
                                        meetingType == '招待' ?
                                            (
                                                <Form
                                                    form={form3}
                                                    {...layout2}
                                                    labelAlign='left'
                                                >
                                                    <Form.Item
                                                        label="是否需要果盘"
                                                        name="compote"
                                                    >
                                                        <Radio.Group value={compote} disabled>
                                                            <Radio value={1}>YES</Radio>
                                                            <Radio value={0}>NO</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="是否需要瓶装水"
                                                        name="water"
                                                    >
                                                        <Radio.Group value={water} disabled>
                                                            <Radio value={1}>YES</Radio>
                                                            <Radio value={0}>NO</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <Tag color="#f50" style={{ marginLeft: '30px' }}>预约【招待】性质的会议,不允许取消</Tag>
                                                </Form>
                                            ) : ''
                                    }
                                </Form>
                            </Modal>
                            {/* 会议室详情 */}
                            <Modal
                                title='会议室详情'
                                visible={meetingDetail}
                                footer={[]}
                                onCancel={cancleMeetingDeatil}
                                width={1300}
                            >
                                <Table
                                    shouldCellUpdate={((now, pre) => {

                                        if (now !== pre) {
                                            setTimeout(() => {
                                                return true
                                            }, 200)
                                        }
                                    })}
                                    columns={meetingDetailColumns}
                                    dataSource={meetingDetailDate}
                                    pagination={false}
                                    // 定义表格行类名以控制样式
                                    rowClassName={(record, index) => {
                                        return 'row-height';
                                    }}
                                />
                            </Modal>

                            {/* 选择参会人 */}
                            <Modal
                                title='选择参会人'
                                visible={chooseMeetingPeople}
                                onOk={addChooseMeetingPeople}
                                onCancel={cancleChooseMeetingPeople}
                                okText={'确认'}
                                cancelText={'取消'}
                                width={1300}
                                zIndex={2000}
                            >
                                <TableTransfer
                                    locale={{ itemUnit: '人', itemsUnit: '人', searchPlaceholder: '请输入搜索内容' }}
                                    dataSource={employeeList}
                                    targetKeys={meetingPeopleIdList}
                                    showSearch={true}
                                    onChange={onMeetingPeopleChange}
                                    filterOption={(inputValue, item) =>
                                        item.department != null && item.email != null ?
                                        item.name.indexOf(inputValue) !== -1 || item.email.indexOf(inputValue) !== -1 || item.department.indexOf(inputValue) !== -1
                                        :item.email != null ? item.name.indexOf(inputValue) !== -1 || item.email.indexOf(inputValue) !== -1
                                        :item.name.indexOf(inputValue) !== -1 
                                    }
                                    leftColumns={leftTableColumns}
                                    rightColumns={rightTableColumns}
                                />
                            </Modal>
                        </div>
                    </Row>
                </div>
                <br />
                <div className='table'>
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        // bordered
                        scroll={{ x:'80vw', y: `${size.width>1500?'70vh':'65vh'}` }}
                        // 定义表格行类名以控制样式
                        rowClassName={(record, index) => {
                            return 'row-height';
                        }}
                    />
                </div>
            </div>
        </div>
    )

    function errMsg(errCode, errMsg) {
        if (errCode == '10001') {
            message.error(errMsg)
            cookie.remove("role", { path: '/' });
            cookie.remove("shop", { path: '/' })
            cookie.remove("shopName", { path: '/' })
            cookie.remove("name", { path: '/' })
            cookie.remove("roleName", { path: '/' })
            cookie.remove("email", { path: '/' })
            cookie.remove("phone", { path: '/' })
            cookie.remove("watermark", { path: '/' })
            cookie.remove("flag", { path: '/' })
            cookie.remove("token", { path: '/' })
            props.history.push("/")
        } else {
            message.error(errMsg)
        }
    }
    // 不可选的周
    function disabledWeek(current) {
        // 不可选前一周
        return current && current < moment().endOf('week').subtract(1, 'week');
    }
    // 不可选的周
    function disabledDate(current) {
        // 不可选前一周
        return current && current < moment().endOf('day').subtract(1, 'day');
    }
}

export default Meeting