import React, { useState, useEffect } from 'react';
import {Typography, Checkbox, message, Table, Button} from 'antd'
import moment from 'moment'
import cookie from 'react-cookies'
import PlatformEventsCalendar from '../../component/platformEventsCalendar'
import style from './index.module.css';
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl';
import webUrl from '../../config/webUrl';
import axios from 'axios';
import AcceptBuried from '../../config/utils/buried';
import downExcel from '../../config/utils/downloadFile';
const {Title, Text} = Typography

function Index(props){
    //数组中删除指定一项数据
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    // 开始时间
    const [ startDate , setStartDate ] = useState(moment().subtract(1,'day').format('YYYY-MM-DD'))
    // 结束时间
    const [ endDate , setEndDate ] = useState(moment().subtract(1,'day').format('YYYY-MM-DD'))
    // 表头字段
    const [ tableField ] = useState(['访客数','加购人数','加购率','支付人数','转化率','支付金额','全店预售定金件数','预售金额','客单价',])
    // 选择表头字段
    const [ chooseTableField , setChooseTableField ] = useState([])
    // 表格表头
    const [ column , setColumn ] = useState([])
    // 数据
    const [ dataSource , setDataSource ] = useState([])
    // 图例
    const [legend, setLegend] = useState([])
    // 是否加载
    const [ loading , setLoading ] = useState(false)

    useEffect(()=>{
        setColumn([
            {
                title: '竞争店铺',
                dataIndex: '竞店',
                key: '竞店',
                align: 'center',
                fixed:'left',
                width:80,
            },
            ...chooseTableField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-本期',
                    key:item+'-本期',
                    width:90,
                    sorter: (a, b) => {
                        return (b[item+'-本期'] == '-' ? 0 : parseInt(b[item+'-本期']))  - (a[item+'-本期'] == '-' ? 0 :parseInt(a[item+'-本期']))
                    },
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    backgroundColor:'#fa897b',
                                    textAlign:'center'
                                }
                            }
                        )
                    },
                    onCell:(record, index)=>{
                        return (
                            {
                                style: {
                                    textAlign:'right',
                                    fontSize:"10px"
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <Text>{text.toLocaleString()}</Text>
                    )
                }
            }),
            ...chooseTableField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-同比',
                    key:item+'-同比',
                    width:90,
                    sorter: (a, b) => {
                        return (b[item+'-同比'] == '-' ? 0 : parseInt(b[item+'-同比']))  - (a[item+'-同比'] == '-' ? 0 :parseInt(a[item+'-同比']))
                    },
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    backgroundColor:'#ffdd94',
                                    textAlign:'center'
                                }
                            }
                        )
                    },
                    onCell:(record, index)=>{
                        return (
                            {
                                style: {
                                    textAlign:'right',
                                    fontSize:"10px"
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <Text>{text.toLocaleString()}</Text>
                    )
                }
            }),
            ...chooseTableField.map(item=>{
                return {
                    title:item,
                    dataIndex:item+'-上期',
                    key:item+'-上期',
                    width:90,
                    sorter: (a, b) => {
                        return (b[item+'-上期'] == '-' ? 0 : parseInt(b[item+'-上期']))  - (a[item+'-上期'] == '-' ? 0 :parseInt(a[item+'-上期']))
                    },
                    onHeaderCell: (record, index) => {
                        return (
                            {
                                style: {
                                    backgroundColor:'#75cce8',
                                    textAlign:'center'
                                }
                            }
                        )
                    },
                    onCell:(record, index)=>{
                        return (
                            {
                                style: {
                                    textAlign:'right',
                                    fontSize:"10px"
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        <Text>{text.toLocaleString()}</Text>
                    )
                }
            }),
            {
                title: '操作',
                dataIndex: '竞店',
                key: '竞店',
                align: 'center',
                fixed:'right',
                width:80,
                render: (text, record, index) => {
                    return (
                        <div style={{display:'flex', flexDirection:"column"}}>
                            <a onClick={()=>{
                                let params = {
                                    'shop':text,
                                    'startDate':startDate,
                                    'endDate':endDate,
                                }
                                let buriedProps = {
                                    start_date:startDate,
                                    end_date:endDate,
                                    race_shop:text
                                }
                                AcceptBuried('view_trends',"click",buriedProps,props.location.pathname,"竞店数据")
                                window["filter"] = params;
                                window.open(`${webUrl}/app/raceshop/trend`)
                            }}>查看趋势</a>
                            <a onClick={()=>{
                                let dataProps = {
                                    roleId: cookie.load('role'),
                                    shopList: cookie.load('shopList'),
                                    start_date:startDate,
                                    end_date:endDate,
                                    race_shop:text
                                }
                                AcceptBuried('download_data',"click",dataProps,props.location.pathname,"竞店数据")
                                setLoading(true)
                                axios({
                                    data: dataProps,
                                    method: 'POST',
                                    url: servicePath.downloadRaceShopDetailData,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        setLoading(false)
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    setLoading(false)
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }}>下载数据</a>
                        </div>
                    )
                }
            },
        ])
    },[chooseTableField, startDate, endDate])

    useEffect(()=>{
        getRaceShopContrastData()
        AcceptBuried('page_views',"view",{"page_type":"初始化页面"},props.location.pathname,"竞店数据")
    },[])

    // 获取竞店数据对比数据
    const getRaceShopContrastData = ()=>{
        let dataProps = {
            roleId: cookie.load('role'),
            shopList: cookie.load('shopList'),
            start_date:startDate,
            end_date:endDate
        }
        AcceptBuried('query_competitive_shop',"click",dataProps,props.location.pathname,"竞店数据")
        setLoading(true)
        NbAxios(dataProps, "POST", servicePath.getRaceShopContrastData).then(res => {
            setLoading(false)
            if (res.success) {
                setDataSource(res.data['table_data']);
                let lastStartDate = moment(startDate).subtract(1,'year').format('YYYY-MM-DD')
                let lastEndDate = moment(endDate).subtract(1,'year').format('YYYY-MM-DD')
                setLegend([startDate.replaceAll('-','.') + '——' + endDate.replaceAll('-','.') ,'同比',lastStartDate.replaceAll('-','.')  + '——' + lastEndDate.replaceAll('-','.') ])
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(err => {
            setLoading(false)
            message.warn('获取竞店数据对比数据接口出错,请联系IT')
        })
    }

    return (
        <div className={style.externalContent}>
            <div style={{display:'flex', justifyContent:'space-around', marginTop: 10, borderBottom: "1px solid #ccc", alignItems:'center', borderLeft:"1px solid #ccc",borderRight:"1px solid #ccc",}}>
                <Title level={4}>竞店数据</Title>
                <div style={{display:'flex', alignItems:"center"}}>
                    <PlatformEventsCalendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
                    <Button type="primary" size="small" style={{marginLeft:"3px"}} onClick={()=>{
                        getRaceShopContrastData()
                    }}>查询</Button>
                </div>
            </div>
            <div style={{display:'grid', gridTemplateColumns:"100px 1fr", alignItems:"center"}}>
                <b>可选字段:</b>
                <div>
                    {
                        tableField.map(item=>{
                            return <Checkbox checked={chooseTableField.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                if (e.target.checked) {
                                    let buriedProps = {
                                        "filed": item,
                                        "is_checked": true
                                    }
                                    AcceptBuried('choose_optional_fields',"click",buriedProps,props.location.pathname,"竞店数据")
                                    let obj = [...chooseTableField]
                                    obj.push(item)
                                    setChooseTableField(obj)
                                } else {
                                    let buriedProps = {
                                        "filed": item,
                                        "is_checked": false
                                    }
                                    AcceptBuried('choose_optional_fields',"click",buriedProps,props.location.pathname,"竞店数据")
                                    let obj = [...chooseTableField]
                                    obj.remove(item)
                                    setChooseTableField(obj)
                                }
                            }}>{item}</Checkbox>
                        })
                    }
                </div>
            </div>
            <div style={{marginTop:"5px"}}>
                <div style={{display:'flex'}}>
                    <Text strong style={{ fontSize: 16, marginRight: 10 }}>图例:</Text>
                    {
                        legend.map((item, index) => {
                            return <div style={{ display: "flex" }}>
                                <div style={{ width: 30, height: 15, marginTop: 5, backgroundColor: index == 0 ? '#fa897b ' : index == 1 ? '#ffdd94' : '#75cce8' }}></div>
                                <Text strong style={{ marginLeft: 5, marginRight: 10 }}>{item}</Text>
                            </div>
                        })
                    }
                </div>
                <div style={{marginTop:"5px"}}>
                    <Table loading={loading} size="small" columns={column} dataSource={dataSource} bordered
                    scroll={{ y: 'calc(90vh - 300px)', x: 1000 }} pagination={false} onChange={(pagination, filters, sorter)=>{
                        let buriedProps = {
                            "filed": sorter.field,
                            "order": sorter.order
                        }
                        AcceptBuried('table_order',"click",buriedProps,props.location.pathname,"竞店数据")
                    }}/>
                </div>
            </div>
        </div>
    )

}


export default Index