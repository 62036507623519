import React, {useEffect, useState} from 'react';
import {
    Table,
    Form,
    Button,
    Typography,
    Modal,
    Input,
    Select,
    Space,
    DatePicker,
    Card,
    message,
    Popover,
    Tabs,
    Collapse,
    Steps,
    Tooltip,
    Radio
} from 'antd'
import {InfoCircleOutlined, RightOutlined, DownOutlined, SearchOutlined} from '@ant-design/icons';
import moment from 'moment'
import cookie from 'react-cookies'
import Base64 from 'base-64'
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/date-picker/locale/zh_CN';
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'

const {Option} = Select;
const {Title, Text} = Typography;
const {TabPane} = Tabs;
const {Panel} = Collapse;
const {RangePicker} = DatePicker;
const {TextArea} = Input
const {Step} = Steps;

function Index(props) {
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv':"3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein':"444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an':"7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr':"f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11':'d4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass':'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }

    // 循环数组
    function search(arr, dst) {
        var i = arr.length;
        while (i -= 1) {
            if (arr[i] == dst) {
                return i;
            }
        }
        return false;
    }

    // 日期格式
    const dateFormat = 'YYYY-MM-DD'
    const layout1 = {
        labelCol: {span: 6},
        wrapperCol: {span: 14},
    }
    const [form] = Form.useForm()
    const [form3] = Form.useForm()

    /**
     * 添加操作表格
     */
        // 定义筛选功能开始
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    placeholder={`搜索${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        查询
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };
    // 选择的id
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 添加操作打开的key
    const [addExpandedRowKeys, setAddExpandedRowKeys] = useState([])
    // 选择人员分类
    const [tabType, setTabType] = useState('OTM')
    // 操作数据
    const [operationData, setOperationData] = useState([])
    // 多选设置
    const onSelectChange = (value) => {
        setSelectedRowKeys(value)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }
    // 表格标题
    let operationColumns = [
        {
            title: '操作名字',
            dataIndex: 'operation_name',
            key: 'operation_name',
            align: 'center',
            ...getColumnSearchProps('operation_name'),
        },
        {
            title: '业务分类',
            dataIndex: 'type_business',
            key: 'type_business',
            align: 'center',
            ...getColumnSearchProps('type_business'),
        },
        {
            title: '人员分类',
            dataIndex: 'type_people',
            key: 'type_people',
            align: 'center',
            ...getColumnSearchProps('type_people'),
        },
    ]

    const content = (
        <Table
            //表格列的配置描述
            columns={operationColumns}
            //数据数组
            dataSource={operationData.filter(item => {
                if (tabType == 'commodity') {
                    return item.type_business == '商品'
                } else if (tabType == 'interest') {
                    return item.type_business == '主利益点'
                } else if (tabType == 'effective') {
                    return item.type_business == '聚划算'
                } else {
                    return item.type_business == tabType
                }
            }).map(item => {
                return item
            })}
            rowSelection={rowSelection}
            pagination={{ pageSize: 5 }}
            //可展开设置
            expandable={{
                expandIcon: ({expanded, onExpand, record}) =>
                    expanded
                        ? (<DownOutlined onClick={e => onExpand(record, e)}/>)
                        : (<RightOutlined onClick={e => onExpand(record, e)}/>)
                ,
                expandedRowRender: record => (
                    <div>
                        <div style={{marginTop: "30px", display: 'flex'}}>
                            <Title level={5}
                                   style={{marginRight: "10px", height: "10px", lineHeight: "10px"}}>操作描述:</Title>
                            <Text type="secondary" style={{
                                marginRight: '20px',
                                height: "10px",
                                lineHeight: "10px"
                            }}>{record.operation_detail}</Text>
                        </div>
                        <div style={{marginTop: "30px", display: 'flex'}}>
                            <Title level={5}
                                   style={{marginRight: "10px", height: "10px", lineHeight: "10px"}}>操作参数名:</Title>
                            {
                                record.operation_parameters.map(item => {
                                    return <Text type="secondary" style={{
                                        marginRight: '20px',
                                        height: "10px",
                                        lineHeight: "10px"
                                    }}>{item}</Text>
                                })
                            }
                        </div>
                    </div>
                ),
                expandedRowKeys: addExpandedRowKeys,
                // 通过点击行来展开子行
                expandRowByClick: true,
                onExpand: (expanded, record) => {
                    let temp = []
                    // 判断是否是同一行进行点击
                    if (expanded) {
                        temp.push(record.id)
                    }
                    setAddExpandedRowKeys(temp)
                },
            }}
            rowKey={'id'}
        />
    )

    // 事件模型list
    const [eventModelList, setEventModelList] = useState([])
    // 事件详情
    const [eventDetail, setEventDetail] = useState({})
    // 事件id
    const [eventId, setEventId] = useState('')
    // 事件等级
    const [eventLevel, setEventLevel] = useState('')
    // 阶段详情
    const [partDetail, setPartDetail] = useState([])
    // 一个阶段详情
    const [onePartDetail, setOnePartDetail] = useState({})
    // 阶段开始时间
    const [partStartTime, setPartStartTime] = useState('')
    // 阶段结束时间
    const [partEndTime, setPartEndTime] = useState('')
    // OTM操作list
    const [otmOperationList, setOtmOperationList] = useState([])
    // CEM操作list
    const [cemOperationList, setCemOperationList] = useState([])
    // 商品操作list
    const [comOperationList, setComOperationList] = useState([])
    // 主利益点list
    const [interestList, setInterestList] = useState([])
    // 聚划算list
    const [effectiveList, setEffectiveList] = useState([])
    // 阶段key
    const [collapseKey, setCollapseKey] = useState('-1')
    // 事件模型id
    const [eventModelId, setEventModelId] = useState('')
    // 阶段数据
    const [stepsList, setStepsList] = useState([])
    const [stepIndex, setStepIndex] = useState(0)
    const [stage, setStage] = useState('')
    const [ finalStage , setFinalStage ] = useState(1)
    // 编辑单个操作
    const [operation, setOperation] = useState({})
    const [operationIndex, setOperationIndex] = useState('')
    const [deleteOperationIndex, setDeleteOperationIndex] = useState('')
    // 是否发生修改
    const [isUpdate, setIsUpdate] = useState(false)
    // 是否打开修改TOM动作弹框
    const [updateOtmOperation, setUpdateOtmOperation] = useState(false)
    // 是否打开删除OTM动作弹框
    const [deleteOtmOperation, setDeleteOtmOperation] = useState(false)
    // 是否打开修改CEM动作弹框
    const [updateCemOperation, setUpdateCemOperation] = useState(false)
    // 是否打开删除CEM动作弹框
    const [deleteCemOperation, setDeleteCemOperation] = useState(false)
    // 是否打开修改商品动作弹框
    const [updateComOperation, setUpdateComOperation] = useState(false)
    // 是否打开删除商品动作弹框
    const [deleteComOperation, setDeleteComOperation] = useState(false)
    // 是否打开修改主利益点弹框
    const [updateInterest, setUpdateInterest] = useState(false)
    // 是否打开删除主利益点弹框
    const [deleteInterest, setDeleteInterest] = useState(false)
    // 是否打开修改聚划算弹框
    const [updateEffective, setUpdateEffective] = useState(false)
    // 是否打开删除聚划算弹框
    const [deleteEffective, setDeleteEffective] = useState(false)
    // 全体员工
    const [employeeList, setEmployeeList] = useState([])
    useEffect(() => {
        // 查询事件模型
        getOtmEventModel()
        // 查询操作模型接口
        getOtmOperationModel()
        // 获取公司所有员工
        getAllEployees()
        // 临时接口
        // queryOtmEvent('6532363e-3837-4fbe-9775-317bf7704579')
    }, [])

    // 获取公司所有员工
    const getAllEployees = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryPMSysUser).then(res => {
            if (res.success) {
                setEmployeeList(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询事件模型
    const getOtmEventModel = () => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.name = ''
        dataProps.type = ''
        dataProps.level = ''
        dataProps.page = 1
        dataProps.page_size = 1000
        NbAxios(dataProps, "POST", servicePath.queryOtmEventModel).then(res => {
            if (res.success) {
                setEventModelList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询操作模型接口
    const getOtmOperationModel = () => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.type_business = ''
        dataProps.operation_name = ''
        dataProps.page = 1
        dataProps.page_size = 1000
        NbAxios(dataProps, "POST", servicePath.queryOtmOperationModel).then(res => {
            if (res.success) {
                setOperationData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取事件模型详情
    const getOtmModelDetail = (value) => {
        setEventModelId(value.split(',')[1]);
        getOtmEventModelDetail(value.split(',')[1])
        // getOtmStageModelList(value.split(',')[1])
        // let dataProps = {}
        // dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        // dataProps.roleId = cookie.load('role')
        // dataProps.id = value.split(',')[1]
        // NbAxios(dataProps, "POST", servicePath.queryOtmModelDetail).then(res=>{
        //     if (res.success) {
        //         console.log(res.data[0]);
        //         setDetailsData(res.data[0])
        //     } else {
        //         message.warn(res.errorMsg)
        //     }
        // })
    }

    // 查询事件阶段详情
    const getOtmEventModelDetail = (id) => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.id = id
        NbAxios(dataProps, "POST", servicePath.queryOtmEventModelDetail).then(res => {
            if (res.success) {
                form3.setFieldsValue(res.data[0])
                // 保存事件id
                // setEventId(res.data[0]['uuid']);
                // 事件等级
                setEventLevel(res.data[0]['level']);
                // 保存事件详情
                setEventDetail(res.data[0])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询事件阶段模型列表接口
    const getOtmStageModelList = (id) => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.otm_event_model_id = id
        NbAxios(dataProps, "POST", servicePath.queryOtmStageModelList).then(res => {
            if (res.success) {
                setPartDetail(res.data)
                let stepsList = []
                stepsList.push('开始阶段')
                res.data.map(item => {
                    stepsList.push(item.name)
                })
                setStepsList(stepsList)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 保存操作
    const saveOperation = () => {
        let flag = 0
        let errorMessage = ''

        if(otmOperationList.length == 0 && cemOperationList.length == 0 && comOperationList.length == 0 && interestList.length == 0 && effectiveList.length == 0){
            flag = 1
            errorMessage = '该阶段至少需要一个操作'
        }

        if (flag == 0) {
            if (partStartTime == '') {
                flag = 1
                errorMessage = '请先完善阶段时间'
            }
        }

        if (flag == 0) {
            otmOperationList.every(item => {
                if (item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value'] == '') {
                    flag = 1
                    errorMessage = '请先完善OTM部分的操作信息'
                    return false
                } else if (item.operation_detail == '') {
                    flag = 1
                    errorMessage = '请先完善OTM部分的操作信息'
                    return false
                }

            })
        }
        if (flag == 0) {
            cemOperationList.every(item => {
                if (item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value'] == '') {
                    flag = 1
                    errorMessage = '请先完善CEM部分的操作信息'
                    return false
                } else if (item.operation_detail == '') {
                    flag = 1
                    errorMessage = '请先完善CEM部分的操作信息'
                    return false
                }

            })
        }

        if (flag == 0) {
            comOperationList.every(item => {
                if (item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value'] == '') {
                    flag = 1
                    errorMessage = '请先完善商品部分的操作信息'
                    return false
                } else if (item.operation_detail == '') {
                    flag = 1
                    errorMessage = '请先完善商品部分的操作信息'
                    return false
                }

            })
        }

        if (flag == 0) {
            interestList.every(item => {
                if (item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value'] == '') {
                    flag = 1
                    errorMessage = '请先完善主利益点'
                    return false
                } else if (item.operation_detail == '') {
                    flag = 1
                    errorMessage = '请先完善主利益点部分的操作信息'
                    return false
                }

            })
        }

        if (flag == 0) {
            effectiveList.every(item => {
                if (item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value'] == '') {
                    flag = 1
                    errorMessage = '请先完善聚划算'
                    return false
                } else if (item.operation_detail == '') {
                    flag = 1
                    errorMessage = '请先完善聚划算部分的操作信息'
                    return false
                }

            })
        }


        if (flag == 1) {
            message.warn(errorMessage)
        } else {
            let stage = onePartDetail
            stage['priority_level'] = eventDetail['level']
            stage['name'] = collapseKey.split(',')[1]
            stage['start_time'] = partStartTime
            stage['end_time'] = partEndTime
            stage['otm_event_id'] = eventDetail['id']
            stage['otm_event_level'] = eventDetail['level']
            stage['otm_event_name'] = eventDetail['name']
            stage['otm_event_type'] = eventDetail['type']
            let data = {
                'operation': {
                    'CEM': cemOperationList,
                    'OTM': otmOperationList,
                    '主利益点': interestList,
                    '商品': comOperationList,
                    '聚划算': effectiveList
                },
                'stage': stage
            }
            let dataProps = {}
            dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
            dataProps.shopList = cookie.load('shopList')
            dataProps.roleId = cookie.load('role')
            dataProps.data = data
            dataProps.level = eventLevel
            dataProps.temporary_uuid = eventId
            dataProps.creator = cookie.load('name')
            dataProps.creator_id = cookie.load('id')
            dataProps.creator_username = cookie.load('email')
            dataProps.stage = stage
            console.log(dataProps);
            NbAxios(dataProps, "POST", servicePath.addOtmTemporaryOperation).then(res => {
                if (res.success) {
                    if(finalStage < stepsList.length){
                        let index = finalStage
                        index = index + 1
                        setFinalStage(index)
                    }
                    message.success('该阶段操作保存成功')
                    setIsUpdate(false)
                    getOtmOperationModelList(collapseKey)
                } else {
                    message.warn('该阶段操作数据有误')
                }
            })
        }
    }

    // 查询阶段操作
    const getOtmOperationModelList = (value) => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.otm_stage_model_id = value.split(',')[0]
        dataProps.temporary_uuid = eventId
        dataProps.stage = value.split(',')[2]
        NbAxios(dataProps, "POST", servicePath.queryOtmOperationModelList).then(res => {
            if (res.success) {
                console.log(res.data);
                setStage(res.data['stage']['stage']);
                setCemOperationList(res.data['operation']['CEM'])
                setOtmOperationList(res.data['operation']['OTM'])
                setComOperationList(res.data['operation']['商品'])
                setInterestList(res.data['operation']['主利益点'])
                setEffectiveList(res.data['operation']['聚划算'])
                setPartStartTime(res.data['stage']['start_time']);
                setPartEndTime(res.data['stage']['end_time']);
                setOnePartDetail(res.data['stage'])
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 提交事件
    const submitEvent = (value) => {
        value.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        value.shopList = cookie.load('shopList')
        value.roleId = cookie.load('role')
        value.otm_event_model_id = eventDetail.id
        value.otm_event_model_name = eventDetail.name
        value.start_time = eventDetail.start_time
        value.end_time = eventDetail.end_time
        let leader = value.leader.split(',')
        value.leader = leader[1]
        value.leader_id = leader[0]
        value.leader_username = leader[2]
        value.creator = cookie.load('name')
        value.creator_id = cookie.load('id')
        value.creator_username = Base64.decode(cookie.load('email'))
        value.uuid = eventId
        if (eventId == '') {
            // 创建事件
            NbAxios(value, "POST", servicePath.addOtmEvent).then(res => {
                if (res.success) {
                    message.success("事件创建成功")
                    setEventId(res.data['uuid'])
                    queryOtmEvent(res.data['uuid'])
                    getOtmStageModelList(eventModelId)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        } else {
            // 更新事件
            NbAxios(value, "POST", servicePath.updateOtmEvent).then(res => {
                if (res.success) {
                    message.success("事件修改成功")
                    queryOtmEvent(eventId)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    //查询事件接口
    const queryOtmEvent = (id) => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.uuid = id
        NbAxios(dataProps, "POST", servicePath.queryOtmEvent).then(res => {
            if (res.success) {
                setEventDetail(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div>
            <div style={{marginLeft: '50px', marginTop: '30px',}}>
                <Select placeholder="请选择事件模型" style={{width: 400, display: eventModelId == '' ? '' : 'none'}} showSearch
                        onChange={getOtmModelDetail}>
                    {
                        eventModelList.map(item => {
                            return <Option value={item.name + "," + item.id}>{item.name}</Option>
                        })
                    }
                </Select>
                <div style={{display: eventModelId == '' ? 'none' : ''}}>
                    <div style={{marginTop: 20}}>
                        <Form onFinish={submitEvent} form={form3}
                              style={{display: 'flex', justifyContent: 'space-around'}}>
                            <Form.Item label="事件级别" name="level">
                                <Text type="secondary">{form3.getFieldValue('level')}</Text>
                            </Form.Item>
                            <Form.Item label="事件类型" name="type">
                                <Text type="secondary">{form3.getFieldValue('type')}</Text>
                            </Form.Item>
                            {/* <Form.Item label="商品类别" style={{ display: form3.getFieldValue('type') == '上新' ? '' : 'none' }}
                                name="commodity_type">
                                <Select style={{ width: 200 }} showSearch>
                                    <Option value={'商场同款'}>{'商场同款'}</Option>
                                    <Option value={'奥莱'}>{'奥莱'}</Option>
                                    <Option value={'专供当季'}>{'专供当季'}</Option>
                                </Select>
                            </Form.Item> */}
                            <Form.Item label="事件名称" name="name">
                                <Input/>
                            </Form.Item>
                            <Form.Item label="事件时间" name="time">
                                <RangePicker locale={locale}
                                             onChange={(value, dateString) => {
                                                 eventDetail.start_time = dateString[0]
                                                 eventDetail.end_time = dateString[1]
                                             }}
                                />
                            </Form.Item>
                            <Form.Item label="事件负责人" name="leader">
                                <Select style={{width: 200}} showSearch>
                                    {
                                        employeeList.map(item => {
                                            return <Option
                                                value={item.key + ',' + item.name + ',' + item.email + ',' + item.acronym}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <div style={{marginLeft: 30}}>
                                {eventId == '' ? <Button htmlType="submit" type="primary">创建事件</Button> :
                                    <Button type="primary" htmlType="submit">更新事件</Button>}
                            </div>
                        </Form>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Steps current={stepIndex}>
                                {/* <Step title="Finished" description="This is a description." />
                            <Step title="In Progress" description="This is a description." />
                            <Step title="Waiting" description="This is a description." /> */}
                                {
                                    stepsList.map(item => {
                                        return <Step title={item} description={"现在正处于" + item}/>
                                    })
                                }
                            </Steps>
                        </div>
                        <div style={{marginLeft: 30, marginBottom: 10, display: eventId == '' ? 'none' : ''}}>
                            <Button style={{display: stepIndex == stepsList.length - 1 ? '' : 'none'}} type="primary"
                                    onClick={() => {
                                        if (isUpdate) {
                                            message.warn('请先保存该阶段的操作信息')
                                        } else if(finalStage != stepsList.length){
                                            message.warn('请先填写所有阶段的详情')
                                        } else {
                                            let dataProps = {}
                                            dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                                            dataProps.shopList = cookie.load('shopList')
                                            dataProps.roleId = cookie.load('role')
                                            dataProps.uuid = eventId
                                            NbAxios(dataProps, "POST", servicePath.addOtmEventAxis).then(res => {
                                                if (res.success) {
                                                    props.history.push({
                                                        'pathname': `/app/otm/${props.history.location.pathname.split('/')[3]}/confirmGannt`,
                                                        'state': {
                                                            'eventId': eventId,
                                                            'start_time': eventDetail.start_time
                                                        }
                                                    })
                                                } else {
                                                    message.warn(res.errorMsg)
                                                }
                                            })
                                        }

                                    }}>确认</Button>
                        </div>
                        {/* <Button type="primary" onClick={()=>{
                            props.history.push({ 'pathname': '/app/otm/confirmGannt', 'state': { 'eventId': 'b007aa23-ae23-4037-a119-1fa7db50d57f', 'start_time':'2021-06-01','partDetail':['618'] } })
                        }}>
                            确认
                        </Button> */}
                    </div>
                    {/* 阶段 */}
                    <div style={{display: eventId == '' ? 'none' : ''}}>
                        <Collapse activeKey={collapseKey} accordion onChange={(value) => {
                            if (isUpdate) {
                                message.warn('请先保存该阶段的操作信息')
                            } else {
                                setCemOperationList([])
                                setOtmOperationList([])
                                setComOperationList([])
                                setInterestList([])
                                setEffectiveList([])
                                setTabType('OTM')
                                setCollapseKey(value)
                                let index = search(stepsList, value.split(',')[1])
                                if (index > stepIndex) {
                                    setStepIndex(index)
                                }
                                getOtmOperationModelList(value)

                            }
                        }}>
                            {
                                partDetail.map((item, index) => {
                                    return <Panel header={item.name} key={item.id + ',' + item.name + ',' + item.stage}>
                                        <div style={{marginLeft: '3vw', marginBottom: '10px'}}>
                                            <Button type="primary" disabled={isUpdate ? false : true}
                                                    onClick={saveOperation}
                                            >保存阶段操作</Button>
                                        </div>
                                        <Form.Item label="阶段时间">
                                            {
                                                partStartTime == '' ?
                                                    <RangePicker
                                                        locale={locale}
                                                        onChange={(value, dateString) => {
                                                            if (dateString[0] == '') {
                                                                message.warn('请选择正确的时间')
                                                            } else {
                                                                setPartStartTime(dateString[0])
                                                                setPartEndTime(dateString[1])
                                                                setIsUpdate(true)
                                                            }
                                                        }}
                                                    />
                                                    : <RangePicker
                                                        value={[moment(partStartTime, dateFormat), moment(partEndTime, dateFormat)]}
                                                        locale={locale}
                                                        onChange={(value, dateString) => {
                                                            if (dateString[0] == '') {
                                                                message.warn('请选择正确的时间')
                                                            } else {
                                                                setPartStartTime(dateString[0])
                                                                setPartEndTime(dateString[1])
                                                                setIsUpdate(true)
                                                            }
                                                        }}
                                                    />
                                            }

                                        </Form.Item>
                                        <div style={{marginTop: 20}}>
                                            <Tabs value={tabType} onChange={(value) => setTabType(value)}>
                                                <TabPane tab="OTM" key="OTM"></TabPane>
                                                <TabPane tab="CEM" key="CEM"></TabPane>
                                                <TabPane tab="商品" key="commodity"></TabPane>
                                                <TabPane tab="主利益点" key="interest"></TabPane>
                                                <TabPane tab="聚划算" key="effective"></TabPane>
                                            </Tabs>
                                        </div>
                                        <Popover content={content} title="添加操作">
                                            <Button type="primary" style={{margin: 10, marginLeft: '3vw'}}
                                                    onClick={() => {
                                                        if (selectedRowKeys.length == 0) {
                                                            message.warn('请选择操作')
                                                        } else {
                                                            let value = {}
                                                            value.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                                                            value.shopList = cookie.load('shopList')
                                                            value.roleId = cookie.load('role')
                                                            value.operation_model_id = selectedRowKeys
                                                            NbAxios(value, "POST", servicePath.queryOtmOperationModelDetail).then(res => {
                                                                if (res.success) {
                                                                    console.log(tabType);
                                                                    if (tabType == 'OTM') {
                                                                        let data = JSON.parse(JSON.stringify(otmOperationList))
                                                                        res.data.map(item => {
                                                                            data.push(item)
                                                                        })
                                                                        setOtmOperationList(data)
                                                                    } else if (tabType == 'CEM') {
                                                                        let data = JSON.parse(JSON.stringify(cemOperationList))
                                                                        res.data.map(item => {
                                                                            data.push(item)
                                                                        })
                                                                        setCemOperationList(data)
                                                                    } else if (tabType == 'commodity') {
                                                                        let data = JSON.parse(JSON.stringify(comOperationList))
                                                                        res.data.map(item => {
                                                                            data.push(item)
                                                                        })
                                                                        setComOperationList(data)
                                                                    } else if (tabType == 'interest') {
                                                                        let data = JSON.parse(JSON.stringify(interestList))
                                                                        res.data.map(item => {
                                                                            data.push(item)
                                                                        })
                                                                        setInterestList(data)
                                                                    } else if (tabType == 'effective') {
                                                                        let data = JSON.parse(JSON.stringify(effectiveList))
                                                                        res.data.map(item => {
                                                                            data.push(item)
                                                                        })
                                                                        setEffectiveList(data)
                                                                    }
                                                                } else {
                                                                    message.warn(res.errorMsg)
                                                                }
                                                            })
                                                        }
                                                        setSelectedRowKeys([])
                                                    }}>添加操作</Button>
                                        </Popover>
                                        <div style={{display: 'flex', flexWrap: "wrap"}}>
                                            {
                                                tabType == 'OTM' ?
                                                    otmOperationList.map((item, index) => {
                                                        return <Card style={{width: '17vw', marginLeft: '3vw'}}
                                                                     title={item.operation_name}>
                                                            <div>
                                                                <Form>
                                                                    <Form.Item label="人员分类">
                                                                        {
                                                                            item.type_people
                                                                        }
                                                                    </Form.Item>
                                                                    <Form.Item label="操作截止时间">
                                                                        {
                                                                            item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value']
                                                                        }
                                                                    </Form.Item>
                                                                    <Form.Item label="操作描述">
                                                                        {
                                                                            item.operation_detail
                                                                        }
                                                                    </Form.Item>
                                                                    <Form.Item label="操作负责人">
                                                                        {
                                                                            item.leader
                                                                        }
                                                                    </Form.Item>
                                                                </Form>
                                                                <div style={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns: "80px 80px 1fr"
                                                                }}>
                                                                    <Button type="primary" onClick={() => {
                                                                        form.resetFields()
                                                                        setOperationIndex(index)
                                                                        setOperation(otmOperationList[index])
                                                                        setUpdateOtmOperation(true)
                                                                    }}>编辑</Button>
                                                                    <Button type="primary" style={{marginLeft: 10}}
                                                                            onClick={() => {
                                                                                setDeleteOperationIndex(index)
                                                                                setOperation(otmOperationList[index])
                                                                                setDeleteOtmOperation(true)
                                                                            }} danger>删除</Button>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        {
                                                                            // item.operation_parameters
                                                                            <span>{item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value'] == '' ?
                                                                                <Text type="danger">未编辑</Text> :
                                                                                <Text type="success">已编辑</Text>}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    })
                                                    : tabType == 'CEM' ?
                                                    cemOperationList.map((item, index) => {
                                                        return <Card style={{width: '17vw', marginLeft: '3vw'}}
                                                                     title={item.operation_name}>
                                                            <div>
                                                                <Form>
                                                                    <Form.Item label="人员分类">
                                                                        {
                                                                            item.type_people
                                                                        }
                                                                    </Form.Item>
                                                                    <Form.Item label="操作截止时间">
                                                                        {
                                                                            item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value']
                                                                        }
                                                                    </Form.Item>
                                                                    <Form.Item label="操作描述">
                                                                        {
                                                                            item.operation_detail
                                                                        }
                                                                    </Form.Item>
                                                                    <Form.Item label="操作负责人">
                                                                        {
                                                                            item.leader
                                                                        }
                                                                    </Form.Item>
                                                                </Form>
                                                                <div style={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns: "80px 80px 1fr"
                                                                }}>
                                                                    <Button type="primary" onClick={() => {
                                                                        form.resetFields()
                                                                        setOperationIndex(index)
                                                                        setOperation(cemOperationList[index])
                                                                        setUpdateCemOperation(true)
                                                                    }}>编辑</Button>
                                                                    <Button type="primary" style={{marginLeft: 10}}
                                                                            onClick={() => {
                                                                                setDeleteOperationIndex(index)
                                                                                setOperation(cemOperationList[index])
                                                                                setDeleteCemOperation(true)
                                                                            }} danger>删除</Button>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        {
                                                                            // item.operation_parameters
                                                                            <span>{item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value'] == '' ?
                                                                                <Text type="danger">未编辑</Text> :
                                                                                <Text type="success">已编辑</Text>}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    })
                                                    : tabType == 'commodity' ?
                                                        comOperationList.map((item, index) => {
                                                            return <Card style={{width: '17vw', marginLeft: '3vw'}}
                                                                         title={item.operation_name}>
                                                                <div>
                                                                    <Form>
                                                                        <Form.Item label="人员分类">
                                                                            {
                                                                                item.type_people
                                                                            }
                                                                        </Form.Item>
                                                                        <Form.Item label="操作截止时间">
                                                                            {
                                                                                item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value']
                                                                            }
                                                                        </Form.Item>
                                                                        <Form.Item label="操作描述">
                                                                            {
                                                                                item.operation_detail
                                                                            }
                                                                        </Form.Item>
                                                                        <Form.Item label="操作负责人">
                                                                            {
                                                                                item.leader
                                                                            }
                                                                        </Form.Item>
                                                                    </Form>
                                                                    <div style={{
                                                                        display: 'grid',
                                                                        gridTemplateColumns: "80px 80px 1fr"
                                                                    }}>
                                                                        <Button type="primary" onClick={() => {
                                                                            form.resetFields()
                                                                            setOperationIndex(index)
                                                                            setOperation(comOperationList[index])
                                                                            setUpdateComOperation(true)
                                                                        }}>编辑</Button>
                                                                        <Button type="primary" style={{marginLeft: 10}}
                                                                                onClick={() => {
                                                                                    setDeleteOperationIndex(index)
                                                                                    setOperation(comOperationList[index])
                                                                                    setDeleteComOperation(true)
                                                                                }} danger>删除</Button>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            justifyContent: 'center'
                                                                        }}>
                                                                            {
                                                                                // item.operation_parameters
                                                                                <span>{item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value'] == '' ?
                                                                                    <Text type="danger">未编辑</Text> :
                                                                                    <Text
                                                                                        type="success">已编辑</Text>}</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        })
                                                        : tabType == 'interest' ?
                                                            interestList.map((item, index) => {
                                                                return <Card style={{width: '17vw', marginLeft: '3vw'}}
                                                                             title={item.operation_name}>
                                                                    <div>
                                                                        <Form>
                                                                            <Form.Item label="人员分类">
                                                                                {
                                                                                    item.type_people
                                                                                }
                                                                            </Form.Item>
                                                                            <Form.Item label="操作截止时间">
                                                                                {
                                                                                    item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value']
                                                                                }
                                                                            </Form.Item>
                                                                            <Form.Item label="操作描述">
                                                                                {
                                                                                    item.operation_detail
                                                                                }
                                                                            </Form.Item>
                                                                            <Form.Item label="操作负责人">
                                                                                {
                                                                                    item.leader
                                                                                }
                                                                            </Form.Item>
                                                                        </Form>
                                                                        <div style={{
                                                                            display: 'grid',
                                                                            gridTemplateColumns: "80px 80px 1fr"
                                                                        }}>
                                                                            <Button type="primary" onClick={() => {
                                                                                form.resetFields()
                                                                                setOperationIndex(index)
                                                                                setOperation(interestList[index])
                                                                                setUpdateInterest(true)
                                                                            }}>编辑</Button>
                                                                            <Button type="primary"
                                                                                    style={{marginLeft: 10}}
                                                                                    onClick={() => {
                                                                                        setDeleteOperationIndex(index)
                                                                                        setOperation(interestList[index])
                                                                                        setDeleteInterest(true)
                                                                                    }} danger>删除</Button>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                justifyContent: 'center'
                                                                            }}>
                                                                                {
                                                                                    // item.operation_parameters
                                                                                    <span>{item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value'] == '' ?
                                                                                        <Text type="danger">未编辑</Text> :
                                                                                        <Text
                                                                                            type="success">已编辑</Text>}</span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            }) : tabType == 'effective' ?
                                                                effectiveList.map((item, index) => {
                                                                    return <Card
                                                                        style={{width: '17vw', marginLeft: '3vw'}}
                                                                        title={item.operation_name}>
                                                                        <div>
                                                                            <Form>
                                                                                <Form.Item label="人员分类">
                                                                                    {
                                                                                        item.type_people
                                                                                    }
                                                                                </Form.Item>
                                                                                <Form.Item label="操作截止时间">
                                                                                    {
                                                                                        item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value']
                                                                                    }
                                                                                </Form.Item>
                                                                                <Form.Item label="操作描述">
                                                                                    {
                                                                                        item.operation_detail
                                                                                    }
                                                                                </Form.Item>
                                                                                <Form.Item label="操作负责人">
                                                                                    {
                                                                                        item.leader
                                                                                    }
                                                                                </Form.Item>
                                                                            </Form>
                                                                            <div style={{
                                                                                display: 'grid',
                                                                                gridTemplateColumns: "80px 80px 1fr"
                                                                            }}>
                                                                                <Button type="primary" onClick={() => {
                                                                                    form.resetFields()
                                                                                    setOperationIndex(index)
                                                                                    setOperation(effectiveList[index])
                                                                                    setUpdateEffective(true)
                                                                                }}>编辑</Button>
                                                                                <Button type="primary"
                                                                                        style={{marginLeft: 10}}
                                                                                        onClick={() => {
                                                                                            setDeleteOperationIndex(index)
                                                                                            setOperation(effectiveList[index])
                                                                                            setDeleteEffective(true)
                                                                                        }} danger>删除</Button>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    justifyContent: 'center'
                                                                                }}>
                                                                                    {
                                                                                        // item.operation_parameters
                                                                                        <span>{item.operation_parameters[item.operation_parameters.map(item => item.operation).indexOf('操作截止时间')]['value'] == '' ?
                                                                                            <Text
                                                                                                type="danger">未编辑</Text> :
                                                                                            <Text
                                                                                                type="success">已编辑</Text>}</span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Card>
                                                                }) : <span></span>
                                            }
                                        </div>
                                    </Panel>
                                })
                            }
                        </Collapse>
                    </div>
                </div>

                <div style={{width: '100vw', display: eventModelId == '' ? '' : 'none'}}>
                    <Title level={3} style={{display: 'flex', justifyContent: 'center'}}>请先选择事件模型</Title>
                </div>


            </div>
            {/* 删除OTM操作 */}
            <Modal
                title="删除OTM操作"
                visible={deleteOtmOperation}
                onCancel={() => {
                    setOperation({})
                    setDeleteOperationIndex('')
                    setDeleteOtmOperation(false)
                }}
                onOk={() => {
                    let data = JSON.parse(JSON.stringify(otmOperationList))
                    data.splice(deleteOperationIndex, 1)
                    setOtmOperationList(data)
                    setIsUpdate(true)
                    setDeleteOtmOperation(false)
                    setOperation({})
                    setDeleteOperationIndex('')
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                <span>是否要删除该 "{operation.operation_name}"操作</span>
            </Modal>
            {/* 删除CEM操作 */}
            <Modal
                title="删除CEM操作"
                visible={deleteCemOperation}
                onCancel={() => {
                    setOperation({})
                    setDeleteOperationIndex('')
                    setDeleteCemOperation(false)
                }}
                onOk={() => {
                    let data = JSON.parse(JSON.stringify(cemOperationList))
                    data.splice(deleteOperationIndex, 1)
                    setCemOperationList(data)
                    setIsUpdate(true)
                    setDeleteCemOperation(false)
                    setOperation({})
                    setDeleteOperationIndex('')
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                <span>是否要删除该 "{operation.operation_name}"操作</span>
            </Modal>
            {/* 删除商品操作 */}
            <Modal
                title="删除商品操作"
                visible={deleteComOperation}
                onCancel={() => {
                    setOperation({})
                    setDeleteOperationIndex('')
                    setDeleteComOperation(false)
                }}
                onOk={() => {
                    let data = JSON.parse(JSON.stringify(comOperationList))
                    data.splice(deleteOperationIndex, 1)
                    setComOperationList(data)
                    setIsUpdate(true)
                    setDeleteComOperation(false)
                    setOperation({})
                    setDeleteOperationIndex('')
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                <span>是否要删除该 "{operation.operation_name}"操作</span>
            </Modal>
            {/* 删除主利益点 */}
            <Modal
                title="删除主利益点"
                visible={deleteInterest}
                onCancel={() => {
                    setOperation({})
                    setDeleteOperationIndex('')
                    setDeleteInterest(false)
                }}
                onOk={() => {
                    let data = JSON.parse(JSON.stringify(interestList))
                    data.splice(deleteOperationIndex, 1)
                    setInterestList(data)
                    setIsUpdate(true)
                    setDeleteInterest(false)
                    setOperation({})
                    setDeleteOperationIndex('')
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                <span>是否要删除该 "{operation.operation_name}"主利益点</span>
            </Modal>
            {/* 删除聚划算 */}
            <Modal
                title="删除聚划算"
                visible={deleteEffective}
                onCancel={() => {
                    setOperation({})
                    setDeleteOperationIndex('')
                    setDeleteEffective(false)
                }}
                onOk={() => {
                    let data = JSON.parse(JSON.stringify(effectiveList))
                    data.splice(deleteOperationIndex, 1)
                    setEffectiveList(data)
                    setIsUpdate(true)
                    setDeleteEffective(false)
                    setOperation({})
                    setDeleteOperationIndex('')
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                <span>是否要删除该 "{operation.operation_name}"主利益点</span>
            </Modal>
            {/* 编辑OTM动作 */}
            <Modal
                title="编辑OTM动作"
                visible={updateOtmOperation}
                onCancel={() => {
                    setOperation({})
                    setOperationIndex('')
                    form.resetFields()
                    setUpdateOtmOperation(false)
                }}
                onOk={() => {
                    setOperation({})
                    form.resetFields()
                    setIsUpdate(true)
                    setOperationIndex('')
                    setUpdateOtmOperation(false)
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                {
                    updateOtmOperation == true ?
                        otmOperationList[operationIndex] == undefined ? <span>111</span>
                            : <Form form={form} labelAlign="left" {...layout1}
                                    style={{display: "flex", flexWrap: 'wrap'}}>
                                <Form.Item label="操作名称" name='operation_name'
                                           style={{width: "50%"}}>{operation.operation_name}</Form.Item>
                                <Form.Item label="人员分类" name='type_people'
                                           style={{width: "50%"}}>{operation.type_people}</Form.Item>
                                <Form.Item label="操作人" name='leader' style={{width: "50%"}}>
                                    {/* <Input defaultValue={otmOperationList[operationIndex]['leader']} onChange={(e) => {
                                    otmOperationList[operationIndex]['leader'] = e.target.value
                                }} /> */}
                                    <Select style={{width: 200}} showSearch
                                            defaultValue={otmOperationList[operationIndex]['leader']} onChange={(value) => {
                                        let leader = value.split(',')
                                        otmOperationList[operationIndex]['leader_id'] = leader[0]
                                        otmOperationList[operationIndex]['leader'] = leader[1]
                                        otmOperationList[operationIndex]['leader_username'] = leader[2]
                                    }}>
                                        {
                                            employeeList.map(item => {
                                                return <Option
                                                    value={item.key + ',' + item.name + ',' + item.email + ',' + item.acronym}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form form={form} style={{width: "100%"}}>
                                    <Form.Item label="操作描述" name='operation_detail'>
                                        <TextArea rows={4}
                                                  defaultValue={otmOperationList[operationIndex]['operation_detail']}
                                                  onChange={(e) => {
                                                      otmOperationList[operationIndex]['operation_detail'] = e.target.value
                                                  }}/>
                                    </Form.Item>
                                </Form>
                                {
                                    operation.operation_parameters.map((item, index) => {
                                        return <Form.Item label={item.operation} name={item.operation}
                                                          style={{width: "50%"}}>
                                            {
                                                item.operation == '操作截止时间' ?
                                                    <DatePicker style={{width: 200}}
                                                                locale={locale}
                                                                defaultValue={otmOperationList[operationIndex]['operation_parameters'][index]['value'] == '' ? '' : moment(item.value, dateFormat)}
                                                                onChange={(date, dateString) => {
                                                                    if (dateString == '') {
                                                                        message.warn('请选择正确的时间')
                                                                        return false
                                                                    } else {
                                                                        otmOperationList[operationIndex]['operation_parameters'][index]['value'] = dateString
                                                                        // detailsData.part_info[index]['operation'][i]['operation_parameters'][y]['value'] = dateString
                                                                    }
                                                                }}
                                                    />
                                                    : item.operation == '操作持续时间' ?
                                                    <Tooltip title="默认展示系统预留的操作持续时间">
                                                        <Input style={{width: 200}}
                                                               defaultValue={otmOperationList[operationIndex]['operation_parameters'][index]['value']}
                                                               addonAfter={"天"}
                                                               onChange={(e) => {
                                                                   otmOperationList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                               }}
                                                        />
                                                    </Tooltip>
                                                    : item.operation == '每日任务' ?
                                                        <div>
                                                            <Radio.Group onChange={(e)=>{
                                                                otmOperationList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                            }} defaultValue={otmOperationList[operationIndex]['operation_parameters'][index]['value']}>
                                                                <Radio value={1}>是</Radio>
                                                                <Radio value={0}>否</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                    :<Input style={{width: 200}}
                                                             defaultValue={otmOperationList[operationIndex]['operation_parameters'][index]['value']}
                                                             onChange={(e) => {
                                                                 otmOperationList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                             }}
                                                    />

                                            }

                                        </Form.Item>
                                    })
                                }
                            </Form>
                        : <span></span>
                }
            </Modal>
            {/* 编辑CEM动作 */}
            <Modal
                title="编辑CEM动作"
                visible={updateCemOperation}
                onCancel={() => {
                    setOperation({})
                    setOperationIndex('')
                    form.resetFields()
                    setUpdateCemOperation(false)
                }}
                onOk={() => {
                    setOperation({})
                    form.resetFields()
                    setIsUpdate(true)
                    setOperationIndex('')
                    setUpdateCemOperation(false)
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                {
                    updateCemOperation == true ?
                        cemOperationList[operationIndex] == undefined ? <span>111</span>
                            : <Form form={form} labelAlign="left" {...layout1}
                                    style={{display: "flex", flexWrap: 'wrap'}}>
                                <Form.Item label="操作名称" name='operation_name'
                                           style={{width: "50%"}}>{operation.operation_name}</Form.Item>
                                <Form.Item label="人员分类" name='type_people'
                                           style={{width: "50%"}}>{operation.type_people}</Form.Item>
                                <Form.Item label="操作人" name='leader' style={{width: "50%"}}>
                                    {/* <Input defaultValue={cemOperationList[operationIndex]['leader']} onChange={(e) => {
                                    cemOperationList[operationIndex]['leader'] = e.target.value
                                    }} /> */}
                                    <Select style={{width: 200}} showSearch
                                            defaultValue={cemOperationList[operationIndex]['leader']} onChange={(value) => {
                                        let leader = value.split(',')
                                        cemOperationList[operationIndex]['leader_id'] = leader[0]
                                        cemOperationList[operationIndex]['leader'] = leader[1]
                                        cemOperationList[operationIndex]['leader_username'] = leader[2]
                                    }}>
                                        {
                                            employeeList.map(item => {
                                                return <Option
                                                    value={item.key + ',' + item.name + ',' + item.email + ',' + item.acronym}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form form={form} style={{width: "100%"}}>
                                    <Form.Item label="操作描述" name='operation_detail'>
                                        <TextArea rows={4}
                                                  defaultValue={cemOperationList[operationIndex]['operation_detail']}
                                                  onChange={(e) => {
                                                      cemOperationList[operationIndex]['operation_detail'] = e.target.value
                                                  }}/>
                                    </Form.Item>
                                </Form>
                                {
                                    operation.operation_parameters.map((item, index) => {
                                        return <Form.Item label={item.operation} name={item.operation}
                                                          style={{width: "50%"}}>
                                            {
                                                item.operation == '操作截止时间' ?
                                                    <DatePicker style={{width: 200}}
                                                                locale={locale}
                                                                defaultValue={cemOperationList[operationIndex]['operation_parameters'][index]['value'] == '' ? '' : moment(item.value, dateFormat)}
                                                                onChange={(date, dateString) => {
                                                                    if (dateString == '') {
                                                                        message.warn('请选择正确的时间')
                                                                        return false
                                                                    } else {
                                                                        cemOperationList[operationIndex]['operation_parameters'][index]['value'] = dateString
                                                                        // detailsData.part_info[index]['operation'][i]['operation_parameters'][y]['value'] = dateString
                                                                    }
                                                                }}
                                                    />
                                                    : item.operation == '操作持续时间' ?
                                                    <Tooltip title="默认展示系统预留的操作持续时间">
                                                        <Input style={{width: 200}}
                                                               defaultValue={cemOperationList[operationIndex]['operation_parameters'][index]['value']}
                                                               addonAfter={"天"}
                                                               onChange={(e) => {
                                                                   cemOperationList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                               }}
                                                        />
                                                    </Tooltip>
                                                    : item.operation == '每日任务' ?
                                                        <div>
                                                            <Radio.Group onChange={(e)=>{
                                                                cemOperationList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                            }} defaultValue={cemOperationList[operationIndex]['operation_parameters'][index]['value']}>
                                                                <Radio value={1}>是</Radio>
                                                                <Radio value={0}>否</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                :<Input style={{width: 200}}
                                                             defaultValue={cemOperationList[operationIndex]['operation_parameters'][index]['value']}
                                                             onChange={(e) => {
                                                                 cemOperationList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                             }}
                                                    />

                                            }

                                        </Form.Item>
                                    })
                                }
                            </Form>
                        : <span></span>
                }
            </Modal>
            {/* 编辑商品动作 */}
            <Modal
                title="编辑商品动作"
                visible={updateComOperation}
                onCancel={() => {
                    setOperation({})
                    setOperationIndex('')
                    form.resetFields()
                    setUpdateComOperation(false)
                }}
                onOk={() => {
                    setOperation({})
                    form.resetFields()
                    setIsUpdate(true)
                    setOperationIndex('')
                    setUpdateComOperation(false)
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                {
                    updateComOperation == true ?
                        comOperationList[operationIndex] == undefined ? <span>111</span>
                            : <Form form={form} labelAlign="left" {...layout1}
                                    style={{display: "flex", flexWrap: 'wrap'}}>
                                <Form.Item label="操作名称" name='operation_name'
                                           style={{width: "50%"}}>{operation.operation_name}</Form.Item>
                                <Form.Item label="人员分类" name='type_people'
                                           style={{width: "50%"}}>{operation.type_people}</Form.Item>
                                <Form.Item label="操作人" name='leader' style={{width: "50%"}}>
                                    {/* <Input defaultValue={comOperationList[operationIndex]['leader']} onChange={(e) => {
                                        comOperationList[operationIndex]['leader'] = e.target.value
                                    }} /> */}
                                    <Select style={{width: 200}} showSearch
                                            defaultValue={comOperationList[operationIndex]['leader']} onChange={(value) => {
                                        let leader = value.split(',')
                                        comOperationList[operationIndex]['leader_id'] = leader[0]
                                        comOperationList[operationIndex]['leader'] = leader[1]
                                        comOperationList[operationIndex]['leader_username'] = leader[2]
                                    }}>
                                        {
                                            employeeList.map(item => {
                                                return <Option
                                                    value={item.key + ',' + item.name + ',' + item.email + ',' + item.acronym}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form form={form} style={{width: "100%"}}>
                                    <Form.Item label="操作描述" name='operation_detail'>
                                        <TextArea rows={4}
                                                  defaultValue={comOperationList[operationIndex]['operation_detail']}
                                                  onChange={(e) => {
                                                      comOperationList[operationIndex]['operation_detail'] = e.target.value
                                                  }}/>
                                    </Form.Item>
                                </Form>
                                {
                                    operation.operation_parameters.map((item, index) => {
                                        return <Form.Item label={item.operation} name={item.operation}
                                                          style={{width: "50%"}}>
                                            {
                                                item.operation == '操作截止时间' ?
                                                    <DatePicker style={{width: 200}}
                                                                locale={locale}
                                                                defaultValue={comOperationList[operationIndex]['operation_parameters'][index]['value'] == '' ? '' : moment(item.value, dateFormat)}
                                                                onChange={(date, dateString) => {
                                                                    if (dateString == '') {
                                                                        message.warn('请选择正确的时间')
                                                                        return false
                                                                    } else {
                                                                        comOperationList[operationIndex]['operation_parameters'][index]['value'] = dateString
                                                                        // detailsData.part_info[index]['operation'][i]['operation_parameters'][y]['value'] = dateString
                                                                    }
                                                                }}
                                                    />
                                                    : item.operation == '操作持续时间' ?
                                                    <Tooltip title="默认展示系统预留的操作持续时间">
                                                        <Input style={{width: 200}}
                                                               defaultValue={comOperationList[operationIndex]['operation_parameters'][index]['value']}
                                                               addonAfter={"天"}
                                                               onChange={(e) => {
                                                                   comOperationList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                               }}
                                                        />
                                                    </Tooltip>
                                                    : item.operation == '每日任务' ?
                                                        <div>
                                                            <Radio.Group onChange={(e)=>{
                                                                comOperationList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                            }} defaultValue={comOperationList[operationIndex]['operation_parameters'][index]['value']}>
                                                                <Radio value={1}>是</Radio>
                                                                <Radio value={0}>否</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                :<Input style={{width: 200}}
                                                             defaultValue={comOperationList[operationIndex]['operation_parameters'][index]['value']}
                                                             onChange={(e) => {
                                                                 comOperationList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                             }}
                                                    />

                                            }

                                        </Form.Item>
                                    })
                                }
                            </Form>
                        : <span></span>
                }
            </Modal>
            {/* 编辑主利益点 */}
            <Modal
                title="编辑主利益点"
                visible={updateInterest}
                onCancel={() => {
                    setOperation({})
                    setOperationIndex('')
                    form.resetFields()
                    setUpdateInterest(false)
                }}
                onOk={() => {
                    setOperation({})
                    form.resetFields()
                    setIsUpdate(true)
                    setOperationIndex('')
                    setUpdateInterest(false)
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                {
                    updateInterest == true ?
                        interestList[operationIndex] == undefined ? <span>111</span>
                            : <Form form={form} labelAlign="left" {...layout1}
                                    style={{display: "flex", flexWrap: 'wrap'}}>
                                <Form.Item label="操作名称" name='operation_name'
                                           style={{width: "50%"}}>{operation.operation_name}</Form.Item>
                                <Form.Item label="人员分类" name='type_people'
                                           style={{width: "50%"}}>{operation.type_people}</Form.Item>
                                <Form.Item label="操作人" name='leader' style={{width: "50%"}}>
                                    {/* <Input defaultValue={interestList[operationIndex]['leader']} onChange={(e) => {
                                    interestList[operationIndex]['leader'] = e.target.value
                                }} /> */}
                                    <Select style={{width: 200}} showSearch
                                            defaultValue={interestList[operationIndex]['leader']} onChange={(value) => {
                                        let leader = value.split(',')
                                        interestList[operationIndex]['leader_id'] = leader[0]
                                        interestList[operationIndex]['leader'] = leader[1]
                                        interestList[operationIndex]['leader_username'] = leader[2]
                                    }}>
                                        {
                                            employeeList.map(item => {
                                                return <Option
                                                    value={item.key + ',' + item.name + ',' + item.email + ',' + item.acronym}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form form={form} style={{width: "100%"}}>
                                    <Form.Item label="操作描述" name='operation_detail'>
                                        <TextArea rows={4} defaultValue={interestList[operationIndex]['operation_detail']}
                                                  onChange={(e) => {
                                                      interestList[operationIndex]['operation_detail'] = e.target.value
                                                  }}/>
                                    </Form.Item>
                                </Form>
                                {
                                    operation.operation_parameters.map((item, index) => {
                                        return <Form.Item label={item.operation} name={item.operation}
                                                          style={{width: "50%"}}>
                                            {
                                                item.operation == '操作截止时间' ?
                                                    <DatePicker style={{width: 200}}
                                                                locale={locale}
                                                                defaultValue={interestList[operationIndex]['operation_parameters'][index]['value'] == '' ? '' : moment(item.value, dateFormat)}
                                                                onChange={(date, dateString) => {
                                                                    if (dateString == '') {
                                                                        message.warn('请选择正确的时间')
                                                                        return false
                                                                    } else {
                                                                        interestList[operationIndex]['operation_parameters'][index]['value'] = dateString
                                                                        // detailsData.part_info[index]['operation'][i]['operation_parameters'][y]['value'] = dateString
                                                                    }
                                                                }}
                                                    />
                                                    : item.operation == '操作持续时间' ?
                                                    <Tooltip title="默认展示系统预留的操作持续时间">
                                                        <Input style={{width: 200}}
                                                               defaultValue={interestList[operationIndex]['operation_parameters'][index]['value']}
                                                               addonAfter={"天"}
                                                               onChange={(e) => {
                                                                   interestList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                               }}
                                                        />
                                                    </Tooltip>
                                                    :item.operation == '每日任务' ?
                                                        <div>
                                                            <Radio.Group onChange={(e)=>{
                                                                interestList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                            }} defaultValue={interestList[operationIndex]['operation_parameters'][index]['value']}>
                                                                <Radio value={1}>是</Radio>
                                                                <Radio value={0}>否</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                : <Input style={{width: 200}}
                                                             defaultValue={interestList[operationIndex]['operation_parameters'][index]['value']}
                                                             onChange={(e) => {
                                                                 interestList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                             }}
                                                    />

                                            }

                                        </Form.Item>
                                    })
                                }
                            </Form>
                        : <span></span>
                }
            </Modal>

            {/* 编辑聚划算 */}
            <Modal
                title="编辑聚划算"
                visible={updateEffective}
                onCancel={() => {
                    setOperation({})
                    setOperationIndex('')
                    form.resetFields()
                    setUpdateEffective(false)
                }}
                onOk={() => {
                    setOperation({})
                    form.resetFields()
                    setIsUpdate(true)
                    setOperationIndex('')
                    setUpdateEffective(false)
                }}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={800}
            >
                {
                    updateEffective == true ?
                        effectiveList[operationIndex] == undefined ? <span>111</span>
                            : <Form form={form} labelAlign="left" {...layout1}
                                    style={{display: "flex", flexWrap: 'wrap'}}>
                                <Form.Item label="操作名称" name='operation_name'
                                           style={{width: "50%"}}>{operation.operation_name}</Form.Item>
                                <Form.Item label="人员分类" name='type_people'
                                           style={{width: "50%"}}>{operation.type_people}</Form.Item>
                                <Form.Item label="操作人" name='leader' style={{width: "50%"}}>
                                    {/* <Input defaultValue={interestList[operationIndex]['leader']} onChange={(e) => {
                                    interestList[operationIndex]['leader'] = e.target.value
                                }} /> */}
                                    <Select style={{width: 200}} showSearch
                                            defaultValue={effectiveList[operationIndex]['leader']} onChange={(value) => {
                                        let leader = value.split(',')
                                        effectiveList[operationIndex]['leader_id'] = leader[0]
                                        effectiveList[operationIndex]['leader'] = leader[1]
                                        effectiveList[operationIndex]['leader_username'] = leader[2]
                                    }}>
                                        {
                                            employeeList.map(item => {
                                                return <Option
                                                    value={item.key + ',' + item.name + ',' + item.email + ',' + item.acronym}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form form={form} style={{width: "100%"}}>
                                    <Form.Item label="操作描述" name='operation_detail'>
                                        <TextArea rows={4} defaultValue={effectiveList[operationIndex]['operation_detail']}
                                                  onChange={(e) => {
                                                      effectiveList[operationIndex]['operation_detail'] = e.target.value
                                                  }}/>
                                    </Form.Item>
                                </Form>
                                {
                                    operation.operation_parameters.map((item, index) => {
                                        return <Form.Item label={item.operation} name={item.operation}
                                                          style={{width: "50%"}}>
                                            {
                                                item.operation == '操作截止时间' ?
                                                    <DatePicker style={{width: 200}}
                                                                locale={locale}
                                                                defaultValue={effectiveList[operationIndex]['operation_parameters'][index]['value'] == '' ? '' : moment(item.value, dateFormat)}
                                                                onChange={(date, dateString) => {
                                                                    if (dateString == '') {
                                                                        message.warn('请选择正确的时间')
                                                                        return false
                                                                    } else {
                                                                        effectiveList[operationIndex]['operation_parameters'][index]['value'] = dateString
                                                                        // detailsData.part_info[index]['operation'][i]['operation_parameters'][y]['value'] = dateString
                                                                    }
                                                                }}
                                                    />
                                                    : item.operation == '操作持续时间' ?
                                                    <Tooltip title="默认展示系统预留的操作持续时间">
                                                        <Input style={{width: 200}}
                                                               defaultValue={effectiveList[operationIndex]['operation_parameters'][index]['value']}
                                                               addonAfter={"天"}
                                                               onChange={(e) => {
                                                                   effectiveList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                               }}
                                                        />
                                                    </Tooltip>
                                                    : item.operation == '每日任务' ?
                                                        <div>
                                                            <Radio.Group onChange={(e)=>{
                                                                effectiveList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                            }} defaultValue={effectiveList[operationIndex]['operation_parameters'][index]['value']}>
                                                                <Radio value={1}>是</Radio>
                                                                <Radio value={0}>否</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                :<Input style={{width: 200}}
                                                             defaultValue={effectiveList[operationIndex]['operation_parameters'][index]['value']}
                                                             onChange={(e) => {
                                                                 effectiveList[operationIndex]['operation_parameters'][index]['value'] = e.target.value
                                                             }}
                                                    />

                                            }

                                        </Form.Item>
                                    })
                                }
                            </Form>
                        : <span></span>
                }
            </Modal>
        </div>
    )

}

export default Index