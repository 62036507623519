import React, { Component, useState, useEffect} from 'react';
import { Button, Input, message, Modal, Typography } from 'antd';
import style from './index.module.css'
import RedEnvelopeOpen from './img/red_envelope_open.png'
import RedEnvelopeClose from './img/red_envelope_close.png'
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl';
const {Text} = Typography


function Index() {

    const [ redEnvelopeDict , setRedEnvelopeDict ] = useState([])

    const [ redEnvelopeObj , setRedEnvelopeObj ] = useState({})

    // 打开红包
    const [ openRedEnvelope , setOpenRedEnvelope] = useState(false)
    // 输入名字
    const [ inputName , setInputName ] = useState(false)
    // 抽奖人名
    const [ name , setName ] = useState('')
    // 抽奖码
    const [ prizeCode, setPrizeCode ] = useState('')


    useEffect(()=>{
        setInputName(true)
    },[])

    // 筛选奖品
    const getPrize = ()=>{
        let dataProps = {
            'code': prizeCode
        }
        NbAxios(dataProps, "POST", servicePath.queryAnniversaryLuckDrawPrize).then(res => {
            if (res.success) {
                setInputName(false)
                setRedEnvelopeObj({})
                setRedEnvelopeDict(res.data['prize_list']);
                setOpenRedEnvelope(false)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => { 
            message.warn('查询接口报错，请联系IT帮忙解决')
        })
    }

    const imgLazy = ()=>{
        redEnvelopeObj['pic_path'] = ""
    }

    return (
        <div className={style.background}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width:'56vw',  margin: "0 auto", flexWrap:'wrap',  }}>
                {
                    redEnvelopeDict.map((item, index)=>{
                        return <div style={{ width: "12vw", marginRight:'2vw' }}>
                            {
                               item.isOpen ? <img src={RedEnvelopeOpen} /> : <img src={RedEnvelopeClose} onClick={()=>{
                                    let redEnvelopeObj = [...redEnvelopeDict]
                                    redEnvelopeObj[index]['isOpen'] = 1
                                    setRedEnvelopeDict(redEnvelopeObj)
                                    setOpenRedEnvelope(true)
                                    setRedEnvelopeObj(item)
                               }}/>
                            }
                        </div>
                    })
                }
            </div>
            <Modal
                visible={openRedEnvelope}
                onCancel={()=>{
                    let dataProps = {
                        'prize_uuid':redEnvelopeObj['uuid'],
                        'owner':name,
                        'code': prizeCode
                    }
                    NbAxios(dataProps, "POST", servicePath.updateAnniversaryLuckDrawPrize).then(res => {
                        if (res.success) {
                            setPrizeCode('')
                            setName('')
                            setRedEnvelopeDict([])
                            setOpenRedEnvelope(false)
                            setInputName(true)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    }).catch((err) => { 
                        message.warn('查询接口报错，请联系IT帮忙解决')
                    })
                }}
                footer={null}
                closable={false}
                centered={true}
                bodyStyle={{background:'#D62929', padding:'20px'}}
                width={800}
            >
                <div style={{width:'100%', height:600, border:'2px solid #ffff00'}}>
                    <div style={{display:'flex', justifyContent:'center', width:"100%"}}>
                        <div style={{color:'#ffff00', fontSize:70, fontWeight:'bolder' }}>{`${name}中奖啦！`}</div>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', width:"100%", marginTop:5, height:300}}>
                        <img src={redEnvelopeObj['pic_path']} onLoad={imgLazy}/>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', width:"100%", marginTop:20}}>
                        <div style={{color:'#ffff00', fontSize:50, fontWeight:'bolder' }}>{redEnvelopeObj['name']}</div>
                    </div>
                </div>
            </Modal>

            <Modal
                visible={inputName}
                onCancel={()=>{
                    setInputName(false)
                }}
                footer={null}
                closable={false}
                centered={true}
                maskClosable={false}
                bodyStyle={{background:'#D62929', padding:'20px'}}
                width={600}
            >
                <div style={{width:'100%', height:200, border:'2px solid #ffff00', display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                    {
                        name == '' ? <div style={{display:'flex', justifyContent:'center' }}> 
                            <b style={{marginRight:10, color:'#fff', fontWeight:'bolder', fontSize:20}}>邀请码:</b>
                            <Input style={{width:150}} value={prizeCode} onChange={(e)=>{
                                setPrizeCode(e.target.value)
                            }}/>
                        </div>
                        : <div style={{display:'flex', justifyContent:'center' }}> 
                        <b style={{marginRight:10, color:'#fff', fontWeight:'bolder', fontSize:20}}>抽奖人:</b>
                        <Text style={{color:'#fff', width:100, fontSize:20, fontWeight:"bolder"}}>{name}</Text>
                    </div>
                    }
                    <Button type="primary" style={{marginTop:20, backgroundColor:'#e5b54c', color:'#fff', border:'1px solid #e5b54c'}} onClick={()=>{
                        if(name == ''){
                            let dataProps = {
                                'code': prizeCode
                            }
                            NbAxios(dataProps, "POST", servicePath.queryAnniversaryLuckDrawPrizeCode).then(res => {
                                if (res.success) {
                                    setName(res.data['name']);
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            }).catch((err) => { 
                                message.warn('查询接口报错，请联系IT帮忙解决')
                            })
                            
                        }else{
                            setInputName(false)
                            getPrize()
                        }
                    }}>确定</Button>
                </div>
            </Modal>
        </div>
    )

}

export default Index