import React, { useEffect } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';

function Index({
    // 宽度
    chartWidth,
    // 长度
    chartHeight,
    // 日期
    head,
    // 数据
    data,
    // 增加
    add,
    // 减少
    reduce
}) {

    // 分时段趋势 
    let splitTimeChart = {
        title: {
            text: '净增加粉丝数',
            subtext:'净增加粉丝数 = 增加粉丝数-取关粉丝数'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: function (params) {
              let tar;
              if (params[1].value !== '-') {
                tar = params[1];
              } else {
                tar = params[0];
              }
              return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value;
            }
        },
        legend: {
            data: ['增加', '减少']
        },
        color: [
            '#7cd5f4',
            '#f7d51f',
        ],
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data:head,
        },
        yAxis: {
            type: 'value',
            scale: true
        },
        series: [{
            name: 'Placeholder',
            type: 'bar',
            stack: 'Total',
            itemStyle: {
                borderColor: 'transparent',
                color: 'transparent'
            },
            emphasis: {
                itemStyle: {
                    borderColor: 'transparent',
                    color: 'transparent'
                }
            },
            data: data
        },
        {
            name: '增加',
            type: 'bar',
            stack: 'Total',
            label: {
                show: true,
                position: 'top'
            },
            data: add
        },
        {
            name: '减少',
            type: 'bar',
            stack: 'Total',
            label: {
                show: true,
                position: 'bottom'
            },
            data: reduce
        },
        ]
    };

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('splitTime'));
        // 绘制图表
        reportChart.setOption(splitTimeChart);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [chartWidth, chartHeight, reduce])

    return (
        <div>
            <div id="splitTime" style={{ width: `calc(${chartWidth})`, height: `calc(${chartHeight})`}} ></div>
        </div>
    )

}

export default Index