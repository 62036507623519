import React, { useState, useEffect } from 'react';
import { Table, Typography, Button, Drawer, Tabs, Tag, Collapse, Modal, Input, Divider, Checkbox, message, Popover, DatePicker, Row, Col } from 'antd'
import { DraggableArea } from 'react-draggable-tags';
import NbAxios from '../../../config/utils/nbAxios';
import axios from 'axios';
import downExcel from '../../../config/utils/downloadFile';
import cookie from 'react-cookies'
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import servicePath from '../../../config/apiUrl';
import { CloseCircleOutlined, PlusCircleOutlined, InboxOutlined, CloseOutlined, createFromIconfontCN } from '@ant-design/icons';
const { Title, Text, Link } = Typography
const { TabPane } = Tabs;
const { CheckableTag } = Tag;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
function Index(props) {
    Array.prototype.indexRemove = function (obj) {
        for (var i = 0; i < this.length; i++) {
            var temp = this[i];
            if (!isNaN(obj)) {
                temp = i;
            }
            if (temp == obj) {
                for (var j = i; j < this.length; j++) {
                    this[j] = this[j + 1];
                }
                this.length = this.length - 1;
            }
        }
    }
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    // 删除数组中第一个匹配的元素，成功则返回位置索引，失败则返回 -1。
    Array.prototype.deleteElementByValue = function (varElement) {
        var numDeleteIndex = -1;
        for (var i = 0; i < this.length; i++) {
            // 严格比较，即类型与数值必须同时相等。
            if (this[i] === varElement) {
                this.splice(i, 1);
                numDeleteIndex = i;
                break;
            }
        }
        return numDeleteIndex;
    }
    // 对象是否一样
    Object.prototype.vequal = function (obj) {
        var props1 = Object.getOwnPropertyNames(this);
        var props2 = Object.getOwnPropertyNames(obj);
        if (props1.length != props2.length) {
            return false;
        }
        for (var i = 0, max = props1.length; i < max; i++) {
            var propName = props1[i];
            if (this[propName] !== obj[propName]) {
                return false;
            }
        }
        return true;
    }
    // 分组数据的呈现方式
    const [groupPresentWays, setGroupPresentWays] = useState([
        {
            'name':'行交差',
            'key':"intersection"
        },{
            'name':'列交差',
            'key':"column"
        },{
            'name':'差异表',
            'key':"different"
        },{
            'name':'柱状图',
            'key':'histogram'
        },{
            'name':'旭日图',
            'key':'sankey'
        }]
    )
    // 选择的呈现方式
    const [selectedPresentWays, setSelectedPresentWays] = useState([])
    // 对比目的
    const [comparsionPurpose, setComparsionPurpose] = useState('')
     // 相同索引
     const [sameIndex, setSameIndex] = useState(0)
    // 数据对比
    const [ isCompared, setIsCompared] = useState(false)
    // 是否在加载中
    const [isSpinning, setIsSpinning] = useState(false)
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 当前开始时间
    const [startTime, setStartTime] = useState(moment().format('YYYY-MM-DD'))
    // 当前开始时间
    const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DD'))
    // 当前开始时间
    const [cacheStartTime, setCacheStartTime] = useState('')
    // 当前开始时间
    const [cacheEndTime, setCacheEndTime] = useState('')
    // 指标集合
    const [indicatorList, setIndicatorList] = useState([])
    // 选择的指标
    const [selectedIndicator, setSelectedIndicator] = useState([])
    const [oldSelectedIndicator, setOldSelectedIndicator] = useState([])
    // 已选指标id
    const [selectedIndicatorIdList, setSelectedIndicatorIdList] = useState([])
    // 维度集合
    const [dimensionList, setDimensionList] = useState([])
    // 分组维度
    const [groupingDimensions, setGroupingDimensions] = useState([])
    const [oldGroupingDimensions, setOldGroupingDimensions] = useState([])
    // 分组维度id
    const [groupingDimensionsIdList, setGroupingDimensionsIdList] = useState([])
    // 统计指标
    const [statisticalIndicators, setStatisticalIndicators] = useState([])
    // 缓存名字
    const [ cacheName , setCacheName ] = useState('')
    // 数据模版名字
    const [templateName, setTemplateName] = useState('')
    // 数据模版名字
    const [newTemplateName, setNewTemplateName] = useState('')
    // 拖拽类型
    const [dragType, setDragType] = useState('')
    // 拖拽数据
    const [dragDate, setDragDate] = useState({})
    // 是否编辑分组逻辑
    const [isEditGroup, setIsEditGroup] = useState(false)
    // 场景Tabkey
    const [scenesTabKey, setScenesTabKey] = useState('原子表格')
    // Tab的键
    const [tabKey, setTabKey] = useState("分组逻辑")
    // 数据模版集合
    const [dataTemplateList, setDataTemplateList] = useState([])
    // 数据模版id
    const [templateId, setTemplateId] = useState('')
    // 明细数据模版id
    const [detailTemplateId, setDetailTemplateId] = useState('')
    // 是否要删除模版
    const [isOpenTemplate, setIsOpenTemplate] = useState(false)
    // 数据模版字典
    const [dataTemplateDict, setDataTemplateDict] = useState({})
    // 数据打开数据模版气泡
    const [openDataTemplateBubbleIndex, setOpenDataTemplateBubbleIndex] = useState(-1)
    // 数据
    const [data, setData] = useState([])
    // 表头
    const [column, setColumn] = useState([])
    // 表头list
    const [columnList, setColumnList] = useState([])
    // 筛选维度
    const [filterDimension, setFilterDimension] = useState([])
    // 短链
    const [shortChain, setShortChain] = useState('')
    // 原子维度
    const [atomicDimension, setAtomicDimension] = useState([ ])
    const [selectedAtomicData, setSelectedAtomicData] = useState([])
    const [atomicData, setAtomicData] = useState([])
    // 筛选维度key
    const [filterDimensionKey, setFilterDimensionKey] = useState('筛选维度')
    // 确认分组统计模版名字
    const [confirmGroupName, setConfirmGroupName] = useState('')
    // 是否确认分组统计模版
    const [isConfirmGroup, setIsConfirmGroup] = useState('')
    // 是否数据对比
    const [isComparison, setIsComparison] = useState(false)
    // 选择维度
    const [selectAtomic, setSelectAtomic] = useState([])
    // 选择维度行数
    const [selectAtomicIndex, setSelectAtomicIndex] = useState(-1)
    // 缓存数据集合
    const [cacheData, setCacheData] = useState([])
    const [openCacheDataBubbleIndex, setOpenCacheDataBubbleIndex] = useState(-1)
    // 缓存数据字典
    const [cacheDataDict, setCacheDataDict] = useState({})
    // 是否要删除缓存
    const [isOpenCache, setIsOpenCache] = useState(false)
    // useEffect(() => {
    //     setColumn([
    //         ...columnList.map(item => {
    //             return {
    //                 title: item.name,
    //                 dataIndex: item.name,
    //                 key: item.name,
    //                 width: 80,
    //                 align: "center",
    //                 fixed: item.type == 'dimension' ? 'left' : '',
    //                 onCell: function (column) {
    //                     return (
    //                         {
    //                             style: {
    //                                 fontSize: 5
    //                             },
    //                         }
    //                     )
    //                 },
    //                 onHeaderCell: function (column) {
    //                     return (
    //                         {
    //                             style: {
    //                                 fontSize: 5
    //                             },
    //                         }
    //                     )
    //                 },
    //             }
    //         })
    //     ])
    // }, [columnList])

    useEffect(() => {
        if (props.location.state != undefined) {
            if (props.location.state.cacheDataDict == undefined) {
                setDetailTemplateId(props.location.state.templateId);
                getDetailDataTemplateDimensionIndicators(props.location.state.templateId)
                if (props.location.state.start_time != '') {
                    setStartTime(props.location.state.start_time)
                    setEndTime(props.location.state.end_time)
                    setCacheStartTime(props.location.state.start_time)
                    setCacheEndTime(props.location.state.end_time)
                }
            } else {
                // 从数据管理页面跳转进入，查看缓存信息
                let cacheDataDict = props.location.state.cacheDataDict
                setCacheName(cacheDataDict['name'])
                setTemplateId(cacheDataDict['id'])
                setDetailTemplateId(cacheDataDict['template_id']);
                setCacheStartTime(cacheDataDict['start_time']);
                setCacheEndTime(cacheDataDict['end_time']);
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "template_id": cacheDataDict['template_id']
                }
                NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateDetail).then(res => {
                    if (res.success) {
                        setStartTime(cacheDataDict['start_time'])
                        setEndTime(cacheDataDict['end_time'])
                        // setDetailCacheId(res.data['cache_id']);
                        setIndicatorList(res.data['indicators'])
                        // setDimensionList(res.data['dimension'])
                        let dimensionList = res.data['dimension']
                        let indicatorList = res.data['indicators']
                        let groupingDimensions = []
                        let selectedIndicator = []
                        res.data['dimension_list'].map(item => {
                            dimensionList.map(dimension => {
                                if (dimension['id'] == item) {
                                    groupingDimensions.push(dimension)
                                    dimensionList.remove(dimension)
                                }
                            })
                        })
                        setDimensionList(dimensionList)
                        setGroupingDimensions(groupingDimensions)
                        setOldGroupingDimensions(groupingDimensions)
                        res.data['indicator_list'].map(item => {
                            indicatorList.map(indicator => {
                                indicator.indicatorList.map(indicatorItem => {
                                    if (indicatorItem['id'] == item) {
                                        selectedIndicator.push(indicatorItem)
                                    }
                                })
                            })
                        })
                        setSelectedIndicator(selectedIndicator);
                        setOldSelectedIndicator(selectedIndicator);
                        let selectedIndicatorIdList = []
                        let groupingDimensionsIdList = []
                        selectedIndicator.map(item => {
                            selectedIndicatorIdList.push(item.id.toString())
                        })
                        setSelectedIndicatorIdList(selectedIndicatorIdList)
                        groupingDimensions.map(item => {
                            groupingDimensionsIdList.push(item.id.toString())
                        })
                        setGroupingDimensionsIdList(groupingDimensionsIdList)
                        setShortChain(cacheDataDict['short_chain'])
                        queryAtomicTable(cacheDataDict['short_chain'])
                        queryDetailTableCondition(cacheDataDict['short_chain'])
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }

        } else {
            setIsEditGroup(true)
        }
        queryGroupStatisticsTemplate()
        queryGroupStatisticsCacheList()
    }, [])

    // 查询个人的分组统计缓存列表接口
    const queryGroupStatisticsCacheList = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'creator_name': cookie.load('name'),
        }
        NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsCacheList).then(res => {
            if (res.success) {
                setCacheData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询个人分组统计模板接口
    const queryGroupStatisticsTemplate = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'creator_name': cookie.load('name'),
        }
        NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplate).then(res => {
            if (res.success) {
                setDataTemplateList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }


    // 查询明细模板对应的指标和维度接口
    const getDetailDataTemplateDimensionIndicators = (templateId) => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'template_id': templateId,
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailDataTemplateDimensionIndicators).then(res => {
            if (res.success) {
                setDimensionList(res.data['dimension'])
                setIndicatorList(res.data['indicators']);
                setIsEditGroup(true)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 缓存数据气泡
    const cacheDataContent = (
        <div style={{ display: 'flex' }}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "template_id": cacheDataDict['template_id']
                }
                NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateDetail).then(res => {
                    if (res.success) {
                        setStartTime(cacheDataDict['start_time'])
                        setEndTime(cacheDataDict['end_time'])
                        // setDetailCacheId(res.data['cache_id']);
                        setIndicatorList(res.data['indicators'])
                        // setDimensionList(res.data['dimension'])
                        let dimensionList = res.data['dimension']
                        let indicatorList = res.data['indicators']
                        let groupingDimensions = []
                        let selectedIndicator = []
                        res.data['dimension_list'].map(item => {
                            dimensionList.map(dimension => {
                                if (dimension['id'] == item) {
                                    groupingDimensions.push(dimension)
                                    dimensionList.remove(dimension)
                                }
                            })
                        })
                        setDimensionList(dimensionList)
                        setGroupingDimensions(groupingDimensions)
                        setOldGroupingDimensions(groupingDimensions)
                        res.data['indicator_list'].map(item => {
                            indicatorList.map(indicator => {
                                indicator.indicatorList.map(indicatorItem => {
                                    if (indicatorItem['id'] == item) {
                                        selectedIndicator.push(indicatorItem)
                                    }
                                })
                            })
                        })
                        setSelectedIndicator(selectedIndicator);
                        setOldSelectedIndicator(selectedIndicator);
                        let selectedIndicatorIdList = []
                        let groupingDimensionsIdList = []
                        selectedIndicator.map(item => {
                            selectedIndicatorIdList.push(item.id.toString())
                        })
                        setSelectedIndicatorIdList(selectedIndicatorIdList)
                        groupingDimensions.map(item => {
                            groupingDimensionsIdList.push(item.id.toString())
                        })
                        setGroupingDimensionsIdList(groupingDimensionsIdList)
                        setShortChain(cacheDataDict['short_chain'])
                        queryAtomicTable(cacheDataDict['short_chain'])
                        queryDetailTableCondition(cacheDataDict['short_chain'])
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }}>查询数据</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={() => {
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "template_id": cacheDataDict['template_id']
                }
                NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateDetail).then(res => {
                    if (res.success) {
                        setStartTime(cacheDataDict['start_time'])
                        setEndTime(cacheDataDict['end_time'])
                        // setDetailCacheId(res.data['cache_id']);
                        setIndicatorList(res.data['indicators'])
                        // setDimensionList(res.data['dimension'])
                        let dimensionList = res.data['dimension']
                        let indicatorList = res.data['indicators']
                        let groupingDimensions = []
                        let selectedIndicator = []
                        res.data['dimension_list'].map(item => {
                            dimensionList.map(dimension => {
                                if (dimension['id'] == item) {
                                    groupingDimensions.push(dimension)
                                    dimensionList.remove(dimension)
                                }
                            })
                        })
                        setDimensionList(dimensionList)
                        setGroupingDimensions(groupingDimensions)
                        setOldGroupingDimensions(groupingDimensions)
                        res.data['indicator_list'].map(item => {
                            indicatorList.map(indicator => {
                                indicator.indicatorList.map(indicatorItem => {
                                    if (indicatorItem['id'] == item) {
                                        selectedIndicator.push(indicatorItem)
                                    }
                                })
                            })
                        })
                        setSelectedIndicator(selectedIndicator);
                        setOldSelectedIndicator(selectedIndicator);
                        let selectedIndicatorIdList = []
                        let groupingDimensionsIdList = []
                        selectedIndicator.map(item => {
                            selectedIndicatorIdList.push(item.id.toString())
                        })
                        setSelectedIndicatorIdList(selectedIndicatorIdList)
                        groupingDimensions.map(item => {
                            groupingDimensionsIdList.push(item.id.toString())
                        })
                        setGroupingDimensionsIdList(groupingDimensionsIdList)
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }}>查看细节</Button>
            <Button type="primary" style={{ marginRight: 10 }} danger onClick={() => {
                setIsOpenCache(true)
            }}>删除缓存</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={() => {
                let key = "download"
                message.loading({ content: '开始下载数据...', key, duration: 100 })
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "cache_id": cacheDataDict['id']
                }
                axios({
                    data: dataProps,
                    method: 'POST',
                    url: servicePath.downloadGroupStatisticsCacheData,
                    responseType: "blob",
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization': cookie.load('token')
                    }
                }).then(
                    res => {
                        message.success({ content: '成功下载数据...', key })
                        downExcel(res)
                    }
                ).catch(e => {
                    message.error({ content: '文件制作出错，请联系it同事！', key })
                })
            }}>下载明细数据</Button>
        </div>
    )
    // 数据模版气泡
    const dataTemplateContent = (
        <div style={{ display: 'flex' }}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "template_id": dataTemplateDict['id']
                }
                NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateDetail).then(res => {
                    if (res.success) {
                        // setDetailCacheId(res.data['cache_id']);
                        setIndicatorList(res.data['indicators'])
                        // setDimensionList(res.data['dimension'])
                        let dimensionList = res.data['dimension']
                        let indicatorList = res.data['indicators']
                        let groupingDimensions = []
                        let selectedIndicator = []
                        res.data['dimension_list'].map(item => {
                            dimensionList.map(dimension => {
                                if (dimension['id'] == item) {
                                    groupingDimensions.push(dimension)
                                    dimensionList.remove(dimension)
                                }
                            })
                        })
                        setDimensionList(dimensionList)
                        setGroupingDimensions(groupingDimensions)
                        setOldGroupingDimensions(groupingDimensions)
                        res.data['indicator_list'].map(item => {
                            indicatorList.map(indicator => {
                                indicator.indicatorList.map(indicatorItem => {
                                    if (indicatorItem['id'] == item) {
                                        selectedIndicator.push(indicatorItem)
                                    }
                                })
                            })
                        })
                        let selectedIndicatorIdList = []
                        let groupingDimensionsIdList = []
                        selectedIndicator.map(item => {
                            selectedIndicatorIdList.push(item.id.toString())
                        })
                        groupingDimensions.map(item => {
                            groupingDimensionsIdList.push(item.id.toString())
                        })
                        setSelectedIndicator(selectedIndicator);
                        setOldSelectedIndicator(selectedIndicator);
                        checkDetailDataCache(selectedIndicatorIdList, groupingDimensionsIdList)
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }}>查询数据</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={() => {
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "template_id": dataTemplateDict['id']
                }
                NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateDetail).then(res => {
                    if (res.success) {
                        // setDetailCacheId(res.data['cache_id']);
                        setIndicatorList(res.data['indicators'])
                        // setDimensionList(res.data['dimension'])
                        let dimensionList = res.data['dimension']
                        let indicatorList = res.data['indicators']
                        let groupingDimensions = []
                        let selectedIndicator = []
                        res.data['dimension_list'].map(item => {
                            dimensionList.map(dimension => {
                                if (dimension['id'] == item) {
                                    groupingDimensions.push(dimension)
                                    dimensionList.remove(dimension)
                                }
                            })
                        })
                        setDimensionList(dimensionList)
                        setGroupingDimensions(groupingDimensions)
                        setOldGroupingDimensions(groupingDimensions)
                        res.data['indicator_list'].map(item => {
                            indicatorList.map(indicator => {
                                indicator.indicatorList.map(indicatorItem => {
                                    if (indicatorItem['id'] == item) {
                                        selectedIndicator.push(indicatorItem)
                                    }
                                })
                            })
                        })
                        setSelectedIndicator(selectedIndicator);
                        setOldSelectedIndicator(selectedIndicator);
                        let selectedIndicatorIdList = []
                        let groupingDimensionsIdList = []
                        selectedIndicator.map(item => {
                            selectedIndicatorIdList.push(item.id.toString())
                        })
                        setSelectedIndicatorIdList(selectedIndicatorIdList)
                        groupingDimensions.map(item => {
                            groupingDimensionsIdList.push(item.id.toString())
                        })
                        setGroupingDimensionsIdList(groupingDimensionsIdList)
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }}>查看细节</Button>
            <Button type="primary" style={{ marginRight: 10 }} danger onClick={() => {
                setIsOpenTemplate(true)
            }}>删除模版</Button>
            <Button type="dashed" style={{ display: dataTemplateDict['is_temporary'] ? '' : 'none' }} onClick={() => {
                // setOpenDataTemplateBubbleIndex(-1)
                // setIsTemplateTurnPositive(true)
            }}>转存为正式模版</Button>
        </div>
    )

    //判断是否存在明细数据缓存
    const checkDetailDataCache = (selectedIndicatorIdList, groupingDimensionsIdList) => {
        setEffective(1)
        let key = "checkDetailData"
        message.loading({ content: '正在校验是否存在明细数据缓存...', key, duration: 100 })
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "template_id": detailTemplateId,
            "start_time": startTime,
            "end_time": endTime
        }
        NbAxios(dataProps, "POST", servicePath.checkDetailDataCache).then(res => {
            if (res.success) {
                message.loading({ content: '正在生产原子表...', key, duration: 100 })
                createAtomicTable(res.data['short_chain'], selectedIndicatorIdList, groupingDimensionsIdList)
            } else {
                // message.warn(res.errorMsg)
                message.loading({ content: '正在生产明细数据缓存...', key, duration: 100 })
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "template_id": detailTemplateId,
                    "start_time": startTime,
                    "end_time": endTime,
                    "creator_name": cookie.load('name'),
                }
                NbAxios(dataProps, "POST", servicePath.createDetailDataCache).then(res => {
                    if (res.success) {
                        message.loading({ content: '正在生产原子表...', key, duration: 100 })
                        createAtomicTable(res.data['short_chain'], selectedIndicatorIdList, groupingDimensionsIdList)
                    } else {
                        setEffective(0)
                        message.warn({ content: res.errorMsg, key })
                    }
                })
            }
        })
    }

    // 查询原子表格接口
    const queryAtomicTable = (short_chain) => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "short_chain": short_chain,
        }
        NbAxios(dataProps, "POST", servicePath.queryAtomicTable).then(res => {
            if (res.success) {
                setAtomicData(res.data['atomic_table']);
                setAtomicDimension(res.data['condition']);
                setIsEditGroup(false)
                setIsConfirmGroup(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询明细表条件接口
    const queryDetailTableCondition = (short_chain) => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "short_chain": short_chain,
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailTableCondition).then(res => {
            if (res.success) {
                // console.log(res.data);
                setFilterDimension(res.data)
                queryDetailTable(short_chain, res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询明细表数据接口
    const queryDetailTable = (short_chain, conditionList) => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "short_chain": short_chain,
            "condition_list": conditionList
        }
        NbAxios(dataProps, "POST", servicePath.queryDetailTable).then(res => {
            if (res.success) {
                setColumn(res.data['head']);
                setData(res.data['data']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 生产原子表
    const createAtomicTable = (short_chain, indicatorIdList, dimensionsIdList) => {
        let key = "checkDetailData"
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "template_id": detailTemplateId,
            "short_chain": short_chain,
            // "short_chain":res.data['short_chain'],
            "start_time": startTime,
            "end_time": endTime,
            "creator_name": cookie.load('name'),
            "name": newTemplateName,
            "purpose": "",
            'dimension_list': dimensionsIdList,
            'indicator_list': indicatorIdList,
            "group_statistics_template_id": templateId
        }
        NbAxios(dataProps, "POST", servicePath.createAtomicTable).then(res => {
            if (res.success) {
                setTemplateId(res.data['template_id'])
                setEffective(0)
                message.success({ content: '成功生产原子表...', key })
                queryGroupStatisticsTemplate()
                setShortChain(res.data.short_chain)
                queryAtomicTable(res.data.short_chain)
                queryDetailTableCondition(res.data.short_chain)
                queryGroupStatisticsCacheList()
            } else {
                setEffective(0)
                message.warn({ content: res.errorMsg, key })
            }
        })
    }

    return (
        <div>
            <div style={
                {
                    display: 'flex',
                    justifyContent: "space-around",
                    backgroundColor: "#fff",
                    borderBottom: "solid 2px #ccc",
                    paddingTop: 5,
                    marginBottom: 10,
                    position: 'fixed',
                    top: 40,
                    zIndex: 99,
                    width: "100vw"
                }
            } >
                <Title level={2} > {props.location.pathname.split('/')[2]}商品分组统计灵活报表 </Title>
                <div><Button type="primary" style={{ marginTop: 5 }} onClick={() => { setIsEditGroup(true) }} > 编辑分组逻辑 </Button></div>
            </div>
            <div style={{ marginLeft: 10, marginRight: 10, marginTop: 70 }}>
                <Tabs activeKey={scenesTabKey} type="card" onChange={(key) => {
                    setFilterDimensionKey('')
                    setScenesTabKey(key)
                }}>
                    <TabPane tab="原子表格" key="原子表格"></TabPane>
                    <TabPane tab="明细数据" key="明细数据"></TabPane>
                </Tabs>
            </div>
            <div style={{ display: scenesTabKey == "明细数据" ? '' : 'none' }}>
                <Collapse activeKey={filterDimensionKey} style={{ marginLeft: 10, marginRight: 10 }} onChange={(key) => {
                    setFilterDimensionKey(key)
                }}>
                    <Panel header="筛选维度" key="筛选维度">
                        {
                            filterDimension.map((item, index) => {
                                return <div style={{ display: 'grid', gridTemplateColumns: "80px 1fr", marginTop: 5, fontSize: 16 }}>
                                    <Text strong>{item.name}:</Text>
                                    <div>
                                        {
                                            item.tagsData.map((tag) => {
                                                return <CheckableTag
                                                    checked={item.selectedTags.indexOf(tag) > -1}
                                                    key={tag}
                                                    style={{ fontSize: 14 }}
                                                    onChange={(checked) => {
                                                        if (checked) {
                                                            // 选中的逻辑
                                                            if (tag != '全部') {
                                                                let obj = [...filterDimension]
                                                                if (obj[index]['selectedTags'][0] == '全部') {
                                                                    let selectedTagsList = []
                                                                    selectedTagsList.push(tag)
                                                                    obj[index]['selectedTags'] = selectedTagsList
                                                                    setFilterDimension(obj)
                                                                } else {
                                                                    let selectedTagsList = obj[index]['selectedTags']
                                                                    selectedTagsList.push(tag)
                                                                    obj[index]['selectedTags'] = selectedTagsList
                                                                    setFilterDimension(obj)
                                                                }
                                                            } else {
                                                                // 点击全部的逻辑
                                                                let obj = [...filterDimension]
                                                                let selectedTagsList = []
                                                                selectedTagsList.push(tag)
                                                                obj[index]['selectedTags'] = selectedTagsList
                                                                setFilterDimension(obj)
                                                            }
                                                        } else {
                                                            // 取消选中的逻辑
                                                            let obj = [...filterDimension]
                                                            obj[index]['selectedTags'].deleteElementByValue(tag)
                                                            setFilterDimension(obj)
                                                        }

                                                    }}
                                                >
                                                    {tag}
                                                </CheckableTag>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="primary" onClick={() => {
                                queryDetailTable(shortChain, filterDimension);
                                setFilterDimensionKey('')
                            }} style={{ marginRight: 10 }}>查询</Button>
                            <Button type="primary" danger style={{ marginRight: 60 }} onClick={() => {
                                let obj = [...filterDimension]
                                obj.map(item => {
                                    item.selectedTags = ['全部']
                                })
                                queryDetailTable(shortChain, obj);
                                setFilterDimension(obj)
                                setFilterDimensionKey('')
                            }}>重置</Button>
                        </div>
                    </Panel>
                </Collapse>
                <div style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}>
                    <Table columns={column} dataSource={data} scroll={{ x: 'max-content', y: 'calc(100vh - 270px)' }} pagination={false} />
                </div>
            </div>
            <div style={{ display: scenesTabKey == "原子表格" ? '' : 'none', marginLeft: 10, marginRight: 10 }}>
                <div>
                    <Collapse activeKey={filterDimensionKey} onChange={(key) => {
                        setFilterDimensionKey(key)
                    }}>
                        <Panel header="筛选维度" key="筛选维度">
                            {
                                isComparison ? <div>
                                    <Button disabled={selectedAtomicData.length != 0 ? false : true} type="primary" style={{ marginBottom: 10 }} onClick={() => {
                                        if(cacheName == ''){
                                            let dataProps = {
                                                "roleId": cookie.load('role'),
                                                "shopList": cookie.load('shopList'),
                                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                                "template_id": templateId
                                            }
                                            NbAxios(dataProps, "POST", servicePath.queryGroupStatisticsTemplateName).then(res => {
                                                if(res.success){
                                                    setCacheName(res.data['name'] + '-' + startTime + '-' + endTime)
                                                    setIsCompared(true)
                                                } else {
                                                    message.warn(res.errorMsg)
                                                }
                                            })
                                        }else{
                                            setIsCompared(true)
                                        }
                                    }}>确认对比</Button>
                                    <Button type="dashed" style={{ marginBottom: 10, marginLeft: 10 }} onClick={() => {
                                        setIsComparison(false)
                                    }} >查询数据</Button>
                                </div>
                                    : <Button type="primary" style={{ marginBottom: 10 }} onClick={() => {
                                        setIsComparison(true)
                                    }}>对比数据</Button>
                            }
                            {
                                isComparison ?
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <Button type="primary" disabled={selectAtomic.length == 2 ? atomicData[1]['value'] != '' && atomicData[0]['value'] != '' ? false : true : true} onClick={() => {
                                                let selectedAtomicDataObj = [...selectedAtomicData]
                                                let mes = selectAtomic[0] + ' 对比 ' + selectAtomic[1]
                                                let flag = 0
                                                selectedAtomicData.map(item => {
                                                    if (item.message.indexOf(selectAtomic[0]) != -1 && item.message.indexOf(selectAtomic[1])!= -1) {
                                                        flag = 1
                                                    }
                                                })
                                                if (flag) {
                                                    message.warn('该维度对比已存在')
                                                }else{
                                                    let obj = {
                                                        message: mes,
                                                        value1: selectAtomic[0] ,
                                                        value2: selectAtomic[1] ,
                                                    }
                                                    selectedAtomicDataObj.push(obj)
                                                    setSelectedAtomicData(selectedAtomicDataObj)
                                                    setSelectAtomic([])
                                                    setSelectAtomicIndex(-1)
                                                }
                                            }}>确定添加该维度</Button>
                                        </div>
                                        <div>
                                            {
                                                atomicDimension.map((item, index) => {
                                                    return <div style={{ display: 'grid', gridTemplateColumns: "80px 1fr" }}>
                                                        <Text strong>{"第" + (index + 1) + "维度"}</Text>
                                                        <div>
                                                            {
                                                                item.atomic.map(i => {
                                                                    return <Checkbox
                                                                        value={i}
                                                                        checked={selectAtomic.indexOf(i) != -1 ? true : false}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                // 当点击的行不同的时候
                                                                                if (index != selectAtomicIndex) {
                                                                                    let obj = []
                                                                                    obj.push(i)
                                                                                    setSelectAtomicIndex(index)
                                                                                    setSelectAtomic(obj)
                                                                                } else {
                                                                                    console.log(selectAtomic);
                                                                                    if (selectAtomic.length == 1) {
                                                                                        let obj = [...selectAtomic]
                                                                                        obj.push(i)
                                                                                        setSelectAtomic(obj)
                                                                                    } else {
                                                                                        message.warn('一次对比仅两个维度进行对比')
                                                                                    }

                                                                                }
                                                                            } else {
                                                                                let obj = [...selectAtomic]
                                                                                obj.deleteElementByValue(i)
                                                                                setSelectAtomic(obj)
                                                                            }
                                                                        }}
                                                                    >{i}</Checkbox>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                                            <Text strong>已选对比维度:</Text>
                                            <div style={{ display: "flex",  flexWrap: 'wrap' }}>
                                                {
                                                    selectedAtomicData.map((item, index) => {
                                                        return <div style={{
                                                            margin: "3px",
                                                            fontSize: '13px',
                                                            border: " 1px dashed #cccccc",
                                                            borderRadius: '4px',
                                                            padding: "0 8px",
                                                            lineHeight: "30px", color: "#666666",
                                                            backgroundColor: "rgba(255, 255, 255, 0.7);",
                                                            whiteSpace: 'nowrap',
                                                            display: 'flex'
                                                        }}>
                                                            <span>{item.message}</span>
                                                            <CloseCircleOutlined style={{ marginTop: 8, marginLeft: 3 }} onClick={() => {
                                                                let obj = [...selectedAtomicData]
                                                                obj.indexRemove(index)
                                                                setSelectedAtomicData(obj)
                                                            }} />
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    atomicDimension.map((item, index) => {
                                        return <div style={{ display: 'grid', gridTemplateColumns: "80px 1fr" }}>
                                            <Text strong>{"第" + (index + 1) + "维度"}</Text>
                                            <div>
                                                {
                                                    item.atomic.map(i => {
                                                        return <a href={"#" + i} style={{ marginRight: 10 }}>{i}</a>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                            }
                        </Panel>
                    </Collapse>
                </div>
                <div style={{ height: 'calc(100vh - 400px)', width: 'calc(100vw-20)', border: '1px solid #ccc', overflow: "auto" }}>
                    {
                        atomicData.map(item => {
                            return <div style={{ width: 'calc(100vw-20)' }}>
                                <Divider>{item.atomic}</Divider>
                                <div>
                                    <a name={item.atomic}></a>
                                </div>
                                <Table dataSource={item.data} columns={item.column} pagination={false} scroll={{ x: 100 }} />
                            </div>
                        })
                    }
                </div>
            </div>
            {/* 编辑分组逻辑 */}
            <Drawer
                visible={isEditGroup}
                onClose={() => {
                    setIsEditGroup(false)
                }}
                title="筛选条件"
                width='80vw'
            >
                <div>
                    <div style={{ display: 'flex' }}>
                        <b style={{ marginRight: 10, marginTop: 5 }}>时间选择:</b>
                        {/* <DatePicker disabledDate={disabledDate} value={time} locale={locale} style={{ height: 30, marginRight: 10, marginBottom: 15 }} value={moment(time, dateFormat)} onChange={(date, dateString) => {
                        if (dateString == '') {
                            message.warn('请选择合理的时间')
                            return false
                        } else {
                            setTime(dateString);
                        }
                    }} /> */}
                        <RangePicker disabledDate={disabledDate} locale={locale} style={{ height: 30, marginRight: 10, marginBottom: 15 }} value={[moment(startTime, dateFormat), moment(endTime, dateFormat)]} onChange={(date, dateString) => {
                            if (dateString[0] == '') {
                                message.warn('请选择合理的时间')
                                return false
                            } else {
                                setStartTime(dateString[0])
                                setEndTime(dateString[1])
                            }
                        }} />
                        <Text type="secondary" style={{ display: cacheStartTime == startTime && cacheEndTime == endTime ? '' : 'none' }}>此时间段存在明细数据缓存</Text>
                    </div>
                </div>
                <Tabs activeKey={tabKey} type="card" style={{ backgroundColor: "#fff", height: 54 }} onChange={(key) => {
                    setTabKey(key)
                }}>
                    <TabPane tab="分组逻辑" key="分组逻辑">
                    </TabPane>
                    {/* <TabPane tab="筛选范围" key="筛选范围">
                </TabPane> */}
                </Tabs>
                <div style={{ display: tabKey == '分组逻辑' ? '' : 'none', width: "100%" }}>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', padding: 20, display: indicatorList.length == 0 ? 'none' : '' }}>
                        <Text strong style={{ fontSize: 16, marginTop: 10 }} > 统计指标: </Text>
                        <div style={{ marginLeft: 50 }}>
                            {
                                indicatorList.map(item => {
                                    return <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr" }}>
                                            <Text strong>{item.indicatorName.name}:</Text>
                                            <div>
                                                {
                                                    item.indicatorList.map(i => {
                                                        return <Checkbox
                                                            checked={JSON.stringify(selectedIndicator).indexOf(JSON.stringify(i)) != -1 ? true : false}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    let obj = [...selectedIndicator]
                                                                    obj.push(i)
                                                                    setSelectedIndicator(obj)
                                                                } else {
                                                                    let obj = [...selectedIndicator]
                                                                    obj.remove(i)
                                                                    setSelectedIndicator(obj)
                                                                }
                                                            }}>{i.name}</Checkbox>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{ marginTop: 10, display: 'grid', gridTemplateColumns: "50px 1fr" }} >
                            <Text strong style={{ fontSize: 16, marginTop: 10 }} > 维度: </Text> <div style={{ display: 'flex', flexWrap: 'nowrap', overflow: "auto" }} >
                                {dimensionList.map(item => {
                                    return <div onDragStart={() => {
                                        setDragType('dimension')
                                        setDragDate(item)
                                    }}
                                        draggable={true}
                                        style={{
                                            margin: "3px",
                                            fontSize: '13px',
                                            border: " 1px dashed #cccccc",
                                            borderRadius: '4px',
                                            padding: "0 8px",
                                            lineHeight: "30px",
                                            color: "#666666",
                                            backgroundColor: "rgba(255, 255, 255, 0.7);",
                                            whiteSpace: 'nowrap'
                                        }}>
                                        <span> {item.name} </span> </div>
                                })
                                }</div>
                        </div>
                        <div style={{ marginTop: 10, display: 'grid', gridTemplateColumns: "70px 1fr" }}
                            onDragEnter={() => {
                                if (dragType == 'dimension') {
                                    // js动态给对象修改样式
                                    let obj = document.getElementById('groupingDimensions')
                                    obj.style.border = "2px solid #a3e9a4"
                                    // 一秒之后自动回复原来的样子
                                    setTimeout(() => {
                                        let obj = document.getElementById('groupingDimensions')
                                        obj.style.border = "1px dashed #cccccc"
                                    }, 1000)
                                    // 分组维度添加一个数据，维度集合减少一个数据
                                    if (groupingDimensions.indexOf(dragDate) == -1) {
                                        let data = [...groupingDimensions]
                                        data.push(dragDate)
                                        setGroupingDimensions(data)
                                        let arr = [...dimensionList]
                                        arr.remove(dragDate)
                                        setDimensionList(arr)
                                    }
                                } else {
                                    let obj = document.getElementById('groupingDimensions')
                                    obj.style.border = "2px solid #fa6c60"
                                    setTimeout(() => {
                                        let obj = document.getElementById('groupingDimensions')
                                        obj.style.border = "1px dashed #cccccc"
                                    }, 1000)
                                }
                            }}
                        >
                            <Text strong style={{ fontSize: 16, marginTop: 12 }} > 分组维度: </Text>
                            <div id="groupingDimensions" style={{ width: '100%', border: "1px dashed #cccccc", height: 50, marginTop: 5, display: 'flex', flexWrap: 'nowrap', overflow: "auto", marginLeft: 4 }}>
                                <DraggableArea tags={groupingDimensions} render={({ tag, index }) => (
                                    <div style={{
                                        margin: "3px",
                                        fontSize: '13px',
                                        border: " 1px dashed #cccccc",
                                        borderRadius: '4px',
                                        padding: "0 8px",
                                        lineHeight: "30px",
                                        color: "#666666",
                                        backgroundColor: "rgba(255, 255, 255, 0.7);",
                                        whiteSpace: 'nowrap',
                                    }} >
                                        <span> {tag.name} </span><CloseOutlined style={{ marginTop: 12, marginLeft: 13 }} onClick={() => {
                                            let obj = [...groupingDimensions]
                                            obj.remove(tag)
                                            setGroupingDimensions(obj)
                                            let arr = [...dimensionList]
                                            arr.push(tag)
                                            setDimensionList(arr)
                                        }} />
                                    </div>
                                )} onChange={tags => setGroupingDimensions(tags)} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "flex-start", marginTop: 30 }} >
                            <Button type="primary" style={{ marginRight: 20 }} onClick={() => {
                                let flag = 1
                                if (!oldSelectedIndicator.vequal(selectedIndicator)) {
                                    flag = 0
                                }
                                if (!oldGroupingDimensions.vequal(groupingDimensions)) {
                                    flag = 0
                                }
                                if (flag) {
                                    let selectedIndicatorIdList = []
                                    let groupingDimensionsIdList = []
                                    selectedIndicator.map(item => {
                                        selectedIndicatorIdList.push(item.id.toString())
                                    })
                                    groupingDimensions.map(item => {
                                        groupingDimensionsIdList.push(item.id.toString())
                                    })
                                    checkDetailDataCache(selectedIndicatorIdList, groupingDimensionsIdList)
                                } else {
                                    setNewTemplateName('')
                                    let selectedIndicatorIdList = []
                                    let groupingDimensionsIdList = []
                                    selectedIndicator.map(item => {
                                        selectedIndicatorIdList.push(item.id.toString())
                                    })
                                    setSelectedIndicatorIdList(selectedIndicatorIdList)
                                    groupingDimensions.map(item => {
                                        groupingDimensionsIdList.push(item.id.toString())
                                    })
                                    setGroupingDimensionsIdList(groupingDimensionsIdList)
                                    setIsConfirmGroup(true)
                                }

                            }}>查询</Button>
                            <Button type="primary" danger onClick={() => {
                                setSelectedIndicator([])
                                setGroupingDimensions([])
                            }}> 重置 </Button>
                        </div>
                    </div>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', height: 300, display: dataTemplateList.length == 0 ? 'none' : '' }}>
                        <div style={{ marginLeft: 20, marginTop: 10 }}>
                            <Title level={4}>分组提取模版</Title>
                            <div style={{ display: "flex", flexWrap: 'wrap' }} onMouseLeave={() => {
                                setOpenDataTemplateBubbleIndex(-1)
                            }}>
                                {
                                    dataTemplateList.map((item, index) => {
                                        return <div style={{
                                            margin: "3px",
                                            fontSize: '13px',
                                            border: " 1px dashed #cccccc",
                                            borderRadius: '4px',
                                            padding: "0 8px",
                                            lineHeight: "30px", color: "rgb(9,110,217)",
                                            backgroundColor: "#E6F7FF",
                                            whiteSpace: 'nowrap'
                                        }}>
                                            <Popover content={dataTemplateContent} visible={openDataTemplateBubbleIndex == index ? true : false} trigger="hover" placement="bottomLeft" onMouseEnter={() => {
                                                setOpenDataTemplateBubbleIndex(index)
                                                setTemplateId(item.id)
                                                setTemplateName(item.name)
                                                let obj = {
                                                    'id': item.id,
                                                    'is_temporary': item.is_temporary
                                                }
                                                setDataTemplateDict(obj);
                                            }}>
                                                <span>{item.name}</span>
                                            </Popover>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', height: 300, display: cacheData.length == 0 ? 'none' : '' }}>
                        <div style={{ marginLeft: 20, marginTop: 10 }}>
                            <Title level={4}>我的分组缓存</Title>
                            <div style={{ display: "flex", flexWrap: 'wrap' }} onMouseLeave={() => {
                                setOpenCacheDataBubbleIndex(-1)
                            }}>
                                {
                                    cacheData.map((item, index) => {
                                        return <div style={{
                                            margin: "3px",
                                            fontSize: '13px',
                                            border: " 1px dashed #cccccc",
                                            borderRadius: '4px',
                                            padding: "0 8px",
                                            lineHeight: "30px", color: "rgb(9,110,217)",
                                            backgroundColor: "#E6F7FF",
                                            whiteSpace: 'nowrap'
                                        }}>
                                            <Popover content={cacheDataContent} visible={openCacheDataBubbleIndex == index ? true : false} trigger="hover" placement="bottomLeft" onMouseEnter={() => {
                                                setOpenCacheDataBubbleIndex(index)
                                                setTemplateId(item.template_id)
                                                let obj = {
                                                    'id': item.id,
                                                    "start_time": item.start_time,
                                                    "short_chain": item.short_chain,
                                                    "end_time": item.end_time,
                                                    'template_id': item.template_id,
                                                    'is_temporary': item.is_temporary
                                                }
                                                setCacheDataDict(obj);
                                            }}>
                                                <span>{item.name}</span>
                                            </Popover>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ display: cacheData.length == 0 && dataTemplateList.length == 0 && indicatorList.length == 0 ? '' : 'none' }}>
                        <div>
                            <Link strong onClick={() => {
                                props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataManagement` })
                            }}>{"暂时数据,请到数据管理页面选择明细模版."}</Link>
                        </div>
                    </div>
                </div>
            </Drawer>
            <Modal
                title="分组统计模版"
                visible={isConfirmGroup}
                centered={true}
                maskClosable={false}
                onCancel={() => {
                    setSelectedIndicatorIdList([])
                    setGroupingDimensionsIdList([])
                    setTemplateName('')
                    setIsConfirmGroup(false)
                }}
                onOk={() => {
                    if (newTemplateName == '') {
                        message.warn('请输入模版名字')
                    } else {
                        checkDetailDataCache(selectedIndicatorIdList, groupingDimensionsIdList)
                    }
                }}
                width={600}
                okText="确定"
                cancelText="取消"
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <div style={{ display: 'flex' }}>
                    <Text strong style={{ marginTop: 5 }}>模版名称:</Text>
                    <Input style={{ width: 300, marginLeft: 10 }} value={newTemplateName} onChange={(e) => {
                        setNewTemplateName(e.target.value)
                    }} />
                </div>
            </Modal>
            <Modal
                title="是否要删除该模版"
                visible={isOpenTemplate}
                centered={true}
                okText={"确定"}
                cancelText={"取消"}
                onCancel={() => {
                    message.success('取消删除该模版')
                    setIsOpenTemplate(false)
                }}
                onOk={() => {
                    let dataProps = {
                        "roleId": cookie.load('role'),
                        "shopList": cookie.load('shopList'),
                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                        "shop": props.history.location.pathname.split('/')[2],
                        "template_id": dataTemplateDict['id']
                    }
                    NbAxios(dataProps, "POST", servicePath.deleteGroupStatisticsTemplate).then(res => {
                        if (res.success) {
                            message.success('成功删除该模版')
                            queryGroupStatisticsTemplate()
                            setOpenDataTemplateBubbleIndex(-1)
                            setIsOpenTemplate(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}
            >
                <Text type='danger' strong>是否要删除该模版？</Text>
            </Modal>
            <Modal
                title="是否要删除该缓存"
                visible={isOpenCache}
                centered={true}
                okText={"确定"}
                cancelText={"取消"}
                onCancel={() => {
                    message.success('取消删除该缓存')
                    setIsOpenCache(false)
                }}
                onOk={() => {
                    let dataProps = {
                        "roleId": cookie.load('role'),
                        "shopList": cookie.load('shopList'),
                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                        "shop": props.history.location.pathname.split('/')[2],
                        "cache_id": cacheDataDict['id']
                    }
                    NbAxios(dataProps, "POST", servicePath.deleteGroupStatisticsCache).then(res => {
                        if (res.success) {
                            message.success('成功删除该缓存')
                            queryGroupStatisticsCacheList()
                            setOpenCacheDataBubbleIndex(-1)
                            setIsOpenCache(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}
            >
                <Text type='danger' strong>是否要删除该缓存？</Text>
            </Modal>

            <Modal
                title="请编辑对比方案"
                visible={isCompared}
                centered={true}
                okText="确定"
                cancelText="取消"
                width={700}
                onOk={()=>{
                    if(comparsionPurpose != ''){
                        console.log(selectedAtomicData);
                        let value_list1 = []
                        let value_list2 = []
                        selectedAtomicData.map(item=>{
                            value_list1.push(item.value1)
                            value_list2.push(item.value2)
                        })
                        let cache_name_1 = cacheName
                        let cache_start_time_1 = startTime
                        let cache_end_time_1 = endTime
                        let cache_short_chain_1 = shortChain
                        let cache_name_2 = cacheName
                        let cache_start_time_2 = startTime
                        let cache_end_time_2 = endTime
                        let cache_short_chain_2 = shortChain
                        let dataProps = {
                            "roleId": cookie.load('role'),
                            "shopList": cookie.load('shopList'),
                            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                            "type": 2,
                            "template_id": templateId,
                            "name": '',
                            "cache_name_1": cache_name_1,
                            "cache_start_time_1": cache_start_time_1,
                            "cache_end_time_1": cache_end_time_1,
                            "cache_short_chain_1": cache_short_chain_1,
                            "cache_name_2": cache_name_2,
                            "cache_start_time_2": cache_start_time_2,
                            "cache_end_time_2": cache_end_time_2,
                            "cache_short_chain_2": cache_short_chain_2,
                            "objective": comparsionPurpose,
                            "is_index": sameIndex,
                            "creator_name": cookie.load('name'),
                            "is_top": 0,
                            "value1": value_list1,
                            "value2":value_list2
                        }
                        NbAxios(dataProps, "POST", servicePath.createGroupStatisticsComparisonRecord).then(res => {
                            setEffective(0)
                            if (res.success) {
                                message.success({ content: '数据对比方案创建成功' })
                                window.location.href = `https://nb20.bmcsoft.cn/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison?selectedPresentWays=${JSON.stringify(selectedPresentWays)}&comparsionShortChain=${res.data['short_chain']}&comparison_type=2`
                                // window.location.href(`/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison?selectedPresentWays=${JSON.stringify(selectedPresentWays)}&comparsionShortChain=${res.data['short_chain']}&comparison_type=2`)
                                // props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/dataMart/dataComparison`, 
                                // 'state': { 'selectedPresentWays': selectedPresentWays, 'comparsionShortChain': res.data['short_chain'], 'comparison_type': type } })
                            } else {
                                message.warn(res.errorMsg)
                            }
                        })
                    }else{
                        message.warn('请先填写对比目的')
                    }
                }}
                onCancel={()=>{
                    setIsCompared(false)
                }}
            >
                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 3 }}>
                            <span style={{ color: "red", fontSize: 20 }}>*</span>
                        </div>
                        <Text strong>对比目的:</Text>
                    </div>
                    <Input value={comparsionPurpose} style={{ width: 300 }} onChange={(e) => {
                        setComparsionPurpose(e.target.value)
                    }} />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                    <Text strong>交并差计算:</Text>
                    <Checkbox value={sameIndex} onChange={(e) => {
                        if (e.target.checked) {
                            setSameIndex(1)
                        } else {
                            setSameIndex(0)
                        }
                    }}>只对比具有相同索引的数据（交计算）</Checkbox>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: "140px 1fr", marginTop: 10 }}>
                    <Text strong>呈现方式:</Text>
                    <div style={{ width: 500 }}>
                        <Row>
                            {
                                groupPresentWays.map(item => {
                                    return <Col span={8}>
                                        <Checkbox value={selectedPresentWays.indexOf(item.key) != -1 ? true : false} onChange={(e) => {
                                            if (e.target.checked) {
                                                let obj = [...selectedPresentWays]
                                                obj.push(item.key)
                                                setSelectedPresentWays(obj)
                                            } else {
                                                let obj = [...selectedPresentWays]
                                                obj.deleteElementByValue(item.key)
                                                setSelectedPresentWays(obj)
                                            }
                                        }}>{item.name}</Checkbox>
                                    </Col>
                                })
                            }
                        </Row>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Index