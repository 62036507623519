import React, { useState, useEffect } from "react";
import {
    message,
    Button,
    Input,
    Select,
    Image,
    Spin,
    Typography,
    Radio,
    DatePicker,
    Checkbox,
    Table,
} from "antd";
import servicePath from "../../config/apiUrl";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import NbAxios from "../../config/utils/nbAxios";
import cookie from "react-cookies";
import style from "./index.module.css"
import downExcel from "../../config/utils/downloadFile"
import axios from "axios";
// import style from "./index.module.css"
const { Text } = Typography;
// 设置选择器
const { Option } = Select;
const { Title } = Typography;
//文本域
const { TextArea } = Input;
function Index() {
    // 规定时间格式
    const dateFormat = "YYYY-MM";
    // 竞品分析表分析维度列表
    const analysisDimensionList = ["品类销售", "品类价格带", "销售阶级"]
    // 强势品牌表分析维度列表
    const strongBrandAnalysisDimensionList = ["金额", "件数"]
    // 设置加载中变量
    const [loading, setloading] = useState(false);
    // 竞品分析表品类列表
    const [competitiveCategoryList, setCompetitiveCategoryList] = useState([])
    // 竞品时间选择
    const [competitiveTime, setCompetitiveTime] = useState("")
    // 竞品分析表分析维度选择
    const [analysisDimension, setAnalysisDimension] = useState("")
    // 设置选中的竞品分析品类列表
    const [competitiveCheckedList, setCompetitiveCheckedList] = useState([]);
    // 竞品分析表数据
    const [competitiveTableData, setCompetitiveTableData] = useState([])
    // 品类比较时间选择
    const [categoryComparisonTime, setCategoryComparisonTime] = useState("")
    // 设置Top前的输入框
    const [categoryComparisonTopNumber, setCategoryComparisonTopNumber] = useState([])
    // 设置销售汇总的输入框
    const [salesSummary, setSalesSummary] = useState([])
    // 品类比较表店铺列表
    const [categoryComparisonList, setCategoryComparisonList] = useState([])
    // 设置选中的品类比较表店铺列表
    const [categoryComparisonCheckedList, setCategoryComparisonCheckedList] = useState([]);
    // 品类比较表数据
    const [categoryComparisonTableData, setCategoryComparisonTableData] = useState([])
    // 品类比较表头
    const [categoryComparisonTableColumns, setCategoryComparisonTableColumns] = useState([])
    // 强势品牌时间选择
    const [strongBrandTime, setStrongBrandTime] = useState("")
    // 强势品牌表品类列表
    const [strongBrandCategoryList, setStrongBrandCategoryList] = useState([])
    // 强势品牌表品品牌列表
    const [strongBrandList, setStrongBrandList] = useState([])
    // 设置选中的强势品牌表品类
    const [strongBrandCategoryChecked, setStrongBrandCategoryChecked] = useState("");
    // 设置选中的强势品牌表品牌列表
    const [strongBrandCheckedList, setStrongBrandCheckedList] = useState([]);
    // 强势品牌表数据
    const [strongBrandTableData, setStrongBrandTableData] = useState([])
    // 强势品牌表分析维度选择
    const [strongBrandAnalysisDimension, setStrongBrandAnalysisDimension] = useState("")
    // 设置强势分析表字符串字段
    const [strongBrandStr, setStrongBrandStr] = useState("")
    // 竞品Top时间选择
    const [competitiveTopTime, setCompetitiveTopTime] = useState("")
    // 设置选中的竞品Top表品类
    const [competitiveTopChecked, setCompetitiveTopChecked] = useState("");
    // 竞品Top表品品牌列表
    const [competitiveTopList, setCompetitiveTopList] = useState([])
    // 竞品Top表数据
    const [competitiveTopTableData, setCompetitiveTopTableData] = useState([])
    // 初始化
    useEffect(() => {
        initialization()
    }, [])
    // // 切换维度时清空品类列表
    // useEffect(() => {
    //     setCompetitiveCheckedList([])
    // }, [analysisDimension])
    // // 切换维度时清空品类,品牌列表
    // useEffect(() => {
    //     setStrongBrandCategoryChecked("")
    //     setStrongBrandCheckedList([])

    // }, [strongBrandAnalysisDimension])
    // 初始化函数
    const initialization = () => {
        setloading(true)
        setCompetitiveCheckedList([])
        // setCompetitiveCategoryList(["全选", "羽绒服", "毛针织衫", "毛呢外套", "裤子", "皮革", "连衣裙", "短外套", "牛仔裤", "卫衣/绒衫", "大码女装", "T恤", "半身裙", "衬衫", "西装", "风衣"])
        // setCategoryComparisonList(["全选", "moco官方旗舰店", "MISS SIXTY官方旗舰店", "大地素", "小地素", "sandro官方旗舰店", "maje", "maxrieny旗舰店", "cocoon官方旗舰店"])
        // setStrongBrandCategoryList(["羽绒服", "毛针织衫", "毛呢外套", "裤子", "皮革", "连衣裙", "短外套", "牛仔裤", "卫衣/绒衫", "大码女装", "T恤", "半身裙", "衬衫", "西装", "风衣"])
        // setStrongBrandList(["全选", "moco官方旗舰店", "MISS SIXTY官方旗舰店", "大地素", "小地素", "sandro官方旗舰店", "maje", "maxrieny旗舰店", "cocoon官方旗舰店"])
        // setCompetitiveTopList(["羽绒服", "毛针织衫", "毛呢外套", "裤子", "皮革", "连衣裙", "短外套", "牛仔裤", "卫衣/绒衫", "大码女装", "T恤", "半身裙", "衬衫", "西装", "风衣","唐装_民族服装_舞台服装"])
        // 调用初始化界面接口
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.competitiveAll).then(
            (res) => {
                setloading(false)
                if (res.success) {
                    setCompetitiveCategoryList(res.data[0])
                    setStrongBrandCategoryList(res.data[1])
                    setCategoryComparisonList(res.data[2])
                    setStrongBrandList(res.data[2])
                    setCompetitiveTopList(res.data[3])
                } else {
                    message.warn(res.errorMsg);
                }
            }
        );
    }
    // 统计时间选择函数
    const onCompetitiveTime = (date, dateString) => {
        if (dateString == "") {
            message.warn("请选择正确的时间");
            return false;
        } else {
            setCompetitiveTime(dateString);
        }
    };
    // 品类比较时间选择函数
    const onCategoryComparisonTime = (date, dateString) => {
        if (dateString == "") {
            message.warn("请选择正确的时间");
            return false;
        } else {
            setCategoryComparisonTime(dateString);
        }
    };
    // 强势品牌表时间选择函数
    const onStrongBrandTime = (date, dateString) => {
        if (dateString == "") {
            message.warn("请选择正确的时间");
            return false;
        } else {
            setStrongBrandTime(dateString);
        }
    };
    // 竞品Top表时间选择函数
    const onCompetitiveTopTime = (date, dateString) => {
        if (dateString == "") {
            message.warn("请选择正确的时间");
            return false;
        } else {
            setCompetitiveTopTime(dateString);
        }
    };
    // 合并列函数
    const createCategoryNewArr = (data) => {
        return data.reduce((result, item) => {
            //首先将name字段作为新数组result取出
            if (result.indexOf(item.category) < 0) {
                result.push(item.category)
            }
            return result
        }, []).reduce((result, category) => {
            //将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
            const children = data.filter(item => item.category === category);
            result = result.concat(
                children.map((item, index) => ({
                    ...item,
                    rowCategorySpan: index === 0 ? children.length : 0,//将第一行数据添加rowSpan字段
                })),
            )
            return result;
        }, [])
    }
    //数组中删除指定一项数据
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    // 竞品分析表表头
    const [competitiveTableColumns, setCompetitiveTableColumns] = useState([])
    // 强势品牌表头
    const strongBrandTableColumns = [
        {
            title: "品牌",
            dataIndex: "brand",
            key: "brand",
            width: 100,
            align: "center",
        },
        {
            title: "0-300",
            dataIndex: "0-300",
            key: "0-300",
            width: 100,
            align: "center",
        },
        {
            title: "300-500",
            dataIndex: "300-500",
            key: "300-500",
            width: 100,
            align: "center",
        },
        {
            title: "500-800",
            dataIndex: "500-800",
            key: "500-800",
            width: 100,
            align: "center",
        },
        {
            title: "800-1000",
            dataIndex: "800-1000",
            key: "800-1000",
            width: 100,
            align: "center",
        },
        {
            title: "1000-1200",
            dataIndex: "1000-1200",
            key: "1000-1200",
            width: 100,
            align: "center",
        },
        {
            title: "1200-1500",
            dataIndex: "1200-1500",
            key: "1200-1500",
            width: 100,
            align: "center",
        },
        {
            title: "1500-1800",
            dataIndex: "1500-1800",
            key: "1500-1800",
            width: 100,
            align: "center",
        },
        {
            title: "1800-2100",
            dataIndex: "1800-2100",
            key: "1800-2100",
            width: 100,
            align: "center",
        },
        {
            title: "2100-2500",
            dataIndex: "2100-2500",
            key: "2100-2500",
            width: 100,
            align: "center",
        },
        {
            title: "2500-3000",
            dataIndex: "2500-3000",
            key: "2500-3000",
            width: 100,
            align: "center",
        },
        {
            title: "3000-5000",
            dataIndex: "3000-5000",
            key: "3000-5000",
            width: 100,
            align: "center",
        },
        {
            title: "5000-8000",
            dataIndex: "5000-8000",
            key: "5000-8000",
            width: 100,
            align: "center",
        },
        {
            title: "8000以上",
            dataIndex: "8000以上",
            key: "8000以上",
            width: 100,
            align: "center",
        },
    ]
    // 竞品Top表头
    const [competitiveTopTableColumns, setCompetitiveTopTableColumns] = useState([
        {
            title: "日期",
            dataIndex: "date",
            key: "date",
            width: 50,
            align: "center",
        },
        {
            title: "商品名称",
            dataIndex: "name",
            key: "name",
            width: 50,
            align: "center",
        },
        {
            title: "所属店铺",
            dataIndex: "shop",
            key: "shop",
            width: 50,
            align: "center",
        },
        {
            title: "平台",
            dataIndex: "platform",
            key: "platform",
            width: 50,
            align: "center",
        },
        {
            title: "类目名称",
            dataIndex: "category",
            key: "category",
            width: 50,
            align: "center",
        },
        {
            title: "行业排名",
            dataIndex: "rank",
            key: "rank",
            width: 50,
            align: "center",
        },
        {
            title: "交易金额",
            dataIndex: "transactionAmount",
            key: "transactionAmount",
            width: 50,
            align: "center",
        },
        {
            title: "交易增长幅度",
            dataIndex: "transactionGrowth",
            key: "transactionGrowth",
            width: 50,
            align: "center",
        },
        {
            title: "交易转换率",
            dataIndex: "transactionConversionRate",
            key: "transactionConversionRate",
            width: 50,
            align: "center",
        },
    ])
    return (
        <div>
            <Spin tip="Loading..." spinning={loading}>
                <div style={{ display: "flex",position:'fixed', top:40, zIndex:99, backgroundColor:'#fff' }}>
                    {/* style={{ width: "60vw", border: "1px dashed #ccc", height: "5vh", marginLeft: "15vw" }} */}
                    <div style={{display:'flex', justifyContent:'space-around', width:'100vw', borderBottom: "1px dashed #ccc"}}>
                        <div style={{marginTop:'0.8vh'}}>
                            <Title level={3}>商品企划——竞品</Title>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop:'0.4vh' }}>
                            <Button style={{ marginTop: 6, marginRight: 10 }}>竞品新品追踪</Button>
                            <a href="#1F">
                                <Button style={{ marginTop: 6, marginRight: 10 }}>竞品分析表</Button>
                            </a>
                            <a href="#2F">
                                <Button style={{ marginTop: 6, marginRight: 10 }}>竞品品类比较表</Button>
                            </a>
                            <a href="#3F">
                                <Button style={{ marginTop: 6, marginRight: 10 }}>强势品牌价格带</Button>
                            </a>
                            <a href="#4F">
                                <Button style={{ marginTop: 6 }}>竞品Top分析</Button>
                            </a>
                        </div>
                    </div>
                    {/* <div style={{ display: "flex", marginLeft: "5vw" }}>
                        <Button style={{ marginTop: 6, width: "120px" }} type="primary">确定</Button>
                    </div> */}
                </div>
                {/* 竞品分析表 */}
                <div className={style.Table} id="1F" style={{marginTop:80}}>
                    <div style={{ display: "flex", justifyContent: 'center', marginTop: 20 }}>
                        <Title level={3}>竞品分析表</Title>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 30 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>时间：</Text>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <DatePicker
                                locale={locale}
                                picker="month"
                                onChange={onCompetitiveTime}
                                value={
                                    competitiveTime == ""
                                        ? ""
                                        : moment(competitiveTime, dateFormat)
                                }
                                format={dateFormat}
                                style={{ width: 188 }}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}        // 禁止选择超过今天的日期 
                            />
                        </div>
                    </div>
                    <div className={style.container}>
                        <div >
                            <Text style={{
                                fontSize: 15,
                                marginTop: 5
                            }}>分析维度：</Text>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <Radio.Group
                                onChange={(e) => setAnalysisDimension(e.target.value)}
                                value={analysisDimension}
                            >
                                {analysisDimensionList.map((item) => (
                                    <Radio value={item}>{item}</Radio>
                                ))}
                            </Radio.Group>
                        </div>
                    </div>
                    {/* <div style={{display:"flex", marginLeft:100,marginTop:20}}> */}
                    {analysisDimension !== "品类价格带" ? false : <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 30 }}>
                            <Text style={{
                                fontSize: 15,
                                marginTop: 5
                            }}>品类：</Text>
                        </div>
                        <div style={{ marginTop: 6, marginLeft: 15 }}>
                            {competitiveCategoryList.map((item) => {
                                return (
                                    <Checkbox style={{ marginLeft: 5 }} checked={competitiveCheckedList.indexOf(item) != -1 ? true : false}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                // 全部选中
                                                if (item == '全选') {
                                                    let obj = []
                                                    competitiveCategoryList.map((item) => {
                                                        obj.push(item)
                                                    })
                                                    setCompetitiveCheckedList(obj)
                                                } else {
                                                    let obj = []
                                                    obj = [...competitiveCheckedList]
                                                    obj.push(item)
                                                    setCompetitiveCheckedList(obj)
                                                }
                                            } else {
                                                // 全部取消
                                                if (item == '全选') {
                                                    setCompetitiveCheckedList([])
                                                } else {
                                                    let obj = []
                                                    obj = [...competitiveCheckedList]
                                                    obj.remove(item)
                                                    setCompetitiveCheckedList(obj)
                                                }
                                            }
                                        }}>
                                        {item}
                                    </Checkbox>
                                )
                            })}
                        </div>
                    </div>}
                    <div style={{ display: "flex", float: "right", marginRight: 50, marginTop: 20 }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                let flag = true;
                                setCompetitiveTableData([])
                                if (competitiveTime == "") {
                                    message.error("未选择时间")
                                    flag = false
                                } else if (analysisDimension == "") {
                                    message.error("未选择分析维度")
                                    flag = false
                                } else {
                                    if (analysisDimension == "品类销售") {
                                        setCompetitiveTableColumns([{
                                            title: "品类",
                                            dataIndex: "category",
                                            key: "category",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "款数",
                                            dataIndex: "modelNumber",
                                            key: "modelNumber",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "销售额",
                                            dataIndex: "sales",
                                            key: "sales",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "销量",
                                            dataIndex: "salesVolume",
                                            key: "salesVolume",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "销售额占比",
                                            dataIndex: "percentageOfSales",
                                            key: "percentageOfSales",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "件单价",
                                            dataIndex: "unitPrice",
                                            key: "unitPrice",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "单款贡献",
                                            dataIndex: "singleContribution",
                                            key: "singleContribution",
                                            width: 50,
                                            align: "center",
                                        },
                                        ])
                                    } else if (analysisDimension == "品类价格带") {
                                        if (competitiveCheckedList.length == 0) {
                                            message.error("未选择品类")
                                            flag = false
                                        } else {
                                            setCompetitiveTableColumns([{
                                                title: "品类",
                                                dataIndex: "category",
                                                key: "category",
                                                width: 50,
                                                align: "center",
                                                render(_, row) {
                                                    return {
                                                        children: row.category,
                                                        props: {
                                                            rowSpan: row.rowCategorySpan,
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                title: "价格带",
                                                dataIndex: "priceBand",
                                                key: "priceBand",
                                                width: 50,
                                                align: "center",
                                            },
                                            {
                                                title: "款数",
                                                dataIndex: "modelNumber",
                                                key: "modelNumber",
                                                width: 50,
                                                align: "center",
                                            },
                                            {
                                                title: "销售额",
                                                dataIndex: "sales",
                                                key: "sales",
                                                width: 50,
                                                align: "center",
                                            },
                                            {
                                                title: "销量",
                                                dataIndex: "salesVolume",
                                                key: "salesVolume",
                                                width: 50,
                                                align: "center",
                                            },
                                            {
                                                title: "销售额占比",
                                                dataIndex: "percentageOfSales",
                                                key: "percentageOfSales",
                                                width: 50,
                                                align: "center",
                                            },
                                            {
                                                title: "件单价",
                                                dataIndex: "unitPrice",
                                                key: "unitPrice",
                                                width: 50,
                                                align: "center",
                                            },
                                            {
                                                title: "单款贡献",
                                                dataIndex: "singleContribution",
                                                key: "singleContribution",
                                                width: 50,
                                                align: "center",
                                            },
                                            ])
                                        }
                                    } else if (analysisDimension == "销售阶级") {
                                        setCompetitiveTableColumns([{
                                            title: "销售阶级",
                                            dataIndex: "salesClass",
                                            key: "salesClass",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "款数",
                                            dataIndex: "modelNumber",
                                            key: "modelNumber",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "销售额",
                                            dataIndex: "sales",
                                            key: "sales",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "销量",
                                            dataIndex: "salesVolume",
                                            key: "salesVolume",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "销售额占比",
                                            dataIndex: "percentageOfSales",
                                            key: "percentageOfSales",
                                            width: 50,
                                            align: "center",
                                        },
                                        {
                                            title: "件单价",
                                            dataIndex: "unitPrice",
                                            key: "unitPrice",
                                            width: 50,
                                            align: "center",
                                        },
                                        ])
                                    }
                                    if (flag) {
                                        setloading(true)
                                        let CheckedList = []
                                        competitiveCheckedList.map((item)=>{
                                            if(item !== "全选"){
                                                CheckedList.push(item)
                                            }
                                        })
                                        // 数据传入调接口
                                        let dataProps = {
                                            competitiveTime: competitiveTime,
                                            analysisDimension: analysisDimension,
                                            competitiveCheckedList: CheckedList,
                                            roleId:cookie.load('role'),
                                            shopList:cookie.load('shopList'),
                                        }
                                        NbAxios(dataProps, "POST", servicePath.competitiveAnalysis).then(
                                            (res) => {
                                                setloading(false)
                                                if (res.success) {
                                                    message.success("查询成功");
                                                    setCompetitiveTableData(res.data)
                                                } else {
                                                    message.warn(res.errorMsg);
                                                }
                                            }
                                        );
                                    }
                                }
                            }}>确定</Button>
                        <Button type="primary" onClick={()=>{
                            if(competitiveTableData.length == 0){
                                message.error("未生成表格")
                            }else{
                                setloading(true)
                                let CheckedList = []
                                competitiveCheckedList.map((item)=>{
                                    if(item !== "全选"){
                                        CheckedList.push(item)
                                    }
                                })
                                // 数据传入调接口
                                let dataProps = {
                                    competitiveTime: competitiveTime,
                                    analysisDimension: analysisDimension,
                                    competitiveCheckedList: CheckedList,
                                    competitiveTableData:competitiveTableData
                                }
                                axios({
                                    data: dataProps,
                                    method: 'POST',
                                    url: servicePath.downloadCompetitiveAnalysis,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        setloading(false)
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    setloading(false)
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }
                                
                        }}>下载</Button>
                    </div>
                    <div>
                        <Table
                            dataSource={createCategoryNewArr(competitiveTableData)}
                            columns={competitiveTableColumns}
                            scroll={{ y:500}}
                        // rowKey={"skc"}
                        pagination={false}
                        />
                    </div>
                </div>
                {/* 竞品店铺销售TOP品类比较表 */}
                <div className={style.Table} id="2F">
                    <div style={{ display: "flex", justifyContent: 'center', marginTop: 20 }}>
                        <Title level={3}>竞品店铺销售TOP品类比较表</Title>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>销售时间：</Text>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <DatePicker
                                locale={locale}
                                picker="month"
                                onChange={onCategoryComparisonTime}
                                value={
                                    categoryComparisonTime == ""
                                        ? ""
                                        : moment(categoryComparisonTime, dateFormat)
                                }
                                format={dateFormat}
                                style={{ width: 188 }}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}        // 禁止选择超过今天的日期 
                            />
                        </div>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 15 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>TOP前：</Text>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <Input style={{ width: 188 }} value={categoryComparisonTopNumber} onChange={(e) => {
                                let obj = [...categoryComparisonTopNumber]
                                obj = e.target.value
                                setCategoryComparisonTopNumber(obj)
                            }} />
                        </div>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 30 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>店铺：</Text>
                        </div>
                        <div style={{ marginTop: 6, marginLeft: 15 }}>
                            {categoryComparisonList.map((item) => {
                                return (
                                    <Checkbox style={{ marginLeft: 5 }} checked={categoryComparisonCheckedList.indexOf(item) != -1 ? true : false}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                // 全部选中
                                                if (item == '全选') {
                                                    let obj = []
                                                    categoryComparisonList.map((item) => {
                                                        obj.push(item)
                                                    })
                                                    setCategoryComparisonCheckedList(obj)
                                                } else {
                                                    let obj = []
                                                    obj = [...categoryComparisonCheckedList]
                                                    obj.push(item)
                                                    setCategoryComparisonCheckedList(obj)
                                                }
                                            } else {
                                                // 全部取消
                                                if (item == '全选') {
                                                    setCategoryComparisonCheckedList([])
                                                } else {
                                                    let obj = []
                                                    obj = [...categoryComparisonCheckedList]
                                                    obj.remove(item)
                                                    setCategoryComparisonCheckedList(obj)
                                                }
                                            }
                                        }}>
                                        {item}
                                    </Checkbox>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ display: "flex", float: "right", marginRight: 50, marginTop: 20 }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                if (categoryComparisonTime == "") {
                                    message.error("未选择销售时间")
                                } else if (categoryComparisonTopNumber.length == 0) {
                                    message.error("未填入Top前")
                                } else if (categoryComparisonCheckedList.length == 0) {
                                    message.error("未选择店铺")
                                } else {
                                    setloading(true)
                                    let obj = [{
                                        title: "金额占比",
                                        dataIndex: "proportionOfAmount",
                                        key: "proportionOfAmount",
                                        width: 100,
                                        align: "center",
                                        fixed: 'left',
                                    },
                                    {
                                        title: "品类",
                                        dataIndex: "category",
                                        key: "category",
                                        width: 100,
                                        align: "center",
                                        fixed: 'left',
                                    },
                                    ]
                                    categoryComparisonCheckedList.map((item) => {
                                        if (item !== "全选") {
                                            obj.push({
                                                title: item,
                                                width: 100,
                                                align: "center",
                                                dataIndex: item,
                                                key: item,
                                            })
                                        }
                                    })
                                    obj.push({
                                        title: "总计",
                                        dataIndex: "total",
                                        key: "total",
                                        width: 100,
                                        align: "center",
                                    })
                                    setCategoryComparisonTableColumns(obj)
                                    let CheckedList = []
                                    categoryComparisonCheckedList.map((item)=>{
                                        if(item !== "全选"){
                                            CheckedList.push(item)
                                        }
                                    })
                                    // 数据传入掉接口
                                    let dataProps = {
                                        categoryComparisonTime: categoryComparisonTime,
                                        categoryComparisonTopNumber: categoryComparisonTopNumber,
                                        categoryComparisonCheckedList: CheckedList,
                                        roleId:cookie.load('role'),
                                        shopList:cookie.load('shopList'),
                                    }
                                    NbAxios(dataProps, "POST", servicePath.categoryComparisonTop).then(
                                        (res) => {
                                            setloading(false)
                                            if (res.success) {
                                                message.success("查询成功");
                                                setCategoryComparisonTableData(res.data)
                                            } else {
                                                message.warn(res.errorMsg);
                                            }
                                        }
                                    );
                                }
                            }}>确定</Button>
                        <Button type="primary" onClick={() => {
                            if(categoryComparisonTableData.length == 0){
                                message.error("未生成表格")
                            }else{
                                setloading(true)
                                let CheckedList = []
                                categoryComparisonCheckedList.map((item)=>{
                                    if(item !== "全选"){
                                        CheckedList.push(item)
                                    }
                                })
                                // 数据传入调接口
                                let dataProps = {
                                    categoryComparisonTime: categoryComparisonTime,
                                    categoryComparisonCheckedList: CheckedList,
                                    salesSummary:salesSummary,
                                    categoryComparisonTableData:categoryComparisonTableData
                                }
                                axios({
                                    data: dataProps,
                                    method: 'POST',
                                    url: servicePath.downloadCategoryComparisonTop,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        setloading(false)
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    setloading(false)
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }
                        }}>下载</Button>
                    </div>
                    <div>
                        <Table
                            dataSource={categoryComparisonTableData}
                            columns={categoryComparisonTableColumns}
                            scroll={{y :500}}
                        // rowKey={"skc"}
                        // pagination={paginationProps}
                            pagination={false}
                        />
                    </div>
                    {categoryComparisonTableColumns.length !== 0 ? <div className={style.container}>
                        <div style={{ marginTop: 20 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>销售汇总：</Text>
                        </div>
                        <div>
                            <Input style={{ width: '80%', marginLeft: 20 , marginTop:10}} value={salesSummary} onChange={(e) => {
                                let obj = [...salesSummary]
                                obj = e.target.value
                                setSalesSummary(obj)
                            }} />
                        </div>
                    </div> : false}

                </div>

                {/* 核心品类核心价格带强势品牌分析表 */}
                <div className={style.Table} id="3F">
                    <div style={{ display: "flex", justifyContent: 'center', marginTop: 20 }}>
                        <Title level={3}>核心品类核心价格带强势品牌分析表</Title>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>销售时间：</Text>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <DatePicker
                                locale={locale}
                                picker="month"
                                onChange={onStrongBrandTime}
                                value={
                                    strongBrandTime == ""
                                        ? ""
                                        : moment(strongBrandTime, dateFormat)
                                }
                                format={dateFormat}
                                style={{ width: 188 }}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}        // 禁止选择超过今天的日期 
                            />
                        </div>
                    </div>
                    <div className={style.container}>
                        <div>
                            <Text style={{
                                fontSize: 15,
                            }}>分析维度：</Text>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <Radio.Group
                                onChange={(e) => setStrongBrandAnalysisDimension(e.target.value)}
                                value={strongBrandAnalysisDimension}
                            >
                                {strongBrandAnalysisDimensionList.map((item) => (
                                    <Radio value={item}>{item}</Radio>
                                ))}
                            </Radio.Group>
                        </div>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 30 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>品类：</Text>
                        </div>
                        <div style={{ marginLeft: 20, marginTop: 5 }}>
                            <Radio.Group
                                onChange={(e) => setStrongBrandCategoryChecked(e.target.value)}
                                value={strongBrandCategoryChecked}
                            >
                                {strongBrandCategoryList.map((item) => (
                                    <Radio value={item}>{item}</Radio>
                                ))}
                            </Radio.Group>
                        </div>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 30 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>品牌：</Text>
                        </div>
                        <div style={{ marginTop: 6, marginLeft: 15 }}>
                            {strongBrandList.map((item) => {
                                return (
                                    <Checkbox style={{ marginLeft: 5 }} checked={strongBrandCheckedList.indexOf(item) != -1 ? true : false}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                // 全部选中
                                                if (item == '全选') {
                                                    let obj = []
                                                    strongBrandList.map((item) => {
                                                        obj.push(item)
                                                    })
                                                    setStrongBrandCheckedList(obj)
                                                } else {
                                                    let obj = []
                                                    obj = [...strongBrandCheckedList]
                                                    obj.push(item)
                                                    setStrongBrandCheckedList(obj)
                                                }
                                            } else {
                                                // 全部取消
                                                if (item == '全选') {
                                                    setStrongBrandCheckedList([])
                                                } else {
                                                    let obj = []
                                                    obj = [...strongBrandCheckedList]
                                                    obj.remove(item)
                                                    setStrongBrandCheckedList(obj)
                                                }
                                            }
                                        }}>
                                        {item}
                                    </Checkbox>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ display: "flex", float: "right", marginRight: 50, marginTop: 20 }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                if (strongBrandTime == "") {
                                    message.error("未选择销售时间")
                                } else if (strongBrandAnalysisDimension == "") {
                                    message.error("未选择分析维度")
                                } else if (strongBrandCategoryChecked == "") {
                                    message.error("未选择品类")
                                } else if (strongBrandCheckedList.length == 0) {
                                    message.error("未选择品牌")
                                } else {
                                    setloading(true)
                                    let CheckedList = []
                                    strongBrandCheckedList.map((item)=>{
                                        if(item !== "全选"){
                                            CheckedList.push(item)
                                        }
                                    })
                                    // 数据传入掉接口
                                    let dataProps = {
                                        strongBrandTime: strongBrandTime,
                                        strongBrandAnalysisDimension: strongBrandAnalysisDimension,
                                        strongBrandCheckedList: CheckedList,
                                        strongBrandCategoryChecked: strongBrandCategoryChecked,
                                        roleId:cookie.load('role'),
                                        shopList:cookie.load('shopList'),
                                    }
                                    setloading(true)
                                    NbAxios(dataProps, "POST", servicePath.strongBrandPriceBand).then(
                                        (res) => {
                                            setloading(false)
                                            if (res.success) {
                                                message.success("查询成功");
                                                setStrongBrandTableData(res.data)
                                            } else {
                                                message.warn(res.errorMsg);
                                            }
                                        }
                                    );
                                    setStrongBrandStr(strongBrandCategoryChecked + "-" + strongBrandAnalysisDimension)
                                }
                            }}>确定</Button>
                        <Button type="primary" onClick={()=>{
                            if(strongBrandTableData.length == 0){
                                message.error("未生成表格")
                            }else{
                            // 数据传入掉接口
                            let dataProps = {
                                strongBrandTime: strongBrandTime,
                                strongBrandAnalysisDimension: strongBrandAnalysisDimension,
                                strongBrandTableData:strongBrandTableData,
                                strongBrandTableColumns:strongBrandTableColumns
                            }
                            axios({
                                data: dataProps,
                                method: 'POST',
                                url: servicePath.downloadStrongBrandPriceBand,
                                responseType: "blob",
                                headers: {
                                    'Content-Type': 'application/json',  //如果写成contentType会报错
                                    'authorization': cookie.load('token')
                                }
                            }).then(
                                res => {
                                    setloading(false)
                                    downExcel(res)
                                }
                            ).catch(e => {
                                setloading(false)
                                message.error({ content: '文件制作出错，请联系it同事！' })
                            })
                            }
                        }}>下载</Button>
                    </div>
                    {strongBrandStr != "" ? <div style={{ marginLeft: 30, marginTop: 10 }}>
                        <Text style={{
                            fontSize: 20,
                        }}>{strongBrandStr}</Text>
                    </div> : false}
                    <div>
                        <Table
                            dataSource={strongBrandTableData}
                            columns={strongBrandTableColumns}
                            scroll={{y:500}}
                            rowKey={"brand"}
                        // pagination={paginationProps}
                            pagination={false}
                        />
                    </div>
                </div>
                {/* 竞品Top分析表 */}
                <div className={style.Table} id="4F">
                    <div style={{ display: "flex", justifyContent: 'center', marginTop: 20 }}>
                        <Title level={3}>竞品Top分析表</Title>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 30 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>时间：</Text>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <DatePicker
                                locale={locale}
                                picker="month"
                                onChange={onCompetitiveTopTime}
                                value={
                                    competitiveTopTime == ""
                                        ? ""
                                        : moment(competitiveTopTime, dateFormat)
                                }
                                format={dateFormat}
                                style={{ width: 188 }}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}        // 禁止选择超过今天的日期 
                            />
                        </div>
                    </div>
                    <div className={style.container}>
                        <div style={{ marginTop: 6, marginLeft: 30 }}>
                            <Text style={{
                                fontSize: 15,
                            }}>品类：</Text>
                        </div>
                        <div style={{ marginLeft: 20, marginTop: 5 }}>
                            <Radio.Group
                                onChange={(e) => setCompetitiveTopChecked(e.target.value)}
                                value={competitiveTopChecked}
                            >
                                {competitiveTopList.map((item) => (
                                    <Radio value={item}>{item}</Radio>
                                ))}
                            </Radio.Group>
                        </div>
                    </div>
                    <div style={{ display: "flex", float: "right", marginRight: 50, marginTop: 20 }}>
                        <Button
                            type="primary"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                if (competitiveTopTime == "") {
                                    message.error("未填入时间")
                                } else if (competitiveTopChecked == "") {
                                    message.error("未填入品类")
                                } else {
                                    setloading(true)
                                    // 数据传入掉接口
                                    let dataProps = {
                                        competitiveTopTime: competitiveTopTime,
                                        competitiveTopChecked: competitiveTopChecked,
                                        roleId:cookie.load('role'),
                                        shopList:cookie.load('shopList'),
                                    }
                                    NbAxios(dataProps, "POST", servicePath.competitiveTop).then(
                                        (res) => {
                                            setloading(false)
                                            if (res.success) {
                                                message.success("查询成功");
                                                setCompetitiveTopTableData(res.data)
                                            } else {
                                                message.warn(res.errorMsg);
                                            }
                                        }
                                    );
                                }
                            }}>确定</Button>
                        <Button type="primary" onClick={()=>{
                            if(competitiveTopTableData.length == 0){
                                message.error("未生成表格")
                            }else{
                                // 数据传入掉接口
                                let dataProps = {
                                    competitiveTopTime: competitiveTopTime,
                                    competitiveTopTableData: competitiveTopTableData,
                                    competitiveTopTableColumns:competitiveTopTableColumns
                                }
                                axios({
                                    data: dataProps,
                                    method: 'POST',
                                    url: servicePath.downloadCompetitiveTop,
                                    responseType: "blob",
                                    headers: {
                                        'Content-Type': 'application/json',  //如果写成contentType会报错
                                        'authorization': cookie.load('token')
                                    }
                                }).then(
                                    res => {
                                        setloading(false)
                                        downExcel(res)
                                    }
                                ).catch(e => {
                                    setloading(false)
                                    message.error({ content: '文件制作出错，请联系it同事！' })
                                })
                            }
                        }}>下载</Button>
                    </div>
                    <div style={{marginBottom:100}}>
                        <Table
                            dataSource={competitiveTopTableData}
                            columns={competitiveTopTableColumns}
                            scroll={{ y : 500}}
                        // rowKey={"brand"}
                        // pagination={paginationProps}
                            pagination={false}
                        />
                    </div>
                </div>
            </Spin> 
        </div>
    )
}
export default Index;