import React from "react";
import { Button } from "antd";
import style from "./index.module.css";
function Initial(props) {
  return (
    <div className={style.initial}>
      <div className={style.externalContent}>
        <div className={style.dis} style={{ fontSize: "50px" }}>
          Pos系统
        </div>
        <div className={style.dis}>
          <Button
            style={{
              fontSize: "30px",
              width: "300px",
              height: "150px",
              textAlign: "center",
              marginRight: "200px",
            }}
            onClick={() =>
              props.history.push({ pathname: `/app/pos2023/create` })
            }
          >
            创建订单
          </Button>
          <Button
            style={{
              fontSize: "30px",
              width: "300px",
              height: "150px",
              textAlign: "center",
            }}
            onClick={() =>
              props.history.push({ pathname: `/app/pos2023/search` })
            }
          >
            查询订单
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Initial;
