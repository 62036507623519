import React, { useState, useEffect } from 'react'
import {Table, Modal, Form, Input,Button,Typography,Row,message } from 'antd'
import cookie from 'react-cookies'
import NbAxios from '../../config/utils/nbAxios';
import servicePath from '../../config/apiUrl';
const { Paragraph, Text } = Typography;

function Index(){
     // 设置输入数字
    function isNumber(val){
 
        var regPos = /^\d+(\.\d+)?$/; //非负浮点数
        var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
        if(regPos.test(val) || regNeg.test(val)){
            return true;
        }else{
            return false;
        }
    }
    const [form] = Form.useForm()
    // 数据
    const [data , setData] =useState([])
    // 定义查询手机格式
    const [ phone , setPhone] = useState('')
    // 定义页数，默认值为1
    const [ page , setPage ] = useState(1)
    // 定义数据量总和,默认值为0
    const [ total , setTotal] = useState(0)    
    // 是否编辑内容
    const [isUpdate , setIsUpdate ] = useState(false)
    // 定义翻页操作
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
   
    // 进入页面的时候调用
    // 监控翻页
    useEffect(()=>{
        getData()
    },[page]) 
    // 设置表格
    const columns = [
        
        {
            title: "手机号",    // 表头标题
            dataIndex: "phone_number",   //  对应数据的key
            key: "phone_number",  //  对应数据的key
            width: "20%",   //  表格宽度
            align: "center",  // 便各自的排列方式
            // render: (text, record, index) => {  // 中自定义表格显示
                
            // },
        },
        {
            title: "平台",    // 表头标题
            dataIndex: "sms_platform",   //  对应数据的key
            key: "sms_platform",  //  对应数据的key
            width: "20%",   //  表格宽度
            align: "center",  // 便各自的排列方式
            // render: (text, record, index) => {  // 中自定义表格显示
                
            // },
        },
        {
            title: "关键词",    // 表头标题
            dataIndex: "sms_key",   //  对应数据的key
            key: "sms_key",  //  对应数据的key
            width: "20%",   //  表格宽度
            align: "center",  // 便各自的排列方式
            // render: (text, record, index) => {  // 中自定义表格显示
                
            // },
        },
        {
            title: "查看",    // 表头标题
            dataIndex: "look",   //  对应数据的key
            key: "look",  //  对应数据的key
            width: "20%",   //  表格宽度
            align: "center",  // 便各自的排列方式
            render: (text, record) => {  // 中自定义表格显示
                return <a onClick={() =>{
                    getSmsContent(record)
                }}>查看</ a>;
            },
        },
        {
            title: "发送时间",    // 表头标题
            dataIndex: "receive_time",   //  对应数据的key
            key: "receive_time",  //  对应数据的key
            width: "20%",   //  表格宽度
            align: "center",  // 便各自的排列方式
            // render: (text, record, index) => {  // 中自定义表格显示
                
            // },
        },
        
    ];

    // 请求手机数据
    const getData = () =>{
        // 创建空字典
        let dataProps = {}
        // 设置后端接收的数据
        dataProps.page = page;              
        dataProps.phone_number = phone
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        NbAxios(dataProps, "POST", servicePath.findPhoneMessage).then(res => {
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取短信内容
    const getSmsContent = (record) =>{
        let dataProps = {}
        dataProps.name = cookie.load('name');
        dataProps.id = record.id
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        NbAxios(dataProps, "POST", servicePath.findSmsContent).then(res => {
            console.log(res);
            if (res.success) {
                form.resetFields([])
                form.setFieldsValue(res.data[0])
                setIsUpdate(true)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

   
    return(
        // jsx
        // react 语法，可以在react里面写html
        
        <div>
            <Row style={{display:'flex', width:"50%", marginLeft: "5%", marginTop: "2%" }}
            // 手机号查询
            >
                <Input style={{width:"30%"}} placeholder="请输入要查询的手机号后四位" type="tel"  maxlength="4"  onChange={(e)=>{
                    setPhone(e.target.value)
                }}/>
                <Button style={{width:"10%", marginLeft:"2%"}} type="primary" onClick={()=>{
                    if(isNumber(phone) || phone == ''){
                        getData()
                    }else{
                        message.warn('只能输入数字')
                    }
                    }}>查询</Button>
            </Row>
           <div style={{marginLeft:30, marginTop:10, marginRight:30}}>
            <Table dataSource={data} columns={columns} pagination={paginationProps} style={{width:"100%"}}/>
           </div>
            <Modal 
                title='查看' 
                visible={isUpdate}
                onCancel={()=>setIsUpdate(false)}
                onOk={()=>setIsUpdate(false)}
                footer={[null, null]}
                centered={true}
                // 置空按钮
            >
                <div>
                {/*  // 查看内容表格  */}
                <Form form={form}>
                        <Form.Item label="验证码" name="sms_code" >
                            <Paragraph copyable style={{marginTop:"1.3%"}}>{form.getFieldValue("sms_code")}</Paragraph>
                        </Form.Item>
                        <Form.Item label="密匙" name="sms_key" >
                            <Text>{form.getFieldValue("sms_key")}</Text>
                        </Form.Item>
                        <Form.Item label="详情" name="sms_content" >
                            <Text>{form.getFieldValue("sms_content")}</Text>
                        </Form.Item>
                </Form>
                </div>
                
            </Modal>
        </div>
    )
}

export default Index