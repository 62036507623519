import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, DatePicker, Modal, Image, message, Collapse, Input, Form, Select, Table, Popconfirm } from 'antd'
import { CloudUploadOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import servicePath from '../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import axios from 'axios'
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select


function SKUDetails(props) {
    const [form] = Form.useForm();

    const [form1] = Form.useForm()

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    const layout1 = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    }

    const layout2 = {
        wrapperCol: { span: 16 },
    }

    // 数据
    const [date, setDate] = useState([])

    // 添加的数据
    const [ addDate , setAddDate ] = useState([])

    // 状态
    const [ status , setStatus ] = useState('')

    // 处理时订单号
    const [ operationDdh, setOperationDdh ] = useState('')

    // 处理时id
    const [ operationId , setOperationId ] = useState('')

    // 总数页数
    const [total, setTotal] = useState(-1)

    // 添加的总数
    const [ addTotal , setAddTotal ] = useState(0)

    // 还能添加的数量
    const [ addNum , setAddNum ] = useState(0)

    //页面
    const [page, setPage] = useState(1)

    // E3库存
    const [e3kc, setE3kc] = useState('')

    // 品牌
    const [trademark, setTrademark] = useState('')

    // SKU
    const [SKU, setSKU] = useState('')

    // 采购单号
    const [purchaseNumber, setPurchaseNumber] = useState('')

    // 选中的id列表
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    // 选中的列表对象
    const [selectedRow, setSelectedRow] = useState([])

    // 添加缺货订单弹框
    const [ openStockout, setOpenStockout ] = useState(false)

    // 按钮是否有效
    const [ effective , setEffective ] = useState(0)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 监控数量
    useEffect(()=>{
        // 如果总数为0的时候，然后
        if(total==0){
            props.history.push({ 'pathname': '/app/presell/details', 'state': { 'infomation': purchaseNumber  + ',' + status}})
        }
    },[total])

    useEffect(() => {
        let infoList = props.location.state.infomation.split(',')
        setPurchaseNumber(infoList[0])
        setSKU(infoList[1])
        setE3kc(infoList[2])
        setStatus(infoList[3])
        let dataProps = {
            "cgdh": infoList[0],
            "spbm":infoList[1],
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrderRelation,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }, [])

    // 获取数据
    const getDate = ()=>{
        let dataProps = {
            "cgdh": purchaseNumber,
            "spbm":SKU,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrderRelation,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTotal(res.data.total)
                    setDate(res.data.data)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 表格标题
    const columns = [
        {
            title: '图片',
            key: 'image',
            dataIndex: 'image',
            width: '100px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => (
                <Image width={50} src={'https://pic.bmcsoft.cn/imge_dir/item_info/xh_outlets/'+date[index]['spbm'].substring(0,8)+'.jpg'}/>
            ),
        },
        {
            title: '订单号',
            key: 'ddh',
            dataIndex: 'ddh',
            width: '100px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '店铺',
            key: 'dp',
            dataIndex: 'dp',
            width: '100px',
            align: 'center'
        },
        {
            title: '付款日期',
            key: 'fkrq',
            dataIndex: 'fkrq',
            width: '120px',
            align: 'center'
        },
        {
            title: '款号',
            key: 'kh',
            dataIndex: 'kh',
            width: '120px',
            align: 'center'
        },
        {
            title: '商品编号',
            key: 'spbm',
            dataIndex: 'spbm',
            width: '100px',
            align: 'center'
        },
        // {
        //     title: '商品名称',
        //     key: 'spmc',
        //     dataIndex: 'spmc',
        //     width: '200px',
        //     align: 'center'
        // },
        {
            title: '颜色集规格',
            key: 'ysjgg',
            dataIndex: 'ysjgg',
            width: '150px',
            align: 'center'
        },
        {
            title: '数量',
            key: 'sl',
            dataIndex: 'sl',
            width: '100px',
            align: 'center'
        },
        {
            title: '商品状态',
            key: 'spzt',
            dataIndex: 'spzt',
            width: '100px',
            align: 'center'
        },
        {
            title: '删除缺货订单',
            key: 'delete',
            dataIndex: 'delete',
            width: '100px',
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => (
                <Popconfirm
                    title="确认删除?删除后不可恢复!"
                    onConfirm={affirmDelect}
                    okText="确定"
                    cancelText="取消"
                >
                    <DeleteOutlined onClick={() => {
                            let data = date[index]
                            setOperationDdh(data['ddh']);
                            setOperationId(data['id']);
                        }} 
                        style={{display: status != '待采购' ? 'none': ''}}
                        />
                </Popconfirm>
            ),
        },
    ]

    // 添加表头
    const addColumns = [
        {
            title: '图片',
            key: 'image',
            dataIndex: 'image',
            width: '100px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => (
                <Image width={50} src={'https://pic.bmcsoft.cn/imge_dir/item_info/xh_outlets/'+addDate[index]['spbm'].substring(0,8)+'.jpg'}/>
            ),
        },
        {
            title: '订单号',
            key: 'ddh',
            dataIndex: 'ddh',
            width: '100px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '平台',
            key: 'pt',
            dataIndex: 'pt',
            width: '100px',
            align: 'center'
        },
        {
            title: '品牌',
            key: 'pp',
            dataIndex: 'pp',
            width: '100px',
            align: 'center'
        },
        {
            title: '店铺',
            key: 'dp',
            dataIndex: 'dp',
            width: '100px',
            align: 'center'
        },
        {
            title: '买家账号',
            key: 'mjzh',
            dataIndex: 'mjzh',
            width: '150px',
            align: 'center'
        },
        {
            title: '付款日期',
            key: 'fkrq',
            dataIndex: 'fkrq',
            width: '100px',
            align: 'center'
        },
        {
            title: '款号',
            key: 'kh',
            dataIndex: 'kh',
            width: '100px',
            align: 'center'
        },
        {
            title: '商品编号',
            key: 'spbm',
            dataIndex: 'spbm',
            width: '100px',
            align: 'center'
        },
        {
            title: '商品名称',
            key: 'spmc',
            dataIndex: 'spmc',
            width: '240px',
            align: 'center'
        },
        {
            title: '颜色集规格',
            key: 'ysjgg',
            dataIndex: 'ysjgg',
            width: '150px',
            align: 'center'
        },
        {
            title: '数量',
            key: 'sl',
            dataIndex: 'sl',
            width: '100px',
            align: 'center'
        },
        {
            title: '商品状态',
            key: 'spzt',
            dataIndex: 'spzt',
            width: '100px',
            align: 'center'
        }
    ]

    // 改变多选
    const onSelectChange = (selectedRowKeys, selectedRow) => {
        if(selectedRow.length <= addNum){
            setSelectedRow(selectedRow)
            setSelectedRowKeys(selectedRowKeys)
        }
    }

    // 多选设置
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    }


    // 删除SKU
    const affirmDelect = () => {
        let dataProps = {
            "cgdh":purchaseNumber,
            "spbm": SKU,
            "ddh":operationDdh,
            "id":operationId,
            "total": total,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        console.log(dataProps);
        axios({
            method: 'POST',
            url: servicePath.deletePurchaseOrderRelationOrderOutOfStockOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res=>{
                if(res.data.success){
                    message.success("成功删除缺货订单")
                    setOperationDdh('')
                    setOperationId('')
                    getDate()
                }else{
                    message.error(res.data.errorMsg)
                }
            }
        )
    }


    // 打开添加弹框
    const clickOpen = ()=>{
        let num = e3kc - total
        setAddNum(num)
        let dataProps = {
            "spbm": SKU,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getPurchaseOrderRelationOrderOutOfStockOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setAddTotal(res.data.total)
                    setAddDate(res.data.data)
                    setOpenStockout(true)
                } else {
                    message.error(res.data.errorMsg)
                }
            }
        )
    }

    // 确定添加
    const affirmStockout = ()=>{
        let idList = []
        let ddhList = []
        selectedRow.map(item=>{
            idList.push(item['id'])
            ddhList.push(item['ddh'])
        })
        let dataProps = {
            "cgdh": purchaseNumber,
            "spbm": SKU,
            "id":idList,
            "ddh":ddhList,
            "total":total,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        console.log(dataProps);
        axios({
            method: 'POST',
            url: servicePath.addPurchaseOrderRelationOrderOutOfStockOrder,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(res=>{
            if (res.data.success) {
                message.success("成功添加缺货订单")
                getDate()
                setSelectedRow([])
                setSelectedRowKeys([])
                setOpenStockout(false)
            } else {
                message.error(res.data.errorMsg)
            }
        })
    }

    // 取消添加
    const closeStockout = ()=>{
        message.success("取消添加缺货订单")
        setOpenStockout(false)
    }



    return (
        <div style={{ marginLeft: size.width * 0.06 }}>
            <div>
                <Form
                    form={form}
                    {...layout2}
                    size="large"
                >
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                name="purchaseNumber"
                                label="采购单号:"
                                style={{ fontWeight: "bold" }}
                            >
                                <span style={{ fontWeight: "bold", fontSize: '20px' }}>{purchaseNumber}</span>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="SKU"
                                label="SKU:"
                                style={{ fontWeight: "bold" }}
                            >
                                <span style={{ fontWeight: "bold", fontSize: '20px' }}>{SKU}</span>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                name="number"
                                label="缺货订单数量:"
                                style={{ fontWeight: "bold" }}
                            >
                                <span style={{ fontWeight: "bold", fontSize: '20px' }}>{total}</span>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="e3"
                                label="E3库存:"
                                style={{ fontWeight: "bold" }}
                            >
                                <span style={{ fontWeight: "bold", fontSize: '20px' }}>{e3kc}</span>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            {/* 导入按钮区域 */}
            <div style={{ marginBottom: size.height * 0.02 }}>
                <Button style={{ marginLeft: '10px', marginRight: '50px', display: status != '待采购' ? 'none': '' }} type="primary" onClick={()=>clickOpen()}>添加缺货订单</Button>
                <Button style={{ marginLeft: '10px', marginRight: '50px' }} type="primary" onClick={() => props.history.push({ 'pathname': '/app/presell/purchase'})}>返回采购单预览</Button>
                <Button style={{ marginLeft: '10px', marginRight: '50px' }} type="primary" onClick={() => props.history.push({ 'pathname': '/app/presell/details', 'state': { 'infomation': purchaseNumber + ',' + status }})}>返回采购单SKU详情</Button>
            </div>
            <div style={{ marginTop: size.height * 0.02, marginRight: size.width * 0.06 }}>
                {/* 表单区域 */}
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={false}
                    scroll={{y: size.height*0.6  }}
                    //表格行 key 的取值
                    rowKey={'id'}
                    onRow={function (record, index) {
                        // console.log(index);
                        return (
                            {
                                style: {
                                    backgroundColor: index % 2 == 0 ? '#D3D3D3' : '#FFFFFF'
                                },
                            }
                        )
                    }}
                />
            </div>
            <Modal
                title="添加缺货订单"
                centered={true}
                visible={openStockout}
                onOk={affirmStockout}
                onCancel={closeStockout}
                okText="添加"
                cancelText="取消"
                width="1300px"
                okButtonProps = {{disabled : effective == 1 ? true : false}}
                cancelButtonProps = {{disabled : effective == 1 ? true : false}}
            >  
                <div style={{color:'red', fontWeight:'bold', fontSize:'20px'}}>{"还能添加" + addNum + "条"}</div>
                <Table
                    rowSelection={rowSelection}
                    //表格列的配置描述
                    columns={addColumns}
                    //数据数组
                    dataSource={addDate}
                    pagination={false}
                    scroll={{ y: 500 }}
                    //表格行 key 的取值
                    rowKey={'id'}
                    onRow={function (record, index) {
                        // console.log(index);
                        return (
                            {
                                style: {
                                    backgroundColor: index % 2 == 0 ? '#D3D3D3' : '#FFFFFF'
                                },
                            }
                        )
                    }}
                />
            </Modal>
        </div>
        
    )

}

export default SKUDetails