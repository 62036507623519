import React, { useState, useEffect, useContext } from 'react';
import { Typography, Transfer, Form, Button, Table, DatePicker, Modal, InputNumber, Input, Row, Tag, message, Col } from 'antd'
import NbAxios from "../../../config/utils/nbAxios"
import servicePath from '../../../config/apiUrl'
import cookie from 'react-cookies'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { StateContext } from '../../raceShop/store/stateShare'
import difference from 'lodash/difference'
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
// 招聘计划
function Index() {
      // 自定义表转移
      const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
        <Transfer {...restProps} showSelectAll={false}>
            {({
                direction,
                filteredItems,
                onItemSelectAll,
                onItemSelect,
                selectedKeys: listSelectedKeys,
                disabled: listDisabled,
            }) => {
                const columns = direction === 'left' ? leftColumns : rightColumns;

                const rowSelection = {
                    getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows
                            .filter(item => !item.disabled)
                            .map(({ key }) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, listSelectedKeys)
                            : difference(listSelectedKeys, treeSelectedKeys);
                        onItemSelectAll(diffKeys, selected);
                    },
                    onSelect({ key }, selected) {
                        onItemSelect(key, selected);
                    },
                    selectedRowKeys: listSelectedKeys,
                };

                return (
                    <Table
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        pagination={paginationProps}
                        style={{ pointerEvents: listDisabled ? 'none' : null }}
                        onRow={({ key, disabled: itemDisabled }) => ({
                            onClick: () => {
                                if (itemDisabled || listDisabled) return;
                                onItemSelect(key, !listSelectedKeys.includes(key));
                            },
                        })}
                    />
                );
            }}
        </Transfer>
    );
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
    };
    const [form] = Form.useForm()
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    // 获取屏幕尺寸
    const size = state.size
    // 数据
    const [date, setDate] = useState([])
    //页面
    const [page, setPage] = useState(1)
    // 总数
    const [total, setTotal] = useState(0)
    // 选中的id列表
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 选中的列表对象
    const [selectedRow, setSelectedRow] = useState([])
    // 职位是否修改
    const [ positionFlag , setPositionFlag ] = useState(false)
    // 职位数据
    const [ position , setPosition] = useState([])
    // 修改的职位数据
    const [ updatePosition , setUpdatePosition ] = useState([])
    // 计划职位
    const [ planPosition , setPlanPosition ] = useState([])
    // 招聘计划名
    const [planName, setPlanName] = useState('')
    // 招聘计划ID
    const [ planId , setPlanId ] = useState('')
    // 招聘开始时间
    const [planStartTime, setPlanStartTime] = useState(moment().format('YYYY-MM-DD'))
    // 招聘结束时间
    const [planEndTime, setPlanEndTime] = useState(moment().format('YYYY-MM-DD'))
    // 新增招聘计划
    const [addPlan, setAddPlan] = useState(false)
    // 查看招聘职位详情
    const [ lookPlan , setLookPlan ] = useState(false)
    // 修改招聘计划
    const [ updatePlan , setUpdatePlan ] = useState(false)
    // 选中的职位
    const [targetKeys, setTargetKeys] = useState([])
    // 修改选中的职位
    const [updateTargetKeys, setUpdateTargetKeys] = useState([])
    // 选中的职位数据
    const [ chooseList , setChooseList ] = useState([])
    // 表格标题
    const columns = [
        {
            title: '编号',
            align: 'center',
            dataIndex: 'id',
        },
        {
            title: '招聘计划',
            align: 'center',
            dataIndex: 'name',
        },
        {
            title: '时间范围',
            align: 'center',
            dataIndex: 'start_time',
            width:'220px',
            render: (text, record, index) => {
                return (
                    <span>{moment(date[index]['start_time']).format('YYYY-MM-DD') + '~' + moment(date[index]['end_time']).format('YYYY-MM-DD')}</span>
                )
            }
        },
        {
            title: '创建时间',
            align: 'center',
            dataIndex: 'create_time',
            width:'220px',
            render: (text, record, index) => (
                moment(text).format('YYYY-MM-DD HH:mm:ss')
            )
        },
        {
            title: '创建人',
            align: 'center',
            dataIndex: 'founder_name',
        },
        {
            title: '发布状态',
            align: 'center',
            dataIndex: 'release_status',
            render: (text, record, index) => {
                return (
                    text == 0 ? <Tag color="red">未发布</Tag>
                    : <Tag color="green">已发布</Tag>
                )
            }
        },
        {
            title: '招聘职位',
            align: 'center',
            dataIndex: 'id',
            render: (text, record, index) => {
                return (
                   <a style={{textDecoration:'underline'}} onClick={()=>getRecruitProgrammePositionRelation(text, date[index]['name'] )}>查看详情</a>
                )
            }
        },
        {
            title: '操作',
            align: 'center',
            dataIndex: 'id',
            render: (text, record, index) => {
                return (
                    <a style={{textDecoration:'underline'}} onClick={()=>getUpdateRecruitPositionList(text, date[index]['name'], date[index]['start_time'],date[index]['end_time'])}>修改</a>
                )
            }
        }
    ]

    // 左边表格标题
    const leftTableColumns = [
        {
            title: '未选择职位',
            align: 'center',
            children: [
                {
                    dataIndex: 'name',
                    title: '职位名称',
                    align: 'center',
                    width: '150px',
                }
            ]
        }
    ];

     // 右边表格标题
     const rightTableColumns = [
        {
            title: '已选择职位',
            align: 'center',
            children: [
                {
                    dataIndex: 'name',
                    title: '职位名称',
                    align: 'center',
                    width: '150px',
                },
                {
                    dataIndex: 'number',
                    title: '数量',
                    align: 'center',
                    width: '100px',
                    render: (text, record, index) => (
                        <InputNumber min={1} max={10} defaultValue={text} onChange={(value)=>{
                            let chooseList = []
                            targetKeys.map(item=>{
                                chooseList.push(position[position.map(item=>item.key).indexOf(item)])
                            })
                            chooseList[index]['number'] = value
                            setChooseList(chooseList);
                        }} />
                    ),
                }
            ]
        }
    ]

     // 右边表格标题
     const updateRightTableColumns = [
        {
            title: '已选择职位',
            align: 'center',
            children: [
                {
                    dataIndex: 'name',
                    title: '职位名称',
                    align: 'center',
                    width: '150px',
                },
                {
                    dataIndex: 'number',
                    title: '数量',
                    align: 'center',
                    width: '100px',
                    render: (text, record, index) => (
                        <InputNumber min={1} max={10} defaultValue={text} onChange={(value)=>{
                            let chooseList = []
                            updateTargetKeys.map(item=>{
                                chooseList.push(updatePosition[updatePosition.map(item=>item.key).indexOf(item)])
                            })
                            chooseList[index]['number'] = value
                            setChooseList(chooseList);
                        }} />
                    ),
                }
            ]
        }
    ]

    // 计划职位列表表头
    const planPositionColumns = [
        {
            dataIndex: 'position_name',
            title: '职位名称',
            align: 'center',
            width: '150px',
        },
        {
            dataIndex: 'number',
            title: '人数',
            align: 'center',
            width: '150px',
        },
    ];

    // // 初始化参数
    // useEffect(() => {
    //     getRecruitPosition()
    // }, [])

    // 获取招聘计划数据
    useEffect(()=>{
        getRecruitProgrammePosition()
    },[page])

    // 查看招聘计划职位
    const getRecruitProgrammePositionRelation = (id,name)=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "programme_id": id,
            "principal_uuid": cookie.load('id')
        }
        NbAxios(dataProps, "POST", servicePath.getRecruitProgrammePositionRelation).then(res => {
            if (res.success) {
                setPlanName(name)
                setPlanPosition(res.data)
                setLookPlan(true)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    //修改招聘计划查询职位列表
    const getUpdateRecruitPositionList = (id, name , startTime , endTime)=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "programme_id": id,
            "principal_uuid": cookie.load('id')
        }
        NbAxios(dataProps, "POST", servicePath.getUpdateRecruitPositionList).then(res => {
            if (res.success) {
                setPlanId(id)
                setPlanName(name)
                setPlanStartTime(moment(startTime).format('YYYY-MM-DD'))
                setPlanEndTime(moment(endTime).format('YYYY-MM-DD'))
                setTargetKeys(res.data[0]['position_id_list']);
                setUpdateTargetKeys(res.data[0]['position_id_list'])
                setUpdatePosition(res.data[0]['recruit_position_dict']);
                let chooseList = []
                res.data[0]['position_id_list'].map(item=>{
                    chooseList.push(res.data[0]['recruit_position_dict'][res.data[0]['recruit_position_dict'].map(item=>item.key).indexOf(item)])
                })
                setChooseList(chooseList);
                setUpdatePlan(true)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 查询职位
    const getRecruitPosition = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.getCreateRecruitPositionList).then(res => {
            if (res.success) {
                setPosition(res.data)
                setAddPlan(true)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    // 获取招聘计划数据
    const getRecruitProgrammePosition = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "principal_uuid": cookie.load('id'),
            "page": page,
            "page_size": 10
        }
        NbAxios(dataProps, "POST", servicePath.getRecruitProgrammePosition).then(res => {
            if (res.success) {
                setDate(res.data)
            } else {
                message.error(res.errorMsg)
            }
        })
    }

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    // 改变多选
    const onSelectChange = (selectedRowKeys, selectedRow) => {
        setSelectedRowKeys(selectedRowKeys);
    }
    // 多选设置
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    }
    //  确定新增招聘计划
    const addPlanOk = () => {
        let newList = []
        targetKeys.map(item=>{
            newList.push(position[position.map(item=>item.key).indexOf(item)])
        })
        let target = 1
        newList.map(item=>{
            if(item.number == null || item.number == 0){
                target = 0;
            }
        })
        if(target == 0){
            message.warn('请输入正确职位数量')
        }else{
            // 新增的集合
            if(planName == ''){
                message.warn('请输入招聘计划名')
            }else if(planStartTime == ''){
                message.warn('请输入招聘计划时间范围')
            }else{
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopId": cookie.load('shopList'),
                    "name":planName,
                    "start_time": planStartTime,
                    "end_time": planEndTime,
                    "dict_list": newList,
                    "principal_uuid": cookie.load('id')
                }
                NbAxios(dataProps, "POST", servicePath.createRecruitProgrammePositionRelation).then(res => {
                    if (res.success) {
                        message.success('新增招聘计划创建成功')
                        setAddPlan(false)
                        setPosition([])
                        setTargetKeys([])
                        setChooseList([])
                        setPlanName('')
                        setPlanStartTime(moment().format('YYYY-MM-DD'))
                        setPlanEndTime(moment().format('YYYY-MM-DD'))
                        getRecruitProgrammePosition()
                    } else {
                        message.error(res.errorMsg)
                    }
                })
            }
        }
    }
    // 取消新增招聘计划
    const addPlanCancel = () => {
        message.success('取消新增招聘计划')
        setPosition([])
        setTargetKeys([])
        setChooseList([])
        setPlanId('')
        setPlanName('')
        setPlanStartTime(moment().format('YYYY-MM-DD'))
        setPlanEndTime(moment().format('YYYY-MM-DD'))
        setAddPlan(false)
    }

    // 穿梭框数据改变
    const onTransferChange = (nextTargetKeys)=>{
        setTargetKeys(nextTargetKeys)
    }
    // 修改时，穿梭框数据修改
    const onUpdateTransferChange = (nextTargetKeys)=>{
        setUpdateTargetKeys(nextTargetKeys)
    }
    // 完成修改招聘计划
    const updatePlanOk = () => {
        let newList = []
        updateTargetKeys.map(item=>{
            newList.push(updatePosition[updatePosition.map(item=>item.key).indexOf(item)])
        })
        let target = 1
        newList.map(item=>{
            if(item.number == null || item.number == 0){
                target = 0;
            }
        })
        if(target == 0){
            message.warn('请输入正确职位数量')
        }else{
            // 更新的集合
            if(planName == ''){
                message.warn('请输入招聘计划名')
            }else if(planStartTime == ''){
                message.warn('请输入招聘计划时间范围')
            }else{
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopId": cookie.load('shopList'),
                    "programme_id": planId,
                    "name":planName,
                    "start_time": planStartTime,
                    "end_time": planEndTime,
                    "dict_list": newList,
                    "principal_uuid": cookie.load('id')
                }
                NbAxios(dataProps, "POST", servicePath.updateRecruitProgrammePositionRelation).then(res => {
                    if (res.success) {
                        message.success('修改招聘计划成功')
                        setUpdatePlan(false)
                        setUpdatePosition([])
                        setTargetKeys([])
                        setChooseList([])
                        setPlanId('')
                        setPlanName('')
                        setPlanStartTime(moment().format('YYYY-MM-DD'))
                        setPlanEndTime(moment().format('YYYY-MM-DD'))
                        getRecruitProgrammePosition()
                    } else {
                        message.error(res.errorMsg)
                    }
                })
            }
            
        }
        // setUpdatePlan(false)
    }
    // 取消修改招聘计划
    const updatePlanCancel = () => {
        message.success('取消修改招聘计划')
        setUpdatePosition([])
        setTargetKeys([])
        setPlanId('')
        setChooseList([])
        setPlanId('')
        setPlanName('')
        setPlanStartTime(moment().format('YYYY-MM-DD'))
        setPlanEndTime(moment().format('YYYY-MM-DD'))
        setUpdatePlan(false)
    }

    return (
        <div>
            <div style={{ marginLeft: '50px', marginTop: '30px' }}>
                <Button type="primary" onClick={() => getRecruitPosition()}>新增招聘计划</Button>
                <Button type="primary" style={{ marginLeft: "20px" }} onClick={() => {
                    if (selectedRowKeys.length == 0) {
                        message.warn('请选择发布的招聘计划')
                    } else {
                        let dataProps = {
                            "roleId": cookie.load('role'),
                            "shopId": cookie.load('shopList'),
                            "programme_id_list": selectedRowKeys,
                            "principal_uuid": cookie.load('id')
                        }
                        NbAxios(dataProps, "POST", servicePath.publishRecruitProgrammePosition).then(res => {
                            if (res.success) {
                                message.success('招聘计划发布成功')
                                setSelectedRowKeys([])
                                getRecruitProgrammePosition()
                            } else {
                                message.error(res.errorMsg)
                            }
                        })
                    }
                }}>发布</Button>
                <Button type="primary" style={{ marginLeft: "20px" }} danger onClick={() => {
                    if (selectedRowKeys.length == 0) {
                        message.warn('请选择取消发布的招聘计划')
                    } else {
                        let dataProps = {
                            "roleId": cookie.load('role'),
                            "shopId": cookie.load('shopList'),
                            "programme_id_list": selectedRowKeys,
                            "principal_uuid": cookie.load('id')
                        }
                        NbAxios(dataProps, "POST", servicePath.cancelRecruitProgrammePosition).then(res => {
                            if (res.success) {
                                message.success('招聘计划取消发布成功')
                                setSelectedRowKeys([])
                                getRecruitProgrammePosition()
                            } else {
                                message.error(res.errorMsg)
                            }
                        })
                    }
                }}>取消发布</Button>
            </div>
            <div style={{ marginLeft: '50px', marginTop: '30px', marginRight: '50px' }}>
                <Table
                    // 选择框设置
                    rowSelection={rowSelection}
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    // 分页处理
                    pagination={paginationProps}
                    //表格行 key 的取值
                    rowKey={'id'}
                    scroll={{ x: '70vw', y: `${size.width > 1500 ? '75vh' : '69vh'}` }}
                />
            </div>
            <Modal
                title="新增招聘计划"
                visible={addPlan}
                onCancel={() => addPlanCancel()}
                onOk={() => addPlanOk()}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={1000}
            >
                <Form
                    {...layout}
                    form={form}
                    labelAlign="left"
                >
                    <Form.Item
                        label="招聘计划名"
                        name="planName"
                        extra="输入格式(年+计划名)"
                        rules={[
                            { required: true, message: '请填写你的招聘计划名!' },
                            {
                                //不能输入非汉字效验  效验不能输入非空字符串
                                validator: (rule, value, callback) => {
                                    let reg = new RegExp("[`~!%@#$^&*()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）|{}【】‘；：”“'\"。，、？]");
                                    if (reg.test(value)) {
                                        return callback("请输入正确的招聘计划名")
                                    } else {
                                        return callback();
                                    }
                                }
                            }
                        ]}
                    >
                        <div>
                            <Input value={planName} placeholder="请填写招聘计划名" onChange={(e) => { setPlanName(e.target.value) }} />
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="招聘计划时间"
                        name="plan_time"
                        rules={[{ required: true, message: '请选择你的招聘计划时间!' }]}
                    >
                        <div>
                            <RangePicker locale={locale} style={{ width: '600px' }}
                                onChange={(date, dateString) => {
                                    setPlanStartTime(date[0].format('YYYY-MM-DD'));
                                    setPlanEndTime(date[1].format('YYYY-MM-DD'));
                                }} />
                        </div>
                    </Form.Item>
                    {/* <Form.Item
                        label="职位选择"
                        name="plan_time"
                        
                    >
                       
                    </Form.Item> */}
                </Form>
                <div style={{marginBottom:'10px'}}><Text strong>职位选择:</Text></div>
                <TableTransfer
                            locale={{ itemUnit: '位', itemsUnit: '位', searchPlaceholder: '请输入搜索职位名称' }}
                            dataSource={position}
                            targetKeys={targetKeys}
                            showSearch={true}
                            onChange={onTransferChange}
                            filterOption={(inputValue, item) =>
                                item.name.indexOf(inputValue) !== -1
                            }
                            leftColumns={leftTableColumns}
                            rightColumns={rightTableColumns}
                        />
            </Modal>
            {/* 查看招聘职位详情 */}
            <Modal
                title="招聘职位详情"
                visible={lookPlan}
                onCancel={() => {setLookPlan(false); setPlanName('')}}
                footer={[]}
                centered={true}
                width={500}
            >
                <Row>
                    <Col><Title level={5}>招聘计划:</Title></Col>
                    <Col><Text type="secondary" style={{marginLeft:'10px', marginTop:'2px'}}>{planName}</Text></Col>
                </Row>
                <Table
                    //表格列的配置描述
                    columns={planPositionColumns}
                    //数据数组
                    dataSource={planPosition}
                    pagination={false}
                    //表格行 key 的取值
                    // scroll={{ x: '70vw', y: `${size.width > 1500 ? '75vh' : '69vh'}` }}
                    //  边框
                    bordered={true}
                />
            </Modal>
            {/* 修改招聘计划 */}
            <Modal
                title="修改招聘计划"
                visible={updatePlan}
                onCancel={() => updatePlanCancel()}
                onOk={() => updatePlanOk()}
                centered={true}
                cancelText="取消"
                okText="确认"
                width={1000}
            >
                <Form
                    {...layout}
                    form={form}
                    labelAlign="left"
                >
                    <Form.Item
                        label="招聘计划名"
                        name="planName"
                    >
                        <div>
                            <Text type="secondary">{planName}</Text>
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="招聘计划时间"
                        name="plan_time"
                        rules={[{ required: true, message: '请选择你的招聘计划时间!' }]}
                    >
                        <div>
                            <RangePicker
                             value={[moment(planStartTime, 'YYYY-MM-DD'), moment(planEndTime, 'YYYY-MM-DD')]}
                             locale={locale} style={{ width: '600px' }}
                                onChange={(date, dateString) => {
                                    setPlanStartTime(date[0].format('YYYY-MM-DD'));
                                    setPlanEndTime(date[1].format('YYYY-MM-DD'));
                                }} />
                        </div>
                    </Form.Item>
                    {/* <Form.Item
                        label="职位选择"
                        name="plan_time"
                        
                    >
                       
                    </Form.Item> */}
                </Form>
                <div style={{marginBottom:'10px'}}><Text strong>职位选择:</Text></div>
                <TableTransfer
                    locale={{ itemUnit: '位', itemsUnit: '位', searchPlaceholder: '请输入搜索职位名称' }}
                    dataSource={updatePosition}
                    targetKeys={updateTargetKeys}
                    showSearch={true}
                    onChange={onUpdateTransferChange}
                    filterOption={(inputValue, item) =>
                        item.name.indexOf(inputValue) !== -1
                    }
                    leftColumns={leftTableColumns}
                    rightColumns={updateRightTableColumns}
                />
            </Modal>
        </div>
    )
}


export default Index