import React, {useEffect, useState} from 'react';
import {Table, Form, Button, Typography, Row, Input, Select, Col, Space, Popconfirm, Modal, message, Drawer} from 'antd'
import {DownOutlined, RightOutlined, MinusCircleOutlined, PlusOutlined, InfoCircleOutlined} from '@ant-design/icons';
import cookie from 'react-cookies'
import Base64 from 'base-64'
import NbAxios from '../../config/utils/nbAxios'
import servicePath from '../../config/apiUrl'

const {Title, Text} = Typography
const {Option} = Select;
const {TextArea} = Input

// 操作模型管理页面
function Index(props) {

    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv':"3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein':"444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an':"7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr':"f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11':'d4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass':'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }

    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 16},
    };

    // 打开的key
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    // 页数
    const [page, setPage] = useState(1)
    // 总数
    const [total, setTotal] = useState(0)
    // 数据
    const [data, setData] = useState([])
    // 搜索业务分类
    const [searchTypeBusiness, setSearchTypeBusiness] = useState('')
    // 搜索操作模型名称
    const [searchOperationName, setSearchOperationName] = useState('')
    // 操作id
    const [operationId, setOperationId] = useState('')
    // 业务分类
    const [typeBusiness, setTypeBusiness] = useState('')
    // 操作名称
    const [operationName, setOperationName] = useState('')
    // 操作名称集合
    const [operationNameList, setOperationNameList] = useState([])
    // 业务分类列表
    const [typeBusinessList, setTypeBusinessList] = useState([])
    // 添加操作模型
    const [addModel, setAddModel] = useState(false)
    // 修改操作模型
    const [updateModel, setUpdateModel] = useState(false)
    // 禁用操作模型
    const [deleteModel, setDeleteModel] = useState(false)
    // 查询操作模型
    const [selectModel, setSelectModel] = useState(false)
    let columns = [
        {
            title: '操作名字',
            dataIndex: 'operation_name',
            key: 'operation_name',
            align: 'center',
        },
        {
            title: '业务分类',
            dataIndex: 'type_business',
            key: 'type_business',
            align: 'center',
        },
        {
            title: '人员分类',
            dataIndex: 'type_people',
            key: 'type_people',
            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            align: 'center',
            render: (text, record, index) => {
                return <span>
                     <a onClick={() => {

                         form.resetFields()
                         form1.resetFields()
                         setOperationId(record.id)
                         setOperationName(record.operation_name)
                         setTypeBusiness(record.type_business)
                         // 设置表单的值
                         let operationParametersList = []

                         if (record.operation_parameters[0] != '') {

                             record.operation_parameters.map(item => {
                                 operationParametersList.push({'operation': item})
                             })
                             record.operation_list = operationParametersList
                         } else {
                             record.operation_list = []
                         }
                         form.setFieldsValue(record)
                         setUpdateModel(true)
                     }}>修改</a> /  <a onClick={() => {
                    setOperationId(record.id)
                    setOperationName(record.operation_name)
                    setDeleteModel(true)
                }}>禁用</a>
                    {/* <Popconfirm
                        placement="rightTop"
                        title="确认禁用"
                        onConfirm={()=>{
                            let dataProps = {}
                            dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                            dataProps.roleId = cookie.load('role')
                            dataProps.id = record.id
                            NbAxios(dataProps, "POST", servicePath.deleteOperationModel).then(res=>{
                                if (res.success) {
                                    message.success("操作模型禁用成功")
                                    getOtmOperationModel()
                                } else {
                                    message.warn(res.errorMsg)
                                }
                            })
                        }}
                        onCancel={()=>{}}
                        okText="确定"
                        cancelText="取消"
                    >
                     <a>禁用</a>
                 </Popconfirm> */}
                </span>
            }
        }
    ]

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }


    useEffect(() => {
        getTypeBusinessList()
    }, [])

    useEffect(() => {

        if (typeBusiness != '') {
            getOtmOperationNameList()
        }
    }, [typeBusiness])

    useEffect(() => {
        getOtmOperationModel()
    }, [page])

    //查询操作模型接口
    const getOtmOperationModel = () => {
        let dataProps = {}
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.shopList = cookie.load('shopList')
        dataProps.roleId = cookie.load('role')
        dataProps.type_business = searchTypeBusiness
        dataProps.operation_name = searchOperationName
        dataProps.page = page
        dataProps.page_size = 10
        NbAxios(dataProps, "POST", servicePath.queryOtmOperationModel).then(res => {
            if (res.success) {
                setTotal(res.total);
                setData(res.data);
                setSelectModel(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询业务分类列表接口
    const getTypeBusinessList = () => {
        let dataProps = {}
        dataProps.shopList = cookie.load('shopList')
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.roleId = cookie.load('role')
        NbAxios(dataProps, "POST", servicePath.queryTypeBusinessList).then(res => {
            if (res.success) {
                setTypeBusinessList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询操作名称
    const getOtmOperationNameList = () => {
        let dataProps = {}
        dataProps.shopList = cookie.load('shopList')
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.roleId = cookie.load('role')
        dataProps.type_business = typeBusiness
        NbAxios(dataProps, "POST", servicePath.queryOtmOperationNameList).then(res => {
            if (res.success) {
                setOperationNameList(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    const addModelFinish = values => {
        if (operationName == '') {
            message.warn('请先选择操作名字')
        } else {
            let operation_list = []
            let flag = 0
            if (values.operation_parameters != undefined) {
                values.operation_parameters.map(item => {
                    if (item['operation'] != undefined) {
                        operation_list.push(item['operation'])
                    } else {
                        flag = 1
                    }
                })
            }
            if (flag == 0) {
                values.operation_parameters = operation_list
                values.shopList = cookie.load('shopList')
                values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                values.roleId = cookie.load('role')
                values.creator = cookie.load('name')
                values.creator_id = cookie.load('id')
                values.creator_username = Base64.decode(cookie.load('email'))
                values.pre_parameters = ''
                values.operation_name = operationName
                NbAxios(values, "POST", servicePath.addOtmOperationModel).then(res => {
                    if (res.success) {
                        message.success("操作模型创建成功")
                        form.resetFields()
                        form1.resetFields()
                        getOtmOperationModel()
                        setAddModel(false)
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            } else {
                message.warn('请正确输入操作参数名');
            }
        }


    }

    const updateModelFinish = values => {
        if (operationName == '') {
            message.warn('请先选择操作名字')
        } else {
            let operation_list = []
            let flag = 0
            if (values.operation_list != undefined) {
                values.operation_list.map(item => {
                    if (item['operation'] != undefined) {
                        operation_list.push(item['operation'])
                    } else {
                        flag = 1
                    }
                })
            }
            if (flag == 0) {
                values.operation_parameters = operation_list
                values.shopList = cookie.load('shopList')
                values.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                values.roleId = cookie.load('role')
                values.creator = cookie.load('name')
                values.creator_id = cookie.load('id')
                values.creator_username = Base64.decode(cookie.load('email'))
                values.pre_parameters = ''
                values.operation_name = operationName
                values.id = operationId
                NbAxios(values, "POST", servicePath.updateOtmOperationModel).then(res => {
                    if (res.success) {
                        message.success("操作模型修改成功")
                        setOperationId('')
                        setOperationName('')
                        setTypeBusiness('')
                        form.resetFields()
                        form1.resetFields()
                        getOtmOperationModel()
                        setUpdateModel(false)
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            } else {
                message.warn('请正确输入操作参数名');
            }
        }


    }

    // 重置
    const restart = () => {
        let dataProps = {}
        dataProps.shopList = cookie.load('shopList')
        dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
        dataProps.roleId = cookie.load('role')
        dataProps.type_business = ''
        dataProps.operation_name = ''
        dataProps.page = 1
        dataProps.page_size = 10
        NbAxios(dataProps, "POST", servicePath.queryOtmOperationModel).then(res => {
            if (res.success) {
                setSearchOperationName('')
                setSearchTypeBusiness('')
                form2.resetFields()
                setTotal(res.total);
                setData(res.data);
                setSelectModel(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div>
            <div style={{marginLeft: '50px', marginTop: "30px"}}>
                <Button type="primary" onClick={() => {
                    setOperationName('')
                    setTypeBusiness('')
                    form.resetFields()
                    form1.resetFields()
                    setAddModel(true)
                }} style={{marginRight: 10}}>新增操作模型</Button>
                <Button type="primary" onClick={() => setSelectModel(true)} style={{marginRight: 10}}>条件查询</Button>
                <Button type="dashed" onClick={() => {
                    props.history.push({'pathname': `/app/otm/${props.history.location.pathname.split('/')[3]}/eventModel`,})
                }}>事件模型管理</Button>
            </div>
            <div style={{marginLeft: '50px', marginTop: '30px', marginRight: '50px'}}>
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={data}
                    pagination={paginationProps}
                    //可展开设置
                    expandable={{
                        expandIcon: ({expanded, onExpand, record}) =>
                            expanded
                                ? (<DownOutlined onClick={e => onExpand(record, e)}/>)
                                : (<RightOutlined onClick={e => onExpand(record, e)}/>)
                        ,
                        expandedRowRender: record => (
                            <div>
                                <div style={{marginTop: "30px", display: 'flex'}}>
                                    <Title level={5} style={{
                                        marginRight: "10px",
                                        height: "10px",
                                        lineHeight: "10px"
                                    }}>操作描述:</Title>
                                    <Text type="secondary" style={{
                                        marginRight: '20px',
                                        height: "10px",
                                        lineHeight: "10px"
                                    }}>{record.operation_detail}</Text>
                                </div>
                                <div style={{marginTop: "30px", display: 'flex'}}>
                                    <Title level={5} style={{
                                        marginRight: "10px",
                                        height: "10px",
                                        lineHeight: "10px"
                                    }}>操作参数名:</Title>
                                    {
                                        record.operation_parameters.map(item => {
                                            return <Text type="secondary" style={{
                                                marginRight: '20px',
                                                height: "10px",
                                                lineHeight: "10px"
                                            }}>{item}</Text>
                                        })
                                    }
                                </div>
                            </div>
                        ),
                        expandedRowKeys: expandedRowKeys,
                        // 通过点击行来展开子行
                        // expandRowByClick: true,
                        onExpand: (expanded, record) => {
                            let temp = []
                            // 判断是否是同一行进行点击
                            if (expanded) {
                                temp.push(record.id)
                            }
                            setExpandedRowKeys(temp)
                        },
                    }}
                    rowKey={'id'}
                />
            </div>

            <Modal
                title="新增操作模型"
                centered="true"
                footer={null}
                onCancel={() => {
                    setOperationName('')
                    setTypeBusiness('')
                    form.resetFields()
                    form1.resetFields()
                    setAddModel(false)
                }}
                visible={addModel}
                width={600}
            >
                <Form {...layout} form={form} labelAlign='left' name="add_operation_model" onFinish={addModelFinish}
                      autoComplete="off">
                    <Form.Item label="业务分类" name="type_business"
                               rules={[{required: true, message: '请选择业务类型！'}]}>
                        <Select placeholder="请选择业务分类" onChange={(value) => {
                            // 输入框清空
                            form1.resetFields()
                            // 操作名字置空
                            setOperationName('')
                            setTypeBusiness(value)
                        }}>
                            {
                                typeBusinessList.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form {...layout} form={form1} labelAlign='left'>
                        <Form.Item label="操作名字" name="operation_name"
                                   rules={[{required: true, message: '请选择操作名称！'}]}>
                            <Select placeholder="请选择操作名称" showSearch defaultValue={operationName} value={operationName}
                                    onChange={(value) => setOperationName(value)}>
                                {
                                    operationNameList.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                    <Form.Item label="人员分类" name="type_people"
                               rules={[{required: true, message: '请选择人员分类！'}]}>
                        <Select placeholder="请选择人员分类">
                            <Option value="运营">运营</Option>
                            <Option value="商品">商品</Option>
                            <Option value="视觉">视觉</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="操作描述" name="operation_detail">
                        <TextArea placeholder="请输入操作描述" rows={4}/>
                    </Form.Item>
                    <Form.Item label="操作参数名"
                               tooltip={{title: '预设的操作参数名；例如: 1件9折；需要填写两个参数名: 件、折', icon: <InfoCircleOutlined/>}}>
                        <Form.List name="operation_parameters">
                            {(fields, {add, remove}) => (
                                <>
                                    <Row>
                                        {fields.map((field, index) => (
                                            <Col span={12}>
                                                <Space key={field.key} style={{display: 'flex', marginBottom: 8}}
                                                       align="baseline">
                                                    <Form.Item
                                                        {...field}
                                                        label={`参数名` + (index + 1)}
                                                        name={[field.name, 'operation']}
                                                        fieldKey={[field.fieldKey, 'operation']}
                                                    >
                                                        <Input/>
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                                </Space>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                            添加参数名
                                        </Button>
                                    </Form.Item>

                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginLeft: 400}}>
                            创建操作模型
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="修改操作模型"
                centered="true"
                footer={null}
                onCancel={() => {
                    setOperationId('')
                    setOperationName('')
                    setTypeBusiness('')
                    form.resetFields()
                    form1.resetFields()
                    setUpdateModel(false)
                }}
                visible={updateModel}
                width={600}
            >
                <Form {...layout} form={form} labelAlign='left' name="update_operation_model"
                      onFinish={updateModelFinish} autoComplete="off">
                    <Form.Item label="业务分类" name="type_business"
                               rules={[{required: true, message: '请选择业务类型！'}]}>
                        <Select placeholder="请选择业务分类" onChange={(value) => {
                            // 输入框清空
                            form1.resetFields()
                            // 操作名字置空
                            setOperationName('')
                            setTypeBusiness(value)
                        }}>
                            {
                                typeBusinessList.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form {...layout} form={form1} labelAlign='left'>
                        <Form.Item label="操作名字" name="operation_name"
                                   rules={[{required: true, message: '请选择操作名称！'}]}>
                            <Select placeholder="请选择操作名称" showSearch defaultValue={operationName} value={operationName}
                                    onChange={(value) => setOperationName(value)}>
                                {
                                    operationNameList.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                    <Form.Item label="人员分类" name="type_people"
                               rules={[{required: true, message: '请选择人员分类！'}]}>
                        <Select placeholder="请选择人员分类">
                            <Option value="运营">运营</Option>
                            <Option value="商品">商品</Option>
                            <Option value="视觉">视觉</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="操作描述" name="operation_detail">
                        <TextArea placeholder="请输入操作描述" rows={4}/>
                    </Form.Item>
                    <Form.Item label="操作参数名"
                               tooltip={{title: '预设的操作参数名；例如: 1件9折；需要填写两个参数名: 件、折', icon: <InfoCircleOutlined/>}}>
                        <Form.List name="operation_list">
                            {(fields, {add, remove}) => (
                                <>
                                    <Row>
                                        {fields.map((field, index) => (
                                            <Col span={12}>
                                                <Space key={field.key} style={{display: 'flex', marginBottom: 8}}
                                                       align="baseline">
                                                    <Form.Item
                                                        {...field}
                                                        label={`参数名` + (index + 1)}
                                                        name={[field.name, 'operation']}
                                                        fieldKey={[field.fieldKey, 'operation']}
                                                    >
                                                        <Input/>
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                                </Space>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                            添加参数名
                                        </Button>
                                    </Form.Item>

                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginLeft: 400}}>
                            修改操作模型
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            {/* 条件查询 */}
            <Drawer
                title="条件查询"
                placement="right"
                visible={selectModel}
                onClose={() => setSelectModel(false)}
                width={500}
            >
                <Form {...layout} form={form2} labelAlign="left">
                    <Form.Item label="业务分类" name="searchTypeBusiness">
                        <Select placeholder="请选择要查询的业务分类" value={searchTypeBusiness}
                                onChange={(value) => setSearchTypeBusiness(value)}>
                            {
                                typeBusinessList.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="操作名称" name="searchOperationName">
                        <Input value={searchOperationName} placeholder="请输入要查询的操作名称"
                               onChange={(e) => setSearchOperationName(e.target.value)}/>
                    </Form.Item>
                </Form>
                <div style={{display: 'flex'}}>
                    <Button type="primary" style={{marginRight: 10}} onClick={() => {
                        if (page != 1) {
                            setPage(1)
                        } else {
                            getOtmOperationModel()
                        }
                    }}>查询</Button>
                    <Button type="primary" danger onClick={() => {
                        setPage(1)
                        restart()
                    }}>重置</Button>
                </div>
            </Drawer>
            <Modal
                title="禁用操作模型"
                centered="true"
                onCancel={() => {
                    setOperationId('')
                    setOperationName('')
                    setDeleteModel(false)
                }}
                onOk={() => {
                    let dataProps = {}
                    dataProps.shopId = shopIdDict[props.history.location.pathname.split('/')[3]]
                    dataProps.shopList = cookie.load('shopList')
                    dataProps.roleId = cookie.load('role')
                    dataProps.id = operationId
                    NbAxios(dataProps, "POST", servicePath.deleteOperationModel).then(res => {
                        if (res.success) {
                            message.success("操作模型禁用成功")
                            getOtmOperationModel()
                            setOperationId('')
                            setOperationName('')
                            setDeleteModel(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}
                okText="确认"
                cancelText="取消"
                visible={deleteModel}
                width={600}
            >
                是否禁用 “ {<Text strong>{operationName}</Text>} ”操作模型
            </Modal>

        </div>
    )

}

export default Index