import React, { useContext, useState, useEffect } from 'react'
import { DatePicker, Tabs, Select, message} from 'antd'
import { StateContext } from '../../raceShop/store/stateShare'
import NbAxios from '../../../config/utils/nbAxios'
import axios from 'axios'
import servicePath from '../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import moment from 'moment'
import cookie from 'react-cookies'
const { TabPane } = Tabs;
const { Option } = Select

// 分销店铺数据汇总
function Index(params) {
    const dateFormat = 'YYYY-MM'
    // 获取状态管理中的值
    const { state, dispatch } = useContext(StateContext)
    // 月份
    const [ month , setMonth ] = useState(moment().format('YYYY-MM'))
    // 标签
    const [tabKey, setTabKey] = useState('1')
    // top10店铺
    const [ topShop , setTopShop ] = useState([])
    // top10金额
    const [ topAmount, setTopAmount ] = useState([])
    // 业务员
    const [ assignPerson , setAssignPerson ] = useState([])
    // 业务员的金额
    const [ assignPersonAmount, setAssignPersonAmount ] = useState([])
    // 业务人员的新开发店铺
    const [ assignPersonNew , setAssignPersonNew ] = useState([])
    // 业务人员的新开发店铺
    const [ assignPersonNewShop , setAssignPersonNewShop ] = useState([])
    // 可分配人集合
    const [ assignablePerson , setAssignablePerson] = useState([])
    // 业务员店铺月份
    const [ assignPersonShopMonth , setAssignPersonShopMonth] = useState([])
    // 标签
    const [ legend , setLegend ] = useState([])
    // 选择
    const [ selected , setSelect ] = useState({})
    // 数据
    const [ series , setSeries ] = useState([])
    // 人名
    const [ name , setName ] = useState('')
    // 店铺集合
    const [ shopList , setShopList] = useState([])
    // 店铺名
    const [ shopName , setShopName ] = useState('')
    // 店铺月份
    const [ shopMonth , setShopMonth ] = useState([])
    // 店铺标签
    const [ shopLegend , setShopLegend ] = useState([])
    // 店铺数据
    const [ shopSeries , setShopSeries ] = useState([])
    
    // 修改月份
    const changeMonth = (date, dateString)=>{
        console.log(dateString);
        setMonth(dateString)
    }


    // 表格
    let topReport = {
        title: {
            text: `Top10店铺`,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: ['采货金额']
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: topShop
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '金额/万元',
                position: 'left',
                axisLabel: {
                    formatter: function (data) {
                        return (data/10000);
                    }
                },
                axisLine: {
                    show: true,
                    onZero: true
                },
            }
        ],
        series: [
            {
                name: '金额',
                type: 'bar',
                barWidth: '30%',
                data: topAmount
            }
        ]
    }

    // 表格
    let assignPersonReport = {
        title: {
            text: `业务人采购金额`,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: ['采货金额']
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: assignPerson
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '金额/万元',
                position: 'left',
                axisLabel: {
                    formatter: function (data) {
                        return (data/10000);
                    }
                },
                axisLine: {
                    show: true,
                    onZero: true
                },
            }
        ],
        series: [
            {
                name: '金额',
                type: 'bar',
                barWidth: '30%',
                data: assignPersonAmount
            }
        ]
    }

    // 表格
    let newShopReport = {
        title: {
            text: `新增店铺`,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: ['采货金额']
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: assignPersonNew
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '店铺个数/个',
                position: 'left',
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    show: true,
                    onZero: true
                },
            }
        ],
        series: [
            {
                name: '金额',
                type: 'bar',
                barWidth: '30%',
                data: assignPersonNewShop
            }
        ]
    }

    // 业务员的业绩图表
    let assignPersonShopReport = {
        title: {
            text: '业务员业绩'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: legend,
            selected: selected
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: false
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: assignPersonShopMonth
        },
        yAxis: {
            type: 'value',
            name: '金额/万元',
            position: 'left',
            axisLine: {
                show: true,
                onZero: true
            },
        },
        series:series
    }

    // 店铺业绩图表
    let shopReport = {
        title: {
            text: shopName + `采货金额`
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: shopLegend,
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: false
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: shopMonth
        },
        yAxis: {
            type: 'value',
            name: '金额/万元',
            position: 'left',
            axisLine: {
                show: true,
                onZero: true
            },
        },
        series:shopSeries
    }

    useEffect(()=>{
        let reportChart = echarts.init(document.getElementById('topReport'));
        // 绘制图表
        reportChart.setOption(topReport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[topAmount])

    useEffect(()=>{
        let reportChart = echarts.init(document.getElementById('assignPersonReport'));
        // 绘制图表
        reportChart.setOption(assignPersonReport);
        reportChart.off('click')
        // 点击事件
        // reportChart.on('click',function(params) {
        //     console.log(params);
        //     console.log(params['name']);
        // })
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[assignPersonAmount])

    useEffect(()=>{
        let reportChart = echarts.init(document.getElementById('newShopReport'));
        // 绘制图表
        reportChart.setOption(newShopReport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[assignPersonNewShop])

    useEffect(()=>{
        let reportChart = echarts.init(document.getElementById('assignPersonShopReport'));
        // 绘制图表
        reportChart.setOption(assignPersonShopReport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[series])

    useEffect(()=>{
        let reportChart = echarts.init(document.getElementById('shopReport'));
        // 绘制图表
        reportChart.setOption(shopReport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[shopSeries])

    useEffect(()=>{
        getTopAmountShop()
        getAssignPersonAmount()
        getAssignPersonNewShop()
    },[month])

    useEffect(()=>{
        getAssignPersonnel()
        getPurchasedShop()
    },[])

    useEffect(()=>{
        getAssignPersonShopAmount()
    },[name])

    useEffect(()=>{
        getPurchasedShopAmount()
    },[shopName])

    // 获取top10店铺数据
    const getTopAmountShop = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "month": month
        }
        NbAxios(dataProps, "POST", servicePath.queryTopAmountShop).then(res => {
            if(res.success){
                setTopShop(res.data.shop);
                setTopAmount(res.data.total);
            }else{
                message.error(res.errorMsg)
            }
        })
    }

    // 获取业务员的金额
    const getAssignPersonAmount =  ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "month": month
        }
        NbAxios(dataProps, "POST", servicePath.queryAssignPersonAmount).then(res => {
            if(res.success){
                setAssignPerson(res.data.people);
                setAssignPersonAmount(res.data.total);
            }else{
                message.error(res.errorMsg)
            }
        })
    }

    // 获取业务人员的新开发店铺
    const getAssignPersonNewShop = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "month": month
        }
        NbAxios(dataProps, "POST", servicePath.queryAssignPersonNewShop).then(res => {
            if(res.success){
                setAssignPersonNew(res.data.people);
                setAssignPersonNewShop(res.data.total);
            }else{
                message.error(res.errorMsg)
            }
        })
    }

    // 获取可分配人员
    const getAssignPersonnel = ()=>{
        let dataProps={
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryAssignPersonnel).then(res => {
            if(res.success){
                setAssignablePerson(res.data);
            }else{
                message.error(res.errorMsg)
            }
        })
    }

    // 获取业务人员的每个店铺每个月业绩数据
    const getAssignPersonShopAmount = ()=>{
        let dataProps={
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "name":name
        }
        NbAxios(dataProps, "POST", servicePath.queryAssignPersonShopAmount).then(res => {
            if(res.success){
                setAssignPersonShopMonth(res.data.month);
                setLegend(res.data.legend)
                setSelect(res.data.selected)
                setSeries(res.data.series)
            }else{
                message.error(res.errorMsg)
            }
        })
    }

    // 获取采购过的所有店铺
    const getPurchasedShop = ()=>{
        let dataProps={
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        NbAxios(dataProps, "POST", servicePath.queryPurchasedShop).then(res => {
            if(res.success){
                setShopList(res.data);
            }else{
                message.error(res.errorMsg)
            }
        })
    }

    // 获取店铺的采购数据
    const getPurchasedShopAmount = ()=>{
        let dataProps={
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "name": shopName
        }
        NbAxios(dataProps, "POST", servicePath.queryPurchasedShopAmount).then(res => {
            if(res.success){
                setShopMonth(res.data.month);
                setShopLegend(res.data.legend)
                setShopSeries(res.data.series)
            }else{
                message.error(res.errorMsg)
            }
        })
    }

    return (
        <div>
            <div style={{marginLeft:"100px", marginTop:"20px"}}>
                <DatePicker value={moment(month, dateFormat)} onChange={changeMonth} picker="month" locale={locale} style={{display:tabKey < 4 ? '': 'none'}} />
                <Select style={{display:tabKey == 4 ? '': 'none', width:'200px'}} onChange={(value)=>setName(value)}>
                    {
                        assignablePerson.map(item=>{
                            return <Option value={item}>{item}</Option>
                        })
                    }
                </Select>
                <Select showSearch={true} style={{display:tabKey == 5 ? '': 'none', width:'200px'}} onChange={(value)=>setShopName(value)}>
                    {
                        shopList.map(item=>{
                            return <Option value={item}>{item}</Option>
                        })
                    }
                </Select>
            </div>
            <div style={{marginLeft:'100px', marginTop:"20px"}}>
                <Tabs type="card" style={{ height: 40 }} onChange={(key)=>setTabKey(key)}>
                    <TabPane tab={<div>TOP10店铺</div>} key={'1'}></TabPane>
                    <TabPane tab={<div>业务人员汇总</div>} key={'2'}></TabPane>
                    <TabPane tab={<div>新开发店铺</div>} key={'3'}></TabPane>
                    <TabPane tab={<div>业务人员店铺详情</div>} key={'4'}></TabPane>
                    <TabPane tab={<div>店铺详情</div>} key={'5'}></TabPane>
                </Tabs>
                <div id="topReport" style={{ width: '90vw', height: '70vh', marginTop: 100 , display:tabKey == 1 ? '': 'none'}}></div>
                <div id="assignPersonReport" style={{ width: '90vw', height: '70vh', marginTop: 100 , display:tabKey == 2 ? '': 'none'}}></div>
                <div id="newShopReport" style={{ width: '90vw', height: '70vh', marginTop: 100 , display:tabKey == 3 ? '': 'none'}}></div>
                <div id="assignPersonShopReport" style={{ width: '90vw', height: '70vh', marginTop: 100 , display:tabKey == 4 ? '': 'none'}}></div>
                <div id="shopReport" style={{ width: '90vw', height: '70vh', marginTop: 100 , display:tabKey == 5 ? '': 'none'}}></div>
            </div>
        </div>
    )
}

export default Index