import React, { useState, useEffect } from 'react';
import { Button, Radio, Table, Tag, DatePicker, message, Drawer, Form, Row, Col, Input, Modal, Typography, Select, Spin } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { CheckCircleTwoTone, EditOutlined } from '@ant-design/icons';
import moment from "moment"
import cookie from 'react-cookies'
import NbAxios from '../../../../../config/utils/nbAxios'
import servicePath from '../../../../../config/apiUrl'
import Base64 from 'base-64'
// import style from './index.module.css';
const {Text, Title} = Typography
const {Option} = Select
function Index() {
    const [ form ] = Form.useForm()
    const [ searchForm ] = Form.useForm()
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };
    // 页面
    const [ page , setPage ] = useState(1)
    // 总数
    const [ total , setTotal ] = useState(100)
    // 数据
    const [data, setData] = useState([])
    // id
    const [ id , setId ] = useState('')
    // 公司下拉框数据
    const [ companyList , setCompanyList ] = useState([])
    // 凭证字下拉框数据
    const [ voucherWordList , setVoucherWordList ] = useState([])
    // 摘要下拉框数据
    const [ abstractList , setAbstractList ] = useState([])
    // 科目全名下拉框数据
    const [ fullAccountNameList , setFullAccountNameList ] = useState([])
    // 过账下拉框数据
    const [ postingList , setPostingList] = useState([])
    // 项目下拉框数据
    const [ projectList , setProjectList ] = useState([])
    // 店铺下拉框数据
    const [ shopList , setShopList ] = useState([])
    // 管理报表科目下拉框数据
    const [ managementList , setManagementList ] = useState([])
    // 事业部下拉框数据
    const [ divisionList , setDivisionList ] = useState([])
    // 业务板块下拉框数据
    const [ businessList  , setBusinessList ] = useState([])
    // 费用类别下拉框数据
    const [ expenseCategoryList , setExpenseCategoryList] = useState([])
    // 集团下拉框数据
    const [ groupList , setGroupList] = useState([])
    // 公司简称下拉框数据
    const [ companyAbbreviationList , setCompanyAbbreviationList] = useState([])
    // 选择的key
    const [ selectedRowKeys , setSelectedRowKeys ] = useState([])
    // 是否是搜索状态
    const [ isSearchStatus , setIsSearchStatus ] = useState(0)
    // 条件查询
    const [isSearch, setIsSearch] = useState(false)
    // 修改
    const [isUpdate, setIsUpdate] = useState(false)
    // 表格表头
    const columns = [
        {
            title: '账簿',
            dataIndex: 'company',
            key: 'company',
            align: 'center',
            width: 140,
            fixed: 'left'
        },
        {
            title: '年份',
            dataIndex: 'year',
            key: 'year',
            align: 'center',
            width: 68,
            fixed: 'left'
        },
        {
            title: '期间',
            dataIndex: 'period',
            key: 'period',
            align: 'center',
            width: 60,
            fixed: 'left'
        },
        {
            title: '凭证字',
            dataIndex: 'voucher_word',
            key: 'voucher_word',
            align: 'center',
            width: 75,
        },
        {
            title: '摘要',
            dataIndex: 'abstract',
            key: 'abstract',
            align: 'center',
            width: 150,
        },
        {
            title: '科目全名',
            dataIndex: ' full_account_name',
            key: ' full_account_name',
            align: 'center',
            width: 140,
        },
        {
            title: '借方金额',
            dataIndex: 'debit_amount',
            key: 'debit_amount',
            align: 'center',
            width: 100,
            render: (text, record, index) => (
                text == "nan" ? <span>{''}</span> : <span>{text}</span>
            ),
        },
        {
            title: '贷方金额',
            dataIndex: 'credit_amount',
            key: 'credit_amount',
            align: 'center',
            width: 100,
            render: (text, record, index) => (
                text == "nan" ? <span>{''}</span> : <span>{text}</span>
            ),
        },
        {
            title: '过账',
            dataIndex: 'Posting',
            key: 'Posting',
            align: 'center',
            width: 80,
        },
        {
            title: '项目',
            dataIndex: 'accounting_dimension_item_name',
            key: 'accounting_dimension_item_name',
            align: 'center',
            width: 120,
        },
        {
            title: '店铺',
            dataIndex: 'accounting_dimension_shop_name ',
            key: 'accounting_dimension_shop_name ',
            align: 'center',
            width: 120,
        },
        {
            title: '科目全名改',
            dataIndex: 'account_full_name_change',
            key: 'account_full_name_change',
            align: 'center',
            width: 140,
        },
        {
            title: '管理报表科目',
            dataIndex: 'management_report_account',
            key: 'management_report_account',
            align: 'center',
            width: 150,
        },
        {
            title: '项目（重分）',
            dataIndex: 'project',
            key: 'project',
            align: 'center',
            width: 120,
        },
        {
            title: '店铺（重分）',
            dataIndex: 'shop',
            key: 'shop',
            align: 'center',
            width: 120,
        },
        {
            title: `事业部(成本中心维度）`,
            dataIndex: 'division',
            key: 'division',
            align: 'center',
            width: 180,
        },
        {
            title: '业务板块',
            dataIndex: 'business_segment',
            key: 'business_segment',
            align: 'center',
            width: 100,
        },
        {
            title: '费用类别',
            dataIndex: 'expense_category',
            key: 'expense_category',
            align: 'center',
            width: 100,
        },
        
        {
            title: '项目调整',
            dataIndex: 'project_adjustment',
            key: 'project_adjustment',
            align: 'center',
            width: 90,
            render: (text, record, index) => (
                text == "1" ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <span></span>
            ),
        },
        {
            title: '店铺调整',
            dataIndex: 'shop_adjustment',
            key: 'shop_adjustment',
            align: 'center',
            width: 90,
            render: (text, record, index) => (
                text == "1" ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <span></span>
            ),
        },
        {
            title: '科目调整',
            dataIndex: 'account_adjustment',
            key: 'account_adjustment',
            align: 'center',
            width: 90,
            render: (text, record, index) => (
                text == "1" ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <span></span>
            ),
        },
        {
            title: '所属集团',
            dataIndex: 'group',
            key: 'group',
            align: 'center',
            width: 100,
        },
        {
            title: '公司简称',
            dataIndex: 'company_abbreviation',
            key: 'company_abbreviation',
            align: 'center',
            width: 100,
        },
        {
            title: '是否有效',
            dataIndex: 'effective',
            key: 'effective',
            align: 'center',
            width: 100,
            fixed: 'right',
            render: (text, record, index) => (
                text == "0" ? <Tag color="red">无效</Tag> :
                        <Tag color="green">有效</Tag>
            ),
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 80,
            fixed: 'right',
            render: (text, record, index) => (
                text == "未确认" ? <Tag>未确认</Tag> :
                    text == "争议" ? <Tag color="red">争议</Tag> :
                        <Tag color="green">确认</Tag>
            ),
        },
        {
            title: '编辑',
            dataIndex: 'year',
            key: 'year',
            align: 'center',
            width: 100,
            fixed: 'right',
            render: (text, record, index) => (
                <EditOutlined onClick={()=>{
                    setId(record.id)
                    form.setFieldsValue(record)
                    setIsUpdate(true)
                }} />
            ),
        }
    ]

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    // 查询总表数据
    useEffect(()=>{
        getAllData()
    },[page])

    // 获取总账表多条件查询下拉框数据接口
    useEffect(()=>{
        
        const getData = async () => {
            await Promise.all([
                getCompanyData(),
                getVoucherWord(),
                getAbstract(),
                getFullAccountName(),
                getPosting(),
                getProject(),
                getShop(),
                getMenagement(),
                getDivision(),
                getBusiness(),
                getExpenseCategory(),
                getGroup(),
                getCompanyAbbreviation()])
        }
        getData()
        
    },[])

    // 查询总表数据
    const search = ()=>{
        let values = searchForm.getFieldValue()
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        values.page = page
        values.pageSize = 10
        values.status = '争议'
        values.is_dispute = 1
        NbAxios(values, "POST", servicePath.queryFinancialSummaryTable).then(res => {
            if (res.success) {
                setIsSearchStatus(1)
                setTotal(res.total);
                setData(res.data);
                setIsSearch(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取总表数据接口
    const getAllData = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "page":page,
            "pageSize":10,
            'status':'争议',
            "is_dispute":1
        }
        NbAxios(dataProps, "POST", servicePath.queryFinancialSummaryTable).then(res => {
            if (res.success) {
                setIsSearchStatus(0)
                setTotal(res.total);
                setData(res.data);
                setIsSearch(false)
                
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取公司下拉框数据
    const getCompanyData = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"company"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setCompanyList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取凭证字下拉框数据
    const getVoucherWord = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"voucher_word"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setVoucherWordList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取摘要下拉框数据
    const getAbstract = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"abstract"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setAbstractList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
     // 获取科目全名下拉框数据
    const getFullAccountName = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"full_account_name"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setFullAccountNameList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 获取过账下拉框数据
    const getPosting = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"Posting"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setPostingList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取项目下拉框数据
    const getProject = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"accounting_dimension_item_name"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setProjectList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取店铺下拉框数据
    const getShop = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"accounting_dimension_item_name"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setShopList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 获取管理报表科目下拉框数据
    const getMenagement = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"management_report_account"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setManagementList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取事业部下拉框数据
    const getDivision = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"division"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setDivisionList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取板块下拉框数据
    const getBusiness = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"business_segment"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setBusinessList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取费用类别下拉框数据
    const getExpenseCategory = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"expense_category"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setExpenseCategoryList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取集团下拉框数据
    const getGroup = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"group"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setGroupList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 获取公司简称下拉框数据
    const getCompanyAbbreviation = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"company_abbreviation"
        }
        NbAxios(dataProps, "POST", servicePath.findSummaryTableField).then(res => {
            if (res.success) {
                setCompanyAbbreviationList(res.data[0]['field_value']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    const onSelectChange = selectedRowKeys =>{
        setSelectedRowKeys(selectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // 更新操作
    const updateData = (values)=>{
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        values.id = id
        NbAxios(values, "POST", servicePath.modifyFinancialSummaryTable).then(res => {
            if (res.success) {
               message.success('信息更新成功')
               setId('')
               setIsUpdate(false)
               if(isSearchStatus == 1){
                    search()
               }else{
                    getAllData()
               }
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 确认
    const confirm = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "field_name":"voucher_word",
            "idList": selectedRowKeys
        } 
        NbAxios(dataProps, "POST", servicePath.modifyFinancialSummaryTableList).then(res => {
            if (res.success) {
               message.success('信息更新成功')
               setSelectedRowKeys([])
               if(isSearchStatus == 1){
                    search()
               }else{
                    getAllData()
               }
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div>
            <div style={{width:'100vw', backgroundColor:'#fff', padding:10, paddingLeft:20}}>
                <Title level={2} style={{margin: 0}}>费用库-总账-争议表</Title>
            </div>
            {/* 按钮区域 */}
            <div style={{ display: 'flex', paddingTop: 10, width: '100vw', backgroundColor: "#fff" }}>
                <Button type="primary" disabled={selectedRowKeys.length == 0 ? true : false} style={{ marginLeft: 10 }} onClick={confirm}>确认</Button>
                <Button style={{ marginLeft: 10 }} onClick={() => setIsSearch(true)}>条件查询</Button>
            </div>
            {/* 表格区域 */}
            <div style={{ width: '100vw', backgroundColor: "#fff", paddingLeft:10, paddingRight:10 }}>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={paginationProps}
                    rowSelection={rowSelection}
                    scroll={{ x: 1500 }}
                    rowKey={'id'}
                />
            </div>
            {/* 条件查询 */}
            <Drawer
                title="条件查询"
                placement="right"
                closable={false}
                onClose={() => {
                    setIsSearch(false)
                }}
                visible={isSearch}
                width={1000}
            >
                <Form form={searchForm} {...layout} labelAlign='left' onFinish={search}>
                    <Row>
                        <Col span={11}>
                            <Form.Item label="账簿" name="company">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择账簿"
                                >
                                    {
                                        companyList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="凭证字" name="voucher_word">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择凭证字"
                                >
                                    {
                                        voucherWordList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="摘要" name="abstract">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择摘要"
                                >
                                    {
                                        abstractList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="科目全名" name="full_account_name">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择科目全名"
                                >
                                    {
                                        fullAccountNameList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="过账" name="Posting">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择过账"
                                >
                                    {
                                        postingList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="项目" name="accounting_dimension_item_name">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择项目"
                                >
                                    {
                                        projectList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="店铺" name="accounting_dimension_shop_name">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择店铺"
                                >
                                    {
                                        shopList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="管理报表科目" name="management_report_account">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择管理报表科目"
                                >
                                    {
                                        managementList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="事业部" name="division">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择事业部"
                                >
                                    {
                                        divisionList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="业务板块" name="business_segment">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择业务板块"
                                >
                                    {
                                        businessList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="费用类别" name="expense_category">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择费用类别"
                                >
                                    {
                                        expenseCategoryList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="所属集团" name="group">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择所属集团"
                                >
                                    {
                                        groupList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="公司简称" name="company_abbreviation">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="请选择公司简称"
                                >
                                    {
                                        companyAbbreviationList.map(item=>{
                                            return <Option key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ display: 'flex' }}>
                        <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>查询</Button>
                        <Button type="primary" danger onClick={()=>{
                            searchForm.resetFields()
                            if(page != 1){
                                setPage(1)
                            }else{
                                getAllData()
                            }
                        }}>重置</Button>
                    </div>
                </Form>
            </Drawer>

            <Modal
                title="编辑总账"
                centered="true"
                visible={isUpdate}
                onCancel={() => setIsUpdate(false)}
                footer={[]}
                width={1200}
            >
                <Form form={form} {...layout} labelAlign='left' onFinish={updateData}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="账簿">
                                <Text type="secondary">{form.getFieldValue('company')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="年份">
                                <Text type="secondary">{form.getFieldValue('year')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="期间">
                                <Text type="secondary">{form.getFieldValue('period')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="凭证字">
                                <Text type="secondary">{form.getFieldValue('voucher_word')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="摘要">
                                <Text type="secondary">{form.getFieldValue('abstract')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="科目全名">
                                <Text type="secondary">{form.getFieldValue('full_account_name')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="借方金额">
                                <Text type="secondary">{form.getFieldValue('debit_amount')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="贷方金额">
                                <Text type="secondary">{form.getFieldValue('credit_amount')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="过账">
                                <Text type="secondary">{form.getFieldValue('Posting')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="项目">
                                <Text type="secondary">{form.getFieldValue('accounting_dimension_item_name')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="店铺">
                                <Text type="secondary">{form.getFieldValue('accounting_dimension_shop_name')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="管理报表科目">
                            <   Text type="secondary">{form.getFieldValue('management_report_account')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="事业部">
                                <Text type="secondary">{form.getFieldValue('division')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="业务板块">
                                <Text type="secondary">{form.getFieldValue('business_segment')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="费用类别">
                                <Text type="secondary">{form.getFieldValue('expense_category')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="所属集团">
                                <Text type="secondary">{form.getFieldValue('group')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="公司简称">
                                <Text type="secondary">{form.getFieldValue('company_abbreviation')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="科目全名改" name="account_full_name_change">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="项目（重分）" name="project">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="店铺（重分）" name="shop">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="状态" name="status">
                                <Select>
                                    <Option value="未确认">未确认</Option>
                                    <Option value="争议">争议</Option>
                                    <Option value="确认">确认</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div>
                        <Button type="primary" htmlType="submit" style={{marginLeft:1000}}>
                            更新
                        </Button>
                    </div>
                </Form>
            </Modal>
        </div>
    )

}

export default Index