import React, { Component } from 'react';
import style from './skuAnaysis.module.css';
import { Empty } from 'antd'
function Top({ data = [], shop }) {
    const data1 = [
        {
            payNums: 2,
            skus: [
                {
                    sku: "1234",
                    img: "https://img.alicdn.com/imgextra/https://img.alicdn.com/imgextra/i3/2201410379958/O1CN01ARDTC32NQogW8HD5w_!!2201410379958.jpg_430x430q90.jpg"
                },
                {
                    sku: "1234",
                    img: "https://img.alicdn.com/imgextra/https://img.alicdn.com/imgextra/i3/2201410379958/O1CN01ARDTC32NQogW8HD5w_!!2201410379958.jpg_430x430q90.jpg"
                }
            ]
        }
    ]
    return (
        <div className={style.topIndex} >
            {
                data.length == 0 && <Empty className={style.noSkus} description={<span className={style.noText}>暂无数据</span>} ></Empty>
            }
            <ul className={style.topWapper} >
                {
                    data.length !== 0 && data.map((item, index) => {
                        return <li className={style.topRow} style={{ "--top": `TOP${index + 1}` }} >
                            <div className={style.topRowTitle}  >{`TOP${index + 1} 销量${item.payNums}`}</div>
                            {
                                item.skus.map(child => {
                                    return (
                                        <a href={`https://list.tmall.com/search_product.htm?q=${child.sku}`} target="_blank" className={style.goodItem} >
                                            <img
                                                data-sku={child.sku}
                                                data-id={child.id}
                                                // data-src={`https://pic.bmcsoft.cn/it/${shop}/${child.sku}.jpg`} 
                                                // data-src={`https://pic.bmcsoft.cn/it/${shop}/${shop == 'vgrass' ? child.id : child.sku}.jpg`}

                                            />
                                            <text className={style.imgText}>{child.sku}</text>
                                        </a>
                                    )
                                })
                            }
                        </li>
                    })
                }
            </ul>
        </div>
    )
}

export default Top