import React, { useState, useEffect } from 'react';
import { Typography, Tabs, Drawer, Input, Upload, Row, Col, Select, Button, message, Collapse, DatePicker, Table, Checkbox, Modal, Tooltip, Popover, Spin, Anchor } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined, InboxOutlined, CloseOutlined, createFromIconfontCN } from '@ant-design/icons';
import servicePath from '../../config/apiUrl';
import moment from 'moment'
import cookie from 'react-cookies'
import locale from 'antd/es/date-picker/locale/zh_CN';
import NbAxios from '../../config/utils/nbAxios';
import CommodityImage from './component/commodityImage'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import axios from 'axios';
import downExcel from '../../config/utils/downloadFile'
import style from './index.module.css'
import { DraggableArea } from 'react-draggable-tags';
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const { Option } = Select
const { Panel } = Collapse
const { TabPane } = Tabs;
const { Link } = Anchor;
// 文件上传组建
const { Dragger } = Upload;
const dateFormat = 'YYYY-MM-DD';

function Index(props) {

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }

    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'z11': 'd4867ead-6724-4e15-b941-77be33fb6b9f',
        'mesux': 'b67c4cef-3f68-4bae-9dd8-12400e5e99b1',
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc'
    }

    const IconFont = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_2817613_yizb8f2tkvi.js',
    });

    const [titleIndex, setTitleIndex] = useState(-1)
    const [rowIndex, setRowIndex] = useState(-1)
    const [chosedList, setChosedList] = useState([])
    const [chooseIndex, setChooseIndex] = useState([])
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
   
    // 对象是否一样
    Object.prototype.vequal = function(obj){
        var props1 = Object.getOwnPropertyNames(this);
        var props2 = Object.getOwnPropertyNames(obj);
        if (props1.length != props2.length) {
            return false;
        }
        for (var i = 0,max = props1.length; i < max; i++) {
            var propName = props1[i];
            if (this[propName] !== obj[propName]) {
                return false;
            }
        }
        return true;
    }
    // 唯一行
    Array.prototype.unique = function () {
        //必须先进行排序
        this.sort();
        var newArr = new Array();
        //放入第一个数组头数据。
        var temp = this[0];
        newArr.push(temp);
        for (var i = 1; i < this.length; i++) {
            if (temp != this[i]) { //判断数组中，是否拥有该值。
                temp = this[i]; //放置到数组里面。
                newArr.push(this[i]);
            }
        }
        return newArr;
    }
    //去重
    function listRemoveRepeat(c) {
        var result = [];
        for (var i = 0; i < c.length; i++) {
            var flag = true;
            var temp = c[i];
            for (var j = i + 1; j < c.length; j++) {
                if (temp === c[j]) {
                    flag = false;
                    break;
                }
            }
            if (flag) {
                result.push(temp);
            }
        }
        return result;
    }
    //交集
    function listIntersection(a, b) {
        var result = [];
        for (var i = 0; i < b.length; i++) {
            var temp = b[i];
            for (var j = 0; j < a.length; j++) {
                if (temp === a[j]) {
                    result.push(temp);
                    break;
                }
            }
        }
        return result;
    }
    //差集:所有属于a且不属于b的元素组成的集合
    function listDifference(a, b) {
        var clone = a.slice(0);
        for (var i = 0; i < b.length; i++) {
            var temp = b[i];
            for (var j = 0; j < clone.length; j++) {
                if (temp === clone[j]) {
                    clone.splice(j, 1);
                }
            }
        }
        return listRemoveRepeat(clone);
    }
    //并集
    function listUnion(a, b) {
        return listRemoveRepeat(a.concat(b));
    }
    // 数据加载
    const [isSpinning, setIsSpinning] = useState(false)
    // 设置页码变量
    const [page, setPage] = useState(1);
    // 设置总数变量
    const [total, setTotal] = useState();
    // Tab的键
    const [tabKey, setTabKey] = useState("指标选择")
    // Tab的键
    const [tabSituationKey, setTabSituationKey] = useState("商品基础信息")
    // 表格数据
    const [data, setData] = useState([])
    // 当前开始时间
    const [startTime, setStartTime] = useState(moment().subtract(1, 'M').format('YYYY-MM-DD'))
    // 当前开始时间
    const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DD'))
    // 指标选择
    const [searchItemDate, setSearchItemDate] = useState([
        {
            "situation": "商品基础信息",
            "date": []
        },
        {
            "situation": "生意参谋全量数据",
            "head": [
                "指标",
                "近7天",
                "近14天",
                "近30天",
                "日",
                "周",
                "月",
                "年",
                "产品全周期"
            ],
            "date": [
                {
                    "option": [
                        {
                            "id": "",
                            "name": "下单转换率",
                            "disable": 1
                        },
                        {
                            "id": 348,
                            "name": "下单转换率_近7天",
                            "disable": 1
                        },
                        {
                            "id": 393,
                            "name": "下单转换率_近14天",
                            "disable": 1
                        },
                        {
                            "id": 438,
                            "name": "下单转换率_近30天",
                            "disable": 1
                        },
                        {
                            "id": 483,
                            "name": "下单转换率_日",
                            "disable": 1
                        },
                        {
                            "id": 528,
                            "name": "下单转换率_周",
                            "disable": 1
                        },
                        {
                            "id": 573,
                            "name": "下单转换率_月",
                            "disable": 1
                        },
                        {
                            "id": 618,
                            "name": "下单转换率_年",
                            "disable": 1
                        },
                        {
                            "id": 663,
                            "name": "下单转换率_产品全周期",
                            "disable": 1
                        }
                    ]
                }
            ]
        }
    ])
    // 生意参谋指标
    const [allBusinessStaffDate, setAllBusinessStaffDate] = useState([
        {
            "option": [
                {
                    "id": "",
                    "name": "下单转换率",
                    "disable": 1
                },
                {
                    "id": 348,
                    "name": "下单转换率_近7天",
                    "disable": 1
                },
                {
                    "id": 393,
                    "name": "下单转换率_近14天",
                    "disable": 1
                },
                {
                    "id": 438,
                    "name": "下单转换率_近30天",
                    "disable": 1
                },
                {
                    "id": 483,
                    "name": "下单转换率_日",
                    "disable": 1
                },
                {
                    "id": 528,
                    "name": "下单转换率_周",
                    "disable": 1
                },
                {
                    "id": 573,
                    "name": "下单转换率_月",
                    "disable": 1
                },
                {
                    "id": 618,
                    "name": "下单转换率_年",
                    "disable": 1
                },
                {
                    "id": 663,
                    "name": "下单转换率_产品全周期",
                    "disable": 1
                }
            ]
        }
    ])
    const [chooseBusinessStaffDate, setChooseBusinessStaffDate] = useState([
        {
            "option": [
                {
                    "id": "",
                    "name": "下单转换率",
                    "disable": 1
                },
                {
                    "id": 348,
                    "name": "下单转换率_近7天",
                    "disable": 1
                },
                {
                    "id": 393,
                    "name": "下单转换率_近14天",
                    "disable": 1
                },
                {
                    "id": 438,
                    "name": "下单转换率_近30天",
                    "disable": 1
                },
                {
                    "id": 483,
                    "name": "下单转换率_日",
                    "disable": 1
                },
                {
                    "id": 528,
                    "name": "下单转换率_周",
                    "disable": 1
                },
                {
                    "id": 573,
                    "name": "下单转换率_月",
                    "disable": 1
                },
                {
                    "id": 618,
                    "name": "下单转换率_年",
                    "disable": 1
                },
                {
                    "id": 663,
                    "name": "下单转换率_产品全周期",
                    "disable": 1
                }
            ]
        }
    ])
    // 选择生意参谋关键字
    const [businessStaffKey, setBusinessStaffKey] = useState('')
    // 已选指标
    const [selectedIndicator, setSelectedIndicator] = useState([{ id: '304', name: '图片' }, { id: '308', name: 'ID' }, { id: '309', name: '款号' }])
    // 模版指标
    const [templateIndicatorList , setTemplateIndicatorList] = useState([])
    // 是否要删除模版
    const [ isOpenTemplate , setIsOpenTemplate ] = useState(false)
    // 固定指标
    const [fixedIndicator] = useState([{ id: '304', name: '图片' }, { id: '308', name: 'ID' }, { id: '309', name: '款号' }])
    
     //设置每个页面显示个数
     const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    
    // 横着气泡
    const horizontallContent = (
        <div style={{ display: "flex" }}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                // 获取竖着可选的id
                let idObj = []
                let itemObj = []
                data.map(item => {
                    if (item['option'][chooseIndex].disable) {
                        idObj.push(item['option'][chooseIndex].id)
                        itemObj.push(item['option'][chooseIndex])
                    }
                })
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.push(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.push(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>全选</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                // 获取竖着可选的id
                let idObj = []
                let itemObj = []
                data.map(item => {
                    if (item['option'][chooseIndex].disable) {
                        idObj.push(item['option'][chooseIndex].id)
                        itemObj.push(item['option'][chooseIndex])
                    }
                })
                let chosedArr = [...chosedList]
                let idIntersectArr = listIntersection(chosedArr, idObj)
                let idUnionArr = listUnion(chosedArr, idObj)
                let idMinusArr = listDifference(idUnionArr, idIntersectArr)
                setChosedList(idMinusArr)
                let itemArr = [...selectedIndicator]
                let itemIntersectArr = listIntersection(itemArr, itemObj)
                let itemUnionArr = listUnion(itemArr, itemObj)
                let itemMinusArr = listDifference(itemUnionArr, itemIntersectArr)
                setSelectedIndicator(itemMinusArr)
                //   let arr = [...chosedList]
                //   obj.map(item=>{
                //     if(chosedList.indexOf(item) != -1){
                //         // 在选中的集合中不存在,添加新的数据
                //         arr.remove(item)
                //     }
                //   })
                //   setChosedList(arr)
            }}>反选</Button>
            <Button type="primary" danger onClick={() => {
                let data = chooseBusinessStaffDate
                // 获取竖着可选的id
                let idObj = []
                let itemObj = []
                data.map(item => {
                    if (item['option'][chooseIndex].disable) {
                        idObj.push(item['option'][chooseIndex].id)
                        itemObj.push(item['option'][chooseIndex])
                    }
                })
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.remove(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.remove(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>清空</Button>
        </div>
    )

    // 竖着气泡
    const verticalContent = (
        <div style={{ display: "flex" }}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                let idObj = []
                let itemObj = []
                data[rowIndex]['option'].map(item => {
                    if (item.disable) {
                        idObj.push(item.id)
                        itemObj.push(item)
                    }
                })
                // 删除第一个
                idObj.shift()
                itemObj.shift()
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.push(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) == -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.push(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>全选</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={() => {
                let data = chooseBusinessStaffDate
                let idObj = []
                let itemObj = []
                data[rowIndex]['option'].map(item => {
                    if (item.disable) {
                        idObj.push(item.id)
                        itemObj.push(item)
                    }
                })
                // 删除第一个
                idObj.shift()
                itemObj.shift()
                let chosedArr = [...chosedList]
                let idIntersectArr = listIntersection(chosedArr, idObj)
                let idUnionArr = listUnion(chosedArr, idObj)
                let idMinusArr = listDifference(idUnionArr, idIntersectArr)
                setChosedList(idMinusArr)
                let itemArr = [...selectedIndicator]
                let itemIntersectArr = listIntersection(itemArr, itemObj)
                let itemUnionArr = listUnion(itemArr, itemObj)
                let itemMinusArr = listDifference(itemUnionArr, itemIntersectArr)
                setSelectedIndicator(itemMinusArr)
                //   let arr = [...chosedList]
                //   obj.map(item=>{
                //     if(chosedList.indexOf(item) != -1){
                //         // 在选中的集合中不存在,添加新的数据
                //         arr.remove(item)
                //     }
                //   })
                //   setChosedList(arr)
            }}>反选</Button>
            <Button type="primary" danger onClick={() => {
                let data = chooseBusinessStaffDate
                let idObj = []
                let itemObj = []
                data[rowIndex]['option'].map(item => {
                    if (item.disable) {
                        idObj.push(item.id)
                        itemObj.push(item)
                    }
                })
                // 删除第一个
                idObj.shift()
                itemObj.shift()
                let arr = [...chosedList]
                idObj.map(item => {
                    if (chosedList.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        arr.remove(item)
                    }
                })
                let itemArr = [...selectedIndicator]
                itemObj.map(item => {
                    if (selectedIndicator.indexOf(item) != -1) {
                        // 在选中的集合中不存在,添加新的数据
                        itemArr.remove(item)
                    }
                })
                setChosedList(arr)
                setSelectedIndicator(itemArr)
            }}>清空</Button>
        </div>
    )

    // 筛选功能
    const searchContent = (
        <div style={{ display: 'flex' }}>
            <Input width={400} value={businessStaffKey} style={{ marginRight: 10 }} onChange={(e) => {
                setBusinessStaffKey(e.target.value)
            }} />
            <Button type="primary" style={{ marginRight: 10 }} onClick={() => {
                let obj = []
                allBusinessStaffDate.map(item => {
                    if (item.option[0]['name'].indexOf(businessStaffKey) != -1) {
                        obj.push(item)
                    }
                })
                setChooseBusinessStaffDate(obj)
            }}>查询</Button>
            <Button type="primary" danger onClick={() => {
                setBusinessStaffKey('')
                let obj = [...allBusinessStaffDate]
                setChooseBusinessStaffDate(obj)
            }}>重置</Button>
        </div>
    )
    // 表头
    const [columns, setColums] = useState([])
    const [columnsList, setColumsList] = useState([])
    const [chosedColumnsList, setChosedColumsList] = useState([])
    // 可选项
    const [choseList, setChoseList] = useState([])
    // 已存在选
    const [exitChoseList, setExitChostList] = useState([])
    const [searchData, setSearchData] = useState([])
    // 是否查询
    const [isSearch, setIsSearch] = useState(false)
    // 是否上传文件
    const [isBaseUpload, setIsBaseUpload] = useState(false)
    // 商品基础信息文件
    const [baseFile, setBaseFile] = useState([])
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 新增数据
    const [addData, setAddData] = useState([])
    // 更新数据
    const [updateData, setUpdateData] = useState([])
    // 是否开始
    const [isStart, setIsStart] = useState(0)
    // 是否生产数据提取模版
    const [isGenerateDataTemplate, setIsGenerateDataTemplate] = useState(false)
    const [isSearchGenerateDataTemplate, setIsSearchGenerateDataTemplate] = useState(false)
    // 是否将模版转为正式的
    const [isTemplateTurnPositive, setIsTemplateTurnPositive] = useState(false)
    // 是否需要周期性生产
    const [periodicProduction, setPeriodicProduction] = useState(0)
    // 数据模版id
    const [ templateId, setTemplateId] = useState('')
    // 数据模版名字
    const [ templateName, setTemplateName] = useState('')
    // 数据提取模版名称
    const [dataTemplateName, setDataTemplateName] = useState("")
    // 数据模版集合
    const [dataTemplateList, setDataTemplateList] = useState([])
    // 数据模版字典
    const [ dataTemplateDict, setDataTemplateDict ] = useState({})
    // 数据打开数据模版气泡
    const [ openDataTemplateBubbleIndex, setOpenDataTemplateBubbleIndex] = useState(-1)
    // 数据模版气泡
    const dataTemplateContent = (
        <div style={{display:'flex'}}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={()=>{
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "template_id": dataTemplateDict['id']
                }
                NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableTemplateCriteria).then(res => {
                    if (res.success) {
                        setTemplateIndicatorList(res.data['data'][0]['selected_indicator'])
                        setSelectedIndicator(res.data['data'][0]['selected_indicator']);
                        setChosedList(res.data['data'][0]['choose_list']);
                        getCommodityWideTableData(res.data['data'][0]['selected_indicator'], templateId, templateName, startTime, endTime)
                        setOpenDataTemplateBubbleIndex(-1)
                        getDataExtractionTemplate()
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }}>查询数据</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={()=>{
                 let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "template_id": dataTemplateDict['id']
                }
                NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableTemplateCriteria).then(res => {
                    if (res.success) {
                        setTemplateIndicatorList(res.data['data'][0]['selected_indicator'])
                        setSelectedIndicator(res.data['data'][0]['selected_indicator']);
                        setChosedList(res.data['data'][0]['choose_list']);
                        getDataExtractionTemplate()
                        setOpenDataTemplateBubbleIndex(-1)
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }}>查看细节</Button>
            <Button type="primary" style={{ marginRight: 10 }} danger onClick={()=>{
                setIsOpenTemplate(true)
            }}>删除模版</Button>
            <Button type="dashed" style={{display:dataTemplateDict['is_temporary'] ? '':'none'}} onClick={()=>{
                setOpenDataTemplateBubbleIndex(-1)
                setIsTemplateTurnPositive(true)
            }}>转存为正式模版</Button>
            <Button type="primary" onClick={()=>{
                 props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/groupStatistics`, 'state':{'templateId':dataTemplateDict['id'], 'start_time': '', 'end_time':''}})
            }}>进行分组统计</Button>
        </div>
    )
    // 缓存数据集合
    const [ cacheData , setCacheData ] = useState([])
    const [ openCacheDataBubbleIndex, setOpenCacheDataBubbleIndex] = useState(-1)
    // 缓存数据字典
    const [ cacheDataDict, setCacheDataDict ] = useState({})
    // 是否要删除缓存
    const [ isOpenCache, setIsOpenCache ] = useState(false)
    // 缓存数据气泡
    const cacheDataContent = (
        <div style={{display:'flex'}}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={()=>{
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "cache_id": cacheDataDict['id']
                }
                NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableCacheCriteria).then(res => {
                    if (res.success) {
                        setEndTime(res.data['end_time']);
                        setStartTime(res.data['start_time'])
                        setTemplateIndicatorList(res.data['data'][0]['selected_indicator'])
                        setSelectedIndicator(res.data['data'][0]['selected_indicator']);
                        setChosedList(res.data['data'][0]['choose_list']);
                        getCommodityWideTableData(res.data['data'][0]['selected_indicator'], templateId, templateName, res.data['start_time'], res.data['end_time'])
                        setOpenCacheDataBubbleIndex(-1)
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }}>查询数据</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={()=>{
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "cache_id": cacheDataDict['id']
                }
                NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableCacheCriteria).then(res => {
                    if (res.success) {
                        setEndTime(res.data['end_time']);
                        setStartTime(res.data['start_time'])
                        // setTime(res.data['date']);
                        setTemplateIndicatorList(res.data['data'][0]['selected_indicator'])
                        setSelectedIndicator(res.data['data'][0]['selected_indicator']);
                        setChosedList(res.data['data'][0]['choose_list']);
                        setOpenCacheDataBubbleIndex(-1)
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }}>查看细节</Button>
            <Button type="primary" style={{ marginRight: 10 }} danger onClick={()=>{
                setIsOpenCache(true)
            }}>删除缓存</Button>
            <Button type="dashed" style={{ marginRight: 10 }} onClick={()=>{
                let key = "download"
                message.loading({ content: '开始下载数据...', key, duration: 100 })
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "cache_id": cacheDataDict['id']
                }
                axios({
                    data: dataProps,
                    method: 'POST',
                    url: servicePath.downloadCommodityWideTableCacheData,
                    responseType: "blob",
                    headers: {
                        'Content-Type': 'application/json',  //如果写成contentType会报错
                        'authorization': cookie.load('token')
                    }
                }).then(
                    res => {
                        message.success({ content: '成功下载数据...', key })
                        downExcel(res)
                    }
                ).catch(e => {
                    message.error({content: '文件制作出错，请联系it同事！', key})
                })
            }}>下载数据</Button>
            <Button type="primary" onClick={()=>{
                //  props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/groupStatistics`, 'state':{'templateId':dataTemplateDict['id']}})
                // props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/groupStatistics`, 'state':{'cacheId':cacheDataDict['id']} })
                let dataProps = {
                    "roleId": cookie.load('role'),
                    "shopList": cookie.load('shopList'),
                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                    "shop": props.history.location.pathname.split('/')[2],
                    "cache_id": cacheDataDict['id']
                }
                NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableCacheCriteria).then(res => {
                    if (res.success) {
                        props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/groupStatistics`, 
                        'state':{'templateId':res.data.template_id, 'start_time': res.data.start_time, 'end_time':res.data.end_time}})
                    } else {
                        message.warn(res.errorMsg)
                    }
                })
            }}>进行分组统计</Button>
        </div>
    )
    // 获取数据
    const getAllData = async () => {
        await Promise.all([
            getCommodityWideTableScenario(),
            getDataExtractionTemplate(),
            getCommodityWideTableCache()
        ])
    }

    useEffect(() => {
        setIsSearch(true)
        getAllData()
        if(props.location.state != undefined){
            let dataProps = {
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                "shop": props.history.location.pathname.split('/')[2],
                "cache_id": props.location.state.cacheId
            }
            NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableCacheCriteria).then(res => {
                if (res.success) {
                    setEndTime(res.data['end_time']);
                    setStartTime(res.data['start_time'])
                    // setTime(res.data['date']);
                    setTemplateIndicatorList(res.data['data'][0]['selected_indicator'])
                    setSelectedIndicator(res.data['data'][0]['selected_indicator']);
                    setChosedList(res.data['data'][0]['choose_list']);
                    getCommodityWideTableData(res.data['data'][0]['selected_indicator'],  props.location.state.templateId, '', res.data['start_time'], res.data['end_time'])
                    setOpenCacheDataBubbleIndex(-1)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
       
    }, [])


    useEffect(() => {
        if (isStart) {
            getCommodityWideTableData(selectedIndicator, templateId, templateName, startTime, endTime)
        }
    }, [page])

    useEffect(() => {
        // setColums(
        //     ...columnsList.map(item=>{
        // return {
        //     title: item,
        //     dataIndex: item,
        //     key: item,
        //     width: 100,
        //     align: "center",
        // }
        //     })
        // )
        setColums([
            {
                title: 'ID',
                dataIndex: 'ID',
                key: 'ID',
                width: 100,
                align: "center",
                fixed: 'left',
                onCell:function(column){
                    return (
                        {
                            style: {
                                // width: 80,
                                // overflow: 'hidden',
                                // whiteSpace: 'nowrap',
                                // textOverflow:'ellipsis',
                                // cursor:'pointer',
                                fontSize:5
                            },
                        }
                    )
                },
                onHeaderCell:function(column){
                    return (
                        {
                            style: {
                                // width: 80,
                                // overflow: 'hidden',
                                // whiteSpace: 'nowrap',
                                // textOverflow:'ellipsis',
                                // cursor:'pointer',
                                fontSize:5
                            },
                        }
                    )
                },
                render: (text, record, index) => (
                    <div>
                        <div>
                            <a href={`https://detail.tmall.com/item.htm?id=${text}`} target='_blank'>
                                {/* <SaleImage width={70} shopName={props.history.location.pathname.split('/')[2]} sku={record.款号} id={text} /> */}
                                <CommodityImage width={70} shopName={props.history.location.pathname.split('/')[2]} sku={record.款号} id={text} index={index} />
                                {/* <img width='50rem' height='50rem' src={`https://pic.bmcsoft.cn/it/${imgUrl}/${record.sku}.jpg`} /> */}
                            </a>
                        </div>
                        <div>
                            {text}
                        </div>
                    </div>
                )
            },
            {
                title: '款号',
                dataIndex: '款号',
                key: '款号',
                width: 100,
                align: "center",
                fixed: 'left',
                onCell:function(column){
                    return (
                        {
                            style: {
                                // width: 80,
                                // overflow: 'hidden',
                                // whiteSpace: 'nowrap',
                                // textOverflow:'ellipsis',
                                // cursor:'pointer',
                                fontSize:5
                            },
                        }
                    )
                },
                onHeaderCell:function(column){
                    return (
                        {
                            style: {
                                // width: 80,
                                // overflow: 'hidden',
                                // whiteSpace: 'nowrap',
                                // textOverflow:'ellipsis',
                                // cursor:'pointer',
                                fontSize:5
                            },
                        }
                    )
                },
            },
            ...chosedColumnsList.map(item => {
                return {
                    title: item.name,
                    dataIndex: item.name,
                    key: item.name,
                    width:  100,
                    align: "center",
                    // sorter:true,
                    onCell:function(column){
                        return (
                            {
                                style: {
                                    // width: 80,
                                    // overflow: 'hidden',
                                    // whiteSpace: 'nowrap',
                                    // textOverflow:'ellipsis',
                                    // cursor:'pointer',
                                    fontSize:5
                                },
                            }
                        )
                    },
                    onHeaderCell:function(column){
                        return (
                            {
                                style: {
                                    // width: 80,
                                    // overflow: 'hidden',
                                    // whiteSpace: 'nowrap',
                                    // textOverflow:'ellipsis',
                                    // cursor:'pointer',
                                    fontSize:5
                                },
                                onMouseEnter:()=>{
                                    // message.info(column['title'])
                                }
                            }
                        )
                    },
                    render: (text, record, index) => (
                        item.name == 'ID' ?
                            <div>
                                <div>
                                    <a href={`https://detail.tmall.com/item.htm?id=${text}`} target='_blank'>
                                        <CommodityImage width={70} shopName={props.history.location.pathname.split('/')[2]} sku={record.款号} id={text} index={index} />
                                        {/* <SaleImage width={70} shopName={props.history.location.pathname.split('/')[2]} sku={record.款号} id={text} /> */}
                                        {/* <img width='50rem' height='50rem' src={`https://pic.bmcsoft.cn/it/${imgUrl}/${record.sku}.jpg`} /> */}
                                    </a>
                                </div>
                                <div>
                                    {text}
                                </div>
                            </div> :
                            <span>{text}</span>
                    )
                };
            })]
        )
    }, [chosedColumnsList])

    // 查询缓存列表
    const getCommodityWideTableCache = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'creator_name': cookie.load('name')
        }
        NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableCache).then(res => {
            if (res.success) {
                setCacheData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询提取数据模版
    const getDataExtractionTemplate = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'creator_name': cookie.load('name')
        }
        NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableTemplate).then(res => {
            if (res.success) {
                setDataTemplateList(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 查询商品宽表场景接口
    const getCommodityWideTableScenario = () => {
        setIsSpinning(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]]
        }
        NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableScenario).then(res => {
            setIsSpinning(false)
            if (res.success) {
                setAllBusinessStaffDate(res.data['1']['date']);
                setChooseBusinessStaffDate(res.data['1']['date']);
                setSearchItemDate(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 商品基础信息文件上传
    const baseFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setBaseFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setBaseFile(fileList)
        }
    }

    // 上传商品基础信息
    const uploadBaseFile = () => {
        if (baseFile.length != 0) {
            setEffective(1)
            const formData = new FormData()
            // 多个图片
            baseFile.forEach((item) => {
                formData.append('file', item['originFileObj']);
            })
            formData.append('shopList', cookie.load('shopList'));
            formData.append('shopId', '0188223a-5046-4afe-a43e-8762d3bd71ed')
            formData.append('roleId', cookie.load('role'));
            // NbAxios(formData, "POST", servicePath.checkJewelryItemInfo).then(res => {
            //     setEffective(0)
            //     if (res.success) {
            //         setBaseFile([])
            //         message.success('文件数据上传成功')
            //         setAddData(res.data['add']);
            //         setUpdateData(res.data['update']);
            //         setIsResult(true)
            //         setIsBaseUpload(false)
            //     } else {
            //         message.warn(res.errorMsg)
            //     }
            // })
        } else {
            message.warn('请选择文件')
        }
    }

    // 查询商品宽表数据接口
    const getCommodityWideTableData = (selectedIndicatorList, template_id, template_name, start_time , end_time) => {
        let idList = []
        selectedIndicatorList.map(item => {
            idList.push(item.id)
        })
        setIsSpinning(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "id_list": idList,
            // "date": time,
            "start_time":start_time,
            "end_time":end_time,
            "page": page,
            "page_size": 10,
            "condition_list": searchData,
            "template_name":template_name,
            "template_id":template_id,
            "creator_name":cookie.load('name'),
            "sort_list": []
        }
        NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableData).then(res => {
            setIsSpinning(false)
            if (res.success) {
                getCommodityWideTableCache()
                setTotal(res.total)
                setIsStart(1)
                let obj = [...selectedIndicatorList]
                obj.shift()
                setChoseList(obj)
                let headList = res.data['head']
                headList.shift()
                headList.shift()
                // setAllBusinessStaffDate(res.data['1']['date']);
                // setChooseBusinessStaffDate(res.data['1']['date']);
                // setSearchItemDate(res.data)
                setData(res.data['data']);
                let headObj = []
                headList.map(item=>{
                    let headDict = {
                        'name': item,
                        'isShow':1
                    }
                    headObj.push(headDict)
                })
                setColumsList(headObj);
                setChosedColumsList(headObj);
                setIsSearch(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 生产数据提取模版
    const generateDataTemplate = () => {
        if (dataTemplateName == '') {
            message.warn('请填写数据提取模版名字')
        } else {
            let dataProps = {
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                "is_cycle": periodicProduction,
                "selected_indicator": selectedIndicator,
                "name": dataTemplateName,
                "choose_list": chosedList,
                'creator_name': cookie.load('name'),
                "shop": props.history.location.pathname.split('/')[2],
                "is_temporary":0,
            }
            NbAxios(dataProps, "POST", servicePath.createCommodityWideTableTemplate).then(res => {
                if (res.success) {
                    message.success('成功生产数据提取模版')
                    getDataExtractionTemplate()
                    setIsGenerateDataTemplate(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 生产临时数据提取模版
    const generateTemporaryDataTemplate = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            "is_cycle": 0,
            "selected_indicator": selectedIndicator,
            "name": dataTemplateName,
            "choose_list": chosedList,
            'creator_name': cookie.load('name'),
            "shop": props.history.location.pathname.split('/')[2],
            "is_temporary":1,
        }
        NbAxios(dataProps, "POST", servicePath.createCommodityWideTableTemplate).then(res => {
            if (res.success) {
                message.success('成功生产数据提取模版')
                setDataTemplateName('')
                setPeriodicProduction(0)
                getDataExtractionTemplate()
                setTemplateId(res.data['template_id'])
                setTemplateName(res.data['template_name'])
                getCommodityWideTableData(selectedIndicator, res.data['template_id'], res.data['template_name'], startTime, endTime)
                setIsSearchGenerateDataTemplate(false)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 生产固定数据提取模版
    const generateFixedDataTemplate = () => {
        if (dataTemplateName == '') {
            message.warn('请填写数据提取模版名字')
        } else {
            let dataProps = {
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                "is_cycle": periodicProduction,
                "selected_indicator": selectedIndicator,
                "name": dataTemplateName,
                "choose_list": chosedList,
                'creator_name': cookie.load('name'),
                "shop": props.history.location.pathname.split('/')[2],
                "is_temporary":0,
            }
            NbAxios(dataProps, "POST", servicePath.createCommodityWideTableTemplate).then(res => {
                if (res.success) {
                    message.success('成功生产数据提取模版')
                    setDataTemplateName('')
                    setPeriodicProduction(0)
                    getDataExtractionTemplate()
                    setTemplateId(res.data['template_id'])
                    setTemplateName(res.data['template_name'])
                    getCommodityWideTableData(selectedIndicator, res.data['template_id'], res.data['template_name'], startTime, endTime)
                    setIsSearchGenerateDataTemplate(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    // 模板临时转永久接口
    const updateDataExtractionTemplate = ()=>{
        if (dataTemplateName == '') {
            message.warn('请填写数据提取模版名字')
        } else {
            let dataProps = {
                "roleId": cookie.load('role'),
                "shopList": cookie.load('shopList'),
                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                "is_cycle": periodicProduction,
                "template_id": dataTemplateDict['id'],
                "name": dataTemplateName,
                'creator_name': cookie.load('name')
            }
            NbAxios(dataProps, "POST", servicePath.updateCommodityWideTableTemplatePermanent).then(res => {
                if (res.success) {
                    message.success('成功将其转存为正式模版')
                    getDataExtractionTemplate()
                    setDataTemplateName('')
                    setPeriodicProduction(0)
                    setIsTemplateTurnPositive(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        }
    }

    return (
        <div>
            {/* 商品大宽表 */}
            <div style={{
                backgroundColor: "#fff",
                position: 'fixed',
                top: 40,
                zIndex: 99,
                borderBottom: "1px solid #ccc",
                width:'100vw'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: "space-around",
                    marginTop: 5
                }}>
                    <Title level={2}>{props.location.pathname.split('/')[2]}商品明细宽表</Title>
                    <div style={{ display: 'flex' }}>
                        <Button type="primary" style={{ marginTop: 8, marginRight: 10 }} onClick={() => setIsSearch(true)}>筛选条件</Button>
                        <a href="https://pic.bmcsoft.cn/imge_dir/093013422446/093013422446.html" target="_Blank" style={{marginTop:10}}><Button type="link">操作手册</Button></a>
                    </div>
                </div>
            </div>
            <div style={{ paddingLeft: 20, paddingRight: 20, marginTop:70 }}>
                <Table
                    locale={{
                        cancelSort: "点击取消排序",
                        triggerAsc: "点击升序",
                        triggerDesc: "点击降序"
                    }}
                    onChange={(pagination, filters, sorter) => {
                        console.log(filters);
                        console.log(sorter);
                    }}
                    loading={isSpinning}
                    dataSource={data}
                    columns={columns}
                    pagination={paginationProps}
                    scroll={{ x:'2000', y:'72vh'}} style={{ border: "1px solid #ccc" }} />
            </div>
            <Drawer
                title="筛选条件"
                visible={isSearch}
                onClose={() => {
                    setIsSearch(false)
                }}
                width={'80vw'}
            >
                <Spin spinning={isSpinning} tip="数据请求中.......">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <b style={{ marginRight: 10, marginTop: 5 }}>时间选择:</b>
                            {/* <DatePicker disabledDate={disabledDate} value={time} locale={locale} style={{ height: 30, marginRight: 10, marginBottom: 15 }} value={moment(time, dateFormat)} onChange={(date, dateString) => {
                                if (dateString == '') {
                                    message.warn('请选择合理的时间')
                                    return false
                                } else {
                                    setTime(dateString);
                                }
                            }} /> */}
                            <RangePicker disabledDate={disabledDate} locale={locale} style={{ height: 30, marginRight: 10, marginBottom: 15 }} value={[moment(startTime, dateFormat),moment(endTime, dateFormat)]} onChange={(date, dateString)=>{
                                if(dateString[0]==''){
                                    message.warn('请选择合理的时间')
                                    return false
                                }else{
                                    setStartTime(dateString[0])
                                    setEndTime(dateString[1])
                                }
                            }} />
                        </div>
                        <div style={{display:'flex'}}>
                            <a href="https://pic.bmcsoft.cn/imge_dir/093013422446/093013422446.html" target="_Blank" style={{marginTop:10}}><Button type="link">操作手册</Button></a>
                            <Tooltip placement="topLeft" title={"程序猿正在加紧开发中"} color={'cyan'}>
                                <Button disabled type="link" style={{ marginTop: 8 }}>查看智能图表</Button>
                            </Tooltip>
                        </div>
                    </div>
                    <Tabs activeKey={tabKey} type="card" style={{ backgroundColor: "#fff", height: 54 }} onChange={(key) => {
                        setTabKey(key)
                    }}>
                        <TabPane tab="指标选择" key="指标选择">
                        </TabPane>
                        <TabPane tab="条件查询" key="条件查询">
                        </TabPane>
                        {/* <TabPane tab="表头选择" key="表头选择">
                        </TabPane> */}
                        {/* <TabPane tab="上传数据" key="上传数据" disabled>
                    </TabPane> */}
                    </Tabs>
                    <div style={{ display: tabKey == "指标选择" ? '' : 'none' }}>
                        {/* border:"1px solid #ccc", */}
                        <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', marginTop: 10 }}>
                            <div style={{ display: 'grid', gridTemplateColumns: "1fr 450px", backgroundColor: 'white' }}>
                                <div style={{ marginLeft: '20px', marginTop: 10, display: 'grid', gridTemplateColumns: "70px 1fr" }}>
                                    <b style={{ marginTop: 10 }}>已选指标:</b>

                                    <div >
                                        <DraggableArea
                                            className={style.indicatorContent}
                                            tags={selectedIndicator}
                                            render={({ tag, index }) => (
                                                <div style={{
                                                    margin: "3px",
                                                    fontSize: '13px',
                                                    border: " 1px dashed #cccccc",
                                                    borderRadius: '4px',
                                                    padding: "0 8px",
                                                    lineHeight: "30px", color: "#666666",
                                                    backgroundColor: "rgba(255, 255, 255, 0.7);",
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    <span>{tag.name}</span><CloseOutlined style={{ marginTop: 10, marginLeft: 13, display: JSON.stringify(fixedIndicator).indexOf(JSON.stringify(tag)) != -1 ? 'none' : '' }} onClick={() => {
                                                        let obj = [...selectedIndicator]
                                                        obj.remove(tag)
                                                        setSelectedIndicator(obj)
                                                        let arr = [...chosedList]
                                                        if (arr.indexOf(tag.id) != -1) {
                                                            arr.remove(tag.id)
                                                        }
                                                        setChosedList(arr)
                                                    }} />
                                                </div>
                                            )}
                                            onChange={tags => setSelectedIndicator(tags)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Button disabled={Object.keys(selectedIndicator).length == 0 ? true : false} type="primary" style={{ width: '100px', marginRight: '20px', marginTop: 10 }} onClick={() => {
                                        if(templateIndicatorList.vequal(selectedIndicator)){
                                            getCommodityWideTableData(selectedIndicator, templateId, templateName, startTime, endTime)
                                        }else{
                                            setIsSearchGenerateDataTemplate(true)
                                        }
                                    }}>查看数据</Button>
                                    <Button disabled={Object.keys(selectedIndicator).length == 0 ? true : false} type="primary" style={{ width: '80px', marginRight: '20px', marginTop: 10 }} danger onClick={() => {
                                        let obj = [...fixedIndicator]
                                        setSelectedIndicator(obj)
                                        setChosedList([])
                                    }}>重置</Button>
                                    <Button type="dashed" style={{ marginTop: 10 }} disabled={Object.keys(selectedIndicator).length == 0 ? true : false} onClick={() => setIsGenerateDataTemplate(true)}>生成数据提取模版</Button>
                                    {/* <Tooltip placement="topLeft" title={"程序猿正在加紧开发中"} color={'cyan'}>
                                    <Button type="dashed" style={{ marginTop: 10 }} disabled >生成数据提取模版</Button>
                                </Tooltip> */}
                                    <Button type="link" href="#download" style={{ marginTop: 10 }}>下载数据</Button>
                                    {/* <Tooltip placement="topLeft" title={"程序猿正在加紧开发中"} color={'cyan'}>
                                        <Button type="link" disabled style={{ marginTop: 10 }}>查询并下载</Button>
                                    </Tooltip> */}
                                </div>
                            </div>
                            <div style={{ display: 'grid', height: 54, gridTemplateColumns: "1fr 400px", backgroundColor: 'white' }}>
                                <Tabs type="card" activeKey={tabSituationKey} style={{ backgroundColor: "#fff", height: 54 }} onChange={(key) => {
                                    setTabSituationKey(key)
                                }}>
                                    {
                                        searchItemDate.map(item => {
                                            return <TabPane tab={item.situation} key={item.situation}>
                                            </TabPane>
                                        })
                                    }
                                </Tabs>
                                <div style={{ display: 'flex' }}>
                                    {/* <Button type="primary" style={{ width: '80px', marginRight: '20px', marginTop: 10 }}>确定</Button>
                                <Button type="dashed" style={{ marginTop: 10 }}>生成模版</Button>
                                <Button type="link" style={{ marginTop: 10 }}>下载数据</Button> */}
                                </div>
                            </div>
                            <div style={{ height: 350 }}>
                                {
                                    tabSituationKey == '商品基础信息' ?
                                        searchItemDate[searchItemDate.findIndex(o => o.situation == tabSituationKey)]['date'].map(item => {
                                            return <div style={{ display: 'grid', gridTemplateColumns: "80px 1fr", marginTop: 5, marginLeft: 10 }}>
                                                <Text type="secondary">{item.name}</Text>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                    {
                                                        item.option.map(item => {
                                                            return <Col>
                                                                <Checkbox
                                                                    checked={JSON.stringify(selectedIndicator).indexOf(JSON.stringify(item)) != -1 ? true : false}
                                                                    value={item} style={{ width: 150, pointerEvents: JSON.stringify(fixedIndicator).indexOf(JSON.stringify(item)) != -1 ? 'none' : '', }} onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            let obj = [...selectedIndicator]
                                                                            obj.push(item)
                                                                            setSelectedIndicator(obj)
                                                                        } else {
                                                                            let obj = [...selectedIndicator]
                                                                            obj.remove(item)
                                                                            setSelectedIndicator(obj)
                                                                        }
                                                                    }}>{item.name}</Checkbox>
                                                            </Col>
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        }) :
                                        tabSituationKey == '生意参谋全量数据' ? <div style={{ width: '70vw', marginLeft: 20 }}>
                                            <Row>
                                                {
                                                    searchItemDate[searchItemDate.findIndex(o => o.situation == tabSituationKey)]['head'].map((item, index) => {
                                                        return index == 0 ?
                                                            <Popover content={searchContent}>
                                                                <Col span={2} style={{ border: "1px solid #ccc", textAlign: 'center', backgroundColor: '#97dcff' }}>{item}</Col>
                                                            </Popover> :
                                                            <Popover content={horizontallContent} trigger="hover" onMouseEnter={() => {
                                                                setChooseIndex(index)
                                                            }}>
                                                                <Col span={2} onMouseEnter={() => {
                                                                    setTitleIndex(index)
                                                                }} onMouseLeave={() => {
                                                                    setTitleIndex('')
                                                                }} style={{ border: "1px solid #ccc", textAlign: 'center', backgroundColor: titleIndex != 0 && index == titleIndex ? '#29b6f6' : '#c3ecff' }}>{item}</Col>
                                                            </Popover>
                                                    })
                                                }
                                            </Row>
                                            <div  className={style.tableContent}>
                                                {
                                                    chooseBusinessStaffDate.map((item, item_index) => {
                                                        return <Row >
                                                            {
                                                                item.option.map((i, i_index) => {
                                                                    return <Col onMouseEnter={() => {
                                                                        setRowIndex(item_index);
                                                                        setTitleIndex(i_index)
                                                                    }} onMouseLeave={() => {
                                                                        setRowIndex(-1);
                                                                        setTitleIndex(-1)
                                                                    }} span={2} style={{
                                                                        border: "1px solid #ccc",
                                                                        textAlign: 'center',
                                                                        pointerEvents: i.disable == 0 ? 'none' : '',
                                                                        backgroundColor: (item_index == rowIndex && i_index < titleIndex && i_index != 0) || (i_index == titleIndex && item_index < rowIndex && i_index != 0) ? '#81d4fa' :
                                                                            (item_index == rowIndex && i_index == titleIndex) ? '#29b6f6' :
                                                                                (item_index == rowIndex && i_index == 0) ? '#29b6f6' :
                                                                                    i_index == 0 ? '#97dcff' :
                                                                                        item_index % 2 == 0 ? '#eee' : ''
                                                                        // chosedList.indexOf(i.id) != -1 ? '#29b6f6' : '',
                                                                    }}>{i_index == 0 ?
                                                                        <Popover content={verticalContent} trigger="hover" onMouseEnter={() => {
                                                                            setRowIndex(item_index);
                                                                        }}>
                                                                            <span>{i.name}</span>
                                                                        </Popover>
                                                                        : chosedList.indexOf(i.id) != -1 ? <span onClick={() => {
                                                                            if (chosedList.indexOf(i.id) == -1) {
                                                                                // 在选中的集合中不存在,添加新的数据
                                                                                let obj = [...selectedIndicator]
                                                                                obj.push(i)
                                                                                setSelectedIndicator(obj)
                                                                                let idObj = [...chosedList]
                                                                                idObj.push(i.id)
                                                                                setChosedList(idObj)
                                                                            } else {
                                                                                // 在选中的集合中存在,删除数据
                                                                                let obj = [...selectedIndicator]
                                                                                obj.remove(i)
                                                                                setSelectedIndicator(obj)
                                                                                let idObj = [...chosedList]
                                                                                idObj.remove(i.id)
                                                                                setChosedList(idObj)
                                                                            }
                                                                        }}>
                                                                            <IconFont type="icon-dagou" style={{ width: '100%', height: '100%', fontSize: 25 }} />
                                                                        </span>
                                                                            : i.disable == 0 ? <span>
                                                                                <IconFont type="icon-hr" style={{ width: '100%', height: '100%', fontSize: 30 }} />
                                                                            </span> : <span onClick={() => {
                                                                                if (chosedList.indexOf(i.id) == -1) {
                                                                                    // 在选中的集合中不存在,添加新的数据
                                                                                    let obj = [...selectedIndicator]
                                                                                    obj.push(i)
                                                                                    setSelectedIndicator(obj)
                                                                                    let idObj = [...chosedList]
                                                                                    idObj.push(i.id)
                                                                                    setChosedList(idObj)
                                                                                } else {
                                                                                    // 在选中的集合中存在,删除数据
                                                                                    let obj = [...selectedIndicator]
                                                                                    obj.remove(i)
                                                                                    setSelectedIndicator(obj)
                                                                                    let idObj = [...chosedList]
                                                                                    idObj.remove(i.id)
                                                                                    setChosedList(idObj)
                                                                                }
                                                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                        }</Col>
                                                                })
                                                            }
                                                        </Row>
                                                    })
                                                }
                                            </div>
                                        </div> : <span></span>
                                }
                            </div>
                        </div>
                        <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', height: 300, marginTop: 20, overflow: 'auto' }}>
                            <div style={{ marginLeft: 20, paddingTop: 10 }}>
                                <Title level={4}>数据提取模版</Title>
                                <div style={{ display: "flex", flexWrap:'wrap' }} onMouseLeave={()=>{
                                    setOpenDataTemplateBubbleIndex(-1)
                                }}>
                                    {
                                        dataTemplateList.map((item, index) => {
                                            return <div style={{
                                                margin: "3px",
                                                fontSize: '13px',
                                                border: " 1px dashed #cccccc",
                                                borderRadius: '4px',
                                                padding: "0 8px",
                                                lineHeight: "30px", color: "rgb(9,110,217)",
                                                backgroundColor: "#E6F7FF",
                                                whiteSpace: 'nowrap'
                                            }}>
                                                <Popover content={dataTemplateContent} visible={openDataTemplateBubbleIndex == index ? true : false} trigger="hover" placement="bottomLeft" onMouseEnter={()=>{
                                                    setOpenDataTemplateBubbleIndex(index)
                                                    setTemplateId(item.id)
                                                    setTemplateName(item.name)
                                                    let obj = {
                                                        'id': item.id,
                                                        'is_temporary': item.is_temporary
                                                    }
                                                    setDataTemplateDict(obj);
                                                }}>
                                                    <span>{item.name}</span>
                                                </Popover>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', height: 300, marginTop: 20 , overflow: 'auto'}}>
                            <div style={{ marginLeft: 20, paddingTop: 10 }}>
                                <div style={{display:'flex'}}>
                                    <Title level={4}>我的缓存数据</Title>
                                    <Text type="danger" style={{marginTop:5, marginLeft:2}}>(此处可以下载缓存数据)</Text>
                                    <a name="download"></a>
                                </div>
                                <div style={{ display: "flex", flexWrap:'wrap' }} onMouseLeave={()=>{
                                    setOpenCacheDataBubbleIndex(-1)
                                }}>
                                    {
                                        cacheData.map((item, index) => {
                                            return <div style={{
                                                margin: "3px",
                                                fontSize: '13px',
                                                border: " 1px dashed #cccccc",
                                                borderRadius: '4px',
                                                padding: "0 8px",
                                                lineHeight: "30px", color: "rgb(9,110,217)",
                                                backgroundColor: "#E6F7FF",
                                                whiteSpace: 'nowrap'
                                            }}>
                                                <Popover content={cacheDataContent} visible={openCacheDataBubbleIndex == index ? true : false} trigger="hover" placement="bottomLeft" onMouseEnter={()=>{
                                                    setOpenCacheDataBubbleIndex(index)
                                                    setTemplateId(item.template_id)
                                                    let obj = {
                                                        'id': item.id,
                                                        'is_temporary': item.is_temporary
                                                    }
                                                    setCacheDataDict(obj);
                                                }}>
                                                    <span>{item.name}</span>
                                                </Popover>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: tabKey == "条件查询" ? '' : 'none' }}>
                        <Row style={{ width: 1500, }}>
                            {
                                searchData.map((item, index) => {
                                    return <Col span={8} style={{ display: 'flex', marginTop: 15 }}>
                                        <Select style={{ width: 250 }} value={searchData[index]['sort']} onChange={(value) => {
                                            let flag = 1
                                            exitChoseList.map(item => {
                                                if (item.key == value.split(',')[0]) {
                                                    flag = 0
                                                }
                                            })
                                            if (flag) {
                                                if (searchData[index]['sort'] != '') {
                                                    let obj = [...exitChoseList]
                                                    obj.splice(index, 1)
                                                    let objValue = {
                                                        'key': value.split(',')[0],
                                                        'value': value.split(',')[1]
                                                    }
                                                    obj.push(objValue)
                                                    setExitChostList(obj)
                                                    let searchDataObj = [...searchData]
                                                    setSearchData([])
                                                    searchDataObj[index]['value'] = []
                                                    searchDataObj[index]['sort'] = value.split(',')[0]
                                                    searchDataObj[index]['sortValue'] = value.split(',')[1]
                                                    let dataProps = {
                                                        "roleId": cookie.load('role'),
                                                        "shopList": cookie.load('shopList'),
                                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                                        "field_id": value.split(',')[1],
                                                    }
                                                    NbAxios(dataProps, "POST", servicePath.queryFieldConditionType).then(res => {
                                                        if (res.success) {
                                                            searchDataObj[index]['condition_type'] = res.data['condition_type']
                                                            setSearchData(searchDataObj)
                                                        } else {
                                                            message.warn(res.errorMsg)
                                                        }
                                                    })
                                                } else {
                                                    let obj = [...exitChoseList]
                                                    setExitChostList([])
                                                    let objValue = {
                                                        'key': value.split(',')[0],
                                                        'value': value.split(',')[1]
                                                    }
                                                    obj.push(objValue)
                                                    setExitChostList(obj)
                                                    let searchDataObj = [...searchData]
                                                    setSearchData([])
                                                    searchDataObj[index]['sort'] = value.split(',')[0]
                                                    searchDataObj[index]['sortValue'] = value.split(',')[1]
                                                    let dataProps = {
                                                        "roleId": cookie.load('role'),
                                                        "shopList": cookie.load('shopList'),
                                                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                                        "field_id": value.split(',')[1],
                                                    }
                                                    NbAxios(dataProps, "POST", servicePath.queryFieldConditionType).then(res => {
                                                        if (res.success) {
                                                            searchDataObj[index]['condition_type'] = res.data['condition_type']
                                                            if (res.data['condition_type']) {
                                                                searchDataObj[index]['value_chose_list'] = res.data['data_list']
                                                            }
                                                            setSearchData(searchDataObj)
                                                        } else {
                                                            message.warn(res.errorMsg)
                                                        }
                                                    })
                                                }
                                            } else {
                                                message.warn('已存在这个选择项了')
                                            }

                                        }}>
                                            {
                                                item.chose_list.map(i => {
                                                    return <Option value={i.name + ',' + i.id}>{i.name}</Option>
                                                })
                                            }
                                        </Select>
                                        {
                                            searchData[index]['condition_type'] == '1' ? <Input style={{ width: 200 }} value={searchData[index]['value']} onChange={(e) => {
                                                let obj = [...searchData]
                                                obj[index]['value'] = e.target.value
                                                setSearchData(obj)
                                            }} />
                                                :
                                                // value={[moment(startTime, dateFormat), moment(endTime, dateFormat)]}
                                                // {[searchData[index]['value'][0], searchData[index]['value'][1]]}
                                                searchData[index]['condition_type'] == '2' ? <RangePicker value={searchData[index]['value'][0] == undefined ? ['', ''] : [moment(searchData[index]['value'][0], dateFormat), moment(searchData[index]['value'][1], dateFormat)]} locale={locale} onChange={(value, dateString) => {
                                                    let time_arr = []
                                                    let obj = [...searchData]
                                                    if (dateString[0] == '') {
                                                        message.warn('请选择合理的时间')
                                                        return false
                                                    } else {
                                                        time_arr.push(dateString[0])
                                                        time_arr.push(dateString[1])
                                                    }
                                                    obj[index]['value'] = time_arr
                                                    setSearchData(obj)
                                                }} />
                                                    :
                                                    searchData[index]['condition_type'] == '3' ?
                                                        <div style={{ display: 'flex' }}>
                                                            <Select width={50} value={searchData[index]['value']['conform'] == undefined ? '' : searchData[index]['value']['conform']} onChange={(value) => {
                                                                let obj = [...searchData]
                                                                let conformDict = {}
                                                                conformDict['conform'] = value
                                                                obj[index]['value'] = conformDict
                                                                setSearchData(obj)
                                                            }}>
                                                                <Option value={">"}>{">"}</Option>
                                                                <Option value={"<"}>{"<"}</Option>
                                                                <Option value={"<="}>{"<="}</Option>
                                                                <Option value={">="}>{">="}</Option>
                                                                <Option value={"="}>{"="}</Option>
                                                            </Select>
                                                            <Input disabled={searchData[index]['value']['conform'] == undefined ? true : false} style={{ width: 150 }} value={searchData[index]['value']['value']} onChange={(e) => {
                                                                let obj = [...searchData]
                                                                obj[index]['value']['value'] = e.target.value
                                                                setSearchData(obj)
                                                            }} />
                                                        </div>
                                                        : <Select value={searchData[index]['value']} style={{ width: 200 }} mode="multiple" onChange={(value) => {
                                                            let obj = [...searchData]
                                                            obj[index]['value'] = value
                                                            setSearchData(obj)
                                                        }}>
                                                            {
                                                                item.value_chose_list.map(i => {
                                                                    return <Option value={i}>{i}</Option>
                                                                })
                                                            }
                                                        </Select>
                                        }
                                        <MinusCircleOutlined style={{ marginTop: 5, marginLeft: 10, marginRight: 10 }} onClick={() => {
                                            if (searchData[index]['sort'] != '') {
                                                let exitChoseObj = [...exitChoseList]
                                                setExitChostList([])
                                                exitChoseObj.splice(index, 1)
                                                setExitChostList(exitChoseObj)
                                                let obj = [...searchData]
                                                obj.splice(index, 1);
                                                setSearchData(obj)
                                            } else {
                                                let obj = [...searchData]
                                                obj.splice(index, 1);
                                                setSearchData(obj)
                                            }
                                        }} />
                                    </Col>
                                })
                            }
                            <PlusCircleOutlined style={{ marginLeft: 20, marginTop: 20 }} onClick={() => {
                                let obj = [...searchData]
                                setSearchData([])
                                let arr3 = [...choseList]
                                // 已存在key集合
                                exitChoseList.map(item => {
                                    arr3.map((i, index) => {
                                        if (i.id == item.value) {
                                            arr3.splice(index, 1)
                                        }
                                    })
                                })
                                obj.push({
                                    'sort': '',
                                    'sortValue': '',
                                    'condition_type': '',
                                    'value': [],
                                    'chose_list': arr3,
                                    'value_chose_list': []
                                })
                                setSearchData(obj);
                            }} />

                        </Row>
                        <div style={{ marginTop: 20, display: 'flex' }}>
                            <Button type="primary" style={{ marginRight: 20 }} onClick={() => {
                                let flag = 1
                                let errMsg = ''
                                searchData.map((item, index) => {
                                    if (flag) {
                                        if (item.condition_type == '') {
                                            errMsg = `请选择查询纬度`
                                            flag = 0
                                        }
                                        if (item.condition_type == 1) {
                                            // 判断是否是数组
                                            if (typeof (item.value) == 'object') {
                                                errMsg = `请输入${index + 1}选项正确的值`
                                                flag = 0
                                            }
                                        }
                                        if (item.condition_type == 2) {
                                            if (item.value.length == 0) {
                                                errMsg = `请输入${index + 1}选项正确的值`
                                                flag = 0
                                            }
                                        }
                                        if (item.condition_type == 3) {
                                            if (Object.keys(item.value).length != 0) {
                                                if (item.value['value'] == undefined) {
                                                    errMsg = `请输入${index + 1}选项正确的值`
                                                    flag = 0
                                                }
                                            } else {
                                                errMsg = `请输入${index + 1}选项正确的值`
                                                flag = 0
                                            }
                                        }
                                    }

                                })
                                if (flag == 0) {
                                    message.warn(errMsg)
                                } else {
                                    if (page == 1) {
                                        getCommodityWideTableData(selectedIndicator, templateId, templateName, startTime, endTime)
                                    } else {
                                        setPage(1)
                                    }
                                }
                            }}>搜索</Button>
                            <Button type="primary" danger onClick={() => {
                                setSearchData([])
                                setExitChostList([])
                                let idList = []
                                selectedIndicator.map(item => {
                                    idList.push(item.id)
                                })
                                let dataProps = {
                                    "roleId": cookie.load('role'),
                                    "shopList": cookie.load('shopList'),
                                    "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                    "id_list": idList,
                                    // "date": time,
                                    "page": page,
                                    "page_size": 10,
                                    "condition_list": [],
                                    "sort_list": [],
                                    "template_name":templateName,
                                    "template_id":templateId,
                                    "creator_name":cookie.load('name'),
                                }
                                NbAxios(dataProps, "POST", servicePath.queryCommodityWideTableData).then(res => {
                                    if (res.success) {
                                        setTotal(res.total)
                                        setIsStart(1)
                                        let obj = [...selectedIndicator]
                                        obj.shift()
                                        setChoseList(obj)
                                        let headList = res.data['head']
                                        headList.shift()
                                        headList.shift()
                                        // setAllBusinessStaffDate(res.data['1']['date']);
                                        // setChooseBusinessStaffDate(res.data['1']['date']);
                                        // setSearchItemDate(res.data)
                                        setData(res.data['data']);
                                        setColumsList(headList);
                                        setIsSearch(false)
                                    } else {
                                        message.warn(res.errorMsg)
                                    }
                                })
                            }}>重置</Button>
                        </div>
                    </div>
                    <div style={{ display: tabKey == "上传数据" ? '' : 'none' }}>
                        <div style={{ display: 'flex' }}>
                            <Button type="dashed" style={{ marginRight: 10 }} onClick={() => setIsBaseUpload(true)}>上传商品基础信息</Button>
                            <Button type="dashed">上传追单表</Button>
                        </div>
                    </div>
                    <div style={{ display: tabKey == "表头选择" ? '' : 'none' }}>
                        <div style={{ display: 'flex' }}>
                           <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            {
                                chosedColumnsList.map((item, index)=>{
                                    return <Col>
                                        <Checkbox checked={item.isShow ? true : false} value={item} onChange={(e)=>{
                                             if (e.target.checked) {
                                                let obj = [...chosedColumnsList]
                                                obj.push(item)
                                                setChosedColumsList(obj)
                                            } else {
                                                let obj = [...chosedColumnsList]
                                                obj[index]['isShow'] = 0
                                                setChosedColumsList(obj)
                                            }
                                        }}>{item.name}</Checkbox>
                                    </Col>
                                })
                            }
                           </Row>
                        </div>
                    </div>
                </Spin>

            </Drawer>
            <Modal
                title="上传女装商品基础信息"
                visible={isBaseUpload}
                centered={true}
                okText="确定"
                cancelText="取消"
                maskClosable={true}
                onOk={() => uploadBaseFile()}
                onCancel={() => {
                    message.success('取消上传女装商品基础信息')
                    setBaseFile([])
                    setIsBaseUpload(false)
                }}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={baseFile}
                    onChange={baseFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx文件
                    </p>
                </Dragger>
            </Modal>

            <Modal
                title="是否要删除该模版"
                visible={isOpenTemplate}
                centered={true}
                okText={"确定"}
                cancelText={"取消"}
                onCancel={()=>{
                    message.success('取消删除该模版')
                    setIsOpenTemplate(false)
                }}
                onOk={()=>{
                    let dataProps = {
                        "roleId": cookie.load('role'),
                        "shopList": cookie.load('shopList'),
                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                        "shop": props.history.location.pathname.split('/')[2],
                        "template_id": dataTemplateDict['id']
                    }
                    NbAxios(dataProps, "POST", servicePath.deleteCommodityWideTableTemplate).then(res => {
                        if (res.success) {
                            message.success('成功删除该模版')
                            getDataExtractionTemplate()
                            setOpenDataTemplateBubbleIndex(-1)
                            setIsOpenTemplate(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}
            >
                <Text type='danger' strong>是否要删除该模版？</Text>
            </Modal>

            <Modal
                title="是否要删除该缓存"
                visible={isOpenCache}
                centered={true}
                okText={"确定"}
                cancelText={"取消"}
                onCancel={()=>{
                    message.success('取消删除该缓存')
                    setIsOpenCache(false)
                }}
                onOk={()=>{
                    let dataProps = {
                        "roleId": cookie.load('role'),
                        "shopList": cookie.load('shopList'),
                        "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                        "shop": props.history.location.pathname.split('/')[2],
                        "cache_id": cacheDataDict['id']
                    }
                    NbAxios(dataProps, "POST", servicePath.deleteCommodityWideTableCache).then(res => {
                        if (res.success) {
                            message.success('成功删除该缓存')
                            getCommodityWideTableCache()
                            setOpenCacheDataBubbleIndex(-1)
                            setIsOpenCache(false)
                        } else {
                            message.warn(res.errorMsg)
                        }
                    })
                }}
            >
                <Text type='danger' strong>是否要删除该缓存？</Text>
            </Modal>

            <Modal
                title="生产数据提取模版"
                visible={isGenerateDataTemplate}
                onCancel={() => {
                    message.success('取消生产数据提取模版')
                    setIsGenerateDataTemplate(false)
                }}
                footer={[]}
                centered={true}
                width={800}
            >
                <div>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', padding: 10 }}>
                        <b>您选择的数据指标:</b>
                        <div style={{ display: 'flex', flexWrap: "wrap" }}>
                            {
                                selectedIndicator.map((item, index) => {
                                    return <div style={{
                                        margin: "3px",
                                        fontSize: '13px',
                                        border: " 1px dashed #cccccc",
                                        borderRadius: '4px',
                                        padding: "0 8px",
                                        lineHeight: "30px", color: "#666666",
                                        backgroundColor: "rgba(255, 255, 255, 0.7);",
                                        whiteSpace: 'nowrap'
                                    }}>{item.name}</div>
                                })
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                        <div style={{ display: 'flex' }}>
                            <b style={{ marginTop: 5, marginRight: 10 }}>模版名称:</b>
                            <Input value={dataTemplateName} style={{ width: 200 }} onChange={(e) => {
                                setDataTemplateName(e.target.value)
                            }} />
                        </div>
                        <Checkbox checked={periodicProduction == 1 ? true : false} onChange={(e) => {
                            if (e.target.checked) {
                                setPeriodicProduction(1)
                            } else {
                                setPeriodicProduction(0)
                            }
                        }}>数据是否周期性生产</Checkbox>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-around", marginTop: 20 }}>
                        <Button type="primary" onClick={() => generateDataTemplate()}>生产数据提取模版</Button>
                        <Button type="primary" danger onClick={() => {
                            message.success('取消生产数据提取模版')
                            setIsGenerateDataTemplate(false)
                        }}>取消</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                title="是否生产数据提取模版"
                visible={isSearchGenerateDataTemplate}
                onCancel={() => {
                    message.success('取消查看数据')
                    setIsSearchGenerateDataTemplate(false)
                    setDataTemplateName('')
                    setPeriodicProduction(0)
                }}
                footer={[]}
                centered={true}
                width={800}
            >
                <div>
                    <div style={{ boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', padding: 10 }}>
                        <b>您选择的数据指标:</b>
                        <div style={{ display: 'flex', flexWrap: "wrap" }}>
                            {
                                selectedIndicator.map((item, index) => {
                                    return <div style={{
                                        margin: "3px",
                                        fontSize: '13px',
                                        border: " 1px dashed #cccccc",
                                        borderRadius: '4px',
                                        padding: "0 8px",
                                        lineHeight: "30px", color: "#666666",
                                        backgroundColor: "rgba(255, 255, 255, 0.7);",
                                        whiteSpace: 'nowrap'
                                    }}>{item.name}</div>
                                })
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                        <div style={{ display: 'flex' }}>
                            <b style={{ marginTop: 5, marginRight: 10 }}>模版名称:</b>
                            <Input value={dataTemplateName} style={{ width: 200 }} onChange={(e) => {
                                setDataTemplateName(e.target.value)
                            }} />
                        </div>
                        <Checkbox checked={periodicProduction == 1 ? true : false} onChange={(e) => {
                            if (e.target.checked) {
                                setPeriodicProduction(1)
                            } else {
                                setPeriodicProduction(0)
                            }
                        }}>数据是否周期性生产</Checkbox>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-around", marginTop: 20 }}>
                        <Button type="primary" onClick={() => generateFixedDataTemplate()}>是</Button>
                        <Button type="primary" danger onClick={() => generateTemporaryDataTemplate()}>否(会生成临时模版)</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                title="是否转存为正式模版"
                visible={isTemplateTurnPositive}
                onCancel={() => {
                    message.success('取消转存为正式模版')
                    setIsTemplateTurnPositive(false)
                    setDataTemplateName('')
                    setPeriodicProduction(0)
                }}
                footer={[]}
                centered={true}
                width={800}
            >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                        <div style={{ display: 'flex' }}>
                            <b style={{ marginTop: 5, marginRight: 10 }}>模版名称:</b>
                            <Input value={dataTemplateName} style={{ width: 200 }} onChange={(e) => {
                                setDataTemplateName(e.target.value)
                            }} />
                        </div>
                        <Checkbox checked={periodicProduction == 1 ? true : false} onChange={(e) => {
                            if (e.target.checked) {
                                setPeriodicProduction(1)
                            } else {
                                setPeriodicProduction(0)
                            }
                        }}>数据是否周期性生产</Checkbox>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-around", marginTop: 20 }}>
                        <Button type="primary" onClick={() => updateDataExtractionTemplate()}>是</Button>
                        <Button type="primary" danger onClick={() => {
                            message.success('取消转存为正式模版')
                            setIsTemplateTurnPositive(false)
                            setDataTemplateName('')
                            setPeriodicProduction(0)
                        }}>否</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )

}

export default Index