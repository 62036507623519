import React, { useState, useEffect } from "react";
import { Row, Button, Modal, Form, message, Upload, Typography, Radio, Spin, Input } from 'antd'
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import LogoTitle from './image/logo.png'
// import '../../../static/css/work/work.css'
import NbAxios from "../../config/utils/nbAxios";
// 设置文本
const { Text } = Typography;
function Login(props) {
    // 设置加载中变量
    const [loading, setloading] = useState(false);
    // 验证码
    const [smsCode, setSmsCode] = useState([])
    // 手机号
    const [phone, setPhone] = useState([])
    // 姓名
    const [name, setName] = useState([])
    // 验证码时效性
    const [isShowCode, setIsShowCode] = useState(false)
    // 验证码时间
    const [time, setTime] = useState(60)
    // 提交函数
    const finish = () => {
        var reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则
        if (name.length == 0) {
            message.error("请输入姓名")
        } else if (phone.length == 0) {
            message.error("请输入手机号")
        } else if (!reg.test(phone)) {
            message.error("请输入正确的手机号")
        } else if (smsCode.length == 0) {
            message.error("请输入验证码")
        } else {
            setloading(true)
            let dataProps = {
                "staff_name": name,
                "staff_phone": phone,
                "verification_code": smsCode,
            }
            console.log(dataProps);
            NbAxios(dataProps, "POST", servicePath.searchShortMessage).then((res) => {
                setloading(false)
                if (res.success) {
                    message.success("登录成功");
                    {/* 跳转到两码一证页面，并传递基础信息 */ }
                    props.history.push({
                        'pathname':'/workMessage/index',
                        'state':{
                            "staff_name":res.data[0]["staff_name"],
                            "entry_post":res.data[0]["entry_post"],
                            "work_city":res.data[0]["work_city"],
                            "work_location":res.data[0]["work_location"],
                            "company":res.data[0]["company"],
                            "staff_phone":res.data[0]["staff_phone"],
                        }
                    })
                } else {
                    message.warn(res.errorMsg);
                }
            });
        }
    }
    return (
        <div style={{ paddingBottom: 20, height: "100vh" }}>
            <Spin tip="Loading..." spinning={loading}>
                <div style={{
                    width: "100vw",
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    position: 'sticky',
                    top: 0,
                    zIndex: 99
                }}>
                    < img src={LogoTitle} style={{ height: 40 }} />
                </div>
                <div style={{ paddingTop: "20vw", paddingLeft: "10vw" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: 80, marginTop: 3 }}>
                            <Text strong style={{ fontSize: "15px" }}>
                                姓名：
                            </Text>
                        </div>
                        <div>
                            <Input style={{ width: "40vw" }}
                                placeholder="请输入姓名"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                allowClear />
                        </div>
                    </div>
                    <div style={{ display: "flex", paddingTop: "5vw" }}>
                        <div style={{ width: 80, marginTop: 3 }}>
                            <Text strong style={{ fontSize: "15px" }}>
                                手机号：
                            </Text>
                        </div>
                        <div>
                            <Input style={{ width: "40vw" }}
                                placeholder="请输入手机号"
                                onChange={(e) => {
                                    setPhone(e.target.value)

                                }}
                                allowClear
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", paddingTop: "5vw" }}>
                        <div style={{ width: 80, marginTop: 3 }}>
                            <Text strong style={{ fontSize: "15px" }}>
                                验证码：
                            </Text>
                        </div>
                        <div>
                            <Input style={{ width: "40vw" }}
                                placeholder="请输入短信验证码"
                                onChange={(e) => {
                                    setSmsCode(e.target.value)
                                }}
                                allowClear />
                        </div>
                        <div style={{ paddingTop: "1vw", paddingLeft: "1vw" }}>
                            <a onClick={() => {
                                if (isShowCode == false) {
                                    if (phone.length == 0) {
                                        message.error("请输入手机号")
                                    } else if (name.length == 0) {
                                        message.error("请输入姓名")
                                    } else {
                                        let dataProps = {
                                            "staff_phone": phone,
                                            "staff_name": name,
                                        }
                                        console.log(dataProps);
                                        setIsShowCode(true)
                                        setloading(true)
                                        NbAxios(dataProps, 'POST', servicePath.sendShortMessage).then(res => {
                                            setloading(false)
                                            if (res.success) {
                                                message.success("验证码发送成功")
                                                // setPhone(res.data.phone)
                                                setIsShowCode(true)
                                                const active = setInterval(() => {
                                                    setTime((preSecond) => {
                                                        if (preSecond <= 1) {
                                                            setIsShowCode(false)
                                                            clearInterval(active)
                                                            // 重置秒数
                                                            return 60
                                                        }
                                                        return preSecond - 1
                                                    })
                                                }, 1000)
                                            } else {
                                                message.warn(res.errorMsg)
                                                setIsShowCode(false)
                                            }
                                        }).catch(e => {
                                            // message.success("短信验证码已发送，有效时间为10分钟！")
                                        })
                                    }
                                }
                            }}>{isShowCode ? `${time}秒后重新发送` : '获取验证码'}</a>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
                    <Button type="primary" style={{ width: "90vw" }} onClick={() => { finish() }}>
                        提交
                    </Button>
                </div>
            </Spin>

        </div>
    )
}

export default Login