
import React, { useContext, useState, useEffect } from 'react'
import { DatePicker, Tabs, Select, message} from 'antd'
import { StateContext } from '../../raceShop/store/stateShare'
import NbAxios from '../../../config/utils/nbAxios'
import axios from 'axios'
import servicePath from '../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import moment from 'moment'
import cookie from 'react-cookies'
const { TabPane } = Tabs;
const { Option } = Select


// KOL数据汇总
function Index(params) {
    const dateFormat = 'YYYY-MM'
    // 月份
    const [ month , setMonth ] = useState(moment().format('YYYY-MM'))
    // 业务人员的新开发博主
    const [ assignPersonNew , setAssignPersonNew ] = useState([])
    // 业务人员的新开发博主
    const [ assignPersonNewKol , setAssignPersonNewKol ] = useState([])
    // 修改月份
    const changeMonth = (date, dateString)=>{
        if (date === null || date === '' || date === undefined) {
            message.warn('请选择正确的月份')
            return false
        }
        setMonth(dateString)
    }

    useEffect(()=>{
        getAssignPersonNewKol()
    },[month])

    const getAssignPersonNewKol = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "month": month
        }
        NbAxios(dataProps, "POST", servicePath.queryAssignPersonNewKol).then(res => {
            if(res.success){
                setAssignPersonNew(res.data.people);
                setAssignPersonNewKol(res.data.total);
            }else{
                message.error(res.errorMsg)
            }
        })
    }

    // 表格
    let newKolReport = {
        title: {
            text: `新增KOL`,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            top: '100px',
            right: '5%',
            left: '5%',
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: ['新增KOL']
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: assignPersonNew
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'KOL个数/个',
                position: 'left',
                axisLabel: {
                    formatter: function (data) {
                        return (data);
                    }
                },
                axisLine: {
                    show: true,
                    onZero: true
                },
            }
        ],
        series: [
            {
                name: '个数',
                type: 'bar',
                barWidth: '10%',
                data: assignPersonNewKol
            }
        ]
    }

    useEffect(()=>{
        let reportChart = echarts.init(document.getElementById('newKolReport'));
        // 绘制图表
        reportChart.setOption(newKolReport);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    },[assignPersonNewKol])

    return (
        <div>
            <div style={{marginLeft:"100px", marginTop:"20px"}}>
                <DatePicker value={moment(month, dateFormat)} onChange={changeMonth} picker="month" locale={locale} />
            </div>
            <div id="newKolReport" style={{ width: '90vw', height: '70vh', marginTop: 100, marginLeft:100}}></div>
        </div>
    )
}

export default Index