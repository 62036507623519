import React, { useState, useCallback, useEffect } from 'react';
import {Form, Modal, TimePicker, Table, Input,  Button, message } from 'antd';
import axios from 'axios'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import servicePath from '../config/apiUrl'
import '../static/css/index.css'
const { TextArea } = Input
const { RangePicker } = TimePicker;

function Meeting(props) {

    const [form] = Form.useForm()

    // 定义表单格式的参数,标签占左边5个格栅，录入占右边16个格栅
    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    }

    // 消息
    const [meetingMessage, setMeetingMessage] = useState('')
    // 数据
    const [date, setDate] = useState([])
    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // 定义预约会议的开始时间
    const [startTime, setStartTime] = useState('')
    // 定义预约会议的结束时间
    const [endTime, setEndTime] = useState('')
    // 定义会议主题信息
    const [meetingTheme, setMeetingTheme] = useState('')
    // 定义会议内容信息
    const [meetingContent, setMeetingContent] = useState('')
    // 申请人邮箱
    const [email , setEmail ] = useState('')
    // 是否是空闲
    const [ flag , setFlag ] = useState(0)

    // 定义预约对话框是否弹出的参数
    const [order, setOrder] = useState(false)

    // 选择key
    const [ selectedRowKey , setSelectedRowKey] = useState([])

    // 最小值
    const [ min , setMin ] = useState('')

    // 最大值
    const [ max , setMax ] = useState('')

    // 选择的行
    const [ selectedRow , setSelectRow ] = useState([])

    // 按钮是否有效
    const [ effective , setEffective ] = useState(0)

    // 不可预约的时间集合
    const [disChooseTime, setDisChooseTime] = useState([1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23, 0])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    const columns = [
        {
            title:'今日空闲时间',
            children:[
                {
                    title: '开始时间',
                    dataIndex: 'start_time',
                    key: 'startTime',
                    align: 'center'
                },
                {
                    title: '结束时间',
                    dataIndex: 'end_time',
                    key: 'endTime',
                    align: 'center'
                }
            ]
        }
    ];

    useEffect(() => {
        getFreeTime()
    }, [])


    useEffect(()=>{
        let now_date = new Date()
        let now_day = moment().weekday(0).format('YYYY-MM-DD')
         // 禁止选的时间点加上已经过去的时间点
         let now_hour = parseInt(now_date.getHours()-1)
         for(let i = 9 ; i<= now_hour; i++){
             disChooseTime.push(i)
         }
    })

    // 回去空余时间表
    const getFreeTime = ()=>{
        axios.get('http://192.168.66.91:5005/list/' + props.match.params.id).then(
            res => {
                setMeetingMessage(res.data[0]['message'])
                setDate(res.data[0]['free_time_list'])
                setFlag(res.data[0]['flag'])
        }).catch(err => {
                console.log(err);
        })
    }

    const orderRoom =()=>{
        // 弹窗打开
        setEffective(1)
        if (meetingTheme == ''){
            message.error('会议主题不能为空')
        } else if(meetingContent == ''){
            message.error('会议主题不能为空')
        }else{
            let data = {}
            data.meeting_room_id = props.match.params.id
            data.email = email + '@bmcsoft.cn'
            data.start_time = startTime
            data.end_time = endTime
            data.meeting_title = meetingTheme
            data.meeting_content = meetingContent
            axios({
                data: data,
                method: 'POST',
                // url: 'http://192.168.2.56:8000/meeting/orSubscribeMeeting',
                url: servicePath.orSubscribeMeeting,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                }
            }).then(
                res => {
                    setEffective(0)
                    if (res.data.success) {
                        form.resetFields()
                        setEmail('')
                        setStartTime('')
                        setEndTime('')
                        setMeetingTheme('')
                        setMeetingContent('')
                        setOrder(false)
                        getFreeTime()
                        setSelectedRowKey([])
                        message.success('预约成功！')
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            )
        }
    }

    const cancleOrder = ()=>{
        form.resetFields()
        setEmail('')
        setStartTime('')
        setEndTime('')
        setMeetingTheme('')
        setMeetingContent('')
        setSelectedRowKey([])
        setOrder(false)
        message.success('取消预约')
    }

    // 预约时的时间范围变化函数
    const timeRangeChange = (time, timeString) => {
        // 对范围选择器来说，传入参数time和timeString为数组
        if (time === null) {
            setStartTime()
            setEndTime()
        } else {
            setStartTime(time[0].format('kk:mm'))
            setEndTime(time[1].format('kk:mm'))
        }
    }

    // 会议主题变化时
    const meetingThemeChange = (e) => {
        setMeetingTheme(e.target.value);
    }
    // 会议内容变化时
    const meetingContentChange = (e) => {
        setMeetingContent(e.target.value)
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKey,
        hideSelectAll:true,
        onChange: (selectedRowKeys)=>{
            let selectedKeys = [...selectedRowKey]
            // 1,首先判断数组的长度
            if(selectedKeys.length == 0){
                let id = selectedRowKeys[0]
                selectedKeys.push(id)
                setSelectedRowKey(selectedRowKeys)
                setStartTime(date[id]['start_time'])
                setEndTime(date[id]['end_time'])
                // 3.最大值和最小值都设置为当前的id
                setMin(id)
                setMax(id)
            } else{
                let id = ''
                if (selectedRowKeys.length > selectedRowKey.length){
                    id = selectedRowKeys.filter(function(v){ return selectedRowKey.indexOf(v) == -1 })[0]
                }else{
                    id = selectedRowKey.filter(function(v){ return selectedRowKeys.indexOf(v) == -1 })[0]
                }
                if(id<min){
                    // 2.1 要是选择的id小于最小值
                    // 2.2 更新最小值
                    setMin(id)
                    let key = []
                    // 2.3 则从最小值到最大的循环，放进数组
                    for (let i = id; i < max+1; i++){
                        key.push(i)
                    }
                    setSelectedRowKey(key)
                    // 2.4更新开始时间
                    setStartTime(date[id]['start_time'])
                    // 2.5更新结束时间
                    setEndTime(date[max]['end_time'])
                }else if(id>min && id != max){
                    // 2.1 要是选择的id大于最小值并且不等于最大值
                    // 2.2 更新最大值
                    setMax(id)
                    let key = []
                    // 2.3  则从最小值到最大的循环，放进数组
                    for (let i = min; i < id+1; i++){
                        key.push(i)
                    }
                    setSelectedRowKey(key)
                    // 2.4更新开始时间
                    setStartTime(date[min]['start_time'])
                    // 2.5更新结束时间
                    setEndTime(date[id]['end_time'])
                }else if((id == min || id == max) && selectedKeys.length != 1 ){
                    // 2.1 要是选择的id是最大值或者最小值并且长度不为1
                    // 2.2 list中去除id这个值
                    selectedKeys.splice(selectedKeys.indexOf(id), 1)
                    setSelectedRowKey(selectedKeys)
                    // 2.3更新最大值和最小值
                    setMax(Math.max.apply(null, selectedKeys))
                    setMin(Math.min.apply(null, selectedKeys))
                    // 2.4更新开始时间
                    setStartTime(date[Math.min.apply(null, selectedKeys)]['start_time'])
                    // 2.5更新结束时间
                    setEndTime(date[Math.max.apply(null, selectedKeys)]['end_time'])
                }else if((id == min) && selectedKeys.length == 1 ){
                    // 2.1 要是选择的id是最小值 并且长度为1 即 取消选择时间
                    setSelectedRowKey([])
                    setStartTime('')
                    setEndTime('')
                }
            }
            
        },
    };

    // 选择行的逻辑处理
    const selectRow = (record) => {
        let selectedRowKeys = [...selectedRowKey]       
        // 1.首先判断数组的长度
        if(selectedRowKeys.length == 0){
            // 2.要是数组长度为0的时候，直接把现在的id放进去，获取当前选择行的开始时间和结束时间
            let id = record.id
            selectedRowKeys.push(record.id)
            setSelectedRowKey(selectedRowKeys)
            setStartTime(record.start_time)
            setEndTime(record.end_time)
            // 3.最大值和最小值都设置为当前的id
            setMin(id)
            setMax(id)
        }else{
            let id = record.id
            if(id<min){
                // 2.1 要是选择的id小于最小值
                // 2.2 更新最小值
                setMin(id)
                let key = []
                // 2.3 则从最小值到最大的循环，放进数组
                for (let i = id; i < max+1; i++){
                    key.push(i)
                }
                setSelectedRowKey(key)
                // 2.4更新开始时间
                setStartTime(date[id]['start_time'])
                // 2.5更新结束时间
                setEndTime(date[max]['end_time'])
            }else if(id > min && id != max){
                // 2.1 要是选择的id大于最小值并且不等于最大值
                // 2.2 更新最大值
                setMax(id)
                let key = []
                // 2.3  则从最小值到最大的循环，放进数组
                for (let i = min; i < id+1; i++){
                    key.push(i)
                }
                setSelectedRowKey(key)
                // 2.4更新开始时间
                setStartTime(date[min]['start_time'])
                // 2.5更新结束时间
                setEndTime(date[id]['end_time'])
            }else if((id == min || id == max) && selectedRowKeys.length != 1 ){
                // 2.1 要是选择的id是最大值或者最小值并且长度不为1
                // 2.2 list中去除id这个值
                selectedRowKeys.splice(selectedRowKeys.indexOf(id), 1)
                setSelectedRowKey(selectedRowKeys)
                // 2.3更新最大值和最小值
                setMax(Math.max.apply(null, selectedRowKeys))
                setMin(Math.min.apply(null, selectedRowKeys))
                // 2.4更新开始时间
                setStartTime(date[Math.min.apply(null, selectedRowKeys)]['start_time'])
                // 2.5更新结束时间
                setEndTime(date[Math.max.apply(null, selectedRowKeys)]['end_time'])
            }else if((id == min) && selectedRowKeys.length == 1 ){
                // 2.1 要是选择的id是最小值 并且长度为1 即 取消选择时间
                setSelectedRowKey([])
                setStartTime('')
                setEndTime('')
            }
        }
    }



    return (
        <>
            <div style={{ height: size.height, width: size.width }}>
                <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}>
                    <span style={{ color: flag == 0 ? "green": "red" }}>{meetingMessage}</span>
                </div>
                <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
                    <div style={{ color: "orange", }}>
                        {'注意:'}
                    </div>
                    <div style={{ color: "orange", textIndent: '35px' }}>
                        {'若会议时长小于30分钟,可以当作临时会议室'}
                    </div>
                    <div style={{ color: "orange", textIndent: '35px' }}>
                        {'若会议时长大于30分钟,请点击下方[立即预约]按钮'}
                    </div>
                    {/* <div style={{ color: "red", textIndent: '35px' }}>
                        {'18:00之后不提供预约会议室功能'}
                    </div> */}
                </div>
                <div style={{textAlign: 'center', marginTop:'10px', marginBottom:'2px'}}>
                    <Button style={{display: date.length == 0 ?'none':'' }} type="primary" onClick={()=>{
                        if(startTime == '' || endTime == '' || startTime == endTime){
                            message.warn('请选择正确的会议时间')
                        }else{
                            form.resetFields()
                            setOrder(true)
                        }
                    }} size="large">立 即 预 约</Button>
                </div>
                <div>
                    <Table 
                        dataSource={date} 
                        columns={columns}  
                        rowKey={'id'}  
                        rowSelection={rowSelection} 
                        onRow={(record)=>({
                            onClick:()=>{
                                selectRow(record)
                            }
                        })} />
                </div>
            </div>
            <Modal
                title='预约会议室'
                visible={order}
                onOk={orderRoom}
                onCancel={cancleOrder}
                okText={'预约'}
                cancelText={'取消'}
                okButtonProps = {{disabled : effective == 1 ? true : false}}
                cancelButtonProps = {{disabled : effective == 1 ? true : false}}
            >
                <Form
                    {...layout}
                    form={form}
                    labelAlign='left'
                >
                    <Form.Item
                        label='选择时间'
                        name='time'
                    >
                        <RangePicker
                            locale={locale}
                            format='HH:mm'
                            minuteStep={30}
                            hourStep={1}
                            showNow={true}
                            allowClear={true}
                            hideDisabledOptions = {true}
                            autoFocus = {true}
                            inputReadOnly = {true}
                            defaultValue={[moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')]}
                            value={[startTime, endTime]}
                            disabled
                            onChange={timeRangeChange}
                            // disabledHours={() => [1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23, 0]}
                            disabledHours={() => disChooseTime}
                        />
                    </Form.Item>
                    <Form.Item
                        label='会议主题'
                        name='theme'
                    >
                        <TextArea
                            placeholder='填写会议主题'
                            onChange={meetingThemeChange}
                            value={meetingTheme}
                        />
                    </Form.Item>
                    <Form.Item
                        label='会议内容'
                        name='meetingContent'
                    >
                        <TextArea
                            placeholder='输入会议的内容，以及注意事项等等...'
                            onChange={meetingContentChange}
                            value={meetingContent}
                        />
                    </Form.Item>
                    <Form.Item
                        label='预约人邮箱'
                        name='email'
                    >
                        <Input
                            placeholder='请输入预约人邮箱'
                            addonAfter="@bmcsoft.cn"
                            onChange={(e)=>{
                                setEmail(e.target.value)
                            }}
                            value={email}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )

}

export default Meeting