import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, Table, Modal, Upload, message, Input, Select, Form, Tabs, Space, Descriptions } from 'antd'
import cookie from 'react-cookies'
import AssignWork from './inside/assignWork'
import PendingWork from './inside/pendingWork'
import ProcessedWork from './inside/processedWork'
import FinishedWork from './inside/finishWork'
import ResolveWork from './inside/resolveWork'
const { TabPane } = Tabs;

function InsideSystem(props) {

    // 当前tab值
    const [curTabKey, setCurTabKey] = useState('1')

    // 切换tab
    const changeTabs = (activeKey) => {
        setCurTabKey(activeKey)
        cookie.save('tabKey', activeKey, { path: '/' })
    }
    // 
    useEffect(()=>{
        setCurTabKey(cookie.load('tabKey')?cookie.load('tabKey'):curTabKey)
    },[])

    return (
        <div style={{display:'flex'}}>
            <div>
                <Tabs defaultActiveKey={cookie.load('tabKey')?cookie.load('tabKey'):curTabKey} tabPosition="left"  onChange={changeTabs}>
                    <TabPane disabled tab=" " key="0">
                    </TabPane>
                    <TabPane disabled tab=" " key="0">
                    </TabPane>
                    <TabPane disabled tab=" " key="0">
                    </TabPane>
                    <TabPane tab="待指派" key="1">
                    </TabPane>
                    <TabPane tab="待处理" key="2">
                    </TabPane>
                    <TabPane tab="处理中" key="3">
                    </TabPane>
                    <TabPane tab="待反馈" key="4">
                    </TabPane>
                    <TabPane tab="已完成" key="5">
                    </TabPane>
                </Tabs>
            </div>
            <div style={{display:curTabKey === '1' ? '' : 'none'}}>
                {curTabKey === '1' ? <AssignWork /> : <div></div>}
            </div>
            <div style={{display:curTabKey === '2' ? '' : 'none'}}>
                {curTabKey === '2' ? <PendingWork /> : <div></div>}
            </div>
            <div style={{display:curTabKey === '3' ? '' : 'none'}}>
                {curTabKey === '3' ? <ProcessedWork history={props.history} /> : <div></div>}
            </div>
            <div style={{display:curTabKey === '4' ? '' : 'none'}}>
                {curTabKey === '4' ? <FinishedWork history={props.history} /> : <div></div>}
            </div>
            <div style={{display:curTabKey === '5' ? '' : 'none'}}>
                {curTabKey === '5' ? <ResolveWork history={props.history} /> : <div></div>}
            </div>
        </div>
    )

}

export default InsideSystem