import React, { useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Row, Col, Button, Table, Modal, Tooltip, message, Input, Collapse, Form, Select, Spin } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import servicePath from '../../config/apiUrl'
import axios from 'axios'
import '../../static/css/Table.css'
import '../../static/css/index.css'
const { Panel } = Collapse
const { confirm } = Modal
const { Option } = Select


// 机构管理
function Provider(props) {

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const [form1] = Form.useForm()

    //数据
    const [data, setData] = useState([])

    // 总数页数
    const [total, setTotal] = useState(0)

    // 老数据
    const [oldData, setOldData] = useState([])

    //备份
    const [backups, setBackups] = useState([])

    //页面
    const [page, setPage] = useState(1)

    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])

    //id
    const [id, setId] = useState(0)

    //联系方式
    const [phone, setPhone] = useState('')

    // 新的联系方式
    const [ newPhone , setNewPhone ] = useState('')

    //机构名
    const [organization, setOrganization] = useState('')

    //联系人
    const [contacts, setContacts] = useState('')

    //简介
    const [synopsis, setSynopsis] = useState('')

    //合作品牌
    const [trademark, setTrademark] = useState('')

    //资源优势
    const [advantage, setAdvantage] = useState('')

    //备注
    const [remark, setRemark] = useState('')

    // 搜索名字
    const [searchName, setSearchName] = useState('')

    //是否打开明细弹框
    const [detail, setDetail] = useState(false)

    //是否打开简介弹框
    const [isSynopsis, setIsSynopsis] = useState(false)

    //是否打开联系方式弹框
    const [isPhone, setIsPhone] = useState(false)

    //是否增加供应商
    const [addProvider, setAddProvider] = useState(false)

    //是否修改供应商
    const [updateProvider, setUpdateProvider] = useState(false)

    // 是否是检索状态
    const [isSearch, setIsSearch] = useState(0)

    // 是否是加载状态
    const [isLoading, setIsLoading] = useState(false)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    //获取权限代码
    useEffect(() => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            url: servicePath.getRoleShopPorts,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json'  //如果写成contentType会报错
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setJurisdiction(res.data.data)
                }
            }
        )
    }, [])

    //表格属性
    const columns = [
        {
            title: '机构名称',
            key: 'organization',
            dataIndex: 'organization',
            width: '200px',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '联系人',
            key: 'contacts',
            dataIndex: 'contacts',
            width: '200px',
            align: 'center',
            fixed: 'left',
        }, {
            title: "联系方式",
            key: 'phone',
            dataIndex: 'phone',
            width: '200px',
            align: 'center',
            fixed: 'left',
            render: (text, record, index) => (
                <Button style={{ display: jurisdiction.indexOf("kol::lookPhoneOfProvider") != -1 ? "" : "none" }} type="dashed" onClick={() => { openPhone(index) }}>查看联系方式</Button>
            ),
        },
        {
            title: '机构简介',
            key: 'synopsis',
            dataIndex: 'synopsis',
            width: '200px',
            align: 'center',
            // render: (text, record, index) => {
            //     // return <div style={{
            //     //     width: size.width * 0.1,
            //     //     height: "20px",
            //     //     overflow: "hidden",
            //     //     textOverflow: "ellipsis",
            //     //     whiteSpace: "nowrap"
            //     // }} onClick={() => {
            //     //     openSynopsis(index)
            //     // }}>{text}</div>

            // }
        },
        {
            title: '合作品牌',
            key: 'trademark',
            dataIndex: 'trademark',
            width: '200px',
            align: 'center'
        },
        {
            title: '资源优势',
            key: 'advantage',
            dataIndex: 'advantage',
            width: '200px',
            align: 'center'
        },
        {
            title: '备注',
            key: 'remark',
            dataIndex: 'remark',
            width: '200px',
            align: 'center'
        },
        {
            title: '查看明细',
            key: 'search',
            width: '200px',
            align: 'center',
            render: (text, record, index) => (
                <SearchOutlined onClick={() => {
                    setId(data[index]['id'])
                    setDetail(true)
                }} />
            ),
        },
        {
            title: '修改',
            key: 'modification',
            width: '200px',
            align: 'center',
            colSpan: jurisdiction.indexOf("kol::updateProvider") != -1 ? 1 : 0,
            render: (text, record, index) => (
                <EditOutlined style={{ display: jurisdiction.indexOf("kol::updateProvider") != -1 ? "" : "none" }} onClick={() => openUpdateModel(index)} />
            ),
        },

    ]

    // 当老数据更新好了才打开更新弹框
    useEffect(() => {
        // 当老数据和联系方式拿到的时候才打开更新弹框
        if (oldData.length != 0 & phone != '') {
            // 往老数据添加联系方式
            oldData['phone'] = phone
            setUpdateProvider(true)
        }
    }, [oldData, phone])

    //获取供应商数据
    useEffect(() => {
        getData()
    }, [page])

    // 获取数据
    const getData = ()=>{
        if (isSearch == 0) {
            getAllProviders()
        } else {
            search()
        }
    }

    //获取所有供应商数据
    const getAllProviders = () => {
        setIsLoading(true)
        let dataProps = {
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role'),
            "page": page
        }
        axios({
            method: 'POST',
            url: servicePath.queryAllProvider,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setData(res.data['data'])
                    setTotal(res.data['total'])
                    setIsLoading(false)
                } else {                 
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //获取联系方式
    const getPhone = (id) => {
        let dataProps = {
            "id": id,
            "operator": cookie.load('name'),
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role')
        }
        axios({
            method: 'POST',
            url: servicePath.getPhoneOfProvider,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setPhone(res.data['data'])
                    setNewPhone(res.data['data'])
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }



    //关闭明细弹框
    const closeDetail = () => {
        setId(-1)
        setDetail(false)
    }

    // 打开联系方式
    const openPhone = (index) => {
        let dataProps = {
            "id": data[index]['id'],
            "operator": cookie.load('name'),
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role')
        }
        axios({
            method: 'POST',
            url: servicePath.getPhoneOfProvider,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setPhone(res.data['data'])
                    setIsPhone(true)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 关闭联系方式
    const closePhone = () => {
        setPhone('')
        setIsPhone(false)
    }

    //打开详细简介
    const openSynopsis = (index) => {
        //根据页改变下标
        setSynopsis(data[index]['synopsis'])
        setIsSynopsis(true)
    }

    //关闭详细简介
    const closeSynopsis = () => {
        setSynopsis([])
        setIsSynopsis(false)
    }

    //确认添加机构
    const addOk = () => {
        if (organization == '') {
            message.error('机构名字不能为空')
        } else if (contacts == '') {
            message.error('联系人不能为空')
        } else if (phone == '') {
            message.error('联系方式不能为空')
        } else if (synopsis == '') {
            message.error('简介不能为空')
        } else if (trademark == '') {
            message.error('合作品牌不能为空')
        } else if (advantage == '') {
            message.error('资源优势不能为空')
        } else {
            let dataProps = {
                'organization': organization,
                'contacts': contacts,
                'phone': phone,
                'synopsis': synopsis,
                'advantage': advantage,
                'trademark': trademark,
                'remark': remark,
                "operator": cookie.load('name'),
                "shopId": cookie.load('shopList'),
                'roleId': cookie.load('role')
            }
            axios({
                method: 'POST',
                url: servicePath.addProvider,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success("成功增加机构")
                        form.resetFields();
                        setOrganization('')
                        setPhone('')
                        setContacts('')
                        setTrademark('')
                        setAdvantage('')
                        setSynopsis('')
                        setRemark('')
                        getData()
                        setAddProvider(false)
                    } else {
                        if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else{
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
    }

    //取消添加供应商
    const addCancel = () => {
        message.success("取消增加机构")
        form.resetFields();
        setOrganization('')
        setPhone('')
        setContacts('')
        setTrademark('')
        setAdvantage('')
        setSynopsis('')
        setRemark('')
        setAddProvider(false)
    }

    //打开修改弹框
    const openUpdateModel = (index) => {
        //根据页改变下标
        setOldData(data[index])
        setId(data[index]['id'])
        getPhone(data[index]['id'])
        setOrganization(data[index]['organization'])
        setContacts(data[index]['contacts'])
        setSynopsis(data[index]['synopsis'])
        setAdvantage(data[index]['advantage'])
        setTrademark(data[index]['trademark'])
        setRemark(data[index]['remark'])
    }

    // 确认修改供应商
    const updateOk = () => {
        if (organization == '') {
            message.error('机构名字不能为空')
        } else if (contacts == '') {
            message.error('联系人不能为空')
        } else if (newPhone == '') {
            message.error('联系方式不能为空')
        } else if (synopsis == '') {
            message.error('简介不能为空')
        } else if (trademark == '') {
            message.error('合作品牌不能为空')
        } else if (advantage == '') {
            message.error('资源优势不能为空')
        } else {
            let dataProps = {
                'id': id,
                'oldData': oldData,
                'organization': organization,
                'contacts': contacts,
                'phone': newPhone,
                'synopsis': synopsis,
                'advantage': advantage,
                'trademark': trademark,
                'remark': remark,
                "operator": cookie.load('name'),
                "shopId": cookie.load('shopList'),
                'roleId': cookie.load('role')
            }
            axios({
                method: 'POST',
                url: servicePath.updateProvider,
                data: dataProps,
                headers: {
                    'Content-Type': 'application/json',  //如果写成contentType会报错
                    'authorization':cookie.load('token')
                }
            }).then(
                res => {
                    if (res.data['success']) {
                        message.success("成功修改机构")
                        form.resetFields();
                        setId(0)
                        setOrganization('')
                        setPhone('')
                        setNewPhone('')
                        setContacts('')
                        setTrademark('')
                        setAdvantage('')
                        setSynopsis('')
                        setRemark('')
                        setOldData([])
                        getData()
                        setUpdateProvider(false)
                    } else {
                        if (res.data['errorCode'] == '401') {
                            message.error(res.data['errorMsg'])
                            form.resetFields();
                            setId(0)
                            setOrganization('')
                            setPhone('')
                            setNewPhone('')
                            setContacts('')
                            setTrademark('')
                            setAdvantage('')
                            setSynopsis('')
                            setRemark('')
                            setOldData([])
                            getData()
                            setUpdateProvider(false)
                        }else if(res.data['errorCode'] == '10001'){
                            message.error(res.data['errorMsg'])
                            cookie.remove("role", { path: '/' });
                            cookie.remove("shop", { path: '/' })
                            cookie.remove("shopName", { path: '/' })
                            cookie.remove("name", { path: '/' })
                            cookie.remove("roleName", { path: '/' })
                            cookie.remove("email", { path: '/' })
                            cookie.remove("phone", { path: '/' })
                            cookie.remove("watermark", { path: '/' })
                            cookie.remove("flag", { path: '/' })
                            cookie.remove("token", { path: '/' })
                            props.history.push("/")
                        }else {
                            message.error(res.data['errorMsg'])
                        }
                    }
                }
            )
        }
    }

    // 取消修改供应商
    const updateCancel = () => {
        message.success("取消修改机构")
        form.resetFields();
        setId(0)
        setOrganization('')
        setPhone('')
        setNewPhone('')
        setContacts('')
        setTrademark('')
        setAdvantage('')
        setSynopsis('')
        setRemark('')
        setOldData([])
        setUpdateProvider(false)
    }

    // 条件查询
    const search = () => {
        // 修改isSearch的值,说明是搜索状态
        setIsSearch(1)
        // 出现加载
        setIsLoading(true)        
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            'name': searchName,
            "page": page,
        }
        axios({
            method: 'POST',
            url: servicePath.searchProvider,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setData(res.data['data'])
                    setTotal(res.data['total'])
                    setIsLoading(false)
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 重置
    const reset = () => {
        // 数据清空
        setSearchName('')
        // 表单清空
        form1.resetFields();
        // 关闭搜索状态
        setIsSearch(0)
        // 将当前页设置为第一页
        setPage(1)
        // 如果当前页面是1的话。手动请求数据
        if (page == 1) {
            getAllProviders()
        }
    }



    return (
        <div>
            <Spin spinning={isLoading} size="large" tip="数据请求中....." style={{ marginTop: size.height * 0.3 }}>
                {/* 按钮区域 */}
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06 }}>
                    <Row>
                        <Col span={3}>
                            <Button type="primary" style={{ display: jurisdiction.indexOf("kol::addProvider") != -1 ? "" : "none" }} onClick={() => setAddProvider(true)}>新增机构</Button>
                        </Col>
                    </Row>
                </div>
                <Collapse style={{ marginRight: size.width * 0.06, marginLeft: size.width * 0.06, marginTop: size.height * 0.02 }}>
                    <Panel header="检索条件">
                        <Form
                            form={form1}
                            style={{
                                padding: 24,
                                background: '#fbfbfb',
                            }}
                            labelAlign='left'
                        >
                            <Row gutter={16}>
                                <Col span={5}>
                                    <Form.Item
                                        name="name"
                                        label="机构名字"
                                    >
                                        <Input
                                            placeholder="请输入机构名字"
                                            value={searchName}
                                            onChange={(e) => {
                                                setSearchName(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Button type="primary"
                                        onClick={() => {
                                            setPage(1)
                                            setTimeout(search(),2000)
                                        }}
                                        style={{ marginRight: "10px" }}>检索</Button>
                                    <Button type="primary" onClick={() => reset()} danger>重置</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
                <div style={{ marginTop: size.height * 0.025, marginLeft: size.width * 0.06, marginRight: size.width * 0.06 }}>
                    {/* 表单区域 */}
                    <Table
                        //表格列的配置描述
                        columns={columns}
                        //数据数组
                        dataSource={data}
                        pagination={paginationProps}
                        scroll={{ x:'80vw', y: `${size.width>1500?'65vh':'55vh'}` }}
                        //表格行 key 的取值
                        rowKey={'id'}
                    />
                </div>
            </Spin>

            {/* 明细Model */}
            <Modal
                title="查看明细"
                visible={detail}
                onCancel={closeDetail}
                footer={[]}
            >
                <Row align="middle" justify="center">
                    <Col>
                        <Button type="primary" onClick={() => { props.history.push({ 'pathname': '/app/kol/kol', 'state': { 'providerId': id } }) }}>查看KOL</Button>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="详细简介"
                visible={isSynopsis}
                onCancel={closeSynopsis}
                footer={[]}
            >
                <text>
                    {synopsis}
                </text>
            </Modal>

            <Modal
                title="联系方式"
                visible={isPhone}
                onCancel={closePhone}
                footer={[]}
            >
                <text>
                    {phone}
                </text>
            </Modal>

            {/* 增加机构 */}
            <Modal
                title="添加机构"
                visible={addProvider}
                onOk={addOk}
                onCancel={addCancel}
                centered={true}
                okText="确认添加"
                cancelText="取消添加"
            >
                <Form form={form} {...layout} labelAlign='left'>
                    <Form.Item label="机构名" name="organization">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写机构名"
                            placement="topLeft"
                        >
                            <Input
                                id="organization"
                                size="default"
                                placeholder="请填写机构名"
                                onChange={(e) => {
                                    setOrganization(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="联系人" name="path">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写联系人"
                            placement="topLeft"
                        >
                            <Input
                                id="path"
                                size="default"
                                placeholder="请填写联系人"
                                onChange={(e) => {
                                    setContacts(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="联系方式" name="phone">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写联系方式"
                            placement="topLeft"
                        >
                            <Input
                                id="phone"
                                size="default"
                                placeholder="请填写联系方式"
                                onChange={(e) => {
                                    setPhone(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="简介" name="synopsis">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写简介"
                            placement="topLeft"
                        >
                            <Input.TextArea
                                id="synopsis"
                                size="default"
                                placeholder="请填写简介"
                                onChange={(e) => {
                                    setSynopsis(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="合作品牌" name="trademark">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写合作品牌"
                            placement="topLeft"
                        >
                            <Input
                                id="trademark"
                                size="default"
                                placeholder="请填写合作品牌"
                                onChange={(e) => {
                                    setTrademark(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="资源优势" name="advantage">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写资源优势"
                            placement="topLeft"
                        >
                            <Input
                                id="advantage"
                                size="default"
                                placeholder="请填写资源优势"
                                onChange={(e) => {
                                    setAdvantage(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="备注" name="remark">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写备注"
                            placement="topLeft"
                        >
                            <Input
                                id="remark"
                                size="default"
                                placeholder="请填写备注"
                                onChange={(e) => {
                                    setRemark(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 修改机构 */}
            <Modal
                title="修改机构"
                visible={updateProvider}
                onOk={updateOk}
                onCancel={updateCancel}
                centered={true}
                okText="确认修改"
                cancelText="取消修改"
            >
                <Form form={form} {...layout} labelAlign='left'>
                    <Form.Item label="机构名" name="organization">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写机构名"
                            placement="topLeft"
                        >
                            <Input
                                id="organization"
                                size="default"
                                value={organization}
                                placeholder="请填写机构名"
                                onChange={(e) => {
                                    setOrganization(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="联系人" name="path">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写联系人"
                            placement="topLeft"
                        >
                            <Input
                                id="path"
                                size="default"
                                value={contacts}
                                placeholder="请填写联系人"
                                onChange={(e) => {
                                    setContacts(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="联系方式" name="phone">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写联系方式"
                            placement="topLeft"
                        >
                            <Input
                                id="phone"
                                size="default"
                                value={newPhone}
                                placeholder="请填写联系方式"
                                onChange={(e) => {
                                    setNewPhone(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="简介" name="synopsis">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写简介"
                            placement="topLeft"
                        >
                            <Input.TextArea
                                id="path"
                                size="synopsis"
                                value={synopsis}
                                placeholder="请填写简介"
                                onChange={(e) => {
                                    setSynopsis(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="合作品牌" name="trademark">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写合作品牌"
                            placement="topLeft"
                        >
                            <Input
                                id="trademark"
                                size="default"
                                placeholder="请填写合作品牌"
                                value={trademark}
                                onChange={(e) => {
                                    setTrademark(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="资源优势" name="advantage">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写资源优势"
                            placement="topLeft"
                        >
                            <Input
                                id="path"
                                size="advantage"
                                value={advantage}
                                placeholder="请填写资源优势"
                                onChange={(e) => {
                                    setAdvantage(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="备注" name="remark">
                        <Tooltip
                            trigger={['focus']}
                            title="请填写备注"
                            placement="topLeft"
                        >
                            <Input
                                id="remark"
                                size="default"
                                placeholder="请填写备注"
                                value={remark}
                                onChange={(e) => {
                                    setRemark(e.target.value)
                                }}
                            />
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    )
}

export default Provider