import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment'
import { Drawer, Row, Col, Button, Upload, message, Tag, DatePicker } from 'antd'
import { LeftOutlined, RightOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../../../../config/apiUrl'
import BraftEditor from 'braft-editor'
import locale from 'antd/es/date-picker/locale/zh_CN';
const dateFormat = 'YYYY';
function WeeklyReport(props) {


    // 富文本编辑器控件
    const extendControls = ['bold', 'italic', 'underline', 'text-color', 'separator', 'link',]

    // 设置编辑器初始内容
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''))

    // 数据
    const [date, setDate] = useState([])

    // id 
    const [id, setId] = useState('')

    // 年份
    const [year, setYear] = useState(moment().locale('zh-cn').format('YYYY'))

    // 今年
    const [nowYear] = useState(moment().locale('zh-cn').format('YYYY'))

    // 任务列表
    const taskList = ['11.27经销抖店直播', '11.27经销抖店直播商品选款']

    // 文档文件集合
    const [docList, setDocList] = useState([])

    // 文件名
    const [fileNameList, setFileNameList] = useState([])

    // 文件id
    const [fileIdList, setFileIdList] = useState([])

    // 第几周
    const [period, setPeriod] = useState(0)

    // 下标
    const [index, setIndex] = useState(-1)

    // 当前下标
    const [ currentIndex , setCurrentIndex] = useState(-1)

    // 选择的周数
    const [choosePeriod, setChoosePreiod] = useState(0)

    // 开始日期
    const [startTime, setStartTime] = useState('')

    // 结束日期
    const [endTime, setEndTime] = useState('')

    // 内容
    const [content, setContent] = useState('')

    // 周
    const [weekList, setWeekList] = useState([])

    // 修改标志
    const [flag, setFlag] = useState(0)

    // 打开周报
    const [openReport, setOpenReport] = useState(false)

    // 打开预览
    const [openPreview, setOpenPreview] = useState(false)

    // 打开过时的周末
    const [openOldReport, setOpenOldReport] = useState(false)

    // 按钮是否有效
    const [effective, setEffective] = useState(0)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    useEffect(() => {
        getOwnWeeklyReport()
    }, [])

    // 周期改变
    useEffect(() => {
        if (index != -1) {
            setIndex(index);
            setContent(date[index].content)
            setChoosePreiod(date[index].period)
            setStartTime(date[index].start_date)
            setEndTime(date[index].end_date)
            setId(date[index].id)
            getOwnWeeklyReportDetail(date[index].id)
        }
    }, [index])

    useEffect(() => {
        getOwnWeeklyReport()
    }, [year])

    // 获取个人周报
    const getOwnWeeklyReport = () => {
        let dataProps = {
            "year": year,
            "principal": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryOwnWeeklyReport,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data.success) {
                    setPeriod(res.data.data['week']);
                    setDate(res.data.data['data'])
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 关闭编写周报弹框
    const closeReport = () => {
        setIndex(-1)
        setFlag(0)
        setFileNameList([])
        setFileIdList([])
        setId('')
        setOpenReport(false)
    }

    // 关闭预览弹框
    const closePreview = () => {
        setIndex(-1)
        setFlag(0)
        setFileNameList([])
        setFileIdList([])
        setId('')
        setOpenPreview(false)
    }

    // 关闭老得周报
    const closeOldReport = () => {
        setIndex(-1)
        setFlag(0)
        setFileNameList([])
        setFileIdList([])
        setId('')
        setOpenOldReport(false)
    }

    // 编辑内容触发
    const editorChange = (editorState) => {
        setEditorState(editorState)
    }

    // 文档列表改变的时候出发
    const fileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'zip', 'rar', 'gif', 'jpg', 'jpeg', 'png', 'jpg', 'pptx', 'txt']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    setDocList(fileList)
                } else {
                    message.error('文件大小必须小于50M')
                }
            } else {
                message.error('文件类型不符合')
            }
        } else {
            setDocList(fileList)
        }
    }

    // 创建周报
    const createWeeklyReport = () => {
        setEffective(1)
        const formData = new FormData()
        // 多个文件
        docList.forEach((item) => {
            formData.append('file_list', item['originFileObj'])
        })
        // 店铺ID
        formData.append('shopId', cookie.load('shopList'))
        // 角色ID
        formData.append('roleId', cookie.load('role'))
        // 周报内容
        formData.append('content', editorState.toHTML())
        // 操作人
        formData.append('principal', cookie.load('name'))
        // 周报开始时间
        formData.append('start_date', startTime)
        // 周报结束时间
        formData.append('end_date', endTime)
        // 周报年份
        formData.append('year', year)
        // 周报周期
        formData.append('period', choosePeriod)
        axios({
            method: 'POST',
            url: servicePath.createWeeklyReport,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data['success']) {
                    message.success("周报提交成功")
                    setEditorState(BraftEditor.createEditorState(''))
                    setDocList([])
                    setStartTime('')
                    setEndTime('')
                    getOwnWeeklyReport()
                    setOpenReport(false)
                    setOpenPreview(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取个人周报详情
    const getOwnWeeklyReportDetail = (id) => {
        let dataProps = {
            "id": id,
            "principal": cookie.load('name'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            url: servicePath.queryOwnWeeklyReportDetail,
            data: dataProps,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                if (res.data.success) {
                    setFileNameList(res.data.data[0].file_name);
                    setFileIdList(res.data.data[0].file_uuid)
                    setOpenOldReport(true)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 更新周报
    const updateWeeklyReport = () => {
        setEffective(1)
        const formData = new FormData()
        // 多个文件
        docList.forEach((item) => {
            formData.append('file_list', item['originFileObj'])
        })
        // 店铺ID
        formData.append('shopId', cookie.load('shopList'))
        // 角色ID
        formData.append('roleId', cookie.load('role'))
        // 周报内容
        formData.append('content', editorState.toHTML())
        // 操作人
        formData.append('principal', cookie.load('name'))
        // 周报id
        formData.append('id', id)
        axios({
            method: 'POST',
            url: servicePath.updateWeeklyReport,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'authorization': cookie.load('token') }
        }).then(
            res => {
                setEffective(0)
                if (res.data['success']) {
                    message.success("周报更新成功")
                    setEditorState(BraftEditor.createEditorState(''))
                    setDocList([])
                    setStartTime('')
                    setEndTime('')
                    getOwnWeeklyReport()
                    setOpenReport(false)
                    setOpenPreview(false)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    return (
        <>
            <div style={{ marginBottom: '60px' }}>
                <div style={{ marginLeft: '20px' }}>
                    <DatePicker
                        locale={locale}
                        defaultValue={moment(year, dateFormat)}
                        value={moment(year, dateFormat)}
                        format={dateFormat}
                        onChange={(date, dateString) => {
                            if (dateString <= nowYear) {
                                setYear(dateString)
                            } else {
                                message.warn('请选择合理的年份')
                            }
                        }}
                        picker="year"
                    />
                </div>
                <Row>
                    {
                        date.map(function (item, index) {
                            return (
                                <Col span={6} onClick={() => {
                                    if (item.can_write) {
                                        if(currentIndex != index){
                                            setDocList([])
                                            setCurrentIndex(index)
                                            setEditorState(BraftEditor.createEditorState('<h3>本周工作总结</h3><p></p><p>1.</p><p>2.</p><p>3.</p><p></p><h3>存在问题</h3><p></p><p>1.</p><p>2.</p><p>3.</p><p></p><p></p><h3>下周工作计划</h3><p></p><p>1.</p><p>2.</p><p>3.</p><p></p><h3>需要支持</h3><p></p><p>1.</p><p>2.</p><p>3.</p><p></p><p></p>'))
                                            setChoosePreiod(item.period)
                                            setStartTime(item.start_date)
                                            setEndTime(item.end_date)
                                            setOpenReport(true)
                                        }else{
                                            setEditorState(BraftEditor.createEditorState(editorState))
                                            setChoosePreiod(item.period)
                                            setStartTime(item.start_date)
                                            setEndTime(item.end_date)
                                            setOpenReport(true)
                                        }
                                    } else {
                                        setIndex(index);
                                        setContent(item.content)
                                        setChoosePreiod(item.period)
                                        setStartTime(item.start_date)
                                        setEndTime(item.end_date)
                                        setId(item.id)
                                    }

                                }}>
                                    <div style={{
                                        height: '80px', width: size.width * 0.2 < 300 ? 300 : size.width * 0.2, border: item.period == period ? '1px solid orange' : '1px solid #ccc',
                                        margin: '20px', borderTop: item.period == period ? '3px solid orange' : '3px solid #6495ED'
                                    }}>
                                        {item.period == period ?
                                            <div style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold', fontSize: '20px' }}>本周(第{item.period}周)</div>
                                            : <div style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold', fontSize: '20px' }}>第{item.period}周</div>}
                                        <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                            <Row>
                                                <div style={{ color: '#ccc' }}>{item.start_date} - {item.end_date}</div>
                                                <div style={{ marginLeft: size.width * 0.04, color: item.period == period ? 'orange' : '#ccc', display: item.can_write ? '' : 'none' }}>写周报</div>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
            {/* 编写周报 */}
            <Drawer
                closable={false}
                onClose={closeReport}
                visible={openReport}
                width={900}
            >
                <Row>
                    <div style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '30px' }}>我的第{choosePeriod}周周报</div>
                    <div style={{ fontSize: '20px', fontWeight: "lighter", marginLeft: '20px', marginTop: '10px' }}>{startTime} - {endTime}</div>
                </Row>
                <div style={{ height: 500, border: '1px solid orange' }}>
                    <BraftEditor
                        contentStyle={{ height: 350 }}
                        value={editorState}
                        extendControls={extendControls}
                        onChange={editorChange}
                    />
                </div>
                <div>
                    {
                        fileNameList.map((item, index) => {
                            return <a target="_blank" href={servicePath.downloadWeeklyReportFile + '?principal=' + cookie.load('name') + '&file_uuid=' + fileIdList[index] + '&roleId=' + cookie.load('role') + '&shopId=' + cookie.load('shopList')}><Tag color="blue">{item}</Tag></a>
                        })
                    }
                </div>
                <div >
                    <Row>
                        <Col span={12}>
                            <Row>
                                一次最多上传5个文件:
                            <div >
                                    <Upload
                                        accept=".doc,.docx,.xlsx,.xls,.pdf,.zip,.rar,.txt,image/*"
                                        action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                                        listType="picture"                                 // 上传列表的内建样式
                                        fileList={docList}                                //已经上传的文件列表（受控）
                                        onChange={fileHandleChange}
                                    >
                                        {docList.length >= 5 ? null :
                                            <Button>
                                                <UploadOutlined /> 文件
                                    </Button>}

                                    </Upload>
                                </div>
                            </Row>
                        </Col>
                        {/* <Col span={12}>
                            本周完成任务:
                                {
                                taskList.map(item => {
                                    return (
                                        <div>
                                            <a style={{ marginLeft: '50px' }}>{item}</a>
                                        </div>
                                    )
                                })
                            }
                        </Col> */}
                    </Row>
                </div>
                <div>
                    <Row>
                        {
                            flag == 0
                                ? <Button style={{ backgroundColor: 'orange', color: 'white', border: '1px solid orange' }} size="large" disabled={effective == 1 ? true : false} onClick={() => createWeeklyReport()}>提交周报</Button>
                                : <Button style={{ backgroundColor: 'orange', color: 'white', border: '1px solid orange' }} size="large" disabled={effective == 1 ? true : false} onClick={() => updateWeeklyReport()}>修改周报</Button>
                        }
                        <Button style={{ backgroundColor: 'white', color: 'orange', marginLeft: '30px', border: '1px solid orange' }} size="large" onClick={() => { setOpenReport(false); setOpenPreview(true) }}>预览</Button>
                        <div style={{ display: choosePeriod == period ? 'none' : '', color: 'red', lineHeight: '50px', marginLeft: '20px' }}>非本周的周报提交后无法更改</div>
                    </Row>
                </div>
            </Drawer>

            {/* 预览弹框 */}
            <Drawer
                closable={false}
                onClose={closePreview}
                visible={openPreview}
                width={900}
            >
                <Row>
                    <div style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '30px' }}>我的第{choosePeriod}周周报</div>
                    <div style={{ fontSize: '20px', fontWeight: "lighter", marginLeft: '20px', marginTop: '10px' }}>{startTime} - {endTime}</div>
                </Row>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: editorState.toHTML() }}></div>
                </div>
                <div>
                    {
                        fileNameList.map((item, index) => {
                            return <a target="_blank" href={servicePath.downloadWeeklyReportFile + '?principal=' + cookie.load('name') + '&file_uuid=' + fileIdList[index] + '&roleId=' + cookie.load('role') + '&shopId=' + cookie.load('shopList')}><Tag color="blue">{item}</Tag></a>
                        })
                    }
                </div>
                <div >
                    <Row>
                        <Col span={12}>
                            <Row>
                                一次最多上传5个文件:
                                <div>
                                    <Upload
                                        accept=".doc,.docx,.xlsx,.xls,.pdf,.zip,.rar,.txt,image/*"
                                        action={servicePath.image}                         //上传地址，填错/不填上传图片的时候都会报错
                                        listType="picture"                                 // 上传列表的内建样式
                                        fileList={docList}                                //已经上传的文件列表（受控）
                                        onChange={fileHandleChange}
                                    >
                                        {docList.length >= 5 ? null :
                                            <Button>
                                                <UploadOutlined /> 文件
                                    </Button>}

                                    </Upload>
                                </div>
                            </Row>
                        </Col>
                        {/* <Col span={12}>
                            本周完成任务:
                                {
                                taskList.map(item => {
                                    return (
                                        <div>
                                            <a style={{ marginLeft: '50px' }}>{item}</a>
                                        </div>
                                    )
                                })
                            }
                        </Col> */}
                    </Row>
                </div>
                <div>
                    <Row>
                        {
                            flag == 0
                                ? <Button style={{ backgroundColor: 'orange', color: 'white', border: '1px solid orange' }} size="large" disabled={effective == 1 ? true : false} onClick={() => createWeeklyReport()}>提交周报</Button>
                                : <Button style={{ backgroundColor: 'orange', color: 'white', border: '1px solid orange' }} size="large" disabled={effective == 1 ? true : false} onClick={() => updateWeeklyReport()}>修改周报</Button>
                        }
                        <Button style={{ backgroundColor: 'white', color: 'orange', marginLeft: '30px', border: '1px solid orange' }} size="large" onClick={() => { setOpenReport(true); setOpenPreview(false) }}>取消预览</Button>
                        <div style={{ display: choosePeriod == period ? 'none' : '', color: 'red', lineHeight: '50px', marginLeft: '20px' }}>非本周的周报提交后无法更改</div>
                    </Row>
                </div>
            </Drawer>

            {/* 看老的周报 */}
            <Drawer
                closable={false}
                onClose={closeOldReport}
                visible={openOldReport}
                width={900}
            >
                <Row>
                    <Col>
                        <div style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '30px' }}>{cookie.load('name')}的第{choosePeriod}周周报</div>
                        <Row>
                            <Col>
                                <div style={{ fontSize: '15px', fontWeight: "lighter", marginLeft: '20px', marginTop: '10px' }}>{startTime} - {endTime}</div>
                            </Col>
                            <Col>
                                {
                                    index != -1 ? <div style={{ marginTop: '10px' }}>
                                        <a style={{ marginLeft: '20px', marginTop: '10px', color: '#6495ED', display: date[index + 1] == null ? 'none' : date[index + 1]['can_write'] == 0 ? '' : 'none' }} onClick={() => { setIndex(index + 1) }}><i><LeftOutlined /><span>上一周</span></i></a>
                                        <a style={{ marginLeft: '20px', marginTop: '10px', color: '#6495ED', display: date[index - 1] == null ? 'none' : date[index - 1]['can_write'] == 0 ? '' : 'none' }} onClick={() => { setIndex(index - 1) }}><span>下一周</span><i><RightOutlined /></i></a>
                                    </div> : <div></div>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <div style={{ marginLeft: '340px', marginTop: '20px' }}>
                            <Button icon={<EditOutlined />} type="primary" disabled={choosePeriod == period ? false : true} onClick={() => { setEditorState(BraftEditor.createEditorState(content)); setOpenOldReport(false); setOpenReport(true); setFlag(1) }}>修改</Button>
                            {
                                choosePeriod == period ?
                                    <div style={{ marginTop: '10px', color: '#ccc' }}>{endTime}后将不可修改</div> :
                                    <div style={{ marginTop: '10px', color: '#ccc' }}>只能修改本周的周报</div>
                            }
                        </div>
                    </Col>
                </Row>
                <div style={{ marginTop: '30px' }}>
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
                <div>
                    {
                        fileNameList.map((item, index) => {
                            return <a target="_blank" href={servicePath.downloadWeeklyReportFile + '?principal=' + cookie.load('name') + '&file_uuid=' + fileIdList[index] + '&roleId=' + cookie.load('role') + '&shopId=' + cookie.load('shopList')}><Tag color="blue">{item}</Tag></a>
                        })
                    }
                </div>
            </Drawer>
        </>
    )

}


export default WeeklyReport