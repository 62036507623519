import React, { useState } from 'react';
import { Tabs } from 'antd'
import DailyReport from './daily/dailyReport'
import MemberReport from './daily/member'
const { TabPane } = Tabs;

function DailyReports(props) {
    // 当前tab值
    const [curTabKey, setCurTabKey] = useState('1')
    
    // 切换tab
    const changeTabs = (activeKey) => {
        setCurTabKey(activeKey)
    }

    return (
        <>
            <Tabs style={{ marginLeft: '10px' }} defaultActiveKey={curTabKey} tabPosition="top" onChange={changeTabs}>
                <TabPane tab="我的" key="1">
                    <DailyReport history={props.history} />
                </TabPane>
                <TabPane tab="成员" key="2">
                    <MemberReport history={props.history} />
                </TabPane>
            </Tabs>
        </>
    )


}

export default DailyReports