import React, { useState, useEffect, useCallback } from 'react';
import { Table, DatePicker, Row, Button, Breadcrumb, message, Select, Tag } from 'antd'
import moment from 'moment'
import axios from 'axios'
import servicePath from '../../config/apiUrl'
import cookie from 'react-cookies'
import '../../static/css/president/president.css'
const { Option } = Select;
const { Column } = Table
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD';

function Daily(props) {

    // 计算两个日期之间的日期
    function getBetweenDateStr(start, end) {
        let result = [];
        let beginDay = start.split("-");
        let endDay = end.split("-");
        let diffDay = new Date();
        let dateList = new Array;
        let i = 0;
        diffDay.setDate(beginDay[2]);
        diffDay.setMonth(beginDay[1] - 1);
        diffDay.setFullYear(beginDay[0]);
        let startDay = start.substring(5, start.size)
        result.push(startDay);
        while (i == 0) {
            var countDay = diffDay.getTime() + 24 * 60 * 60 * 1000;
            diffDay.setTime(countDay);
            dateList[2] = diffDay.getDate();
            dateList[1] = diffDay.getMonth() + 1;
            dateList[0] = diffDay.getFullYear();
            if (String(dateList[1]).length == 1) { dateList[1] = "0" + dateList[1] };
            if (String(dateList[2]).length == 1) { dateList[2] = "0" + dateList[2] };
            result.push(dateList[1] + "-" + dateList[2]);
            if (dateList[0] == endDay[0] && dateList[1] == endDay[1] && dateList[2] == endDay[2]) {
                i = 1;
            }
        };
        result.reverse()
        return result;
    }

    // 品牌字典
    const trademarkDict = {
        'cr': '卡洛琳',
        'joomilim': 'joomilim',
        'billymargot': 'billymargot',
        'ivorycoat': 'ivorycoat',
        'airinum': 'airinum',
        'zhuoya': 'JORYA',
        'freiol': '福来',
        'iv': '常春藤',
        'qda': 'QDA',
        'an': '恩曼琳',
        'xh': '经销',
        'z11': 'Z11',
        'vgrass': 'vgrass'
    }

    // 店铺字典
    const storeDict = {
        '':[
            {
                'name':'请选择店铺',
                'value':''
            }
        ],
        'ivorycoat':[
            {
                'name':'ivorycoat海外旗舰店',
                'value':'ivorycoat'
            }
        ],
        'zhuoya':[
            {
                'name':'天猫',
                'value':'jr_tmall'
            },
            // {
            //     'name':'京东',
            //     'value':'jr_JD'
            // }
        ],
        'an':[
            {
                'name':'天猫',
                'value':'an_tmall'
            }
        ],
        'qda':[
            {
                'name':'天猫',
                'value':'qda_tmall'
            } 
        ],
        'joomilim':[
            {
                'name':'天猫',
                'value':'joomilim_tmall'
            }
        ],
        'iv':[
            {
                'name':'天猫',
                'value':'iv_tmall'
            }
        ],
        'freiol':[
            {
                'name':'freiol旗舰店',
                'value':'freiol_tm'
            },
            {
                'name':'freiol福来官方品牌店',
                'value':'freiol'
            },
            {
                'name':'FREIOL海外旗舰店',
                'value':'freiol_tmgj'
            },
        ],
        'xh':[
            {
                'name':'欣贺女装outlets天猫店',
                'value':'xh_outlets'
            },
            {
                'name':'欣贺女装outlets淘宝店',
                'value':'xh_outlets_c'
            },
            {
                'name':'LY高端精选女装店',
                'value':'likeforlike'
            }
        ],
        'z11':[
            {
                'name':'Z11天猫店',
                'value':'Z11_tmall'
            },
            {
                'name':'Z11官方企业店',
                'value':'Z11_c'
            }
        ],
        'cr':[
            {
                'name':'卡洛琳天猫店',
                'value':'cr_tmall'
            }
        ],
        'airinum':[
            {
                'name':'airinum旗舰店',
                'value':'airinum'
            }
        ],
        'billymargot':[
            {
                'name':'billymargot海外旗舰店',
                'value':'billymargot'
            }
        ],
        'vgrass':[
            {
                'name':'vgrass女装旗舰店',
                'value':'vgrass'
            }
        ]

    }

    // 表格数据
    const [tableData, setTableData ] = useState([])

    // 品牌名称
    const [trademark, setTrademark] = useState('')

    // 店铺集合
    const [ shopList , setShopList ] = useState([])

    // 店铺
    const [ shop , setShop ] = useState('')

    // 店铺名字
    const [ shopName , setShopName ] = useState('')

    // 表头集合
    const [columnList, setColumnList] = useState([])

    // 结束时间 默认是今天的前一天
    const [endDate, setEndDate] = useState(moment().subtract(1, 'days').locale('zh-cn').format('YYYY-MM-DD'))

    // 开始时间 默认是今天的前一天的前三十天
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').locale('zh-cn').format('YYYY-MM-DD'))

    // 隐藏列表
    const [hideList] = useState(['支付完成率(%)','回款完成率(%)','支付目标','回款目标'])

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 获取品牌名
    useEffect(() => {
        let urlPath = props.location.pathname
        let urlList = urlPath.split('/')
        setTrademark(urlList[3])
    }, [])

    // 获取店铺
    useEffect(()=>{
        setShopList(storeDict[trademark])
        setShop(storeDict[trademark][0]['value'])
        setShopName(storeDict[trademark][0]['name'])
    },[trademark])


    // 计算两个日期之间的月日 构造表头
    useEffect(() => {
        setColumnList(getBetweenDateStr(startDate, endDate))
        let data = {}
        if (trademark == '') {
            let urlPath = props.location.pathname
            let urlList = urlPath.split('/')
            setTrademark(urlList[3])
            data.shop = storeDict[urlList[3]][0]['value']
            data.startTime = startDate
            data.endTime = endDate
            data.roleId = cookie.load('role')
            data.shopList = cookie.load('shopList')
        } else {
            data.shop = shop
            data.startTime = startDate
            data.endTime = endDate
            data.roleId = cookie.load('role')
            data.shopList = cookie.load('shopList')
        }
        axios({
            data: data,
            method: 'POST',
            url: servicePath.getPresidentReport,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res=>{
                if (res.data.success){
                    setTableData(res.data.data['data_dict_table'])
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg']);
                    }
                }
            }
        )
    }, [startDate, endDate])

    // 日期切换函数
    function onChange(value, dateString) {
        setStartDate(dateString[0]);
        setEndDate(dateString[1])
    }

    // 近三十天
    const thirty = () => {
        setStartDate(moment().subtract(30, 'days').locale('zh-cn').format('YYYY-MM-DD'))
        setEndDate(moment().subtract(1, 'days').locale('zh-cn').format('YYYY-MM-DD'))
    }

    // 近六十天
    const sixty = () => {
        setStartDate(moment().subtract(60, 'days').locale('zh-cn').format('YYYY-MM-DD'))
        setEndDate(moment().subtract(1, 'days').locale('zh-cn').format('YYYY-MM-DD'))
    }

    // 近九十天
    const ninety = () => {
        setStartDate(moment().subtract(90, 'days').locale('zh-cn').format('YYYY-MM-DD'))
        setEndDate(moment().subtract(1, 'days').locale('zh-cn').format('YYYY-MM-DD'))
    }

    // 查看图标的函数
    const lookCharts = ()=>{
        props.history.push({ 'pathname': '/app/president/DaliyCharts', 'state': { 'trademark': trademark}})
    }

    // 切换店铺
    const changeShop = (value)=>{
        // 切换店铺的值
        setShop(value)
        // 修改店铺名称
        setShopName(shopList[shopList.map(item => item.value).indexOf(value)]['name']);
        // 因切换店铺发起请求
        let data = {}
        data.shop = value
        data.startTime = startDate
        data.endTime = endDate
        data.roleId = cookie.load('role')
        data.shopList = cookie.load('shopList')
        axios({
            data: data,
            method: 'POST',
            url: servicePath.getPresidentReport,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res=>{
                if (res.data.success){
                    setTableData(res.data.data['data_dict_table'])
                }else{
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg']);
                    }
                }
            }
        )
    }
    
    return (
        <div>
            <div style={{ marginLeft: size.width * 0.02, marginRight: size.width * 0.02, marginTop: size.height * 0.02 }}>
                <Row style={{marginTop: size.height * 0.02}}>
                    <Select style={{  width: 190,marginLeft:'15px', marginRight:'10px' }} value={shop} onChange={changeShop}>
                        {/* <Option value="jack">Jack</Option> */}
                        {
                            shopList.map(function(item){
                                return (
                                    <Option value={item.value}>{item.name}</Option>
                                )
                            })
                        }
                    </Select>
                    <RangePicker
                        style={{ marginRight: '10px'}}
                        value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                        defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                        onChange={onChange} />

                    <Button type="primary" style={{ marginRight: '10px' }} onClick={() => thirty()}>近三十天</Button>
                    <Button type="primary" style={{ marginRight: '10px' }} onClick={() => sixty()}>近六十天</Button>
                    <Button type="primary" style={{ marginRight: '10px' }} onClick={() => ninety()}>近九十天</Button>
                    <Button style={{ marginRight: '10px' }} onClick={lookCharts}>查看图表</Button>
                    {/* <div style={{color:'red', fontSize:'20px', fontWeight:'bold'}}>红色数字为汇总值</div> */}
                    <Tag color="error" style={{fontSize:'15px', fontWeight:'bold', height:'35px', lineHeight:'35px'}}>红色数字为汇总值</Tag>
                </Row>
                <Table
                    scroll={{ x: 3000 }}
                    style={{ marginTop: size.height * 0.02 }}
                    // 数据源
                    dataSource={tableData}
                    // 不进行分页处理
                    pagination = {false}
                    scroll={{ y: `${size.width>1500?'90vh':'70vh'}` }}
                    rowClassName={(record, index) => {
                        return 'zoncai-row';
                    }}
                >

                    <Column key='item' dataIndex="item" align="center"
                            title='数据项' width={150} fixed={'left'} 
                            onCell={function(record, rowIndex){
                                return (
                                    {
                                        style: {
                                            padding: '0',
                                            height:'35px',
                                            // display: record[columnList[1]]==null ? 'none':''
                                        },
                                    }
                                )
                            }}/>
                    {
                        columnList.map(item => {
                            return (<Column align="center" key={item} width={200} 
                                            title={item} style={{height:'10px', }} 
                                            dataIndex={item} 
                                            onCell={function(record, rowIndex){
                                                return (
                                                    {
                                                        style: {
                                                            padding: '0',
                                                            height:'35px',
                                                            color: rowIndex > 9 ? 'red' : 'black',
                                                            // display: record[columnList[1]]==null ? 'none':''
                                                        },
                                                    }
                                                )
                                            }}
                                    />)
                        })
                    }
                </Table>

            </div>
        </div>
    )

}

export default Daily