import React, {useContext, useState, useEffect, memo, useMemo} from 'react';
import {
    Table,
    Row,
    Cascader,
    InputNumber,
    Col,
    Select,
    BackTop,
    Form,
    Radio,
    Checkbox,
    Tabs,
    Input,
    DatePicker,
    Typography,
    Tooltip,
    Button,
    message,
    Spin
} from 'antd';
import {CloudDownloadOutlined, SearchOutlined, RightOutlined, SyncOutlined, LeftOutlined} from '@ant-design/icons';
import style from './style.module.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ADD_GOODS_LIST, ADD_SHOP_GOODS_DATA, DELETE_SHOP_GOODS_DATA, GoodsContext } from './store/itemInfoShare'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import NbAxios from '../../config/utils/nbAxios';
import cookie from 'react-cookies';
import GoodsChart from './reportModel';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import Axios from 'axios'
import servicePath from '../../config/apiUrl';
import downExcel from '../../config/utils/downloadFile'
const { Title, Paragraph, Text, Link } = Typography;
const { RangePicker } = DatePicker;
const {Option} = Select;
const {TabPane} = Tabs;
function GoodsReport(props) {
    const [form1] = Form.useForm();
    const layout = {
        labelCol: {span: 5},
        wrapperCol: {span: 19},
    };
    // 获取状态管理中的值
    const {state, dispatch} = useContext(GoodsContext)
    // 判断组件是否第一次加载
    const [first, setFirst] = useState(true)
    // 已经请求过的key
    const [tabKeyList, setTabKeyList] = useState([])
    // 标签key
    const [tabKey, setTabKey] = useState('product_line')
    // 店铺id对应店铺名
    // const shopDict = {
    //     'aecc4073-4fc8-41c7-9d6a-e37b69947c3e': 'jr',
    //     'd4867ead-6724-4e15-b941-77be33fb6b9f': 'z11',
    //     '2715f3fa-0dae-4dc7-bcfc-0dd912314e40': 'qda',
    //     '7f1324e7-0d75-4d94-bace-911d5fbd4727': 'an',
    //     'f115bbea-bb18-439e-bda8-8d1e26719fee': 'cr',
    //     'fc84f6bf-1e5f-4566-a49c-cad309658dbc': 'vgrass',
    //     '773a99f1-d1e0-4d36-aeda-62be6680f73a': 'xhoutlets',
    //     '444b93cc-a5d0-4886-a4b2-d5f8de27e945': 'ein'
    // }
    // 获取店铺名
    const shopName = props.location.pathname.split('/')[3]
    // const shopName = 'vgrass'
    // 条件列表
    const [conditions, setConditions] = useState([])
    // 条件及条件值
    const [conditionDict, setConditionDict] = useState({})
    // 产品线表格数据
    const [proData, setProData] = useState([])
    const [proX, setProX] = useState([])
    const [proBar, setProBar] = useState([])
    const [proLine, setProLine] = useState([])
    // 季节线表格数据
    const [seasonData, setSeasonData] = useState([])
    const [seasonX, setSeasonX] = useState([])
    const [seasonBar, setSeasonBar] = useState([])
    const [seasonLine, setSeasonLine] = useState([])
    // 品类线表格数据
    const [kindData, setKindData] = useState([])
    const [kindX, setKindX] = useState([])
    const [kindBar, setKindBar] = useState([])
    const [kindLine, setKindLine] = useState([])
    // 价格线表格数据
    const [priceData, setPriceData] = useState([])
    const [priceX, setPriceX] = useState([])
    const [priceBar, setPriceBar] = useState([])
    const [priceLine, setPriceLine] = useState([])
    // 自定义类目数据
    const [kind, setKind] = useState([])
    // 自定义产品线数据
    const [product, setProduct] = useState([])
    // 自定义季节数据
    const [season, setSeason] = useState([])
    // 自定义年份数据
    const [year, setYear] = useState([])
    // 自定义类目数据全选状态
    const [kindAll, setKindAll] = useState(false)
    // 自定义产品线数据
    const [productAll, setProductAll] = useState(false)
    // 自定义季节数据
    const [seasonAll, setSeasonAll] = useState(false)
    // 自定义年份数据
    const [yearAll, setYearAll] = useState(false)
    // 自定义年份数据
    const [yearCheckAll, setYearCheckAll] = useState(false)

    // 自定义数据
    const [customData, setCustomData] = useState([])
    // 自定义表格数据加载状态
    const [customLoading, setCustomLoading] = useState(false)
    // 价格带
    const [priceInterval, setPriceInterval] = useState(500)
    // 下载按钮的状态
    const [downStatus, setDownStatus] = useState(false)
    // 选择的周期类型
    const [dateType, setDateType] = useState('month')
    // 开始日期
    const [startDate, setStartDate] = useState((first && state['shop_table_data'][shopName] && state['shop_table_data'][shopName].start_time) || moment().startOf(dateType))
    // 结束日期  
    const [endDate, setEndDate] = useState((first && state['shop_table_data'][shopName] && state['shop_table_data'][shopName].end_time) || moment().endOf(dateType))
    // 同比类型
    const [compareType, setCompareType] = useState("year")
    // 页码
    const [page, setPage] = useState(1)
    // 页面长度
    const [pageSize, setPageSize] = useState(10)
    // 数据总长度
    const [total, setTotal] = useState(10)
    // 表格状态
    const [tableLoading, setTableLoading] = useState(false)
    // 选择分析的字段
    const [checkFiled, setCheckFiled] = useState('年份')
    // 自定义条件 
    const options = [
        { label: '产品线', value: "product_line" },
        { label: '季节', value: 'season' },
        { label: '品类', value: 'category2' },
        { label: '年份', value: 'year' },
        { label: '价格带', value: 'price_zone' },
    ];
    // 排序标志位
    const [sort, setSort] = useState('DESC')
    // 需要排序的字段
    const [sortField, setSortField] = useState('pay_money')
    //定义分页器的参数
    const paginationProps = {
        showSizeChanger: false,
        showQuickJumper: true,
        // 总页数
        total: total,
        //页码改变时，设置当前页码和每页显示条数
        current: page,
        onChange: (page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
        },
    }
    const colNameList = [
        {
            title: '动销款数',
            key: "pay_skus",
            width: 120,
            sorter: true
        },
        {
            title: '单款贡献',
            key: "sku_contribution",
            width: 120,
            sorter: true
        },
        {
            title: '款数%',
            key: "pay_sku_rate",
            width: 150,
            sorter: true,
            showPercent: true
        },
        {
            title: '销量',
            key: "pay_nums",
            width: 100,
            sorter: true
        },
        {
            title: '销售额',
            key: "pay_money",
            width: 100,
            sorter: true
        },
        {
            title: '销售额%',
            key: "money_rate",
            width: 110,
            sorter: true,
            showPercent: true
        },
        {
            title: '件单价',
            key: "unit_price",
            width: 100,
            sorter: true
        },
        {
            title: '折扣%',
            key: "discount",
            width: 100,
            sorter: true,
            showPercent: true
        }
    ]
    const proColumns = [
        {
            title: '产品线',
            dataIndex: 'product_line',
            key: 'product_line',
            align: 'center',
            ellipsis: true,
            fixed: "left",
            onHeaderCell: onHeaderCell,
            width: 200
        },
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                align: 'center',
                sorter: (a, b) => {
                    let sort_a = parseFloat(a[item.key] || 0)
                    let sort_b = parseFloat(b[item.key] || 0)
                    return sort_a - sort_b
                },
                onHeaderCell: onHeaderCell,
                width: item.width,
                render: (cell, index, record) => {
                    if (cell !== null) {
                        if (item.showPercent) {
                            return `${cell}%`
                        }
                        return cell
                    } else {
                        return '-'
                    }
                }
            }
        }),
        {
            title: '查看',
            dataIndex: 'action',
            fixed: "right",
            key: 'action',
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100,
            render: (item, record, index) => {
                let { product_line } = record
                let { field } = record
                // return <Button type="link" onClick={() => getGoodsIdList({ "product_line": product_line })} >
                //     {product_line === "基础信息缺失" ? '导出' : '详情'}
                // </Button>
                return product_line === '基础信息缺失'?
                <Button type="link" onClick={() => getGoodsIdList({ "product_line": product_line })} >
                    导出
                </Button>:<Button type="link"  onClick={()=>lookDetails({ "product_line": field })}>
                    详情
                </Button>
            }
        }
    ]
    const seasonColumns = [
        {
            title: '季节线',
            dataIndex: 'season',
            key: 'season',
            fixed: "left",
            align: 'center',
            ellipsis: true,
            width: 100,
            onHeaderCell: onHeaderCell
        },
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                align: 'center',
                width: item.width,
                sorter: (a, b) => {
                    let sort_a = parseFloat(a[item.key] || 0)
                    let sort_b = parseFloat(b[item.key] || 0)
                    return sort_a - sort_b
                },
                onHeaderCell: onHeaderCell,
                render: (cell, index, record) => {
                    if (cell !== null) {
                        if (item.showPercent) {
                            return `${cell}%`
                        }
                        return cell
                    } else {
                        return '-'
                    }
                }
            }
        }),
        {
            title: '查看',
            dataIndex: 'action',
            fixed: "right",
            key: 'action',
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100,
            render: (item, record, index) => {
                let { season } = record
                // return <Button type="link" onClick={() => getGoodsIdList({ "season": season })} >
                //     {season === "基础信息缺失" ? '导出' : '详情'}
                // </Button>
                return season === '基础信息缺失'?
                <Button type="link" onClick={() => getGoodsIdList({ "season": season })} >
                    导出
                </Button>:<Button type="link"  onClick={()=>lookDetails({ "season": season })}>
                    详情
                </Button>
            }
        }
    ]
    const kindColumns = [
        {
            title: '品类线',
            dataIndex: 'category2',
            key: 'category2',
            fixed: "left",
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100
        },
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                align: 'center',
                onHeaderCell: onHeaderCell,
                sorter: (a, b) => {
                    let sort_a = parseFloat(a[item.key] || 0)
                    let sort_b = parseFloat(b[item.key] || 0)
                    return sort_a - sort_b
                },
                width: item.width,
                render: (cell, index, record) => {
                    if (cell !== null) {
                        if (item.showPercent) {
                            return `${cell}%`
                        }
                        return cell
                    } else {
                        return '-'
                    }
                }
            }
        }),
        {
            title: '查看',
            dataIndex: 'action',
            fixed: "right",
            key: 'action',
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100,
            render: (item, record, index) => {
                let { category2 } = record
                // return <Button type="link" onClick={() => getGoodsIdList({ "category2": category2 })} >
                //     {category2 === "基础信息缺失" ? '导出' : '详情'}
                // </Button>
                return category2 === '基础信息缺失'?
                <Button type="link" onClick={() => getGoodsIdList({ "category2": category2 })} >
                    导出
                </Button>:<Button type="link"  onClick={()=>lookDetails({ "category2": category2 })}>
                    详情
                </Button>
            }
        }
    ]
    const priceColumns = [
        {
            title: 'TOP款',
            dataIndex: 'price',
            fixed: "left",
            key: 'price',
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100
        },
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                align: 'center',
                sorter: (a, b) => {
                    let sort_a = parseFloat(a[item.key] || 0)
                    let sort_b = parseFloat(b[item.key] || 0)
                    return sort_a - sort_b
                },
                onHeaderCell: onHeaderCell,
                width: item.width,
                render: (cell, index, record) => {
                    if (cell !== null) {
                        if (item.showPercent) {
                            return `${cell}%`
                        }
                        return cell
                    } else {
                        return '-'
                    }
                }
            }
        }),
        {
            title: '查看',
            dataIndex: 'action',
            fixed: "right",
            key: 'action',
            align: 'center',
            ellipsis: true,
            onHeaderCell: onHeaderCell,
            width: 100,
            render: (item, record, index) => {
                let { price } = record
                // return <Button type="link" onClick={() => getGoodsIdList({ "price": price })} >
                //     {price === "基础信息缺失" ? '导出' : '详情'}
                // </Button>
                return price === '基础信息缺失'?
                <Button type="link" onClick={() => getGoodsIdList({ "price": price })} >
                    导出
                </Button>:<Button type="link"  onClick={()=>{
                    let { id_list } = record
                    props.history.push({'pathname':`/app/SaleBorder/${shopName}`,'state':{'idList':id_list,"start_date":startDate.format('YYYY-MM-DD'),"end_date":endDate.format('YYYY-MM-DD')}})
                }}>
                    详情
                </Button>
            }
        }
    ]
    const customColumns = [
        ...conditions.map((item, index, arr) => {
            return {
                title: options.filter(option => option.value == item)[0].label,
                dataIndex: item,
                key: item,
                align: 'center',
                ellipsis: true,
                onHeaderCell: onHeaderCell,
                width: 100
            }
        })
        ,
        ...colNameList.map((item, index) => {
            return {
                title: item.title,
                dataIndex: item.key,
                key: item.key,
                align: 'center',
                sorter: (a, b) => {
                    if (item.key == "sku_contribution") {
                        return (a.pay_money / a.pay_skus).toFixed(0) - (b.pay_money / b.pay_skus).toFixed(0)
                    } else {
                        return a[item.key] - b[item.key]
                    }
                },
                // sortOrder: item.key === sortField && changeSortField(item.key),
                onHeaderCell: onHeaderCell,
                width: item.width,
                render: (content, record, index) => {
                    if (item.key == "sku_contribution") {
                        return (record.pay_money / record.pay_skus).toFixed(0)
                    } else {
                        if (content !== null) {
                            if (item.showPercent) {
                                return `${content}%`
                            }
                            return content
                        } else {
                            return '-'
                        }
                    }
                }
            }
        })
    ]

    // 显示当前自定义条件的jsx
    const fileds = condition => <span className={style.condition}>{options.filter(item => item.value == condition)[0].label}</span>

    // 表格上的操作区域
    const header = (
        <div className={style.tableControll} >
            <div className={style.leftControll} >
                <CopyToClipboard text={JSON.parse(JSON.stringify(priceColumns.reduce((pre, now) => pre + `${pre === "" ? "" : "\t\t"}` + now.title, "")))} >
                    <Title level={5} style={{ margin: "0 10px 0 0" }} >复制表格数据</Title>
                </CopyToClipboard>
            </div>
        </div>
    )

    useEffect(() => {
        let a = ['product_line', 'season', 'category', 'price']
        // 第一次加载组件去状态管理中获取数据，如果没有则向后台请求
        if (first && state['shop_table_data'][shopName]) {
            let shopData = state['shop_table_data'][shopName]
            let season = shopData['season']
            let category = shopData['category']
            let price = shopData['price']
            let product_line = shopData['product_line']
            // 季节线数据
            setSeasonData(season.data)
            setSeasonX(season.x)
            setSeasonLine(season.line)
            setSeasonBar(season.bar)
            // 类目线数据
            setKindData(category.data)
            setKindX(category.x)
            setKindLine(category.line)
            setKindBar(category.bar)
            // 产品线数据
            setProData(product_line.data)
            setProX(product_line.x)
            setProLine(product_line.line)
            setProBar(product_line.bar)
            // 价格带线数据
            setPriceData(price.data)
            setPriceX(price.x)
            setPriceBar(price.bar)
            setPriceLine(price.line)
            dispatch({ type: DELETE_SHOP_GOODS_DATA, shopName })
        } else {
            setSeasonData([])
            setSeasonX([])
            setSeasonLine([])
            setSeasonBar([])
            setPriceX([])
            setPriceBar([])
            setPriceData([])
            setPriceLine([])
            setProData([])
            setProX([])
            setProBar([])
            setProLine([])
            setKindX([])
            setKindBar([])
            setKindLine([])
            setKindData([])
            setTabKeyList([])
            tabKeyList.push('product_line')
            // // 创建promise任务列表
            // let promiseList = a.map((kind, index, arr) => {
            //     return getTableDataByType(kind)
            // })
            // // 并发获取数据，.then在所有promise状态位fullfilled时执行，使用catch捕获请求出错的情况
            // Promise.all(promiseList).then(() => setTableLoading(false)).catch(e=>{
            //     setTableLoading(false)
            // })
        }
        // 第一次加载完成后，将标志位职位false
        first && setFirst(false)
        // getTableDataByType('season').then(
        //     getTableDataByType('price')
        // ).then(
        //     getTableDataByType('product_line')
        // ).then(
        //     getTableDataByType('category')
        // ).then(() => setTableLoading(false)).catch(()=>{
        // })
    }, [startDate, endDate, compareType, shopName])

    useEffect(() => {
        if (tabKeyList.indexOf(tabKey) == -1) {
            tabKeyList.push(tabKey)
            setTableLoading(true)
            if (tabKey != 'price') {
                getTableDataByType(tabKey)
            } else {
                getTopData()
            }
        }
    }, [tabKey, tabKeyList])


    useEffect(() => {
        // getCustomTableData(conditions)
    }, [startDate, endDate, shopName, sortField, sort])

    useEffect(() => {
        // 第一次加载获取可选条件
        getCustomConditions()
    }, [])
    return (
        <div className={style.goodsIndexWapper} >
            <div className={style.DataArea}>
                <BackTop visibilityHeight={10} />
                <div className={style.controlBar}>
                    <div className={style.controlBarLeft}>
                        <Title level={2} style={{margin: 0}}>{shopName == 'jr' ? 'JORYA' : shopName}商品数据统计看板</Title>
                    </div>
                    <div className={style.controlBarRight}>
                        {/* <Text>自定义价格带：</Text>
                        <InputNumber className={style.priceZone} size="small" style={{ height: 28, width: 70 }} max={2000} min={10} prefix="自定义价格带" placeholder="价格带-默认500" defaultValue={priceInterval} step={100} onPressEnter={priceChange} /> */}
                        <RangePicker
                            ranges={{
                                '今天': [moment(), moment()],
                                '本周': [moment().startOf('week'), moment().endOf('week')],
                                '本月': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            locale={locale}
                            style={{height: 28, width: 240}}
                            value={[startDate, endDate]}
                            format="YYYY-MM-DD"
                            onChange={dateChange}
                        />
                        <Tooltip placement="bottomLeft" title="选择今日" >
                            <Button
                                style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                onClick={() => dateTypeChange('day')}
                            >
                                <Text>日</Text>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="选择本周" >
                            <Button
                                style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                onClick={() => dateTypeChange('week')}
                            >
                                <Text>周</Text>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="选择本月" >
                            <Button
                                style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                onClick={() => dateTypeChange('month')}
                            >
                                <Text>月</Text>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="周期减1" >
                            <Button
                                style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}
                                onClick={() => dateCycleChange('sub')}
                            >
                                <LeftOutlined style={{ lineHeight: "10px", fontSize: 12 }} />
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title="周期加1" >
                            <Button
                                onClick={() => dateCycleChange('add')}
                                style={{ padding: "0 5px", width: 28, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "rgba(0,0,0,0.5)" }}>
                                <RightOutlined style={{ lineHeight: "10px", fontSize: 12 }} />
                            </Button>
                        </Tooltip>
                        {/* <Tooltip placement="bottomLeft" title="下载所选日期的全量商品数据" >
                            <Button loading={downStatus} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}><CloudDownloadOutlined /></Button>
                        </Tooltip> */}
                        {/* <Button onClick={()=>getTableDataByType("product_line")} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}><SyncOutlined /></Button> */}
                        {/* <Button onClick={getTimeRangesaleStatistical} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}><SyncOutlined /></Button> */}
                    </div>
                </div>
                <div className={style.myTable}>
                    <div className={style.myTableHeader} >
                        <Title level={4} style={{ fontsize: 15 }} >
                            常用分析报表
                        </Title>
                        <p>
                            历史数据同比：
                            <span>
                                <Radio.Group onChange={compareChange} value={compareType === "year" ? 1 : 2}>
                                    <Radio value={1}>同去年</Radio>
                                    <Radio value={2}>同上期</Radio>
                                </Radio.Group>
                            </span>
                        </p>
                    </div>
                    <Spin spinning={tableLoading} tip="数据计算中...">
                        <Tabs activeKey={tabKey} onChange={(key) => setTabKey(key)}>
                            <TabPane tab="产品线" key={'product_line'}></TabPane>
                            <TabPane tab="季节线" key={'season'}></TabPane>
                            <TabPane tab="品类线" key={'category'}></TabPane>
                            <TabPane tab="TOP款" key={'price'}></TabPane>
                        </Tabs>
                        <div className={style.indicatorCardRow}>
                            {
                                tabKey == 'product_line' ?
                                    <div className={style.reportWapper}>
                                        <GoodsChart id={"proLine"} title={"产品线商品数据统计"} xZhou={proX} barData={proBar}
                                                    lineData={proLine}/>
                                        <Table
                                            // title={() => header}
                                            scroll={{x: '100%', y: 300}}
                                            columns={proColumns}
                                            dataSource={proData}
                                            rowClassName={style.tableRow}
                                            pagination={false}
                                        />
                                    </div>
                                    : tabKey == 'season' ?
                                    <div className={style.reportWapper}>
                                        <GoodsChart id={"season"} title={"季节线商品数据统计"} xZhou={seasonX}
                                                    barData={seasonBar} lineData={seasonLine}/>
                                        <Table
                                            // title={() => header}
                                            scroll={{x: '100%', y: 300}}
                                            columns={seasonColumns}
                                            dataSource={seasonData}
                                            rowClassName={style.tableRow}
                                            pagination={false}
                                        />
                                    </div>
                                    : tabKey == 'category' ?
                                        <div className={style.reportWapper}>
                                            <GoodsChart id={"kind"} title={"品类线商品数据统计"} xZhou={kindX} barData={kindBar}
                                                        lineData={kindLine}/>
                                            <Table
                                                // title={() => header}
                                                scroll={{x: '100%', y: 300}}
                                                columns={kindColumns}
                                                dataSource={kindData}
                                                rowClassName={style.tableRow}
                                                pagination={false}
                                                onRow={function (record, rowIndex) {
                                                    return (
                                                        {
                                                            style: {
                                                                display: record['pay_skus'] == 0 ? 'none' : ''
                                                            },
                                                        }
                                                    )
                                                }}
                                            />
                                        </div>
                                        : <div className={style.reportWapper}>
                                            <GoodsChart id={"price"} title={"TOP款商品数据统计"} xZhou={priceX}
                                                        barData={priceBar} lineData={priceLine}/>
                                            <Table
                                                // title={() => header}
                                                scroll={{x: '100%', y: 300}}
                                                columns={priceColumns}
                                                dataSource={priceData}
                                                rowClassName={style.tableRow}
                                                pagination={false}
                                            />
                                        </div>
                            }
                        </div>
                    </Spin>
                </div>
                <div className={style.myTable}>
                    <div className={style.customTableHeader}>
                        <Title level={4} style={{fontsize: 15, margin: 0}}>自定义属性分析</Title>
                        <Form {...layout} form={form1} labelAlign='left'>
                            <div className={style.customTableHeaderRow}>
                                <Form.Item label="类目" name="category">
                                    <Cascader
                                        style={{width: 250, height: 28}}
                                        size="small"
                                        placeholder="选择类目"
                                        onChange={(value) => conditionChange(value, 'category2')}
                                        options={kind}
                                        changeOnSelect
                                        showSearch
                                    />
                                </Form.Item>
                            </div>
                            <div className={style.customTableHeaderRow}>
                                <Form.Item label="季节" name="season">
                                    <Checkbox.Group options={season}
                                                    onChange={(value) => conditionChange(value, 'season')}/>
                                </Form.Item>
                            </div>
                            <div className={style.customTableHeaderRow}>
                                <Form.Item label="产品线" name="product_line">
                                    <Checkbox.Group options={product}
                                                    onChange={(value) => conditionChange(value, 'product_line')}/>
                                </Form.Item>
                            </div>
                            <div className={style.customTableHeaderRow}>
                                <Form.Item label="年份" name="year">
                                    <Checkbox.Group options={year}
                                                    onChange={(value) => conditionChange(value, 'year')}/>
                                </Form.Item>
                            </div>
                            <div className={style.customTableHeaderRow}>
                                <Form.Item label="价格带" name="priceInterval">
                                    <div>
                                        <InputNumber className={style.priceZone} style={{height: 28, width: 70}}
                                                     max={2000} min={10} value={priceInterval} step={100}
                                                     onChange={priceChange}/>
                                    </div>
                                </Form.Item>
                            </div>
                        </Form>
                        <div className={style.customButonRow}>
                            <Button type="primary" onClick={() => getCustomTableData(conditions)}>查询</Button>
                            <Button type="primary" danger onClick={clearConditions}>清空</Button>
                            <Text type="secondary" style={{fontSize: "14px", lineHeight: "32px"}}>
                                当前统计条件:
                                <span className={style.conditions}>
                                    {conditions.length !== 0 ?
                                        conditions.map(condition => {
                                            return fileds(condition)
                                        }) : " 总计数据"
                                    }
                                </span>
                            </Text>
                        </div>
                    </div>
                    <Spin spinning={customLoading} tip="自定义数据查询中..." >
                        <Table
                            columns={customColumns}
                            dataSource={customData}
                            rowClassName={style.tableRow}
                            pagination={false}
                        // onChange={tableChange}
                        />
                    </Spin>
                </div>
            </div>
        </div>
    )

    // 条件字段变化
    function conditionChange(value,type){
        if (value.length === 0) {
            let newDict = {...conditionDict}
            delete newDict[type]
            setConditionDict(newDict)
            let con = [...conditions]
            let index = con.indexOf(type)
            con.splice(index, 1)
            setConditions(con)
        } else {
            if (conditions.indexOf(type) === -1) {
                let nowConditions = [...conditions]
                nowConditions.push(type)
                setConditions(nowConditions)
            }
            let newDict = {...conditionDict}
            newDict[type] = value
            setConditionDict(newDict)
        }
    }

    // 获取分析可选条件 
    function getCustomConditions() {
        let data = {}
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.shop_name = shopName
        return NbAxios(data, "POST", servicePath.getCustomConditions).then((res, rej) => {
            if (res.success) {
                let data = res.data
                setKind(data.category)
                if (data.product_line[0]['label']) {
                    setProduct(data.product_line)
                }
                setSeason(data.season)
                setYear(data.year)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("出现错误！")
            setCustomLoading(false)
        })
    }   
    // 查看详情
    function lookDetails(conditions){
        let data = {}
        data.roleId = cookie.load('role')
        data.shop_name = shopName
        data.conditions_list = conditions
        data.start_date = startDate.format('YYYY-MM-DD')
        data.end_date = endDate.format('YYYY-MM-DD')
        data.shopList = cookie.load('shopList')
        let key = "getCommodityGoodsIdList"
        message.loading({ content: '正在获取数据...', key, duration: 100 })
        NbAxios(data, "POST", servicePath.getCommodityGoodsIdList).then((res, rej) => {
            if(res.success){
                props.history.push({'pathname':`/app/SaleBorder/${shopName}`,'state':{'idList':res.data, "start_date":startDate.format('YYYY-MM-DD'),"end_date":endDate.format('YYYY-MM-DD')}})
                message.success({ content: "成功获取数据!", key })
            }
            else{
                message.error({content:res.errorMsg, key})
            }
        })
        // let idList = ['1','2','3','4','5','6','7','8','1','2','3','4','5','6','7','8','1','2','3','4','5','6','7','8','1','2','3','4','5','6','7','8','1','2','3','4','5','6','7','8',]
        // props.history.push({'pathname':`/app/SaleBorder/${shopName}`,'state':{'idList':idList}})
    }
    // 根据条件获取商品id列表
    function getGoodsIdList(conditions) {
        let download = conditions[Object.keys(conditions)[0]] === "基础信息缺失"
        let data = {}
        data.start_date = startDate.format('YYYY-MM-DD')
        data.end_date = endDate.format('YYYY-MM-DD')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.conditions_list = conditions
        data.download = download
        data.shop_name = shopName
        let key = "getIdList"
        message.loading({ content: download ? '正在为你导出...' : '正在获取数据...', key, duration: 100 })
        if (download) {
            return Axios({
                method: 'POST',
                url: servicePath.getGoodsIdList,
                data: data,
                responseType: "blob",
                headers: {
                    'authorization': cookie.load('token')
                },
            }).then(res => {
                downExcel(res)
                setDownStatus(false)
                message.success({ content: "导出成功！", key })
            }).catch(e => {
                message.error({ content: '文件制作出错，请联系it同事！', key })
            })
        }
        // return NbAxios(data, "POST", servicePath.getGoodsIdList).then((res, rej) => {
        //     if (res.success) {
        //         let data = res.data
        //         console.log(data);
        //         let shop_data = {}, table_data = {};
        //         shop_data[`${shopName}`] = data
        //         table_data['season'] = {
        //             x: seasonX,
        //             bar: seasonBar,
        //             line: seasonLine,
        //             data: seasonData
        //         }
        //         table_data['category'] = {
        //             x: kindX,
        //             bar: kindBar,
        //             line: kindLine,
        //             data: kindData
        //         }
        //         table_data['price'] = {
        //             x: priceX,
        //             bar: priceBar,
        //             line: priceLine,
        //             data: priceData
        //         }
        //         table_data['product_line'] = {
        //             x: proX,
        //             bar: proBar,
        //             line: proLine,
        //             data: proData
        //         }
        //         table_data['start_time'] = startDate
        //         table_data['end_time'] = endDate
        //         let shop_table_data = {}
        //         shop_table_data[`${shopName}`] = table_data
        //         dispatch({ type: ADD_GOODS_LIST, shop_data })
        //         dispatch({ type: ADD_SHOP_GOODS_DATA, shop_table_data })
        //         message.success({ content: "数据获取成功，为你跳转至详情页面！", key })
        //         let idList = ['1','2','3','4','5','6','7','8']
        //         // props.history.push(`/app/SaleBorder/${shopName}`)
        //         console.log(idList);
        //         props.history.push({'pathname':`/app/SaleBorder/${shopName}`,'state':{'idList':idList}})
        //         data = null
        //     } else {
        //         message.warn({ content: res.errorMsg, key })
        //     }
        //     key = null
        //     data = null
        //     return true
        // })
    }

    // 改变指定字段的排序状态
    function changeSortField(sortType) {
        // 如果
        if (sort === 'ASC') {
            return 'ascend'
        } else if (sort === 'DESC') {
            return 'descend'
        } else {
            return false
        }
    }

    // 表格变化时
    function tableChange(pagination, filters, sorter, extra) {
        setSortField(sorter.field)
        let order = sorter.order
        if (order === 'ascend') {
            setSort('ASC')
        } else if (order === 'descend') {
            setSort('DESC')
        } else {
            setSort(false)
        }
    }

    // 价格带变化
    function priceChange(value) {
        setPriceInterval(value)
        if (conditions.includes("price_zone")) {
            getCustomTableData(conditions, value)
        }
    }


    // 清空所有字段
    function clearConditions() {
        form1.resetFields()
        setConditions([])
        getCustomTableData([])
    }

    // 选择同比时间端
    function compareChange(e) {
        let choose = e.target.value
        switch (choose) {
            // 同比去年
            case 1:
                setCompareType("year")
                setStartDate(moment().startOf('month'))
                setEndDate(moment().endOf('month'))
                break
            // 同比上期
            case 2:
                setCompareType(dateType)
                break
        }
    }

    // 分析字段变化
    function radioChange(value) {
        let newCondition = value.filter(item => conditions.indexOf(item) === -1)
        if (newCondition.length === 0) {
            let nowConditions = conditions.filter(item => value.indexOf(item) !== -1)
            setConditions(nowConditions)
        } else {
            setConditions([...conditions, ...newCondition]);
        }
    }

    // 获取自定义表格数据
    function getCustomTableData(conditions, price = priceInterval) {
        let data = {}
        data.start_date = startDate.format('YYYY-MM-DD')
        data.end_date = endDate.format('YYYY-MM-DD')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.conditions_list = conditions
        data.conditions_dict = conditionDict
        data.shop_name = shopName
        data.price_interval = parseInt(price)
        data.sort_field = sortField
        data.sort = sort
        setCustomLoading(true)
        return NbAxios(data, "POST", servicePath.getCustomTableData).then((res, rej) => {
            if (res.success) {
                let data = res.data
                setCustomData(data)
            } else {
                message.warn(res.errorMsg)
            }
            setCustomLoading(false)
            data = null
            return true
        }).catch(e => {
            message.error("出现错误！")
            data = null
            setCustomLoading(false)
        })
    }

    // 设置日期框选择周期
    function dateTypeChange(type) {
        setCompareType(type)
        setDateType(type)
        switch (type) {
            case 'day':
                setStartDate(moment())
                setEndDate(moment())
                break
            case 'week':
                setStartDate(moment().startOf('week'))
                setEndDate(moment().endOf('week'))
                break
            case 'month':
                setStartDate(moment().startOf('month'))
                setEndDate(moment().endOf('month'))
                break
            default:
                break;
        }
    }

    // 周期加减函数
    function dateCycleChange(type) {
        if (type === 'add') {
            setStartDate(moment(startDate).add(1, dateType).startOf(dateType))
            setEndDate(moment(startDate).add(1, dateType).endOf(dateType))
        } else if (type === 'sub') {
            setStartDate(moment(startDate).subtract(1, dateType).startOf(dateType))
            setEndDate(moment(startDate).subtract(1, dateType).endOf(dateType))
        }
    }

    // 日期变化 
    function dateChange(dates, dateStrings) {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    // 控制单元格表头样式，去掉padding文字居左
    function onHeaderCell(record, rowIndex) {
        return (
            {
                style: {
                    padding: '0',
                    background: '#ffffff',
                    borderTop: "1px solid #f6f6f6"
                }
            }
        )
    }

    // 获取top类型表格数据
    function getTopData() {
        let data = {}
        data.start_date = startDate.format('YYYY-MM-DD')
        data.end_date = endDate.format('YYYY-MM-DD')
        data.last_start_date = moment(startDate).subtract(1, compareType).startOf(dateType).format('YYYY-MM-DD')
        data.last_end_date = moment(endDate).subtract(1, compareType).endOf(dateType).format('YYYY-MM-DD')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.shop_name = shopName
        return NbAxios(data, "POST", servicePath.getTopData).then((res, rej) => {
            if (res.success) {
                console.log(res.data);
                let data = res.data
                setPriceX(data.chart.x_zhou)
                setPriceBar(data.chart.bar)
                setPriceData(data.table)
                setPriceLine(data.chart.line)
                setTableLoading(false)
            } else {
                message.warn(res.errorMsg)
                setTableLoading(false)
            }
        }).catch(e => {
            setTableLoading(false)
        })
    }

    // 按分类获取表格数据
    function getTableDataByType(type) {
        // let {start,last} = getDate(startDate,endDate,"year")
        let data = {}
        data.start_date = startDate.format('YYYY-MM-DD')
        data.end_date = endDate.format('YYYY-MM-DD')
        data.last_start_date = moment(startDate).subtract(1, compareType).startOf(dateType).format('YYYY-MM-DD')
        data.last_end_date = moment(endDate).subtract(1, compareType).endOf(dateType).format('YYYY-MM-DD')
        data.role = cookie.load('role')
        data.shop_list = cookie.load('shopList')
        data.shop_name = shopName
        data.table_type = type
        return NbAxios(data, "POST", servicePath.getTableDataByType).then((res, rej) => {
            if (res.success) {
                let data = res.data
                switch (type) {
                    case 'season':
                        setSeasonData(data.table)
                        setSeasonX(data.chart.x_zhou)
                        setSeasonLine(data.chart.line)
                        setSeasonBar(data.chart.bar)
                        break
                    case 'price':
                        setPriceX(data.chart.x_zhou)
                        setPriceBar(data.chart.bar)
                        setPriceData(data.table)
                        setPriceLine(data.chart.line)
                        break
                    case 'product_line':
                        setProData(data.table)
                        setProX(data.chart.x_zhou)
                        setProBar(data.chart.bar)
                        setProLine(data.chart.line)
                        break
                    case 'category':
                        setKindX(data.chart.x_zhou)
                        setKindBar(data.chart.bar)
                        setKindLine(data.chart.line)
                        setKindData(data.table)
                        break
                }
                setTableLoading(false)
            } else {
                message.warn(res.errorMsg)
                setTableLoading(false)
            }
            data = null
            return true
        }).catch(e => {
            setTableLoading(false)
        })
    }

    function getDate(start, end, type) {
        let startDate
        let endDate
        let timeType
        switch (type) {
            // 如果同比去年
            case "year":
                timeType = "year"
                setStartDate(moment(start).subtract(1, "year"))
                setEndDate(moment(end).subtract(1, "year"))
            // 如果同比上一期
            case "last":
                timeType = dateType
                setStartDate(moment(start).subtract(1, dateType))
                setEndDate(moment(end).subtract(1, dateType))
        }
        startDate = moment(start).subtract(1, timeType)
        endDate = moment(end).subtract(1, timeType)
        return { last: endDate, start: startDate }
    }

}

export default GoodsReport