import React, { useEffect, useState } from "react";
import { Checkbox, message, Button, Spin, Table, DatePicker } from "antd";
import cookie from "react-cookies";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import servicePath from "../../config/apiUrl";
import NbAxios from "../../config/utils/nbAxios";
import axios from "axios";
import style from "./index.module.css";
import downExcel from "../../config/utils/downloadFile";
const dateFormat = "YYYY-MM-DD";
function Detail(props) {
  // 类型
  const [calculation_type] = useState(
    props.history.location.pathname.split("/")[3]
  );
  // 是否有效
  const [loading, setLoading] = useState(false);
  // 选择的开始时间
  const [startTime, setStartTime] = useState(moment());
  // 选择的结束时间
  const [endTime, setEndTime] = useState("");
  // 选中的模版
  const [template, setTemplate] = useState("");
  // 公司选择器列表
  const [companyList, setCompanyList] = useState([]);
  // 公司选择器已选中列表
  const [companyChecked, setCompanyChecked] = useState([]);
  // 部门选择器列表
  const [departmentList, setDepartmentList] = useState([]);
  // 部门选择器已选中列表
  const [departmentChecked, setDepartmentChecked] = useState([]);
  // 项目选择器列表
  const [projectList, setProjectList] = useState([]);
  // 项目选择器已选中列表
  const [projectChecked, setProjectChecked] = useState([]);
  // 店铺选择器列表
  const [shopList, setShopList] = useState([]);
  // 店铺选择器已选中列表
  const [shopChecked, setShopChecked] = useState([]);
  // 岗位选择器列表
  const [postList, setPostList] = useState([]);
  // 岗位选择器已选中列表
  const [postChecked, setPostChecked] = useState([]);
  // 科目选择器列表
  const [subjectList, setSubjectList] = useState([
    "全选",
    "工资",
    "公积金-个人部分",
    "公积金-公司部分",
    "年终奖",
    "残疾人保障金",
    "个人所得税",
    "工会经费",
    "绩效奖金",
    "劳务工",
    "人力外包服务费",
    "社保-个人失业保险",
    "社保-个人养老保险",
    "社保-个人医疗保险",
    "社保-公司部分",
    "员工关怀",
  ]);
  // 科目选择器已选中列表
  const [subjectChecked, setSubjectChecked] = useState([]);
  // 显示数据选择器列表
  const [displayList, setDisplayList] = useState([
    "全选",
    "费用",
    "编制人数",
    "人数",
  ]);
  // 显示数据选择器已选中列表
  const [displayChecked, setDisplayChecked] = useState([]);
  // 表头字段
  const [filed] = useState([
    "数据项",
    "一月",
    "二月",
    "三月",
    "四月",
    "五月",
    "六月",
    "七月",
    "八月",
    "九月",
    "十月",
    "十一月",
    "十二月",
  ]);
  // 表头
  const [column, setColumn] = useState([]);
  // 数据源
  const [dataSource, setDataSource] = useState([]);
  // 身份权限
  const [isFinance, setIsFinance] = useState("");
  useEffect(() => {
    // setColumn(filed.map(item => {
    //     return {
    //         title: item.indexOf("月") !== -1 ? () => (<div style={{ display: 'flex', justifyContent: 'space-around', alignItems: "center", marginLeft: "20px" }}>
    //             {displayChecked.indexOf('费用')!==-1&&<div>{item + '费用'}</div>}
    //             {displayChecked.indexOf('编制人数')!==-1&&<div>{item + "编制人数"}</div>}
    //             {displayChecked.indexOf('人数')!==-1&&<div>{item + "人数"}</div>}
    //         </div>) : item,
    //         dataIndex: item,
    //         key: item,
    //         align: "center",
    //         fixed: item == '数据项' ? 'left' : '',
    //         width: item == '数据项' ? "6%" : '',
    //         render: (text, record, index) => {
    //             return item.indexOf("月") !== -1 ? <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: "center", marginLeft: "20px" }}>
    //                 {displayChecked.indexOf('费用')!==-1&&<span>
    //                     {record[item+ '费用']?record[item+ '费用'].toFixed(2):record[item+ '费用']}
    //                 </span>}
    //                 {displayChecked.indexOf('编制人数')!==-1&&<span>
    //                     {record[item + "编制人数"]?record[item + "编制人数"].toFixed(2):record[item + "编制人数"]}
    //                 </span>}
    //                 {displayChecked.indexOf('人数')!==-1&&<span>
    //                     {record[item + "人数"]?record[item + "人数"].toFixed(2):record[item + "人数"]}
    //                 </span>}
    //             </div> : text
    //         }
    //     }
    // }))
    setColumn(
      filed.reduce((result, item) => {
        if (item.indexOf("月") !== -1) {
          if (displayChecked.indexOf("费用") !== -1) {
            result.push({
              title: item + "费用",
              dataIndex: item + "费用",
              key: item + "费用",
              align: "center",
              render: (text, record, index) => {
                return text !== "" ? text.toFixed(2) : text;
              },
            });
          }
          if (displayChecked.indexOf("编制人数") !== -1) {
            result.push({
              title: item + "编制人数",
              dataIndex: item + "编制人数",
              key: item + "编制人数",
              align: "center",
              render: (text, record, index) => {
                return text !== "" ? text.toFixed(2) : text;
              },
            });
          }
          if (displayChecked.indexOf("人数") !== -1) {
            result.push({
              title: item + "人数",
              dataIndex: item + "人数",
              key: item + "人数",
              align: "center",
              render: (text, record, index) => {
                return text !== "" ? text.toFixed(2) : text;
              },
            });
          }
          return result;
        } else {
          result.push({
            title: item,
            dataIndex: item,
            key: item,
            align: "center",
            fixed: item == "数据项" ? "left" : "",
            width: item == "数据项" ? "6%" : "",
          });
          return result;
        }
      }, [])
    );
  }, [displayChecked]);
  useEffect(() => {
    setLoading(true);
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      shopId: cookie.load("shopId"),
      nb_user_id: cookie.load("id"),
      calculation_type: calculation_type,
    };
    // 初始化数据
    const getAllData = async () => {
      await Promise.all([
        getCompany(dataProps),
        getDepartment(dataProps),
        getItem(dataProps),
        getShop(dataProps),
        getStation(dataProps),
        findTemplate(dataProps),
        getUserId(dataProps),
      ]);
    };
    getAllData();
  }, []);
  // 查询公司列表
  const getCompany = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getCompany)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = ["全选"];
          res.data.company_id_list.map((item) => {
            obj.push(item.company);
          });

          setCompanyList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询公司列表出错,请联系IT");
      });
  };
  // 查询部门列表
  const getDepartment = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getDepartment)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = ["全选"];
          res.data.department_id_list.map((item) => {
            obj.push(item.department);
          });
          setDepartmentList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询部门列表出错,请联系IT");
      });
  };
  // 查询项目列表
  const getItem = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getItem)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = ["全选"];
          res.data.item_id_list.map((item) => {
            obj.push(item.item);
          });
          setProjectList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询项目列表出错,请联系IT");
      });
  };
  // 查询店铺列表
  const getShop = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getShop)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = ["全选"];
          res.data.shop_id_list.map((item) => {
            obj.push(item.shop);
          });
          setShopList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询店铺列表出错,请联系IT");
      });
  };
  // 查询岗位列表
  const getStation = (dataProps) => {
    NbAxios(dataProps, "POST", servicePath.getStation)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let obj = ["全选"];
          res.data.station_id_list.map((item) => {
            obj.push(item.station);
          });
          setPostList(obj);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询岗位列表出错,请联系IT");
      });
  };
  // 费用汇总呈现接口
  const allSubjectShow = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.allSubjectShow)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("费用汇总呈现接口出错,请联系IT");
      });
  };
  // 模板新增替换接口
  const addChangeTemplate = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.addChangeTemplate)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("保存成功");
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("模板新增替换接口出错,请联系IT");
      });
  };
  // 模板查询接口
  const findTemplate = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.findTemplate)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setCompanyChecked(res.data.companyChecked);
          setDepartmentChecked(res.data.departmentChecked);
          setProjectChecked(res.data.projectChecked);
          setShopChecked(res.data.shopChecked);
          setDisplayChecked(res.data.showChecked);
          setPostChecked(res.data.stationChecked);
          setStartTime(moment(res.data.year, dateFormat));
          setSubjectChecked(res.data.subjectChecked);
        } else {
          if (res.errorMsg !== "模板不存在") {
            alert(res.errorMsg);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("模板查询接口出错,请联系IT");
      });
  };
  // 拆分表保存接口
  const saveAllSubject = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.saveAllSubject)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("保存成功");
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("拆分表保存接口出错,请联系IT");
      });
  };
  // 下载接口
  const downloadAllSubjectShow = (dataProps) => {
    setLoading(true);
    axios({
      data: dataProps,
      method: "POST",
      url: servicePath.downloadAllSubjectShow,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json", //如果写成contentType会报错
        authorization: cookie.load("token"),
      },
    })
      .then((res) => {
        setLoading(false);
        downExcel(res);
      })
      .catch((e) => {
        setLoading(false);
        message.error({ content: "文件制作出错，请联系it同事！" });
      });
  };
  // 查询NB用户映射接口
  const getUserId = (dataProps) => {
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getUserId)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setIsFinance(res.data.is_finance);
        } else {
          alert(res.errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.warn("查询NB用户映射接口出错,请联系IT");
      });
  };
  return (
    <div className={style.initial}>
      <Spin spinning={loading} tip="数据加载中....">
        <div className={style.externalContent}>
          <h2>预估明细拆分</h2>
          <div className={style.detailGrid}>
            <span className={style.gridSpan}>年份:</span>
            <div>
              {/* <RangePicker locale={locale} style={{ width: "300px" }} picker='year'
                                value={startTime == '' ? ['', ''] : [moment(startTime, dateFormat), moment(endTime, dateFormat)]}
                                onChange={(date, dateString) => {
                                    if (dateString[0] == '') {
                                        message.warn('请选择正确的时间')
                                        return false
                                    } else {
                                        setStartTime(dateString[0])
                                        setEndTime(dateString[1])
                                    }
                                }
                                } /> */}
              <DatePicker
                locale={locale}
                picker="year"
                size="small"
                className={style.staffInfoSelectWidth}
                style={{ height: "32px" }}
                value={startTime}
                // onOpenChange={(open)=>{if(open){
                //     setHackValue('')
                // }else {
                //     setHackValue(null);
                //   }}}
                onChange={(value, dateString) => {
                  setStartTime(value);
                }}
              />
            </div>
            {/* <span className={style.gridSpan}>模版:</span>
                        <div>
                            <Radio.Group value={template} onChange={e => {
                                setTemplate(e.target.value)
                            }}>
                                {templateList.map(item => {
                                    return <Radio value={item}>{item}</Radio>
                                })}
                            </Radio.Group>
                        </div> */}
            <span className={style.gridSpan}>公司:</span>
            <div className={style.gridCheck}>
              {companyList.map((item) => {
                return (
                  <Checkbox
                    style={{ marginLeft: 0, marginRight: "8px" }}
                    checked={companyChecked.indexOf(item) != -1 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        // 全部选中
                        if (item == "全选") {
                          let obj = [];
                          companyList.map((item) => {
                            obj.push(item);
                          });
                          setCompanyChecked(obj);
                        } else {
                          let obj = [...companyChecked];
                          obj.push(item);
                          // 取差集
                          let temp = companyList
                            .concat(obj)
                            .filter((item) => !obj.includes(item));
                          // 如果除了全选之外，所有已选中，则将全选加入
                          if (temp.length == 1 && temp[0] == "全选") {
                            obj.push("全选");
                          }
                          setCompanyChecked(obj);
                        }
                      } else {
                        // 全部取消
                        if (item == "全选") {
                          setCompanyChecked([]);
                        } else {
                          let obj = [];
                          obj = [...companyChecked];
                          obj.remove(item);
                          // 如果没有全选，则去除全选
                          if (obj.indexOf("全选") !== -1) {
                            obj.remove("全选");
                          }
                          setCompanyChecked(obj);
                        }
                      }
                    }}
                  >
                    {item}
                  </Checkbox>
                );
              })}
            </div>
            <span className={style.gridSpan}>部门:</span>
            <div className={style.gridCheck}>
              {departmentList.map((item) => {
                return (
                  <Checkbox
                    style={{ marginLeft: 0, marginRight: "8px" }}
                    checked={
                      departmentChecked.indexOf(item) != -1 ? true : false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        // 全部选中
                        if (item == "全选") {
                          let obj = [];
                          departmentList.map((item) => {
                            obj.push(item);
                          });
                          setDepartmentChecked(obj);
                        } else {
                          let obj = [...departmentChecked];
                          obj.push(item);
                          // 取差集
                          let temp = departmentList
                            .concat(obj)
                            .filter((item) => !obj.includes(item));
                          // 如果除了全选之外，所有已选中，则将全选加入
                          if (temp.length == 1 && temp[0] == "全选") {
                            obj.push("全选");
                          }
                          setDepartmentChecked(obj);
                        }
                      } else {
                        // 全部取消
                        if (item == "全选") {
                          setDepartmentChecked([]);
                        } else {
                          let obj = [];
                          obj = [...departmentChecked];
                          obj.remove(item);
                          // 如果没有全选，则去除全选
                          if (obj.indexOf("全选") !== -1) {
                            obj.remove("全选");
                          }
                          setDepartmentChecked(obj);
                        }
                      }
                    }}
                  >
                    {item}
                  </Checkbox>
                );
              })}
            </div>
            <span className={style.gridSpan}>项目:</span>
            <div className={style.gridCheck}>
              {projectList.map((item) => {
                return (
                  <Checkbox
                    style={{ marginLeft: 0, marginRight: "8px" }}
                    checked={projectChecked.indexOf(item) != -1 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        // 全部选中
                        if (item == "全选") {
                          let obj = [];
                          projectList.map((item) => {
                            obj.push(item);
                          });
                          setProjectChecked(obj);
                        } else {
                          let obj = [...projectChecked];
                          obj.push(item);
                          // 取差集
                          let temp = projectList
                            .concat(obj)
                            .filter((item) => !obj.includes(item));
                          // 如果除了全选之外，所有已选中，则将全选加入
                          if (temp.length == 1 && temp[0] == "全选") {
                            obj.push("全选");
                          }
                          setProjectChecked(obj);
                        }
                      } else {
                        // 全部取消
                        if (item == "全选") {
                          setProjectChecked([]);
                        } else {
                          let obj = [];
                          obj = [...projectChecked];
                          obj.remove(item);
                          // 如果没有全选，则去除全选
                          if (obj.indexOf("全选") !== -1) {
                            obj.remove("全选");
                          }
                          setProjectChecked(obj);
                        }
                      }
                    }}
                  >
                    {item}
                  </Checkbox>
                );
              })}
            </div>
            <span className={style.gridSpan}>店铺:</span>
            <div className={style.gridCheck}>
              {shopList.map((item) => {
                return (
                  <Checkbox
                    style={{ marginLeft: 0, marginRight: "8px" }}
                    checked={shopChecked.indexOf(item) != -1 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        // 全部选中
                        if (item == "全选") {
                          let obj = [];
                          shopList.map((item) => {
                            obj.push(item);
                          });
                          setShopChecked(obj);
                        } else {
                          let obj = [...shopChecked];
                          obj.push(item);
                          // 取差集
                          let temp = shopList
                            .concat(obj)
                            .filter((item) => !obj.includes(item));
                          // 如果除了全选之外，所有已选中，则将全选加入
                          if (temp.length == 1 && temp[0] == "全选") {
                            obj.push("全选");
                          }
                          setShopChecked(obj);
                        }
                      } else {
                        // 全部取消
                        if (item == "全选") {
                          setShopChecked([]);
                        } else {
                          let obj = [];
                          obj = [...shopChecked];
                          obj.remove(item);
                          // 如果没有全选，则去除全选
                          if (obj.indexOf("全选") !== -1) {
                            obj.remove("全选");
                          }
                          setShopChecked(obj);
                        }
                      }
                    }}
                  >
                    {item}
                  </Checkbox>
                );
              })}
            </div>
            {isFinance !== 1 ? (
              <span className={style.gridSpan}>岗位:</span>
            ) : (
              ""
            )}
            {isFinance !== 1 ? (
              <div className={style.gridCheck}>
                {postList.map((item) => {
                  return (
                    <Checkbox
                      style={{ marginLeft: 0, marginRight: "8px" }}
                      checked={postChecked.indexOf(item) != -1 ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          // 全部选中
                          if (item == "全选") {
                            let obj = [];
                            postList.map((item) => {
                              obj.push(item);
                            });
                            setPostChecked(obj);
                          } else {
                            let obj = [...postChecked];
                            obj.push(item);
                            // 取差集
                            let temp = postList
                              .concat(obj)
                              .filter((item) => !obj.includes(item));
                            // 如果除了全选之外，所有已选中，则将全选加入
                            if (temp.length == 1 && temp[0] == "全选") {
                              obj.push("全选");
                            }
                            setPostChecked(obj);
                          }
                        } else {
                          // 全部取消
                          if (item == "全选") {
                            setPostChecked([]);
                          } else {
                            let obj = [];
                            obj = [...postChecked];
                            obj.remove(item);
                            // 如果没有全选，则去除全选
                            if (obj.indexOf("全选") !== -1) {
                              obj.remove("全选");
                            }
                            setPostChecked(obj);
                          }
                        }
                      }}
                    >
                      {item}
                    </Checkbox>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            <span className={style.gridSpan}>科目:</span>
            <div className={style.gridCheck}>
              {subjectList.map((item) => {
                return (
                  <Checkbox
                    style={{ marginLeft: 0, marginRight: "8px" }}
                    checked={subjectChecked.indexOf(item) != -1 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        // 全部选中
                        if (item == "全选") {
                          let obj = [];
                          subjectList.map((item) => {
                            obj.push(item);
                          });
                          setSubjectChecked(obj);
                        } else {
                          let obj = [...subjectChecked];
                          obj.push(item);
                          // 取差集
                          let temp = subjectList
                            .concat(obj)
                            .filter((item) => !obj.includes(item));
                          // 如果除了全选之外，所有已选中，则将全选加入
                          if (temp.length == 1 && temp[0] == "全选") {
                            obj.push("全选");
                          }
                          setSubjectChecked(obj);
                        }
                      } else {
                        // 全部取消
                        if (item == "全选") {
                          setSubjectChecked([]);
                        } else {
                          let obj = [];
                          obj = [...subjectChecked];
                          obj.remove(item);
                          // 如果没有全选，则去除全选
                          if (obj.indexOf("全选") !== -1) {
                            obj.remove("全选");
                          }
                          setSubjectChecked(obj);
                        }
                      }
                    }}
                  >
                    {item}
                  </Checkbox>
                );
              })}
            </div>
            <span className={style.gridSpan}>显示数据:</span>
            <div className={style.gridCheck}>
              {displayList.map((item) => {
                return (
                  <Checkbox
                    style={{ marginLeft: 0, marginRight: "8px" }}
                    checked={displayChecked.indexOf(item) != -1 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        // 全部选中
                        if (item == "全选") {
                          let obj = [];
                          displayList.map((item) => {
                            obj.push(item);
                          });
                          setDisplayChecked(obj);
                        } else {
                          let obj = [...displayChecked];
                          obj.push(item);
                          // 取差集
                          let temp = displayList
                            .concat(obj)
                            .filter((item) => !obj.includes(item));
                          // 如果除了全选之外，所有已选中，则将全选加入
                          if (temp.length == 1 && temp[0] == "全选") {
                            obj.push("全选");
                          }
                          setDisplayChecked(obj);
                        }
                      } else {
                        // 全部取消
                        if (item == "全选") {
                          setDisplayChecked([]);
                        } else {
                          let obj = [];
                          obj = [...displayChecked];
                          obj.remove(item);
                          // 如果没有全选，则去除全选
                          if (obj.indexOf("全选") !== -1) {
                            obj.remove("全选");
                          }
                          setDisplayChecked(obj);
                        }
                      }
                    }}
                  >
                    {item}
                  </Checkbox>
                );
              })}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="small"
              type="primary"
              style={{ borderRadius: "3px" }}
              onClick={() => {
                if (
                  companyChecked.length == 0 &&
                  departmentChecked.length == 0 &&
                  projectChecked.length == 0 &&
                  shopChecked.length == 0 &&
                  postChecked.length == 0
                ) {
                  message.warning("公司、部门、项目、店铺、岗位必选其一");
                } else if (subjectChecked.length == 0) {
                  message.warning("科目必须要选择");
                } else if (displayChecked.length == 0) {
                  message.warning("显示数据必须要选择");
                } else {
                  let dataProps = {
                    roleId: cookie.load("role"),
                    shopList: cookie.load("shopList"),
                    shopId: cookie.load("shopId"),
                    change_member: cookie.load("name"),
                    calculation_type: calculation_type,
                    year: startTime.format("YYYY"),
                    nb_user_id: cookie.load("id"),
                    // endTime: endTime,
                    // template: template.indexOf('全选')!==-1?['全选']:template,
                    company_checked:
                      companyChecked.indexOf("全选") !== -1
                        ? ["全选"]
                        : companyChecked,
                    department_checked:
                      departmentChecked.indexOf("全选") !== -1
                        ? ["全选"]
                        : departmentChecked,
                    project_checked:
                      projectChecked.indexOf("全选") !== -1
                        ? ["全选"]
                        : projectChecked,
                    shop_checked:
                      shopChecked.indexOf("全选") !== -1
                        ? ["全选"]
                        : shopChecked,
                    station_checked:
                      postChecked.indexOf("全选") !== -1
                        ? ["全选"]
                        : postChecked,
                    subject_checked:
                      subjectChecked.indexOf("全选") !== -1
                        ? ["全选"]
                        : subjectChecked,
                    display_checked:
                      displayChecked.indexOf("全选") !== -1
                        ? ["全选"]
                        : displayChecked,
                  };
                  allSubjectShow(dataProps);
                }
              }}
            >
              查询
            </Button>
            <Button
              size="small"
              type="danger"
              style={{ margin: "0 20px 0 20px", borderRadius: "3px" }}
              onClick={() => {
                setStartTime("");
                setEndTime("");
                setTemplate("");
                setCompanyChecked([]);
                setProjectChecked([]);
                setShopChecked([]);
                setDepartmentChecked([]);
                setPostChecked([]);
                setSubjectChecked([]);
                setDisplayChecked([]);
              }}
            >
              重置
            </Button>
            <Button
              size="small"
              style={{
                borderColor: "#0091FF",
                color: "#0091FF",
                borderRadius: "3px",
                textAlign: "center",
              }}
              onClick={() => {
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  shopId: cookie.load("shopId"),
                  change_member: cookie.load("name"),
                  nb_user_id: cookie.load("id"),
                  calculation_type: calculation_type,
                  year: startTime.format("YYYY"),
                  company_checked: companyChecked,
                  department_checked: departmentChecked,
                  project_checked: projectChecked,
                  shop_checked: shopChecked,
                  station_checked: postChecked,
                  subject_checked: subjectChecked,
                  display_checked: displayChecked,
                };
                addChangeTemplate(dataProps);
              }}
            >
              保存当前模版
            </Button>
          </div>
        </div>
        <div className={style.externalContent} style={{ marginTop: "20px" }}>
          <h3>数据拆分表</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <Button
              size="small"
              style={{
                borderColor: "#0091FF",
                color: "#0091FF",
                borderRadius: "3px",
                textAlign: "center",
              }}
              onClick={() => {
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  shopId: cookie.load("shopId"),
                  change_member: cookie.load("name"),
                  nb_user_id: cookie.load("id"),
                  calculation_type: calculation_type,
                  year: startTime.format("YYYY"),
                };
                downloadAllSubjectShow(dataProps);
              }}
            >
              下载
            </Button>
            <Button
              size="small"
              type="primary"
              style={{ borderRadius: "3px", marginLeft: "20px" }}
              onClick={() => {
                let dataProps = {
                  roleId: cookie.load("role"),
                  shopList: cookie.load("shopList"),
                  shopId: cookie.load("shopId"),
                  change_member: cookie.load("name"),
                  nb_user_id: cookie.load("id"),
                  calculation_type: calculation_type,
                  year: startTime.format("YYYY"),
                };
                saveAllSubject(dataProps);
              }}
            >
              保存
            </Button>
          </div>
          <Table
            size="small"
            columns={column}
            dataSource={dataSource}
            rowKey={"key"}
            scroll={{ y: "calc(98vh - 150px)", x: 5000 }}
            pagination={false}
            rowClassName={style.orange}
          />
        </div>
      </Spin>
    </div>
  );
}
export default Detail;
