/**
 * 经销对应品牌商品分析页面
 * @name 经销对应品牌商品分析页面
 */
// 组件
import cookie from 'react-cookies';
import React, { useState, useEffect } from "react";
import { Button, Checkbox, Form, Input, DatePicker, Table, Select, message, Spin } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from "moment";
// 自定义组件
import servicePath from '../../config/apiUrl';
import NbAxios from '../../config/utils/nbAxios';
// 样式 
import styles from "./distributionBrandProductAnalysis.module.css"


function DistributionBrandProductAnalysis(props) {
    // 组件初始化
    const { RangePicker } = DatePicker;
    const CheckboxGroup = Checkbox.Group;
    const { Option } = Select;

    // 店铺选择器相关
    const shopList = [
        <Option key={"jorya官方旗舰店"}>{"jorya官方旗舰店"}</Option>,
        <Option key={"ANMANI恩曼琳官方旗舰店"}>{"ANMANI恩曼琳官方旗舰店"}</Option>,
        <Option key={"aivei官方旗舰店"}>{"aivei官方旗舰店"}</Option>,
        <Option key={"caroline官方旗舰店"}>{"caroline官方旗舰店"}</Option>,
        <Option key={"GIVHSHYH旗舰店"}>{"GIVHSHYH旗舰店"}</Option>,
        <Option key={"qda官方旗舰店"}>{"qda官方旗舰店"}</Option>,
    ]  // 店铺列表
    const defaultShopList = ["jorya官方旗舰店"]  // 默认选择店铺列表
    const [searchShopList, setSearchShopList] = useState(defaultShopList)  // 选中店铺
    const [maxTagTextLength, setMaxTagTextLength] = useState(20)  // 省略tag长度
    // 选中店铺
    function onChangeShopList(value) {
        // 更新省略tag长度
        if (value.length <= 1) {
            setMaxTagTextLength(20)
        } else {
            setMaxTagTextLength(2)
        }

        // 更新选中店铺
        setSearchShopList(value)
    }

    // 款号相关
    const [spu, setSpu] = useState([])  // 款号
    // 输入款号
    function onChangeSpu(value) {
        // 更新款号
        if (value.target.value != ""){
            let spu_list = [value.target.value]
            setSpu(spu_list)
        }else{
            setSpu([])
        }
    }

    // 日期相关
    const [startTime, setStartTime] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'))  // 开始时间
    const [endTime, setEndTime] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'))  // 结束时间
    // 选择日期
    function timeOnChange(value) {
        // 获取日期
        var startTime = moment(value[0]).format('YYYY-MM-DD');
        var endTime = moment(value[1]).format('YYYY-MM-DD');

        // 更新开始时间和结束时间
        setStartTime(startTime)
        setEndTime(endTime)
    }

    // 统计维度相关
    const dimensionList = ["年", "月", "日期", "款号"]  // 维度选择列表
    const defaultDimensionList = ["年", "月", "日期", "款号"]  // 默认维度选择列表
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);  // 是否全选
    const [checkedDimensionList, setCheckedDimensionList] = useState(defaultDimensionList);  // 选中维度选择列表
    // 选中全选
    function onCheckAllChange(e) {
        setCheckedDimensionList(e.target.checked ? dimensionList : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }
    // 选中维度
    function onChangeDimension(list) {
        setCheckedDimensionList(list);
        setIndeterminate(!!list.length && list.length < dimensionList.length);
        setCheckAll(list.length === dimensionList.length);
    }

    // 表格相关
    const dimensionColumns = {
        "品牌": {
            title: '品牌',
            dataIndex: '品牌',
            align: "center",
            width: "5%",
        },
        "年": {
            title: '年',
            dataIndex: '年',
            align: "center",
            width: "5%",
        },
        "月": {
            title: '月',
            dataIndex: '月',
            align: "center",
            width: "5%",
        },
        "日期": {
            title: '日期',
            dataIndex: '日期',
            align: "center",
            width: "5%",
        },
        "款号": {
            title: '款号',
            dataIndex: '款号',
            align: "center",
            width: "5%",
        },
    }
    const indexColumns = [
        {
            title: '款数',
            dataIndex: '款数',
            align: "center",
            width: "5%",
        },
        {
            title: '下单金额',
            dataIndex: '下单金额',
            align: "center",
            width: "5%",
        },
        {
            title: '下单商品件数',
            dataIndex: '下单商品件数',
            align: "center",
            width: "5%",
        },
        {
            title: '客单价',
            dataIndex: '客单价',
            align: "center",
            width: "5%",
        },
    ]
    const pageSize = 11  // 表格每页条数
    const [page, setPage] = useState(1)  // 表格当前页
    const [total, setTotal] = useState(1)  // 表格数据总数
    const [columns, setColumns] = useState([
        {
            title: '品牌',
            dataIndex: '品牌',
            align: "center",
            width: "5%",
        },
        {
            title: '年',
            dataIndex: '年',
            align: "center",
            width: "5%",
        },
        {
            title: '月',
            dataIndex: '月',
            align: "center",
            width: "5%",
        },
        {
            title: '日期',
            dataIndex: '日期',
            align: "center",
            width: "5%",
        },
        {
            title: '款号',
            dataIndex: '款号',
            align: "center",
            width: "5%",
        },
        {
            title: '款数',
            dataIndex: '款数',
            align: "center",
            width: "5%",
        },
        {
            title: '销售额',
            dataIndex: '销售额',
            align: "center",
            width: "5%",
        },
        {
            title: '销售件数',
            dataIndex: '销售件数',
            align: "center",
            width: "5%",
        },
        {
            title: '客单价',
            dataIndex: '客单价',
            align: "center",
            width: "5%",
        },
    ])  // 表格表头
    const [tableTotalData, setTableTotalData] = useState([])  // 表格数据总计
    const [tableData, setTableData] = useState([])  // 表格数据
    // 表格翻页
    function turnPages(page, pageSize) {
        // 更新数据
        setPage(page)  // 表格当前页

        // 获取商品统计数据
        getData(page, tableTotalData)
    }

    // 表格加载相关
    const [tableLoading, setTableLoading] = useState(false);


    // 获取商品统计数据
    function getData(page, list) {
        // 定义变量
        const url = servicePath.getCommodityStatisticalAnalysisData
        const dataParam = {
            "shop_list": searchShopList,
            "spu_list": spu,
            "start_date": startTime,
            "end_date": endTime,
            "dimension_list": checkedDimensionList,
            "page": page,
            "page_size": 10
        }

        dataParam.roleId = cookie.load('role')
        dataParam.shopList = cookie.load('shopList')

        // 请求接口
        setTableLoading(true)
        return NbAxios(dataParam, "POST", url).then((res, rej) => {
            if (res.success) {
                let data = res.data
                setTableData(data["table_data"].concat(list))
                setTableLoading(false)
            } else {
                message.warn(res.errorMsg)
                setTableLoading(false)
            }
        }).catch(e => {
            message.error("出现错误！")
            setTableLoading(false)
        })
    }
    // 获取商品统计数据总数
    function getTotal() {
        // 定义变量
        const url = servicePath.getCommodityStatisticalAnalysisTotal
        const dataParam = {
            "shop_list": searchShopList,
            "spu_list": spu,
            "start_date": startTime,
            "end_date": endTime,
            "dimension_list": checkedDimensionList,
        }
        dataParam.roleId = cookie.load('role')
        dataParam.shopList = cookie.load('shopList')

        // 请求接口
        return NbAxios(dataParam, "POST", url).then((res, rej) => {
            if (res.success) {
                let data = res.data
                let total = Math.ceil(data["total"] / 10) * 11
                setTotal(total)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("出现错误！")
        })
    }
    // 获取商品统计数据总计
    function getTotalData() {
        // 定义变量
        const url = servicePath.getCommodityStatisticalAnalysisTotalData
        const dataParam = {
            "shop_list": searchShopList,
            "spu_list": spu,
            "start_date": startTime,
            "end_date": endTime,
            "dimension_list": checkedDimensionList,
        }
        dataParam.roleId = cookie.load('role')
        dataParam.shopList = cookie.load('shopList')

        // 请求接口
        return NbAxios(dataParam, "POST", url).then((res, rej) => {
            if (res.success) {
                let data = res.data
                setTableTotalData(data["total_data"])
                getData(1, data["total_data"])
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("出现错误！")
        })
    }

    // 点击查询按钮
    function onClickInquire() {
        // 更新表格
        setPage(1)
        setTotal(1)
        setTableData([])

        // 更新表头
        let columns = checkedDimensionList.map((val, index) => {
            return dimensionColumns[val]
        })
        columns = [dimensionColumns["品牌"]].concat(columns)
        setColumns(columns.concat(indexColumns))

        setTableLoading(true)
        // 获取商品统计数据总数
        getTotal()
        // 获取商品统计数据总计
        getTotalData()
    }

    // 默认数据
    useEffect(() => {
        // 获取表格表头
        let columns = defaultDimensionList.map((val, index) => {
            return dimensionColumns[val]
        })
        setColumns(columns.concat(indexColumns))

        // 初始化表格数据总计
        let totalData = [{
            "品牌": "总计",
            "款数": "0",
            "下单金额": "-",
            "下单商品件数": "-",
            "下单买家数": "-",
            "客单价": "-",
            "年": "总计",
            "月": "总计",
            "日期": "总计",
            "款号": "总计"
        }]
        setTableData(totalData)
    }, [])

    return (
        <div className={styles.wholeDiv}>
            {/* 查询条件 */}
            <div className={styles.queryConditionsDiv}>
                <Form
                    name="basic"
                    labelCol={{ span: 4 }}
                    layout={"inline"}
                    autoComplete = "off"
                >
                    <Form.Item
                        label="店铺"
                        name="店铺"
                        wrapperCol={{ span: 20 }}
                        style={{ paddingBottom: "5px" }}
                    >
                        <Select
                            mode="tags"
                            allowClear
                            style={{ width: '240px', textAlign: "left" }}
                            placeholder="请选择店铺"
                            defaultValue={defaultShopList}
                            onChange={onChangeShopList}
                            maxTagTextLength={maxTagTextLength}
                            maxTagCount={2}
                        >
                            {shopList}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="款号"
                        name="款号"
                        style={{ paddingBottom: "5px" }}
                    >
                        <Input
                            style={{ marginLeft: "5px" }}
                            onChange={onChangeSpu}
                        />
                    </Form.Item>

                    <Form.Item
                        label="日期"
                        name="日期"
                        style={{ paddingBottom: "5px" }}
                    >
                        <RangePicker
                            locale={locale}
                            onChange={timeOnChange}
                            defaultValue={[moment().subtract(1, 'day'), moment().subtract(1, 'day')]}
                        />
                    </Form.Item>

                    <Form.Item
                        style={{ paddingBottom: "5px" }}
                    >
                        <Button
                            onClick={onClickInquire}
                        >
                            查询
                        </Button>
                    </Form.Item>

                </Form>
            </div>

            {/* 统计维度 */}
            <div className={styles.dimensionDiv}>
                <Form
                    name="basic"
                    layout={"inline"}
                >
                    <Form.Item
                        label="统计维度"
                        name="统计维度"
                    >
                        <div className={styles.checkboxGroupDiv}>
                            <Checkbox
                                indeterminate={indeterminate}
                                onChange={onCheckAllChange}
                                checked={checkAll}
                                style={{ width: "100px", float: "left" }}
                            >
                                全选
                            </Checkbox>

                            <CheckboxGroup
                                options={dimensionList}
                                value={checkedDimensionList}
                                onChange={onChangeDimension}

                            />
                        </div>
                    </Form.Item>
                </Form>
            </div>

            {/* 表格数据 */}
            <div className={styles.tableDiv}>
                <Spin spinning={tableLoading}>
                    <Table
                        pagination={{
                            position: ["topLeft"],
                            current: page,
                            total: total,
                            pageSize: pageSize,
                            showSizeChanger: false,
                            onChange: (page, pageSize) => turnPages(page, pageSize),
                        }}
                        dataSource={tableData}
                        columns={columns}
                    />
                </Spin>

            </div>
        </div>
    )
}

export default DistributionBrandProductAnalysis;