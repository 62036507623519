import React, {useContext, useState, useEffect, memo, useMemo} from 'react';
import {Table, Tabs, DatePicker, Spin, Skeleton, message, Empty, Input, Typography, Collapse, Image, Slider, Radio, Select, Button} from 'antd';
import {SearchOutlined, CaretRightOutlined, LeftOutlined} from '@ant-design/icons';
import SaleImage from '../saleBoard/components/saleImage'
import TopCard from './components/topCard'
import style from './skuAnaysis.module.css';
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import AcceptBuried from '../../config/utils/buried';
import cookie from 'react-cookies';
import Chart from './chat'
import PieChart from './components/pieChart'
import LineChart from './components/lineChart'
import CovidLineChart from './components/covidLineChart'
import PlatformEventsCalendar from '../../component/platformEventsCalendar'
import Top from './top'
import servicePath from '../../config/apiUrl'
import NbAxios from '../../config/utils/nbAxios'
import debounce from 'lodash/debounce';
const {Title, Paragraph, Text, Link} = Typography;
const {RangePicker} = DatePicker;
const {TabPane} = Tabs;
const {Panel} = Collapse;
const {Search} = Input;
const {Option} = Select
// 日期格式
const dateFormat = 'YYYY-MM-DD'
// 店铺id对应店铺名
const shopDict = {
    'aecc4073-4fc8-41c7-9d6a-e37b69947c3e': 'JORYA',
    'd4867ead-6724-4e15-b941-77be33fb6b9f': 'z11',
    '2715f3fa-0dae-4dc7-bcfc-0dd912314e40': 'qda',
    '7f1324e7-0d75-4d94-bace-911d5fbd4727': 'an',
    'f115bbea-bb18-439e-bda8-8d1e26719fee': 'cr',
    'fc84f6bf-1e5f-4566-a49c-cad309658dbc': 'vgrass',
    '773a99f1-d1e0-4d36-aeda-62be6680f73a': 'xhoutlets',
    '444b93cc-a5d0-4886-a4b2-d5f8de27e945': 'ein',
    'b67c4cef-3f68-4bae-9dd8-12400e5e99b1': 'mesux',
    'qda':"2715f3fa-0dae-4dc7-bcfc-0dd912314e40"
}

function SkuAnaysis(props) {
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    // tab关键字
    const [ tabsKey , setTabsKey ] = useState('0')
    // 开始时间
    const [ startDate , setStartDate ] = useState(moment().subtract(14, 'day').format('YYYY-MM-DD'))
    // 结束时间
    const [ endDate , setEndDate ] = useState(moment().format('YYYY-MM-DD'))
    // top10数据
    const [topData, setTopData] = useState([])
    // 折叠面板的key
    const [collapseKey, setCollapseKey] = useState(0)
    // 获取店铺名
    let shopName = props.history.location.pathname.split('/')[2]
    // 开始日期
    const [startMonth, setStartMonth] = useState(moment().subtract(6, 'month'))
    // 结束日期  
    const [endMonth, setEndMonth] = useState(moment())
    // 搜索商品id
    const [searchItem, setSearchItem] = useState('')
    // 当前查看的商品id
    const [item, setItem] = useState('');
    // 加载状态
    const [infoLoading, setInfoLoading] = useState(false)
    // 标签2数据加载状态
    const [tabTwoLoading, setTabTwoLoading] = useState(false)
    // 标签3数据加载状态
    const [ tabThreeLoading , setTabThreeLoading ] = useState(false)
    // 表格图数据
    const [tableSeries, setTableSeries] = useState([])
    // 表格标签数据
    const [tableLegend, setTableLegend] = useState(['金额', '销量', '访客', '件单', '转化率', '折扣', '加购率'])
    // 表格数据
    const [tableData, setTableData] = useState([])
    // 销售数据
    const [ saleDate, setSaleDate ] = useState([])
    // 图表数据
    const [chartData, setChartData] = useState([])
    // 销量 图1/柱图
    const [payNum, setPayNum] = useState([])
    // 金额 图1/折线
    const [payMoney, setPayMoney] = useState([])
    // 访客 图一/柱图
    const [visitor, setVisitor] = useState([])
    // 折扣 图一/折线
    const [discount, setDiscount] = useState([])
    // 加购率  图1/折线
    const [addRate, setAddRate] = useState([])
    // 件单价 图1/折线
    const [onePrice, setOnePrice] = useState([])
    // 转化率 图2/折线
    const [changeRate, setChangeRate] = useState([])
    // 备货参考 图2/折线
    const [readyNum, setReadyNum] = useState([])
    // 产销春夏 图2/柱图
    const [summer, setSummer] = useState([])
    // 产销秋冬 图2/柱图
    const [winter, setWinter] = useState([])
    // top排行数据
    const [top, setTop] = useState([])
    // 新客人数
    const [newPeople, setNewPeople] = useState([])
    // 老客人数
    const [oldPeople, setOldPeople] = useState([])
    const [col, setCol] = useState([])
    // 搭配排行
    const [ saleRankingDate , setSaleRankingDate ] = useState([])
    const [ payRankingDate , setPayRankingDate ] = useState([])
    // 尺码图表数据
    const [ sizeLegend, setSizeLegend ] = useState([])
    const [ sizeChartData, setSizeChartData ] = useState([])
    // 颜色图表数据
    const [ colorLegend, setColorLegend ] = useState([])
    const [ colorChartData, setColorChartData ] = useState([])
    // 地域指标
    const [ referenceIndicator, setReferenceIndicator] = useState("支付金额")
    // 全部省份数据
    const [ allProvinceData , setAllProvinceData ] = useState({
        "province_money_data_list":[]
    })
    // 省份图表数据
    const [ provinceLegend, setProvinceLegend ] = useState([])
    const [ provinceChartData, setProvinceChartData ] = useState([])
    const [ allProvinceChartData, setAllProvinceChartData ] = useState([])
    // 大区图表数据
    const [ regionLegend, setRegionLegend ] = useState([])
    const [ regionChartData, setRegionChartData ] = useState([])
    // 城市圈层图表数据
    const [ cityCircleLegend, setCityCircleLegend ] = useState([])
    const [ cityCircleChartData, setCityCircleChartData ] = useState([])
    // 省份取值
    const [ minProvince, setMinProvince] = useState(1)
    const [ maxProvince, setMaxProvince] = useState(9)
    // 省份
    const [ province , setProvince ] = useState('')
    // 温度销售
    const [ temperatureSaleLegend, setTemperatureSaleLegend] = useState([])
    const [ temperatureSaleData, setTemperatureSaleData ] = useState([])
    const [ allTemperatureSaleData, setAllTemperatureSaleData ] = useState([])
    // 省份下拉框
    const [ provinceList , setProvinceList ] = useState([])
    // 选择省份
    const [ chooseProvince, setChooseProvince ] = useState('')
    // 城市下拉框
    const [ cityList , setCityList ] = useState([])
    // 选择城市
    const [ chooseCity, setChooseCity ] = useState('')
    // 省份新老客
    const [ provinceNewOldLegend , setProvinceNewOldLegend ] = useState([])
    const [ provinceNewOldData , setProvinceNewOldData ] = useState([])
    const [ allProvinceNewOldData , setAllProvinceNewOldData ] = useState([])
    // 城市生命周期
    const [ cityDate , setCityDate ] = useState([])
    const [ cityData , setCityData ] = useState([])
    const [ cityMinTemp , setCityMinTemp ] = useState([])
    const [ cityMaxTemp , setCityMaxTemp ] = useState([])
    const [ allCityDate, setAllCityDate ] = useState([])
    // 省份疫情数据
    const [ provinceCovidDate, setProvinceCovidDate ] = useState([])
    const [ provinceNewLocal, setProvinceNewLocal ] = useState([])
    const [ provinceNewAsymptomatic, setProvinceNewAsymptomatic] = useState([])
    // 表格列配置
    const [colConfig, setColConfig] = useState([
        {
            title: '维度',
            dataIndex: 'filed',
            key: 'filed',
            fixed: "left",
            align: 'center',
            width: 80
        },
        {
            title: '合计',
            dataIndex: 'total',
            key: 'total',
            fixed: "left",
            align: 'center',
            width: 80
        },
        col.map(item => {
            return {
                title: item,
                dataIndex: item,
                key: item,
                align: 'center',
                width: 100
            }
        })
    ])
    // 时间顺序
    const [chronologically, setChronologically] = useState(1)
    // 商品信息
    const [itemInfo, setItemInfo] = useState([])

    // 销售
    const saleSeries = [
        {
            name: "金额",
            data: payMoney,
            yAxisIndex: 1,
            type: 'bar',
            itemStyle: {
                color: 'rgba(255, 15, 72,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(255, 15, 72)',
                }
            }
        },
        {
            name: "访客",
            data: visitor,
            type: 'bar',
            itemStyle: {
                color: 'rgba(78, 203, 117,.85)',
                barBorderRadius: [10, 10, 0, 0],
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(78, 203, 117)',
                }
            }
        },
        {
            name: "销量",
            data: payNum,
            type: 'bar',
            itemStyle: {
                color: 'rgba(255, 224, 65,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(255, 224, 65)',
                }
            }

        },
        {
            name: "转化率",
            data: changeRate,
            yAxisIndex: 2,
            type: 'line',
            itemStyle: {
                color: 'rgba(114, 88, 180,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(114, 88, 180)',
                }
            }
        },
        {
            name: "折扣",
            data: discount,
            type: 'line',
            yAxisIndex: 2,
            itemStyle: {
                color: 'rgba(78, 203, 117,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(78, 203, 117)',
                }
            }
        },
        {
            name: "加购率",
            data: addRate,
            type: 'line',
            yAxisIndex: 2,
            itemStyle: {
                color: 'rgba(76, 175, 254,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(76, 175, 254)',
                }
            }
        },
        {
            name: "件单",
            data: onePrice,
            type: 'bar',
            itemStyle: {
                color: 'rgba(76, 175, 254,.85)',
                barBorderRadius: [10, 10, 0, 0],

            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(76, 175, 254)',
                }
            }
        }
    ]
    // 销售y
    const saleY = [
        {
            type: 'value',
            position: 'left',
            name: '数量/(人,件)',
            nameLocation: "end",
            nameTextStyle: {
                color: "rgba(0, 0, 0, 0.7)",
                fontWeight: 600,
                align: "right",
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.4)",
                },
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
        {
            type: 'value',
            offset: 80,
            position: 'left',
            name: '金额/元',
            nameLocation: "end",
            nameTextStyle: {
                color: "rgba(0, 0, 0, 0.7)",
                fontWeight: 600,
                align: "right",
            },
            axisLabel: {
                formatter: function (data) {
                    return (data);
                }
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.4)",
                },
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
        {
            type: 'value',
            position: 'right',
            name: '百分比率%',
            nameLocation: "end",
            nameTextStyle: {
                color: "rgba(0, 0, 0, 0.7)",
                fontWeight: 600,
                align: "left",
            },
            axisLabel: {
                formatter: function (data) {
                    return (data);
                }
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.4)",
                },
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
    ]

    // 新老客
    const newSeries = [
        {
            name: '老客',
            type: 'bar',
            stack: '总量',
            label: {
                show: false,
                // color: "#fff",
                // position: 'top',
                // formatter: function (item) {
                //     if (item.value) {
                //         return `${item.value}`
                //     } else {
                //         return ""
                //     }
                // }
            },
            data: oldPeople,
            itemStyle: {
                color: 'rgba(106, 90, 205,.85)'
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(106, 90, 205)',
                }
            }
        },
        {
            name: '新客',
            type: 'bar',
            stack: '总量',
            label: {
                show: true,
                color: "#000",
                position: 'top',
                formatter: function (item) {
                    if (item.value) {
                        return `新客:${item.value}, 老客:${oldPeople[item.dataIndex]}`
                    } else {
                        return ""
                    }
                }
            },
            data: newPeople,
            itemStyle: {
                color: 'rgba(78, 203, 117,.85)',
                barBorderRadius: [10, 10, 0, 0]
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(78, 203, 117)',
                }
            }
        }
    ]
    // 新老客y
    const newY = [
        {
            type: 'value',
            name: '人数',
            position: 'left',
            nameLocation: "end",
            nameTextStyle: {
                color: "rgba(0, 0, 0, 0.7)",
                fontWeight: 600,
                align: "right",
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.4)",
                },
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        }
    ]
    // 表格中的数据
    useEffect(() => {
        if(item) {
            let data = {}
            data.start_month = startMonth.format('YYYY-MM')
            data.end_month = endMonth.format('YYYY-MM')
            data.shop = shopName
            data.item_id = item
            AcceptBuried('query_by_month',"click",data,props.location.pathname,"单款数据分析")
            let buriedProps = {}
            buriedProps.start_day = startDate
            buriedProps.end_day = endDate
            buriedProps.shop = shopName
            buriedProps.goods_id = item
            AcceptBuried('query_by_area_day',"click",buriedProps,props.location.pathname,"单款数据分析")
            const getAllData = async () => {
                await Promise.all([
                    getData(item),
                    getMatchRanking(item),
                    getColorSizeSaleDate(item),
                    getColorSizeSaleChartDate(item),
                    getData2(item),
                    getAreaPayNumChartDate(item),
                    getTempePayNumChartDate(item),
                    getProvincePayNumChartDate(item)
                ])
            }
            getAllData()
        }
        if(chronologically){
            let res = getMonthBetween(startMonth.format('YYYY-MM'), endMonth.format('YYYY-MM'))
            setCol(res)
        }else{
            let res = getMonthBetween(startMonth.format('YYYY-MM'), endMonth.format('YYYY-MM')).reverse()
            setCol(res)
        }
    }, [item])

    useEffect(()=>{
        if(chronologically){
            if(chartData.length!=0){
                setPayNum(payNum.reverse())
                setPayMoney(payMoney.reverse())
                setChangeRate(changeRate.reverse())
                setVisitor(visitor.reverse())
                setDiscount(discount.reverse())
                setReadyNum(readyNum.reverse())
                setAddRate(addRate.reverse())
                setOnePrice(onePrice.reverse())
                setSummer(summer.reverse())
                setWinter(winter.reverse())
                setCol(col.reverse())
            }
        }else{
            if(chartData.length != 0){
                setPayNum(payNum.reverse())
                setPayMoney(payMoney.reverse())
                setChangeRate(changeRate.reverse())
                setVisitor(visitor.reverse())
                setDiscount(discount.reverse())
                setReadyNum(readyNum.reverse())
                setAddRate(addRate.reverse())
                setOnePrice(onePrice.reverse())
                setSummer(summer.reverse())
                setWinter(winter.reverse())
                setCol(col.reverse())
            }
        }
    },[chronologically])

    useEffect(() => {
        if(item) {
            const getAllData = async () => {
                await Promise.all([
                    getAreaPayNumChartDate(item),
                    getTempePayNumChartDate(item),
                    getProvincePayNumChartDate(item)
                ])
            }
            getAllData()
        }
    }, [ item])

    useEffect(()=>{
        if(referenceIndicator == '支付金额'){
            setRegionChartData(allProvinceChartData['area_money_data_list'])
            setCityCircleChartData(allProvinceChartData['level_money_data_list'])
            setTemperatureSaleData(allTemperatureSaleData["province_money_data_list"])
            setProvinceNewOldData(allProvinceNewOldData['old_new_money_data_list'])
            setCityData(allCityDate['city_money_data_list'])
        }else{
            setRegionChartData(allProvinceChartData['area_num_data_list'])
            setCityCircleChartData(allProvinceChartData['level_num_data_list'])
            setTemperatureSaleData(allTemperatureSaleData['province_num_data_list'])
            setProvinceNewOldData(allProvinceNewOldData['old_new_num_data_list'])
            setCityData(allCityDate['city_num_data_list'])
        }
    },[referenceIndicator, allProvinceChartData, allTemperatureSaleData, allProvinceNewOldData, allCityDate])

    useEffect(() => {
        AcceptBuried('page_views',"view",{"page_type":"初始化页面"},props.location.pathname,"单款数据分析")
        setItemInfo([])
        setItem()
        getSingleAnalysisTopData()
        getProvinceCityDate()
        if(window.location.href.indexOf('?') != -1){
            let parameter = window.location.href.split('?')[1]
            setItem(parameter.split('=')[1]);
            setSearchItem(parameter.split('=')[1]);
        }else{
            if(props.location.state){
                const getAllData = async () => {
                    await Promise.all([
                        setItem(props.location.state.goodId),
                        setSearchItem(props.location.state.goodId)
                    ])
                }
                getAllData()
            }else{
                getSingleAnalysisTopOneData()
            }
        }
        
    }, [])

    // 筛选省份数据
    useEffect(()=>{
        setMaxProvince(allProvinceData['province_money_data_list'].length > 10 ? 10 : allProvinceData['province_money_data_list'].length)
    },[allProvinceData])
    useEffect(()=>{
        let buriedProps = {
            "minProvince":minProvince,
            "maxProvince":maxProvince,
        }
        AcceptBuried('select_province_top',"click",buriedProps,props.location.pathname,"单款数据分析")
        if(allProvinceData['province_money_data_list'].length!=0){
            let newProvinceChartData = []
            let newProvinceLegend = []
            let chooseAllProvinceData = []
            if(referenceIndicator == '支付金额'){
                chooseAllProvinceData = allProvinceData['province_money_data_list']
            }else{
                chooseAllProvinceData = allProvinceData['province_num_data_list']
            }
            for(let i = parseInt(minProvince - 1); i < maxProvince; i++){
                newProvinceChartData.push(chooseAllProvinceData[i])
                newProvinceLegend.push(chooseAllProvinceData[i].name)
            }
            setProvinceLegend(newProvinceLegend)
            setProvinceChartData(newProvinceChartData)
        }
    },[maxProvince, minProvince, referenceIndicator])

    // 获取到色到码的销售数据
    function getColorSizeSaleDate(id){
        let data = {}
        data.start_month = startMonth.format('YYYY-MM')
        data.end_month = endMonth.format('YYYY-MM')
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        data.item_id = id
        return NbAxios(data, "POST", servicePath.getColorSizeSaleDate).then((res, rej) => {
            if (res.success) {
                setSaleDate(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("获取到色到码的销售数据错误！")
            data = null
        })
    }

    // 获取到色到码饼图数据
    function getColorSizeSaleChartDate(id){
        let data = {}
        data.start_month = startMonth.format('YYYY-MM')
        data.end_month = endMonth.format('YYYY-MM')
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        data.item_id = id
        return NbAxios(data, "POST", servicePath.getColorSizeSaleChartDate).then((res, rej) => {
            if (res.success) {
                setColorLegend(res.data.color_legend)
                setColorChartData(res.data.color_data_list);
                setSizeLegend(res.data.size_legend)
                setSizeChartData(res.data.size_data_list)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("获取到色到码的销售数据错误！")
            data = null
        })
    }

    // 获取省份支付件数和支付金额饼图数据
    function getProvincePayNumChartDate(id){
        let data = {}
        data.start_day = startDate
        data.end_day = endDate
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        data.goods_id = id
        setTabThreeLoading(true)
        return NbAxios(data, "POST", servicePath.getProvincePayNumChartDate).then((res, rej) => {
            setTabThreeLoading(false)
            if (res.success) {
                setAllProvinceData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            setTabThreeLoading(false)
            message.error("获取温度分区支付件数和支付金额饼图数据错误")
            data = null
        })
    }

    // 获取温度分区支付件数和支付金额饼图数据
    function getTempePayNumChartDate(id){
        let data = {}
        data.start_day = startDate
        data.end_day = endDate
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        data.goods_id = id
        return NbAxios(data, "POST", servicePath.getTempePayNumChartDate).then((res, rej) => {
            if (res.success) {
                setTemperatureSaleLegend(res.data.province_legend)
                setAllTemperatureSaleData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("获取温度分区支付件数和支付金额饼图数据错误！")
            data = null
        })
    }
    // 获取区域,城市分类的支付件数饼图数据
    function getAreaPayNumChartDate(id){
        let data = {}
        data.start_day = startDate
        data.end_day = endDate
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        data.goods_id = id
        return NbAxios(data, "POST", servicePath.getAreaPayNumChartDate).then((res, rej) => {
            if (res.success) {
                setRegionLegend(res.data['area_legend']);
                setCityCircleLegend(res.data['level_legend'])
                setAllProvinceChartData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch(e => {
            message.error("获取区域,城市分类的支付件数饼图数据错误！")
            data = null
        })
    }
    //获取省份、城市数据
    const getProvinceCityDate = () => {
        let data = {}
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        return NbAxios(data, "POST", servicePath.getProvinceCityDate).then((res, rej) => {
            if (res.success) {
                setCityList(res.data['city']);
                setProvinceList(res.data['province']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    //获取销售额TOP10数据
    const getSingleAnalysisTopData = () => {
        let data = {}
        data.start_month = startMonth.format('YYYY-MM')
        data.end_month = endMonth.format('YYYY-MM')
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        return NbAxios(data, "POST", servicePath.getSingleAnalysisTopData).then((res, rej) => {
            if (res.success) {
                setTopData(res.data);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 获取销量TOP1的数据
    const getSingleAnalysisTopOneData = () => {
        let data = {}
        data.start_month = startMonth.format('YYYY-MM')
        data.end_month = endMonth.format('YYYY-MM')
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        return NbAxios(data, "POST", servicePath.getSingleAnalysisTopOneData).then((res, rej) => {
            if (res.success) {
                setItem(res.data[0]['goods_id']);
                setSearchItem(res.data[0]['goods_id']);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // top10点击时间
    const topClick = (id) => {
        let buriedProps = {
            item : id
        }
        AcceptBuried('top_search_item',"click",buriedProps,props.location.pathname,"单款数据分析")
        setItem(id)
        setSearchItem(id);
    }

    useEffect(()=>{
        if(province != ''){
            getProvinceCovidDate()
        }
    },[province])

    // 获取省份疫情数据
    const getProvinceCovidDate = ()=>{
        let data = {}
        data.start_day = startDate
        data.end_day = endDate
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        data.province = province
        return NbAxios(data, "POST", servicePath.getProvinceCovidDate).then((res, rej) => {
            if (res.success) {
                setProvinceCovidDate(res.data.date);
                setProvinceNewLocal(res.data.new_local);
                setProvinceNewAsymptomatic(res.data.new_asymptomatic);
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    return (
        <div className={style.goodsIndexWapper}>
            <div className={style.DataArea}>
                <div className={style.controlBar}>
                    <div className={style.controlBarLeft}>
                        <Title level={3} style={{margin: 0}}>{shopName}单款数据统计</Title>
                    </div>
                    <div className={style.controlBarMiddle}>
                        <Input value={searchItem} placeholder="请输入Id" onChange={(e) => {
                            setSearchItem(e.target.value);
                        }} addonAfter={<SearchOutlined onClick={() => {
                            setItem(searchItem)
                        }}/>}/>
                        {/* <Search value={item} placeholder="请输入Id" onSearch={selectItem}/> */}
                        {/* <DebounceSelect
                            showSearch
                            value={item}
                            placeholder="搜索id"
                            suffixIcon={<SearchOutlined />}
                            fetchOptions={id=>fetchUserList(id,shopName)}
                            onChange={selectItem}
                            style={{ width: '100%' }}
                        /> */}
                    </div>
                    <div className={style.controlBarRight}>
                        {
                            tabsKey != '2' ? <div>
                                <b>时间轴: </b>
                                <Radio.Group value={chronologically} onChange={(e)=>{
                                    let chronologically = ''
                                    if(e.target.value == 1){
                                        chronologically = '正序'
                                    }else{
                                        chronologically = '倒序'
                                    }
                                    let buriedProps = {
                                        "chronologically":chronologically
                                    }
                                    AcceptBuried('choose_timeline',"click",buriedProps,props.location.pathname,"单款数据分析")
                                    setChronologically(e.target.value)
                                }}>
                                    <Radio value={1}>正序</Radio>
                                    <Radio value={0}>倒序</Radio>
                                </Radio.Group>
                                <RangePicker
                                    disabledDate={disabledDate}
                                    ranges={{
                                        '今天': [moment(), moment()],
                                        '本周': [moment().startOf('week'), moment().endOf('week')],
                                        '本月': [moment().startOf('month'), moment().endOf('month')],
                                    }}
                                    locale={locale}
                                    style={{ height: 28, width: 230 }}
                                    value={[startMonth, endMonth]}
                                    onChange={monthChange}
                                    format="YYYY-MM"
                                    picker="month"
                                /> 
                                <Button style={{marginLeft:"10px"}} type="primary" onClick={()=>{
                                    if(item) {
                                        const getAllData = async () => {
                                            await Promise.all([
                                                getData(item),
                                                getMatchRanking(item),
                                                getColorSizeSaleDate(item),
                                                getColorSizeSaleChartDate(item),
                                                getData2(item),
                                                getAreaPayNumChartDate(item),
                                                getTempePayNumChartDate(item),
                                                getProvincePayNumChartDate(item)
                                            ])
                                        }
                                        getAllData()
                                    }
                                    let res = getMonthBetween(startMonth.format('YYYY-MM'), endMonth.format('YYYY-MM')).reverse()
                                    setCol(res)
                                }}>查询</Button>
                            </div>: <div style={{display:'flex', marginTop:'2px'}}>
                                <PlatformEventsCalendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
                                <Radio.Group style={{marginLeft:"130px"}} value={referenceIndicator} onChange={(e)=>{
                                    let buriedProps = {
                                        "referenceIndicator":e.target.value
                                    }
                                    AcceptBuried('select_payment_dimension',"click",buriedProps,props.location.pathname,"单款数据分析")
                                    setReferenceIndicator(e.target.value)
                                }}>
                                    <Radio value={"支付金额"}>支付金额</Radio>
                                    <Radio value={"支付件数"}>支付件数</Radio>
                                </Radio.Group>
                                <Button style={{marginLeft:"10px"}} type="primary" onClick={()=>{
                                    if(item) {
                                        const getAllData = async () => {
                                            await Promise.all([
                                                getAreaPayNumChartDate(item),
                                                getTempePayNumChartDate(item),
                                                getProvincePayNumChartDate(item)
                                            ])
                                        }
                                        getAllData()
                                    }
                                    let buriedProps = {}
                                    buriedProps.start_day = startDate
                                    buriedProps.end_day = endDate
                                    buriedProps.shop = shopName
                                    buriedProps.goods_id = item
                                    AcceptBuried('query_by_area_day',"click",buriedProps,props.location.pathname,"单款数据分析")
                                }}>查询</Button>
                            </div>

                        }
                        
                        {/* <Button onClick={getData} style={{ padding: "0 5px", height: 26, display: "flex", justifyContent: "center", alignItems: "center" }}><SyncOutlined /></Button> */}
                    </div>
                </div>
                <div className={style.itemInfo} >
                    <Skeleton avatar={{ shape: "square", size: 145 }} title={false} loading={itemInfo.length == 0 || false} active={infoLoading} paragraph={{ rows: 5 }} >
                        {/* <img src={`http://img.alicdn.com/bao/uploaded/i2/637882657523/O1CN01azo7Mw1qfa91UUzh1_!!0-item_pic.jpg_180x180.jpg`} /> */}
                        {/* <img
                            className={style.itemMainImg}
                            data-sku={itemInfo.length !== 0 && itemInfo[0]['value']}
                            // data-src={item && `https://pic.bmcsoft.cn/it/${shopName}/${shopName == 'vgrass' ? item.value : item.label}.jpg`}
                            data-id={itemInfo.length !== 0 && itemInfo[1]['value']}
                        /> */}
                        <Image src={`https://pic.bmcsoft.cn/it/${shopName}/${itemInfo.length !== 0 && itemInfo[1]['value']}.jpg`}  style={{width:'130px', height:'130px'}}
                            fallback="https://gimg2.baidu.com/image_search/src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20170712%2F713ea78d708c4e4696ddfc78c1c3f2c5.png&refer=http%3A%2F%2F5b0988e595225.cdn.sohucs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653630406&t=74bf22102152d082aa1cae092af719f1"
                        />
                        <div className={style.itemSecond}>
                            <ul className={style.itemAttrs}>
                                {itemInfo.length !== 0 && itemInfo.map((item, index) => {
                                    return <li className={style.attr}>{`${item.label}：${item.value || "暂无"}`}</li>
                                })}
                            </ul>
                        </div>
                    </Skeleton>
                </div>
                <Collapse
                    activeKey={collapseKey} onChange={(key) => setCollapseKey(key)}
                    bordered={false}
                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                    className={style.CustomCollapse}
                >
                    <Panel header="金额Top10" key={1}>
                        <div style={{display: 'flex', justifyContent: "space-between"}}>
                            {
                                topData.map(item => {
                                    return <TopCard suk={item.sku} id={item.goods_id} shopName={shopName}
                                                    topClick={topClick}/>
                                })
                            }
                        </div>
                    </Panel>
                </Collapse>
                <Tabs activeKey={tabsKey} onChange={(value)=>{
                    let type = ''
                    if(value == '0'){
                        type="销售分析"
                    }else if(value == '1'){
                        type="潜力分析"
                    }else{
                        type="地域分析"
                    }
                    let buriedProps = {
                        "dimensions_name":type
                    }
                    AcceptBuried('view_dimensions',"click",buriedProps,props.location.pathname,"单款数据分析")
                    setTabsKey(value)
                }}>
                    <TabPane tab="销售分析" key={'0'}>
                        {
                            itemInfo.length == 0 ? <Empty className={style.noData}
                                                          description={item != '' ? "数据加载中..." : "选择一款商品分析"}></Empty> :
                                <div>
                                    <div className={style.sale}>
                                        <Chart title="单款销售情况分析图" id="chart" xZhou={col} legend={tableLegend}
                                               series={saleSeries} yAxis={saleY}/>
                                    </div>
                                    <Table
                                        bordered
                                        className={style.saleTable}
                                        pagination={false}
                                        dataSource={tableData}
                                        columns={
                                            [
                                                {
                                                    title: '维度',
                                                    dataIndex: 'filed',
                                                    key: 'filed',
                                                    fixed: "left",
                                                    align: 'center',
                                                    width: 80
                                                },
                                                {
                                                    title: '合计',
                                                    dataIndex: 'total',
                                                    key: 'total',
                                                    fixed: "left",
                                                    align: 'center',
                                                    width: 80,
                                                    render: (text, record, index) => (
                                                        <div style={{textAlign:'right'}}>{text}</div>
                                                    )
                                                },
                                                ...col.map(item => {
                                                    return {
                                                        title: item,
                                                        dataIndex: item,
                                                        key: item,
                                                        align: 'center',
                                                        width: 100,
                                                        render: (text, record, index) => (
                                                            <div style={{textAlign:'right'}}>{text}</div>
                                                        )
                                                    }
                                                })
                                            ]
                                        }
                                    />
                                    <div style={{display:'flex', justifyContent:'space-around'}}>
                                        <PieChart id={"sizeSale"} title="尺码销售数据汇总" height={"500px"} data={sizeChartData} legend={sizeLegend} />
                                        <PieChart id={"colorSale"} title="颜色销售数据汇总" height={"500px"} data={colorChartData} legend={colorLegend} />
                                    </div>
                                    <Table bordered
                                        style={{marginBottom:50}}
                                        className={style.saleTable}
                                        pagination={false}
                                        dataSource={saleDate}
                                        columns={[
                                            {
                                                title: '颜色',
                                                dataIndex: 'color',
                                                key: 'color',
                                                fixed: "left",
                                                align: 'center',
                                                width: 80 
                                            },
                                            {
                                                title: '尺码',
                                                dataIndex: 'size',
                                                key: 'size',
                                                fixed: "left",
                                                align: 'center',
                                                width: 80 
                                            },
                                            ...col.map(item => {
                                                return {
                                                    title: item,
                                                    children:[
                                                        {
                                                            title:'销售件数',
                                                            dataIndex: item + '_销售件数',
                                                            key: item + '_销售件数',
                                                            align: 'center',
                                                            width: 100,
                                                            render: (text, record, index) => (
                                                                <div style={{textAlign:'right'}}>{text}</div>
                                                            )
                                                        },
                                                        {
                                                            title:'支付金额',
                                                            dataIndex: item + '_支付金额',
                                                            key: item + '_支付金额',
                                                            align: 'center',
                                                            width: 100,
                                                            render: (text, record, index) => (
                                                                <div style={{textAlign:'right'}}>{text}</div>
                                                            )
                                                        },
                                                        {
                                                            title:'件数占比',
                                                            dataIndex: item + '_件数占比',
                                                            key: item + '_件数占比',
                                                            align: 'center',
                                                            width: 100,
                                                            render: (text, record, index) => (
                                                                <div style={{textAlign:'right'}}>{text == undefined ? '' : (parseInt(parseFloat(text)*100)).toString() + '%'}</div>
                                                            )
                                                        }
                                                    ]
                                                   
                                                }
                                            })
                                        ]}
                                        scroll={{x:1000}}
                                    ></Table>
                            </div>
                        }
                    </TabPane>
                    <TabPane tab="潜力分析" key={'1'} >
                        {
                            (tabTwoLoading || itemInfo.length == 0) ? <Empty className={style.noData} description={item != '' ? "数据加载中..." : "选择一款商品分析"} ></Empty> : <div className={style.chart} >
                                {/* <Top data={top} shop={shopName} loading={tabTwoLoading} /> */}
                                <div>
                                    <Title level={5}>搭配排行</Title>
                                    <div style={{display:'flex'}}>
                                        <div style={{width:'50%', display:'flex', justifyContent:'space-around' }}>
                                            <b>支付</b>
                                            <b>图例</b>
                                            <b>搭配件数</b>
                                        </div>
                                        <div style={{width:'50%', display:'flex', justifyContent:'space-around'}}>
                                            <b>实销</b>
                                            <b>图例</b>
                                            <b>搭配件数</b>
                                        </div>
                                    </div>
                                    <div style={{display:'flex'}}>
                                        <div style={{ width:'50%', height:'60vh', overflowY:'auto', border:"2px dashed rgba(0, 0, 0, 0.15)", padding:10, marginRight:5}}>
                                            {
                                                payRankingDate.map((item, index)=>{
                                                    return <div style={{display:'flex', justifyContent:'space-around', paddingTop:3, alignItems:'center'}}>
                                                        <div>{index + 1}</div>
                                                        <div style={{width:100}}>
                                                            <Image src={`https://pic.bmcsoft.cn/it/${shopName}/${item.id}.jpg`} style={{width:100, height:100, boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', borderRadius:"5px"}}
                                                            fallback="https://gimg2.baidu.com/image_search/src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20170712%2F713ea78d708c4e4696ddfc78c1c3f2c5.png&refer=http%3A%2F%2F5b0988e595225.cdn.sohucs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653630406&t=74bf22102152d082aa1cae092af719f1"
                                                            />
                                                            <Text style={{width:100, display:'flex', justifyContent:'center'}}>{item.sku}</Text>
                                                        </div>
                                                        <div style={{display:'flex'}}>
                                                            <div style={{fontSize:30, }}>{item.num}</div>
                                                            <SearchOutlined style={{marginTop:15, marginLeft:5, fontSize:20}} onClick={()=>{
                                                                let buriedProps = {
                                                                    "item":item.id
                                                                }
                                                                AcceptBuried('match_ranking_search',"click",buriedProps,props.location.pathname,"单款数据分析")
                                                                setItem(item.id)
                                                            }}/>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div style={{ width:'50%', height:'60vh', overflowY:'auto', border:"2px dashed rgba(0, 0, 0, 0.15)", padding:10}}>
                                            {
                                                saleRankingDate.map((item, index)=>{
                                                    return <div style={{display:'flex', justifyContent:'space-around', paddingTop:3, alignItems:'center'}}>
                                                         <div>{index + 1}</div>
                                                        <div style={{width:100}}>
                                                            <Image src={`https://pic.bmcsoft.cn/it/${shopName}/${item.id}.jpg`} style={{width:100, height:100, boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', borderRadius:'5px'}}
                                                            fallback="https://gimg2.baidu.com/image_search/src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20170712%2F713ea78d708c4e4696ddfc78c1c3f2c5.png&refer=http%3A%2F%2F5b0988e595225.cdn.sohucs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653630406&t=74bf22102152d082aa1cae092af719f1"
                                                            />
                                                            <Text style={{width:100, display:'flex', justifyContent:'center'}}>{item.sku}</Text>
                                                        </div>
                                                        <div style={{display:'flex'}}>
                                                            <div style={{fontSize:30, }}>{item.num}</div>
                                                            <SearchOutlined style={{marginTop:15, marginLeft:5, fontSize:20}} onClick={()=>{
                                                                setItem(item.id)
                                                            }}/>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <Chart title="新老客占比统计图" id="newOld" xZhou={col.map(item => moment(item).format('YY/MM'))} height={"100%"} legend={['新客', '老客']} series={newSeries} yAxis={newY} />
                            </div>
                        }
                    </TabPane>
                    <TabPane tab="地域分析" key={'2'} >
                        {
                            tabThreeLoading || itemInfo.length == 0 ? <Empty className={style.noData} description={item != '' ? "数据加载中..." : "选择一款商品分析"} ></Empty> :
                            <div style={{paddingBottom:"100px"}}>
                                <div style={{display:'flex', justifyContent:'space-around'}}>
                                    <PieChart id={"regionSale"} title="大区分布" height={"500px"} data={regionChartData} legend={regionLegend} />
                                    <PieChart id={"cityCircleSale"} title="城市圈层分布" height={"500px"} data={cityCircleChartData} legend={cityCircleLegend} />
                                    <PieChart id={"temperatureSale"} title="单款温度销售集中情况" height={"500px"} data={temperatureSaleData} legend={temperatureSaleLegend} />
                                    <div style={{width:"100%"}}>
                                        <div style={{display:'flex', height:"20px", lineHeight:"20px"}}>
                                            <b>选择省份TOP:</b>
                                            <div style={{width:"300px", marginLeft:"10px"}}>
                                                <Slider 
                                                    range={{
                                                        draggableTrack:true
                                                    }}
                                                    min={1}
                                                    max={allProvinceData['province_money_data_list'].length}
                                                    value={[minProvince, maxProvince]}
                                                    onChange={(value)=>{
                                                        setMinProvince(value[0]);
                                                        setMaxProvince(value[1]);
                                                    }}
                                                />
                                            </div>
                                            <b style={{marginLeft:'5px'}}>{`以选择范围为:${minProvince} - ${maxProvince}`} </b>
                                        </div>
                                        <PieChart id={"provinceSale"} title="省份分布" height={"480px"} data={provinceChartData} legend={provinceLegend} isClick={true} setProvince={setProvince}/>
                                    </div>
                                </div>
                                <div style={{display:provinceNewAsymptomatic.length == 0 ? 'none' : '', width:"100%"}}>
                                    <CovidLineChart province={province} provinceCovidDate={provinceCovidDate} provinceNewLocal={provinceNewLocal} provinceNewAsymptomatic={provinceNewAsymptomatic}/>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-around', marginTop:'10px'}}>
                                    <div style={{width:"100%"}}>
                                        <div>
                                            <b>选择省份:</b>
                                            <Select showSearch style={{marginLeft:"10px", width:"200px"}} value={chooseProvince} onChange={(value)=>{
                                                setChooseProvince(value)
                                                let data = {}
                                                data.start_day = startDate
                                                data.end_day = endDate
                                                data.roleId = cookie.load('role')
                                                data.shopId = cookie.load('shopList')
                                                data.shop = shopName
                                                data.goods_id = item
                                                data.province = value
                                                AcceptBuried('select_province',"click",data,props.location.pathname,"单款数据分析")
                                                return NbAxios(data, "POST", servicePath.getOldNewProvincePayNumChartDate).then((res, rej) => {
                                                    if (res.success) {
                                                        setProvinceNewOldLegend(res.data['old_new_legend'])
                                                        setAllProvinceNewOldData(res.data);
                                                    } else {
                                                        message.warn(res.errorMsg)
                                                    }
                                                }).catch(e => {
                                                    message.error("获取温度分区支付件数和支付金额饼图数据错误！")
                                                    data = null
                                                })
                                            }}>
                                                {
                                                    provinceList.map(item=>{
                                                        return <Option value={item}>{item}</Option>
                                                    })
                                                }
                                            </Select>
                                        </div>
                                        <PieChart id={"provinceNewOld"} title="新老客占比" height={"480px"} data={provinceNewOldData} legend={provinceNewOldLegend} />
                                    </div>
                                    <div style={{width:"100%"}}>
                                        <div>
                                            <b>选择城市:</b>
                                            <Select showSearch style={{marginLeft:"10px", width:"200px"}} value={chooseCity} onChange={(value)=>{
                                                setChooseCity(value)
                                                let data = {}
                                                data.start_day = startDate
                                                data.end_day = endDate
                                                data.roleId = cookie.load('role')
                                                data.shopId = cookie.load('shopList')
                                                data.shop = shopName
                                                data.goods_id = item
                                                data.city = value
                                                AcceptBuried('select_city',"click",data,props.location.pathname,"单款数据分析")
                                                return NbAxios(data, "POST", servicePath.getTempePayNumCityBarLineDate).then((res, rej) => {
                                                    if (res.success) {
                                                        setAllCityDate(res.data);
                                                        setCityDate(res.data['date']);
                                                        setCityMaxTemp(res.data['city_maxTemp_data_list']);
                                                        setCityMinTemp(res.data['city_minTemp_data_list']);
                                                    } else {
                                                        message.warn(res.errorMsg)
                                                    }
                                                }).catch(e => {
                                                    message.error("获取温度分区支付件数和支付金额饼图数据错误！")
                                                    data = null
                                                })
                                            }}>
                                                {
                                                    cityList.map(item=>{
                                                        return <Option value={item}>{item}</Option>
                                                    })
                                                }
                                            </Select>
                                        </div>
                                        <LineChart id={"provinceTemperatureSale"} title="城市生命周期" height={"480px"} data={cityData} legend={provinceLegend} date={cityDate} minTemp={cityMinTemp} maxTemp={cityMaxTemp} dateTitle={referenceIndicator}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </TabPane>
                </Tabs>

            </div>
        </div>
    )

    function tabChange(value) {
        if (value == '1') {
            // 加入任务队列，等待fiber commit，才可获取到图片
            // setTimeout(() => {
            //     lazyImg()
            // })
        }
    }

    // 选择商品id
    function selectItem(e) {
        // console.log(e);
        // setItem(item)
        // if (item) {
        //     setItem(item)
        // }
    }

    function monthChange(e) {
        if (e) {
            setStartMonth(e[0])
            setEndMonth(e[1])
        }
    }

    function getMonthBetween(start, end) {//传入的格式YYYY-MM
        var result = [];
        var s = start.split("-");
        var e = end.split("-");
        var min = new Date();
        var max = new Date();
        min.setFullYear(s[0], s[1] * 1 - 1, 1);//开始日期
        max.setFullYear(e[0], e[1] * 1 - 1, 1);//结束日期
        var curr = min;
        while (curr <= max) {
            var month = curr.getMonth()
            result.push(moment(curr).format('YYYY-MM'));
            curr.setMonth(month + 1);
        }
        return result;
    }

    function getData2(id) {
        let data = {}
        data.start_month = startMonth.format('YYYY-MM')
        data.end_month = endMonth.format('YYYY-MM')
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        data.item_id = id
        setTabTwoLoading(true)
        return NbAxios(data, "POST", servicePath.getNewAndOldCustomers).then((res, rej) => {
            if (res.success) {
                let data = res.data
                setNewPeople(data.new)
                setOldPeople(data.old)
            } else {
                message.warn(res.errorMsg)
            }
            data = null
            setTabTwoLoading(false)
            return true
        }).catch(e => {
            message.error("出现错误！")
            data = null
        })
    }

    function getMatchRanking(id){
        let data = {}
        data.start_month = startMonth.format('YYYY-MM')
        data.end_month = endMonth.format('YYYY-MM')
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        data.item_id = id
        // setTabTwoLoading(true)
        return NbAxios(data, "POST", servicePath.getMatchRanking).then((res, rej) => {
            if (res.success) {
                setSaleRankingDate(res.data.sale);
                setPayRankingDate(res.data.pay);
            } else {
                message.warn(res.errorMsg)
            }
            data = null
            // setTabTwoLoading(false)
            return true
        }).catch(e => {
            message.error("出现错误！")
            data = null
        })
    }

    // 图片懒加载
    // async function lazyImg() {
    //     // 通过属性选择器获取所有需要懒加载的图片
    //     let imgs = document.querySelectorAll("img[data-id]")
    //     if (imgs) {
    //         imgs.forEach(img => {
    //             // 获取sku属性和图片路径
    //             let sku = img.getAttribute("data-sku")
    //             // let src = img.getAttribute("data-src")
    //             let id = img.getAttribute("data-id")
    //             let newImgSKU = new Image()
    //             newImgSKU.src = `https://pic.bmcsoft.cn/it/${shopName}/${sku}.jpg`
    //             // 当图片加载完毕
    //             newImgSKU.onload = () => {
    //                 // 加载成功则添加src
    //                 img.setAttribute('src', `https://pic.bmcsoft.cn/it/${shopName}/${sku}.jpg`)
    //             }
    //             newImgSKU.onerror = (e) => {
    //                 let newImgId = new Image()
    //                 newImgId.src = `https://pic.bmcsoft.cn/it/${shopName}/${id}.jpg`
    //                 newImgId.onload = () => {
    //                     // 加载成功则添加src
    //                     img.setAttribute('src', `https://pic.bmcsoft.cn/it/${shopName}/${id}.jpg`)
    //                 }
    //                 newImgId.onerror = (e) => {
    //                     // 暂无图片的时候，采用默认的src
    //                     img.setAttribute('src', `https://gimg2.baidu.com/image_search/src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20170712%2F713ea78d708c4e4696ddfc78c1c3f2c5.png&refer=http%3A%2F%2F5b0988e595225.cdn.sohucs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653630406&t=74bf22102152d082aa1cae092af719f1`)
    //                 }
    //             }
    //             // 如果两者不为空，新建Image对象加载图片
    //             // if (sku && src) {
    //             //     let newImg = new Image()
    //             //     // 加载图片
    //             //     newImg.src = src;
    //             //     // 当图片加载完毕
    //             //     newImg.onload = () => {
    //             //         // 加载成功则添加src
    //             //         img.setAttribute('src', src)
    //             //         // 加载成功将data-src置空，防止重复加载
    //             //         img.removeAttribute('data-src')
    //             //     }
    //             //     newImg.onerror = (e) => {
    //             //         console.log("加载失败");
    //             //     }
    //             // }
    //         })
    //     }
    // }

    function getData(id) {
        let data = {}
        data.start_month = startMonth.format('YYYY-MM')
        data.end_month = endMonth.format('YYYY-MM')
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.shop = shopName
        data.item_id = id
        setInfoLoading(true)
        setItemInfo([])
        return NbAxios(data, "POST", servicePath.getSingleAnalysisData1).then((res, rej) => {
            if (res.success) {
                let data = res.data
                setItemInfo(data.table1)
                setTableData(data.table2)
                let chartData = data.table2_e
                setChartData(chartData);
                setPayNum(chartData.销量.data.reverse())
                setPayMoney(chartData.金额.data.reverse())
                setChangeRate(chartData.转化率.data.reverse())
                setVisitor(chartData.访客.data.reverse())
                setDiscount(chartData.折扣.data.reverse())
                setReadyNum(chartData.备货参考.data.reverse())
                setAddRate(chartData.加购.data.reverse())
                setOnePrice(chartData.件单.data.reverse())
                setSummer(chartData.产销春夏.data.reverse())
                setWinter(chartData.产销秋冬.data.reverse())
            } else {
                message.warn(res.errorMsg)
            }
            data = null
            setInfoLoading(false)
            return true
        }).catch(e => {
            message.error("出现错误！")
            data = null
        })
    }
}


// function DebounceSelect({ fetchOptions, debounceTimeout = 800,shopName, ...props }) {
//     const [fetching, setFetching] = useState(false);
//     const [options, setOptions] = useState([]);
//     const fetchRef = React.useRef(0);
//     const debounceFetcher = React.useMemo(() => {
//         const loadOptions = (value) => {
//             fetchRef.current += 1;
//             const fetchId = fetchRef.current;
//             setOptions([]);
//             setFetching(true);
//             fetchOptions(value).then((newOptions) => {
//                 if (fetchId !== fetchRef.current) {
//                     // for fetch callback order
//                     return;
//                 }
//                 setOptions(newOptions);
//                 setFetching(false);
//             });
//         };

//         return debounce(loadOptions, debounceTimeout);
//     }, [fetchOptions, debounceTimeout]);
//     return (
//         <Select
//             labelInValue
//             filterOption={false}
//             onSearch={debounceFetcher}
//             notFoundContent={fetching ? <Spin size="small" /> : null}
//             {...props}
//             options={options}
//         />
//     );
// }

async function fetchUserList(id, shopName) {
    let data = {}
    data.roleId = cookie.load('role')
    data.shopId = cookie.load('shopList')
    data.shop = shopName
    data.key_word = id
    return NbAxios(data, "POST", servicePath.getItemInfoList).then((res) =>
        res.data.map((user) => ({
            label: `${user.sku}`,
            value: user.item_id,
        })),
    );
}
export default SkuAnaysis