import React, { useEffect } from 'react';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import Debounce from 'lodash/debounce'

function PieChart({
    id,
    title,
    legend = [],
    data = [],
    height,
    isClick=false,
    setProvince,
    ...rest
}){

    let report = {
        title: {
            text: title,
            left: 'center',
            textStyle:{
                fontSize:20
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: legend,
            top: 'bottom'
        },
        series: [
            {
                name: title,
                type: 'pie',
                selectedMode: 'single',
                radius: '50%',
                // label: {
                //     position: 'inner',
                //     fontSize: 14,
                // },
                // data: addCarConsumerDate,
                data:  data,
                itemStyle: {
                    normal: {
                        label: {
                            show: true,
                            formatter: '{b} : {c} ({d}%)',
                            textStyle:{
                                fontSize:15
                            }
                        },
                        labelLine: { show: true }
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
    useEffect(() => {
        // 基于准备好的dom，初始化echarts实例
        let reportChart = echarts.init(document.getElementById(id));
        reportChart.clear()
        // 绘制图表
        reportChart.setOption(report)
        if(isClick){
            reportChart.off('click')
            reportChart.on('click', function (param) {
                setProvince(param.data.name);
            })
        }
        // window捕获标签点击，表格重绘放到浏览器渲染前
        window.addEventListener('click', e => {
            if (e.target.getAttribute("class") === "ant-tabs-tab-btn") {
                requestAnimationFrame(() =>reportChart.resize({
                        animation:{duration:300,easing:"linear",delay:1}
                    })
                )
            }
        }, true);
        window.addEventListener('resize', Debounce(() => reportChart.resize(), 100));
        return () => {
            // window捕获标签点击，表格重绘放到浏览器渲染前
            window.addEventListener('click', e => {
                if (e.target.getAttribute("class") === "ant-tabs-tab-btn") {
                    requestAnimationFrame(() =>reportChart.resize({
                            animation:{duration:300,easing:"linear",delay:1}
                        })
                    )
                }
            }, true);
            window.removeEventListener('resize', Debounce(() => reportChart.resize(), 100));
        }
    }, [data])

    return (
        <div id={id} style={{ width: '100%', height: height || "400px" }} {...rest} ></div>
    )
}

export default PieChart