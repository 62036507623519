import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Drawer, Form, Button, Table, Row, Modal, Radio, Input, Select, Descriptions, message, Upload, Col } from 'antd'
import NbAxios from "../../../config/utils/nbAxios"
import DownloadFile from "../../../config/utils/downloadFile"
import Axios from 'axios';
import servicePath from '../../../config/apiUrl'
import cookie from 'react-cookies'
import moment from 'moment'
const { Paragraph, Title, Text } = Typography;
const { Option } = Select;
// 人才库
function Index(props) {

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
    };
    const [form] = Form.useForm()
    // 表格加载状态
    const [tableLoading,setTableLoading] = useState(false)
    // 数据
    const [date, setDate] = useState([])
    //页面
    const [page, setPage] = useState(1)
    // 总数
    const [total, setTotal] = useState(0)
    // 搜索邮箱
    const [searchEmail, setSearchEmail] = useState('')
    // 搜索名字
    const [searchName, setSearchName] = useState('')
    // 搜索性别
    const [searchSex, setSearchSex] = useState('')
    // 搜索手机号
    const [searchPhone, setSearchPhone] = useState('')
    // 搜索地址
    const [searchAddress, setSearchAddress] = useState('')
    // 搜索学校
    const [searchSchool, setSearchSchool] = useState('')
    // 搜索专业
    const [searchMajor, setSearchMajor] = useState('')
    // 搜索学历
    const [searchEducation, setSearchEducation] = useState('')
    // 邮箱
    const [email, setEmail] = useState('')
    // 名字
    const [name, setName] = useState('')
    // 生日
    const [birth, setDirth] = useState('')
    // 性别
    const [sex, setSex] = useState('')
    // 手机号
    const [phone, setPhone] = useState('')
    // 地址
    const [address, setAddress] = useState('')
    // 学校
    const [school, setSchool] = useState('')
    // 专业
    const [major, setMajor] = useState('')
    // 学历
    const [education, setEducation] = useState('')
    // 毕业时间
    const [educationEndTime, setEducationEndTime] = useState('')
    // 简历名字
    const [ fileName, setFileName ] = useState('')
    // id
    const [ id , setId ] = useState('')
    // 打开搜索
    const [onSearch, setOnSearch] = useState(false)
    // 打开简历
    const [ openResume , setOpenResume ] = useState(false)
    // 表格标题
    const columns = [
        {
            title: '邮箱',
            align: 'center',
            dataIndex: 'email',
        },
        {
            title: '名字',
            align: 'center',
            dataIndex: 'name',
        },
        {
            title: '性别',
            align: 'center',
            dataIndex: 'gender',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            align: 'center',
            render: (text, record, index) => (
                text != null ?
                    <Paragraph copyable={{ tooltips: false }}>{text}</Paragraph>
                    : <span></span>
            ),
        },
        {
            title: '家庭所在地',
            align: 'center',
            dataIndex: 'address',
        },
        {
            title: '学校',
            align: 'center',
            dataIndex: 'school',
        },
        {
            title: '学历',
            align: 'center',
            dataIndex: 'education',
        },
        {
            title: '专业',
            align: 'center',
            dataIndex: 'major',
        },
        {
            title: '毕业时间',
            align: 'center',
            dataIndex: 'education_end_time',
            render: (text, record, index) => (
                text != null ?
                    moment(text).format('YYYY-MM-DD')
                    : <span></span>
            ),
        },
        {
            title: '注册时间',
            align: 'center',
            dataIndex: 'create_time',
            render: (text, record, index) => (
                text != null ?
                    moment(text).format('YYYY-MM-DD HH:mm:ss')
                    : <span></span>
            ),
        },
        {
            title: '简历详情',
            align: 'center',
            dataIndex: 'major',
            render: (text, record, index) => (
                text != null ?
                    <a onClick={() => {
                        setName(date[index]['name']);
                        setDirth(date[index]['birth']);
                        setEmail(date[index]['email']);
                        setSex(date[index]['gender']);
                        setPhone(date[index]['phone']);
                        setAddress(date[index]['address']);
                        setSchool(date[index]['school']);
                        setMajor(date[index]['major']);
                        setEducation(date[index]['education']);
                        setEducationEndTime(date[index]['education_end_time']);
                        setFileName(date[index]['file_name'])
                        setId(date[index]['uuid'])
                        setOpenResume(true)
                    }} style={{ textDecoration: 'underline' }}>点击查看</a>
                    : <span></span>
            ),
        },
        {
            title: '简历附件',
            dataIndex: 'file_name',
            align: 'center',
            render: (text, record, index) => (
                text != null ?
                    <a
                        onClick={()=>downloadFile(date[index]['uuid'])}
                        // href={servicePath.downloadResume + "?shopId=" + cookie.load('shopList') + "&roleId=" + cookie.load('role') + "&uuid=" + date[index]['uuid'] + "&principal_uuid=" + cookie.load('id')}
                        target="_blank" style={{ textDecoration: 'underline' }}>下载</a>
                    : <span></span>
            ),
        },
    ]

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }

    // 初始化数据
    useEffect(() => {
        getRecruitInformation()
    }, [page])

    // 获取人才库数据
    const getRecruitInformation = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "email": searchEmail,
            "name": searchName,
            "gender": searchSex,
            "phone": searchPhone,
            "address": searchAddress,
            "school": searchSchool,
            "major": searchMajor,
            "education": searchEducation,
            "page": page,
            "page_size": 10
        }
        setTableLoading(true)
        NbAxios(dataProps, "POST", servicePath.getRecruitInformation).then(res => {
            if (res.success) {
                setTotal(res.total);
                setDate(res.data);
                setOnSearch(false)
            } else {
                message.error(res.errorMsg)
            }
            setTableLoading(false)
        })
    }

    // 重置
    const restart = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "email": '',
            "name": '',
            "gender": '',
            "phone": '',
            "address": '',
            "school": '',
            "major": '',
            "education": '',
            "page": 1,
            "page_size": 10
        }
        setTableLoading(true)
        NbAxios(dataProps, "POST", servicePath.getRecruitInformation).then(res => {
            if (res.success) {
                setTotal(res.total);
                setDate(res.data);
                form.resetFields()
                setPage(1)
                setSearchEmail('')
                setSearchName('')
                setSearchSex('')
                setSearchPhone('')
                setSearchAddress('')
                setSearchSchool('')
                setSearchMajor('')
                setSearchEducation('')
                setOnSearch(false)
            } else {
                message.error(res.errorMsg)
            }
            setTableLoading(false)
        })
    }

    // 下载简历
    const downloadFile = (id)=>{
        let data = {}
        data.roleId = cookie.load('role')
        data.shopId = cookie.load('shopList')
        data.uuid = id
        data.principal_uuid = cookie.load('id')
        Axios({
            method: 'POST',
            url: servicePath.downloadResume,
            data: data,
            responseType: "blob",
            headers: {
                'authorization': cookie.load('token')
            },
        }).then(res=>{
            DownloadFile(res)
        })
    }

    return (
        <div>
            <div style={{ marginLeft: '50px', marginTop: '30px' }}>
                <Button type="primary" onClick={() => setOnSearch(true)}>查询</Button>
            </div>
            <div style={{ marginLeft: '50px', marginTop: '30px', marginRight: '50px' }}>
                <Table
                    loading={tableLoading}
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={paginationProps}
                />
            </div>
            <Drawer
                title="查询条件"
                placement="right"
                closable={false}
                onClose={() => setOnSearch(false)}
                visible={onSearch}
                width={1100}
            >
                <Form
                    {...layout}
                    form={form}
                    labelAlign="left"
                >
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                label="姓名"
                                name="searchName"
                                rules={[
                                    {
                                        //不能输入非汉字效验  效验不能输入非空字符串
                                        validator: (rule, value, callback) => {
                                            let reg = new RegExp("[`~!%@#$^&*()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）|{}【】‘；：”“'\"。，、？]");
                                            if (reg.test(value)) {
                                                return callback("请输入正确的姓名")
                                            } else {
                                                return callback();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div>
                                    <Input value={searchName} placeholder="请填写姓名" onChange={(e) => { setSearchName(e.target.value) }} />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="邮箱"
                                name="searchEmail"
                            >
                                <Input value={searchEmail} placeholder="请填写邮箱" onChange={(e) => setSearchEmail(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="性别"
                                name="searchSex"
                            >
                                <div>
                                    <Radio.Group value={searchSex} onChange={e => { setSearchSex(e.target.value); }} style={{ width: '600px' }}>
                                        <Radio value='男'>男</Radio>
                                        <Radio value='女'>女</Radio>
                                    </Radio.Group>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="手机号"
                                name="searchPhone"
                            >
                                <div>
                                    <Input value={searchPhone} placeholder="请填写手机号" onChange={(e) => { setSearchPhone(e.target.value) }} />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="家庭所在地"
                                name="searchAddress"
                            >
                                <div>
                                    <Input value={searchAddress} placeholder="请填写家庭所在地" onChange={(e) => { setSearchAddress(e.target.value) }} />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="学校"
                                name="searchAddress"
                            >
                                <div>
                                    <Input value={searchSchool} placeholder="请填写学校" onChange={(e) => { setSearchSchool(e.target.value) }} />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="专业"
                                name="searchMajor"
                            >
                                <div>
                                    <Input value={searchMajor} placeholder="请填写专业" onChange={(e) => { setSearchMajor(e.target.value) }} />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="学历"
                                name="searchAddress"
                            >
                                <Select defaultValue={searchEducation} placeholder="请填写学历" onChange={(value) => setSearchEducation(value)}>
                                    <Option value="专科">专科</Option>
                                    <Option value="本科">本科</Option>
                                    <Option value="硕士">硕士</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button type="primary" style={{marginLeft:'10px', marginRight:'10px'}} onClick={()=>getRecruitInformation()}>查询</Button>
                            <Button type="primary" danger onClick={()=>restart()}>重置</Button>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
            <Modal 
                title="简历详情" 
                visible={openResume} 
                footer={null}
                centered={true}
                onCancel={()=>{
                    setId('')
                    setOpenResume(false)
                }}
                width={1000}
            >
                <Descriptions title="个人信息" bordered style={{marginBottom:"10px"}}>
                    <Descriptions.Item label="姓名">{name}</Descriptions.Item>
                    <Descriptions.Item label="出生日期">{birth == ''? '' : moment(birth).format('YYYY-MM-DD')}</Descriptions.Item>
                    <Descriptions.Item label="性别">{sex}</Descriptions.Item>
                    <Descriptions.Item label="邮箱">{email}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{phone}</Descriptions.Item>
                    <Descriptions.Item label="家庭所在地">{address}</Descriptions.Item>
                </Descriptions>
                <Descriptions title="教育背景" bordered style={{marginBottom:"10px"}}>
                    <Descriptions.Item label="学历">{education}</Descriptions.Item>
                    <Descriptions.Item label="毕业时间">{educationEndTime == ''? '' : moment(educationEndTime).format('YYYY-MM-DD')}</Descriptions.Item>
                    <Descriptions.Item label="学校">{school}</Descriptions.Item>
                    <Descriptions.Item label="专业">{major}</Descriptions.Item>
                </Descriptions>
                <span><Title level={5}>附件简历</Title>  
                    <a onClick={()=>downloadFile(id)}
                        target="_blank">
                        <Text type="secondary">{fileName}</Text>
                    </a>
                </span>
            </Modal>
        </div>
    )

}

export default Index