import React, { useState } from 'react';
import { Tabs } from 'antd'
import DailyReport from './report/dailyReport'
import WeeklyReport from './report/weeklyReport'
const { TabPane } = Tabs;

function Member(props){

    // 当前tab值
    const [ curTabKey , setCurTabKey ] = useState('1')

    // 切换tab
    const changeTabs = (activeKey)=>{
        setCurTabKey(activeKey)
    }

    return(
        <>
             <Tabs style={{marginLeft:'10px'}} defaultActiveKey={curTabKey} tabPosition="left" onChange={changeTabs}>
                <TabPane disabled tab=" " key="0">
                </TabPane>
                <TabPane disabled tab=" " key="0">
                </TabPane>
                <TabPane disabled tab=" " key="0">
                </TabPane>
                <TabPane tab="日报" key="1">
                    {curTabKey ==='1' ? <DailyReport history={props.history}/>: <div></div> } 
                </TabPane>
                <TabPane tab="周报" key="2">
                    {curTabKey ==='2' ? <WeeklyReport history={props.history}/>: <div></div> } 
                </TabPane>
            </Tabs>
        </>
    )



}

export default Member