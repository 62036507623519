import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  message,
  Modal,
  Button,
  Spin,
  Table,
  Select,
  Progress,
} from "antd";
import { DownloadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import cookie from "react-cookies";
import servicePath from "../../../config/apiUrl";
import NbAxios from "../../../config/utils/nbAxios";
import axios from "axios";
import downExcel from "../../../config/utils/downloadFile";
import style from "./index.module.css";
import Barcode from "../../pos/components/barcode";
import staffList from "../data/staff.json";
const { Paragraph } = Typography;
const { Option } = Select;
const { TextArea } = Input;
function Create(props) {
  // 对象移除
  Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
      this.splice(index, 1);
    }
  };
  // 表头列表
  const [columnList] = useState([
    "序号",
    "商家编码",
    "数量",
    "金额",
    "结算价",
    "结算金额",
    "编辑",
  ]);
  // 打印表头列表
  const [printColumnList] = useState([
    "序号",
    "商家编码",
    "数量",
    "金额",
    "结算价",
    "结算金额",
  ]);
  // 录入商品详情列表
  const [detailInsertList] = useState(["商家编码", "数量", "金额", "结算价"]);
  // 导出权限校验
  const [jurisdiction] = useState([
    "于雪",
    "沈费",
    "唐铎",
    "叶康辉",
    "周浩琪",
    "姚凯鹏",
    "王修文",
    "陈黎黎",
    "曹守梅",
    // #I6K2J7 POS系统导出权限新增人员
    "徐悦",
    "徐慧慧",
  ]);
  // 设置表头
  const [column, setColumn] = useState([]);
  // 设置打印表头
  const [printColumn, setPrintColumn] = useState([]);
  // 条形码
  const [barCode, setBarCode] = useState("");
  // 推荐人
  const [staffName, setStaffName] = useState("");
  // 是否打开支付窗口
  const [isOrder, setIsOrder] = useState(false);
  // 是否打开备注窗口
  const [isRemark, setIsRemark] = useState(false);
  // 是否需要更改价格
  const [updateFlag, setUpdateFlag] = useState(true);
  // 订单编号
  const [orderCode, setOrderCode] = useState("");
  // 表格明细内容
  const [tableData, setTableData] = useState([]);
  // 是否有效
  const [loading, setLoading] = useState(false);
  // 是否在导出中
  const [exportFlag, setExportFlag] = useState(0);
  // 是否导出所有明细数据
  const [isExportAllModel, setIsExportAllModel] = useState(false);
  // 是否打开录入商品信息窗口
  const [isGoodsDetialInsert, setIsGoodsDetialInsert] = useState(false);
  // 商品信息
  const [goodsInfo, setGoodsInfo] = useState({
    商家编码: "",
    数量: "",
    金额: "",
    结算价: "",
  });
  // 备注
  const [remark, setRemark] = useState("");
  // 保存前备注
  const [OldRemark, setOldRemark] = useState("");
  // 总计计算和增加
  const totalAdd = (obj, setTableData) => {
    // 如果最后一列是总计，则删除
    if (obj[obj.length - 1]) {
      if (obj[obj.length - 1]["序号"] === "合计") {
        obj.pop();
      }
    }
    // 计算总计
    let sumds = 0;
    let num = 0;
    // let sumdj = 0;
    obj.map((item) => {
      // if (item["金额"]) {
      //   sumdj = sumdj + item["金额"];
      // }
      if (item["结算金额"]) {
        sumds = sumds + item["结算金额"];
      }
      if (item["数量"]) {
        num = num + item["数量"];
      }
    });
    obj.push({
      序号: "合计",
      // 金额: sumdj,
      结算金额: sumds,
      数量: num,
    });
    setTableData(obj);
  };
  // 查询条形码接口
  const getGoodsInfoByCode = (goods_code) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      goods_code: goods_code,
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.getGoodsInfoByCode)
      .then((res) => {
        setLoading(false);

        if (res.success) {
          setBarCode("");
          let obj = [...tableData];
          // 如果最后一列是总计，则删除
          if (obj[obj.length - 1]) {
            if (obj[obj.length - 1]["序号"] === "合计") {
              obj.pop();
            }
          }
          // 是否是重复商品
          let repeat = true;
          obj.map((item, index) => {
            // 如果是重复商品数量加一
            if (
              res.data["商家编码"] === item["商家编码"] &&
              res.data["结算价"] === item["结算价"]
            ) {
              repeat = false;
              item["数量"] = item["数量"] + 1;
            }
          });

          // 如果不是重复商品则添加进入列表
          if (repeat) {
            res.data["数量"] = 1;
            res.data["结算金额"] = res.data["结算价"] * res.data["数量"];
            obj.push(res.data);
          }
          // 添加序号
          obj.map((item, index) => {
            item["结算金额"] = item["结算价"] * item["数量"];
            item["序号"] = index + 1;
          });
          // 重新计算总计列
          totalAdd(obj, setTableData);
        } else {
          message.warn({ content: res.errorMsg });
          if (res.errorMsg === "未查询到商品") {
            setUpdateFlag(false);
            setIsGoodsDetialInsert(true);
            let obj = {
              商家编码: barCode,
              数量: "",
              金额: "",
              结算价: "",
            };
            setGoodsInfo(obj);
          }
          setBarCode("");
        }
      })
      .catch((err) => {
        setBarCode("");
        setLoading(false);
        // 获取500错误
        message.warn("查询条形码接口报错，请联系IT帮忙解决");
      });
  };
  // 删除商品
  const delGoods = (record) => {
    let obj = [...tableData];
    obj.remove(record);
    // 如果最后一列是总计，则删除
    if (obj[obj.length - 1]) {
      if (obj[obj.length - 1]["序号"] === "合计") {
        obj.pop();
      }
    }
    // 重新生成序号
    obj.map((item, index) => {
      item["序号"] = index + 1;
    });
    if (obj.length !== 0) {
      // 重新计算总计
      totalAdd(obj, setTableData);
    } else {
      setTableData(obj);
    }
  };
  // 生成订单
  const createOrderNew = () => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      user: cookie.load("name"),
      data: tableData,
      order_remarks: remark,
      referrer: staffName,
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.createOrderNew)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setOrderCode(res.data);
          setIsOrder(true);
          setRemark("");
          // props.history.push({ 'pathname': `/app/pos/initial` })
        } else {
          message.warn({ content: res.errorMsg });
        }
      })
      .catch((err) => {
        setLoading(false);
        // 获取500错误
        message.warn("生成订单报错，请联系IT帮忙解决");
      });
  };
  //该方法参数 content：需要打印的元素
  const printFun = (content) => {
    var Window = window.open(
      "",
      "页面打印",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no"
    );
    var style = "<style type='text/css'></style>";
    Window.document.write(content + style);
    Window.focus();
    Window.document.close(); //关闭document的输出流, 显示选定的数据
    Window.print(); //打印当前窗口
    return Window;
  };
  // 更改订单状态
  const modifyOrderStatusNew = (status) => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      name: cookie.load("name"),
      data: tableData,
      order_state: status,
      order_code: orderCode,
    };
    setLoading(true);
    NbAxios(dataProps, "POST", servicePath.modifyOrderStatusNew)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success("订单处理成功");
          // var windows = printFun(document.getElementById("print").innerHTML);
          // windows.close();
          // props.history.push({ pathname: `/app/pos/initial` });
          // 清空数据
          setIsOrder(false);
          setBarCode("");
          setRemark("");
          setStaffName("");
          setTableData([]);
        } else {
          message.warn({ content: res.errorMsg });
        }
      })
      .catch((err) => {
        setLoading(false);
        // 获取500错误
        message.warn("更改订单状态报错，请联系IT帮忙解决");
      });
  };

  // 合并相同结算价的商品
  const mergeGoods = (record, obj, index) => {
    obj.map((item, _index) => {
      // 如果列表中存在相同结算价且商家编码相同的商品就合并
      if (
        item["商家编码"] === record["商家编码"] &&
        item["结算价"] === record["结算价"] &&
        index !== _index // 确保不是与自己比较
      ) {
        item["数量"] += 1;
        item["结算金额"] = item["结算价"] * item["数量"];
        obj.remove(record);
      }
    });
    return obj;
  };
  useEffect(() => {
    setColumn(
      columnList.map((item, index) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          width: "5%",
          render: (text, record, index) => {
            return item === "编辑" && record["序号"] !== "合计" ? (
              <a
                onClick={() => {
                  // 删除商品
                  delGoods(record);
                }}
              >
                删除
              </a>
            ) : item === "结算价" && record["序号"] !== "合计" ? (
              <Paragraph
                style={{ margin: 0 }}
                onClick={() => setUpdateFlag(false)}
                editable={{
                  onChange: (value) => {
                    let reg = new RegExp("^[0-9.]*$");
                    if (reg.test(value) && value) {
                      let obj = [...tableData];
                      // 修改价格
                      obj[index][item] = parseFloat(value);
                      obj[index]["结算金额"] =
                        obj[index]["结算价"] * obj[index]["数量"];
                      // 合并相同价格列
                      obj = mergeGoods(record, obj, index);
                      // 重新计算总计列
                      totalAdd(obj, setTableData);
                      // 恢复输入框自动获取焦点
                      setUpdateFlag(true);
                    } else {
                      message.error("只能输入数字");
                    }
                  },
                }}
              >
                {typeof text === "number" ? text.toFixed(2) : text}
              </Paragraph>
            ) : item === "金额" || item === "结算金额" ? (
              typeof text === "number" ? (
                text.toFixed(2)
              ) : (
                text
              )
            ) : (
              text
            );
          },
        };
      })
    );
    setPrintColumn(
      printColumnList.map((item, index) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          align: "center",
          width: "5%",
          render: (text, record, index) => {
            return item !== "序号" && item !== "商家编码" && item !== "数量"
              ? typeof text === "number"
                ? text.toFixed(2)
                : text
              : text;
          },
          onCell: function (column) {
            return {
              style: {
                // width: 80,
                // overflow: 'hidden',
                // whiteSpace: 'nowrap',
                // textOverflow:'ellipsis',
                // cursor:'pointer',
                fontSize: 5,
              },
            };
          },
          onHeaderCell: function (column) {
            return {
              style: {
                // width: 80,
                // overflow: 'hidden',
                // whiteSpace: 'nowrap',
                // textOverflow:'ellipsis',
                // cursor:'pointer',
                fontSize: 5,
              },
            };
          },
        };
      })
    );
  }, [tableData]);

  // 导出销售订单明细
  const downloadStandardizedModel = () => {
    let dataProps = {
      roleId: cookie.load("role"),
      shopList: cookie.load("shopList"),
      name: cookie.load("name"),
    };
    setLoading(true);
    axios({
      data: dataProps,
      method: "POST",
      url: servicePath.downloadOrderDetailNew,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json", //如果写成contentType会报错
        authorization: cookie.load("token"),
      },
    })
      .then((res) => {
        setLoading(false);
        downExcel(res);
        setExportFlag(2);
      })
      .catch((e) => {
        setLoading(false);
        setExportFlag(3);
        message.error({ content: "文件制作出错，请联系it同事！" });
      });
  };
  return (
    <div className={style.initial}>
      <Spin spinning={loading} tip="数据加载中....">
        <div
          style={{
            marginTop: "20px",
            alignItems: "center",
            fontSize: "30px",
            cursor: "pointer",
            marginLeft: "50px",
          }}
          onClick={() =>
            props.history.push({ pathname: `/app/pos2023/initial` })
          }
        >
          <ArrowLeftOutlined />
          返回
        </div>
        <div>
          <div className={style.externalContent}>
            <div className={style.mak} style={{ fontSize: "40px" }}>
              销售订单明细
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <span style={{ marginRight: "10px" }}>商品条形码:</span>
                <Input
                  value={barCode}
                  onChange={(e) => {
                    setBarCode(e.target.value);
                  }}
                  // 自动获取焦点
                  ref={function (input) {
                    if (input != null && updateFlag) {
                      input.focus();
                    }
                  }}
                  onPressEnter={(e) => {
                    getGoodsInfoByCode(e.target.value);
                  }}
                  style={{ width: "200px" }}
                />
              </div>
              <div>
                <span style={{ marginRight: "10px" }}>员工:</span>
                <Select
                  value={staffName.split("-")[1]}
                  onChange={(value) => {
                    setStaffName(value);
                  }}
                  onClick={() => {
                    // 鼠标点击选择框时取消输入框的自动获取焦点
                    setUpdateFlag(false);
                  }}
                  onBlur={() => {
                    // 鼠标点击其他地方时焦点回到输入框
                    setUpdateFlag(true);
                  }}
                  style={{ width: "200px" }}
                  showSearch
                  allowClear
                >
                  {staffList.map((item) => (
                    <Option value={`${item.id}-${item.name}`}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Table
                columns={column}
                dataSource={tableData}
                size="small"
                pagination={false}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    if (jurisdiction.indexOf(cookie.load("name")) == -1) {
                      message.error("没有权限导出");
                    } else {
                      setIsExportAllModel(true);
                      setExportFlag(0);
                    }
                  }}
                  type="primary"
                >
                  <DownloadOutlined /> 导出销售订单明细
                </Button>
                <Button
                  className={style.buleButton}
                  onClick={() => {
                    setIsRemark(true);
                    setOldRemark(remark);
                  }}
                >
                  添加订单备注
                </Button>
              </div>
              <div className={style.endButton}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (tableData.length !== 0) {
                      createOrderNew();
                    } else {
                      message.warning("还未录入商品");
                    }
                  }}
                >
                  生成订单
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <Modal
        title="备注窗口"
        visible={isRemark}
        onCancel={() => {
          setIsRemark(false);
          setUpdateFlag(true);
          setRemark(OldRemark);
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                setRemark("");
                setIsRemark(false);
                setUpdateFlag(true);
              }}
            >
              清空并退出
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setIsRemark(false);
                setUpdateFlag(true);
              }}
            >
              保存
            </Button>
          </div>
        }
        // okText="确认"
        // cancelText="取消"
        centered={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ marginRight: "20px", fontSize: "16px" }}>备注:</div>
          <div>
            <TextArea
              style={{ width: "300px", height: 120 }}
              value={remark}
              onClick={() => setUpdateFlag(false)}
              onChange={(e) => setRemark(e.target.value)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="支付窗口"
        visible={isOrder}
        onCancel={() => {
          setIsOrder(false);
        }}
        // onOk={()=>{
        //     setIsOrder(false)
        // }}
        // okText="已支付"
        // cancelText="取消支付"
        centered={true}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ width: "88px" }}
              onClick={() => {
                message.success("取消支付成功");
                modifyOrderStatusNew("取消支付");
              }}
            >
              取消支付
            </Button>
            <Button
              style={{ width: "74px" }}
              type="primary"
              onClick={() => {
                modifyOrderStatusNew("已支付");
              }}
            >
              已支付
            </Button>
            <Button
              onClick={() => {
                // setIsOrder(false)
                // let newstr = document.getElementById("print").innerHTML;
                // let oldstr = document.body.innerHTML;
                // document.body.innerHTML = newstr;
                // window.print();
                // document.body.innerHTML = oldstr;
                // window.location.reload()
                var windows = printFun(
                  document.getElementById("print").innerHTML
                );
                windows.close();
              }}
            >
              打印
            </Button>
          </div>
        }
        width={1000}
      >
        {/* style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "120px" */}
        <div id="print">
          <h1 style={{ textAlign: "center" }}>销售订单明细</h1>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "500px" }}>
              <div style={{ display: "grid", gridTemplateColumns: "70px 1fr" }}>
                <div>交易时间:</div>
                <div>{moment().format("YYYY-MM-DD HH:mm:ss")}</div>
                <div>订单编号:</div>
                <div>{orderCode}</div>
                <div>单位:</div>
                <div>元</div>
              </div>
            </div>
            <div>
              {/* <BarCodeSVG /> */}
              <Barcode value={orderCode} height={50} width={2} />
            </div>
          </div>

          {/* <div style={{ fontSize: '30px' }}>总金额:{total}</div> */}
          <Table
            columns={printColumn}
            dataSource={tableData}
            size="small"
            pagination={false}
          />
          <div style={{ display: "flex" }}>
            <div style={{ width: "180px" }}>
              <div style={{ display: "grid", gridTemplateColumns: "70px 1fr" }}>
                <div>结算金额:</div>
                <div style={{ textAlign: "right" }}>
                  {tableData.length !== 0
                    ? tableData[tableData.length - 1]["结算金额"].toFixed(2) +
                      "元"
                    : ""}
                </div>
                {/* <div>应付金额:</div>
                <div style={{ textAlign: "right" }}>
                  {tableData.length !== 0
                    ? tableData[tableData.length - 1]["应付金额"].toFixed(2) +
                      "元"
                    : ""}
                </div> */}
              </div>
            </div>
            <div style={{ display: "inline-flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "70px 1fr",
                  height: "88px",
                  marginLeft: "120px",
                }}
              >
                <div>数量合计:</div>
                <div>
                  {tableData.length !== 0
                    ? tableData[tableData.length - 1]["数量"] + "件"
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            如需开票请持有此单在收银柜台登记，我司将以邮件形式将电子发票信息发送至您的邮箱！
          </div>
        </div>
      </Modal>
      <Modal
        title="录入商品"
        visible={isGoodsDetialInsert}
        onCancel={() => {
          setIsGoodsDetialInsert(false);
          setUpdateFlag(true);
        }}
        onOk={() => {
          let flag = false;
          detailInsertList.map((item) => {
            if (!goodsInfo[item]) {
              flag = true;
            }
          });
          if (flag) {
            message.warning("请将商品信息填写完整");
          } else {
            let obj = Object.assign({}, goodsInfo);
            obj["结算金额"] = obj["结算价"] * obj["数量"];
            obj["金额"] = parseFloat(obj["金额"]);
            let data = [...tableData];
            // 如果最后一列是总计，则删除
            if (data[data.length - 1]) {
              if (data[data.length - 1]["序号"] == "合计") {
                data.pop();
              }
            }
            data.push(obj);
            // 添加序号
            data.map((item, index) => {
              item["序号"] = index + 1;
            });
            totalAdd(data, setTableData);
            setIsGoodsDetialInsert(false);
            setUpdateFlag(true);
          }
        }}
        okText="确认"
        cancelText="取消"
        centered={true}
        width={800}
      >
        {/* <Image src='https://pic.bmcsoft.cn/it/JORYA/656728153951.jpg' style={{ width: "105px", height: "105px" }}/> */}
        {/* <img src={detailData.url} style={{ width: '150px', height: '150px' }} /> */}
        <div>
          <h3 style={{ textAlign: "center" }}>
            请再次确认查无商品,需要自己录入
          </h3>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50% 1fr",
              fontSize: "15px",
            }}
          >
            {detailInsertList.map((item) => {
              return (
                <div
                  style={{ display: "grid", gridTemplateColumns: "30% 1fr" }}
                >
                  <div style={{ textAlign: "right", marginTop: "23px" }}>
                    {item}:
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      marginLeft: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <Input
                      value={goodsInfo[item]}
                      onChange={(e) => {
                        let obj = Object.assign({}, goodsInfo);
                        if (
                          item == "数量" ||
                          item == "金额" ||
                          item == "结算价"
                        ) {
                          let reg = new RegExp("^[0-9.]*$");
                          if (reg.test(e.target.value)) {
                            obj[item] = parseFloat(e.target.value);
                          } else {
                            message.error("只能输入数字");
                          }
                        } else {
                          obj[item] = e.target.value;
                        }
                        setGoodsInfo(obj);
                      }}
                      disabled={item == "商家编码" ? true : false}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      <Modal
        title="导出订单销售明细"
        visible={isExportAllModel}
        centered={true}
        onCancel={() => {
          if (exportFlag == 1) {
            message.warning("导出中,请勿操作");
          } else {
            setIsExportAllModel(false);
          }
        }}
        onOk={() => {
          if (cookie.load("name"))
            if (exportFlag == 1) {
              message.warning("导出中,请勿操作");
            } else if (exportFlag == 2) {
              setIsExportAllModel(false);
            } else {
              const getAllData = async () => {
                await Promise.all([
                  downloadStandardizedModel(),
                  setExportFlag(1),
                ]);
              };
              getAllData();
            }
        }}
        okText="确认"
        cancelText="取消"
      >
        {/* <Spin style={{ display: tabKey == 'productList' ? '' : 'none' }} spinning={loading} tip="数据加载中...."> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <ExclamationCircleOutlined style={{color:"#a3986d",width:'30px'}}/> */}
          {exportFlag == 0 ? (
            <Progress type="circle" percent={0} />
          ) : exportFlag == 1 ? (
            <Spin size="large" />
          ) : exportFlag == 2 ? (
            <Progress type="circle" percent={100} />
          ) : (
            <Progress type="circle" percent={70} status="exception" />
          )}
        </div>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {exportFlag == 0 ? (
            <b>确认导出所有销售订单明细吗？</b>
          ) : exportFlag == 1 ? (
            <b>导出中</b>
          ) : exportFlag == 2 ? (
            <b>导出完成</b>
          ) : (
            <b>导出失败</b>
          )}
        </div>
        {/* </Spin> */}
      </Modal>
    </div>
  );
}

export default Create;
