import React, { useEffect, useState } from 'react';
import { DatePicker, Typography, Checkbox, message, Modal, Button, Spin } from 'antd'
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import cookie from 'react-cookies'
import servicePath from '../../../config/apiUrl';
import NbAxios from '../../../config/utils/nbAxios';
import LineChart from '../components/lineChart'
const { Title, } = Typography;
const monthDateFormat = 'YYYY-MM';
function Index(props) {
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'qda': '2715f3fa-0dae-4dc7-bcfc-0dd912314e40',
        'gs':'703247c1-a200-42bc-b70f-742dbab27aa4'
    }
    function disabledDate(current) {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }
    // 商品月份
    const [commodityMonth, setCommodityMonth] = useState(moment().startOf('month').format('YYYY-MM'))
    // 产品线
    const [productLineList, setProductLineList] = useState([])
    const [productLineCheck, setProductLineCheck] = useState(true)
    const [productLine, setProductLine] = useState([])
    // 季节
    const [seasonList, setSeasonList] = useState(['春', '夏', '秋', '冬'])
    const [seasonCheck, setSeasonCheck] = useState(true)
    const [season, setSeason] = useState(['春', '夏', '秋', '冬'])
    // 年份
    const [yearList, setYearList] = useState([])
    const [yearCheck, setYearCheck] = useState(true)
    const [year, setYear] = useState([])
    // 品类
    const [categoryList, setCategoryList] = useState([])
    const [categoryCheck, setCategoryCheck] = useState(false)
    const [category, setCategory] = useState([])
    // 打开单个品类
    const [openCategory, setOpenCategory] = useState(false)
    // 单个品类
    const [chooseCategory, setChooseCategory] = useState('')
    // 核心品类
    const [ coreCategoryLegend, setCoreCategoryLegend] = useState([])
    const [ coreCategoryXAxis, setCoreCategoryXAxis] = useState([])
    const [ coreCategoryData, setCoreCategoryData] = useState([])
    const [ coreCategoryLoading, setCoreCategoryLoading] = useState(false)
    // 品类
    const [ categoryLegend, setCategoryLegend] = useState([])
    const [ categoryXAxis, setCategoryXAxis] = useState([])
    const [ categoryData, setCategoryData] = useState([])
    // 加购TOP
    const [ addOnTopLegend, setAddOnTopLegend] = useState([])
    const [ addOnTopXAxis, setAddOnTopXAxis] = useState([])
    const [ addOnTopData, setAddOnTopData] = useState([])
    const [ addOnTopLoading, setAddOnTopLoading] = useState(false)
    // 品类累计加购
    const [ addOnCategoryLegend, setAddOnCategoryLegend] = useState([])
    const [ addOnCategoryXAxis, setAddOnCategoryXAxis] = useState([])
    const [ addOnCategoryData, setAddOnCategoryData] = useState([])
    const [ addOnCategoryDataLoading, setAddOnCategoryDataLoading] = useState(false)
    // 品类累计支付
    const [ payCategoryLegend, setPayCategoryLegend] = useState([])
    const [ payCategoryXAxis, setPayCategoryXAxis] = useState([])
    const [ payCategoryData, setPayCategoryData] = useState([])
    const [ payCategoryLoading, setPayCategoryLoading] = useState(false)
    // 核心品类图表
    let coreCategoryChart = {
        title: {
            text: '核心品类加购趋势(手动选择)'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: coreCategoryLegend
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: coreCategoryXAxis,
            axisLabel:{
                rotate:60,
            }
        },
        yAxis: {
            type: 'value'
        },
        series: coreCategoryData
    }
    // TOP图表
    let topChart = {
        title: {
            text: 'TOP款加购趋势'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: addOnTopLegend
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: addOnTopXAxis,
            axisLabel:{
                rotate:60,
            }
        },
        yAxis: {
            type: 'value'
        },
        series:addOnTopData
    }
    // 品类加购
    let categoryAddChart = {
        title: {
            text: '品类加购'
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (params, ticket, callback) {
                var showHtm="";
                for(var i=0;i<params.length;i++){
                    //x轴名称
                    var name = params[i].seriesName;
                    // 名称
                    var marker = params[i].marker;
                    //值
                    var value = params[i].value;
                    if(name == '同比'){
                        showHtm+= marker +  name + '\xa0\xa0\xa0' +  value + '%<br>'
                    }else{
                        showHtm+= marker + name + '\xa0\xa0\xa0' +  value + '<br>'
                    }
                    
                }
                return showHtm;
            }
        },
        legend: {
            data: addOnCategoryLegend
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: addOnCategoryXAxis,
            axisLabel:{
                rotate:60,
            }
        },
        yAxis: [
            {
                type: 'value',
                name: '加购',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: '同比',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: addOnCategoryData
    }
    // 品类销售
    let categorySaleChart = {
        title: {
            text: '品类销售'
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (params, ticket, callback) {
                var showHtm="";
                for(var i=0;i<params.length;i++){
                    //x轴名称
                    var name = params[i].seriesName;
                    // 名称
                    var marker = params[i].marker;
                    //值
                    var value = params[i].value;
                    if(name == '同比'){
                        showHtm+= marker +  name + '\xa0\xa0\xa0' +  value + '%<br>'
                    }else{
                        showHtm+= marker + name + '\xa0\xa0\xa0' +  value + '<br>'
                    }
                    
                }
                return showHtm;
            }
        },
        legend: {
            data: payCategoryLegend
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: payCategoryXAxis,
            axisLabel:{
                rotate:60,
            }
        },
        yAxis: [
            {
                type: 'value',
                name: '加购',
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: '同比',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: payCategoryData
    }

    useEffect(()=>{
        const getData = async () => {
            await Promise.all([
                searchCommodityBasicCategory(),
                searchCommodityBasicProductLine(),
                searchCommodityBasicYear(),
            ])
        }
        getData()
    },[])

    useEffect(()=>{
        const getData = async () => {
            await Promise.all([
                getCategoryAdditionalPurchase(),
                getCategoryAdditionalTop(),
                cumulativeAdditionalYear(),
                cumulativePayYear()
            ])
        }
        getData()
    },[commodityMonth])

    useEffect(() => {
        if (productLine.length == productLineList.length) {
            setProductLineCheck(true)
        } else {
            setProductLineCheck(false)
        }
    }, [productLine])

    useEffect(() => {
        if (season.length == seasonList.length) {
            setSeasonCheck(true)
        } else {
            setSeasonCheck(false)
        }
    }, [season])

    useEffect(() => {
        if (year.length == yearList.length) {
            setYearCheck(true)
        } else {
            setYearCheck(false)
        }
    }, [year])
    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('coreCategoryChart'));
        reportChart.clear()
        reportChart.off('click')
        // 绘制图表
        reportChart.setOption(coreCategoryChart);
        // 图表点击事件
        reportChart.on('click', function (param) {
            setChooseCategory(param.seriesName);
            getCategoryAdditionalYear(param.seriesName)
        })
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [coreCategoryData])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('topChart'));
        reportChart.clear()
        // 绘制图表
        reportChart.setOption(topChart);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [addOnTopData])

    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('categoryAddChart'));
        reportChart.clear()
        // 绘制图表
        reportChart.setOption(categoryAddChart);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [addOnCategoryData])
    useEffect(() => {
        let reportChart = echarts.init(document.getElementById('categorySaleChart'));
        reportChart.clear()
        // 绘制图表
        reportChart.setOption(categorySaleChart);
        window.addEventListener('resize', () => {
            reportChart.resize()
        });
    }, [payCategoryData])

    // 查询产品线数据
    const searchCommodityBasicProductLine = ()=>{
        let dateProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "basic": "product_line",
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dateProps, "POST", servicePath.searchCommodityBasic).then(res => {
            if (res.success) {
                setProductLineList(res.data);
                setProductLine(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('查询产品线数据出错，请联系IT帮忙解决')
        })
    }

    // 查询年份数据
    const searchCommodityBasicYear = ()=>{
        let dateProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "basic": "year",
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dateProps, "POST", servicePath.searchCommodityBasic).then(res => {
            if (res.success) {
                setYearList(res.data);
                setYear(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('查询年份数据出错，请联系IT帮忙解决')
        })
    }

    // 查询品类数据
    const searchCommodityBasicCategory = ()=>{
        let dateProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "basic": "category2",
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dateProps, "POST", servicePath.searchCommodityBasic).then(res => {
            if (res.success) {
                setCategoryList(res.data);
                let obj = []
                obj.push(res.data[0])
                obj.push(res.data[1])
                obj.push(res.data[2])
                setCategory(obj)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('查询品类数据出错，请联系IT帮忙解决')
        })
    }

    // 按品类获取每日加购数据接口
    const getCategoryAdditionalPurchase = ()=>{
        let dateProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "category_name": category,
            "season":season,
            "year":year,
            "year_month":commodityMonth,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'product_line':productLine
        }
        setCoreCategoryLoading(true)
        NbAxios(dateProps, "POST", servicePath.getCategoryAdditionalPurchase).then(res => {
            setCoreCategoryLoading(false)
            if (res.success) {
                setCoreCategoryLegend(res.data[0].legend);
                setCoreCategoryXAxis(res.data[0].xAxis);
                setCoreCategoryData(res.data[0].data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setCoreCategoryLoading(false)
            message.warn('按品类获取每日加购数据出错，请联系IT帮忙解决')
        })
    }

    // 按品类获取每日加购数据接口
    const getCategoryAdditionalYear = (category_name)=>{
        let dateProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "category_name": category_name,
            "season":season,
            "year":year,
            "year_month":commodityMonth,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'product_line':productLine
        }
        setCoreCategoryLoading(true)
        NbAxios(dateProps, "POST", servicePath.getCategoryAdditionalYear).then(res => {
            setCoreCategoryLoading(false)
            if (res.success) {
                setCategoryLegend(res.data[0].legend);
                setCategoryXAxis(res.data[0].xAxis);
                setCategoryData(res.data[0].data);
                setOpenCategory(true)
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setCoreCategoryLoading(false)
            message.warn('按品类获取每日加购数据出错，请联系IT帮忙解决')
        })
    }

    // 获取日加购数据top接口
    const getCategoryAdditionalTop = ()=>{
        let dateProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "season":season,
            "year":year,
            "year_month":commodityMonth,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'product_line':productLine
        }
        setAddOnTopLoading(true)
        NbAxios(dateProps, "POST", servicePath.getCategoryAdditionalTop).then(res => {
            setAddOnTopLoading(false)
            if (res.success) {
                setAddOnTopLegend(res.data[0].legend);
                setAddOnTopXAxis(res.data[0].xAxis);
                setAddOnTopData(res.data[0].data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setAddOnTopLoading(false)
            message.warn('按品类获取每日加购数据出错，请联系IT帮忙解决')
        })
    }
    // 按品类获取年累计加购数据及同比
    const cumulativeAdditionalYear = ()=>{
        let dateProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "season":season,
            "year":year,
            "year_month":commodityMonth,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'product_line':productLine
        }
        setAddOnCategoryDataLoading(true)
        NbAxios(dateProps, "POST", servicePath.cumulativeAdditionalYear).then(res => {
            setAddOnCategoryDataLoading(false)
            if (res.success) {
                setAddOnCategoryLegend(res.data[0].legend);
                setAddOnCategoryXAxis(res.data[0].xAxis);
                setAddOnCategoryData(res.data[0].data);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            // 获取500错误
            setAddOnCategoryDataLoading(false)
            message.warn('按品类获取年累计加购数据及同比数据出错，请联系IT帮忙解决')
        })
    }

    // 按品类获取年累计加购数据及同比
    const cumulativePayYear = ()=>{
        let dateProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "season":season,
            "year":year,
            "year_month":commodityMonth,
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
            'product_line':productLine
        }
        setPayCategoryLoading(true)
        NbAxios(dateProps, "POST", servicePath.cumulativePayYear).then(res => {
            setPayCategoryLoading(false)
            if (res.success) {
                setPayCategoryLegend(res.data[0]['legend']);
                setPayCategoryXAxis(res.data[0]['xAxis']);
                setPayCategoryData(res.data[0]['data']);
            } else {
                message.warn(res.errorMsg)
            }
        }).catch((err) => {
            setPayCategoryLoading(false)
            // 获取500错误
            message.warn('按品类获取年累计加购数据及同比数据出错，请联系IT帮忙解决')
        })
    }

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: "space-around",
                backgroundColor: "#fff",
                borderBottom: "solid 2px #ccc",
                marginBottom: 10,
                position: 'fixed',
                top: 40,
                zIndex: 99,
                width: '100vw',
                paddingTop:10
            }}>
                <Title level={3} style={{ margin: 0 }} >商品日报</Title>
                <DatePicker format="YYYY-MM" picker="month" style={{ height: 30 }} disabledDate={disabledDate} locale={locale} defaultValue={commodityMonth == '' ? '' : moment(commodityMonth, monthDateFormat)}
                    onChange={(date, dateString) => {
                        if (dateString == '') {
                            message.warn('请选择合理的时间')
                            return false
                        } else {
                            setCommodityMonth(dateString)
                        }
                    }} />
            </div>
            <div style={{marginLeft:20, marginTop:80, marginRight:20, paddingBottom:200}}>
                <div style={{borderBottom: "1px solid #ccc", paddingBottom:10}}>
                    <div style={{display:'grid', gridTemplateColumns:'1fr 100px'}}>
                        <div></div>
                        <Button style={{ padding: "0 5px", width: 40, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "#ffffff" }}
                        type="primary" onClick={()=>{
                            const getData = async () => {
                                await Promise.all([
                                    getCategoryAdditionalPurchase(),
                                    getCategoryAdditionalTop(),
                                    cumulativeAdditionalYear(),
                                    cumulativePayYear()
                                ])
                            }
                            getData()
                        }}>确定</Button>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr" }}>
                        <div>
                            <b>产品线</b>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Checkbox style={{marginLeft:8}} checked={productLineCheck} onChange={(e) => {
                                if (e.target.checked) {
                                    setProductLineCheck(true)
                                    setProductLine(productLineList)
                                } else {
                                    setProductLineCheck(false)
                                    setProductLine([])
                                }
                            }}>全部</Checkbox>
                            {
                                productLineList.map(item => {
                                    return <Checkbox checked={productLine.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...productLine]
                                            obj.push(item)
                                            setProductLine(obj)
                                        } else {
                                            let obj = [...productLine]
                                            obj.remove(item)
                                            setProductLine(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr" }}>
                        <div>
                            <b>季节</b>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Checkbox style={{marginLeft:8}} checked={seasonCheck} onChange={(e) => {
                                if (e.target.checked) {
                                    setSeasonCheck(true)
                                    setSeason(seasonList)
                                } else {
                                    setSeasonCheck(false)
                                    setSeason([])
                                }
                            }}>全部</Checkbox>
                            {
                                seasonList.map(item => {
                                    return <Checkbox checked={season.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...season]
                                            obj.push(item)
                                            setSeason(obj)
                                        } else {
                                            let obj = [...season]
                                            obj.remove(item)
                                            setSeason(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: "100px 1fr"}}>
                        <div>
                            <b>年份</b>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Checkbox style={{marginLeft:8}} checked={yearCheck} onChange={(e) => {
                                if (e.target.checked) {
                                    setYearCheck(true)
                                    setYear(yearList)
                                } else {
                                    setYearCheck(false)
                                    setYear([])
                                }
                            }}>全部</Checkbox>
                            {
                                yearList.map(item => {
                                    return <Checkbox checked={year.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                        if (e.target.checked) {
                                            let obj = [...year]
                                            obj.push(item)
                                            setYear(obj)
                                        } else {
                                            let obj = [...year]
                                            obj.remove(item)
                                            setYear(obj)
                                        }
                                    }}>{item}</Checkbox>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: "100px 1fr 100px", marginTop: 10 }}>
                    <div>
                        <b>核心品类</b>
                    </div>
                    <div style={{ display: 'flex', flexWrap:'wrap' }}>
                        <Checkbox style={{marginLeft:8}} checked={categoryCheck} onChange={(e) => {
                            if (e.target.checked) {
                                setCategoryCheck(true)
                                setCategory(categoryList)
                            } else {
                                setCategoryCheck(false)
                                setCategory([])
                            }
                        }}>全部</Checkbox>
                        {
                            categoryList.map(item => {
                                return <Checkbox checked={category.indexOf(item) != -1 ? true : false} onChange={(e) => {
                                    if (e.target.checked) {
                                        let obj = [...category]
                                        obj.push(item)
                                        setCategory(obj)
                                    } else {
                                        let obj = [...category]
                                        obj.remove(item)
                                        setCategory(obj)
                                    }
                                }}>{item}</Checkbox>
                            })
                        }
                    </div>
                    <Button style={{ padding: "0 5px", width: 40, height: 26, display: "flex", justifyContent: "center", alignItems: "center", color: "#ffffff" }}
                    type="primary" onClick={()=>{
                        getCategoryAdditionalPurchase()
                    }}>确定</Button>
                </div>
                <Spin spinning={coreCategoryLoading}>
                    <div id="coreCategoryChart" style={{ width: '100%', height: '300px' }}></div>
                </Spin>
                <Spin spinning={addOnTopLoading}>
                    <div id="topChart" style={{ width: '100%', height: '300px' }}></div>
                </Spin>
                <Spin spinning={addOnCategoryDataLoading}>
                    <div id="categoryAddChart" style={{ width: '100%', height: '300px' }}></div>
                </Spin>
                <Spin spinning={payCategoryLoading} >
                    <div id="categorySaleChart" style={{ width: '100%', height: '300px'}}></div>
                </Spin>
            </div>

            <Modal
                visible={openCategory}
                footer={[]}
                onCancel={() => {
                    setOpenCategory(false)
                }}
                width="60vw"
                centered={true}
            >
                <LineChart chooseCategory={chooseCategory} categoryLegend={categoryLegend} categoryData={categoryData} categoryXAxis={categoryXAxis} />
            </Modal>
        </div>
    )

}

export default Index