import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd'
import Team from './member/team'
import Personage from './member/personage'
const { TabPane } = Tabs;
function Member(props){

    // 当前tab值
    const [ curTabKey , setCurTabKey ] = useState('1')

    // 获取项目id
    const [ projectId , setProjectId ] = useState('')

    // 切换tab
    const changeTabs = (activeKey)=>{
        setCurTabKey(activeKey)
    }

    return(
        <div>
             <Tabs style={{marginLeft:'10px'}} defaultActiveKey={curTabKey} tabPosition="left" onChange={changeTabs}>
                <TabPane disabled tab=" " key="0">
                </TabPane>
                <TabPane disabled tab=" " key="0">
                </TabPane>
                <TabPane disabled tab=" " key="0">
                </TabPane>
                <TabPane tab="团队" key="1">
                    {curTabKey ==='1' ? <Team history={props.history} project = {props.location.state.project}/>: <div></div> } 
                </TabPane>
                <TabPane tab="个人" key="2">
                    {curTabKey ==='2' ? <Personage history={props.history} project = {props.location.state.project}/>: <div></div> } 
                </TabPane>
            </Tabs>
        </div>
    )



}

export default Member