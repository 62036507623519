import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Table, Button, Tooltip, Col, Input, Popconfirm, Select, Modal, DatePicker, Drawer, Radio, Tag, message } from 'antd'
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import '../../static/css/index.css'
const { RangePicker } = DatePicker;
const {Option} = Select
function Promote(props) {

    const [form] = Form.useForm();

    const layout1 = {
        labelCol: { span: 5 },
        wrapperCol: { span: 20 },
    };

    // 数据
    const [date, setDate] = useState([])

    // 页数
    const [page, setPage] = useState(1)

    // 总数
    const [total, setTotal] = useState('')

    //联系方式
    const [contact, setContact] = useState('')

    // 推广人员
    const [ principal , setPrincipal ] = useState('')

    // 平台
    const [ platform , setPlatform ] = useState('')

    // 产品
    const [ product , setProduct ] = useState('')

    // KOL名字
    const [ name , setName ] = useState('')

    // 推广开始时间
    const [ startTime , setStartTime ] = useState('')

    // 推广结束时间
    const [ endTime , setEndTime ] = useState('')

    // 创建开始时间
    const [ createStartTime , setCreateStartTime ] = useState('')

    // 创建结束时间
    const [ createEndTime , setCreateEndTime ] = useState('')

    // 排序规则
    const [ orderByRule , setOrderByRule ] = useState('')

    // 评级
    const [ rate , setRate ] = useState('')

    // 打开查询条件
    const [openSearch, setOpenSearch] = useState(false)

    // 查看详情
    const [ lookDetail , setLookDetail ] = useState(false)

    //是否打开联系方式弹框
    const [isPhone, setIsPhone] = useState(false)

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        total: total,
        current: page,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }


    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 表格标题
    const columns = [
        {
            title: '负责人',
            key: 'principal',
            dataIndex: 'principal',
            width: '80px',
            align: 'center',
        },
        {
            title: '推广品牌',
            key: 'trademark',
            dataIndex: 'trademark',
            width: '80px',
            align: 'center',
        },
        {
            title: '推广产品',
            key: 'product',
            dataIndex: 'product',
            width: '80px',
            align: 'center',
        },
        {
            title: '平台',
            key: 'platform',
            dataIndex: 'platform',
            width: '80px',
            align: 'center',
            render: (text, record, index) => {
                return  <a href={date[index]['platform_url']} target="_blank" style={{textDecoration:'underline'}}>{text}</a>
            }
        },
        {
            title: '红人',
            key: 'kol_name',
            dataIndex: 'kol_name',
            width: '80px',
            align: 'center',
            render: (text, record, index) => {
                return  <a onClick={()=>getPhone(date[index]['id'])} style={{textDecoration:'underline'}}>{text}</a>
                
            }
        },
        {
            title: '粉丝数',
            key: 'fans',
            dataIndex: 'fans',
            width: '80px',
            align: 'center',
        },
        {
            title: '费用',
            key: 'cost',
            dataIndex: 'cost',
            width: '80px',
            align: 'center',
        },
        {
            title: '总藏赞',
            key: 'praise',
            dataIndex: 'praise',
            width: '80px',
            align: 'center',
        },
        {
            title: '平均阅读数',
            key: 'avgRead',
            dataIndex: 'avgRead',
            width: '120px',
            align: 'center',
        },
        {
            title: '平均互动量',
            key: 'avgInteraction',
            dataIndex: 'avgInteraction',
            width: '120px',
            align: 'center',
        },
        {
            title: '合作形式',
            key: 'status',
            dataIndex: 'status',
            width: '80px',
            align: 'center',
        },
        {
            title: '内容连接',
            key: 'url',
            dataIndex: 'url',
            width: '80px',
            align: 'center',
            render: (text, record, index) => {
                return  <a href={text} target="_blank" style={{textDecoration:'underline'}}>点击查看</a>
            }
        },
        {
            title: '阅读量',
            key: 'read',
            dataIndex: 'read',
            width: '80px',
            align: 'center',
        },
        {
            title: '阅读成本',
            key: 'read_cost',
            dataIndex: 'read_cost',
            width: '80px',
            align: 'center',
        },
        {
            title: '互动量',
            key: 'interaction',
            dataIndex: 'interaction',
            width: '80px',
            align: 'center',
        },
      
        {
            title: '互动成本',
            key: 'interaction_cost',
            dataIndex: 'interaction_cost',
            width: '80px',
            align: 'center',
        },
        
        {
            title: '销量',
            key: 'sales',
            dataIndex: 'sales',
            width: '80px',
            align: 'center',
        },
        {
            title: '销售额',
            key: 'sale',
            dataIndex: 'sale',
            width: '80px',
            align: 'center',
        },
        {
            title: 'ROI',
            key: 'roi',
            dataIndex: 'roi',
            width: '80px',
            align: 'center',
        },
        {
            title: '评级',
            key: 'rate',
            dataIndex: 'rate',
            width: '80px',
            align: 'center',
            render: (text, record, index) => (
                text == "超出预期" ? <Tag color="blue">{text}</Tag> : text == "达到预期" ? <Tag color="green">{text}</Tag> : <Tag color="red">{text}</Tag>
            ),
        },
        {
            title: '机构',
            key: 'organization',
            dataIndex: 'organization',
            width: '120px',
            align: 'center'
        },
        {
            title: '推广日期',
            key: 'name',
            dataIndex: 'name',
            width: '200px',
            align: 'center',
            render: (text, record, index) => {
                return  <div>{date[index]['startTime']} - {date[index]['endTime']}</div>
            }
        }

    ]

    useEffect(()=>{
        getPromotionMessage()
    },[page])

    const getPromotionMessage =()=>{
        let dataProps = {
            "page":page,
            "pageSize": 10,
            "principal": principal,
            "platform": platform,
            "product":product,
            "name": name,
            "start_time":startTime,
            "end_time":endTime,
            "create_start_time":createStartTime,
            "create_end_time":createEndTime,
            "rate":rate,
            "rule": orderByRule,
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method:'POST',
            data:dataProps,
            url: servicePath.queryPromotionMessage,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res=>{
                if(res.data.success){
                    setDate(res.data.data);
                    setTotal(res.data.total)
                }else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    //获取KOL的联系方式
    const getPhone = (id) => {
        let dataProps = {
            "kol_id": id,
            "operator": cookie.load('name'),
            "shopId": cookie.load('shopList'),
            'roleId': cookie.load('role')
        }
        axios({
            method: 'POST',
            url: servicePath.getPhoneOfKOl,
            data: dataProps,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization':cookie.load('token')
            }
        }).then(
            res => {
                if (res.data['success']) {
                    setIsPhone(true)
                    setContact(res.data['data'])
                } else {
                    if(res.data['errorCode'] == '10001'){
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    }else{
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 关闭查看详情
    const closeDetail = ()=>{
        setLookDetail(false)
    }

    // 关闭查询弹框
    const onSearchClose = () => {
        setOpenSearch(false)
    }

    // 重置
    const restart = ()=>{
        setPage(1)
        setPrincipal('')
        setPlatform('')
        setProduct('')
        setName('')
        let dataProps = {
            "page":1,
            "pageSize": 10,
            "principal": '',
            "platform": '',
            "product":'',
            "name": '',
            "start_time":'',
            "end_time":'',
            "create_start_time":'',
            "create_end_time":'',
            "rate":'',
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        console.log(dataProps);
        axios({
            method:'POST',
            data:dataProps,
            url: servicePath.queryPromotionMessage,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res=>{
                if(res.data.success){
                    setDate(res.data.data);
                    setTotal(res.data.total)
                    setOpenSearch(false);
                    form.resetFields()
                }else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

     // 关闭联系方式
     const closePhone = () => {
        setContact('')
        setIsPhone(false)
    }

    return (
        <div>
            {/* 按钮区域 */}
            <div style={{ marginTop: size.height * 0.01, marginLeft: size.width * 0.03 }}>
                <Button type="primary" onClick={()=>setOpenSearch(true)}>条件查询</Button>
            </div>
            {/* 项目表格 */}
            <div style={{ marginTop: size.height * 0.03 }}>
                <Table
                    //表格列的配置描述
                    columns={columns}
                    //数据数组
                    dataSource={date}
                    pagination={paginationProps}
                    scroll={{ x:'80vw', y: `${size.width>1500?'70vh':'63vh'}` }}
                />
            </div>


            {/* 查询条件 */}
            <Drawer
                title="查询条件"
                placement="right"
                closable={false}
                onClose={onSearchClose}
                visible={openSearch}
                width={900}
            >
                <Form
                    form={form}
                    {...layout1}
                    labelAlign='left'
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="promoter"
                                label="推广人员"
                            >
                                <Input
                                    id='promoter'
                                    placeholder="请输入推广人员"
                                    value={principal}
                                    onChange={(e) => {
                                        setPrincipal(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="platform"
                                label="平台"
                            >
                                <Input
                                    id='platform'
                                    placeholder="请输入平台"
                                    value={platform}
                                    onChange={(e) => {
                                        setPlatform(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="product"
                                label="推广产品"
                            >
                                <Input
                                    id='product'
                                    placeholder="请输入推广产品"
                                    value={product}
                                    onChange={(e) => {
                                        setProduct(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="红人名称"
                            >
                                <Input
                                    id='name'
                                    placeholder="请输入红人名称"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="promotion"
                                label="推广时间"
                            >
                                <RangePicker locale={locale} onChange={(value, dateString)=>{
                                    setStartTime(dateString[0])
                                    setEndTime(dateString[1])
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="create"
                                label="创建时间"
                            >
                                <RangePicker locale={locale} onChange={(value, dateString)=>{
                                    setCreateStartTime(dateString[0])
                                    setCreateEndTime(dateString[1])
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="rate"
                                label="评级"
                            >
                                <Select onChange={(value)=>setRate(value)}>
                                    <Option value="未达到预期">未达到预期</Option>
                                    <Option value="达到预期">达到预期</Option>
                                    <Option value="超出预期">超出预期</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="rule"
                                label="排序规则"
                            >
                                <Tooltip title="排序规则:从高到低">
                                    <Radio.Group onChange={(e)=>setOrderByRule(e.target.value)} value={orderByRule}>
                                        <Radio value="read">阅读成本</Radio>
                                        <Radio value="interaction">互动成本</Radio>
                                        <Radio value="fans">粉丝数</Radio>
                                    </Radio.Group>
                                </Tooltip>
                               
                            </Form.Item>
                        </Col>
                        <Col style={{ marginLeft: 50 }}>
                            <Button type="primary" onClick={()=>{
                                if(page != 1){
                                    setPage(1);
                                }else{
                                    getPromotionMessage()
                                }
                                setOpenSearch(false);
                            }}>查询</Button>
                            <Button type="primary" style={{marginLeft:20}} danger onClick={()=>restart()}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

             {/* 联系方式 */}
             <Modal
                title="联系方式"
                visible={isPhone}
                onCancel={closePhone}
                footer={[]}
            >
                <text>
                    {contact}
                </text>
            </Modal>
        </div>
    )

}


export default Promote