import React, { Component, useEffect, useState } from 'react';
import style from './index.module.css';
import { DeleteOutlined, UnorderedListOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Typography, Select, Input , DatePicker, message, Modal, Spin, Divider} from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import cookie from 'react-cookies'
import axios from 'axios';
import { DraggableArea } from 'react-draggable-tags';
import downExcel from '../../../../config/utils/downloadFile';
import NbAxios from '../../../../config/utils/nbAxios';
import servicePath from '../../../../config/apiUrl';
import { ReactComponent as AddCart } from '../static/svg/addCart.svg'
import { ReactComponent as Pay } from '../static/svg/pay.svg'
import { ReactComponent as Order } from '../static/svg/order.svg'
import { ReactComponent as Base } from '../static/svg/base.svg'
import { ReactComponent as City } from '../static/svg/city.svg'
import { ReactComponent as Logo } from '../static/svg/logo.svg'
import { ReactComponent as TitleLogo } from '../static/svg/titleLogo.svg'
import ModulLogo from '../static/image/logo.png'
import moment from 'moment'
const { Title, Text } = Typography;
const { Option } = Select
const { RangePicker } = DatePicker
// 日期格式
const dateFormat = 'YYYY-MM-DD'
function Index(props) {
    let shopIdDict = {
        'JORYA': "aecc4073-4fc8-41c7-9d6a-e37b69947c3e",
        'iv': "3ed0b079-36ca-4701-b6b1-643cc4d41066",
        'ein': "444b93cc-a5d0-4886-a4b2-d5f8de27e945",
        'an': "7f1324e7-0d75-4d94-bace-911d5fbd4727",
        'cr': "f115bbea-bb18-439e-bda8-8d1e26719fee",
        'vgrass': 'fc84f6bf-1e5f-4566-a49c-cad309658dbc',
        'qda': '2715f3fa-0dae-4dc7-bcfc-0dd912314e40',
        'gs':'703247c1-a200-42bc-b70f-742dbab27aa4'
    }
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }
    // 对象移除
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    // 选择数据
    const [ chooseData , setChooseData ] = useState([])
    // 全部数据
    const [allData , setAllData ] = useState({})
    // 人数
    const [peopleNumber, setPeopleNumber] = useState('')
    // 电话号码
    const [ phoneList , setPhoneList ] = useState([])
    // 订单时间
    const [orderTime, setOrderTime] = useState('')
    // 加载
    const [ loading , setLoading ] = useState(false)
    // 流量指标
    const [trafficMetrics] = useState(['浏览量', '访客数'])
    // 收藏加购
    const [addCollection] = useState(['收藏人数', '加购人数', '加购件数'])
    // 支付人群
    const [payCrowd] = useState(['支付件数', '支付人数', '支付金额', '支付新买家数', '支付老买家数'])
    // 下单数据
    const [orderData] = useState(['下单件数', '下单人数'])
    // 基础数据
    const [baseData] = useState(['产品线', '上市年份', '上市月份', '季节', '品类', 'SPU'])
    // 订单信息
    const [orderInfo] = useState(['省份', '城市', '地域', '城市等级'])
    // 拖拽对象
    const [dragItem, setDragItem] = useState('')
    // 第一种显示形式
    const [oneShow] = useState(['浏览量', '访客数', '收藏人数', '加购人数', '加购件数', '支付件数', '支付人数', '支付金额', '支付新买家数', '支付老买家数', '下单件数', '下单人数'])
    // 第二种显示方式
    const [twoShow] = useState(['产品线', '上市年份', '上市月份', '季节', '品类', 'SPU',])
    // 展示数据
    const [showDate, setShowDate] = useState({})
    // 符号下拉框数据
    const [symbolDate, setSymbolDate] = useState(['=', '!=', '>', '<', '>=', '<=','包含','不包含','开始以','结束以','是null','不是null','是空的','不是空的','介于','不介于'])
    // 交并差
    const [ crossoverList ] = useState(['交','并','差'])
    // 是否删除
    const [ isDelete , setIsDelete ] = useState(false)
    // 选中条件
    const [ chooseItem , setChooseItem ] = useState('')

    useEffect(()=>{
        getMaxOrderPayTime()
        getItemInfoCircleSelectionConditions()
    },[])

    // 订单最新数据日期查询接口
    const getMaxOrderPayTime = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dataProps, "POST", servicePath.getMaxOrderPayTime).then(res => {
            if (res.success) {
                setOrderTime(res.data['order_pay_time']);
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('订单最新数据日期查询报错，请联系IT帮忙解决')
        })
    }

    // 获取基础信息圈选条件接口
    const getItemInfoCircleSelectionConditions = ()=>{
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
        }
        NbAxios(dataProps, "POST", servicePath.getItemInfoCircleSelectionConditions).then(res => {
            if (res.success) {
                console.log(res.data['item_info_group_field_data']);
                setAllData(res.data['item_info_group_field_data']);
            } else {
                message.warn({ content: res.errorMsg })
            }
        }).catch((err) => {
            // 获取500错误
            message.warn('获取基础信息圈选条件接口报错，请联系IT帮忙解决')
        })
    }
   
    return (
        <div>
            {/* <div style={{
                display: 'flex',
                justifyContent: "space-around",
                backgroundColor: "#fff",
                borderBottom: "solid 2px #ccc",
                marginBottom: 10,
                position: 'fixed',
                top: 40,
                zIndex: 99,
                width: '100vw',
                paddingTop: 10,
                paddingBottom: 10,
                alignItems: "center"
            }}>
                <Title level={3} style={{ margin: 0 }} >圈选人群</Title>
                <div style={{ display: "flex" }}>
                    <b>当前订单更新至:</b> {orderTime}
                </div>
            </div> */}
            <div className={style.externalContent} >
                <div style={{display: 'grid', gridTemplateColumns: "15% 85%", height: "100px" }}>
                    <div style={{border: "1px solid #ccc",  width:"100%", display:'flex', justifyContent:'center', flexDirection:"column", alignItems:"center"}}>
                        <img src={ModulLogo} style={{height:"50px", width:"120px", backgroundSize:'100% 100%'}}/>
                        <Text strong style={{letterSpacing:"10px"}}>人群圈选</Text>
                    </div>
                    <div style={{border: "1px solid #ccc"}}>
                        <div style={{display:"flex", justifyContent:'flex-end', marginTop:"5px", marginRight:"20px"}}>
                            <a href="https://pic.bmcsoft.cn/imge_dir/070816102826/070816102826.html" target="_Blank" style={{marginRight:20}}>操作手册</a>
                            <b>当前订单更新至:</b> {orderTime}
                        </div>
                        <div style={{display:'flex'}}>
                            <b style={{marginLeft:"10px", marginTop:"20px"}}>圈选标签调整顺序:</b>
                            <div>
                                <DraggableArea
                                    className={style.indicatorContent}
                                    tags = {chooseData}
                                    render={({ tag, index }) => (
                                        <div style={{

                                            fontSize: '13px',
                                            border: " 1px dashed #cccccc",
                                            borderRadius: '4px',
                                            padding: "0 8px",
                                            lineHeight: "30px", color: "#666666",
                                            backgroundColor: "rgba(255, 255, 255, 0.7);",
                                            whiteSpace: 'nowrap'
                                        }}>
                                            <span>{tag.name}</span>
                                        </div>
                                    )}
                                    onChange={tags => setChooseData(tags)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div style={{border: "1px solid #ccc",  width:"100%", display:'flex', alignItems:"center", paddingLeft:"20px"}}>
                            <span style={{ color: "red", fontSize: "10px", marginRight: "5px", marginLeft: "3px", marginTop: "3px" }}>*</span>
                            <PlatformEventsCalendar startDate={startDate} setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate}/>
                    </div> */}
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: "15% 85%", height: "calc(99vh - 130px)" }}>
                    <div style={{ border: "1px solid #ccc", width: "100%", overflow:"auto", paddingBottom:"100px" }}>
                        <div style={{ marginTop: "5px",  padding: '5px 0px 5px 5px' }}><b>流量指标</b></div>
                        <div style={{ backgroundColor: "#eeeeee" }}>
                            {
                                trafficMetrics.map(item => {
                                    return <div style={{ display: "flex", height: "30px", alignItems: "center", padding: "5px", backgroundColor: dragItem == item ? '#E4EFFB' :  "#ffffff", borderRight:dragItem == item ? '3px solid #92C4FF' :  "" }} draggable={true} onDragStart={() => {
                                        setDragItem(item)
                                    }}>
                                        <Logo width={15} height={15} /><span style={{ marginLeft: "10px", fontSize:dragItem == item ? '18px' : '14px', color: dragItem == item ? '#1882FF' :  "#666", }}>{item}</span>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{ marginTop: "5px",  padding: '5px 0px 5px 5px' }}><b>收藏加购</b></div>
                        <div style={{ backgroundColor: "#eeeeee" }}>
                            {
                                addCollection.map(item => {
                                    return  <div style={{ display: "flex", height: "30px", alignItems: "center", padding: "5px", backgroundColor: dragItem == item ? '#E4EFFB' :  "#ffffff", borderRight:dragItem == item ? '3px solid #92C4FF' :  "" }} draggable={true} onDragStart={() => {
                                        setDragItem(item)
                                    }}>
                                        <AddCart width={15} height={15} /> <span style={{ marginLeft: "10px", fontSize:dragItem == item ? '18px' : '14px', color: dragItem == item ? '#1882FF' :  "#666", }}>{item}</span>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{ marginTop: "5px",padding: '5px 0px 5px 5px' }}><b>支付人群</b></div>
                        <div style={{ backgroundColor: "#eeeeee" }}>
                            {
                                payCrowd.map(item => {
                                    return  <div style={{ display: "flex", height: "30px", alignItems: "center", padding: "5px", backgroundColor: dragItem == item ? '#E4EFFB' :  "#ffffff", borderRight:dragItem == item ? '3px solid #92C4FF' :  "" }} draggable={true} onDragStart={() => {
                                        setDragItem(item)
                                    }}>
                                        <Pay  width={15} height={15} /> <span style={{ marginLeft: "10px", fontSize:dragItem == item ? '18px' : '14px', color: dragItem == item ? '#1882FF' :  "#666", }}>{item}</span>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{ marginTop: "5px",  padding: '5px 0px 5px 5px' }}><b>下单数据</b></div>
                        <div style={{  backgroundColor: "#eeeeee" }}>
                            {
                                orderData.map(item => {
                                    return <div style={{ display: "flex", height: "30px", alignItems: "center", padding: "5px", backgroundColor: dragItem == item ? '#E4EFFB' :  "#ffffff", borderRight:dragItem == item ? '3px solid #92C4FF' :  "" }} draggable={true} onDragStart={() => {
                                        setDragItem(item)
                                    }}>
                                        <Order width={20} height={20} /> <span style={{ marginLeft: "10px", fontSize:dragItem == item ? '18px' : '14px', color: dragItem == item ? '#1882FF' :  "#666", }}>{item}</span>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{ marginTop: "5px", padding: '5px 0px 5px 5px' }}><b>基础信息</b></div>
                        <div style={{  backgroundColor: "#eeeeee" }}>
                            {
                                baseData.map(item => {
                                    return <div style={{ display: "flex", height: "30px", alignItems: "center", padding: "5px", backgroundColor: dragItem == item ? '#E4EFFB' :  "#ffffff", borderRight:dragItem == item ? '3px solid #92C4FF' :  "" }} draggable={true} onDragStart={() => {
                                        setDragItem(item)
                                    }}>
                                        <Base  width={15} height={15} /> <span style={{ marginLeft: "10px", fontSize:dragItem == item ? '18px' : '14px', color: dragItem == item ? '#1882FF' :  "#666", }}>{item}</span>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{ marginTop: "5px",  padding: '5px 0px 5px 5px' }}><b>订单信息</b></div>
                        <div style={{ backgroundColor: "#eeeeee" }}>
                            {
                                orderInfo.map(item => {
                                    return <div style={{ display: "flex", height: "30px", alignItems: "center", padding: "5px", backgroundColor: dragItem == item ? '#E4EFFB' :  "#ffffff", borderRight:dragItem == item ? '3px solid #92C4FF' :  "" }} draggable={true} onDragStart={() => {
                                        setDragItem(item)
                                    }}>
                                        <City  width={20} height={20} /> <span style={{ marginLeft: "10px", fontSize:dragItem == item ? '18px' : '14px', color: dragItem == item ? '#1882FF' :  "#666", }}>{item}</span>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div style={{ width: "100%", padding: "20px", border:"1px solid #ccc", overflow:"auto", height:"100%"}}  onDragEnter={() => {
                        if (dragItem != '') {
                            let obj = [...chooseData]
                            let dragId = dragItem + Math.ceil(Math.random()*100)
                            obj.push({
                                id:dragId,
                                name:dragId,
                            })
                            setChooseData(obj)
                            if(chooseData.length > 0){
                                message.info({
                                    content:'请选择"交并差"关系',
                                    style:{
                                        marginTop:"20vh",
                                        fontSize:"20px"
                                    }
                                })
                            }
                            if (oneShow.indexOf(dragItem) != -1) {
                                let newDate = {
                                    type: 1,
                                    name: dragItem,
                                    symbol: '',
                                    value1: "",
                                    value2: "",
                                    crossover:'',
                                    startDate:'',
                                    endDate:"",
                                    dragId:dragId
                                }
                                showDate[dragId] = newDate
                            } 
                            else if(twoShow.indexOf(dragItem) != -1){
                                let newDate = {
                                    type: 2,
                                    name: dragItem,
                                    value: [],
                                    startDate:'',
                                    endDate:"",
                                    symbol: "在数组",
                                    crossover:'',
                                    dragId:dragId
                                }
                                showDate[dragId] = newDate
                            }else{
                                let newDate = {
                                    type: 3,
                                    name: dragItem,
                                    startDate:'',
                                    endDate:"",
                                    selectList:[],
                                    value: [],
                                    symbol: "在数组",
                                    crossover:'',
                                    dragId:dragId
                                }
                                showDate[dragId] = newDate
                            }
                            setDragItem('')
                        }
                    }}>
                        <Spin spinning={loading} tip="数据计算中.....">
                            {
                                chooseData.length != 0 ?
                                chooseData.map((item, index) => {
                                    return showDate[item.name]['type'] == 1 ?
                                        <div>
                                            <Divider style={{ display: index  == 0 ? "none" : 'flex', border:showDate[item.name]['crossover'] == '' ? '1px dashed red' : '', marginTop:"5px", marginBottom:"5px", paddingTop:"5px", paddingBottom:"5px" }}>
                                                <div style={{ display: 'flex' }}>
                                                    {
                                                        crossoverList.map(crossoverItem=>{
                                                            return <div style={{padding:"5px", color: showDate[item.name]['crossover'] == crossoverItem ? "#1890FF" :"#000", border:showDate[item.name]['crossover'] == crossoverItem ? "1px solid #1890FF" :"1px solid #ccc",marginLeft:"5px"}} onClick={()=>{
                                                                showDate[item.name]['crossover'] = crossoverItem
                                                            }}>{crossoverItem}</div>
                                                        })
                                                    }
                                                </div>
                                            </Divider>
                                            <div style={{ width: "100%", padding: "20px", display: "flex", border: "1px solid #ccc", justifyContent:"space-between", backgroundColor:"#F7F7F7" }}>
                                                <div>
                                                    <div style={{display: "flex", marginBottom:'10px'}}>
                                                        <span style={{lineHeight: "32px", marginRight:"20px", textDecoration:"underline"}}>圈选标签:</span>
                                                        <span style={{lineHeight: "32px", textDecoration:"underline"}}>{showDate[item.name]['dragId']}</span>
                                                    </div>
                                                    <div style={{display: "flex"}}>
                                                        <span style={{lineHeight: "32px", marginRight:"20px"}}>时间:</span>
                                                        <RangePicker locale={locale} disabledDate={disabledDate} value={showDate[item.name]['startDate'] != '' ? [moment(showDate[item.name]['startDate'] , dateFormat), moment(showDate[item.name]['endDate'],dateFormat)] : ['','']} 
                                                            onChange={(value, dateString) => {
                                                                if (dateString[0] == '') {
                                                                    message.warn('请选择正确的时间')
                                                                } else {
                                                                    showDate[item.name]['startDate'] = dateString[0]
                                                                    showDate[item.name]['endDate'] = dateString[1]
                                                                }
                                                            }} /> 
                                                    </div>
                                                    <div style={{display: "flex", marginTop:"5px"}}>
                                                        <span style={{ lineHeight: "32px", marginRight: "10px" }}>{showDate[item.name]['name']}</span>
                                                        <Select value={showDate[item.name]['symbol']} style={{ width: "100px" }} onChange={(value) => {
                                                            showDate[item.name]['symbol'] = value
                                                        }}>
                                                            {
                                                                symbolDate.map(item => {
                                                                    return <Option value={item}>{item}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                        {
                                                            showDate[item.name]['symbol'] == '介于' || showDate[item.name]['symbol'] == '不介于' ? <div style={{display:'flex'}}>
                                                                <Input key={item.name} defaultValue={showDate[item.name]['value1']} style={{ width: "80px", marginLeft:"5px" }} onChange={(e) => {
                                                                    showDate[item.name]['value1'] = e.target.value
                                                                }} />
                                                                <Input  key={item.name} defaultValue={showDate[item.name]['value2']} style={{ width: "80px", marginLeft:"5px" }} onChange={(e) => {
                                                                    showDate[item.name]['value2'] = e.target.value
                                                                }} />
                                                            </div>: showDate[item.name]['symbol'] == '不在数组' || showDate[item.name]['symbol'] == '在数组' ? <Input key={item.name} placeholder='请以英文逗号进行分区数据' defaultValue={showDate[item.name]['value1']} style={{ width: "200px", marginLeft:"5px" }} onChange={(e) => {
                                                                showDate[item.name]['value1'] = e.target.value
                                                            }} /> :
                                                             <Input key={item.name} defaultValue={showDate[item.name]['value1']} style={{ width: "80px", marginLeft:"5px" }} onChange={(e) => {
                                                                showDate[item.name]['value1'] = e.target.value
                                                            }} />
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{marginRight:"20px"}}>
                                                    <DeleteOutlined onClick={()=>{
                                                        setChooseItem(item)
                                                        setIsDelete(true)
                                                    }}/>
                                                </div>
                                            </div>
                                        </div> 
                                        : showDate[item.name]['type'] == 2 ? <div>
                                             <Divider style={{ display: index  == 0 ? "none" : 'flex', border:showDate[item.name]['crossover'] == '' ? '1px dashed red' : '', marginTop:"5px", marginBottom:"5px", paddingTop:"5px", paddingBottom:"5px" }}>
                                                <div style={{ display: 'flex' }}>
                                                    {
                                                        crossoverList.map(crossoverItem=>{
                                                            return <div style={{padding:"5px", color: showDate[item.name]['crossover'] == crossoverItem ? "#1890FF" :"#000", border:showDate[item.name]['crossover'] == crossoverItem ? "1px solid #1890FF" :"1px solid #ccc",marginLeft:"5px"}} onClick={()=>{
                                                                showDate[item.name]['crossover'] = crossoverItem
                                                            }}>{crossoverItem}</div>
                                                        })
                                                    }
                                                </div>
                                            </Divider>
                                            <div style={{ width: "100%", padding: "20px", border: "1px solid #ccc", backgroundColor:"#F7F7F7" }}>
                                                <div style={{display:'flex', justifyContent:"space-between"}}>
                                                <div style={{width:"90%"}}>
                                                        <div style={{display: "flex", marginBottom:'10px'}}>
                                                            <span style={{lineHeight: "32px", marginRight:"20px", textDecoration:"underline"}}>圈选标签:</span>
                                                            <span style={{lineHeight: "32px", textDecoration:"underline"}}>{showDate[item.name]['dragId']}</span>
                                                        </div>
                                                        <div style={{display:'flex'}}>
                                                            <span style={{ lineHeight: "32px", marginRight: "20px" }}>{'时间:'}</span>
                                                            <RangePicker style={{marginRight:"5px"}} locale={locale} disabledDate={disabledDate} value={showDate[item.name]['startDate'] != '' ? [moment(showDate[item.name]['startDate'] , dateFormat), moment(showDate[item.name]['endDate'],dateFormat)] : ['','']} 
                                                                onChange={(value, dateString) => {
                                                                    if (dateString[0] == '') {
                                                                        message.warn('请选择正确的时间')
                                                                    } else {
                                                                        showDate[item.name]['startDate'] = dateString[0]
                                                                        showDate[item.name]['endDate'] = dateString[1]
                                                                    }
                                                            }} /> 
                                                        </div>
                                                        <div style={{display:'flex'}}>
                                                            <span style={{ lineHeight: "32px", marginRight: "10px" }}>{showDate[item.name]['name']}</span>
                                                            <Select mode="multiple" maxTagCount="responsive" style={{ width: "90%", marginTop:"5px" }} onChange={(value) => {
                                                                showDate[item.name]['value'] = value
                                                            }}>
                                                                {
                                                                    allData[showDate[item.name]['name']].map(item => {
                                                                        return <Option value={item}>{item}</Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </div>
                                                       
                                                </div>
                                                    <div style={{marginRight:"20px"}}>
                                                        <DeleteOutlined onClick={()=>{
                                                            setChooseItem(item)
                                                            setIsDelete(true)
                                                        }}/>
                                                    </div>
                                                </div>
                                                {/* <div style={{border:"1px dashed #ccc", padding:"10px", display:"flex", marginTop:"5px"}}>
                                                    {
                                                        item['value'].map(item=>{
                                                            return <div style={{border:"1px dashed #ccc", padding:"5px", marginRight:"10px"}}>{item}</div>
                                                        })
                                                    }
                                                </div> */}
                                            </div>
                                        </div> :
                                        <div>
                                            <Divider style={{ display: index  == 0 ? "none" : 'flex', border:showDate[item.name]['crossover'] == '' ? '1px dashed red' : '', marginTop:"5px", marginBottom:"5px", paddingTop:"5px", paddingBottom:"5px" }}>
                                                <div style={{ display: 'flex' }}>
                                                    {
                                                        crossoverList.map(crossoverItem=>{
                                                            return <div style={{padding:"5px", color: showDate[item.name]['crossover'] == crossoverItem ? "#1890FF" :"#000", border:showDate[item.name]['crossover'] == crossoverItem ? "1px solid #1890FF" :"1px solid #ccc",marginLeft:"5px"}} onClick={()=>{
                                                                showDate[item.name]['crossover'] = crossoverItem
                                                            }}>{crossoverItem}</div>
                                                        })
                                                    }
                                                </div>
                                            </Divider>
                                            <div style={{ width: "100%", padding: "20px", border: "1px solid #ccc", backgroundColor:"#F7F7F7" }}>
                                                <div style={{display:'flex', justifyContent:"space-between"}}>
                                                <div style={{ width:"90%"}}>
                                                        <div>
                                                            <div style={{display: "flex", marginBottom:'10px'}}>
                                                                <span style={{lineHeight: "32px", marginRight:"20px", textDecoration:"underline"}}>圈选标签:</span>
                                                                <span style={{lineHeight: "32px", textDecoration:"underline"}}>{showDate[item.name]['dragId']}</span>
                                                            </div>
                                                            <div style={{display:'flex',}}>
                                                                <span style={{ lineHeight: "32px", marginRight: "20px" }}>时间:</span>
                                                                <RangePicker style={{marginRight:"5px"}} locale={locale} disabledDate={disabledDate} value={showDate[item.name]['startDate'] != '' ? [moment(showDate[item.name]['startDate'] , dateFormat), moment(showDate[item.name]['endDate'],dateFormat)] : ['','']} 
                                                                    onChange={(value, dateString) => {
                                                                        if (dateString[0] == '') {
                                                                            message.warn('请选择正确的时间')
                                                                        } else {
                                                                            showDate[item.name]['startDate'] = dateString[0]
                                                                            showDate[item.name]['endDate'] = dateString[1]
                                                                        }
                                                                    }} /> 
                                                                <Button style={{marginLeft:"10px", backgroundColor:"#1890FF", color:"#ffffff",}} onClick={()=>{
                                                                    if(showDate[item.name]['startDate'] == ''){
                                                                        message.warn('请选择时间')
                                                                    }else{
                                                                        let dataProps = {
                                                                            "roleId": cookie.load('role'),
                                                                            "shopList": cookie.load('shopList'),
                                                                            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                                                            "start_date": showDate[item.name]['startDate'],
                                                                            "end_date": showDate[item.name]['endDate'],
                                                                            "field": showDate[item.name]['name']
                                                                        }
                                                                        NbAxios(dataProps, "POST", servicePath.getOrderFieldCircleSelectionConditions).then(res => {
                                                                            if (res.success) {
                                                                                message.success('成功查询到下拉框选择')
                                                                                showDate[item.name]['selectList'] = res.data['order_group_field_data']
                                                                            } else {
                                                                                message.warn({ content: res.errorMsg })
                                                                            }
                                                                        }).catch((err) => {
                                                                            // 获取500错误
                                                                            message.warn('获取订单圈选条件接口报错，请联系IT帮忙解决')
                                                                        })
                                                                    }
                                                                }}>查询</Button>
                                                            </div>
                                                            <div style={{display:"flex"}}>
                                                                <span style={{ lineHeight: "32px", marginRight: "10px" }}>{showDate[item.name]['name']}</span>
                                                                <Select mode="multiple" maxTagCount="responsive" style={{ width: "90%", marginTop:"10px" }} onChange={(value) => {
                                                                    showDate[item.name]['value'] = value
                                                                }}>
                                                                    {
                                                                        showDate[item.name]['selectList'].map(item => {
                                                                            return <Option value={item}>{item}</Option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </div>
                                                           
                                                        </div>
                                                </div>
                                                    <div style={{marginRight:"20px"}}>
                                                        <DeleteOutlined onClick={()=>{
                                                            setChooseItem(item)
                                                            setIsDelete(true)
                                                        }}/>
                                                    </div>
                                                </div>
                                                {/* <div style={{border:"1px dashed #ccc", padding:"10px", display:"flex", marginTop:"5px", overflow:"auto"}}>
                                                    {
                                                        item['value'].map(item=>{
                                                            return <div style={{border:"1px dashed #ccc", padding:"5px", marginRight:"10px", whiteSpace: 'nowrap'}}>{item}</div>
                                                        })
                                                    }
                                                </div> */}
                                            </div>
                                        </div>
                                })
                                :<div style={{display:'flex', justifyContent:"center", alignItems:"center", height: "calc(80vh - 200px)", width:"100%"}}>
                                    <div style={{fontWeight:"bolder", fontSize:"30px"}}>将左侧列表中的"条件"拖进右侧面板中</div>
                                </div>
                            }
                        </Spin>
                        
                    </div>
                </div>
                <div className={style.bottomContent}>
                    <b>人数:</b>
                    <Text style={{ width: "200px", fontSize:"30px", fontWeight:"bold", marginLeft:"30px" }}>{peopleNumber}</Text>
                    <Button style={{  backgroundColor:"#E4EFFB", color:"#1890FF", borderRadius:"5px", marginRight:"15px"}} onClick={() => {
                        let calculateShowDate = []
                        chooseData.map(item=>{
                            calculateShowDate.push(showDate[item.name])
                        })
                        calculateShowDate[0]['crossover'] = ''
                        let errFlag = 0
                        calculateShowDate.map((item, index)=>{
                            if(index != 0 && item.crossover == ''){
                                errFlag = 1
                            }
                        })
                        if(errFlag){
                            message.warn('请选择全部的"交并差"关系')
                        }else{
                            setPeopleNumber('')
                            let dataProps = {
                                "roleId": cookie.load('role'),
                                "shopList": cookie.load('shopList'),
                                "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                                "condition":calculateShowDate
                            }
                            setLoading(true)
                            NbAxios(dataProps, "POST", servicePath.getPeopleNumber).then(res => {
                                setLoading(false)
                                if (res.success) {
                                    setPeopleNumber(res.data['lxsj'].length)
                                    setPhoneList(res.data['lxsj'])
                                } else {
                                    message.warn({ content: res.errorMsg })
                                }
                            }).catch((err) => {
                                // 获取500错误
                                setLoading(false)
                                message.warn('计算人数接口报错，请联系IT帮忙解决')
                            })
                        }
                    }}>计算人数</Button>
                    <Button disabled={phoneList.length == 0 ? true : false} style={{backgroundColor:"#1890FF", color:"#ffffff", borderRadius:"5px", marginRight:"15px"}} onClick={()=>{
                        let dataProps = {
                            "roleId": cookie.load('role'),
                            "shopList": cookie.load('shopList'),
                            "shopId": shopIdDict[props.history.location.pathname.split('/')[2]],
                            "data":phoneList
                        }
                        setLoading(true)
                        axios({
                            data: dataProps,
                            method: 'POST',
                            url: servicePath.downloadPhoneNumber,
                            responseType: "blob",
                            headers: {
                                'Content-Type': 'application/json',  //如果写成contentType会报错
                                'authorization': cookie.load('token')
                            }
                        }).then(
                            res => {
                                setLoading(false)
                                downExcel(res)
                            }
                        ).catch(e => {
                            setLoading(false)
                            message.error({ content: '文件制作出错，请联系it同事！' })
                        })
                    }}>详细数据</Button>
                    <Button style={{ backgroundColor:"#1890FF", color:"#ffffff",borderRadius:"5px", marginRight:"60px"}} onClick={()=>{
                        props.history.push({ 'pathname': `/app/${props.history.location.pathname.split('/')[2]}/crowd/analyze`, 'state': {'phoneList': phoneList,  } })
                    }}>商品分析</Button>
                </div>
            </div>
            <Modal
                title=""
                footer={null}
                visible={isDelete}
                closable={false}
                onCancel={()=>{
                    setIsDelete(false)
                }}
                centered={true}
                width={400}
            >
                <div>
                    <div style={{fontSize:"20px",display:'flex', height:"20px", justifyContent:"center", alignItems:'center'}}>
                        <QuestionCircleOutlined style={{ color:"red"}} />
                        <b style={{marginLeft:"5px"}}>是否确认删除此条件</b>
                    </div>
                    <div style={{display:'flex', justifyContent:"space-around",marginTop:"15px"}}>
                        <Button size="small" style={{color:"#fff", backgroundColor:"#F74500"}} onClick={()=>{
                            let obj = [...chooseData]
                            obj.remove(chooseItem)
                            setChooseData(obj)
                            setIsDelete(false)
                        }}>删除</Button>
                        <Button size="small" style={{color:"#F74500", backgroundColor:"#FFF0EA"}} onClick={()=>setIsDelete(false)}>取消</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )

}

export default Index