import React, { useState, useEffect } from 'react'
import NbAxios from '../../../config/utils/nbAxios';
import servicePath from '../../../config/apiUrl';
import downExcel from '../../../config/utils/downloadFile';
import { LeftOutlined, RightOutlined, InboxOutlined, UserOutlined } from '@ant-design/icons';
import axios from 'axios'
import cookie from 'react-cookies'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { Form, Tooltip, Table, Button, Select, Modal, Drawer, Input, Row, Col, Divider, message, Upload, DatePicker, Typography, Radio, Badge } from 'antd';
const { Option } = Select;
// 文件上传组建
const { Dragger } = Upload;
const { Title, Text } = Typography
const { TextArea } = Input
//时间框
const { RangePicker } = DatePicker;
//定义时间格式（年月日）
const dateFormat = 'YYYY-MM-DD';
// 供应商页面(props可用于之后的页面跳转)
function Index(props) {
    //便于label对齐
    const layout = {
        labelCol: { span: 9 },
        wrapperCol: { span: 16 },
    };

    //创建form的控制实例
    const [form] = Form.useForm()
    const [dockingForm] = Form.useForm()
    const [searchForm] = Form.useForm()
    //  基础消息新增弹框(定义一些函数控制窗口的开关)
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const showAddModal = () => {
        setIsAddModalVisible(true);
    };
    //搜索框
    const [visible, setVisible] = useState(false);
    const [value, setValue] = React.useState(1);

    const onChange = e => {
        setValue(e.target.value);
    };
    const handleAddOk = () => {
        setIsAddModalVisible(false);
    };

    const handleAddCancel = () => {
        message.success("取消新增供应商")
        setIsAddModalVisible(false);
    };

    // 基础消息修改弹框(定义一些函数控制窗口的开关)
    const [isModifiedModalVisible, setIsModifiedModalVisible] = useState(false);
    const showModifiedModal = () => {
        setIsModifiedModalVisible(true);
    };

    const handleModifiedOk = () => {
        setIsModifiedModalVisible(false);
    };

    const handleModifiedCancel = () => {
        message.success("取消修改供应商")
        setIsModifiedModalVisible(false);
    };
    //权限列表
    const [jurisdiction, setJurisdiction] = useState([])
    // 第几页
    const [page, setPage] = useState(1)
    // 总数
    const [total, setTotal] = useState(100)
    // 报价单id
    const [quotationid, setQuotationId] = useState(1)
    // 供应商id
    const [supplierid, setSupplierId] = useState(1)
    // 表格数据
    const [data, setData] = useState([])
    // 下载表格数据
    const [downloadData, setDownloadData] = useState([])
    // 控制附件表格是否打开
    const [isdownloadData, setIsDownloadData] = useState(false)
    // 是否上传文件
    const [isUpload, setIsUpload] = useState(false)
    // 是否上传供应商
    const [uploadSupplier, setUploadSupplier] = useState(false)
    // 预算目标文件
    const [baseFile, setBaseFile] = useState([])
    // 供应商
    const [supplierFile, setSupplierFile] = useState([])
    // 按钮是否有效
    const [effective, setEffective] = useState(0)
    // 标记点击下标
    const [clickIndex, setClickIndex] = useState(-1)
    // 获取table行数据
    const [tabledata, setTableData] = useState([])
    // 是否额外数据弹窗
    const [othertabledata, setOtherTableData] = useState(false)
    // 对接人新增弹窗
    const [isOppositePersonXinzengModalVisible, setIsOppositePersonXinzengModalVisible] = useState(false);
    const showOppositePersonXinzengModal = () => {

        setIsOppositePersonXinzengModalVisible(true);
    };

    const handleOppositePersonXinzengOk = () => {
        setIsOppositePersonXinzengModalVisible(false);
    };

    const handleOppositePersonXinzengCancel = () => {
        setIsOppositePersonXinzengModalVisible(false);
    };
    // 对接人修改弹窗
    const [isOppositePersonXiugaiModalVisible, setIsOppositePersonXiugaiModalVisible] = useState(false);
    const showOppositePersonXiugaiModal = () => {

        setIsOppositePersonXiugaiModalVisible(true);
    };

    const handleOppositePersonXiugaiOk = () => {
        setIsOppositePersonXiugaiModalVisible(false);
    };

    const handleOppositePersonXiugaiCancel = () => {
        dockingForm.resetFields()
        setIsOppositePersonXiugaiModalVisible(false);
    };
    //是否显示对接人弹窗
    const [isDuijierenModalVisible, setIsDuijierenModalVisible] = useState(false);
    const showDuijierenModal = () => {
        setIsDuijierenModalVisible(true);

    };
    const handleDuijierenOk = () => {
        setIsDuijierenModalVisible(false);
    };
    const handleDuijierenCancel = () => {
        setIsDuijierenModalVisible(false);
    };
    // 对接人主对接人设定弹窗
    const [isMainOppositePersonXinzengModalVisible, setIsMainOppositePersonXinzengModalVisible] = useState(false);
    const showMainOppositePersonXinzengModal = () => {

        setIsMainOppositePersonXinzengModalVisible(true);
    };

    const handleMainOppositePersonXinzengOk = () => {
        setIsMainOppositePersonXinzengModalVisible(false);
    };

    const handleMainOppositePersonXinzengCancel = () => {
        setIsMainOppositePersonXinzengModalVisible(false);
    };
    // 对接人数据
    const [oppositePersonData, setOppositePersonData] = useState([])
    // 对接人修改
    const [mainId, setMainId] = useState()
    /* 表格table列名 */
    //主表格的列名
    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: 50,
            align: 'center',
            fixed:true,
            // colSpan:jurisdiction.indexOf("threepets::operationSMSData1") != -1 ? 1 : 0,
            // 参数分别为当前行的一个值，当前行所有数据(json)，行索引
            render: (text, record, index) => (
                <div onClick={() => {
                    setClickIndex(index)
                    setOtherTableData(true)
                    setSupplierId(record.id)
                    setTableData(record)
                }}
                //  style={{display:jurisdiction.indexOf("threepets::operationSMSData") != -1 ? '' : 'none'}}
                 >
                    {
                        index == clickIndex ? <RightOutlined /> : <LeftOutlined />
                    }
                </div>
            ),
        },
        {
            title: '分类',
            dataIndex: 'classification',
            key: 'classification',
            align: 'center',
            width: 100,
            fixed:true,
        },
        {
            title: '供应商编码',
            dataIndex: 'supplier_code',
            key: 'supplier_code',
            align: 'center',
            width: 110,
            fixed:true,
        },
        {
            title: '公司名称',
            dataIndex: 'corporate_name',
            key: 'corporate_name',
            align: 'center',
            width: 250,
            //设置 column.ellipsis 可以让单元格内容根据宽度自动省略
            ellipsis: {
                showTitle: false,
            },
            //放置会显示出全部数据
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '主要业务',
            dataIndex: 'main_business',
            key: 'main_business',
            align: 'center',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: text => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            )
        },
        {
            title: '是否合作',
            dataIndex: 'cooperation',
            key: 'cooperation',
            align: 'center',
            width: 150,
        },
        {
            title: '初次合作时间',
            dataIndex: 'Initial_cooperation_time',
            key: 'Initial_cooperation_time',
            align: 'center',
            width: 200,
            //如果数据类型为string,则取前7位数据,即年和月
            render: text => (
                <span>{typeof(text) == "string" ? text.slice(0,7) : ''}</span>
            ),
        },
        {
            title: '地区',
            dataIndex: 'region',
            key: 'region',
            align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: region => (
                <Tooltip placement="topLeft" title={region}>
                    {region}
                </Tooltip>
            ),
            width: 200,
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: remarks => (
                <Tooltip placement="topLeft" title={remarks}>
                    {remarks}
                </Tooltip>
            ),
            width: 350,
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            align: 'center',
            width: 150,
            render: (text, record) => (
                <div style={{display:'flex', width:120, justifyContent:"space-around"}}>
                    <a style={{marginRight:3}} onClick={() => {
                            //跳转页面,并且传递数据
                            props.history.push({
                                'pathname': "/app/three/pets/sms/factory",
                                'state': {
                                    'workOrderId': record.id,
                                    'corporate_name': record.corporate_name,
                                }
                            })
                        }}
                    >工厂实力</a>
                    <div style={{ display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none" }}>
                        <a onClick={() => {
                                setSupplierId(record.id)
                                if(record.Initial_cooperation_time != ''){
                                    record.Initial_cooperation_time = moment(record.Initial_cooperation_time)
                                }else{
                                    record.Initial_cooperation_time = undefined
                                }
                                //修改时,填充已有的数据
                                form.setFieldsValue(record)
                                setIsModifiedModalVisible(true);
                            }}
                        >修改</a>
                        <a style={{marginLeft:5}} onClick={() => {
                                summary_delect(record.id)
                            }}
                        >删除</a>
                    </div>
                  
                </div>
            ),
        },
    ];
    // 下载报价单列名
    const downloadcolumns = [
        {
            title: '文件名',
            align: "quotation_name",
            dataIndex: 'quotation_name',
            render: (text, record) => <a onClick={() => {
                downloadQuotation(record.id);
                message.success('附件下载成功')
            }}>{text}</a>,
        },
        {
            title: '上传时间',
            dataIndex: 'upload_time',
            align: "upload_time",
        },
        {
            title: '操作',
            dataIndex: 'chaozhuo',
            key: 'chaozhuo',
            align: 'center',
            render: (text, record, index) => (
                // 参数分别为当前行的值，当前行数据，行索引
                <a onClick={() => {
                    setQuotationId(record.id)
                    quotation_delect(record.id)

                }}>删除</a>
            ),
        },
    ];
    /* 接口 */
    // 初始化页面（page）。page发生变化就重新find
    useEffect(() => {
        findSummary()
        getJurisdiction()
    }, [page])
    //搜索框，在进入Drawer抽屉查询时，内容已经存在供应商名和工厂名
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    // 基础信息新增
    const addSummary = (values) => {
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        values.creator = cookie.load('name')
        if(values.Initial_cooperation_time != undefined){
            values.Initial_cooperation_time = values.Initial_cooperation_time.format("YYYY-MM-DD")
        }else{
            values.Initial_cooperation_time = ''
        }
        NbAxios(values, "POST", servicePath.addSummary).then(res => {
            if (res.success) {
                message.success('供应商新增成功');
                handleAddOk();
                //新增成功,清空显示的数据
                form.resetFields();
                //新增成功后调用，显示一遍页面
                findSummary()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 基础信息修改
    const modifiedSummary = (values) => {
        // values.creator = '111'
        values.id = supplierid
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        if(values.Initial_cooperation_time != undefined){
            values.Initial_cooperation_time = values.Initial_cooperation_time.format("YYYY-MM-DD")
        }else{
            values.Initial_cooperation_time = ''
        }
        NbAxios(values, "POST", servicePath.modifiedSummary).then(res => {
            if (res.success) {
                message.success('供应商修改成功');
                handleModifiedOk()
                // setIsDetailData(true)
                findSummary()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 获取所有角色/店铺对应的权限
    const getJurisdiction = () => {
        let dataProps = {
            "role_id": cookie.load('role'),
            "shop_id": ['82328e58-068b-4a27-9bd0-9d1649cb8e6e']
        }
        NbAxios(dataProps, "POST", servicePath.getRoleShopPorts).then(res => {
            if (res.success) {
                setJurisdiction(res.data)
            }
        })
    }

    // 获取表格数据
    const findSummary = (value) => {
        if (value == undefined) {
            value = {}
        }
        value.page = page
        value.roleId = cookie.load('role')
        value.shopList = cookie.load('shopList')
        NbAxios(value, "POST", servicePath.findSummary).then(res => {
            if (res.success) {
                setVisible(false)
                setTotal(res.total);
                setData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 供应商详情删除接口
    const delectSummary = (id) => {
        let obj = {
            'id': id,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.delectSummary).then(res => {
            if (res.success) {
                message.success('供应商删除成功')
                findSummary()
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 供应商是否删除确认弹框
    function summary_delect(id) {
        Modal.confirm({
            title: '',
            width: "25%",
            okText: '确定',
            cancelText: '取消',
            centered:true,
            onOk: () => {
                delectSummary(id)
            },
            onClose: () => {

            },
            content: (
                <div>
                    <span>
                        是否删除该供应商信息？
                    </span>
                </div>
            ),

        });
    }
    // 报单表查询
    const findQuotation = (id) => {
        let obj = {
            "supplier_id": id,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.findQuotation).then(res => {
            if (res.success) {
                setDownloadData(res.data)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }

    // 报单表下载
    const downloadQuotation = (id) => {
        if (downloadData.length == 0) {
            message.error({ content: '并没有上传相关文件' })
        }
        let data = {
            'id': id,
            'roleId': cookie.load('role'),
            'shopList': cookie.load('shopList')
        }
        axios({
            data: data,
            method: 'POST',
            url: servicePath.downloadQuotation,
            responseType: "blob",
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                downExcel(res)
            }
        ).catch(e => {
            message.error({ content: '并没有相关文件' })
        })
    }
    // 报价单删除
    const delectQuotation = (id) => {
        let obj = {
            'id': id,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.delectQuotation).then(res => {
            if (res.success) {
                message.success('报价单删除成功');
                findQuotation(supplierid)
            } else {
                message.warn(res.errorMsg)
            }
        })

    }
    // 报价单是否删除确认弹框
    function quotation_delect(id) {
        Modal.confirm({
            title: '',
            width: "25%",
            okText: '确定',
            cancelText: '取消',
            centered:true,
            onOk: () => {
                delectQuotation(id)
            },
            onClose: () => {

            },
            content: (
                <div>
                    <span>
                        是否删除该报价单信息？
                    </span>
                </div>
            ),

        });
    }
    // 对接人详情查找接口
    const findOppositePersongys = (supplierid) => {
        let obj = {
            'supplier_id': supplierid,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.findOppositePersongys).then(res => {
            if (res.success) {
                setOppositePersonData(res.data)

            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 主对接人详情删除
    const delectOppositePersongys = (id) => {
        let obj = {
            'id': id,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.delectOppositePersongys).then(res => {
            if (res.success) {
                message.success('对接人删除成功');
                findOppositePersongys(supplierid)

            } else {
                message.warn(res.errorMsg)
            }
        })

    }
    // 对接人详情新增接口
    const addOppositePersdongys = (values) => {
        values.supplier_id = supplierid
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.addOppositePersdongys).then(res => {
            if (res.success) {
                dockingForm.resetFields()
                handleOppositePersonXinzengOk()
                message.success('对接人新增成功');
                findOppositePersongys(supplierid)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 主对接人设定id
    const mainOppositePersongys = (id) => {
        let obj = {
            'id': id,
            "roleId": cookie.load('role'),
            "shopList": cookie.load('shopList'),
        }
        NbAxios(obj, "POST", servicePath.mainOppositePersongys).then(res => {
            if (res.success) {
                message.success('主对接人设定成功');
                handleMainOppositePersonXinzengOk()
                findOppositePersongys(supplierid)

            } else {
                message.warn(res.errorMsg)
            }
        })

    }
    // 对接人修改
    const modifiedOppositePersongys = (values) => {
        values.id = mainId
        values.supplier_id = supplierid
        values.roleId = cookie.load('role')
        values.shopList = cookie.load('shopList')
        NbAxios(values, "POST", servicePath.modifiedOppositePersongys).then(res => {
            if (res.success) {
                dockingForm.resetFields()
                message.success('对接人修改成功');
                findOppositePersongys(supplierid)
            } else {
                message.warn(res.errorMsg)
            }
        })
    }
    // 对接人是否删除确认弹框
    function oppositeperson_delect(id) {
        Modal.confirm({
            title: '',
            width: "25%",
            okText: '确定',
            cancelText: '取消',
            centered:true,
            onOk: () => {
                delectOppositePersongys(id)
            },
            onClose: () => {

            },
            content: (
                <div>
                    <span>
                        是否删除该对接人信息？
                    </span>
                </div>
            ),

        });
    }
    //限制页面输出
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
        current: page,
        total: total,
        onChange: page => {
            //改变页码
            setPage(page)
        }
    }
    // 上传商品基础信息
    const uploadBaseFile = () => {
        if (baseFile.length != 0) {
            setEffective(1)
            const formData = new FormData()
            // 多个图片
            baseFile.forEach((item) => {
                formData.append('quotation', item['originFileObj']);
            })
            formData.append('supplier_id', supplierid)
            formData.append('roleId', cookie.load('role'));
            formData.append('shopList', cookie.load('shopList'));
            NbAxios(formData, "POST", servicePath.uploadQuotation).then(res => {
                setEffective(0)
                if (res.success) {
                    setBaseFile([]);

                    setIsUpload(false)
                } else {
                    message.warn(res.errorMsg)
                }
            })
        } else {

        }
    }
    // 上传供应商数据
    const uploadSupplierFile = ()=>{
        if (supplierFile.length != 0) {
            setEffective(1)
            const formData = new FormData()
            // 多个图片
            supplierFile.forEach((item) => {
                formData.append('file', item['originFileObj']);
            })
            formData.append('creator', cookie.load('name'))
            formData.append('roleId', cookie.load('role'));
            formData.append('shopList', cookie.load('shopList'));
            NbAxios(formData, "POST", servicePath.uploadOppositePersonGys).then(res => {
                setEffective(0)
                if (res.success) {
                    message.success(res.data['message'])
                    setSupplierFile([]);
                    findSummary()
                    setUploadSupplier(false)
                } else {
                    message.warn(res.errorMsg)
                }
            }).catch((err) => { 
                // 获取500错误
                setEffective(0)
                message.warn('接口报错')
            })
        } else {
            message.warn('请选择上传文件')
        }
    }
    // 预算目标文件上传
    const baseFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['pdf', 'docx', 'doc', 'xlsx', 'xls', 'png', 'jpg']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setBaseFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setBaseFile(fileList)
        }
    }
    // 预算供应商文件上传
    const supplierFileHandleChange = ({ fileList }) => {
        if (fileList.length != 0) {
            // 获取最新文件的文件名
            let fileName = fileList[fileList.length - 1].originFileObj['name']
            // 提取文件名的后缀名
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
            // 定义允许的类型
            let fileTypes = ['xlsx', 'xls']
            // 首先判断文件上传的类型
            if (fileTypes.indexOf(fileExtension) != -1) {
                let flag = fileList[fileList.length - 1].originFileObj['size'] / 1024 / 1024 < 50
                // 在判断文件的大小
                if (flag) {
                    // 更新文件(限制只能上传一个文件)
                    let newFileList = []
                    newFileList.push(fileList[fileList.length - 1])
                    setSupplierFile(newFileList)
                } else {
                    message.error('文件大小必须小于50M')
                    // 移除文件
                    fileList.pop()
                }
            } else {
                message.error('文件类型不符合')
                // 移除文件
                fileList.pop()
            }
        } else {
            setSupplierFile(fileList)
        }
    }
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-around", marginBottom: 20, marginTop: 10 , borderBottom:"1px solid #ccc"}}>
                <Title level={4}>供应商管理</Title>
                <div style={{ display: "flex" }}>
                    <a href="https://pic.bmcsoft.cn/imge_dir/122717595311/122717595311.html" target="_Blank"><Button type="link">操作手册</Button></a>
                    <Button type="link" style={{display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none"}}>
                        <a href={"https://pic.bmcsoft.cn/imge_dir/供应商上传模板.xlsx"}>下载供应商上传模板</a>
                    </Button>
                    <Button type="primary" onClick={showDrawer} style={{ marginRight: 10 }}>
                        条件查询
                    </Button>
                    {/* <Button
                        style={{ display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none",marginRight: 10 }}
                        type="primary"
                        onClick={showAddModal}>新增</Button> */}
                    <Button
                        style={{ marginRight: 10 }}
                        type="primary"
                        onClick={showAddModal}>新增</Button>
                    <Button
                        style={{ display: jurisdiction.indexOf("threepets::operationSMSData") != -1 ? "" : "none" }}
                        type="primary"
                        onClick={()=>{
                            setUploadSupplier(true)
                        }} danger>上传供应商</Button>
                </div>
            </div>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
                <Table
                    columns={columns}
                    dataSource={data}
                    bordered
                    scroll={{ x: 900, y: 'calc(100vh - 250px)' }}
                    pagination={paginationProps}
                    hideOnSinglePage={true} />
            </div>
            {/* model弹窗 */}
            {/* 新增基础消息弹窗 */}
            <Modal
                title="新增供应商"
                visible={isAddModalVisible}
                footer={[]}
                onCancel={() => {
                    handleAddCancel();
                    form.resetFields();
                }
                }
                width={1000}
                centered={true}
                okText="确认"
                cancelText="取消">
                <div>
                    <Form {...layout} labelAlign='left' form={form} onFinish={addSummary}>
                        <Row >
                            <Col span={12}>
                                <Form.Item label="分类" name="classification" size="large" style={{ width: 350 }} rules={[{ required: true, message: '请填写完整' }]} >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="公司名称" name="corporate_name" size="large" style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="供应商编码" name="supplier_code" size="large" style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="主要业务" name="main_business" size="large" style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="是否合作" name="cooperation"  style={{ width: 350 }}>
                                    <Radio.Group onChange={onChange} value={value}>
                                        <Radio value={"是"}>是</Radio>
                                        <Radio value={"否"}>否</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="初次合作时间" name="Initial_cooperation_time" style={{ width: 350 }}>

                                    <DatePicker dateFormat={dateFormat} picker="month" locale={locale}/>


                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="地区" name="region" style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} name="tax_point" label="税点" style={{ width: 350 }}>
                                    <Input addonAfter="%" />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="地址：" name="company_address" size="large" style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="备注：" name="remarks" style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    确定
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        handleAddCancel();
                                        form.resetFields();
                                    }
                                    }>
                                    取消
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </div>

            </Modal>
            {/* 修改基础消息弹窗 */}
            <Modal
                title="修改供应商"
                visible={isModifiedModalVisible}
                footer={[]}
                onCancel={() => {
                    handleModifiedCancel();
                    findSummary()
                }}
                width={1000}
                centered={true}
            >
                <div>
                    <Form {...layout} labelAlign='left' form={form} onFinish={modifiedSummary}>
                        <Row >
                            <Col span={12}>
                                <Form.Item label="分类：" name="classification" size="large"  style={{ width: 350 }} rules={[{ required: true, message: '请填写完整' }]} >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="公司名称：" name="corporate_name" size="large"  style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="供应商编码：" name="supplier_code" size="large" style={{ width: 350 }}>
                                    <Text type={"secondary"}>{form.getFieldValue('supplier_code')}</Text>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="主要业务：" name="main_business" size="large" style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="是否合作：" name="cooperation" style={{ width: 350 }}>
                                    <Radio.Group onChange={onChange} value={value}>
                                        <Radio value={"是"}>是</Radio>
                                        <Radio value={"否"}>否</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="初次合作时间：" name="Initial_cooperation_time" style={{ width: 350 }}>
                                    <DatePicker dateFormat={dateFormat} picker="month" locale={locale}/>


                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="地区：" name="region" style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} name="tax_point" label="税点：" style={{ width: 350 }}>
                                    <Input addonAfter="%" />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="地址：" name="company_address" size="large" style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="备注：" name="remarks" style={{ width: 350 }}>
                                    <Input />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    确定
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        handleModifiedCancel();
                                        findSummary()
                                    }}>
                                    取消
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </div>

            </Modal>
            {/* 上传商品基础信息弹窗 */}
            <Modal
                title="上传报价单"
                visible={isUpload}
                centered={true}
                okText="确定"
                cancelText="取消"
                maskClosable={true}
                onOk={() => {
                    uploadBaseFile();
                    message.success('附件上传成功')
                }}
                onCancel={() => {
                    message.success('取消上传报价单')
                    setBaseFile([])
                    setIsUpload(false)
                }}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.findTrue}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={baseFile}
                    onChange={baseFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.doc .docx .pdf .xlsx .xls .jpg .png文件
                    </p>
                </Dragger>
            </Modal>
              {/* 上传供应商弹窗 */}
              <Modal
                title="上传供应商"
                visible={uploadSupplier}
                centered={true}
                okText="确定"
                cancelText="取消"
                maskClosable={true}
                onOk={() => {
                    uploadSupplierFile();
                }}
                onCancel={() => {
                    message.success('取消上传供应商')
                    setSupplierFile([])
                    setUploadSupplier(false)
                }}
                okButtonProps={{ disabled: effective == 1 ? true : false }}
                cancelButtonProps={{ disabled: effective == 1 ? true : false }}
            >
                <Dragger
                    // accept=".pdf .doc .docx"
                    action={servicePath.findTrue}                         //上传地址，填错/不填上传图片的时候都会报错
                    fileList={supplierFile}
                    onChange={supplierFileHandleChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到该区域以上传</p>
                    <p className="ant-upload-hint">
                        仅支持上传一份后缀为.xlsx .xls 文件
                    </p>
                </Dragger>
            </Modal>
            <Drawer
                title="供应商条件查询"
                placement="right"
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                width={720}>
                <Form
                    form={searchForm}
                    layout="vertical"
                    onFinish={findSummary}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="classification"
                                label="分类"
                            >
                                <Input placeholder="请输入分类" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="supplier_code"
                                label="供应商编码"
                            >
                                <Input placeholder="请输入供应商编码" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="corporate_name"
                                label="公司名称"
                            >
                                <Input placeholder="请输入公司名称" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="cooperation"
                                label="是否合作"
                            >
                                <Input placeholder="请输入是否合作" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="Initial_cooperation_time"
                                label="初次合作时间"
                            >
                                <RangePicker />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: 'space-around' }}>
                        <Button type="primary" htmlType="submit"
                            onClick={() => {
                                onClose()
                            }}>
                            查询
                        </Button>
                        <Button type="primary" onClick={() => {
                            //重置form
                            searchForm.resetFields();
                            findSummary()
                        }}>
                            重置
                        </Button>
                    </div>
                </Form>
            </Drawer>
            {/* 报价单附件展示弹窗 */}
            <Modal
                title="报价单附件"
                // 控制弹框是否打开
                visible={isdownloadData}
                onCancel={() => {
                    setIsDownloadData(false)
                }}
                footer={[]}
                centered={true}
            >
                <div>
                    <Table
                        align="center"
                        columns={downloadcolumns}
                        dataSource={downloadData}
                        pagination={false}
                    />
                </div>
            </Modal>
            {/* 额外表格数据展示弹窗 */}
            <Modal
                title="额外表格数据展示"
                // 控制弹框是否打开
                visible={othertabledata}
                onCancel={() => {
                    setClickIndex(-1)
                    setOtherTableData(false)
                }}
                centered={true}
                footer={[]}
            >
                <div>
                    <div style={{ display: 'flex' }}>
                        <p>地址：</p>
                        <span>{tabledata.company_address}</span>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <p>税点：</p>
                        <span>{tabledata.tax_point}</span>
                        <p>%</p>
                    </div>


                    <Button
                        type="primary"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            setSupplierId(tabledata.id)
                            uploadBaseFile()
                            setIsUpload(true)
                        }}
                    >上传</Button>

                    <Button
                        type="primary"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            findQuotation(tabledata.id);
                            setIsDownloadData(true)
                        }
                        }
                    >下载报价单</Button>
                    <Button
                        type="primary"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            setSupplierId(tabledata.id)
                            showDuijierenModal()
                            findOppositePersongys(tabledata.id);
                        }
                        }>对接人</Button>
                </div>
            </Modal>
            {/* 对接人表弹窗 */}
            <Modal
                title="对接人表"
                visible={isDuijierenModalVisible}
                onCancel={handleDuijierenCancel}
                width={800}
                footer={[]}
                centered={true}
            >
                <br></br>
                <div style={{ display: "flex", justifyContent: "flex-end" }} >

                    <Row >
                        <Button
                            type="primary"
                            style={{ marginLeft: 20 }}
                            onClick={showOppositePersonXinzengModal}>新增</Button>
                        <Button
                            type="primary"
                            style={{ marginLeft: 20 }}
                            onClick={() => {
                                showMainOppositePersonXinzengModal()
                            }}
                        >主对接人</Button>
                    </Row>
                </div>
                <br></br>
                <div>
                    {
                        oppositePersonData.map((item, index) => {
                            if (index === 0) {
                                return (
                                    <Badge.Ribbon text="主对接人" color="red">
                                        <Form form={form} style={{boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', padding:20}}>
                                            <div>
                                                <Row >
                                                    <Col span={12}>
                                                        <Form.Item label="对接人：" name="opposite_person" style={{ width: 260 }}>
                                                            <span>{item.opposite_person}</span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label="联系方式：" name="contact_information" size="large" style={{ width: 260 }}>
                                                            <span>{item.contact_information}</span>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div>
                                                <Row >
                                                    <Col span={12}>
                                                        <Form.Item label="部门：" name="department" style={{ width: 260 }}>
                                                            <span>{item.department}</span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label="直接上级：" name="direct_superior" size="large" style={{ width: 260 }}>
                                                            <span>{item.direct_superior}</span>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Form.Item label="备注：" name="remarks" style={{ width: 260 }}>
                                                            <span>{item.remarks}</span>
                                                        </Form.Item>
                                                    </Col>

                                                </Row>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Row >
                                                    <Button
                                                        type="primary"
                                                        style={{ marginLeft: 20 }}
                                                        onClick={() => {
                                                            setMainId(item.id)
                                                            dockingForm.setFieldsValue(item)
                                                            showOppositePersonXiugaiModal()
                                                        }}>修改</Button>
                                                    <Button
                                                        type="primary"
                                                        style={{ marginLeft: 20 }}
                                                        onClick={() => {
                                                            oppositeperson_delect(item.id)

                                                        }}>删除</Button>
                                                </Row>

                                            </div>
                                        </Form>
                                    </Badge.Ribbon>
                                )

                            } else {
                                return (
                                    <Form form={form} style={{boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.4)', padding:20, marginTop:5}}>
                                        <div>
                                            <Row >
                                                <Col span={12}>
                                                    <Form.Item label="对接人：" name="opposite_person" style={{ width: 260 }}>
                                                        <span>{item.opposite_person}</span>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="联系方式：" name="contact_information" size="large" style={{ width: 260 }}>
                                                        <span>{item.contact_information}</span>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row >
                                                <Col span={12}>
                                                    <Form.Item label="部门：" name="department" style={{ width: 260 }}>
                                                        <span>{item.department}</span>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="直接上级：" name="direct_superior" size="large" style={{ width: 260 }}>
                                                        <span>{item.direct_superior}</span>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Form.Item label="备注：" name="remarks" style={{ width: 260 }}>
                                                        <span>{item.remarks}</span>
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Row >
                                                <Button
                                                    type="primary"
                                                    style={{ marginLeft: 20 }}
                                                    onClick={() => {
                                                        setMainId(item.id)
                                                        dockingForm.setFieldsValue(item)
                                                        showOppositePersonXiugaiModal()
                                                    }}>修改</Button>
                                                <Button
                                                    type="primary"
                                                    style={{ marginLeft: 20 }}
                                                    onClick={() => {
                                                        oppositeperson_delect(item.id)

                                                    }}>删除</Button>
                                            </Row>

                                        </div>

                                    </Form>

                                )
                            }
                        })
                    }
                </div>
            </Modal>
            {/* 新增对接人弹窗 */}
            <Modal
                title="新增对接人"
                visible={isOppositePersonXinzengModalVisible}
                onCancel={() => {
                    handleOppositePersonXinzengCancel();
                    dockingForm.resetFields();
                }}
                width={800}
                centered={true}
                footer={[]} >
                <Form name="addOppositePersdongys" form={dockingForm} onFinish={addOppositePersdongys}>
                    <div>

                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="对接人：" name="opposite_person"  style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="联系方式：" name="contact_information" size="large" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row >
                            <Col span={12}>
                                <Form.Item label="部门：" name="department" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="直接上级：" name="direct_superior" size="large" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col>
                                <Form.Item label="备注：" name="remarks" style={{ width: 500 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <Divider />
                    <Row>
                        <Col span={12}>
                            <Button
                                type="primary"
                                htmlType="submit">
                                确定
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                type="primary"
                                danger
                                onClick={() => {
                                    handleOppositePersonXinzengCancel();
                                    dockingForm.resetFields();
                                }
                                }>
                                取消
                            </Button>
                        </Col>
                    </Row>
                </Form>


            </Modal>
            {/* 主对接人设定弹窗 */}
            <Modal
                title="主对接人设定"
                visible={isMainOppositePersonXinzengModalVisible}
                onOk={handleMainOppositePersonXinzengOk}
                onCancel={handleMainOppositePersonXinzengCancel}
                width={400}
                centered={true}
                footer={[]} >
                <div>
                    {
                        // 输出每个对接人
                        oppositePersonData.map((item, index) => {
                            return (
                                <div>
                                    <Button
                                        type="text"
                                        icon={<UserOutlined />}
                                        onClick={() => {
                                            mainOppositePersongys(item.id)
                                        }}>{item.opposite_person}</Button>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
            {/* 对接人修改弹窗 */}
            <Modal
                title="对接人修改"
                visible={isOppositePersonXiugaiModalVisible}
                onCancel={handleOppositePersonXiugaiCancel}
                width={800}
                centered={true}
                footer={[]} >
                <Form form={dockingForm} onFinish={modifiedOppositePersongys}>
                    <div>

                        <Row >
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="对接人：" name="opposite_person" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{ required: true, message: '请填写完整' }]} label="联系方式：" name="contact_information" size="large" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row >
                            <Col span={12}>
                                <Form.Item label="部门：" name="department" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="直接上级：" name="direct_superior" size="large" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col>
                                <Form.Item label="备注：" name="remarks" style={{ width: 260 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <Divider />
                    <Row>
                        <Col span={12}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={() => {
                                    handleOppositePersonXiugaiOk()
                                }}>
                                确定
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                type="primary"
                                danger
                                onClick={() => handleOppositePersonXiugaiCancel()}>
                                取消
                            </Button>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </div>
    )
}
export default Index;