import React, { useState, useEffect, useCallback } from 'react'
import { Transfer, Modal, Table, Tag, Button, message, Spin, Row } from 'antd'
import difference from 'lodash/difference'
import cookie from 'react-cookies'
import axios from 'axios'
import servicePath from '../../../config/apiUrl'
import locale from 'antd/es/date-picker/locale/zh_CN';
import '../../../static/css/index.css'


function Personage(props) {

    //设置每个页面显示个数
    const paginationProps = {
        pageSize: 10, // 每页条数
        showSizeChanger: false,
    }



    // 自定义表转移
    const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
        <Transfer {...restProps} showSelectAll={false}>
            {({
                direction,
                filteredItems,
                onItemSelectAll,
                onItemSelect,
                selectedKeys: listSelectedKeys,
                disabled: listDisabled,
            }) => {
                const columns = direction === 'left' ? leftColumns : rightColumns;

                const rowSelection = {
                    getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows
                            .filter(item => !item.disabled)
                            .map(({ key }) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, listSelectedKeys)
                            : difference(listSelectedKeys, treeSelectedKeys);
                        onItemSelectAll(diffKeys, selected);
                    },
                    onSelect({ key }, selected) {
                        onItemSelect(key, selected);
                    },
                    selectedRowKeys: listSelectedKeys,
                };

                return (
                    <Table
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        pagination={paginationProps}
                        style={{ pointerEvents: listDisabled ? 'none' : null }}
                        onRow={({ key, disabled: itemDisabled }) => ({
                            style:{
                                pointerEvents: key == founder || key == director ? 'none' : ''
                               
                            },
                            onClick: () => {
                                if (itemDisabled || listDisabled) return;
                                onItemSelect(key, !listSelectedKeys.includes(key));
                            },
                        })}
                    />
                );
            }}
        </Transfer>
    );

    // 数据
    const [data, setData] = useState([])

    // 创建人
    const [founder , setFounder ] = useState('')

    // 负责人
    const [director , setDirector ] = useState('')

    // 选中的个人
    const [targetKeys, setTargetKeys] = useState([])

    // 标志位
    const [flag, setFlag] = useState(0)

    // 原先人员数量
    const [oldUserNumber, setOldUserNumber] = useState('')

    // 是否在加载
    const [isLoading, setIsLoading] = useState(true)

    // 获取屏幕的大小，包括长宽
    const [size, setSize] = useState({
        width: document.body.scrollWidth,
        height: window.innerHeight,
    })

    // start:获取屏幕大小的方法
    const onResize = useCallback(() => {
        setSize({
            width: document.body.scrollWidth,
            height: window.innerHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])
    // end:获取屏幕大小的方法

    // 左边表格标题
    const leftTableColumns = [
        {
            title: '可选择个人',
            children: [
                {
                    dataIndex: 'name',
                    title: '名字',
                    width: '50px',
                },
                {
                    dataIndex: 'department',
                    title: '部门',
                    width: '50px',
                },
                {
                    dataIndex: 'email',
                    title: '邮箱',
                    width: '50px',
                }
            ]
        }
    ];

    // 右边表格标题
    const rightTableColumns = [
        {
            title: '已存在个人',
            children: [
                {
                    dataIndex: 'name',
                    title: '名字',
                    width: '50px',
                },
                {
                    dataIndex: 'department',
                    title: '部门',
                    width: '50px',
                },
                {
                    dataIndex: 'email',
                    title: '邮箱',
                    width: '50px',
                }
            ]
        }
    ];

    useEffect(() => {
        getAllPeople()
        getExisUserData()
        setFounder(props.history.location.state.founder)
        setDirector(props.history.location.state.director)
    }, [])

    // 获取已存在团队数据
    const getExisUserData = () => {
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
            "project_id": props.project,
            "name": cookie.load('name'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryProjectExisUsermData,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    setTargetKeys(res.data.data);
                    setOldUserNumber(res.data.data.length)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    // 获取所有公司人员
    const getAllPeople = () => {
        setIsLoading(true)
        let dataProps = {
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList')
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.queryPMSysUser,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                setIsLoading(false)
                if (res.data.success) {
                    setData(res.data.data);
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    const onChange = (nextTargetKeys) => {
        setFlag(1)
        setTargetKeys(nextTargetKeys)
    }


    // 保存操作
    const save = () => {
        let dataProps = {
            "old_user_number": oldUserNumber,
            "new_user_number": targetKeys.length,
            "user_ids": targetKeys,
            "project_id": props.project,
            "principal_uuid": cookie.load('id'),
            "roleId": cookie.load('role'),
            "shopId": cookie.load('shopList'),
        }
        axios({
            method: 'POST',
            data: dataProps,
            url: servicePath.updateProjectUserData,
            headers: {
                'Content-Type': 'application/json',  //如果写成contentType会报错
                'authorization': cookie.load('token')
            }
        }).then(
            res => {
                if (res.data.success) {
                    message.success('数据保存成功')
                    getExisUserData()
                    setTargetKeys([])
                    setFlag(0)
                } else {
                    if (res.data['errorCode'] == '10001') {
                        message.error(res.data['errorMsg'])
                        cookie.remove("role", { path: '/' });
                        cookie.remove("shop", { path: '/' })
                        cookie.remove("shopName", { path: '/' })
                        cookie.remove("name", { path: '/' })
                        cookie.remove("roleName", { path: '/' })
                        cookie.remove("email", { path: '/' })
                        cookie.remove("phone", { path: '/' })
                        cookie.remove("watermark", { path: '/' })
                        cookie.remove("flag", { path: '/' })
                        cookie.remove("token", { path: '/' })
                        props.history.push("/")
                    } else {
                        message.error(res.data['errorMsg'])
                    }
                }
            }
        )
    }

    return (
        <div style={{ paddingBottom: size.height * 0.1 }}>
            <Spin spinning={isLoading} size="large" tip="Loading......" style={{ marginTop: size.height * 0.2 }}>
                <div style={{ marginTop: size.height * 0.03, marginBottom: size.height * 0.03 }}>
                    <Row>
                        <Button type="primary" onClick={() => save()} disabled={flag == 0 ? true : false}>保存</Button>
                        <div style={{marginLeft:20, color:'red', marginTop:10}}>项目负责人或者创建人，不能在此页面移除项目</div>
                    </Row>
                </div>
                <TableTransfer
                    locale={{ itemUnit: '人', itemsUnit: '人', searchPlaceholder: '请输入搜索内容' }}
                    dataSource={data}
                    targetKeys={targetKeys}
                    showSearch={true}
                    onChange={onChange}
                    filterOption={(inputValue, item) =>
                        item.department != null ?
                            item.name.indexOf(inputValue) !== -1 || item.email.indexOf(inputValue) !== -1 || item.department.indexOf(inputValue) !== -1
                            : item.name.indexOf(inputValue) !== -1 || item.email.indexOf(inputValue) !== -1
                    }
                    leftColumns={leftTableColumns}
                    rightColumns={rightTableColumns}
                />
            </Spin>
        </div>
    )

}

export default Personage