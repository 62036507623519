let ipUrl = 'https://nbapi.bmcsoft.cn'
let servicePath = {
    // login: ipUrl + '/user/login',               //登陆
    login: ipUrl + '/user/loginForWinmail',        //邮箱登陆
    updatePassword: ipUrl + '/user/updatePassword',     //第一次修改密码
    updateNewPassword: ipUrl + '/user/updateNewPassword',    //修改密码
    getVerification: ipUrl + '/user/getVerification',      //获取验证码
    checkVerification: ipUrl + '/user/checkVerification',    //校验验证码
    cancelGetPassword: ipUrl + '/user/cancelGetPassword',    //取消找回密码操作
    queryRoles: ipUrl + '/role/queryRoles/',    //获取所有角色
    queryNorRoles: ipUrl + '/role/getNorRoles/',   //获取所有正常角色
    addRole: ipUrl + '/role/addRole',    //增加角色
    deleteRole: ipUrl + '/role/deleteRole',    //删除角色
    updateRole: ipUrl + '/role/updateRole',    //更新角色
    recoverRole: ipUrl + '/role/recoverRole',  //恢复角色
    queryUser: ipUrl + '/user/getUser',       //获取所有用户
    addUser: ipUrl + '/user/addUser',          //增加用户
    updateUser: ipUrl + '/user/updateUser',    //更新用户
    deleteUser: ipUrl + '/user/deleteUser',    //删除用户
    recoverUser: ipUrl + '/user/recoverUser',  //恢复用户
    queryShops: ipUrl + '/shop/getShops/',     //获取所有店铺
    addShop: ipUrl + '/shop/addShop',       //添加店铺
    updateShop: ipUrl + '/shop/updateShop',    //修改店铺
    deleteShop: ipUrl + '/shop/deleteShop',    //删除店铺
    shopRelevanceMenu: ipUrl + '/shop/shopRelevanceMenu',  //查询店铺是否还要有关联菜单
    recoverShop: ipUrl + '/shop/recoverShop',  //恢复店铺
    queryNorShop: ipUrl + '/shop/getNorShop/',   //获取所有正常的店铺
    queryMenu: ipUrl + '/menu/getMenu/',        //获取所有菜单
    queryPartMenu: ipUrl + '/menu/getParMenu/',    //获取所有父菜单
    queryChildMenu: ipUrl + '/menu/getChildMenu/', //获取所有子菜单
    addMenu: ipUrl + '/menu/addMenu',              //添加菜单
    updateMenu: ipUrl + '/menu/updateMenu',        //更新菜单
    deleteMenu: ipUrl + '/menu/deleteMenu',        //删除菜单
    recoverMenu: ipUrl + '/menu/recoverMenu',      //恢复菜单
    getChildMenuByShop: ipUrl + '/menu/getChildMenuByShop',    //根据店铺获取菜单
    addDataPermission: ipUrl + '/dataPermission/addDataPermission',    //添加数据权限
    updateDataPermission: ipUrl + '/dataPermission/updateDataPermission',  //修改数据权限
    queryAllDataPermission: ipUrl + '/dataPermission/getAllDataPermission/', //获取全部数据权限
    deleteDataPermission: ipUrl + '/dataPermission/deleteDataPermission',  //禁用数据权限
    recoverDataPermission: ipUrl + '/dataPermission/recoverDataPermission',    //恢复数据权限
    queryPort: ipUrl + '/port/queryPort/',         //获取所有接口
    addPort: ipUrl + '/port/addPort',              //添加接口
    updatePort: ipUrl + '/port/updatePort',        //更新接口
    deletePort: ipUrl + '/port/deletePort',        //禁用接口
    recoverPort: ipUrl + '/port/recoverPort',      //恢复接口
    queryNorPort: ipUrl + '/port/queryNorPort/',    //获取正常的接口
    getPortByMenu: ipUrl + '/port/getPortByMenu',  //根据菜单获取接口
    getDataByPort: ipUrl + '/dataPermission/getDataByPort',    //根据接口获取数据权限
    getRoleShop: ipUrl + '/accredit/getRoleShop',      //根据角色获取店铺
    getRoleShopMenu: ipUrl + '/accredit/getRoleShopMenu/', //获取角色对应店铺下面的菜单权限
    getRoleShopTreeMenu: ipUrl + '/accredit/getRoleShopTreeMenu',  //获取角色对应店铺下面的树形菜单
    getRoleShopPort: ipUrl + '/accredit/getRoleShopPort/', //获取角色对应店铺下面的接口权限
    getRoleShopData: ipUrl + '/accredit/getRoleShopDatas',  //获取角色对应店铺下面的数据权限
    getAllRoleShopData: ipUrl + '/accredit/getAllRoleShopData',    //获取角色对应店铺下面的数据权限代码
    updateRoleShopPort: ipUrl + '/accredit/updateRoleShopPort',    //更新角色对应店铺下面的接口权限
    updateRoleShopMenu: ipUrl + '/accredit/updateRoleShopMenu',    //更新角色对应店铺下面的菜单权限
    updateRoleShopData: ipUrl + '/accredit/updateRoleShopData',     //更新角色对应店铺下面的数据权限
    getRoleShopPorts: ipUrl + '/accredit/getRoleShopPorts',         //获取角色对应店铺下面的接口权限代码
    getNotification: ipUrl + '/notification/getNotification',      //获取所有通知信息
    addNotification: ipUrl + '/notification/addNotification',      //添加通知
    updateNotification: ipUrl + '/notification/updateNotification',    //修改通知
    getOneNotification: ipUrl + '/notification/getOneNotification',    //获取一次通知
    getAllNotification: ipUrl + '/notification/getAllNotification',    //获取所有通知
    getRoleModuleMenu: ipUrl + '/accredit/getRoleModuleMenu',          //获取角色模块菜单


    //星云KOL管理
    getAllParty: ipUrl + '/party/getAllParty',             //获取所有的甲方信息接口
    searchParty: ipUrl + '/party/searchParty',              //搜索甲方
    addParty: ipUrl + '/party/addParty',                    //增加甲方信息
    updateParty: ipUrl + '/party/updateParty',              //更新甲方信息
    getNorParty: ipUrl + '/party/getNorParty',             //获取正常的甲方信息
    getAllKol: ipUrl + '/kol/getAllKol/',                   //获取所有KOL信息
    getNorKol: ipUrl + '/kol/getNorKol',                   //获取所有正常的KOL部分信息
    getPhoneOfKOl: ipUrl + '/kol/getPhoneOfKOl',           //获取KOL的联系方式
    addKol: ipUrl + '/kol/addKol',                         //增加KOL信息
    updateKol: ipUrl + '/kol/updateKol',                   //更新kol信息
    getKolByProvider: ipUrl + '/kol/getKolByProvider',     //根据机构查询kol信息
    getPlatform: ipUrl + '/kol/getPlatform',               //获取kol对应的平台信息
    searchKol: ipUrl + '/kol/searchKol',                   //搜索kol接口
    getKolTrench: ipUrl + '/kol/getKolTrench',             //获取Kol推广渠道
    searchKolTrench: ipUrl + '/kol/searchKolTrench',       //搜索Kol推广渠道
    queryAllProvider: ipUrl + '/provider/getAllProvider/', //获取所有机构接口
    getNorProvider: ipUrl + '/provider/getNorProvider/',   //获取所有正常机构的接口
    addProvider: ipUrl + '/provider/addProvider',          //增加机构接口
    searchProvider: ipUrl + '/provider/searchProvider',    //搜索机构接口
    updateProvider: ipUrl + '/provider/updateProvider',    //更新机构接口
    getPhoneOfProvider: ipUrl + '/provider/getPhoneOfProvider',    //获取机构联系方式
    getMarketing: ipUrl + '/marketing/getMarketing',      //获取营销数据
    getAllMarketing: ipUrl + '/marketing/getAllMarketing',     //获取所有营销数据
    updateMarketing: ipUrl + '/marketing/updateMarketing', //更新营销数据接口
    addMarketing: ipUrl + '/marketing/addMarketing',       //增加营销数据接口
    searchMarketing: ipUrl + '/marketing/searchMarketing', //搜索营销数据接口
    getAllPut: ipUrl + '/put/getAllPut',                   //获取所有投放数据
    addPutMessage: ipUrl + '/put/addPut',                   //添加投放信息
    updatePut: ipUrl + '/put/updatePut',                   //修改投放信息
    searchPut: ipUrl + '/put/searchPut',                    //搜索投放
    getAllKolOfPut: ipUrl + '/put/getAllKolOfPut',         //获取kol推广数据
    conditionPutOfKOL: ipUrl + '/put/conditionPutOfKOL',   // 动态搜索kol推广数据
    queryPromotionMessage: ipUrl + '/put/queryPromotionMessage', //查询推广管理基础信息

    // 市场排行接口
    getCategory: ipUrl + '/shopMarketIndustryRank/getSysType', //获取行业排行下店铺类目
    getStoreRank: ipUrl + '/shopMarketIndustryRank/getShopRank', //获取行业排行下店铺排行
    getProductRank: ipUrl + '/shopMarketIndustryRank/getItemRank', //获取行业排行下商品排行


    // 星云巨量数据
    getJillon: ipUrl + '/jillion/getJillon',               //获取巨量的数据
    getJillonTask: ipUrl + '/jillion/getTask',             //获取巨量任务列表
    getJillonExcel: ipUrl + '/jillion/getJillionExcel',    //下载巨量数据接口

    //商品基础信息接口
    searchItemInfo: ipUrl + '/ItemInfo/searchItemInfo',       //筛选功能
    setAddItemInfo: ipUrl + '/ItemInfo/setAddItemInfo',     //新增或修改商品基础信息
    uploadExcel: ipUrl + '/ItemInfo/uploadExcel',           //上传excel
    uploadRar: ipUrl + '/ItemInfo/uploadRar',               //上传rar
    uploadPicture: ipUrl + '/ItemInfo/uploadPicture',       //上传单张图片
    getFilterCondition: ipUrl + '/itemInfo/getFilterCondition',    //获取所有筛选条件接口
    getFieldSelectMenu: ipUrl + '/itemInfo/getFieldSelectMenu',    //获取字段选择菜单数据接口
    queryItemInfoData: ipUrl + '/itemInfo/queryItemInfoData',      //查询商品基础信息接口
    queryDisplayFieldData: ipUrl + '/itemInfo/queryDisplayFieldData',  //查询展示字段数据接口
    downloadItemInfoData: ipUrl + '/itemInfo/downloadItemInfoData',    //下载数据
    downloadItemInfoUploadFileTemplate: ipUrl + '/itemInfo/downloadItemInfoUploadFileTemplate',    //下载商品基础信息上传模版
    uploadItemInfoFile: ipUrl + '/itemInfo/uploadItemInfoFile',        //上传商品基础信息
    getUploadProgress: ipUrl + '/itemInfo/getUploadProgress',          //获取上传进度接口
    getRecentLostItemInfoNumber: ipUrl + '/itemInfo/getRecentLostItemInfoNumber',  //获取近期缺失商品基础信息数量接口
    downloadLostItemInfoData: ipUrl + '/itemInfo/downloadLostItemInfoData',        //导出缺失商品基础信息接口.
    // 插件爬虫文件导出接口
    // getSpiderPluginTask: ipUrl + '/spiderPluginTask/getSpiderPluginTask',    // 获取插件爬虫任务所有列表
    getSpiderPluginTask1: ipUrl + '/spiderPluginTask/getSpiderPluginTask1',    // 获取 1.商品关键字搜索结果爬取 任务列表
    getSpiderPluginTask2: ipUrl + '/spiderPluginTask/getSpiderPluginTask2',    // 获取 2.店铺关键字搜索结果爬取 任务列表
    getSpiderPluginTask3: ipUrl + '/spiderPluginTask/getSpiderPluginTask3',    // 获取 3.店铺所有商品列表爬取 任务列表
    getSpiderPluginTask4: ipUrl + '/spiderPluginTask/getSpiderPluginTask4',    // 获取 4.商品评论爬取（仅支持天猫） 任务列表
    getSpiderPluginTask5: ipUrl + '/spiderPluginTask/getSpiderPluginTask5',    // 获取 5.商品报活动价格爬取 任务列表
    getSpiderPluginTask6: ipUrl + '/spiderPluginTask/getSpiderPluginTask6',    // 获取 6.生意参谋预售活动商品详情爬取 任务列表
    getSpiderPluginTask7: ipUrl + '/spiderPluginTask/getSpiderPluginTask7',    // 获取 7.唯品会店铺商品爬取 任务列表
    getSpiderPluginTask8: ipUrl + '/spiderPluginTask/getSpiderPluginTask8',    // 获取 8.天猫用户清关信息爬取并上传至CRM 任务列表
    getSpiderPluginTask9: ipUrl + '/spiderPluginTask/getSpiderPluginTask9',    // 获取 9.手淘直播商品数据爬取 任务列表
    getSpiderPluginTask10: ipUrl + '/spiderPluginTask/getSpiderPluginTask10',    // 获取 10.直播广告投放花费监控 任务列表
    getSpiderPluginTask11: ipUrl + '/spiderPluginTask/getSpiderPluginTask11',    // 获取 11.抖音退货单 任务列表
    getSpiderPluginTask12: ipUrl + '/spiderPluginTask/getSpiderPluginTask12',    // 获取 12.修改抖音商品信息 任务列表
    getSpiderPluginTask13: ipUrl + '/spiderPluginTask/getSpiderPluginTask13',    // 获取 13.收集飞瓜推广视频数据 任务列表
    getTaskType1Data: ipUrl + '/spiderPluginTask/getTaskType1Data?task_id=',    // 获取 1.商品关键字搜索结果爬取 数据
    getTaskType2Data: ipUrl + '/spiderPluginTask/getTaskType2Data?task_id=',    // 获取 2:店铺关键字搜索结果爬取 数据
    getTaskType3Data: ipUrl + '/spiderPluginTask/getTaskType3Data?task_id=',    // 获取 3:店铺所有商品列表爬取 数据
    getTaskType4Data: ipUrl + '/spiderPluginTask/getTaskType4Data?task_id=',    // 获取 4:商品评论爬取（仅支持天猫） 结果
    getTaskType5Data: ipUrl + '/spiderPluginTask/getTaskType5Data?task_id=',    // 获取 5:商品报活动价格爬取 结果
    getTaskType6Data: ipUrl + '/spiderPluginTask/getTaskType6Data?task_id=',    // 获取 6:生意参谋预售活动商品详情爬取 结果
    getTaskType7Data: ipUrl + '/spiderPluginTask/getTaskType7Data?task_id=',    // 获取 7:唯品会店铺商品爬取 结果
    getTaskType9Data: ipUrl + '/spiderPluginTask/getTaskType9Data?task_id=',    // 获取 9:手淘直播商品数据爬取 结果
    getTaskType11Data: ipUrl + '/spiderPluginTask/getTaskType11Data?task_id=',    // 获取 11:抖音退货单 结果
    getTaskType13Data: ipUrl + '/spiderPluginTask/getTaskType13Data?task_id=',    // 获取 13:收集飞瓜推广视频数据 结果
    getCiun: ipUrl + '/spiderPluginTask/getCiyun',    // 获取 商品评论词云

    // 工单系统
    getCreatorWork: ipUrl + '/workOrder/getCreatorWork',            //创建人获取工单列表
    getAcceptorWork: ipUrl + '/workOrder/getAcceptorWork',          //处理人获取工单列表
    getComment: ipUrl + '/workOrder/getComment',                    //获取工单对应的评论
    getMessage: ipUrl + '/workOrder/getMessage',                    //获取消息
    lookMessage: ipUrl + '/workOrder/lookMessage',                  //查看消息
    addComment: ipUrl + '/workOrder/addComment',                    //添加评论
    image: ipUrl + '/workOrder/image',                              //接受图片地址
    finishWork: ipUrl + '/workOrder/finishWork',                    //确认完成工单
    rejectWork: ipUrl + '/workOrder/rejectWork',                    //驳回工单
    passWork: ipUrl + '/workOrder/passWork',                      //通过工单
    getOneWork: ipUrl + '/workOrder/getOneWork',                     //获取一个工单数据
    mistakeWork: ipUrl + '/workOrder/mistakeWork',                   //确认工单有误
    manageWork: ipUrl + '/workOrder/manageWork',                     //处理工单
    createWorkOrder: ipUrl + '/workOrder/createWorkOrder',          //创建工单
    allocationWork: ipUrl + '/workOrder/allocationWork',            //分配工单
    getWorkFile: ipUrl + '/workOrder/getFile',                     //工单下载文件
    createEmailWorkOrder: ipUrl + '/workOrder/createEmailWorkOrder',  //创建邮件工单接口

    // 组织架构
    getSubordinate: ipUrl + '/framework/getSubordinate',            //获取自己跟下属

    // Z11报表下载
    getWeeklyReportTime: ipUrl + '/reportFormZ11/makeZ11WeeklyReport',            // Z11周报下载
    getFlowReportTime: ipUrl + '/reportFormZ11/getFlowReport',            // Z11流量报表下载

    // 卓雅报表下载
    getRaceShopReport: ipUrl + '/report/getRaceShopReport',        //竞店数据报表下载
    getMarkRankReport: ipUrl + '/report/getMarkRankReport',        //行业排行数据报表下载
    getMarkBorderReport: ipUrl + '/report/getMarkBorderReport',        //行业排行数据报表下载


    // 会议管理
    myMeetings: ipUrl + '/meeting/getOwnerMeetingMessage',                                // 获取我的会议
    getAllPeople: ipUrl + '/meeting/getPeople',                               // 获取所有人员
    getAllRoom: ipUrl + '/meeting/getMeetingRoom',                            // 获取所有会议室
    getAllMeetings: ipUrl + '/meeting/getAllMeetingMessage',                  // 获取所有会议
    orderRoom: ipUrl + '/meeting/subscribeMeeting',                           // 预约会议
    orderDelete: ipUrl + '/meeting/cancelMeeting',                           // 删除预约
    aheadDelete: ipUrl + '/meeting/aheadMeeting',                        //提早结束会议
    meetingDeatil: ipUrl + '/meeting/getMeetingBaseMessage',   // 获取单个会议详情
    orSubscribeMeeting: ipUrl + '/meeting/orSubscribeMeeting',                             //二维码预约会议室
    searchMeeting: ipUrl + '/meeting/searchMeeting',                   //查询会议室
    smartSubscribeMeeting: ipUrl + '/meeting/smartSubscribeMeeting',   //智能预约会议室
    getMeetingDetails: ipUrl + '/meeting/getMeetingDetails',           //会议室详情
    queryUsageOfRoom: ipUrl + '/meeting/queryUsageOfRoom',           //查看当日所有会议
    queryOwnUsageRoom: ipUrl + '/meeting/queryOwnUsageRoom',           //查看当日我的会议


    // 总裁报表
    getPresidentReport: ipUrl + '/presidentReport/getPresidentReport',         //查看总裁日报
    getOverViewData: ipUrl + '/presidentReport/getOverViewData',               //查看汇总数据

    // 欣贺经销预售采购库存提醒
    getOutOfStockOrder: ipUrl + '/xhPurchase/getOutOfStockOrder',               //根据条件查询缺货订单接口
    getOutOfStockOrderCount: ipUrl + '/xhPurchase/getOutOfStockOrderCount',    //根据条件查询缺货订单统计接口
    getOutOfStockOrderList: ipUrl + '/xhPurchase/getOutOfStockOrderList',      //根据SKU列表和数量查询缺货订单接口
    creatPurchaseOrder: ipUrl + '/xhPurchase/creatPurchaseOrder',              //创建采购单接口
    updateOutOfStockOrder: ipUrl + '/xhPurchase/updateOutOfStockOrder',        //修改缺货订单备注接口
    getPurchaseOrder: ipUrl + '/xhPurchase/getPurchaseOrder',                  //根据条件查询采购单接口
    updatePurchaseOrder: ipUrl + '/xhPurchase/updatePurchaseOrder',            //修改采购单状态接口
    downloadPurchaseOrderE3: ipUrl + '/xhPurchase/downloadPurchaseOrderE3',    //下载采购单E3格式接口
    downloadPurchaseOrderJST: ipUrl + '/xhPurchase/downloadPurchaseOrderJST',  //下载采购单聚水潭格式接口
    deletePurchaseOrder: ipUrl + '/xhPurchase/deletePurchaseOrder',            //删除采购单接口
    getPurchaseOrderSku: ipUrl + '/xhPurchase/getPurchaseOrderSku',            //查询采购单下的SKU接口
    getPurchaseOrderOutOfStockOrderCount: ipUrl + '/xhPurchase/getPurchaseOrderOutOfStockOrderCount',   //采购单下增加SKU查询统计缺货订单接口
    addPurchaseOrderSku: ipUrl + '/xhPurchase/addPurchaseOrderSku',            //采购单下增加SKU接口
    deletePurchaseOrderSku: ipUrl + '/xhPurchase/deletePurchaseOrderSku',      //采购单下删除SKU接口
    getPurchaseOrderRelation: ipUrl + '/xhPurchase/getPurchaseOrderRelation',  //查看采购单SKU下的缺货订单接口
    getPurchaseOrderRelationOrderOutOfStockOrder: ipUrl + '/xhPurchase/getPurchaseOrderRelationOrderOutOfStockOrder', //采购单SKU下增加缺货订单查询缺货订单接口
    addPurchaseOrderRelationOrderOutOfStockOrder: ipUrl + '/xhPurchase/addPurchaseOrderRelationOrderOutOfStockOrder',  //采购单SKU下增加缺货订单接口
    deletePurchaseOrderRelationOrderOutOfStockOrder: ipUrl + '/xhPurchase/deletePurchaseOrderRelationOrderOutOfStockOrder',    //采购单SKU下删除缺货订单接口
    uploadOutOfStockOrder: ipUrl + '/xhPurchase/uploadOutOfStockOrder',    //导入缺货单接口
    uploadRefundOrder: ipUrl + '/xhPurchase/uploadRefund',            //导入销退仓单
    uploadPurchaseOrder: ipUrl + '/xhPurchase/uploadPurchaseOrder',    //导入采购单接口
    getOutOfStockOrderPurchaseOrder: ipUrl + '/xhPurchase/getOutOfStockOrderPurchaseOrder',    //查询缺货订单的采购单接口
    getFileTime: ipUrl + '/xhPurchase/getFileTime',            //获取导入缺货单/销退仓单的接口

    getBusinessStaffDailyResport: ipUrl + '/businessStaff/getBusinessStaffDailyResport',   //获取生意参谋日报表
    downBusinessStaffDailyResport: ipUrl + '/businessStaff/downBusinessStaffDailyResport', //下载生意参谋日报数据
    getBusinessStaffDailyAdditionalPurchase: ipUrl + '/businessStaff/getBusinessStaffDailyAdditionalPurchase', //获取加购销售折线图
    getTaskDaily: ipUrl + '/businessStaff/getTaskDaily',                                  //查看生意参谋日报下载并导入任务是否成功
    getDataIntegrity: ipUrl + '/businessStaff/getDataIntegrity',                          //查询日报数据是否完整
    // 竞店数据
    getRaceShopData: ipUrl + '/raceShop/getRaceShopData',
    getRaceShopLine: ipUrl + '/raceShop/getRaceShopLine',
    getRaceShopDetailData: ipUrl + '/raceShop/getRaceShopDetailData',                      //获取竞店数据详情数据接口
    getRaceShopContrastData: ipUrl + '/raceShop/getRaceShopContrastData',                  //获取竞店数据对比数据
    downloadRaceShopDetailData: ipUrl + '/raceShop/downloadRaceShopDetailData',            //下载竞店数据详情数据
    getRaceShopTotalData: ipUrl + '/raceShop/getRaceShopTotalData',                        //获取累计数据
    // 市场大盘
    getMarkData: ipUrl + '/shopMarketIndustryRank/getMarkData',
    getCateoryBar: ipUrl + '/shopMarketIndustryRank/getCateoryBar',

    // 宠粮经销供应商档案
    queryFoodDistributionSupplierData: ipUrl + '/petFoodDistributionSupplier/queryData',    //查询宠粮经销供应商档案数据接口（部分数据不返回）
    queryFoodDistributionSupplierDetailData: ipUrl + '/petFoodDistributionSupplier/queryDetailData',    //查询宠粮经销供应商档案数据接口（全量数据）
    uploadFoodDistributionSupplierFile: ipUrl + '/petFoodDistributionSupplier/uploadFile', //上传宠物供应商文件
    updateFoodDistributionSupplierData: ipUrl + '/petFoodDistributionSupplier/updateData', //修改宠粮经销供应商档案数据接口
    deleteFoodDistributionSupplierData: ipUrl + '/petFoodDistributionSupplier/deleteData', //删除宠粮经销供应商档案数据接口
    downloadFoodDistributionSupplierData: ipUrl + '/petFoodDistributionSupplier/downloadData', //下载供应商文件接口
    downloadFoodDistributionSupplierBjFile: ipUrl + '/petFoodDistributionSupplier/downloadBjFile',    //下载供应商报价表
    queryFoodDistributionSupplierBjData: ipUrl + '/petFoodDistributionSupplier/queryBjData',       //查询供应商报价表接口
    uploadFoodDistributionSupplierBjFile: ipUrl + '/petFoodDistributionSupplier/uploadBjFile',     //上传供应商报价表接口
    queryPetFoodCommodity: ipUrl + '/petFoodDistributionSupplier/queryPetFoodCommodity',       //查询宠粮经销商品基础信息接口
    updatePetFoodCommodity: ipUrl + '/petFoodDistributionSupplier/updatePetFoodCommodity',     //修改宠粮经销商品基础信息接口
    deletePetFoodCommodity: ipUrl + '/petFoodDistributionSupplier/deletePetFoodCommodity',     //删除宠粮经销商品基础信息接口
    uploadPetFoodCommodity: ipUrl + '/petFoodDistributionSupplier/uploadPetFoodCommodity',     //上传宠粮经销商品基础信息接口

    // 项目管理功能接口
    queryPMSysUser: ipUrl + '/projectManagement/querySysUser',         //查询所有公司成员接口
    createProject: ipUrl + '/projectManagement/createProject',       //创建项目基础信息接口
    queryProject: ipUrl + '/projectManagement/queryProject',           //查询项目信息接口
    updateProject: ipUrl + '/projectManagement/updateProject',         //更新项目接口
    deleteProject: ipUrl + '/projectManagement/deleteProject',         //项目完结接口
    queryTeamInfo: ipUrl + '/projectManagement/queryTeamInfo',         //查询团队信息接口
    createTeam: ipUrl + '/projectManagement/createTeam',               //创建团队接口
    updateTeam: ipUrl + '/projectManagement/updateTeam',               //更新团队接口
    dissolveTeam: ipUrl + '/projectManagement/dissolveTeam',           //解散团队接口
    queryProjectExisTeamData: ipUrl + '/projectManagement/queryProjectExisTeamData',   //获取项目已存在团队数据
    queryProjectTeamData: ipUrl + '/projectManagement/queryProjectTeamData',       //获取项目团队数据
    updateProjectTeamData: ipUrl + '/projectManagement/updateProjectTeamData',     //更新项目团队数据
    queryProjectExisUsermData: ipUrl + '/projectManagement/queryProjectExisUsermData', //获取项目已存在个人
    updateProjectUserData: ipUrl + '/projectManagement/updateProjectUserData',     //更新项目个人数据
    queryTaskFounderProjectList: ipUrl + '/projectManagement/queryTaskFounderProjectList',   //查询任务创建人所在项目
    queryTaskFounderTeamList: ipUrl + '/projectManagement/queryTaskFounderTeamList',         //查询任务创建人所在团队列表接口
    queryCollaboratorsList: ipUrl + '/projectManagement/queryCollaboratorsList',             //查询任务协作人列表接口
    queryPrincipalTaskList: ipUrl + '/projectManagement/queryPrincipalTaskList',             //查询父任务列表
    createProjectManagementTask: ipUrl + '/projectManagement/createTask',                    //创建任务
    queryProjectManagementTask: ipUrl + '/projectManagement/queryTask',                      //查询任务
    queryProjectManagementStateTask: ipUrl + '/projectManagement/queryStateTask',            //获取任务看板数据
    updateProjectManagementStateTask: ipUrl + '/projectManagement/updateTask',               //更新任务
    downloadTaskFile: ipUrl + '/projectManagement/downloadTaskFile',                         //下载任务文件
    queryOwnDailyReport: ipUrl + '/projectManagement/queryOwnDailyReport',                   //获取日报数据
    createDailyReport: ipUrl + '/projectManagement/createDailyReport',                       //创建日报数据
    downloadDailyReportFile: ipUrl + '/projectManagement/downloadDailyReportFile',           //下载日报文件
    updateDailyReport: ipUrl + '/projectManagement/updateDailyReport',                       //更新日报数据
    queryOwnDailyTaskProgress: ipUrl + '/projectManagement/queryOwnDailyTaskProgress',        //更新每日个人的工作进度变化
    queryMemberDailyReport: ipUrl + '/projectManagement/queryMemberDailyReport',          //查询成员的日报接口
    queryMemberDailyReportDetail: ipUrl + '/projectManagement/queryMemberDailyReportDetail',   //获取日报详情
    queryOwnWeeklyReport: ipUrl + '/projectManagement/queryOwnWeeklyReport',                //获取个人周报
    createWeeklyReport: ipUrl + '/projectManagement/createWeeklyReport',                   //创建周报
    queryOwnWeeklyReportDetail: ipUrl + '/projectManagement/queryOwnWeeklyReportDetail',    //查看个人周报详情
    updateWeeklyReport: ipUrl + '/projectManagement/updateWeeklyReport',                    // 更新周报
    queryWeekList: ipUrl + '/projectManagement/queryWeekList',                              //一年的周期
    queryMemberWeeklyReport: ipUrl + '/projectManagement/queryMemberWeeklyReport',          // 获取成员周报
    downloadWeeklyReportFile: ipUrl + '/projectManagement/downloadWeeklyReportFile',        //周报文件下载
    queryTaskDetail: ipUrl + '/projectManagement/queryTaskDetail',                             //获取任务详情
    createTaskReview: ipUrl + '/projectManagement/createTaskReview',                            //创建任务评论
    queryTaskReview: ipUrl + '/projectManagement/queryTaskReview',                              //查看任务评论


    //个人工作台接口
    getAllPersonalMeeting: ipUrl + '/personalWorkbench/getAllPersonalMeeting',                 //获取个人会议
    getAllPersonalWorkOrder: ipUrl + '/personalWorkbench/getAllPersonalWorkOrder',             //获取个人工单
    getWeatherData: ipUrl + '/personalWorkbench/getWeatherData',                               //获取天气数据

    // getPersonalDailyReport : ipUrl + '/personalWorkbench/getPersonalDailyReport',               //获取个人日报

    // 站内信模块
    getPersonalFiveLetters: ipUrl + '/siteLetter/getPersonalFiveLetters',                              // 获取前5条个人站内信
    getPersonalAllLetters: ipUrl + '/siteLetter/getPersonalAllLetters',                              // 获取所有个人站内信
    readLetter: ipUrl + '/siteLetter/readLetter',                                                    // 站内信标记已读接口
    getUnreadCount: ipUrl + '/siteLetter/getUnreadCount',                                            // 站内信获取未读消息数
    readAllLetter: ipUrl + '/siteLetter/readAllLetter',                                              // 站内信标记所有已读接口
    // 女装取数
    queryBasic: ipUrl + '/basicAccess/queryBasic',                                 //获取an的商品订单人群数据
    queryProList: ipUrl + '/basicAccess/queryProList',                                  //获取省
    queryDyList: ipUrl + '/basicAccess/queryDyList',                                   // 获取地域
    queryCityList: ipUrl + '/basicAccess/queryCityList',                               // 获取城市
    queryProductLineList: ipUrl + '/basicAccess/queryProductLineList',                 //获取产品线
    queryBrandList: ipUrl + '/basicAccess/queryBrandList',                             //获取品牌
    queryYearList: ipUrl + '/basicAccess/queryYearList',                               //获取年份
    querySeasonList: ipUrl + '/basicAccess/querySeasonList',                           //获取季节
    queryColorList: ipUrl + '/basicAccess/queryColorList',                             //获取颜色
    querySizeList: ipUrl + '/basicAccess/querySizeList',                               //获取尺寸
    queryYhfcList: ipUrl + '/basicAccess/queryYhfcList',                               //获取用户分层
    queryCityLevelList: ipUrl + '/basicAccess/queryCityLevelList',                     //城市等级
    queryCategoryList: ipUrl + '/basicAccess/queryCategoryList',                       //获取品类
    downloadBasic: ipUrl + '/basicAccess/downloadBasic',                               //下载基础数据
    queryFileTask: ipUrl + '/makeFileTask/queryFileTask',                              //获取女装下载文件任务
    downloadFile: ipUrl + '/makeFileTask/downloadFile',                                //下载女装文件
    createBasicFileTask: ipUrl + '/basicAccess/createBasicFileTask',                  //创建女装下载任务

    // 批量处理菜单、接口 权限
    batchProcessingRoleMenu: ipUrl + '/accredit/batchProcessingRoleMenu',               //批量处理角色菜单权限
    batchProcessingRolePort: ipUrl + '/accredit/batchProcessingRolePort',               //批量处理角色接口权限

    // 欣贺月报模块
    getMonthTableData: ipUrl + '/xhMonthReport/getMonthTableData',               //获取月报表格数据
    uploadXhExcel: ipUrl + '/xhMonthReport/uploadExcel',               //获取月报表格数据
    creatXhMonthTableFileTask: ipUrl + '/xhMonthReport/creatXhMonthTableFileTask',  //创建月报下载任务

    // 夜校模块接口
    queryNsCoursesPublishMainList: ipUrl + '/nightSchool/queryNsCoursesPublishMainList',  //获取夜校约课页数据
    createNsCoursesParticipant: ipUrl + '/nightSchool/createNsCoursesParticipant', //报名接口
    createCursorBase: ipUrl + '/nightSchool/createCourses',                            //创建课程基础信息
    queryCursorBase: ipUrl + '/nightSchool/queryCoursesBase',                            //查询课程基础信息
    queryCursorTypes: ipUrl + '/nightSchool/queryCoursesTypes',                               //查询课程分类
    createNsCoursesPublish: ipUrl + '/nightSchool/createNsCoursesPublish',             //发布夜校
    queryNsCoursesPublishList: ipUrl + '/nightSchool/queryNsCoursesPublishList',       //查询已发布的课程
    queryNsCoursesPublishDetail: ipUrl + '/nightSchool/queryNsCoursesPublishDetail',     //查询课程详情
    creatNsCoursesPublishComment: ipUrl + '/nightSchool/creatNsCoursesPublishComment',     //添加评论
    downloadNsCoursesPublishFile: ipUrl + '/nightSchool/downloadNsCoursesPublishFile',     //下载课件
    downloadNsCoursesPublishCommentFile: ipUrl + '/nightSchool/downloadNsCoursesPublishCommentFile',   //下载评论文件
    queryNsCoursesParticipantList: ipUrl + '/nightSchool/queryNsCoursesParticipantList',       //查询学习的课程
    queryNsCourseSeek: ipUrl + '/nightSchool/queryNsCourseSeek',                               //查询已求课、可求课、已约课接口
    createNsCourseSeek: ipUrl + '/nightSchool/createNsCourseSeek',                             //求开课
    createNextNsCourseSeek: ipUrl + '/nightSchool/createNextNsCourseSeek',                     //再次求开课
    queryAlreadySeekList: ipUrl + '/nightSchool/queryAlreadySeekList',                         //查询所有已求课
    queryAlreadyScheduledList: ipUrl + '/nightSchool/queryAlreadyScheduledList',                //查询所有已预约好的课
    queryNsFocusTypeList: ipUrl + '/nightSchool/queryNsFocusTypeList',                         //查询课程分类列表
    createNsFocusType: ipUrl + '/nightSchool/createNsFocusType',                               //创建自己感兴趣的课程分类
    updateNsFocusType: ipUrl + '/nightSchool/updateNsFocusType',                               //更新自己感兴趣的课程分类

    // 女装销售看板
    setSaleBorderTarget: ipUrl + '/saleBorder/setSaleBorderTarget',                               //设置目标
    getDailyReportStatistical: ipUrl + '/saleBorder/getDailyReportStatistical',                               //获取日报汇总数据
    getTargetByYear: ipUrl + '/saleBorder/getTargetByYear',                               // 根据年份获取目标
    getGoodsTableData: ipUrl + '/saleBorder/getGoodsTableData',                               // 获取表格商品数据
    getGoodsTotalData: ipUrl + '/saleBorder/getGoodsTotalData',                               // 获取表格商品汇总数据
    getGoodsTableTotalLength: ipUrl + '/saleBorder/getGoodsTableTotalLength',                               // 获取表格数据总量
    getGoodsItemReport: ipUrl + '/saleBorder/getGoodsItemReport',                               // 导出表格数据
    setExcludeGoodsId: ipUrl + '/saleBorder/setExcludeGoodsId',                               // 设置排除商品id
    setExcludeGoodsIdFailure: ipUrl + '/saleBorder/setExcludeGoodsIdFailure',                 // 将排除商品id重新加入计算
    getGoodsItemLostExcel: ipUrl + '/saleBorder/getGoodsItemLostExcel',                 // 获取商品信息缺失导出模板
    getSalesStatisticsData: ipUrl + '/dailyDataReport/getSalesStatisticsData',         //获取日报汇总数据
    getSalesTrendData: ipUrl + '/dailyDataReport/getSalesTrendData',                   //获取销售趋势数据接口
    getSaleTargetData: ipUrl + '/dailyDataReport/getSaleTargetData',                   //获取目标接口
    updateSaleTargetData: ipUrl + '/dailyDataReport/updateSaleTargetData',             //更新目标接口
    // 校招模块接口
    getRecruitInformation: ipUrl + '/recruitBackstage/getRecruitInformation',                    //获取人才库数据
    downloadResume: ipUrl + '/recruitBackstage/downloadResume',                                  //人才库简历下载
    getCreateRecruitPositionList: ipUrl + '/recruitBackstage/getCreateRecruitPositionList',      //招聘计划查询职位列表接口
    createRecruitProgrammePositionRelation: ipUrl + '/recruitBackstage/createRecruitProgrammePositionRelation',    //新增招聘计划接口
    getRecruitProgrammePosition: ipUrl + '/recruitBackstage/getRecruitProgrammePosition',          //获取招聘计划数据
    getRecruitProgrammePositionRelation: ipUrl + '/recruitBackstage/getRecruitProgrammePositionRelation',  //查询招聘计划职位详情接口
    updateRecruitProgrammePositionRelation: ipUrl + '/recruitBackstage/updateRecruitProgrammePositionRelation',    //修改招聘计划接口
    getUpdateRecruitPositionList: ipUrl + '/recruitBackstage/getUpdateRecruitPositionList',        //修改招聘计划查询职位列表接口
    publishRecruitProgrammePosition: ipUrl + '/recruitBackstage/publishRecruitProgrammePosition',  //发布招聘计划接口
    cancelRecruitProgrammePosition: ipUrl + '/recruitBackstage/cancelRecruitProgrammePosition',    //取消发布招聘计划接口
    getRecruitInterviewManagement: ipUrl + '/recruitBackstage/getRecruitInterviewManagement',    //获取面试管理数据
    downloadRecruitInterviewManagementFile: ipUrl + '/recruitBackstage/downloadRecruitInterviewManagementFile',    //获取面试管理数据
    adoptRecruitInterviewManagement: ipUrl + '/recruitBackstage/adoptRecruitInterviewManagement',    // 面试通过至下一步接口
    failedRecruitInterviewManagement: ipUrl + '/recruitBackstage/failedRecruitInterviewManagement',    // 面试失败接口
    updateRecruitInterviewManagement: ipUrl + '/recruitBackstage/updateRecruitInterviewManagement',    // 修改面试详情接口
    getRecruitPosition: ipUrl + '/recruitBackstage/getRecruitPosition',    // 查询职位
    createRecruitPosition: ipUrl + '/recruitBackstage/createRecruitPosition',    // 新增职位
    updateRecruitPosition: ipUrl + '/recruitBackstage/updateRecruitPosition',    // 修改职位

    //福来分销店铺接口
    queryDistributionShopData: ipUrl + '/distributionShop/queryDistributionShopData',              //查询分销店铺数据
    queryDistributionShopRelevantPeople: ipUrl + '/distributionShop/queryDistributionShopRelevantPeople',  //查看分销店铺相关人信息
    createDistributionShopData: ipUrl + '/distributionShop/createDistributionShopData',                //新增分销店铺数据
    updateDistributionShopData: ipUrl + '/distributionShop/updateDistributionShopData',             //更新分销店铺数据
    queryPurchaseAmount: ipUrl + '/distributionShop/queryPurchaseAmount',                          //查询采货金额数据
    updatePurchaseAmount: ipUrl + '/distributionShop/updatePurchaseAmount',                        //更新采货金额数据
    uploadPurchaseAmount: ipUrl + '/distributionShop/uploadPurchaseAmount',                        //上传采货金额数据
    queryAssignPersonnel: ipUrl + '/distributionShop/queryAssignPersonnel',                        //查询可分配人员
    updateAssignPersonnel: ipUrl + '/distributionShop/updateAssignPersonnel',                      //修改可分配人
    queryTopAmountShop: ipUrl + '/distributionShop/queryTopAmountShop',                            //获取Top10店铺
    queryAssignPersonAmount: ipUrl + '/distributionShop/queryAssignPersonAmount',                  //获取业务人员的采购金额
    queryAssignPersonNewShop: ipUrl + '/distributionShop/queryAssignPersonNewShop',                //获取业务人员的新开发店铺
    queryAssignPersonShopAmount: ipUrl + '/distributionShop/queryAssignPersonShopAmount',          //获取业务人员的每个店铺每个月业绩数据
    queryPurchasedShop: ipUrl + '/distributionShop/queryPurchasedShop',                            //获取采购订单的店铺数据
    queryPurchasedShopAmount: ipUrl + '/distributionShop/queryPurchasedShopAmount',                //获取店铺的采购数据

    // 一枕星河官网
    getContactCompany: ipUrl + '/milkyWay/getContactCompany',                // 获取一枕星河官网联系我们客户信息
    getTotalCompany: ipUrl + '/milkyWay/getTotalCompany',                // 获取一枕星河官网联系我们客户统计信息

    // 新版KOL博主
    queryAllPlatformKol: ipUrl + '/kol/queryAllPlatformKol',               //获取KOL各个平台数据
    queryPlatformKolContent: ipUrl + '/kol/queryPlatformKolContent',       //获取KOL联系方式
    queryPlatformKolEchart: ipUrl + '/kol/queryPlatformKolEchart',         //获取KOL平台图表数据
    updatePlatformKolData: ipUrl + '/kol/updatePlatformKolData',           //修改KOL平台数据
    uploadKolData: ipUrl + '/kol/uploadKolData',                           //导入KOL数据
    queryAssignPersonNewKol: ipUrl + '/kol/queryAssignPersonNewKol',       //获取业务人员的新开发KOL
    uploadKolCost: ipUrl + '/kol/uploadKolCost',                           //导入KOL成本数据
    queryKolCost: ipUrl + '/kol/queryKolCost',                             //获取KOL成本数据
    uploadKolLaunch: ipUrl + '/kol/uploadKolLaunch',                       //导入KOL投放数据
    queryKolLaunch: ipUrl + '/kol/queryKolLaunch',                         //查询KOL投放数据
    disablePlatformKolData: ipUrl + '/kol/disablePlatformKolData',         //禁用KOL数据

    // 对接短信接口
    sendSmsForResetPassword: ipUrl + '/user/sendSmsForResetPassword',      //NB重置邮箱密码发送验证码接口
    validateActivationCodeForResetPassword: ipUrl + '/user/validateActivationCodeForResetPassword',    //NB重置邮箱密码验证验证码修改密码接口
    LogForResetPassword: ipUrl + '/user/LogForResetPassword',   //NB用户登入后修改密码接口
    loginWebWinmail: ipUrl + '/user/loginWebWinmail',          //登入公司邮箱页面接口
    getWinmailMsglist: ipUrl + '/user/getWinmailMsglist',      //获取未读邮件数量
    addNbUser: ipUrl + '/user/addNbUser',                      //手动新增NB用户接口
    disableNbUser: ipUrl + '/user/disableNbUser',              //手动禁用NB用户接口
    recoveryNbUser: ipUrl + '/user/recoveryNbUser',            //手动恢复NB用户接口

    // 商品数据统计分析接口
    getTableDataByType: ipUrl + '/productAnalysis/getTableDataByType',       // 根据类型获取表格数据
    getCustomTableData: ipUrl + '/productAnalysis/getCustomTableData',       // 获取自定义条件分析数据
    getGoodsIdList: ipUrl + '/productAnalysis/getGoodsIdList',       // 根据条件获取商品id列表
    getCustomConditions: ipUrl + '/productAnalysis/getCustomConditions',       // 根据自定义分析可选条件
    getTopData: ipUrl + '/productAnalysis/getTopData',                 // 获取TOP类型表格数据
    getCommodityGoodsIdList: ipUrl + '/productAnalysis/getCommodityGoodsIdList',   //根据条件获取商品id列表
    //销售业绩
    querySalesPerformance: ipUrl + '/salesPerformance/querySalesPerformance',  //查询销售业绩
    uploadBudgetTarget: ipUrl + '/salesPerformance/uploadBudgetTarget',    //上传预算目标
    uploadDataSummary: ipUrl + '/salesPerformance/uploadDataSummary',      //上传汇总数据
    downloadSalesPerformance: ipUrl + '/salesPerformance/downloadSalesPerformance', //下载销售业绩
    queryDivisionPlate: ipUrl + '/salesPerformance/queryDivisionPlate',              //获取事业部、板块
    downloadDataSummary: ipUrl + '/salesPerformance/downloadDataSummary',          //下载业绩上传模版
    queryMonthPlateSalesSummary: ipUrl + '/salesPerformance/queryMonthPlateSalesSummary',    //查询每个月板块下面的各个项目业绩汇总
    queryYearQuarterlyPlateSalesSummary: ipUrl + '/salesPerformance/queryYearQuarterlyPlateSalesSummary',      //查询每年每季度板块下面的各个项目业绩汇总
    queryYearPlateSalesSummaryEchart: ipUrl + '/salesPerformance/queryYearPlateSalesSummaryEchart', //查询每年板块下面的各个项目业绩汇总表格
    queryDivisionPlateByDivision: ipUrl + '/salesPerformance/queryDivisionPlateByDivision',    //根据事业部获取事业部、板块
    queryMonthDivisionSalesSummary: ipUrl + '/salesPerformance/queryMonthDivisionSalesSummary',    //查询每个月事业部下面的各个项目业绩汇总
    queryYearQuarterlyDivisionSalesSummary: ipUrl + '/salesPerformance/queryYearQuarterlyDivisionSalesSummary',  //查询每年每季度事业部下面的各个项目业绩汇总
    queryYearDivisionSalesSummaryEchart: ipUrl + '/salesPerformance/queryYearDivisionSalesSummaryEchart', //查询每年事业部下面的各个板块业绩汇总表格
    queryProjectByDivision: ipUrl + '/salesPerformance/queryProjectByDivision',                  //根据事业部获取项目
    queryMonthShopSummary: ipUrl + '/salesPerformance/queryMonthShopSummary',                    //查询每个月店铺业绩汇总
    queryYearQuarterlyShopSalesSummary: ipUrl + '/salesPerformance/queryYearQuarterlyShopSalesSummary',    //查询每年、每季度板块下面的各个店铺业绩汇总
    queryShopSalesTargetData: ipUrl + '/salesPerformance/queryShopSalesTargetData',            //获取店铺销售目标数据
    updateShopSalesTargetData: ipUrl + '/salesPerformance/updateShopSalesTargetData',          //修改店铺销售目标数据
    deleteShopSalesTargetData: ipUrl + '/salesPerformance/deleteShopSalesTargetData',          //禁用店铺销售目标数据
    addShopSalesTargetData: ipUrl + '/salesPerformance/addShopSalesTargetData',                //添加店铺销售目标数据
    //OTM
    getOtmData: ipUrl + '/otm/getOtmData',                                  //获取所有OTM数据
    getOtmSingleData: ipUrl + '/otm/getOtmSingleData',                     //获取单个OTM数据
    queryOtmOperation: ipUrl + '/otm/queryOtmOperation',                   //查询操作详情
    updateOtmOperation: ipUrl + '/otm/updateOtmOperation',                 //更新操作
    queryOtmOperationList: ipUrl + '/otm/queryOtmOperationList',            //查询OTM阶段
    addOtmOperation: ipUrl + '/otm/addOtmOperation',                       //添加操作
    addOtmOperationModel: ipUrl + '/otm/addOperationModel',                  //新增操作模型接口
    queryOtmOperationNameList: ipUrl + '/otm/queryOperationNameList',           //查询操作名称列表接口
    queryTypeBusinessList: ipUrl + '/otm/queryTypeBusinessList',               //查询业务分类列表接口
    queryOtmOperationModel: ipUrl + '/otm/queryOperationModel',                   //查询操作模型接口
    updateOtmOperationModel: ipUrl + '/otm/updateOperationModel',                     //修改操作模型接口
    deleteOperationModel: ipUrl + '/otm/deleteOperationModel',                      //禁用操作模型接口
    addOtmEventModel: ipUrl + '/otm/addOtmEventModel',                             //新增事件模型接口
    queryOtmLevelList: ipUrl + '/otm/queryLevelList',                                  //查询事件等级列表接口
    queryOtmEventModel: ipUrl + '/otm/queryOtmEventModel',                            //查询事件模型
    deleteOtmEventModel: ipUrl + '/otm/deleteOtmEventModel',                           // 删除事件模型
    updateOtmEventModel: ipUrl + '/otm/updateOtmEventModel',                           //修改事件模型
    queryOtmStageList: ipUrl + '/otm/queryStageList',                                    //查询事件阶段列表接口
    addOtmStageModel: ipUrl + '/otm/addOtmStageModel',                                 //新增事件阶段模型接口
    queryOtmStageModel: ipUrl + '/otm/queryOtmStageModel',                             //查询事件阶段模型接口
    deleteOtmStageModel: ipUrl + '/otm/deleteOtmStageModel',                           //删除事件阶段模型接口
    queryOtmStageModelOperationModelOperation: ipUrl + '/otm/queryOtmStageModelOperationModelOperation',   //  查询阶段模型操作模型关系接口
    addOtmStageModelOperationModelOperation: ipUrl + '/otm/addOtmStageModelOperationModelOperation',   //新增阶段模型操作模型关系接口
    deleteOtmStageModelOperationModelOperation: ipUrl + '/otm/deleteOtmStageModelOperationModelOperation', //删除阶段模型操作模型关系接口
    queryOtmModel: ipUrl + '/otm/queryOtmModel',                                   //查询OTM模型接口
    queryOtmModelDetail: ipUrl + '/otm/queryOtmModelDetail',                       //查询OTM模型详情接口
    queryOtmOperationModelDetail: ipUrl + '/otm/queryOtmOperationModelDetail',     //查询事件模型接口
    queryOtmEventModelDetail: ipUrl + '/otm/queryOtmEventModelDetail',             //查询事件模型详情
    queryOtmStageModelList: ipUrl + '/otm/queryOtmStageModelList',                 //查询事件阶段模型列表接口
    queryOtmOperationModelList: ipUrl + '/otm/queryOtmOperationModelList',         //查询事件阶段模型操作列表接口
    addOtmTemporaryOperation: ipUrl + '/otm/addOtmTemporaryOperation',             //创建事件接口
    addOtmEvent: ipUrl + '/otm/addOtmEvent',                                       //创建事件接口
    updateOtmEvent: ipUrl + '/otm/updateOtmEvent',                                 //更新事件接口
    queryOtmEvent: ipUrl + '/otm/queryOtmEvent',                                   //查询事件接口
    addOtmEventAxis: ipUrl + '/otm/addOtmEventAxis',                               //保存并拆分阶段接口
    getOtmPreviewData: ipUrl + '/otm/getOtmPreviewData',                           //获取事件轴数据预览接口
    queryOtmEventStage: ipUrl + '/otm/queryOtmEventStage',                          //查询事件下阶段接口
    completeOtmOperation: ipUrl + '/otm/completeOperation',                         //完成操作接口
    queryOtmOperationFeedback: ipUrl + '/otm/queryOtmOperationFeedback',           //查询操作下接口
    addOtmOperationFeedback: ipUrl + '/otm/addOtmOperationFeedback',               //新增操作下反馈接口
    updateOtmOperationFeedback: ipUrl + '/otm/updateOtmOperationFeedback',         //修改操作下反馈接口
    queryOtmOperationFeedbackFile: ipUrl + '/otm/queryOtmOperationFeedbackFile',   //查询操作下反馈文件接口
    addOtmOperationFeedbackFile: ipUrl + '/otm/addOtmOperationFeedbackFile',       //新增操作下反馈文件接口
    downloadOtmOperationFeedbackFile: ipUrl + '/otm/downloadOtmOperationFeedbackFile', //下载操作下反馈文件接口
    getOtmReportData: ipUrl + '/otm/getOtmReportData',                             //查询日报接口
    deleteOtmOperation: ipUrl + '/otm/deleteOtmOperation',                         //删除操作接口
    addOtmCatalogue: ipUrl + '/otm/addOtmCatalogue',                              //新增菜单层次接口
    deleteOtmCatalogue: ipUrl + '/otm/deleteOtmCatalogue',                         //删除菜单层次接口
    queryOtmCatalogue: ipUrl + '/otm/queryOtmCatalogue',                           //查询菜单层次接口
    sortOtmCatalogue: ipUrl + '/otm/sortOtmCatalogue',                             //排序菜单层次接口
    updateOtmCatalogue: ipUrl + '/otm/updateOtmCatalogue',                         //更新菜单层次接口
    queryOtmCataloguePopulation: ipUrl + '/otm/queryOtmCataloguePopulation',       //查询菜单操作接口
    addOtmCataloguePopulation: ipUrl + '/otm/addOtmCataloguePopulation',            //新增菜单操作接口
    deleteOtmCataloguePopulation: ipUrl + '/otm/deleteOtmCataloguePopulation',     //删除菜单操作接口
    updateOtmCataloguePopulation: ipUrl + '/otm/updateOtmCataloguePopulation',     //更新菜单操作接口
    queryOtmCataloguePersonal: ipUrl + '/otm/queryOtmCataloguePersonal',           //查询个人操作菜单接口
    updateOtmCataloguePersonal: ipUrl + '/otm/updateOtmCataloguePersonal',         //更新个人操作菜单接口
    queryOtmOperationModelListName: ipUrl + '/otm/queryOtmOperationModelListName', //根据模型名字查询操作模型列表接口
    queryOtmOperationModelDetailName: ipUrl + '/otm/queryOtmOperationModelDetailName',     //根据模型ID查询OTM操作模型详情接口
    updateOtmOperationName: ipUrl + '/otm/updateOtmOperationName',                 //修改操作名字接口


    // 单款分析接口
    getSingleAnalysisData1: ipUrl + '/singleAnalysis/getSingleAnalysisData1',       // 商品基础信息和销售数据
    getSingleAnalysisData2: ipUrl + '/singleAnalysis/getSingleAnalysisData2',       // 款搭配和新老客接口
    getItemInfoList: ipUrl + '/singleAnalysis/getItemInfoList',                    // 单款分析接口
    getSingleAnalysisTopData: ipUrl + '/singleAnalysis/getTopData',                // 获取销售金额TOP10数据
    getSingleAnalysisTopOneData: ipUrl + '/singleAnalysis/getTopOneData',          // 获取销售TOP1的数据
    getMatchRanking: ipUrl + '/singleAnalysis/getMatchRanking',                    //获取搭配排行
    getNewAndOldCustomers: ipUrl + '/singleAnalysis/getNewAndOldCustomers',        //获取新老客的数据
    getColorSizeSaleDate: ipUrl + '/singleAnalysis/getColorSizeSaleDate',         //获取到色到码的销售数据
    getColorSizeSaleChartDate: ipUrl + '/singleAnalysis/getColorSizeSaleChartDate',    //获取到色到码的销售饼图数据
    getAreaPayNumChartDate: ipUrl + '/singleAnalysis/getAreaPayNumChartDate',          //获取区域,城市分类的支付件数饼图数据
    getTempePayNumChartDate: ipUrl + '/singleAnalysis/getTempePayNumChartDate',         //获取温度分区支付件数和支付金额饼图数据
    getProvincePayNumChartDate: ipUrl + '/singleAnalysis/getProvincePayNumChartDate',  //获取省份支付件数和支付金额饼图数据
    getProvinceCityDate: ipUrl + '/singleAnalysis/getProvinceCityDate',                //获取省份、城市数据
    getOldNewProvincePayNumChartDate: ipUrl + '/singleAnalysis/getOldNewProvincePayNumChartDate',  //获取新老客支付件数到省饼图数据
    getTempePayNumCityBarLineDate: ipUrl + '/singleAnalysis/getTempePayNumCityBarLineDate',        //温度、支付件数和支付金额到城市柱状图及折线图数据
    getProvinceCovidDate: ipUrl + '/singleAnalysis/getProvinceCovidDate',                  //查询

    // 流量看板
    queryPieChartSummary: ipUrl + '/trafficSummary/queryPieChartSummary',          //获取各个维度饼图汇总数据
    queryFlowTable: ipUrl + '/trafficSummary/queryFlowTable',                      //获取流量数据表格
    queryTopFlowSource: ipUrl + '/trafficSummary/queryTopFlowSource',              //获取TOP10流量来源
    queryTopFlowCommodity: ipUrl + '/trafficSummary/queryTopFlowCommodity',        //获取TOP10流量商品
    queryVisitorNumTrend: ipUrl + '/trafficSummary/queryVisitorNumTrend',          //获取访客趋势

    //运营汇总数据
    queryOperationDataProductLine: ipUrl + '/operationSummaryData/queryOperationDataProductLine',  //获取运营汇总数据产品线接口
    queryOperationDataSeason: ipUrl + '/operationSummaryData/queryOperationDataSeason',            //获取运营汇总数据季节接口
    queryOperationDataCategory: ipUrl + '/operationSummaryData/queryOperationDataCategory',         //获取运营汇总数据品类接口
    queryOperationDataTop: ipUrl + '/operationSummaryData/queryOperationDataTop',                  //获取运营汇总数据Top接口
    downloadUnmatchedGoodsId: ipUrl + '/operationSummaryData/downloadUnmatchedGoodsId',            //导出未匹配的商品id文件接口
    queryOperationDataCustom: ipUrl + '/operationSummaryData/queryOperationDataCustom',            //运营汇总数据自定义查询接口
    getOperationCommodityGoodsIdList: ipUrl + '/operationSummaryData/getCommodityGoodsIdList',      //获取对应条件的商品id
    getCommodityTopGoodsIdList: ipUrl + '/operationSummaryData/getCommodityTopGoodsIdList',        //获取TOP款的商品id
    // 人群分类
    getKMeansRmfData: ipUrl + '/kMeansRMF/getRmfData',                                             //RFM人群分类数据
    createRfmFileTask: ipUrl + '/kMeansRMF/createRfmFileTask',                                      //创建RFM人群分类数据任务

    // 标品行业数据
    getStandardHeadData: ipUrl + '/standardProductOperateKanban/getHeadData',                  //获取表头日期接口
    getViewsRelevantData: ipUrl + '/standardProductOperateKanban/getViewsRelevantData',        //获取访客数据相关接口
    getCompeteItemPayTransformRateData: ipUrl + '/standardProductOperateKanban/getCompeteItemPayTransformRateData',    //获取爆款转化率相关接口
    getCompeteItemViewsRelevantData: ipUrl + '/standardProductOperateKanban/getCompeteItemViewsRelevantData',      // 获取爆款访客数占比相关接口
    getCompeteShopPayTransformRateData: ipUrl + '/standardProductOperateKanban/getCompeteShopPayTransformRateData',    //获取店铺转化率相关接口
    getCompeteShopViewsData: ipUrl + '/standardProductOperateKanban/getCompeteShopViewsData',               //获取店铺访客数接口
    getCompeteItemViewsData: ipUrl + '/standardProductOperateKanban/getCompeteItemViewsData',               //获取爆款UV数据接口


    // 样衣管理需求
    querySampleGarmentList: ipUrl + '/supplyChainManagement/querySampleGarmentList',           //获取样衣信息列表接口
    deleteSampleGarment: ipUrl + '/supplyChainManagement/deleteSampleGarment',                    //删除样衣信息接口
    updateSampleGarment: ipUrl + '/supplyChainManagement/updateSampleGarment',                 //修改样衣信息接口
    uploadSampleGarmentExcel: ipUrl + '/supplyChainManagement/uploadSampleGarmentExcel',       //上传样衣信息Excel接口
    uploadSampleGarmentPic: ipUrl + '/supplyChainManagement/uploadSampleGarmentPic',           //上传样衣信息图片接口

    // 财务管理接口
    findSummaryTableField: ipUrl + '/financialManagement/findSummaryTableField',               //获取总账表多条件查询下拉框数据接口
    queryFinancialSummaryTable: ipUrl + '/financialManagement/querySummaryTable',              //查询总表数据接口
    modifyFinancialSummaryTable: ipUrl + '/financialManagement/modifySummaryTable',            //修改总表数据
    modifyFinancialSummaryTableList: ipUrl + '/financialManagement/modifySummaryTableList',    //修改总表列表接口
    findFinancialSubject: ipUrl + '/financialManagement/findSubject',                          //查询科目
    addFinancialSubject: ipUrl + '/financialManagement/addSubject',                            //添加科目
    modifyFinancialSubject: ipUrl + '/financialManagement/modifySubject',                      //修改科目
    findFinancialProject: ipUrl + '/financialManagement/findProject',                          //项目查询接口
    addFinancialProject: ipUrl + '/financialManagement/addProject',                           //添加项目
    modifyFinancialProject: ipUrl + '/financialManagement/modifyProject',                     //修改项目接口
    deleteFinancialProject: ipUrl + '/financialManagement/deleteProject',                     //禁用项目
    findFinancialProjectSubject: ipUrl + '/financialManagement/findProjectSubject',            //查询项目下面所有科目
    modifyFinancialProjectSubject: ipUrl + '/financialManagement/modifyProjectSubject',        //项目-科目关系表修改接口
    uploadFinancialSummaryTable: ipUrl + '/financialManagement/uploadSummaryTable',             //上传总表数据接口
    downloadFinancialSummaryTable: ipUrl + '/financialManagement/downloadSummaryTable',                  //下载总表数据
    judgeFinancialSummaryTable: ipUrl + '/financialManagement/judgeSummaryTable',                       //判断总表数据是否全部确认


    //获取商品排行表格数据
    getProductRankingTableData: ipUrl + '/productRanking/getProductRankingTableData',          //获取商品排行表格数据接口
    getProductLineData: ipUrl + '/productRanking/getProductLineData',                          // 获取折线图数据接口
    createPopularProductRecommendation: ipUrl + '/productRanking/createPopularProductRecommendation',  //新增爆款商品推荐数据接口
    queryPopularProductRecommendation: ipUrl + '/productRanking/queryPopularProductRecommendation',    //获取爆款商品推荐数据接口
    deletePopularProductRecommendation: ipUrl + '/productRanking/deletePopularProductRecommendation',   //删除爆款商品推荐数据接口


    // 供应链需求开发商品管理页面
    development_all: ipUrl + "/supplyChainManagement/all",                                      // 初始化接口
    development_save: ipUrl + "/supplyChainManagement/development_progress_details_update",    // 商品开发详情
    development_reason: ipUrl + "/supplyChainManagement/cancel",                               // 取消开发接口
    development_order: ipUrl + "/supplyChainManagement/order_details",                         // 获取下单详情页面数据接口
    development_order_insert: ipUrl + "/supplyChainManagement/order_details_insert",            // 下单详情新增接口
    development_order_update: ipUrl + "/supplyChainManagement/order_details_update",            // 下单详情修改接口
    development_uploadImage: ipUrl + "/supplyChainManagement/imageUploadPositive",            // 正面图片上传接口
    development_uploadBackImage: ipUrl + "/supplyChainManagement/imageUploadNegative",        // 背面图片上传接口
    developmentOutsideUploadImage: ipUrl + "/supplyChainManagement/uploadpicpositive",        // 外面正面图片上传接口
    developmentOutsideUploadBackImage: ipUrl + "/supplyChainManagement/uploadpicnegative",        // 外面背面图片上传接口
    development_uploadFile: ipUrl + "/supplyChainManagement/clothes_insert",                   // 上传文件接口
    development_uploadSuccess: ipUrl + "/supplyChainManagement/clothes_insert_react",          // 上传成功时返回成功接口
    development_details: ipUrl + "/supplyChainManagement/development_details",                    // 下单详情页展示接口
    development_order_brand_skc_update: ipUrl + "/supplyChainManagement/order_brand_skc_update",   // 修改品牌款号接口
    development_fabric: ipUrl + "/supplyChainManagement/fabric_insert_details",                   // 获取面料表接口
    development_inspiration: ipUrl + "/supplyChainManagement/idea_details",                      // 获取灵感表接口
    development_fabric_insert: ipUrl + "/supplyChainManagement/fabric_insert",                    // 面料新增接口
    development_inspiration_insert: ipUrl + "/supplyChainManagement/idea_insert",                 // 添加灵感和描述接口
    development_print: ipUrl + "/supplyChainManagement/print",                                    // 打印页面接口
    development_progress: ipUrl + "/supplyChainManagement/queryProgress",                         // 获取开发进度详情接口
    development_details_update: ipUrl + "/supplyChainManagement/development_details_update",      // 开发商品详情保存接口
    developmemt_back_cancel: ipUrl + "/supplyChainManagement/back_cancel",                        // 开发商品恢复接口



    // 供应链需求供应商管理页面
    supplier_all: ipUrl + "/supplyChainManagement/supplier_details",                            // 初始化接口
    supplier_update: ipUrl + "/supplyChainManagement/supplier_details_update",                  // 供应商修改接口
    supplier_insert: ipUrl + "/supplyChainManagement/supplier_details_insert",                  // 供应商新增接口



    // 供应链需求面料管理页面
    fabric_all: ipUrl + "/supplyChainManagement/fabric_details",                                // 初始化
    fabric_update: ipUrl + "/supplyChainManagement/fabric_details_update",                      // 修改面料接口
    fabric_insert: ipUrl + "/supplyChainManagement/fabric_details_insert",                      // 新增面料接口
    fabric_uploadImage: ipUrl + "/supplyChainManagement/imageUploadFabric",                    // 面料图片上传接口


    // kol媒介合作管理
    kolAll: ipUrl + "/kol/querykollist",                                                         // 初始化页面
    queryKolDetails: ipUrl + "/kol/querykolDetails",                                               // 开发进度详情界面
    uploadKolExcel: ipUrl + "/kol/uploadkolExcel",                                                 // 上传Excal接口

    // 首饰商品基础信息
    queryJewelryItemInfoField: ipUrl + '/itemInfoDW/queryItemInfoField',                           //查询首饰商品基础信息字段接口
    checkJewelryItemInfo: ipUrl + '/itemInfoDW/checkItemInfo',                                     //校验商品基础信息文件接口
    saveJewelryItemInfo: ipUrl + '/itemInfoDW/saveItemInfo',                                       // 校验并上传商品基础信息数据接口
    queryJewelryItemInfo: ipUrl + '/itemInfoDW/queryItemInfo',                                     //查询商品基础信息接口
    queryJewelryItemInfoCondition: ipUrl + '/itemInfoDW/queryItemInfoCondition',                   //查询商品基础信息条件选项接口

    // 手机短信
    findPhoneMessage: ipUrl + '/phoneMesage/findPhoneMessage',                                     //查询手机短信
    findSmsContent: ipUrl + '/phoneMesage/findSmsContent',                                        //查询短信信息

    // // 共享工单
    // createSharedWorkOrder : ipUrl + '/workOrder/createSharedWorkOrder',                             //创建共享工单接口
    // querySharedWorkOrderDetail : ipUrl + '/workOrder/querySharedWorkOrderDetail',                   //查询共享工单详情
    // updateSharedWorkOrder : ipUrl + '/workOrder/updateSharedWorkOrder',                             //修改共享工单详情接口
    //
    // // 商品企划接口
    // findSeasonCollectionTarget : ipUrl + '/commodityPlanning/findSeasonCollectionTarget',          //查询回款目标拆解接口

    // 会员消费数据分析
    getConsumerAnalysisNewOldData: ipUrl + '/memberConsumeAnalysis/getConsumerAnalysisNewOldData',   //消费者人数分析新老客接口
    getConsumerAnalysisData: ipUrl + '/memberConsumeAnalysis/getConsumerAnalysisData',               //消费者人数分析接口
    getConsumptionLevelsData: ipUrl + '/memberConsumeAnalysis/getConsumptionLevelsData',             //获取会员消费层次数据接口
    getTopSingleAnalysisData: ipUrl + '/memberConsumeAnalysis/getTopSingleAnalysisData',             //获取新老客TOP款分析数据接口
    getRegionAnalysisData: ipUrl + '/memberConsumeAnalysis/getRegionAnalysisData',                     //获取地域TOP分析数据接口
    getPreferenceCategoryAnalysisData: ipUrl + '/memberConsumeAnalysis/getPreferenceCategoryAnalysisData', //获取偏好品类分析数据接口
    downloadConsumptionFile: ipUrl + '/memberConsumeAnalysis/downloadConsumptionFile',             //导出用户名单接口

    // 设备管理
    calculateComputerSubsidyAmount: ipUrl + '/device/calculateComputerSubsidyAmount',                //计算电脑补助金额
    getPcBaseInfo: ipUrl + '/device/getPcBaseInfo',                                                  //获取电脑基础信息
    addPcSubsidy: ipUrl + '/device/addPcSubsidy',                                                    //添加电脑设备补助
    cancelDevice: ipUrl + '/device/cancelDevice',                                                   //取消添加此设备
    getDevice: ipUrl + '/device/getDevice',                                                          //获取设备信息

    // 产品共性购买分析
    queryProductItemInfoCondition: ipUrl + '/ProductCommonalityAnalysis/queryItemInfoCondition',      //获取筛选条件列表接口
    readFileSku: ipUrl + '/ProductCommonalityAnalysis/readFileSku',                                  //读取文件中商品基础信息SKU接口
    getFlowAnalysisData: ipUrl + '/ProductCommonalityAnalysis/getFlowAnalysisData',                  //获取流向分析数据接口
    downloadProductCommonalityAnalysisConsumptionFile: ipUrl + '/ProductCommonalityAnalysis/downloadConsumptionFile',          //消费者导出接口
    downloadProductCommonalityFile: ipUrl + '/ProductCommonalityAnalysis/downloadProductFile',       //产品导出
    getProductAnalysisData: ipUrl + '/ProductCommonalityAnalysis/getProductAnalysisData',            //获取产品分析数据接口

    // 商品宽表
    queryCommodityWideTableScenario: ipUrl + '/commodityTable/queryCommodityWideTableScenario',      //查询商品宽表场景接口
    queryCommodityWideTableData: ipUrl + '/commodityTable/queryCommodityWideTableData',              // 查询商品宽表数据接口
    queryFieldConditionType: ipUrl + '/commodityTable/queryFieldConditionType',                      //获取字段查询条件

    // 新的接口
    queryCommodityWideTableTemplate: ipUrl + '/commodityTable/queryCommodityWideTableTemplate',        //查询用户模板列表接口
    deleteCommodityWideTableTemplate: ipUrl + '/commodityTable/deleteCommodityWideTableTemplate',      //删除用户模板接口
    queryCommodityWideTableCache: ipUrl + '/commodityTable/queryCommodityWideTableCache',              //查询缓存列表接口
    deleteCommodityWideTableCache: ipUrl + '/commodityTable/deleteCommodityWideTableCache',            //删除缓存数据
    queryCommodityWideTableCacheCriteria: ipUrl + '/commodityTable/queryCommodityWideTableCacheCriteria',  //查询缓存条件接口
    queryCommodityWideTableTemplateCriteria: ipUrl + '/commodityTable/queryCommodityWideTableTemplateCriteria',    //查询模板条件接口
    downloadCommodityWideTableCacheData: ipUrl + '/commodityTable/downloadCommodityWideTableCacheData',    //下载缓存数据接口
    createCommodityWideTableTemplate: ipUrl + '/commodityTable/createCommodityWideTableTemplate',          //创建用户模板接口
    updateCommodityWideTableTemplatePermanent: ipUrl + '/commodityTable/updateCommodityWideTableTemplatePermanent',    //模板临时转永久接口

    // 我的数据管理
    queryDetailDataTemplate: ipUrl + '/myDataMart/queryDetailDataTemplate',                              //查询明细数据模板接口
    queryDetailDataTemplateDetails: ipUrl + '/myDataMart/queryDetailDataTemplateDetails',                //查询明细数据模板详情接口
    queryDataMartIndex: ipUrl + '/myDataMart/queryIndex',                                                //查询指标
    createDetailDataTemplate: ipUrl + '/myDataMart/createDetailDataTemplate',                            //新增明细数据模板详情接口
    deleteDetailDataTemplate: ipUrl + '/myDataMart/deleteDetailDataTemplate',                            //逻辑删除明细数据模板
    recoveryDetailDataTemplate: ipUrl + '/myDataMart/recoveryDetailDataTemplate',                        //恢复明细数据模板接口
    openDetailDataTemplate: ipUrl + '/myDataMart/openDetailDataTemplate',                                 //公开明细数据模板接口
    cancelOpenDetailDataTemplate: ipUrl + '/myDataMart/cancelOpenDetailDataTemplate',                    //取消公开明细数据模板接口
    queryDetailDataTemplateCache: ipUrl + '/myDataMart/queryDetailDataTemplateCache',                    //查询明细数据模板下缓存列表接口
    queryDetailDataTemplateCacheList: ipUrl + '/myDataMart/queryDetailDataTemplateCacheList',            //查询用户所有明细数据缓存接口

    // 分组统计
    queryDetailDataTemplateDimensionIndicators: ipUrl + '/myDataMart/queryDetailDataTemplateDimensionIndicators', //查询明细模板对应的指标和维度接口
    createAtomicTable: ipUrl + '/myDataMart/createAtomicTable',                                        //生成原子表接口
    queryAtomicTable: ipUrl + '/myDataMart/queryAtomicTable',                                         //  查询原子表接口
    queryDetailTableCondition: ipUrl + '/myDataMart/queryDetailTableCondition',                       //查询明细表条件接口
    queryDetailTable: ipUrl + '/myDataMart/queryDetailTable',                                         //查询明细表数据接口
    queryGroupStatisticsTemplate: ipUrl + '/myDataMart/queryGroupStatisticsTemplate',                 //查询个人分组统计模板接口
    queryGroupStatisticsTemplateList: ipUrl + '/myDataMart/queryGroupStatisticsTemplateList',         //查询分组统计模板列表接口
    queryGroupStatisticsTemplateDetail: ipUrl + '/myDataMart/queryGroupStatisticsTemplateDetail',      //查询分组统计模板详情接口
    queryGroupStatisticsCacheShortChain: ipUrl + '/myDataMart/queryGroupStatisticsCacheShortChain',   //查询分组统计缓存短链接口
    deleteGroupStatisticsTemplate: ipUrl + '/myDataMart/deleteGroupStatisticsTemplate',               //删除分组统计模版接口
    recoveryGroupStatisticsTemplate: ipUrl + '/myDataMart/recoveryGroupStatisticsTemplate',           //恢复分组统计模版接口
    openGroupStatisticsTemplate: ipUrl + '/myDataMart/openGroupStatisticsTemplate',                   //公开分组统计模版接口
    cancelOpenGroupStatisticsTemplate: ipUrl + '/myDataMart/cancelOpenGroupStatisticsTemplate',       //不公开分组统计模版接口
    checkDetailDataCache: ipUrl + '/myDataMart/checkDetailDataCache',                                 //判断是否存在明细数据缓存
    createDetailDataCache: ipUrl + '/myDataMart/createDetailDataCache',                               //生成明细数据缓存数据
    queryGroupStatisticsCacheList: ipUrl + '/myDataMart/queryGroupStatisticsCacheList',               //查询个人分组统计缓存列表接口
    deleteGroupStatisticsCache: ipUrl + '/myDataMart/deleteGroupStatisticsCache',                     //删除分组统计缓存接口
    downloadGroupStatisticsCacheData: ipUrl + '/myDataMart/downloadGroupStatisticsCacheData',         //下载分组统计明细数据接口
    queryGroupStatisticsTemplateCacheList: ipUrl + '/myDataMart/queryGroupStatisticsTemplateCacheList',    //查询分组统计模板下分组统计缓存列表接口
    checkDataTimeInterval: ipUrl + '/myDataMart/checkTimeInterval',                                    //检查时间间隔接口
    createDataComparisonRecord: ipUrl + '/myDataMart/createComparisonRecord',                              //创建对比记录接口
    queryDataComparisonRecord: ipUrl + '/myDataMart/queryComparisonRecord',                            //查询个人对比记录接口
    queryDetailDataComparisonRecordCache: ipUrl + '/myDataMart/queryDetailDataComparisonRecordCache',                      //查询对比记录缓存接口
    createGroupStatisticsComparisonRecord: ipUrl + '/myDataMart/createGroupStatisticsComparisonRecord',        //创建分组统计对比记录接口
    queryGroupStatisticsTemplateName: ipUrl + '/myDataMart/queryGroupStatisticsTemplateName',              //查询分组统计模板名字接口
    queryGroupStatisticsComparisonRecordCache: ipUrl + '/myDataMart/queryGroupStatisticsComparisonRecordCache',    //查询分组对比对比记录缓存接口
    queryGroupStatisticsComparisonRecordCacheRisingSunChart: ipUrl + '/myDataMart/queryGroupStatisticsComparisonRecordCacheRisingSunChart',    //分组统计查看旭日图
    topComparisonRecord: ipUrl + '/myDataMart/topComparisonRecord',                                        //置顶对比记录接口
    cancelComparisonRecord: ipUrl + '/myDataMart/cancelComparisonRecord',                                  //取消置顶对比记录接口
    deleteComparisonRecord: ipUrl + '/myDataMart/deleteComparisonRecord',                                  //删除对比记录接口
    recoveryComparisonRecord: ipUrl + '/myDataMart/recoveryComparisonRecord',                              //恢复对比记录接口

    // 选款定价
    querySelectionPricingSchemeList: ipUrl + '/selectionPricing/querySelectionPricingSchemeList',            //查询选款定价方案列表接口
    queryActivityLabel: ipUrl + '/selectionPricing/queryActivityLabel',                                     //查询活动标签接口
    createSelectionPricingScheme: ipUrl + '/selectionPricing/createSelectionPricingScheme',                 //创建选款定价方案接口
    queryFitTargetValue: ipUrl + '/selectionPricing/queryFitTargetValue',                                   //查询下拉菜单列表接口
    queryTblCouponsList: ipUrl + '/selectionPricing/queryTblCouponsList',                                   //查询店铺下拉菜单接口
    createActivityInterestPoint: ipUrl + '/selectionPricing/createActivityInterestPoint',                      //新增活动玩法利益点接口
    merchandiseValuation: ipUrl + '/selectionPricing/merchandiseValuation',                                    //商品定价接口
    querySpecialAreaList: ipUrl + '/selectionPricing/querySpecialAreaList',                                    //查询默认专区列表接口
    queryCommodityTagList: ipUrl + '/selectionPricing/queryCommodityTagList',                                  //查询商品标签列表
    getSummaryRang: ipUrl + '/selectionPricing/getSummaryRange',                                                //查询汇总范围接口
    getAllShopSummary: ipUrl + '/selectionPricing/getAllShopSummary',                                          //查询全店数据汇总数据接口
    getCategorySummary: ipUrl + '/selectionPricing/getCategorySummary',                                        //查询品类数据汇总数据接口
    downloadSelectionPricingResult: ipUrl + '/selectionPricing/downloadSelectionPricingResult',                //导出选款定价最终结果接口
    summaryCommodityData: ipUrl + '/selectionPricing/summaryCommodityData',                                    //计算保存汇总数据接口
    queryHeatmapData: ipUrl + '/selectionPricing/queryHeatmapData',                                            //统计热力图
    getCategoryPriceSummary: ipUrl + '/selectionPricing/getCategoryPriceSummary',                              //品类价格汇总
    getCategoryPriceDetail: ipUrl + '/selectionPricing/getCategoryPriceDetail',                                //获取品类价格带详情数据
    downloadSelectionPricingResultDetail: ipUrl + '/selectionPricing/downloadSelectionPricingResultDetail',    //导出选款定价最终结果
    querySelectionPricingLog: ipUrl + '/selectionPricing/querySelectionPricingLog',                            //查询选款定价日志接口
    queryOptionJson: ipUrl + '/selectionPricing/queryOptionJson',                                              //查询利益点接口
    queryOptionPricingCommodityList: ipUrl + '/selectionPricing/queryOptionPricingCommodityList',              //查询选款定价商品列表
    updateBatchPriceInterface: ipUrl + '/selectionPricing/updateBatchPriceInterface',                          //批量修改定价方法
    // 三只小宠
    addSummary: ipUrl + '/threePets/add_summary',     //供应商基础信息新增接口
    findSummary: ipUrl + '/threePets/find_summary',   //供应商基础信息查询接口
    modifiedSummary: ipUrl + '/threePets/modified_summary',   //供应商基础信息修改接口
    delectSummary: ipUrl + '/threePets/delect_summary',   //供应商基础删除接口
    findQuotation: ipUrl + '/threePets/find_quotation',   //报单表查询接口
    uploadQuotation: ipUrl + '/threePets/upload_quotation',   //报单表上传接口
    downloadQuotation: ipUrl + '/threePets/download_quotation',   //报单表下载接口
    delectQuotation: ipUrl + '/threePets/delect_quotation',   //报单表下载接口
    findOppositePersongys: ipUrl + '/threePets/find_opposite_person_gys',  //供应商对接人详情查找接口
    addOppositePersdongys: ipUrl + '/threePets/add_opposite_person_gys',   //供应商对接人详情新增接口
    delectOppositePersongys: ipUrl + '/threePets/delect_opposite_person_gys',    //供应商对接人详情删除接口
    modifiedOppositePersongys: ipUrl + '/threePets/modified_opposite_person_gys',    //供应商对接人详情修改接口
    mainOppositePersongys: ipUrl + '/threePets/main_opposite_person_gys',  //供应商主对接人设定接口
    findFactory: ipUrl + '/threePets/find_factory',   //工厂详情查找接口
    addFactory: ipUrl + '/threePets/add_factory',   //工厂详情新增接口
    delectFactory: ipUrl + '/threePets/delect_factory',  //工厂详情删除接口
    modifiedFactory: ipUrl + '/threePets/modified_factory',  //工厂详情修改接口
    findFactoryFile: ipUrl + '/threePets/find_factoryfile',   //工厂文件查询接口
    uploadFactoryFile: ipUrl + '/threePets/upload_factoryfile',  //工厂文件上传接口
    downloadFactoryFile: ipUrl + '/threePets/download_factoryfile',  //工厂文件下载接口
    delectFactoryFile: ipUrl + '/threePets/delect_factoryfile',   //工厂文件 下载接口
    findOppositePerson: ipUrl + '/threePets/find_opposite_person',  //工厂对接人详情查找接口
    addOppositePersdon: ipUrl + '/threePets/add_opposite_person',   //工厂对接人详情新增接口
    delectOppositePerson: ipUrl + '/threePets/delect_opposite_person',    //工厂对接人详情删除接口
    modifiedOppositePerson: ipUrl + '/threePets/modified_opposite_person',    //工厂对接人详情修改接口
    mainOppositePerson: ipUrl + '/threePets/main_opposite_person',  //工厂主对接人设定接口
    searchOrder: ipUrl + '/threePets/search_order',   //订单详情搜索接口
    uploadOrder: ipUrl + '/threePets/upload_order',    //订单详情上传接口
    downloadOrder: ipUrl + '/threePets/download_order',    //订单详情下载接口
    modifiedorderback: ipUrl + '/threePets/modified_order_back',    //订单详情状态修改接口
    findCommodity: ipUrl + '/threePets/find_commodity',    //商品详情查找接口
    delectCommodity: ipUrl + '/threePets/delect_commodity',    //商品详情删除接口
    uploadCommodity: ipUrl + '/threePets/upload_commodity',    //商品详情上传接口
    downloadCommodity: ipUrl + '/threePets/download_commodity',    //商品详情下载接口
    recoveryCommodity: ipUrl + '/threePets/recovery_commodity',   //商品详情恢复接口
    deactivateCommodity: ipUrl + '/threePets/deactivate_commodity',    //商品详情停用接口
    modified_commodity: ipUrl + '/threePets/modified_commodity',      // 商品详情修改后上传接口
    findTrue: ipUrl + '/threePets/find_true',    //返回true
    uploadOppositePersonGys: ipUrl + '/threePets/uploadOppositePersonGys', //上传供应商信息
    addProblem: ipUrl + '/threePets/addProblem', //上传质量问题
    modifiedProblem: ipUrl + '/threePets/modifiedProblem', //修改质量问题

    // 半餐
    queryHalfMealAllDessert: ipUrl + '/halfMeal/queryAllDessert',          //查询所有点心
    addHalfMealDessert: ipUrl + '/halfMeal/addDessert',                     //添加点心
    updateHalfMealDessert: ipUrl + '/halfMeal/updateDessert',              //修改点心
    deleteHalfMealDessert: ipUrl + '/halfMeal/deleteDessert',              //移除点心
    releaseHalfMealPlan: ipUrl + '/halfMeal/releasePlan',                  //发布计划
    queryHalfMealPlan: ipUrl + '/halfMeal/queryPlan',                       //查询发布计划
    queryHalfMealPlanDessert: ipUrl + '/halfMeal/queryPlanDessert',        //查询计划下面的点心
    deleteHalfMealPlan: ipUrl + '/halfMeal/deletePlan',                    //取消发布
    updateHalfMealPlan: ipUrl + '/halfMeal/updatePlan',                    //修改发布
    queryHalfMealPlanPeople: ipUrl + '/halfMeal/queryPlanPeople',                  //查询计划下面的所有人员
    downloadHalfMealPlanPeople: ipUrl + '/halfMeal/downloadPlanPeople',            //下载人员信息
    queryHalfMealTodayDessert: ipUrl + '/halfMeal/queryTodayDessert',              //获取今日点心
    queryHalfMealTakeCode: ipUrl + '/halfMeal/queryTakeCode',                      //获取取餐码
    signUpHalfMeal: ipUrl + '/halfMeal/signUp',                            //报名
    cancelSignUpHalfMeal: ipUrl + '/halfMeal/cancelSignUp',                //取消报名
    queryHalfMealRemainingCount: ipUrl + '/halfMeal/queryRemainingCount',          //查询剩余次数

    // 经营分析维度
    getCommodityOperationTableFieldData: ipUrl + '/commodityOperationTable/getFieldData',  //获取维度数据接口
    getCommodityOperationTableSalesData: ipUrl + '/commodityOperationTable/getSalesData',  //获取销售数据接口
    getCommodityOperationTablePreheatData: ipUrl + '/commodityOperationTable/getPreheatData',  //获取预热数据接口
    downloadCommodityOperationTableSalesData: ipUrl + '/commodityOperationTable/downloadSalesData',  //下载销售数据接口
    downloadCommodityOperationTablePreheatData: ipUrl + '/commodityOperationTable/downloadPreheatData',  //下载热数据接口
    getCommodityOperationTableDfOrder: ipUrl + '/commodityOperationTable/getDfOrder',                        //数据排序接口

    // 抽奖
    queryAnniversaryLuckDrawPrize: ipUrl + '/anniversaryLuckDraw/queryPrize',         //筛选奖品接口
    updateAnniversaryLuckDrawPrize: ipUrl + '/anniversaryLuckDraw/updatePrize',       //记录奖品接口
    queryAnniversaryLuckDrawPrizeCode: ipUrl + '/anniversaryLuckDraw/queryPrizeCode',  //校验抽奖码接口
    anniversaryLuckDrawSignIn: ipUrl + '/anniversaryLuckDraw/signIn',   //签到
    queryAnniversaryLuckDrawSignIn: ipUrl + '/anniversaryLuckDraw/querySignIn',            //查询签到人数

    // 订单日常分析
    queryOrderAnalysisOrderProvince: ipUrl + '/orderAnalysis/queryOrderProvince',     //获取省份汇总数据接口
    queryOrderAnalysisOrderCity: ipUrl + '/orderAnalysis/queryOrderCity',             //获取城市汇总数据接口
    queryOrderAnalysisOrderCitylevel: ipUrl + '/orderAnalysis/queryOrderCitylevel',   //获取城市等级汇总数据接口
    queryOrderAnalysisCountOfOldCustomer: ipUrl + '/orderAnalysis/queryCountOfOldCustomer',         //获取订单对应时间内老客人数接口
    queryOrderAnalysisCountOfNewCustomer: ipUrl + '/orderAnalysis/queryCountOfNewCustomer',         //获取订单对应时间内新客人数接口
    queryOrderAnalysisCountOfCustomer: ipUrl + '/orderAnalysis/queryCountOfCustomer',         //获取订单对应时间内总客人数接口
    queryOrderAnalysisOrderSpu: ipUrl + '/orderAnalysis/queryOrderSpu',   //获取商品汇总数据接口
    queryOrderAnalysisOrderCategory2: ipUrl + '/orderAnalysis/queryOrderCategory2',         //获取品类汇总数据接口
    downloadOrderDataFile: ipUrl + '/orderAnalysis/downloadOrderDataFile',     //下载数据接口
    downloadOrderAnalysisOrderCity: ipUrl + '/orderAnalysis/downloadOrderCity',             //下载市级数据

    // 流量数据汇总页面下载
    downloadTrafficSummaryFile: ipUrl + '/trafficSummary/downloadTrafficSummaryFile',    //流量数据汇总页面下载

    // 人群资产页面,会员报表
    getCrowdAssetsData: ipUrl + '/crowdAssets/getCrowdAssetsData',                 //获取人群资产数据接口
    getCrowdAssetsContrastData: ipUrl + '/crowdAssets/getCrowdAssetsContrastData', //获取人群资产对比数据接口
    getCrowdAssetsRecentData: ipUrl + '/crowdAssets/getCrowdAssetsRecentData',     //获取人群资产进15天数据接口
    getFansData: ipUrl + '/memberReport/getFansData',                   //获取日的数据
    getFansDataForWeek: ipUrl + '/memberReport/getFansWeeklyData',                 //获取周的数据
    getFansDataForMonth: ipUrl + '/memberReport/getFansMonthlyData',               //获取月的数据
    getMemberData: ipUrl + '/memberReport/getMemberData',                          //获取会员数据接口
    getInteractionData: ipUrl + '/memberReport/getInteractionData',                //获取积分互动数据接口
    getInteractionDataForWeek: ipUrl + '/crowdAssets/getInteractionDataForWeek',   //获取积分互动周数据接口
    getInteractionDataForMonth: ipUrl + '/crowdAssets/getInteractionDataForMonth', //获取积分互动月数据接口
    getSummaryTableData: ipUrl + '/memberReport/getSummaryTableData',   //获取汇总表格日数据接口
    getSummaryTableDataForWeek: ipUrl + '/crowdAssets/getSummaryTableDataForWeek',   //获取汇总表格周数据接口
    getSummaryTableDataForMonth: ipUrl + '/crowdAssets/getSummaryTableDataForMonth',   //获取汇总表格月数据接口
    downloadSummaryTableData: ipUrl + '/memberReport/downloadSummaryTableData',         //下载数据
    getCrowdAssetsRecentRateData: ipUrl + '/crowdAssets/getCrowdAssetsRecentRateData', //获取人群资产近15天增长率趋势数据接口.
    downloadCrowdAssetsData: ipUrl + '/crowdAssets/downloadCrowdAssetsData',           //下载人群资产数据接口.

    // 爆款单品诊断
    getExplosiveModelsMenuList: ipUrl + '/explosiveModels/getMenuList',               //查询下拉菜单列表接口
    getSingleProductModelList: ipUrl + '/explosiveModels/getSingleProductModelList',     //查询模型列表接口
    getModelComparisonLineChartData: ipUrl + '/explosiveModels/getModelComparisonLineChartData',   //获取折线图
    getCommodityMenuList: ipUrl + '/explosiveModels/getCommodityMenuList',              //查询商品下拉菜单列表接口
    getExplosiveModelsCommodityList: ipUrl + '/explosiveModels/getCommodityList',      //查询新品列表接口

    // 竞品新品追踪
    searchCompetitorTrackingBasic: ipUrl + '/competitorTracking/searchBasic',          //查询基础信息
    keepCompetitorTrackingBasic: ipUrl + '/competitorTracking/keepBasic',              //保存字段
    readCompetitorTrackingBasic: ipUrl + '/competitorTracking/readBasic',              //读取字段
    searchCompetitorTrackingProducts: ipUrl + '/competitorTracking/searchProducts',     //查询商品
    downloadCompetitorTrackingProduct: ipUrl + '/competitorTracking/downloadProduct',                      //下载竞品新品数据
    sortCompetitorTrackingProducts: ipUrl + '/competitorTracking/sortProducts',        //排序

    // 女装周报
    getOperationalContrastData: ipUrl + '/weeklyDataReport/getOperationalContrastData',    //获取运营对比数据接口
    getOperationalDataDetail: ipUrl + '/weeklyDataReport/getOperationalDataDetail',        //获取运营数据接口
    getOperationalData: ipUrl + '/weeklyDataReport/getOperationalData',                    //  查询漏斗图数据
    getWeeklyDataReportFlowData: ipUrl + '/weeklyDataReport/getFlowData',                  //获取流量数据接口
    getWeeklyDataReportFlowTop: ipUrl + '/weeklyDataReport/getFlowTop',                    //获取流量排名数据接口
    getWeeklyDataReportOperationalCommodityTop: ipUrl + '/weeklyDataReport/getOperationalCommodityTop',    //获取商品排名数据接口
    getOperationalCommoditySummary: ipUrl + '/weeklyDataReport/getOperationalCommoditySummary',        //获取商品汇总数据接口
    getOperationalProductLineYearSummary: ipUrl + '/weeklyDataReport/getOperationalProductLineYearSummary',    //获取商品产品线年份汇总数据接口
    getOperationalSeasonSummary: ipUrl + '/weeklyDataReport/getOperationalSeasonSummary',              //获取商品季节汇总数据接口
    getOperationalCategorySummary: ipUrl + '/weeklyDataReport/getOperationalCategorySummary',          //获取商品品类汇总数据接口
    getOperationalTopSummary: ipUrl + '/weeklyDataReport/getOperationalTopSummary',                    //获取商品top款汇总数据接口
    updateWeeklyReportConclusion: ipUrl + '/weeklyDataReport/updateWeeklyReportConclusion',        //修改周报结论
    getWeeklyReportConclusion: ipUrl + '/weeklyDataReport/getWeeklyReportConclusion',              //查询周报结论
    getOperationalProductLineYearSummaryOperation: ipUrl + '/weeklyDataReport/getOperationalProductLineYearSummaryOperation',  //获取运营产品线年份汇总数据接口
    getOperationalSeasonSummaryOperation: ipUrl + '/weeklyDataReport/getOperationalSeasonSummaryOperation',    //获取运营季节汇总数据接口
    getOperationalCategorySummaryOperation: ipUrl + '/weeklyDataReport/getOperationalCategorySummaryOperation',    //获取运营品类汇总数据接口
    getOperationalTopSummaryOperation: ipUrl + '/weeklyDataReport/getOperationalTopSummaryOperation',      //获取运营top款汇总数据接口.


    // 商品日报
    searchCommodityBasic: ipUrl + '/commodityDailyReport/searchBasic',                                   //查询搜索指标
    getCategoryAdditionalPurchase: ipUrl + '/commodityDailyReport/getCategoryAdditionalPurchase',               //按品类获取每日加购数据接口
    getCategoryAdditionalYear: ipUrl + '/commodityDailyReport/getCategoryAdditionalYear',                  //按品类获取年加购数据
    getCategoryAdditionalTop: ipUrl + '/commodityDailyReport/getCategoryAdditionalTop',                    //获取日加购数据top接口
    cumulativeAdditionalYear: ipUrl + '/commodityDailyReport/cumulativeAdditionalYear',                    //按品类获取年累计加购数据及同比
    cumulativePayYear: ipUrl + '/commodityDailyReport/cumulativePayYear',                                  //按品类获取年累计销售数据及同比

    // 商品企划竞品页面
    competitiveAll: ipUrl + '/commodityPlanning/competitiveAll',           // 竞品初始化接口
    competitiveAnalysis: ipUrl + '/commodityPlanning/competitiveAnalysis',       // 竞品分析表查询接口
    categoryComparisonTop: ipUrl + '/commodityPlanning/categoryComparisonTop',     // 竞品TOP品类比较表查询接口
    strongBrandPriceBand: ipUrl + '/commodityPlanning/strongBrandPriceBand',        // 强势品类价格带查询接口
    competitiveTop: ipUrl + '/commodityPlanning/competitiveTop',              // 竞品Top分析表查询接口
    downloadCompetitiveAnalysis: ipUrl + '/commodityPlanning/downloadCompetitiveAnalysis',              // 竞品分析表下载接口
    downloadCategoryComparisonTop: ipUrl + '/commodityPlanning/downloadCategoryComparisonTop',              // 竞品店铺销售TOP品类比较表下载接口
    downloadStrongBrandPriceBand: ipUrl + '/commodityPlanning/downloadStrongBrandPriceBand',              // 核心品类核心价格带强势品牌分析表下载接口
    downloadCompetitiveTop: ipUrl + '/commodityPlanning/downloadCompetitiveTop',              // 竞品Top分析表下载接口
    coreCategory: ipUrl + '/commodityPlanning/coreCategory',               // 核心品类热词趋势表查询接口
    downloadCoreCategory: ipUrl + '/commodityPlanning/downloadCoreCategory',               // 核心品类热词趋势表下载接口
    industryAll: ipUrl + '/commodityPlanning/industryAll',               // 行业板块初始化接口
    industryTop: ipUrl + '/commodityPlanning/industryTop',               // 行业Top分析表查询接口
    downloadIndustryTop: ipUrl + '/commodityPlanning/downloadIndustryTop',               // 行业TOP分析表下载接口
    industryCategory: ipUrl + '/commodityPlanning/industryCategory',               // 行业品类趋势表查询接口
    downloadIndustryCategory: ipUrl + '/commodityPlanning/downloadIndustryCategory',               // 行业品类趋势表下载接口

    // 入职工单页面
    searchWorkOrderEmployment: ipUrl + '/workOrder/searchWorkOrderEmployment',       // 入职门禁直接领导审批查询接口
    handleWorkOrderControlEmployment: ipUrl + '/workOrder/handleWorkOrderControlEmployment',      // 入职门禁直接领导审批处理接口
    searchWorkOrderControlEmployment: ipUrl + '/workOrder/searchWorkOrderControlEmployment',       // 入职门禁铎哥审批查询接口
    finalWorkOrderEmployment: ipUrl + '/workOrder/finalWorkOrderEmployment',       // 入职门禁铎哥处理接口
    searchWorkOrderComputerEmployment: ipUrl + '/workOrder/searchWorkOrderComputerEmployment',       // 入职电脑HRBP查询接口
    handleWorkOrderComputerEmployment: ipUrl + '/workOrder/handleWorkOrderComputerEmployment',       // 入职电脑HRBP处理接口
    handleUseComputerEmployment: ipUrl + '/workOrder/handleUseComputerEmployment',       // 入职电脑行政处理接口
    searchWorkOrderComputerAll: ipUrl + '/workOrder/searchWorkOrderComputerAll',       // 入职电脑完成后内容查询接口
    handleWorkOrderGreenEmployment: ipUrl + '/workOrder/handleWorkOrderGreenEmployment',       // 入职绿码HPBP处理接口
    searchWorkOrderGreenEmployment: ipUrl + '/workOrder/searchWorkOrderGreenEmployment',       // 入职绿码行政查询接口
    handleGreenEmployment: ipUrl + '/workOrder/handleGreenEmployment',       // 入职绿码行政处理接口
    sendShortMessage: ipUrl + '/workOrder/sendShortMessage',       // 短信登录发送验证码接口
    searchShortMessage: ipUrl + '/workOrder/searchShortMessage',       // 短信登录验证接口
    handleShortMessageEmployment: ipUrl + '/workOrder/handleShortMessageEmployment',       // 两码一证短信上传接口
    handleWorkOrderShareEmployment: ipUrl + '/workOrder/handleWorkOrderShareEmployment',       // 共享工单直接领导上传接口
    searchWorkOrderShareEmployment: ipUrl + '/workOrder/searchWorkOrderShareEmployment',       // 共享工单铎哥审批查询接口
    handleWorkOrderAccountPasswordEmployment: ipUrl + '/workOrder/handleWorkOrderAccountPasswordEmployment',       // 共享工单铎哥审批处理接口
    searchWorkOrderShareAllEmployment: ipUrl + '/workOrder/searchWorkOrderShareAllEmployment',       // 共享工单内容查看接口
    handleWorkOrderErpEmployment: ipUrl + '/workOrder/handleWorkOrderErpEmployment',       // ERP工单直接领导上传接口
    searchWorkOrderErpEmployment: ipUrl + '/workOrder/searchWorkOrderErpEmployment',       // ERP工单铎哥审批查询接口
    searchWorkOrderErpAllEmployment: ipUrl + '/workOrder/searchWorkOrderErpAllEmployment',       // ERP工单内容查询接口
    handleWorkOrderPhoneEmployment: ipUrl + '/workOrder/handleWorkOrderPhoneEmployment',       // 手机工单HRBP审批接口
    rejectOAWork: ipUrl + '/workOrder/rejectOAWork',                                        //拒绝OA系统创建工单

    // 商品Top榜
    getCommodityRankFieldData: ipUrl + "/commodityRankContrast/getFieldData",                //获取维度
    getCommodityRankData: ipUrl + '/commodityRankContrast/getCommodityRankData',             //查询商品TOP数据

    // 抖音直播排期计划页面
    uploadTarget: ipUrl + '/tiktokLiveBroadcast/uploadTarget',       // 抖音直播排期Excle上传接口
    tiktokExcel: ipUrl + '/tiktokLiveBroadcast/tiktokExcel',       // 抖音直播排期Excle上传接口
    tiktokTargetSearch: ipUrl + '/tiktokLiveBroadcast/searchTarget',       // 抖音直播排期查询接口
    modifyTarget: ipUrl + '/tiktokLiveBroadcast/modifyTarget',       // 抖音直播排期修改接口
    uploadDetailsFile: ipUrl + '/tiktokLiveBroadcast/uploadDetailsFile',   // 抖音日报上传接口
    tiktokSearch: ipUrl + '/tiktokLiveBroadcast/tiktokSearch',   // 抖音日报查询接口
    liveBroadcastDetailsQuery: ipUrl + '/tiktokLiveBroadcast/liveBroadcastDetailsQuery',   // 抖音日报详情查询接口
    searchFansMoneyMonth: ipUrl + "/tiktokLiveBroadcast/searchFansMoneyMonth",  // 查询月粉丝新增和金额接口
    searchCumulativeFansMoneyMonth: ipUrl + "/tiktokLiveBroadcast/searchCumulativeFansMoneyMonth",  // 查询月累积粉丝新增和金额接口
    searchLiveDuration: ipUrl + "/tiktokLiveBroadcast/searchLiveDuration",  // 查询直播饼图数据
    searchVariousPercentages: ipUrl + "/tiktokLiveBroadcast/searchVariousPercentages",  // 查询直播折线图数据
    addTarget: ipUrl + '/tiktokLiveBroadcast/addTarget',                   //添加抖音目标
    getTiktokLiveLineChartData: ipUrl + '/tiktokLiveBroadcast/getTiktokLiveLineChartData',                   //查询直播详情折线图数据接口
    uploadLiveCostFile: ipUrl + '/tiktokLiveBroadcast/uploadLiveCostFile',                   //上传直播费用文件接口
    liveBroadcastDetailsUpdate: ipUrl + '/tiktokLiveBroadcast/liveBroadcastDetailsUpdate',                   // 修改直播详情数据
    // 抖音商品日报
    uploadTiktokCommodityDetailFile: ipUrl + '/tiktokCommodityDaily/uploadTiktokCommodityDetailFile',         //上传抖音商品数据.
    getTiktokSummaryData: ipUrl + '/tiktokCommodityDaily/getSummaryData',                                                         //获取汇总数据接口
    getSummaryOfYearData: ipUrl + '/tiktokCommodityDaily/getSummaryOfYearData',                                            //获取年份数据
    getSummaryOfSeasonData: ipUrl + '/tiktokCommodityDaily/getSummaryOfSeasonData',                                         //获取季节汇总数据接口
    getSummaryOfCategoryData: ipUrl + '/tiktokCommodityDaily/getSummaryOfCategoryData',                                    //获取品类汇总数据接口
    getSummaryOfPriceData: ipUrl + '/tiktokCommodityDaily/getSummaryOfPriceData',                                          //获取价格带汇总数据接口
    getTiktokCommodityOperationalData: ipUrl + '/tiktokCommodityDaily/getTiktokCommodityOperationalData',                  //获取商品信息数据接口
    getSummaryOfTopData: ipUrl + '/tiktokCommodityDaily/getSummaryOfTopData',                                              //获取支付件数TOP汇总数据接口
    // 抖音商品单款分析
    getCommodityDetailData: ipUrl + '/tiktokCommoditySingleAnalysis/getCommodityDetailData',                               //获取单款商品基础信息
    getCommodityRecentLiveData: ipUrl + '/tiktokCommoditySingleAnalysis/getCommodityRecentLiveData',                      //查询近期直播数据接口.

    // // 商品基础信息
    queryGoodsManager: ipUrl + '/goodsManager/goods',                                                                       //初始化商品列表
    searchCategoryByCategoryLevel: ipUrl + '/goodsManager/searchCategoryByCategoryLevel',                                   //查询品类下拉框
    searchPropertyModelByCategory: ipUrl + '/goodsManager/searchPropertyModelByCategory',                                   //查询属性模板
    uploadGoodsExcel: ipUrl + '/goodsManager/uploadGoodsExcel',                                                             // 文件导入商品列表
    allowUploadFile: ipUrl + '/goodsManager/allowUploadFile',                                                               // 上传文件正确反馈
    searchPropertyValueByInfo: ipUrl + '/goodsManager/searchPropertyValueByInfo',                                            // 查询属性下拉取值
    addNewGoods: ipUrl + '/goodsManager/submitOneNewGoods',                                                                  // 单款录入添加商品
    searchGoodsByKeysOrTags: ipUrl + '/goodsManager/searchGoodsByKeysOrTags',                                                // 查询商品列表
    getAllGoodsTags: ipUrl + '/goodsManager/getAllGoodsTags',                                                                 // 查询所有商品标签
    alterSelectedField: ipUrl + '/goodsManager/alterSelectedField',                                                           // 批量操作-批量修改
    alterUploadTime: ipUrl + '/goodsManager/alterUploadTime',                                                                 // 批量操作-修改上架时间
    setTagsForGoods: ipUrl + '/goodsManager/setTagsForGoods',                                                                 // 为商品设置标签
    addGoodsTags: ipUrl + '/goodsManager/addGoodsTags',                                                                       // 编辑标签
    deleteGoodsByKeys: ipUrl + '/goodsManager/deleteGoodsByKeys',                                                             // 删除商品
    searchCategoryByInfo: ipUrl + '/goodsManager/searchCategoryByInfo',                                                       // 查询品类列表
    submitEditCategory: ipUrl + '/goodsManager/submitEditCategory',                                                           // 编辑品类提交
    deleteCategoryByKeys: ipUrl + '/goodsManager/deleteCategoryByKeys',                                                       // 删除品类确认
    delRelationCategoryByKeys: ipUrl + '/goodsManager/delRelationCategoryByKeys',                                             // 删除级联品类
    addNewCategory: ipUrl + '/goodsManager/addNewCategory',                                                                   // 新增品类
    initStandardizedModelList: ipUrl + '/goodsManager/initStandardizedModelList',                                             // 初始化规格模型
    searchStandardizedModelByInfo: ipUrl + '/goodsManager/searchStandardizedModelByInfo',                                     // 筛选规格模型
    searchPropertyGroupNameBySN: ipUrl + '/goodsManager/searchPropertyGroupNameBySN',                                         // 查询规格模型分组名称
    searchPropertyByGroupName: ipUrl + '/goodsManager/searchPropertyByGroupName',                                             // 查询规格模型分组属性项
    addNewPropertyForStandModel: ipUrl + '/goodsManager/addNewPropertyForStandModel',                                         // 添加属性
    delPropertyGroupByGroupName: ipUrl + '/goodsManager/delPropertyGroupByGroupName',                                         // 删除分组
    delPropertyInGroupByPropertyName: ipUrl + '/goodsManager/delPropertyInGroupByPropertyName',                               // 删除属性
    downloadStandardizedModel: ipUrl + '/goodsManager/downloadStandardizedModel',                                             // 导出规格模型
    initHighSearchForGoods: ipUrl + '/goodsManager/initHighSearchForGoods',                                                   // 初始化高级筛选
    searchGoodsByInfo: ipUrl + '/goodsManager/searchGoodsByInfo',                                                             // 高级筛选提交
    downloadUploadGoodsExcelTemplate: ipUrl + '/goodsManager/downloadUploadGoodsExcelTemplate',                               // 下载商品文件导入模版
    initOneGoodsDetail: ipUrl + '/goodsManager/initOneGoodsDetail',                                                           // 初始化商品详情
    alterOneGoodsDetail: ipUrl + '/goodsManager/alterOneGoodsDetail',                                                         // 单款详情修改
    getCategoryByChart: ipUrl + '/goodsManager/getCategoryByChart',                                                           // 查看关系图
    downloadSetGoodsTagsExcelTemplate: ipUrl + '/goodsManager/downloadSetGoodsTagsExcelTemplate',                             // 文件设定标签下载模版
    setTagsForGoodsByFile: ipUrl + '/goodsManager/setTagsForGoodsByFile',                                                     // 文件设定标签上传
    getCategoryByTable: ipUrl + '/goodsManager/getCategoryByTable',                                                           // 查看关系表
    downloadGoodsByFile: ipUrl + '/goodsManager/downloadGoodsByFile',                                                         // 导出全部商品
    batchModifyVariableName: ipUrl + '/goodsManager/batchModifyVariableName',                                                 // 查询可供批量修改的字段
    judgeSearchMethod: ipUrl + '/goodsManager/judgeSearchMethod',                                                             // 查询批量下拉框取值

    // 圈选人群
    getItemInfoCircleSelectionConditions: ipUrl + '/circleCrowd/getItemInfoCircleSelectionConditions',                     //获取基础信息圈选条件接口
    getOrderCircleSelectionConditions: ipUrl + '/circleCrowd/getOrderCircleSelectionConditions',                           //获取订单圈选条件接口
    getOrderFieldCircleSelectionConditions: ipUrl + '/circleCrowd/getOrderFieldCircleSelectionConditions',                 //获取订单圈选条件接口(每个字段)
    getPeopleNumber: ipUrl + '/circleCrowd/getPeopleNumber',                                                               //计算人数接口
    getMaxOrderPayTime: ipUrl + '/circleCrowd/getMaxOrderPayTime',                                                         //订单最新数据日期查询接口.
    downloadPhoneNumber: ipUrl + '/circleCrowd/downloadPhoneNumber',                                                        //下载手机号详情
    getCircleCrowdGoods: ipUrl + '/circleCrowd/getGoods',                                                                  //人群圈选商品
    uploadPhoneNumber: ipUrl + '/circleCrowd/uploadPhoneNumber',                                                           //读取手机号详情


    // 选款定价2.0
    initializationPlan : ipUrl + '/chooseAndPricing/initializationPlan',                                                    //首页-初始化选款定价计划
    chooseAndPricingPlanFirst: ipUrl + '/chooseAndPricing/chooseAndPricingPlanFirst',                                       //首页-查询选款定价活动计划
    searchNewPlanInActivity : ipUrl + '/chooseAndPricing/searchNewPlanInActivity',                                          //首页-查询最新计划对应活动
    initializeAssociatedActivities : ipUrl + '/chooseAndPricing/initializeAssociatedActivities',                            //首页-初始化关联活动下拉框内容
    searchGoodsFilterLabelAndCondition : ipUrl + '/chooseAndPricing/searchGoodsFilterLabelAndCondition',                    //创建计划步骤页-查询（沿用）计划的商品的过滤标签与条件
    searchGoodsInfoFields : ipUrl + '/chooseAndPricing/searchGoodsInfoFields',                                              //创建计划步骤页-查询商品基础信息字段
    splitCartesianProduct : ipUrl + '/chooseAndPricing/splitCartesianProduct',                                              //多个多选下拉菜单拆分合成笛卡尔积
    checkPlanCondition : ipUrl + '/chooseAndPricing/checkPlanCondition',                                                    //创建计划步骤页-保存并校验计划框选商品条件
    searchPricingRulesIndex : ipUrl + '/chooseAndPricing/searchPricingRulesIndex',                                          //查询调价规则相关指标
    searchPlanMatchingRelationships : ipUrl + '/chooseAndPricing/searchPlanMatchingRelationships',                          //查询计划所有商品匹配关系
    updateGroupMatchingRelationship: ipUrl + '/chooseAndPricing/updateGroupMatchingRelationship',                           //更新校验商品对标的分组匹配关系
    searchPlanDiscountsAndVip : ipUrl + '/chooseAndPricing/searchPlanDiscountsAndVip',                                      //查询（沿用）计划的专区、会员
    searchChooseAndPricingCoupons: ipUrl + '/chooseAndPricing/searchChooseAndPricingCoupons',                               //查询店铺选款定价的优惠券
    manageChooseAndPricingCoupons : ipUrl + '/chooseAndPricing/manageChooseAndPricingCoupons',                              //管理选款定价优惠券
    searchPlanCoupons : ipUrl + '/chooseAndPricing/searchPlanCoupons',                                                      //查询（沿用）计划的优惠券
    setPlanBenefitPoint : ipUrl + '/chooseAndPricing/setPlanBenefitPoint',                                                  //设置计划的利益点
    searchFieldOfPlan : ipUrl + '/chooseAndPricing/searchFieldOfPlan',                                                      //查询计划的可显示字段,
    createChooseAndpricing : ipUrl + '/chooseAndPricing/createChooseAndpricing',                                            //生成选款定价计划
    modifyPlanInfo : ipUrl + '/chooseAndPricing/modifyPlanInfo',                                                            //修改选款定价计划基础信息
    searchIndexCoefficientGroup : ipUrl + '/chooseAndPricing/searchIndexCoefficientGroup',                                  //查询指标系数的分组逻辑
    searchAlterLevel : ipUrl + '/chooseAndPricing/searchAlterLevel',                                                        //查询预警级别接口
    searchRulePrceRange : ipUrl + '/chooseAndPricing/searchRulePrceRange',                                                  //查询调价规则的配置调价幅度
    searchIndexCoefficient : ipUrl + '/chooseAndPricing/searchIndexCoefficient',                                            //查询指标系数接口
    searchGoodsClass : ipUrl + '/chooseAndPricing/searchGoodsClass',                                                        //查询商品类别表
    initializePriceAdjustment : ipUrl + '/chooseAndPricing/initializePriceAdjustment',                                      //初始化调价页面
    goodsStatusPriceConfirm: ipUrl + '/chooseAndPricing/goodsStatusPriceConfirm',                                           //商品状态-确认价格
    goodStatusAddIntoPlan : ipUrl +'/chooseAndPricing/goodStatusAddIntoPlan',                                               //商品状态-加入计划
    searchSingleItemInformation : ipUrl + '/chooseAndPricing/searchSingleItemInformation',                                  //查看单款信息
    searchGoods : ipUrl + '/chooseAndPricing/searchGoods',                                                                  //调价页面 - 查询商品
    initializeQueryFiltering : ipUrl + '/chooseAndPricing/initializeQueryFiltering',                                        //调价页面 -初始化查询筛选商品
    descriptionInterface : ipUrl + '/chooseAndPricing/descriptionInterface',                                                //编辑说明
    adjustPriceOfSingle : ipUrl + '/chooseAndPricing/adjustPriceOfSingle',                                                  //调整单款价格
    adjustCouponOfSingle : ipUrl + '/chooseAndPricing/adjustCouponOfSingle',                                                //调整单款价格
    resetPriceOfGood : ipUrl + '/chooseAndPricing/resetPriceOfGood',                                                        //重置单款价格
    batchSetupInitialization : ipUrl + '/chooseAndPricing/batchSetupInitialization',                                        //批量设置初始化接口
    batchSettingToChangeGoods : ipUrl + '/chooseAndPricing/batchSettingToChangeGoods',                                      //批量设置更改商品
    batchSettingGoodsFlag: ipUrl + '/chooseAndPricing/batchSettingGoodsFlag',                                               //批量设置更改商品设定标记
    batchSettingQueryAllGoods : ipUrl + '/chooseAndPricing/batchSettingQueryAllGoods',                                      //根据商品唯一编码查询商品
    triggerSummaryCalculation : ipUrl + '/chooseAndPricing/triggerSummaryCalculation',                                      //触发汇总计算
    searchConditionInitialization : ipUrl + '/chooseAndPricing/searchConditionInitialization',                              //查询条件初始化
    searchTotalInfo : ipUrl + '/chooseAndPricing/searchTotalInfo',                                                          //查询汇总信息
    searchHeatmap : ipUrl + '/chooseAndPricing/searchHeatmap',                                                              //查看热力图
    downloadChooseAndPriceResult : ipUrl + '/chooseAndPricing/downloadChooseAndPriceResult',                                //导出上传平台数据
    retainCheckConditions:ipUrl + '/chooseAndPricing/retainCheckConditions',                                                //保存计划框选商品接口
    verifyGroupMatching:ipUrl + '/chooseAndPricing/verifyGroupMatching',                                                    //校验商品分组匹配
    filterTagsOfGoods:ipUrl + '/chooseAndPricing/filterTagsOfGoods',                                                        //获取商品标签接口
    alterPlanUseStatus:ipUrl + '/chooseAndPricing/alterPlanUseStatus',                                                      //修改计划状态接口
    initShowConfigWindow:ipUrl + '/chooseAndPricing/initShowConfigWindow',                                                  //显示配置初始化的接口
    submitShowConfigWindow:ipUrl + '/chooseAndPricing/submitShowConfigWindow',                                              //显示配置提交的接口
    searchGoods2:ipUrl + '/chooseAndPricing/searchGoods2',                                                                  //调价页面表格查询接口
    referDataConfigOfPlan:ipUrl + '/chooseAndPricing/referDataConfigOfPlan',                                                //查询计划的可显示字段
    downloadCalculateDetail:ipUrl + '/chooseAndPricing/downloadCalculateDetail',                                            //导出选款定价中间结果

    // 经销对应品牌商品分析页面
    getCommodityStatisticalAnalysisData: ipUrl + '/distributionBrandProductAnalysis/getCommodityStatisticalAnalysisData',
    getCommodityStatisticalAnalysisTotal: ipUrl + '/distributionBrandProductAnalysis/getCommodityStatisticalAnalysisTotal',
    getCommodityStatisticalAnalysisTotalData: ipUrl + '/distributionBrandProductAnalysis/getCommodityStatisticalAnalysisTotalData',


    // pos系统
    goodsInfoByCode: ipUrl + '/posSystem/goodsInfoByCode',                                                              // 扫码查询商品信息
    createOrder:ipUrl + '/posSystem/createOrder',                                                                       // 生成订单
    modifyOrderStatus:ipUrl + '/posSystem/modifyOrderStatus',                                                           // 更改订单状态
    queryOrderInfo:ipUrl + '/posSystem/queryOrderInfo',                                                                 // 赠品查询订单信息
    confirmGiftInfo:ipUrl + '/posSystem/confirmGiftInfo',                                                               // 赠品提交
    saleReturnGetGoodsInfo:ipUrl + '/posSystem/saleReturnGetGoodsInfo',                                                 // 退货单查询订单信息
    uploadRefundInfo:ipUrl + '/posSystem/uploadRefundInfo',                                                             // 退货单提交
    downloadOrderDetailByFile:ipUrl + '/posSystem/downloadOrderDetailByFile',                                           // 导出销售订单明细
    updateRefundStatus:ipUrl + '/posSystem/updateRefundStatus',                                                         // 更改退货单状态
    downloadGiftDetail:ipUrl + '/posSystem/downloadGiftDetail',                                                         // 导出赠品明细
    downloadRefundDetail:ipUrl + '/posSystem/downloadRefundDetail',                                                     // 导出退货单明细

  // pos系统2.0
  getGoodsInfoByCode: ipUrl + "/posSystem/getGoodsInfoByCode", // 扫码获取商品接口
  createOrderNew: ipUrl + "/posSystem/createOrderNew", // 生成订单接口
  modifyOrderStatusNew: ipUrl + "/posSystem/modifyOrderStatusNew", // 修改订单状态接口
  downloadOrderDetailNew: ipUrl + "/posSystem/downloadOrderDetailNew", // 导出订单明细
  queryOrderInfoNew: ipUrl + "/posSystem/queryOrderInfoNew", // 查询订单接口

    // 人资预算系统
    getRelationshipCompanyDepartment :ipUrl + '/humanResourceEstimation/getRelationshipCompanyDepartment',                   // 公司和部门关系查询接口
    addRelationshipCompanyDepartment :ipUrl + '/humanResourceEstimation/addRelationshipCompanyDepartment',                   // 公司和部门关系新增接口
    getCompany :ipUrl + '/humanResourceEstimation/getCompany',                                                               // 公司查询接口
    getDepartment :ipUrl + '/humanResourceEstimation/getDepartment',                                                         // 部门查询接口
    getItem :ipUrl + '/humanResourceEstimation/getItem',                                                                     // 项目查询接口
    getShop :ipUrl + '/humanResourceEstimation/getShop',                                                                     // 店铺查询接口
    getStation :ipUrl + '/humanResourceEstimation/getStation',                                                               // 岗位查询接口
    deleteRelationshipCompanyDepartment:ipUrl + '/humanResourceEstimation/deleteRelationshipCompanyDepartment',              // 公司和部门关系逻辑删除接口
    backedRelationshipCompanyDepartment:ipUrl + '/humanResourceEstimation/backedRelationshipCompanyDepartment',              // 公司和部门关系逻辑恢复接口
    changeCompany:ipUrl + '/humanResourceEstimation/changeCompany',                                                          // 公司修改接口
    addCompany:ipUrl + '/humanResourceEstimation/addCompany',                                                                // 公司新增接口
    getRelationshipDepartmentItem:ipUrl + '/humanResourceEstimation/getRelationshipDepartmentItem',                          // 部门和项目关系查询接口
    deleteRelationshipDepartmentItem:ipUrl + '/humanResourceEstimation/deleteRelationshipDepartmentItem',                    // 部门和项目关系逻辑删除接口
    backedRelationshipDepartmentItem:ipUrl + '/humanResourceEstimation/backedRelationshipDepartmentItem',                    // 部门和项目关系逻辑恢复接口
    addRelationshipDepartmentItem:ipUrl + '/humanResourceEstimation/addRelationshipDepartmentItem',                          // 部门和项目关系新增接口
    getRelationshipItemShop:ipUrl + '/humanResourceEstimation/getRelationshipItemShop',                                      // 项目和店铺关系查询接口
    deleteRelationshipItemShop:ipUrl + '/humanResourceEstimation/deleteRelationshipItemShop',                                // 项目和店铺关系逻辑删除接口
    backedRelationshipItemShop:ipUrl + '/humanResourceEstimation/backedRelationshipItemShop',                                // 项目和店铺关系逻辑删除恢复接口
    addRelationshipItemShop:ipUrl + '/humanResourceEstimation/addRelationshipItemShop',                                      // 项目和店铺关系新增接口
    changeDepartment:ipUrl + '/humanResourceEstimation/changeDepartment',                                                    // 部门修改接口
    addDepartment:ipUrl + '/humanResourceEstimation/addDepartment',                                                          // 部门新增接口
    changeItem:ipUrl + '/humanResourceEstimation/changeItem',                                                                // 项目修改接口
    addItem:ipUrl + '/humanResourceEstimation/addItem',                                                                      // 项目新增接口
    changeShop:ipUrl + '/humanResourceEstimation/changeShop',                                                                // 店铺修改接口
    addHRShop:ipUrl + '/humanResourceEstimation/addShop',                                                                    // 店铺新增接口
    changeStation:ipUrl + '/humanResourceEstimation/changeStation',                                                          // 岗位修改接口
    addStation:ipUrl + '/humanResourceEstimation/addStation',                                                                // 岗位新增接口
    getMemberItemInformation:ipUrl + '/humanResourceEstimation/getMemberItemInformation',                                    // 员工项目信息查询接口
    getMemberInformation:ipUrl + '/humanResourceEstimation/getMemberInformation',                                            // 员工基础信息查询接口
    addMemberInformation:ipUrl + '/humanResourceEstimation/addMemberInformation',                                            // 员工基础信息新增接口
    getBPList:ipUrl + '/humanResourceEstimation/getBPList',                                                                  // 查询BP管理人
    addMemberItemInformation:ipUrl + '/humanResourceEstimation/addMemberItemInformation',                                    // 员工项目信息新增接口
    changeMemberItemInformation:ipUrl + '/humanResourceEstimation/changeMemberItemInformation',                              // 员工项目信息修改接口
    changeMemberInformation:ipUrl + '/humanResourceEstimation/changeMemberInformation',                                      // 员工基础信息修改接口
    getMemberPayInformation:ipUrl + '/humanResourceEstimation/getMemberPayInformation',                                      // 员工薪酬信息查询接口
    addMemberPayInformation:ipUrl + '/humanResourceEstimation/addMemberPayInformation',                                      // 员工薪酬信息新增接口
    changeMemberPayInformation:ipUrl + '/humanResourceEstimation/changeMemberPayInformation',                                // 员工薪酬信息修改接口
    findSocialSecurity:ipUrl + '/humanResourceEstimation/findSocialSecurity',                                                // 员工社保基数查询接口
    searchNewWorkerSocialSecurity:ipUrl + '/humanResourceEstimation/findSocialSecurityJudgmentMaintain',                     // 社保基数判别查询接口
    searchWorkerSocialSecuritySubject:ipUrl + '/humanResourceEstimation/findSocialSecuritySubject',                          // 员工社保缴纳系数查询接口
    getSocialSecurityStation:ipUrl + '/humanResourceEstimation/getSocialSecurityStation',                                    // 查询社保缴纳地接口
    searchWorkerAccumulationFund:ipUrl + '/humanResourceEstimation/findAccumulationFundJudgmentMaintain',                    // 员工公积金基数判断查询接口
    searchWorkerAccumulationFundEstimate:ipUrl + '/humanResourceEstimation/findAccumulationFund',                            // 员工公积金基数查询接口
    addNewWorkerSocialSecurity:ipUrl + '/humanResourceEstimation/addSocialSecurityJudgmentMaintain',                         // 社保基数判断新增数据接口
    addSocialSecuritySubject:ipUrl + '/humanResourceEstimation/addSocialSecuritySubject',                                    // 社保缴纳系数新增接口
    addSocialSecurityBase:ipUrl + '/humanResourceEstimation/addSocialSecurityBase',                                          // 新增社保基数接口
    salaryShow:ipUrl + '/humanResourceEstimation/salaryShow',                                                                // 工资呈现接口
    specialDeductionShow:ipUrl + '/humanResourceEstimation/specialDeductionShow',                                            // 当月专项附加扣除呈现接口
    socialSecurityShow:ipUrl + '/humanResourceEstimation/socialSecurityShow',                                                // 社保呈现接口
    changeSocialSecurity:ipUrl + '/humanResourceEstimation/changeSocialSecurity',                                            // 员工社保基数修改接口
    accumulationFundShow:ipUrl + '/humanResourceEstimation/accumulationFundShow',                                            // 公积金呈现接口
    personalIncomeTaxShow:ipUrl + '/humanResourceEstimation/personalIncomeTaxShow',                                          // 个人所得税呈现接口
    outSubjectExpensesShow:ipUrl + '/humanResourceEstimation/outSubjectExpensesShow',                                        // 费用汇总呈现接口
    achievementBonusCalculation:ipUrl + '/humanResourceEstimation/achievementBonusCalculation',                              // 绩效奖金汇总呈现接口
    changeOutSubjectExpenses:ipUrl + '/humanResourceEstimation/changeOutSubjectExpenses',                                    // 额外费用修改接口
    changeAchievementBonus:ipUrl + '/humanResourceEstimation/changeAchievementBonus',                                        // 绩效奖金修改接口
    findPersonalIncomeTaxMaintain:ipUrl + '/humanResourceEstimation/findPersonalIncomeTaxMaintain',                          // 个人所得税变更规则查询接口
    addOutSubjectExpenses:ipUrl + '/humanResourceEstimation/addOutSubjectExpenses',                                          // 额外费用新增接口
    addAchievementBonus:ipUrl + '/humanResourceEstimation/addAchievementBonus',                                              // 绩效奖金新增接口
    allSubjectShow:ipUrl + '/humanResourceEstimation/allSubjectShow',                                                        // 费用汇总呈现接口
    memberChangeShow:ipUrl + '/humanResourceEstimation/memberChangeShow',                                                    // 人员变更记录呈现接口
    changeSocialSecuritySubject:ipUrl + '/humanResourceEstimation/changeSocialSecuritySubject',                              // 社保缴纳系数修改接口
    addChangeSocialSecurity:ipUrl + '/humanResourceEstimation/addChangeSocialSecurity',                                      // 员工社保基数新增修改接口
    changeAccumulationFundJudgmentMaintain:ipUrl + '/humanResourceEstimation/changeAccumulationFundJudgmentMaintain',        // 公积金基数判断修改数据接口
    countCompanyShow:ipUrl + '/humanResourceEstimation/countCompanyShow',                                                    // 公司人员个数呈现接口
    addPersonalIncomeTaxMaintain:ipUrl + '/humanResourceEstimation/addPersonalIncomeTaxMaintain',                            // 个人所得税变更规则修改接口
    changePersonalIncomeTaxMaintain:ipUrl + '/humanResourceEstimation/changePersonalIncomeTaxMaintain',                      // 个人所得税变更规则新增接口
    addBp:ipUrl + '/humanResourceEstimation/addBp',                                                                          // BP权限人员新增接口
    confirmChangeBp:ipUrl + '/humanResourceEstimation/confirmChangeBp',                                                      // BP权限人员确认接口
    getBp:ipUrl + '/humanResourceEstimation/getBp',                                                                          // BP权限人员查询接口.
    deleteBp:ipUrl + '/humanResourceEstimation/deleteBp',                                                                    // BP权限人员删除接口
    getBpList:ipUrl + '/humanResourceEstimation/getBpList',                                                                  // BP列表查询接口
    changeBp:ipUrl + '/humanResourceEstimation/changeBp',                                                                    // BP权限人员转移接口
    rejectBp:ipUrl + '/humanResourceEstimation/rejectBp',                                                                    // BP权限人员拒绝接口
    addSocialSecurity:ipUrl + '/humanResourceEstimation/addSocialSecurity',                                                  // 员工社保基数新增接口
    changeSocialSecurityThird:ipUrl + '/humanResourceEstimation/changeSocialSecurityThird',                                  // 员工社保基数修改接口
    addAccumulationFund:ipUrl + '/humanResourceEstimation/addAccumulationFund',                                              // 公积金基数新增接口
    changeAccumulationFund:ipUrl + '/humanResourceEstimation/changeAccumulationFund',                                        // 公积金基数修改接口
    addChangeTemplate:ipUrl + '/humanResourceEstimation/addChangeTemplate',                                                  // 模板新增替换接口
    getUserId:ipUrl + '/humanResourceEstimation/getUserId',                                                                  // 查询NB用户映射接口
    findTemplate:ipUrl + '/humanResourceEstimation/findTemplate',                                                            // 模板查询接口
    getBpInformation:ipUrl + '/humanResourceEstimation/getBpInformation',                                                    // BP权限人员基础信息查询接口
    downloadCountCompanyShow:ipUrl + '/humanResourceEstimation/downloadCountCompanyShow',                                    // 公司人员个数下载接口
    recalculate:ipUrl + '/humanResourceEstimation/recalculate',                                                              // 重新计算接口
    judgeSalarySpecialist:ipUrl + '/humanResourceEstimation/judgeSalarySpecialist',                                          // 判断薪资专员接口
    saveAllSubject:ipUrl + '/humanResourceEstimation/saveAllSubject',                                                        // 拆分表保存接口
    downloadAllSubjectShow:ipUrl + '/humanResourceEstimation/downloadAllSubjectShow',                                        // 下载接口
    getAllSubjectName:ipUrl + '/humanResourceEstimation/getAllSubjectName',                                                  // 拆分表查询所有矩阵接口
    deleteAllSubject:ipUrl + '/humanResourceEstimation/deleteAllSubject',                                                    // 拆分表删除缓存接口
    compareShow:ipUrl + '/humanResourceEstimation/compareShow',                                                              // 对照呈现接口
    deleteSocialSecurity:ipUrl + '/humanResourceEstimation/deleteSocialSecurity',                                            // 员工社保基数删除接口
    deleteAccumulationFund:ipUrl + '/humanResourceEstimation/deleteAccumulationFund',                                        // 公积金基数删除接口
    addAccumulationFundJudgmentMaintain:ipUrl + '/humanResourceEstimation/addAccumulationFundJudgmentMaintain',              // 公积金基数判断新增数据接口
    supplementaryPaymentSocialSecurity:ipUrl + '/humanResourceEstimation/supplementaryPaymentSocialSecurity',                // 补缴社保费用接口
    supplementaryPaymentAccumulationFund:ipUrl + '/humanResourceEstimation/supplementaryPaymentAccumulationFund',            // 补缴公积金费用接口
    changeMemberPayInformationSingle:ipUrl + '/humanResourceEstimation/changeMemberPayInformationSingle',                    // 员工薪酬信息单个修改接口
    splitSalaryTime:ipUrl + '/humanResourceEstimation/splitSalaryTime',                                                      // 薪酬表中的切分时间接口
    findSocialSecurityRange:ipUrl + '/humanResourceEstimation/findSocialSecurityRange',                                      // 查询社保基数范围接口
    addSocialSecurityRange:ipUrl + '/humanResourceEstimation/addSocialSecurityRange',                                        // 新增社保基数范围接口
    changeSocialSecurityRange:ipUrl + '/humanResourceEstimation/changeSocialSecurityRange',                                  // 修改社保基数范围接口
    findWorkerAccumulationRange:ipUrl + '/humanResourceEstimation/findWorkerAccumulationRange',                              // 查询公积金基数范围接口
    addWorkerAccumulationRange:ipUrl + '/humanResourceEstimation/addWorkerAccumulationRange',                                // 新增公积金基数范围接口
    changeWorkerAccumulationRange:ipUrl + '/humanResourceEstimation/changeWorkerAccumulationRange',                          // 修改公积金基数范围接口
    countItemShow:ipUrl + '/humanResourceEstimation/countItemShow',                                                          // 项目人员统计呈现接口
    downloadCountItemShow:ipUrl + '/humanResourceEstimation/downloadCountItemShow',                                          // 项目人员个数下载接口
    getEmployeeIdList:ipUrl + '/humanResourceEstimation/getEmployeeIdList',                                                  // 获取员工ID列表接口
    estimateToBudgetDataSynchronous:ipUrl + '/humanResourceEstimation/estimateToBudgetDataSynchronous',                      // 预估到预算数据同步接口
    getRightsBudgetList:ipUrl + '/humanResourceEstimation/getRightsBudgetList',                                              // 获取对应管理人员列表接口
    // #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 start
    downloadSocialSecurityEstimate:ipUrl + '/humanResourceEstimation/downloadSocialSecurityEstimate',                        // 社保基数预估表单下载接口
    downloadAccumulationFundEstimate:ipUrl + '/humanResourceEstimation/downloadAccumulationFundEstimate',                    // 公积金基数预估表单下载接口
    downloadMemberItemInformation:ipUrl + '/humanResourceEstimation/downloadMemberItemInformation',                          // 员工项目信息下载接口
    downloadMemberPayInformation:ipUrl + '/humanResourceEstimation/downloadMemberPayInformation',                            // 员工薪酬信息下载接口
    downloadMemberEstimate:ipUrl + '/humanResourceEstimation/downloadMemberEstimate',                                        // 员工表单下载接口
    // #I66709 NB20人资新增员工、员工项目、社保、公积金下载接口 end
    // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 start
    deleteMemberInformation:ipUrl + '/humanResourceEstimation/deleteMemberInformation',                                      // 员工基础信息删除接口
    deleteMemberItemInformation:ipUrl + '/humanResourceEstimation/deleteMemberItemInformation',                              // 员工项目信息删除接口
    deleteMemberPayInformation:ipUrl + '/humanResourceEstimation/deleteMemberPayInformation',                                // 员工薪酬信息删除接口
    // #I6DXU4 NB20人资新增员工项目薪酬的删除功能 end
  uploadMemberPayInformation:
    ipUrl + "/humanResourceEstimation/uploadMemberPayInformation", // 员工薪酬信息导入接口
  templateMemberPayInformation:
    ipUrl + "/humanResourceEstimation/templateMemberPayInformation", // 员工薪酬信息下载模版接口

    //短视频智能生成平台项目
    selectShortVideoMaterial:ipUrl+'/shortVideo/searchMaterial',                                                              //短视频素材查询接口
    editShortVideoMaterial:ipUrl+'/shortVideo/editMaterial',                                                                  //短视频素材编辑接口
    deleteShortVideoMaterial:ipUrl+'/shortVideo/deleteMaterial',                                                              //短视频素材删除接口
    showTagsShortVideoMaterial:ipUrl+'/shortVideo/showTags',                                                                  //短视频素材展示标签接口
    editTagsShortVideoMaterial:ipUrl+'/shortVideo/editTags',                                                                  //短视频素材编辑标签接口
    addTagsShortVideoMaterial:ipUrl+'/shortVideo/labelingMaterial',                                                            //短视频素材添加标签接口
    selectShortVideoPlan:ipUrl+'/shortVideo/searchPlan',                                                                       //短视频计划查询接口
    deleteShortVideoPlan:ipUrl+'/shortVideo/disablePlan',                                                                      //短视频计划删除接口
    searchPlanStepOne:ipUrl+'/shortVideo/searchPlanStepOne',                                                                   //短视频计划查询步骤一接口
    createPlanStepOne:ipUrl+'/shortVideo/createPlanStepOne',                                                                    //短视频计划编辑步骤一接口
    searchPlanStepTwo:ipUrl+'/shortVideo/searchPlanStepTwo',                                                                    //短视频计划查询步骤二接口
    editPlanStepTwo:ipUrl+'/shortVideo/editPlanStepTwo',                                                                        //短视频计划编辑步骤二接口
    searchPlanStepThree:ipUrl+'/shortVideo/searchPlanStepThree',                                                                //短视频计划查询步骤三接口
    editPlanStepThree:ipUrl+'/shortVideo/editPlanStepThree',                                                                    //短视频计划编辑步骤三接口
    screenVideoData:ipUrl+'/shortVideo/screenVideoData',                                                                        //短视频计划下载素材
    screenVideoDataDownload:ipUrl+'/shortVideo/screenVideoDataDownload',                                                                        //短视频计划直接下载素材

    // 知识库
    searchTabKnoledge: ipUrl + '/knoledge/searchTab',                                                                          //检索页面tab页
    uploadReportKnoledge: ipUrl + '/knoledge/uploadReport',                                                                   //上传报告
    reviewShowReportKnoledge: ipUrl + '/knoledge/reviewShowReport',                                                          //查询审核列表
    reviewChangeStatusKnoledge: ipUrl + '/knoledge/reviewChangeStatus',                                                     //改变报告状态
    searchByKeywordsKnoledge:ipUrl + '/knoledge/searchByKeywords',                                                          //根据关键词查询报告
    recordAccessesKnoledge:ipUrl + '/knoledge/recordAccesses',                                                          //9. 访问记录接口
    batchUploadINterimReportKnoledge: ipUrl + '/knoledge/batchUploadINterimReport',                                    //批量上传临时报告
    editInterimReportKnoledge: ipUrl + '/knoledge/editInterimReport',                                                 //编辑临时报告

    // 店群
    // 店铺日报
    updateStoreGroupStoreDetail: ipUrl + '/StoreGroup/updateStoreDetail',                                                         //上传聚水潭数据
    updateStoreGroupJushuitanData: ipUrl + '/StoreGroup/updateJushuitanData',                                                     //上传聚水潭数据
    getStoreGroupShopName : ipUrl + '/StoreGroup/getShopName',                                                                    //获取所有店铺                              
    updateStoreGroupDailyData : ipUrl + '/StoreGroup/updateDailyData',                                                            //上传电商罗盘数据
    shopStoreGroupMonthStatistic : ipUrl + '/StoreGroup/shopMonthStatistic',                                                      //获取，下载月度店铺数据
    shopStoreGroupDailyStatistic : ipUrl + '/StoreGroup/shopDailyStatistic',                                                      //获取，下载日统计
    shopStoreGroupTotalStatistic : ipUrl + '/StoreGroup/shopTotalStatistic',                                                                //获取，下载店铺数据
    shopStoredownloadGoodsDetail : ipUrl + '/StoreGroup/downloadGoodsDetail',                                                    //下载商品明细数据
}

export default servicePath